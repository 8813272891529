import React, { Fragment } from "react"
import Header from "../shared/header/header";
import trainingtData from "../../mockData/training.json";
import { connect } from "react-redux";
import { UserContextState } from "../../redux/actions/userContextAction";
import TrainingCard from "./trainingCard"
import Profile from "../../model/users/profile"

interface passedProps {
    userContext: UserContextState,
    profile: Profile
}

function Training(props: passedProps) {


    const trainingOptions = trainingtData.Training;



    return (
        <Fragment>
            <Header></Header>
            <div className="px-4 lg:px-8 mt-3 pt-6 xl:w-4/5 2xl:w-4/5 m-auto">
                <h2 className="text-2xl text-primary-green font-semibold">Training</h2>
                <div className="flex flex-wrap mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
                    {trainingOptions &&
                        trainingOptions.map(option => {

                            return (

                                <Fragment>
                                    {(option.roles.includes(props.userContext.roleId.toString())) &&
                                        <TrainingCard
                                            title={option.heading}
                                            description={option.description}
                                            url={option.url}
                                            css={option.css}
                                            props={props.userContext}></TrainingCard>
                                    }
                                </Fragment>

                            )
                        })
                    }
                </div>
            </div>

        </Fragment>
    );
}

const mapStateToProps = (state: any) => {
    return {
        profile: state.profile,
        userContext: state.userContext
    };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(Training);

import Select from "react-select";
import { useEffect, useState } from "react";
import { GetSchoolYears } from "../../api/teacher/school";
import { ISchoolYear } from "../../model/teacher/schoolYear";
import { ISelectElement } from "../../model/interface/selectElement";

interface ISchoolYearDropDown {
  schoolYearData?: Array<ISchoolYear>;
  schoolId?: number;
  reset?: boolean;
  offsets?: number[];
  className?: string;
  sortByGreatestFirst?: boolean;
  schoolYearId?: number;
  handleSchoolYearChange: (selectedSchoolYearId: number) => void;
  handleSchoolYearLoaded: (currentSchoolYearId?: number) => void;
  handleSchoolYearData: (schoolYearData: Array<ISchoolYear>) => void;
}

export function SchoolYearDropDown(props: ISchoolYearDropDown) {
  const [schoolYearDetails, setSchoolYearDetails] =
    useState<Array<ISchoolYear>>();
  const [schoolYears, setSchoolYears] = useState<Array<ISelectElement>>();
  const [selectedSchoolYearId, setSelectedSchoolYearId] =
    useState<ISelectElement>();

  useEffect(() => {
    if (props.schoolYearData) {
      setSchoolYearData(props.schoolYearData);
    } else {
      getSchoolYears();
    }
  }, []);

  useEffect(() => {
    if (props.reset) resetSchoolYear();
  }, [props.reset]);

  useEffect(() => {
    if (props.schoolYearId && schoolYearDetails) {
      const selectedYear = schoolYearDetails.find(
        (year) => year.schoolYearId === props.schoolYearId
      );
      if (selectedYear) {
        setSelectedSchoolYearId({
          label: selectedYear.name,
          value: selectedYear.schoolYearId.toString(),
        });
        props.handleSchoolYearChange(props.schoolYearId);
      }
    }
  }, [props.schoolYearId, schoolYearDetails]);

  function getSchoolYears() {
    GetSchoolYears(props.schoolId ?? 0).then((response) => {
      if (response.data && response.data.length > 0) {
        let filteredData = response.data;
        if (props.offsets && props.offsets.length > 0) {
          filteredData = filteredData.filter((item) =>
            props.offsets?.some((offset) =>
              item.currentYearOffset.toString().includes(offset.toString())
            )
          );
        }
        setSchoolYearData(filteredData);
      }
    });
  }

  function handleSchoolYearChange(e) {
    setSelectedSchoolYearId(e);
    props.handleSchoolYearChange(parseInt(e.value));
  }

  function setSchoolYearData(data: Array<ISchoolYear>) {
    let schoolYearData: Array<ISchoolYear> = data;

    if (props.sortByGreatestFirst) {
      schoolYearData = schoolYearData.sort((a, b) => b.currentYearOffset - a.currentYearOffset);
    }

    if (data) {
      setSchoolYearDetails(schoolYearData);
      props.handleSchoolYearData(schoolYearData);
      const schoolyears: Array<ISelectElement> = data.map((d: ISchoolYear) => {
        return {
          label: d.name,
          value: d.schoolYearId.toString(),
        };
      });
      setSchoolYears(schoolyears);

      let initialSchoolYear: ISelectElement;
      if (props.schoolYearId) {
        const selectedYear = schoolYearData.find(
          (year) => year.schoolYearId === props.schoolYearId
        );
        initialSchoolYear = selectedYear
          ? {
              label: selectedYear.name,
              value: selectedYear.schoolYearId.toString(),
            }
          : {
              label: schoolyears[0].label,
              value: schoolyears[0].value,
            };
      } else {
        const currentSchoolYear = schoolYearData.find((s) => s.currentYearOffset === 0);
        initialSchoolYear = currentSchoolYear
          ? {
              label: currentSchoolYear.name,
              value: currentSchoolYear.schoolYearId.toString(),
            }
          : {
              label: schoolyears[0].label,
              value: schoolyears[0].value,
            };
      }

      setSelectedSchoolYearId(initialSchoolYear);
      props.handleSchoolYearLoaded(parseInt(initialSchoolYear.value));
    }
  }

  function resetSchoolYear() {
    const currentSchoolYear = schoolYearDetails?.find(
      (s) => s.currentYearOffset === 0
    );
    if (currentSchoolYear) {
      setSelectedSchoolYearId({
        label: currentSchoolYear.name,
        value: currentSchoolYear.schoolYearId.toString(),
      });
      props.handleSchoolYearChange(
        parseInt(currentSchoolYear.schoolYearId.toString())
      );
    }
  }

  return (
    <>
      {schoolYears && (
        <div className={props.className ?? ""}>
          <Select
            key="schoolyear"
            id="schoolyear"
            name="schoolyear"
            value={selectedSchoolYearId}
            onChange={handleSchoolYearChange}
            options={schoolYears}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary: "#008fbb",
              },
            })}
          />
        </div>
      )}
    </>
  );
}

import { useEffect, useState } from "react";
import TOSGamePlayScoreboard from "./tosGamePlayScoreboard";
import { GetGameSession, GetGameSessionItems, GetGameSessionStandards, GetGameSessionTeamNames, getMinibreak, SetItemAnswered, SetSessionEnded, UpdateTeamPoints } from "../../../../../api/classroomGame/thinkOrSwim";
import { IGameSessionItem, IGameSessionStandardsItems, IMiniBreak, ISelectedStandard, ISessionTeam } from "../../../../../model/interface/classroomGame/thinkOrSwimSetup";
import { toast } from "react-toastify";
import Loader from "../../../../shared/loader";
import { useHistory } from "react-router-dom";
import routeConstant from "../../../../../utils/constant/routeConstant";
import TOSGamePlayQuestion from "./tosGamePlayQuestion";
import TOSGamePlayMainScreen from "./tosGamePlayMainScreen";
import TOSGamePlayEndScreen from "./tosGamePlayEndScreen";

interface ITOSGamePlay {
  selectedClassroomGameSessionId: number,
}

function TOSGamePlay(props: ITOSGamePlay) {
  const {
    selectedClassroomGameSessionId,
  } = props;

  const history = useHistory();
  const [showScoreboard, setShowScoreboard] = useState<boolean>(false);
  const [sessionTeams, setSessionTeams] = useState<Array<ISessionTeam>>([]);
  const [sessionStandards, setSessionStandards] = useState<Array<ISelectedStandard>>([]);
  const [sessionItems, setSessionItems] = useState<Array<IGameSessionStandardsItems>>([]);
  const [selectedStandardIndex, setSelectedStandardIndex] = useState<number>(0);
  const [selectedQuestionIndex, setSelectedQuestionIndex] = useState<number>(0);
  const [teamTurn, setTeamTurn] = useState<number>(0);
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [showQuestion, setShowQuestion] = useState<boolean>(false);
  const [questionPreviewSettings, setQuestionPreviewSettings] = useState<any>();
  const [miniBreak, setMinibreak] = useState<IMiniBreak>();
  const [isQuestion, setIsQuestion] = useState<boolean>(true);
  const [showSpanishQuestion, setShowSpanishQuestion] = useState<boolean>(false);
  const [selectedTeams, setSelectedTeams] = useState<Array<string>>([]);
  const [isGameEnded, setIsGameEnded] = useState<boolean>(false);

  const classroomGameId = 1;
  const answerPointValue = 100;
  const reefRecessPointsValue = 50;

  const loadSession = (classroomGameSessionId: number) => {
    GetGameSession(classroomGameSessionId, classroomGameId)
    .then(response => {
      setShowLoading(false);
      setIsGameEnded(!!response.data.completedDate);
    })
    .catch(error => {
      setShowLoading(false);
      toast.error(`${error.message} Cannot load Session`);
    })
  }

  const loadSessionTeams = (classroomGameSessionId: number) => {
    GetGameSessionTeamNames(classroomGameSessionId)
    .then(response => {
      setShowLoading(false);
      setSessionTeams(response.data.teamNames);
    })
    .catch(error => {
      setShowLoading(false);
      toast.error(`${error.message} Cannot load Session Team Names`);
    });
  }

  const loadSessionItems = (classroomGameSessionId: number) => {
    GetGameSessionItems(classroomGameSessionId)
    .then(response => {
      setShowLoading(false);
      const items: Array<IGameSessionStandardsItems> = Object.entries(response.data.sessionItems).map( i => {
        const itemsArray = i[1] as Array<IGameSessionItem>;
        return {
          standardId: +i[0],
          sessionItems: itemsArray
            .sort((a, b) => a.sortOrder - b.sortOrder)
            .filter(item => item.sortOrder < 5)
            .map(item => ({
              ...item,
              dateAndswered: item.dateAnswered && new Date(item.dateAnswered),
            })),
        }
      })
      setSessionItems(items);
    })
    .catch(error => {
      setShowLoading(false);
      toast.error(`${error.message} Cannot load Session Items`);
    });
  }

  const loadSessionStandards = (classroomGameSessionId: number) => {
    GetGameSessionStandards(classroomGameId, classroomGameSessionId)
    .then(standardsResponse => {
      setShowLoading(false);
      const standards = standardsResponse.data.standards;
      setSessionStandards(standards);
    })
    .catch(error => {
      setShowLoading(false);
      toast.error(`${error.message} Cannot load Session Standards`);
    })
  }

  const loadQuestion = (question: IGameSessionItem, standard: ISelectedStandard, showSpanish: boolean = showSpanishQuestion) => {
    const newQuestionPreviewSettings = {
      previewId: showSpanish ? question.spanishQuestionReferenceId : question.englishQuestionReferenceId,
      questionId: question.questionId,
      standardId: standard.standardId,
      standardName: standard.standardName,
      englishQuestionReferenceId: question.englishQuestionReferenceId,
      spanishQuestionReferenceId: question.spanishQuestionReferenceId,
    };
    setQuestionPreviewSettings(newQuestionPreviewSettings);
    setShowQuestion(true);
  }

  const loadMiniBreak = (miniBreakId: number, standardName: string) => {
    setShowLoading(true);
    getMinibreak(miniBreakId)
      .then(response => {
        setShowLoading(false);
        setMinibreak(response.data);
        const newQuestionPreviewSettings = {
          standardName
        };
        setQuestionPreviewSettings(newQuestionPreviewSettings);
        setShowQuestion(true);
      })
      .catch(error => {
        setShowLoading(false);
        toast.error(`${error.message} Cannot load selected mini break`);
      })
  }

  const handleLanguageChange = (showSpanish: boolean) => {
    const newQuestionPreviewSettings = {...questionPreviewSettings, previewId: undefined};
    setQuestionPreviewSettings(newQuestionPreviewSettings);
    setShowSpanishQuestion(showSpanish);
  }

  const handleShellClick = (standardIndex: number, questionIndex: number) => {
    const standardId = sessionStandards[standardIndex]?.standardId;
    const question = sessionItems.find(item => item.standardId == standardId)?.sessionItems[questionIndex];
    const questionId = question?.questionId;
    const miniBreakId = question?.classroomGameMiniBreakId;
    const selectedStandardIndex = sessionItems.findIndex(item => item.standardId == standardId);
    setSelectedStandardIndex(selectedStandardIndex);
    setSelectedQuestionIndex(questionIndex);
    setIsQuestion(!!questionId);
    setSelectedTeams([]);
    setShowQuestion(false);

    if (questionId) {
      question && loadQuestion(question, sessionStandards[standardIndex]);
    } else if (miniBreakId) {
      loadMiniBreak(miniBreakId, sessionStandards[standardIndex]?.standardName);
    }
  }

  const handleAddSingleTeamPoints = async (teamName: string, points: number) => {
    const teams = sessionTeams;
    setShowLoading(true);
    const team = teams.find(team => team.name === teamName);
    if (team) {
      try {
        team.points += points;
        await UpdateTeamPoints(selectedClassroomGameSessionId, team);
      } catch (error) {
        toast.error(`Cannot update team points for team ${team.name}`);
      }
    }
    setShowLoading(false);
    setSessionTeams(teams);
  }

  const handleAddTeamPoints = async (points: number) => {
    const teams = sessionTeams;
    setShowLoading(true)
    teams.forEach(async team => {
      if(!!selectedTeams.find(teamName => teamName === team.name)) {
        try {
          team.points += points;
          await UpdateTeamPoints(selectedClassroomGameSessionId, team);
        } catch (error) {
          toast.error(`Cannot update team points for team ${team.name}`);
        }
      }
    })
    setShowLoading(false);
    setSessionTeams(teams);
  }

  const getQtyAvailableItems = (items: Array<IGameSessionStandardsItems>) => {
    const qty = sessionItems
      .reduce((acc: number, items: IGameSessionStandardsItems) => 
        acc + (items.sessionItems
          .reduce((acc: number, item: IGameSessionItem) => 
            !!item.dateAnswered ? acc : (acc + 1), 0
          )
        ), 0
      )
    return qty;
  }

  const handleItemAnswered = () => {
    const standards = [...sessionStandards];
    const items = sessionItems;
    const item = items[selectedStandardIndex].sessionItems[selectedQuestionIndex];
    setShowLoading(true);
    SetItemAnswered(selectedClassroomGameSessionId, item.thinkOrSwimSessionItemId)
    .then(_response => {
      setShowLoading(false);
      item.dateAnswered = new Date()
      setSessionItems(items);
      setSessionStandards(standards);
      
      const itemsLeft = getQtyAvailableItems(items);
      console.log(`Questions left: ${itemsLeft}`);
      if(itemsLeft === 0)
        endGame();
    })
    .catch(error => {
      setShowLoading(false);
      toast.error(`${error.message} Cannot set item as answered`);
    })
  }

  const changeTurn = () => {
    const nextTurn = teamTurn + 1 >= sessionTeams.length ? 0 : teamTurn + 1;
    setTeamTurn(nextTurn)
  }

  const changeSelectedTeam = (teamName: string) => {
    const newSelectedTeams = !!selectedTeams.find(n => n === teamName) 
      ? selectedTeams.filter(team => team !== teamName) 
      : [...selectedTeams, teamName];
    setSelectedTeams(newSelectedTeams);
  }

  const handleAssignPointsClick = (isQuestion: boolean) => {
    handleAddTeamPoints(
      isQuestion ? answerPointValue : reefRecessPointsValue
    );
  
    handleItemAnswered();
    changeTurn();

    setShowQuestion(false);
  }

  const scrollToTop = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  const endGame = () => {
    setShowScoreboard(false);
    setShowLoading(true);
    scrollToTop();
    SetSessionEnded(selectedClassroomGameSessionId, true)
    .then(_response => {
      setShowLoading(false);
      setIsGameEnded(true);
    })
    .catch(error => {
      setShowLoading(false);
      toast.error(`${error.message} Cannot set session as ended`);
    })
  }

  const returnToSessionScreen = () => {
    history.push({
      pathname: routeConstant.ClassroomGame.ThinkOrSwim
    });
  }

  useEffect(() => {
    setShowLoading(true);
    setTeamTurn(0);
    setShowQuestion(false);
    loadSession(selectedClassroomGameSessionId);
    loadSessionTeams(selectedClassroomGameSessionId);
    loadSessionStandards(selectedClassroomGameSessionId);
    loadSessionItems(selectedClassroomGameSessionId);
    scrollToTop();
  }, [selectedClassroomGameSessionId])

  useEffect(() => {
    if (questionPreviewSettings) {
      const newQuestionPreviewSetting = {
        ...questionPreviewSettings,
        previewId: showSpanishQuestion 
          ? questionPreviewSettings.spanishQuestionReferenceId
          : questionPreviewSettings.englishQuestionReferenceId
      };
      setQuestionPreviewSettings(newQuestionPreviewSetting);
    }
  }, [showSpanishQuestion])
  
  return (
    <div>
      {(showLoading || !sessionItems.length || !sessionItems.length) && (<Loader />)}
      {!isGameEnded && (
        <TOSGamePlayMainScreen 
          sessionStandards={sessionStandards}
          sessionItems={sessionItems}
          sessionTeams={sessionTeams}
          teamTurn={teamTurn}
          setShowScoreboard={setShowScoreboard}
          handleShellClick={handleShellClick}
          endGame={endGame}
        />
      )}
      {isGameEnded && (
        <TOSGamePlayEndScreen 
          sessionTeams={sessionTeams}
          onReturnToSessionScreen={returnToSessionScreen}
        />
      )}
      {showScoreboard && (
        <TOSGamePlayScoreboard 
          sessionTeams={sessionTeams}
          handleAddSingleTeamPoints={handleAddSingleTeamPoints}
          onClose={() => setShowScoreboard(false)} 
          onEndGame={endGame}
        />
      )}
      {showQuestion && (
        <TOSGamePlayQuestion 
          questionPreviewSettings={questionPreviewSettings} 
          isQuestion={isQuestion} 
          showSpanishQuestion={showSpanishQuestion} 
          handleLanguageChange={handleLanguageChange} 
          setShowQuestion={setShowQuestion} 
          sessionTeams={sessionTeams} 
          selectedTeams={selectedTeams} 
          changeSelectedTeam={changeSelectedTeam} 
          handleAssignPointsClick={handleAssignPointsClick} 
          miniBreak={miniBreak} 
          reefRecessPointsValue={reefRecessPointsValue}
        />
      )}
    </div>
  )
}

export default TOSGamePlay

import { Fragment, useState } from "react";
import SkillSubject from "./skillSubject";
import SkillStandard from "./skillStandard";
import classNames from "classnames";
import GameChoose from "./gameChoose";

function Home() {
  const [skillSubjectId, setSkillSubjectId] = useState<number>(0);
  const [skilltandardId, setSkilltandardId] = useState<number>(0);
  const [currentTab, setCurrentTab] = useState("Subject");
  const tabs: Array<any> = [{ name: "Subject" }, { name: "Game" }];

  return (
    <Fragment>
      <section className="min-w-0 w-full">
        <div className="px-4 py-5 lg:px-8 border-b border-gray-200">
          <div className="text-lg flex justify-between gap-2 h-auto sm:h-10 items-center">
            <h1 className="text-primary-violet font-medium flex justify-start">
              Skills Practice
            </h1>
          </div>
        </div>
        <div className="hidden sm:block ml-5">
          <div className="border-b border-gray-200">
            <nav className="-mb-px flex space-x-8" aria-label="Tabs">
              {tabs.map((tab) => (
                <a
                  key={tab.name}
                  href={tab.href}
                  className={classNames(
                    currentTab === tab.name
                      ? "border-indigo-500 text-indigo-600"
                      : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                    "whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
                  )}
                  aria-current={tab.current ? "page" : undefined}
                  onClick={() => {
                    setCurrentTab(tab.name);
                  }}
                >
                  {tab.name}
                </a>
              ))}
            </nav>
          </div>
        </div>

        {/* tab content */}
        <div className="p-1">
          <div>
            {currentTab === "Subject" && (
              <div>
                <div className="p-5">
                  <SkillSubject
                    onSubjectSelected={(skillSubjectId: number) => {
                      setSkillSubjectId(skillSubjectId);
                    }}
                  />
                </div>
                <div className="p-5 pt-2">
                  <SkillStandard
                    skillSubjectId={skillSubjectId}
                    onStandardSelected={(skillStandardId: number) => {
                      setSkilltandardId(skillStandardId);
                    }}
                  />
                </div>
              </div>
            )}
            {currentTab === "Game" && (
              <div>
                <GameChoose standardId={skilltandardId} gradeId={0} />
              </div>
            )}
          </div>
          <div className="justify-end flex border-t py-2">
            <button
              disabled={currentTab === "Subject"}
              className={classNames(
                "disabled:opacity-50 bg-transparent border border-primary-violet shadow-sm py-2 px-4 inline-flex justify-center items-center text-sm font-medium text-primary-violet ",
                currentTab !== "Subject"
                  ? "hover:text-white hover:bg-light-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3 transition ease-in-out duration-500"
                  : "cursor-not-allowed"
              )}
              onClick={() => {
                setCurrentTab("Subject");
              }}
            >
              Prev
            </button>
            <button
              disabled={currentTab === "Game"}
              className={classNames(
                "disabled:opacity-50 ml-2 bg-transparent border border-primary-violet shadow-sm py-2 px-4 inline-flex justify-center items-center text-sm font-medium text-primary-violet ",
                currentTab !== "Game"
                  ? "hover:text-white hover:bg-light-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3 transition ease-in-out duration-500"
                  : "cursor-not-allowed"
              )}
              onClick={() => {
                setCurrentTab("Game");
              }}
            >
              Next
            </button>
          </div>
        </div>
      </section>
    </Fragment>
  );
}

export default Home;

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  BellIcon,
  CaretUpSolidIcon,
  CaretDownSolidIcon,
  EditIcon,
} from "../../../../assets/icons";
import { getBellRingerAll } from "../../../../api/teacher/bellRinger";
import PageSize from "../../../shared/pagination/pageSize";
import Pagination from "../../../shared/pagination/pagination";
import {
  DEFAULT_PAGE_NUMBER,
  DEFAULT_PAGE_SIZE,
} from "../../../../utils/pagingConstant";
import constant from "../../../../utils/constant/routeConstant";
import { Link } from "react-router-dom";
import Permissions from "../../../../utils/permissions";
import AllowedTo from "../../../shared/rbac";
import AccessDenied from "../../../shared/accessDenied";

function BellRingerLanding(props) {
  const [pageNumber, setPageNumber] = useState(DEFAULT_PAGE_NUMBER);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [totalRecords, setTotalRecords] = useState(0);
  const [bellRingers, setBellRingers] = useState<Array<any>>([]);
  const [resetPageNumber, setResetPageNumber] = useState<boolean>(false);
  const [orderByColumn, setOrderByColumn] = useState<string>("name");
  const [sortByDesc, setSortByDesc] = useState<boolean>(false);

  useEffect(() => {
    getAllBellRinger(props.userContext.userId);
  }, [pageNumber, pageSize, sortByDesc]);

  const getAllBellRinger = async (teacherId) => {
    const resp = await getBellRingerAll(
      teacherId,
      pageNumber,
      pageSize,
      orderByColumn,
      sortByDesc,
      props.subjectId
    );
    if (resp && resp.data && resp.data.data && resp.data.data.length) {
      setBellRingers(resp.data.data);
      setTotalRecords(resp.data.totalRecords);
    }
  };

  const onPageChange = (pageNo) => {
    setPageNumber(pageNo);
  };

  const onPageSizeChange = (pageSize) => {
    setPageNumber(DEFAULT_PAGE_NUMBER);
    setPageSize(pageSize);
    setResetPageNumber(true);
  };

  const handleSortBy = (column: string) => {
    setSortByDesc(!sortByDesc);
    setOrderByColumn(column);
  };

  return (
    <div className="bg-gray-100 rounded-lg p-5">
      <div className="shadow hover:shadow-lg bg-white py-5 px-5 xl:px-2 1xl:px-5">
        <AllowedTo
          perform={Permissions.bell_ringer_view}
          no={() => <AccessDenied />}
        >
          <AllowedTo perform={Permissions.bell_ringer_create}>
            <div className="flex justify-end">
              <Link
                to={
                  constant.TeacherRoutes.BellRingerNew +
                  `?subjectId=${props.subjectId}`
                }
                className="bg-transparent group border border-primary-violet shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-primary-violet hover:text-white hover:bg-light-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3 transition ease-in-out duration-500"
              >
                <BellIcon className="w-5 mr-1 text-primary-violet group-hover:text-white" />
                New Bell Ringer
              </Link>
            </div>
          </AllowedTo>

          <div className="mt-5 overflow-x-auto shadow">
            <table className="table w-full ">
              <thead className="mb-5 border border-light-violet">
                <tr className="bg-light-violet  text-white py-3 px-3 overflow-hidden">
                  <th className="text-left p-3 font-normal">
                    <span className="flex">
                      Name
                      <span className="cursor-pointer">
                        {sortByDesc && orderByColumn === "name" ? (
                          <CaretUpSolidIcon
                            onClick={() => handleSortBy("name")}
                            className="w-5 h-5"
                          ></CaretUpSolidIcon>
                        ) : (
                          <CaretDownSolidIcon
                            onClick={() => handleSortBy("name")}
                            className="w-5 h-5"
                          ></CaretDownSolidIcon>
                        )}
                      </span>
                    </span>
                  </th>

                  <th className="text-left p-3 font-normal">
                    <span className="flex">
                      Domain
                      <span className="cursor-pointer">
                        {sortByDesc && orderByColumn === "domain" ? (
                          <CaretUpSolidIcon
                            onClick={() => handleSortBy("domain")}
                            className="w-5 h-5"
                          ></CaretUpSolidIcon>
                        ) : (
                          <CaretDownSolidIcon
                            onClick={() => handleSortBy("domain")}
                            className="w-5 h-5"
                          ></CaretDownSolidIcon>
                        )}
                      </span>
                    </span>
                  </th>

                  <th className="text-left p-3 font-normal">
                    <span className="flex">
                      Subject
                      <span className="cursor-pointer">
                        {sortByDesc && orderByColumn === "subject" ? (
                          <CaretUpSolidIcon
                            onClick={() => handleSortBy("subject")}
                            className="w-5 h-5"
                          ></CaretUpSolidIcon>
                        ) : (
                          <CaretDownSolidIcon
                            onClick={() => handleSortBy("subject")}
                            className="w-5 h-5"
                          ></CaretDownSolidIcon>
                        )}
                      </span>
                    </span>
                  </th>

                  <th className="text-left p-3 font-normal">
                    <span className="flex">
                      Standard
                      <span className="cursor-pointer">
                        {sortByDesc && orderByColumn === "standard" ? (
                          <CaretUpSolidIcon
                            onClick={() => handleSortBy("standard")}
                            className="w-5 h-5"
                          ></CaretUpSolidIcon>
                        ) : (
                          <CaretDownSolidIcon
                            onClick={() => handleSortBy("standard")}
                            className="w-5 h-5"
                          ></CaretDownSolidIcon>
                        )}
                      </span>
                    </span>
                  </th>

                  <th className="text-left p-3 font-normal">
                    <span className="flex">
                      Question Type
                      <span className="cursor-pointer">
                        {sortByDesc && orderByColumn === "questionTypeName" ? (
                          <CaretUpSolidIcon
                            onClick={() => handleSortBy("questionTypeName")}
                            className="w-5 h-5"
                          ></CaretUpSolidIcon>
                        ) : (
                          <CaretDownSolidIcon
                            onClick={() => handleSortBy("questionTypeName")}
                            className="w-5 h-5"
                          ></CaretDownSolidIcon>
                        )}
                      </span>
                    </span>
                  </th>

                  <th className="text-center p-3 font-normal">Edit</th>
                </tr>
              </thead>

              <tbody>
                {bellRingers?.map((bellRinger, i) => {
                  return (
                    <tr
                      key={i}
                      className={`hover:bg-primary-violet/20 transition ease-in-out duration-300 ${
                        i % 2 === 0 ? "bg-white" : "bg-light-violet/10"
                      }`}
                    >
                      <td className="text-sm p-3">
                        <Link
                          className="text-blue-500 underline hover:no-underline whitespace-nowrap"
                          to={
                            constant.TeacherRoutes.BellRingerEdit.replace(
                              ":id",
                              bellRinger.bellRingerId
                            ) + `?subjectId=${props.subjectId}`
                          }
                        >
                          {bellRinger.name}
                        </Link>
                      </td>
                      <td className="text-sm p-3">{bellRinger.domainName}</td>
                      <td className="text-sm p-3">{bellRinger.subjectName}</td>
                      <td className="text-sm p-3">
                        {`[${bellRinger.standardCode}] ${bellRinger.standardName}`}
                      </td>
                      <td className="text-sm p-3">
                        {bellRinger.questionTypeName}
                      </td>
                      <td className="p-3">
                        <AllowedTo perform={Permissions.bell_ringer_modify}>
                          <Link
                            to={
                              constant.TeacherRoutes.BellRingerEdit.replace(
                                ":id",
                                bellRinger.bellRingerId
                              ) + `?subjectId=${props.subjectId}`
                            }
                          >
                            <EditIcon
                              title="Edit bell ringer"
                              className="mx-auto w-4 h-4 cursor-pointer text-gray-500 hover:text-gray-700 transaction ease-in-out duration-300"
                            />
                          </Link>
                        </AllowedTo>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>

            {bellRingers.length === 0 && (
              <div className="p-3">No records found</div>
            )}
          </div>

          <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between border-t-2 pt-4 mt-5">
            {totalRecords !== 0 && (
              <Pagination
                totalRecords={totalRecords}
                pageSize={pageSize}
                onPageChange={onPageChange}
                reset={resetPageNumber}
                pageNumber={pageNumber}
              />
            )}

            {totalRecords !== 0 && (
              <PageSize
                onPageSizeChange={onPageSizeChange}
                pageSize={pageSize}
              />
            )}
          </div>
        </AllowedTo>
      </div>
    </div>
  );
}

export default connect((state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
})(BellRingerLanding);

import { Fragment, useEffect, useRef, useState } from "react";
import { XIcon } from "@heroicons/react/solid";
import Assessment from "../../../../model/teacher/assessment/assessment";
import { deleteAssessments } from "../../../../api/teacher/assessment";
import Loader from "../../../shared/loader";
import { toast } from "react-toastify";

interface AssessmentStatProps {
  userId: number;
  assessments: Array<Assessment>;
  togglePopup: (popupAction: boolean, refreshData: boolean) => void;
}

function DeleteAssessment(props: AssessmentStatProps) {
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const { userId, assessments, togglePopup } = props;
  const [keywordText, setKeywordText] = useState<string>("");

  function handleDelete() {
    if (keywordText.trim().toLocaleLowerCase() === "delete") {
      const authoredAssessments: Array<Assessment> = assessments.filter(
        (r) => r.authorId === userId
      );
      if (authoredAssessments.length > 0) {
        setShowLoading(true);
        deleteAssessments(
          authoredAssessments.map((r) => r.assessmentId).join(",")
        ).then((response) => {
          setShowLoading(false); togglePopup(false, response.data);
          if(!response.data) {
            toast.error("Can not delete assessment(s)");
          }
        });
      }
    } else {
      inputRef.current?.focus();
      return false;
    }
  }

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    inputRef.current?.focus();
  });

  return (
    <Fragment>
      {showLoading && <Loader></Loader>}
      <div className="fixed inset-0 z-40 flex items-center bg-black bg-opacity-50 sm:items-center sm:justify-center">
        <div
          className="w-1/3 flex flex-col justify-between overflow-hidden h-auto max-h-80percent bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:max-w-6xl"
          role="dialog"
        >
          <header>
            {/* Header */}
            <div className="px-4 py-4 bg-gray-50">
              <div className="flex items-start justify-between space-x-3">
                <div className="space-y-1">
                  <div className="text-lg font-medium text-gray-900">
                    Are you sure you wish to delete this assessment?
                  </div>
                </div>
                <div className="h-7 flex items-center">
                  <button
                    type="button"
                    className="text-gray-400 hover:text-gray-500"
                    onClick={() => togglePopup(false, false)}
                  >
                    <span className="sr-only">Close</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
          </header>
          <div className="w-full relative overflow-auto px-4 py-1">
            <div className="mb-4 pb-4 overflow-x-auto xl:overflow-x-visible block text-sm text-gray-500">
              <span className="flex">
                This action will remove results from any associated assignments
                and delete all records and results from any users with whom you
                have shared this assessment.
              </span>

              <span className="text-red-600 ">
                This CANNOT be undone! You must type the word 'delete' in the
                box below to perform this action.
              </span>
              <input
                type="text"
                value={keywordText}
                name="Assignment-name"
                id="Assignment-name"
                autoComplete="off"
                onChange={(e) => {
                  setKeywordText(e.target.value);
                }}
                ref={inputRef}
                className="mt-8 shadow-sm focus:ring-secondary-teal focus:border-secondary-teal h-10 block w-full text-lg border border-gray-200 text-gray-500 px-3 rounded-sm"
              />
            </div>
          </div>
          <footer className="flex items-center justify-end px-4 py-4 sm:flex-row bg-gray-50 dark:bg-gray-800 mt-4 gap-4">
            <div className="space-x-3 flex justify-end">
              <button
                onClick={() => handleDelete()}
                className="bg-secondary-teal border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-dark-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3"
              >
                Delete
              </button>
            </div>
            <div className="space-x-3 flex justify-end">
              <button
                onClick={() => togglePopup(false, false)}
                className="bg-secondary-teal border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-dark-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3"
              >
                Don't Delete
              </button>
            </div>
          </footer>
        </div>
      </div>
    </Fragment>
  );
}

export default DeleteAssessment;

import { IInitalizeStudyPlanResponse } from "./../../model/student/studyplan/initializeStudyPlanRespons";
import { AxiosResponse } from "axios";
import { AxiosHelper } from "../../environment/axiosConfig";
import { IStudyPlanStandard } from "../../model/interface/studyPlanStandard";
import APIConstant from "../../utils/constant/apiConstant";
import { IStudyPlanSummary } from "../../model/interface/studyPlanSummary";
import {
  IDomainTest,
  ISummativeTest,
} from "../../model/interface/summativeTest/summativeTest";
import { LevelUpGradeResponse } from "../../model/interface/levelUpGradeResponse";
import { IStudyPlanSubjectStats } from "../../model/interface/studyPlanSubjectStats";
import { IStudyPlanFocusAreasStandard } from "../../model/interface/studyPlanFocusAreas";

export function getStudyPlanStandards(
  userId: number,
  subjectId: number,
  showDotRank: boolean = false,
  schoolId: number
): Promise<AxiosResponse<Array<IStudyPlanStandard>>> {
  return AxiosHelper.axiosInstance().get<Array<IStudyPlanStandard>>(
    APIConstant.StudyPlan_API.StudyPlanStandard,
    {
      params: {
        userId: userId,
        subjectId: subjectId,
        showDotRank: showDotRank,
        schoolId: schoolId
      },
    }
  );
}

export function getStudentStudyPlanSubjectStats(
  studentId: number,
  subjectId: number
): Promise<AxiosResponse<IStudyPlanSubjectStats>> {
  return AxiosHelper.axiosInstance().get<IStudyPlanSubjectStats>(
    APIConstant.StudyPlan_API.StudyPlanSubjectStats,
    {
      params: {
        studentId,
        subjectId
      },
    }
  );
}

export function getFocusAreas(
  userId: number,
  subjectId: number
): Promise<AxiosResponse<Array<IStudyPlanFocusAreasStandard>>> {
  return AxiosHelper.axiosInstance().get<Array<IStudyPlanFocusAreasStandard>>(
    APIConstant.StudyPlan_API.FocusAreas,
    {
      params: {
        studentId: userId,
        subjectId
      },
    }
  );
}

export function getStudyPlanResult(sessionId: string, schoolYearId?: number | null): Promise<any> {
  return AxiosHelper.axiosInstance().get<any>(
    APIConstant.StudyPlan_API.Result,
    {
      params: {
        sessionId: sessionId,
        schoolYearId: schoolYearId // Which DB to use
      },
    }
  );
}

export function initializeStudyPlan(
  userId: number,
  standardId: number,
  languageId: string,
  gameId: number,
  rocketId: number,
  blasterId: number,
  blasterType: string,
  classId: number | undefined,
  teacherId: number | undefined,
  gradeId: number
): Promise<AxiosResponse<IInitalizeStudyPlanResponse>> {
  return AxiosHelper.axiosInstance().post<IInitalizeStudyPlanResponse>(
    APIConstant.StudyPlan_API.Initialize,
    {
      userId: userId,
      standardId: standardId,
      languageId: languageId,
      gameId: gameId,
      rocketId: rocketId,
      blasterId: blasterId,
      blasterType: blasterType,
      classId: classId,
      teacherId: teacherId,
      gradeId: gradeId,
    }
  );
}

export function getStudyPlanSummary(
  userId: number,
  standardId: number
): Promise<AxiosResponse<Array<IStudyPlanSummary>>> {
  return AxiosHelper.axiosInstance().get<Array<IStudyPlanSummary>>(
    APIConstant.StudyPlan_API.Summary,
    {
      params: {
        userId: userId,
        standardId: standardId,
      },
    }
  );
}

export function completeStudyPlan(
  userId: number,
  sessionId: string,
  activitySessionId: number
): Promise<AxiosResponse<LevelUpGradeResponse>> {
  return AxiosHelper.axiosInstance().put<LevelUpGradeResponse>(
    APIConstant.StudyPlan_API.CompleteStudyPlan,
    {
      userId: userId,
      sessionId: sessionId,
      activitySessionId: activitySessionId,
    }
  );
}

export function updateStudyPlan(
  userId: number,
  sessionId: string,
  classId: number,
  teacherId: number,
  languageId: number
): Promise<AxiosResponse<number>> {
  return AxiosHelper.axiosInstance().put<number>(
    APIConstant.StudyPlan_API.UpdateStudyPlan,
    {
      userId: userId,
      sessionId: sessionId,
      classId: classId,
      teacherId: teacherId,
      languageId: languageId,
    }
  );
}

export function updateStudyPlanGameScore(
  userId: number,
  sessionId: string,
  gameScore: number,
  gameLevel: number,
  gameSettings?: string
): Promise<AxiosResponse<number>> {
  return AxiosHelper.axiosInstance().put<number>(
    APIConstant.StudyPlan_API.UpdateGameScore,
    {
      userId: userId,
      sessionId: sessionId,
      gameScore: gameScore,
      gameLevel: gameLevel,
      gameSettings: gameSettings,
    }
  );
}

export function getStudyPlanGameDetails(
  userId: number,
  sessionId: string
): Promise<any> {
  return AxiosHelper.axiosInstance().get<any>(
    APIConstant.StudyPlan_API.GetGameDetails,
    {
      params: { userId: userId, sessionId: sessionId },
    }
  );
}

export function getStudyPlanLastSession(
  userId: number,
  standardId: number,
  languageId: number
) {
  return AxiosHelper.axiosInstance().get<any>(
    APIConstant.StudyPlan_API.LastSession,
    {
      params: {
        userId: userId,
        standardId: standardId,
        languageId: languageId,
      },
    }
  );
}

//------------------[ Student Summative Test ]---------------------
//-----------------------------------------------------------------

export function getInProgressSummativeTests(
  userId: number,
  subjectId: number
): Promise<AxiosResponse<Array<ISummativeTest>>> {
  return AxiosHelper.axiosInstance().get<Array<ISummativeTest>>(
    APIConstant.Student_SummativeTest_API.SavedTest,
    {
      params: { userId: userId, subjectId: subjectId },
    }
  );
}

export function getSummativeLastSession(
  userId: number,
  subjectId: number,
  testSize: number,
  languageId: number
) {
  return AxiosHelper.axiosInstance().get<any>(
    APIConstant.Student_SummativeTest_API.LastSession,
    {
      params: {
        userId: userId,
        subjectId: subjectId,
        testSize: testSize,
        languageId: languageId,
      },
    }
  );
}

export function initializeSummativeTest(
  userId: number,
  subjectId: number,
  testSize: number,
  languageId: string,
  gameId: number,
  rocketId: number,
  blasterId: number,
  blasterType: string,
  gradeId: number,
  classId: number,
  teacherId: number
): Promise<AxiosResponse<IInitalizeStudyPlanResponse>> {
  return AxiosHelper.axiosInstance().post<IInitalizeStudyPlanResponse>(
    APIConstant.Student_SummativeTest_API.Initialize,
    {
      userId: userId,
      subjectId: subjectId,
      testSize: testSize,
      languageId: languageId,
      gameId: gameId,
      rocketId: rocketId,
      blasterId: blasterId,
      blasterType: blasterType,
      gradeId: gradeId,
      classId: classId,
      teacherUserId: teacherId,
    }
  );
}

export function completeSummativeStudyPlan(
  userId: number,
  sessionId: string,
  activitySessionId: number
): Promise<AxiosResponse<number>> {
  return AxiosHelper.axiosInstance().put<number>(
    APIConstant.Student_SummativeTest_API.Complete,
    {
      userId: userId,
      sessionId: sessionId,
      activitySessionId: activitySessionId,
    }
  );
}

export function getSummativeTestResult(
  sessionId: string,
  schoolYearId?: number | null
): Promise<any> {
  return AxiosHelper.axiosInstance().get<any>(
    APIConstant.Student_SummativeTest_API.Result,
    {
      params: { sessionId: sessionId, schoolYearId: schoolYearId },
    }
  );
}

export function updateSummativeTest(
  studentSummativeTestId: number,
  teacherId: number,
  classId: number
): Promise<any> {
  return AxiosHelper.axiosInstance().put<any>(
    APIConstant.Student_SummativeTest_API.Update,
    {
      studentSummativeTestId: studentSummativeTestId,
      teacherId: teacherId,
      classId: classId,
    }
  );
}

//------------------[ Student Domain Test ]---------------------
//-----------------------------------------------------------------
export function getInProgressDomainTests(
  userId: number,
  subjectId: number
): Promise<AxiosResponse<Array<IDomainTest>>> {
  return AxiosHelper.axiosInstance().get<Array<IDomainTest>>(
    APIConstant.Student_DomainTest_API.InProgress,
    {
      params: { userId: userId, subjectId: subjectId },
    }
  );
}

export function getDomainLastSession(
  userId: number,
  domainId: number,
  languageId: number
) {
  return AxiosHelper.axiosInstance().get<any>(
    APIConstant.Student_DomainTest_API.LastSession,
    {
      params: { userId: userId, domainId: domainId, languageId: languageId },
    }
  );
}

export function completeDomainTestStudyPlan(
  userId: number,
  sessionId: string,
  activitySessionId: number
): Promise<AxiosResponse<number>> {
  return AxiosHelper.axiosInstance().put<number>(
    APIConstant.Student_DomainTest_API.Complete,
    {
      userId: userId,
      sessionId: sessionId,
      activitySessionId: activitySessionId,
    }
  );
}

export function getDomainTestResult(
  sessionId: string,
  schoolYearId?: number | null
): Promise<any> {
  return AxiosHelper.axiosInstance().get<any>(
    APIConstant.Student_DomainTest_API.Result,
    {
      params: { sessionId: sessionId, schoolYearId: schoolYearId },
    }
  );
}

export function initializeDomainTest(
  userId: number,
  domainId: number,
  languageId: string,
  gameId: number,
  rocketId: number,
  blasterId: number,
  blasterType: string,
  gradeId: number,
  classId: number,
  teacherId: number,
  subjectId: number
): Promise<AxiosResponse<IInitalizeStudyPlanResponse>> {
  return AxiosHelper.axiosInstance().post<IInitalizeStudyPlanResponse>(
    APIConstant.Student_DomainTest_API.Initialize,
    {
      userId: userId,
      domainId: domainId,
      languageId: languageId,
      gameId: gameId,
      rocketId: rocketId,
      blasterId: blasterId,
      blasterType: blasterType,
      gradeId: gradeId,
      classId: classId,
      teacherUserId: teacherId,
      subjectId: subjectId,
    }
  );
}

export function updateDomainTest(
  studentDomainTestId: number,
  teacherId: number,
  classId: number
): Promise<any> {
  return AxiosHelper.axiosInstance().put<any>(
    APIConstant.Student_DomainTest_API.Update,
    {
      studentDomainTestId: studentDomainTestId,
      teacherId: teacherId,
      classId: classId,
    }
  );
}

export function getActivityStatus(refrenceId: string): Promise<any> {
  return AxiosHelper.axiosInstance().get<any>(
    APIConstant.StudyPlan_API.ActivityStatus,
    {
      params: { activityReference: refrenceId },
    }
  );
}

export function abandonStudyPlan(
  userId: number,
  sessionId: string
): Promise<AxiosResponse<number>> {
  return AxiosHelper.axiosInstance().put<number>(
    APIConstant.StudyPlan_API.AbandonStudyPlan,
    {
      userId: userId,
      sessionId: sessionId,
    }
  );
}

export function abandonDomainTest(
  userId: number,
  sessionId: string
): Promise<AxiosResponse<number>> {
  return AxiosHelper.axiosInstance().put<number>(
    APIConstant.Student_DomainTest_API.Abandon,
    {
      userId: userId,
      sessionId: sessionId,
    }
  );
}

export function abandonSummativeTest(
  userId: number,
  sessionId: string
): Promise<AxiosResponse<number>> {
  return AxiosHelper.axiosInstance().put<number>(
    APIConstant.Student_SummativeTest_API.Abandon,
    {
      userId: userId,
      sessionId: sessionId,
    }
  );
}

export function getVideoPracticeResult(sessionId: string, schoolYearId?: number | null): Promise<any> {
  return AxiosHelper.axiosInstance().get<any>(
    APIConstant.StudentVideoAssignment_API.Result,
    {
      params: {
        sessionId: sessionId,
        schoolYearId: schoolYearId
      },
    }
  );
}

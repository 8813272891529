import { AxiosResponse } from "axios";
import { AxiosHelper } from "../../../environment/axiosConfig";
import { IMyGalaxySubject } from "../../../model/interface/liftOff/myGalaxySubject";
import { IInitializeStandardAssignmentRespons } from "../../../model/student/mygalaxystandard/initializeStandardAssignmentRespons";
import APIConstant from "../../../utils/constant/apiConstant";

export const getMyGalaxySubject = (
  userId: number
): Promise<AxiosResponse<Array<IMyGalaxySubject>>> => {
  return AxiosHelper.axiosInstance().get<Array<IMyGalaxySubject>>(
    APIConstant.StudentLiftOffMyGalaxy_API.CMS_MyGalaxySubject_All,
    {
      params: {
        userId: userId,
      },
    }
  );
};

export function initializeStandardAssignment(
  userId: number,
  standardId: number,
  language: string,
  gameId: number,
  rocketId: number,
  blasterId: number,
  blasterType: string,
  isBegin: boolean,
  classId?: number,
  teacherId?: number,
  languageId?: number,
  isStandardGrouped?: any,
  gradeId?: number
): Promise<AxiosResponse<IInitializeStandardAssignmentRespons>> {
  return AxiosHelper.axiosInstance().post<IInitializeStandardAssignmentRespons>(
    APIConstant.StudentLiftOffMyGalaxy_API.Initialize,
    {
      userId: userId,
      standardId: standardId,
      language: language,
      gameId: gameId,
      rocketId: rocketId,
      blasterId: blasterId,
      blasterType: blasterType,
      isBegin: isBegin,
      classId: classId,
      teacherId: teacherId,
      languageId: languageId,
      isStandardGrouped: isStandardGrouped === "true" ? true : false,
      gradeId: gradeId,
    }
  );
}

export function getStandardAssignmentResult(
  userId: number,
  isStandardGrouped: boolean,
  standardId?: number,
  sessionId?: any
): Promise<AxiosResponse<any>> {
  return AxiosHelper.axiosInstance().get<any>(
    APIConstant.StudentLiftOffMyGalaxy_API.Result,
    {
      params: {
        userId: userId,
        standardId: standardId,
        isStandardGrouped: isStandardGrouped,
        sessionId: sessionId,
      },
    }
  );
}

export function updateMyGalaxyGameScore(
  userId: number,
  sessionId: string,
  gameScore: number,
  gameLevel: number,
  gameSettings?: string
): Promise<AxiosResponse<number>> {
  return AxiosHelper.axiosInstance().put<number>(
    APIConstant.StudentLiftOffMyGalaxy_API.UpdateGameScore,
    {
      userId: userId,
      sessionId: sessionId,
      gameScore: gameScore,
      gameLevel: gameLevel,
      gameSettings: gameSettings,
    }
  );
}

export function getMyGalaxyGameDetails(
  userId: number,
  sessionId: string
): Promise<any> {
  return AxiosHelper.axiosInstance().get<any>(
    APIConstant.StudentLiftOffMyGalaxy_API.GetGameDetails,
    {
      params: { userId: userId, sessionId: sessionId },
    }
  );
}

export function getStudentLiftOffMyGalaxyStandards(
  userId?: number,
  subjectId?: number
) {
  return AxiosHelper.axiosInstance().get(
    APIConstant.StudentLiftOffMyGalaxy_API.GetBySubjectId,
    {
      params: {
        userId: userId,
        subjectId: subjectId,
      },
    }
  );
}

export function getStudentLiftOffMyGalaxyLastSession(
  userId: number,
  standardId: number,
  isStandardGrouped: any,
  languageId: number
) {
  return AxiosHelper.axiosInstance().get(
    APIConstant.StudentLiftOffMyGalaxy_API.LastSession,
    {
      params: {
        userId: userId,
        standardId: standardId,
        isStandardGrouped: isStandardGrouped,
        languageId: languageId,
      },
    }
  );
}

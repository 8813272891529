import thinkorswimLogo from '../../../../assets/images/thinkorswim/thinkorswimLogo.jpg';

interface ThinkOrSwimButtonProps {
  onClick: () => void
}

function ThinkOrSwimButton(props: ThinkOrSwimButtonProps) {
  return (
    <li className="col-span-1 flex flex-col text-center bg-white rounded-lg shadow divide-y divide-gray-200 cursor-pointer hover:bg-gray-50">
      <div 
        className="flex-1 flex flex-col p-3"
        onClick={props.onClick}
      >
        <img 
          className="w-full h-25 flex-shrink-0 mx-auto"
          src={thinkorswimLogo}
          alt="Think or swim"
        />
        <dl className="mt-4 flex-grow flex flex-col justify-between">
          <dd className="text-gray-500 text-left text-sm">
            Split your class into teams for this projector-style trivia game!
          </dd>
        </dl>
      </div>
    </li>
  )
}

export default ThinkOrSwimButton

import { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import Profile from "../../../../../../model/users/profile";
import { UserContextState } from "../../../../../../redux/actions/userContextAction";
import QuestionAnswer from "../../../shared/questionAnswer";
import Constant from "../../../../../../utils/constant/constant";
import {
  completeVideoQuesion,
  getLastWithFullScore,
  getVideoQuestionLastSession,
  initializeVideoQuestion,
} from "../../../../../../api/student/studentVideoAssignmentApi";
import constant from "../../../../../../utils/constant/constant";
import { toast } from "react-toastify";
import routeConstant from "../../../../../../utils/constant/routeConstant";
import { useHistory } from "react-router-dom";
import Breadcrumb from "../../../middleSchool/breadcrumb";
import { IBreadcrumb } from "../../../../../../model/common/breadcrumb";
import { useTranslation } from "react-i18next";
import { getCalculatorBySubjectId } from "../../../../../../api/student/studentSubjectApi";
import BeginActivity from "../../../shared/beginActivity";
import ActivitySessionQuestionErrorPopup from "../../../shared/popups/activitySessionQuestionErrorPopup";
import {paramDecode, paramEncode} from "../../../../../../utils/urlHelper";
interface IVideoQuestionPractice {
  match: any;
  userContext: UserContextState;
  profile: Profile;
  location: any;
}

function VideoQuestionPractice(props: IVideoQuestionPractice) {
  const [videoPractice, setVideoPractice] = useState<any>({});
  const standardId = parseInt(atob(props.match.params.standardId));
  const standardName = paramDecode(props.match.params.standardName);
  const videoId = parseInt(atob(props.match.params.videoId));
  const subjectId = parseInt(atob(props.match.params.subjectId));
  const [calculatorReferenceId, setCalculatorReferenceId] = useState<string>();
  const history = useHistory();
  const { t } = useTranslation();
  const [beginActivityModal, setBeginActivityModal] = useState<any>({
    open: false,
    data: undefined,
  });
  const [selectedLanguage, setSelectedLanguage] = useState<number>(1);
  const [lastSession, setLastSession] = useState<any>({});
  const videoTitle = props.location?.state?.videoTitle ?? "Video Practice";
  const [showActivtySessionError, setShowActivitySessionError] =
    useState<boolean>(false);
  const [contentAreaId, setContentAreaId] = useState<number>(0);
  const [isPreview, setIsPreview] = useState<boolean>(true);

  useEffect(() => {
    getPreviousVideoAssignment();
    if (subjectId > 0) {
      getCalculatorBySubjectId(subjectId).then((res) => {
        if (res.data) setCalculatorReferenceId(res.data);
      });
    }
  }, []);

  function getPreviousVideoAssignment() {
    const getLastSessionRequest = getVideoQuestionLastSession(
      props.userContext.userId,
      videoId,
      0
    );

    Promise.all([
      getLastSessionRequest
    ]).then(
      ([lastSessionResponse]) => {
        const data = lastSessionResponse.data;
        setLastSession(data);
        setVideoPractice(data);
        setIsPreview(data?.assignmentStatusId === constant.AssignmentStatusId.COMPLETE
            && data?.score === 100);
        setBeginActivityModal({open: true,  data: data });
      }
    )
  }

  async function getLastVideoWithFullScore(languageId: number) {
    const response = await getLastWithFullScore(
      props.userContext.userId,
      videoId,
      languageId
    );
    setVideoPractice(response.data);
    setBeginActivityModal({ ...beginActivityModal, open: false });
  }

  function initializeTest(data: any) {
    initializeVideoQuestion(
      props.userContext.userId,
      videoId,
      standardId,
      data.languageId === constant.Languages.Spanish ? "es" : "en",
      props.userContext?.gradeId
    ).then((d) => {
      if (d.data.hasPendingSession) {
        setTimeout(() => {
          setVideoPractice(d.data);
        }, 1000);
      } else if (!d.data.sessionId) {
        setShowActivitySessionError(true);
      } else {
        setVideoPractice(d.data);
      }
      setBeginActivityModal({ ...beginActivityModal, open: false });
    });
  }

  function getRedirectUrl() {
    return routeConstant.MiddleSchool.VideoQuestionList
      .replace(":standardId", encodeURI(btoa(standardId.toString())))
      .replace(":standardName", paramEncode(standardName))
      .replace(":subjectId", encodeURI(btoa(subjectId.toString())));
  }

  async function onBeginActivity(data: any, value: any) {
    setContentAreaId(value.contentAreaId);
    setSelectedLanguage(value.languageId);
    if (isPreview) {
      return await getLastVideoWithFullScore(value.languageId);
    }
    if (
      (lastSession === null ||
      lastSession === "" ||
      lastSession === undefined ||
      lastSession.assignmentStatusId === constant.AssignmentStatusId.COMPLETE ||
      data.languageId !== value.languageId)
    ) {
      initializeTest(value);
    } else {
      setVideoPractice(data);
      setBeginActivityModal({ ...beginActivityModal, open: false });
    }
  }

  function completeVideoPracticeActivity() {
    completeVideoQuesion(
      props.userContext.userId,
      videoPractice.sessionId,
      videoPractice.activitySessionId
    ).then((d) => {
      toast.success("Video Practice Completed");
      history.push(
        routeConstant.MiddleSchool.VideoResult.replace(
          ":sessionId",
          videoPractice ? videoPractice.sessionId : ""
        )
      );
    });
  }
  const breadcrumbItems = () => {
    const items: Array<IBreadcrumb> = [
      {
        name: t("Breadcrumb.Home"),
        url: routeConstant.ASSIGNMENTS_MS,
        textColor: "",
      },
      {
        name: t("Breadcrumb.StudyPlanSubjects"),
        textColor: "",
        url: routeConstant.SUBJECTS_MS,
      },
      {
        name: t("Breadcrumb.StudyPlanStandards"),
        textColor: "",
        url: routeConstant.MiddleSchool.StudyPlanStandards.replace(
          ":id",
          subjectId.toString()
        ),
      },
      {
        name: t("Breadcrumb.VideoLearn"),
        textColor: "",
        url: routeConstant.MiddleSchool.VideoQuestionList.replace(
          ":standardId",
          encodeURI(btoa(standardId.toString()))
        )
          .replace(":standardName", paramEncode(standardName))
          .replace(":subjectId", encodeURI(btoa(subjectId.toString()))),
      },
      {
        name: t("Breadcrumb.VideoPractice"),
        textColor: "",
        url: "",
      },
    ];

    return items;
  };

  return (
    <Fragment>
      <div data-id={standardId}>
        <div className="md:flex md:items-center px-4 py-3 lg:px-8 border-b">
          <Breadcrumb items={breadcrumbItems()} />
          <div className="md:w-[55%] text-lg flex justify-between gap-2 h-auto sm:h-10 items-center">
            <h1 className="text-gray-50 font-medium flex justify-start">
              {t("StudyPlan.VideoPractice")}
            </h1>
          </div>
        </div>

        {!beginActivityModal.open &&
          videoPractice !== undefined &&
          videoPractice.sessionId &&
          videoPractice.sessionId.length > 0 && (
            <QuestionAnswer
              activityType={Constant.ActivityType.STUDYPLAN}
              activityId={
                videoPractice.activityId ? videoPractice.activityId : ""
              }
              teacherActivityId={videoPractice.sessionId}
              showActivity={videoPractice.activityId?.length > 0 ? true : false}
              showQuestions={
                videoPractice.activityId?.length > 0 ? false : true
              }
              questionIds={
                videoPractice.itemReferences &&
                videoPractice.itemReferences.length > 0
                  ? videoPractice.itemReferences
                  : []
              }
              hasSections={false}
              hasItemBranching={false}
              isAlienExplanation={false}
              isGameChoosen={false}
              studentId={props.userContext.userId.toString()}
              resumeActivity={videoPractice.hasPendingSession}
              sessionId={videoPractice.sessionId}
              studentType="MS"
              title={standardName}
              adaptiveFilter={null}
              OnSubmit={completeVideoPracticeActivity}
              onExitRedirectUrl={isPreview 
                ? getRedirectUrl()
                : routeConstant.MiddleSchool.Home
              }
              activitySubType={Constant.ActivitySubTypes.VIDEO}
              calculatorReferenceId={calculatorReferenceId}
              questionRetry={false}
              showExplanation={false}
              activitySessionId={videoPractice.activitySessionId}
              language={selectedLanguage === 2 ? "es" : "en"}
              standardId={standardId.toString()}
              contentAreaId={contentAreaId}
              isVideoPreview={isPreview}
              subjectId={subjectId}
            ></QuestionAnswer>
          )}
        {beginActivityModal.open && (
          <BeginActivity
            activity={t("Assignments.Video")}
            name={videoTitle}
            noOfQuestions={1}
            source={"StudyPlan"}
            themeName={"MIDDLESCHOOLHOME"}
            data={beginActivityModal.data}
            hideClass={true}
            hideTeacher={true}
            ActivityLanguageLevel={"UserLevel"}
            onBegin={onBeginActivity}
            subjectId={subjectId}
          />
        )}
        {showActivtySessionError && (
          <ActivitySessionQuestionErrorPopup
            showPopup={(show) => {
              setShowActivitySessionError(show);
            }}
            studentType="middleschool"
          ></ActivitySessionQuestionErrorPopup>
        )}
      </div>
    </Fragment>
  );
}

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(VideoQuestionPractice);

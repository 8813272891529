import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/database";
import { FirebaseConfiguration } from "../../environment/setup";
import constant from "../../utils/constant/constant";
import APIConstant from "../../utils/constant/apiConstant";

export class MessageApi {
  public static initializeFirebase = () => {
    firebase.initializeApp(FirebaseConfiguration);
  };

  public static getOnlineUsersRef = (schoolId: number) => {
    MessageApi.initializeFirebase();
    const ref = firebase.database().ref(`/${schoolId + "/online-users"}`);
    return ref;
  };

  public static getRecentChatRef = () => {
    MessageApi.initializeFirebase();
    const ref = firebase
      .firestore()
      .collection(APIConstant.Message_API.RecentChat);
    return ref;
  };

  public static handleOnActive = (userId: number, schoolId: number) => {
    MessageApi.initializeFirebase();
    const reference = firebase
      .database()
      .ref(
        `${
          APIConstant.Message_API.OnlineUsers.replace(
            "{:schoolId}",
            schoolId.toString()
          ) + userId.toString()
        }`
      );
    reference.set(constant.USERACTIVITY.Active);
    reference.onDisconnect().remove();
  };

  public static handleOnIdle = (userId: number, schoolId: number) => {
    MessageApi.initializeFirebase();
    const reference = firebase
      .database()
      .ref(
        `${
          APIConstant.Message_API.OnlineUsers.replace(
            "{:schoolId}",
            schoolId.toString()
          ) + userId.toString()
        }`
      );
    reference.set(constant.USERACTIVITY.Ideal);
    reference.onDisconnect().remove();
  };
}

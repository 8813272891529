import { Modal, ModalBody, ModalHeader } from "@windmill/react-ui";
import { Fragment, useState } from "react";
import { useHistory } from "react-router-dom";
import UpGradeMessagePopup from "../../../shared/upGradeMessagePopup";
import constant from "../../../../utils/constant/constant";
interface AssessmentCardProps {
  title: string;
  thumbnailUrl: string;
  videoUrl: string;
  items: Array<{
    name: string;
    url: string;
    isSchoolAssignment?: boolean;
    upgrade?: boolean;
    showUpgradePopup?: boolean;  
    upgradeMessage?: string;  
  }>;
  cardColor: string;
  tooltip?: string;
  headerLabel: string;
  headerColor: string;
  copy: string;
}

function AssignmentCenterCard(props: AssessmentCardProps) {
  const history = useHistory();
  const { videoUrl, items, title } = props;
  const [showVideo, setShowVideo] = useState(false);
  const [showUpgradePopup, setShowUpgradePopup] = useState<boolean>(false);
  const [upgradeMessage, setUpgradeMessage] = useState<string>(constant.UPGRADE_MESSAGE_SCHOOL_ACCOUNT);

  const classes =
    [
      "text-xl font-semibold text-white flex items-center px-8 py-2 rounded-t-lg ",
    ].join(" ") + props.cardColor;
  const headerClass =
    ["text-xl font-bold items-center px-6 py-2 text-center "].join(" ") +
    props.headerColor;

  const handleRedirect = (url: string, isSchoolAssessment: boolean) => {
    history.push({
      pathname: url,
      state: {
        isSchoolAssessment: isSchoolAssessment,
      },
    });
  };

  return (
    <Fragment>
      {showUpgradePopup && (
        <UpGradeMessagePopup
          togglePopup={setShowUpgradePopup}
          roleId={constant.UserRoleId.FreeTeacher}
          message={upgradeMessage}
        />
      )}
      <div className="relative flex flex-col flex-grow ">
        <div className={headerClass}>{props.headerLabel}</div>
        <div className="flex-1 bg-white rounded-lg shadow-lg hover:shadow-2xl flex flex-col">
          <h3 className={classes} style={{ display: "block" }}>
            <span>{props.title}</span>
            <div className="font-thin" style={{ fontSize: "0.7rem" }}>
              {props.copy}
            </div>
          </h3>

          <ul className="my-6 space-y-6 px-8 lg:px-6 xl:px-8">
            {items.map((item, index) => {
              return (
                <Fragment key={index}>
                  <li>
                    {item.upgrade && (
                      <a
                        onClick={() => {
                          if (item.showUpgradePopup) {
                            setUpgradeMessage(item.upgradeMessage??constant.UPGRADE_MESSAGE_SCHOOL_ACCOUNT)
                            setShowUpgradePopup(true);                            
                          }
                        }}
                        className="flex items-center text-gray-800 group border-b border-gray-200  pb-3 cursor-not-allowed bg-opacity-200"
                      >
                        <span className="text-gray-400  mr-1  group-hover:border-primary-violet  group-hover:opacity-100 inset-0 transform  transition duration-300">
                          {item.name}
                        </span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          className="lex-shrink-0 w-5 h-5 text-primary-violet group-hover:shadow-lg opacity-0 group-hover:opacity-100 inset-0 transform group-hover:translate-x-2 transition duration-300"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z" />
                        </svg>
                      </a>
                    )}
                    {!item.upgrade && (
                      <a
                        onClick={() =>
                          handleRedirect(
                            item.url,
                            item.isSchoolAssignment ?? false
                          )
                        }
                        className="flex items-center text-gray-800 hover:text-primary-violet group border-b border-gray-200 hover:border-primary-violet pb-3"
                      >
                        <span className="mr-1  group-hover:border-primary-violet  group-hover:opacity-100 inset-0 transform  transition duration-300">
                          {item.name}
                        </span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          className="lex-shrink-0 w-5 h-5 text-primary-violet group-hover:shadow-lg opacity-0 group-hover:opacity-100 inset-0 transform group-hover:translate-x-2 transition duration-300"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z" />
                        </svg>
                      </a>
                    )}
                  </li>
                </Fragment>
              );
            })}
          </ul>
        </div>
        <Modal
          isOpen={showVideo}
          onClose={() => setShowVideo(false)}
          className="w-1/2 bg-white rounded-lg p-2"
        >
          <ModalHeader></ModalHeader>
          <ModalBody>
            <div className="relative w-full overflow-hidden pt-[56.25%]">
              <iframe
                title={`${title}`}
                width="690"
                height="360"
                className="px-5 absolute top-0 left-0 bottom-0 right-0 w-full h-full"
                src={`https://fast.wistia.net/embed/iframe/${videoUrl}`}
              ></iframe>
            </div>
          </ModalBody>
        </Modal>
      </div>
    </Fragment>
  );
}

export default AssignmentCenterCard;

import React, { useEffect, useState } from "react";
import Loader from "../../../shared/loader";
import PageSize from "../../../shared/pagination/pageSize";
import Pagination from "../../../shared/pagination/pagination";
import PagingResponse from "../../../../model/common/pagingResponse";
import tagListResponse from "../../../../model/teacher/tags/tagListResponse";
import tagSearchModel from "../../../../model/teacher/tags/tagSearchModel";
import {
  DEFAULT_PAGE_NUMBER,
  DEFAULT_PAGE_SIZE,
} from "../../../../utils/pagingConstant";
import {
  getAllUserTags,
  addUserTags,
  updateUserTags,
  deleteUserTag,
} from "../../../../api/teacher/tags";
import TagDetailPopup from "./popups/tagDetailPopup";
import userTag from "../../../../model/teacher/tags/userTag";
import { UserContextState } from "../../../../redux/actions/userContextAction";
import { connect } from "react-redux";
import { EditIcon, DeleteIcon } from "../../../../assets/icons/index";
import { useConfirmation } from "../../../shared/confirmation/confirmationService";
import getColors from "../../../shared/tagColor";
import { toast } from "react-toastify";
import Permissions from "../../../../utils/permissions";
import AllowedTo from "../../../shared/rbac";
import AccessDenied from "../../../shared/accessDenied";
import Breadcrumb from "../../teacher/shared/breadcrumb";
import { IBreadcrumb } from "../../../../model/common/breadcrumb";
import RouteConstant from "../../../../utils/constant/routeConstant";
import { useTranslation } from "react-i18next";

interface ManageTagsProps {
  userContext: UserContextState;
}

const ManageTags = (props: ManageTagsProps) => {
  const defaultColor = "#F3F4F6";
  const { t } = useTranslation();
  const defaultFilter: tagSearchModel = {
    userTagId: 0,
    userId: props.userContext?.userId,
    name: "",
    isActive: true,
    categoryId: 0,
    pageNumber: DEFAULT_PAGE_NUMBER,
    pageSize: DEFAULT_PAGE_SIZE,
    orderByColumn: "",
    sortByDesc: false,
  };
  const defaultTag: userTag = {
    userTagId: 0,
    name: "",
    isActive: true,
    description: "",
    color: defaultColor,
    categoryId: 1,
    userId: props.userContext?.userId,
  };

  const confirm = useConfirmation();
  const [loading, showLoading] = useState<boolean>(false);
  const [isActive, setIsActive] = useState<boolean>(defaultFilter.isActive);
  //const [pageNumber, setPageNumber] = useState(DEFAULT_PAGE_NUMBER);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [resetPageNumber, setResetPageNumber] = useState<boolean>(false);
  const [tags, setTags] = useState<PagingResponse<tagListResponse>>();
  const [filter, setFilter] = useState<tagSearchModel>(defaultFilter);
  const [showTagDetailPopup, setShowTagDetailPopup] = useState(false);
  const [tagDetail, setTagDetail] = useState<userTag>(defaultTag);
  const [userTagId, setUserTagId] = useState(0);
  const [tagName, setTagName] = useState("");

  useEffect(() => {
    getTags(defaultFilter);
  }, []);

  const getTags = (filter: tagSearchModel) => {
    showLoading(true);
    getAllUserTags(filter)
      .then((r) => {
        setTags(r.data);
        showLoading(false);
      })
      .catch(() => {
        showLoading(false);
      });
  };

  function getTextColor(colorCode?: string) {
    let textColor = "text-black ";
    const selectedColor = getColors.filter((r) => r.code === colorCode);
    if (selectedColor && selectedColor.length > 0) {
      if (selectedColor[0].isTextWhite) {
        textColor = "text-white ";
      }
    }

    return textColor;
  }

  const handleNameChange = (value) => {
    filter.name = value;
    setTagName(value);
    setFilter(filter);
  };

  const handleIdChange = (value) => {
    const id = Number.isNaN(parseInt(value)) ? 0 : parseInt(value);
    filter.userTagId = id;
    setUserTagId(id);
    setFilter(filter);
  };

  const handleIsActiveChange = (value) => {
    filter.isActive = value;
    setIsActive(!isActive);
    setFilter(filter);
  };

  const handleSearch = () => {
    getTags(filter);
  };

  const handleReset = () => {
    setFilter(defaultFilter);
    setTagName("");
    setUserTagId(0);
    setIsActive(true);
  };

  const handleCreateNewTagClick = () => {
    setTagDetail(defaultTag);
    setShowTagDetailPopup(true);
  };

  const handleEditTagClick = (tag: any) => {
    const temptag: userTag = {
      userTagId: tag.userTagId,
      name: tag.name,
      isActive: tag.isActive,
      description: tag.description,
      color: tag.color,
      categoryId: tag.categoryId,
      userId: props.userContext?.userId,
    };
    setTagDetail(temptag);
    setShowTagDetailPopup(true);
  };

  const handleDeleteTagClick = (tag: any) => {
    confirm({
      variant: "danger",
      title: "Please Confirm",
      description: "Are you sure you want to delete the selected tag.?",
    }).then((d) => {
      deleteTag(tag.userTagId);
    });
  };

  const saveTag = (tag: userTag) => {
    setShowTagDetailPopup(false);
    if (tag.userTagId > 0) {
      editTag(tag);
    } else {
      addTag(tag);
    }
  };

  const addTag = (tag: userTag) => {
    showLoading(true);
    addUserTags(tag).then(() => {
      showLoading(false);
      getTags(filter);
      toast.success("Tags saved successfully.");
    });
  };

  const editTag = (tag: userTag) => {
    showLoading(true);
    updateUserTags(tag).then(() => {
      showLoading(false);
      getTags(filter);
      toast.success("Tags saved successfully.");
    });
  };

  const deleteTag = (userTagId: number) => {
    showLoading(true);
    deleteUserTag(userTagId).then(() => {
      showLoading(false);
      getTags(filter);
      toast.success("Tags deleted successfully.");
    });
  };

  const onPageChange = (pageNo) => {
    //setPageNumber(pageNo);
    filter.pageNumber = pageNo;
    setFilter(filter);
    getTags(filter);
  };

  const onPageSizeChange = (pageSize) => {
    // setPageNumber(DEFAULT_PAGE_NUMBER);
    setPageSize(pageSize);
    setResetPageNumber(true);
    filter.pageSize = pageSize;
    setFilter(filter);
    getTags(filter);
  };
  const breadcrumbItems = () => {
    const items: Array<IBreadcrumb> = [
      { name: t("Breadcrumb.Home"), url: RouteConstant.ROUTE_DASHBOARD },
      {
        name: t("Breadcrumb.TagManagement"),
        url: "",
      },
    ];

    return items;
  };

  return (
    <AllowedTo perform={Permissions.tags_view} no={() => <AccessDenied />}>
      <section className="min-w-0 w-full">
        <div className="lg:flex lg:items-center px-4 py-3 lg:px-8 border-b border-gray-200">
          <Breadcrumb items={breadcrumbItems()} />
          <div className="lg:w-[55%] text-lg flex justify-between gap-2 h-auto sm:h-10 items-center">
            <h1 className="text-primary-violet font-medium flex justify-start">
              Tag Management
            </h1>
          </div>
        </div>

        {/* Grid */}
        <div className="px-2 sm:px-6  lg:px-8 py-1 relative my-4">
          {loading && <Loader />}
          <div className=" mx-auto lg:grid lg:grid-cols-12 lg:gap-8">
            <div className="min-w-0 block lg:col-span-12 xl:col-span-12 w-full">
              <AllowedTo perform={Permissions.tags_create}>
                <div className="flex justify-end mb-4">
                  <button
                    onClick={handleCreateNewTagClick}
                    type="button"
                    title="Create New Tag"
                    className="bg-primary-violet border border-transparent shadow-sm py-2 px-4 justify-end text-sm font-medium text-white hover:bg-dark-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 whitespace-nowrap"
                  >
                    <span className="whitespace-nowrap">Create New Tag</span>
                  </button>
                </div>
              </AllowedTo>
              <div className="bg-gray-100 w-full rounded-lg p-5">
                <div className="grid grid-cols-12 flex flex-col sm:flex-row sm:items-center sm:justify-start gap-4 mb-4">
                  <div className="col-span-12 md:col-span-4 xl:col-span-3 xxl:col-span-2 xxxl:col-span-2">
                    <label className="block text-sm text-gray-500"> Id</label>
                    <input
                      type="number"
                      name="Assignment-name"
                      id="Assignment-name"
                      autoComplete="off"
                      value={userTagId === 0 ? "" : userTagId}
                      onChange={(e) => handleIdChange(e.target.value)}
                      className="shadow-sm focus:ring-primary-violet focus:border-primary-violet h-10 block w-full text-lg border border-gray-200 text-gray-500 px-3 rounded-sm"
                    />
                  </div>

                  <div className="col-span-12 md:col-span-4 xl:col-span-3 xxl:col-span-2 xxxl:col-span-2">
                    <label className="block text-sm text-gray-500"> Name</label>
                    <input
                      type="text"
                      name="Assignment-name"
                      id="Assignment-name"
                      autoComplete="off"
                      value={tagName}
                      onChange={(e) => handleNameChange(e.target.value)}
                      className="shadow-sm focus:ring-primary-violet focus:border-primary-violet h-10 block w-full text-lg border border-gray-200 text-gray-500 px-3 rounded-sm"
                    />
                  </div>

                  <div className="col-span-8 md:col-span-4 xl:col-span-3 xxl:col-span-2 xxxl:col-span-2 self-end">
                    <div className="flex items-center">
                      <label className="cursor-pointer flex items-center text-sm text-gray-500">
                        <input
                          type="checkbox"
                          x-model="value"
                          name="privacy_setting"
                          value="Private to Project Members"
                          className="h-4 w-4 mr-2 mt-0.5 cursor-pointer text-blue-600 border-gray-300 focus:ring-blue-500"
                          aria-labelledby="privacy-setting-1-label"
                          aria-describedby="privacy-setting-1-description"
                          checked={isActive}
                          onChange={(e) => {
                            handleIsActiveChange(e.target.checked);
                          }}
                        />
                        Show active
                      </label>
                    </div>
                  </div>
                  <div className="col-span-12 md:col-span-12 xl:col-span-3 xxl:col-span-6 xxxl:col-span-6 flex space-x-3 ml-auto">
                    <button
                      onClick={() => handleReset()}
                      className="bg-transparent border my-auto border-primary-violet shadow-sm py-2 px-4 flex justify-center text-sm font-medium text-primary-violet hover:text-white hover:bg-light-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3 transition ease-in-out duration-500"
                    >
                      Reset
                    </button>
                    <button
                      onClick={() => handleSearch()}
                      className="bg-primary-violet border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-dark-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3 "
                    >
                      Search
                    </button>
                  </div>
                </div>

                <div className="mb-0">
                  <div className=" mt-2">
                    <div className="bg-white py-3 px-5">
                      <div className="mb-4 pb-4 border-b-2 overflow-x-auto ">
                        <table className="table w-full">
                          <thead className="mb-5">
                            <tr className="bg-light-violet text-white py-3 px-3 overflow-hidden">
                              <th className="text-center px-3 py-2  text-sm font-normal w-6">
                                Id
                              </th>
                              <th className="text-left px-3 py-2  text-sm font-normal">
                                Name
                              </th>

                              <th className="text-left px-3 py-2  text-sm font-normal">
                                Description
                              </th>
                              <th className="text-center w-8 px-3 py-2  text-sm font-normal">
                                Actions
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {tags?.data.map((tag, index) => {
                              return (
                                <tr
                                  key={tag.userTagId}
                                  className={`hover:bg-light-violet/20 transition ease-in-out duration-300 ${
                                    index % 2 === 0
                                      ? "bg-white"
                                      : "bg-light-violet/10"
                                  }`}
                                >
                                  <td className="text-center px-3 py-2  text-sm font-normal h-4">
                                    {tag.userTagId}
                                  </td>
                                  <td className="text-left px-3 py-2  text-sm font-normal h-4">
                                    <p
                                      className={`inline-block bg-[${
                                        tag.color
                                      }] ${getTextColor(
                                        tag.color
                                      )} rounded-full text-xs shadow-sm py-0.5 px-3 `}
                                    >
                                      {tag.name}
                                    </p>
                                  </td>

                                  <td className=" text-left px-3 py-2 text-sm font-normal h-4">
                                    <span className="inline-block">
                                      {tag.description}
                                    </span>
                                  </td>
                                  <td className=" text-center px-3 py-2 text-sm font-normal h-4">
                                    <div className="flex gap-2 justify-center">
                                      <AllowedTo
                                        perform={Permissions.tags_modify}
                                      >
                                        <EditIcon
                                          title="Edit tag"
                                          onClick={() => {
                                            handleEditTagClick(tag);
                                          }}
                                          className="w-4 h-4 cursor-pointer text-gray-500 hover:text-gray-700 transaction ease-in-out duration-300"
                                        />
                                      </AllowedTo>
                                      <AllowedTo
                                        perform={Permissions.tags_delete}
                                      >
                                        <DeleteIcon
                                          title="Remove tag"
                                          onClick={() => {
                                            handleDeleteTagClick(tag);
                                          }}
                                          className="w-4 h-4 cursor-pointer text-gray-500 hover:text-gray-700 transaction ease-in-out duration-300"
                                        />
                                      </AllowedTo>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                            {tags?.totalRecords === 0 && (
                              <tr>
                                <td colSpan={5} className=" mt-5 p-3 text-left">
                                  No record found
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>

                      <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between mt-2">
                        {tags?.totalRecords !== undefined &&
                          tags?.totalRecords !== 0 && (
                            <Pagination
                              totalRecords={tags?.totalRecords}
                              pageSize={pageSize}
                              onPageChange={onPageChange}
                              reset={resetPageNumber}
                            />
                          )}
                        {tags?.totalRecords !== 0 && (
                          <PageSize
                            pageSize={pageSize}
                            onPageSizeChange={onPageSizeChange}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {showTagDetailPopup && (
        <TagDetailPopup
          showPopUp={setShowTagDetailPopup}
          handleSave={saveTag}
          tag={tagDetail}
        />
      )}
    </AllowedTo>
  );
};

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(ManageTags);

import { Fragment, useEffect, useState } from "react";
import Select from "react-select";
import {
  getAllDomainsBySubject,
  getAllStandardByDomains,
  getConstructedResponseByStandards,
} from "../../../../api/teacher/assignment";
import {
  OutlineMessagesIcon,
  KeyIcon,
  PersonWithTickIcon,
} from "../../../../assets/icons/index";
import { IAssignment } from "../../../../model/interface/assignment";
import { IAssignmentActivity } from "../../../../model/interface/assignmentActivity";
import {
  IKeyValuePair,
  ISelectElement,
} from "../../../../model/interface/selectElement";
import DomainStandard from "../../../../model/teacher/domainStandard";
import QuestionStandard from "../../../../model/teacher/questionStandard";
import Standard from "../../../../model/teacher/standard";
import Constant from "../../../../utils/constant/constant";
import { getActivityTypeId, OrderByArray } from "../../../../utils/helper";
import Feedback from "../../../shared/feedback";
import QuestionPreview from "../shared/questionPreview/questionPreview";
import Loader from "../../../shared/loader";
import MessagePopup from "../../../shared/messagePopup";
import EllipsisText from "react-ellipsis-text";
interface ConstructedResponseProps {
  userId: number;
  assignment: IAssignment;
  updateAssignment: (updatedAsignment: IAssignment) => void;
  isActivityUpdated: (activityUpdated: boolean) => void;
  canActivityUpdate: boolean;
}

function ConstructedResponse(props: ConstructedResponseProps) {
  const {
    userId,
    assignment,
    updateAssignment,
    isActivityUpdated,
    canActivityUpdate,
  } = props;
  const [domains, setDomains] = useState<Array<ISelectElement>>([]);
  const [constructedResponseList, setConstructedResponseList] = useState<
    Array<QuestionStandard>
  >([]);
  const [selectedQuestions, setSelectedQuestions] = useState<Array<number>>([]);
  const [showFeedbackPopup, setShowFeedbackPopup] = useState<boolean>(false);
  const [selectedDomains, setSelectedDomains] = useState<Array<ISelectElement>>(
    []
  );
  const [domainStandards, setDomainStandards] = useState<Array<DomainStandard>>(
    []
  );

  const [selectedQuestion, setselectedQuestion] = useState<number>(0);
  const [domainStandardIds, setDomainStandardIds] = useState<
    Array<IKeyValuePair>
  >([]);
  const [open, setOpen] = useState<boolean>(false);
  const [activityPreviewDetail, setActivityPreviewDetail] = useState<{
    previewId: string;
    activityIdPreview: number;
    activityName: string;
    standardId: number;
  }>();
  const [isAuthoredQuestion, setIsAuthoredQuestion] = useState<boolean>(false);
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [messagePopup, setMessagePopup] = useState<boolean>(false);
  const [selectedStandardId, setSelectedStandardId] = useState<number>();

  function decodeHtml(html) {
    var txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
  }
  function handleFeedbackPopup(id: number, standardId: number) {
    setselectedQuestion(id);
    setSelectedStandardId(standardId);
    toggleFeedback(true);
  }

  function handleDomainChange(selectdItems: any) {
    setSelectedDomains(selectdItems);
    const selectedDomains = Array.prototype.map.call(
      selectdItems,
      (s) => s.value
    );

    bindStandards(selectedDomains.join(","));

    if (assignment.activities && assignment.activities.length > 0) {
      let allActivities: Array<IAssignmentActivity> | undefined =
        assignment.activities;
      if (allActivities) {
        allActivities = allActivities.filter((r) =>
          isActivityValidByDomain(r, selectedDomains)
        );
        assignment.activities = allActivities;
        updateAssignment(assignment);
      }
    }
  }

  function isActivityValidByDomain(
    assignmentActivity: IAssignmentActivity,
    domains: Array<unknown>
  ) {
    if (
      assignmentActivity.activityType !==
      Constant.AssignmentActivityType.CONSTRUCTEDRESPONSE
    )
      return true;

    const exist = domains.includes(assignmentActivity.domainId?.toString());
    return exist;
  }

  function isActivityValidByStandard(
    assignmentActivity: IAssignmentActivity,
    standards: Array<unknown>
  ) {
    if (
      assignmentActivity.activityType !==
      Constant.AssignmentActivityType.CONSTRUCTEDRESPONSE
    )
      return true;

    const exist = standards.includes(assignmentActivity.standardId?.toString());
    return exist;
  }

  function bindStandards(selectedDomains: string) {
    getAllStandardByDomains(selectedDomains, true).then((response) => {
      const standardsData = response.data.data;
      setDomainStandards(standardsData);
    });
  }

  function handleStandardChange(selectdItems: any, domainId: string) {
    const selectedStandards = Array.prototype.map
      .call(selectdItems, (s) => s.value)
      .join(",");

    const currentDomainStandard: IKeyValuePair = {
      key: domainId,
      value: selectedStandards,
    };

    let domainStandards = [
      ...domainStandardIds.filter(
        (r) => r.key.toString() !== domainId.toString()
      ),
      currentDomainStandard,
    ];

    setDomainStandardIds(domainStandards);

    let allStandards: Array<string> = [];
    domainStandards.forEach((r) => {
      allStandards.push(r.value);
    });
    bindConstructedResponses(allStandards.join(","), isAuthoredQuestion);

    if (assignment.activities && assignment.activities.length > 0) {
      var allActivities: Array<IAssignmentActivity> | undefined =
        assignment.activities;
      if (allActivities) {
        allActivities = allActivities.filter((r) =>
          isActivityValidByStandard(r, allStandards.join(",").split(","))
        );
        assignment.activities = allActivities;
        updateAssignment(assignment);
      }
    }
  }

  function bindConstructedResponses(
    selectedStandards: string,
    authoredQuestion: boolean
  ) {
    setShowLoading(true);
    getConstructedResponseByStandards(
      selectedStandards,
      userId,
      authoredQuestion
    ).then((response) => {
      setShowLoading(false);
      setConstructedResponseList(response.data.data);
    });
  }

  function toggleAuthoredQuestion() {
    if (!canActivityUpdate) {
      return;
    }
    setIsAuthoredQuestion(!isAuthoredQuestion);

    let seletcedStandards: Array<string> = [];
    domainStandardIds.forEach(function (domainStandardId) {
      seletcedStandards.push(...domainStandardId.value.split(","));
    });

    bindConstructedResponses(seletcedStandards.join(","), !isAuthoredQuestion);

    setSelectedQuestions([]);

    const existingActivitys: Array<IAssignmentActivity> | undefined =
      assignment.activities?.filter(
        (r) =>
          r.activityType !== Constant.AssignmentActivityType.CONSTRUCTEDRESPONSE
      );

    assignment.activities = existingActivitys;
    updateAssignment(assignment);
  }

  function handleSelectedQuestion(
    questionId: number,
    questionText: string,
    domainId: string,
    standardId: string,
    learnosityIdEnglish: string,
    learnosityIdSpanish: string,
    authoredQuestion: boolean
  ) {
    var selectedQuestionList: Array<number> = new Array<number>();
    const questionExist = selectedQuestions.some(
      (r) => (r as number) === questionId
    );

    if (questionExist) {
      selectedQuestionList = selectedQuestions.filter(
        (r) => (r as number) !== questionId
      );
    } else {
      selectedQuestionList = [...selectedQuestions];
      selectedQuestionList.push(questionId);
    }

    setSelectedQuestions(selectedQuestionList);
    const keyValuePair: IKeyValuePair = {
      key: questionId.toString(),
      value: questionText,
    };
    updateActivity(
      keyValuePair,
      domainId,
      standardId,
      learnosityIdEnglish,
      learnosityIdSpanish,
      authoredQuestion
    );
  }

  function getDomains() {
    getAllDomainsBySubject(assignment.subjectId).then((res) => {
      const domains = res.data.data.map((values: any) => {
        return {
          label: values?.name,
          value: values?.domainId,
        };
      });
      setDomains(domains);
      setExistingValues(domains);
    });
  }

  function toggleFeedback(popupAction: boolean) {
    setShowFeedbackPopup(popupAction);
  }

  function updateActivity(
    keyValuePair: IKeyValuePair,
    domainId: string,
    standardId: string,
    learnosityIdEnglish: string,
    learnosityIdSpanish: string,
    authoredQuestion: boolean
  ) {
    isActivityUpdated(true);
    const existingActivity: IAssignmentActivity | undefined =
      assignment.activities?.filter(
        (r) =>
          r.activityType ===
            Constant.AssignmentActivityType.CONSTRUCTEDRESPONSE &&
          r.questionId?.toString() === keyValuePair.key
      )[0];
    if (existingActivity) {
      var allActivities: Array<IAssignmentActivity> | undefined =
        assignment.activities;
      if (allActivities) {
        allActivities = allActivities.filter(
          (r) =>
            (r.activityType ===
              Constant.AssignmentActivityType.CONSTRUCTEDRESPONSE &&
              r.questionId?.toString() === keyValuePair.key) === false
        );
        assignment.activities = allActivities;
      }
    } else {
      const activity: IAssignmentActivity = {
        assignmentActivityId: 0,
        assignmentId: assignment.assignmentId,
        activityTypeId: getActivityTypeId(
          Constant.AssignmentActivityType.CONSTRUCTEDRESPONSE
        ),
        activityType: Constant.AssignmentActivityType.CONSTRUCTEDRESPONSE,
        sequenceNumber: assignment.activities
          ? assignment.activities.length + 1
          : 1,
        questionId: keyValuePair.key,
        questionText: keyValuePair.value,
        domainId: domainId,
        standardId: standardId,
        learnosityIdEnglish: learnosityIdEnglish,
        learnosityIdSpanish: learnosityIdSpanish,
        isAuthoredQuestion: authoredQuestion,
      };

      assignment.activities?.push(activity);
    }

    updateAssignment(assignment);
  }

  function setExistingValues(domains: Array<ISelectElement>) {
    const existingActivities: Array<IAssignmentActivity> | undefined =
      assignment.activities?.filter(
        (r) =>
          r.activityType === Constant.AssignmentActivityType.CONSTRUCTEDRESPONSE
      );

    if (existingActivities && existingActivities.length > 0) {
      const existingQuestionIds: Array<number> = [];
      const domainStandards: Array<IKeyValuePair> = [];
      const existingDomainIds: Array<string> = [];
      let existingStandardIds: Array<string> = [];
      setIsAuthoredQuestion(
        existingActivities.filter((r) => r.isAuthoredQuestion).length ===
          existingActivities.length
      );
      existingActivities.forEach((activity) => {
        if (activity.questionId) {
          existingQuestionIds.push(parseInt(activity.questionId));
        }
        if (
          activity.domainId &&
          !existingDomainIds.includes(activity.domainId)
        ) {
          existingDomainIds.push(activity.domainId);
        }
        if (
          activity.standardId &&
          !existingStandardIds.includes(activity.standardId)
        ) {
          existingStandardIds.push(activity.standardId.toString());
        }
      });

      const existingDomains: Array<ISelectElement> = domains.filter((r) =>
        existingDomainIds.includes(r.value)
      );

      setSelectedDomains(existingDomains);

      bindStandards(existingDomainIds.join(","));

      existingDomainIds.forEach((domainId) => {
        const keyValuePair: IKeyValuePair = {
          key: domainId,
          value: existingActivities
            .filter((r) => r.domainId === domainId)
            .map((s) => s.standardId)
            .join(","),
        };
        domainStandards.push(keyValuePair);
      });

      setDomainStandardIds(domainStandards);
      bindConstructedResponses(
        existingStandardIds.join(","),
        existingActivities[0].isAuthoredQuestion!
      );
      setSelectedQuestions(existingQuestionIds);
    }
  }

  function getDomainStandards(domainId: string) {
    var standards: Array<ISelectElement> = new Array<ISelectElement>();
    const selectedStandards = domainStandardIds.filter(
      (r) => r.key === domainId
    );
    domainStandards
      .filter((r) => r.domainId === domainId)
      .forEach(function (domainStandard: DomainStandard) {
        domainStandard.standards.forEach(function (standard: Standard) {
          if (selectedStandards && selectedStandards.length > 0) {
            const standardIds = selectedStandards[0].value.split(",");
            if (
              standardIds.some((r) => r.toString() === standard.id.toString())
            ) {
              return;
            }
          }
          standards.push({
            label: `${standard.code} ${standard.name}`,
            value: standard.id,
          });
        });
      });

    return standards;
  }

  function getSelectedStandards(domainId: string) {
    let seletcedStandards: Array<string> = [];

    if (
      domainStandardIds &&
      domainStandardIds.some((r) => r.key === domainId)
    ) {
      seletcedStandards = domainStandardIds
        .filter((r) => r.key === domainId)[0]
        .value.split(",");
    }

    var standards: Array<ISelectElement> = new Array<ISelectElement>();

    domainStandards
      .filter((r) => r.domainId === domainId)
      .forEach(function (domainStandard: DomainStandard) {
        domainStandard.standards.forEach(function (standard: Standard) {
          if (seletcedStandards.includes(standard.id.toString())) {
            standards.push({
              label: `${standard.code} ${standard.name}`,
              value: standard.id.toString(),
            });
          }
        });
      });

    return standards;
  }

  function getDomainQuestions(domainId: string) {
    var data = constructedResponseList.filter(
      (r) => r.domainId.toString() === domainId.toString()
    );
    return OrderByArray(data, "questionText");
  }

  const openModal = (
    learnosityReferenceId,
    activityId,
    activityName,
    standardId
  ) => {
    if (!learnosityReferenceId) {
      return false;
    }

    setOpen(true);
    setActivityPreviewDetail({
      previewId: learnosityReferenceId,
      activityIdPreview: activityId,
      activityName: activityName,
      standardId: standardId,
    });
  };

  const popUpClose = (value) => {
    setOpen(value);
    setActivityPreviewDetail(undefined);
  };

  function handleKeyPopup(textExplanation: string) {
    setMessage(textExplanation);
    setMessagePopup(true);
  }

  useEffect(() => {
    getDomains();
  }, []);

  return (
    <Fragment>
      {messagePopup && (
        <MessagePopup
          message={message}
          togglePopup={() => setMessagePopup(!messagePopup)}
        />
      )}

      {activityPreviewDetail && (
        <QuestionPreview
          modal={openModal}
          show={open}
          changeValue={popUpClose}
          itemRef={activityPreviewDetail.previewId}
          questionIdPreview={activityPreviewDetail.activityIdPreview}
          activityName={activityPreviewDetail.activityName}
          standardId={activityPreviewDetail.standardId}
        ></QuestionPreview>
      )}

      {showFeedbackPopup && (
        <Feedback
          toggleFeedback={toggleFeedback}
          referenceType={Constant.FeedbackReferenceType.QUESTION}
          referenceId={selectedQuestion}
          subjectId={assignment.subjectId}
          standardId={selectedStandardId}
          subjectName={assignment.subjectName ?? ""}
        />
      )}
      {showLoading && <Loader></Loader>}
      <div className="min-w-0 block lg:col-span-9 xl:col-span-10 w-full sm:px-5">
        <div
          onClick={() => toggleAuthoredQuestion()}
          className={`${
            isAuthoredQuestion
              ? "bg-secondary-teal/10 border-secondary-teal "
              : "border-gray-200"
          } ${
            canActivityUpdate ? "cursor-pointer" : "pointer-events-none"
          } relative border p-4 flex flex-col md:pl-4 md:pr-6 focus:outline-none mb-2`}
        >
          <div>
            <div className="flex items-center">
              <input
                id="styled-checkbox-2"
                type="checkbox"
                className={`checkbox h-4 w-4 text-primary-violet border-gray-500 focus:ring-primary-violet  ${
                  canActivityUpdate
                    ? "cursor-pointer"
                    : "opacity-50 cursor-not-allowed pointer-events-none"
                }`}
                checked={isAuthoredQuestion ? true : false}
              />
              <label className="checkbox-label text-sm text-gray-500">
                Only View My Authored Constructed Response Questions
              </label>
            </div>
          </div>
        </div>
        <div className="bg-gray-100  w-full mb-5 rounded-lg p-5 h-full">
          <div className="mb-0">
            <div className="grid xl:grid-cols-2">
              <div>
                <label className="block text-sm text-gray-500 mb-1">
                  Domain
                </label>
                <Select
                  value={selectedDomains}
                  options={domains}
                  isMulti={true}
                  closeMenuOnSelect={false}
                  onChange={handleDomainChange}
                  className={`${
                    canActivityUpdate
                      ? ""
                      : "opacity-50 cursor-not-allowed pointer-events-none"
                  }`}
                />
              </div>
            </div>

            {selectedDomains.map((selectedDomain, index) => {
              return (
                <div className=" mt-6" key={index}>
                  <div>
                    <label className="block text-sm font-medium text-gray-500 border-b border-gray-300">
                      {selectedDomain.label}
                    </label>
                  </div>
                  <div className="grid xl:grid-cols-2">
                    <div className="mt-2">
                      <label className="block text-sm text-gray-500 mb-1">
                        Standard
                      </label>
                      <Select
                        value={getSelectedStandards(selectedDomain.value)}
                        options={getDomainStandards(selectedDomain.value)}
                        isMulti={true}
                        closeMenuOnSelect={false}
                        onChange={(e) =>
                          handleStandardChange(e, selectedDomain.value)
                        }
                        className={`${
                          canActivityUpdate
                            ? ""
                            : "opacity-50 cursor-not-allowed pointer-events-none"
                        }`}
                      />
                    </div>
                  </div>
                  <div className="mt-6 bg-white py-3 px-5 shadow hover:shadow-lg">
                    <div className="mb-4">
                      <h3 className="mb-2">{selectedDomain.label}</h3>
                      <div className="shadow">
                        <table className="table w-full ">
                          <thead className="mb-5">
                            <tr className="bg-light-violet  text-white py-3 px-3 overflow-hidden">
                              <th className="text-center p-3 text-sm font-normal"></th>
                              <th className="text-center p-3 text-sm font-normal"></th>
                              <th className="text-left p-3 text-sm  font-normal">
                                Standard
                              </th>
                              <th className="text-left  p-3 text-sm font-normal w-1/2">
                                Question
                              </th>
                              <th className="text-center  p-3 text-sm font-normal">
                                Key
                              </th>
                              <th className="text-center  p-3 text-sm font-normal text-center">
                                Feedback
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {getDomainQuestions(selectedDomain.value).map(
                              (question, index) => {
                                return (
                                  <tr
                                    className={`${
                                      selectedQuestions.some(
                                        (r) =>
                                          (r as number) === question.questionId
                                      )
                                        ? "bg-light-violet/20 border border-light-violet"
                                        : ""
                                    } hover:bg-light-violet/20 transition ease-in-out duration-300  ${
                                      question.isAuthoredQuestion ? "" : ""
                                    }  ${
                                      index % 2 === 0
                                        ? "bg-white"
                                        : "bg-light-violet/10"
                                    } ${
                                      canActivityUpdate
                                        ? ""
                                        : " opacity-50 cursor-not-allowed"
                                    }`}
                                  >
                                    <td className="p-3 w-14 flex items-center justify-center">
                                      {question.isAuthoredQuestion && (
                                        <span className="bg-secondary-teal/20 inline-block p-2 rounded-lg relative">
                                          <PersonWithTickIcon className="w-5 h-5" />
                                        </span>
                                      )}
                                    </td>
                                    <td className="text-center mt-5 p-3 w-10">
                                      <input
                                        id="styled-checkbox-2"
                                        type="checkbox"
                                        onChange={() =>
                                          handleSelectedQuestion(
                                            question.questionId,
                                            question.questionText,
                                            selectedDomain.value,
                                            question.standardId.toString(),
                                            question.englishQuestionReferenceId,
                                            question.spanishQuestionReferenceId,
                                            question.isAuthoredQuestion
                                          )
                                        }
                                        className={`checkbox mx-auto h-4 w-4 cursor-pointer text-primary-violet border-gray-300 focus:ring-primary-violet ${
                                          canActivityUpdate
                                            ? ""
                                            : "pointer-events-none"
                                        }`}
                                        checked={selectedQuestions.some(
                                          (r) =>
                                            (r as number) ===
                                            question.questionId
                                        )}
                                      />
                                      <label className="checkbox-label"></label>
                                    </td>
                                    <td className="text-sm mt-5  p-3">
                                      {question.standardName}
                                    </td>
                                    <td className="p-3 flex justify-start">
                                      <span
                                        className="cursor-pointer text-blue-500 underline hover:no-underline"
                                        onClick={() =>
                                          openModal(
                                            question.englishQuestionReferenceId,
                                            question.questionId,
                                            `${question.standardName}`,
                                            question.standardId
                                          )
                                        }
                                      >
                                        <EllipsisText
                                          text={decodeHtml(
                                            question.questionText?.replace(
                                              new RegExp("<[^>]*>", "g"),
                                              ""
                                            )
                                          )}
                                          length={50}
                                        />
                                      </span>
                                    </td>
                                    <td className="text-center text-sm mt-5  p-3">
                                      {question.isAuthoredQuestion ? (
                                        "N/A"
                                      ) : (
                                        <KeyIcon
                                          title="Key"
                                          onClick={() =>
                                            handleKeyPopup(
                                              question.textExplanation
                                            )
                                          }
                                          className="cursor-pointer w-5 mx-auto stroke-current hover:text-gray-700 text-gray-500 "
                                        />
                                      )}
                                    </td>
                                    <td className="text-center text-sm mt-5  p-3">
                                      <OutlineMessagesIcon
                                        className={`${
                                          canActivityUpdate
                                            ? "cursor-pointer"
                                            : "pointer-events-none"
                                        } h-4 mx-auto fill-current text-gray-500 hover:text-gray-700`}
                                        title="Feedback"
                                        onClick={() =>
                                          handleFeedbackPopup(
                                            question.questionId,
                                            question.standardId
                                          )
                                        }
                                      />
                                    </td>
                                  </tr>
                                );
                              }
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default ConstructedResponse;

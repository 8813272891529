import { useState } from "react";
import { Configuration } from "../../../environment/setup";
import { toast } from "react-toastify";
import { ExclamationIcon } from "../../../assets/icons";
import Loader from "../../shared/loader";
import { CanvasApi } from "../../../api/canvas/canvasApi";
import { handleCanvasAuthentication } from "../../../utils/canvasHelper";
import constant from "../../../utils/constant/constant";

const CanvasUserLogin = (props: any) => {
  const [loading, setLoading] = useState(false);
  const [isValidFlag, setIsValidFlag] = useState(true);
  const [accountLoginName, setAccountLoginName] = useState("");

  const validate = () => {
    let isValid = true;
    setIsValidFlag(true);
    if (accountLoginName === "") {
      isValid = false;
      toast.error("Please enter your account login name");
    }
    if (!isValid) {
      setIsValidFlag(false);
    }
    return isValid;
  };

  const handleCanvasLogin = () => {
    if (validate()) {
      setLoading(true);
      CanvasApi.getCanvasAccountSettingsByAccountLoginName(accountLoginName)
        .then((r) => {
          setLoading(false);
          if (r.data && r.data.endPointURL) {
            handleCanvasAuthentication(r.data, accountLoginName, "");
          } else {
              var message = "";
              switch (r.data.reasonCode) {
                case constant.SubscriptionTypes.TeacherFree:
                  message = constant.UPGRADE_THIRDPARTY;
                  break;
                default:
                  message =
                    "Your school has not configured canvas account settings yet please contact administrator.";
                  break;
              }           
              toast.error(message);              
          }
        })       
        .catch(() => {
          setLoading(false);
        });
    }
  };

  return (
    <>
      <div className="bg-splitColor w-full h-screen flex items-center relative">
        {loading && <Loader />}
        <div className="w-full">
          <h1 className="text-center text-2xl text-white font-semibold pb-6">
            Canvas Account
          </h1>
          <div className="bg-white w-1/3 min-h-[24rem] py-6 flex flex-col justify-center mx-auto rounded-lg shadow-xl">
            <img
              className=" w-24 mx-auto mb-8"
              src={`${Configuration.S3bucketImagePath}/images/pl-logo.png`}
              alt="education galaxy"
            />

            <div className=" w-full flex flex-col items-center gap-y-4 mb-3">
              <div className=" w-3/4 relative group">
                <label className="mb-1">Account Id</label>
                <input
                  type="text"
                  className="py-3 px-4 shadow-sm focus:ring-secondary-teal focus:border-secondary-teal block w-full sm:text-sm border-gray-300 rounded-md"
                  onChange={(event) => setAccountLoginName(event.target.value)}
                  value={accountLoginName}
                />
                {!isValidFlag && accountLoginName === "" && (
                  <span>
                    <span className="opacity-0 group-hover:opacity-100 bg-white text-red-500 border-red-400 border transition duration-500 rounded-sm max-w-70% p-0.5 pl-2 pr-3 text-xs inline-block absolute top-1/2 transform -translate-y-1/2 right-2 ">
                      Account Id is required
                    </span>
                    <ExclamationIcon className="w-1 absolute opacity-100 top-1/2 transform -translate-y-1/2 right-3 cursor-pointer" />
                  </span>
                )}
              </div>

              <button
                className="bg-secondary-teal border border-transparent shadow-sm py-2 px-4 justify-center text-sm font-medium text-white hover:bg-dark-teal hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3 "
                onClick={() => {
                  handleCanvasLogin();
                }}
              >
                Login
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CanvasUserLogin;

import { useState } from "react";
import {
  getAssignmentStudentAttemptsReport,
  getAssignmentStudentAttemptsReportExcel
} from "../../../../../../api/report/resultsReport";
import { AssessmentStudentAttemptsReport } from "../../../../../../model/report/resultsReport/StudentAttemptsReport";
import { ReportAssessmentStudentAttemptsTable } from "./ReportAssessmentStudentAttemptsTable";
import Loader from "../../../../../shared/loader";
import { toast } from "react-toastify";
import { AxiosResponse } from "axios";

interface IResultReportAssignmentStudentAttemptsProps {  
  assignmentId: number;
  classIds: number[];
  schoolId: number;
  schoolYearId: number;
}

export const ResultReportAssignmentStudentAttempts = (
  props: IResultReportAssignmentStudentAttemptsProps
) => {
  const [showLoader, setShowLoader] = useState<boolean>(false);

  const getReportData = async (activeSortColumn: string, orderType: string, pageNumber: number, pageSize: number):
    Promise<AssessmentStudentAttemptsReport> => {
    
    const response = await getAssignmentStudentAttemptsReport(
      props.assignmentId,
      props.schoolId,
      props.classIds,
      props.schoolYearId,
      activeSortColumn,
      orderType === "desc" ? true : false, 
      pageNumber,
      pageSize
    );

    return response.data;
  };

  const exportReport = () => { 
    setShowLoader(true);
    getAssignmentStudentAttemptsReportExcel(
      props.assignmentId,
      props.schoolId,
      props.classIds,
      props.schoolYearId
    )
    .then(response => {
      startFileDownload(response, "AssignmentStudentAttempts.xlsx");
      setShowLoader(false);
    })
    .catch(error => {
      setShowLoader(false);
      toast.error(
        "Export request failed. If the request takes a long time, consider adding filters to decrease the report size.",
        {autoClose: 0}
      );
    })
  }

  const startFileDownload = (
    response: AxiosResponse<Blob>,
    fileName: string,
  ) => {
    const file = response.data;
    const fileURL = URL.createObjectURL(file);
    const link = document.createElement("a");
    link.href = fileURL;
    link.download = fileName;
    link.setAttribute("target", "_blank");
    document.body.appendChild(link);
    link.click();
  }

  return (
    <>
      {showLoader && <Loader></Loader>}
      <ReportAssessmentStudentAttemptsTable
        exportData={exportReport}
        getData={getReportData}
        isDistrictUser={false}
        schoolIds={[props.schoolId]}
        schoolYearId={props.schoolYearId}
      ></ReportAssessmentStudentAttemptsTable>
    </>    
  );
};

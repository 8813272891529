import { Button } from "@windmill/react-ui";
import { Fragment, useState } from "react";
import { connect } from "react-redux";
import Header from "../../shared/header/header";
import { updateContactUs } from "./api";

function ContactUs(props: any) {
  const [firstName] = useState(props.profile.firstName);
  const [lastName] = useState(props.profile.lastName);
  const [emailId, setEmailId] = useState(props.profile.email);
  const [phoneNumber] = useState(props.profile.phoneNumber);
  const [message, setMessage] = useState("");
  const [ticketResponse, setTicketResponse] = useState("");

  function handleMessageChange(event: any) {
    setMessage(event.target.value);
  }

  function handleEmailChange(event: any) {
    setEmailId(event.target.value);
  }

  function handleClick() {
    updateContactUs(
      props.userContext.userId,
      props.userContext.roleId,
      emailId,
      message
    )?.then((r) => setTicketResponse(r.data));
  }

  return (
    <Fragment>
      <Header></Header>
      <div className="bg-gray-100">
        <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
          <div className="relative bg-white shadow-xl">
            <div className="grid grid-cols-1 lg:grid-cols-3">
              {/* Contact information */}
              <div className="relative overflow-hidden py-10 px-6 bg-primary-green sm:px-10 xl:p-12">
                <h3 className="text-lg font-medium text-white">Contact Us</h3>
                <p className="mt-6 text-base text-white max-w-3xl">
                  Fill out the form with a detailed message. We respond to all
                  emails promptly and within 24 hours. Most emails are responded
                  to within 1 hour. Please tell us how we can make your
                  Education Galaxy experience great!
                </p>
              </div>

              {/* Contact form */}
              <div className="py-10 px-6 sm:px-10 lg:col-span-2 xl:p-12">
                <form
                  action="#"
                  className="mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8"
                >
                  <div>
                    <label
                      htmlFor="first_name"
                      className="block text-sm font-medium text-gray-900"
                    >
                      First Name
                    </label>
                    <div className="mt-1">
                      <input
                        value={firstName}
                        type="text"
                        name="first_name"
                        id="first_name"
                        autoComplete="given-name"
                        className="border py-3 px-4 block w-full shadow-sm text-gray-900 focus:outline-none focus:border-gray-700 border border-gray-300 rounded-md"
                      />
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="last_name"
                      className="block text-sm font-medium text-gray-900"
                    >
                      Last Name
                    </label>
                    <div className="mt-1">
                      <input
                        value={lastName}
                        type="text"
                        name="last_name"
                        id="last_name"
                        autoComplete="family-name"
                        className="border py-3 px-4 block w-full shadow-sm text-gray-900 focus:outline-none focus:border-gray-700 border border-gray-300 rounded-md"
                      />
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-900"
                    >
                      Email
                    </label>
                    <div className="mt-1">
                      <input
                        onChange={(e) => handleEmailChange(e)}
                        value={emailId}
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        className="border py-3 px-4 block w-full shadow-sm text-gray-900 focus:outline-none focus:border-gray-700 border border-gray-300 rounded-md"
                      />
                    </div>
                  </div>
                  <div>
                    <div className="flex justify-between">
                      <label
                        htmlFor="phone"
                        className="block text-sm font-medium text-gray-900"
                      >
                        Phone
                      </label>
                      <span
                        id="phone-optional"
                        className="text-sm text-gray-500"
                      >
                        Optional
                      </span>
                    </div>
                    <div className="mt-1">
                      <input
                        readOnly={true}
                        value={phoneNumber}
                        type="text"
                        name="phone"
                        id="phone"
                        autoComplete="tel"
                        className="border py-3 px-4 block w-full shadow-sm text-gray-900 focus:outline-none focus:border-gray-700 border border-gray-300 rounded-md"
                        aria-describedby="phone-optional"
                      />
                    </div>
                  </div>
                  <div></div>
                  <div className="sm:col-span-2">
                    <div className="flex justify-between">
                      <label
                        htmlFor="message"
                        className="block text-sm font-medium text-gray-900"
                      >
                        Message
                      </label>
                      <span id="message-max" className="text-sm text-gray-500">
                        Max. 500 characters
                      </span>
                    </div>
                    <div className="mt-1">
                      <textarea
                        onChange={(e) => handleMessageChange(e)}
                        id="message"
                        name="message"
                        rows={4}
                        className="border py-3 px-4 block w-full shadow-sm text-gray-900 focus:outline-none focus:border-gray-700 border border-gray-300 rounded-md"
                        aria-describedby="message-max"
                        defaultValue={" "}
                      />
                    </div>
                  </div>
                  <div className="sm:col-span-2">{ticketResponse}</div>
                  <div className="sm:col-span-2 sm:flex sm:justify-end">
                    <Button
                      className="text-white-700 bg-primary-green hover:bg-light-green py-1.5 px-2"
                      layout="primary"
                      onClick={() => handleClick()}
                    >
                      Send Email
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(ContactUs);

import { Fragment, useEffect, useState } from "react";
import Moment from "react-moment";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { ClassApi } from "../../../../../api/teacher/classManagementApi";
import { RemoveIcon } from "../../../../../assets/icons/index";
import { PageAccessMode } from "../../../../../model/common/pageAccessMode";
import PagingResponse from "../../../../../model/common/pagingResponse";
import ImpersonateUserModel from "../../../../../model/users/impersonateUser";
import {
  default as ClassStudentModel,
  default as ClasStudentModel,
} from "../../../../../model/teacher/classManagement/classStudentModel";
import { fetchProfile } from "../../../../../redux/actions/userActions";
import * as userContextAction from "../../../../../redux/actions/userContextAction";
import { UserContextState } from "../../../../../redux/actions/userContextAction";
import RouteConstant from "../../../../../utils/constant/routeConstant";
import { impersonateUser } from "../../../../../utils/impersonateHelper";
import {
  DEFAULT_PAGE_NUMBER,
  DEFAULT_PAGE_SIZE_50,
  DEFAULT_PAGE_SIZE_100,
} from "../../../../../utils/pagingConstant";
import ConfirmationDialog from "../../../../shared/confirmationDialog";
import Loader from "../../../../shared/loader";
import PageSize from "../../../../shared/pagination/pageSize";
import Pagination from "../../../../shared/pagination/pagination";
import UserTile from "../../../../shared/UserTile";
import UserPasswordPopup from "../classDetail/popups/userPasswordPopup";
import StudentPopup from "./popups/studentPopup";
import { getNameLabel } from "../../../../../utils/helper";
import LiftoffTile from "../../../../shared/LiftoffTile";
import userApi from "../../../../../api/userApi";

interface ClassStudentProps {
  classId: number;
  className: string;
  schoolAccountId: number;
  googleClassId: number;
  courseId: string;
  canvasCourseId: string;
  isGoogleEnabled: boolean;
  isCanvasEnabled: boolean;
  mode: PageAccessMode;
  onlineUsers: Array<any>;
  setStudentCount: (e: number) => void;
  userContext?: UserContextState;
  setUserContext?: (context: any) => void;
  setProfile?: (
    userId: number,
    roleId: number,
    schoolId: number,
    id: number
  ) => void;
  shouldUpdateKey?: number;
  isEditingEnabled: boolean;
}

const ClassStudent = (props: ClassStudentProps) => {
  const [showStudentPopup, setShowStudentPopup] = useState(false);
  const [students, setStudent] = useState<PagingResponse<ClasStudentModel>>();

  const [pageNumber, setPageNumber] = useState(DEFAULT_PAGE_NUMBER);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE_50);
  const [pageSize100, setPageSize100] = useState(DEFAULT_PAGE_SIZE_100);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [confirmationTitle, setConfirmationTitle] = useState("Confirm Action");
  const [confirmationMessage, setConfirmationMessage] =
    useState("Please confirm");
  const [classStudentToDelete, setStudentToDelete] = useState([
    { userId: 0, googleId: "" },
  ]);
  const [showUserPassword, setShowUserPassword] = useState<boolean>(false);
  const [selectedStudentToshowPassword, setSelectedStudentToshowPassword] =
    useState<any>();
  const [resetPageNumber, setResetPageNumber] = useState<boolean>(false);
  const [loading, showLoading] = useState(false);
  const history = useHistory();
  const [confirmationAction, setConfirmationAction] = useState<string>("");
  const [studentToImpersonate, setStudentToImpersonate] =
    useState<ClassStudentModel>();

  useEffect(() => {
    getClassStudent();
  }, [pageNumber, pageSize, props.shouldUpdateKey]);

  useEffect(() => {
    if (students) {
      ClassApi.getClassSubject(props.classId, pageNumber, pageSize100)
        .then((d) => {
          if (d.data.data?.length > 0) {
            let subjectIds: Array<number> = [];
            let studentIds: Array<number> = [];

            d.data?.data?.map((element, index) => {
              subjectIds.push(element.subjectId);
              return null;
            });
            students?.data?.map((student, index) => {
              studentIds.push(student.userId);
              return null;
            });
          }
        })
        .catch(() => {
          showLoading(false);
        });
    }
  }, [students]);

  function searchStudents(): void {
    setPageNumber(DEFAULT_PAGE_NUMBER);
    setPageSize(DEFAULT_PAGE_SIZE_50);
    setPageSize100(DEFAULT_PAGE_SIZE_100);
    getClassStudent();
  }

  function getClassStudent(): void {
    if (props.classId.toString() !== "new") {
      showLoading(true);
      ClassApi.getClassStudent(props.classId, pageNumber, pageSize)
        .then((d) => {
          setStudent(d.data);
          props.setStudentCount(d.data.totalRecords);
          showLoading(false);
        })
        .catch(() => {
          showLoading(false);
        });
    }
  }

  function confirmationOkClose() {
    switch (confirmationAction) {
      case "DELETE_STUDENT":
        deleteClassStudentConfirmed();
        break;
      case "IMPERSONATE_STUDENT":
        loginAsUserConfirm(studentToImpersonate);
        break;
    }
  }

  function deleteClassStudent(user: ClassStudentModel): void {
    setStudentToDelete([]);
    setStudentToDelete([{ userId: user.userId, googleId: user.googleId }]);
    setConfirmationAction("DELETE_STUDENT");
    showConfirmation(
      "Please Confirm",
      "Are you sure you want to remove student from class?"
    );
  }
  function showConfirmation(title: string, message: string) {
    setOpenConfirmation(true);
    setConfirmationTitle(title);
    setConfirmationMessage(message);
  }

  function deleteClassStudentConfirmed() {
    showLoading(true);
    ClassApi.deleteClassStudent(
      props.userContext?.userId,
      props.classId,
      classStudentToDelete.map((s) => s.userId),
      props.isGoogleEnabled,
      props.googleClassId,
      props.courseId,
      classStudentToDelete.map((s) => s.googleId),
      props.isCanvasEnabled,
      props.canvasCourseId
    ).then(() => {
      showLoading(false);
      searchStudents();
      confirmationCancelClose();
      toast.success("Student removed successfully");
    });
  }

  function confirmationCancelClose() {
    setOpenConfirmation(false);
  }
  const onPageSizeChange = (pageSize) => {
    setPageNumber(DEFAULT_PAGE_NUMBER);
    setPageSize(pageSize);
    setResetPageNumber(true);
  };

  function loginAsUser(user: ClassStudentModel) {
    setConfirmationAction("IMPERSONATE_STUDENT");
    setStudentToImpersonate(user);
    showConfirmation(
      "Login As Student",
      "<b>Student: </b>" +
        user.lastName +
        ", " +
        user.firstName +
        " <br/> <br/> This will log you into the selected student's account via impersonation. If you would like to see a demo student view instead, you can do so by navigating to 'Student View' located within Class Center on your sidebar. You will be able to navigate back to your account by selecting 'Return to your account' at the top of the page when you are finished."
    );
  }

  function loginAsUserConfirm(user: ClassStudentModel | undefined) {
    if (user !== undefined) {
      userApi.GetUserDetail(user.userId).then((res) => {
        //explicit call to get password
        var impersonateUserDetails = new ImpersonateUserModel(
          user.username,
          atob(res.data.passwordHash),
          user.accountId,
          props.setUserContext,
          props.setProfile,
          props.userContext?.userId,
          localStorage.getItem("AuthToken")?.toString(),
          history,
          RouteConstant.TeacherRoutes.ClassesDetails.replace(
            ":classId",
            props.classId.toString()
          ),
          props.userContext?.activeExternalRoster ?? ""
        );
        impersonateUser(impersonateUserDetails);
      });
    }
  }
  function getLoginDetails(userId: number) {
    userApi.GetUserDetail(userId).then((res) => {
      if (res.data) {
        setSelectedStudentToshowPassword(res.data);
        setShowUserPassword(true);
      }
    });
  }
  return (
    <Fragment>
      {loading && <Loader />}
      <div className="mb-4 w-full relative">
        <div className="flex items-end justify-between mb-4 flex-wrap">
          <div>
            <h1 className="font-bold text-primary-violet">Class Students</h1>
            <div className="mb-2 sm:mb-0 flex mt-2">
              <div className="flex items-center">
                <LiftoffTile />
                <span className="text-xs ml-2 text-gray-500">
                  Liftoff Activated
                </span>
              </div>
            </div>
          </div>
          <div className="flex">
            {props.mode === PageAccessMode.Edit && (
              <button
                type="button"
                className={
                  (props.isEditingEnabled
                    ? "bg-primary-violet hover:bg-dark-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3 transition ease-in-out duration-500 text-white"
                    : "cursor-not-allowed text-gray-400 border-gray-300 bg-gray-300") +
                  " ml-2 border my-auto shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium"
                }
                onClick={() => {
                  if (props.isEditingEnabled) {
                    setShowStudentPopup(true);
                  }
                }}
              >
                {" "}
                Add Students
              </button>
            )}
          </div>
        </div>

        <div className="">
          <div className="overflow-x-auto shadow">
            <table className="table w-full overflow-auto">
              <thead className="mb-5">
                <tr className="bg-light-violet  text-white py-3 px-3 overflow-hidden">
                  <th className="text-left  p-3 text-sm font-normal w-16"></th>
                  <th className="text-left  p-3 text-sm font-normal">
                    {" "}
                    Student{" "}
                  </th>
                  {props.userContext?.impersonatedUser == null && (
                    <th className="text-left  p-3 text-sm font-normal">
                      {" "}
                      Login{" "}
                    </th>
                  )}
                  <th className="text-center p-3 text-sm font-normal">
                    {" "}
                    Logins{" "}
                  </th>
                  <th className="text-center p-3 text-sm font-normal">
                    {" "}
                    Last Login{" "}
                  </th>
                  <th className="text-center p-3 text-sm font-normal">
                    {" "}
                    Grade{" "}
                  </th>
                  <th className="text-center p-3 text-sm font-normal">
                    {" "}
                    Total Time{" "}
                  </th>
                  {props.mode === PageAccessMode.Edit && (
                    <th className=" text-center p-3 text-sm font-normal">
                      {" "}
                      Remove{" "}
                    </th>
                  )}
                </tr>
              </thead>
              <tbody>
                {students?.data?.map((student, index) => {
                  return (
                    <tr
                      key={student.userId}
                      className={`hover:bg-light-violet/20 transition ease-in-out duration-300 ${
                        index % 2 === 0 ? "bg-white" : "bg-light-violet/10"
                      }`}
                    >
                      <td className=" mt-5 p-3 text-center">
                        <span className="relative">
                          <UserTile
                            userId={student.userId}
                            onlineUsers={props.onlineUsers}
                            isLoggedInUser={false}
                          />
                        </span>
                      </td>
                      <td className="flex text-sm mt-5  p-3 items-center">
                        {student.isLOActive &&
                          student.gradeId !== 1 &&
                          student.gradeId !== 2 && (
                            <>
                              <LiftoffTile className="mr-2" />
                            </>
                          )}
                        {!(student.isLOActive &&
                          student.gradeId !== 1 &&
                          student.gradeId !== 2) && (
                            <div className="w-12"> </div>
                          )
                        }
                        {getNameLabel(student.lastName, student.firstName)}
                      </td>

                      {props.userContext?.impersonatedUser === null && (
                        <td className="text-sm mt-5  p-3">
                          <span className="flex gap-1">
                            {!student.isDisabled && (
                              <>
                                <span
                                  onClick={() => {
                                    loginAsUser(student);
                                  }}
                                  className="cursor-pointer text-blue-500 underline hover:no-underline cursor-pointer"
                                >
                                  Login
                                </span>
                                <span className="text-gray-700">|</span>
                              </>
                            )}
                            <label
                              className="text-blue-500 underline hover:no-underline cursor-pointer"
                              onClick={() => {
                                getLoginDetails(student.userId);
                              }}
                            >
                              Show Login
                            </label>
                          </span>
                        </td>
                      )}

                      <td className="text-sm text-center mt-5  p-3">
                        {student.loginCount}
                      </td>
                      <td className="text-sm text-center mt-5  p-3">
                        {student?.lastLogin !== null && (
                          <> {<Moment>{student?.lastLogin}</Moment>}</>
                        )}
                      </td>
                      <td className="text-sm text-center mt-5  p-3">
                        {student.grade}
                      </td>
                      <td className="text-sm text-center mt-5  p-3">
                        {student.totalTimeSpent}
                      </td>
                      {props.mode === PageAccessMode.Edit && (
                        <td className="p-3">
                          <div
                            className={
                              (props.isEditingEnabled
                                ? ""
                                : "cursor-not-allowed text-gray-400") +
                              " flex items-center justify-center cursor-pointer"
                            }
                            onClick={() => {
                              if (props.isEditingEnabled) {
                                deleteClassStudent(student);
                              }
                            }}
                          >
                            <span title="Remove Student">
                              <RemoveIcon
                                className={
                                  (props.isEditingEnabled
                                    ? "text-gray-500 hover:text-gray-700 cursor-pointer"
                                    : "cursor-not-allowed text-gray-400") +
                                  " h-4"
                                }
                              />
                            </span>
                          </div>
                        </td>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between border-t-2 pt-4 mt-5">
            {students?.totalRecords !== undefined &&
              students?.totalRecords !== 0 && (
                <Pagination
                  totalRecords={students?.totalRecords}
                  pageSize={pageSize}
                  onPageChange={(e) => {
                    setPageNumber(e);
                  }}
                  reset={resetPageNumber}
                  pageNumber={pageNumber}
                />
              )}
            {students?.totalRecords !== 0 && (
              <PageSize
                pageSize={pageSize}
                onPageSizeChange={onPageSizeChange}
              />
            )}
          </div>
          {students?.totalRecords === 0 && (
            <span className="text-color-black">No record found</span>
          )}
        </div>
      </div>
      <ConfirmationDialog
        open={openConfirmation}
        confirmationTitle={confirmationTitle}
        confirmationMessage={confirmationMessage}
        onCancelClick={confirmationCancelClose}
        onOkClick={confirmationOkClose}
      ></ConfirmationDialog>

      {showUserPassword && (
        <UserPasswordPopup
          userName={selectedStudentToshowPassword?.username}
          password={atob(selectedStudentToshowPassword?.passwordHash)}
          name={getNameLabel(
            selectedStudentToshowPassword?.lastName,
            selectedStudentToshowPassword?.firstName
          )}
          showPopUp={(show) => {
            setShowUserPassword(show);
          }}
        />
      )}
      {showStudentPopup && (
        <StudentPopup
          classId={props.classId}
          googleClassId={props.googleClassId}
          isGoogleEnabled={props.isGoogleEnabled}
          isCanvasEnabled={props.isCanvasEnabled}
          showPopUp={(show) => {
            setShowStudentPopup(show);
          }}
          refreshParent={getClassStudent}
          onlineUsers={props.onlineUsers}
        />
      )}
    </Fragment>
  );
};

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setUserContext: (payload: userContextAction.UserContextState) => {
      dispatch(userContextAction.setUserContext(payload));
    },
    setProfile: (
      userId: number,
      roleId: number,
      schoolId: number,
      districtId: number
    ) => {
      dispatch(fetchProfile(userId, roleId, schoolId, districtId));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ClassStudent);

import { Fragment, useEffect, useState } from "react";
import "../../../../../../css/style.css";
import { connect } from "react-redux";
import OverviewResult from "./overViewResult";
import LearnosityReport from "../../../../results/learnosityReport";
import Profile from "../../../../../../model/users/profile";
import { UserContextState } from "../../../../../../redux/actions/userContextAction";
import { useTranslation } from "react-i18next";
import { getCannedTestResult } from "../../../../../../api/student/cannedTestApi";
import ProgressResult from "../progressResult";
import TotalQuestionsByDomains from "../../../middleSchool/studyPlan/studyPlanResult/totalQuestionsByDomains";
import TotalQuestionsByStandards from "../../../middleSchool/studyPlan/studyPlanResult/totalQuestionsByStandards";
import Toggle from "../../../../../shared/toggle";

interface ISummativeTestResult {
  userContext: UserContextState;
  profile: Profile;
  match: any;
  studentId?: number;
  studentGradeId?: number;
  isTeacherView?: boolean;
  sessionId?: number;
}
const CannedTestResult = (props: ISummativeTestResult) => {
  const [studyPlanResult, setStudyPlanResult] = useState<any>(null);
  const [title, setTitle] = useState<any>("");
  const [toggleRetry, setToggleRetry] = useState(false);
  const sessionId = props.isTeacherView
    ? props.sessionId ?? ""
    : props.match.params.sessionId;
  const { t } = useTranslation();
  const userId =
    props.isTeacherView === true
      ? props.studentId ?? 0
      : props.userContext.userId;
  const [learnosityActivityUserId, setLearnosityActivityUserId] =
    useState<number>(userId);

  useEffect(() => {
    getResult();
  }, []);

  function getResult() {
    getCannedTestResult(sessionId).then((res) => {
      setLearnosityActivityUserId(res.data.learnosityActivityUserId ?? userId);
      setStudyPlanResult(res.data);
      setTitle(res.data.subjectName);
    });
  }

  return (
    <Fragment>
      {studyPlanResult && (
        <div className="relative w-full bg-white flex-grow mb-4 rounded-2xl ">
          <div className="p-5 md:py-5 md:px-2 lg:p-5 flex flex-col md:flex-row">
            <OverviewResult data={studyPlanResult} />
            <div className="flex-grow h-auto md:w-1/2">
              <div className="flex w-full mt-6 md:mt-0 pb-6">
                <div>
                  <h1 className="text-2xl font-bold text-white capitalize">
                    {t("StudyPlanResult.PracticeTest")}
                  </h1>
                  <h2 className="text-base font-medium text-white capitalize">
                    {title}
                  </h2>
                </div>
              </div>
              <div className="flex-none xxl:flex">
                <div className="flex-none w-full xl:w-4/5 xxl:w-2/5 rounded-md xl:mr-12 mb-12 xxl:mb-0 overflow-hidden shadow-xl bg-white">
                  <ProgressResult data={studyPlanResult}></ProgressResult>
                </div>
                <div className="flex-grow w-full xl:w-4/5 xxl:w-3/5">
                  <TotalQuestionsByDomains data={studyPlanResult} />
                  <TotalQuestionsByStandards data={studyPlanResult} />
                </div>
              </div>
            </div>
          </div>
          <div className="p-5">
            <div className="p-5 bg-gray-50 border rounded-md text-sm font-semibold">
              <div className="flex w-full justify-between gap-x-4">
                <div>
                  <h2>{t("StudyPlanResult.AnswerQuestionStatus")}.</h2>
                  <ul className="flex flex-wrap gap-2 w-full mt-3">
                    {(
                      (!toggleRetry && studyPlanResult.questions) ||
                      (toggleRetry && studyPlanResult.retryQuestions)
                    ).map((question, index) => {
                      return (
                        <>
                          {question.order > 0 && question.score > 0 && (
                            <li
                              key={index}
                              className="w-6 h-6 bg-[#B2D136] border border-[#B2D136] text-white text-xs font-semibold flex items-center rounded-sm shadow-sm justify-center hover:bg-transparent hover:text-[#B2D136]"
                            >
                              {index + 1}
                            </li>
                          )}
                          {question.order > 0 && question.score === 0 && (
                            <li
                              key={index}
                              className="w-6 h-6 bg-[#e53f71] border border-[#e53f71] text-white text-xs font-semibold flex items-center rounded-sm shadow-sm justify-center hover:bg-transparent hover:text-[#e53f71]"
                            >
                              {index + 1}
                            </li>
                          )}
                        </>
                      );
                    })}
                  </ul>
                </div>
                {studyPlanResult.retryScore !== null && (
                  <div className="flex gap-x-2">
                    <span> Original </span>
                    <Toggle
                      checked={toggleRetry}
                      onChange={(e) => {
                        setToggleRetry(!toggleRetry);
                      }}
                    />
                    <span> Retry </span>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="p-5">
            <div className="p-5 border-2 border-blue-400 rounded-md text-sm font-semibold">
              <>
                {!toggleRetry && (
                  <LearnosityReport
                    sessionId={studyPlanResult.lernositySessionId}
                    user_id={learnosityActivityUserId.toString()}
                    show_answers={true}
                    wrapperClass="MS-Wrapper"
                    standardIds={studyPlanResult.standards
                      ?.map((standard) => {
                        return standard.standardId;
                      })
                      ?.join(",")}
                  ></LearnosityReport>
                )}

                {toggleRetry && (
                  <LearnosityReport
                    sessionId={studyPlanResult.retryLernositySessionId}
                    user_id={learnosityActivityUserId.toString()}
                    show_answers={true}
                    wrapperClass="MS-Wrapper"
                    standardIds={studyPlanResult.standards
                      ?.map((standard) => {
                        return standard.standardId;
                      })
                      ?.join(",")}
                  ></LearnosityReport>
                )}
              </>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
};
export default connect<{}, {}, {}>(mapStateToProps, {})(CannedTestResult);

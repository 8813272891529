import { Fragment } from "react";
import { Info } from "../../assets/icons";

interface CallOutMessageProps {
  message: string;
  title?: string;
  className?: string;
}
function CallOutMessage(props: CallOutMessageProps) {
  return (
    <Fragment>
      <div
        className={`${
          props.className ? props.className : "my-4"
        } text-sm text-white font-light bg-secondary-teal p-4 flex w-full rounded-md rounded-t-lg sm:rounded-lg overflow-hidden`}
      >
        <div className="flex-shrink-0 current-fill mt-0.5">
          <Info title={props.title ? props.title : ""} />
        </div>
        <div
          className="pl-2"
          dangerouslySetInnerHTML={{ __html: props.message }}
        />
      </div>
    </Fragment>
  );
}

export default CallOutMessage;

import { Disclosure } from "@headlessui/react";
import { ExclamationIcon } from "@heroicons/react/solid";
import { Fragment, useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import DatePicker from "react-datepicker";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import ReactTooltip from "react-tooltip";
import {
  addAssignmentActivity,
  getAssignmentById,
  updateActivitySetting,
  updateAssignmentData,
} from "../../../../api/teacher/assignment";
import {
  CanvasIcon,
  DragAndDropIcon,
  GoogleClassroomIcon,
  InformationIcon,
  PersonWithTickIcon,
  PreviewIcon,
} from "../../../../assets/icons/index";
import { IAssignment } from "../../../../model/interface/assignment";
import { IAssignmentActivity } from "../../../../model/interface/assignmentActivity";
import { ISelectElement } from "../../../../model/interface/selectElement";
import { getActivityName } from "../../../../utils/assignmentHelper";
import Constant from "../../../../utils/constant/constant";
import RouteConstant from "../../../../utils/constant/routeConstant";
import {
  OrderByArray,
  getActivitySubTypes,
  validateIsCanvasUser,
  validateIsGoogleUser,
} from "../../../../utils/helper";
import ConfirmationDialog from "../../../shared/confirmationDialog";
import Loader from "../../../shared/loader";
import Toggle from "../../../shared/toggle";
import ApplyAllSettingContextMenu from "../shared/applyAllSettingContextMenu";
import MessagePopup from "../../../shared/messagePopup";
import constant from "../../../../utils/constant/constant";
import { GoogleApi } from "../../../../api/google/googleApi";
import { connect } from "react-redux";
import Profile from "../../../../model/users/profile";
import { UserContextState } from "../../../../redux/actions/userContextAction";
import GoogleSignInModal from "../../popup/googleSignInModal";
import GoogleMappingModal from "../../popup/googleMappingModal";
import { CanvasApi } from "../../../../api/canvas/canvasApi";
import CanvasSignInModal from "../../popup/canvasSignInModal";
import { CanvasConfiguration } from "../../../../environment/setup";
import { XIcon } from "@heroicons/react/outline";
import QuestionPreview from "../shared/questionPreview/questionPreview";
import { isAllowViewAssignmentAssessment } from "../../../../utils/assessmentHelper";
import InfoNote from "../../../shared/InfoNote";
import { getAssignmentBankUpgradePermission } from "../../../shared/permissionHelper";
import { ISchoolYear } from "../../../../model/teacher/schoolYear";
import {
  getMaxSchoolYearDate,
  getMinSchoolYearDate,
} from "../../../../utils/dateHelper";
import { useConfirmation } from "../../../shared/confirmation/confirmationService";
import RosterAssignmentStatusPopup from "./rosterAssignmentStatusPopup";
import { IAssignmentStatus } from "../../../../model/externalRoster/externalRosterType";
import moment from "moment";
import { validateSyncGoogleAssignment } from "../../../../utils/googleHelper";

interface SettingProps {
  userId: number;
  assignment: IAssignment;
  userContext?: UserContextState;
  profile?: Profile;
  updateAssignment: (updatedAsignment: IAssignment) => void;
  schoolYearData?: Array<ISchoolYear>;
}

function Setting(props: SettingProps) {
  const numericExpression = new RegExp(/^[0-9\b]+$/);
  const history = useHistory();
  const { userId, assignment, updateAssignment } = props;

  type SettingType =
    | "MinimumScore"
    | "MultipleAttempt"
    | "AllowRetry"
    | "MaximumCharLimit"
    | "MaximumPoint";

  type RowSettingType = "ApplyAbove" | "ApplyBelow" | "ApplyAll";

  const unlimitedSelectValue: ISelectElement = {
    label: "Unlimited",
    value: "999",
  };

  const multipleAttemptsOptions: Array<ISelectElement> = [
    { label: "1", value: "1" },
    { label: "2", value: "2" },
    { label: "3", value: "3" },
    { label: "4", value: "4" },
    { label: "5", value: "5" },
    { label: unlimitedSelectValue.label, value: unlimitedSelectValue.value },
  ];

  const [activities, setActivities] = useState<Array<IAssignmentActivity>>([]);
  const [selectedStartDate, setStartDate] = useState<Date>();
  const [selectedDueDate, setDueDate] = useState<Date>();
  const [addToSchoolBank, setAddToSchoolBank] = useState<boolean>(false);
  const [activitiesInOrder, setActivitiesInOrder] = useState<boolean>(false);
  const [showDetailsAfterDueDate, setShowDetailsAfterDueDate] =
    useState<boolean>(false);
  const [updateState, setUpdateState] = useState<boolean>(false);
  const [multipleAttempts] = useState<Array<ISelectElement>>(
    multipleAttemptsOptions
  );
  const [minimumScores, setMinimumScores] = useState<Array<ISelectElement>>([]);
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [settingType, setSettingType] = useState<SettingType>();
  const [hoverElement, setHoverElement] = useState<string>("");
  const [settingDetail, setSettingDetail] = useState<{
    settingType: RowSettingType;
    rowIndex: number;
    value: string;
  }>();
  const [enableAddSchoolBank, setEnableAddSchoolBank] = useState<boolean>(true);
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [isMessagePopupShowed, setIsMessagePopupShowed] =
    useState<boolean>(false);
  const [infoMessage, setInfoMessage] = useState<string>("");
  const [messagePopup, setMessagePopup] = useState<boolean>(false);
  const [errorMessagePopup, setErrorMessagePopup] = useState<boolean>(false);
  const [showGooglePopup, setShowGooglePopup] = useState({
    show: false,
    mode: Constant.GoogleSignInMode.SSO,
  });
  const [showGoogleMappingPopup, setShowGoogleMappingPopup] = useState(false);
  const [showCanvasPopup, setShowCanvasPopup] = useState({
    isopen: false,
    mode: "SIGNIN",
  });

  const [showActivityRemovePopup, setShowActivityRemovePopup] = useState(false);
  const [selectedRemoveActivity, setSelectedRemoveActivity] = useState<
    IAssignmentActivity | undefined
  >(undefined);

  const [activityPreviewDetail, setActivityPreviewDetail] = useState<{
    previewId: string;
    activityIdPreview: number;
    activityName: string;
    activityType: string;
    standardId: number;
  }>();
  const [open, setOpen] = useState<boolean>(false);
  const [upgrade, setUpgrade] = useState(false);
  const [minDate, setMinDate] = useState<Date>();
  const [maxDate, setMaxDate] = useState<Date>();
  const [showAssignmentStatusPopup, setShowAssignmentStatusPopup] = useState<{
    show: boolean;
    status: Array<IAssignmentStatus>;
  }>({ show: false, status: [] });

  const confirm = useConfirmation();

  useEffect(() => {
    checkUpgradeRequired();
  }, []);

  useEffect(() => {
    var selectedSchoolYear = props.schoolYearData?.filter(
      (s) => s.schoolYearId == assignment.schoolYearId
    )[0];
    const minDate = getMinSchoolYearDate(selectedSchoolYear);
    setMinDate(minDate < new Date() ? new Date() : minDate);
    setMaxDate(getMaxSchoolYearDate(selectedSchoolYear));
  }, [props.schoolYearData]);

  const checkUpgradeRequired = async () => {
    if (getAssignmentBankUpgradePermission(props.userContext?.roleId ?? 0)) {
      setUpgrade(true);
    } else {
      setUpgrade(false);
    }
  };

  const toggleErrorMessagePopup = () => {
    setErrorMessagePopup(false);
  };

  const handleDragEnd = (result: any) => {
    if (!result.destination || activities.length <= 1) return;
    const tempActivities = [...activities];
    const [selectedRow] = tempActivities.splice(result.source.index, 1);
    tempActivities.splice(result.destination.index, 0, selectedRow);
    setActivities(tempActivities);
  };

  function handleStartDate(date: any) {
    if (selectedDueDate && date > selectedDueDate) {
      return false;
    }
    let selectedDate = moment(
      new Date(date.getFullYear(), date.getMonth(), date.getDate())
    ).set({ hour: 0, minute: 0, second: 0 });

    let startDate = selectedDate.toDate();
    setStartDate(startDate);
    updateAssignment(assignment);
  }

  function isLessThan(date, comparedDate) {
    return (
      new Date(date.getFullYear(), date.getMonth(), date.getDate()) <
      new Date(
        comparedDate.getFullYear(),
        comparedDate.getMonth(),
        comparedDate.getDate()
      )
    );
  }

  function handleDueDate(date: any) {
    if (selectedStartDate && isLessThan(date, selectedStartDate)) {
      return false;
    }
    let selectedDate = moment(
      new Date(date.getFullYear(), date.getMonth(), date.getDate())
    ).set({ hour: 23, minute: 59, second: 59 });

    let endDate = selectedDate.toDate();
    setDueDate(endDate);

    updateAssignment(assignment);
  }

  function toggleAddToSchoolBank() {
    assignment.isSharedWithSchool = !addToSchoolBank;
    setAddToSchoolBank(!addToSchoolBank);
    updateAssignment(assignment);
  }

  function toggleActivitiesInOrder() {
    assignment.completeInOrder = !activitiesInOrder;
    setActivitiesInOrder(!activitiesInOrder);
    updateAssignment(assignment);
  }

  function toggleReleaseItemDetails() {
    assignment.showDetailsAfterDueDate = !showDetailsAfterDueDate;
    setShowDetailsAfterDueDate(!showDetailsAfterDueDate);
    updateAssignment(assignment);
  }

  function updateActivitySettings(
    settingType: SettingType,
    activityType: string,
    keyId: string,
    value: any
  ) {
    switch (activityType) {
      case Constant.AssignmentActivityType.ASSESSMENT:
        activities.forEach((activity: IAssignmentActivity) => {
          if (
            activity.activityType ===
              Constant.AssignmentActivityType.ASSESSMENT &&
            (activity.assessmentId === keyId || activity.cannedTestId === keyId)
          ) {
            setSelectedValue(activity, settingType, value);
          }
        });
        break;
      case Constant.AssignmentActivityType.PREBUILTTESTS:
        activities.forEach((activity: IAssignmentActivity) => {
          if (
            activity.activityType ===
              Constant.AssignmentActivityType.PREBUILTTESTS &&
            (activity.domainId === keyId || activity.summativeTestId === keyId)
          ) {
            setSelectedValue(activity, settingType, value);
          }
        });
        break;
      case Constant.AssignmentActivityType.PRACTICEQUESTIONS:
        activities.forEach((activity: IAssignmentActivity) => {
          if (
            activity.activityType ===
              Constant.AssignmentActivityType.PRACTICEQUESTIONS &&
            activity.standardId === keyId
          ) {
            setSelectedValue(activity, settingType, value);
          }
        });
        break;
      case Constant.AssignmentActivityType.VIDEOLESSON:
        activities.forEach((activity: IAssignmentActivity) => {
          if (
            activity.activityType ===
              Constant.AssignmentActivityType.VIDEOLESSON &&
            activity.questionId === keyId
          ) {
            setSelectedValue(activity, settingType, value);
          }
        });
        break;
      case Constant.AssignmentActivityType.CONSTRUCTEDRESPONSE:
        activities.forEach((activity: IAssignmentActivity) => {
          if (
            activity.activityType ===
              Constant.AssignmentActivityType.CONSTRUCTEDRESPONSE &&
            activity.questionId === keyId
          ) {
            setSelectedValue(activity, settingType, value);
          }
        });
        break;
    }

    updateAssignment(assignment);
    setUpdateState(!updateState);
  }

  function setSelectedValue(
    activity: IAssignmentActivity,
    settingType: SettingType,
    value: any
  ) {
    var selectedValue: any = "";
    var prevMinimumScore = activity.minimumScore;
    switch (settingType) {
      case "MinimumScore":
        selectedValue = value.value;
        activity.minimumScore = selectedValue;
        break;
      case "MultipleAttempt":
        selectedValue = value.value;
        activity.multipleAttempt = selectedValue;
        break;
      case "AllowRetry":
        activity.allowRetry = value;
        break;
      case "MaximumCharLimit":
        selectedValue = value.target.value;
        if (selectedValue > 1000000) {
          selectedValue = "";
          return false;
        }
        activity.maximumCharacter = selectedValue;
        break;
      case "MaximumPoint":
        selectedValue = value.target.value;
        if (selectedValue !== "" && !numericExpression.test(selectedValue)) {
          selectedValue = "";
          return false;
        }

        if (selectedValue > 100) {
          selectedValue = "";
          return false;
        }
        activity.maximumPoint = selectedValue;
        break;
    }

    if (
      settingType === "MinimumScore" &&
      value.label !== "None" &&
      prevMinimumScore === 0 &&
      activity.activityType !==
        Constant.AssignmentActivityType.CONSTRUCTEDRESPONSE &&
      !isMessagePopupShowed
    ) {
      setInfoMessage(
        "Unlimited multiple attempts have been enabled because you are requiring a minimum score."
      );
      setMessagePopup(true);
      setIsMessagePopupShowed(true);
    }

    if (activity.multipleAttempt! > 1 || activity.minimumScore! > 0) {
      activity.allowRetry = false;
    }
  }

  function getSelectedValue(
    settingType: SettingType,
    activityType: string,
    keyId: string
  ) {
    switch (activityType) {
      case Constant.AssignmentActivityType.ASSESSMENT:
        const assessment = activities.filter(
          (r) =>
            r.activityType === Constant.AssignmentActivityType.ASSESSMENT &&
            (r.assessmentId === keyId || r.cannedTestId === keyId)
        );
        if (assessment && assessment.length > 0) {
          return getSettingValue(assessment[0], settingType);
        }
        break;
      case Constant.AssignmentActivityType.PREBUILTTESTS:
        const preBuild = activities.filter(
          (r) =>
            r.activityType === Constant.AssignmentActivityType.PREBUILTTESTS &&
            (r.domainId === keyId ||
              r.summativeTestId === keyId ||
              r.cannedTestId === keyId)
        );
        if (preBuild && preBuild.length > 0) {
          return getSettingValue(preBuild[0], settingType);
        }
        break;
      case Constant.AssignmentActivityType.PRACTICEQUESTIONS:
        const practiseQuestions = activities.filter(
          (r) =>
            r.activityType ===
              Constant.AssignmentActivityType.PRACTICEQUESTIONS &&
            r.standardId === keyId
        );
        if (practiseQuestions && practiseQuestions.length > 0) {
          return getSettingValue(practiseQuestions[0], settingType);
        }
        break;
      case Constant.AssignmentActivityType.VIDEOLESSON:
        const videoLessons = activities.filter(
          (r) =>
            r.activityType === Constant.AssignmentActivityType.VIDEOLESSON &&
            r.questionId === keyId
        );
        if (videoLessons && videoLessons.length > 0) {
          return getSettingValue(videoLessons[0], settingType);
        }
        break;
      case Constant.AssignmentActivityType.CONSTRUCTEDRESPONSE:
        const crQuestions = activities.filter(
          (r) =>
            r.activityType ===
              Constant.AssignmentActivityType.CONSTRUCTEDRESPONSE &&
            r.questionId === keyId
        );
        if (crQuestions && crQuestions.length > 0) {
          return getSettingValue(crQuestions[0], settingType);
        }
        break;
    }
  }

  function getSettingValue(
    activity: IAssignmentActivity,
    settingType: SettingType
  ) {
    var settingValue: any;
    switch (settingType) {
      case "MinimumScore":
        const minimumScore: ISelectElement = {
          label: activity.minimumScore ? activity.minimumScore?.toString() : "",
          value: activity.minimumScore ? activity.minimumScore?.toString() : "",
        };
        settingValue = minimumScore;
        break;
      case "MultipleAttempt":
        const multipleAttempt: ISelectElement = {
          label: activity.multipleAttempt
            ? activity.multipleAttempt.toString() === unlimitedSelectValue.value
              ? unlimitedSelectValue.label
              : activity.multipleAttempt.toString()
            : "1",
          value: activity.multipleAttempt
            ? activity.multipleAttempt?.toString()
            : "1",
        };
        settingValue =
          activity.minimumScore && activity.minimumScore?.toString() !== "0"
            ? unlimitedSelectValue
            : multipleAttempt;
        break;
      case "AllowRetry":
        settingValue = activity.allowRetry ? activity.allowRetry : false;
        break;
      case "MaximumCharLimit":
        settingValue = activity.maximumCharacter
          ? activity.maximumCharacter
          : "";
        break;
      case "MaximumPoint":
        settingValue = activity.maximumPoint ? activity.maximumPoint : "";
        break;
    }

    return settingValue;
  }

  function setExistingValue() {
    if (assignment) {
      if (assignment.startDate) {
        setStartDate(new Date(assignment.startDate));
      }
      if (assignment.endDate) {
        setDueDate(new Date(assignment.endDate));
      }
      if (assignment.isSharedWithSchool) {
        setAddToSchoolBank(assignment.isSharedWithSchool);
      }
      if (assignment.isSharedWithSchool) {
        setAddToSchoolBank(assignment.isSharedWithSchool);
      }
      if (assignment.completeInOrder) {
        setActivitiesInOrder(assignment.completeInOrder);
      }
      if (assignment.showDetailsAfterDueDate) {
        setShowDetailsAfterDueDate(assignment.showDetailsAfterDueDate);
      }
    }
  }

  function getKeyId(activityType: string, activity: IAssignmentActivity) {
    var value: string = "";
    switch (activityType) {
      case Constant.AssignmentActivityType.ASSESSMENT:
        if (activity.cannedTestId) {
          value = activity.cannedTestId;
        } else {
          value = activity.assessmentId ? activity.assessmentId : "";
        }
        break;
      case Constant.AssignmentActivityType.PREBUILTTESTS:
        if (activity.domainId) {
          value = activity.domainId;
        } else if (activity.summativeTestId) {
          value = activity.summativeTestId;
        } else if (activity.cannedTestId) {
          value = activity.cannedTestId;
        }
        break;
      case Constant.AssignmentActivityType.PRACTICEQUESTIONS:
        value = activity.standardId ? activity.standardId : "";
        break;
      case Constant.AssignmentActivityType.VIDEOLESSON:
      case Constant.AssignmentActivityType.CONSTRUCTEDRESPONSE:
        value = activity.questionId ? activity.questionId : "";
        break;
    }

    return value;
  }

  function shouldDisableCRSettings(activity: IAssignmentActivity): boolean {
    return activity.completed == null || activity.completed > 0;
  }

  function handleSaveActivities() {
    if (
      !assignment.activities ||
      (assignment.activities && assignment.activities.length === 0)
    ) {
      toast.error("Please add activity");
      return false;
    } else if (assignment.students && assignment.students.length > 0) {
      let hasError: boolean = false;
      if (!selectedStartDate && !selectedDueDate) {
        toast.error("Start date and due date cannot be empty.");
        hasError = true;
      } else if (!selectedStartDate && selectedDueDate) {
        toast.error("Start date cannot be empty.");
        hasError = true;
      } else if (selectedStartDate && !selectedDueDate) {
        toast.error("Due date cannot be empty.");
        hasError = true;
      }

      if (hasError) {
        return false;
      }
    }
    if (!hasPointValueAdded()) {
      toast.error(
        "Point value cannot be empty for constructed response activity."
      );
      return false;
    }

    function hasPointValueAdded() {
      let pointValueAdded: boolean = true;
      const crActivities: Array<IAssignmentActivity> = activities.filter(
        (activity) =>
          activity.activityType ===
          Constant.AssignmentActivityType.CONSTRUCTEDRESPONSE
      );

      if (crActivities.length > 0) {
        const pointAddedActivities = crActivities.filter(
          (r) => r.maximumPoint && r.maximumPoint > 0
        );
        pointValueAdded = crActivities.length === pointAddedActivities.length;
      }

      return pointValueAdded;
    }

    function getDefaulMultipleAttempt(activity: IAssignmentActivity) {
      let mulipleAttempt: number = 1;
      if (!isSettingEligible(activity, "MultipleAttempt")) {
        return mulipleAttempt;
      } else if (activity.minimumScore && activity.minimumScore > 0) {
        mulipleAttempt = parseInt(unlimitedSelectValue.value);
      } else if (activity.multipleAttempt && activity.multipleAttempt > 0) {
        mulipleAttempt = activity.multipleAttempt;
      }

      return mulipleAttempt;
    }

    activities.forEach((activity, index) => {
      activity.sequenceNumber = index + 1;
      activity.multipleAttempt = getDefaulMultipleAttempt(activity);
    });

    if (activities && activities.length > 0) {
      setShowLoading(true);
      updateActivitySetting(userId, activities)
        .then((response) => {
          setShowLoading(false);
          toast.success("Settings saved successfully.");
          history.push(RouteConstant.ASSIGNMENTBANK);
        })
        .catch(() => {
          setShowLoading(false);
          setErrorMessagePopup(true);
        });
    }

    if (assignment.assignmentId > 0) {
      if (assignment.assignmentStatusId === Constant.AssignmentStatusId.DRAFT) {
        assignment.assignmentStatusId = Constant.AssignmentStatusId.NEW;
      }

      if (selectedStartDate) {
        assignment.startDate = selectedStartDate.toISOString().slice(0, -1);
      }
      if (selectedDueDate) {
        assignment.endDate = selectedDueDate.toISOString().slice(0, -1);
      }

      updateAssignmentData(userId, assignment).then(() => {
        history.push(RouteConstant.ASSIGNMENTBANK);
      });
    }
  }

  const validateShowGooglePopup = () => {
    if (!validateIsGoogleUser(props.profile!)) {
      setShowGooglePopup({ show: true, mode: Constant.GoogleSignInMode.SSO });
      return false;
    } else if (!props.profile?.gmailId) {
      setShowGoogleMappingPopup(true);
      return false;
    }
    return true;
  };

  const validateShowCanvasPopup = () => {
    if (!validateIsCanvasUser(props.profile!)) {
      setShowCanvasPopup({ isopen: true, mode: "MAPPING" });
      return false;
    }
    return true;
  };

  function handleSyncAssignmentToGoogleClassroom() {
    if (!validateShowGooglePopup()) {
      return false;
    }

    if (
      validateSyncGoogleAssignment(
        assignment,
        selectedStartDate,
        selectedDueDate,
        toast
      )
    ) {
      return false;
    }

    setShowLoading(true);
    GoogleApi.syncAssignmentsToGoogle(
      assignment.assignmentId,
      props.userId,
      props.profile?.googleUserId!,
      props.profile?.accountLoginName!,
      selectedStartDate!,
      selectedDueDate!,
      props.profile?.googleUser?.refreshToken!
    )
      .then((r) => {
        setShowLoading(false);
        if (r.data.isSuccess === false && r.data.code === "PERMISSION_DENIED") {
          setShowGooglePopup({
            show: true,
            mode: Constant.GoogleSignInMode.AUTHORIZE,
          });
        } else if (r.data.isSuccess === true) {
          if (r.data.code === "ASSIGNMENT_PARTIALLY_SYNCED") {
            toast.warning(r.data.message);
          } else {
            toast.success(r.data.message);
          }
        } else {
          toast.error(r.data.message);
        }
      })
      .catch((r) => {
        setShowLoading(false);
        toast.error("Failed to sync assignment. " + (r.response?.data?.Message !== undefined ? r.response?.data?.Message : ""));
      });
  }

  function handleSyncAssignmentToCanvas() {
    if (!validateShowCanvasPopup()) {
      return false;
    }
    if (assignment.students && assignment.students.length > 0) {
      let hasError: boolean = false;
      if (!selectedStartDate && !selectedDueDate) {
        toast.error("Start date and due date cannot be empty.");
        hasError = true;
      } else if (!selectedStartDate && selectedDueDate) {
        toast.error("Start date cannot be empty.");
        hasError = true;
      } else if (selectedStartDate && !selectedDueDate) {
        toast.error("Due date cannot be empty.");
        hasError = true;
      }

      if (hasError) {
        return false;
      }
    }

    setShowLoading(true);
    CanvasApi.syncAssignemntsToCanvas(
      assignment.assignmentId,
      props.userId,
      selectedDueDate!,
      props.profile?.accountLoginName!,
      props.profile?.canvasAccountSettings!
    )
      .then((r) => {
        setShowAssignmentStatusPopup({ show: true, status: r.data });
        setShowLoading(false);
      })
      .catch((r) => {
        setShowLoading(false);
        toast.error("Failed to sync assignment.");
      });
  }

  function getActivitySubtype(activity: IAssignmentActivity) {
    let activitySubtype = "";
    switch (activity.activityType) {
      case Constant.AssignmentActivityType.ASSESSMENT:
        if (activity.cannedTestId) {
          if (activity.cannedTestTypeId === Constant.CannedTestTypeId.PreTest) {
            activitySubtype =
              Constant.AssignmentActivitySubType.ASSESSMENT.DIAGNOSTIC_PRETEST;
          } else if (
            activity.cannedTestTypeId === Constant.CannedTestTypeId.PostTest
          ) {
            activitySubtype =
              Constant.AssignmentActivitySubType.ASSESSMENT.DIAGNOSTIC_POSTTEST;
          } else if (
            activity.cannedTestTypeId === Constant.CannedTestTypeId.PracticeTest
          ) {
            activitySubtype =
              Constant.AssignmentActivitySubType.ASSESSMENT
                .STATE_PRACTICE_ASSESSMENT;
          } else if (
            activity.cannedTestTypeId === Constant.CannedTestTypeId.CannedTest
          ) {
            activitySubtype =
              Constant.AssignmentActivitySubType.ASSESSMENT.CANNEDTEST;
          }
        } else if (activity.assessmentId) {
          activitySubtype =
            Constant.AssignmentActivitySubType.ASSESSMENT.ASSESSMENT;
        }
        break;
      case Constant.AssignmentActivityType.PREBUILTTESTS:
        if (activity.domainId) {
          activitySubtype =
            Constant.AssignmentActivitySubType.PREBUILTTESTS.DOMAIN;
        } else if (activity.cannedTestId) {
          activitySubtype =
            Constant.AssignmentActivitySubType.PREBUILTTESTS.CANNEDTEST;
        } else if (activity.summativeTestId) {
          activitySubtype =
            Constant.AssignmentActivitySubType.PREBUILTTESTS.SUMMATIVE;
        }
        break;
      case Constant.AssignmentActivityType.PRACTICEQUESTIONS:
        activitySubtype =
          Constant.AssignmentActivitySubType.PRACTICEQUESTIONS
            .PRACTICEQUESTIONS;
        break;
      case Constant.AssignmentActivityType.VIDEOLESSON:
        activitySubtype =
          Constant.AssignmentActivitySubType.VIDEOLESSON.VIDEOLESSON;
        break;
      case Constant.AssignmentActivityType.CONSTRUCTEDRESPONSE:
        activitySubtype =
          Constant.AssignmentActivitySubType.CONSTRUCTEDRESPONSE
            .CONSTRUCTEDRESPONSE;
        break;
    }
    return activitySubtype;
  }

  function isSettingEligible(
    activity: IAssignmentActivity,
    settingType: SettingType
  ) {
    const allPrebuildActivitySubtypes = getActivitySubTypes(
      Constant.AssignmentActivityType.PREBUILTTESTS //SUMMATIVE, DOMAIN, CANNEDTEST
    );

    const settingEligibility: { key: SettingType; value: Array<string> }[] = [
      {
        key: "MultipleAttempt",
        value: [
          Constant.AssignmentActivitySubType.ASSESSMENT.ASSESSMENT,
          Constant.AssignmentActivitySubType.ASSESSMENT.CANNEDTEST,
          Constant.AssignmentActivitySubType.ASSESSMENT
            .STATE_PRACTICE_ASSESSMENT,
          Constant.AssignmentActivitySubType.PRACTICEQUESTIONS
            .PRACTICEQUESTIONS,
          ...allPrebuildActivitySubtypes,
          Constant.AssignmentActivitySubType.VIDEOLESSON.VIDEOLESSON,
        ],
      },
      {
        key: "MaximumCharLimit",
        value: [
          Constant.AssignmentActivitySubType.CONSTRUCTEDRESPONSE
            .CONSTRUCTEDRESPONSE,
        ],
      },
      {
        key: "MaximumPoint",
        value: [
          Constant.AssignmentActivitySubType.CONSTRUCTEDRESPONSE
            .CONSTRUCTEDRESPONSE,
        ],
      },
    ];

    return settingEligibility
      .filter((r) => r.key === settingType)[0]
      .value.includes(getActivitySubtype(activity));
  }

  function applySettingToAll(settingType: SettingType, value: any) {
    if (activities && activities.length > 0) {
      switch (settingType) {
        case "MinimumScore":
          activities.forEach((activity: IAssignmentActivity) => {
            if (isSettingEligible(activity, settingType)) {
              activity.minimumScore = value;
            }
          });
          break;
        case "MultipleAttempt":
          activities.forEach((activity: IAssignmentActivity) => {
            if (isSettingEligible(activity, settingType)) {
              activity.multipleAttempt = value;
            }
          });
          break;
        case "AllowRetry":
          activities.forEach((activity: IAssignmentActivity) => {
            if (isSettingEligible(activity, settingType)) {
              activity.allowRetry = value;
            }
          });
          break;
        case "MaximumCharLimit":
          activities.forEach((activity: IAssignmentActivity) => {
            if (isSettingEligible(activity, settingType)) {
              activity.maximumCharacter = value;
            }
          });
          break;
        case "MaximumPoint":
          activities.forEach((activity: IAssignmentActivity) => {
            if (isSettingEligible(activity, settingType)) {
              activity.maximumPoint = value;
            }
          });
          break;
      }
    }
    setUpdateState(!updateState);
  }

  function applySettingToAbove(
    settingType: SettingType,
    value: any,
    rowIndex: number
  ) {
    if (activities && activities.length > 0) {
      switch (settingType) {
        case "MinimumScore":
          activities.forEach((activity: IAssignmentActivity, index: number) => {
            if (isSettingEligible(activity, settingType)) {
              if (index === rowIndex - 1) {
                activity.minimumScore = value;
              }
            }
          });
          break;
        case "MultipleAttempt":
          activities.forEach((activity: IAssignmentActivity, index: number) => {
            if (isSettingEligible(activity, settingType)) {
              if (index === rowIndex - 1) {
                activity.multipleAttempt = value;
              }
            }
          });
          break;
        case "AllowRetry":
          activities.forEach((activity: IAssignmentActivity, index: number) => {
            if (isSettingEligible(activity, settingType)) {
              if (index === rowIndex - 1) {
                activity.allowRetry = value;
              }
            }
          });
          break;
        case "MaximumCharLimit":
          activities.forEach((activity: IAssignmentActivity, index: number) => {
            if (isSettingEligible(activity, settingType)) {
              if (index === rowIndex - 1) {
                activity.maximumCharacter = value;
              }
            }
          });
          break;
        case "MaximumPoint":
          activities.forEach((activity: IAssignmentActivity, index: number) => {
            if (isSettingEligible(activity, settingType)) {
              if (index === rowIndex - 1) {
                activity.maximumPoint = value;
              }
            }
          });
          break;
      }
    }
    setUpdateState(!updateState);
  }

  function applySettingToBelow(
    settingType: SettingType,
    value: any,
    rowIndex: number
  ) {
    if (activities && activities.length > 0) {
      switch (settingType) {
        case "MinimumScore":
          activities.forEach((activity: IAssignmentActivity, index: number) => {
            if (isSettingEligible(activity, settingType)) {
              if (index === rowIndex + 1) {
                activity.minimumScore = value;
              }
            }
          });
          break;
        case "MultipleAttempt":
          activities.forEach((activity: IAssignmentActivity, index: number) => {
            if (isSettingEligible(activity, settingType)) {
              if (index === rowIndex + 1) {
                activity.multipleAttempt = value;
              }
            }
          });
          break;
        case "AllowRetry":
          activities.forEach((activity: IAssignmentActivity, index: number) => {
            if (isSettingEligible(activity, settingType)) {
              if (index === rowIndex + 1) {
                activity.allowRetry = value;
              }
            }
          });
          break;
        case "MaximumCharLimit":
          activities.forEach((activity: IAssignmentActivity, index: number) => {
            if (isSettingEligible(activity, settingType)) {
              if (index === rowIndex + 1) {
                activity.maximumCharacter = value;
              }
            }
          });
          break;
        case "MaximumPoint":
          activities.forEach((activity: IAssignmentActivity, index: number) => {
            if (isSettingEligible(activity, settingType)) {
              if (index === rowIndex + 1) {
                activity.maximumPoint = value;
              }
            }
          });
          break;
      }
    }
    setUpdateState(!updateState);
  }

  function handleConfirmation(settingType: SettingType) {
    setShowConfirmation(!showConfirmation);
    setSettingType(settingType);
  }

  function handleOkConfirmation() {
    if (settingType && settingDetail?.settingType) {
      switch (settingDetail.settingType) {
        case "ApplyAll":
          applySettingToAll(settingType, settingDetail.value);
          break;
        case "ApplyAbove":
          applySettingToAbove(
            settingType,
            settingDetail.value,
            settingDetail.rowIndex
          );
          break;
        case "ApplyBelow":
          applySettingToBelow(
            settingType,
            settingDetail.value,
            settingDetail.rowIndex
          );
          break;
      }

      setShowConfirmation(!showConfirmation);
    }
  }

  function handleCancelConfirmation() {
    setShowConfirmation(!showConfirmation);
    setSettingType(undefined);
  }

  function canActivityUpdate() {
    return assignment.assignmentId > 0 &&
      assignment.assignmentStatus &&
      (assignment.assignmentStatus.toLocaleUpperCase() ===
        Constant.AssignmentStatus.INPROGRESS ||
        assignment.assignmentStatus.toLocaleUpperCase() ===
          Constant.AssignmentStatus.COMPLETE)
      ? false
      : true;
  }

  function handleRemoveActivityPopup(activity: IAssignmentActivity) {
    if (
      !assignment.activities ||
      (assignment.activities && assignment.activities.length === 1)
    ) {
      toast.error("At least one activity should exist in the assignment.");
      return false;
    }

    setSelectedRemoveActivity(activity);
    setShowActivityRemovePopup(true);
  }

  async function handleRemoveActivityRemoveConfirmation() {
    if (selectedRemoveActivity?.assignmentActivityId) {
      let activityList = activities.filter(
        (act) =>
          act.assignmentActivityId !==
          selectedRemoveActivity.assignmentActivityId
      );
      setShowActivityRemovePopup(false);
      setShowLoading(true);
      await addAssignmentActivity(
        userId,
        assignment.assignmentId,
        assignment.name,
        assignment.subjectId,
        activityList,
        false,
        true,
        selectedRemoveActivity.activityTypeId,
        true
      );
      setShowLoading(false);
      assignment.activities = activityList;
      setActivities(activityList);
      setSelectedRemoveActivity(undefined);
    }
  }

  function handleCancelActivityRemoveConfirmation() {
    setSelectedRemoveActivity(undefined);
    setShowActivityRemovePopup(false);
  }

  function applyAll(setting: string, index: number, value: string): void {
    const settingDetail: {
      settingType: RowSettingType;
      rowIndex: number;
      value: string;
    } = { settingType: "ApplyAll", rowIndex: index, value: value };
    setSettingDetail(settingDetail);
    handleConfirmation(setting as SettingType);
  }

  function applyAllAbove(setting: string, index: number, value: string): void {
    const settingDetail: {
      settingType: RowSettingType;
      rowIndex: number;
      value: string;
    } = { settingType: "ApplyAbove", rowIndex: index, value: value };
    setSettingDetail(settingDetail);
    handleConfirmation(setting as SettingType);
  }

  function applyAllBelow(setting: string, index: number, value: string): void {
    const settingDetail: {
      settingType: RowSettingType;
      rowIndex: number;
      value: string;
    } = { settingType: "ApplyBelow", rowIndex: index, value: value };
    setSettingDetail(settingDetail);
    handleConfirmation(setting as SettingType);
  }

  function toggleMessagePopup() {
    setMessagePopup(false);
  }

  useEffect(() => {
    setShowLoading(true);
    getAssignmentById(assignment.assignmentId, null, true).then((response) => {
      setShowLoading(false);
      const activityDetails: Array<IAssignmentActivity> =
        response.data.activities;
      const assessments = activityDetails.filter(
        (r) => r.activityType === Constant.AssignmentActivityType.ASSESSMENT
      );
      if (assessments && assessments.length > 0) {
        const selectedAssessments = assessments.length;
        const sharedAssessments = assessments.filter(
          (r) => r.isSharedAssessment
        ).length;

        setEnableAddSchoolBank(selectedAssessments === sharedAssessments);
      }

      var activities: Array<IAssignmentActivity> = [];
      const hasCombineActivity = activityDetails.some(
        (r) => r.isCombinedActivity
      );
      if (hasCombineActivity) {
        const combineActivity = activityDetails.filter(
          (r) => r.isCombinedActivity
        )[0];
        const otherActivities = activityDetails.filter(
          (r) => !r.isCombinedActivity
        );
        activities = [...otherActivities, combineActivity];
        setActivities(OrderByArray(activities, "sequenceNumber"));
      } else {
        setActivities(OrderByArray(activityDetails, "sequenceNumber"));
      }
    });

    setExistingValue();
    let minScores: Array<ISelectElement> = [];
    minScores.push({ label: "None", value: "0" });
    for (let i = 1; i <= 100; i++) {
      if (i % 5 === 0) {
        minScores.push({ label: i.toString() + "%", value: i.toString() });
      }
    }

    setMinimumScores(minScores);
  }, []);

  const handlePreviewPopup = (activity: any) => {
    openModal(
      activity?.learnosityIdEnglish,
      activity.assignmentId,
      activity ? getActivityName(activity) : "",
      activity?.activityType,
      activity?.standardId
    );
  };

  const openModal = (
    learnosityReferenceId,
    activityId,
    activityName,
    activityType,
    standardId
  ) => {
    if (!learnosityReferenceId) {
      return false;
    }

    setOpen(true);
    setActivityPreviewDetail({
      previewId: learnosityReferenceId,
      activityIdPreview: activityId,
      activityName: activityName,
      activityType: activityType,
      standardId: standardId,
    });
  };

  const popUpClose = (value) => {
    setOpen(value);
    setActivityPreviewDetail(undefined);
  };

  function getActivityRef(activityType: string, reference: string) {
    let referenceId: string | null = null;
    switch (activityType) {
      case Constant.AssignmentActivityType.ASSESSMENT:
      case Constant.AssignmentActivityType.PREBUILTTESTS:
      case Constant.AssignmentActivityType.PRACTICEQUESTIONS:
        referenceId = reference;
        break;
    }

    return referenceId;
  }

  function getItemRef(activityType: string, reference: string) {
    let referenceId: string | null = null;
    switch (activityType) {
      case Constant.AssignmentActivityType.VIDEOLESSON:
      case Constant.AssignmentActivityType.CONSTRUCTEDRESPONSE:
        referenceId = reference;
        break;
    }

    return referenceId;
  }
  
  function hasNonAssessments() {
    const nonAssessments = activities.filter(
      (a) =>
        a.activityTypeId !== Constant.AssignmentActivityTypeEnum.ASSESSMENT ||
        Number(a.cannedTestId ?? 0) > 0 //check for premium asssessments
    );
    return nonAssessments.length > 0;
  }

  return (
    <Fragment>
      {showLoading && <Loader />}
      <ReactTooltip
        multiline={true}
        className="bg-primary-violet"
        backgroundColor="#992883"
      />
      <ConfirmationDialog
        open={showConfirmation}
        confirmationTitle={"Please confirm"}
        confirmationMessage={
          "Are you sure you want update multiple activity settings?"
        }
        onCancelClick={handleCancelConfirmation}
        onOkClick={handleOkConfirmation}
      ></ConfirmationDialog>

      <ConfirmationDialog
        open={showActivityRemovePopup}
        confirmationTitle={"Please confirm"}
        confirmationMessage={
          "Are you sure you wish to remove this activity from the assignment?"
        }
        onCancelClick={handleCancelActivityRemoveConfirmation}
        onOkClick={handleRemoveActivityRemoveConfirmation}
        okButtonText={"Remove"}
        cancelButtonText={"Cancel"}
      ></ConfirmationDialog>

      {messagePopup && (
        <MessagePopup message={infoMessage} togglePopup={toggleMessagePopup} />
      )}
      {errorMessagePopup && (
        <MessagePopup
          message={constant.UserErrorMessage.AssignmentCreateError}
          togglePopup={toggleErrorMessagePopup}
        />
      )}

      {activityPreviewDetail && (
        <QuestionPreview
          modal={openModal}
          show={open}
          changeValue={popUpClose}
          activityRef={getActivityRef(
            activityPreviewDetail.activityType,
            activityPreviewDetail.previewId
          )}
          itemRef={getItemRef(
            activityPreviewDetail.activityType,
            activityPreviewDetail.previewId
          )}
          activityIdPreview={activityPreviewDetail.activityIdPreview}
          activityName={activityPreviewDetail.activityName}
          standardId={activityPreviewDetail.standardId}
          subjectId={assignment.subjectId}
        ></QuestionPreview>
      )}

      {showAssignmentStatusPopup.show && (
        <RosterAssignmentStatusPopup
          show={(e) => {
            setShowAssignmentStatusPopup({ show: e, status: [] });
          }}
          assignmentData={showAssignmentStatusPopup.status}
        />
      )}

      <div className="my-4 relative px-4 sm:px-6  lg:px-8 ">
        <div className="text-sm text-gray-700 font-light bg-blue-50 p-4 flex rounded-md mb-6">
          <div className="flex-shrink-0 current-fill text-blue-400">
            <InformationIcon />
          </div>
          <span className="pl-2">
            All of your chosen activities are displayed below. Each item can be
            manually moved by selecting
            <DragAndDropIcon />
            and dragging up or down. Select the order you would like your
            students to complete their tasks using the radio buttons below to
            complete in order or random.
          </span>
        </div>
        <div className="bg-gray-100  w-full mb-5 rounded-lg p-5 h-full">
          <div className="flex flex-col 2xl:flex-row 2xl:items-center gap-8">
            <div className="flex gap-8">
              <div>
                <label className="block text-sm text-gray-500">
                  Start Date
                </label>
                <DatePicker
                  disabled={!canActivityUpdate()}
                  className="mt-1 react-datepicker__input-container py-1.5 px-3 block w-full shadow-sm focus:ring-primary-violet focus:border-primary-violet border border-gray-300 rounded-md "
                  selected={selectedStartDate}
                  onChange={(date) => handleStartDate(date)}
                  minDate={minDate}
                  maxDate={maxDate}
                />
              </div>
              <div className="relative">
                <label className="block text-sm text-gray-500">Due Date</label>
                <DatePicker
                  disabled={
                    !assignment.students || assignment.students?.length === 0
                  }
                  className="mt-1 react-datepicker__input-container py-1.5 px-3 block w-full shadow-sm focus:ring-primary-violet focus:border-primary-violet border border-gray-300 rounded-md "
                  selected={selectedDueDate}
                  onChange={(date) => handleDueDate(date)}
                  minDate={minDate}
                  maxDate={maxDate}
                />
              </div>
            </div>
            <div className="flex flex-col w-full xl:flex-row  xl:gap-8">
              <div>
                <div className="block text-sm text-gray-500">
                  Add to school bank{" "}
                </div>
                <div className="mt-1">
                  {!upgrade && (
                    <Toggle
                      checked={addToSchoolBank}
                      disabled={!enableAddSchoolBank}
                      onChange={() => {
                        toggleAddToSchoolBank();
                      }}
                    />
                  )}
                  {upgrade && (
                    <Toggle
                      checked={addToSchoolBank}
                      disabled={true}
                      onChange={() => {}}
                    />
                  )}
                </div>
              </div>
              {activities && activities.length > 1 && (
                <div className="flex flex-col items-start col-span-2">
                  <div 
                    className={`cursor-pointer ${!canActivityUpdate() ? 'tooltip-disabled' : ''}`}
                    data-tip
                    data-for="toggleDisabledTooltip"
                  >
                    <div className="block text-sm text-gray-500">
                      Complete activities in specific order
                    </div>
                    <div className="mt-1">
                      <Toggle
                        checked={activitiesInOrder}
                        onChange={() => {
                          toggleActivitiesInOrder();
                        }}
                        disabled={!canActivityUpdate()}
                      />
                    </div>
                  </div>
                  {!canActivityUpdate() && (
                    <ReactTooltip
                      id="toggleDisabledTooltip"
                      class="bg-secondary-teal/10 z-50"
                      effect="solid"
                    >
                      <span>This setting is disabled because at least one student has started the activity.</span>
                    </ReactTooltip>
                  )}              
                </div>
              )}

              <div className="flex flex-col items-start col-span-2">
                <div className="block text-sm text-gray-500">
                  <span className="flex">
                    Release item details after due date
                    <InfoNote message="Enabling this setting will hide all item details (excluding scores) from students until the due date has passed. This setting applies to all activity types excluding user-authored assessments. User-authored assessment settings must be managed in Assessment Builder." />
                  </span>
                </div>
                <div className="mt-1">
                  <Toggle
                    checked={showDetailsAfterDueDate}
                    onChange={() => {
                      toggleReleaseItemDetails();
                    }}
                    disabled={!hasNonAssessments()}
                  />
                </div>
              </div>

              <div className="flex flex-wrap  justify-end gap-4 mt-1.5 items-end col-span-2 ml-auto">
                {!upgrade && (
                  <>
                    <button
                      onClick={() => {
                        if (props?.userContext?.impersonatedUser !== null) {
                          confirm({
                            variant: "info",
                            title: "Impersonation Detected",
                            description:
                              "This feature can only be accessed directly from the users login. Please reach out to the impersonated user to access this functionality.",
                          });
                        } else {
                          handleSyncAssignmentToGoogleClassroom();
                        }
                      }}
                      type="submit"
                      className={
                        (props?.userContext?.impersonatedUser !== null
                          ? "opacity-50 "
                          : "") +
                        "bg-transparent border border-primary-violet shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-primary-violet hover:text-white hover:bg-light-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition ease-in-out duration-500"
                      }
                    >
                      <GoogleClassroomIcon className="w-5" />
                      <span className="ml-2 text-sm">
                        Sync to Google Classroom
                      </span>
                    </button>
                    {CanvasConfiguration.EnableCanvas && (
                      <button
                        onClick={() => {
                          if (props?.userContext?.impersonatedUser !== null) {
                            confirm({
                              variant: "info",
                              title: "Impersonation Detected",
                              description:
                                "This feature can only be accessed directly from the users login. Please reach out to the impersonated user to access this functionality.",
                            });
                          } else {
                            handleSyncAssignmentToCanvas();
                          }
                        }}
                        type="submit"
                        className={
                          (props?.userContext?.impersonatedUser !== null
                            ? "opacity-50 "
                            : "") +
                          "bg-transparent border border-primary-violet shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-primary-violet hover:text-white hover:bg-light-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition ease-in-out duration-500"
                        }
                      >
                        <CanvasIcon className="w-5" />
                        <span className="ml-2 text-sm">Sync to Canvas</span>
                      </button>
                    )}
                  </>
                )}
                {upgrade && (
                  <>
                    <button
                      type="submit"
                      className="opacity-50 cursor-not-allowed  bg-transparent border border-primary-violet shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-primary-violet hover:text-white hover:bg-light-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition ease-in-out duration-500"
                    >
                      <GoogleClassroomIcon className="w-5" />
                      <span className="ml-2 text-sm">
                        Sync to Google Classroom
                      </span>
                    </button>
                    {CanvasConfiguration.EnableCanvas && (
                      <button
                        type="submit"
                        className="opacity-50 cursor-not-allowed  bg-transparent border border-primary-violet shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-primary-violet hover:text-white hover:bg-light-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition ease-in-out duration-500"
                      >
                        <CanvasIcon className="w-5" />
                        <span className="ml-2 text-sm">Sync to Canvas</span>
                      </button>
                    )}
                  </>
                )}

                <button
                  onClick={() => handleSaveActivities()}
                  type="submit"
                  className="lg:block bg-primary-violet border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-dark-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Save Assignment
                </button>
              </div>
            </div>
          </div>
          {!enableAddSchoolBank && (
            <div className="pl-2 flex bg-yellow-50 m-2">
              <ExclamationIcon className="w-4 text-yellow-500 mr-2" />
              <p>
                This assignment cannot be added to the school bank because it
                contains an assessment that is not shared.
              </p>
            </div>
          )}
          <div className="mb-0">
            <div>
              <div className="mt-2 bg-white py-3 px-5 shadow hover:shadow-lg">
                <div className="overflow-x-auto xl:overflow-x-visible ">
                  <DragDropContext
                    onDragEnd={(result) => {
                      handleDragEnd(result);
                    }}
                  >
                    <table className="table w-full ">
                      <thead className="mb-5">
                        <tr className="bg-light-violet  text-white py-3 px-3 overflow-hidden">
                          <th className="text-left p-3 pr-0  text-sm font-normal w-2"></th>
                          <th className="text-left p-3 pr-0  text-sm font-normal w-2"></th>
                          <th className="text-left p-3  text-sm font-normal"></th>
                          <th className="p-3 text-sm font-normal">
                            {" "}
                            <span className="flex flex-col items-center">
                              <span className="flex gap-2 items-center">
                                Number of attempts
                              </span>
                            </span>
                          </th>
                          <th className="p-3 text-sm font-normal">
                            {" "}
                            <span className="flex flex-col items-center">
                              <span>Minimum Score Requirement </span>
                            </span>
                          </th>
                          <th className="p-3 text-sm font-normal">
                            {" "}
                            <span className="flex flex-col items-center">
                              <span className="flex gap-2 items-center">
                                Allow Students to Retry Missed Items
                                <InfoNote message="This setting cannot be used with Multiple Attempts or Minimum Score Required settings. Students will have a single attempt, within the same session, to Retry Missed Items. They will be unable to save or return to the items." />
                              </span>
                            </span>
                          </th>
                          <th className="p-3 text-sm font-normal">
                            {" "}
                            <span className="flex flex-col items-center">
                              <span>Maximum Character Limit </span>
                            </span>
                          </th>
                          <th className="p-3 text-sm font-normal">
                            {" "}
                            <span className="flex flex-col items-center">
                              <span>Maximum Point Value </span>
                            </span>
                          </th>
                          <th className="p-3 text-sm font-normal">
                            {" "}
                            <span className="flex flex-col items-center">
                              <span>Preview </span>
                            </span>
                          </th>
                          <th className="p-3 text-sm font-normal">
                            {" "}
                            <span className="flex flex-col items-center">
                              <span>Remove </span>
                            </span>
                          </th>
                        </tr>
                      </thead>
                      <Droppable droppableId="tbody">
                        {(provided) => (
                          <tbody
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                          >
                            {activities &&
                              activities.map((activity, index) => (
                                <Draggable
                                  draggableId={activity.sequenceNumber.toString()}
                                  key={activity.sequenceNumber}
                                  index={index}
                                >
                                  {(provided) => (
                                    <>
                                      <Disclosure key={index}>
                                        <tr
                                          className="hover:bg-light-violet/20 transition ease-in-out duration-300 group"
                                          key={activity.sequenceNumber}
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          onMouseOver={() => {
                                            setHoverElement("row-" + index);
                                          }}
                                        >
                                          <td>
                                            {activity.isAuthoredQuestion && (
                                              <PersonWithTickIcon className="w-4 h-4" />
                                            )}
                                          </td>
                                          <td
                                            {...provided.dragHandleProps}
                                            className="mt-5 px-3 pr-0 py-6 w-10 relative  transition ease-in-out duration-500"
                                          >
                                            <DragAndDropIcon className="w-6 h-6"></DragAndDropIcon>
                                          </td>
                                          <td
                                            className="text-sm  px-3 py-6 mt-5  p-3"
                                            dangerouslySetInnerHTML={{
                                              __html: getActivityName(activity),
                                            }}
                                          ></td>
                                          <td className="text-sm  px-3 py-6 mt-5  p-3">
                                            {isSettingEligible(
                                              activity,
                                              "MultipleAttempt"
                                            ) ? (
                                              <div className="flex">
                                                <Select
                                                  isDisabled={getSelectedValue(
                                                    "AllowRetry",
                                                    activity.activityType,
                                                    getKeyId(
                                                      activity.activityType,
                                                      activity
                                                    )
                                                  )}
                                                  className="w-full max-w-sm flex-none"
                                                  value={getSelectedValue(
                                                    "MultipleAttempt",
                                                    activity.activityType,
                                                    getKeyId(
                                                      activity.activityType,
                                                      activity
                                                    )
                                                  )}
                                                  options={multipleAttempts}
                                                  onChange={(e) =>
                                                    updateActivitySettings(
                                                      "MultipleAttempt",
                                                      activity.activityType,
                                                      getKeyId(
                                                        activity.activityType,
                                                        activity
                                                      ),
                                                      e
                                                    )
                                                  }
                                                />
                                                {hoverElement ===
                                                "row-" + index ? (
                                                  <ApplyAllSettingContextMenu
                                                    isFirst={(
                                                      index === 0
                                                    ).toString()}
                                                    isLast={(
                                                      index ===
                                                      activities.length - 1
                                                    ).toString()}
                                                    applyAll={applyAll}
                                                    applyAllAbove={
                                                      applyAllAbove
                                                    }
                                                    applyAllBelow={
                                                      applyAllBelow
                                                    }
                                                    setting="MultipleAttempt"
                                                    userId={index}
                                                    value={
                                                      getSelectedValue(
                                                        "MultipleAttempt",
                                                        activity.activityType,
                                                        getKeyId(
                                                          activity.activityType,
                                                          activity
                                                        )
                                                      )?.value
                                                    }
                                                  />
                                                ) : (
                                                  <div className="w-10"></div>
                                                )}
                                              </div>
                                            ) : (
                                                <div className="flex mt-1.5 items-center justify-center">
                                                  NA
                                                </div>
                                            )}
                                          </td>
                                          <td className="text-sm  px-3 py-6 mt-5  p-3">
                                            {activity.minimumScoreEnabled ? (
                                              <div className="flex">
                                                <Select
                                                  isDisabled={getSelectedValue(
                                                    "AllowRetry",
                                                    activity.activityType,
                                                    getKeyId(
                                                      activity.activityType,
                                                      activity
                                                    )
                                                  )}
                                                  className="w-full max-w-sm flex-none"
                                                  value={getSelectedValue(
                                                    "MinimumScore",
                                                    activity.activityType,
                                                    getKeyId(
                                                      activity.activityType,
                                                      activity
                                                    )
                                                  )}
                                                  options={minimumScores}
                                                  onChange={(e) =>
                                                    updateActivitySettings(
                                                      "MinimumScore",
                                                      activity.activityType,
                                                      getKeyId(
                                                        activity.activityType,
                                                        activity
                                                      ),
                                                      e
                                                    )
                                                  }
                                                />
                                                {hoverElement ===
                                                "row-" + index ? (
                                                  <ApplyAllSettingContextMenu
                                                    isFirst={(
                                                      index === 0
                                                    ).toString()}
                                                    isLast={(
                                                      index ===
                                                      activities.length - 1
                                                    ).toString()}
                                                    applyAll={applyAll}
                                                    applyAllAbove={
                                                      applyAllAbove
                                                    }
                                                    applyAllBelow={
                                                      applyAllBelow
                                                    }
                                                    setting="MinimumScore"
                                                    userId={index}
                                                    value={
                                                      getSelectedValue(
                                                        "MinimumScore",
                                                        activity.activityType,
                                                        getKeyId(
                                                          activity.activityType,
                                                          activity
                                                        )
                                                      )?.value
                                                    }
                                                  />
                                                ) : (
                                                  <div className="w-10"></div>
                                                )}
                                              </div>
                                            ) : (
                                                <div className="flex mt-1.5 items-center justify-center">
                                                  NA
                                                </div>
                                            )}
                                          </td>
                                          <td>
                                            {activity.allowRetryEnabled ? (
                                                <div className="flex mt-1.5 items-center justify-center">
                                                <div className="col-span-4 sm:col-span-2 relative">
                                                  <Toggle
                                                    checked={getSelectedValue(
                                                      "AllowRetry",
                                                      activity.activityType,
                                                      getKeyId(
                                                        activity.activityType,
                                                        activity
                                                      )
                                                    )}
                                                    onChange={(e) => {
                                                      updateActivitySettings(
                                                        "AllowRetry",
                                                        activity.activityType,
                                                        getKeyId(
                                                          activity.activityType,
                                                          activity
                                                        ),
                                                        e
                                                      );
                                                    }}
                                                  />
                                                </div>{" "}
                                              </div>
                                            ) : (
                                              <div className="flex mt-1.5 items-center justify-center">
                                                NA
                                              </div>
                                            )}
                                          </td>

                                          <td className="text-sm  px-3 py-6 mt-5  p-3">
                                            {isSettingEligible(
                                              activity,
                                              "MaximumCharLimit"
                                            ) ? (
                                              <div className="flex mt-1.5 items-center justify-center">
                                                <div className="col-span-4 sm:col-span-2 relative">
                                                  <input
                                                    type="text"
                                                    autoComplete="off"
                                                    placeholder="unlimited"
                                                    value={getSelectedValue(
                                                      "MaximumCharLimit",
                                                      activity.activityType,
                                                      getKeyId(
                                                        activity.activityType,
                                                        activity
                                                      )
                                                    )}
                                                    onChange={(e) =>
                                                      updateActivitySettings(
                                                        "MaximumCharLimit",
                                                        activity.activityType,
                                                        getKeyId(
                                                          activity.activityType,
                                                          activity
                                                        ),
                                                        e
                                                      )
                                                    }
                                                    disabled={shouldDisableCRSettings(
                                                      activity
                                                    )}
                                                    className={
                                                      "mt-1 focus:outline-none focus:ring-gray-300 focus:border-gray-500 block w-full  sm:text-sm border-gray-400  py-2 bg-transparent border-b-2" +
                                                      (shouldDisableCRSettings(
                                                        activity
                                                      )
                                                        ? " text-gray-400 cursor-not-allowed"
                                                        : "")
                                                    }
                                                  />
                                                </div>
                                                {!shouldDisableCRSettings(
                                                  activity
                                                ) && (
                                                  <div className="w-6">
                                                    {hoverElement ===
                                                      "row-" + index && (
                                                      <ApplyAllSettingContextMenu
                                                        isFirst={(
                                                          index === 0
                                                        ).toString()}
                                                        isLast={(
                                                          index ===
                                                          activities.length - 1
                                                        ).toString()}
                                                        applyAll={applyAll}
                                                        applyAllAbove={
                                                          applyAllAbove
                                                        }
                                                        applyAllBelow={
                                                          applyAllBelow
                                                        }
                                                        setting="MaximumCharLimit"
                                                        userId={index}
                                                        value={getSelectedValue(
                                                          "MaximumCharLimit",
                                                          activity.activityType,
                                                          getKeyId(
                                                            activity.activityType,
                                                            activity
                                                          )
                                                        )}
                                                      />
                                                    )}
                                                  </div>
                                                )}
                                              </div>
                                            ) : (
                                              <div className="flex mt-1.5 items-center justify-center">
                                                NA
                                              </div>
                                            )}
                                          </td>
                                          <td className="text-sm  px-3 py-6 mt-5  p-3">
                                            {isSettingEligible(
                                              activity,
                                              "MaximumPoint"
                                            ) ? (
                                              <div className="flex mt-1.5 items-center justify-center">
                                                <div className="col-span-4 sm:col-span-2 relative">
                                                  <input
                                                    type="text"
                                                    autoComplete="off"
                                                    name="maximum-point"
                                                    placeholder="1-100"
                                                    id="maximum-point"
                                                    value={getSelectedValue(
                                                      "MaximumPoint",
                                                      activity.activityType,
                                                      getKeyId(
                                                        activity.activityType,
                                                        activity
                                                      )
                                                    )}
                                                    onChange={(e) =>
                                                      updateActivitySettings(
                                                        "MaximumPoint",
                                                        activity.activityType,
                                                        getKeyId(
                                                          activity.activityType,
                                                          activity
                                                        ),
                                                        e
                                                      )
                                                    }
                                                    disabled={shouldDisableCRSettings(
                                                      activity
                                                    )}
                                                    className={
                                                      "mt-1 focus:outline-none focus:ring-gray-300 focus:border-gray-500 block w-full sm:text-sm border-gray-400  py-2 bg-transparent border-b-2" +
                                                      (shouldDisableCRSettings(
                                                        activity
                                                      )
                                                        ? " text-gray-400 cursor-not-allowed"
                                                        : "")
                                                    }
                                                  />
                                                </div>
                                                {!shouldDisableCRSettings(
                                                  activity
                                                ) && (
                                                  <div className="w-6">
                                                    {hoverElement ===
                                                      "row-" + index && (
                                                      <ApplyAllSettingContextMenu
                                                        isFirst={(
                                                          index === 0
                                                        ).toString()}
                                                        isLast={(
                                                          index ===
                                                          activities.length - 1
                                                        ).toString()}
                                                        applyAll={applyAll}
                                                        applyAllAbove={
                                                          applyAllAbove
                                                        }
                                                        applyAllBelow={
                                                          applyAllBelow
                                                        }
                                                        setting="MaximumPoint"
                                                        userId={index}
                                                        value={getSelectedValue(
                                                          "MaximumPoint",
                                                          activity.activityType,
                                                          getKeyId(
                                                            activity.activityType,
                                                            activity
                                                          )
                                                        )}
                                                      />
                                                    )}
                                                  </div>
                                                )}
                                              </div>
                                            ) : (
                                              <div className="flex mt-1.5 items-center justify-center">
                                                NA
                                              </div>
                                            )}
                                          </td>
                                          <td>
                                            {isAllowViewAssignmentAssessment(
                                              props.userContext?.roleId,
                                              activity.canViewAssessment,
                                              activity?.activityType,
                                              activity?.assessmentCreatedByUserId,
                                              props.userId,
                                              activity?.assessmentSharedTypeId
                                            ) && (
                                              <div
                                                className="flex mt-1.5 items-center justify-center"
                                                onClick={() =>
                                                  handlePreviewPopup(activity)
                                                }
                                              >
                                                <PreviewIcon className="mr-3 h-5 w-5 text-xs font-light cursor-pointer text-gray-400 hover:text-secondary-color transaction ease-in-out duration-300" />
                                              </div>
                                            )}
                                          </td>
                                          <td>
                                            {canActivityUpdate() && (
                                              <div
                                                className="flex mt-1.5 items-center justify-center"
                                                onClick={() =>
                                                  handleRemoveActivityPopup(
                                                    activity
                                                  )
                                                }
                                              >
                                                <XIcon
                                                  className="h-6 w-6"
                                                  aria-hidden="true"
                                                />
                                              </div>
                                            )}
                                            {!canActivityUpdate() && (
                                              <div className="flex mt-1.5 items-center justify-center">
                                                <XIcon
                                                  className="h-6 w-6  text-gray-300"
                                                  aria-hidden="true"
                                                />
                                              </div>
                                            )}
                                          </td>
                                        </tr>
                                      </Disclosure>
                                    </>
                                  )}
                                </Draggable>
                              ))}
                            {provided.placeholder}
                          </tbody>
                        )}
                      </Droppable>
                    </table>
                  </DragDropContext>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showGooglePopup.show && (
        <GoogleSignInModal
          roleId={props.userContext?.roleId!}
          returnUrl={RouteConstant.EDITASSIGNMENT.replace(
            ":id",
            assignment.assignmentId.toString()
          )}
          showPopUp={() => {
            setShowGooglePopup({ show: false, mode: showGooglePopup.mode });
          }}
          mode={showGooglePopup.mode}
        />
      )}
      {showGoogleMappingPopup && (
        <GoogleMappingModal
          roleId={props.userContext?.roleId!}
          returnUrl={RouteConstant.EDITASSIGNMENT.replace(
            ":id",
            assignment.assignmentId.toString()
          )}
          showPopUp={setShowGoogleMappingPopup}
        />
      )}
      {showCanvasPopup.isopen && (
        <CanvasSignInModal
          mappingMode={showCanvasPopup.mode as any}
          returnUrl={RouteConstant.EDITASSIGNMENT.replace(
            ":id",
            assignment.assignmentId.toString()
          )}
          showPopUp={(show) => {
            setShowCanvasPopup({ isopen: show, mode: showCanvasPopup.mode });
          }}
          accountLoginName={props.profile?.accountLoginName!}
        />
      )}
    </Fragment>
  );
}

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(Setting);

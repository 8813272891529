import { AxiosHelper } from "../../environment/axiosConfig";
import APIConstant from "../../utils/constant/apiConstant";

export const getProgressReportDataByStandard = (
  subjectId: number,
  dateCompletedMin: Date | null,
  dateCompletedMax: Date | null,
  schoolId: number,
  classIds: string | null,
  assessmentTagId: number,
  includeAssignments: boolean,
  includeStudyPlan: boolean,
  studentUserId: number | null,
  schoolYearId: number
) => {
  return AxiosHelper.axiosInstance().get(
    APIConstant.PROGRESSREPORT_API.GetProgressReportByStandard,
    {
      params: {
        subjectId: subjectId,
        dateCompletedMin: dateCompletedMin,
        dateCompletedMax: dateCompletedMax,
        schoolId: schoolId === 0 ? null : schoolId,
        classIds: classIds,
        assessmentTagId: assessmentTagId === 0 ? null : assessmentTagId,
        includeAssignments: includeAssignments,
        includeStudyPlan: includeStudyPlan,
        studentUserId: studentUserId,
        schoolYearId: schoolYearId,
      },
    }
  );
};
export const getProgressReportDataByTeacher = (
  subjectId: number,
  dateCompletedMin: Date | null,
  dateCompletedMax: Date | null,
  schoolId: number,
  classIds: string | null,
  assessmentTagId: number,
  includeAssignments: boolean,
  includeStudyPlan: boolean,
  schoolYearId: number
) => {
  return AxiosHelper.axiosInstance().get(
    APIConstant.PROGRESSREPORT_API.GetProgressReportByTeacher,
    {
      params: {
        subjectId: subjectId,
        dateCompletedMin: dateCompletedMin,
        dateCompletedMax: dateCompletedMax,
        schoolId: schoolId === 0 ? null : schoolId,
        classIds: classIds,
        assessmentTagId: assessmentTagId === 0 ? null : assessmentTagId,
        includeAssignments: includeAssignments,
        includeStudyPlan: includeStudyPlan,
        schoolYearId: schoolYearId,
      },
    }
  );
};

export const getProgressReportDataByStudent = (
  subjectId: number,
  dateCompletedmin: Date | null,
  dateCompletedmax: Date | null,
  schoolId: number,
  classIds: string | null,
  assessmentTagId: number,
  includeAssignments: boolean,
  includeStudyPlan: boolean,
  schoolYearId: number
) => {
  return AxiosHelper.axiosInstance().get(
    APIConstant.PROGRESSREPORT_API.GetProgressReportByStudent,
    {
      params: {
        subjectId: subjectId,
        dateCompletedmin: dateCompletedmin,
        dateCompletedmax: dateCompletedmax,
        schoolId: schoolId === 0 ? null : schoolId,
        classIds: classIds,
        assessmentTagId: assessmentTagId === 0 ? null : assessmentTagId,
        includeAssignments: includeAssignments,
        includeStudyPlan: includeStudyPlan,
        schoolYearId: schoolYearId,
      },
    }
  );
};
export const getProgressReportDataBySchool = (
  subjectId: number,
  dateCompletedmin: Date | null,
  dateCompletedmax: Date | null,
  schoolId: number,
  classIds: string | null,
  assessmentTagId: number,
  includeAssignments: boolean,
  includeStudyPlan: boolean,
  schoolYearId: number
) => {
  return AxiosHelper.axiosInstance().get(
    APIConstant.PROGRESSREPORT_API.GetProgressReportBySchool,
    {
      params: {
        subjectId: subjectId,
        dateCompletedmin: dateCompletedmin,
        dateCompletedmax: dateCompletedmax,
        schoolId: schoolId === 0 ? null : schoolId,
        classIds: classIds,
        assessmentTagId: assessmentTagId === 0 ? null : assessmentTagId,
        includeAssignments: includeAssignments,
        includeStudyPlan: includeStudyPlan,
        schoolYearId: schoolYearId,
      },
    }
  );
};
export const getProgressReportDataByStandardByStudentIds = (
  subjectId: number,
  dateCompletedmin: Date | null,
  dateCompletedmax: Date | null,
  schoolId: number,
  classIds: string | null,
  assessmentTagId: number,
  includeAssignments: boolean,
  includeStudyPlan: boolean,
  studentUserIds: string,
  schoolYearId: number
) => {
  return AxiosHelper.axiosInstance().get(
    APIConstant.PROGRESSREPORT_API.GetProgressReportByStandardByStudentIds,
    {
      params: {
        subjectId: subjectId,
        dateCompletedmin: dateCompletedmin,
        dateCompletedmax: dateCompletedmax,
        schoolId: schoolId === 0 ? null : schoolId,
        classIds: classIds,
        assessmentTagId: assessmentTagId === 0 ? null : assessmentTagId,
        includeAssignments: includeAssignments,
        includeStudyPlan: includeStudyPlan,
        studentUserIds: studentUserIds,
        schoolYearId: schoolYearId,
      },
    }
  );
};

import { Fragment } from "react";
import { connect } from "react-redux";
import BubbleList from "../bubbleSheet/bubbleSheetList";

function BubbleSheet(props: any) {
  return (
    <Fragment>
      <div className="min-w-0 w-full">
        <div className="px-6 py-5 lg:px-8 border-b border-gray-200">
          <div className="text-lg flex justify-between gap-2 h-auto sm:h-10 items-center">
            <h1 className="text-secondary-teal font-medium flex justify-start">
              Bubble Sheet
            </h1>
          </div>
        </div>
        <div className="px-2 sm:px-6 lg:px-8 py-6">
          <div className="mx-auto lg:grid lg:grid-cols-12 lg:gap-8 mt-4">
            <div className="w-full block mx-4 sm:mx-0 lg:col-span-9 xl:col-span-12">
              <BubbleList
                userId={props.userContext.userId}
                roleId={props.userContext.roleId}
              ></BubbleList>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
  };
};
export default connect<{}, {}, {}>(mapStateToProps, {})(BubbleSheet);

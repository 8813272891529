import APIConstant from "../../utils/constant/apiConstant";
import { AxiosHelper } from "../../environment/axiosConfig";

export const syncTeacherClasses = (
  userId: number,
  schoolId: number,
  integrationTypes: Array<string>  
) => {
  const params = {
    userId: userId,
    schoolId: schoolId,
    integrationTypes: integrationTypes,    
  };

  return AxiosHelper.axiosInstance().post(
    APIConstant.THIRDPARTYINTEGRATION_API.SyncTeacherClasses,
    params,
    {}
  );
};


export const syncTeacherClassEnrollments = (
  userId: number,
  classId: number   
) => {
  const params = {
    userId: userId,
    classId: classId
  };

  return AxiosHelper.axiosInstance().post(
    APIConstant.THIRDPARTYINTEGRATION_API.SyncTeacherClassEnrollment,
    params,
    {}
  );
};

import { AxiosHelper } from "../../environment/axiosConfig";
import APIConstant from "../../utils/constant/apiConstant";

export const getAllQuestionTypes = (teacherId: number = 0) => {
  return AxiosHelper.axiosInstance().get(
    APIConstant.APP_QUESTIONTYPES_FOR_TEACHER,
    {
      params: {teacherId: teacherId},
    }
  );
};

export const getPayload = (code) => {
  return AxiosHelper.axiosInstance().get(APIConstant.CMS_API.PayloadTeacherSchema, {
    params: {
      code: code,
    },
  });
};

export const getQuestionTypesByStandard = (standardIds, isPassage = false, includeConstructedResponseQuestionTypes = false) => {
  return AxiosHelper.axiosInstance().get(
    APIConstant.APP_QUESTIONTYPES_BY_STANDARD,
    {
      params: {
        standardIds: standardIds,
        isPassage: isPassage,
        includeConstructedResponseQuestionTypes: includeConstructedResponseQuestionTypes
          ? includeConstructedResponseQuestionTypes
          : false,
      },
    }
  );
};



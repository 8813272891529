import { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import UserSchool from "../../../model/users/userSchool";
import {
  fetchMasterData,
  fetchProfile,
} from "../../../redux/actions/userActions";
import * as userContextAction from "../../../redux/actions/userContextAction";
import { useConfirmation } from "../../shared/confirmation/confirmationService";
import { toast } from "react-toastify";
import ProfileModel from "../../../model/users/profile";
import { getLinkedSchools } from "../../../api/student/studentApi";
import Constant from "../../../utils/constant/constant";
import Loader from "../loader";
import { login } from "../../../api/login.service";
import { handleRedirection } from "../../../utils/helper";
import { useHistory } from "react-router-dom";
import { jwtToken } from "../tokenHelper";
interface IStudentSchoolCode {
  theme: "ELEMENTARY" | "MIDDLESCHOOL" | "KINDERGARTEN";
  userContext?: userContextAction.UserContextState;
  profile?: ProfileModel;
  setUserContext?: any;
  setProfile?: any;
}

const theme = {
  ELEMENTARY: {
    bgWrapper: "",
    bgColor: "bg-[#A1BD32]",
    bgHoverClass: "bg-opacity-90",
    groupHoverClass: "",
    innerBgColor: "",
    dropText: "text-sm",
    svgColor: "text-white",
  },
  MIDDLESCHOOL: {
    bgWrapper: "bg-white bg-opacity-30",
    bgColor: "bg-blues",
    bgHoverClass: "bg-opacity-90",
    groupHoverClass: "",
    innerBgColor: "",
    dropText: "text-sm",
    svgColor: "text-white",
  },
  KINDERGARTEN: {
    bgWrapper: "",
    bgColor: "bg-[#FEC928]",
    bgHoverClass: "bg-opacity-90",
    groupHoverClass: "",
    innerBgColor: "",
    dropText: "text-base text-gray-900",
    svgColor: "text-gray-900",
  },
};

// <div className = {`h-4 ${theme[props.theme]?.height}`}/>
function StudentSchoolCode(props: IStudentSchoolCode) {
  const confirm = useConfirmation();
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  function getSchools() {
    if (
      props.userContext?.linkedUserId &&
      props.userContext?.linkedUserId !== ""
    ) {
      getLinkedSchools(props.userContext?.linkedUserId).then((d) => {
        setUserSchools(d.data);
      });
    }
  }

  const [userSchools, setUserSchools] = useState<Array<UserSchool>>([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [currentSchoolId, setCurrentSchoolId] = useState(
    props.userContext?.schoolId
  );

  function changeSchool(school: UserSchool) {
    if (school.schoolId === props.userContext?.schoolId) {
      return false;
    }
    confirm({
      variant: "danger",
      title: "Please Confirm",
      description: "Are you sure you want to change your school?",
    }).then((d) => {
      reloginUser(school.accountLoginName ?? "");
    });
  }

  function reloginUser(accountLoginName: string) {
    var userSchool: any = userSchools.find(
      (t) => t.accountLoginName === accountLoginName
    );
    setLoading(true);
    login(
      userSchool.username,
      userSchool.passwordHash,
      userSchool.accountLoginName,
      0,
      "",
      "",
      Constant.LoginType.FORM,
      "",
      false
    )
      .then((response) => {
        if (!response) {
          return toast.warning("Unauthorized Access!");
        } else {
          const authToken = response.data["access_token"];
          localStorage.setItem("AuthToken", authToken);
          const user: any = jwtToken(authToken);
          const userId = isNaN(user.userId) ? 0 : parseInt(user.userId);
          const roleId = isNaN(user.roleId) ? 0 : parseInt(user.roleId);
          const gradeId = isNaN(user.gradeId) ? 0 : parseInt(user.gradeId);
          const linkedUserId = user.linkedUserId;
          const districtId = isNaN(user.districtId)
            ? 0
            : parseInt(user.districtId);

          const schoolId =
            isNaN(user.schoolId) || user.schoolId === ""
              ? 0
              : parseInt(user.schoolId);
          const schoolAccountId =
            isNaN(user.schoolAccountId) || user.schoolAccountId === ""
              ? 0
              : parseInt(user.schoolAccountId);
          const userName = !user.userName ? "" : user.userName;
          const stateId = isNaN(user.stateId) ? 0 : parseInt(user.stateId);
          const accountId = isNaN(user.accountId)
            ? 0
            : parseInt(user.accountId);

          const context: userContextAction.UserContextState = {
            userId: userId,
            roleId: roleId,
            gradeId: gradeId,
            schoolId: schoolId,
            districtId: districtId,
            accountId: accountId,
            stateId: stateId,
            schoolAccountId: schoolAccountId,
            impersonatedUser: null,
            googleId: "",
            activeExternalRoster: "",
            userName: userName,
            linkedUserId: linkedUserId,
          };

          props.setUserContext(context);
          setTimeout(() => {
            props.setProfile(userId, roleId, schoolId, 0);
            handleRedirection(roleId, gradeId, linkedUserId ?? "", history);
          }, 600);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }

  useEffect(() => {
    getSchools();

    if (currentSchoolId !== props.userContext?.schoolId) {
      setShowDropdown(false);
      toast.success("School changed successfully");
      setCurrentSchoolId(props.userContext?.schoolId);
      setTimeout(() => {
        window.location.reload();
      }, 500);
    }
  }, [props.userContext?.schoolId]);

  return (
    <Fragment>
      {loading && <Loader />}
      {userSchools && userSchools.length === 1 && (
        <div className="text-right px-1 py-2">
          <p className=" text-gray-500 text-xs">
            {userSchools[0].schoolName}{" "}
            <strong>{userSchools[0].schoolCode} </strong>
          </p>
        </div>
      )}
      {userSchools && userSchools.length > 1 && (
        <Fragment>
          <div
            className={`text-right px-1 py-2 ${theme[props.theme]?.bgWrapper}`}
            style={{ visibility: userSchools ? "visible" : "hidden" }}
          >
            {/* code */}
            <div className="flex justify-end relative">
              <div className="inline-flex shadow-sm rounded-md divide-x divide-indigo-600 right-0">
                <div className="relative z-0 inline-flex shadow-sm rounded-md divide-x divide-indigo-600">
                  <button
                    type="button"
                    onClick={() =>
                      showDropdown
                        ? setShowDropdown(false)
                        : setShowDropdown(true)
                    }
                    className={`relative group inline-flex items-center p-2  rounded-md text-sm font-medium text-white hover:${
                      theme[props.theme]?.bgHoverClass
                    } hover:shadow-lg focus:outline-none focus:z-10 ${
                      theme[props.theme]?.bgColor
                    }`}
                  >
                    <div
                      className={`relative inline-flex items-center group-hover:${
                        theme[props.theme]?.groupHoverClass
                      } py-0 lg:pl-3 lg:pr-4 border border-transparent rounded-l-md shadow-sm text-white ${
                        theme[props.theme]?.innerBgColor
                      }`}
                    >
                      <p
                        x-text="selected.title"
                        className={`ml-2.5 font-medium ${
                          theme[props.theme]?.dropText
                        }`}
                      >
                        {props.profile?.schoolName}{" "}
                        <strong>{props.profile?.schoolCode} </strong>
                      </p>
                    </div>

                    <span className="sr-only">Change published status</span>
                    <svg
                      className={`h-5 w-5 text-white ${
                        theme[props.theme]?.svgColor
                      }`}
                      x-description="Heroicon name: solid/chevron-down"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </button>
                </div>
              </div>
              <ul
                className={
                  (showDropdown ? "visible" : "hidden") +
                  " origin-top-right absolute z-10 right-0 mt-10 w-44 rounded-md shadow-lg overflow-hidden bg-white divide-y divide-gray-200 ring-1 ring-black ring-opacity-5 focus:outline-none"
                }
              >
                {userSchools &&
                  userSchools?.map((school, index) => {
                    return (
                      <Fragment key={index}>
                        <li
                          onClick={() => {
                            changeSchool(school);
                          }}
                          className="hover:bg-gray-50 cursor-pointer select-none relative  px-4 py-2 text-sm text-gray-900"
                        >
                          <div className="flex flex-col">
                            <div className="flex justify-between">
                              <p className="font-semibold">
                                {" "}
                                {school.schoolCode}
                              </p>
                              {props.userContext?.schoolId ===
                                school.schoolId && (
                                <span className="text-secondary-teal">
                                  <svg
                                    className="h-5 w-5"
                                    x-description="Heroicon name: solid/check"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    aria-hidden="true"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                      clipRule="evenodd"
                                    ></path>
                                  </svg>
                                </span>
                              )}
                            </div>
                            <p className="text-gray-500 text-left text-xs">
                              {school.schoolName}{" "}
                            </p>
                          </div>
                        </li>
                      </Fragment>
                    );
                  })}
              </ul>
            </div>
          </div>
          {/* dropdown */}
        </Fragment>
      )}
    </Fragment>
  );
}

StudentSchoolCode.displayName = "SchoolCode";

const mapStateToProps = (state: any) => {
  return {
    profile: state.profile,
    userContext: state.userContext,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setUserContext: (payload: userContextAction.UserContextState) => {
      dispatch(userContextAction.setUserContext(payload));
    },
    setMasterData: (
      userId: number,
      roleId: number,
      schoolId: number,
      stateId: number,
      districtId: number
    ) => {
      dispatch(fetchMasterData(userId, roleId, schoolId, stateId, districtId));
    },
    setProfile: (
      userId: number,
      roleId: number,
      schoolId: number,
      districtId: number
    ) => {
      dispatch(fetchProfile(userId, roleId, schoolId, districtId));
    },
  };
};

export default connect<{}, {}, {}>(
  mapStateToProps,
  mapDispatchToProps
)(StudentSchoolCode);

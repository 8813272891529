import { useState, useEffect } from "react";
import ProgressBar from "../../../../../shared/widgets/progressBar";
import { useTranslation } from "react-i18next";
import { getScoreLabel } from "../../../../../../utils/scoreHelper";
import { moveItemsToEnd } from "../../../../../../utils/helper";

export default function ProgressResult({ data }: any) {
  const { t } = useTranslation();
  const [radioValue, setRadioValue] = useState<string>();
  const [viewPracticeNeeded, setViewPracticeNeeded] = useState<boolean>();
  const [isGraded, setIsGraded] = useState<boolean>(false);
  useEffect(() => {
    let isView: any = true;
    if (data.results_header) {
      isView = false;
      setIsGraded(data?.results_header?.assignment?.result?.not_graded === 1);
    }
    setViewPracticeNeeded(true);
    setRadioValue(isView ? "1" : "2");
  }, []);

  const badgeBgColor = (value) => {
    let badgeBg = "";
    switch (true) {
      case value < 63:
        badgeBg = "bg-[#BB0070]";
        break;
      case value >= 63 && value <= 85:
        badgeBg = "bg-[#F4D220]";
        break;
      case value > 85:
        badgeBg = "bg-[#61D140]";
        break;
      default:
        badgeBg = "bg-gray-700";
        break;
    }

    return badgeBg;
  };

  const practiceNeeded = () => {
    if (viewPracticeNeeded) {
      const percentLessThan80: any = [];
      if (data.standards) {
        let rearrangedStandards = moveItemsToEnd(
          data.standards,
          (s: any) => s.standardName === null
        );
        rearrangedStandards.forEach((standard) => {
          standard.name = standard.standardName
            ? standard.standardName
            : "Other Standards";
          standard.correct = standard.totalScore;
          standard.total_points = standard.totalAttempted;
          const percent = Math.round(
            (Number(standard.totalScore) / Number(standard.totalAttempted)) *
              100
          );
          standard.percent_correct = percent;
          if (percent < 80 && !standard.isHiddenFromStudentPractice)
            percentLessThan80.push({ ...standard, isPracticeOpen: false });
        });
      }

      return (
        <div>
          {Object.entries(percentLessThan80).map(([key, value]: any, index) => {
            return (
              <div key={key}>
                <ProgressBar
                  styleProps={{
                    tracker: index,
                    header: `${
                      value.standardName
                        ? value.standardName
                        : "Other Standards"
                    }`,
                    subHeader: "",
                    remediationLinks: {
                      remediation_questions_link:
                        value.remediation_questions_link,
                      remediation_video_link: value.remediation_video_link,
                    },
                    bars: [
                      {
                        barColor: "bg-[#BB0070]",
                        percent: value.percent_correct || 0,
                      },
                      {
                        barColor: "bg-site-teal",
                        percent: value.retry_percent_correct || 0,
                      },
                    ],
                    barBackgroundColor: "bg-gray-300",
                    badgeText: `${
                      value.retry_correct
                        ? getScoreLabel(value.retry_correct)
                        : getScoreLabel(value.correct)
                    }/${value.total_points || 0}`,
                    badgeTextColor: "text-white",
                    badgeColor: `${badgeBgColor(
                      (value.retry_correct
                        ? value.retry_correct
                        : value.correct * 100) / value.total_points || 0
                    )}`,
                    percentText: value.retry_percent_correct
                      ? value.retry_percent_correct
                      : value.percent_correct || 0,
                  }}
                />
              </div>
            );
          })}
          {percentLessThan80.length === 0 && (
            <div className="px-9 md:px-4 lg:px-4 xl:px-9 2xl:px-9 3xl:px-9 pb-4">
              {" "}
              No Data Available
            </div>
          )}

          {/* <h1 className="text-sm font-medium uppercase px-7 py-7 font-medium">
            additional practice
          </h1> */}
        </div>
      );
    } else {
      <div
        className={"bg-gray-100  py-5 px-8"}
        style={{ margin: "-10px -30px 0px -30px", zIndex: 10 }}
      ></div>;
    }
  };

  const domain = () => {
    const domains: any = [];
    if (data.domains) {
      const rearrangedDomains = moveItemsToEnd(
        data.domains,
        (d: any) => d.domainName === null
      );
      rearrangedDomains.forEach((domain) => {
        let domainResult = {
          correct: domain.totalScore,
          name: domain.domainName ? domain.domainName : "Other Domains",
          percent_correct: Math.round(
            (Number(domain.totalScore) / Number(domain.totalAttempted)) * 100
          ),
          total_points: domain.totalAttempted,
        };
        domains.push(domainResult);
      });
    }
    return (
      <div>
        {Object.entries(domains).map(([key, value]: any, index) => {
          return (
            <div
              className={`flex flex-none flex-col pt-4 pb-1 ${
                index % 2 === 0 ? "bg-gray-200 bg-opacity-50" : "bg-white"
              }`}
              key={key}
            >
              <ProgressBar
                styleProps={{
                  tracker: index,
                  header: `${value.name}`,
                  subHeader: "",
                  bars: [
                    {
                      barColor: "bg-[#BB0070]",
                      percent: value.percent_correct || 0,
                    },
                    {
                      barColor: "bg-site-teal",
                      percent: value.retry_percent_correct || 0,
                    },
                  ],
                  barBackgroundColor: "bg-gray-300",
                  badgeColor: `${badgeBgColor(value.percent_correct || 0)}`,
                  badgeText: `${
                    value.retry_correct
                      ? getScoreLabel(value.retry_correct)
                      : getScoreLabel(value.correct)
                  }/${value.total_points || 0}`,
                  badgeTextColor: "text-white",
                  percentText: value.retry_percent_correct
                    ? value.retry_percent_correct
                    : value.percent_correct || 0,
                }}
              />
            </div>
          );
        })}
      </div>
    );
  };

  const standard = () => {
    const standards: any = [];
    if (data.standards) {
      let rearrangedStandards = moveItemsToEnd(
        data.standards,
        (s: any) => s.standardName === null
      );
      rearrangedStandards.forEach((standard) => {
        let standardResult = {
          correct: standard.totalScore,
          name: standard.standardName
            ? standard.standardName
            : "Other Standards",
          percent_correct: Math.round(
            (Number(standard.totalScore) / Number(standard.totalAttempted)) *
              100
          ),
          total_points: standard.totalAttempted,
        };
        standards.push(standardResult);
      });
    }

    return (
      <div>
        {Object.entries(standards).map(([key, value]: any, index) => {
          return (
            <div
              className={`flex flex-none flex-col pt-4 pb-1 ${
                index % 2 === 0 ? "bg-gray-200 bg-opacity-50" : "bg-white"
              }`}
              key={key}
            >
              <ProgressBar
                styleProps={{
                  tracker: index,
                  header: `${value.name}`,
                  subHeader: "",
                  bars: [
                    {
                      barColor: "bg-[#BB0070]",
                      percent: value.percent_correct || 0,
                    },
                    {
                      barColor: "bg-site-teal",
                      percent: value.retry_percent_correct || 0,
                    },
                  ],
                  barBackgroundColor: "bg-gray-300",
                  badgeColor: `${badgeBgColor(
                    (value.retry_correct
                      ? value.retry_correct
                      : value.correct * 100) / value.total_points || 0
                  )}`,
                  badgeText: `${
                    value.retry_correct
                      ? getScoreLabel(value.retry_correct)
                      : getScoreLabel(value.correct)
                  }/${value.total_points || 0}`,
                  badgeTextColor: "text-white",
                  percentText: value.retry_percent_correct
                    ? value.retry_percent_correct
                    : value.percent_correct || 0,
                }}
              />
            </div>
          );
        })}
      </div>
    );
  };

  const onRadioChange = (e) => {
    setRadioValue(e.target.value);
  };

  return (
    <>
      <div className="flex flex-col xs:flex-row items-center space-y-4 xs:space-y-0 justify-between md:space-x-1 px-7 md:px-2 lg:px-2 xl:px-7 2xl:px-7 3xl:px-7 py-7">
        <div className="flex w-full xs:w-auto items-end lg:ml-2">
          <input
            className="focus:ring-radio-blue h-4 w-4 text-radio-blue border-gray-900 relative bottom-1"
            key={"1"}
            name="site_name"
            type="radio"
            value={1}
            defaultChecked={viewPracticeNeeded}
            onChange={(e) => onRadioChange(e)}
          />
          <div className="ml-3 flex flex-col">
            <span
              id="privacy-setting-0-label"
              className="text-black-333 w-full text-sm font-medium"
            >
              {t("AssignmentResult.Practice")}
            </span>
            <span
              id="privacy-setting-0-description"
              className="text-gray-400 block text-xl font-semibold w-full"
            >
              {t("AssignmentResult.Needed")}
            </span>
          </div>
        </div>

        <div className="flex w-full xs:w-auto items-end">
          <input
            name="site_name"
            type="radio"
            key={"2"}
            value={2}
            defaultChecked={false}
            onChange={(e) => onRadioChange(e)}
            className="focus:radio-blue h-4 w-4 text-radio-blue border-gray-900 relative bottom-1"
          />
          <div className="ml-3 flex flex-col">
            <span
              id="privacy-setting-1-label"
              className="text-black-333 w-full text-sm font-medium"
            >
              {t("AssignmentResult.ScoresBy")}
            </span>
            <span
              id="privacy-setting-1-description"
              className="text-gray-400 block text-xl font-semibold w-full"
            >
              {t("AssignmentResult.Domain")}
            </span>
          </div>
        </div>
        <div className="flex w-full xs:w-auto items-end lg:mr-2">
          <input
            key={"3"}
            type="radio"
            name="site_name"
            value={3}
            defaultChecked={false}
            onChange={(e) => onRadioChange(e)}
            className="focus:radio-blue h-4 w-4 text-radio-blue border-gray-900 relative bottom-1"
          />
          <div className="ml-3 flex flex-col">
            <span
              id="privacy-setting-2-label"
              className="text-black-333 w-full text-sm font-medium"
            >
              {t("AssignmentResult.ScoresBy")}
            </span>
            <span
              id="privacy-setting-2-description"
              className="text-gray-400 block text-xl font-semibold w-full"
            >
              {t("AssignmentResult.Standard")}
            </span>
          </div>
        </div>
      </div>

      {!viewPracticeNeeded && radioValue === "1" && !isGraded && (
        <div className="grid  grid-flow-col text-center">
          <div className={"my-8"}>
            <div className={"text-lg capitalize font-semibold"}>
              Congratulations! You scored over 80% in all standards!
            </div>
          </div>
        </div>
      )}
      {radioValue === "1" && !isGraded && practiceNeeded()}
      {radioValue === "2" && !isGraded && domain()}
      {radioValue === "3" && !isGraded && standard()}
    </>
  );
}

import React from "react";
import { useTranslation } from "react-i18next";
import { RightArrowIcon } from "../../../../../assets/icons";

export const CompleteSavedActivityGrid = ({
  savedActivityContinueBtnClick,
  savedActivityData,
}: any) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="hidden xl:flex xl:flex-col relative">
        <div className="xl:flex xl:flex-col">
          <div className="overflow-hidden rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-200 uppercase">
                <tr>
                  <th
                    scope="col"
                    className="top-0 xl:px-2 2xl:px-6 py-4 px-6 text-left text-sm font-semibold text-dark-teal uppercase tracking-wider"
                  >
                    {t("Assignments.SavedDate")}
                  </th>
                  <th
                    scope="col"
                    className="bg-gray-100 top-0 xl:px-2 2xl:px-6 py-4 px-6 text-left text-sm font-semibold text-dark-teal uppercase tracking-wider"
                  >
                    {t("Assignments.DueDate")}
                  </th>
                  <th
                    scope="col"
                    className="top-0 bg-gray-200 xl:px-2 2xl:px-6 py-4 px-6 text-left text-sm font-semibold text-dark-teal uppercase tracking-wider"
                  >
                    {t("Assignments.Test")} {t("Assignments.Name")}
                  </th>
                  <th
                    scope="col"
                    className="bg-gray-100 top-0 xl:px-2 2xl:px-6 py-4 px-6 text-left text-sm font-semibold text-dark-teal uppercase tracking-wider"
                  >
                    {t("Assignments.Questions")}
                  </th>
                  <th
                    scope="col"
                    className="top-0 bg-gray-200 xl:px-2 2xl:px-6 py-4 px-6 text-left text-sm font-semibold text-dark-teal uppercase tracking-wider"
                  >
                    {t("Assignments.Progress")}
                  </th>
                  <th
                    scope="col"
                    className="top-0 bg-gray-100 xl:px-2 2xl:px-6 py-4 px-6 text-left text-sm font-semibold text-dark-teal uppercase tracking-wider"
                  >
                    <span className="sr-only">Action button</span>
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white">
                {savedActivityData?.map((savedActivity, index) => {
                  const d = savedActivity.created;
                  const dueDate = savedActivity.due_date;

                  return (
                    <React.Fragment key={index}>
                      <tr
                        className={`border-b-2 shadow ${
                          index % 2 === 0 ? "bg-white" : "bg-gray-100"
                        }`}
                      >
                        <td className="px-6 xl:px-2 2xl:px-6 py-4">
                          <div className="text-sm font-semibold text-gray-500">
                            {d}
                          </div>
                          <div className="text-xs text-site-orange font-semibold capitalize">
                            {savedActivity.days_remaining}{" "}
                            {t("Assignments.DaysRemaining")}
                          </div>
                        </td>

                        <td className="px-6 xl:px-2 2xl:px-6 py-4">
                          <div
                            className={`border-l-4  pl-2 ${
                              savedActivity.days_remaining === 0
                                ? "border-site-pink"
                                : "border-blue"
                            }`}
                          >
                            <div
                              className={`text-sm font-semibold ${
                                savedActivity.days_remaining === 0
                                  ? "text-site-pink"
                                  : "text-blues"
                              }`}
                            >
                              {dueDate}
                            </div>
                            {savedActivity.days_remaining === 0 && (
                              <div className="text-xs text-gray-500 font-semibold capitalize">
                                {t("Assignments.PastDue")}
                              </div>
                            )}
                          </div>
                        </td>

                        <td className="px-6 py-4 border-r-2">
                          <div className="text-sm text-gray-800 capitalize font-semibold">
                            {" "}
                            {savedActivity.title}
                          </div>
                          <div className="text-sm text-dark-teal capitalize font-semibold">
                            {" "}
                            {savedActivity.subtitle}
                          </div>
                        </td>
                        <td className="px-6 py-4 border-r-2 border-gray-100">
                          <div className="flex xl:justify-center">
                            <div className="flex w-6 h-6 px-4 py-4 bg-dark-teal text-white font-medium rounded-full justify-center items-center text-sm">
                              {savedActivity.length}
                            </div>
                          </div>
                        </td>
                        <td className="px-6 py-4 text-sm text-gray-500 border-r-2 border-gray-100">
                          <div className="w-full block relative h-8">
                            <div className="w-full h-2 bg-gray-300 rounded-md"></div>
                            <div
                              style={{
                                width: `${savedActivity.progress}`,
                              }}
                              className="h-2 bg-chart-blue rounded-md absolute top-0 z-0"
                            >
                              <span
                                className={`${
                                  savedActivity.progress !== "0%" && "absolute"
                                } right-0 text-black-333 text-lg font-roboto font-semibold pt-2 mb-2 inline-block`}
                              >
                                {savedActivity.progress}
                              </span>
                            </div>
                          </div>
                          {/* <div className="relative pt-1">
                            <div className="flex mb-2 items-center justify-between">
                              <div className="text-right">
                                <span className="text-xs font-semibold inline-block text-blue-600">
                                  {savedActivity.progress}
                                </span>
                              </div>
                            </div>
                            <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-indigo-300">
                              <div
                                style={{ width: savedActivity.progress }}
                                className="shadow-none flex flex-col text-center text-white justify-center bg-indigo-700"
                              ></div>
                            </div>
                          </div> */}
                        </td>
                        <td>
                          <div className="flex xl:justify-center">
                            <a
                              href="#!"
                              title={
                                savedActivity.disabled === 1
                                  ? "Disabled"
                                  : "Click to continue"
                              }
                              onClick={() =>
                                savedActivityContinueBtnClick(
                                  savedActivity.continue_link
                                )
                              }
                              className={`bg-blues ${
                                savedActivity.days_remaining === 0
                                  ? "bg-site-pink"
                                  : ""
                              } ${
                                savedActivity.disabled === 1 &&
                                "pointer-events-none"
                              }
                               hover:opacity-90 inline-block rounded-full whitespace-nowrap text-sm px-5 py-2 text-white cursor-pointer`}
                            >
                              {t("Assignments.Complete")}
                              <RightArrowIcon className="h-6 w-6 ml-2 inline-block" />
                            </a>
                          </div>
                        </td>
                      </tr>
                    </React.Fragment>
                  );
                })}
                {savedActivityData.length === 0 && (
                  <tr className="bg-white">
                    <td colSpan={6} className="text-sm px-6 py-4">
                      No records found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

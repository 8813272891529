import React, { Fragment, useState, useEffect } from "react";
import Header from "../shared/header/header";
import { connect } from "react-redux";
import { Button, Select } from "@windmill/react-ui";
import TeacherTrainingModel from "../../model/teacher/teacherTraining";
import { fetchData } from "../../api/teacherTrainingApi";
import Download from "../../utils/downloadHelper";
import Loader from "../shared/loader";
import Constant from "../../utils/constant/constant";
import ProgressBar from "../shared/progressBar";
import moment from "moment";
import Pagination from "../shared/pagination";

function TeacherTraining(props: any) {
  type ExportType = "NONE" | "CSV";
  let defaultReportData: Array<TeacherTrainingModel> = [];
  const pageSize = 10;
  const [selectedProgram, setSelectedProgram] = useState(0);
  const [SelectedProgress, setSelectedProgress] = useState(0);
  const [selectedSchool, setSelectedSchool] = useState(0);
  const [currentPageNumber, setPageNumber] = useState(1);
  const [showLoader, setLoader] = useState(false);
  const [reportData, setReportData] = useState(defaultReportData);

  useEffect(() => {
    showReport("NONE");
  }, [currentPageNumber]);

  function handlePageChange(e: any) {
    setPageNumber(e);
  }

  function handleProgramChange(event: any) {
    setSelectedProgram(parseInt(event.target.value));
  }

  function handleSubjectChange(event: any) {
    setSelectedProgress(parseInt(event.target.value));
  }

  function handleSchoolChange(event: any) {
    setSelectedSchool(parseInt(event.target.value));
  }

  function bindData(data: any, exportType: ExportType) {
    setLoader(false);
    setReportData(data);
    if (data && data.length > 0 && exportType !== "NONE") {
      if (data[0].base64StringData) {
        const fileName = "TeacherTraining";
        const hasMultipleFiles = data[0].hasMultipleFiles;
        const extension =
          exportType === "CSV" && hasMultipleFiles ? "zip" : "csv";
        Download(data[0].base64StringData, fileName, extension);
      }
    }
  }

  function showReport(exportType: ExportType) {
    setLoader(true);
    const stateId = props.userContext.stateId;
    const userId = props.userContext.userId;
    const districtId = props.userContext.districtId;
    const schoolId =
      props.userContext.roleId.toString() ===
      Constant.UserRoleId.District.toString()
        ? selectedSchool
        : props.userContext.schoolId;
    const progressId = SelectedProgress;
    const programId = selectedProgram;
    const roleId = props.userContext.roleId;
    fetchData(
      Constant.Report.TEACHERTRAINING,
      userId,
      schoolId,
      districtId,
      stateId,
      currentPageNumber,
      pageSize,
      exportType,
      roleId,
      programId,
      progressId
    )
      ?.then((r) => bindData(r.data, exportType))
      .catch((err) => {
        setLoader(false);
      });
  }

  const totalResults =
    reportData && reportData.length > 0 ? reportData[0].totalCount : 0;
  return (
    <Fragment>
      <Header></Header>
      <div className="px-4 lg:px-8 mt-10">
        <h2 className="text-2xl text-primary-green font-semibold">
          Teacher Training Report
        </h2>
        <div className="flex space-x-8 my-4">
          <div>
            <label className="block mb-1 text-sm" htmlFor="">
              Training Program
            </label>
            <Select
              value={selectedProgram}
              onChange={(e) => handleProgramChange(e)}
              className="block  text-sm  focus:outline-none border border-gray-400 sm:focus:border-gray-700 border-opacity-50 focus:shadow-outline-green form-select leading-5 p-1 rounded-lg"
            >
              <Fragment key="-1">
                <option key="-1" value="-1">
                  Select Program
                </option>
              </Fragment>
              <Fragment key="1">
                <option key="1" value="1">
                  K-1 Program
                </option>
              </Fragment>
              <Fragment key="2">
                <option key="2" value="2">
                  Elementary Program
                </option>
              </Fragment>
              <Fragment key="4">
                <option key="4" value="4">
                  Intermediate/Middle Program
                </option>
              </Fragment>
            </Select>
          </div>
          <div>
            <label className="block mb-1 text-sm" htmlFor="">
              Training Progress
            </label>
            <Select
              value={SelectedProgress}
              onChange={(e) => handleSubjectChange(e)}
              className="block  text-sm  focus:outline-none border border-gray-400 sm:focus:border-gray-700 border-opacity-50 focus:shadow-outline-green form-select leading-5 p-1 rounded-lg"
            >
              <Fragment key="-1">
                <option key="-1" value="-1">
                  Select Progress
                </option>
              </Fragment>
              <Fragment key="1">
                <option key="1" value="1">
                  Completed
                </option>
              </Fragment>
              <Fragment key="2">
                <option key="2" value="2">
                  InComplete
                </option>
              </Fragment>
            </Select>
          </div>
          {props.userContext.roleId.toString() ===
            Constant.UserRoleId.District.toString() && (
            <div>
              <label className="block mb-1 text-sm" htmlFor="">
                School
              </label>
              <Select
                value={selectedSchool}
                onChange={(e) => handleSchoolChange(e)}
                className="block  text-sm  focus:outline-none border border-gray-400 sm:focus:border-gray-700 border-opacity-50 focus:shadow-outline-green form-select leading-5 p-1 rounded-lg"
              >
                {props.masterData.schools.map(
                  (obj: { gradeId: number; key: string; value: string }) => {
                    return (
                      <Fragment key={obj.key}>
                        <option key={obj.key} value={obj.key}>
                          {obj.value}
                        </option>
                      </Fragment>
                    );
                  }
                )}
              </Select>
            </div>
          )}
        </div>
        <div className="space-x-4 mb-5">
          <Button
            className="text-gray-700 bg-primary-green hover:bg-light-green py-1.5 px-2"
            layout="primary"
            onClick={() => showReport("NONE")}
          >
            Show Report
          </Button>
          <Button
            className="text-primary-green border border-primary-green py-1.5 px-2 bg-primary-green bg-opacity-0 hover:bg-primary-green hover:text-gray-700"
            layout="primary"
            onClick={() => showReport("CSV")}
          >
            Export Data
          </Button>
        </div>
        <div className="hidden sm:block mt-4">
          <div className="border-b border-gray-200">
            <nav className="-mb-px flex" aria-label="Tabs"></nav>
          </div>
        </div>
      </div>
      <div className="px-4 lg:px-8 mt-3">
        <div className="flex flex-col relative">
          {showLoader && <Loader></Loader>}
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className="shadow overflow-hidden sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-primary-green bg-opacity-75">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-1 text-left text-xs font-medium text-gray-700 uppercase tracking-wider"
                      >
                        User Name
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-1 text-left text-xs font-medium text-gray-700 uppercase tracking-wider"
                      >
                        First Name
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-1 text-center text-xs font-medium text-gray-700 uppercase tracking-wider"
                      >
                        Last Name
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-1 text-center text-xs font-medium text-gray-700 uppercase tracking-wider"
                      >
                        State
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-1 text-center text-xs font-medium text-gray-700 uppercase tracking-wider"
                      >
                        District
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-1 text-center text-xs font-medium text-gray-700 uppercase tracking-wider"
                      >
                        School
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-1 text-center text-xs font-medium text-gray-700 uppercase tracking-wider"
                      >
                        Program
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-1 text-center text-xs font-medium text-gray-700 uppercase tracking-wider"
                      >
                        Email
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-1 text-center text-xs font-medium text-gray-700 uppercase tracking-wider"
                      >
                        Start Date
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-1 text-center text-xs font-medium text-gray-700 uppercase tracking-wider"
                      >
                        End Date
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-1 text-center text-xs font-medium text-gray-700 uppercase tracking-wider"
                      >
                        Progress %
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {reportData.map((report, personIdx) => (
                      <tr
                        key={personIdx}
                        className={
                          personIdx % 2 === 0
                            ? "bg-white"
                            : "bg-primary-green bg-opacity-10"
                        }
                      >
                        <td className="px-6 py-1 whitespace-nowrap text-xs text-gray-500">
                          {report.username}
                        </td>
                        <td className="px-6 py-1 whitespace-nowrap text-xs text-gray-500">
                          {report.firstname}
                        </td>
                        <td className="px-6 py-1 whitespace-nowrap text-xs text-gray-500 text-center">
                          {report.lastname}
                        </td>
                        <td className="px-6 py-1 whitespace-nowrap text-xs text-gray-500 text-center">
                          {report.state}
                        </td>
                        <td className="px-6 py-1 whitespace-nowrap text-xs text-gray-500 text-center">
                          {report.district}
                        </td>
                        <td className="px-6 py-1 whitespace-nowrap text-xs text-gray-500 text-center">
                          {report.school}
                        </td>
                        <td className="px-6 py-1 whitespace-nowrap text-xs text-gray-500 text-center">
                          {report.program}
                        </td>
                        <td className="px-6 py-1 whitespace-nowrap text-xs text-gray-500 text-center">
                          {report.email}
                        </td>
                        <td className="px-6 py-1 whitespace-nowrap text-xs text-gray-500 text-center">
                          {report.startDate === null
                            ? ""
                            : moment(report.startDate).format("YYYY-MM-DD")}
                        </td>
                        <td className="px-6 py-1 whitespace-nowrap text-xs text-gray-500 text-center">
                          {report.endDate === null
                            ? ""
                            : moment(report.endDate).format("YYYY-MM-DD")}
                        </td>
                        <td>
                          <ProgressBar
                            percentage={report.progress.toLocaleString(
                              undefined,
                              { maximumFractionDigits: 2 }
                            )}
                          ></ProgressBar>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
          <Pagination
            totalResults={totalResults}
            resultsPerPage={pageSize}
            onChange={(e) => {
              handlePageChange(e);
            }}
            label=""
          />
        </div>
      </div>
    </Fragment>
  );
}

const mapStateToProps = (state: any) => {
  return {
    masterData: state.masterData,
    userContext: state.userContext,
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(TeacherTraining);

import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { XIcon } from "@heroicons/react/solid";
import { LockIcon } from "../../../../../assets/icons";
import { UserContextState } from "../../../../../redux/actions/userContextAction";
import { updateStudentBlasters } from "../../../../../api/student/studentApi";
import { getBlasterValues } from "../../../../../utils/studentHelper";
import { Configuration } from "../../../../../environment/setup";

interface BlastersProps {
  userContext?: UserContextState;
  rewards?: any;
  type: string;
}

const Blasters = (props: BlastersProps) => {
  const { t } = useTranslation();
  type BlasterType = "cannon" | "energy" | "fire" | "ice" | "lazer" | "missile";
  const totalBlasterLevel = 4;
  const totalBlasters = 24;
  const [showBlasterPopup, setBlasterPopup] = useState(false);
  const [blasters, setBlasters] = useState<any>();
  const [cannon, setCannon] = useState<Array<any>>([]);
  const [energy, setEnergy] = useState<Array<any>>([]);
  const [fire, setFire] = useState<Array<any>>([]);
  const [ice, setIce] = useState<Array<any>>([]);
  const [lazer, setLazer] = useState<Array<any>>([]);
  const [missile, setMissile] = useState<Array<any>>([]);

  useEffect(() => {
    if (props.userContext?.userId && props.rewards) {
      if (props?.type === "PL") {
        setBlasterValues(props.rewards.plBlasterSetting);
      } else {
        setBlasterValues(props.rewards.loBlasterSetting);
      }
    }
  }, [props.rewards]);

  const updateBlaster = (
    blaster: any,
    studentId: number,
    programMode: string
  ) => {
    updateStudentBlasters(blaster, studentId, programMode).then((r) => {
      setBlasterValues(r.data);
    });
  };

  const setBlasterValues = (blasterSetting: any) => {
    setBlasters(blasterSetting);
    let rewards: any = {};
    if (props.type === "PL") {
      rewards = props.rewards.plStarReward;
    } else {
      rewards = props.rewards.loStarReward;
    }

    const blasterValues = getBlasterValues(blasterSetting, rewards);
    const cannon = blasterValues.filter((b: any) => b.type === "cannon");
    const energy = blasterValues.filter((b: any) => b.type === "energy");
    const fire = blasterValues.filter((b: any) => b.type === "fire");
    const ice = blasterValues.filter((b: any) => b.type === "ice");
    const lazer = blasterValues.filter((b: any) => b.type === "lazer");
    const missile = blasterValues.filter((b: any) => b.type === "missile");
    setCannon(cannon);
    setEnergy(energy);
    setFire(fire);
    setIce(ice);
    setLazer(lazer);
    setMissile(missile);
  };

  const upgradeBlasters = (type: BlasterType) => {
    if (isBlasterUpgradeable(type) === 1) {
      const tempBlasters = blasters;
      if (type === "cannon") {
        if (tempBlasters.cannon < totalBlasterLevel) tempBlasters.cannon++;
      } else if (type === "energy") {
        if (tempBlasters.energy < totalBlasterLevel) tempBlasters.energy++;
      } else if (type === "fire") {
        if (tempBlasters.fire < totalBlasterLevel) tempBlasters.fire++;
      } else if (type === "ice") {
        if (tempBlasters.ice < totalBlasterLevel) tempBlasters.ice++;
      } else if (type === "lazer") {
        if (tempBlasters.lazer < totalBlasterLevel) tempBlasters.lazer++;
      } else if (type === "missile") {
        if (tempBlasters.missile < totalBlasterLevel) tempBlasters.missile++;
      }
      updateBlaster(tempBlasters, props.userContext?.userId!, props?.type);
    }
  };

  const isBlasterUpgradeable = (type: BlasterType) => {
    let tempBlaster: any = [];
    if (type === "cannon") {
      tempBlaster = cannon.filter((c) => !c.isEnabled);
    } else if (type === "energy") {
      tempBlaster = energy.filter((c) => !c.isEnabled);
    } else if (type === "fire") {
      tempBlaster = fire.filter((c) => !c.isEnabled);
    } else if (type === "ice") {
      tempBlaster = ice.filter((c) => !c.isEnabled);
    } else if (type === "lazer") {
      tempBlaster = lazer.filter((c) => !c.isEnabled);
    } else if (type === "missile") {
      tempBlaster = missile.filter((c) => !c.isEnabled);
    }

    if (tempBlaster.length > 0 && tempBlaster[0].starsRequired === 0) {
      return 1; // true
    } else if (tempBlaster.length > 0 && tempBlaster[0].starsRequired > 0) {
      return 2; // more stars required
    } else if (tempBlaster.length === 0) {
      return 3; // false
    }
  };

  return (
    <>
      <div
        className="h-full w-full bg-gradient-to-b flex items-center flex-col group justify-end from-[#ff8142] to-[#f5c800] rounded-2xl py-4 px-4 text-center md:col-span-2 hover:shadow-2xl transition ease-in-out duration-500 cursor-pointer"
        onClick={() => setBlasterPopup(true)}
      >
        <img
          className="w-auto h-28 m-auto flex-shrink-0 transition group-hover:scale-110 ease-in-out duration-500"
          src={`${Configuration.S3bucketImagePath}/images/blaster-side.svg`}
          alt="blasters"
          title="Blasters"
        />

        {blasters && (
          <p className="mt-2 ">
            {Object.keys(blasters)
              .map((k) => blasters[k])
              .reduce((a, b) => {
                return a + b;
              })}
            /{totalBlasters}
          </p>
        )}
        <h5 className="text-lg">{t("StudentElementaryHome.Blasters")} </h5>
      </div>
      {showBlasterPopup && (
        <Fragment>
          <div className="fixed inset-0 z-40 flex items-center bg-black bg-opacity-50 sm:justify-center enter-done">
            <div className="w-full flex flex-col justify-between overflow-hidden h-auto max-h-80percent bg-white rounded-lg dark:bg-gray-800 sm:m-4 sm:max-w-xl">
              <header className="flex justify-between p-4">
                <h2>{t("StudentElementaryHome.Blasters")} </h2>
                <XIcon
                  className="cursor-pointer h-4 w-4"
                  onClick={() => setBlasterPopup(false)}
                ></XIcon>
              </header>
              <div className="w-full relative overflow-auto px-6 py-3">
                <div className="max-w-7xl mx-auto rounded-2xl">
                  {cannon.length > 0 &&
                    energy.length > 0 &&
                    fire.length > 0 &&
                    missile.length > 0 &&
                    lazer.length > 0 &&
                    ice.length > 0 && (
                      <ul className="grid grid-cols-2 gap-x-1 gap-y-2 sm:grid-cols-3 sm:gap-x-3 lg:grid-cols-6 xl:gap-x-4">
                        {[...Array(4)].map((a, index) => (
                          <Fragment key={index}>
                            <li className="relative" key={"cannon" + index}>
                              <div className="group bg-gradient-to-b from-[#0062ff] to-[#61efff] shadow hover:bg-opacity-80 p-2 block w-full rounded-lg focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden transition ease-in-out duration-500">
                                <img
                                  src={cannon[index].path}
                                  alt="cannon"
                                  className="object-cover pointer-events-none m-auto h-8"
                                />

                                {!cannon[index].isEnabled && (
                                  <div className="flex items-center justify-center">
                                    <div className="absolute bg-white p-1 rounded-lg">
                                      <LockIcon className="w-4 h-4" />
                                    </div>
                                  </div>
                                )}
                              </div>
                            </li>

                            <li className="relative" key={"energy" + index}>
                              <div className="group bg-gradient-to-b from-[#0062ff] to-[#61efff] shadow hover:bg-opacity-80 p-2 block w-full rounded-lg focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden transition ease-in-out duration-500">
                                <img
                                  src={energy[index].path}
                                  alt="energy"
                                  className="object-cover pointer-events-none m-auto h-8"
                                />

                                {!energy[index].isEnabled && (
                                  <div className="flex items-center justify-center">
                                    <div className="absolute bg-white p-1 rounded-lg">
                                      <LockIcon className="w-4 h-4" />
                                    </div>
                                  </div>
                                )}
                              </div>
                            </li>

                            <li className="relative" key={"fire" + index}>
                              <div className="group bg-gradient-to-b from-[#0062ff] to-[#61efff] shadow hover:bg-opacity-80 p-2 block w-full rounded-lg focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden transition ease-in-out duration-500">
                                <img
                                  src={fire[index].path}
                                  alt="fire"
                                  className="object-cover pointer-events-none m-auto h-8"
                                />

                                {!fire[index].isEnabled && (
                                  <div className="flex items-center justify-center">
                                    <div className="absolute bg-white p-1 rounded-lg">
                                      <LockIcon className="w-4 h-4" />
                                    </div>
                                  </div>
                                )}
                              </div>
                            </li>

                            <li className="relative" key={"ice" + index}>
                              <div className="group bg-gradient-to-b from-[#0062ff] to-[#61efff] shadow hover:bg-opacity-80 p-2 block w-full rounded-lg focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden transition ease-in-out duration-500">
                                <img
                                  src={ice[index].path}
                                  alt="ice"
                                  className="object-cover pointer-events-none m-auto h-8"
                                />

                                {!ice[index].isEnabled && (
                                  <div className="flex items-center justify-center">
                                    <div className="absolute bg-white p-1 rounded-lg">
                                      <LockIcon className="w-4 h-4" />
                                    </div>
                                  </div>
                                )}
                              </div>
                            </li>

                            <li className="relative" key={"lazer" + index}>
                              <div className="group bg-gradient-to-b from-[#0062ff] to-[#61efff] shadow hover:bg-opacity-80 p-2 block w-full rounded-lg focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden transition ease-in-out duration-500">
                                <img
                                  src={lazer[index].path}
                                  alt="lazer"
                                  className="object-cover pointer-events-none m-auto h-8"
                                />

                                {!lazer[index].isEnabled && (
                                  <div className="flex items-center justify-center">
                                    <div className="absolute bg-white p-1 rounded-lg">
                                      <LockIcon className="w-4 h-4" />
                                    </div>
                                  </div>
                                )}
                              </div>
                            </li>

                            <li className="relative" key={"missile" + index}>
                              <div className="group bg-gradient-to-b from-[#0062ff] to-[#61efff] shadow hover:bg-opacity-80 p-2 block w-full rounded-lg focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden transition ease-in-out duration-500">
                                <img
                                  src={missile[index].path}
                                  alt="missile"
                                  className="object-cover pointer-events-none m-auto h-8"
                                />

                                {!missile[index].isEnabled && (
                                  <div className="flex items-center justify-center">
                                    <div className="absolute bg-white p-1 rounded-lg">
                                      <LockIcon className="w-4 h-4" />
                                    </div>
                                  </div>
                                )}
                              </div>
                            </li>
                          </Fragment>
                        ))}

                        {/* cannon */}
                        <button
                          className="align-bottom inline-flex leading-none items-center justify-center cursor-pointer  transition-colors duration-150 font-medium focus:outline-none px-1 py-2 rounded-lg text-xs text-white border border-transparent  focus:shadow-outline-purple w-full sm:w-auto bg-primary-blue hover:bg-[#0149c1] gap-2 hover:shadow-md"
                          type="button"
                          onClick={() => upgradeBlasters("cannon")}
                        >
                          {isBlasterUpgradeable("cannon") === 1
                            ? t("Rockets.Upgrade")
                            : isBlasterUpgradeable("cannon") === 2
                            ? t("Rockets.Get") +
                              Math.round(
                                cannon.filter((c) => !c.isEnabled)[0]
                                  .starsRequired
                              ) +
                              t("Rockets.StarsToUnlock")
                            : isBlasterUpgradeable("cannon") === 3
                            ? t("Rockets.Upgraded")
                            : ""}
                        </button>

                        {/* energy */}
                        <button
                          className="align-bottom inline-flex leading-none items-center justify-center cursor-pointer  transition-colors duration-150 font-medium focus:outline-none px-1 py-2 rounded-lg text-xs text-white border border-transparent  focus:shadow-outline-purple w-full sm:w-auto bg-primary-blue hover:bg-[#0149c1] gap-2 hover:shadow-md"
                          type="button"
                          onClick={() => upgradeBlasters("energy")}
                        >
                          {isBlasterUpgradeable("energy") === 1
                            ? t("Rockets.Upgrade")
                            : isBlasterUpgradeable("energy") === 2
                            ? t("Rockets.Get") +
                              Math.round(
                                energy.filter((c) => !c.isEnabled)[0]
                                  .starsRequired
                              ) +
                              t("Rockets.StarsToUnlock")
                            : isBlasterUpgradeable("energy") === 3
                            ? t("Rockets.Upgraded")
                            : ""}
                        </button>

                        {/* fire */}
                        <button
                          className="align-bottom inline-flex leading-none items-center justify-center cursor-pointer  transition-colors duration-150 font-medium focus:outline-none px-1 py-2 rounded-lg text-xs text-white border border-transparent  focus:shadow-outline-purple w-full sm:w-auto bg-primary-blue hover:bg-[#0149c1] gap-2 hover:shadow-md"
                          type="button"
                          onClick={() => upgradeBlasters("fire")}
                        >
                          {isBlasterUpgradeable("fire") === 1
                            ? t("Rockets.Upgrade")
                            : isBlasterUpgradeable("fire") === 2
                            ? t("Rockets.Get") +
                              Math.round(
                                fire.filter((c) => !c.isEnabled)[0]
                                  .starsRequired
                              ) +
                              t("Rockets.StarsToUnlock")
                            : isBlasterUpgradeable("fire") === 3
                            ? t("Rockets.Upgraded")
                            : ""}
                        </button>

                        {/* ice */}
                        <button
                          className="align-bottom inline-flex leading-none items-center justify-center cursor-pointer  transition-colors duration-150 font-medium focus:outline-none px-1 py-2 rounded-lg text-xs text-white border border-transparent  focus:shadow-outline-purple w-full sm:w-auto bg-primary-blue hover:bg-[#0149c1] gap-2 hover:shadow-md"
                          type="button"
                          onClick={() => upgradeBlasters("ice")}
                        >
                          {isBlasterUpgradeable("ice") === 1
                            ? t("Rockets.Upgrade")
                            : isBlasterUpgradeable("ice") === 2
                            ? t("Rockets.Get") +
                              Math.round(
                                ice.filter((c) => !c.isEnabled)[0].starsRequired
                              ) +
                              t("Rockets.StarsToUnlock")
                            : isBlasterUpgradeable("ice") === 3
                            ? t("Rockets.Upgraded")
                            : ""}
                        </button>

                        {/* lazer */}
                        <button
                          className="align-bottom inline-flex leading-none items-center justify-center cursor-pointer  transition-colors duration-150 font-medium focus:outline-none px-1 py-2 rounded-lg text-xs text-white border border-transparent  focus:shadow-outline-purple w-full sm:w-auto bg-primary-blue hover:bg-[#0149c1] gap-2 hover:shadow-md"
                          type="button"
                          onClick={() => upgradeBlasters("lazer")}
                        >
                          {isBlasterUpgradeable("lazer") === 1
                            ? t("Rockets.Upgrade")
                            : isBlasterUpgradeable("lazer") === 2
                            ? t("Rockets.Get") +
                              Math.round(
                                lazer.filter((c) => !c.isEnabled)[0]
                                  .starsRequired
                              ) +
                              t("Rockets.StarsToUnlock")
                            : isBlasterUpgradeable("lazer") === 3
                            ? t("Rockets.Upgraded")
                            : ""}
                        </button>

                        {/* missile */}
                        <button
                          className="align-bottom inline-flex leading-none items-center justify-center cursor-pointer  transition-colors duration-150 font-medium focus:outline-none px-1 py-2 rounded-lg text-xs text-white border border-transparent  focus:shadow-outline-purple w-full sm:w-auto bg-primary-blue hover:bg-[#0149c1] gap-2 hover:shadow-md"
                          type="button"
                          onClick={() => upgradeBlasters("missile")}
                        >
                          {isBlasterUpgradeable("missile") === 1
                            ? t("Rockets.Upgrade")
                            : isBlasterUpgradeable("missile") === 2
                            ? t("Rockets.Get") +
                              Math.round(
                                missile.filter((c) => !c.isEnabled)[0]
                                  .starsRequired
                              ) +
                              t("Rockets.StarsToUnlock")
                            : isBlasterUpgradeable("missile") === 3
                            ? t("Rockets.Upgraded")
                            : ""}
                        </button>
                      </ul>
                    )}
                </div>
              </div>
              <footer className="flex flex-col items-center justify-end px-6 py-3 -mx-6 -mb-4 space-y-4 sm:space-y-0 sm:space-x-6 sm:flex-row bg-gray-50 dark:bg-gray-800"></footer>
            </div>
          </div>
        </Fragment>
      )}
    </>
  );
};

export default Blasters;

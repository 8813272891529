import React, { useState, useEffect } from "react";
import PIEChart from "../../shared/charts/piechart";

export default function ChartQuestionsByStandards({ data }: any) {
  const [standardDataValues, setStandardDataValue] = useState<Array<any>>([]);
  const [toggleShowMore, setToggleShowMoreOption] = useState<boolean>(false);
  const [standardData, setStandardData] = useState<Array<any>>([]);

  useEffect(() => {
    if (data && data.elements && Object.keys(data.elements).length) {
      let chartDivHeight: any = document.getElementById("chart_id");
      if (chartDivHeight) chartDivHeight = chartDivHeight.style.height;

      let totalQuestions = 0;
      if (data.elements) {
        Object.entries(data.elements).forEach(
          ([key, element]: any) =>
            (totalQuestions += element.total_points
              ? parseInt(element.total_points)
              : 0)
        );
      }

      const stdData: any = [];
      Object.entries(data.elements).forEach(([key, value]: any) => {
        stdData.push({
          name: value.name,
          value: Number(
            ((value.total_points * 100) / totalQuestions).toFixed(2)
          ),
        });
      });
      setStandardData(stdData);
      setStandardDataValue(stdData.slice(0, 10));
    }
  }, []);

  const showHideGraphDetails = (): void => {
    if (!toggleShowMore) {
      setStandardDataValue(standardData);
    } else {
      setStandardDataValue(standardData.slice(0, 10));
    }
    setToggleShowMoreOption(!toggleShowMore);
  };

  return (
    <>
      {standardDataValues.length > 1 && (
        <div className="relative w-full mt-8 px-7 py-7 bg-white rounded-md shadow-md xs-chart-fix xs-standards-chart-fix">
          <div
            id="chart_id"
            style={{
              display: "inline-block",
            }}
          >
            <h2 className="text-md font-semibold text-gray-800 mb-2">
              Question Breakdown Percent by Standard
            </h2>

            {standardDataValues.length > 1 && (
              <PIEChart data={standardDataValues} innerRadius={40} />
            )}

            {standardDataValues.length >= 10 && (
              <div style={{ textAlign: "center" }} className="show-more">
                {toggleShowMore ? (
                  <button
                    style={{ fontSize: "15px", color: "blue" }}
                    onClick={showHideGraphDetails}
                  >
                    Hide &#x2C4;
                  </button>
                ) : (
                  <button
                    style={{ fontSize: "15px", color: "blue" }}
                    onClick={showHideGraphDetails}
                  >
                    Show More &#x2C5;
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}

import { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import { getSchoolByDistrictId } from "../../../api/teacher/school";
import { Info } from "../../../assets/icons";
import { ISelectSchoolElement } from "../../../model/interface/selectElement";
import Loader from "../loader";

interface SchoolProps {
  infoTitle?: string;
  selectedItems: Array<ISelectSchoolElement>;
  onChange: (selectedItems: Array<ISelectSchoolElement>) => void;
}

function School(props: SchoolProps & any) {
  const infoTitle = props.infoTitle as string;
  const selectedItems = props.selectedItems as Array<ISelectSchoolElement>;
  const onChange = props.onChange as (selectedItems: Array<ISelectSchoolElement>) => void;
  const [schools, setSchools] = useState<Array<ISelectSchoolElement>>([]);
  const [showLoading, setShowLoading] = useState<boolean>(false);

  const getSchools = () => {
    setShowLoading(false);
    getSchoolByDistrictId(props.userContext.districtId).then((response) => {
      setShowLoading(false);
      let schoolsData: Array<ISelectSchoolElement> = new Array<ISelectSchoolElement>();

      const result = response.data;
      result.forEach(function (school: any) {
        schoolsData.push({ label: school.name, value: { schoolAccountId: school.schoolAccountId, schoolId: school.schoolId, }});
      });

      setSchools(schoolsData);
    });
  };

  function handleChange(e: Array<ISelectSchoolElement>) {
    let selectedValues: Array<ISelectSchoolElement> = e;
    if (selectedValues && selectedValues.length > 0) {
      if (selectedValues.some(r => r.value.schoolId === 0) &&
        selectedValues.some(r => r.value.schoolId > 0)) {
        selectedValues = selectedValues.filter(r => r.value.schoolId > 0);
      }
    } else {
      selectedValues = [{label: "All Schools", value: { schoolId: 0, schoolAccountId: 0 }}];
    }
    onChange(selectedValues);
  }

  useEffect(() => {
    getSchools();
  }, []);

  return (
    <Fragment>
      {showLoading && <Loader></Loader>}
      <div className="flex flex-col">
        <div className="flex gap-2">
          <label className="block mb-1 text-sm text-gray-500">School</label>
          {infoTitle && <Info title={infoTitle} />}
        </div>
        <Select
          value={selectedItems}
          options={schools}
          isMulti={true}
          isSearchable={true}
          onChange={(e) => handleChange(e as Array<ISelectSchoolElement>)}
          closeMenuOnSelect={false}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary: "#992883",
            },
          })}
          placeholder={"All Schools"}
        />
      </div>
    </Fragment>
  );
}

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(School);

import { Fragment, useEffect, useState } from "react";
import Select from "react-select";
import { ISelectElement } from "../../../../model/interface/selectElement";
import Pagination from "../../../shared/pagination/pagination";
import PageSize from "../../../shared/pagination/pageSize";
import { toast } from "react-toastify";
import {
  DEFAULT_PAGE_NUMBER,
  DEFAULT_PAGE_SIZE,
} from "../../../../utils/pagingConstant";
import { DownloadIcon } from "../../../../assets/icons";
import PagingResponse from "../../../../model/common/pagingResponse";
import {
  getClassesBubbleSheet,
  getStudentsBubbleSheet,
  downloadStudentsBubbleSheet,
} from "../../../../api/teacher/bubbleSheet";
import ClassStudentModel from "../../../../model/teacher/classManagement/classStudentModel";
import BubbleSheetModel from "../../../../model/teacher/bubbleSheet/bubbleSheetModel";
import Loader from "../../../shared/loader";

interface passedProps {
  userId: number;
  roleId: number;
}

function BubbleSheetList(props: passedProps) {
  const defaultValue = { label: "Select", value: "0" };
  const { userId } = props;
  // const [showLoading, setShowLoading] = useState<boolean>(false);
  const [pageNumber, setPageNumber] = useState(DEFAULT_PAGE_NUMBER);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [resetPageNumber, setResetPageNumber] = useState<boolean>(false);
  const [students, setStudents] = useState<PagingResponse<ClassStudentModel>>();
  const [classes, setClasses] = useState<Array<ISelectElement>>([]);
  const [selectedClasses, setSelectedClasses] = useState<ISelectElement>();
  const [selectedStudents, setSelectedStudents] = useState<
    Array<BubbleSheetModel>
  >([]);
  const [toggleSelectAll, setToggleSelectAll] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  function resetFilters() {
    setSelectedClasses(defaultValue);
    //setShowLoading(true);
    setLoading(true);
    getStudentsBubbleSheet(0, pageNumber, pageSize).then((r) => {
      setLoading(false);
      setStudents(r.data.data);
      setSelectedStudents([]);
      // setShowLoading(false);
    });
  }

  function searchStudents() {
    //setShowLoading(true);
    setLoading(true);
    getStudentsBubbleSheet(
      selectedClasses && selectedClasses.value !== "0"
        ? parseInt(selectedClasses.value)
        : 0,
      pageNumber,
      pageSize
    ).then((r) => {
      setStudents(r.data);
      setSelectedStudents([]);
      //setShowLoading(false);
      setLoading(false);
    });
  }

  function bindClasses() {
    setLoading(true);
    getClassesBubbleSheet(userId).then((response) => {
      setLoading(false);
      const classesData = response.data.data;
      var classes: Array<ISelectElement> = new Array<ISelectElement>();
      classesData.forEach(function (keyValuePair: any) {
        classes.push({ label: keyValuePair.name, value: keyValuePair.classId });
      });
      setClasses(classes);
    });
  }

  function handleClassesChange(selectdItem: any) {
    setSelectedClasses(selectdItem);
  }

  function handleSelectedStudents(student: ClassStudentModel) {
    var selectedStudenttList: Array<BubbleSheetModel> =
      new Array<BubbleSheetModel>();
    const studentExist = selectedStudents.some(
      (r) => (r as BubbleSheetModel).userId === student.userId
    );
    if (studentExist) {
      selectedStudenttList = selectedStudents.filter(
        (r) => (r as BubbleSheetModel).userId !== student.userId
      );
    } else {
      selectedStudenttList = [
        ...selectedStudents,
        {
          userId: student.userId,
          username: student.username,
          firstName: student.firstName,
          lastName: student.lastName,
        },
      ];
    }
    setSelectedStudents(selectedStudenttList);
  }

  function handleSelectAll() {
    const isSelected = !toggleSelectAll;
    if (isSelected && students && students.data) {
      setSelectedStudents(
        students.data.map((r) => {
          return new BubbleSheetModel(
            r.userId,
            r.username,
            r.firstName,
            r.lastName
          );
        })
      );
    } else {
      setSelectedStudents([]);
    }
    setToggleSelectAll(!toggleSelectAll);
  }

  function handleFileDownload(userId) {
    let selectedStudentList: Array<BubbleSheetModel> =
      new Array<BubbleSheetModel>();
    if (students && students.data) {
      selectedStudentList = students.data
        .filter((x) => x.userId === userId)
        .map((r) => {
          return new BubbleSheetModel(
            r.userId,
            r.username,
            r.firstName,
            r.lastName
          );
        });
      setSelectedStudents(selectedStudentList);
    }
    if (selectedStudentList.length > 0) {
      setLoading(true);
      downloadStudentsBubbleSheet(selectedStudentList).then((response) => {
        setLoading(false);
        const file = response.data;
        const fileURL = URL.createObjectURL(file);
        const link = document.createElement("a");
        link.href = fileURL;
        link.setAttribute(
          "download",
          selectedStudentList[0].username + "_bubblesheet.pdf"
        );
        document.body.appendChild(link);
        link.click();
      });
    }
  }
  function handleFileDownloadAll() {
    if (!selectedStudents || selectedStudents.length === 0) {
      toast.error("Please select at least one student from the student list.");
      return;
    }
    setLoading(true);
    downloadStudentsBubbleSheet(selectedStudents).then((response) => {
      setLoading(false);
      const file = response.data;
      const fileURL = URL.createObjectURL(file);
      const link = document.createElement("a");
      link.href = fileURL;
      link.setAttribute(
        "download",
        selectedStudents.length === 1
          ? selectedStudents[0].username + "_bubblesheet.pdf"
          : "bubblesheet.pdf"
      );
      document.body.appendChild(link);
      link.click();
    });
  }
  const onPageChange = (pageNo) => {
    setPageNumber(pageNo);
  };
  const onPageSizeChange = (pageSize) => {
    setPageNumber(DEFAULT_PAGE_NUMBER);
    setPageSize(pageSize);
    setResetPageNumber(true);
  };
  useEffect(() => {
    searchStudents();
  }, [pageSize, pageNumber]);

  useEffect(() => {
    bindClasses();
  }, []);
  return (
    <Fragment>
      {loading && <Loader />}
      <div className="min-w-0 block lg:col-span-9 xl:col-span-10 w-full">
        <div className="bg-gray-100  w-full mb-5 rounded-lg p-5 h-full">
          <div className="grid grid-cols-2 sm:grid-cols-8 lg:grid-cols-8 xl:grid-cols-8 gap-6 pb-4">
            <div className="col-span-8 sm:col-span-4 xl:col-span-2">
              <label className="block text-sm text-gray-500">Class</label>
              <Select
                value={selectedClasses}
                options={classes}
                onChange={handleClassesChange}
              />
            </div>
          </div>
          <div className="flex flex-col md:flex-row gap-4 items-start md:items-center md:justify-between justify-start pb-10">
            <div className="grid grid-cols-2 gap-4">
              <button
                onClick={() => resetFilters()}
                className="bg-transparent border my-auto border-primary-violet shadow-sm py-2 px-4 flex justify-center text-sm font-medium text-primary-violet hover:text-white hover:bg-light-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3 transition ease-in-out duration-500"
              >
                {" "}
                Reset
              </button>
              <button
                onClick={() => searchStudents()}
                className="bg-primary-violet border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-dark-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3 "
              >
                Filter
              </button>
            </div>
          </div>

          <div className="bg-white py-5 px-5">
            <div className="flex flex-col md:flex-row flex-wrap gap-6 md:items-center xl:justify-end mb-5 mt-2">
              <div className="flex items-center">
                <DownloadIcon
                  className="w-4 m-auto text-gray-500"
                  onClick={() => handleFileDownloadAll()}
                />

                <span className="text-xs ml-2 text-gray-500">Download all</span>
              </div>
            </div>
            <div className="mb-4 pb-4 border-b-2 overflow-auto">
              <table className="table w-full ">
                <thead className="mb-5">
                  <tr className="bg-light-violet  text-white py-3 px-3 overflow-hidden">
                    <th className="text-center  p-3 text-sm font-normal">
                      <input
                        id="styled-checkbox-2"
                        type="checkbox"
                        className="checkbox"
                        checked={toggleSelectAll}
                        onChange={() => handleSelectAll()}
                      />
                    </th>
                    <th className="text-center  p-3 text-sm font-normal">
                      First Name
                    </th>
                    <th className="text-center  p-3 text-sm font-normal">
                      Last Name
                    </th>
                    <th className="text-center  p-3 text-sm font-normal">
                      Download
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {students &&
                    students.data?.length > 0 &&
                    students.data?.map((student, index) => {
                      return (
                        <Fragment key={index}>
                          <tr
                            className={`hover:bg-light-violet/20 transition ease-in-out duration-300 ${
                              index % 2 === 0
                                ? "bg-white"
                                : "bg-light-violet/10"
                            }`}
                          >
                            <td className="text-center text-sm mt-5  p-3">
                              <input
                                id="styled-checkbox-2"
                                type="checkbox"
                                className="checkbox"
                                checked={selectedStudents.some(
                                  (r) =>
                                    (r as BubbleSheetModel).userId ===
                                    student.userId
                                )}
                                onChange={() => handleSelectedStudents(student)}
                              />
                              <label className="checkbox-label"></label>
                            </td>
                            <td className="text-center text-sm mt-5  p-3">
                              <span className="text-dark-blue">
                                {student.firstName}
                              </span>
                            </td>

                            <td className="text-center text-sm mt-5  p-3">
                              <span className="text-dark-blue">
                                {student.lastName}
                              </span>
                            </td>

                            <td className="text-center text-sm mt-5  p-3">
                              <DownloadIcon
                                className="w-4 m-auto text-gray-500"
                                onClick={() =>
                                  handleFileDownload(student.userId)
                                }
                              />
                            </td>
                          </tr>
                        </Fragment>
                      );
                    })}
                </tbody>
              </table>
            </div>
            <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
              {students?.totalRecords !== undefined &&
                students?.totalRecords !== 0 && (
                  <Pagination
                    totalRecords={students?.totalRecords}
                    pageSize={pageSize}
                    onPageChange={onPageChange}
                    reset={resetPageNumber}
                    pageNumber={pageNumber}
                  />
                )}
              {students?.totalRecords !== 0 && (
                <PageSize
                  pageSize={pageSize}
                  onPageSizeChange={onPageSizeChange}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default BubbleSheetList;

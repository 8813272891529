import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { MessageApi } from "../../../../api/message/messageApi";
import {
  HomeIcon,
  LogoPLIcon,
  LiftOffIcon,
  GoogleClassroomIcon,
  CanvasIcon,
} from "../../../../assets/icons";
import ProfileModel from "../../../../model/users/profile";
import * as userContextAction from "../../../../redux/actions/userContextAction";
import auth from "../../../../utils/auth";
import constant from "../../../../utils/constant/constant";
import RouteConstant from "../../../../utils/constant/routeConstant";
import ParentConnectPopup from "../../../screen/student/elementary/popup/parentConnect";
import { useHistory } from "react-router-dom";
import { backToOriginalUser } from "../../../../utils/impersonateHelper";
import { fetchProfile } from "../../../../redux/actions/userActions";
import DefaultUserIcon from "../../../../assets/images/userIcon.png";
import AlienRanking from "./popup/alienRanking";
import AlienAvatar from "../shared/alienAvatar/alienAvatar";
import StudentProfile from "../../../../model/student/studentProfile";
import { getStudentProfile } from "../../../../api/student/studentProfile";
import StudentSchoolCode from "../../../shared/header/studentSchoolCode";
import { UserCircleIcon } from "@heroicons/react/solid";
import { isActiveCanvasExternalRoster } from "../../../../utils/helper";
import { Configuration } from "../../../../environment/setup";
import { IStudentReward } from "../../../../model/interface/studentReward";
import { getDefaultRewards } from "../../../../utils/studentHelper";
import { getStudentRewards } from "../../../../api/student/studentApi";

interface headerProps {
  isSubjectHomePage?: boolean;
  showParentConnectPopUp: (show: boolean) => void;
  userContext: userContextAction.UserContextState;
  profile?: ProfileModel;
}

const Header: React.FC<headerProps> = (props: any) => {
  const { t } = useTranslation();
  const [showMenu, setShowMenu] = useState(false);
  const [showParentConnect, setShowParentConnect] = useState(false);
  const [programMode, setProgramMode] = useState(constant.ProgramMode.EG);
  const [speakerMode, setSpeakerMode] = useState(constant.SpeakerMode.Hold);
  const [studentProfile, setStudentProfile] = useState<StudentProfile>();
  const [rewards, setRewards] = useState<IStudentReward>(getDefaultRewards());

  const history = useHistory();
  // const closeChatWindow = () => {
  //   // SetIsOpenChat(false);
  // };

  const handleToggleMenu = () => {
    setShowMenu(!showMenu);

    if (!studentProfile && showMenu) {
      bindStudentProfile();
      getStudentRewards(props.userContext?.userId ?? 0).then((d) => {
        setRewards(d.data);
      });
    }
  };

  const logout = () => {
    auth.logout();
  };

  function bindStudentProfile() {
    getStudentProfile(
      props.userContext!.userId,
      props.profile?.gradeId ? props.profile?.gradeId : 0
    ).then((response) => {
      setStudentProfile(response.data);
    });
  }
  useEffect(() => {
    localStorage.setItem(constant.TTS.SPEAKER_MODE_KEY, speakerMode.toString());
    MessageApi.handleOnActive(
      props.userContext.userId,
      props.userContext.schoolId
    );
  }, []);

  function handleNaviagation(link: string, programMode?: string) {
    if (programMode) {
      setProgramMode(programMode);
      localStorage.setItem("programMode", programMode);
    }
    history.push(link);
  }

  function backToTeacher(): void {
    backToOriginalUser(
      props.userContext,
      props.setUserContext,
      props.setProfile,
      history
    );
  }
  function handleSpeaker() {
    const spMode =
      speakerMode === constant.SpeakerMode.Hold
        ? constant.SpeakerMode.Speak
        : constant.SpeakerMode.Hold;
    setSpeakerMode(spMode);
    localStorage.setItem(constant.TTS.SPEAKER_MODE_KEY, spMode.toString());
  }

  return (
    <>
      {props.userContext?.impersonatedUser !== null && (
        <div className="w-full pointer-events-none h-screen border-4 border-[#A3E635] z-50 flex justify-center fixed ">
          <span className="bg-[#A3E635] h-8  px-3  flex items-center  text-gray-700 ">
            You are logged in as {props.profile?.lastName},{" "}
            {props.profile?.firstName}
            <span
              onClick={backToTeacher}
              className="pl-2 text-gray-700 hover:underline cursor-pointer pointer-events-auto font-semibold"
            >
              Return to your account
            </span>
          </span>
        </div>
      )}

      <header
        className={`${
          window.location.pathname === "/kindergarten" ? "lg:fixed" : ""
        } lg:top-0 lg:left-0 lg:w-full bg-[#091423] z-10`}
      >
        <div>
          <nav className="py-2 shadow">
            <div className=" px-4 sm:px-6 lg:px-8">
              <div className="flex-shrink-0 flex mx-auto">
                <div className="flex justify-between items-center h-16 w-full">
                  <div className="flex-shrink-0 flex items-center ">
                    <span className="hidden">{programMode}</span>
                    <Link
                      to={`${RouteConstant.KindergartenStudentRoutes.Home}`}
                    >
                      <button>
                        {/* {props.userContext.stateId ===
                          Configuration.TexasStateId && (
                          <img
                            className="block lg:block h-12 sm:h-16 w-auto"
                            src={`${Configuration.S3bucketImagePath}/images/EG-poweredby-PL-logo-white.png`}
                            alt="Workflow"
                          ></img>
                        )} */}
                        {/* {props.userContext.stateId !==
                          Configuration.TexasStateId && ( */}
                        <LogoPLIcon
                          className="block lg:block h-12 sm:h-16 w-auto"
                          title="Progress Learning"
                        ></LogoPLIcon>
                        {/* )} */}
                      </button>
                    </Link>
                    {props.userContext.gradeId > 2 &&
                      props.profile?.isLOActive && (
                        <button
                          type="button"
                          title="Liftoff"
                          onClick={() =>
                            handleNaviagation(
                              RouteConstant.LiftOff.Kindergarten.Home,
                              constant.ProgramMode.LO
                            )
                          }
                          className="filter grayscale"
                        >
                          <LiftOffIcon
                            className="hidden lg:block h-12 w-auto ml-3"
                            title="Liftoff"
                          ></LiftOffIcon>
                        </button>
                      )}
                  </div>

                  {props.isSubjectHomePage && (
                    <div
                      className="flex space-x-3  items-center cursor-pointer"
                      onClick={() => {
                        history.push(
                          RouteConstant.KindergartenStudentRoutes.Home
                        );
                      }}
                    >
                      <span className="ml-3 md:ml-0">
                        <HomeIcon
                          title="Back to home"
                          className="h-9 sm:h-12 sm:w-12"
                        />
                      </span>
                      <Link
                        className="hidden sm:block font-semibold text-white"
                        to={`${RouteConstant.KindergartenStudentRoutes.Home}`}
                      >
                        {t("Breadcrumb.BackToHome")}
                      </Link>
                    </div>
                  )}
                  {/* Mobile View */}
                  <div className="ml-auto mr-2 md:hidden">
                    <StudentSchoolCode theme="KINDERGARTEN"></StudentSchoolCode>
                  </div>

                  <div className="flex items-center">
                    <div className="flex sm:space-x-3 uppercase justify-center items-center font-Didact">
                      <div>
                        <StudentSchoolCode theme="KINDERGARTEN"></StudentSchoolCode>
                      </div>
                      {speakerMode === constant.SpeakerMode.Speak && (
                        <div
                          title="Speaker on"
                          className="bg-yellow-400 flex items-center justify-center p-1 my-auto rounded-md"
                          onClick={() => handleSpeaker()}
                        >
                          <svg
                            version="1.0"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 100.000000 100.000000"
                            preserveAspectRatio="xMidYMid meet"
                            className="fill-current text-black w-7 cursor-pointer"
                          >
                            <g
                              transform="translate(0.000000,100.000000) scale(0.100000,-0.100000)"
                              fill="currentColor"
                              stroke="none"
                            >
                              <path
                                d="M454 930 c-99 -31 -184 -134 -187 -227 -2 -36 2 -48 13 -48 9 0 17
 15 22 42 14 87 60 147 138 182 79 36 183 19 249 -42 38 -35 71 -115 71 -174 0
 -107 -16 -147 -107 -266 -47 -62 -103 -145 -125 -186 -49 -89 -84 -114 -147
 -108 -59 6 -93 34 -112 92 -18 52 -35 67 -45 40 -20 -52 60 -157 131 -171 86
 -16 147 20 199 115 21 38 67 107 103 153 100 130 111 149 128 213 41 158 -5
 292 -123 358 -58 32 -151 44 -208 27z"
                              ></path>
                              <path
                                d="M465 826 c-55 -25 -105 -94 -105 -146 0 -11 -14 -39 -31 -64 -39 -54
 -34 -85 16 -115 41 -24 43 -33 12 -50 -28 -15 -57 -61 -57 -91 0 -53 64 -112
 105 -96 24 9 17 26 -15 38 -31 10 -50 34 -50 63 0 9 19 33 41 54 36 32 41 42
 35 65 -4 16 -21 35 -41 48 -19 11 -35 24 -35 28 0 5 12 23 26 40 15 17 31 52
 35 78 11 59 36 93 82 111 73 27 148 -9 168 -82 15 -52 25 -65 40 -50 19 19 1
 80 -34 120 -52 60 -126 78 -192 49z"
                              ></path>
                            </g>
                          </svg>
                        </div>
                      )}
                      {speakerMode === constant.SpeakerMode.Hold && (
                        <div
                          title="Speaker off"
                          className="bg-gray-200 flex items-center justify-center p-1 my-auto rounded-md"
                          onClick={() => handleSpeaker()}
                        >
                          <svg
                            version="1.0"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 100.000000 100.000000"
                            preserveAspectRatio="xMidYMid meet"
                            className="fill-current text-red-500 w-7 font-bold cursor-pointer"
                          >
                            <g
                              transform="translate(0.000000,100.000000) scale(0.100000,-0.100000)"
                              fill="currentColor"
                              stroke="none"
                            >
                              <path
                                d="M447 926 c-65 -24 -115 -64 -143 -113 -15 -27 -29 -50 -31 -52 -2 -3

-42 33 -88 79 -67 67 -87 82 -97 72 -10 -10 72 -97 400 -424 328 -329 414

-410 424 -400 10 10 -13 38 -115 140 l-127 127 37 45 c55 69 84 140 90 223 6

87 -6 139 -50 203 -62 91 -201 137 -300 100z m178 -50 c97 -45 141 -129 132

-250 -6 -79 -32 -143 -86 -206 l-30 -35 -126 125 -125 125 14 53 c16 62 36 87

83 103 74 24 144 -13 166 -89 15 -49 33 -65 43 -37 20 52 -60 157 -130 171

-87 16 -176 -40 -198 -122 l-12 -44 -23 22 c-28 26 -28 38 -4 86 25 48 55 76

111 101 60 27 123 26 185 -3z"
                              />

                              <path
                                d="M307 584 c-20 -20 -5 -55 33 -79 46 -29 49 -37 17 -54 -28 -15 -57

-61 -57 -91 0 -53 64 -112 105 -96 24 9 17 26 -15 38 -31 10 -50 34 -50 63 0

9 19 33 41 54 35 32 41 43 35 65 -3 14 -19 33 -34 42 -15 10 -36 28 -48 41

-11 13 -23 20 -27 17z"
                              />

                              <path
                                d="M543 238 c-62 -117 -102 -148 -177 -134 -49 9 -84 41 -97 89 -15 52

-25 65 -40 50 -19 -19 -1 -80 34 -120 58 -66 132 -80 207 -40 36 19 52 38 92

111 46 82 54 116 29 116 -6 0 -28 -32 -48 -72z"
                              />
                            </g>
                          </svg>
                        </div>
                      )}

                      <Link
                        className="flex items-center group hover:text-gray-900"
                        to={`${RouteConstant.KindergartenStudentRoutes.Profile}`}
                      >
                        <button
                          type="button"
                          className="bg-gradient-to-r rounded-md from-[#dec80a] to-[#F29C1E] group-hover:from-[#c1ad06] group-hover:to-[#de880b] hover:from-[#c1ad06] hover:to-[#de880b] shadow-sm py-1.5 px-1.5 ml-1.5 sm:ml-0 sm:pr-4 sm:pl-3 inline-flex items-center justify-center font-medium text-base "
                        >
                          <UserCircleIcon className="w-7 h-7 group-hover:text-gray-900" />
                          <span className="hidden sm:block ml-2">
                            {t("Shared.Profile.Profile")}
                          </span>
                        </button>
                      </Link>
                      <span className="transition ease-in-out duration-500 border-transparent  text-white inline-flex items-center rounded-md py-1.5 border-b-2 text-base font-medium">
                        <div className="flex justify-center items-center capitalize">
                          <img
                            className="h-8 w-8 hidden rounded-full"
                            src={DefaultUserIcon}
                            alt=""
                          />
                          &nbsp;
                          <span className="pl-2 capitalize break-all">
                            Hi,&nbsp;
                            {props.profile?.firstName +
                              " " +
                              props.profile?.lastName}
                            <div className="text-center bg-primary-violet text-white rounded-md text-xs px-1.5 py-0.5">
                              {props?.profile
                                ? props?.profile?.gradeId - 1 === 0
                                  ? t("Shared.StudentHeader.Kindergarten")
                                  : props?.profile?.gradeId -
                                    1 +
                                    "st " +
                                    t("Shared.StudentHeader.Grade")
                                : ""}
                            </div>
                          </span>
                        </div>
                      </span>
                      {props?.userContext?.activeExternalRoster ===
                        "GOOGLE" && (
                        <div>
                          <a
                            href="#!"
                            className="no-underline md:text-gray-700 flex items-center hover:text-black transition-all text-xs lg:text-sm py-3 md:py-5"
                          >
                            <GoogleClassroomIcon
                              className="h-6 "
                              title="Google Classroom"
                            ></GoogleClassroomIcon>
                          </a>
                        </div>
                      )}
                      {isActiveCanvasExternalRoster(props?.userContext) && (
                        <a
                          href="#!"
                          className="no-underline md:text-gray-700 flex items-center hover:text-black transition-all text-xs lg:text-sm py-3 md:py-5"
                        >
                          <CanvasIcon
                            className="h-6 mr-2"
                            title="Canvas"
                          ></CanvasIcon>
                          <span className=" block md:hidden">Canvas</span>
                        </a>
                      )}
                      <div
                        className="ml-2"
                        onClick={() => {
                          logout();
                        }}
                      >
                        <div>
                          <span
                            title="Logout"
                            className="cursor-pointer inline-flex bg-gradient-to-b from-[#ff416c] to-[#ff4b2b] hover:from-[#ff416c] hover:to-[#d62000] transition ease-in-out duration-500 border-transparent  text-gray-900 inline-flex items-center px-3 rounded-md py-2 border-b-2 text-base font-black shadow-lg"
                          >
                            <img
                              className="h-4 w-4"
                              src={`${Configuration.S3bucketImagePath}/images/logout.svg`}
                              alt="logout"
                            />
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="-mr-2 flex items-center md:hidden">
                      <button
                        type="button"
                        className="inline-flex items-center justify-center p-1 ml-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                        aria-controls="mobile-menu"
                        aria-expanded="false"
                        onClick={() => {
                          handleToggleMenu();
                        }}
                      >
                        <span className="sr-only">Open main menu</span>
                        <svg
                          className="block h-6 w-6"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M4 6h16M4 12h16M4 18h16"
                          ></path>
                        </svg>
                        <svg
                          className="hidden h-6 w-6"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M6 18L18 6M6 6l12 12"
                          >
                            {" "}
                          </path>
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {showMenu && (
              <div
                x-description="Mobile menu, show/hide based on menu state."
                className=" z-20 md:w-1/3 ml-auto relative md:hidden bg-[#091423] md:bg-transparent px-4 pt-2 md:px-0 md:pt-0"
                id="mobile-menu"
                x-show="open"
              >
                <div className="space-y-1 hidden">
                  {/* <span className="cursor-pointer inline-flex bg-gradient-to-b from-[#ddc600] to-[#ff890a] hover:from-[#ddc600] hover:to-[#ff5200] transition ease-in-out duration-500 border-transparent  text-gray-900 inline-flex items-center px-3 rounded-md py-1.5 border-b-2 text-base font-medium shadow-lg w-full font-Didact">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      className="-ml-0.5 mr-2 h-4 w-4"
                      viewBox="0 0 16 16"
                    >
                      <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555zM0 4.697v7.104l5.803-3.558L0 4.697zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757zm3.436-.586L16 11.801V4.697l-5.803 3.546z">
                        {" "}
                      </path>
                    </svg>
                    {t("Shared.StudentHeader.Messages")}
                  </span> */}
                  <span
                    className="hidden inline-flex bg-gradient-to-b from-[#ddc600] to-[#ff890a] hover:from-[#ddc600] hover:to-[#ff5200] transition ease-in-out duration-500 border-transparent  text-gray-900 inline-flex items-center px-3 rounded-md py-1.5 border-b-2 text-base font-medium shadow-lg w-full font-Didact"
                    onClick={() => {
                      setShowParentConnect(true);
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      className="-ml-0.5 mr-2 h-4 w-4"
                      viewBox="0 0 16 16"
                    >
                      <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555zM0 4.697v7.104l5.803-3.558L0 4.697zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757zm3.436-.586L16 11.801V4.697l-5.803 3.546z"></path>
                    </svg>
                    {t("Shared.StudentHeader.ParentConnect")}
                  </span>
                  <span className="cursor-pointer inline-flex bg-gradient-to-b from-[#96d659] to-[#70d11b] hover:from-[#96d659] hover:to-[#4b9808] transition ease-in-out duration-500 border-transparent  text-gray-900 inline-flex items-center px-3 rounded-md py-1.5 border-b-2 text-base font-medium shadow-lg w-full font-Didact">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      className="-ml-0.5 mr-2 h-4 w-4"
                      viewBox="0 0 16 16"
                    >
                      <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555zM0 4.697v7.104l5.803-3.558L0 4.697zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757zm3.436-.586L16 11.801V4.697l-5.803 3.546z">
                        {" "}
                      </path>
                    </svg>
                    Grade{" "}
                    <span id="grade-name">
                      {props.userContext.gradeId > 1
                        ? props.userContext.gradeId - 1
                        : "K"}
                    </span>
                  </span>
                </div>
                <div className="pt-2 pb-3 border-gray-200">
                  <div className="flex items-center px-4 bg-[#a2147e] py-3 rounded-lg hidden">
                    <div className="flex-shrink-0">
                      <img
                        className="h-8 w-8 rounded-full"
                        src={DefaultUserIcon}
                        alt=""
                      />
                    </div>
                    <div className="ml-3">
                      <div className="text-base font-medium text-white font-Didact">
                        {" "}
                        Hi,{" "}
                        <span>
                          {props.profile?.firstName +
                            " " +
                            props.profile?.lastName}
                        </span>
                      </div>
                      {props?.userContext.activeExternalRoster === "GOOGLE" && (
                        <a
                          href="#!"
                          className="no-underline md:text-gray-700 flex items-center py-3  hover:text-black transition-all text-xs lg:text-sm md:pl-3 py-3 md:py-5"
                        >
                          <GoogleClassroomIcon
                            className="h-6 mr-2"
                            title="Google Classroom"
                          ></GoogleClassroomIcon>
                          <span className=" block md:hidden">
                            Google Classroom
                          </span>
                        </a>
                      )}
                    </div>
                  </div>
                  <div className="mt-2 space-y-1">
                    <div className="px-2 py-2  bg-gradient-to-b from-white/10 to-transparent rounded-lg transition ease-in-out mx-auto text-center gap-4 flex flex-col">
                      <div className=" border-2 border-opacity-50 rounded-md overflow-hidden cursor-pointer hover:border-opacity-100 transition ease-in-out">
                        {
                          <AlienRanking
                            studentId={props.userContext!.userId}
                            gradeId={
                              props.profile?.gradeId
                                ? props.profile?.gradeId
                                : 0
                            }
                            firstName={props.profile?.firstName!}
                            lastName={props.profile?.lastName!}
                          />
                        }
                      </div>
                      {
                        <div className="cursor-pointer hover:border-opacity-100 transition ease-in-out">
                          {studentProfile && (
                            <AlienAvatar
                              studentId={props.userContext!.userId}
                              gradeId={
                                props.profile?.gradeId
                                  ? props.profile?.gradeId
                                  : 0
                              }
                              alienData={studentProfile.plAvatar}
                              showOnQuestions={
                                studentProfile.plShowAvatarOnQuestions
                              }
                              studentGradeMode="Kindergarten"
                              rewards={rewards}
                            />
                          )}
                        </div>
                      }
                    </div>
                  </div>

                  <div className="mt-3 space-y-1 hidden">
                    <span className="cursor-pointer inline-flex bg-gradient-to-b from-[#ff416c] to-[#ff4b2b] hover:from-[#ff416c] hover:to-[#d62000] transition ease-in-out duration-500 border-transparent  text-gray-900 inline-flex items-center px-3 rounded-md py-1.5 border-b-2 text-base font-medium shadow-lg w-full font-Didact">
                      <img
                        className="-ml-0.5 mr-2 h-4 w-4"
                        src={`${Configuration.S3bucketImagePath}/images/logout.svg`}
                        alt=""
                      />
                      Sign Out
                    </span>
                  </div>
                </div>
              </div>
            )}
          </nav>
        </div>
        {showParentConnect && (
          <ParentConnectPopup
            showPopUp={(show) => {
              setShowParentConnect(show);
            }}
          />
        )}
      </header>
      {/* {isOpneChat && <ChatRoom {...chatRoomProps}></ChatRoom>} */}
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setUserContext: (payload: userContextAction.UserContextState) => {
      dispatch(userContextAction.setUserContext(payload));
    },
    setProfile: (
      userId: number,
      roleId: number,
      schoolId: number,
      districtId: number
    ) => {
      dispatch(fetchProfile(userId, roleId, schoolId, districtId));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);

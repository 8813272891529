import { XIcon } from "@heroicons/react/outline";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { getAllGrades } from "../../../../../../api/gradeApi";
import { addStudents } from "../../../../../../api/teacher/assignment";
import { ClassApi } from "../../../../../../api/teacher/classManagementApi";
import PagingResponse from "../../../../../../model/common/pagingResponse";
import ClassStudentModel from "../../../../../../model/teacher/classManagement/classStudentModel";
import { UserContextState } from "../../../../../../redux/actions/userContextAction";
import {
  DEFAULT_PAGE_NUMBER,
  DEFAULT_PAGE_SIZE,
} from "../../../../../../utils/pagingConstant";
import ConfirmationDialog from "../../../../../shared/confirmationDialog";
import InfoMessage from "../../../../../shared/infoMessage";
import PageSize from "../../../../../shared/pagination/pageSize";
import Pagination from "../../../../../shared/pagination/pagination";
import ClassAssignment from "./classAssignment";
import { IClassStudent } from "../../../../../../model/interface/classStudent";
import { getNameLabel } from "../../../../../../utils/helper";
import Profile from "../../../../../../model/users/profile";
interface StudentPopupProps {
  showPopUp: (show: boolean) => void;
  classId: number;
  googleClassId: number;
  isGoogleEnabled: boolean;
  isCanvasEnabled: boolean;
  userContext?: UserContextState;
  profile?: Profile;
  refreshParent?: () => void;
  onlineUsers?: Array<any>;
}

const StudentPopup = (props: StudentPopupProps) => {
  const filterCharList = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
    "All",
  ];
  const defaultGrade = [{ gradeId: 0, name: "All" }];
  const [startWith, setStartWith] = useState<string>("All");
  const [studentName, setStudentName] = useState<string>("");
  const [gradeList, setGradeList] = useState<any>([]);
  const [pageNumber, setPageNumber] = useState(DEFAULT_PAGE_NUMBER);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [selectedGrade, setSelectedGrade] = useState<number>(0);
  const [students, setStudents] = useState<PagingResponse<ClassStudentModel>>();
  const [selectAll, setSelectAll] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [confirmationTitle, setConfirmationTitle] = useState("Confirm Action");
  const [confirmationMessage, setConfirmationMessage] =
    useState("Please confirm");
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [resetPageNumber, setResetPageNumber] = useState<boolean>(false);
  const [showAssignments, setShowAssignments] = useState<boolean>(false);
  const [selectedAssignments, setSelectedAssignments] = useState<Array<number>>(
    []
  );
  const [selectStudentToAdd, setSelectStudentToAdd] = useState<Array<number>>(
    []
  );
  const [assignmentStuds, setAssignmentStuds] = useState<Array<any>>([]);
  useEffect(() => {
    getGrades();
  }, []);

  useEffect(() => {
    getSchoolStudent();
  }, [pageNumber, pageSize]);

  useEffect(() => {
    // persist selectAll
    const studentIds = students?.data?.map((s) => s.userId) || [];
    const selectedStudent =
      selectStudentToAdd.length > 0 ? selectStudentToAdd[0] : 0;
    if (studentIds.includes(selectedStudent)) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  }, [students?.data]);

  function getGrades(): void {
    getAllGrades().then((d: any) => {
      setGradeList(defaultGrade.concat(d.data));
    });
  }
  function searchStudents(): void {
    setPageNumber(DEFAULT_PAGE_NUMBER);
    setPageSize(DEFAULT_PAGE_SIZE);
    getSchoolStudent();
  }

  function handleCharFilter(char: string) {
    if (char === "All") {
      setStudentName("");
    }
    setStartWith(char);
    getSchoolStudent(char);
  }

  function getSchoolStudent(charToSearch?: string): void {
    ClassApi.getClassPendingStudent(
      props.classId,
      pageNumber,
      pageSize,
      props.userContext?.schoolAccountId,
      charToSearch ? charToSearch : startWith,
      selectedGrade,
      charToSearch === "All" ? "" : studentName,
      props.userContext?.userId
    ).then((d) => {
      let students = d.data.data?.map((student, index) => {
        if (selectStudentToAdd.indexOf(student.userId) > -1) {
          student.isSelected = true;
        }
        return student;
      });
      d.data.data = students;
      setStudents(d.data);
    });
  }

  function selectStudent(userId: any) {
    let currentStudents: any = {};
    Object.assign(currentStudents, students);
    students?.data.map((student) => {
      if (student.userId === userId) {
        student.isSelected = !student.isSelected;
      }
      return null;
    });

    setStudents(currentStudents);

    setIsDisabled(getSelectedStudents().length === 0);

    setSelectAll(false);

    if (selectStudentToAdd.includes(userId)) {
      setSelectStudentToAdd(selectStudentToAdd.filter((ds) => ds !== userId));
    } else {
      const arr = selectStudentToAdd;
      arr.push(userId);
    }
  }

  function selectAllStudent(e, students: Array<any>): void {
    setSelectAll(!selectAll);
    if (e.target.checked) {
      let arr = students.map((s) => s.userId) || [];
      setSelectStudentToAdd(arr);
    }
  }

  function getSelectedStudents() {
    if (students?.data) {
      const selectedStudents = students?.data?.filter((e) => {
        return e.isSelected === true;
      });
      return selectedStudents.map((e) => e.userId);
    }
    return [];
  }

  // function selectedStudents() {
  //   if (students?.data) {
  //     const selectedStudents = students?.data?.filter((e) => {
  //       return e.isSelected === true;
  //     });

  //     return selectedStudents;
  //   }
  //   return [];
  // }

  function assignStudentsConfirm() {
    showConfirmation(
      "Please Confirm",
      "Are you sure you want to assign assignments for selected students"
    );
  }

  function handleSelectedAssignemnts(assignmentIds: Array<number>) {
    setSelectedAssignments(assignmentIds);
  }

  function handleStudentAssignments() {
    const assignments: Array<number> = selectedAssignments;
    const students: Array<number> = getSelectedStudents();
    let classStudents: Array<IClassStudent> = [];
    if (students.length > 0) {
      students.map((student) => {
        const classStudent: IClassStudent = {
          studentId: student,
          classId: props.classId,
        };
        classStudents.push(classStudent);
        return null;
      });
    }

    if (props.userContext && classStudents.length > 0) {
      addStudents(
        props.userContext?.userId,
        assignments,
        classStudents,
        props?.profile?.googleUserId!
      ).then(() => {
        toast.success("Students assigned successfully");
      });
    }
  }

  function addClassStudents() {
    showConfirmation(
      "Please Confirm",
      "Are you sure you want to add the selected students to the class?"
    );
  }

  function addClassStudentsConfirm() {
    //const selectedStudents = getSelectedStudents();
    ClassApi.addClassStudent(
      props.userContext?.userId,
      props.classId,
      selectStudentToAdd,
      props.googleClassId,
      props.isGoogleEnabled,
      props.isCanvasEnabled
    ).then(() => {
      confirmationCancelClose();
      setSelectAll(false);
      toast.success("Students added successfully");
      setSelectStudentToAdd([]);
      props.showPopUp(false);
      if (props.refreshParent !== undefined) props.refreshParent();
    });
  }

  function showConfirmation(title: string, message: string) {
    setOpenConfirmation(true);
    setConfirmationTitle(title);
    setConfirmationMessage(message);
  }

  function confirmationOkClose() {
    if (showAssignments) {
      handleStudentAssignments();
    }
    addClassStudentsConfirm();
  }

  function confirmationCancelClose() {
    setOpenConfirmation(false);
  }

  const onPageSizeChange = (pageSize) => {
    setPageNumber(DEFAULT_PAGE_NUMBER);
    setPageSize(pageSize);
    setResetPageNumber(true);
  };

  const removeStudent = (userId: number) => {
    const students = assignmentStuds.filter((s) => s.userId !== userId);
    setAssignmentStuds(students);
  };

  const showAssignment = async () => {
    if (!showAssignments) {
      const resp = await ClassApi.getClassPendingStudent(
        props.classId,
        1,
        99999,
        props.userContext?.schoolAccountId,
        startWith,
        selectedGrade,
        studentName,
        props.userContext?.userId
      );

      let obj: any = [];

      resp?.data?.data?.map((data) => {
        if (selectStudentToAdd.includes(data.userId)) {
          obj.push(data);
        }
        return null;
      });

      setAssignmentStuds(obj);
      setTimeout(() => {
        setShowAssignments(true);
      });
    } else {
      setShowAssignments(false);
    }
  };

  return (
    <div className="fixed inset-0 z-40 flex items-center bg-black bg-opacity-50 sm:items-center sm:justify-center">
      <div
        className="w-full flex flex-col justify-between overflow-hidden h-auto max-h-80percent bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:max-w-5xl"
        role="dialog"
      >
        <header>
          {/* Header */}
          <div className="px-4 py-4 bg-gray-50">
            <div className="flex items-start justify-between space-x-3">
              <div className="space-y-1">
                <div className="text-lg font-medium text-gray-900">
                  Available Students
                </div>
              </div>
              <div className="h-7 flex items-center">
                <button
                  type="button"
                  className="text-gray-400 hover:text-gray-500"
                  onClick={() => props.showPopUp(false)}
                >
                  <span className="sr-only">Close panel</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </header>

        {showAssignments && (
          <div className="w-full relative overflow-auto px-4 py-3">
            <ClassAssignment
              userId={props.userContext ? props.userContext.userId : 0}
              roleId={props.userContext ? props.userContext?.roleId : 0}
              classId={props.classId}
              students={assignmentStuds}
              selectAssignments={handleSelectedAssignemnts}
              removeStudent={removeStudent}
              onlineUsers={props.onlineUsers}
              schoolYearId={props.profile?.currentSchoolYear?.schoolYearId}
            ></ClassAssignment>
          </div>
        )}

        <div
          className={`w-full relative overflow-auto px-4 py-3 ${
            showAssignments ? "hidden" : ""
          }`}
        >
          <div className="p-1">
            <InfoMessage
              message="Select the students you would like to include in your class. You can filter by last name by selecting a letter, by grade or by entering a student name directly.
                       Once a student has been selected, they will remain so as you continue to filter for additional students."
            />
          </div>

          {/* <!-- Divider container -->  */}
          <div className="p-3 sm:py-0 sm:space-y-0 sm:divide-y sm:divide-gray-200 text-sm text-gray-700 dark:text-gray-400">
            <div>
              <div className="mb-4">
                <h1 className="font-bold text-primary-violet mb-2">
                  Available Students
                </h1>
                <ul className="flex flex-wrap gap-2 sm:gap-1 capitalize items-center cursor-pointer">
                  {filterCharList.map((ch) => {
                    if (ch === "All")
                      return (
                        <li
                          key={ch}
                          className={
                            startWith === ch
                              ? "bg-secondary-teal flex items-center justify-center w-10 h-5 text-sm text-white hover:opacity-70"
                              : "flex items-center justify-center bg-primary-blue w-10 h-5 text-sm text-white hover:opacity-70"
                          }
                          onClick={() => {
                            handleCharFilter(ch);
                          }}
                        >
                          {ch}
                        </li>
                      );
                    else
                      return (
                        <li
                          key={ch}
                          className={
                            startWith === ch
                              ? "bg-secondary-teal flex items-center justify-center w-5 h-5 text-sm text-white hover:opacity-70"
                              : "flex items-center justify-center bg-primary-blue w-5 h-5 text-sm text-white hover:opacity-70"
                          }
                          onClick={() => {
                            handleCharFilter(ch);
                          }}
                        >
                          {ch}
                        </li>
                      );
                  })}
                </ul>

                <div className="grid grid-cols-12 gap-6 flex items-end mt-3">
                  <div className="col-span-12 sm:col-span-4 xl:col-span-3">
                    <label className="block text-sm text-gray-500">Grade</label>
                    <select
                      className="mt-1 py-1.5 px-3 block w-full shadow-sm focus:ring-primary-violet focus:border-primary-violet border border-gray-300 rounded-md"
                      onChange={(e) => {
                        setSelectedGrade(Number.parseInt(e.target.value));
                      }}
                    >
                      {gradeList.map((g) => {
                        return (
                          <option key={g.gradeId} value={g.gradeId}>
                            {g.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="col-span-12 sm:col-span-4 xl:col-span-3">
                    <label className="block text-sm text-gray-500">
                      Name / Student id
                    </label>
                    <input
                      type="text"
                      className="mt-1 py-1.5 px-3 block w-full shadow-sm focus:ring-primary-violet focus:border-primary-violet border border-gray-300 rounded-md"
                      value={studentName}
                      onChange={(e) => {
                        setStudentName(e.target.value);
                      }}
                    />
                  </div>
                  <div className="col-span-12 sm:col-span-4 xl:col-span-3">
                    <button
                      id="searchStudent"
                      type="button"
                      onClick={searchStudents}
                      className="bg-primary-violet border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-dark-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      Search
                    </button>
                  </div>
                </div>
              </div>
              <div className="overflow-x-auto shadow">
                <table className="table w-full">
                  <thead className="mb-5  w-full">
                    <tr className="bg-light-violet  text-white py-3 px-3 overflow-hidden">
                      <th className="text-left  p-3 text-sm font-normal w-4">
                        <label
                          x-radio-group-option=""
                          className="relative cursor-pointer"
                        >
                          <input
                            type="checkbox"
                            x-model="value"
                            name="privacy_setting"
                            value="Private to Project Members"
                            className="h-4 w-4 mt-0.5 cursor-pointer text-primary-violet border-gray-300 focus:ring-primary-violet"
                            aria-labelledby="privacy-setting-1-label"
                            aria-describedby="privacy-setting-1-description"
                            checked={selectAll}
                            onChange={(e) => {
                              selectAllStudent(e, students?.data!);
                            }}
                          />
                        </label>
                      </th>
                      <th className="text-left  p-3 text-sm font-normal">
                        {" "}
                        Name{" "}
                      </th>
                      <th className="text-left  p-3 text-sm font-normal">
                        {" "}
                        Username{" "}
                      </th>
                      <th className="text-center  p-3 text-sm font-normal">
                        {" "}
                        Student Id{" "}
                      </th>
                      <th className="text-center  p-3 text-sm font-normal">
                        {" "}
                        Grade{" "}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {students?.data?.map((student, index) => {
                      return (
                        <tr
                          key={student.userId}
                          className={`hover:bg-light-violet/20 transition ease-in-out duration-300 ${
                            index % 2 === 0 ? "bg-white" : "bg-light-violet/10"
                          }`}
                        >
                          <td className=" mt-5 p-3 text-center">
                            <input
                              type="checkbox"
                              className="h-4 w-4 cursor-pointer text-primary-violet border-gray-300 focus:ring-primary-violet"
                              key={student.userId}
                              checked={selectStudentToAdd.includes(
                                student.userId
                              )}
                              onChange={() => {
                                selectStudent(student.userId);
                              }}
                            />
                          </td>
                          <td className="text-sm mt-5  p-3">
                            {getNameLabel(student.lastName, student.firstName)}
                          </td>
                          <td className="text-sm mt-5  p-3">
                            {student.username}
                          </td>
                          <td className="text-sm text-center mt-5  p-3">
                            {student.studentId}
                          </td>
                          <td className="text-sm text-center mt-5  p-3">
                            {student.grade}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between mt-3">
                {students?.totalRecords !== undefined &&
                  students?.totalRecords !== 0 && (
                    <Pagination
                      totalRecords={students?.totalRecords}
                      pageSize={pageSize}
                      onPageChange={(e) => {
                        setPageNumber(e);
                      }}
                      reset={resetPageNumber}
                      pageNumber={pageNumber}
                    />
                  )}
                {students?.totalRecords !== 0 && (
                  <PageSize
                    pageSize={pageSize}
                    onPageSizeChange={onPageSizeChange}
                  />
                )}
                {students?.totalRecords === 0 && (
                  <span className="text-color-black">No record found</span>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Action Buttons -->  */}
        <footer className="flex flex-col items-center justify-end px-6 py-4 sm:flex-row bg-gray-50 dark:bg-gray-800">
          <div className="flex-shrink-0">
            <div className="space-x-3 flex justify-end">
              {showAssignments && (
                <button
                  className="disabled:opacity-50 align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 rounded-lg text-sm text-white border border-transparent bg-primary-green focus:shadow-outline-purple w-full sm:w-auto"
                  type="button"
                  onClick={() => setShowAssignments(false)}
                >
                  {" "}
                  Back
                </button>
              )}
              {showAssignments && (
                <button
                  className="disabled:opacity-50 align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 rounded-lg text-sm text-white border border-transparent bg-primary-green focus:shadow-outline-purple w-full sm:w-auto"
                  disabled={
                    (selectedAssignments.length > 0 &&
                      selectStudentToAdd.length > 0) === false
                  }
                  type="button"
                  onClick={assignStudentsConfirm}
                >
                  {" "}
                  Assign
                </button>
              )}

              {!showAssignments && (
                <button
                  className="disabled:opacity-50 align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 rounded-lg text-sm text-white border border-transparent bg-primary-green focus:shadow-outline-purple w-full sm:w-auto"
                  disabled={selectStudentToAdd.length === 0}
                  type="button"
                  onClick={addClassStudents}
                >
                  {" "}
                  Add Students
                </button>
              )}

              {!showAssignments && (
                <button
                  className="disabled:opacity-50 align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 rounded-lg text-sm text-white border border-transparent bg-primary-blue focus:shadow-outline-purple w-full sm:w-auto"
                  disabled={isDisabled}
                  type="button"
                  onClick={() => showAssignment()}
                >
                  {" "}
                  Add and Assign Assignments
                </button>
              )}
              <button
                className="align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 rounded-lg text-sm text-gray-600 border-gray-300 border dark:text-gray-400 focus:outline-none active:bg-transparent hover:border-gray-500 focus:border-gray-500 active:text-gray-500 focus:shadow-outline-gray w-full sm:w-auto"
                type="button"
                onClick={() => {
                  props.showPopUp(false);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </footer>
      </div>
      <ConfirmationDialog
        open={openConfirmation}
        confirmationTitle={confirmationTitle}
        confirmationMessage={confirmationMessage}
        onCancelClick={confirmationCancelClose}
        onOkClick={confirmationOkClose}
        className="fixed z-50 inset-0 overflow-y-auto"
      ></ConfirmationDialog>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(StudentPopup);

import { useState, useEffect } from "react";
import Select, { MultiValue } from "react-select";
import { getQuestionTypesByStandard } from "../../../../api/teacher/questionType";
import { ISelectElement } from "../../../../model/interface/selectElement";

export default function QuestionTypeForPassageSelect(props: any) {
  const [items, setItems] = useState<any[]>();
  const [value, setValue] = useState<Array<ISelectElement>>();

  const mapOptions: any = (data) => {
    const options: Array<ISelectElement> = [
      { label: "All Questions", value: "0" },
    ];

    data?.map((item) => {
      const selectElement: ISelectElement = {
        label: item.value,
        value: item.key,
      };
      options.push(selectElement);
      return null;
    });

    return options;
  };

  const handleOnChange = (options: MultiValue<ISelectElement> | null) => {
    if(!options) return;

    let selectedValues: Array<ISelectElement> = options.map(option => option);
    if (selectedValues && selectedValues.length > 0) {
      const lastSelectedValue = selectedValues[selectedValues.length - 1];
      if (lastSelectedValue.value === "0") {
        selectedValues = [lastSelectedValue];
      } else if (
        selectedValues.some((r) => r.value === "0") &&
        selectedValues.some((r) => r.value !== "0")
      ) {
        selectedValues = selectedValues.filter((r) => r.value !== "0");
      }
    }

    setValue(selectedValues);
    if (typeof props.onChange === "function") props.onChange(selectedValues);
  };

  useEffect(() => {
    getQuestionTypesByStandard(props.standardIds, true, props.includeConstructedReponseTypes).then((resp) => {
      let options: Array<ISelectElement> = [];

      if (resp.data.length > 0) {
        options = mapOptions(resp.data);
      }
      setItems(options);
      setValue(props.value);
    });
  }, [props.standardIds]);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  return (
    <Select
      options={items}
      placeholder={""}
      onChange={handleOnChange}
      isMulti={true}
      value={value}
      isDisabled={props.isEdit ? true : props.languages === "ES" ? true : false}
      isSearchable={true}
      closeMenuOnSelect={false}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary25: "#B3B3B3",
          primary: "#041E42",
        },
      })}
      styles={{
        control: (base, state) => ({
          ...base,
          background:
            document.getElementsByClassName("dark")?.length > 0
              ? "#1A1C23"
              : "white",
          borderColor:
            document.getElementsByClassName("dark")?.length > 0
              ? "#6B7280"
              : "#D1D5DB",
          borderWidth: "1px",
          "&:hover": {
            borderColor:
              state.isFocused &&
              document.getElementsByClassName("dark")?.length > 0
                ? "#6b7280"
                : state.isFocused
                ? "#4c5563"
                : "",
          },
        }),
        singleValue: (base) => ({
          ...base,
          color:
            document.getElementsByClassName("dark")?.length > 0
              ? "white"
              : "#374051",
        }),
        input: (base) => ({
          ...base,
          color:
            document.getElementsByClassName("dark")?.length > 0 ? "white" : "",
        }),
      }}
    />
  );
}

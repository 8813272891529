import { useCallback, useEffect, useRef, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { ContentState, EditorState, convertToRaw } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {  
  IScoreUpdate,
} from "../../../model/teacher/assignment/responseScore";
import { updateStudentResponseScores } from "../../../api/student/studentApi";
import Loader from "../../shared/loader";
import {   
  getQuestionsBySessionId,  
  getTeacherFeedbacksBySessionId,
 } from "../../../api/student/studentActivityApi";
import { toast } from "react-toastify";
import { getQuestionExplanations } from "../../../api/student/question/explanation";
import { getRequest } from "../../../api/userActivity";
import { IActivitySessionQuestion } from "../../../model/interface/activitySession/activitySessionQuestion";
import { GradeCRQuestionProps } from './gradeCRQuestion'
import { AssessmentType } from "../../../model/common/AssessmentType";
import { IActivitySessionQuestionFeedback } from "../../../model/interface/activitySession/activitySessionQuestionFeedback";
import {  scrollToElementById } from "../../../utils/helper";

interface IGradeAssesmentWithCRProps extends GradeCRQuestionProps {
  studentStatus: string;
  attemptNumber: number;
  onlyView: boolean;
}

export default function GradeAssesmentWithCR(props: IGradeAssesmentWithCRProps) {
  
  
  const [sessionId, setSessionId] = useState<string>(props.sessionId ?? "");
  const [userId, setUserId] = useState<string>(props.user_id ?? "");  
  const [studentAssignmentActivityId, setStudentAssignmentActivityId] =
    useState<number>(props.studentAssignmentActivityId ?? 0);
  const [currentstudent, setCurrentStudent] = useState<any>();
  const [loading, showLoading] = useState<boolean>(false);
  const [allQuestions, setAllQuestions] = useState<IActivitySessionQuestion[]>([]);
  const [alreadyGradedStudents, setAlreadyGradedStudents] = useState<number[]>([]);
  const [studentAssessmentActivityId, setStudentAssessmentActivityId] =
    useState<number>(props.studentAssessmentActivityId ?? 0);

  const [feedbacks, setFeedbacks] = useState<{ [questionId: number]: { isFeedbackLoaded: boolean; feedback: string; } }>({});
  const [editorStates, setEditorStates] = useState<{ [questionId: number]: EditorState }>({});
  const [questionScores, setQuestionScores] = useState<{ [questionId: number]: number | null }>({});
  const [responseMappings, setResponseMappings] = useState<{ [itemReference: string]: { responseId: string; sheetReference: string; } }>({});
  const [textExplanations, setTextExplanations] = useState<{ [questionId: string]: string }>({});
  const [questionsFetched, setQuestionsFetched] = useState(false);
  const isInitialized = useRef(false);


     
  const initialization = useCallback(() => {
    
    if (isInitialized.current) return;
      
      isInitialized.current = true;
    var itemsApp;
    const items = allQuestions.filter(q => q.isConstructedResponse).map(q => q.itemReference);  
    const request = {
      user_id: userId.toString(),
      rendering_type: "inline",
      name: "Items API Grade",
      state: "review",      
      activity_id: props.studentAssignmentActivityId.toString(),
      session_id: sessionId.toString(),
      items: items,
      type: "submit_practice",
      config: {
        showCorrectAnswers: true,
        showInstructorStimulus: true,
        fontsize: "normal",
        renderSubmitButton: false,
        questions_api_init_options: {
          beta_flags: {
            reactive_views: true,
          },
        },
      },
    };
    
    var callbacks = {
      readyListener: function () {      
        const questionsFromItemsApp = itemsApp.getQuestions();
        const newMappings = {};
        for (const key in questionsFromItemsApp) {
          const question = questionsFromItemsApp[key];
          newMappings[question.metadata.sheet_reference] = {
            responseId: question.response_id,
            sheetReference: question.metadata.sheet_reference,
          };
        }
        setResponseMappings(newMappings);
        showLoading(false);
      },
      errorListener: function (e) {        
        console.error("Error: ", e);        
      }
    };

    showLoading(true);
    getRequest("items", JSON.stringify(request)).then((response) => {
      if (response.data) {        
        itemsApp = window["LearnosityItems"]?.init(
          response.data,
          callbacks
        );        
      }
    });

  }, [sessionId, userId, questionsFetched, allQuestions]);

  // Trigger initialization when questions are fetched
  useEffect(() => {
    if (questionsFetched && ((currentstudent &&
        sessionId === currentstudent.learnositySessionId &&
        userId === currentstudent.userId) || !currentstudent)) {
          initialization();
    }
  }, [sessionId, userId, currentstudent, questionsFetched, allQuestions]);
  
  // Sets the current student
  useEffect(() => {      

    let currentIndex = 0;
    // Try to find student by studentAssignmentActivityId
    if (studentAssignmentActivityId > 0) {        
        currentIndex = props.studentList?.findIndex(
          (element) =>
            element.studentAssignmentActivityId === studentAssignmentActivityId
            && element.attemptNumber === props.attemptNumber
        );
    } 
    else if (studentAssessmentActivityId > 0) {  // Otherwise, try to find student by studentAssessmentActivityId
      currentIndex = props.studentList?.findIndex(
        (element) =>
          element.studentAssessmentActivityId === studentAssessmentActivityId
          && element.attemptNumber === props.attemptNumber
      );      
    }
    
    var currentStudent = props.studentList[currentIndex];
    setCurrentStudent(currentStudent);

  }, [studentAssignmentActivityId, props.attemptNumber]);
  
  // Get question explanations
  useEffect(() => {
    // Extract item references from responseMappings
    const itemReferences = Object.keys(responseMappings);
    
    if (itemReferences.length > 0 && !props.onlyView) {

      // Call the updated getQuestionExplanations function
      getQuestionExplanations(itemReferences, "en").then(res => {
        if (res.data) {
          
          const explanationsByQuestionId = res.data.reduce((acc, explanation) => {
            
            // Find questionId using responseMappings and questionRefId
            const referenceId = Object.entries(responseMappings)
              .find(([_, value]) => value.sheetReference === explanation.englishQuestionReferenceId)?.[1]?.sheetReference;              
            if (referenceId) {
              acc[referenceId] = explanation.textExplanation;
            }
            return acc;
          }, {});

          setTextExplanations(explanationsByQuestionId);
        }
      });
    }
  }, [props.onlyView, responseMappings]);
  
  // Gets ans sets the feebacks
  useEffect(() => {
    if (allQuestions.length > 0) {      
      
      const fetchAndSetFeedbacks = async () => {

        const response = await getTeacherFeedbacksBySessionId(sessionId);
        const feedbackArray = response.data;
        const newFeedbacks = {};
        const newEditorStates = {};
        
        // Iterate over the feedback array to populate the states
        feedbackArray.forEach(feedbackObj => {
          const { questionId, teacherFeedback } = feedbackObj;
          newFeedbacks[questionId] = { isFeedbackLoaded: true, feedback: teacherFeedback };
          newEditorStates[questionId] = EditorState.createWithContent(ContentState.createFromText(teacherFeedback));
        });

        setFeedbacks(newFeedbacks);
        setEditorStates(newEditorStates);
      }

      fetchAndSetFeedbacks();

      const newQuestionScores = {};

      allQuestions.forEach(question => {
        newQuestionScores[question.questionId] = question.pointsEarned;
      });

      setQuestionScores(newQuestionScores);

    }
  }, [allQuestions]);
  
  // When the list of already graded students changes, move to the next student
  useEffect(() => {
    if (alreadyGradedStudents.length > 0 && currentstudent) {
      nextStudent();      
    }
  }, [alreadyGradedStudents]);

  // Fetch questions when sessionId changes
  useEffect(() => {    
    if (sessionId) {
      getQuestionsDb();
    }
  }, [sessionId]); // Runs whenever sessionId changes
  
  function textToSingleLine(text) {
    var singleLine = "";
    text.forEach((element) => {
      singleLine += element.text + "\n";
    });
    return singleLine.endsWith("\n")
      ? singleLine.substring(0, singleLine.length - 1)
      : singleLine;
  }

  const updateScore = (questionId: number, score: number | null, maxScore: number) => {
    if (score === null || validateScore(score, maxScore)) {      
      setQuestionScores(prevScores => ({
        ...prevScores,
        [questionId]: score,
      }));
    }
  };
  
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, questionId: number, maxScore: number) => {    
    const value = e.target.value;
    // Allow only numeric values or an empty string
    if (value === "" || /^[0-9\b]+$/.test(value)) {
      const score = value === "" ? null : parseInt(value, 10);      
      updateScore(questionId, score, maxScore);
    }
  };
  
  function validateScore(score, maxScore) {
    const re = /^[0-9\b]+$/;
    var el = document.getElementById("gradeError");
    if (re.test(score) && score <= maxScore) {
      if (el) el.style.display = "none";
      return true;
    } else {
      if (el) el.style.display = "block";
      return false;
    }
  }

  const validateScores = (
    questionScores: { [questionId: number]: number | null },
    allQuestions: IActivitySessionQuestion[]
  ): boolean => {
    let isValid = true;
  
    // Hide error initially
    var el = document.getElementById("gradeError");
    if (el) el.style.display = "none";
  
    for (const question of allQuestions) {
      const score = questionScores[question.questionId];
      const maxScore = question.pointsPossible;
  
      // Check if score is null or if it's a valid number within range
      if (score === null || (typeof score === 'number' && score <= maxScore)) {
        // Score is either not set or is a valid number; continue checking next scores
        continue;
      } else {
        // Invalid score found: either not a number or exceeds maxScore
        isValid = false;
        if (el) el.style.display = "block";
        break; // Exit loop after finding the first invalid score
      }
    }
  
    return isValid;
  };
  
  async function submitGrades() {

    showLoading(true);

    // Construct feedback updates for each question
    const feedbackUpdates: IActivitySessionQuestionFeedback[] = allQuestions.map(question => ({
      activitySessionId: studentAssignmentActivityId, // Assuming this is available in your component
      questionId: question.questionId,
      teacherFeedback: feedbacks[question.questionId]?.feedback || "",
    }));

    // Construct score updates for each question based on the stored scores
    const scoreUpdates: IScoreUpdate[] = allQuestions.filter(x => questionScores[x.questionId] !== null).map(question => {
      const mapping = responseMappings[question.itemReference];
      return {
        // Your existing logic for constructing IScoreUpdate objects
        responseScore: {
          sessions: [{
            sessionId: sessionId.toString(),
            userId: userId.toString(),
            responses: [{
              responseId: mapping?.responseId ?? "", // Use mapped responseId
              score: questionScores[question.questionId] ?? 0,
              attempted: true,
              maxScore: question.pointsPossible,
            }]
          }]
        },
        score: questionScores[question.questionId] ?? 0,
        maxPoints: question.pointsPossible,
        isTeacherQuestion: mapping?.sheetReference.includes("teacher"),
        questionId: question.questionId,
        assessmentType: AssessmentType.AssessmentWithConstructedResponse      
      };
    });

    // Now, use the new `updateStudentResponseScores` function to submit all scores
    let res = await updateStudentResponseScores(
      feedbackUpdates, // Ensure this is correctly gathered for each question
      studentAssignmentActivityId,
      scoreUpdates, // Updated to send an array of score updates
    );
    
    const isValidScore = validateScores(questionScores, allQuestions);
    if (isValidScore) {
      scrollToElementById("crTopElement");
      
      if (res.data === 1) {        
       
        // Update the list of already graded students          
        setAlreadyGradedStudents(prev => [...prev, currentstudent.userId]);          
        props.refreshData();
        //setEditorState(EditorState.createEmpty());        
        
      } else {
        toast.info("Grades could not be updated.");
      }
    }
  }
  
  function nextStudent() {
    
    // Filter students by current attempt
    const studentsByAttempt = props.studentList
        .filter(s => s.attemptNumber === props.attemptNumber)
        .sort((a, b) => a.userName.localeCompare(b.userName)); // Sort alphabetically by name

    const currentStudentIndex = studentsByAttempt.findIndex(
      (student) => student.userId === currentstudent.userId
    );

    const nextStudentIndex = currentStudentIndex + 1;

    // Proceed with the next student
    if (nextStudentIndex < studentsByAttempt.length) {
      const nextStudent = studentsByAttempt[nextStudentIndex];
      setCurrentStudent(nextStudent);
      setStudentAssignmentActivityId(nextStudent.studentAssignmentActivityId);
      setStudentAssessmentActivityId(nextStudent.studentAssessmentActivityId);
      setQuestionsFetched(false);
      setSessionId(nextStudent.learnositySessionId);
      setUserId(nextStudent.userId);
      isInitialized.current = false;
      toast.info("Student Successfully Graded. Moving to next student");
    } else {
      goToList(); // Go back to list if there's no next student
    }
  }

  function goToList() {
    props.setShowCRReport(true);
  }

  async function getQuestionsDb(): Promise<IActivitySessionQuestion[]> {
    const response = await getQuestionsBySessionId(sessionId);  

    // Filter to have just constructed response questions
    const constructedResponseQuestions = response.data.filter(q => q.isConstructedResponse);
    setAllQuestions(constructedResponseQuestions); // Store all questions in state        
    setQuestionsFetched(true);
    return response.data;
  }

  

  const handleFeedbackChange = (questionId: number, text: string) => {
    setFeedbacks(prevFeedbacks => ({
      ...prevFeedbacks,
      [questionId]: { isFeedbackLoaded: true, feedback: text }
    }));    
  };
  
  const onEditorStateChange = (questionId: number, newEditorState: EditorState) => {
    const newEditorStates = { ...editorStates, [questionId]: newEditorState };
    setEditorStates(newEditorStates);
  
    // Convert editor state to raw content, then to a single line of text
    const blocks = convertToRaw(newEditorState.getCurrentContent()).blocks;
    const feedbackText = textToSingleLine(blocks);
  
    // Update feedback using the questionId
    handleFeedbackChange(questionId, feedbackText);
  };
  
  return (
    <div id="crTopElement" className="min-w-0 block lg:col-span-9 xl:col-span-10 w-full">
      {loading && <Loader />}
      <div className="w-full mb-5 rounded-lg sm:px-2 lg:px-2 mt-5 h-full">
        <div className="mt-4 mb-4">
          <label className="font-medium rounded-lg text-gray-700 mr-1.5">
            Student Name:
          </label>
          {currentstudent?.userName}
        </div>

        {questionsFetched && (
        
          allQuestions.map((question, index) => {

            const explanation = textExplanations[question.itemReference];      
            return (
              <div className="crGradeWrapper main-content w-full bg-gray-50 rounded-lg border border-gray-200 shadow-lg px-6 mb-12">
                <h3 className="text-lg font-semibold mb-4">
                  {`Item ${index + 1}`}
                </h3>            
                <span key={index} className="learnosity-item" data-reference={question.itemReference}></span>
                {!props.onlyView && (
                  <div className="flex flex-col sm:flex-row space-y-8 sm:space-y-0 justify-between mb-5">
                    <div className="bg-gray-50 border border-gray-200 rounded-lg shadow-lg px-6 py-6 w-full lg:w-1/2 xl:w-2/3 xxxl:w-1/2">
                      <div className="mb-4">
                        <span>Enter grade: </span>
                        <input
                          type="text"
                          id={"score-" + question.questionId}                          
                          max={question.pointsPossible}
                          value={questionScores[question.questionId] ?? ""}
                          onChange={(e) => handleInputChange(e, question.questionId, question.pointsPossible)}
                          className="mx-2"
                        />
        
                        /{question.pointsPossible}
                      </div>
                      <div
                        id="gradeError"
                        className="hidden bg-red-50 p-3 text-red-700 font-medium mb-2 rounded-md"
                      >
                        Invalid Score
                      </div>
                      <span>Type your feedback below</span>
                        
                      <div className="w-full mt-2">
                        {(feedbacks[question.questionId]?.isFeedbackLoaded || feedbacks[question.questionId] == null) && (
                          <Editor
                            key={question.questionId}
                            wrapperClassName="texteditor-wrapper"
                            editorClassName="bg-white demo-editor min-h-[12rem] max-h-auto overflow-hidden"
                            defaultContentState={convertToRaw(
                              ContentState.createFromText(
                                feedbacks[question.questionId]?.feedback ? feedbacks[question.questionId]?.feedback : ""
                              )
                            )}
                            value={feedbacks[question.questionId]?.feedback}
                            editorState={editorStates[question.questionId] || EditorState.createEmpty()}
                            onEditorStateChange={(newEditorState) => onEditorStateChange(question.questionId, newEditorState)}
                          />
                        )}
                      </div>
                    </div>
                    {explanation && (
                      <div
                      className="bg-gray-50 rounded-lg border border-gray-200 shadow-lg px-6 py-6 w-full lg:w-1/2 xl:1/3 xxxl:w-1/2"
                      dangerouslySetInnerHTML={{ __html: explanation }}
                    ></div>
                    )}
                  </div>
                )}
              </div>
            );
          })
        )}       

        <div className="w-full mb-5 rounded-lg sm:px-6 lg:px-8 mt-5 h-full">
          <div className="space-x-2 flex mt-8 items-end col-span-2 justify-end pb-4">
            <button
              className="bg-secondary-teal border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-dark-teal hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              type="button"
              onClick={(e) => {
                goToList();
              }}
            >
              {" "}
              Go To List
            </button>

            {!props.onlyView && (
              <button
                className="bg-secondary-teal border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-dark-teal hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                type="button"
                onClick={(e) => {
                  submitGrades();
                }}
              >
                {" "}
                Submit Grade / Next Student
            </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { Fragment } from "react";
import { Configuration } from "../../environment/setup";
import RouteConstant from "../../utils/constant/routeConstant";
import { useHistory } from "react-router-dom";
import {
  MailIcon,
  VideoCameraIcon,
  QuestionMarkCircleIcon,
  PhoneIcon,
} from "@heroicons/react/solid";

interface passedProps {
  title: string;
  description: string;
  css: string;
  url: string;
}

function HelpCard(props: passedProps) {
  const history = useHistory();
  function naviagateToPage(url: string, title: string) {
    const navigateUrl = url.replace("{{baseUrl}}", Configuration.EGBaseUrl);
    const videonavigateUrl = `${Configuration.EGBaseUrl}quickguide/gettingstarted.aspx?dr=true`;
    switch (title) {
      case "Contact Us":
        history.push({
          pathname: RouteConstant.CONTACTUS,
        });
        break;
      case "Parent Letters":
        history.push({
          pathname: RouteConstant.PARENTLETTER,
        });
        break;
      case "Help Center":
        window.open(navigateUrl, "_blank");
        break;
      case "Help Videos":
        history.push({
          pathname: RouteConstant.CONTAINER,
          search: "",
          state: { url: videonavigateUrl },
        });
        break;
      default:
        history.push({
          pathname: RouteConstant.CONTAINER,
          search: "",
          state: { url: navigateUrl },
        });
        break;
    }
  }

  function getIcons(option: string) {
    switch (option.trim()) {
      case "Contact Us":
        return <PhoneIcon className="fill-current h-6 text-white" />;
      case "Parent Letters":
        return <MailIcon className="fill-current h-6 text-white" />;
      case "Help Center":
        return (
          <QuestionMarkCircleIcon className="fill-current h-6 text-white" />
        );
      case "Help Videos":
        return <VideoCameraIcon className="fill-current h-6 text-white" />;
    }
  }

  return (
    <Fragment>
      <div className="relative text-white pt-5 px-4 pb-2 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden group">
        <dt>
          <div className={`absolute ${props.css} rounded-md p-3`}>
            {getIcons(props.title)}
          </div>
        </dt>
        <dd className="mb-10 ml-16 pb-2 flex items-baseline sm:pb- flex-col">
          <p className="text-base font-semibold text-gray-900">{props.title}</p>
          <p className="text-xs text-gray-900 ">{props.description}</p>
        </dd>
        <div
          onClick={() => naviagateToPage(props.url, props.title)}
          className={`cursor-pointer transaction duration-300 ease-in-out absolute bottom-0 inset-x-0 bg-gray-50 px-4 py-4 sm:px-6 group-hover:text-white group-hover:${props.css}`}
        >
          <div className="text-sm">
            <a
              href=";"
              className="font-medium text-gray-900 group-hover:text-white"
            >
              {" "}
              Manage<span className="sr-only"></span>
            </a>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default HelpCard;

import { Fragment, useEffect, useState } from "react";
import { handleLogin } from "../../../utils/googleHelper";
import { toastr } from "react-redux-toastr";
import constant from "../../../utils/constant/constant";

interface GoogleSignInModalProps {
  roleId: number;
  returnUrl: string;
  mode: string;
  showPopUp: (show: boolean) => void;
}

const messages = {
  SSO: "Your account is linked with Google Classroom. In order to utilize Google Classroom, you need to login with your Google account.",
  MAPPING:
    "Your account is not linked with Google Classroom. In order to utilize Google Classroom, you need to map your account to your Google account.",
  AUTHORIZE:
    "Sorry, you don't have the necessary permissions to utilize Google Classroom, you need to sign in and allow access to the required services.",
};

function GoogleSignInModal(props: GoogleSignInModalProps) {
  const { mode } = props;
  const [message] = useState<string>(messages[mode]);

  function redirectToGoogleClassroomLogin() {
    if (props.roleId === constant.UserRoleId.Student) {
      handleLogin("Student", props.returnUrl, true);
    } else {
      handleLogin("Teacher", props.returnUrl, true);
    }
  }

  function closeGoogleModal() {
    window.localStorage.setItem("showGoogleSignInPopup", "false");
    props.showPopUp(false);
  }

  function setToastr() {
    const toastrOptions = {
      timeOut: 0,
      showCloseButton: true,
      closeOnToastrClick: true,
      position: "top-center",
      onCloseButtonClick: () => closeGoogleModal(),
      component: (
        <Fragment>
          <div>
            <div>
              <div className="px-4 pb-4 pt-0">
                <div className="toastr-left-container"></div>
                <div className="toastr-middle-container">
                  <div className="flex space-x-2 items-start">
                    <div className="w-20 pt-0.5">
                      <img
                        className="w-6"
                        src="https://cdn.worldvectorlogo.com/logos/google-classroom.svg"
                        alt="google classroom"
                      />
                    </div>
                    <div>
                      <div className="title text-left text-sm">{message}</div>
                      <div className=" mt-6 inline-block flex items-center justify-between">
                        {mode === constant.GoogleSignInMode.SSO && (
                          <span
                            onClick={() => closeGoogleModal()}
                            data-dismiss="modal"
                            className="cursor-pointer text-sm text-blue-800 hover:text-blue-800 block pr-4 focus:outline-none pr-10"
                          >
                            Continue without Google
                          </span>
                        )}
                        <div
                          className="focus:outline-none"
                          onClick={() => redirectToGoogleClassroomLogin()}
                        >
                          <div className=" inline-flex space-x-2 bg-blue-600 text-white border border-blue-600 pr-4">
                            <div className="abcRioButtonIcon p-2 bg-white">
                              <div className="abcRioButtonSvgImageWithFallback abcRioButtonIconImage abcRioButtonIconImage18">
                                <svg
                                  version="1.1"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="18px"
                                  height="18px"
                                  viewBox="0 0 48 48"
                                  className="abcRioButtonSvg"
                                >
                                  <g>
                                    <path
                                      fill="#EA4335"
                                      d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"
                                    ></path>
                                    <path
                                      fill="#4285F4"
                                      d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"
                                    ></path>
                                    <path
                                      fill="#FBBC05"
                                      d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"
                                    ></path>
                                    <path
                                      fill="#34A853"
                                      d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"
                                    ></path>
                                    <path fill="none" d="M0 0h48v48H0z"></path>
                                  </g>
                                </svg>
                              </div>
                            </div>
                            <span className="abcRioButtonContents text-xs flex items-center">
                              <span id="not_signed_int8aitmh8ix7c ml-4">
                                Sign in with Google
                              </span>
                              <span id="connectedt8aitmh8ix7c"></span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      ),
    };

    toastr.message(
      mode === constant.GoogleSignInMode.AUTHORIZE
        ? "Insufficient Permissions"
        : "Google Classroom",
      "",
      toastrOptions
    );
  }

  useEffect(() => {
    setToastr();
  }, []);
  return <></>;
}
export default GoogleSignInModal;

export interface ISpeechMarks {
    time: number;
    type: string;
    start: number;
    end: number;
    value: string;
  }

export interface ISpeechWordHTMLElement {
  innerHTML: string;
  index: number;
  elementTag: string;
}
  
export const speechConfig = {
  semantics: true,
  domain:  'mathspeak',
  style:  'default',
  speakText: true,
  speech: 'deep',
  modality: "speech",
};

export const speakerClass = {
  question: {
    learnosity: "lrn_question",
    app: "div_speaker_question"
  },
  feature: {
    learnosity: "lrn_sharedpassage",
    app: "div_speaker_feature"
  },
  option: {
    learnosity: "lrn-mcq-option",
    app: "div_speaker_options"
  },
  arialLabel: {
    learnosity: "lrn-qe-aria-label-widget"
  }
}
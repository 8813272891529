import {
  HelperText,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Select,
} from "@windmill/react-ui";
import React, { Fragment, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import PublishMessageParams from "../../../model/videoMessage/publishMessageParams";
import MultiselectComponent from "../../shared/multiselect";
import { ReactMediaRecorder } from "react-media-recorder";
import VideoRecorder from "../../shared/videoRecorder";
import Constant from "../../../utils/constant/constant";

export interface VideoMessageModalProps {
  userContext?: any;
  masterData?: any;
  messageId?: number;
  messageContent?: string;
  linkedItemId?: number;
  title?: string;
  isActive?: boolean;
  startDate?: Date;
  endDate?: Date;
  gradeId?: number;
  classIds?: number[];
  studentIds?: number[];
  showModal: (show: boolean) => void;
  publishMessage: (params: PublishMessageParams) => any;
  uploadFile: (formData: FormData, callBack: (data) => void) => any;
}

function VideoMessageModal(props: VideoMessageModalProps) {
  const [messageTitle, setMessageTitle] = useState("");
  const [selectedIsActive, setIsActive] = useState(true);
  const [selectedStartDate, setStartDate] = useState(new Date());
  const [selectedEndDate, setEndDate] = useState(new Date());
  const [selectedGrade, setGrade] = useState(0);
  const [selectedClasses, setClasses] = useState<any[]>([]);
  const [selectedStudents, setStudents] = useState<any[]>([]);
  const [selectedfile, setfile] = useState(new File([], ""));
  const [multiSelectClassOptions, setMultiSelectClassOptions] = useState<any[]>(
    []
  );
  const [multiSelectStudentOptions, setMultiSelectStudentOptions] = useState<
    any[]
  >([]);
  const [validationMessage, setValidationMessage] = useState("");
  const [publishFlag, setPublishFlag] = useState(false);
  const maxRecordingTimeLimit = 30000;

  useEffect(() => {
    setData();
  }, []);

  const setData = () => {
    if (props.messageId && props.messageId > 0) {
      const title = props.title ? props.title : "";
      const isActive =
        props.isActive || props.isActive === false ? props.isActive : true;
      const gradeId = props.gradeId ? props.gradeId : 0;
      const filename = props.messageContent?.split("/").pop();
      const multiSelectClasses = props.masterData.classes
        .filter((item) => {
          return item.gradeId === gradeId;
        })
        .map((item) => {
          return { name: item.value, id: item.key };
        });
      const multiSelectStudents = props.masterData.students
        .filter((item) => {
          return multiSelectClasses.includes(item.classId);
        })
        .map((item) => {
          return { name: item.value, id: item.key };
        });

      setMessageTitle(title);
      setIsActive(isActive);
      setMultiSelectClassOptions(multiSelectClasses);
      setMultiSelectStudentOptions(multiSelectStudents);
      setfile(new File([], filename ? filename : ""));
    }
  };

  const handleTextChange = (event: any) => {
    setMessageTitle(event.target.value);
  };

  const handleGradeChange = (event: any) => {
    const gradeId = parseInt(event.target.value);
    const classes = props.masterData.classes
      .filter((item) => {
        return item.gradeId === gradeId;
      })
      .map((item) => {
        return { name: item.value, id: item.key };
      });

    clearDDLData();
    setGrade(gradeId);
    setMultiSelectClassOptions(classes);
  };

  const handleClassChange = (classes: any) => {
    const classIds = classes.map((item) => {
      return item.id;
    });
    const students = props.masterData.students
      .filter((item) => {
        return classIds.includes(item.classId);
      })
      .map((item) => {
        return { name: item.value, id: item.key };
      });

    setClasses(classes);
    setMultiSelectStudentOptions(students);
  };

  const handleStudentChange = (students: any) => {
    setStudents(students);
  };

  const handleVideoStopEvent = (blob: Blob) => {
    const fileName =
      Math.floor(Math.random() * 9000000000000) + 1000000000 + ".webm";
    const file = new File([blob], fileName);
    setfile(file);
  };

  const uploadFileToAWS = (callBack: (data) => void) => {
    const file = selectedfile;
    const formData = new FormData();
    formData.append("file", file);
    props.uploadFile(formData, callBack);
  };

  const publishVideoMessage = (data) => {
    if (data) {
      const messageId = 0;
      const linkedItemId = 0;
      const messageType = 2;
      const messageTargetType = 5;
      const linkedItemTitle = "";
      const messageContent = selectedfile.name;
      const userId = props.userContext.userId;
      const schoolid = props.userContext.schoolId;
      const title = messageTitle;
      const isActive = selectedIsActive;
      const startDate = selectedStartDate;
      const endDate = selectedEndDate;
      const gradeId = selectedGrade;
      const classIds = selectedClasses.map((item) => {
        return item.id;
      });
      const studentIds = selectedStudents.map((item) => {
        return item.id;
      });
      const params = new PublishMessageParams(
        userId,
        schoolid,
        title,
        isActive,
        startDate,
        endDate,
        gradeId,
        classIds,
        studentIds,
        messageId,
        linkedItemId,
        messageType,
        messageTargetType,
        linkedItemTitle,
        messageContent
      );
      props.publishMessage(params);
    }
  };

  const rePublishVideoMessage = () => {
    if (props.messageId && props.messageId > 0) {
      const messageId = props.messageId;
      const linkedItemId = props.linkedItemId ? props.linkedItemId : 0;
      const messageType = 2;
      const messageTargetType = 5;
      const linkedItemTitle = "";
      const messageContent = selectedfile.name;
      const userId = props.userContext.userId;
      const schoolid = props.userContext.schoolId;
      const title = messageTitle;
      const isActive = selectedIsActive;
      const startDate = selectedStartDate;
      const endDate = selectedEndDate;
      const gradeId = selectedGrade;
      const classIds = selectedClasses.map((item) => {
        return item.id;
      });
      const studentIds = selectedStudents.map((item) => {
        return item.id;
      });
      const params = new PublishMessageParams(
        userId,
        schoolid,
        title,
        isActive,
        startDate,
        endDate,
        gradeId,
        classIds,
        studentIds,
        messageId,
        linkedItemId,
        messageType,
        messageTargetType,
        linkedItemTitle,
        messageContent
      );
      props.publishMessage(params);
    }
  };

  function validate() {
    if (messageTitle === "") {
      setValidationMessage("Please enter the message title");
      return false;
    } else if (selectedEndDate <= selectedStartDate) {
      setValidationMessage("Please select a valid date");
      return false;
    } else if (selectedGrade === 0) {
      setValidationMessage("Please select grade");
      return false;
    } else if (selectedClasses.length === 0) {
      setValidationMessage("Please select classes");
      return false;
    } else if (selectedStudents.length === 0) {
      setValidationMessage("Please select students");
      return false;
    } else if (selectedfile.name === "") {
      setValidationMessage("Please record message");
      return false;
    }

    return true;
  }

  const publish = () => {
    setPublishFlag(true);
    if (validate()) {
      //setLoader(true);
      props.showModal(false);
      if (props.messageId && props.messageId > 0) {
        rePublishVideoMessage();
      } else {
        uploadFileToAWS(publishVideoMessage);
      }
    }
  };

  function clearDDLData() {
    setGrade(0);
    setClasses([]);
    setStudents([]);
    setMultiSelectClassOptions([]);
    setMultiSelectStudentOptions([]);
  }

  return (
    <Fragment>
      <Modal
        isOpen={true}
        onClose={() => {
          props.showModal(false);
        }}
        className="w-54 w-full px-6 py-4 overflow-hidden bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:max-w-3xl"
      >
        <ModalHeader>
          {props.messageId && props.messageId > 0
            ? "Edit Video Message"
            : "Create New Video Message"}
        </ModalHeader>
        <ModalBody>
          <div className="grid grid-cols-2 gap-4 ">
            <div className="h-full">
              <ReactMediaRecorder
                stopStreamsOnStop={true}
                video
                mediaRecorderOptions={{
                  mimeType: "video/webm;codecs:vp9,opus"
                }}
                onStop={(blobUrl, blob) => {
                  handleVideoStopEvent(blob);
                }}
                blobPropertyBag={{
                  type: Constant.MimeTypes.MP4,
                  }}
                askPermissionOnMount={true}
                render={({ startRecording, stopRecording, mediaBlobUrl, previewStream }) => (
                  <div>
                    {mediaBlobUrl && <video src={mediaBlobUrl} controls autoPlay loop />}
                    <VideoRecorder 
                      stream={previewStream} 
                      onStartRecording={startRecording} 
                      maxRecordingTimeLimit={maxRecordingTimeLimit}
                      onStopRecording={() => {
                        stopRecording();                        
                      }}
                    />
                  </div>
                )}
              />
              <p className="text-sm text-yellow-600 bg-yellow-50 dark:text-red-400 p-4 block rounded-md mt-4">
                The video message will be available for a maximum of 45 days.
                The videos are permanently deleted after 45 days of creation.
              </p>
            </div>
            <div className="grid grid-cols-2 gap-4">
              <div className="col-span-2">
                <label
                  className="block text-sm font-medium text-gray-900 mb-1"
                  htmlFor=""
                >
                  Message Title
                </label>
                <input
                  className={`py-2 px-4 block w-full shadow-sm text-gray-900 focus:outline-none focus:border-gray-700 border-gray-300 rounded-md border border-${
                    messageTitle === "" && publishFlag ? "red-500" : "gray-300"
                  } `}
                  type="text"
                  placeholder=""
                  value={messageTitle}
                  onChange={(e) => {
                    handleTextChange(e);
                  }}
                />
              </div>

              <div>
                <label
                  className="block text-sm font-medium text-gray-900 mb-1"
                  htmlFor=""
                >
                  Start date{" "}
                </label>
                <DatePicker
                  className={
                    "react-datepicker__input-container py-2 px-4 block w-full shadow-sm text-gray-900 focus:outline-none focus:border-gray-700 border border-gray-300 rounded-md"
                  }
                  selected={selectedStartDate}
                  onChange={(date) => setStartDate(date)}
                />
              </div>
              <div>
                <label
                  className="block text-sm font-medium text-gray-900 mb-1"
                  htmlFor=""
                >
                  End date{" "}
                </label>
                <DatePicker
                  className={`react-datepicker__input-container py-2 px-4 block w-full shadow-sm text-gray-900 focus:outline-none focus:border-gray-700 border rounded-md border-${
                    selectedEndDate <= selectedStartDate && publishFlag
                      ? "red-500"
                      : "gray-300"
                  }`}
                  selected={selectedEndDate}
                  onChange={(date) => setEndDate(date)}
                />
              </div>
              <div>
                <label
                  className="block text-sm font-medium text-gray-900 mb-1"
                  htmlFor=""
                >
                  Grade
                </label>
                <div className="flex">
                  <Select
                    value={selectedGrade}
                    onChange={(e) => handleGradeChange(e)}
                    className={
                      "py-2.5 px-4 w-full  dark:text-gray-300 focus:border-purple-400 dark:border-gray-600 dark:bg-gray-700 focus:shadow-outline-purple dark:focus:shadow-outline-gray dark:focus:border-gray-600 form-select block text-sm focus:outline-none border-gray-400 sm:focus:border-gray-700 border-opacity-50 focus:shadow-outline-green form-select leading-5 p-1 rounded-lg border sm:focus:border-gray-700 border-opacity-50 focus:shadow-outline-green form-select leading-5 p-1 rounded-lg"
                    }
                  >
                    {props.masterData.grades
                      .filter((item) => {
                        return item.key !== 0;
                      })
                      .map((obj: { key: string; value: string }) => {
                        return (
                          <Fragment key={obj.key}>
                            <option key={obj.key} value={obj.key}>
                              {obj.value}
                            </option>
                          </Fragment>
                        );
                      })}
                  </Select>
                </div>
              </div>
              <div className="mt-5">
                <label className="relative p-3 flex flex-col cursor-pointer md:pl-4 md:pr-6 bg-white rounded-lg border-2 hover:shadow-lg transition-all">
                  <div className="flex items-center text-sm w-full">
                    <input
                      type="checkBox"
                      className="h-4 w-4 text-indigo-600 border-gray-300 focus:ring-indigo-500"
                      checked={selectedIsActive}
                      onChange={(e) => {
                        setIsActive(e.target.checked);
                      }}
                    />
                    <span
                      id="video-status-label"
                      className="text-gray-900 ml-3 font-medium"
                    >
                      Active
                    </span>
                  </div>
                </label>
              </div>

              <div className="col-span-2">
                <label
                  className="block text-sm font-medium text-gray-900 mb-1"
                  htmlFor=""
                >
                  Class
                </label>
                <div className="flex">
                  <MultiselectComponent
                    showCheckbox={true}
                    placeholder={"Select Classes"}
                    options={multiSelectClassOptions}
                    displayValue="name"
                    handleOnSelect={(selectedList, selectedItem) => {
                      handleClassChange(selectedList);
                    }}
                    handleOnRemove={(selectedList, removedItem) => {
                      handleClassChange(selectedList);
                    }}
                    selectedOptions={selectedClasses}
                    isValid={
                      selectedClasses.length === 0 && publishFlag ? false : true
                    }
                  ></MultiselectComponent>
                </div>
              </div>
              <div className="col-span-2">
                <label
                  className="block text-sm font-medium text-gray-900"
                  htmlFor=""
                >
                  Students
                </label>
                <div className="flex ">
                  <MultiselectComponent
                    showCheckbox={true}
                    placeholder={"Select Students"}
                    options={multiSelectStudentOptions}
                    displayValue="name"
                    handleOnSelect={(selectedList, selectedItem) => {
                      handleStudentChange(selectedList);
                    }}
                    handleOnRemove={(selectedList, removedItem) => {
                      handleStudentChange(selectedList);
                    }}
                    selectedOptions={selectedStudents}
                    isValid={
                      selectedStudents.length === 0 && publishFlag
                        ? false
                        : true
                    }
                  ></MultiselectComponent>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <HelperText valid={false} className="text-xl">
            {validationMessage}
          </HelperText>
          <button
            className="align-bottom items-center block justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none rounded-lg text-sm border border-transparent active:bg-purple-600 focus:shadow-outline-purple text-gray-700 bg-primary-green hover:bg-light-green py-2 px-5 ml-auto"
            onClick={() => {
              publish();
            }}
          >
            Publish
          </button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
}

const mapStateToProps = (state: any) => {
  return {
    masterData: state.masterData,
    userContext: state.userContext,
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(VideoMessageModal);

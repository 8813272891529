import { Fragment, useEffect, useState } from "react";
import {
  DEFAULT_PAGE_NUMBER,
  DEFAULT_PAGE_SIZE,
} from "../../../../../../utils/pagingConstant";
import Loader from "../../../../../shared/loader";
import PageSize from "../../../../../shared/pagination/pageSize";
import Pagination from "../../../../../shared/pagination/pagination";
import { AssessmentStudentAttemptsReport, IStudentAttemptsReport } from "../../../../../../model/report/resultsReport/StudentAttemptsReport";
import moment from "moment";
import { getScoreLabel } from "../../../../../../utils/scoreHelper";
import routeConstant from "../../../../../../utils/constant/routeConstant";
import SortTableHeader from "../../../../../shared/sortTableHeader";
import { SortingOrder } from "../../../../../../types/type";
import { Info } from "../../../../../../assets/icons";

interface IReportAssessmentStudentAttemptsTableProps {
  isDistrictUser: boolean;
  schoolIds: number[];
  schoolYearId: number;
  getData: (activeSortColumn: string, orderType: string, pageNumber: number, pageSize: number) => 
    Promise<AssessmentStudentAttemptsReport>;
  exportData: () => void;
}

export const ReportAssessmentStudentAttemptsTable = (
  props: IReportAssessmentStudentAttemptsTableProps
) => {

  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [pageNumber, setPageNumber] = useState(DEFAULT_PAGE_NUMBER);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [resetPageNumber, setResetPageNumber] = useState<boolean>(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [resultDetails, setResultDetails] = useState<Array<IStudentAttemptsReport>>([]);
  const [activeSortColumn, setActiveSortColumn] = useState("DateCompleted");
  const [orderType, setOrderType] = useState<SortingOrder>("desc");

  const onPageChange = (pageNo) => {    
    setPageNumber(pageNo);
  };

  const onPageSizeChange = (pageSize) => {    
    setPageNumber(DEFAULT_PAGE_NUMBER);
    setPageSize(pageSize);
    setResetPageNumber(true);
  };
  
  useEffect(() => {    
    getReportData();
  }, [pageSize, pageNumber, resetPageNumber, props.schoolIds]);

  useEffect(() => {
    setPageNumber(DEFAULT_PAGE_NUMBER);
    setResetPageNumber(!resetPageNumber);
  }, [orderType, activeSortColumn]);

  const getReportData = async () => {
    setShowLoader(true);

    const reportData = await props.getData(activeSortColumn, orderType, pageNumber, pageSize);

    if (reportData) {      
      setTotalRecords(reportData.data.totalRecords);
      setResultDetails(reportData.data.data);
    } else {
      setErrorMessage("No data found");
    }
    setShowLoader(false);
  };

  const formatDuration = (durationInSeconds: number): string => {
    var date = new Date(0);
    date.setSeconds(durationInSeconds); // specify value for SECONDS here
    var timeString = date.toISOString().substring(11, 19);
    return timeString;
  };

  const handleStudentResultViewClick = (
    assignmentId,
    assessmentId,
    assignmentActivityId,
    userId,
    gradeId,
    sessionId
  ) => {

    var route = routeConstant.TeacherRoutes.StudentAssignmentResult.replace(
        ":assignmentId",
        assignmentId ?? "0"
    )
        .replace(":assignmentActivityId", assignmentActivityId ?? assessmentId)
        .replace(":userId", userId)
        .replace(":gradeId", gradeId ?? 0)
        .replace(":sessionId", sessionId);
    window.open(route + (props.schoolYearId > 0 ? "schoolYearId=" + props.schoolYearId : ""), "_blank");
  };

  const handleSortRecords = (
    _data: any,
    columnName: string,
    sortType: SortingOrder
  ) => {
    setActiveSortColumn(columnName);
    setOrderType(sortType);
  };

  return (
    <>
      {showLoader && <Loader></Loader>}
      {errorMessage !== "" && <div className="p-3">{errorMessage}</div>}
      {resultDetails && (
        <div className="mt-5">
          {(props.isDistrictUser) && (
            <div className="text-sm mb-4 text-white font-light bg-secondary-teal px-4 py-2 mr-auto mt-2 ml-3 leading-5 flex overflow-hidden">
            <div className="flex-shrink-0 current-fill mt-0.5">
              <Info />
            </div>
            <div className="pl-2">
              To view individual student results, please filter by school.
            </div>
          </div>
          
          )}
          <div>
            <button
                 onClick={() => props.exportData()}
                 className={
                     "bg-primary-violet border mb-4 border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 hover:bg-dark-violet hover:shadow-lg"
                 }
              >
                Export
            </button>
            <div className="overflow-x-auto xl:overflow-x-visible">
              <table className="table w-full ">
                <thead>
                  <tr className="bg-primary-violet  text-white ">
                    <th className="text-center p-3 text-sm font-normal whitespace-nowrap">
                      <div className="w-25 flex justify-center">
                        <SortTableHeader
                          data={[]}
                          columnName="DateCompleted"
                          headerComponent="Completion Date"
                          orderType={orderType}
                          activeSortColumn={activeSortColumn}
                          sortRecords={handleSortRecords}
                        />
                      </div>
                    </th>
                    <th className="text-center p-3 text-sm font-normal whitespace-nowrap">
                      <div className="w-25 flex justify-center">
                        <SortTableHeader
                          data={[]}
                          columnName="Duration"
                          headerComponent="Duration"
                          orderType={orderType}
                          activeSortColumn={activeSortColumn}
                          sortRecords={handleSortRecords}
                        />
                      </div>
                    </th>
                    <th className="text-center p-3 text-sm font-normal whitespace-nowrap">
                      <div className="w-25 flex justify-center">
                        <SortTableHeader
                          data={[]}
                          columnName="Student"
                          headerComponent="Student"
                          orderType={orderType}
                          activeSortColumn={activeSortColumn}
                          sortRecords={handleSortRecords}
                        />
                      </div>
                    </th>
                    <th className="text-center p-3 text-sm font-normal">
                      Student ID
                    </th>
                    <th className="text-center p-3 text-sm font-normal whitespace-nowrap">
                      <div className="w-36 flex justify-center">
                        <SortTableHeader
                          data={[]}
                          columnName="Class"
                          headerComponent="Class"
                          orderType={orderType}
                          activeSortColumn={activeSortColumn}
                          sortRecords={handleSortRecords}
                        />
                      </div>
                    </th>
                    <th className="text-center p-3 text-sm font-normal whitespace-nowrap">
                      <div className="w-25 flex justify-center">
                        <SortTableHeader
                          data={[]}
                          columnName="Score"
                          headerComponent="Score"
                          orderType={orderType}
                          activeSortColumn={activeSortColumn}
                          sortRecords={handleSortRecords}
                        />
                      </div>
                    </th>
                    <th className="text-left p-3 text-sm font-normal"></th>
                  </tr>
                </thead>
                <tbody>
                  {resultDetails &&
                    resultDetails.length > 0 &&
                    resultDetails?.map((attempt, index) => {
                      return (
                        <Fragment>
                          <tr
                            className={`hover:bg-primary-violet/20 transition ease-in-out duration-300 ${
                              index % 2 === 0 ? "bg-white" : "bg-primary-violet/10"
                            }`}
                          >
                            <td className="text-sm p-3 text-center">
                              {attempt.dateCompleted
                                ? moment(attempt.dateCompleted).format("MM/DD/YYYY")
                                : "N/A"}
                            </td>
                            <td className="text-sm p-3 text-center">
                              {attempt.durationInSeconds
                                ? formatDuration(attempt.durationInSeconds)
                                : "N/A"}
                            </td>
                            <td className="text-sm p-3 text-center">
                              {attempt.lastName},{" "}
                              {attempt.firstName}
                            </td>
                            <td className="text-center text-sm p-3 ">
                              {!attempt.studentId || attempt.studentId === ""
                                ? "-"
                                : attempt.studentId}
                            </td>
                            <td className="text-sm p-3 ">{attempt.className}</td>                            
                            <td className="text-sm text-center p-3">
                              <div className="flex flex-col items-center">
                                <div className="flex gap-x-4 font-bold px-1">
                                  <div>
                                    {"(" + getScoreLabel(attempt.pointsEarned) +
                                        "/" +
                                    getScoreLabel(attempt.pointsPossible) + ")"}
                                  </div>
                                  <div>
                                    {(attempt.pointsPossible > 0
                                          ? (
                                              (attempt.pointsEarned /
                                                attempt.pointsPossible) *
                                              100
                                            ).toFixed()
                                          : 0) + "%"}
                                  </div>
                                </div>
                                {attempt.retryPointsEarned != null && attempt.correctPointsEarned !== null && attempt.correctPointsEarned !== undefined && (
                                  <>
                                    <div className="italic font-normal text-center w-full text-primary-purple">
                                      Retry
                                    </div>
                                    <div className="flex gap-x-4 italic text-primary-purple border border-gray-400 rounded-lg px-1">
                                      <div>
                                        { "(" +
                                            getScoreLabel(
                                              attempt.correctPointsEarned
                                            ).toString() +
                                            "/" +
                                            getScoreLabel(attempt.pointsPossible) +
                                            ")"}
                                      </div>
                                      <div>
                                        {(attempt.pointsPossible > 0
                                              ? (
                                                  (attempt.correctPointsEarned /
                                                    attempt.pointsPossible) *
                                                  100
                                                ).toFixed()
                                              : 0) + "%"}
                                      </div>
                                    </div>
                                  </>
                                )}
                              </div>
                            </td>
                            <td className="text-sm p-3 ">
                              <button
                                  className="text-blue-500 hover:underline"
                                  onClick={() =>
                                    handleStudentResultViewClick(attempt.assignmentId, attempt.assessmentId, attempt.assignmentActivityId, attempt.userId, attempt.gradeId, attempt.sessionId)
                                  }
                                >
                                  View{" "}
                              </button>
                            </td>
                          </tr>
                        </Fragment>
                      );
                    })}
                  {resultDetails?.length === 0 && (
                    <tr className="bg-white">
                      <td colSpan={6} className="text-sm p-3">
                        No records found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>

            {resultDetails?.length !== 0 && (
              <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between border-t-2 pt-4 mt-3">
                <Pagination
                  totalRecords={totalRecords}
                  pageNumber={pageNumber}
                  pageSize={pageSize}
                  onPageChange={onPageChange}
                  reset={resetPageNumber}
                  setPageNumber={setPageNumber}
                />
                <PageSize
                  pageSize={pageSize}
                  onPageSizeChange={onPageSizeChange}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

import { SetStateAction, useEffect, useState } from "react";
import { connect } from "react-redux";
import { getSchoolByDistrictId } from "../../../../../api/teacher/school";
import Loader from "../../../../shared/loader";
import { toast } from "react-toastify";
import Subject from "../../../../shared/dropdowns/subject";
import { ISelectElement, ISelectSchoolElement } from "../../../../../model/interface/selectElement";
import School from "../../../../shared/dropdowns/school";
import SelectGroup from "react-select";
import DatePicker from "react-datepicker";
import TeacherUsageReportButtons from "./teacherUsageReportButtons";
import { GetTeachersBySchoolAndSubject } from "../../../../../api/teacher/teacher";
import { ITeachersBySchoolAndSubjectQuery, ITeachersBySchoolAndSubjectResponse } from "../../../../../model/teacher/teacherSchoolSubject";
import {getEndDate} from "../../../../../utils/dateHelper";

interface TeacherUsageReportFiltersProps {
  profile: { 
    currentSchoolYear: { schoolYearId: number }
  },
  userContext: { 
    schoolId: SetStateAction<number>,
    userId: number,
    roleId: number, 
    districtId: number, 
  },
  isDistrictUser: boolean,
  selectedSchools: ISelectSchoolElement[],
  setSelectedSchools: (elements: ISelectSchoolElement[]) => void,
  selectedSubject: Array<{label: string, value: number}>,
  setSelectedSubject: (selectedSubject: Array<{label: string, value: number}>) => void,
  selectedTeacher: ISelectElement,
  setSelectedTeacher: (selectedTeacher: ISelectElement) => void,
  selectedStartDate: Date | null,
  setSelectedStartDate: (selectedStartDate: Date | null) => void,
  selectedEndDate: Date | null,
  setSelectedEndDate: (selectedEndDate: Date | null) => void,
  minStartDate: Date,
  maxEndDate: Date,
  runReport: () => void,
  exportReport: () => void,
  showReport: boolean,
  setShowReport: (value: boolean) => void,
}

const allTeachers: ISelectElement = { label: "All Teachers", value: "0"};

function TeacherUsageReportFilters(props: Readonly<TeacherUsageReportFiltersProps>) {
  const {
    isDistrictUser,
    selectedSchools,
    setSelectedSchools,
    selectedSubject,
    setSelectedSubject,
    selectedTeacher,
    setSelectedTeacher,
    selectedStartDate,
    setSelectedStartDate,
    selectedEndDate,
    setSelectedEndDate,
    runReport,
    exportReport,
    showReport,
    setShowReport,
  } = props;

  const [teachers, setTeachers] = useState<Array<ISelectElement>>([]);
  const [isInvalidStartDate, setIsInvalidStartDate] = useState<boolean>(false);
  const [isInvalidEndDate, setIsInvalidEndDate] = useState<boolean>(false);
  const [showLoading, setShowLoading] = useState<boolean>(false);

  const getSchool = () => {
    setShowLoading(true);
    getSchoolByDistrictId(
      props.userContext?.districtId ?? 0, 0
    )
    .then(response => {
      setShowLoading(false);
      if (response.data.length) {
        const schoolId = +props.userContext.schoolId;
        const school = response.data.find(s => s.schoolId === schoolId);
        if (school) {
          const currentSchool: ISelectSchoolElement[] = [{
            label: school.name,
            value: {
              schoolId: school.schoolId,
              schoolAccountId: school.schoolAccountId,
            }
          }];
          setSelectedSchools(currentSchool);
          getTeachers(currentSchool, selectedSubject);
        }
      }
    })
    .catch(error => {
      setShowLoading(false);
      toast.error(`${error.message || ''} Cannot load Schools`);
    })
  }

  const getTeachers = (selectedSchools: ISelectSchoolElement[], selectedSubject: Array<{label: string, value: number}>) => {
    const schoolIds: number[] = selectedSchools.map(s => s.value.schoolId);
    const subjectIds: number[] = selectedSubject.map(s => s.value);
    const query: ITeachersBySchoolAndSubjectQuery = {
      districtId: props.userContext?.districtId,
      schoolIds: schoolIds,
      subjectIds: subjectIds,
    }
    
    setShowLoading(true);
    GetTeachersBySchoolAndSubject(query)
    .then(response => {
      setShowLoading(false);
      if (response?.data?.length) {
        fillTeacherCombobox(response.data);
      } else {
        setTeachers([allTeachers]);
      }
    })
    .catch(error => {
      setShowLoading(false);
      toast.error(`${error.message || ''} Cannot load Teachers`);
    })
  }

  const fillTeacherCombobox = (teachers: ITeachersBySchoolAndSubjectResponse[]) => {
    const teachersList: Array<ISelectElement> = teachers.map((value: ITeachersBySchoolAndSubjectResponse) =>(
      {
        label: `${value.lastName}, ${value.firstName}` + (isDistrictUser ? ` - ${value.school}` : ''),
        value: ""+value.userId,
      }
    ))
    setTeachers([allTeachers, ...teachersList]);
    setSelectedTeacher(allTeachers);
  }

  const handleSchoolChange = (selectedSchools: ISelectSchoolElement[]) => {
    if (selectedSchools.length === 1 && selectedSchools[0].value.schoolId === 0)
      selectedSchools = [];
    setSelectedSchools(selectedSchools);
    getTeachers(selectedSchools, selectedSubject);
  }

  const handleSubjectChange = (selectedSubjects: Array<{label: string, value: number}>) => {
    if (selectedSubjects.length === 1 && +selectedSubjects[0].value === 0)
      selectedSubjects = [];
    setSelectedSubject(selectedSubjects);
    getTeachers(selectedSchools, selectedSubjects);
  }

  const handleStartDateChange = (date: Date) => {
    const isInvalid = !!(selectedEndDate && date > selectedEndDate);
    setIsInvalidStartDate(isInvalid);
    setSelectedStartDate(date);
  }

  const handleEndDateChange = (date: Date) => {
    const isInvalid = !!(selectedStartDate && date < selectedStartDate);
    setIsInvalidEndDate(isInvalid);
    setSelectedEndDate(date);
  }

  const handleCalendarOpen = (mode: string) => {
    switch (mode){
      case "end":
        if (!selectedEndDate) {
          setSelectedEndDate(getEndDate(new Date(), true));
        }
        break;
    }
  };

  const resetFilters = () => {
    if (isDistrictUser) {
      handleSchoolChange([]);
    }
    setSelectedSubject([]);
    setSelectedTeacher(allTeachers);
    setSelectedStartDate(null);
    setSelectedEndDate(null);
    setIsInvalidStartDate(false);
    setIsInvalidEndDate(false);
    setShowReport(false);
  }

  useEffect(() => {
    if (!isDistrictUser) getSchool();
    if (isDistrictUser) {
      getTeachers(selectedSchools, selectedSubject);
    }
  }, []);

  return (
    <div>
      {showLoading && <Loader />}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 2xl:grid-cols-5 xxxl:grid-cols-5 gap-4 mb-4 mt-4">
        {isDistrictUser && (
          <div className="flex flex-col">
            <School 
              selectedItems={selectedSchools}
              onChange={handleSchoolChange}
            />
          </div>
        )}

        <div className="flex flex-col">
          <Subject 
            themeColor={"#992883"}
            placeholder="All Subjects"
            setSelectedSubject={handleSubjectChange}
            selectedItems={selectedSubject}
            isMulti
          />
        </div>

        <div className="flex flex-col">
          <label className="block mb-1 text-sm text-gray-500">
            Teacher
          </label>
          <SelectGroup 
            placeholder={"All Teachers"}
            options={teachers}
            onChange={(e) => setSelectedTeacher(e as ISelectElement)}
            value={selectedTeacher}
            isSearchable
          />
        </div>

        <div className="flex flex-col">
          <label className="block mb-1 text-sm text-gray-500">
            Start Date
          </label>
          <DatePicker 
            className="react-datepicker__input-container py-1.5 px-3 block w-full shadow-sm focus:ring-primary-violet focus:border-primary-violet border border-gray-300 rounded"
            selected={selectedStartDate}
            minDate={props.minStartDate}
            maxDate={props.maxEndDate}
            onChange={handleStartDateChange}
            isClearable={true}
            onCalendarOpen={() => { handleCalendarOpen("start") }}
          />
          {isInvalidStartDate && (
            <span className="pl-2 text-red-600">Invalid Date</span>
          )}
        </div>

        <div className="flex flex-col">
          <label className="block mb-1 text-sm text-gray-500">
            End Date
          </label>
          <DatePicker 
            className="react-datepicker__input-container py-1.5 px-3 block w-full shadow-sm focus:ring-primary-violet focus:border-primary-violet border border-gray-300 rounded"
            selected={selectedEndDate}
            minDate={props.minStartDate}
            maxDate={props.maxEndDate}
            onChange={handleEndDateChange}
            isClearable={true}
            onCalendarOpen={() => { handleCalendarOpen("end") }}
          />
          {isInvalidEndDate && (
            <span className="pl-2 text-red-600">Invalid Date</span>
          )}
        </div>
      </div>

      <TeacherUsageReportButtons 
        resetFilters={resetFilters}
        isRunReportEnabled={!isInvalidStartDate && !isInvalidEndDate}
        isExportEnabled={showReport && !isInvalidStartDate && !isInvalidEndDate}
        runReport={runReport}
        exportReport={exportReport}
      />
    </div>
  )
}

export default connect((state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
})(TeacherUsageReportFilters);

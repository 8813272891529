import { useEffect, useState } from "react";
import {
  DownArrowIcon,
  Info,
  OutlineCrossIcon,
  OutlineTickIcon,
  UpArrow,
} from "../../../../../../assets/icons";
import constant from "../../../../../../utils/constant/constant";
import InfoMessage from "../../../../../shared/infoMessage";
import QuestionPreviewFromReport from "../../../shared/questionPreview/questionPreviewFromReport";
import { getScoreLabel } from "../../../../../../utils/scoreHelper";
import ResultReportItemAnalysisHeaderGraph from "./resultReportItemAnalysisHeaderGraph";

export const ResultsReportItemAnalysis = (props: any) => {
  const [resultsReportItemAnalysis, setResultsReportItemAnalysis] =
    useState<any>();
  const [isCollapse, setIsCollapse] = useState<boolean>(false);
  const [previewId, setPreviewId] = useState<string>("");
  const [questionIdPreview, setQuestionIdPreview] = useState<number>();
  const [open, setOpen] = useState<boolean>(false);
  const [questionText, setQuestionText] = useState<string>("");

  const openModal = (learnosityReferenceId, questionId, questionText) => {
    questionText = questionText
      ?.replace(/<img .*?>/g, "")
      .replace(/<br\s*\/?>/gi, "");
    setPreviewId(learnosityReferenceId);
    setQuestionIdPreview(questionId);
    setQuestionText(questionText);
    setOpen(true);
  };

  const popUpClose = (value) => {
    setOpen(value);
    setPreviewId("");
  };

  useEffect(() => {
    setResultsReportItemAnalysis(props.data);
  }, []);
  return (
    <>
      {(props.redirectedFrom === constant.RedirectedFrom.MYASSIGNMENTS ||
        props.redirectedFrom === constant.RedirectedFrom.MYASSESSMENTS ||
        props.redirectedFrom ===
          constant.RedirectedFrom.PREMIUMASSESSMENTS) && (
        <div className="text-sm text-white font-light bg-secondary-teal px-4 py-2 mr-auto mt-2 leading-5 flex overflow-hidden">
          <div className="flex-shrink-0 current-fill mt-0.5">
            <Info />
          </div>
          <div className="pl-2">
            {props.redirectedFrom === constant.RedirectedFrom.MYASSIGNMENTS &&
              "The best attempt each student has made on this assignment is displayed. You can access details on each attempt by searching in the Class Results tab of your Gradebook."}
            {(props.redirectedFrom === constant.RedirectedFrom.MYASSESSMENTS ||
              props.redirectedFrom ===
                constant.RedirectedFrom.PREMIUMASSESSMENTS) &&
              "The most recent attempt each student has made on this assessment is displayed. You can access details on each attempt by selecting ‘Export Results’ from your assessment bank or by searching in the Class Results tab of your Gradebook."}
          </div>
        </div>
      )}
      <QuestionPreviewFromReport
        key={questionIdPreview}
        modal={openModal}
        show={open}
        changeValue={popUpClose}
        itemRef={previewId}
        questionIdPreview={questionIdPreview}
        activityName={questionText}
        userId={props.userId}
        subjectId={props.subjectId}
        subjectName={props.subjectName}
        standardIds={props.standardIds}
      ></QuestionPreviewFromReport>
      {props.roleId === constant.UserRoleId.District && (
        <InfoMessage message="To view individual student results, please filter by school." />
      )}
      {resultsReportItemAnalysis && (
        <div className="mt-5 max-h-[600px] 2xl:max-h-[800px] overflow-auto">
          <table className="relative border-b border-primary-violet">
            <tr className="border divide-x divide-primary-violet border-primary-violet">
              <th className="sticky top-0 whitespace-nowrap py-1 text-left px-2 bg-gray-100 sticky left-0 z-[9]">
                <span className="text-primary-violet text-base min-w-[200px] font-semibold inline-block">
                  Student
                </span>
              </th>

              <th
                className="sticky top-0 whitespace-nowrap relative px-2 pr-2 bg-gray-100 sticky left-[202px] z-[9]"
                title="Results"
              >
                <p className="text-primary-violet text-base font-semibold">
                  <div
                    className="flex"
                    onClick={() => setIsCollapse(!isCollapse)}
                  >
                    Results
                    <span>
                      {!isCollapse && (
                        <UpArrow className="w-5 mr-2 cursor-pointer text-blue-600" />
                      )}
                      {isCollapse && (
                        <DownArrowIcon className="w-5 mr-2 cursor-pointer text-blue-600" />
                      )}
                    </span>
                  </div>
                </p>
              </th>

              {resultsReportItemAnalysis.questions?.map(
                (question, questionIndex) => (
                  <>
                    <th
                      className="sticky top-0 relative px-2 bg-white"
                      title={question.questionId}
                    >
                      <p className="items-center text-sm font-medium">
                        <div
                          className="underline cursor-pointer text-blue-600 whitespace-nowrap"
                          onClick={() =>
                            openModal(
                              question.englishReferenceId ??
                                question.questionId + "-en",
                              question.questionId,
                              question.questionText ?? ""
                            )
                          }
                        >
                          Question {questionIndex + 1}
                        </div>
                        <div className="flex flex-col items-center text-center text-sm font-normal h-[135px]">
                          <span className="text-gray-700 text-xs block mt-2">
                            {question.standardCode}
                          </span>
                          <ResultReportItemAnalysisHeaderGraph
                            question={question}
                          ></ResultReportItemAnalysisHeaderGraph>
                        </div>
                      </p>
                    </th>
                  </>
                )
              )}
            </tr>

            {!isCollapse &&
              resultsReportItemAnalysis.students.map(
                (student, studentIndex) => (
                  <>
                    <tr
                      className={
                        "border divide-x divide-primary-violet border-primary-violet " +
                        (studentIndex % 2 !== 0 ? "bg-primary-violet/10" : "")
                      }
                    >
                      <td className="bg-gray-100 sticky left-0 z-0 py-1">
                        <div
                          lang="es"
                          className="font-semibold flex items-center justify-start w-[200px] px-2"
                        >
                          <p className="break-words">
                            {student.lastName}, {student.firstName}
                          </p>
                        </div>
                      </td>
                      <td className="whitespace-nowrap bg-gray-100 sticky left-[202px] z-0">
                        <div
                          className={
                            "font-semibold flex items-center justify-center w-full  px-2 "
                          }
                        >
                          {props.redirectedFrom ===
                            constant.RedirectedFrom.MYASSIGNMENTS &&
                          student.questionResult?.length > 0 &&
                          student.questionResult?.filter((x) => x.isUngraded)
                            .length > 0 ? (
                            "NG"
                          ) : (
                            <>
                              {getScoreLabel(student.pointsEarned)}/
                              {getScoreLabel(student.pointsPossible)}
                            </>
                          )}
                        </div>
                      </td>
                      {student.questionResult?.map(
                        (pct, index) =>
                          !isCollapse && (
                            <>
                            {pct.isUngraded && (
                                <td
                                  className={
                                    "text-center p-3 text-sm font-normal bg-[#999]"
                                  }
                                >
                                  <span className="w-5 text-gray-700 fill-current mx-auto">
                                    NG
                                  </span>
                                </td>
                              )}
                              {pct.isCorrect &&
                                !pct.isPartiallyCorrect && (
                                  <td
                                    className={
                                      "text-center p-3 text-sm font-normal bg-galaxy-green"
                                    }
                                  >
                                    <OutlineTickIcon className="w-5 text-green-700 fill-current mx-auto"></OutlineTickIcon>
                                  </td>
                                )}
                              {!pct.isCorrect &&
                                pct.isPartiallyCorrect && (
                                  <td
                                    className={
                                      "text-center p-3 text-sm font-normal bg-[#d6d6d6]"
                                    }
                                  >
                                    <span className="w-5 text-gray-700 fill-current mx-auto">
                                      P
                                    </span>
                                  </td>
                                )}
                              {!pct.isCorrect &&
                                !pct.isPartiallyCorrect &&
                                pct.isAttempted &&
                                !pct.isUngraded && (
                                  <td
                                    className={
                                      "text-center p-3 text-sm font-normal bg-[#E53F71]"
                                    }
                                  >
                                    <OutlineCrossIcon className="w-5 text-gray-700 fill-current mx-auto"></OutlineCrossIcon>
                                  </td>
                                )}
                              {!pct.isCorrect &&
                                !pct.isPartiallyCorrect &&
                                !pct.isAttempted && !pct.isUngraded && (
                                  <td
                                    className={
                                      "text-center p-3 text-sm font-normal bg-[#999]"
                                    }
                                  >
                                    <span className="w-5 text-gray-700 fill-current mx-auto">
                                      -
                                    </span>
                                  </td>
                                )}
                              
                            </>
                          )
                      )}
                    </tr>
                  </>
                )
              )}
          </table>
        </div>
      )}
    </>
  );
};

import { IContentAreaSubject } from "./../../model/interface/subject";
import { AxiosResponse } from "axios";
import { AxiosHelper } from "../../environment/axiosConfig";
import { ISubject } from "../../model/interface/subject";
import APIConstant from "../../utils/constant/apiConstant";
import { ICannedTestSubject } from "../../model/interface/cannedTestSubject";
import IStudyPlanSubjects from "../../model/student/studyPlanSubjects";

export function getStudentSubject(
  userId: number
): Promise<AxiosResponse<Array<ISubject>>> {
  return AxiosHelper.axiosInstance().get<Array<ISubject>>(
    APIConstant.StudyPlan_API.StudyPlanSubject,
    {
      params: {
        userId: userId,
      },
    }
  );
}

export function getStudentContentAreaSubject(
  userId: number
): Promise<AxiosResponse<Array<IContentAreaSubject>>> {
  return AxiosHelper.axiosInstance().get<Array<IContentAreaSubject>>(
    APIConstant.StudyPlan_API.ContentAreaSubject,
    {
      params: {
        userId: userId,
      },
    }
  );
}

export function getStudyPlanStudentSubjects(
  userId: number
): Promise<AxiosResponse<Array<IStudyPlanSubjects>>> {
  return AxiosHelper.axiosInstance().get<Array<IStudyPlanSubjects>>(
    APIConstant.StudyPlan_API.StudyPlanSubject,
    {
      params: {
        userId: userId,
      },
    }
  );
}
export function getStudentCannedTestSubject(
  userId: number,
  classId: number
): Promise<AxiosResponse<Array<ICannedTestSubject>>> {
  return AxiosHelper.axiosInstance().get<Array<ICannedTestSubject>>(
    APIConstant.StudentCannedTest_API.CannedTestSubject,
    {
      params: {
        userId: userId,
        classId: classId,
      },
    }
  );
}

export function getAllSubjectsByUserId(
  userId: number
): Promise<AxiosResponse<Array<IStudyPlanSubjects>>> {
  return AxiosHelper.axiosInstance().get<Array<ICannedTestSubject>>(
    APIConstant.APP_SUBJECTS_ALL,
    {
      params: {
        TeacherId: userId,
      },
    }
  );
}
export function getCalculatorBySubjectId(
  subjectId: number
): Promise<AxiosResponse<string>> {
  return AxiosHelper.axiosInstance().get(
    APIConstant.CLASSMANAGEMENT_API.CalculatorBySubjectId,
    {
      params: {
        subjectId: subjectId,
      },
    }
  );
}

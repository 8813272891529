import { Fragment } from "react";
import Header from "../../header/header";
import Footer from "../../footer/footer";
import Sidebar from "../../menu/sidebar";
import { backToOriginalUser } from "../../../../utils/impersonateHelper";
import * as userContextAction from "../../../../redux/actions/userContextAction";
import { fetchProfile } from "../../../../redux/actions/userActions";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import SchoolCode from "../../header/schoolCode";
import constant from "../../../../utils/constant/constant";

const Layout = ({ children, ...props }) => {
  const history = useHistory();
  function backToTeacher(): void {
    backToOriginalUser(
      props.userContext,
      props.setUserContext,
      props.setProfile,
      history
    );
  }
  return (
    <Fragment>
      <div
        className={`flex flex-col justify-between min-h-screen w-full ${
          props.userContext?.impersonatedUser !== null
            ? " border-4  border-blues"
            : ""
        } `}
      >
        <Header />
        {props.userContext?.impersonatedUser !== null && (
          <div className="w-full fixed pointer-events-none min-h-full z-50 flex justify-center  ">
            <span className="bg-blues h-8  px-3  flex items-center  text-white ">
              You are logged in as {props.profile?.lastName},{" "}
              {props.profile?.firstName}
              <span
                className="pl-2 text-white hover:underline cursor-pointer pointer-events-auto font-semibold"
                onClick={backToTeacher}
              >
                Return to your account
              </span>
            </span>
          </div>
        )}
        <div className="flex flex-grow">
          {!window.location.pathname.toString().includes("launchSession") &&
            !window.location.pathname
              .toString()
              .includes("ArenaStartSession") &&
            !window.location.pathname
              .toString()
              .includes("AlienFinalResult") && <Sidebar />}
          <div className="min-w-0 w-full">

                      {props.userContext.roleId.toString() !==
                          constant.UserRoleId.District.toString() && (
                              <div>
                                  <SchoolCode></SchoolCode>
                              </div>
                          )}

            {children}
          </div>
        </div>
        <Footer></Footer>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setUserContext: (payload: userContextAction.UserContextState) => {
      dispatch(userContextAction.setUserContext(payload));
    },
    setProfile: (
      userId: number,
      roleId: number,
      schoolId: number,
      districtId: number
    ) => {
      dispatch(fetchProfile(userId, roleId, schoolId, districtId));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);

import { Fragment, useState } from "react";
import {
  getFavoriteQuestions,
  getPassageQuestions,
  getPassageSearch,
  updateFavoriteQuestion,
} from "../../../../api/teacher/assessment";
import { IAssessmentPassage } from "../../../../model/interface/assessmentPassage";
import { IAssessmentQuestion } from "../../../../model/interface/assessmentQuestion";
import { IPassageSearch } from "../../../../model/interface/passageSearch";
import {
  IKeyValuePair,
  ISelectElement,
} from "../../../../model/interface/selectElement";
import { IQuestionSearch } from "../../../../model/interface/questionSearch";
import Loader from "../../../shared/loader";
import QuestionPreview from "../shared/questionPreview/questionPreview";
import Feedback from "../../../shared/feedback";
import Constant from "../../../../utils/constant/constant";
import arraySort from "array-sort";
import MessagePopup from "../../../shared/messagePopup";
import { getHtmlParsedQuestions } from "../../../../utils/assessmentHelper";
import Standard from "../../../../model/teacher/standard";
import PassageQuestionFilters from "./passageQuestionFilters";
import PassageQuestionPassages from "./passageQuestionPassages";

interface passedProps {
  userId: number;
  districtId: number;
  hasDistrictAssessmentQuestionIcon: boolean;
  subjectId: number;
  domainList: Array<ISelectElement>;
  selectedQuestions: Array<IAssessmentQuestion>;
  updateQuestion: (question: IAssessmentQuestion) => void;
  allQuestions: Array<IAssessmentQuestion>;
  allowEdit: boolean;
}

  type SortByOption =
    | "Alphabetical"
    | "Newest"
    | "Question Count"
    | "Word Count"
    | "Lexile Level"
    | "Question Type"
    | "Favorites";

  const defaultCategory = {
    title: "Select",
    key: "0",
    value: "0",
  };

  interface IQuestionPreviewDetail {
    questionReference: string;
    questionId: number;
    questionText: string;
    currentIndex: number;
    isPassage: boolean;
    standardId: number;
    spanishQuestionReference: string;
    domainId?: number;
    passageId?: number;
  };

function PassageQuestion(props: passedProps) {
  const {
    userId,
    districtId,
    hasDistrictAssessmentQuestionIcon ,
    subjectId,
    selectedQuestions,
    updateQuestion,
    allQuestions,
    allowEdit,
  } = props;


  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [favoriteQuestionsIds, setFavoriteQuestionsIds] = useState<Array<number>>([]);
  const [selectedCategory, setSelectedCategory] = useState<any>(defaultCategory);
  const [selectedDomains, setSelectedDomains] = useState<Array<ISelectElement>>([]);
  const [selectedStandards, setSelectedStandards] = useState<Array<ISelectElement>>([]);
  const [feedbackStandardId, setFeedbackStandardId] = useState<number>();
  const [displayRows, setDisplayRows] = useState<Array<IKeyValuePair>>([]);
  const [passages, setPassages] = useState<Array<IAssessmentPassage>>([]);
  const [multiPassageQuestions, setMultiPassageQuestions] = useState<Array<number>>([]);
  const [selectedSortOption, setSelectedSortOption] = useState<Array<{ domainId: number; selectedValue: ISelectElement }>>([]);
  const [passageQuestions, setPassageQuestions] = useState<Array<IAssessmentQuestion>>([]);
  const [expandedPassagesIds, setExpandedPassagesIds] = useState<Array<number>>([]);
  const [feedbackQuestionId, setFeedbackQuestionId] = useState<number>(0);
  const [showFeedbackPopup, setShowFeedbackPopup] = useState<boolean>(false);
  const [questionPreviewDetail, setQuestionPreviewDetail] = useState<IQuestionPreviewDetail>();
  const [selectedQuestionCategoryIds, setSelectedQuestionCategoryIds] = useState<Array<number>>([]);
  const [showQuestionPreview, setShowQuestionPreview] = useState(false);
  const [errorMessagePopup, setErrorMessagePopup] = useState<boolean>(false);
  const [spanishVersion, setSpanishVersion] = useState<boolean>(false);
  const [keywordTags, setKeywordTags] = useState<Array<string>>([]);
  const [matchAnyTag, setMatchAnyTag] = useState<boolean>(true);

  const searchPassages = async (
    selectedStandards: Array<ISelectElement>,
    questionCategoryIds?: Array<number>,
    passageCategory?: any
  ) => {
    const selectedDomainsIds = selectedDomains.map(domain => domain.value);

    const selectedStandardIds = selectedStandards.map(standard => standard.value);

    if (selectedStandardIds.length === 0 && keywordTags.length === 0) {
      setPassages([]);
      return;
    }

    const passageSearch: IPassageSearch = {
      userId: userId,
      domainIds: selectedDomainsIds.join(","),
      standardIds: selectedStandardIds.join(","),
      questionTypeIds: questionCategoryIds
        ? questionCategoryIds.join(",")
        : selectedQuestionCategoryIds?.join(","),
      passageCategoryIds:
        passageCategory?.passageCategoryId ??
        selectedCategory?.passageCategoryId,
      subjectId: subjectId,
      includeConstructedResponseQuestionTypes: true,
      keywordTags: keywordTags,
      matchAnyTag: matchAnyTag,
      pageSize: 9999,
      pageNumber: 1,
    };
    setShowLoading(true);
    const response = await getPassageSearch(passageSearch);
    const passages = response.data.passages
    setPassages(passages);

    const passageTableRows: Array<IKeyValuePair> = passages.map((passage: any) => ({
      key: passage.passageId.toString(),
      value: "10",
    }))
    setDisplayRows(passageTableRows);

    if (passages?.length > 0) {
      const multiPassageQuestionList: Array<number> = response.data.questionIds;
      setMultiPassageQuestions(multiPassageQuestionList);
    }

    setShowLoading(false);
  }

  const updateStandardsAndQuestions = (groupedStandards: any[]) => {
    const standardIdsSet: Set<number> = new Set(selectedQuestions
      .filter(question => question.standard)
      .map(question => parseInt(question.standardId ?? "0"))
    );
    const standardIds: Array<number> = Array.from(standardIdsSet);

    const standardsData: Array<ISelectElement> = groupedStandards
      .map(standard => standard.options)
      .flat()
      .filter(standard => standardIds.includes(+standard.value));
    setSelectedStandards(standardsData);
    searchPassages(standardsData);
  }

  const handlePassageQuestionsCollapse = (
    passageId: number | undefined,
    viewAll: boolean,
    domainId: number
  ) => {
    if (passageId && expandedPassagesIds && !viewAll) {
      const isPassageExpanded: boolean = expandedPassagesIds.some(r => r === passageId);
      if (isPassageExpanded) {
        const newExpandedPassageIds: Array<number> = expandedPassagesIds.filter(r => r !== passageId);
        const filteredQuestions: Array<IAssessmentQuestion> = passageQuestions.filter(r => r.passageId !== passageId);
        setExpandedPassagesIds(newExpandedPassageIds);
        setPassageQuestions(filteredQuestions);
        return;
      }
    }

    const isPassageCollapsed = expandedPassagesIds && !expandedPassagesIds?.some(r => r === passageId)
    if (passageId && isPassageCollapsed) {
      const newExpandedPassageIds: Array<number> = [...expandedPassagesIds, passageId];
      setExpandedPassagesIds(newExpandedPassageIds);
    }

    const selectedStandardIds = selectedStandards.map(standard => standard.value);

    const existingSelectedQuestions: Array<IAssessmentQuestion> =
      allQuestions.filter(r =>
        r.assessmentQuestionTypeId ===
          Constant.AssessmentQuestionType.NonPassageQuestions ||
        r.assessmentQuestionTypeId ===
          Constant.AssessmentQuestionType.SystemGenerated
      );

    const questionSearch: IQuestionSearch = {
      userId: userId,
      passageId: passageId,
      viewAll: selectedStandardIds.length === 0 || viewAll,
      standardIds: selectedStandardIds.join(","),
      questionTypeIds: selectedQuestionCategoryIds?.join(","),
      subjectId: subjectId,
      excludedQuestions: existingSelectedQuestions.map(s => s.questionId),
      includeConstructedResponseQuestionTypes: true
    };

    setShowLoading(true);
    getPassageQuestions(questionSearch).then(response => {
      setShowLoading(false);
      const currentPassageQuestions: Array<IAssessmentQuestion> =
        response.data.data;
      const otherQuestions: Array<IAssessmentQuestion> =
        passageQuestions.filter((r) => r.passageId !== passageId);
      const updatedQuestions: Array<IAssessmentQuestion> = [
        ...currentPassageQuestions,
        ...otherQuestions,
      ];

      const isFavouriteSortApplied: boolean = selectedSortOption.some(
        (r) =>
          r.domainId === domainId &&
          (r.selectedValue.label as SortByOption) === "Favorites"
      );

      if (isFavouriteSortApplied) {
        updatedQuestions.forEach((question) => {
          question.isFavorite = favoriteQuestionsIds.some(
            (q) => q === question.questionId
          );
        });

        const sortedQuestions: Array<IAssessmentQuestion> = arraySort(
          updatedQuestions,
          "isFavorite",
          {
            reverse: true,
          }
        );

        setPassageQuestions(sortedQuestions);
      } else {
        setPassageQuestions(updatedQuestions);
      }
    });
  }

  const getSortbyType = (value: string) => {
    switch (value as SortByOption) {
      case "Alphabetical":
        return "title";
      case "Question Count":
        return "questionCount";
      case "Word Count":
        return "wordsCount";
      case "Lexile Level":
        return "lexileLevel";
      default:
        return "";
    }
  }

  const getSortedPassages = (domainId: number) => {
    let filteredPassags: Array<IAssessmentPassage> = passages.filter(
      (r) => r.domainId === domainId
    );

    const isSortApplied: boolean = selectedSortOption.some(
      (r) => r.domainId === domainId
    );

    if (isSortApplied) {
      const selectedSortType = selectedSortOption.find(
        (r) => r.domainId === domainId
      );
      if (selectedSortType) {
        const sortedPassages: Array<IAssessmentPassage> = arraySort(
          filteredPassags,
          getSortbyType(selectedSortType.selectedValue.label),
          {
            reverse: selectedSortType.selectedValue.label
              .toLowerCase()
              .includes("alphabetical")
              ? false
              : true,
          }
        );
        filteredPassags = sortedPassages;
      }
    }

    return filteredPassags;
  }

  const getRowsCount = (passageId: number) => {
    const rowsCount: number = displayRows?.length > 0
      ? parseInt(displayRows.find(r => r.key === passageId.toString())?.value ?? "10")
      : 10
    return rowsCount;
  }

  const getSortedPassageQuestions = (passageId: number, domainId: number) => {
    const updatedQuestions: Array<IAssessmentQuestion> = passageQuestions
      .filter((r) => r.passageId === passageId)
      .slice(0, getRowsCount(passageId));

    const isFavouriteSortApplied: boolean = selectedSortOption.some(
      (r) =>
        r.domainId === domainId &&
        (r.selectedValue.label as SortByOption) === "Favorites"
    );

    if (isFavouriteSortApplied) {
      updatedQuestions.map((question) => {
        question.isFavorite = favoriteQuestionsIds.some(
          (q) => q === question.questionId
        );
        return [];
      });

      const sortedQuestions: Array<IAssessmentQuestion> = arraySort(
        updatedQuestions,
        "isFavorite",
        {
          reverse: true,
        }
      );

      return getHtmlParsedQuestions(sortedQuestions);
    } else {
      return getHtmlParsedQuestions(updatedQuestions);
    }
  }

  const handleQuestionsSortBy = (e: any, domainIdToSort: string) => {
    const newSortOption: {
      domainId: number;
      selectedValue: ISelectElement;
    } = { domainId: parseInt(domainIdToSort), selectedValue: e };

    const updatedSortBy: Array<{
      domainId: number,
      selectedValue: ISelectElement
    }> = 
      selectedSortOption.some(option => option.domainId === parseInt(domainIdToSort))
      ? [...selectedSortOption.filter(option => option.domainId !== parseInt(domainIdToSort)), newSortOption]
      : [...selectedSortOption, newSortOption];

    setSelectedSortOption(updatedSortBy);
  }

  const handleFavoriteQuestion = (questionId: number) => {
    const questionExist = favoriteQuestionsIds.some((r) => r === questionId);
    const updatedQuestionsIds: Array<number> = questionExist
      ? favoriteQuestionsIds.filter(r => r !== questionId)
      : [...favoriteQuestionsIds, questionId];

    setFavoriteQuestionsIds(updatedQuestionsIds);

    updateFavoriteQuestion(userId, questionId);
  }

  const addQuestion = (questionId: number) => {
    const selectedQuestion = passageQuestions.find(r => r.questionId === questionId);
    if (selectedQuestion) {
      selectedQuestion.assessmentQuestionTypeId = Constant.AssessmentQuestionType.PassageQuestions;
      updateQuestion(selectedQuestion);
    }
  }

  const handlePreviousPassage = () => {
    if (questionPreviewDetail) {
      if (questionPreviewDetail.currentIndex === 0) {
        return false;
      }

      let passages: Array<IAssessmentPassage> = [];

      selectedDomains.map((selectedDomain) => {
        passages = [...getSortedPassages(parseInt(selectedDomain.value))];
        return null;
      });

      if (passages.length > 0) {
        const previousPassage =
          passages[questionPreviewDetail.currentIndex - 1];
        setQuestionPreviewDetail({
          questionReference: `${previousPassage.learnosityReferenceId}`,
          questionId: previousPassage.passageId ? previousPassage.passageId : 0,
          questionText: previousPassage.title ? previousPassage.title : "",
          currentIndex: questionPreviewDetail.currentIndex - 1,
          isPassage: true,
          standardId: 0,
          spanishQuestionReference:
            previousPassage.learnosityReferenceId?.replace("en", "es") ?? "",
        });
      }
    }
  }

  const handleNextPassage = () => {
    if (questionPreviewDetail) {
      let passages: Array<IAssessmentPassage> = [];

      selectedDomains.map((selectedDomain) => {
        passages = [...getSortedPassages(parseInt(selectedDomain.value))];
        return null;
      });

      if (passages.length > 0) {
        if (questionPreviewDetail.currentIndex === passages.length - 1) {
          return false;
        }

        const previousPassage =
          passages[questionPreviewDetail.currentIndex + 1];
        setQuestionPreviewDetail({
          questionReference: `${previousPassage.learnosityReferenceId}`,
          questionId: previousPassage.passageId ? previousPassage.passageId : 0,
          questionText: previousPassage.title ? previousPassage.title : "",
          currentIndex: questionPreviewDetail.currentIndex + 1,
          isPassage: true,
          standardId: 0,
          spanishQuestionReference:
            previousPassage.learnosityReferenceId?.replace("en", "es") ?? "",
        });
      }
    }
  }

  const handlePreviousQuestion = () => {
    if (questionPreviewDetail) {
      const nextQuestion = passageQuestions[questionPreviewDetail?.currentIndex - 1];
      if (nextQuestion && nextQuestion.questionId) {
        setQuestionPreviewDetail({
          questionReference: `${
            spanishVersion && nextQuestion.spanishLearnosityReferenceId
              ? nextQuestion.spanishLearnosityReferenceId
              : nextQuestion.learnosityReferenceId
          }`,
          questionId: nextQuestion.questionId,
          questionText: nextQuestion.questionText
            ? nextQuestion.questionText
            : "",
          currentIndex: questionPreviewDetail.currentIndex - 1,
          isPassage: false,
          standardId: parseInt(nextQuestion?.standardId ?? "0"),
          spanishQuestionReference:
            nextQuestion?.spanishLearnosityReferenceId ?? "",
          domainId: parseInt(nextQuestion.domainId ?? "0"),
          passageId: questionPreviewDetail.passageId,
        });
      }
    }
  }

  const handleNextQuestion = () => {
    if (questionPreviewDetail) {
      const nextQuestion = passageQuestions[questionPreviewDetail?.currentIndex + 1];
      if (nextQuestion && nextQuestion.questionId) {
        setQuestionPreviewDetail({
          questionReference: nextQuestion.learnosityReferenceId
            ? nextQuestion?.learnosityReferenceId
            : "",
          questionId: nextQuestion.questionId,
          questionText: nextQuestion.questionText
            ? nextQuestion.questionText
            : "",
          currentIndex: questionPreviewDetail.currentIndex + 1,
          isPassage: false,
          standardId: parseInt(nextQuestion?.standardId ?? "0"),
          spanishQuestionReference:
            nextQuestion?.spanishLearnosityReferenceId ?? "",
          domainId: parseInt(nextQuestion?.domainId ?? "0"),
          passageId: questionPreviewDetail.passageId,
        });
      }
    }
  }

  const showSpanishVersion = (e) => {
    setSpanishVersion(e.target.checked);
  }

  const toggleFeedback = (popupAction: boolean) => {
    setShowFeedbackPopup(popupAction);
  }

  const handleFeedbackPopup = (id: number, standardList: Array<Standard>) => {
    setFeedbackQuestionId(id);
    setFeedbackStandardId(
      standardList.length > 0 ? parseInt(standardList[0].id) : undefined
    );
    toggleFeedback(true);
  }

  const openQuestionPreview = (
    learnosityReferenceId,
    questionId,
    questionText,
    currentIndex,
    isPassgae: boolean,
    standardId,
    spanishLearnosityReferenceId,
    domainId,
    passageId
  ) => {
    setShowQuestionPreview(true);
    setQuestionPreviewDetail({
      questionReference:
        spanishVersion && spanishLearnosityReferenceId
          ? spanishLearnosityReferenceId
          : learnosityReferenceId,
      questionId: questionId,
      questionText: questionText,
      currentIndex: currentIndex,
      isPassage: isPassgae,
      standardId: standardId,
      spanishQuestionReference: spanishLearnosityReferenceId,
      domainId: domainId,
      passageId: passageId,
    });
  };

  const closeQuestionPreview = (value) => {
    setShowQuestionPreview(value);
    setQuestionPreviewDetail(undefined);
  };

  const bindFavoriteQuestions = () => {
    setShowLoading(true);
    getFavoriteQuestions(userId).then((response) => {
      setFavoriteQuestionsIds(response.data);
      setShowLoading(false);
    });
  }

  const areStandardsAndPassagesSelected: boolean = true; //passages?.length > 0;

  return (
    <Fragment>
      {showLoading && <Loader></Loader>}
      <QuestionPreview
        key={
          spanishVersion && questionPreviewDetail?.spanishQuestionReference
            ? questionPreviewDetail?.spanishQuestionReference
            : questionPreviewDetail?.questionReference
        }
        modal={openQuestionPreview}
        show={showQuestionPreview}
        changeValue={closeQuestionPreview}
        itemRef={
          spanishVersion && questionPreviewDetail?.spanishQuestionReference
            ? questionPreviewDetail?.spanishQuestionReference
            : questionPreviewDetail?.questionReference
        }
        questionIdPreview={questionPreviewDetail?.questionId}
        activityName={questionPreviewDetail?.questionText}
        addQuestionClick={
          questionPreviewDetail?.isPassage === false ? addQuestion : undefined
        }
        nextQuestionClick={
          questionPreviewDetail?.isPassage === false
            ? handleNextQuestion
            : undefined
        }
        previousQuestionClick={
          questionPreviewDetail?.isPassage === false
            ? handlePreviousQuestion
            : undefined
        }
        nextPassageClick={
          questionPreviewDetail?.isPassage === true
            ? handleNextPassage
            : undefined
        }
        previousPassageClick={
          questionPreviewDetail?.isPassage === true
            ? handlePreviousPassage
            : undefined
        }
        isQuestionAdded={selectedQuestions.some(
          (r) => r.questionId === questionPreviewDetail?.questionId
        )}
        currentQuestionIndex={
          questionPreviewDetail?.isPassage
            ? null
            : questionPreviewDetail?.currentIndex
        }
        totalQuestions={
          questionPreviewDetail?.isPassage
            ? null
            : getSortedPassageQuestions(
                questionPreviewDetail?.passageId ?? 0,
                questionPreviewDetail?.domainId ?? 0
              ).length
        }
        standardId={questionPreviewDetail?.standardId}
        showSpanishVersion={showSpanishVersion}
        spanishVersion={spanishVersion}
      ></QuestionPreview>
      {showFeedbackPopup && (
        <Feedback
          toggleFeedback={toggleFeedback}
          referenceType={Constant.FeedbackReferenceType.QUESTION}
          referenceId={feedbackQuestionId}
          subjectId={subjectId}
          standardId={feedbackStandardId}
        />
      )}
      {errorMessagePopup && (
        <MessagePopup
          message={
            Constant.AssessmentWarningMessage.AssessmentQuestionMultiplePassages
          }
          togglePopup={() => setErrorMessagePopup(!errorMessagePopup)}
        />
      )}
      {subjectId > 0 && (
        <div
          className={`col-span-10 lg:col-span-9 xl:col-span-10 ${
          allowEdit ? "" : "pointer-events-none opacity-50"
        }`}
        >
          <div className={`bg-gray-100 w-full rounded-lg p-5 h-full ${areStandardsAndPassagesSelected ? '' : 'mb-[250px]'}`}>
            <PassageQuestionFilters 
              subjectId={subjectId}
              selectedQuestions={selectedQuestions}
              selectedStandards={selectedStandards}
              setSelectedStandards={setSelectedStandards}
              selectedDomains={selectedDomains}
              searchPassages={searchPassages}
              setPassages={setPassages}
              selectedQuestionCategoryIds={selectedQuestionCategoryIds}
              setSelectedQuestionCategoryIds={setSelectedQuestionCategoryIds}
              selectedCategory={selectedCategory}
              setSelectedCategory={setSelectedCategory}
              setExpandedPassagesIds={setExpandedPassagesIds}
              updateStandardsAndQuestions={updateStandardsAndQuestions}
              bindFavoriteQuestions={bindFavoriteQuestions}
              setDisplayRows={setDisplayRows}
              keywordTags={keywordTags}
              setKeywordTags={setKeywordTags}
              matchAnyTag={matchAnyTag}
              setMatchAnyTag={setMatchAnyTag}
              setShowLoading={setShowLoading} 
              setSelectedDomains={setSelectedDomains}  
            />
            <div
              className={`${
                passages.length > 0
                  ? "bg-white py-5 px-5 w-full shadow hover:shadow-lg mt-3"
                  : ""
              } `}
            >
              {areStandardsAndPassagesSelected && (
                <PassageQuestionPassages 
                  districtId={districtId}
                  hasDistrictAssessmentQuestionIcon={hasDistrictAssessmentQuestionIcon}
                  passages={passages}
                  passageQuestions={passageQuestions}
                  displayRows={displayRows}
                  setDisplayRows={setDisplayRows}
                  selectedDomains={selectedDomains}
                  selectedQuestions={selectedQuestions}
                  updateQuestion={updateQuestion}
                  multiPassageQuestions={multiPassageQuestions}
                  expandedPassagesIds={expandedPassagesIds}
                  selectedSortOption={selectedSortOption}
                  favoriteQuestionsIds={favoriteQuestionsIds}
                  handleQuestionsSortBy={handleQuestionsSortBy}
                  getSortedPassages={getSortedPassages}
                  getSortedPassageQuestions={getSortedPassageQuestions}
                  handleFavoriteQuestion={handleFavoriteQuestion}
                  handleFeedbackPopup={handleFeedbackPopup}
                  handlePassageQuestionsCollapse={handlePassageQuestionsCollapse}
                  openQuestionPreview={openQuestionPreview}
                  errorMessagePopup={errorMessagePopup}
                  setErrorMessagePopup={setErrorMessagePopup}
                />
              )}
            </div>
          </div>
        </div>
        )}
    </Fragment>
  );
}

export default PassageQuestion;

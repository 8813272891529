import React, { Fragment, useEffect, useState } from "react";
import Header from "../shared/header/header";
import { fetchData } from "../../api/manageUserApi";
import { connect } from "react-redux";
import { UserContextState } from "../../redux/actions/userContextAction";
import ManageUser from "../../model/manageUser";
import ManageUserCard from "./manageUserCard";
import Profile from "../../model/users/profile";

interface passedProps {
  userContext: UserContextState;
  profile: Profile;
}

function ManageUsers(props: passedProps) {
  const usaTestPrepDisabledLink: Array<string> = [
    "View Students",
    "Program Selection",
    "Diagnostics",
    "Skill Practice",
    "Google Classroom",
    "Canvas Mapping",
    "NWEA Mapping",
    "EOC Subjects",
  ];

  const defaultManageUserOptions: Array<ManageUser> = [];

  const [manageUserOptions, setManageUserOptions] = useState(
    defaultManageUserOptions
  );

  function setOptions(data: any) {
    if (props.profile.isUSATESTEnabled) {
      var tempdata: any = [];
      for (let index = 0; index < data.length; index++) {
        if (usaTestPrepDisabledLink.indexOf(data[index].heading) === -1) {
          tempdata.push(data[index]);
        }
      }
      setManageUserOptions(tempdata);
    } else {
      setManageUserOptions(data);
    }
  }

  useEffect(() => {
    fetchData(props.userContext.userId, props.userContext.roleId)?.then((r) =>
      setOptions(r.data)
    );
  }, []);

  return (
    <Fragment>
      <Header></Header>
      <div className="px-4 lg:px-8 mt-3 pt-6 xl:w-4/5 2xl:w-4/5 m-auto">
        <h2 className="text-2xl text-primary-green font-semibold">
          Manage Users
        </h2>
        <div className="flex flex-wrap mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
          {manageUserOptions &&
            manageUserOptions.map((option) => {
              return (
                <Fragment>
                  <ManageUserCard
                    title={option.heading}
                    description={option.description}
                    url={option.url}
                    css={option.css}
                    isCanvasMapped={props.profile.isCanvasMapped}
                  ></ManageUserCard>
                </Fragment>
              );
            })}
        </div>
      </div>
    </Fragment>
  );
}

const mapStateToProps = (state: any) => {
  return {
    profile: state.profile,
    userContext: state.userContext,
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(ManageUsers);

import moment from "moment";
import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import {
  GoldCoinIcon,
  ResultCoinIcon,
  StarGradient,
} from "../../../assets/icons/index";

export default function OverviewResult({ inputData }) {
  const { t } = useTranslation();
  const tokensEarned = inputData?.results_header?.assignment?.tokens_earned;
  const tokenImageCount = tokensEarned <= 4 ? tokensEarned : 4;

  function needsGrading(): boolean {
    return (
      inputData?.results_header?.assignment?.result?.unmarked
    );
  }

  return (
    <div className="bg-blue-900 flex-none w-64 h-auto bg-gradient-to-b from-bright-blue via-bright-blue to-grad-blue rounded-md shadow-md xl:mr-12 mr-5">
      <div className="text-md text-white text-center px-12 md:px-5 ">
        <h2 className="text-sm text-white font-medium uppercase text-center my-4">
          Score
        </h2>
        {needsGrading() ? (
          <div
            style={{
              width: 160,
              height: 160,
              display: "inline-grid",
              fontSize: "17px;",
            }}
          >
            <CircularProgressbar
              value={0}
              text={`${
                inputData?.results_header?.assignment?.result?.retry_percent ||
                inputData?.results_header?.assignment?.result?.percent
              }`}
              styles={buildStyles({
                pathColor: `${inputData?.results_header?.assignment?.result?.dot_rank_color}`,
                textColor: "#ffffff",
              })}
            />
          </div>
        ) : (
          <div
            style={{
              width: 140,
              height: 140,
              display: "inline-grid",
              position: "relative",
            }}
          >
            <CircularProgressbar
              value={
                inputData?.results_header?.assignment?.result?.retry_percent ||
                inputData?.results_header?.assignment?.result?.percent
              }
              text={`${
                inputData?.results_header?.assignment?.result?.retry_percent ||
                inputData?.results_header?.assignment?.result?.percent
              }%`}
              styles={buildStyles({
                pathColor: `${inputData?.results_header?.assignment?.result?.dot_rank_color}`,
                textColor: "#ffffff",
              })}
              className="circular-bar-pending-size"
            />
            <span className="absolute top-1/2 left-1/2 transform -translate-x-2/4 -translate-y-2/4 pt-11">
              (2/3)
            </span>
          </div>
        )}
      </div>
      {<div className="w-full text-lg text-white text-center mb-4"></div>}
      {/* <div className="w-full text-lg text-white text-center mb-4">72</div> */}
      {tokensEarned && (
        <div className="w-full bg-dark-grey py-5 text-center">
          <h2 className="text-white text-lg font-bold">
            {tokensEarned}
            <span className="text-sm font-medium pl-2">Tokens earned</span>
          </h2>
          <div className="relative inline-block w-20 h-8 mt-1">
            {[...Array(tokenImageCount)].map((token, tokenIndex) => (
              <span
                className={`inline-block absolute left-${tokenIndex * 4}`}
                key={tokenIndex}
              >
                <Fragment>
                  <ResultCoinIcon />
                </Fragment>
              </span>
            ))}
          </div>
        </div>
      )}
      <div className="flex flex-none flex-col bg-white px-7 py-8 -mx-2 rounded-md shadow-md mb-4">
        <div className="flex w-full flex-none justify-between">
          <div className="capitalize font-myriad font-thin text-md">Score</div>

          <div className="h-6 ml-3 inline-block text-black-333 flex items-center px-2 font-roboto text-sm font-bold">
            25%
            <span className="text-sm pl-1">(3/12)</span>
          </div>
        </div>
        <div className="w-full block mt-2 relative">
          <div className="w-full h-2 bg-gray-300 rounded-md"></div>
          <div
            style={{
              width: `${inputData?.results_header?.assignment?.result.percent}%`,
            }}
            className="h-2 bg-[#BB0070] rounded-md absolute top-0 z-0"
          ></div>
        </div>
        <div className="flex w-full flex-none justify-between mt-5">
          <div className="capitalize font-myriad font-thin text-md">
            Retry score
          </div>

          <div className=" h-6 ml-3 inline-block text-black-333 flex items-center px-2 font-roboto text-sm font-bold">
            40%
            <span className="text-sm pl-1">(5/12)</span>
          </div>
        </div>
        <div className="w-full block mt-2 relative">
          <div className="w-full h-2 bg-gray-300 rounded-md"></div>
          <div className="h-2 bg-[#BB0070] rounded-md absolute top-0 z-0 w-3/4"></div>
        </div>
      </div>

      <div className="flex flex-none flex-col px-7 py-8 -mx-2 rounded-md shadow-md mb-4 bg-gradient-to-b from-orange-dark to-white">
        <div className="flex flex-col w-full items-center ">
          <div className="capitalize font-myriad font-semibold text-lg text-gray-700 pb-2">
            Tokens Earned
          </div>
          <div className="relative w-24 inline-block pb-10">
            <span className="inline-block absolute left-0">
              <GoldCoinIcon className="w-9 h-9"></GoldCoinIcon>
            </span>
            <span className="inline-block absolute left-6">
              <GoldCoinIcon className="w-9 h-9"></GoldCoinIcon>
            </span>
            <span className="inline-block absolute left-12">
              <GoldCoinIcon className="w-9 h-9"></GoldCoinIcon>
            </span>
          </div>
        </div>
      </div>
      <div className="flex flex-none flex-col px-7 py-8 -mx-2 rounded-md shadow-md mb-4 bg-gradient-to-b from-orange-dark to-white">
        <div className="flex flex-col w-full items-center ">
          <div className="capitalize font-myriad font-semibold text-lg text-gray-700">
            Galaxy Stars Earned
          </div>
          <span className="flex space-x-1 mt-1">
            <StarGradient className="w-10" />
            <StarGradient className="w-10" />
            <StarGradient className="w-10" />
          </span>
        </div>
      </div>

      <div className="m-10 flex flex-col flex-none mx-7 text-white border-white border-b border-opacity-25 pb-1 mb-2">
        <div className="text-xs text-light-teal capitalize">Date Completed</div>
        <div className="text-light-teal font-roboto font-bold capitalize">
          2021/12/30
        </div>
      </div>
      <div className="flex flex-col flex-none mx-7 text-white border-white border-b border-opacity-25 pb-1 mb-3">
        <div className="text-xs text-light-teal capitalize">Elapsed Time</div>
        <div className="text-light-teal font-roboto font-bold">
          {moment()
            .startOf("day")
            .add(inputData?.results_header?.elapsed_time, "seconds")
            .format("HH:mm:ss")}
        </div>
      </div>
      <div className="flex flex-col flex-none mx-7 text-white border-white border-b border-opacity-25 pb-1 mb-3">
        <div className="text-xs text-yellow-300 capitalize relative">
          due date
        </div>
        <div className="text-yellow-300 font-roboto font-bold">2021/12/30</div>
      </div>

      <div className="flex flex-col flex-none mx-7 text-white border-white border-b border-opacity-25 pb-1 mb-3">
        <div className="text-xs text-light-teal capitalize">{t("StudyPlanResult.Subject")}</div>
        <div className="text-light-teal text-md font-roboto font-bold capitalize">
          {inputData?.results_header?.test
            ? `${inputData?.results_header?.test.name}`
            : "-"}{" "}
        </div>
      </div>
      <div className="flex flex-col flex-none mx-7 text-white border-white border-b border-opacity-25 pb-1 mb-3">
        <div className="text-xs text-light-teal capitalize">{t("StudyPlanResult.Teacher")}</div>
        <div className="text-light-teal text-md font-roboto font-bold capitalize">
          {Object.keys(inputData?.results_header?.teacher).length > 0 &&
            `${inputData?.results_header?.teacher?.first} ${inputData?.results_header?.teacher?.last}`}
          {Object.keys(inputData?.results_header?.teacher).length === 0 && (
            <div className="text-xs font-semibold capitalize cursor-pointer text-white">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 stroke-current text-white inline"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                />
              </svg>
              {""} Add Teacher
            </div>
          )}
        </div>
      </div>
      <div className="flex flex-col flex-none mx-7 text-white border-white border-b border-opacity-25 pb-1 mb-3">
        <div className="text-xs text-light-teal capitalize">{t("StudyPlanResult.Class")}</div>
        <div className="text-light-teal text-md font-roboto font-bold capitalize">
          {inputData?.results_header?.class &&
          Object.keys(inputData?.results_header?.class).length > 0
            ? `${inputData?.results_header?.class.name}`
            : "-"}{" "}
        </div>
      </div>
      <div className="flex flex-col bg-gradient-to-b from-orange-dark to-orange-light rounded-md flex-none mx-7 text-white py-1.5 px-1.5 mb-3">
        <div className="text-xs text-gray-900 capitalize">Game Score</div>
        <div className="text-md font-roboto font-bold capitalize text-gray-900">
          1025
        </div>
      </div>
      <div className="flex flex-col bg-gradient-to-b from-orange-dark to-orange-light rounded-md flex-none mx-7 text-white py-1.5 px-1.5 mb-3">
        <div className="text-xs text-gray-900 capitalize">Game Name</div>
        <div className="text-md font-roboto font-bold capitalize text-gray-900">
          WormHole
        </div>
      </div>
    </div>
  );
}

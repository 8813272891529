import { Fragment, useEffect, useState } from "react";
import Chart from "react-google-charts";
import { connect } from "react-redux";
import {
  getAllDomainsBySubject,
  getStandardsByDomainId,
} from "../../../../api/domainApi";
import { getWorksheetsBySubject } from "../../../../api/teacher/classActivity";
import { getSuperSheets } from "../../../../api/teacher/superSheet";
import { ISuperSheetSearch } from "../../../../model/interface/superSheetSearch";
import { getRandomColor } from "../../../../utils/helper";
import Loader from "../../../shared/loader";
import ReactTooltip from "react-tooltip";
interface domainStandardListProps {
  subjectId: number;
  userContext: any;
}

function DomainStandardList(props: domainStandardListProps) {
  const [allStandards, setAllStandards] = useState<any[]>([]);
  const [newDomain, setNewDomain] = useState<any[]>([]);
  const [domainPercentages, setDomainPercentages] = useState<Array<any>>([]);
  const [randomColors, setRandomColors] = useState<Array<string>>([]);
  const [showLoader, setShowLoader] = useState(false);
  const [totalTeiQuestionsCount, setTotalTeiQuestionsCount] = useState<number>(0);
  const [totalQuestionsCount, setTotalQuestionsCount] = useState<number>(0);
  const [totalVideosCount, setTotalVideosCount] = useState<number>(0);
  const [totalSupersheetCount, setTotalSupersheetCount] = useState<number>(0);
  const [totalWorksheetCount, setTotalWorksheetCount] = useState<number>(0);
  const options = {
    title: "",
    colors: randomColors,
    legend: {
      position: "right",
      alignment: "center",
      textStyle: {
        fontSize: 12,
      },
    },
    tooltip: { text: "none" },
  };

  useEffect(() => {
    const syncCalls = async () => {
      setShowLoader(true);
      const domainsData: any = await bindDomain();

      //let domainData: Array<[]> = [];
      let chartData: any = [["Domains", "Domains Percentage"]];
      let colors: Array<string> = [];
      domainsData.map((values: any) => {
        chartData.push([
          `${values?.name}   ${values?.testPercentage}%`,
          values?.testPercentage,
        ]);
        colors.push(getRandomColor());
        return null;
      });
      //setDomains(domainsData);
      setDomainPercentages(chartData);
      setRandomColors(colors);
      // setDomainDataValues(domainData.slice(0, 10));
      bindAllStands(domainsData);
    };
    syncCalls();
  }, []);

  useEffect(() => {
    bindSupersheetCount();
    bindWorksheetCount();
  }, []);

  const bindDomain = () => {
    return new Promise((resolve) => {
      if (props.subjectId) {
        getAllDomainsBySubject(props.subjectId).then((response: any) => {
          setNewDomain(response.data.data);
          resolve(response.data.data);
        });
      }
    });
  };

  const bindAllStands = (domainsData?) => {
    const domains = domainsData && domainsData.length ? domainsData : [];
    const promises: any = [];
    setAllStandards([]);
    for (let i = 0; i < domains.length; i++) {
      promises.push(setStandardsData(domains[i].domainId));
    }
    Promise.all(promises).then((result: any) => {
      const merged = [].concat([], ...result);
      const totalTeiQuestions = merged
          .map((standard: any) => standard.totalTeiQuestions)
          .reduce((totalTeiQuestions, questions) => totalTeiQuestions + questions);
      const totalQuestions = merged
        .map((stand: any) => stand?.totalQuestions)
        .reduce((totalQuestions, questions) => totalQuestions + questions);
      const totalVideos = merged
        .map((stand: any) => stand?.totalVideos)
        .reduce((totalVideos, videos) => totalVideos + videos);
      setTotalTeiQuestionsCount(totalTeiQuestions);
      setTotalQuestionsCount(totalQuestions);
      setTotalVideosCount(totalVideos);

      setAllStandards(merged);
      setShowLoader(false);
    });
  };

  const bindSupersheetCount = () => {
    setTotalSupersheetCount(0);
    const superSheetSearch: ISuperSheetSearch = {
      subjectId: props.subjectId ?? 0,
      teacherId: props.userContext.userId,
    };
    getSuperSheets(superSheetSearch).then((r) => {
      if (r.data) setTotalSupersheetCount(r.data.totalRecords);
    });
  };

  const bindWorksheetCount = () => {
    setTotalWorksheetCount(0);
    getWorksheetsBySubject(props.subjectId ?? 0, "").then((res) => {
      let wksCount: number = 0;
      if (res.data) {
        res.data.forEach((domain) => {
          wksCount = wksCount + domain.worksheets.length ?? 0;
        });
        setTotalWorksheetCount(wksCount);
      }
    });
  };

  const setStandardsData = async (domainId: number) => {
    return new Promise((resolve) => {
      getStandardsByDomainId(props.subjectId, domainId).then(
        (response: any) => {
          resolve(response.data.data);
        }
      );
    });
  };
  return (
    <Fragment>
      {showLoader && <Loader></Loader>}
      <ReactTooltip
        multiline={true}
        className="bg-secondary-teal"
        backgroundColor="#008fbb"
      />
      <h3 className="text-gray-700 text-sm font-semibold border-b border-gray-300 pb-1 my-6 xl:mb-6 xl:mt-0 ">
        Standards
      </h3>
      <div className="flex flex-col md:space-x-6 md:flex-row">
        <div className="w-full md:w-2/3 xl:w-2/3 xxxl:w-2/3 rounded-lg ">
          <Chart
            chartType="PieChart"
            data={domainPercentages}
            options={options}
            width={"100%"}
          />
        </div>
        <div className="w-full mt-4 flex md:mt-0 md:w-1/3 xl:w-1/3 xxxl:w-1/3">
          <div className="bg-white px-3 pt-2 pb-3 w-full">
            <h3 className="text-gray-700 text-sm font-semibold mb-2">
              Item Counts for This Subject
            </h3>
            <ul className="list-disc list-outside pl-4 text-xs text-gray-500">
              {totalQuestionsCount > 0 && (
                <li>
                  Questions:{" "}
                  <span className="text-gray-800">{totalQuestionsCount}</span>
                </li>
              )}
              {totalTeiQuestionsCount > 0 && (
                <li>
                  Technology Enhanced Items:{" "}
                  <span className="text-gray-800">{totalTeiQuestionsCount}</span>
                </li>
              )}
              {totalSupersheetCount > 0 && (
              <li>
                Supersheets:{" "}
                <span className="text-gray-800">{totalSupersheetCount}</span>
              </li>
              )}
              {totalWorksheetCount > 0 && (
              <li>
                Worksheets:{" "}
                <span className="text-gray-800">{totalWorksheetCount}</span>
              </li>
              )}
              {totalVideosCount > 0 && (
                <li>
                  Instructional Videos:{" "}
                  <span className="text-gray-800">{totalVideosCount}</span>
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>

      <div className="mt-4">
        {newDomain
            .sort((a, b) => (a.loadOrder > b.loadOrder ? 1 : -1))
          .map((domain, domainIndex) => (
            <div key={domainIndex}>
              <h3 className="bg-primary-violet text-white px-2 my-2 mt-4 rounded-sm">
                {domain.name}
              </h3>

              {allStandards
                .sort((a, b) => (a.loadOrder > b.loadOrder ? 1 : -1))
                .filter((t) => t.domainId === domain.domainId)
                .map((stand, index) => (
                  <div key={index}>
                    <div
                      className={`flex items-center justify-between  ${
                        index % 2 === 0
                          ? "bg-white"
                          : "bg-primary-violet bg-opacity-10"
                      }`}
                    >
                      <div
                        className={`flex items-center pl-4 ${
                          stand.domainId === domain.domainId ? "" : "hidden"
                        }`}
                      >
                        <div
                          className="flex items-center ml-4 text-gray-700"
                          title={stand.description}
                        >
                          {`${stand.code} ${stand.name}`}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          ))}
      </div>
    </Fragment>
  );
}

export default connect((state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
})(DomainStandardList);

import { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { ClassApi } from "../../../../../api/teacher/classManagementApi";
import {
  RemoveIcon,
  StarDarkIcon,
  StarIcon,
} from "../../../../../assets/icons/index";
import { PageAccessMode } from "../../../../../model/common/pageAccessMode";
import PagingResponse from "../../../../../model/common/pagingResponse";
import ClassTeacherModel from "../../../../../model/teacher/classManagement/classTeacherModel";
import { UserContextState } from "../../../../../redux/actions/userContextAction";
import Constant from "../../../../../utils/constant/constant";
import RouteConstant from "../../../../../utils/constant/routeConstant";
import {
  DEFAULT_PAGE_NUMBER,
  DEFAULT_PAGE_SIZE_50,
} from "../../../../../utils/pagingConstant";
import ConfirmationDialog from "../../../../shared/confirmationDialog";
import Loader from "../../../../shared/loader";
import PageSize from "../../../../shared/pagination/pageSize";
import Pagination from "../../../../shared/pagination/pagination";
import TeacherPopup from "../classDetail/popups/teacherPopup";
import UserTile from "../../../../shared/UserTile";
import { jwtToken } from "../../../../shared/tokenHelper";
import { getClassManagementUpgradePermission } from "../../../../shared/permissionHelper";
import constant from "../../../../../utils/constant/constant";
import UpGradeMessagePopup from "../../../../shared/upGradeMessagePopup";

interface ClassTeacherProps {
  classId: number;
  className: string;
  schoolAccountId: number;
  onlineUsers: Array<any>;
  mode: PageAccessMode;
  setTeacherCount: (e) => void;
  userContext?: UserContextState;
  classExternalRoster: Array<any>;
  setPrimaryTeacher: (teacher: ClassTeacherModel) => void;
  primaryTeacher: ClassTeacherModel | undefined;
  isEditingEnabled: boolean;
}

const ClassTeacher = (props: ClassTeacherProps) => {
  const [showTeacherPopup, setShowTeacherPopup] = useState(false);
  const [classTeachers, setClassTeacher] =
    useState<PagingResponse<ClassTeacherModel>>();
  const [pageNumber, setPageNumber] = useState(DEFAULT_PAGE_NUMBER);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE_50);
  const [classTeacherToDelete, setClassTeacherToDelete] = useState<number>(0);
  const [confirmationType, setConfirmationType] = useState<ConfrimationType>();
  const [newPrimaryTeacher, setNewPrimaryTeacher] = useState<number>(0);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [confirmationTitle, setConfirmationTitle] = useState("Confirm Action");
  const [confirmationMessage, setConfirmationMessage] =
    useState("Please confirm");
  const [resetPageNumber, setResetPageNumber] = useState<boolean>(false);
  const [loading, showLoading] = useState(false);
  const history = useHistory();
  const impersonatedUser: any = props.userContext?.impersonatedUser?.token
    ? jwtToken(props.userContext?.impersonatedUser?.token)
    : null;
  const [upgrade, setUpgrade] = useState(false);
  const [showUpgradePopup, setShowUpgradePopup] = useState<boolean>(false);

  enum ConfrimationType {
    DeleteClassUserConfiramtion,
    PrimaryTeacherChange,
  }

  const checkUpgradeRequired = async () => {
    if (getClassManagementUpgradePermission(props.userContext?.roleId ?? 0)) {
      setUpgrade(true);
    } else {
      setUpgrade(false);
    }
  };

  useEffect(() => {
    checkUpgradeRequired();
    getPrimaryClassTeacher();
  }, []);

  useEffect(() => {
    getClassTeacher();
  }, [pageNumber, pageSize]);

  function searchClassTeachers(): void {
    setPageNumber(DEFAULT_PAGE_NUMBER);
    setPageSize(DEFAULT_PAGE_SIZE_50);
    getClassTeacher();
  }

  function getClassTeacher(): void {
    showLoading(true);
    ClassApi.getClassTeacher(props.classId, pageNumber, pageSize)
      .then((d) => {
        setClassTeacher(d.data);
        props.setTeacherCount(d.data.totalRecords);
        showLoading(false);
      })
      .catch(() => {
        showLoading(false);
      });
  }

  function getPrimaryClassTeacher(): void {
    showLoading(true);
    ClassApi.getPrimaryClassTeacher(props.classId).then((d) => {
      showLoading(false);
      if (d.status !== 204) props.setPrimaryTeacher(d.data);
    });
  }

  function confirmationOkClose() {
    switch (confirmationType) {
      case ConfrimationType.DeleteClassUserConfiramtion:
        deleteClassTeacherConfirmed();
        break;
      case ConfrimationType.PrimaryTeacherChange:
        updateClassTeacherIsPrimaryConfirm();
        break;
    }
  }

  function deleteClassTeacher(user: ClassTeacherModel): void {
    setClassTeacherToDelete(user.userId);
    let msg: string = "";
    if (user.userId !== props.userContext?.userId) {
      msg =
        "You are about to remove <b>{teacherName}</b> from <b>{className}</b>. <br/><br/> Would you like to proceed?";
      msg = msg.replace("{teacherName}", user.lastName + ", " + user.firstName);
      msg = msg.replace("{className}", props.className);
    } else {
      msg =
        "You are about to remove yourself from class <b>{className}</b>. <br/><br/>Would you like to proceed?";
      msg = msg.replace("{className}", props.className);
    }
    showConfirmation(
      "Remove Class Teacher",
      msg,
      ConfrimationType.DeleteClassUserConfiramtion
    );
  }
  function showConfirmation(
    title: string,
    message: string,
    type: ConfrimationType
  ) {
    setOpenConfirmation(true);
    setConfirmationTitle(title);
    setConfirmationMessage(message);
    setConfirmationType(type);
  }

  function deleteClassTeacherConfirmed() {
    showLoading(true);
    ClassApi.deleteClassTeacher(props.userContext?.userId, props.classId, [
      classTeacherToDelete,
    ]).then(() => {
      const hasDeletedSelf = classTeacherToDelete === props.userContext?.userId;
      showLoading(false);
      if (!hasDeletedSelf) {
        searchClassTeachers();
      }
      confirmationCancelClose();
      toast.success("Teacher(s) deleted successfully.");

      if (hasDeletedSelf) {
        history.push({
          pathname: RouteConstant.TeacherRoutes.Classes,
        });
      }
    });
  }

  function confirmationCancelClose() {
    setOpenConfirmation(false);
  }

  function updateClassTeacherIsPrimary(teacher: ClassTeacherModel): void {
    if (
      props.primaryTeacher === undefined ||
      props.primaryTeacher?.userId === props.userContext?.userId ||
      props.userContext?.roleId === Constant.UserRoleId.SchoolAdmin
    ) {
      setNewPrimaryTeacher(teacher.userId);
      let msg =
        "You are about to transfer ownership of <b>{className}</b> to <b>{teacherName}</b>. Once changed, this action can only be reversed by the new primary teacher or admin. <br/><br/> Would you like to proceed?";
      msg = msg.replace(
        "{teacherName}",
        IsNullOrEmpty(teacher.lastName) +
          ", " +
          IsNullOrEmpty(teacher.firstName)
      );
      msg = msg.replace("{className}", props.className);

      showConfirmation(
        "Change Primary Teacher",
        msg,
        ConfrimationType.PrimaryTeacherChange
      );
    } else if (
      props.primaryTeacher !== undefined &&
      props.primaryTeacher?.userId !== props.userContext?.userId
    ) {
      let msg =
        "The primary teacher can only be changed by '{teacherName}' or your administrator.";
      msg = msg.replace(
        "{teacherName}",
        IsNullOrEmpty(props.primaryTeacher.lastName) +
          ", " +
          IsNullOrEmpty(props.primaryTeacher.firstName)
      );
      toast.info(msg);
    }
  }

  function IsNullOrEmpty(str: string): string {
    return str === null || str === undefined ? "" : str;
  }
  function updateClassTeacherIsPrimaryConfirm(): void {
    showLoading(true);
    ClassApi.updateClassTeacherIsPrimary(props.classId, newPrimaryTeacher).then(
      () => {
        showLoading(false);
        confirmationCancelClose();
        searchClassTeachers();
        getPrimaryClassTeacher();
        toast.success("Primary class teacher changed successfully");
      }
    );
  }
  const onPageSizeChange = (pageSize) => {
    setPageNumber(DEFAULT_PAGE_NUMBER);
    setPageSize(pageSize);
    setResetPageNumber(true);
  };

  return (
    <Fragment>
      <div className="justify-between mb-4 w-full relative ">
        {loading && <Loader />}
        {showUpgradePopup && (
          <UpGradeMessagePopup
            togglePopup={setShowUpgradePopup}
            roleId={props.userContext?.roleId ?? 0}
            message={constant.UPGRADE_MESSAGE_SCHOOL_ACCOUNT}
          />
        )}
        <div>
          <div className="flex items-end justify-between mb-4">
            <h1 className="font-bold text-primary-violet">Class Teachers</h1>
            <div className="flex items-center space-x-2">
              {(props.mode === PageAccessMode.Edit ||
                (impersonatedUser?.roleId
                  ? parseInt(impersonatedUser.roleId)
                  : 0) <= Constant.UserRoleId.SchoolAdmin) && (
                <button
                  type="button"
                  className={
                    (upgrade ? "opacity-50 " : "") +
                    (props.isEditingEnabled
                      ? "bg-primary-violet hover:bg-dark-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3 transition ease-in-out duration-500 text-white" 
                      : "cursor-not-allowed text-gray-400 border-gray-300 bg-gray-300")
                    + " disabled:opacity-50 shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium"
                  }
                  onClick={() => {
                    if (props.isEditingEnabled) {
                      if (upgrade) {
                        setShowUpgradePopup(true);
                      } else {
                        setShowTeacherPopup(true);
                      }
                    }
                  }}
                >
                  {" "}
                  Add Teacher
                </button>
              )}
            </div>
          </div>
          <div className="overflow-x-auto shadow">
            <table className="table w-full overflow-auto">
              <thead className="mb-5">
                <tr className="bg-light-violet  text-white py-3 px-3 overflow-hidden">
                  <th className="text-left  p-3 text-sm font-normal w-16"></th>
                  <th className="text-left  p-3 text-sm font-normal">
                    {" "}
                    Teacher{" "}
                  </th>
                  <th className="text-left  p-3 text-sm font-normal">
                    {" "}
                    Email{" "}
                  </th>
                  <th className=" text-center  p-3 text-sm font-normal">
                    {" "}
                    Primary{" "}
                  </th>
                  <th className="text-center  p-3 text-sm font-normal">
                    {" "}
                    Remove{" "}
                  </th>
                </tr>
              </thead>
              <tbody>
                {classTeachers?.data?.map((teacher, index) => {
                  return (
                    <tr
                      key={teacher.userId}
                      className={`hover:bg-light-violet/20 transition ease-in-out duration-300 ${
                        index % 2 === 0 ? "bg-white" : "bg-light-violet/10"
                      }`}
                    >
                      <td className=" mt-5 p-3 text-center">
                        <span className="relative">
                          <UserTile
                            userId={teacher.userId}
                            onlineUsers={props.onlineUsers}
                            isLoggedInUser={false}
                          />
                        </span>
                      </td>
                      <td className="text-sm mt-5  p-3 text-left">
                        {teacher.lastName}, {teacher.firstName}
                      </td>
                      <td className="text-sm mt-5  p-3 text-left">
                        {teacher.email}
                      </td>
                      <td className="text-sm mt-5  p-3 text-center">
                      {teacher.isPrimary && (<span
                          title="Change Primary Teacher"
                          className={(props.isEditingEnabled ? "cursor-pointer" : "cursor-not-allowed") + " inline-block text-yellow-500"}
                        >
                          {" "}
                          <StarDarkIcon className="w-4" />
                          {" "}
                        </span>)}
                        {!teacher.isPrimary && (<span
                          title="Change Primary Teacher"
                          className={(props.isEditingEnabled ? "cursor-pointer" : "cursor-not-allowed") + " inline-block text-black"}
                        >
                          {" "}
                          <StarIcon
                            className="w-4"
                            onClick={() => {
                              if (props.isEditingEnabled) {
                                if (
                                  props.mode === PageAccessMode.Edit ||
                                  (impersonatedUser?.roleId
                                    ? parseInt(impersonatedUser.roleId)
                                    : 0) === Constant.UserRoleId.SchoolAdmin
                                ) {
                                  updateClassTeacherIsPrimary(teacher);
                                }
                              }
                            }}
                          />
                          {" "}
                        </span>)}
                      </td>
                      <td className="text-sm mt-5  p-3 text-center">
                        {!teacher.isPrimary &&
                          (props.mode === PageAccessMode.Edit ||
                            teacher.userId === props.userContext?.userId) && (
                            <span
                              title="Remove Teacher"
                              className={(props.isEditingEnabled ? "cursor-pointer" : "cursor-not-allowed") + " inline-block"}
                              onClick={() => {
                                if(props.isEditingEnabled) {
                                  deleteClassTeacher(teacher);
                                }
                              }}
                            >
                              {" "}
                              {((props.userContext?.userId ===
                                props.primaryTeacher?.userId ||
                                teacher.userId ===
                                  props.userContext?.userId) && props.isEditingEnabled) && (
                                <RemoveIcon className="w-4 mx-2 text-gray-500 hover:text-gray-700" />
                              )}{" "}
                            </span>
                          )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between border-t-2 pt-4 mt-5">
            {classTeachers?.totalRecords !== undefined &&
              classTeachers?.totalRecords !== 0 && (
                <Pagination
                  totalRecords={classTeachers?.totalRecords}
                  pageSize={pageSize}
                  onPageChange={(e) => {
                    setPageNumber(e);
                  }}
                  reset={resetPageNumber}
                  pageNumber={pageNumber}
                />
              )}
            {classTeachers?.totalRecords !== 0 && (
              <PageSize
                pageSize={pageSize}
                onPageSizeChange={onPageSizeChange}
              />
            )}
          </div>
          {classTeachers?.totalRecords === 0 && (
            <span className="text-color-black">No record found</span>
          )}
        </div>
      </div>
      {showTeacherPopup && (
        <TeacherPopup
          classId={props.classId}
          showPopUp={(show) => {
            setShowTeacherPopup(show);
          }}
          refreshParent={getClassTeacher}
        />
      )}
      <ConfirmationDialog
        open={openConfirmation}
        confirmationTitle={confirmationTitle}
        confirmationMessage={confirmationMessage}
        onCancelClick={confirmationCancelClose}
        onOkClick={confirmationOkClose}
      ></ConfirmationDialog>
    </Fragment>
  );
};

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(ClassTeacher);

import { Windmill } from "@windmill/react-ui";
import "moment-timezone";
import React, { Suspense, useEffect, useState } from "react";
import Moment from "react-moment";
import { connect } from "react-redux";
import {
  Redirect,
  Route,
  Switch,
  matchPath,
  useHistory,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Routes } from "./../src/routeConfig";
import { MessageApi } from "./api/message/messageApi";
import { useIdleTimer } from "react-idle-timer";
import NotFound from "./component/shared/404";
import { ConfirmationServiceProvider } from "./component/shared/confirmation/confirmationService";
import ElementaryStudentLayout from "./component/shared/layout/student/elementary/layout";
import KindergartenStudentLayout from "./component/shared/layout/student/kindergarten/layout";
import MiddleSchoolStudentLayout from "./component/shared/layout/student/middleSchool/layout";
import TeacherLayout from "./component/shared/layout/teacher/layout";
import ThemedSuspense from "./component/shared/themedSuspense";
import { fetchProfile } from "./redux/actions/userActions";
import egTheme from "./theme/egtheme";
import Constant from "./utils/constant/constant";
import RouteConstant from "./utils/constant/routeConstant";
import { Configuration } from "./environment/setup";
import { ErrorBoundary } from "react-error-boundary";
import ErrorBoundaryComponent from "./component/shared/errorBoundary";
import i18n from "./i18Next";
import { stopTextToSpeech } from "./component/screen/student/shared/textToSpeech/textToSpeech";
import routeConstant from "./utils/constant/routeConstant";
import pendoScript from "./utils/pendo";
import auth from "./utils/auth";

Moment.globalTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
Moment.globalFormat = "MM-DD-YYYY hh:mm:ss";
function App(props: any) {
  const history = useHistory();
  const [errorFlag, setErrorFlag] = useState<boolean>(false);
  const gradeId: number = props.userContext.gradeId;

  function getDashboardDisableClass() {
    const isShowTrialPopup = window.sessionStorage.getItem("showTrialPopup");
    if (
      props.userContext.roleId.toString() ===
        Constant.UserRoleId.FreeTeacher.toString() &&
      isShowTrialPopup === "true"
    ) {
      return "filter blur-sm";
    }
    return "";
  }

  useIdleTimer({
    timeout: Configuration.IdealStatusTime,
    onActive: () => {
      MessageApi.handleOnActive(
          props.userContext.userId,
          props.userContext.schoolId
      );
    },
    onIdle: () => {
      MessageApi.handleOnIdle(
          props.userContext.userId,
          props.userContext.schoolId
      );
    },
    debounce: 300
  });
  useIdleTimer({
    crossTab: true,
    timeout: Configuration.UserIdleTime,
    name: "idle-logout",
    onIdle: () => redirectToLogin(),
    debounce: 300
  });

  useEffect(() => {
    const authToken = localStorage.getItem("AuthToken");

    if (
      window.location.pathname !== routeConstant.CLEVERLOGIN &&
      window.location.pathname !== routeConstant.CLASSLINKLOGIN &&
      window.location.pathname !== routeConstant.GOOGLESTUDENTLOGIN &&
      window.location.pathname !== routeConstant.GOOGLETEACHERLOGIN &&
      window.location.pathname !== routeConstant.GOOGLEUSERLOGIN &&
      window.location.pathname !== routeConstant.CANVASUSERLOGIN &&
      window.location.pathname !== routeConstant.ROUTE_FORGOTPASSWORD &&
      window.location.pathname !== routeConstant.CONTACTUS &&
      window.location.pathname !== routeConstant.HELP &&
      window.location.pathname !== routeConstant.PARENTLETTER &&
      !window.location.pathname
        .toUpperCase()
        .includes(routeConstant.CANVASMAPPING.toUpperCase()) &&
      !window.location.pathname
        .toUpperCase()
        .includes(routeConstant.GOOGLEMAPPING.toUpperCase()) &&
      !window.location.pathname
        .toUpperCase()
        .includes(routeConstant.EXTERNALCANVASLOGIN.toUpperCase()) &&
      !window.location.pathname
        .toUpperCase()
        .includes(routeConstant.EXTERNALGOOGLELOGIN.toUpperCase()) &&
      !matchPath(window.location.pathname, {
        path: routeConstant.USERREGISTRATION,
        exact: true,
        strict: false,
        sensitive: false,
      }) &&
      window.location.pathname !== routeConstant.RECOVERYCODEEXPIRED
    ) {
      if (
        !authToken &&
        window.location.pathname !== "/login" &&
        window.location.pathname !== "/"
      ) {
        return history.push({
          pathname: `/login`,
          state: { redirectUrl: window.location.pathname },
        });
      } else if (!authToken) {
        return history.push("/login");
      }
    }

    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    };

    //Need to also check if token exists but is expired, otherwise setProfile with an expired token will cause a redirect to login (plp-1844)
    if (props.userContext.userId > 0 && !auth.isTokenExpired()) {
      props.setProfile(
        props.userContext.userId,
        props.userContext.roleId,
        props.userContext.schoolId,
        props.userContext.accountId
      );
    }

    if (window.sessionStorage.getItem("impersonateStudentOn") === "true") {
      const redirectPath: string | null = window.sessionStorage.getItem(
        "studentImpersonateUrl"
      );
      history.push({
        pathname: RouteConstant.VIEWASTUDENT,
        search: "",
        state: { url: redirectPath },
      });
    }
  }, []);

  useEffect(() => {
    // call analytics on homepage
    if (props.userContext?.userId > 0 && props.profile?.userId > 0) {
      pendoScript(props.userContext, props.profile);
    }
  }, [props.userContext, props.profile]);

  history.listen((location, action) => {
    if (location.pathname.toUpperCase().includes("LIFTOFF")) {
      i18n.changeLanguage(
        (props?.profile?.loLanguageId ?? 1) === 2 ? "es" : "en"
      );
    } else {
      i18n.changeLanguage(
        (props?.profile?.plLanguageId ?? 1) === 2 ? "es" : "en"
      );
    }
    if (stopTextToSpeech) {
      stopTextToSpeech();
    }
  });

  const teacherRoutes = Routes.filter(
    (r) => r.layout === Constant.LayoutType.TEACHER
  );
  const kindergartenStudentRoutes = Routes.filter(
    (r) => r.layout === Constant.LayoutType.KINDERGARTEN
  );
  const elementaryStudentRoutes = Routes.filter(
    (r) => r.layout === Constant.LayoutType.ELEMENTARY
  );
  const middleSchoolStudentRoutes = Routes.filter(
    (r) => r.layout === Constant.LayoutType.MIDDLESCHOOL
  );
  const middleSchoolLiftOffStudentRoutes = Routes.filter(
    (r) => r.layout === Constant.LayoutType.MIDDLESCHOOLLiftOff
  );
  const defaultRoutes = Routes.filter(
    (r) => r.layout === Constant.LayoutType.NONE
  );

  function redirectToLogin() {
    if (props.userContext.userId > 0) {
      auth.logout();
    }
  }

  return (
    <div className={`App ${getDashboardDisableClass()}`}>
      <React.StrictMode>
        <Suspense fallback={<ThemedSuspense />}>
          <Windmill theme={egTheme}>
            {!errorFlag && (
              <ConfirmationServiceProvider>
                <ErrorBoundary
                  fallbackRender={({ error, resetErrorBoundary }) => {
                    return (
                      <div>
                        <ErrorBoundaryComponent
                          toggleErrorBoundary={() => {
                            setErrorFlag(false);
                          }}
                        />
                      </div>
                    );
                  }}
                >
                  <Switch>
                    {/* Teacher Layout */}
                    <Route path={teacherRoutes.map((r) => r.path)}>
                      <TeacherLayout>
                        <Switch>
                          {teacherRoutes.map((route, i) => {
                            return (
                              <Route
                                key={i}
                                path={route.path}
                                exact
                                component={route.component}
                              ></Route>
                            );
                          })}
                        </Switch>
                      </TeacherLayout>
                    </Route>
                    {/* Kindergarten Layout */}
                    <Route path={kindergartenStudentRoutes.map((r) => r.path)}>
                      {gradeId <= Constant.Grade.GRADE1 ? (
                        <KindergartenStudentLayout>
                          <Switch>
                            {kindergartenStudentRoutes.map((route, i) => {
                              return (
                                <Route
                                  key={i}
                                  path={route.path}
                                  exact
                                  component={route.component}
                                ></Route>
                              );
                            })}
                          </Switch>
                        </KindergartenStudentLayout>
                      ) : (
                        <Redirect to={routeConstant.FORBIDDEN} />
                      )}
                    </Route>
                    {/* Elementary Layout */}
                    <Route path={elementaryStudentRoutes.map((r) => r.path)}>
                      <ElementaryStudentLayout {...props}>
                        <Switch>
                          {elementaryStudentRoutes.map((route, i) => {
                            return (
                              <Route
                                key={i}
                                path={route.path}
                                exact
                                component={route.component}
                              ></Route>
                            );
                          })}
                        </Switch>
                      </ElementaryStudentLayout>
                    </Route>
                    {/* MiddleSchool Layout */}
                    <Route path={middleSchoolStudentRoutes.map((r) => r.path)}>
                      {gradeId >= Constant.Grade.GRADE6 ? (
                        <MiddleSchoolStudentLayout>
                          <Switch>
                            {middleSchoolStudentRoutes.map((route, i) => {
                              return (
                                <Route
                                  key={i}
                                  path={route.path}
                                  exact
                                  component={route.component}
                                ></Route>
                              );
                            })}
                          </Switch>
                        </MiddleSchoolStudentLayout>
                      ) : (
                        <Redirect to={routeConstant.FORBIDDEN} />
                      )}
                    </Route>

                    {/* MiddleSchool LiftOff Layout */}
                    <Route
                      path={middleSchoolLiftOffStudentRoutes.map((r) => r.path)}
                    >
                      {gradeId >= Constant.Grade.GRADE6 ? (
                        <ElementaryStudentLayout {...props}>
                          <Switch>
                            {middleSchoolLiftOffStudentRoutes.map(
                              (route, i) => {
                                return (
                                  <Route
                                    key={i}
                                    path={route.path}
                                    exact
                                    component={route.component}
                                  ></Route>
                                );
                              }
                            )}
                          </Switch>
                        </ElementaryStudentLayout>
                      ) : (
                        <Redirect to={routeConstant.FORBIDDEN} />
                      )}
                    </Route>

                    {/* Default Layout */}
                    <Route path={defaultRoutes.map((r) => r.path)}>
                      <Switch>
                        {defaultRoutes.map((route, i) => {
                          return (
                            <Route
                              key={i}
                              path={route.path}
                              exact
                              component={route.component}
                            ></Route>
                          );
                        })}
                        <Route component={NotFound} />
                      </Switch>
                    </Route>
                  </Switch>

                  <ToastContainer
                    hideProgressBar
                    position="top-right"
                    autoClose={5000}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                  />
                </ErrorBoundary>
              </ConfirmationServiceProvider>
            )}
          </Windmill>
        </Suspense>
      </React.StrictMode>
    </div>
  );
}

const mapStateToProps = (state: any) => {
  if (window.location.pathname.toUpperCase().includes("LIFTOFF")) {
    i18n.changeLanguage(
      (state?.profile?.loLanguageId ?? 1) === 2 ? "es" : "en"
    );
  } else {
    i18n.changeLanguage(
      (state?.profile?.plLanguageId ?? 1) === 2 ? "es" : "en"
    );
  }

  return {
    userContext: state.userContext,
    profile: state.profile,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setProfile: (
      userId: number,
      roleId: number,
      schoolId: number,
      districtId: number
    ) => {
      dispatch(fetchProfile(userId, roleId, schoolId, districtId));
    },
  };
};

App.displayName = "App";
export default connect(mapStateToProps, mapDispatchToProps)(App);

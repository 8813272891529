import { UserContextState } from "../actions/userContextAction";
import * as actionType from "../actions/actionTypes";
import * as action from "../actions/makeAction";
import { exterRosterType } from "../../model/externalRoster/externalRosterType";

const initialState = {
  userId: 0,
  roleId: 0,
  gradeId: 0,
  schoolId: 0,
  accountId: 0,
  districtId: 0,
  stateId: 0,
  googleId: "",
  activeExternalRoster: "" as exterRosterType,
  schoolAccountId: 0,
  impersonatedUser: null,
  linkedUserId: ""
};

export const userContextReducer = (
  state: UserContextState = initialState,
  action: action.IActionPayload<UserContextState>
) => {
  switch (action.type) {
    case actionType.USERCONTEXT_GETCONTEXT: {
      return state;
    }
    case actionType.USERCONTEXT_SETCONTEXT: {
      state = action.payload === null ? initialState : action.payload;
      return state;
    }
    default:
      return state;
  }
};

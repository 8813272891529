import Breadcrumb from "../shared/breadcrumb";
import React, {useEffect, useState} from "react";
import {IBreadcrumb} from "../../../../model/common/breadcrumb";
import RouteConstant from "../../../../utils/constant/routeConstant";
import { useTranslation } from "react-i18next";
import {toast} from "react-toastify";
import {getSubjectDetail} from "../../../../api/teacher/assessment";
import {getUrlParams} from "../../../../utils/helper";
import Loader from "../../../shared/loader";
import {
    getStudentActivityResources,
    IStudentActivityResourceGroup
} from "../../../../api/teacher/StudentActivityResources";

export const StudentActivities = () => {
    const subjectId = getUrlParams()?.subjectId || undefined;
    const [subjectDeails, setSubjectDeails] = useState<any>();
    const [loading, setLoading] = useState<boolean>(false);
    const [studentActivityResources, setStudentActivityResources] = useState<Array<IStudentActivityResourceGroup>>();
    const { t } = useTranslation();

    useEffect(() => {
        setStudentActivityResources(getStudentActivityResources());
        (async () => {
            if (!subjectId || parseInt(subjectId) === 0) {
                toast.error("Invalid Subject");
                return;
            }
            setLoading(true);
            const subDetails = await getSubjectDetail(subjectId);
            setLoading(false);
            if (subDetails?.data) setSubjectDeails(subDetails.data);
        })();
    }, []);

    const breadcrumbItems = () => {
        const items: Array<IBreadcrumb> = [
            { name: t("Breadcrumb.Home"), url: RouteConstant.ROUTE_DASHBOARD },
            {
                name: t("Breadcrumb.InstructionalResources"),
                url: "",
            },
            {
                name: t("Breadcrumb.Subjects"),
                url: RouteConstant.TeacherRoutes.InstructionalResourceSubjects,
            },
            {
                name: t("Breadcrumb.Resources"),
                url: RouteConstant.TeacherRoutes.InstructionalResources,
                stateObject: { label: subjectDeails?.name, value: subjectId },
            },
            { name: t("Breadcrumb.StudentActivities"), url: "" },
        ];

        return items;
    };
    return (
        <>
            {loading && <Loader />}
            <div className="min-w-0 w-full">
                <div className="lg:flex lg:items-center px-4 py-3 lg:px-8 border-b">
                    <Breadcrumb items={breadcrumbItems()} />
                    <div className="lg:w-[55%] text-lg font-medium flex gap-2 h-auto sm:h-10 items-center">
                        <h1 className="text-primary-violet font-medium flex justify-start mb-0 pb-0">
                            Student Activities
                        </h1>
                    </div>
                </div>

                <div className="p-6">
                    {studentActivityResources?.map((group) => {
                        return (
                            <>
                                <div className="flex items-start h-16 mb-3">
                                    <div className="h-full"><img className="w-16" alt={""} src={`https://progresslearning.com/wp-content/uploads/2024/01/${group.iconFile}`}/></div>
                                    <div className="h-full flex items-center ml-4 text-lg text-primary-violet font-semibold">{group.name}</div>
                                </div>

                                <div className="flex items-start flex-wrap max-w-7xl mb-8">
                                    {group.resources.map((resource) => (
                                        <div className="mr-6 p-3 border w-52 h-[340px] mb-6">
                                            <a href={resource.url} target={"student-activity"}>
                                                <div className="mb-2 text-gray-700">{resource.name}</div>
                                                <img alt={""} src={`https://progresslearning.com/wp-content/uploads/2024/01/${resource.iconFile}`}/>
                                            </a>
                                        </div>
                                    ))}
                                </div>
                            </>
                        )
                    })}
                </div>
            </div>
        </>
    );
};
import { Fragment } from "react";
import { OutlineRefreshIcon } from "../../../../../assets/icons";

type studentGradeModeType =
  | "Kindergarten"
  | "Elementary"
  | "MiddleSchool"
  | "LiftOff";
interface AlienAvatarProps {
  studentId: number;
  gradeId: number;
  alienData: any;
  showOnQuestions: boolean;
  studentGradeMode: studentGradeModeType;
  applykinderGartenStyle?: boolean;
  isLiftOff?: boolean;
}

function StudentAlienArena(props: AlienAvatarProps) {
  return (
    <Fragment>
      <div
        className={`${
          props.studentGradeMode === "Elementary" ? "" : ""
        } rounded-2xl bg-white cursor-pointer h-full w-full py-4 px-4 text-center rounded-xl hover:shadow-2xl transition ease-in-out duration-500`}
      >
        <div className="grid grid-cols-1 xl:grid-cols-12 gap-0 xl:gap-6">
          <div className="col-span-4 xl:block md:gap-4 xl:gap-0 space-y-4 xl:space-y-6 md:mb-4 xl:mb-0">
            <div className="block bg-[#ff4655] py-2 px-3 sm:p-4 rounded-2xl">
              <div className="cursor-pointer flex items-center justify-between">
                <h2 className="text-lg py-1">Alien Arena</h2>
              </div>
            </div>
          </div>
          <div className="col-span-8 h-full mt-8 md:mt-4 xl:mt-0">
            <div className="xl:h-full bg-[#ff4655] rounded-2xl relative">
              <div className="mx-auto space-y-3 px-2 py-4 sm:flex-col w-full sm:px-6 lg:px-8">
                <div>
                  <a
                    href="#!"
                    className="cursor-pointer inline-flex items-center md:whitespace-nowrap lg:whitespace-normal order-first bg-white float-right px-3 py-2 rounded-lg mb-2 mt hover:shadow-lg text-black hover:text-black"
                  >
                    <OutlineRefreshIcon className="w-3 h-3 mr-2"></OutlineRefreshIcon>
                    Refresh
                  </a>
                </div>
                <div className="w-full overflow-x-auto rounded-lg mb-5 mt-2">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th className="px-3 py-3 w-20 text-left text-xs font-medium text-gray-500 capitalize tracking-wider bg-gray-100">
                          Session Name
                        </th>
                        <th className="px-3 py-3 w-20 text-left text-xs font-medium text-gray-500 capitalize tracking-wider bg-gray-100">
                          Subject
                        </th>
                        <th className="px-3 py-3 w-20 text-left text-xs font-medium text-gray-500 capitalize tracking-wider bg-gray-100">
                          Team Name
                        </th>
                        <th className="px-3 py-3 w-20 text-center text-xs font-medium text-gray-500 capitalize tracking-wider bg-gray-100">
                          Start Date
                        </th>
                        <th className="px-3 py-3 w-20 text-center text-xs font-medium text-gray-500 capitalize tracking-wider bg-gray-100">
                          Score
                        </th>
                        <th className="px-3 py-3 w-20 text-left text-xs font-medium text-gray-500 capitalize tracking-wider bg-gray-100"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="bg-white">
                        <td className="px-3 py-2 text-left text-sm text-gray-500">
                          Ankur's Arena
                        </td>
                        <td className="px-3 py-2 text-left text-sm text-gray-500">
                          Science
                        </td>
                        <td className="px-3 py-2 text-left text-sm text-gray-500">
                          Galaxies
                        </td>
                        <td className="px-3 py-2 text-center text-sm text-gray-500">
                          08/05/2021
                        </td>
                        <td className="px-3 py-2 text-center text-sm text-gray-500">
                          20
                        </td>
                        <td className="px-3 py-2 text-center text-sm text-gray-500">
                          Completed
                        </td>
                      </tr>
                      <tr className="bg-white bg-opacity-90">
                        <td className="px-3 py-2 text-left text-sm text-gray-500">
                          Example Arena 1
                        </td>
                        <td className="px-3 py-2 text-left text-sm text-gray-500">
                          Social Studies (GSE)
                        </td>
                        <td className="px-3 py-2 text-left text-sm text-gray-500">
                          Galaxies
                        </td>
                        <td className="px-3 py-2 text-center text-sm text-gray-500">
                          08/05/2021
                        </td>
                        <td className="px-3 py-2 text-center text-sm text-gray-500">
                          1
                        </td>
                        <td className="px-3 py-2 text-center text-sm text-gray-500">
                          Completed
                        </td>
                      </tr>
                      <tr className="bg-white">
                        <td className="px-3 py-2 text-left text-sm text-gray-500">
                          Ankur's Arena
                        </td>
                        <td className="px-3 py-2 text-left text-sm text-gray-500">
                          Science
                        </td>
                        <td className="px-3 py-2 text-left text-sm text-gray-500">
                          Comets
                        </td>
                        <td className="px-3 py-2 text-center text-sm text-gray-500">
                          08/05/2021
                        </td>
                        <td className="px-3 py-2 text-center text-sm text-gray-500">
                          20
                        </td>
                        <td className="px-3 py-2 text-center text-sm text-gray-500">
                          <button className="flex items-center mx-auto justify-center  hover:bg-opacity-80  px-2  text-xs py-1 text-white rounded-lg p-1 space-x-0.5 bg-blues">
                            <span className="mr-0.5">Start</span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              className="w-4 cursor-pointer"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z"
                              ></path>
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                              ></path>
                            </svg>
                          </button>
                        </td>
                      </tr>
                      <tr className="bg-white bg-opacity-90">
                        <td className="px-3 py-2 text-left text-sm text-gray-500">
                          Example Arena 1
                        </td>
                        <td className="px-3 py-2 text-left text-sm text-gray-500">
                          Social Studies (GSE)
                        </td>
                        <td className="px-3 py-2 text-left text-sm text-gray-500">
                          Spaces Pirates
                        </td>
                        <td className="px-3 py-2 text-center text-sm text-gray-500">
                          08/05/2021
                        </td>
                        <td className="px-3 py-2 text-center text-sm text-gray-500">
                          1
                        </td>
                        <td className="px-3 py-2 text-center text-sm text-gray-500">
                          <button className="flex items-center mx-auto justify-center  hover:bg-opacity-80  px-2  text-xs py-1 text-white rounded-lg p-1 space-x-0.5 bg-blues">
                            <span className="mr-0.5">Start</span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              className="w-4 cursor-pointer"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z"
                              ></path>
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                              ></path>
                            </svg>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default StudentAlienArena;

import { AxiosHelper } from "../../environment/axiosConfig";
import APIConstant from "../../utils/constant/apiConstant";

export const getDiagnosticsStrengthWeaknessReport = ( 
    studentId,
    subjectId,
    schoolId,
    classId,
    testTypeId
) => {
  return AxiosHelper.axiosInstance().get(
    APIConstant.DIAGNOSTICS_API.GetDiagnosticsStrengthWeaknessReport,
    {
      params: {      
        studentId,
        subjectId,
        schoolId, 
        classId,
        testTypeId        
      },
    }
  );
};

export const getDiagnosticsStrengthWeaknessReportExport = ( 
  studentId,
  subjectId,
  schoolId,
  classId,
  testTypeId
) => {
return AxiosHelper.axiosInstance().get(
  APIConstant.DIAGNOSTICS_API.GetDiagnosticsStrengthWeaknessReportExport,
  {
    params: {      
      studentId,
      subjectId,
      schoolId, 
      classId,
      testTypeId        
    },
  }
);
};

import moment from "moment";
import { ITeacherUsageReport } from "../../../../../model/interface/report/teacherUsageReport";
import NoData from "../../../../shared/noData";
import { useState } from "react";
import { DEFAULT_PAGE_NUMBER, DEFAULT_PAGE_SIZE } from "../../../../../utils/pagingConstant";
import Pagination from "../../../../shared/pagination/pagination";
import PageSize from "../../../../shared/pagination/pageSize";

interface TeacherUsageReportTableProps {
  reportData?: Array<ITeacherUsageReport>,
  isDistrictUser: boolean,
}

function TeacherUsageReportTable(props: TeacherUsageReportTableProps) {
  const {
    reportData,
    isDistrictUser,
  } = props;

  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [pageNumber, setPageNumber] = useState(DEFAULT_PAGE_NUMBER);
  const [resetPageNumber, setResetPageNumber] = useState<boolean>(false);

  const totalRecords = reportData?.length || 0;

  const handlePageSizeChange = (pageSize) => {
    setPageNumber(DEFAULT_PAGE_NUMBER);
    setPageSize(pageSize);
    setResetPageNumber(true);
  }

  const columnClass = "text-sm p-3";
  const headerClass = "text-left p-3 text-sm font-normal";

  return (
    <div>
      {reportData && (
        <div className="bg-white py-5 px-5">
          <div className="overflow-x-auto xl:overflow-x-visible shadow">
            <table className="table w-full">
              <thead>
                <tr className="bg-primary-violet text-white">
                  {isDistrictUser && (
                    <th className={headerClass}>
                      School
                    </th>
                  )}
                  <th className={headerClass}>
                    Teacher Name
                  </th>
                  <th className={headerClass}>
                    Total Logins
                  </th>
                  <th className={headerClass}>
                    Last Login
                  </th>
                  <th className={headerClass}>
                    Subject
                  </th>
                  <th className={headerClass}>
                    # of Assessments Created
                  </th>
                  <th className={headerClass}>
                    # of Assignments Created
                  </th>
                </tr>
              </thead>
              <tbody>
                {reportData
                ?.filter((_teacherUsageReport: ITeacherUsageReport, index: number) => 
                  index >= (pageNumber - 1) * pageSize && index <= pageNumber * pageSize)
                ?.map((teacherUsage: ITeacherUsageReport, index: number) => (
                  <tr 
                    className={`hover:bg-primary-violet/20 transition ease-in-out duration-300 ${
                      index % 2 === 0
                        ? "bg-white"
                        : "bg-primary-violet/10"
                    }`}
                  >
                    {isDistrictUser && (
                      <td className={columnClass}>
                        {teacherUsage.schoolName}
                      </td>
                    )}
                    <td className={columnClass}>
                      {`${teacherUsage.lastName}, ${teacherUsage.firstName}`}
                    </td>
                    <td className={columnClass}>
                      {teacherUsage.loginCount}
                    </td>
                    <td className={columnClass}>
                      {teacherUsage.lastLogin ? moment(teacherUsage.lastLogin).format("MM-DD-YYYY hh:mm:ss") : "N/A"}
                    </td>
                    <td className={columnClass}>
                      {teacherUsage.subject}
                    </td>
                    <td className={columnClass}>
                      {teacherUsage.assessmentCount}
                    </td>
                    <td className={columnClass}>
                      {teacherUsage.assignmentCount}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {reportData?.length === 0 && <NoData />}
          {reportData?.length !== 0 && (
            <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between border-t-2 pt-4 mt-4">
              <Pagination 
                totalRecords={totalRecords}
                pageNumber={pageNumber}
                pageSize={pageSize}
                onPageChange={setPageNumber}
                reset={resetPageNumber}
                setPageNumber={setPageNumber}
              />
              <PageSize
                pageSize={pageSize}
                setPageSize={setPageSize}
                onPageSizeChange={handlePageSizeChange}
              />
            </div>
          )}  
        </div>
      )}
    </div>
  )
}

export default TeacherUsageReportTable

import { Fragment } from "react";

type studentGradeModeType =
  | "Kindergarten"
  | "Elementary"
  | "MiddleSchool"
  | "LiftOff";
interface AlienAvatarProps {
  studentId: number;
  gradeId: number;
  alienData: any;
  showOnQuestions: boolean;
  studentGradeMode: studentGradeModeType;
  applykinderGartenStyle?: boolean;
  isLiftOff?: boolean;
}

function StudentAlienArenaStartSession(props: AlienAvatarProps) {
  return (
    <Fragment>
      <div
        className={`${
          props.studentGradeMode === "Elementary" ? "" : ""
        } rounded-2xl bg-white cursor-pointer h-full w-full py-4 px-4 text-center rounded-xl hover:shadow-2xl transition ease-in-out duration-500`}
      >
        <div className="w-full flex flex-col lg:flex-row gap-y-4 lg:gap-y-0 gap-x-6">
          <div className="w-full lg:w-1/2 shadow-lg border rounded-md p-6">
            <div className="flex border-b pb-2">
              <span>Question Id</span>
              <span className="text-xs text-white bg-primary-violet rounded-md inline-block px-1 py-0.5 ml-1">
                141748
              </span>
            </div>
          </div>
          <div className="w-full lg:w-1/2 bg-[#ff4655] p-6 rounded-md ">
            <div className="grid md:grid-cols-2 lg:grid-cols-1 xl:grid-cols-2 gap-6">
              <div className="rounded-md shadow p-5 bg-white flex justify-between">
                <div className="flex items-center gap-x-2">
                  <span className="w-8 h-8 flex rounded-full bg-green-500 text-white font-semibold text-lg inline-block justify-center items-center">
                    8
                  </span>
                  <span className="text-primary-violet">Rocketeers</span>
                </div>
                <div className="flex items-center gap-x-2 px-2 bg-gray-100 rounded-md">
                  <div className="text-sm text-gray-500">Score</div>
                  <div className="text-lg text-gray-700 font-semibold">3</div>
                </div>
              </div>
              <div className="rounded-md shadow p-5 bg-white flex justify-between">
                <div className="flex items-center gap-x-2">
                  <span className="w-8 h-8 flex rounded-full bg-green-500 text-white font-semibold text-lg inline-block justify-center items-center">
                    5
                  </span>
                  <span className="text-primary-violet">Shooting Stars</span>
                </div>
                <div className="flex items-center gap-x-2 px-2 bg-gray-100 rounded-md">
                  <div className="text-sm text-gray-500">Score</div>
                  <div className="text-lg text-gray-700 font-semibold">0</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default StudentAlienArenaStartSession;

import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { ChevronRightIcon } from "@heroicons/react/solid";
import RouteConstant from "../../../../utils/constant/routeConstant";

function AlienArenaResults(props: any) {
  return (
    <div className="min-w-0 w-full">
      <div className="px-4 py-5 lg:px-8 border-b">
        <ol className="flex items-center space-x-2 mb-1">
          <li>
            <Link to={RouteConstant.TeacherRoutes.AlienArenaSessions}>
              <div className="flex items-center">
                <div className="cursor-pointer text-sm text-gray-500 hover:text-gray-700 border-dashed border-b border-gray-400 hover:border-opacity-0">
                  Alien Arena
                </div>
              </div>
            </Link>
          </li>
          <li>
            <div className="flex items-center">
              <ChevronRightIcon className="flex-shrink-0 h-5 w-5 text-gray-400" />
              <span className="ml-1 text-sm text-gray-500 capitalize">
                Start Session
              </span>
            </div>
          </li>
        </ol>
        <div className=" text-lg font-medium flex gap-2 h-auto sm:h-10 items-center">
          <h1 className="text-primary-violet flex items-center justify-between w-full pr-4">
            Start Session
          </h1>
        </div>
      </div>
      <div className="px-2 sm:px-6 lg:px-8 py-6">
        <div className="w-full flex flex-col lg:flex-row gap-y-4 lg:gap-y-0 gap-x-6">
          <div className="w-full lg:w-1/2 shadow-lg border rounded-md p-6">
            <div className="flex border-b pb-2">
              <span>Question Id</span>
              <span className="text-xs text-white bg-primary-violet rounded-md inline-block px-1 py-0.5 ml-1">
                141748
              </span>
            </div>
          </div>
          <div className="w-full lg:w-1/2 bg-gray-100 p-6 rounded-md ">
            <div className="grid md:grid-cols-2 lg:grid-cols-1 xl:grid-cols-2 gap-6">
              <div className="rounded-md shadow p-5 bg-white flex justify-between">
                <div className="flex items-center gap-x-2">
                  <span className="w-8 h-8 flex rounded-full bg-green-500 text-white font-semibold text-lg inline-block justify-center items-center">
                    8
                  </span>
                  <span className="text-primary-violet">Rocketeers</span>
                </div>
                <div className="flex items-center gap-x-2 px-2 bg-gray-100 rounded-md">
                  <div className="text-sm text-gray-500">Score</div>
                  <div className="text-lg text-gray-700 font-semibold">3</div>
                </div>
              </div>
              <div className="rounded-md shadow p-5 bg-white flex justify-between">
                <div className="flex items-center gap-x-2">
                  <span className="w-8 h-8 flex rounded-full bg-green-500 text-white font-semibold text-lg inline-block justify-center items-center">
                    5
                  </span>
                  <span className="text-primary-violet">Shooting Stars</span>
                </div>
                <div className="flex items-center gap-x-2 px-2 bg-gray-100 rounded-md">
                  <div className="text-sm text-gray-500">Score</div>
                  <div className="text-lg text-gray-700 font-semibold">0</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-8 text-center">
          <button
            className="bg-primary-violet border border-transparent shadow-sm mt-4 sm:mt-0 py-2 px-4 justify-center text-sm font-medium text-white hover:bg-dark-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            type="button"
          >
            Get Result
          </button>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(AlienArenaResults);

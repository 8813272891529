import React, { useEffect, useState } from "react";

interface IMultiState {
  label: string;
  value: number;
  selected?: boolean;
}

export const MultiStateButton: React.FC<any> = (props: {
  labels: Array<any>;
  onSelect: any;
  onReset?: boolean;
}) => {
  const defaultSelected = props?.labels?.filter((ds) => ds.selected);
  const [selected, setSelected] = useState<IMultiState>(defaultSelected[0]);

  const onChange = (label: string, i: any) => {
    setSelected({ label, value: i, selected: i });
  };

  useEffect(() => {
    props.onSelect(selected);
  }, [selected]);

  useEffect(() => {
    setSelected(defaultSelected[0]);
  }, [props.onReset]);

  return (
    <div className="border text-xs border-gray-300 overflow-hidden rounded-md flex whitespace-nowrap">
      {props?.labels?.map((ds, i) => {
        i++;
        return (
          <span
            key={i}
            onClick={() => onChange(ds.label, ds.value)}
            className={`${
              selected?.value === ds.value
                ? "bg-tertiary-green text-white"
                : "bg-white text-gray-400"
            } inline-block dark:text-white w-28 py-0.5 text-center cursor-pointer hover:opacity-80`}
          >
            {ds.label}
          </span>
        );
      })}
    </div>
  );
};

export default MultiStateButton;

import React, { useEffect, useState } from "react";
import Breadcrumb from "../../../screen/teacher/shared/breadcrumb";
import { IBreadcrumb } from "../../../../model/common/breadcrumb";
import RouteConstant from "../../../../utils/constant/routeConstant";
import { useTranslation } from "react-i18next";
import Loader from "../../../shared/loader";
import NoData from "../../../shared/noData";
import { ICanvasClassDTO } from "../../../../model/canvasClassroom/canvasClassDTO";
import { CanvasApi } from "../../../../api/canvas/canvasApi";
import { connect } from "react-redux";
import { UserContextState } from "../../../../redux/actions/userContextAction";
import Profile from "../../../../model/users/profile";
import { toast } from "react-toastify";
import {
  DEFAULT_PAGE_NUMBER,
  DEFAULT_PAGE_SIZE_100,
} from "../../../../utils/pagingConstant";
import { DownArrowIcon, UpArrow } from "../../../../assets/icons";

interface ImportCanvasClassesProps {
  userContext: UserContextState;
  profile: Profile;
}

const ImportCanvasClasses = (props: ImportCanvasClassesProps) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [pageNumber, setPageNumber] = useState(DEFAULT_PAGE_NUMBER);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE_100);
  const [searchFilter, setSearchFilter] = useState({
    name: "",
  });
  const [classes, setClasses] = useState<Array<ICanvasClassDTO>>([]);
  const [hasMore, setHasMore] = useState<boolean>(true);

  const breadcrumbItems = () => {
    const items: Array<IBreadcrumb> = [
      { name: t("Breadcrumb.Home"), url: RouteConstant.ROUTE_DASHBOARD },
      {
        name: t("Breadcrumb.ClassManagement"),
        url: RouteConstant.TeacherRoutes.Classes,
      },
      { name: t("Breadcrumb.ImportCanvasClasses"), url: "" },
    ];

    return items;
  };

  const getCanvasClassesToImport = async (
    pageNumber: number,
    pageSize: number
  ) => {
    setPageNumber(pageNumber);
    setPageSize(pageSize);
    setLoading(true);
    try {
      const response = await CanvasApi.getCanvasClassesToImport(
        props?.userContext?.userId!,
        props?.profile?.canvasId!,
        searchFilter.name,
        "",
        false,
        pageSize,
        pageNumber,
        props?.profile?.canvasAccountSettings
      );
      setLoading(false);
      setHasMore(response.data?.length > 0);
      return response;
    } catch (r) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  const loadMore = async (pageNumber: number, pageSize: number) => {
    const resp = await getCanvasClassesToImport(pageNumber, pageSize);
    setClasses([...classes, ...resp?.data!]);
  };

  const getCanvasClasses = async (pageNumber: number, pageSize: number) => {
    const resp = await getCanvasClassesToImport(pageNumber, pageSize);
    setClasses(resp?.data!);
  };

  const getCanvasSections = async (courseId: string) => {
    setLoading(true);
    const resp = await CanvasApi.getCanvasSections(
      courseId,
      props.profile.canvasAccountSettings
    );
    if (resp.data && resp.data.length > 0) {
      const allClasses = classes;
      const modifiedSelection = allClasses.map((c) => {
        if (c.courseId === courseId) {
          c.sections = resp.data.filter((s) => c.name !== s.name);
        }
        return c;
      });
      setClasses(modifiedSelection);
    }
    setLoading(false);
  };

  const handleOnChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setSearchFilter({ ...searchFilter, [name]: value });
  };

  const handleSelectClasses = (courseId: string, e: boolean) => {
    const allClasses = classes;
    const modifiedSelection = allClasses.map((c) => {
      if (c.courseId === courseId) {
        c.isSelected = e;
      }
      return c;
    });
    setClasses(Array.from(modifiedSelection));
  };

  const handleSelectSections = (
    courseId: string,
    sectionId: string,
    e: boolean
  ) => {
    const allClasses = classes;
    const modifiedSelection = allClasses.map((c) => {
      if (c.courseId === courseId) {
        c.sections?.map((s) => {
          if (s.sectionId === sectionId) {
            s.isSelected = e;
            return s;
          }
        });
      }
      return c;
    });
    setClasses(Array.from(modifiedSelection));
  };

  const handleCollapseExpand = (courseId: string, e: boolean) => {
    const allClasses = classes;
    const modifiedSelection = allClasses.map((c) => {
      if (c.courseId === courseId) {
        c.isExpanded = e;
      }
      return c;
    });
    setClasses(Array.from(modifiedSelection));
  };

  const importCanvasClasses = () => {
    let classesToImport: any = [];
    let sectionsToImport: any = [];
    classes.map((c) => {
      if (!c.isImported && c.isSelected) {
        const data = { ...c, sections: Array.from(c.sections ?? []) };
        classesToImport.push(data);
      }
    });

    classes.map((c) => {
      if (c.sections && c.sections.length > 0)
        c.sections.forEach((s) => {
          if (s.isSelected && !s.isImported) {
            sectionsToImport.push(s);
          }
        });
    });

    const successMessage =
      classesToImport.length > 1
        ? "Classes imported successfully"
        : "Class imported successfully";

    if (classesToImport.length > 0 || sectionsToImport.length > 0) {
      setLoading(true);
      CanvasApi.importCanvasClasses(
        classesToImport,
        sectionsToImport,
        props?.userContext?.schoolAccountId!,
        props?.userContext?.userId!
      )
        .then((r) => {
          setLoading(false);
          onFilterClick();
          toast.success(successMessage);
        })
        .catch((r) => {
          setLoading(false);
          toast.error("Something went wrong");
        });
    }
  };

  const onFilterClick = () => {
    getCanvasClasses(DEFAULT_PAGE_NUMBER, DEFAULT_PAGE_SIZE_100);
  };

  const resetFilter = () => {
    const searchQuery = {
      name: "",
    };
    setSearchFilter(searchQuery);
    onFilterClick();
  };

  useEffect(() => {
    onFilterClick();
  }, []);

  return (
    <>
      {loading && <Loader />}
      <div>
        <div className="lg:flex lg:items-center px-4 py-3 lg:px-8 border-b border-gray-200">
          <Breadcrumb items={breadcrumbItems()} />
          <div className="lg:w-[55%] text-lg flex justify-between gap-2 h-auto sm:h-10 items-center">
            <h1 className="text-primary-violet font-medium flex justify-start">
              Import Canvas Classes
            </h1>
          </div>
        </div>

        <div className="px-2 sm:px-6 lg:px-8 py-6">
          <div className="md:col-span-2 lg:col-span-3">
            <div className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 xl:grid-cols-4 sm:gap-x-8">
              <div>
                <label
                  htmlFor="last_name"
                  className="block text-sm  text-gray-500"
                >
                  Class name
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="name"
                    maxLength={60}
                    value={searchFilter.name}
                    onChange={handleOnChange}
                    className="border py-2 px-4 block w-full shadow-sm text-gray-900 focus:ring-primary-violet focus:border-primary-violet border-gray-300 rounded-md"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="flex mt-5 pt-5 text-right items-end lg:flex-row md:items-end justify-end border-t">
            <div className=" flex space-x-3 mt-2 lg:mt-0">
              <button
                className="bg-transparent border border-primary-violet shadow-sm py-2 px-4 inline-flex justify-center items-center text-sm font-medium text-primary-violet hover:text-white hover:bg-light-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3 transition ease-in-out duration-500"
                type="button"
                onClick={importCanvasClasses}
              >
                <span className="text-sm">Import</span>
              </button>
              <button
                className="bg-transparent border border-primary-violet shadow-sm py-2 px-4 inline-flex justify-center items-center text-sm font-medium text-primary-violet hover:text-white hover:bg-light-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3 transition ease-in-out duration-500"
                type="button"
                onClick={resetFilter}
              >
                <span className="text-sm">Reset</span>
              </button>
              <button
                className="bg-primary-violet border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-dark-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                type="button"
                onClick={onFilterClick}
              >
                Filter
              </button>
            </div>
          </div>

          <section className="mt-3">
            <div className="bg-gray-100 rounded-lg p-5 ">
              <div className="shadow hover:shadow-lg bg-white py-5 px-5 xl:px-2 1xl:px-5">
                <div className="flex flex-col relative">
                  <div>
                    <div className="py-2">
                      <div className="overflow-x-auto xl:overflow-x-visible ">
                        <table className="table w-full ">
                          <tr className="bg-light-violet/10  text-gray-700 py-3 px-3">
                            <th className="text-left p-5 text-sm font-normal w-1/3">
                              Name
                            </th>
                          </tr>
                          <tbody>
                            {classes &&
                              classes.length > 0 &&
                              classes.map((c, i) => (
                                <>
                                  <tr key={c.courseId} className="w-full">
                                    <td>
                                      <div className="flex gap-2 py-5 px-2 items-center cursor-pointer border-b">
                                        {c.isExpanded ? (
                                          <UpArrow
                                            className="w-4 h-4 stroke-current text-gray-500 hover:text-gray-700 cursor-pointer"
                                            onClick={() => {
                                              handleCollapseExpand(
                                                c.courseId,
                                                !c.isExpanded
                                              );
                                            }}
                                          />
                                        ) : (
                                          <DownArrowIcon
                                            className="w-4 h-4 stroke-current text-gray-500 hover:text-gray-700 cursor-pointer"
                                            onClick={() => {
                                              handleCollapseExpand(
                                                c.courseId,
                                                !c.isExpanded
                                              );
                                              getCanvasSections(c.courseId);
                                            }}
                                          />
                                        )}
                                        <label className="flex items-center gap-2 cursor-pointer w-full">
                                          <input
                                            type="checkbox"
                                            x-model="value"
                                            name="privacy_setting"
                                            value="Private to Project Members"
                                            disabled={c.isImported}
                                            className="h-4 w-4 mt-0.5 cursor-pointer text-blue-600 border-gray-300 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed"
                                            checked={
                                              c.isImported
                                                ? c.isImported
                                                : c.isSelected
                                            }
                                            onChange={(e) => {
                                              handleSelectClasses(
                                                c.courseId,
                                                e.target.checked
                                              );
                                            }}
                                          />

                                          <span className="ml-2 text-primary-violet text-base font-semibold">
                                            {c.name}{" "}
                                            {c.code
                                              ? "(code - " + c.code + ")"
                                              : ""}
                                          </span>
                                        </label>
                                      </div>
                                      {c.isExpanded && (
                                        <div className="px-3 py-3 bg-gray-100">
                                          <div className="bg-white">
                                            <table className="table w-full shadow">
                                              <tr className="bg-light-violet  text-white py-3 px-3">
                                                <th
                                                  scope="col"
                                                  className="text-left  px-3 xl:px-2 xl:py-3 1xl:p-3 text-sm font-normal"
                                                >
                                                  Section
                                                </th>
                                              </tr>
                                              <tbody>
                                                {(c.sections &&
                                                  c.sections.length &&
                                                  c.sections.map((s, i) => (
                                                    <tr
                                                      className={`
                                              ${
                                                i % 2 === 0
                                                  ? "bg-white"
                                                  : "bg-light-violet/10"
                                              }
                                            `}
                                                    >
                                                      <td className="p-3 text-sm text-gray-500 w-1/3 text-left">
                                                        <label className="flex items-center gap-2 ml-5 cursor-pointer">
                                                          <input
                                                            type="checkbox"
                                                            x-model="value"
                                                            name="privacy_setting"
                                                            value="Private to Project Members"
                                                            disabled={
                                                              s.isImported
                                                            }
                                                            className="h-4 w-4 mt-0.5 cursor-pointer text-blue-600 border-gray-300 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed"
                                                            checked={
                                                              s.isImported
                                                                ? s.isImported
                                                                : s.isSelected ??
                                                                  false
                                                            }
                                                            onChange={(e) => {
                                                              handleSelectSections(
                                                                s.courseId,
                                                                s.sectionId,
                                                                e.target.checked
                                                              );
                                                            }}
                                                          />
                                                          {s.name}
                                                        </label>
                                                      </td>
                                                    </tr>
                                                  ))) || (
                                                  <tr>
                                                    <td className="px-3 xl:px-2 xl:py-3 1xl:p-3 bg-white text-sm text-gray-500">
                                                      No Data
                                                    </td>
                                                  </tr>
                                                )}
                                              </tbody>
                                            </table>
                                          </div>
                                        </div>
                                      )}
                                    </td>
                                  </tr>
                                </>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    {classes && classes.length > 0 && hasMore && (
                      <div className="flex justify-end mt-2">
                        <button
                          className="bg-transparent border border-primary-violet shadow-sm py-2 px-4 inline-flex justify-center items-center text-sm font-medium text-primary-violet hover:text-white hover:bg-light-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3 transition ease-in-out duration-500"
                          type="button"
                          onClick={() => {
                            loadMore(pageNumber + 1, pageSize);
                          }}
                        >
                          <span className="text-sm">Load More</span>
                        </button>
                      </div>
                    )}
                  </div>
                  {classes && classes.length === 0 && <NoData></NoData>}
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(ImportCanvasClasses);

import { Fragment, useEffect, useState } from "react";
import {
  getSkillStandard,
  getSkillSubject,
} from "../../../../../api/student/skillPracticeApi";
import { ISkillSubject } from "../../../../../model/skillPractice/skillSubject";
import Loader from "../../../../shared/loader";
import classNames from "classnames";
import Profile from "../../../../../model/users/profile";
import constant from "../../../../../utils/constant/constant";
import { Configuration } from "../../../../../environment/setup";

interface SkillSubjectProps {
  onSubjectSelected: (skillSubjectId: number, subjectName: string) => void;
  isOpened: boolean;
  profile?: Profile;
}

function SkillSubject(props: SkillSubjectProps) {
  const [skillSubjects, setSkillSubjects] = useState<Array<ISkillSubject>>([]);
  const [selectedSubject, setSelectedSubject] = useState<number>(0);
  const [loading, showLoading] = useState<boolean>(false);
  const elaIcon: string = `${Configuration.S3bucketImagePath}/Icons/ContentArea/reading.svg`;
  const mathIcon: string = `${Configuration.S3bucketImagePath}/Icons/ContentArea/math.svg`;
  const socialStudyIcon: string = `${Configuration.S3bucketImagePath}/Icons/ContentArea/social-science.svg`;

  useEffect(() => {
    if (props.isOpened === true && skillSubjects.length === 0) {
      showLoading(true);
      getSkillStandard(0, 0, props.profile?.gradeId ?? 0)
        .then((d) => {
          if (d.data.data.length > 0) {
            loadSubjects(d.data.data.map((s) => s.skillSubjectId));
          } else {
            showLoading(false);
          }
        })
        .catch(() => {
          showLoading(false);
        });
    }
  }, [props.isOpened]);

  function loadSubjects(skillSubjectIds) {
    getSkillSubject()
      .then((d) => {
        const validSkillSubjects = d.data.data.filter((s) =>
          skillSubjectIds.includes(s.skillSubjectId)
        );
        setSkillSubjects(validSkillSubjects);
        showLoading(false);
        setSelectedSubject(validSkillSubjects[0].skillSubjectId);
        props.onSubjectSelected(
          validSkillSubjects[0].skillSubjectId,
          (props.profile?.plLanguageId ?? 1) === constant.Languages.Spanish
            ? d.data.data[0].spanishName
            : d.data.data[0].name
        );
      })
      .catch(() => {
        showLoading(false);
      });
  }
  function getSubjectIcon(subjectName: string) {
    if (subjectName === "Math") {
      return mathIcon;
    } else if (subjectName === "Reading") {
      return elaIcon;
    } else if (subjectName === "SS") {
      return socialStudyIcon;
    }
  }

  return (
    <Fragment>
      <div className={`block bg-[#fe3bac] p-1 rounded-2xl relative`}>
        {loading && <Loader />}
        <div className="grid lg:grid-cols-4 sm:grid-cols-2 bg-white border-[#e3178d] border-[3px] px-3 py-4 rounded-2xl gap-3">
          {skillSubjects.map((item, index) => (
            <button
              key={index}
              onClick={() => {
                props.onSubjectSelected(
                  item.skillSubjectId,
                  (props.profile?.plLanguageId ?? 1) ===
                    constant.Languages.Spanish
                    ? item.spanishName
                    : item.name
                );
                setSelectedSubject(item.skillSubjectId);
              }}
              className={classNames(
                "flex px-2 py-1.5 border-[3px] cursor-pointer items-center text-left rounded-lg",
                selectedSubject === item.skillSubjectId
                  ? "bg-[#e3178d] text-white hover:bg-opacity-90 border-[#F9D228] border-dashed"
                  : "border-[#e3178d] opacity-70 hover:opacity-100"
              )}
            >
              <span className="mr-2 w-6 flex-none">
                <img
                  className={`w-full  ${
                    selectedSubject === item.skillSubjectId
                      ? "filter invert"
                      : ""
                  }`}
                  srcSet={
                    item.subjectIcon
                      ? item.subjectIcon
                      : getSubjectIcon(item.name)
                  }
                  alt="Subject Icon"
                />
              </span>

              {props.profile?.plLanguageId === constant.Languages.Spanish
                ? item.spanishName ?? item.name
                : item.name}
            </button>
          ))}
        </div>
      </div>
    </Fragment>
  );
}

export default SkillSubject;

import { Fragment } from "react";
import { Configuration } from "../../../../../environment/setup";

type studentGradeModeType =
  | "Kindergarten"
  | "Elementary"
  | "MiddleSchool"
  | "LiftOff";
interface AlienAvatarProps {
  studentId: number;
  gradeId: number;
  alienData: any;
  showOnQuestions: boolean;
  studentGradeMode: studentGradeModeType;
  applykinderGartenStyle?: boolean;
  isLiftOff?: boolean;
}

function StudentAlienArenaResults(props: AlienAvatarProps) {
  return (
    <Fragment>
      <div
        className={`${
          props.studentGradeMode === "Elementary" ? "" : ""
        } rounded-2xl bg-white cursor-pointer h-full w-full py-4 px-4 text-center rounded-xl hover:shadow-2xl transition ease-in-out duration-500`}
      >
        <div className="px-2 sm:px-6 lg:px-8 py-6">
          <h1 className="text-lg text-gra-700 mb-2 text-left">Question 3</h1>
          <div className="w-full flex flex-col xl:flex-row gap-x-6">
            <div className="flex flex-col gap-y-6 md:gap-y-0 md:flex-row gap-x-6 w-full">
              <div className="w-full md:w-84 shadow-lg border rounded-md p-6 text-center">
                <h2 className="text-gray-700 text-xl font-light mb-2">
                  Round Winners
                </h2>
                <div className="relative flex flex-col items-center">
                  <img
                    src={`${Configuration.S3bucketImagePath}/images/SpaceCadet.png`}
                    className="w-20 mx-auto"
                    alt="Space Cadet"
                  />
                  <span className="font-medium text-gray-800 bg-green-400 inline-block px-3 py-1 rounded-md mt-3">
                    Shooting Stars
                  </span>
                </div>
              </div>
              <div className="w-full shadow-lg border rounded-md p-6 bg-[#ff4655] ">
                <div className="shadow overflow-x-auto rounded-lg">
                  <table className="w-full table">
                    <thead className="bg-gray-50">
                      <tr className="bg-light-violet text-white ">
                        <th className="px-3 py-3 text-left text-xs font-medium text-gray-500 capitalize tracking-wider bg-gray-100">
                          Team
                        </th>
                        <th className="px-3 py-3 text-left text-xs font-medium text-gray-500 capitalize tracking-wider bg-gray-100">
                          Question Performance
                        </th>
                        <th className="px-3 py-3 text-center text-xs font-medium text-gray-500 capitalize tracking-wider bg-gray-100">
                          Game Score
                        </th>
                        <th className="px-3 py-3 text-center text-xs font-medium text-gray-500 capitalize tracking-wider bg-gray-100">
                          Game
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="bg-white">
                        <td className="px-3 py-2  text-sm text-gray-500 text-left">
                          Rocketeers
                        </td>
                        <td className="px-3 py-2  text-sm text-gray-500 text-left">
                          0 of 0 correct
                        </td>
                        <td className="px-3 py-2  text-sm text-gray-500 text-center">
                          0
                        </td>
                        <td className="px-3 py-2  text-sm text-gray-500 text-center">
                          <svg
                            className="w-5 mx-auto text-green-500 cursor-pointer"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 256.000000 256.000000"
                            preserveAspectRatio="xMidYMid meet"
                          >
                            <g
                              transform="translate(0.000000,256.000000) scale(0.100000,-0.100000)"
                              fill="currentColor"
                              stroke="none"
                            >
                              <path d="M629 2177 c-78 -22 -145 -82 -171 -154 -10 -30 -13 -180 -13 -743 0 -656 1 -708 18 -752 20 -52 80 -112 136 -136 59 -25 147 -20 209 12 155 79 1211 681 1235 703 92 89 92 257 0 346 -24 22 -1087 628 -1230 701 -62 31 -126 39 -184 23z"></path>
                            </g>
                          </svg>
                        </td>
                      </tr>
                      <tr className="bg-white bg-opacity-80">
                        <td className="px-3 py-2  text-sm text-gray-500 text-left">
                          Shooting Stars
                        </td>
                        <td className="px-3 py-2  text-sm text-gray-500 text-left">
                          1 of 1 correct
                        </td>
                        <td className="px-3 py-2  text-sm text-gray-500 text-center">
                          0
                        </td>
                        <td className="px-3 py-2  text-sm text-gray-500 text-center"></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="w-full mt-6 xl:mt-0 xl:w-[37rem] bg-gray-50 p-6 rounded-md shadow-lg border flex items-center">
              <img
                src={`${Configuration.S3bucketImagePath}/images/Alien%20Professor.png`}
                className="w-28 transform scale-x-[-1]"
                alt="Alien Professor"
              />
              <div className="ml-3">
                <h2 className="text-gray-700 text-xl font-light">
                  Alien Explanation
                </h2>
                <button
                  className="bg-primary-violet border border-transparent shadow-sm mt-2 py-2 px-4 justify-center text-sm font-medium text-white hover:bg-dark-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  type="button"
                >
                  View Explanation
                </button>
              </div>
            </div>
          </div>
          <div className="w-full flex flex-col gap-y-6 xl:gap-y-0 xl:flex-row gap-x-6 mt-6">
            <div className="w-full xl:w-1/2 bg-gray-50 p-6 rounded-md border shadow-lg text-left">
              <h2 className="text-gray-700 text-xl font-light mb-2">
                Question Summary
              </h2>
              <span className="text-sm text-gray-500">
                View each question your students answered and display which
                students got the answer correct or incorrect. Click the current
                question to review or explain the question to the class after
                the students answer.
              </span>
              <div className="flex flex-wrap gap-1.5 mt-5">
                <div className="flex w-8 h-8 bg-green-400 shadow rounded-md text-sm text-white font-semibold justify-center items-center hover:bg-green-500 cursor-pointer">
                  1
                </div>
                <div className="flex w-8 h-8 bg-green-400 shadow rounded-md text-sm text-white font-semibold justify-center items-center hover:bg-green-500 cursor-pointer">
                  2
                </div>
                <div className="flex w-8 h-8 bg-green-400 shadow rounded-md text-sm text-white font-semibold justify-center items-center hover:bg-green-500 cursor-pointer">
                  3
                </div>
                <div className="flex w-8 h-8 bg-red-400 shadow rounded-md text-sm text-white font-semibold justify-center items-center hover:bg-red-500 cursor-pointer">
                  4
                </div>
              </div>
            </div>
            <div className="w-full xl:w-1/2 shadow-lg border rounded-md p-6 text-left">
              <span className="text-gray-700 bg-gray-100 text-xl font-light inline-block px-2 rounded-md border">
                <span className="text-primary-violet font-semibold mr-1">
                  50%
                </span>
                Correct
              </span>
            </div>
          </div>
        </div>
        <div className="text-center mt-6">
          <button
            className="bg-primary-violet border border-transparent shadow-sm mt-2 py-2 px-4 justify-center text-sm font-medium text-white hover:bg-dark-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            type="button"
          >
            Next Question
          </button>
        </div>
      </div>
    </Fragment>
  );
}

export default StudentAlienArenaResults;

import { AxiosResponse } from "axios";
import { ISpeechMarks } from "../../component/screen/student/shared/textToSpeech/textToSpeechConfig";
import { AxiosHelper } from "../../environment/axiosConfig";
import APIConstant from "../../utils/constant/apiConstant";
import { IPassageSpeech } from "../../model/passageSpeech";

export function getSpeechFromText(
  text: string,
  language: string,
) {
  return AxiosHelper.axiosInstance().post(APIConstant.TTS_API.GetAudioFromText,
    {
      text,
      lang: language
    },
    {
      responseType: "arraybuffer"
    }
  )
}

export function getSpeechMarks(
  text: string,
  language: string
): Promise<AxiosResponse<Array<ISpeechMarks>>> {
  return AxiosHelper.axiosInstance().post(APIConstant.TTS_API.GetSpeechMarksFromText,
    {
      text,
      lang: language
    }
  );
}

export function createPassageSpeech(
  subjectId: number,
  passages: Array<IPassageSpeech>
): Promise<AxiosResponse<boolean>> {
  return AxiosHelper.axiosInstance().post(
    APIConstant.TTS_API.CreatePassageSpeech,
    {
      subjectId,
      passages,
    }
  );
}

export function getSubjectUsesSpeechMarks(
  subjectId: number,
): Promise<AxiosResponse<boolean>> {
  return AxiosHelper.axiosInstance().get(APIConstant.TTS_API.GetSubjectUsesSpeechMarks,
    {
      params: {
        subjectId
      }
    }
  )
}
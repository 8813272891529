import constant from "../../utils/constant/constant";
import Constant from "../../utils/constant/constant";
import Environments from "../../utils/constant/environment";
import routeConstant from "../../utils/constant/routeConstant";
import RouteConstant from "../../utils/constant/routeConstant";
import permissions from "../../utils/permissions";

const side = [
  {
    id: 1,
    menu: "Home",
    url: RouteConstant.ROUTE_DASHBOARD,
    isPopup: false,
    isExternalUrl: false,
    icon: Constant.Menu.HOME,
    permission: permissions.dashboard_view,
    roles: [
      Constant.UserRoleId.SchoolAdmin,
      Constant.UserRoleId.SuperAdmin,
      Constant.UserRoleId.SchoolTeacher,
      Constant.UserRoleId.District,
      Constant.UserRoleId.FreeTeacher,
    ],
    submenu: [],
  },
  {
    id: 2,
    menu: "School Center",
    url: "",
    isPopup: false,
    isExternalUrl: false,
    icon: Constant.Menu.SCHOOLCENTER,
    roles: [
      Constant.UserRoleId.SchoolAdmin,
      Constant.UserRoleId.District,
      constant.UserRoleId.SchoolTeacher,
      constant.UserRoleId.PayTeacher,
      Constant.UserRoleId.FreeTeacher,
    ],
    submenu: [
      {
        name: "User Management",
        route: RouteConstant.TeacherRoutes.manageUser,
        icon: Constant.Menu.USERMANAGEMENT,
        permission: permissions.user_management_view,
      },
      {
        name: "Liftoff Management",
        route: routeConstant.TeacherRoutes.UserLiftOffManagement,
        icon: Constant.Menu.LIFTOFF_MANAGEMENT,
        permission: permissions.liftoff_management_view,
      },
      {
        name: "Account Information",
        route: routeConstant.UserProfile,
        icon: Constant.Menu.ACCOUNT_INFORMATION,
        permission: permissions.account_information_view,
      },
      {
        name: "NWEA Mapping",
        route: RouteConstant.TeacherRoutes.nweaMapping,
        icon: Constant.Menu.NWEAMAPPING,
        permission: permissions.nwea_mapping_view,
        roles: [Constant.UserRoleId.SchoolAdmin],
      },
      {
        name: "Student View",
        isPopup: true,
        route: "StudentView",
        restrictImpersonatedUser: true,
        icon: Constant.Menu.STUDENTVIEW,
        permission: permissions.studentview_view,
      },
      {
        name: "Student Settings",
        route: routeConstant.TeacherRoutes.StudentSetting,
        icon: Constant.Menu.STUDENT_SETTINGS,
        permission: permissions.student_settings_view,
        roles: [
          Constant.UserRoleId.SchoolAdmin,
          Constant.UserRoleId.SchoolTeacher,
        ],
      },
    ],
  },
  {
    id: 3,
    menu: "Class Center",
    url: "",
    isPopup: false,
    isExternalUrl: false,
    icon: Constant.Menu.CLASSCENTER,
    roles: [
      Constant.UserRoleId.SchoolAdmin,
      Constant.UserRoleId.SuperAdmin,
      Constant.UserRoleId.SchoolTeacher,
      Constant.UserRoleId.FreeTeacher,
    ],
    submenu: [
      {
        name: "Class Management",
        route: RouteConstant.TeacherRoutes.Classes,
        icon: Constant.Menu.CLASSMANAGEMENT,
        permission: permissions.class_management_view,
      },
      {
        name: "Student View",
        isPopup: true,
        route: "StudentView",
        restrictImpersonatedUser: true,
        icon: Constant.Menu.STUDENTVIEW,
        permission: permissions.studentview_view,
        upgradePermission: permissions.studentview_upgrade,
      },
    ],
  },
  {
    id: 4,
    menu: "Assessment/Assignment Center",
    url: RouteConstant.TeacherRoutes.assignmentCenter,
    isPopup: false,
    isExternalUrl: false,
    icon: Constant.Menu.ASSIGNMENT,
    permission: permissions.assessment_center_view,
    roles: [
      Constant.UserRoleId.SchoolAdmin,
      Constant.UserRoleId.SuperAdmin,
      Constant.UserRoleId.SchoolTeacher,
      Constant.UserRoleId.FreeTeacher,
    ],
    submenu: [],
  },
  {
    id: 11,
    menu: "Assessment Center",
    url: RouteConstant.TeacherRoutes.assignmentCenter,
    isPopup: false,
    isExternalUrl: false,
    icon: Constant.Menu.ASSIGNMENT,
    permission: permissions.assessment_center_view,
    roles: [Constant.UserRoleId.District],
    submenu: [],
  },
  {
    id: 5,
    menu: "Instructional Resources",
    url: RouteConstant.TeacherRoutes.InstructionalResourceSubjects,
    isPopup: false,
    isExternalUrl: false,
    icon: Constant.Menu.INSTRUCTIONALRESOURCES,
    permission: permissions.instructional_resources_view,
    roles: [
      Constant.UserRoleId.SchoolAdmin,
      Constant.UserRoleId.SuperAdmin,
      Constant.UserRoleId.SchoolTeacher,
      Constant.UserRoleId.District,
      Constant.UserRoleId.FreeTeacher
    ],
    submenu: [],
  },

  {
    id: 6,
    menu: "Reports",
    url: RouteConstant.REPORTS,
    isPopup: false,
    isExternalUrl: false,
    icon: Constant.Menu.REPORT,
    permission: permissions.dashboard_view,
    roles: [
      Constant.UserRoleId.SchoolAdmin,
      Constant.UserRoleId.SuperAdmin,
      Constant.UserRoleId.SchoolTeacher,
      Constant.UserRoleId.District,
      Constant.UserRoleId.FreeTeacher,
    ],
    submenu: [],
  },
  {
    id: 9,
    menu: "Training",
    url: "",
    isPopup: false,
    isExternalUrl: false,
    icon: Constant.Menu.TRAINING,
    permission: permissions.training_center_view,
    roles: [],
    submenu: [],
  },
  {
    id: 10,
    menu: "Family Connect",
    url: "",
    isPopup: true,
    isExternalUrl: false,
    icon: Constant.Menu.FAMILYCONNECT,
    roles: [Constant.UserRoleId.Student],
    permission: permissions.family_connect_view,
    submenu: [],
  },
  {
    id: 11,
    menu: "Classroom Game",
    url: RouteConstant.CLASSROOM_GAME,
    isPopup: false,
    isExternalUrl: false,
    icon: Constant.Menu.CLASSROOM_GAME,
    permission: permissions.dashboard_view,
    roles: [
      Constant.UserRoleId.SchoolAdmin,
      Constant.UserRoleId.SuperAdmin,
      Constant.UserRoleId.SchoolTeacher
    ],
    submenu: [],
    environments: [
      Environments.Environment.LOCAL,
      Environments.Environment.DEV,
      Environments.Environment.QA,
      Environments.Environment.PRODUCTION,
      Environments.Environment.SANDBOX,
      Environments.Environment.STAGING,
    ],
  }
];

export default side;

import { IAssignment } from "../../model/interface/assignment";
import { IAssignmentActivity } from "../../model/interface/assignmentActivity";
import APIConstant from "../../utils/constant/apiConstant";
import { IAssignmentSearch } from "../../model/interface/assignmentSearch";
import { AxiosHelper } from "../../environment/axiosConfig";
import { IClassStudent } from "../../model/interface/classStudent";
import auth from "../../utils/auth";
import StudentRemediation from "../../model/teacher/assignment/studentRemediation";
import {
  AssignmentType,
  ClassType,
} from "../../model/report/LiveMonitoringActivity";
import { AxiosResponse } from "axios";
import { IAssignmentStatus } from "../../model/teacher/assignment/assignmentStatus";
import { formatDate } from "../../utils/helper";
import {IResult} from "../../model/interface/IResult";

export const getAssociatedSubjects = (
  teacherId: number,
  subjectTypeId?: number,
  schoolId?: number,
  classId?: number
) => {
  return AxiosHelper.axiosInstance().get(APIConstant.APP_SUBJECTS_ALL, {
    params: {
      TeacherId: teacherId,
      SubjectTypeId: subjectTypeId,
      schoolId: schoolId,
      classId: classId,
    },
  });
};

export const getDistrictAdminSubjects = (userId: number) => {
  return AxiosHelper.axiosInstance().get(
    APIConstant.APP_DISTRICT_ADMIN_SUBJECTS,
    {
      params: {
        UserId: userId,
      },
    }
  );
};

export const getAllDomainsBySubject = (subjectId) => {
  return AxiosHelper.axiosInstance().get(APIConstant.CMS_DOMAIN_ALL, {
    params: {
      SubjectId: subjectId,
      OrderByColumn: "LoadOrder",
      SortDir: "Asc",
      IsActive: true,
      isApproved: true,
    },
  });
};

export const getAllCannedTestsBySubject = (subjectId) => {
  return AxiosHelper.axiosInstance().get(APIConstant.CMS_CANNEDTEST_ALL, {
    params: {
      SubjectId: subjectId,
      CannedTestTypeId: 3,
      OrderByColumn: "Name",
      SortDir: "Asc",
      IsActive: true,
      isApproved: true,
    },
  });
};

export const getAllStandardByDomains = (
  domainIds: string,
  crStandardsOnly: boolean = false,
  hideHiddenStandards: boolean = false
) => {
  return AxiosHelper.axiosInstance().get(APIConstant.APP_STANDARD_ALL, {
    params: {
      DomainIds: domainIds,
      crStandardsOnly: crStandardsOnly,
      hideHiddenStandards: hideHiddenStandards,
    },
  });
};

export const getVideoLessonByStandards = (
  standardIds: string,
  subjectId: number
) => {
  return AxiosHelper.axiosInstance().get(APIConstant.APP_QUESTION_ALL, {
    params: {
      StandardIds: standardIds,
      SubjectId: subjectId,
    },
  });
};

export const getConstructedResponseByStandards = (
  standardIds: string,
  userId: number,
  isAuthoredQuestion: boolean
) => {
  return AxiosHelper.axiosInstance().get(
    APIConstant.ASSIGNMENT_API.GetConstructedResponse,
    {
      params: {
        StandardIds: standardIds,
        UserId: userId,
        IsAuthoredQuestion: isAuthoredQuestion,
      },
    }
  );
};

export const addAssignment = (
  teacherId: number,
  name: string,
  subjectId: number,
  activities: Array<IAssignmentActivity>,
  schoolYearId: number
) => {
  const params = {
    name: name,
    subjectId: subjectId,
    userId: teacherId,
    activities: activities,
    schoolYearId: schoolYearId,
  };

  return AxiosHelper.axiosInstance().post(
    APIConstant.ASSIGNMENT_API.Assignment,
    params,
    {}
  );
};

export const updateAssignmentData = (
  teacherId: number,
  assignment: IAssignment
) => {
  const params = {
    AssignmentId: assignment.assignmentId,
    UserId: teacherId,
    Name: assignment.name,
    SubjectId: assignment.subjectId,
    AssignmentTextNote: assignment.textNote,
    AssignmentVideoNote: assignment.videoNote,
    StartDate: formatDate(assignment.startDate),
    EndDate: formatDate(assignment.endDate),
    IsSharedWithSchool: assignment.isSharedWithSchool,
    DisplayAnswerExplanation: assignment.displayAnswerExplanation,
    CompleteInOrder: assignment.completeInOrder,
    TextToSpeech: assignment.textToSpeech,
    AssignmentStatus: assignment.assignmentStatusId,
    ShowDetailsAfterDueDate: assignment.showDetailsAfterDueDate,
    CanvasAccessToken: auth.getCanvasAccessToken(),
    SchoolYearId: assignment.schoolYearId,
  };

  return AxiosHelper.axiosInstance().put(
    APIConstant.ASSIGNMENT_API.Assignment,
    params,
    {}
  );
};

export const addAssignmentActivity = (
  teacherId: number,
  assignmentId: number,
  assignmentName: string,
  subjectId: number,
  activities: Array<IAssignmentActivity>,
  isSubjectChanged: boolean = false,
  isActivityUpdated: boolean = false,
  activityTypeId: number = 0,
  isDeleteAllActivityTypes: boolean = false
) => {
  const params = {
    assignmentId: assignmentId,
    name: assignmentName,
    subjectId: subjectId,
    activities: activities,
    userId: teacherId,
    isSubjectChanged: isSubjectChanged,
    isActivityUpdated: isActivityUpdated,
    activityTypeId: activityTypeId,
    isDeleteAllActivityTypes: isDeleteAllActivityTypes,
  };

  return AxiosHelper.axiosInstance().post(
    APIConstant.ASSIGNMENT_API.Assignment,
    params,
    {}
  );
};

export const updateActivitySetting = (
  teacherId: number,
  activities: Array<IAssignmentActivity>
) => {
  const params = {
    activities: activities,
    userId: teacherId,
  };

  return AxiosHelper.axiosInstance().put(
    APIConstant.ASSIGNMENT_API.UpdateActivitySettings,
    params,
    {}
  );
};

export const getAssignments = (assignmentSearch: IAssignmentSearch) => {
  return AxiosHelper.axiosInstance().get(
    APIConstant.ASSIGNMENT_API.Assignment,
    {
      params: {
        teacherId: assignmentSearch.teacherId,
        roleId: assignmentSearch.roleId,
        authorId: assignmentSearch.authorId,
        studentId: assignmentSearch.studentId,
        assessmentId: assignmentSearch.assessmentId,
        classId: assignmentSearch.classId,
        subjectId: assignmentSearch.subjectId,
        isAssigned: assignmentSearch.isAssigned,
        isArchived: assignmentSearch.isArchived,
        dateType: assignmentSearch.dateType,
        fromDate: assignmentSearch.fromDate,
        toDate: assignmentSearch.toDate,
        name: assignmentSearch.name,
        assignmentType: assignmentSearch.assignmentType,
        pageNumber: assignmentSearch.pageNumber,
        pageSize: assignmentSearch.pageSize,
        orderByColumn: assignmentSearch.orderByColumn,
        sortByDesc: assignmentSearch.sortByDesc,
        schoolYearId: assignmentSearch.schoolYearId
          ? assignmentSearch.schoolYearId
          : 1,
        usePrimaryDataSource: assignmentSearch.usePrimaryDataSource,
        includeActivitiesInformation: assignmentSearch.includeActivitiesInformation,
      },
    }
  );
};

export const getAssignmentById = (
  assignmentId: number,
  schoolYearId?: number | null,
  includeCrRelatedSettings: boolean = false
) => {
  return AxiosHelper.axiosInstance().get(APIConstant.ASSIGNMENT_API.GetById, {
    params: {
      assignmentId: assignmentId,
      includeCrRelatedSettings: includeCrRelatedSettings,
      schoolYearId: schoolYearId
    },
  });
};

export const getAssignmentNoteById = (assignmentId: number) => {
  return AxiosHelper.axiosInstance().get(APIConstant.ASSIGNMENT_API.Note, {
    params: {
      assignmentId: assignmentId,
    },
  });
};

export const getAssignmentStudents = (
  teacherId: number,
  roleId: number,
  isClassAssignment: boolean = false,
  isRemediateAssignment: boolean = false
) => {
  return AxiosHelper.axiosInstance().get(
    APIConstant.ASSIGNMENT_API.GetStudents,
    {
      params: {
        teacherId: teacherId,
        roleId: roleId,
        isClassAssignment: isClassAssignment,
        isRemediateAssignment: isRemediateAssignment,
      },
    }
  );
};

export const getAssignmentClasses = (
  teacherId: number,
  roleId: number,
  isClassAssignment: boolean = false,
  isRemediateAssignment: boolean = false
) => {
  return AxiosHelper.axiosInstance().get(
    APIConstant.ASSIGNMENT_API.GetClasses,
    {
      params: {
        teacherId: teacherId,
        roleId: roleId,
        isClassAssignment: isClassAssignment,
        isRemediateAssignment: isRemediateAssignment,
      },
    }
  );
};

export const getAssignmentSubjects = (
  teacherId: number,
  roleId: number,
  isClassAssignment: boolean = false,
  isRemediateAssignment: boolean = false
) => {
  return AxiosHelper.axiosInstance().get(
    APIConstant.ASSIGNMENT_API.GetSubjects,
    {
      params: {
        teacherId: teacherId,
        roleId: roleId,
        isClassAssignment: isClassAssignment,
        isRemediateAssignment: isRemediateAssignment,
      },
    }
  );
};

export const getSharedAssignmentSubjects = (
  teacherId: number,
  roleId: number
) => {
  return AxiosHelper.axiosInstance().get(
    APIConstant.ASSIGNMENT_API.GetSharedassignmentSubjects,
    {
      params: {
        teacherId: teacherId,
        roleId: roleId,
      },
    }
  );
};

export const getAssignmentAuthors = (teacherId: number, roleId: number) => {
  return AxiosHelper.axiosInstance().get(
    APIConstant.ASSIGNMENT_API.GetAuthors,
    {
      params: {
        teacherId: teacherId,
        roleId: roleId,
      },
    }
  );
};

export const updateAssignments = (
  teacherId: number,
  assignmentIds: string,
  action: string,
  isArchived: boolean = false
) => {
  const params = {
    teacherId: teacherId,
    assignmentIds: assignmentIds,
    action: action,
    isArchived: isArchived,
    googleAccessToken: auth.getGoogleAccessToken(),
  };

  return AxiosHelper.axiosInstance().put(
    APIConstant.ASSIGNMENT_API.UpdateAssignments,
    params,
    {}
  );
};

export const duplicateAssignment = (
  teacherId: number,
  assignmentId: number
) => {
  const params = {
    teacherId: teacherId,
    assignmentId: assignmentId,
  };

  return AxiosHelper.axiosInstance().post<IResult<number>>(
    APIConstant.ASSIGNMENT_API.DuplicateAssignment,
    params,
    {}
  );
};

export const addStudents = (
  teacherId: number,
  assignments: Array<number>,
  students: Array<IClassStudent>,
  googleUserId: number,
  isUpdate: boolean = false
) => {
  const params = {
    teacherId: teacherId,
    assignments: assignments,
    students: students,
    isUpdate,
    googleUserId: googleUserId,
    googleAccessToken: auth.getGoogleAccessToken(),
  };

  return AxiosHelper.axiosInstance().post(
    APIConstant.ASSIGNMENT_API.AddStudents,
    params,
    {}
  );
};

export const addRemediation = (
  teacherId: number,
  remediationSourceId: number,
  subjectId: number,
  isClassRemediation: boolean,
  StudentRemediations: Array<StudentRemediation>,
  startDate: string,
  endDate: string,
  assignmentRemediationActivityId?: number,
  assessmentId?: number,
  assignmentName: string = ""
) => {
  const params = {
    teacherId: teacherId,
    remediationSourceId: remediationSourceId,
    assessmentId: assessmentId,
    subjectId: subjectId,
    assignmentRemediationActivityId: assignmentRemediationActivityId,
    isClassRemediation: isClassRemediation,
    startDate: startDate,
    endDate: endDate,
    StudentRemediations: StudentRemediations,
    assignmentName: assignmentName,
  };

  return AxiosHelper.axiosInstance().post(
    APIConstant.ASSIGNMENT_API.CreateRemediation,
    params,
    {}
  );
};

export const getRemediatedStudents = (assignmentActivityId: number) => {
  return AxiosHelper.axiosInstance().get(
    APIConstant.ASSIGNMENT_API.GetRemediatedStudents,
    {
      params: {
        assignmentActivityId: assignmentActivityId,
      },
    }
  );
};

export const getActivityDetail = (assignmentActivityId: number) => {
  return AxiosHelper.axiosInstance().get(
    APIConstant.ASSIGNMENT_API.GetActivityDetails,
    {
      params: {
        activityId: assignmentActivityId,
      },
    }
  );
};

export const getAllClassesByAssignment = (
  schoolYearId: number,
  assignmentId: number,
  assessmentId: number,
  teacherId: number,
  roleId: number,
  schoolId?: number,
  includeArchived?: boolean,
) => {
  return AxiosHelper.axiosInstance().get(
    APIConstant.ASSIGNMENT_API.GetClassesById,
    {
      params: {
        assignmentId: assignmentId,
        assessmentId: assessmentId,
        teacherId: teacherId,
        RoleId: roleId,
        schoolId: schoolId,
        includeArchived: includeArchived,
        schoolYearId: schoolYearId,
      },
    }
  );
};

export const getAssignmentActivityStandardsById = (
  assignmentActivityId: number
) => {
  return AxiosHelper.axiosInstance().get(
    APIConstant.ASSIGNMENT_API.GetActivityStandardsById,
    {
      params: {
        assignmentActivityId: assignmentActivityId,
      },
    }
  );
};

export const getAssignmentByAssignmentActivityId = (
  assignmentId: number,
  assignmentActivityId: number,
  schoolYearId?: number
) => {
  return AxiosHelper.axiosInstance().get(
    APIConstant.ASSIGNMENT_API.GetByAssignmentActivityId,
    {
      params: {
        assignmentId: assignmentId,
        assignmentActivityId: assignmentActivityId,
        schoolYearId: schoolYearId,
      },
    }
  );
};

export const getAssignmentFilterForLiveMonitoringReport = (
  assignmentSearch
) => {
  return AxiosHelper.axiosInstance().get<Array<AssignmentType>>(
    APIConstant.LIVEMONITORINGREPORT_API.GetAssignmentFilter,
    {
      params: {
        subjectId: assignmentSearch.subjectId,
      },
    }
  );
};
export const getClassFilterForLiveMonitoringReport = (classSearch) => {
  return AxiosHelper.axiosInstance().get<Array<ClassType>>(
    APIConstant.LIVEMONITORINGREPORT_API.GetClassFilter,
    {
      params: {
        assignmentId: classSearch.assignmentId,
      },
    }
  );
};

export function getAssignmentStatus(
  assignmentId: number,
  studentUserId: number | null
): Promise<AxiosResponse<IAssignmentStatus>> {
  return AxiosHelper.axiosInstance().get<IAssignmentStatus>(
    APIConstant.ASSIGNMENT_API.GetAssignmentStatus,
    {
      params: {
        assignmentId: assignmentId,
        studentUserId: studentUserId,
      },
    }
  );
}

export const getAssignmentQuestionReferences = (activityReference: string) => {
  return AxiosHelper.axiosInstance().get(
    APIConstant.ASSIGNMENT_API.GetQuestionReferences,
    {
      params: {
        activityReference: activityReference,
      },
    }
  );
};

import React from "react";
import ComponentRegistry from "./componentRegistry";

const DynamicComponent = (item: any) => {
  if (typeof ComponentRegistry[item.component] !== "undefined") {
    return React.createElement(
      ComponentRegistry[item.component],
      item.parameter
    );
  }
  return React.createElement(() => <div></div>);
};

export default DynamicComponent;

import { Fragment, useState } from "react";
import { ChevronUpIcon, ChevronDownIcon } from "@heroicons/react/solid";
import Constant from "../../utils/constant/constant";
import { SortBothIcon } from "../../assets/icons/index";

interface TabProps {
  sortColumn: string;
  onClick: (sortColumn: string, sortBy: number) => void;
}

function TableSort(props: TabProps) {
  const [sortBy, setSortBy] = useState(Constant.SortBy.NONE);

  function toggleSort() {
    let sortByValue = "";
    switch (sortBy) {
      case Constant.SortBy.NONE:
        sortByValue = Constant.SortBy.ASC;
        break;
      case Constant.SortBy.ASC:
        sortByValue = Constant.SortBy.DESC;
        break;
      case Constant.SortBy.DESC:
        sortByValue = Constant.SortBy.ASC;
        break;
      default:
        break;
    }

    setSortBy(sortByValue);
    const sortDirection = sortByValue === Constant.SortBy.ASC ? 0 : 1;
    props.onClick(props.sortColumn, sortDirection);
  }

  return (
    <Fragment>
      {sortBy === Constant.SortBy.NONE && (
        <SortBothIcon
          className="w-1.5 ml-1 text-white fill-current"
          onClick={() => toggleSort()}
        />
      )}

      {sortBy === Constant.SortBy.ASC && (
        <ChevronUpIcon className="w-4 ml-1" onClick={() => toggleSort()} />
      )}

      {sortBy === Constant.SortBy.DESC && (
        <ChevronDownIcon className="w-4 ml-1" onClick={() => toggleSort()} />
      )}
    </Fragment>
  );
}

export default TableSort;

import APIConstant from "../../utils/constant/apiConstant";
import { AxiosHelper } from "../../environment/axiosConfig";
import { AxiosResponse } from "axios";
import CannedTestList from "../../model/student/shared/cannedTest/cannedTestList";
import CannedTestInstruction from "../../model/student/shared/cannedTest/cannedTestInstruction";
import { IInitalizeStudyPlanResponse } from "../../model/student/studyplan/initializeStudyPlanRespons";
import StandardEffitiencyReport from "../../model/student/shared/cannedTest/standardEffitiencyReport";
import { ISection } from "../../model/interface/section";

export function getCannedTests(
  studentId: number,
  subjectId: number,
  isDiagnostics: boolean = true
): Promise<AxiosResponse<Array<CannedTestList>>> {
  return AxiosHelper.axiosInstance().get(
    APIConstant.StudentCannedTest_API.CannedTests,
    {
      params: {
        studentId: studentId,
        subjectId: subjectId,
        isDiagnostics: isDiagnostics,
      },
    }
  );
}

export function initializeCannedTest(
  userId: number,
  cannedTestId: number,
  classId: number,
  teacherId: number,
  languageId: number,
  gradeId: number
): Promise<AxiosResponse<IInitalizeStudyPlanResponse>> {
  return AxiosHelper.axiosInstance().post<IInitalizeStudyPlanResponse>(
    APIConstant.StudentCannedTest_API.Initialize,
    {
      userId,
      cannedTestId,
      classId,
      teacherId,
      languageId,
      gradeId,
    }
  );
}

export function completeCannedTest(
  activitySessionId: number,
  studentId?: number,
  learnositySessionId?: string,
  statusId?: number
): Promise<AxiosResponse<number>> {
  return AxiosHelper.axiosInstance().put(
    APIConstant.StudentCannedTest_API.Complete,
    {
      studentId: studentId,
      learnositySessionId: learnositySessionId,
      statusId: statusId,
      activitySessionId: activitySessionId,
    }
  );
}

export function getCannedTestReport(
  studentId: number,
  learnosityReferenceId: string,
  learnositySessionId: string
): Promise<AxiosResponse<StandardEffitiencyReport>> {
  return AxiosHelper.axiosInstance().get(
    APIConstant.StudentCannedTest_API.Report,
    {
      params: {
        studentId: studentId,
        learnosityReferenceId: learnosityReferenceId,
        learnositySessionId: learnositySessionId,
      },
    }
  );
}
export function getCannedTestSections(
  cannedTestId: number,
  selectedLanguage: string
): Promise<AxiosResponse<Array<ISection>>> {
  return AxiosHelper.axiosInstance().get(
    APIConstant.StudentCannedTest_API.Sections,
    {
      params: {
        cannedTestId: cannedTestId,
        selectedLanguage: selectedLanguage,
      },
    }
  );
}
export function getCannedTestInstructions(
  cannedTestId: number,
  selectedLanguage: string
): Promise<AxiosResponse<Array<CannedTestInstruction>>> {
  return AxiosHelper.axiosInstance().get(
    APIConstant.StudentCannedTest_API.Instructions,
    {
      params: {
        cannedTestId: cannedTestId,
        selectedLanguage: selectedLanguage,
      },
    }
  );
}

export function getCannedTestResult(sessionId: string): Promise<any> {
  return AxiosHelper.axiosInstance().get<any>(
    APIConstant.StudentCannedTest_API.Result,
    {
      params: { sessionId: sessionId },
    }
  );
}

export function updatCannedTest(
  studentCannedTestId: number,
  teacherId: number,
  classId: number
): Promise<any> {
  return AxiosHelper.axiosInstance().put<any>(
    APIConstant.StudentCannedTest_API.Update,
    {
      studentCannedTestId: studentCannedTestId,
      teacherId: teacherId,
      classId: classId,
    }
  );
}

export function getCannedTestTotalQuestionCount(
  cannedTestId: number,
  cannedTestTypeId: number
) {
  return AxiosHelper.axiosInstance().get<any>(
    APIConstant.StudentCannedTest_API.TotalQuestionCount,
    {
      params: { cannedTestId, cannedTestTypeId },
    }
  );
}

export function getCannedTestLastSession(userId: number, cannedTestId: number, languageId: number) {
  return AxiosHelper.axiosInstance().get<any>(
    APIConstant.StudentCannedTest_API.LastSession,
    {
      params: { userId: userId, cannedTestId, languageId },
    }
  );
}

export function completeCannedTestStudyPlan(
  userId: number,
  sessionId: string,
  activitySessionId: number
): Promise<AxiosResponse<number>> {
  return AxiosHelper.axiosInstance().put<number>(
    APIConstant.StudentCannedTest_API.Complete,
    {
      userId: userId,
      sessionId: sessionId,
      activitySessionId: activitySessionId,
    }
  );
}

export function getInProgressCannedTests(
  userId: number
): Promise<AxiosResponse<Array<any>>> {
  return AxiosHelper.axiosInstance().get<Array<any>>(
    APIConstant.StudentCannedTest_API.InProgress,
    {
      params: { userId: userId },
    }
  );
}

export function abandon(
  userId: number,
  sessionId: string
): Promise<AxiosResponse<number>> {
  return AxiosHelper.axiosInstance().put<number>(
    APIConstant.StudentCannedTest_API.Abandon,
    {
      userId: userId,
      sessionId: sessionId,
    }
  );
}

export function getCannedTestQuestionStandards( cannedTestId: number) {
  return AxiosHelper.axiosInstance().get<any>(
    APIConstant.StudentCannedTest_API.Standards,
    {
      params: { cannedTestId },
    }
  );
}
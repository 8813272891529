import { CSSProperties, useEffect, useState } from "react";
import { connect } from "react-redux";
import Select, { MultiValue, SingleValue } from "react-select";
import { ISelectElement } from "../../../model/interface/selectElement";
import constant from "../../../utils/constant/constant";
import { Info, LogoPLShootingStarIcon } from "../../../assets/icons";
import Asterisk from "../asterisk";

interface AssignmentProps {
  label?: string;
  infoTitle?: string;
  defaultText?: string;
  selectedItems: Array<ISelectElement>;
  setSelectedAssignment: (selectedItems: Array<ISelectElement>) => void;
  isMulti?: boolean;
  isRequired?: boolean;
  subjectTypeId?: number;
  themeColor?: string;
  assignments?: Array<any>;
  hideAdditionalSubjects?: boolean;
  isDisabled?: boolean;
  showDiagTest?: boolean;
  diagTestTypeIds?: Array<number>;
}
function Assignment(props: AssignmentProps & any) {
  const plAssignmentLabel: string = "Premium Assignments";
  const {
    infoTitle,
    selectedItems,
    setSelectedAssignment,
    label,
    defaultText,
  } = props;
  const [assignments, setAssignments] = useState<Array<any>>([]);
  const groupStyles = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    fontSize: 14,
  };
  const groupBadgeStyles: CSSProperties = {
    backgroundColor: "#EBECF0",
    borderRadius: "2em",
    color: "#172B4D",
    display: "inline-block",
    fontSize: 14,
    fontWeight: "normal",
    lineHeight: "1",
    minWidth: 1,
    padding: "0.16666666666667em 0.5em",
    textAlign: "center",
  };

  const colourStyles = {
    option: (provided, state) => ({
      ...provided,
      color:
        state.label === "My Subjects" || state.label === "Additional Subjects"
          ? props.themeColor ?? "#992883"
          : "default",
    }),
  };

  const categorizeOptions = async (response: any) => {
    const results = response;
    const additionalRoles: Array<number> = [
      constant.UserRoleId.SchoolAdmin,
      constant.UserRoleId.SchoolTeacher,
      constant.UserRoleId.District,
    ];
    const showAdditionalAssignment: boolean = additionalRoles.some(
      (r) => r === props.userContext.roleId
    );
    const myAssignments: Array<any> = [];
    const additionalAssignments: Array<any> = [];

    const additionalAssignmentCount = results.filter(
      (r) => r.isAdditional === true
    ).length;
    var options = getSortedAssignments(results, false);

    var additionalOptions = getSortedAssignments(results, true);

    var plPremiumAssignments: Array<any> = [];
    if (props.showDiagTest) {
      var diagTests = getDiagnosticTests(results, props.diagTestTypeIds);
      plPremiumAssignments.push(...diagTests);
    }
    let cannedTest = getCannedTests(results);
    if (cannedTest && cannedTest.length > 0) {
      plPremiumAssignments.push(...cannedTest);
    }
    if (plPremiumAssignments.length > 0) {
      myAssignments.push({
        label: plAssignmentLabel,
        options: plPremiumAssignments,
      });
    }

    myAssignments.push({
      label: "My Assignments",
      options: options,
    });

    additionalAssignments.push({
      label: "Additional Assignemnts",
      options: additionalOptions,
    });

    let assignmentOptions = myAssignments;
    if (showAdditionalAssignment && additionalAssignmentCount > 0) {
      assignmentOptions.push(...additionalAssignments);
    }

    setAssignments(assignmentOptions);
  };
  const formatAdditionalAssignmentName = (value) => {
    return (
      value?.name +
      " - " +
      value?.authorLastName +
      ", " +
      value?.authorFirstName
    );
  };
  const getCannedTests = (results: any) => {
    var assignments: Array<ISelectElement> = results
      .filter((r) => r.diagTestTypeId === 3)
      .map((values: any) => {
        return {
          label: values?.name,
          value: values?.assignmentId,
          isCannedTest: true,
        };
      });
    return assignments;
  };
  const getSortedAssignments = (results: any, isAdditional: boolean) => {
    var assignments: Array<ISelectElement> = results
      .filter(
        (r) => r.isAdditional === isAdditional && r.diagTestTypeId == null
      )
      .map((values: any) => {
        return {
          label: isAdditional
            ? formatAdditionalAssignmentName(values)
            : values?.name,
          value: values?.assignmentId,
          isCannedTest: false,
        };
      });
    return assignments;
  };

  const getDiagnosticTests = (results: any, diagTestTypeIds: Array<number>) => {
    var tests: Array<ISelectElement> = results
      .filter((r) => r.diagTestTypeId in diagTestTypeIds)
      .map((values: any) => {
        return {
          label: values?.name,
          value: values?.assignmentId,
          isCannedTest: true,
        };
      });
    return tests;
  };

  function onChange(options: MultiValue<ISelectElement> | SingleValue<ISelectElement> | null) {
    if(!options) return;

    let selectedValues: Array<ISelectElement>;

    if (Array.isArray(options)) {
      // It's a multi-value (an array), so we can use map
      selectedValues = options.map(option => option);
    } else {
      // It's a single value (not an array), so we treat it as a single ISelectElement      
      selectedValues = [options as ISelectElement];
    }

    if (selectedValues && selectedValues.length > 0) {
      const lastSelectedValue = selectedValues[selectedValues.length - 1];
      if (lastSelectedValue.value === "") {
        selectedValues = [lastSelectedValue];
      } else if (
        selectedValues.some((r) => r.value === "") &&
        selectedValues.some((r) => r.value !== "")
      ) {
        selectedValues = selectedValues.filter((r) => r.value !== "");
      }
    } else {
      selectedValues = [
        { label: defaultText ?? "Select Assignment", value: "0" },
      ];
    }
    setSelectedAssignment(selectedValues);
  }

  const formatGroupLabel = (data: any) => {
    return (
      <div style={groupStyles}>
        <span className="flex gap-x-1">
          {data.label == plAssignmentLabel && (
            <LogoPLShootingStarIcon></LogoPLShootingStarIcon>
          )}
          {data.label}
        </span>
        <span style={groupBadgeStyles}>{data.options.length}</span>
      </div>
    );
  };

  useEffect(() => {
    if (props.assignments) {
      categorizeOptions(props.assignments);
    }
  }, [props.assignments]);

  return (
    <>
      <div className="nested-optgroup">
        <div>
          <label className="block text-sm text-gray-500">
            {label ? label : "Assignment"} {props.isRequired && <Asterisk />}
          </label>
          {infoTitle && <Info title={infoTitle} />}
        </div>
        <div className="mt-1">
          <Select
            value={selectedItems}
            options={assignments}
            isClearable
            isSearchable
            formatGroupLabel={formatGroupLabel}
            formatOptionLabel={(value) => {
              let isDiagnostic =
                value.label.includes("Diagnostic") && value.label.length < 12;
              if (isDiagnostic) {
                return (
                  value.label +
                  ` - ${label.includes("Pre-Test") ? "Pre-Test" : "Post-Test"}`
                );
              }
              return value.label;
            }}
            getOptionValue={(value) => {
              return value.value;
            }}
            onChange={onChange}
            isMulti={props.isMulti ?? true}
            closeMenuOnSelect={!(props.isMulti ?? true)}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary: props.themeColor ?? "#041E42",
              },
            })}
            styles={colourStyles}
            isDisabled={props.isDisabled ?? false}
          />
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(Assignment);

import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import Constant from "../../utils/constant/constant";
import RouteConstant from "../../utils/constant/routeConstant";

function Forbidden(props: any) {
  const history = useHistory();

  function handleGoBack() {
    const roleId = props.userContext.roleId;
    const gradeId = props.userContext.gradeId;
    if (
      roleId === Constant.UserRoleId.SchoolAdmin ||
      roleId === Constant.UserRoleId.PayTeacher ||
      roleId === Constant.UserRoleId.FreeTeacher ||
      roleId === Constant.UserRoleId.SchoolTeacher ||
      roleId === Constant.UserRoleId.District ||
      roleId === Constant.UserRoleId.SuperAdmin ||
      roleId === 0
    ) {
      history.push(RouteConstant.ROUTE_DASHBOARD);
    } else if (roleId === Constant.UserRoleId.Student) {
      if (gradeId <= Constant.Grade.GRADE1) {
        history.push(RouteConstant.KindergartenStudentRoutes.Home);
      } else if (
        gradeId > Constant.Grade.GRADE1 &&
        gradeId < Constant.Grade.GRADE6
      ) {
        history.push(RouteConstant.StudentRoute.Home);
      } else if (gradeId >= Constant.Grade.GRADE6) {
        history.push(RouteConstant.MiddleSchool.assignments);
      } else {
        history.push(RouteConstant.StudentRoute.Home);
      }
    } else {
      history.push(RouteConstant.TeacherRoutes.assignmentCenter);
    }
  }

  return (
    <div className="bg-gradient-to-b shadow-xl from-primary-violet to-dark-teal h-screen flex justify-center items-center">
      <div className=" bg-white w-full  md:w-3/4 xl:w-1/2 xxxl:w-1/3 flex flex-col items-center py-12 px-4 rounded-lg mx-8 lg:mx-0">
        <h1 className="text-9xl font-bold text-primary-violet">403</h1>
        <p className="text-gray-700 text-4xl font-semibold text-center mt-2">
          <span className="text-red-400">Oops!</span> Unauthorized access.
        </p>
        <a
          className="bg-light-violet cursor-pointer hover:opacity-90 mt-8 px-8 h-12 xxxl:h-14 text-lg xxxl:text-xl flex items-center justify-center text-white hover:text-white font-Barlow font-semibold"
          onClick={handleGoBack}
        >
          Go to home
        </a>
      </div>
    </div>
  );
}

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(Forbidden);

import { UserContextState } from "./../redux/actions/userContextAction";
import { login } from "../api/login.service";
import * as userContextAction from "../redux/actions/userContextAction";
import ImpersonateUserModel from "../model/users/impersonateUser";
import Constant from "./constant/constant";
import RouteConstant from "./constant/routeConstant";
import { jwtToken } from "../component/shared/tokenHelper";

export function impersonateUser(impersonateUser: ImpersonateUserModel) {
  login(
    impersonateUser.userName,
    impersonateUser.password,
    impersonateUser.accountLoginName,
    0,
    "",
    "",
    "",
    "",
    true,
    impersonateUser.impersonatedByUserId
  ).then((response) => {
    const newUserToken = response.data["access_token"];
    localStorage.setItem("AuthToken", newUserToken);

    const user: any = jwtToken(newUserToken);
    const userId = isNaN(user.userId) ? 0 : parseInt(user.userId);
    const roleId = isNaN(user.roleId) ? 0 : parseInt(user.roleId);
    const linkedUserId = user.linkedUserId;
    const schoolId =
      isNaN(user.schoolId) || user.schoolId === ""
        ? 0
        : parseInt(user.schoolId);
    const schoolAccountId =
      isNaN(user.schoolAccountId) || user.schoolAccountId === ""
        ? 0
        : parseInt(user.schoolAccountId);
    const gradeId = isNaN(user.gradeId) ? 0 : parseInt(user.gradeId);
    const accountId = isNaN(user.accountId) ? 0 : parseInt(user.accountId);
    const districtId = isNaN(user.districtId) ? 0 : parseInt(user.districtId);
    const stateId = isNaN(user.stateId) ? 0 : parseInt(user.stateId);

    var context: userContextAction.UserContextState = {
      userId: userId,
      roleId: roleId,
      gradeId: gradeId,
      schoolId: schoolId,
      accountId: accountId,
      districtId: districtId,
      googleId: "",
      activeExternalRoster: "",
      stateId: stateId,
      schoolAccountId: schoolAccountId,
      linkedUserId: linkedUserId,
      impersonatedUser:
        impersonateUser.impersonatedByUserId === 0
          ? null
          : {
              userId: impersonateUser.impersonatedByUserId ?? 0,
              token: impersonateUser.impersonatedByUserToken ?? "",
              returnUrl: impersonateUser.returnUrl,
              activeUserExternalRoster:
                impersonateUser.impersonatedByUserExternalRoster ?? "",
            },
    };
    if (impersonateUser.setUserContext !== undefined)
      impersonateUser.setUserContext(context);

    if (impersonateUser.setProfile !== undefined)
      impersonateUser.setProfile(userId, roleId, schoolId, 0);

    handleRedirection(roleId, gradeId, impersonateUser.history);
  });
}
const handleRedirection = (
  roleId: number,
  gradeId: number = 0,
  history: any
) => {
  if (
    roleId === Constant.UserRoleId.SchoolAdmin ||
    roleId === Constant.UserRoleId.PayTeacher ||
    roleId === Constant.UserRoleId.FreeTeacher ||
    roleId === Constant.UserRoleId.SchoolTeacher ||
    roleId === Constant.UserRoleId.District ||
    roleId === Constant.UserRoleId.SuperAdmin ||
    roleId === 0
  ) {
    history.push(RouteConstant.ROUTE_DASHBOARD);
  } else if (roleId === Constant.UserRoleId.Student) {
    if (gradeId <= Constant.Grade.GRADE1) {
      history.push(RouteConstant.KindergartenStudentRoutes.Home);
    } else if (
      gradeId > Constant.Grade.GRADE1 &&
      gradeId < Constant.Grade.GRADE6
    ) {
      history.push(RouteConstant.StudentRoute.Home);
    } else if (gradeId >= Constant.Grade.GRADE6) {
      history.push(RouteConstant.MiddleSchool.home);
    } else {
      history.push(RouteConstant.StudentRoute.Home);
    }
  } else {
    history.push(RouteConstant.TeacherRoutes.assignmentCenter);
  }
};

export function backToOriginalUser(
  userContext: UserContextState | undefined,
  setUserContext: Function | undefined,
  setProfile: Function | undefined,
  history: any
) {
  const teacherToken = userContext?.impersonatedUser?.token ?? "";
  const returnUrl = userContext?.impersonatedUser?.returnUrl ?? "";
  const activeExternalRoster =
    userContext?.impersonatedUser?.activeUserExternalRoster ?? "";
  localStorage.setItem("AuthToken", teacherToken);

  const user: any = jwtToken(teacherToken);
  const userId = isNaN(user.userId) ? 0 : parseInt(user.userId);
  const roleId = isNaN(user.roleId) ? 0 : parseInt(user.roleId);
  const accountId = isNaN(user.accountId) ? 0 : parseInt(user.accountId);
  const linkedUserId = user.linkedUserId;
  const schoolId =
    isNaN(user.schoolId) || user.schoolId === "" ? 0 : parseInt(user.schoolId);
  const schoolAccountId =
    isNaN(user.schoolAccountId) || user.schoolAccountId === ""
      ? 0
      : parseInt(user.schoolAccountId);
  const districtId = isNaN(user.districtId) ? 0 : parseInt(user.districtId);

  var context: userContextAction.UserContextState = {
    userId: userId,
    roleId: roleId,
    gradeId: 0,
    schoolId: schoolId,
    accountId: accountId,
    districtId: districtId,
    stateId: 0,
    googleId: "",
    activeExternalRoster: activeExternalRoster,
    schoolAccountId: schoolAccountId,
    impersonatedUser: null,
    linkedUserId: linkedUserId,
  };
  if (setUserContext !== undefined) {
    setUserContext(context);
  }

  if (setProfile !== undefined) {
    setProfile(userId, roleId, schoolId, 0);
  }

  localStorage.removeItem(Constant.TTS.SPEAKER_MODE_KEY);

  history.push(returnUrl);
}

import { IPremiumAssessmentDTO } from "../model/interface/assessment";
import { IAssignmentActivity } from "../model/interface/assignmentActivity";
import { IClassStudent } from "../model/interface/classStudent";
import { IStudyPlanStandard } from "../model/interface/studyPlanStandard";
import { ITreeItem } from "../model/interface/treeItem";
import ActivityListResponse from "../model/student/shared/assignment/activityListResponse";
import { StudentAssignmentSetting } from "../types/type";
import Constant from "./constant/constant";
import routeConstant from "./constant/routeConstant";
import { IStandardEfficiencyParams } from "./standardEfficiencyHelper";
import { paramEncode } from "./urlHelper";
import MyAssignmentDetails from "../model/teacher/assignment";

function getSubActivityName(
  activity: IAssignmentActivity | ActivityListResponse,
  languageId: number
) {
  let activityName: string = "";
  if (activity.activityType === Constant.AssignmentActivityType.ASSESSMENT) {
    if (activity.cannedTestId) {
      activityName = getPremiumAssessmentName(
        activity.cannedTestName!,
        activity.cannedTestType!,
        activity.cannedTestTypeId!
      );
    } else if (activity.assessmentName) {
      activityName = activity.assessmentName;
    }
  } else if (
    activity.activityType === Constant.AssignmentActivityType.PREBUILTTESTS
  ) {
    if (activity.summativeTestName) {
      activityName = activity.summativeTestName;
    } else if (activity.domainName) {
      activityName =
        languageId === 2
          ? activity.spanishDomainName ?? activity.domainName
          : activity.domainName;
    } else if (activity.standardName) {
      activityName =
        languageId === 2
          ? activity.spanishStandardName ?? activity.standardName
          : activity.standardName;
    } else if (activity.cannedTestName) {
      activityName = activity.cannedTestName;
    }
  }

  return activityName;
}

export function getActivityName(
  activity: IAssignmentActivity | ActivityListResponse,
  languageId: number = 1
) {
  let displayName: string = "";
  let activityType = activity.activityType
    ? activity.activityType
    : activity.assignmentType;
  switch (activityType) {
    case Constant.AssignmentActivityType.ASSESSMENT:
      displayName = bindAssignmentName(
        languageId === 2
          ? Constant.AssignmentActivityType_Spanish.ASSESSMENT
          : Constant.AssignmentActivityType.ASSESSMENT,
        getSubActivityName(activity, languageId)
      );
      break;
    case Constant.AssignmentActivityType.PREBUILTTESTS:
      const summativeTestTitle =
        languageId === 2 ? "Prueba de Evaluación" : "Summative Test";
      const domainTitle =
        languageId === 2 ? "Prueba de Dominio" : "Domain Test";
      const assignmentTypeTitle = activity.summativeTestId
        ? summativeTestTitle
        : domainTitle;
      displayName = bindAssignmentName(
        assignmentTypeTitle,
        getSubActivityName(activity, languageId)
      );
      break;
    case Constant.AssignmentActivityType.PRACTICEQUESTIONS:
      displayName = bindAssignmentName(
        languageId === 2
          ? Constant.AssignmentActivityType_Spanish.PRACTICEQUESTIONS
          : Constant.AssignmentActivityType.PRACTICEQUESTIONS,
        activity.isCombinedActivity
          ? "Mixed Standard Practice Questions"
          : languageId === 2
          ? activity.spanishStandardName
          : activity.standardName
      );
      break;
    case Constant.AssignmentActivityType.VIDEOLESSON:
      displayName = bindAssignmentName(
        languageId === 2
          ? Constant.AssignmentActivityType_Spanish.VIDEOLESSON
          : Constant.AssignmentActivityType.VIDEOLESSON,
        activity.questionText
      );

      break;
    case Constant.AssignmentActivityType.CONSTRUCTEDRESPONSE:
      displayName = bindAssignmentName(
        languageId === 2
          ? Constant.AssignmentActivityType_Spanish.CONSTRUCTEDRESPONSE
          : Constant.AssignmentActivityType.CONSTRUCTEDRESPONSE,
        ""
      );
      break;
  }

  return displayName;
}

export function bindAssignmentName(assignmentType, assignmentName) {
  let displayName =
    (assignmentType ? assignmentType : "") +
    (assignmentName ? " - " + assignmentName : "");
  return displayName;
}

export function stripHTML(myString) {
  return myString ?? "".replace(/(<([^>]+)>)/gi, "");
}

export function getAssignmentStatusClassname(assignmentStatusId: number) {
  switch (assignmentStatusId) {
    case 0:
      return "bg-gray-200 text-gray-700";
    case 1:
      return "bg-yellow-500";
    case 2:
      return "bg-blue-500";
    case 3:
      return "bg-green-500";
    default:
      return "";
  }
}

export function getStudyplanAssignmentStatusClassname(
  standard: IStudyPlanStandard
) {
  const assignmentStatusId = standard.assignmentStatusId;
  const videoAssignmentStatusId = standard.videoAssignmentStatusId;
  const completedAssignments = standard.completedAssignments;
  const completedVideoQuestions = standard.completedVideoQuestions;
  const totalVideoQuestions = standard.totalVideoQuestions;

  const earnedStar = standard.earnedStar;

  if (
    completedAssignments > 0 &&
    completedVideoQuestions === totalVideoQuestions &&
    earnedStar >= 2
  ) {
    return "bg-green-500";
  }

  if (assignmentStatusId === 0 && videoAssignmentStatusId === 0) {
    return "bg-gray-200 text-gray-700";
  } else if (assignmentStatusId === 1 && videoAssignmentStatusId === 1) {
    return "bg-yellow-500";
  } else if (assignmentStatusId === 2 || videoAssignmentStatusId === 2) {
    return "bg-yellow-500";
  } else if (assignmentStatusId === 3 && videoAssignmentStatusId === 3) {
    return "bg-blue-500";
  } else if (assignmentStatusId === 0 && videoAssignmentStatusId === 3) {
    return "bg-blue-500";
  } else if (assignmentStatusId === 3 && videoAssignmentStatusId === 0) {
    return "bg-blue-500";
  } else {
    return "";
  }
}

export function getRecommendedAssignmentStatusClassname(
  standard: IStudyPlanStandard
) {
  const assignmentStatusId = standard.assignmentStatusId;

  const earnedStar = standard.earnedStar;

  if (assignmentStatusId === 3 && earnedStar === 3) {
    return "bg-green-500";
  }

  if (assignmentStatusId === 0) {
    return "bg-gray-200 text-gray-700";
  } else if (assignmentStatusId === 1) {
    return "bg-yellow-500";
  } else if (assignmentStatusId === 2) {
    return "bg-yellow-500";
  } else if (assignmentStatusId === 3) {
    return "bg-blue-500";
  } else {
    return "";
  }
}

export function getDiagnosticStatusClassname(statusId: number, status: string) {
  if (status.toLowerCase() === "locked") {
    return "bg-red-400";
  } else if (statusId === 0) {
    return "bg-gray-200 text-gray-700";
  } else if (statusId === 1) {
    return "bg-yellow-500";
  } else if (statusId === 2) {
    return "bg-yellow-500";
  } else if (statusId === 3) {
    return "bg-blue-500";
  } else {
    return "";
  }
}

export function redirectToActivity(
  history: any,
  activity: ActivityListResponse,
  studentAssignmentRoute: string,
  languageId?: number
) {
  history.push({
    pathname: studentAssignmentRoute
      .replace(
        ":assignmentActivityId",
        encodeURI(btoa(activity.assignmentActivityId.toString()))
      )
      .replace(
        ":assignmentActivityName",
        encodeURI(paramEncode(getActivityName(activity, languageId ?? 1)))
      )
      .replace(
        ":lernosityActivityIdEN",
        encodeURI(
          btoa(activity.learnosityIdEnglish ? activity.learnosityIdEnglish : "")
        )
      )
      .replace(
        ":lernosityActivityIdES",
        encodeURI(
          btoa(
            activity.learnosityIdSpanish
              ? activity.learnosityIdSpanish
              : activity.learnosityIdEnglish
              ? activity.learnosityIdEnglish
              : ""
          )
        )
      )
      .replace(":assignmentType", encodeURI(btoa(activity.activityType ?? "")))
      .replace(
        ":assignmentId",
        encodeURI(btoa(activity.assignmentId.toString()))
      )
      .replace(":subjectId", encodeURI(btoa(activity.subjectId.toString())))
      .replace(
        ":multipleAttemptAllowed",
        encodeURI(btoa(activity.multipleAttempt.toString()))
      ),
    state: {
      prebuildTestType:
        (activity?.summativeTestId ?? 0) > 0
          ? (activity?.summativeTestName ?? "") + " Practice Test"
          : (activity?.domainId ?? 0) > 0
          ? "Practice Test - " + activity?.domainName ?? ""
          : "",
    },
  });
}

export function getPremiumAssessmentName(
  name: string,
  cannedTestType: string,
  cannedTestTypeId: number
) {
  return cannedTestTypeId === Constant.CannedTestTypeId.PreTest ||
    cannedTestTypeId === Constant.CannedTestTypeId.PostTest
    ? "Diagnostic " + cannedTestType
    : name;
}

export function getStudentSettingValue(
  classId: number,
  studentId: number,
  student: ITreeItem,
  setting: StudentAssignmentSetting,
  selectedStudents: IClassStudent[]
) {
  const selectedstudent = selectedStudents.find(
    (s) => s.classId === classId && s.studentId === studentId
  );
  let value: any;

  switch (setting) {
    case "TTS":
      const assignmentTTS_Setting = selectedstudent
        ? selectedstudent?.isTTSActive!
        : null;
      const globalTTS_Setting = student.isTTSActive!;
      value =
        assignmentTTS_Setting != null
          ? assignmentTTS_Setting
          : globalTTS_Setting;
      break;

    case "Language":
      const assignmentLanguage_Setting = selectedstudent
        ? selectedstudent?.languageSettingId!
        : null;
      const globalLanguage_Setting = student.languageSettingId!;
      value =
        assignmentLanguage_Setting != null
          ? assignmentLanguage_Setting
          : globalLanguage_Setting;
      break;
  }

  return value;
}

export function isActivityValid(learnosityId: string) {
  if (learnosityId === "") {
    return false;
  }
  return true;
}

export function getPremiumAssessmentNameWithSubjectPrefix(
  assessment: IPremiumAssessmentDTO
) {
  if (
    assessment.cannedTestTypeId === Constant.CannedTestTypeId.PreTest ||
    assessment.cannedTestTypeId === Constant.CannedTestTypeId.PostTest
  ) {
    return (
      assessment.subjectName +
      " - " +
      getPremiumAssessmentName(
        assessment.name,
        assessment.cannedTestType,
        assessment.cannedTestTypeId
      )
    );
  } else {
    return getPremiumAssessmentName(
      assessment.name,
      assessment.cannedTestType,
      assessment.cannedTestTypeId
    );
  }
}

export function canTeacherViewItemDetails(
  roleId: number,
  assignmentResult: any
) {
  let isAllowed = true;
  const assignmentActivityTypeId = assignmentResult.assignmentActivityTypeId;
  const assessmentSharedTypeId = assignmentResult.assessmentSharedTypeId;
  const canView = assignmentResult.canViewAssessment;

  if (
    assignmentActivityTypeId ===
      Constant.AssignmentActivityTypeEnum.ASSESSMENT &&
    assessmentSharedTypeId !== null &&
    canView !== null
  ) {
    if (
      roleId === Constant.UserRoleId.SchoolAdmin &&
      assessmentSharedTypeId === Constant.AssessmentSharedType.DistrictShared
    ) {
      isAllowed = canView;
    } else if (
      (roleId === Constant.UserRoleId.SchoolTeacher ||
        roleId === Constant.UserRoleId.PayTeacher) &&
      assessmentSharedTypeId === Constant.AssessmentSharedType.SchoolShared
    ) {
      isAllowed = canView;
    }
  }

  return isAllowed;
}

export function canStudentViewItemDetails(assignmentResult: any) {
  const resultDisplayTypeId = assignmentResult.assessmentResultDisplayTypeId;
  const assignmentActivityTypeId = assignmentResult.assignmentActivityTypeId;
  const showDetailsAfterDueDate = assignmentResult.showDetailsAfterDueDate;
  const isDueDatePassed = !assignmentResult.attemptAgain; // if dueDate is greater than current date then attemptAgain is true
  let isAllowed = false;
  const isPremiumAssessment =
    assignmentResult.learnosityActivityId.indexOf("CannedTest") > -1
      ? true
      : false;
  if (
    assignmentActivityTypeId ===
      Constant.AssignmentActivityTypeEnum.ASSESSMENT &&
    !isPremiumAssessment
  ) {
    // Assessment & Premium assessment
    isAllowed =
      resultDisplayTypeId === 0 ||
      resultDisplayTypeId ===
        Constant.AssessmentResultDisplayTypeId.QuestionsAndAnswer ||
      resultDisplayTypeId ===
        Constant.AssessmentResultDisplayTypeId.QuestionsOnly;
  } else {
    // canned test, summative & domain, video lessons, practice questions, cr
    if (showDetailsAfterDueDate && isDueDatePassed) {
      // shows item details if the duedate is passed
      isAllowed = true;
    } else if (!showDetailsAfterDueDate) {
      // shows item details irrespective of duedate is passed
      isAllowed = true;
    }
  }
  return isAllowed;
}

export const canViewAssessmentExplanation = (
  assignmentActivityTypeId: number,
  assessmentResultDisplayTypeId: number,
  isTeacherView?: boolean
): boolean => {
  return (
    isTeacherView ||
    (assignmentActivityTypeId ===
      Constant.AssignmentActivityTypeEnum.ASSESSMENT &&
      (!assessmentResultDisplayTypeId || assessmentResultDisplayTypeId ===
        Constant.AssessmentResultDisplayTypeId.QuestionsAndAnswer))
  );
};

export const isGroupedAssignment = (
  hasActicity: boolean,
  activityCount: number
): boolean => {
  return hasActicity && activityCount > 1;
};

export const getContextMenuActivityType = (hasMultipleActivities: boolean) => {
  return !hasMultipleActivities
    ? Constant.ContextMenuActivityType.ASSIGNMENT
    : Constant.ContextMenuActivityType.GROUPEDASSIGNMENT;
};

export const getIsParentGroupedActivityType = (activityType: string) => {
  return activityType === Constant.ContextMenuActivityType.GROUPEDASSIGNMENT;
};

export const getSessionFromActivities = (activities: any): string => {
  if (!activities) return "";
  return activities[0]?.sessionId ?? "";
};

export function redirectStandardsEfficiencyReport(
  params: IStandardEfficiencyParams
) {
  let url = params.studentUrl;
  if (params.isTeacherView) {
    url = routeConstant.TeacherRoutes.StudentStandardEfficiencyReport.replace(
      ":gradeId",
      params.studentGradeId.toString()
    ).replace(":userId", params.studentId.toString());
  }
  url = url.replace(":sessionId", params.sessionId);
  params.history.push(url);
}

export function hasLockedPremiumAssessments(
  assignments: Array<MyAssignmentDetails>,
  assignmentId: number
) {
  const assignment = assignments.find(
    (s) => s.assignmentId?.toString() === assignmentId.toString()
  );
  return assignment?.hasLockedPremiumAssessments!;
}

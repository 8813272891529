import { AxiosHelper } from "../environment/axiosConfig";
import { Configuration } from "../environment/setup";

export function fetchToken(userId: number) {
  const url = `${Configuration.ApiBaseUrl}Profile/GetToken/${userId}`;
  return AxiosHelper.axiosInstance().get(url);
}

export function getToken(
  userName?: string,
  password?: string,
  loginMethod?: string,
  accessCode?: string,
  state?: string,
  googleInfo?: string
) {
  const url = `${Configuration.ApiBaseUrl}Identity/GetToken`;
  return AxiosHelper.axiosInstance().get(url, {
    params: {
      userName: userName,
      password: password,
      loginMethod: loginMethod,
      accessCode: accessCode,
      state: state,
      googleInfo: googleInfo,
    },
  });
}

export function downloadFile(data: any, fileName: string)
{
  const fileURL = URL.createObjectURL(data);
  const link = document.createElement("a");
  link.href = fileURL;
  link.download = fileName;
  link.setAttribute("target", "_blank");
  document.body.appendChild(link);
  link.click();
}
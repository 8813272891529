import classNames from "classnames";
import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useTranslation } from "react-i18next";
import { GoldCoinIcon } from "../../../../../../assets/icons";
import { getScoreLabel } from "../../../../../../utils/scoreHelper";

function OverviewResult(params: any) {
  const [studyPlanResult, setStudyPlanResult] = useState<any>();
  const { t } = useTranslation();
  const getRingColor = (value) => {
    let ringColor = "";
    switch (true) {
      case value < 63:
        ringColor = "#E53F71";
        break;
      case value >= 63 && value < 80:
        ringColor = "#FFDB3A";
        break;
      case value >= 80:
        ringColor = "#B2D136";
        break;
      default:
        ringColor = "bg-gray-700";
        break;
    }
    return ringColor;
  };

  function overview() {
    if (params.data) {
      const overviewResultInput = {
        results_header: {
          assignment: {
            result: {
              retry_percent: 0,
              percent:
                Number(params.data.totalAttempted) === 0
                  ? 0
                  : Math.round(
                      (Number(params.data.totalScore) /
                        Number(params.data.totalAttempted)) *
                        100
                    ),
              totalAttempted: params.data.totalAttempted,
              totalScore: params.data.totalScore,
            },
          },
          elapsed_time: params.data.sessionDuration,
          date_completed: params.data.dateCompleted,
          earnedStar: params.data.earnedStar,
          earnedToken: params.data.earnedToken,
          test: {
            name: params.data.standardName,
          },
          teacher: {
            first: params.data.teacherFirstName,
            last: params.data.teacherLastName,
          },
          student: {
            first: params.data.studentFirstName
              ? params.data.studentFirstName
              : "",
            last: params.data.studentLastName
              ? params.data.studentLastName
              : "",
          },
          class: {
            name: params.data.class,
          },
        },
      };
      setStudyPlanResult(overviewResultInput);
    }
  }
  useEffect(() => {
    overview();
  }, []);

  function needsGrading(): boolean {
    return (
      studyPlanResult?.results_header?.assignment?.result?.unmarked
    );
  }

  return (
    <>
      {studyPlanResult && (
        <div className="flex-none md:w-64 h-auto bg-blue-400 rounded-md shadow-md xl:mr-12 md:mr-12 shadow-lg">
          <div className="text-md text-white text-center px-12 md:px-5 ">
            <h2 className="text-lg text-white font-medium uppercase text-center my-4">
              {t("StudyPlanResult.Score")}
            </h2>
            {needsGrading() ? (
              <div
                style={{
                  width: 160,
                  height: 160,
                  display: "inline-grid",
                  fontSize: "17px;",
                }}
              >
                <CircularProgressbar
                  value={0}
                  text={`${studyPlanResult?.results_header?.assignment?.result?.percent}`}
                  styles={buildStyles({
                    pathColor: `${getRingColor(
                      studyPlanResult?.results_header?.assignment?.result
                        ?.percent
                    )}`,
                    textColor: "#374151",
                  })}
                />
              </div>
            ) : (
              <div
                style={{
                  width: 140,
                  height: 140,
                  display: "inline-grid",
                  position: "relative",
                }}
              >
                <CircularProgressbar
                  value={
                    studyPlanResult?.results_header?.assignment?.result?.percent
                  }
                  text={`${
                    studyPlanResult?.results_header?.assignment?.result
                      ?.retry_percent ||
                    studyPlanResult?.results_header?.assignment?.result?.percent
                  }%`}
                  styles={buildStyles({
                    pathColor: `${getRingColor(
                      studyPlanResult?.results_header?.assignment?.result
                        ?.percent
                    )}`,
                    textColor: "#374151",
                  })}
                  className="circular-bar-pending-size"
                />
                <span className="absolute top-1/2 left-1/2 transform -translate-x-2/4 -translate-y-2/4 pt-11 text-white">
                  (
                  {
                    getScoreLabel(studyPlanResult?.results_header?.assignment?.result
                      ?.totalScore)
                  }
                  /(
                  {
                    getScoreLabel(studyPlanResult?.results_header?.assignment?.result
                      ?.totalAttempted)
                  }
                  )
                </span>
              </div>
            )}
          </div>
          {<div className="w-full text-lg text-white text-center mb-4"></div>}

          <div className="flex flex-none flex-col bg-white px-7 py-4 mx-4 rounded-md mb-8 mt-10 shadow-lg">
            <div className="flex w-full flex-none justify-between">
              <div className="capitalize font-myriad font-semibold text-md">
                {t("StudyPlanResult.Score")}
              </div>

              <div className="h-6 ml-3 inline-block text-black-333 flex items-center px-2 font-roboto text-sm font-bold">
                {studyPlanResult?.results_header?.assignment?.result?.percent}%
                <span className="text-xs pl-1">
                  (
                  {
                    studyPlanResult?.results_header?.assignment?.result
                      ?.totalScore
                  }
                  /
                  {
                    studyPlanResult?.results_header?.assignment?.result
                      ?.totalAttempted
                  }
                  )
                </span>
              </div>
            </div>
            <div className="w-full block mt-2 relative">
              <div className="w-full h-2 bg-gray-300 rounded-md"></div>
              <div
                style={{
                  width: `${studyPlanResult?.results_header?.assignment?.result.percent}%`,
                }}
                className={
                  "h-2 bg-[" +
                  getRingColor(
                    studyPlanResult?.results_header?.assignment?.result?.percent
                  ) +
                  "] rounded-md absolute top-0 z-0"
                }
              ></div>
            </div>
          </div>

          <div className="hidden flex flex-none flex-col bg-white px-7 py-4 mx-4 rounded-md mb-8 mt-10 shadow-lg">
            <div className="flex flex-col w-full items-center ">
              <div className="capitalize font-myriad font-semibold text-lg text-gray-700 pb-2">
                {t("StudyPlanResult.TokenEarned")}
              </div>
              <div className="relative w-24 inline-block pb-10 ">
                <span className="inline-block absolute left-0">
                  <GoldCoinIcon className="w-9 h-9"></GoldCoinIcon>
                </span>
                <span className="inline-block absolute left-6">
                  <GoldCoinIcon className="w-9 h-9"></GoldCoinIcon>
                </span>
                <span className="inline-block absolute left-12">
                  <GoldCoinIcon className="w-9 h-9"></GoldCoinIcon>
                </span>
              </div>
            </div>
          </div>
          {params.showToken && (
            <div className="flex flex-none flex-col bg-white px-7 py-4 mx-4 rounded-md mb-8 mt-10 shadow-lg">
              <div className="flex flex-col w-full items-center ">
                <div className="capitalize font-myriad font-semibold text-lg text-gray-700 pb-2">
                  {t("StudyPlanResult.TokenEarned")}
                </div>
                <div className="relative w-24 inline-block pb-10 text-center">
                  {studyPlanResult?.results_header?.earnedToken > 0 &&
                    Array.from(
                      Array(studyPlanResult?.results_header?.earnedToken),
                      (e, i) => {
                        return (
                          <Fragment key={i}>
                            <span
                              className={classNames(
                                "inline-block absolute ",
                                i === 0 ? "left-0" : "",
                                i === 1 ? "left-4" : "",
                                i === 2 ? "left-8" : "",
                                i === 3 ? "left-12" : ""
                              )}
                            >
                              <GoldCoinIcon className="w-9 h-9"></GoldCoinIcon>
                            </span>{" "}
                          </Fragment>
                        );
                      }
                    )}
                  {studyPlanResult?.results_header?.earnedToken === 0 && (
                    <span className="text-center">-</span>
                  )}
                </div>
              </div>
            </div>
          )}
          <div className="mt-10 flex flex-col flex-none mx-7 text-white border-white border-b border-opacity-25 pb-2 mb-2">
            <div className="text-xs text-white capitalize">
              {t("StudyPlanResult.DateCompleted")}
            </div>
            <div className="text-white font-roboto font-bold capitalize">
              {moment(studyPlanResult?.results_header?.date_completed).format(
                "MM/DD/YYYY"
              )}
            </div>
          </div>
          <div className="flex flex-col flex-none mx-7 text-white border-white border-b border-opacity-25 pb-2 mb-3">
            <div className="text-xs text-white capitalize">
              {t("StudyPlanResult.SessionTime")}
            </div>
            <div className="text-white font-roboto font-bold">
              {moment()
                .startOf("day")
                .add(studyPlanResult?.results_header?.elapsed_time, "seconds")
                .format("HH:mm:ss")}
            </div>
          </div>
          <div className="flex flex-col flex-none mx-7 text-white border-white border-b border-opacity-25 pb-2 mb-3">
            <div className="text-xs text-white capitalize">
              {t("StudyPlanResult.Subject")}
            </div>
            <div className="text-white text-md font-roboto font-bold capitalize">
              {studyPlanResult?.results_header?.test
                ? `${studyPlanResult?.results_header?.test.name}`
                : "-"}{" "}
            </div>
          </div>
          <div className="flex flex-col flex-none mx-7 text-white border-white border-b border-opacity-25 pb-2 mb-3">
            <div className="text-xs text-white capitalize">
              {t("StudyPlanResult.Teacher")}
            </div>
            <div className="text-white text-md font-roboto font-bold capitalize">
              {Object.keys(studyPlanResult?.results_header?.teacher).length >
                0 &&
                `${studyPlanResult?.results_header?.teacher?.first} ${studyPlanResult?.results_header?.teacher?.last}`}
              {Object.keys(studyPlanResult?.results_header?.teacher).length ===
                0 && (
                <div className="text-xs font-semibold capitalize cursor-pointer text-white">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5 stroke-current text-white inline"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                    />
                  </svg>
                  {""} Add Teacher
                </div>
              )}
            </div>
          </div>
          <div className="flex flex-col flex-none mx-7 text-white border-white border-b border-opacity-25 pb-2 mb-3">
            <div className="text-xs text-white capitalize">
              {t("StudyPlanResult.Class")}
            </div>
            <div className="text-white text-md font-roboto font-bold capitalize">
              {studyPlanResult?.results_header?.class &&
              Object.keys(studyPlanResult?.results_header?.class).length > 0
                ? `${studyPlanResult?.results_header?.class.name}`
                : "-"}{" "}
            </div>
          </div>
          <div className="flex flex-col flex-none mx-7 text-white border-white border-b border-opacity-25 pb-2 mb-3">
            <div className="text-xs text-white capitalize">
              {" "}
              {t("StudyPlanResult.StudentName")}
            </div>
            <div className="text-white text-md font-roboto font-bold capitalize">
              {Object.keys(studyPlanResult?.results_header?.student).length >
                0 &&
                `${studyPlanResult?.results_header?.student?.first} ${studyPlanResult?.results_header?.student?.last}`}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
export default OverviewResult;

import ProgressBar from "../../../../shared/progressBar";

interface IStarProgressChart {
  completed: number;
  total: number;
}
export default function StarProgressChart(props: IStarProgressChart) {
  return (
    <div>      
      <ProgressBar
        percentage={((props.completed / props.total) * 100).toFixed(2).toString()}
      />
    </div>
  );
}

import { useEffect, useState } from "react";
import { connect } from "react-redux";
import Select, { MultiValue } from "react-select";
import DatePicker from "react-datepicker";
import { getSchoolByDistrictId } from "../../../../../api/teacher/school";
import { DownArrowIcon, UpArrow } from "../../../../../assets/icons";
import {
  getSessionActivityReport,
  getSessionActivityDetailsReport,
  getSessionActivitySummaryReport,
} from "../../../../../api/report/sessionActivity";
import { ClassApi } from "../../../../../api/teacher/classManagementApi";
import {
  getAllStudents,
  getAllClassesBySubject,
} from "../../../../../api/teacher/teacher";
import { getSchoolStudentsBySchoolAccountId } from "../../../../../api/teacher/school";

import "../../../../../css/style.css";
import RouteConstant from "../../../../../utils/constant/routeConstant";
import Breadcrumb from "../../../teacher/shared/breadcrumb";
import { IBreadcrumb } from "../../../../../model/common/breadcrumb";
import { useTranslation } from "react-i18next";
import {
  DEFAULT_PAGE_NUMBER,
  DEFAULT_PAGE_SIZE,
  DEFAULT_PAGE_SIZE_MAX_99999,
} from "../../../../../utils/pagingConstant";
import Pagination from "../../../../shared/pagination/pagination";
import PageSize from "../../../../shared/pagination/pageSize";
import Constant from "../../../../../utils/constant/constant";
import {
  SessionActivityResponse,
  SessionActivityStudents,
} from "../../../../../model/report/sessionActivityResponse";
import { ISessionActivityReportSearch } from "../../../../../model/interface/report/sessionActivityReportSearch";
import { ISelectElement } from "../../../../../model/interface/selectElement";
import Loader from "../../../../shared/loader";
import moment from "moment";
import { toast } from "react-toastify";
import PagingResponse from "../../../../../model/common/pagingResponse";
import _ from "lodash";
import Asterisk from "../../../../shared/asterisk";
import ClassDropDown from "../../shared/classDropDown";
import Subject from "../../../../shared/dropdowns/subject";
import NoData from "../../../../shared/noData";
import constant from "../../../../../utils/constant/constant";
import { AxiosResponse } from "axios";
import {
  getEndDate,
  getMinSchoolYearDate,
  getStartDate,
} from "../../../../../utils/dateHelper";
import { UserContextState } from "../../../../../redux/actions/userContextAction";
import Profile from "../../../../../model/users/profile";

interface ISessionActivityProps {
  userContext: UserContextState;
  profile: Profile;
}
function SessionActivity(props: ISessionActivityProps) {
  let activityTypesDefault = [
    {
      label: "All Types",
      value: "",
    },
    {
      label: "Assessment",
      value: Constant.Activity_Type_Enum.Assessment.toString(),
    },
    {
      label: "Assignments",
      value: Constant.Activity_Type_Enum.Assignment.toString(),
    },
    {
      label: "Study Plan",
      value: Constant.Activity_Type_Enum.StudyPlan.toString(),
    },
  ];
  const DefaultFilters = {
    className: "All Classes",
    studentName: "All Students",
    subjectName: "All Subjects",
    types: "All Types",
  };

  const [activityTypes, setActivityTypes] = useState<Array<any>>();
  const defaultValue = { label: "Select", value: "0" };
  const [schools, setSchools] = useState<Array<any>>();
  const [schoolIds, setSchoolIds] = useState<Array<any>>();
  const [classes, setClasses] = useState<Array<any>>();
  const [students, setStudents] = useState<any[]>();
  const [selectedSchool, setSelectedSchool] =
    useState<ISelectElement>(defaultValue);
  const [selectedClass, setSelectedClass] = useState<Array<ISelectElement>>([
    { label: "All Classes", value: "0" },
  ]);
  const [selectedSubject, setSelectedSubject] = useState<Array<ISelectElement>>(
    [{ label: "All Subjects", value: "" }]
  );
  const [selectedStartDate, setSelectedStartDate] = useState<
    Date | undefined
  >();
  const [selectedEndDate, setSelectedEndDate] = useState<Date | undefined>();
  const [selectedType, setSelectedType] = useState<Array<ISelectElement>>([
    {
      label: "All Types",
      value: "",
    },
  ]);
  const [selectedStudent, setSelectedStudent] = useState<Array<ISelectElement>>(
    [{ label: "All Students", value: "" }]
  );
  const [isDistrictUser] = useState<boolean>(
    props.userContext.roleId === Constant.UserRoleId.District ? true : false
  );
  const [isSchoolAdmin] = useState<boolean>(
    props.userContext.roleId === Constant.UserRoleId.SchoolAdmin ? true : false
  );
  const [isTeacher] = useState<boolean>(
    props.userContext.roleId === Constant.UserRoleId.SchoolTeacher
      ? true
      : false
  );
  const { t } = useTranslation();
  const [toggle, setToggle] = useState<number>();
  const [pageNumber, setPageNumber] = useState(DEFAULT_PAGE_NUMBER);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [resetPageNumber, setResetPageNumber] = useState<boolean>(false);
  const [studentsDetails, setStudentsDetails] =
    useState<PagingResponse<SessionActivityStudents>>();
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [pageLoad, setPageLoad] = useState<boolean>(true);
  const [isInvalidDate, setIsInvalidDate] = useState<boolean>(false);
  const [filters, setFilters] = useState(DefaultFilters);

  useEffect(() => {
    if (isDistrictUser) {
      getSchools();
    }
  }, [isDistrictUser]);

  const onKeyPress = (e) => {
    if (e.charCode === 13) onRunReport();
  };

  useEffect(() => {
    getClasses();
    getStudents();
  }, [selectedSchool]);

  useEffect(() => {
    let activities: any[] = [];
    activityTypesDefault.map((element, index) => {
      activities.push(element);
      return null;
    });
    if (
      props.profile?.isLOActive ||
      props.userContext.roleId === constant.UserRoleId.District
    ) {
      activities.push({
        label: "Liftoff Diagnostics",
        value: Constant.Activity_Type_Enum.LiftOffDiagnostics.toString(),
      });
      activities.push({
        label: "Liftoff MyGalaxies",
        value: Constant.Activity_Type_Enum.LiftOffMyGalaxies.toString(),
      });
    }
    setActivityTypes(activities);
    setDates();
  }, []);

  useEffect(() => {
    setStudents([]);
    getStudents();
  }, [selectedClass]);

  useEffect(() => {
    if (!pageLoad) onRunReport();
  }, [pageNumber, pageSize]);

  const setDates = () => {
    setSelectedStartDate(getMinSchoolYearDate(props.profile.currentSchoolYear));
    setSelectedEndDate(new Date());
  };

  const getSchools = async () => {
    const resp = await getSchoolByDistrictId(
      props.userContext?.districtId ?? 0
    );
    if (resp?.data?.length) {
      const schools: Array<ISelectElement> = resp?.data.map((values: any) => {
        return {
          label: values?.name,
          value: values?.schoolAccountId,
        };
      });
      const schoolIds: Array<any> = resp?.data.map((values: any) => {
        return {
          schoolAccountId: values?.schoolAccountId,
          schoolId: values?.schoolId,
        };
      });
      setSchoolIds(schoolIds);
      setSchools(schools);
    }
  };

  const getClasses = async () => {
    let resp;
    let schoolId = props.userContext.schoolId;
    let schoolYearId = props.profile.currentSchoolYear.schoolYearId;
    if (isDistrictUser) {
      var d = schoolIds?.find(
        (x) => x.schoolAccountId === parseInt(selectedSchool.value)
      );
      if (d) {
        schoolId = d?.schoolId;
      }
    }
    let subjectId =
      selectedSubject.length === 1 &&
      !selectedSubject.some((s) => s.value === "") &&
      selectedClass.length === 1 &&
      selectedClass.some((c) => c.value === "0")
        ? Number(selectedSubject[0].value)
        : null;
    resp = await getAllClassesBySubject(
      props.userContext.userId,
      props.userContext.roleId,
      subjectId,
      schoolYearId,
      schoolId
    );
    if (resp?.data?.data?.length) {
      setClasses(resp?.data.data);
    }
  };

  const getStudents = async () => {
    let resp;
    const isSelectAll = selectedClass.filter(
      (currEle) => currEle.value === "0"
    );
    if (selectedClass?.length && isSelectAll.length === 0) {
      const selectedClassIds = Array.prototype.map.call(
        selectedClass,
        (s) => s.value
      );
      resp = await ClassApi.getClassStudentByClassIds(
        selectedClassIds?.join(",")
      );
    } else if (isTeacher) {
      resp = await getAllStudents(
        props.userContext?.userId,
        props.userContext?.roleId,
        0
      );
    } else if (isSchoolAdmin) {
      resp = await getSchoolStudentsBySchoolAccountId(
        props.userContext?.schoolAccountId
      );
    } else if (isDistrictUser) {
      resp = await getSchoolStudentsBySchoolAccountId(
        Number(selectedSchool.value)
      );
    }
    if (resp?.data?.data?.length || resp?.data.length) {
      var data: any[] = [];
      if (isTeacher && resp?.data?.data?.length) {
        resp?.data?.data.map((students) => {
          if (students.students?.length > 0) {
            students.students.map((student) => {
              data.push(student);
            });
          }
        });
      } else {
        data = resp?.data?.data?.students ?? resp?.data.data ?? resp?.data;
      }
      const students: Array<ISelectElement> = [
        { label: "All Students", value: "" },
      ];
      data.map((values: any) => {
        if (
          students.findIndex(
            (x) => x.value === values?.studentId ?? values?.userId ?? values?.id
          ) === -1
        ) {
          students.push({
            label:
              values.studentName ??
              values.name ??
              values.studentName ??
              values?.lastName + ", " + values?.firstName,
            value: values?.studentId ?? values?.userId ?? values?.id,
          });
        }
      });
      setStudents(students);
    }
  };

  function handleSelectedTypeChange(options: MultiValue<ISelectElement> | null) {
    if(options) {
      let selectedValues: Array<ISelectElement> = options.map((x) => x);
      if (selectedValues && selectedValues.length > 0) {
        const lastSelectedValue = selectedValues[selectedValues.length - 1];
        if (lastSelectedValue.value === "") {
          selectedValues = [lastSelectedValue];
        } else if (
          selectedValues.some((r) => r.value === "") &&
          selectedValues.some((r) => r.value !== "")
        ) {
          selectedValues = selectedValues.filter((r) => r.value !== "");
        }
      } else {
        selectedValues = [{ label: "All Types", value: "" }];
      }
      setSelectedType(selectedValues);
      var types = Array.prototype.map.call(selectedValues, (s) => s.label);
      setFilters({ ...filters, types: types?.join(",") });
      }
  }

  function handleSelectedStudentChange(options: MultiValue<ISelectElement> | null) {
    if(options) {
      let selectedValues: Array<ISelectElement> = options.map((x) => x);
      if (selectedValues && selectedValues.length > 0) {
        const lastSelectedValue = selectedValues[selectedValues.length - 1];
        if (lastSelectedValue.value === "") {
          selectedValues = [lastSelectedValue];
        } else if (
          selectedValues.some((r) => r.value === "") &&
          selectedValues.some((r) => r.value !== "")
        ) {
          selectedValues = selectedValues.filter((r) => r.value !== "");
        }
      } else {
        selectedValues = [{ label: "All Students", value: "" }];
      }
      setSelectedStudent(selectedValues);
      var students = Array.prototype.map.call(selectedValues, (s) => s.label);

      setFilters({ ...filters, studentName: students?.join(",") });
    }
  }

  function handleClassChange(e: any) {
    const selectAllClass = e.filter((currEle) => currEle.value === "0");
    if (selectAllClass && selectAllClass.length > 0) {
      setSelectedClass(selectAllClass);
      setFilters({ ...filters, className: "All Classess" });
    } else {
      setSelectedClass(e);
      var classes = Array.prototype.map.call(e, (s) => s.label);

      setFilters({ ...filters, className: classes?.join(",") });
    }
  }

  const onRunReport = async () => {
    setPageLoad(false);
    if (isDistrictUser && selectedSchool.value === "0") {
      toast.error("Please select school");
      return;
    }
    setShowLoading(true);
    const selectedClassIds =
      selectedClass &&
      selectedClass.filter((currEle) => currEle.value === "0").length === 0
        ? Array.prototype.map.call(selectedClass, (s) => s.value)
        : undefined;
    const selectedSubjectIds = selectedSubject
      ? Array.prototype.map.call(selectedSubject, (s) => s.value)
      : undefined;
    const selectedTypes = selectedType
      ? Array.prototype.map.call(selectedType, (s) => s.value)
      : undefined;
    const selectedStudents = selectedStudent
      ? Array.prototype.map.call(selectedStudent, (s) => s.value)
      : undefined;
    let schoolId = props.userContext.schoolId;
    if (isDistrictUser) {
      var d = schoolIds?.find(
        (x) => x.schoolAccountId === parseInt(selectedSchool.value)
      );
      if (d) {
        schoolId = d?.schoolId;
      }
    }

    let params: ISessionActivityReportSearch = {
      teacherId:
        props.userContext.roleId === Constant.UserRoleId.SchoolTeacher
          ? props.userContext.userId
          : undefined,
      schoolAccountId: isSchoolAdmin
        ? props.userContext?.schoolAccountId
        : isDistrictUser
        ? parseInt(selectedSchool.value)
        : undefined,

      activityTypes: selectedTypes?.join(","),
      classIds: selectedClassIds?.join(","),
      studentIds: selectedStudents?.join(","),
      subjectIds: selectedSubjectIds?.join(","),
      dateCompletedMin: selectedStartDate
        ? getStartDate(selectedStartDate)
        : undefined,
      dateCompletedMax: selectedEndDate
        ? getEndDate(selectedEndDate)
        : undefined,
      pageNumber: pageNumber,
      pageSize: pageSize,
      userId: props.userContext.userId,
      roleId: props.userContext.roleId,
      schoolId: schoolId,
      schoolYearId: props.profile.currentSchoolYear.schoolYearId,
    };
    getSessionActivityReport(params)
      .then((result) => {
        let students: PagingResponse<SessionActivityStudents> = {
          data: [],
          totalRecords: 0,
          page: 1,
          pageSize: 9999,
        };
        if (result?.data?.totalRecords > 0 && result?.data?.data !== null) {
          var sessionResponse = result?.data?.data;
          var flags = {};
          var uniqueUsers = sessionResponse.filter(function (entry) {
            if (flags[entry.userId]) {
              return false;
            }
            flags[entry.userId] = true;
            return true;
          });

          uniqueUsers?.map((element, index) => {
            var _details: Array<SessionActivityResponse> =
              sessionResponse.filter((x) => x.userId === element.userId);
            var details = _.orderBy(
              _details,
              (o: SessionActivityResponse) => {
                return o.dateCompleted;
              },
              ["desc"]
            );

            var stu: SessionActivityStudents = {
              id: element.userId,
              name: element.userName,
              totalQuestionsAnswered: details.reduce(function (prev, current) {
                return prev + +current.questionCount;
              }, 0),
              totalTimeSpent: details.reduce(function (prev, current) {
                return prev + +current.timeSpent;
              }, 0),
              details: details,
            };
            students.data.push(stu);
            return null;
          });
          students.totalRecords = result?.data?.totalRecords;
        }
        setStudentsDetails(students);
        setShowLoading(false);
      })
      .catch(() => {
        setShowLoading(false);
      });
  };

  const onReset = () => {
    setSelectedSchool(defaultValue);
    setSelectedClass([{ label: "All Classes", value: "0" }]);
    setSelectedSubject([{ label: "All Subjects", value: "" }]);
    setSelectedStudent([{ label: "All Students", value: "" }]);
    setSelectedStartDate(undefined);
    setSelectedEndDate(undefined);
    setDates();
    setSelectedType([{ label: "All Types", value: "" }]);
    setPageNumber(DEFAULT_PAGE_NUMBER);
    setPageSize(DEFAULT_PAGE_SIZE);
    setStudentsDetails(undefined);
    setFilters(DefaultFilters);
  };

  const breadcrumbItems = () => {
    const items: Array<IBreadcrumb> = [
      { name: t("Breadcrumb.Home"), url: RouteConstant.ROUTE_DASHBOARD },
      { name: t("Breadcrumb.Reports"), url: RouteConstant.REPORTS },
      { name: t("Breadcrumb.SessionActivityReport"), url: "" },
    ];

    return items;
  };

  const onPageSizeChange = (pageSize) => {
    setPageNumber(DEFAULT_PAGE_NUMBER);
    setPageSize(pageSize);
    setResetPageNumber(true);
  };

  const handleToggle = (index: number, type: string) => {
    if (type === "show") {
      setToggle(index);
    } else {
      setToggle(undefined);
    }
  };

  const getSubjectNames = () => {
    if (
      selectedSubject.filter((currEle) => currEle.value === "0").length === 0
    ) {
      var subjects = Array.prototype.map.call(selectedSubject, (s) => s.label);

      return subjects?.join(",");
    } else {
      return "All Subjects";
    }
  };

  const getFilters = () => {
    const startDate = selectedStartDate
      ? getStartDate(selectedStartDate)
      : null;
    const endDate =
      selectedEndDate != null ? getEndDate(selectedEndDate) : null;

    const filter =
      "Filters - Class: " +
      (filters.className ? filters.className : " ") +
      ", Student:" +
      filters.studentName +
      ", Subject:" +
      getSubjectNames() +
      ", Type: " +
      filters.types +
      ", From: " +
      (startDate ? startDate.toLocaleDateString() : "") +
      ", To: " +
      (endDate ? endDate.toLocaleDateString() : "");

    return filter;
  };

  const onExportToExcel = (exportSummary: boolean) => {
    setShowLoading(true);
    const selectedClassIds =
      selectedClass &&
      selectedClass.filter((currEle) => currEle.value === "0").length === 0
        ? Array.prototype.map.call(selectedClass, (s) => s.value)
        : undefined;
    const selectedSubjectIds = selectedSubject
      ? Array.prototype.map.call(selectedSubject, (s) => s.value)
      : undefined;
    const selectedTypes = selectedType
      ? Array.prototype.map.call(selectedType, (s) => s.value)
      : undefined;
    const selectedStudents = selectedStudent
      ? Array.prototype.map.call(selectedStudent, (s) => s.value)
      : undefined;
    let schoolId = props.userContext.schoolId;
    if (isDistrictUser) {
      var d = schoolIds?.find(
        (x) => x.schoolAccountId === parseInt(selectedSchool.value)
      );
      if (d) {
        schoolId = d?.schoolId;
      }
    }
    let params: ISessionActivityReportSearch = {
      teacherId:
        props.userContext.roleId === Constant.UserRoleId.SchoolTeacher
          ? props.userContext.userId
          : undefined,
      schoolAccountId: isSchoolAdmin
        ? props.userContext?.schoolAccountId
        : isDistrictUser
        ? parseInt(selectedSchool.value)
        : undefined,

      activityTypes: selectedTypes?.join(","),
      classIds: selectedClassIds?.join(","),
      studentIds: selectedStudents?.join(","),
      subjectIds: selectedSubjectIds?.join(","),
      dateCompletedMin: selectedStartDate
        ? getStartDate(selectedStartDate)
        : undefined,
      dateCompletedMax: selectedEndDate
        ? getEndDate(selectedEndDate)
        : undefined,
      pageNumber: pageNumber,
      pageSize: DEFAULT_PAGE_SIZE_MAX_99999,
      userId: props.userContext.userId,
      roleId: props.userContext.roleId,
      schoolId: schoolId,
      schoolYearId: props.profile.currentSchoolYear.schoolYearId,
    };

    var filters = getFilters();
    if (exportSummary) {
      getSessionActivitySummaryReport(params, filters)
        .then((result) => {
          if (result.data) {
            setShowLoading(false);
            startFileDownload(result, "SessionActivitySummary.xlsx");
          }
        })
        .catch(() => {
          setShowLoading(false);
        });
    } else {
      getSessionActivityDetailsReport(params, filters)
        .then((result) => {
          if (result.data) {
            setShowLoading(false);
            startFileDownload(result, "SessionActivityDetails.xlsx");
          }
        })
        .catch(() => {
          setShowLoading(false);
        });
    }
  };

  const startFileDownload = (
    response: AxiosResponse<any>,
    fileName: string
  ) => {
    const file = response.data;
    const fileURL = URL.createObjectURL(file);
    let link = document.createElement("a");
    link.href = fileURL;
    link.download = fileName;
    link.setAttribute("target", "_blank");
    document.body.appendChild(link);
    link.click();
    setShowLoading(false);
  };

  const isInvalidSchool = () => {
    return (
      props.userContext.roleId === constant.UserRoleId.District &&
      selectedSchool.value === "0"
    );
  };

  const formatTime = (totalSec) => {
    const remainingSecs = totalSec % 86400;
    const formatOptions = { minimumIntegerDigits: 2 };
    return `${
      Math.floor(totalSec / 86400)
        .toLocaleString("en-US", formatOptions)
        .replace(/,/g, "") +
      ":" +
      Math.floor(
        moment.duration(remainingSecs, "seconds").asHours()
      ).toLocaleString("en-US", formatOptions) +
      ":" +
      moment
        .duration(remainingSecs, "seconds")
        .minutes()
        .toLocaleString("en-US", formatOptions) +
      ":" +
      Math.floor(totalSec % 60).toLocaleString("en-US", formatOptions)
    }`;
  };

  function handleSelectedSchool(option: ISelectElement| null) {
    if(option){
      setSelectedSchool(option);
    }    
  }

  return (
    <div className="min-w-0 w-full">
      {showLoading && <Loader />}
      <div className="lg:flex lg:items-center px-4 py-3 lg:px-8 border-b">
        <Breadcrumb items={breadcrumbItems()} />
        <div className="lg:w-[55%] text-lg font-medium flex gap-2 h-auto sm:h-10 items-center">
          <h1 className="text-primary-violet font-medium flex justify-start mb-0 pb-0">
            Session Activity Report
          </h1>
        </div>
      </div>
      <div className="my-4 text-sm text-gray-700 font-light bg-blue-50 p-4 flex rounded-md rounded-t-lg sm:rounded-lg overflow-hidden mx-2 sm:mx-6 lg:mx-8">
        <div className="flex-shrink-0 current-fill text-blue-400">
          <svg
            className="h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fill-rule="evenodd"
              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </div>
        <div className="pl-2">
          <div>
            This report tracks each individual session of activities worked by
            students{" "}
            <b className="font-bold">for the current school year only</b>. If an
            activity takes multiple sessions, you will see each session listed
            individually.
          </div>
        </div>
      </div>
      <div className="px-2 sm:px-6  lg:px-8 py-1 relative my-4">
        <div className="bg-gray-100 w-full rounded-lg p-5">
          <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-4 mb-4">
            {isDistrictUser && (
              <div className="flex flex-col">
                <label className="block mb-1 text-sm text-gray-500">
                  School <Asterisk />
                </label>

                <Select
                  options={schools}
                  value={selectedSchool}
                  onChange={handleSelectedSchool}
                />
              </div>
            )}

            <div className="flex flex-col">
              <label className="block mb-1 text-sm text-gray-500">Class</label>

              <ClassDropDown
                schoolYearId={props.profile.currentSchoolYear.schoolYearId}
                isMultiSelect={true}
                onChange={handleClassChange}
                value={selectedClass}
                data={classes}
                isDisabled={
                  props.userContext.roleId === Constant.UserRoleId.District &&
                  selectedSchool.value === "0"
                    ? true
                    : false
                }
                showArchived={true}
                showHidden={true}
                selectedSchoolAccountId={parseInt(selectedSchool.value)}
              ></ClassDropDown>
            </div>
            <div className="flex flex-col">
              <label className="block mb-1 text-sm text-gray-500">
                Student
              </label>

              <Select
                tabIndex={4}
                options={students}
                value={selectedStudent}
                onChange={(e) => handleSelectedStudentChange(e)}
                isMulti={true}
                closeMenuOnSelect={false}
              />
            </div>
            <div className="flex flex-col">
              <label className="block mb-1 text-sm text-gray-500">Type</label>
              <Select
                tabIndex={5}
                value={selectedType}
                options={activityTypes}
                isMulti={true}
                closeMenuOnSelect={false}
                onChange={(e) => handleSelectedTypeChange(e)}                
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: "#008fbb",
                  },
                
                })}
              />
            </div>
            <div className="flex flex-col">
              <Subject
                selectedSchools={selectedSchool.value}
                setSelectedSubject={setSelectedSubject}
                selectedItems={selectedSubject}
                isMulti={true}
                subjectTypeId={0}
              ></Subject>
            </div>
          </div>

          <label className="block mb-1 text-sm text-gray-700">
            Session Date Range
          </label>
          <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-4 mb-4">
            <div className="flex flex-col">
              <label className="block mb-1 text-sm text-gray-500">From</label>

              <DatePicker
                tabIndex={7}
                minDate={getMinSchoolYearDate(props.profile.currentSchoolYear)}
                maxDate={new Date()}
                selected={selectedStartDate}
                onChange={(e) => {
                  if (selectedEndDate && e > selectedEndDate) {
                    setIsInvalidDate(true);
                  } else {
                    setIsInvalidDate(false);
                  }
                  setSelectedStartDate(e);
                }}
                className="react-datepicker__input-container py-1.5 px-3 block w-full shadow-sm focus:ring-primary-violet focus:border-primary-violet border border-gray-300 rounded "
              />
              {isInvalidDate && (
                <span className="pl-2 text-red-600">Invalid Date</span>
              )}
            </div>
            <div className="flex flex-col">
              <label className="block mb-1 text-sm text-gray-500">To</label>

              <DatePicker
                tabIndex={8}
                minDate={getMinSchoolYearDate(props.profile.currentSchoolYear)}
                maxDate={new Date()}
                selected={selectedEndDate}
                onChange={(e) => {
                  if (selectedStartDate && e < selectedStartDate) {
                    setIsInvalidDate(true);
                  } else {
                    setIsInvalidDate(false);
                  }
                  setSelectedEndDate(e);
                }}
                className="react-datepicker__input-container py-1.5 px-3 block w-full shadow-sm focus:ring-primary-violet focus:border-primary-violet border border-gray-300 rounded "
              />
              {isInvalidDate && (
                <span className="pl-2 text-red-600">Invalid Date</span>
              )}
            </div>
          </div>
          <div className="flex gap-4 justify-end">
            <button
              onClick={onReset}
              tabIndex={2}
              className="bg-transparent border my-auto border-primary-violet shadow-sm py-2 px-4 flex justify-center text-sm font-medium text-primary-violet hover:text-white hover:bg-light-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3 transition ease-in-out duration-500"
            >
              Reset
            </button>

            <div className="cursor-not-allowed">
              <button
                tabIndex={1}
                onClick={onRunReport}
                onKeyPress={onKeyPress}
                className={
                  "bg-primary-violet border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 " +
                  (isInvalidSchool() || isInvalidDate
                    ? "bg-opacity-20 text-primary-violet text-opacity-40 pointer-events-none"
                    : "hover:bg-dark-violet hover:shadow-lg")
                }
              >
                Run Report
              </button>
            </div>
            <div className="cursor-not-allowed">
              <button
                tabIndex={2}
                onClick={() => onExportToExcel(true)}
                onKeyPress={onKeyPress}
                className={
                  "bg-primary-violet border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-dark-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3 " +
                  (studentsDetails && studentsDetails.data.length > 0
                    ? ""
                    : "bg-opacity-20 text-primary-violet text-opacity-40 pointer-events-none")
                }
              >
                Export Student Summary
              </button>
            </div>
            <div className="cursor-not-allowed">
              <button
                tabIndex={3}
                onClick={() => onExportToExcel(false)}
                onKeyPress={onKeyPress}
                className={
                  "bg-primary-violet border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-dark-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3 " +
                  (studentsDetails && studentsDetails.data.length > 0
                    ? ""
                    : "bg-opacity-20 text-primary-violet text-opacity-40 pointer-events-none")
                }
              >
                Export Activity Details
              </button>
            </div>
          </div>

          <div className="border-b border-gray-400 py-2 mb-2"></div>
          <div className="bg-white px-3 pt-2 pb-3">
            <div className="overflow-x-auto xl:overflow-x-visible mt-3">
              <table className="table w-full ">
                <thead className="mb-5">
                  <tr className="bg-light-violet/10  text-gray-700 py-3 px-3 w-full">
                    <th
                      scope="col"
                      className="text-left  p-3 text-sm font-normal w-84"
                    >
                      Name
                    </th>

                    <th
                      scope="col"
                      className="text-center  p-3 text-sm font-normal"
                    >
                      Total Questions Answered
                    </th>

                    <th
                      scope="col"
                      className="text-center p-3 text-sm font-normal"
                    >
                      <div>Total Time Spent</div>
                      <div>(DD:HH:MM:SS)</div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {studentsDetails?.data?.map((student, index) => {
                    return (
                      <>
                        <tr key={index} className={` w-full border-b`}>
                          <td>
                            <div
                              className={`flex py-3 px-2 items-center cursor-pointer`}
                            >
                              {index !== toggle && (
                                <DownArrowIcon
                                  onClick={() => {
                                    handleToggle(index, "show");
                                  }}
                                />
                              )}
                              {index === toggle && (
                                <UpArrow
                                  onClick={() => {
                                    handleToggle(index, "hide");
                                  }}
                                />
                              )}

                              <span className="ml-2 text-primary-violet">
                                {student.name}
                              </span>
                            </div>
                          </td>
                          <td>
                            <span className="flex justify-center">
                              {student.totalQuestionsAnswered}
                            </span>
                          </td>
                          <td>
                            <span className="flex justify-center">
                              {student.totalTimeSpent
                                ? formatTime(student.totalTimeSpent)
                                : "N/A"}
                            </span>
                          </td>
                        </tr>
                        <tr
                          className={`${
                            index !== toggle ? "hidden" : ""
                          } w-full`}
                        >
                          <td colSpan={8}>
                            <table className="table w-full ">
                              <thead className="mb-5">
                                <tr className="bg-light-violet  text-white py-3 px-3">
                                  <th
                                    scope="col"
                                    className="text-left  p-3 text-sm font-normal w-80"
                                  >
                                    Subject
                                  </th>
                                  <th
                                    scope="col"
                                    className="text-left  p-3 text-sm font-normal w-80"
                                  >
                                    Standard
                                  </th>
                                  <th
                                    scope="col"
                                    className="text-center  p-3 text-sm font-normal w-10"
                                  >
                                    Type
                                  </th>
                                  <th
                                    scope="col"
                                    className="text-center  p-3 text-sm font-normal"
                                  >
                                    Date
                                  </th>
                                  <th
                                    scope="col"
                                    className="text-center  p-3 text-sm font-normal"
                                  >
                                    <div>Time Spent</div>
                                    <div>(DD:HH:MM:SS)</div>
                                  </th>

                                  <th
                                    scope="col"
                                    className="text-center p-3 text-sm font-normal"
                                  >
                                    Total Questions Attempted
                                  </th>

                                  <th
                                    scope="col"
                                    className="text-center  p-3 text-sm font-normal"
                                  >
                                    Total Questions Correct
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {student.details.map((data, i) => (
                                  <tr
                                    key={i}
                                    className={`${
                                      i % 2 !== 0
                                        ? "bg-light-violet/10"
                                        : "bg-white"
                                    }`}
                                  >
                                    <td className="p-3 text-sm text-gray-500 w-10">
                                      {data.subject}
                                    </td>
                                    <td className="p-3 text-sm text-gray-500 w-72 text-left">
                                      {data.standard}
                                    </td>
                                    <td className="p-3 text-sm text-gray-500 text-center">
                                      {data.type}
                                      {data.isRetry &&
                                           <span>&nbsp;(Retry)</span>
                                      }
                                    </td>
                                    <td className="p-3 text-sm text-gray-500 text-center">
                                      {data.activityDate ?
                                        moment(data.activityDate).format(
                                          "MM/DD/YYYY"
                                        ) : data.dateCompleted
                                        ? moment(data.dateCompleted).format(
                                            "MM/DD/YYYY"
                                          )
                                        : data.dateStarted
                                        ? moment(data.dateStarted).format(
                                            "MM/DD/YYYY"
                                          )
                                        : "N/A"}
                                    </td>
                                    <td className="p-3 text-sm text-gray-500 text-center">
                                      {data.timeSpent
                                        ? formatTime(data.timeSpent)
                                        : "N/A"}
                                    </td>
                                    <td className="p-3 text-sm text-gray-500 text-center">
                                      {data.questionsAttempted}
                                    </td>
                                    <td className="p-3 text-sm text-gray-500 text-center">
                                      {data.correctAnswerCount}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>
            </div>

            {studentsDetails && studentsDetails.data.length > 0 && (
              <div className="sm:flex-1 sm:flex sm:items-center sm:justify-between mt-2">
                {studentsDetails?.totalRecords !== undefined &&
                  studentsDetails?.totalRecords !== 0 && (
                    <Pagination
                      totalRecords={studentsDetails?.totalRecords}
                      pageSize={pageSize}
                      pageNumber={pageNumber}
                      onPageChange={(e) => {
                        setPageNumber(e);
                      }}
                      reset={resetPageNumber}
                    />
                  )}
                {studentsDetails?.totalRecords !== 0 && (
                  <PageSize
                    pageSize={pageSize}
                    onPageSizeChange={onPageSizeChange}
                  />
                )}
              </div>
            )}
            {studentsDetails?.data?.length === 0 && (
              <div className="mt-2">
                <NoData />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default connect((state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
})(SessionActivity);

import React, { useEffect, useContext, useState } from "react"
import { createPortal } from "react-dom"
import Backdrop from "./backdrop"
import Transition from "./transition"
import FocusLock from "react-focus-lock"

import { ThemeContext } from "./themeContext"

export interface ModalProps extends React.HTMLAttributes<HTMLDivElement> {
  /**
   * Function executed when the dropdown is closed
   */
  onClose: () => void
  /**
   * Defines if the modal is open
   */
  isOpen: boolean,
  title?: string,
  children?: JSX.Element|JSX.Element[]
}

const Modal = React.forwardRef<HTMLDivElement, ModalProps>(function Modal(props, ref) {
  const { children, onClose, isOpen, title, ...other } = props

  const {
    theme: { modal },
  } = useContext(ThemeContext)

  const baseStyle = modal.base

  function handleEsc(e: KeyboardEvent) {
    if (e.key === "Esc" || e.key === "Escape") {
      onClose()
    }
  }

  useEffect(() => {
    document.addEventListener("keydown", handleEsc, { capture: true })
    return () => {
      document.removeEventListener("keydown", handleEsc)
    }
  })

  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    setMounted(true)
  }, [])

  const modalComponent = (
    <Transition show={isOpen}>
      <>
        <Transition
          enter="transition ease-out duration-150"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Backdrop onClick={onClose}>
            <Transition
              enter="transition ease-out duration-150"
              enterFrom="opacity-0 transform translate-y-1/2"
              enterTo="opacity-100"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100"
              leaveTo="opacity-0  transform translate-y-1/2"
            >
              <div
                className={baseStyle}
                role="dialog"
                onClick={(e) => e.stopPropagation()}
                ref={ref}
                {...other}
              >
                <FocusLock className="h-full" returnFocus>
                  <header className="px-4 py-4 bg-gray-50 sm:px-4">
                    <div className="flex items-start justify-between space-x-3">
                      <div className="space-y-1">
                        <div className="text-lg font-medium text-gray-900">{title ?? "Add"}</div>
                      </div>
                      <div className="h-7 flex items-center">
                        <button
                          className="text-gray-400 hover:text-gray-500"
                          aria-label="close"
                          onClick={onClose}
                        >
                          <span className="sr-only">Close panel</span>
                          <svg
                            className="w-6 h-6"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            role="img"
                            aria-hidden="true"
                          >
                            <path
                              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                              clipRule="evenodd"
                              fillRule="evenodd"
                            ></path>
                          </svg>
                        </button>
                      </div>
                    </div>

                  </header>
                  {children}
                </FocusLock>
              </div>
            </Transition>
          </Backdrop>
        </Transition>
      </>
    </Transition>
  )

  return mounted ? createPortal(modalComponent, document.body) : null
})

export default Modal
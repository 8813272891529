import React, { Fragment } from "react";
import { DateIcon, DeleteIcon, EditIcon } from "../../../assets/icons/index";
import { VideoMessageModalProps } from "./videoMessageModal";
import Moment from "react-moment";
import { Configuration } from "../../../environment/setup";
export interface VideoCardProps {
  handleDeleteEvent: (messageId) => void;
  handleEditEvent: (data) => void;
  title: string;
  startDate: Date;
  endDate: Date;
  messageCreatedDate: Date;
  teacherName: string;
  grade: string;
  gradeId: number;
  location: string;
  role: string;
  mediaUrl: string;
  messageId: number;
  linkedItemId: number;
  isActive: boolean;
  classIds?: number[];
  studentIds?: number[];
}

const VideoCard: React.FC<VideoCardProps> = (props) => {
  const handleDeleteEvent = () => {
    props.handleDeleteEvent(props.messageId);
  };

  const handleEditEvent = () => {
    let data: VideoMessageModalProps = {
      messageId: props.messageId,
      linkedItemId: props.linkedItemId,
      title: props.title,
      startDate: props.startDate,
      endDate: props.endDate,
      messageContent: props.mediaUrl,
      gradeId: props.gradeId,
      classIds: props.classIds,
      studentIds: props.studentIds,
      isActive: props.isActive,
      showModal: (data) => {},
      publishMessage: (data) => {},
      uploadFile: (data, callBack) => {},
    };
    props.handleEditEvent(data);
  };

  return (
    <Fragment>
      <li className="relative ring-2 ring-gray-200 p-4 rounded-lg hover:bg-gray-100 hover:shadow-2xl transition ease-in-out duration-500 group">
        <div className="flex absolute -right-4 -top-4 bg-gray-200  rounded-full  group-hover:border  border-2 shadow-xl px-1 opacity-0  group-hover:opacity-100 transition ease-in-out duration-500">
          <a
            href="#!"
            className="cursor-pointer  text-gray-500 hover:bg-white p-3 rounded-full  group-hover:border  transition ease-in-out duration-500  border-black"
            onClick={() => {
              handleDeleteEvent();
            }}
          >
            <DeleteIcon className="w-5" />
          </a>
          <a
            href="#!"
            className="cursor-pointer text-gray-500 hover:bg-white p-3 rounded-full  group-hover:border  transition ease-in-out duration-500  border-black"
            onClick={() => {
              handleEditEvent();
            }}
          >
            <EditIcon className="w-5" />
          </a>
        </div>
        <div onContextMenu={(e) => e.preventDefault()} className="group block w-full aspect-w-10 aspect-h-7 rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden">
          <div>
            <video
              preload="none"
              poster={`${Configuration.S3bucketImagePath}/images/video-message-thumbnail.png`}
              className="video-js vjs-default-skin vjs-big-play-centered"
              controls
              width={"100%"}
            >
              <source src={props.mediaUrl}></source>
            </video>
          </div>
        </div>
        <p className="mt-4 text-base font-medium text-gray-900 truncate pointer-events-none flex items-center justify-between ">
          <span> {props.title}</span>
        </p>
        <p className="block text-sm mt-1 text-gray-900 pointer-events-none space-x-1">
          <span className="text-base">{props.teacherName}</span>{" "}
          <span>({<Moment>{props.messageCreatedDate}</Moment>})</span>
        </p>
        <p className="block text-sm mt-3 text-gray-600 pointer-events-none space-y-1">
          <span className="flex ">
            <DateIcon className="fill-current w-3 mr-1 opacity-80" />
            Start
            <span className="ml-1">{<Moment>{props.startDate}</Moment>}</span>
          </span>
          <span className="flex ">
            <DateIcon className="fill-current w-3 mr-1 opacity-80" />
            End
            <span className="ml-1">{<Moment>{props.endDate}</Moment>}</span>
          </span>
        </p>
        <p className="block text-sm mt-3 text-gray-600 pointer-events-none space-y-1">
          <span>{props.grade}</span>
          <span className="ml-2">{props.location}</span>
        </p>
      </li>
    </Fragment>
  );
};

export default VideoCard;

import { XIcon } from "@heroicons/react/outline";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import PagingResponse from "../../../../model/common/pagingResponse";
import { UserContextState } from "../../../../redux/actions/userContextAction";
import {
  DEFAULT_PAGE_NUMBER,
  DEFAULT_PAGE_SIZE,
} from "../../../../utils/pagingConstant";
import InfoMessage from "../../../shared/infoMessage";
import PageSize from "../../../shared/pagination/pageSize";
import Pagination from "../../../shared/pagination/pagination";
import UserApi from "../../../../api/userApi";
import { useConfirmation } from "../../../shared/confirmation/confirmationService";
import { getNameLabel } from "../../../../utils/helper";

interface MergeUserStudentSearchProp {
  userContext?: UserContextState;
  skipUserId: number;
  addStudentToMerge: Function;
  showPopup: Function;
}

const MergeUserStudentSearch = (props: MergeUserStudentSearchProp) => {
  const alphaNumericExpression = new RegExp(/^[a-zA-Z0-9_@.-]*$/);
  const [schoolStudents, setSchoolStudents] = useState<PagingResponse<any>>();
  const [pageNumber, setPageNumber] = useState(DEFAULT_PAGE_NUMBER);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [selectAll, setSelectAll] = useState(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [resetPageNumber, setResetPageNumber] = useState<boolean>(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const confirm = useConfirmation();

  useEffect(() => {
    getSchoolStudent();
  }, [pageNumber, pageSize]);

  function getSchoolStudent() {
    UserApi.MergeUserSearchStudent(
      props.userContext?.schoolAccountId ?? 0,
      firstName,
      lastName,
      props.skipUserId,
      pageNumber,
      pageSize
    ).then((d) => {
      setSchoolStudents(d.data);
    });
  }
  function selectStudent(userId: any) {
    const Students = schoolStudents?.data.find((e) => e.userId === userId);
    if (Students) {
      Students.isSelected =
        Students.isSelected === undefined ? true : !Students.isSelected;
    }
    setIsDisabled(getSelectedStudents().length === 0);
  }

  function selectAllStudent(): void {
    if (schoolStudents?.data) {
      for (const key in schoolStudents?.data) {
        const obj = schoolStudents?.data[key];
        obj.isSelected = !selectAll;
      }
    }
    setSelectAll(!selectAll);
    setIsDisabled(getSelectedStudents().length === 0);
  }
  function getSelectedStudents() {
    if (schoolStudents?.data) {
      const SelectedStudents = schoolStudents?.data?.filter((e) => {
        return e.isSelected === true;
      });
      return SelectedStudents.map((e) => e.userId);
    }
    return [];
  }
  function addStudentToMerge() {
    confirm({
      variant: "danger",
      title: "Student Merge",
      description:
        "Are you sure you want to add the selected students to merge?",
    }).then((d) => {
      var selectedStudents = schoolStudents?.data?.filter((e) => {
        return e.isSelected === true;
      });
      selectedStudents = selectedStudents?.map((obj) => {
        return { ...obj, logins: obj.loginCount };
      });
      props.addStudentToMerge(selectedStudents);
    });
  }

  const onPageSizeChange = (pageSize) => {
    setPageNumber(DEFAULT_PAGE_NUMBER);
    setPageSize(pageSize);
    setResetPageNumber(true);
  };

  return (
    <div className="fixed inset-0 z-40 flex items-center bg-black bg-opacity-50 sm:items-center sm:justify-center">
      <div
        className="w-full flex flex-col justify-between overflow-hidden h-auto max-h-80percent bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:max-w-5xl"
        role="dialog"
      >
        <header>
          {/* Header */}
          <div className="px-4 py-6 bg-gray-50 sm:px-6">
            <div className="flex items-start justify-between space-x-3">
              <div className="space-y-1">
                <div className="text-lg font-medium text-gray-900">
                  Merge - Search Students
                </div>
              </div>
              <div className="h-7 flex items-center">
                <button
                  type="button"
                  className="text-gray-400 hover:text-gray-500"
                  onClick={() => props.showPopup(false)}
                >
                  <span className="sr-only">Close panel</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </header>

        <div className="w-full relative overflow-auto px-4 py-3">
          <div className="p-1">
            <InfoMessage message="Select Students you wish to merge into selected students" />
          </div>

          {/* <!-- Divider container -->  */}
          <div className="p-3 space-y-6 sm:py-0 sm:space-y-0 sm:divide-y sm:divide-gray-200 text-sm text-gray-700 dark:text-gray-400">
            <div>
              <div>
                <div className="mb-2">
                  <div className="grid grid-cols-3 gap-4 flex items-center">
                    <div>
                      <label
                        htmlFor="student_firstName"
                        className="block text-sm  text-gray-500"
                      >
                        First Name
                      </label>
                      <div className="mt-1">
                        <input
                          type="text"
                          name="student_firstName"
                          id="student_firstName"
                          value={firstName}
                          maxLength={60}
                          onChange={(e) => {
                            if (
                              alphaNumericExpression.test(
                                e.target.value.toString()
                              )
                            ) {
                              setFirstName(e.target.value);
                            }
                          }}
                          className="border py-2 px-4 block w-full shadow-sm text-gray-900 focus:ring-primary-violet focus:border-primary-violet
            border border-gray-300 rounded-md"
                        />
                      </div>
                    </div>
                    <div>
                      <label
                        htmlFor="student_lastName"
                        className="block text-sm  text-gray-500"
                      >
                        Last Name
                      </label>
                      <div className="mt-1">
                        <input
                          type="text"
                          name="student_lastName"
                          id="student_lastName"
                          value={lastName}
                          maxLength={60}
                          onChange={(e) => {
                            if (
                              alphaNumericExpression.test(
                                e.target.value.toString()
                              )
                            ) {
                              setLastName(e.target.value);
                            }
                          }}
                          className="border py-2 px-4 block w-full shadow-sm text-gray-900 focus:ring-primary-violet focus:border-primary-violet
            border border-gray-300 rounded-md"
                        />
                      </div>
                    </div>
                    <div className="mt-1 pt-2 text-right">
                      <button
                        className="bg-primary-violet border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-dark-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        type="button"
                        onClick={getSchoolStudent}
                      >
                        Search
                      </button>
                    </div>
                  </div>
                </div>

                <div className="overflow-x-auto mt-3">
                  <table className="table w-full overflow-auto">
                    <thead className="mb-5">
                      <tr className="bg-light-violet  text-white py-3 px-3 overflow-hidden">
                        <th className="text-left  p-3 text-sm font-normal w-4">
                          <label
                            x-radio-group-option=""
                            className="relative cursor-pointer"
                          >
                            <input
                              type="checkbox"
                              x-model="value"
                              name="privacy_setting"
                              value="Private to Project Members"
                              className="h-4 w-4 mt-0.5 cursor-pointer text-indigo-600 border-gray-300 focus:ring-indigo-500"
                              aria-labelledby="privacy-setting-1-label"
                              aria-describedby="privacy-setting-1-description"
                              checked={selectAll}
                              onChange={selectAllStudent}
                            />
                          </label>
                        </th>
                        <th className="text-left  p-3 text-sm font-normal">
                          {" "}
                          Student{" "}
                        </th>
                        <th className="text-left  p-3 text-sm font-normal">
                          {" "}
                          Grade{" "}
                        </th>
                        <th className="text-left  p-3 text-sm font-normal">
                          {" "}
                          Student Id{" "}
                        </th>{" "}
                        <th className="text-left  p-3 text-sm font-normal">
                          {" "}
                          Email{" "}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {schoolStudents?.data?.map((Student, index) => {
                        return (
                          <tr
                            key={Student.userId}
                            className={`hover:bg-light-violet/20 transition ease-in-out duration-300 ${
                              index % 2 === 0
                                ? "bg-white"
                                : "bg-light-violet/10"
                            }`}
                          >
                            <td className=" mt-5 p-3 text-center">
                              <input
                                type="checkbox"
                                key={Student.userId}
                                defaultChecked={false}
                                checked={
                                  selectAll ? Student.isSelected : undefined
                                }
                                onChange={() => {
                                  selectStudent(Student.userId);
                                }}
                              />
                            </td>
                            <td className="text-sm mt-5  p-3">
                              {getNameLabel(
                                Student.lastName,
                                Student.firstName
                              )}
                            </td>
                            <td className="text-sm mt-5  p-3 text-left">
                              {Student.grade}
                            </td>
                            <td className="text-sm mt-5  p-3 text-left">
                              {Student.studentId}
                            </td>
                            <td className="text-sm mt-5  p-3 text-left">
                              {Student.email}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between mt-2">
                  {schoolStudents?.totalRecords !== undefined &&
                    schoolStudents?.totalRecords !== 0 && (
                      <Pagination
                        totalRecords={schoolStudents?.totalRecords}
                        pageSize={pageSize}
                        onPageChange={(e) => {
                          setPageNumber(e);
                        }}
                        reset={resetPageNumber}
                        pageNumber={pageNumber}
                      />
                    )}
                  {schoolStudents?.totalRecords !== 0 && (
                    <PageSize
                      pageSize={pageSize}
                      onPageSizeChange={onPageSizeChange}
                    />
                  )}
                  {schoolStudents?.totalRecords === 0 && (
                    <span className="text-color-black">No record found</span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Action Buttons -->  */}
        <footer className="flex flex-col items-center justify-end px-6 py-4 sm:flex-row bg-gray-50 dark:bg-gray-800">
          <div className="flex-shrink-0">
            <div className="space-x-3 flex justify-end">
              <button
                className="disabled:opacity-50 align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 rounded-lg text-sm text-white border border-transparent bg-primary-green focus:shadow-outline-purple w-full sm:w-auto"
                disabled={isDisabled}
                type="button"
                onClick={addStudentToMerge}
              >
                {" "}
                Add Student
              </button>
              <button
                className="align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 rounded-lg text-sm text-gray-600 border-gray-300 border dark:text-gray-400 focus:outline-none active:bg-transparent hover:border-gray-500 focus:border-gray-500 active:text-gray-500 focus:shadow-outline-gray w-full sm:w-auto"
                type="button"
                onClick={() => {
                  props.showPopup(false);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(MergeUserStudentSearch);

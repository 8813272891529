import { getAdaptiveData } from "../api/student/liftOff/adaptiveData";
import { IAdaptiveDataFilter } from "../model/interface/adaptiveDataFilter";
import { getCannedTestSections } from "../api/student/cannedTestApi";
import i18n from "../i18Next";
import { ISection } from "../model/interface/section";

async function GetAdaptiveData(
  adaptiveDataFilter: IAdaptiveDataFilter | null,
  language
) {
  let adaptiveData: any = {};
  let stepData: any = [];
  if (adaptiveDataFilter) {
    await getAdaptiveData(
      adaptiveDataFilter.headerId,
      adaptiveDataFilter.gradeId,
      language
    ).then((res) => {
      if (res.data) {
        adaptiveData = res.data;
        adaptiveData.steps.forEach((step) => {
          let actStep: any = {
            id: step.id,
            reference: step.reference,
            next: step.next,
          };
          stepData.push(actStep);
        });
        adaptiveData.steps = stepData;
      }
    });
  }
  return adaptiveData;
}
export async function GetSectionData(cannedTestId, language) {
  let sectionData: ISection[] = [];
  await getCannedTestSections(cannedTestId, language).then((res) => {
    sectionData = res.data;
  });
  return sectionData;
}

export async function initRequest(
  id: string,
  activity: boolean,
  questions: boolean,
  itemIds: Array<string>,
  includeAdaptive: boolean,
  includeSections: boolean,
  sessionId: string,
  state: string,
  studentId: string,
  teacherActivityId: string,
  adaptiveFilter: IAdaptiveDataFilter | null,
  selectedLanguage: string,
  includeStartPage: boolean,
  introMessage: string,
  checkAnswer: boolean,
  timeLimit: number | null,
  studentType: string,
  cannedTestId?: number,
  onExitRedirectUrl?: string,
  calculatorReferenceId?: string,
  skipQuestions?: boolean,
  shuffleItems?: boolean,
  hideSaveBtn?: boolean,
  allowPartialSubmit?: boolean,
  fromStudentResponsePopUp?: boolean,
  fontSize?: string
) {
  if (activity) {
    return {
      user_id: studentId,
      rendering_type: "assess",
      name: "Items API Test",
      state: state,
      activity_template_id: activity && !includeSections ? id : null,
      activity_id: teacherActivityId,
      session_id: sessionId,
      type: "submit_practice",
      adaptive: includeAdaptive
        ? await GetAdaptiveData(adaptiveFilter, selectedLanguage)
        : null,
      config: {
        //regions: "main",
        annotations: true,
        annotations_api_init_options: {
          modules: {
            drawing: true,
            texthighlight: true,
            notepad: true,
            stickynote: true,
          },
          labelBundle: {
            notepadTitle:
              selectedLanguage === "es"
                ? "Bloc"
                : i18n.t("StudentResponse.Notepad"),
            notepadHide:
              selectedLanguage === "es"
                ? "Esconder"
                : i18n.t("StudentResponse.Hide"),
            notepadCollapse:
              selectedLanguage === "es"
                ? "Colapsar"
                : i18n.t("StudentResponse.Collapse"),
            notepadMaximize:
              selectedLanguage === "es"
                ? "Maximizar"
                : i18n.t("StudentResponse.Maximize"),
            notepadMinimize:
              selectedLanguage === "es"
                ? "Minimizar"
                : i18n.t("StudentResponse.Minimize"),
            characterLimit:
              selectedLanguage === "es"
                ? "{{currentCount}}/{{totalCount}} límite de caracteres"
                : i18n.t("StudentResponse.CharacterLimit"),
            stickynoteCollapse:
              selectedLanguage === "es"
                ? "Colapsar"
                : i18n.t("StudentResponse.Collapse"),
            stickynoteCancelRemove:
              selectedLanguage === "es"
                ? "Cancelar"
                : i18n.t("StudentResponse.Cancel"),
            stickynoteConfirmBlurb:
              selectedLanguage === "es"
                ? "¿Quitar nota adhesiva?"
                : i18n.t("StudentResponse.StickynoteConfirmBlurb"),
            stickynoteConfirmRemove:
              selectedLanguage === "es"
                ? "Eliminar"
                : i18n.t("StudentResponse.Remove"),
            stickynoteExpand:
              selectedLanguage === "es"
                ? "Expandir"
                : i18n.t("StudentResponse.Expand"),
            stickynoteRemove:
              selectedLanguage === "es"
                ? "Eliminar"
                : i18n.t("StudentResponse.Remove"),
            texthighlightApply:
              selectedLanguage === "es"
                ? "Destacar"
                : i18n.t("StudentResponse.Highlight"),
            texthighlightHidePalette:
              selectedLanguage === "es"
                ? "Ocultar paleta de colores"
                : i18n.t("StudentResponse.HideColorPalette"),
            texthighlightRemove:
              selectedLanguage === "es"
                ? "Eliminar"
                : i18n.t("StudentResponse.Remove"),
            texthighlightShowPalette:
              selectedLanguage === "es"
                ? "Mostrar paleta de colores"
                : i18n.t("StudentResponse.TextHighlightShowPalette"),
            characterLimitReached:
              selectedLanguage === "es"
                ? "{{currentCount}}/{{totalCount}} límite de caracteres alcanzado"
                : i18n.t("StudentResponse.CharacterLimitReached"),
            drawingToolbarShowGridButton:
              selectedLanguage === "es"
                ? "Mostrar cuadrícula"
                : i18n.t("StudentResponse.ShowGrid"),
            drawingToolbarShowColorPaletteButton:
              selectedLanguage === "es"
                ? "Seleccionar el color"
                : i18n.t("StudentResponse.SelectColor"),
            drawingToolbarPencilButton:
              selectedLanguage === "es"
                ? "Lápiz"
                : i18n.t("StudentResponse.Pencil"),
            drawingToolbarHideGridButton:
              selectedLanguage === "es"
                ? "Ocultar cuadrícula"
                : i18n.t("StudentResponse.HideGrid"),
            drawingToolbarExitButton:
              selectedLanguage === "es"
                ? "Salir del modo de dibujo"
                : i18n.t("StudentResponse.ExitDrawingMode"),
            drawingToolbarEraserButton:
              selectedLanguage === "es"
                ? "Goma de borrar"
                : i18n.t("StudentResponse.Eraser"),
            drawingAreaShowGridButton:
              selectedLanguage === "es"
                ? "Mostrar cuadrícula"
                : i18n.t("StudentResponse.ShowGrid"),
            drawingAreaHideGridButton:
              selectedLanguage === "es"
                ? "Ocultar cuadrícula"
                : i18n.t("StudentResponse.HideGrid"),
            drawingAreaExitButton:
              selectedLanguage === "es"
                ? "Salir del modo de dibujo"
                : i18n.t("StudentResponse.ExitDrawingMode"),
          },
        },
        ui_style: "horizontal",
        renderSaveButton: true,
        navigation: {
          skip_submit_confirmation: includeAdaptive ? true : false,
          auto_save: {
            changed_responses_only: true,
            saveIntervalDuration: 300,
          },
          show_save: true,
          show_outro: false,
          show_intro: includeStartPage,
          resource_items: calculatorReferenceId
            ? [calculatorReferenceId]
            : undefined,
          warning_on_change:
            skipQuestions || allowPartialSubmit
              ? false
              : {
                  disable_item_navigation: true,
                },
          warning_on_section_change: true,
        },
        configuration: {
          onsubmit_redirect_url: false,
          lazyload: false,
          events: true,
          onsave_redirect_url: onExitRedirectUrl,
          ondiscard_redirect_url: false,
          decouple_submit_from_review: true,
          submit_criteria: allowPartialSubmit
            ? null
            : {
                type: "attempted",
                threshold: 100,
              },
          fontsize: fontSize
            ? fontSize
            : studentType && studentType === "K1"
            ? "xxlarge"
            : "large",
          shuffle_items: shuffleItems ?? false,
          section_options: {
            allow_backward_navigation: false,
          },
        },
        labelBundle: {
          saveButtonLabel:
            selectedLanguage === "es"
              ? "Ahorrar/Salida"
              : i18n.t("StudentResponse.Save/Exit"),
          resources:
            selectedLanguage === "es"
              ? "Calculadoras"
              : i18n.t("StudentResponse.Calculators"),
          introItemMessage: introMessage,
          submitButtonLabel:
            selectedLanguage === "es"
              ? "Enviar"
              : i18n.t("StudentResponse.Submit"),
          submitTest:
            selectedLanguage === "es"
              ? "Enviar respuesta"
              : i18n.t("StudentResponse.SubmitAnswer"),
          nextButtonLabel:
            selectedLanguage === "es"
              ? "Próximo"
              : i18n.t("StudentResponse.Next"),
          fullScreen:
            selectedLanguage === "es"
              ? "Pantalla completa"
              : i18n.t("StudentResponse.FullScreen"),
          exitFullScreen:
            selectedLanguage === "es"
              ? "Salir de pantalla completa"
              : i18n.t("StudentResponse.ExitFullScreen"),
          accessibility:
            selectedLanguage === "es"
              ? "Accesibilidad"
              : i18n.t("StudentResponse.Accessibility"),
          notepad:
            selectedLanguage === "es"
              ? "Bloc"
              : i18n.t("StudentResponse.Notepad"),
          addStickyNote:
            selectedLanguage === "es"
              ? "Añadir nota adhesiva"
              : i18n.t("StudentResponse.AddStickyNote"),
          hideStickyNotes:
            selectedLanguage === "es"
              ? "Ocultar notas adhesivas"
              : i18n.t("StudentResponse.HideStickyNotes"),
          drawingMode:
            selectedLanguage === "es"
              ? "Modo de dibujo"
              : i18n.t("StudentResponse.DrawingMode"),
          hideDrawings:
            selectedLanguage === "es"
              ? "Ocultar dibujos"
              : i18n.t("StudentResponse.HideDrawings"),
          answerMasking:
            selectedLanguage === "es"
              ? "Enmascaramiento de respuesta"
              : i18n.t("StudentResponse.AnswerMasking"),
          collapseMenu:
            selectedLanguage === "es"
              ? "Menú colapsar"
              : i18n.t("StudentResponse.CollapseMenu"),
          expandMenu:
            selectedLanguage === "es"
              ? "Expandir Menú"
              : i18n.t("StudentResponse.ExpandMenu"),
          flagItem:
            selectedLanguage === "es"
              ? "Elemento de la bandera"
              : i18n.t("StudentResponse.FlagItem"),
          unflagItem:
            selectedLanguage === "es"
              ? "Desmarcar elemento"
              : i18n.t("StudentResponse.UnflagItem"),
          reviewScreen:
            selectedLanguage === "es"
              ? "Revisar"
              : i18n.t("StudentResponse.ReviewScreen"),
          allQuestionsAttempted:
            selectedLanguage === "es"
              ? "Se han intentado todas las preguntas"
              : i18n.t("StudentResponse.AllQuestionsAttempted"),
          actionsubmit:
            selectedLanguage === "es"
              ? "enviar"
              : i18n.t("StudentResponse.Actionsubmit"),
          actionsave:
            selectedLanguage === "es"
              ? "ahorrar"
              : i18n.t("StudentResponse.Actionsave"),
          isAllResponsesAttempted:
            selectedLanguage === "es"
              ? "Se han intentado todas las preguntas. ¿Quieres enviar la actividad ahora?"
              : i18n.t("StudentResponse.IsAllResponsesAttempted"),
          submitActionConfirmation:
            selectedLanguage === "es"
              ? "¿Está seguro de que desea {{action}} la {{test}}?"
              : i18n.t("StudentResponse.SubmitActionConfirmation"),
          submittingInProgress:
            selectedLanguage === "es"
              ? "Envío en progreso"
              : i18n.t("StudentResponse.SubmittingInProgress"),
          savingInProgress:
            selectedLanguage === "es"
              ? "Guardando en progreso"
              : i18n.t("StudentResponse.SavingInProgress"),
          yes: allowPartialSubmit
            ? selectedLanguage === "es"
              ? "Enviar Tal Cual"
              : i18n.t("StudentResponse.SubmitAsIs")
            : selectedLanguage === "es"
            ? "sí"
            : i18n.t("StudentResponse.Yes"),
          no: allowPartialSubmit
            ? selectedLanguage === "es"
              ? "No Envíe"
              : i18n.t("StudentResponse.DoNotSubmit")
            : selectedLanguage === "es"
            ? "no"
            : i18n.t("StudentResponse.No"),
          test:
            selectedLanguage === "es"
              ? "actividad"
              : i18n.t("StudentResponse.Test"),
          continue:
            selectedLanguage === "es"
              ? "seguir"
              : i18n.t("StudentResponse.Continue"),
          confirm:
            selectedLanguage === "es"
              ? "confirmar"
              : i18n.t("StudentResponse.Confirm"),
          close:
            selectedLanguage === "es"
              ? "Cerca"
              : i18n.t("StudentResponse.Close"),
          submitCriteriaNotMet:
            selectedLanguage === "es"
              ? "No se han cumplido los siguientes criterios de presentación"
              : i18n.t("StudentResponse.SubmitCriteriaNotMet"),
          submitCriteriaLessThanRequiredAttemptedQuestionsWarning:
            selectedLanguage === "es"
              ? "Debe intentar el 100% de las preguntas. Por favor intente todas las preguntas."
              : i18n.t(
                  "StudentResponse.SubmitCriteriaLessThanRequiredAttemptedQuestionsWarning"
                ),
          warningOnSectionChangeBodyMsg:
            selectedLanguage === "es"
              ? "Asegúrate de haber respondido todas las preguntas de esta sección. Una vez que todas las preguntas estén contestadas y elijas continuar, no podrás regresar. ¿Deseas avanzar a la siguiente sección?"
              : i18n.t("StudentResponse.ChangeSectionWarning"),
          warningOnSectionChangeCancelButton:
            selectedLanguage === "es"
              ? "Revisar preguntas de la sección"
              : i18n.t("StudentResponse.CancelSectionWarningButton"),
          warningOnSectionChangeContinueButton:
            selectedLanguage === "es"
              ? "Continuar a la siguiente sección"
              : i18n.t("StudentResponse.ContinueSectionWarningButton"),
          saved:
            selectedLanguage === "es"
              ? "Salvado"
              : i18n.t("StudentResponse.Save"),
          saveTestSuccessfully:
            selectedLanguage === "es"
              ? "Tu actividad ha sido guardada."
              : i18n.t("StudentResponse.ActivitySaved"),
          saveTestSuccessfullyPrompt:
            selectedLanguage === "es"
              ? "¿Quieres retomar tu actividad, o salir?"
              : i18n.t("StudentResponse.DoYouWantToResume"),
          resume:
            selectedLanguage === "es"
              ? "Reanudar"
              : i18n.t("StudentResponse.Resume"),
          quit:
            selectedLanguage === "es"
              ? "Salida"
              : i18n.t("StudentResponse.Exit"),
          accessibilityPanel:
            selectedLanguage === "es"
              ? "Opciones de accesibilidad"
              : i18n.t("StudentResponse.AccessibilityPanel"),
          colorScheme:
            selectedLanguage === "es"
              ? "Esquema de colores"
              : i18n.t("StudentResponse.ColorScheme"),
          fontSize:
            selectedLanguage === "es"
              ? "Tamaño de fuente"
              : i18n.t("StudentResponse.FontSize"),
          zoom:
            selectedLanguage === "es" ? "Zoom" : i18n.t("StudentResponse.Zoom"),
          paletteInstructions:
            selectedLanguage === "es"
              ? "Cambia los colores de fondo y de primer plano de tu actividad."
              : i18n.t("StudentResponse.PaletteInstructions"),
          blackOnWhite:
            selectedLanguage === "es"
              ? "Negra sobre blanco (predeterminado)"
              : i18n.t("StudentResponse.BlackOnWhite"),
          purpleOnGreen:
            selectedLanguage === "es"
              ? "Púrpura sobre verde claro"
              : i18n.t("StudentResponse.PurpleOnGreen"),
          yellowOnBlue:
            selectedLanguage === "es"
              ? "Amarilla en azul marino"
              : i18n.t("StudentResponse.YellowOnBlue"),
          blackOnRose:
            selectedLanguage === "es"
              ? "Negra sobre violeta"
              : i18n.t("StudentResponse.BlackOnRose"),
          greyOnGrey:
            selectedLanguage === "es"
              ? "Gris sobre gris claro"
              : i18n.t("StudentResponse.GreyOnGrey"),
          whiteOnBlack:
            selectedLanguage === "es"
              ? "Blanca sobre negro"
              : i18n.t("StudentResponse.WhiteOnBlack"),
          customColorPalette:
            selectedLanguage === "es"
              ? "Esquema personalizado"
              : i18n.t("StudentResponse.CustomColorPalette"),
          fontSizeInstructions:
            selectedLanguage === "es"
              ? "Ajusta el tamaño de las fuentes en tu actividad."
              : i18n.t("StudentResponse.FontSizeInstructions"),
          small:
            selectedLanguage === "es"
              ? "Pequeña"
              : i18n.t("StudentResponse.Small"),
          normal:
            selectedLanguage === "es"
              ? "Normal"
              : i18n.t("StudentResponse.Normal"),
          large:
            selectedLanguage === "es"
              ? "Largo"
              : i18n.t("StudentResponse.Large"),
          xlarge:
            selectedLanguage === "es"
              ? "Extra grande"
              : i18n.t("StudentResponse.Xlarge"),
          xxlarge:
            selectedLanguage === "es"
              ? "Gigante"
              : i18n.t("StudentResponse.Xxlarge"),
          zoomInstructions:
            selectedLanguage === "es"
              ? "Acercar y alejar usando los siguientes métodos abreviados de teclado:"
              : i18n.t("StudentResponse.ZoomInstructions"),
          command:
            selectedLanguage === "es"
              ? "Dominio"
              : i18n.t("StudentResponse.Command"),
          control:
            selectedLanguage === "es"
              ? "Ctrl"
              : i18n.t("StudentResponse.Control"),
          zoomIn:
            selectedLanguage === "es"
              ? "Acercarse"
              : i18n.t("StudentResponse.ZoomIn"),
          zoomOut:
            selectedLanguage === "es"
              ? "Disminuir el zoom"
              : i18n.t("StudentResponse.ZoomOut"),
          returnZoom:
            selectedLanguage === "es"
              ? "Restablecer acercamiento"
              : i18n.t("StudentResponse.ReturnZoom"),
          toZoomIn:
            selectedLanguage === "es"
              ? "Para acercar, presione"
              : i18n.t("StudentResponse.ToZoomIn"),
          toZoomOut:
            selectedLanguage === "es"
              ? "Para alejar, presione"
              : i18n.t("StudentResponse.ToZoomOut"),
          toReturnZoom:
            selectedLanguage === "es"
              ? "Restablezca el nivel de zoom presionando"
              : i18n.t("StudentResponse.ToReturnZoom"),
          zoomInResult:
            selectedLanguage === "es"
              ? "El navegador se acercará gradualmente cada vez que presione la tecla más (+)."
              : i18n.t("StudentResponse.ZoomInResult"),
          zoomOutResult:
            selectedLanguage === "es"
              ? "El navegador se alejará gradualmente cada vez que presione la tecla menos (-)."
              : i18n.t("StudentResponse.ZoomOutResult"),
          returnZoomResult:
            selectedLanguage === "es"
              ? "El navegador volverá a su nivel de zoom predeterminado."
              : i18n.t("StudentResponse.ReturnZoomResult"),
          showDrawings:
            selectedLanguage === "es"
              ? "Mostrar dibujos"
              : i18n.t("StudentResponse.ShowDrawings"),
          toggleDrawingVisibility:
            selectedLanguage === "es"
              ? "Alternar la visibilidad del dibujo"
              : i18n.t("StudentResponse.ToggleDrawingVisibility"),
          toggleDrawingMode:
            selectedLanguage === "es"
              ? "Alternar modo de dibujo"
              : i18n.t("StudentResponse.ToggleDrawingMode"),
          submitWithUnattemptedQuestions:
            selectedLanguage === "es"
              ? "Estás a punto de enviar una actividad con {{unattemptedResponsesCount}} preguntas que no han sido respondidas. Si elige enviar sin responder las preguntas pendientes, esas preguntas se marcarán como incorrectas."
              : i18n.t("StudentResponse.SubmitWithUnattemptedQuestions"),
          submitWithUnattemptedQuestion:
            selectedLanguage === "es"
              ? "Estás a punto de enviar una actividad con {{unattemptedResponsesCount}} preguntas que no han sido respondidas. Si elige enviar sin responder las preguntas pendientes, esas preguntas se marcarán como incorrectas."
              : i18n.t("StudentResponse.SubmitWithUnattemptedQuestions"),
        },
        regions: {
          "top-left": [
            {
              type: "title_element",
            },
          ],
          "top-right": [
            {
              type: "pause_button",
              position: "right",
            },
            {
              type: "timer_element",
            },
            {
              type: "itemcount_element",
            },
            allowPartialSubmit && cannedTestId != null && includeSections
              ? { type: "previous_button" }
              : null,
            {
              type: "next_button",
            },
          ],
          right: [
            {
              type: "save_button",
            },
            // {
            //   type: "fullscreen_button",
            // },
            {
              type: "separator_element",
            },
            {
              type: "accessibility_button",
            },
            {
              type: "resource_button",
            },
            skipQuestions
              ? {
                  type: "reviewscreen_button",
                }
              : null,
            skipQuestions
              ? {
                  type: "flagitem_button",
                }
              : null,
            {
              type: "masking_button",
            },
            {
              type: "notepad_button",
            },
            {
              type: "stickynote_add_button",
            },
            {
              type: "stickynote_visibility_button",
            },
            {
              type: "drawing_mode_button",
            },
            {
              type: "drawing_visibility_button",
            },
            // {
            //   type: "linereader_button",
            // },
          ],
          items: [
            {
              type: "slider_element",
              scrollable_option: false,
            },
            {
              type: "progress_element",
            },
          ],
        },
        questions_api_init_options: {
          attribute_overrides: {
            instant_feedback: checkAnswer,
          },
          labelBundle: {
            checkAnswer:
              selectedLanguage === "es"
                ? "Checar respuesta"
                : i18n.t("StudentResponse.CheckAnswer"),
          },
        },
        time:
          timeLimit && timeLimit > 0
            ? {
                max_time: timeLimit,
                limit_type: "soft",
                warning_time: 10,
                // countdown_option: true,
              }
            : null,
      },
      sections: includeSections
        ? await GetSectionData(cannedTestId, selectedLanguage)
        : null,
    };
  } else if (questions && itemIds.length > 0) {
    return {
      user_id: studentId,
      rendering_type: "assess",
      name: "Items API Test",
      state: state,
      items: itemIds,
      activity_id: teacherActivityId,
      session_id: sessionId,
      type: "submit_practice",
      adaptive: includeAdaptive
        ? await GetAdaptiveData(adaptiveFilter, selectedLanguage)
        : null,
      config: {
        //regions: "main",
        annotations: true,
        annotations_api_init_options: {
          modules: {
            drawing: true,
            texthighlight: true,
            notepad: true,
            stickynote: true,
          },
          labelBundle: {
            notepadTitle:
              selectedLanguage === "es"
                ? "Bloc"
                : i18n.t("StudentResponse.Notepad"),
            notepadHide:
              selectedLanguage === "es"
                ? "Esconder"
                : i18n.t("StudentResponse.Hide"),
            notepadCollapse:
              selectedLanguage === "es"
                ? "Colapsar"
                : i18n.t("StudentResponse.Collapse"),
            notepadMaximize:
              selectedLanguage === "es"
                ? "Maximizar"
                : i18n.t("StudentResponse.Maximize"),
            notepadMinimize:
              selectedLanguage === "es"
                ? "Minimizar"
                : i18n.t("StudentResponse.Minimize"),
            characterLimit:
              selectedLanguage === "es"
                ? "{{currentCount}}/{{totalCount}} límite de caracteres"
                : i18n.t("StudentResponse.CharacterLimit"),
            stickynoteCollapse:
              selectedLanguage === "es"
                ? "Colapsar"
                : i18n.t("StudentResponse.Collapse"),
            stickynoteCancelRemove:
              selectedLanguage === "es"
                ? "Cancelar"
                : i18n.t("StudentResponse.Cancel"),
            stickynoteConfirmBlurb:
              selectedLanguage === "es"
                ? "¿Quitar nota adhesiva?"
                : i18n.t("StudentResponse.StickynoteConfirmBlurb"),
            stickynoteConfirmRemove:
              selectedLanguage === "es"
                ? "Eliminar"
                : i18n.t("StudentResponse.Remove"),
            stickynoteExpand:
              selectedLanguage === "es"
                ? "Expandir"
                : i18n.t("StudentResponse.Expand"),
            stickynoteRemove:
              selectedLanguage === "es"
                ? "Eliminar"
                : i18n.t("StudentResponse.Remove"),
            texthighlightApply:
              selectedLanguage === "es"
                ? "Destacar"
                : i18n.t("StudentResponse.Highlight"),
            texthighlightHidePalette:
              selectedLanguage === "es"
                ? "Ocultar paleta de colores"
                : i18n.t("StudentResponse.HideColorPalette"),
            texthighlightRemove:
              selectedLanguage === "es"
                ? "Eliminar"
                : i18n.t("StudentResponse.Remove"),
            texthighlightShowPalette:
              selectedLanguage === "es"
                ? "Mostrar paleta de colores"
                : i18n.t("StudentResponse.TextHighlightShowPalette"),
            characterLimitReached:
              selectedLanguage === "es"
                ? "{{currentCount}}/{{totalCount}} límite de caracteres alcanzado"
                : i18n.t("StudentResponse.CharacterLimitReached"),
            drawingToolbarShowGridButton:
              selectedLanguage === "es"
                ? "Mostrar cuadrícula"
                : i18n.t("StudentResponse.ShowGrid"),
            drawingToolbarShowColorPaletteButton:
              selectedLanguage === "es"
                ? "Seleccionar el color"
                : i18n.t("StudentResponse.SelectColor"),
            drawingToolbarPencilButton:
              selectedLanguage === "es"
                ? "Lápiz"
                : i18n.t("StudentResponse.Pencil"),
            drawingToolbarHideGridButton:
              selectedLanguage === "es"
                ? "Ocultar cuadrícula"
                : i18n.t("StudentResponse.HideGrid"),
            drawingToolbarExitButton:
              selectedLanguage === "es"
                ? "Salir del modo de dibujo"
                : i18n.t("StudentResponse.ExitDrawingMode"),
            drawingToolbarEraserButton:
              selectedLanguage === "es"
                ? "Goma de borrar"
                : i18n.t("StudentResponse.Eraser"),
            drawingAreaShowGridButton:
              selectedLanguage === "es"
                ? "Mostrar cuadrícula"
                : i18n.t("StudentResponse.ShowGrid"),
            drawingAreaHideGridButton:
              selectedLanguage === "es"
                ? "Ocultar cuadrícula"
                : i18n.t("StudentResponse.HideGrid"),
            drawingAreaExitButton:
              selectedLanguage === "es"
                ? "Salir del modo de dibujo"
                : i18n.t("StudentResponse.ExitDrawingMode"),
          },
        },
        ui_style: "horizontal",
        navigation: {
          auto_save: {
            changed_responses_only: true,
            saveIntervalDuration: 300,
          },
          show_save: true,
          show_outro: false,
          show_intro: includeStartPage,
          warning_on_change:
            skipQuestions || allowPartialSubmit
              ? false
              : {
                  disable_item_navigation: true,
                },
          warning_on_section_change: true,
        },
        configuration: {
          onsubmit_redirect_url: false,
          lazyload: false,
          events: false,
          onsave_redirect_url: onExitRedirectUrl,
          decouple_submit_from_review: true,
          submit_criteria: allowPartialSubmit
            ? null
            : {
                type: "attempted",
                threshold: 100,
              },
          fontsize: fontSize
            ? fontSize
            : studentType && studentType === "K1"
            ? "xxlarge"
            : "large",
          shuffle_items: shuffleItems ?? false,
          section_options: {
            allow_backward_navigation: false,
          },
        },
        labelBundle: {
          saveButtonLabel:
            selectedLanguage === "es"
              ? "Ahorrar/Salida"
              : i18n.t("StudentResponse.Save/Exit"),
          resources:
            selectedLanguage === "es"
              ? "Calculadoras"
              : i18n.t("StudentResponse.Calculators"),
          introItemMessage: introMessage,
          submitButtonLabel:
            selectedLanguage === "es"
              ? "Enviar"
              : i18n.t("StudentResponse.Submit"),
          submitTest:
            selectedLanguage === "es"
              ? "Enviar respuesta"
              : i18n.t("StudentResponse.SubmitAnswer"),
          nextButtonLabel:
            selectedLanguage === "es"
              ? "Próximo"
              : i18n.t("StudentResponse.Next"),
          fullScreen:
            selectedLanguage === "es"
              ? "Pantalla completa"
              : i18n.t("StudentResponse.FullScreen"),
          exitFullScreen:
            selectedLanguage === "es"
              ? "Salir de pantalla completa"
              : i18n.t("StudentResponse.ExitFullScreen"),
          accessibility:
            selectedLanguage === "es"
              ? "Accesibilidad"
              : i18n.t("StudentResponse.Accessibility"),
          notepad:
            selectedLanguage === "es"
              ? "Bloc"
              : i18n.t("StudentResponse.Notepad"),
          addStickyNote:
            selectedLanguage === "es"
              ? "Añadir nota adhesiva"
              : i18n.t("StudentResponse.AddStickyNote"),
          hideStickyNotes:
            selectedLanguage === "es"
              ? "Ocultar notas adhesivas"
              : i18n.t("StudentResponse.HideStickyNotes"),
          drawingMode:
            selectedLanguage === "es"
              ? "Modo de dibujo"
              : i18n.t("StudentResponse.DrawingMode"),
          hideDrawings:
            selectedLanguage === "es"
              ? "Ocultar dibujos"
              : i18n.t("StudentResponse.HideDrawings"),
          answerMasking:
            selectedLanguage === "es"
              ? "Enmascaramiento de respuesta"
              : i18n.t("StudentResponse.AnswerMasking"),
          collapseMenu:
            selectedLanguage === "es"
              ? "Menú colapsar"
              : i18n.t("StudentResponse.CollapseMenu"),
          expandMenu:
            selectedLanguage === "es"
              ? "Expandir Menú"
              : i18n.t("StudentResponse.ExpandMenu"),
          flagItem:
            selectedLanguage === "es"
              ? "Elemento de la bandera"
              : i18n.t("StudentResponse.FlagItem"),
          unflagItem:
            selectedLanguage === "es"
              ? "Desmarcar elemento"
              : i18n.t("StudentResponse.UnflagItem"),
          reviewScreen:
            selectedLanguage === "es"
              ? "Revisar"
              : i18n.t("StudentResponse.ReviewScreen"),
          allQuestionsAttempted:
            selectedLanguage === "es"
              ? "Se han intentado todas las preguntas"
              : i18n.t("StudentResponse.AllQuestionsAttempted"),
          actionsubmit:
            selectedLanguage === "es"
              ? "enviar"
              : i18n.t("StudentResponse.Actionsubmit"),
          actionsave:
            selectedLanguage === "es"
              ? "ahorrar"
              : i18n.t("StudentResponse.Actionsave"),
          isAllResponsesAttempted:
            selectedLanguage === "es"
              ? "Se han intentado todas las preguntas. ¿Quieres enviar la actividad ahora?"
              : i18n.t("StudentResponse.IsAllResponsesAttempted"),
          submitActionConfirmation:
            selectedLanguage === "es"
              ? "¿Está seguro de que desea {{action}} la {{test}}?"
              : i18n.t("StudentResponse.SubmitActionConfirmation"),
          submittingInProgress:
            selectedLanguage === "es"
              ? "Envío en progreso"
              : i18n.t("StudentResponse.SubmittingInProgress"),
          savingInProgress:
            selectedLanguage === "es"
              ? "Guardando en progreso"
              : i18n.t("StudentResponse.SavingInProgress"),
          yes: selectedLanguage === "es" ? "sí" : i18n.t("StudentResponse.Yes"),
          no: selectedLanguage === "es" ? "no" : i18n.t("StudentResponse.No"),
          test:
            selectedLanguage === "es"
              ? "actividad"
              : i18n.t("StudentResponse.Test"),
          continue:
            selectedLanguage === "es"
              ? "seguir"
              : i18n.t("StudentResponse.Continue"),
          confirm:
            selectedLanguage === "es"
              ? "confirmar"
              : i18n.t("StudentResponse.Confirm"),
          close:
            selectedLanguage === "es"
              ? "Cerca"
              : i18n.t("StudentResponse.Close"),
          submitCriteriaNotMet:
            selectedLanguage === "es"
              ? "No se han cumplido los siguientes criterios de presentación"
              : i18n.t("StudentResponse.SubmitCriteriaNotMet"),
          submitCriteriaLessThanRequiredAttemptedQuestionsWarning:
            selectedLanguage === "es"
              ? "Debe intentar el 100% de las preguntas. Por favor intente todas las preguntas."
              : i18n.t(
                  "StudentResponse.SubmitCriteriaLessThanRequiredAttemptedQuestionsWarning"
                ),
          warningOnSectionChangeBodyMsg:
            selectedLanguage === "es"
              ? "Asegúrate de haber respondido todas las preguntas de esta sección. Una vez que todas las preguntas estén contestadas y elijas continuar, no podrás regresar. ¿Deseas avanzar a la siguiente sección?"
              : i18n.t("StudentResponse.ChangeSectionWarning"),
          warningOnSectionChangeCancelButton:
            selectedLanguage === "es"
              ? "Revisar preguntas de la sección"
              : i18n.t("StudentResponse.CancelSectionWarningButton"),
          warningOnSectionChangeContinueButton:
            selectedLanguage === "es"
              ? "Continuar a la siguiente sección"
              : i18n.t("StudentResponse.ContinueSectionWarningButton"),
          saved:
            selectedLanguage === "es"
              ? "Salvado"
              : i18n.t("StudentResponse.Save"),
          saveTestSuccessfully:
            selectedLanguage === "es"
              ? "Tu actividad ha sido guardada."
              : i18n.t("StudentResponse.ActivitySaved"),
          saveTestSuccessfullyPrompt:
            selectedLanguage === "es"
              ? "¿Quieres retomar tu actividad, o salir?"
              : i18n.t("StudentResponse.DoYouWantToResume"),
          resume:
            selectedLanguage === "es"
              ? "Reanudar"
              : i18n.t("StudentResponse.Resume"),
          quit:
            selectedLanguage === "es"
              ? "Salida"
              : i18n.t("StudentResponse.Exit"),
          accessibilityPanel:
            selectedLanguage === "es"
              ? "Opciones de accesibilidad"
              : i18n.t("StudentResponse.AccessibilityPanel"),
          colorScheme:
            selectedLanguage === "es"
              ? "Esquema de colores"
              : i18n.t("StudentResponse.ColorScheme"),
          fontSize:
            selectedLanguage === "es"
              ? "Tamaño de fuente"
              : i18n.t("StudentResponse.FontSize"),
          zoom:
            selectedLanguage === "es" ? "Zoom" : i18n.t("StudentResponse.Zoom"),
          paletteInstructions:
            selectedLanguage === "es"
              ? "Cambia los colores de fondo y de primer plano de tu actividad."
              : i18n.t("StudentResponse.PaletteInstructions"),
          blackOnWhite:
            selectedLanguage === "es"
              ? "Negra sobre blanco (predeterminado)"
              : i18n.t("StudentResponse.BlackOnWhite"),
          purpleOnGreen:
            selectedLanguage === "es"
              ? "Púrpura sobre verde claro"
              : i18n.t("StudentResponse.PurpleOnGreen"),
          yellowOnBlue:
            selectedLanguage === "es"
              ? "Amarilla en azul marino"
              : i18n.t("StudentResponse.YellowOnBlue"),
          blackOnRose:
            selectedLanguage === "es"
              ? "Negra sobre violeta"
              : i18n.t("StudentResponse.BlackOnRose"),
          greyOnGrey:
            selectedLanguage === "es"
              ? "Gris sobre gris claro"
              : i18n.t("StudentResponse.GreyOnGrey"),
          whiteOnBlack:
            selectedLanguage === "es"
              ? "Blanca sobre negro"
              : i18n.t("StudentResponse.WhiteOnBlack"),
          customColorPalette:
            selectedLanguage === "es"
              ? "Esquema personalizado"
              : i18n.t("StudentResponse.CustomColorPalette"),
          fontSizeInstructions:
            selectedLanguage === "es"
              ? "Ajusta el tamaño de las fuentes en tu actividad."
              : i18n.t("StudentResponse.FontSizeInstructions"),
          small:
            selectedLanguage === "es"
              ? "Pequeña"
              : i18n.t("StudentResponse.Small"),
          normal:
            selectedLanguage === "es"
              ? "Normal"
              : i18n.t("StudentResponse.Normal"),
          large:
            selectedLanguage === "es"
              ? "Largo"
              : i18n.t("StudentResponse.Large"),
          xlarge:
            selectedLanguage === "es"
              ? "Extra grande"
              : i18n.t("StudentResponse.Xlarge"),
          xxlarge:
            selectedLanguage === "es"
              ? "Gigante"
              : i18n.t("StudentResponse.Xxlarge"),
          zoomInstructions:
            selectedLanguage === "es"
              ? "Acercar y alejar usando los siguientes métodos abreviados de teclado:"
              : i18n.t("StudentResponse.ZoomInstructions"),
          command:
            selectedLanguage === "es"
              ? "Dominio"
              : i18n.t("StudentResponse.Command"),
          control:
            selectedLanguage === "es"
              ? "Ctrl"
              : i18n.t("StudentResponse.Control"),
          zoomIn:
            selectedLanguage === "es"
              ? "Acercarse"
              : i18n.t("StudentResponse.ZoomIn"),
          zoomOut:
            selectedLanguage === "es"
              ? "Disminuir el zoom"
              : i18n.t("StudentResponse.ZoomOut"),
          returnZoom:
            selectedLanguage === "es"
              ? "Restablecer acercamiento"
              : i18n.t("StudentResponse.ReturnZoom"),
          toZoomIn:
            selectedLanguage === "es"
              ? "Para acercar, presione"
              : i18n.t("StudentResponse.ToZoomIn"),
          toZoomOut:
            selectedLanguage === "es"
              ? "Para alejar, presione"
              : i18n.t("StudentResponse.ToZoomOut"),
          toReturnZoom:
            selectedLanguage === "es"
              ? "Restablezca el nivel de zoom presionando"
              : i18n.t("StudentResponse.ToReturnZoom"),
          zoomInResult:
            selectedLanguage === "es"
              ? "El navegador se acercará gradualmente cada vez que presione la tecla más (+)."
              : i18n.t("StudentResponse.ZoomInResult"),
          zoomOutResult:
            selectedLanguage === "es"
              ? "El navegador se alejará gradualmente cada vez que presione la tecla menos (-)."
              : i18n.t("StudentResponse.ZoomOutResult"),
          returnZoomResult:
            selectedLanguage === "es"
              ? "El navegador volverá a su nivel de zoom predeterminado."
              : i18n.t("StudentResponse.ReturnZoomResult"),
          showDrawings:
            selectedLanguage === "es"
              ? "Mostrar dibujos"
              : i18n.t("StudentResponse.ShowDrawings"),
          toggleDrawingVisibility:
            selectedLanguage === "es"
              ? "Alternar la visibilidad del dibujo"
              : i18n.t("StudentResponse.ToggleDrawingVisibility"),
          ToggleDrawingMode:
            selectedLanguage === "es"
              ? "Alternar modo de dibujo"
              : i18n.t("StudentResponse.ToggleDrawingMode"),
          submitWithUnattemptedQuestions:
            selectedLanguage === "es"
              ? "Estás a punto de enviar una actividad con {{unattemptedResponsesCount}} preguntas que no han sido respondidas. Si elige enviar sin responder las preguntas pendientes, esas preguntas se marcarán como incorrectas."
              : i18n.t("StudentResponse.SubmitWithUnattemptedQuestions"),
          submitWithUnattemptedQuestion:
            selectedLanguage === "es"
              ? "Estás a punto de enviar una actividad con {{unattemptedResponsesCount}} preguntas que no han sido respondidas. Si elige enviar sin responder las preguntas pendientes, esas preguntas se marcarán como incorrectas."
              : i18n.t("StudentResponse.SubmitWithUnattemptedQuestions"),
        },

        regions: {
          "top-left": [
            {
              type: "title_element",
            },
          ],
          "top-right": [
            {
              type: "pause_button",
              position: "right",
            },
            {
              type: "timer_element",
            },
            {
              type: "itemcount_element",
            },
            !fromStudentResponsePopUp
              ? {
                  type: "next_button",
                }
              : null,
          ],
          right: [
            !hideSaveBtn
              ? {
                  type: "save_button",
                }
              : null,
            // {
            //   type: "fullscreen_button",
            // },
            {
              type: "separator_element",
            },
            {
              type: "accessibility_button",
            },
            {
              type: "notepad_button",
            },
            {
              type: "stickynote_add_button",
            },
            {
              type: "stickynote_visibility_button",
            },
            {
              type: "drawing_mode_button",
            },
            {
              type: "drawing_visibility_button",
            },
            // {
            //   type: "linereader_button",
            // },
            skipQuestions
              ? {
                  type: "reviewscreen_button",
                }
              : null,
            skipQuestions
              ? {
                  type: "flagitem_button",
                }
              : null,
            {
              type: "masking_button",
            },
            {
              type: "resource_button",
            },
          ],
          items: [
            {
              type: "slider_element",
              scrollable_option: false,
            },
            {
              type: "progress_element",
            },
          ],
          "bottom-right": [
            fromStudentResponsePopUp
              ? {
                  type: "next_button",
                }
              : null,
          ],
        },
        questions_api_init_options: {
          attribute_overrides: {
            instant_feedback: checkAnswer,
          },
          labelBundle: {
            checkAnswer:
              selectedLanguage === "es"
                ? "Checar respuesta"
                : i18n.t("StudentResponse.CheckAnswer"),
          },
        },
        time:
          timeLimit && timeLimit > 0
            ? {
                max_time: timeLimit,
                limit_type: "soft",
                warning_time: 10,
                // countdown_option: true,
              }
            : null,
      },
    };
  }
}

import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { XIcon } from "@heroicons/react/solid";
import {
  EHStarIcon1,
  EHStarIcon2,
  EHStarIcon3,
} from "../../../../../assets/icons";
import { Configuration } from "../../../../../environment/setup";

interface GalaxyStarsProps {
  rewards?: any;
  type: string;
}

const GalaxyStars = (props: GalaxyStarsProps) => {
  const { t } = useTranslation();
  const [showGalaxyStarPopup, setGalaxyStarPopup] = useState(false);

  return (
    <>
      <div
        onClick={() => setGalaxyStarPopup(true)}
        className="h-full w-full bg-gradient-to-b flex items-center flex-col justify-end from-[#ff007f] to-[#df85c4] rounded-2xl py-4 px-4 text-center md:col-span-3 hover:shadow-2xl transition ease-in-out duration-500 group cursor-pointer"
      >
        <img
          className="w-auto h-24 m-auto flex-shrink-0 filter drop-shadow-2xl transition group-hover:scale-110 ease-in-out duration-500 "
          src={`${Configuration.S3bucketImagePath}/images/star-icon.svg`}
          alt="galaxy stars"
          title="galaxy stars"
        />

        {props.type === "PL" && props?.rewards?.plStarReward && (
          <p className="mt-2 ">{`${props?.rewards?.plStarReward?.earnedStar}/${props?.rewards?.plStarReward?.totalStar}`}</p>
        )}
        {props.type === "LO" && props?.rewards?.loStarReward && (
          <p className="mt-2 ">{`${props?.rewards?.loStarReward?.earnedStar}/${props?.rewards?.loStarReward?.totalStar}`}</p>
        )}
        <h5 className="text-lg"> {t("StudentElementaryHome.GalaxyStars")}</h5>
      </div>
      {showGalaxyStarPopup && (
        <Fragment>
          <div className="fixed inset-0 z-40 flex items-center bg-black bg-opacity-50 sm:justify-center enter-done">
            <div className="w-full px-6 py-4 overflow-hidden bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:max-w-xl">
              <div>
                <header className="flex justify-between">
                  <h2>{t("GalaxyStars.HowToEarn")}</h2>
                  <XIcon
                    className="cursor-pointer h-4 w-4"
                    onClick={() => setGalaxyStarPopup(false)}
                  ></XIcon>
                </header>
                <div className="my-3  rounded-2xl py-1 text-center transition ease-in-out duration-500 bg-galaxy-stars bg-cover bg-top">
                  <div className="text-lg text-gray-700 dark:text-gray-300 mb-6 overflow-auto text-left font-light">
                    <div className="text-left text-black">
                      <p className="align-bottom rounded-lg text-base  w-full gap-2 mb-1">
                        {t("GalaxyStars.WorkOn")}
                      </p>

                      <p className="align-bottom rounded-lg text-base  w-full gap-2 mb-1">
                        {t("GalaxyStars.EarnUp")}
                      </p>

                      <p className="align-bottom rounded-lg text-base  w-full gap-2 mb-1">
                        {t("GalaxyStars.MustComplete")}
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-col gap-4 ">
                    <div className="flex justify-start items-center ">
                      <EHStarIcon1 className="w-8"></EHStarIcon1>
                      <span className="text-2xl ml-5">
                        {" "}
                        {t("GalaxyStars.Score")} 70%
                      </span>
                    </div>
                    <div className="flex justify-start items-center">
                      <EHStarIcon2 className="w-14"></EHStarIcon2>
                      <span className="text-2xl ml-5">
                        {" "}
                        {t("GalaxyStars.Score")} 80%
                      </span>
                    </div>
                    <div className="flex justify-start items-center">
                      <EHStarIcon3 className="w-20"></EHStarIcon3>
                      <span className="text-2xl ml-5">
                        {" "}
                        {t("GalaxyStars.Score")} 100%
                      </span>
                    </div>
                  </div>
                </div>
                <footer className="flex flex-col items-center justify-end px-6 py-3 -mx-6 -mb-4 space-y-4 sm:space-y-0 sm:space-x-6 sm:flex-row bg-gray-50 dark:bg-gray-800">
                  <button
                    className="align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 rounded-lg text-sm text-white border border-transparent  focus:shadow-outline-purple w-full sm:w-auto bg-primary-blue hover:bg-[#0149c1] gap-2 hover:shadow-md"
                    type="button"
                    onClick={() => {
                      setGalaxyStarPopup(false);
                    }}
                  >
                    OK
                  </button>
                </footer>
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </>
  );
};

export default GalaxyStars;

import { useEffect, useState } from "react";
import { connect } from "react-redux";
import Loader from "../../../shared/loader";
import classNames from "classnames";
import {
  StatsIcon,
  FeedbackIcon,
  StarIcon,
  DeleteIcon,
  PrintIcon,
  DisplayQuestion,
  SaveQuestion,
  StarDarkIcon,
} from "../../../../assets/icons/index";
import { Modal, ModalBody, ModalFooter, Button } from "@windmill/react-ui";
import PreviewPage from "../shared/questionPreview/learnosityPreviewPage";
import { Link, useHistory } from "react-router-dom";
import constant from "../../../../utils/constant/routeConstant";
import {
  getRandomQuestionsByStandardId,
  postBellRinger,
  putBellRinger,
  favQuestion,
  deleteBellRinger,
} from "../../../../api/teacher/bellRinger";
import { toast } from "react-toastify";
import { getBellRinger } from "../../../../api/teacher/bellRinger";
import PrintQuestion from "./printQuestion";
import Permissions from "../../../../utils/permissions";
import AllowedTo from "../../../shared/rbac";
import AccessDenied from "../../../shared/accessDenied";
import Feedback from "../../../shared/feedback";
import Constant from "../../../../utils/constant/constant";
import Toggle from "../../../shared/toggle";
import { getQuestionStats } from "../../../../api/teacher/question";
import Classification from "../shared/questionPreview/classification";
import QuestionPrintDialog from "./questionPrintLearnosity";

function QuestionSet(props) {
  const history = useHistory();
  const idToEdit = props.match.params.id || "";
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedQuestion, setSelectedQuestion] = useState<string>("");
  const [selectedESQuestion, setSelectedESQuestion] = useState<string>("");
  const [selectedQuestionId, setSelectedQuestionId] = useState<number>();
  const [saveModal, setSaveModal] = useState<boolean>(false);
  const [removeModal, setRemoveModal] = useState<boolean>(false);
  const [statsModal, setStatsModal] = useState<boolean>(false);
  const [randomQuestions, setRandomQuestions] = useState<Array<any>>([]);
  const [bellRinger, setBellRinger] = useState<any>({});
  const [questionStats, setQuestionStats] = useState<any>({});
  const [printModal, setPrintModal] = useState<boolean>(false);
  const [showFeedbackPopup, setShowFeedbackPopup] = useState<boolean>(false);
  const [questionId, setQuestionId] = useState<number>(0);
  const [isSpanish, setIsSpanish] = useState(false);

  useEffect(() => {
    if (idToEdit) {
      getBellRingerData(idToEdit);
      return;
    }

    /** LS is used to maintain state back from display question page */
    let LS: any = localStorage.getItem("bellRinger");
    if (LS) {
      LS = JSON.parse(LS);
      setBellRinger({
        questionTypeName: LS.questionTypeName,
        questionTypeId: LS.questionTypeId,
        domainName: LS.domainName,
        domainId: LS.domainId,
        standardName: LS.standardName,
        standardId: LS.standardId,
        standardCode: LS.standardCode,
      });
      setRandomQuestions(LS.randomQuestions);
      setSelectedQuestion(LS.selectedQuestion);
      setSelectedQuestionId(LS.selectedQuestionId);
      setSelectedESQuestion(LS.selectedESQuestion);
    } else {
      setBellRinger({
        questionTypeName: props.selectedQuestionType?.label,
        questionTypeId: props.selectedQuestionType?.value,
        domainName: props.selectedDomain?.label,
        domainId: props.selectedDomain?.value,
        standardName: props.selectedStandard?.label,
        standardId: props.selectedStandard?.value,
        IsFavorite: 0,
        standardCode: props.selectedStandardCode ?? LS.standardCode,
      });
      getRandomQuestions(
        props.selectedStandard?.value,
        props.selectedQuestionType?.value
      );
    }
  }, []);

  useEffect(() => {
    if (randomQuestions.length > 0) {
      renderQuestion();
    }
  }, [selectedQuestion, randomQuestions]);

  const renderQuestion = () => {
    getRefId();
    //return <PreviewPage itemRef={getRefId()} />;
  };

  const getRandomQuestions = async (stdId, questionTypeId) => {
    setLoading(true);
    const resp = await getRandomQuestionsByStandardId(
      stdId,
      questionTypeId ?? 0,
      5
    );
    setLoading(false);
    if (resp && resp.data && resp.data.length) {
      setRandomQuestions(resp.data);
      setSelectedQuestion(resp.data[0].englishQuestionReferenceId);
      setSelectedESQuestion(resp.data[0].spanishQuestionReferenceId);
      setSelectedQuestionId(resp.data[0].questionId);
    } else {
      setRandomQuestions([]);
      setSelectedQuestion("");
      setSelectedESQuestion("");
      setSelectedQuestionId(0);
    }
  };

  const onDisplayQuestion = () => {
    localStorage.setItem(
      "bellRinger",
      JSON.stringify({
        domainName: bellRinger.domainName,
        domainId: bellRinger.domainId,
        standardName: bellRinger.standardName,
        standardId: bellRinger.standardId,
        questionTypeName: bellRinger.questionTypeName,
        questionTypeId: bellRinger.questionTypeId,
        randomQuestions: randomQuestions,
        selectedQuestion: selectedQuestion,
        selectedESQuestion: selectedESQuestion,
        instructions: bellRinger.instructions,
        selectedQuestionId: selectedQuestionId,
        standardCode: bellRinger.standardCode,
      })
    );
  };

  const getRefId = () => {
    let id = "";
    const data = randomQuestions.filter(
      (ds) => ds.englishQuestionReferenceId === selectedQuestion
    );
    if (data.length) {
      id = data[0].englishQuestionReferenceId;
      setQuestionId(data[0].questionId);
    }
    return id || "";
  };

  const onNameChange = (e) => {
    setBellRinger({ ...bellRinger, name: e.target.value });
  };

  const onInstructionChange = (e) => {
    setBellRinger({ ...bellRinger, instructions: e.target.value });
  };

  const saveQuestion = async () => {
    setLoading(true);
    if (!idToEdit) {
      let resp = await postBellRinger({
        teacherId: props.userContext.userId,
        name: bellRinger.name,
        instructions: bellRinger.instructions,
        questionTypeId: bellRinger.questionTypeId,
        questionId: selectedQuestionId,
        standardId: bellRinger.standardId,
      });
      if (resp?.data > 0) {
        toast.success("Bell Ringer Created Successfully");
      } else {
        toast.error("Something went wrong!");
      }
    } else {
      let resp = await putBellRinger({
        bellRingerId: bellRinger.bellRingerId,
        teacherId: props.userContext.userId,
        name: bellRinger.name,
        instructions: bellRinger.instructions,
        questionTypeId: bellRinger.questionTypeId,
        questionId: selectedQuestionId,
        standardId: bellRinger.standardId,
      });
      if (resp?.data === 0) {
        toast.success("Bell Ringer Updated Successfully");
      } else {
        toast.error("Something went wrong!");
      }
    }
    setSaveModal(false);
    setLoading(false);
    history.push(
      constant.TeacherRoutes.BellRinger + `?subjectId=${props.subjectId}`
    );
  };

  const getBellRingerData = async (id) => {
    setLoading(true);
    const resp = await getBellRinger(id, props?.userContext?.userId);
    setLoading(false);
    if (resp && resp.data) {
      setBellRinger({
        bellRingerId: resp.data.bellRingerId,
        name: resp.data.name,
        instructions: resp.data.instructions,
        questionTypeName: resp.data.questionTypeName,
        questionTypeId: resp.data.questionTypeId,
        domainName: resp.data.domainName,
        domainId: resp.data.domainId,
        standardName: resp.data.standardName,
        standardId: resp.data.standardId,
        standardCode: resp.data.standardCode,
        isFavorite: resp.data.isFavorite,
      });
      setSelectedQuestion(resp.data.questionReferenceId);
      setSelectedESQuestion(resp.data.spanishQuestionReferenceId);
      setSelectedQuestionId(resp.data.questionId);
      setRandomQuestions([
        {
          englishQuestionReferenceId: resp.data.questionReferenceId,
          questionId: resp.data.questionId,
        },
      ]);
    } else {
      toast.error("Something went wrong!");
    }
  };

  const removeBellRinger = async () => {
    let resp = await deleteBellRinger({
      bellRingerId: bellRinger.bellRingerId,
      userId: props.userContext.userId,
    });
    if (resp?.data === 0) {
      toast.success("Question Removed Successfully");
      history.push(
        constant.TeacherRoutes.BellRinger + `?subjectId=${props.subjectId}`
      );
    } else {
      toast.error("Something went wrong!");
    }

    getRandomQuestions(bellRinger?.standardId, bellRinger?.questionTypeId);
    setRemoveModal(false);
  };

  const addToFav = async (value: boolean) => {
    setLoading(true);
    setBellRinger({ ...bellRinger, isFavorite: value });
    try {
      const resp = await favQuestion({
        userId: props.userContext.userId,
        questionId: selectedQuestionId,
      });
      setLoading(false);

      if (resp?.data === 0) {
        if (value) {
          toast.success("Question added in favorites successfully");
        } else {
          toast.success("Question removed from favorites successfully");
        }
      } else {
        toast.error("Something went wrong");
      }
    } catch (e) {
      toast.error("Something went wrong");
      setLoading(false);
    }
  };

  const handleRedirect = () => {
    if (!idToEdit) {
      return (
        constant.TeacherRoutes.PreviewQuestion.replace(
          ":id",
          isSpanish ? selectedESQuestion : selectedQuestion
        ) + `?subjectId=${props.subjectId}`
      );
    } else {
      return (
        constant.TeacherRoutes.PreviewQuestion.replace(
          ":id",
          isSpanish ? selectedESQuestion : selectedQuestion
        ) + `?subjectId=${props.subjectId}&bellRingerId=${idToEdit}`
      );
    }
  };

  function toggleFeedback(popupAction: boolean) {
    setShowFeedbackPopup(popupAction);
  }
  const handleStatsModal = async () => {
    const resp = await getQuestionStats(questionId);
    setQuestionStats(resp.data);
    setStatsModal(true);
  };
  return (
    <AllowedTo
      perform={[Permissions.bell_ringer_create, Permissions.bell_ringer_modify]}
      no={() => <AccessDenied />}
    >
      {loading && <Loader />}
      <div className="flex flex-col">
        <div className="flex w-full flex-col lg:flex-row gap-5 mt-5">
          <div>
            <div>Optional instructions for your students</div>

            <div className="flex mt-5">
              <textarea
                defaultValue={bellRinger.instructions}
                onChange={(e) => onInstructionChange(e)}
                className="h-32 lg:h-96 w-full md:flex-1 md:w-auto lg:w-60 py-2.5 px-4 block text-sm border border-gray-400 border-opacity-50 focus:ring-primary-violet focus:border-primary-violet form-select leading-5 p-1 rounded-lg"
              />
            </div>
          </div>

          <div className="w-full min-h-full flex flex-col">
            <span>
              Please choose one question from the options below or{" "}
              <span
                className="cursor-pointer text-blue-500 underline hover:no-underline"
                onClick={() => {
                  props.startOver();
                }}
              >
                start over
              </span>
            </span>
            <div className="flex divide-x divide-gray-500 mt-3 gap-x-3">
              <span>
                <span className="text-primary-violet font-semibold">
                  Domain
                </span>{" "}
                {bellRinger?.domainName}
              </span>
              <span className="pl-3">
                <span className="text-primary-violet font-semibold">
                  Standard
                </span>{" "}
                {bellRinger?.standardCode}{" "}
                {bellRinger?.standardName || "Any Standard"}
              </span>
              <span className="pl-3">
                <span className="text-primary-violet font-semibold">
                  Question Type
                </span>{" "}
                {bellRinger?.questionTypeName}{" "}
              </span>
            </div>
            <div className="bg-gray-50 grid grid-cols-1 md:grid-cols-5 mt-4 sm:mt-2 flex-grow border border-light-violet rounded px-4 py-4 md:flex md:flex-col">
              <div className="flex flex-col 2xl:flex-row 2xl:gap-x-4">
                <div className="w-full">
                  <nav className="flex space-x-8" aria-label="Tabs">
                    {randomQuestions.map((que, i) => (
                      <a
                        href="#null"
                        key={i}
                        onClick={() => {
                          setSelectedQuestion(que.englishQuestionReferenceId);
                          setSelectedESQuestion(que.spanishQuestionReferenceId);
                          setSelectedQuestionId(que.questionId);
                        }}
                        className={classNames(
                          que.englishQuestionReferenceId === selectedQuestion
                            ? "border-primary-green text-primary-violet"
                            : "text-gray-500 border-gray-300 hover:text-gray-700 hover:border-gray-500",
                          "mb-4 px-1 text-center border-b-2 font-medium text-sm cursor-pointer"
                        )}
                      >
                        {`Option ${i + 1}`}
                      </a>
                    ))}
                  </nav>

                  {randomQuestions.map(
                    (que, i) =>
                      que.englishQuestionReferenceId === selectedQuestion &&
                      selectedQuestion.length > 0 &&
                      (randomQuestions.length > 0 || idToEdit) && (
                        <div className="grid bg-white p-5 rounded-md border border-primary-violet bell-ringer-preview-div">
                          <div>
                            <span className="font-semibold text-gray-700 text-base">
                              Question Id:{" "}
                              {parseInt(selectedQuestion?.split("-")[0] ?? "0")}
                            </span>
                            <Classification
                              questionId={parseInt(
                                selectedQuestion?.split("-")[0] ?? "0"
                              )}
                              standardId={bellRinger.standardId}
                              questionTypeId={
                                selectedQuestion?.indexOf("-teacher") !== -1
                                  ? 2
                                  : 1
                              }
                            ></Classification>
                          </div>
                          <div className="mt-5">
                            <PreviewPage
                              itemRef={
                                isSpanish
                                  ? selectedESQuestion
                                  : selectedQuestion
                              }
                              questionId={
                                isSpanish
                                  ? selectedESQuestion
                                  : selectedQuestion
                              }
                            />
                          </div>
                        </div>
                      )
                  )}

                  {randomQuestions.length === 0 && !idToEdit && (
                    <div className="grid place-items-center h-60">
                      No Records Available
                    </div>
                  )}
                </div>

                <div className="order-first 2xl:order-none">
                  <div className="flex flex-col md:flex-row md:flex-wrap mb-4 2xl:mt-10 2xl:flex-col 2xl:flex-nowrap xxxl:mb-0 xxxl:items-end 2xl:justify-end gap-4">
                    <div className="flex flex-col items-center cursor-pointer">
                      <div
                        className={`flex items-center ${
                          randomQuestions.length === 0
                            ? "cursor-not-allowed"
                            : ""
                        }`}
                      >
                        <Link
                          onClick={() => onDisplayQuestion()}
                          to={handleRedirect()}
                          className={`${
                            randomQuestions.length === 0
                              ? "pointer-events-none"
                              : ""
                          } w-44 bg-transparent border border-primary-violet shadow-sm py-2 px-2 inline-flex justify-center text-sm font-medium text-primary-violet hover:text-white hover:bg-light-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3 transition ease-in-out duration-500`}
                        >
                          <DisplayQuestion className="w-5 mr-1" />
                          Display Question
                        </Link>
                      </div>
                    </div>

                    <div className="flex flex-col items-center">
                      <div
                        className={`flex items-center ${
                          randomQuestions.length === 0
                            ? "cursor-not-allowed"
                            : ""
                        }`}
                      >
                        <a
                          href="#!"
                          onClick={() => setPrintModal(true)}
                          className={`${
                            randomQuestions.length === 0
                              ? "pointer-events-none"
                              : ""
                          } w-44 bg-transparent border border-primary-violet shadow-sm py-2 px-2 inline-flex justify-center text-sm font-medium text-primary-violet hover:text-white hover:bg-light-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3 transition ease-in-out duration-500`}
                        >
                          <PrintIcon className="w-5 mr-1" />
                          Print Question
                        </a>
                      </div>
                    </div>

                    <AllowedTo perform={Permissions.bell_ringer_create}>
                      {!idToEdit && (
                        <div className="flex flex-col items-center">
                          <div
                            className={`flex items-center ${
                              randomQuestions.length === 0
                                ? "cursor-not-allowed"
                                : ""
                            }`}
                          >
                            <a
                              href="#!"
                              onClick={() => setSaveModal(true)}
                              className={`${idToEdit ? "w-44" : "w-44"} ${
                                randomQuestions.length === 0
                                  ? "pointer-events-none"
                                  : ""
                              } bg-transparent border border-primary-violet shadow-sm py-2 px-4 inline-flex justify-start text-sm font-medium text-primary-violet hover:text-white hover:bg-light-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3 transition ease-in-out duration-500`}
                            >
                              <SaveQuestion className="w-5 mr-1" />
                              Save Question
                            </a>
                          </div>
                        </div>
                      )}
                    </AllowedTo>

                    <AllowedTo perform={Permissions.bell_ringer_modify}>
                      {idToEdit && (
                        <div className="flex flex-col items-center">
                          <div className="flex justify-end">
                            <a
                              href="#!"
                              onClick={() => setSaveModal(true)}
                              className={`${
                                idToEdit ? "w-44" : "w-44"
                              } bg-transparent border border-primary-violet shadow-sm py-2 px-4 inline-flex justify-start text-sm font-medium text-primary-violet hover:text-white hover:bg-light-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3 transition ease-in-out duration-500 whitespace-nowrap `}
                            >
                              <SaveQuestion className="w-5 mr-1" />
                              Update Question
                            </a>
                          </div>
                        </div>
                      )}
                    </AllowedTo>

                    <AllowedTo perform={Permissions.bell_ringer_delete}>
                      {idToEdit && (
                        <div className="flex flex-col items-center">
                          <div className="flex justify-end">
                            <a
                              href="#!"
                              onClick={() => setRemoveModal(true)}
                              className="w-44 bg-transparent border border-primary-violet shadow-sm py-2 px-1 inline-flex justify-center text-sm font-medium text-primary-violet hover:text-white hover:bg-light-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3 transition ease-in-out duration-500"
                            >
                              <DeleteIcon className="w-4 mr-1" />
                              Remove Question
                            </a>
                          </div>
                        </div>
                      )}
                    </AllowedTo>

                    <div className="flex gap-4 items-center">
                      Spanish Version
                      <Toggle
                        disabled={false}
                        checked={isSpanish}
                        onChange={() => {
                          setIsSpanish(!isSpanish);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex flex-row gap-4 mt-8 col-span-4">
                <div
                  className={`${
                    randomQuestions.length === 0 ? "cursor-not-allowed" : ""
                  } flex flex-col space-y-1 cursor-pointer group hover:text-gray-500`}
                  onClick={() => handleStatsModal()}
                >
                  <StatsIcon
                    title="Stats"
                    className={`${
                      randomQuestions.length === 0 ? "pointer-events-none" : ""
                    } text-primary-violet h-5 group-hover:opacity-80`}
                  />
                  <div
                    className={`${
                      randomQuestions.length === 0 ? "pointer-events-none" : ""
                    }  text-center`}
                  >
                    Stats
                  </div>
                </div>

                <div
                  onClick={() => setShowFeedbackPopup(true)}
                  className={`${
                    randomQuestions.length === 0 ? "cursor-not-allowed" : ""
                  }  flex flex-col space-y-1 cursor-pointer group hover:text-gray-500`}
                >
                  <FeedbackIcon
                    title="Question feedback"
                    className={`${
                      randomQuestions.length === 0 ? "pointer-events-none" : ""
                    }  text-primary-violet h-5 group-hover:opacity-80`}
                  />
                  <div
                    className={`${
                      randomQuestions.length === 0 ? "pointer-events-none" : ""
                    } text-center `}
                  >
                    Question Feedback
                  </div>
                </div>

                <div
                  className={`flex flex-col space-y-1 cursor-pointer group hover:text-gray-500`}
                >
                  {bellRinger.isFavorite ? (
                    <StarDarkIcon
                      title="Remove from favorites"
                      className="w-5 h-5 cursor-pointer mx-auto fill-current text-yellow-300"
                      onClick={() => addToFav(false)}
                    />
                  ) : (
                    <StarIcon
                      title="Add to favorites"
                      className="w-5 h-5 cursor-pointer mx-auto fill-current text-gray-500"
                      onClick={() => addToFav(true)}
                    />
                  )}
                  <div
                    className={`${
                      !bellRinger.isFavorite ? "pointer-events-none" : ""
                    } text-center `}
                  >
                    Add to Favorites
                  </div>
                </div>
              </div>

              <Modal
                isOpen={saveModal}
                onClose={() => setSaveModal(false)}
                className="w-full px-6 py-4 overflow-hidden bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:max-w-3xl"
              >
                <ModalBody>
                  <h1 className="text-xl font-medium text-primary-violet mb-2">
                    {idToEdit ? "Update" : "Save"} Bell Ringer
                  </h1>
                  <div className="py-1">
                    Enter a name for this Bell Ringer so that you may use it
                    again in the future. Examples: Unit 2 Warm-Up, October 4
                    Exit Ticket, Adverbs Day 3, Cold War (USH13.g), etc.
                    <div className="mt-6 flex items-center text-gray-700">
                      Name :{" "}
                      <input
                        defaultValue={bellRinger.name}
                        onChange={(e) => onNameChange(e)}
                        className="ml-2 py-2.5 px-4 block text-sm border border-gray-400 border-opacity-50 focus:ring-primary-violet focus:border-primary-violet form-select leading-5 p-1 rounded-lg text-gray-700"
                        type="text"
                      />
                    </div>
                  </div>
                </ModalBody>

                <ModalFooter>
                  <Button
                    className="w-full sm:w-auto"
                    layout="outline"
                    onClick={() => setSaveModal(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={() => saveQuestion()}
                    className="bg-primary-violet border border-transparent shadow-sm py-2 px-4 justify-end text-sm font-medium text-white hover:bg-dark-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 whitespace-nowrap ml-auto"
                  >
                    Save
                  </Button>
                </ModalFooter>
              </Modal>

              <Modal
                isOpen={removeModal}
                onClose={() => setRemoveModal(false)}
                className="w-full px-6 py-4 overflow-hidden bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:max-w-3xl"
              >
                <ModalBody className="mt-2 mb-2">
                  <h1 className="text-xl font-medium -mt-4 text-primary-violet">
                    Remove Saved Bell Ringer
                  </h1>
                  <div className="py-1">
                    You are about to remove this saved Bell Ringer from your
                    list. Would you like to continue?
                  </div>
                </ModalBody>

                <ModalFooter>
                  <Button
                    className="w-full sm:w-auto"
                    layout="outline"
                    onClick={() => setRemoveModal(false)}
                  >
                    Cancel
                  </Button>

                  <Button
                    onClick={() => removeBellRinger()}
                    className="bg-primary-violet border border-transparent shadow-sm py-2 px-4 justify-end text-sm font-medium text-white hover:bg-dark-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 whitespace-nowrap ml-auto"
                  >
                    Remove
                  </Button>
                </ModalFooter>
              </Modal>

              <Modal
                isOpen={statsModal}
                onClose={() => setStatsModal(false)}
                className="w-full px-6 py-4 overflow-hidden bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:max-w-md"
              >
                <ModalBody className="mt-2 mb-2">
                  <h1 className="text-xl font-medium mb-4">Stats</h1>
                  <div className="w-full">
                    <ul className="flex-1 flex flex-col gap-y-1">
                      <li className="flex border">
                        <span className="flex flex-none flex-1 items-center w-40 justify-end bg-gray-100 text-gray-700 font-medium px-4 py-2">
                          ID
                        </span>{" "}
                        <span className="flex items-center px-4 py-2 text-gray-500">
                          {questionId}
                        </span>
                      </li>
                      <li className="flex border">
                        <span className="flex flex-none flex-1 items-center w-40 justify-end bg-gray-100 font-medium px-4 py-2">
                          Total Answered
                        </span>{" "}
                        <span className="flex items-center px-4 py-2 text-gray-500">
                          {" "}
                          {questionStats.totalAnswered &&
                          questionStats.totalAnswered > 0
                            ? questionStats.totalAnswered
                            : "Not Available"}
                        </span>
                      </li>
                      <li className="flex border">
                        <span className="flex flex-none flex-1 items-center w-40 justify-end bg-gray-100 font-medium px-4 py-2">
                          Percent Correct
                        </span>{" "}
                        <span className="flex items-center px-4 py-2 text-gray-500">
                          {questionStats.percentCorrect &&
                          questionStats.percentCorrect > 0
                            ? questionStats.percentCorrect
                            : "Not Available"}
                        </span>
                      </li>
                      <li className="flex border">
                        <span className="flex flex-none flex-1 items-center w-40 justify-end bg-gray-100 font-medium px-4 py-2">
                          DOK Level
                        </span>{" "}
                        <span className="flex items-center px-4 py-2 text-gray-500">
                          {" "}
                          {questionStats.dokLevel}
                        </span>
                      </li>
                      <li className="flex border">
                        <span className="flex flex-none flex-1 items-center w-40 justify-end bg-gray-100 font-medium px-4 py-2">
                          Popularity
                        </span>{" "}
                        <span className="flex items-center px-4 py-2 text-gray-500">
                          {questionStats.popularity &&
                          questionStats.popularity > 0
                            ? questionStats.popularity
                            : "Not Available"}
                        </span>
                      </li>
                    </ul>
                  </div>
                </ModalBody>
              </Modal>
              {printModal && (
                <QuestionPrintDialog
                  onCancelClick={() => {
                    setPrintModal(false);
                  }}
                >
                  <PrintQuestion
                    {...props}
                    instructions={bellRinger.instructions}
                    questionId={
                      isSpanish ? selectedESQuestion : selectedQuestion
                    }
                  />
                </QuestionPrintDialog>
              )}
              {showFeedbackPopup && (
                <Feedback
                  toggleFeedback={toggleFeedback}
                  referenceType={Constant.FeedbackReferenceType.QUESTION}
                  referenceId={selectedQuestionId}
                  subjectId={props.subjectId}
                  subjectName={props.subjectName}
                  standardId={bellRinger?.standardId}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </AllowedTo>
  );
}

export default connect((state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
})(QuestionSet);

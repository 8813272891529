import APIConstant from "../../utils/constant/apiConstant";
import { AxiosResponse } from "axios";
import { AxiosHelper } from "../../environment/axiosConfig";
import Question from "../../model/teacher/question";
import QuestionTotalPoint from "../../model/teacher/questionTotalPoint";
import SearchQuestionRequest from "../../model/teacher/searchQuestionRequest";

export const addQuestion = (question: Question) => {
  return AxiosHelper.axiosInstance().post(
    APIConstant.APP_TEACHERQUESTION,
    question,
    {}
  );
};

export const saveQuestion = (question: Question) => {
  return AxiosHelper.axiosInstance().put(
    APIConstant.APP_TEACHERQUESTION_SAVE,
    question,
    {}
  );
};

export const getQuestion = (id) => {
  return AxiosHelper.axiosInstance().get(
    `${APIConstant.APP_TEACHERQUESTION}/${id}`,
    {}
  );
};
export const getQuestionStats = (id) => {
  return AxiosHelper.axiosInstance().get(
    `${APIConstant.APP_QUESTIONS_QUESTIONSTATS}`,
    { params: { questionId: id } }
  );
};

export const getAllQuestions = (questionData: SearchQuestionRequest) => {
  return AxiosHelper.axiosInstance().get(APIConstant.APP_TEACHERQUESTION, {
    params: {
      questionCategoryIds: questionData.questionCategoryIds,
      questionTypeIds: questionData.questionTypeIds,
      stateId: questionData.stateId,
      subjectId: questionData.subjectId,
      domainIds: questionData.domainIds,
      standardIds: questionData.standardIds,
      isPublished: questionData.isPublished,
      isActive: questionData.isActive,
      questionIds: questionData.teacherQuestionId,
      orderbyColumn: questionData.orderbyColumn,
      sortByDesc: questionData.sortByDesc,
      pageSize: questionData.pageSize,
      pageNumber: questionData.pageNumber,
      dok: questionData.dok,
      createdByUserId: questionData.createdByUserId,
      isAssessmentSearch: questionData.isAssessmentSearch
        ? questionData.isAssessmentSearch
        : false,
      includeConstructedResponseQuestionTypes: questionData.includeConstructedResponseQuestionTypes
        ? questionData.includeConstructedResponseQuestionTypes 
        : false
    },
  });
};
export const updateQuestion = (question: Question) => {
  return AxiosHelper.axiosInstance().put(
    APIConstant.APP_TEACHERQUESTION,
    question,
    {}
  );
};

export const deleteQuestion = (id) => {
  return AxiosHelper.axiosInstance().delete(
    `${APIConstant.APP_TEACHERQUESTION}/${id}`,
    {}
  );
};

export const saveTeacherPassageQuestion = (teacherpassagequestion: any) => {
  return AxiosHelper.axiosInstance().post(
    APIConstant.APP_TEACHERPASSAGEQUESTION,
    teacherpassagequestion,
    {}
  );
};

export const duplicateQuestion = (id, userId) => {
  return AxiosHelper.axiosInstance().post(
    APIConstant.APP_TEACHERQUESTION_DUPLICATE,
    {
      id: id,
      userId: userId,
    },
    {}
  );
};

export const getTeacherPassageQuestions = (id, userId) => {
  return AxiosHelper.axiosInstance().get(
    `${APIConstant.APP_TEACHERPASSAGEQUESTION}/${id}`,
    {}
  );
};

export const getQuestionRubric = (studentAssignmentActivityId) => {
  return AxiosHelper.axiosInstance().get(APIConstant.APP_QUESTION_RUBRIC, {
    params: { studentAssignmentActivityId: studentAssignmentActivityId },
  });
};

export const validateTeacherQuestion = (id) => {
  return AxiosHelper.axiosInstance().get(
    APIConstant.APP_TEACHERQUESTION_VALIDATE,
    {
      params: {
        id: id,
      },
    }
  );
};

export const getQuestionClassification = (
  id,
  questionTypeId,
  standardIds,
  subjectId,
  domainId,
  assessmentId?
) => {
  return AxiosHelper.axiosInstance().get(
    APIConstant.APP_QUESTIONS_QUESTIONCLASSIFICATION,
    {
      params: {
        questionId: id,
        questionTypeId: questionTypeId,
        standardIds: standardIds,
        subjectId: subjectId,
        domainId: domainId,
        assessmentId: assessmentId,
      },
    }
  );
};
export const questionTotalPoints = (
  itemReferences: string[]
): Promise<AxiosResponse<Array<QuestionTotalPoint>>> => {
  return AxiosHelper.axiosInstance().post<Array<QuestionTotalPoint>>(
    APIConstant.APP_QUESTIONS_QUESTIONTOTALPOINTS,
    {
      itemReferences: itemReferences ? itemReferences : [],
    }
  );
};

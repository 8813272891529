import { Fragment, useEffect, useState } from "react";
import { CloseIcon } from "../../../../../assets/icons/index";
import Constant from "../../../../../utils/constant/constant";

const theme = {
  ELEMENTARYHOME: {
    header: "bg-[#a9fb24]",
    headerTextColor: "text-lg font-medium text-gray-700",
    bodyText: "text-aqua-blue",
    okButton:
      "bg-[#a9fb24] shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-opacity-80 hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 rounded-lg",
  },
  KINDERGARTEN: {
    header: "bg-yellow-400",
    headerTextColor: "text-lg font-medium text-gray-700",
    bodyText: "text-yellow-500",
    okButton:
      "align-bottom inline-flex font-bold items-center justify-center cursor-pointer leading-5 transition-colors duration-150  focus:outline-none px-5 py-3 rounded-lg text-lg text-white bg-primary-violet hover:shadow-lg hover:bg-dark-violet",
  },
  MIDDLESCHOOLHOME: {
    header: "bg-site-teal",
    headerTextColor: "text-lg font-medium text-white",
    bodyText: "text-site-teal",
    okButton:
      "bg-primary-violet border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-dark-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 rounded-lg",
  },
};

interface VideoPopupProps {
  videoUrl: string;
  themeName: "ELEMENTARYHOME" | "KINDERGARTEN" | "MIDDLESCHOOLHOME";
  showPopup(show: boolean): void;
}

export default function VideoMessagePopup(props: VideoPopupProps) {
  const { videoUrl, themeName, showPopup } = props;
  const [showControls, setShowControls] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShowControls(true);
    }, 10000);
  }, []);

  return (
    <Fragment>
      <div className="fixed inset-0 z-50 flex items-center bg-black bg-opacity-50 sm:items-center sm:justify-center">
        <div
          className="w-full flex flex-col justify-between overflow-hidden h-auto max-h-80percent bg-white  dark:bg-gray-800 rounded-lg sm:m-4 sm:max-w-xl"
          role="dialog"
        >
          <header>
            <div className={`px-4 py-2 ${theme[themeName]?.header}`}>
              <div className="flex items-start justify-between space-x-3">
                <div className={` ${theme[themeName]?.headerTextColor}`}>
                  Video Message
                </div>
                <div>
                  {showControls && (
                    <button
                      type="button"
                      className="inline-flex items-center justify-center w-6 h-6 text-gray-400 transition-colors duration-150 rounded dark:hover:text-gray-200 hover: hover:text-gray-700"
                      onClick={() => showPopup(false)}
                    >
                      <CloseIcon />
                    </button>
                  )}
                </div>
              </div>
            </div>
          </header>

          <div onContextMenu={(e) => e.preventDefault()} className="flex items-start justify-center px-6 py-2 sm:flex-row bg-gray-50">
            <video
              id={"myVideo"}
              preload="none"
              className="video-js vjs-default-skin vjs-big-play-centered w-full h-80"
              controls
              autoPlay
              controlsList="nodownload"
            >
              <source src={videoUrl} type={Constant.MimeTypes.MP4}></source>
              <source src={videoUrl} type={Constant.MimeTypes.WEBM}></source>
            </video>
          </div>
          <footer className="flex flex-col items-center justify-end px-6 py-2 sm:flex-row bg-gray-50 dark:bg-gray-800">
            <div className="flex-shrink-0">
              <div className="space-x-3 flex justify-end">
                {showControls && (
                  <button
                    onClick={() => showPopup(false)}
                    className={`${theme[themeName]?.okButton}`}
                  >
                    Ok
                  </button>
                )}
              </div>
            </div>
          </footer>
        </div>
      </div>
    </Fragment>
  );
}

import { useEffect, useState, Fragment } from "react";
import { getStudentsReportForActivity } from "../../../api/student/studentActivityApi";
import Loader from "../../shared/loader";
import moment from "moment";
import Select from "react-select";
import { GradingStatus } from '../../../model/common/gradingStatus';
import { GetSchoolYears } from "../../../api/teacher/school";
import { UserContextState } from "../../../redux/actions/userContextAction";

interface ConstructedResponseReportProps {
  assessmentId: number | undefined;
  assignmentId: number;
  assignmentActivityId: number;
  classIds: Array<number>;
  gradeCRQuestion: any;
  setShowCRReport: any;
  setAverageScore: any;
  schoolIds: Array<number>;
  hasCRQuestions: boolean;
  schoolYearId?: number;
  userContext: UserContextState;
}

function ConstructedResponseReport(props: ConstructedResponseReportProps) {
  const [students, setStudents] = useState<any>();
  const [loading, showLoading] = useState<boolean>(false);
  const [assessmentId] = useState<number | null>(props.assessmentId ?? null);
  const [assignmentId] = useState<number>(props.assignmentId);
  const [assignmentActivityId] = useState<number>(props.assignmentActivityId);
  const [studentsCompleted, setStudentsCompleted] = useState<number>();
  const [studentsIncomplete, setStudentsIncomplete] = useState<number>(0);
  const [averageScore, setAverageScore] = useState<number>();
  const [maxPoint, setMaxPoint] = useState<number>();
  const [gradableStudents, setGradableStudents] = useState<any[]>();  
  const [attemptNumbers, setAttemptNumbers] = useState<{ label: string, value: number }[]>([{ label: '1st Attempt', value: 1 }]);
  const [selectedValue, setSelectedValue] = useState<{ label: string, value: number }>({ label: '1st Attempt', value: 1 });
  const [uniqueStudentIds, setUniqueStudentIds] = useState<number[]>([]);
  const [studentAssignedCount, setStudentAssignedCount] = useState<number>(0);
  const isAssessmentActivity = assessmentId !== null && assessmentId !== 0;
  const [defaultSchoolYearId, setDefaultSchoolYearId] = useState<number>(2);

  useEffect(() => {
    GetSchoolYears(props.userContext?.schoolId!).then((response) => {
      if (response.data) {
        var currentfutureschoolyears = response.data.filter(
          (y) => y.currentYearOffset == 0
        );
        const schoolYearId = currentfutureschoolyears[0].schoolYearId;
        setDefaultSchoolYearId(schoolYearId);
      }
    });
  }, []);
  
  useEffect(() => {
    getStudents();
  }, [props.classIds, props.schoolIds]);

  useEffect(() => {
    if (attemptNumbers.length > 0 && !selectedValue) {
      setSelectedValue(attemptNumbers[0]);      
    }

    if (isAssessmentActivity && selectedValue && studentAssignedCount) {
      const completedForThisAttempt = students?.filter(student => student.attemptNumber === selectedValue.value).length;
      setStudentsCompleted(completedForThisAttempt);
      setStudentsIncomplete(studentAssignedCount - completedForThisAttempt);
    }
  }, [attemptNumbers, selectedValue, studentAssignedCount]); 

  function getStudents() {
    showLoading(true);
    const params = {
      assignmentActivityId: assignmentActivityId,
      assessmentId:
        props.assessmentId === 0 || props.assessmentId === undefined
          ? null
          : props.assessmentId,
      assignmentId: assignmentId,
      classIds: props.classIds,
      schoolIds: props.schoolIds,
      schoolYearId: props.schoolYearId
    };
    getStudentsReportForActivity(
      params.assessmentId ?? null,
      params.assignmentActivityId,
      params.assignmentId,
      params.classIds,
      params.schoolIds,      
      true,
      params.schoolYearId,
    ).then((r) => {      
      showLoading(false);
      setStudents([...r.data.students]);
      setStudentsCompleted(r.data?.completed);
      setStudentsIncomplete(r.data?.notCompleted);
      setAverageScore(r.data?.averageScore);

      // If we have CR questions and this is an assessment activity, set the total assigned students
      if (props.hasCRQuestions && isAssessmentActivity && r.data?.assignedStudentsCount) {
        const completedForThisAttempt = r.data?.students?.filter(s => s.attemptNumber === selectedValue.value).length;
        setStudentsIncomplete(r.data.assignedStudentsCount - completedForThisAttempt);
        setStudentAssignedCount(r.data.assignedStudentsCount);
      }
      else {
        setStudentsIncomplete(r.data?.notCompleted); // If we just have CR use the normal count
        setStudentAssignedCount(r.data?.assignedStudentsCount);
      }

      const averageScorePercentage = Number(getPercentage(r.data?.totalPointsEarned, r.data?.totalPointsPossible));      
      props.setAverageScore(averageScorePercentage);      
      setMaxPoint(r.data?.maximumPoint ?? 100);
      var studentsToBeGraded = r.data.students?.filter(
        (x) => x.status === GradingStatus.Grade || 
               x.status === GradingStatus.ReGrade ||
               x.status === GradingStatus.ContinueGrading
      );      
      setGradableStudents(studentsToBeGraded);


      if (r.data.students) {
        // Explicitly type the Set
        const idsSet = new Set<number>(r.data.students.map(student => student.userId));      
        const idsArray = Array.from(idsSet);
        setUniqueStudentIds(idsArray);
      }

      const maxAttemptNumber = Math.max(...r.data.students.map(student => student.attemptNumber));
      const attemptNumbersList = Array.from({ length: maxAttemptNumber }, (_, i) => {
        const attemptNumber = i + 1;
        return {
          value: attemptNumber,
          label: `${getOrdinalNumber(attemptNumber)} Attempt`
        };
      });
      setAttemptNumbers(attemptNumbersList);
    });
  }

  function RedirectToGradeCR(
    sessionId: string,
    userId: string,
    activityId: number,
    studentStatus: string,
    onlyView: boolean = false,
    studentAssessmentActivityId: number
  ) {
    props.gradeCRQuestion(
      sessionId,
      userId,
      activityId,
      maxPoint,
      gradableStudents,
      studentStatus,
      selectedValue?.value,
      onlyView,
      studentAssessmentActivityId
    );
    props.setShowCRReport(false);
  }

  function getOrdinalNumber(n) {
    const s = ["th", "st", "nd", "rd"],
          v = n % 100;
    return n + (s[(v - 20) % 10] || s[v] || s[0]);
  }

  function getStudentDataForAttempt(userId, selectedAttempt, students) {
    if (!students || students.length === 0) {
      // Handle the case where students data is not yet available
      return null;
    }

    const studentForSelectedAttempt = students.find(student => 
      student.userId === userId && student.attemptNumber === selectedAttempt
    );
  
    if (studentForSelectedAttempt) {
      return studentForSelectedAttempt;
    }
    
    // If there is not data for the current attempt, we get the student info and fill the missing data
    const firstInstance = students.find(student => student.userId === userId);
    return firstInstance
      ? { ...firstInstance, questionsAnswered: 0, crQuestionsGraded: 0, status: 'Not Completed' }
      : null;
  }

  const customSelectStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? 'white' : 'black',
      backgroundColor: state.isSelected ? 'rgba(153, 40, 131, 1)' : state.isFocused ? 'rgba(153, 40, 131, 0.5)' : 'white',
      ':active': {
        ...provided[':active'],
        backgroundColor: !state.isDisabled && (state.isSelected ? 'rgba(153, 40, 131, 1)' : 'rgba(153, 40, 131, 0.5)'),
        color: state.isSelected ? 'white' : 'black',
      },
      padding: 20,
    }),
    control: (provided) => ({
      ...provided,
      borderColor: 'rgba(153, 40, 131, 1)',
      boxShadow: 'none',
      '&:hover': {
        borderColor: 'rgba(153, 40, 131, 1)'
      },
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: state.isDisabled ? '#ccc' : 'black',
    }),
    menu: (provided) => ({
      ...provided,
      width: 'auto', // This will allow the dropdown to size according to its content
      minWidth: '100%', // This ensures that the dropdown is at least as wide as the control
    })
  };

  const getPercentage = (pointsEarned, pointsPossible) => {
    if (!pointsPossible) return 0;
    return ((pointsEarned / pointsPossible) * 100).toFixed();
  };

  

  return (
    <div className="min-w-0 block lg:col-span-9 xl:col-span-10 w-full">
      <div className="w-full mb-5 rounded-lg pt-5 h-full">
        {loading && <Loader />}
        <div className="w-full rounded-lg">
          <h2 className="text-white bg-primary-violet p-3">
            <div className="flex justify-between">
              <div className="flex items-center">
                <span className="font-semibold bg-yellow-400 py-0.5 px-1 inline-block flex items-center text-gray-800 justify-center rounded-sm mr-1.5">
                  {studentsCompleted}
                </span>{" "}
                COMPLETED / {studentsIncomplete} NOT COMPLETED
              </div>
              <div className={`flex items-center ${
                  !(props.hasCRQuestions && isAssessmentActivity) ? "hidden" : ""
                }`}>
                  View Attempt
                  <Select
                    className="mx-auto w-40 ml-2"
                    styles={customSelectStyles}        
                    options={attemptNumbers}
                    getOptionLabel={(option) => option.label}
                    getOptionValue={(option) => option.value.toString()}
                    value={selectedValue}
                    onChange={option => setSelectedValue(option as { label: string, value: number })}
                  />
              </div>
              <div
                className={`flex items-center ${
                  isAssessmentActivity ? "hidden" : ""
                }`}
              >
                <span className="font-semibold bg-yellow-400 py-0.5 px-1 inline-block flex items-center text-gray-800 justify-center rounded-sm mr-1.5">
                  {averageScore}%
                </span>{" "}
                AVERAGE SCORE
              </div>
            </div>
          </h2>
        </div>
        <div className="py-5 relative">
          <div className="mb-4 pb-4 border-b-2 overflow-x-auto xl:overflow-x-visible">
            <table className="table w-full ">
              <thead className="mb-5">
                <tr className="bg-secondary-teal  text-white py-3 px-3">
                  <th className="text-center  p-3 text-sm font-normal">
                    {" "}
                    Date
                  </th>
                  <th className="text-center  p-3 text-sm font-normal">Name</th>
                  <th
                    className={`text-center  p-3 text-sm font-normal ${
                      isAssessmentActivity ? "hidden" : ""
                    }`}
                  >
                    Score
                  </th>
                  <th
                    className={`text-center  p-3 text-sm font-normal ${
                      isAssessmentActivity ? "hidden" : ""
                    }`}
                  >
                    Elapsed Time
                  </th>
                  <th
                    className={`text-center  p-3 text-sm font-normal ${
                      isAssessmentActivity ? "hidden" : ""
                    }`}
                  >
                    Attempts
                  </th>
                  <th 
                    className={`text-center  p-3 text-sm font-normal ${
                      !(props.hasCRQuestions && isAssessmentActivity) ? "hidden" : ""
                    }`}>
                    # of Questions Answered
                  </th>
                  <th 
                    className={`text-center  p-3 text-sm font-normal ${
                      !(props.hasCRQuestions && isAssessmentActivity) ? "hidden" : ""
                    }`}>
                    # of Questions Graded
                  </th>
                  <th className="text-center  p-3 text-sm font-normal"></th>
                </tr>
              </thead>
              <tbody>
                {students && students.length > 0 ? (
                  uniqueStudentIds.map((userId, index) => {                    
                    const student = getStudentDataForAttempt(userId, selectedValue?.value, students);                    
                    if(student) {
                      return (
                        <Fragment key={index}>
                          <tr
                            className={`hover:bg-secondary-teal/20 transition ease-in-out duration-300 ${
                              index % 2 === 0
                                ? "bg-white"
                                : "bg-secondary-teal/10"
                            }`}
                          >
                            <td className=" mt-5 p-3 text-center text-gray-500">
                              {student.dateCompleted
                                ? moment(student.dateCompleted).format(
                                    "MM/DD/YYYY"
                                  )
                                : "N/A"}
                            </td>
                            <td className=" mt-5 p-3 text-center text-gray-500">
                              {student.userName}
                            </td>
                            <td 
                              className={` mt-5 p-3 text-center text-gray-500 ${
                                !(props.hasCRQuestions && isAssessmentActivity) ? "hidden" : ""
                              }`}>
                              {student.questionsAnswered}
                            </td>
                            <td 
                              className={` mt-5 p-3 text-center text-gray-500 ${
                                !(props.hasCRQuestions && isAssessmentActivity) ? "hidden" : ""
                              }`}>
                              {student.crQuestionsGraded}
                            </td>
                            <td
                              className={` mt-5 p-3 text-center text-gray-500 ${
                                isAssessmentActivity ? "hidden" : ""
                              }`}
                            >
                              {student.status === "Re-Grade"
                                ? student.score
                                : student.score
                                ? student.score
                                : "N/A"}
                            </td>
                            <td
                              className={` mt-5 p-3 text-center text-gray-500 ${
                                isAssessmentActivity ? "hidden" : ""
                              }`}
                            >
                              {student.elapsedTime
                                ? moment()
                                    .startOf("day")
                                    .add(student.elapsedTime, "seconds")
                                    .format("HH:mm:ss")
                                : "N/A"}
                            </td>
                            <td
                              className={` mt-5 p-3 text-center text-gray-500 ${
                                isAssessmentActivity ? "hidden" : ""
                              }`}
                            >
                              {student.attempts ? student.attempts : "N/A"}
                            </td>
                            <td className=" mt-5 p-3 text-center text-gray-500">
                              {student.status &&
                              (student.status === GradingStatus.Grade ||
                                student.status === GradingStatus.ReGrade ||
                                student.status === GradingStatus.ContinueGrading) ? (
                                <div className="space-x-2">
                                  <button
                                    type="button"
                                    disabled={props.schoolYearId !== defaultSchoolYearId}
                                    className={"bg-secondary-teal border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-dark-teal hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3 "+
                                      ( props.schoolYearId !== defaultSchoolYearId                  
                                          ? 'bg-opacity-20 text-opacity-40 pointer-events-none'
                                          : '')
                                    }
                                    onClick={(e) =>
                                      RedirectToGradeCR(
                                        student.learnositySessionId,
                                        student.userId,
                                        student.studentAssignmentActivityId,
                                        student.status,
                                        false,
                                        student.studentAssessmentActivityId
                                      )
                                    }
                                  >
                                    {student.status}
                                  </button>
  
                                  {student.status === GradingStatus.ReGrade ? (
                                    <button
                                      type="button"
                                      className="bg-secondary-teal border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-dark-teal hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3 "
                                      onClick={(e) =>
                                        RedirectToGradeCR(
                                          student.learnositySessionId,
                                          student.userId,
                                          student.studentAssignmentActivityId,
                                          student.status,
                                          true,
                                          student.studentAssessmentActivityId
                                        )
                                      }
                                    >
                                      View
                                    </button>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              ) : (
                                student.status
                              )}
                            </td>
                          </tr>
                        </Fragment>
                      );
                    } else {
                      <tr>
                        <td colSpan={100} className="text-center p-3">No records found</td>
                      </tr>
                    }
                  })
                ) : (
                  <tr>
                    <td colSpan={100} className="text-center p-3">No records found</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConstructedResponseReport;

import { Fragment, useEffect, useState } from "react";
import useSound from "use-sound";
import { connect } from "react-redux";
import { ISkillStandard } from "../../../../../model/skillPractice/skillStandard";
import { getSkillStandard } from "../../../../../api/student/skillPracticeApi";
import { useTranslation } from "react-i18next";
import { UserContextState } from "../../../../../redux/actions/userContextAction";
import useTextToSpeech from "../../shared/textToSpeech/useTextToSpeech";
import Profile from "../../../../../model/users/profile";
import constant from "../../../../../utils/constant/constant";

interface SkillStandardProps {
  contentAreaId: number;
  userContext?: UserContextState;
  onStandardSelected: (skillStandardId: number) => void;
  profile?: Profile;
}

function SkillStandard(props: SkillStandardProps) {
  const { t } = useTranslation();
  const [play, { stop }] = useSound("");
  const { startSpeaking, stopSpeaking } = useTextToSpeech();
  var topicNumber = 1;

  const [skillStandards, setSkillStandards] = useState<Array<ISkillStandard>>(
    []
  );

  useEffect(() => {
    if (props.contentAreaId !== 0) {
      getSkillStandard(0, props.contentAreaId, props.userContext?.gradeId ?? 0)
        .then((d) => {
          setSkillStandards(d.data.data);
        })
        .catch(() => {});
    }
  }, [props.contentAreaId]);

  return (
    <Fragment>
      <div className="bg-[#403d6a] rounded-t-lg mt-6 md:mt-0">
        <h3 className="py-4 px-7 bg-[#4e4b75] rounded-t-lg tracking-wider font-Montserrat relative text-lg  lg:text-xl xl:text-2xl text-[#f5d500] text-white hover:shadow-2xl h-full flex items-center justify-center">
          {t("StudyType.SkillPractice")}
        </h3>
        <div className="p-4">
          <Fragment>
            <div
              className="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-12 text-center flex flex-col mb-6"
              onMouseEnter={() => play()}
              onMouseLeave={() => stop()}
            >
              {skillStandards.map((standard, index) => {
                return (
                  <div
                    key={index}
                    className="bg-white rounded-lg hover:bg-[#93b7fa] cursor-pointer border-8 border-[#3C7BF0]"
                    onClick={() => {
                      props.onStandardSelected(standard.skillStandardId);
                    }}
                    onMouseEnter={() => {
                      startSpeaking(
                        props.profile?.plLanguageId ===
                          constant.Languages.Spanish
                          ? standard.spanishName ?? standard.name
                          : standard.name,
                        (props.profile?.plLanguageId ?? 1) === 2 ? 2 : 1
                      );
                    }}
                    onMouseLeave={() => {
                      stopSpeaking();
                    }}
                  >
                    <div className="flex items-center h-24 p-4">
                      <label className="flex-shrink-0 text-white font-semibold text-2xl cursor-pointer h-14 w-14 mr-4 bg-[#3C7BF0] rounded-full flex inline-block items-center justify-center">
                        {topicNumber++}
                      </label>
                      <p className="font-Montserrat text-lg tracking-wide lg:text-xl xl:text-2xl  text-left items-center">
                        {props.profile?.plLanguageId ===
                        constant.Languages.Spanish
                          ? standard.spanishName ?? standard.name
                          : standard.name}
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          </Fragment>
        </div>
      </div>
    </Fragment>
  );
}

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(SkillStandard);

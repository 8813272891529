import { Fragment, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Select } from "@windmill/react-ui";
import {
  DEFAULT_PAGE_NUMBER,
  DEFAULT_PAGE_SIZE_10,
  DEFAULT_PAGE_SIZE_MAX_99999,
} from "../../../../../utils/pagingConstant";
import Pagination from "../../../../shared/pagination/pagination";
import PageSize from "../../../../shared/pagination/pageSize";

import "../../../../../css/style.css";
import RouteConstant from "../../../../../utils/constant/routeConstant";
import Breadcrumb from "../../shared/breadcrumb";
import { IBreadcrumb } from "../../../../../model/common/breadcrumb";
import { useTranslation } from "react-i18next";
import { getAllGrades } from "../../../../../api/gradeApi";
import constant from "../../../../../utils/constant/constant";
import { ISchool } from "../../../../../model/interface/school";
import { getSchoolByDistrictId } from "../../../../../api/teacher/school";
import { ISelectElement } from "../../../../../model/interface/selectElement";
import Asterisk from "../../../../shared/asterisk";
import SelectGroup from "react-select";
import ClassDropDown from "../../shared/classDropDown";
import { getAllClassesBySubject } from "../../../../../api/teacher/teacher";
import {
  downloadCertificate,
  getAllAlienRankingCards,
} from "../../../../../api/student/studentApi";
import ReactTooltip from "react-tooltip";
import Loader from "../../../../shared/loader";
import { getLiftoffSubjects } from "../../../../../api/student/liftOff/diagnosticApi";
import {
  exportLiftoffGalaxyStarReportExcel,
  getLiftoffGalaxyStarReport,
  exportLiftoffGalaxyStarReportCertificates,
} from "../../../../../api/report/liftoffGalaxystarReport";
import ReactToPrint from "react-to-print";
import { PrintIcon } from "../../../../../assets/icons";
import { AxiosResponse } from "axios";
import LiftoffGalaxyStarReportData from "../liftoffGalaxyStarReport/liftoffGalaxyStarReportData";
import { hasLiftOffAccess } from "../../../../../utils/helper";

interface IClasses {
  classId: number;
  displayName: string;
}

function LiftoffGalaxyStarReport(props) {
  const DefaultFilters = {
    school: "",
    subject: "",
    class: "All Classes",
    grade: "All Grades",
    alienRanking: "All Alien Rankings",
  };
  const { t } = useTranslation();
  const componentRef = useRef(null);
  const [schools, setSchools] = useState<ISchool[]>();
  const [pageNumber, setPageNumber] = useState(DEFAULT_PAGE_NUMBER);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE_10);
  const [resetPageNumber, setResetPageNumber] = useState<boolean>(false);
  const [selectedClass, setSelectedClass] = useState<number>(-1);
  const [selectedSubject, setSelectedSubject] = useState<number>(0);
  const [selectedGrade, setSelectedGrade] = useState<number>(0);
  const [selectedSchool, setSelectedSchool] = useState<number>(0);
  const [selectedAlienRank, setSelectedAlienRank] = useState<number>(0);
  const [noRecordFoundMsg, setNoRecordFoundMsg] = useState<string>("");
  const [showReport, setShowReport] = useState<boolean>(false);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [reportData, setReportData] = useState<any>([]);
  const [pdfReportData, setPdfReportData] = useState<any>([]);
  const [classes, setClasses] = useState<IClasses[]>();
  const [subjects, setSubjects] = useState<Array<any>>();
  const [grades, setGrades] = useState<Array<any>>();
  const [alienRankings, setAlienRankings] = useState<Array<any>>();
  const [selectedClasses, setSelectedClasses] = useState<Array<ISelectElement>>(
    [{ label: "Select Class", value: "0" }]
  );
  const [showLoader, setLoader] = useState<boolean>(false);
  const [filters, setFilters] = useState(DefaultFilters);
  const [selectedSchoolAccountId, setSelectedSchoolAccountId] =
    useState<number>(0);

  const getSchools = async (subjectId = 0) => {
    const resp = await getSchoolByDistrictId(
      props.userContext?.districtId ?? 0,
      subjectId
    );
    if (resp?.data?.length) {
      let schoolList: ISchool[] = [];
      resp?.data.forEach((school) => {
        if (!schoolList.find((sl) => sl.schoolId === school.schoolId)) {
          schoolList.push(school);
        }
      });
      setSchools(schoolList);
      if (props.userContext.roleId !== constant.UserRoleId.District) {
        setSelectedSchool(props.userContext.schoolId);
        // setDefaultStartDate(schoolList, props.userContext.schoolId);
      }
    }
  };
  const [selectedSubjectName, setSelectedSubjectName] = useState<string>();
  const [subjectName, setSubjectName] = useState<string>();

  function getSubjects(schId: number = 0) {
    var schoolId =
      schId > 0
        ? schId
        : selectedSchool === 0
        ? props.userContext.schoolId
        : selectedSchool;
    getLiftoffSubjects(schoolId).then((response) => {
      const subjects: Array<ISelectElement> = [];

      response.data.forEach((values: any) => {
        subjects.push({
          label: values?.name,
          value: values?.id,
        });
      });

      setSubjects(subjects);
    });
  }

  const getSubjectClasses = async (
    subjectId,
    classId?: number,
    schoolId?: number
  ) => {
    if (subjectId) {
      setSelectedSubject(parseInt(subjectId));
    } else {
      setSelectedSubject(0);
    }

    //For all users, get the user classes
    subjectId = 0;
    setClasses([]);
    setSelectedClasses([{ label: "Select Class", value: "-1" }]);
    const resp = await getAllClassesBySubject(
      props.userContext.userId,
      props.userContext.roleId,
      subjectId,
      props.profile.currentSchoolYear.schoolYearId,
      schoolId && schoolId > 0 ? schoolId : props.userContext.schoolId
    );
    if (resp?.data?.data?.length) setClasses(resp.data.data);
    if (classId) {
      setSelectedClass(classId);
      let selectedClass = resp.data.data.filter(
        (x) => x.classId === Number(classId)
      );
      if (selectedClass.length > 0)
        setSelectedClasses([
          {
            label: selectedClass[0].displayName,
            value: selectedClass[0].classId,
          },
        ]);
    } else {
      setSelectedClass(-1);
    }
  };

  const getGrades = async () => {
    const resp = await getAllGrades();
    if (resp?.data?.length) {
      let grades = resp.data.filter((x) => x.gradeId > 2 && x.gradeId <= 13);
      setGrades(grades);
    }
  };

  const getAlienRankingCards = async () => {
    const resp = await getAllAlienRankingCards();
    if (resp?.data?.length) setAlienRankings(resp.data);
  };

  const downloadStudentCertificate = (
    firstName,
    lastName,
    level,
    isPrestigeMode
  ) => {
    downloadCertificate(firstName, lastName, level, isPrestigeMode).then(
      (response) => {
        const file: any = response.data;
        const fileURL = URL.createObjectURL(file);
        const link = document.createElement("a");
        link.href = fileURL;
        link.setAttribute("download", "certificate.pdf");
        document.body.appendChild(link);
        link.click();

        // Clean up and remove the link
        link.parentNode!.removeChild(link);
      }
    );
  };

  useEffect(() => {
    hasLiftOffAccess(props.userContext).then((res) => {
      if (!res) {
        window.location.replace(RouteConstant.FORBIDDEN);
      }
    });
    getSubjects();
    getSubjectClasses(0, undefined, 0);
    getAlienRankingCards();
    getGrades();
    getSchools();
  }, []);

  useEffect(() => {
    if (showReport) getReport();
  }, [pageSize, pageNumber, resetPageNumber]);

  const getFilters = () => {
    const filter =
      "Filters : " +
      (filters.school ? filters.school + ", " : " ") +
      filters.class +
      ", " +
      filters.grade +
      ", " +
      filters.subject;

    return filter;
  };

  const getReport = () => {
    setLoader(true);
    setSubjectName(selectedSubjectName);
    getLiftoffGalaxyStarReport(
      Number(selectedSchool),
      Number(selectedSubject),
      pageNumber,
      pageSize,
      Number(selectedAlienRank) === 0 ? undefined : Number(selectedAlienRank),
      Number(selectedClass) <= 0 ? undefined : Number(selectedClass),
      Number(selectedGrade) === 0 ? undefined : Number(selectedGrade),
      props.userContext.roleId
    )
      .then((res) => {
        if (!res.data.data.data || res.data.data.totalRecords === 0) {
          setNoRecordFoundMsg("No record found");
          setShowReport(false);
        } else {
          setReportData(res.data.data.data);
          setShowReport(true);
          setTotalRecords(res.data.data.totalRecords);
        }
        setLoader(false);
      })
      .catch(() => {
        setLoader(false);
      });
  };

  const exportReportExcel = () => {
    setLoader(true);
    const filter = getFilters();
    setSubjectName(selectedSubjectName);
    exportLiftoffGalaxyStarReportExcel(
      Number(selectedSchool),
      Number(selectedSubject),
      DEFAULT_PAGE_NUMBER,
      DEFAULT_PAGE_SIZE_MAX_99999,
      Number(selectedAlienRank) === 0 ? undefined : Number(selectedAlienRank),
      subjectName ?? "",
      filter,
      Number(selectedClass) <= 0 ? undefined : Number(selectedClass),
      Number(selectedGrade) === 0 ? undefined : Number(selectedGrade),
      props.userContext.roleId
    )
      .then((r) => {
        if (r.data) {
          startFileDownload(r, "logalaxystarreport.xlsx");
        }
      })
      .catch(() => {
        setLoader(false);
      });
  };

  const exportReportCertificates = () => {
    setLoader(true);
    setSubjectName(selectedSubjectName);
    exportLiftoffGalaxyStarReportCertificates(
      Number(selectedSchool),
      Number(selectedSubject),
      DEFAULT_PAGE_NUMBER,
      DEFAULT_PAGE_SIZE_MAX_99999,
      Number(selectedAlienRank) === 0 ? undefined : Number(selectedAlienRank),
      Number(selectedClass) <= 0 ? undefined : Number(selectedClass),
      Number(selectedGrade) === 0 ? undefined : Number(selectedGrade)
    )
      .then((r) => {
        if (r.data) {
          startFileDownload(r, "certificates.pdf");
        }
      })
      .catch(() => {
        setLoader(false);
      });
  };

  async function getReportPdf() {
    setLoader(true);
    setSubjectName(selectedSubjectName);
    const response = await getLiftoffGalaxyStarReport(
      Number(selectedSchool),
      Number(selectedSubject),
      DEFAULT_PAGE_NUMBER,
      DEFAULT_PAGE_SIZE_MAX_99999,
      Number(selectedAlienRank) === 0 ? undefined : Number(selectedAlienRank),
      Number(selectedClass) <= 0 ? undefined : Number(selectedClass),
      Number(selectedGrade) === 0 ? undefined : Number(selectedGrade),
      props.userContext.roleId
    );
    if (response.data.data.data && response.data.data.totalRecords > 0) {
      setPdfReportData(response.data.data.data);
    } else {
      setPdfReportData([]);
    }
    setLoader(false);
    return await response.data.data.data;
  }

  const startFileDownload = (
    response: AxiosResponse<any>,
    fileName: string
  ) => {
    const file = response.data;
    const fileURL = URL.createObjectURL(file);
    let link = document.createElement("a");
    link.href = fileURL;
    link.download = fileName;
    link.setAttribute("target", "_blank");
    document.body.appendChild(link);
    link.click();
    setLoader(false);
  };

  const onRunReport = () => {
    setPageNumber(DEFAULT_PAGE_NUMBER);
    setNoRecordFoundMsg("");
    setShowReport(false);
    setReportData([]);
    setTotalRecords(0);
    getReport();
  };

  const onReset = () => {
    setReportData([]);
    setNoRecordFoundMsg("");
    setShowReport(false);
    setSelectedSubject(0);
    setSelectedGrade(0);
    setSelectedClass(0);
    if (props.userContext.roleId === constant.UserRoleId.District) {
      setSelectedSchool(0);
    }
    setSelectedAlienRank(0);
    setSelectedClasses([{ label: "Select Class", value: "-1" }]);
    getSubjectClasses(0, undefined, selectedSchool);
    setFilters(DefaultFilters);
  };

  const breadcrumbItems = () => {
    const items: Array<IBreadcrumb> = [
      { name: t("Breadcrumb.Home"), url: RouteConstant.ROUTE_DASHBOARD },
      {
        name: t("Breadcrumb.Reports"),
        url: RouteConstant.REPORTS,
      },
      { name: t("Breadcrumb.LiftoffGalaxyStarReport"), url: "" },
    ];
    return items;
  };

  const onPageChange = (pageNo) => {
    setPageNumber(pageNo);
  };

  const onPageSizeChange = (pageSize) => {
    setPageNumber(DEFAULT_PAGE_NUMBER);
    setPageSize(pageSize);
    setResetPageNumber(true);
  };

  const findSubjectByValue: any = () => {
    if (!selectedSubject || selectedSubject === 0) return null;
    return subjects?.find((option) => option.value === selectedSubject);
  };

  const handleClassChange: any = (e: any) => {
    setSelectedClasses(e);
    handleClassFilterChange(e);
    setSelectedClass(Number(e[e.length - 1].value));
  };

  const handleSchoolFilterChange: any = (e: any) => {
    var value = Number(e.target.value);
    if (value > 0) {
      var filteredSchool = schools?.filter((s) => s.schoolId === value);
      var school =
        filteredSchool && filteredSchool?.length > 0
          ? filteredSchool[0].name
          : "";
      setFilters({ ...filters, school: school });
      setSelectedSchoolAccountId(
        schools?.filter((s) => s.schoolId === value)[0]?.schoolAccountId ?? 0
      );
    }
  };

  const handleClassFilterChange: any = (e: any) => {
    if (e.length === 1) {
      setFilters({ ...filters, class: e[0].label });
    }
  };

  const handleGradeFilterChange: any = (e: any) => {
    var value = Number(e.target.value);
    if (value > 0) {
      var filteredGrade = grades?.filter((c) => c.gradeId === value);
      var grade =
        filteredGrade && filteredGrade?.length > 0 ? filteredGrade[0].name : "";
      setFilters({ ...filters, grade: "Grade " + grade });
    } else if (value === 0) {
      setFilters({ ...filters, grade: "All Grades" });
    }
  };

  const handleAlienRankingFilterChange: any = (e: any) => {
    var value = Number(e.target.value);
    if (value > 0) {
      var filteredRankings = alienRankings?.filter(
        (c) => c.alienRankingCardId === value
      );
      var data =
        filteredRankings && filteredRankings?.length > 0
          ? filteredRankings[0]
          : "";
      setFilters({
        ...filters,
        alienRanking: data.name + (data.isPrestigeMode ? " (Prestige)" : ""),
      });
    } else if (value === 0) {
      setFilters({ ...filters, alienRanking: "All Alien Rankings" });
    }
  };

  function validateRequiredFields() {
    if (props.userContext.roleId === constant.UserRoleId.District) {
      return (
        selectedSubject === 0 || selectedSchool === 0 || selectedGrade === -1
      );
    } else if (props.userContext.roleId === constant.UserRoleId.SchoolTeacher) {
      return (
        selectedSubject === 0 || selectedSchool === 0 || selectedClass === -1
      );
    } else if (props.userContext.roleId === constant.UserRoleId.SchoolAdmin) {
      return selectedSubject === 0 || selectedSchool === 0;
    }
    return true;
  }

  return (
    <>
      <div className="min-w-0 w-full">
        <div className="lg:flex lg:items-center px-4 py-3 lg:px-8 border-b">
          <Breadcrumb items={breadcrumbItems()} />
          <div className="lg:w-[55%] text-lg font-medium flex gap-2 h-auto sm:h-10 items-center">
            <h1 className="text-primary-violet font-medium flex justify-start mb-0 pb-0">
              Liftoff Galaxy Star Report
            </h1>
          </div>
        </div>
        <div className="px-2 sm:px-6  lg:px-8 py-1 relative my-4">
          <div className=" bg-gray-100 w-full rounded-lg p-5">
            <div>
              <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-4 mb-4 mt-4">
                {props.userContext.roleId === constant.UserRoleId.District && (
                  <div className="flex flex-col">
                    <label className="block mb-1 text-sm text-gray-500">
                      School <Asterisk />
                    </label>

                    <Select
                      value={selectedSchool}
                      onChange={(e) => {
                        getSubjectClasses(
                          selectedSubject,
                          undefined,
                          Number(e.target.value)
                        );
                        setSelectedSchool(parseInt(e.target.value));
                        getSubjects(parseInt(e.target.value));
                        handleSchoolFilterChange(e);
                      }}
                      className={
                        "py-2.5 px-4 block text-sm border border-gray-400 border-opacity-50 focus:ring-primary-violet focus:border-primary-violet form-select leading-5 p-1 rounded-lg "
                      }
                    >
                      <option value={0}>Select School</option>
                      {schools?.map((school: ISchool, index) => {
                        return (
                          <Fragment key={index}>
                            <option value={school.schoolId}>
                              {school.name}
                            </option>
                          </Fragment>
                        );
                      })}
                    </Select>
                  </div>
                )}

                <div className="flex flex-col">
                  <label className="block mb-1 text-sm text-gray-500">
                    Subject <Asterisk />
                  </label>
                  <SelectGroup
                    placeholder={"Select Subject"}
                    value={findSubjectByValue()}
                    options={subjects}
                    isSearchable={true}
                    onChange={(item) => {
                      setSelectedSubjectName(item.label);
                      setSelectedSubject(item.value);
                      setFilters({ ...filters, subject: item.label });
                    }}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: "#008fbb",
                      },
                    })}
                  />
                </div>

                <div className="flex flex-col">
                  <label className="block mb-1 text-sm text-gray-500">
                    Class{" "}
                    {props.userContext.roleId !==
                      constant.UserRoleId.District &&
                      props.userContext.roleId !==
                        constant.UserRoleId.SchoolAdmin && <Asterisk />}
                  </label>
                  <ClassDropDown
                    schoolYearId={props.profile.currentSchoolYear.schoolYearId}
                    isMultiSelect={false}
                    onChange={handleClassChange}
                    value={selectedClasses}
                    data={classes}
                    isDisabled={
                      props.userContext.roleId ===
                        constant.UserRoleId.District && selectedSchool === 0
                        ? true
                        : false
                    }
                    showArchived={true}
                    showHidden={true}
                    //subjectId={selectedSubject}
                    colorStyle={"white"}
                    selectedSchoolAccountId={selectedSchoolAccountId}
                  ></ClassDropDown>
                </div>
                <div className="flex flex-col">
                  <label className="block mb-1 text-sm text-gray-500">
                    Grade
                    {props.userContext.roleId ===
                      constant.UserRoleId.District && <Asterisk />}
                  </label>

                  <Select
                    value={selectedGrade}
                    onChange={(e) => {
                      setSelectedGrade(parseInt(e.target.value));
                      handleGradeFilterChange(e);
                    }}
                    className="py-2 px-4 block text-sm border border-gray-300 focus:ring-primary-violet focus:border-primary-violet form-select leading-5 p-1 rounded"
                  >
                    <option value={0}>All Grades</option>
                    {grades?.map((item: any, index) => {
                      return (
                        <Fragment key={index}>
                          <option value={item.gradeId}>{item?.name}</option>
                        </Fragment>
                      );
                    })}
                  </Select>
                </div>

                <div className="flex flex-col">
                  <label className="block mb-1 text-sm text-gray-500">
                    Alien Rankings
                  </label>
                  <Select
                    value={selectedAlienRank}
                    onChange={(e) => {
                      setSelectedAlienRank(parseInt(e.target.value));
                      handleAlienRankingFilterChange(e);
                    }}
                    className="py-2 px-4 block text-sm border border-gray-300 focus:ring-primary-violet focus:border-primary-violet form-select leading-5 p-1 rounded"
                  >
                    <option value={0}>All</option>
                    {alienRankings?.map((item: any, index) => {
                      return (
                        <Fragment key={index}>
                          <option value={item.alienRankingCardId}>
                            {item?.name +
                              (item.isPrestigeMode ? " (Prestige)" : "")}
                          </option>
                        </Fragment>
                      );
                    })}
                  </Select>
                </div>
              </div>

              <div className="flex gap-4 justify-end">
                <button
                  onClick={onReset}
                  className="bg-transparent border my-auto border-primary-violet shadow-sm py-2 px-4 flex justify-center text-sm font-medium text-primary-violet hover:text-white hover:bg-light-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3 transition ease-in-out duration-500"
                >
                  Reset
                </button>

                <div
                  className="cursor-pointer"
                  data-tip
                  data-for="incompleteSelection"
                >
                  {(selectedSubject === 0 ||
                    selectedSchool === 0 ||
                    (props.userContext.roleId !==
                      constant.UserRoleId.District &&
                      props.userContext.roleId !==
                        constant.UserRoleId.SchoolAdmin &&
                      selectedClass < 0)) && (
                    <ReactTooltip
                      id="incompleteSelection"
                      class="bg-secondary-teal/10 z-50 "
                      effect="solid"
                    >
                      <span>Please complete all required fields</span>
                    </ReactTooltip>
                  )}

                  <div className="cursor-not-allowed">
                    <button
                      onClick={onRunReport}
                      className={
                        "bg-primary-violet border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 " +
                        (validateRequiredFields()
                          ? "bg-opacity-20 text-primary-violet text-opacity-40 pointer-events-none"
                          : "hover:bg-dark-violet hover:shadow-lg")
                      }
                    >
                      Run Report
                    </button>
                  </div>
                </div>

                <div className="flex gap-x-4 cursor-not-allowed">
                  <button
                    onClick={exportReportExcel}
                    className={`bg-primary-violet border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 
                  ${
                    reportData && reportData.length > 0
                      ? "hover:bg-dark-violet hover:shadow-lg"
                      : "bg-opacity-20 text-primary-violet text-opacity-40 pointer-events-none"
                  }
                  `}
                  >
                    Export Data
                  </button>

                  <ReactToPrint
                    trigger={() => (
                      <div className="cursor-not-allowed">
                        <button
                          className={`bg-primary-violet border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 
                      ${
                        reportData && reportData.length > 0
                          ? "hover:bg-dark-violet hover:shadow-lg"
                          : "bg-opacity-20 text-primary-violet text-opacity-40 pointer-events-none"
                      }
                      `}
                        >
                          <PrintIcon className="w-5 h-5 mr-1"></PrintIcon>
                          Print Report
                        </button>
                      </div>
                    )}
                    content={() => componentRef.current}
                    onBeforeGetContent={getReportPdf}
                    documentTitle={"LOGalaxyStarReport"}
                  />
                  <button
                    onClick={exportReportCertificates}
                    className={`bg-primary-violet border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 
                  ${
                    reportData && reportData.length > 0
                      ? "hover:bg-dark-violet hover:shadow-lg"
                      : "bg-opacity-20 text-primary-violet text-opacity-40 pointer-events-none"
                  }
                  `}
                  >
                    Print Class Certificates
                  </button>
                </div>
              </div>

              <div className="border-b border-gray-400 py-2 mb-2"></div>
              {showLoader && <Loader></Loader>}
              {noRecordFoundMsg}
              {showReport && (
                <div className="bg-white py-5 px-5 pt-2 mt-4">
                  <LiftoffGalaxyStarReportData
                    subjectName={subjectName ?? ""}
                    filters={getFilters()}
                    reportData={reportData}
                    printFlag={false}
                    downloadStudentCertificate={downloadStudentCertificate}
                  ></LiftoffGalaxyStarReportData>
                  <div
                    className={`${pageNumber} hidden sm:flex-1 sm:flex sm:items-center sm:justify-between`}
                  >
                    {reportData?.length !== 0 && (
                      <Pagination
                        totalRecords={totalRecords}
                        pageSize={pageSize}
                        pageNumber={pageNumber}
                        onPageChange={onPageChange}
                        reset={resetPageNumber}
                        bgColor="primary-violet"
                      />
                    )}
                    {reportData?.length !== 0 && (
                      <PageSize
                        pageSize={pageSize}
                        onPageSizeChange={onPageSizeChange}
                        bgColor="primary-violet"
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="hidden">
        <div className="bg-white py-5 px-5 pt-2 mt-4" ref={componentRef}>
          <LiftoffGalaxyStarReportData
            subjectName={subjectName ?? ""}
            filters={getFilters()}
            reportData={pdfReportData}
            printFlag={true}
            downloadStudentCertificate={downloadStudentCertificate}
          ></LiftoffGalaxyStarReportData>
        </div>
      </div>
    </>
  );
}

export default connect((state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
})(LiftoffGalaxyStarReport);

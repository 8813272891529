import {AxiosHelper} from "../../environment/axiosConfig";
import { Configuration } from "../../environment/setup";

export function getStudentFamily(userId: number) {
  const url = `${Configuration.ApiBaseUrl}app/student/FamilyId/${userId}`;
  return AxiosHelper.axiosInstance().get(url);
}
export function getQRCode(userId: number) {
  const url = `${Configuration.ApiBaseUrl}app/student/QRCode/${userId}`;
  return AxiosHelper.axiosInstance().get(url);
}

import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { ChevronRightIcon } from "@heroicons/react/solid";
import RouteConstant from "../../../../utils/constant/routeConstant";
import { Configuration } from "../../../../environment/setup";

function LaunchAlienArena(props: any) {
  return (
    <div className="min-w-0 w-full">
      <div className="px-4 py-5 lg:px-8 border-b">
        <ol className="flex items-center space-x-2 mb-1">
          <li>
            <Link to={RouteConstant.TeacherRoutes.AlienArenaSessions}>
              <div className="flex items-center">
                <div className="cursor-pointer text-sm text-gray-500 hover:text-gray-700 border-dashed border-b border-gray-400 hover:border-opacity-0">
                  Alien Arena
                </div>
              </div>
            </Link>
          </li>
          <li>
            <div className="flex items-center">
              <ChevronRightIcon className="flex-shrink-0 h-5 w-5 text-gray-400" />
              <span className="ml-1 text-sm text-gray-500 capitalize">
                Final Result
              </span>
            </div>
          </li>
        </ol>
        <div className=" text-lg font-medium flex gap-2 h-auto sm:h-10 items-center">
          <h1 className="text-primary-violet flex items-center justify-between w-full pr-4">
            Final Result
          </h1>
        </div>
      </div>
      <div className="px-2 sm:px-6 lg:px-8 py-6">
        <div className="w-full bg-gray-100 text-center px-6 py-8 rounded-md">
          <div className="bg-primary-violet md:w-3/4 lg:w-1/2 xl:w-1/3 xxl:w-1/3 xxxl:w-1/3 rounded-md py-10 mx-auto">
            <h1 className="text-white text-4xl font-light mb-4">
              Congratulations !
            </h1>
            <img
              src={`${Configuration.S3bucketImagePath}/images/sport_badges-05-512.png`}
              className="w-28 mx-auto"
              alt="Congratulations"
            />
            <div>
              <h2 className="text-green-300 text-2xl mt-4">Winner</h2>
              <div className="flex justify-between shadow border bg-white mx-6 px-4 py-2 rounded-md mt-3">
                <div>
                  <div className="text-gray-700 text-sm">Rocketeers</div>
                </div>
                <div>
                  <div className="text-green-500 text-sm">4/5</div>
                </div>
              </div>

              <div className="flex justify-between shadow border bg-white mx-6 px-4 py-2 rounded-md mt-2">
                <div>
                  <div className="text-gray-700 text-sm">Shooting Stars</div>
                </div>
                <div>
                  <div className="text-green-500 text-sm">5/5</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-8 text-center">
          <button
            className="bg-primary-violet border border-transparent shadow-sm mt-4 sm:mt-0 py-2 px-4 justify-center text-sm font-medium text-white hover:bg-dark-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            type="button"
          >
            Close & View Summary Report
          </button>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(LaunchAlienArena);

import { AxiosResponse } from "axios";
import { AxiosHelper, axiosInstance } from "../../environment/axiosConfig";
import { IGoogleCourseDTO } from "../../model/googleClassroom/googleCourseDTO";
import auth from "../../utils/auth";
import APIConstant from "../../utils/constant/apiConstant";
import { IGoogleStudentModel } from "../../model/interface/rosterUser";

export class GoogleApi {
  public static addGoogleUser(
    accountLoginName: string,
    username: string,
    password: string,
    googleId: string,
    gmailId: string,
    refreshToken: string
  ) {
    return axiosInstance.post(APIConstant.GOOGLE_API.GoogleUser_Add, {
      AccountId: accountLoginName,
      Username: username,
      Password: password,
      GoogleId: googleId,
      GmailId: gmailId,
      refreshToken: refreshToken,
    });
  }

  public static unlinkGoogleUser(userId: number) {
    return AxiosHelper.axiosInstance().put(
      APIConstant.GOOGLE_API.GoogleUser_UnlinkGoogleUser,
      {
        UserId: userId,
      }
    );
  }

  public static getGoogleClassesToImport(
    userId: number,
    googleUserId: number,
    googleId: string,
    refreshToken: string
  ): Promise<AxiosResponse<IGoogleCourseDTO[]>> {
    return AxiosHelper.axiosInstance().get<IGoogleCourseDTO[]>(
      APIConstant.GOOGLE_API.GetGoogleClassesToImport,
      {
        params: {
          userId: userId,
          googleUserId: googleUserId,
          GoogleId: googleId,
          refreshToken: refreshToken,
        },
      }
    );
  }

  public static importGoogleClasses(
    googleClasses: IGoogleCourseDTO[],
    schoolAccountId: number,
    userId: number
  ) {
    return AxiosHelper.axiosInstance().post(
      APIConstant.GOOGLE_API.ImportGoogleClasses,
      {
        googleClasses: googleClasses,
        schoolAccountId: schoolAccountId,
        userId: userId,
        googleAccessToken: auth.getGoogleAccessToken(),
      }
    );
  }

  public static submitGoogleAssignmentScore(
    assignmentId: number,
    assignmentActivityId: number,
    googleId: string,
    userId: number,
    googleUserId: number,
    score: number,
    refreshToken: string
  ) {
    return AxiosHelper.axiosInstance().post(
      APIConstant.GOOGLE_API.SubmitGoogleAssignmentScore,
      {
        assignmentId: assignmentId,
        assignmentActivityId: assignmentActivityId,
        googleId: googleId,
        googleUserId: googleUserId,
        userId: userId,
        score: score,
        refreshToken: refreshToken,
      }
    );
  }

  public static gradeGoogleAssignment(
    googleId: string,
    userId: number,
    googleUserId: number,
    refreshToken: string
  ) {
    return AxiosHelper.axiosInstance().post(
      APIConstant.GOOGLE_API.GradeGoogleAssignment,
      {
        googleId: googleId,
        googleUserId: googleUserId,
        userId: userId,
        refreshToken: refreshToken,
      }
    );
  }

  public static getGoogleStudentToImport(
    classId: number,
    courseId: string,
    pageSize: number,
    nextPageToken: string,
    refreshToken: string
  ): Promise<AxiosResponse<IGoogleStudentModel>> {
    return AxiosHelper.axiosInstance().get<IGoogleStudentModel>(
      APIConstant.GOOGLE_API.GetGoogleStudentsToImport,
      {
        params: {
          classId: classId,
          courseId: courseId,
          PageSize: pageSize,
          PageToken: nextPageToken,
          refreshToken: refreshToken,
        },
      }
    );
  }

  public static syncGoogleStudents(
    googleId: string,
    userId: number,
    classId: number,
    courseId: string,
    students: Array<any>
  ) {
    return AxiosHelper.axiosInstance().post(
      APIConstant.GOOGLE_API.SyncGoogleStudents,
      {
        googleId: googleId,
        userId: userId,
        classId: classId,
        courseId: courseId,
        students: students,
        googleAccessToken: auth.getGoogleAccessToken(),
      }
    );
  }

  public static mapGoogleStudents(
    googleCourseStudents: Array<any>,
    classId: number
  ) {
    return AxiosHelper.axiosInstance().post(
      APIConstant.GOOGLE_API.MapGoogleStudents,
      {
        googleCourseStudents: googleCourseStudents,
        classId: classId,
      }
    );
  }

  public static addGoogleCourse(
    classId: number,
    name: string,
    userId: number,
    googleId: string,
    refreshToken: string
  ) {
    return AxiosHelper.axiosInstance().post(
      APIConstant.GOOGLE_API.AddGoogleCourse,
      {
        classId: classId,
        name: name,
        userId: userId,
        googleId: googleId,
        refreshToken: refreshToken,
      }
    );
  }

  public static syncAssignmentsToGoogle(
    assignmentId: number,
    userId: number,
    googleUserId: number,
    accountLoginName: string,
    startDate: Date,
    dueDate: Date,
    refreshToken: string
  ) {
    return AxiosHelper.axiosInstance().post(
      APIConstant.GOOGLE_API.AddGoogleAssignments,
      {
        assignmentId: assignmentId,
        userId: userId,
        googleUserId: googleUserId,
        startDate: startDate,
        dueDate: dueDate,
        accountLoginName: accountLoginName,
        refreshToken: refreshToken,
      }
    );
  }

  public static removeGoogleAssignment(
    assignmentId: number,
    refreshToken: string
  ) {
    return AxiosHelper.axiosInstance().delete(
      APIConstant.GOOGLE_API.RemoveGoogleAssignment,
      {
        data: {
          assignmentId: assignmentId,
          refreshToken: refreshToken,
        },
      }
    );
  }

  public static connectToGoogle(
    classId: number,
    userId: number,
    overrideDisplayName: boolean,
    course: IGoogleCourseDTO
  ) {
    return AxiosHelper.axiosInstance().post(
      APIConstant.GOOGLE_API.ConnectToGoogle,
      {
        classId: classId,
        userId: userId,
        overrideDisplayName: overrideDisplayName,
        course: course,
      }
    );
  }

  public static disconnect(classId: number) {
    return AxiosHelper.axiosInstance().put(APIConstant.GOOGLE_API.Disconnect, {
      classId: classId,
    });
  }
}

import React from "react";
import { CompletionInfo } from "./interface";

const Assessment: React.FunctionComponent<CompletionInfo> = ({
  is_saved,
  past_due,
}: CompletionInfo) => {
  return (
    <div
      className={`flex-shrink-0 cursor-pointer h-12 w-12 bg-blues ${
        is_saved === 1 ? "bg-site-orange" : ""
      } ${
        past_due === 1 ? "bg-site-pink" : ""
      }  hover:opacity-90 rounded-full flex items-center justify-center`}
    >
      <svg
        className="w-5 h-5"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16 19"
      >
        <g id="Group_13" data-name="Group 13" transform="translate(-1377 -738)">
          <g
            id="Rectangle_3"
            data-name="Rectangle 3"
            transform="translate(1377 738)"
            fill="none"
            stroke="#fff"
            strokeWidth="1"
          >
            <rect width="16" height="19" stroke="none"></rect>
            <rect x="0.5" y="0.5" width="15" height="18" fill="none"></rect>
          </g>
          <line
            id="Line_12"
            data-name="Line 12"
            x2="15"
            transform="translate(1377.5 739.5)"
            fill="none"
            stroke="#fff"
            strokeWidth="2"
          ></line>
          <g id="Group_9" data-name="Group 9">
            <line
              id="Line_13"
              data-name="Line 13"
              x2="8"
              transform="translate(1382.5 743.5)"
              fill="none"
              stroke="#fff"
              strokeWidth="1.3"
            ></line>
            <line
              id="Line_14"
              data-name="Line 14"
              x2="1.6"
              transform="translate(1379.5 743.5)"
              fill="none"
              stroke="#fff"
              strokeWidth="1.3"
            ></line>
          </g>
          <g id="Group_10" data-name="Group 10" transform="translate(0 3)">
            <line
              id="Line_13-2"
              data-name="Line 13"
              x2="8"
              transform="translate(1382.5 743.5)"
              fill="none"
              stroke="#fff"
              strokeWidth="1.3"
            ></line>
            <line
              id="Line_14-2"
              data-name="Line 14"
              x2="1.6"
              transform="translate(1379.5 743.5)"
              fill="none"
              stroke="#fff"
              strokeWidth="1.3"
            ></line>
          </g>
          <g id="Group_11" data-name="Group 11" transform="translate(0 6)">
            <line
              id="Line_13-3"
              data-name="Line 13"
              x2="8"
              transform="translate(1382.5 743.5)"
              fill="none"
              stroke="#fff"
              strokeWidth="1.3"
            ></line>
            <line
              id="Line_14-3"
              data-name="Line 14"
              x2="1.6"
              transform="translate(1379.5 743.5)"
              fill="none"
              stroke="#fff"
              strokeWidth="1.3"
            ></line>
          </g>
          <g id="Group_12" data-name="Group 12" transform="translate(0 9)">
            <line
              id="Line_13-4"
              data-name="Line 13"
              x2="8"
              transform="translate(1382.5 743.5)"
              fill="none"
              stroke="#fff"
              strokeWidth="1.3"
            ></line>
            <line
              id="Line_14-4"
              data-name="Line 14"
              x2="1.6"
              transform="translate(1379.5 743.5)"
              fill="none"
              stroke="#fff"
              strokeWidth="1.3"
            ></line>
          </g>
        </g>
      </svg>
    </div>
  );
};

export default Assessment;


function NotAuthorized() {
  return (
    <div>
      You are not authorized. Please <a href="https://app.progresslearning.com/login">Login</a>{" "}
      again.
    </div>
  );
}

export default NotAuthorized;

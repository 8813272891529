import { Fragment, useEffect, useState } from "react";
import { XIcon } from "@heroicons/react/outline";
import { getSchoolSubscriptionSubjects } from "../../../../../api/teacher/school";

interface AddSubscriptionModalProps {
  showPopUp: (show: boolean) => void;
  districtId: number;
  subjectId: number;
  startDate: string;
  endDate: string;
}

interface SchoolSubscriptionStatus {
  schoolId: number;
  schoolName: string;
  subscriptionStatus: string;
}

function AddSubscriptionModal(props: AddSubscriptionModalProps) {
  const [schoolSubscription, setSchoolSubscription] =
    useState<Array<SchoolSubscriptionStatus>>();
  useEffect(() => {
    getSchoolSubscriptionSubjects(
      props.districtId,
      props.subjectId,
      props.startDate,
      props.endDate
    ).then((res) => {
      if (res.data) {
        setSchoolSubscription(res.data);
      }
    });
  }, [props]);
  return (
    <Fragment>
      <div className="fixed inset-0 z-40 flex items-center bg-black bg-opacity-50 sm:items-center sm:justify-center">
        <div
          className="w-full flex flex-col justify-between overflow-hidden h-auto max-h-80percent bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:max-w-xl"
          role="dialog"
        >
          <header>
            {/* Header */}
            <div className="px-4 py-4 bg-gray-50">
              <div className="flex items-start justify-between space-x-3">
                <div className="space-y-1">
                  <div className="text-lg font-medium text-gray-900"></div>
                </div>
                <div className="h-7 flex items-center">
                  <button
                    type="button"
                    className="text-gray-400 hover:text-gray-500"
                    onClick={() => props.showPopUp(false)}
                  >
                    <span className="sr-only">Close panel</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
          </header>
          <div className="w-full relative overflow-auto px-4 py-4">
            <div className="px-2">
              <table className="w-full">
                <thead>
                  <tr className="bg-light-violet text-white">
                    <th className="text-left p-3 text-sm font-normal">
                      School
                    </th>
                    <th className="text-left p-3 text-sm font-normal">
                      Status
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {schoolSubscription &&
                    schoolSubscription.map((school) => {
                      return (
                        <>
                          <tr>
                            <td className="capitalize text-left p-3 text-sm font-normal">
                              {school.schoolName}
                            </td>
                            <td className="capitalize text-left p-3 text-sm font-normal">
                              <span
                                className={`${
                                  school.subscriptionStatus == "Active"
                                    ? "text-green-400"
                                    : "text-red-400"
                                }`}
                              >
                                {school.subscriptionStatus}
                              </span>
                            </td>
                          </tr>
                        </>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>

          <footer className="flex flex-col items-center justify-end px-6 py-4 sm:flex-row bg-gray-50 dark:bg-gray-800">
            <div className="flex-shrink-0">
              <div className="space-x-3 flex justify-end">
                <button
                  className="align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 rounded-lg text-sm text-gray-600 border-gray-300 border dark:text-gray-400 focus:outline-none active:bg-transparent hover:border-gray-500 focus:border-gray-500 active:text-gray-500 focus:shadow-outline-gray sm:w-auto"
                  type="button"
                  onClick={() => {
                    props.showPopUp(false);
                  }}
                >
                  Close
                </button>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </Fragment>
  );
}

export default AddSubscriptionModal;

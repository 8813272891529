import { useHistory } from "react-router-dom";
import RouteConstant from "../../../../../utils/constant/routeConstant";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { PaperAirplaneIcon } from "@heroicons/react/outline";
import { RocketIcon, SkillPracticeIcon } from "../../../../../assets/icons";
import { connect } from "react-redux";
import { getNewAssignmentsCount } from "../../../../../api/student/studentAssignmentApi";
import { getSkillStandard } from "../../../../../api/student/skillPracticeApi";
import {
  currentArcadeLockTime,
  isGameArcadeLockedForStudent,
} from "../../../../../utils/studentHelper";
import ReactTooltip from "react-tooltip";
import moment from "moment";
import { useLocation } from "react-router-dom";

interface GameLockTime {
  gameLockStartTime: string | null;
  gameLockEndTime: string | null;
}
function HeaderLeft(props: any) {
  const location = useLocation();
  const history = useHistory();
  const { t } = useTranslation();
  const [activeLink, setActiveLink] = useState(
    location.pathname.toLowerCase().indexOf("gradedwork") > -1
      ? RouteConstant.MiddleSchool.gradedWork
      : RouteConstant.MiddleSchool.home
  );
  const [studentNewAssignmentsCount, setStudentNewAssignmentsCount] =
    useState<number>(0);
  const [showSkillPractice, setShowSkillPractice] = useState<boolean>(false);
  const showArcade: Boolean = true;
  const [isArcadeLocked, setIsArcadeLocked] = useState<boolean>(false);
  const [lockMessage, setLockMessage] = useState<string>("");

  function handleNaviagation(link: string, programMode?: string) {
    setActiveLink(link);
    history.push(link);
  }

  function checkSkillPracticeExists() {
    getSkillStandard(0, 0, props.userContext?.gradeId).then((d) => {
      if (d.data.data.length > 0) {
        setShowSkillPractice(true);
      }
    });
  }

  function getNewAssignmentCount() {
    getNewAssignmentsCount(props.userContext!.userId).then((response) => {
      setStudentNewAssignmentsCount(response.data);
    });
  }

  function timeFormat(time) {
    return moment(time, "hh:mm:ss").format("hh:mm A");
  }
  const activeLinkClass =
    "space-x-2 relative bg-site-teal text-white transition-all duration-500 group w-full pl-6 flex items-center px-2 py-3 text-sm rounded-l-md rounded-r-md ";
  const inActiveLinkClass =
    " space-x-2 relative text-gray-600 transition-all duration-500 group hover:bg-site-teal/20 w-full hover:pl-6 flex items-center px-2 py-3 text-sm rounded-l-md rounded-r-md ";
  const lockedClass =
    " opacity-50 space-x-2 relative transition-all duration-500 group w-full hover:pl-6 flex items-center px-2 py-3 text-sm rounded-l-md rounded-r-md cursor-not-allowed";

  useEffect(() => {
    getNewAssignmentCount();
    checkSkillPracticeExists();
  }, []);

  useEffect(() => {
    if (
      props.profile &&
      props.profile.gameLockStartTime &&
      props.profile.gameLockEndTime
    ) {
      const isLocked = isGameArcadeLockedForStudent(
        props.profile.isGameLocked,
        props.profile.gameLockStartTime,
        props.profile.gameLockEndTime
      );
      setIsArcadeLocked(isLocked);
      var currentGameLockTime: GameLockTime = currentArcadeLockTime(
        props.profile.isGameLocked,
        props.profile.gameLockStartTime,
        props.profile.gameLockEndTime
      );
      if (currentGameLockTime && !lockMessage) {
        const message =
          "The Game Arcade has been locked from " +
          timeFormat(currentGameLockTime?.gameLockStartTime) +
          " to " +
          timeFormat(currentGameLockTime?.gameLockEndTime) +
          " by your teacher. If you believe this to be an error, please reach out to your teacher.";
        setLockMessage(message);
      }
    }
  }, [props.profile]);
  return (
    <div className="w-full px-3">
      {isArcadeLocked && (
        <ReactTooltip
          id="arcadeLockMessage"
          multiline={true}
          class="bg-secondary-teal/10 z-50 "
          place="bottom"
          effect="solid"
          offset={{ top: -40, left: 60 }}
          arrowColor="transparent"
          className="custom-tooltip"
        ></ReactTooltip>
      )}
      <nav className="space-y-4 mt-2">
        <button
          onClick={() =>
            handleNaviagation(RouteConstant.MiddleSchool.assignments)
          }
          className={`${
            activeLink === RouteConstant.MiddleSchool.assignments
              ? activeLinkClass
              : inActiveLinkClass
          }${studentNewAssignmentsCount > 0 ? "justify-between" : ""}`}
        >
          <span className="flex space-x-2">
            <svg
              className="stroke-current h-5 w-5"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 209.661 162.64"
            >
              <g
                id="Group_1"
                data-name="Group 1"
                transform="translate(-226.006 -206.167)"
              >
                <path
                  id="Path_1"
                  data-name="Path 1"
                  d="M278,208.667H388L428.667,252H241.333Z"
                  fill="none"
                  strokeLinecap="round"
                  strokeWidth="8"
                ></path>
                <path
                  id="Path_2"
                  data-name="Path 2"
                  d="M414.667,256v48H241.333s-28.667-25.333,0-51.333"
                  fill="none"
                  strokeLinecap="round"
                  strokeWidth="8"
                ></path>
                <path
                  id="Path_3"
                  data-name="Path 3"
                  d="M414.667,283.333l16,21.333h-16"
                  fill="none"
                  strokeLinecap="round"
                  strokeWidth="8"
                ></path>
                <path
                  id="Path_4"
                  data-name="Path 4"
                  d="M262.667,304.667,253.333,322H430.667l-6-17.333"
                  fill="none"
                  strokeLinecap="round"
                  strokeWidth="8"
                ></path>
                <path
                  id="Path_5"
                  data-name="Path 5"
                  d="M253.333,365.333H414V322l16,43.333H417.333"
                  fill="none"
                  strokeLinecap="round"
                  strokeWidth="8"
                ></path>
                <path
                  id="Path_6"
                  data-name="Path 6"
                  d="M260.667,340.667h142"
                  fill="none"
                  strokeLinecap="round"
                  strokeWidth="8"
                ></path>
                <path
                  id="Path_7"
                  data-name="Path 7"
                  d="M241.333,278.333H396.667"
                  fill="none"
                  strokeLinecap="round"
                  strokeWidth="8"
                ></path>
                <path
                  id="Path_8"
                  data-name="Path 8"
                  d="M295.333,220h68"
                  fill="none"
                  strokeLinecap="round"
                  strokeWidth="8"
                ></path>
                <path
                  id="Path_9"
                  data-name="Path 9"
                  d="M290,234h81.333"
                  fill="none"
                  strokeWidth="8"
                ></path>
                <path
                  id="Path_10"
                  data-name="Path 10"
                  d="M251.75,323.75s-8,21.25,1.25,41.75"
                  fill="none"
                  strokeLinecap="round"
                  strokeWidth="8"
                ></path>
              </g>
            </svg>
            <span className="">{t("Shared.StudentSideBar.Assignments")}</span>
          </span>
          {studentNewAssignmentsCount > 0 && (
            <span
              id="ribbon"
              className="bg-[#F15A27] flex items-center text-xs text-white rounded-xl shadow px-1.5 py-1 font-bold"
            >
              {t("StudyPlan.New")}
            </span>
          )}
        </button>
        <button
          onClick={() =>
            handleNaviagation(RouteConstant.MiddleSchool.gradedWork)
          }
          className={
            activeLink === RouteConstant.MiddleSchool.gradedWork
              ? activeLinkClass
              : inActiveLinkClass
          }
        >
          <svg
            className="stroke-current h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 154.461 208"
          >
            <g
              id="Group_3"
              data-name="Group 3"
              transform="translate(-601.667 -40)"
            >
              <path
                id="Path_13"
                data-name="Path 13"
                d="M702.667,48H807.282l33.846,35.077V248H694.667V48Z"
                transform="translate(-89 -4)"
                fill="none"
                strokeLinejoin="round"
                strokeWidth="12"
              ></path>
              <path
                id="Path_14"
                data-name="Path 14"
                d="M814,48V87.333h36.667"
                transform="translate(-101.205 -4)"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="12"
              ></path>
              <path
                id="Path_15"
                data-name="Path 15"
                d="M720.667,155,730,164l18-17.333"
                transform="translate(-91.416 -12.25)"
                fill="none"
                strokeLinecap="round"
                strokeWidth="12"
              ></path>
              <path
                id="Path_16"
                data-name="Path 16"
                d="M720.667,155,730,164l18-17.333"
                transform="translate(-91.416 17.991)"
                fill="none"
                strokeLinecap="round"
                strokeWidth="12"
              ></path>
              <path
                id="Path_17"
                data-name="Path 17"
                d="M720.667,155,730,164l18-17.333"
                transform="translate(-91.416 49.333)"
                fill="none"
                strokeLinecap="round"
                strokeWidth="12"
              ></path>
              <path
                id="Path_18"
                data-name="Path 18"
                d="M789.333,155h38"
                transform="translate(-98.575 -12.231)"
                fill="none"
                strokeLinecap="round"
                strokeWidth="12"
              ></path>
              <path
                id="Path_19"
                data-name="Path 19"
                d="M789.333,155h38"
                transform="translate(-98.575 18.538)"
                fill="none"
                strokeLinecap="round"
                strokeWidth="12"
              ></path>
              <path
                id="Path_20"
                data-name="Path 20"
                d="M789.333,155h38"
                transform="translate(-98.575 49.667)"
                fill="none"
                strokeLinecap="round"
                strokeWidth="12"
              ></path>
              <g
                id="Ellipse_1"
                data-name="Ellipse 1"
                transform="translate(673.938 138.734)"
                fill="none"
                strokeWidth="12"
              >
                <circle cx="4.5" cy="4.5" r="4.5" stroke="none"></circle>
                <circle cx="4.5" cy="4.5" r="0.5" fill="none"></circle>
              </g>
              <g
                id="Ellipse_2"
                data-name="Ellipse 2"
                transform="translate(673.938 169.085)"
                fill="none"
                strokeWidth="12"
              >
                <circle cx="4.5" cy="4.5" r="4.5" stroke="none"></circle>
                <circle cx="4.5" cy="4.5" r="0.5" fill="none"></circle>
              </g>
              <g
                id="Ellipse_3"
                data-name="Ellipse 3"
                transform="translate(673.938 203.116)"
                fill="none"
                strokeWidth="12"
              >
                <circle cx="4.5" cy="4.5" r="4.5" stroke="none"></circle>
                <circle cx="4.5" cy="4.5" r="0.5" fill="none"></circle>
              </g>
              <path
                id="Path_21"
                data-name="Path 21"
                d="M773.5,73.5V94"
                transform="translate(-95.064 -4)"
                fill="none"
                strokeLinecap="round"
                strokeWidth="12"
              ></path>
              <path
                id="Path_22"
                data-name="Path 22"
                d="M760.5,83.75h23.75"
                transform="translate(-94.956 -4)"
                fill="none"
                strokeLinecap="round"
                strokeWidth="12"
              ></path>
              <path
                id="Path_23"
                data-name="Path 23"
                d="M738.75,73.5l-17.25,46"
                transform="translate(-91.316 -4)"
                fill="none"
                strokeLinecap="round"
                strokeWidth="12"
              ></path>
              <path
                id="Path_24"
                data-name="Path 24"
                d="M739.75,75.25l17,44.75"
                transform="translate(-92.884 -4)"
                fill="none"
                strokeLinecap="round"
                strokeWidth="12"
              ></path>
              <path
                id="Path_25"
                data-name="Path 25"
                d="M730.125,102.5H748.25"
                transform="translate(-92.079 -8.192)"
                fill="none"
                strokeLinecap="round"
                strokeWidth="12"
              ></path>
            </g>
          </svg>
          <span className="">{t("Shared.StudentSideBar.GradedWork")}</span>
        </button>
        <button
          onClick={() => handleNaviagation(RouteConstant.MYSTATMS)}
          className={`hidden
          ${
            activeLink === RouteConstant.MYSTATMS
              ? activeLinkClass
              : inActiveLinkClass
          }`}
        >
          <svg
            version="1.0"
            className="w-5 h-5"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 48.000000 48.000000"
            preserveAspectRatio="xMidYMid meet"
          >
            <g
              transform="translate(0.000000,48.000000) scale(0.100000,-0.100000)"
              fill="currentColor"
              stroke="none"
            >
              <path
                d="M30 275 l0 -145 85 0 c84 0 85 0 85 -25 0 -22 -4 -25 -35 -25 -19 0
-35 -4 -35 -10 0 -6 43 -10 110 -10 67 0 110 4 110 10 0 6 -16 10 -35 10 -31
0 -35 3 -35 25 0 25 1 25 85 25 l85 0 0 145 0 145 -210 0 -210 0 0 -145z m400
0 l0 -125 -190 0 -190 0 0 125 0 125 190 0 190 0 0 -125z m-170 -170 c0 -18
-5 -25 -20 -25 -15 0 -20 7 -20 25 0 18 5 25 20 25 15 0 20 -7 20 -25z"
              />
              <path
                d="M170 320 c0 -36 -2 -40 -25 -40 -22 0 -25 -4 -25 -35 0 -22 -6 -38
-15 -41 -42 -17 0 -24 135 -24 135 0 177 7 135 24 -11 4 -15 22 -15 71 l0 65
-35 0 c-24 0 -35 -5 -35 -15 0 -8 -9 -15 -20 -15 -15 0 -20 7 -20 25 0 23 -4
25 -40 25 l-40 0 0 -40z m60 -50 c0 -63 -2 -70 -20 -70 -18 0 -20 7 -20 70 0
63 2 70 20 70 18 0 20 -7 20 -70z m110 -10 c0 -47 -3 -60 -15 -60 -12 0 -15
13 -15 60 0 47 3 60 15 60 12 0 15 -13 15 -60z m-50 -15 c0 -38 -3 -45 -20
-45 -17 0 -20 7 -20 45 0 38 3 45 20 45 17 0 20 -7 20 -45z m-120 -15 c0 -20
-5 -30 -15 -30 -10 0 -15 10 -15 30 0 20 5 30 15 30 10 0 15 -10 15 -30z"
              />
            </g>
          </svg>
          <span className="">{t("Shared.StudentSideBar.MyStats")}</span>
        </button>
        <button
          onClick={() => handleNaviagation(RouteConstant.LEADERBOARDMS)}
          className={`hidden
          ${
            activeLink === RouteConstant.LEADERBOARDMS
              ? activeLinkClass
              : inActiveLinkClass
          }`}
        >
          <PaperAirplaneIcon className="w-5 h-5" />
          <span className="">{t("Shared.StudentSideBar.Leaderboard")}</span>
        </button>
        <button
          onClick={() => handleNaviagation(RouteConstant.MiddleSchool.subjects)}
          className={
            activeLink === RouteConstant.MiddleSchool.subjects
              ? activeLinkClass
              : inActiveLinkClass
          }
        >
          <svg
            version="1.0"
            className="w-5 h-5"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 64.000000 64.000000"
            preserveAspectRatio="xMidYMid meet"
          >
            <g
              transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)"
              fill="currentColor"
              stroke="none"
            >
              <path
                d="M249 545 c-33 -18 -53 -66 -45 -106 5 -27 3 -30 -12 -24 -41 16 -172
29 -183 18 -7 -7 -2 -44 21 -129 53 -201 43 -184 108 -184 31 -1 81 -9 110
-19 50 -17 54 -17 105 0 28 10 78 18 109 19 65 0 55 -17 108 184 23 85 28 122
21 129 -11 11 -142 -2 -183 -18 -15 -6 -17 -3 -12 24 17 85 -70 148 -147 106z
m91 -45 c11 -11 20 -29 20 -40 0 -26 -34 -60 -60 -60 -26 0 -60 34 -60 60 0
11 9 29 20 40 11 11 29 20 40 20 11 0 29 -9 40 -20z m-209 -114 c37 -8 85 -24
108 -35 l41 -21 0 -99 0 -99 -47 14 c-25 8 -63 14 -84 14 -27 0 -39 5 -43 18
-12 38 -56 207 -56 215 0 9 4 9 81 -7z m419 7 c0 -8 -44 -177 -56 -215 -4 -13
-16 -18 -43 -18 -21 0 -59 -6 -84 -14 l-47 -14 0 99 0 99 41 21 c57 29 189 58
189 42z"
              />
            </g>
          </svg>

          <span className="">{t("Shared.StudentSideBar.StudyPlan")}</span>
        </button>
        {props.profile?.isPLSkillPracticeActive === true &&
          showSkillPractice && (
            <button
              onClick={() =>
                handleNaviagation(
                  RouteConstant.MiddleSchool.SkillPracticeSubjects
                )
              }
              className={
                activeLink === RouteConstant.MiddleSchool.SkillPracticeSubjects
                  ? activeLinkClass
                  : inActiveLinkClass
              }
            >
              <SkillPracticeIcon className="w-5 h-5" />
              <span className="">
                {t("Shared.StudentSideBar.SkillsPractice")}
              </span>
            </button>
          )}
        {/*handle locked arcade*/}
        {isArcadeLocked && lockMessage && (
          <p
            className={lockedClass}
            data-tip={lockMessage}
            data-for={"arcadeLockMessage"}
          >
            <RocketIcon className="w-5 h-5" />
            <span className="">{t("Shared.StudentSideBar.Arcade")}</span>
          </p>
        )}
        {showArcade && !isArcadeLocked && (
          <>
            <button
              onClick={() =>
                handleNaviagation(RouteConstant.MiddleSchool.Arcade)
              }
              className={
                activeLink === RouteConstant.MiddleSchool.Arcade
                  ? activeLinkClass
                  : inActiveLinkClass
              }
              disabled={isArcadeLocked ? true : false}
            >
              <RocketIcon className="w-5 h-5" />
              <span className="">{t("Shared.StudentSideBar.Arcade")}</span>
            </button>
          </>
        )}
      </nav>
    </div>
  );
}
const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
};
export default connect<{}, {}, {}>(mapStateToProps, {})(HeaderLeft);

import { getCopyrightText } from "../../../utils/helper";

export interface ICopyrightFooterProps {
  firstName: string | undefined;
  lastName: string | undefined;
}

function ReportFooter(props: ICopyrightFooterProps) {
  return (
    <>
      <footer className="w-full bottom-0 fixed text-gray-400 flex justify-between ">
        <div>{getCopyrightText()}</div>
        <div className="pr-10">
          Printed by: {props.firstName} {props.lastName}
        </div>
      </footer>
    </>
  );
}

export default ReportFooter;

import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Select from "react-select";
import ReactTooltip from "react-tooltip";
import {
  getAlienArenaSessionById,
  getGames,
  getStandardsBySubject,
} from "../../../../api/teacher/alienArena";
import { ClassApi } from "../../../../api/teacher/classManagementApi";
import { Configuration } from "../../../../environment/setup";
import { IAlienStudentData } from "../../../../model/interface/alienArena";
import { IAlienArenaSetup } from "../../../../model/interface/alienArenaSetup";
import { IGames } from "../../../../model/interface/game";
import { ISelectElement } from "../../../../model/interface/selectElement";
import ClassSearchDTO from "../../../../model/teacher/classManagement/classSearchModel";
import { UserContextState } from "../../../../redux/actions/userContextAction";
import Constant from "../../../../utils/constant/constant";
import RouteConstant from "../../../../utils/constant/routeConstant";
import Permissions from "../../../../utils/permissions";
import AccessDenied from "../../../shared/accessDenied";
import Loader from "../../../shared/loader";
import AllowedTo from "../../../shared/rbac";
interface ArenaSetupProps {
  userId: number;
  userContext?: UserContextState;
  setActiveTab: (type: string) => void;
  setStudents: (students: IAlienStudentData[]) => void;
  alienArenaSetup: IAlienArenaSetup;
  setAlienArenaSetup: (alienArenaSetup: any) => void;
  setAlienArenaTeams: (alienArenaTeams: any) => void;
  alienArenaId: number;
}
interface ISelectedGame {
  value?: number;
  label?: string;
  isBlasterNeeded?: boolean;
  imageURL?: string;
}

function ArenaSetup(props: ArenaSetupProps) {
  const { handleSubmit } = useForm();

  const [loading, showLoading] = useState<boolean>(false);
  const [selectedGameId, setSelectedGameId] = useState<number>(0);

  const defaultTeams = [
    {
      label: "2",
      value: "2",
    },
    {
      label: "3",
      value: "3",
    },
    {
      label: "4",
      value: "4",
    },
  ];
  const defaultNumberOfQuestions = [
    {
      label: "4",
      value: "4",
    },
    {
      label: "5",
      value: "5",
    },
    {
      label: "6",
      value: "6",
    },
    {
      label: "7",
      value: "7",
    },
    {
      label: "8",
      value: "8",
    },
    {
      label: "9",
      value: "9",
    },
    {
      label: "10",
      value: "10",
    },
    {
      label: "11",
      value: "11",
    },
    {
      label: "12",
      value: "12",
    },
    {
      label: "13",
      value: "13",
    },
    {
      label: "14",
      value: "14",
    },
    {
      label: "15",
      value: "15",
    },
    {
      label: "16",
      value: "16",
    },
    {
      label: "17",
      value: "17",
    },
    {
      label: "18",
      value: "18",
    },
    {
      label: "19",
      value: "19",
    },
    {
      label: "20",
      value: "20",
    },
  ];
  const { setActiveTab, setStudents, setAlienArenaSetup, alienArenaSetup } =
    props;
  const [subjects, setSubjects] = useState<Array<any>>([]);
  const defaultValue = { label: "Select", value: "0" };
  const [selectedSubject, setSelectedSubject] =
    useState<ISelectElement>(defaultValue);
  const [selectedClass, setSelectedClass] =
    useState<ISelectElement>(defaultValue);
  const [selectedStandard, setSelectedStandard] = useState<ISelectElement[]>(
    []
  );
  const [standards, setStandards] = useState<ISelectElement[]>([]);
  const [selectedTeams, setSelectedTeams] =
    useState<ISelectElement>(defaultValue);
  const [selectedNoOfQuestions, setSelectedNoOfQuestions] =
    useState<ISelectElement>(defaultValue);
  const [selectedGame, setSelectedGame] = useState<ISelectedGame>();
  const [selectedRocket, setSelectedRocket] =
    useState<ISelectElement>(defaultValue);
  const [selectedBlaster, setSelectedBlaster] =
    useState<ISelectElement>(defaultValue);
  const [isTeacherDevice, setIsTeacherDevice] = useState<boolean>(false);
  const [isStudentDevice, setIsStudentDevice] = useState<boolean>(true);

  const [sessionName, setSessionName] = useState("");
  const teams = defaultTeams;

  const totalNumberOfQuestions = defaultNumberOfQuestions;

  const [classes, setClasses] = useState<Array<ISelectElement>>([defaultValue]);
  const [games, setGames] = useState<Array<IGames>>();
  const [alienArenaSetupDetails, setAlienArenaSetupDetails] =
    useState<IAlienArenaSetup>(alienArenaSetup);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  const getAlienArenaGames = (alienArenaSetupEdit?: IAlienArenaSetup) => {
    showLoading(true);
    getGames(
      props.userId,
      props.userContext?.gradeId ? props.userContext?.gradeId : 0,
      1
    )
      .then((d) => {
        setGames(d.data);
        if (alienArenaSetup && alienArenaSetup.gameId) {
          setSelectedGameId(alienArenaSetup.gameId);
          let selectedGame = d.data
            .filter((item) => {
              return item.gameId === alienArenaSetup.gameId;
            })
            .map((el) => {
              return {
                label: el.name,
                value: el.gameId,
                isBlasterNeeded: el.isBlasterNeeded,
                imageURL: `${
                  Configuration.S3bucketImagePath
                }/images/game-thumbnail/${
                  el.imageurl?.toString().split("/")[
                    el.imageurl?.toString().split("/").length - 1
                  ]
                }`,
              };
            });
          setSelectedGame(selectedGame[0]);
          setAlienArenaSetupDetails((prevState) => ({
            ...prevState,
            gameId: alienArenaSetup.gameId,
            isBlastersRequired: alienArenaSetup.isBlastersRequired,
          }));
        }
        if (alienArenaSetupEdit && alienArenaSetupEdit.gameId) {
          setSelectedGameId(alienArenaSetupEdit.gameId);
          let selectedGame = d.data
            .filter((item) => {
              return item.gameId === alienArenaSetupEdit.gameId;
            })
            .map((el) => {
              return {
                label: el.name,
                value: el.gameId,
                isBlasterNeeded: el.isBlasterNeeded,
                imageURL: `${
                  Configuration.S3bucketImagePath
                }/images/game-thumbnail/${
                  el.imageurl?.toString().split("/")[
                    el.imageurl?.toString().split("/").length - 1
                  ]
                }`,
              };
            });
          setSelectedGame(selectedGame[0]);
          setAlienArenaSetupDetails((prevState) => ({
            ...prevState,
            gameId: alienArenaSetup.gameId,
            isBlastersRequired: alienArenaSetup.isBlastersRequired,
          }));
        }
        showLoading(false);
      })
      .catch(() => {
        showLoading(false);
      });
  };
  function getClassStudent(classId: number): void {
    showLoading(true);
    ClassApi.getClassStudent(classId, 1, 999)
      .then((d) => {
        setStudents(
          d.data.data
            .filter((d) => d.isDisabled === false)
            .map((values: any) => {
              return {
                name: `${values?.lastName},${values?.firstName}`,
                id: values?.username,
                code: values?.schoolCode,
              };
            })
        );
        showLoading(false);
      })
      .catch(() => {
        showLoading(false);
      });
  }
  function getClassList(
    classSearch: ClassSearchDTO,
    alienArenaSetupEdit?: IAlienArenaSetup
  ) {
    showLoading(true);
    const response = ClassApi.getClassList(classSearch);
    response
      ?.then((d) => {
        setClasses(
          d.data.data.map((values: any) => {
            return {
              label: values?.name,
              value: values?.classId,
            };
          })
        );
        if (alienArenaSetupEdit && alienArenaSetupEdit.classId) {
          const selectedClass: any = d.data.data
            .map((values: any) => {
              return {
                label: values?.name,
                value: values?.classId,
              };
            })
            .filter((item) => {
              return item.value === alienArenaSetupEdit.classId;
            });
          setSelectedClass(selectedClass);
        }
        if (alienArenaSetup && alienArenaSetup.classId) {
          const selectedClass: any = d.data.data
            .map((values: any) => {
              return {
                label: values?.name,
                value: values?.classId,
              };
            })
            .filter((item) => {
              return item.value === alienArenaSetup.classId;
            });
          setSelectedClass(selectedClass);
        }
        showLoading(false);
      })
      .catch(() => {
        showLoading(false);
      });
  }

  function getSubjects(
    classId: number,
    alienArenaSetupEdit?: IAlienArenaSetup
  ) {
    showLoading(true);
    ClassApi.getClassSubject(classId, 1, 999).then((response) => {
      showLoading(false);
      const subjects = response.data.data.map((values: any) => {
        return {
          label: values?.name,
          value: values?.subjectId,
        };
      });
      setSubjects(subjects);
      if (
        alienArenaSetup &&
        alienArenaSetup.subjectName &&
        alienArenaSetup.subjectId
      ) {
        setSelectedSubject({
          label: alienArenaSetup.subjectName,
          value: alienArenaSetup.subjectId.toString(),
        });
        setAlienArenaSetup((prevState) => ({
          ...prevState,
          subjectId: alienArenaSetup.subjectId,
          subjectName: alienArenaSetup.subjectName,
        }));
        setAlienArenaSetupDetails((prevState) => ({
          ...prevState,
          subjectId: alienArenaSetup.subjectId,
          subjectName: alienArenaSetup.subjectName,
        }));
      }
      if (
        alienArenaSetupEdit &&
        alienArenaSetupEdit.subjectName &&
        alienArenaSetupEdit.subjectId
      ) {
        setSelectedSubject({
          label: alienArenaSetupEdit.subjectName,
          value: alienArenaSetupEdit.subjectId.toString(),
        });
        setAlienArenaSetup((prevState) => ({
          ...prevState,
          subjectId: alienArenaSetupEdit.subjectId,
          subjectName: alienArenaSetupEdit.subjectName,
        }));
        setAlienArenaSetupDetails((prevState) => ({
          ...prevState,
          subjectId: alienArenaSetupEdit.subjectId,
          subjectName: alienArenaSetupEdit.subjectName,
        }));
      }
    });
  }

  function getStandards(
    subjectId: number,
    alienArenaSetupEdit?: IAlienArenaSetup
  ) {
    getStandardsBySubject(subjectId).then((response) => {
      const standards = response.data.map((values: any) => {
        return {
          label: values?.name,
          value: values?.id,
        };
      });
      setStandards(standards);
      if (alienArenaSetup && alienArenaSetup.standardIds?.length) {
        const standardsArray = alienArenaSetup.standardIds.split(",");
        var defaultStandards: ISelectElement[] = [];

        defaultStandards = standards.filter((item) =>
          standardsArray.includes(item.value.toString())
        );
        setSelectedStandard(defaultStandards);
        setAlienArenaSetup((previousState: IAlienArenaSetup) => ({
          ...previousState,
          standardIds: alienArenaSetup.standardIds,
        }));
        setAlienArenaSetupDetails((previousState: IAlienArenaSetup) => ({
          ...previousState,
          standardIds: alienArenaSetup.standardIds,
        }));
      }
      if (alienArenaSetupEdit && alienArenaSetupEdit.standardIds?.length) {
        const standardsArray = alienArenaSetupEdit.standardIds.split(",");
        defaultStandards = standards.filter((item) =>
          standardsArray.includes(item.value.toString())
        );
        setSelectedStandard(defaultStandards);
        setAlienArenaSetup((previousState: IAlienArenaSetup) => ({
          ...previousState,
          standardIds: alienArenaSetupEdit.standardIds,
        }));
        setAlienArenaSetupDetails((previousState: IAlienArenaSetup) => ({
          ...previousState,
          standardIds: alienArenaSetupEdit.standardIds,
        }));
      }
    });
  }

  function handleSessionNameChange(e: any) {
    setSessionName(e.target.value);
    setAlienArenaSetupDetails((prevState) => ({
      ...prevState,
      sessionName: e.target.value,
    }));
    setAlienArenaSetup((previousState: IAlienArenaSetup) => ({
      ...previousState,
      sessionName: e.target.value.toString(),
    }));
  }

  function handleClassChange(e: any) {
    setSelectedClass(e);

    setAlienArenaSetupDetails((prevState) => ({
      ...prevState,
      classId: e.value,
      className: e.label,
    }));
    setSelectedSubject(defaultValue);
    getSubjects(e.value);
    getClassStudent(e.value);
  }
  function handleSubjectChange(e: any) {
    setSelectedSubject(e);
    setAlienArenaSetupDetails((prevState) => ({
      ...prevState,
      subjectId: e.value,
      subjectName: e.label,
    }));
    setAlienArenaSetup(alienArenaSetupDetails);
    setSelectedStandard([]);
    getStandards(e.value);
  }
  function handleStandardChange(e: any) {
    setSelectedStandard(e);
    const standrdListOfIDs = e.map((r) => parseInt(r.value));
    setAlienArenaSetupDetails((prevState) => ({
      ...prevState,
      standardIds: standrdListOfIDs.toString(),
    }));
    setAlienArenaSetup(alienArenaSetupDetails);
  }
  function handleTeamsChange(e: any) {
    setSelectedTeams(e);
    setAlienArenaSetupDetails((prevState) => ({
      ...prevState,
      teamSize: e.value,
    }));
    setAlienArenaSetup(alienArenaSetupDetails);
  }
  function handleGameChange(
    gameId: number,
    gameName: string,
    isBlaster: boolean,
    imageURL: string
  ) {
    setSelectedGameId(gameId);
    setSelectedGame({
      value: gameId,
      label: gameName,
      isBlasterNeeded: isBlaster,
      imageURL: imageURL,
    });
    if (!isBlaster) {
      setSelectedBlaster(defaultValue);
    }
    setAlienArenaSetupDetails((prevState) => ({
      ...prevState,
      gameId: gameId,
      isBlastersRequired: true,
    }));
    setAlienArenaSetup(alienArenaSetupDetails);
  }
  function handleRocketChange(rocketId: number, rocketName: string) {
    setSelectedRocket({ label: rocketName, value: rocketId.toString() });
    setAlienArenaSetupDetails((prevState) => ({
      ...prevState,
      rocketId: rocketId,
    }));
    setAlienArenaSetup((prevState) => ({
      ...prevState,
      rocketId: rocketId,
    }));
  }
  function handleBlasterChange(blasterId: number, blasterName: string) {
    setSelectedBlaster({ label: blasterName, value: blasterId.toString() });
    setAlienArenaSetupDetails((prevState) => ({
      ...prevState,
      blasterName: blasterName,
      blasterLevel: "1",
    }));
    setAlienArenaSetup((prevState) => ({
      ...prevState,
      blasterName: blasterName,
      blasterLevel: "1",
    }));
  }
  function handleNumberOfQuestionsChange(e: any) {
    setSelectedNoOfQuestions(e);
    setAlienArenaSetupDetails((prevState) => ({
      ...prevState,
      questionCount: parseInt(e.value),
    }));
    setAlienArenaSetup(alienArenaSetupDetails);
  }
  function handleSetSelectedDeviceType(type: string) {
    if (type === "Teacher") {
      setIsTeacherDevice(true);
      setIsStudentDevice(false);
      //  setSelectedDeviceType(type);
    } else {
      setIsTeacherDevice(false);
      setIsStudentDevice(true);
      //  setSelectedDeviceType(type);
    }
    setAlienArenaSetupDetails((prevState) => ({
      ...prevState,
      gamePlaySide: type,
    }));
    setAlienArenaSetup(alienArenaSetupDetails);
  }
  function handleNext() {
    setActiveTab(Constant.ArenaActiveTab.TEAMS);
    setAlienArenaSetupDetails(alienArenaSetupDetails);
    setAlienArenaSetup(alienArenaSetupDetails);
  }
  const getAlienArenaEditData = (alienArenaId: number) => {
    getAlienArenaSessionById(alienArenaId).then((res) => {
      props.setAlienArenaTeams(res.data.alienArenaTeams);

      setAlienArenaSetupDetails((prevState) => ({
        ...prevState,
        classId: res.data.alienArena.classId,
        className: res.data.alienArena.className,
        sessionid: res.data.alienArena.sessionId,
        sessionName: res.data.alienArena.sessionName,
        alienArenaId: res.data.alienArena.alienArenaId,
        blasterName: res.data.alienArena.blasterName,
        blasterLevel: "1",
        rocketId: res.data.alienArena.rocketId.toString(),
        gamePlaySide: res.data.alienArena.gamePlaySide,
        questionCount: res.data.alienArena.questionCount.toString(),
        teamSize: Object.keys(res.data.alienArenaTeams)?.length - 1,
      }));

      setAlienArenaSetup((prevState) => ({
        ...prevState,
        classId: res.data.alienArena.classId,
        className: res.data.alienArena.className,
        sessionid: res.data.alienArena.sessionId,
        sessionName: res.data.alienArena.sessionName,
        alienArenaId: res.data.alienArena.alienArenaId,
        blasterName: res.data.alienArena.blasterName,
        blasterLevel: "1",
        rocketId: res.data.alienArena.rocketId.toString(),
        gamePlaySide: res.data.alienArena.gamePlaySide,
        questionCount: res.data.alienArena.questionCount.toString(),
        teamSize: Object.keys(res.data.alienArenaTeams)?.length - 1,
      }));

      if (res.data.alienArena && res.data.alienArena.blasterName) {
        setSelectedBlaster({
          value: "0",
          label: res.data.alienArena.blasterName,
        });
      }

      if (res.data.alienArena && res.data.alienArena.rocketId) {
        setSelectedRocket({
          value: res.data.alienArena.rocketId.toString(),
          label: "",
        });
      }
      if (res.data.alienArena.gamePlaySide) {
        //  setSelectedDeviceType(res.data.alienArena.gamePlaySide);
        setIsStudentDevice(res.data.alienArena.gamePlaySide === "Student");
        setIsTeacherDevice(res.data.alienArena.gamePlaySide === "Teacher");
      }

      if (res.data.alienArena.questionCount) {
        setSelectedNoOfQuestions({
          label: res.data.alienArena.questionCount.toString(),
          value: res.data.alienArena.questionCount.toString(),
        });
      }
      setSelectedTeams({
        label: (Object.keys(res.data?.alienArenaTeams)?.length - 1).toString(),
        value: (Object.keys(res.data?.alienArenaTeams)?.length - 1).toString(),
      });
      if (res.data.alienArena.sessionName)
        setSessionName(res.data.alienArena.sessionName);
      getAlienArenaGames(res.data.alienArena);
      const classSearch: ClassSearchDTO = {
        pageNumber: 1,
        pageSize: 999,
        showArchive: false,
        showCurrent: true,
        showHidden: false,
        userId: props.userContext?.userId ? props.userContext?.userId : 0,
        schoolId: props.userContext?.schoolId ? props.userContext?.schoolId : 0,
        schoolAccountId: props.userContext?.schoolAccountId
          ? props.userContext?.schoolAccountId
          : 0,
      };
      getClassList(classSearch, res.data.alienArena);
      if (res.data.alienArena.classId)
        getSubjects(res.data.alienArena.classId, res.data.alienArena);
      if (res.data.alienArena.subjectId)
        getStandards(res.data.alienArena.subjectId, res.data.alienArena);
    });
  };
  useEffect(() => {
    if (props.alienArenaId) getAlienArenaEditData(props.alienArenaId);
  }, [props.alienArenaId]);

  useEffect(() => {
    if (!props.alienArenaId) {
      if (alienArenaSetup.blasterName) {
        setSelectedBlaster({
          value: "0",
          label: alienArenaSetup.blasterName,
        });
      }

      if (alienArenaSetup.rocketId) {
        setSelectedRocket({
          value: alienArenaSetup.rocketId.toString(),
          label: "",
        });
      }
      if (alienArenaSetup.gamePlaySide) {
        //  setSelectedDeviceType(alienArenaSetup.gamePlaySide);
        setIsStudentDevice(alienArenaSetup.gamePlaySide === "Student");
        setIsTeacherDevice(alienArenaSetup.gamePlaySide === "Teacher");
      }

      if (alienArenaSetup.questionCount)
        setSelectedNoOfQuestions({
          label: alienArenaSetup.questionCount.toString(),
          value: alienArenaSetup.questionCount.toString(),
        });
      if (alienArenaSetup.teamSize)
        setSelectedTeams({
          label: alienArenaSetup.teamSize.toString(),
          value: alienArenaSetup.teamSize.toString(),
        });
      if (alienArenaSetup.sessionName)
        setSessionName(alienArenaSetup.sessionName);
      getAlienArenaGames();
      const classSearch: ClassSearchDTO = {
        pageNumber: 1,
        pageSize: 999,
        showArchive: false,
        showCurrent: true,
        showHidden: false,
        userId: props.userContext?.userId ? props.userContext?.userId : 0,
        schoolId: props.userContext?.schoolId ? props.userContext?.schoolId : 0,
        schoolAccountId: props.userContext?.schoolAccountId
          ? props.userContext?.schoolAccountId
          : 0,
      };
      getClassList(classSearch);
      if (alienArenaSetup.classId) getSubjects(alienArenaSetup.classId);
      if (alienArenaSetup.subjectId) getStandards(alienArenaSetup.subjectId);
    }
  }, []);

  const onSubmit = async () => {
    setIsLoaded(true);
    if (
      !selectedNoOfQuestions.value ||
      !selectedTeams.value ||
      !selectedStandard.length ||
      !selectedSubject.value ||
      !sessionName.length ||
      !(selectedGame || alienArenaSetup.gameId)
    )
      return;

    handleNext();
  };
  const getSelectedRocketURL = (rocketId: number) => {
    let imageURL = Constant.Rockets.filter((item) => {
      return item.id === rocketId;
    }).map((el) => {
      return el.imageUrl;
    });
    return imageURL[0];
  };

  const getBlasterURL = (blasterName: String) => {
    for (const [key, value] of Object.entries(Constant.Blasters)) {
      if (key !== "") {
      }
      let imageURL = value.filter((item) => {
        return item.name === blasterName;
      })[0]?.imageUrl;
      if (imageURL) return imageURL;
    }
  };
  return (
    <AllowedTo
      perform={[
        Permissions.alien_arena_setup_create,
        Permissions.alien_arena_setup_modify,
      ]}
      no={() => <AccessDenied />}
    >
      <div className="px-4 sm:px-6  lg:px-6 py-6">
        {loading && <Loader />}
        <ReactTooltip
          multiline={true}
          className="bg-secondary-teal"
          backgroundColor="#008fbb"
        />
        <div className="relative">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div>
              <div className="flex flex-col xl:flex-row md:space-x-4">
                <div className="w-full bg-gray-100 xl:w-3/4 order-last xl:order-none">
                  <div className="flex flex-col lg:flex-row rounded-lg py-5 px-3 ">
                    <div className="space-y-4 w-full lg:w-1/4 mr-3">
                      <div>
                        <label
                          htmlFor="first-name"
                          className="block text-sm font-medium text-gray-500"
                        >
                          Session Name
                        </label>
                        <div className="mt-1">
                          <input
                            autoComplete="off"
                            type="text"
                            name="first-name"
                            id="first-name"
                            value={sessionName}
                            onChange={(e) => handleSessionNameChange(e)}
                            className="shadow-sm focus:ring-primary-violet focus:border-primary-violet h-10 block w-full text-lg border border-gray-300 text-gray-500 px-3 rounded-sm"
                          />
                        </div>
                        {isLoaded && sessionName.length === 0 && (
                          <small className="text-red-400">
                            <span>Session Name is required</span>
                          </small>
                        )}
                      </div>
                      <div>
                        <label
                          htmlFor="country"
                          className="block text-sm font-medium text-gray-500"
                        >
                          Class
                        </label>
                        <div className="mt-1">
                          <Select
                            value={selectedClass}
                            options={classes}
                            isSearchable={true}
                            onChange={handleClassChange}
                            className="w-full"
                            theme={(theme) => ({
                              ...theme,
                              colors: {
                                ...theme.colors,
                                primary: "#008fbb",
                              },
                            })}
                          />
                        </div>
                        {isLoaded && selectedClass.value === "0" && (
                          <small className="text-red-400">
                            <span>Class is required</span>
                          </small>
                        )}
                      </div>
                      <div>
                        <label
                          htmlFor="country"
                          className="block text-sm font-medium text-gray-500"
                        >
                          Subject
                        </label>
                        <div className="mt-1">
                          <Select
                            value={selectedSubject}
                            options={subjects}
                            isSearchable={true}
                            onChange={handleSubjectChange}
                            className="w-full"
                            isDisabled={selectedClass.value === "0"}
                            theme={(theme) => ({
                              ...theme,
                              colors: {
                                ...theme.colors,
                                primary: "#008fbb",
                              },
                            })}
                          />
                        </div>
                        {isLoaded && selectedSubject.value === "0" && (
                          <small className="text-red-400">
                            <span>Subject is required</span>
                          </small>
                        )}
                      </div>
                      <div>
                        <label
                          htmlFor="country"
                          className="block text-sm font-medium text-gray-500"
                        >
                          Standard
                        </label>
                        <div className="mt-1">
                          <Select
                            value={selectedStandard}
                            options={standards}
                            isSearchable={true}
                            isMulti={true}
                            closeMenuOnSelect={false}
                            onChange={handleStandardChange}
                            className="w-full"
                            isDisabled={selectedSubject.value === "0"}
                            theme={(theme) => ({
                              ...theme,
                              colors: {
                                ...theme.colors,
                                primary: "#008fbb",
                              },
                            })}
                          />
                        </div>
                        {isLoaded && selectedStandard.length === 0 && (
                          <small className="text-red-400">
                            <span>Standards are required</span>
                          </small>
                        )}
                      </div>
                      <div>
                        <label
                          htmlFor="country"
                          className="block text-sm font-medium text-gray-500"
                        >
                          Teams
                        </label>
                        <div className="mt-1">
                          <Select
                            value={selectedTeams}
                            options={teams}
                            isSearchable={true}
                            onChange={handleTeamsChange}
                            className="w-full"
                            theme={(theme) => ({
                              ...theme,
                              colors: {
                                ...theme.colors,
                                primary: "#008fbb",
                              },
                            })}
                          />
                        </div>
                        {isLoaded && selectedTeams.value === "0" && (
                          <small className="text-red-400">
                            <span>Teams is required</span>
                          </small>
                        )}
                      </div>
                      <div>
                        <label
                          htmlFor="country"
                          className="block text-sm font-medium text-gray-500"
                        >
                          No Of Questions
                        </label>
                        <div className="mt-1">
                          <Select
                            value={selectedNoOfQuestions}
                            options={totalNumberOfQuestions}
                            isSearchable={true}
                            onChange={handleNumberOfQuestionsChange}
                            className="w-full"
                            theme={(theme) => ({
                              ...theme,
                              colors: {
                                ...theme.colors,
                                primary: "#008fbb",
                              },
                            })}
                          />
                          {isLoaded && selectedNoOfQuestions.value === "0" && (
                            <small className="text-red-400">
                              <span>Total Number of questions required</span>
                            </small>
                          )}
                        </div>
                      </div>

                      <div className="mt-6 mb-4">
                        <div role="group" aria-labelledby="label-notifications">
                          <div className="">
                            <div>
                              <div
                                className="text-sm font-medium text-gray-500"
                                id="label-notifications"
                              >
                                Play Game
                              </div>
                            </div>
                            <div className="flex flex-col space-y-1 mt-2 items-start">
                              <label
                                x-radio-group-option=""
                                className=" relative flex items-center cursor-pointer focus:outline-none border-gray-400"
                              >
                                <input
                                  type="radio"
                                  checked={isTeacherDevice}
                                  x-model="value"
                                  name="assignment"
                                  value="Public access"
                                  className="h-4 w-4 mt-0.5 cursor-pointer text-primary-violet border-gray-300 focus:ring-primary-violet"
                                  aria-labelledby="privacy-setting-0-label"
                                  aria-describedby="privacy-setting-0-description"
                                  onClick={() =>
                                    handleSetSelectedDeviceType("Teacher")
                                  }
                                />
                                <div className="ml-3 flex flex-col">
                                  <span
                                    id="privacy-setting-0-label"
                                    className="block font-medium text-primary-violet text-sm"
                                  >
                                    On Teacher Device
                                  </span>
                                </div>
                              </label>
                              <label
                                x-radio-group-option=""
                                className=" relative flex items-center cursor-pointer focus:outline-none border-gray-400"
                              >
                                <input
                                  type="radio"
                                  x-model="value"
                                  name="assignment"
                                  value="Public access"
                                  checked={isStudentDevice}
                                  className="h-4 w-4 mt-0.5 cursor-pointer text-primary-violet border-gray-300 focus:ring-primary-violet"
                                  aria-labelledby="privacy-setting-0-label"
                                  aria-describedby="privacy-setting-0-description"
                                  onClick={() =>
                                    handleSetSelectedDeviceType("Student")
                                  }
                                />

                                <div className="ml-3 flex flex-col">
                                  <span
                                    id="privacy-setting-0-label"
                                    className="block font-medium text-primary-violet text-sm"
                                  >
                                    On Student Device
                                  </span>
                                </div>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="w-full lg:w-3/4 flex flex-col">
                      <div className="h-24 flex items-end justify-end">
                        {selectedGame?.value && (
                          <span className="flex flex-col">
                            <span className="text-sm text-gray-500">
                              Selected Game
                            </span>
                            <span className="flex space-x-2 bg-primary-violet p-2 rounded-lg">
                              <span className="border rounded-lg w-16 h-16 flex justify-center bg-white overflow-hidden ">
                                <img
                                  alt="game"
                                  className="h-full"
                                  src={selectedGame && selectedGame.imageURL}
                                />
                              </span>

                              {selectedRocket.value !== "0" && (
                                <span className="border rounded-lg w-16 h-16 flex justify-center p-1.5 bg-white  ">
                                  <img
                                    alt="rocket"
                                    src={getSelectedRocketURL(
                                      parseInt(selectedRocket.value)
                                    )}
                                  />
                                </span>
                              )}
                              {selectedBlaster.value !== "0" && (
                                <span className="border rounded-lg w-16 h-16 flex justify-center p-1.5 bg-white ">
                                  <img
                                    alt="blaster"
                                    src={getBlasterURL(selectedBlaster.label)}
                                  />
                                </span>
                              )}
                            </span>
                          </span>
                        )}
                      </div>
                      <div className="bg-white pl-3 pt-2 rounded-lg overflow-hidden shadow-sm mt-4 ">
                        <div className="mb-2">
                          <span className="text-sm font-medium text-gray-500">
                            Game
                          </span>
                          <ul className="flex flex-wrap items-center">
                            {games?.map((game) => (
                              <li
                                className={`${
                                  selectedGameId === game.gameId
                                    ? "border-x-4 border-emerald-600 h-20 border rounded-lg w-28  mr-2 mb-2 cursor-pointer bg-primary-violet overflow-hidden transition-all relative"
                                    : "border rounded-lg w-24 h-16 mr-2 mb-2 cursor-pointer overflow-hidden group relative"
                                }`}
                                key={game.gameId}
                                onClick={() => {
                                  handleGameChange(
                                    game.gameId,
                                    game.name ? game.name : "",
                                    game.isBlasterNeeded
                                      ? game.isBlasterNeeded
                                      : false,
                                    `${
                                      Configuration.S3bucketImagePath
                                    }/images/game-thumbnail/${
                                      game.imageurl?.toString().split("/")[
                                        game.imageurl?.toString().split("/")
                                          .length - 1
                                      ]
                                    }`
                                  );
                                }}
                              >
                                <img
                                  alt="game"
                                  className="h-full hover:opacity-70"
                                  src={`${
                                    Configuration.S3bucketImagePath
                                  }/images/game-thumbnail/${
                                    game.imageurl?.toString().split("/")[
                                      game.imageurl?.toString().split("/")
                                        .length - 1
                                    ]
                                  }`}
                                />
                                <div className="text-xs absolute bottom-0 text-white p-1 flex justify-center bg-primary-violet w-full transform transition duration-500 translate-y-10 group-hover:opacity-100 group-hover:translate-y-0">
                                  {game.name}
                                </div>
                              </li>
                            ))}
                          </ul>
                          {isLoaded &&
                            !(selectedGame || alienArenaSetup.gameId) && (
                              <small className="text-red-400">
                                <span>Game is required</span>
                              </small>
                            )}
                        </div>
                        <div className="mb-2">
                          <span className="text-sm font-medium text-gray-500">
                            Rocket
                          </span>
                          <ul className="flex flex-wrap">
                            {Constant.Rockets?.map((rocket) => (
                              <li
                                className={`${
                                  parseInt(selectedRocket.value) === rocket.id
                                    ? "border rounded-lg w-16 h-16 mr-2 mb-2 cursor-pointer bg-primary-violet p-1 flex justify-center transition-all"
                                    : "border rounded-lg w-16 h-16 mr-2 mb-2  cursor-pointer flex justify-center p-1.5 hover:bg-primary-violet/30"
                                } `}
                                key={rocket.id}
                                onClick={() => {
                                  handleRocketChange(rocket.id, rocket.name);
                                }}
                              >
                                <img src={rocket.imageUrl} alt="rocket" />
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                      {selectedGame?.isBlasterNeeded && (
                        <div className="bg-white pl-3 pt-2 pb-2 rounded-lg shadow-sm mt-3">
                          <span className="text-sm font-medium text-gray-500">
                            Blaster
                          </span>
                          <ul className="flex flex-wrap">
                            {Constant.Blasters.cannons?.map((connon) => (
                              <li
                                className={
                                  selectedBlaster.label === connon.name
                                    ? "border rounded-lg w-16 h-16 mr-2 mb-2 cursor-pointer transition-all p-1 bg-primary-violet"
                                    : "border rounded-lg w-16 h-16 mr-2 mb-2 cursor-pointer hover:p-1 hover:bg-primary-violet hover:opacity-80 transition-all"
                                }
                                onClick={() => {
                                  handleBlasterChange(connon.id, connon.name);
                                }}
                                key={connon.id}
                              >
                                <img src={connon.imageUrl} alt="cannon" />
                              </li>
                            ))}
                            {Constant.Blasters.energies?.map((energy) => (
                              <li
                                className={
                                  selectedBlaster.label === energy.name
                                    ? "border rounded-lg w-16 h-16 mr-2 mb-2 cursor-pointer transition-all p-1 bg-primary-violet"
                                    : "border rounded-lg w-16 h-16 mr-2 mb-2 cursor-pointer hover:p-1 hover:bg-primary-violet hover:opacity-80 transition-all"
                                }
                                onClick={() => {
                                  handleBlasterChange(energy.id, energy.name);
                                }}
                                key={energy.id}
                              >
                                <img src={energy.imageUrl} alt="energy" />
                              </li>
                            ))}
                            {Constant.Blasters.ices?.map((ice) => (
                              <li
                                className={
                                  selectedBlaster.label === ice.name
                                    ? "border rounded-lg w-16 h-16 mr-2 mb-2 cursor-pointer transition-all p-1 bg-primary-violet"
                                    : "border rounded-lg w-16 h-16 mr-2 mb-2 cursor-pointer hover:p-1 hover:bg-primary-violet hover:opacity-80 transition-all"
                                }
                                onClick={() => {
                                  handleBlasterChange(ice.id, ice.name);
                                }}
                                key={ice.id}
                              >
                                <img src={ice.imageUrl} alt="ice" />
                              </li>
                            ))}
                            {Constant.Blasters.fires?.map((fire) => (
                              <li
                                className={
                                  selectedBlaster.label === fire.name
                                    ? "border rounded-lg w-16 h-16 mr-2 mb-2 cursor-pointer transition-all p-1 bg-primary-violet"
                                    : "border rounded-lg w-16 h-16 mr-2 mb-2 cursor-pointer hover:p-1 hover:bg-primary-violet hover:opacity-80 transition-all"
                                }
                                onClick={() => {
                                  handleBlasterChange(fire.id, fire.name);
                                }}
                                key={fire.id}
                              >
                                <img src={fire.imageUrl} alt="fire" />
                              </li>
                            ))}
                            {Constant.Blasters.lazers?.map((lazer) => (
                              <li
                                className={
                                  selectedBlaster.label === lazer.name
                                    ? "border rounded-lg w-16 h-16 mr-2 mb-2 cursor-pointer transition-all p-1 bg-primary-violet"
                                    : "border rounded-lg w-16 h-16 mr-2 mb-2 cursor-pointer hover:p-1 hover:bg-primary-violet hover:opacity-80 transition-all"
                                }
                                onClick={() => {
                                  handleBlasterChange(lazer.id, lazer.name);
                                }}
                                key={lazer.id}
                              >
                                <img src={lazer.imageUrl} alt="razor" />
                              </li>
                            ))}
                            {Constant.Blasters.missiles?.map((missile) => (
                              <li
                                className={
                                  selectedBlaster.label === missile.name
                                    ? "border rounded-lg w-16 h-16 mr-2 mb-2 cursor-pointer transition-all p-1 bg-primary-violet"
                                    : "border rounded-lg w-16 h-16 mr-2 mb-2 cursor-pointer hover:p-1 hover:bg-primary-violet hover:opacity-80 transition-all"
                                }
                                onClick={() => {
                                  handleBlasterChange(missile.id, missile.name);
                                }}
                                key={missile.id}
                              >
                                <img src={missile.imageUrl} alt="missile" />
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}

                      {
                        <div className="flex justify-end mt-2">
                          <Link
                            to={RouteConstant.TeacherRoutes.AlienArenaSessions}
                          >
                            <button
                              type="button"
                              className="mr-2 bg-transparent whitespace-nowrap border my-auto border-primary-violet shadow-sm py-2 px-4 flex  text-sm font-medium text-primary-violet hover:text-white hover:bg-light-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500transition ease-in-out duration-500"
                            >
                              Cancel
                            </button>
                          </Link>
                          <AllowedTo
                            perform={Permissions.alien_arena_setup_create}
                          >
                            <button
                              onClick={onSubmit}
                              type="submit"
                              className="  bg-primary-violet border border-transparent shadow-sm py-2 px-4  text-sm font-medium text-white hover:bg-dark-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            >
                              Save & Continue
                            </button>
                          </AllowedTo>
                        </div>
                      }
                    </div>
                  </div>
                </div>

                <div className="w-full xl:w-1/4 order-1 xl:order-none">
                  <div className="text-sm text-gray-700 font-light bg-blue-50 p-4 flex flex-col lg:flex-row xl:flex-col w-full rounded-md mb-6 rounded-t-lg sm:rounded-lg">
                    <div className="flex">
                      <div className="flex-shrink-0 current-fill text-blue-400">
                        <svg
                          className="h-5 w-5"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fillRule="evenodd"
                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                      </div>
                      <span className="pl-2">
                        <span className="text-sm font-medium text-gray-500 pb-2 inline-block">
                          Instructions
                        </span>
                        <ul className="list-disc ml-4 text-sm text-gray-500 space-y-1">
                          <li>Select a class, and subject.</li>
                          <li>Choose your standard.</li>
                          <li>
                            Choose to have 2,3, or 4 teams within your class.
                          </li>
                          <li>
                            Choose the total number of questions. The number of
                            questions allowed will be a multiple of the number
                            of topics chosen.
                          </li>
                          <li>
                            Choose a game and a rocket. All games and rockets
                            are unlocked and available!
                          </li>
                          <li>
                            The questions will be randomly picked by the system
                            based on the selected topics
                          </li>
                        </ul>
                      </span>
                    </div>
                    <div className="bg-gray-900 sm:w-96 ml-8 xl:w-auto xl:h-80 mt-4">
                      <img
                        title="Video"
                        alt="Video"
                        className="h-full w-full"
                        src={`${Configuration.S3bucketImagePath}/images/VideoThumb_AA.png`}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </AllowedTo>
  );
}

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(ArenaSetup);

import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { getSkillStandardDetail } from "../../../../api/skillStandard/skillStandard";
import {
  getSessionSummary,
  getStudentAssignmentDetails,
} from "../../../../api/student/skillPracticeApi";
import { ISkillPracticeSessionSummary } from "../../../../model/skillPractice/skillPracticeSessionSummary";
import { ISkillStandard } from "../../../../model/skillPractice/skillStandard";
import { ITestDetails } from "../../../../model/skillPractice/testDetails";
import constant from "../../../../utils/constant/constant";
import RouteConstant from "../../../../utils/constant/routeConstant";
function SessionSummary(props: any) {
  const { params } = props.match;
  const history = useHistory();
  const [sessionSummary, setSessionSummary] =
    useState<ISkillPracticeSessionSummary>();
  const [testDetails, setTestDetails] = useState<ITestDetails>();
  const [isWon, setIsWon] = useState<boolean>(false);
  const [skillStandard, setSkillStandard] = useState<ISkillStandard>();
  const { t } = useTranslation();

  useEffect(() => {
    if (params.skillStudentAssignmentId > 0) {
      getStudentAssignmentDetails(
        params.skillStandardId,
        params.skillStudentAssignmentId
      )
        .then((d) => {
          setTestDetails(d.data);
        })
        .catch(() => {});
      getSessionSummary(params.skillStandardId, params.skillStudentAssignmentId)
        .then((d) => {
          setSessionSummary(d.data);
          setIsWon(d.data.isWon);
        })
        .catch(() => {});
    }

    if (params.skillStandardId > 0) {
      getSkillStandardDetail(params.skillStandardId).then((d) => {
        setSkillStandard(d.data);
      });
    }
  }, [params.skillStudentAssignmentId]);

  const theme = {
    ELEMENTARYHOME: {
      bgcolor: "bg-white ",
      breadcrumbcolor: "text-gray-500 ",
      breadcrumbborder: "border-gray-400 ",
      pgtitle: "text-gray-700",
      btncolor: "bg-primary-violet hover:bg-dark-violet",
      breadcrumbpadding: "px-6 py-5",
    },
    KINDERGARTEN: {
      bgcolor: "bg-white mx-8",
      breadcrumbcolor: "text-gray-500 ",
      breadcrumbborder: "border-gray-400 hover:text-gray-700 ",
      pgtitle: "text-gray-700",
      btncolor: "bg-primary-violet hover:bg-dark-violet",
      breadcrumbpadding: "p-5",
    },
    MIDDLESCHOOLHOME: {
      bgcolor: "",
      breadcrumbcolor: "text-white ",
      breadcrumbborder: "border-white ",
      pgtitle: "text-white ",
      btncolor: "bg-blues hover:hover:opacity-90",
      breadcrumbpadding: "px-4 py-5 lg:px-8",
    },
  };

  function redirectToHome() {
    const query: string = props.location.pathname;
    let homeUrl = "";
    let sessionSummaryUrl =
      RouteConstant.KindergartenStudentRoutes.SkillPracticeSessionSummary.replace(
        ":skillStandardId",
        params.skillStandardId.toString()
      ).replace(
        ":skillStudentAssignmentId",
        params.skillStudentAssignmentId.toString()
      );
    homeUrl =
      query.indexOf(sessionSummaryUrl) > -1
        ? RouteConstant.KindergartenStudentRoutes.Home
        : query.indexOf("middleSchool") > -1
        ? RouteConstant.MiddleSchool.Home
        : RouteConstant.StudentRoute.Home;
    history.push({
      pathname: homeUrl,
    });
  }

  const path: string = window.location.pathname;
  const themeName =
    path.indexOf("kindergarten") > -1
      ? "KINDERGARTEN"
      : path.indexOf("middleSchool") > -1
      ? "MIDDLESCHOOLHOME"
      : "ELEMENTARYHOME";

  return (
    <Fragment>
      <div
        className={` ${theme[themeName]?.bgcolor} h-full min-w-0 mb-4 rounded-2xl `}
      >
        <div className={`${theme[themeName]?.breadcrumbpadding}  border-b`}>
          <ol
            className={`${theme[themeName]?.breadcrumbcolor} flex items-center space-x-2 mb-1`}
          >
            <li>
              <div className="flex items-center">
                <span
                  onClick={redirectToHome}
                  className={`${theme[themeName]?.breadcrumbborder} cursor-pointer text-sm border-b border-dashed hover:border-opacity-0`}
                >
                  {t("Breadcrumb.Home")}
                </span>
              </div>
            </li>

            <li>
              <div className="flex items-center">
                <svg
                  className={`${theme[themeName]?.breadcrumbcolor} flex-shrink-0 h-5 w-5`}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clipRule="evenodd"
                  />
                </svg>

                <span
                  className={`ml-1 text-sm ${theme[themeName]?.breadcrumbcolor}`}
                >
                  {t("Breadcrumb.SkillsPractice")}
                </span>
              </div>
            </li>
          </ol>

          <div className="text-lg font-medium flex gap-2 h-auto sm:h-10 items-center">
            <h1
              className={`${theme[themeName]?.pgtitle} font-medium flex justify-start mb-0 pb-0`}
            >
              {t("SkillPractice.SkillsPracticeSummary")}
            </h1>
          </div>
        </div>

        <div className="sm:px-6  lg:px-8 py-1 relative my-4">
          <div className="text-center text-2xl flex flex-col">
            <div className="text-lg text-gray-700 w-auto inline-block mx-auto font-semibold">
              {skillStandard?.name}
            </div>
            <h2
              className={`capitalize text-3xl my-4 bg-white inline-block mx-auto rounded-full px-8 py-1 ${
                isWon === true ? "text-green-400" : "text-red-400"
              }`}
            >
              {props.profile?.plLanguageId === constant.Languages.Spanish &&
              sessionSummary?.message
                ? sessionSummary?.spanishMessage ?? sessionSummary?.message
                : sessionSummary?.message}
            </h2>

            <div className="w-full sm:w-3/4 xl:w-2/4 mx-auto shadow border bg-gray-50 rounded-md flex flex-col px-6 relative pt-6 pb-6">
              <div className="flex justify-between gap-x-4 left-0 w-full">
                <div
                  className={`${
                    isWon ? "bg-green-400" : "bg-red-400"
                  } text-white rounded-md py-2 px-4 shadow-lg w-52`}
                >
                  <div className="text-lg font-semibold">
                    {props.profile?.firstName.charAt(0) +
                      ". " +
                      props.profile?.lastName.charAt(0)}
                  </div>
                  <div className="text-xs">{testDetails?.schoolName}</div>
                </div>
                <div
                  className={`${
                    !isWon ? "bg-green-400" : "bg-red-400"
                  } text-white rounded-md py-2 px-4 shadow-lg w-52`}
                >
                  {testDetails?.opponentDetails && (
                    <>
                      <div className="text-lg font-semibold">
                        {testDetails?.opponentDetails?.firstName.charAt(0) +
                          "." +
                          testDetails?.opponentDetails?.lastName.charAt(0)}
                      </div>
                      <div className="text-xs">
                        {testDetails?.opponentDetails?.schoolName ?? ""}
                      </div>
                    </>
                  )}
                  {!testDetails?.opponentDetails && (
                    <>
                      <div className="text-lg font-semibold">Not Available</div>
                    </>
                  )}
                </div>
              </div>

              <div className="w-full flex flex-col mb-2">
                <div className="text-base mt-6 font-semibold">
                  {t("SkillPractice.Score")}
                </div>
                <div className="flex justify-center space-x-3">
                  <div
                    className={`${
                      isWon ? "text-green-400" : "text-red-400"
                    } font-semibold`}
                  >
                    {sessionSummary?.studentScore}
                  </div>
                  <div
                    className={`${
                      !isWon ? "text-green-400" : "text-red-400"
                    } font-semibold`}
                  >
                    {sessionSummary?.opponentScore}
                  </div>
                </div>
              </div>
              <div className="w-full flex flex-col mb-2">
                <div className="text-base font-semibold">
                  {t("SkillPractice.TotalTime")}
                </div>
                <div className="flex justify-center space-x-3">
                  <div className="flex flex-col">
                    <div
                      className={`${
                        isWon ? "text-green-400" : "text-red-400"
                      } font-semibold`}
                    >
                      <span>
                        {(
                          "0" +
                          Math.floor(
                            ((sessionSummary?.totalTime ?? 0) / 60) % 60
                          )
                        ).slice(-2)}
                      </span>
                      :
                      <span>
                        {(
                          "0" +
                          Math.floor((sessionSummary?.totalTime ?? 0) % 60)
                        ).slice(-2)}
                      </span>
                    </div>
                    <div className="flex">
                      <div className="text-xs ml-auto text-gray-500 pr-2">
                        min
                      </div>
                      <div className="text-xs ml-auto text-gray-500">sec</div>
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <div
                      className={`${
                        !isWon ? "text-green-400" : "text-red-400"
                      } font-semibold`}
                    >
                      <span>
                        {(
                          "0" +
                          Math.floor(
                            ((sessionSummary?.opponentTime ?? 0) / 60) % 60
                          )
                        ).slice(-2)}
                      </span>
                      :
                      <span>
                        {(
                          "0" +
                          Math.floor((sessionSummary?.opponentTime ?? 0) % 60)
                        ).slice(-2)}
                      </span>
                    </div>
                    <div className="flex">
                      <div className="text-xs ml-auto text-gray-500 pr-2">
                        min
                      </div>
                      <div className="text-xs ml-auto text-gray-500">sec</div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="w-full flex flex-col mt-2">
                <div className="text-base font-semibold">
                  {t("SkillPractice.TotalQuestions")}
                </div>
                <div className="text-xl font-semibold bg-gray-200 rounded-full inline-block mx-auto text-gray-700 w-10 h-10 mt-3 flex justify-center items-center">
                  {sessionSummary?.questionCount}
                </div>
              </div>
            </div>

            <button
              onClick={redirectToHome}
              className={`${theme[themeName]?.btncolor} mx-auto mt-10 border border-transparent rounded shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3 `}
            >
              {t("SkillPractice.Back")}
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(SessionSummary);

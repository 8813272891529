import { Fragment } from "react";
import {
  EHStarIcon1,
  EHStarIcon2,
  EHStarIcon3,
} from "../../../../../assets/icons";

function GalaxyStar() {
  return (
    <Fragment>
      <div className="fixed inset-0 z-40 flex items-center bg-black bg-opacity-50 sm:justify-center enter-done">
        <div
          className="w-full px-6 py-4 overflow-hidden bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:max-w-xl"
          role="dialog"
        >
          <div>
            <header className="flex justify-between">
              <h2>How to Earn Galaxy Stars </h2>
              <button
                className="inline-flex items-center justify-center w-6 h-6 text-gray-400 transition-colors duration-150 rounded dark:hover:text-gray-200 hover: hover:text-gray-700"
                aria-label="close"
              >
                <svg
                  className="w-4 h-4"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  role="img"
                  aria-hidden="true"
                >
                  <path
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                    fillRule="evenodd"
                  ></path>
                </svg>
              </button>
            </header>
            <div className="my-3  rounded-2xl py-1 text-center transition ease-in-out duration-500 bg-galaxy-stars bg-cover bg-top">
              <div className="text-lg text-gray-700 dark:text-gray-300 mb-6 overflow-auto text-left font-light">
                <div className="text-left text-black">
                  <p className="align-bottom rounded-lg text-base  w-full gap-2 mb-1">
                    Work on MY STUDY PLAN topics to earn Galaxy Stars.
                  </p>

                  <p className="align-bottom rounded-lg text-base  w-full gap-2 mb-1">
                    Earn up to 3 stars per topic.
                  </p>

                  <p className="align-bottom rounded-lg text-base  w-full gap-2 mb-1">
                    Must complete full 10 question session to earn stars
                  </p>
                </div>
              </div>
              <div className="flex flex-col gap-4 ">
                <div className="flex justify-start items-center ">
                  <EHStarIcon1 className="w-8"></EHStarIcon1>
                  <span className="text-2xl ml-5">Score 70%</span>
                </div>
                <div className="flex justify-start items-center">
                  <EHStarIcon2 className="w-14"></EHStarIcon2>
                  <span className="text-2xl ml-5">Score 80%</span>
                </div>
                <div className="flex justify-start items-center">
                  <EHStarIcon3 className="w-20"></EHStarIcon3>
                  <span className="text-2xl ml-5">Score 100%</span>
                </div>
              </div>
            </div>
            <footer className="flex flex-col items-center justify-end px-6 py-3 -mx-6 -mb-4 space-y-4 sm:space-y-0 sm:space-x-6 sm:flex-row bg-gray-50 dark:bg-gray-800">
              <button
                className="align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 rounded-lg text-sm text-white border border-transparent  focus:shadow-outline-purple w-full sm:w-auto bg-primary-blue hover:bg-[#0149c1] gap-2 hover:shadow-md"
                type="button"
              >
                OK
              </button>
            </footer>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default GalaxyStar;

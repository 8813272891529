import { useState } from "react";
import { connect } from "react-redux";
import "../../../../../css/style.css";
import RouteConstant from "../../../../../utils/constant/routeConstant";
import Breadcrumb from "../../shared/breadcrumb";
import { IBreadcrumb } from "../../../../../model/common/breadcrumb";
import { useTranslation } from "react-i18next";
import constant from "../../../../../utils/constant/constant";
import Loader from "../../../../../component/shared/loader";
import ResultsData from "./resultsData";
import { ReportType } from "../../../../../types/type";


function ResultReports(props) {
  const { t } = useTranslation();  
  const parameters = new URLSearchParams(props.location.search);
  const [type] = useState<string>(parameters.get("type") ?? "ASSIGNMENT");
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [assignmentId] = useState<number>(
    parseInt(parameters.get("assignmentId") ?? "0")
  );
  const [assignmentActivityId] = useState<number>(
    parseInt(parameters.get("assignmentActivityId") ?? "0")
  );
  const [assessmentId] = useState<number>(
    parseInt(parameters.get("assessmentId") ?? "0")
  );
  const [schoolYearId] = useState<number>(
    parseInt(parameters.get("schoolYearId") ?? "0")
  );
  const assessmentSubjectId = parseInt(parameters.get("subjectId") ?? "0");
  const [classId] = useState<string>(parameters.get("classId") ?? "0");  
  const [hasCRActivity] = useState<boolean>(
    parameters.get("hasCRActivity") &&
      parameters.get("hasCRActivity") === "true"
      ? true
      : false
  );
  
  const [redirectedFrom] = useState<string>(
    (parameters.get("redirectedFrom") ?? "").toUpperCase()
  );

  const breadcrumbItems = () => {
    let items: Array<IBreadcrumb> = [];

    if (redirectedFrom === "GRADEBOOK") {
      items = [
        { name: t("Breadcrumb.Home"), url: RouteConstant.ROUTE_DASHBOARD },
        {
          name: t("Breadcrumb.Reports"),
          url: RouteConstant.REPORTS,
        },
        {
          name: t("Breadcrumb.GradeBook"),
          url: RouteConstant.TeacherRoutes.GradeBook,
        },
        { name: t("Breadcrumb.ResultsReport"), url: "" },
      ];
    } else if (
      redirectedFrom === "MYASSIGNMENTS" ||
      redirectedFrom === "REMEDIATIONASSIGNMENTS"
    ) {
      items = [
        { name: t("Breadcrumb.Home"), url: RouteConstant.ROUTE_DASHBOARD },
        {
          name:
            props.userContext.roleId === constant.UserRoleId.District
              ? t("Breadcrumb.AssessmentCenter")
              : t("Breadcrumb.AssessmentAssignmentCenter"),
          url: RouteConstant.TeacherRoutes.assignmentCenter,
        },
        {
          name: t("Breadcrumb.Assignments"),
          url: RouteConstant.ASSIGNMENTBANK,
        },
        { name: t("Breadcrumb.ResultsReport"), url: "" },
      ];
    } else if (
      redirectedFrom === constant.RedirectedFrom.MYASSESSMENTS ||
      redirectedFrom === constant.RedirectedFrom.PREMIUMASSESSMENTS
    ) {
      items = [
        { name: t("Breadcrumb.Home"), url: RouteConstant.ROUTE_DASHBOARD },
        {
          name:
            props.userContext.roleId === constant.UserRoleId.District
              ? t("Breadcrumb.AssessmentCenter")
              : t("Breadcrumb.AssessmentAssignmentCenter"),
          url: RouteConstant.TeacherRoutes.assignmentCenter,
        },
        {
          name: t("Breadcrumb.AssessmentBank"),
          url: RouteConstant.ASSESSMENTBANK,
        },
        { name: t("Breadcrumb.ResultsReport"), url: "" },
      ];
    } else {
      items = [
        { name: t("Breadcrumb.Home"), url: RouteConstant.ROUTE_DASHBOARD },
        { name: t("Breadcrumb.ResultsReport"), url: "" },
      ];
    }
    return items;
  };

  const handleToggleLoader = (value: boolean) => {
    setShowLoader(value);
  }

  return (
    <div className="min-w-0 w-full">
      {showLoader && <Loader></Loader>}
      <div className="lg:flex lg:items-center px-4 py-3 lg:px-8 border-b">
        <Breadcrumb items={breadcrumbItems()} />
        <div className="lg:w-[55%] text-lg font-medium flex gap-2 h-auto sm:h-10 items-center">
          <h1 className="text-primary-violet font-medium flex justify-start mb-0 pb-0">
            Results Report
          </h1>
        </div>
      </div>

      <ResultsData
        userContext={props.userContext}
        profile={props.profile}
        reportType={type as ReportType}
        assignmentId={assignmentId}
        assignmentActivityId={assignmentActivityId}
        assessmentId={assessmentId}
        schoolYearId={schoolYearId}
        assessmentSubjectId={assessmentSubjectId}
        classId={parseInt(classId)}
        hasCRActivity={hasCRActivity}
        redirectedFrom={redirectedFrom}
        assignmentName={parameters.get("assignmentName") ?? ""}
        activityType={parameters.get("activityType") ?? ""}
        toggleLoader={handleToggleLoader}
      ></ResultsData>
    </div>
  );
}

export default connect((state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
})(ResultReports);

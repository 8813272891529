import { XIcon } from "@heroicons/react/outline";
import { LogoPLIcon } from "../../../../../assets/icons";

interface PrintConfirmationDialogProp {
  open: boolean;
  onCancelClick: () => void;
  handlePrint?: () => void;
}

export default function PrintConfirmationDialog(
  props: PrintConfirmationDialogProp
) {
  return (
    <div className="fixed inset-0 z-40 flex items-center bg-black bg-opacity-50 sm:items-center sm:justify-center">
      <div
        className="w-full flex flex-col justify-between overflow-hidden h-auto max-h-80percent bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:max-w-6xl"
        role="dialog"
      >
        <header>
          {/* Header */}
          <div className="px-4 py-4 bg-gray-50">
            <div className="flex items-start justify-between space-x-3">
              <div className="space-y-1">
                <div className="text-lg font-medium text-gray-900 flex items-center ">
                  <LogoPLIcon
                    className="block lg:block h-16 w-auto pr-4"
                    title="Progress Learning logo"
                  ></LogoPLIcon>
                </div>
              </div>
              <div className="h-7 flex items-center">
                <button
                  type="button"
                  className="text-gray-400 hover:text-gray-500"
                  onClick={() => props.onCancelClick()}
                >
                  <span className="sr-only">Close panel</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </header>

        {props.open && (
          <div className="mt-2 px-4 py-4">
            <p className="text-sm text-gray-500">
              Printing a Progress Learning assessment is not an ideal delivery
              method. It consumes paper, does not auto-grade, and often has
              layout issues. Students must complete a Progress Learning
              assessment online for you to access all of the data reporting,
              including an item analysis for each question. If you still wish to
              print the assessment, please click the button below to confirm,
              pursuant to our Customer Agreement, that you will not modify,
              publish, transmit, participate in the transfer or sale of,
              reproduce, create new works from, distribute, perform, display, or
              in any way exploit, any of the Content in whole or in part.
            </p>
          </div>
        )}
        {/* <!-- Action Buttons -->  */}
        <footer className="flex flex-col items-center justify-end px-6 py-4 sm:flex-row bg-gray-50 dark:bg-gray-800">
          <div className="flex-shrink-0">
            <div className="space-x-3 flex justify-end">
              <button
                className="ml-4 bg-secondary-teal border border-transparent shadow-sm py-2 px-4 justify-end text-sm font-medium text-white hover:bg-dark-teal hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 whitespace-nowrap"
                type="button"
                onClick={props.handlePrint}
              >
                I AGREE and still wish to print
              </button>
              <button
                className="align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 rounded-lg text-sm text-gray-600 border-gray-300 border dark:text-gray-400 focus:outline-none active:bg-transparent hover:border-gray-500 focus:border-gray-500 active:text-gray-500 focus:shadow-outline-gray w-full sm:w-auto"
                type="button"
                onClick={() => {
                  props.onCancelClick();
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
}

import { Chart as ChartJS, Colors, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(Colors, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

function StudentReportCardGraph({chartOptions, chartData}) {
  return (
    <Line options={chartOptions} data={chartData} />
  )
}

export default StudentReportCardGraph

import { connect } from "react-redux";
import { IBreadcrumb } from "../../../../../model/common/breadcrumb";
import RouteConstant from "../../../../../utils/constant/routeConstant";
import { default as Constant } from "../../../../../utils/constant/constant";
import Breadcrumb from "../../../teacher/shared/breadcrumb";
import { useTranslation } from "react-i18next";
import Asterisk from "../../../../shared/asterisk";
import SelectGroup from "react-select";
import { useEffect, useState } from "react";
import { Select } from "@windmill/react-ui";
import { getSchoolByDistrictId } from "../../../../../api/teacher/school";
import { ISelectElement } from "../../../../../model/interface/selectElement";
import { getAllClassesBySubject } from "../../../../../api/teacher/teacher";
import { getAllGrades } from "../../../../../api/gradeApi";
import { getLiftoffSubjects } from "../../../../../api/student/liftOff/diagnosticApi";
import {
  getLiftoffClassGalaxySummaryReport,
  getLiftoffClassGalaxySummaryReportExcel,
} from "../../../../../api/report/liftoffClassGalaxySummaryReport";
import Loader from "../../../../shared/loader";
import ReactTooltip from "react-tooltip";
import { hasLiftOffAccess } from "../../../../../utils/helper";
import { downloadFile } from "../../../../../api/api";
import ReportButton from "../../shared/reportButton";
import ITeacherClass from "../../../../../model/interface/teacherclass";
import ClassDropDown from "../../shared/classDropDown";
import { ISchool } from "../../../../../model/interface/school";

function LiftoffClassGalaxySummaryReport(props) {
  const { t } = useTranslation();
  const [isDistrictUser] = useState<boolean>(
    props.userContext.roleId === Constant.UserRoleId.District ? true : false
  );
  const [schools, setSchools] = useState<Array<ISelectElement>>([]);
  const [classes, setClasses] = useState<Array<ITeacherClass>>([]);
  const [subjects, setSubjects] = useState<Array<ISelectElement>>([]);
  const [grades, setGrades] = useState<Array<ISelectElement>>([]);
  const [selectedSchool, setSelectedSchool] = useState<string>(
    props.userContext.roleId === Constant.UserRoleId.District
      ? "0"
      : props.userContext.schoolId
  );
  const [selectedClass, setSelectedClass] = useState<string>("0");
  const [selectedSubject, setSelectedSubject] = useState<string>("0");
  const [selectedViewBy, setSelectedViewBy] = useState<string>(
    props.userContext.roleId === Constant.UserRoleId.District ||
      props.userContext.roleId === Constant.UserRoleId.SchoolAdmin
      ? "0"
      : "1"
  );
  const [selectedGrade, setSelectedGrade] = useState<string>("0");
  const [isClassDisabled, setIsClassDisabled] = useState<boolean>(
    props.userContext.roleId !== Constant.UserRoleId.District ? false : true
  );

  const [isSubjectsDisabled, setIsSubjectDisabled] = useState<boolean>(
    props.userContext.roleId !== Constant.UserRoleId.District ? false : true
  );
  const [isGradeDisabled, setIsGradeDisabled] = useState<boolean>(true);
  const [isViewByDisabled, setViewByDisabled] = useState<boolean>(
    props.userContext.roleId !== Constant.UserRoleId.District ? false : true
  );
  const [showReport, setShowReport] = useState<boolean>(false);
  const [noRecordFoundMsg, setNoRecordFoundMsg] = useState<string>("");
  const [reportData, setReportData] = useState<any>([]);
  const [showLoader, setLoader] = useState<boolean>(false);
  const [selectedClassItem, setSelectedClassItem] = useState<ISelectElement>({
    label: "Select Class",
    value: "0",
  });
  const [allSchools, setAllSchools] = useState<ISchool[]>([]);
  const [selectedSchoolAccountId, setSelectedSchoolAccountId] =
    useState<number>(0);
  const breadcrumbItems = () => {
    const items: Array<IBreadcrumb> = [
      { name: t("Breadcrumb.Home"), url: RouteConstant.ROUTE_DASHBOARD },
      { name: t("Breadcrumb.Reports"), url: RouteConstant.REPORTS },
      { name: t("Breadcrumb.LiftoffClassGalaxySummaryReport"), url: "" },
    ];

    return items;
  };
  const nonApplicableGrades: number[] = [1, 2];

  const getSchools = async () => {
    const resp = await getSchoolByDistrictId(
      props.userContext?.districtId ?? 0
    );
    if (resp?.data?.length) {
      let schoolList: Array<ISelectElement> = [];
      resp?.data.forEach((school) => {
        if (!schoolList.find((sl) => sl.value === school.schoolId.toString())) {
          schoolList.push({
            label: school.name,
            value: school.schoolId.toString(),
          });
        }
      });
      setSchools(schoolList);
      setAllSchools(resp?.data);
    }
  };

  const getClasses = async (schoolId: number) => {
    const resp = await getAllClassesBySubject(
      props.userContext.userId,
      props.userContext.roleId,
      null,
      props.profile.currentSchoolYear.schoolYearId,
      schoolId
    );
    setClasses(resp.data.data);
  };

  const getSubjects = async (schoolId: number) => {
    const resp = await getLiftoffSubjects(schoolId);
    if (resp?.data?.length) {
      let subjectList: Array<ISelectElement> = [];
      resp?.data.forEach((subject) => {
        subjectList.push({
          label: subject.name,
          value: subject.id.toString(),
        });
      });
      setSubjects(subjectList);
    }
  };

  const getGrades = async () => {
    const resp = await getAllGrades();
    if (resp?.data?.length) {
      let gradeList: Array<ISelectElement> = [
        {
          label: "All Grades",
          value: "0",
        },
      ];
      resp?.data.forEach((grade) => {
        if (
          !gradeList.find((sl) => sl.value === grade.gradeId.toString()) &&
          nonApplicableGrades.indexOf(grade.gradeId) === -1
        ) {
          gradeList.push({
            label: grade.name,
            value: grade.gradeId.toString(),
          });
        }
      });
      setGrades(gradeList);
    }
  };

  const getReport = () => {
    setLoader(true);
    getLiftoffClassGalaxySummaryReport(
      Number(selectedSchool),
      Number(selectedSubject),
      Number(selectedClass) === 0 ? undefined : Number(selectedClass),
      Number(selectedGrade) === 0 ? undefined : Number(selectedGrade)
    )
      .then((res) => {
        console.log(res.data);
        if (!res.data.data) setNoRecordFoundMsg("No record found");
        setReportData(res.data.data);

        setShowReport(true);
        setLoader(false);
      })
      .catch(() => {
        setLoader(false);
      });
  };

  const onExportReport = () => {
    setLoader(true);
    getLiftoffClassGalaxySummaryReportExcel(
      Number(selectedSchool),
      Number(selectedSubject),
      Number(selectedClass) === 0 ? undefined : Number(selectedClass),
      Number(selectedGrade) === 0 ? undefined : Number(selectedGrade)
    )
      .then((res) => {
        downloadFile(res.data, "LoClassGalaxySummaryReport.xlsx");
        setLoader(false);
      })
      .catch(() => {
        setLoader(false);
      });
  };

  const findSchooltByValue: any = () => {
    if (!selectedSchool || selectedSchool === "0") return null;
    return schools.find((school) => school.value === selectedSchool);
  };

  const findSubjectByValue: any = () => {
    if (!selectedSubject || selectedSubject === "0") return null;
    return subjects.find((subject) => subject.value === selectedSubject);
  };

  const findGradeByValue: any = () => {
    return grades.find((cl) => cl.value === selectedGrade);
  };

  const handleSchoolOnChange = (school: ISelectElement) => {
    if (school.value !== selectedSchool) {
      setClasses([]);
      setSelectedViewBy("0");
      setSelectedClass("0");
      setSelectedGrade("0");
      setSubjects([]);
      setSelectedSubject("0");
      setViewByDisabled(false);
      setIsClassDisabled(false);
      setIsGradeDisabled(false);
      setIsSubjectDisabled(false);

      setSelectedSchool(school.value);
      getClasses(Number(school.value));
      getSubjects(Number(school.value));
      setSelectedSchoolAccountId(
        allSchools.filter((s) => s.schoolId === Number(school.value))[0]
          ?.schoolAccountId ?? 0
      );
    }
  };

  const handleClassOnChange = (cl: ISelectElement) => {
    setSelectedClass(cl.value);
    setSelectedClassItem(cl);
  };

  const handleGradeOnChange = (grade: ISelectElement) => {
    setSelectedGrade(grade.value);
  };

  const handleSubjectOnChange = (subject: ISelectElement) => {
    setSelectedSubject(subject.value);
  };

  const handleViewByOnChange = (e) => {
    setSelectedClass("0");
    setSelectedGrade("0");
    setIsGradeDisabled(false);
    setSelectedViewBy(e.target.value);
  };

  const onReset = () => {
    if (props.userContext.roleId === Constant.UserRoleId.District) {
      setSelectedSchool("0");
      setClasses([]);
      setIsClassDisabled(true);
      setSubjects([]);
      setViewByDisabled(true);
      setIsSubjectDisabled(true);
      setSelectedViewBy("0");
    }
    if (props.userContext.roleId === Constant.UserRoleId.SchoolAdmin) {
      setViewByDisabled(false);
      setIsSubjectDisabled(false);
      setSelectedViewBy("0");
    }
    if (
      props.userContext.roleId !== Constant.UserRoleId.SchoolAdmin &&
      props.userContext.roleId !== Constant.UserRoleId.District
    ) {
      setIsSubjectDisabled(false);
      setSelectedViewBy("1");
    }

    setNoRecordFoundMsg("");
    setSelectedClass("0");
    setSelectedSubject("0");
    setShowReport(false);
    // setPageNumber(DEFAULT_PAGE_NUMBER);
  };

  const onRunReport = () => {
    setNoRecordFoundMsg("");
    setShowReport(false);

    getReport();
  };

  const renderGalaxySummary = (headerId, galaxyId) => {
    if (reportData.galaxySummaries && reportData.galaxySummaries.length > 0) {
      return reportData.galaxySummaries
        .filter(
          (s) =>
            s.liftOffHeaderId === headerId && s.liftOffGalaxyId === galaxyId
        )
        .map((s) => (
          <div className="inline-block bg-gray-100 px-1.5 border-gray-200 border rounded-lg  whitespace-nowrap mr-1.5 mb-1">
            {s.lastName}, {s.firstName}
          </div>
        ));
    }
  };

  const renderDiagnosticSummary = (headerId) => {
    if (
      reportData.diagnosticSummaries &&
      reportData.diagnosticSummaries.length > 0
    ) {
      return reportData.diagnosticSummaries
        .filter((s) => s.liftOffHeaderId === headerId)
        .map((s) => (
          <div className="inline-block bg-gray-100 px-1.5 border-gray-200 border rounded-lg  whitespace-nowrap mr-1.5 mb-1">
            {s.lastName}, {s.firstName}
          </div>
        ));
    }
  };

  useEffect(() => {
    hasLiftOffAccess(props.userContext).then((res) => {
      if (!res) {
        window.location.replace(RouteConstant.FORBIDDEN);
      }
    });
    getSchools();
    getGrades();
    if (props.userContext.roleId !== Constant.UserRoleId.District) {
      getSubjects(props.userContext.schoolId);
      getClasses(props.userContext.schoolId);
    }
  }, []);

  return (
    <div className="min-w-0 w-full">
      <div className="lg:flex lg:items-center px-4 py-3 lg:px-8 border-b">
        {props.userContext.roleId !== Constant.UserRoleId.Student && (
          <Breadcrumb items={breadcrumbItems()} />
        )}

        <div className="lg:w-[55%] text-lg font-medium flex gap-2 h-auto sm:h-10 items-center">
          <h1 className="text-primary-violet font-medium flex justify-start mb-0 pb-0">
            Liftoff Class Galaxy Summary Report
          </h1>
        </div>
      </div>
      <div className="px-2 sm:px-6  lg:px-8 py-1 relative my-4">
        <div className="bg-gray-100 w-full rounded-lg p-5">
          <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-4 mb-4">
            {isDistrictUser && (
              <div className="flex flex-col">
                <label className="block mb-1 text-sm text-gray-500">
                  School <Asterisk />
                </label>
                <SelectGroup
                  placeholder={"Select School"}
                  value={findSchooltByValue()}
                  options={schools}
                  isSearchable={true}
                  onChange={(item) => {
                    handleSchoolOnChange(item);
                  }}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: "#008fbb",
                    },
                  })}
                />
              </div>
            )}
            {(isDistrictUser ||
              props.userContext.roleId === Constant.UserRoleId.SchoolAdmin) && (
              <div className="flex flex-col">
                <label className="block mb-1 text-sm text-gray-500">
                  View By <Asterisk />
                </label>
                <Select
                  value={selectedViewBy}
                  disabled={isViewByDisabled}
                  onChange={(item) => {
                    handleViewByOnChange(item);
                  }}
                  className={
                    "py-2 px-4 block text-sm border border-gray-300 focus:ring-primary-violet focus:border-primary-violet form-select leading-5 p-1 rounded "
                  }
                >
                  <option value={0}>Select</option>
                  <option value={1}>Class</option>
                  <option value={2}>Grade</option>
                </Select>
              </div>
            )}

            {selectedViewBy === "1" && (
              <div className="flex flex-col">
                <label className="block mb-1 text-sm text-gray-500">
                  Class <Asterisk />
                </label>
                <ClassDropDown
                  schoolYearId={props.profile.currentSchoolYear.schoolYearId}
                  defaultLabel={"Select Class"}
                  onChange={(item) => handleClassOnChange(item[0])}
                  value={selectedClassItem}
                  data={classes}
                  isDisabled={isClassDisabled}
                  showArchived={true}
                  showHidden={true}
                  selectedSchoolAccountId={selectedSchoolAccountId}
                ></ClassDropDown>
              </div>
            )}
            {selectedViewBy === "2" && (
              <div className="flex flex-col">
                <label className="block mb-1 text-sm text-gray-500">
                  Grade <Asterisk />
                </label>
                <SelectGroup
                  value={findGradeByValue()}
                  options={grades}
                  isSearchable={true}
                  isDisabled={isGradeDisabled}
                  onChange={(item) => {
                    handleGradeOnChange(item);
                  }}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: "#008fbb",
                    },
                  })}
                />
              </div>
            )}
            <div className="flex flex-col">
              <label className="block mb-1 text-sm text-gray-500">
                Subject <Asterisk />
              </label>
              <SelectGroup
                placeholder={"Select Subject"}
                value={findSubjectByValue()}
                options={subjects}
                isSearchable={true}
                isDisabled={isSubjectsDisabled}
                onChange={(item) => {
                  handleSubjectOnChange(item);
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: "#008fbb",
                  },
                })}
              />
            </div>
          </div>

          <div className="flex gap-4 justify-end">
            <button
              onClick={onReset}
              className="bg-transparent border my-auto border-primary-violet shadow-sm py-2 px-4 flex justify-center text-sm font-medium text-primary-violet hover:text-white hover:bg-light-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3 transition ease-in-out duration-500"
            >
              Reset
            </button>

            <p
              className="cursor-pointer"
              data-tip
              data-for="incompleteSelection"
            >
              {(selectedSchool === "0" ||
                selectedViewBy === "0" ||
                selectedSubject === "0") && (
                <ReactTooltip
                  id="incompleteSelection"
                  class="bg-secondary-teal/10 z-50 "
                  effect="solid"
                >
                  <span>Please complete all required fields</span>
                </ReactTooltip>
              )}

              <div className="cursor-not-allowed">
                <button
                  onClick={onRunReport}
                  className={
                    "bg-primary-violet border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 " +
                    (selectedSchool === "0" ||
                    selectedViewBy === "0" ||
                    selectedSubject === "0"
                      ? "bg-opacity-20 text-primary-violet text-opacity-40 pointer-events-none"
                      : "hover:bg-dark-violet hover:shadow-lg")
                  }
                >
                  Run Report
                </button>
              </div>
            </p>

            <ReportButton
              caption={"Export"}
              disabledTooltip={"Please run the report first"}
              enabled={showReport}
              onClick={onExportReport}
            ></ReportButton>
          </div>

          <div className="border-b border-gray-400 py-2 mb-2"></div>
          {showLoader && <Loader></Loader>}
          {noRecordFoundMsg}
          {showReport && (
            <div className="bg-white px-3 pt-2 pb-3">
              <div className="overflow-x-auto">
                <table className="text-xs text-left rounded-lg w-full">
                  <thead>
                    <tr className="bg-light-violet  text-white py-3 px-3">
                      <th className="min-w-[10rem] p-3 text-sm font-normal align-top">
                        Galaxy
                      </th>
                      {/* dynamic headers */}
                      {reportData.headers &&
                        reportData.headers.length > 0 &&
                        reportData.headers.map((h, index) => {
                          return (
                            <th className="min-w-[10rem] p-3 text-sm font-normal align-top">
                              <div className="flex flex-col">{h.header}</div>
                            </th>
                          );
                        })}
                    </tr>
                  </thead>
                  <tbody>
                    {/* dynamic galaxies */}
                    {reportData.galaxies &&
                      reportData.galaxies.length > 0 &&
                      reportData.galaxies.map((g, index) => (
                        <>
                          <tr
                            className={`hover:bg-primary-violet/20 transition ease-in-out duration-300 ${
                              index % 2 === 0
                                ? "bg-white"
                                : "bg-primary-violet/10"
                            }`}
                          >
                            <td className="p-3 text-sm font-normal align-top">
                              {g.galaxy}
                            </td>

                            {reportData.headers &&
                              reportData.headers.length > 0 &&
                              reportData.headers.map((h, index) => (
                                <td className="p-3 text-sm font-normal align-top">
                                  {renderGalaxySummary(
                                    h.liftOffHeaderId,
                                    g.liftOffGalaxyId
                                  )}
                                </td>
                              ))}
                          </tr>
                        </>
                      ))}

                    {/* Diagnostic not completed */}
                    <tr
                      className={`hover:bg-primary-violet/20 transition ease-in-out duration-300 ${
                        reportData.galaxies.length % 2 === 0
                          ? "bg-white"
                          : "bg-primary-violet/10"
                      }`}
                    >
                      <td className="p-3 text-sm font-normal align-top">
                        DIAGNOSTIC NOT COMPLETED
                      </td>

                      {reportData.headers &&
                        reportData.headers.length > 0 &&
                        reportData.headers.map((h, index) => (
                          <td className="p-3 text-sm font-normal align-top">
                            {renderDiagnosticSummary(h.liftOffHeaderId)}
                          </td>
                        ))}
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default connect((state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
})(LiftoffClassGalaxySummaryReport);

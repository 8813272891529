import { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  getStudyPlanStandards,
  getInProgressDomainTests,
  getInProgressSummativeTests,
  getFocusAreas,
} from "../../../../../api/student/studyPlanApi";
import { UserContextState } from "../../../../../redux/actions/userContextAction";
import {
  IStudyPlanStandard,
  IDomainStandardGroup,
} from "../../../../../model/interface/studyPlanStandard";
import Loader from "../../../../shared/loader";
import classNames from "classnames";
import {
  StarDarkIcon,
  StarIcon,
  PlayIconFilled,
  PracticeTest,
  ProgressReportIcon,
} from "../../../../../assets/icons";
import { PlayIcon } from "@heroicons/react/outline";
import { useTranslation } from "react-i18next";
import Profile from "../../../../../model/users/profile";
import constant from "../../../../../utils/constant/constant";
import { Link, useHistory } from "react-router-dom";
import RouteConstant from "../../../../../utils/constant/routeConstant";
import { getStudyplanAssignmentStatusClassname } from "../../../../../utils/assignmentHelper";
import { paramEncode } from "../../../../../utils/urlHelper";
import {
  ICannedTest,
  IDomainTest,
  ISummativeTest,
} from "../../../../../model/interface/summativeTest/summativeTest";
import StudyPlanSubjectTestPopup from "../../shared/popups/studyPlanSubjectTestPopup";
import {
  getCannedTests,
  getInProgressCannedTests,
} from "../../../../../api/student/cannedTestApi";
import { getSubjectDetail } from "../../../../../api/subjectAPI";
import StudyPlanSubjectTests from "../../shared/studyPlanSubjectTest";
import { verifySubjectNeedsCannedTest } from "../../../../../utils/helper";
import { IStudyPlanFocusAreasStandard } from "../../../../../model/interface/studyPlanFocusAreas";
import StudyPlanVideoTestPopup from "../../shared/popups/studyPlanVideoTestsPopup";
import { IStudentVideoAssignment } from "../../../../../model/interface/studentVideoAssignment";
import { getVideoAssignment } from "../../../../../api/student/studentVideoAssignmentApi";
import { IGrade } from "../../../../../model/interface/grade";

interface IStudyPlanStandardProps {
  subjectId: number;
  userContext?: UserContextState;
  profile?: Profile;
  subjectName?: string;
}

function StudyPlanStandard(props: IStudyPlanStandardProps) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [standardGroups, setStandardGroups] = useState<
    Array<IDomainStandardGroup>
  >([]);
  const [focusAreas, setFocusAreas] = useState<
    Array<IStudyPlanFocusAreasStandard>
  >([]);
  const [selectedStudyType, setSelectedStudyType] = useState<string>(
    constant.StudyType.MYSTUDYPLAN
  );
  const [showFocusAreas, setShowFocusAreas] = useState<boolean>(false);
  const [pendingPracticeTests, setPendingPracticeTests] = useState(0);
  const [domainTestsInProgress, setDomainTestsInProgress] = useState<
    IDomainTest[]
  >([]);
  const [cannedTestsInProgress, setCannedTestsInProgress] = useState<
    ICannedTest[]
  >([]);
  const [openSubjectTestModal, setOpenSubjectTestModal] =
    useState<boolean>(false);
  const [openVideoTestModal, setOpenVideoTestModal] = useState<boolean>(false);
  const [selectedStandardName, setSelectedStandardName] = useState<string>("");
  const [standardVideos, setStandardVideos] = useState<
    Array<IStudentVideoAssignment>
  >([]);
  const [smallSummativeTest, setSmallSummativeTest] =
    useState<ISummativeTest>();
  const [mediumSummativeTest, setMediumSummativeTest] =
    useState<ISummativeTest>();
  const [largeSummativeTest, setLargeSummativeTest] =
    useState<ISummativeTest>();
  const [cannedTests, setCannedTests] = useState<Array<any>>([]);
  const [allowCannedTest, setAllowCannedTest] = useState<boolean>(false);
  const [subjectGrades, setSubjectGrades] = useState<Array<IGrade>>([]);
  const themeName = "ELEMENTARY";

  useEffect(() => {
    setSelectedStudyType(constant.StudyType.MYSTUDYPLAN);
    loadSubjectData();
  }, [props.subjectId]);

  async function loadSubjectData() {
    try {
      setLoading(true);
      await verifyTests(props.subjectId);
      await loadAllStudyPlanStandards();
      await getPendingPracticeTests();
      await getFocusAreas(
        props?.userContext?.userId ?? 0,
        props.subjectId
      ).then((d) => {
        setShowFocusAreas(d.data.length !== 0);
        setFocusAreas(d.data);
        if (d.data.length === 0) {
          setSelectedStudyType(constant.StudyType.MYSTUDYPLAN);
        }
      });
    } finally {
      setLoading(false);
    }
  }

  async function loadAllStudyPlanStandards() {
    await getStudyPlanStandards(
      props.userContext?.userId ?? 0,
      props.subjectId,
      false,
      props.userContext?.schoolId ?? 0
    ).then((d) => {
      convertToStandardGroup(d.data);
    });
  }

  function convertToStandardGroup(standards: Array<IStudyPlanStandard>) {
    const domains = Array.from(new Set(standards.map((item) => item.domainId)));
    var tempStandardGroup: Array<IDomainStandardGroup> = [];
    domains.forEach((d) => {
      var domain = standards.find((t) => t.domainId === d);
      var standardGroup: IDomainStandardGroup = {
        domainName:
          props.profile?.plLanguageId === constant.Languages.Spanish
            ? domain?.spanishDomainName ?? domain?.domainName ?? ""
            : domain?.domainName ?? "",
        domainId: parseInt(d),
        standards: standards.filter((s) => s.domainId === d),
      };
      tempStandardGroup.push(standardGroup);
    });
    setStandardGroups(tempStandardGroup);
  }
  function getAssignmentStatus(standard: IStudyPlanStandard) {
    const assignmentStatusId = standard.assignmentStatusId;
    const videoAssignmentStatusId = standard.videoAssignmentStatusId;
    const completedAssignments = standard.completedAssignments;
    const completedVideoQuestions = standard.completedVideoQuestions;
    const totalVideoQuestions = standard.totalVideoQuestions;
    const earnedStar = standard.earnedStar;

    if (
      completedAssignments > 0 &&
      completedVideoQuestions === totalVideoQuestions &&
      earnedStar >= 2
    ) {
      return t("StudyPlan.Completed");
    }

    if (assignmentStatusId === 0 && videoAssignmentStatusId === 0) {
      return t("StudyPlan.NotStarted");
    } else if (assignmentStatusId === 1 && videoAssignmentStatusId === 1) {
      return t("StudyPlan.New");
    } else if (assignmentStatusId === 2 || videoAssignmentStatusId === 2) {
      return t("StudyPlan.InProgress");
    } else if (assignmentStatusId === 3 && videoAssignmentStatusId === 3) {
      return t("StudyPlan.InProgress");
    } else if (assignmentStatusId === 0 && videoAssignmentStatusId === 3) {
      return t("StudyPlan.InProgress");
    } else if (assignmentStatusId === 3 && videoAssignmentStatusId === 0) {
      return t("StudyPlan.InProgress");
    } else {
      return "";
    }
  }

  function getStandardName(standard: any) {
    if (
      props.profile?.plLanguageId === constant.Languages.Spanish &&
      (standard.spanishStandardName !== null ||
        standard.spanishStandardName !== undefined)
    )
      return (
        standard.spanishStandardName ?? standard.standardName ?? standard.name
      );
    else return standard.standardName ?? standard.name;
  }

  function redirectVideoAssignment(standard: IStudyPlanStandard) {
    history.push(
      RouteConstant.StudentRoute.VideoQuestionList.replace(
        ":standardId",
        encodeURI(btoa(standard.standardId.toString()))
      )
        .replace(
          ":standardName",
          encodeURI(
            btoa("[" + standard.standardCode + "] " + getStandardName(standard))
          )
        )
        .replace(":subjectId", encodeURI(btoa(props.subjectId.toString())))
    );
  }

  function openVideoTestPopup(standard: IStudyPlanFocusAreasStandard) {
    setLoading(true);
    setSelectedStandardName(standard.standardName);
    getVideoAssignment(
      props.userContext?.userId ?? 0,
      props.subjectId,
      standard.standardId
    )
      .then((d) => {
        setStandardVideos(d.data);
      })
      .finally(() => {
        setLoading(false);
        setOpenVideoTestModal(true);
      });
  }

  function startVideoPractice(video: IStudentVideoAssignment) {
    history.push({
      pathname: RouteConstant.StudentRoute.VideoQuestionPractice.replace(
        ":standardName",
        encodeURI(btoa(selectedStandardName))
      )
        .replace(":subjectId", encodeURI(btoa(props.subjectId.toString())))
        .replace(":standardId", encodeURI(btoa(video.standardId.toString())))
        .replace(":videoId", encodeURI(btoa(video.videoId.toString()))),
      state: {
        videoTitle:
          props.profile?.plLanguageId === constant.Languages.Spanish
            ? video.spanishVideoTitle ?? video.videoTitle
            : video.videoTitle,
      },
    });
  }

  function startStudyPlan(
    standard: any,
    isRecommendedStandard: boolean = false
  ) {
    if (
      standard.assignmentStatusId === constant.AssignmentStatusId.INPROGRESS
    ) {
      history.push(
        RouteConstant.StudentRoute.StudyPlan.replace(
          ":standardId",
          encodeURI(btoa(standard.standardId.toString()))
        )
          .replace(
            ":standardName",
            encodeURI(paramEncode(getStandardName(standard)))
          )
          .replace(":gameId", encodeURI(btoa("0")))
          .replace(":rocketId", encodeURI(btoa("0")))
          .replace(":blasterId", encodeURI(btoa("0")))
          .replace(":subjectId", encodeURI(btoa(props.subjectId.toString())))
          .replace(":blasterType", encodeURI(btoa("0")))
          .replace(
            ":isRecommendedStandard",
            encodeURI(btoa(isRecommendedStandard.toString()))
          )
      );
    } else {
      var route = RouteConstant.StudentRoute.GameChoose.replace(
        ":standardId",
        encodeURI(btoa(standard.standardId.toString()))
      )
        .replace(":subjectId", encodeURI(btoa(props.subjectId.toString())))
        .replace(
          ":standardName",
          encodeURI(paramEncode(getStandardName(standard)))
        )
        .replace(
          ":mode",
          encodeURI(btoa(constant.GameChooseMode.ElementaryStudyPlan))
        )
        .replace(
          ":isRecommendedStandard",
          encodeURI(btoa(isRecommendedStandard.toString()))
        );
      history.push(route);
    }
  }

  async function getPendingPracticeTests() {
    await Promise.all([
      getInProgressSummativeTests(
        props?.userContext?.userId ?? 0,
        props.subjectId
      ),
      getInProgressDomainTests(
        props?.userContext?.userId ?? 0,
        props.subjectId
      ),
      getInProgressCannedTests(props?.userContext?.userId ?? 0),
    ]).then(([summativeTests, domaintests, cannedTests]) => {
      var summativeTestCount = summativeTests.data
        ? summativeTests.data.length
        : 0;

      const smallTest = summativeTests.data.find((t) => t.testSize === 1);
      if (smallTest) setSmallSummativeTest(smallTest);

      const mediumTest = summativeTests.data.find((t) => t.testSize === 2);
      if (mediumTest) setMediumSummativeTest(mediumTest);

      const largeTest = summativeTests.data.find((t) => t.testSize === 3);
      if (largeTest) setLargeSummativeTest(largeTest);

      const cannedTestsProgress = (cannedTests.data as ICannedTest[]).filter(
        (t) => t.subjectId === props.subjectId
      );

      setPendingPracticeTests(summativeTestCount);
      setDomainTestsInProgress(domaintests.data);
      setCannedTestsInProgress(cannedTestsProgress);
    });
  }

  async function verifyTests(subjectId: number) {
    if (subjectId <= 0) return;
    await getSubjectDetail(subjectId).then((d) => {
      var subjectName = d.data.name.toUpperCase();
      setSubjectGrades((d.data as any)?.grades ?? []);
      const cannedTestShouldBeCalled =
        verifySubjectNeedsCannedTest(subjectName);
      if (cannedTestShouldBeCalled) {
        getCannedTests(props?.userContext?.userId ?? 0, subjectId, false).then(
          (d) => {
            setCannedTests(d.data);
          }
        );
      } else {
        setCannedTests([]);
      }
      setAllowCannedTest(cannedTestShouldBeCalled);
    });
  }

  function isDomainTestInProgress(domainId: number) {
    return domainTestsInProgress?.find((t) => t.domainId === domainId) != null;
  }

  function isSubjectTestInProgress() {
    if (allowCannedTest) {
      return (
        cannedTests &&
        cannedTests.find(
          (t) =>
            t.statusId > 1 &&
            t.statusId !== constant.AssignmentStatusId.COMPLETE
        ) != null
      );
    }
    return pendingPracticeTests > 0;
  }

  function startDomainTest(domainId: number, name: string) {
    history.push({
      pathname: RouteConstant.StudentRoute.DomainTest.replace(
        ":subjectId",
        encodeURI(btoa(props.subjectId.toString()))
      ).replace(":domainId", encodeURI(btoa(domainId.toString()))),
      state: { domainName: name, subjectName: props.subjectName },
    });
  }

  function startSummativeTest(testSize: number) {
    history.push({
      pathname: RouteConstant.StudentRoute.SummativeTest.replace(
        ":subjectId",
        encodeURI(btoa(props.subjectId.toString()))
      ).replace(":testSize", encodeURI(btoa(testSize.toString()))),
      state: { subjectName: props.subjectName },
    });
  }

  function startCannedTest(cannedTestId: number, learnosityReferenceId) {
    const cannedTest = cannedTests.filter(
      (t) => t.cannedTestId === cannedTestId
    )[0];

    history.push({
      pathname: RouteConstant.StudentRoute.CannedTestGC.replace(
        ":subjectId",
        encodeURI(btoa(props.subjectId.toString()))
      )
        .replace(":cannedTestId", encodeURI(btoa(cannedTestId.toString())))
        .replace(
          ":learnosityReferenceId",
          encodeURI(btoa(learnosityReferenceId))
        ),
      state: {
        cannedTestTypeId: cannedTest.cannedTestTypeId,
        subjectName: cannedTest.subjectName,
      },
    });
  }

  function isLastChild(index: number): boolean {
    return index === focusAreas.length - 1;
  }

  function getBorderColor(standardStatus: string, index: number): string {
    const borderColor =
      standardStatus === constant.FocusAreaStatusType.CRITICAL
        ? "border-[#E53F71]"
        : "border-[#FFDB3A]";
    if (
      !isLastChild(index) &&
      focusAreas[index + 1].standardStatus === standardStatus
    ) {
      return `border-t-4 ${borderColor}`;
    }
    return `border-y-4 ${borderColor}`;
  }

  return (
    <Fragment>
      <div>
        {loading && <Loader />}
        <div className="pt-1 w-full mb-4 flex justify-between">
          <div className="inline-block overflow-hidden border-2 border-white border-opacity-70 mb-auto rounded-lg bg-black bg-opacity-30">
            <button
              className={classNames(
                selectedStudyType === constant.StudyType.MYSTUDYPLAN
                  ? "text-white bg-black bg-opacity-30 font-semibold transition-all"
                  : "text-white text-opacity-90 transition-all inner-btn-shadow font-semibold hover:text-opacity-100",
                " inline-block whitespace-nowrap text-base transition-all text-center pb-2.5 pt-3 px-8 tracking-wide"
              )}
              onClick={() => {
                setSelectedStudyType(constant.StudyType.MYSTUDYPLAN);
              }}
            >
              {t("StudyPlan.StudyPlan")}
            </button>
            {showFocusAreas && (
              <button
                className={classNames(
                  selectedStudyType === constant.StudyType.FOCUSAREAS
                    ? "text-white bg-black bg-opacity-30 font-semibold transition-all"
                    : "text-white text-opacity-90 transition-all font-semibold inner-btn-shadow hover:text-opacity-100",
                  " inline-block whitespace-nowrap text-base transition-all text-center pb-2.5 pt-3 px-8 tracking-wide"
                )}
                onClick={() => {
                  setSelectedStudyType(constant.StudyType.FOCUSAREAS);
                }}
              >
                {t("StudyType.FocusAreas")}
              </button>
            )}
          </div>
          <div className="flex mb-2">
            <Link
              className="mr-4"
              to={
                RouteConstant.StudentRoute.ProgressReport.replace(
                  ":subjectId",
                  ""
                ) + props.subjectId
              }
            >
              <div className="relative flex items-center bg-black bg-opacity-30 hover:bg-opacity-40 cursor-pointer rounded-md px-2 py-2 shadow-xl">
                <span className="bg-white w-6 h-6 rounded-md flex items-center justify-center relative mr-2">
                  <ProgressReportIcon
                    title="Progress report"
                    className="w-4 stroke-current text-site-pink"
                  />
                </span>
                <span className="text-sm font-medium text-white capitalize">
                  {t("StudyPlan.ProgressReport")}
                </span>
              </div>
            </Link>
            {openSubjectTestModal && (
              <StudyPlanSubjectTestPopup
                onClose={() => setOpenSubjectTestModal(false)}
                themeName={themeName}
              >
                <StudyPlanSubjectTests
                  smallSummativeTest={smallSummativeTest}
                  mediumSummativeTest={mediumSummativeTest}
                  largeSummativeTest={largeSummativeTest}
                  cannedTests={cannedTests}
                  cannedTestsInProgress={cannedTestsInProgress}
                  startSummativeTest={startSummativeTest}
                  startCannedTest={startCannedTest}
                  themeName={themeName}
                  subjectGrades={subjectGrades}
                />
              </StudyPlanSubjectTestPopup>
            )}
            {openVideoTestModal && (
              <StudyPlanVideoTestPopup
                standardVideos={standardVideos}
                standardName={selectedStandardName}
                themeName={themeName}
                userContext={props.userContext}
                profile={props.profile}
                onClose={() => {
                  setOpenVideoTestModal(false);
                  setSelectedStandardName("");
                  setStandardVideos([]);
                }}
                startVideoPractice={startVideoPractice}
              />
            )}
            <div
              className="relative flex items-center bg-black bg-opacity-30 hover:bg-opacity-40 cursor-pointer rounded-md px-2 py-2 shadow-xl"
              onClick={() => {
                setOpenSubjectTestModal(true);
              }}
            >
              <span className="bg-white w-6 h-6 rounded-md flex items-center justify-center relative mr-2">
                <PracticeTest
                  className="h-4 stroke-current text-site-pink"
                  title={t("StudyPlan.SubjectTests")}
                />
              </span>
              {isSubjectTestInProgress() && (
                <label className="absolute -top-1 -left-0.5 w-3 h-3 flex items-center justify-center text-gray-700 font-semibold text-xs inline-block cursor-pointer bg-yellow-400 border border-yellow-500 hover:opacity-90 rounded-full"></label>
              )}
              <span className="text-sm font-medium text-white capitalize">
                {t("StudyPlan.SubjectTests")}
              </span>
            </div>
          </div>
        </div>
        {selectedStudyType === constant.StudyType.MYSTUDYPLAN && (
          <Fragment>
            {standardGroups.map((group, index) => {
              return (
                <Fragment key={index}>
                  <div className="flex justify-between items-end pb-1">
                    <span className="text-lg font-semibold inline-block text-black opacity-70">
                      {group.domainName}
                    </span>
                    {!allowCannedTest && (
                      <span
                        className="bg-white p-2 mb-1 rounded-md flex items-center justify-start relative hover:opacity-90 cursor-pointer"
                        onClick={() => {
                          startDomainTest(group.domainId, group.domainName);
                        }}
                      >
                        <PracticeTest
                          className="h-4 stroke-current text-site-pink"
                          title="practice test"
                        />
                        <span className="px-2 text-gray-700 text-sm text-gray-500 ">
                          {t("StudyPlan.DomainPracticeTest")}
                        </span>
                        {isDomainTestInProgress(group.domainId) && (
                          <label className="absolute -top-1 -left-1 w-3 h-3 flex items-center justify-center text-gray-700 font-semibold font-lg inline-block cursor-pointer bg-yellow-400 border border-yellow-500 hover:opacity-90 rounded-full"></label>
                        )}
                      </span>
                    )}
                  </div>
                  <div
                    className={classNames(
                      "overflow-x-auto rounded-lg mb-5 border-4 border-[#12B481] shadow-xl"
                    )}
                  >
                    <table className="min-w-full divide-y divide-gray-200 ">
                      <thead className="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            className="px-3 py-3 w-20 text-left text-xs font-medium text-gray-500 capitalize tracking-wider bg-gray-100"
                          >
                            {t("StudyPlan.Standard")}
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3 w-60 text-left text-xs font-medium text-gray-500 capitalize tracking-wider bg-gray-100"
                          >
                            {t("StudyPlan.Name")}
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3 text-center text-xs font-medium text-gray-500 capitalize tracking-wider bg-gray-100"
                          >
                            {t("StudyPlan.Status")}
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3 text-center text-xs font-medium text-gray-500 capitalize tracking-wider bg-gray-100"
                          >
                            {t("StudyPlan.Learn")}
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3 text-center text-xs font-medium text-gray-500 capitalize tracking-wider bg-gray-100"
                          >
                            {t("StudyPlan.Practice")}
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3 text-center text-xs font-medium text-gray-500 capitalize tracking-wider bg-gray-100"
                          >
                            {t("StudyPlan.GalaxyStar")}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {group.standards.map((standard, index) => (
                          <tr
                            key={index}
                            className={
                              index % 2 === 0
                                ? "bg-white"
                                : "bg-white bg-opacity-90"
                            }
                          >
                            <td className="group px-3 py-3 cursor-pointer text-sm text-gray-500   ">
                              {standard.standardCode}
                              {standard.standardDescription && (
                                <div className="hidden group-hover:block absolute w-72 bg-yellow-200 rounded-md shadow-xl p-3 z-10 text-gray-700">
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: standard.standardDescription,
                                    }}
                                  ></span>
                                </div>
                              )}
                            </td>
                            <td className="px-3 py-3 text-sm text-gray-500">
                              {getStandardName(standard)}
                            </td>
                            <td className="px-3 py-3 text-sm text-gray-500">
                              <div className="w-full flex justify-center">
                                <span
                                  className={`text-white px-3 py-0.5 whitespace-pre text-xs rounded-xl text-center ${getStudyplanAssignmentStatusClassname(
                                    standard
                                  )}`}
                                >
                                  {getAssignmentStatus(standard)}
                                </span>
                              </div>
                            </td>
                            <td
                              className="px-3 py-3 text-sm text-gray-500"
                              title={t("StudyPlan.Learn")}
                            >
                              {standard.totalVideoQuestions > 0 && (
                                <button
                                  className="flex items-center mx-auto w-full max-w-[10rem] justify-center bg-blues hover:bg-opacity-80 px-2 text-white text-xs py-1 rounded-lg p-1 space-x-0.5"
                                  onClick={() => {
                                    redirectVideoAssignment(standard);
                                  }}
                                >
                                  <span className="whitespace-pre">
                                    {t("StudyPlan.Learn")}{" "}
                                    {standard.completedVideoQuestions}/
                                    {standard.totalVideoQuestions}
                                  </span>
                                  <PlayIconFilled className="w-3.5 cursor-pointer fill-white" />
                                </button>
                              )}
                            </td>

                            <td
                              className="px-3 py-3  text-sm text-gray-500 text-center"
                              title={t("StudyPlan.StartStudyPlan")}
                            >
                              <button
                                className={classNames(
                                  "flex items-center mx-auto w-full max-w-[10rem] justify-center  hover:bg-opacity-80  px-2  text-xs py-1 text-white rounded-lg p-1 space-x-0.5",
                                  standard.assignmentStatusId ===
                                    constant.AssignmentStatusId.INPROGRESS
                                    ? "bg-blue-500"
                                    : "bg-blues"
                                )}
                                onClick={() => {
                                  startStudyPlan(standard);
                                }}
                              >
                                {standard.assignmentStatusId ===
                                  constant.AssignmentStatusId.INPROGRESS && (
                                  <span>{t("StudyPlan.Resume")}</span>
                                )}

                                {standard.assignmentStatusId !==
                                  constant.AssignmentStatusId.INPROGRESS && (
                                  <span className="mr-0.5">
                                    {t("StudyPlan.Start")}
                                  </span>
                                )}

                                <PlayIcon className="w-4 cursor-pointer" />
                              </button>
                            </td>
                            <td className="px-3 py-3 text-sm text-gray-500">
                              <div className=" text-sm text-gray-500 flex justify-center gap-1">
                                {standard.earnedStar === 0 && (
                                  <>
                                    {" "}
                                    <StarIcon
                                      title={t("GalaxyStars.GalaxyStar")}
                                      className="w-5 text-gray-500"
                                    />
                                    <StarIcon
                                      title={t("GalaxyStars.GalaxyStar")}
                                      className="w-5 text-gray-500"
                                    />
                                    <StarIcon
                                      title={t("GalaxyStars.GalaxyStar")}
                                      className="w-5 text-gray-500"
                                    />
                                  </>
                                )}
                                {standard.earnedStar === 1 && (
                                  <>
                                    {" "}
                                    <StarDarkIcon
                                      title={t("GalaxyStars.GalaxyStar")}
                                      className="w-5 fill-current text-[#f7c602]"
                                    />
                                    <StarIcon
                                      title={t("GalaxyStars.GalaxyStar")}
                                      className="w-5 text-gray-500"
                                    />
                                    <StarIcon
                                      title={t("GalaxyStars.GalaxyStar")}
                                      className="w-5 text-gray-500"
                                    />
                                  </>
                                )}
                                {standard.earnedStar === 2 && (
                                  <>
                                    {" "}
                                    <StarDarkIcon
                                      title={t("GalaxyStars.GalaxyStar")}
                                      className="w-5 fill-current text-[#f7c602]"
                                    />
                                    <StarDarkIcon
                                      title={t("GalaxyStars.GalaxyStar")}
                                      className="w-5 fill-current text-[#f7c602]"
                                    />
                                    <StarIcon
                                      title="Galaxy star"
                                      className="w-5 text-gray-500"
                                    />
                                  </>
                                )}
                                {standard.earnedStar === 3 && (
                                  <>
                                    {" "}
                                    <StarDarkIcon
                                      title={t("GalaxyStars.GalaxyStar")}
                                      className="w-5 fill-current text-[#f7c602]"
                                    />
                                    <StarDarkIcon
                                      title={t("GalaxyStars.GalaxyStar")}
                                      className="w-5 fill-current text-[#f7c602]"
                                    />
                                    <StarDarkIcon
                                      title={t("GalaxyStars.GalaxyStar")}
                                      className="w-5 fill-current text-[#f7c602]"
                                    />
                                  </>
                                )}
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </Fragment>
              );
            })}
          </Fragment>
        )}

        {selectedStudyType === constant.StudyType.FOCUSAREAS && (
          <Fragment>
            <div className="flex flex-col">
              <div className="h-auto mr-auto py-3 px-4 w-auto sm:w-100 bg-black bg-opacity-20 rounded-2xl space-y-2 mt-2 mb-3">
                <ul className="block sm:flex items-center flex-wrap text-white text-sm gap-x-2.5 gap-y-1 mb-2">
                  <li className="block sm:inline-flex items-center text-sm flex md:flex-none">
                    <label className="bg-[#FFDB3A] p-2 rounded mr-1.5"> </label>
                    {t("StudyPlan.ApproachingMastery")}
                  </li>
                  <li className="block sm:inline-flex items-center text-sm flex md:flex-none">
                    <label className="bg-[#E53F71] p-2 rounded mr-1.5"> </label>
                    {t("Diagnostics.Critical")}
                  </li>
                </ul>
                <label className="block sm:inline-flex items-center text-xs flex md:flex-none p-1 text-white rounded inline-block px-2">
                  {t("StudyPlan.Earn80%OrMore")}
                </label>
              </div>
              <div>
                {focusAreas.length === 0 && (
                  <div className="mt-10">
                    <h1 className="text-lg text-gray-900 mb-1 inline-block">
                      {t("StudyPlan.NoFocusAreasActivities")}
                    </h1>
                  </div>
                )}

                {focusAreas.length > 0 && (
                  <div
                    className={classNames(
                      "overflow-x-auto rounded-lg mb-5 bg-gray-50"
                    )}
                  >
                    <table
                      className="min-w-full border-separate"
                      cellSpacing={0}
                    >
                      <thead className="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            className="px-3 py-3 w-20 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            {t("StudyPlan.Standard")}
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3 w-56 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            {t("StudyPlan.Name")}
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            {t("StudyPlan.Learn")}
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            {t("StudyPlan.Practice")}
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            <div>{t("StudyPlan.OverallScore")}</div>
                            <div className="text-[12px]">
                              ({t("StudyPlan.AllActivities")})
                            </div>
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            {t("StudyPlan.QuestionsAnswered")}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {focusAreas.map((standard, index) => (
                          <tr key={index} className={"bg-white"}>
                            <td
                              className={
                                "border-l-4 " +
                                getBorderColor(standard.standardStatus, index) +
                                (isLastChild(index) ? " rounded-bl-lg" : "") +
                                " px-3 py-4 group text-sm text-gray-500"
                              }
                            >
                              {standard.standardCode}
                              {standard.description && (
                                <div className="hidden group-hover:block absolute w-72 bg-yellow-200 rounded-md shadow-xl p-3 z-10 text-gray-700">
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: standard.description,
                                    }}
                                  ></span>
                                </div>
                              )}
                            </td>
                            <td
                              className={`${getBorderColor(
                                standard.standardStatus,
                                index
                              )} px-3 py-2 text-sm text-gray-500`}
                            >
                              {standard.standardName}
                            </td>
                            <td
                              className={`${getBorderColor(
                                standard.standardStatus,
                                index
                              )} px-3 py-4 justify-center text-sm text-gray-500 space-x-1`}
                            >
                              {standard.totalVideoQuestions > 0 && (
                                <button
                                  className="flex items-center mx-auto w-full max-w-[10rem] justify-center bg-blues hover:bg-opacity-80 px-2 text-white text-xs py-1 rounded-lg p-1 space-x-0.5"
                                  onClick={() => {
                                    openVideoTestPopup(standard);
                                  }}
                                >
                                  <span className="whitespace-pre">
                                    {t("StudyPlan.Learn")}{" "}
                                    {standard.completedVideoQuestions}/
                                    {standard.totalVideoQuestions}
                                  </span>
                                  <PlayIconFilled className="w-3.5 cursor-pointer fill-white" />
                                </button>
                              )}
                            </td>
                            <td
                              className={`${getBorderColor(
                                standard.standardStatus,
                                index
                              )} px-3 py-2 text-sm text-gray-500`}
                            >
                              <button
                                className={classNames(
                                  "flex items-center mx-auto w-full max-w-[10rem] justify-center  hover:bg-opacity-80  px-2  text-xs py-1 text-white rounded-lg p-1 space-x-0.5",
                                  standard.assignmentStatusId ===
                                    constant.AssignmentStatusId.INPROGRESS
                                    ? "bg-blue-500"
                                    : "bg-blues"
                                )}
                                onClick={() => {
                                  startStudyPlan(standard, true);
                                }}
                              >
                                {standard.assignmentStatusId ===
                                  constant.AssignmentStatusId.INPROGRESS && (
                                  <span>{t("StudyPlan.Resume")}</span>
                                )}

                                {standard.assignmentStatusId !==
                                  constant.AssignmentStatusId.INPROGRESS && (
                                  <span className="mr-0.5">
                                    {t("StudyPlan.Start")}
                                  </span>
                                )}

                                <PlayIcon className="w-4 cursor-pointer" />
                              </button>
                            </td>

                            <td
                              className={`${getBorderColor(
                                standard.standardStatus,
                                index
                              )} px-3 py-2 text-sm text-gray-500 text-center`}
                            >
                              {standard.overallScore} %
                            </td>
                            <td
                              className={
                                "border-r-4 " +
                                getBorderColor(standard.standardStatus, index) +
                                (isLastChild(index) ? " rounded-br-lg" : "") +
                                " px-3 py-2 text-sm text-center text-gray-500"
                              }
                            >
                              {standard.totalQuestionsAnswered}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
          </Fragment>
        )}
      </div>
    </Fragment>
  );
}
const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(StudyPlanStandard);

import Subject from "../../../../shared/dropdowns/subject"
import AnglerfishButton from "./anglerfishButton"
import { ISelectedStandard, IDomain, IStandard } from "../../../../../model/interface/classroomGame/thinkOrSwimSetup";
import { Fragment, useEffect, useState } from "react";
import { getAllDomainsBySubject } from "../../../../../api/teacher/assignment";
import { toast } from "react-toastify";
import Loader from "../../../../shared/loader";
import { DownArrowIcon, UpArrow } from "../../../../../assets/icons";
import {getStandardsWithQuestionFilters} from "../../../../../api/classroomGame/thinkOrSwim";

interface ITOSGameSetupPickContentProps {
  selectedSubject: Array<{label: string, value: number }>,
  setSelectedSubject: (selectedSubject: Array<{label: string, value: number}>) => void,
  selectedStandards: ISelectedStandard[],
  setSelectedStandards: (selectedStandards: ISelectedStandard[]) => void,
  onContinue: () => void
}

function TOSGameSetupPickContent(props: ITOSGameSetupPickContentProps) {

  const {
    selectedSubject,
    setSelectedSubject,
    selectedStandards,
    setSelectedStandards,
    onContinue
  } = props;

  const [domains, setDomains] = useState<Array<IDomain>>([]);
  const [showLoading, setShowLoading] = useState<boolean>(false);

  const getStandards = (domainIds: string) => {
    setShowLoading(true);
    getStandardsWithQuestionFilters(domainIds.split(",").map(n => parseInt(n)), true, 5).then(response => {
      setShowLoading(false);
      const standards: any = response.data.data;
      const domainsWithStandards: Array<IDomain> = standards.map((domain: any) => ({
        domainId: domain.domainId,
        domainName: domain.domainName,
        collapsed: true,
        standards: domain.standards.map((standard: any) => ({
          standardId: standard.id,
          standardName: standard.name,
          code: standard.code,
          description: standard.description
        }))
      }))
      setDomains(domainsWithStandards);
    }).catch(error => {
      setShowLoading(false);
      toast.error(`${error.message}: Cannot retrieve standards`);
      console.error({error})
    });
  }

  const getDomains = (subjects: Array<{label: string, value: number}>) => {
    setSelectedSubject(subjects);
    
    if (subjects.length === 0 || !subjects[0].value) {
      setDomains([]);
      return;
    }
    
    setShowLoading(true);
    const subjectId: number = subjects[0].value;
    getAllDomainsBySubject(subjectId).then(response => {
      setShowLoading(false);
      const domainIds: string = response.data.data.map((domain: any) => domain.domainId).join(',');
      getStandards(domainIds);
    }).catch(error => {
      setShowLoading(false);
      toast.error(`${error.message}: Cannot retrieve domains`);
      console.error({error})
    });
  }

  const handleSubjectChange = (subjects: Array<{label: string, value: number}>) => {
    if(
      subjects.length > 0 
      && (selectedSubject.length === 0 
        || (selectedSubject.length > 0 
        && subjects[0].value !== selectedSubject[0].value
      ))
    ) {
      setSelectedStandards([]);
      getDomains(subjects);
    }
  }

  const handleDomainCollapseToggle = (domainId: number) => {
    const domainPosition = domains.findIndex((domain: IDomain) => domain.domainId === domainId);
    if (domainPosition !== -1) {
      const newDomains = [...domains];
      newDomains[domainPosition].collapsed = !domains[domainPosition].collapsed;
      setDomains(newDomains);
    }
  }

  const handleSelectStandard = (standardId: number) => {
    const selectedStandardPosition = selectedStandards.findIndex((standard: ISelectedStandard) => standard.standardId === standardId);
    if (selectedStandardPosition === -1) {
      if(selectedStandards.length > 4) {
        toast.warn("You have already selected the maximum number of standards");
        return;
      }
      const domainPosition = domains.findIndex((domain: IDomain) => domain.standards.some((standard: IStandard) => standard.standardId === standardId))
      const standardPosition = domains[domainPosition].standards.findIndex((standard: IStandard) => standard.standardId === standardId);
      const newSelectedStandard: ISelectedStandard = {
        domainId: domains[domainPosition].domainId,
        domainName: domains[domainPosition].domainName,
        standardId: domains[domainPosition].standards[standardPosition].standardId,
        standardName: domains[domainPosition].standards[standardPosition].standardName,
        standardCode: domains[domainPosition].standards[standardPosition].code,
        description: domains[domainPosition].standards[standardPosition].description,
      }
      const newSelectedStandards = [...selectedStandards, newSelectedStandard];
      setSelectedStandards(newSelectedStandards);
    } else {
      const newSelectedStandards = selectedStandards.filter((selectedStandard:ISelectedStandard) => selectedStandard.standardId !== standardId);
      setSelectedStandards(newSelectedStandards);
    }
  }

  const handleContinue = () => {
    if(selectedStandards.length < 2) {
      toast.warn("You must select a minimum of 2 standards");
      return;
    }

    onContinue();
  }

  useEffect(() => {
    if(selectedSubject.length) {
      getDomains(selectedSubject);
    }
  }, [])

  return (
    <Fragment>
      {showLoading && <Loader />}
      <div className="flex flex-row justify-between">
        <div className="w-full">
          <p className="text-gray-500 text-left my-4">Choose between 2-5 standards you would like to use for this game. Each standard will have 5 questions.</p>
          <div className="grid grid-cols-3 my-4">
            <div className="col-span-1">
              <div className="flex flex-col">
                <Subject 
                  isMulti={false} 
                  themeColor={"#992883"} 
                  placeholder="Select a subject" 
                  setSelectedSubject={handleSubjectChange} 
                  selectedItems={selectedSubject}
                />
              </div>
            </div>
            <div className="col-start-3 col-span-1 items-center flex text-gray-500 font-medium">
              {selectedStandards.length} Standard Chosen
            </div>
          </div>
          {domains.length === 0 && (<div className="h-[200px]"></div>)}
          {domains.map((domain: IDomain, index: number) => (
            <div key={index}>
              <div 
                className="w-full bg-gray-100 p-3 mb-3 cursor-pointer"
                onClick={() => handleDomainCollapseToggle(domain.domainId)}
              >
                  <div className="text-gray-500 font-bold flex items-center">
                    <span className="flex-grow">{domain.domainName}</span>
                    {domain.collapsed && (
                      <span title="Expand to view Standards">
                        <DownArrowIcon className="w-5 h-5"/>
                      </span>

                    )}
                    {!domain.collapsed && (
                      <span title="Collapse Standards">
                        <UpArrow className="w-5 h-5" />
                      </span>
                    )}
                  </div>
                </div>
                {!domain.collapsed && (domain.standards.map((standard: IStandard) => (
                  <div 
                    className="border-2 border-secondary-teal p-2 mb-2 flex items-center cursor-pointer"
                    onClick={() => handleSelectStandard(standard.standardId)}
                  >
                    <div className="flex mr-3">
                      <input 
                        title="Selected"
                        type="checkbox"
                        className="focus:ring-secondary-real h-4 text-secondary-teal border-gray-500 cursor-pointer"
                        onChange={() => handleSelectStandard(standard.standardId)}
                        checked={selectedStandards.some((selectedStandard: ISelectedStandard) => selectedStandard.standardId === standard.standardId)}
                      />
                    </div>
                    <div className="flex-grow">
                      <div className="text-secondary-teal font-bold items-center">
                        {standard.code} {standard.standardName}
                      </div>
                      <div className=" text-gray-700 text-[0.8rem] items-center">
                        {standard.description}
                      </div>
                    </div>
                    
                  </div>
                )))}
            </div>
          ))}
        </div>
        <div className="w-[21rem]">
          <AnglerfishButton
            text="Continue"
            textSize={1.1}
            canTextShink
            onClick={handleContinue}
          />
        </div>
      </div>
    </Fragment>
  )
}

export default TOSGameSetupPickContent

import XIcon from "@heroicons/react/outline/XIcon";
import { useState } from "react";
import { Eye, EyeOff, UnLockIcon } from "../../../../../../assets/icons/index";
import InfoMessage from "../../../../../shared/infoMessage";

interface ClassPasswordPopupProps {
  showPopUp: (show: boolean) => void;
  setPassword: (password: string) => void;
  password: string;
}

const ClassPasswordPopup = (props: ClassPasswordPopupProps) => {
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState(props.password);
  const [validationError, setValidationError] = useState<boolean>(false);
  return (
    <div className="fixed inset-0 z-40 flex items-center bg-black bg-opacity-50 sm:items-center sm:justify-center enter-done">
      <div
        className="w-full overflow-hidden bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:max-w-2xl"
        role="dialog"
      >
        <div className="flex-1">
          {/* Header */}
          <div className="px-4 py-4 bg-gray-50 sm:px-4">
            <div className="flex items-start justify-between space-x-3">
              <div className="space-y-1">
                <div className="text-lg font-medium text-gray-900">
                  Class Password
                </div>
              </div>
              <div className="h-7 flex items-center">
                <button
                  type="button"
                  className="text-gray-400 hover:text-gray-500"
                  onClick={() => props.showPopUp(false)}
                >
                  <span className="sr-only">Close panel</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
          <div className="py-1 px-4">
            <InfoMessage message="This setting allows you to create a password between 3-12 characters that students will enter to join the class." />
          </div>

          {/* <!-- Divider container -->  */}
          <div className="text-sm text-gray-700 px-4">
            <div className="grid flex items-end grid-cols-2 gap-x-3">
              <div>
                <label
                  htmlFor="password"
                  className="block text-sm text-gray-500 sm:mt-px sm:pt-2"
                >
                  Password
                </label>
                <div className="flex items-center">
                  {showPassword === true && (
                    <input
                      id="password"
                      maxLength={12}
                      minLength={3}
                      type="text"
                      className="py-1.5 px-3 block w-full shadow-sm focus:ring-primary-violet focus:border-primary-violet border border-gray-300 rounded-md cursor-pointer"
                      name="password"
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                      value={password}
                    />
                  )}
                  {showPassword === false && (
                    <input
                      id="password"
                      maxLength={13}
                      minLength={3}
                      type="password"
                      className="py-1.5 px-3 block w-full shadow-sm focus:ring-primary-violet focus:border-primary-violet border border-gray-300 rounded-md cursor-pointer"
                      name="password"
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                      value={password}
                    />
                  )}

                  <div title="Toggle Password Visibility">
                    {showPassword === true && (
                      <Eye
                        onClick={() => {
                          setShowPassword(!showPassword);
                        }}
                        className="ml-2 mt-1 w-5 text-gray-500 cursor-pointer"
                      />
                    )}
                    {showPassword === false && (
                      <EyeOff
                        onClick={() => {
                          setShowPassword(!showPassword);
                        }}
                        className="ml-2 mt-1 w-5 text-gray-500 cursor-pointer"
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="flex justify-end">
                <button
                  className="bg-transparent border border-primary-violet shadow-sm py-2 px-4 inline-flex justify-center items-center text-sm font-medium text-primary-violet hover:text-white hover:bg-light-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition ease-in-out duration-500"
                  onClick={() => {
                    setValidationError(false);
                    props.setPassword("");
                  }}
                >
                  <UnLockIcon className="w-4 mr-2" />
                  Remove Password
                </button>
              </div>
            </div>
            <div>
              {validationError && (
                <label className="text-sm text-red-500">
                  Password should be between 3 and 12 characters.
                </label>
              )}
            </div>
          </div>
        </div>

        {/* <!-- Action Buttons -->  */}
        <footer className="flex items-center mt-4 justify-end px-6 py-4 sm:flex-row bg-gray-50 dark:bg-gray-800">
          <div className="flex-shrink-0">
            <div className="space-x-3 flex justify-end">
              <button
                className="align-bottom inline-flex items-center whitespace-nowrap justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 rounded-lg text-sm text-white border border-transparent bg-primary-green focus:shadow-outline-purple w-full sm:w-auto"
                type="button"
                onClick={() => {
                  if (password?.length < 3 || password?.length > 13) {
                    setValidationError(true);
                  } else {
                    setValidationError(false);
                    props.setPassword(password);
                  }
                }}
              >
                {" "}
                Set Password
              </button>
              <button
                className="align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 rounded-lg text-sm text-gray-600 border-gray-300 border dark:text-gray-400 focus:outline-none active:bg-transparent hover:border-gray-500 focus:border-gray-500 active:text-gray-500 focus:shadow-outline-gray w-full sm:w-auto"
                type="button"
                onClick={() => {
                  props.showPopUp(false);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default ClassPasswordPopup;

import APIConstant from "../utils/constant/apiConstant";
import { AxiosHelper } from "../environment/axiosConfig";

export const getAllDomainsBySubject = (subjectId: number) => {
  return AxiosHelper.axiosInstance().get(APIConstant.CMS_DOMAIN_ALL, {
    params: {
      SubjectId: subjectId,
      OrderByColumn: "Name",
      SortDir: "Asc",
      IsActive: true,
      isApproved: true,
    },
  });
};
export const getStandardsByDomainId = (subjectId: number, domainId: number) => {
  return AxiosHelper.axiosInstance().get(APIConstant.CMS_STANDARD, {
    params: {
      subjectId: subjectId,
      domainId: domainId,
      IsActive: true,
    },
  });
};

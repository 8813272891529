import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import classNames from "classnames";
import { DotsVerticalIcon } from "@heroicons/react/solid";

interface ApplyAllSettingContextMenuProp {
  applyAllAbove?: (setting: string, userId: number, value: string) => void;
  applyAllBelow?: (setting: string, userId: number, value: string) => void;
  applyAll: (setting: string, userId: number, value: string) => void;
  userId: number;
  setting: string;
  value: string;
  isFirst: string | undefined;
  isLast: string | undefined;
  isEditingEnabled?: boolean;
}
function ApplyAllSettingContextMenu(props: ApplyAllSettingContextMenuProp) {
  return (
    <Fragment>
      <Menu as="div" className="relative flex items-center">
        <Menu.Button className={(props.isEditingEnabled == null || props.isEditingEnabled === true) ? "" : "cursor-not-allowed" + " w-8 bg-transparent inline-flex items-center justify-center text-gray-400 rounded-full hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bg-primary-color"}>
          <span className="sr-only">Open options</span>
          <DotsVerticalIcon className="w-5" aria-hidden="true" />
        </Menu.Button>
        {(props.isEditingEnabled == null || props.isEditingEnabled === true) && (
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="mx-3 transform -translate-y-full origin-top-right absolute right-3 top-5 w-48 mt-1 rounded-md shadow-lg z-10 bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none">
            <div className="py-1">
              {props.isFirst !== undefined && props.isFirst === "false" && (
                <Menu.Item>
                  {() => (
                    <button
                      onClick={() => {
                        props.applyAllAbove &&
                          props.applyAllAbove(
                            props.setting,
                            props.userId,
                            props.value
                          );
                      }}
                      className={classNames(
                        props.isFirst === "true"
                          ? "bg-gray-100 text-gray-500 cursor-not-allowed"
                          : "text-gray-700 cursor-pointer",
                        "group flex items-center px-4 py-2 text-sm w-full"
                      )}
                    >
                      Apply value above
                    </button>
                  )}
                </Menu.Item>
              )}

              {props.isLast !== undefined && props.isLast === "false" && (
                <Menu.Item>
                  {() => (
                    <button
                      onClick={() => {
                        props.applyAllBelow &&
                          props.applyAllBelow(
                            props.setting,
                            props.userId,
                            props.value
                          );
                      }}
                      className={classNames(
                        props.isLast === "true"
                          ? "bg-gray-100 text-gray-500 cursor-not-allowed"
                          : "text-gray-700 cursor-pointer",
                        "group flex items-center px-4 py-2 text-sm  w-full"
                      )}
                    >
                      Apply value below
                    </button>
                  )}
                </Menu.Item>
              )}

              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={() => {
                      props.applyAll(props.setting, props.userId, props.value);
                    }}
                    className={classNames(
                      active
                        ? "bg-gray-100 text-gray-500"
                        : "text-gray-700 cursor-pointer",
                      "group flex items-center px-4 py-2 text-sm  w-full"
                    )}
                  >
                    Apply setting to all
                  </button>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
        )}
      </Menu>      
    </Fragment>
  );
}
export default ApplyAllSettingContextMenu;

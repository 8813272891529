import React, { useRef } from "react";
import { connect } from "react-redux";
import { PrintIcon } from "../../../../assets/icons/index";
import PreviewPage from "../shared/questionPreview/learnosityPreviewPage";
import { LogoPLIcon } from "../../../../assets/icons/index";
import ReactToPrint from "react-to-print";

function PrintQuestion(props) {
  const componentRef = useRef(null);

  return (
    <>
      <div className="flex justify-end">
        <ReactToPrint
          trigger={() => (
            <button
              className="h-10 bg-transparent border border-primary-violet shadow-sm py-2 px-4 inline-flex justify-start text-sm font-medium text-primary-violet hover:text-white hover:bg-light-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3 transition ease-in-out duration-500"
              type="button"
            >
              <PrintIcon className="w-6 mr-1"></PrintIcon>
              Print
            </button>
          )}
          content={() => componentRef.current}
        />
      </div>
      <div className="grid p-5" ref={componentRef}>
        <div className="flex justify-between mb-10">
          <LogoPLIcon title="Progress Learning logo" className="h-20 w-20" />
        </div>
        <div className="flex justify-between mb-5">
          <div>Student Name: ______________</div>
          <div>Date: ______________</div>
        </div>
        <div className="flex justify-between">
          <div>Teacher Name: {props.userContext.userName}</div>
          <div>Score: ______________</div>
        </div>

        <div className="font-semibold mt-5">Bell Ringer Question</div>
        {props.instructions && (
          <div className="mt-2">
            Instructions:
            <div className="rounded border-black border p-2">
              {props.instructions}
            </div>
          </div>
        )}
        <div className="bell-ringer-print-div">
          {props.questionId && <PreviewPage itemRef={props.questionId} />}
        </div>
      </div>
    </>
  );
}

export default connect((state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
})(PrintQuestion);

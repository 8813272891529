import MainScreen from '../../../../../assets/images/thinkorswim/EndScreen.jpg';
import { ISessionTeam } from '../../../../../model/interface/classroomGame/thinkOrSwimSetup';
import TextBubble from './textBubble';

interface ITOSGamePlayEndScreenProps {
  sessionTeams: Array<ISessionTeam>,
  onReturnToSessionScreen: () => void,
}

function TOSGamePlayEndScreen(props: ITOSGamePlayEndScreenProps) {
  const {
    sessionTeams,
    onReturnToSessionScreen,
  } = props;

  const getMaxScore = (): number => {
    const maxScore: number = sessionTeams.reduce((max, team) => team.points > max ? team.points : max, 0);
    return maxScore;
  }

  const getWinnerTeams = (maxScore: number): string => {
    const winnerNames: string = sessionTeams
      .sort((a: ISessionTeam, b: ISessionTeam) => b.points - a.points)
      .filter(team => team.points === maxScore)
      .reduce((names, team, index, teams) => {
        return index === teams.length - 1 
          ? names = `${names}${team.name}`
          : index === teams.length - 2
            ? names = `${names}${team.name} and `
            : names = `${names}${team.name}, `
      }, "");
    return winnerNames;
  }

  const maxScore: number = getMaxScore();
  const winnerTeams: string = getWinnerTeams(maxScore);

  return (
    <div>
      <div className='relative overflow-hidden'>
        <div className='absolute top-[3%] left-[21%] w-[40%]'>
          <TextBubble 
            text={`Congratulations ${winnerTeams} on being the biggest fish in the sea! Your fish brain is larger than the average-sized fish brain.`}
            textSizeCSSClass='text-[1.2vw]'
          />
        </div>
        <div className='absolute top-[25%] w-full overflow-hidden'>
          <div className='flex justify-center'>
            <div className='w-[45%] bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg p-3'>
              {sessionTeams.sort((a: ISessionTeam, b: ISessionTeam) => b.points - a.points).map((team: ISessionTeam, index: number) => (
                <div 
                  key={index} 
                  className={
                    'relative w-full my-3 grid grid-cols-7 gap-4 items-center text-blue-500 ' +
                    (team.points === maxScore ? 'text-[2vw] font-extrabold' : 'text-[1.7vw] font-medium')
                  }
                >
                  <div className='col-span-4'>
                    {team.name}
                  </div>
                  <div className='col-span-3 text-right'>
                    {team.points} pts
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className='flex justify-center mt-3'>
            <button
              type='button'
              className='bg-secondary-teal focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dark-teal focus:bg-dark-teal hover:shadow-lg hover:bg-dark-teal rounded-md shadow-sm p-2 text-white mb-8'
              onClick={onReturnToSessionScreen}
            >
              Return to Session Screen
            </button>
          </div>
        </div>
        <img 
          className="w-full"
          src={MainScreen}
          alt='Think or Swim'
        />
      </div>
    </div>
  )
}

export default TOSGamePlayEndScreen

import { useState } from "react";
import { connect } from "react-redux";
import LiftOffMyGalaxy from "../../shared/liftoff/liftOffMyGalaxy";

function MyGalaxyMS(props: any) {
  const [subjectId] = useState<number>(Number(props.match.params.id));
  const [subjectName] = useState<string>(
    String(props.match.params.subjectName)
  );
  return (
    <LiftOffMyGalaxy
      subjectId={subjectId}
      userId={props.userContext?.userId}
      subjectName={subjectName}
    ></LiftOffMyGalaxy>
  );
}

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
  };
};
export default connect<{}, {}, {}>(mapStateToProps, {})(MyGalaxyMS);

import { Fragment, useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Dialog, Transition } from "@headlessui/react";
import { ExclamationIcon } from "@heroicons/react/outline";

interface RouterPromptProps {
  title: string;
  saveText?: string;
  okText?: string;
  cancelText: string;
  message: string;
  when: boolean;
  onOK: () => void;
  onCancel: () => void;
  onSaveAndLeave: ( logoutClicked?: boolean ) => void;
}
export function RouterPrompt(props: RouterPromptProps) {
  const history = useHistory();

  const [showPrompt, setShowPrompt] = useState(false);
  const [currentPath, setCurrentPath] = useState("");
  const [logoutClicked, setLogoutClicked] = useState(false);

  useEffect(() => {
    if (props.when) {
      history.block((prompt) => {
        const { state } : any = prompt;
        if (state?.logoutClicked) {
          setLogoutClicked(true);
        }
        setCurrentPath(prompt.pathname);
        setShowPrompt(true);
        return false;
      });
    } else {
      history.block(() => {});
    }
    return () => {
      history.block(() => {});
    };
  }, [props.when]);

  const handleOK = () => {
    history.block(() => {});
    history.push(currentPath);
    setShowPrompt(false);
  };

  const handleCancel = () => {
    setLogoutClicked(false);
    setShowPrompt(false);
  };

  const handleSaveAndLeave = useCallback(async () => {
    if (props.onSaveAndLeave) {
      await Promise.resolve(props.onSaveAndLeave(logoutClicked));
      history.block(() => {});
      history.push(currentPath);
    }
    setShowPrompt(false);
  }, [currentPath, history, props.onSaveAndLeave]);

  return (
    <Transition.Root show={showPrompt} as={Fragment}>
      <Dialog
        as="div"
        static
        className={"fixed z-10 inset-0 overflow-y-auto"}
        open={showPrompt}
        onClose={handleCancel}
      >
        <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full">
              <div className="sm:flex sm:items-start p-4 mb-6">
                <div className="mx-auto mt-4 flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-yellow-100 sm:mx-0 sm:h-10 sm:w-10">
                  <ExclamationIcon
                    className="h-6 w-6 text-yellow-500"
                    aria-hidden="true"
                  />
                </div>
                <div className="mt-3 text-center sm:mt-4 sm:ml-4 sm:text-left">
                  <Dialog.Title
                    as="h3"
                    className="text-lg leading-6 font-medium text-gray-900"
                  >
                    {props.title}
                  </Dialog.Title>
                  <div className="mt-2">
                    <p
                      className="text-sm text-gray-500"
                      dangerouslySetInnerHTML={{
                        __html: props.message,
                      }}
                    ></p>
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-end px-6 py-4 sm:flex-row bg-gray-50">
                <div className="flex-shrink-0">
                  <div className="space-x-3 flex justify-end">
                    {props.saveText !== undefined && (
                      <button
                        type="button"
                        className="align-bottom inline-flex items-center whitespace-nowrap justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 rounded-lg text-sm text-white border border-transparent bg-primary-green focus:shadow-outline-purple w-auto"
                        onClick={() => handleSaveAndLeave()}
                      >
                        {props.saveText ? props.saveText : "Save and Leave"}
                      </button>
                    )}

                    {props.okText !== undefined && (
                      <button
                        type="button"
                        className="w-auto inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                        onClick={() => handleOK()}
                      >
                        {props.okText ? props.okText : "Ok"}
                      </button>
                    )}

                    <button
                      type="button"
                      className="w-auto inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                      onClick={() => handleCancel()}
                    >
                      {props.cancelText ? props.cancelText : "Cancel"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

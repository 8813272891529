import { Fragment, useState, useEffect } from "react";
import CollapsibleButton from "../../elementary/home/collapsibleButton";
import { StudyType } from "../../../../../types/type";
import {
  AssignmentsIcon,
  EnglishIcon,
  GradedWorkIcon,
  MyStudyPlanIcon,
  SkillPracticeIcon,
  SocialStudiesIcon,
} from "../../../../../assets/icons";
import Assignments from "../../elementary/assignment/assignments";
import AlienArenaElementary from "../arena/alienArenaElementary";
import Constant from "../../../../../utils/constant/constant";
import { UserContextState } from "../../../../../redux/actions/userContextAction";
import * as userContextAction from "../../../../../redux/actions/userContextAction";
import { fetchProfile } from "../../../../../redux/actions/userActions";
import { connect } from "react-redux";
import Profile from "../../../../../model/users/profile";
import SkillStandard from "../../skillPractice/elementary/skillStandard";
import StudyPlanStandard from "../studyplan/studyPlanStandard";
import StudentProfile from "../../../../../model/student/studentProfile";
import { getStudentProfile } from "../../../../../api/student/studentProfile";
import AlienAvatar from "../../shared/alienAvatar/alienAvatar";
import AlienRanking from "../../shared/alienRanking/alienRanking";
import GalaxyStars from "../../shared/galaxyStars/galaxyStars";
import Rockets from "../../shared/rockets/rockets";
import Blasters from "../../shared/blasters/blasters";
import { getStudentRewards } from "../../../../../api/student/studentApi";
import Loader from "../../../../shared/loader";
import GoogleSignInModal from "../../../popup/googleSignInModal";
import { getNewAssignmentsCount } from "../../../../../api/student/studentAssignmentApi";
import GradedWork from "../gradedWork/gradedWork";
import { IStudentReward } from "../../../../../model/interface/studentReward";
import { getDefaultRewards } from "../../../../../utils/studentHelper";
//import { useHistory } from "react-router-dom";
import RouteConstant from "../../../../../utils/constant/routeConstant";
//import { HomeIcon } from "@heroicons/react/outline";
import { useTranslation } from "react-i18next";

interface HomeProps {
  userContext?: UserContextState;
  profile?: Profile;
  setUserContext?: (context: any) => void;
  setProfile?: (
    userId: number,
    roleId: number,
    schoolId: number,
    id: number
  ) => void;
  location?: any;
}

const Home = (props: HomeProps) => {
  const [showLoader, setLoader] = useState(false);
  const [studytypes] = useState<Array<StudyType>>([
    "Assignments",
    "Graded Work",
    "My Study Plan",
    "Skill Practice",
  ]);

  const tab = decodeURIComponent(
    new URLSearchParams(props.location?.search ?? "").get("tab") ??
      studytypes[0]
  );
  const [selectedStudyType, setSelectedStudyType] = useState(tab);
  const [selectedTab, setSelectedTab] = useState<StudyType>(
    tab == Constant.StudyType.MYSTUDYPLAN.toLowerCase()
      ? studytypes[2]
      : studytypes[0]
  );

  const [selectedSubject, setSelectedSubject] = useState(
    parseInt(
      new URLSearchParams(props.location?.search ?? "").get("subjectId") ?? "0"
    )
  );
  const [subjectName, setSubjectName] = useState(
    decodeURIComponent(
      new URLSearchParams(props.location?.search ?? "").get("subjectName") ?? ""
    )
  );

  const [studentProfile, setStudentProfile] = useState<StudentProfile>();
  const [studentNewAssignmentsCount, setStudentNewAssignmentsCount] =
    useState<number>(0);
  const [rewards, setRewards] = useState<IStudentReward>(getDefaultRewards());
  const [showGooglePopup, setShowGooglePopup] = useState(false);
  //const history = useHistory();
  const { t } = useTranslation();
  const [activeLink] = useState(RouteConstant.STUDENT);
  const [programMode] = useState(
    localStorage.getItem("programMode")
      ? localStorage.getItem("programMode")
      : Constant.ProgramMode.EG
  );

  /* function handleNaviagation(link: string, programMode?: string) {
    if (programMode) {
      setProgramMode(programMode);
      localStorage.setItem("programMode", programMode);
    }

    setActiveLink(link);
    history.push(link);
  } */

  function setSubject(
    subjectId: number,
    studyType: string,
    subjectName: string
  ) {
    setSelectedSubject(subjectId);
    setSelectedStudyType(studyType);
    setSubjectName(subjectName);
  }

  function bindStudentProfile() {
    getStudentProfile(
      props.userContext!.userId,
      props.profile?.gradeId ? props.profile?.gradeId : 0
    ).then((response) => {
      setStudentProfile(response.data);
    });
  }
  function getHasNewAssignment() {
    getNewAssignmentsCount(props.userContext!.userId).then((response) => {
      setStudentNewAssignmentsCount(response.data);
    });
  }

  useEffect(() => {
    bindStudentProfile();
    getHasNewAssignment();
  }, []);

  useEffect(() => {
    if (
      props.userContext?.userId &&
      props.profile?.userId &&
      props.userContext?.userId > 0 &&
      props.profile?.userId! > 0
    ) {
      const showPopup =
        window.localStorage.getItem("showGoogleSignInPopup")! === "true";
      if (
        props.profile?.googleUser?.refreshToken === null &&
        showPopup &&
        props.profile?.gmailId &&
        props.userContext?.impersonatedUser === null
      ) {
        setShowGooglePopup(true);
      }
    }
  }, [props.userContext?.userId, props.profile?.userId]);

  useEffect(() => {
    setLoader(true);
    getStudentRewards(props.userContext?.userId ?? 0).then((d) => {
      setLoader(false);
      setRewards(d.data);
    });
  }, []);

  function getContentBGColor() {
    switch (selectedStudyType.toLocaleUpperCase()) {
      case Constant.StudyType.ALIENARENA:
        return "bg-[#ff4655]";
      case Constant.StudyType.MYSTUDYPLAN:
        return "bg-[#34D9A5]";
      case Constant.StudyType.GRADED_WORK:
        return "bg-[#fb923c]";
      case Constant.StudyType.SKILLPRACTICE:
        return "bg-[#fe3bac]";

      default:
        return "bg-[#00b1fb]";
    }
  }

  const getStudyName = (studyType: string) => {
    switch (studyType) {
      case "Assignments":
        return t("StudentElementaryHome.Assignments");
      case "Diagnostics":
        return t("StudentElementaryHome.Diagnostics");
      case "My Study Plan":
        return t("StudentElementaryHome.MyStudyPlan");
      case "Skill Practice":
        return t("StudentElementaryHome.SkillPractice");
      case "Alien Arena":
        return t("StudentElementaryHome.AlienArena");
      case "My Galaxies":
        return t("LiftOffElementaryHome.MyGalaxy");
      case "Graded Work":
        return t("Shared.StudentSideBar.GradedWork");
    }
  };

  const dicStudyTypeColorMapping = {
    Assignments: "#00b1fb",
    Diagnostics: "#d2ff00",
    "My Study Plan": "#34D9A5",
    "Skill Practice": "#fe3bac",
    "Alien Arena": "#ff4655",
    "My Galaxies": "#fe3bac",
    "Graded Work": "#f5a223",
  };

  const dicStudyTypeIcon = {
    Assignments: <AssignmentsIcon />,
    "Graded Work": <GradedWorkIcon />,
    "My Study Plan": <MyStudyPlanIcon />,
    "Skill Practice": <SkillPracticeIcon />,
  };

  const activeLinkClass =
    "mb-3 space-x-2 items-center justify-start text-gray-900 group flex px-1.5 py-3 text-sm border-[3px] bg-opacity-90 border-l-0 rounded-r-2xl shadow-2xl transition ease-in-out duration-500";
  const inActiveLinkClass =
    "mb-3 space-x-2 items-center justify-start text-gray-900 group flex px-1.5 py-3 text-sm rounded-r-2xl hover:bg-white hover:bg-opacity-50 transition ease-in-out duration-500 group";

  return (
    <Fragment>
      {showLoader && <Loader></Loader>}
      <div className="flex h-full">
        {programMode === Constant.ProgramMode.EG && (
          <div className="w-44 flex-none pt-5 flex justify-center hidden md:block">
            <nav
              className="flex-1 space-y-4 hidden sm:block"
              aria-label="Sidebar"
            >
              <div className="">
                {studytypes.map((studyType) => {
                  return (
                    <Fragment>
                      {(studyType.toLowerCase() !== "skill practice" ||
                        props.profile?.isPLSkillPracticeActive) && (
                        <button
                          key={studyType}
                          onClick={() => {
                            setSelectedTab(studyType);
                            if (studyType !== "My Study Plan")
                              setSubject(0, studyType, "");
                          }}
                          className={
                            activeLink === RouteConstant.StudentRoute.Home ||
                            activeLink === RouteConstant.STUDENT
                              ? activeLinkClass
                              : inActiveLinkClass
                          }
                          style={
                            selectedStudyType.toLocaleUpperCase() ===
                            studyType.toLocaleUpperCase()
                              ? {
                                  borderColor:
                                    dicStudyTypeColorMapping[studyType],
                                  backgroundColor: "white",
                                  width: "calc(100% - 5px)",
                                }
                              : {
                                  borderColor: "white",
                                  backgroundColor:
                                    dicStudyTypeColorMapping[studyType],
                                  width: "calc(100% - 16px)",
                                }
                          }
                        >
                          {dicStudyTypeIcon[studyType]}
                          <span className="text-left">
                            {getStudyName(studyType)}
                          </span>
                        </button>
                      )}
                    </Fragment>
                  );
                })}
              </div>
            </nav>
          </div>
        )}
        <div className="bg-white flex-grow rounded-2xl mb-4">
          {" "}
          <main className="p-5">
            <div className="grid lg:grid-cols-4 xl:grid-cols-5 gap-4 xl:gap-4">
              <div className="flex flex-col md:flex-row gap-4 md:col-span-2">
                {studentProfile && (
                  <AlienRanking
                    studentId={props.userContext!.userId}
                    gradeId={
                      props.profile?.gradeId ? props.profile?.gradeId : 0
                    }
                    firstName={props.profile?.firstName!}
                    lastName={props.profile?.lastName!}
                    type="PL"
                  />
                )}
                {studentProfile && (
                  <AlienAvatar
                    studentId={props.userContext!.userId}
                    gradeId={
                      props.profile?.gradeId ? props.profile?.gradeId : 0
                    }
                    alienData={studentProfile.plAvatar}
                    showOnQuestions={studentProfile.plShowAvatarOnQuestions}
                    studentGradeMode="Elementary"
                    isLiftOff={false}
                    rewards={rewards}
                  />
                )}
              </div>
              <div className="flex h-full items-end col-span-1 md:col-span-3 m-auto w-full ">
                <div className="grid grid-cols-1 xs:grid-cols-3 sm:grid-cols-3 md:grid-cols-7 gap-4 h-full w-full">
                  <GalaxyStars rewards={rewards} type="PL" />
                  <Rockets rewards={rewards} type="PL" />
                  <Blasters
                    userContext={props.userContext}
                    rewards={rewards}
                    type="PL"
                  />
                </div>
              </div>
            </div>
            <div
              className={
                selectedTab !== "Assignments" && selectedTab !== "Graded Work"
                  ? "mt-4"
                  : "-mt-2"
              }
            >
              <div className="grid grid-cols-1 xl:grid-cols-12 gap-0 xl:gap-4">
                <div className="col-span-12 xl:block md:gap-4 xl:gap-0 space-y-4 xl:space-y-6 md:mb-4 xl:mb-0">
                  {studytypes
                    .filter(
                      (studyType) =>
                        studyType !== "Assignments" &&
                        studyType !== "Graded Work"
                    )
                    .map((studyType, index) => {
                      return (
                        <Fragment>
                          {selectedTab === studyType && (
                            <CollapsibleButton
                              key={index}
                              studyType={studyType}
                              onClick={setSubject}
                              profile={props.profile}
                              expand={selectedTab === studyType}
                              selectedSubjectId={selectedSubject}
                              newAssignmentsCount={studentNewAssignmentsCount}
                            ></CollapsibleButton>
                          )}
                        </Fragment>
                      );
                    })}
                </div>
                <div className="col-span-12 h-full mt-8 md:mt-4 xl:mt-0">
                  <div
                    className={`xl:h-full ${getContentBGColor()} rounded-2xl relative`}
                  >
                    {selectedStudyType.toLocaleUpperCase() !==
                      Constant.StudyType.ALIENARENA &&
                      selectedStudyType.toLocaleUpperCase() !==
                        Constant.StudyType.ASSIGNMENTS &&
                      selectedStudyType.toLocaleUpperCase() !==
                        Constant.StudyType.GRADED_WORK &&
                      false && (
                        <span className="mt-5 left-2 sm:left-6 lg:left-8 relative inline-flex items-center text-sm bg-white rounded-full px-4 py-2 bg-opacity-70 ">
                          {subjectName === "Math" && (
                            <img
                              className="w-6 mr-2"
                              src="https://assets.progresslearning.com/images/content-area/math.svg"
                            />
                          )}
                          {subjectName === "SS" && (
                            <SocialStudiesIcon className="h-5 mr-2 flex-shrink-0 m-auto filter invert" />
                          )}
                          {subjectName === "Reading" && (
                            <EnglishIcon className="w-6 mr-2 flex-shrink-0 m-auto filter invert" />
                          )}
                          {subjectName}
                        </span>
                      )}
                    <div className=" mx-auto space-y-3 px-2 py-4 sm:flex-col w-full sm:px-6 lg:px-8">
                      {selectedStudyType.toLocaleUpperCase() ===
                        Constant.StudyType.GRADED_WORK && (
                        <GradedWork
                          userId={props.userContext!.userId}
                          roleId={props.userContext!.roleId}
                        ></GradedWork>
                      )}

                      {selectedStudyType.toLocaleUpperCase() ===
                        Constant.StudyType.ALIENARENA && (
                        <AlienArenaElementary
                          userId={props.userContext!.userId}
                          roleId={props.userContext!.roleId}
                        ></AlienArenaElementary>
                      )}
                      {selectedStudyType.toLocaleUpperCase() ===
                        Constant.StudyType.MYSTUDYPLAN && (
                        <StudyPlanStandard
                          subjectId={selectedSubject}
                          subjectName={subjectName}
                        ></StudyPlanStandard>
                      )}
                      {selectedStudyType.toLocaleUpperCase() ===
                        Constant.StudyType.ASSIGNMENTS && <Assignments />}
                      {selectedStudyType.toLocaleUpperCase() ===
                        Constant.StudyType.SKILLPRACTICE && (
                        <SkillStandard skillSubjectId={selectedSubject} />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>

      {showGooglePopup && (
        <GoogleSignInModal
          roleId={Constant.UserRoleId.Student}
          returnUrl={""}
          showPopUp={setShowGooglePopup}
          mode={Constant.GoogleSignInMode.SSO}
        />
      )}
    </Fragment>
  );
};

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setUserContext: (payload: userContextAction.UserContextState) => {
      dispatch(userContextAction.setUserContext(payload));
    },
    setProfile: (
      userId: number,
      roleId: number,
      schoolId: number,
      districtId: number
    ) => {
      dispatch(fetchProfile(userId, roleId, schoolId, districtId));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);

import { Fragment, useState } from "react";
import { connect } from "react-redux";
import { useEffect } from "react";
import { getClassInformation } from "../../../api/class/classStudent";
import {
  CanvasIcon,
  GoogleClassroomIcon,
  GoogleLoginIcon,
} from "../../../assets/icons/index";
import { getStudentFamily } from "../../../api/student/familyApi";
import { getStudentPorgram } from "../../../api/student/programApi";
import constant from "../../../utils/constant/constant";
import { handleLoginWithCredentials } from "../../../utils/googleHelper";
import { handleCanvasAuthenticationWithCredentials } from "../../../utils/canvasHelper";
import { Button } from "@windmill/react-ui";
import { GoogleApi } from "../../../api/google/googleApi";
import { CanvasApi } from "../../../api/canvas/canvasApi";
import { useConfirmation } from "../../shared/confirmation/confirmationService";
import Loader from "../../shared/loader";
import { CanvasConfiguration } from "../../../environment/setup";
import { toast } from "react-toastify";
import { IBreadcrumb } from "../../../model/common/breadcrumb";
import { useTranslation } from "react-i18next";
import Breadcrumb from "./middleSchool/breadcrumb";
import routeConstant from "../../../utils/constant/routeConstant";
import userApi from "../../../api/userApi";
import UpgradeButtonControl from "../../../component/shared/customButtonControl";

const Profile = (props: any) => {
  const url = String(props.match.url);
  const isLoMode = localStorage.getItem("programMode") === constant.ProgramMode.LO;
  const layout = {
    ELEMENTARY: {
      header: "text-gray-600 font-medium text-lg",
      breadcrumbText: "text-gray-500",
      routes: {
        home: isLoMode ? routeConstant.LiftOff.Elementary.Home :routeConstant.StudentRoute.Home,
        profile: routeConstant.StudentRoute.Profile,
      },
      border: "border-b",
      displayBreadcrumb: "block",
      containerMargin: "mt-10",
      textLabel: "text-sm",
      textBig: "text-base",
      tFont: "text-sm",
      inputBorder: "border-gray-300 border",
    },
    KINDERGARTEN: {
      header: "text-gray-50 font-medium text-lg lg:text-xl xl:text-3xl",
      breadcrumbText: "text-gray-500",
      routes: {
        home: routeConstant.KindergartenStudentRoutes.Home,
        profile: "",
      },
      border: "",
      displayBreadcrumb: "hidden",
      containerMargin: "mt-0",
      textLabel: "text-base",
      textBig: "text-xl",
      tFont: "text-base",
      inputBorder: "border-orange-light border-[3px]",
    },
    MIDDLESCHOOL: {
      header: "text-gray-50 font-medium text-lg",
      breadcrumbText: "text-white",
      routes: {
        home: isLoMode ? routeConstant.LiftOff.MiddleSchool.Home :routeConstant.MiddleSchool.assignments,
        profile: routeConstant.MiddleSchool.Profile,
      },
      border: "border-b",
      displayBreadcrumb: "block",
      containerMargin: "mt-10",
      textLabel: "text-sm",
      textBig: "text-base",
      tFont: "text-sm",
      inputBorder: "border-gray-300 border",
    },
  };
  const themeName = url.toUpperCase().includes("ELEMENTARY")
    ? "ELEMENTARY"
    : url.toUpperCase().includes("MIDDLESCHOOL")
    ? "MIDDLESCHOOL"
    : "KINDERGARTEN";
  const { t } = useTranslation();
  const confirm = useConfirmation();
  const [studentClassProfile, setStudentClassProfile] = useState<any>([]);
  const [studentFamily, setStudentFamily] = useState<any>([]);
  const [studentProgram, setStudentProgram] = useState<any>();
  const [isEGMode, setIsEGMode] = useState(true);
  const [loading, showLoading] = useState<boolean>(false);
  const [credentials, setCredentials] = useState({
    accountLoginName: "",
    username: "",
    passwordHash: "",
  });

  const getStudentClassProfile = () => {
    var response = getClassInformation(props.profile.userId);
    response?.then((d) => {
      setStudentClassProfile(d.data);
    });
  };

  const getFamily = () => {
    var response = getStudentFamily(props.profile.userId);
    response?.then((d) => {
      setStudentFamily(d.data);
    });
  };

  const breadcrumbItems = () => {
    const items: Array<IBreadcrumb> = [
      {
        name: t("Breadcrumb.Home"),
        url: layout[themeName].routes.home,
        textColor: layout[themeName].breadcrumbText,
      },
      {
        name: t("Shared.Profile.Profile"),
        url: "",
        textColor: layout[themeName].breadcrumbText,
      },
    ];

    return items;
  };

  const getPrograms = () => {
    var response = getStudentPorgram(props.profile.userId);
    response?.then((d) => {
      setStudentProgram(d.data);
      setIsEGMode(
        !studentProgram?.isLiftOff && studentProgram?.isEducationGalaxy
          ? true
          : false
      );
    });
  };

  function redirectToGoogleclassNameroomLogin() {
    handleLoginWithCredentials(
      "Student",
      layout[themeName].routes.profile,
      credentials.accountLoginName,
      credentials.username,
      credentials.passwordHash
    );
  }

  function redirectToCanvasLogin() {
    getCanvasAccountSettings();
  }

  function handleUnLinkGoogle() {
    confirm({
      variant: "danger",
      title: "Please Confirm",
      description:
        "Are you sure you want to unlink the Google Classroom Account?",
    }).then((d) => {
      showLoading(true);
      GoogleApi.unlinkGoogleUser(props?.profile?.userId)
        .then((r) => {
          showLoading(false);
          window.location.reload();
        })
        .catch(() => {
          showLoading(false);
        });
    });
  }

  function handleUnLinkCanvas() {
    confirm({
      variant: "danger",
      title: "Please Confirm",
      description: "Are you sure you want to unlink the Canvas Account?",
    }).then((d) => {
      showLoading(true);
      CanvasApi.unlinkCanvasUser(props?.profile?.userId)
        .then((r) => {
          showLoading(false);
          window.location.reload();
        })
        .catch(() => {
          showLoading(false);
        });
    });
  }

  const OnMdeChange = () => {
    setIsEGMode(!isEGMode);
    var updateStudentProgram = studentProgram;
    if (isEGMode) {
      updateStudentProgram.isEducationGalaxy = true;
      updateStudentProgram.isLiftOff = false;
    } else {
      updateStudentProgram.isEducationGalaxy = false;
      updateStudentProgram.isLiftOff = true;
    }
    setStudentProgram(updateStudentProgram);
  };

  const getCanvasAccountSettings = () => {
    showLoading(true);
    CanvasApi.getCanvasAccountSettingsByAccountLoginName(
      props.profile?.accountLoginName!
    )
      .then((r) => {
        showLoading(false);
        if (r.data && r.data.endPointURL) {
          handleCanvasAuthenticationWithCredentials(
            r.data,
            layout[themeName].routes.profile,
            credentials.accountLoginName,
            credentials.username,
            credentials.passwordHash
          );
        } else {
          toast.error(
            "Your school has not configured canvas account settings yet please contact administrator."
          );
        }
      })
      .catch(() => {
        showLoading(false);
      });
  };

  function getLoginDetails(userId: number) {
    userApi.GetUserDetail(userId).then((res) => {
      if (res.data) {
        setCredentials({
          accountLoginName: res.data.accountId,
          username: res.data.username,
          passwordHash: res.data.passwordHash,
        });
      }
    });
  }

  useEffect(() => {
    getStudentClassProfile();
    getPrograms();
    getFamily();
    getLoginDetails(props.profile.userId);
  }, []);

  return (
    <Fragment>
      <div className="min-w-0 w-full relative">
        {loading && <Loader />}
        <div
          className={`md:flex md:items-center px-4 py-3 lg:px-8 ${layout[themeName].border}`}
        >
          <div className={`md:w-[45%] ${layout[themeName].displayBreadcrumb}`}>
            <Breadcrumb items={breadcrumbItems()} />
          </div>
          <div className="md:w-[55%] flex justify-between gap-2 h-auto sm:h-10 items-center">
            <h1 className={`${layout[themeName]?.header} flex justify-start`}>
              {t("Shared.Profile.Profile")}
            </h1>
          </div>
        </div>

        <section
          className={`${layout[themeName]?.containerMargin} px-4 py-3 lg:px-8`}
        >
          <div className="grid grid-cols-10 gap-4">
            <div className="hidden col-span-10 md:col-span-3 xl:col-span-2 space-y-6 px-4">
              <div className="p-4 bg-white bg-opacity-20 shadow-lg rounded-md">
                {studentProgram?.isLiftOffEnabled &&
                  props.profile.gradeId < constant.Grade.GRADE6 && (
                    <div className="flex gap-3 mb-4 items-center justify-center">
                      <span className="font-medium text-gray-800" id="eg">
                        PL
                      </span>
                      <label className="flex items-center cursor-pointer">
                        <div className="relative">
                          <input
                            id="toogleA"
                            type="checkbox"
                            checked={!isEGMode}
                            onChange={() => OnMdeChange()}
                            className="hidden"
                          />
                          <div className="toggle__line w-11 h-5 bg-primary-green rounded-full shadow-inner"></div>
                          <div className="toggle__dot absolute w-4 h-4 bg-white bg-opacity-90 rounded-full shadow -inset-y-1 in top-0.5 left-0.5"></div>
                        </div>
                      </label>
                      <span className="font-medium text-gray-800" id="eg">
                        LO
                      </span>
                    </div>
                  )}
                {!studentProgram?.isLiftOff && studentProgram?.isPL && (
                  <img className="m-auto" src={studentProgram?.pLCard} alt="" />
                )}
                {studentProgram?.isLiftOff && !studentProgram?.isPL && (
                  <img
                    className="m-auto"
                    src={studentProgram?.liftOffCard}
                    alt=""
                  />
                )}
              </div>
            </div>
            <div
              className={
                themeName === "KINDERGARTEN"
                  ? "col-span-12  h-full"
                  : "col-span-10 md:col-span-10 lg:col-span-10 xl:col-span-8 h-full"
              }
            >
              <div className="bg-white shadow-lg border-t border-opacity-50 rounded-md relative mb-10">
                <div className=" mx-auto px-2 py-8 sm:flex-col w-full sm:px-6 lg:px-6">
                  <div className="max-w-5xl">
                    <div className="grid grid-cols-6 px-4 pb-2 gap-x-6 gap-y-4">
                      <div className="col-span-6 sm:col-span-3 lg:col-span-3">
                        <label
                          className={`${layout[themeName]?.textLabel} block font-medium text-gray-700`}
                        >
                          {t("Shared.Profile.AccountID")}
                        </label>
                        <input
                          type="text"
                          name="Account Id"
                          id="Account_Id"
                          className={`${layout[themeName]?.inputBorder} bg-gray-100 mt-1 focus:ring-primary-green focus:border-primary-green block w-full shadow-sm sm:text-sm rounded-md rounded h-10 px-2 py-4`}
                          value={props.profile.accountLoginName}
                          disabled
                        />
                      </div>
                      <div className="col-span-6 sm:col-span-3 lg:col-span-3">
                        <label
                          className={`${layout[themeName]?.textLabel} block font-medium text-gray-700`}
                        >
                          {t("Shared.Profile.UserName")}
                        </label>
                        <input
                          type="text"
                          name="User Name"
                          id="User_Name"
                          className={`${layout[themeName]?.inputBorder} bg-gray-100 mt-1 focus:ring-primary-green focus:border-primary-green block w-full shadow-sm sm:text-sm rounded-md rounded h-10 px-2 py-4`}
                          value={props.profile.username}
                          disabled
                        />
                      </div>
                      <div className="col-span-6 sm:col-span-3 lg:col-span-3">
                        <label
                          className={`${layout[themeName]?.textLabel} block font-medium text-gray-700`}
                        >
                          {t("Shared.Profile.FirstName")}
                        </label>
                        <input
                          type="text"
                          name="First Name"
                          id="First_Name"
                          className={`${layout[themeName]?.inputBorder} bg-gray-100 mt-1 focus:ring-primary-green focus:border-primary-green block w-full shadow-sm sm:text-sm rounded-md rounded h-10 px-2 py-4`}
                          value={props.profile.firstName}
                          disabled
                        />
                      </div>
                      <div className="col-span-6 sm:col-span-3 lg:col-span-3">
                        <label
                          className={`${layout[themeName]?.textLabel} block font-medium text-gray-700`}
                        >
                          {t("Shared.Profile.LastName")}
                        </label>
                        <input
                          type="text"
                          name="Last Name"
                          id="Last_Name"
                          className={`${layout[themeName]?.inputBorder} bg-gray-100 mt-1 focus:ring-primary-green focus:border-primary-green block w-full shadow-sm sm:text-sm rounded-md rounded h-10 px-2 py-4`}
                          value={props.profile.lastName}
                          disabled
                        />
                      </div>

                      <div className="col-span-6 sm:col-span-3 lg:col-span-3">
                        <label
                          className={`${layout[themeName]?.textLabel} block font-medium text-gray-700`}
                        >
                          {t("Shared.Profile.District")}
                        </label>
                        <input
                          type="text"
                          name="District"
                          id="District"
                          className={`${layout[themeName]?.inputBorder} bg-gray-100 mt-1 focus:ring-primary-green focus:border-primary-green block w-full shadow-sm sm:text-sm rounded-md rounded h-10 px-2 py-4`}
                          value={props.profile.districtName}
                          disabled
                        />
                      </div>
                      <div className="col-span-6 sm:col-span-3 lg:col-span-3">
                        <label
                          className={`${layout[themeName]?.textLabel} block font-medium text-gray-700`}
                        >
                          {t("Shared.Profile.School")}
                        </label>
                        <input
                          type="text"
                          name="School"
                          id="School"
                          className={`${layout[themeName]?.inputBorder} bg-gray-100 mt-1 focus:ring-primary-green focus:border-primary-green block w-full shadow-sm sm:text-sm rounded-md rounded h-10 px-2 py-4`}
                          value={props.profile.schoolName}
                          disabled
                        />
                      </div>

                      <div className="col-span-6">
                        <div className="grid grid-cols-6 gap-5 col-span-6  mt-1">
                          <div className="col-span-6  pt-5 border-b pb-2 2xl:mr-[3.5rem] xxxl:mr-24">
                            <h1 className={`${layout[themeName]?.textBig} h1`}>
                              {t("Shared.Profile.3rdPartyIntegration")}{" "}
                            </h1>
                          </div>

                          {CanvasConfiguration.EnableCanvas && (
                            <>
                              <div className="col-span-6">
                                <div className="flex ">
                                  <CanvasIcon className="w-5"></CanvasIcon>
                                  <label className="ml-1 text-sm text-gray-700">
                                    {t("Shared.Profile.Canvas")}
                                  </label>
                                </div>
                              </div>

                              <div className="col-span-6 sm:col-span-2 lg:col-span-2">
                                <label
                                  className={`${layout[themeName]?.textLabel} block font-medium text-gray-700`}
                                >
                                  {t("Shared.Profile.LoginId")}
                                </label>
                                <input
                                  type="text"
                                  id="postal_code"
                                  className={`${layout[themeName]?.inputBorder} max-w-sm bg-gray-100 mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm rounded-md rounded h-10 px-2 py-4`}
                                  value={props.profile.canvasLoginId}
                                  disabled
                                />
                              </div>
                              <div className="col-span-6 sm:col-span-2 lg:col-span-2">
                                <label
                                  className={`${layout[themeName]?.textLabel} block font-medium text-gray-700`}
                                >
                                  {t("Shared.Profile.CanvasId")}
                                </label>
                                <input
                                  type="text"
                                  id="postal_code"
                                  className={`${layout[themeName]?.inputBorder} max-w-sm bg-gray-100 mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm rounded-md rounded h-10 px-2 py-4`}
                                  value={
                                    props.profile.canvasId
                                      ? props.profile.canvasId
                                      : ""
                                  }
                                  disabled
                                />
                              </div>
                              {!props.profile.canvasLoginId && (
                                <div className="col-span-6 sm:col-span-2 lg:col-span-2">
                                  <label className="block text-sm font-medium text-gray-700 mb-1">
                                    {t("Shared.Profile.CanvasLinkStatus")}{" "}
                                  </label>

                                  <UpgradeButtonControl
                                    controlType={"div"}
                                    userContext={props?.userContext}
                                    isUpgradeRequired={
                                      props?.profile?.isUpgradeRequired
                                    }
                                    isRoleCheck={true}
                                    className={
                                      "inline-flex space-x-2 border-[#E6252A] text-white border bg-[#E6252A] pr-4 cursor-pointer " +
                                      (props?.profile?.isUpgradeRequired
                                        ? "cursor-not-allowed bg-opacity-20"
                                        : "cursor-pointer")
                                    }
                                    onClick={() => {
                                      redirectToCanvasLogin();
                                    }}
                                  >
                                    <span className="flex rounded bg-white w-16 justify-center items-center h-10">
                                      <CanvasIcon className="w-5"></CanvasIcon>
                                    </span>

                                    <span className="w-full pl-[32px] md:pl-[16px] 2xl:pl-[28px] xxxl:pl-[32px] text-sm xxxl:text-base text-white font-Barlow inline-block bg-class-blue flex items-center">
                                      {t("Shared.Profile.SigninwithCanvas")}
                                    </span>
                                  </UpgradeButtonControl>
                                </div>
                              )}
                              {props.profile.canvasLoginId && (
                                <div className="col-span-6 sm:col-span-2 lg:col-span-2">
                                  <label className="block text-sm font-medium text-gray-700 mb-1">
                                    {t("Shared.Profile.CanvasLinkStatus")}{" "}
                                  </label>

                                  <Button
                                    className="w-full sm:w-auto mt-1 border-red-500 text-red-500"
                                    layout="outline"
                                    onClick={(e) => {
                                      handleUnLinkCanvas();
                                    }}
                                  >
                                    {t("Shared.Profile.UnlinkCanvas")}
                                  </Button>
                                </div>
                              )}
                            </>
                          )}
                          <div className="col-span-6">
                            <div className="flex ">
                              <GoogleClassroomIcon className="w-5"></GoogleClassroomIcon>
                              <label className="ml-1 text-sm text-gray-700">
                                {t("Shared.Profile.GoogleClassroom")}
                              </label>
                            </div>
                          </div>

                          <div className="col-span-6 sm:col-span-2 lg:col-span-2">
                            <label
                              className={`${layout[themeName]?.textLabel} block font-medium text-gray-700`}
                            >
                              {t("Shared.Profile.GmailId")}
                            </label>
                            <input
                              type="text"
                              id="postal_code"
                              className={`${layout[themeName]?.inputBorder} max-w-sm bg-gray-100 mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm rounded-md rounded h-10 px-2 py-4`}
                              value={props.profile.gmailId}
                              disabled
                            />
                          </div>
                          <div className="col-span-6 sm:col-span-2 lg:col-span-2">
                            <label
                              className={`${layout[themeName]?.textLabel} block font-medium text-gray-700`}
                            >
                              {t("Shared.Profile.GoogleId")}
                            </label>
                            <input
                              type="text"
                              id="postal_code"
                              className={`${layout[themeName]?.inputBorder} max-w-sm bg-gray-100 mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm rounded-md rounded h-10 px-2 py-4`}
                              value={
                                props.profile.googleId
                                  ? props.profile.googleId
                                  : ""
                              }
                              disabled
                            />
                          </div>
                          {!props.profile.gmailId && (
                            <div className="col-span-6 sm:col-span-2 lg:col-span-2">
                              <label className="block text-sm font-medium text-gray-700 mb-1">
                                {t("Shared.Profile.GoogleLinkStatus")}{" "}
                              </label>

                              <UpgradeButtonControl
                                controlType={"div"}
                                userContext={props?.userContext}
                                isUpgradeRequired={
                                  props?.profile?.isUpgradeRequired
                                }
                                isRoleCheck={true}
                                className={
                                  "inline-flex space-x-2 bg-blue-600 text-white border border-blue-600 h-10 pr-4 cursor-pointer " +
                                  (props?.profile?.isUpgradeRequired
                                    ? "cursor-not-allowed bg-opacity-20"
                                    : "cursor-pointer")
                                }
                                onClick={redirectToGoogleclassNameroomLogin}
                              >
                                <div className="abcRioButtonIcon p-2 flex items-center bg-white">
                                  <div className="abcRioButtonSvgImageWithFallback abcRioButtonIconImage abcRioButtonIconImage18">
                                    <GoogleLoginIcon title="Google Classroom" />
                                  </div>
                                </div>
                                <span className="abcRioButtonContents text-xs flex items-center">
                                  <span id="not_signed_int8aitmh8ix7c ml-4">
                                    {t("Shared.Profile.SigninwithGoogle")}
                                  </span>
                                  <span id="connectedt8aitmh8ix7c"></span>
                                </span>
                              </UpgradeButtonControl>
                            </div>
                          )}
                          {props.profile.gmailId && (
                            <div className="col-span-6 sm:col-span-2 lg:col-span-2">
                              <label className="block text-sm font-medium text-gray-700 mb-1">
                                {t("Shared.Profile.GoogleLinkStatus")}{" "}
                              </label>

                              <Button
                                className="w-full sm:w-auto mt-1 border-red-500 text-red-500"
                                layout="outline"
                                onClick={(e) => {
                                  handleUnLinkGoogle();
                                }}
                              >
                                {t("Shared.Profile.UnlinkGoogleClassroom")}
                              </Button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {studentClassProfile.length > 0 && (
                    <div className="max-w-5xl">
                      <div className="grid grid-cols-6 px-4 pb-2 gap-x-6 gap-y-4 2xl:pr-[3.5rem] xxxl:pr-24">
                        <div className=" mt-6 border-gray-200 border bg-gray-50 p-2 rounded-lg col-span-6">
                          <div className="col-span-6 border-b pb-2">
                            <h3 className="h1 text-base">
                              {t("Shared.Profile.Classes")}
                            </h3>
                          </div>
                          <div className="overflow-auto rounded-md">
                            <table className="table-fixed w-full">
                              <thead className=" rounded-2xl overflow-hidden">
                                <tr className="bg-light-violet text-white py-3 px-3">
                                  <th
                                    className={`${layout[themeName]?.tFont} text-left p-3 font-normal`}
                                  >
                                    {t("Shared.Profile.ClassName")}
                                  </th>
                                  <th
                                    className={`${layout[themeName]?.tFont} text-left p-3 font-normal`}
                                  >
                                    {t("Shared.Profile.Grade")}
                                  </th>
                                  <th
                                    className={`${layout[themeName]?.tFont} text-left p-3 font-normal`}
                                  >
                                    {t("Shared.Profile.Teacher")}
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {studentClassProfile.map(
                                  (studentclass, index) => {
                                    return (
                                      <tr
                                        key={index}
                                        className={`hover:bg-light-violet/20 transition ease-in-out duration-300 ${
                                          index % 2 === 0
                                            ? "bg-white"
                                            : "bg-light-violet/10"
                                        }`}
                                      >
                                        <td
                                          className={`${layout[themeName]?.tFont} font-normal p-3`}
                                        >
                                          {studentclass.className}
                                        </td>
                                        <td
                                          className={`${layout[themeName]?.tFont} font-normal p-3`}
                                        >
                                          {studentclass.gradeCode}
                                        </td>
                                        <td
                                          className={`${layout[themeName]?.tFont} font-normal p-3`}
                                        >
                                          {studentclass.teacherName}
                                        </td>
                                      </tr>
                                    );
                                  }
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {studentFamily.length > 0 && (
                    <div className=" mx-auto space-y-3 px-4 pt-4 pb-2 gap-5 hidden">
                      <div className="col-span-6  pt-5 border-b pb-2">
                        <h3 className="h1 text-base">
                          {t("Shared.Profile.Family")}
                        </h3>
                      </div>
                      <table className="table-fixed w-full text-sm bg-gray-50 rounded-lg">
                        <thead className=" rounded-2xl overflow-hidden">
                          <tr className="bg-gray-200 px-2 rounded-2xl overflow-hidden space-x-4 rounded-2xl overflow-hidden">
                            <th className="font-normal text-left px-2  rounded-tl-lg ">
                              {t("Shared.Profile.Name")}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {studentFamily.map((family, index) => {
                            return (
                              <tr className="" key={index}>
                                <td className="font-light px-2 py-2">
                                  {family.parentName}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(Profile);

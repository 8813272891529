import gamebgtop from "../../../../../assets/images/thinkorswim/gamebgtop.jpg";
import gamebgbottom from "../../../../../assets/images/thinkorswim/gamebgbottom.jpg";
import { IGameSessionItem, IGameSessionStandardsItems, ISelectedStandard, ISessionTeam } from "../../../../../model/interface/classroomGame/thinkOrSwimSetup"
import AnglerfishButton from "./anglerfishButton"
import ShellButton from "./shellButton"
import SubmarineButton from "./submarineButton"
import TextBubble from "./textBubble"

interface ITOSGamePlayMainScreenProps {
  sessionStandards: Array<ISelectedStandard>,
  sessionItems: Array<IGameSessionStandardsItems>,
  sessionTeams: Array<ISessionTeam>,
  teamTurn: number,
  setShowScoreboard: (show: boolean) => void,
  handleShellClick: (standardIndex: number, questionIndex: number) => void,
  endGame: () => void,
}

function TOSGamePlayMainScreen(props: ITOSGamePlayMainScreenProps) {
  const {
    sessionStandards,
    sessionItems,
    sessionTeams,
    teamTurn,
    setShowScoreboard,
    handleShellClick,
    endGame,
  } = props;

  return (
    <div>
      <div className="relative overflow-hidden">
        <div className="absolute top-[40%] left-[20%] w-[40%]">
          <TextBubble 
            text={`${sessionTeams[teamTurn]?.name || 'Team'}, choose a question!`}
            textSizeCSSClass="text-[1.6vw]"
            isBubblePointingToLeft
          />
        </div>
        <div className="absolute left-[64%] w-[35%]">
          <SubmarineButton 
            text="Scoreboard"
            onClick={() => {setShowScoreboard(true)}}
          />
        </div>
        <img
          className="w-full"
          src={gamebgtop}
          alt="Top Background"
        />
      </div>
      <div className="p-4" style={{backgroundImage: "linear-gradient(rgba(74,145,233,1), rgba(62,114,223,1))"}}>
        {sessionStandards.map((standard: ISelectedStandard, index: number) => (
          <div key={index} className="grid grid-cols-2">
            <div className="col-span-1 text-white font-medium text-[1.7vw]">
              {standard?.standardName || ''}
            </div>
            <div className="grid grid-cols-8">
              {sessionItems.find(item => item.standardId == standard.standardId)?.sessionItems.map((item: IGameSessionItem, itemIndex: number) => (
                <div key={itemIndex}>
                  <ShellButton 
                    text={"" + (index * 5 + itemIndex +1)}
                    isOn={!item.dateAnswered}
                    onClick={() => {
                      if(!item.dateAnswered) 
                        handleShellClick(index, itemIndex)
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
      <div className="relative overflow-hidden">
        <div className="absolute top-[30%] left-[50%] -translate-x-1/2 -translate-y-1/2 w-[20vw]">
          <AnglerfishButton
            text="End Game"
            canTextShink={true}
            onClick={endGame}
          />
        </div>
        <img
          className="w-full"
          src={gamebgbottom}
          alt="Bottom Background"
        />
      </div>
    </div>
  )
}

export default TOSGamePlayMainScreen

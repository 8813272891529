import { AxiosHelper } from "../../environment/axiosConfig";
import APIConstant from "../../utils/constant/apiConstant";

export const GetStudentSchoolGameScore = (
  userId: number,
  gradeId: number,
  gameId: number,
  programMode: number,
  schoolId: number
) => {
  return AxiosHelper.axiosInstance().get(
    APIConstant.Student_API.StudentSchoolGameScore,
    {
      params: {
        userId: userId,
        gradeId: gradeId,
        gameId: gameId,
        programMode: programMode,
        schoolId: schoolId,
      },
    }
  );
};
export const GetStudentGalaxyGameScore = (
  userId: number,
  gradeId: number,
  gameId: number,
  programMode: number
) => {
  return AxiosHelper.axiosInstance().get(
    APIConstant.Student_API.StudentGalaxyGameScore,
    {
      params: {
        userId: userId,
        gradeId: gradeId,
        gameId: gameId,
        programMode: programMode,
      },
    }
  );
};
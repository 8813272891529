import { AxiosHelper } from "../../environment/axiosConfig";
import { IAlienArena } from "../../model/interface/alienArena";
import APIConstant from "../../utils/constant/apiConstant";

export const getAllAlienArenaSessions = (
  teacherId: number,
  schoolId: number,
  pageSize: number,
  pageNumber: number
) => {
  return AxiosHelper.axiosInstance().get(
    APIConstant.ALIEN_ARENA_API.getAllSessions,
    {
      params: {
        isActive: true,
        userId: teacherId,
        SchoolId: schoolId,
        pageSize: pageSize,
        pageNumber: pageNumber,
      },
    }
  );
};

export const getAllAlienArenaSessionsByStudent = (studentId: number) => {
  return AxiosHelper.axiosInstance().get(
    APIConstant.ALIEN_ARENA_API.getAllSessions,
    {
      params: {
        userId: studentId,
        pageSize: 9999,
        pageNumber: 1,
      },
    }
  );
};
export const getGames = (
  userId: number,
  gradeId: number,
  programMode: number
) => {
  return AxiosHelper.axiosInstance().get(APIConstant.ALIEN_ARENA_API.getGames, {
    params: {
      userId: userId,
      gradeId: gradeId,
      programMode: programMode,
    },
  });
};

export const getAlienArenaSessionById = (sessionId: number) => {
  return AxiosHelper.axiosInstance().get(
    APIConstant.ALIEN_ARENA_API.getSessionById,
    {
      params: {
        AlienArenaId: sessionId,
      },
    }
  );
};
export const getAlienArenaStudentsByClass = (classId: number) => {
  return AxiosHelper.axiosInstance().get(
    APIConstant.CLASSMANAGEMENT_API.GetClassStudent,
    {
      params: {
        ClassId: classId,
        PageSize: 999,
        PageNumber: 1,
      },
    }
  );
};
export const getStandardsBySubject = (subjectId: number) => {
  return AxiosHelper.axiosInstance().get(
    APIConstant.ALIEN_ARENA_API.getStandardsBySubject,
    {
      params: {
        SubjectId: subjectId,
      },
    }
  );
};

export const createAlienArena = (alienArenaSetup: IAlienArena) => {
  return AxiosHelper.axiosInstance().post(
    APIConstant.ALIEN_ARENA_API.createAlienArena,
    alienArenaSetup,
    {}
  );
};
export const updateAlienArena = (alienArenaSetup: IAlienArena) => {
  return AxiosHelper.axiosInstance().put(
    APIConstant.ALIEN_ARENA_API.createAlienArena,
    alienArenaSetup,
    {}
  );
};
export const copyAlienArenaSession = (
  alienArenaId: number,
  sessionName: string
) => {
  return AxiosHelper.axiosInstance().post(
    APIConstant.ALIEN_ARENA_API.copyAlienArenaSession,
    { alienArenaId: alienArenaId, sessionName: sessionName },
    {}
  );
};
export const deleteAlienArena = (SessionId: string) => {
  return AxiosHelper.axiosInstance().delete(
    APIConstant.ALIEN_ARENA_API.deleteAlienArenaSessionById,
    {
      params: {
        SessionId: SessionId,
      },
    }
  );
};

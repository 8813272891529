import React from "react";

export const AccessDenied: React.FC = () => {
  return (
    <div className="w-full grid place-items-center text-red-500 text-lg">
      <span>Access Denied</span>
    </div>
  );
};

export default AccessDenied;

export default function Download(
  data: any,
  fileName: string,
  extension: string
) {
  const link = document.createElement("a");
  link.href = "data:application/octet-stream;base64," + data;
  link.target = "_blank";
  link.download = `${fileName}.${extension}`;
  link.click();
}

import React from "react"	
import defaultTheme from "../theme/egtheme"	

interface ThemeContextInterface {	
  theme: any	
  mode?: any	
  toggleMode?: any	
}	

export const ThemeContext = React.createContext<ThemeContextInterface>({ theme: defaultTheme })	

interface ThemeProviderProps {	
  children: React.ReactNode	
  value?: any	
}	

export const ThemeProvider: React.FC<ThemeProviderProps> = ({ children, value }) => {	
  return <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>	
}
import classNames from "classnames";
import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { GetAccountDetail } from "../../../api/account/accountApi";
import { DownArrowIcon } from "../../../assets/icons";
import Profile from "../../../model/users/profile";
import { UserContextState } from "../../../redux/actions/userContextAction";
import constant from "../../../utils/constant/constant";
import SubscriptionRequest from "./popup/subscriptionRequest";
import TrialSubscriptionRequest from "../../shared/trialSubscriptionRequestPopup";
import Loader from "../../shared/loader";
import { getSchoolByDistrictId } from "../../../api/teacher/school";
import { ISchool } from "../../../model/interface/school";
import { Select } from "@windmill/react-ui";
import { IAccountDetailSearch } from "../../../model/interface/accountDetail";
import { SubscriptionRequestType } from "../../../types/type";

interface IAccountInfo {
  userContext?: UserContextState;
  profile?: Profile;
}

const AccountInfo = (props: IAccountInfo) => {
  const [account, setAccount] = useState<any>({});
  const [selectedSubject, setSelectedSubject] = useState<Array<number>>([]);
  const [toggleAdditionalLicense, setToggleAdditionalLicense] = useState(false);

  const [hasSubjectSelectedToRenew, setHasSubjectSelectedToRenew] =
    useState(false);
  const [selectedSubjectForQuote, setSelectedSubjectForQuote] = useState<
    Array<number>
  >([]);
  const [hasSubjectSelectedForQuote, setHasSubjectSelectedForQuote] =
    useState(false);

  const [showRenewSubjectPopup, setShowRenewSubjectPopup] = useState(false);
  const [subjectList, setSubjectList] = useState<Array<string>>([]);
  const [subjectIds, setSubjectIds] = useState<Array<number>>([]);
  const [requestType, setRequestType] = useState<SubscriptionRequestType>(
    SubscriptionRequestType.Quote
  );
  const [isActiveLicense, setisActiveLicense] = useState(false);
  const [loading, setLoading] = useState(false);
  const [ShowQuoteRequestPopup, setShowQuoteRequestPopup] = useState(false);
  const [ShowTrialRequestPopup, setShowTrialRequestPopup] = useState(false);
  const [checkboxesAdditionalLicense, setCheckboxesAdditionalLicense] =
    useState({});
  const [checkboxesPurchasedLicense, setCheckboxesPurchasedLicense] = useState(
    {}
  );
  const [schools, setSchools] = useState<ISchool[]>([]);
  const [selectedSchool, setSelectedSchool] = useState<number>(0);

  useEffect(() => {
    if (constant.UserRoleId.District === props.userContext?.roleId) {
      getSchools();
    }
    if (constant.UserRoleId.District !== props.userContext?.roleId) {
      getPurchasedLicense();
    }
  }, []);

  function getSchools() {
    getSchoolByDistrictId(props.userContext?.districtId ?? 0).then((d) => {
      setSchools(d.data);
    });
  }

  function getPurchasedLicense(schoolId?: number) {
    const accountSearch: IAccountDetailSearch = {
      userId: props.userContext?.userId ?? 0,
      schoolId: schoolId,
    };
    setLoading(true);
    GetAccountDetail(accountSearch)
      .then((d) => {
        setSelectedSubject([]);
        setHasSubjectSelectedToRenew(false);
        setSelectedSubjectForQuote([]);
        setHasSubjectSelectedForQuote(false);
        setCheckboxesPurchasedLicense({});
        setCheckboxesAdditionalLicense({});
        setAccount(d.data);
        if (d.data.purchasedLicense) {
          setisActiveLicense(d.data.status === "Active");
        }
        setLoading(false);
      })
      .catch((e) => setLoading(false));
  }

  function handleSchoolChange(event: any) {
    setSelectedSchool(parseInt(event?.target?.value));
    getPurchasedLicense(parseInt(event?.target?.value));
  }

  function selectSubjectToRenew(subjectId) {
    const subjectIndex: number = selectedSubject.indexOf(subjectId);
    if (subjectIndex !== -1) {
      const filterdArray = selectedSubject.filter((r) => {
        return r !== subjectId;
      });
      setSelectedSubject(filterdArray);
      setHasSubjectSelectedToRenew(filterdArray.length !== 0);
    } else {
      selectedSubject.push(subjectId);
      setSelectedSubject(selectedSubject);
      setHasSubjectSelectedToRenew(true);
    }
  }

  function selectSubjectToQuote(subjectId) {
    const subjectIndex: number = selectedSubjectForQuote.indexOf(subjectId);
    if (subjectIndex !== -1) {
      const filterdArray = selectedSubjectForQuote.filter((r) => {
        return r !== subjectId;
      });
      setSelectedSubjectForQuote(filterdArray);
      setHasSubjectSelectedForQuote(filterdArray.length !== 0);
    } else {
      selectedSubjectForQuote.push(subjectId);
      setSelectedSubjectForQuote(selectedSubjectForQuote);
      setHasSubjectSelectedForQuote(true);
    }
  }

  function renewSubjects() {
    var subjectList = account.purchasedLicense
      .filter((t) => selectedSubject.includes(t.subjectId))
      .map((m) => m.name);
    setSubjectList(subjectList);

    var subIds = account.purchasedLicense
      .filter((t) => selectedSubject.includes(t.subjectId))
      .map((m) => parseInt(m.subjectId));
    setSubjectIds(subIds);

    setRequestType(SubscriptionRequestType.Renew);
    setShowRenewSubjectPopup(true);
  }

  function getQutes() {
    var subjectList = account.additionalLicense
      .filter((t) => selectedSubjectForQuote.includes(t.subjectId))
      .map((m) => m.name);
    setSubjectList(subjectList);

    var subIds = account.additionalLicense
      .filter((t) => selectedSubjectForQuote.includes(t.subjectId))
      .map((m) => parseInt(m.subjectId));
    setSubjectIds(subIds);

    setRequestType(SubscriptionRequestType.Quote);
    setShowQuoteRequestPopup(true);
  }

  function trialRequest() {
    var subjectList = account.additionalLicense
      .filter((t) => selectedSubjectForQuote.includes(t.subjectId))
      .map((m) => m.name);
    setSubjectList(subjectList);

    var subIds = account.additionalLicense
      .filter((t) => selectedSubjectForQuote.includes(t.subjectId))
      .map((m) => parseInt(m.subjectId));
    setSubjectIds(subIds);

    setRequestType(SubscriptionRequestType.Trial);
    setShowTrialRequestPopup(true);
  }

  function getTeacherFreeQuotes() {
    var subjectList = account.purchasedLicense
      .filter((t) => selectedSubject.includes(t.subjectId))
      .map((m) => m.name);
    setSubjectList(subjectList);
    setRequestType(SubscriptionRequestType.Quote);
    setShowRenewSubjectPopup(true);
  }
  function isUserRole(userRoleId: number) {
    return props.userContext?.roleId === userRoleId;
  }

  function shouldShowData() {
    return (
      !isUserRole(constant.UserRoleId.District) ||
      (isUserRole(constant.UserRoleId.District) && selectedSchool !== 0)
    );
  }

  return (
    <>
      {loading && <Loader></Loader>}
      {isUserRole(constant.UserRoleId.District) && (
        <div>
          <div className="w-64 mb-8">
            <label className="block mb-1 text-sm text-gray-500">School</label>
            <div className="flex">
              <Select
                value={selectedSchool!}
                onChange={(e) => {
                  handleSchoolChange(e);
                }}
                className="py-2.5 px-4 block text-sm border border-gray-400 
             border-opacity-50 focus:ring-primary-violet focus:border-primary-violet form-select leading-5 p-1 rounded-lg"
              >
                {selectedSchool === 0 && (
                  <option value={0}>Select school</option>
                )}
                {schools?.map((school: ISchool, index) => {
                  return (
                    <Fragment key={index}>
                      <option value={school.schoolId}>{school.name}</option>
                    </Fragment>
                  );
                })}
              </Select>
            </div>
          </div>
        </div>
      )}
      {shouldShowData() && (
        <>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-x-6 gap-y-4">
            <div>
              <label className="block text-sm text-gray-500 font-medium">
                Subscription Type
              </label>
              <span className="mt-1 text-gray-700">
                {account.subscriptionType}
              </span>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-500">
                Status
              </label>
              {loading ? (
                ""
              ) : (
                <span
                  className={classNames(
                    "flex-shrink-0 inline-block px-2 py-0.5 mt-1 text-xs font-medium  rounded-full ",
                    isActiveLicense
                      ? "bg-green-100 text-green-800"
                      : "bg-red-100 text-red-800"
                  )}
                >
                  {isActiveLicense ? "active" : "expired"}
                </span>
              )}
            </div>

            <div>
              <label className="block text-sm text-gray-500 font-medium">
                Payment Type
              </label>
              <span className="mt-1 text-gray-700">Offline</span>
            </div>
          </div>

          <div className="mt-4 border-gray-200 border bg-gray-50 p-2 rounded-lg">
            <div className="text-sm font-medium mb-2">Purchased Licenses</div>
            <div className="overflow-auto h-64">
              <table className="table w-full">
                <thead className="sticky top-0">
                  <tr className="bg-light-violet text-white py-3 px-3">
                    <th className="text-center p-3 text-sm font-normal w-10">
                      Select
                    </th>
                    <th className="text-left p-3 text-sm font-normal w-56">
                      Name
                    </th>
                    <th></th>
                    <th className="text-center p-3 text-sm font-normal">
                      License Start Date
                    </th>
                    <th className="text-center p-3 text-sm font-normal">
                      License Expiration Date
                    </th>
                  </tr>
                </thead>
                <tbody className="h-full overflow-y-auto">
                  {account.purchasedLicense?.map((subject, index) => (
                    <Fragment key={index}>
                      <tr
                        key={subject.classId}
                        className={`hover:bg-light-violet/20 transition ease-in-out duration-300 ${
                          index % 2 === 0 ? "bg-white" : "bg-light-violet/10"
                        }`}
                      >
                        <td className="text-center p-3 text-sm font-normal">
                          {" "}
                          <input
                            type="checkbox"
                            className="p-3"
                            name={`PL-${subject.subjectId}`}
                            checked={
                              !!checkboxesPurchasedLicense[
                                `PL-${subject.subjectId}`
                              ]
                            }
                            onChange={(event) => {
                              const { name, checked } = event.target;
                              setCheckboxesPurchasedLicense((prevState) => ({
                                ...prevState,
                                [name]: checked,
                              }));
                              selectSubjectToRenew(subject.subjectId);
                            }}
                          />
                        </td>

                        <td className="text-left p-3 text-sm font-normal">
                          {" "}
                          {subject.name}
                        </td>
                        <td className="items-center">
                          {subject.subscriptionRequestType &&
                            subject.subscriptionRequestType === "Trial" && (
                              <span
                                className="mt-2 md:mt-0 md:ml-3 items-center inline-block my-0 px-5 py-1.5 bg-red-200 text-gray-700 text-xs 
                                              leading-tight rounded-full whitespace-nowrap "
                              >
                                Trial
                              </span>
                            )}
                        </td>
                        <td className="text-left p-3 text-sm font-normal">
                          {" "}
                          <div className="flex justify-center">
                            {moment(subject.startDate).format("MM/DD/YYYY")}
                          </div>
                        </td>
                        <td className="text-left p-3 text-sm font-normal">
                          <div className="flex justify-center">
                            <span
                              className={classNames(
                                " inline-block px-2 py-0.5 text-sm rounded-full",
                                new Date(subject.endDate) < new Date()
                                  ? "bg-red-100 text-red-800"
                                  : "bg-gray-100 text-gray-700"
                              )}
                            >
                              {moment(subject.endDate).format("MM/DD/YYYY hh:mm A")}
                            </span>
                          </div>
                        </td>
                      </tr>
                    </Fragment>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="text-right justify-end">
              {props.userContext?.roleId == constant.UserRoleId.FreeTeacher && (
                <button
                  type="button"
                  className="disabled:opacity-50 disabled:cursor-not-allowed ml-2 mt-6 bg-primary-violet border border-transparent shadow-sm py-2 px-4 justify-end text-sm font-medium text-white hover:bg-dark-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 whitespace-nowrap"
                  disabled={!hasSubjectSelectedToRenew}
                  onClick={getTeacherFreeQuotes}
                >
                  Get Quote
                </button>
              )}
              {props.userContext?.roleId !==
                constant.UserRoleId.FreeTeacher && (
                <button
                  type="button"
                  className="disabled:opacity-50 disabled:cursor-not-allowed ml-2 mt-6 bg-primary-violet border border-transparent shadow-sm py-2 px-4 justify-end text-sm font-medium text-white hover:bg-dark-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 whitespace-nowrap"
                  disabled={!hasSubjectSelectedToRenew}
                  onClick={renewSubjects}
                >
                  Renew
                </button>
              )}
            </div>
          </div>
          <div className="mt-4 border-gray-200 border bg-gray-50 p-2 rounded-lg">
            <div
              className="inline-flex text-sm font-medium cursor-pointer group"
              onClick={() => {
                setToggleAdditionalLicense(!toggleAdditionalLicense);
              }}
            >
              <span className="group-hover:opacity-80">
                {" "}
                Additional licenses for purchase
              </span>
              <DownArrowIcon
                className={`ml-2 w-5 transition-all duration-300 transform group-hover:opacity-80
            ${toggleAdditionalLicense ? "-rotate-0" : "-rotate-90"}
            `}
              />
            </div>

            <div
              className={` ${
                toggleAdditionalLicense
                  ? "h-full opacity-100 pt-2"
                  : "h-0 opacity-0"
              } transition-all transform duration-300 `}
            >
              <div
                className={` ${
                  toggleAdditionalLicense ? "overflow-auto h-64 " : "h-0"
                } transition-all transform duration-300 `}
              >
                <table
                  className={` ${
                    toggleAdditionalLicense
                      ? "w-full scale-y-100 "
                      : "w-full scale-y-0"
                  } transition-all transform duration-300 `}
                >
                  <thead className="sticky top-0">
                    <tr className="bg-light-violet text-white py-3 px-3">
                      <th className="text-center p-3 text-sm font-normal w-10">
                        Select
                      </th>
                      <th className="text-left p-3 text-sm font-normal">
                        Name
                      </th>
                    </tr>
                  </thead>
                  <tbody className="h-full overflow-y-auto">
                    {account.additionalLicense?.map((subject, index) => (
                      <Fragment key={index}>
                        <tr
                          key={subject.classId}
                          className={`hover:bg-light-violet/20 transition ease-in-out duration-300 ${
                            index % 2 === 0 ? "bg-white" : "bg-light-violet/10"
                          }`}
                        >
                          <td className="text-center p-3 text-sm font-normal">
                            <input
                              className="p-3 text-right justify-end"
                              type="checkbox"
                              name={`AL-${subject.subjectId}`}
                              checked={
                                !!checkboxesAdditionalLicense[
                                  `AL-${subject.subjectId}`
                                ]
                              }
                              onChange={(event) => {
                                const { name, checked } = event.target;
                                setCheckboxesAdditionalLicense((prevState) => ({
                                  ...prevState,
                                  [name]: checked,
                                }));
                                selectSubjectToQuote(subject.subjectId);
                              }}
                            />
                          </td>
                          <td>
                            {" "}
                            <div className=" flex flex-col w-full">
                              <span>{subject.name}</span>
                              <span className="text-xs">
                                {subject.description}
                              </span>
                            </div>
                          </td>
                        </tr>
                      </Fragment>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="flex items-end">
                <div className="ml-auto">
                  <button
                    type="button"
                    className={
                      (props.userContext?.roleId ===
                      constant.UserRoleId.FreeTeacher
                        ? "hidden "
                        : "") +
                      "disabled:opacity-50 bg-primary-violet border border-transparent shadow-sm py-2 px-4 justify-end text-sm font-medium text-white hover:bg-dark-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 whitespace-nowrap m-2"
                    }
                    disabled={!hasSubjectSelectedForQuote}
                    onClick={trialRequest}
                  >
                    Request Trial
                  </button>
                  <button
                    type="button"
                    className="disabled:opacity-50 bg-primary-violet border border-transparent shadow-sm py-2 px-4 justify-end text-sm font-medium text-white hover:bg-dark-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 whitespace-nowrap"
                    disabled={!hasSubjectSelectedForQuote}
                    onClick={getQutes}
                  >
                    Get Quote
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {showRenewSubjectPopup === true && (
        <SubscriptionRequest
          showPopUp={(show: boolean) => {
            setShowRenewSubjectPopup(false);
          }}
          subjects={subjectList}
          subjectIds={subjectIds}
          requestType={requestType}
          title="Renew Subscription"
          okButtonText="Renew"
          showCSM={true}
          text={`Hello, I would like to information to renew the following license(s) \n\n`}
          schoolId={
            isUserRole(constant.UserRoleId.District)
              ? selectedSchool
              : props.userContext?.schoolId
          }
        />
      )}
      {ShowQuoteRequestPopup === true && (
        <SubscriptionRequest
          showPopUp={(show: boolean) => {
            setShowQuoteRequestPopup(false);
          }}
          subjects={subjectList}
          subjectIds={subjectIds}
          requestType={requestType}
          title="Request a Quote"
          okButtonText="Quote"
          showCSM={true}
          text={`Hello, I would like to information to quote the following license(s) \n\n`}
          schoolId={
            isUserRole(constant.UserRoleId.District)
              ? selectedSchool
              : props.userContext?.schoolId
          }
        />
      )}
      {ShowTrialRequestPopup === true && (
        <TrialSubscriptionRequest
          showPopUp={(show: boolean) => {
            setShowTrialRequestPopup(false);
          }}
          subjects={subjectList}
          subjectIds={subjectIds}
          requestType={SubscriptionRequestType.Trial}
          schoolId={
            isUserRole(constant.UserRoleId.District)
              ? selectedSchool
              : props.userContext?.schoolId
          }
        />
      )}
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(AccountInfo);

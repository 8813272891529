import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  Fragment,
} from "react";
import {
  CloseIcon,
  MinusIcon,
  OutlineRefreshIcon,
} from "../../../../assets/icons/index";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/database";
import StudentChatMessage from "./studentChatMessage";
import "../chatRoom.css";
import ChatRoomProps from "../../../../model/chat/chatRoomProps";
import { useIdleTimer } from "react-idle-timer";
import Constant from "../../../../utils/constant/constant";
import { GetTeachersByStudentId } from "../../../../api/chat/teacher";
import { GetClassesByStudentId } from "../../../../api/chat/class";
import debounce from "lodash.debounce";
import { Configuration } from "../../../../environment/setup";
import UserTile from "../shared/userTile";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { useTranslation } from "react-i18next";
interface ChatSearch {
  id: number;
  displayName: string;
  isClass: boolean;
  profileUrl: string;
  recentMassageCount: number;
  colorCode: string;
  roleId: number;
}
function StudentChatRoom(props: ChatRoomProps) {
  const { t } = useTranslation();
  firebase.initializeApp(props.configuration);
  const db = firebase.database();
  const firestore = firebase.firestore();
  const [sendBy] = useState(props.userId);
  const [classes, setClasses] = useState([]);
  const [presenceCollection] = useState<string>(props.presenceCollection);
  const [isCollapse, setIsCollapse] = useState(true);
  const [onlineUsers, setOnlineUsers] = useState([]);
  const [idleTimer] = useState<number>(Configuration.IdealStatusTime);
  const [serachQuery, setSerachQuery] = useState<string>("");
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize] = useState<number>(10);
  const [hightClass, setHightClass] = useState<string>("h-auto");
  const listInnerRef = useRef<any>();
  const recentChatRef = firestore.collection(props.recentChatcollectionName);
  const [chatItems, setchatItems] = useState([]);
  var [position] = useState<number>(100);
  const chatQuery: any = recentChatRef.where("userId", "==", props.userId);
  var [reactChats, loading]: any = useCollectionData(chatQuery);

  const [chatWindow, setChatWindow] = useState([
    {
      isActive: false,
      sendTO: {
        id: 0,
        displayName: "",
        isClass: false,
        profileUrl: "",
        colorCode: "",
        roleId: 0,
        isCollapse: false,
        hightClass: "auto",
      },
    },
    {
      isActive: false,
      sendTO: {
        id: 0,
        displayName: "",
        isClass: false,
        profileUrl: "",
        colorCode: "",
        roleId: 0,
        isCollapse: false,
        hightClass: "auto",
      },
    },
    {
      isActive: false,
      sendTO: {
        id: 0,
        displayName: "",
        isClass: false,
        profileUrl: "",
        colorCode: "",
        roleId: 0,
        isCollapse: false,
        hightClass: "auto",
      },
    },
    {
      isActive: false,
      sendTO: {
        id: 0,
        displayName: "",
        isClass: false,
        profileUrl: "",
        colorCode: "",
        roleId: 0,
        isCollapse: false,
        hightClass: "auto",
      },
    },
  ]);

  const changeHandler = (event: any) => {
    setSerachQuery(event.target.value);
    getStudent(event.target.value);
  };

  useIdleTimer({
    timeout: idleTimer,
    onActive: () => handleOnActive(),
    onIdle: () => handleOnIdle(),
    debounce: 300
  });

  const debouncedChangeHandler = useCallback(debounce(changeHandler, 1000), []);

  useEffect(() => {
    const ref = db.ref(presenceCollection);
    ref.on(
      "value",
      (snapshot) => {
        if (snapshot.val()) {
          setOnlineUsers(snapshot.val());
        } else {
          setOnlineUsers([]);
        }
      },
      (errorObject) => {
        console.log("The read failed: " + errorObject.name);
      }
    );
    handleOnActive();
    getStudent(serachQuery);
    getStudentClasses();
  }, []);

  const getStudent = (query: string) => {
    query = query ? query : serachQuery;
    var students: any = chatItems;
    var studentSearchQuery: any = {
      studentId: props.userId,
      searchQuery: query,
      pageNumber: pageNumber,
      pageSize: pageSize,
    };

    GetTeachersByStudentId(props.token, studentSearchQuery)
      .then((response) => {
        var chatSearch: ChatSearch[] = response.data.data;
        if (chatSearch.length > 0) {
          let studentList = chatSearch.filter((item) => item.isClass === false);
          for (let index = 0; index < studentList.length; index++) {
            let studentModal = studentList[index];
            let student = students.find(
              (item: any) =>
                item.id === studentModal.id && item.isClass === false
            );
            if (!student) {
              students.push({
                id: studentModal.id,
                displayName: studentModal.displayName,
                isClass: false,
                recentMassageCount: 0,
                colorCode: studentModal.colorCode,
                roleId: studentModal.roleId,
              });
            }
          }
        }
        if (query) {
          students = students.filter((item: any) =>
            item.displayName
              .toLocaleLowerCase()
              .includes(query.toLocaleLowerCase())
          );
        }
        setchatItems(Array.from(students));
        setHightClass("height-100");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getStudentClasses = () => {
    GetClassesByStudentId(props.token, sendBy).then((r) => {
      const classIds = r.data && r.data.map((c: any) => c.classId);
      setClasses(classIds);
    });
  };

  const handleStartChat = (chatSearch: ChatSearch) => {
    if (sendBy <= 0) return;

    resetChatCount(chatSearch);
    let tempChatWindow = [...chatWindow];

    var isPresent =
      chatWindow[0].sendTO.id === chatSearch.id ||
      chatWindow[1].sendTO.id === chatSearch.id ||
      chatWindow[2].sendTO.id === chatSearch.id ||
      chatWindow[3].sendTO.id === chatSearch.id;

    if (isPresent) {
      tempChatWindow = tempChatWindow.map((t) => {
        if (t.sendTO.id === chatSearch.id) {
          t.sendTO.isCollapse = true;
          t.sendTO.hightClass = "height-100";
        } else {
          t.sendTO.isCollapse = false;
          t.sendTO.hightClass = "h-auto";
        }
        return t;
      });
      const arrayvalue = [...tempChatWindow];
      setChatWindow([]);
      setChatWindow(arrayvalue);
      return;
    }

    const activeWindowCount = chatWindow.filter((cw) => cw.isActive).length;

    if (activeWindowCount === chatWindow.length) {
      tempChatWindow.shift();
      tempChatWindow.push({
        isActive: false,
        sendTO: {
          id: 0,
          displayName: "",
          isClass: false,
          profileUrl: "",
          colorCode: "",
          roleId: 0,
          isCollapse: true,
          hightClass: "height-100",
        },
      });
    }

    for (let index = 0; index < tempChatWindow.length; index++) {
      const element = tempChatWindow[index];
      if (!element.isActive && element.sendTO.id === 0) {
        element.isActive = true;
        element.sendTO = {
          id: chatSearch.id,
          displayName: chatSearch.displayName,
          isClass: chatSearch.isClass,
          profileUrl: chatSearch.profileUrl,
          colorCode: chatSearch.colorCode,
          roleId: chatSearch.roleId,
          isCollapse: true,
          hightClass: "height-100",
        };
        tempChatWindow[index] = element;
        break;
      } else {
        tempChatWindow[index].sendTO.isCollapse = false;
        tempChatWindow[index].sendTO.hightClass = "h-auto";
      }
    }

    const arrayvalue = [...tempChatWindow];
    setChatWindow([]);
    setChatWindow(arrayvalue);
  };

  const handleCollapse = () => {
    setIsCollapse(!isCollapse);
    if (isCollapse) {
      setHightClass("h-auto");
    } else {
      setHightClass("height-100");
    }
  };

  const handleOnActive = () => {
    const reference = firebase
      .database()
      .ref(`/${presenceCollection}/` + sendBy);
    reference
      .set(Constant.USERACTIVITY.Active)
      .then(() => console.log("Online presence set"));
    reference
      .onDisconnect()
      .remove()
      .then(() => console.log("On disconnect function configured."));
  };

  const handleOnIdle = () => {
    const reference = firebase
      .database()
      .ref(`/${presenceCollection}/` + sendBy);
    reference
      .set(Constant.USERACTIVITY.Ideal)
      .then(() => console.log("Online presence set"));
    reference
      .onDisconnect()
      .remove()
      .then(() => console.log("On disconnect function configured."));
  };

  const handleSearch = () => {
    getStudent(serachQuery);
  };

  const closeChatWindow = (window: number) => {
    let element = chatWindow[window];
    element.isActive = false;
    element.sendTO = {
      id: 0,
      displayName: "",
      isClass: false,
      profileUrl: "",
      colorCode: "",
      roleId: 0,
      isCollapse: false,
      hightClass: "h-auto",
    };
    chatWindow[window] = element;
    const arrayvalue = [...chatWindow];
    setChatWindow([]);
    setChatWindow(arrayvalue);
  };

  const handleCollapseChat = (window: number) => {
    let element = chatWindow[window];
    element.sendTO.isCollapse = !element.sendTO.isCollapse;
    element.sendTO.hightClass = element.sendTO.isCollapse
      ? "height-100"
      : "h-auto";
    chatWindow[window] = element;
    const arrayvalue = [...chatWindow];
    setChatWindow([]);
    setChatWindow(arrayvalue);
  };

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight >= scrollHeight) {
        var tempPageNumer = pageNumber + 1;
        setPageNumber(0);
        setPageNumber(tempPageNumer);
        getStudent(serachQuery);
      }
    }
  };

  const handleCloseChat = () => {
    props.closeChatWindow();
  };

  // const handleSettingsClicked = () => {
  //   props.onSettingsClicked();
  // };

  const resetChatCount = async (chatSearch: ChatSearch) => {
    if (chatSearch.isClass) {
      // await firestore.runTransaction(async (t: any) => {
      //   const studentId = chatSearch.id;
      //   let studentRef = firestore
      //     .collection(props.recentChatcollectionName)
      //     .doc("" + studentId);
      //   let recentChatStudent = await t.get(studentRef);
      //   if (recentChatStudent.data()) {
      //     let tempdata = recentChatStudent
      //       .data()
      //       .classes.find((item: any) => item.id === props.sendTo.id);
      //     if (tempdata) {
      //       let classList = recentChatStudent.data().classes;
      //       let objIndex = classList.findIndex(
      //         (item: any) => item.id === props.sendTo.id
      //       );
      //       classList[objIndex].recentMassageCount = 0;
      //       t.update(studentRef, { classes: classList });
      //     }
      //   }
      // });
    } else {
      await firestore.runTransaction(async (t: any) => {
        const recentChatSendByRef = firestore
          .collection(props.recentChatcollectionName)
          .doc("" + sendBy);

        const recentChatSendBy = await t.get(recentChatSendByRef);
        if (recentChatSendBy.data()) {
          let data = recentChatSendBy
            .data()
            .chatTo.find((item: any) => item.id === chatSearch.id);
          if (data) {
            let chatToList = recentChatSendBy.data().chatTo;
            let objIndex = chatToList.findIndex(
              (item: any) => item.id === chatSearch.id
            );
            chatToList[objIndex].recentMassageCount = 0;
            t.update(recentChatSendByRef, { chatTo: chatToList });
          }
        }
      });
    }
  };

  const onMessageAreaClicked = (chatSearch: any) => {
    resetChatCount(chatSearch);
  };

  return (
    <>
      <div className="w-full fixed bottom-0 right-0 z-[999]">
        <div className="flex items-end justify-end mr-24">
          {chatWindow.map((element, index) => (
            <Fragment key={index}>
              {element.isActive && element.sendTO.id > 0 && sendBy > 0 && (
                <>
                  <StudentChatMessage
                    messagecollectionName={props.messagecollectionName}
                    recentChatcollectionName={props.recentChatcollectionName}
                    position={"right-" + position}
                    sendBy={sendBy}
                    classes={classes}
                    displayName={props.displayName}
                    sendTo={element.sendTO}
                    onlineUsers={onlineUsers}
                    firestore={firestore}
                    db={db}
                    isClass={element.sendTO.isClass}
                    token={props.token}
                    schoolId={props.schoolId}
                    sendByRoleId={props.roleId}
                    closeChatWindow={() => closeChatWindow(index)}
                    isCollapse={element.sendTO.isCollapse}
                    hightClass={element.sendTO.hightClass}
                    impersonatedUser={props.impersonatedUser}
                    handleCollapse={() => {
                      handleCollapseChat(index);
                    }}
                    onMessageAreaClicked={() => {
                      onMessageAreaClicked(element.sendTO);
                    }}
                  />
                  <span className="invisible">
                    {(position = position + 10)}
                  </span>
                </>
              )}
            </Fragment>
          ))}

          <div className="w-96 bottom-0 right-28 bg-yellow-500 z-50 rounded-xl overflow-hidden shadow-2xl">
            <div
              className={`flex-1 justify-between flex flex-col ${hightClass} max-h-46 shadow-lg`}
            >
              <div className="flex sm:items-center justify-between py-3 bg-yellow-500 px-3">
                <div className="flex items-center space-x-4">
                  <div className="flex flex-col leading-tight">
                    <div className="mt-1 flex items-center ml-1">
                      <span className="text-white mr-3 text-base">
                        {t("Shared.StudentHeader.Messaging")}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="flex items-center">
                  <button
                    onClick={handleCollapse}
                    type="button"
                    className="inline-flex group items-center justify-center rounded-full h-6 w-6 transition duration-500 ease-in-out text-white hover:bg-white focus:outline-none"
                  >
                    <MinusIcon className="w-4 h-4 fill-current text-white group-hover:text-yellow-500"></MinusIcon>
                  </button>
                  <button
                    onClick={handleCloseChat}
                    type="button"
                    className="inline-flex group items-center justify-center rounded-full h-6 w-6 transition duration-500 ease-in-out text-white hover:bg-white focus:outline-none"
                  >
                    <CloseIcon className="w-4 h-4 fill-current text-white group-hover:text-yellow-500 "></CloseIcon>
                  </button>
                </div>
              </div>
              {isCollapse && (
                <>
                  <div className="px-3 flex items-center bg-yellow-500 pb-2">
                    <input
                      type="text"
                      onChange={debouncedChangeHandler}
                      placeholder={t("Shared.StudentHeader.Search")}
                      className="w-full focus:outline-none text-xs focus:placeholder-gray-400 border border-gray-300 text-gray-600 placeholder-gray-400 px-4 bg-white rounded-lg py-1.5"
                    />

                    <button
                      onClick={handleSearch}
                      type="button"
                      className="ml-2 inline-flex group items-center justify-center rounded-full h-6 w-6 transition duration-500 ease-in-out text-white hover:bg-white focus:outline-none"
                    >
                      <OutlineRefreshIcon className=" w-4 h-4 fill-current text-white group-hover:text-yellow-500  "></OutlineRefreshIcon>
                    </button>
                  </div>
                  <div className="text-sm text-gray-400 font-medium pl-4 pt-3 rounded-t-xl bg-white shadow-inner">
                    {t("Shared.StudentHeader.Recent")}
                  </div>
                  <div
                    id="chatUser"
                    onScroll={onScroll}
                    ref={listInnerRef}
                    className={`flex flex-col overflow-y-auto bg-white scrollbar-thumb-blue scrollbar-thumb-rounded scrollbar-track-blue-lighter scrollbar-w-2 scrolling-touch
                    ${hightClass} 
                    `}
                  >
                    {/* recent chats */}
                    {!loading &&
                      reactChats?.length > 0 &&
                      reactChats[0].chatTo
                        .filter((item: any) =>
                          item.displayName
                            .toLocaleLowerCase()
                            .includes(serachQuery.toLocaleLowerCase())
                        )
                        .sort((a, b) => b.updatedAt - a.updatedAt)
                        .map((chatsearch: ChatSearch, index: number) => (
                          <div key={index}>
                            {(chatsearch.isClass = false)}
                            <UserTile
                              onlineUsers={onlineUsers}
                              chatSearch={chatsearch}
                              handleStartChat={handleStartChat}
                              roleId={props.roleId}
                            ></UserTile>
                          </div>
                        ))}

                    {/* users from pl database other than recent chats */}
                    {!loading &&
                      chatItems &&
                      reactChats.length > 0 &&
                      chatItems
                        .filter(
                          (item: any) =>
                            !reactChats[0].chatTo.includes(
                              (el: any) => el.id === item.id && !item.isClass
                            )
                        )
                        .map((chatsearch: ChatSearch, index) => (
                          <div key={index}>
                            {!reactChats[0].chatTo.find(
                              (el: any) => el.id === chatsearch.id
                            ) && (
                              <UserTile
                                onlineUsers={onlineUsers}
                                chatSearch={chatsearch}
                                roleId={props.roleId}
                                handleStartChat={handleStartChat}
                              ></UserTile>
                            )}
                          </div>
                        ))}

                    {/* users from pl database when there are no recent chats */}
                    {!loading &&
                      (reactChats?.length === 0 || !reactChats) &&
                      chatItems &&
                      chatItems
                        .filter((c: ChatSearch) => !c.isClass)
                        .map((chatsearch: ChatSearch, index) => (
                          <div key={index}>
                            <UserTile
                              onlineUsers={onlineUsers}
                              chatSearch={chatsearch}
                              roleId={props.roleId}
                              handleStartChat={handleStartChat}
                            ></UserTile>
                          </div>
                        ))}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default StudentChatRoom;

import PagingResponse from "../../../../../model/common/pagingResponse";
import ClassTeacherModel from "../../../../../model/teacher/classManagement/classTeacherModel";
import InfoMessage from "../../../../shared/infoMessage";
import PageSize from "../../../../shared/pagination/pageSize";
import Pagination from "../../../../shared/pagination/pagination";

interface ClassBuilderTeachersProps {
  filterCharList: string[];
  startWith: string;
  schoolTeachers: PagingResponse<ClassTeacherModel> | undefined;
  selectAll: boolean;
  teacherPageSize: number;
  teacherPageNumber: number;
  resetTeacherPageNumber: boolean;
  isDetailsValid: boolean;  
  handleSkipClassSetup: () => void;
  handleFinalSubmit: () => void;
  handleCharFilter: (char: string) => void;
  selectAllteacher: (e: React.ChangeEvent<HTMLInputElement>) => void;
  selectTeacher: (userId: number) => void;
  setTeacherPageNumber: (pageNumber: number) => void;
  onTeacherPageSizeChange: (size: number) => void;
}

const ClassBuilderTeachers: React.FC<ClassBuilderTeachersProps> = ({
  filterCharList,
  startWith,
  schoolTeachers,
  selectAll,
  teacherPageSize,
  teacherPageNumber,
  resetTeacherPageNumber,
  isDetailsValid,
  handleSkipClassSetup,
  handleFinalSubmit,
  handleCharFilter,
  selectAllteacher,
  selectTeacher,
  setTeacherPageNumber,
  onTeacherPageSizeChange,
}) => {
  
  return (
    <div className="w-full relative overflow-auto px-4 py-6">
      <div className="w-full flex">
        {/* Teachers Selection Content */}
        <div className="flex-grow mr-4">
          <InfoMessage message="Select teachers to add to your class. They will automatically be set as secondary teachers." />
          <div className="p-3 sm:py-0 sm:space-y-0 sm:divide-y sm:divide-gray-200 text-sm text-gray-700 dark:text-gray-400">
            <div>
              <div>
                <div className="mb-3">
                  <h1 className="font-bold text-primary-violet mb-2 ">
                    Available Teachers
                  </h1>

                  <div className="grid grid-cols-12 gap-6 flex items-center">
                    <div className="col-span-9 ">
                      <ul className="flex gap-2 sm:gap-1 capitalize cursor-pointer">
                        {filterCharList.map((ch) => {
                          if (ch === "All")
                            return (
                              <li
                                key={ch}
                                className={
                                  startWith === ch
                                    ? "bg-secondary-teal flex items-center justify-center w-10 h-5 text-sm text-white hover:opacity-70"
                                    : "flex items-center justify-center bg-secondary-teal w-10 h-5 text-sm text-white hover:opacity-70"
                                }
                                onClick={() => {
                                  handleCharFilter(ch);
                                }}
                              >
                                {ch}
                              </li>
                            );
                          else
                            return (
                              <li
                                key={ch}
                                className={
                                  startWith === ch
                                    ? "bg-secondary-teal flex items-center justify-center w-5 h-5 text-sm text-white hover:opacity-70"
                                    : "flex items-center justify-center bg-primary-blue w-5 h-5 text-sm text-white hover:opacity-70"
                                }
                                onClick={() => {
                                  handleCharFilter(ch);
                                }}
                              >
                                {ch}
                              </li>
                            );
                        })}
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="overflow-x-auto shadow">
                  <table className="table w-full overflow-auto">
                    <thead className="mb-5">
                      <tr className="bg-light-violet  text-white py-3 px-3 overflow-hidden">
                        <th className="text-left  p-3 text-sm font-normal w-4">
                          <label
                            x-radio-group-option=""
                            className="relative cursor-pointer"
                          >
                            <input
                              type="checkbox"
                              x-model="value"
                              name="privacy_setting"
                              value="Private to Project Members"
                              className="h-4 w-4 cursor-pointer text-primary-violet border-gray-300 focus:ring-primary-violet"
                              aria-labelledby="privacy-setting-1-label"
                              aria-describedby="privacy-setting-1-description"
                              checked={selectAll}
                              onChange={selectAllteacher}
                            />
                          </label>
                        </th>
                        <th className="text-left  p-3 text-sm font-normal">
                          {" "}
                          Teacher{" "}
                        </th>
                        <th className="text-left  p-3 text-sm font-normal">
                          {" "}
                          Email{" "}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {schoolTeachers?.data?.map((teacher, index) => {
                        return (
                          <tr
                            key={teacher.userId}
                            className={`hover:bg-light-violet/20 transition ease-in-out duration-300 ${
                              index % 2 === 0
                                ? "bg-white"
                                : "bg-light-violet/10"
                            }`}
                          >
                            <td className=" mt-5 p-3 text-center">
                              <input
                                type="checkbox"
                                className="h-4 w-4 cursor-pointer text-primary-violet border-gray-300 focus:ring-primary-violet"
                                key={teacher.userId}
                                defaultChecked={teacher.isSelected}
                                checked={
                                  selectAll ? teacher.isSelected : undefined
                                }
                                onChange={() => {
                                  selectTeacher(teacher.userId);
                                }}
                              />
                            </td>
                            <td className="text-sm mt-5  p-3">
                              {teacher.lastName}, {teacher.firstName}
                            </td>
                            <td className="text-sm mt-5  p-3 text-left">
                              {teacher.email}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between mt-2">
                  {schoolTeachers?.totalRecords !== undefined &&
                    schoolTeachers?.totalRecords !== 0 && (
                      <Pagination
                        totalRecords={schoolTeachers?.totalRecords}
                        pageSize={teacherPageSize}
                        pageNumber={teacherPageNumber}
                        onPageChange={(e) => {
                          setTeacherPageNumber(e);
                        }}
                        reset={resetTeacherPageNumber}
                      />
                    )}
                  {schoolTeachers?.totalRecords !== 0 && (
                    <PageSize
                      pageSize={teacherPageSize}
                      onPageSizeChange={onTeacherPageSizeChange}
                    />
                  )}
                  {schoolTeachers?.totalRecords === 0 && (
                    <span className="text-color-black">No record found</span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Buttons Container */}
        <div className="ml-auto flex flex-col gap-4 w-1/6">
          <button
            className="bg-primary-violet border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-dark-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 w-full"
            onClick={handleFinalSubmit}
            disabled={!isDetailsValid}
            type="button"
          >
            Save Class
          </button>
          <button
            type="button"
            onClick={handleSkipClassSetup}
            className="bg-primary-violet border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-dark-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 w-full"
          >
            Skip Class Setup
          </button>
        </div>
      </div>
    </div>
  );
};

export default ClassBuilderTeachers;

import {
  CalendarIcon,
  CogIcon,
  DocumentIcon,
  DocumentTextIcon,
} from "@heroicons/react/solid";
import { Fragment, useState } from "react";
import base64 from "react-native-base64";
import { useHistory } from "react-router-dom";
import * as uuid from "uuid";
import urlAuthApi from "../../api/urlAuthApi";
import { Configuration } from "../../environment/setup";
import { UrlParams } from "../../model/common/urlParams";
import { UserContextState } from "../../redux/actions/userContextAction";
import RouteConstant from "../../utils/constant/routeConstant";

interface passedProps {
  title: string;
  description: string;
  css: string;
  url: string;
  props: UserContextState;
}

function TrainingCard(props: passedProps) {
  const history = useHistory();
  const [trainingUrl, setTrainingUrl] = useState("");
  const userId = props.props && props.props?.userId;

  if (userId !== undefined && userId > 0 && trainingUrl === "") {
    urlAuthApi.GetURLParams(userId).then((data: Array<any>) => {
      const u: UrlParams = new UrlParams();
      Object.assign(u, data);
      setTrainingUrl(
        `${Configuration.TrainingUrl}?g=${uuid.v4()}&u=${encodeURIComponent(
          base64.decode(decodeURIComponent(u.userName))
        )}&t=&p=${encodeURIComponent(
          base64.decode(decodeURIComponent(u.password))
        )}&source=EGDashboard&redirectUrl=`
      );
    });
  }

  function naviagateToPage(url: string, title: string) {
    const navigateUrl = url.replace("{{baseUrl}}", Configuration.EGBaseUrl);

    switch (title) {
      case "My Training":
        window.open(trainingUrl, "_blank");
        break;
      case "Teacher Training Report":
        history.push({
          pathname: RouteConstant.TEACHERTRAINING,
        });
        break;
      case "Campus Training Report":
        history.push({
          pathname: RouteConstant.CAMPUSTRAINING,
        });
        break;
      case "Live Training Calendar":
        window.open(navigateUrl, "_blank");
        break;
      default:
        history.push({
          pathname: RouteConstant.CONTAINER,
          search: "",
          state: { url: navigateUrl },
        });
        break;
    }
  }

  function getIcons(option: string) {
    switch (option.trim()) {
      case "My Training":
        return <CogIcon className="fill-current h-6 text-white" />;
      case "Teacher Training Report":
        return <DocumentTextIcon className="fill-current h-6 text-white" />;
      case "Campus Training Report":
        return <DocumentIcon className="fill-current h-6 text-white" />;
      case "Live Training Calendar":
        return <CalendarIcon className="fill-current h-6 text-white" />;
    }
  }

  return (
    <Fragment>
      <div className="relative text-white pt-5 px-4 pb-2 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden group">
        <dt>
          <div className={`absolute ${props.css} rounded-md p-3`}>
            {getIcons(props.title)}
          </div>
        </dt>
        <dd className="mb-10 ml-16 pb-2 flex items-baseline sm:pb- flex-col">
          <p className="text-base font-semibold text-gray-900">{props.title}</p>
          <p className="text-xs text-gray-900 ">{props.description}</p>
        </dd>
        <div
          className={`transaction duration-300 ease-in-out absolute bottom-0 inset-x-0 bg-gray-50 px-4 py-4 sm:px-6 group-hover:text-white group-hover:${props.css}`}
        >
          <div
            className="text-sm"
            onClick={() => naviagateToPage(props.url, props.title)}
          >
            <a
              href="#!"
              className="font-medium text-gray-900 group-hover:text-white"
            >
              {" "}
              Manage<span className="sr-only"></span>
            </a>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default TrainingCard;

import { useEffect, useState } from "react";
import Select from "react-select";
import { TreeSelect } from "antd";
import { IKeyValuePair, ISelectElement } from "../../../../model/interface/selectElement";
import {
  getAllDomainsBySubject,
  getAllStandardByDomains,
} from "../../../../api/teacher/assignment";
import {
  getPassageCategoriesByStandard,
} from "../../../../api/teacher/assessment";
import QuestionTypeForPassageSelect from "../shared/questionTypeForPassageSelect";
import { IAssessmentQuestion } from "../../../../model/interface/assessmentQuestion";
import { IAssessmentPassage } from "../../../../model/interface/assessmentPassage";
import TagsInput from "../shared/tagsInput";

interface PassageQuestionFiltersProps {
  subjectId: number,
  selectedQuestions: Array<IAssessmentQuestion>,
  selectedStandards: Array<ISelectElement>,
  setSelectedStandards: (selectedStandards: Array<ISelectElement>) => void,
  selectedDomains: Array<ISelectElement>,
  setSelectedDomains: (selectedDomains: Array<ISelectElement>) => void,
  searchPassages: (
    selectedStandards: Array<ISelectElement>, 
    questionCategoryIds?: Array<number>, 
    passageCategory?: any
  ) => void,
  setPassages: (passages: Array<IAssessmentPassage>) => void,
  selectedQuestionCategoryIds: Array<number>,
  setSelectedQuestionCategoryIds: (selectedQuestionCategoryIds: Array<number>) => void,
  selectedCategory: any,
  setSelectedCategory: (selectedCategory: any) => void,
  setExpandedPassagesIds: (expandedPassesgesIds: Array<number>) => void,
  updateStandardsAndQuestions: (groupedStandards: any[]) => void,
  bindFavoriteQuestions: () => void,
  setDisplayRows: (displayRows: Array<IKeyValuePair>) => void,
  keywordTags: Array<string>,
  setKeywordTags: (tags: Array<string>) => void,
  matchAnyTag: boolean,
  setMatchAnyTag: (any: boolean) => void,
  setShowLoading: (value: boolean) => void,
}

const defaultQuestionType = { label: "All Questions", value: "0" };

function PassageQuestionFilters(props: PassageQuestionFiltersProps) {
  const {
    subjectId,
    selectedQuestions,
    selectedStandards,
    setSelectedStandards,
    selectedDomains,
    setSelectedDomains,
    searchPassages,
    setPassages,
    selectedQuestionCategoryIds,
    setSelectedQuestionCategoryIds,
    selectedCategory,
    setSelectedCategory,
    setExpandedPassagesIds,
    updateStandardsAndQuestions,
    bindFavoriteQuestions,
    setDisplayRows,
    keywordTags,
    setKeywordTags,
    matchAnyTag,
    setMatchAnyTag,
    setShowLoading,
  } = props;

  const [domains, setDomains] = useState<Array<ISelectElement>>([]);
  const [categoryTypeData, setCategoryTypeData] = useState<any>([]);
  const [category, setCategory] = useState<ISelectElement[]>([]);
  const [standardsGroups, setStandardsGroups] = useState<Array<any>>([]);
  const [selectedQuestionTypes, setSelectedQuestionTypes] = useState<Array<ISelectElement>>([defaultQuestionType]);

  const agroupStandards = (domainStandards: any[]) => {
    const groupedStandards: Array<{label: string, options: Array<ISelectElement>}> = domainStandards.map((domainStandard: any) => ({
      label: domainStandard.domainName,
      options: domainStandard.standards.map((standard: any) => ({
        label: `${standard.code} ${standard.name}`,
        value: standard.id,
      })),
    }));

    return groupedStandards;
  }

  const updateSelectedStandards = (groupedStandards: any[]) => {
    const currentStandards: Array<number> = groupedStandards
      .map((standard: any) => (standard.options.map((option: any) => +option.value)))
      .flat();
    
    const filteredStandard: Array<ISelectElement> = selectedStandards
      .filter(standard => currentStandards.includes(+standard.value));
    
    setSelectedStandards(filteredStandard);
  }

  const bindStandards = (
    selectedDomains: string,
    setExistingStandards: boolean = false
  ) => {
    setShowLoading(true);
    getAllStandardByDomains(selectedDomains).then(response => {
      const domainstandards = response.data.data;

      const groupedStandards = agroupStandards(domainstandards);
      setStandardsGroups(groupedStandards);

      if (!setExistingStandards && selectedStandards.length > 0)
        updateSelectedStandards(groupedStandards);

      if (setExistingStandards && selectedQuestions?.length > 0)
        updateStandardsAndQuestions(groupedStandards);

      setExpandedPassagesIds([]);
      setDisplayRows([]);
      setShowLoading(false);
    });
  }

  const bindCategoryType = (standardIds: string) => {
    getPassageCategoriesByStandard(standardIds, subjectId).then(response => {
      if (response.data.length > 0) {
        const renameKeys = response.data.map((element, i) => ({
          key: element.name + i,
          passageCategoryId: element.passageCategoryId,
          parentId: element.parentId,
          title: `${element.name}`,
          value: element.name + i,
          refid: element.refid,
        }));

        /** Code to create nested array from single object based on parent child relationship */
        const obj = {},
          treeFormation: any = [];
        renameKeys.forEach(
          (x) => ((obj[x.passageCategoryId] = x), (x.children = []))
        );
        renameKeys.forEach((x) =>
          x.parentId ? obj[x.parentId].children.push(x) : treeFormation.push(x)
        );

        treeFormation.unshift({
          key: "0",
          value: "0",
          title: "Select",
        });

        setCategoryTypeData(renameKeys);
        setCategory(treeFormation);
      }
    });
  }

  const getDomains = () => {
    setShowLoading(true);
    getAllDomainsBySubject(subjectId).then(res => {
      setShowLoading(false);
      const domainsData: Array<ISelectElement> = res.data.data.map((value: any) => ({
        label: value.name,
        value: value.domainId
      }))
      setDomains(domainsData);

      if (selectedQuestions?.length > 0) {
        const domainIdSet: Set<number> = new Set(selectedQuestions.map(question => parseInt(question.domainId ?? "0")));
        const domainIds: Array<number> = Array.from(domainIdSet);
        const existingDomains: Array<ISelectElement> = domainsData.filter(domain => domainIds.includes(+domain.value));
        setSelectedDomains(existingDomains);
        bindStandards(domainIds.join(","), true);
      }
    });
  }

  const handleDomainChange = (selectdItems: any) => {
    setSelectedDomains(selectdItems);
    const selectedDomainsIds = selectdItems.map((item: any) => item.value);

    if (selectdItems.length === 0) {
      setSelectedStandards([]);
      setPassages([]);
    }

    bindStandards(selectedDomainsIds.join(","));
  }

  const handleStandardChange = (selectedItems: any) => {
    if (selectedItems.length === 0) {
      setSelectedStandards([]);
    }

    setSelectedStandards(selectedItems);

    searchPassages(selectedItems);
    const selectedStandardsIds = selectedItems.map(selectedItem => selectedItem.value);
    bindCategoryType(selectedStandardsIds.join(","));
  }

  const handleQuestionTypeChange = (e: any) => {
    const questionTypeIds = e.map((q: any) => q.value);
    setSelectedQuestionCategoryIds(questionTypeIds);
    setSelectedQuestionTypes(e);
    searchPassages(selectedStandards, questionTypeIds);
  }

  const handleCategoryChange = (selectedItem: any) => {
    const selectedItemData: any = categoryTypeData.find((ds: any) => ds.value === selectedItem);
    setSelectedCategory(selectedItemData);
    searchPassages(
      selectedStandards,
      selectedQuestionCategoryIds,
      selectedItemData ?? {
        title: "Select",
        key: "0",
        value: "0",
        passageCategoryId: "",
      }
    );
  };

  const handleSearch = () => {
    if (selectedStandards.length === 0 && keywordTags.length === 0) {
      setPassages([]);
      return;
    }
    searchPassages(selectedStandards);
  }

  useEffect(() => {
    if (subjectId !== 0) {
      getDomains();
    }
    bindFavoriteQuestions();
  }, [subjectId]);

  return (
    <div>
      <div className="flex flex-col sm:flex-row">
        <div className="grid grid-cols-2 sm:grid-cols-8 lg:grid-cols-8 xl:grid-cols-12 gap-6 pb-4 w-full">
          <div className="col-span-8 sm:col-span-4 xl:col-span-3 3xl:col-span-2">
            <label className="block text-sm text-gray-500">
              Select Domain(s)
            </label>
            <div className="mt-1">
              <Select
                value={selectedDomains}
                options={domains}
                isMulti={true}
                onChange={handleDomainChange}
                closeMenuOnSelect={false}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: "#008fbb",
                  },
                })}
              />
            </div>
          </div>
          <div className="col-span-8 sm:col-span-4 xl:col-span-3 3xl:col-span-2">
            <label className="block text-sm text-gray-500">
              Select Standard(s)
            </label>
            <div className="mt-1">
              <Select
                value={selectedStandards}
                options={standardsGroups}
                isMulti={true}
                onChange={handleStandardChange}
                closeMenuOnSelect={false}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: "#008fbb",
                  },
                })}
              />
            </div>
          </div>
          <div className="col-span-8 sm:col-span-4 xl:col-span-3 3xl:col-span-2">
            <label className="block text-sm text-gray-500">
              Question Type
            </label>
            <div className="mt-1">
              <QuestionTypeForPassageSelect
                onChange={handleQuestionTypeChange}
                value={selectedQuestionTypes}
                standardIds={selectedStandards
                  .map((s) => s.value)
                  .join(",")}
                includeConstructedReponseTypes={true}
              ></QuestionTypeForPassageSelect>
            </div>
          </div>
          <div className="col-span-8 sm:col-span-4 xl:col-span-3 3xl:col-span-2">
            <label className="block text-sm text-gray-500">
              Passage Category
            </label>
            <div className="mt-1">
              <TreeSelect
                className="w-full mt-1"
                showSearch
                style={{ width: "100%" }}
                dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                placeholder="Select"
                allowClear
                treeDefaultExpandAll={true}
                onChange={handleCategoryChange}
                treeData={category}
                value={selectedCategory?.value}
              />
            </div>
          </div>

          <div className="col-span-8 sm:col-span-4 xl:col-span-3 3xl:col-span-2">
            <label className="block text-sm text-gray-500">
              Keyword Search
            </label>
            <div className="mt-1">
              <TagsInput 
                tags={keywordTags}
                setTags={setKeywordTags}
                matchAny={matchAnyTag}
                setMatchAny={setMatchAnyTag}
                instructions="To search multiple, press Enter after each keyword or phrase followed by Search"
                placeholder="Enter keyword or phrase"
                tagLengthLimit={40}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-end">
        <button
          onClick={handleSearch}
          disabled={!keywordTags.length && !selectedStandards.length}
          className={"my-auto bg-secondary-teal border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white whitespace-nowrap "
            + (keywordTags.length || selectedStandards.length ? "focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 hover:bg-dark-teal hover:shadow-lg " : "")
            + (!keywordTags.length && !selectedStandards.length ? "bg-opacity-20 text-opacity-40 cursor-not-allowed" : "")}
        >
          Search
        </button>
      </div>
    </div>
  )
}

export default PassageQuestionFilters

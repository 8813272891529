import { useEffect, useState } from "react";
import { generatePath } from "react-router-dom";
import { DownArrowIcon, UpArrow } from "../../../../../assets/icons";
import { IStudent } from "../../../../../model/interface/student";
import { isPermissionExist } from "../../../../../utils/helper";
import MessagePopup from "../../../../shared/messagePopup";
import DotRank from "../../../../shared/widgets/dotRank";
import ProgressReportRemediation from "../../assignment/remediation/popup/progressReportRemediation";
import Permissions from "../../../../../utils/permissions";
import Constant from "../../../../../utils/constant/constant";
import routeConstant from "../../../../../utils/constant/routeConstant";
import UpgradeControl from "../../../../shared/customButtonControl";
import React from "react";

export default function ProgressReportByStudentByStandard(props: any) {
  const viewReportHeight = "1000px";
  const [progressReportByStudentStandard, setProgressReportByStudentStandard] =
    useState<any>();
  const [showRemediationPopup, setShowRemediationPopup] =
    useState<boolean>(false);
  const [selectedStandard, setSelectedStandard] = useState<any>();
  const [selectedStudent, setSelectedStudent] = useState<IStudent>();
  const [showMessagePopup, setShowMessagePopup] = useState<boolean>(false);
  const isUpgradeRequiredForStudent: boolean =
    (props.profile?.isUpgradeRequired ?? false) &&
    props.userContext.roleId == Constant.UserRoleId.Student;
  useEffect(() => {
    setAllStudentsSummary();
    setVisibilityForStudents();
    setVisibilityForSubjects();
    setProgressReportByStudentStandard(props.data);
  }, []);

  const setAllStudentsSummary = () => {
    if (
      props.data.students &&
      props.data.students.filter((x) => x.name === "All Students").length === 0
    ) {
      let allStudentsScore: any = {
        questionCount: 0,
        studentCount: 0,
        questionsAttempted: 0,
        questionsCorrect: 0,
        classes: [],
        pctCorrect: [],
        pointsEarned: [],
        index: 0,
        id: 0,
        name: "All Students",
      };
      props.data?.subjects?.forEach((subject) => {
        allStudentsScore.questionsAttempted = subject.questionsAttempted;
        allStudentsScore.questionsCorrect = subject.questionsCorrect;
        allStudentsScore.pointsEarned.push(subject.pointsEarned);
        allStudentsScore.pctCorrect.push(
          getPercentage(
            subject.pointsEarned,
            subject.pointsPossible,
            subject.questionsAttempted
          )
        );

        subject.domains?.forEach((domain) => {
          allStudentsScore.pointsEarned.push(domain.pointsEarned);
          allStudentsScore.pctCorrect.push(
            getPercentage(
              domain.pointsEarned,
              domain.pointsPossible,
              domain.questionsAttempted
            )
          );

          domain.standards?.forEach((standard) => {
            allStudentsScore.pointsEarned.push(standard.pointsEarned);
            allStudentsScore.pctCorrect.push(
              getPercentage(
                standard.pointsEarned,
                standard.pointsPossible,
                standard.questionsAttempted
              )
            );
          });
        });
      });
      props.data?.students?.forEach((student) => {
        allStudentsScore.questionCount += student.questionCount;
        allStudentsScore.studentCount += student.studentCount;
      });

      props.data.students = [allStudentsScore, ...props.data.students];
    }
  };

  const getPercentage = (pointsEarned, pointsPossible, questionsAttempted) => {
    if (
      pointsEarned === null ||
      pointsPossible === null ||
      questionsAttempted < 2
    )
      return null;

    return pointsPossible === 0 ? 0 : pointsEarned / pointsPossible;
  };

  const setVisibilityForStudents = () => {
    props.data?.students?.forEach((student, i) => {
      student["isCollapse"] = true;
      student.visibility = [];
      if (i !== 0) student.pctCorrect = [];
      student?.pointsEarned?.forEach((pct, j) => {
        student.visibility.push(true);
        if (i !== 0) {
          student.pctCorrect.push(
            getPercentage(
              student?.pointsEarned[j],
              student?.pointsPossible[j],
              student?.questionsAttempted[j]
            )
          );
        }
      });
    });
  };

  const setVisibilityForSubjects = () => {
    let index = 0;
    let visibility: any = [];
    let contentTypes: any = [];

    props.data?.subjects?.forEach((subject) => {
      let subjectChild = 0;
      subject["index"] = index;
      subject["isCollapse"] = false;
      visibility.push(true);
      contentTypes.push(0);
      index = index + 1;
      subject.domains?.forEach((domain) => {
        let domaintChild = 0;
        domain["index"] = index;
        domain["isCollapse"] = false;
        subjectChild = subjectChild + 1;
        visibility.push(true);
        contentTypes.push(1);
        index = index + 1;
        domain.standards?.forEach((standard) => {
          standard["displayName"] = "(" + standard.code + ") " + standard.name;
          standard["index"] = index;
          standard["isCollapse"] = false;
          subjectChild = subjectChild + 1;
          domaintChild = domaintChild + 1;
          visibility.push(true);
          contentTypes.push(2);
          index = index + 1;
        });
        domain["childCount"] = domaintChild;
      });
      subject["childCount"] = subjectChild;
    });

    if (props.data) {
      props.data.visibility = visibility;
      props.data.contentTypes = contentTypes;
    }
  };

  const handleHorizontalCollapse = (entity, value, entityType) => {
    entity.isCollapse = value;
    if (entityType === "SUBJECT") {
      entity.domains.forEach((domain) => {
        progressReportByStudentStandard.visibility[domain.index] = !value;
        domain.standards.forEach((standard) => {
          if (domain.isCollapse && !value)
            progressReportByStudentStandard.visibility[standard.index] = false;
          else
            progressReportByStudentStandard.visibility[standard.index] = !value;
        });
      });
    } else if (entityType === "DOMAIN") {
      entity.standards.forEach((standard) => {
        progressReportByStudentStandard.visibility[standard.index] = !value;
      });
    }

    progressReportByStudentStandard?.students?.forEach((student) => {
      student?.pctCorrect?.forEach((pct, index) => {
        student.visibility[index] =
          progressReportByStudentStandard.visibility[index];
      });
    });

    let _progressReportByStudentStandard = {
      ...progressReportByStudentStandard,
    };
    setProgressReportByStudentStandard(_progressReportByStudentStandard);
  };

  function isEligibleForRemediation(student: any, index, point: number) {
    if (
      props.roleId === Constant.UserRoleId.District ||
      student.id <= 0 ||
      getAttemptedQuestions(student.id, index) < 2 ||
      point >= 80
    ) {
      return false;
    }

    return true;
  }

  function getAttemptedQuestions(studentId: any, index) {
    const student = progressReportByStudentStandard.students.find(
      (r) => r.id === studentId
    );

    const attemptedQuestions: number = student.questionsAttempted[index] ?? 0;

    return attemptedQuestions;
  }

  function showRemediation(student: any, index, point: number) {
    if (
      props.roleId === Constant.UserRoleId.District ||
      !isPermissionExist(Permissions.remediations_view)
    ) {
      return;
    }

    if (
      student.id <= 0 ||
      getAttemptedQuestions(student.id, index) < 2 ||
      point >= 80
    ) {
      return;
    }

    if (props.isMultiClassSelected) {
      setShowMessagePopup(true);
      return;
    }

    const standards: Array<any> = [];
    progressReportByStudentStandard.subjects[0].domains.map((domain) => {
      domain.standards.map((standard) => {
        standards.push(standard);
      });
    });

    if (standards.findIndex((r) => r.index === index) > -1) {
      setSelectedStandard(standards.filter((r) => r.index === index)[0]);
      setSelectedStudent({ name: student.name, studentId: student.id });
      setShowRemediationPopup(true);
    }
  }

  return (
    <>
      {showMessagePopup && (
        <MessagePopup
          message={
            "Single click remediation is available for your assigned classes only and only when a single class has been selected in the filter."
          }
          togglePopup={setShowMessagePopup}
        ></MessagePopup>
      )}
      {selectedStandard && showRemediationPopup && selectedStudent && (
        <ProgressReportRemediation
          teacherId={props.userId}
          subjectId={props.subjectId}
          standardId={selectedStandard.id}
          standardName={selectedStandard.name}
          stadardCode={selectedStandard.code}
          classId={props.classId}
          togglePopup={setShowRemediationPopup}
          isClassRemediation={false}
          students={[selectedStudent]}
        />
      )}
      {progressReportByStudentStandard && (
        <div
          className="pt-60 overflow-x-auto ml-1"
          style={{
            height: viewReportHeight,
          }}
        >
          <table className="border-b border-primary-violet relative">
            <thead className={`sticky bg-white`} style={{ top: 0, zIndex: 12 }}>
              <tr className="border-b border-primary-violet">
                <th className="py-1 text-left pl-2 w-40 sticky left-0 bg-white z-[11]">
                  <span className="text-primary-violet text-base font-semibold">
                    Student
                  </span>
                  <div
                    className="absolute left-0 w-full z-[9] bg-white"
                    style={{ top: "-300px", height: "300px" }}
                  ></div>
                </th>
                <th className="py-1 text-left pl-2 w-40 sticky left-[10.1rem] bg-white z-[11]">
                  <span className="text-primary-violet text-base font-semibold">
                    Student ID
                  </span>
                  <div
                    className="absolute left-0 w-full z-[9] bg-white"
                    style={{ top: "-300px", height: "300px" }}
                  ></div>
                </th>
                {progressReportByStudentStandard.subjects.map(
                  (subject, subjectIndex) => (
                    <>
                      <th className="relative" title={subject.name}>
                        <p className="w-[271px] absolute z-[10] top-[-5.25rem] left-[-0.75rem] transform -rotate-45 whitespace-nowrap flex items-center text-lg font-bold">
                          {subject.name.length > 30
                            ? subject.name.substring(0, 30) + "..."
                            : subject.name}
                          <div
                            onClick={() =>
                              handleHorizontalCollapse(
                                subject,
                                !subject.isCollapse,
                                "SUBJECT"
                              )
                            }
                          >
                            {!subject.isCollapse && (
                              <UpArrow className="w-5 mr-2 cursor-pointer text-blue-600" />
                            )}
                            {subject.isCollapse && (
                              <DownArrowIcon className="w-5 mr-2 cursor-pointer text-blue-600" />
                            )}
                          </div>
                        </p>
                        <div
                          className="absolute left-0 w-full bg-white"
                          style={{ top: "-300px", height: "300px" }}
                        ></div>
                      </th>
                      {!subject.isCollapse &&
                        subject.domains.map((domain, domainIndex) => (
                          <>
                            <th className="relative" title={domain.name}>
                              <p className="w-[271px] absolute z-[10] top-[-5.25rem] left-[-0.75rem] transform -rotate-45 whitespace-nowrap flex items-center text-base font-medium">
                                {domain.name.length > 40
                                  ? domain.name.substring(0, 40) + "..."
                                  : domain.name}
                                <div
                                  onClick={() =>
                                    handleHorizontalCollapse(
                                      domain,
                                      !domain.isCollapse,
                                      "DOMAIN"
                                    )
                                  }
                                >
                                  {!domain.isCollapse && (
                                    <UpArrow className="w-5 mr-2 cursor-pointer text-red-600" />
                                  )}
                                  {domain.isCollapse && (
                                    <DownArrowIcon className="w-5 mr-2 cursor-pointer text-red-600" />
                                  )}
                                </div>
                              </p>
                              <div
                                className="absolute left-0 w-full bg-white"
                                style={{ top: "-300px", height: "300px" }}
                              ></div>
                            </th>

                            {!subject.isCollapse &&
                              !domain.isCollapse &&
                              domain.standards.map(
                                (standard, standardIndex) => (
                                  <th
                                    className="relative"
                                    title={standard.displayName}
                                  >
                                    <p className="w-[271px] absolute z-[10] top-[-5.25rem] left-[-0.75rem] transform -rotate-45 whitespace-nowrap flex items-center text-sm font-normal">
                                      {standard.displayName.length > 40
                                        ? standard.displayName.substring(
                                            0,
                                            40
                                          ) + "..."
                                        : standard.displayName}
                                    </p>
                                    <div
                                      className="absolute left-0 w-full bg-white"
                                      style={{ top: "-300px", height: "300px" }}
                                    ></div>
                                  </th>
                                )
                              )}
                          </>
                        ))}
                    </>
                  )
                )}
              </tr>
            </thead>
            <tbody>
              {progressReportByStudentStandard.students.map(
                (student, studentIndex) => (
                  <>
                    <tr
                      className={
                        "border-r border-primary-violet " +
                        (studentIndex % 2 !== 0 ? "bg-[#e9f3f8]" : "bg-white")
                      }
                    >
                      <td className="sticky left-0 relative bg-inherit">
                        <div
                          className={
                            (studentIndex === 0 ? "font-semibold " : "") +
                            "flex items-center justify-start w-40 break-all  px-2"
                          }
                        >
                          {studentIndex > 0 && (
                            <a
                              rel="noreferrer"
                              className={
                                "underline cursor-pointer text-blue-600 hover:underline"
                              }
                              href={generatePath(
                                routeConstant.TeacherRoutes
                                  .ProgressReportStudent,
                                {
                                  studentUserId: student.id,
                                  subjectId: props.subjectId,
                                }
                              )}
                              target="_blank"
                            >
                              {student.name}
                            </a>
                          )}

                          {studentIndex === 0 && <span>{student.name}</span>}
                        </div>
                        <div className="absolute top-0 left-0 z-[11] h-[100%] w-1 border-l border-primary-violet"></div>
                      </td>
                      <td className="sticky left-[10.1rem] relative bg-inherit">
                        <div
                          className={
                            (studentIndex === 0 ? "font-semibold " : "") +
                            "flex items-center justify-start w-40 break-all  px-2"
                          }
                        >
                          {studentIndex > 0 && (
                            <span className="text-center">
                              {student.studentId}
                            </span>
                          )}

                          {studentIndex === 0 && (
                            <span>{student.studentId}</span>
                          )}
                        </div>
                      </td>
                      {student.pctCorrect.map(
                        (pct, index) =>
                          student.visibility[index] && (
                            <UpgradeControl
                              controlType={"td"}
                              userContext={props.userContext}
                              permissionName={
                                isUpgradeRequiredForStudent
                                  ? null
                                  : Permissions.remediations_upgrade
                              }
                              onClick={() =>
                                showRemediation(
                                  student,
                                  index,
                                  Math.round(pct * 100)
                                )
                              }
                              className={
                                "cursor-pointer text-center p-3 text-sm font-normal " +
                                (props.data.contentTypes[index] === 1
                                  ? "border-l border-gray-500"
                                  : "")
                              }
                              isRoleCheck={isUpgradeRequiredForStudent}
                              isUpgradeRequired={isUpgradeRequiredForStudent}
                            >
                              <DotRank
                                shape="circle"
                                displayScore={true}
                                value={
                                  pct
                                    ? Math.round(pct * 100)
                                    : getAttemptedQuestions(
                                        student.id,
                                        index
                                      ) >= 2
                                    ? 0
                                    : undefined
                                }
                                tooltip={
                                  isEligibleForRemediation(
                                    student,
                                    index,
                                    Math.round(pct * 100)
                                  )
                                    ? "Click to assign remediation"
                                    : ""
                                }
                              ></DotRank>
                            </UpgradeControl>
                          )
                      )}
                    </tr>
                  </>
                )
              )}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
}

import { useState } from 'react'
import { readTextNote, speak, hold } from './textToSpeech'
import constant from "../../../../../utils/constant/constant";


const useTextToSpeech = () => {
    const [isSpeaking, setIsSpeaking] = useState(false);
  
    const readElementText = (text: string, language: number) => {
      let speakerMode = constant.SpeakerMode.Hold;
      if (localStorage.getItem(constant.TTS.SPEAKER_MODE_KEY)) {
        speakerMode = Number(localStorage.getItem(constant.TTS.SPEAKER_MODE_KEY));
      }
      if (speakerMode !== constant.SpeakerMode.Speak || isSpeaking) return
      setIsSpeaking(true);
      readTextNote(text, language);
    }

    const startSpeaking = (text: string, language: number) => {
      if(!isSpeaking) {
        setIsSpeaking(true);
        speak(text, language);
      }
    }

    const stopSpeaking = () => {
      hold();
      setIsSpeaking(false);
    }
  
    return {
      readElementText,
      startSpeaking,
      stopSpeaking
    }
  }
  
  export default useTextToSpeech;
import { PlayIcon } from "@heroicons/react/solid";
import ReactDOMServer from "react-dom/server";
import classNames from "classnames";
import { useCallback, useEffect, useRef, useState } from "react";
import { getQuestionExplanation } from "../../../api/student/question/explanation";
import { renderClassificationDetailsStudentResult } from "../../../utils/learnosityHelper";
import QuestionExplanationPopup from "../student/shared/popups/questionExplanationPopup";
import { TextIcon } from "../../../assets/icons";
import { getRequest } from "../../../api/userActivity";
import { getTeacherFeedbacksBySessionId } from  "../../../api/student/studentActivityApi";
import Modal from "../../shared/modal/modal";
import ModalBody from "../../shared/modal/modalBody";

interface LearnosityReportProps {
  sessionId: string;
  user_id: string;
  show_answers: boolean;
  wrapperClass: string;
  standardIds?: string;
  subjectId?: number;
  hideExplanation?: boolean;
  showIndividualQuestionFeedback?: boolean;
}
export default function LearnosityReport(props: LearnosityReportProps) {
  const [showPopup, setShowPopup] = useState(false);
  const [textExplanation, setTextExplanation] = useState<string>("");
  const [videoUrl, setVideoUrl] = useState<string>("");
  const [itemReference, setItemReference] = useState<string>("");
  const [studentType, setStudentType] = useState<string>("K1");
  const [unattemptedQuestions, setUnattemptedQuestions] =
    useState<Array<any>>();
  const [itemsApiReady, setItemApiReady] = useState<boolean>(false);
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const isInitialized = useRef(false);

  var reportsApp;

  function ShowExplanation(itemReference, language, videoUrl, textExplanation) {
    if (videoUrl) {
      setVideoUrl(videoUrl);
      setTextExplanation("");
    } else if (textExplanation) {
      setVideoUrl("");
      setTextExplanation(textExplanation);
    } else {
      setVideoUrl("");
      setTextExplanation("No Explanation available");
    }
    setItemReference(itemReference);
    setShowPopup(true);
  }

  function CreateExplanationLinks(itemsApp: any) {
    var items = itemsApp.getItems();
    Object.values(items).forEach(async function (item: any) {
      let divEl = document.createElement("div");
      var element = document.querySelector(
        "div[data-reference='" + item.reference + "']"
      ) as HTMLElement;
      var language = item.reference.includes("teacher")
        ? "en"
        : item.reference.split("-")[1];
      await getQuestionExplanation(item.reference, language, true).then(
        (res: any) => {
          if (res.data) {
            if (res.data.videoUrl) {
              let btnEl = document.createElement("button");
              btnEl.id = "explanation_" + item.reference;
              btnEl.innerHTML =
                language === "es"
                  ? ReactDOMServer.renderToString(
                      <PlayIcon className="w-5 h-5 text-gray-400" />
                    ) + "Vídeo Explicación"
                  : ReactDOMServer.renderToString(
                      <PlayIcon className="w-5 h-5 text-gray-400" />
                    ) + " Video Explanation";
              btnEl.className = "hover:underline pt-4 pb-4";
              btnEl.style.cssText = "font-weight:500; color:#3b82f6; ";
              btnEl.onclick = () =>
                ShowExplanation(
                  item.reference,
                  language,
                  res.data.videoUrl,
                  null
                );
              divEl.prepend(btnEl);
            }
            if (res.data.textExplanation) {
              let btnEl = document.createElement("button");
              btnEl.id = "explanation_" + item.reference;
              btnEl.innerHTML =
                language === "es"
                  ? ReactDOMServer.renderToString(
                      <TextIcon className="w-5 h-5 text-gray-500 mr-1" />
                    ) + "Texto Explicación"
                  : ReactDOMServer.renderToString(
                      <TextIcon className="w-5 h-5 text-gray-500 mr-1" />
                    ) + "Text Explanation";
              btnEl.className = "pl-2 hover:underline pt-4 pr-4 pb-4";
              btnEl.style.cssText = "font-weight:500; color:#3b82f6; ";
              btnEl.onclick = () =>
                ShowExplanation(
                  item.reference,
                  language,
                  null,
                  res.data.textExplanation
                );
              divEl.prepend(btnEl);
            }
            divEl.className = "xl:absolute xl:right-0 xl:top-0 mr-4";
            element?.prepend(divEl);
            element?.classList?.add("xl:relative");
          }
        }
      );
    });
  }

  function AddClassificationDetails(itemsApp: any) {
    var items = itemsApp.getItems();
    Object.values(items).forEach(async function (item: any) {
      var element = document.querySelector(
        "div[data-reference='" + item.reference + "']"
      ) as HTMLElement;
      var language = item.reference.includes("teacher")
        ? "en"
        : item.reference.split("-")[1];
      const questionId = parseInt(item.reference?.split("-")[0] ?? "0");
      renderClassificationDetailsStudentResult(
        element,
        questionId,
        language === "es" ? true : false
      );
    });
  }
  function CreateUnattemptedScoreCanvas() {
    unattemptedQuestions?.forEach(async function (element: any) {
      var el = document.querySelector(
        "div[data-reference='" + element?.data_reference + "']"
      ) as HTMLElement;
      var canvasdiv = document.createElement("div");
      canvasdiv.innerHTML =
        '<div style="display: flex;" class="flex justify-end mb-2"><div class="flex justify-end" style="width: 40px; height: 40px;border-radius: 41px;display: flex;background-color: #DD002F;"><div style="font-size: 100%;font-family: sans-serif;color: white;margin: auto auto;font-weight: bold;">0/' +
        element?.max_score +
        "</div></div></div>";
      el.insertAdjacentElement("beforebegin", canvasdiv);
    });
  }

  function CreateQuestionsFeedbackLinks(itemsApp: any) {
    // First, get the question feedbacks for the current session
    getTeacherFeedbacksBySessionId(props.sessionId).then((response) => {
      if (response.data) {

        let feedbacksData = {};
        response.data.forEach(item => {
          feedbacksData[item.questionId] = item.teacherFeedback;
        });
        
        var items = itemsApp.getItems();
        Object.values(items).forEach(function (item: any) {          
          var questionId = Number(item.reference.split('-')[0]);
          var feedback = response.data.find(x => x.questionId === questionId);
          if(feedback) {
            
            var newDiv = document.createElement('div');
            newDiv.className = 'pull-right';
            newDiv.setAttribute('data-placement', 'right');
            newDiv.setAttribute('tabindex', '1');
            newDiv.setAttribute('aria-hidden', 'true');
            newDiv.style.color = 'rgb(54, 163, 235)';
            newDiv.style.fontSize = 'larger';
            newDiv.style.fontWeight = 'bold';          
            newDiv.style.paddingRight = '60px'; 
            newDiv.style.paddingTop = '1px'; 
            newDiv.style.marginRight = '60px';
            newDiv.style.maxWidth = '80%'; // Set a max width to ensure it doesn't stretch too far
            
            newDiv.onclick = function() {
                setModalContent(feedback?.teacherFeedback ?? "");
                setShowModal(true); // Open the modal
            };

            var feedbackText = feedback.teacherFeedback;            
            // Check if feedback length is greater than 40 characters
            if (feedbackText.length > 40) {
                feedbackText = feedbackText.substring(0, 40) + '...';
            }
            newDiv.innerHTML = `<span>Teacher Feedback - ${feedbackText}</span>`;
                  
            // Find the h3 element for the item
            var itemDiv = document.querySelector(`div[data-reference="${item.reference}"]`);
            
            if (itemDiv) {
                // Traverse backwards to find the h3 element
                var h3Element = itemDiv.previousElementSibling;
                while (h3Element && h3Element.tagName !== 'H3') {
                    h3Element = h3Element.previousElementSibling;
                }

                if (h3Element) {
                    // Insert the new div above the h3 element
                    h3Element.parentNode?.insertBefore(newDiv, h3Element);
                }
            }
          }
        });
      }
    });
  }

  
  const initialization = useCallback(() => {
    if (isInitialized.current) return;
      
    isInitialized.current = true; 

    var callbacks = {
      readyListener: function () {
        var report = reportsApp.getReport(props.sessionId);
        report.on("load:data", function (data) {
          var unattempted = data.items.filter((x) => x.attempted === false);
          setUnattemptedQuestions(unattempted);
        });
        report.on("ready:itemsApi", function (itemsApp) {
          setItemApiReady(true);
          if (!props.hideExplanation) CreateExplanationLinks(itemsApp);
          AddClassificationDetails(itemsApp);

          if (props.showIndividualQuestionFeedback) {
            CreateQuestionsFeedbackLinks(itemsApp);
          }
        });

        report.on("ready:questionsApi", function () {});
      },
      errorListener: function (e) {
        // Adds a listener to all error codes.
        console.log("Error Code ", e.code);
        console.log("Error Message ", e.msg);
        console.log("Error Detail ", e.detail);
      },
    };

    const request = {
      reports: [
        {
          id: props.sessionId,
          type: "session-detail-by-item",
          session_id: props.sessionId,
          user_id: props.user_id,
          questions_api_init_options: {
            showCorrectAnswers: props.show_answers,
          },
        },
      ],
    };
    getRequest("reports", JSON.stringify(request)).then((response) => {
      if (response.data) {
        reportsApp = window["LearnosityReports"]?.init(
          response.data,
          callbacks
        );
      }
    });
  }, []);

  useEffect(() => {
    initialization();
    var studentType = props.wrapperClass.includes("K1")
      ? "K1"
      : props.wrapperClass.includes("EL")
      ? "EL"
      : "MS";
    setStudentType(studentType);
  }, [initialization]);
  useEffect(() => {
    if (
      unattemptedQuestions &&
      unattemptedQuestions?.length > 0 &&
      itemsApiReady
    ) {
      CreateUnattemptedScoreCanvas();
    }
  }, [unattemptedQuestions, itemsApiReady]);

  return (
    <div>
      <div className={classNames("main-content", props.wrapperClass)}>
        <div id={props.sessionId}></div>
      </div>
      {showPopup && (
        <QuestionExplanationPopup
          showPopUp={(show) => {
            setShowPopup(show);
          }}
          textExplanation={textExplanation}
          videoUrl={videoUrl}
          studentType={studentType}
          itemReference={itemReference}
        />
      )}

      <Modal 
        isOpen={showModal} 
        onClose={() => setShowModal(false)}
        title="Teacher Feedback">
        <ModalBody>
            <div>{modalContent}</div>
        </ModalBody>
      </Modal>
    </div>
  );
}

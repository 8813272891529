import axios, { AxiosInstance } from "axios"
import auth from "../utils/auth"
import { Configuration } from "../environment/setup"

export class BaseApi {
    public api: AxiosInstance;

    constructor() {
        this.api = axios.create({
            baseURL: Configuration.ApiBaseUrl,
            headers: {
                Authorization: "Bearer " + auth.getAuthToken(),
                "Content-Type": "application/json",
            }
        });
    }
}

export const axiosWithToken = new BaseApi().api;

export default BaseApi;

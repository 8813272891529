import { Fragment, useEffect, useState } from "react";
import "../../../../../../css/style.css";
import { getStudentAssignmentResult } from "../../../../../../api/student/studentAssignmentApi";
import { connect } from "react-redux";
import { UserContextState } from "../../../../../../redux/actions/userContextAction";
import Profile from "../../../../../../model/users/profile";
import OverviewResult from "./overviewResult";
import LearnosityReport from "../../../../results/learnosityReport";
import { useTranslation } from "react-i18next";
import ProgressResult from "./progressResult";
import TotalQuestionsByDomains from "./totalQuestionsByDomains";
import TotalQuestionsByStandards from "./totalQuestionsByStandards";
import Breadcrumb from "../../../../teacher/shared/breadcrumb";
import { IBreadcrumb } from "../../../../../../model/common/breadcrumb";
import RouteConstant from "../../../../../../utils/constant/routeConstant";

interface IAssignmentResult {
  userContext: UserContextState;
  profile: Profile;
  match: any;
}
const AssignmentResultEL = (props: IAssignmentResult) => {
  const { t } = useTranslation();
  const [assignmentResult, setAssignmentResult] = useState<any>(null);
  const assignmentId = parseInt(props.match.params.assignmentId);
  const assignmentActivityId = parseInt(
    props.match.params.assignmentActivityId
  );
  const studentId = parseInt(props.match.params.studentId);
  const [learnosityActivityUserId, setLearnosityActivityUserId] =
    useState<number>(studentId);
  useEffect(() => {
    getResult(studentId, assignmentId, assignmentActivityId);
  }, []);

  function getResult(
    studentId: number,
    assignmentId: number,
    assignmentActivityId: number
  ) {
    getStudentAssignmentResult(
      props.profile.currentSchoolYear.schoolYearId,
      studentId,
      assignmentId,
      assignmentActivityId
    ).then((res) => {
      setLearnosityActivityUserId(
        res.data.learnosityActivityUserId ?? studentId
      );
      setAssignmentResult(
        res.data && res.data.activities.length > 0 ? res.data : null
      );
    });
  }
  const breadcrumbItems = () => {
    const items: Array<IBreadcrumb> = [
      { name: t("Breadcrumb.Home"), url: RouteConstant.ROUTE_DASHBOARD },
      { name: t("Breadcrumb.Reports"), url: RouteConstant.REPORTS },
      { name: t("Breadcrumb.SessionActivityReport"), url: "" },
    ];

    return items;
  };
  return (
    <Fragment>
      <Breadcrumb items={breadcrumbItems()} />
      {assignmentResult && (
        <div className="relative bg-primary-violet w-full">
          <div className="p-5 flex flex-col md:flex-row">
            <OverviewResult data={assignmentResult} />
            <div className="flex-grow h-auto md:w-1/2">
              {/* flex-grow h-auto md:w-1/2 */}
              <div className="flex w-full mt-6 md:mt-0 pb-6">
                <div>
                  <h1 className="text-2xl font-bold text-white capitalize">
                    {"Student Assignment Result"}
                  </h1>
                  <h2 className="text-base font-medium text-white capitalize">
                    {assignmentResult.assignmentName}
                  </h2>
                </div>
              </div>
              <div className="flex-none xxl:flex">
                <div className="flex-none w-full xl:w-4/5 xxl:w-2/5 bg-gray-50 rounded-md xl:mr-12 mb-12 xxl:mb-0 overflow-hidden">
                  <ProgressResult data={assignmentResult} />
                </div>
                <div className="flex-grow w-full xl:w-4/5 xxl:w-3/5">
                  <div className="hidden mb-8">
                    <h2 className="text-md font-semibold text-gray-800 mb-2">
                      Teacher Feedback
                    </h2>
                    <div className="rounded-md bg-blue-100 p-4">
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <svg
                            className="h-7 w-7 text-blue-400"
                            x-description="Heroicon name: solid/information-circle"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              fillRule="evenodd"
                              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                              clip-rule="evenodd"
                            ></path>
                          </svg>
                        </div>
                        <div className="ml-3 flex-1 md:flex md:justify-between">
                          <p className="text-lg text-blue-700">
                            You did a great job!
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <TotalQuestionsByDomains data={assignmentResult} />
                  <TotalQuestionsByStandards data={assignmentResult} />
                </div>
              </div>
            </div>
          </div>
          <div className="p-5">
            <div className="p-5 bg-gray-50 border rounded-md text-sm font-semibold">
              <h2> {t("AssignmentResult.AnswerStatusByQuestionNo")}</h2>

              <ul className="flex flex-wrap gap-2 w-full xl:w-1/2 mt-3">
                {assignmentResult.questions.map((question, index) => {
                  return (
                    <>
                      {question.order > 0 && question.score === question.pointsPossible && (
                        <li className="w-6 h-6 bg-[#B2D136] border border-[#B2D136] text-white text-xs font-semibold flex items-center rounded-sm shadow-sm justify-center hover:bg-transparent hover:text-[#B2D136]">
                          {index + 1}
                        </li>
                      )}
                      {question.order > 0 && question.score < question.pointsPossible && (
                        <li className="w-6 h-6 bg-[#e53f71] border border-[#e53f71] text-white text-xs font-semibold flex items-center rounded-sm shadow-sm justify-center hover:bg-transparent hover:text-[#e53f71]">
                          {index + 1}
                        </li>
                      )}
                    </>
                  );
                })}
              </ul>
            </div>
          </div>
          <div className="p-5 bg-white">
            {assignmentResult && assignmentResult.activities?.length > 0 && (
              <LearnosityReport
                sessionId={assignmentResult.activities[0].sessionId}
                user_id={learnosityActivityUserId.toString()}
                show_answers={true}
                wrapperClass=""
              ></LearnosityReport>
            )}
          </div>
        </div>
      )}
    </Fragment>
  );
};

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
};
export default connect<{}, {}, {}>(mapStateToProps, {})(AssignmentResultEL);

import { useEffect, useState } from "react";
import { getProgressReportDataByStudent } from "../../../../../api/report/progressReport";
import { DownArrowIcon, UpArrow } from "../../../../../assets/icons";
import { IStudent } from "../../../../../model/interface/student";
import { isPermissionExist } from "../../../../../utils/helper";
import MessagePopup from "../../../../shared/messagePopup";
import DotRank from "../../../../shared/widgets/dotRank";
import ProgressReportRemediation from "../../assignment/remediation/popup/progressReportRemediation";
import Permissions from "../../../../../utils/permissions";
import Constant from "../../../../../utils/constant/constant";
import UpgradeControl from "../../../../shared/customButtonControl";
import { getScoreLabel } from "../../../../../utils/scoreHelper";
import Loader from "../../../../shared/loader";

export default function ProgressReportByDistrict(props: any) {
  const [loader, setLoader] = useState<boolean>(false);
  const [progressReportByStandard, setProgressReportByStandard] =
    useState<any>();
  const [showRemediationPopup, setShowRemediationPopup] =
    useState<boolean>(false);
  const [selectedStandard, setSelectedStandard] = useState<any>();
  const [selectedStudents, setSelectedStudents] = useState<Array<IStudent>>([]);
  const [showMessagePopup, setShowMessagePopup] = useState<boolean>(false);
  const [messagePopupText, setMessagePopupText] = useState<string>("");
  const isUpgradeRequiredForStudent: boolean =
    (props.profile?.isUpgradeRequired ?? false) &&
    props.userContext.roleId == Constant.UserRoleId.Student;

  useEffect(() => {
    let domainVisibility: any = [];
    props.data?.subjects?.map((subject, subjectIndex) => {
      subject?.domains?.map((domain, domainIndex) => {
        domain?.standards?.map((standard, standardIndex) => {
          standard["overallScore"] = getOverallScore(
            standard.pointsEarned,
            standard.pointsPossible
          );
          return null;
        });
        domain["overallScore"] = getOverallScore(
          domain.pointsEarned,
          domain.pointsPossible
        );
        domainVisibility.push({
          subjectId: subject.id,
          domainId: domain.id,
          isCollapse: false,
        });
        return null;
      });
      subject["overallScore"] = getOverallScore(
        subject.pointsEarned,
        subject.pointsPossible
      );
      return null;
    });

    //created custom properties
    domainVisibility.forEach((domain) => {
      props.data[domain.subjectId + "_" + domain.domainId] = false;
    });
    setProgressReportByStandard(props.data);
  }, []);

  const getOverallScore = (pointsEarned, pointsPossible) => {
    if (pointsEarned === 0 || pointsPossible === 0) {
      return 0;
    }
    return Math.round((Number(pointsEarned) / Number(pointsPossible)) * 100);
  };

  const setDomainVisibility = (subjectId, domainId, value) => {
    let _progressReport = { ...progressReportByStandard };
    _progressReport[subjectId + "_" + domainId] = value;
    setProgressReportByStandard(_progressReport);
  };

  function getStudentsForRemediation(result: any, standard: any) {
    const standards: Array<any> = [];
    result.subjects[0].domains.map((domain) => {
      domain.standards.map((standard) => {
        standards.push(standard);
      });
    });

    const index: number = standards.find((r) => r.id === standard.id).index;
    let students: Array<IStudent> = [];
    result.students.map((student) => {
      const pctCorrect = student.pctCorrect[index];
      const attemptedQuestions = student.questionsAttempted[index];
      const percentage = Math.round(pctCorrect * 100);
      if (
        attemptedQuestions >= 2 &&
        (pctCorrect === 0 ||
          (pctCorrect &&
            Math.round(percentage) > 0 &&
            Math.round(percentage) < 80))
      ) {
        students.push({ studentId: student.id, name: student.name });
      }
    });

    setSelectedStudents(students);
  }

  function isRemediationEligible() {
    let remediationEligible: boolean = true;
    if (
      props.roleId === Constant.UserRoleId.District ||
      props.roleId === Constant.UserRoleId.Student
    ) {
      remediationEligible = false;
    } else if (!isPermissionExist(Permissions.remediations_view)) {
      remediationEligible = false;
    }

    return remediationEligible;
  }

  function showRemediation(standard: any, overallScore: number) {
    if (!isRemediationEligible()) {
      return;
    }

    if (overallScore === 0) {
      return;
    }

    if (props.isMultiClassSelected) {
      setMessagePopupText("Single click remediation is available for your assigned classes only and only when a single class has been selected in the filter.");
      setShowMessagePopup(true);
      return;
    }

    if (standard.isHiddenFromStudentPractice) {
      setMessagePopupText("Content is not available for remediation.");
      setShowMessagePopup(true);
      return;
    }

    setLoader(true);
    
    getProgressReportDataByStudent(
      props.data?.subjects[0].id,
      props.startDate,
      props.endDate,
      props.selectedSchool,
      props.selectedClass,
      0,
      props.includeAssignments,
      props.includeStudyPlan,
      props.schoolYearId
    ).then((response) => {
      const result = response.data.data;
      getStudentsForRemediation(result, standard);
      setSelectedStandard(standard);
      setShowRemediationPopup(true);
      setLoader(false);
    });
  }

  return (
    <>
      {loader && <Loader />}
      {selectedStandard && showRemediationPopup && (
        <ProgressReportRemediation
          teacherId={props.userId}
          subjectId={props.subjectId}
          standardId={selectedStandard.id}
          standardName={selectedStandard.name}
          stadardCode={selectedStandard.code}
          classId={props.classId}
          togglePopup={setShowRemediationPopup}
          isClassRemediation={true}
          students={selectedStudents}
        />
      )}
      {showMessagePopup && (
        <MessagePopup
          message={messagePopupText}
          togglePopup={setShowMessagePopup}
        ></MessagePopup>
      )}
      {progressReportByStandard && (
        <div className="overflow-x-auto xl:overflow-x-visible mt-5">
          {progressReportByStandard?.subjects?.map((subject, subjectIndex) => (
            <table className="table w-full " key={subjectIndex}>
              <tr className="bg-light-violet/10  text-gray-700 py-3 px-3">
                <th
                  scope="col"
                  className="text-left  p-3 text-sm font-normal w-1/3 "
                >
                  {subject.name}
                </th>

                <th
                  scope="col"
                  className="text-left  p-3 text-sm font-normal pl-10"
                >
                  <div className="flex flex-col text-center">
                    <span>Questions Attempted</span>
                    <span className="font-semibold text-gray-500">
                      {subject.questionsAttempted}
                    </span>
                  </div>
                </th>

                <th scope="col" className="text-left p-3 text-sm font-normal">
                  <div className="flex flex-col text-center">
                    <span>Total Points Earned</span>
                    <span className="font-semibold text-gray-500">
                      {getScoreLabel(subject.pointsEarned)}
                    </span>
                  </div>
                </th>

                <th scope="col" className="text-left p-3 text-sm font-normal">
                  <div className="flex flex-col text-center">
                    <span>Total Points Possible</span>
                    <span className="font-semibold text-gray-500">
                      {getScoreLabel(subject.pointsPossible)}
                    </span>
                  </div>
                </th>

                <th scope="col" className="text-left  p-3 text-sm font-normal">
                  <div className="flex flex-col text-center">
                    <span>Overall Score</span>
                    <span className="font-semibold text-gray-500">
                      {subject.overallScore}%
                    </span>
                  </div>
                </th>

                <th
                  scope="col"
                  className="text-left  p-3 text-sm font-normal w-56"
                >
                  <div className="flex flex-col text-center items-center">
                    <span>Dot Rank</span>
                    <DotRank
                      value={subject.overallScore}
                      attempted={subject.questionsAttempted}
                    ></DotRank>
                  </div>
                </th>
              </tr>

              <tbody>
                {subject.domains?.map((domain, domainIndex) => (
                  <tr className="w-full" key={domainIndex}>
                    <td colSpan={6}>
                      <div
                        className="flex py-5 px-2 items-center cursor-pointer border-b"
                        onClick={() =>
                          setDomainVisibility(
                            subject.id,
                            domain.id,
                            !progressReportByStandard[
                              subject.id + "_" + domain.id
                            ]
                          )
                        }
                      >
                        {progressReportByStandard[
                          subject.id + "_" + domain.id
                        ] && (
                          <DownArrowIcon className="w-4 h-4 stroke-current text-gray-500 hover:text-gray-700" />
                        )}
                        {!progressReportByStandard[
                          subject.id + "_" + domain.id
                        ] && (
                          <UpArrow className="w-4 h-4 stroke-current text-gray-500 hover:text-gray-700" />
                        )}
                        <span className="ml-2 text-primary-violet text-base font-semibold">
                          {domain.name}
                        </span>
                      </div>

                      {!progressReportByStandard[
                        subject.id + "_" + domain.id
                      ] && (
                        <table className="table w-full ">
                          <thead className="mb-5">
                            <tr className="bg-light-violet  text-white py-3 px-3">
                              <th
                                scope="col"
                                className="text-left  p-3 text-sm font-normal w-10"
                              >
                                #
                              </th>
                              <th
                                scope="col"
                                className="text-left  p-3 text-sm font-normal w-1/3"
                              >
                                Name
                              </th>

                              <th
                                scope="col"
                                className="text-left  p-3 text-sm font-normal"
                              >
                                <div className="flex flex-col text-center">
                                  <span>Questions Attempted</span>
                                </div>
                              </th>

                              <th
                                scope="col"
                                className="text-left  p-3 text-sm font-normal"
                              >
                                <div className="flex flex-col text-center">
                                  <span>Total Points Earned</span>
                                </div>
                              </th>

                              <th
                                scope="col"
                                className="text-left p-3 text-sm font-normal"
                              >
                                <div className="flex flex-col text-center">
                                  <span>Total Points Possible</span>
                                </div>
                              </th>

                              <th
                                scope="col"
                                className="text-left  p-3 text-sm font-normal"
                              >
                                <div className="flex flex-col text-center">
                                  <span>Overall Score</span>
                                </div>
                              </th>

                              <th
                                scope="col"
                                className="text-left  p-3 text-sm font-normal w-56"
                              >
                                <div className="flex flex-col text-center">
                                  <span>Dot Rank</span>
                                  {isRemediationEligible() && (
                                    <span>(Click Standard to Assign)</span>
                                  )}
                                </div>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {domain?.standards?.map(
                              (standard, standardIndex) => (
                                <tr
                                  key={standardIndex}
                                  className={`${
                                    standardIndex % 2 !== 0
                                      ? "bg-light-violet/10"
                                      : "bg-white"
                                  }`}
                                >
                                  <td className="p-3 text-sm text-gray-500 w-10">
                                    {standardIndex + 1}
                                  </td>
                                  <td className="p-3 text-sm text-gray-500 w-1/3 text-left">
                                    {standard.code} {standard.name}
                                  </td>
                                  <td className="p-3 text-sm text-gray-500 text-center">
                                    {standard.questionsAttempted}
                                  </td>
                                  <td className="p-3 text-sm text-gray-500 text-center">
                                    {getScoreLabel(standard.pointsEarned)}
                                  </td>
                                  <td className="p-3 text-sm text-gray-500 text-center">
                                    {getScoreLabel(standard.pointsPossible)}
                                  </td>
                                  <td className="p-3 text-sm text-gray-500 text-center">
                                    {standard.overallScore}%
                                  </td>
                                  <UpgradeControl
                                    controlType={"td"}
                                    userContext={props.userContext}
                                    permissionName={
                                      isUpgradeRequiredForStudent
                                        ? null
                                        : Permissions.remediations_upgrade
                                    }
                                    onClick={() =>
                                      showRemediation(
                                        standard,
                                        standard.overallScore
                                      )
                                    }
                                    className="cursor-pointer p-3 text-sm text-gray-500 text-center flex justify-center items-center"
                                    isRoleCheck={isUpgradeRequiredForStudent}
                                    isUpgradeRequired={
                                      isUpgradeRequiredForStudent
                                    }
                                  >
                                    <DotRank
                                      value={standard.overallScore}
                                      attempted={standard.questionsAttempted}
                                      tooltip={
                                        isRemediationEligible() &&
                                        standard.overallScore > 0
                                          ? "Click to assign remediation"
                                          : ""
                                      }
                                    ></DotRank>
                                  </UpgradeControl>
                                </tr>
                              )
                            )}

                            <tr className="bg-white border-t-2 border-b-2 border-gray-300">
                              <td className="p-3 text-sm text-gray-500 w-10"></td>
                              <td className="p-3 text-sm text-gray-500 w-1/3 text-left font-semibold">
                                Domain Totals
                              </td>
                              <td className="p-3 text-sm text-gray-500 text-center font-semibold">
                                {domain.questionsAttempted}
                              </td>
                              <td className="p-3 text-sm text-gray-500 text-center font-semibold">
                                {getScoreLabel(domain.pointsEarned)}
                              </td>
                              <td className="p-3 text-sm text-gray-500 text-center font-semibold">
                                {getScoreLabel(domain.pointsPossible)}
                              </td>
                              <td className="p-3 text-sm text-gray-500 text-center font-semibold">
                                {domain.overallScore} %
                              </td>
                              <td className="p-3 text-sm text-gray-500 text-center flex justify-center items-center">
                                <DotRank
                                  value={domain.overallScore}
                                  attempted={domain.questionsAttempted}
                                ></DotRank>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ))}
        </div>
      )}
    </>
  );
}

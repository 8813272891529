import { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import Constant from "../../../../../utils/constant/constant";
import {
  completeCannedTest,
  getCannedTestLastSession,
  getCannedTestTotalQuestionCount,
  initializeCannedTest,
  //updatCannedTest,
} from "../../../../../api/student/cannedTestApi";
import { IInitalizeStudyPlanResponse } from "../../../../../model/student/studyplan/initializeStudyPlanRespons";
import QuestionAnswer from "../../shared/questionAnswer";
import BeginActivity from "../../shared/beginActivity";
import RouteConstant from "../../../../../utils/constant/routeConstant";
import Breadcrumb from "../../middleSchool/breadcrumb";
import { IBreadcrumb } from "../../../../../model/common/breadcrumb";
import { useTranslation } from "react-i18next";
import {
  getStudentClasses,
  getStudentTeacher,
} from "../../../../../api/student/studentApi";
import { useHistory } from "react-router-dom";
import { getCalculatorBySubjectId } from "../../../../../api/student/studentSubjectApi";
import {
  GetShowExplanation,
  GetIsQuestionRetryAllowed,
  GetShowOnlyTextExpalanation,
} from "../../../../../utils/helper";
import { getRetryEligibility } from "../../../../../api/student/studentActivityApi";
import { IQuestionAnswerProps } from "../../../../../model/interface/questionAnswer/questionAnswer";
import { IRetryMissedItemState } from "../../../../../model/interface/activitySession/retryAssignment";

function StudyPlanCannedTest(props: any) {
  const { t } = useTranslation();
  const studentType: any = "EL";
  const history = useHistory();
  const [cannedTest, setCannedTest] = useState<IInitalizeStudyPlanResponse>();
  const [lastSession, setLastSession] = useState<any>({});
  const subjectId = parseInt(atob(props.match.params.subjectId));
  const subjectName = props.location.state.subjectName ?? "";
  const cannedTestTypeId = parseInt(props.location.state.cannedTestTypeId ?? 3);
  const cannedTestId: number = parseInt(atob(props.match.params.cannedTestId));
  const [beginActivityModal, setBeginActivityModal] = useState<any>({
    open: false,
    data: undefined,
  });
  const [totalQuestionCount, setTotalQuestionCount] = useState<number>(0);
  const [calculatorReferenceId, setCalculatorReferenceId] = useState<string>();
  const [showExpalanation, setShowExplanation] = useState<boolean>(false);
  const [isQuestionRetry, setQuestionRetry] = useState<boolean>(false);
  const [showOnlyTextExpalanation, setShowOnlyTextExplanation] =
    useState<boolean>(false);
  const [selectedLanguage, setSelectedLanguage] = useState<number>(1);
  const [contentAreaId, setContentAreaId] = useState<number>(0);
  useEffect(() => {
    if (subjectId > 0) {
      getCalculatorBySubjectId(subjectId).then((res) => {
        if (res.data) setCalculatorReferenceId(res.data);
      });
    }
    setShowExplanation(
      GetShowExplanation(
        props.userContext?.gradeId,
        props.userContext?.stateId,
        Constant.ActivitySubTypes.PRACTICETEST,
        undefined,
        undefined,
        undefined
      )
    );
    setQuestionRetry(
      GetIsQuestionRetryAllowed(
        props.userContext?.gradeId,
        props.userContext?.stateId,
        Constant.ActivitySubTypes.PRACTICETEST
      )
    );
    setShowOnlyTextExplanation(
      GetShowOnlyTextExpalanation(
        props.userContext?.gradeId,
        props.userContext?.stateId,
        Constant.ActivitySubTypes.PRACTICETEST
      )
    );
    initialize();
  }, []);

  function initialize() {
    var classesResponseApi = getStudentClasses(
      props.userContext.userId,
      subjectId,
      0
    );
    var teacherResponseApi = getStudentTeacher(
      props.userContext.userId,
      subjectId,
      0
    );

    var lastSessionApi = getCannedTestLastSession(
      props.userContext.userId,
      cannedTestId,
      0
    );

    var totalQuestionResponseApi = getCannedTestTotalQuestionCount(
      cannedTestId,
      cannedTestTypeId
    );

    Promise.all([
      classesResponseApi,
      teacherResponseApi,
      lastSessionApi,
      totalQuestionResponseApi,
    ]).then(
      ([
        classesResponse,
        teachersResponse,
        lastSessionResponse,
        totalQuestionResponse,
      ]) => {
        setLastSession(lastSessionResponse.data);
        setTotalQuestionCount(totalQuestionResponse.data);
        var data: any = {
          classes: classesResponse.data,
          teachers: teachersResponse.data,
          classId:
            lastSessionResponse.data !== null
              ? lastSessionResponse.data.classId
              : undefined,
          teacherId:
            lastSessionResponse.data !== null
              ? lastSessionResponse.data.teacherUserId
              : undefined,
        };
        setBeginActivityModal({ open: true, data });
      }
    );
  }

  function initializeTest(data: any) {
    initializeCannedTest(
      props.userContext.userId,
      cannedTestId,
      data.classId,
      data.teacherId,
      data.languageId,
      props.userContext?.gradeId
    ).then((d) => {
      var data = d.data;
      setCannedTest(d.data);
      setBeginActivityModal({ open: false, data });
    });
  }

  // function update(data: any) {
  //   updatCannedTest(
  //     lastSession.studentCannedTestId,
  //     data.teacherId,
  //     data.classId
  //   );
  // }

  function completeStudyPlanActivity() {
    completeCannedTest(
      cannedTest?.activitySessionId ?? 0,
      props.userContext.userId,
      cannedTest?.sessionId ?? "",
      Constant.AssignmentStatusId.COMPLETE
    ).then((res) => {
      handleOnCompleteStudyPlanActivity();
    });
  }

  const breadcrumbItems = () => {
    const items: Array<IBreadcrumb> = [
      {
        name: t("Breadcrumb.Home"),
        url: RouteConstant.StudentRoute.Home,
        textColor: "",
      },
      {
        name: t("Breadcrumb.Elementary"),
        textColor: "",
        url: RouteConstant.StudentRoute.Home,
      },
      {
        name: t("Breadcrumb.StudyPlanCannedTest"),
        textColor: "",
        url: "",
      },
    ];

    return items;
  };

  const handleOnCompleteStudyPlanActivity = async () => {
    const resultUrl = RouteConstant.StudentRoute.cannedTestResult.replace(
      ":sessionId",
      cannedTest ? cannedTest.sessionId : ""
    );
    const res = await getRetryEligibility(cannedTest?.sessionId!);

    if (res.data.retryEligible) {
      redirectToRetryMissedItems(resultUrl);
    } else {
      history.push(resultUrl);
    }
  };

  function redirectToRetryMissedItems(resultUrl: string) {
    const retryUrl =
      RouteConstant.StudentRoute.StudentRetryAssignmentActivityEL.replace(
        ":sessionId",
        cannedTest?.sessionId!
      );

    const questionAnswer: IQuestionAnswerProps = {
      activityType: Constant.ActivityType.STUDYPLAN,
      activityId: cannedTest?.activityId ?? "",
      teacherActivityId: cannedTest?.learnositySessionGroupId ?? "",
      showActivity: false,
      showQuestions: true,
      questionIds: [],
      hasSections: false,
      hasItemBranching: false,
      isAlienExplanation: true,
      isGameChoosen: false,
      studentId: props.userContext.userId.toString(),
      resumeActivity: false,
      sessionId: cannedTest?.sessionId!, // replace with retry sessionId
      studentType: studentType,
      title: subjectName,
      adaptiveFilter: null,
      onExitRedirectUrl: RouteConstant.StudentRoute.Home,
      questionRetry: isQuestionRetry,
      showExplanation: showExpalanation,
      showTextExpalanation: showOnlyTextExpalanation,
      activitySessionId: cannedTest?.activitySessionId!, // replace with retry activitySessionId
      subjectId: subjectId,
      activitySubType: Constant.ActivitySubTypes.PRACTICETEST,
      calculatorReferenceId: calculatorReferenceId,
      OnSubmit: () => {},
      contentAreaId: contentAreaId,
      questionsState: cannedTest?.questionStates,
    };

    const state: IRetryMissedItemState = {
      activityType: Constant.RetryActivityType.STUDYPLAN_CANNEDTEST,
      languageId: Constant.Languages.English,
      resultUrl: resultUrl,
      questionAnswer: JSON.stringify(questionAnswer),
    };

    history.push({
      pathname: retryUrl,
      state: state,
    });
  }

  function onCancel () {
    history.push(`${
      RouteConstant.StudentRoute.Home
    }?tab=${Constant.StudyType.MYSTUDYPLAN.toLowerCase()}&subjectId=${subjectId}`);
  }

  return (
    <Fragment>
      <>
        <div className="px-4 py-3 lg:px-8 border-b">
          <nav className="flex mt-2" aria-label="Breadcrumb">
            <Breadcrumb items={breadcrumbItems()} />
          </nav>
          <div className="text-lg flex justify-between gap-2 h-auto sm:h-10 items-center">
            <h1 className="text-gray-50 font-medium flex justify-start">
              {t("StudyPlan.StudyPlanCannedTest")}
            </h1>
          </div>
        </div>
        {cannedTest !== undefined &&
          cannedTest &&
          cannedTest?.sessionId?.length > 0 && (
            <QuestionAnswer
              activityType={Constant.ActivityType.STUDYPLAN}
              activityId={cannedTest.activityId}
              teacherActivityId={cannedTest?.learnositySessionGroupId}
              showActivity={true}
              showQuestions={false}
              questionIds={[]}
              hasSections={true}
              hasItemBranching={false}
              isAlienExplanation={true}
              isGameChoosen={false}
              studentId={props.userContext.userId.toString()}
              resumeActivity={cannedTest.hasPendingSession}
              sessionId={cannedTest.sessionId}
              studentType="EL"
              title={subjectName}
              adaptiveFilter={null}
              OnSubmit={completeStudyPlanActivity}
              onExitRedirectUrl={RouteConstant.StudentRoute.Home}
              activitySubType={Constant.ActivitySubTypes.PRACTICETEST}
              calculatorReferenceId={calculatorReferenceId}
              cannedTestId={cannedTestId}
              questionRetry={isQuestionRetry}
              showExplanation={showExpalanation}
              showTextExpalanation={showOnlyTextExpalanation}
              activitySessionId={cannedTest.activitySessionId}
              language={selectedLanguage === 2 ? "es" : "en"}
              subjectId={subjectId}
              contentAreaId={contentAreaId}
              questionsState={cannedTest.questionStates}
            />
          )}
        {beginActivityModal.open && (
          <BeginActivity
            activity={"Practice Test - " + subjectName}
            name={subjectName + " Practice Test"}
            noOfQuestions={totalQuestionCount}
            source={"StudyPlan"}
            themeName={"ELEMENTARYHOME"}
            data={beginActivityModal.data}
            hideClass={true}
            hideTeacher={true}
            ActivityLanguageLevel={"UserLevel"}
            onBegin={(data: any, value: any) => {
              setContentAreaId(value.contentAreaId);
              setSelectedLanguage(value.languageId);
              if (
                lastSession === null ||
                lastSession === "" ||
                lastSession === undefined
              ) {
                initializeTest(value);
              } else {
                setCannedTest({
                  hasPendingSession: true,
                  sessionId: lastSession.learnositySessionId,
                  activityId: lastSession.learnosityReferenceId,
                  activitySessionId: lastSession.activitySessionId,
                  learnositySessionGroupId:
                    lastSession.learnositySessionGroupId,
                  isDisplayRandomQuestion: false,
                  questionStates: lastSession.questionStates,
                });
                //update(value);
                setBeginActivityModal({ ...beginActivityModal, open: false });
              }
            }}
            onCancel={onCancel}
            subjectId={subjectId}
          />
        )}
      </>
    </Fragment>
  );
}

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(StudyPlanCannedTest);

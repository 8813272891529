import Profile from "../../model/users/profile";
import * as actionType from "../actions/actionTypes"

export const profileReducer = (state: Profile | null = null, action: any) => {
    switch (action.type) {
        case actionType.GET_PROFILE: {    
            return state;
        }
        case actionType.SET_PROFILE: {    
            if (action.payload !== undefined) {
                state = action.payload
            }  
            return state;
        }
        default:
            return state;
    }
}
import Loader from "../../../shared/loader";
import { useEffect, useState, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { getUrlParams } from "../../../../utils/helper";
import Breadcrumb from "../../teacher/shared/breadcrumb";
import { IBreadcrumb } from "../../../../model/common/breadcrumb";
import { IDomainVidoeGroup } from "../../../../model/teacher/video";
import { getVideobySubjectId } from "../../../../api/teacher/video";
import { getSubjectDetail } from "../../../../api/teacher/assessment";
import RouteConstant from "../../../../utils/constant/routeConstant";
import constant from "../../../../utils/constant/constant";
import format from "format-duration";
import QuestionPreview from "../shared/questionPreview/questionPreview";
import { IKeyValuePair } from "../../../../model/interface/selectElement";
import { toast } from "react-toastify";

function InstructionalResourcesVideos(props) {
  const subjectId = getUrlParams()?.subjectId || undefined;
  const [loading, setLoading] = useState<boolean>(false);
  const [subjectDeails, setSubjectDetails] = useState<any>();
  const { t } = useTranslation();
  const [videoList, setVideoList] = useState<Array<IDomainVidoeGroup>>([]);
  const [viewBy, setViewBy] = useState<string>("Outline");
  const [title, setTitle] = useState<string>("");
  const debouncedSearchTerm = useDebounce(title, 500);
  const selectedViewBy =
    "flex text-white no-underline  hover:text-white hover:bg-opacity-90  bg-teal-light py-1 px-2 uppercase font-semibold shadow-xl rounded-md";
  const unSelectedViewBy =
    "flex text-gray-500 uppercase items-center px-2 group";
  const [open, setOpen] = useState<boolean>(false);
  const [IdToPreview, setIdToPreview] = useState<string>("");
  const [displayRows, setDisplayRows] = useState<Array<IKeyValuePair>>([]);
  const displayRowCount = "10";

  useEffect(() => {
    (async () => {
      if (!subjectId || parseInt(subjectId) === 0) {
        toast.error("Invalid Subject");
        return;
      }
      setLoading(true);
      const subDetails = await getSubjectDetail(subjectId);
      setLoading(false);
      if (subDetails?.data) setSubjectDetails(subDetails.data);
    })();
    GetVideos();
  }, []);
  useEffect(() => {
    setLoading(false);
    GetVideos();
  }, [debouncedSearchTerm]);

  function GetVideos() {
    setLoading(true);
    getVideobySubjectId(subjectId, title ?? undefined).then((response) => {
      setLoading(false);
      GroupVideoByDomain(response.data);
    });
  }

  function GroupVideoByDomain(_videos: any) {
    var videos: any = [];
    _videos.forEach((v) => {
      var video = v;
      video.videoTitle = stripHTML(v.videoTitle);
      videos.push(video);
    });

    const domains = Array.from(new Set(videos.map((item) => item.domainId)));
    var tempDomainVideosGroup: Array<IDomainVidoeGroup> = [];
    let domainTableRows: Array<IKeyValuePair> = [];
    domains.forEach((d: any) => {
      var domain = videos.find((t) => t.domainId === d);
      var domainGroup: any = {
        domainName:
          props.profile?.plLanguageId === constant.Languages.Spanish
            ? domain?.spanishDomainName ?? domain?.domainName ?? ""
            : domain?.domainName ?? "",
        domainId: d,
        videos: videos
          .filter((s) => s.domainId === d)
          .sort((a, b) => a.videoTitle.localeCompare(b.videoTitle)),
      };
      tempDomainVideosGroup.push(domainGroup);
      domainTableRows.push({ key: d.toString(), value: displayRowCount });
    });
    setVideoList(tempDomainVideosGroup);
    setDisplayRows(domainTableRows);
  }

  function handleSearchOnChange(e: any) {
    setTitle(e.target.value || "");
  }

  function selectViewBy(value: string) {
    setViewBy(value);
  }

  const filterHandler = (e) => {
    e.preventDefault();
    setVideoList([]);
    GetVideos();
  };

  const breadcrumbItems = () => {
    const items: Array<IBreadcrumb> = [
      { name: t("Breadcrumb.Home"), url: RouteConstant.ROUTE_DASHBOARD },
      {
        name: t("Breadcrumb.InstructionalResources"),
        url: "",
      },
      {
        name: t("Breadcrumb.Subjects"),
        url: RouteConstant.TeacherRoutes.InstructionalResourceSubjects,
      },
      {
        name: t("Breadcrumb.Resources"),
        url: RouteConstant.TeacherRoutes.InstructionalResources,
        stateObject: { label: subjectDeails?.name, value: subjectId },
      },
      { name: t("Breadcrumb.Videos"), url: "" },
    ];

    return items;
  };

  function useDebounce(value, delay) {
    // State and setters for debounced value
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(
      () => {
        // Update debounced value after delay
        const handler = setTimeout(() => {
          setDebouncedValue(value);
        }, delay);
        // Cancel the timeout if value changes (also on delay change or unmount)
        // This is how we prevent debounced value from updating if value is changed ...
        // .. within the delay period. Timeout gets cleared and restarted.
        return () => {
          clearTimeout(handler);
        };
      },
      [value, delay] // Only re-call effect if value or delay changes
    );
    return debouncedValue;
  }
  const openModal = (learnosityReferenceId) => {
    if (!learnosityReferenceId) {
      return false;
    }

    setOpen(true);
    setIdToPreview(learnosityReferenceId);
  };

  const popUpClose = (value) => {
    setOpen(value);
    setIdToPreview("");
  };

  function getRowsCount(domainId: number) {
    let rowsCount: number = 10;
    if (displayRows && setDisplayRows.length > 0) {
      const videosCount = displayRows.filter(
        (r) => r.key.toString() === domainId.toString()
      );
      if (videosCount && videosCount.length > 0) {
        rowsCount = parseInt(videosCount[0].value);
      }
    }
    return rowsCount;
  }
  function canLoadMoreVideos(domainId: number) {
    let canLoadMoreQuestions: boolean = true;
    const selectedDomainVideos: number = videoList.filter(
      (r) => r.domainId === domainId
    )[0].videos.length;
    if (selectedDomainVideos < 10) {
      return false;
    }

    const currentRow = displayRows.filter((r) => r.key === domainId.toString());
    if (
      currentRow &&
      currentRow.length > 0 &&
      parseInt(currentRow[0].value) > selectedDomainVideos
    ) {
      canLoadMoreQuestions = false;
    }

    return canLoadMoreQuestions;
  }
  function handleLoadMoreVideos(domainId: number) {
    const updatedRowSettings: Array<IKeyValuePair> = [];
    const currentRowSettings: Array<IKeyValuePair> | undefined = displayRows;
    if (currentRowSettings && currentRowSettings.length > 0) {
      currentRowSettings.map((currentRowSetting) => {
        const rowSetting: IKeyValuePair = {
          key: currentRowSetting.key,
          value:
            currentRowSetting.key.toString() === domainId.toString()
              ? (
                  parseInt(currentRowSetting.value) + parseInt(displayRowCount)
                ).toString()
              : currentRowSetting.value,
        };
        updatedRowSettings.push(rowSetting);
        return null;
      });
    }

    setDisplayRows(updatedRowSettings);
  }
  function stripHTML(myString) {
    return myString.replace(/(<([^>]+)>)/gi, "");
  }
  return (
    <div>
      {loading && <Loader />}
      {IdToPreview && (
        <QuestionPreview
          modal={openModal}
          show={open}
          changeValue={popUpClose}
          itemRef={IdToPreview}
          showClassification={false}
        ></QuestionPreview>
      )}
      <div className="min-w-0 w-full">
        <div className="lg:flex lg:items-center px-4 py-3 lg:px-8 border-b">
          <Breadcrumb items={breadcrumbItems()} />
          <div className="lg:w-[55%] text-lg flex justify-between gap-2 h-auto sm:h-10 items-center">
            <h1 className="text-primary-violet font-medium flex justify-start mb-0 pb-0">
              {"Videos"} {/* {t("BellRinger.BellRinger")} */}
            </h1>
          </div>
        </div>
        <form action="#" onSubmit={filterHandler}>
          <div className=" bg-[#00b1fb] bg-opacity-10 w-full gap-x-2 lg:gap-x-0 rounded-lg py-4 sm:py-2 px-5 flex flex-col sm:flex-row justify-between items-center mt-4">
            <input
              type="text"
              name="txtSearch"
              id="txtSearch"
              className="focus:ring-indigo-500 focus:border-indigo-500 w-60 rounded-md sm:text-sm border-gray-300"
              placeholder="Search by title or keyword"
              value={title}
              onChange={(e) => {
                handleSearchOnChange(e);
              }}
            />
            <div className="mt-1 flex justify-center mt-2 sm:mt-0 md:justify-start rounded-md shadow-sm w-72 max-w-xs">
              <a
                href="#!"
                title="List view"
                className={
                  viewBy === "Thumbnail" ? unSelectedViewBy : selectedViewBy
                }
                onClick={() => {
                  selectViewBy("Outline");
                }}
              >
                <svg
                  className="w-4 mr-1 text-white"
                  version="1.0"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 96.000000 96.000000"
                  preserveAspectRatio="xMidYMid meet"
                >
                  <g
                    transform="translate(0.000000,96.000000) scale(0.100000,-0.100000)"
                    fill={viewBy === "Thumbnail" ? "#6B7280" : "currentColor"}
                    stroke="none"
                  >
                    <path d="M80 760 c-11 -11 -20 -29 -20 -40 0 -26 34 -60 60 -60 26 0 60 34 60 60 0 26 -34 60 -60 60 -11 0 -29 -9 -40 -20z" />
                    <path d="M280 720 l0 -40 300 0 300 0 0 40 0 40 -300 0 -300 0 0 -40z" />
                    <path d="M80 520 c-11 -11 -20 -29 -20 -40 0 -26 34 -60 60 -60 26 0 60 34 60 60 0 26 -34 60 -60 60 -11 0 -29 -9 -40 -20z" />
                    <path d="M280 480 l0 -40 300 0 300 0 0 40 0 40 -300 0 -300 0 0 -40z" />
                    <path d="M80 280 c-11 -11 -20 -29 -20 -40 0 -26 34 -60 60 -60 26 0 60 34 60 60 0 26 -34 60 -60 60 -11 0 -29 -9 -40 -20z" />
                    <path d="M280 240 l0 -40 300 0 300 0 0 40 0 40 -300 0 -300 0 0 -40z" />
                  </g>
                </svg>
                TITLE
              </a>
              <a
                href="#!"
                title="Detail view"
                className={
                  viewBy === "Thumbnail" ? selectedViewBy : unSelectedViewBy
                }
                onClick={() => {
                  selectViewBy("Thumbnail");
                }}
              >
                <svg
                  className="w-4 mr-1 text-white"
                  version="1.0"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 96.000000 96.000000"
                  preserveAspectRatio="xMidYMid meet"
                >
                  <g
                    transform="translate(0.000000,96.000000) scale(0.100000,-0.100000)"
                    fill={viewBy === "Thumbnail" ? "currentColor" : "#6B7280"}
                    stroke="none"
                  >
                    {" "}
                    <path d="M150 660 l0 -150 150 0 150 0 0 150 0 150 -150 0 -150 0 0 -150z m240 0 l0 -90 -90 0 -90 0 0 90 0 90 90 0 90 0 0 -90z" />
                    <path d="M510 660 l0 -150 150 0 150 0 0 150 0 150 -150 0 -150 0 0 -150z m240 0 l0 -90 -90 0 -90 0 0 90 0 90 90 0 90 0 0 -90z" />
                    <path d="M150 300 l0 -150 150 0 150 0 0 150 0 150 -150 0 -150 0 0 -150z m240 0 l0 -90 -90 0 -90 0 0 90 0 90 90 0 90 0 0 -90z" />
                    <path d="M510 300 l0 -150 150 0 150 0 0 150 0 150 -150 0 -150 0 0 -150z m240 0 l0 -90 -90 0 -90 0 0 90 0 90 90 0 90 0 0 -90z" />
                  </g>
                </svg>
                THUMBNAIL
              </a>
            </div>
          </div>
        </form>

        <div className="w-full rounded-lg p-5">
          {videoList && videoList.length > 0 && !loading && (
            <>
              {viewBy === "Thumbnail" && (
                <div>
                  {videoList?.map((domain, index) => {
                    return (
                      <Fragment key={index}>
                        <div className="text-lg text-primary-violet font-semibold my-4 pt-4">
                          {domain.domainName}
                        </div>
                        <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-4 xxxl:grid-cols-5">
                          {domain.videos
                            ?.slice(0, getRowsCount(domain.domainId))
                            .map((video, index) => {
                              return (
                                <li
                                  key={index}
                                  className="border border-gray-50 col-span-1 flex flex-col text-center bg-white rounded-lg shadow divide-y divide-gray-200 hover:bg-gray-50"
                                >
                                  <div className="flex-1 flex flex-col p-8">
                                    <div>
                                      {video.thumbnail && (
                                        <>
                                          <img
                                            onClick={() =>
                                              openModal(
                                                video.englishQuestionReferenceId
                                              )
                                            }
                                            className="cursor-pointer hover:opacity-80 border rounded-xl overflow-hidden w-full"
                                            src={video.thumbnail}
                                            alt=""
                                          />
                                          <div className="items-center text-gray-400 text-xs mt-2">
                                            {video?.lengthInSeconds
                                              ? "(" +
                                                format(
                                                  1000 * video.lengthInSeconds
                                                ) +
                                                "s)"
                                              : ""}
                                          </div>
                                        </>
                                      )}
                                      <h3 className="h-10 lg:h-14 xl:h-10 text-gray-900 text-center text-sm font-medium">
                                        {stripHTML(video.videoTitle)}
                                      </h3>
                                    </div>
                                  </div>
                                </li>
                              );
                            })}
                          {canLoadMoreVideos(domain.domainId) && (
                            <span
                              onClick={() =>
                                handleLoadMoreVideos(domain.domainId)
                              }
                              className="flex justify-center cursor-pointer text-blue-500 underline hover:no-underline bg-gray-50 py-2 px-6 w-full mx-auto md:col-span-2 lg:col-span-3 2xl:col-span-4 xxxl:col-span-5"
                            >
                              Show More Videos
                            </span>
                          )}
                        </ul>
                      </Fragment>
                    );
                  })}
                </div>
              )}
              {viewBy === "Outline" && (
                <div>
                  {videoList?.map((domain, index) => {
                    return (
                      <Fragment>
                        <div className="text-lg text-primary-violet font-semibold my-4">
                          {domain.domainName}
                        </div>
                        <div className="mb-4 pb-4 overflow-x-auto">
                          <ul className="grid lg:grid-cols-2 2xl:grid-cols-3 gap-x-8 gap-y-3">
                            {domain.videos?.map((video, i) => {
                              return (
                                <li
                                  onClick={() =>
                                    openModal(video.englishQuestionReferenceId)
                                  }
                                  className="flex items-center gap-x-1 border border-primary-violet/50 hover:border-primary-violet py-1 px-2 rounded-md cursor-pointer group "
                                >
                                  <a
                                    href="#!"
                                    className="text-gray-700 block group-hover:text-primary-violet"
                                  >
                                    {stripHTML(video.videoTitle)}
                                  </a>
                                  <span className="items-center text-gray-400 text-xs">
                                    {video?.lengthInSeconds
                                      ? "(" +
                                        format(1000 * video.lengthInSeconds) +
                                        "s)"
                                      : ""}
                                  </span>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </Fragment>
                    );
                  })}
                </div>
              )}
            </>
          )}
          {videoList && videoList.length === 0 && !loading && (
            <span>No data found</span>
          )}
        </div>
      </div>
    </div>
  );
}
export default connect((state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
})(InstructionalResourcesVideos);

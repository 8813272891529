import { ITransferStudentResult } from "./../../model/teacher/classManagement/transferStudentResult";
import { AxiosResponse } from "axios";
import PagingResponse from "../../model/common/pagingResponse";
import classListResponse from "../../model/teacher/classManagement/classListResponse";
import ClassModel from "../../model/teacher/classManagement/classModel";
import classSearchDTO from "../../model/teacher/classManagement/classSearchModel";
import ClassStudentModel from "../../model/teacher/classManagement/classStudentModel";
import ClassSubjectModel from "../../model/teacher/classManagement/classSubjectModel";
import ClassTeacherModel from "../../model/teacher/classManagement/classTeacherModel";
import { IAvailavelClassSubject } from "../../model/teacher/classManagement/IAvailavelClassSubject";
import { IUpdateClassStudentSetting } from "../../model/teacher/classManagement/IUpdateClassStudentSetting";
import APIConstant from "../../utils/constant/apiConstant";
import auth from "../../utils/auth";
import { AxiosHelper } from "../../environment/axiosConfig";
import { IEligibleImportClassStudentData } from "../../model/teacher/classManagement/IEligibleImportClassStudentData";
import StudentClassListResponse from "../../model/teacher/classManagement/studentClassListResponse";
export class ClassApi {
  public static getClassList(
    classSearch: classSearchDTO
  ): Promise<AxiosResponse<PagingResponse<classListResponse>>> {
    return AxiosHelper.axiosInstance().get<PagingResponse<classListResponse>>(
      APIConstant.CLASSMANAGEMENT_API.ClassList,
      {
        params: {
          schoolId: classSearch.schoolId,
          showArchived: classSearch.showArchive,
          showHidden: classSearch.showHidden,
          showCurrent: classSearch.showCurrent,
          pageNumber: classSearch.pageNumber,
          pageSize: classSearch.pageSize,
          teacherUserId: classSearch.teacherUserId,
        },
      }
    );
  }

  public static getStudentClassList(
    studentId: number
  ): Promise<AxiosResponse<StudentClassListResponse[]>> {
    return AxiosHelper.axiosInstance().get(
      APIConstant.CHAT_API.GET_CLASSES_BY_STUDENT_ID.replace(
        "{:studentId}",
        studentId.toString()
      ),
      {}
    );
  }

  public static addClass(classDTO: ClassModel) {
    return AxiosHelper.axiosInstance().post(
      APIConstant.CLASSMANAGEMENT_API.AddClass,
      classDTO
    );
  }

  public static updateClass(classModel: ClassModel) {
    return AxiosHelper.axiosInstance().put(
      APIConstant.CLASSMANAGEMENT_API.UpdateClass,
      classModel
    );
  }

  public static deleteClass(
    classId: number,
    userId: number | undefined,
    isGoogleEnabled: boolean,
    googleClassId: number,
    courseId: string
  ) {
    return AxiosHelper.axiosInstance().delete(
      APIConstant.CLASSMANAGEMENT_API.DeleteClass,
      {
        params: {
          classId: classId,
          isGoogleEnabled: isGoogleEnabled,
          googleClassId: googleClassId,
          courseId: courseId,
          googleAccessToken: auth.getGoogleAccessToken(),
        },
      }
    );
  }
  public static setIsArchive(
    classId: number,
    userId: number | undefined,
    isArchived: boolean
  ) {
    return AxiosHelper.axiosInstance().put(
      APIConstant.CLASSMANAGEMENT_API.SetIsArchive,
      {
        classId: classId,
        userId: userId,
        isArchived: isArchived,
      }
    );
  }

  public static setIsHidden(
    classId: number,
    userId: number,
    isHidden: boolean
  ) {
    return AxiosHelper.axiosInstance().put(
      APIConstant.CLASSMANAGEMENT_API.SetIsHidden,
      {
        classId: classId,
        userId: userId,
        isHidden: isHidden,
      }
    );
  }

  public static getClassDetail(
    classId: number,
    userId: number
  ): Promise<AxiosResponse<ClassModel>> {
    const url =
      APIConstant.CLASSMANAGEMENT_API.ClassDetail + classId.toString();
    return AxiosHelper.axiosInstance().get<ClassModel>(url, {
      params: {
        classId: classId,
      },
    });
  }

  public static getClassSubject(
    classId: number,
    pageNumber: number,
    pageSize: number
  ): Promise<AxiosResponse<PagingResponse<ClassSubjectModel>>> {
    return AxiosHelper.axiosInstance().get<PagingResponse<ClassSubjectModel>>(
      APIConstant.CLASSMANAGEMENT_API.ClassSubject,
      {
        params: {
          classId: classId,
          pageNumber: pageNumber,
          pageSize: pageSize,
        },
      }
    );
  }

  public static getAvailableClassSubject(
    classId: number,
    schoolAccountId: number | undefined
  ): Promise<AxiosResponse<IAvailavelClassSubject[]>> {
    return AxiosHelper.axiosInstance().get<IAvailavelClassSubject[]>(
      APIConstant.CLASSMANAGEMENT_API.AvailableClassSubject,
      {
        params: {
          classId: classId,
          schoolAccountId: schoolAccountId,
        },
      }
    );
  }

  public static getSubjectsByUserId(
    userId?: number
  ): Promise<AxiosResponse<IAvailavelClassSubject[]>> {
    return AxiosHelper.axiosInstance().get<IAvailavelClassSubject[]>(
      APIConstant.CLASSMANAGEMENT_API.SubjectsbyUserId,
      {
        params: {
          userId: userId,
        },
      }
    );
  }
  public static getInstructionalResourcesSubjectsByUserId(
    userId?: number
  ): Promise<AxiosResponse<IAvailavelClassSubject[]>> {
    return AxiosHelper.axiosInstance().get<IAvailavelClassSubject[]>(
      APIConstant.CLASSMANAGEMENT_API.InstructionalResourcesSubjectsbyUserId,
      {
        params: {
          userId: userId,
        },
      }
    );
  }

  public static addClassSubject(
    classId: number,
    subjectIds: Array<number>,
    userId: number
  ) {
    const postData = {
      classId: classId,
      subjectIds: subjectIds,
    };
    return AxiosHelper.axiosInstance().post(
      APIConstant.CLASSMANAGEMENT_API.AddClassSubject,
      postData
    );
  }

  public static deleteClassSubject(
    classId: number,
    subjectIds: string,
    userId: number | undefined
  ) {
    return AxiosHelper.axiosInstance().delete(
      APIConstant.CLASSMANAGEMENT_API.DeleteClassSubject,
      {
        params: {
          classId: classId,
          subjectIds: subjectIds,
        },
      }
    );
  }
  public static getClassTeacher(
    classId: number,
    pageNumber: number,
    pageSize: number
  ) {
    return AxiosHelper.axiosInstance().get<PagingResponse<ClassTeacherModel>>(
      APIConstant.CLASSMANAGEMENT_API.ClassTeacher,
      {
        params: {
          classId: classId,
          pageNumber: pageNumber,
          pageSize: pageSize,
        },
      }
    );
  }
  public static getClassTeacherList(
    classId: number,
    pageNumber: number,
    pageSize: number
  ) {
    return AxiosHelper.axiosInstance().get(
      APIConstant.CLASSMANAGEMENT_API.ClassTeacher,
      {
        params: {
          classId: classId,
          pageNumber: pageNumber,
          pageSize: pageSize,
        },
      }
    );
  }
  public static getClassPendingTeacher(
    classId: number,
    pageNumber: number,
    pageSize: number,
    schoolAccountId: number | undefined,
    startWith: string,
    userId: number | undefined
  ): Promise<AxiosResponse<PagingResponse<ClassTeacherModel>>> {
    return AxiosHelper.axiosInstance().get<PagingResponse<ClassTeacherModel>>(
      APIConstant.CLASSMANAGEMENT_API.PendingClassTeacher,
      {
        params: {
          classId: classId,
          pageNumber: pageNumber,
          pageSize: pageSize,
          schoolAccountId: schoolAccountId,
          startWith: startWith === "All" ? "" : startWith,
          userId: userId,
        },
      }
    );
  }
  public static addClassTeacher(
    userId: number | undefined,
    classId: number,
    teacherId: Array<number>
  ) {
    const postData = {
      classId: classId,
      teacherIds: teacherId,
    };
    return AxiosHelper.axiosInstance().post(
      APIConstant.CLASSMANAGEMENT_API.AddClassTeacher,
      postData
    );
  }
  public static deleteClassTeacher(
    userId: number | undefined,
    classId: number,
    teacherId: Array<number>
  ) {
    return AxiosHelper.axiosInstance().delete(
      APIConstant.CLASSMANAGEMENT_API.DeleteClassTeacher,
      {
        params: {
          classId: classId,
          teacherIds: teacherId.join(","),
        },
      }
    );
  }

  public static getPrimaryClassTeacher(
    classId: number
  ): Promise<AxiosResponse<ClassTeacherModel>> {
    return AxiosHelper.axiosInstance().get<ClassTeacherModel>(
      APIConstant.CLASSMANAGEMENT_API.PrimaryClassTeacher,
      {
        params: {
          classId: classId,
        },
      }
    );
  }

  public static updateClassTeacherIsPrimary(
    classId: number,
    teacherId: number
  ) {
    return AxiosHelper.axiosInstance().put(
      APIConstant.CLASSMANAGEMENT_API.UpdateClassTeacherSetPrimary,
      { classId: classId, userId: teacherId }
    );
  }

  public static getClassStudent(
    classId: number,
    pageNumber: number,
    pageSize: number
  ): Promise<AxiosResponse<PagingResponse<ClassStudentModel>>> {
    return AxiosHelper.axiosInstance().get<PagingResponse<ClassStudentModel>>(
      APIConstant.CLASSMANAGEMENT_API.GetClassStudent,
      {
        params: {
          classId: classId,
          pageNumber: pageNumber,
          pageSize: pageSize,
        },
      }
    );
  }
  public static getClassStudentwithPassword(
    classId: number,
    pageNumber: number,
    pageSize: number
  ): Promise<AxiosResponse<PagingResponse<ClassStudentModel>>> {
    return AxiosHelper.axiosInstance().get<PagingResponse<ClassStudentModel>>(
      APIConstant.CLASSMANAGEMENT_API.GetClassStudentWithPassword,
      {
        params: {
          classId: classId,
          pageNumber: pageNumber,
          pageSize: pageSize,
        },
      }
    );
  }

  public static getClassStudentByClassIds(
    classId: string
  ): Promise<AxiosResponse<Array<ClassStudentModel>>> {
    return AxiosHelper.axiosInstance().get<Array<ClassStudentModel>>(
      APIConstant.CLASSMANAGEMENT_API.GetClassStudentsByClassIds,
      {
        params: {
          classIds: classId,
        },
      }
    );
  }
  public static getClassSubjectsByClassIds(
    classId: string
  ): Promise<AxiosResponse<Array<ClassSubjectModel>>> {
    return AxiosHelper.axiosInstance().get<Array<ClassSubjectModel>>(
      APIConstant.CLASSMANAGEMENT_API.GetClassSubjectsByClassIds,
      {
        params: {
          classIds: classId,
        },
      }
    );
  }
  public static getClassPendingStudent(
    classId: number,
    pageNumber: number,
    pageSize: number,
    schoolAccountId: number | undefined,
    startWith: string,
    gradeId: number,
    studentName: string,
    userId: number | undefined
  ): Promise<AxiosResponse<PagingResponse<ClassStudentModel>>> {
    return AxiosHelper.axiosInstance().get<PagingResponse<ClassStudentModel>>(
      APIConstant.CLASSMANAGEMENT_API.PendingClassStudent,
      {
        params: {
          classId: classId,
          pageNumber: pageNumber,
          pageSize: pageSize,
          schoolAccountId: schoolAccountId,
          startWith: startWith === "View All" ? "" : startWith,
          gradeId: gradeId,
          studentName: studentName,
          userId: userId,
        },
      }
    );
  }
  public static addClassStudent(
    userId: number | undefined,
    classId: number,
    studentIds: Array<number>,
    googleClassId: number,
    isGoogleEnabled: boolean,
    isCanvasEnabled: boolean
  ) {
    const postData = {
      classId: classId,
      studentIds: studentIds,
      googleClassId: googleClassId,
      isGoogleEnabled: isGoogleEnabled,
      isCanvasEnabled: isCanvasEnabled,
    };
    return AxiosHelper.axiosInstance().post(
      APIConstant.CLASSMANAGEMENT_API.AddClassStudent,
      postData
    );
  }
  public static deleteClassStudent(
    userId: number | undefined,
    classId: number,
    studentIds: Array<number>,
    isGoogleEnabled: boolean,
    googleClassId: number,
    courseId: string,
    googleIds: Array<string>,
    isCanvasEnabled: boolean,
    canvasCourseId: string
  ) {
    return AxiosHelper.axiosInstance().delete(
      APIConstant.CLASSMANAGEMENT_API.DeleteClassStudent,
      {
        params: {
          classId: classId,
          studentIds: studentIds.join(","),
          isGoogleEnabled: isGoogleEnabled,
          googleClassId: googleClassId,
          courseId: courseId,
          googleIds: googleIds.join(","),
          canvasCourseId: canvasCourseId,
          isCanvasEnabled: isCanvasEnabled,
          googleAccessToken: auth.getGoogleAccessToken(),
          canvasAccessToken: auth.getCanvasAccessToken(),
        },
      }
    );
  }

  public static updateClassStudentSetting(
    updateSetting: IUpdateClassStudentSetting
  ) {
    return AxiosHelper.axiosInstance().put(
      APIConstant.CLASSMANAGEMENT_API.UpdateClassStudent,
      {
        classId: updateSetting.classId,
        setting: updateSetting.setting,
        userIds: updateSetting.userIds.join(","),
        value: updateSetting.value.toString(),
      }
    );
  }

  public static getClassListByContentArea(
    contentAreaId: number,
    schoolAccountId: number | undefined
  ) {
    return AxiosHelper.axiosInstance().get(
      APIConstant.CLASSMANAGEMENT_API.ClassByContentArea,
      {
        params: {
          ContentAreaId: contentAreaId,
          SchoolAccountId: schoolAccountId,
        },
      }
    );
  }

  public static transferClassStudents(
    sourceClassId: number,
    destinationClassId: number,
    studentIds: Array<number>
  ): Promise<AxiosResponse<Array<ITransferStudentResult>>> {
    const postData = {
      SourceClassId: sourceClassId,
      DestinationClassId: destinationClassId,
      StudentIds: studentIds,
      TransferOrImport: "transfer",
    };
    return AxiosHelper.axiosInstance().post<Array<ITransferStudentResult>>(
      APIConstant.CLASSMANAGEMENT_API.TransferStudent,
      postData
    );
  }

  public static importStudentClassData(
    sourceClassId: number,
    destinationClassId: number,
    studentIds: Array<number>
  ): Promise<AxiosResponse<Array<ITransferStudentResult>>> {
    const postData = {
      SourceClassId: sourceClassId,
      DestinationClassId: destinationClassId,
      StudentIds: studentIds,
      TransferOrImport: "import",
    };
    return AxiosHelper.axiosInstance().post<Array<ITransferStudentResult>>(
      APIConstant.CLASSMANAGEMENT_API.TransferStudent,
      postData
    );
  }

  public static getEligibleImportStudents(
    classId: number
  ): Promise<AxiosResponse<Array<IEligibleImportClassStudentData>>> {
    return AxiosHelper.axiosInstance().get<
      Array<IEligibleImportClassStudentData>
    >(
      APIConstant.CLASSMANAGEMENT_API.GetEligibleImportStudents.replace(
        "{classId}",
        classId.toString()
      )
    );
  }

  public static getTeacherClasses(
    teacherId: number,
    studentId: number,
    schoolYearId: number
  ) {
    return AxiosHelper.axiosInstance().get(
      APIConstant.CLASSMANAGEMENT_API.TeacherClasses,
      {
        params: {
          teacherId: teacherId,
          studentId: studentId,
          schoolYearId: schoolYearId,
        },
      }
    );
  }

  public static validateClassPassword(classId: number, password: string) {
    return AxiosHelper.axiosInstance().get(
      APIConstant.CLASSMANAGEMENT_API.ValidatePassword,
      {
        params: {
          classId: classId,
          password: password,
        },
      }
    );
  }

  public static isStudentPresentInClass(classId: number, studentId: number) {
    return AxiosHelper.axiosInstance().get(
      APIConstant.CLASSMANAGEMENT_API.IsStudentPresent,
      {
        params: {
          classId: classId,
          studentId: studentId,
        },
      }
    );
  }

  public static joinClass(
    classId: number,
    studentId: number,
    teacherId: number
  ) {
    const postData = {
      classId: classId,
      studentId: studentId,
      teacherId: teacherId,
    };
    return AxiosHelper.axiosInstance().post(
      APIConstant.CLASSMANAGEMENT_API.JoinClass,
      postData
    );
  }

  public static hasDiagnosticsAvailable(
    classId: number
  ): Promise<AxiosResponse<boolean>> {
    const url = APIConstant.CLASSMANAGEMENT_API.HasDiagnosticsAvailable.replace(
      "{classId}",
      classId.toString()
    );
    return AxiosHelper.axiosInstance().get<boolean>(url);
  }

  public static getClassStudentForDiagnostics(
    classId: number,
    subjectId: number,
    schoolId: number
  ): Promise<AxiosResponse<Array<ClassStudentModel>>> {
    return AxiosHelper.axiosInstance().get(
      APIConstant.CLASSMANAGEMENT_API.GetClassStudentWithDiagnostics,
      {
        params: {
          classId: classId,
          subjectId: subjectId,
          schoolId: schoolId,
        },
      }
    );
  }
}

import { Configuration } from "../../../../../environment/setup";

function Rocket() {
  return (
    <div className="fixed inset-0 z-40 flex items-center bg-black bg-opacity-50 sm:justify-center enter-done">
      <div className="w-full px-6 py-4 overflow-hidden bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:max-w-xl">
        <div>
          <header className="flex justify-between">
            <h2>You have 12 out of 30 rockets! </h2>
            <button
              className="inline-flex items-center justify-center w-6 h-6 text-gray-400 transition-colors duration-150 rounded dark:hover:text-gray-200 hover: hover:text-gray-700"
              aria-label="close"
            >
              <svg
                className="w-4 h-4"
                fill="currentColor"
                viewBox="0 0 20 20"
                role="img"
                aria-hidden="true"
              >
                <path
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                  fillRule="evenodd"
                ></path>
              </svg>
            </button>
          </header>
          <div className="max-w-7xl mx-auto rounded-2xl mt-3">
            <ul className="grid grid-cols-2 gap-x-2 gap-y-3 sm:grid-cols-3 sm:gap-x-3 lg:grid-cols-6 xl:gap-x-4">
              <li className="relative">
                <div className="group bg-gradient-to-b from-[#0062ff] to-[#61efff] shadow hover:bg-opacity-80 p-2 block w-full rounded-lg focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden transition ease-in-out duration-500">
                  <img
                    src={`${Configuration.S3bucketImagePath}/images/student/elementary/rockets/rocket0.png`}
                    alt=""
                    className="object-cover pointer-events-none m-auto h-16"
                  />
                </div>
              </li>

              <li className="relative">
                <div className="group  bg-gradient-to-b from-[#0062ff] to-[#61efff] shadow bg-opacity-50 hover:bg-opacity-80 p-2  block w-full rounded-lg focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden transition ease-in-out duration-500">
                  <img
                    src={`${Configuration.S3bucketImagePath}/images/student/elementary/rockets/rocket1.png`}
                    alt=""
                    className="object-cover pointer-events-none  h-16 m-auto "
                  />
                </div>
              </li>

              <li className="relative">
                <div className="group bg-gradient-to-b from-[#0062ff] to-[#61efff] shadow bg-opacity-50 hover:bg-opacity-80 p-2  block w-full rounded-lg focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden transition ease-in-out duration-500">
                  <img
                    src={`${Configuration.S3bucketImagePath}/images/student/elementary/rockets/rocket2.png`}
                    alt=""
                    className="object-cover pointer-events-none  h-16 m-auto"
                  />
                </div>
              </li>

              <li className="relative">
                <div className="group bg-gradient-to-b from-[#0062ff] to-[#61efff] shadow bg-opacity-50 hover:bg-opacity-80 p-2 block w-full rounded-lg focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden transition ease-in-out duration-500">
                  <img
                    src={`${Configuration.S3bucketImagePath}/images/student/elementary/rockets/rocket3.png`}
                    alt=""
                    className="object-cover pointer-events-none  h-16 m-auto"
                  />
                </div>
              </li>

              <li className="relative">
                <div className="group bg-gradient-to-b from-[#0062ff] to-[#61efff] shadow bg-opacity-50 hover:bg-opacity-80 p-2 block w-full rounded-lg focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden transition ease-in-out duration-500">
                  <img
                    src={`${Configuration.S3bucketImagePath}/images/student/elementary/rockets/rocket4.png`}
                    alt=""
                    className="object-cover pointer-events-none  h-16 m-auto"
                  />
                </div>
              </li>

              <li className="relative">
                <div className="group bg-gradient-to-b from-[#0062ff] to-[#61efff] shadow bg-opacity-50 hover:bg-opacity-80 p-2 block w-full rounded-lg focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden transition ease-in-out duration-500">
                  <img
                    src={`${Configuration.S3bucketImagePath}/images/student/elementary/rockets/rocket5.png`}
                    alt=""
                    className="object-cover pointer-events-none  h-16 m-auto"
                  />
                </div>
              </li>

              <li className="relative">
                <div className="group bg-gradient-to-b from-[#0062ff] to-[#61efff] shadow bg-opacity-50 hover:bg-opacity-80 p-2 block w-full rounded-lg focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden transition ease-in-out duration-500">
                  <img
                    src={`${Configuration.S3bucketImagePath}/images/student/elementary/rockets/rocket6.png`}
                    alt=""
                    className="object-cover pointer-events-none  h-16 m-auto"
                  />
                </div>
              </li>

              <li className="relative">
                <div className="group bg-gradient-to-b from-[#0062ff] to-[#61efff] shadow bg-opacity-50 hover:bg-opacity-80 p-2  block w-full rounded-lg focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden transition ease-in-out duration-500">
                  <img
                    src={`${Configuration.S3bucketImagePath}/images/student/elementary/rockets/rocket7.png`}
                    alt=""
                    className="object-cover pointer-events-none  h-16 m-auto"
                  />
                </div>
              </li>

              <li className="relative">
                <div className="group bg-gradient-to-b from-[#0062ff] to-[#61efff] shadow bg-opacity-50 hover:bg-opacity-80 p-2  block w-full rounded-lg focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden transition ease-in-out duration-500">
                  <img
                    src={`${Configuration.S3bucketImagePath}/images/student/elementary/rockets/rocket8.png`}
                    alt=""
                    className="object-cover pointer-events-none  h-16 m-auto"
                  />
                </div>
              </li>
              <li className="relative">
                <div className="group bg-gradient-to-b from-[#0062ff] to-[#61efff] shadow bg-opacity-50 hover:bg-opacity-80 p-2 block w-full rounded-lg focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden transition ease-in-out duration-500">
                  <img
                    src={`${Configuration.S3bucketImagePath}/images/student/elementary/rockets/rocket9.png`}
                    alt=""
                    className="object-cover pointer-events-none  h-16 m-auto"
                  />
                </div>
              </li>

              <li className="relative">
                <div className="group bg-gradient-to-b from-[#0062ff] to-[#61efff] shadoww bg-opacity-50 hover:bg-opacity-80 p-2 block w-full rounded-lg focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden transition ease-in-out duration-500">
                  <img
                    src={`${Configuration.S3bucketImagePath}/images/student/elementary/rockets/rocket10.png`}
                    alt=""
                    className="object-cover pointer-events-none  h-16 m-auto"
                  />
                </div>
              </li>

              <li className="relative">
                <div className="group bg-gradient-to-b from-[#0062ff] to-[#61efff] shadow bg-opacity-50 hover:bg-opacity-80 p-2 block w-full rounded-lg focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden transition ease-in-out duration-500">
                  <img
                    src={`${Configuration.S3bucketImagePath}/images/student/elementary/rockets/rocket11.png`}
                    alt=""
                    className="object-cover pointer-events-none  h-16 m-auto"
                  />
                </div>
              </li>

              <li className="relative">
                <div className="group bg-gradient-to-b from-[#0062ff] to-[#61efff] shadow bg-opacity-50 hover:bg-opacity-80 p-2 block w-full rounded-lg focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden transition ease-in-out duration-500">
                  <img
                    src={`${Configuration.S3bucketImagePath}/images/student/elementary/rockets/rocket12.png`}
                    alt=""
                    className="object-cover pointer-events-none  h-16 m-auto"
                  />
                </div>
              </li>

              <li className="relative">
                <div className="group bg-gradient-to-b from-[#0062ff] to-[#61efff] shadow bg-opacity-50 hover:bg-opacity-80 p-2  block w-full rounded-lg focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden transition ease-in-out duration-500">
                  <img
                    src={`${Configuration.S3bucketImagePath}/images/student/elementary/rockets/rocket13.png`}
                    alt=""
                    className="object-cover pointer-events-none  h-16 m-auto "
                  />
                </div>
              </li>

              <li className="relative">
                <div className="group bg-gradient-to-b from-[#0062ff] to-[#61efff] shadow bg-opacity-50 hover:bg-opacity-80 p-2  block w-full rounded-lg focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden transition ease-in-out duration-500">
                  <img
                    src={`${Configuration.S3bucketImagePath}/images/student/elementary/rockets/rocket14.png`}
                    alt=""
                    className="object-cover pointer-events-none  h-16 m-auto"
                  />
                </div>
              </li>

              <li className="relative">
                <div className="group bg-gradient-to-b from-[#0062ff] to-[#61efff] shadow bg-opacity-50 hover:bg-opacity-80 p-2 block w-full rounded-lg focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden transition ease-in-out duration-500">
                  <img
                    src={`${Configuration.S3bucketImagePath}/images/student/elementary/rockets/rocket15.png`}
                    alt=""
                    className="object-cover pointer-events-none  h-16 m-auto"
                  />
                </div>
              </li>

              <li className="relative">
                <div className="group bg-gradient-to-b from-[#0062ff] to-[#61efff] shadow bg-opacity-50 hover:bg-opacity-80 p-2 block w-full rounded-lg focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden transition ease-in-out duration-500">
                  <img
                    src={`${Configuration.S3bucketImagePath}/images/student/elementary/rockets/rocket16.png`}
                    alt=""
                    className="object-cover pointer-events-none  h-16 m-auto"
                  />
                </div>
              </li>

              <li className="relative">
                <div className="group bg-gradient-to-b from-[#0062ff] to-[#61efff] shadow bg-opacity-50 hover:bg-opacity-80 p-2 block w-full rounded-lg focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden transition ease-in-out duration-500">
                  <img
                    src={`${Configuration.S3bucketImagePath}/images/student/elementary/rockets/rocket17.png`}
                    alt=""
                    className="object-cover pointer-events-none  h-16 m-auto"
                  />
                </div>
              </li>

              <li className="relative">
                <div className="group bg-gradient-to-b from-[#0062ff] to-[#61efff] shadow bg-opacity-50 hover:bg-opacity-80 p-2 block w-full rounded-lg focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden transition ease-in-out duration-500">
                  <img
                    src={`${Configuration.S3bucketImagePath}/images/student/elementary/rockets/rocket18.png`}
                    alt=""
                    className="object-cover pointer-events-none  h-16 m-auto"
                  />
                </div>
              </li>

              <li className="relative">
                <div className="group bg-gradient-to-b from-[#0062ff] to-[#61efff] shadow bg-opacity-50 hover:bg-opacity-80 p-2  block w-full rounded-lg focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden transition ease-in-out duration-500">
                  <img
                    src={`${Configuration.S3bucketImagePath}/images/student/elementary/rockets/rocket19.png`}
                    alt=""
                    className="object-cover pointer-events-none  h-16 m-auto"
                  />
                </div>
              </li>

              <li className="relative">
                <div className="group bg-gradient-to-b from-[#0062ff] to-[#61efff] shadow bg-opacity-50 hover:bg-opacity-80 p-2  block w-full rounded-lg focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden transition ease-in-out duration-500">
                  <img
                    src={`${Configuration.S3bucketImagePath}/images/student/elementary/rockets/rocket20.png`}
                    alt=""
                    className="object-cover pointer-events-none  h-16 m-auto"
                  />
                </div>
              </li>

              <li className="relative">
                <div className="group bg-gradient-to-b from-[#0062ff] to-[#61efff] shadow bg-opacity-50 hover:bg-opacity-80 p-2 block w-full rounded-lg focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden transition ease-in-out duration-500">
                  <img
                    src={`${Configuration.S3bucketImagePath}/images/student/elementary/rockets/rocket21.png`}
                    alt=""
                    className="object-cover pointer-events-none  h-16 m-auto"
                  />
                </div>
              </li>

              <li className="relative">
                <div className="group bg-gradient-to-b from-[#0062ff] to-[#61efff] shadow bg-opacity-50 hover:bg-opacity-80 p-2 block w-full rounded-lg focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden transition ease-in-out duration-500">
                  <img
                    src={`${Configuration.S3bucketImagePath}/images/student/elementary/rockets/rocket22.png`}
                    alt=""
                    className="object-cover pointer-events-none  h-16 m-auto"
                  />
                </div>
              </li>

              <li className="relative">
                <div className="group bg-gradient-to-b from-[#0062ff] to-[#61efff] shadow bg-opacity-50 hover:bg-opacity-80 p-2 block w-full rounded-lg focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden transition ease-in-out duration-500">
                  <img
                    src={`${Configuration.S3bucketImagePath}/images/student/elementary/rockets/rocket_locked16.png`}
                    alt=""
                    className="object-cover pointer-events-none  h-16 m-auto"
                  />
                </div>
              </li>

              <li className="relative">
                <div className="group bg-gradient-to-b from-[#0062ff] to-[#61efff] shadow bg-opacity-50 hover:bg-opacity-80 p-2 block w-full rounded-lg focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden transition ease-in-out duration-500">
                  <img
                    src={`${Configuration.S3bucketImagePath}/images/student/elementary/rockets/rocket_locked16.png`}
                    alt=""
                    className="object-cover pointer-events-none  h-16 m-auto"
                  />
                </div>
              </li>

              <li className="relative">
                <div className="group bg-gradient-to-b from-[#0062ff] to-[#61efff] shadow bg-opacity-50 hover:bg-opacity-80 p-2  block w-full rounded-lg focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden transition ease-in-out duration-500">
                  <img
                    src={`${Configuration.S3bucketImagePath}/images/student/elementary/rockets/rocket_locked29.png`}
                    alt=""
                    className="object-cover pointer-events-none  h-16 m-auto"
                  />
                </div>
              </li>

              <li className="relative">
                <div className="group bg-gradient-to-b from-[#0062ff] to-[#61efff] shadow bg-opacity-50 hover:bg-opacity-80 p-2 block w-full rounded-lg focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden transition ease-in-out duration-500">
                  <img
                    src={`${Configuration.S3bucketImagePath}/images/student/elementary/rockets/rocket26.png`}
                    alt=""
                    className="object-cover pointer-events-none  h-16 m-auto"
                  />
                </div>
              </li>

              <li className="relative">
                <div className="group bg-gradient-to-b from-[#0062ff] to-[#61efff] shadow bg-opacity-50 hover:bg-opacity-80 p-2 block w-full rounded-lg focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden transition ease-in-out duration-500">
                  <img
                    src={`${Configuration.S3bucketImagePath}/images/student/elementary/rockets/rocket27.png`}
                    alt=""
                    className="object-cover pointer-events-none  h-16 m-auto"
                  />
                </div>
              </li>

              <li className="relative">
                <div className="group bg-gradient-to-b from-[#0062ff] to-[#61efff] shadow bg-opacity-50 hover:bg-opacity-80 p-2 block w-full rounded-lg focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden transition ease-in-out duration-500">
                  <img
                    src={`${Configuration.S3bucketImagePath}/images/student/elementary/rockets/rocket28.png`}
                    alt=""
                    className="object-cover pointer-events-none  h-16 m-auto"
                  />
                </div>
              </li>

              <li className="relative">
                <div className="group bg-gradient-to-b from-[#0062ff] to-[#61efff] shadow bg-opacity-50 hover:bg-opacity-80 p-2 block w-full rounded-lg focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden transition ease-in-out duration-500">
                  <img
                    src={`${Configuration.S3bucketImagePath}/images/student/elementary/rockets/rocket29.png`}
                    alt=""
                    className="object-cover pointer-events-none  h-16 m-auto"
                  />
                </div>
              </li>
            </ul>
          </div>
          <footer className="flex flex-col items-center justify-end px-6 py-3 -mx-6 -mb-4 space-y-4 sm:space-y-0 sm:space-x-6 sm:flex-row bg-gray-50 dark:bg-gray-800">
            <button
              className="align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 rounded-lg text-sm text-white border border-transparent  focus:shadow-outline-purple w-full sm:w-auto bg-primary-blue hover:bg-[#0149c1] gap-2 hover:shadow-md"
              type="button"
            >
              OK
            </button>
          </footer>
        </div>
      </div>
    </div>
  );
}

export default Rocket;

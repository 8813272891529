import { Fragment, useEffect, useState } from "react";
import Constant from "../../../../utils/constant/constant";
import Assessment from "./assessment";
import PrebuildTest from "../assignment/prebuildTest";
import PracticeQuestions from "./practiceQuestion";
import VideoLesson from "../assignment/videoLesson";
import ConstructedResponse from "../assignment/constructedResponse";
import { ForwardArrowIcon } from "../../../../assets/icons/index";
import { ISelectElement } from "../../../../model/interface/selectElement";
import Select from "react-select";
import {
  addAssignment,
  addAssignmentActivity,
  getAssociatedSubjects,
  updateAssignmentData,
} from "../../../../api/teacher/assignment";
import { IAssignment } from "../../../../model/interface/assignment";
import ConfirmationDialog from "../../../shared/confirmationDialog";
import { IAssignmentActivity } from "../../../../model/interface/assignmentActivity";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import InfoMessage from "../../../shared/infoMessage";
import Loader from "../../../shared/loader";
import { getActivityTypeId } from "../../../../utils/helper";
import MessagePopup from "../../../shared/messagePopup";
import arraySort from "array-sort";
import UpGradeMessagePopup from "../../../shared/upGradeMessagePopup";
import constant from "../../../../utils/constant/constant";
import { getAssignmentBankUpgradePermission } from "../../../shared/permissionHelper";
import { ISchoolYear } from "../../../../model/teacher/schoolYear";
import Profile from "../../../../model/users/profile";

interface ContentProps {
  userId: number;
  assignment: IAssignment;
  updateAssignment: (
    updatedAsignment: IAssignment,
    moveToNextTab?: boolean
  ) => void;
  assessmentId?: number;
  assessmentName?: string;
  assessmentSubjectId?: number;
  roleId?: number;
  schoolYearData?: Array<ISchoolYear>;
  isEditMode: boolean;
  profile: Profile;
}

function Content(props: ContentProps) {
  const assignmentUpdateMessage =
    "Your assignment has been successfully created.";
  const activityUpdateMessage = "Activity saved successfully";

  const {
    userId,
    roleId,
    assignment,
    updateAssignment,
    assessmentId,
    assessmentName,
    assessmentSubjectId,
    schoolYearData,
  } = props;
  const defaultValue = { label: "Select", value: "0" };
  const [assignmentPart, setAssignmentPart] = useState("");
  const [assignmentName, setAssignmentName] = useState<string>(assignment.name);
  const [subjects, setSubjects] = useState<Array<any>>([]);
  const [subjectChangeConfirmation, setSubjectChangeConfirmation] =
    useState<boolean>(false);
  const [selectedSubject, setSelectedSubject] =
    useState<ISelectElement>(defaultValue);
  const [selectedSubjectConfirm, setSelectedSubjectConfirm] =
    useState<ISelectElement>(defaultValue);
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [assessmentKey, setAssessmentKey] = useState<number>(0);
  const [activityUpdated, setActivityUpdated] = useState<boolean>(false);
  const [errorMessagePopup, setErrorMessagePopup] = useState<boolean>(false);
  const [upgrade, setUpgrade] = useState(false);
  const [defaultSchoolYearId, setDefaultSchoolYearId] = useState<number>(1);
  const [schoolYears, setSchoolYears] = useState<Array<ISelectElement>>();
  const [selectedSchoolYearId, setSelectedSchoolYearId] =
    useState<ISelectElement>();
  const {
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [showUpgradePopup, setShowUpgradePopup] = useState<boolean>(false);

  useEffect(() => {
    checkUpgradeRequired();
  }, []);

  useEffect(() => {
    if (schoolYearData && schoolYearData?.length > 0) {
      var schoolyears: Array<ISelectElement> = schoolYearData.map(
        (d: ISchoolYear) => {
          return {
            label: d.name,
            value: d.schoolYearId.toString(),
          };
        }
      );
      setSchoolYears(schoolyears);
      var currentSchoolYear: ISchoolYear = schoolYearData.filter(
        (x) => x.currentYearOffset === 0
      )[0];
      setDefaultSchoolYearId(currentSchoolYear.schoolYearId);
      if (assignment.schoolYearId) {
        var assignmentSchoolYear = schoolYearData?.filter(
          (s) => s.schoolYearId == assignment.schoolYearId
        )[0];
        setSelectedSchoolYearId({
          label: assignmentSchoolYear?.name,
          value: assignmentSchoolYear.schoolYearId.toString(),
        });
      } else if (schoolyears)
        setSelectedSchoolYearId({
          label: schoolyears[0]?.label,
          value: schoolyears[0]?.value,
        });
    }
  }, [schoolYearData]);

  const checkUpgradeRequired = async () => {
    if (getAssignmentBankUpgradePermission(roleId ?? 0)) {
      setUpgrade(true);
    } else {
      setUpgrade(false);
    }
  };

  function handleAssignmentNameChange(e: any) {
    const assignmentName = e.target.value;
    setAssignmentName(assignmentName);
    assignment.name = assignmentName;
    updateAssignment(assignment);
  }

  function handleSubjectChange(selectdItem: any) {
    if (!selectdItem.value) {
      return false;
    }

    verifyCanSeeContent(selectdItem.value, selectedSchoolYearId?.value);

    setSelectedSubjectConfirm(selectdItem);
    if (      
      assignment.activities &&
      assignment.activities?.length > 0
    ) {
      setSubjectChangeConfirmation(true);
    } else {
      setAssessmentKey(Math.random());
      setSelectedSubject(selectdItem);
      assignment.subjectId = parseInt(selectdItem.value);
      assignment.subjectName = selectdItem.label;
      updateAssignment(assignment);
    }
  }

  function canShowOptions(subjectId: string, schoolYearId: string | undefined) {
    return (
      subjectId &&
      subjectId.toString() !== "0" &&
      schoolYearId &&
      schoolYearId.toString() !== "0"
    );
  }

  function verifyCanSeeContent(
    subjectId: string,
    schoolYearId: string | undefined
  ) {
    if (canShowOptions(subjectId, schoolYearId)) {
      setAssignmentPart(Constant.AssignmentPart.ASSESSMENT);
    }
  }

  function confirmationOkClose() {
    setAssessmentKey(Math.random());
    setSelectedSubject(selectedSubjectConfirm);
    const existingActivities: Array<IAssignmentActivity> | undefined =
      assignment.activities;
    assignment.subjectId = parseInt(selectedSubjectConfirm.value);
    assignment.subjectName = selectedSubjectConfirm.label;
    assignment.activities = [];
    assignment.isSubjectChanged = true;
    updateAssignment(assignment);
    setSubjectChangeConfirmation(false);
    setAssignmentPart(Constant.AssignmentPart.ASSESSMENT);
    if (existingActivities) {
      addAssignmentActivity(
        userId,
        assignment.assignmentId,
        assignmentName,
        parseInt(selectedSubject.value),
        existingActivities,
        true
      );
    }
  }

  function confirmationCancelClose() {
    setSubjectChangeConfirmation(false);
    setSelectedSubjectConfirm(defaultValue);
  }

  const findSubjectByValue: any = () => {
    if (!selectedSubject.value) {
      return null;
    }

    if (!selectedSubject || selectedSubject.value === "0") return null;

    return subjects
      .filter((r) => r.options)
      ?.reduce((prev, curr) => {
        return (
          prev ||
          curr.options.find((option) => option.value === selectedSubject.value)
        );
      }, null);
  };

  const getContentAreaSubjects = (
    results: any,
    isAdditionalSubject: boolean,
    contentAreaId: number
  ) => {
    var subjects: Array<ISelectElement> = results
      .filter(
        (r) =>
          r.isAdditionalSubject === isAdditionalSubject &&
          r.contentAreaId === contentAreaId
      )
      .map((values: any) => {
        return {
          label: values?.name,
          value: values?.subjectId,
        };
      });

    return arraySort(subjects, "label");
  };

  function getSubjects() {
    getAssociatedSubjects(userId).then((response) => {
      const results: any = response.data;

      const additionalSubjectRoles: Array<number> = [
        Constant.UserRoleId.SchoolAdmin,
        Constant.UserRoleId.SchoolTeacher,
      ];

      const showAdditionalSubject: boolean = additionalSubjectRoles.some(
        (r) => r === roleId
      );

      const mySubjects = [
        {
          label: "My Subjects",
        },
        {
          label: "English",
          options: getContentAreaSubjects(
            results,
            false,
            Constant.ContentArea.English
          ),
        },
        {
          label: "Math",
          options: getContentAreaSubjects(
            results,
            false,
            Constant.ContentArea.Math
          ),
        },
        {
          label: "Science",
          options: getContentAreaSubjects(
            results,
            false,
            Constant.ContentArea.Science
          ),
        },
        {
          label: "Social Studies",
          options: getContentAreaSubjects(
            results,
            false,
            Constant.ContentArea.SocialStudies
          ),
        },
      ];

      const additionalSubjects = [
        {
          label: "Additional Subjects",
        },
        {
          label: "English",
          options: getContentAreaSubjects(
            results,
            true,
            Constant.ContentArea.English
          ),
        },
        {
          label: "Math",
          options: getContentAreaSubjects(
            results,
            true,
            Constant.ContentArea.Math
          ),
        },
        {
          label: "Science",
          options: getContentAreaSubjects(
            results,
            true,
            Constant.ContentArea.Science
          ),
        },
        {
          label: "Social Studies",
          options: getContentAreaSubjects(
            results,
            true,
            Constant.ContentArea.SocialStudies
          ),
        },
      ];

      let subjectOptions = mySubjects;

      if (showAdditionalSubject) {
        subjectOptions.push(...additionalSubjects);
      }

      setSubjects(subjectOptions);

      if (assignment.subjectId > 0) {
        const selectedSubject: ISelectElement = results
          .filter((r) => r.subjectId === assignment.subjectId)
          .map((values: any) => {
            return {
              label: values?.name,
              value: values?.subjectId,
            };
          })[0];

        setSelectedSubject(selectedSubject);
      }

      // when redirect from assessment
      if (assessmentSubjectId) {
        const selectedSubject: ISelectElement = results
          .filter((r) => r.subjectId === assessmentSubjectId)
          .map((values: any) => {
            return {
              label: values?.name,
              value: values?.subjectId,
            };
          })[0];

        setSelectedSubject(selectedSubject);
        assignment.subjectId = parseInt(selectedSubject.value);
        assignment.subjectName = selectedSubject.label;
        updateAssignment(assignment);
      }
    });
  }

  const colourStyles = {
    option: (provided, state) => ({
      ...provided,
      color:
        state.label === "My Subjects" || state.label === "Additional Subjects"
          ? "#01a2d3"
          : "default",
    }),
  };

  function hasLockedPremiumAssessments() {
    const lockedActivities = assignment!.activities!.filter(
      (a) => a.hasLockedPremiumAssessment === true
    );
    return lockedActivities.length > 0;
  }

  function handleSaveAssignemnt(data) {
    assignment.schoolYearId = selectedSchoolYearId
      ? Number(selectedSchoolYearId.value)
      : defaultSchoolYearId;
    if (assignmentName.trim() === "") {
      toast.error("Assignment name cannot be empty");
      return false;
    }
    if (
      !assignment.activities ||
      (assignment.activities && assignment.activities.length === 0)
    ) {
      toast.error("Please add activity");
      return false;
    }
    if (
      assignment.activities?.length > 0 &&
      hasLockedPremiumAssessments() &&
      canActivityUpdate()
    ) {
      toast.error("Cannot assign locked assessment");
      return false;
    }
    if (assignment.assignmentId === 0) {
      if (assignmentName.trim() === "") {
        toast.error("Assignment name cannot be empty");
        return false;
      } else if (selectedSubject.value === "0") {
        toast.error("Please select subject");
        return false;
      } else if (
        !assignment.activities ||
        (assignment.activities && assignment.activities.length === 0)
      ) {
        toast.error("Please add activity");
        return false;
      }
      setShowLoading(true);
      addAssignment(
        userId,
        assignmentName,
        parseInt(selectedSubject.value),
        assignment.activities ? assignment.activities : [],
        Number(selectedSchoolYearId?.value)
      )
        .then((response) => {
          setShowLoading(false);
          setAssignmentPart(Constant.AssignmentPart.ASSESSMENT);
          toast.success(assignmentUpdateMessage);
          assignment.assignmentId = parseInt(response.data);
          assignment.assignmentStatusId = Constant.AssignmentStatusId.DRAFT;
          assignment.name = assignmentName;
          updateAssignment(assignment, true);
        })
        .catch((err) => {
          setShowLoading(false);
          setErrorMessagePopup(true);
        });
    } else {
      if (assignment.activities && activityUpdated) {
        setShowLoading(true);
        addAssignmentActivity(
          userId,
          assignment.assignmentId,
          assignmentName,
          parseInt(selectedSubject.value),
          assignment.activities.filter(
            (activity) => activity.activityType === assignmentPart
          ),
          false,
          activityUpdated,
          getActivityTypeId(assignmentPart)
        ).then((response) => {
          updateAssignmentData(userId, assignment).then((response) => {
            updateAssignment(assignment, true);
            setShowLoading(false);
            toast.success(activityUpdateMessage);
          });
        });
      } else {
        updateAssignmentData(userId, assignment).then((response) => {
          updateAssignment(assignment, true);
        });
      }
    }
  }

  const toggleErrorMessagePopup = () => {
    setErrorMessagePopup(false);
  };
  function handleTabChange(assignmentPartValue: string) {
    if (assignmentName.trim() === "") {
      toast.error("Assignment name cannot be empty");
      return false;
    }

    let isActivityExist: boolean | undefined = false;
    if (assignment.assignmentId > 0) {
      isActivityExist = true;
    } else {
      isActivityExist =
        assignment.activities &&
        assignment.activities.some(
          (activity) => activity.activityType === assignmentPart
        );
    }

    if (assignment.activities && isActivityExist && activityUpdated) {
      setShowLoading(true);
      addAssignmentActivity(
        userId,
        assignment.assignmentId,
        assignmentName,
        parseInt(selectedSubject.value),
        assignment.activities.filter(
          (activity) => activity.activityType === assignmentPart
        ),
        false,
        activityUpdated,
        getActivityTypeId(assignmentPart)
      ).then((response) => {
        setShowLoading(false);
        toast.success(activityUpdateMessage);
        if (assignment.assignmentId === 0) {
          assignment.assignmentStatusId = Constant.AssignmentStatusId.DRAFT;
        }
        assignment.assignmentId = parseInt(response.data);

        setAssignmentPart(assignmentPartValue);
        setActivityUpdated(false);
      });
    } else {
      setAssignmentPart(assignmentPartValue);
    }
  }

  function canActivityUpdate() {
    return assignment.assignmentId > 0 &&
      assignment.assignmentStatus &&
      (assignment.assignmentStatus.toLocaleUpperCase() ===
        Constant.AssignmentStatus.INPROGRESS ||
        assignment.assignmentStatus.toLocaleUpperCase() ===
          Constant.AssignmentStatus.COMPLETE)
      ? false
      : true;
  }

  function handleSchoolYearChange(e) {
    setSelectedSchoolYearId(e);
    verifyCanSeeContent(selectedSubject.value, e.value);
  }

  useEffect(() => {
    getSubjects();
    if (assignment.assignmentId > 0 || assessmentId) {
      setAssignmentPart(Constant.AssignmentPart.ASSESSMENT);
    }

    if (assignment.assignmentId === 0 && assessmentName) {
      setAssignmentName(assessmentName);
    }
  }, []);

  function canAllowViewPreBuiltTests() {
    const hasPremiumActivities =
      assignment.activities
        ?.filter(
          (r) =>
            r.activityType === Constant.AssignmentActivityType.PREBUILTTESTS
        )
        ?.some((activity) => activity.cannedTestId != null) ?? false;
    return props.isEditMode && hasPremiumActivities;
  }

  return (
    <Fragment>
      {showLoading && <Loader></Loader>}
      <ConfirmationDialog
        open={subjectChangeConfirmation}
        confirmationTitle={"Are you sure?"}
        confirmationMessage={
          "Updating the subject will remove all selected activities. Are you sure you want to change the subject?"
        }
        onCancelClick={confirmationCancelClose}
        onOkClick={confirmationOkClose}
      ></ConfirmationDialog>
      {showUpgradePopup && (
        <UpGradeMessagePopup
          togglePopup={setShowUpgradePopup}
          roleId={props?.roleId ?? 0}
          message={constant.UPGRADE_MESSAGE_SCHOOL_ACCOUNT}
        />
      )}

      {errorMessagePopup && (
        <MessagePopup
          message={Constant.UserErrorMessage.AssignmentCreateError}
          togglePopup={toggleErrorMessagePopup}
        />
      )}
      <div className="px-4 sm:px-6  lg:px-8 py-6">
        {!canActivityUpdate() && (
          <div className="flex bg-yellow-50 m-2">
            <p>
              Content edits are not available after students have started the
              assignment.
            </p>
          </div>
        )}

        <div className="relative">
          <InfoMessage
            message="Create a name for your assignment and select a
              subject. Choose 1 or more activities from the options below for
              your assignment. You will have the ability to narrow your content
              down by Domain and Standard within individual activities."
          />
        </div>
        <form onSubmit={handleSubmit(handleSaveAssignemnt)}>
          <div className="grid grid-cols-1 sm:grid-cols-12 gap-6 border-b pb-3">
            <div className="col-span-6 lg:col-span-4 xl:col-span-3">
              <label className="block text-sm text-gray-500 mb-1">
                Assignment Name
              </label>
              <input
                type="text"
                name="Assignment-name"
                id="Assignment-name"
                autoComplete="off"
                value={assignmentName}
                onChange={(e) => handleAssignmentNameChange(e)}
                className="shadow-sm focus:ring-primary-violet focus:border-primary-violet w-full border border-gray-300 py-1.5 px-3 rounded-sm"
              />
            </div>
            <div className="col-span-6 lg:col-span-4 xl:col-span-3">
              <div>
                <label className="block text-sm text-gray-500 mb-1">
                  Subject
                </label>
                <Select
                  value={findSubjectByValue()}
                  formatGroupLabel={(data) => {
                    return (
                      <div
                        className="bg-gray-200 -mx-3"
                        style={Constant.SubjectDDLCSS.groupStyles}
                      >
                        <span className="text-secondary-teal pl-2">
                          {data.label}
                        </span>

                        <span style={Constant.SubjectDDLCSS.groupBadgeStyles()}>
                          {data.options.length}
                        </span>
                      </div>
                    );
                  }}
                  options={subjects}
                  isSearchable={true}
                  isDisabled={!canActivityUpdate()}
                  onChange={handleSubjectChange}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: "#008fbb",
                    },
                  })}
                  styles={colourStyles}
                />
                <small className="text-red-400">
                  {errors.subject && <span>This field is required</span>}
                </small>
              </div>
            </div>
            <div className="col-span-6 lg:col-span-4 xl:col-span-3">
              <div>
                <label className="block text-sm text-gray-500 mb-1">
                  School Year
                </label>
                <Select
                  key="schoolyear"
                  id="schoolyear"
                  name="schoolyear"
                  value={selectedSchoolYearId}
                  isDisabled={!canActivityUpdate()}
                  onChange={handleSchoolYearChange}
                  options={schoolYears}
                />
              </div>
            </div>
            <div className="hidden lg:flex col-span-6 lg:col-span-4 xl:col-span-3 flex items-end justify-end sm:justify-end mb-1">
              <button
                type="submit"
                className="bg-primary-violet border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-dark-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Save and Continue
              </button>
            </div>
          </div>
        </form>
        <label className="block text-gray-700 mt-4 text-lg">Activities</label>
        <div className=" mx-auto lg:grid lg:grid-cols-12 gap-0 xxxl:gap-8 mt-4">
          <div
            className={`min-w-0 block lg:col-span-3 xl:col-span-2 sm:mx-0 mb-4 ${
              !canShowOptions(
                selectedSubject.value,
                selectedSchoolYearId?.value
              )
                ? " pointer-events-none opacity-50"
                : ""
            } `}
          >
            <nav aria-label="Progress">
              <ol className="overflow-hidden">
                <li
                  className="relative pb-5"
                  onClick={() =>
                    handleTabChange(Constant.AssignmentPart.ASSESSMENT)
                  }
                >
                  <a
                    href="#!"
                    className={`relative flex items-center group ${
                      assignmentPart === Constant.AssignmentPart.ASSESSMENT
                        ? " border-2 border-primary-violet"
                        : "border border-gray-300"
                    }  mr-5 min-h-[70px]`}
                    x-description="Complete Step"
                  >
                    <span
                      className="h-9 flex items-center  absolute -right-4"
                      aria-hidden="true"
                    >
                      <span
                        className={`relative z-10 w-8 h-8 flex items-center justify-center bg-white ${
                          assignmentPart === Constant.AssignmentPart.ASSESSMENT
                            ? " border-2 border-primary-violet text-primary-violet"
                            : " border border-gray-300"
                        }  rounded-full`}
                      >
                        {assignmentPart ===
                          Constant.AssignmentPart.ASSESSMENT && (
                          <ForwardArrowIcon />
                        )}
                      </span>
                    </span>
                    <span
                      className={`ml-3 pr-4 min-w-0 flex flex-col ${
                        assignmentPart === Constant.AssignmentPart.ASSESSMENT
                          ? " text-primary-violet"
                          : "text-gray-500"
                      }`}
                    >
                      <span className="text-xs font-semibold tracking-wide uppercase">
                        Assessments
                      </span>
                    </span>
                  </a>
                </li>
                {!upgrade && (
                  <>
                    <li
                      className="relative pb-5"
                      onClick={() =>
                        handleTabChange(Constant.AssignmentPart.PREBUILTTESTS)
                      }
                    >
                      <a
                        href="#!"
                        className={`relative flex items-center group ${
                          assignmentPart ===
                          Constant.AssignmentPart.PREBUILTTESTS
                            ? " border-2 border-primary-violet"
                            : "border border-gray-300"
                        }  mr-5 min-h-[70px]`}
                        x-description="Complete Step"
                      >
                        <span
                          className="h-9 flex items-center  absolute -right-4"
                          aria-hidden="true"
                        >
                          <span
                            className={`relative z-10 w-8 h-8 flex items-center justify-center bg-white ${
                              assignmentPart ===
                              Constant.AssignmentPart.PREBUILTTESTS
                                ? " border-2 border-primary-violet text-primary-violet"
                                : " border border-gray-300"
                            }  rounded-full`}
                          >
                            {assignmentPart ===
                              Constant.AssignmentPart.PREBUILTTESTS && (
                              <ForwardArrowIcon />
                            )}
                          </span>
                        </span>
                        <span
                          className={`ml-3 pr-4 min-w-0 flex flex-col ${
                            assignmentPart ===
                            Constant.AssignmentPart.PREBUILTTESTS
                              ? " text-primary-violet"
                              : "text-gray-500"
                          }`}
                        >
                          <span className="text-xs font-semibold tracking-wide uppercase">
                            Summative and Domain Practice Tests
                          </span>
                        </span>
                      </a>
                    </li>
                    <li
                      className="relative pb-5"
                      onClick={() =>
                        handleTabChange(
                          Constant.AssignmentPart.PRACTICEQUESTIONS
                        )
                      }
                    >
                      <a
                        href="#!"
                        className={`relative flex items-center group ${
                          assignmentPart ===
                          Constant.AssignmentPart.PRACTICEQUESTIONS
                            ? " border-2 border-primary-violet"
                            : "border border-gray-300"
                        }  mr-5 min-h-[70px]`}
                        x-description="Complete Step"
                      >
                        <span
                          className="h-9 flex items-center  absolute -right-4"
                          aria-hidden="true"
                        >
                          <span
                            className={`relative z-10 w-8 h-8 flex items-center justify-center bg-white ${
                              assignmentPart ===
                              Constant.AssignmentPart.PRACTICEQUESTIONS
                                ? " border-2 border-primary-violet text-primary-violet"
                                : " border border-gray-300"
                            }  rounded-full`}
                          >
                            {assignmentPart ===
                              Constant.AssignmentPart.PRACTICEQUESTIONS && (
                              <ForwardArrowIcon />
                            )}
                          </span>
                        </span>
                        <span
                          className={`ml-3 pr-4 min-w-0 flex flex-col ${
                            assignmentPart ===
                            Constant.AssignmentPart.PRACTICEQUESTIONS
                              ? " text-primary-violet"
                              : "text-gray-500"
                          }`}
                        >
                          <span className="text-xs font-semibold tracking-wide uppercase">
                            Practice Questions
                          </span>
                        </span>
                      </a>
                    </li>
                    <li
                      className="relative pb-5"
                      onClick={() =>
                        handleTabChange(Constant.AssignmentPart.VIDEOLESSON)
                      }
                    >
                      <a
                        href="#!"
                        className={`relative flex items-center group ${
                          assignmentPart === Constant.AssignmentPart.VIDEOLESSON
                            ? " border-2 border-primary-violet"
                            : "border border-gray-300"
                        }  mr-5 min-h-[70px]`}
                        x-description="Complete Step"
                      >
                        <span
                          className="h-9 flex items-center  absolute -right-4"
                          aria-hidden="true"
                        >
                          <span
                            className={`relative z-10 w-8 h-8 flex items-center justify-center bg-white ${
                              assignmentPart ===
                              Constant.AssignmentPart.VIDEOLESSON
                                ? " border-2 border-primary-violet text-primary-violet"
                                : " border border-gray-300"
                            }  rounded-full`}
                          >
                            {assignmentPart ===
                              Constant.AssignmentPart.VIDEOLESSON && (
                              <ForwardArrowIcon />
                            )}
                          </span>
                        </span>
                        <span
                          className={`ml-3 pr-4 min-w-0 flex flex-col ${
                            assignmentPart ===
                            Constant.AssignmentPart.VIDEOLESSON
                              ? " text-primary-violet"
                              : "text-gray-500"
                          }`}
                        >
                          <span className="text-xs font-semibold tracking-wide uppercase">
                            Video Lessons
                          </span>
                        </span>
                      </a>
                    </li>
                    <li
                      className="relative pb-5"
                      onClick={() =>
                        handleTabChange(
                          Constant.AssignmentPart.CONSTRUCTEDRESPONSE
                        )
                      }
                    >
                      <a
                        href="#!"
                        className={`relative flex items-center group ${
                          assignmentPart ===
                          Constant.AssignmentPart.CONSTRUCTEDRESPONSE
                            ? " border-2 border-primary-violet"
                            : "border border-gray-300"
                        }  mr-5 min-h-[70px]`}
                        x-description="Complete Step"
                      >
                        <span
                          className="h-9 flex items-center  absolute -right-4"
                          aria-hidden="true"
                        >
                          <span
                            className={`relative z-10 w-8 h-8 flex items-center justify-center bg-white ${
                              assignmentPart ===
                              Constant.AssignmentPart.CONSTRUCTEDRESPONSE
                                ? " border-2 border-primary-violet text-primary-violet"
                                : " border border-gray-300"
                            }  rounded-full`}
                          >
                            {assignmentPart ===
                              Constant.AssignmentPart.CONSTRUCTEDRESPONSE && (
                              <ForwardArrowIcon />
                            )}
                          </span>
                        </span>
                        <span
                          className={`ml-3 pr-4 min-w-0 flex flex-col ${
                            assignmentPart ===
                            Constant.AssignmentPart.CONSTRUCTEDRESPONSE
                              ? " text-primary-violet"
                              : "text-gray-500"
                          }`}
                        >
                          <span className="text-xs font-semibold tracking-wide uppercase">
                            Constructed Response
                          </span>
                        </span>
                      </a>
                    </li>
                  </>
                )}
                {upgrade && (
                  <>
                    <li
                      className="opacity-50 relative pb-5"
                      onClick={() => setShowUpgradePopup(true)}
                    >
                      <a
                        href="#!"
                        className={`relative flex items-center group ${
                          assignmentPart ===
                          Constant.AssignmentPart.PREBUILTTESTS
                            ? " border-2 border-primary-violet"
                            : "border border-gray-300"
                        }  mr-5 min-h-[70px]`}
                        x-description="Complete Step"
                      >
                        <span
                          className="h-9 flex items-center  absolute -right-4"
                          aria-hidden="true"
                        >
                          <span
                            className={`relative z-10 w-8 h-8 flex items-center justify-center bg-white ${
                              assignmentPart ===
                              Constant.AssignmentPart.PREBUILTTESTS
                                ? " border-2 border-primary-violet text-primary-violet"
                                : " border border-gray-300"
                            }  rounded-full`}
                          >
                            {assignmentPart ===
                              Constant.AssignmentPart.PREBUILTTESTS && (
                              <ForwardArrowIcon />
                            )}
                          </span>
                        </span>
                        <span
                          className={`ml-3 pr-4 min-w-0 flex flex-col ${
                            assignmentPart ===
                            Constant.AssignmentPart.PREBUILTTESTS
                              ? " text-primary-violet"
                              : "text-gray-500"
                          }`}
                        >
                          <span className="text-xs font-semibold tracking-wide uppercase">
                            Summative and Domain Practice Tests
                          </span>
                        </span>
                      </a>
                    </li>
                    <li
                      className="opacity-50 relative pb-5"
                      onClick={() => setShowUpgradePopup(true)}
                    >
                      <a
                        href="#!"
                        className={`relative flex items-center group ${
                          assignmentPart ===
                          Constant.AssignmentPart.PRACTICEQUESTIONS
                            ? " border-2 border-primary-violet"
                            : "border border-gray-300"
                        }  mr-5 min-h-[70px]`}
                        x-description="Complete Step"
                      >
                        <span
                          className="h-9 flex items-center  absolute -right-4"
                          aria-hidden="true"
                        >
                          <span
                            className={`relative z-10 w-8 h-8 flex items-center justify-center bg-white ${
                              assignmentPart ===
                              Constant.AssignmentPart.PRACTICEQUESTIONS
                                ? " border-2 border-primary-violet text-primary-violet"
                                : " border border-gray-300"
                            }  rounded-full`}
                          >
                            {assignmentPart ===
                              Constant.AssignmentPart.PRACTICEQUESTIONS && (
                              <ForwardArrowIcon />
                            )}
                          </span>
                        </span>
                        <span
                          className={`ml-3 pr-4 min-w-0 flex flex-col ${
                            assignmentPart ===
                            Constant.AssignmentPart.PRACTICEQUESTIONS
                              ? " text-primary-violet"
                              : "text-gray-500"
                          }`}
                        >
                          <span className="text-xs font-semibold tracking-wide uppercase">
                            Practice Questions
                          </span>
                        </span>
                      </a>
                    </li>
                    <li
                      className="opacity-50 relative pb-5"
                      onClick={() => setShowUpgradePopup(true)}
                    >
                      <a
                        href="#!"
                        className={`relative flex items-center group ${
                          assignmentPart === Constant.AssignmentPart.VIDEOLESSON
                            ? " border-2 border-primary-violet"
                            : "border border-gray-300"
                        }  mr-5 min-h-[70px]`}
                        x-description="Complete Step"
                      >
                        <span
                          className="h-9 flex items-center  absolute -right-4"
                          aria-hidden="true"
                        >
                          <span
                            className={`relative z-10 w-8 h-8 flex items-center justify-center bg-white ${
                              assignmentPart ===
                              Constant.AssignmentPart.VIDEOLESSON
                                ? " border-2 border-primary-violet text-primary-violet"
                                : " border border-gray-300"
                            }  rounded-full`}
                          >
                            {assignmentPart ===
                              Constant.AssignmentPart.VIDEOLESSON && (
                              <ForwardArrowIcon />
                            )}
                          </span>
                        </span>
                        <span
                          className={`ml-3 pr-4 min-w-0 flex flex-col ${
                            assignmentPart ===
                            Constant.AssignmentPart.VIDEOLESSON
                              ? " text-primary-violet"
                              : "text-gray-500"
                          }`}
                        >
                          <span className="text-xs font-semibold tracking-wide uppercase">
                            Video Lessons
                          </span>
                        </span>
                      </a>
                    </li>
                    <li
                      className="opacity-50 relative pb-5"
                      onClick={() => setShowUpgradePopup(true)}
                    >
                      <a
                        href="#!"
                        className={`relative flex items-center group ${
                          assignmentPart ===
                          Constant.AssignmentPart.CONSTRUCTEDRESPONSE
                            ? " border-2 border-primary-violet"
                            : "border border-gray-300"
                        }  mr-5 min-h-[70px]`}
                        x-description="Complete Step"
                      >
                        <span
                          className="h-9 flex items-center  absolute -right-4"
                          aria-hidden="true"
                        >
                          <span
                            className={`relative z-10 w-8 h-8 flex items-center justify-center bg-white ${
                              assignmentPart ===
                              Constant.AssignmentPart.CONSTRUCTEDRESPONSE
                                ? " border-2 border-primary-violet text-primary-violet"
                                : " border border-gray-300"
                            }  rounded-full`}
                          >
                            {assignmentPart ===
                              Constant.AssignmentPart.CONSTRUCTEDRESPONSE && (
                              <ForwardArrowIcon />
                            )}
                          </span>
                        </span>
                        <span
                          className={`ml-3 pr-4 min-w-0 flex flex-col ${
                            assignmentPart ===
                            Constant.AssignmentPart.CONSTRUCTEDRESPONSE
                              ? " text-primary-violet"
                              : "text-gray-500"
                          }`}
                        >
                          <span className="text-xs font-semibold tracking-wide uppercase">
                            Constructed Response
                          </span>
                        </span>
                      </a>
                    </li>
                  </>
                )}
              </ol>
            </nav>
          </div>
          {assignmentPart === Constant.AssignmentPart.ASSESSMENT && (
            <Assessment
              key={assessmentKey}
              userId={userId}
              assignment={assignment}
              updateAssignment={updateAssignment}
              assessmentId={assessmentId}
              assessmentSubjectId={assessmentSubjectId}
              isActivityUpdated={setActivityUpdated}
              canActivityUpdate={canActivityUpdate()}
              roleId={props.roleId}
              profile={props.profile}
            />
          )}
          {assignmentPart === Constant.AssignmentPart.PREBUILTTESTS && (
            <PrebuildTest
              assignment={assignment}
              updateAssignment={updateAssignment}
              isActivityUpdated={setActivityUpdated}
              canActivityUpdate={canActivityUpdate()}
              allowViewPreBuiltTests={canAllowViewPreBuiltTests()}
            />
          )}
          {assignmentPart === Constant.AssignmentPart.PRACTICEQUESTIONS && (
            <PracticeQuestions
              assignment={assignment}
              updateAssignment={updateAssignment}
              isActivityUpdated={setActivityUpdated}
              canActivityUpdate={canActivityUpdate()}
            />
          )}
          {assignmentPart === Constant.AssignmentPart.VIDEOLESSON && (
            <VideoLesson
              assignment={assignment}
              updateAssignment={updateAssignment}
              isActivityUpdated={setActivityUpdated}
              canActivityUpdate={canActivityUpdate()}
            />
          )}
          {assignmentPart === Constant.AssignmentPart.CONSTRUCTEDRESPONSE && (
            <ConstructedResponse
              userId={userId}
              assignment={assignment}
              updateAssignment={updateAssignment}
              isActivityUpdated={setActivityUpdated}
              canActivityUpdate={canActivityUpdate()}
            />
          )}
        </div>
      </div>
    </Fragment>
  );
}

export default Content;

interface StudentReportCardButtonsProps {
  resetFilters: () => void,
  isRunReportEnabled: boolean,
  runReport: () => void,
  isPrintReportEnabled: boolean,
  printReport: () => void
}

function StudentReportCardButtons(props: StudentReportCardButtonsProps) {

  const {resetFilters, runReport, printReport, isRunReportEnabled, isPrintReportEnabled} = props;

  const commonButtonClassNames = "bg-primary-violet border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition ease-in-out duration-500 ";
  const enabledButtonClassName = commonButtonClassNames + "hover:bg-dark-violet hover:shadow-lg";
  const disabledButtonClassName = commonButtonClassNames + "bg-opacity-20 text-primary-violet text-opacity-40 pointer-events-none";

  return (
    <div className="flex gap-4 justify-end">
      <button
        className="bg-transparent border my-auto border-primary-violet shadow-sm py-2 px-4 flex justify-center text-sm font-medium text-primary-violet hover:text-white hover:bg-light-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3 transition ease-in-out duration-500"
        onClick={resetFilters}
      >
        Reset
      </button>
      <div className="cursor-not-allowed">
        <button
          className={isRunReportEnabled ? enabledButtonClassName : disabledButtonClassName}
          onClick={runReport}
        >
          Run Report
        </button>
      </div>
      <div className="cursor-not-allowe">
        <button
          className={isPrintReportEnabled ? enabledButtonClassName : disabledButtonClassName}
          onClick={printReport}
        >
          Print
        </button>
      </div>
    </div>
  )
}

export default StudentReportCardButtons

import { Menu, Transition } from "@headlessui/react";
import { Modal, ModalBody, ModalHeader } from "@windmill/react-ui";
import classNames from "classnames";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
  CanvasIcon,
  ChatIcon,
  DownArrowIcon,
  GoogleClassroomIcon, MessageOpenIcon,
  OutlineLogoutIcon,
  OutlinePersonIcon,
} from "../../../assets/icons/index";
import { FirebaseConfiguration } from "../../../environment/setup";
import * as userContextAction from "../../../redux/actions/userContextAction";
import ProfileModel from "../../../model/users/profile";
import auth from "../../../utils/auth";
import constant from "../../../utils/constant/constant";
import RouteConstant from "../../../utils/constant/routeConstant";
import JoinClassPopUp from "../../../component/screen/student/shared/popups/joinClassPopup";
import UserTile from "../../shared/UserTile";
import { MessageApi } from "../../../api/message/messageApi";
import { CalendarIcon, SupportIcon } from "@heroicons/react/outline";
import ChatRoom from "../../screen/chat/chatRoom";
import { isActiveCanvasExternalRoster } from "../../../utils/helper";
import UpgradeButtonControl from "../customButtonControl";
export interface ProfileProps {
  name: string;
  src: string;
  recentChat: any;
  className?: string;
  userContext: userContextAction.UserContextState;
  profile?: ProfileModel;
  onContactUsClicked?: Function;
  showContactUs: boolean;
}

function Profile(props: ProfileProps) {
  const history = useHistory();

  const [showMenu, toggleMenu] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [url] = useState("");
  const [isOpneChat, SetIsOpenChat] = useState(false);
  const [recentMessageCount, setRecentMessageCount] = useState(0);
  const [openJoinClassPopUp, setOpenJoinClassPopUp] = useState(false);
  const [onlineUsers, setOnlineUsers] = useState([]);

  const [chatRoomProps, setChatProps] = useState<any>({});
  const isUpgradeRequired =
    (props?.userContext.roleId == constant.UserRoleId.Student &&
      props.profile?.isUpgradeRequired) ||
    constant.RolesRequireUpgrade.includes(props?.userContext.roleId ?? 0);

  function getOnlineUsers() {
    MessageApi.getOnlineUsersRef(props?.userContext?.schoolId!).on(
      "value",
      (snapshot) => {
        if (snapshot.val()) {
          setOnlineUsers(snapshot.val());
        } else {
          setOnlineUsers([]);
        }
      }
    );
  }

  useEffect(() => {
    if (props.name) {
      setChatProps({
        userId: props.userContext?.userId,
        configuration: FirebaseConfiguration,
        messagecollectionName: "Messages",
        recentChatcollectionName: "RecentChat",
        presenceCollection: props.userContext?.schoolId + "/online-users",
        roleId: props.userContext?.roleId,
        districtId: props.userContext?.districtId,
        token: auth.getAuthToken(),
        impersonatedUser: props.userContext.impersonatedUser,
        displayName: props.name,
        schoolId: props.userContext?.schoolId,
        closeChatWindow(): void {
          closeChatWindow();
        },
        onSettingsClicked(): void {
          onSettingsClicked();
        },
        redirectToClassDetails(classId: number): void {
          redirectToClassDetails(classId);
        },
      });
    }
  }, [props.name]);

  useEffect(() => {
    getOnlineUsers();
  }, []);

  useEffect(() => {
    setMessageCount();
  }, [props.recentChat]);

  const setMessageCount = () => {
    const count =
      props.recentChat &&
      props.recentChat.length > 0 &&
      props.recentChat[0].chatTo &&
      props.recentChat[0].chatTo
        .map((c) => {
          return c.recentMassageCount;
        })
        .reduce((a, b) => a + b);
    setRecentMessageCount(count);
  };

  const closeChatWindow = () => {
    SetIsOpenChat(false);
  };

  const onSettingsClicked = () => {
    history.push({
      pathname: RouteConstant.UserProfile,
      state: {
        tabSelected: "SETTING",
      },
    });
  };

  const redirectToClassDetails = (classId: number) => {
    history.push({
      pathname: classId
        ? RouteConstant.TeacherRoutes.ClassesDetails.replace(
            ":classId",
            classId.toString()
          )
        : RouteConstant.TeacherRoutes.Classes,
    });
  };

  /* function handleToggle() {
    toggleMenu(!showMenu);
  } */

  function openProfileModal() {
    history.push({
      pathname:
        props.userContext.roleId === constant.UserRoleId.Student
          ? RouteConstant.MiddleSchool.Profile
          : RouteConstant.UserProfile,
    });

    toggleMenu(false);
  }
  const onJoinClassCancelClick = () => {
    setOpenJoinClassPopUp(false);
  };
  const onJoinClassOkClick = () => {
    setOpenJoinClassPopUp(false);
  };

  // function openModal(name: string) {
  //   let currentUrl = "";
  //   switch (name) {
  //     case "Account":
  //       currentUrl = `${Configuration.EGBaseUrl}teacher/account.aspx`;
  //       break;
  //     case "SchoolInfo":
  //       currentUrl = `${Configuration.EGBaseUrl}teacher/schoolinformation.aspx`;
  //       break;
  //     case "Settings":
  //       currentUrl = `${Configuration.EGBaseUrl}teacher/settings.aspx`;
  //       break;
  //     case "ChangePassword":
  //       currentUrl = `${Configuration.EGBaseUrl}changepassword.aspx`;
  //       break;
  //     default:
  //       currentUrl = `${Configuration.EGBaseUrl}teacher/account.aspx`;
  //       break;
  //   }
  //   setUrl(currentUrl + "?dr=true");
  //   setIsModalOpen(true);
  //   toggleMenu(false);
  // }

  function closeModal() {
    setIsModalOpen(false);
  }

  function logOut() {
    toggleMenu(!showMenu);
    history.push(RouteConstant.ROUTE_LOGIN, { logoutClicked: true });
  }

  function getClassName(roleId: number) {
    const baseClassName =
      " sm:w-auto flex text-left items-center text-sm lg:text-sm focus:outline-none text-gray-700 md:inline-flex";
    const buttonClass =
      roleId.toString() === constant.UserRoleId.District.toString()
        ? "bg-yellow-500 hover:bg-yellow-400"
        : "";
    return classNames(baseClassName, buttonClass);
  }

  function showStudentMessaging() {
    if (props.userContext.roleId !== constant.UserRoleId.District) {
      return true;
    }
    return false;
  }
  const { t } = useTranslation();

  return (
    <Fragment>
      {openJoinClassPopUp && (
        <JoinClassPopUp
          userContext={props?.userContext!}
          profile={props?.profile!}
          open={openJoinClassPopUp}
          onCancelClick={onJoinClassCancelClick}
          onOkClick={onJoinClassOkClick}
        ></JoinClassPopUp>
      )}

      <div className="flex space-x-2 sm:space-x-3">
        <div className="flex">
          <Menu as="div" className="relative flex items-center justify-center">
            <Menu.Button className="bg-transparent inline-flex items-start items-center justify-center text-gray-400 rounded-full hover:text-gray-500">
              <span title="Support">
                <SupportIcon className="h-5 w-5 sm:mr-2" />
              </span>
              <div className={`group hover:text-gray-500`}>
                <div className="group-hover:text-gray-500 text-gray-700 hidden sm:inline-block">
                  {t("Shared.Profile.Help")}
                </div>
              </div>
              <DownArrowIcon
                className="sm:ml-1 w-5 h-5 hover:text-gray-700 text-gray-500"
                aria-hidden="true"
              />
            </Menu.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="right-0 absolute top-5 w-[22rem] mt-4 rounded-md shadow-lg z-30 bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none">
                <div className="py-1">
                  {props.showContactUs && (
                      <Menu.Item>
                        {({ active }) => (
                            <a
                                onClick={() => props.onContactUsClicked?.()}
                                className={classNames(
                                    active
                                        ? "bg-gray-100 text-gray-900"
                                        : "text-gray-700",
                                    "group flex items-center px-4 py-2 text-sm"
                                )}
                            >
                              <MessageOpenIcon
                                  className="w-4 h-4 mr-3"
                                  aria-hidden="true"
                              />
                              Contact Us
                            </a>
                        )}
                      </Menu.Item>
                  )}
                  <Menu.Item>
                    {({ active }) => (
                      <a
                        href="https://help.progresslearning.com/"
                        target="_bank"
                        className={classNames(
                          active
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-700",
                          "group flex items-center px-4 py-2 text-sm"
                        )}
                      >
                        <SupportIcon className="w-4 h-4 mr-3" />
                        {t("Shared.Profile.HelpCenter")}
                      </a>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <a
                        href="https://progresslearning.com/about-us/training/"
                        target="_bank"
                        className={classNames(
                          active
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-700",
                          "group flex items-center px-4 py-2 text-sm"
                        )}
                      >
                        <CalendarIcon
                          className="w-4 h-4 mr-3"
                          aria-hidden="true"
                        />
                        {t("Shared.Profile.TrainingProfessionalDevelopment")}
                      </a>
                    )}
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
        <span className="hidden h-full sm:inline-block border-r border-gray-300"></span>
        <div className="flex justify-end space-x-2 sm:space-x-3">
          {showStudentMessaging() && (
              <>
                <UpgradeButtonControl
                    controlType={"div"}
                    userContext={props?.userContext}
                    isUpgradeRequired={isUpgradeRequired}
                    isRoleCheck={true}
                    className={`group hover:text-gray-500 cursor-pointer ${
                        props.userContext.roleId === constant.UserRoleId.District
                            ? " sm:w-auto flex text-left items-center text-sm lg:text-sm focus:outline-none  md:inline-flex"
                    : getClassName(props.userContext.roleId)
                } ${
                  isUpgradeRequired
                    ? " text-gray-300 hover:text-gray-300 cursor-not-allowed "
                    : ""
                }`}
                onClick={() => {
                  SetIsOpenChat(true);
                }}
              >
                <span className="relative">
                  {recentMessageCount > 0 && (
                    <>
                      <span className=" animate-ping w-4 h-4 inline-block absolute -top-2 -left-1 flex items-center justify-center   rounded-full bg-yellow-500 "></span>
                      <span className="w-4 h-4 inline-block absolute -top-2 -left-1 flex items-center justify-center text-[0.6rem] rounded-full bg-yellow-500 text-white font-semibold">
                        {recentMessageCount}
                      </span>
                    </>
                  )}
                  <ChatIcon
                    aria-hidden="true"
                    className="flex-none group-hover:text-gray-700 w-5 h-5 sm:ml-0 sm:mr-2 text-gray-500"
                    title="Student Messaging"
                  ></ChatIcon>
                </span>
                <span className="hidden sm:inline-block">
                  {t("Shared.StudentHeader.StudentMessaging")}
                </span>
              </UpgradeButtonControl>
              <span className="h-full inline-block border-r border-gray-300"></span>
            </>
          )}

          <div className="flex pr-2">
            <Menu
              as="div"
              className="relative flex items-start sm:items-center justify-center"
            >
              <Menu.Button className="bg-transparent inline-flex items-start items-center justify-center text-gray-400 rounded-full hover:text-gray-500">
                <span className="sr-only">Open options</span>
                <div
                  // onClick={handleToggle}
                  className={`group hover:text-gray-500 ${
                    props.userContext.roleId === constant.UserRoleId.District
                      ? " sm:w-auto flex text-left items-center text-sm lg:text-sm focus:outline-none  md:inline-flex"
                      : getClassName(props.userContext.roleId)
                  }`}
                >
                  {/* {props.src && (
                <Avatar
                  size="large"
                  src={props.src}
                  alt="Profile"
                  className="mr-2"
                />
              )} */}
                  <UserTile
                    userId={props.userContext.userId}
                    onlineUsers={onlineUsers}
                    isLoggedInUser={true}
                  />
                  <div className="hidden sm:block">
                    <span className="hidden sm:inline-block ml-3 break-all">
                      {props.name}
                      {props.profile &&
                        props.userContext.roleId ===
                          constant.UserRoleId.Student && (
                          <div className="text-center bg-primary-violet text-white rounded-md text-xs px-1.5 py-0.5">
                            {props.profile.gradeId -
                              1 +
                              "th " +
                              t("Shared.StudentHeader.Grade")}
                          </div>
                        )}
                    </span>
                    {props.userContext.roleId ===
                      constant.UserRoleId.District && (
                      <p className="rounded-md bg-[#FCD34D] text-black text-[8px] shadow-sm py-0.5 px-3 uppercase ml-2 mr-2">
                        District Administrator
                      </p>
                    )}
                    {props.userContext.roleId ===
                      constant.UserRoleId.SchoolAdmin && (
                      <p className="rounded-md bg-[#0ea5e9] text-white text-[8px] shadow-sm py-0.5 px-3 uppercase ml-2 mr-2">
                        School Administrator
                      </p>
                    )}
                    {props.userContext.roleId ===
                      constant.UserRoleId.SchoolTeacher && (
                      <p className="rounded-md bg-[#A3E635] text-black text-[8px] shadow-sm py-0.5 px-3 uppercase ml-2 mr-2">
                        Teacher
                      </p>
                    )}
                  </div>
                  {props?.userContext?.activeExternalRoster === "GOOGLE" &&
                    props?.profile?.gmailId && (
                      <a
                        href="#!"
                        className="no-underline md:text-gray-700 flex items-center hover:text-black transition-all text-xs lg:text-sm md:pl-3 py-3 md:py-5"
                      >
                        <GoogleClassroomIcon
                          className="h-6 mr-2"
                          title="Google Classroom"
                        ></GoogleClassroomIcon>
                        <span className=" block md:hidden">
                          Google Classroom
                        </span>
                      </a>
                    )}
                  {isActiveCanvasExternalRoster(props?.userContext) &&
                    props?.profile?.canvasLoginId && (
                      <a
                        href="#!"
                        className="no-underline md:text-gray-700 flex items-center hover:text-black transition-all text-xs lg:text-sm md:pl-3 py-3 md:py-5"
                      >
                        <CanvasIcon
                          className="h-6 mr-2"
                          title="Canvas"
                        ></CanvasIcon>
                        <span className=" block md:hidden">Canvas</span>
                      </a>
                    )}
                </div>
                <DownArrowIcon className="w-5 h-5" aria-hidden="true" />
              </Menu.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="right-0 absolute top-5 w-48 mt-4 rounded-md shadow-lg z-30 bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none">
                  <div className="py-1">
                    {props.userContext?.impersonatedUser === null && (
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href="#!"
                            onClick={openProfileModal}
                            className={classNames(
                              active
                                ? "bg-gray-100 text-gray-900"
                                : "text-gray-700",
                              "group flex items-center px-4 py-2 text-sm"
                            )}
                          >
                            <OutlinePersonIcon
                              className="w-4 h-4 mr-3"
                              aria-hidden="true"
                            />
                            {t("Shared.Profile.Profile")}
                          </a>
                        )}
                      </Menu.Item>
                    )}
                    {props.userContext.roleId ===
                      constant.UserRoleId.Student && (
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href="#!"
                            onClick={() => setOpenJoinClassPopUp(true)}
                            className={classNames(
                              active
                                ? "bg-gray-100 text-gray-900"
                                : "text-gray-700",
                              "group flex items-center px-4 py-2 text-sm"
                            )}
                          >
                            <OutlinePersonIcon
                              className="w-4 h-4 mr-3"
                              aria-hidden="true"
                            />
                            {t("JoinClass.JoinClass")}
                          </a>
                        )}
                      </Menu.Item>
                    )}
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          href="#!"
                          onClick={() => logOut()}
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "group flex items-center px-4 py-2 text-sm"
                          )}
                        >
                          <OutlineLogoutIcon
                            className="w-4 h-4 mr-3"
                            aria-hidden="true"
                          />
                          <span>{t("Shared.Profile.Logout")}</span>
                        </a>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>
        <Modal
          isOpen={isModalOpen}
          onClose={closeModal}
          className="sm:max-w-2xl bg-white rounded-lg p-2"
        >
          <ModalHeader></ModalHeader>
          <ModalBody>
            <iframe
              width="650"
              height="350"
              className="pl-5"
              src={url}
              style={{ overflow: "hidden" }}
              title="fram"
            ></iframe>
          </ModalBody>
        </Modal>
        {isOpneChat && <ChatRoom {...chatRoomProps}></ChatRoom>}
      </div>
    </Fragment>
  );
}

export default Profile;

import { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Select } from "@windmill/react-ui";
import { getClassesBySchoolAccountId } from "../../../api/manageUser/class";
import { getAllGrades } from "../../../api/gradeApi";
import Constant from "../../../utils/constant/constant";
import Action from "./action";
import UserSearchQuery from "../../../model/users/userSearchQuery";
import { IGrade } from "../../../model/interface/grade";
import { UserContextState } from "../../../redux/actions/userContextAction";
import constant from "../../../utils/constant/constant";
import { getSchoolByDistrictId } from "../../../api/teacher/school";
import { ISchool } from "../../../model/interface/school";
import { ClassApi } from "../../../api/teacher/classManagementApi";
import ClassSearchDTO from "../../../model/teacher/classManagement/classSearchModel";
import Profile from "../../../model/users/profile";
interface IFilter {
  userContext?: UserContextState;
  profile?: Profile;
  masterData?: any;
  mode: string;
  onHandleSearch: Function;
  handlePrint: Function;
  onHandleShowIsLocked: Function;
  onHandleShowInActive: Function;
  handlePasswordCardModal: Function;
  handleExport: Function;
  clearUserSelection: Function;
}
const Filter = (props: IFilter) => {
  const alphaNumericExpression = new RegExp(/^[a-zA-Z0-9_@.-\s]*$/);
  const [grades, setGrades] = useState<IGrade[]>();
  const [schools, setSchools] = useState<ISchool[]>();
  const [classes, setClasses] = useState<any>();
  const [showIsLocked, setShowIsLocked] = useState(false);
  const [showInActive, setShowInActive] = useState(false);
  const [searchQuery, setSearchQuery] = useState<UserSearchQuery>({
    classId: 0,
    gradeId: 0,
    firstName: "",
    lastName: "",
    studentId: "",
    username: "",
    schoolAccountId: props.userContext?.schoolAccountId ?? 0,
  });

  const onHandleSearch = () => {
    props.onHandleSearch(searchQuery);
  };
  const setSchoolAccountSearch = (schoolAccountId: number) => {
    searchQuery.schoolAccountId = schoolAccountId;
    props.onHandleSearch(searchQuery);
    getClasses(schoolAccountId);
  };
  useEffect(() => {
    getClasses(undefined);
    var gradeResponse = getAllGrades();
    gradeResponse?.then((d) => {
      setGrades(d.data);
    });

    if (props.userContext?.roleId === constant.UserRoleId.District) {
      getSchools();
    } else {
      onHandleSearch();
    }
  }, [false]);

  useEffect(() => {
    if (props.mode !== Constant.ManageUserMode.District) {
      resetFilter();
    }
  }, [props.mode]);

  function getClasses(schoolAccountId: number | undefined) {
    if (props.userContext?.roleId === constant.UserRoleId.SchoolTeacher) {
      getTeacherClasses();
    } else {
      var response = getClassesBySchoolAccountId(
        schoolAccountId ?? props.userContext?.schoolAccountId ?? 0,
        props.profile?.currentSchoolYear.schoolYearId ?? 0
      );
      response?.then((d) => {
        setClasses(d.data);
      });
    }
  }

  function getTeacherClasses() {
    const classSearch: ClassSearchDTO = {
      showArchive: false,
      showCurrent: true,
      showHidden: false,
      userId: props.userContext?.userId ?? 0,
      schoolId: props.userContext?.schoolId ?? 0,
      schoolAccountId: props.userContext?.schoolAccountId ?? 0,
      pageNumber: 1,
      pageSize: 100,
    };
    const response = ClassApi.getClassList(classSearch);
    response?.then((d) => {
      setClasses(d.data.data);
    });
  }

  function getSchools() {
    getSchoolByDistrictId(props.userContext?.districtId ?? 0).then((d) => {
      setSchools(d.data);
      if (d.data !== null && d.data.length >= 1) {
        setSearchQuery({
          ...searchQuery,
          schoolAccountId: d.data[0].schoolAccountId,
        });
        setSchoolAccountSearch(d.data[0].schoolAccountId);
        getClasses(d.data[0].schoolAccountId);
      }
    });
  }

  function resetFilter() {
    const userSearchQuery: UserSearchQuery = {
      classId: 0,
      gradeId: 0,
      firstName: "",
      lastName: "",
      studentId: "",
      username: "",
      schoolAccountId: searchQuery.schoolAccountId,
    };
    setSearchQuery(userSearchQuery);

    setShowIsLocked(false);
    setShowInActive(false);
    props.onHandleShowIsLocked(false);
    props.onHandleShowInActive(false);
    props.onHandleSearch(userSearchQuery, false, true);
    props.clearUserSelection();
  }
  return (
    <Fragment>
      <div className="md:col-span-2 lg:col-span-3">
        <div className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 xl:grid-cols-4 sm:gap-x-8">
          {props.userContext?.roleId === constant.UserRoleId.District &&
            (props.mode === Constant.ManageUserMode.Student ||
              props.mode === Constant.ManageUserMode.Teacher) && (
              <div className="">
                <label className="block mb-1 text-sm text-gray-500">
                  School
                </label>
                <div className="flex">
                  <Select
                    value={searchQuery.schoolAccountId}
                    onChange={(e) => {
                      setSearchQuery({
                        ...searchQuery,
                        schoolAccountId: parseInt(e.target.value),
                      });
                      getClasses(parseInt(e.target.value));
                    }}
                    className="py-2.5 px-4 block text-sm border border-gray-400 
             border-opacity-50 focus:ring-primary-violet focus:border-primary-violet form-select leading-5 p-1 rounded-lg"
                  >
                    {schools?.map((school: ISchool, index) => {
                      return (
                        <Fragment key={index}>
                          <option value={school.schoolAccountId}>
                            {school.name}
                          </option>
                        </Fragment>
                      );
                    })}
                  </Select>
                </div>
              </div>
            )}
          {props.mode === Constant.ManageUserMode.Student && (
            <div className="">
              <label className="block mb-1 text-sm text-gray-500">Grade</label>
              <div className="flex">
                <Select
                  value={searchQuery.gradeId}
                  onChange={(e) => {
                    setSearchQuery({
                      ...searchQuery,
                      gradeId: parseInt(e.target.value),
                    });
                  }}
                  className="py-2.5 px-4 block text-sm border border-gray-400 
              border-opacity-50 focus:ring-primary-violet focus:border-primary-violet form-select leading-5 p-1 rounded-lg"
                >
                  <option value={0}>All Grades</option>
                  {grades?.map((grade: any, index) => {
                    return (
                      <Fragment key={index}>
                        <option value={grade.gradeId}>{grade?.name}</option>
                      </Fragment>
                    );
                  })}
                </Select>
              </div>
            </div>
          )}
          {props.mode === Constant.ManageUserMode.Student && (
            <div className="">
              <label className="block mb-1 text-sm text-gray-500">Class</label>
              <div className="flex">
                <Select
                  value={searchQuery.classId}
                  onChange={(e) => {
                    setSearchQuery({
                      ...searchQuery,
                      classId: parseInt(e.target.value),
                    });
                  }}
                  className="py-2.5 px-4 block text-sm border border-gray-400
              border-opacity-50 focus:ring-primary-violet focus:border-primary-violet form-select leading-5 p-1 rounded-lg"
                >
                  <option value={0}>All Classes</option>
                  {classes?.map((item: any, index) => {
                    return (
                      <Fragment key={index}>
                        <option value={item.classId}>
                          {item?.displayName}
                          {(item.primaryTeacherFirstName ?? "").length > 0
                            ? " - " +
                              item.primaryTeacherFirstName +
                              " " +
                              item.primaryTeacherLastName
                            : ""}
                        </option>
                      </Fragment>
                    );
                  })}
                </Select>
              </div>
            </div>
          )}

          {(props.mode === Constant.ManageUserMode.Student ||
            props.mode === Constant.ManageUserMode.District ||
            props.mode === Constant.ManageUserMode.Teacher) && (
            <div>
              <label
                htmlFor="user_name"
                className="block text-sm text-gray-500"
              >
                Username
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="user_name"
                  id="user_name"
                  maxLength={100}
                  value={searchQuery.username}
                  onChange={(e) => {
                    if (
                      alphaNumericExpression.test(e.target.value.toString())
                    ) {
                      setSearchQuery({
                        ...searchQuery,
                        username: e.target.value,
                      });
                    }
                  }}
                  className="border py-2 px-4 block w-full shadow-sm text-gray-900 focus:ring-primary-violet focus:border-primary-violet
            border border-gray-300 rounded-md"
                />
              </div>
            </div>
          )}

          {props.mode === Constant.ManageUserMode.Student && (
            <div>
              <label
                htmlFor="student_id"
                className="block text-sm  text-gray-500"
              >
                Student Id
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="student_id"
                  id="student_id"
                  value={searchQuery.studentId}
                  maxLength={20}
                  onChange={(e) => {
                    if (
                      alphaNumericExpression.test(e.target.value.toString())
                    ) {
                      setSearchQuery({
                        ...searchQuery,
                        studentId: e.target.value,
                      });
                    }
                  }}
                  className="border py-2 px-4 block w-full shadow-sm text-gray-900 focus:ring-primary-violet focus:border-primary-violet
            border border-gray-300 rounded-md"
                />
              </div>
            </div>
          )}

          {(props.mode === Constant.ManageUserMode.Student ||
            props.mode === Constant.ManageUserMode.District ||
            props.mode === Constant.ManageUserMode.Teacher) && (
            <div>
              <label
                htmlFor="first_name"
                className="block text-sm  text-gray-500"
              >
                First Name
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="first_name"
                  id="first_name"
                  maxLength={60}
                  value={searchQuery.firstName}
                  onChange={(e) => {
                    if (
                      alphaNumericExpression.test(e.target.value.toString())
                    ) {
                      setSearchQuery({
                        ...searchQuery,
                        firstName: e.target.value,
                      });
                    }
                  }}
                  className="border py-2 px-4 block w-full shadow-sm text-gray-900 focus:ring-primary-violet focus:border-primary-violet
            border border-gray-300 rounded-md"
                />
              </div>
            </div>
          )}

          {(props.mode === Constant.ManageUserMode.Student ||
            props.mode === Constant.ManageUserMode.District ||
            props.mode === Constant.ManageUserMode.Teacher) && (
            <div>
              <label
                htmlFor="last_name"
                className="block text-sm  text-gray-500"
              >
                Last Name
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="last_name"
                  id="last_name"
                  maxLength={60}
                  value={searchQuery.lastName}
                  onChange={(e) => {
                    if (
                      alphaNumericExpression.test(e.target.value.toString())
                    ) {
                      setSearchQuery({
                        ...searchQuery,
                        lastName: e.target.value,
                      });
                    }
                  }}
                  className="border py-2 px-4 block w-full shadow-sm text-gray-900 focus:ring-primary-violet focus:border-primary-violet border border-gray-300 rounded-md"
                />
              </div>
            </div>
          )}

          <div className="col-span-2 flex gap-x-4">
            <div className="flex items-end">
              <label className="cursor-pointer flex items-center text-sm text-primary-violet">
                <input
                  type="checkbox"
                  x-model="value"
                  name="privacy_setting"
                  value="Private to Project Members"
                  className="h-4 w-4 mr-2 mt-0.5 cursor-pointer text-blue-600 border-gray-300 focus:ring-blue-500"
                  aria-labelledby="privacy-setting-1-label"
                  aria-describedby="privacy-setting-1-description"
                  checked={showIsLocked}
                  onChange={(e) => {
                    props.onHandleShowIsLocked(e.target.checked);
                    setShowIsLocked(e.target.checked);
                    props.onHandleShowInActive(false);
                    setShowInActive(false);
                  }}
                />
                Show Locked Users Only
              </label>
            </div>

            <div className="flex items-end">
              <label className="cursor-pointer flex items-center text-sm text-primary-violet">
                <input
                  type="checkbox"
                  x-model="value"
                  name="privacy_setting"
                  value="Private to Project Members"
                  className="h-4 w-4 mr-2 mt-0.5 cursor-pointer text-blue-600 border-gray-300 focus:ring-blue-500"
                  aria-labelledby="privacy-setting-1-label"
                  aria-describedby="privacy-setting-1-description"
                  checked={showInActive}
                  onChange={(e) => {
                    props.onHandleShowInActive(e.target.checked);
                    setShowInActive(e.target.checked);
                    props.onHandleShowIsLocked(false);
                    setShowIsLocked(false);
                  }}
                />
                Show Deleted
              </label>
            </div>
          </div>
        </div>

        <Action
          {...props}
          onHandleSearch={onHandleSearch}
          handlePrint={props.handlePrint}
          handlePasswordCardModal={props.handlePasswordCardModal}
          handleExport={props.handleExport}
          handleReset={resetFilter}
        ></Action>
      </div>
    </Fragment>
  );
};
const mapStateToProps = (state: any) => {
  return {
    masterData: state.masterData,
    userContext: state.userContext,
    profile: state.profile,
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(Filter);

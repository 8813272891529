import * as userContextAction from "../../../../src/redux/actions/userContextAction";
import { login } from "../../../api/login.service";
import Constant from "../../../utils/constant/constant";
import RouteConstant from "../../../utils/constant/routeConstant";
import { ILoginState } from "../../../model/googleLogin/loginState";
import { useHistory } from "react-router-dom";
import {
  getAssignmentById,
  getAssignmentStatus,
} from "../../../api/teacher/assignment";
import { getActivityName } from "../../../utils/assignmentHelper";
import { useEffect, useState } from "react";
import { fetchProfile } from "../../../redux/actions/userActions";
import { connect } from "react-redux";
import Loader from "../../shared/loader";
import { toast } from "react-toastify";
import { GoogleApi } from "../../../api/google/googleApi";
import { paramEncode } from "../../../utils/urlHelper";
import InformationDialog from "../../shared/informationDialog";
import {
  handleRedirection,
  validateStudentAssignment,
} from "../../../utils/helper";
import { jwtToken } from "../../shared/tokenHelper";
import auth from "../../../utils/auth";

const ExternalGoogleLogin = (props: any) => {
  const history = useHistory();
  const code = new URLSearchParams(props.location.search).get("code");
  const state = JSON.parse(
    atob(new URLSearchParams(props.location.search).get("state")!)
  ) as ILoginState;
  const accountLoginName = state.AccountLoginName;
  const [InformationPopup, setInformationPopup] = useState<any>();

  useEffect(() => {
    if (code) {
      handleLogin();
    } else {
      // redirect to teacher student login
      history.push(RouteConstant.GOOGLEUSERLOGIN);
    }
  }, []);

  const verifyLoginMode = (roleId: number) => {
    if (roleId === Constant.UserRoleId.Student && !state.IsStudent) {
      // redirect to student
      history.push(RouteConstant.GOOGLESTUDENTLOGIN);
      return false;
    } else if (
      (roleId === Constant.UserRoleId.SchoolTeacher ||
        roleId === Constant.UserRoleId.FreeTeacher ||
        roleId === Constant.UserRoleId.PayTeacher ||
        roleId === Constant.UserRoleId.SchoolAdmin ||
        roleId === Constant.UserRoleId.District) &&
      !state.IsTeacher
    ) {
      // redirect to teacher
      history.push(RouteConstant.GOOGLETEACHERLOGIN);
      return false;
    }

    return true;
  };

  const handleAutoMapping = (gmailId: string, googleId: string) => {
    const username = state.Credentials?.Username!;
    const password = atob(state.Credentials?.PassowrdHash!);
    GoogleApi.addGoogleUser(
      accountLoginName!,
      username,
      password,
      googleId,
      gmailId,
      auth.getGoogleRefreshToken()
    ).then((r) => {
      if (r.data > 0) {
        handleLoginByGmailId(gmailId);
      }
    });
  };

  const handleLogin = () => {
    login(
      "none",
      "none",
      accountLoginName ?? "none",
      0,
      "",
      "",
      Constant.LoginType.GOOGLE,
      code!,
      false
    ).then((response) => {
      const googleData = response.data["googleInfo"];
      const googleInfo = googleData.GoogleInfo;
      const googleToken = googleData.GoogleToken;

      if (!googleData.IsUserMapped) {
        // mapping
        const googleAccessToken = googleToken.access_token;
        const googleRefreshToken = googleToken.refresh_token;
        localStorage.setItem("GoogleAccessToken", googleAccessToken);
        localStorage.setItem("GoogleRefreshToken", googleRefreshToken);

        const gmailId = googleInfo.email;
        const googleId = googleInfo.id;

        if (state.Credentials != null) {
          handleAutoMapping(gmailId, googleId);
        } else {
          const url = RouteConstant.GOOGLEUSERMAPPING.replace(
            ":gmailId",
            encodeURI(btoa(gmailId))
          ).replace(":googleId", encodeURI(btoa(googleId)));

          history.push({
            pathname: url,
          });
        }
      } else {
        const authToken = response.data["access_token"];
        const googleAccessToken = googleToken.access_token;
        const googleRefreshToken = googleToken.refresh_token;
        const user: any = jwtToken(authToken);
        if (
          state.autoMaping &&
          state.Credentials != null &&
          state.ReturnUrl.length > 0 &&
          state.Credentials.Username !== user.userName
        ) {
          toast.error("GmailId is mapped with other user");
          history.push(state.ReturnUrl);
          return;
        }

        localStorage.setItem("AuthToken", authToken);
        localStorage.setItem("GoogleAccessToken", googleAccessToken);
        localStorage.setItem("GoogleRefreshToken", googleRefreshToken);

        const userId = isNaN(user.userId) ? 0 : parseInt(user.userId);
        const roleId = isNaN(user.roleId) ? 0 : parseInt(user.roleId);
        const gradeId = isNaN(user.gradeId) ? 0 : parseInt(user.gradeId);
        const accountId = isNaN(user.accountId) ? 0 : parseInt(user.accountId);
        const districtId = isNaN(user.districtId)
          ? 0
          : parseInt(user.districtId);
        const schoolId =
          isNaN(user.schoolId) || user.schoolId === ""
            ? 0
            : parseInt(user.schoolId);
        const schoolAccountId =
          isNaN(user.schoolAccountId) || user.schoolAccountId === ""
            ? 0
            : parseInt(user.schoolAccountId);
        const stateId = isNaN(user.stateId) ? 0 : parseInt(user.stateId);
        var context: userContextAction.UserContextState = {
          userId: userId,
          roleId: roleId,
          gradeId: gradeId,
          schoolId: schoolId,
          accountId: accountId,
          districtId: districtId,
          stateId: stateId,
          googleId: user.googleId,
          activeExternalRoster: Constant.ExterRosterType.GOOGLE,
          schoolAccountId: schoolAccountId,
          impersonatedUser: null,
          linkedUserId: user.linkedUserId,
        };

        if (verifyLoginMode(roleId)) {
          props.setUserContext(context);
          props.setProfile(userId, roleId, schoolId, 0);
          handleUserRedirection(userId, roleId, gradeId, user.linkedUserId);
        }
      }
    });
  };

  const handleLoginByGmailId = (gmailId: string) => {
    // this should be called only after auto mapping google user
    login(
      "none",
      "none",
      accountLoginName ?? "none",
      0,
      gmailId,
      "",
      Constant.LoginType.GOOGLE,
      "",
      false
    ).then((response) => {
      if (response) {
        const authToken = response.data["access_token"];
        localStorage.setItem("AuthToken", authToken);
        const user: any = jwtToken(authToken);
        const userId = isNaN(user.userId) ? 0 : parseInt(user.userId);
        const roleId = isNaN(user.roleId) ? 0 : parseInt(user.roleId);
        const gradeId = isNaN(user.gradeId) ? 0 : parseInt(user.gradeId);
        const accountId = isNaN(user.accountId) ? 0 : parseInt(user.accountId);
        const districtId = isNaN(user.districtId)
          ? 0
          : parseInt(user.districtId);
        const schoolId =
          isNaN(user.schoolId) || user.schoolId === ""
            ? 0
            : parseInt(user.schoolId);
        const schoolAccountId =
          isNaN(user.schoolAccountId) || user.schoolAccountId === ""
            ? 0
            : parseInt(user.schoolAccountId);
        const stateId = isNaN(user.stateId) ? 0 : parseInt(user.stateId);
        var context: userContextAction.UserContextState = {
          userId: userId,
          roleId: roleId,
          gradeId: gradeId,
          schoolId: schoolId,
          accountId: accountId,
          districtId: districtId,
          stateId: stateId,
          googleId: user.googleId,
          activeExternalRoster: Constant.ExterRosterType.GOOGLE,
          schoolAccountId: schoolAccountId,
          impersonatedUser: null,
          linkedUserId: user.linkedUserId,
        };

        props.setUserContext(context);
        props.setProfile(userId, roleId, schoolId, 0);
        handleUserRedirection(userId, roleId, gradeId, user.linkedUserId);
      }
    });
  };

  const handleUserRedirection = (
    userId: number,
    roleId: number,
    gradeId: number = 0,
    linkedUserId: string | null
  ) => {
    localStorage.setItem("programMode", Constant.ProgramMode.EG);
    if (
      roleId === Constant.UserRoleId.SchoolAdmin ||
      roleId === Constant.UserRoleId.PayTeacher ||
      roleId === Constant.UserRoleId.FreeTeacher ||
      roleId === Constant.UserRoleId.SchoolTeacher ||
      roleId === Constant.UserRoleId.District ||
      roleId === Constant.UserRoleId.SuperAdmin ||
      roleId === 0
    ) {
      handleTeacherRedirection(gradeId, roleId, linkedUserId);
    } else if (roleId === Constant.UserRoleId.Student) {
      handleStudentRedirection(userId, gradeId, roleId, linkedUserId);
    } else {
      history.push(RouteConstant.TeacherRoutes.assignmentCenter);
    }
  };

  const handleTeacherRedirection = (
    gradeId: number,
    roleId: number,
    linkedUserId: string | null
  ) => {
    if (state.AssignmentId > 0 && state.AssignmentActivityId > 0) {
      handleTeacherAssignmentRedirection();
    } else {
      if (state.ReturnUrl) {
        history.push(state.ReturnUrl);
      } else {
        handleRedirection(
          roleId,
          gradeId,
          linkedUserId ?? "",
          history,
          Constant.ExterRosterType.GOOGLE
        );
      }
    }
  };

  const handleStudentRedirection = (
    userId: number,
    gradeId: number,
    roleId: number,
    linkedUserId: string | null
  ) => {
    if (state.AssignmentId === 0 && state.AssignmentActivityId === 0) {
      if (state.ReturnUrl) {
        history.push(state.ReturnUrl);
      } else {
        handleRedirection(
          roleId,
          gradeId,
          linkedUserId ?? "",
          history,
          Constant.ExterRosterType.GOOGLE
        );
      }
    } else {
      handleStudentAssignmentRedirection(userId, gradeId, roleId);
    }
  };

  const getAssignmentRoute = (gradeId: number) => {
    if (gradeId <= Constant.Grade.GRADE1) {
      return RouteConstant.KindergartenStudentRoutes.StudentAssignmentActivity;
    } else if (
      gradeId > Constant.Grade.GRADE1 &&
      gradeId < Constant.Grade.GRADE6
    ) {
      return RouteConstant.StudentRoute.StudentAssignmentActivityEL;
    } else if (gradeId >= Constant.Grade.GRADE6) {
      return RouteConstant.MiddleSchool.StudentAssignmentActivityMS;
    } else {
      return RouteConstant.StudentRoute.StudentAssignmentActivityEL;
    }
  };

  const handleStudentAssignmentRedirection = (
    userId: number,
    gradeId: number,
    roleId: number
  ) => {
    getAssignmentStatus(state.AssignmentId, userId).then((r) => {
      if (r.data) {
        const response = validateStudentAssignment(r.data);
        if (response.isSuccess) {
          getAssignmentById(state.AssignmentId).then((r) => {
            const studentRoute = getAssignmentRoute(gradeId);

            const activity = r.data.activities.filter(
              (a) => a.assignmentActivityId === state.AssignmentActivityId
            )[0];

            startAssignmentActivity(
              r.data.subjectId,
              state.AssignmentActivityId,
              getActivityName(activity, props.profile?.plLanguageId ?? 1),
              activity.multipleAttempt,
              state.AssignmentId,
              r.data.activities,
              studentRoute,
              activity.learnosityIdEnglish,
              activity.learnosityIdSpanish,
              activity.activityType
            );
          });
        } else {
          setInformationPopup(
            <InformationDialog
              informationMessage={response.message}
              informationTitle={"Error"}
              isError={true}
              open={true}
              onOkClick={() => {
                handleRedirection(
                  roleId,
                  gradeId,
                  "",
                  history,
                  Constant.ExterRosterType.GOOGLE
                );
              }}
            />
          );
        }
      }
    });
  };

  const handleTeacherAssignmentRedirection = () => {
    getAssignmentStatus(state.AssignmentId, null).then((r) => {
      if (r.data && r.data.isActive) {
        history.push(
          RouteConstant.EDITASSIGNMENT.replace(
            ":id",
            state.AssignmentId.toString()
          )
        );
      } else {
        setInformationPopup(
          <InformationDialog
            informationMessage={"This assignment is not active."}
            informationTitle={"Error"}
            isError={true}
            open={true}
            onOkClick={() => {
              history.push(RouteConstant.ROUTE_DASHBOARD);
            }}
          />
        );
      }
    });
  };

  function startAssignmentActivity(
    subjectId: number,
    assignmentActivityId: number,
    assignmentActivityName: string,
    multipleAttempt: number,
    assignmentId: number,
    activities: Array<any>,
    studentRoute: string,
    lernosityActivityIdEN?: string,
    lernosityActivityIdES?: string,
    assignmentType?: string
  ) {
    let multipleAttemptAllowed =
      multipleAttempt && multipleAttempt > 0 ? true : false;
    assignmentType = assignmentType ? assignmentType : "";
    const assignment = activities.filter(
      (a) => a.assignmentActivityId === assignmentActivityId
    )[0];
    if (lernosityActivityIdEN || lernosityActivityIdES) {
      history.push({
        pathname: studentRoute
          .replace(
            ":assignmentActivityId",
            encodeURI(btoa(assignmentActivityId.toString()))
          )
          .replace(
            ":assignmentActivityName",
            encodeURI(paramEncode(assignmentActivityName))
          )
          .replace(
            ":lernosityActivityIdEN",
            encodeURI(btoa(lernosityActivityIdEN ? lernosityActivityIdEN : ""))
          )
          .replace(
            ":lernosityActivityIdES",
            encodeURI(btoa(lernosityActivityIdES ? lernosityActivityIdES : ""))
          )
          .replace(":assignmentType", encodeURI(btoa(assignmentType)))
          .replace(":assignmentId", encodeURI(btoa(assignmentId.toString())))
          .replace(":subjectId", encodeURI(btoa(subjectId.toString())))
          .replace(
            ":multipleAttemptAllowed",
            encodeURI(btoa(multipleAttemptAllowed.toString()))
          ),
        state: {
          prebuildTestType:
            (assignment?.summativeTestId ?? 0) > 0
              ? (assignment?.summativeTestName ?? "") + " Practice Test"
              : (assignment?.domainId ?? 0) > 0
              ? "Practice Test - " + assignment?.domainName ?? ""
              : "",
        },
      });
    }
  }

  return (
    <>
      <Loader></Loader>
      {InformationPopup ?? InformationPopup}
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    profile: state.profile,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setUserContext: (payload: userContextAction.UserContextState) => {
      dispatch(userContextAction.setUserContext(payload));
    },
    setProfile: (
      userId: number,
      roleId: number,
      schoolId: number,
      districtId: number
    ) => {
      dispatch(fetchProfile(userId, roleId, schoolId, districtId));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExternalGoogleLogin);

import { v4 as uuidv4 } from "uuid";
const uuid = uuidv4();

export function initItemRequest(itemIds: Array<string>, showAnswers: boolean) {
  return {
    user_id: "DemoTest",
    rendering_type: "inline",
    name: "Items API Test",
    state: "review",
    items: itemIds,
    activity_id: itemIds[0],
    session_id: uuid,
    type: "local_practice",
    config: {
      showCorrectAnswers: showAnswers,
      showInstructorStimulus: showAnswers,
      fontsize: "normal",
      renderSubmitButton: false,
      questions_api_init_options: {
        beta_flags: {
          reactive_views: true,
        },
      },
    },
  };
}

export function initRequestByitemsRef(
  id: string,
  showAnswers: boolean,
  items: Array<string>
) {
  const requestDetails = {
    user_id: "DemoTest",
    rendering_type: "inline",
    name: "Items API Test",
    state: "review",
    activity_template_id: id,
    activity_id: id,
    session_id: uuid,
    type: "local_practice",
    config: {
      showCorrectAnswers: showAnswers,
      showInstructorStimulus: showAnswers,
      fontsize: "normal",
      renderSubmitButton: false,
      questions_api_init_options: {
        beta_flags: {
          reactive_views: true,
        },
      },
    },
  };
  return {
    ...requestDetails,
    items: items,
  };
}

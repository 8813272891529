import { Fragment, useEffect, useState } from "react";
import "../../../../../../css/style.css";
import { connect } from "react-redux";
import OverviewResult from "./overViewResult";
import LearnosityReport from "../../../../results/learnosityReport";
import Profile from "../../../../../../model/users/profile";
import { UserContextState } from "../../../../../../redux/actions/userContextAction";
import { getVideoPracticeResult } from "../../../../../../api/student/studyPlanApi";
import { useTranslation } from "react-i18next";
import Breadcrumb from "../../breadcrumb";
import routeConstant from "../../../../../../utils/constant/routeConstant";
import {useHistory, useLocation} from "react-router-dom";
interface IStudyPlanResult {
  userContext: UserContextState;
  profile: Profile;
  match: any;
  location: any;
  studentId?: number;
  studentGradeId?: number;
  isTeacherView?: boolean;
  sessionId?: number;
}
const VideoPracticeResult = (props: IStudyPlanResult) => {
  const [studyPlanResult, setStudyPlanResult] = useState<any>(null);
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const schoolYearId: number | null = parseInt(query.get("schoolYearId") ?? "0") || null;
  const [title, setTitle] = useState<any>("");
  const sessionId = props.isTeacherView
    ? props.sessionId ?? ""
    : props.match.params.sessionId;
  const { t } = useTranslation();
  const userId =
    props.isTeacherView === true
      ? props.studentId ?? 0
      : props.userContext.userId;
  const [learnosityActivityUserId, setLearnosityActivityUserId] =
    useState<number>(userId);
  const history = useHistory();

  const videoTitle =
    (props.location?.state && props.location?.state?.videoTitle) ??
    t("StudyPlanResult.VideoResult");
  useEffect(() => {
    getResult();
  }, []);

  function getResult() {
    getVideoPracticeResult(sessionId, schoolYearId).then((res) => {
      setLearnosityActivityUserId(res.data.learnosityActivityUserId ?? userId);
      setTitle(res.data.standardName);
      res.data.standardName = res.data.subjectName;
      setStudyPlanResult(res.data);
    }).catch(apiError => {
      if (apiError.response.status === 404) {
        history.push({ pathname: '/notfound' });
      }
    });
  }

  const breadcrumbItems = () => {
    return [
      {
        name: t("Breadcrumb.Home"),
        url: routeConstant.MiddleSchool.assignments,
        textColor: "text-white",
      },
      {
        name: t("Breadcrumb.StudyPlanSubjects"),
        textColor: "text-white",
        url: routeConstant.MiddleSchool.subjects,
      },
      {
        name: t("Breadcrumb.Result"),
        textColor: "text-white",
        url: "",
      },
    ];
  };

  function returnToStudyPlan() {
    var path = routeConstant.MiddleSchool.StudyPlanStandards;
    path = path.replace(":id", studyPlanResult?.subjectId.toString());
    history.push({
      pathname: path,
    });
  }

  return (
    <Fragment>
      {studyPlanResult && (
        <div className="relative bg-primary-violet w-full">
          {!props.isTeacherView && (
            <nav
              className="flex px-4 lg:px-6 py-4 border-b"
              aria-label="Breadcrumb"
            >
              <Breadcrumb items={breadcrumbItems()} />
            </nav>
          )}
          <div className="p-5 md:py-5 md:px-2 lg:p-5 flex flex-col md:flex-row">
            <OverviewResult data={studyPlanResult} />
            <div className="flex-grow h-auto md:w-1/2">
              <div className="flex w-full mt-6 md:mt-0 pb-6 justify-between items-center">
                <div>
                  <h1 className="text-2xl font-bold text-white capitalize">
                    {videoTitle}
                  </h1>
                  <h2 className="text-base font-medium text-white capitalize">
                    {title}
                  </h2>
                </div>
                {!props.isTeacherView && (
                  <button
                    className={`cursor-pointer bg-blues rounded-md shadow-sm py-2 px-4 flex justify-center text-white font-semibold text-sm hover:text-white-700 hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
                    onClick={() => returnToStudyPlan()}
                  >
                    Return to Study Plan
                  </button>
                )}
              </div>
              <div className="flex-none xxl:flex">
                <div className="w-full rounded-md xl:mr-12 mb-12 xxl:mb-0 overflow-hidden shadow-xl">
                  {studyPlanResult.questions.length > 1 && (
                    <>
                      <div className="p-5 bg-gray-50 border rounded-md text-sm font-semibold">
                        <h2>{t("StudyPlanResult.AnswerQuestionStatus")}.</h2>
                        <ul className="flex flex-wrap gap-2 w-full xl:w-1/2 mt-3">
                          {studyPlanResult.questions.map((question, index) => {
                            return (
                              <>
                                {question.order > 0 && question.score > 0 && (
                                  <li
                                    key={index}
                                    className="w-6 h-6 bg-[#B2D136] border border-[#B2D136] text-white text-xs font-semibold flex items-center rounded-sm shadow-sm justify-center hover:bg-transparent hover:text-[#B2D136]"
                                  >
                                    {index + 1}
                                  </li>
                                )}
                                {question.order > 0 && question.score === 0 && (
                                  <li
                                    key={index}
                                    className="w-6 h-6 bg-[#e53f71] border border-[#e53f71] text-white text-xs font-semibold flex items-center rounded-sm shadow-sm justify-center hover:bg-transparent hover:text-[#e53f71]"
                                  >
                                    {index + 1}
                                  </li>
                                )}
                              </>
                            );
                          })}
                        </ul>
                      </div>
                    </>
                  )}
                  <div className="mt-5 p-5 bg-white rounded-md text-sm font-semibold">
                    <LearnosityReport
                      sessionId={studyPlanResult.lernositySessionId}
                      user_id={learnosityActivityUserId.toString()}
                      show_answers={true}
                      wrapperClass="MS-Wrapper"
                      subjectId={studyPlanResult.subjectId}
                    ></LearnosityReport>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
};
export default connect<{}, {}, {}>(mapStateToProps, {})(VideoPracticeResult);

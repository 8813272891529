import { Fragment, useEffect, useState } from "react";
import Profile from "../../../../../model/users/profile";
import { connect } from "react-redux";
import { ISkillStandard } from "../../../../../model/skillPractice/skillStandard";
import { getSkillStandard } from "../../../../../api/student/skillPracticeApi";
import constant from "../../../../../utils/constant/constant";

interface SkillStandardProps {
  skillSubjectId: number;
  profile?: Profile;
  onStandardSelected: (skillStandardId: number) => void;
}

function SkillStandard(props: SkillStandardProps) {
  const [skillStandards, setSkillStandards] = useState<Array<ISkillStandard>>(
    []
  );
  var topicNumber = 1;

  useEffect(() => {
    if (props.skillSubjectId !== 0) {
      getSkillStandard(props.skillSubjectId, 0, props.profile?.gradeId ?? 0)
        .then((d) => {
          setSkillStandards(d.data.data);
        })
        .catch(() => {});
    }
  }, [props.skillSubjectId]);

  return (
    <Fragment>
      <div className="rounded-t-lg">
        <div>
          <Fragment>
            <div className="grid grid-cols-3 xl:grid-cols-2 xxxl:grid-cols-3 gap-3 text-center flex flex-col">
              {skillStandards.map((standard, index) => {
                return (
                  <div
                    key={index}
                    className="flex group w-full bg-white shadow p-3 min-h-[4rem] border-l-4 border-[#00A2D4] justify-start items-center rounded-lg hover:shadow-2xl cursor-pointer"
                    onClick={() => {
                      props.onStandardSelected(standard.skillStandardId);
                    }}
                  >
                    <label className="flex-shrink-0 text-white font-semibold text-base cursor-pointer h-10 w-10 mr-4 bg-[#00A2D4] bg-ligth-teal rounded-full flex inline-block items-center justify-center">
                      {topicNumber++}
                    </label>
                    <p className="font-Montserrat text-left text-base tracking-wide text-700 group-hover:text-black ">
                      {props.profile?.plLanguageId ===
                      constant.Languages.Spanish
                        ? standard.spanishName ?? standard.name
                        : standard.name}
                    </p>
                  </div>
                );
              })}
            </div>
          </Fragment>
        </div>
      </div>
    </Fragment>
  );
}

const mapStateToProps = (state: any) => {
  return {
    profile: state.profile,
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(SkillStandard);

import { Configuration } from "../../environment/setup"
import {AxiosHelper} from "../../environment/axiosConfig";

export function getStudentPorgram(
    userId: number
) {
    const url = `${Configuration.ApiBaseUrl}app/student/StudentProgram/${userId}`;
    return AxiosHelper.axiosInstance().get(
        url
    );
}
import { XIcon } from "@heroicons/react/outline";
import { Fragment, useRef, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { addFeedback } from "../../../api/feedback.service";
import { OutlineFileUpload } from "../../../assets/icons";
import { IFeedback } from "../../../model/interface/feedback";
import userError from "../../../model/manageUser/userError";
import { default as constant } from "../../../utils/constant/constant";
import InfoMessage from "../../shared/infoMessage";
import Loader from "../../shared/loader";

function ProductFeedback(props: any) {
  const { profile } = props;
  const feedbackType = 5; //Other
  let hiddenFileInput = useRef<HTMLInputElement>(null);
  const alphaNumericExpression = new RegExp(/^[a-zA-Z0-9_.-^\w\s]*$/);

  const [showLoader, setLoader] = useState(false);
  const [validEmail, setValidEmail] = useState(false);
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [feedbackText, setfeedbackText] = useState<string>("");
  const [isUploadDisabled, setIsUploadDisabled] = useState<boolean>(false);
  const [selectedfile, setFile] = useState(new File([], ""));
  const [fileData, setFileData] = useState("");
  const handleAdd = () => {
    hiddenFileInput.current?.click();
  };
  const [userErrors, setUserErrors] = useState({
    name: new userError(false, "Please enter name"),
    email: new userError(false, constant.UserErrorMessage.Email),
    emailFormat: new userError(false, constant.UserErrorMessage.EmailFormat),
    feedbackText: new userError(false, "Please provide feedback"),
  });
  const handleSubmit = (e) => {
    e.preventDefault();
    if (Validation()) {
      return;
    }
    setIsUploadDisabled(false);
    setLoader(true);
    const feedback: IFeedback = {
      reference: 0,
      referenceType: "",
      feedbackTypeId: feedbackType,
      comments: feedbackText,
      submittedBy: profile?.userId,
      attachment: fileData,
      email: email,
      name: name,
      sendEmail: true,
    };
    addFeedback(feedback).then(() => {
      toast.success("Feedback submitted successfully.");
    });
    setLoader(false);
    handleOnClose();
  };
  const handleOnClose = () => {
    props.showPopUp(false);
  };
  const handleChangeFile = (event) => {
    var files = event.target.files,
      file = files[0];
    setFile(file);
    let result = convertBase64(file);
    result.then((data) => {
      let outputResult = "" + data;
      setFileData(outputResult);
    });
  };
  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  function handleEmailChange(email) {
    setEmail(email);
    if (isValidEmail(email)) {
      setValidEmail(true);
      userErrors.emailFormat.isError = false;
    } else {
      setValidEmail(false);
      userErrors.emailFormat.isError = true;
    }
  }
  function isValidEmail(email): boolean {
    var reg = /^[A-Z0-9._%+-]+@([A-Z0-9-]+\.)+[A-Z]{2,4}$/i;
    if (reg.test(email)) {
      return true;
    }
    return false;
  }
  const Validation = () => {
    var error = userErrors;
    error.name.isError = !name ? true : false;

    error.email.isError = !email ? true : false;
    error.emailFormat.isError = !email ? false : !isValidEmail(email);
    error.feedbackText.isError = !feedbackText ? true : false;

    setUserErrors({ ...error });
    return (
      error.name.isError ||
      error.email.isError ||
      error.emailFormat.isError ||
      error.feedbackText.isError
    );
  };

  return (
    <Fragment>
      <div className="fixed inset-0 z-40 flex items-center bg-black bg-opacity-50 sm:items-center sm:justify-center">
        <div
          className="w-full flex flex-col justify-between overflow-hidden h-auto max-h-80percent bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:max-w-3xl"
          role="dialog"
        >
          <header>
            {/* Header */}
            <div className="px-4 py-4 bg-gray-50">
              <div className="flex items-start justify-between space-x-3">
                <div className="space-y-1">
                  <div className="text-lg font-medium text-gray-900">
                    <h1 className="text-2xl mb-2"> Contact Us </h1>
                    <span className="text-gray-700"></span>
                    <InfoMessage
                      message="Please provide detailed information for any support
                      issues. Please direct product feedback or ideas to the
                      Feedback option from the Resource Center located in the
                      lower right corner of the screen."
                    ></InfoMessage>
                  </div>
                </div>
                <div className="h-7 flex items-center">
                  <button
                    type="button"
                    className="text-gray-400 hover:text-gray-500"
                    onClick={() => props.showPopUp(false)}
                  >
                    <span className="sr-only">Close panel</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
          </header>
          <div className="w-full relative overflow-auto px-6 py-6">
            <div className="space-y-6">
              {showLoader && <Loader></Loader>}
              <div>
                <div className="mt-1">
                  <input
                    placeholder="Your name"
                    type="text"
                    name="name"
                    id="name"
                    value={name}
                    maxLength={60}
                    onChange={(e) => {
                      if (
                        alphaNumericExpression.test(e.target.value.toString())
                      ) {
                        setName(e.target.value);
                      }
                    }}
                    className="border py-3 px-4 block w-full shadow-sm text-gray-900 focus:ring-primary-violet focus:border-primary-violet border border-gray-300 rounded-md"
                  />
                  {userErrors.name.isError && (
                    <span className="text-red-500">
                      {userErrors.name.message}
                    </span>
                  )}
                </div>
              </div>
              <div>
                <div className="mt-1">
                  <input
                    placeholder="my.email@example.com"
                    type="text"
                    name="user_email"
                    id="user_email"
                    value={email}
                    maxLength={60}
                    onChange={(e) => {
                      handleEmailChange(e.target.value);
                    }}
                    className="border py-3 px-4 block w-full shadow-sm text-gray-900 focus:ring-primary-violet focus:border-primary-violet border border-gray-300 rounded-md"
                  />
                  {userErrors.email.isError && (
                    <span className="text-red-500">
                      {userErrors.email.message}
                    </span>
                  )}
                  {userErrors.emailFormat.isError && (
                    <span className="text-red-500">
                      {userErrors.emailFormat.message}
                    </span>
                  )}
                </div>
              </div>
              <div>
                <div className="mt-1">
                  <textarea
                    placeholder=" Please provide details on how we can help…"
                    rows={5}
                    className="py-1.5 px-3 block w-full shadow-sm focus:ring-primary-violet focus:border-primary-violet border border-gray-300 rounded-md"
                    value={feedbackText}
                    onChange={(e) => {
                      setfeedbackText(e.target.value);
                    }}
                  />
                  {userErrors.feedbackText.isError && (
                    <span className="text-red-500">
                      {userErrors.feedbackText.message}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="flex py-1.5 px-5 items-center space-x-4">
            <div className="hidden">
              <input
                type="file"
                ref={hiddenFileInput}
                onChange={handleChangeFile}
                onClick={(event) => {
                  event.currentTarget.value = "";
                }}
                style={{ display: "none" }}
                accept="*.*"
              />
            </div>
            <button
              type="submit"
              className="flex bg-primary-violet border border-transparent mb-auto shadow-sm  py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-dark-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              onClick={handleAdd}
              disabled={isUploadDisabled}
            >
              <OutlineFileUpload className="w-4 h-4 mr-2 text-white fill-current" />
              Upload file
            </button>
            <label>{selectedfile.name}</label>
          </div>
          {/* <!-- Action Buttons -->  */}
          <footer className="flex  items-center justify-end px-6 py-4 sm:flex-row bg-gray-50 dark:bg-gray-800">
            <div className="flex-shrink-0">
              <div className="justify-center">
                <button
                  className="disabled:opacity-50 align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 rounded-lg text-sm text-white border border-transparent bg-primary-green focus:shadow-outline-purple sm:w-auto"
                  type="button"
                  onClick={(e) => {
                    handleSubmit(e);
                  }}
                  disabled={!validEmail}
                >
                  Send
                </button>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </Fragment>
  );
}

const mapStateToProps = (state: any) => {
  return {
    masterData: state.masterData,
    userContext: state.userContext,
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(ProductFeedback);

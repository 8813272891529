import React, { ChangeEvent, useState } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import ReactTooltip from "react-tooltip";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import {
  CancelIcon,
  CometIcon,
  CrossIcon,
  EdGalaxyLogo,
  ExclamationIcon,
  LoginSearchIcon,
  LogoIcon,
  RightArrow,
  UsaTestPrepLogo,
} from "../../assets/icons/index";
import "../../css/style.css";
import { fetchProfile } from "../../redux/actions/userActions";
import * as userContextAction from "../../redux/actions/userContextAction";
import { useConfirmation } from "../shared/confirmation/confirmationService";
import { SendForgotPasswordEmail } from "../../api/account/accountApi";

interface LoginContainerProps {
  fetchLoginData: any;
  token: string;
  history: string;
}

const Login: React.FC<LoginContainerProps> = (props: any) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [accountLoginName, setAccountLoginName] = useState("");
  const [email, setEmail] = useState("");
  // const [userValid, setUserValid] = useState(false);
  // const [errorMessage, setErrorMessage] = useState("");
  const [crossIcon, setShowCrossIcon] = useState(false);
  const [loading, setLoading] = useState(false);
  const confirm = useConfirmation();

  function handleOnChangeAccountLoginName(e: ChangeEvent<HTMLInputElement>) {
    setAccountLoginName(e.currentTarget.value);
  }
  function handleOnChangeEmail(e: ChangeEvent<HTMLInputElement>) {
    setEmail(e.currentTarget.value);
  }
  function handleOnSubmit() {
    setLoading(true);
    SendForgotPasswordEmail(accountLoginName, email)
      ?.then((response) => {
        setLoading(false);

        if (!response.data) {
          toast.error("Invalid account details.");
        } else if (response.data.isDisabled || !response.data.isActive) {
          toast.error(
            "Your account is currently inactive. Reach out to your administrator to have this corrected."
          );
        } else {
          setAccountLoginName("");
          setEmail("");
          confirm({
            variant: "info",
            title: "Password recovery information has been sent.",
            description:
              " We sent password recovery instructions to your email. Please follow these instructions to recover your password.",
          }).then((d) => {});
        }
      })
      .catch(() => {
        setLoading(false);
      });
  }

  return (
    <>
      <ReactTooltip
        id={"ssoInfo"}
        className="bg-primary-violet z-50"
      ></ReactTooltip>
      <div className="flex flex-col min-h-screen relative">
        <nav className="md:hidden absolute top-4 left-4 menu-button cursor-pointer">
          {!crossIcon && (
            <CrossIcon
              onClick={() => {
                setShowCrossIcon(true);
              }}
            />
          )}
          {crossIcon && (
            <CancelIcon
              onClick={() => {
                setShowCrossIcon(false);
              }}
            />
          )}
        </nav>
        <div>
          <div className="flex flex-col md:items-center md:justify-between md:flex-row w-11/12 md:w-10/12 xl:w-containerMain mx-auto py-2">
            <span className="flex justify-center md:flex-none mb-4 md:mb-0">
              <a href="https://progresslearning.com/">
                <LogoIcon title="Progress Learning" />
              </a>
            </span>
            <span className="md:mt-0 flex items-end inline-block justify-center">
              <span className="flex items-center">
                <a href="https://progresslearning.com/resources/">
                  <span className="text-primary-purple font-Barlow text-base font-semibold mr-4 md:mr-6 cursor-pointer hover:underline">
                    Resources
                  </span>
                </a>
                <a href="https://progresslearning.com/our-products/pricing/">
                  <span className="bg-secondary-teal text-base font-medium font-Barlow text-white hover:text-white px-4 md:px-8 py-2.5 mr-3 cursor-pointer hover:bg-dark-teal transition-all">
                    Get a Quote
                  </span>
                </a>
                <a href="/login">
                  <span className="text-base font-medium font-Barlow border border-primary-purple text-primary-purple px-4 md:px-8 py-2.5 hover:bg-primary-purple hover:text-white cursor-pointer mr-4 md:mr-4">
                    Login
                  </span>
                </a>
                <a href="https://progresslearning.com/search/">
                  <span>
                    <LoginSearchIcon />
                  </span>
                </a>
              </span>
            </span>
          </div>
        </div>
        <div
          className={`li-menu bg-secondary-teal border-b border-gray-50 xl:border-0 md:block ${
            crossIcon ? "" : "hidden"
          }`}
        >
          <div className="flex py-3 md:py-0 flex-col md:flex-row justify-between w-10/12 md:w-full md:pl-4 lg:pl-0 lg:w-10/12 xl:w-containerMain mx-auto items-start md:items-center relative">
            <ul className="flex h-auto md:h-14 space-y-3 md:space-y-0 flex-col md:flex-row  font-Montserrat md:text-sm lg:text-base font-semibold text-white capitalize cursor-pointer">
              <li className="flex group inline-block relative hover:bg-dark-teal pl-3 md:pl-4 pr-3">
                <span className="flex items-center inline-block">
                  <span className="whitespace-nowrap">our products</span>
                  <RightArrow className="w-4 stroke-current text-white ml-1 group-hover:transform group-hover:rotate-90 transition-all" />
                </span>
                <ul className="absolute hidden group-hover:block z-10 top-7 left-0 md:top-14 md:left-0 bg-white shadow-lg rounded-sm w-56 min-w-full">
                  <li className="bg-dark-teal hover:bg-darkest-teal transition-all">
                    <a
                      href="#!"
                      className="font-normal font-Barlow border-b border-white border-opacity-50 block mx-4 py-3 hover:border-opacity-0"
                    >
                      Education Galaxy for Elementary (K-5){" "}
                    </a>
                  </li>
                  <li className="bg-dark-teal hover:bg-darkest-teal transition-all">
                    <a
                      href="#!"
                      className="font-normal font-Barlow border-b border-white border-opacity-50 block mx-4 py-3 hover:border-opacity-0"
                    >
                      Liftoff Adaptive Intervention (2-8){" "}
                    </a>
                  </li>
                  <li className="bg-dark-teal hover:bg-darkest-teal transition-all">
                    <a
                      href="#!"
                      className="font-normal font-Barlow border-b border-white border-opacity-50 block mx-4 py-3 hover:border-opacity-0"
                    >
                      Progress Learning for Middle School (6-8){" "}
                    </a>
                  </li>
                  <li className="bg-dark-teal hover:bg-darkest-teal transition-all">
                    <a
                      href="#!"
                      className="font-normal font-Barlow border-b border-white border-opacity-50 block mx-4 py-3 hover:border-opacity-0"
                    >
                      Progress Learning for High School (9-12){" "}
                    </a>
                  </li>
                  <li className="bg-dark-teal hover:bg-darkest-teal transition-all">
                    <a
                      href="#!"
                      className="font-normal font-Barlow border-b border-white border-opacity-50 block mx-4 py-3 hover:border-opacity-0"
                    >
                      Integrations{" "}
                    </a>
                  </li>
                  <li className="bg-dark-teal hover:bg-darkest-teal transition-all">
                    <a
                      href="#!"
                      className="font-normal font-Barlow block mx-4 py-3 "
                    >
                      Pricing{" "}
                    </a>
                  </li>
                </ul>
              </li>
              <li className="flex inline-block items-center relative hover:bg-dark-teal pl-3 pr-3">
                schools & districts
              </li>
              <li className="flex inline-block items-center relative hover:bg-dark-teal pl-3 pr-3">
                teachers
              </li>
              <li className="flex inline-block items-center relative hover:bg-dark-teal pl-3 pr-3">
                news & blog
              </li>
              <li className="flex group inline-block relative hover:bg-dark-teal pl-3 md:pl-4 pr-3">
                <span className="flex items-center inline-block">
                  <span className="whitespace-nowrap">about us</span>
                  <RightArrow className="w-4 stroke-current text-white ml-1 group-hover:transform group-hover:rotate-90 transition-all" />
                </span>
                <ul className="absolute hidden group-hover:block z-10 top-7 left-0 md:top-14 md:left-0 bg-white shadow-lg rounded-sm w-56 min-w-full">
                  <li className="bg-dark-teal hover:bg-darkest-teal transition-all">
                    <a
                      href="#!"
                      className="font-normal font-Barlow border-b border-white border-opacity-50 block mx-4 py-3 hover:border-opacity-0"
                    >
                      Careers{" "}
                    </a>
                  </li>
                  <li className="bg-dark-teal hover:bg-darkest-teal transition-all">
                    <a
                      href="#!"
                      className="font-normal font-Barlow border-b border-white border-opacity-50 block mx-4 py-3 hover:border-opacity-0"
                    >
                      Certifications{" "}
                    </a>
                  </li>
                  <li className="bg-dark-teal hover:bg-darkest-teal transition-all">
                    <a
                      href="#!"
                      className="font-normal font-Barlow border-b border-white border-opacity-50 block mx-4 py-3 hover:border-opacity-0"
                    >
                      Testimonials{" "}
                    </a>
                  </li>
                  <li className="bg-dark-teal hover:bg-darkest-teal transition-all">
                    <a
                      href="#!"
                      className="font-normal font-Barlow block mx-4 py-3"
                    >
                      Training{" "}
                    </a>
                  </li>
                </ul>
              </li>
              <li className="flex inline-block items-center relative hover:bg-dark-teal pl-3 pr-3">
                contact us
              </li>
            </ul>
          </div>
        </div>
        <div className="flex flex-col sm:flex-row md:flex-1 lg:flex-none relative">
          <div className="w-full sm:w-3/12 bg-bgDots bg-cover"></div>
          <div className="w-full sm:w-9/12 bg-light-gray flex justify-center">
            <div className="w-12/12 md:w-10/12 lg:w-10/12 xl:mx-0 xl:w-10/12 2xl:w-10/12 3xl:w-10/12 flex flex-col items-center md:flex-row mt-0 pt-6 xl:pt-0 xl:mt-36 px-10 xl:px-0 xl:pr-0 2xl:pr-0 pb-12 xl:pb-48">
              <form onSubmit={handleSubmit(handleOnSubmit)}>
                <div className="w-72 sm:w-96 flex flex-col">
                  <CometIcon className="ml-auto" />
                  <div className="mb-8">
                    <div className="text-gray-700 block text-lg mb-1 font-medium font-Montserratt">
                      Forgot password?
                    </div>
                    <p className="text-sm text-blue-700 bg-blue-50 px-4 py-2 border border-blue-200 rounded-md">
                      Enter your Account ID and Email and we will send password
                      recovery instructions to you.
                    </p>
                  </div>

                  <div>
                    <label className="text-gray-light block text-sm font-semibold font-Montserrat ">
                      Account ID
                    </label>
                    <div className="mt-1.5 relative group">
                      <input
                        type="text"
                        className="border border-gray-200 shadow-sm focus:ring-primary-purple focus:border-primary-purple h-10 3xl:h-12 block w-full text-lg border border-gray-200 text-gray-500 px-3 rounded-sm"
                        {...register("accountLoginName", {
                          onChange: handleOnChangeAccountLoginName,
                          required: true,
                        })}
                        value={accountLoginName}
                      />

                      {errors.accountLoginName && (
                        <span>
                          <span className="opacity-0 group-hover:opacity-100 bg-white text-red-500 border-red-400 border transition duration-500 rounded-sm max-w-70% p-0.5 pl-2 pr-3 text-xs inline-block absolute top-1/2 transform -translate-y-1/2 right-2 ">
                            Account Id is required
                          </span>
                          <ExclamationIcon className="w-1 absolute opacity-100 top-1/2 transform -translate-y-1/2 right-3 cursor-pointer"></ExclamationIcon>
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="mt-5">
                    <label className="text-gray-light block text-sm 3xl:text-base font-semibold font-Montserrat ">
                      Email
                    </label>
                    <div className="mt-1.5 relative group">
                      <input
                        type="text"
                        className="border border-gray-200 shadow-sm focus:ring-primary-purple focus:border-primary-purple h-10 3xl:h-12 block w-full text-lg border border-gray-200 text-gray-500 px-3 rounded-sm"
                        {...register("email", {
                          onChange: handleOnChangeEmail,
                          required: "Email is required",
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "Invalid email address",
                          },
                        })}
                        value={email}
                      />

                      {errors.email?.message && (
                        <span>
                          <span className="opacity-0 group-hover:opacity-100 bg-white text-red-500 border-red-400 border transition duration-500 rounded-sm max-w-70% p-0.5 pl-2 pr-3 text-xs inline-block absolute top-1/2 transform -translate-y-1/2 right-2 ">
                            {errors.email.message}
                          </span>
                          <ExclamationIcon className="w-1 absolute opacity-100 top-1/2 transform -translate-y-1/2 right-3 cursor-pointer"></ExclamationIcon>
                        </span>
                      )}
                    </div>
                  </div>

                  <button
                    className="bg-teal-light cursor-pointer hover:opacity-90 mt-6 w-full h-12 3xl:h-14 text-lg 3xl:text-xl flex items-center justify-center text-white font-Barlow font-semibold"
                    // onClick={handleOnClickRetrievePassword}
                    type="submit"
                  >
                    Retrieve
                    <div className="p-4">
                      {loading && (
                        <div
                          style={{ borderTopColor: "transparent" }}
                          className="w-6 h-6 border-4 border-white-400 border-solid rounded-full animate-spin"
                        ></div>
                      )}
                    </div>
                  </button>

                  <div className="border-t border-gray-300 mt-8 hidden md:block">
                    <div className="text-sm 3xl:text-md text-primary-purple mt-8 px-10 3xl:px-14 font-Montserrat text-center font-bold">
                      Education Galaxy and USATestprep are now Progress Learning
                    </div>
                    <div className="flex justify-center space-x-5 mt-5">
                      <div>
                        <EdGalaxyLogo />
                      </div>
                      <div>
                        <UsaTestPrepLogo />
                      </div>
                      <div></div>
                    </div>
                  </div>
                </div>
              </form>

              <div className="border-t border-gray-300 mt-8 block md:hidden">
                <div className="text-sm md:text-lg text-primary-purple mt-8 px-14 font-Montserrat text-center font-bold">
                  Education Galaxy and USATestprep are now Progress Learning
                </div>
                <div className="flex justify-center space-x-5 mt-5">
                  <div>
                    <EdGalaxyLogo />
                  </div>
                  <div>
                    <UsaTestPrepLogo />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    profile: state.profile,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setUserContext: (payload: userContextAction.UserContextState) => {
      dispatch(userContextAction.setUserContext(payload));
    },
    setProfile: (
      userId: number,
      roleId: number,
      schoolId: number,
      districtId: number
    ) => {
      dispatch(fetchProfile(userId, roleId, schoolId, districtId));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);

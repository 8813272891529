import {
  ChatOutlineIcon,
  CloseIcon,
  DeleteIcon,
  EditOutlineIcon,
  MinusIcon,
  OutlineRefreshIcon,
} from "../../../assets/icons/index";

const Chat = () => {
  return (
    <>
      <div className="w-100 fixed bottom-0 right-100 bg-white z-10">
        <div className="flex-1 justify-between flex flex-col h-100 shadow-lg">
          <div className="flex sm:items-center justify-between py-3 border-b-2 border-gray-200 bg-secondary-teal px-2">
            <div className="flex items-center space-x-4">
              <span className="relative">
                <span className="text-green-500 absolute right-0 bottom-1">
                  <svg width="10" height="10">
                    <circle cx="5" cy="5" r="5" fill="currentColor"></circle>
                  </svg>
                </span>
                <img
                  src="https://images.unsplash.com/photo-1549078642-b2ba4bda0cdb?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=facearea&amp;facepad=3&amp;w=144&amp;h=144"
                  alt=""
                  className="w-10 h-10 rounded-full"
                />
              </span>
              <div className="flex flex-col leading-tight">
                <div className="text-2xl mt-1 flex items-center">
                  <span className="text-gray-900 mr-3 text-sm">
                    Anderson Vanhron
                  </span>
                </div>
                <span className="text-xs text-gray-200">Junior Developer</span>
              </div>
            </div>
            <div className="flex items-center">
              <button
                type="button"
                className="inline-flex items-center justify-center rounded-full h-6 w-6 transition duration-500 ease-in-out text-white hover:bg-light-violet focus:outline-none"
              >
                <ChatOutlineIcon
                  title="Broadcast"
                  className="w-4 h-4 opacity-70"
                ></ChatOutlineIcon>
              </button>
              <button
                type="button"
                className="inline-flex items-center justify-center rounded-full h-6 w-6 transition duration-500 ease-in-out text-white hover:bg-light-violet focus:outline-none"
              >
                <DeleteIcon
                  title="Delete chat"
                  className="w-3 h-3 opacity-70"
                ></DeleteIcon>
              </button>
              <button
                type="button"
                className="inline-flex items-end justify-center rounded-full h-6 w-6 transition duration-500 ease-in-out text-white hover:bg-light-violet focus:outline-none"
              >
                <MinusIcon
                  title="Minimize"
                  className="w-4 h-4 opacity-70"
                ></MinusIcon>
              </button>
              <button
                type="button"
                title="Close"
                className="inline-flex items-center justify-center rounded-full h-6 w-6 transition duration-500 ease-in-out text-white hover:bg-light-violet focus:outline-none"
              >
                <CloseIcon className="opacity-70 w-4 h-4"></CloseIcon>
              </button>
            </div>
          </div>
          <div>
            <input
              type="text"
              placeholder="Search"
              className="w-full focus:outline-none text-xs focus:placeholder-gray-400 text-gray-600 placeholder-gray-600 px-4 bg-gray-200 py-1.5 border-0 focus:ring-gray-200 focus:border-gray-200"
            />
          </div>
          <div
            id="messages"
            className="flex flex-col space-y-4 p-3 overflow-y-auto scrollbar-thumb-blue scrollbar-thumb-rounded scrollbar-track-blue-lighter scrollbar-w-2 scrolling-touch"
          >
            <div className="chat-message">
              <div className="flex items-end">
                <div className="flex flex-col space-y-2 text-xs max-w-xs mx-2 order-2 items-start">
                  <div>
                    <span className="px-4 py-2 rounded-lg inline-block rounded-bl-none bg-gray-300 text-gray-600">
                      Can be verified on any platform using docker
                    </span>
                  </div>
                  <div className="text-xs text-gray-400 ml-auto">11:57 am</div>
                </div>
                <img
                  src="https://images.unsplash.com/photo-1549078642-b2ba4bda0cdb?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=facearea&amp;facepad=3&amp;w=144&amp;h=144"
                  alt="My profile"
                  className="w-6 h-6 rounded-full order-1"
                />
              </div>
            </div>
            <div className="chat-message">
              <div className="flex items-end justify-end">
                <div className="flex flex-col space-y-2 text-xs max-w-xs mx-2 order-1 items-end">
                  <div>
                    <span className="px-4 py-2 rounded-lg inline-block bg-light-violet text-white ">
                      Your error message says permission denied, npm global
                      installs must be given root privileges.
                    </span>
                  </div>
                  <div className="text-xs text-gray-400 mr-auto">11:57 am</div>
                </div>
              </div>
            </div>
            <div className="chat-message">
              <div className="flex items-end justify-end">
                <div className="flex flex-col space-y-2 text-xs max-w-xs mx-2 order-1 items-end">
                  <div>
                    <span className="px-4 py-2 rounded-lg inline-block bg-light-violet text-white ">
                      Your error message says permission denied, npm global
                      installs must be given root privileges.
                    </span>
                  </div>
                  <div className="text-xs text-gray-400 mr-auto">11:57 am</div>
                </div>
              </div>
            </div>
            <div className="chat-message">
              <div className="flex items-end justify-end">
                <div className="flex flex-col space-y-2 text-xs max-w-xs mx-2 order-1 items-end">
                  <div>
                    <span className="px-4 py-2 rounded-lg inline-block bg-light-violet text-white ">
                      Your error message says permission denied, npm global
                      installs must be given root privileges.
                    </span>
                  </div>
                  <div className="text-xs text-gray-400 mr-auto">11:57 am</div>
                </div>
              </div>
            </div>
            <div className="chat-message">
              <div className="flex items-end justify-end">
                <div className="flex flex-col space-y-2 text-xs max-w-xs mx-2 order-1 items-end">
                  <div>
                    <span className="px-4 py-2 rounded-lg inline-block bg-light-violet text-white ">
                      Your error message says permission denied, npm global
                      installs must be given root privileges.
                    </span>
                  </div>
                  <div className="text-xs text-gray-400 mr-auto">11:57 am</div>
                </div>
              </div>
            </div>
            <div className="chat-message">
              <div className="flex items-end justify-end">
                <div className="flex flex-col space-y-2 text-xs max-w-xs mx-2 order-1 items-end">
                  <div>
                    <span className="px-4 py-2 rounded-lg inline-block bg-light-violet text-white ">
                      Your error message says permission denied, npm global
                      installs must be given root privileges.
                    </span>
                  </div>
                  <div className="text-xs text-gray-400 mr-auto">11:57 am</div>
                </div>
              </div>
            </div>
            <div className="chat-message">
              <div className="flex items-end">
                <div className="flex flex-col space-y-2 text-xs max-w-xs mx-2 order-2 items-start">
                  <div>
                    <span className="px-4 py-2 rounded-lg inline-block rounded-bl-none bg-gray-300 text-gray-600">
                      Can be verified on any platform using docker
                    </span>
                  </div>
                  <div className="text-xs text-gray-400 ml-auto">11:57 am</div>
                </div>
                <img
                  src="https://images.unsplash.com/photo-1549078642-b2ba4bda0cdb?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=facearea&amp;facepad=3&amp;w=144&amp;h=144"
                  alt="My profile"
                  className="w-6 h-6 rounded-full order-1"
                />
              </div>
            </div>
            <div className="chat-message">
              <div className="flex items-end justify-end">
                <div className="flex flex-col space-y-2 text-xs max-w-xs mx-2 order-1 items-end">
                  <div>
                    <span className="px-4 py-2 rounded-lg inline-block bg-light-violet text-white ">
                      Any updates on this issue? I'm getting the same error when
                      trying to install devtools. Thanks
                    </span>
                  </div>
                  <div className="text-xs text-gray-400 mr-auto">11:57 am</div>
                </div>
              </div>
            </div>
          </div>
          <div className="border-t-2 border-gray-200 px-4 p-4">
            <div className="relative flex">
              <input
                type="text"
                placeholder="Write Something"
                className="w-full focus:outline-none text-sm focus:placeholder-gray-400 text-gray-600 placeholder-gray-600 pl-6 pr-32 bg-gray-200 rounded-full py-3"
              />
              <div className="absolute right-0 items-center inset-y-0 hidden sm:flex">
                <button
                  type="button"
                  className="inline-flex items-center justify-center rounded-full h-12 w-12 transition duration-500 ease-in-out text-white bg-light-violet hover:bg-dark-violet focus:outline-none"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    className="h-6 w-6 transform rotate-90"
                  >
                    <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z"></path>
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-96 fixed bottom-0 right-28 bg-white z-10">
        <div className="flex-1 justify-between flex flex-col h-auto max-h-46 shadow-lg">
          <div className="flex sm:items-center justify-between py-3 border-b-2 border-gray-200 bg-secondary-teal px-2">
            <div className="flex items-center space-x-4">
              <div className="flex flex-col leading-tight">
                <div className="mt-1 flex items-center ml-1">
                  <span className="text-gray-900 mr-3 text-base">
                    Messaging
                  </span>
                </div>
              </div>
            </div>
            <div className="flex items-center">
              <button
                type="button"
                className="inline-flex items-center justify-center rounded-full h-6 w-6 transition duration-500 ease-in-out text-white hover:bg-light-violet focus:outline-none"
              >
                <EditOutlineIcon className="w-4 h-4"></EditOutlineIcon>
              </button>
              <button
                type="button"
                className="inline-flex items-end justify-center rounded-full h-6 w-6 transition duration-500 ease-in-out text-white hover:bg-light-violet focus:outline-none"
              >
                <MinusIcon className="w-4 h-4 opacity-70"></MinusIcon>
              </button>
              <button
                type="button"
                className="inline-flex items-center justify-center rounded-full h-6 w-6 transition duration-500 ease-in-out text-white hover:bg-light-violet focus:outline-none"
              >
                <CloseIcon className="w-4 h-4 opacity-70"></CloseIcon>
              </button>
            </div>
          </div>
          <div className="px-3 mt-1.5 flex items-center">
            <input
              type="text"
              placeholder="Search"
              className="w-full focus:outline-none text-xs focus:placeholder-gray-400 text-gray-600 placeholder-gray-600 px-4 bg-gray-200 rounded-full py-1.5"
            />
            <OutlineRefreshIcon
              title="Clear"
              className="w-4 h-4 cursor-pointer ml-2 hover:opacity-70"
            ></OutlineRefreshIcon>
          </div>
          <div className="text-sm text-gray-400 font-medium pl-3 pt-2">
            Recent
          </div>

          <div
            id="messages"
            className="flex flex-col overflow-y-auto scrollbar-thumb-blue scrollbar-thumb-rounded scrollbar-track-blue-lighter scrollbar-w-2 scrolling-touch"
          >
            <div className="chat-message border-b border-gray-200 p-3 cursor-pointer transition duration-500 ease-in-out hover:bg-gray-100">
              <div className="flex items-end">
                <div className="flex items-center space-x-4">
                  <div className="relative">
                    <span className="text-green-500 absolute right-0 bottom-1">
                      <svg width="10" height="10">
                        <circle
                          cx="5"
                          cy="5"
                          r="5"
                          fill="currentColor"
                        ></circle>
                      </svg>
                    </span>
                    <img
                      src="https://images.unsplash.com/photo-1549078642-b2ba4bda0cdb?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=facearea&amp;facepad=3&amp;w=144&amp;h=144"
                      alt=""
                      className="w-10 h-10 rounded-full"
                    />
                  </div>
                  <div className="flex flex-col leading-tight">
                    <div className="text-2xl mt-1 flex items-center">
                      <span className="text-gray-900 mr-3 text-sm">
                        Anderson Vanhron
                      </span>
                    </div>
                    <span className="text-xs text-gray-400">
                      Junior Developer
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="chat-message border-b border-gray-200 p-3 cursor-pointer transition duration-500 ease-in-out hover:bg-gray-100">
              <div className="flex items-end">
                <div className="flex items-center space-x-4">
                  <div className="relative">
                    <span className="text-yellow-500 absolute right-0 bottom-1">
                      <svg width="10" height="10">
                        <circle
                          cx="5"
                          cy="5"
                          r="5"
                          fill="currentColor"
                        ></circle>
                      </svg>
                    </span>
                    <img
                      src="https://images.unsplash.com/photo-1549078642-b2ba4bda0cdb?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=facearea&amp;facepad=3&amp;w=144&amp;h=144"
                      alt=""
                      className="w-10 h-10 rounded-full"
                    />
                  </div>
                  <div className="flex flex-col leading-tight">
                    <div className="text-2xl mt-1 flex items-center">
                      <span className="text-gray-900 mr-3 text-sm">
                        Anderson Vanhron
                      </span>
                    </div>
                    <span className="text-xs text-gray-400">
                      Junior Developer
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="chat-message border-b border-gray-200 p-3 cursor-pointer transition duration-500 ease-in-out hover:bg-gray-100">
              <div className="flex items-end">
                <div className="flex items-center space-x-4">
                  <div className="relative">
                    <span className="text-green-500 absolute right-0 bottom-1">
                      <svg width="10" height="10">
                        <circle
                          cx="5"
                          cy="5"
                          r="5"
                          fill="currentColor"
                        ></circle>
                      </svg>
                    </span>
                    <img
                      src="https://images.unsplash.com/photo-1549078642-b2ba4bda0cdb?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=facearea&amp;facepad=3&amp;w=144&amp;h=144"
                      alt=""
                      className="w-10 h-10 rounded-full"
                    />
                  </div>
                  <div className="flex flex-col leading-tight">
                    <div className="text-2xl mt-1 flex items-center">
                      <span className="text-gray-900 mr-3 text-sm">
                        Anderson Vanhron
                      </span>
                    </div>
                    <span className="text-xs text-gray-400">
                      Junior Developer
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="chat-message border-b border-gray-200 p-3 cursor-pointer transition duration-500 ease-in-out hover:bg-gray-100">
              <div className="flex items-end">
                <div className="flex items-center space-x-4">
                  <div className="relative">
                    <span className="text-green-500 absolute right-0 bottom-1">
                      <svg width="10" height="10">
                        <circle
                          cx="5"
                          cy="5"
                          r="5"
                          fill="currentColor"
                        ></circle>
                      </svg>
                    </span>
                    <img
                      src="https://images.unsplash.com/photo-1549078642-b2ba4bda0cdb?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=facearea&amp;facepad=3&amp;w=144&amp;h=144"
                      alt=""
                      className="w-10 h-10 rounded-full"
                    />
                  </div>
                  <div className="flex flex-col leading-tight">
                    <div className="text-2xl mt-1 flex items-center">
                      <span className="text-gray-900 mr-3 text-sm">
                        Anderson Vanhron
                      </span>
                    </div>
                    <span className="text-xs text-gray-400">
                      Junior Developer
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="chat-message border-b border-gray-200 p-3 cursor-pointer transition duration-500 ease-in-out hover:bg-gray-100">
              <div className="flex items-end">
                <div className="flex items-center space-x-4">
                  <div className="relative">
                    <span className="text-green-500 absolute right-0 bottom-1">
                      <svg width="10" height="10">
                        <circle
                          cx="5"
                          cy="5"
                          r="5"
                          fill="currentColor"
                        ></circle>
                      </svg>
                    </span>
                    <img
                      src="https://images.unsplash.com/photo-1549078642-b2ba4bda0cdb?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=facearea&amp;facepad=3&amp;w=144&amp;h=144"
                      alt=""
                      className="w-10 h-10 rounded-full"
                    />
                  </div>
                  <div className="flex flex-col leading-tight">
                    <div className="text-2xl mt-1 flex items-center">
                      <span className="text-gray-900 mr-3 text-sm">
                        Anderson Vanhron
                      </span>
                    </div>
                    <span className="text-xs text-gray-400">
                      Junior Developer
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <script>
        const el = document.getElementById('messages') el.scrollTop =
        el.scrollHeight
      </script>
    </>
  );
};
export default Chat;

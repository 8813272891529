import { AxiosHelper } from "../../environment/axiosConfig";
import APIConstant from "../../utils/constant/apiConstant";

export const getLiftoffGrowthReport = (
  schoolId: number,
  subjectId: number,
  pageNumber: number,
  pageSize: number,
  schoolYearId: number,
  classId?: number,
  gradeId?: number
) => {
  return AxiosHelper.axiosInstance().get(
    APIConstant.LIFTOFFGROWTHREPORT_API.GetLiftoffGrowthReportByStudentQuery,
    {
      params: {
        schoolId: schoolId,
        subjectId: subjectId,
        classId: classId,
        gradeId: gradeId,
        pageNumber: pageNumber,
        pageSize: pageSize,
        schoolYearId: schoolYearId,
      },
    }
  );
};

export const exportLiftoffGrowthReport = (
  schoolId: number,
  subjectId: number,
  schoolYearId: number,
  classId?: number,
  gradeId?: number,
  subjectName?: string,
  className?: string,
  grade?: string
) => {
  const params = {
    schoolId: schoolId,
    subjectId: subjectId,
    classId: classId,
    gradeId: gradeId,
    subjectName: subjectName,
    className: className,
    grade: grade,
    schoolYearId: schoolYearId,
  };

  return AxiosHelper.axiosInstance().post(
    APIConstant.LIFTOFFGROWTHREPORT_API.ExportLiftoffGrowthReportByStudentQuery,
    params,
    { responseType: "blob" }
  );
};

import { AxiosHelper } from "../../environment/axiosConfig";
import APIConstant from "../../utils/constant/apiConstant";

export const getSkillStandardDetail = (skillStandardId: number) => {
  return AxiosHelper.axiosInstance().get(
    `${APIConstant.CMS_API.SkillStandard}/${skillStandardId}`,
    {
      params: {},
    }
  );
};

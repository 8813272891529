import { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import ReactTooltip from "react-tooltip";
import Constant from "../../../utils/constant/constant";
import sidebarMenu from "../../../model/sideMenu/sidemenu";
import { getAuthParams } from "../../../api/profile/profile";
import { Configuration } from "../../../environment/setup";
import {
  BookIcon,
  CircleLeftIcon,
  MobileIcon,
  NewsPaperIcon,
  PLHomeIcon,
  SupportIcon,
  UserAddIcon,
  UserGroupIcon,
  TagOutlineIcon,
  AlienIcon,
  TrainingIcon,
  SupersheetIcon,
  SetupIcon,
  ArenaIcon,
  ClassCenterIcon,
  SchoolCenterIcon,
  TrainingCenterIcon,
  VideoMessageIcon,
  InstructionalResourcesIcon,
  DocumentReportIcon,
  CreditIcon,
  RocketIcon,
  NweaMapping,
  StudentViewIcon,
  SettingsIcon,
  ClassroomGameIcon,
  HorizonEducationLogo,
  HorizonEducationThumbnail,
} from "../../../assets/icons/index";
import { useLocation } from "react-router-dom";

import AllowedTo from "../rbac";
import { useHistory } from "react-router-dom";
import Menu from "./menu";
import SubMenu from "./subMenu";
import RouteConstant from "../../../utils/constant/routeConstant";
import Profile from "../../../model/users/profile";
import constant from "../../../utils/constant/constant";
//import { GetUSATPGuid } from "../../../api/teacher/teacher";
//import { useConfirmation } from "../../shared/confirmation/confirmationService";
import DynamicComponent from "../../dynamicComponent/dynamicComponent";
import UpgradeButtonControl from "../customButtonControl";
import { getEnvironment } from "../../../utils/environmentHelper";

interface ISidebarProps {
  userContext?: any;
  profile?: Profile;
}

const Sidebar = (props: ISidebarProps) => {
  const getMenuByRoleAndEnv = () => {
    return sidebarMenu
      .filter(item => 
        item.roles.includes(props.userContext?.roleId) 
        && (!item.environments || item.environments.includes(getEnvironment()))
      )
  };

  const menuRef: any = useRef(null);
  const history = useHistory();
  const [filteredNavMenu, setFilteredNavMenu] = useState(getMenuByRoleAndEnv());
  const [toggleSideBar, setToggleSideBar] = useState<boolean>(false);
  const [toggleClosedSubmenu, setToggleClosedSubmenu] =
    useState<boolean>(false);
  const [trainingUrl, setTrainingUrl] = useState("");
  const [activeMenu, setActiveMenu] = useState("");
  const [activeSubMenu, setActiveSubMenu] = useState("");
  const [isSubMenuActive, setIsSubMenuActive] = useState(false);
  const [path, setPath] = useState(window.location.pathname);
  //const [usatpGuid, setUsatpGuid] = useState<any>(null);
  type dynamicComponent = { component: any; parameter: any };
  const [popupComponent, setPopupComponent] = useState<dynamicComponent>();
  const [showPopup, setShowPopUp] = useState(false);
  //const confirm = useConfirmation();
  const location = useLocation();

  useEffect(() => {
    if (
      localStorage.getItem(Constant.LocalStorageKeys.ToggleSideMenu) === null
    ) {
      localStorage.setItem(Constant.LocalStorageKeys.ToggleSideMenu, "false");
    } else {
      const toggle = localStorage.getItem(
        Constant.LocalStorageKeys.ToggleSideMenu
      );
      setToggleSideBar(toggle?.toLocaleUpperCase() === "TRUE");
    }

    bindTrainingUrl();
    setFilteredNavMenu(getMenuByRoleAndEnv());
    //getUsatpGuid();
  }, [props.userContext.roleId]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setToggleClosedSubmenu(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuRef]);

  const bindTrainingUrl = () => {
    getAuthParams().then(() => {
      setTrainingUrl(Configuration.TrainingUrl);
    });
  };

  // function getUsatpGuid() {
  //   GetUSATPGuid(props.userContext.userId).then((d) => {
  //     setUsatpGuid(d.data);
  //   });
  // }
  function getIcon(menu: string) {
    let icon: any = null;

    switch (menu) {
      case Constant.Menu.HOME:
        icon = <PLHomeIcon className="w-5 h-5" />;
        break;
      case Constant.Menu.CLASSMANAGEMENT:
        icon = <NewsPaperIcon className="w-5 h-5 " />;
        break;
      case Constant.Menu.ASSIGNMENT:
        icon = <BookIcon className="w-5 h-5 " />;
        break;
      case Constant.Menu.USERMANAGEMENT:
        icon = <UserGroupIcon className="w-5 h-5 " />;
        break;
      case Constant.Menu.ACCOUNT_INFORMATION:
        icon = <CreditIcon className="w-5 h-5 " />;
        break;

      case Constant.Menu.SUPPORT:
        icon = <SupportIcon className="w-5 h-5 " />;
        break;
      case Constant.Menu.FAMILYCONNECT:
        icon = <UserAddIcon className="w-5 h-5 " />;
        break;
      case Constant.Menu.CONTACTUS:
        icon = <MobileIcon className="w-5 h-5 " />;
        break;
      case Constant.Menu.TAGS:
        icon = <TagOutlineIcon className="w-5 h-5 " />;
        break;
      case Constant.Menu.ALIENARENA:
        icon = <AlienIcon className="w-5 h-5 " />;
        break;
      case Constant.Menu.ALIENARENA_SETUP:
        icon = <SetupIcon className="w-5 h-5 " />;
        break;
      case Constant.Menu.ALIENARENA_SESSION:
        icon = <ArenaIcon className="w-5 h-5 " />;
        break;
      case Constant.Menu.TRAINING:
        icon = <TrainingIcon className="w-5 h-5 " />;
        break;
      case Constant.Menu.SUPERSHEET:
        icon = <SupersheetIcon className="w-5 h-5 " />;
        break;

      case Constant.Menu.CLASSCENTER:
        icon = <ClassCenterIcon className="w-5 h-4 " />;
        break;
      case Constant.Menu.SCHOOLCENTER:
        icon = <SchoolCenterIcon className="w-5 h-4 " />;
        break;
      case Constant.Menu.INSTRUCTIONALRESOURCES:
        icon = <InstructionalResourcesIcon className="w-5 h-5" />;
        break;
      case Constant.Menu.VIDEOMESSAGE:
        icon = <VideoMessageIcon className="w-5 h-4 " />;
        break;
      case Constant.Menu.TRAININGCENTER:
        icon = <TrainingCenterIcon className="w-5 h-4 " />;
        break;
      case Constant.Menu.REPORT:
        icon = <DocumentReportIcon className="w-5 h-5" />;
        break;
      case Constant.Menu.NWEAMAPPING:
        icon = <NweaMapping className="w-4 h-4 ml-1" />;
        break;
      case Constant.Menu.LIFTOFF_MANAGEMENT:
        icon = <RocketIcon className="w-5 h-5 fill-current" />;
        break;
      case Constant.Menu.STUDENTVIEW:
        icon = <StudentViewIcon className="w-5 h-5 fill-current" />;
        break;
      case Constant.Menu.STUDENT_SETTINGS:
        icon = <SettingsIcon className="w-5 h-5 fill-current" />;
        break;
      case Constant.Menu.CLASSROOM_GAME:
        icon = <ClassroomGameIcon className="w-5 h-5 fill-current" />;
    }
    return icon;
  }

  const handleToggleSideBar = () => {
    localStorage.setItem(
      Constant.LocalStorageKeys.ToggleSideMenu,
      !toggleSideBar + ""
    );
    setToggleSideBar(!toggleSideBar);
    setToggleClosedSubmenu(false);
  };

  const handleMenuClicked = (menuItem: any) => {
    if (menuItem.menu === "Training") {
      window.open(trainingUrl);
      return;
    }
    if (menuItem.menu === "Horizon Education") {
      window.open(Configuration.HorizonEducationUrl);
      return;
    }
    setActiveMenu(menuItem.menu);
    handleRedirection(menuItem);
    if (menuItem.submenu.length > 0 && menuItem.menu === activeMenu) {
      setToggleClosedSubmenu(!toggleClosedSubmenu);
    } else if (menuItem.submenu.length > 0) {
      setToggleClosedSubmenu(true);
    }
  };

  const linkClickHandler = (url: string, subMenu, subMenuItem) => {
    if (subMenu === "Training Center") {
      window.open(trainingUrl);
      return;
    }

    setIsSubMenuActive(true);
    if (!toggleSideBar) setToggleClosedSubmenu(false);
    setPath(url);
    if (url === RouteConstant.UserProfile) {
      history.push({
        pathname: url,
        state: { defaultTab: 2 },
      });
    } else if (subMenuItem.isPopup ?? false) {
      const parameter = {
        userContext: props.userContext,
        showPopUp: setShowPopUp,
      };
      setShowPopUp(true);
      setPopupComponent({ component: subMenuItem.route, parameter });
    } else {
      history.push({
        pathname: url,
      });
    }
  };

  const handleRedirection = (menu: any) => {
    if (menu.url !== "") {
      setIsSubMenuActive(false);
      setActiveSubMenu("");
      setPath(menu.url);
      history.push({
        pathname: menu.url,
      });
    } else if (menu.isPopup) {
    }
  };

  const determineShouldExpand = (menuItem: any) => {
    return activeMenu === menuItem.menu && menuItem.submenu.length > 0;
  };

  const isActiveMenu = (menuItem: any) => {
    return (
      menuItem.submenu.length === 0 &&
      menuItem.url !== "" &&
      path.includes(menuItem.url) &&
      !isSubMenuActive
    );
  };

  const isActiveSubMenu = (menu: any, subMenu: any) => {
    return (
      menu.submenu.length > 0 &&
      subMenu.route !== "" &&
      path.includes(subMenu.route!)
    );
  };

  const hasActiveSubmenu = (menu: any, activeSubMenu) => {
    if (menu.submenu.length > 0) {
      const submenus = menu.submenu.map((m) => m.name);
      let checkIfBelongsToDropdown: boolean = true;
      if (
        menu.menu === "School Center" &&
        props.userContext.roleId !== constant.UserRoleId.District &&
        activeSubMenu === "Student View"
      ) {
        checkIfBelongsToDropdown = false;
      }
      return submenus.includes(activeSubMenu) && checkIfBelongsToDropdown;
    }
    return false;
  };

  const hasPermission = (nav) => {
    if (
      AllowedTo({
        perform: nav.permission || "",
        asFunction: true,
      }) &&
      nav?.submenu?.length === 0
    ) {
      return true;
    } else if (nav?.submenu?.length > 0) {
      return true;
    } else if (
      AllowedTo({
        perform: nav.permission || "",
        asFunction: true,
      })
    ) {
      return true;
    } else {
      return false;
    }
  };

  const checkLiftoffAccess = (nav: any) => {
    if (nav.name === "Liftoff Management") {
      if (
        ((props.userContext.roleId === constant.UserRoleId.SchoolAdmin ||
          props.userContext.roleId === constant.UserRoleId.SchoolTeacher ||
          props.userContext.roleId === constant.UserRoleId.PayTeacher) &&
          props.profile?.isLOActive) ||
        props.userContext.roleId === constant.UserRoleId.District
      ) {
        return true;
      } else {
        return false;
      }
    }
    return true;
  };

  const checkNWEAAccess = (nav: any) => {
    if (nav.name === "NWEA Mapping") {
      return props.profile?.schoolNWEAEnabled ?? false;
    }
    return true;
  };
  const checkImpersonatedUserAccess = (nav: any) => {
    if (
      (nav.restrictImpersonatedUser ?? false) === true &&
      props.userContext?.impersonatedUser !== null
    ) {
      return false;
    }
    return true;
  };

  const checkBelongsToDropdown = (item: any, nav: any) => {
    if (
      item.menu === "School Center" &&
      props.userContext.roleId !== constant.UserRoleId.District &&
      nav.name === "Student View"
    ) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    const currentPath = location.pathname;
    if (currentPath.includes(RouteConstant.ROUTE_DASHBOARD) && activeMenu !== Constant.Menu.HOME) {
      handleMenuClicked(filteredNavMenu[0]);
    }
  }, [location.pathname]);

  return (
    <div className="shadow" ref={menuRef}>
      {popupComponent && showPopup && DynamicComponent(popupComponent)}
      {!toggleSideBar && (
        <>
          {filteredNavMenu.map((subject, i) => (
            <ReactTooltip
              key={i}
              id={subject.id.toString()}
              class="bg-secondary-teal/10 z-50 "
              place="right"
              effect="solid"
              offset={{ top: 35, left: 46 }}
              arrowColor="transparent"
              className="custom-tooltip"
            ></ReactTooltip>
          ))}

          <ReactTooltip
            id={"0"}
            class="bg-secondary-teal/10 z-50 "
            place="right"
            effect="solid"
            offset={{ top: 35, left: 46 }}
            arrowColor="transparent"
            className="custom-tooltip"
          ></ReactTooltip>

          <ReactTooltip
            id="HorizonEducation"
            class="bg-secondary-teal/10 z-50 "
            place="right"
            effect="solid"
            offset={{ top: 35, left: 46 }}
            arrowColor="transparent"
            className="custom-tooltip"
          ></ReactTooltip>
        </>
      )}

      <div
        className={`bg-white w-60 transform transition-all duration-500 text-white flex justify-center ${
          toggleSideBar ? "flex-shrink-0 sticky top-0" : " -ml-48"
        }`}
      >
        <div className="flex flex-col w-60">
          <div className="flex flex-col min-h-0">
            <div className=" flex flex-col pb-4 relative">
              <span
                title="Toggle Menu"
                className={`${
                  toggleSideBar ? "" : "transform rotate-180"
                } transition ease-linear duration-500 absolute -right-2.5 top-4 shadow-xl text-secondary-teal cursor-pointer bg-white rounded-full hover:bg-secondary-teal hover:text-white`}
              >
                <CircleLeftIcon
                  onClick={handleToggleSideBar}
                  className="w-5"
                ></CircleLeftIcon>
              </span>
              <nav className="mt-6">
                <div
                  id="div-menu"
                  className={`mt-4 ${toggleSideBar ? "mx-3" : "mr-1.5"} `}
                >
                  <div className="flex flex-col">
                    <div className="grow mb-[150px]">
                      {filteredNavMenu.map((menuItem: any, index) => (
                        <div key={index}>
                          <div className="mt-1">
                            {hasPermission(menuItem) && (
                              <Menu
                                icon={getIcon(menuItem.icon)}
                                menu={menuItem}
                                isActive={
                                  isActiveMenu(menuItem) ||
                                  hasActiveSubmenu(menuItem, activeSubMenu)
                                }
                                isExpandableMenu={menuItem?.submenu?.length > 0}
                                expandMenu={
                                  determineShouldExpand(menuItem) &&
                                  toggleClosedSubmenu
                                }
                                toggleSidebarView={toggleSideBar}
                                onClick={(e) => handleMenuClicked(e)}
                              ></Menu>
                            )}

                            {toggleSideBar &&
                              menuItem?.submenu?.length > 0 &&
                              menuItem?.submenu?.map(
                                (
                                  el: {
                                    name?: string;
                                    route?: string;
                                    icon?: string;
                                    isExternalUrl?: boolean;
                                    upgradePermission?: string;
                                  },
                                  index
                                ) => (
                                  <div key={index}>
                                    {(el.upgradePermission || hasPermission(el)) &&
                                      checkLiftoffAccess(el) &&
                                      checkNWEAAccess(el) &&
                                      checkImpersonatedUserAccess(el) &&
                                      checkBelongsToDropdown(menuItem, el) && (
                                        <UpgradeButtonControl
                                          controlType={"div"}
                                          userContext={props?.userContext}
                                          permissionName={
                                            el.upgradePermission ?? ""
                                          }
                                          className={""}
                                          onClick={() => {}}
                                        >
                                          <SubMenu
                                            icon={getIcon(el.icon ?? "")}
                                            parentMenu={menuItem}
                                            menu={el}
                                            isActive={isActiveSubMenu(menuItem, el)}
                                            expandMenu={
                                              determineShouldExpand(menuItem) &&
                                              toggleClosedSubmenu
                                            }
                                            toggleSidebarView={toggleSideBar}
                                            subMenuType={"open"}
                                            onClick={(e) => {
                                              if (
                                                !el.upgradePermission ||
                                                !constant.RolesRequireUpgrade.includes(
                                                  props.userContext.roleId
                                                )
                                              ) {
                                                linkClickHandler(
                                                  el.route ?? "",
                                                  el.name,
                                                  el
                                                );
                                              }
                                            }}
                                            setActiveMenu={(e) => {
                                              setActiveMenu(e);
                                              setActiveSubMenu(el.name!);
                                              setIsSubMenuActive(true);
                                            }}
                                          ></SubMenu>
                                        </UpgradeButtonControl>
                                      )}
                                  </div>
                                )
                              )}
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="flex-none">
                      <hr></hr>
                      <div className="mt-1"
                        data-tip={"Horizon Education"}
                        data-class="custom-tooltip"
                        data-for={"HorizonEducation"}
                      >
                        <button 
                          className={`mb-1 p-2 cursor-pointer rounded-lg transition-all hover:bg-secondary-teal/50 ${toggleSideBar ? "w-full" : "ml-auto flex"}`}
                          onClick={() => handleMenuClicked({menu: "Horizon Education"})}
                        >
                          {toggleSideBar && (<HorizonEducationLogo style={{width: "66%"}} />)}
                          {!toggleSideBar && (<HorizonEducationThumbnail style={{width: "20px", height: "20px"}} />)}
                        </button>
                      </div>
                    </div>
                  </div>

                  
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
      {!toggleSideBar &&
        filteredNavMenu.map((menuItem: any, index) => (
          <div
            key={index}
            className="check left-12 h-auto z-20 absolute w-auto cursor-pointer bg-secondary-teal rounded-r-lg text-white  shadow-lg "
            style={{
              top: `${124 + 40 * index}px`,
            }}
          >
            {menuItem?.submenu?.length > 0 &&
              menuItem?.submenu?.map(
                (
                  el: {
                    name?: string;
                    route?: string;
                    icon?: string;
                    isExternalUrl?: boolean;
                    upgradePermission?: string;
                  },
                  index
                ) => (
                  <div key={index}>
                    {(el.upgradePermission || hasPermission(el)) &&
                      checkLiftoffAccess(el) &&
                      checkBelongsToDropdown(menuItem, el) && (
                        <UpgradeButtonControl
                          controlType={"div"}
                          userContext={props?.userContext}
                          permissionName={el.upgradePermission ?? ""}
                          className={""}
                          onClick={() => {}}
                        >
                          <SubMenu
                            icon={getIcon(el.icon ?? "")}
                            parentMenu={menuItem}
                            menu={el}
                            isActive={isActiveSubMenu(menuItem, el)}
                            expandMenu={
                              determineShouldExpand(menuItem) &&
                              toggleClosedSubmenu
                            }
                            toggleSidebarView={toggleSideBar}
                            subMenuType={"close"}
                            onClick={(e) => {
                              if (
                                !el.upgradePermission ||
                                !constant.RolesRequireUpgrade.includes(
                                  props.userContext.roleId
                                )
                              ) {
                                linkClickHandler(el.route ?? "", el.name, el);
                              }
                            }}
                            setActiveMenu={(e) => {
                              setActiveMenu(e);
                              setActiveSubMenu(el.name!);
                              setIsSubMenuActive(true);
                            }}
                          ></SubMenu>
                        </UpgradeButtonControl>
                      )}
                  </div>
                )
              )}
          </div>
        ))}
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(Sidebar);

import { AxiosResponse } from "axios";
import { AxiosHelper } from "../../environment/axiosConfig";
import { SortingOrder } from "../../types/type";
import APIConstant from "../../utils/constant/apiConstant";
import { IGradeBookAssignmentReport } from "../../model/interface/report/gradeBookAssignmentReport";
import { IGradeBookClassResultReport } from "../../model/interface/report/gradeBookClassResultReport";
export const getGradeBookDataByAssignments = (
  classId: number,
  dateCompletedmin: Date | null,
  dateCompletedmax: Date | null,
  includeRemediation: boolean,
  retriesOnly: boolean
): Promise<AxiosResponse<IGradeBookAssignmentReport>> => {
  return AxiosHelper.axiosInstance().get<IGradeBookAssignmentReport>(
    APIConstant.GRADEBOOK_API.GetGradeBookDataByAssignments,
    {
      params: {
        classId: classId && classId !== 0 ? classId : null,
        dateCompletedmin: dateCompletedmin,
        dateCompletedmax: dateCompletedmax,
        includeRemediation: includeRemediation ?? false,
        retriesOnly: retriesOnly,
      },
    }
  );
};

export const exportGradeBookDataByAssignmentsExcel = (
  classId: number,
  className: string | null,
  dateCompletedmin: Date | null,
  dateCompletedmax: Date | null,
  includeRemediation: boolean,
  viewBy: number,
  retriesOnly: boolean
): Promise<AxiosResponse<Blob>> => {
  return AxiosHelper.axiosInstance().get<Blob>(
    APIConstant.GRADEBOOK_API.ExportGradeBookDataByAssignmentsExcel,
    {
      responseType: "blob",
      params: {
        classId: classId && classId !== 0 ? classId : null,
        className: className,
        dateCompletedmin: dateCompletedmin,
        dateCompletedmax: dateCompletedmax,
        includeRemediation: includeRemediation ?? false,
        viewBy: viewBy,
        retriesOnly: retriesOnly,
      },
    }
  );
};

export const getGradeBookDataByClassResults = (
  districtId: number,
  classId: string,
  subjectId: string,
  dateCompletedmin: Date | null,
  dateCompletedmax: Date | null,
  studentId: string,
  activitytype: string,
  pageNumber: number,
  pageSize: number,
  orderType: SortingOrder,
  orderByColumn: string,
  retriesOnly: boolean
): Promise<AxiosResponse<IGradeBookClassResultReport>> => {
  return AxiosHelper.axiosInstance().get<IGradeBookClassResultReport>(
    APIConstant.GRADEBOOK_API.GetGradeBookDataByClassResults,
    {
      params: {
        districtId: districtId && districtId !== 0 ? districtId : null,
        classId: classId && classId !== "0" ? classId : null,
        subjectId: subjectId && subjectId !== "0" ? subjectId : null,
        dateCompletedmin: dateCompletedmin,
        dateCompletedmax: dateCompletedmax,
        studentuserid: studentId,
        activitytype: activitytype,
        pageNumber: pageNumber,
        pageSize: pageSize,
        orderByColumn: orderByColumn,
        sortByDesc: orderType === "desc" ? true : false,
        retriesOnly: retriesOnly,
      },
    }
  );
};

export const exportGradeBookDataByClassResultsExcel = (
  districtId: number,
  classId: string,
  subjectId: string,
  dateCompletedmin: Date | null,
  dateCompletedmax: Date | null,
  studentId: string,
  activitytype: string,
  className: string | null,
  retriesOnly: boolean
): Promise<AxiosResponse<Blob>> => {
  return AxiosHelper.axiosInstance().get<Blob>(
    APIConstant.GRADEBOOK_API.ExportGradeBookDataByClassResultsExcel,
    {
      responseType: "blob",
      params: {
        districtId: districtId && districtId !== 0 ? districtId : null,
        classId: classId && classId !== "0" ? classId : null,
        subjectId: subjectId && subjectId !== "0" ? subjectId : null,
        dateCompletedmin: dateCompletedmin,
        dateCompletedmax: dateCompletedmax,
        studentuserid: studentId,
        activitytype: activitytype,
        className: className,
        retriesOnly: retriesOnly,
      },
    }
  );
};

import { call, put, takeLatest } from "redux-saga/effects";
import * as actions from "../actions/actionTypes";
import { setProfile } from "../actions/userActions";

import { AxiosHelper } from "../../environment/axiosConfig";
import APIConstant from "../../utils/constant/apiConstant";
import auth from "../../utils/auth";

const getProfile = async (userId: number, roleid: number, schoolId: number) =>  {
  const response = await AxiosHelper.axiosInstance().get(APIConstant.APP_Profile, {
    params: {
      userId,
      roleid,
      schoolId,
    },
    headers: {
      Authorization: "Bearer " + auth.getAuthToken(),
    },
  });
  return response;
};

function* fetchProfile(payload: any) {
  const { userId, roleId, schoolId } = payload;
  const res = yield call(getProfile, userId, roleId, schoolId);
  yield put(setProfile(res.data));
}

const userSaga = [takeLatest(actions.GET_PROFILE, fetchProfile)];
export default userSaga;

import { Fragment, useState } from "react";
import { CanvasIcon } from "../../assets/icons/index";
import { Configuration } from "../../environment/setup";
import Constant from "../../utils/constant/constant";
import RouteConstant from "../../utils/constant/routeConstant";
import { useHistory } from "react-router-dom";
import { Modal, ModalBody } from "@windmill/react-ui";
import {
  ShareIcon,
  ClipboardCheckIcon,
  PresentationChartLineIcon,
  BookOpenIcon,
  TranslateIcon,
  ShieldExclamationIcon,
  ChipIcon,
  UserIcon,
  AtSymbolIcon,
  PencilAltIcon,
  DocumentAddIcon,
} from "@heroicons/react/solid";

interface passedProps {
  title: string;
  description: string;
  css: string;
  url: string;
  isCanvasMapped: boolean;
}

function ManageUserCard(props: passedProps) {
  const history = useHistory();
  const [showCanvasPopup, setCanvasPopuup] = useState(false);

  function naviagateToPage(url: string, title: string) {
    if (
      title.toLocaleUpperCase() === "CANVAS MAPPING" &&
      !props.isCanvasMapped
    ) {
      setCanvasPopuup(true);
      return;
    }

    if (title === Constant.MANAGESTUDENTUri) {
      history.push({
        pathname: RouteConstant.MANAGESTUDENT,
      });
    } else {
      const navigateUrl =
        url.replace("{{baseUrl}}", Configuration.EGBaseUrl) + "?dr=true";
      history.push({
        pathname: RouteConstant.CONTAINER,
        search: "",
        state: { url: navigateUrl },
      });
    }
  }

  function getIcons(option: string) {
    switch (option.trim()) {
      case "Program Selection":
        return <ClipboardCheckIcon className="fill-current h-6 text-white" />;
      case "NWEA Mapping":
        return <ShareIcon className="fill-current h-6 text-white" />;
      case "Canvas Mapping":
        return (
          <PresentationChartLineIcon className="fill-current h-6 text-white" />
        );
      case "View Students":
        return <BookOpenIcon className="fill-current h-6 text-white" />;
      case "Language Settings":
        return <TranslateIcon className="fill-current h-6 text-white" />;
      case "Diagnostics":
        return (
          <ShieldExclamationIcon className="fill-current h-6 text-white" />
        );
      case "Skill Practice":
        return <ChipIcon className="fill-current h-6 text-white" />;
      case "Read-Aloud":
        return <ClipboardCheckIcon className="fill-current h-6 text-white" />;
      case "Avatar Usage":
        return <UserIcon className="fill-current h-6 text-white" />;
      case "EOC Subjects":
        return <PencilAltIcon className="fill-current h-6 text-white" />;
      case "Google Classroom":
        return <AtSymbolIcon className="fill-current h-6 text-white" />;
      case "Manage Students":
        return <DocumentAddIcon className="fill-current h-6 text-white" />;
    }
  }

  return (
    <Fragment>
      <div className="relative text-white pt-5 px-4 pb-2 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden group">
        <dt>
          <div className={`absolute ${props.css} rounded-md p-3`}>
            {getIcons(props.title)}
          </div>
        </dt>
        <dd className="mb-10 ml-16 pb-2 flex items-baseline sm:pb- flex-col">
          <p className="text-base font-semibold text-gray-900">{props.title}</p>
          <p className="text-xs text-gray-900 ">{props.description}</p>
        </dd>
        <div
          onClick={() => naviagateToPage(props.url, props.title)}
          className={`cursor-pointer transaction duration-300 ease-in-out absolute bottom-0 inset-x-0 bg-gray-50 px-4 py-4 sm:px-6 group-hover:text-white group-hover:${props.css}`}
        >
          <div className="text-sm">
            <span className="font-medium text-gray-900 group-hover:text-white cursor-pointer">
              {" "}
              Manage<span className="sr-only"></span>
            </span>
          </div>
        </div>
      </div>

      <Modal isOpen={showCanvasPopup} onClose={() => setCanvasPopuup(false)}>
        <ModalBody>
          <div>
            <div className="flex space-x-2 items-start">
              <div className="w-20 pt-0.5">
                <CanvasIcon></CanvasIcon>
              </div>
              <div>
                <div className="title text-left text-sm">
                  Canvas Mapping requires an account that is linked with Canvas.
                  To link with Canvas, you will need to add the Education Galaxy
                  Application in your Canvas Course Settings. Once added, you
                  can add Education Galaxy as an External Tool to complete the
                  mapping process.
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </Fragment>
  );
}

export default ManageUserCard;

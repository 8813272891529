import { AxiosHelper } from "../../environment/axiosConfig";
import APIConstant from "../../utils/constant/apiConstant";
import { getTimezoneOffset } from "../../utils/helper";

export const getAssessmentResultsByStudent = (
  districtId: number,
  subjectId: number,
  schoolId: number,
  teacherUserId: number,
  assessmentId: string,
  selectedSchoolYearId: number,
  startDate: Date | null,
  endDate: Date | null,
  isPremiumAssessment?: boolean
) => {
  return AxiosHelper.axiosInstance().get(
    APIConstant.ASSESSMENTRESULTSREPORT_API.GetAssessmentResultsByStudent,
    {
      params: {
        districtId: districtId,
        subjectId: subjectId,
        schoolId,
        teacherUserId,
        assessmentId,
        schoolYearId: selectedSchoolYearId,
        isPremiumAssessment: isPremiumAssessment ?? false,
        startDate: startDate,
        endDate: endDate,
      },
    }
  );
};

export const exportAssessmentResultsByStudentToExcel = (
  districtId: number,
  subjectId: number,
  schoolId: number,
  teacherUserId: number,
  assessmentIds: string,
  premiumAssessmentIds: string,
  schoolYearId: number,
  startDate: Date,
  endDate: Date,
  viewByScore: boolean = false
) => {
  return AxiosHelper.axiosInstance().get(
    APIConstant.ASSESSMENTRESULTSREPORT_API
      .ExportAssessmentResultsByStudentToExcel,
    {
      responseType: "blob",
      params: {
        districtId: districtId,
        subjectId: subjectId,
        schoolId,
        teacherUserId,
        assessmentIds,
        timezoneOffset: getTimezoneOffset(),
        premiumAssessmentIds: premiumAssessmentIds,
        schoolYearId: schoolYearId,
        startDate: startDate,
        endDate: endDate,
        viewByScore: viewByScore
      },
    }
  );
};

export const exportAssessmentResultsByStudentToCSV = (
  districtId: number,
  subjectId: number,
  schoolId: number,
  teacherUserId: number,
  assessmentIds: string
) => {
  return AxiosHelper.axiosInstance().get(
    APIConstant.ASSESSMENTRESULTSREPORT_API
      .ExportAssessmentResultsByStudentToCSV,
    {
      responseType: "blob",
      params: {
        districtId: districtId,
        subjectId: subjectId,
        schoolId,
        teacherUserId,
        assessmentIds,
      },
    }
  );
};

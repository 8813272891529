import submarine from "../../../../../assets/images/thinkorswim/submarine.png";

interface ISubmarineButtonProps {
  text: string,
  onClick?: () => void
}

function SubmarineButton(props: ISubmarineButtonProps) {
  return (
    <div
      className="cursor-pointer relative w-full text-[2vw] hover:text-[2.1vw]"
      onClick={props.onClick}
    >
      <div className="absolute top-[88%] left-[20%] w-full -translate-y-1/2">
        <p
          className="text-black text-center"
        >
          {props.text}
        </p>
      </div>
      <img
        className="mx-auto"
        src={submarine}
        alt="submarine button"
      />
    </div>
  )
}

export default SubmarineButton

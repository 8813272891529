import { GoogleClassroomConfiguration } from "../environment/setup";
import { ILoginState, ICredentials } from "../model/googleLogin/loginState";
import { Buffer } from "buffer";
import { IAssignment } from "../model/interface/assignment";
const promptType = {
  Login: "login",
  Consent: "consent",
};
export function handleLogin(
  type,
  returnUrl: string,
  allowAuthorization?: boolean
) {
  if (type === "Student") {
    const state: ILoginState = {
      IsStudent: true,
      IsTeacher: false,
      AssignmentId: 0,
      AssignmentActivityId: 0,
      ReturnUrl: returnUrl,
      autoMaping: false,
      AccountLoginName: null,
      Credentials: null,
    };
    const signInUrl = GoogleClassroomConfiguration.GoogleSignInUrl.replace(
      "{:redirectUrl}",
      GoogleClassroomConfiguration.ReturnUrl
    )
      .replace(
        "{:prompt}",
        allowAuthorization ? promptType.Consent : promptType.Login
      )
      .replace("{:clientId}", GoogleClassroomConfiguration.GoogleClientId)
      .replace("{:scope}", GoogleClassroomConfiguration.StudentScopes)
      .replace(
        "{:state}",
        encodeURI(Buffer.from(JSON.stringify(state)).toString("base64"))
      );

    window.location.href = signInUrl;
  } else {
    const state: ILoginState = {
      IsStudent: false,
      IsTeacher: true,
      AssignmentId: 0,
      AssignmentActivityId: 0,
      ReturnUrl: returnUrl,
      autoMaping: false,
      AccountLoginName: null,
      Credentials: null,
    };
    const signInUrl = GoogleClassroomConfiguration.GoogleSignInUrl.replace(
      "{:redirectUrl}",
      GoogleClassroomConfiguration.ReturnUrl
    )
      .replace(
        "{:prompt}",
        allowAuthorization ? promptType.Consent : promptType.Login
      )
      .replace("{:clientId}", GoogleClassroomConfiguration.GoogleClientId)
      .replace("{:scope}", GoogleClassroomConfiguration.TeacherScopes)
      .replace(
        "{:state}",
        encodeURI(Buffer.from(JSON.stringify(state)).toString("base64"))
      );

    window.location.href = signInUrl;
  }
}

export function handleLoginWithCredentials(
  type,
  returnUrl: string,
  accountLoginName: string,
  username: string,
  passwordHash: string
) {
  const credential: ICredentials = {
    AccountLoginName: accountLoginName,
    Username: username,
    PassowrdHash: passwordHash,
  };

  if (type === "Student") {
    const state: ILoginState = {
      IsStudent: true,
      IsTeacher: false,
      AssignmentId: 0,
      AssignmentActivityId: 0,
      ReturnUrl: returnUrl,
      autoMaping: true,
      AccountLoginName: accountLoginName,
      Credentials: credential,
    };
    const signInUrl = GoogleClassroomConfiguration.GoogleSignInUrl.replace(
      "{:redirectUrl}",
      GoogleClassroomConfiguration.ReturnUrl
    )
      .replace("{:prompt}", promptType.Consent)
      .replace("{:clientId}", GoogleClassroomConfiguration.GoogleClientId)
      .replace("{:scope}", GoogleClassroomConfiguration.StudentScopes)
      .replace(
        "{:state}",
        encodeURI(Buffer.from(JSON.stringify(state)).toString("base64"))
      );

    window.location.href = signInUrl;
  } else {
    const state: ILoginState = {
      IsStudent: false,
      IsTeacher: true,
      AssignmentId: 0,
      AssignmentActivityId: 0,
      ReturnUrl: returnUrl,
      autoMaping: true,
      AccountLoginName: accountLoginName,
      Credentials: credential,
    };
    const signInUrl = GoogleClassroomConfiguration.GoogleSignInUrl.replace(
      "{:redirectUrl}",
      GoogleClassroomConfiguration.ReturnUrl
    )
      .replace("{:prompt}", promptType.Consent)
      .replace("{:clientId}", GoogleClassroomConfiguration.GoogleClientId)
      .replace("{:scope}", GoogleClassroomConfiguration.TeacherScopes)
      .replace(
        "{:state}",
        encodeURI(Buffer.from(JSON.stringify(state)).toString("base64"))
      );

    window.location.href = signInUrl;
  }
}

export function validateSyncGoogleAssignment(
  assignment: IAssignment,
  selectedStartDate: Date | undefined,
  selectedDueDate: Date | undefined,
  toast: any
) {
  let hasError: boolean = false;
  const startDate = selectedStartDate
    ? selectedStartDate!.toDateString()
    : null;
  const dueDate = selectedDueDate ? selectedDueDate!.toDateString() : null;
  if (assignment.students && assignment.students.length === 0) {
    toast.error("Please assign the assignment to students");
    hasError = true;
  } else if (startDate === null && dueDate === null) {
    toast.error("Start date and due date cannot be empty.");
    hasError = true;
  } else if (startDate === null && dueDate) {
    toast.error("Start date cannot be empty.");
    hasError = true;
  } else if (startDate && dueDate === null) {
    toast.error("Due date cannot be empty.");
    hasError = true;
  } else if (selectedDueDate! < new Date()) {
    toast.error("Due date must be in the future.");
    hasError = true;
  }
  return hasError;
}

import { connect } from "react-redux";

const ActivityEL = (props: any) => {
  return <></>;
};

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(ActivityEL);

import { useEffect, useState } from "react";
import {
  addRemediation,
  getActivityDetail,
  getRemediatedStudents,
} from "../../../../../../api/teacher/assignment";
import { DownArrowIcon, Info, UpArrow } from "../../../../../../assets/icons";
import { IStudent } from "../../../../../../model/interface/student";
import StudentRemediation from "../../../../../../model/teacher/assignment/studentRemediation";
import DotRank from "../../../../../shared/widgets/dotRank";
import AssignmentRemediation from "../../../assignment/remediation/popup/assignmentRemediation";
import Constant from "../../../../../../utils/constant/constant";
import Loader from "../../../../../shared/loader";
import { isPermissionExist } from "../../../../../../utils/helper";
import Permissions from "../../../../../../utils/permissions";
import routeConstant from "../../../../../../utils/constant/routeConstant";
import { IActivityDetail } from "../../../../../../model/interface/activityDetail";
import InfoMessage from "../../../../../shared/infoMessage";
import classNames from "classnames";
import {
  getAssessmentResultReportByStudentExcel,
  getAssignmentResultReportByStudentExcel,
  getPremiumAssessmentResultReportByStudentExcel,
} from "../../../../../../api/report/resultsReport";
import ReactTooltip from "react-tooltip";
import permissions from "../../../../../../utils/permissions";
import ExportControl from "../../../../../shared/customButtonControl";
import { getCssForDisabled } from "../../../../../shared/permissionHelper";
import RemediationButtonControl from "../../../../../shared/customButtonControl";
import constant from "../../../../../../utils/constant/constant";
import {IStandard} from "../../../../../../model/interface/standard";
import { useStore } from "react-redux";

export default function ResultsReportByStudentByStandard(props: any) {
  const [resultsReportByStudentStandard, setResultsReportByStudentStandard] =
    useState<any>();
  const [showRemediationPopup, setShowRemediationPopup] =
    useState<boolean>(false);
  const redirectedFrom = props.redirectedFrom;
  const [students, setStudents] = useState<Array<IStudent>>([]);
  const [remediatedStudents, setRemediatedStudents] = useState<Array<IStudent>>(
    []
  );
  const [loading, setLoading] = useState(false);
  const [activityDetail, setActivityDetail] = useState<IActivityDetail>();
  const [totalStandardsCount, setTotalStandardsCount] = useState(0);
  const isDistrictUser: boolean = props.userContext.roleId === constant.UserRoleId.District;
  const store = useStore();
  
  const currentSchoolYear = store.getState().profile?.currentSchoolYear?.schoolYearId;
  const isCurrentSchoolYearSelected = props.schoolYearId === currentSchoolYear;

  useEffect(() => {
    bindActivityDetail();
    setAllStudentsSummary();
    setVisibilityForStudents();
    setVisibilityForSubjects();
    setResultsReportByStudentStandard(props.data);
    const stdCount = getTotalStandardsCount(props.data.activity);
    setTotalStandardsCount(stdCount);
  }, []);

  function bindRemediatedStudents() {
    getRemediatedStudents(props.activityId).then((response) =>
      setRemediatedStudents(response.data.data)
    );
  }

  function bindActivityDetail() {
    getActivityDetail(props.activityId).then((response) => {
      const activityDetail: IActivityDetail = response.data;
      if (
        activityDetail.activityType ===
          Constant.AssignmentActivityType.ASSESSMENT ||
        activityDetail.activityType ===
          Constant.AssignmentActivityType.PREBUILTTESTS
      ) {
        bindRemediatedStudents();
      }
      setActivityDetail(activityDetail);
    });
  }

  const setAllStudentsSummary = () => {
    if (
      props.data.students &&
      props.data.students.filter((x) => x.name === "Student Average").length ===
        0
    ) {
      let allStudentsScore: any = {
        questionCount: 0,
        studentCount: 0,
        questionsAttempted: 0,
        questionsCorrect: 0,
        classes: [],
        pctCorrect: [],
        pointsEarned: [],
        index: 0,
        id: 0,
        name: "Student Average",
      };

      //Actvity Total
      allStudentsScore.questionsAttempted =
        props.data?.activity.questionsAttempted;
      allStudentsScore.questionsCorrect = props.data?.activity.questionsCorrect;
      allStudentsScore.pointsEarned.push(props.data?.activity.pointsEarned);
      allStudentsScore.pctCorrect.push(
        getPercentage(
          props.data?.activity.pointsEarned,
          props.data?.activity.pointsPossible,
          props.data?.activity.questionsAttempted
        )
      );

      props.data?.activity.subjects?.forEach((subject) => {
        allStudentsScore.questionsAttempted = subject.questionsAttempted;
        allStudentsScore.questionsCorrect = subject.questionsCorrect;
        allStudentsScore.pointsEarned.push(subject.pointsEarned);
        allStudentsScore.pctCorrect.push(
          getPercentage(
            subject.pointsEarned,
            subject.pointsPossible,
            subject.questionsAttempted
          )
        );

        subject.domains?.forEach((domain) => {
          allStudentsScore.pointsEarned.push(domain.pointsEarned);
          allStudentsScore.pctCorrect.push(
            getPercentage(
              domain.pointsEarned,
              domain.pointsPossible,
              domain.questionsAttempted
            )
          );

          domain.standards?.forEach((standard) => {
            allStudentsScore.pointsEarned.push(standard.pointsEarned);
            allStudentsScore.pctCorrect.push(
              getPercentage(
                standard.pointsEarned,
                standard.pointsPossible,
                standard.questionsAttempted
              )
            );
          });
        });
      });
      props.data?.students?.forEach((student) => {
        allStudentsScore.questionCount += student.questionCount;
        allStudentsScore.studentCount += student.studentCount;
      });

      props.data.students = [allStudentsScore, ...props.data.students];
    }
  };

  const getPercentage = (pointsEarned, pointsPossible, questionsAttempted) => {
    if (
      pointsEarned === null ||
      pointsPossible === null
      // || questionsAttempted < 5
    )
      return null;

    return pointsPossible === 0 ? 0 : pointsEarned / pointsPossible;
  };

  const handleStudentResultViewClick = (
    assignmentId,
    activityId,
    userId,
    gradeId,
    sessionId
  ) => {
    const pathname =
      routeConstant.TeacherRoutes.StudentAssignmentResult.replace(
        ":assignmentId",
        assignmentId
      )
        .replace(":assignmentActivityId", activityId)
        .replace(":userId", userId)
        .replace(":gradeId", gradeId ?? 0)
        .replace(":sessionId?", sessionId);
    window.open(pathname, "_blank");
  };

  const setVisibilityForStudents = () => {
    props.data?.students?.forEach((student, i) => {
      student["isCollapse"] = true;
      student.visibility = [];
      if (i !== 0) student.pctCorrect = [];
      student?.pointsEarned?.forEach((pct, j) => {
        //Hide Subject
        if (j === 1) student.visibility.push(false);
        else student.visibility.push(true);

        if (i !== 0) {
          student.pctCorrect.push(
            getPercentage(
              student?.pointsEarned[j],
              student?.pointsPossible[j],
              student?.questionsAttempted[j]
            )
          );
        }
      });
    });
  };

  const setVisibilityForSubjects = () => {
    let index = 0;
    let visibility: any = [];
    let contentTypes: any = [];
    visibility.push(true);
    contentTypes.push(0);
    index = index + 1;
    let activity = props.data?.activity;
    activity["isCollapse"] = false;

    props.data?.activity.subjects?.forEach((subject) => {
      let subjectChild = 0;
      subject["index"] = index;
      subject["isCollapse"] = false;
      visibility.push(false);
      contentTypes.push(0);
      index = index + 1;
      subject.domains?.forEach((domain) => {
        let domaintChild = 0;
        domain["index"] = index;
        domain["isCollapse"] = false;
        subjectChild = subjectChild + 1;
        visibility.push(true);
        contentTypes.push(1);
        index = index + 1;
        domain.standards?.forEach((standard) => {
          standard["displayName"] = "(" + standard.code + ") " + standard.name;
          standard["index"] = index;
          standard["isCollapse"] = false;
          subjectChild = subjectChild + 1;
          domaintChild = domaintChild + 1;
          visibility.push(true);
          contentTypes.push(2);
          index = index + 1;
        });
        domain["childCount"] = domaintChild;
      });
      subject["childCount"] = subjectChild;
    });

    if (props.data) {
      props.data.visibility = visibility;
      props.data.contentTypes = contentTypes;
    }
  };

  const handleHorizontalCollapse = (entity, value, entityType) => {
    entity.isCollapse = value;
    if (entityType === "ACTIVITY") {
      resultsReportByStudentStandard.visibility[entity.index] = true;
      entity.subjects.forEach((subject) => {
        if (entity.isCollapse && !value)
          resultsReportByStudentStandard.visibility[subject.index] = false;
        else resultsReportByStudentStandard.visibility[subject.index] = !value;

        subject.domains.forEach((domain) => {
          if (subject.isCollapse)
            resultsReportByStudentStandard.visibility[domain.index] = false;
          else resultsReportByStudentStandard.visibility[domain.index] = !value;

          domain.standards.forEach((standard) => {
            if ((subject.isCollapse || domain.isCollapse) && !value)
              resultsReportByStudentStandard.visibility[standard.index] = false;
            else
              resultsReportByStudentStandard.visibility[standard.index] =
                !value;
          });
        });
      });
    } else if (entityType === "SUBJECT") {
      resultsReportByStudentStandard.visibility[entity.index] = true;
      entity.domains.forEach((domain) => {
        resultsReportByStudentStandard.visibility[domain.index] = !value;
        domain.standards.forEach((standard) => {
          if (domain.isCollapse && !value)
            resultsReportByStudentStandard.visibility[standard.index] = false;
          else
            resultsReportByStudentStandard.visibility[standard.index] = !value;
        });
      });
    } else if (entityType === "DOMAIN") {
      entity.standards.forEach((standard) => {
        resultsReportByStudentStandard.visibility[standard.index] = !value;
      });
    }

    resultsReportByStudentStandard?.students?.forEach((student) => {
      student?.pctCorrect?.forEach((pct, index) => {
        //Hide Subject
        if (index !== 1)
          student.visibility[index] =
            resultsReportByStudentStandard.visibility[index];
      });
    });

    let _resultsReportByStudentStandard = {
      ...resultsReportByStudentStandard,
    };
    setResultsReportByStudentStandard(_resultsReportByStudentStandard);
    const stdCount = getTotalStandardsCount(_resultsReportByStudentStandard.activity);
    setTotalStandardsCount(stdCount);
  };

  function getClassId(studentId: number) {
    let classId: number = 0;
    const student: IStudent | undefined = remediatedStudents.find(
      (r) => r.studentId === studentId
    );
    if (student && student.classId) {
      classId = student.classId;
    }

    return classId;
  }

  function assignRemediation(
    students: Array<IStudent>,
    numberOfWeakAreas: number,
    startDate: Date,
    endDate: Date
  ) {
    const studentRemediations: Array<StudentRemediation> = [];
    const reportStandardsIndex: any = Object.fromEntries(
      resultsReportByStudentStandard.activity.subjects[0].domains.map((domain) => {
            return domain.standards.map((standard) => [standard.id, {id: standard.id, name: standard.name}]);
          }
      ).flat(1));
    const reportStudentsIndex = Object.fromEntries(resultsReportByStudentStandard.students.map(s => [s.id, s]));
    const selectedReportStudents = students.map(s => reportStudentsIndex[s.studentId]);

    selectedReportStudents.map((student) => {
      if(student.remediationStandards== null)
        return;

      const standardIds: Array<number> = student.remediationStandards.slice(0, numberOfWeakAreas);
      const standards: Array<IStandard> = standardIds.map(id => reportStandardsIndex[id]);

      const studentRemediation: StudentRemediation = new StudentRemediation(
          student.id,
          student.name,
          getClassId(student.id),
          standards
      );
      studentRemediations.push(studentRemediation);
    });

    setLoading(true);

    const selectedStartDate = startDate.toISOString().slice(0, -1);
    const selectedDueDate = new Date(
      endDate.getFullYear(),
      endDate.getMonth(),
      endDate.getDate(),
      23,
      59,
      59
    )
      .toISOString()
      .slice(0, -1);

    addRemediation(
      props.teacherId,
      Constant.RemediationSource.ASSIGNMENT,
      props.subjectId,
      false,
      studentRemediations.filter((r) => r.standards.length > 0),
      selectedStartDate,
      selectedDueDate,
      parseInt(props.activityId),
      activityDetail && activityDetail.assessmentId > 0
        ? activityDetail.assessmentId
        : undefined,
      activityDetail?.assignmentName
    ).then(() => {
      setLoading(false);
      setShowRemediationPopup(false);
      bindRemediatedStudents();
    });
  }

  function isAdditionalOrAllClass() {
    const allClasses = props.assignmentClasses;
    const selectedClass = props.selectedClasses[0];

    if (selectedClass.value === "0") {
      return true;
    }

    let isAdditionalClass: boolean = false;

    isAdditionalClass = allClasses.some(
      (r) => r.classId === selectedClass.value && r.isAdditionalClass
    );

    return isAdditionalClass;
  }
  const exportToExcel = () => {
    setLoading(true);
    if (redirectedFrom === constant.RedirectedFrom.MYASSESSMENTS) {
      getAssessmentResultReportByStudentExcel(
        props.activityId,
        props.schoolId === 0 ? null : props.schoolId,
        String(props.classIds) === "0" ? props.ClassIds : String(props.classIds),
        props.schoolYearId
      )
        .then((r) => {
          setLoading(false);
          if (r.data) {
            const file = r.data;
            const fileURL = URL.createObjectURL(file);
            const link = document.createElement("a");
            link.href = fileURL;
            link.download = "resultschooldotrank.xlsx";
            link.setAttribute("target", "_blank");
            document.body.appendChild(link);
            link.click();
          } else {
            console.log("error while downloading file");
          }
        })
        .catch(() => {
          setLoading(false);
        });
    } else if (redirectedFrom === constant.RedirectedFrom.PREMIUMASSESSMENTS) {
      getPremiumAssessmentResultReportByStudentExcel(
        props.schoolYearId,
        props.activityId,
        props.schoolId === 0 ? null : props.schoolId,
        String(props.classIds) === "0" ? null : props.classIds.split(",")
      ).then((r) => {
          setLoading(false);
          if (r.data) {
            const file = r.data;
            const fileURL = URL.createObjectURL(file);
            const link = document.createElement("a");
            link.href = fileURL;
            link.download = "ResultStudentDotRank.xlsx";
            link.setAttribute("target", "_blank");
            document.body.appendChild(link);
            link.click();
          } else {
            console.log("error while downloading file");
          }
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      getAssignmentResultReportByStudentExcel(
        props.activityId,
        props.schoolId === 0 ? null : [props.schoolId],
        props.classIds == null ? [] : String(props.classIds).split(',').map(Number),
        props.schoolYearId,
      )
        .then((r) => {
          setLoading(false);
          if (r.data) {
            const file = r.data;
            const fileURL = URL.createObjectURL(file);
            const link = document.createElement("a");
            link.href = fileURL;
            link.download = "ResultStudentDotRank.xlsx";
            link.setAttribute("target", "_blank");
            document.body.appendChild(link);
            link.click();
          } else {
            console.log("error while downloading file");
          }
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };
  function handleRemediationClick() {
    setShowRemediationPopup(true);
    let studentResults: Array<IStudent> = [];
    resultsReportByStudentStandard.students.map((student) => {
      if(student.remediationStandards?.length > 0) {
        studentResults.push({
          studentId: student.id,
          name: student.name,
          isAssigned: remediatedStudents.some(
            (r) => r.studentId === student.id && r.hasRemediated
          ),
        });
      }
    });

    setStudents(studentResults);
  }

  const renderDotRank = (index: number, pct: number | null): JSX.Element => {
    if (!props.hasCRActivity || (props.hasCRActivity && pct != null)) {
      return (
        <DotRank
          displayScore={true}
          value={!pct && pct !== 0 ? undefined : Math.round(pct * 100)}
        ></DotRank>
      );
    }
    if (props.hasCRActivity && pct == null && index > 0) {
      return <div className="text-xs text-gray-500">NG</div>;
    }
    return <div className="text-xs text-gray-500">&nbsp;</div>;
  };

  const getTotalStandardsCount = (activity) => {
    // Start with one for the top-level assessment name
    let totalCount = 1;

    // Add count of subjects
    totalCount += activity.subjects.length;

    // Add count of domains and standards within each subject
    activity.subjects.forEach(subject => {
      // Add domain count
      totalCount += subject.domains.length;

      // Add standards count
      subject.domains.forEach(domain => {
        totalCount += domain.standards.length;
      });
    });

    return totalCount;
  };

  return (
    <>
      {loading && <Loader />}

      <ReactTooltip
        id={"noResult"}
        className="bg-secondary-teal"
        backgroundColor="#008fbb"
      ></ReactTooltip>
      <div className="flex">
        <ExportControl
          controlType={"button"}
          controlText={"Export"}
          userContext={props.userContext}
          permissionName={permissions.reports_export_upgrade}
          className={
            "cursor-pointer mt-2 ml-3 leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 text-sm text-white border border-transparent bg-primary-green focus:shadow-outline-purple w-auto" +
            getCssForDisabled(
              permissions.reports_export_upgrade,
              " bg-primary-green "
            )
          }
          onClick={exportToExcel}
        ></ExportControl>
        {(redirectedFrom === constant.RedirectedFrom.MYASSIGNMENTS ||
          redirectedFrom === constant.RedirectedFrom.MYASSESSMENTS ||
          props.redirectedFrom ===
            constant.RedirectedFrom.PREMIUMASSESSMENTS) && (
          <div className="text-sm text-white font-light bg-secondary-teal px-4 py-2 mr-auto mt-2 ml-3 leading-5 flex overflow-hidden">
            <div className="flex-shrink-0 current-fill mt-0.5 ">
              <Info />
            </div>
            <div className="pl-2">
              {redirectedFrom === constant.RedirectedFrom.MYASSIGNMENTS &&
                "Your assignment results will show the student's best result. You can access details on each attempt by searching in the Class Results tab of your Gradebook."}
              {(redirectedFrom === constant.RedirectedFrom.MYASSESSMENTS ||
                props.redirectedFrom ===
                  constant.RedirectedFrom.PREMIUMASSESSMENTS) &&
                "Your assessment results include the average across all attempts each student has made. You can view the individual attempts by exporting the assessment results under the Actions column in your assessment bank." +
                (!isDistrictUser ? " If you would like to assign remediation for this assessment, you can do so from the assignment results within the assignment bank." : "")}
            </div>
          </div>
        )}
      </div>
      {(isPermissionExist(Permissions.remediations_view) ||
        isPermissionExist(Permissions.remediations_upgrade)) &&
        activityDetail &&
        (activityDetail.activityType ===
          Constant.AssignmentActivityType.ASSESSMENT ||
          activityDetail.activityType ===
            Constant.AssignmentActivityType.PREBUILTTESTS) && (
          <div
            className="flex flex-col gap-x-4 mt-3"
            title={`${
              isAdditionalOrAllClass()
                ? "Single click remediation is available for your assigned classes only and only when a single class has been selected in the filter."
                : ""
            }`}
          >
            {props.redirectedFrom !== constant.RedirectedFrom.MYASSESSMENTS && (
              <>
                <div className="flex flex-col sm:flex-row w-full gap-x-4">
                  <RemediationButtonControl
                    controlType={"button"}
                    controlText={"Assign Remediation"}
                    userContext={props.userContext}
                    permissionName={permissions.remediations_upgrade}
                    className={
                      `${
                        isAdditionalOrAllClass() || !isCurrentSchoolYearSelected
                          ? "pointer-events-none opacity-50 mb-auto mt-2 "
                          : "mb-3 "
                      }cursor-pointer bg-transparent border border-primary-violet shadow-sm py-2 px-4 flex justify-center text-sm font-medium text-primary-violet hover:text-white hover:bg-light-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition ease-in-out duration-500` +
                      getCssForDisabled(
                        permissions.remediations_upgrade,
                        " hover:bg-transparent hover:text-primary-violet opacity-50 mb-auto mt-2 "
                      )
                    }
                    onClick={handleRemediationClick}
                  ></RemediationButtonControl>
                  {isAdditionalOrAllClass() && isCurrentSchoolYearSelected && (
                    <div className="relative mt-2 sm:-mt-4">
                      <InfoMessage message="Single click remediation is available for your assigned classes only and only when a single class has been selected in the filter." />
                    </div>
                  )}
                  {!isCurrentSchoolYearSelected && (
                    <div className="relative mt-2 sm:-mt-4">
                      <InfoMessage message="Quick click remediation is unavailable for results from previous school years." />
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        )}

      {showRemediationPopup && (
        <AssignmentRemediation
          togglePopup={setShowRemediationPopup}
          students={students}
          assignRemediation={assignRemediation}
        />
      )}
      {resultsReportByStudentStandard && (
        <div className="pt-56 overflow-x-auto pl-1">
          <table className="border-b border-primary-violet">
            <tr className="border-b border-primary-violet">
              <th className="py-1 text-left pl-2 bg-white sticky left-[-5px] z-30">
                <span className="text-primary-violet text-base font-semibold">
                  Student
                </span>
              </th>
              <th className="py-1 text-left pl-2 bg-white sticky left-[-5px] z-30 whitespace-nowrap">
                <span className="text-primary-violet text-base font-semibold">
                  Student ID
                </span>
              </th>
              <th
                className="relative"
                title={resultsReportByStudentStandard.activity?.name}
              >
                <p className="w-[271px] absolute top-[-5.25rem] left-[-0.75rem] transform -rotate-45 whitespace-nowrap flex items-center text-md font-bold">
                  {resultsReportByStudentStandard.activity?.name?.length > 40
                    ? resultsReportByStudentStandard.activity?.name?.substring(
                        0,
                        40
                      ) + "..."
                    : resultsReportByStudentStandard.activity?.name}
                  <div
                    onClick={() =>
                      handleHorizontalCollapse(
                        resultsReportByStudentStandard.activity,
                        !resultsReportByStudentStandard.activity.isCollapse,
                        "ACTIVITY"
                      )
                    }
                  >
                    {!resultsReportByStudentStandard.activity.isCollapse && (
                      <UpArrow className="w-5 mr-2 cursor-pointer text-green-600" />
                    )}
                    {resultsReportByStudentStandard.activity.isCollapse && (
                      <DownArrowIcon className="w-5 mr-2 cursor-pointer text-green-600" />
                    )}
                  </div>
                </p>
              </th>

              {!resultsReportByStudentStandard.activity.isCollapse &&
                resultsReportByStudentStandard.activity?.subjects?.map(
                  (subject, subjectIndex) => (
                    <>
                      {/* <th className="relative" title={subject.name}>
                        <p className="w-[271px] absolute z-[10] top-[-5.25rem] left-[-0.75rem] transform -rotate-45 whitespace-nowrap flex items-center text-sm font-bold">
                          {subject.name.length > 40
                            ? subject.name.substring(0, 40) + "..."
                            : subject.name}
                          <div
                            onClick={() =>
                              handleHorizontalCollapse(
                                subject,
                                !subject.isCollapse,
                                "SUBJECT"
                              )
                            }
                          >
                            {!subject.isCollapse && (
                              <UpArrow className="w-5 mr-2 cursor-pointer text-blue-600" />
                            )}
                            {subject.isCollapse && (
                              <DownArrowIcon className="w-5 mr-2 cursor-pointer text-blue-600" />
                            )}
                          </div>
                        </p>
                      </th> */}

                      {!resultsReportByStudentStandard.activity.isCollapse &&
                        !subject.isCollapse &&
                        subject.domains.map((domain, domainIndex) => (
                          <>
                            <th className="relative" title={domain.name}>
                              <p className="w-[271px] absolute top-[-5.25rem] left-[-0.75rem] transform -rotate-45 whitespace-nowrap flex items-center text-sm font-medium">
                                {domain.name.length > 40
                                  ? domain.name.substring(0, 40) + "..."
                                  : domain.name}
                                <div
                                  onClick={() =>
                                    handleHorizontalCollapse(
                                      domain,
                                      !domain.isCollapse,
                                      "DOMAIN"
                                    )
                                  }
                                >
                                  {!domain.isCollapse && (
                                    <UpArrow className="w-5 mr-2 cursor-pointer text-red-600" />
                                  )}
                                  {domain.isCollapse && (
                                    <DownArrowIcon className="w-5 mr-2 cursor-pointer text-red-600" />
                                  )}
                                </div>
                              </p>
                            </th>

                            {!resultsReportByStudentStandard.activity
                              .isCollapse &&
                              !subject.isCollapse &&
                              !domain.isCollapse &&
                              domain.standards.map(
                                (standard, standardIndex) => (
                                  <th
                                    className="relative"
                                    title={standard.displayName}
                                  >
                                    <p className="w-[271px] absolute top-[-5.25rem] left-[-0.75rem] transform -rotate-45 whitespace-nowrap flex items-center text-xs font-normal">
                                      {standard.displayName.length > 40
                                        ? standard.displayName.substring(
                                            0,
                                            40
                                          ) + "..."
                                        : standard.displayName}
                                    </p>
                                  </th>
                                )
                              )}
                          </>
                        ))}
                    </>
                  )
                )}
            </tr>
            {resultsReportByStudentStandard.students.map(
              (student, studentIndex) => (
                <>
                  <tr
                    className={
                      "border-l border-r border-primary-violet " +
                      (studentIndex % 2 !== 0 ? "bg-primary-violet/10" : "")
                    }
                  >
                    <td className="whitespace-nowrap bg-white sticky left-[-5px] z-30">
                      <div
                        className={classNames(
                          studentIndex === 0 ? "font-semibold " : "",
                          props.redirectedFrom !==
                            constant.RedirectedFrom.MYASSESSMENTS &&
                            props.redirectedFrom !==
                              constant.RedirectedFrom.PREMIUMASSESSMENTS &&
                            studentIndex !== 0 &&
                            student.pctCorrect.length > 0
                            ? "underline cursor-pointer text-blue-600 "
                            : "",
                          "flex items-center justify-start w-full  px-2 "
                        )}
                        onClick={() => {
                          if (
                            props.redirectedFrom !==
                              constant.RedirectedFrom.MYASSESSMENTS &&
                            props.redirectedFrom !==
                              constant.RedirectedFrom.PREMIUMASSESSMENTS &&
                            student.pctCorrect.length > 0
                          ) {
                            handleStudentResultViewClick(
                              props.assignmentId,
                              props.activityId,
                              student.id,
                              student.gradeId,
                              student.sessionId
                            );
                          }
                        }}
                      >
                        {student.pctCorrect.length == 0 && (
                          <p
                            title={
                              "This student has not completed the assignment."
                            }
                            data-tip={
                              "This student has not completed the assignment."
                            }
                            data-for={"noResult"}
                          >
                            {student.name}
                          </p>
                        )}
                        {student.pctCorrect.length > 0 && student.name}
                      </div>
                    </td>
                    <td className="whitespace-nowrap bg-white sticky text-center z-30">
                      <p>{student.studentId}</p>
                    </td>

                    {student.pctCorrect.length 
                      ? student.pctCorrect.map((pct, index) =>
                          student.visibility[index] && (
                            <td
                              className={
                                "text-center p-3 text-sm font-normal " +
                                (props.data.contentTypes &&
                                props.data.contentTypes[index] === 1
                                  ? "border-l border-gray-500"
                                  : "")
                              }
                            >
                              {renderDotRank(studentIndex, pct)}
                            </td>
                          )
                        )
                      : props.hasCRActivity // If there is no data and we are in CR, render will NULL por points
                        ? [...Array(totalStandardsCount)].map((_, index) => (                                         
                          index !== 0 ? (
                            <td
                            key={index}
                            className={
                              "text-center p-3 text-sm font-normal " +
                              (props.data.contentTypes && props.data.contentTypes[index] === 1
                                ? "border-l border-gray-500"
                                : "")
                            }
                          >
                            {renderDotRank(studentIndex, null)}
                          </td>
                          ) : (null)
                        ))
                        : [
                            // Render empty cells with a dash if hasCrActivity is false and there is no data
                            ...Array(
                              resultsReportByStudentStandard.students[0]
                                .pctCorrect.length
                            ),
                          ].map((e, i) => (
                            <td
                              key={i}
                              className={`text-center p-3 text-sm font-normal ${
                                i === 0 ? "border-r border-gray-500" : ""
                              }`}
                            >
                              -
                            </td>
                          ))}

                  </tr>
                </>
              )
            )}
          </table>
        </div>
      )}
    </>
  );
}

import React, { useEffect, useState } from "react";
import Constant from "../../../../utils/constant/constant";
import { TagColor } from "../../../shared/tagColor";
import { AvatarIcon } from "../../../../assets/icons/index";

interface ChatSearch {
  id: number;
  displayName: string;
  isClass: boolean;
  profileUrl: string;
  recentMassageCount: number;
  colorCode: string;
  roleId: number;
}
interface userTileProps {
  onlineUsers: any[];
  chatSearch: ChatSearch;
  roleId: number;
  handleStartChat(arg0: ChatSearch): void;
}

function UserTile(props: userTileProps) {
  const [classBgColor, setBgColor] = useState(props.chatSearch.colorCode);

  const [defaultAvatar] = useState(
    props.chatSearch.profileUrl ? props.chatSearch.profileUrl : ""
  );

  useEffect(() => {
    props.chatSearch.profileUrl = defaultAvatar;
    if (props.chatSearch.colorCode) {
      setBgColor(props.chatSearch.colorCode);
    }
  }, [defaultAvatar, props.chatSearch.colorCode]);

  return (
    <>
      <div
        className={`chat-message group border-b border-gray-200 py-3 pr-3 pl-4 cursor-pointer transition duration-500 ease-in-out hover:bg-gray-100 hover:border-gray-200`}
        style={{
          backgroundColor: `${
            props.chatSearch.isClass ? classBgColor + "10" : ""
          }`,
        }}
        onClick={() => props.handleStartChat(props.chatSearch)}
      >
        <div className="w-full flex items-center justify-between">
          <div className="flex items-center">
            <div className="relative">
              {props.onlineUsers[props.chatSearch.id] ===
                Constant.USERACTIVITY.Active &&
                !props.chatSearch.isClass && (
                  <span className="text-green-500 absolute -right-0 -bottom-0">
                    <svg width="11" height="11">
                      <circle cx="5" cy="5" r="5" fill="currentColor"></circle>
                    </svg>
                  </span>
                )}

              {props.onlineUsers[props.chatSearch.id] ===
                Constant.USERACTIVITY.Ideal &&
                !props.chatSearch.isClass && (
                  <span className="text-yellow-300 absolute -right-0 -bottom-0">
                    <svg width="10" height="10">
                      <circle cx="5" cy="5" r="5" fill="currentColor"></circle>
                    </svg>
                  </span>
                )}
              {!props.onlineUsers[props.chatSearch.id] &&
                !props.chatSearch.isClass && (
                  <span className="text-gray-500 absolute -right-0 -bottom-0">
                    <svg width="10" height="10">
                      <circle cx="5" cy="5" r="5" fill="currentColor"></circle>
                    </svg>
                  </span>
                )}

              {props.chatSearch.isClass && (
                <span
                  className={`w-10 h-10 rounded-xl flex items-center justify-center border border-transparent`}
                  style={{ backgroundColor: `${classBgColor}` }}
                >
                  <svg
                    className={`w-6 h-6 fill-current opacity-80
                      ${
                        TagColor.isDark(props.chatSearch.colorCode)
                          ? "text-white"
                          : ""
                      }
                    `}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 64.000000 64.000000"
                    preserveAspectRatio="xMidYMid meet"
                  >
                    <g
                      transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)"
                      fill="currentColor"
                      stroke="none"
                    >
                      <path d="M25 575 c-25 -24 -25 -26 -25 -205 0 -173 1 -180 20 -180 19 0 20 7 20 173 0 121 4 177 12 185 17 17 519 17 536 0 8 -8 12 -64 12 -185 0 -166 1 -173 20 -173 19 0 20 7 20 180 0 179 0 181 -25 205 l-24 25 -271 0 -271 0 -24 -25z"></path>
                      <path d="M115 288 c-11 -6 -26 -18 -32 -26 -16 -19 -17 -84 -2 -99 7 -7 3 -18 -15 -36 -34 -34 -38 -107 -6 -107 16 0 20 7 20 34 0 45 18 58 77 54 48 -3 48 -3 53 -43 3 -27 10 -41 23 -43 13 -3 17 3 17 23 0 50 16 65 70 65 54 0 70 -15 70 -65 0 -20 4 -26 18 -23 12 2 19 16 22 43 5 40 5 40 53 43 59 4 77 -9 77 -54 0 -27 4 -34 20 -34 32 0 28 73 -6 107 -18 18 -22 29 -15 36 6 6 11 28 11 49 0 66 -60 104 -118 74 -17 -9 -34 -26 -38 -38 -6 -22 -7 -22 -20 4 -7 14 -27 31 -44 38 -38 16 -85 -1 -104 -38 -13 -26 -14 -26 -20 -4 -7 22 -53 52 -78 52 -7 0 -22 -6 -33 -12z m59 -41 c22 -16 18 -55 -6 -71 -40 -24 -80 44 -42 71 10 7 21 13 24 13 3 0 14 -6 24 -13z m170 0 c22 -16 18 -55 -6 -71 -40 -24 -80 44 -42 71 10 7 21 13 24 13 3 0 14 -6 24 -13z m170 0 c22 -16 18 -55 -6 -71 -40 -24 -80 44 -42 71 10 7 21 13 24 13 3 0 14 -6 24 -13z m-254 -98 c0 -11 -26 -18 -39 -10 -9 6 -9 14 0 35 l13 27 13 -23 c7 -13 12 -26 13 -29z m170 0 c0 -11 -26 -18 -39 -10 -9 6 -9 14 0 35 l13 27 13 -23 c7 -13 12 -26 13 -29z"></path>
                    </g>
                  </svg>
                </span>
              )}

              {!props.chatSearch.isClass && defaultAvatar === "" && (
                <span
                  className={`w-10 h-10 rounded-xl  flex items-center justify-center border-2 border-white bg-gray-300`}
                >
                  <AvatarIcon
                    className={`w-6 h-6 fill-current text-white opacity-90 `}
                  />
                </span>
              )}

              {!props.chatSearch.isClass && defaultAvatar !== "" && (
                <img
                  src={defaultAvatar}
                  alt=""
                  className="w-10 h-10 rounded-xl border border-gray-300"
                />
              )}
            </div>
            <div className="flex flex-col leading-tight">
              <div className="mt-1 ml-2 flex flex-col items-start">
                <span className="text-gray-900 text-sm">
                  {props.chatSearch.displayName}
                </span>
                <span className="font-xsmall text-gray-500">
                  {props.chatSearch.isClass && "Class"
                    ? "Class"
                    : props.chatSearch.roleId === Constant.UserRoleId.Student
                    ? "Student"
                    : props.chatSearch.roleId ===
                      Constant.UserRoleId.SchoolAdmin
                    ? "School Admin"
                    : props.chatSearch.roleId === Constant.UserRoleId.District
                    ? "District Admin"
                    : "Teacher"}
                </span>
              </div>
            </div>
          </div>
          {props.chatSearch.recentMassageCount > 0 && (
            <div
              className={`${
                props.roleId === Constant.UserRoleId.Student
                  ? "bg-yellow-500 group-hover:text-yellow-500"
                  : "bg-tertiary-green group-hover:text-tertiary-green"
              } group-hover:bg-white  group-hover:border group-hover:border-yellow-200 transition-all text-white w-6 h-6 text-sm flex items-center rounded-lg justify-center ml-auto`}
            >
              {props.chatSearch.recentMassageCount}
            </div>
          )}
        </div>
      </div>
    </>
  );
}
export default UserTile;

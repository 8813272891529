import EllipsisText from "react-ellipsis-text";

const ellipsisText = ({
  text,
  isQuestion,
  length,
}: {
  text: any;
  isQuestion?: boolean;
  length?: number;
}): JSX.Element => {
  return (
    <>
      <div>
        {text && (
          <EllipsisText
            text={text?.replace(new RegExp("<[^>]*>", "g"), "")}
            length={length ? length : isQuestion ? 25 : 13}
          />
        )}
      </div>
    </>
  );
};

export default ellipsisText;

import AssignmentSearchModel from "../../model/student/shared/assignment/assignmentSearchModel";
import APIConstant from "../../utils/constant/apiConstant";
import { AxiosHelper } from "../../environment/axiosConfig";
import { AxiosResponse } from "axios";
import PagingResponse from "../../model/common/pagingResponse";
import StudentAssignmentResponse from "../../model/student/shared/assignment/assignmentListResponse";
import { getTimezoneOffset } from "../../utils/helper";

export class StudentAssignmentApi {
  public static getStudentAssignments(
    search: AssignmentSearchModel
  ): Promise<AxiosResponse<PagingResponse<StudentAssignmentResponse>>> {
    return AxiosHelper.axiosInstance().get(
      APIConstant.STUDENTASSIGNMENT_API.StudentAssignments,
      {
        params: search,
      }
    );
  }
}

export function getStudentAssignmentResult(
  schoolYearId: number,
  studentId: number,
  assignmentId: number,
  assignmentActivityId: number,
  showNextActivity: boolean = false,
  sessionId?: string
): Promise<AxiosResponse<any>> {
  return AxiosHelper.axiosInstance().get(
    APIConstant.STUDENTASSIGNMENT_API.StudentAssignmentResult,
    {
      params: {
        UserId: studentId,
        AssignmentId: assignmentId,
        AssignmentActivityId: assignmentActivityId,
        ShowNextActivity: showNextActivity,
        sessionId: sessionId,
        timeOffset: getTimezoneOffset(),
        schoolYearId: schoolYearId,
      },
    }
  );
}
export function getNewAssignmentsCount(userId: number) {
  return AxiosHelper.axiosInstance().get(
    APIConstant.STUDENTASSIGNMENT_API.StudentAssignmentsNewAssignmentsCount,
    {
      params: {
        userId: userId,
      },
    }
  );
}

import { connect } from "react-redux";
import { IBreadcrumb } from "../../../model/common/breadcrumb";
import { default as Constant } from "../../../utils/constant/constant";
import routeConstant from "../../../utils/constant/routeConstant";
import Breadcrumb from "../teacher/shared/breadcrumb";
import { useTranslation } from "react-i18next";
import Loader from "../../shared/loader";
import {
  DEFAULT_PAGE_NUMBER,
  DEFAULT_PAGE_SIZE_100,
} from "../../../utils/pagingConstant";
import { useEffect, useState } from "react";
import NWEAMappingModal from "./nweaMappingModal";
import {
  getNWEASettings,
  mapNWEAStudentsByFilter,
  updateNWEAId,
  updateNWEADisabledSetting,
  deleteNWEALiftOffDiagnosticAssignments,
  resetNWEAResult,
  getMappedNWEAStudentsByFilter,
  getUnmappedNWEAStudentsByFilter,
  addNWEATestResult,
} from "../../../api/teacher/nwea";
import NweaResultModal from "./nweaResultModal";
import NWEAMappingStatusModal from "./nweaMappingStatusModal";
import ConfirmationDialog from "../../shared/confirmationDialog";
import AllowedTo from "../../shared/rbac";
import permissions from "../../../utils/permissions";
import SelectGroup from "react-select";
import ClassDropDown from "../teacher/shared/classDropDown";
import { ISelectElement } from "../../../model/interface/selectElement";
import userApi from "../../../api/userApi";
import ITeacherClass from "../../../model/interface/teacherclass";
import { getLiftoffStudents } from "../../../api/student/studentApi";
import {
  getAllClassesBySubject,
  getClassesByTeachers,
} from "../../../api/teacher/teacher";

import PageSize from "../../shared/pagination/pageSize";
import Pagination from "../../shared/pagination/pagination";
import { toast } from "react-toastify";
import NoData from "../../shared/noData";
import { UserContextState } from "../../../redux/actions/userContextAction";
import Profile from "../../../model/users/profile";
import NWEASyncJobBatchStatus from "./nweaSyncJobBatchStatus";

interface INWEAMappingProps {
  userContext: UserContextState;
  profile: Profile;
}

const NWEAMapping = (props: INWEAMappingProps) => {
  const { t } = useTranslation();
  const [pageNumberM, setPageNumberM] = useState(DEFAULT_PAGE_NUMBER);
  const [pageSizeM, setPageSizeM] = useState(DEFAULT_PAGE_SIZE_100);
  const [resetPageNumberM, setResetPageNumberM] = useState<boolean>(false);
  const [pageNumber, setPageNumber] = useState(DEFAULT_PAGE_NUMBER);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE_100);
  const [resetPageNumber, setResetPageNumber] = useState<boolean>(false);
  const [fileUpload, setFileUpload] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [nweaSyncAll, setNWEASyncAll] = useState<boolean>(false);
  const [nweaSyncSelected, setNWEASyncSelected] = useState<boolean>(false);

  const [mappedNWEAStudents, setMappedNWEAStudents] = useState<Array<any>>([]);
  const [unmappedNWEAStudents, setUnmappedNWEAStudents] = useState<Array<any>>(
    []
  );
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [showLoaderMappedStudents, setShowLoaderMappedStudents] =
    useState<boolean>(false);
  const [showLoaderUnmappedStudents, setShowLoaderUnmappedStudents] =
    useState<boolean>(false);
  const [showResultModal, setShowResultModal] = useState<boolean>(false);
  const [selectedNWEAId, setSelectedNweaId] = useState<string>();
  const [selectedMappedUserId, setSelectedMappedUserId] = useState<number>();
  const [selectedFirstName, setSelectedFirstName] = useState<string>();
  const [selectedLastName, setSelectedLastName] = useState<string>();
  const [showBatchStatus, setShowBatchStatus] = useState<boolean>(false);
  const [showResultMappingStatusModal, setShowResultMappingStatusModal] =
    useState<boolean>(false);

  const [totalRecordsMappedNWEAStudents, setTotalRecordsMappedNWEAStudents] =
    useState<number>(0);
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [showSyncConfirmation, setShowSyncConfirmation] =
    useState<boolean>(false);
  const [nweaStudentMappingStatus, setNWEAStudentMappingStatus] = useState<any>(
    []
  );
  const [
    totalRecordsUnmappedNWEAStudents,
    setTotalRecordsUnmappedNWEAStudents,
  ] = useState<number>(0);

  const [studentProfileId, setStudentProfileId] = useState<number>();
  const [selectedUnmappedUserId, setSelectedUnmappedUserId] =
    useState<number>();
  const [selectedUnmappedStudentId, setSelectedUnmappedStudentId] =
    useState<string>();
  const [selectedUnmappedUserFirstName, setSelectedUnmappedUserFirstName] =
    useState<string>();
  const [selectedUnmappedUserLastName, setSelectedUnmappedUserLastName] =
    useState<string>();

  const nonApplicableGrades: number[] = [1, 2, 14, 15, 16, 17];

  const studentLabel = "All Students";
  const teacherLabel = "All Teachers";
  const classesLabel = "All Classes";
  const gradesLabel = "All Grades";

  const [grades, setGrades] = useState<Array<any>>([]);
  const [selectedGrades, setSelectedGrades] = useState<Array<any>>([
    { label: gradesLabel, value: "0" },
  ]);

  const [teachers, setTeachers] = useState<Array<any>>();
  const [selectedTeachers, setSelectedTeachers] = useState<
    Array<ISelectElement>
  >([{ label: teacherLabel, value: "0" }]);

  const [classes, setClasses] = useState<ITeacherClass[]>();
  const [selectedClasses, setSelectedClasses] = useState<Array<ISelectElement>>(
    [{ label: classesLabel, value: "0" }]
  );

  const [students, setStudents] = useState<Array<any>>();
  const [selectedStudents, setSelectedStudents] = useState<Array<any>>([
    { label: studentLabel, value: "0" },
  ]);

  const [selectedStudent, setSelectedStudent] = useState<any>();

  const getAllGrades = async () => {
    setGrades([]);
    setShowLoader(true);
    let selectedGradesData: any = [];
    const nweaSettings = await getNWEASettings(props.profile.userId);
    setShowLoader(false);
    if (nweaSettings.data.length > 0) {
      nweaSettings.data.sort(
        (firstItem, secondItem) => firstItem.gradeId - secondItem.gradeId
      );
      nweaSettings.data.forEach((grade) => {
        if (nonApplicableGrades.indexOf(grade.gradeId) === -1) {
          selectedGradesData.push({
            value: grade.gradeId || 0,
            label: `${grade.gradeName}`,
          });
        }
      });
      setGrades(selectedGradesData);
    }
  };

  const getTeachers = async () => {
    var query: any = {
      userId: 0,
      firstName: "",
      lastName: "",
      username: "",
      gradeId: 0,
      classId: 0,
      studentId: "",
      roleId: Constant.UserRoleId.SchoolTeacher,
      schoolAccountId: props.userContext.schoolAccountId,
      OrderByColumn: "LastName",
      SortByDesc: false,
      isActive: true,
      showLocked: false,
      pageSize: 99999,
      PageNumber: 1,
      exportType: "NONE",
      districtId: props.userContext?.districtId,
    };
    var response = userApi.GetAllUsers(query);
    response?.then((res) => {
      if (res?.data?.data?.length) {
        const teachers: Array<ISelectElement> = [];
        res.data.data.map((value: any) => {
          teachers.push({
            label: value?.lastName + ", " + value?.firstName,
            value: value?.userId,
          });
        });
        setTeachers(teachers);
      } else {
        setTeachers([]);
      }
    });
  };

  const getClasses = async () => {
    setClasses([]);
    const resp = await getAllClassesBySubject(
      props.userContext.userId,
      props.userContext.roleId,
      null,
      props.profile.currentSchoolYear.schoolYearId,
      props.userContext.schoolId
    );
    if (resp?.data?.data?.length) {
      setClasses(resp.data.data);
    }
  };

  const getAllClasses = async () => {
    setClasses([]);
    setStudents([]);
    const resp = await getAllClassesBySubject(
      props.userContext.userId,
      props.userContext.roleId,
      null,
      props.profile.currentSchoolYear.schoolYearId,
      props.userContext.schoolId
    );
    if (resp?.data?.data?.length > 0) {
      setClasses(resp.data.data);
      getStudents();
    }
  };

  const getClassesByTeacherIds = async () => {
    setClasses([]);
    setStudents([]);
    const resp = await getClassesByTeachers(getList(selectedTeachers),
      props.profile.currentSchoolYear.schoolYearId);
    if (resp?.data?.length > 0) {
      setClasses(resp.data);
      setSelectedClasses([{ label: classesLabel, value: "0" }]);
      await getStudents();
    }
  };

  const getStudents = async () => {
    setStudents([]);
    var response = getLiftoffStudents(
      getList(selectedClasses),
      getList(selectedGrades),
      getList(selectedTeachers)
    );
    response?.then((res) => {
      if (res?.data?.length) {
        const students: Array<ISelectElement> = [];
        res.data.map((value: any) => {
          students.push({
            label:
              value?.lastName +
              ", " +
              value?.firstName +
              (value.studentId ? " (" + value.studentId + ")" : ""),
            value: value?.userId,
          });
        });
        setStudents(students);
      } else {
        setStudents([]);
      }
    });
  };

  const getList = (dropdownItems: any) => {
    let ids: any = [];
    if (
      dropdownItems &&
      dropdownItems.length > 0 &&
      dropdownItems[0].value != "0"
    ) {
      dropdownItems.forEach((cs) => {
        ids.push(Number(cs.value));
      });
    }
    return ids;
  };

  function getSelectedValue(e: any, label: string) {
    let selectedValues: Array<ISelectElement> = e;
    if (selectedValues && selectedValues.length > 0) {
      const lastSelectedValue = selectedValues[selectedValues.length - 1];
      if (lastSelectedValue.value === "0") {
        selectedValues = [lastSelectedValue];
      } else {
        selectedValues = selectedValues.filter((r) => r.value !== "0");
      }
    } else {
      selectedValues = [{ label, value: "0" }];
    }
    return selectedValues;
  }

  const handleStudentChange: any = (e: any) => {
    let selectedValues: Array<ISelectElement> = getSelectedValue(
      e,
      studentLabel
    );
    setSelectedStudents(selectedValues);
  };

  const handleTeacherChange: any = (e: any) => {
    let selectedValues: Array<ISelectElement> = getSelectedValue(
      e,
      teacherLabel
    );
    setSelectedTeachers(selectedValues);
  };

  const handleGradeChange: any = (e: any) => {
    let selectedValues: Array<ISelectElement> = getSelectedValue(
      e,
      gradesLabel
    );
    setSelectedGrades(selectedValues);
  };

  const handleGradeMenuClose = () => {
    getStudents();
  };

  const handleClassChange: any = (e: any) => {
    let selectedValues: Array<ISelectElement> = getSelectedValue(
      e,
      classesLabel
    );
    setSelectedClasses(selectedValues);
  };

  const handleClassMenuClose = () => {
    getStudents();
  };

  const handleTeacherMenuClose = () => {
    if (selectedTeachers && selectedTeachers.length > 0) {
      const lastSelectedValue = selectedTeachers[selectedTeachers.length - 1];
      if (lastSelectedValue.value != "0") {
        getClassesByTeacherIds();
      } else {
        getAllClasses();
      }
    }
  };
  const updateNWEAStudents = (
    userId: number,
    pageNumber: number,
    pageSize: number
  ) => {
    setShowModal(false);
    resetPagination();
    getMappedStudentsByFilter(userId, pageNumber, pageSize);
    getUnMappedStudentsByFilter(userId, pageNumber, pageSize);
  };

  const getNWEAStudents = () => {
    setNWEASyncSelected(false);
    setNWEASyncAll(false);
    setMappedNWEAStudents([]);
    setUnmappedNWEAStudents([]);
    setShowModal(false);
    resetPagination();
    getMappedStudentsByFilter(
      props.profile.userId,
      DEFAULT_PAGE_NUMBER,
      DEFAULT_PAGE_SIZE_100
    );
    getUnMappedStudentsByFilter(
      props.profile.userId,
      DEFAULT_PAGE_NUMBER,
      DEFAULT_PAGE_SIZE_100
    );
  };
  const startMapping = async (userId: number) => {
    setNWEASyncSelected(false);
    setNWEASyncAll(false);
    setMappedNWEAStudents([]);
    setUnmappedNWEAStudents([]);
    setShowModal(false);
    resetPagination();
    setShowLoaderMappedStudents(true);
    setShowLoaderUnmappedStudents(true);
    await mapNWEAStudentsByFilter(
      userId,
      getList(selectedClasses),
      getList(selectedGrades),
      getList(selectedTeachers),
      getList(selectedStudents)
    );
    updateNWEAStudents(userId, pageNumber, pageSize);
  };

  const resetPagination = () => {
    setPageNumberM(DEFAULT_PAGE_NUMBER);
    setPageSizeM(DEFAULT_PAGE_SIZE_100);
    setResetPageNumberM(false);
    setPageNumber(DEFAULT_PAGE_NUMBER);
    setPageSize(DEFAULT_PAGE_SIZE_100);
    setResetPageNumber(false);
  };

  const getMappedStudentsByFilter = async (
    userId: number,
    pageNumber: number,
    pageSize: number
  ) => {
    setNWEASyncAll(false);
    setNWEASyncSelected(false);
    setShowLoaderMappedStudents(true);
    getMappedNWEAStudentsByFilter(
      userId,
      pageNumber,
      pageSize,
      getList(selectedClasses),
      getList(selectedGrades),
      getList(selectedTeachers),
      getList(selectedStudents)
    ).then((response) => {
      setShowLoaderMappedStudents(false);
      if (response.data.data.length > 0) {
        const mappedStudents: Array<any> = [];
        const studentMappingStatus: Array<any> = [];

        response.data.data.forEach((nweaStudent) => {
          mappedStudents.push(nweaStudent);
          studentMappingStatus.push({
            studentProfileId: nweaStudent.studentProfileId,
            studentId: nweaStudent.userId,
            isNWEADisabled: nweaStudent.isNWEADisabled,
          });
        });
        setNWEAStudentMappingStatus(studentMappingStatus);
        mappedStudents.forEach((student) => {
          student.nweaSync = false;
        });
        setMappedNWEAStudents(mappedStudents);

        setTotalRecordsMappedNWEAStudents(response.data.totalRecords);
      } else {
        setTotalRecordsMappedNWEAStudents(0);
        setMappedNWEAStudents([]);
      }
    });
  };

  const getUnMappedStudentsByFilter = (
    userId: number,
    pageNumber: number,
    pageSize: number
  ) => {
    setShowLoaderUnmappedStudents(true);
    getUnmappedNWEAStudentsByFilter(
      userId,
      pageNumber,
      pageSize,
      getList(selectedClasses),
      getList(selectedGrades),
      getList(selectedTeachers),
      getList(selectedStudents)
    ).then((response) => {
      setShowLoaderUnmappedStudents(false);
      if (response.data.data.length > 0) {
        const unmappedStudents: Array<any> = [];
        response.data.data.forEach((nweaStudent) => {
          unmappedStudents.push(nweaStudent);
        });
        setUnmappedNWEAStudents(unmappedStudents);
        setTotalRecordsUnmappedNWEAStudents(response.data.totalRecords);
      } else {
        setTotalRecordsUnmappedNWEAStudents(0);
        setUnmappedNWEAStudents([]);
      }
    });
  };

  const breadcrumbItems = () => {
    const items: Array<IBreadcrumb> = [
      {
        name: t("Breadcrumb.Home"),
        url: routeConstant.ROUTE_DASHBOARD,
      },
      { name: t("Breadcrumb.NweaMapping"), url: "" },
    ];

    return items;
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const onPageSizeChange = (pageSize) => {
    setPageNumber(pageNumber);
    setPageSize(pageSize);
    setResetPageNumber(true);
  };
  const onPageChange = (pageNo) => {
    setPageNumber(pageNo);
  };

  const showPopUp = (
    profileId: any,
    selectedUnmappedUserId: number,
    selectedUnmappedUserFirstName: string,
    selectedUnmappedUserLastName: string,
    selectedUnmappedStudentId: string
  ) => {
    setStudentProfileId(profileId);
    setSelectedUnmappedStudentId(selectedUnmappedStudentId);
    setSelectedUnmappedUserId(selectedUnmappedUserId);
    setSelectedUnmappedUserFirstName(selectedUnmappedUserFirstName);
    setSelectedUnmappedUserLastName(selectedUnmappedUserLastName);
    setShowModal(true);
  };

  const showResultPopup = (
    userId: number,
    nweaId: string,
    firstName: string,
    lastName: string
  ) => {
    setSelectedMappedUserId(userId);
    setSelectedNweaId(nweaId);
    setSelectedFirstName(firstName);
    setSelectedLastName(lastName);
    setShowResultModal(true);
  };

  const closeResultPopup = () => {
    setShowResultModal(false);
  };

  const showBatchStatusPopup = () => {
    setShowBatchStatus(true);
  };

  const closeBatchStatusPopup = () => {
    setShowBatchStatus(false);
  };

  const showResultMappingStatusPopup = () => {
    setShowResultMappingStatusModal(true);
  };

  const closeResultMappingStatusPopup = () => {
    setShowResultMappingStatusModal(false);
  };

  useEffect(() => {
    if (mappedNWEAStudents?.length > 0)
      getMappedStudentsByFilter(props.profile.userId, pageNumberM, pageSizeM);
  }, [pageSizeM, pageNumberM, resetPageNumberM]);

  useEffect(() => {
    if (unmappedNWEAStudents?.length > 0)
      getUnMappedStudentsByFilter(props.profile.userId, pageNumber, pageSize);
  }, [pageSize, pageNumber, resetPageNumber]);

  const nweaDisabledStatus = (studentProfileId: number) => {
    if (nweaStudentMappingStatus) {
      return nweaStudentMappingStatus.find(
        (s) => s.studentProfileId === studentProfileId
      )?.isNWEADisabled;
    } else {
      return false;
    }
  };

  useEffect(() => {
    getAllGrades();
    getStudents();
    getTeachers();
    getClasses();
  }, [1]);

  const update = async (
    studentProfileId: any,
    nweaId: any,
    studentUserId: number
  ) => {
    await updateNWEAId(
      studentProfileId,
      nweaId,
      props.profile.userId,
      studentUserId
    );
    updateNWEAStudents(props.profile.userId, pageNumber, pageSize);
  };

  const updateNWEADisabledField = async (
    studentProfileId: number,
    isNWEADisabled: boolean
  ) => {
    await updateNWEADisabledSetting(
      studentProfileId,
      isNWEADisabled,
      props.profile.userId
    );
  };

  const deleteNWEALiftOffDiagnostic = async (
    studentId: number,
    updatedByUserId: number
  ) => {
    await deleteNWEALiftOffDiagnosticAssignments(studentId, updatedByUserId);
  };

  const handleNWEADisabledSetting = async (studentProfileId: number) => {
    var student = nweaStudentMappingStatus.find(
      (ns) => ns.studentProfileId === studentProfileId
    );
    student.isNWEADisabled = !student.isNWEADisabled;
    setSelectedStudent(student);
    if (student.isNWEADisabled) {
      setShowConfirmation(true);
    } else {
      await updateNWEADisabledField(student.studentProfileId, false);
    }
    setNWEAStudentMappingStatus([...nweaStudentMappingStatus]);
  };

  const handleNWEASyncSelection = async (studentProfileId: number) => {
    console.log(studentProfileId);
    let students: any = [...mappedNWEAStudents];
    let student = students.find((s) => s.studentProfileId === studentProfileId);
    student.nweaSync = !student.nweaSync;
    if (
      students.filter((s) => s.nweaSync === student.nweaSync).length ===
      students.length
    ) {
      setNWEASyncAll(student.nweaSync);
    } else {
      setNWEASyncAll(false);
    }
    if (students.filter((s) => s.nweaSync === true).length > 0) {
      setNWEASyncSelected(true);
    } else {
      setNWEASyncSelected(false);
    }

    setMappedNWEAStudents(students);
  };

  const handleSelectAllNWEASync = () => {
    let syncStatus: boolean = !nweaSyncAll;
    setNWEASyncAll(syncStatus);
    let students: any = [...mappedNWEAStudents];
    students.forEach((student) => {
      student.nweaSync = syncStatus;
    });
    if (students.filter((s) => s.nweaSync === true).length > 0) {
      setNWEASyncSelected(true);
    } else {
      setNWEASyncSelected(false);
    }
    setMappedNWEAStudents(students);
  };

  const resetStudentNWEAResult = async () => {
    setShowLoaderMappedStudents(true);
    let students: any = mappedNWEAStudents.filter((s) => s.nweaSync);
    if (students && students.length > 0) {
      let userIds: any = [];
      let nweaIds: any = [];
      students.forEach(async (student) => {
        userIds.push(student.userId);
        nweaIds.push(student.nweaId);
      });
      await resetNWEAResult({
        userIds: userIds,
        nweaIds: nweaIds,
      });
    }
    getMappedStudentsByFilter(
      props.profile.userId,
      DEFAULT_PAGE_NUMBER,
      DEFAULT_PAGE_SIZE_100
    );
  };

  const handleOkConfirmation = async () => {
    var student = nweaStudentMappingStatus.find(
      (ns) => ns.studentProfileId === selectedStudent.studentProfileId
    );
    student.isNWEADisabled = true;
    setNWEAStudentMappingStatus([...nweaStudentMappingStatus]);
    await updateNWEADisabledField(
      student.studentProfileId,
      student.isNWEADisabled
    );
    await deleteNWEALiftOffDiagnostic(student.studentId, props.profile.userId);
    setShowConfirmation(false);
  };

  const handleCancelConfirmation = () => {
    setShowConfirmation(false);
    var student = nweaStudentMappingStatus.find(
      (ns) => ns.studentProfileId === selectedStudent.studentProfileId
    );
    student.isNWEADisabled = !student.isNWEADisabled;
    setNWEAStudentMappingStatus([...nweaStudentMappingStatus]);
  };
  const onPageSizeChangeM = (pageSize) => {
    setPageNumberM(pageNumberM);
    setPageSizeM(pageSize);
    setResetPageNumberM(true);
  };
  const onPageChangeM = (pageNo) => {
    setPageNumberM(pageNo);
  };
  const handleCancelConfirmationSync = () => {
    setShowSyncConfirmation(false);
  };

  const handleOkConfirmationSync = async () => {
    setShowSyncConfirmation(false);
    setShowLoaderMappedStudents(true);
    addNWEATestResult(
      getList(selectedClasses),
      getList(selectedGrades),
      getList(selectedTeachers),
      getList(selectedStudents)
    ).then((response) => {
      setShowLoaderMappedStudents(false);
      if (response.data) {
        toast.success(
          `Your request to sync scores has been submitted. Please note Batch Id : ${response.data}`
        );
      }
    });
  };

  return (
    <>
      {showLoader && <Loader />}
      <div className="lg:flex lg:items-center px-4 py-3 lg:px-8 border-b border-gray-200">
        <Breadcrumb items={breadcrumbItems()} />
        <div className="lg:w-[55%] text-lg flex justify-between gap-2 h-auto sm:h-10 items-center">
          <h1 className="text-primary-violet font-medium flex justify-start">
            NWEA Mapping
          </h1>
        </div>
      </div>

      <div className="px-2 sm:px-6  lg:px-8 py-1 relative my-4">
        <div className=" mx-auto lg:grid lg:grid-cols-12 lg:gap-8">
          <div className="min-w-0 block lg:col-span-12 xl:col-span-12 w-full">
            <div className="bg-gray-100 w-full rounded-lg p-5">
              <div className="shadow hover:shadow-lg bg-white py-5 px-5">
                <div className="flex flex-col relative">
                  <div className="border-b mb-4">
                    Applicable Grades for NWEA mapping
                  </div>

                  <div className="px-2 sm:px-6  lg:px-8 py-1 relative my-4">
                    <div className="bg-gray-100 w-full rounded-lg p-5">
                      <div className="flex flex-col 1xl:flex-row">
                        <div className="w-full">
                          <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-5 gap-4 mb-4">
                            <div className="flex flex-col">
                              <label className="block mb-1 text-sm text-gray-500">
                                Grade
                              </label>
                              <SelectGroup
                                isMulti={true}
                                placeholder={"All Grades"}
                                onChange={handleGradeChange}
                                value={selectedGrades}
                                options={grades}
                                closeMenuOnSelect={false}
                                onMenuClose={handleGradeMenuClose}
                                isSearchable={true}
                                theme={(theme) => ({
                                  ...theme,
                                  colors: {
                                    ...theme.colors,
                                    primary: "#008fbb",
                                  },
                                })}
                              />
                            </div>
                            <div className="flex flex-col">
                              <label className="block mb-1 text-sm text-gray-500">
                                Teacher
                              </label>
                              <SelectGroup
                                isMulti={true}
                                placeholder={teacherLabel}
                                onChange={handleTeacherChange}
                                value={selectedTeachers}
                                options={teachers}
                                onMenuClose={handleTeacherMenuClose}
                                closeMenuOnSelect={false}
                                isSearchable={true}
                                theme={(theme) => ({
                                  ...theme,
                                  colors: {
                                    ...theme.colors,
                                    primary: "#008fbb",
                                  },
                                })}
                              />
                            </div>
                            <div className="flex flex-col">
                              <label className="block mb-1 text-sm text-gray-500">
                                Class
                              </label>
                              <ClassDropDown
                                schoolYearId={
                                  props.profile.currentSchoolYear.schoolYearId
                                }
                                defaultLabel={classesLabel}
                                isMultiSelect={true}
                                onChange={handleClassChange}
                                onMenuClose={handleClassMenuClose}
                                value={selectedClasses}
                                data={classes}
                                isDisabled={false}
                                showArchived={true}
                                showHidden={true}
                              ></ClassDropDown>
                            </div>
                            <div className="flex flex-col">
                              <label className="block mb-1 text-sm text-gray-500">
                                Student
                              </label>
                              <SelectGroup
                                isMulti={true}
                                placeholder={studentLabel}
                                onChange={handleStudentChange}
                                value={selectedStudents}
                                options={students}
                                closeMenuOnSelect={false}
                                isSearchable={true}
                                theme={(theme) => ({
                                  ...theme,
                                  colors: {
                                    ...theme.colors,
                                    primary: "#008fbb",
                                  },
                                })}
                              />
                            </div>
                          </div>
                          <div className="flex flex-wrap lg:flex-nowrap gap-4">
                            <div className="cursor-not-allowed">
                              <button
                                onClick={() => getNWEAStudents()}
                                className={
                                  "bg-primary-violet whitespace-nowrap border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 " +
                                  (showLoaderMappedStudents ||
                                  showLoaderUnmappedStudents
                                    ? "bg-opacity-20 text-primary-violet text-opacity-40 pointer-events-none"
                                    : "hover:bg-dark-violet hover:shadow-lg")
                                }
                                type="button"
                              >
                                View
                              </button>
                            </div>

                            <div className="cursor-not-allowed">
                              <button
                                onClick={() =>
                                  startMapping(props.profile.userId)
                                }
                                className={
                                  "bg-primary-violet whitespace-nowrap border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 " +
                                  (showLoaderMappedStudents ||
                                  showLoaderUnmappedStudents
                                    ? "bg-opacity-20 text-primary-violet text-opacity-40 pointer-events-none"
                                    : "hover:bg-dark-violet hover:shadow-lg")
                                }
                                type="button"
                              >
                                Start Mapping
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-4 space-x-4 flex items-center"></div>
                  <div className="mt-4 space-x-4">
                    {fileUpload && (
                      <input
                        className=" border border-gray-300 shadow-sm py-1.5 px-4 inline-flex justify-center focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 rounded-lg"
                        type="file"
                      />
                    )}

                    {fileUpload && (
                      <button
                        className="bg-primary-violet border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-dark-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 rounded-lg"
                        type="button"
                      >
                        Upload
                      </button>
                    )}

                    {fileUpload && (
                      <button
                        onClick={() => setFileUpload(!fileUpload)}
                        className="bg-primary-violet border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-dark-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 rounded-lg"
                        type="button"
                      >
                        Cancel
                      </button>
                    )}
                  </div>
                </div>
              </div>

              {showModal && (
                <NWEAMappingModal
                  closeModal={closeModal}
                  studentProfileId={studentProfileId}
                  userId={props.profile.userId}
                  studentId={selectedUnmappedStudentId}
                  selectedUserId={selectedUnmappedUserId}
                  selectedUserFirstName={selectedUnmappedUserFirstName}
                  selectedUserLastName={selectedUnmappedUserLastName}
                  updateUnmappedStudents={updateNWEAStudents}
                />
              )}

              {showResultModal && (
                <NweaResultModal
                  closeModal={closeResultPopup}
                  userId={selectedMappedUserId}
                  nweaId={selectedNWEAId}
                  firstName={selectedFirstName}
                  lastName={selectedLastName}
                />
              )}

              {showBatchStatus && (
                <NWEASyncJobBatchStatus
                  closeModal={closeBatchStatusPopup}
                  userId={props.profile.userId}
                />
              )}

              {showResultMappingStatusModal && (
                <NWEAMappingStatusModal
                  closeModal={closeResultMappingStatusPopup}
                  userId={props.profile.userId}
                  classIds={getList(selectedClasses)}
                  gradeIds={getList(selectedGrades)}
                  teacherUserIds={getList(selectedTeachers)}
                  studentUserIds={getList(selectedStudents)}
                />
              )}

              <ConfirmationDialog
                open={showConfirmation}
                confirmationTitle={"Please confirm"}
                confirmationMessage={
                  "Disabling NWEA will disregard NWEA RIT scores and the students will be required to take the full LiftOff diagnostic battery of tests."
                }
                onCancelClick={handleCancelConfirmation}
                onOkClick={handleOkConfirmation}
              ></ConfirmationDialog>

              {mappedNWEAStudents && mappedNWEAStudents.length > 0 && (
                <ConfirmationDialog
                  open={showSyncConfirmation}
                  confirmationTitle={"Please confirm"}
                  confirmationMessage={
                    "Are you sure you want to update and sync all students across all pages within your search results?"
                  }
                  okButtonText={"Yes I want to sync all"}
                  onCancelClick={handleCancelConfirmationSync}
                  onOkClick={handleOkConfirmationSync}
                ></ConfirmationDialog>
              )}

              <div className="mt-4">
                <div className="shadow hover:shadow-lg bg-white py-5 px-5">
                  <div className="flex flex-col relative">
                    <div className="flex items-center gap-x-6 border-b pb-2 w-full">
                      <div>List of NWEA Mapped Students</div>
                      <div className="flex gap-x-4">
                        <AllowedTo perform={permissions.nwea_sync_view}>
                          <div className="cursor-not-allowed">
                            <button
                              onClick={() => resetStudentNWEAResult()}
                              className={
                                " bg-primary-violet whitespace-nowrap border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 " +
                                (showLoaderMappedStudents || !nweaSyncSelected
                                  ? "bg-opacity-20 text-primary-violet text-opacity-40 pointer-events-none"
                                  : "hover:bg-dark-violet hover:shadow-lg")
                              }
                              type="button"
                            >
                              Sync Selected Students
                            </button>
                          </div>
                        </AllowedTo>
                        <AllowedTo perform={permissions.nwea_sync_view}>
                          <div className="cursor-not-allowed">
                            <button
                              onClick={() => setShowSyncConfirmation(true)}
                              className={
                                " bg-primary-violet whitespace-nowrap border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 " +
                                (showLoaderMappedStudents ||
                                !mappedNWEAStudents ||
                                mappedNWEAStudents.length == 0
                                  ? "bg-opacity-20 text-primary-violet text-opacity-40 pointer-events-none"
                                  : "hover:bg-dark-violet hover:shadow-lg")
                              }
                              type="button"
                            >
                              Sync All Pages
                            </button>
                          </div>
                        </AllowedTo>

                        <div className="cursor-not-allowed">
                          <button
                            onClick={() => showResultMappingStatusPopup()}
                            className={
                              "bg-primary-violet whitespace-nowrap border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 " +
                              (showLoaderMappedStudents ||
                              !mappedNWEAStudents ||
                              mappedNWEAStudents.length == 0
                                ? "bg-opacity-20 text-primary-violet text-opacity-40 pointer-events-none"
                                : "hover:bg-dark-violet hover:shadow-lg")
                            }
                            type="button"
                          >
                            View Student Status
                          </button>
                        </div>

                        <div className="cursor-not-allowed">
                          <button
                            onClick={() => showBatchStatusPopup()}
                            className={
                              "bg-primary-violet whitespace-nowrap border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 " +
                              (showLoaderMappedStudents ||
                              !mappedNWEAStudents ||
                              mappedNWEAStudents.length == 0
                                ? "bg-opacity-20 text-primary-violet text-opacity-40 pointer-events-none"
                                : "hover:bg-dark-violet hover:shadow-lg")
                            }
                            type="button"
                          >
                            View Sync Status
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="py-2 ">
                      <div className="shadow hover:shadow-lg bg-white py-5 px-5">
                        <div className="flex flex-col relative">
                          <div className="shadow overflow-x-auto xl:overflow-x-visible relative">
                            {showLoaderMappedStudents && (
                              <Loader className=" absolute h-full " />
                            )}
                            <table className="table w-full ">
                              <thead className="mb-5">
                                <tr className="bg-light-violet  text-white py-3 px-3">
                                  <AllowedTo
                                    perform={permissions.nwea_sync_view}
                                  >
                                    <th>
                                      <input
                                        type="checkbox"
                                        name="nwea_sync_setting"
                                        checked={nweaSyncAll}
                                        onChange={() =>
                                          handleSelectAllNWEASync()
                                        }
                                      />
                                    </th>
                                  </AllowedTo>
                                  <th
                                    scope="col"
                                    className="text-left  p-3 text-sm font-normal"
                                  >
                                    <div className="flex">First Name</div>
                                  </th>

                                  <th
                                    scope="col"
                                    className="text-left  p-3 text-sm font-normal"
                                  >
                                    <div className="flex">Last Name</div>
                                  </th>
                                  <th
                                    scope="col"
                                    className="text-left  p-3 text-sm font-normal"
                                  >
                                    <div className="flex">Student ID</div>
                                  </th>

                                  <th
                                    scope="col"
                                    className="text-left  p-3 text-sm font-normal"
                                  >
                                    <div className="flex">NWEA ID</div>
                                  </th>
                                  <th
                                    scope="col"
                                    className="text-left  p-3 text-sm font-normal"
                                  >
                                    <div className="flex">Term</div>
                                  </th>
                                  <th
                                    scope="col"
                                    className="text-left  p-3 text-sm font-normal"
                                  >
                                    <div className="flex">Test Results</div>
                                  </th>
                                  <th
                                    scope="col"
                                    className="text-left  p-3 text-sm font-normal"
                                  >
                                    Action
                                  </th>
                                  <th
                                    scope="col"
                                    className="text-left  p-3 text-sm font-normal"
                                  >
                                    Disable NWEA
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {mappedNWEAStudents?.map((student, i) => (
                                  <tr
                                    key={i}
                                    className={
                                      i % 2 === 0
                                        ? " hover:bg-light-violet/20 transition ease-in-out duration-300 bg-white"
                                        : "hover:bg-light-violet/20 transition ease-in-out duration-300 bg-light-violet/10"
                                    }
                                  >
                                    <AllowedTo
                                      perform={permissions.nwea_sync_view}
                                    >
                                      <td className="p-3 whitespace-nowrap text-sm text-gray-500 text-center">
                                        {!nweaDisabledStatus(
                                          student.studentProfileId
                                        ) && (
                                          <input
                                            type="checkbox"
                                            name={"nwea_sync_setting" + i}
                                            checked={student.nweaSync}
                                            onChange={() =>
                                              handleNWEASyncSelection(
                                                student.studentProfileId
                                              )
                                            }
                                          />
                                        )}
                                      </td>
                                    </AllowedTo>
                                    <td className="p-3 whitespace-nowrap text-sm text-gray-500">
                                      {student.firstName}
                                    </td>
                                    <td className="p-3 whitespace-nowrap text-sm text-gray-500">
                                      {student.lastName}
                                    </td>
                                    <td className="p-3 whitespace-nowrap text-sm text-gray-500">
                                      {student.studentId}
                                    </td>
                                    <td className="p-3 whitespace-nowrap text-sm text-gray-500">
                                      {student.nweaId}
                                    </td>
                                    <td className="p-3 whitespace-nowrap text-sm text-gray-500">
                                      {student.nweaTermName}
                                    </td>
                                    <td className="p-3 whitespace-nowrap text-sm text-gray-500">
                                      <button
                                        className="text-indigo-500"
                                        type="button"
                                        onClick={() => {
                                          showResultPopup(
                                            student.userId,
                                            student.nweaId,
                                            student.firstName,
                                            student.lastName
                                          );
                                        }}
                                      >
                                        Show Results
                                      </button>
                                    </td>
                                    <td className="p-3 whitespace-nowrap text-sm text-gray-500">
                                      {!nweaDisabledStatus(
                                        student.studentProfileId
                                      ) && (
                                        <button
                                          className="text-indigo-500"
                                          type="button"
                                          onClick={() => {
                                            update(
                                              student.studentProfileId,
                                              null,
                                              student.userId
                                            );
                                          }}
                                        >
                                          Unmap
                                        </button>
                                      )}
                                    </td>
                                    <td className="p-3 whitespace-nowrap text-sm text-gray-500">
                                      <>
                                        <button
                                          className="bg-primary-violet whitespace-nowrap border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 hover:bg-dark-violet hover:shadow-lg"
                                          type="button"
                                          onClick={() => {
                                            handleNWEADisabledSetting(
                                              student.studentProfileId
                                            );
                                          }}
                                        >
                                          {nweaDisabledStatus(
                                            student.studentProfileId
                                          )
                                            ? "Enable"
                                            : "Disable"}
                                        </button>
                                      </>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>

                          {mappedNWEAStudents?.length === 0 && <NoData />}

                          {mappedNWEAStudents?.length !== 0 && (
                            <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between border-t-2 pt-4 mt-3">
                              <Pagination
                                totalRecords={totalRecordsMappedNWEAStudents}
                                pageSize={pageSizeM}
                                onPageChange={onPageChangeM}
                                reset={resetPageNumberM}
                                pageNumber={pageNumberM}
                                setPageNumber={setPageNumber}
                              />
                              <PageSize
                                pageSize={pageSizeM}
                                onPageSizeChange={onPageSizeChangeM}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="shadow hover:shadow-lg bg-white py-5 px-5 mt-5">
                  <div className="flex flex-col relative">
                    <div className="py-2 ">
                      <div className="border-b mb-4">
                        List of NWEA Unmapped Students
                      </div>
                      <div className="shadow overflow-x-auto xl:overflow-x-visible">
                        {showLoaderUnmappedStudents && (
                          <Loader className=" absolute h-full " />
                        )}
                        <table className="table w-full ">
                          <thead className="mb-5">
                            <tr className="bg-light-violet  text-white py-3 px-3">
                              <th
                                scope="col"
                                className="text-left  p-3 text-sm font-normal"
                              >
                                <div className="flex">First Name</div>
                              </th>

                              <th
                                scope="col"
                                className="text-left  p-3 text-sm font-normal"
                              >
                                <div className="flex">Last Name</div>
                              </th>

                              <th
                                scope="col"
                                className="text-left  p-3 text-sm font-normal"
                              >
                                <div className="flex">Student ID</div>
                              </th>

                              <th
                                scope="col"
                                className="text-left  p-3 text-sm font-normal"
                              >
                                <div className="flex">NWEA ID</div>
                              </th>

                              <th
                                scope="col"
                                className="text-left  p-3 text-sm font-normal"
                              >
                                Action
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {unmappedNWEAStudents?.map((student, i) => (
                              <tr
                                key={i}
                                className={
                                  i % 2 === 0
                                    ? " hover:bg-light-violet/20 transition ease-in-out duration-300 bg-white"
                                    : "hover:bg-light-violet/20 transition ease-in-out duration-300 bg-light-violet/10"
                                }
                              >
                                <td className="p-3 whitespace-nowrap text-sm text-gray-500">
                                  {student.firstName}
                                </td>
                                <td className="p-3 whitespace-nowrap text-sm text-gray-500">
                                  {student.lastName}
                                </td>
                                <td className="p-3 whitespace-nowrap text-sm text-gray-500">
                                  {student.studentId}
                                </td>

                                <td className="p-3 whitespace-nowrap text-sm text-gray-500">
                                  Unmapped
                                </td>
                                <td className="p-3 whitespace-nowrap text-sm text-gray-500">
                                  <button
                                    className="text-indigo-500"
                                    type="button"
                                    onClick={() => {
                                      showPopUp(
                                        student.studentProfileId,
                                        student.userId,
                                        student.firstName,
                                        student.lastName,
                                        student.studentId
                                      );
                                    }}
                                  >
                                    Map
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    {unmappedNWEAStudents?.length === 0 && <NoData />}

                    {unmappedNWEAStudents?.length !== 0 && (
                      <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between border-t-2 pt-4 mt-3">
                        <>
                          <Pagination
                            totalRecords={totalRecordsUnmappedNWEAStudents}
                            pageSize={pageSize}
                            onPageChange={onPageChange}
                            reset={resetPageNumber}
                            pageNumber={pageNumber}
                            setPageNumber={setPageNumber}
                          />
                          <PageSize
                            pageSize={pageSize}
                            onPageSizeChange={onPageSizeChange}
                          />
                        </>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect((state: any) => {
  return {
    profile: state.profile,
    userContext: state.userContext,
  };
}, {})(NWEAMapping);

import { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  GetSchoolInformation,
  updateSchoolInformation,
} from "../../../api/profileApi";
import { useForm } from "react-hook-form";
import moment from "moment";
import { toast } from "react-toastify";

const SchoolInfo = (props: any) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  const DefaultStartDate = new Date().getFullYear() + "-08-01";
  const [schoolInfo, setSchoolInfo] = useState<any>({
    schoolId: 0,
    schoolName: "",
    districtName: "",
    principalName: "",
    principalEmail: "",
    accountAdminName: "",
    accountAdminEmail: "",
    startDate: null,
  });

  useEffect(() => {
    GetSchoolInformation(props.userContext.schoolId).then((d) => {
      setSchoolInfo(d.data);
    });
  }, []);

  const handleSchoolInfoSubmit = () => {
    const schoolData = {
      schoolId: schoolInfo.schoolId,
      principalName: schoolInfo.principalName,
      principalEmail: schoolInfo.principalEmail,
      accountAdminName: schoolInfo.accountAdminName,
      accountAdminEmail: schoolInfo.accountAdminEmail,
    };

    updateSchoolInformation(schoolData).then((r) => {
      const res = r.data;
      if (res === -1) {
        toast.error(
          "Principal Email is already associated with other account."
        );
      } else if (res === -2) {
        toast.error(
          "Account Admin Email is already associated with other account."
        );
      } else {
        toast.success("School Information updated successfully.");
      }
    });
  };

  const handleOnChange = (e) => {
    const name = e.target.name;
    let value = e.target.value;
    if (name === "startDate" && value === "") value = DefaultStartDate;

    setSchoolInfo({ ...schoolInfo, [name]: value });
  };

  return (
    <form onSubmit={handleSubmit(handleSchoolInfoSubmit)}>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-x-6 gap-y-4">
        <div>
          <label className="text-sm font-medium text-gray-500">
            School Name
          </label>
          <input
            type="text"
            className="mt-1 block w-full max-w-sm shadow-sm sm:text-sm border-gray-300 rounded-md  border border-gray-300 rounded h-10 px-2 py-4 bg-gray-100"
            value={schoolInfo.schoolName}
            disabled={true}
            name="schoolName"
            onChange={handleOnChange}
          />
        </div>

        <div>
          <label className="text-sm font-medium text-gray-500">District</label>
          <input
            type="text"
            className="mt-1 block w-full max-w-sm shadow-sm sm:text-sm border-gray-300 rounded-md  border border-gray-300 rounded h-10 px-2 py-4 bg-gray-100"
            disabled={true}
            value={schoolInfo.districtName}
            name="districtName"
            onChange={handleOnChange}
          />
        </div>

        <div>
          <label className="text-sm font-medium text-gray-500">
            Principal Name
          </label>
          <input
            type="text"
            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full max-w-sm shadow-sm sm:text-sm border-gray-300 rounded-md  border border-gray-300 rounded h-10 px-2 py-4"
            value={schoolInfo.principalName}
            {...register("principalName", {
              pattern: {
                value: /^[a-zA-Z', ]*$/,
                message: "Please enter a valid name",
              },
              onChange: (e) => {
                handleOnChange(e);
              },
            })}
          />

          {errors.principalName?.message && (
            <>
              <span className="text-red-500 max-w-70% p-0.5 mt-2 pr-3 text-sm">
                {errors.principalName.message}
              </span>
            </>
          )}
        </div>

        <div>
          <label className="text-sm font-medium text-gray-500">
            Principal E-mail
          </label>
          <input
            type="text"
            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full max-w-sm shadow-sm sm:text-sm border-gray-300 rounded-md  border border-gray-300 rounded h-10 px-2 py-4"
            value={schoolInfo.principalEmail}
            {...register("principalEmail", {
              pattern: {
                value:
                  /(?=^[a-z0-9.]+@[a-z0-9.-]+\.[a-zA-Z]{2,6}$)(?=^.{1,60}$)/,
                message: "Invalid email",
              },
              onChange: (e) => {
                handleOnChange(e);
              },
            })}
          />

          {errors.principalEmail?.message && (
            <>
              <span className="text-red-500 max-w-70% p-0.5 mt-2 pr-3 text-sm">
                {errors.principalEmail.message}
              </span>
            </>
          )}
        </div>

        <div>
          <label className="text-sm font-medium text-gray-500">
            Account Admin Name
          </label>
          <input
            type="text"
            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full max-w-sm shadow-sm sm:text-sm border-gray-300 rounded-md  border border-gray-300 rounded h-10 px-2 py-4"
            value={schoolInfo.accountAdminName}
            {...register("accountAdminName", {
              pattern: {
                value: /^[a-zA-Z', ]*$/,
                message: "Please enter a valid name",
              },
              onChange: (e) => {
                handleOnChange(e);
              },
            })}
          />

          {errors.accountAdminName?.message && (
            <>
              <span className="text-red-500 max-w-70% p-0.5 mt-2 pr-3 text-sm">
                {errors.accountAdminName.message}
              </span>
            </>
          )}
        </div>

        <div>
          <label className="text-sm font-medium text-gray-500">
            Account Admin Email
          </label>
          <input
            type="text"
            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full max-w-sm shadow-sm sm:text-sm border-gray-300 rounded-md  border border-gray-300 rounded h-10 px-2 py-4"
            value={schoolInfo.accountAdminEmail}
            {...register("accountAdminEmail", {
              pattern: {
                value:
                  /(?=^[a-z0-9.]+@[a-z0-9.-]+\.[a-zA-Z]{2,6}$)(?=^.{1,60}$)/,
                message: "Invalid email",
              },
              onChange: (e) => {
                handleOnChange(e);
              },
            })}
          />

          {errors.accountAdminEmail?.message && (
            <>
              <span className="text-red-500 max-w-70% p-0.5 mt-2 pr-3 text-sm">
                {errors.accountAdminEmail.message}
              </span>
            </>
          )}
        </div>
        <div>
          <label className="text-sm font-medium text-gray-500">
            School Year Reset Date
          </label>
          <input
            type="text"
            className="disabled:opacity-50 disabled:cursor-not-allowed mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full max-w-sm shadow-sm sm:text-sm border-gray-300 rounded-md  border border-gray-300 rounded h-10 px-2 py-4"
            value={
              schoolInfo.startDate
                ? moment.utc(schoolInfo.startDate).format("MM/DD").toString()
                : moment.utc(DefaultStartDate).format("MM/DD").toString()
            }
            disabled={true}
          />

          {errors.fDaySchool?.message && (
            <>
              <span className="text-red-500 max-w-70% p-0.5 mt-2 pr-3 text-sm">
                {errors.fDaySchool.message}
              </span>
            </>
          )}
        </div>
      </div>

      <div className="mt-5 text-primary-violet">
        Date is used to define current school year for reporting and data reset
        purposes. If you need to have this date updated, please reach out to
        your Customer Success Manager.
      </div>

      <div className="px-4 py-3 bg-gray-50 my-6 mr-16  text-right ">
        <button
          type="submit"
          className="bg-primary-violet border border-transparent shadow-sm py-2 px-4 justify-end text-sm font-medium text-white hover:bg-dark-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 whitespace-nowrap ml-auto"
        >
          Save
        </button>
      </div>
    </form>
  );
};

export default connect((state: any) => {
  return {
    profile: state.profile,
    userContext: state.userContext,
  };
})(SchoolInfo);

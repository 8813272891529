import { useState, Fragment, useEffect, useRef } from "react";
import "../../../../../css/style.css";
import { useTranslation } from "react-i18next";
import ReactToPrint from "react-to-print";
import { Configuration } from "../../../../../environment/setup";
import DiagnosticsStrengthWeaknessData from "../../../../../model/report/diagnostics/diagnosticsStrengthWeaknessData";
import { getDiagnosticsStrengthWeaknessReportExport } from "../../../../../api/report/diagnosticStrengthWeaknessStudentReport";
import { LogoPLIcon } from "../../../../../assets/icons";
import { XIcon } from "@heroicons/react/outline";
import moment from "moment";

interface DiagnosticStrengthWeaknessReportPrintProps
{
  showPrint: (show: boolean) => void;
  classId: number;
  selectedStudentIds: string;
  subjectId: number;
  schoolId: number;
}

const DiagnosticStrengthWeaknessReportPrint: React.FC<DiagnosticStrengthWeaknessReportPrintProps> = (props) => {
  const { t } = useTranslation();
  const [reportData, setReportData] =
    useState<Array<DiagnosticsStrengthWeaknessData> | null>();  
  const [date, setDateGenerated] = useState<string>("");

  const componentRef = useRef(null);

  function runReport() {
    getDiagnosticsStrengthWeaknessReportExport(
      props.selectedStudentIds,
      props.subjectId,
      props.schoolId,
      props.classId,
      0
    ).then((response) => {
      if (response.data) {       
        setDateGenerated(response.data.dateGenerated);
        setReportData(response.data.data);
      }
    });
  }

  useEffect(() => {
    runReport();
  }, []);

  return (
    <div className="fixed inset-0 z-40 flex items-center bg-black bg-opacity-50 sm:items-center sm:justify-center">
      <div
        className="w-full flex flex-col justify-between overflow-hidden h-auto max-h-80percent bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:max-w-5xl"
        role="dialog"
      >
        <header>
          {/* Header */}
          <div className="px-2 py-2 bg-gray-50">
            <div className="flex items-start justify-between space-x-3">
              <div className="h-7 flex items-center">
                <button
                  type="button"
                  className="text-gray-400 hover:text-gray-500"
                  onClick={() => props.showPrint(false)}
                >
                  <span className="sr-only">Close panel</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </header>

        <div ref={componentRef} className="w-full flex flex-wrap overflow-auto">
          <div className="py-1 w-full relative my-4">
            <div className="w-full rounded-lg px-5">
              <div>
                <div className="overflow-x-auto xl:overflow-x-visible">
                  <div className="bg-white ">
                    {reportData &&
                      reportData?.map((singleSudentReport, index) => {
                        return (
                          <Fragment>
                            <div
                              className={"" + (index == 0 ? "" : "pagebreak")}
                            >
                              <div className="flex items-center justify-between">
                                <div className="text-lg font-medium text-gray-900 flex items-center">
                                  <LogoPLIcon
                                    className="block lg:block h-16 w-auto pr-4"
                                    title="Progress Learning logo"
                                  ></LogoPLIcon>                                
                                </div>
                                <h4>{moment(date).format("MM/DD/YYYY")}</h4>
                              </div>
                              <div className="pb-1 pt-5 border-b border-gray-300 mb-4">
                                <div className="text-lg flex justify-between gap-2 items-end mb-1">
                                  <h1 className="text-primary-violet font-medium flex justify-start mb-0 pb-0">
                                    {t(
                                      "Breadcrumb.DiagnosticStrengthWeaknessReport"
                                    )} <span className="text-gray-700 ml-1 border-l-2 border-gray-400 ml-2 pl-2">{singleSudentReport.testType}</span>
                                  </h1>                                 
                                </div>
                              </div>

                              <div className="flex gap-x-2 mb-6">
                                <div className="w-1/3 bg-white shadow-lg border-l-[10px] border-primary-violet">
                                  <div className=" px-2 h-full pb-2">
                                    <div className="flex py-2 gap-x-3">
                                      <span className="w-20 flex-shrink-0 flex items-center">
                                        Class:
                                      </span>
                                      <span className="text-green-400 text-lg font-semibold">
                                        {
                                          singleSudentReport.studentDetail
                                            .className
                                        }
                                      </span>
                                    </div>
                                    <div className="flex border-t py-2 gap-x-3">
                                      <span className="w-20 flex-shrink-0 flex items-center">
                                        Student:
                                      </span>
                                      <span className="text-green-400 text-lg font-semibold">
                                        {singleSudentReport.studentDetail
                                          .firstName +
                                          " " +
                                          singleSudentReport.studentDetail
                                            .lastName}
                                      </span>
                                    </div>
                                    <div className="flex border-t py-2 gap-x-3">
                                      <span className="w-20 flex-shrink-0 flex items-center">
                                        Grade:
                                      </span>
                                      <span className="text-green-400 text-lg font-semibold">
                                        {singleSudentReport.studentDetail.grade}
                                      </span>
                                    </div>
                                    <div className="flex border-t py-2 gap-x-3">
                                      <span className="w-20 flex-shrink-0 flex items-center">
                                        Subject:
                                      </span>
                                      <span className="text-green-400 text-lg font-semibold">
                                        {
                                          singleSudentReport.studentDetail
                                            .subjectName
                                        }
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="w-1/3 bg-white shadow-lg border-l-[10px] border-primary-violet">
                                  {/*  */}
                                  <div className=" px-2 h-full pb-2">
                                    <div className="py-3">
                                      <span className="text-lg text-gray-700 uppercase">
                                        Overall Score
                                      </span>
                                    </div>
                                    <div className="flex border-t py-2 gap-x-3">
                                      <span className="w-40 flex items-center">
                                        Questions Answered
                                      </span>
                                      <span className="text-green-400 text-lg font-semibold">
                                        {
                                          singleSudentReport.overallScore
                                            .questionsAnswered
                                        }
                                      </span>
                                    </div>
                                    <div className="flex border-t py-2 gap-x-3">
                                      <span className="w-40 flex items-center">
                                        Questions Correct
                                      </span>
                                      <span className="text-green-400 text-lg font-semibold ">
                                        {
                                          singleSudentReport.overallScore
                                            .questionsCorrect
                                        }
                                      </span>
                                    </div>
                                    <div className="flex border-t  py-2 gap-x-3">
                                      <span className="w-40 flex items-center">
                                        Overall Score
                                      </span>
                                      <span className="text-green-400 text-lg font-semibold ">
                                        {singleSudentReport.overallScore.score}%
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="w-1/3 bg-white shadow-lg border-l-[10px] border-primary-violet">
                                  <div className=" px-2 h-full pb-2">
                                    <div className="py-3">
                                      <span className="text-lg text-gray-700 uppercase">
                                        Ranking against peers
                                      </span>
                                    </div>
                                    <div className="flex border-t py-2 gap-x-3">
                                      <span className="w-40 flex items-center">
                                        Total Students Tested
                                      </span>
                                      <span className="text-green-400 text-lg font-semibold break-all">
                                        {
                                          singleSudentReport.ranking
                                            .totalStudents
                                        }
                                      </span>
                                    </div>
                                    <div className="flex border-t py-2 gap-x-3">
                                      <span className="w-40 flex items-center">
                                        Average Score
                                      </span>
                                      <span className="text-green-400 text-lg font-semibold break-all">
                                        {singleSudentReport.ranking.averageScore.toFixed(
                                          2
                                        )}
                                        %
                                      </span>
                                    </div>
                                    <div className="flex border-t  py-2 gap-x-3">
                                      <span className="w-40 flex items-center">
                                        Your Ranking
                                      </span>
                                      <span className="text-green-400 text-lg font-semibold break-all">
                                        {singleSudentReport.ranking.studentRank}
                                      </span>
                                    </div>
                                    <div className="flex border-t  py-2 gap-x-3">
                                      <span className="w-40 flex items-center">
                                        Percentile
                                      </span>
                                      <span className="text-green-400 text-lg font-semibold break-all">
                                        {singleSudentReport.ranking.percentile}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="mb-48 flex gap-y-6 justify-between gap-x-6">
                                <div className={`w-full lg:w-1/3 rounded-lg overflow-hidden border border-primary-violet shadow ${singleSudentReport.standardEfficiencyReport.strongTopics.length > 7 ? "pagebreak" : ""}`}>
                                  <div className="bg-[#61D140] flex lg:flex-col xl:flex-row items-center w-full bg-primary-violet py-3 px-2">
                                    <img
                                      alt="areas of strength"
                                      title="Areas of strength"
                                      className="w-10"
                                      src={`${Configuration.S3bucketImagePath}/images/student/shared/Areas-of-Strength.png`}
                                    />
                                    <div className="text-white lg:text-center xl:text-left text-base uppercase py-3 px-2">
                                      AREAS OF STRENGTH
                                    </div>
                                  </div>
                                  <div className="py-3 px-6 flex flex-col divide-y gap-y-2">
                                    {singleSudentReport.standardEfficiencyReport.strongTopics?.map(
                                      (topic) => {
                                        return (
                                          <>
                                            <div className="flex items-center text-base text-gray-700  pt-2">
                                              {topic}
                                            </div>
                                          </>
                                        );
                                      }
                                    )}
                                  </div>
                                </div>
                                <div className={`w-full lg:w-1/3 rounded-lg overflow-hidden border border-primary-violet shadow ${singleSudentReport.standardEfficiencyReport.averageTopics.length > 7 ? "pagebreak" : ""}`}>
                                  <div className="bg-[#F4D220] flex lg:flex-col xl:flex-row items-center w-full bg-primary-violet py-3 px-2">
                                    <img
                                      alt="some study required"
                                      title="Some study required"
                                      className="w-10 ml-2 mr-1 lg:mr-0 lg:ml-0 xl:ml-2 xl:mr-1 "
                                      src="https://assets-dev.progresslearning.com/images/student/shared/study-required.png"
                                    ></img>
                                    <div className="text-white lg:text-center xl:text-left text-base uppercase py-3 px-2">
                                      Some study required
                                    </div>
                                  </div>
                                  <div className="py-3 px-6 flex flex-col divide-y gap-y-2">
                                    {singleSudentReport.standardEfficiencyReport.averageTopics.map(
                                      (topic) => {
                                        return (
                                          <>
                                            <div className="flex items-center text-base text-gray-700  pt-2">
                                              {topic}
                                            </div>
                                          </>
                                        );
                                      }
                                    )}
                                  </div>
                                </div>
                                <div className={`w-full lg:w-1/3 rounded-lg overflow-hidden border border-primary-violet shadow ${singleSudentReport.standardEfficiencyReport.criticalTopics.length > 7 ? "pagebreak" : ""}`}>
                                  <div className="bg-[#BB0070] flex lg:flex-col xl:flex-row items-center w-full bg-primary-violet py-3 px-2">
                                    <img
                                      alt="critical need for study"
                                      title="Critical need for study"
                                      className="w-10 ml-4 mr-3 lg:ml-0 lg:mr-0 xl:ml-4 xl:mr-3"
                                      src="https://assets-dev.progresslearning.com/images/student/shared/Critical-Need-Study.png"
                                    ></img>
                                    <div className="text-white lg:text-center xl:text-left text-base uppercase py-3 px-2">
                                      CRITICAL NEED FOR STUDY
                                    </div>
                                  </div>
                                  <div className="py-3 px-6 flex flex-col divide-y gap-y-2">
                                    {singleSudentReport.standardEfficiencyReport.criticalTopics?.map(
                                      (topic) => {
                                        return (
                                          <>
                                            <div className="flex items-center text-base text-gray-700  pt-2">
                                              {topic}
                                            </div>
                                          </>
                                        );
                                      }
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Fragment>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <footer className="flex flex-col items-center justify-end px-6 py-4 sm:flex-row bg-gray-50 dark:bg-gray-800">
          <div className="flex-shrink-0">
            <div className="space-x-3 flex justify-end">
              <ReactToPrint
                trigger={() => (
                  <button
                    className="ml-4 bg-secondary-teal border border-transparent shadow-sm py-2 px-4 justify-end text-sm font-medium text-white hover:bg-dark-teal hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 whitespace-nowrap"
                    type="button"
                  >
                    Export/Print
                  </button>
                )}
                content={() => componentRef.current}
              />

              <button
                className="align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 rounded-lg text-sm text-gray-600 border-gray-300 border dark:text-gray-400 focus:outline-none active:bg-transparent hover:border-gray-500 focus:border-gray-500 active:text-gray-500 focus:shadow-outline-gray w-full sm:w-auto"
                type="button"
                onClick={() => {
                  props.showPrint(false);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
}

export default DiagnosticStrengthWeaknessReportPrint;

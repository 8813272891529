import React, { Fragment } from "react";
import { XIcon } from "@heroicons/react/solid";
import { useTranslation } from "react-i18next";
import { ISubject } from "../../../../../model/interface/subject";
import Profile from "../../../../../model/users/profile";
import { connect } from "react-redux";
import useTextToSpeech from "../../shared/textToSpeech/useTextToSpeech";

export interface SubjectSelectionPopupProps {
  showPopup: (show: boolean) => void;
  subjects: Array<ISubject>;
  profile?: Profile;
  selectSubject: (s: ISubject) => void;
  formatSubject: (s: ISubject) => string;
}

const SubjectSelectionPopup: React.FC<SubjectSelectionPopupProps> = (props) => {
  const { t } = useTranslation();
  const { startSpeaking, stopSpeaking } = useTextToSpeech();

  return (
    <>
      <section>
        <div className="fixed inset-0 z-50 flex items-center bg-black bg-opacity-50 sm:items-center sm:justify-center enter-done">
          <div className="w-full flex flex-col justify-between overflow-hidden h-auto max-h-80percent bg-white rounded-lg dark:bg-gray-800 sm:m-4 sm:max-w-xl">
            <header className="flex justify-end p-4">
              <XIcon
                className="cursor-pointer h-4 w-4 "
                onClick={() => props.showPopup(false)}
              ></XIcon>
            </header>
            <div className="w-full relative overflow-auto px-4 py-3 ">
              <p className="text-xl font-semibold text-gray-700 dark:text-gray-300 mb-6 rounded-2xl overflow-auto">
                {t("Shared.StudentSideBar.SelectSubject")}
              </p>
              <div className="mb-6 text-sm space-y-3 text-gray-700 dark:text-gray-400 text-center z-10 relative">
                {props.subjects.map((s) => {
                  return (
                    <Fragment key={s.subjectId}>
                      <button
                        onMouseEnter={() => {
                          startSpeaking(
                            props.formatSubject(s),
                            (props.profile?.plLanguageId ?? 1) === 2 ? 2 : 1
                          );
                        }}
                        onMouseLeave={() => {
                          stopSpeaking();
                        }}
                        className="flex w-full  bg-[#dec80a] hover:bg-[#7DBC41] border-2 border-[#dec80a] p-1 rounded-2xl group hover:shadow-2xl  transition ease-in-out duration-500"
                        onClick={() => {
                          props.selectSubject(s);
                        }}
                      >
                        <span className="bg-[#051834] w-full py-3 rounded-2xl border-2 ring-6 ring-[#90d12a] ring-offset-6 overflow-hidden relative text-lg lg:text-xl xl:text-2xl text-white hover:shadow-2xl text-left pl-3 h-full flex items-center">
                          <span className="w-10 h-44 bg-white absolute z-0 -top-5 opacity-5 transform  left-6 rotate-45 group-hover:opacity-10 group-hover:rotate-12 transition ease-in-out duration-1000"></span>
                          <span className="w-3 h-44 bg-white absolute z-0 -top-5  opacity-5 left-20 transform rotate-45 group-hover:opacity-20 group-hover:rotate-12 transition ease-in-out duration-1000"></span>
                          {props.formatSubject(s)}
                        </span>
                      </button>
                    </Fragment>
                  );
                })}
              </div>
            </div>
            <footer className="flex bg-gray-50 flex-col items-center justify-end px-6 py-4 sm:flex-row">
              <button
                style={{ fontWeight: 900 }}
                className="align-bottom inline-flex font-bold items-center justify-center cursor-pointer leading-5 transition-colors duration-150  focus:outline-none px-5 py-3 rounded-lg text-lg text-white bg-primary-violet hover:shadow-lg hover:bg-dark-violet 
                  "
                type="button"
                onClick={() => {
                  props.showPopup(false);
                }}
              >
                {t("StudentResponse.Close")}
              </button>
            </footer>
          </div>
        </div>
      </section>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(SubjectSelectionPopup);

import "antd/dist/antd.css";
import arraySort from "array-sort";
import { Fragment, useState } from "react";
import {
  getFavoriteQuestions,
  getQuestionSearch,
  updateFavoriteQuestion,
} from "../../../../api/teacher/assessment";
import { IAssessmentQuestion } from "../../../../model/interface/assessmentQuestion";
import { IQuestionSearch } from "../../../../model/interface/questionSearch";
import {
  IKeyValuePair,
  ISelectElement,
  ISelectElementCollapsable,
} from "../../../../model/interface/selectElement";
import DomainStandard from "../../../../model/teacher/domainStandard";
import { getHtmlParsedQuestions } from "../../../../utils/assessmentHelper";
import Constant from "../../../../utils/constant/constant";
import Feedback from "../../../shared/feedback";
import Loader from "../../../shared/loader";
import QuestionPreview from "../shared/questionPreview/questionPreview";
import _ from "lodash";
import NonPassageQuestionsFilters from "./nonPassageQuestionsFilters";
import NonPassageQuestionsDomainStandards from "./nonPassageQuestionsDomainStandards";

interface NonPassageQuestionsProps {
  userId: number;
  roleId: number;
  districtId: number;
  hasDistrictAssessmentQuestionIcon: boolean;
  subjectId: number;
  selectedQuestions: Array<IAssessmentQuestion>;
  updateQuestion: (question: IAssessmentQuestion) => void;
  updateQuestions: (question: Array<IAssessmentQuestion>) => void;
  allQuestions: Array<IAssessmentQuestion>;
  allowEdit: boolean;
  enableQuestionSelectionTabs: boolean;
};

type SortByOption =
  | "Newest"
  | "Low Difficulty"
  | "High Difficulty"
  | "Low DOK"
  | "High DOK"
  | "Favorites";

const defaultSortField: string = "questionType";
const defaultSelectedQuestion: Array<ISelectElement> = [{ label: "All Questions", value: "0" }];

function NonPassageQuestions(props: Readonly<NonPassageQuestionsProps>) {
  const {
    userId,
    subjectId,
    districtId,
    hasDistrictAssessmentQuestionIcon,
    selectedQuestions,
    updateQuestion,
    allQuestions,
    allowEdit,
    enableQuestionSelectionTabs,
  } = props;

  const [domainStandards, setDomainStandards] = useState<Array<DomainStandard>>([]);
  const [selectedDomains, setSelectedDomains] = useState<Array<ISelectElement>>([]);
  const [displayDomains, setDisplayDomains] = useState<Array<ISelectElement>>([]);
  const [selectedStandards, setSelectedStandards] = useState<Array<ISelectElementCollapsable>>([]);
  const [displayStandards, setDisplayStandards] = useState<Array<ISelectElementCollapsable>>([]);
  const [standardQuestions, setStandardQuestions] = useState<Array<IAssessmentQuestion>>([]);
  const [questions, setQuestions] = useState<Array<IAssessmentQuestion>>([]);
  const [selectedQuestionCategoryIds, setSelectedQuestionCategoryIds] = useState<Array<number>>([]);
  const [selectedQuestionTypes, setSelectedQuestionTypes] = useState<Array<ISelectElement>>(defaultSelectedQuestion );
  const [showFeedbackPopup, setShowFeedbackPopup] = useState<boolean>(false);
  const [feedbackQuestionId, setFeedbackQuestionId] = useState<number>(0);
  const [previewId, setPreviewId] = useState<string>("");
  const [questionIdPreview, setQuestionIdPreview] = useState<number>();
  const [showQuestionPreview, setShowQuestionPreview] = useState<boolean>(false);
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [favoriteQuestionsIds, setFavoriteQuestionsIds] = useState<Array<number>>([]);
  const [selectedSortOption, setSelectedSortOption] = useState<Array<{ domainId: number; selectedValue: ISelectElement }>>([]);
  const [displayRows, setDisplayRows] = useState<Array<IKeyValuePair>>([]);
  const [questionText, setQuestionText] = useState<string>("");
  const [selectedQuestionStandardId, setSelectedQuestionStandardId] = useState<string>("");
  const [questionIndex, setQuestionIndex] = useState<number>(0);
  const [selectedStandardId, setSelectedStandardId] = useState<number>();
  const [spanishVersion, setSpanishVersion] = useState<boolean>(false);
  const [keywordTags, setKeywordTags] = useState<Array<string>>([]);
  const [matchAnyTag, setMatchAnyTag] = useState<boolean>(true);

  const searchQuestions = async (
    selectedStandards: Array<ISelectElement>,
    questionCategoryIds?: Array<number>
  ) => {
    const selectedDomainsIds = selectedDomains.map(domain => domain.value);

    const selectedStandardIds = selectedStandards.map(standard => standard.value);

    if (selectedStandardIds.length === 0 && keywordTags.length === 0) {
      setQuestions([]);
      setDisplayDomains([]);
      setDisplayStandards([]);
      return;
    }

    const questionSearch: IQuestionSearch = {
      userId: userId,
      subjectId: subjectId,
      domainIds: selectedDomainsIds.join(","),
      standardIds: selectedStandardIds.join(","),
      questionTypeIds: questionCategoryIds
        ? questionCategoryIds.join(",")
        : selectedQuestionCategoryIds?.join(","),
      excludedQuestions: getExistingSelectedQuestions().map(s => s.questionId),
      includeConstructedResponseQuestionTypes: true,
      keywordTags: keywordTags,
      matchAnyTag: matchAnyTag,
      pageSize: 9999,
      pageNumber: 1
    };
    setShowLoading(true);
    const response = await getQuestionSearch(questionSearch);
    const questionsData = response.data.data;
    const newDomainsToDisplay = getDomainsToDisplay(questionsData);
    const newStandardsToDisplay = getStandardsToDisplay(questionsData);
    setQuestions(questionsData);
    setStandardQuestions(questionsData);
    setDisplayStandards(newStandardsToDisplay);
    setDisplayDomains(newDomainsToDisplay);
    generateDisplayRows(newStandardsToDisplay);
    setShowLoading(false);
  }

  const generateDisplayRows = (standards: Array<ISelectElementCollapsable>) => {
    const standardTableRows: Array<IKeyValuePair> = standards.map(item => ({
      key: item.value,
      value: "10",
    }));
    setDisplayRows(standardTableRows);
  }

  const getExistingSelectedQuestions = () => {
    const existingSelectedQuestions: Array<IAssessmentQuestion> =
      allQuestions.filter(question =>
        question.assessmentQuestionTypeId === Constant.AssessmentQuestionType.PassageQuestions 
        || question.assessmentQuestionTypeId === Constant.AssessmentQuestionType.SystemGenerated
      );

    return existingSelectedQuestions;
  }

  const updateStandadardsAndQuestions = async (groupedStandards: any[]) => {
    const standardIdsSet: Set<number> = new Set(selectedQuestions
      .filter(question => question.standardId)
      .map(question => parseInt(question.standardId ?? "0"))
    );
    const standardIds: Array<number> = Array.from(standardIdsSet);
    const standardTableRows: Array<IKeyValuePair> = standardIds.map(standardId => ({
      key: standardId.toString(),
      value: "10"
    }));
    setDisplayRows(standardTableRows);
      
    const standardsData: Array<ISelectElementCollapsable> = groupedStandards
      .map(standard => standard.options)
      .flat()
      .filter(standard => standardIds.includes(+standard.value));
    setSelectedStandards(standardsData);
    setDisplayStandards(standardsData);

    const questionSearch: IQuestionSearch = {
      userId: userId,
      subjectId: subjectId,
      standardIds: standardIds.join(","),
      questionTypeIds: selectedQuestionCategoryIds?.join(","),
      excludedQuestions: getExistingSelectedQuestions().map(s => s.questionId),
      includeConstructedResponseQuestionTypes: true,
      keywordTags: keywordTags,
      matchAnyTag: matchAnyTag,
    };

    setShowLoading(true);
    const response = await getQuestionSearch(questionSearch);
    const questionData: Array<IAssessmentQuestion> = response.data.data.map((question: any) => ({
      ...question,
      isFavorite: favoriteQuestionsIds.some(favoriteQuestionsId => favoriteQuestionsId === question.questionId),
    }));
    setQuestions(questionData);
    setStandardQuestions(questionData);
    setDisplayStandards(getStandardsToDisplay(questionData));
    setDisplayDomains(getDomainsToDisplay(questionData));
    setShowLoading(false);
  }

  const getDomainsToDisplay = (questions: Array<IAssessmentQuestion>) => {
    const domainsIds = Array.from(new Set(questions
      .sort((a, b) => (a.standardCode ?? "") > (b.standardCode ?? "") ? 1 : (a.standardCode ?? "") < (b.standardCode ?? "") ? -1 : 0)
      .map(question => question.domainId)
    ));

    const domainsToDisplay: Array<ISelectElement> = domainsIds.map(domainId => {
      const questionData = questions.find(question => question.domainId === domainId);
      return {
        label: questionData?.domainName ?? "",
        value: ""+domainId
      }
    });

    return domainsToDisplay;
  }

  const getStandardsToDisplay = (questions: Array<IAssessmentQuestion>) => {
    const standardIds: Array<string> = Array.from(new Set(questions
      .map(question => question.standardId ?? "")
      .filter(value => value !== "")
    ));
    const standardsToDisplay: Array<ISelectElementCollapsable> = standardIds.map(standardId => {
      const questionData = questions.find(question => question.standardId === standardId);
      return {
        label: `${questionData?.standardCode} ${questionData?.standardName}`,
        value: questionData?.standardId ?? "",
        isFirst: false,
        isCollapsed: true,
      }
    }).sort((a, b) => a.label > b.label ? 1 : a.label < b.label ? -1 : 0)
    return standardsToDisplay;
  }

  const handleStandardCollapse = (standard: ISelectElementCollapsable) => {
    const currentDisplayStandards = [...displayStandards];
    const standardIdToSwitch = currentDisplayStandards.findIndex(s => s.value === standard.value);

    if (standardIdToSwitch !== -1) {
      currentDisplayStandards[standardIdToSwitch].isCollapsed = !standard.isCollapsed;
    }

    setDisplayStandards(currentDisplayStandards);
  }

  const getSortbyType = (value: string) => {
    switch (value as SortByOption) {
      case "Newest":
        return "createdDate";
      case "Low DOK":
      case "High DOK":
        return "dokId";
      case "Favorites":
        return "isFavorite";
      default:
        return "";
    }
  }

  const getSortQuestions = (
    questions: Array<IAssessmentQuestion>,
    domainId: string,
    sortByType: string,
    label: string
  ) => {
    let questionsToSort: Array<IAssessmentQuestion> = questions
      .filter(question => question.domainId!.toString() === domainId);

    const sortedResults: Array<IAssessmentQuestion> = arraySort(
      questionsToSort,
      sortByType,
      {
        reverse: (
          label.toLowerCase().includes("high") ||
          sortByType === "popularity" ||
          sortByType === "isFavorite" ||
          sortByType === "createdDate"
        )
      }
    );

    return sortedResults;
  }

  const handleQuestionsSortBy = (e: ISelectElement, domainIdToSort: string) => {
    const newSortOption: {
      domainId: number;
      selectedValue: ISelectElement;
    } = { domainId: parseInt(domainIdToSort), selectedValue: e };

    const updatedSortBy: Array<{ 
      domainId: number, 
      selectedValue: ISelectElement 
    }> = 
      selectedSortOption.some(option => option.domainId === parseInt(domainIdToSort))
      ? [...selectedSortOption.filter(option => option.domainId !== parseInt(domainIdToSort)), newSortOption]
      : [...selectedSortOption, newSortOption];

    setSelectedSortOption(updatedSortBy);

    const updatedStandardQuestions = standardQuestions.map(question => ({
      ...question,
      isFavorite: favoriteQuestionsIds.some(questionId => questionId === question.questionId)
    }));

    const sortedQuestions: Array<IAssessmentQuestion> = updatedSortBy
      .map(domain => (getSortQuestions(
        updatedStandardQuestions,
        domain.domainId.toString(),
        getSortbyType(domain.selectedValue.label),
        domain.selectedValue.label
      )))
      .flat();

    const sortAppliedDomainsIds = updatedSortBy.map(domain => domain.domainId);

    const restQuestions: Array<IAssessmentQuestion> = updatedStandardQuestions
      .filter(r => !sortAppliedDomainsIds.includes(parseInt(r.domainId!)));

    const sortedResults: Array<IAssessmentQuestion> = [
      ...sortedQuestions,
      ...restQuestions,
    ];
    setQuestions(sortedResults);
  }

  const handleFavoriteQuestion = (questionId: number) => {
    const questionExist = favoriteQuestionsIds.some((r) => r === questionId);
    const updatedQuestionsIds: Array<number> = questionExist
      ? favoriteQuestionsIds.filter((r) => r !== questionId)
      : [...favoriteQuestionsIds, questionId];

    setFavoriteQuestionsIds(updatedQuestionsIds);

    updateFavoriteQuestion(userId, questionId);

    const updatedStandardQuestions: Array<IAssessmentQuestion> = standardQuestions.map(question => ({
      ...question,
      isFavorite: updatedQuestionsIds.some(q => q === question.questionId)
    }));

    setStandardQuestions(updatedStandardQuestions);
  }

  const addQuestion = (questionId: number, standardId: string) => {
    const selecedQuestion = standardQuestions.find(r => r.questionId === questionId && r.standardId === standardId);
    if (selecedQuestion) {
      selecedQuestion.assessmentQuestionTypeId = Constant.AssessmentQuestionType.NonPassageQuestions;
      updateQuestion(selecedQuestion);
    }
  }

  const getStandardQuestions = (standardId: string) => {
    const domainId = domainStandards.find(domainStandard => domainStandard.standards.some(standard => standard.id === standardId))?.domainId;
    const sortLabel = selectedSortOption.find(option => option.domainId === parseInt(domainId ?? "0"))?.selectedValue?.label;

    let sortedQuestions = questions.filter(question => question.standardId === standardId);
    if (!sortLabel) {
      sortedQuestions = arraySort(
        questions.filter(question => question.standardId === standardId),
        defaultSortField
      );
    }
    const standardQuestions = getHtmlParsedQuestions(sortedQuestions);
    return standardQuestions;
  }

  const changePreviewQuestion = (question: IAssessmentQuestion, isNextQuestion: boolean) => {
    if (question?.learnosityReferenceId) {
      setPreviewId(
        spanishVersion && question.spanishLearnosityReferenceId
          ? question.spanishLearnosityReferenceId
          : question.learnosityReferenceId
      );
      setQuestionIdPreview(question.questionId);
      setQuestionText(question.questionText ? question.questionText : "");
      setSelectedQuestionStandardId(question.standardId ?? "");
      setQuestionIndex(questionIndex + (isNextQuestion ? 1 : - 1));
    } else {
      setQuestionIndex(isNextQuestion ? questions.length - 1 : 0);
    }
  }

  const handlePreviousQuestion = () => {
    const standardQuestions = getStandardQuestions(selectedQuestionStandardId);
    const previousQuestion = standardQuestions[questionIndex - 1];
    changePreviewQuestion(previousQuestion, false);
  }

  const handleNextQuestion = () => {
    var standardQuestions = getStandardQuestions(selectedQuestionStandardId);
    const nextQuestion = standardQuestions[questionIndex + 1];
    changePreviewQuestion(nextQuestion, true);
  }

  const showSpanishVersion = (e, questionIdPreview) => {
    setSpanishVersion(e.target.checked);
    const currentQuestion = questions?.find(
      q => q.questionId === questionIdPreview
    );

    if (currentQuestion) {
      if (e.target.checked && currentQuestion.spanishLearnosityReferenceId) {
        setPreviewId(currentQuestion.spanishLearnosityReferenceId);
      } else if (currentQuestion.learnosityReferenceId) {
        setPreviewId(currentQuestion.learnosityReferenceId);
      }
    }
  }

  const toggleFeedback = (popupAction: boolean) => {
    setShowFeedbackPopup(popupAction);
  }

  const handleFeedbackPopup = (id: number, standardId: number) => {
    setFeedbackQuestionId(id);
    setSelectedStandardId(standardId);
    toggleFeedback(true);
  }

  const openQuestionPreview = (
    learnosityReferenceId,
    questionId,
    questionText,
    standardId,
    spanishLearnosityReferenceId
  ) => {
    setShowQuestionPreview(true);
    setPreviewId(
      spanishVersion && spanishLearnosityReferenceId
        ? spanishLearnosityReferenceId
        : learnosityReferenceId
    );
    setQuestionIdPreview(questionId);
    setQuestionText(questionText);
    setSelectedQuestionStandardId(standardId ?? "");

    const currentIndex: number = getStandardQuestions(standardId).findIndex(
      (q) => q.questionId == questionId
    );
    setQuestionIndex(currentIndex);
  };

  const closeQuestionPreview = (value) => {
    setShowQuestionPreview(value);
    setPreviewId("");
  };

  const bindFavoriteQuestions = () => {
    setShowLoading(true);
    getFavoriteQuestions(userId).then(response => {
      setFavoriteQuestionsIds(response.data);
      setShowLoading(false);
    });
  }

  const areStandardsAndQuestionsSelected: boolean = displayStandards?.length > 0 && questions?.length > 0;

  return (
    <Fragment>
      {showLoading && <Loader></Loader>}
      <QuestionPreview
        key={previewId}
        modal={openQuestionPreview}
        show={showQuestionPreview}
        changeValue={closeQuestionPreview}
        itemRef={previewId}
        questionIdPreview={questionIdPreview}
        questionStandardId={selectedQuestionStandardId}
        activityName={questionText}
        addQuestionClick={addQuestion}
        nextQuestionClick={handleNextQuestion}
        previousQuestionClick={handlePreviousQuestion}
        isQuestionAdded={selectedQuestions.some(
          (r) => r.questionId === questionIdPreview
        )}
        currentQuestionIndex={questionIndex}
        totalQuestions={getStandardQuestions(selectedQuestionStandardId).length}
        standardId={selectedQuestionStandardId}
        showSpanishVersion={showSpanishVersion}
        spanishVersion={spanishVersion}
      ></QuestionPreview>
      {showFeedbackPopup && (
        <Feedback
          toggleFeedback={toggleFeedback}
          referenceType={Constant.FeedbackReferenceType.QUESTION}
          referenceId={feedbackQuestionId}
          subjectId={subjectId}
          standardId={selectedStandardId}
        />
      )}
      {subjectId > 0 && enableQuestionSelectionTabs && (
        <div
          className={`col-span-10 lg:col-span-9 xl:col-span-10 ${
            allowEdit ? "" : "pointer-events-none opacity-50"
          }`}
        >
          <div
            className={`bg-gray-100 w-full rounded-lg p-5 h-full ${
              areStandardsAndQuestionsSelected ? "mb-[50px]" : "mb-[250px]"
            }`}
          >
            <NonPassageQuestionsFilters 
              subjectId={subjectId}
              selectedQuestions={selectedQuestions}
              selectedStandards={selectedStandards}
              setSelectedStandards={setSelectedStandards}
              setDisplayStandards={setDisplayStandards}
              searchQuestions={searchQuestions}
              setQuestions={setQuestions}
              selectedDomains={selectedDomains}
              setSelectedDomains={setSelectedDomains}
              setDomainStandards={setDomainStandards}
              updateStandadardsAndQuestions={updateStandadardsAndQuestions}
              bindFavoriteQuestions={bindFavoriteQuestions}
              selectedQuestionTypes={selectedQuestionTypes}
              setSelectedQuestionCategoryIds={setSelectedQuestionCategoryIds}
              setSelectedQuestionTypes={setSelectedQuestionTypes}
              keywordTags={keywordTags}
              setKeywordTags={setKeywordTags}
              matchAnyTag={matchAnyTag}
              setMatchAnyTag={setMatchAnyTag}
              setShowLoading={setShowLoading}
            />
            <div
              className={`${
                displayStandards.length > 0
                  ? "bg-white py-5 px-5 w-full shadow hover:shadow-lg mt-3"
                  : ""
              } `}
            >
              {areStandardsAndQuestionsSelected && 
                <NonPassageQuestionsDomainStandards 
                  districtId={districtId}
                  hasDistrictAssessmentQuestionIcon={hasDistrictAssessmentQuestionIcon}
                  questions={questions}
                  displayDomains={displayDomains}
                  displayStandards={displayStandards}
                  displayRows={displayRows}
                  setDisplayRows={setDisplayRows}
                  handleStandardCollapse={handleStandardCollapse}
                  selectedQuestions={selectedQuestions}
                  updateQuestion={updateQuestion}
                  handleFavoriteQuestion={handleFavoriteQuestion}
                  handleFeedbackPopup={handleFeedbackPopup}
                  getStandardQuestions={getStandardQuestions}
                  openQuestionPreview={openQuestionPreview}
                  favoriteQuestionsIds={favoriteQuestionsIds} 
                  selectedSortOption={selectedSortOption} 
                  handleQuestionsSortBy={handleQuestionsSortBy}
                />
              }
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
}

export default NonPassageQuestions;

import React, { useState } from "react";
import { ModalFooter, Modal, ModalBody, Button } from "@windmill/react-ui";
import ReactPlayer from "react-player";
interface videoModalProps {
  url: string;
  isOpne: boolean;
  closeVideoModal(): void;
  notVideoUrl(url: string): void;
}
function VideoModal(props: videoModalProps) {
  const [visible, setVisible] = useState("invisible");
  const inValidatURL = () => {
    setVisible("invisible");
    props.notVideoUrl(props.url);
  };
  const validURL = () => {
    setVisible("visible");
  };
  return (
    <>
      <Modal
        isOpen={props.isOpne}
        onClose={props.closeVideoModal}
        className={`${visible} bg-white rounded-lg p-6`}
      >
        <ModalBody>
          <ReactPlayer
            onContextMenu={(e) => e.preventDefault()}
            url={props.url}
            onError={inValidatURL}
            onReady={validURL}
          ></ReactPlayer>
        </ModalBody>
        <ModalFooter>
          <Button
            className="w-full sm:w-auto"
            layout="outline"
            onClick={props.closeVideoModal}
          >
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}
export default VideoModal;

import { useCallback, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import {
  getAssessmentById,
  getPremiumAssessmentById,
  getPremiumAssessmentQuestionReferences,
  getQuestionReferences,
} from "../../../../../api/teacher/assessment";
import { initRequest } from "./learnosityRequestForPrint";
import { LogoPLIcon } from "../../../../../assets/icons";
import Classification from "../questionPreview/classification";
import { getRequest } from "../../../../../api/userActivity";
import { getAssignmentQuestionReferences } from "../../../../../api/teacher/assignment";
import { initItemRequest } from "./learnosityRequestForItemPrint";

function AssignmentPrint(props: any) {
  console.log(props);
  const [itemReferences, setItemReferences] = useState<string[]>();
  const [standardIds, setStandardIds] = useState<Array<number>>();
  const isInitialized = useRef(false);

  const initialization = useCallback(() => {
    
    if (isInitialized.current) return;
      
    isInitialized.current = true;
    var itemsApp;
    const request = initRequest(props.activityId, props.isAnswerKey);
    const callbacks = {
      readyListener: function () {
        if (props.isAnswerKey) {
          var items = itemsApp.getItems();
          DisplayTotalPointValue(items);
        }
      },

      errorListener: function (e) {
        console.log("Error Code ", e.code);
        console.log("Error Message ", e.msg);
        console.log("Error Detail ", e.detail);
      },
    };
    if (props.id) {
      getQuestionReferences(props.id).then((res) => {
        var data = res.data;
        setItemReferences(data);
        setTimeout(function () {
          getRequest("items", JSON.stringify(request)).then((response) => {
            if (response.data) {
              itemsApp = window["LearnosityItems"]?.init(
                response.data,
                callbacks
              );
              props.setDisablePrintbutton(false);
            }
          });
        }, 1000);
      });
    } else if (props.cannedTestId) {
      getPremiumAssessmentQuestionReferences(props.cannedTestId).then((res) => {
        var data = res.data.data;
        setItemReferences(data);
        setTimeout(function () {
          getRequest("items", JSON.stringify(request)).then((response) => {
            if (response.data) {
              itemsApp = window["LearnosityItems"]?.init(
                response.data,
                callbacks
              );
              props.setDisablePrintbutton(false);
            }
          });
        }, 1000);
      });
    } else if (props.questionReference?.length > 1) {
      setItemReferences([props.questionReference]);
      setTimeout(function () {
        const request = initItemRequest([props.questionReference], false);
        getRequest("items", JSON.stringify(request)).then((response) => {
          if (response.data) {
            itemsApp = window["LearnosityItems"]?.init(
              response.data,
              callbacks
            );
            props.setDisablePrintbutton(false);
          }
        });
      }, 1000);
    } else if (props.isAssignment) {
      getAssignmentQuestionReferences(props.activityId).then((res) => {
        var data = res.data;
        setItemReferences(data);
        setTimeout(function () {
          getRequest("items", JSON.stringify(request)).then((response) => {
            if (response.data) {
              itemsApp = window["LearnosityItems"]?.init(
                response.data,
                callbacks
              );
              props.setDisablePrintbutton(false);
            }
          });
        }, 1000);
      });
    }
  }, []);

  const getAssessmentStandards = () => {
    if (props.id) {
      getAssessmentById(props.id).then((response) => {
        var standardIds = response.data.questions?.map(
          (question, questionIndex) => {
            return question.standardId;
          }
        );
        setStandardIds(standardIds);
      });
    } else if (props.cannedTestId) {
      getPremiumAssessmentById(props.cannedTestId).then((response) => {
        var standardIds = response.data.data.questions?.map(
          (question, questionIndex) => {
            return question.standardId!;
          }
        );
        setStandardIds(standardIds);
      });
    }
  };

  function DisplayTotalPointValue(items) {
    Object.values(items)?.forEach(function (item: any) {
      var questions = item?.questions;
      var score: number = 0;
      if (questions?.length === 1) {
        var question = questions[0];
        var type = question?.type;
        if (type === "longtextV2") {
          score = question?.validation?.max_score ?? 0;
        } else {
          score = question?.validation?.valid_response?.score ?? 0;
        }
      } else {
        questions.forEach(function (question: any) {
          score = score + question?.validation.valid_response.score;
        });
      }
      if (score > 0) {
        let pointValueEl = document.createElement("div");
        pointValueEl.innerHTML = "Point Value: " + score;
        pointValueEl.className = "point-value float-right mt-1.5";
        var divEl = document.getElementById(item.reference);
        divEl?.appendChild(pointValueEl);
      }
    });
  }
  useEffect(() => {
    initialization();
    getAssessmentStandards();
  }, []);

  return (
    <div className="print-container">
      <div className="showInPrint hidden">
        <div className="text-lg font-medium text-gray-900 flex items-center mb-2">
          <LogoPLIcon
            className="block lg:block h-16 w-auto pr-4"
            title="Progress Learning logo"
          ></LogoPLIcon>
          {props.activityName ?? ""}
          {props.code && <>- [{props.code}]</>}
        </div>
      </div>
      {!props.isAnswerKey && (
        <div className="flex space-x-12 mb-6">
          <div className="flex flex-col space-y-2">
            <div className="flex items-end">
              <span className="w-28 text-gray-700">Student Name:</span>
              <span className="w-44 border-b border-gray-700 inline-block"></span>
            </div>
            <div className="flex items-end">
              <span className="w-28 text-gray-700">Score:</span>
              <span className="w-44 border-b border-gray-700 inline-block"></span>
            </div>
          </div>
          <div className="flex flex-col space-y-2">
            <div className="flex">
              <span className="w-28 text-gray-700">Teacher Name:</span>{" "}
              <span className="w-44 border-b border-gray-700 text-gray-700 inline-block">
                {props.profile.firstName + " " + props.profile.lastName}
              </span>
            </div>
            <div className="flex">
              <span className="w-28 text-gray-700">Date:</span>
              <span className="w-44 border-b border-gray-700 inline-block"></span>
            </div>
          </div>
        </div>
      )}
      {itemReferences?.map((itemRef, index) => {
        return (
          <div key={index} className="pl_question_print_container mb-4">
            <div className="pl_question_classification">
              <div id={itemRef}>
                <b>Question {index + 1}</b> :{" "}
                {parseInt(itemRef.split(",")[0] ?? "0")}
              </div>
              {props.isAnswerKey && (
                <>
                  {standardIds && (
                    <div className="bg-gray-100 p-2 mt-1 mb-2">
                      <Classification
                        questionId={parseInt(itemRef.split(",")[0] ?? "0")}
                        standardId={standardIds?.join(",").toString()}
                        questionTypeId={
                          itemRef.indexOf("-teacher") !== -1 ? 2 : 1
                        }
                        assessmentId={props.id}
                      />
                    </div>
                  )}
                </>
              )}
            </div>

            <span className="learnosity-item" data-reference={itemRef}></span>
          </div>
        );
      })}
    </div>
  );
}

const mapStateToProps = (state: any) => {
  return {
    profile: state.profile,
  };
};

export default connect(mapStateToProps, {})(AssignmentPrint);

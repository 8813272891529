import { HorizonEducationLogo } from "../../../../assets/icons";

const HorizonLinks = [
  {
    name: "Benchmark Assessments",
    url: "https://horizoneducation.com/benchmark-assessments",
  },
  {
    name: "Readiness Courses for ACT™️",
    url: "https://horizoneducation.com/readiness-courses-for-the-act-exam",
  },
  {
    name: "Readiness Courses for SAT™️",
    url: "https://horizoneducation.com/sat-readiness-courses",
  },
]

function InstructionalResourcesHorizonEducation() {
  const handleOptionClick = (url: string) => {
    window.open(url);
  }

  return (
    <div className="flex flex-col text-white text-lg p-3 bg-[#F47E26]">
      <div className="flex flex-col gap-y-4 lg:flex-row flex-wrap space-x-0 lg:gap-4">
        <div className="bg-white w-full sm:w-80 p-2 mt-1 rounded-lg hover:shadow-lg transition ease-in-out duration-300">
          <div className="flex items-center rounded-lg border-2 border-gray-700 bg-white">
            <div className="flex items-center justify-center m-1.5 h-10 w-full rounded-md bg-light-biolet text-white">
              <HorizonEducationLogo className="h-full" />
            </div>
          </div>
          <div className="divide-y divide-gray-200">
            {HorizonLinks.map((link, index) => (
              <div 
                key={index}
                className="flex justify-start min-h-[3rem] py-1 items-center text-gray-900 mr-auto text-sm font-medium bg-white px-2 hover:bg-gray-200 shadow cursor-pointer"
                onClick={() => handleOptionClick(link.url)}
              >
                <label className="w-full">
                  <span className="sm:pl-2 text-gray-500 block leading-tight hover:text-primary-violet transition ease-in-out duration-300 cursor-pointer">
                    {link.name}
                  </span>
                </label>
              </div>
            ))}
            
          </div>
        </div>
      </div>
    </div>
  )
}

export default InstructionalResourcesHorizonEducation

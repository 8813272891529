import { useEffect, useState } from "react";
import LearnosityItemAnalysis from "../../LearnosityItemAnalysis";
import {
  ILearnosityItemAnalysis,
  ItemAnalysisUser,
  ItemAnalysisItemReferenceMap,
} from "../../../../../../model/report/LearnosityItemAnalysis";
import Toggle from "../../../../../shared/toggle";
import QuestionClassification from "../../../../../../model/teacher/questionClassification";
import { getQuestionClassification } from "../../../../../../api/teacher/question";
import { IItemAnalysisResult } from "../../../../../../model/report/itemAnalysisResult";

type questionRefClassificationType = {
  key: number;
  value: QuestionClassification;
};
interface ResultsReportItemAnalysisBetaProps {
  data: IItemAnalysisResult;
  standardIds: number[];
}

export const ResultsReportItemAnalysisBeta = (
  props: ResultsReportItemAnalysisBetaProps
) => {
  const [resultsReportItemAnalysisBeta, setResultsReportItemAnalysisBeta] =
    useState<any>();
  const [itemAnalysisEnglishData, setItemAnalysisEnglishData] =
    useState<ILearnosityItemAnalysis>();
  const [itemAnalysisSpanishData, setItemAnalysisSpanishData] =
    useState<ILearnosityItemAnalysis>();
  const [toggle, setToggle] = useState<boolean>(false);
  const [classification, setClassification] = useState<
    questionRefClassificationType[]
  >([]);
  useEffect(() => {
    setResultsReportItemAnalysisBeta(props.data);
  }, []);

  useEffect(() => {
    if (resultsReportItemAnalysisBeta) {
      HandleReportForLanguages();
    }
  }, [resultsReportItemAnalysisBeta]);

  async function HandleReportForLanguages() {
    GetDataForEnglishReport();
    GetDataForSpanishReport();
    GetClassification();
  }
  function GetDataForEnglishReport() {
    var data_en = resultsReportItemAnalysisBeta.students?.filter(
      (s) => s.languageId === 1
    );
    var sessionIds: string[] = [];
    var users: ItemAnalysisUser[] = [];
    var itemRefrencemap: ItemAnalysisItemReferenceMap[] = [];
    data_en?.forEach((en) => {
      sessionIds.push(en.sessionId?.toString());
      var user: ItemAnalysisUser = {
        id: en?.userId.toString(),
        name: en?.lastName + ", " + en?.firstName + "- " + en?.userId,
      };
      users.push(user);
    });
    resultsReportItemAnalysisBeta.questions?.forEach((q, index) => {
      var map: ItemAnalysisItemReferenceMap = {
        reference: q?.englishReferenceId,
        name: "Item " + (index + 1),
      };
      itemRefrencemap.push(map);
    });
    let englishReport: ILearnosityItemAnalysis = {
      sessionIds: sessionIds,
      users: users,
      itemReferenceMap: itemRefrencemap,
    };
    setItemAnalysisEnglishData(englishReport);
  }
  function GetDataForSpanishReport() {
    var data_es = resultsReportItemAnalysisBeta.students?.filter(
      (s) => s.languageId === 2
    );
    if (data_es.length > 0) {
      var sessionIds: string[] = [];
      var users: ItemAnalysisUser[] = [];
      var itemRefrencemap: ItemAnalysisItemReferenceMap[] = [];
      data_es?.forEach((es) => {
        sessionIds.push(es.sessionId?.toString());
        var user: ItemAnalysisUser = {
          id: es?.userId.toString(),
          name: es?.lastName + ", " + es?.firstName + "- " + es?.userId,
        };
        users.push(user);
      });
      resultsReportItemAnalysisBeta.questions?.forEach((q, index) => {
        var map: ItemAnalysisItemReferenceMap = {
          reference: q?.spanishReferenceId,
          name: "Item " + (index + 1),
        };
        itemRefrencemap.push(map);
      });
      let spanishReport: ILearnosityItemAnalysis = {
        sessionIds: sessionIds,
        users: users,
        itemReferenceMap: itemRefrencemap,
      };
      setItemAnalysisSpanishData(spanishReport);
    }
  }
  async function GetClassification() {
    let QuestionRefClassificationArray: Array<questionRefClassificationType> =
      [];
    let count = 0;
    resultsReportItemAnalysisBeta.questions.forEach(async (q: any) => {
      const questionTypeId =
        q.englishReferenceId?.indexOf("-teacher") !== -1 ? 2 : 1;

      await getQuestionClassification(
        q.questionId,
        questionTypeId,
        props.standardIds?.join(","),
        0,
        0
      ).then((res) => {
        if (res.data) {
          const classification: QuestionClassification = res.data;
          const questionRefClassification: questionRefClassificationType = {
            key: q.questionId,
            value: classification,
          };
          QuestionRefClassificationArray.push(questionRefClassification);
        }
        count++;
      });
      if (count === resultsReportItemAnalysisBeta?.questions?.length) {
        setClassification(QuestionRefClassificationArray);
      }
    });
  }
  return (
    <>
      {itemAnalysisSpanishData && (
        <div className="flex gap-x-2 justify-end items-center mt-3">
          <Toggle
            checked={toggle}
            onChange={(e) => {
              setToggle(!toggle);
            }}
          />
          <span className="text-xs">
            {itemAnalysisSpanishData.users.length} Student(s) took this activity
            in Spanish{" "}
          </span>
        </div>
      )}
      {itemAnalysisEnglishData && !toggle && classification.length > 0 && (
        <div>
          <LearnosityItemAnalysis
            data={itemAnalysisEnglishData}
            classification={classification}
            language={"en"}
            standardIds={props.standardIds}
          ></LearnosityItemAnalysis>
        </div>
      )}
      {itemAnalysisSpanishData && toggle && classification.length > 0 && (
        <div>
          <LearnosityItemAnalysis
            data={itemAnalysisSpanishData}
            classification={classification}
            language={"es"}
            standardIds={props.standardIds}
          ></LearnosityItemAnalysis>
        </div>
      )}
    </>
  );
};

import { Fragment, useRef } from "react";
import { StudentsIdCardToPrint } from "../../../print/studentIdCard";
import { PrintIcon } from "../../../../assets/icons/index";
import ReactToPrint from "react-to-print";
import { XIcon } from "@heroicons/react/outline";

function PasswordCard(props: any) {
  const componentRef = useRef(null);

  return (
    <Fragment>
      <div className="fixed inset-0 z-40 flex items-center bg-black bg-opacity-50 sm:items-center sm:justify-center ">
        <div className="w-full flex flex-col justify-between overflow-hidden h-auto max-h-80percent bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:w-full">
          <header>
            {/* Header */}
            <div className="px-4 py-4 bg-gray-50 sm:px-4">
              <div className="flex items-start justify-between space-x-3">
                <div className="space-y-1">
                  <div className="text-lg text-gray-900">Password Cards</div>
                </div>
                <div className="h-7 flex items-center">
                  <button
                    type="button"
                    className="text-gray-400 hover:text-gray-500"
                    onClick={() => {
                      props.handlePasswordCardModal(false);
                    }}
                  >
                    <span className="sr-only">Close panel</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
          </header>

          <div className="w-full relative overflow-auto overflow-x-hidden px-4 py-3 ">
            <StudentsIdCardToPrint
              props={props.userContext}
              mode={props.mode}
              selectedGrade={props.searchQuery?.gradeId}
              selectedClass={props.searchQuery?.classId}
              firstName={props.searchQuery?.firstName}
              lastName={props.searchQuery?.lastName}
              username={props.searchQuery?.username}
              studentId={props.searchQuery?.studentId}
              selectedSchool={props.userContext.schoolId}
              schoolAccountId={props.searchQuery?.schoolAccountId}
              userContext={props.userContext}
              ref={componentRef}
            ></StudentsIdCardToPrint>
          </div>

          <footer className="flex items-center justify-end px-6 py-4 sm:flex-row bg-gray-50 dark:bg-gray-800">
            {/* <!-- Action Buttons -->  */}
            <div className="flex-shrink-0">
              <div className="space-x-3 flex justify-end">
                <ReactToPrint
                  trigger={() => (
                    <button
                      className="align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 rounded-lg text-sm text-white border border-transparent bg-primary-green focus:shadow-outline-purple w-full sm:w-auto"
                      type="button"
                    >
                      <PrintIcon className="w-5 h-5 mr-1"></PrintIcon>
                      Print
                    </button>
                  )}
                  content={() => componentRef.current}
                />
                <button
                  className="align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 rounded-lg text-sm text-gray-600 border-gray-300 border dark:text-gray-400 focus:outline-none active:bg-transparent hover:border-gray-500 focus:border-gray-500 active:text-gray-500 focus:shadow-outline-gray w-full sm:w-auto"
                  type="button"
                  onClick={() => {
                    props.handlePasswordCardModal(false);
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </Fragment>
  );
}
export default PasswordCard;

import { Fragment, useEffect, useState } from "react";
import "../../../../../../css/style.css";
import { connect } from "react-redux";
import OverviewResult from "./overviewResult";
import { useTranslation } from "react-i18next";
import { UserContextState } from "../../../../../../redux/actions/userContextAction";
import Profile from "../../../../../../model/users/profile";
import { getLiftOffDiagnosticResult } from "../../../../../../api/student/liftOff/diagnosticApi";
import LearnosityReport from "../../../../results/learnosityReport";

interface ILiftOffDaignosticResult {
  userContext: UserContextState;
  profile: Profile;
  match: any;
}
const ELLIFTOFFDIAGNOSTICRESULT = (props: ILiftOffDaignosticResult) => {
  const { t } = useTranslation();
  const [diagnosticHeaderResult, setDiagnosticHeaderResult] =
    useState<any>(null);
  const liftOffHeaderId = parseInt(atob(props.match.params.liftOffHeaderId));
  const studentId = parseInt(props.match.params.studentId);
  const [learnosityActivityUserId, setLearnosityActivityUserId] =
    useState<number>(studentId);

  useEffect(() => {
    getResult(props.userContext.userId, liftOffHeaderId);
  }, []);

  function getResult(userId: number, liftOffHeaderId?: number) {
    getLiftOffDiagnosticResult(props.userContext.userId, liftOffHeaderId).then(
      (res) => {
        setLearnosityActivityUserId(
          res.data.learnosityActivityUserId ?? studentId
        );
        setDiagnosticHeaderResult(
          res.data && res.data.headers.length > 0 ? res.data.headers[0] : null
        );
      }
    );
  }
  return (
    <Fragment>
      {diagnosticHeaderResult && (
        <div className="relative bg-teal-light">
          <div className="p-5 md:py-5 md:px-2 lg:p-5 flex flex-col md:flex-row">
            <OverviewResult data={diagnosticHeaderResult} />
            <div className="flex-grow h-auto md:w-1/2">
              {/* flex-grow h-auto md:w-1/2 */}
              <div className="flex w-full mt-6 md:mt-0 pb-6">
                <div>
                  <h1 className="text-2xl font-bold text-white capitalize">
                    {t("LiftOffDiagnosticResult.Title")}
                  </h1>
                  <h2 className="text-base font-medium text-white capitalize">
                    {diagnosticHeaderResult.liftOffHeader}
                  </h2>
                </div>
              </div>
            </div>
          </div>
          <div className="p-5">
            <div className="p-5 bg-gray-50 border rounded-md text-sm font-semibold">
              <h2> {t("LiftOffDiagnosticResult.AnswerStatusByQuestionNo")}</h2>

              <ul className="flex flex-wrap gap-2 w-full xl:w-1/2 mt-3">
                {diagnosticHeaderResult.questions.map((question, index) => {
                  return (
                    <>
                      {question.order > 0 && question.score === question.pointsPossible && (
                        <li className="w-6 h-6 bg-[#B2D136] border border-[#B2D136] text-white text-xs font-semibold flex items-center rounded-sm shadow-sm justify-center hover:bg-transparent hover:text-[#B2D136]">
                          {index + 1}
                        </li>
                      )}
                      {question.order > 0 && question.score < question.pointsPossible && (
                        <li className="w-6 h-6 bg-[#e53f71] border border-[#e53f71] text-white text-xs font-semibold flex items-center rounded-sm shadow-sm justify-center hover:bg-transparent hover:text-[#e53f71]">
                          {index + 1}
                        </li>
                      )}
                    </>
                  );
                })}
              </ul>
            </div>
          </div>
          <div className="m-5 bg-white">
            <LearnosityReport
              sessionId={diagnosticHeaderResult.lernositySessionId}
              user_id={learnosityActivityUserId.toString()}
              show_answers={true}
              wrapperClass=""
            ></LearnosityReport>
          </div>
        </div>
      )}
    </Fragment>
  );
};

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
};
export default connect<{}, {}, {}>(
  mapStateToProps,
  {}
)(ELLIFTOFFDIAGNOSTICRESULT);

import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getAssignmentById } from "../../../../api/teacher/assignment";
import { IAssignment } from "../../../../model/interface/assignment";
import { UserContextState } from "../../../../redux/actions/userContextAction";
import Constant from "../../../../utils/constant/constant";
import RouteConstant from "../../../../utils/constant/routeConstant";
import Permissions from "../../../../utils/permissions";
import AccessDenied from "../../../shared/accessDenied";
import Loader from "../../../shared/loader";
import AllowedTo from "../../../shared/rbac";
import Class from "../assignment/class";
import Content from "../assignment/content";
import Setting from "../assignment/setting";
import Note from "./note";
import Breadcrumb from "../../teacher/shared/breadcrumb";
import { IBreadcrumb } from "../../../../model/common/breadcrumb";
import { useTranslation } from "react-i18next";
import { ISchoolYear } from "../../../../model/teacher/schoolYear";
import { GetSchoolYears } from "../../../../api/teacher/school";
import Profile from "../../../../model/users/profile";
interface passedProps {
  userContext: UserContextState;
  profile: Profile;
}

function NewAssignment(props: passedProps & any) {
  const defaultAssignment: IAssignment = {
    assignmentId: 0,
    name: "",
    subjectId: 0,
    activities: [],
    students: [],
    isGoogleAssignment: false,
    isCanvasAssignment: false,
    completed: 0,
  };
  const [activeTab, setActiveTab] = useState("");
  const [assignment, setAssignment] = useState<IAssignment>(defaultAssignment);
  const [assessmentId, setAssessmentId] = useState<number>();
  const [assessmentName, setAssessmentName] = useState<string>();
  const [assessmentSubjectId, setAssessmentSubjectId] = useState<number>();
  const [loading, setLoading] = useState(false);
  const [schoolYearData, setSchoolYearData] = useState<Array<ISchoolYear>>();
  const { t } = useTranslation();

  function updateAssignment(
    updatedAsignment: IAssignment,
    moveToNextTab: boolean = false
  ) {
    if (moveToNextTab) {
      switch (activeTab) {
        case Constant.ActiveTab.CONTENT:
          setActiveTab(Constant.ActiveTab.CLASS);
          break;
        case Constant.ActiveTab.CLASS:
          setActiveTab(Constant.ActiveTab.NOTES);
          break;
        case Constant.ActiveTab.NOTES:
          setActiveTab(Constant.ActiveTab.SETTING);
          break;
      }
    }

    setAssignment(updatedAsignment);
  }

  function handleSwitchTab(tabName: string) {
    if (assignment.assignmentId > 0 && isValidToSwitchTab(tabName)) {
      setActiveTab(tabName);
    }
  }

  function isValidToSwitchTab(tabName: string) {
    let validToSwitchTab: boolean = false;
    if (assignment.assignmentStatusId === Constant.AssignmentStatusId.DRAFT) {
      switch (activeTab) {
        case Constant.ActiveTab.CONTENT:
          validToSwitchTab = false;
          break;
        case Constant.ActiveTab.CLASS:
          validToSwitchTab = tabName === Constant.ActiveTab.CONTENT;
          break;
        case Constant.ActiveTab.NOTES:
          validToSwitchTab =
            tabName === Constant.ActiveTab.CLASS ||
            tabName === Constant.ActiveTab.CONTENT;
          break;
        case Constant.ActiveTab.SETTING:
          validToSwitchTab = true;
          break;
      }
    } else {
      validToSwitchTab = true;
    }
    return validToSwitchTab;
  }

  useEffect(() => {
    const assignmentId = props.match.params.id;
    if (assignmentId && assignmentId > 0) {
      setLoading(true);
      getAssignmentById(assignmentId).then((response) => {
        setLoading(false);
        setAssignment(response.data);
        const activeTab =
          props.location.state?.editMode === "AssignStudent"
            ? Constant.ActiveTab.CLASS
            : Constant.ActiveTab.CONTENT;
        setActiveTab(activeTab);
      });
    } else {
      const stateData = props.location.state;
      if (stateData && stateData.assessmentId) {
        setAssessmentId(stateData.assessmentId);
        setAssessmentName(stateData.assessmentName);
        setAssessmentSubjectId(stateData.subjectId);
      }
      setActiveTab(Constant.ActiveTab.CONTENT);
    }    
  }, []);
  useEffect(() => {
    GetSchoolYears(props.userContext?.schoolId!).then((response) => {
      if (response.data) {
        var currentfutureschoolyears = response.data.filter(
          (y) => y.currentYearOffset >= 0
        );
        setSchoolYearData(currentfutureschoolyears);       
      }
    });
  }, []);

  const breadcrumbItems = () => {
    const items: Array<IBreadcrumb> = [
      { name: t("Breadcrumb.Home"), url: RouteConstant.ROUTE_DASHBOARD },
      {
        name: "Assessment/Assignment Center",
        url: RouteConstant.TeacherRoutes.assignmentCenter,
      },
      { name: t("Breadcrumb.Assignment"), url: RouteConstant.ASSIGNMENTBANK },

      {
        name: `${
          assignment.assignmentId > 0
            ? t("Breadcrumb.EditAssignment")
            : t("Breadcrumb.CreateNewAssignment")
        }`,
        url: "",
      },
    ];

    return items;
  };

  return (
    <AllowedTo
      perform={[Permissions.assignments_create, Permissions.assignments_modify]}
      no={() => <AccessDenied />}
    >
      <div className="min-w-0 w-full">
        {loading && <Loader />}
        <div className="lg:flex lg:items-center px-4 py-3 lg:px-8 border-b border-gray-200 ">
          <Breadcrumb items={breadcrumbItems()} />
          <div className="lg:w-[55%] text-lg flex justify-between gap-2 h-auto sm:h-10 items-center">
            <h1 className="text-primary-violet font-medium flex justify-start mb-0 pb-0">
              {`${
                assignment.assignmentId > 0
                  ? "Edit Assignment"
                  : "Create New Assignment"
              }`}
            </h1>
          </div>
        </div>
        <div className="lg:border-b lg:border-gray-200 ">
          <nav className="px-4 sm:pl-6 lg:pl-8 lg:pr-0" aria-label="Progress">
            <ol className="rounded-md overflow-hidden lg:flex lg:border-l lg:border-r lg:border-gray-200 lg:rounded-none">
              <li
                className={`relative overflow-hidden lg:flex-1 ${
                  activeTab === Constant.ActiveTab.CONTENT
                    ? "bg-primary-violet"
                    : ""
                } `}
                onClick={() => handleSwitchTab(Constant.ActiveTab.CONTENT)}
              >
                <div
                  className={`${
                    activeTab === Constant.ActiveTab.CONTENT
                      ? ""
                      : "border border-white"
                  }   border border-b-0 border-gray-200 overflow-hidden lg:border-0`}
                >
                  <a href="#!" aria-current="step" x-description="Current Step">
                    <span
                      className={`absolute top-0 left-0 w-1 h-full  ${
                        activeTab === Constant.ActiveTab.CONTENT
                          ? "bg-primary-violet"
                          : "bg-transparent group-hover:bg-gray-200 "
                      } lg:w-full lg:h-0.5 lg:bottom-0 lg:top-auto`}
                      aria-hidden="true"
                    ></span>
                    <span className="px-6 py-3 flex items-center text-sm font-medium lg:pl-9">
                      <span className="flex-shrink-0">
                        <span
                          className={`w-10 h-10 flex items-center justify-center border-2  ${
                            activeTab === Constant.ActiveTab.CONTENT
                              ? "border-white"
                              : "border-gray-300"
                          } rounded-full`}
                        >
                          <span
                            className={
                              activeTab === Constant.ActiveTab.CONTENT
                                ? "text-white"
                                : "text-gray-500"
                            }
                          >
                            01
                          </span>
                        </span>
                      </span>
                      <span className="mt-0.5 ml-4 min-w-0 flex flex-col">
                        <span
                          className={`text-sm font-semibold  ${
                            activeTab === Constant.ActiveTab.CONTENT
                              ? "text-white"
                              : "text-gray-500"
                          } tracking-wide uppercase`}
                        >
                          Content
                        </span>
                      </span>
                    </span>
                  </a>
                </div>
              </li>
              <li
                className={`relative overflow-hidden lg:flex-1 ${
                  activeTab === Constant.ActiveTab.CLASS
                    ? "bg-primary-violet"
                    : ""
                } `}
                onClick={() => handleSwitchTab(Constant.ActiveTab.CLASS)}
              >
                <div
                  className={`${
                    activeTab === Constant.ActiveTab.CLASS
                      ? ""
                      : "border border-white"
                  }   border border-b-0 border-gray-200 overflow-hidden lg:border-0`}
                >
                  <a href="#!" aria-current="step" x-description="Current Step">
                    <span
                      className={`absolute top-0 left-0 w-1 h-full  ${
                        activeTab === Constant.ActiveTab.CLASS
                          ? "bg-primary-violet"
                          : "bg-transparent group-hover:bg-gray-200 "
                      } lg:w-full lg:h-0.5 lg:bottom-0 lg:top-auto`}
                      aria-hidden="true"
                    ></span>
                    <span className="px-6 py-3 flex items-center text-sm font-medium lg:pl-9">
                      <span className="flex-shrink-0">
                        <span
                          className={`w-10 h-10 flex items-center justify-center border-2  ${
                            activeTab === Constant.ActiveTab.CLASS
                              ? "border-white"
                              : "border-gray-300"
                          } rounded-full`}
                        >
                          <span
                            className={
                              activeTab === Constant.ActiveTab.CLASS
                                ? "text-white"
                                : "text-gray-500"
                            }
                          >
                            02
                          </span>
                        </span>
                      </span>
                      <span className="mt-0.5 ml-4 min-w-0 flex flex-col">
                        <span
                          className={`text-sm font-semibold  ${
                            activeTab === Constant.ActiveTab.CLASS
                              ? "text-white"
                              : "text-gray-500"
                          } tracking-wide uppercase`}
                        >
                          Class
                        </span>
                      </span>
                    </span>
                  </a>
                  <div
                    className={`hidden absolute top-0 -left-${
                      activeTab === Constant.ActiveTab.CONTENT ? "0.5" : "0"
                    } w-3 inset-0 lg:block`}
                    aria-hidden="true"
                  >
                    {activeTab !== Constant.ActiveTab.CLASS && (
                      <svg
                        className={`h-full w-full ${
                          activeTab === Constant.ActiveTab.CONTENT
                            ? " text-primary-violet fill-current"
                            : " text-gray-300"
                        }`}
                        viewBox="0 0 12 82"
                        fill="none"
                        preserveAspectRatio="none"
                      >
                        <path
                          d="M0.5 0V31L10.5 41L0.5 51V82"
                          stroke="currentcolor"
                          vectorEffect="non-scaling-stroke"
                        ></path>
                      </svg>
                    )}
                  </div>
                </div>
              </li>
              <li
                className={`relative overflow-hidden lg:flex-1 ${
                  activeTab === Constant.ActiveTab.NOTES
                    ? "bg-primary-violet"
                    : ""
                } `}
                onClick={() => handleSwitchTab(Constant.ActiveTab.NOTES)}
              >
                <div
                  className={`${
                    activeTab === Constant.ActiveTab.NOTES
                      ? ""
                      : "border border-b-0 border-gray-200 "
                  }  border border-gray-200 border border-b-0 border-gray-200 overflow-hidden lg:border-0`}
                >
                  <a href="#!" aria-current="step" x-description="Current Step">
                    <span
                      className={`absolute top-0 left-0 w-1 h-full  ${
                        activeTab === Constant.ActiveTab.NOTES
                          ? "bg-primary-violet"
                          : "bg-transparent group-hover:bg-gray-200 "
                      } lg:w-full lg:h-0.5 lg:bottom-0 lg:top-auto`}
                      aria-hidden="true"
                    ></span>
                    <span className="px-6 py-3 flex items-center text-sm font-medium lg:pl-9">
                      <span className="flex-shrink-0">
                        <span
                          className={`w-10 h-10 flex items-center justify-center border-2  ${
                            activeTab === Constant.ActiveTab.NOTES
                              ? "text-white"
                              : "border-gray-300"
                          } rounded-full`}
                        >
                          <span
                            className={
                              activeTab === Constant.ActiveTab.NOTES
                                ? "text-white"
                                : "text-gray-500"
                            }
                          >
                            03
                          </span>
                        </span>
                      </span>
                      <span className="mt-0.5 ml-4 min-w-0 flex flex-col">
                        <span
                          className={`text-sm font-semibold  ${
                            activeTab === Constant.ActiveTab.NOTES
                              ? "text-white"
                              : "text-gray-500"
                          } tracking-wide uppercase`}
                        >
                          Notes
                        </span>
                      </span>
                    </span>
                  </a>
                  <div
                    className={`hidden absolute top-0 -left-${
                      activeTab === Constant.ActiveTab.CLASS ? "0.5" : "0"
                    } w-3 inset-0 lg:block`}
                    aria-hidden="true"
                  >
                    {activeTab !== Constant.ActiveTab.NOTES && (
                      <svg
                        className={`h-full w-full ${
                          activeTab === Constant.ActiveTab.CLASS
                            ? " text-primary-violet fill-current"
                            : " text-gray-300"
                        }`}
                        viewBox="0 0 12 82"
                        fill="none"
                        preserveAspectRatio="none"
                      >
                        <path
                          d="M0.5 0V31L10.5 41L0.5 51V82"
                          stroke="currentcolor"
                          vectorEffect="non-scaling-stroke"
                        ></path>
                      </svg>
                    )}
                  </div>
                </div>
              </li>
              <li
                className={`relative overflow-hidden lg:flex-1 ${
                  activeTab === Constant.ActiveTab.SETTING
                    ? "bg-primary-violet"
                    : ""
                } `}
                onClick={() => handleSwitchTab(Constant.ActiveTab.SETTING)}
              >
                <div
                  className={`${
                    activeTab === Constant.ActiveTab.SETTING
                      ? ""
                      : "border lg:border-0 border-gray-200 "
                  }  border lg:border-0 border-b border-gray-200 overflow-hidden lg:border-00`}
                >
                  <a href="#!" aria-current="step" x-description="Current Step">
                    <span
                      className={`absolute top-0 left-0 w-1 h-full  ${
                        activeTab === Constant.ActiveTab.SETTING
                          ? "bg-primary-violet"
                          : "bg-transparent group-hover:bg-gray-200 "
                      } lg:w-full lg:h-0.5 lg:bottom-0 lg:top-auto`}
                      aria-hidden="true"
                    ></span>
                    <span className="px-6 py-3 flex items-center text-sm font-medium lg:pl-9">
                      <span className="flex-shrink-0">
                        <span
                          className={`w-10 h-10 flex items-center justify-center border-2  ${
                            activeTab === Constant.ActiveTab.SETTING
                              ? "border-white"
                              : "border-gray-300"
                          } rounded-full`}
                        >
                          <span
                            className={
                              activeTab === Constant.ActiveTab.SETTING
                                ? "text-white"
                                : "text-gray-500"
                            }
                          >
                            04
                          </span>
                        </span>
                      </span>
                      <span className="mt-0.5 ml-4 min-w-0 flex flex-col">
                        <span
                          className={`text-sm font-semibold  ${
                            activeTab === Constant.ActiveTab.SETTING
                              ? "text-white"
                              : "text-gray-500"
                          } tracking-wide uppercase`}
                        >
                          Settings
                        </span>
                      </span>
                    </span>
                  </a>
                  <div
                    className={`hidden absolute top-0 -left-${
                      activeTab === Constant.ActiveTab.NOTES ? "0.5" : "0"
                    } w-3 inset-0 lg:block`}
                    aria-hidden="true"
                  >
                    {activeTab !== Constant.ActiveTab.SETTING && (
                      <svg
                        className={`h-full w-full ${
                          activeTab === Constant.ActiveTab.NOTES
                            ? " text-primary-violet fill-current"
                            : " text-gray-300"
                        }`}
                        viewBox="0 0 12 82"
                        fill="none"
                        preserveAspectRatio="none"
                      >
                        <path
                          d="M0.5 0V31L10.5 41L0.5 51V82"
                          stroke="currentcolor"
                          vectorEffect="non-scaling-stroke"
                        ></path>
                      </svg>
                    )}
                  </div>

                  {/* {activeTab === Constant.ActiveTab.NOTES && (
                    <div
                      className="hidden absolute top-0 -left-0.5 w-3 inset-0 lg:block"
                      aria-hidden="true"
                    >
                      <svg
                        className={`h-full w-full ${
                          activeTab === Constant.ActiveTab.NOTES
                            ? " text-primary-violet"
                            : " text-gray-300"
                        } fill-current`}
                        viewBox="0 0 12 82"
                        fill="none"
                        preserveAspectRatio="none"
                      >
                        <path
                          d="M0.5 0V31L10.5 41L0.5 51V82"
                          stroke="currentcolor"
                          vectorEffect="non-scaling-stroke"
                        ></path>
                      </svg>
                    </div>
                  )} */}
                </div>
              </li>
            </ol>
          </nav>
        </div>
        {activeTab === Constant.ActiveTab.CONTENT && (
          <Content
            userId={props.userContext.userId}
            roleId={props.userContext.roleId}
            assignment={assignment}
            updateAssignment={updateAssignment}
            assessmentId={assessmentId}
            assessmentName={assessmentName}
            assessmentSubjectId={assessmentSubjectId}
            schoolYearData={schoolYearData}
            isEditMode={props.match.params.id > 0}
            profile={props.profile}
          />
        )}
        {activeTab === Constant.ActiveTab.CLASS && (
          <Class
            userId={props.userContext.userId}
            roleId={props.userContext.roleId}
            assignment={assignment}
            updateAssignment={updateAssignment}
          />
        )}
        {activeTab === Constant.ActiveTab.NOTES && (
          <Note
            userId={props.userContext.userId}
            assignment={assignment}
            updateAssignment={updateAssignment}
          />
        )}
        {activeTab === Constant.ActiveTab.SETTING && (
          <Setting
            userId={props.userContext.userId}
            assignment={assignment}
            updateAssignment={updateAssignment}
            schoolYearData={schoolYearData}
          />
        )}
        <div className="px-4 py-3 bg-gray-50 text-right sm:px-6  block lg:hidden">
          <button
            type="submit"
            className="bg-primary-violet border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Save & Continue
          </button>
        </div>
      </div>
    </AllowedTo>
  );
}

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(NewAssignment);

import React, { useState, useEffect } from "react";
import {
  AwardImage,
  GradedWorkImage,
  MilestonesImage,
  StarsImage,
} from "../../../../assets/images/index";
import { UserContextState } from "../../../../redux/actions/userContextAction";
import GalaxyStarPopup from "./popup/galaxyStarPopup";
import GameScorePopup from "./popup/gameScorePopup";
import RocketPopup from "./popup/rocketPopup";
import BlasterPopup from "./popup/blasterPopup";
import { getStudentRewards } from "../../../../api/student/studentApi";
import Loader from "../../../shared/loader";
import Profile from "../../../../model/users/profile";
import routeConstant from "../../../../utils/constant/routeConstant";
import { useHistory } from "react-router-dom";
import AlienAvatar from "../shared/alienAvatar/alienAvatar";
import StudentProfile from "../../../../model/student/studentProfile";
import SubjectSelectionPopup from "./popup/subjectSelectionPopup";
import { IStudentReward } from "../../../../model/interface/studentReward";
import { getDefaultRewards } from "../../../../utils/studentHelper";
import AlienRanking from "./popup/alienRanking";
import { IContentAreaSubject, ISubject } from "../../../../model/interface/subject";
import { getContentAreaNameFromSubject, getSubjectName } from "../../../../utils/helper";

export interface AchievementsProps {
  userContext?: UserContextState;
  profile?: Profile;
  studentProfile: StudentProfile;
  subjectsData: Array<IContentAreaSubject>,
}

const Achievements: React.FC<AchievementsProps> = (props) => {
  const [showGalaxyStarPopup, setShowGalaxyStarPopup] = useState(false);
  const [showRocketPopup, setShowRocketPopup] = useState(false);
  const [showGameScorePopup, setShowGameScorePopup] = useState(false);
  const [showBlasterPopup, setShowBlasterPopup] = useState(false);
  const [rewards, setRewards] = useState<IStudentReward>(getDefaultRewards());
  const [showLoader, setLoader] = useState(false);
  const [showProgressReport, setShowProgressReport] = useState(false);
  const [subjects, setSubjects] = useState<any>([]);
  const history = useHistory();

  useEffect(() => {
    setLoader(true);
    createSubjectArray(props.subjectsData);
    getStudentRewards(props.userContext?.userId ?? 0).then((d) => {
      setLoader(false);
      setRewards(d.data);
    });
  }, []);

  const createSubjectArray = (subjects: Array<IContentAreaSubject>) => {
    var subjectArray: any = [];

    subjects.forEach((contentArea) => {
      if (contentArea.subjects.length > 1) {
        let updatedArray = contentArea.subjects.map((item) => {
          item.spanishDisplayName = item.spanishDisplayName ?? item.name;
          return item;
        });
        subjectArray.push.apply(subjectArray, updatedArray);
      } else {
        subjectArray.push.apply(subjectArray, contentArea.subjects);
      }
    });
    setSubjects(subjectArray);
  }

  function handleGradedWorkClick() {
    history.push({
      pathname: routeConstant.KindergartenStudentRoutes.GradedWork,
    });
  }

  return (
    <>
      {showLoader && <Loader></Loader>}

      <div className="flex flex-col gap-y-6 md:mr-6 pt-4">
        <div className="flex flex-row mr-5 md:mr-0 justify-between md:flex-col">
          <div className="block  lg:absolute w-[130px] lg:w-[110px] z-10 top-5 left-7 transition ease-in-out text-center space-y-4 lg:space-y-2 xl:space-y-4 ml-5 md:ml-0 md:mb-5">
            {
              <AlienRanking
                studentId={props.userContext!.userId}
                gradeId={props.profile?.gradeId ? props.profile?.gradeId : 0}
                firstName={props.profile?.firstName!}
                lastName={props.profile?.lastName!}
              />
            }
          </div>
          <div className="bg-transparent lg:bg-transparent lg:top-5 w-[8rem]">
            <div className="md:block border-4 border-opacity-50 rounded-2xl overflow-hidden cursor-pointer hover:border-opacity-100 transition ease-in-out">
              {props.studentProfile && (
                <AlienAvatar
                  studentId={props.userContext!.userId}
                  gradeId={props.profile?.gradeId ? props.profile?.gradeId : 0}
                  alienData={props.studentProfile.plAvatar}
                  showOnQuestions={props.studentProfile.plShowAvatarOnQuestions}
                  studentGradeMode="Kindergarten"
                  applykinderGartenStyle={true}
                  rewards={rewards}
                />
              )}
            </div>
          </div>
        </div>
        

        
        <ul className="flex-shrink-0 flex w-[90%] md:flex-col transition ease-in-out mx-auto rounded-lg z-[1] gap-y-2">
          <li
            className="mx-auto flex flex-col"
            onClick={() => {
              setShowGalaxyStarPopup(true);
            }}
          >
            <div className="flex-shrink-0 flex m-auto items-center bg-[#90d12a] border md:border-2 border-[#4e9926] p-px md:p-1 rounded-full group cursor-pointer">
              <span className="bg-[#051834] relative p-5 rounded-full border md:border-2 ring-6 ring-[#90d12a] ring-offset-6 overflow-hidden relative">
                <span className="w-10 h-44 bg-white absolute z-0 -top-5 opacity-5 transform  left-6 rotate-45 group-hover:opacity-10 group-hover:rotate-12 transition ease-in-out duration-1000"></span>
                <span className="w-3 h-44 bg-white absolute z-0 -top-5  opacity-5 left-20 transform rotate-45 group-hover:opacity-20 group-hover:rotate-12 transition ease-in-out duration-1000"></span>
                <StarsImage className="w-7 relative group-hover:scale-125 group-hover:-translate-y-2 transition ease-in-out duration-500" />
                {rewards?.plStarReward && (
                  <p className="text-center font-bold text-white absolute bottom-0.5 left-0.5 right-0 mx-auto inline-block w-9 text-xs ">{`${rewards?.plStarReward?.earnedStar}/${rewards?.plStarReward?.totalStar}`}</p>
                )}
              </span>
            </div>
            <span className="text-xs bloack text-white flex justify-center text-center mx-auto md:flex-none md:w-auto">
              Galaxy Stars
            </span>
          </li>

          <li
            className="mx-auto flex flex-col hidden"
            title="Award"
            onClick={() => {
              setShowGameScorePopup(true);
            }}
          >
            <div className=" flex-shrink-0 flex items-center bg-[#90d12a] border md:border-2 border-[#4e9926] p-px md:p-1 rounded-full group cursor-pointer">
              <span className="bg-[#051834]  p-3 lg:p-4 xl:p-4 2xl:p-6 rounded-full border md:border-2 ring-6 ring-[#90d12a] ring-offset-6 overflow-hidden relative">
                <span className="w-10 h-44 bg-white absolute z-0 -top-5 opacity-5 transform  left-6 rotate-45 group-hover:opacity-10 group-hover:rotate-12 transition ease-in-out duration-1000"></span>
                <span className="w-3 h-44 bg-white absolute z-0 -top-5  opacity-5 left-20 transform rotate-45 group-hover:opacity-20 group-hover:rotate-12 transition ease-in-out duration-1000"></span>
                <AwardImage className="w-7 h-7 relative group-hover:scale-125 group-hover:-translate-y-1 transition ease-in-out duration-500" />
              </span>
            </div>
            <span className="text-xs inline-block text-white ">
              Game Scores
            </span>
          </li>

          <li
            className="mx-auto flex flex-col"
            title="Progress Report"
            onClick={() => {
              setShowProgressReport(true);
            }}
          >
            <div className="flex-shrink-0 flex m-auto items-center bg-[#90d12a] border md:border-2 border-[#4e9926] p-px md:p-1 rounded-full group cursor-pointer">
              <span className="bg-[#051834] relative p-5 rounded-full border md:border-2 ring-6 ring-[#90d12a] ring-offset-6 overflow-hidden relative">
                <span className="w-10 h-44 bg-white absolute z-0 -top-5 opacity-5 transform  left-6 rotate-45 group-hover:opacity-10 group-hover:rotate-12 transition ease-in-out duration-1000"></span>
                <span className="w-3 h-44 bg-white absolute z-0 -top-5  opacity-5 left-20 transform rotate-45 group-hover:opacity-20 group-hover:rotate-12 transition ease-in-out duration-1000"></span>
                <MilestonesImage className="w-7 h-7 relative group-hover:scale-125 group-hover:-translate-y-1 transition ease-in-out duration-500" />
              </span>
            </div>
            <span className="text-xs inline-bloack text-white">
              Progress Report
            </span>
          </li>

          <li
            className="mx-auto flex flex-col"
            title="Graded Work"
            onClick={() => {
              handleGradedWorkClick();
            }}
          >
            <div className="flex-shrink-0 flex m-auto items-center bg-[#90d12a] border md:border-2 border-[#4e9926] p-px md:p-1 rounded-full group cursor-pointer">
              <span className="bg-[#051834]  relative p-5 rounded-full border md:border-2 ring-6 ring-[#90d12a] ring-offset-6 overflow-hidden relative">
                <span className="w-10 h-44 bg-white absolute z-0 -top-5 opacity-5 transform  left-6 rotate-45 group-hover:opacity-10 group-hover:rotate-12 transition ease-in-out duration-1000"></span>
                <span className="w-3 h-44 bg-white absolute z-0 -top-5  opacity-5 left-20 transform rotate-45 group-hover:opacity-20 group-hover:rotate-12 transition ease-in-out duration-1000"></span>
                <GradedWorkImage className="w-7 h-7  relative group-hover:scale-125 group-hover:-translate-y-1 transition ease-in-out duration-500" />
              </span>
            </div>
            <span className="text-xs inline-bloack text-white ">
              Graded Work
            </span>
          </li>
        </ul>
      </div>

      {showGalaxyStarPopup && (
        <GalaxyStarPopup rewards={rewards} showPopup={setShowGalaxyStarPopup} />
      )}
      {showRocketPopup && (
        <RocketPopup rewards={rewards} showPopup={setShowRocketPopup} />
      )}
      {showBlasterPopup && (
        <BlasterPopup
          userContext={props.userContext}
          rewards={rewards}
          type={"PL"}
          showPopup={setShowBlasterPopup}
        />
      )}
      {showGameScorePopup && (
        <GameScorePopup
          showPopup={setShowGameScorePopup}
          userContext={props.userContext}
          profile={props.profile}
        />
      )}
      {showProgressReport && (
        <SubjectSelectionPopup
          showPopup={(s) => {
            setShowProgressReport(false);
          }}
          subjects={subjects}
          formatSubject={(s: ISubject) => {
            return getContentAreaNameFromSubject(s, props.profile) + " - " + getSubjectName(s, props.profile);
          }}
          selectSubject={(s) => {
            history.push(
              routeConstant.KindergartenStudentRoutes.ProgressReport.replace(
                ":subjectId",
                s.subjectId.toString()
              )
            );
          }}
        />
      )}
    </>
  );
};

export default Achievements;

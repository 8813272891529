import { ExclamationIcon, XIcon } from "@heroicons/react/solid";
import { Fragment } from "react";
import { connect } from "react-redux";
interface IRetryMissedItem {
  languageCode: string;
  onSuccess: (isSuccess: boolean) => void;
  show: (show: boolean) => void;
}

const text = {
  en: {
    RetryTitle: "Do you want to retry missed items?",
    Yes: "Yes, I want to retry the items I missed",
    No: "No, I do not want to retry the items I missed",
    RetryMessage: "You have the opportunity to retry the questions you missed.",
    Warning:
      "Do NOT refresh the page! This is the only time you will be able to retry any missed items for this activity.",
    Info: "Questions must be completed and submitted in 1 attempt.",
  },
  es: {
    RetryTitle: "¿Desea volver a intentar los elementos perdidos?",
    Yes: "Sí, quiero volver a intentar los elementos que me perdí",
    No: "No, no quiero volver a intentar los elementos que me perdí.",
    RetryMessage:
      "Tiene la oportunidad de volver a intentar las preguntas que falló.",
    Warning:
      "¡NO actualices la página! Esta es la única vez que podrá volver a intentar cualquier elemento perdido para esta actividad.",
    Info: "Las preguntas deben completarse y enviarse en 1 intento.",
  },
};

function RetryMissedItemsPopup(props: IRetryMissedItem) {
  const { languageCode } = props;

  return (
    <Fragment>
      <div className="fixed inset-0 z-40 flex items-center bg-black bg-opacity-50 sm:items-center sm:justify-center">
        <div className="w-[42rem] relative flex flex-col justify-between overflow-hidden h-auto max-h-80percent bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:max-w-6xl">
          <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
            <button
              type="button"
              className="inline-flex items-center justify-center w-6 h-6 text-gray-400 transition-colors duration-150 rounded dark:hover:text-gray-200 hover: hover:text-gray-700"
              onClick={() => {
                props.onSuccess(false);
              }}
            >
              <span className="sr-only">Close</span>
              <XIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="flex items-center p-4 mb-6 mx-auto">
            <div className="mt-4 flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-yellow-100 ">
              <ExclamationIcon
                className="h-6 w-6 text-yellow-500"
                aria-hidden="true"
              />
            </div>
            <div className="text-center mt-4 ml-4">
              <h3
                className="text-lg leading-6 text-gray-700"
                id="headlessui-dialog-title-50"
              >
                {text[languageCode].RetryTitle}
              </h3>
            </div>
          </div>
          <div className="px-16 w-full gap-y-4 flex flex-col mb-6">
            <button
              type="button"
              className="align-bottom w-full inline-flex items-center whitespace-nowrap justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-4 rounded-lg text-base text-white border border-transparent bg-primary-green focus:shadow-outline-purple"
              onClick={() => {
                props.onSuccess(true);
              }}
            >
              {text[languageCode].Yes}
            </button>
            <button
              type="button"
              className="align-bottom w-full inline-flex items-center whitespace-nowrap justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-4 rounded-lg text-base text-gray-700 hover:text-gray-500 border border-gray-300 focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              onClick={() => {
                props.onSuccess(false);
              }}
            >
              {text[languageCode].No}
            </button>
          </div>

          <div className="bg-gray-50 mx-6 p-4 text-normal text-gray-700 mb-6">
            <span>
              {text[languageCode].RetryMessage}{" "}
              <span className="text-red-500 pr-1">
                {text[languageCode].Warning}
              </span>
              {text[languageCode].Info}
            </span>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(RetryMissedItemsPopup);
import { AxiosHelper } from "../../environment/axiosConfig";
import APIConstant from "../../utils/constant/apiConstant";

export const getLiftoffClassGalaxySummaryReport = (
  schoolId: number,
  subjectId: number,
  classId?: number,
  gradeId?: number
) => {
  return AxiosHelper.axiosInstance().get(
    APIConstant.LIFTOFFCLASSGALAXYSUMMARY_API
      .GetLiftoffClassGalaxySummaryReport,
    {
      params: {
        schoolId: schoolId,
        subjectId: subjectId,
        classId: classId,
        gradeId: gradeId,
      },
    }
  );
};

export const getLiftoffClassGalaxySummaryReportExcel = (
    schoolId: number,
    subjectId: number,
    classId?: number,
    gradeId?: number
) => {
    return AxiosHelper.axiosInstance().get(
        APIConstant.LIFTOFFCLASSGALAXYSUMMARY_API
            .GetLiftoffClassGalaxySummaryReportExcel,
        {
            responseType: "blob",
            params: {
                schoolId: schoolId,
                subjectId: subjectId,
                classId: classId,
                gradeId: gradeId,
            },
        }
    );
};
import { RemoveIcon } from "../../../../assets/icons";
import { getFormatedMathText } from "../../../../utils/helper";
import { PhotographIcon } from "@heroicons/react/outline";
import { IAssessmentQuestion } from "../../../../model/interface/assessmentQuestion";
import {Configuration} from "../../../../environment/setup";

interface AssessmentQuestionRowContentProps {
  isGroup: boolean;
  index: number;
  // provided: any;
  question: IAssessmentQuestion;
  openModal: (
    learnosityReferenceId,
    questionId,
    questionText,
    currentIndex,
    standardId,
    assessmentQuestionTypeId,
    passageId,
    spanishLearnosityReferenceId
  ) => void;
  allowEdit: boolean;
  handleDeleteQuestion: (questionId: number) => void;
  districtId: number;
  hasDistrictAssessmentQuestionIcon: boolean
}

function AssessmentQuestionRowContent(
  props: AssessmentQuestionRowContentProps
) {
  let index = props.index;
  let question = props.question;
  const openModal = props.openModal;
  const allowEdit = props.allowEdit;
  const handleDeleteQuestion = props.handleDeleteQuestion;

  return (
    <>
      <div className="ml-8 px-3 py-2 w-[20%] lg:w-[20%] xl:w-[20%] 1xl:w-[25%] xxl:w-[35%] xxxl:w-[50%] flex-grow">
        <span className="flex gap-2 mathdiv flex-wrap">
          {question.passageTitle && !props.isGroup && (
            <i>{question.passageTitle} - </i>
          )}
          {getFormatedMathText(
            question.questionText
              ?.replace(/^<img[^>]*>/, "")
              .replace(/^(<br\s*\/?>\s*)+/i, "")
              .trim() ?? ""
          )?.map((element, index) => {
            return (
              <>
                {element.type === "math" && (
                  <>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: element.object ?? "",
                      }}
                    ></span>
                  </>
                )}

                {element.type === "text" && (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: element.object ?? "",
                    }}
                  ></span>
                )}
              </>
            );
          })}
        </span>
        <div>
          {question.questionText?.trim() === "" ? (
            <PhotographIcon className="w-5 h-5 text-gray-500" />
          ) : undefined}
        </div>
      </div>
        <div className="w-[60px] xl:w-[130px] px-3 py-2 items-center flex justify-center">
            {props.hasDistrictAssessmentQuestionIcon && question.isDistrictAssessmentQuestion ? (
                <img alt="" className=""
                     src={`${Configuration.S3bucketImagePath}/districts/${props.districtId.toString()}/district-assessment-question-icon.png`}/>) : undefined}
        </div>
        <div className="w-[80px] xl:w-[150px] px-3 py-2 text-center flex-none">
            <div>{question.pointsPossible}</div>
        </div>

      <div className="w-[100px] xl:w-[150px] px-3 py-2 flex-none">
        <div>{question.questionType}</div>
      </div>
      <div className="w-[100px] lg:w-[130px] xl:w-[150px] px-3 py-2 flex-none">
        <div>{question.domainName}</div>
      </div>
      <div className="px-3 py-2 w-32 lg:w-32 xl:w-40 flex-none">
        <div>{`${question.standardCode} ${question.standardName}`}</div>
      </div>
      <div className="w-[5.5rem] lg:w-[5.5rem] xl:w-28 1xl:w-36 px-3 py-2 flex-none">
        <a
          href={void 0}
          className="text-blue-500 underline hover:no-underline"
          onClick={() =>
            question.learnosityReferenceId
              ? openModal(
                  question.learnosityReferenceId,
                  question.questionId,
                  question.questionText,
                  index,
                  question.standardId,
                  question.assessmentQuestionTypeId,
                  question.passageId,
                  question.spanishLearnosityReferenceId
                )
              : undefined
          }
        >
          Preview or Replace
        </a>
      </div>
      {allowEdit && (
        <div className="w-5 mr-1 flex-none">
          <RemoveIcon
            title="Remove question"
            onClick={() => handleDeleteQuestion(question.questionId)}
            className="h-4 text-gray-500 hover:text-gray-700 cursor-pointer"
          />
        </div>
      )}
    </>
  );
}
export default AssessmentQuestionRowContent;

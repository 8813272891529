import { Fragment, useEffect, useState } from "react";
import {
  exportResultsAssessmentStudentDetailsBySchoolExcel,
  exportResultsPremiumAssessmentStudentDetailsBySchoolExcel,
  getResultsAssessmentStudentDetailsBySchool,
  getResultsPremiumAssessmentStudentDetailsBySchool,
} from "../../../../../../api/report/resultsReport";
import {
  DEFAULT_PAGE_NUMBER,
  DEFAULT_PAGE_SIZE,
} from "../../../../../../utils/pagingConstant";
import Loader from "../../../../../shared/loader";
import NoData from "../../../../../shared/noData";
import PageSize from "../../../../../shared/pagination/pageSize";
import Pagination from "../../../../../shared/pagination/pagination";
import constant from "../../../../../../utils/constant/constant";
import { Info } from "../../../../../../assets/icons";

interface IResultReportAssessmentStudentDetailsBySchoolInput {
  schoolYearId: number;
  schoolIds: number[];
  assessmentId?: number;
  type: string;
}

export const ResultReportAssessmentStudentDetailsBySchool = (
  props: IResultReportAssessmentStudentDetailsBySchoolInput
) => {
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [pageNumber, setPageNumber] = useState(DEFAULT_PAGE_NUMBER);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [resetPageNumber, setResetPageNumber] = useState<boolean>(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [resultDetails, setResultDetails] = useState<Array<any>>([]);
  const [isSchoolChanged, setIsSchoolChanged] = useState<boolean>(true);

  const onPageChange = (pageNo) => {
    setIsSchoolChanged(false);
    setPageNumber(pageNo);
  };

  const onPageSizeChange = (pageSize) => {
    setIsSchoolChanged(false);
    setPageNumber(DEFAULT_PAGE_NUMBER);
    setPageSize(pageSize);
    setResetPageNumber(true);
  };

  const getAssessmentsStudentDetailsBySchool = (
    schoolIds: number[],
    assessmentId?: number,
    pageNumber?: number,
    pageSize?: number
  ) => {
    setShowLoader(true);
    if (
      props.type.toUpperCase() === constant.AssessmentTypes.PREMIUMASSESSMENT
    ) {
      getResultsPremiumAssessmentStudentDetailsBySchool(
        props.schoolYearId,
        schoolIds.includes(0) ? null : props.schoolIds,
        assessmentId,
        pageNumber,
        pageSize
      ).then((response) => {
        setShowLoader(false);
        if (!response.data.data) {
          setErrorMessage("No record found");
          setResultDetails([]);
          setTotalRecords(0);
        } else {
          setResultDetails(response.data.data.data);
          setTotalRecords(response.data.data.totalRecords);
        }
      });
    } else {
      getResultsAssessmentStudentDetailsBySchool(
        props.schoolYearId,
        schoolIds.includes(0) ? null : props.schoolIds,
        assessmentId,
        pageNumber,
        pageSize).then((response) => {
        setShowLoader(false);
        if (!response.data.data) {
          setErrorMessage("No record found");
          setResultDetails([]);
          setTotalRecords(0);
        } else {
          setResultDetails(response.data.data.data);
          setTotalRecords(response.data.data.totalRecords);
        }
      });
    }
  };

  useEffect(() => {
    if (!isSchoolChanged) {
      getAssessmentsStudentDetailsBySchool(
        props.schoolIds,
        props.assessmentId,
        pageNumber,
        pageSize
      );
    }
  }, [pageSize, pageNumber, resetPageNumber]);

  useEffect(() => {
    setResultDetails([]);
    setIsSchoolChanged(true);
    setPageNumber(DEFAULT_PAGE_NUMBER);
    setPageSize(DEFAULT_PAGE_SIZE);
    setResetPageNumber(false);
    getAssessmentsStudentDetailsBySchool(
      props.schoolIds,
      props.assessmentId,
      DEFAULT_PAGE_NUMBER,
      DEFAULT_PAGE_SIZE
    );
  }, [props.schoolIds]);

  const exportToExcel = () => {
    setShowLoader(true);
    if (
      props.type.toUpperCase() === constant.AssessmentTypes.PREMIUMASSESSMENT
    ) {
      exportResultsPremiumAssessmentStudentDetailsBySchoolExcel(
        props.schoolYearId,
        props.schoolIds.includes(0) ? null : props.schoolIds,
        props.assessmentId
      ).then((response) => {
        setShowLoader(false);
        const file = response.data;
        const fileURL = URL.createObjectURL(file);
        const link = document.createElement("a");
        link.href = fileURL;
        link.download = "studentdetails.xlsx";
        link.setAttribute("target", "_blank");
        document.body.appendChild(link);
        link.click();
      });
    } else {
      exportResultsAssessmentStudentDetailsBySchoolExcel(props.schoolYearId, props.schoolIds.includes(0) ? null : props.schoolIds, props.assessmentId).then((response) => {
        setShowLoader(false);
        const file = response.data;
        const fileURL = URL.createObjectURL(file);
        const link = document.createElement("a");
        link.href = fileURL;
        link.download = "studentdetails.xlsx";
        link.setAttribute("target", "_blank");
        document.body.appendChild(link);
        link.click();
      });
    }
  };

  return (
    <>
      {showLoader && <Loader></Loader>}
      {errorMessage !== "" && <div className="p-3">{errorMessage}</div>}
      {resultDetails && (
        <div className="mt-5">
          <div className="text-sm mb-4 text-white font-light bg-secondary-teal px-4 py-2 mr-auto mt-2 ml-3 leading-5 flex overflow-hidden">
            <div className="flex-shrink-0 current-fill mt-0.5">
              <Info />
            </div>
            <div className="pl-2">
              Your assessment results include the average across all attempts
              each student has made. You can view the individual attempts by
              exporting the assessment results under the Actions column in your
              assessment bank.
            </div>
          </div>
          <div className="flex w-full justify-end items-center mb-4">
            <div className="px-5">
              Use the Export button to show all Student data for those that have
              been Assigned, Completed, Not Completed and Taken By Code.
            </div>
            <button
              onClick={() => exportToExcel()}
              className={
                resultDetails && resultDetails.length > 0
                  ? "bg-primary-violet border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-dark-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3 "
                  : "pointer-events-none text-gray-400 bg-gray"
              }
            >
              Export
            </button>
          </div>
          <div>
            <div className="overflow-x-auto xl:overflow-x-visible">
              <table className="table w-full ">
                <thead>
                  <tr className="bg-primary-violet  text-white ">
                    <th className="text-center p-3 text-sm font-normal">
                      School
                    </th>
                    <th className="text-center p-3 text-sm font-normal">
                      Total Assigned
                    </th>
                    <th className="text-center p-3 text-sm font-normal">
                      Completed
                    </th>
                    <th className="text-center p-3 text-sm font-normal">
                      Pending
                    </th>
                    <th className="text-center p-3 text-sm font-normal">
                      Taken By Code
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {resultDetails &&
                    resultDetails?.map((student, index) => {
                      return (
                        <Fragment>
                          <tr
                            className={`hover:bg-primary-violet/20 transition ease-in-out duration-300 ${
                              index % 2 === 0
                                ? "bg-white"
                                : "bg-primary-violet/10"
                            }`}
                          >
                            <td className="text-sm p-3 ">{student.school}</td>
                            <td className="text-sm p-3 text-center">
                              {student.assignedCount}
                            </td>
                            <td className="text-sm p-3 text-center">
                              {student.completedCount}
                            </td>
                            <td className="text-sm p-3 text-center">
                              {student.pendingCount}
                            </td>
                            <td className="text-sm p-3 text-center">
                              {student.takenByCodeCount}
                            </td>
                          </tr>
                        </Fragment>
                      );
                    })}
                </tbody>
              </table>
            </div>

            {resultDetails?.length === 0 && <NoData />}
            {resultDetails?.length !== 0 && (
              <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between border-t-2 pt-4 mt-3">
                <Pagination
                  totalRecords={totalRecords}
                  pageNumber={pageNumber}
                  pageSize={pageSize}
                  onPageChange={onPageChange}
                  reset={resetPageNumber}
                  setPageNumber={setPageNumber}
                />
                <PageSize
                  pageSize={pageSize}
                  onPageSizeChange={onPageSizeChange}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

import { useEffect, useState } from "react";
import { DownArrowIcon, UpArrow } from "../../../../../assets/icons";
import DotRank from "../../../../shared/widgets/dotRank";
import React from "react";

export default function ProgressReportByTeacherByStandard(props: any) {
  const viewReportHeight = "1000px";
  const [progressReportByTeacherStandard, setProgressReportByTeacherStandard] =
    useState<any>();
  useEffect(() => {
    setAllTeachersSummary();
    setVisibilityForTeachers();
    setVisibilityForSubjects();
    setProgressReportByTeacherStandard(props.data);
  }, []);

  const setAllTeachersSummary = () => {
    if (
      props.data.teachers &&
      props.data.teachers.filter((x) => x.name === "All Teachers").length === 0
    ) {
      let allTeachersScore: any = {
        questionCount: 0,
        studentCount: 0,
        questionsAttempted: 0,
        questionsCorrect: 0,
        classes: [],
        pctCorrect: [],
        pointsEarned: [],
        index: 0,
        id: 0,
        name: "All Teachers",
      };
      props.data?.subjects?.forEach((subject) => {
        allTeachersScore.questionsAttempted = subject.questionsAttempted;
        allTeachersScore.questionsCorrect = subject.questionsCorrect;
        allTeachersScore.pointsEarned.push(subject.pointsEarned);
        allTeachersScore.pctCorrect.push(
          getPercentage(
            subject.pointsEarned,
            subject.pointsPossible,
            subject.questionsAttempted
          )
        );
        subject.domains?.forEach((domain) => {
          allTeachersScore.pointsEarned.push(domain.pointsEarned);
          allTeachersScore.pctCorrect.push(
            getPercentage(
              domain.pointsEarned,
              domain.pointsPossible,
              domain.questionsAttempted
            )
          );
          domain.standards?.forEach((standard) => {
            allTeachersScore.pointsEarned.push(standard.pointsEarned);
            allTeachersScore.pctCorrect.push(
              getPercentage(
                standard.pointsEarned,
                standard.pointsPossible,
                standard.questionsAttempted
              )
            );
          });
        });
      });
      props.data?.teachers?.forEach((teacher) => {
        allTeachersScore.questionCount += teacher.questionCount;
        allTeachersScore.studentCount += teacher.studentCount;
      });

      props.data.teachers = [allTeachersScore, ...props.data.teachers];
    }
  };

  const getPercentage = (pointsEarned, pointsPossible, questionsAttempted) => {
    if (
      pointsEarned === null ||
      pointsPossible === null ||
      questionsAttempted < 2
    )
      return null;
    return pointsPossible === 0 ? 0 : pointsEarned / pointsPossible;
  };

  const setVisibilityForTeachers = () => {
    props.data?.teachers?.forEach((teacher, teacherIndex) => {
      teacher["isCollapse"] = true;
      teacher.visibility = [];
      if (teacherIndex !== 0) teacher.pctCorrect = [];
      teacher?.pointsEarned?.forEach((pct, i) => {
        teacher.visibility.push(true);
        if (teacherIndex !== 0) {
          teacher.pctCorrect.push(
            getPercentage(
              teacher?.pointsEarned[i],
              teacher?.pointsPossible[i],
              teacher?.questionsAttempted[i]
            )
          );
        }
      });
      teacher?.classes?.forEach((cl) => {
        cl.visibility = [];
        cl.pctCorrect = [];
        cl?.pointsEarned?.forEach((pct, j) => {
          cl.visibility.push(true);
          cl.pctCorrect.push(
            getPercentage(
              cl?.pointsEarned[j],
              cl?.pointsPossible[j],
              cl?.questionsAttempted[j]
            )
          );
        });
      });
    });
  };

  const setVisibilityForSubjects = () => {
    let index = 0;
    let visibility: any = [];
    let contentTypes: any = [];

    props.data?.subjects?.forEach((subject) => {
      let subjectChild = 0;
      subject["index"] = index;
      subject["isCollapse"] = false;
      visibility.push(true);
      contentTypes.push(0);
      index = index + 1;
      subject.domains?.forEach((domain) => {
        let domaintChild = 0;
        domain["index"] = index;
        domain["isCollapse"] = false;
        subjectChild = subjectChild + 1;
        visibility.push(true);
        contentTypes.push(1);
        index = index + 1;
        domain.standards?.forEach((standard) => {
          standard["displayName"] = "(" + standard.code + ") " + standard.name;
          standard["index"] = index;
          standard["isCollapse"] = false;
          subjectChild = subjectChild + 1;
          domaintChild = domaintChild + 1;
          visibility.push(true);
          contentTypes.push(2);
          index = index + 1;
        });
        domain["childCount"] = domaintChild;
      });
      subject["childCount"] = subjectChild;
    });

    if (props.data) {
      props.data.visibility = visibility;
      props.data.contentTypes = contentTypes;
    }
  };

  const handleVerticalCollapse = (teacher, value) => {
    teacher.isCollapse = value;
    let _progressReportByTeacherStandard = {
      ...progressReportByTeacherStandard,
    };
    setProgressReportByTeacherStandard(_progressReportByTeacherStandard);
  };

  const handleHorizontalCollapse = (entity, value, entityType) => {
    entity.isCollapse = value;
    if (entityType === "SUBJECT") {
      entity.domains.forEach((domain) => {
        progressReportByTeacherStandard.visibility[domain.index] = !value;
        domain.standards.forEach((standard) => {
          if (domain.isCollapse && !value)
            progressReportByTeacherStandard.visibility[standard.index] = false;
          else
            progressReportByTeacherStandard.visibility[standard.index] = !value;
        });
      });
    } else if (entityType === "DOMAIN") {
      entity.standards.forEach((standard) => {
        progressReportByTeacherStandard.visibility[standard.index] = !value;
      });
    }

    progressReportByTeacherStandard?.teachers?.forEach((teacher) => {
      teacher?.pctCorrect?.forEach((pct, index) => {
        teacher.visibility[index] =
          progressReportByTeacherStandard.visibility[index];
      });
      teacher?.classes?.forEach((cl) => {
        cl?.pctCorrect?.forEach((pct, index) => {
          cl.visibility[index] =
            progressReportByTeacherStandard.visibility[index];
        });
      });
    });

    let _progressReportByTeacherStandard = {
      ...progressReportByTeacherStandard,
    };
    setProgressReportByTeacherStandard(_progressReportByTeacherStandard);
  };

  return (
    <>
      {progressReportByTeacherStandard && (
        <div
          className="pt-60 overflow-x-auto ml-1"
          style={{
            height: viewReportHeight,
          }}
        >
          <table className="border-b border-primary-violet relative">
            <thead className={`sticky bg-white`} style={{ top: 0, zIndex: 12 }}>
              <tr className="border-b border-primary-violet">
                <th className="py-1 text-left pl-2 w-40 sticky left-0 bg-white z-[11]">
                  <span className="text-primary-violet text-base font-semibold">
                    Teacher
                  </span>
                  <div
                    className="absolute left-0 w-full z-[9] bg-white"
                    style={{ top: "-300px", height: "300px" }}
                  ></div>
                </th>
                {progressReportByTeacherStandard.subjects.map(
                  (subject, subjectIndex) => (
                    <>
                      <th className="relative" title={subject.name}>
                        <p className="w-[271px] absolute z-[10] top-[-5.25rem] left-[-0.75rem] transform -rotate-45 whitespace-nowrap flex items-center text-lg font-bold">
                          {subject.name.length > 30
                            ? subject.name.substring(0, 30) + "..."
                            : subject.name}
                          <div
                            onClick={() =>
                              handleHorizontalCollapse(
                                subject,
                                !subject.isCollapse,
                                "SUBJECT"
                              )
                            }
                          >
                            {!subject.isCollapse && (
                              <UpArrow className="w-5 mr-2 cursor-pointer text-blue-600" />
                            )}
                            {subject.isCollapse && (
                              <DownArrowIcon className="w-5 mr-2 cursor-pointer text-blue-600" />
                            )}
                          </div>
                        </p>
                        <div
                          className="absolute left-0 w-full bg-white"
                          style={{ top: "-300px", height: "300px" }}
                        ></div>
                      </th>
                      {!subject.isCollapse &&
                        subject.domains.map((domain, domainIndex) => (
                          <>
                            <th className="relative" title={domain.name}>
                              <p className="w-[271px] absolute z-[10] top-[-5.25rem] left-[-0.75rem] transform -rotate-45 whitespace-nowrap flex items-center text-base font-medium">
                                {domain.name.length > 40
                                  ? domain.name.substring(0, 40) + "..."
                                  : domain.name}
                                <div
                                  onClick={() =>
                                    handleHorizontalCollapse(
                                      domain,
                                      !domain.isCollapse,
                                      "DOMAIN"
                                    )
                                  }
                                >
                                  {!domain.isCollapse && (
                                    <UpArrow className="w-5 mr-2 cursor-pointer text-red-600" />
                                  )}
                                  {domain.isCollapse && (
                                    <DownArrowIcon className="w-5 mr-2 cursor-pointer text-red-600" />
                                  )}
                                </div>
                              </p>
                              <div
                                className="absolute left-0 w-full bg-white"
                                style={{ top: "-300px", height: "300px" }}
                              ></div>
                            </th>

                            {!subject.isCollapse &&
                              !domain.isCollapse &&
                              domain.standards.map(
                                (standard, standardIndex) => (
                                  <th
                                    className="relative"
                                    title={standard.displayName}
                                  >
                                    <p className="w-[271px] absolute z-[10] top-[-5.25rem] left-[-0.75rem] transform -rotate-45 whitespace-nowrap flex items-center text-sm font-normal">
                                      {standard.displayName.length > 40
                                        ? standard.displayName.substring(
                                            0,
                                            40
                                          ) + "..."
                                        : standard.displayName}
                                    </p>
                                    <div
                                      className="absolute left-0 w-full bg-white"
                                      style={{ top: "-300px", height: "300px" }}
                                    ></div>
                                  </th>
                                )
                              )}
                          </>
                        ))}
                    </>
                  )
                )}
              </tr>
            </thead>
            <tbody>
              {progressReportByTeacherStandard.teachers.map(
                (teacher, teacherIndex) => (
                  <>
                    <tr
                      className={
                        "border-r border-primary-violet " +
                        (teacherIndex % 2 !== 0 ? "bg-[#e9f3f8]" : "bg-white")
                      }
                    >
                      <td className="whitespace-nowrap sticky left-0 bg-white relative">
                        <div
                          className={
                            (teacherIndex === 0 ? "font-semibold " : "") +
                            "flex items-center justify-start w-full pr-2"
                          }
                        >
                          <div
                            className="flex px-2 items-center cursor-pointer flex"
                            onClick={() =>
                              handleVerticalCollapse(
                                teacher,
                                !teacher.isCollapse
                              )
                            }
                          >
                            {teacherIndex !== 0 && !teacher.isCollapse && (
                              <UpArrow className="w-4 h-4 stroke-current text-gray-500 hover:text-gray-700" />
                            )}
                            {teacherIndex !== 0 && teacher.isCollapse && (
                              <DownArrowIcon className="w-4 h-4 stroke-current text-gray-500 hover:text-gray-700" />
                            )}
                          </div>
                          {teacher.name}
                        </div>
                        <div className="absolute top-0 left-0 z-[11] h-[100%] w-1 border-l border-primary-violet"></div>
                      </td>
                      {teacher.pctCorrect.map(
                        (pct, index) =>
                          teacher.visibility[index] && (
                            <td
                              className={
                                "text-center p-3 text-sm font-normal " +
                                (props.data.contentTypes[index] === 1
                                  ? "border-l border-gray-500"
                                  : "")
                              }
                            >
                              <DotRank
                                shape="circle"
                                displayScore={true}
                                value={
                                  pct != null
                                    ? Math.round(pct * 100)
                                    : undefined
                                }
                              ></DotRank>
                            </td>
                          )
                      )}
                    </tr>
                    {!teacher.isCollapse &&
                      teacher?.classes?.map((cl, clindex) => (
                        <tr className="border-r border-primary-violet bg-primary-violet/10 relative">
                          <td className="text-left p-3 pl-10 text-sm font-normal sticky left-0 z-[11] bg-[#008fbb1a]/10 backdrop-blur-3xl">
                            <td className="whitespace-nowrap relative">
                              {cl.name}
                            </td>
                            <div className="absolute top-0 left-0 z-[11] h-[100%] w-1 border-l border-primary-violet"></div>
                          </td>
                          {cl.pctCorrect.map(
                            (pct, index) =>
                              cl.visibility[index] && (
                                <td
                                  className={
                                    "text-center p-3 text-sm font-normal " +
                                    (props.data.contentTypes[index] === 1
                                      ? "border-l border-gray-500"
                                      : "")
                                  }
                                >
                                  <DotRank
                                    shape="circle"
                                    displayScore={true}
                                    value={
                                      pct != null
                                        ? Math.round(pct * 100)
                                        : undefined
                                    }
                                  ></DotRank>
                                </td>
                              )
                          )}
                        </tr>
                      ))}
                  </>
                )
              )}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
}

import { XIcon } from "@heroicons/react/outline";
import { useEffect, useState } from "react";
import { IGrade } from "../../../../../../model/interface/grade";
import { getAllGrades } from "../../../../../../api/gradeApi";
import { Select } from "@windmill/react-ui";

interface SetValuesProps {
  setValues: (values: any) => void;
  showPopUp: (show: boolean) => void;
}

const SetValuesPopup = (props: SetValuesProps) => {
  const [grades, setGrades] = useState<IGrade[]>();
  const [values, setValues] = useState({
    gradeId: 0,
  });

  const getGrades = () => {
    getAllGrades()?.then((d) => {
      setGrades(d.data);
    });
  };

  const handleOnChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setValues({ ...values, [name]: value });
  };

  useEffect(() => {
    getGrades();
  }, []);

  return (
    <div className="fixed inset-0 z-40 flex items-center bg-black bg-opacity-50 sm:items-center sm:justify-center ">
      <div className="w-full flex flex-col justify-between h-auto max-h-80percent bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:max-w-xl">
        <header className="rounded-t-lg overflow-hidden">
          <div className="px-4 py-4 bg-gray-50">
            <div className="flex items-start justify-between space-x-3">
              <div className="space-y-1">
                <div className="text-lg font-medium text-gray-900">
                  Set Grade Level
                </div>
              </div>
              <div className="h-7 flex items-center">
                <button
                  type="button"
                  className="inline-flex items-center justify-center w-6 h-6 text-gray-400 transition-colors duration-150 rounded dark:hover:text-gray-200 hover: hover:text-gray-700"
                  onClick={() => props.showPopUp(false)}
                >
                  <span className="sr-only">Close panel</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </header>

        <div className="px-6 py-8">
          <div className="mt-1 max-w-sm">
            <label className="block text-sm text-gray-500 mb-2">
              Set grade level to
            </label>
            <Select
              name="gradeId"
              value={values.gradeId}
              onChange={(e) => {
                handleOnChange(e);
              }}
              className="py-2.5 px-4 block text-sm focus:ring-primary-violet focus:border-primary-violet border border-gray-400 border-opacity-50 form-select leading-5 p-1 rounded-lg"
            >
              <option key={0} value={0}>
                Select
              </option>
              {grades?.map((item: IGrade) => {
                return (
                  <option key={item.gradeId} value={item.gradeId}>
                    {item?.name}
                  </option>
                );
              })}
            </Select>
          </div>
        </div>
        <footer className="flex items-center justify-end px-6 py-4 sm:flex-row bg-gray-50 dark:bg-gray-800 rounded-b-lg">
          <div className="flex-shrink-0">
            <div className="space-x-3 flex justify-end">
              <button
                className="align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 rounded-lg text-sm text-white border border-transparent bg-primary-green focus:shadow-outline-purple w-full sm:w-auto"
                onClick={() => {
                  props.setValues(values);
                }}
              >
                Set
              </button>
              <button
                className="align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 rounded-lg text-sm text-gray-600 border-gray-300 border dark:text-gray-400 focus:outline-none active:bg-transparent hover:border-gray-500 focus:border-gray-500 active:text-gray-500 focus:shadow-outline-gray w-full sm:w-auto"
                type="button"
                onClick={() => {
                  props.showPopUp(false);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default SetValuesPopup;

import { AxiosHelper } from "../../environment/axiosConfig";
import APIConstant from "../../utils/constant/apiConstant";

export const getLiftoffUsageReport = (
  schoolId: number,
  subjectId: number,
  viewBy: number,
  startDate: Date | null,
  endDate: Date | null,
  pageNumber: number,
  pageSize: number,
  schoolYearId: number
) => {
  return AxiosHelper.axiosInstance().get(
    APIConstant.LIFTOFFUSAGE_API.GetLiftoffUsageReport,
    {
      params: {
        schoolId: schoolId,
        subjectId: subjectId,
        startDate: startDate,
        endDate: endDate,
        viewBy: viewBy,
        pageNumber: pageNumber,
        pageSize: pageSize,
        schoolYearId: schoolYearId,
      },
    }
  );
};

export const getLiftoffUsageStudentLevelReport = (
  schoolId: number,
  subjectId: number,
  startDate: Date | null,
  endDate: Date | null,
  pageNumber: number,
  pageSize: number,
  classId: number | null,
  gradeId: number | null,
  schoolYearId: number | null
) => {
  return AxiosHelper.axiosInstance().get(
    APIConstant.LIFTOFFUSAGE_API.GetLiftoffUsageStudentLevelReport,
    {
      params: {
        schoolId: schoolId,
        subjectId: subjectId,
        classId: classId,
        gradeId: gradeId,
        startDate: startDate,
        endDate: endDate,
        pageNumber: pageNumber,
        pageSize: pageSize,
        schoolYearId: schoolYearId,
      },
    }
  );
};

export const getLiftoffUsageReportExcel = (
  schoolId: number,
  subjectId: number,
  viewBy: number,
  startDate: Date | null,
  endDate: Date | null,
  pageNumber: number,
  pageSize: number,
  filters: string,
  schoolYearId: number
) => {
  return AxiosHelper.axiosInstance().get(
    APIConstant.LIFTOFFUSAGE_API.GetLiftoffUsageReportExcel,
    {
      responseType: "blob",
      params: {
        schoolId: schoolId,
        subjectId: subjectId,
        startDate: startDate,
        endDate: endDate,
        viewBy: viewBy,
        pageNumber: pageNumber,
        pageSize: pageSize,
        filters: filters,
        schoolYearId: schoolYearId,
      },
    }
  );
};

export const getLiftoffUsageStudentLevelReportExcel = (
  schoolId: number,
  subjectId: number,
  startDate: Date | null,
  endDate: Date | null,
  pageNumber: number,
  pageSize: number,
  classId: number | null,
  gradeId: number | null,
  filters: string,
  schoolYearId: number | null
) => {
  return AxiosHelper.axiosInstance().get(
    APIConstant.LIFTOFFUSAGE_API.GetLiftoffUsageStudentLevelReportExcel,
    {
      responseType: "blob",
      params: {
        schoolId: schoolId,
        subjectId: subjectId,
        classId: classId,
        gradeId: gradeId,
        startDate: startDate,
        endDate: endDate,
        pageNumber: pageNumber,
        pageSize: pageSize,
        filters: filters,
        schoolYearId: schoolYearId,
      },
    }
  );
};

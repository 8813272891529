import { AxiosHelper } from "../../../environment/axiosConfig";
import APIConstant from "../../../utils/constant/apiConstant";
import { AxiosResponse } from "axios";

export const AddActivitySessionQuestions = (
  activitySessionId: number,
  questionId: number,
  durationInSeconds: number,
  pointsPossible: number,
  pointsEarned: number | undefined,
  parts: number,
  partsCorrect: number | undefined,
  sessionCounter: number,
  isAttempted: boolean
): Promise<AxiosResponse<number>> => {
  return AxiosHelper.axiosInstance().post<number>(
    APIConstant.ACTIVITYSESSIONS_API.AddQuestion,
    {
      activitySessionId: activitySessionId,
      questionId: questionId,
      durationInSeconds: durationInSeconds,
      pointsPossible: pointsPossible,
      pointsEarned: pointsEarned,
      parts: parts,
      partsCorrect: partsCorrect,
      sessionCounter: sessionCounter,
      isAttempted: isAttempted,
    }
  );
};
export const AddActivitySessionTeacherQuestions = (
  activitySessionId: number,
  teacherQuestionId: number,
  durationInSeconds: number,
  pointsPossible: number,
  pointsEarned: number | undefined,
  parts: number,
  partsCorrect: number | undefined,
  sessionCounter: number,
  isAttempted: boolean
): Promise<AxiosResponse<number>> => {
  return AxiosHelper.axiosInstance().post<number>(
    APIConstant.ACTIVITYSESSIONS_API.AddTeacherQuestion,
    {
      activitySessionId: activitySessionId,
      teacherQuestionId: teacherQuestionId,
      durationInSeconds: durationInSeconds,
      pointsPossible: pointsPossible,
      pointsEarned: pointsEarned,
      parts: parts,
      partsCorrect: partsCorrect,
      sessionCounter: sessionCounter,
      isAttempted: isAttempted,
    }
  );
};
export const GetActivitySessionAttemptedQuestionCount = (
  activitySessionId: number
) => {
  return AxiosHelper.axiosInstance().get<number>(
    APIConstant.ACTIVITYSESSIONS_API.AttemptedQuestionCount,
    {
      params: {
        activitySessionId: activitySessionId,
      },
    }
  );
};

export const IsActivitySessionQuestionAttempted = (
  activitySessionId: number,
  questionId: number
) => {
  return AxiosHelper.axiosInstance().get<boolean>(
    APIConstant.ACTIVITYSESSIONS_API.IsQuestionAttempted,
    {
      params: {
        activitySessionId: activitySessionId,
        questionId: questionId,
      },
    }
  );
};

export const GetActivitySessionCounter = (activitySessionId: number) => {
  return AxiosHelper.axiosInstance().get<number>(
    APIConstant.ACTIVITYSESSIONS_API.SessionCounter,
    {
      params: {
        activitySessionId: activitySessionId,
      },
    }
  );
};

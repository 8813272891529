import { SetStateAction, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import constant from "../../../../../utils/constant/constant";
import Loader from "../../../../shared/loader";
import routeConstant from "../../../../../utils/constant/routeConstant";
import Breadcrumb from "../../shared/breadcrumb";
import { IBreadcrumb } from "../../../../../model/common/breadcrumb";
import { getStudentReportCard } from "../../../../../api/report/resultsReport";
import StudentReportCardFilters from "./studentReportCardFilters";
import StudentReportCardGraph from "./studentReportCardGraph";
import { IReportData } from "../../../../../model/report/reportData";
import { IStudentCard } from "../../../../../model/interface/report/studentReportCard";
import { AxiosResponse } from "axios";
import { useReactToPrint } from "react-to-print";
import StudentReportCardPrint from "./Print/studentReportCardPrint";
import userApi from "../../../../../api/userApi";
import { waitForElementsToRender } from "../../../../../utils/waitFor";

interface StudentReportCardProps {
  profile: { 
    currentSchoolYear: { schoolYearId: number }
  },
  userContext: { 
    schoolId: SetStateAction<number>,
    userId: number,
    roleId: number, 
    districtId: number, 
  },
  location: any
}

const graphColors = {
  backgrounds: [
    'rgba(127, 204, 242, .7)', 
    'rgba(255, 115, 144, .7)', 
    'rgba(255, 201, 159, .7)', 
    'rgba(255, 226, 170, .7)', 
    'rgba(152, 219, 219, .7)', 
    'rgba(195, 174, 253, .7)', 
    'rgba(224, 226, 228, .7)',
    'rgba(252, 66, 66, .7)',
    'rgba(121, 254, 139, .7)',
  ],
  borders: [
    'rgb(38, 169, 233)', 
    'rgb(255, 115, 144)', 
    'rgb(255, 162, 94)', 
    'rgb(255, 205, 111)', 
    'rgb(80, 195, 194)', 
    'rgb(153, 119, 251)', 
    'rgb(203, 203, 208)',
    'rgb(252, 35, 35)',
    'rgb(35, 252, 65)',
  ],
};

function StudentReportCard(props: Readonly<StudentReportCardProps>) {
  const [selectedSchoolId, setSelectedSchoolId] = useState<number>(0);
  const [selectedStudentId, setSelectedStudentId] = useState<number>(0);
  const [selectedStudentName, setSelectedStudentName] = useState<string>("");
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [showReport, setShowReport] = useState<boolean>(false);
  const [chartOptions, setChartOptions] = useState<any>({});
  const [chartData, setChartData] = useState<any>({});
  const [autoRunReport, setAutoRunReport] = useState<boolean>(false);
  const [isPrinting, setIsPrinting] = useState<boolean>(false);
  const componentRefStudentReportCard = useRef<HTMLDivElement>(null);
  const handleStudentReportCardPrint = useReactToPrint({
    content: () => componentRefStudentReportCard.current,
    pageStyle: "@media print { @page { size: landscape; } }",
    onAfterPrint: () => {setIsPrinting(false)},
  });
  
  const isDistrictUser: boolean = props.userContext.roleId === constant.UserRoleId.District;
  const isSchoolAdmin: boolean = props.userContext.roleId === constant.UserRoleId.SchoolAdmin;
  const schoolLinkId: number = Number(new URLSearchParams(props.location.search).get("schoolId"));
  const studentLinkId: number = Number(new URLSearchParams(props.location.search).get("studentId"));

  const CHART_TITLE_TEXT = "Select or de-select subjects to update your view";

  const formatDate = (dateText: string | number | Date): string => {
    return new Intl.DateTimeFormat(
      'en-US',
      {year: 'numeric', month: '2-digit', day: '2-digit'}
    ).format(new Date(dateText));
  }

  const handleReportData = (reportData: IReportData<IStudentCard>, studentName?: string) => {
    studentName = studentName || selectedStudentName;
    setChartOptions({
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        y: {
          title: {
            display: true,
            text: "Overall Average"
          },
          suggestedMin: 0,
          suggestedMax: 100,
          ticks: {
            maxTicksLimit: 11
          }
        },
        x: {
          title: {
            display: true,
            text: "Week Ending Date"
          }
        }
      },
      plugins: {
        legend: {
          position: 'top' as const,
          align: 'center',
          title: { 
            display: true,
            color: 'rgba(0, 143, 187, 1)',
            font: { weight: 'bold'},
            text: CHART_TITLE_TEXT
          }
        },
        title: {
          display: true,
          align: 'center',
          font: { size: 14, weight: 'bolder'},
          text: `${reportData.title} - ${studentName}`,
        }
      }
    });

    const dates = reportData.data.map(d => formatDate(d.weekEnding));

    const labels = Array.from(new Set(dates));
    
    const subjectIds = Array.from(new Set(reportData.data.map(d => d.subjectId)));

    const datasets = subjectIds.map((subjectId, index) => ({
      label: reportData.data.find(d => d.subjectId === subjectId)?.subjectName,
      data: reportData.data
        .filter(d => d.subjectId === subjectId)
        .map(d => d.runningPointsPossible === 0 ? null : Math.round(d.runningPointsEarned / d.runningPointsPossible * 100)),
      pointRadius: 7,
      pointHoverRadius: 9,
      borderWidth: 1,
      backgroundColor: graphColors.backgrounds[index % graphColors.backgrounds.length],
      borderColor: graphColors.borders[index % graphColors.borders.length],
      spanGaps: true
    }))

    setChartData({
      labels,
      datasets
    });
  }

  const runReport = async () => {
    setShowLoading(true);
    const studentData = await userApi.GetUserDetail(selectedStudentId);
    const studentName = `${studentData.data.lastName} ${studentData.data.firstName}`;
    setSelectedStudentName(studentName);
    const reportResponse: AxiosResponse<IReportData<IStudentCard>> = await getStudentReportCard(
      selectedSchoolId,
      props.userContext.userId,
      selectedStudentId,
      []
    );
    if (reportResponse.data) {
      handleReportData(reportResponse.data, studentName)
      setShowReport(true);
    }
    setShowLoading(false);
  };

  const printReport = async () => {
    if (handleStudentReportCardPrint) {
      setIsPrinting(true);
      waitForElementsToRender('#student-report-card-print','#student-report-card-print-header',0,600).then(() => {
        handleStudentReportCardPrint();
      })
    }
  };

  const breadcrumbItems = () => {
    const items: Array<IBreadcrumb> = [
      { name: "Home", url: routeConstant.ROUTE_DASHBOARD },
      { name: "Reports", url: routeConstant.REPORTS },
      { name: "Student Report Card", url: "" },
    ];

    return items;
  };

  useEffect(() => {
    if(schoolLinkId && studentLinkId) {
      setSelectedSchoolId(schoolLinkId);
      setSelectedStudentId(studentLinkId);
      setAutoRunReport(true);
    }
  }, [])

  useEffect(() => {
    if(autoRunReport)
      runReport();
  }, [autoRunReport])

  useEffect(() => {
    if(Object.keys(chartOptions).length === 0)
      return;

    const options = {
      ...chartOptions,
      plugins: {
        ...chartOptions.plugins,
        legend: {
          ...chartOptions.plugins.legend,
          title: {
            ...chartOptions.plugins.legend.title,
            text: isPrinting ? "": CHART_TITLE_TEXT
          }
        }
      }
    };
    setChartOptions(options);
  }, [isPrinting])

  return (
    <div className="min-w-0 w-full">
      {showLoading && <Loader></Loader>}
      <div className="lg:flex lg:items-center px-4 py-3 lg:px-8 border-b">
        <Breadcrumb items={breadcrumbItems()} />
        <div className="lg:w-[55%] text-lg flex justify-between gap-2 items-end">
          <h1 className="text-primary-violet font-medium flex justify-start mb-0 pb-0">
            Student Report Card
          </h1>
        </div>
      </div>
      <div className="px-2 sm:px-6  lg:px-8 py-1 relative my-4">
        <div className="bg-gray-100 w-full rounded-lg p-5">
          <StudentReportCardFilters 
            isDistrictUser={isDistrictUser} 
            isSchoolAdmin={isSchoolAdmin}
            selectedSchoolId={selectedSchoolId} 
            setSelectedSchoolId={setSelectedSchoolId} 
            selectedStudentId={selectedStudentId} 
            setSelectedStudentId={setSelectedStudentId} 
            showReport={showReport}
            setShowReport={setShowReport} 
            runReport={runReport} 
            printReport={printReport}
          />
          <div className="border-b border-gray-400 py-2 mb-2"></div>

          <div id="student-report-card-graph-div" className={isPrinting ? "absolute top-0 left-0 invisible w-full overflow-hidden" : ""}>
            <div id="student-report-card-print" ref={componentRefStudentReportCard}>
              {showReport && isPrinting && (<StudentReportCardPrint studentName={selectedStudentName} chartOptions={chartOptions} chartData={chartData} />)}
              <div className={isPrinting ? "flex justify-center align-items-center w-full" : ""}>
                <div className={isPrinting 
                  ? "bg-white py-5 place-self-center flex justify-center align-items-center w-[1000px] h-[600px]" 
                  : "bg-white px-3 py-5 h-[600px]"
                }>
                  {showReport && (<StudentReportCardGraph chartOptions={chartOptions} chartData={chartData} />)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default connect((state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
})(StudentReportCard);

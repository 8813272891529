export const getScoreLabel = (score: number) => {
  if (!score) return "0";
  return Number.isInteger(score)
    ? score.toFixed()
    : score % 1 === 0.5
      ? score.toFixed(1)
      : score.toFixed(2);
}

export const getScoreRounded = (score: number) => {
  return Math.round(score);
}

export const getScoreNumber = (score: number) => {
  return parseFloat(getScoreLabel(score));
}

export const validateIfScoreLessThan80 = (score: number | undefined) => {
  if (score !== undefined && score < 80) {
    return true;
  }
  return false;
};


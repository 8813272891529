import { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { UserContextState } from "../../../../../../redux/actions/userContextAction";
import Constant from "../../../../../../utils/constant/constant";
import {
  getStudentLiftOffMyGalaxyLastSession,
  initializeStandardAssignment,
} from "../../../../../../api/student/liftOff/myGalaxyApi";
import { IInitializeStandardAssignmentRespons } from "../../../../../../model/student/mygalaxystandard/initializeStandardAssignmentRespons";
import QuestionAnswer from "../../../shared/questionAnswer";
import Profile from "../../../../../../model/users/profile";
import { AxiosHelper } from "../../../../../../environment/axiosConfig";
import APIConstant from "../../../../../../utils/constant/apiConstant";
import BeginActivity from "../../../shared/beginActivity";
import RouteConstant from "../../../../../../utils/constant/routeConstant";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { getCalculatorBySubjectId } from "../../../../../../api/student/studentSubjectApi";
import { GetIsQuestionRetryAllowed } from "../../../../../../utils/helper";
import constant from "../../../../../../utils/constant/constant";
import AlienRankingCard from "../../../../../../model/student/shared/alienRanking/alienRankingCard";
import VideoPopup from "../../../shared/popups/videoPopup";
import { IBreadcrumb } from "../../../../../../model/common/breadcrumb";
import Breadcrumb from "../../../middleSchool/breadcrumb";
import { useTranslation } from "react-i18next";
import { getStandardGroupStandards } from "../../../../../../api/cmsApi";
import MessagePopup from "../../../../../shared/messagePopup";
import { paramDecode } from "../../../../../../utils/urlHelper";
import NetworkErrorMessagePopup from "../../../shared/popups/networkErrorMessagePopup";
import { IsActivitySessionQuestionAttempted } from "../../../../../../api/student/activitySession/activitySessionQuestions";
import QuestionAlreadyAttemptedPopup from "../../../shared/popups/questionAlreadyAttemptedPopup";

interface ILiftOffMyGalaxyStandardActivity {
  userContext: UserContextState;
  profile: Profile;
  match: any;
}
function LiftOffMyGalaxyStandardActivity(
  props: ILiftOffMyGalaxyStandardActivity
) {
  const { t } = useTranslation();
  const [standardAssignment, setStandardAssignment] =
    useState<IInitializeStandardAssignmentRespons>();
  const standardId = parseInt(atob(props.match.params.standardId));
  const standardName = paramDecode(props.match.params.standardName);
  const gameId = parseInt(atob(props.match.params.gameId));
  const rocketId = parseInt(atob(props.match.params.rocketId));
  const blasterId = parseInt(atob(props.match.params.blasterId));
  const blasterType = atob(props.match.params.blasterType);
  const isStandardGrouped = atob(props.match.params.isStandardGrouped ?? "");
  const [standardIds, setStandardIds] = useState<Array<number>>();
  const [errorMessagePopup, setErrorMessagePopup] = useState<boolean>(false);
  const [showNetworkErrorPopup, setShowNetworkErrorPopup] = useState<boolean>(false);

  let classId: any = undefined;
  let teacherId: any = undefined;
  let languageId: any = undefined;

  const subjectId = parseInt(atob(props.match.params.subjectId));
  const [calculatorReferenceId, setCalculatorReferenceId] = useState<string>();
  const [beginActivityModal, setBeginActivityModal] = useState<any>({
    open: false,
    data: undefined,
  });

  const history = useHistory();
  const location = useLocation();
  const redirectUrl =
    location.pathname.toLowerCase().indexOf("kindergarten") > -1
      ? RouteConstant.LiftOff.Kindergarten.MyGalaxyResult
      : location.pathname.toLowerCase().indexOf("elementary") > -1
      ? RouteConstant.LiftOff.Elementary.MyGalaxyResult
      : location.pathname.toLowerCase().indexOf("middleschool") > -1
      ? RouteConstant.LiftOff.MiddleSchool.MyGalaxyResult
      : "";
  const exitUrl =
    location.pathname.toLowerCase().indexOf("kindergarten") > -1
      ? RouteConstant.LiftOff.Kindergarten.Home
      : location.pathname.toLowerCase().indexOf("elementary") > -1
      ? RouteConstant.LiftOff.Elementary.Home
      : location.pathname.toLowerCase().indexOf("middleschool") > -1
      ? RouteConstant.LiftOff.MiddleSchool.Home
      : "";
  const [isQuestionRetry, setQuestionRetry] = useState<boolean>(false);
  const [showVideoPopup, setShowVideoPopup] = useState(false);
  const [currenRankingCard, setCurrentRankingCard] =
    useState<AlienRankingCard>();
  const [showQuestionAlreadyAttempted, setShowQuestionAlreadyAttempted] = useState<boolean>(false);

  useEffect(() => {
    if (subjectId > 0) {
      getCalculatorBySubjectId(subjectId)
      .then((res) => {
        if (res.data) setCalculatorReferenceId(res.data);
      })
      .catch(() => {setShowNetworkErrorPopup(true);});
    }
    setQuestionRetry(
      GetIsQuestionRetryAllowed(
        props.userContext?.gradeId,
        props.userContext?.stateId,
        Constant.ActivitySubTypes.LIFTOFFMYGALAXIES,
        undefined
      )
    );
  }, []);
  function completeStudentLiftOffStandardAssignment(
    userId?: number,
    learnositySessionId?: string,
    activitySessionId?: number
  ) {
    return AxiosHelper.axiosInstance().put(
      APIConstant.StudentLiftOffMyGalaxy_API.Complete,
      {
        userId: userId,
        sessionId: learnositySessionId,
        activitySessionId: activitySessionId,
      }
    );
  }

  function updateStudentLiftOffStandardAssignment(
    userId?: number,
    learnositySessionId?: string,
    classId?: number,
    teacherId?: number,
    languageId?: number
  ) {
    return AxiosHelper.axiosInstance().put(
      APIConstant.StudentLiftOffMyGalaxy_API.Update,
      {
        userId: userId,
        sessionId: learnositySessionId,
        classId: classId,
        teacherId: teacherId,
        languageId: languageId,
      }
    );
  }

  useEffect(() => {
    if (isStandardGrouped == "true") {
      getStandardGroupStandards(standardId).then((response) => {
        if (response.data.data) {
          setStandardIds(
            response.data?.data.map((s) => {
              return s.standardId;
            })
          );
        }
      });
    }

    initialize(false);
  }, []);

  function initialize(isBegin: boolean) {
    getStudentLiftOffMyGalaxyLastSession(
      props.userContext.userId,
      standardId,
      isStandardGrouped,
      props.profile.loLanguageId !== constant.Languages.Toggle
        ? props.profile.loLanguageId
        : 0
    ).then((assignment) => {
      var data = assignment.data;
      setBeginActivityModal({ open: true, data });
    })
    .catch(() => {setShowNetworkErrorPopup(true);});
  }

  const handleOnSubmit = () => {
    completeStudentLiftOffStandardAssignment(
      props.userContext.userId,
      standardAssignment?.sessionId,
      standardAssignment?.activitySessionId
    ).then((d) => {
      if (d.data && d.data.hasAlienCardChanged === true) {
        setCurrentRankingCard(d.data.alienRankingCard);
        setShowVideoPopup(true);
      } else {
        history.push(
          redirectUrl
            .replace(":standardId", standardId.toString())
            .replace(":isStandardGrouped", isStandardGrouped.toString())
        );
      }
    });
  };

  function closeMessagePopup() {
    setErrorMessagePopup(false);
    history.goBack();
  }

  function updateStudentLiftOffStandardAssignmentActivity(
    data: any,
    sessionId: string
  ) {
    classId = data.classId;
    teacherId = data.teacherId;
    languageId = data.languageId;
    initializeStandardAssignment(
      props.userContext.userId,
      standardId,
      languageId === constant.Languages.Spanish ? "es" : "en",
      gameId,
      rocketId,
      blasterId,
      blasterType,
      true,
      data.classId,
      data.teacherId,
      data.languageId,
      isStandardGrouped,
      props.userContext.gradeId
    ).then((d) => {
      var data = d.data;
      if (
        !data.isSuccess &&
        data.code === constant.ErrorType.CREATEACTIVITYFAILED
      ) {
        setErrorMessagePopup(true);
        setBeginActivityModal({ ...beginActivityModal, open: false });
        return false;
      } else {
        setStandardAssignment(data);
        updateStudentLiftOffStandardAssignment(
          props.userContext.userId,
          data.sessionId,
          classId,
          teacherId,
          languageId
        ).then((d) => {
          setBeginActivityModal({ ...beginActivityModal, open: false });
        });
      }
    })
    .catch(() => {setShowNetworkErrorPopup(true);});
  }

  useEffect(() => {
    if (!showVideoPopup && currenRankingCard) {
      history.push(
        redirectUrl
          .replace(":standardId", standardId.toString())
          .replace(":isStandardGrouped", isStandardGrouped.toString())
      );
    }
  }, [showVideoPopup]);

  const breadcrumbItems = () => {
    const homeUrl =
      location.pathname.toLowerCase().indexOf("kindergarten") > -1
        ? RouteConstant.LiftOff.Kindergarten.Home
        : location.pathname.toLowerCase().indexOf("elementary") > -1
        ? RouteConstant.LiftOff.Elementary.Home
        : location.pathname.toLowerCase().indexOf("middleschool") > -1
        ? RouteConstant.LiftOff.MiddleSchool.Home
        : "";
    const items: Array<IBreadcrumb> = [
      {
        name: t("Breadcrumb.Home"),
        url: homeUrl,
        textColor: "text-gray-500",
      },
      {
        name: t("Breadcrumb.LiftOffMyGalaxy"),
        textColor: "text-gray-500",
        url: "",
      },
    ];

    return items;
  };

  function validateStandardActivity(questionId: any) {
    if (standardAssignment && questionId && !isQuestionRetry) {
      IsActivitySessionQuestionAttempted(
        standardAssignment.activitySessionId,
        questionId
      ).then(d => {
        if(d.data === true) setShowQuestionAlreadyAttempted(true);
      })
    }
  }

  return (
    <Fragment>
      <div className="px-4 py-3 lg:px-8 border-b">
        <nav className="flex mt-2 text-gray-600" aria-label="Breadcrumb">
          <Breadcrumb items={breadcrumbItems()} />
        </nav>
        <div className="text-lg flex justify-between gap-2 h-auto sm:h-10 items-center">
          <h1 className="text-gray-600 font-medium flex justify-start">
            {t("Breadcrumb.LiftOffMyGalaxy")}
          </h1>
        </div>
      </div>

      {errorMessagePopup && (
        <MessagePopup
          message={Constant.ERRORMESSAGE}
          togglePopup={closeMessagePopup}
        />
      )}

      {showVideoPopup && currenRankingCard && (
        <VideoPopup
          videoUrl={currenRankingCard?.videoUrl ?? ""}
          showPopup={setShowVideoPopup}
        />
      )}
      {standardAssignment !== undefined &&
        standardAssignment.sessionId.length > 0 && (
          <QuestionAnswer
            activityType={Constant.ActivityType.MYGALAXY}
            activityId={
              standardAssignment.activityId ? standardAssignment.activityId : ""
            }
            teacherActivityId={standardAssignment.sessionId}
            showActivity={
              standardAssignment.activityId?.length > 0 ? true : false
            }
            showQuestions={
              standardAssignment.activityId?.length > 0 ? false : true
            }
            questionIds={
              standardAssignment.itemReferences &&
              standardAssignment.itemReferences.length > 0
                ? standardAssignment.itemReferences
                : []
            }
            hasSections={false}
            hasItemBranching={false}
            isAlienExplanation={true}
            isGameChoosen={standardAssignment?.gameId > 0 || gameId > 0}
            studentId={props.userContext.userId.toString()}
            resumeActivity={standardAssignment.hasPendingSession}
            sessionId={standardAssignment.sessionId}
            studentType="EL"
            title={standardName}
            adaptiveFilter={null}
            OnSubmit={handleOnSubmit}
            onExitRedirectUrl={exitUrl}
            activitySubType={Constant.ActivitySubTypes.LIFTOFFMYGALAXIES}
            calculatorReferenceId={calculatorReferenceId}
            questionRetry={isQuestionRetry}
            showExplanation={true}
            activitySessionId={standardAssignment.activitySessionId}
            language={
              standardAssignment.languageId === constant.Languages.Spanish
                ? "es"
                : "en"
            }
            standardId={
              isStandardGrouped == "true"
                ? standardIds?.join(",")
                : standardId?.toString()
            }
            subjectId={subjectId}
            questionsState={standardAssignment.questionStates}
            shuffleItems={standardAssignment.isDisplayRandomQuestion}
            onUnfocused={validateStandardActivity}
            onFocused={validateStandardActivity}
          ></QuestionAnswer>
        )}
      {beginActivityModal.open && (
        <BeginActivity
          activity={t("StudyPlan.Questions")}
          name={standardName + " - " + t("StudyPlanResult.PracticeQuestions")}
          noOfQuestions={10}
          hideTeacher={true}
          hideClass={true}
          hideSpanishLanguage={props.profile.loLanguageId === 1 ? true : false}
          hideEnglishLanguage={props.profile.loLanguageId === 2 ? true : false}
          source={"Assignment"}
          themeName={"ELEMENTARYHOME"}
          data={beginActivityModal.data}
          ActivityLanguageLevel={"UserLevel"}
          onBegin={(data: any, value: any) => {
            updateStudentLiftOffStandardAssignmentActivity(
              value,
              beginActivityModal.data.sessionId
            );
          }}
          programMode={constant.ProgramMode.LO}
        />
      )}

      {showQuestionAlreadyAttempted && (<QuestionAlreadyAttemptedPopup />)}
      {showNetworkErrorPopup && (<NetworkErrorMessagePopup/>)}
    </Fragment>
  );
}

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
};

export default connect<{}, {}, {}>(
  mapStateToProps,
  {}
)(LiftOffMyGalaxyStandardActivity);

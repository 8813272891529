import { Fragment, useState } from "react";
import { XIcon } from "@heroicons/react/solid";
import { convertToDistrictAssessment } from "../../../../api/teacher/assessment";

interface SettingPopupProps {
  userId: number;
  togglePopup: (popupAction: boolean, refreshData?: boolean) => void;
}

function ConvertSchoolAssessment(props: SettingPopupProps) {
  const { userId, togglePopup } = props;
  const [code, setCode] = useState<string>("");

  function handleSave() {
    if (code !== "") {
      convertToDistrictAssessment(userId, code).then(() => {
        togglePopup(false, true);
      });
    }
  }

  return (
    <Fragment>
      <div className="fixed inset-0 z-40 flex items-center bg-black bg-opacity-50 sm:items-center sm:justify-center">
        <div
          className="w-full flex flex-col justify-between overflow-hidden h-auto max-h-80percent bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:max-w-5xl"
          role="dialog"
        >
          <header>
            {/* Header */}
            <div className="px-4 py-4 bg-gray-50">
              <div className="flex items-start justify-between space-x-3">
                <div className="space-y-1">
                  <div className="text-lg font-medium text-gray-900">
                    Convert a School Assessment
                  </div>
                </div>
                <div className="h-7 flex items-center">
                  <button
                    type="button"
                    className="text-gray-400 hover:text-gray-500"
                    onClick={() => togglePopup(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
          </header>
          <div className="w-full relative overflow-auto px-4 py-1">
            <div className="mt-1 sm:mt-0 sm:col-span-2">
              Enter an assessment code to transfer an assessment from a school
              account to the district account. You will be able to edit the
              assessment content and settings once it's available in your
              district assessments list
              <input
                type="text"
                autoComplete="off"
                value={code}
                onChange={(e) => setCode(e.target.value)}
                className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
              />
            </div>
          </div>

          {/* <!-- Action Buttons -->  */}
          <footer className="flex items-center justify-end px-4 py-4 sm:flex-row bg-gray-50 dark:bg-gray-800 mt-4">
            <div className="space-x-3 flex justify-end">
              <button
                onClick={() => handleSave()}
                className="align-middle inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 rounded-lg text-sm text-white border border-transparent bg-secondary-teal hover:bg-dark-teal focus:shadow-indigo-500 w-full sm:w-auto"
              >
                Save
              </button>
              <button
                onClick={() => togglePopup(false)}
                className="align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 rounded-lg text-sm text-gray-600 border-gray-300 border dark:text-gray-400 focus:outline-none active:bg-transparent hover:border-gray-500 focus:border-gray-500 active:text-gray-500 focus:shadow-outline-gray w-full sm:w-auto"
              >
                Close
              </button>
            </div>
          </footer>
        </div>
      </div>
    </Fragment>
  );
}

export default ConvertSchoolAssessment;

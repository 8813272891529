import { exterRosterType } from "../../model/externalRoster/externalRosterType";
import * as action from "./actionTypes";
import makeAction, { makeActionWithPayload } from "./makeAction";

export interface UserContextState {
  userId: number;
  roleId: number;
  gradeId: number;
  schoolId: number;
  districtId: number;
  accountId: number;
  stateId: number;
  schoolAccountId: number;
  impersonatedUser: ImpersonatedUser | null;
  googleId: string;
  activeExternalRoster: exterRosterType;
  theme?: string;
  userName?: string;
  linkedUserId?: string;
}

export interface ImpersonatedUser {
  userId: number;
  token: string;
  returnUrl: string;
  activeUserExternalRoster: exterRosterType;
}
export const getUserContext = makeAction(action.USERCONTEXT_GETCONTEXT);
export const setUserContext = makeActionWithPayload<UserContextState>(
  action.USERCONTEXT_SETCONTEXT
);

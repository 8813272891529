import { Menu, Transition } from "@headlessui/react";
import {
  PresentationChartBarIcon,
  SwitchHorizontalIcon,
  ViewListIcon,
} from "@heroicons/react/solid";
import classNames from "classnames";
import { Fragment } from "react";
import {
  DuplicateIcon,
  ExportIcon,
  KeyIcon,
  PreviewIcon,
  PrintIcon,
} from "../../../../assets/icons";
import { ISelectOption } from "../../../../model/interface/selectElement";

interface ContextMenuProp {
  items: Array<ISelectOption>;
  entityId?: number;
  learnosityId?: string;
  activityName: string;
  activityType: string;
  value?: any;
  onChange: (
    selectdItem: ISelectOption,
    entityId: number,
    learnosityId: string,
    activityName: string,
    activityType: string,
    value?: any
  ) => void;
  isDisabled?: boolean;
  onUpgrade: (item: ISelectOption) => void;
  isLocked?: boolean;
}

function getActiveIcon(menu: string) {
  let icon: any = null;

  switch (menu) {
    case "Answer Key":
      icon = (
        <KeyIcon className="mr-3 h-5 w-5 text-xs font-light cursor-pointer text-gray-400 hover:text-secondary-color transaction ease-in-out duration-300" />
      );
      break;
    case "Export Results":
      icon = (
        <ExportIcon className="mr-3 h-5 w-5 text-xs font-light cursor-pointer text-gray-400 hover:text-secondary-color transaction ease-in-out duration-300" />
      );
      break;
    case "Preview":
      icon = (
        <PreviewIcon className="mr-3 h-5 w-5 text-xs font-light cursor-pointer text-gray-400 hover:text-secondary-color transaction ease-in-out duration-300" />
      );
      break;
    case "Print":
      icon = (
        <PrintIcon className="mr-3 h-5 w-5 text-xs font-light cursor-pointer text-gray-400 hover:text-secondary-color transaction ease-in-out duration-300" />
      );
      break;
    case "Duplicate":
      icon = (
        <DuplicateIcon className="mr-3 h-5 w-5 text-xs font-light cursor-pointer text-gray-400 hover:text-secondary-color transaction ease-in-out duration-300" />
      );
      break;
    case "Projector Mode":
      icon = (
        <PresentationChartBarIcon className="mr-3 h-5 w-5 text-xs font-light cursor-pointer text-gray-400 hover:text-secondary-color transaction ease-in-out duration-300" />
      );
      break;
    case "Compare":
      icon = (
        <SwitchHorizontalIcon className="mr-3 h-5 w-5 text-xs font-light cursor-pointer text-gray-400 hover:text-secondary-color transaction ease-in-out duration-300" />
      );
      break;
    case "Print Codes":
      icon = (
        <PrintIcon className="mr-3 h-5 w-5 text-xs font-light cursor-pointer text-gray-400 hover:text-secondary-color transaction ease-in-out duration-300" />
      );
      break;
  }
  return icon;
}
function getDeactiveIcon(menu: string) {
  let icon: any = null;

  switch (menu) {
    case "Answer Key":
      icon = (
        <KeyIcon className="mr-3 h-5 w-5 text-xs font-light text-gray-300 hover:text-secondary-color transaction ease-in-out duration-300" />
      );
      break;
    case "Export Results":
      icon = (
        <ExportIcon className="mr-3 h-5 w-5 text-xs font-light text-gray-300 hover:text-secondary-color transaction ease-in-out duration-300" />
      );
      break;
    case "Preview":
      icon = (
        <PreviewIcon className="mr-3 h-5 w-5 text-xs font-light text-gray-300 hover:text-secondary-color transaction ease-in-out duration-300" />
      );
      break;
    case "Print":
      icon = (
        <PrintIcon className="mr-3 h-5 w-5 text-xs font-light text-gray-300 hover:text-secondary-color transaction ease-in-out duration-300" />
      );
      break;
    case "Duplicate":
      icon = (
        <DuplicateIcon className="mr-3 h-5 w-5 text-xs font-light text-gray-300 hover:text-secondary-color transaction ease-in-out duration-300" />
      );
      break;
    case "Projector Mode":
      icon = (
        <PresentationChartBarIcon className="mr-3 h-5 w-5 text-xs font-light text-gray-300 hover:text-secondary-color transaction ease-in-out duration-300" />
      );
      break;
    case "Compare":
      icon = (
        <SwitchHorizontalIcon className="mr-3 h-5 w-5 text-xs font-light text-gray-300 hover:text-secondary-color transaction ease-in-out duration-300" />
      );
      break;
    case "Print Codes":
      icon = (
        <PrintIcon className="mr-3 h-5 w-5 text-xs font-light text-gray-400 hover:text-secondary-color transaction ease-in-out duration-300" />
      );
      break;
  }
  return icon;
}

function ContextMenu(props: ContextMenuProp) {
  const {
    items,
    value,
    onChange,
    entityId,
    learnosityId,
    activityName,
    activityType,
    isDisabled,
    onUpgrade,
    isLocked,
  } = props;
  return (
    <Fragment>
      {isDisabled && (
        <Menu as="div" className="relative flex items-center justify-center">
          <ViewListIcon
            className="text-gray-300 hover:text-gray-700 w-5 h-5"
            aria-hidden="true"
          />
        </Menu>
      )}
      {!isDisabled && (
        <Menu as="div" className="relative flex items-center justify-center">
          <Menu.Button
            title="Open context menu"
            className="w-6 bg-transparent inline-flex items-center justify-center text-gray-400 rounded-full hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bg-primary-color"
          >
            <span className="sr-only">Open options</span>
            <ViewListIcon className="w-5 h-5" aria-hidden="true" />
          </Menu.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="transform  mx-3 origin-top-right absolute right-3 top-5 w-48 mt-1 rounded-md shadow-lg z-10 bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none">
              <div className="py-1">
                {items.map((item, index) => (
                  <Menu.Item key={index}>
                    {({ active }) => (
                      <>
                        {item.isUpgrade && (
                          <div
                            onClick={() => onUpgrade(item)}
                            className={
                              "text-gray-300 group flex items-center px-4 py-2 text-sm cursor-pointer"
                            }
                          >
                            {getDeactiveIcon(item.label)}
                            {item.label}
                          </div>
                        )}

                        {((!item.isActive && !item.isUpgrade) || (isLocked??false)) && (
                          <div
                            className={classNames(
                              active
                                ? "bg-gray-100 text-gray-300"
                                : "text-gray-300",
                              "group flex items-center px-4 py-2 text-sm"
                            )}
                          >
                            {getDeactiveIcon(item.label)}
                            {item.label}
                          </div>
                        )}
                        {item.isActive && !item.isUpgrade && !(isLocked??false) && (
                          <a
                            href="#!"
                            onClick={() =>
                              onChange(
                                item,
                                entityId ?? 0,
                                learnosityId ? learnosityId : "",
                                activityName,
                                activityType,
                                value
                              )
                            }
                            className={classNames(
                              active
                                ? "bg-gray-100 text-gray-900"
                                : "text-gray-700",
                              "group flex items-center px-4 py-2 text-sm"
                            )}
                          >
                            {getActiveIcon(item.label)}
                            {item.label}
                          </a>
                        )}
                      </>
                    )}
                  </Menu.Item>
                ))}
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      )}
    </Fragment>
  );
}
export default ContextMenu;

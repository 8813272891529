import React, { useState } from "react";
import Picker, { SKIN_TONE_LIGHT } from "emoji-picker-react";

interface emojiTileProps {
  position: string;
  zIndex: string;
  hightClass: string;
  handleCloseIcon(): void;
  onEmojiClick(emojiObject: any): void;
}
function EmojiTile(props: emojiTileProps) {
  const onEmojiClick = (event: any, emojiObject: any) => {
    props.onEmojiClick(emojiObject);
  };
  const [hightClass] = useState<number>(
    parseInt(props.hightClass.replace("h-", ""))
  );
  return (
    <>
      <div
        className={`absolute bottom-2 left-2  max-w-24 bg-white   rounded-lg`}
      >
        <div
          className={`flex-1 justify-between flex flex-col h-${
            hightClass - 30
          } border pt-6   shadow-lg relative`}
        >
          <button
            type="button"
            onClick={props.handleCloseIcon}
            className="inline-flex items-center absolute top-0 right-0 z-50  justify-center group rounded-full h-6 w-6 transition duration-500 ease-in-out text-white hover:bg-white focus:outline-none"
          >
            <svg
              fill="currentColor"
              viewBox="0 0 20 20"
              role="img"
              aria-hidden="true"
              className="w-4 h-4 fill-current text-gray-500 group-hover:text-yellow-500 "
            >
              <path
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"
                fill-rule="evenodd"
              ></path>
            </svg>
          </button>

          <div className="flex flex-col space-y-4 overflow-y-auto scrollbar-thumb-blue scrollbar-thumb-rounded scrollbar-track-blue-lighter scrollbar-w-2 scrolling-touch">
            <Picker skinTone={SKIN_TONE_LIGHT} onEmojiClick={onEmojiClick} />
          </div>
        </div>
      </div>
    </>
  );
}
export default EmojiTile;

import { Fragment, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { GoogleApi } from "../../../../../api/google/googleApi";
import Loader from "../../../../shared/loader";
import { getAllGrades } from "../../../../../api/gradeApi";
import { IGrade } from "../../../../../model/interface/grade";
import Profile from "../../../../../model/users/profile";
import { UserContextState } from "../../../../../redux/actions/userContextAction";
import { Select } from "@windmill/react-ui";
import { DEFAULT_PAGE_SIZE } from "../../../../../utils/pagingConstant";
import { connect } from "react-redux";
import SetValuesPopup from "./popups/setValuesPopup";
import {
  IGoogleRosterStudentDTO,
  IGoogleStudentGridDTO,
} from "../../../../../model/interface/rosterUser";

interface GoogleClassStudentMappingProps {
  userContext?: UserContextState;
  profile?: Profile;
  classId: number;
  courseId: string;
  mappingStudentCount: (count) => void;
  onMappingCompleted: () => void;
}

const GoogleClassStudentMapping = (props: GoogleClassStudentMappingProps) => {
  const [loading, setLoading] = useState(false);
  const [grades, setGrades] = useState<IGrade[]>();
  const [unMappedStudents, setUnMappedStudents] = useState<
    Array<IGoogleStudentGridDTO>
  >([]);
  const [mappedStudents, setMappedStudents] = useState<
    Array<IGoogleStudentGridDTO>
  >([]);
  const [selectAll, setSelectAll] = useState(false);
  const [nextPageToken, setNextPageToken] = useState<string>();
  const [showPopup, setShowPopup] = useState(false);
  const [selectAllExistingStudents, setSelectAllExistingStudents] =
    useState(false);

  useEffect(() => {
    getGrades();
    getStudentsToImport();
  }, []);

  const getGrades = () => {
    getAllGrades()?.then((d) => {
      setGrades(d.data);
    });
  };

  const syncGoogleStudents = () => {
    const students = mappedStudents
      .filter((s) => s.isSelected)
      .map((s) => s.student);
    if (students.length === 0) {
      toast.warning("Please select students to sync");
      return false;
    }
    setLoading(true);
    GoogleApi.syncGoogleStudents(
      props.userContext?.googleId!,
      props.userContext?.userId!,
      props.classId,
      props.courseId,
      students
    )
      .then((r) => {
        setLoading(false);
        getStudentsToImport();
        props.onMappingCompleted();
      })
      .catch((r) => {
        setLoading(false);
      });
  };

  const getGoogleStudentsToImport = async (nextPageToken: string) => {
    if (props?.userContext?.impersonatedUser === null) {
      setLoading(true);
      try {
        const response = await GoogleApi.getGoogleStudentToImport(
          props.classId,
          props.courseId,
          DEFAULT_PAGE_SIZE,
          nextPageToken,
          props?.profile?.googleUser?.refreshToken!
        );
        setLoading(false);
        const tempData: Array<IGoogleStudentGridDTO> = [];
        if (response.data.students) {
          response.data.students.map((s) => {
            tempData.push({
              isSelected: false,
              teacherUserId: props.userContext?.userId!,
              studentUserId: 0,
              studentName: "",
              gradeId: 0,
              schoolAccountId: props.userContext?.schoolAccountId,
              districtId: props.profile?.districtId,
              stateId: props.userContext?.stateId,
              student: s,
              createNewUser: true,
            });
            return null;
          });
        }

        setNextPageToken(response.data.nextPageToken);
        return await tempData;
      } catch (r) {
        setLoading(false);
        toast.error("Something went wrong");
      }
    }
  };

  const loadMore = async () => {
    const resp = await getGoogleStudentsToImport(
      nextPageToken === undefined ? "" : nextPageToken
    );
    if (resp) {
      const nonImportedStudents = resp.filter((s) => !s.student.isImported);
      const unMappedStudentData = resp.filter(
        (s) =>
          s.student.existingUser === null &&
          s.student.existingUserByEmail === null
      );
      const mappedStudentData = resp.filter(
        (s) =>
          (s.student.existingUser !== null ||
            s.student.existingUserByEmail !== null) &&
          !s.student.isImported
      );

      props.mappingStudentCount(nonImportedStudents.length);
      setUnMappedStudents([...unMappedStudents, ...unMappedStudentData]);
      setMappedStudents([...mappedStudents, ...mappedStudentData]);
    }
  };

  const getStudentsToImport = async () => {
    const resp = await getGoogleStudentsToImport("");
    if (resp) {
      const nonImportedStudents = resp.filter((s) => !s.student.isImported);
      const unMappedStudentData = resp.filter(
        (s) =>
          s.student.existingUser === null &&
          s.student.existingUserByEmail === null
      );
      const mappedStudentData = resp.filter(
        (s) =>
          (s.student.existingUser !== null ||
            s.student.existingUserByEmail !== null) &&
          !s.student.isImported
      );
      props.mappingStudentCount(nonImportedStudents.length);
      setUnMappedStudents(unMappedStudentData);
      setMappedStudents(mappedStudentData);
    }
  };

  const handleOnChange = (e, index) => {
    const name = e.target.name;
    const value = e.target.value;
    let tempSettings = unMappedStudents;
    let tempData = unMappedStudents[index];
    tempData = { ...tempData, [name]: value };
    tempSettings[index] = tempData;
    setUnMappedStudents(Array.from(tempSettings));

    if (name === "isSelected" && value === false) {
      setSelectAll(false);
    }
  };

  const handleOnChangeExistingStudents = (e, index) => {
    const name = e.target.name;
    const value = e.target.value;
    let tempSettings = mappedStudents;
    let tempData = mappedStudents[index];
    tempData = { ...tempData, [name]: value };
    tempSettings[index] = tempData;
    setMappedStudents(Array.from(tempSettings));

    if (name === "isSelected" && value === false) {
      setSelectAllExistingStudents(false);
    }
  };

  const applySelectAll = (isChecked: boolean) => {
    let tempSettings = unMappedStudents;
    const data = tempSettings.map((ms) => {
      if (!ms.student.isImported && ms.student.existingUser === null) {
        ms.isSelected = isChecked;
      }
      return ms;
    });
    setUnMappedStudents(Array.from(data));
  };

  const applySelectAllExistingStudents = (isChecked: boolean) => {
    let tempSettings = mappedStudents;
    const data = tempSettings.map((ms) => {
      if (!ms.student.isImported) {
        ms.isSelected = isChecked;
      }

      return ms;
    });
    setMappedStudents(Array.from(data));
  };

  const validate = (student: any) => {
    if (student.gradeId === 0) {
      toast.warning("Please select Grade");
      return false;
    } else if (
      !student.createNewUser &&
      (student.studentUserId === undefined || student.studentUserId === 0)
    ) {
      toast.warning("Please select existing user");
      return false;
    }
    return true;
  };

  const validateMultiple = (students: Array<any>) => {
    let validatedUserCount = 0;

    if (students.length === 0) {
      toast.warning("Please select students to map");
      return false;
    }

    for (let i = 0; i < students.length; i++) {
      if (validate(students[i])) {
        validatedUserCount++;
      } else {
        break;
      }
    }
    return validatedUserCount === students.length;
  };

  const handleMapSelectedStudents = () => {
    let selectedStudents = unMappedStudents.filter((s) => s.isSelected);

    if (validateMultiple(selectedStudents)) {
      const students = selectedStudents.map((s) => {
        return {
          teacherUserId: s.teacherUserId,
          studentUserId: s.studentUserId,
          gradeId: s.gradeId,
          schoolAccountId: s.schoolAccountId,
          districtId: s.districtId,
          stateId: s.stateId,
          createNewUser: s.createNewUser,
          courseId: s.student.courseId,
          emailAddress: s.student.email,
          fullName: s.student.name,
          googleId: s.student.googleId,
        };
      });
      GoogleApi.mapGoogleStudents(students, props.classId)
        .then(() => {
          setLoading(false);
          getStudentsToImport();
          props.onMappingCompleted();
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  const isRecordSelected = () => {
    let selectedSetting: Array<any> = [];
    if (unMappedStudents && unMappedStudents.length > 0) {
      selectedSetting = unMappedStudents.filter((s) => s.isSelected);
    }
    return selectedSetting.length > 0;
  };

  const setValues = (e: any) => {
    setGradeLevel(e.gradeId);
    setShowPopup(false);
  };

  const setGradeLevel = (gradeId) => {
    let tempSettings = unMappedStudents.map((s) => {
      if (s.isSelected) {
        s.gradeId = gradeId;
      }
      return s;
    });

    setUnMappedStudents(Array.from(tempSettings));
  };

  const getName = (user: IGoogleRosterStudentDTO) => {
    if (user.existingUser != null) {
      return user.existingUser.firstName + " " + user.existingUser.lastName;
    } else if (user.existingUserByEmail != null) {
      return (
        user.existingUserByEmail.firstName +
        " " +
        user.existingUserByEmail.lastName
      );
    } else {
      return "";
    }
  };

  return (
    <>
      {loading && <Loader />}
      <div className="mb-4 w-full relative">
        <div className="flex items-start justify-between flex-wrap">
          <div>
            <h1 className="font-bold text-primary-violet">Student Mapping</h1>
          </div>
        </div>
        {/* Grid */}
        <div className=" mt-2">
          <div className="bg-white py-2">
            <button
              type="button"
              className="disabled:opacity-50 bg-transparent border border-primary-violet shadow-sm py-2 px-4 inline-flex items-center justify-center text-sm font-medium text-primary-violet hover:text-white hover:bg-light-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3 transition ease-in-out duration-500"
              disabled={!isRecordSelected()}
              onClick={() => {
                setShowPopup(true);
              }}
            >
              Set Grade Level
            </button>
            <div className="my-4">
              <h1 className="font-bold text-primary-violet py-2">
                New Students
              </h1>
              <div className="shadow">
                <table className="table w-full">
                  <thead className="mb-5">
                    <tr className="bg-light-violet  text-white py-3 px-3 overflow-hidden">
                      <th className="text-left  pl-3 text-sm font-normal w-10">
                        <input
                          type="checkbox"
                          x-model="value"
                          name="selectAll"
                          value="Private to Project Members"
                          className="h-4 w-4 mr-2 mt-0.5 cursor-pointer text-blue-600 border-gray-300 focus:ring-blue-500"
                          checked={selectAll}
                          onChange={(e) => {
                            applySelectAll(e.target.checked);
                            setSelectAll(e.target.checked);
                          }}
                        />
                      </th>
                      <th className="text-left  p-3 text-sm font-normal">
                        {" "}
                        User{" "}
                      </th>
                      <th className="text-left  p-3 text-sm font-normal">
                        {" "}
                        Gmail{" "}
                      </th>

                      <th className="text-left p-3 text-sm font-normal">
                        {" "}
                        Grade
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {unMappedStudents &&
                      unMappedStudents.length > 0 &&
                      unMappedStudents.map((s, i) => (
                        <tr
                          key={i}
                          className={`hover:bg-light-violet/20 transition ease-in-out duration-300 ${
                            i % 2 === 0 ? "bg-white" : "bg-light-violet/10"
                          }`}
                        >
                          <td className="text-sm mt-5 pl-3">
                            <input
                              type="checkbox"
                              x-model="value"
                              name="isSelected"
                              value="Private to Project Members"
                              disabled={
                                s.student.isImported ||
                                s.student.existingUser !== null
                              }
                              className="h-4 w-4 mt-0.5 cursor-pointer text-blue-600 border-gray-300 focus:ring-blue-500 disabled:opacity-50"
                              checked={
                                s.student.isImported
                                  ? s.student.isImported
                                  : s.isSelected
                              }
                              onChange={(e) => {
                                const data = {
                                  target: {
                                    name: "isSelected",
                                    value: !s.isSelected,
                                  },
                                };
                                handleOnChange(data, i);
                              }}
                            />
                          </td>
                          <td className="text-sm mt-5 p-3">{s.student.name}</td>
                          <td className="text-sm mt-5 p-3">
                            {s.student.email}
                          </td>
                          <td className="text-sm mt-5 p-3">
                            <div>
                              <div className="mt-1">
                                <Select
                                  name="gradeId"
                                  value={s.gradeId}
                                  onChange={(e) => {
                                    handleOnChange(e, i);
                                  }}
                                  disabled={
                                    s.student.isImported ||
                                    s.student.existingUser !== null
                                  }
                                  className="py-2.5 px-4 block text-sm focus:ring-primary-violet focus:border-primary-violet border border-gray-400 border-opacity-50 form-select leading-5 p-1 rounded-lg"
                                >
                                  <option key={0} value={0}>
                                    Select
                                  </option>
                                  {grades?.map((item: IGrade) => {
                                    return (
                                      <Fragment key={item.gradeId}>
                                        <option
                                          key={item.gradeId}
                                          value={item.gradeId}
                                        >
                                          {item?.name}
                                        </option>
                                      </Fragment>
                                    );
                                  })}
                                </Select>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                    {unMappedStudents &&
                      unMappedStudents.length === 0 &&
                      nextPageToken === "" && (
                        <tr>
                          <td
                            colSpan={4}
                            className="px-3 xl:px-2 xl:py-3 1xl:p-3 text-sm text-gray-500"
                          >
                            No record found
                          </td>
                        </tr>
                      )}
                  </tbody>
                </table>
              </div>
              {nextPageToken !== "" && (
                <div className="flex justify-end mt-2">
                  <button
                    className="bg-transparent border border-primary-violet shadow-sm py-2 px-4 inline-flex justify-center items-center text-sm font-medium text-primary-violet hover:text-white hover:bg-light-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3 transition ease-in-out duration-500"
                    type="button"
                    onClick={loadMore}
                  >
                    <span className="text-sm">Load More</span>
                  </button>
                </div>
              )}
            </div>
            <div className="flex gap-2 items-end justify-end">
              <button
                className="bg-primary-violet border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-dark-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={handleMapSelectedStudents}
              >
                Create Students
              </button>
            </div>
            {/* Existing students */}
            <div className="my-4">
              <h1 className="font-bold text-primary-violet py-2">
                Existing Students
              </h1>
              <div className="shadow">
                <table className="table w-full">
                  <thead className="mb-5">
                    <tr className="bg-light-violet  text-white py-3 px-3 overflow-hidden">
                      <th className="text-left  pl-3 text-sm font-normal w-10">
                        <input
                          type="checkbox"
                          name="selectAllExistingStudents"
                          className="h-4 w-4 mr-2 mt-0.5 cursor-pointer text-blue-600 border-gray-300 focus:ring-blue-500"
                          checked={selectAllExistingStudents}
                          onChange={(e) => {
                            applySelectAllExistingStudents(e.target.checked);
                            setSelectAllExistingStudents(e.target.checked);
                          }}
                        />
                      </th>
                      <th className="text-left  p-3 text-sm font-normal">
                        {" "}
                        User{" "}
                      </th>
                      <th className="text-left  p-3 text-sm font-normal">
                        {" "}
                        Gmail{" "}
                      </th>
                      <th className="text-left  p-3 text-sm font-normal">
                        {" "}
                        Name{" "}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {mappedStudents &&
                      mappedStudents.length > 0 &&
                      mappedStudents.map((s, i) => (
                        <tr
                          key={i}
                          className={`hover:bg-light-violet/20 transition ease-in-out duration-300 ${
                            i % 2 === 0 ? "bg-white" : "bg-light-violet/10"
                          }`}
                        >
                          <td className="text-sm mt-5 pl-3">
                            <input
                              type="checkbox"
                              name="selectedExistingStudent"
                              className="h-4 w-4 mr-2 mt-0.5 cursor-pointer text-blue-600 border-gray-300 focus:ring-blue-500 disabled:opacity-50"
                              checked={s.isSelected}
                              onChange={(e) => {
                                const data = {
                                  target: {
                                    name: "isSelected",
                                    value: !s.isSelected,
                                  },
                                };
                                handleOnChangeExistingStudents(data, i);
                              }}
                            />
                          </td>
                          <td className="text-sm mt-5 p-3">{s.student.name}</td>
                          <td className="text-sm mt-5 p-3">
                            {s.student.email}
                          </td>
                          <td className="text-sm mt-5 p-3">
                            {getName(s.student)}
                          </td>
                        </tr>
                      ))}
                    {mappedStudents && mappedStudents.length === 0 && (
                      <tr>
                        <td
                          colSpan={4}
                          className="px-3 xl:px-2 xl:py-3 1xl:p-3 text-sm text-gray-500"
                        >
                          No record found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="flex gap-2 items-end justify-end">
              <button
                type="button"
                className="bg-primary-violet border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-dark-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={syncGoogleStudents}
              >
                Sync Existing Students
              </button>
            </div>
          </div>
        </div>
      </div>
      {showPopup && (
        <SetValuesPopup setValues={setValues} showPopUp={setShowPopup} />
      )}
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
};

export default connect<{}, {}, {}>(
  mapStateToProps,
  {}
)(GoogleClassStudentMapping);

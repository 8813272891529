import { useCallback, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { getQuestionExplanation } from "../../../../api/student/question/explanation";
import { IAdaptiveDataFilter } from "../../../../model/interface/adaptiveDataFilter";
import Constant from "../../../../utils/constant/constant";
import constant from "../../../../utils/constant/constant";
import { GetSectionData, initRequest } from "../../../../utils/initAssessment";
import StudentResponsePopup from "./popups/studentResponsePopup";
import { getCannedTestInstructions } from "../../../../api/student/cannedTestApi";
import CannedTestInstruction from "../../../../model/student/shared/cannedTest/cannedTestInstruction";
import { Configuration } from "../..../../../../../environment/setup";
import GamePopup from "./popups/gamePopup";
import VideoPopup from "./popups/videoPopup";
import ActivityErrorPopup from "./popups/activityErrorPopup";
import {
  AddActivitySessionQuestions,
  AddActivitySessionTeacherQuestions,
  GetActivitySessionAttemptedQuestionCount,
} from "../../../../api/student/activitySession/activitySessionQuestions";
import { UpdateActivitySessionTotalQuestionCount } from "../../../../api/student/activitySession/activitySessions";
import { useTranslation } from "react-i18next";
import ActivitySessionQuestionErrorPopup from "./popups/activitySessionQuestionErrorPopup";
import { bindTextToSpeech } from "./textToSpeech/textToSpeech";
import { IQuestionStates } from "../../../../model/interface/questionState";
import { getRequest } from "../../../../api/userActivity";
import {
  createCloseTranscriptionButton,
  createShowTranscriptionButton,
  createTranscriptionContainer,
} from "../../../../utils/learnosityVideoHelpter";
import QuestionNavigationPlayer from "./questionNavigationPlayer";
import { ISection } from "../../../../model/interface/section";
import { LearnosityDom, LearnosityEnums } from "../../../../utils/learnosityHelper";
import NetworkErrorMessagePopup from "./popups/networkErrorMessagePopup";
import { waitForElementsToRender } from "../../../../utils/waitFor";
import { Connection } from "../../../../api/account/accountApi";
import { AxiosResponse } from "axios";
import { IQuestionExplanationDto } from "../../../../model/interface/questions/QuestionExplanationDto";

interface LearnosityPlayerProps {
  activityType: string;
  activityId: string;
  teacherActivityId: string;
  showActivity: boolean;
  showQuestions: boolean;
  questionIds: string[];
  hasSections: boolean;
  hasItemBranching: boolean;
  isGameChoosen: boolean;
  isAlienExplanation: boolean;
  studentId: string;
  resumeActivity: boolean;
  setItemId: (itemId: string) => void;
  sessionId?: string;
  adaptiveFilter: IAdaptiveDataFilter | null;
  OnSubmit?: Function;
  includeStartPage?: boolean | false;
  language?: string;
  IntroMessage?: string;
  cannedTestId?: number;
  checkAnswer?: boolean;
  onExitRedirectUrl?: string;
  studentType: string;
  calculatorReferenceId?: string;
  activitySubType: string;
  questionRetry: boolean;
  timeLimitInSeconds: number | null;
  showExplanation: boolean;
  setQuestionsAttemptedTillTimeLimit?: (
    questionAttemptedTillTimeLimit: number
  ) => void;
  showTextToSpeech?: boolean;
  activitySessionId: number;
  showTextExpalanation: boolean;
  shuffleItems: boolean;
  showScored100PercentVideo: boolean;
  onUnfocused?: Function;
  onFocused?: Function;
  eliminateAnswerForSingleMCQ?: boolean;
  subjectId?: number;
  sessionCounter: number;
  questionsState?: Array<IQuestionStates>;
  hideSaveBtn: boolean;
  abandonSession?: Function;
  OnExit?: (durationInSeconds: number) => void;
  fontSize?: string;
  OnUpdateFontSize?: (fontSize: string) => void;
  allowPartialSubmit: boolean;
}
export default function LearnosityPlayer(props: LearnosityPlayerProps) {
  const history = useHistory();
  const [studentPopUp, setStudentPopUp] = useState<JSX.Element | null>(null);
  const [showPopup, setShowPopup] = useState(false);
  const [showGamePopup, setShowGamePopup] = useState(false);
  const [showActivityErrorPopup, setShowActivityErrorPopup] = useState(false);
  const [showNetworkErrorPopup, setShowNetworkErrorPopup] = useState(false);
  const [message, setMessage] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [textExplanation, setTextExplanation] = useState("");
  const [cancelIcon, showCancelIcon] = useState(true);
  const [skipButton, showSkipButton] = useState(false);
  const showCancelIconTime = Configuration.SkipButtonTime;
  const SkipTextExplanationButtonTime =
    Configuration.SkipTextExplanationButtonTime;
  const popupState = useRef<boolean>();
  const [loaded, setLoaded] = useState<boolean>(false);
  const [lrnPauseButton, setLRNPauseButton] = useState<HTMLElement>();
  const [isStudyPlan, setIsStudyPlan] = useState<boolean>(false);
  const [itemRef, setItemRef] = useState<string | undefined>();
  const defaultIntroMessage =
    "Please click on start button to start the activity.";
  const [isPopUpClosed, setIsPopUpClosed] = useState<boolean>(false);
  const [itemsAPP, setItemsAPP] = useState<any>();
  let itemsApp;
  let questionsState: Array<IQuestionStates>;
  popupState.current = isPopUpClosed;
  const [showVideoPopUp, setShowVideoPopUp] = useState<boolean>(false);
  const score100percentVideoUrl = `${Configuration.LearnosityAssetsBaseUrl}/3stars_final.mp4`;
  //const score100percentVideoUrl = "https://fast.wistia.net/embed/iframe/qvobbyhbgr";
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [submitBtnclicked, setSubmitBtnClicked] = useState<boolean>(false);
  const submitBtnState = useRef<boolean>();
  submitBtnState.current = submitBtnclicked;
  const [isLastItem, setIsLastItem] = useState<boolean>(false);
  const [isTimeEnd, setIsTimeEnd] = useState<boolean>(false);
  const { t } = useTranslation();
  const [showActivtySessionError, setShowActivitySessionError] =
    useState<boolean>(false);
  const [onFocus, setOnFocus] = useState<boolean>(false);
  const [onUnFocus, setOnUnFocus] = useState<boolean>(false);
  const [questionStates, setQuestionStates] = useState<Array<IQuestionStates>>(
    []
  );
  const [currentItemReference, setCurrentItemReference] = useState<string>();
  const [questionReferencesOrder, setQuestionReferencesOrder] = useState<
    Array<string>
  >([]);
  const [initialMapIndex, setInitialMapIndex] = useState<number>(0);
  const questionMap = useRef<Array<IQuestionStates>>([]);
  const activitySections = useRef<Array<ISection>>([]);
  const allQuestionsMap = useRef<Array<IQuestionStates>>([]);
  const [navigation, setNavigation] = useState<any>();
  const [fontSize, setFontSize] = useState<string>(props.fontSize ?? "");
  const [isLastItemReached, SetIsLastItemReached] = useState<boolean>(false);

  let isInternetConnectionConfirmed: boolean = false;
  const waitTimeForInputListener = 2000;
  let isBeforeUnloadDebounce = false;
  let itemReference: any;
  let current_item_previous_status = "";

  const observer = new MutationObserver(function (mutations) {
    mutations.forEach(function (mutationRecord) {
      var calculators = LearnosityDom.getCalculators();
      for (let i = 0; i < calculators.length; i++) {
        var calculator = calculators[i] as HTMLDivElement;
        calculator.innerHTML = "";
      }
    });
  });

  const isInitialized = useRef(false);

  function CreateObserverForCalcContainer() {
    var calcContainer = LearnosityDom.getCalculatorContainers();
    var barSideButtons = LearnosityDom.getBarSideButtons();
    for (let i = 0; i < barSideButtons.length; i++) {
      var barSideButton = barSideButtons[i] as HTMLButtonElement;
      barSideButton.addEventListener("click", () => {
        var calcButtons = LearnosityDom.getCalculatorButtons();
        for (let i = 0; i < calcButtons.length; i++) {
          calcButtons[i].addEventListener("click", () => {
            for (let i = 0; i < calcContainer.length; i++) {
              calcContainer[i].id = "calcContainerId" + i;
              var calcContainerById = document.getElementById(
                "calcContainerId" + i
              );
              console.log("calcContainerById: ", calcContainerById);
              if (calcContainerById) {
                observer.observe(calcContainerById, {
                  attributes: true,
                  attributeFilter: ["style"],
                });
              }
            }
          });
        }
      });
    }
  }
  async function GetCannedTestInstructions(cannedTestId, language) : Promise<CannedTestInstruction[]> {
    let instructions: CannedTestInstruction[] = [];
    await getCannedTestInstructions(cannedTestId, language).then((res) => {
      instructions = res.data;
    });
    return instructions;
  }
  function submitButtonClick(e) {
    setSubmitBtnClicked(true);
  }
  function studentResponsePopupClosed(value: boolean) {
    setIsPopUpClosed(true);
    if(isLastItem)
      setSubmitBtnClicked(true);
  }
  async function saveStudentQuestionResponse(
    currentItemRef: string,
    durationInSeconds: number,
    pointsPossible: number,
    pointsEarned: number | undefined,
    parts: number,
    partsCorrect: number | undefined,
    questionId: number,
    isAttempted: boolean
  ) {
    try {
      var isSuccess = true;
      var isTeacherQuestions = false;
      if (currentItemRef?.includes("teacher")) isTeacherQuestions = true;
      if (isTeacherQuestions) {
        await AddActivitySessionTeacherQuestions(
          props.activitySessionId,
          questionId !== 0
            ? questionId
            : currentItemRef?.split("-")
            ? parseInt(currentItemRef?.split("-")[0])
            : 0,
          durationInSeconds,
          pointsPossible,
          pointsEarned,
          parts,
          partsCorrect,
          props.sessionCounter,
          isAttempted
        )
          .then((resp) => {
            if (resp.data !== 0) {
              isSuccess = false;
            }
          })
          .catch((ex) => {
            isSuccess = false;
            console.log(ex.message);
          });
      } else {
        await AddActivitySessionQuestions(
          props.activitySessionId,
          questionId !== 0
            ? questionId
            : currentItemRef?.split("-")
            ? parseInt(currentItemRef?.split("-")[0])
            : 0,
          durationInSeconds,
          pointsPossible,
          pointsEarned,
          parts,
          partsCorrect,
          props.sessionCounter,
          isAttempted
        )
          .then((resp) => {
            if (resp.data !== 0) {
              isSuccess = false;
            }
          })
          .catch((ex) => {
            isSuccess = false;
            console.log(ex.message);
          });
      }
    } catch (error) {
      isSuccess = false;
      console.log(error);
    }
    if (!isSuccess) {
      /*Commenting this code for now, no more showing error pop up on failure*/
      // setShowActivitySessionError(true);
      // lrnPauseButton?.click();
    }
  }
  async function updateActivitySessionTotalQuestionCount(
    activitySessionId: number,
    questionCount: number
  ) {
    UpdateActivitySessionTotalQuestionCount(activitySessionId, questionCount)
      .then()
      .catch((ex) => {
        setShowNetworkErrorPopup(true);
        console.log(ex.message);
      });
  }
  function showTranscriptDiv(id) {
    var el = document.getElementById(id);
    if (el) {
      el.classList.remove("hidden");
    }
  }
  function hideTranscriptDiv(id) {
    var el = document.getElementById(id);
    if (el) {
      el.classList.add("hidden");
    }
  }

  function eliminateAnswerForSingleMCQ(itemsApp: any) {
    if (props.eliminateAnswerForSingleMCQ) {
      var responseMaskingPressed = false;
      var Questions = itemsApp.getCurrentItem().questions;
      var responseMaskingButton = LearnosityDom.getResponseMaskingButton(props.language);
      var responseMaskingClicked =
        responseMaskingButton?.getAttribute("aria-pressed");
      if (responseMaskingButton && responseMaskingClicked === "false") {
        responseMaskingButton.click();
        responseMaskingPressed = true;
      }

      setTimeout(function () {
        Object.values(Questions).forEach(async function (question: any) {
          var metaDataValue = question.metadata?.auto_mask_response
            ? question.metadata?.auto_mask_response
            : null;
          const options: Array<any> = question.options ?? [];
          // eliminates one answer choice if options are more than 2
          if (metaDataValue && options.length > 2) {
            var response_id = question.response_id;
            var parent_div = document.querySelector(
              "[id='" + response_id + "']"
            ) as HTMLElement;
            var el = parent_div
              ?.querySelector(".lrn_response_wrapper")
              ?.querySelector("[value='" + metaDataValue + "']") as HTMLElement;

            var parent_li_el = el.parentElement as HTMLElement;
            var questionOptionButton = parent_li_el?.querySelector(
              "button.lrn-mask"
            ) as HTMLElement;
            if (questionOptionButton) {
              var isAlreadyMasked =
                questionOptionButton.getAttribute("aria-label");
              if (!isAlreadyMasked?.includes("Unmask")) {
                questionOptionButton.click();
              }
            }
          }
        });
        if (responseMaskingButton && responseMaskingPressed) {
          responseMaskingButton.click();
        }
      });
    }
  }

  const sortQuestionByActivityOrder = (
    questions: Array<IQuestionStates>
  ): Array<IQuestionStates> => {
    const uniqueQuestions = [] as string[];
    const questionOrdered = [] as Array<IQuestionStates>;
    questionReferencesOrder.forEach((item) => {
      const question = questions.find((state) => state.reference === item);
      if (question && !uniqueQuestions.includes(item)) {
        questionOrdered.push({ ...question });
      } else if (!question && !uniqueQuestions.includes(item)) {
        questionOrdered.push({
          reference: item,
          questionId: parseInt(item.split("-")[0]),
          answered: false,
        });
      }
      uniqueQuestions.push(item);
    });
    return questionOrdered;
  };

  const changeQuestionSelected = (index: number) => {
    if (navigation) navigation.goto(index);
  };

  const verifyQuestionMapIsEnabled = () => {
    const isAssignment =
      props.activityType === Constant.ActivityType.ASSIGNMENT &&
      (props.activitySubType === Constant.ActivitySubTypes.ASSESSMENT ||
        props.activitySubType === Constant.ActivitySubTypes.GENERATEDTEST);

    const isStudyPlan =
      props.activityType === Constant.ActivityType.STUDYPLAN &&
      props.activitySubType === Constant.ActivitySubTypes.PRACTICETEST;

    const isAssessment =
      props.activityType === Constant.ActivitySubTypes.ASSESSMENT;

    return (
      (isAssignment || isStudyPlan || isAssessment) &&
      props.questionsState != null
    );
  };

  const updateQuestionsMap = (currentItemRef: string) => {
    questionMap.current.forEach(function (element: IQuestionStates) {
      if (element.reference === currentItemRef) {
        element.answered = true;
      }
    });
    setQuestionStates(questionMap.current);
  };

  const verifyCurrentInAllQuestionsMap = () => {
    return questionMap.current.every((questionState) =>
      allQuestionsMap.current.some(
        (question) => question.questionId === questionState.questionId
      )
    );
  };

  const getSectionIndex = (itemReference) => {
    const sectionIndex = activitySections.current.findIndex((section) =>
        section.items.includes(itemReference)
    );
    if (sectionIndex <= 0) return 0;
    return sectionIndex;
  }

  const setUpQuestionMap = (
    questions: any[],
    reference: string
  ) => {
    let questionsOrdered = Object.values(questions).map(function (
      question: any
    ) {
      return question.getMetadata()?.sheet_reference;
    });

    let initialMapIndex = 0;
    if (activitySections.current.length > 0)
    {
      var sectionIndex = getSectionIndex(reference);
      var firstItemReference = activitySections.current[sectionIndex].items[0];
      for (let count = 0; count < sectionIndex; count++) {
        initialMapIndex += activitySections.current[count].items.length;
      }
      const firstSectionQuestionIndex = questionsOrdered.findIndex(
          (question) => question === firstItemReference
      );
      questionsOrdered = questionsOrdered.slice(firstSectionQuestionIndex);
    }

    setInitialMapIndex(initialMapIndex);
    setQuestionReferencesOrder(questionsOrdered || []);

  };

  useEffect(() => {
    if (!props.questionsState || !verifyQuestionMapIsEnabled()) return;
    const questions = sortQuestionByActivityOrder(props.questionsState);
    if (!verifyCurrentInAllQuestionsMap()) {
      allQuestionsMap.current = [...allQuestionsMap.current, ...questions];
    }
    setQuestionStates(questions);
    questionMap.current = questions;
  }, [questionReferencesOrder]);

  const redrawDragAndDropSpaces = () => {
    /*
      This is a workaround for drag and drop questions.
      In browsers on iPhone, iPad or Safari on Mac, response spaces are 
      usually hidden behind other objects even if a z-index with a high 
      value is defined.
      The function forces the response spaces to be displayed again to 
      place them on top of the other objects or backgrounds.
      The instruction inside the timeout waits for at least one page 
      drawing cycle to complete, and then the spaces are displayed again.
    */

    let imageResponse = LearnosityDom.getImageResponses();

    imageResponse.forEach((e) => {
      e.classList.add("hidden");
    });

    setTimeout(() => {
      imageResponse.forEach((e) => {
        e.classList.remove("hidden");
      });
    });
  };

  const handleItemLoad = async (instructions: CannedTestInstruction[] | undefined) => {
    console.log("item:load");
    const currentItem = itemsApp.getCurrentItem();
    const currentItemRef = currentItem?.reference;
    props.setItemId(currentItemRef);
    setItemRef(currentItemRef);
    setIsLastItem(currentItem.is_last_item);

    // Global vars
    itemReference = currentItemRef;
    current_item_previous_status = currentItem?.attempt_status;

    // Text-to-speech
    const learnosityPlayerStorage = JSON.parse(localStorage.getItem(constant.LearnosityPlayerStorage) || "{}");

    if (props.showTextToSpeech) {
      bindTextToSpeech(itemsApp, props.language, props.subjectId);
      if (currentItem?.reference !== null) {
        learnosityPlayerStorage.currentQuestionReference = currentItem.reference;
        localStorage.setItem(constant.LearnosityPlayerStorage, JSON.stringify(learnosityPlayerStorage));
      }
    }

    if (props.hasSections && instructions && instructions?.length > 0) {
      var ins = instructions?.find(
        (x) =>
          x.questionReferenceId === currentItemRef
      );
      if (
        ins &&
        instructions &&
        instructions?.length > 0 &&
        ins.questionReferenceId !== instructions[0]?.questionReferenceId
      ) {
        itemsApp
          .assessApp()
          .dialogs()
          .custom.show({
          header: "Instructions",
          body: ins?.instructions,
          buttons: [
            {
              button_id: "custom_OK_button",
              label: "OK",
              is_primary: true,
            },
          ],
        });
      }
    }

    let isVideoItem =
      currentItem.features?.length > 0 &&
      currentItem.features[0].type === "videoplayer";
    if (isVideoItem) {
      var videoObject = currentItem.features[0];
      if (videoObject.metadata?.transcript) {
        var featureEl = document.getElementById(
          videoObject.feature_id
        ) as HTMLElement;
        //Transcript Link
        let btnEl = createShowTranscriptionButton(videoObject.feature_id);
        btnEl.onclick = () =>
          showTranscriptDiv("transcriptDiv_" + videoObject.feature_id);
        featureEl?.append(btnEl);

        //Transcript Parent Div
        let transcriptContainer = createTranscriptionContainer(
          videoObject.feature_id
        );

        //Transcript Text Div
        let txtDivEl = document.createElement("div");
        txtDivEl.innerHTML = videoObject.metadata?.transcript;

        //Transcript Close Div & Button
        let clsDivEl = document.createElement("div");
        clsDivEl.className = "mt-4 text-right";

        let btnClsEl = createCloseTranscriptionButton(
          videoObject.feature_id,
          t("StudentResponse.Close")
        );
        btnClsEl.onclick = () =>
          hideTranscriptDiv("transcriptDiv_" + videoObject.feature_id);
        clsDivEl.append(btnClsEl);

        //Append text and close divs to parent div
        transcriptContainer.appendChild(txtDivEl);
        transcriptContainer.appendChild(clsDivEl);

        //Append parent div in feature div
        featureEl?.append(transcriptContainer);
      }

      disableContextMenuForVideo(currentItem);
    }
    eliminateAnswerForSingleMCQ(itemsApp);

    redrawDragAndDropSpaces();
  };

  const disableContextMenuForVideo = (currentItem) => {

    // Check if there are feature IDs
    if (currentItem.feature_ids && currentItem.feature_ids.length > 0) {
      const featureId = currentItem.feature_ids[0];
      const featureDiv = document.getElementById(featureId);

      if (featureDiv) {        
          const videoElement = featureDiv.querySelector('video');
          if (videoElement) {        
              videoElement.addEventListener('contextmenu', (event) => {
                  event.preventDefault();
              });
          }
      }
    }
  }

  const initialization = useCallback(
    async (
      activityType: string,
      instructions: CannedTestInstruction[] | undefined,
      navigateToQuestionIndex: number
    ) => {
      if (isInitialized.current) return;
      
      isInitialized.current = true;

      console.log("initialization");
      const state = props.resumeActivity ? "resume" : "initial";
      let skipQuestions = false;
      let isVideoPractice = false;

      if (
        props.activitySubType &&
        (props.activitySubType === Constant.ActivitySubTypes.ASSESSMENT ||
          props.activitySubType === Constant.ActivitySubTypes.PRACTICETEST ||
          props.activitySubType === Constant.ActivitySubTypes.GENERATEDTEST ||
          props.activitySubType === Constant.ActivitySubTypes.DIAGNOSTICS)
      ) {
        skipQuestions = true;
      }
      if (
        props.activitySubType === Constant.ActivitySubTypes.VIDEO ||
        props.activityType === Constant.ActivityType.VIDEOPRACTICE
      ) {
        isVideoPractice = true;
      }

      var callbacks = {
        readyListener: function () {
          if (props.questionsState) {
            questionsState = props.questionsState;
          }

          setNavigation(itemsApp?.items());
          var questions = itemsApp.questions();
          let currentItem = itemsApp?.getCurrentItem();

          itemReference = currentItem?.reference;
          if(currentItem) {
            setInputListenerForManualGradedQuestion(currentItem);
          }
          
          setCurrentItemReference(itemReference);
          setUpQuestionMap(questions, itemReference);

          let flag = false;
          props.setItemId(itemReference);
          setLoaded(true);
          setItemsAPP(itemsApp);
          itemsApp.dialogs().pause.hide();
          let scored100percent = true;
          if (
            Object.values(itemsApp.getScores()).find((x: any) => x.score === 0)
          ) {
            scored100percent = false;
          }
          var itemsArr = itemsApp.getItems();
          updateActivitySessionTotalQuestionCount(
            props.activitySessionId,
            itemsArr ? Object.keys(itemsArr).length : 0
          );
          var lastItemReference =
            Object.keys(itemsArr)[Object.keys(itemsArr).length - 1];
          if (props.resumeActivity && navigateToQuestionIndex > 0) {
            if (navigateToQuestionIndex === Object.keys(itemsArr).length) {
              itemsApp
                .assessApp()
                .dialogs()
                .custom.show({
                  header: t("StudentResponse.SubmitAnswer"),
                  body: t("StudentResponse.IsAllResponsesAttempted"),
                  buttons: [
                    {
                      button_id: "custom_submit_yes_button",
                      label: t("StudentResponse.Yes"),
                      is_primary: true,
                    },
                    {
                      button_id: "custom_submit_no_button",
                      label: t("StudentResponse.No"),
                      is_primary: false,
                    },
                  ],
                });
            } else {
              itemsApp.items().goto(navigateToQuestionIndex);
            }
          }
          CreateObserverForCalcContainer();
          let reachedLastItem = false;
          let timeEndFlag = false;
          var _lrnPauseButton = document.querySelector(
            ".test-pause"
          ) as HTMLElement;
          setLRNPauseButton(_lrnPauseButton);
          var _submitButton = LearnosityDom.getSubmitButton();
          if (_submitButton !== null)
            _submitButton.addEventListener("click", submitButtonClick);
          eliminateAnswerForSingleMCQ(itemsApp);
          Object.values(questions).forEach(function (question: any) {
            question.on("validated", async function () {
              if (question.isAttempted()) {
                var currentItemRef = itemsApp?.getCurrentItem()?.reference;
                var correctAns = question.isValid(true)?.correct;
                if (correctAns) {
                  if (props.isGameChoosen) {
                    hideShowGamePopup(true);
                  }
                } else {
                  if (
                    Constant.ManualGradedQuestionTypes.find(
                      (x) => x === question.getQuestion().type
                    )
                  ) {
                    scored100percent = false;
                    setShowPopup(false);
                  } else {
                    scored100percent = false;
                    await getQuestionExplanation(
                      itemsApp?.getCurrentItem()?.reference,
                      props.language ?? "en"
                    )
                      .then((res: AxiosResponse<IQuestionExplanationDto>) => {
                        if (res.data) {
                          if (res.data.videoUrl || res.data.textExplanation) {
                            var isTextExp = false;
                            if (res.data.videoUrl) {
                              setVideoUrl(res.data.videoUrl);
                              setTextExplanation("");
                            } else {
                              setTextExplanation(res.data.textExplanation);
                              setVideoUrl("");
                              isTextExp = true;
                            }
                            showCancelIcon(false);
                            showSkipButton(false);
                            setShowPopup(true);
                            setItemRef(currentItemRef);
                            setTimeout(
                              function () {
                                showSkipButton(true);
                              },
                              isTextExp
                                ? SkipTextExplanationButtonTime
                                : showCancelIconTime
                            );
                          } else {
                            setVideoUrl("");
                            setShowPopup(false);
                            setTextExplanation("");
                            setMessage("");
                          }
                        } else {
                          setVideoUrl("");
                          setShowPopup(false);
                          setTextExplanation("");
                          setMessage("");
                        }
                      })
                      .catch(() => {
                        setShowNetworkErrorPopup(true);
                      });
                  }
                }
              }
            });
            question.on("changed", function () {
              //console.log("student has attempted a question");
              var saveSettings = {
                error: function (e) {
                  // Receives the event object defined in the Event section
                  console.log("save has failed", e);
                },
              };
              itemsApp.save(saveSettings);
            });
          });
          itemsApp.on("item:changed", function (itemIndex) {
            let currentItem = itemsApp.getCurrentItem();
            setCurrentItemReference(currentItem.reference);            
            props.setItemId(currentItem.reference);
            setInputListenerForManualGradedQuestion(currentItem);
            var saveSettings = {
              error: function (e) {
                // Receives the event object defined in the Event section
                console.log("save has failed", e);
              },
            };
            itemsApp.save(saveSettings);
          });

          itemsApp.on("section:changed", function () {
            setUpQuestionMap(
              itemsApp.questions(),
              itemsApp.getCurrentItem().reference
            );
          });

          itemsApp.on("item:load", () => {
            handleItemLoad(instructions);
          });

          itemsApp.on("test:submit:success", function () {
            if (
              scored100percent &&
              (Object.values(itemsApp.getScores()).find(
                (x: any) => x.score === 0
              ) ||
                isVideoPractice ||
                props.activitySubType !==
                  Constant.ActivitySubTypes.PRACTICEQUESTIONS ||
                props.studentType === "MS" ||
                props.showScored100PercentVideo === false)
            ) {
              scored100percent = false;
            }
            if (scored100percent) {
              setShowVideoPopUp(true);
              setIsSubmitted(true);
            } else {
              if (props.OnSubmit) {
                props.OnSubmit();
              }
            }
          });
          itemsApp
            .assessApp()
            .on("button:custom_OK_button:clicked", function () {
              itemsApp.assessApp().dialogs().custom.hide();
            });
          itemsApp.on("test:panel:show", function () {
            if (
              props.hasItemBranching &&
              props.activityType === Constant.ActivityType.LIFTOFFDIAGNOSTIC
            ) {
              CheckIfLastQuestionForAdaptive();
            }
          });
          itemsApp.on("test:panel:hide", function () {
            if (submitBtnState.current) {
              if (skipQuestions) {
                itemsApp?.dialogs().reviewScreen.show();
              } else {
                var d: any[] = Object.values(itemsApp.getItems()).filter(
                  (x: any) => x.attempt_status !== LearnosityEnums.AttemptStatus.FULLY_ATTEMPTED
                );
                if (d?.length > 0)
                  itemsApp.items().goto(d[0]?.source.reference);
              }
              setSubmitBtnClicked(false);
            }
          });
          itemsApp.on("item:beforeunload", async function (object) {
            //PLP-3239: Learnosity fires this event multiple times in some cases.
            //This seems to happen only when an item contains multiple questions, but not consistently
            //This debounce logic is to prevent this handler from running if multiples of this event get fired in rapid succession
            if (isBeforeUnloadDebounce) {
              console.log("item:beforeunload - debounced, exiting");
              object.preventDefault();
              return;
            }

            var currentItem = itemsApp?.getCurrentItem();
            var currentItemRef = currentItem?.reference;
            let isVideoItem =
              currentItem.features?.length > 0 &&
              currentItem.features[0].type === "videoplayer";
            var Questions = currentItem.questions;
            let isManualGradingQuestion = false;
            let pointsPossible: number = 0;
            let pointsEarned: number = 0;
            let parts: number = Questions.length;
            let partsCorrect: number = 0;

            if (skipQuestions) {
              Object.values(currentItem.response_ids).forEach(function (
                response_id,
                index
              ) {
                var questionObject = itemsApp.question(response_id);
                var q = questionObject.getQuestion();
                if (
                  questionObject.isAttempted() &&
                  q.multiple_responses &&
                  q.min_selection &&
                  q.min_selection > 0
                ) {
                  var valid_response_count = q.metadata.valid_response_count;
                  var questionResponse = questionObject.getResponse();
                  if (valid_response_count) {
                    // calculate the currentResponseCount using .reduce() to count the student responses
                    var currentResponseCount =
                      questionResponse && Array.isArray(questionResponse.value)
                        ? questionResponse.value.reduce(function (acc, curr) {
                            return acc + (curr ? 1 : 0);
                          }, 0)
                        : 0;

                    if (currentResponseCount < valid_response_count) {
                      // display a custom dialog to the student
                      itemsApp
                        .assessApp()
                        .dialogs()
                        .custom.show({
                          header: t("StudentResponse.MoreAnswersRequired"),
                          body:
                            t("StudentResponse.ThisQuestionRequires") +
                            valid_response_count +
                            (valid_response_count === 1
                              ? t("StudentResponse.Answer")
                              : t("StudentResponse.Answers")),
                        });

                      // preventDefault() to stop the assessment navigation
                      object.preventDefault();
                      return false;
                    }
                  }
                }
              });
            }
            if (currentItem.attempt_status === LearnosityEnums.AttemptStatus.FULLY_ATTEMPTED) {
              let isCorrect = true;

              Object.values(Questions).forEach(async function (
                itemQuestion: any
              ) {
                var question = itemsApp.question(itemQuestion.response_id);
                var scoreObj = question.getScore();
                pointsPossible += (scoreObj && scoreObj.max_score) ?? 1;

                if (question.isAttempted()) {
                  var correctAns = question.isValid(true)?.correct;
                  if (correctAns) {
                    pointsEarned += (scoreObj && scoreObj.score) ?? 1;
                    pointsEarned > 0 && pointsEarned < scoreObj.max_score
                      ? (isCorrect = false)
                      : partsCorrect++;
                  } else {
                    if (
                      Constant.ManualGradedQuestionTypes.find(
                        (x) => x === question.getQuestion().type
                      )
                    ) {
                      flag = true;
                      isManualGradingQuestion = true;
                      setShowPopup(false);
                      scored100percent = false;
                      pointsEarned += (scoreObj && scoreObj.score) ?? 0;
                    } else {
                      isCorrect = false;
                      scored100percent = false;
                    }
                  }
                }
              });

              if (activityType === Constant.ActivityType.MYGALAXY) {
                //Verify if question was answered
                var answeredFlag = false;
                var questionId = 0;
                questionsState.forEach(function (element: IQuestionStates) {
                  if (element.reference === currentItemRef) {
                    questionId = element.questionId;
                    if (element.answered) {
                      answeredFlag = true;
                    }
                  }
                });
                if (!answeredFlag) {
                  //Save response in database
                  saveStudentQuestionResponse(
                    currentItemRef,
                    currentItem.time,
                    pointsPossible,
                    isManualGradingQuestion ? undefined : pointsEarned,
                    isVideoItem ? parts : 1,
                    isManualGradingQuestion
                      ? undefined
                      : isVideoItem
                      ? partsCorrect
                      : isCorrect
                      ? 1
                      : 0,
                    questionId,
                    true
                  );
                  questionsState.forEach(function (element: IQuestionStates) {
                    if (element.reference === currentItemRef) {
                      element.answered = true;
                    }
                  });
                }
              } else {
                saveStudentQuestionResponse(
                  currentItemRef,
                  currentItem.time,
                  pointsPossible,
                  isManualGradingQuestion ? undefined : pointsEarned,
                  isVideoItem ? parts : 1,
                  isManualGradingQuestion
                    ? undefined
                    : isVideoItem
                    ? partsCorrect
                    : isCorrect
                    ? 1
                    : 0,
                  0,
                  true
                );
                if (questionMap.current && questionMap.current.length > 0) {
                  updateQuestionsMap(currentItemRef);
                }
              }

              if (
                activityType === "isLiftOffDiagnostic" &&
                currentItem.attempt_status === LearnosityEnums.AttemptStatus.FULLY_ATTEMPTED &&
                (!reachedLastItem ||
                  current_item_previous_status !== LearnosityEnums.AttemptStatus.FULLY_ATTEMPTED ||
                  !skipQuestions) &&
                !props.checkAnswer &&
                currentItemRef === itemReference &&
                !popupState.current &&
                !flag
              ) {
                if (!isInternetConnectionConfirmed && !reachedLastItem) {
                  object.preventDefault();
                  var hasInternet = await Connection();
                  if (hasInternet) {
                    isInternetConnectionConfirmed = true;
                    itemsApp.items().next();
                  } else {
                    setShowNetworkErrorPopup(true);
                  }
                } else {
                  isInternetConnectionConfirmed = false;
                }
              }

              if (
                activityType === "isStudyPlan" &&
                ((!reachedLastItem &&
                  currentItem.attempt_status === LearnosityEnums.AttemptStatus.FULLY_ATTEMPTED) ||
                  (current_item_previous_status !== LearnosityEnums.AttemptStatus.FULLY_ATTEMPTED &&
                    currentItem.attempt_status === LearnosityEnums.AttemptStatus.FULLY_ATTEMPTED) ||
                  (!skipQuestions &&
                    currentItem.attempt_status === LearnosityEnums.AttemptStatus.FULLY_ATTEMPTED)) &&
                !props.checkAnswer &&
                currentItemRef === itemReference &&
                !popupState.current &&
                !flag
              ) {
                if (currentItemRef === lastItemReference) {
                  reachedLastItem = true;
                }
                if (
                  current_item_previous_status !== LearnosityEnums.AttemptStatus.FULLY_ATTEMPTED &&
                  currentItem.attempt_status === LearnosityEnums.AttemptStatus.FULLY_ATTEMPTED
                ) {
                  current_item_previous_status = currentItem.attempt_status;
                }
                if (currentItem.attempt_status === LearnosityEnums.AttemptStatus.FULLY_ATTEMPTED) {
                  if (props.showExplanation && !isCorrect) {
                    object.preventDefault();
                    await getQuestionExplanation(
                      itemsApp?.getCurrentItem()?.reference,
                      props.language ?? "en"
                    )
                      .then((res: AxiosResponse<IQuestionExplanationDto>) => {
                        if (res.data) {
                          var isTextExp = false;
                          if (
                            props.showTextExpalanation &&
                            res.data.textExplanation
                          ) {
                            setTextExplanation(res.data.textExplanation);
                            setVideoUrl("");
                            isTextExp = true;
                            showCancelIcon(false);
                            showSkipButton(false);
                            setShowPopup(true);
                            setItemRef(currentItemRef);
                            setTimeout(function () {
                              showSkipButton(true);
                            }, SkipTextExplanationButtonTime);
                          } else if (
                            !props.showTextExpalanation &&
                            (res.data.videoUrl || res.data.textExplanation)
                          ) {
                            if (res.data.videoUrl) {
                              setVideoUrl(res.data.videoUrl);
                              setTextExplanation("");
                            } else {
                              setTextExplanation(res.data.textExplanation);
                              setVideoUrl("");
                              isTextExp = true;
                            }
                            showCancelIcon(false);
                            showSkipButton(false);
                            setShowPopup(true);
                            setItemRef(currentItemRef);
                            setTimeout(
                              function () {
                                showSkipButton(true);
                              },
                              isTextExp
                                ? SkipTextExplanationButtonTime
                                : showCancelIconTime
                            );
                          } else {
                            setVideoUrl("");
                            setTextExplanation("");
                            setMessage("");
                            setVideoUrl("");
                            if (props.questionRetry) {
                              setShowPopup(true);
                            } else {
                              setShowPopup(false);
                              flag = true;
                              if (
                                !reachedLastItem ||
                                currentItemRef !== lastItemReference
                              ) {
                                itemsApp.items().next();
                              }
                              if (
                                currentItem.is_last_item &&
                                submitBtnState.current &&
                                !Object.values(itemsApp.getItems()).find(
                                  (x: any) =>
                                    x.attempt_status !== LearnosityEnums.AttemptStatus.FULLY_ATTEMPTED
                                )
                              ) {
                                itemsApp.submit();
                              }
                            }
                          }
                        } else {
                          setVideoUrl("");
                          setTextExplanation("");
                          setMessage("");
                          setVideoUrl("");
                          if (props.questionRetry) {
                            setShowPopup(true);
                          } else {
                            setShowPopup(false);
                            flag = true;
                            if (
                              !reachedLastItem ||
                              currentItemRef !== lastItemReference
                            ) {
                              itemsApp.items().next();
                            }
                            if (
                              currentItem.is_last_item &&
                              submitBtnState.current &&
                              !Object.values(itemsApp.getItems()).find(
                                (x: any) =>
                                  x.attempt_status !== LearnosityEnums.AttemptStatus.FULLY_ATTEMPTED
                              )
                            ) {
                              itemsApp.submit();
                            }
                          }
                        }
                      })
                      .catch(() => {
                        setShowNetworkErrorPopup(true);
                      });
                  } else if (isCorrect) {
                    if (!isInternetConnectionConfirmed && !reachedLastItem) {
                      object.preventDefault();
                      var hasInternet = await Connection();
                      if(hasInternet)
                      { 
                        isInternetConnectionConfirmed = true;
                        itemsApp.items().next();
                      } else {
                        setShowNetworkErrorPopup(true);
                      }
                    } else {
                      isInternetConnectionConfirmed = false;
                      if (props.isGameChoosen) {
                        SetIsLastItemReached(reachedLastItem);
                        object.preventDefault();
                        hideShowGamePopup(true);
                      }
                    }
                  } else if (isManualGradingQuestion) {
                    setShowPopup(false);
                    if (
                      !reachedLastItem ||
                      currentItemRef !== lastItemReference
                    ) {
                      itemsApp.items().next();
                    }
                  }
                }
              } else {
                if (flag) flag = false;
                if (popupState.current) setIsPopUpClosed(false);
              }
            } else if (currentItem.attempt_status === LearnosityEnums.AttemptStatus.NOT_ATTEMPTED) {
              console.log(currentItem.reference + ": not attempted");
              Object.values(Questions).forEach(async function (
                itemQuestion: any
              ) {
                var question = itemsApp.question(itemQuestion.response_id);
                var scoreObj = question.getScore();
                pointsPossible += (scoreObj && scoreObj.max_score) ?? 1;
              });
              saveStudentQuestionResponse(
                currentItemRef,
                currentItem.time,
                pointsPossible,
                isManualGradingQuestion ? undefined : 0,
                isVideoItem ? parts : 1,
                isManualGradingQuestion ? undefined : 0,
                0,
                false
              );
            } else {
              if (flag) flag = false;
              if (popupState.current) setIsPopUpClosed(false);
            }

            if (
              props.hasSections &&
              props.cannedTestId &&
              activitySections.current.length > 1 &&
              verifyQuestionMapIsEnabled()
            ) {
              const lastSectionQuestionReference =
                questionMap.current[questionMap.current.length - 1]?.reference;
              const isLastQuestionInSection =
                currentItem.reference === lastSectionQuestionReference &&
                !currentItem.is_last_item;
              const notAnsweredAllQuestions =
                itemsApp?.attemptedQuestions()?.length <
                Object.keys(itemsApp?.getQuestions())?.length;

              LearnosityDom.setContinueSectionWarningButtonVisibility(
                isLastQuestionInSection && notAnsweredAllQuestions
              );
            }

            isBeforeUnloadDebounce = true;
            setTimeout(() => {
              isBeforeUnloadDebounce = false;
              }, 300);
          });
          itemsApp.on("time:end", function () {
            setIsTimeEnd(true);
            timeEndFlag = true;
            itemsApp
              .assessApp()
              .dialogs()
              .custom.show({
                header: "Time Limit Reached",
                body: "Your time limit has been reached. Complete the remaining questions to record your score.",
                buttons: [
                  {
                    button_id: "custom_time_limit_Continue_button",
                    label: "Continue",
                    is_primary: true,
                  },
                ],
              });
          });
          itemsApp
            .assessApp()
            .on(
              "button:custom_time_limit_Continue_button:clicked",
              function () {
                itemsApp.assessApp().dialogs().custom.hide();
                if (props.setQuestionsAttemptedTillTimeLimit) {
                  props.setQuestionsAttemptedTillTimeLimit(
                    itemsApp.attemptedItems().length
                  );
                }
              }
            );
          itemsApp.on("focused", function () {
            if (
              props.timeLimitInSeconds &&
              !timeEndFlag &&
              itemsApp.getTime() >= props.timeLimitInSeconds
            ) {
              setIsTimeEnd(true);
              timeEndFlag = true;
              itemsApp
                .assessApp()
                .dialogs()
                .custom.show({
                  header: "Time Limit Reached",
                  body: "Your time limit has been reached. Complete the remaining questions to record your score.",
                  buttons: [
                    {
                      button_id: "custom_time_limit_Continue_button",
                      label: "Continue",
                      is_primary: true,
                    },
                  ],
                });
            }

            setOnUnFocus(false);
            setOnFocus(true);
          });
          itemsApp.on("unfocused", function () {
            setOnUnFocus(true);
            setOnFocus(false);
          });
          itemsApp
            .assessApp()
            .on("button:custom_submit_yes_button:clicked", function () {
              itemsApp.assessApp().dialogs().custom.hide();
              itemsApp.submit();
            });
          itemsApp
            .assessApp()
            .on("button:custom_submit_no_button:clicked", function () {
              history.push(props.onExitRedirectUrl ?? "");
            });

          itemsApp.on("test:finished:save", function () {
            const durationInSeconds: number = itemsApp.getTime();
            if (props.OnExit) {
              props.OnExit(durationInSeconds);
            }
          });

          itemsApp
            .assessApp()
            .on("button:custom_cancel_button:clicked", function () {
              itemsApp.assessApp().dialogs().custom.hide();
            });
          itemsApp
            .assessApp()
            .on("button:custom_save_and_exit_button:clicked", function () {
              itemsApp.assessApp().dialogs().custom.hide();
              var saveSettings = {
                // Receives an array of response_ids (strings) of the saved session responses
                success: function (response_ids) {
                  history.push(props.onExitRedirectUrl ?? "");
                },
                // Allows you to immediately handle a save error event
                error: function (event) {
                  console.log("Save has failed", event);
                },
              };
              itemsApp.save(saveSettings);
            });
          itemsApp.on("test:proctor:terminated", function () {
            setTimeout(() => {
              if (props.abandonSession) {
                props.abandonSession();
              }
            }, 10000); //modal popup from learnosity shows for 10 sec
          });
          itemsApp.on("accessibility:change:fontsize", function (fontSize) {
            setFontSize(fontSize);
            if (props.OnUpdateFontSize) {
              props.OnUpdateFontSize(fontSize);
            }
          });
        },
        errorListener: function (e) {
          if (
            e.code === 20013 &&
            //PLP-2570
            //using text comparison because message can be different for different scenarios under same code
            //(https://reference.learnosity.com/items-api/troubleshooting Code-20013);
            //Learnosity ticket 23811
            //this check should be removed once fixed from Learnosity.
            e.msg === "No Item matching the criteria could be found." &&
              props.activityType === Constant.ActivityType.LIFTOFFDIAGNOSTIC
          ) {
            if (props.OnSubmit) {
              props.OnSubmit();
            }
          } else if (e.code === 20013) {
            hideShowActivityErrorPopup(true);
          }
          console.log("Error Code ", e.code);
          console.log("Error Message ", e.msg);
          console.log("Error Detail ", e.detail);
        },
      };

      if (props.hasSections && props.cannedTestId) {
        activitySections.current = await GetSectionData(
          props.cannedTestId,
          props.language ?? "en"
        );
      }

      initRequest(
        props.activityId,
        props.showActivity,
        props.showQuestions,
        props.questionIds,
        props.hasItemBranching,
        props.hasSections,
        props.sessionId ?? uuidv4(),
        state,
        props.studentId,
        props.teacherActivityId,
        props.adaptiveFilter,
        props.language ?? "en",
        props.hasSections && instructions && instructions?.length > 0
          ? true
          : props.includeStartPage ?? false,
        props.hasSections && instructions && instructions?.length > 0
          ? instructions[0].instructions
          : props.IntroMessage ?? defaultIntroMessage,
        props.checkAnswer ?? false,
        props.timeLimitInSeconds ?? null,
        props.studentType,
        props.cannedTestId,
        props.onExitRedirectUrl,
        props.calculatorReferenceId,
        skipQuestions ?? undefined,
        props.shuffleItems ?? false,
        props.hideSaveBtn ?? false,
        props.allowPartialSubmit ?? false,
        false,
        props.fontSize
      ).then((requestObj) => {
        getRequest("items", JSON.stringify(requestObj))
          .then((response) => {
            console.log("API Call Result")
            if (response.data) {
              if (itemsApp) {
                itemsApp.reset();
              }
              itemsApp = window["LearnosityItems"]?.init(
                response.data,
                callbacks
              );
            }
          })
          .catch(() => {
            setShowNetworkErrorPopup(true);
          });
      });
    },
    []
  );

  useEffect(() => {
    console.log("useEffect");
    let activityType = "";
    if (
      props.activityType &&
      (props.activityType === Constant.ActivityType.STUDYPLAN ||
        props.activityType === Constant.ActivityType.VIDEOPRACTICE ||
        props.activityType === Constant.ActivityType.ASSIGNMENT ||
        props.activityType === Constant.ActivityType.SKILLPRACTICE ||
        props.activityType === Constant.ActivityType.ALIENARENA ||
        props.activityType === Constant.ActivityType.MYGALAXY)
    ) {
      setIsStudyPlan(true);
      activityType = "isStudyPlan";
    } else if (
      props.activityType &&
      props.activityType === Constant.ActivityType.DIAGNOSTIC
    ) {
      activityType = "isDiagnostic";
    } else if (
      props.activityType &&
      props.activityType === Constant.ActivityType.LIFTOFFDIAGNOSTIC
    ) {
      activityType = "isLiftOffDiagnostic";
    }

    if (props.hasSections) {
      GetCannedTestInstructions(
        props.cannedTestId,
        props.language ?? "en"
      ).then((res) => {
        initialization(activityType, res, 0);
      });
    } else {
      if (
        props.resumeActivity &&
        (props.activitySubType ===
          Constant.ActivitySubTypes.PRACTICEQUESTIONS ||
          props.activitySubType ===
            Constant.ActivitySubTypes.LIFTOFFMYGALAXIES ||
          props.activitySubType ===
            Constant.ActivitySubTypes.STUDYPLANPRACTICEQUESTIONS)
      ) {
        GetActivitySessionAttemptedQuestionCount(props.activitySessionId)
          .then((res) => {
            initialization(activityType, undefined, res.data);
          })
          .catch(() => {
            setShowNetworkErrorPopup(true);
          });
      } else {
        initialization(activityType, undefined, 0);
      }
    }
  }, [initialization, props.activityType]);

  useEffect(() => {
    if (showGamePopup || showActivtySessionError) {
      lrnPauseButton?.click();
    } else {
      var _lrnResumeButton = LearnosityDom.getResumeButton();
      if (_lrnResumeButton) _lrnResumeButton.click();
    }
    if (
      loaded &&
      !showPopup &&
      isStudyPlan &&
      isPopUpClosed &&
      !showActivtySessionError
    ) {
      if (submitBtnState.current && isLastItem) {
        if (
          !Object.values(itemsAPP.getItems()).find(
            (x: any) => x.attempt_status !== LearnosityEnums.AttemptStatus.FULLY_ATTEMPTED
          )
        ) {
          itemsAPP?.submit();
        } else {
          var d: any[] = Object.values(itemsAPP.getItems()).filter(
            (x: any) => x.attempt_status !== LearnosityEnums.AttemptStatus.FULLY_ATTEMPTED
          );
          if (d?.length > 0) {
            itemsAPP.items().goto(d[0]?.source.reference);
          }
          setSubmitBtnClicked(false);
        }
      } else {
        itemsAPP?.items()?.next();
      }
    }
  }, [
    showPopup,
    loaded,
    isPopUpClosed,
    itemsAPP,
    showGamePopup,
    showActivtySessionError,
  ]);

  useEffect(() => {
    if (showPopup) {
      setStudentPopUp(
        <StudentResponsePopup
          showPopUp={(show) => {
            setShowPopup(show);
          }}
          message={message}
          setMessage={() => {
            setShowPopup(false);
          }}
          setVideoUrl={(videoUrl) => {
            setVideoUrl(videoUrl);
          }}
          setTextExplanation={(textExplanation) => {
            setTextExplanation(textExplanation);
          }}
          showCancelIcon={(cancelIcon) => {
            showCancelIcon(cancelIcon);
          }}
          cancelIcon={cancelIcon}
          videoUrl={videoUrl}
          itemRef={itemRef ?? ""}
          textExplanation={textExplanation}
          showSkipButton={(skipButton) => {
            showSkipButton(skipButton);
          }}
          skipButton={skipButton}
          setIsPopUpClosed={studentResponsePopupClosed}
          studentType={props.studentType}
          onExitRedirectUrl={props.onExitRedirectUrl ?? ""}
          questionRetry={props.questionRetry ?? true}
          isLastItem={isLastItem}
          language={props.language ?? "en"}
          eliminateAnswerForSingleMCQ={props.eliminateAnswerForSingleMCQ}
          showTextToSpeech={props.showTextToSpeech}
          fontSize={fontSize}
        />
      );
    } else {
      setStudentPopUp(null);
    }
  }, [
    showPopup,
    cancelIcon,
    message,
    videoUrl,
    itemRef,
    textExplanation,
    skipButton,
  ]);

  const hideShowGamePopup = (show) => {
    setShowGamePopup(show);
    if (!show) {
      setIsPopUpClosed(true);
    }
  };
  const hideShowActivityErrorPopup = (show) => {
    setShowActivityErrorPopup(show);
    if (!show) {
      setShowActivityErrorPopup(true);
    }
  };
  const OnExitClick = () => {
    console.log("OnExitClick");
    console.log(itemsAPP.getItems());
    var d: any[] = Object.values(itemsAPP.getItems()).filter(
      (x: any) => x.attempt_status !== LearnosityEnums.AttemptStatus.FULLY_ATTEMPTED
    );
    if (d?.length > 0) {
      itemsAPP
        .assessApp()
        .dialogs()
        .custom.show({
          header: "Exit Activity",
          body: "You have the option to save or submit your activity. You currently have questions that have not been answered. If you choose to submit without responding to the unanswered questions, then those questions will be marked incorrect.\n How would you like to proceed?",
          buttons: [
            {
              button_id: "custom_cancel_button",
              label:
                props.language === "es"
                  ? "Cancelar"
                  : t("StudentResponse.Cancel"),
              is_primary: false,
            },
            {
              button_id: "custom_save_and_exit_button",
              label:
                props.language === "es"
                  ? "Guardar Progreso Y Salir"
                  : t("StudentResponse.SaveAndExit"),
              is_primary: false,
            },

            {
              button_id: "custom_submit_yes_button",
              label:
                props.language === "es"
                  ? "Enviar Tal Cual"
                  : t("StudentResponse.SubmitAsIs"),
              is_primary: true,
            },
          ],
        });
    } else {
      itemsAPP
        .assessApp()
        .dialogs()
        .custom.show({
          header: "Exit Activity",
          body: "You have the option to save or submit your activity. \n How would you like to proceed?",
          buttons: [
            {
              button_id: "custom_cancel_button",
              label: t("StudentResponse.Cancel"),
              is_primary: false,
            },
            {
              button_id: "custom_save_and_exit_button",
              label: t("StudentResponse.SaveAndExit"),
              is_primary: false,
            },
            {
              button_id: "custom_submit_yes_button",
              label: t("StudentResponse.SubmitAsIs"),
              is_primary: true,
            },
          ],
        });
    }
  };
  const CheckIfLastQuestionForAdaptive = () => {
    waitForElementsToRender("", ".test-dialog-save-submit", 0, 1000).then(
      (_response) => {
        var d =
          document.getElementsByClassName("test-dialog-save-submit").length > 0
            ? true
            : false;
        if (d) {
          setIsLastItem(true);
        }
      }
    );
  };
  useEffect(() => {
    if (isSubmitted && !showVideoPopUp) {
      if (props.OnSubmit) {
        props.OnSubmit();
      }
    }
  }, [showVideoPopUp, isSubmitted]);

  useEffect(() => {
    if (
      submitBtnState.current &&
      !isTimeEnd &&
      props.timeLimitInSeconds &&
      props.timeLimitInSeconds > 0 &&
      props.setQuestionsAttemptedTillTimeLimit
    ) {
      props.setQuestionsAttemptedTillTimeLimit(
        itemsAPP?.attemptedItems().length
      );
    }
  }, [itemsAPP, submitBtnState.current]);

  useEffect(() => {
    if (showActivtySessionError && showPopup) {
      setShowPopup(false);
    }
    if (showActivtySessionError && showGamePopup) {
      setShowGamePopup(false);
    }
    if (!showGamePopup && isLastItemReached) {
      const submitButton = document.querySelector(".test-submit") as HTMLElement;
      submitButton.click();
    }
  }, [showActivtySessionError, showPopup, showGamePopup]);

  useEffect(() => {
    if (
      props.onFocused &&
      onFocus &&
      !showPopup &&
      !showGamePopup &&
      !showVideoPopUp &&
      !isLastItem &&
      !isPopUpClosed
    ) {
      props.onFocused();
    } else if (
      props.onUnfocused &&
      onUnFocus &&
      !showPopup &&
      !showGamePopup &&
      !showVideoPopUp &&
      !isLastItem &&
      !isPopUpClosed
    ) {
      props.onUnfocused();
    }
  }, [
    onFocus,
    onUnFocus,
    showPopup,
    showGamePopup,
    showVideoPopUp,
    isLastItem,
  ]);

  const setInputListenerForManualGradedQuestion = (currentItem) => {
    
    var questionReference = itemsApp.question(currentItem.response_ids[0]);

    let isManualGradingQuestion = Constant.ManualGradedQuestionTypes.find((x) => x === questionReference.getQuestion().type);
    
    if (isManualGradingQuestion) {
      var textEditor = LearnosityDom.getTextEditor(currentItem.reference); //document.querySelector(containerSelector);
      if(textEditor) {     
        addInputListenerForTextEditor(textEditor);        
      }
    }
  }

  const addInputListenerForTextEditor = (textEditorElement) => {

    textEditorElement.addEventListener('input', function() {

      var nextOrSubmitButton = LearnosityDom.getNextOrSubmitButton();

      if (nextOrSubmitButton) {

          nextOrSubmitButton.disabled = true;

          // Enable the Next/Submit button after time elapses
          setTimeout(function() {
              nextOrSubmitButton.disabled = false;
          }, waitTimeForInputListener);
      }
    });
  }

  return (
    <>
      <div className={props.studentType + "-Wrapper main-content"}>
        {(verifyQuestionMapIsEnabled() || props.allowPartialSubmit) && (
          <QuestionNavigationPlayer
            showQuestionMap={verifyQuestionMapIsEnabled()}
            questions={questionStates}
            changeQuestion={changeQuestionSelected}
            currentItem={currentItemReference}
            initialMapIndex={initialMapIndex}
            allowPartialSubmit={props.allowPartialSubmit}
            Exitlabel={
              props.language === "es" ? "Salida" : t("StudentResponse.Exit")
            }
            OnExitClick={() => OnExitClick()}
          />
        )}

        <div
          id="learnosity_assess"
          style={{ position: "relative", zIndex: 0 }}
        ></div>

        <div>{studentPopUp}</div>
      </div>

      {showGamePopup && (
        <GamePopup
          studentId={parseInt(props.studentId)}
          activityType={props.activityType}
          sessionId={props.sessionId!}
          showPopup={hideShowGamePopup}
        />
      )}
      {showVideoPopUp && (
        <VideoPopup
          videoUrl={score100percentVideoUrl}
          showPopup={setShowVideoPopUp}
        />
      )}
      {showActivityErrorPopup && (
        <ActivityErrorPopup
          showPopup={(show) => {
            setShowActivityErrorPopup(show);
          }}
          studentType={props.studentType}
        />
      )}

      {showActivtySessionError && (
        <ActivitySessionQuestionErrorPopup
          showPopup={(show) => {
            setShowActivitySessionError(show);
          }}
          studentType={props.studentType}
        ></ActivitySessionQuestionErrorPopup>
      )}

      {showNetworkErrorPopup && (
        <NetworkErrorMessagePopup
          cancelText="Try Again"
          cancelAction={() => setShowNetworkErrorPopup(false)}
        />
      )}
    </>
  );
}

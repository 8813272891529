import React, { useState } from "react";
import ActivityStatusListResponse from "../../../../../model/student/shared/assignment/activityStatusListResponse";
import { CompleteSavedActivityGrid } from "./completeSavedActivityGrid";
interface listProps {
  activityData: ActivityStatusListResponse[];
  userId: number;
  plLanguageId?: number;
}

function CompleteSavedActivity(props: listProps) {
  useState<boolean>(false);
  const savedActivityData = props.activityData;
  // const isIFrameEnabled = false;

  return (
    <>
      <div>
        {
         
             
              <CompleteSavedActivityGrid
                savedActivityData={savedActivityData}
                plLanguageId={props.plLanguageId}
              />
           
        }
      </div>
    </>
  );
}

export default CompleteSavedActivity;

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { RocketFire, RocketImage, PlanetSurfaceImage, SpaceShuttleImage } from "../../../../../assets/images/index";
import Profile from "../../../../../model/users/profile";
import { fetchProfile } from "../../../../../redux/actions/userActions";
import * as userContextAction from "../../../../../redux/actions/userContextAction";
import { UserContextState } from "../../../../../redux/actions/userContextAction";
import RouteConstant from "../../../../../utils/constant/routeConstant";
import Achievements from "../achievements";
import SubjectContentAreas from "../contentAreas";
import StudentProfile from "../../../../../model/student/studentProfile";
import { getStudentProfile } from "../../../../../api/student/studentProfile";
import useTextToSpeech from "../../shared/textToSpeech/useTextToSpeech";
import GoogleSignInModal from "../../../popup/googleSignInModal";
import Constant from "../../../../../utils/constant/constant";
import { getNewAssignmentsCount } from "../../../../../api/student/studentAssignmentApi";
import { getStudentContentAreaSubject } from "../../../../../api/student/studentSubjectApi";
import { IContentAreaSubject } from "../../../../../model/interface/subject";

interface HomeProps {
  userContext?: UserContextState;
  profile?: Profile;
  setUserContext?: (context: any) => void;
  setProfile?: (
    userId: number,
    roleId: number,
    schoolId: number,
    id: number
  ) => void;
}
const Home = (props: HomeProps) => {
  const history = useHistory();
  const { t } = useTranslation();

  const [studentProfile, setStudentProfile] = useState<StudentProfile>();
  const [showGooglePopup, setShowGooglePopup] = useState(false);
  const [subjectsData, setSubjectsData] = useState<Array<IContentAreaSubject>>();
  const [studentNewAssignmentsCount, setStudentNewAssignmentsCount] =
    useState<number>(0);
  const { startSpeaking, stopSpeaking } = useTextToSpeech();

  function handleSubjectClick(
    subjectId: number,
    name: string,
    contentAreaId: number
  ) {
    var path = RouteConstant.KindergartenStudentRoutes.SubjectStudy;
    path = encodeURI(
      path
        .replace(":subjectId", btoa(subjectId.toString()))
        .replace(":subjectName", btoa(name))
        .replace(":contentAreaId", btoa(contentAreaId.toString()))
    );
    history.push({
      pathname: path,
    });
  }

  function handleAssignmentClick() {
    history.push({
      pathname: RouteConstant.KindergartenStudentRoutes.Assignment,
    });
  }

  function bindStudentProfile() {
    getStudentProfile(
      props.userContext!.userId,
      props.profile?.gradeId ? props.profile?.gradeId : 0
    ).then((response) => {
      setStudentProfile(response.data);
    });
  }

  function getNewAssignmentCount() {
    getNewAssignmentsCount(props.userContext!.userId).then((response) => {
      setStudentNewAssignmentsCount(response.data);
    });
  }

  const loadSubjects = () =>{
    getStudentContentAreaSubject(props.userContext?.userId ?? 0)
      .then(response => setSubjectsData(response.data));
  }

  useEffect(() => {
    bindStudentProfile();
    getNewAssignmentCount();
    loadSubjects();
  }, []);

  useEffect(() => {
    if (
      props.userContext?.userId &&
      props.profile?.userId &&
      props.userContext?.userId > 0 &&
      props.profile?.userId > 0
    ) {
      const showPopup =
        window.localStorage.getItem("showGoogleSignInPopup")! === "true";
      if (
        props.profile?.googleUser?.refreshToken === null &&
        showPopup &&
        props.profile?.gmailId &&
        props.userContext?.impersonatedUser === null
      ) {
        setShowGooglePopup(true);
      }
    }
  }, [props.userContext?.userId, props.profile?.userId]);

  return (
    <>
      <div className="flex flex-col md:flex-row h-full overflow-auto relative">
        <div className="w-full px-5 pt-5 md:px-0 md:pt-0 relative flex flex-col lg:flex-row items-start justify-start lg:justify-center ">
          <div className="flex w-full mt-8 md:mt-0 mb-4 md:mb-0 justify-between md:px-8 lg:px-0 lg:w-[67%] xl:w-[65%] 1xl:w-[60%] md:pt-[6rem] lg:pt-[4rem] xl:pt-[2rem] 1xl:pt-[3rem] 2xl:pt-[4rem] lg:ml-[11.5rem] xl:ml-[12.5rem] 1xl:ml-[12.5rem] 2xl:ml-[13.5rem]">
            {subjectsData && <SubjectContentAreas 
              onSubjectSelected={handleSubjectClick} 
              subjectsData={subjectsData} 
            />}
          </div>
          <div className="fixed -bottom-20  right-[25%]  cursor-pointer"></div>
        </div>

        {subjectsData && <Achievements
          userContext={props.userContext}
          profile={props.profile}
          studentProfile={studentProfile!}
          subjectsData={subjectsData}
        />}
      </div>
      <div className="hidden fixed flex-shrink-0 xl:scale-100 -bottom-8 lg:-bottom-6 xl:bottom-7 left-[50%] scale-75 xl:scale-100 ml-[-8%] lg:ml-[-25%] xl:ml-[-20%]">
        <div className="flex items-center w-full ml-auto">
          <div>
            <SpaceShuttleImage className="w-64 z-10" />
          </div>
        </div>
      </div>
      <div className="mt-10 md:mt-[100px] lg:mt-[250px] flex-shrink-0  -bottom-0 md:scale-75 lg:scale-[.65] xl:scale-100 left-0 md:left-[2.5rem] lg:left-[-0.5rem] xl:left-[2rem]">
        <div className="flex items-center w-full justify-center md:ml-auto md:justify-start md:absolute md:overflow-hidden md:h-[650px] md:translate-y-[-100%] lg:translate-x-[-25%] xl:translate-x-0 md:pl-[15px]">
          <div className="flex flex-col items-center transform  relative md:-bottom-24 xl:-bottom-8">
            <div className="pb-8">
              {studentNewAssignmentsCount === 0 && (
                <RocketImage className="w-[7rem] md:w-[14rem] xl:w-[11rem] 1xl:w-48" />
              )}
              {studentNewAssignmentsCount > 0 && (
                <RocketFire className="w-48" />
              )}
            </div>
            <div
              className="flex items-center justify-center relative cursor-pointer group"
              onClick={handleAssignmentClick}
              onMouseEnter={() => {
                startSpeaking(
                  t("StudyType.Assignment"),
                  (props.profile?.plLanguageId ?? 1) === 2 ? 2 : 1
                );
              }}
              onMouseLeave={() => {
                stopSpeaking();
              }}
            >
              <ul className="flex relative group-hover:transform group-hover:scale-110 group-hover:opacity-80 transition-all duration-300 ">
                <li className="w-24 h-24 bg-white rounded-full"></li>
                <li className="w-[8rem] h-[9rem] md:w-44 md:h-44 bg-white rounded-full -mx-12 -mt-8"></li>
                <li className="w-24 h-24 bg-white rounded-full"></li>
                <li></li>
              </ul>

              {studentNewAssignmentsCount > 0 && (
                <span className="absolute top-0">
                  <span className="w-24 h-24 animate-ping-fast absolute left-0 top-0 bg-white rounded-full"></span>
                  <span className="w-24 h-24 animate-ping-slow absolute right-0 top-0 bg-white rounded-full"></span>
                  <span className="w-24 h-44 animate-ping-slow absolute top-0 bg-white rounded-full"></span>
                </span>
              )}
              <h2
                className={`transition-all font-Barlow font-bold duration-300 text-3xl md:text-4xl text-gray-900 absolute top-4 ${
                  studentNewAssignmentsCount > 0 ? "animate-fade" : ""
                }`}
              >
                {studentNewAssignmentsCount > 0 ? t("StudyPlan.New") : ""}{" "}
                {t("StudyType.Assignment")}
              </h2>
            </div>
          </div>
        </div>
      </div>

      <div className="hidden text-center gap-4 font-Didact grid grid-cols-1 w-full sm:w-1/2 mx-auto lg:grid-cols-1 px-3 lg:px-0 mt-6 mb-10 md:pb-32 lg:pb-0 md:hidden relative">
        <a
          href={RouteConstant.ARENA_KG}
          className="hidden flex items-start bg-[#0881de] hover:bg-[#00C5DB] border-2 border-[#0881de] p-1 rounded-2xl group hover:shadow-2xl lg:mx-3 items-left justify-center transition ease-in-out duration-500 sm:col-start-2"
        >
          <span className="w-full py-3 rounded-2xl border-2 ring-6 ring-[#90d12a] ring-offset-6 overflow-hidden relative text-xl lg:text-xl xl:text-2xl text-white hover:shadow-2xl text-left pl-3 h-full flex items-center flex-col gap-2">
            <SpaceShuttleImage className="w-16 h-20" />
            Alien Arena
          </span>
        </a>
        <span className="cursor-pointer flex items-center bg-[#0881de] hover:bg-[#00C5DB] border-2 border-[#0881de] p-1 rounded-2xl group hover:shadow-2xl lg:mx-3 items-left justify-center transition ease-in-out duration-500">
          <span
            onClick={handleAssignmentClick}
            className="w-full py-3 rounded-2xl border-2 ring-6 ring-[#90d12a] ring-offset-6 overflow-hidden relative text-xl  lg:text-xl xl:text-2xl text-white hover:shadow-2xl text-left pl-3 h-full flex items-center flex-col gap-2"
          ></span>
        </span>
      </div>

      <div className="md:hidden md:fixed md:bottom-28 md:bottom-7 w-full flex-shrink-0 ">
        <PlanetSurfaceImage className="w-full z-10 flex-shrink-0 flex" />
      </div>
      {showGooglePopup && (
        <GoogleSignInModal
          roleId={Constant.UserRoleId.Student}
          returnUrl={""}
          showPopUp={setShowGooglePopup}
          mode={Constant.GoogleSignInMode.SSO}
        />
      )}
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setUserContext: (payload: userContextAction.UserContextState) => {
      dispatch(userContextAction.setUserContext(payload));
    },
    setProfile: (
      userId: number,
      roleId: number,
      schoolId: number,
      districtId: number
    ) => {
      dispatch(fetchProfile(userId, roleId, schoolId, districtId));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);

import { useEffect, useRef, useState } from "react";

const VideoRecorder = ({ stream, onStartRecording, onStopRecording, maxRecordingTimeLimit }: 
    {
      stream: MediaStream | null,
      onStartRecording: () => void,
      onStopRecording: () => void,
      maxRecordingTimeLimit: number;
    }) => {      
    const videoRef = useRef<HTMLVideoElement>(null);
    const [countdown, setCountdown] = useState(0);
    const [recordClicked, setRecordClicked] = useState(false);
    const [isRecording, setIsRecording] = useState(false);
    const [recordingTimeLeft, setRecordingTimeLeft] = useState(maxRecordingTimeLimit / 1000);
  
    useEffect(() => {
      if (videoRef.current && stream) {
        videoRef.current.srcObject = stream;
      }
    }, [stream]);

    useEffect(() => {
      let timer: NodeJS.Timeout;
      if (countdown > 0) {
        timer = setTimeout(() => setCountdown(countdown - 1), 1000);
      } else if (countdown === 0 && recordClicked) {
        onStartRecording();
        setRecordClicked(false);
        setIsRecording(true);
        setRecordingTimeLeft(maxRecordingTimeLimit / 1000);
      }
      return () => clearTimeout(timer);
    }, [countdown, recordClicked, onStartRecording, maxRecordingTimeLimit]);

    useEffect(() => {
      let recordingTimer: NodeJS.Timeout;
      if (isRecording && recordingTimeLeft > 0) {
        recordingTimer = setTimeout(() => setRecordingTimeLeft(recordingTimeLeft - 1), 1000);
      } else if (isRecording && recordingTimeLeft === 0) {
        onStopRecording();
        setIsRecording(false);
      }
      return () => clearTimeout(recordingTimer);
    }, [isRecording, recordingTimeLeft, onStopRecording]);


    const handleStartCountdown = () => {
      setCountdown(3);
      setRecordClicked(true);
    };

    const handleStopClick = () => {
      onStopRecording();
      setIsRecording(false);
    };

    if (!stream) {
      return null;
    }

    return (
      <div style={{ position: 'relative' }}>
        <video style={{ width: '100%' }} ref={videoRef} autoPlay />
        {countdown > 0 && (
          <div style={{
            position: 'absolute',
            left: '0px',
            right: '0px',
            top: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            transform: 'translateY(-50%)',
            fontSize: '100px',  // Make the countdown text larger
            color: 'white',
            zIndex: 10,
          }}>
            {countdown}
          </div>
        )}
        {isRecording && (
          <div style={{
            position: 'absolute',
            top: '40px',
            right: '40px',
            fontSize: '24px',
            color: 'white',
            zIndex: 10,
            display: 'flex',
            alignItems: 'center'
          }}>
            <div style={{
              width: '10px',
              height: '10px',
              backgroundColor: 'red',
              borderRadius: '50%',
              marginRight: '5px'
            }}></div>
            {recordingTimeLeft}s
          </div>
        )}
        <div style={{
          position: 'absolute',
          left: '0px',
          right: '0px',
          top: '50%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          transform: 'translateY(-50%)',
        }}>
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}>
            {!isRecording && countdown === 0 && (
              <>
                <div style={{
                  fontFamily: 'Arial',
                  fontSize: '20px', // Adjusted for better visibility
                  color: 'white',
                  letterSpacing: '1.75px',
                  display: 'flex',
                  marginBottom: '40px', // Added margin bottom
                }}>
                  <div style={{ marginRight: '4px' }}>PRESS</div>
                  <div style={{ color: 'red', fontWeight: 'bold', marginRight: '4px' }}>REC</div>
                  <div style={{ marginRight: '4px' }}>WHEN</div>
                  READY
                </div>
                <div style={{
                  border: '8px solid rgba(255, 255, 255, 0.4)',
                  height: '80px',
                  width: '80px',
                  borderRadius: '50%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}>
                  <button
                    onClick={handleStartCountdown}
                    style={{
                      borderRadius: '50%',
                      width: '64px',
                      height: '64px',
                      backgroundColor: 'rgba(227, 73, 28, 0.8)',
                      outline: 'none',
                      border: 'none',
                      cursor: 'pointer',
                      zIndex: 5,
                      position: 'relative',
                    }}
                  />
                </div>
              </>
            )}
            {isRecording && (
              <button
                onClick={handleStopClick}
                style={{
                  borderRadius: '50%',
                  width: '64px',
                  height: '64px',
                  backgroundColor: 'rgba(227, 73, 28, 0.8)',
                  outline: 'none',
                  border: 'none',
                  cursor: 'pointer',
                  zIndex: 5,
                  position: 'relative',
                }}
              >
                <div
                  style={{
                    position: 'absolute',
                    left: '50%',
                    top: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '24px',
                    height: '24px',
                    backgroundColor: 'white',
                  }}
                />
              </button>
            )}
          </div>
        </div>
      </div>
    );
  
  };

export default VideoRecorder;
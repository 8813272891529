import { XIcon } from "@heroicons/react/outline";
import { connect } from "react-redux";
import NoData from "../../shared/noData";
import Pagination from "../../shared/pagination/pagination";
import PageSize from "../../shared/pagination/pageSize";
import { useEffect, useState } from "react";
import {
  DEFAULT_PAGE_NUMBER,
  DEFAULT_PAGE_SIZE,
} from "../../../utils/pagingConstant";
import { getNWEATestResultMappingStatusByFilter } from "../../../api/teacher/nwea";
import moment from "moment";

interface INWEAMappingStatusModal {
  userId?: number;
  classIds: number[] | undefined;
  gradeIds: number[] | undefined;
  teacherUserIds: number[] | undefined;
  studentUserIds: number[] | undefined;
  closeModal: () => void;
}

interface INWEAStudentResultModel {
  subject: string;
  testName: string;
  testDate: Date;
  topic: string;
  score: string;
  grade: string;
  isTestValid: boolean;
}

const NWEAMappingStatusModal = (props: INWEAMappingStatusModal) => {
  const [pageNumber, setPageNumber] = useState(DEFAULT_PAGE_NUMBER);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [resetPageNumber, setResetPageNumber] = useState<boolean>(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [nWEAMappingStatus, setNWEAMappingStatus] = useState<Array<any>>([]);

  const getNWEAMappingStatus = (
    userId?: number,
    pageNumber?: number,
    pageSize?: number
  ) => {
    getNWEATestResultMappingStatusByFilter(
      userId,
      pageNumber,
      pageSize,
      props.classIds,
      props.gradeIds,
      props.teacherUserIds,
      props.studentUserIds
    ).then((response) => {
      if (response.data.data.length > 0) {
        setTotalRecords(response.data.totalRecords);
        const studentResult: Array<INWEAStudentResultModel> =
          response.data.data;
        setNWEAMappingStatus(studentResult);
      } else {
        setNWEAMappingStatus([]);
        setTotalRecords(0);
      }
    });
  };

  useEffect(() => {
    getNWEAMappingStatus(props.userId, pageNumber, pageSize);
  }, [pageSize, pageNumber, resetPageNumber]);

  const onPageChange = (pageNo) => {
    setPageNumber(pageNo);
  };

  const onPageSizeChange = (pageSize) => {
    setPageNumber(DEFAULT_PAGE_NUMBER);
    setPageSize(pageSize);
    setResetPageNumber(true);
  };

  return (
    <div className="fixed inset-0 z-40 flex items-center bg-black bg-opacity-50 sm:items-center sm:justify-center">
      <div
        className="w-full flex flex-col justify-between overflow-hidden h-auto max-h-80percent bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:max-w-5xl"
        role="dialog"
      >
        <header>
          <div className="px-4 py-4 bg-gray-50">
            <div className="flex items-start justify-between space-x-3 border-b border-gray-300">
              <div className="space-y-1">
                <div className="text-lg font-medium text-gray-900">
                  <h2 className="text-lg text-gray-700 mb-2">
                    {" "}
                    NWEA Test Result Mapping Status
                  </h2>
                </div>
              </div>

              <div className="h-7 flex items-center">
                <button
                  type="button"
                  className="text-gray-400 hover:text-gray-500"
                  onClick={() => props.closeModal()}
                >
                  <span className="sr-only">Close panel</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </header>

        <div className="w-full relative overflow-auto px-4 py-3">
          <div className="shadow">
            <table className="table w-full ">
              <thead className="mb-5">
                <tr className="bg-light-violet  text-white py-3 px-3">
                  <th
                    scope="col"
                    className="text-left  p-3 text-sm font-normal"
                  >
                    <div>First Name</div>
                  </th>
                  <th
                    scope="col"
                    className="text-left  p-3 text-sm font-normal"
                  >
                    <div>Last Name</div>
                  </th>
                  <th
                    scope="col"
                    className="text-left  p-3 text-sm font-normal"
                  >
                    <div>Sync Status</div>
                  </th>
                  <th
                    scope="col"
                    className="text-left  p-3 text-sm font-normal"
                  >
                    <div>Mapped By</div>
                  </th>
                  <th
                    scope="col"
                    className="text-left  p-3 text-sm font-normal"
                  >
                    <div>Mapped On</div>
                  </th>
                </tr>
              </thead>

              <tbody>
                {nWEAMappingStatus?.map((result, i) => (
                  <tr
                    className={
                      i % 2 === 0
                        ? " hover:bg-light-violet/20 transition ease-in-out duration-300 bg-white"
                        : "hover:bg-light-violet/20 transition ease-in-out duration-300 bg-light-violet/10"
                    }
                  >
                    <td className="p-3 text-sm text-gray-500">
                      {result.firstName}
                    </td>
                    <td className="p-3 text-sm text-gray-500">
                      {result.lastName}
                    </td>
                    <td className="p-3 text-sm text-gray-500">
                      {result.status}
                    </td>
                    <td className="p-3 text-sm text-gray-500">
                      {result.createdByLastName +
                        ", " +
                        result.createdByFirstName}
                    </td>
                    <td className="p-3 text-sm text-gray-500">
                      {moment(result.createdOn).format("MM-DD-YYYY hh:mm:ss")}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="flex px-4 pb-4">
          {nWEAMappingStatus?.length === 0 && <NoData />}
          {nWEAMappingStatus?.length !== 0 && (
            <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between border-t-2 pt-4 mt-3">
              <Pagination
                totalRecords={totalRecords}
                pageNumber={pageNumber}
                pageSize={pageSize}
                onPageChange={onPageChange}
                reset={resetPageNumber}
                setPageNumber={setPageNumber}
              />
              <PageSize
                pageSize={pageSize}
                onPageSizeChange={onPageSizeChange}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default connect((state: any) => {
  return {
    masterData: state.masterData,
    userContext: state.userContext,
  };
}, {})(NWEAMappingStatusModal);

import { Fragment, useEffect, useState } from "react";
import {
  getInProgressDomainTests,
  getInProgressSummativeTests,
  getStudentStudyPlanSubjectStats,
  getStudyPlanStandards,
} from "../../../../../api/student/studyPlanApi";
import {
  IStudyPlanStandard,
  IDomainStandardGroup,
} from "../../../../../model/interface/studyPlanStandard";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import constant from "../../../../../utils/constant/constant";
import { Link, useHistory } from "react-router-dom";
import RouteConstant from "../../../../../utils/constant/routeConstant";
import { PlayIcon, QuestionMarkCircleIcon } from "@heroicons/react/solid";
import {
  getCannedTests,
  getInProgressCannedTests,
} from "../../../../../api/student/cannedTestApi";
import DotRank from "../../shared/dotRank";
import StarProgressChart from "./starProgressChart";
import {
  InfoIcon,
  PracticeTest,
  ProgressReportIcon,
  StarProgressIcon,
} from "../../../../../assets/icons";
import { paramEncode } from "../../../../../utils/urlHelper";
import { getSubjectDetail } from "../../../../../api/subjectAPI";
import StudyPlanSubjectTestPopup from "../../shared/popups/studyPlanSubjectTestPopup";
import {
  ICannedTest,
  IDomainTest,
  ISummativeTest,
} from "../../../../../model/interface/summativeTest/summativeTest";
import { IStudyPlanSubjectStats } from "../../../../../model/interface/studyPlanSubjectStats";
import { formatSecondsToTime } from "../../../../../utils/dateHelper";
import StudyPlanSubjectTests from "../../shared/studyPlanSubjectTest";
import { verifySubjectNeedsCannedTest } from "../../../../../utils/helper";
import { UserContextState } from "../../../../../redux/actions/userContextAction";
import Profile from "../../../../../model/users/profile";
import { IGrade } from "../../../../../model/interface/grade";

interface IStudyPlanStandardsTabProps {
  subjectId: number;
  subjectName: string;
  userContext: UserContextState;
  profile: Profile;
  setLoading(value: boolean): void;
  setSubjectName(name: string): void;
  themeName: "ELEMENTARY" | "MIDDLESCHOOL";
}

function StudyPlanStandardsTab(props: IStudyPlanStandardsTabProps) {
  const [showDotRankInfo, setShowDotRankInfo] = useState<boolean>(false);
  const [mouseOverID, setMouseOverID] = useState<number>();

  const { t } = useTranslation();
  const history = useHistory();
  const [standardGroups, setStandardGroups] = useState<
    Array<IDomainStandardGroup>
  >([]);
  const [subjectId] = useState<number>(Number(props.subjectId));
  const [pendingPracticeTests, setPendingPracticeTests] = useState(0);
  const [domainTestsInProgress, setDomainTestsInProgress] = useState<
    IDomainTest[]
  >([]);
  const [cannedTestsInProgress, setCannedTestsInProgress] = useState<
    ICannedTest[]
  >([]);
  const [showDescriptionInfo, setShowDescriptionInfo] =
    useState<boolean>(false);
  const [allowCannedTest, setAllowCannedTest] = useState<boolean>(false);
  const [openSubjectModal, setOpenSubjectModal] = useState<boolean>(false);
  const [smallSummativeTest, setSmallSummativeTest] =
    useState<ISummativeTest>();
  const [mediumSummativeTest, setMediumSummativeTest] =
    useState<ISummativeTest>();
  const [largeSummativeTest, setLargeSummativeTest] =
    useState<ISummativeTest>();
  const [cannedTests, setCannedTests] = useState<Array<any>>([]);
  const [studentSubjectStats, setStudentSubjectStats] =
    useState<IStudyPlanSubjectStats>();
  const [subjectGrades, setSubjectGrades] = useState<Array<IGrade>>([]);

  useEffect(() => {
    loadSubjectData();
  }, [subjectId]);

  async function loadSubjectData() {
    try {
      props.setLoading(true);
      await verifyTests(subjectId);
      await getSubjectStats();
      await loadStandards();
      await getPendingPracticeTests();
    } finally {
      props.setLoading(false);
    }
  }

  async function getSubjectStats() {
    await getStudentStudyPlanSubjectStats(
      props.userContext?.userId ?? 0,
      subjectId
    ).then((d) => {
      setStudentSubjectStats(d.data);
    });
  }

  async function loadStandards() {
    await getStudyPlanStandards(
      props.userContext?.userId ?? 0,
      subjectId,
      true,
      props.userContext?.schoolId ?? 0
    ).then((d) => {
      convertToStandardGroup(d.data);
    });
  }

  async function verifyTests(subjectId: number) {
    if (subjectId <= 0) return;
    await getSubjectDetail(subjectId).then((d) => {
      if (d.data) {
        var _subjectName = d.data?.name.toUpperCase();
        setSubjectGrades((d.data as any)?.grades ?? []);
        const cannedTestShouldBeCalled =
          verifySubjectNeedsCannedTest(_subjectName);
        if (props.subjectName === "") props.setSubjectName(_subjectName);
        if (cannedTestShouldBeCalled) {
          getCannedTests(props.userContext.userId, subjectId, false).then(
            (d) => {
              setCannedTests(d.data);
            }
          );
        } else {
          setCannedTests([]);
        }
        setAllowCannedTest(cannedTestShouldBeCalled);
      }
    });
  }

  function convertToStandardGroup(standards: Array<IStudyPlanStandard>) {
    const domains = Array.from(new Set(standards.map((item) => item.domainId)));
    var tempStandardGroup: Array<IDomainStandardGroup> = [];
    domains.forEach((d) => {
      var domain = standards.find((t) => t.domainId === d);
      var standardGroup: IDomainStandardGroup = {
        domainName:
          props.profile?.plLanguageId === constant.Languages.Spanish
            ? domain?.spanishDomainName ?? domain?.domainName ?? ""
            : domain?.domainName ?? "",
        domainId: parseInt(d),
        standards: standards.filter((s) => s.domainId === d),
      };
      tempStandardGroup.push(standardGroup);
    });
    setStandardGroups(tempStandardGroup);
  }

  function hasCompletedStandardStudy(standard: IStudyPlanStandard) {
    var totalVideosQuestions = calculateTotalVideoQuestions(
      standard.totalVideoQuestions
    );
    return (
      standard.completedVideoQuestions >= totalVideosQuestions &&
      standard.completedAssignments === 1
    );
  }

  function calculateTotalVideoQuestions(totalVideoQuestions) {
    var total = totalVideoQuestions;
    if (totalVideoQuestions > constant.MINIMUMSUBJECTVIDEOS) {
      total = Math.ceil(
        totalVideoQuestions * constant.PERCENTAGEVIDEOSREQUIRED
      );
    }
    return total;
  }
  function calculateTotalPractice(standard: IStudyPlanStandard) {
    var total = standard.totalVideoQuestions + 1;
    if (standard.totalVideoQuestions > constant.MINIMUMSUBJECTVIDEOS) {
      total =
        Math.ceil(
          standard.totalVideoQuestions * constant.PERCENTAGEVIDEOSREQUIRED
        ) + 1;
    }
    return total;
  }

  function calculateCompletedPractice(standard: IStudyPlanStandard) {
    var totalVideoQuestions = calculateTotalVideoQuestions(
      standard.totalVideoQuestions
    );
    var completed = totalVideoQuestions
      ? standard.completedVideoQuestions >= totalVideoQuestions &&
        standard.completedAssignments === 0
        ? totalVideoQuestions - 1
        : standard.completedVideoQuestions
      : 0;
    completed =
      completed +
      ((standard.completedAssignments ?? 0) > 1
        ? 1
        : standard.completedAssignments ?? 0);
    return completed;
  }

  function getStandardName(standard: IStudyPlanStandard) {
    if (
      props.profile?.plLanguageId === constant.Languages.Spanish &&
      (standard.spanishStandardName !== null ||
        standard.spanishStandardName !== undefined)
    )
      return standard.spanishStandardName ?? standard.standardName;
    else return standard.standardName;
  }

  function startStudyPlan(standard: IStudyPlanStandard) {
    history.push(
      RouteConstant.MiddleSchool.StudyPlan.replace(
        ":standardId",
        encodeURI(btoa(standard.standardId.toString()))
      )
        .replace(
          ":standardName",
          encodeURI(paramEncode(getStandardName(standard)))
        )
        .replace(":gameId", encodeURI(btoa("0")))
        .replace(":rocketId", encodeURI(btoa("0")))
        .replace(":blasterId", encodeURI(btoa("0")))
        .replace(":subjectId", encodeURI(btoa(subjectId.toString())))
        .replace(":blasterType", encodeURI(btoa("0")))
    );
  }

  function startVideoAssignment(standard: IStudyPlanStandard) {
    history.push(
      RouteConstant.MiddleSchool.VideoQuestionList
          .replace(":standardId", encodeURI(btoa((standard.standardId.toString()))))
          .replace(":standardName", paramEncode("[" + standard.standardCode + "] " + getStandardName(standard)))
          .replace(":subjectId", encodeURI(btoa(subjectId.toString())))
    );
  }

  async function getPendingPracticeTests() {
    await Promise.all([
      getInProgressSummativeTests(props.userContext.userId, subjectId),
      getInProgressDomainTests(props.userContext.userId, subjectId),
      getInProgressCannedTests(props.userContext.userId),
    ]).then(([summativeTests, domaintests, cannedTests]) => {
      const summativeTestCount = summativeTests.data
        ? summativeTests.data.length
        : 0;
      const smallTest = summativeTests.data.find((t) => t.testSize === 1);
      if (smallTest) setSmallSummativeTest(smallTest);

      const mediumTest = summativeTests.data.find((t) => t.testSize === 2);
      if (mediumTest) setMediumSummativeTest(mediumTest);

      const largeTest = summativeTests.data.find((t) => t.testSize === 3);
      if (largeTest) setLargeSummativeTest(largeTest);

      const cannedTestsProgress = (cannedTests.data as ICannedTest[]).filter(
        (t) => t.subjectId === subjectId
      );

      setPendingPracticeTests(summativeTestCount);
      setDomainTestsInProgress(domaintests.data);
      setCannedTestsInProgress(cannedTestsProgress);
    });
  }

  function isSubjectTestInProgress() {
    if (allowCannedTest) {
      return (
        cannedTests &&
        cannedTests.find(
          (t) =>
            t.statusId > 1 &&
            t.statusId !== constant.AssignmentStatusId.COMPLETE
        ) != null
      );
    }
    return pendingPracticeTests > 0;
  }

  function isDomainTestInProgress(domainId: number) {
    return domainTestsInProgress?.find((t) => t.domainId === domainId) != null;
  }

  function startDomainTest(domainId: number, name: string) {
    history.push({
      pathname: RouteConstant.MiddleSchool.DomainTest.replace(
        ":subjectId",
        encodeURI(btoa(subjectId.toString()))
      ).replace(":domainId", encodeURI(btoa(domainId.toString()))),
      state: { domainName: name, subjectName: props.subjectName },
    });
  }

  function startCannedTest(cannedTestId: number, learnosityReferenceId) {
    const cannedTest = cannedTests.filter(
      (t) => t.cannedTestId === cannedTestId
    )[0];

    history.push({
      pathname: RouteConstant.MiddleSchool.CannedTestGC.replace(
        ":subjectId",
        encodeURI(btoa(subjectId.toString()))
      )
        .replace(":cannedTestId", encodeURI(btoa(cannedTestId.toString())))
        .replace(
          ":learnosityReferenceId",
          encodeURI(btoa(learnosityReferenceId))
        ),
      state: {
        cannedTestTypeId: cannedTest.cannedTestTypeId,
        subjectName: cannedTest.subjectName,
      },
    });
  }

  function startSummativeTest(testSize: number) {
    history.push({
      pathname: RouteConstant.MiddleSchool.SummativeTest.replace(
        ":subjectId",
        encodeURI(btoa(subjectId.toString()))
      ).replace(":testSize", encodeURI(btoa(testSize.toString()))),
      state: { subjectName: props.subjectName },
    });
  }

  return (
    <Fragment>
      <>
        {openSubjectModal && (
          <StudyPlanSubjectTestPopup
            onClose={() => setOpenSubjectModal(false)}
            themeName={props.themeName}
          >
            <StudyPlanSubjectTests
              smallSummativeTest={smallSummativeTest}
              mediumSummativeTest={mediumSummativeTest}
              largeSummativeTest={largeSummativeTest}
              cannedTests={cannedTests}
              cannedTestsInProgress={cannedTestsInProgress}
              startSummativeTest={startSummativeTest}
              startCannedTest={startCannedTest}
              themeName={props.themeName}
              subjectGrades={subjectGrades}
            />
          </StudyPlanSubjectTestPopup>
        )}
        <div className="px-4 lg:px-8 flex justify-between items-end">
          <span className="text-xl text-white inline-block">
            {props.subjectName}
          </span>
          <div className="pb-1">
            <span
              className="bg-white bg-opacity-90 w-52 hover:shadow-lg px-3 py-2.5 rounded-md flex items-center justify-start relative cursor-pointer"
              onClick={() => {
                setOpenSubjectModal(true);
              }}
            >
              <span className="bg-site-pink bg-opacity-10 w-6 h-6 rounded-md flex items-center justify-center relative mr-2">
                <PracticeTest
                  className="h-4 stroke-current text-site-pink inline-flex"
                  title="practice test"
                />
              </span>
              <span className="text-gray-700">
                {t("StudyPlan.SubjectTests")}
              </span>
              {isSubjectTestInProgress() && (
                <label className="absolute -top-1 -right-1 w-3 h-3 flex items-center justify-center text-gray-700 font-semibold font-lg inline-block cursor-pointer bg-yellow-400 border border-yellow-500 hover:opacity-90 rounded-full"></label>
              )}
            </span>
          </div>
        </div>
        <div className="px-4 pb-4 pt-2 lg:px-8">
          <div className="overflow-x-auto rounded-lg md:overflow-visible w-full border-4 border-gray-700 border-opacity-20 shadow-xl">
            <table className="min-w-full divide-y divide-gray-200 roundedTable">
              <thead className="bg-gray-200 overflow-hidden">
                <tr>
                  <th
                    scope="col"
                    className="rounded-tl-lg w-96 max-w-96 bg-gray-100 pl-7 pr-3 py-3 text-left text-sm text-dark-teal tracking-wider"
                  >
                    {t("StudyPlan.Subject")}
                  </th>
                  <th
                    scope="col"
                    className="bg-gray-200 w-60 max-w-60 px-3 py-3 text-center text-sm font-normal text-dark-teal tracking-wider"
                  >
                    <div className="text-sm">
                      {t("StudyPlan.OverallDotRank")}
                    </div>
                    <div className="text-[12px]">
                      {t("StudyPlan.DotRankSubHeader")}
                    </div>
                  </th>
                  <th
                    scope="col"
                    className="bg-gray-100 px-3 py-3 text-center text-sm font-normal text-dark-teal tracking-wider"
                  >
                    {t("StudyPlan.TotalCompletedAssignments")}
                  </th>
                  <th
                    scope="col"
                    className="bg-gray-200 px-3 py-3 text-center text-sm font-normal text-dark-teal tracking-wider"
                  >
                    <div>{t("StudyPlan.StudyPlanActivities")}</div>
                    <div className="text-[12px]">
                      {t("StudyPlan.StudyPlanActivitiesSubHeader")}
                    </div>
                  </th>
                  <th
                    scope="col"
                    className="bg-gray-100 px-3 py-3 text-center text-sm font-normal text-dark-teal tracking-wider"
                  >
                    <div>{t("StudyPlan.TotalTimeSpent")}</div>
                    <div className="text-[12px]">DD:HH:MM</div>
                  </th>
                  <th
                    scope="col"
                    className="rounded-tr-lg bg-gray-200 w-60 max-w-60 px-3 py-3 text-center text-sm font-normal text-dark-teal tracking-wider"
                  >
                    {t("StudyPlan.DotRankSummary")}
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white">
                <tr>
                  <td className="px-3 py-4 border-r-2 border-gray-100">
                    <div className="relative mx-4">{props.subjectName}</div>
                  </td>
                  <td className="px-3 py-4 border-r-2 border-gray-100">
                    <div className="flex justify-center">
                      {studentSubjectStats && (
                        <DotRank
                          dotRankId={studentSubjectStats.dotRankId}
                          score={studentSubjectStats.overallScore}
                        />
                      )}
                      <InfoIcon
                        className="w-4 h-4"
                        onMouseOver={() => {
                          setShowDotRankInfo(true);
                          setMouseOverID(0);
                        }}
                        onMouseOut={() => {
                          setShowDotRankInfo(false);
                          setMouseOverID(0);
                        }}
                      />
                      <div className="flex gap-1 relative">
                        <span className="mt-1">
                          <div
                            className={`w-96 absolute bg-blue-50 top-10 left-0  transition-all shadow-lg border border-blue-200 p-4 rounded-lg z-20
                      ${
                        showDotRankInfo && mouseOverID === 0
                          ? "visible"
                          : "hidden"
                      }
                      `}
                            onMouseOver={() => {
                              setShowDotRankInfo(true);
                              setMouseOverID(0);
                            }}
                            onMouseOut={() => {
                              setShowDotRankInfo(false);
                              setMouseOverID(0);
                            }}
                          >
                            <div className="flex gap-1 flex-col bg-white/50 p-1">
                              <div>
                                <p>{t("StudyPlan.DotRankToolTip")}</p>
                              </div>
                              <div className="flex gap-1 flex-col bg-white/50 p-1">
                                <p className="flex items-center">
                                  <span className="w-3.5 h-3.5 rounded-full bg-[#9e9e9e] border border-black inline-block mr-1"></span>
                                  {t("StudyPlan.GrayDotRankToolTip")}
                                </p>
                                <p className="flex items-center">
                                  <span className="w-3.5 h-3.5 rounded-full bg-[#e53f71] border border-black inline-block mr-1"></span>{" "}
                                  {t("StudyPlan.PinkDotRankToolTip")}
                                </p>
                                <p className="flex items-center">
                                  <span className="w-3.5 h-3.5 rounded-full bg-[#f4d221] border border-black inline-block mr-1"></span>
                                  {t("StudyPlan.YellowDotRankToolTip")}
                                </p>
                                <p className="flex items-center">
                                  <span className="w-3.5 h-3.5 rounded-full bg-[#a3b938] border border-black inline-block mr-1"></span>
                                  {t("StudyPlan.GreenDotRankToolTip")}
                                </p>
                              </div>
                            </div>
                          </div>
                        </span>
                      </div>
                    </div>
                  </td>
                  <td className="px-3 py-2 border-r-2 border-gray-100">
                    <div className="flex justify-center relative w-32 mx-auto">
                      {studentSubjectStats?.totalCompletedAssignments ?? "-"}
                    </div>
                  </td>
                  <td className="px-3 py-2 border-r-2 border-gray-100">
                    <div className="flex justify-center relative w-32 mx-auto">
                      {studentSubjectStats?.totalStudyPlanActivities ?? "-"}
                    </div>
                  </td>
                  <td className="px-3 py-4 border-r-2 border-gray-100">
                    <div className="flex justify-center relative w-32 mx-auto">
                      {studentSubjectStats?.totalTimeSpentInSeconds
                        ? formatSecondsToTime(
                            studentSubjectStats?.totalTimeSpentInSeconds
                          )
                        : "-"}
                    </div>
                  </td>
                  <td className="px-3 py-4 border-r-2 border-gray-100">
                    <Link
                      to={
                        RouteConstant.MiddleSchool.ProgressReport.replace(
                          ":subjectId",
                          ""
                        ) + subjectId
                      }
                    >
                      <span>
                        <div className="flex text-sm items-center items-center px-2 bg-white rounded-lg border cursor-pointer hover:border-gray-500">
                          <span className="bg-white w-10 h-10 flex items-center justify-center">
                            <ProgressReportIcon title="Progress report" />
                          </span>
                          <span className="text-xs text-gray-700">
                            {t("StudyPlan.ProgressReport")}
                          </span>
                        </div>
                      </span>
                    </Link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        {standardGroups.map((group, index) => {
          return (
            <Fragment key={index}>
              <div className="px-4 py-4 lg:px-8">
                <div className="flex justify-between items-end pb-2">
                  <span className="text-lg text-white inline-block font-semibold">
                    {group.domainName}
                  </span>
                  {!allowCannedTest && (
                    <div className="pb-1">
                      <span
                        className="bg-white w-52 bg-opacity-90 hover:shadow-lg px-3 py-2.5 rounded-md flex items-center justify-start relative cursor-pointer"
                        onClick={() => {
                          startDomainTest(group.domainId, group.domainName);
                        }}
                      >
                        <span className="bg-site-pink bg-opacity-10 w-6 h-6 rounded-md flex items-center justify-center relative mr-2">
                          <PracticeTest
                            className="h-4 stroke-current text-site-pink inline-flex"
                            title="practice test"
                          />
                        </span>
                        <span className="text-gray-700">
                          {t("StudyPlan.DomainPracticeTest")}
                        </span>
                        {isDomainTestInProgress(group.domainId) && (
                          <label className="absolute -top-1 -right-1 w-3 h-3 flex items-center justify-center text-gray-700 font-semibold font-lg inline-block cursor-pointer bg-yellow-400 border border-yellow-500 hover:opacity-90 rounded-full">
                            {" "}
                          </label>
                        )}
                      </span>
                    </div>
                  )}
                </div>
                <div
                  className={classNames(
                    "overflow-x-auto rounded-lg md:overflow-visible w-full border-4 border-gray-700 border-opacity-20 shadow-xl ",
                    index !== 0 ? "" : ""
                  )}
                >
                  <table className="min-w-full divide-y divide-gray-200 roundedTable">
                    <thead className="bg-gray-200 overflow-hidden">
                      <tr>
                        <th
                          scope="col"
                          className="rounded-tl-lg bg-gray-100 w-96 min-w-96 pl-7 pr-3 py-3 text-left text-sm font-normal text-dark-teal tracking-wider"
                        >
                          {t("StudyPlan.Name")}
                        </th>
                        <th
                          scope="col"
                          className="bg-gray-200 w-60 max-w-60 px-3 py-3 text-center font-normal text-dark-teal tracking-wider"
                        >
                          <div className="text-sm">
                            {t("StudyPlan.OverallDotRank")}
                          </div>
                          <div className="text-[12px] normal-case">
                            {t("StudyPlan.DotRankSubHeader")}
                          </div>
                        </th>
                        <th
                          scope="col"
                          className="bg-gray-100 px-3 py-3 text-center text-sm font-normal text-dark-teal tracking-wider"
                        >
                          {t("StudyPlan.Videos")}
                        </th>
                        <th
                          scope="col"
                          className="bg-gray-200 px-3 py-3 text-center text-sm font-normal text-dark-teal tracking-wider"
                        >
                          {t("StudyPlan.Questions")}
                        </th>
                        <th
                          scope="col"
                          className="rounded-tr-lg w-60 max-w-60 bg-gray-100 px-3 py-3 text-center text-sm font-normal text-dark-teal tracking-wider"
                        >
                          {t("StudyPlan.PracticeProgress")}
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white">
                      {group.standards.map((standard, index) => (
                        <tr
                          key={index}
                          className={
                            index % 2 === 0
                              ? "bg-white border-b-2"
                              : "bg-gray-100 border-b-2"
                          }
                        >
                          <td
                            className="px-3 py-4 border-r-2 border-gray-100"
                            onMouseOver={() => {
                              setShowDescriptionInfo(true);
                              setMouseOverID(standard.standardId);
                            }}
                            onMouseOut={() => {
                              setShowDescriptionInfo(false);
                              setMouseOverID(0);
                            }}
                          >
                            <div className="relative mx-4">
                              {(standard.inProgressVideoQuestions > 0 ||
                                standard.inProgressAssignments > 0) && (
                                <label className="absolute -top-2 -left-4 w-4 h-4 flex items-center justify-center text-gray-700 font-semibold text-xs inline-block cursor-pointer bg-yellow-400 border border-yellow-500 hover:opacity-90 rounded-full">
                                  {standard.inProgressVideoQuestions +
                                    standard.inProgressAssignments}
                                </label>
                              )}
                              [{standard.standardCode}]{" "}
                              {getStandardName(standard)}
                            </div>
                            <div className="flex gap-1 relative">
                              <span className="mt-1">
                                <div
                                  className={`w-96 absolute bg-blue-50 top-0 left-0  transition-all shadow-lg border border-blue-200 p-4 rounded-lg z-50
                                ${
                                  showDescriptionInfo &&
                                  mouseOverID === standard.standardId
                                    ? "visible"
                                    : "hidden"
                                }
                                `}
                                  onMouseOver={() => {
                                    setShowDescriptionInfo(true);
                                    setMouseOverID(standard.standardId);
                                  }}
                                  onMouseOut={() => {
                                    setShowDescriptionInfo(false);
                                    setMouseOverID(0);
                                  }}
                                >
                                  <div className="flex gap-1 flex-col bg-white/50 p-1">
                                    <div className="flex">
                                      {props.profile.plLanguageId ===
                                        constant.Languages.Spanish &&
                                      standard.standardSpanishDescription
                                        ? standard.standardSpanishDescription
                                        : standard.standardDescription}
                                    </div>
                                  </div>
                                </div>
                              </span>
                            </div>
                          </td>
                          <td className="px-3 py-2 border-r-2 border-gray-100">
                            <div className="flex justify-center">
                              <DotRank
                                dotRankId={standard.dotRankId}
                                score={standard.score}
                              />
                              <InfoIcon
                                className="w-4 h-4"
                                onMouseOver={() => {
                                  setShowDotRankInfo(true);
                                  setMouseOverID(standard.standardId);
                                }}
                                onMouseOut={() => {
                                  setShowDotRankInfo(false);
                                  setMouseOverID(0);
                                }}
                              />
                              <div className="flex gap-1 relative">
                                <span className="mt-1">
                                  <div
                                    className={`w-96 absolute bg-blue-50 top-10 left-0  transition-all shadow-lg border border-blue-200 p-4 rounded-lg z-20
                                ${
                                  showDotRankInfo &&
                                  mouseOverID === standard.standardId
                                    ? "visible"
                                    : "hidden"
                                }
                                `}
                                    onMouseOver={() => {
                                      setShowDotRankInfo(true);
                                      setMouseOverID(standard.standardId);
                                    }}
                                    onMouseOut={() => {
                                      setShowDotRankInfo(false);
                                      setMouseOverID(0);
                                    }}
                                  >
                                    <div className="flex gap-1 flex-col bg-white/50 p-1">
                                      <div>
                                        <p>{t("StudyPlan.DotRankToolTip")}</p>
                                      </div>
                                      <div className="flex gap-1 flex-col bg-white/50 p-1">
                                        <p className="flex items-center">
                                          <span className="w-3.5 h-3.5 rounded-full bg-[#9e9e9e] border border-black inline-block mr-1"></span>
                                          {t("StudyPlan.GrayDotRankToolTip")}
                                        </p>
                                        <p className="flex items-center">
                                          <span className="w-3.5 h-3.5 rounded-full bg-[#e53f71] border border-black inline-block mr-1"></span>{" "}
                                          {t("StudyPlan.PinkDotRankToolTip")}
                                        </p>
                                        <p className="flex items-center">
                                          <span className="w-3.5 h-3.5 rounded-full bg-[#f4d221] border border-black inline-block mr-1"></span>
                                          {t("StudyPlan.YellowDotRankToolTip")}
                                        </p>
                                        <p className="flex items-center">
                                          <span className="w-3.5 h-3.5 rounded-full bg-[#a3b938] border border-black inline-block mr-1"></span>
                                          {t("StudyPlan.GreenDotRankToolTip")}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </span>
                              </div>
                            </div>
                          </td>
                          <td
                            className="px-3 py-2 border-r-2 border-gray-100"
                            title={t("StudyPlan.Learn")}
                          >
                            {standard.totalVideoQuestions > 0 && (
                              <div className="relative w-32 mx-auto">
                                {standard.inProgressVideoQuestions > 0 && (
                                  <label className="absolute -top-2 right-0 w-4 h-4 flex items-center justify-center text-gray-700 font-semibold font-lg inline-block cursor-pointer bg-yellow-400 border border-yellow-500 hover:opacity-90 rounded-full">
                                    {standard.inProgressVideoQuestions}
                                  </label>
                                )}
                                <button
                                  className="flex items-center mx-auto justify-center bg-white bg-opacity-50 w-32 border rounded-lg p-1 hover:border-gray-500 space-x-0.5"
                                  onClick={() => {
                                    startVideoAssignment(standard);
                                  }}
                                >
                                  <PlayIcon className="w-5 cursor-pointer text-green-400" />
                                  <span className="pl-2">
                                    {" "}
                                    {standard.completedVideoQuestions}/
                                    {standard.totalVideoQuestions}
                                  </span>
                                </button>
                              </div>
                            )}
                          </td>

                          <td
                            className="px-3 py-2 border-r-2 border-gray-100"
                            title={t("StudyPlan.StartStudyPlan")}
                          >
                            <div className="relative w-32 mx-auto">
                              {standard.inProgressAssignments > 0 && (
                                <label className="absolute -top-2 right-0 w-4 h-4 flex items-center justify-center text-gray-700 font-semibold font-lg inline-block cursor-pointer bg-yellow-400 border border-yellow-500 hover:opacity-90 rounded-full">
                                  {standard.inProgressAssignments}
                                </label>
                              )}
                              <button
                                className="flex items-center mx-auto justify-center bg-white bg-opacity-50 w-32 border rounded-lg p-1 hover:border-gray-500 space-x-0.5"
                                onClick={() => {
                                  startStudyPlan(standard);
                                }}
                              >
                                <QuestionMarkCircleIcon className="w-5 cursor-pointer text-gray-500" />
                                <span className="pl-2 flex inline-block">
                                  {standard.completedAssignments ?? 0}/1{" "}
                                </span>
                              </button>
                            </div>
                          </td>
                          <td className="px-3 py-2 border-r-2 border-gray-100">
                            <div className="justify-center">
                              {hasCompletedStandardStudy(standard) ===
                                false && (
                                <>
                                  <StarProgressChart
                                    total={calculateTotalPractice(standard)}
                                    completed={calculateCompletedPractice(
                                      standard
                                    )}
                                  />
                                </>
                              )}
                              {hasCompletedStandardStudy(standard) === true && (
                                <>
                                  <StarProgressIcon className="w-12 mx-auto" />
                                </>
                              )}
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </Fragment>
          );
        })}
      </>
    </Fragment>
  );
}

export default StudyPlanStandardsTab;

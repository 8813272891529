import { useEffect, useState } from "react";
import { DownArrowIcon, UpArrow } from "../../../../../assets/icons";
import DotRank from "../../../../shared/widgets/dotRank";

export default function ProgressReportBySchoolByStandard(props: any) {
  const [progressReportBySchoolStandard, setProgressReportBySchoolStandard] =
    useState<any>();
  useEffect(() => {
    setAllSchoolsSummary();
    setVisibilityForSchools();
    setVisibilityForSubjects();
    setProgressReportBySchoolStandard(props.data);
  }, []);

  const setAllSchoolsSummary = () => {
    if (
      props.data.schools &&
      props.data.schools.filter((x) => x.name === "All Schools").length === 0
    ) {
      let allSchoolsScore: any = {
        questionCount: 0,
        schoolCount: 0,
        questionsAttempted: 0,
        questionsCorrect: 0,
        classes: [],
        pctCorrect: [],
        pointsEarned: [],
        index: 0,
        id: 0,
        name: "All Schools",
      };
      props.data?.subjects?.forEach((subject) => {
        allSchoolsScore.questionsAttempted = subject.questionsAttempted;
        allSchoolsScore.questionsCorrect = subject.questionsCorrect;
        allSchoolsScore.pointsEarned.push(subject.pointsEarned);
        allSchoolsScore.pctCorrect.push(
          getPercentage(
            subject.pointsEarned,
            subject.pointsPossible,
            subject.questionsAttempted
          )
        );
        subject.domains?.forEach((domain) => {
          allSchoolsScore.pointsEarned.push(domain.pointsEarned);
          allSchoolsScore.pctCorrect.push(
            getPercentage(
              domain.pointsEarned,
              domain.pointsPossible,
              domain.questionsAttempted
            )
          );
          domain.standards?.forEach((standard) => {
            allSchoolsScore.pointsEarned.push(standard.pointsEarned);
            allSchoolsScore.pctCorrect.push(
              getPercentage(
                standard.pointsEarned,
                standard.pointsPossible,
                standard.questionsAttempted
              )
            );
          });
        });
      });
      props.data?.schools?.forEach((school) => {
        allSchoolsScore.questionCount += school.questionCount;
        allSchoolsScore.schoolCount += school.schoolCount;
      });

      props.data.schools = [allSchoolsScore, ...props.data.schools];
    }
  };

  const getPercentage = (pointsEarned, pointsPossible, questionsAttempted) => {
    if (
      pointsEarned === null ||
      pointsPossible === null ||
      questionsAttempted < 2
    )
      return null;

    return pointsPossible === 0 ? 0 : pointsEarned / pointsPossible;
  };

  const setVisibilityForSchools = () => {
    props.data?.schools?.forEach((school, i) => {
      school["isCollapse"] = true;
      school.visibility = [];
      if (i !== 0) school.pctCorrect = [];
      school?.pointsEarned?.forEach((pct, j) => {
        school.visibility.push(true);
        if (i !== 0) {
          school.pctCorrect.push(
            getPercentage(
              school?.pointsEarned[j],
              school?.pointsPossible[j],
              school?.questionsAttempted[j]
            )
          );
        }
      });
    });
  };

  const setVisibilityForSubjects = () => {
    let index = 0;
    let visibility: any = [];
    let contentTypes: any = [];
    props.data?.subjects?.forEach((subject) => {
      let subjectChild = 0;
      subject["index"] = index;
      subject["isCollapse"] = false;
      visibility.push(true);
      contentTypes.push(0);
      index = index + 1;
      subject.domains?.forEach((domain) => {
        let domaintChild = 0;
        domain["index"] = index;
        domain["isCollapse"] = false;
        subjectChild = subjectChild + 1;
        visibility.push(true);
        contentTypes.push(1);
        index = index + 1;
        domain.standards?.forEach((standard) => {
          standard["displayName"] = "(" + standard.code + ") " + standard.name;
          standard["index"] = index;
          standard["isCollapse"] = false;
          subjectChild = subjectChild + 1;
          domaintChild = domaintChild + 1;
          visibility.push(true);
          contentTypes.push(2);
          index = index + 1;
        });
        domain["childCount"] = domaintChild;
      });
      subject["childCount"] = subjectChild;
    });

    if (props.data) {
      props.data.visibility = visibility;
      props.data.contentTypes = contentTypes;
    }
  };

  const handleHorizontalCollapse = (entity, value, entityType) => {
    entity.isCollapse = value;
    if (entityType === "SUBJECT") {
      entity.domains.forEach((domain) => {
        progressReportBySchoolStandard.visibility[domain.index] = !value;
        domain.standards.forEach((standard) => {
          if (domain.isCollapse && !value)
            progressReportBySchoolStandard.visibility[standard.index] = false;
          else
            progressReportBySchoolStandard.visibility[standard.index] = !value;
        });
      });
    } else if (entityType === "DOMAIN") {
      entity.standards.forEach((standard) => {
        progressReportBySchoolStandard.visibility[standard.index] = !value;
      });
    }

    progressReportBySchoolStandard?.schools?.forEach((school) => {
      school?.pctCorrect?.forEach((pct, index) => {
        school.visibility[index] =
          progressReportBySchoolStandard.visibility[index];
      });
    });

    let _progressReportBySchoolStandard = {
      ...progressReportBySchoolStandard,
    };
    setProgressReportBySchoolStandard(_progressReportBySchoolStandard);
  };

  return (
    <>
      {progressReportBySchoolStandard && (
        <div className="pt-60 overflow-x-auto ml-1">
          <table className="border-b border-primary-violet">
            <tr className="border-b border-primary-violet">
              <th className="py-1 text-left pl-2 sticky left-0 bg-white z-[11]">
                <span className="text-primary-violet text-base font-semibold">
                  School
                </span>
                <div
                  className="absolute left-0 w-full z-[9] bg-white"
                  style={{ top: "-300px", height: "300px" }}
                ></div>
              </th>
              {progressReportBySchoolStandard.subjects.map(
                (subject, subjectIndex) => (
                  <>
                    <th className="relative" title={subject.name}>
                      <p className="w-[271px] absolute top-[-5.25rem] left-[-0.75rem] transform -rotate-45 whitespace-nowrap flex items-center text-lg font-bold">
                        {subject.name.length > 30
                          ? subject.name.substring(0, 30) + "..."
                          : subject.name}
                        <div
                          onClick={() =>
                            handleHorizontalCollapse(
                              subject,
                              !subject.isCollapse,
                              "SUBJECT"
                            )
                          }
                        >
                          {!subject.isCollapse && (
                            <UpArrow className="w-5 mr-2 cursor-pointer text-blue-600" />
                          )}
                          {subject.isCollapse && (
                            <DownArrowIcon className="w-5 mr-2 cursor-pointer text-blue-600" />
                          )}
                        </div>
                      </p>
                    </th>
                    {!subject.isCollapse &&
                      subject.domains.map((domain, domainIndex) => (
                        <>
                          <th className="relative" title={domain.name}>
                            <p className="w-[271px] absolute top-[-5.25rem] left-[-0.75rem] transform -rotate-45 whitespace-nowrap flex items-center text-base font-medium">
                              {domain.name.length > 40
                                ? domain.name.substring(0, 40) + "..."
                                : domain.name}
                              <div
                                onClick={() =>
                                  handleHorizontalCollapse(
                                    domain,
                                    !domain.isCollapse,
                                    "DOMAIN"
                                  )
                                }
                              >
                                {!domain.isCollapse && (
                                  <UpArrow className="w-5 mr-2 cursor-pointer text-red-600" />
                                )}
                                {domain.isCollapse && (
                                  <DownArrowIcon className="w-5 mr-2 cursor-pointer text-red-600" />
                                )}
                              </div>
                            </p>
                          </th>

                          {!subject.isCollapse &&
                            !domain.isCollapse &&
                            domain.standards.map((standard, standardIndex) => (
                              <th
                                className="relative"
                                title={standard.displayName}
                              >
                                <p className="w-[271px] absolute top-[-5.25rem] left-[-0.75rem] transform -rotate-45 whitespace-nowrap flex items-center text-sm font-normal">
                                  {standard.displayName.length > 40
                                    ? standard.displayName.substring(0, 40) +
                                      "..."
                                    : standard.displayName}
                                </p>
                              </th>
                            ))}
                        </>
                      ))}
                  </>
                )
              )}
            </tr>

            {progressReportBySchoolStandard.schools.map(
              (school, schoolIndex) => (
                <>
                  <tr className="border-r border-primary-violet">
                    <td className="whitespace-nowrap sticky left-0 bg-white relative">
                      <div
                        className={
                          (schoolIndex === 0 ? "font-semibold " : "") +
                          "flex items-center justify-start w-full  px-2"
                        }
                      >
                        {school.name}
                      </div>
                      <div className="absolute top-0 left-0 z-[11] h-[100%] w-1 border-l border-primary-violet"></div>
                    </td>
                    {school.pctCorrect.map(
                      (pct, index) =>
                        school.visibility[index] && (
                          <td
                            className={
                              "text-center p-3 text-sm font-normal " +
                              (props.data.contentTypes[index] === 1
                                ? "border-l border-gray-500"
                                : "")
                            }
                          >
                            <DotRank
                              shape="circle"
                              displayScore={true}
                              value={
                                pct != null ? Math.round(pct * 100) : undefined
                              }
                            ></DotRank>
                          </td>
                        )
                    )}
                  </tr>
                </>
              )
            )}
          </table>
        </div>
      )}
    </>
  );
}

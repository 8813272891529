import "antd/dist/antd.css";
import classNames from "classnames";
import { Fragment, useEffect, useState } from "react";
import { IAssessment } from "../../../../model/interface/assessment";
import InfoMessage from "../../../shared/infoMessage";
import { IAssessmentQuestion } from "../../../../model/interface/assessmentQuestion";
import NonPassageQuestions from "./nonPassageQuestions";
import PassageQuestion from "./passageQuestion";
import AuthoredQuestions from "./authoredQuestion";
import SystemGeneratedQuestions from "./systemGeneratedQuestions";
import Constant from "../../../../utils/constant/constant";
import {
  addAssessment,
  getSubjectDetail,
  updateAssessmentData,
} from "../../../../api/teacher/assessment";
import { toast } from "react-toastify";
import Loader from "../../../shared/loader";
import { ExclamationIcon } from "@heroicons/react/solid";
import { RouterPrompt } from "../../../shared/routerPrompt";
import React, { forwardRef } from "react";
import auth from "../../../../utils/auth";
import UpGradeMessagePopup from "../../../shared/upGradeMessagePopup";
import ConfirmationDialog from "../../../shared/confirmationDialog";
import ReactTooltip from "react-tooltip";
import Select from "react-select";
import { ISelectElement } from "../../../../model/interface/selectElement";
import TagSelector from "../../../shared/tagSelector";
import arraySort from "array-sort";
import { getAssociatedSubjects } from "../../../../api/teacher/assignment";
import { getAllTags } from "../../../../api/teacher/tags";
import {
  getAsessementCountUpgradePermission,
  getAuthoredQuestionsUpgradePermission,
} from "../../../shared/permissionHelper";
import Details from "../../../shared/details";

interface ContentProps {
  userId: number;
  roleId: number;
  districtId: number;
  hasDistrictAssessmentQuestionIcon: boolean;
  assessment: IAssessment;
  subjectDetail: any;
  updateAssessment: (
    updateAssessment: IAssessment,
    moveToNextTab?: boolean
  ) => void;
  allowEdit: boolean;
  togglePreviewTab: any;
  updateSubjectDetail: (subjectDetail: any) => void;
  setIsCalculatorSubject: Function;
  upgrade: boolean;
  isSchoolAssessment: boolean;
}

const Content = forwardRef((props: ContentProps, ref: any) => {
  const {
    userId,
    roleId,
      districtId,
    hasDistrictAssessmentQuestionIcon,
    assessment,
    subjectDetail,
    updateAssessment,
    allowEdit,
    updateSubjectDetail,
    setIsCalculatorSubject,
    upgrade,
    isSchoolAssessment,
  } = props;
  type TabButton = "I Will Select the Questions" | "Question Quick Pick" | "";
  type AssessmentPart =
    | "NonPassageQuestions"
    | "PassageQuestions"
    | "AuthoredQuestions"
    | "SystemGenerated";

  const [tabs] = useState<Array<TabButton>>([
    "I Will Select the Questions",
    "Question Quick Pick",
  ]);
  const [selectedTab, setSelectedTab] = useState<TabButton>("");
  const [assessmentPart, setAssessmentPart] = useState<AssessmentPart>(
    "NonPassageQuestions"
  );

  type AssessmentPartInfoType = {
    id: number;
    name: string;
    type: AssessmentPart;
    isUpgradeRequired: boolean;
  };

  let [assessmentParts] = useState<AssessmentPartInfoType[]>([
    {
      id: 0,
      name: "Domain and Standard",
      type: "NonPassageQuestions",
      isUpgradeRequired: false,
    },
    {
      id: 1,
      name: "Passage Questions",
      type: "PassageQuestions",
      isUpgradeRequired: false,
    },
    {
      id: 2,
      name: "My Authored Questions",
      type: "AuthoredQuestions",
      isUpgradeRequired: getAuthoredQuestionsUpgradePermission(roleId),
    },
  ]);

  const [selectedQuestions, setSelectedQuestions] = useState<
    Array<IAssessmentQuestion>
  >(assessment.questions ? assessment.questions : []);
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [testSizeTypeId, setTestSizeTypeId] = useState<number>();
  const [isDataChanged, setDataChanged] = useState<boolean>(false);
  const [upgradeWithLimit, setUpgradeWithLimit] = useState(false);
  const [showUpgradeLimitPopup, setShowUpgradeLimitPopup] =
    useState<boolean>(false);
  const [showUpgradePopup, setShowUpgradePopup] = useState<boolean>(false);
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [assessmentName, setAssessmentName] = useState(assessment.name);
  const defaultValue = { label: "Select", value: "0" };
  const [selectedSubject, setSelectedSubject] =
    useState<ISelectElement>(defaultValue);
  const [subjects, setSubjects] = useState<Array<any>>([]);
  const [selectedSubjectConfirm, setSelectedSubjectConfirm] =
    useState<ISelectElement>(defaultValue);
  const [selectedTags, setSelectedTags] = useState<Array<number>>([]);
  const [resetGenerateQuestions, setResetGenerateQuestions] = useState<boolean>(false);
  const calculatorContentArea: Array<string> = [
    "Math",
    "Science",
    "Social Studies",
  ];
  const [calculatorSubjects, setCalculatorSubjects] = useState<Array<number>>(
    []
  );
  const [enableQuestionSelectionTabs, setEnableQuestionSelectionTabs] =
    useState<boolean>(false);

  React.useImperativeHandle(ref, () => ({
    handleSaveAndPreviewFromParent() {
      if (selectedQuestions.length > 0) {
        handleSaveAndPreview();
      }
    },
  }));

  function handleTabChange(tabButton: TabButton) {
    setSelectedTab(tabButton);
    if (tabButton === "Question Quick Pick") {
      setAssessmentPart("SystemGenerated");
    } else {
      setAssessmentPart("NonPassageQuestions");
    }
  }

  function handleSelectedQuestions(question: IAssessmentQuestion) {
    const questionExist = selectedQuestions.some(
      (r) => {
        return (r.questionId === question.questionId &&
            r.assessmentQuestionTypeId === question.assessmentQuestionTypeId)
        ||
        // when people add and remove system generated questions "quick pick"
        // you can run into a scenario where the system adds a question and then
        // the user picks the same question which is added in duplicate without
        // the condition below
        (r.questionId === question.questionId &&
            r.assessmentQuestionTypeId == 4 && question.assessmentQuestionTypeId == 1
        )
      });
    var selectedQuestionList: Array<IAssessmentQuestion> =
      new Array<IAssessmentQuestion>();
    if (questionExist) {
      selectedQuestionList = selectedQuestions.filter(
        (r) => r.questionId !== question.questionId
      );
    } else {
      selectedQuestionList = [...selectedQuestions, question];
    }

    setSelectedQuestions(selectedQuestionList);
    props.togglePreviewTab(selectedQuestionList.length);
    setDataChanged(true);
  }

  function handleSystemGeneratedQuestions(
    testSizeTypeId: number | undefined,
    questions: Array<IAssessmentQuestion>
  ) {
    setTestSizeTypeId(testSizeTypeId);

    const existingQuestions = selectedQuestions.filter(
      (r) =>
        r.assessmentQuestionTypeId !==
        Constant.AssessmentQuestionType.SystemGenerated
    );
    // not efficient but required
    const systemGeneratedQuestionsToAdd = questions.filter(
        (sysQuestion) => !existingQuestions.find(q => q.questionId == sysQuestion.questionId)
    );

    var selectedQuestionList = [...existingQuestions, ...systemGeneratedQuestionsToAdd];

    setSelectedQuestions(selectedQuestionList);
    props.togglePreviewTab(selectedQuestionList.length);
    setDataChanged(true);
  }

  function hasQuestionUpdated(
    currentQuestions: Array<IAssessmentQuestion>,
    updatedQuestions: Array<IAssessmentQuestion>
  ) {
    let questionUpdated: boolean = false;
    if (currentQuestions.length !== updatedQuestions.length) {
      questionUpdated = true;
    }

    const matchedQuestons = updatedQuestions.filter((r) =>
      currentQuestions.some(
        (s) => s.questionId === r.questionId && s.standardId === r.standardId
      )
    );

    if (matchedQuestons.length !== currentQuestions.length) {
      questionUpdated = true;
    }

    return questionUpdated;
  }

  async function checkUpgradeLimit() {
    if (upgrade) {
      if (upgradeWithLimit) {
        setShowUpgradeLimitPopup(true);
        return true;
      } else {
        let isRestricted: boolean = await getAsessementCountUpgradePermission(
          roleId
        );
        setUpgradeWithLimit(isRestricted);
        setShowUpgradeLimitPopup(isRestricted);
        return isRestricted;
      }
    } else {
      setUpgradeWithLimit(false);
    }
    return false;
  }

  function getSharedType() {
    let sharedType: number | undefined = undefined;

    if (roleId === Constant.UserRoleId.SchoolTeacher) {
      sharedType = Constant.AssessmentSharedType.ClassShared;
    } else if (
      roleId === Constant.UserRoleId.SchoolAdmin ||
      roleId == Constant.UserRoleId.FreeTeacher
    ) {
      if (isSchoolAssessment === true) {
        sharedType = Constant.AssessmentSharedType.SchoolShared;
      } else {
        sharedType = Constant.AssessmentSharedType.ClassShared;
      }
    } else if (roleId === Constant.UserRoleId.District) {
      sharedType = Constant.AssessmentSharedType.DistrictShared;
    }
    return sharedType;
  }

  async function handleSaveAndPreview(logoutClicked = false) {
    if (assessment.assessmentId == 0) {
      const isRestricted = await checkUpgradeLimit();
      if (isRestricted) return;
    }
    if (assessmentName.trim() === "") {
      toast.error("Assessment name cannot be empty");
      return false;
    } else if (selectedSubject.value === "0") {
      toast.error("Please select subject");
      return false;
    }

    if (selectedQuestions.length === 0) {
      toast.error("You must add at least 1 question to enable preview.");
      return false;
    }

    const currentQuestionIds: Array<number> = [];
    assessment.questions?.map((q) => {
      currentQuestionIds.push(q.questionId);
      return null;
    });

    const sharedTypeId = assessment.sharedTypeId ?? getSharedType();

    const assessmentToSave: IAssessment = {
      ...assessment,
      name: assessmentName,
      subjectId: parseInt(selectedSubject.value),
      tagIds: selectedTags,
      displayResultInDetailId: assessment.displayResultInDetailId ?? 1,
      sharedTypeId: sharedTypeId,
    };
    if (hasQuestionUpdated(assessment.questions ?? [], selectedQuestions)) {
      assessmentToSave.updateQuestions = true;
    }
    let sortedQuestionList: IAssessmentQuestion[];
    selectedQuestions.forEach((question, index) => {
      question.sequenceNumber = index + 1;
    });
    sortedQuestionList = selectedQuestions;
    assessmentToSave.testSizeTypeId = testSizeTypeId;
    assessmentToSave.questions = sortedQuestionList;

    const existingTags: any = assessmentToSave.tagIds;
    if (existingTags && existingTags.indexOf(",") > -1) {
      assessmentToSave.tagIds = existingTags.split(",");
    }

    if (!allowEdit) {
      updateAssessment(assessmentToSave, true);
      return;
    }

    if (assessment.assessmentId > 0) {
      setShowLoading(true);
      updateAssessmentData(userId, roleId, assessmentToSave)
        .then((r) => {
          setShowLoading(false);
          if (assessmentToSave.learnosityIdEnglish == null) {
            assessmentToSave.learnosityIdEnglish = r.data.learnosityIdEnglish;
            assessmentToSave.learnosityIdSpanish = r.data.learnosityIdSpanish;
          }
          const assessmentSaved = r.data as IAssessment;
          assessmentSaved.questions?.forEach(function (questionSaved) {
            assessmentToSave.questions?.forEach(function (questionToSave) {
              if (questionSaved.questionId === questionToSave.questionId) {
                questionToSave.domainOrder = questionSaved.domainOrder;
                questionToSave.standardOrder = questionSaved.standardOrder;
                questionToSave.pointsPossible = questionSaved.pointsPossible;
              }
            });
          });
          updateAssessment(
            assessmentToSave,
            assessment.assignmentStatus?.toLocaleUpperCase() ===
              Constant.AssignmentStatus.INPROGRESS
              ? false
              : true
          );
        })
        .catch((r) => {
          toast.error(
            "An unexpected error occurred while trying to save the assessment"
          );
        })
        .finally(() => {
          if (logoutClicked) auth.logout();
          setShowLoading(false);
        });
    } else {
      setShowLoading(true);
      addAssessment(userId, roleId, assessmentToSave).then((response) => {
        assessmentToSave.assessmentId = response.data;
        updateAssessmentData(userId, roleId, assessmentToSave)
          .then((r) => {
            const assessmentSaved = r.data as IAssessment;
            assessmentSaved.questions?.forEach(function (questionSaved) {
              assessmentToSave.questions?.forEach(function (questionToSave) {
                if (questionSaved.questionId === questionToSave.questionId) {
                  questionToSave.domainOrder = questionSaved.domainOrder;
                  questionToSave.standardOrder = questionSaved.standardOrder;
                  questionToSave.pointsPossible = questionSaved.pointsPossible;
                }
              });
            });
          })
          .catch((r) => {
            toast.error(
              "An unexpected error occurred while trying to save the assessment"
            );
          })
          .finally(() => {
            if (logoutClicked) auth.logout();
            setShowLoading(false);
            updateAssessment(assessmentToSave, false);
            updateAssessment(assessmentToSave, true);
          });
      });
    }
  }

  const colourStyles = {
    option: (provided, state) => ({
      ...provided,
      color:
        state.label === "My Subjects" || state.label === "Additional Subjects"
          ? "#992883"
          : "default",
    }),
  };

  function confirmationOkClose() {
    setSelectedSubject(selectedSubjectConfirm);
    bindSubjectDetail(parseInt(selectedSubjectConfirm.value));
    setShowConfirmation(false);
    if(assessment.assessmentId > 0) {
      const assessmentToSave: IAssessment = assessment;
      assessmentToSave.questions = [];
      assessmentToSave.updateQuestions = true;
      updateAssessment(assessmentToSave, false);
      const existingTags: any = assessmentToSave.tagIds;
      if (existingTags && existingTags.indexOf(",") > -1) {
        assessmentToSave.tagIds = existingTags.split(",");
      }
      updateAssessmentData(userId, roleId, assessmentToSave);
    }
    else {
      setSelectedQuestions([]);
      // Clear quick pick selection and move to the first tab
      setSelectedTab(tabs[0]);
      setAssessmentPart("NonPassageQuestions");
      setTestSizeTypeId(undefined);
      setResetGenerateQuestions(true);
    }
  }

  function confirmationCancelClose() {
    setShowConfirmation(false);
  }

  function handleAssignmentNameChange(e: any) {
    const assessmentName = e.target.value;
    if (
      selectedSubject.value !== "0" &&
      assessmentName.trim() !== "" &&
      !enableQuestionSelectionTabs
    ) {
      setEnableQuestionSelectionTabs(true);
      setSelectedTab(tabs[0]);
      setAssessmentPart("NonPassageQuestions");
    }
    setAssessmentName(assessmentName);
    if (assessment.assessmentId > 0) {
      setDataChanged(true);
    }
  }

  const findSubjectByValue: any = () => {
    if (!selectedSubject.value) {
      return null;
    }

    if (!selectedSubject || selectedSubject.value === "0") return null;

    return subjects
      .filter((r) => r.options)
      ?.reduce((prev, curr) => {
        return (
          prev ||
          curr.options.find((option) => option.value === selectedSubject.value)
        );
      }, null);
  };

  function handleSubjectChange(selectdItem: any) {
    if (!selectdItem.value) {
      return false;
    }
    if (
      selectdItem.value !== "0" &&
      assessmentName.trim() !== "" &&
      !enableQuestionSelectionTabs
    ) {
      setEnableQuestionSelectionTabs(true);
      setSelectedTab(tabs[0]);
      setAssessmentPart("NonPassageQuestions");
    }
    if (
      (assessment.assessmentId > 0 &&
      assessment.questions &&
      assessment.questions.length > 0) || selectedQuestions.length > 0
    ) {
      setSelectedSubjectConfirm(selectdItem);
      setShowConfirmation(true);
      checkSubjectHasCalculator(parseInt(selectdItem.value));
    } else {
      bindSubjectDetail(parseInt(selectdItem.value));
      setSelectedSubject(selectdItem);
    }
    if (assessment.assessmentId > 0) {
      setDataChanged(true);
    }
  }

  const checkSubjectHasCalculator = (subjectId: number) => {
    const subjectCanUseCalculator = calculatorSubjects.some(
      (r) => r.toString() === subjectId.toString()
    );

    setIsCalculatorSubject(subjectCanUseCalculator);
  };

  function bindSubjectDetail(subjectId: number) {
    if (!subjectId || subjectId === 0) return;
    setShowLoading(true);
    getSubjectDetail(subjectId).then((response) => {
      const data = response.data;
      setShowLoading(false);
      updateSubjectDetail(data);
    });
    checkSubjectHasCalculator(subjectId);
  }

  function handleTagChange(tag) {
    if (tag.selected) {
      setSelectedTags([...selectedTags, tag.tagId]);
    } else {
      const tags = selectedTags.filter((t) => t !== tag.tagId);
      setSelectedTags(tags);
    }
    if (assessment.assessmentId > 0) {
      setDataChanged(true);
    }
  }

  const getContentAreaSubjects = (
    results: any,
    isAdditionalSubject: boolean,
    contentAreaId: number
  ) => {
    var subjects: Array<ISelectElement> = results
      .filter(
        (r) =>
          r.isAdditionalSubject === isAdditionalSubject &&
          r.contentAreaId === contentAreaId
      )
      .map((values: any) => {
        return {
          label: values?.name,
          value: values?.subjectId,
        };
      });

    return arraySort(subjects, "label");
  };

  function getSubjects() {
    setShowLoading(true);
    getAssociatedSubjects(userId).then((response) => {
      setShowLoading(false);
      const results = response.data;
      const calculatorSubjectIds: Array<number> = results
        .filter((r) => calculatorContentArea.includes(r.contentAreaName))
        .map((r) => r.subjectId);

      setCalculatorSubjects(calculatorSubjectIds);

      const additionalSubjectRoles: Array<number> = [
        Constant.UserRoleId.SchoolAdmin,
        Constant.UserRoleId.SchoolTeacher,
        Constant.UserRoleId.FreeTeacher,
      ];

      const showAdditionalSubject: boolean = additionalSubjectRoles.some(
        (r) => r === roleId
      );

      const mySubjects = [
        {
          label: "My Subjects",
        },
        {
          label: "English",
          options: getContentAreaSubjects(
            results,
            false,
            Constant.ContentArea.English
          ),
        },
        {
          label: "Math",
          options: getContentAreaSubjects(
            results,
            false,
            Constant.ContentArea.Math
          ),
        },
        {
          label: "Science",
          options: getContentAreaSubjects(
            results,
            false,
            Constant.ContentArea.Science
          ),
        },
        {
          label: "Social Studies",
          options: getContentAreaSubjects(
            results,
            false,
            Constant.ContentArea.SocialStudies
          ),
        },
      ];

      const additionalSubjects = [
        {
          label: "Additional Subjects",
        },
        {
          label: "English",
          options: getContentAreaSubjects(
            results,
            true,
            Constant.ContentArea.English
          ),
        },
        {
          label: "Math",
          options: getContentAreaSubjects(
            results,
            true,
            Constant.ContentArea.Math
          ),
        },
        {
          label: "Science",
          options: getContentAreaSubjects(
            results,
            true,
            Constant.ContentArea.Science
          ),
        },
        {
          label: "Social Studies",
          options: getContentAreaSubjects(
            results,
            true,
            Constant.ContentArea.SocialStudies
          ),
        },
      ];

      let subjectOptions = mySubjects;

      if (showAdditionalSubject) {
        subjectOptions.push(...additionalSubjects);
      }

      setSubjects(subjectOptions);

      if (assessment.subjectId > 0) {
        const selectedSubject: ISelectElement = results
          .filter((r) => r.subjectId === assessment.subjectId)
          .map((values: any) => {
            return {
              label: values?.name,
              value: values?.subjectId,
            };
          })[0];

        setSelectedSubject(selectedSubject);
      }
    });
  }

  const fetchDistrictAdminSubjects = () => {
    setShowLoading(true);
    getAssociatedSubjects(userId).then((response) => {
      setShowLoading(false);
      const subjectsELA: Array<ISelectElement> = response.data
        .filter((item) => item.contentAreaId === 1)
        ?.map((values: any) => {
          return {
            label: values?.name,
            value: values?.subjectId,
          };
        });
      const subjectsMath: Array<ISelectElement> = response.data
        .filter((item) => item.contentAreaId === 2)
        ?.map((values: any) => {
          return {
            label: values?.name,
            value: values?.subjectId,
          };
        });
      const subjectsScience: Array<ISelectElement> = response.data
        .filter((item) => item.contentAreaId === 3)
        ?.map((values: any) => {
          return {
            label: values?.name,
            value: values?.subjectId,
          };
        });
      const subjectsSocial: Array<ISelectElement> = response.data
        .filter((item) => item.contentAreaId === 4)
        ?.map((values: any) => {
          return {
            label: values?.name,
            value: values?.subjectId,
          };
        });

      const calculatorSubjectIds: Array<number> = response.data
        .filter((r) => calculatorContentArea.includes(r.contentAreaName))
        .map((r) => r.subjectId);

      setCalculatorSubjects(calculatorSubjectIds);

      const options = [
        {
          label: "English",
          options: arraySort(subjectsELA, "label"),
        },
        {
          label: "Math",
          options: arraySort(subjectsMath, "label"),
        },
        {
          label: "Science",
          options: arraySort(subjectsScience, "label"),
        },
        {
          label: "Social Studies",
          options: arraySort(subjectsSocial, "label"),
        },
      ];
      setSubjects(options);

      let selectedSubjects = response.data.filter(
        (r) => r.subjectId.toString() === assessment.subjectId.toString()
      );

      if (selectedSubjects && selectedSubjects.length > 0) {
        setSelectedSubject({
          label: selectedSubjects[0].name,
          value: selectedSubjects[0].subjectId,
        });
      }
    });
  };

  function getTags() {
    setShowLoading(true);
    getAllTags(userId).then((response) => {
      setShowLoading(false);
      const tagsData = response.data.data;

      if (assessment.tagIds) {
        const existingTagIds: Array<number> = assessment.tagIds;
        const selectedTags = tagsData
          .filter((r) => existingTagIds.includes(parseInt(r.userTagId)))
          .map((t) => t.userTagId);
        setSelectedTags(selectedTags);
      }
    });
  }

  const handleAssessmentPartSelection = (part: AssessmentPartInfoType) => {
    if (part.isUpgradeRequired) {
      setShowUpgradePopup(true);
      return;
    }

    setAssessmentPart(part.type);
  };

  const handleResetComplete = () => {
    setResetGenerateQuestions(false); // Reset the flag
  };

  useEffect(() => {
    if (assessment?.questions && assessment.questions.length > 0) {
      setSelectedQuestions(assessment.questions);
      props.togglePreviewTab(assessment.questions.length);
    }

    if (assessment.testSizeTypeId) {
      setTestSizeTypeId(assessment.testSizeTypeId);
    }
    if (props.roleId === Constant.UserRoleId.District) {
      fetchDistrictAdminSubjects();
    } else {
      getSubjects();
    }
    if (assessment.subjectId > 0 && assessment.name) {
      setEnableQuestionSelectionTabs(true);
      setSelectedTab(tabs[0]);
      setAssessmentPart("NonPassageQuestions");
    }
    getTags();
    bindSubjectDetail(assessment.subjectId);
  }, []);

  useEffect(() => {
    if (assessment.subjectId) {
      checkSubjectHasCalculator(assessment.subjectId);
    }
  }, [calculatorSubjects]);

  return (
    <Fragment>
      {showLoading && <Loader></Loader>}
      {showUpgradeLimitPopup && (
        <UpGradeMessagePopup
          togglePopup={setShowUpgradeLimitPopup}
          roleId={props.roleId ?? 0}
          message={Constant.UPGRADE_MESSAGE_ASSESSMENT_LIMIT}
        />
      )}
      {showUpgradePopup && (
        <UpGradeMessagePopup
          togglePopup={setShowUpgradePopup}
          roleId={props.roleId ?? 0}
          message={Constant.UPGRADE_MESSAGE}
        />
      )}
      {isDataChanged && (
        <RouterPrompt
          key={"content"}
          when={isDataChanged}
          title="Leave this page?"
          saveText="Save Assessment"
          cancelText="Cancel"
          message="You are leaving the Assessment Builder without saving. Would you like to continue?"
          onSaveAndLeave={handleSaveAndPreview}
          onOK={() => false}
          onCancel={() => false}
        />
      )}
      <ConfirmationDialog
        open={showConfirmation}
        confirmationTitle={"Are you sure?"}
        confirmationMessage={
          "Updating the subject will remove all selected questions. Are you sure you want to change the subject?"
        }
        onCancelClick={confirmationCancelClose}
        onOkClick={confirmationOkClose}
      ></ConfirmationDialog>
      <ReactTooltip
        multiline={true}
        className="bg-secondary-teal w-1/2"
        backgroundColor="#008fbb"
      />
      <div className="px-4 sm:px-6  lg:px-8 py-6">
        <div className="relative">
          <InfoMessage message="Search and select questions using ‘I Will Select the Questions’ and/or ‘Question Quick Pick’. You will be able to preview your assessment, arrange the questions, and manage settings on the ‘Preview’ tab." />
          <div className="flex justify-between mt-4">
            {!allowEdit && (
              <div className="flex items-center bg-yellow-50 p-4 rounded-lg my-auto">
                <ExclamationIcon className="w-4 text-yellow-500 mr-2"></ExclamationIcon>
                <p className="text-gray-700">
                  Content edits are not available after students have started
                  the assessment.
                </p>
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-col xl:flex-row my-1 xl:space-x-8">
          <div className="w-full text-base textgray-700 mb-4 xl:mb-0">
            <div className="flex flex-col lg:flex-none lg:grid lg:grid-cols-12 gap-4 mb-4">
              <form className="lg:col-span-7 space-y-8 divide-y divide-gray-200">
                <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                  <div className="space-y-6 sm:space-y-5">
                    <div className="space-y-6 sm:space-y-5">
                      <div className="sm:grid sm:grid-cols-4 md:grid-cols-2 2xl:grid-cols-3 xxxl:grid-cols-4 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <div className="flex place-items-center gap-2 self-center">
                          <div className="ring-1 ring-secondary-teal ring-inset pointer-events-auto inline-flex items-baseline px-2 py-0 rounded-full text-xs font-medium bg-white text-base lg:mt-0">
                            1
                          </div>
                          <label
                            htmlFor="first-name"
                            className="block text-sm font-normal text-gray-500"
                          >
                            Assessment Name
                          </label>
                        </div>
                        <div className="mt-1 sm:mt-0">
                          <input
                            autoComplete="off"
                            type="text"
                            name="first-name"
                            id="first-name"
                            maxLength={60}
                            value={assessmentName}
                            onChange={(e) => handleAssignmentNameChange(e)}
                            className="max-w-sm w-full block  shadow-sm focus:ring-secondary-teal focus:border-secondary-teal sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                      </div>
                      <div className="sm:grid sm:grid-cols-4 md:grid-cols-2 2xl:grid-cols-3 xxxl:grid-cols-4 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <div className="flex place-items-center gap-2 self-center">
                          <div className="ring-1 ring-secondary-teal ring-inset pointer-events-auto inline-flex items-baseline px-2 py-0 rounded-full text-xs font-medium bg-white text-base lg:mt-0">
                            2
                          </div>
                          <label
                            htmlFor="first-name"
                            className="block text-sm font-normal text-gray-500"
                          >
                            Subject
                          </label>
                        </div>
                        <div className="max-w-sm w-full block w-full shadow-sm focus:ring-secondary-teal focus:border-secondary-teal sm:text-sm border-gray-300 rounded-md">
                          <Select
                            value={findSubjectByValue()}
                            formatGroupLabel={(data) => {
                              return (
                                <div
                                  className="bg-gray-200 -mx-3"
                                  style={Constant.SubjectDDLCSS.groupStyles}
                                >
                                  <span className="text-secondary-teal pl-2">
                                    {data.label}
                                  </span>

                                  <span
                                    style={Constant.SubjectDDLCSS.groupBadgeStyles()}
                                  >
                                    {data.options.length}
                                  </span>
                                </div>
                              );
                            }}
                            options={subjects}
                            isSearchable={true}
                            isDisabled={
                              (assessment.assessmentId > 0 && !allowEdit) ||
                              (assessment.assessmentId > 0 &&
                                assessment.questions &&
                                assessment.questions.length > 0)
                            }
                            onChange={handleSubjectChange}
                            className="w-full "
                            theme={(theme) => ({
                              ...theme,
                              colors: {
                                ...theme.colors,
                                primary: "#992883",
                              },
                            })}
                            styles={colourStyles}
                          />
                        </div>
                      </div>
                      <div className="sm:grid sm:grid-cols-4 md:grid-cols-2 2xl:grid-cols-3 xxxl:grid-cols-4 sm:gap-4 sm:items-start sm:border-t sm:border-b sm:border-gray-200 sm:pt-5 sm:pb-5">
                        <div data-tip="Assessment tags are great for organizing your assessments and running reports based on only the assessments grouped by that specific tag.">
                          <label
                            htmlFor="country"
                            className="block text-sm font-normal text-gray-500 sm:mt-px sm:pt-2 "
                          >
                            Add Tag(s)
                          </label>
                        </div>
                        <div className="mt-1 sm:mt-0">
                          <TagSelector
                            label={"Tags"}
                            userId={userId}
                            selectedTagIds={selectedTags}
                            showSelectedTags={true}
                            onTagSelectChange={handleTagChange}
                            onNewTagCreated={() => {}}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
              <div className="col-span-4 order-first lg:col-start-8 lg:col-span-5 row-span-2 row-end-3 flex flex-col gap-4">
                <div className="ml-auto justify-self-end">
                  <button
                    onClick={() => handleSaveAndPreview()}
                    className="my-auto bg-secondary-teal border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-dark-teal hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 whitespace-nowrap"
                  >
                    Save and Preview
                  </button>
                </div>
                <Details
                  questions={
                    selectedQuestions.length > 0 ? selectedQuestions : []
                  }
                />
              </div>
              <div className="lg:col-span-7 flex justify-between">
                <div className="flex place-items-center gap-2 self-center">
                  <div className="ring-1 ring-secondary-teal ring-inset pointer-events-auto inline-flex items-baseline px-2 py-0 rounded-full text-xs font-medium bg-white text-base lg:mt-0">
                    3
                  </div>
                  <div className="text-secondary-teal opacity-80 flex items-end font-medium">
                    Choose to select individual questions, a quick-pick by our
                    generator, or combine them using any of the search options
                    below
                  </div>
                </div>
              </div>
            </div>
            <div className="lg:px-2 pt-2 bg-gray-100 mt-2">
              <div className="flex px-4 sm:px-0 flex-col sm:flex-row items-end sm:justify-between sm:items-center">
                <nav className="flex space-x-2" aria-label="Tabs">
                  {tabs.map((tab) => (
                    <a
                      href={void 0}
                      key={tab}
                      onClick={() => handleTabChange(tab)}
                      className={classNames(
                        tab === selectedTab
                          ? "border-secondary-teal bg-white text-primary-violet hover:text-dark-violet py-3 tab-box-shadow tab-box-shadow"
                          : "text-primary-violet text-opacity-60 bg-primary-violet/10 py-[0.6rem] mt-auto transition-all hover:py-3 hover:text-opacity-100 hover:text-primary-violet border-[#dee9f1]",
                        "px-2 sm:px-4 text-center border-t-2 text-sm cursor-pointer font-medium",
                        `${
                          enableQuestionSelectionTabs
                            ? ""
                            : "pointer-events-none"
                        }`
                      )}
                    >
                      {tab}
                    </a>
                  ))}
                </nav>
              </div>
            </div>
            <div
              className="mx-auto grid-cols-12 lg:gap-3 xl:gap-8 mt-6"
              key={
                selectedSubject.value
              } /* refresh div when subject is changed*/
            >
              {selectedTab === "I Will Select the Questions" && (
                <div className="row-span-2 lg:row-span-3 xl:row-span-2 ml-2 m-5">
                  <nav aria-label="Progress">
                    <ol className="overflow-hidden flex gap-8">
                      {assessmentParts.map((part) => (
                        <li
                          className="relative"
                          onClick={() => handleAssessmentPartSelection(part)}
                        >
                          <a
                            href={void 0}
                            className={`relative flex items-center group ${
                              assessmentPart === part.type
                                ? " bg-secondary-teal border border-transparent"
                                : " border-2 border-secondary-teal"
                            } 
                            ${
                              part.isUpgradeRequired
                                ? " opacity-50 cursor-not-allowed"
                                : ""
                            }
                            min-h-[70px]`}
                            x-description="Complete Step"
                          >
                            <span
                              className={`px-4 min-w-0 flex flex-col ${
                                assessmentPart === part.type
                                  ? " text-white"
                                  : " text-secondary-teal"
                              }`}
                            >
                              <span className="text-xs font-semibold tracking-wide uppercase">
                                {part.name}
                              </span>
                            </span>
                          </a>
                        </li>
                      ))}
                    </ol>
                  </nav>
                </div>
              )}
              {assessmentPart === "NonPassageQuestions" && (
                <NonPassageQuestions
                  userId={userId}
                  roleId={roleId}
                  districtId={districtId}
                  hasDistrictAssessmentQuestionIcon={hasDistrictAssessmentQuestionIcon}
                  subjectId={parseInt(selectedSubject.value)}
                  selectedQuestions={selectedQuestions.filter(
                    (r) =>
                      r.assessmentQuestionTypeId ===
                      Constant.AssessmentQuestionType.NonPassageQuestions
                  )}
                  updateQuestion={handleSelectedQuestions}
                  updateQuestions={setSelectedQuestions}
                  allQuestions={selectedQuestions}
                  allowEdit={allowEdit}
                  enableQuestionSelectionTabs={enableQuestionSelectionTabs}
                />
              )}
              {assessmentPart === "PassageQuestions" && (
                <PassageQuestion
                  userId={userId}
                  districtId={districtId}
                  hasDistrictAssessmentQuestionIcon={hasDistrictAssessmentQuestionIcon}
                  subjectId={parseInt(selectedSubject.value)}
                  domainList={[]}
                  selectedQuestions={selectedQuestions.filter(
                    (r) =>
                      r.assessmentQuestionTypeId ===
                      Constant.AssessmentQuestionType.PassageQuestions
                  )}
                  updateQuestion={handleSelectedQuestions}
                  allQuestions={selectedQuestions}
                  allowEdit={allowEdit}
                />
              )}
              {assessmentPart === "AuthoredQuestions" && (
                <AuthoredQuestions
                  key={1}
                  userId={userId}
                  districtId={districtId}
                  hasDistrictAssessmentQuestionIcon={hasDistrictAssessmentQuestionIcon}
                  subjectId={parseInt(selectedSubject.value)}
                  selectedQuestions={selectedQuestions.filter(
                    (r) =>
                      r.assessmentQuestionTypeId ===
                      Constant.AssessmentQuestionType.AuthoredQuestions
                  )}
                  updateQuestion={handleSelectedQuestions}
                  updateSelectedQuestions={() => undefined}
                  allowEdit={allowEdit}
                />
              )}
              {assessmentPart === "SystemGenerated" && (
                <SystemGeneratedQuestions
                  subjectId={parseInt(selectedSubject.value)}
                  subjectName={assessment.subjectName}
                  testSizeTypeId={testSizeTypeId}
                  subjectDetail={subjectDetail}
                  updateQuestions={handleSystemGeneratedQuestions}
                  allQuestions={selectedQuestions}
                  allowEdit={allowEdit}
                  resetQuestions={resetGenerateQuestions}
                  onResetComplete={handleResetComplete}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
});

export default Content;

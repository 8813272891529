import { connect } from "react-redux";
import auth from "../../../utils/auth";
import LayoutContainer from "../layoutContainer";

function Home(props: any) {
  props.userContext.userId === 0 && auth.logout();

  return <LayoutContainer></LayoutContainer>;
}

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(Home);

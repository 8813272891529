import { AxiosHelper } from "../../environment/axiosConfig";
import APIConstant from "../../utils/constant/apiConstant";
import IStudentSetting from "../../model/teacher/studentSetting";
import PagingResponse from "../../model/common/pagingResponse";
import { IResult } from "../../model/interface/IResult";

export const getStudentSettings = (
  classId: number,
  pageSize: number,
  pageNumber: number
) => {
  return AxiosHelper.axiosInstance().get<
    IResult<PagingResponse<IStudentSetting>>
  >(APIConstant.CLASSMANAGEMENT_API.GetStudentSettings, {
    params: {
      classId: classId,
      pageSize: pageSize,
      pageNumber: pageNumber,
    },
  });
};
export const updateStudentLanguageSetting = (
  languageSettingId: number,
  contentAreaId: number,
  studentProfileIds: number[],
  classId: number
) => {
  return AxiosHelper.axiosInstance().put<IResult<number>>(
    APIConstant.CLASSMANAGEMENT_API.UpdateStudentLanguageSetting,
    {
      languageSettingId: languageSettingId,
      contentAreaId: contentAreaId,
      studentProfileIds: studentProfileIds,
      classId: classId,
    }
  );
};
export const updateStudentTTSSetting = (
  isTTSActive: boolean,
  contentAreaId: number,
  studentProfileIds: number[],
  classId: number
) => {
  return AxiosHelper.axiosInstance().put<IResult<number>>(
    APIConstant.CLASSMANAGEMENT_API.UpdateStudentTTSSetting,
    {
      isTTSActive: isTTSActive,
      contentAreaId: contentAreaId,
      studentProfileIds: studentProfileIds,
      classId: classId,
    }
  );
};

export const updateStudentKTo5SpanishSetting = (
  isKTo5SpanishActive: boolean,
  studentProfileIds: number[],
  classId: number
) => {
  return AxiosHelper.axiosInstance().put<IResult<number>>(
    APIConstant.CLASSMANAGEMENT_API.UpdateStudentKTo5SpanishSetting,
    {
      isKTo5SpanishActive: isKTo5SpanishActive,
      studentProfileIds: studentProfileIds,
      classId: classId,
    }
  );
};

export const updateStudentTTSSettingByClassId = (
  isTTSActive: boolean,
  contentAreaId: number,
  classId: number
) => {
  return AxiosHelper.axiosInstance().put<IResult<number>>(
    APIConstant.CLASSMANAGEMENT_API.UpdateStudentTTSSettingByClassId,
    {
      isTTSActive: isTTSActive,
      contentAreaId: contentAreaId,
      classId: classId,
    }
  );
};

export const updateStudentLanguageSettingByClassId = (
  languageSettingId: number,
  contentAreaId: number,
  classId: number
) => {
  return AxiosHelper.axiosInstance().put<IResult<number>>(
    APIConstant.CLASSMANAGEMENT_API.UpdateStudentLanguageSettingByClassId,
    {
      languageSettingId: languageSettingId,
      contentAreaId: contentAreaId,
      classId: classId,
    }
  );
};

export const updateStudentKTo5SpanishSettingByClassId = (
  isKTo5SpanishActive: boolean,
  classId: number
) => {
  return AxiosHelper.axiosInstance().put<IResult<number>>(
    APIConstant.CLASSMANAGEMENT_API.UpdateStudentKTo5SpanishSettingByClassId,
    {
      isKTo5SpanishActive: isKTo5SpanishActive,
      classId: classId,
    }
  );
};

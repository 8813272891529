import classNames from "classnames";
import { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { getStudentCannedTestSubject } from "../../../../../api/student/studentSubjectApi";
import { ICannedTestSubject } from "../../../../../model/interface/cannedTestSubject";
import constant from "../../../../../utils/constant/constant";
import CannedTestList from "../cannedTests/testList";
import RouteConstant from "../../../../../utils/constant/routeConstant";
import Breadcrumb from "../../middleSchool/breadcrumb";
import { IBreadcrumb } from "../../../../../model/common/breadcrumb";
import { useTranslation } from "react-i18next";

function CannedTestSubject(props) {
  const [subjects, setSubjects] = useState<Array<ICannedTestSubject>>([]);
  const [selectedSubject, setSelectedSubject] = useState<ICannedTestSubject>();
  const { t } = useTranslation();

  useEffect(() => {
    loadSubjects();
  }, []);

  function loadSubjects() {
    getStudentCannedTestSubject(props.userContext?.userId ?? 0, 0).then((d) => {
      const subjects = d.data.filter((s) => s.isPLPreTestActive === true);
      setSubjects(subjects);
      if (selectedSubject === undefined && subjects.length > 0) {
        setSelectedSubject(subjects[0]);
      }
    });
  }

  const breadcrumbItems = () => {
    const items: Array<IBreadcrumb> = [
      {
        name: t("Breadcrumb.Home"),
        url: RouteConstant.MiddleSchool.assignments,
        textColor: "text-white",
      },
      {
        name: t("Breadcrumb.DiagnosticsSubjects"),
        textColor: "text-white",
        url: "",
      },
    ];

    return items;
  };
  return (
    <Fragment>
      <>
        <div className="md:flex md:items-center px-4 py-3 lg:px-8 border-b">
          <Breadcrumb items={breadcrumbItems()} />
          <div className="md:w-[55%] text-lg flex justify-between gap-2 h-auto sm:h-10 items-center">
            <h1 className="text-gray-50 font-medium flex justify-start">
              {t("StudyType.Diagnostics")}
            </h1>
          </div>
        </div>
        <div className="px-4 py-4 lg:px-8 xl:px-4 1xl:px-8">
          <div className="grid grid-cols-1 xl:grid-cols-10 gap-0 xl:gap-3 2xl:gap-6 mt-4">
            <div className="col-span-4 xl:block md:gap-4 xl:gap-0 space-y-4 xl:space-y-6 md:mb-4 xl:mb-0">
              <div className="grid grid-cols-1 sm:grid-cols-2 bg-white bg-opacity-20 p-3 rounded-md gap-4 shadow-xl">
                {subjects.map((s) => {
                  return (
                    <div
                      key={s.subjectId}
                      className={classNames(
                        "group bg-white w-full px-2 py-2.5 border-[3px] rounded-md transition ease-in-out duration-300",
                        selectedSubject?.subjectId === s.subjectId
                          ? "bg-white border-primary-violet text-primary-violet"
                          : " text-gray-500"
                      )}
                      onClick={() => {
                        setSelectedSubject(s);
                      }}
                    >
                      <button
                        className="flex items-center w-full"
                        onClick={() => {
                          setSelectedSubject(s);
                        }}
                      >
                        <img
                          className="w-6"
                          srcSet={s.subjectIcon}
                          alt="Subject Icon"
                        />
                        <span className="pl-2 text-left group-hover:text-primary-violet">
                          {props.profile?.plLanguageId ===
                          constant.Languages.Spanish
                            ? s.spanishName ?? s.name
                            : s.name}
                        </span>
                      </button>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="col-span-6 h-full mt-8 md:mt-4 xl:mt-0 ">
              <div>
                {selectedSubject && (
                  <CannedTestList
                    subjectId={selectedSubject?.subjectId ?? 0}
                    userId={props.profile?.userId ? props.profile?.userId : 0}
                    languageId={props.profile?.plLanguageId ?? 1}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    </Fragment>
  );
}
const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(CannedTestSubject);

import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import MyStatDetail from "../../../../../model/student/shared/myStats/myStatDetail";
import Breadcrumb from "../../middleSchool/breadcrumb";
import { IBreadcrumb } from "../../../../../model/common/breadcrumb";
import RouteConstant from "../../../../../utils/constant/routeConstant";
import MyStatSummary from "../../../../../model/student/shared/myStats/myStatSummary";
import { ISubject } from "../../../../../model/interface/subject";
import {
  getMyStatsBySubject,
  getMyStatSummary,
} from "../../../../../api/student/myStatsApi";
import { getStudentSubject } from "../../../../../api/student/studentSubjectApi";
import { connect } from "react-redux";
import { fetchProfile } from "../../../../../redux/actions/userActions";
import * as userContextAction from "../../../../../redux/actions/userContextAction";
import { BookIcon } from "../../../../../assets/icons";
import constant from "../../../../../utils/constant/constant";
import { Configuration } from "../../../../../environment/setup";

function MyStatMiddleSchool(props: any) {
  const { t } = useTranslation();
  const [myStatSummary, setMyStatSummary] = useState<MyStatSummary>();
  const [myStatDetails, setMyStatDetails] = useState<Array<MyStatDetail>>([]);
  const [subjects, setSubjects] = useState<Array<ISubject>>([]);
  const [selectedSubject, setSelectedSubject] = useState<ISubject>();
  const programMode =
    localStorage.getItem("programMode") ?? constant.ProgramMode.EG;

  const breadcrumbItems = () => {
    const items: Array<IBreadcrumb> = [
      {
        name: t("Breadcrumb.Home"),
        url: RouteConstant.MiddleSchool.assignments,
        textColor: "text-white",
      },
      {
        name: t("Breadcrumb.MyStats"),
        textColor: "text-white",
        url: "",
      },
    ];

    return items;
  };

  const onSubjectSelect = (subject: ISubject) => {
    getMyStatsBySubject(props.userContext.userId, subject.subjectId).then(
      (response) => {
        setMyStatDetails(response.data);
      }
    );
    setSelectedSubject(subject);
  };

  useEffect(() => {
    getMyStatSummary(
      props.userContext.userId ?? 0,
      programMode === constant.ProgramMode.LO ? 2 : 1
    ).then((response) => {
      setMyStatSummary(response.data);
    });
    if (programMode === constant.ProgramMode.EG) {
      getStudentSubject(props.userContext.userId ?? 0).then((response) => {
        setSubjects(response.data);
        onSubjectSelect(response.data[0]);
      });
    }
  }, []);

  return (
    <Fragment>
      {/* main section */}
      <>
        <div className="px-4 py-3 lg:px-8 border-b">
          <nav className="flex mt-2" aria-label="Breadcrumb">
            <Breadcrumb items={breadcrumbItems()} />
          </nav>
          <div className="text-lg flex justify-between gap-2 h-auto sm:h-10 items-center">
            <h1 className="text-gray-50 font-medium flex justify-start">
              {t("Shared.StudentSideBar.MyStats")}
            </h1>
          </div>
        </div>
        <div className="px-4 py-4 lg:px-8">
          <section>
            <div>
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 bg-gray-100 px-6 py-4 rounded-md border mt-4">
                <div className="border-0 lg:border-r border-gray-300">
                  <h3 className="text-xl mb-2">{t("MyStats.Score")}</h3>
                  <div className="grid grid-cols-3 bg-white rounded-md mr-4 py-3 shadow-sm">
                    <div className="col-span-1 flex flex-col font-light text-base text-center">
                      <h4 className="leading-4 xl:leading-none mb-1">
                        {t("MyStats.QuestionsAnswered")}
                      </h4>
                      <span className="flex items-end justify-center flex-grow font-semibold">
                        {myStatSummary?.questionsAnswered}
                      </span>
                    </div>
                    <div className="col-span-1 flex flex-col  font-light text-base text-center">
                      <h4 className="leading-4 xl:leading-none mb-1">
                        {t("MyStats.QuestionsCorrect")}
                      </h4>
                      <span className="flex items-end justify-center flex-grow font-semibold">
                        {myStatSummary?.questionsCorrect}
                      </span>
                    </div>
                    <div className="col-span-1 flex flex-col font-light text-base text-center">
                      <h4 className="leading-4 xl:leading-none mb-1">
                        {t("MyStats.OverallScore")}
                      </h4>
                      <span className="flex items-end justify-center flex-grow font-semibold">
                        {myStatSummary?.overallScore}%
                      </span>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col">
                  <h3 className="text-xl mb-2">{t("MyStats.Time")}</h3>
                  <div className="grid grid-cols-4 bg-white rounded-md mr-4 py-3 shadow-sm flex-grow ">
                    <div className="col-span-1 flex flex-col font-light text-base text-center">
                      <h4 className="leading-4 xl:leading-none mb-1">
                        {t("MyStats.Days")}
                      </h4>
                      <span className="flex items-end justify-center flex-grow font-semibold">
                        {myStatSummary?.days}
                      </span>
                    </div>
                    <div className="col-span-1 flex flex-col font-light text-base text-center">
                      <h4 className="leading-4 xl:leading-none mb-1">
                        {t("MyStats.Hours")}
                      </h4>
                      <span className="flex items-end justify-center flex-grow font-semibold">
                        {myStatSummary?.hours}
                      </span>
                    </div>
                    <div className="col-span-1 flex flex-col font-light text-base text-center">
                      <h4 className="leading-4 xl:leading-none mb-1">
                        {t("MyStats.Minutes")}
                      </h4>
                      <span className="flex items-end justify-center flex-grow font-semibold">
                        {myStatSummary?.minutes}
                      </span>
                    </div>
                    <div className="col-span-1 flex flex-col font-light text-base text-center">
                      <h4 className="leading-4 xl:leading-none mb-1">
                        {t("MyStats.Seconds")}
                      </h4>
                      <span className="flex items-end justify-center flex-grow font-semibold">
                        {myStatSummary?.seconds}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {programMode === constant.ProgramMode.EG && (
            <section className="mt-10 mb-10 xl:mb-0">
              <div className="grid grid-cols-1 xl:grid-cols-10 gap-6">
                <div className="xl:col-span-2 space-y-6 px-1">
                  {subjects &&
                    subjects.map((subject) => {
                      return (
                        <div
                          className={`${
                            subject.subjectId === selectedSubject?.subjectId
                              ? "bg-[#00b1fb] hover:opacity-80"
                              : "bg-blues hover:bg-blues hover:bg-opacity-80 transition-all duration-300 "
                          } block p-4 rounded-md shadow-xl`}
                        >
                          <div
                            className="cursor-pointer"
                            onClick={() => onSubjectSelect(subject)}
                          >
                            <button>
                              <h2
                                className={`${
                                  subject.subjectId ===
                                  selectedSubject?.subjectId
                                    ? "text-gray-900"
                                    : "text-white"
                                } text-base text-left flex items-start`}
                              >
                                {subject.subjectIcon &&
                                  subject.subjectIcon !== "null" && (
                                    <img
                                      className={`${
                                        subject.subjectId ===
                                        selectedSubject?.subjectId
                                          ? ""
                                          : "filter invert"
                                      } h-4 mt-1 mr-2 flex-shrink-0`}
                                      srcSet={subject.subjectIcon}
                                      alt="Subject Icon"
                                    />
                                  )}
                                {subject.subjectIcon === "null" && (
                                  <>
                                    <BookIcon className="h-4 mr-2 flex-shrink-0" />
                                  </>
                                )}
                                {subject.name}
                              </h2>
                            </button>
                          </div>
                        </div>
                      );
                    })}
                </div>
                <div className="xl:col-span-8 h-full">
                  <div className="h-full bg-[#00b1fb] rounded-md relative mt-5 px-2 sm:px-6 ">
                    <span className="py-2 px-5 -top-5 left-6 relative border border-gray-200 inline-flex items-center rounded-md text-sm  text-gray-900 bg-white shadow-xl transition ease-in-out duration-150">
                      {selectedSubject?.subjectIcon &&
                        selectedSubject.subjectIcon !== "null" && (
                          <img
                            className="h-4 mr-2 flex-shrink-0 m-auto"
                            src={selectedSubject.subjectIcon}
                            alt=""
                          />
                        )}
                      {selectedSubject?.subjectIcon === "null" && (
                        <>
                          <BookIcon className="h-4 mr-2 flex-shrink-0 m-auto" />
                        </>
                      )}
                      {selectedSubject?.name}
                    </span>
                    {myStatDetails.map((myStatDetail, index) => {
                      return (
                        <div className=" w-full mb-6" key={index}>
                          <h3 className="bg-gray-700 rounded-t-md px-6 py-3 capitalize text-white text-lg  font-medium">
                            {myStatDetail.domainName}
                          </h3>

                          <div className="rounded-b-md overflow-auto">
                            <table className="table min-w-full text-sm bg-white divide-y divide-gray-200">
                              <thead className="bg-gray-200 uppercase">
                                <tr className="bg-white px-2 space-x-4">
                                  {/* <th className="bg-gray-200 px-2 2xl:px-6 py-3 text-left text-sm font-semibold text-dark-teal uppercase tracking-wider">
                                  {t("MyStats.Standard")}
                                </th> */}
                                  <th className="top-0 bg-gray-100 px-2 2xl:px-6 py-3 text-left text-sm font-semibold text-dark-teal uppercase tracking-wider">
                                    {t("MyStats.Name")}
                                  </th>
                                  <th className="top-0 bg-gray-200 px-2 2xl:px-6 py-3 text-center text-sm font-semibold text-dark-teal uppercase tracking-wider">
                                    {t("MyStats.TotalAnswered")}
                                  </th>
                                  <th className="top-0 bg-gray-100 px-2 2xl:px-6 py-3 text-center text-sm font-semibold text-dark-teal uppercase tracking-wider">
                                    {t("MyStats.TotalCorrect")}
                                  </th>
                                  <th className="top-0 bg-gray-200 px-2 2xl:px-6 py-3 text-center text-sm font-semibold text-dark-teal uppercase tracking-wider">
                                    {t("MyStats.OverallScore")}
                                  </th>
                                  <th className="top-0 bg-gray-100 px-2 2xl:px-6 py-3 text-center text-sm font-semibold text-dark-teal uppercase tracking-wider">
                                    {t("MyStats.LastSessionScore")}
                                  </th>
                                  <th className="top-0 bg-gray-200 px-2 2xl:px-6 py-3 text-center text-sm font-semibold text-dark-teal uppercase tracking-wider">
                                    {t("MyStats.GalaxyStar")}
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {myStatDetail.standards.map(
                                  (standard, index) => {
                                    return (
                                      <tr key={index} className="border-b-2 ">
                                        {/* <td className="px-6 py-4 bg-gray-100">
                                      {standard.standard}
                                    </td> */}
                                        <td className="px-6 py-4 border-r-2 border-gray-100">
                                          {standard.standardName}
                                        </td>
                                        <td className="px-6 py-4 border-r-2 text-center border-gray-100">
                                          {standard.totalQuestions}
                                        </td>
                                        <td className="px-6 py-4 border-r-2 text-center border-gray-100">
                                          {standard.totalCorrect}
                                        </td>
                                        <td className="px-6 py-4 border-r-2 text-center border-gray-100">
                                          {standard.overallScore} %
                                        </td>
                                        <td className="px-6 py-4 border-r-2 text-center border-gray-100">
                                          {standard.lastSessionScore}%
                                        </td>
                                        <td className="px-6 py-4 border-r-2 border-gray-100">
                                          <img
                                            title="Galaxy star"
                                            className="h-6 filter scale-75 hover:scale-100 mx-auto transition ease-in-out duration-500"
                                            src={`${Configuration.S3bucketImagePath}/images/h-star-icon1.svg`}
                                            alt="Galaxy star"
                                          />
                                        </td>
                                      </tr>
                                    );
                                  }
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      );
                    })}
                    {myStatDetails.length === 0 && (
                      <div className="flex px-8 py-4">
                        <span className="text-sm">No records found</span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </section>
          )}
        </div>
      </>
    </Fragment>
  );
}

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    setUserContext: (payload: userContextAction.UserContextState) => {
      dispatch(userContextAction.setUserContext(payload));
    },
    setProfile: (
      userId: number,
      roleId: number,
      schoolId: number,
      districtId: number
    ) => {
      dispatch(fetchProfile(userId, roleId, schoolId, districtId));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(MyStatMiddleSchool);

export default class UserProfile {
  constructor(
    public profile: string,
    public userId: number,
    public firstName: string = "",
    public lastName: string = "",
    public email: string = "",
    public passwordHash: string = "",
    public pricipalName: string = "",
    public pricipalEmail: string = "",
    public schoolId: number = 0,
    public schoolAccountId: number = 0,
    public schoolStartTime: string = "",
    public schoolEndTime: string = "",
    public ProfileImage: string = "",
    public ProfileImageSourceType: number = 0,
    public IsUpdateProfileImageOnly: boolean = false
  ) {}
}

import { useState } from 'react'

interface TagInputProps {
  tags: Array<string>,
  setTags: (tags: Array<string>) => void,
  matchAny?: boolean,
  setMatchAny?: (any: boolean) => void,
  instructions: string,
  placeholder: string,
  tagLengthLimit: number,
}

function TagsInput(props: TagInputProps) {
  const {
    tags,
    setTags,
    matchAny,
    setMatchAny,
    instructions,
    placeholder,
    tagLengthLimit,
  } = props;

  const [inputText, setInputText] = useState<string>("");
  const [isInputFocused, setIsInputFocused] = useState<boolean>(false);

  const handleKeywordSearchKeyPress = (e: any) => {
    if (e.key === 'Enter' && inputText.trim()) {
      if (!tags.includes(inputText.trim()))  
        setTags([...tags, inputText.trim()]);
      setInputText("");
    }
  }

  const handleTagRemove = (tagToRemove) => {
    const newTags = tags.filter(tag => tag !== tagToRemove)
    setTags(newTags);
  }

  const handleFocus = () => {
    setIsInputFocused(true);
  }

  const handleLostFocus = () => {
    setIsInputFocused(false);
    if (inputText.trim() && !tags.includes(inputText.trim())) {
      setTags([...tags, inputText.trim()]);
      setInputText("");
    }
  }

  return (
    <div>
      <div className="flex">
        <div className={
            "flex flex-wrap max-w-sm w-full block shadow-sm border text-base leading-normal rounded-md m-0 pt-2 px-2 bg-white color-gray-500 align-middle " 
            + (isInputFocused ? "ring-secondary-teal ring-1 border-secondary-teal" : "border-gray-300")}>
          {tags.map((tag, index) => (
            <div key={index} 
              className="flex-none max-w-[100%] text-wrap inline-block bg-gray-200 pt-0 px-2 pb-0 rounded-sm text-black mb-2 text-sm mr-1"
            >
              {tag}
              <button className="ml-2" onClick={() => handleTagRemove(tag)}>&times;</button>
            </div>
          ))}
          <input 
            type="text" 
            value={inputText}
            onChange={(e) => setInputText(e.target.value)}
            onKeyPress={handleKeywordSearchKeyPress}
            onFocus={handleFocus}
            onBlur={handleLostFocus}
            className="mb-2 border-transparent border-0 flex-auto outline-0 bg-transparent focus:border-0 py-0 px-1 m-0 w-1/4 focus:ring-transparent sm:text-sm" 
            placeholder={!tags.length ? placeholder : ""}
            maxLength={tagLengthLimit}
          />
        </div>
        {matchAny !== undefined &&  (<div className="flex flex-col ml-2">
          <div className="inline-flex items-center">
            <input 
              id="any"
              type="radio" 
              className="h-4 w-4 cursor-pointer text-secondary-teal border-gray-300 focus:ring-secondary-teal" 
              checked={matchAny} 
              onChange={() => setMatchAny && setMatchAny(true)} 
            />
            <label htmlFor="any" className="mx-1 text-[75%]">any</label>
          </div>
          <div className="inline-flex items-center">
            <input 
              id="all"
              type="radio" 
              className="h-4 w-4 cursor-pointer text-secondary-teal border-gray-300 focus:ring-secondary-teal" 
              checked={!matchAny} 
              onChange={() => setMatchAny && setMatchAny(false)} 
            />
            <label htmlFor="all" className="mx-1 text-[75%]">all</label>
          </div>
        </div>)}
      </div>
      <div className="text-[60%] text-gray-500">{instructions}</div>
    </div>
  )
}

export default TagsInput

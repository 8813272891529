import { XIcon } from "@heroicons/react/solid";
import { Fragment, useState } from "react";
import InfoMessage from "../../../../../shared/infoMessage";
import DatePicker from "react-datepicker";
import moment from "moment";
import { IStudent } from "../../../../../../model/interface/student";
import { AssignedIcon } from "../../../../../../assets/icons";
import arraySort from "array-sort";
import MessagePopup from "../../../../../shared/messagePopup";
interface CreateRemediationProps {
  togglePopup: (popupAction: boolean) => void;
  students: Array<IStudent>;
  assignRemediation: (
    students: Array<IStudent>,
    numberOfWeakAreas: number,
    startDate: Date,
    endDate: Date
  ) => void;
}

function AssignmentRemediation(props: CreateRemediationProps) {
  const { togglePopup, students, assignRemediation } = props;
  const [selectedStartDate, setStartDate] = useState<Date>();
  const [selectedDueDate, setDueDate] = useState<Date>();
  const [startDateCorrect, setStartDateCorrect] = useState<boolean>(true);
  const [dueDateCorrect, setDueDateCorrect] = useState<boolean>(true);
  const [selectedStudents, setSelectedStudents] = useState<Array<IStudent>>([]);
  const [toggleSelectAll, setToggleSelectAll] = useState<boolean>(false);
  const [hideAssigned, setHideAssigned] = useState<boolean>(false);
  const [numberOfWeakArea, setNumberOfWeakArea] = useState<number>(1);
  const [errorMessagePopup, setErrorMessagePopup] = useState<boolean>(false);

  function handleAssignClick() {
    if (!selectedStartDate || !selectedDueDate) {
      return false;
    }

    if (selectedStartDate > selectedDueDate) {
      return false;
    }

    assignRemediation(
      selectedStudents,
      numberOfWeakArea,
      selectedStartDate ?? new Date(),
      selectedDueDate ?? new Date()
    );
  }

  function handleStartDate(date: any) {
    if (selectedDueDate && date > selectedDueDate) {
      setStartDateCorrect(false);
      setErrorMessagePopup(true);
    } else {
      setStartDateCorrect(true);
      setDueDateCorrect(true);
    }

    setStartDate(date);
  }

  function handleDueDate(date: any) {
    if (selectedStartDate && date < selectedStartDate) {
      setDueDateCorrect(false);
      setErrorMessagePopup(true);
    } else {
      setStartDateCorrect(true);
      setDueDateCorrect(true);
    }

    setDueDate(date);
  }

  function handleSelectAll() {
    const updatedValue: boolean = !toggleSelectAll;

    let updatedStudents: Array<IStudent> = [];
    if (updatedValue) {
      updatedStudents = students.filter((r) => !r.isAssigned);
    }

    setSelectedStudents(updatedStudents);
    setToggleSelectAll(updatedValue);
  }

  function handleSelectStudent(student: IStudent) {
    const studentExist = selectedStudents.some(
      (r) => r.studentId === student.studentId
    );

    let updatedStudents: Array<IStudent> = [];
    if (studentExist) {
      updatedStudents = selectedStudents.filter(
        (r) => r.studentId !== student.studentId
      );
    } else {
      updatedStudents = [...selectedStudents, student];
    }

    setSelectedStudents(updatedStudents);
  }

  return (
    <Fragment>
      {errorMessagePopup && (
        <MessagePopup
          message={"Invalid Date"}
          togglePopup={setErrorMessagePopup}
        />
      )}

      <div className="fixed inset-0 z-40 flex items-center bg-black bg-opacity-50 sm:items-center sm:justify-center">
        <div
          className="w-1/2 flex flex-col justify-between h-auto max-h-80percent bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:max-w-6xl"
          role="dialog"
        >
          <header>
            {/* Header */}
            <div className="px-4 py-4 bg-gray-50 rounded-t-lg">
              <div className="flex items-start justify-between space-x-3">
                <div className="space-y-1">
                  <div className="text-lg font-medium text-gray-900">
                    Assign Additional Practice
                  </div>
                </div>
                <div className="h-7 flex items-center">
                  <button
                    type="button"
                    className="text-gray-400 hover:text-gray-500"
                    onClick={() => togglePopup(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
          </header>
          <div
            id="remeditionpopup"
            className="w-full overflow-auto relative px-4 py-1"
          >
            <InfoMessage
              message={
                "Select up to 3 areas of weakness to generate targeted remediation specific to the selected students for standards where they scored less than 80%. Any students that have scored 80% or higher for all standards are ineligible for remediation. You can view these assignments in ‘My Assignments’ from your Assessment/Assignment Center screen under the Remediation Assignments menu option."
              }
            />

            <div className="mb-2 mt-0 flex items-center gap-4 mt-7">
              <label
                x-radio-group-option=""
                className=" relative flex items-center cursor-pointer focus:outline-none border-gray-400"
              >
                <input
                  type="radio"
                  x-model="value"
                  name="weakareas"
                  defaultValue="Public access"
                  className="h-4 w-4 cursor-pointer text-secondary-teal border-gray-300 focus:ring-secondary-teal"
                  aria-labelledby="privacy-setting-0-label"
                  aria-describedby="privacy-setting-0-description"
                  checked={numberOfWeakArea === 1}
                  onClick={() => setNumberOfWeakArea(1)}
                />
                <div className="ml-3 flex flex-col">
                  <span
                    id="privacy-setting-0-label"
                    className="block text-sm text-gray-500"
                  >
                    One Weak Area
                  </span>
                </div>
              </label>
              <label
                x-radio-group-option=""
                className=" relative flex items-center cursor-pointer focus:outline-none border-gray-400"
              >
                <input
                  type="radio"
                  x-model="value"
                  name="weakareas"
                  value="Public access"
                  className="h-4 w-4 cursor-pointer text-secondary-teal border-gray-300 focus:ring-secondary-teal"
                  aria-labelledby="privacy-setting-0-label"
                  aria-describedby="privacy-setting-0-description"
                  checked={numberOfWeakArea === 2}
                  onClick={() => setNumberOfWeakArea(2)}
                />
                <div className="ml-3 flex flex-col">
                  <span
                    id="privacy-setting-0-label"
                    className="block text-sm text-gray-500"
                  >
                    Two Weak Areas
                  </span>
                </div>
              </label>
              <label
                x-radio-group-option=""
                className=" relative flex items-center cursor-pointer focus:outline-none border-gray-400"
              >
                <input
                  type="radio"
                  x-model="value"
                  name="weakareas"
                  value="Public access"
                  className="h-4 w-4 cursor-pointer text-secondary-teal border-gray-300 focus:ring-secondary-teal"
                  aria-labelledby="privacy-setting-0-label"
                  aria-describedby="privacy-setting-0-description"
                  checked={numberOfWeakArea === 3}
                  onClick={() => setNumberOfWeakArea(3)}
                />
                <div
                  className="ml-3 flex flex-col"
                  data-tip={
                    "An orphaned assessment is a shared assessment created by a user that has been deleted. Deleting here will remove the assessment permanently."
                  }
                >
                  <span
                    id="privacy-setting-0-label"
                    className="block text-sm text-gray-500"
                  >
                    Three Weak Areas
                  </span>
                </div>
              </label>
            </div>

            <div className="flex gap-8 text-base font-medium text-gray-700 mb-2 mt-6">
              Assignment Dates
            </div>
            <div className="flex gap-8">
              <div>
                <label className="block text-sm text-gray-500">
                  Start Date
                </label>
                <DatePicker
                  className={`react-datepicker__input-container mt-1 py-1.5 px-3 block w-full shadow-sm focus:ring-primary-violet focus:border-primary-violet border border-${
                    startDateCorrect ? "gray-300" : "red-500"
                  } rounded-md`}
                  selected={selectedStartDate}
                  onChange={(date) => handleStartDate(date)}
                  minDate={moment().toDate()}
                  popperProps={{
                    positionFixed: true, // use this to make the popper position: fixed
                  }}
                />
              </div>
              <div className="relative">
                <label className="block text-sm text-gray-500">Due Date</label>
                <DatePicker
                  className={`react-datepicker__input-container mt-1 py-1.5 px-3 block w-full shadow-sm focus:ring-primary-violet focus:border-primary-violet border border-${
                    dueDateCorrect ? "gray-300" : "red-500"
                  } rounded-md`}
                  selected={selectedDueDate}
                  onChange={(date) => handleDueDate(date)}
                  minDate={moment().toDate()}
                  popperProps={{
                    positionFixed: true, // use this to make the popper position: fixed
                  }}
                />
              </div>
            </div>
            <div className="flex grid grid-cols-2 text-base font-medium text-gray-700 mt-4">
              Students
              <div className="flex xl:justify-end mt-1">
                <AssignedIcon className="w-4 h-4 mr-2 fill-current text-red-500 stroke-current stroke-2" />
                <span className="text-xs ml-2 text-gray-500">
                  Student already assigned remediation
                </span>
              </div>
            </div>
            <div className="gap-8 mt-2 border rounded-md p-2 max-h-[10rem] overflow-y-auto">
              <div className="flex text-sm font-medium text-gray-700 mb-2 bg-gray-100 py-1 px-2">
                <div>
                  <input
                    type="checkbox"
                    onChange={() => handleSelectAll()}
                    className="mr-2 cursor-pointer focus:ring-primary-violet h-4 w-4 text-primary-violet border-gray-500"
                  />
                  <span className="text-gray-700">Select All</span>
                </div>
                <div className="ml-4">
                  <input
                    type="checkbox"
                    onChange={() => setHideAssigned(!hideAssigned)}
                    className="mr-2 cursor-pointer focus:ring-primary-violet h-4 w-4 text-primary-violet border-gray-500"
                  />
                  <span className="text-gray-700">Hide Assigned</span>
                </div>
              </div>

              {students &&
                students.length > 0 &&
                arraySort(students, "name").map((student) => {
                  return (
                    <div className="flex items-center px-2 text-sm mb-1">
                      {student.isAssigned && !hideAssigned && (
                        <AssignedIcon className="w-4 h-4 mr-2 fill-current text-red-500 stroke-current stroke-2" />
                      )}
                      {!student.isAssigned && (
                        <input
                          type="checkbox"
                          checked={selectedStudents.some(
                            (r) => r.studentId === student.studentId
                          )}
                          onChange={() => handleSelectStudent(student)}
                          className="mr-2 cursor-pointer focus:ring-primary-violet h-4 w-4 text-primary-violet border-gray-500"
                        />
                      )}
                      {(!student.isAssigned || !hideAssigned) && (
                        <span
                          className={`${
                            student.isAssigned ? "text-gray-400" : ""
                          }`}
                        >
                          {student.name}
                        </span>
                      )}
                    </div>
                  );
                })}
            </div>
          </div>
          <footer className="flex items-center justify-end px-4 py-4 sm:flex-row bg-gray-50 dark:bg-gray-800 mt-4 rounded-b-lg">
            <div className="space-x-3 flex justify-end">
              <button
                onClick={() => handleAssignClick()}
                className={`${
                  selectedStudents.length === 0
                    ? "pointer-events-none opacity-50 "
                    : ""
                } bg-primary-violet border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-dark-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3`}
              >
                Assign
              </button>
              <button
                onClick={() => togglePopup(false)}
                className="bg-primary-violet border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-dark-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3"
              >
                Close
              </button>
            </div>
          </footer>
        </div>
      </div>
    </Fragment>
  );
}

export default AssignmentRemediation;

import { XIcon } from "@heroicons/react/outline";
import InfoMessage from "../../../../shared/infoMessage";
const theme = {
  EL: {
    headerBg: "bg-[#34d9a5]",
    tryAgainBtn: "bg-[#34d9a5] hover:bg-opacity-80",
  },
  K1: {
    headerBg: "bg-yellow-400",
    tryAgainBtn:
      "align-bottom inline-flex font-bold items-center justify-center cursor-pointer leading-5 transition-colors duration-150  focus:outline-none px-5 py-3 rounded-lg text-lg text-white bg-primary-violet hover:shadow-lg hover:bg-dark-violet",
  },
  MS: {
    headerBg: "bg-gray-50",
    tryAgainBtn:
      "bg-primary-violet border border-transparent hover:bg-dark-violet",
  },
};

interface ActivityErrorPopupProps {
  showPopup: (show: boolean) => void;
  studentType: string;
}

const ActivityErrorPopup = (props: ActivityErrorPopupProps) => {
  const themeName = props.studentType;
  const TryAgain = () => {
    window.location.reload();
  };
  return (
    <div
      className="fixed inset-0 flex items-center bg-black bg-opacity-50 sm:items-center sm:justify-center"
      style={{ zIndex: 11000 }}
    >
      <div
        className="w-full flex flex-col justify-between overflow-hidden h-auto max-h-80percent bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:w-[50%]"
        role="dialog"
      >
        <header>
          {/* Header */}
          <div className={` ${theme[themeName]?.headerBg}  px-4 py-4 sm:px-4 `}>
            <div className="flex items-start justify-between space-x-3">
              <div className="space-y-1">
                <div className="text-lg text-gray-900">Error</div>
              </div>
              <div className="h-7 flex items-center">
                <button
                  type="button"
                  className="text-gray-400 hover:text-gray-500"
                  onClick={() => props.showPopup(false)}
                >
                  <span className="sr-only">Close panel</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </header>

        <div>
          <div className="p-1">
            <InfoMessage message="Due to a technical error, please try again later" />
          </div>
        </div>
        <footer className="flex items-center justify-end px-6 py-4 sm:flex-row bg-gray-50 dark:bg-gray-800">
          {/* <!-- Action Buttons -->  */}
          <div className="flex-shrink-0">
            <div className="space-x-3 flex justify-end">
              <button
                className={` ${theme[themeName]?.tryAgainBtn}  border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 rounded-lg `}
                type="button"
                onClick={() => TryAgain()}
              >
                Try Again
              </button>
              <button
                className="align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 rounded-lg text-sm text-gray-600 border-gray-300 border dark:text-gray-400 focus:outline-none active:bg-transparent hover:border-gray-500 focus:border-gray-500 active:text-gray-500 focus:shadow-outline-gray w-full sm:w-auto"
                type="button"
                onClick={() => props.showPopup(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default ActivityErrorPopup;

import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getTestComparisonStudentDetails } from "../../../../../api/report/testComparisonReport";
import { Info } from "../../../../../assets/icons";
import { UserContextState } from "../../../../../redux/actions/userContextAction";
import Loader from "../../../../shared/loader";
import ReactTooltip from "react-tooltip";

interface IndividualStudentsDetailProps {
  preActivitySessionId: number | null;
  postActivitySessionId: number | null;
  subjectId: number;
  preTestName: string;
  postTestName: string;
  userContext?: UserContextState;
  schoolYearId: number | null;
}
interface IQuestionStats {
  totalQuestions: number;
  pointsEarned: number;
  pointsPossible: number;
}
interface IDomainTestData {
  id: number;
  name: string;
  preTest?: IQuestionStats;
  postTest?: IQuestionStats;
  standards: Array<IStandardTestData>;
}

interface IStandardTestData {
  id: number;
  name: string;
  code: string;
  description: string;
  preTest?: IQuestionStats;
  postTest?: IQuestionStats;
}

function IndividualStudentsDetail(props: IndividualStudentsDetailProps) {
  const [domains, setDomains] = useState<Array<IDomainTestData>>([]);
  const [showLoading, setShowLoading] = useState<boolean>(false);
  useEffect(() => {
    setShowLoading(true);
    getTestComparisonStudentDetails(
      props.userContext?.userId ?? 0,
      props.subjectId,
      props.preActivitySessionId,
      props.postActivitySessionId,
      props.schoolYearId
    )
      .then((result) => {
        mergeResult(
          result.data?.preTestResult?.subjects[0],
          result.data?.postTestResult?.subjects[0]
        );
      })
      .finally(() => {
        setShowLoading(false);
      });
  }, []);

  function mergeResult(preTestResult: any, postTestResult: any) {
    var domainList: Array<IDomainTestData> = [];
    if (preTestResult) {
      preTestResult.domains.forEach((d) => {
        var domain: IDomainTestData = {
          id: d.id,
          name: d.name,
          preTest: {
            totalQuestions: d.totalQuestions,
            pointsEarned: d.pointsEarned,
            pointsPossible: d.pointsPossible,
          },
          standards: [],
        };
        d.standards.forEach((std) => {
          domain.standards.push({
            id: std.id,
            name: std.name,
            code: std.code,
            description: std.description,
            preTest: {
              totalQuestions: std.totalQuestions,
              pointsEarned: std.pointsEarned,
              pointsPossible: std.pointsPossible,
            },
          });
        });
        domainList.push(domain);
      });
    }

    if (postTestResult) {
      postTestResult.domains.forEach((d) => {
        var domain = domainList.find((t) => t.id === d.id);
        if (domain === undefined)
          domain = {
            id: d.id,
            name: d.name,
            postTest: {
              totalQuestions: d.totalQuestions,
              pointsEarned: d.pointsEarned,
              pointsPossible: d.pointsPossible,
            },
            standards: [],
          };
        else {
          domain.postTest = {
            totalQuestions: d.totalQuestions,
            pointsEarned: d.pointsEarned,
            pointsPossible: d.pointsPossible,
          };
        }
        d.standards.forEach((std) => {
          let standard = domain?.standards.find((t) => t.id === std.id);
          if (standard === undefined)
            standard = {
              id: std.id,
              name: std.name,
              code: std.code,
              description: std.description,
            };
          standard.postTest = {
            totalQuestions: std.totalQuestions,
            pointsEarned: std.pointsEarned,
            pointsPossible: std.pointsPossible,
          };

          const standardIndex =
            domain?.standards.findIndex((t) => t.id === std.id) ?? 0;
          if (standardIndex === -1) {
            domain?.standards.push(standard);
          } else {
            if (domain) domain.standards[standardIndex] = standard;
          }
        });

        var domainIndex = domainList.findIndex((t) => t.id === domain?.id);
        if (domainIndex === -1) {
          domainList.push(domain);
        } else {
          domainList[domainIndex] = domain;
        }
      });
    }

    setDomains(domainList);
  }

  function getPercentage(total, earned) {
    return total === 0 ? 0 : Math.round((earned * 100.0) / total);
  }

  function getDifference(std) {
    return (
      getPercentage(
        std.postTest?.pointsPossible ?? 0,
        std.postTest?.pointsEarned ?? 0
      ) -
      getPercentage(
        std.preTest?.pointsPossible ?? 0,
        std.preTest?.pointsEarned ?? 0
      )
    );
  }
  return (
    <div>
      <ReactTooltip
        multiline={true}
        className="bg-secondary-teal"
        backgroundColor="#008fbb"
      />
      {showLoading && <Loader />}
      <div className="flex items-center justify-end gap-x-2 mt-4 mx-3">
        <div className="bg-primary-violet text-white px-2 text-xs">
          {props.preTestName}
        </div>
        <div className="bg-secondary-teal text-white px-2 text-xs">
          {props.postTestName}
        </div>
      </div>
      <div className="relative overflow-x-auto shadow-md mx-3 my-4">
        <table className="w-full text-sm text-left text-gray-500 border h-[1px]">
          <thead className="text-sm text-gray-800 bg-gray-50">
            <tr>
              <th className="px-6 py-3 whitespace-nowrap w-[26rem] text-gray-700 font-medium"></th>
              <th className="px-6 py-3 text-center text-gray-700 font-medium">
                Total Questions
                {/*  <div className="flex flex-row justify-center items-center gap-x-2">
                  <div className="w-10 h-4 bg-primary-violet pr-10"></div>
                  <div className="w-10 h-4 bg-secondary-teal pl-10"></div>
                </div> */}
              </th>
              <th className="px-6 py-3 font-medium text-gray-700">
                <div className="flex justify-center">
                  <span
                    data-tip="Score variance from Pre-Test to Post-Test."
                    className="flex items-center gap-x-2"
                  >
                    Difference
                    <Info
                      className="w-5 h-5 text-primary-violet cursor-pointer"
                      title="Score variance from Pre-Test to Post-Test"
                    ></Info>
                  </span>
                </div>
              </th>
              <th className="px-6 py-3 text-center text-gray-700 font-medium">
                Score
              </th>
            </tr>
          </thead>
          <tbody>
            {domains?.map((domain, index) => {
              return (
                <>
                  <tr
                    className="bg-[#edeef1] border-t border-b border-gray-500"
                    key={index}
                  >
                    <td className="px-6 text-sm uppercase text-gray-700 font-medium">
                      {domain.name}
                    </td>
                    <td className="w-[7rem]">
                      <div className="flex justify-center h-full w-full">
                        <div className="w-1/2 flex items-center bg-primary-violet justify-center text-white">
                          {domain.preTest?.totalQuestions ?? 0}
                        </div>
                        <div className="w-1/2 flex items-center bg-secondary-teal justify-center text-white">
                          {domain.postTest?.totalQuestions ?? 0}
                        </div>
                      </div>
                    </td>
                    <td
                      className={
                        "px-6 py-3 text-center" +
                        (getDifference(domain) < 0
                          ? " text-secondary-teal"
                          : "")
                      }
                    >
                      {domain.preTest !== undefined &&
                        domain.postTest !== undefined && (
                          <>{getDifference(domain)}</>
                        )}
                    </td>
                    <td className="px-6 py-3">
                      <div className="flex items-center justify-center w-full">
                        <div className="flex-none text-xs text-gray-500 w-9">
                          Pre
                        </div>
                        <div className="bg-white w-48 mr-2 h-4 flex overflow-hidden">
                          <div
                            className={"bg-primary-violet"}
                            style={{
                              width:
                                getPercentage(
                                  domain.preTest?.pointsPossible ?? 0,
                                  domain.preTest?.pointsEarned ?? 0
                                ) + "%",
                            }}
                          ></div>
                          <div className="bg-[#82c95e]"></div>
                        </div>
                        <div className="text-xs text-gray-500 w-5">
                          {domain.preTest !== undefined &&
                            getPercentage(
                              domain.preTest?.pointsPossible ?? 0,
                              domain.preTest?.pointsEarned ?? 0
                            )}
                        </div>
                      </div>
                      <div className="flex items-center justify-center w-full">
                        <div className="flex-none text-xs text-gray-500 w-9">
                          Post
                        </div>
                        <div className="bg-white w-48 mr-2 h-4 flex overflow-hidden">
                          <div
                            className={"bg-secondary-teal"}
                            style={{
                              width:
                                getPercentage(
                                  domain.postTest?.pointsPossible ?? 0,
                                  domain.postTest?.pointsEarned ?? 0
                                ) + "%",
                            }}
                          ></div>
                          <div className="bg-[#82c95e]"></div>
                        </div>
                        <div className="text-xs text-gray-500 w-5">
                          {domain.postTest !== undefined &&
                            getPercentage(
                              domain.postTest?.pointsPossible ?? 0,
                              domain.postTest?.pointsEarned ?? 0
                            )}
                        </div>
                      </div>
                    </td>
                  </tr>
                  {domain.standards?.map((std, stdIndex) => {
                    return (
                      <tr className="bg-white hover:bg-gray-50" key={stdIndex}>
                        <td className="px-6 py-3 text-gray-500 whitespace-nowrap">
                          {/* {stdIndex === 0 && (
                            <div className="text-gray-700 whitespace-nowrap font-medium mb-2">
                              {domain.name}
                            </div>
                          )} */}
                          <span className="ml-6">{`${
                            std.code ? `[${std.code}]` : ""
                          } ${std.name}`}</span>
                        </td>
                        <td className="w-[7rem]">
                          <div className="flex justify-center h-full w-full">
                            <div className="w-1/2 flex items-center bg-primary-violet justify-center text-white">
                              {std.preTest?.totalQuestions ?? 0}
                            </div>
                            <div className="w-1/2 flex items-center bg-secondary-teal justify-center text-white">
                              {std.postTest?.totalQuestions ?? 0}
                            </div>
                          </div>
                        </td>
                        <td
                          className={
                            "px-6 py-3 text-center" +
                            (getDifference(std) < 0
                              ? " text-secondary-teal"
                              : "")
                          }
                        >
                          {std.preTest !== undefined &&
                            std.postTest !== undefined && (
                              <>{getDifference(std)}</>
                            )}
                        </td>
                        <td className="px-6 py-3">
                          <div className="flex items-center justify-center w-full">
                            <div className="flex-none text-xs text-gray-500 w-9">
                              Pre
                            </div>
                            <div className="bg-gray-200 w-48 mr-2 h-4 flex overflow-hidden">
                              <div
                                className={"bg-primary-violet"}
                                style={{
                                  width:
                                    getPercentage(
                                      std.preTest?.pointsPossible ?? 0,
                                      std.preTest?.pointsEarned ?? 0
                                    ) + "%",
                                }}
                              ></div>
                              <div className="bg-[#82c95e]"></div>
                            </div>
                            <div className="text-xs text-gray-500 w-5">
                              {std.preTest !== undefined &&
                                getPercentage(
                                  std.preTest?.pointsPossible ?? 0,
                                  std.preTest?.pointsEarned ?? 0
                                )}
                            </div>
                          </div>
                          <div className="flex items-center justify-center w-full">
                            <div className="flex-none text-xs text-gray-500 w-9">
                              Post
                            </div>
                            <div className="bg-gray-200 w-48 mr-2 h-4 flex overflow-hidden">
                              <div
                                className={"bg-secondary-teal"}
                                style={{
                                  width:
                                    getPercentage(
                                      std.postTest?.pointsPossible ?? 0,
                                      std.postTest?.pointsEarned ?? 0
                                    ) + "%",
                                }}
                              ></div>
                              <div className="bg-[#82c95e]"></div>
                            </div>
                            <div className="text-xs text-gray-500 w-5">
                              {std.postTest !== undefined &&
                                getPercentage(
                                  std.postTest?.pointsPossible ?? 0,
                                  std.postTest?.pointsEarned ?? 0
                                )}
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default connect((state: any) => {
  return {
    userContext: state.userContext,
  };
})(IndividualStudentsDetail);

import { connect } from "react-redux";
import * as userContextAction from "../../../../src/redux/actions/userContextAction";
import { GoogleIcon } from "../../../assets/icons/index";
import constant from "../../../utils/constant/constant";
import Profile from "../../../model/users/profile";
import { handleLoginWithCredentials } from "../../../utils/googleHelper";
import userApi from "../../../api/userApi";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

interface UnauthorizedProps {
  userContext?: userContextAction.UserContextState;
  profile?: Profile;
}

const Unauthorized = (props: UnauthorizedProps) => {
  const url = new URLSearchParams(useLocation().search).get("redirectUrl");
  const redirectUrl = decodeURIComponent(url!);
  const [credentials, setCredentials] = useState({
    accountLoginName: "",
    username: "",
    passwordHash: "",
  });

  function getLoginDetails(userId: number) {
    userApi.GetUserDetail(userId).then((res) => {
      if (res.data) {
        setCredentials({
          accountLoginName: res.data.accountId,
          username: res.data.username,
          passwordHash: res.data.passwordHash,
        });
      }
    });
  }

  function redirectToGoogleLogin() {
    if (props.userContext?.roleId === constant.UserRoleId.Student) {
      handleLoginWithCredentials(
        "Student",
        redirectUrl!,
        credentials.accountLoginName,
        credentials.username,
        credentials.passwordHash
      );
    } else {
      handleLoginWithCredentials(
        "Teacher",
        redirectUrl!,
        credentials.accountLoginName,
        credentials.username,
        credentials.passwordHash
      );
    }
  }
  useEffect(() => {
    getLoginDetails(props.userContext?.userId!);
  }, []);

  return (
    <>
      <div className="bg-gradient-to-b shadow-xl from-primary-violet to-dark-teal h-screen flex justify-center items-center">
        <div className=" bg-white w-full  md:w-3/4 xl:w-1/2 xxxl:w-1/3 flex flex-col items-center py-12 px-4 rounded-lg mx-8 lg:mx-0">
          <h1 className="text-9xl font-bold text-primary-violet">401</h1>
          <p className="text-gray-700 text-3xl font-semibold text-center mt-2">
            <span className="text-red-400">Oops!</span> Google token is expired
            please sign in to utilize Google classroom.
          </p>

          <div
            className="focus:outline-none mt-8"
            onClick={() => redirectToGoogleLogin()}
          >
            <div className=" inline-flex space-x-2 bg-blue-600 text-white border border-blue-600 pr-4 cursor-pointer">
              <div className="p-2 bg-white">
                <GoogleIcon />
              </div>
              <span className="abcRioButtonContents text-xs flex items-center">
                <span id="not_signed_int8aitmh8ix7c ml-4">
                  Sign in with Google
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(Unauthorized);

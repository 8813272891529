import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Moment from "react-moment";
import { connect } from "react-redux";
import { getAllAlienArenaSessionsByStudent } from "../../../../../api/teacher/alienArena";
import { RefreshIcon } from "../../../../../assets/icons";
import PagingResponse from "../../../../../model/common/pagingResponse";
import AlienArenaDetails from "../../../../../model/student/alienArenaDetails";
import { UserContextState } from "../../../../../redux/actions/userContextAction";
import Loader from "../../../../shared/loader";
import RouteConstant from "../../../../../utils/constant/routeConstant";
import Breadcrumb from "../../middleSchool/breadcrumb";
import { IBreadcrumb } from "../../../../../model/common/breadcrumb";

interface AlienArenaProps {
  userContext: UserContextState;
  userId: number;
  roleId: number;
}

function AlienArenaElementary(props: AlienArenaProps) {
  const { t } = useTranslation();
  const [loading, showLoading] = useState<boolean>(false);
  const [alienArenaSessions, setAlienArenaSessions] =
    useState<PagingResponse<AlienArenaDetails>>();

  const getAlienArenaSessionsList = () => {
    showLoading(true);
    getAllAlienArenaSessionsByStudent(props.userContext.userId)
      .then((d) => {
        setAlienArenaSessions(d.data);
        showLoading(false);
      })
      .catch(() => {});
  };
  const handleRefresh = () => {
    getAlienArenaSessionsList();
  };
  useEffect(() => {
    getAlienArenaSessionsList();
  }, []);
  const breadcrumbItems = () => {
    const items: Array<IBreadcrumb> = [
      {
        name: t("Breadcrumb.Home"),
        url: RouteConstant.MiddleSchool.assignments,
      },
      {
        name: t("Breadcrumb.AlienArena"),
        url: "",
      },
    ];

    return items;
  };

  return (
    <Fragment>
      {loading && <Loader />}

      <div className="min-w-0 block lg:col-span-9 xl:col-span-10 w-full">
        <Breadcrumb items={breadcrumbItems()} />
        <div className="bg-middle-school-primary  w-full mb-5 rounded-lg p-5 h-full">
          <div className="flex flex-col md:flex-row gap-4 items-end md:items-center md:justify-between justify-start my-4">
            <div className="p-1"></div>

            <div className="grid grid-cols-1 gap-4">
              <button
                onClick={() => {
                  handleRefresh();
                }}
                type="submit"
                className="border border-transparent shadow-sm py-2 px-4 justify-end text-sm font-medium text-white"
              >
                <RefreshIcon />
                Refresh
              </button>
            </div>
          </div>
          <div className="mb-4 pb-4 border-b-2 overflow-x-auto xl:overflow-x-visible py-5 px-5">
            <table className="table w-full ">
              <thead>
                <tr className="bg-middle-school-secondary  text-gray-700 	font-weight: 600 ">
                  <th className="text-left p-3 text-sm font-normal">
                    {t("AlienArena.Session")} {t("AlienArena.Name")}
                  </th>
                  <th className="text-left p-3 text-sm font-normal">
                    {t("AlienArena.Subject")}{" "}
                  </th>
                  <th className="text-left p-3 text-sm font-normal">
                    {t("AlienArena.TeamName")}
                  </th>
                  <th className="text-left p-3 text-sm font-normal">
                    {t("AlienArena.StartDate")}
                  </th>
                  <th className="text-left p-3 text-sm font-normal">
                    {" "}
                    {t("AlienArena.Score")}
                  </th>
                  <th className="text-left p-3 text-sm font-normal"></th>
                </tr>
              </thead>
              <tbody>
                {alienArenaSessions &&
                  alienArenaSessions!.data.length > 0 &&
                  alienArenaSessions.data?.map((session, index) => {
                    return (
                      <Fragment key={index}>
                        <tr
                          className={`bg-lime-100`}
                          // onMouseOver={() => {
                          //   setHoverElement("row-" + session.id);
                          // }}
                        >
                          <td className="text-sm p-3 ">
                            {session.sessionName}
                          </td>

                          <td className="text-sm p-3 ">
                            {session.sessionName}
                          </td>
                          <td className="text-sm p-3 ">{session.subject}</td>
                          <td className="text-sm p-3 ">
                            {<Moment>{session.startDate}</Moment>}
                          </td>
                          <td className="text-sm p-3 "><>{session.score}</></td>
                          <td className="text-sm p-3 ">
                            <span className="py-0.5 px-1 text-xs rounded-lg text-white">
                              {session.statusName}
                            </span>
                          </td>
                        </tr>
                      </Fragment>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(AlienArenaElementary);

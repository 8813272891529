import { AxiosHelper } from "../../../environment/axiosConfig";
import APIConstant from "../../../utils/constant/apiConstant";

export const getAdaptiveData = (
  liftOffHeaderId: number,
  gradeId: number,
  selectedLanguage: string
) => {
  return AxiosHelper.axiosInstance().get(APIConstant.APP_ADAPTIVE_DATA, {
    params: {
      liftOffHeaderId: liftOffHeaderId,
      gradeId: gradeId,
      selectedLanguage: selectedLanguage,
    },
  });
};

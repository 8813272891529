import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { getStudyPlanStandards } from "../../../../../api/student/studyPlanApi";
import { StarDarkIcon, StarIcon } from "../../../../../assets/icons";
import {
  IDomainStandardGroup,
  IStudyPlanStandard,
} from "../../../../../model/interface/studyPlanStandard";
import Profile from "../../../../../model/users/profile";
import { UserContextState } from "../../../../../redux/actions/userContextAction";
import constant from "../../../../../utils/constant/constant";
import RouteConstant from "../../../../../utils/constant/routeConstant";
import useTextToSpeech from "../../shared/textToSpeech/useTextToSpeech";
import { paramEncode } from "../../../../../utils/urlHelper";

interface IStudyPlanStandardProps {
  subjectId: number;
  subjectName: string;
  contentAreaId: number;
  userContext?: UserContextState;
  profile?: Profile;
}
function StudyPlanStandard(props: IStudyPlanStandardProps) {
  const { t } = useTranslation();
  const history = useHistory();
  const [standardGroups, setStandardGroups] = useState<
    Array<IDomainStandardGroup>
  >([]);
  const { startSpeaking, stopSpeaking } = useTextToSpeech();

  useEffect(() => {
    loadStandards();
  }, [props.subjectId]);
  function loadStandards() {
    getStudyPlanStandards(
      props.userContext?.userId ?? 0,
      props.subjectId,
      false,
      props.userContext?.schoolId ?? 0
    )
      .then((d) => {
        convertToStandardGroup(d.data);
      })
      .finally(() => {});
  }

  function convertToStandardGroup(standards: Array<IStudyPlanStandard>) {
    const domains = Array.from(new Set(standards.map((item) => item.domainId)));
    var tempStandardGroup: Array<IDomainStandardGroup> = [];
    var index: number = 1;
    domains.forEach((d) => {
      var domain = standards.find((t) => t.domainId === d);
      var standardGroup: IDomainStandardGroup = {
        domainName:
          props.profile?.plLanguageId === constant.Languages.Spanish
            ? domain?.spanishDomainName ?? domain?.domainName ?? ""
            : domain?.domainName ?? "",
        domainId: 0,
        standards: standards.filter((s) => s.domainId === d),
      };
      standardGroup.standards.forEach((std) => {
        std.index = index;
        index++;
      });
      tempStandardGroup.push(standardGroup);
    });
    setStandardGroups(tempStandardGroup);
  }
  function getStandardName(standard: IStudyPlanStandard) {
    if (
      props.profile?.plLanguageId === constant.Languages.Spanish &&
      standard.spanishStandardName !== null &&
      standard.spanishStandardName !== undefined
    )
      return standard.spanishStandardName;
    else return standard.standardName;
  }
  function startAssignment(standard: IStudyPlanStandard) {
    if (
      standard.assignmentStatusId === constant.AssignmentStatusId.INPROGRESS
    ) {
      history.push(
        RouteConstant.KindergartenStudentRoutes.StudyPlan.replace(
          ":standardId",
          encodeURI(btoa(standard.standardId.toString()))
        )
          .replace(
            ":standardName",
            encodeURI(paramEncode(getStandardName(standard)))
          )
          .replace(":gameId", encodeURI(btoa("0")))
          .replace(":rocketId", encodeURI(btoa("0")))
          .replace(":blasterId", encodeURI(btoa("0")))
          .replace(":blasterType", encodeURI(btoa("0")))
          .replace(":subjectId", encodeURI(btoa(props.subjectId.toString()))) +
          "?s=" +
          props.subjectName +
          "&c=" +
          props.contentAreaId
      );
    } else {
      var route =
        RouteConstant.KindergartenStudentRoutes.GameChoose.replace(
          ":standardId",
          encodeURI(btoa(standard.standardId.toString()))
        )
          .replace(":subjectId", encodeURI(btoa(props.subjectId.toString())))
          .replace(":standardName", encodeURI(btoa(getStandardName(standard))))
          .replace(
            ":mode",
            encodeURI(btoa(constant.GameChooseMode.KinderGartenStudyPlan))
          ) +
        "?s=" +
        props.subjectName +
        "&c=" +
        props.contentAreaId;
      history.push(route);
    }
  }

  return (
    <Fragment>
      <div className="bg-[#403d6a] rounded-lg mt-6 md:mt-0">
        <h3 className="py-4 px-7 bg-[#4e4b75] rounded-t-lg tracking-wider font-Didact relative text-lg  lg:text-xl xl:text-2xl text-[#f5d500] text-white hover:shadow-2xl h-full flex items-center justify-center">
          {t("StudyPlan.StudyPlan")}
        </h3>

        <div className="p-4">
          {standardGroups.map((group) => {
            return (
              <Fragment key={group.domainName}>
                <h3 className="py-3 font-Didact relative text-lg tracking-wide lg:text-lg xl:text-xl text-white hover:shadow-2xl text-left pl-3 h-full flex items-center">
                  {group.domainName}
                </h3>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 gap-12 text-center flex flex-col mb-6">
                  {group.standards.map((standard) => {
                    return (
                      <Fragment key={standard.standardId}>
                        <div
                          className="bg-white group rounded-lg hover:shadow-2xl hover:bg-[#ffcb91] cursor-pointer border-8 border-[#FF8700] px-2"
                          onClick={() => {
                            startAssignment(standard);
                          }}
                          onMouseEnter={() => {
                            startSpeaking(
                              getStandardName(standard),
                              (props.profile?.plLanguageId ?? 1) === 2 ? 2 : 1
                            );
                          }}
                          onMouseLeave={() => {
                            stopSpeaking();
                          }}
                        >
                          <label className="flex-shrink-0 text-white font-semibold text-2xl cursor-pointer h-14 w-14 bg-[#FF8700] hover:opacity-90 mt-4 rounded-full flex inline-block m-4 mb-0 items-center justify-center">
                            {standard.index}
                          </label>
                          <div className=" px-4 py-2 text-base m-auto w-36 mt-1">
                            <img
                              className="w-full"
                              alt="standard"
                              title="standard"
                              srcSet={standard.standardImageUrl}
                            />
                          </div>
                          <div className="flex gap-2 mt-6 items-center justify-center">
                            {standard.earnedStar === 0 && (
                              <>
                                {" "}
                                <StarIcon title="Star" className="w-9 " />
                                <StarIcon title="Star" className="w-9 " />
                                <StarIcon title="Star" className="w-9" />
                              </>
                            )}
                            {standard.earnedStar === 1 && (
                              <>
                                {" "}
                                <StarDarkIcon
                                  title="star"
                                  className="w-9 fill-current text-yellow-300"
                                />
                                <StarIcon title="Star" className="w-9" />
                                <StarIcon title="Star" className="w-9" />
                              </>
                            )}
                            {standard.earnedStar === 2 && (
                              <>
                                {" "}
                                <StarDarkIcon
                                  title="Star"
                                  className="w-9 fill-current text-yellow-300"
                                />
                                <StarDarkIcon
                                  title="Star"
                                  className="w-9 fill-current text-yellow-300"
                                />
                                <StarIcon title="Star" className="w-9" />
                              </>
                            )}
                            {standard.earnedStar === 3 && (
                              <>
                                {" "}
                                <StarDarkIcon
                                  title="star"
                                  className="w-9 fill-current text-yellow-300 "
                                />
                                <StarDarkIcon
                                  title="star"
                                  className="w-9 fill-current text-yellow-300"
                                />
                                <StarDarkIcon
                                  title="star"
                                  className="w-9 fill-current text-yellow-300"
                                />
                              </>
                            )}
                          </div>
                          <p className="group-hover:font-bold group-hover:text-black group-hover:scale-110 transform transition-all duration-300 font-Didact my-6  text-lg tracking-wide lg:text-xl xl:text-2xl  text-center items-center">
                            {getStandardName(standard)}
                          </p>
                        </div>
                      </Fragment>
                    );
                  })}
                </div>
              </Fragment>
            );
          })}
        </div>
      </div>
    </Fragment>
  );
}

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(StudyPlanStandard);

import { BlastOff } from "@educationgalaxy/blastoff/lib";
import { TapRocket } from "@educationgalaxy/pl-game-tap-rocket/lib";
import { GalacticEscape } from "@educationgalaxy/galactic-escape/lib";
import { AlienInvasion } from "@educationgalaxy/alien-invasion/lib";
import { GalaxyDefender } from "@educationgalaxy/galaxy-defender/lib";
import { RocketJump } from "@educationgalaxy/rocket-jump/lib";
import { GalaxyLander } from "@educationgalaxy/galaxy-lander/lib";
import { Wormhole } from "@educationgalaxy/wormhole/lib";
import { SpaceKraken } from "@educationgalaxy/space-kraken/lib";
import { SpaceMatch } from "@educationgalaxy/space-match/lib";
import { SpaceRaid } from "@educationgalaxy/space-raid/lib";
import { StarDust } from "@educationgalaxy/stardust/lib";
import { BossBattle } from "@educationgalaxy/boss-battle/lib";
import {ZigZag} from "@educationgalaxy/zigzag/lib";
import constant from "../../../utils/constant/constant";
import { useEffect, useState } from "react";
import { waitForElementsToRender } from "../../../utils/waitFor";

interface GameProps {
  gameId: number;
  rocketId: number;
  blasterId: number;
  blasterType: string;
  startLevel: number;
  startScore: number;
  timer: number;
  onGameComplete(data: {
    newLevel: number;
    currentScore: number;
    itemsleftarray?: string;
  }): void;
  bossLevel?: number; 
  gameSettings?: string;
}

const Game = (props: GameProps) => {
  const defaultGameTries = 3;
  const {
    startLevel,
    startScore,
    rocketId,
    blasterId,
    blasterType,
    gameId,
    timer,
    onGameComplete,
    bossLevel,
    gameSettings
  } = props;
  const [gameStart, setGameStart] = useState(false);
  
  useEffect(() => {
    waitForElementsToRender("", ".gameContainer", 0, 3000).then(_ => setGameStart(true));
  }, [])
  
  return (
    <>
      <div style={{ width: "70vw", height: "70vh" }}>
        {gameStart && gameId === constant.Game.BlastOff && (
          <BlastOff
            startLevel={startLevel}
            startScore={startScore}
            rocketId={rocketId}
            tries={defaultGameTries}
            onGameComplete={onGameComplete}
          ></BlastOff>
        )}
        {gameStart && gameId === constant.Game.TapRocket && (
          <TapRocket
            startLevel={startLevel}
            startScore={startScore}
            onGameComplete={onGameComplete}
            rocketId={rocketId}
            timer={timer}
            retries={1}
          />
        )}
        {gameStart && gameId === constant.Game.GalacticEscape && (
          <GalacticEscape
            startLevel={startLevel}
            startScore={startScore}
            onGameComplete={onGameComplete}
            rocketId={rocketId}
            timer={timer}
          />
        )}
        {gameStart && gameId === constant.Game.AlienInvasion && (
          <AlienInvasion
            startLevel={startLevel}
            startScore={startScore}
            onGameComplete={onGameComplete}
            rocketId={rocketId}
            timer={timer}
            blasterName={blasterType}
            blasterLevel={blasterId}
          />
        )}
        {gameStart && gameId === constant.Game.GalaxyDefender && (
          <GalaxyDefender
            startLevel={startLevel}
            startScore={startScore}
            onGameComplete={onGameComplete}
            rocketId={rocketId}
            timer={timer}
          />
        )}
        {gameStart && gameId === constant.Game.RocketJump && (
          <RocketJump
            startLevel={startLevel}
            startScore={startScore}
            rocketId={rocketId}
            tries={defaultGameTries}
            timer={timer}
            onGameComplete={onGameComplete}
          ></RocketJump>
        )}
        {gameStart && gameId === constant.Game.GalaxyLander && (
          <GalaxyLander
            startLevel={startLevel}
            startScore={startScore}
            onGameComplete={onGameComplete}
            rocketId={rocketId}
            timer={timer}
          />
        )}
        {gameStart && gameId === constant.Game.WormHole && (
          <Wormhole
            startLevel={startLevel}
            startScore={startScore}
            onGameComplete={onGameComplete}
            rocketId={rocketId}
            timer={timer}
          />
        )}
        {gameStart && gameId === constant.Game.SpaceKraken && (
          <SpaceKraken
            startLevel={startLevel}
            startScore={startScore}
            onGameComplete={onGameComplete}
            rocketId={rocketId}
            timer={timer}
          />
        )}
        {gameStart && gameId === constant.Game.SpaceMatch && (
          <SpaceMatch
            startLevel={startLevel}
            startScore={startScore}
            rocketCount={2}
            cardCount={5}
            itemsLeft={gameSettings??""}
            onGameComplete={onGameComplete}
            startTimer={timer}
          />
        )}
        {gameStart && gameId === constant.Game.SpaceRaid && (
          <SpaceRaid
            startLevel={startLevel}
            startScore={startScore}
            onGameComplete={onGameComplete}
            rocketId={rocketId}
            blasterName={blasterType}
            blasterLevel={blasterId}
            timer={timer}
          />
        )}
        {gameStart && gameId === constant.Game.StarDust && (
          <StarDust
            startLevel={startLevel}
            startScore={startScore}
            onGameComplete={onGameComplete}
            timer={timer}
          />
        )}
        {gameStart && gameId === constant.Game.BossBattles && (
          <BossBattle
            startLevel={startLevel}
            startScore={startScore}
            onGameComplete={onGameComplete}
            rocketId={rocketId}
            timer={timer}
            bossLevel={bossLevel??1}
            bossHealth={Number(gameSettings??"100")}
            blasterName={blasterType} 
            blasterLevel={blasterId} 
          />
        )}
         {gameId === constant.Game.ZigZag && (
          <ZigZag
            startLevel={startLevel}
            startScore={startScore}
            onGameComplete={onGameComplete}
            rocketId={rocketId}
            timer={timer} 
            retries={defaultGameTries}          
          />
        )}
      </div>
    </>
  );
};

export default Game;

/* User Context Action */

export const USERCONTEXT_GETCONTEXT = "USERCONTEXT_GETCONTEXT";
export const USERCONTEXT_SETCONTEXT = "USERCONTEXT_SETCONTEXT";


export const ALERT_SHOW_MESSAGE = "ALERT_SHOW_MESSAGE";

/*
 * User
 */

export const GET_PROFILE = "GET_PROFILE";
export const SET_PROFILE = "SET_PROFILE";

export const GET_MASTERDATA = "GET_MASTERDATA";
export const SET_MASTERDATA = "SET_MASTERDATA";

/*
 * Session Shared Values
 */

export const GET_SESSIONVALUES = "GET_SESSIONVALUES";
export const SET_SESSIONVALUES = "SET_SESSIONVALUES";

import React from "react";

export const BadRequest: React.FC = () => {
  return (
    <div className="w-full h-screen place-items-center grid">
      <div className="grid text-center">
        <span className="text-3xl">400</span>
        <span className="text-xl">Bad Request</span>
        <span className="text-red-500">
            The server cannot process your request due to invalid data
        </span>
      </div>
    </div>
  );
};

export default BadRequest;
import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { RouteComponentProps } from "react-router";
import { LogoIcon } from "../../../assets/icons/index";
import { Configuration } from "../../../environment/setup";
import { registerUser, validateRecoveryCode } from "./api";
import { useHistory } from "react-router-dom";
import RouteConstant from "../../../utils/constant/routeConstant";

interface UserRegistrationParams {
  recoveryCode: string;
}
function UserRegistration(props: RouteComponentProps<UserRegistrationParams>) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();
  const [accountId, setAccountId] = useState("");
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [recoveryCode, setRecoveryCode] = useState(
    props.match.params.recoveryCode
  );
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const passwordRef = useRef({});
  passwordRef.current = watch("password", "");

  function handleAccountIdChange(e: ChangeEvent<HTMLInputElement>) {
    setShowSuccessMessage(false);
    setShowErrorMessage(false);
    setAccountId(e.target.value);
  }

  function handleUserNameChange(e: ChangeEvent<HTMLInputElement>) {
    setShowSuccessMessage(false);
    setShowErrorMessage(false);
    setUserName(e.target.value);
  }

  function handlePasswordChange(e: ChangeEvent<HTMLInputElement>) {
    setShowSuccessMessage(false);
    setShowErrorMessage(false);
    setPassword(e.target.value);
  }

  function handleConfirmPasswordChange(e: ChangeEvent<HTMLInputElement>) {
    setShowSuccessMessage(false);
    setShowErrorMessage(false);
    setConfirmPassword(e.target.value);
  }

  function handleOnSubmit() {
    registerUser(accountId, userName, recoveryCode, password)?.then((res) => {
      if (res.data) {
        setPassword("");
        setConfirmPassword("");
        setRecoveryCode("");
        setShowErrorMessage(false);
        setShowSuccessMessage(true);
      } else {
        setShowSuccessMessage(false);
        setShowErrorMessage(true);
      }
    });
  }

  useEffect(() => {
    validateRecoveryCode(recoveryCode)?.then((res) => {
      if (!res.data) history.push(`${RouteConstant.RECOVERYCODEEXPIRED}`);
      else setLoading(false);
    });
  }, []);

  return (
    <>
      {!loading && (
        <form onSubmit={handleSubmit(handleOnSubmit)}>
          <div className="min-h-screen bg-white flex">
            <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
              <div className="mx-auto w-full max-w-lg">
                <div>
                  <LogoIcon />
                  <h2 className="mt-6 text-2xl bg-primary-violet text-white py-2 px-4 mb-4">
                    Complete Registration
                  </h2>
                  <p className="text-sm">
                    Use the credentials provided in the email to gain access to your account.
                  </p>
                </div>

                <div className="mt-8 bg-gray-50 py-8 px-8">
                  <div className="space-y-6">
                    <div>
                      <label className="block text-sm font-medium text-gray-500">
                        Account Id
                      </label>
                      <div className="mt-1">
                        <input
                          disabled={showSuccessMessage}
                          value={accountId}
                          type="text"
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          {...register("accountId", {
                            onChange: handleAccountIdChange,
                            required: "Account Id should not be empty",
                          })}
                        />
                        <small className="text-red-400">
                          {errors.accountId && (
                            <span>{errors.accountId.message}</span>
                          )}
                        </small>
                      </div>
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-500">
                        Username
                      </label>
                      <div className="mt-1">
                        <input
                          disabled={showSuccessMessage}
                          value={userName}
                          type="text"
                          autoComplete={undefined}
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          {...register("username", {
                            onChange: handleUserNameChange,
                            required: "Username should not be empty",
                          })}
                        />
                        <small className="text-red-400">
                          {errors.userName && (
                            <span>{errors.userName.message}</span>
                          )}
                        </small>
                      </div>
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-500">
                        Password
                      </label>
                      <div className="mt-1">
                        <input
                          disabled={showSuccessMessage}
                          value={password}
                          type="password"
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          {...register("password", {
                            onChange: handlePasswordChange,
                            required: "You must specify a password",
                            minLength: {
                              value: 8,
                              message:
                                "Password must have at least 8 characters",
                            },
                            pattern: {
                              value:
                                /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,50}$/,
                              message:
                                "Password must contain: Minimum 8 characters atleast 1 Alphabet,1 Special Character and 1 Number.",
                            },
                          })}
                        />
                        <small className="text-red-400">
                          {errors.password && (
                            <span>{errors.password.message}</span>
                          )}
                        </small>
                      </div>
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-500">
                        Re-enter Password
                      </label>
                      <div className="mt-1">
                        <input
                          disabled={showSuccessMessage}
                          value={confirmPassword}
                          type="password"
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          {...register("confirmPassword", {
                            onChange: handleConfirmPasswordChange,
                            validate: (value) =>
                              value === passwordRef.current ||
                              "The passwords do not match",
                          })}
                        />
                        <small className="text-red-400">
                          {errors.confirmPassword && (
                            <span>{errors.confirmPassword.message}</span>
                          )}
                        </small>
                      </div>
                    </div>
                    <div>
                      <button
                        disabled={showSuccessMessage}
                        type="submit"
                        className="mb-5 w-full flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium text-white bg-primary-green hover:bg-primary-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      >
                        Save
                      </button>
                      {showErrorMessage && (
                        <span className="text-red-500">
                          Invalid user details.
                        </span>
                      )}
                      {showSuccessMessage && (
                        <span className="text-green-500">
                          You have succesfully changed your password.{" "}
                          <a
                            className="text-primary-violet font-bold"
                            href={`${RouteConstant.ROUTE_LOGIN}`}
                          >
                            Click here
                          </a>{" "}
                          to login.
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="hidden lg:block relative w-0 flex-1 bg-gradient-to-l from-sky-800 to-cyan-700 mix-blend-multiply">
              <img
                className="hidden absolute inset-0 h-full w-full object-cover bg-gradient-to-l from-sky-800 to-cyan-700 mix-blend-multiply"
                src={`${Configuration.S3bucketImagePath}/images/teacherfree_email.jpg`}
                alt=""
              />
            </div>
          </div>
        </form>
      )}
    </>
  );
}

export default UserRegistration;

import { AxiosHelper } from "../../environment/axiosConfig";
import APIConstant from "../../utils/constant/apiConstant";

export const getLiftoffGalaxyStarReport = (
  schoolId: number,
  subjectId: number,
  pageNumber: number,
  pageSize: number,
  alienRankingCardId: number | undefined,
  classId?: number | undefined,
  gradeId?: number | undefined,
  roleId?: number
) => {
  return AxiosHelper.axiosInstance().get(
    APIConstant.LIFTOFF_GALAXYSTARREPORT_API.GetGALAXYSTARReport,
    {
      params: {
        schoolId: schoolId,
        subjectId: subjectId,
        alienRankingCardId: alienRankingCardId,
        classId: classId,
        gradeId: gradeId,
        pageNumber: pageNumber,
        pageSize: pageSize,
        roleId: roleId,
      },
    }
  );
};

export const exportLiftoffGalaxyStarReportExcel = (
  schoolId: number,
  subjectId: number,
  pageNumber: number,
  pageSize: number,
  alienRankingCardId: number | undefined,
  subjectName: string,
  filters: string,
  classId?: number | undefined,
  gradeId?: number | undefined,
  roleId?: number
) => {
  return AxiosHelper.axiosInstance().get(
    APIConstant.LIFTOFF_GALAXYSTARREPORT_API.GetGALAXYSTARReportExcel,
    {
      responseType: "blob",
      params: {
        schoolId: schoolId,
        subjectId: subjectId,
        alienRankingCardId: alienRankingCardId,
        classId: classId,
        gradeId: gradeId,
        subjectName: subjectName,
        filters: filters,
        pageNumber: pageNumber,
        pageSize: pageSize,
        roleId: roleId,
      },
    }
  );
};

export const exportLiftoffGalaxyStarReportCertificates = (
  schoolId: number,
  subjectId: number,
  pageNumber: number,
  pageSize: number,
  alienRankingCardId: number | undefined,
  classId?: number | undefined,
  gradeId?: number | undefined
) => {
  return AxiosHelper.axiosInstance().get(
    APIConstant.LIFTOFF_GALAXYSTARREPORT_API.GetGALAXYSTARReportCertificates,
    {
      responseType: "blob",
      params: {
        schoolId: schoolId,
        subjectId: subjectId,
        alienRankingCardId: alienRankingCardId,
        classId: classId,
        gradeId: gradeId,
        pageNumber: pageNumber,
        pageSize: pageSize,
      },
    }
  );
};

import classNames from "classnames";
import moment from "moment";
import React from "react";
import { Fragment } from "react";
import { DownArrowIcon, UpArrow } from "../../../../../../assets/icons";
import DotRank from "../../../../../shared/widgets/dotRank";
import constant from "../../../../../../utils/constant/constant";

interface resultReportBySchoolPrintProps {
  schoolMatrixResult: any;
  redirectedFrom: string;
  overallLabel: string;
  overallScore: number;
  isDistrictUser: boolean;
  header: {
    name: string;
    activity: string;
    author: string;
    subject: string;
    dateCreated: any;
    dueDate: any;
    schoolName: string;
    className: string;
  };
  resultHeader: any;
  resultSubHeader: any;
  isDomainActive: boolean;
  showSubHeader: boolean;
  isSubHeaderDomainActive: boolean;
}

export const ResultReportBySchoolPrint = React.forwardRef(
  (props: resultReportBySchoolPrintProps, ref: any) => {
    const {
      schoolMatrixResult,
      redirectedFrom,
      overallLabel,
      overallScore,
      isDistrictUser,
      header,
      resultSubHeader,
      resultHeader,
      isDomainActive,
      showSubHeader,
      isSubHeaderDomainActive,
    } = props;

    const getPercentage = (pointsEarned, pointsPossible) => {
      if (!pointsPossible) return 0;
      return ((pointsEarned / pointsPossible) * 100).toFixed();
    };

    return (
      <Fragment>
        <div ref={ref}>
          <div className="px-2 sm:px-6  lg:px-8 py-1 relative my-4">
            <div className="w-full rounded-lg py-5  font-semibold text-2xl text-gray-700 mb-8">
              <div className="bg-gray-100 py-2 grid grid-cols-1 md:grid-cols-12 text-gray-700 md:space-x-4 px-4">
                <div className="px-2 md:px-0 md:col-span-6 2xl:col-span-4 xxxl:col-span-3 space-y-3">
                  {overallLabel} Average{" "}
                  <span className="text-primary-violet ">
                    {" "}
                    {overallScore.toFixed()}%
                  </span>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-9 text-gray-700 md:space-x-4">
              <div className="px-2 md:px-0 col-span-5 space-y-3">
                <div className="flex w-full">
                  <div className="w-40 font-semibold flex-none">Name</div>
                  <div>{header.name}</div>
                </div>
                <div className="flex w-full">
                  <div className="w-40 font-semibold flex-none">Activity</div>
                  <div>{header.activity}</div>
                </div>
                <div className="flex w-full">
                  <div className="w-40 font-semibold flex-none">Author</div>
                  <div>{header.author}</div>
                </div>
                <div className="flex w-full">
                  <div className="w-40 font-semibold flex-none">Subject</div>
                  <div>{header.subject}</div>
                </div>
                <div className="flex w-full">
                  <div className="w-40 font-semibold flex-none">
                    Date Created
                  </div>
                  <div className="flex flex-wrap">
                    <span className="bg-gray-100 rounded-lg inline-block px-2 mr-2">
                      {}
                      {header?.dateCreated
                        ? moment(header?.dateCreated).format("MM/DD/YYYY")
                        : "N/A"}
                    </span>
                  </div>
                </div>

                <div className="flex w-full">
                  <div className="w-40 font-semibold flex-none">Due Date</div>
                  <div>
                    {" "}
                    {header?.dueDate
                      ? moment(header?.dueDate).format("MM/DD/YYYY")
                      : "N/A"}
                  </div>
                </div>
                <div className="flex w-full">
                  <div className="w-40 font-semibold flex-none">{`${
                    isDistrictUser ? "School" : "Class"
                  }`}</div>
                  <div>
                    {isDistrictUser ? header.schoolName : header.className}
                  </div>
                </div>
              </div>
              {/* Header */}
              {!showSubHeader && (
                <div className="mt-4 md:mt-0 col-span-4 bg-gray-50 shadow-lg py-4 px-6 space-y-3">
                  <div className="inline-flex inline-block border text-sm border-gray-300 rounded-md overflow-hidden">
                    <span
                      className={`${
                        isDomainActive
                          ? "bg-secondary-teal text-white "
                          : "bg-white text-gray-400"
                      } w-36 inline-block py-0.5 text-center cursor-pointer hover:opacity-80`}
                    >
                      Scores by Domain
                    </span>
                    <span
                      className={`${
                        !isDomainActive
                          ? "bg-secondary-teal text-white "
                          : "bg-white text-gray-400"
                      } w-36 inline-block py-0.5 text-center cursor-pointer hover:opacity-80`}
                    >
                      View By Standard
                    </span>
                  </div>
                  <div>
                    {isDomainActive && (
                      <div className="domain">
                        {resultHeader?.subjects?.map((subject, subIndex) =>
                          subject?.domains?.map((domain, index) => {
                            return (
                              <div className="mt-2">
                                <div className="text-blue-400 mb-1">
                                  {domain.name}
                                </div>
                                <div className="flex items-center w-full">
                                  <div className="text-xl font-semibold text-gray-500">
                                    {getPercentage(
                                      domain.pointsEarned,
                                      domain.pointsPossible
                                    )}
                                    %
                                  </div>
                                  <div className="bg-gray-200 w-full ml-2 h-5 flex rounded-lg overflow-hidden">
                                    <div
                                      className="w-[50%] bg-primary-violet"
                                      style={{
                                        width:
                                          getPercentage(
                                            domain.pointsEarned,
                                            domain.pointsPossible
                                          ) + "%",
                                      }}
                                    ></div>
                                    <div className="bg-[#82c95e]"></div>
                                  </div>
                                </div>
                              </div>
                            );
                          })
                        )}
                      </div>
                    )}
                    {!isDomainActive && (
                      <div className="standard">
                        {resultHeader?.subjects?.map((subject, subIndex) =>
                          subject.domains?.map((domain, _index) => {
                            return (
                              <div className="mt-4 p-3 pb-4 rounded-md border border-blue-400">
                                <div className="text-xl text-blue-400 mb-3 mt-1">
                                  {domain.name}
                                </div>
                                {domain.standards?.map((standard, index) => {
                                  return (
                                    <div className="mt-2">
                                      <div className="text-blue-400 mb-1">
                                        {standard.name}
                                      </div>
                                      <div className="flex items-center w-full">
                                        <div className="text-xl font-semibold text-gray-500">
                                          {getPercentage(
                                            standard.pointsEarned,
                                            standard.pointsPossible
                                          )}
                                          %
                                        </div>
                                        <div className="bg-gray-200 w-full ml-2 h-5 flex rounded-lg overflow-hidden">
                                          <div
                                            className="w-[70%] bg-primary-violet"
                                            style={{
                                              width:
                                                getPercentage(
                                                  standard.pointsEarned,
                                                  standard.pointsPossible
                                                ) + "%",
                                            }}
                                          ></div>
                                          <div className="bg-[#82c95e]"></div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            );
                          })
                        )}
                      </div>
                    )}
                  </div>
                </div>
              )}
              {/* Sub Header */}
              {showSubHeader && (
                <div className="mt-4 md:mt-0 md:col-span-6 2xl:col-span-5 xxxl:col-span-4 bg-gray-50 shadow-lg py-4 px-6 space-y-3">
                  <div className="inline-flex inline-block border text-sm border-gray-300 rounded-md overflow-hidden">
                    <span
                      className={`${
                        isSubHeaderDomainActive
                          ? "bg-secondary-teal text-white "
                          : "bg-white text-gray-400"
                      } w-36 inline-block py-0.5 text-center cursor-pointer hover:opacity-80`}
                    >
                      Scores by Domain
                    </span>
                    <span
                      className={`${
                        !isSubHeaderDomainActive
                          ? "bg-secondary-teal text-white "
                          : "bg-white text-gray-400"
                      } w-36 inline-block py-0.5 text-center cursor-pointer hover:opacity-80`}
                    >
                      View By Standard
                    </span>
                  </div>
                  <div>
                    {isSubHeaderDomainActive && (
                      <div className="domain">
                        {resultSubHeader?.subjects?.map((subject, subIndex) =>
                          subject?.domains?.map((domain, index) => {
                            return (
                              <div className="mt-2">
                                <div className="text-blue-400 mb-1">
                                  {domain.name}
                                </div>
                                <div className="flex items-center w-full">
                                  <div className="text-xl font-semibold text-gray-500">
                                    {getPercentage(
                                      domain.pointsEarned,
                                      domain.pointsPossible
                                    )}
                                    %
                                  </div>
                                  <div className="bg-gray-200 w-full ml-2 h-5 flex rounded-lg overflow-hidden">
                                    <div
                                      className="w-[50%] bg-primary-violet"
                                      style={{
                                        width:
                                          getPercentage(
                                            domain.pointsEarned,
                                            domain.pointsPossible
                                          ) + "%",
                                      }}
                                    ></div>
                                    <div className="bg-[#82c95e]"></div>
                                  </div>
                                </div>
                              </div>
                            );
                          })
                        )}
                      </div>
                    )}
                    {!isSubHeaderDomainActive && (
                      <div className="standard">
                        {resultSubHeader?.subjects?.map((subject, subIndex) =>
                          subject.domains?.map((domain, index) => {
                            return (
                              <div className="mt-4 p-3 pb-4 rounded-md border border-blue-400">
                                <div className="text-xl text-blue-400 mb-3 mt-1">
                                  {domain.name}
                                </div>
                                {domain.standards?.map((standard, index) => {
                                  return (
                                    <div className="mt-2">
                                      <div className="text-blue-400 mb-1">
                                        {standard.name}
                                      </div>
                                      <div className="flex items-center w-full">
                                        <div className="text-xl font-semibold text-gray-500">
                                          {getPercentage(
                                            standard.pointsEarned,
                                            standard.pointsPossible
                                          )}
                                          %
                                        </div>
                                        <div className="bg-gray-200 w-full ml-2 h-5 flex rounded-lg overflow-hidden">
                                          <div
                                            className="w-[70%] bg-primary-violet"
                                            style={{
                                              width:
                                                getPercentage(
                                                  standard.pointsEarned,
                                                  standard.pointsPossible
                                                ) + "%",
                                            }}
                                          ></div>
                                          <div className="bg-[#82c95e]"></div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            );
                          })
                        )}
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="pt-56 ml-4">
            <table className="border-b border-primary-violet">
              <tr className="border-b border-primary-violet">
                <th className="py-1 text-left pl-2 bg-white">
                  <span className="text-primary-violet text-base font-semibold">
                    School
                  </span>
                </th>

                <th
                  className="relative"
                  title={schoolMatrixResult.activity?.name}
                >
                  <p className="w-[271px] absolute top-[-5.25rem] left-[-0.75rem] transform -rotate-45 whitespace-nowrap flex items-center text-md font-bold">
                    {schoolMatrixResult.activity?.name?.length > 40
                      ? schoolMatrixResult.activity?.name?.substring(0, 40) +
                        "..."
                      : schoolMatrixResult.activity?.name}
                    <div>
                      {!schoolMatrixResult.activity.isCollapse && (
                        <UpArrow className="w-5 mr-2 cursor-pointer text-green-600" />
                      )}
                      {schoolMatrixResult.activity.isCollapse && (
                        <DownArrowIcon className="w-5 mr-2 cursor-pointer text-green-600" />
                      )}
                    </div>
                  </p>
                </th>

                {!schoolMatrixResult.activity.isCollapse &&
                  schoolMatrixResult.activity?.subjects?.map(
                    (subject, subjectIndex) => (
                      <>
                        {!schoolMatrixResult.activity.isCollapse &&
                          !subject.isCollapse &&
                          subject.domains.map((domain, domainIndex) => (
                            <>
                              <th className="relative" title={domain.name}>
                                <p className="w-[271px] absolute top-[-5.25rem] left-[-0.75rem] transform -rotate-45 whitespace-nowrap flex items-center text-sm font-medium">
                                  {domain.name.length > 40
                                    ? domain.name.substring(0, 40) + "..."
                                    : domain.name}
                                  <div>
                                    {!domain.isCollapse && (
                                      <UpArrow className="w-5 mr-2 cursor-pointer text-red-600" />
                                    )}
                                    {domain.isCollapse && (
                                      <DownArrowIcon className="w-5 mr-2 cursor-pointer text-red-600" />
                                    )}
                                  </div>
                                </p>
                              </th>

                              {!schoolMatrixResult.activity.isCollapse &&
                                !subject.isCollapse &&
                                !domain.isCollapse &&
                                domain.standards.map(
                                  (standard, standardIndex) => (
                                    <th
                                      className="relative"
                                      title={standard.displayName}
                                    >
                                      <p className="w-[271px] absolute top-[-5.25rem] left-[-0.75rem] transform -rotate-45 whitespace-nowrap flex items-center text-xs font-normal">
                                        {standard.displayName?.length > 40
                                          ? standard.displayName.substring(
                                              0,
                                              40
                                            ) + "..."
                                          : standard.displayName}
                                      </p>
                                    </th>
                                  )
                                )}
                            </>
                          ))}
                      </>
                    )
                  )}
              </tr>

              {schoolMatrixResult.schools.map((school, schoolIndex) => (
                <>
                  <tr
                    className={
                      "border-l border-r border-primary-violet " +
                      (schoolIndex % 2 !== 0 ? "bg-primary-violet/10" : "")
                    }
                  >
                    <td className="whitespace-nowrap bg-white">
                      <div
                        className={classNames(
                          schoolIndex === 0 ? "font-semibold " : "",
                          redirectedFrom !== constant.RedirectedFrom.MYASSESSMENTS &&
                            schoolIndex !== 0
                            ? "underline cursor-pointer text-blue-600 "
                            : "",
                          "flex items-center justify-start w-full  px-2 "
                        )}
                      >
                        {school.name}
                      </div>
                    </td>
                    {school.pctCorrect?.length
                      ? school.pctCorrect.map(
                          (pct, index) =>
                            school.visibility[index] && (
                              <td
                                className={
                                  "text-center p-3 text-sm font-normal " +
                                  (schoolMatrixResult.contentTypes[index] === 1
                                    ? "border-l border-gray-500"
                                    : "")
                                }
                              >
                                <DotRank
                                  displayScore={true}
                                  value={
                                    !pct && pct !== 0
                                      ? undefined
                                      : Math.round(pct * 100)
                                  }
                                ></DotRank>
                              </td>
                            )
                        )
                      : [
                          ...Array(
                            schoolMatrixResult.schools[0].pctCorrect.length - 1
                          ),
                        ].map((e, i) => (
                          <td
                            key={i}
                            className={`text-center opacity-0 p-3 text-sm font-normal ${
                              i === 0 ? "border-r border-gray-500" : ""
                            }`}
                          >
                            -
                          </td>
                        ))}
                  </tr>
                </>
              ))}
            </table>
          </div>
        </div>
      </Fragment>
    );
  }
);

import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { ChevronRightIcon } from "@heroicons/react/solid";
import RouteConstant from "../../../../utils/constant/routeConstant";
import { StudentArenaIcon, WaitIcon } from "../../../../assets/icons";
import { Configuration } from "../../../../environment/setup";

function LaunchAlienArena(props: any) {
  return (
    <div className="min-w-0 w-full">
      <div className="px-4 py-5 lg:px-8 border-b">
        <ol className="flex items-center space-x-2 mb-1">
          <li>
            <Link to={RouteConstant.TeacherRoutes.AlienArenaSessions}>
              <div className="flex items-center">
                <div className="cursor-pointer text-sm text-gray-500 hover:text-gray-700 border-dashed border-b border-gray-400 hover:border-opacity-0">
                  Alien Arena
                </div>
              </div>
            </Link>
          </li>
          <li>
            <div className="flex items-center">
              <ChevronRightIcon className="flex-shrink-0 h-5 w-5 text-gray-400" />
              <span className="ml-1 text-sm text-gray-500 capitalize">
                Launch Session
              </span>
            </div>
          </li>
        </ol>
        <div className=" text-lg font-medium flex gap-2 h-auto sm:h-10 items-center">
          <h1 className="text-primary-violet flex items-center justify-between w-full pr-4">
            Launch Session
          </h1>
        </div>
      </div>
      <div className="px-2 sm:px-6 lg:px-8 py-6">
        <div className="w-full flex flex-col lg:flex-row gap-x-6">
          <div className="w-full lg:w-1/2">
            <div className="border-2 rounded-lg border-yellow-400 bg-yellow-50 p-3 space-y-2 text-center">
              <p className="text-base text-yellow-600">
                Other students have not yet joined.
              </p>
              <div className="flex items-center justify-center">
                <span className="bg-yellow-500 w-7 h-7 rounded-full flex items-center justify-center mr-1.5">
                  <WaitIcon className="w-4 fill-current text-white"></WaitIcon>
                </span>
                <h1 className="text-2xl font-semibold text-yellow-500">
                  Please Wait
                </h1>
              </div>
              <p className="text-xs text-yellow-700">
                You have sucessfully joined. Please wait for your teacher to
                start the session.
              </p>
            </div>
            <div className="shadow-lg flex flex-col xl:flex-row mt-4 border rounded-md relative overflow-hidden">
              <img
                src={`${Configuration.S3bucketImagePath}/images/AlienNinja.png`}
                className="absolute bottom-0 right-5 w-44 xl:w-48"
                alt="Alien Ninja"
              />
              <div className="w-full xl:w-2/3 space-y-3 p-5">
                <div>
                  <span className="text-gray-500 rounded-sm mb-1 block text-sm">
                    Alien Arena
                  </span>
                  <p className="text-xs text-gray-500">
                    Wait for each of your students to come “online”. Their tiles
                    will be highlighted on the tiles to the right when they are
                    ready to begin. Starting a session before each student is
                    highlighted will omit the “offline” students from the round.
                    Once your session begins, students are required to answer
                    questions individually and the team that gets the highest
                    percent of students with a correct answer wins the round and
                    gets to play the game.
                  </p>
                </div>
                <div>
                  <span className="text-gray-500 rounded-sm block text-sm">
                    Session Name
                  </span>
                  <p className="text-sm text-gray-700 inline-block font-semibold">
                    Test 2
                  </p>
                </div>
                <div>
                  <span className="text-gray-500 rounded-sm block text-sm">
                    Grade
                  </span>
                  <p className="text-sm text-gray-700 inline-block font-semibold">
                    Kth Grade
                  </p>
                </div>
                <div>
                  <span className="text-gray-500 rounded-sm block text-sm">
                    Subject
                  </span>
                  <p className="text-sm text-gray-700 inline-block font-semibold">
                    STAAR Math
                  </p>
                </div>
                <div>
                  <span className="text-gray-500 rounded-sm mb-1 block text-sm">
                    Topic
                  </span>
                  <p className="text-xs text-white bg-primary-violet rounded-md inline-block px-1 py-0.5">
                    Numericals
                  </p>
                </div>
              </div>
              <div className="w-full xl:w-1/3 relative flex flex-col items-center justify-between order-first xl:order-last">
                <div className="flex flex-col items-center bg-gray-100 py-6 w-full rounded-bl-lg">
                  <span className="text-gray-700 text-xl mb-0.5">3/4</span>
                  <span className="bg-green-500 px-2 text-xs rounded-md text-white flex items-center">
                    Online
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full lg:w-1/2 bg-gray-100 p-6 rounded-md order-first lg:order-last">
            <div className="grid md:grid-cols-2 lg:grid-cols-1 xl:grid-cols-2 gap-6">
              <div className="rounded-md shadow p-5 bg-white">
                <div className="flex justify-between">
                  <div className="w-12 h-12 min-h-full rounded-md bg-primary-violet/20 flex items-center justify-center mr-2.5 px-1.5">
                    <div className="w-8 h-8 rounded-full bg-white flex items-center justify-center">
                      <StudentArenaIcon className="w-6 fill-current text-primary-violet"></StudentArenaIcon>
                    </div>
                  </div>
                  <div className="w-full ">
                    <span className="bg-green-500 px-2 text-xs rounded-md text-white float-right">
                      Online
                    </span>
                    <h2 className="text-sm text-gray-700">Malu Nisanth</h2>
                    <span className="text-xs text-primary-violet">
                      Asteroids
                    </span>
                  </div>
                </div>
              </div>

              <div className="rounded-md shadow p-5 bg-white">
                <div className="flex justify-between">
                  <div className="w-12 h-12 min-h-full rounded-md bg-primary-violet/20 flex items-center justify-center mr-2.5 px-1.5">
                    <div className="w-8 h-8 rounded-full bg-white flex items-center justify-center">
                      <StudentArenaIcon className="w-6 fill-current text-primary-violet"></StudentArenaIcon>
                    </div>
                  </div>
                  <div className="w-full ">
                    <span className="bg-green-500 px-2 text-xs rounded-md text-white float-right">
                      Online
                    </span>
                    <h2 className="text-sm text-gray-700">Sachin Landage</h2>
                    <span className="text-xs text-primary-violet">
                      Starburst
                    </span>
                  </div>
                </div>
              </div>

              <div className="rounded-md shadow p-5 bg-white">
                <div className="flex justify-between">
                  <div className="w-12 h-12 min-h-full rounded-md bg-primary-violet/20 flex items-center justify-center mr-2.5 px-1.5">
                    <div className="w-8 h-8 rounded-full bg-white flex items-center justify-center">
                      <StudentArenaIcon className="w-6 fill-current text-primary-violet"></StudentArenaIcon>
                    </div>
                  </div>
                  <div className="w-full ">
                    <span className="bg-green-500 px-2 text-xs rounded-md text-white float-right">
                      Online
                    </span>
                    <h2 className="text-sm text-gray-700">Rohit Sharma</h2>
                    <span className="text-xs text-primary-violet">
                      Asteroids
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-8 text-center">
          <button
            className="bg-primary-violet border border-transparent shadow-sm mt-4 sm:mt-0 py-2 px-4 justify-center text-sm font-medium text-white hover:bg-dark-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            type="button"
          >
            Start Session
          </button>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(LaunchAlienArena);

import { Fragment, useEffect, useState } from "react";
import Moment from "react-moment";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { getAllAlienArenaSessionsByStudent } from "../../../../../api/teacher/alienArena";
import { RefreshIcon } from "../../../../../assets/icons";
import PagingResponse from "../../../../../model/common/pagingResponse";
import AlienArenaDetails from "../../../../../model/student/alienArenaDetails";
import { UserContextState } from "../../../../../redux/actions/userContextAction";
import {
  DEFAULT_PAGE_NUMBER,
  DEFAULT_PAGE_SIZE,
} from "../../../../../utils/pagingConstant";
import Loader from "../../../../shared/loader";

interface AlienArenaProps {
  userContext?: UserContextState;
  userId: number;
  roleId: number;
}

function AlienArenaElementary(props: AlienArenaProps) {
  const { t } = useTranslation();
  const [loading, showLoading] = useState<boolean>(false);
  const pageNumber = DEFAULT_PAGE_NUMBER;
  const pageSize = DEFAULT_PAGE_SIZE;
  const [alienArenaSessions, setAlienArenaSessions] =
    useState<PagingResponse<AlienArenaDetails>>();

  const getAlienArenaSessionsList = () => {
    showLoading(true);
    getAllAlienArenaSessionsByStudent(props.userContext!.userId)
      .then((d) => {
        setAlienArenaSessions(d.data);
        showLoading(false);
      })
      .catch(() => {});
  };
  const handleRefresh = () => {
    getAlienArenaSessionsList();
  };
  useEffect(() => {
    getAlienArenaSessionsList();
  }, [pageNumber, pageSize]);

  return (
    <Fragment>
      {loading && <Loader />}

      <div className="min-w-0 block lg:col-span-9 xl:col-span-10 w-full">
        <div className="w-full">
          <div className="flex flex-col md:flex-row items-end md:items-center md:justify-between justify-start mb-4">
            <div className="p-1"></div>
            <div className="grid grid-cols-1 gap-4">
              <button
                onClick={() => {
                  handleRefresh();
                }}
                type="submit"
                className="flex items-center mx-auto justify-center bg-white w-24 border rounded-lg p-1 hover:border-gray-500 space-x-0.5"
              >
                <RefreshIcon className="w-5 pr-1" />
                Refresh
              </button>
            </div>
          </div>
          <div>
            <div className="shadow overflow-x-auto rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr className="px-3 py-3 text-left text-xs font-medium text-gray-500  tracking-wider">
                    <th className="text-left p-3 text-sm font-normal">
                      {t("AlienArena.Session")} {t("AlienArena.Name")}
                    </th>
                    <th className="text-left p-3 text-sm font-normal">
                      {t("AlienArena.Subject")}
                    </th>
                    <th className="text-left p-3 text-sm font-normal">
                      Team Name
                    </th>
                    <th className="text-left p-3 text-sm font-normal">
                      Start Date
                    </th>
                    <th className="text-left p-3 text-sm font-normal">
                      {t("AlienArena.Score")}
                    </th>
                    <th className="text-left p-3 text-sm font-normal"></th>
                  </tr>
                </thead>
                <tbody>
                  {alienArenaSessions &&
                    alienArenaSessions!.data.length > 0 &&
                    alienArenaSessions.data?.map((session, index) => {
                      return (
                        <Fragment>
                          <tr
                            key={index}
                            className={
                              index % 2 === 0
                                ? "bg-white"
                                : "bg-white bg-opacity-80"
                            }
                            // onMouseOver={() => {
                            //   setHoverElement("row-" + session.id);
                            // }}
                          >
                            <td className="text-sm p-3 ">
                              {session.sessionName}
                            </td>

                            <td className="text-sm p-3 ">
                              {session.sessionName}
                            </td>
                            <td className="text-sm p-3 ">{session.subject}</td>
                            <td className="text-sm p-3 ">
                              {<Moment>{session.startDate}</Moment>}
                            </td>
                            <td className="text-sm p-3 "><>{session.score}</></td>
                            <td className="text-sm p-3 ">
                              <span className="py-0.5 px-1 text-xs rounded-lg text-white">
                                {session.statusName}
                              </span>
                            </td>
                          </tr>
                        </Fragment>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(AlienArenaElementary);

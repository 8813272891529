import * as userContextAction from "../../../redux/actions/userContextAction";
import { login } from "../../../api/login.service";
import Constant from "../../../utils/constant/constant";
import RouteConstant from "../../../utils/constant/routeConstant";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { fetchProfile } from "../../../redux/actions/userActions";
import { connect } from "react-redux";
import Loader from "../../shared/loader";
import { ICanvasLoginState } from "../../../model/canvasClassroom/canvasloginState";
import {
  getAssignmentById,
  getAssignmentStatus,
} from "../../../api/teacher/assignment";
import { getActivityName } from "../../../utils/assignmentHelper";
import { CanvasApi } from "../../../api/canvas/canvasApi";
import { paramEncode } from "../../../utils/urlHelper";
import {
  handleRedirection,
  validateStudentAssignment,
} from "../../../utils/helper";
import InformationDialog from "../../shared/informationDialog";
import moment from "moment";
import { jwtToken } from "../../shared/tokenHelper";

const ExternalCanvasLogin = (props: any) => {
  const history = useHistory();
  const code = new URLSearchParams(props.location.search).get("code");
  const stateData = new URLSearchParams(props.location.search).get("state")!;
  const state = JSON.parse(
    atob(decodeURI(stateData).replaceAll(" ", "+"))
  ) as ICanvasLoginState;
  const [InformationPopup, setInformationPopup] = useState<any>();

  useEffect(() => {
    if (code) {
      handleLogin();
    } else {
      history.push(RouteConstant.ROUTE_LOGIN);
    }
  }, []);

  const handleAutoMapping = (canvasId: string, canvasLoginId: string) => {
    const accountLoginName = state.Credentials?.AccountLoginName!;
    const username = state.Credentials?.Username!;
    const passowrd = atob(state.Credentials?.PassowrdHash!);
    CanvasApi.addCanvasUser(
      accountLoginName,
      username,
      passowrd,
      canvasId,
      canvasLoginId
    ).then((r) => {
      if (r.data > 0) {
        handleLoginByCanvasLoginId(canvasLoginId);
      }
    });
  };

  const handleLogin = () => {
    login(
      "none",
      "none",
      state.AccountLoginName,
      0,
      "",
      "",
      Constant.LoginType.CANVAS,
      code!,
      false
    ).then((response) => {
      const canvasData = response.data["canvasUserInfo"];
      const settings = {
        accountLoginName: state.AccountLoginName,
        clientId: state.ClientId,
        endPointURL: state.EndPointURL,
        defaultToken: state.DefaultToken,
        adminAccountId: state.AdminAccountId,
        expiresIn: canvasData.ExpiresIn,
        lastLogin: moment(),
      };
      localStorage.setItem("CanvasAccountSettings", JSON.stringify(settings));

      if (!canvasData.IsUserMapped) {
        // mapping
        const canvasAccessToken = canvasData.AccessToken;
        localStorage.setItem("CanvasAccessToken", canvasAccessToken);
        const canvasLoginId = canvasData.LoginId;
        const canvasId = canvasData.Id;

        if (state.Credentials != null) {
          handleAutoMapping(canvasId.toString(), canvasLoginId);
        } else {
          history.push(
            RouteConstant.CANVASUSERMAPPING.replace(
              ":canvasLoginId",
              encodeURI(btoa(canvasLoginId))
            ).replace(":canvasId", encodeURI(btoa(canvasId)))
          );
        }
      } else {
        const authToken = response.data["access_token"];
        const canvasAccessToken = canvasData.AccessToken;

        localStorage.setItem("AuthToken", authToken);
        localStorage.setItem("CanvasAccessToken", canvasAccessToken);
        const user: any = jwtToken(authToken);
        const userId = isNaN(user.userId) ? 0 : parseInt(user.userId);
        const roleId = isNaN(user.roleId) ? 0 : parseInt(user.roleId);
        const gradeId = isNaN(user.gradeId) ? 0 : parseInt(user.gradeId);
        const accountId = isNaN(user.accountId) ? 0 : parseInt(user.accountId);
        const districtId = isNaN(user.districtId)
          ? 0
          : parseInt(user.districtId);
        const schoolId =
          isNaN(user.schoolId) || user.schoolId === ""
            ? 0
            : parseInt(user.schoolId);
        const schoolAccountId =
          isNaN(user.schoolAccountId) || user.schoolAccountId === ""
            ? 0
            : parseInt(user.schoolAccountId);
        const stateId = isNaN(user.stateId) ? 0 : parseInt(user.stateId);
        const linkedUserId = user.linkedUserId;
        var context: userContextAction.UserContextState = {
          userId: userId,
          roleId: roleId,
          gradeId: gradeId,
          schoolId: schoolId,
          districtId: districtId,
          accountId: accountId,
          stateId: stateId,
          googleId: user.googleId,
          activeExternalRoster: Constant.ExterRosterType.CANVAS,
          schoolAccountId: schoolAccountId,
          impersonatedUser: null,
          linkedUserId: user.linkedUserId,
        };

        props.setUserContext(context);
        props.setProfile(userId, roleId, schoolId, 0);
        handleUserRedirection(userId, roleId, gradeId, linkedUserId);
      }
    });
  };

  const handleLoginByCanvasLoginId = (canvasLoginId: string) => {
    login(
      "none",
      "none",
      state.AccountLoginName,
      0,
      "",
      canvasLoginId,
      Constant.LoginType.CANVAS,
      "",
      false
    ).then((response) => {
      if (response) {
        const authToken = response.data["access_token"];
        localStorage.setItem("AuthToken", authToken);
        const user: any = jwtToken(authToken);
        const userId = isNaN(user.userId) ? 0 : parseInt(user.userId);
        const roleId = isNaN(user.roleId) ? 0 : parseInt(user.roleId);
        const gradeId = isNaN(user.gradeId) ? 0 : parseInt(user.gradeId);
        const accountId = isNaN(user.accountId) ? 0 : parseInt(user.accountId);
        const districtId = isNaN(user.districtId)
          ? 0
          : parseInt(user.districtId);
        const schoolId =
          isNaN(user.schoolId) || user.schoolId === ""
            ? 0
            : parseInt(user.schoolId);
        const schoolAccountId =
          isNaN(user.schoolAccountId) || user.schoolAccountId === ""
            ? 0
            : parseInt(user.schoolAccountId);
        const stateId = isNaN(user.stateId) ? 0 : parseInt(user.stateId);
        var context: userContextAction.UserContextState = {
          userId: userId,
          roleId: roleId,
          gradeId: gradeId,
          schoolId: schoolId,
          districtId: districtId,
          accountId: accountId,
          stateId: stateId,
          googleId: user.googleId,
          activeExternalRoster: Constant.ExterRosterType.CANVAS,
          schoolAccountId: schoolAccountId,
          impersonatedUser: null,
          linkedUserId: user.linkedUserId,
        };

        props.setUserContext(context);
        props.setProfile(userId, roleId, schoolId, 0);
        handleUserRedirection(userId, roleId, gradeId, user.linkedUserId);
      }
    });
  };

  const handleUserRedirection = (
    userId: number,
    roleId: number,
    gradeId: number = 0,
    linkedUserId: string | null
  ) => {
    localStorage.setItem("programMode", Constant.ProgramMode.EG);
    if (
      roleId === Constant.UserRoleId.SchoolAdmin ||
      roleId === Constant.UserRoleId.PayTeacher ||
      roleId === Constant.UserRoleId.FreeTeacher ||
      roleId === Constant.UserRoleId.SchoolTeacher ||
      roleId === Constant.UserRoleId.District ||
      roleId === Constant.UserRoleId.SuperAdmin ||
      roleId === 0
    ) {
      handleTeacherRedirection(gradeId, roleId, linkedUserId);
    } else if (roleId === Constant.UserRoleId.Student) {
      handleStudentRedirection(userId, gradeId, roleId, linkedUserId);
    } else {
      history.push(RouteConstant.TeacherRoutes.assignmentCenter);
    }
  };

  const handleTeacherRedirection = (
    gradeId: number,
    roleId: number,
    linkedUserId: string | null
  ) => {
    if (state.AssignmentId > 0 && state.AssignmentActivityId > 0) {
      handleTeacherAssignmentRedirection();
    } else {
      if (state.ReturnUrl) {
        history.push(state.ReturnUrl);
      } else {
        handleRedirection(
          roleId,
          gradeId,
          linkedUserId ?? "",
          history,
          Constant.ExterRosterType.CANVAS
        );
      }
    }
  };

  const handleStudentRedirection = (
    userId: number,
    gradeId: number,
    roleId: number,
    linkedUserId: string | null
  ) => {
    if (state.AssignmentId === 0 && state.AssignmentActivityId === 0) {
      if (state.ReturnUrl) {
        history.push(state.ReturnUrl);
      } else {
        handleRedirection(
          roleId,
          gradeId,
          linkedUserId ?? "",
          history,
          Constant.ExterRosterType.CANVAS
        );
      }
    } else {
      handleStudentAssignmentRedirection(userId, gradeId, roleId);
    }
  };

  const getAssignmentRoute = (gradeId: number) => {
    if (gradeId <= Constant.Grade.GRADE1) {
      return RouteConstant.KindergartenStudentRoutes.StudentAssignmentActivity;
    } else if (
      gradeId > Constant.Grade.GRADE1 &&
      gradeId < Constant.Grade.GRADE6
    ) {
      return RouteConstant.StudentRoute.StudentAssignmentActivityEL;
    } else if (gradeId >= Constant.Grade.GRADE6) {
      return RouteConstant.MiddleSchool.StudentAssignmentActivityMS;
    } else {
      return RouteConstant.StudentRoute.StudentAssignmentActivityEL;
    }
  };

  const handleStudentAssignmentRedirection = (
    userId: number,
    gradeId: number,
    roleId: number
  ) => {
    getAssignmentStatus(state.AssignmentId, userId).then((r) => {
      if (r.data) {
        const response = validateStudentAssignment(r.data);
        if (response.isSuccess) {
          getAssignmentById(state.AssignmentId).then((r) => {
            const studentRoute = getAssignmentRoute(gradeId);

            const activity = r.data.activities.filter(
              (a) => a.assignmentActivityId === state.AssignmentActivityId
            )[0];

            startAssignmentActivity(
              r.data.subjectId,
              state.AssignmentActivityId,
              getActivityName(activity, props.profile?.plLanguageId ?? 1),
              activity.multipleAttempt,
              state.AssignmentId,
              r.data.activities,
              studentRoute,
              activity.learnosityIdEnglish,
              activity.learnosityIdSpanish,
              activity.activityType
            );
          });
        } else {
          setInformationPopup(
            <InformationDialog
              informationMessage={response.message}
              informationTitle={"Error"}
              isError={true}
              open={true}
              onOkClick={() => {
                handleRedirection(
                  roleId,
                  gradeId,
                  "",
                  history,
                  Constant.ExterRosterType.CANVAS
                );
              }}
            />
          );
        }
      }
    });
  };

  const handleTeacherAssignmentRedirection = () => {
    getAssignmentStatus(state.AssignmentId, null).then((r) => {
      if (r.data && r.data.isActive) {
        history.push(
          RouteConstant.EDITASSIGNMENT.replace(
            ":id",
            state.AssignmentId.toString()
          )
        );
      } else {
        setInformationPopup(
          <InformationDialog
            informationMessage={"This assignment is not active."}
            informationTitle={"Error"}
            isError={true}
            open={true}
            onOkClick={() => {
              history.push(RouteConstant.ROUTE_DASHBOARD);
            }}
          />
        );
      }
    });
  };

  function startAssignmentActivity(
    subjectId: number,
    assignmentActivityId: number,
    assignmentActivityName: string,
    multipleAttempt: number,
    assignmentId: number,
    activities: Array<any>,
    studentRoute: string,
    lernosityActivityIdEN?: string,
    lernosityActivityIdES?: string,
    assignmentType?: string
  ) {
    let multipleAttemptAllowed =
      multipleAttempt && multipleAttempt > 0 ? true : false;
    assignmentType = assignmentType ? assignmentType : "";
    const assignment = activities.filter(
      (a) => a.assignmentActivityId === assignmentActivityId
    )[0];
    if (lernosityActivityIdEN || lernosityActivityIdES) {
      history.push({
        pathname: studentRoute
          .replace(
            ":assignmentActivityId",
            encodeURI(btoa(assignmentActivityId.toString()))
          )
          .replace(
            ":assignmentActivityName",
            encodeURI(paramEncode(assignmentActivityName))
          )
          .replace(
            ":lernosityActivityIdEN",
            encodeURI(btoa(lernosityActivityIdEN ? lernosityActivityIdEN : ""))
          )
          .replace(
            ":lernosityActivityIdES",
            encodeURI(btoa(lernosityActivityIdES ? lernosityActivityIdES : ""))
          )
          .replace(":assignmentType", encodeURI(btoa(assignmentType)))
          .replace(":assignmentId", encodeURI(btoa(assignmentId.toString())))
          .replace(":subjectId", encodeURI(btoa(subjectId.toString())))
          .replace(
            ":multipleAttemptAllowed",
            encodeURI(btoa(multipleAttemptAllowed.toString()))
          ),
        state: {
          prebuildTestType:
            (assignment?.summativeTestId ?? 0) > 0
              ? (assignment?.summativeTestName ?? "") + " Practice Test"
              : (assignment?.domainId ?? 0) > 0
              ? "Practice Test - " + assignment?.domainName ?? ""
              : "",
        },
      });
    }
  }

  return (
    <>
      <Loader></Loader>
      {InformationPopup ?? InformationPopup}
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    profile: state.profile,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setUserContext: (payload: userContextAction.UserContextState) => {
      dispatch(userContextAction.setUserContext(payload));
    },
    setProfile: (
      userId: number,
      roleId: number,
      schoolId: number,
      districtId: number
    ) => {
      dispatch(fetchProfile(userId, roleId, schoolId, districtId));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExternalCanvasLogin);

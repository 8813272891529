import { IBreadcrumb } from "../../../../model/common/breadcrumb";
import { useTranslation } from "react-i18next";
import routeConstant from "../../../../utils/constant/routeConstant";
import Breadcrumb from "../shared/breadcrumb";
import { connect } from "react-redux";
import ThinkOrSwimButton from "./thinkOrSwimButton";
import { useHistory } from "react-router-dom";

function ClassroomGame(props) {
  const history = useHistory();
  const { t } = useTranslation();

  const breadcrumbItems = () => {
    const items: Array<IBreadcrumb> = [
      { name: t("Breadcrumb.Home"), url: routeConstant.ROUTE_DASHBOARD },
      { name: t("Breadcrumb.ClassroomGame"), url: ""}
    ]
    return items;
  }

  const handleThinkOrSwimButton = () => {
    history.push({
      pathname: routeConstant.ClassroomGame.ThinkOrSwim
    });
  }

  return (
    <div className="min-w-0 w-full">
      <div className="lg:flex lg:items-center px-4 py-3 lg:px-8 border-b">
        <Breadcrumb items={breadcrumbItems()} />
        <div className="lg:w-[55%] text-lg font-medium flex gap-2 h-auto sm:h-10 items-center">
          <h1 className="text-primary-biolet font-medium flex justify-start mb-0 pb-0">
            Classroom Game
          </h1>
        </div>
      </div>
      <div className="px-2 sm:px-6 lg:px-8 py-1 relative my-4">
        <div className="bg-gray-100 w-full rounded-lg p-5">
          <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-4 xxxl:grid-cols-5">
            <ThinkOrSwimButton onClick={handleThinkOrSwimButton} />
          </ul>
        </div>
        
      </div>
    </div>
  )
}

export default connect((state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
})(ClassroomGame)

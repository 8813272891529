import React, { useState, useEffect } from "react";
import MultiStateButton from "../shared/multiStateButton";
import { filterType } from "../../../../model/chat/type/types";
import Constant from "../../../../utils/constant/constant";

interface filterProps {
  roleId: number;
  filterType: filterType;
  handleShowFilter(show: boolean): void;
  saveFilter(filter: filterType): void;
}

const Filter = (props: filterProps) => {
  const defaultSchoolAdminFilterMultiState = [
    { label: "Student", value: "STUDENTS" },
    { label: "Class", value: "CLASSES" },
    { label: "All", value: "BOTH", selected: true },
  ];

  const defaultTeacherFilterMultiState = [
    { label: "Student", value: "STUDENTS" },
    { label: "Class", value: "CLASSES" },
    { label: "All", value: "BOTH", selected: true },
  ];
  const [filter, setFilter] = useState(props.filterType);
  const [filterMultiState, setFilterMultiState] = useState<Array<any>>([]);

  useEffect(() => {
    const defaultfilter =
      props.roleId === Constant.UserRoleId.SchoolAdmin
        ? defaultSchoolAdminFilterMultiState
        : defaultTeacherFilterMultiState;
    const tempMultiState = defaultfilter.map((i) => {
      if (props.filterType === i.value) {
        i.selected = true;
      } else {
        i.selected = false;
      }
      return i;
    });

    setFilterMultiState(tempMultiState);
  }, [props.filterType]);

  return (
    <div>
      <div className="overflow-auto h-full w-full absolute left-0 top-0 z-10">
        <div className=" filter:animation p-0 absolute top-0 bg-white  z-10 opacity-100 w-full h-full">
          <div className="py-2 px-4">
            <div className="">
              <div className="flex items-center justify-between text-xl mb-2 border-b pb-1">
                <span>Filter</span>

                <button
                  type="button"
                  onClick={() => {
                    props.handleShowFilter(false);
                  }}
                  className="inline-flex items-center justify-center group rounded-full h-6 w-6 transition duration-500 ease-in-out text-white hover:bg-white focus:outline-none"
                >
                  <svg
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    role="img"
                    aria-hidden="true"
                    className="w-4 h-4 fill-current text-black"
                  >
                    <path
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                      fill-rule="evenodd"
                    ></path>
                  </svg>
                </button>
              </div>

              <div className="my-2">
                {filterMultiState.length > 0 && (
                  <MultiStateButton
                    labels={filterMultiState}
                    onSelect={(e: any) => {
                      setFilter(e.value);
                    }}
                  />
                )}
              </div>
            </div>
            <div className="text-right mt-4">
              <button
                className="align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-3 py-1 rounded-md text-sm border border-transparent bg-tertiary-green text-white hover:bg-green-600"
                onClick={() => {
                  props.saveFilter(filter);
                  props.handleShowFilter(false);
                }}
              >
                Apply
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Filter;

import { AxiosHelper } from "../../environment/axiosConfig";
import APIConstant from "../../utils/constant/apiConstant";

export const getAuthParams = () => {
  return AxiosHelper.axiosInstance().get(
    APIConstant.Profile_API.GetAuthParams,
    {
      params: {},
    }
  );
};

import tagSearchModel from "../../model/teacher/tags/tagSearchModel";
import userTag from "../../model/teacher/tags/userTag";
import APIConstant from "../../utils/constant/apiConstant";
import { AxiosHelper } from "../../environment/axiosConfig";

export const getByUserTagId = (id: number) => {
  return AxiosHelper.axiosInstance().get(
    APIConstant.UserTags_API.GetByUserTagId,
    {
      params: {
        id: id,
      },
    }
  );
};

export const checkUserTagExists = (
  userTagId: number,
  name: string,
  userId: number
) => {
  return AxiosHelper.axiosInstance().get(
    APIConstant.UserTags_API.CheckUserTagExists,
    {
      params: {
        userTagId: userTagId,
        name: name,
        userId: userId,
      },
    }
  );
};

export const getAllUserTags = (search: tagSearchModel) => {
  return AxiosHelper.axiosInstance().get(APIConstant.UserTags_API.GetAll, {
    params: search,
  });
};

export const getAllTags = (userId: number) => {
  return AxiosHelper.axiosInstance().get(APIConstant.UserTags_API.GetAll, {
    params: { userId: userId, isActive: true },
  });
};

export const addUserTags = (tag: userTag) => {
  return AxiosHelper.axiosInstance().post(APIConstant.UserTags_API.Add, tag);
};

export const updateUserTags = (tag: userTag) => {
  return AxiosHelper.axiosInstance().put(APIConstant.UserTags_API.Update, tag);
};

export const deleteUserTag = (userTagId) => {
  return AxiosHelper.axiosInstance().delete(APIConstant.UserTags_API.Delete, {
    params: {
      userTagId: userTagId,
    },
  });
};

import { useState, useEffect, useRef } from "react";
import Select, { MultiValue, SingleValue } from "react-select";
import { ISelectElement } from "../../../../model/interface/selectElement";
import { connect } from "react-redux";
import { UserContextState } from "../../../../redux/actions/userContextAction";
import { getArchivedClasses } from "../../../../api/teacher/teacher";
import ITeacherClass from "../../../../model/interface/teacherclass";

interface IClassDropDown {
  schoolYearId: number;
  value?: any;
  data?: any;
  isDisabled: boolean;
  isMultiSelect?: boolean;
  onChange: (values: any) => void;
  showAllOption?: boolean;
  defaultLabel?: string;
  colorStyle?: string;
  showArchived?: boolean;
  showHidden?: boolean;
  subjectId?: number;
  userContext?: UserContextState;
  selectedSchoolAccountId?: number;
  onMenuClose?: () => void;
  placeholder?: string;
}

function ClassDropDown(props: IClassDropDown) {
  const [items, setItems] = useState<any[]>();
  const [value, setValue] = useState<Array<ISelectElement>>();
  const [isDisabled, setDisabled] = useState<boolean>(props.isDisabled);
  const [archivedClasses, setArchivedClasses] = useState<ITeacherClass[]>([]);
  const changed = useRef<boolean>(false);

  const getTeacherArchivedClasses = async () => {
    const resp = await getArchivedClasses(
      props.schoolYearId,
      props.subjectId,
      props.selectedSchoolAccountId ?? 0 > 0
        ? props.selectedSchoolAccountId
        : props.userContext?.schoolAccountId || 0
    );
    setArchivedClasses(resp.data);
  };

  const mapOptions: any = (data) => {
    const options: any =
      props.showAllOption ?? true
        ? [
            {
              label: getDefaultLabel(),
              value: "0",
            },
          ]
        : [];

    const classes: Array<ISelectElement> = data
      .filter((r) => r.isAdditionalClass === false && r.isArchived === false)
      .map((values: any) => {
        return {
          label: values?.displayName,
          value: values?.classId,
        };
      });
    const additionalClasses: Array<ISelectElement> = data
      .filter((r) => r.isAdditionalClass === true && r.isArchived === false)
      .map((values: any) => {
        return {
          label:
            values.teacherLastName +
            ", " +
            values.teacherFirstName +
            " - " +
            values?.displayName,
          value: values?.classId,
        };
      });

    let archivedTeacherClasses: Array<ISelectElement>;
    if(props.showArchived === true)
    {
      archivedTeacherClasses = archivedClasses.map(
          (values: any) => {
            return {
              label:
                  values.teacherLastName +
                  ", " +
                  values.teacherFirstName +
                  " - " +
                  values?.displayName,
              value: values?.classId,
            };
          }
      );
    } else {
      archivedTeacherClasses = data
          .filter((r) => r.isArchived === true)
          .map(
          (values: any) => {
            return {
              label:
                  values.teacherLastName +
                  ", " +
                  values.teacherFirstName +
                  " - " +
                  values?.displayName,
              value: values?.classId,
            };
          }
      );
    }


    if (classes.length > 0) {
      options.push({
        label: "My Classes",
        options: classes,
      });
    }
    if (additionalClasses.length > 0) {
      options.push({
        label: "Additional Classes",
        options: additionalClasses,
      });
    }
    if (archivedTeacherClasses.length > 0) {
      options.push({
        label: "Archived Classes",
        options: archivedTeacherClasses,
      });
    }
    if (
      classes.length === 0 &&
      additionalClasses.length === 0 &&
      data.length > 0
    ) {
      data.map((values: any) => {
        options.push({
          label: values?.displayName,
          value: values?.classId,
        });
      });
    }
    return options;
  };

  const getDefaultLabel = () => {
    return props.defaultLabel && props.defaultLabel.trim() !== ""
      ? props.defaultLabel
      : "All Classes";
  };

  const handleOnChange = (options: MultiValue<ISelectElement>  | SingleValue<ISelectElement> | null) => {

    if (!options) return;

    let selectedValues: Array<ISelectElement>;

    if (Array.isArray(options)) {
      // It's a multi-value, so we can use map
      selectedValues = options.map(option => option);
    } else {
      // It's a single value or null
      selectedValues = [options as ISelectElement];
    }

    if (selectedValues && selectedValues.length > 0) {
      const lastSelectedValue = selectedValues[selectedValues.length - 1];
      if (lastSelectedValue.value === "0") {
        selectedValues = [lastSelectedValue];
      } else if (
        selectedValues.some((r) => r.value === "0") &&
        selectedValues.some((r) => r.value !== "0")
      ) {
        selectedValues = selectedValues.filter((r) => r.value !== "0");
      }
    } else {
      selectedValues = [{ label: getDefaultLabel(), value: "0" }];
    }

    setValue(selectedValues);
    if (typeof props.onChange === "function") props.onChange(selectedValues);
  };

  useEffect(() => {
    initClassOptions();
  }, [props.data, props.isDisabled]);

  useEffect(() => {
    if (props.showArchived && changed.current && !props.isDisabled) {
      getTeacherArchivedClasses();
    }
  }, [
    props.selectedSchoolAccountId,
    props.data,
    props.isDisabled,
    changed.current,
  ]);

  const initClassOptions = () => {
    if (props.isDisabled) return;
    if (props.data && props.data.length > 0) {
      let options: any = [];
      options = mapOptions(props.data);
      setItems(options);
      setValue(props.value);
    }
    changed.current = true;
  };

  useEffect(() => {
    let options: any = [];
    options = mapOptions(props.data && props.data.length > 0 ? props.data : []);
    setItems(options);
    setValue(props.value);
  }, [archivedClasses]);

  useEffect(() => {
    if (props.value.length > 0) setValue(props.value);
  }, [props.value]);

  useEffect(() => {
    setDisabled(props.isDisabled);
  }, [props.isDisabled]);

  return (
    <Select
      menuPortalTarget={document.body}
      options={items}
      placeholder={props.placeholder ?? ""}
      onChange={handleOnChange}
      onMenuClose={props.onMenuClose}
      isMulti={props.isMultiSelect}
      value={value}
      isDisabled={isDisabled}
      isSearchable={true}
      closeMenuOnSelect={props.isMultiSelect ? false : true}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary: "#008fbb",
        },
      })}
      styles={{
        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
        control: (base, state) => ({
          ...base,
          background: props.colorStyle ?? "white",
          borderColor: "#D1D5DB",
          borderWidth: "1px",
          "&:hover": {
            borderColor: state.isFocused ? "#008fbb" : "",
          },
        }),

        input: (base) => ({
          ...base,
          color:
            document.getElementsByClassName("dark")?.length > 0 ? "white" : "",
        }),
      }}
    />
  );
}
const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(ClassDropDown);

import { getMyAssessmentCount } from "../../api/teacher/assessment";
import { getAuthoredQuestionCount } from "../../api/teacher/teacher";
import ITabPermission, {
  ITabRequest,
} from "../../model/interface/tabPermission";
import constant from "../../utils/constant/constant";
import { userPermissions } from "./rbac";

export const cssForDisabledControl =
  " cursor-not-allowed border-gray-50 focus:outline-none ";
const isControlDisabled = (permission: string) => {
  //disable if permission type is upgrade
  return permission.includes("upgrade");
};

const isVisible = (tabPermission: string) => {
  return (
    tabPermission.includes("view") ||
    tabPermission.includes("modify") ||
    tabPermission.includes("upgrade")
  );
};

export const getPermissionBasedTabs = (
  requestedTabs: Array<ITabRequest>,
  roleId: number
) => {
  let tabPermissions: Array<ITabPermission> = [];
  requestedTabs.map((request) => {
    const allowedPermission = request.roles.includes(roleId)
      ? "view"
      : request.rolesForUpgrade?.includes(roleId)
      ? "roleupgrade"
      : "";
    if (
      request.roles.includes(roleId) ||
      request.rolesForUpgrade?.includes(roleId)
    ) {
      tabPermissions.push({
        permissionName: allowedPermission,
        name: request.name,
        href: request.href,
        visibility: isVisible(allowedPermission),
        isDisabled: isControlDisabled(allowedPermission),
        className: isControlDisabled(allowedPermission)
          ? cssForDisabledControl
          : "",
      });
    }
  });
  return tabPermissions;
};

export const getRequestedPermission = (permissionName: string) => {
  const perm: Array<string> = userPermissions();
  return perm.filter((p) => p.includes(permissionName))[0];
};

export const getCssForDisabled = (
  permissionName: string,
  customClass: string
) => {
  const requestedPerm = getRequestedPermission(permissionName);
  return requestedPerm
    ? isControlDisabled(permissionName)
      ? customClass != ""
        ? customClass
        : cssForDisabledControl
      : ""
    : "";
};

export const checkIfUpgradeRequired = (permissionName: string) => {
  const requestedPerm = getRequestedPermission(permissionName);
  return requestedPerm
    ? isControlDisabled(permissionName)
      ? true
      : false
    : false;
};

export const checkIfUpgradeRequiredForStudent = (
  isUpgradeRequired: boolean
) => {
  return isUpgradeRequired ?? false;
};

export const getAsessementBuilderUpgradePermission = (roleId: number) => {
  return constant.RolesRequireUpgrade.includes(roleId) ? true : false;
};
export const getAsessementCountUpgradePermission = async (roleId: number) => {
  if (constant.RolesRequireUpgrade.includes(roleId)) {
    let assessmentCount: number = (await getMyAssessmentCount()).data;
    if (assessmentCount >= constant.TEACHER_FREE_ASSESSMENT_LIMIT) {
      return true;
    }
  }
  return false;
};

export const validateAssessmentCount = (
  assessmentCount: number,
  roleId: number
) => {
  if (constant.RolesRequireUpgrade.includes(roleId)) {
    if (assessmentCount >= constant.TEACHER_FREE_ASSESSMENT_LIMIT) {
      return true;
    }
  }
  return false;
};

export const getAssignmentBankUpgradePermission = (roleId: number) => {
  return constant.RolesRequireUpgrade.includes(roleId) ? true : false;
};

export const getAuthoredQuestionsUpgradePermission = (roleId: number) => {
  return constant.RolesRequireUpgrade.includes(roleId) ? true : false;
};

export const getAuthoredQuestionCountUpgradePermission = async (
  roleId: number
) => {
  if (constant.RolesRequireUpgrade.includes(roleId)) {
    let questionCount: number = (await getAuthoredQuestionCount()).data;
    if (questionCount >= constant.TEACHER_FREE_AUTHOR_QUESTION_LIMIT) {
      return true;
    }
  }
  return false;
};

export const getClassManagementUpgradePermission = (roleId: number) => {
  return constant.RolesRequireUpgrade.includes(roleId) ? true : false;
};

export const getPremiumAssessmentUpgradePermission = (roleId: number) => {
  return constant.RolesRequireUpgrade.includes(roleId) ? true : false;
};

export const getProfileUpgradePermission = (roleId: number) => {
  return constant.RolesRequireUpgrade.includes(roleId) ? true : false;
};

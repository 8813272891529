import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { Dialog, Transition } from "@headlessui/react";
import { ExclamationIcon, XIcon } from "@heroicons/react/outline";
import { getAssessmentByCode } from "../../../../api/student/studentAssessmentActivityApi";
import { UserContextState } from "../../../../redux/actions/userContextAction";
import { connect } from "react-redux";

interface TakeAssignmentDialogProp {
  open: boolean;
  onOkClick: (assignmentCode: any, subjectId: number) => void;
  onCancelClick: () => void;
  userContext?: UserContextState;
}

export function TakeAssignmentDialog(props: TakeAssignmentDialogProp) {
  const { t } = useTranslation();
  const [assignmentCode, setAssignmentCode] = useState("");
  const [isInvalidCode, setIsInvalidCode] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("Invalid Code");
  function handleAssignmentCodeChange(e: any) {
    setIsInvalidCode(false);
    setAssignmentCode(e.target.value);
  }

  const handleOk = async () => {
    setIsInvalidCode(false);
    var result = await getAssessmentByCode(
      assignmentCode,
      props.userContext?.userId ?? 0
    );
    if (result.data === null) {
      setIsInvalidCode(true);
      setErrorMessage("Invalid Code");
      return false;
    } else if (result.data.result === false) {
      setIsInvalidCode(true);
      setErrorMessage(result.data.message);
      return false;
    } else {
      setIsInvalidCode(false);
      var subjectId = result.data.assessment.subjectId;
      props.onOkClick(assignmentCode, subjectId);
    }
  };

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog
        as="div"
        static
        className={"fixed z-10 inset-0 overflow-y-auto"}
        open={props.open}
        onClose={props.onCancelClick}
      >
        <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                <button
                  type="button"
                  className="inline-flex items-center justify-center w-6 h-6 text-gray-400 transition-colors duration-150 rounded dark:hover:text-gray-200 hover: hover:text-gray-700"
                  onClick={() => props.onCancelClick()}
                >
                  <span className="sr-only">Close</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="flex sm:items-start p-4 sm:mb-6">
                <div className="mx-auto mt-4 flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-yellow-100 sm:mx-0 sm:h-10 sm:w-10">
                  <ExclamationIcon
                    className="h-6 w-6 text-yellow-500"
                    aria-hidden="true"
                  />
                </div>
                <div className="mt-4 ml-4 text-left">
                  <Dialog.Title
                    as="h3"
                    className="text-lg leading-6 font-medium text-gray-900"
                  >
                    {t("Assignments.TakeAnAssessment")}
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      {" "}
                      {t("Assignments.ObtainCode")}
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-start px-6 py-4 sm:flex-row">
                <div className="flex w-full flex-col items-start sm:flex-row sm:items-center">
                  <label
                    htmlFor="first-name"
                    className="block text-sm font-medium text-gray-500"
                  >
                    {t("Assignments.AssessmentCode")}
                  </label>
                  <div className="mt-1 w-full">
                    <input
                      autoComplete="off"
                      type="text"
                      name="first-name"
                      id="first-name"
                      value={assignmentCode}
                      onChange={(e) => handleAssignmentCodeChange(e)}
                      className=" sm:ml-2 shadow-sm focus:ring-primary-violet focus:border-primary-violet h-10 block w-full sm:max-w-xs text-lg border border-gray-300 text-gray-500 px-3 rounded-sm"
                    />
                  </div>
                </div>
              </div>
              {isInvalidCode && (
                <div className="flex">
                  <label
                    htmlFor="first-name"
                    className="block text-sm font-medium text-red-500 ml-5"
                  >
                    {errorMessage}
                  </label>
                </div>
              )}

              <div className="flex-shrink-0 p-4 bg-gray-50">
                <div className="space-x-3 flex justify-end">
                  <button
                    type="button"
                    className="align-bottom inline-flex items-center whitespace-nowrap justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 rounded-lg text-sm text-white border border-transparent bg-primary-green focus:shadow-outline-purple w-auto"
                    onClick={() => handleOk()}
                  >
                    {t("BeginActivity.Ok")}
                  </button>
                  <button
                    type="button"
                    className="w-auto inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                    onClick={() => props.onCancelClick()}
                  >
                    {t("BeginActivity.Cancel")}
                  </button>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(TakeAssignmentDialog);

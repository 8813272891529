import { AxiosHelper } from "../environment/axiosConfig";
import APIConstant from "../utils/constant/apiConstant";
import { AxiosResponse } from "axios";

export const getPendingSession = (activityId: string, studentId: string) => {
  return AxiosHelper.axiosInstance().get(APIConstant.APP_USER_ACTIVITY_STATUS, {
    params: {
      activityId: activityId,
      userId: studentId,
    },
  });
};

export const getRequest = (
  serviceName: string,
  requestObj: string,
  userId?: number,
  action?: string
): Promise<AxiosResponse<string>> => {
  return AxiosHelper.axiosInstance().post<string>(
      APIConstant.APP_USER_ACTIVITY_GETREQUEST,
      {
          serviceName: serviceName,
          requestPacket: requestObj,
          userId: userId ?? null,
          action: action ?? null,
          hostName: window.location.hostname
      }
  );
};

import { Fragment, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { XIcon } from "@heroicons/react/outline";
import { IAssignment } from "../../../../../../model/interface/assignment";
import { updateAssignmentData } from "../../../../../../api/teacher/assignment";
import Moment from "moment";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import Loader from "../../../../../shared/loader";
import Constant from "../../../../../../utils/constant/constant";

interface SettingPopupProps {
  userId: number;
  assignmentId: string;
  assignmentName: string;
  subjectId: number;
  startDate: string;
  endDate: string;
  schoolYearId?: number;
  toggleSettingPopup: (popupAction: boolean, refreshData?: boolean) => void;
}

function RemediationSetting(props: SettingPopupProps) {
  const {
    userId,
    assignmentId,
    assignmentName,
    subjectId,
    startDate,
    endDate,
    toggleSettingPopup,
    schoolYearId,
  } = props;
  const defaultAssignment: IAssignment = {
    assignmentId: 0,
    name: "",
    subjectId: 0,
    activities: [],
    students: [],
    isGoogleAssignment: false,
    isCanvasAssignment: false,
    schoolYearId: 0,
    completed: 0
  };

  const [assignment, setAssignment] = useState<IAssignment>(defaultAssignment);
  const [selectedStartDate, setStartDate] = useState<Date>();
  const [selectedDueDate, setDueDate] = useState<Date>();
  const [loading, setLoading] = useState(false);

  function handleStartDate(date: any) {
    if (selectedDueDate && date > selectedDueDate) {
      return false;
    }
    let startDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      0,
      0,
      0
    );
    setStartDate(startDate);
    assignment.startDate = startDate.toISOString().slice(0, -1);
  }

  function handleDueDate(date: any) {
    if (selectedStartDate && date < selectedStartDate) {
      return false;
    }
    let endDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      23,
      59,
      59
    );
    setDueDate(endDate);
    assignment.endDate = endDate.toISOString().slice(0, -1);
  }

  async function handleSave() {
    let hasError: boolean = false;
    if (!selectedStartDate && !selectedDueDate) {
      toast.error("Start date and due date cannot be empty.");
      hasError = true;
    } else if (!selectedStartDate && selectedDueDate) {
      toast.error("Start date cannot be empty.");
      hasError = true;
    } else if (selectedStartDate && !selectedDueDate) {
      toast.error("Due date cannot be empty.");
      hasError = true;
    }

    if (selectedStartDate) {
      assignment.startDate = selectedStartDate.toISOString().slice(0, -1);
    }
    if (selectedDueDate) {
      assignment.endDate = selectedDueDate.toISOString().slice(0, -1);
    }

    if (hasError) {
      return false;
    }

    setLoading(true);

    updateAssignmentData(userId, assignment).then(() => {
      setLoading(false);
      toggleSettingPopup(false, true);
    });
  }

  useEffect(() => {
    const updatedAssignment: IAssignment = assignment;
    updatedAssignment.assignmentId = parseInt(assignmentId);
    updatedAssignment.name = assignmentName;
    updatedAssignment.startDate = startDate;
    updatedAssignment.endDate = endDate;
    updatedAssignment.subjectId = subjectId;
    updatedAssignment.assignmentStatusId = Constant.AssignmentStatusId.NEW;
    updatedAssignment.schoolYearId = schoolYearId;
    setStartDate(new Date(startDate));
    setDueDate(new Date(endDate));
    setAssignment(updatedAssignment);
  }, []);

  return (
    <Fragment>
      {loading && <Loader />}
      <div className="fixed inset-0 z-40 flex items-center bg-black bg-opacity-50 sm:items-center sm:justify-center">
        <div
          className="w-full flex flex-col justify-between overflow-hidden h-auto max-h-80percent bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:max-w-6xl"
          role="dialog"
        >
          <header>
            {/* Header */}
            <div className="px-4 py-4 bg-gray-50">
              <div className="flex items-start justify-between space-x-3">
                <div className="space-y-1">
                  <div className="text-lg font-medium text-gray-900">
                    Edit Assignment Settings
                  </div>
                </div>
                <div className="h-7 flex items-center">
                  <button
                    type="button"
                    className="text-gray-400 hover:text-gray-500"
                    onClick={() => toggleSettingPopup(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
          </header>
          <div className="w-full relative overflow-auto px-4 py-1">
            <form>
              <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5 ml-8">
                <div className="pt-8 sm:pt-6 space-y-6 sm:space-y-5">
                  <div className="space-y-6 sm:space-y-5 xl:sticky 1xl:left-0 1xl:top-0 1xl:bg-white 1xl:-top-1 1xl:z-20">
                    <div className="sm:grid sm:grid-cols-4 sm:gap-4 sm:items-center">
                      <label
                        htmlFor="first-name"
                        className="block text-sm font-normal text-gray-500"
                      >
                        Assignment Name
                      </label>
                      <div className="mt-1 sm:mt-0 sm:col-span-2">
                        {assignmentName}
                      </div>
                    </div>
                    <div className="sm:grid sm:grid-cols-4 sm:gap-4 sm:items-center">
                      <label className="block text-sm text-gray-500 ">
                        Start Date
                      </label>
                      <DatePicker
                        className="react-datepicker__input-container py-1.5 px-3 block w-full shadow-sm focus:ring-primary-violet focus:border-primary-violet border border-gray-300 rounded-md "
                        selected={selectedStartDate}
                        onChange={(date) => handleStartDate(date)}
                        minDate={Moment().toDate()}
                      />
                    </div>
                    <div className="sm:grid sm:grid-cols-4 sm:gap-4 sm:items-center">
                      <label className="block text-sm text-gray-500">
                        Due Date
                      </label>
                      <DatePicker
                        className="react-datepicker__input-container py-1.5 px-3 block w-full shadow-sm focus:ring-primary-violet focus:border-primary-violet border border-gray-300 rounded-md "
                        selected={selectedDueDate}
                        onChange={(date) => handleDueDate(date)}
                        minDate={Moment().toDate()}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>

          {/* <!-- Action Buttons -->  */}
          <footer className="flex items-center justify-end px-4 py-4 sm:flex-row bg-gray-50 dark:bg-gray-800 mt-4">
            <div className="space-x-3 flex justify-end">
              <button
                onClick={() => handleSave()}
                className="bg-primary-violet border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-dark-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3"
              >
                Save Assignment
              </button>
              <button
                onClick={() => toggleSettingPopup(false)}
                className="align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 rounded-lg text-sm text-gray-600 border-gray-300 border dark:text-gray-400 focus:outline-none active:bg-transparent hover:border-gray-500 focus:border-gray-500 active:text-gray-500 focus:shadow-outline-gray w-full sm:w-auto"
              >
                Close
              </button>
            </div>
          </footer>
        </div>
      </div>
    </Fragment>
  );
}

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(RemediationSetting);

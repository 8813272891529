import { Fragment } from "react";
import { connect } from "react-redux";
import { UserContextState } from "../../../../../redux/actions/userContextAction";
import Profile from "../../../../../model/users/profile";
import RouteConstant from "../../../../../utils/constant/routeConstant";
import routeConstant from "../../../../../utils/constant/routeConstant";
import Breadcrumb from "../breadcrumb";
import { IBreadcrumb } from "../../../../../model/common/breadcrumb";
import { useTranslation } from "react-i18next";
import CannedTestList from "./testList";

interface ICannedTestPractice {
  userContext: UserContextState;
  profile: Profile;
  match: any;
  location: any;
}
function CannedTestPracticeMS(props: ICannedTestPractice) {
  const { t } = useTranslation();
  const subjectId = props.match.params.subjectId;
  const subjectName = props.location?.state?.subjectName ?? "";

  const breadcrumbItems = () => {
    const items: Array<IBreadcrumb> = [
      {
        name: t("Breadcrumb.Home"),
        url: RouteConstant.ASSIGNMENTS_MS,
        textColor: "",
      },
      {
        name: t("Breadcrumb.DiagnosticsSubjects"),
        textColor: "",
        url: routeConstant.MiddleSchool.CannedTestSubjects,
      },
      {
        name: subjectName ?? t("Breadcrumb.Diagnostics"),
        textColor: "",
        url: "",
      },
    ];

    return items;
  };
  return (
    <Fragment>
      <>
        <div className="md:flex md:items-center px-4 py-3 lg:px-8 border-b">
          <Breadcrumb items={breadcrumbItems()} />
          <div className="md:w-[55%] text-lg flex justify-between gap-2 h-auto sm:h-10 items-center">
            <h1 className="text-gray-50 font-medium flex justify-start">
              {t("StudyType.Diagnostics")}
            </h1>
          </div>
        </div>
        <div className="grid grid-cols-1 gap-4 w-2/3 p-5">
          <CannedTestList
            subjectId={subjectId}
            userId={props.profile?.userId ? props.profile?.userId : 0}
          />
        </div>
      </>
    </Fragment>
  );
}

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(CannedTestPracticeMS);

import React, { useState, Fragment, useEffect } from "react";
import {
  PaginationFirst,
  PaginationLast,
  PaginationPrev,
  PaginationNext,
} from "../../../../assets/icons/index";

const Pagination: React.FC<any> = (props: {
  totalRecords: number;
  pageSize: number;
  onPageChange: any;
  reset?: boolean;
  bgColor?: string;
}) => {
  const [pageNo, setPageNo] = useState<number>(1);
  const [noOfPages, setNoOfPages] = useState<number>(
    Math.ceil(props.totalRecords / props.pageSize)
  );

  const pagination: any = async (param: string) => {
    if (param && param === "prev" && pageNo !== 1) {
      setPageNo(pageNo - 1);
      props.onPageChange(pageNo - 1);
    }

    if (param && param === "next" && pageNo !== noOfPages) {
      setPageNo(pageNo + 1);
      props.onPageChange(pageNo + 1);
    }
    if (param && param === "next" && pageNo === props.totalRecords) {
      setPageNo(pageNo);
      props.onPageChange(pageNo);
    }

    if (param && param === "first") {
      setPageNo(1);
      props.onPageChange(1);
    }

    if (param && param === "last") {
      setPageNo(noOfPages);
      props.onPageChange(noOfPages);
    }
  };

  useEffect(() => {
    setPageNo(1);
    setNoOfPages(Math.ceil(props.totalRecords / props.pageSize));
  }, [props.reset, props.totalRecords, props.pageSize]);

  return (
    <div className="inline-flex mt-2">
      <nav
        className="relative z-0 inline-flex items-center rounded "
        aria-label="Pagination"
      >
        <button
          onClick={() => pagination("first")}
          className={`disabled:opacity-50 border border-white transition-all relative inline-flex items-center justify-center h-7 w-7 rounded bg-${
            props.bgColor ? props.bgColor : "light-violet"
          } hover:bg-${
            props.bgColor ? props.bgColor : "light-violet"
          } text-sm font-medium text-white mr-2 ${
            pageNo === 1 ? "cursor-not-allowed" : "cursor-pointer"
          }`}
          title="Go to first page"
          disabled={pageNo === 1}
        >
          <Fragment>{<PaginationPrev />} </Fragment>
        </button>

        <button
          className={`disabled:opacity-50 border border-white transition-all relative inline-flex items-center justify-center h-7 w-7 rounded bg-${
            props.bgColor ? props.bgColor : "light-violet"
          } hover:bg-${
            props.bgColor ? props.bgColor : "light-violet"
          } text-sm font-medium text-white mr-2 ${
            pageNo === 1 ? "cursor-not-allowed" : "cursor-pointer"
          }`}
          title="Previous"
          onClick={() => pagination("prev")}
          disabled={pageNo === 1}
        >
          <Fragment> {<PaginationFirst />}</Fragment>
        </button>

        <span className="relative inline-flex items-center px-4 text-sm font-medium text-white whitespace-nowrap">
          {pageNo}/{noOfPages}
        </span>

        <button
          className={`disabled:opacity-50 border border-white transition-all relative inline-flex items-center justify-center h-7 w-7 rounded bg-${
            props.bgColor ? props.bgColor : "light-violet"
          } hover:bg-${
            props.bgColor ? props.bgColor : "light-violet"
          } text-sm font-medium text-white mr-2 ${
            pageNo === noOfPages ? "cursor-not-allowed" : "cursor-pointer"
          }`}
          title="Next"
          onClick={() => pagination("next")}
          disabled={pageNo === noOfPages}
        >
          <Fragment>{<PaginationNext />} </Fragment>
        </button>

        <button
          onClick={() => pagination("last")}
          className={`disabled:opacity-50 border border-white transition-all relative inline-flex items-center justify-center h-7 w-7 rounded bg-${
            props.bgColor ? props.bgColor : "light-violet"
          } hover:bg-${
            props.bgColor ? props.bgColor : "light-violet"
          } text-sm font-medium text-white mr-2 ml-2 ${
            pageNo === noOfPages ? "cursor-not-allowed" : "cursor-pointer"
          }`}
          title="Go to last Page"
          disabled={pageNo === noOfPages}
        >
          <Fragment>{<PaginationLast />} </Fragment>
        </button>

        <span className="hidden sm:inline-flex relative items-center px-1 text-sm text-white whitespace-nowrap">
          [{props.totalRecords} Records]
        </span>
      </nav>
    </div>
  );
};
export default Pagination;

import moment from "moment";
import { useEffect, useState } from "react";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useTranslation } from "react-i18next";
import { getScoreLabel } from "../../../../../utils/scoreHelper";

function CannedTestOverViewResult(params: any) {
  const [isGraded] = useState<boolean>(false);
  const [result, setResult] = useState<any>();
  const { t } = useTranslation();
  const getRingColor = (value) => {
    let ringColor = "";
    switch (true) {
      case value < 63:
        ringColor = "#E53F71";
        break;
      case value >= 63 && value < 80:
        ringColor = "#FFDB3A";
        break;
      case value >= 80:
        ringColor = "#B2D136";
        break;
      default:
        ringColor = "bg-gray-700";
        break;
    }
    return ringColor;
  };

  function overview() {
    if (params.data) {
      const overviewResultInput = {
        results_header: {
          assignment: {
            result: {
              retry_percent: 0,
              percent:
                Number(params.data.totalAttempted) === 0
                  ? 0
                  : Math.round(
                      (Number(params.data.totalScore) /
                        Number(params.data.totalAttempted)) *
                        100
                    ),
              totalAttempted: params.data.totalAttempted,
              totalScore: params.data.totalScore,
            },
          },
          elapsed_time: params.data.sessionDuration,
          date_completed: params.data.dateCompleted,
          earnedStar: params.data.earnedStar,
          earnedToken: params.data.earnedToken,
          test: {
            name: params.data.subjectName,
          },
          teacher: {
            first:
              params.data.teacherFirstName === null
                ? ""
                : params.data.teacherFirstName,
            last:
              params.data.teacherLastName === null
                ? ""
                : params.data.teacherLastName,
          },
          student: {
            first:
              params.data.studentFirstName === null
                ? ""
                : params.data.studentFirstName,
            last:
              params.data.studentLastName === null
                ? ""
                : params.data.studentLastName,
          },
          class: {
            name: params.data.class === null ? "" : params.data.class,
          },
        },
      };
      setResult(overviewResultInput);
    }
  }
  useEffect(() => {
    overview();
  }, []);

  return (
    <>
      {result && (
        <div className="flex-none md:w-64 h-auto bg-blues rounded-md shadow-md md:mr-6 xl:mr-12 xxl:mr-6 xxxl:mr-12 shadow-lg">
          <div className="text-md text-white text-center px-12 md:px-5 ">
            <h2 className="text-lg text-white font-medium uppercase text-center my-4">
              {t("StudyPlanResult.Score")}
            </h2>
            {isGraded ? (
              <div
                style={{
                  width: 160,
                  height: 160,
                  display: "inline-grid",
                  fontSize: "17px;",
                }}
              >
                <CircularProgressbar
                  value={result?.results_header?.assignment?.result?.percent}
                  text={`${result?.results_header?.assignment?.result?.percent}`}
                  styles={buildStyles({
                    pathColor: `${getRingColor(
                      result?.results_header?.assignment?.result?.percent
                    )}`,
                    textColor: "#ffffff",
                  })}
                />
              </div>
            ) : (
              <div
                style={{
                  width: 140,
                  height: 140,
                  display: "inline-grid",
                  position: "relative",
                }}
              >
                <CircularProgressbar
                  value={0}
                  text={`${
                    result?.results_header?.assignment?.result?.retry_percent ||
                    result?.results_header?.assignment?.result?.percent
                  }%`}
                  styles={buildStyles({
                    pathColor: `${getRingColor(
                      result?.results_header?.assignment?.result?.percent
                    )}`,
                    textColor: "#ffffff",
                  })}
                  className="circular-bar-pending-size"
                />
                <span className="absolute top-1/2 left-1/2 transform -translate-x-2/4 -translate-y-2/4 pt-11 text-white">
                  ({getScoreLabel(result?.results_header?.assignment?.result?.totalScore)}/
                  {result?.results_header?.assignment?.result?.totalAttempted})
                </span>
              </div>
            )}
          </div>
          {<div className="w-full text-lg text-white text-center mb-4"></div>}

          <div className="mt-10 flex flex-col flex-none mx-7 text-white border-white border-b border-opacity-25 pb-2 mb-2">
            <div className="text-xs text-white capitalize">
              {t("StudyPlanResult.DateCompleted")}
            </div>
            <div className="text-white font-roboto font-bold capitalize">
              {result?.results_header?.date_completed
                ? moment(result?.results_header?.date_completed).format(
                    "MM/DD/YYYY"
                  )
                : ""}
            </div>
          </div>
          <div className="flex flex-col flex-none mx-7 text-white border-white border-b border-opacity-25 pb-2 mb-3">
            <div className="text-xs text-white capitalize">
              {t("StudyPlanResult.SessionTime")}
            </div>
            <div className="text-white font-roboto font-bold">
              {moment()
                .startOf("day")
                .add(result?.results_header?.elapsed_time, "seconds")
                .format("HH:mm:ss")}
            </div>
          </div>
          <div className="flex flex-col flex-none mx-7 text-white border-white border-b border-opacity-25 pb-1 mb-3">
            <div className="text-xs text-white capitalize">
              {t("StudyPlanResult.Subject")}
            </div>
            <div className="text-white text-md font-roboto font-bold capitalize">
              {result?.results_header?.test
                ? `${result?.results_header?.test.name}`
                : "-"}{" "}
            </div>
          </div>
          <div className="flex flex-col flex-none mx-7 text-white border-white border-b border-opacity-25 pb-2 mb-3">
            <div className="text-xs text-white capitalize">
              {" "}
              {t("StudyPlanResult.StudentName")}
            </div>
            <div className="text-white text-md font-roboto font-bold capitalize">
              {Object.keys(result?.results_header?.student).length > 0 &&
                `${result?.results_header?.student?.first} ${result?.results_header?.student?.last}`}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
export default CannedTestOverViewResult;

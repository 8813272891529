import { Fragment, useEffect, useState } from "react";
import {
  getAssessments,
  getPremiumAssessments,
} from "../../../../api/teacher/assessment";
import PagingResponse from "../../../../model/common/pagingResponse";
import { IAssessmentSearch } from "../../../../model/interface/assessmentSearch";
import { IAssignment } from "../../../../model/interface/assignment";
import AssessmentDetails from "../../../../model/teacher/assessment/assessment";
import Moment from "moment";
import Constant from "../../../../utils/constant/constant";
import { IKeyValuePair } from "../../../../model/interface/selectElement";
import { IAssignmentActivity } from "../../../../model/interface/assignmentActivity";
import { getActivityTypeId } from "../../../../utils/helper";
import AssessmentDetailPopup from "./assessmentDetail";
import Loader from "../../../shared/loader";
import {
  isAllowViewAssignmentAssessment,
  isDisplayContentViewMessage,
} from "../../../../utils/assessmentHelper";
import {
  LockIcon,
  LogoPLShootingStarIcon,
  PreviewIcon,
} from "../../../../assets/icons";
import QuestionPreview from "../shared/questionPreview/questionPreview";
import MessagePopup from "../../../shared/messagePopup";
import SharedByIcon from "../../../shared/icons/sharedIcon";
import { IPremiumAssessmentDTO } from "../../../../model/interface/assessment";
import { AssessmentActivityType } from "../../../../types/type";
import { getPremiumAssessmentName } from "../../../../utils/assignmentHelper";
import { getPremiumAssessmentUpgradePermission } from "../../../shared/permissionHelper";
import UpGradeMessagePopup from "../../../shared/upGradeMessagePopup";
import { verifyUserHasFullAccessSubscription } from "../../../../api/account/accountApi";
import constant from "../../../../utils/constant/constant";
import { toast } from "react-toastify";
import Profile from "../../../../model/users/profile";

interface AssessmentProps {
  userId: number;
  assignment: IAssignment;
  updateAssignment: (updatedAsignment: IAssignment) => void;
  assessmentId?: number;
  assessmentSubjectId?: number;
  isActivityUpdated: (activityUpdated: boolean) => void;
  canActivityUpdate: boolean;
  roleId?: number;
  profile: Profile;
}

interface IActivityPreviewDetail {
  previewId: string;
  activityIdPreview: number;
  activityName: string;
  isProjectorMode: boolean;
  subjectId?: number;  
}

function Assessment(props: AssessmentProps) {
  const {
    userId,
    assignment,
    updateAssignment,
    assessmentId,
    assessmentSubjectId,
    isActivityUpdated,
    canActivityUpdate,
  } = props;
  const [assessments, setAssessments] =
    useState<PagingResponse<AssessmentDetails>>();
  const [premiumAssessments, setPremiumAssessments] =
    useState<Array<IPremiumAssessmentDTO>>();
  const [showDetail, setShowDetail] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [errorMessagePopup, setErrorMessagePopup] = useState<boolean>(false);
  const [assessmentDetailId, setAssessmentDetailId] = useState<number>();
  const [selectedAssessments, setSelectedAssessments] = useState<Array<number>>(
    []
  );
  const [selectedPremiumAssessments, setSelectedPremiumAssessments] = useState<
    Array<number>
  >([]);
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [activityPreviewDetail, setActivityPreviewDetail] =
    useState<IActivityPreviewDetail>();
  const [upgrade, setUpgrade] = useState(false);
  const [showUpgradePopup, setShowUpgradePopup] = useState<boolean>(false);
  const [subscriptionIsPremium, setSubscriptionIsPremium] =
    useState<boolean>(false);

  function searchAssessments() {
    const assessmentSearch: IAssessmentSearch = {
      teacherId: userId,
      subjectId: assessmentSubjectId
        ? assessmentSubjectId
        : assignment.subjectId,
      orderByColumn: "CreateDate",
      sortByDesc: true,
      canAssignValue: true,
    };

    setShowLoading(true);
    getAssessments(assessmentSearch).then((r) => {
      setShowLoading(false);
      setAssessments(r.data);
      setExistingValues();

      if (assessmentId) {
        const response: Array<AssessmentDetails> = r.data.data;
        const assessmentDetails = response.filter(
          (r) => r.assessmentId.toString() === assessmentId.toString()
        );
        if (assessmentDetails && assessmentDetails.length > 0) {
          const assessmentDetail = assessmentDetails[0];
          handleSelectedAssessment(
            assessmentId,
            assessmentDetail.name,
            assessmentDetail.isSharedAssessment,
            assessmentDetail.learnosityIdEnglish,
            assessmentDetail.learnosityIdSpanish
          );
        }
      }
    });

    getPremiumAssessments(
      null,
      null,
      assessmentSubjectId ? assessmentSubjectId : assignment.subjectId,
      null,
      null,
      null,
      Constant.Pagination.DefaultPageNumber,
      Constant.Pagination.DefaultPageSize,
      "Subject",
      false,
      props.profile.currentSchoolYear.schoolYearId
    ).then((r) => {
      setPremiumAssessments(r.data.data);
    });
  }

  function getAssessmentDetails(assessmentId: number) {
    setShowDetail(true);
    setAssessmentDetailId(assessmentId);
  }

  function isActivityExist(
    key: string,
    activity: IAssignmentActivity,
    activityType: AssessmentActivityType
  ) {
    var isExist: boolean = false;
    switch (activityType) {
      case "Assessment":
        isExist = activity.assessmentId?.toString() === key;
        break;
      case "PremiumAssessment":
        isExist = activity.cannedTestId?.toString() === key;
        break;
    }

    return isExist;
  }

  function updateActivity(
    keyValuePair: IKeyValuePair,
    activityType: AssessmentActivityType,
    learnosityIdEnglish: string,
    learnosityIdSpanish: string,
    isSharedAssessment: boolean
  ) {
    isActivityUpdated(true);
    const existingActivities: Array<IAssignmentActivity> | undefined =
      assignment.activities?.filter(
        (r) =>
          r.activityType === Constant.AssignmentActivityType.ASSESSMENT &&
          isActivityExist(keyValuePair.key, r, activityType)
      );
    if (existingActivities && existingActivities.length > 0) {
      var allActivities: Array<IAssignmentActivity> | undefined =
        assignment.activities;
      if (allActivities) {
        allActivities = allActivities.filter(
          (r) =>
            (r.activityType === Constant.AssignmentActivityType.ASSESSMENT &&
              isActivityExist(keyValuePair.key, r, activityType)) === false
        );
        assignment.activities = allActivities;
      }
    } else {
      const activity: IAssignmentActivity = {
        assignmentActivityId: 0,
        assignmentId: assignment.assignmentId,
        activityTypeId: getActivityTypeId(
          Constant.AssignmentActivityType.ASSESSMENT
        ),
        activityType: Constant.AssignmentActivityType.ASSESSMENT,
        sequenceNumber: assignment.activities
          ? assignment.activities.length + 1
          : 1,

        learnosityIdEnglish: learnosityIdEnglish,
        learnosityIdSpanish: learnosityIdSpanish,
        isSharedAssessment: isSharedAssessment,
      };

      switch (activityType) {
        case "Assessment":
          activity.assessmentId = keyValuePair.key;
          activity.assessmentName = keyValuePair.value;
          break;
        case "PremiumAssessment":
          activity.cannedTestId = keyValuePair.key;
          activity.cannedTestName = keyValuePair.value;
          break;
      }
      assignment.activities?.push(activity);
    }

    updateAssignment(assignment);
  }

  function handleSelectedAssessment(
    assessmentId: number,
    name: string,
    isSharedAssessment: boolean,
    learnosityIdEnglish: string,
    learnosityIdSpanish: string
  ) {
    const assessmentExist = selectedAssessments.some(
      (r) => (r as number) === assessmentId
    );
    var selectedAssessmentList: Array<number> = new Array<number>();
    if (assessmentExist) {
      selectedAssessmentList = selectedAssessments.filter(
        (r) => (r as number) !== assessmentId
      );
    } else {
      selectedAssessmentList = [...selectedAssessments];
      selectedAssessmentList.push(assessmentId);
    }

    const keyValuePair: IKeyValuePair = {
      key: assessmentId.toString(),
      value: name,
    };
    setSelectedAssessments(selectedAssessmentList);
    updateActivity(
      keyValuePair,
      "Assessment",
      learnosityIdEnglish,
      learnosityIdSpanish,
      isSharedAssessment
    );
  }

  function handleSelectedPremiumAssessments(
    cannedTestId: number,
    name: string,
    learnosityIdEnglish: string,
    learnosityIdSpanish: string
  ) {
    const cannedTestExist = selectedPremiumAssessments.some(
      (r) => (r as number) === cannedTestId
    );
    var selectedCannedTestList: Array<number> = new Array<number>();
    if (cannedTestExist) {
      selectedCannedTestList = selectedPremiumAssessments.filter(
        (r) => (r as number) !== cannedTestId
      );
    } else {
      selectedCannedTestList = [...selectedPremiumAssessments];
      selectedCannedTestList.push(cannedTestId);
    }

    const keyValuePair: IKeyValuePair = {
      key: cannedTestId.toString(),
      value: name,
    };
    setSelectedPremiumAssessments(selectedCannedTestList);
    updateActivity(
      keyValuePair,
      "PremiumAssessment",
      learnosityIdEnglish,
      learnosityIdSpanish,
      false
    );
  }

  function setExistingValues() {
    const existingActivities: Array<IAssignmentActivity> | undefined =
      assignment.activities?.filter(
        (r) => r.activityType === Constant.AssignmentActivityType.ASSESSMENT
      );

    if (existingActivities && existingActivities.length > 0) {
      const existingAssessmentIds: Array<number> = [];
      const existingPremiumAssessmentIds: Array<number> = [];
      existingActivities.forEach((assessment) => {
        if (assessment && assessment.assessmentId) {
          existingAssessmentIds.push(parseInt(assessment.assessmentId));
        }
        if (assessment && assessment.cannedTestId) {
          existingPremiumAssessmentIds.push(parseInt(assessment.cannedTestId));
        }
      });
      setSelectedAssessments(existingAssessmentIds);
      setSelectedPremiumAssessments(existingPremiumAssessmentIds);
    }
  }

  const handlePreviewPopup = (assessment: AssessmentDetails) => {
    if (isActivityValid(assessment.learnosityIdEnglish)) {
      openModal({
        previewId: assessment.learnosityIdEnglish,
        activityIdPreview: parseInt(assessment.assessmentId),
        activityName: assessment.name,
        isProjectorMode: false,
      });
    }
  };

  const handlePremiumAssessmentPreviewPopup = (
    assessment: IPremiumAssessmentDTO
  ) => {
    if (isActivityValid(assessment.learnosityIdEnglish)) {
      openModal({
        previewId: assessment.learnosityIdEnglish,
        activityIdPreview: assessment.cannedTestId,
        activityName: assessment.name,
        isProjectorMode: false,
        subjectId: assessment.subjectId,
      });
    }
  };

  const openModal = (activityDetail: IActivityPreviewDetail) => {
    if (!activityDetail.previewId) {
      return false;
    }

    setOpen(true);
    setActivityPreviewDetail(activityDetail);
  };

  const popUpClose = (value) => {
    setOpen(value);
    setActivityPreviewDetail(undefined);
  };

  function isActivityValid(learnosityId: string) {
    if (learnosityId === "") {
      setErrorMessage(
        "You cannot perform this action because there are no questions associated with this assessment."
      );
      setErrorMessagePopup(true);
      return false;
    }
    return true;
  }

  const toggleErrorMessagePopup = () => {
    setErrorMessagePopup(false);
  };

  const checkUpgradeRequired = async () => {
    if (getPremiumAssessmentUpgradePermission(props.roleId!)) {
      setUpgrade(true);
    } else {
      setUpgrade(false);
    }
  };

  const validateUpgradeRequired = (successCallback: Function) => {
    if (upgrade) {
      setShowUpgradePopup(true);
    } else {
      successCallback();
    }
  };

  const getSubscriptionPremiumStatus = () => {
    verifyUserHasFullAccessSubscription()
      .then((response) => setSubscriptionIsPremium(response.data))
      .catch((_) => setSubscriptionIsPremium(false));
  };

  useEffect(() => {
    searchAssessments();
    checkUpgradeRequired();
    getSubscriptionPremiumStatus();
  }, []);

  return (
    <Fragment>
      {showLoading && <Loader></Loader>}
      {showDetail && assessmentDetailId && (
        <AssessmentDetailPopup
          assessmentId={assessmentDetailId}
          toggleDetailPopup={setShowDetail}
        />
      )}
      {errorMessagePopup && (
        <MessagePopup
          message={errorMessage}
          togglePopup={toggleErrorMessagePopup}
        />
      )}
      {activityPreviewDetail && (
        <QuestionPreview
          modal={openModal}
          show={open}
          changeValue={popUpClose}
          activityRef={activityPreviewDetail.previewId}
          activityIdPreview={activityPreviewDetail.activityIdPreview}
          activityName={activityPreviewDetail.activityName}
          isProjectorMode={activityPreviewDetail.isProjectorMode}
          subjectId={activityPreviewDetail.subjectId}
        ></QuestionPreview>
      )}
      {showUpgradePopup && (
        <UpGradeMessagePopup
          togglePopup={setShowUpgradePopup}
          roleId={props.roleId! ?? 0}
          message={Constant.UPGRADE_MESSAGE_SCHOOL_ACCOUNT}
        />
      )}
      <div className="min-w-0 block lg:col-span-9 xl:col-span-10 w-full sm:px-5">
        <div className="bg-gray-100  w-full mb-5 rounded-lg p-5">
          <div className="mb-20">
            <div>
              {!upgrade && // Premium assignment not available for teacher free
                premiumAssessments &&
                premiumAssessments.length > 0 && (
                  <div className="bg-white py-6 px-5 mb-10">
                    <fieldset>
                      <div className="flex gap-2">
                        <LogoPLShootingStarIcon className="w-8 h-8" />
                        <h3 className="mb-2 text-lg">Premium Assessments</h3>
                      </div>

                      <div className="shadow">
                        <table className="table w-full">
                          <thead className="mb-5">
                            <tr className=" bg-light-violet text-white py-3 px-3 overflow-hidden">
                              <th className="text-left p-3"></th>

                              <th className="text-left p-3 text-sm w-1/2 font-normal">
                                Name
                              </th>

                              <th className="text-center p-3 text-sm font-normal">
                                Preview
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {(premiumAssessments &&
                              premiumAssessments.length > 0 &&
                              premiumAssessments.map((assessment, index) => (
                                <tr
                                  key={index}
                                  className={`${
                                    index % 2 === 0
                                      ? "bg-white"
                                      : "bg-light-violet/10"
                                  } ${
                                    canActivityUpdate &&
                                    !upgrade &&
                                    !(
                                      assessment.isLocked &&
                                      props.roleId ==
                                        constant.UserRoleId.SchoolTeacher
                                    )
                                      ? ""
                                      : " opacity-50 cursor-not-allowed"
                                  }`}
                                >
                                  <td className="text-center mt-5  p-3">
                                    {!(
                                      assessment.isLocked &&
                                      props.roleId ===
                                        constant.UserRoleId.SchoolTeacher
                                    ) && (
                                      <input
                                        id="chk-premium-assessment"
                                        type="checkbox"
                                        className={`checkbox h-4 w-4 cursor-pointer text-primary-violet border-gray-300 focus:ring-primary-violet ${
                                          canActivityUpdate &&
                                          !upgrade &&
                                          !(
                                            assessment.isLocked &&
                                            props.roleId ==
                                              constant.UserRoleId.SchoolTeacher
                                          )
                                            ? ""
                                            : "pointer-events-none"
                                        }`}
                                        onChange={(e) => {
                                          validateUpgradeRequired(() => {
                                            if (
                                              assessment.isLocked &&
                                              e.target.checked
                                            ) {
                                              toast.error(
                                                "Cannot assign locked assessment"
                                              );
                                              return;
                                            }
                                            handleSelectedPremiumAssessments(
                                              assessment.cannedTestId,
                                              getPremiumAssessmentName(
                                                assessment.name,
                                                assessment.cannedTestType,
                                                assessment.cannedTestTypeId
                                              ),
                                              assessment.learnosityIdEnglish,
                                              assessment.learnosityIdSpanish
                                            );
                                          });
                                        }}
                                        checked={selectedPremiumAssessments.some(
                                          (r) =>
                                            (r as number) ===
                                            assessment.cannedTestId
                                        )}
                                      />
                                    )}
                                  </td>

                                  <td className="text-left text-sm mt-5  p-3">
                                    {assessment.isLocked && (
                                      <LockIcon
                                        className="inline-flex mr-2 w-5 h-5 text-gray-500"
                                        title="Assessment locked by admin"
                                      />
                                    )}
                                    {getPremiumAssessmentName(
                                      assessment.name,
                                      assessment.cannedTestType,
                                      assessment.cannedTestTypeId
                                    )}
                                  </td>
                                  <td>
                                    <div
                                      className="flex mt-1.5 items-center justify-center"
                                      onClick={() => {
                                        validateUpgradeRequired(() => {
                                          handlePremiumAssessmentPreviewPopup(
                                            assessment
                                          );
                                        });
                                      }}
                                    >
                                      <PreviewIcon className="mr-3 h-5 w-5 text-xs font-light cursor-pointer text-gray-400 hover:text-secondary-color transaction ease-in-out duration-300" />
                                    </div>
                                  </td>
                                </tr>
                              ))) || (
                              <tr>
                                <td
                                  colSpan={4}
                                  className="text-center mt-5  p-3"
                                >
                                  No Data Available
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </fieldset>
                  </div>
                )}
              <div className="bg-white py-6 px-5">
                <div className="flex flex-col xl:flex-row xl:items-end xl:justify-between">
                  <h3 className="text-lg order-last xl:order-1">
                    Full Assessment List
                  </h3>
                  {subscriptionIsPremium && (
                    <div className="order-1 mb-4 xl:mb-0 xl:order-last grid grid-cols-2 gap-x-4 gap-y-3 2xl:grid-cols-none 2xl:grid-rows-none 2xl:flex 2xl:gap-6 md:items-center sm:justify-end">
                      <div className="flex items-center">
                        <SharedByIcon byTeacher={false} />
                        <span className="text-xs ml-2 text-gray-500">
                          Shared by you
                        </span>
                      </div>
                      <div className="flex items-center">
                        <SharedByIcon byTeacher={true} />
                        <span className="text-xs ml-2 text-gray-500">
                          Teacher Shared
                        </span>
                      </div>
                      <div className="flex items-center">
                        <div className="ml-1 pointer-events-auto inline-flex items-baseline px-2 py-0 rounded-full text-xs font-medium bg-[#FFDB3A] text-dark-blue md:mt-2 lg:mt-0">
                          D
                        </div>
                        <span className="text-xs ml-2 text-gray-500">
                          District Assessment
                        </span>
                      </div>
                      <div className="flex items-center">
                        <div className="ml-1 pointer-events-auto inline-flex items-baseline px-2 py-0 rounded-full text-xs font-medium bg-[#F89F5A] text-dark-blue md:mt-2 lg:mt-0">
                          A
                        </div>
                        <span className="text-xs ml-2 text-gray-500">
                          School Admin
                        </span>
                      </div>
                    </div>
                  )}
                </div>
                <div className="shadow overflow-x-auto xl:overflow-x-visible mt-3">
                  <table className="table w-full ">
                    <thead className="mb-5">
                      <tr className=" bg-light-violet text-white py-3 px-3 overflow-hidden">
                        <th className="text-left p-3"></th>
                        <th className="text-center p-3 text-sm font-normal">
                          Date
                        </th>
                        <th className="text-left p-3 text-sm w-1/2 font-normal">
                          Name
                        </th>
                        <th className="text-left p-3 text-sm font-normal">
                          Code
                        </th>
                        <th className="text-center p-3 text-sm font-normal">
                          Preview
                        </th>
                        {/* <th className="text-center p-3 text-sm font-normal">
                        Completed
                      </th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {assessments &&
                        assessments.data
                          .filter(
                            (r) =>
                              r.questionsCount > 0 &&
                              r.learnosityIdEnglish &&
                              r.learnosityIdEnglish.trim() !== ""
                          )
                          .map((assessment, index) => {
                            return (
                              <tr
                                key={index}
                                className={`${
                                  selectedAssessments.some(
                                    (r) =>
                                      (r as number) ===
                                      parseInt(assessment.assessmentId)
                                  )
                                    ? "bg-light-violet/20 border border-light-violet"
                                    : ""
                                } hover:bg-light-violet/20 transition ease-in-out duration-300 ${
                                  index % 2 === 0
                                    ? "bg-white"
                                    : "bg-light-violet/10"
                                }${
                                  canActivityUpdate
                                    ? ""
                                    : " opacity-50 cursor-not-allowed"
                                }  `}
                              >
                                <td className="text-center mt-5 p-3">
                                  <input
                                    id="styled-checkbox-2"
                                    type="checkbox"
                                    className={`checkbox h-4 w-4 cursor-pointer text-primary-violet border-gray-300 focus:ring-primary-violet ${
                                      canActivityUpdate
                                        ? ""
                                        : "pointer-events-none"
                                    }`}
                                    onChange={() =>
                                      handleSelectedAssessment(
                                        parseInt(assessment.assessmentId),
                                        assessment.name,
                                        assessment.isSharedAssessment,
                                        assessment.learnosityIdEnglish,
                                        assessment.learnosityIdSpanish
                                      )
                                    }
                                    checked={selectedAssessments.some(
                                      (r) =>
                                        (r as number) ===
                                        parseInt(assessment.assessmentId)
                                    )}
                                  />
                                </td>
                                <td className="text-center text-sm mt-5  p-3">
                                  {Moment(assessment.createdDate).format(
                                    "MM-DD-yyyy"
                                  )}
                                </td>
                                <td className="text-sm mt-5 p-3 relative">
                                  <a
                                    href="#!"
                                    className="text-dark-blue underline"
                                    onClick={() =>
                                      getAssessmentDetails(
                                        parseInt(assessment.assessmentId)
                                      )
                                    }
                                  >
                                    {assessment.name}
                                  </a>
                                  {assessment.isSharedAssessment && " "}
                                  {assessment.isSharedAssessment &&
                                    assessment.sharedTypeId ===
                                      Constant.AssessmentSharedType
                                        .ClassShared &&
                                    assessment.authorId === props.userId && (
                                      <SharedByIcon />
                                    )}
                                  {assessment.isSharedAssessment &&
                                    assessment.sharedTypeId ===
                                      Constant.AssessmentSharedType
                                        .ClassShared &&
                                    assessment.authorId !== props.userId && (
                                      <SharedByIcon byTeacher={true} />
                                    )}
                                  {assessment.isSharedAssessment &&
                                    assessment.sharedTypeId ===
                                      Constant.AssessmentSharedType
                                        .SchoolShared && (
                                      <div className="pointer-events-auto inline-flex items-baseline px-2 py-0 rounded-full text-xs font-medium bg-[#F89F5A] text-dark-blue md:mt-2 lg:mt-0">
                                        A
                                      </div>
                                    )}
                                  {assessment.sharedTypeId ===
                                    Constant.AssessmentSharedType
                                      .DistrictShared && (
                                    <div className="pointer-events-auto inline-flex items-baseline px-2 py-0 rounded-full text-xs font-medium bg-[#FFDB3A] text-dark-blue md:mt-2 lg:mt-0">
                                      D
                                    </div>
                                  )}
                                  {"  "}
                                  {isDisplayContentViewMessage(
                                    props.roleId,
                                    assessment.canAssign,
                                    assessment.canView,
                                    assessment?.sharedTypeId,
                                    assessment?.authorId,
                                    props.userId
                                  ) && (
                                    <span className="text-red-500">
                                      {
                                        "Set to ‘assign only’. Content cannot be viewed."
                                      }
                                    </span>
                                  )}
                                </td>
                                <td className="text-sm mt-5 p-3">
                                  {assessment.code}
                                </td>
                                <td>
                                  {isAllowViewAssignmentAssessment(
                                    props.roleId,
                                    assessment.canView,
                                    Constant.AssignmentActivityType.ASSESSMENT,
                                    assessment?.authorId,
                                    props.userId,
                                    assessment?.sharedTypeId
                                  ) && (
                                    <div
                                      className="flex mt-1.5 items-center justify-center"
                                      onClick={() =>
                                        handlePreviewPopup(assessment)
                                      }
                                    >
                                      <PreviewIcon className="mr-3 h-5 w-5 text-xs font-light cursor-pointer text-gray-400 hover:text-secondary-color transaction ease-in-out duration-300" />
                                    </div>
                                  )}
                                </td>
                                {/* <td className="text-center text-sm mt-5 p-3">
                                0
                              </td> */}
                              </tr>
                            );
                          })}
                      {assessments?.data?.length === 0 && (
                        <tr className="bg-white">
                          <td colSpan={6} className="text-sm p-3">
                            No records found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Assessment;

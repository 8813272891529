import { AxiosResponse } from "axios";
import { AxiosHelper } from "../../environment/axiosConfig";
import ITeacherClass from "../../model/interface/teacherclass";
import APIConstant from "../../utils/constant/apiConstant";
import { getTimezoneOffset, GetCurrentSchoolYearId } from "../../utils/helper";
import { ITeachersBySchoolAndSubjectQuery, ITeachersBySchoolAndSubjectResponse } from "../../model/teacher/teacherSchoolSubject";
import qs from "qs";
import { ITeacherProfilesMarketing } from "../../model/teacher/teacherProfilesMarketing";
import { IResult } from "../../model/interface/IResult";

export const getAllStudents = (
  teacherId: number,
  roleId: number,
  subjectId: number,
  schoolYearId?: number
) => {
  return AxiosHelper.axiosInstance().get(APIConstant.APP_TEACHERSTUDENTS_ALL, {
    params: {
      teacherId: teacherId,
      RoleId: roleId,
      SubjectId: subjectId,
      SchoolYearId: schoolYearId,
    },
  });
};

export const getAllClassesBySubject = async (
  teacherId: number,
  roleId: number,
  subjectId: number | null,
  schoolYearId: number,
  schoolId: number,
  schoolYearIdMatchType?: string
) => {
  var _schoolYearId =
    schoolYearId === 0 ? await GetCurrentSchoolYearId(schoolId) : schoolYearId;
  const params =  {
    teacherId: teacherId,
    RoleId: roleId,
    SubjectId: subjectId,
    schoolId: schoolId,
    schoolYearId: _schoolYearId,
  };
  return AxiosHelper.axiosInstance().get(APIConstant.APP_TEACHERCLASSES_ALL, {
    params: {
      ...params,
      ...(schoolYearIdMatchType && {schoolYearIdMatchType: schoolYearIdMatchType})
    },
  });
};

export const getClassesByTeachers = (
    teacherUserIds: number[] | undefined,
    schoolYearId?: number
  ) => {
    const params = {
      teacherUserIds: teacherUserIds,
      schoolYearId: schoolYearId
    };
    return AxiosHelper.axiosInstance().post(
      APIConstant.APP_CLASSES_BY_Teachers,
      params,
      {}
    );
  };

export const getAllTags = () => {
  return AxiosHelper.axiosInstance().get(APIConstant.APP_TAGS_ALL, {});
};

export const getSchoolTeachers = (schoolId: number) => {
  return AxiosHelper.axiosInstance().get(
    APIConstant.Teacher_API.GetSchoolTeachers.replace(
      "{schoolId}",
      schoolId.toString()
    ),
    {}
  );
};

export const GetChatSettings = (teacherId: number) => {
  return AxiosHelper.axiosInstance().get(
    APIConstant.Teacher_API.GetChatSettings,
    {
      params: {
        teacherId: teacherId,
      },
    }
  );
};

export const UpdateChatSettings = (settings: any) => {
  return AxiosHelper.axiosInstance().put(
    APIConstant.Teacher_API.UpdateChatSettings,
    {
      ClassId: settings.classId,
      TeacherId: settings.teacherId,
      AllowStudentChat: settings.allowStudentChat,
    }
  );
};

export const UpdateAvailableTeachingTime = (settings: any) => {
  return AxiosHelper.axiosInstance().put(
    APIConstant.Teacher_API.UpdateAvailableTeachingTime,
    {
      TeacherId: settings.teacherId,
      StartTime: settings.startTime,
      EndTime: settings.endTime,
    }
  );
};

export const getTeacherSchools = (userId: number) => {
  return AxiosHelper.axiosInstance().get(
    APIConstant.Teacher_API.GetTeacherSchools,
    { params: { UserId: userId } }
  );
};

export const UpdateTeacherSchools = (userId: number, schoolId) => {
  return AxiosHelper.axiosInstance().put(
    APIConstant.Teacher_API.UpdateTeacherSchools,
    { UserId: userId, SchoolId: schoolId }
  );
};

export const GetUSATPGuid = (userId: number) => {
  return AxiosHelper.axiosInstance().get(
    APIConstant.Teacher_API.GetTeacherGuid,
    { params: { UserId: userId } }
  );
};

export const getClassTeacherByClassId = (
  schoolId: number,
  schoolYearId: number,
  subjectId?: number,
  classIds?: any
) => {
  const params = {
    schoolId: schoolId,
    schoolYearId: schoolYearId,
    subjectId: subjectId,
    classIds: classIds,
  };

  return AxiosHelper.axiosInstance().post(
    APIConstant.Teacher_API.GetClassTeacherByClassId,
    params,
    {}
  );
};

export const getClassStudentByClassId = (
  schoolId: number,
  subjectId?: number,
  classIds?: any
) => {
  const params = {
    schoolId: schoolId,
    subjectId: subjectId,
    classIds: classIds,
  };

  return AxiosHelper.axiosInstance().post(
    APIConstant.Teacher_API.GetClassStudentByClassId,
    params,
    {}
  );
};

export const getActivitySearchReport = (
  schoolId: number,
  schoolYearId: number,
  subjectId?: number,
  classIds?: any,
  teacherUserIds?: any,
  studentUserIds?: any,
  activityTypes?: any,
  dateCompletedStart?: any,
  dateCompletedEnd?: any,
  pageNumber?: number,
  pageSize?: number,
  retriesOnly?: boolean,
  orderByColumn?: string,
  sortByDesc?: boolean
) => {
  const params = {
    schoolId: schoolId,
    schoolYearId: schoolYearId,
    subjectId: subjectId,
    classIds: classIds,
    teacherUserIds: teacherUserIds,
    studentUserIds: studentUserIds,
    activityTypes: activityTypes,
    dateCompletedStart: dateCompletedStart,
    dateCompletedEnd: dateCompletedEnd,
    pageNumber: pageNumber,
    pageSize: pageSize,
    retriesOnly: retriesOnly,
    orderByColumn: orderByColumn,
    sortByDesc: sortByDesc,
  };

  return AxiosHelper.axiosInstance().post(
    APIConstant.Teacher_API.GetStudentActivitySearchReport,
    params,
    {}
  );
};

export const exportActivitySearchReportExcel = (
  pageNumber: number,
  pageSize: number,
  schoolId: number,
  schoolYearId: number,
  subjectId?: number,
  classIds?: any,
  teacherUserIds?: any,
  studentUserIds?: any,
  activityTypes?: any,
  dateCompletedStart?: any,
  dateCompletedEnd?: any,
  filters?: string,
  retriesOnly?: boolean
) => {
  const params = {
    schoolId: schoolId,
    schoolYearId: schoolYearId,
    subjectId: subjectId,
    classIds: classIds,
    teacherUserIds: teacherUserIds,
    studentUserIds: studentUserIds,
    activityTypes: activityTypes,
    dateCompletedStart: dateCompletedStart,
    dateCompletedEnd: dateCompletedEnd,
    pageNumber: pageNumber,
    pageSize: pageSize,
    timezoneOffset: getTimezoneOffset(),
    filters: filters,
    retriesOnly: retriesOnly,
  };

  return AxiosHelper.axiosInstance().post(
    APIConstant.Teacher_API.GetStudentActivitySearchReportExcel,
    params,
    { responseType: "blob" }
  );
};

export const getLiftoffActivityReport = (
  schoolId: number,
  subjectIds?: any,
  classIds?: any,
  gradetId?: number,
  studentUserIds?: any,
  activityTypes?: any,
  dateCompletedStart?: any,
  dateCompletedEnd?: any,
  orderByColumn?: string,
  sortByDesc?: boolean,
  pageSize?: number,
  pageNumber?: number,
  schoolYearId?: number
) => {
  const params = {
    schoolId: schoolId,
    subjectIds: subjectIds,
    classIds: classIds,
    gradeIds: gradetId ? [gradetId] : [],
    studentIds: studentUserIds,
    activityTypes: activityTypes,
    startDate: dateCompletedStart,
    endDate: dateCompletedEnd,
    pageNumber: pageNumber,
    orderByColumn: orderByColumn,
    sortByDesc: sortByDesc,
    pageSize: pageSize,
    schoolYearId: schoolYearId,
  };

  return AxiosHelper.axiosInstance().post(
    APIConstant.Teacher_API.GetLiftoffActivitySearchReport,
    params,
    {}
  );
};
export const exportLiftoffActivityReport = (
  schoolId: number,
  subjectIds?: any,
  classIds?: any,
  gradetId?: number,
  studentUserIds?: any,
  activityTypes?: any,
  dateCompletedStart?: any,
  dateCompletedEnd?: any,
  schoolYearId?: number
) => {
  const params = {
    schoolId: schoolId,
    subjectIds: subjectIds,
    classIds: classIds,
    gradeIds: gradetId ? [gradetId] : [],
    studentIds: studentUserIds,
    activityTypes: activityTypes,
    startDate: dateCompletedStart,
    endDate: dateCompletedEnd,
    schoolYearId: schoolYearId,
    timezoneOffset: getTimezoneOffset(),
  };

  return AxiosHelper.axiosInstance().post(
    APIConstant.Teacher_API.GetLiftoffActivitySearchReportExcel,
    params,
    { responseType: "blob" }
  );
};

export const getArchivedClasses = async (
  schoolYearId: number,
  subjectId?: number,
  schoolAccountId?: number
) => {
  var _schoolYearId =
    schoolYearId === 0 ? await GetCurrentSchoolYearId(0) : schoolYearId;
  return AxiosHelper.axiosInstance().get<Array<ITeacherClass>>(
    APIConstant.Teacher_API.GetArchivedClasses,
    {
      params: {
        schoolYearId: _schoolYearId,
        subjectId: subjectId,
        schoolAccountId: schoolAccountId,
      },
    }
  );
};
export const getHiddenClasses = async (
  schoolYearId: number,
  subjectId?: number,
  schoolAccountId?: number
) => {
  var _schoolYearId =
    schoolYearId === 0 ? await GetCurrentSchoolYearId(0) : schoolYearId;
  return AxiosHelper.axiosInstance().get<Array<ITeacherClass>>(
    APIConstant.Teacher_API.GetHiddenClasses,
    {
      params: {
        schoolYearId: _schoolYearId,
        subjectId: subjectId,
        schoolAccountId: schoolAccountId,
      },
    }
  );
};

export const getAuthoredQuestionCount = (): Promise<AxiosResponse<number>> => {
  return AxiosHelper.axiosInstance().get(
    APIConstant.Teacher_API.GetTotalQuestionCount
  );
};

export const GetTeachersBySchoolAndSubject = (
  query: ITeachersBySchoolAndSubjectQuery
): Promise<AxiosResponse<ITeachersBySchoolAndSubjectResponse[]>> => 
  AxiosHelper.axiosInstance().get<ITeachersBySchoolAndSubjectResponse[]>(
    APIConstant.Teacher_API.GetTeachersBySchoolAndSubject,
    {
      params: {...query},
      paramsSerializer: (params) => 
        qs.stringify(params),
    },
  );

export const GetTeacherProfilesMarketing = (
  userId: number,
): Promise<AxiosResponse<ITeacherProfilesMarketing>> =>
AxiosHelper.axiosInstance().get<ITeacherProfilesMarketing>(
  APIConstant.Teacher_API.GetMarketingSurvey,
  {
    params: { userId },
    paramsSerializer: (params) => 
      qs.stringify(params),
  },
);

export const UpdateTeacherProfilesMarketing = (
  params: ITeacherProfilesMarketing,
): Promise<AxiosResponse<IResult<boolean>>> =>
AxiosHelper.axiosInstance().put<IResult<boolean>>(
  APIConstant.Teacher_API.UpdateMarketingSurvey,
  params,
);

import { LogoIcon } from "../../assets/icons";

function RecoveryCodeExpired() {
  return (
    <>
      <div className="min-h-screen bg-white flex">
        <div className="flex-1 flex flex-col py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              <LogoIcon />
              <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
                Link Expired
              </h2>
              <p>
                Sorry, this link has expired.{" "}
                <p>
                  The content you were looking for is no longer available. You
                  can reset password again with Forgot Password option.
                  <br />
                  <br />
                  <p>
                    Please contact the administrator for further assistance or
                    if you have any questions.
                  </p>
                </p>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default RecoveryCodeExpired;

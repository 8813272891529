import {} from "axios";
import { axiosInstance } from "../../../environment/axiosConfig";
import APIConstant from "../../../utils/constant/apiConstant";

export function validateRecoveryCode(code: string) {
  return axiosInstance.get(APIConstant.RegisterUser_API.ValidateRecoveryCode, {
    headers: {
      "Cache-Control": "no-cache",
    },
    params: {
      recoverycode: code,
    },
  });
}
export function registerUser(
  accountId: string,
  userName: string,
  passwordRecoveryCode: string,
  newPassword?: string
) {
  const data = {
    accountId: accountId,
    userName: userName,
    recoveryCode: passwordRecoveryCode,
    password: newPassword,
  };
  return axiosInstance.put<any>(
    APIConstant.RegisterUser_API.RegisterUser,
    data
  );
}

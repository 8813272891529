import { ErrorMessagePopupProps } from "./interfaces";

const themeList = {
  ELEMENTARY: {
    headerBg: "bg-[#34d9a5]",
    tryAgainBtn: "bg-[#34d9a5] hover:bg-opacity-80",
  },
  MIDDLESCHOOL: {
    headerBg: "bg-yellow-400",
    tryAgainBtn:
      "align-bottom inline-flex font-bold items-center justify-center cursor-pointer leading-5 transition-colors duration-150  focus:outline-none px-5 py-3 rounded-lg text-lg text-white bg-primary-violet hover:shadow-lg hover:bg-dark-violet",
  },
  KINDERGARDEN: {
    headerBg: "bg-gray-50",
    tryAgainBtn:
      "bg-primary-violet border border-transparent hover:bg-dark-violet",
  },
};

const ErrorMessagePopup = (props: ErrorMessagePopupProps) => {
  const { 
    theme = "KINDERGARDEN", 
    title = "Error",
    message = "",
    okText = "OK",
    okAction,
    cancelText = "Cancel",
    cancelAction
  } = props;

  return (
    <div 
      className="fixed inset-0 flex items-center bg-black bg-opacity-50 sm:items-center sm:justify-center"
      style={{ zIndex: 11000 }}
    >
      <div 
        className="w-full flex flex-col justify-between overflow-hidden h-auto max-h-80percent bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:w-[50%]"
        role="dialog"
      >
        <header>
          <div className={`${themeList[theme].headerBg} px-4 py-4 sm:px-4`}>
            <div className="flex items-start justify-between space-x-3">
              <div className="space-y-1">
                <div className="text-lg text-gray-900">{title}</div>
              </div>
            </div>
          </div>
        </header>
        <div>
          <div className="w-full relative overflow-auto px-6 py-6 " dangerouslySetInnerHTML={{ __html: message }}></div>
        </div>
        <footer className="flex items-center justify-end px-6 py-4 sm:flex-row bg-gray-50 dark:bg-gray-800">
          <div className="flex-shrink-0">
            <div className="space-x-3 flex justify-end">
              {cancelAction && (
                <button
                  className="align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 rounded-lg text-sm text-gray-600 border-gray-300 border dark:text-gray-400 focus:outline-none active:bg-transparent hover:border-gray-500 focus:border-gray-500 active:text-gray-500 focus:shadow-outline-gray w-full sm:w-auto"
                  type="button"
                  onClick={cancelAction}
                >{cancelText}</button>
              )}
              {okAction && (
                <button
                  className={`${themeList[theme].tryAgainBtn} border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 rounded-lg`}
                  type="button"
                  onClick={okAction}
                >{okText}</button>
              )}
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default ErrorMessagePopup;

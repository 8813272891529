import { Configuration } from "../../../../environment/setup";
import {
  StarIcon,
  StarDarkIcon,
  OutlineMessagesIcon,
} from "../../../../assets/icons/index";
import { getFormatedMathText } from "../../../../utils/helper";
import { IAssessmentQuestion } from "../../../../model/interface/assessmentQuestion";
import { ISelectElementCollapsable } from "../../../../model/interface/selectElement";

interface NonPassageQuestionsDomainStandardsQuestionsProps {
  districtId: number,
  hasDistrictAssessmentQuestionIcon: boolean,
  standard: ISelectElementCollapsable,
  selectedQuestions: Array<IAssessmentQuestion>,
  getStandardQuestions: (standardId: string) => IAssessmentQuestion[],
  getRowsCount: (standardId: string) => number,
  handleSelectedQuestion: (question: IAssessmentQuestion) => void,
  favoriteQuestionsIds: Array<number>,
  handleStandardCollapse: (standard: ISelectElementCollapsable) => void,
  handleFavoriteQuestion: (questionId: number) => void,
  handleFeedbackPopup: (id: number, standardId: number) => void,
  canLoadMoreQuestions: (standardId: string) => boolean,
  handleLoadMoreQuestions: (standardId: string) => void,
  openQuestionPreview: (
    learnosityReferenceId: any,
    questionId: any,
    questionText: any,
    standardId: any,
    spanishLearnosityReferenceId: any
  ) => void,
}


function NonPassageQuestionsDomainStandardsQuestions(props: NonPassageQuestionsDomainStandardsQuestionsProps) {
  const {
    districtId,
    hasDistrictAssessmentQuestionIcon,
    standard,
    selectedQuestions,
    getStandardQuestions,
    getRowsCount,
    handleSelectedQuestion,
    favoriteQuestionsIds,
    handleStandardCollapse,
    handleFavoriteQuestion,
    handleFeedbackPopup,
    canLoadMoreQuestions,
    handleLoadMoreQuestions,
    openQuestionPreview,
  } = props;

  return (
    <div className="overflow-x-auto">
      <table className="table w-full">
        <thead className="mb-5">
          <tr className="border-[3px] border-secondary-teal text-secondary-teal p-3">
            <th className="text-left p-3 text-sm font-normal w-5">
              Select
            </th>
            {hasDistrictAssessmentQuestionIcon && (<th className="text-left text-sm font-normal"></th>)}
            <th className="text-left p-3 text-sm font-normal">
              Question - Click to View
            </th>
            <th className="text-center p-3 text-sm font-normal">
              Point Value
            </th>
            <th className="text-center p-3 text-sm font-normal">
              Question Type
            </th>
            <th className="text-center p-3 text-sm font-normal">
              DOK
            </th>
            <th className="text-center p-3 text-sm font-normal">
              Favorites
            </th>
            <th className="text-center p-3 text-sm font-normal">
              Feedback
            </th>
          </tr>
        </thead>
        <tbody>
          {getStandardQuestions(standard.value)
            .slice(0, getRowsCount(standard.value))
            .map((question, index) => {
              question.questionText = question.questionText?.replaceAll(/\\'s/g, "'s");
              return (
                <tr
                  key={question.questionId}
                  className={`hover:bg-secondary-teal/20 transition easi-in-out duration-300 ${index % 2 === 0 ? "bg-white" : "bg-secondary-teal/10"}`}
                >
                  <td className="text-sm mt-5 p-3">
                    <input
                      type="checkbox"
                      className="focus:ring-secondary-teal h-4 text-secondary-teal border-gray-500"
                      onChange={() => handleSelectedQuestion(question)}
                      checked={selectedQuestions.some(q => q.questionId === question.questionId)}
                    />
                  </td>
                  {hasDistrictAssessmentQuestionIcon && (
                    <td className="text-sm mt-5 flex flex-nowrap items-center">
                      {question.isDistrictAssessmentQuestion && (
                        <img alt="" src={`${Configuration.S3bucketImagePath}/districts/${districtId.toString()}/district-assessment-question-icon.png`}/>
                      )}
                    </td>
                  )}
                  <td className="text-sm mt-5 p-3">
                    <span
                      className="cursor-pointer text-blue-500 w-[26rem] flex flex-wrap hover:underline"
                      onClick={() => openQuestionPreview(
                        question.learnosityReferenceId,
                        question.questionId,
                        question.questionText,
                        question.standardId,
                        question.spanishLearnosityReferenceId
                      )}
                    >
                      {getFormatedMathText(question.questionText ?? "")?.map((element, index) => (
                        <>
                          {element.type === "math" && (<span
                            dangerouslySetInnerHTML={{__html: element.object ?? ""}}
                          ></span>)}
                          {element.type === "text" && (<span
                            dangerouslySetInnerHTML={{__html: element.object ?? "Click to preview"}}
                          ></span>)}
                        </>
                      ))}
                    </span>
                  </td>
                  <td className="text-center text-sm mt-5 p-3">
                    {question.pointsPossible}
                  </td>
                  <td className="text-sm mt-5 p-3">
                    <div className="flex space-x-2">
                      <span className="bg-gray-100 p-1 text-sm">
                        {question.questionType}
                      </span>
                    </div>
                  </td>
                  <td className="text-center text-sm mt-5 p-3">
                    {question.dokId && question.dokId > 0 ? question.dokId : "N/A"}
                  </td>
                  <td className="text-sm mt-5 p-3">
                    {favoriteQuestionsIds.some(q => q === question.questionId) 
                      ? (<StarDarkIcon
                        className="w-5 h-5 cursor-pointer mx-auto fill-current text-yellow-300"
                        onClick={() => handleFavoriteQuestion(question.questionId)}
                        />)
                      : (<StarIcon
                          title="Add to favorites"
                          className="w-5 h-5 cursor-pointer mx-auto fill-current text-gray-500"
                          onClick={() => handleFavoriteQuestion(question.questionId)}
                        />)
                    }
                  </td>
                  <td className="text-sm mt-5 p-3 cursor-pointer">
                    <OutlineMessagesIcon
                      title="Submit feedback"
                      className="w-5 h-5 mx-auto fill-current text-gray-500"
                      onClick={() => handleFeedbackPopup(question.questionId, +standard.value)}
                    />
                  </td>
                </tr>
              )
            })
          }
        </tbody>
      </table>
      <div className="flex justify-between items-center my-2">
        <div className="self-end ml-1">
          <a
            className="cursor-pointer text-sm text-red-400 font-medium underline hover:text-blue-500 hover:no-underline"
            onClick={() => handleStandardCollapse(standard)}
          >
            Collapse all question
          </a>
        </div>
        {canLoadMoreQuestions(standard.value) && (
          <span
            onClick={() => handleLoadMoreQuestions(standard.value)}
            className="flex justify-end cursor-pointer text-blue-500 underline hover:no-underline"
          >
            Load More Questions
          </span>
        )}
      </div>
    </div>
  )
}

export default NonPassageQuestionsDomainStandardsQuestions

import XIcon from "@heroicons/react/outline/XIcon";
import { useEffect, useState } from "react";
import PreviewPage from "../../../teacher/shared/questionPreview/learnosityPreviewPage";

const theme = {
  EL: {
    headerText: "text-gray-700",
    headerBg: "bg-[#a9fb24]",
    closeBtn: "bg-[#a9fb24] hover:bg-opacity-80 hover:shadow-lg text-gray-700",
  },
  K1: {
    headerText: "text-gray-700",
    headerBg: "bg-yellow-400",
    closeBtn:
      "align-bottom inline-flex font-bold items-center justify-center cursor-pointer leading-5 transition-colors duration-150  focus:outline-none px-5 py-3 rounded-lg text-lg text-white bg-primary-violet hover:shadow-lg hover:bg-dark-violet",
  },
  MS: {
    headerText: "text-white",
    headerBg: "bg-site-teal",
    closeBtn:
      "bg-primary-violet border border-transparent hover:bg-dark-violet",
  },
};
interface QuestionExplanationPopupProps {
  showPopUp: (show: boolean) => void;
  videoUrl: string;
  textExplanation: string;
  studentType: string;
  itemReference?: string;
}

const QuestionExplanationPopup = (props: QuestionExplanationPopupProps) => {
  const [arrayOfcomponents, setArrayOfComponents] = useState<Array<any>>();
  const [conatinsMath, setContainsMath] = useState<boolean>(false);
  type objectType = {
    type: string;
    object: any;
  };
  const themeName = props.studentType;

  useEffect(() => {
    if (props.textExplanation) {
      const mainArray: objectType[] = [];
      if (props.textExplanation.includes("<math>")) {
        setContainsMath(true);
        const obj: objectType = {
          type: "math",
          object:
            "<MathJaxContext><MathJax>" +
            props.textExplanation +
            "</MathJax></MathJaxContext>",
        };
        mainArray.push(obj);
      } else {
        const obj: objectType = {
          type: "text",
          object: props.textExplanation,
        };
        mainArray.push(obj);
      }
      setArrayOfComponents(mainArray);
    }
  }, []);
  return (
    <div
      className="fixed inset-0 flex items-center bg-black bg-opacity-50 sm:items-center sm:justify-center"
      style={{ zIndex: 9999 }}
    >
      <div
        className={`xs:w-[100%] flex flex-col overflow-hidden xs:h-auto bg-white dark:bg-gray-800 rounded-lg sm:m-4 sm:w-[75%] ${
          props.videoUrl ? "lg:h-[83%] xl:h-[95%]" : "max-h-80percent"
        } `}
        role="dialog"
      >
        {/* Header */}
        <header>
          <div className={` ${theme[themeName]?.headerBg}  px-4 py-4 sm:px-4 `}>
            <div className="flex items-start justify-between space-x-3">
              <div className="space-y-1">
                <div
                  className={` ${theme[themeName]?.headerText} text-lg font-medium `}
                ></div>
              </div>

              <div className="h-7 flex items-center">
                <button
                  type="button"
                  className="text-gray-400 hover:text-gray-500"
                  onClick={() => props.showPopUp(false)}
                >
                  <span className="sr-only">Close panel</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </header>

        {props.videoUrl && props.videoUrl.length > 0 && (
          <div onContextMenu={(e) => e.preventDefault()} className="w-full h-full relative overflow-hidden flex items-center justify-center px-8 py-6">
            <div className="w-full h-full relative" style={{ maxWidth: '85%' }}>
              <video
                title="Video"
                className="w-full h-full"
                src={props.videoUrl + "?autoPlay=true"}
                controls
                autoPlay
                controlsList="nodownload"
                style={{ display: 'block', margin: 'auto', zIndex: 1000 }}
              >
                Your browser does not support the video tag.
              </video>
            </div>
        </div>
        )}
        {props.textExplanation && props.textExplanation.length > 0 && (
          <div className="flex flex-col w-full h-full relative overflow-auto px-8 py-6 ">
            <div>
              <PreviewPage
                itemRef={props.itemReference}
                isProjectorMode={false}
                isStudentScreen={true}
                isHide={true}
              />
              <div className="text-lg text-gray-700 font-light bg-blue-50 p-8 flex w-full flex-grow rounded-md rounded-t-lg sm:rounded-lg ">
                {conatinsMath && (
                  <div className="mathdiv flex-wrap">
                    {arrayOfcomponents?.map((element, index) => {
                      return (
                        <>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: element.object ?? "",
                            }}
                          ></span>
                        </>
                      );
                    })}
                  </div>
                )}
                {!conatinsMath &&
                  arrayOfcomponents?.map((element, index) => {
                    return (
                      <>
                        {element.type === "text" && (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: element.object ?? "",
                            }}
                          ></div>
                        )}
                      </>
                    );
                  })}
              </div>
            </div>
          </div>
        )}

        <div className="flex flex-col items-center justify-end px-6 py-4 sm:flex-row bg-gray-50 dark:bg-gray-800 rounded-b-lg">
          <div className="flex-shrink-0">
            <div className="space-x-3 flex justify-center">
              <button
                type="button"
                className={` ${theme[themeName]?.closeBtn}  border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 rounded-lg `}
                onClick={() => props.showPopUp(false)}
              >
                {" "}
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuestionExplanationPopup;

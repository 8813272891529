import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { ChevronRightIcon } from "@heroicons/react/solid";
import RouteConstant from "../../../../utils/constant/routeConstant";
import { Configuration } from "../../../../environment/setup";

function AlienArenaResults(props: any) {
  return (
    <div className="min-w-0 w-full">
      <div className="px-4 py-5 lg:px-8 border-b">
        <ol className="flex items-center space-x-2 mb-1">
          <li>
            <Link to={RouteConstant.TeacherRoutes.AlienArenaSessions}>
              <div className="flex items-center">
                <div className="cursor-pointer text-sm text-gray-500 hover:text-gray-700 border-dashed border-b border-gray-400 hover:border-opacity-0">
                  Alien Arena
                </div>
              </div>
            </Link>
          </li>
          <li>
            <div className="flex items-center">
              <ChevronRightIcon className="flex-shrink-0 h-5 w-5 text-gray-400" />
              <span className="ml-1 text-sm text-gray-500 capitalize">
                Result
              </span>
            </div>
          </li>
        </ol>
        <div className=" text-lg font-medium flex gap-2 h-auto sm:h-10 items-center">
          <h1 className="text-primary-violet flex items-center justify-between w-full pr-4">
            Result
          </h1>
        </div>
      </div>
      <div className="px-2 sm:px-6 lg:px-8 py-6">
        <h1 className="text-lg text-gra-700 mb-2">Question 3</h1>
        <div className="w-full flex flex-col xl:flex-row gap-x-6">
          <div className="flex flex-col gap-y-6 md:gap-y-0 md:flex-row gap-x-6 w-full">
            <div className="w-full md:w-84 shadow-lg border rounded-md p-6 text-center">
              <h2 className="text-gray-700 text-xl font-light mb-2">
                Round Winners
              </h2>
              <div className="relative flex flex-col items-center">
                <img
                  src={`${Configuration.S3bucketImagePath}/images/SpaceCadet.png`}
                  className="w-20 mx-auto"
                  alt="SpaceCadet"
                />
                <span className="font-medium text-gray-800 bg-green-400 inline-block px-3 py-1 rounded-md mt-3">
                  Shooting Stars
                </span>
              </div>
            </div>
            <div className="w-full shadow-lg border rounded-md p-6">
              <table className="w-full table">
                <thead>
                  <tr className="bg-light-violet text-white ">
                    <th className="text-left text-sm font-normal py-1 px-2 ">
                      Team
                    </th>
                    <th className="text-left text-sm font-normal py-1 px-2">
                      Question Performance
                    </th>
                    <th className="text-left text-sm font-normal py-1 px-2">
                      Game Score
                    </th>
                    <th className="text-left text-sm font-normal py-1 px-2">
                      Game
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="py-1.5 px-2 text-sm text-gray-500">
                      Rocketeers
                    </td>
                    <td className="py-1.5 px-2 text-sm text-gray-500">
                      0 of 0 correct
                    </td>
                    <td className="py-1.5 px-2 text-sm text-gray-500">0</td>
                    <td className="py-1.5 px-2 text-sm text-gray-500"></td>
                  </tr>
                  <tr className="bg-light-violet/10 hover:bg-light-violet/20">
                    <td className="py-1.5 px-2 text-sm text-gray-500">
                      Shooting Stars
                    </td>
                    <td className="py-1.5 px-2 text-sm text-gray-500">
                      1 of 1 correct
                    </td>
                    <td className="py-1.5 px-2 text-sm text-gray-500">0</td>
                    <td className="py-1.5 px-2 text-sm text-gray-500"></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className="w-full mt-6 xl:mt-0 xl:w-[37rem] bg-gray-50 p-6 rounded-md shadow-lg border flex items-center">
            <img
              src={`${Configuration.S3bucketImagePath}/images/Alien%20Professor.png`}
              className="w-28 transform scale-x-[-1]"
              alt="Alien Professor"
            />
            <div className="ml-3">
              <h2 className="text-gray-700 text-xl font-light">
                Alien Explanation
              </h2>
              <button
                className="bg-primary-violet border border-transparent shadow-sm mt-2 py-2 px-4 justify-center text-sm font-medium text-white hover:bg-dark-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                type="button"
              >
                View Explanation
              </button>
            </div>
          </div>
        </div>
        <div className="w-full flex flex-col gap-y-6 xl:gap-y-0 xl:flex-row gap-x-6 mt-6">
          <div className="w-full xl:w-1/2 bg-gray-50 p-6 rounded-md border shadow-lg">
            <h2 className="text-gray-700 text-xl font-light mb-2">
              Question Summary
            </h2>
            <span className="text-sm text-gray-500">
              View each question your students answered and display which
              students got the answer correct or incorrect. Click the current
              question to review or explain the question to the class after the
              students answer.
            </span>
            <div className="flex flex-wrap gap-1.5 mt-5">
              <div className="flex w-8 h-8 bg-green-400 shadow rounded-md text-sm text-white font-semibold justify-center items-center hover:bg-green-500 cursor-pointer">
                1
              </div>
              <div className="flex w-8 h-8 bg-green-400 shadow rounded-md text-sm text-white font-semibold justify-center items-center hover:bg-green-500 cursor-pointer">
                2
              </div>
              <div className="flex w-8 h-8 bg-green-400 shadow rounded-md text-sm text-white font-semibold justify-center items-center hover:bg-green-500 cursor-pointer">
                3
              </div>
              <div className="flex w-8 h-8 bg-red-400 shadow rounded-md text-sm text-white font-semibold justify-center items-center hover:bg-red-500 cursor-pointer">
                4
              </div>
            </div>
          </div>
          <div className="w-full xl:w-1/2 shadow-lg border rounded-md p-6">
            <span className="text-gray-700 bg-gray-100 text-xl font-light inline-block px-2 rounded-md border">
              <span className="text-primary-violet font-semibold mr-1">
                50%
              </span>
              Correct
            </span>
          </div>
        </div>
        <div className="text-center mt-6">
          <button
            className="bg-primary-violet border border-transparent shadow-sm mt-2 py-2 px-4 justify-center text-sm font-medium text-white hover:bg-dark-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            type="button"
          >
            Next Question
          </button>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(AlienArenaResults);

/**
 * Waits for the result of a function to be true to continue. 
 * The function can be use like: 
 * await waitFor(number, booleanFunction);
 * or 
 * waitFor(number, booleanFunction).then(result => {});
 * @param timeOutMS Ammount of time in milliseconds to wait for a time out
 * @param isReady Async boolean function with the expected condition to fullfill
 * @returns A true value the condition is fullfilled or the timeout reached
 */
export const waitFor = async (timeOutMS: number, isReady: () => Promise<boolean>) => {
  return new Promise(async resolve => {
    const startTime = Date.now();
    while (!(await isReady() || (timeOutMS > 0 && Date.now() - startTime > timeOutMS))) {
      await new Promise(resolve => setTimeout(resolve, 100));
    }
    resolve(true);
  })
}

/**
 * Waits for an element or a list of elements to be present inside of a element container or the document
 * @param containerQuery Identifier (class|id|tag) to locate the container element in which 
 * it will be observed until the elements are found, leave it blank to look in document
 * @param elementQuery Identifier (class|id|tag) to look for in the container children elements
 * @param elementMinCount Minimal ammount of elements needed to be found
 * @param timeOutMS Ammount of time in milliseconds to wait for a time out
 * @returns A list with elements once the elements are found or the timeout reached
 */
export const waitForElementsToRender = async (containerQuery:string, elementQuery: string, elementMinCount: number, timeOutMS: number) => {
  return new Promise(async resolve => {
    const containerElement = containerQuery ? document.querySelector(containerQuery) : document;
    const lookForElements = async (): Promise<boolean> => {
      return !!(containerElement && containerElement.querySelectorAll(elementQuery).length > elementMinCount);
    }
    await waitFor(timeOutMS, lookForElements);
    resolve(containerElement && containerElement.querySelectorAll(elementQuery))
  })
}

import { useCallback, useEffect, useRef, useState } from "react";
import { XIcon } from "@heroicons/react/outline";
import {
  OutlineMessagesIcon,
  StarDarkIcon,
  StarIcon,
} from "../../../../../assets/icons";
import {
  getFavoriteQuestions,
  updateFavoriteQuestion,
} from "../../../../../api/teacher/assessment";
import Feedback from "../../../../shared/feedback";
import constant from "../../../../../utils/constant/constant";
import { initRequest } from "./learnosityItemRequestForReport";
import Classification from "./classification";
import { getRequest } from "../../../../../api/userActivity";

export default function QuestionPreviewFromReport(props: any) {
  const [feedbackQuestionId, setFeedbackQuestionId] = useState<number>(0);
  const [showFeedbackPopup, setShowFeedbackPopup] = useState<boolean>(false);
  const [favoriteQuestions, setFavoriteQuestions] = useState<Array<number>>([]);
  const [standardId, setStandardId] = useState<number>();
  const isInitialized = useRef(false);

  function bindFavoriteQuestions() {
    getFavoriteQuestions(props.userId).then((response) => {
      setFavoriteQuestions(response.data);
    });
  }

  function handleFavoriteQuestion(questionId: number) {
    updateFavoriteQuestion(props.userId, questionId).then((d) => {
      bindFavoriteQuestions();
    });
  }

  function handleFeedbackPopup(id: number) {
    setFeedbackQuestionId(id);
    toggleFeedback(true);
  }

  function toggleFeedback(popupAction: boolean) {
    setShowFeedbackPopup(popupAction);
  }

  const initialization = useCallback(() => {
    if (isInitialized.current) return;
      
    isInitialized.current = true;
    var itemsApp;
    const request = initRequest([props.itemRef], true);
    const callbacks = {
      readyListener: function () {
        var items = itemsApp.getItems();
        DisplayTotalPointValue(items);
      },
      errorListener: function (e) {
        console.log("Error Code ", e.code);
        console.log("Error Message ", e.msg);
        console.log("Error Detail ", e.detail);
      },
    };
    getRequest("items", JSON.stringify(request)).then((response) => {
      if (response.data) {
        itemsApp = window["LearnosityItems"]?.init(response.data, callbacks);
      }
    });
  }, []);

  function DisplayTotalPointValue(items) {
    Object.values(items)?.forEach(function (item: any) {
      var questions = item?.questions;
      var score: number = 0;
      if (questions?.length === 1) {
        var question = questions[0];
        var type = question?.type;
        if (type === "longtextV2") {
          score = question?.validation?.max_score ?? 0;
        } else {
          score = question?.validation?.valid_response?.score ?? 0;
        }
      } else {
        questions.forEach(function (question: any) {
          score = score + question?.validation.valid_response.score;
        });
      }
      if (score > 0) {
        let pointValueEl = document.createElement("div");
        pointValueEl.innerHTML = "Point Value: " + score;
        pointValueEl.className = "point-value float-right mt-1.5";
        var divEl = document.getElementById(item.reference);
        divEl?.appendChild(pointValueEl);
      }
    });
  }

  useEffect(() => {
    if (props.show) {
      initialization();
      bindFavoriteQuestions();
    }
  }, [props.userId, props.show]);
  return (
    <>
      {showFeedbackPopup && (
        <Feedback
          toggleFeedback={toggleFeedback}
          referenceType={constant.FeedbackReferenceType.QUESTION}
          referenceId={feedbackQuestionId}
          subjectName={props.subjectName}
          subjectId={props.subjectId}
          standardId={standardId}
        />
      )}
      {props.show ? (
        <>
          <div className="fixed inset-0 z-10 flex items-center bg-black bg-opacity-50 sm:items-center sm:justify-center">
            <div
              className={`w-full flex flex-col justify-between overflow-hidden h-auto  bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 max-h-80percent sm:max-w-7xl  ${
                props.isProjectorMode ? "" : ""
              }`}
              role="dialog"
            >
              <header>
                {/* Header */}
                <div className="px-4 py-4 bg-gray-50">
                  <div className="flex items-center">
                    <span className="text-lg font-medium text-gray-900 h-6 pr-4">
                      {props.questionIdPreview}
                    </span>
                    <div className="ml-auto mr-4" id={props.itemRef}>
                      {props.itemRef && (
                        <Classification
                          questionId={parseInt(
                            props.itemRef?.split("-")[0] ?? "0"
                          )}
                          standardId={props.standardIds?.join(",").toString()}
                          questionTypeId={
                            props.itemRef?.indexOf("-teacher") !== -1 ? 2 : 1
                          }
                          setStandardId={setStandardId}
                        ></Classification>
                      )}
                    </div>
                    <div className="h-7 flex items-center">
                      <button
                        type="button"
                        className="text-gray-400 hover:text-gray-500"
                        onClick={() => props.changeValue(false)}
                      >
                        <span className="sr-only">Close</span>
                        <XIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                </div>
              </header>
              <div className="w-full relative overflow-auto px-4 py-1">
                <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5 ml-8">
                  <div>
                    <span
                      className="learnosity-item"
                      data-reference={props.itemRef}
                    ></span>
                  </div>
                </div>
              </div>

              <footer className="flex items-center justify-end px-4 py-4 sm:flex-row bg-gray-50 dark:bg-gray-800 mt-4">
                <div className="space-x-3 flex justify-end">
                  {favoriteQuestions.some(
                    (q) => q === props.questionIdPreview
                  ) ? (
                    <>
                      <button
                        className="align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 rounded-lg text-sm text-white border border-transparent bg-primary-green focus:shadow-outline-purple sm:w-auto"
                        type="button"
                        onClick={() =>
                          handleFavoriteQuestion(props.questionIdPreview)
                        }
                      >
                        <StarDarkIcon
                          title="Remove from favorites"
                          className="w-5 h-5 cursor-pointer mx-auto fill-current text-yellow-300"
                        />
                        <span className="ml-2 text-sm">
                          Remove from favorites
                        </span>
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        className="align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 rounded-lg text-sm text-white border border-transparent bg-primary-green focus:shadow-outline-purple sm:w-auto"
                        type="button"
                        onClick={() =>
                          handleFavoriteQuestion(props.questionIdPreview)
                        }
                      >
                        <StarIcon
                          title="Add to favorites"
                          className="w-5 h-5 cursor-pointer mx-auto fill-current text-white"
                        />
                        <span className="ml-2 text-sm">Add to favorites</span>
                      </button>
                    </>
                  )}

                  <>
                    <button
                      className="align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 rounded-lg text-sm text-white border border-transparent bg-primary-green focus:shadow-outline-purple sm:w-auto"
                      type="button"
                      onClick={() =>
                        handleFeedbackPopup(props.questionIdPreview)
                      }
                    >
                      <OutlineMessagesIcon
                        title="Submit feedback"
                        className="w-5 h-5 mx-auto fill-current text-white"
                      />
                      <span className="ml-2 text-sm">Feedback</span>
                    </button>
                  </>
                </div>
              </footer>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
}

import { Fragment, useEffect, useState } from "react";
import Select from "react-select";
import { ISelectElement } from "../../../../../model/interface/selectElement";
import { toast } from "react-toastify";
import { CloseIcon } from "../../../../../assets/icons/index";
import { useTranslation } from "react-i18next";

import * as userContextAction from "../../../../../redux/actions/userContextAction";
import { getSchoolTeachers } from "../../../../../api/teacher/teacher";
import { ClassApi } from "../../../../../api/teacher/classManagementApi";
import InfoMessage from "../../../../shared/infoMessage";
import Profile from "../../../../../model/users/profile";

interface JoinClassProp {
  userContext: userContextAction.UserContextState;
  profile: Profile;
  open: boolean;
  onOkClick: () => void;
  onCancelClick: () => void;
}
const defaultTeacherValue = { label: "Choose Teacher", value: "0" };
const defaultClassValue = { label: "Choose Class", value: "0" };

export default function JoinClassPopUp(props: JoinClassProp) {
  const { t } = useTranslation();
  const [selectedTeacher, setSelectedTeacher] =
    useState<ISelectElement>(defaultTeacherValue);
  const [selectedClass, setSelectedClass] =
    useState<ISelectElement>(defaultClassValue);
  const [password, setPassword] = useState("");
  const [teacherOptions, setTeacherOptions] = useState<Array<ISelectElement>>(
    []
  );
  const [classOptions, setClassOptions] = useState<Array<ISelectElement>>([]);
  const [classes, setClasses] = useState<Array<any>>([]);
  const [showPasswordTextBox, setShowPasswordTextBox] = useState(false);
  const [isValid, setIsValid] = useState(true);

  useEffect(() => {
    getTeachers();
  }, []);

  const getTeachers = () => {
    getSchoolTeachers(props.userContext.schoolId).then((r) => {
      const teachers = r.data.map((t) => {
        return {
          label: t.name,
          value: t.userId,
        };
      });
      setTeacherOptions(teachers);
    });
  };

  const getClasses = (teacherId, studentId) => {
    ClassApi.getTeacherClasses(
      teacherId,
      studentId,
      props.profile.currentSchoolYear.schoolYearId
    ).then((r) => {
      const classes = r.data.map((t) => {
        return {
          label: t.name,
          value: t.classId,
        };
      });
      setClassOptions(classes);
      setClasses(r.data);
    });
  };

  const handleTeacherChange = (option: ISelectElement | null) => {
    if(option) {
      setSelectedTeacher(option);
      getClasses(Number(option.value), props.userContext.userId);
    }
  };

  const handleClassChange = (option: ISelectElement | null) => {
    if(option) {
      setSelectedClass(option);
      const tmpClass = classes.filter(
        (c) => c.classId === option.value
      )[0];
      setShowPasswordTextBox(tmpClass.hasPassword);
    }
  };

  const validate = () => {
    let isValid = true;
    if (parseInt(selectedTeacher.value) === 0) {
      isValid = false;
    } else if (parseInt(selectedClass.value) === 0) {
      isValid = false;
    } else if (showPasswordTextBox && password.length === 0) {
      isValid = false;
    }
    setIsValid(isValid);
    return isValid;
  };

  const handleJoinClass = async () => {
    if (validate()) {
      let isPasswordValidResponse;
      const classId = parseInt(selectedClass.value);
      const teacherId = parseInt(selectedTeacher.value);
      const studentId = props.userContext.userId;
      let isStudentPresentResponse = await ClassApi.isStudentPresentInClass(
        classId,
        studentId
      );

      if (isStudentPresentResponse.data) {
        toast.warning("You have already joined the class");
        return;
      }

      if (showPasswordTextBox) {
        isPasswordValidResponse = await ClassApi.validateClassPassword(
          classId,
          password
        );
      }

      if (
        !showPasswordTextBox ||
        (isPasswordValidResponse && isPasswordValidResponse.data)
      ) {
        ClassApi.joinClass(classId, studentId, teacherId).then((r) => {
          if (r) {
            toast.success("You have successfully joined the class");
            props.onCancelClick();
          }
        });
      } else {
        toast.error("The password entered is incorrect");
      }
    }
  };
  return (
    <Fragment>
      <div className="fixed inset-0 z-[102] flex items-center bg-black bg-opacity-50 sm:items-center sm:justify-center">
        <div className="w-full flex flex-col justify-between  h-auto max-h-80percent bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:max-w-6xl">
          <header className="rounded-t-lg overflow-hidden">
            {/* Header */}
            <div className="px-4 py-4 bg-gray-50">
              <div className="flex items-start justify-between space-x-3">
                <div className="space-y-1">
                  <div className="text-lg font-medium text-gray-900">
                    {t("JoinClass.JoinClass")}
                  </div>
                </div>
                <div className="h-7 flex items-center">
                  <button
                    type="button"
                    className="inline-flex items-center justify-center w-6 h-6 text-gray-400 transition-colors duration-150 rounded dark:hover:text-gray-200 hover: hover:text-gray-700"
                    onClick={() => props.onCancelClick()}
                  >
                    <span className="sr-only">{t("JoinClass.Close")}</span>
                    <CloseIcon />
                  </button>
                </div>
              </div>
            </div>
          </header>
          <div className="w-full z-40 h-72 px-4 py-1">
            <InfoMessage
              message={`All active unlocked assignments associated with ${
                parseInt(selectedClass.value) > 0
                  ? selectedClass.label
                  : "the class"
              } will be automatically added to your account.`}
            />
            <div className="w-full flex items-center justify-start px-6 py-4 sm:flex-row">
              <div className="grid grid-cols-12 w-full gap-6">
                <div className="col-span-1 mt-2">
                  <label className="block text-sm text-gray-500 mb-1">
                    {t("JoinClass.Teacher")}
                  </label>
                </div>
                <div className="col-span-9">
                  <Select
                    value={selectedTeacher}
                    options={teacherOptions}
                    isSearchable={true}
                    onChange={handleTeacherChange}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: "#008fbb",
                      },
                    })}
                    className="max-w-xs"
                  />
                </div>
                {!isValid && parseInt(selectedTeacher.value) === 0 && (
                  <span> Please select teacher </span>
                )}
              </div>
            </div>
            <div className="w-full flex items-center justify-start px-6 py-4 sm:flex-row">
              <div className="grid grid-cols-12 w-full gap-6">
                <div className="col-span-1 mt-2">
                  <label className="block text-sm text-gray-500 mb-1">
                    {t("JoinClass.Class")}
                  </label>
                </div>
                <div className="col-span-9">
                  <Select
                    value={selectedClass}
                    options={classOptions}
                    isSearchable={true}
                    onChange={handleClassChange}
                    isDisabled={selectedTeacher.value === "0"}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: "#008fbb",
                      },
                    })}
                    className="max-w-xs"
                  />
                </div>
                {!isValid && parseInt(selectedClass.value) === 0 && (
                  <span> Please select class </span>
                )}
              </div>
            </div>
            {showPasswordTextBox && (
              <div className="w-full flex items-center justify-start px-6 py-4 sm:flex-row bg-white">
                <div className="grid grid-cols-12 w-full gap-6">
                  <div className="col-span-1 mt-2">
                    <label className="block text-sm text-gray-500 mb-1">
                      {t("JoinClass.Password")}
                    </label>
                  </div>
                  <div className="col-span-9">
                    <input
                      type="password"
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                      className="max-w-xs mt-1 py-1.5 px-3 block w-full shadow-sm focus:ring-primary-violet focus:border-primary-violet border border-gray-300 rounded-md"
                    />
                  </div>
                  {!isValid && password?.length === 0 && (
                    <span> Please enter password to join class </span>
                  )}
                </div>
              </div>
            )}
          </div>

          {/* <!-- Action Buttons -->  */}
          <footer className="flex items-center justify-end px-4 py-4 sm:flex-row bg-gray-50 dark:bg-gray-800 mt-4 rounded-b-lg overflow-hidden">
            <div className="space-x-3 flex justify-end">
              <button
                type="button"
                className="w-auto inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                onClick={() => props.onCancelClick()}
              >
                {t("JoinClass.Close")}
              </button>

              <button
                type="button"
                onClick={() => handleJoinClass()}
                className="align-bottom inline-flex items-center whitespace-nowrap justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 rounded-lg text-sm text-white border border-transparent bg-primary-green focus:shadow-outline-purple w-auto"
              >
                {t("JoinClass.JoinClass")}
              </button>
            </div>
          </footer>
        </div>
      </div>
    </Fragment>
  );
}

export const createShowTranscriptionButton = (feature_id: string) => {
  const showButton = document.createElement("anchor");
  showButton.id = "transcript_" + feature_id;
  showButton.innerHTML = "Show Transcript";
  showButton.className = "underline hover:no-underline";
  showButton.style.cssText = "font-weight:500; color:#3b82f6; ";
  return showButton;
}

export const createTranscriptionContainer = (feature_id: string) => {
  const containerTranscript = document.createElement("div");
  containerTranscript.id = "transcriptDiv_" + feature_id;
  containerTranscript.className =
    "hidden bg-blue-50 text-sm p-6 rounded-lg";
  containerTranscript.style.cssText = "font-weight:500; color:black; ";
  return containerTranscript;
}


export const createCloseTranscriptionButton = (feature_id: string, closeLabel: string) => {
  const closeButton = document.createElement("button");
  closeButton.id = "transcriptClose_" + feature_id;
  closeButton.innerHTML = closeLabel;
  closeButton.className = 
    "mt-5 disabled:opacity-50 align-bottom inline-flex items-center justify-center" +
    " cursor-pointer leading-5 transition-colors duration-150 font-medium" + 
    " focus:outline-none px-4 py-2 rounded-lg text-sm text-white border" + 
    " border-transparent bg-primary-green focus:shadow-outline-purple w-full sm:w-auto";
  return closeButton      
}
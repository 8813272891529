import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { Configuration } from "../../../../../environment/setup";
import Constant from "../../../../../utils/constant/constant";
import RouteConstant from "../../../../../utils/constant/routeConstant";
import useTextToSpeech from "../../shared/textToSpeech/useTextToSpeech";
import SkillStandard from "../../skillPractice/kindergarten/skillStandard";
import FocusAreas from "./focusArea";
import StudyPlan from "../studyPlan/studyPlanStandard";
import VideoQuestionList from "../studyPlan/VideoPractice/videoQuestionList";
import UpgradeButtonControl from "../../../../shared/customButtonControl";
import { getFocusAreas } from "../../../../../api/student/studyPlanApi";

function SubjectHome(props: any) {
  const history = useHistory();
  const { t } = useTranslation();
  const subjectId = parseInt(atob(props.match.params.subjectId));
  const subjectName = atob(props.match.params.subjectName);
  const contentAreaId = parseInt(atob(props.match.params.contentAreaId));
  const [selectedStudyType, setSelectedStudyType] = useState(
    Constant.StudyType.MYSTUDYPLAN
  );
  const [showFocusAreas, setShowFocusAreas] = useState(false);
  const { startSpeaking, stopSpeaking } = useTextToSpeech();

  useEffect(() => {
    getFocusAreas(
      props?.userContext?.userId,
      subjectId
    ).then((d) => {
      setShowFocusAreas(
        d.data.length !== 0
      );
    });
  }, []);

  return (
    <Fragment>
      <div className="h-full pt-4">
        <h2 className="py-3 font-Didact relative text-lg tracking-wide lg:text-xl xl:text-3xl text-white hover:shadow-2xl text-left pl-8 items-center">
          {subjectName.toLocaleUpperCase()}
        </h2>
        <div className="px-4 lg:px-8 grid grid-cols-1 md:grid-cols-5 lg:grid-cols-12 xl:grid-cols-5 md:gap-5 mb-8">
          <div className="col-span-3 lg:col-span-8 xl:col-span-4  order-last md:order-none">
            {selectedStudyType.toLocaleUpperCase() ===
              Constant.StudyType.MYSTUDYPLAN && (
              <StudyPlan
                subjectId={subjectId}
                subjectName={subjectName}
                contentAreaId={contentAreaId}
              ></StudyPlan>
            )}
            {selectedStudyType.toLocaleUpperCase() ===
              Constant.StudyType.VIDEO && (
              <VideoQuestionList
                subjectId={subjectId}
                subjectName={subjectName}
                contentAreaId={contentAreaId}
              ></VideoQuestionList>
            )}

            {selectedStudyType.toLocaleUpperCase() ===
              Constant.StudyType.FOCUSAREAS && (
              <FocusAreas
                subjectId={subjectId}
                subjectName={subjectName}
              ></FocusAreas>
            )}
            {selectedStudyType.toLocaleUpperCase() ===
              Constant.StudyType.SKILLPRACTICE && (
              <SkillStandard
                contentAreaId={contentAreaId}
                onStandardSelected={(standardId: number) => {
                  history.push(
                    RouteConstant.KindergartenStudentRoutes.SkillGameChoose.replace(
                      ":standardId",
                      standardId.toString()
                    )
                  );
                }}
              ></SkillStandard>
            )}
          </div>
          <div className="space-y-6 col-span-2 lg:col-span-4 xl:col-span-1 order-1 md:order-none">
            <div
              title="my study plan"
              className={`${
                selectedStudyType.toLocaleUpperCase() ===
                Constant.StudyType.MYSTUDYPLAN
                  ? "rounded-lg border-8 border-[#FF8700] filter brightness-125"
                  : ""
              }   rounded-lg overflow-hidden cursor-pointer relative group hover:filter hover:brightness-125 transition-all duration-300 `}
              onClick={() => {
                setSelectedStudyType(Constant.StudyType.MYSTUDYPLAN);
              }}
            >
              <div>
                <img
                  alt="My study plan"
                  className=""
                  src={`${Configuration.S3bucketImagePath}/images/student/kindergarten/general/study-plan.jpg`}
                ></img>
              </div>
              <div
                className="absolute bg-center bg-no-repeat top-0"
                onMouseEnter={() => {
                  startSpeaking(
                    t("StudyType.MyStudyPlan"),
                    (props.profile?.plLanguageId ?? 1) === 2 ? 2 : 1
                  );
                }}
                onMouseLeave={() => {
                  stopSpeaking();
                }}
              >
                <img
                  alt="study plan"
                  className=""
                  src={`${Configuration.S3bucketImagePath}/images/student/kindergarten/shapes/cloud-orange.png`}
                />
                <span className="rounded-lg absolute left-0 right-0 mx-auto bottom-0 font-Didact my-6 lg:my-1 1xl:my-3 xxxl:my-6 group-hover:font-bold transition-all duration-300  text-2xl tracking-wide lg:text-xl xl:text-2xl  text-center items-center">
                  {t("StudyType.MyStudyPlan")}
                </span>
              </div>
            </div>
            <UpgradeButtonControl
              controlType={"div"}              
              userContext={props?.userContext}
              isUpgradeRequired={props?.profile?.isUpgradeRequired}                   
              isRoleCheck={true}               
              className={`${
                selectedStudyType.toLocaleUpperCase() ===
                Constant.StudyType.VIDEO
                  ? "rounded-lg border-8 border-[#C038D3] filter brightness-125"
                  : ""
              }   ${props?.profile?.isUpgradeRequired ? " cursor-not-allowed bg-opacity-20 " : " cursor-pointer hover:filter hover:brightness-125 transition-all duration-300 "} rounded-lg overflow-hidden relative group `}
              onClick={() => {
                setSelectedStudyType(Constant.StudyType.VIDEO);
              }}
              title="Videos"
            >           
              <div>
                <img
                  alt="videos"
                  className=""
                  src={`${Configuration.S3bucketImagePath}/images/student/kindergarten/general/study-video.jpg`}
                ></img>
              </div>
             
              <div
                className="absolute bg-center bg-no-repeat top-0"
                onMouseEnter={() => {
                  startSpeaking(
                    t("Videos"),
                    (props.profile?.plLanguageId ?? 1) === 2 ? 2 : 1
                  );
                }}
                onMouseLeave={() => {
                  stopSpeaking();
                }}
              >
                <img
                  alt="videos"
                  className=""
                  src={`${Configuration.S3bucketImagePath}/images/student/kindergarten/shapes/cloud-violet.png`}
                />
                <span className={"rounded-lg absolute left-0 right-0 mx-auto bottom-0 font-Didact my-6 lg:my-1 1xl:my-3 xxxl:my-6 text-2xl tracking-wide lg:text-xl xl:text-2xl  text-center items-center" + 
                 (props?.profile?.isUpgradeRequired ? "" : "  group-hover:font-bold transition-all duration-300 ")
              }>
                  {t("StudyPlan.Videos")}
                </span>
              </div>
            </UpgradeButtonControl>

            {showFocusAreas && (
              <div
                title="Focus areas"
                className={`${
                  selectedStudyType.toLocaleUpperCase() ===
                  Constant.StudyType.FOCUSAREAS
                    ? "rounded-lg border-8 border-[#49BA1A] filter brightness-125"
                    : ""
                }   rounded-lg overflow-hidden cursor-pointer relative group hover:filter hover:brightness-125 transition-all duration-300 `}
                onClick={() => {
                  setSelectedStudyType(Constant.StudyType.FOCUSAREAS);
                }}
              >
                <div>
                  <img
                    alt="focus areas"
                    className=""
                    src={`${Configuration.S3bucketImagePath}/images/student/kindergarten/general/recommended-topics.jpg`}
                  ></img>
                </div>
                <div
                  className="absolute bg-center bg-no-repeat top-0 "
                  onMouseEnter={() => {
                    startSpeaking(
                      t("StudyType.FocusAreas"),
                      (props.profile?.plLanguageId ?? 1) === 2 ? 2 : 1
                    );
                  }}
                  onMouseLeave={() => {
                    stopSpeaking();
                  }}
                >
                  <img
                    alt="focus areas"
                    className=""
                    src={`${Configuration.S3bucketImagePath}/images/student/kindergarten/shapes/cloud-green.png`}
                  />
                  <span className="rounded-lg absolute left-0 right-0 mx-auto  bottom-0 font-Didact my-6 lg:my-1 1xl:my-3 xxxl:my-6 group-hover:font-bold transition-all duration-300  text-2xl tracking-wide lg:text-xl xl:text-2xl  text-center items-center">
                    {t("StudyType.FocusAreas")}
                  </span>
                </div>
              </div>
            )}
            {props.profile?.isPLSkillPracticeActive && (
              <div
                title="Skills practice"
                className={`${
                  selectedStudyType.toLocaleUpperCase() ===
                  Constant.StudyType.SKILLPRACTICE
                    ? "rounded-lg border-8 border-[#3C7BF0] filter brightness-125"
                    : ""
                }   rounded-lg overflow-hidden cursor-pointer relative group hover:filter hover:brightness-125 transition-all duration-300 `}
                onClick={() => {
                  props.profile?.isPLSkillPracticeActive === true &&
                    setSelectedStudyType(Constant.StudyType.SKILLPRACTICE);
                }}
              >
                <div className="relative">
                  <img
                    alt="skills practice"
                    className=""
                    src={`${Configuration.S3bucketImagePath}/images/student/kindergarten/general/skill-practice.jpg`}
                  ></img>
                </div>
                <div
                  className="absolute bg-center bg-no-repeat top-0"
                  onMouseEnter={() => {
                    startSpeaking(
                      t("StudyType.SkillPractice"),
                      (props.profile?.plLanguageId ?? 1) === 2 ? 2 : 1
                    );
                  }}
                  onMouseLeave={() => {
                    stopSpeaking();
                  }}
                >
                  <img
                    alt="skills practice"
                    className=""
                    src={`${Configuration.S3bucketImagePath}/images/student/kindergarten/shapes/cloud-blue.png`}
                  />
                  <span className="rounded-lg absolute left-0 right-0 mx-auto  bottom-0 font-Didact my-6 lg:my-1 1xl:my-3 xxxl:my-6 group-hover:font-bold transition-all duration-300  text-2xl tracking-wide lg:text-xl xl:text-2xl  text-center items-center">
                    {t("StudyType.SkillPractice")}
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
}

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(SubjectHome);

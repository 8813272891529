import { AxiosHelper } from "../environment/axiosConfig";
import { Configuration } from "../environment/setup";

export function fetchData(
  report: string,
  userId: number,
  schoolId: number,
  districtId: number,
  stateId: number,
  pageNumber: number,
  pageSize: number,
  exportType: string = "",
  roleId: number,
  programId: number,
  progressId: number
) {
  
  const url = `${Configuration.ApiBaseUrl}ReportData`;
  return AxiosHelper.axiosInstance().get(url, {
    params: {
      report: report,
      userId: userId,
      schoolId: schoolId,
      districtId: districtId,
      stateId: stateId,
      pageNumber: pageNumber,
      pageSize: pageSize,
      exportType: exportType,
      roleId: roleId,
      programId: programId,
      progressId: progressId,
    },
  });
}


import React, {Fragment} from "react";
import Profile from "../../../../model/users/profile";
import { UserContextState } from "../../../../redux/actions/userContextAction";
import {IContentAreaSubject} from "../../../../model/interface/subject";
import useTextToSpeech from "../shared/textToSpeech/useTextToSpeech";

export interface PlanetProps {
    userContext?: UserContextState;
    profile?: Profile;
    contentArea: IContentAreaSubject;
    onContentAreaSelected: (
        contentArea: IContentAreaSubject,
    ) => void;
    ttsText: string;
    planetImage: string;
    iconImage: string;
}
const contentAreaToMetadata = {
    1: {
        iconCss: "w-[81px] md:w-[110px] xl:w-[160px] z-[2] absolute bottom-[-5px] left-0 right-0 m-auto"
    },
    2: {

        iconCss: "w-[80px] md:w-[90px] xl:w-[140px] z-[2] absolute bottom-[-24px] xl:bottom-[-40px] left-0 right-1 m-auto"
    },
    3: {
        iconCss: "w-[80px] md:w-[100px] xl:w-[150px] z-[2] absolute bottom-[-15px] left-0 right-0 m-auto"
    },
    4: {
        headerCss: "text-white leading-5 md:leading-7 xl:leading-8 absolute md:top-[25px] z-[2] filter text-shadow font-Barlow text-2xl md:text-3xl xl:text-4xl font-bold left-0 right-0 m-auto text-center group-hover:scale-125 transform transition ease-in-out duration-500",
        iconCss: "w-[80px] md:w-[100px] xl:w-[150px] z-[2] absolute md:bottom-[-18px] bottom-[-15px] left-0 right-0 m-auto",
    }
};

function Planet(props: PlanetProps) {
  const { startSpeaking, stopSpeaking } = useTextToSpeech();

  return (
    <Fragment key={props.contentArea.contentAreaId}>
        <div
              className="relative cursor-pointer group"
              onMouseEnter={() => {
                  startSpeaking(
                      props.ttsText,
                      (props.profile?.plLanguageId ?? 1) === 2 ? 2 : 1
                  );
              }}
              onMouseLeave={() => {
                  stopSpeaking();
              }}
              onClick={() => {
                  props.onContentAreaSelected(props.contentArea);
              }}
          >
              <h2 className={
                  contentAreaToMetadata[props.contentArea.contentAreaId].headerCss ??
                  "text-white absolute md:top-[25px] z-[2] filter text-shadow font-Barlow text-2xl md:text-3xl xl:text-4xl font-bold left-0 right-0 m-auto text-center group-hover:scale-125 transform transition ease-in-out duration-500"
              }
              >
                  {props.contentArea.k1DisplayName}
              </h2>
              <img
                  alt={props.contentArea.k1DisplayName}
                  className="w-[90px] md:w-[150px] xl:w-[200px] z-[1] relative"
                  src={props.planetImage}
              />
              <img
                  alt={props.contentArea.k1DisplayName}
                  className={contentAreaToMetadata[props.contentArea.contentAreaId].iconCss}
                  src={props.iconImage}
                  onMouseEnter={() => {
                      startSpeaking(
                          props.ttsText,
                          (props.profile?.plLanguageId ?? 1) === 2 ? 2 : 1
                      );
                  }}
                  onMouseLeave={() => {
                      stopSpeaking();
                  }}
                  onClick={() => {
                      props.onContentAreaSelected(props.contentArea);
                  }}
              />
          </div>
     </Fragment>
  );
}
export default Planet;

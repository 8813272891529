import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { IBreadcrumb } from "../../../../model/common/breadcrumb";
import RouteConstant from "../../../../utils/constant/routeConstant";
import { getUrlParams } from "../../../../utils/helper";
import Permissions from "../../../../utils/permissions";
import AccessDenied from "../../../shared/accessDenied";
import AllowedTo from "../../../shared/rbac";
import Breadcrumb from "../../teacher/shared/breadcrumb";
import SuperSheetList from "../superSheet/superSheetList";
import { useTranslation } from "react-i18next";

function SuperSheet(props: any) {
  let subjectId = getUrlParams()?.subjectId || undefined;
  const subjectName = getUrlParams()?.name || undefined;
  const { t } = useTranslation();

  const breadcrumbItems = () => {
    const items: Array<IBreadcrumb> = [
      { name: t("Breadcrumb.Home"), url: RouteConstant.ROUTE_DASHBOARD },
      {
        name: t("Breadcrumb.InstructionalResources"),
        url: "",
      },
      {
        name: t("Breadcrumb.Subjects"),
        url: RouteConstant.TeacherRoutes.InstructionalResourceSubjects,
      },
      {
        name: t("Breadcrumb.Resources"),
        url: RouteConstant.TeacherRoutes.InstructionalResources,
        stateObject: { label: decodeURI(subjectName) || "", value: subjectId },
      },
      { name: t("Breadcrumb.Supersheets"), url: `` },
    ];

    return items;
  };

  return (
    <AllowedTo
      perform={Permissions.supersheets_view}
      no={() => <AccessDenied />}
    >
      <div className="min-w-0 w-full">
        <div className="lg:flex lg:items-center px-4 py-3 lg:px-8 border-b">
          <Breadcrumb items={breadcrumbItems()} />
          <div className="lg:w-[55%] text-lg flex justify-between gap-2 h-auto sm:h-10 items-center">
            <h1 className="text-primary-violet font-medium flex justify-start mb-0 pb-0">
              Supersheets
            </h1>
          </div>
        </div>

        <div className="px-2 sm:px-6 lg:px-8 ">
          <div className="mt-4">
            <div className="w-full block mx-4 sm:mx-0 lg:col-span-9 xl:col-span-12">
              <div
                className="flex justify-end mb-4"
                style={{ display: "none" }}
              >
                <Link to={RouteConstant.NEWBUBBLESHEET}>
                  <button
                    type="submit"
                    className="bg-secondary-teal border border-transparent shadow-sm py-2 px-4 justify-end text-sm font-medium text-white hover:bg-secondary-teal/20 hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 whitespace-nowrap"
                  >
                    Create Bubble SuperSheet
                  </button>
                </Link>
              </div>
              <SuperSheetList
                userId={props.userContext.userId}
                roleId={props.userContext.roleId}
                defaultSubjectId={subjectId}
              ></SuperSheetList>
            </div>
          </div>
        </div>
      </div>
    </AllowedTo>
  );
}
const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
  };
};
export default connect<{}, {}, {}>(mapStateToProps, {})(SuperSheet);

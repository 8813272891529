import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { v4 as uuidv4 } from "uuid";
import Constant from "../../../../utils/constant/constant";
import RouteConstant from "../../../../utils/constant/routeConstant";
import {
  IAlienArena,
  IAlienStudentData,
} from "../../../../model/interface/alienArena";
import { ExportIcon, RandomIcon, PersonIcon } from "../../../../assets/icons";
import { IAlienArenaSetup } from "../../../../model/interface/alienArenaSetup";
import { IAlienArenaTeams } from "../../../../model/interface/alienArenaTeams";
import {
  createAlienArena,
  updateAlienArena,
} from "../../../../api/teacher/alienArena";
import { toast } from "react-toastify";
import { ClassApi } from "../../../../api/teacher/classManagementApi";
import Loader from "../../../shared/loader";

const onDragEnd = (result, columns, setColumns) => {
  if (!result.destination) return;
  const { source, destination } = result;

  if (source.droppableId !== destination.droppableId) {
    const sourceColumn = columns[source.droppableId];
    const destColumn = columns[destination.droppableId];
    const sourceItems = [...sourceColumn.items];
    const destItems = [...destColumn.items];
    const [removed] = sourceItems.splice(source.index, 1);
    destItems.splice(destination.index, 0, removed);
    setColumns({
      ...columns,
      [source.droppableId]: {
        ...sourceColumn,
        items: sourceItems,
      },
      [destination.droppableId]: {
        ...destColumn,
        items: destItems,
      },
    });
  } else {
    const column = columns[source.droppableId];
    const copiedItems = [...column.items];
    const [removed] = copiedItems.splice(source.index, 1);
    copiedItems.splice(destination.index, 0, removed);
    setColumns({
      ...columns,
      [source.droppableId]: {
        ...column,
        items: copiedItems,
      },
    });
  }
};
interface ArenaTeamsProps {
  userId: number;
  setActiveTab: (type: string) => void;
  students: IAlienStudentData[];
  alienArena: IAlienArena;
  alienArenaSetup: IAlienArenaSetup;
  alienAreanaTeams: IAlienArenaTeams;
  setAlienArena: (alienArena: IAlienArena) => void;
}

function ArenaTeams(props: ArenaTeamsProps) {
  const [loading, showLoading] = useState<boolean>(false);
  const { setActiveTab, alienArenaSetup, alienAreanaTeams } = props;
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [students, setStudents] = useState<Array<IAlienStudentData>>([]);
  const [studentsEdit, setStudentsEdit] = useState<Array<IAlienStudentData>>(
    []
  );
  const history = useHistory();
  const [teams, setTeams] = useState<any>({});
  const [teamsCloneEdit, setTeamsCloneEdit] = useState<any>({});

  function getClassStudentList(classId: number): void {
    showLoading(true);
    ClassApi.getClassStudent(classId, 1, 999)
      .then((d) => {
        setStudents(
          d.data?.data
            ?.filter((d) => d.isDisabled === false)
            ?.map((values: any) => {
              return {
                studentName: `${values?.lastName},${values?.firstName}`,
                id: values?.username,
                code: values?.schoolCode,
                studentId: values?.userId,
              };
            })
        );

        showLoading(false);
        var studentsEditData: IAlienStudentData[] = [];
        for (const [key, value] of Object.entries(alienAreanaTeams)) {
          if (key !== "1") {
          }
          var teamObject: any = value;
          if (teamObject.name !== "Students") {
            for (let i = 0; i < teamObject?.items?.length; i++) {
              studentsEditData.push({
                alienArenaId: teamObject.items[i]?.alienArenaId,
                id: teamObject.items[i]?.id,
                studentName: teamObject.items[i].studentName,
                studentId: teamObject.items[i].studentId,
                code: teamObject.items[i]?.code,
                alienArenaTeamId: teamObject.items[i].alienArenaTeamId,
              });
            }
          }
        }
        setStudentsEdit(studentsEditData);
      })
      .catch(() => {
        showLoading(false);
      });
  }

  useEffect(() => {
    if (Object.keys(alienAreanaTeams)?.length) {
      setTeams(alienAreanaTeams);
      let defaultTeams = {
        [uuidv4()]: {
          name: "Students",
          items: students,
        },
      };

      if (alienArenaSetup?.teamSize) {
        for (const [key, value] of Object.entries(teams)) {
          if (key !== "") {
          }
          var teamObject: any = value;
          if (teamObject.name !== "Students") {
            defaultTeams[uuidv4()] = {
              name: teamObject.name,
              items: [],
            };
          }
        }
      }
      setTeamsCloneEdit(defaultTeams);
    } else {
      let defaultTeams = {
        [uuidv4()]: {
          name: "Students",
          items: students,
        },
      };

      if (alienArenaSetup?.teamSize) {
        for (let i = 0; i < alienArenaSetup?.teamSize; i++) {
          defaultTeams[uuidv4()] = {
            name: `${
              Constant.defaultTeams[
                Math.floor(Math.random() * (13 - 1 + 1) + 1)
              ].TeamName
            } `,
            items: [],
          };
        }
      }
      setTeams(defaultTeams);
      setTeamsCloneEdit(defaultTeams);
    }
  }, [students]);

  function handleBack() {
    setActiveTab(Constant.ArenaActiveTab.SETUP);
  }
  const handleReset = () => {
    setTeams(teamsCloneEdit);
  };

  const splitEqually = (a, n, balanced) => {
    if (n < 2) return [a];

    var len = a?.length,
      out: any = [],
      i = 0,
      size;

    if (len % n === 0) {
      size = Math.floor(len / n);
      while (i < len) {
        out.push(a.slice(i, (i += size)));
      }
    } else if (balanced) {
      while (i < len) {
        size = Math.ceil((len - i) / n--);
        out.push(a.slice(i, (i += size)));
      }
    } else {
      n--;
      size = Math.floor(len / n);
      if (len % size === 0) size--;
      while (i < size * n) {
        out.push(a.slice(i, (i += size)));
      }
      out.push(a.slice(size * n));
    }

    return out;
  };

  const handleRandom = () => {
    let splitted = splitEqually(
      Object.keys(alienAreanaTeams)?.length ? studentsEdit : students,
      alienArenaSetup?.teamSize,
      true
    );
    var itemsToAssign: any = {};
    let arr: any = Object.entries(teams);
    let removedArr: any = arr.shift();
    if (removedArr?.length) {
      itemsToAssign[removedArr[0]] = {
        name: removedArr[1].name,
        items: [],
      };
    }

    arr?.map((ds, i) => {
      if (ds[1]?.name !== "Students") {
        itemsToAssign[ds[0]] = {
          name: ds[1]?.name,
          items: splitted[i],
        };
      }
      return null;
    });
    setTeams(itemsToAssign);
  };

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth < 1200);
    }

    window.addEventListener("resize", handleResize);
  });

  useEffect(() => {
    if (alienArenaSetup.classId) getClassStudentList(alienArenaSetup.classId);
  }, []);

  const updateAlienArenaSession = () => {
    const defaultTeams: IAlienArenaTeams[] = [];

    for (const [key, value] of Object.entries(teams)) {
      if (key !== "") {
      }
      var teamObject: any = value;
      if (teamObject.name !== "Students") {
        for (let i = 0; i < teamObject?.items?.length; i++) {
          defaultTeams.push({
            alienArenaId: teamObject.items[i]?.alienArenaId,
            displaySequenceNumber: 1,
            gameLevel: 1,
            id: 0,
            isDeleted: false,
            lastUpdatedByUserId: 1,
            studentName: teamObject.items[i].studentName,
            teamName: teamObject.name,
            studentId: teamObject.items[i].studentId,
            alienArenaTeamId: teamObject.items[i].alienArenaTeamId,
          });
        }
      }
    }

    const alienArena: IAlienArena = {
      alienArena: alienArenaSetup,
      alienArenaTeams: defaultTeams,
    };
    updateAlienArena(alienArena)
      .then((res) => {
        toast.success("Alien Arena Updated Sucessfully");
        history.push(RouteConstant.ALIEN_ARENA_SESSIONS);
      })
      .catch((err) => {
        toast.error("Something Went Wrong!!");
      });
  };

  const createAlienArenaSession = () => {
    const defaultTeams: IAlienArenaTeams[] = [];

    for (const [key, value] of Object.entries(teams)) {
      if (key !== "") {
      }
      var teamObject: any = value;
      if (teamObject.name !== "Students") {
        for (let i = 0; i < teamObject?.items?.length; i++) {
          defaultTeams.push({
            alienArenaId: 0,
            displaySequenceNumber: 1,
            gameLevel: 1,
            id: 0,
            isDeleted: false,
            lastUpdatedByUserId: 1,
            studentName: teamObject.items[i].studentName,
            teamName: teamObject.name,
            studentId: teamObject.items[i].studentId,
          });
        }
      }
    }

    const alienArena: IAlienArena = {
      alienArena: alienArenaSetup,
      alienArenaTeams: defaultTeams,
    };
    createAlienArena(alienArena)
      .then((res) => {
        toast.success("Alien Arena Created Sucessfully");
        history.push(RouteConstant.ALIEN_ARENA_SESSIONS);
      })
      .catch((err) => {
        toast.error("Something Went Wrong!!");
      });
  };

  return (
    <div className="my-4 relative px-4 sm:px-6  lg:px-8 ">
      {loading && <Loader />}
      <div className="bg-gray-100  w-full mb-5 rounded-lg p-5 h-full ">
        <div className="flex flex-col md:flex-row md:justify-between">
          <div className="flex flex-wrap gap-4">
            <button
              onClick={() => {
                handleRandom();
              }}
              className={`bg-transparent border border-primary-violet shadow-sm py-2 px-4 inline-flex items-center justify-center text-sm font-medium text-primary-violet hover:text-white hover:bg-light-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition ease-in-out duration-500`}
            >
              <RandomIcon className="w-4 fill-current text-primary-violet group-hover:text-white" />
              <span className="ml-2">Random</span>
            </button>
            <button
              onClick={() => {
                handleReset();
              }}
              className={`  bg-transparent border border-primary-violet shadow-sm py-2 px-4 inline-flex items-center justify-center text-sm font-medium text-primary-violet hover:text-white hover:bg-light-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition ease-in-out duration-500`}
            >
              <ExportIcon className="w-4 group-hover:text-white" />
              <span className="ml-2">Reset</span>
            </button>
          </div>
          <div className="flex flex-wrap gap-4 mt-4 md:mt-0 justify-start md:justify-end">
            <button
              onClick={() => handleBack()}
              type="button"
              className="bg-transparent whitespace-nowrap border my-auto border-primary-violet shadow-sm py-2 px-4 flex inline-end justify-end text-sm font-medium text-primary-violet hover:text-white hover:bg-light-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3 transition ease-in-out duration-500"
            >
              Back
            </button>

            {Object.keys(alienAreanaTeams)?.length ? (
              <button
                type="submit"
                onClick={() => {
                  updateAlienArenaSession();
                }}
                className="bg-primary-violet whitespace-nowrap border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-dark-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Update Session
              </button>
            ) : (
              <button
                type="submit"
                onClick={() => {
                  createAlienArenaSession();
                }}
                className="bg-primary-violet whitespace-nowrap border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-dark-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Create Session
              </button>
            )}
            <button
              onClick={() => {
                createAlienArenaSession();
              }}
              type="submit"
              className="bg-primary-violet whitespace-nowrap border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-dark-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Launch Session
            </button>
          </div>
        </div>
        <div className="">
          <div className="relative">
            <form className="space-y-8 divide-y divide-gray-200">
              <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                <div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
                  <div className="space-y-6 sm:space-y-5">
                    <div
                      className={`${
                        !isMobile
                          ? "flex justify-start h-full md:justify-self-auto md:h-auto"
                          : ""
                      }`}
                    >
                      <DragDropContext
                        onDragEnd={(result) =>
                          onDragEnd(result, teams, setTeams)
                        }
                      >
                        {Object.entries(teams).map(
                          ([columnId, column], index) => {
                            var col: any = column;
                            return (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: `${
                                    !isMobile
                                      ? col.name === "Students"
                                        ? "left"
                                        : "center"
                                      : ""
                                  }`,
                                  width: `${
                                    !isMobile && col.name === "Students"
                                      ? "30%"
                                      : "auto"
                                  }`,
                                }}
                                key={columnId}
                              >
                                <h2>{col.name}</h2>
                                <div style={{ margin: 8 }}>
                                  <Droppable
                                    droppableId={columnId}
                                    key={columnId}
                                  >
                                    {(provided, snapshot) => {
                                      return (
                                        <div
                                          {...provided.droppableProps}
                                          ref={provided.innerRef}
                                          style={{
                                            background: snapshot.isDraggingOver
                                              ? "lightblue"
                                              : col.name === "Students"
                                              ? "#FFFFFF"
                                              : index === 1
                                              ? "rgb(254 252 232)"
                                              : index === 2
                                              ? "rgb(240 253 244)"
                                              : index === 3
                                              ? "rgb(245 243 255)"
                                              : index === 4
                                              ? "rgb(253 242 248)"
                                              : "white",
                                            padding: 16,
                                            width: `${
                                              col.name === "Students"
                                                ? "100%"
                                                : "250px"
                                            }`,
                                            minHeight: 500,
                                          }}
                                          className={`rounded-lg shadow-lg ${
                                            col.name !== "Students"
                                              ? "border-2 border-gray-400 border-dashed"
                                              : ""
                                          } `}
                                        >
                                          {col.items?.map(
                                            (item, innerIndex) => {
                                              return (
                                                <Draggable
                                                  key={item.id}
                                                  draggableId={item.id}
                                                  index={innerIndex}
                                                >
                                                  {(provided, snapshot) => {
                                                    return (
                                                      <div
                                                        className={`flex items-center ${
                                                          snapshot.isDragging ||
                                                          col.name !==
                                                            "Students"
                                                            ? "bg-primary-violet text-white"
                                                            : "bg-gray-100 text-gray-600"
                                                        } rounded-lg border-2 border-dashed border-primary-violet  text-base`}
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        style={{
                                                          userSelect: "none",
                                                          padding: 10,
                                                          margin: "0 0 10px 0",
                                                          minHeight: "50px",
                                                          // backgroundColor:
                                                          //   snapshot.isDragging
                                                          //     ? "#263B4A"
                                                          //     : "#456C86",
                                                          // color: "white",
                                                          ...provided
                                                            .draggableProps
                                                            .style,
                                                        }}
                                                      >
                                                        <div className="flex items-center">
                                                          <span
                                                            className={`w-10 h-10 font-medium text-xs flex items-center justify-center border-2  text-gray-600 border-primary-violet rounded-full   opacity-80  ${
                                                              snapshot.isDragging
                                                                ? "text-gray-600"
                                                                : "text-white"
                                                            }
                                                       
                                                          `}
                                                            style={{
                                                              background: `${
                                                                col.name ===
                                                                "Students"
                                                                  ? "#FFFFFF"
                                                                  : index === 1
                                                                  ? "rgb(254 252 232)"
                                                                  : index === 2
                                                                  ? "rgb(240 253 244)"
                                                                  : index === 3
                                                                  ? "rgb(245 243 255)"
                                                                  : index === 4
                                                                  ? "rgb(253 242 248)"
                                                                  : "white"
                                                              }`,
                                                            }}
                                                          >
                                                            <span>
                                                              {item.code}
                                                            </span>
                                                          </span>
                                                          <span className="relative mr-2 ml-2 relative">
                                                            <PersonIcon />
                                                            <span className="w-2 h-2 rounded-full bg-yellow-400 absolute  top-2 right-0"></span>
                                                          </span>
                                                          <span className="ml-4 text-sm">
                                                            {item.studentName}
                                                          </span>
                                                        </div>
                                                      </div>
                                                    );
                                                  }}
                                                </Draggable>
                                              );
                                            }
                                          )}
                                          {col.name !== "Students" &&
                                          col.items?.length === 0 ? (
                                            <div className="text-sm opacity-40">
                                              {" "}
                                              Drag students here{" "}
                                            </div>
                                          ) : (
                                            col.name === "Students" &&
                                            col.items?.length === 0 && (
                                              <div className="text-xs">
                                                {" "}
                                                No student available for further
                                                team selection
                                              </div>
                                            )
                                          )}

                                          {provided.placeholder}
                                        </div>
                                      );
                                    }}
                                  </Droppable>
                                </div>
                              </div>
                            );
                          }
                        )}
                      </DragDropContext>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ArenaTeams;

import { useEffect, useState } from "react";
import XIcon from "@heroicons/react/outline/XIcon";
import InfoMessage from "../../../shared/infoMessage";
import WarningMessage from "../../../shared/warningMessage";
import { Disclosure } from "@headlessui/react";
import { DownArrowIcon, UpArrow } from "../../../../assets/icons";
import userApi from "../../../../api/userApi";
import { IDuplicateUser } from "../../../../model/teacher/userManagement/duplicateUser";
import { IMergeUser } from "../../../../model/teacher/userManagement/mergeUser";
import Loader from "../../../shared/loader";
import { toast } from "react-toastify";
import { UserContextState } from "../../../../redux/actions/userContextAction";
import { connect } from "react-redux";
import { useConfirmation } from "../../../shared/confirmation/confirmationService";
import Moment from "react-moment";
import MergeUserStudentSearch from "./mergeUserStudentSearch";

interface MergeStudentsProps {
  showPopUp: (show: boolean) => void;
  mergeType:
    | "ManualStudentMerge"
    | "ManualTeacherMerge"
    | "PotentialDuplicateStudent";
  schoolAccountId: number;
  mergeUserId: number;
  userContext?: UserContextState;
}

const MergeStudents = (props: MergeStudentsProps) => {
  const [infoMessage, setInfoMessage] = useState<string>("");
  const [popupTittle, setPopupTittle] = useState("");
  const [users, setUsers] = useState<Array<IDuplicateUser>>([]);
  const [expandedIds, setExpandedIds] = useState<Array<any>>([]);
  const [parentRowIds, setParentRowIds] = useState<Array<any>>([]);
  const [childRowIds, setChildRowIds] = useState<Array<any>>([]);
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [skipUserId, setSkipUserId] = useState(0);
  const [showMergeStudentSearch, setShowMergeStudentSearch] = useState(false);
  const confirm = useConfirmation();

  const onExpand = (index, type?) => {
    let ids = [...expandedIds];
    if (ids.includes(index)) {
      ids = ids.filter((ds) => ds !== index);
      setExpandedIds(ids);
    } else {
      ids.push(index);
      setExpandedIds(ids);
    }
  };

  const onParentRowSelect = (user: IDuplicateUser) => {
    let ids = [...parentRowIds];
    if (ids.includes(user.userId)) {
      ids = ids.filter((ds) => ds !== user.userId);
      setParentRowIds(ids);
      removeChildSelection(user.duplicateUsers.map((t) => t.userId));
    } else {
      ids.push(user.userId);
      setParentRowIds(ids);
      addChildSelection(user.duplicateUsers.map((t) => t.userId));
    }
  };

  const removeChildSelection = (userIds: Array<number>) => {
    let childIds = [...childRowIds];
    var filterChildIds = childIds.filter((r) => !userIds.includes(r));
    setChildRowIds(filterChildIds);
  };

  const addChildSelection = (userIds: Array<number>) => {
    let childIds = [...childRowIds];
    var filterChildIds = userIds.filter((r) => !childIds.includes(r));

    if (filterChildIds !== null && filterChildIds.length > 0) {
      childIds = [...filterChildIds];
      setChildRowIds(childIds);
    }
  };

  const onChildRowSelect = (userId: number, parentUserId: number) => {
    let ids = [...childRowIds];
    if (ids.includes(userId)) {
      ids = ids.filter((ds) => ds !== userId);
      setChildRowIds(ids);
    } else {
      ids.push(userId);
      setChildRowIds(ids);

      var parentIds = [...parentRowIds];
      if (!parentIds.includes(parentIds)) {
        parentIds.push(parentUserId);
        setParentRowIds(parentIds);
      }
    }
  };

  useEffect(() => {
    if (props.mergeType === "ManualStudentMerge") {
      setInfoMessage(
        "Students may forget a password and create new account(s). This tool will allow you to merge multiple Student accounts into a single account without data loss."
      );
      setPopupTittle("Merge Student");
    } else if (props.mergeType === "ManualTeacherMerge") {
      setInfoMessage(
        "Teachers may forget a password and create new account(s). This tool will allow you to merge multiple Teacher accounts into a single account without data loss."
      );
      setPopupTittle("Merge Teacher");
    } else if (props.mergeType === "PotentialDuplicateStudent") {
      setInfoMessage(
        "Students may forget a password and create new account(s). This tool allows you to merge potential duplicate student accounts into a single account without data loss. The User listed in Orange has the most recent activity and will be kept."
      );
      setPopupTittle("Merge Potential Duplicate Student");
    }

    loadUsers();
  }, [props.mergeType]);

  function loadUsers() {
    setShowLoader(true);
    userApi
      .GetDuplicateUsers(
        props.schoolAccountId,
        props.mergeType,
        props.mergeUserId
      )
      .then((d) => {
        setUsers(d.data);
        setShowLoader(false);

        if (d.data.length > 0) {
          var result = d.data.findIndex((t) => t.hasLatestLastLogin === false);
          setIsDisabled(result !== -1);
        } else {
          setIsDisabled(true);
        }
      })
      .catch((e) => {
        setShowLoader(false);
      });
  }

  function mergeUsers() {
    if (parentRowIds.length === 0) {
      toast.warning("Please select users to merge");
      return;
    }
    confirm({
      variant: "danger",
      title: "Please Confirm",
      description:
        "Are you sure you want to merge selected students? The process cannot be undone once completed.",
    }).then((d) => {
      var postData: Array<IMergeUser> = [];
      parentRowIds.forEach((p) => {
        var obj: IMergeUser = { parentUserId: p, mergeUserIds: [] };
        var parentUser = users.find((t) => t.userId === p);
        if (parentUser !== null && parentUser !== undefined) {
          obj.mergeUserIds = parentUser.duplicateUsers.map((t) => t.userId);
        }
        postData.push(obj);
      });
      userApi.MergeUsers(postData, props.userContext?.userId ?? 0).then((d) => {
        toast.success("User merged successfully.");
        props.showPopUp(false);
      });
    });
  }
  return (
    <div className="fixed inset-0 z-40 flex items-center bg-black bg-opacity-50 sm:items-center sm:justify-center">
      <div
        className="w-full flex flex-col justify-between overflow-hidden h-auto max-h-80percent bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:max-w-5xl"
        role="dialog"
      >
        <header>
          {/* Header */}
          <div className="px-4 py-4 bg-gray-50 sm:px-4">
            <div className="flex items-start justify-between space-x-3">
              <div className="space-y-1">
                <div className="text-lg font-medium text-gray-900">
                  {popupTittle}{" "}
                </div>
              </div>
              <div className="h-7 flex items-center">
                <button
                  type="button"
                  className="text-gray-400 hover:text-gray-500"
                  onClick={() => props.showPopUp(false)}
                >
                  <span className="sr-only">Close panel</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </header>
        <div className="w-full relative overflow-auto px-4 py-3">
          <div className="flex-1">
            <div className="p-1 flex justify-between px-5 items-center w-full">
              <div className="pr-2">
                <InfoMessage
                  title="Information Message"
                  message={infoMessage}
                />
              </div>
            </div>

            {/* <!-- Divider container -->  */}
            <div className="text-sm text-gray-700 px-4 mt-2 h-100 overflow-auto">
              <table className="table w-full ">
                {showLoader && <Loader></Loader>}
                <thead className="mb-5">
                  <tr className="bg-light-violet  text-white py-3 px-3">
                    <th
                      scope="col"
                      className="text-left  p-3 text-sm font-normal"
                    >
                      <div className="flex">Name</div>
                    </th>
                    <th
                      scope="col"
                      className="text-left  p-3 text-sm font-normal"
                    >
                      <div className="flex">Grade</div>
                    </th>
                    <th
                      scope="col"
                      className="text-left  p-3 text-sm font-normal"
                    >
                      <div className="flex">ID</div>
                    </th>
                    <th
                      scope="col"
                      className="text-left  p-3 text-sm font-normal"
                    >
                      <div className="flex">Logins</div>
                    </th>
                    <th
                      scope="col"
                      className="text-left  p-3 text-sm font-normal"
                    >
                      <div className="flex">Last Login</div>
                    </th>
                    <th
                      scope="col"
                      className="text-left  p-3 text-sm font-normal"
                    >
                      <div className="flex">Email</div>
                    </th>
                    {props.mergeType === "ManualStudentMerge" && (
                      <th
                        scope="col"
                        className="text-left  p-3 text-sm font-normal"
                      >
                        <div className="flex">Search Student</div>
                      </th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {users?.map((user, index) => (
                    <Disclosure key={index}>
                      {({ open }) => (
                        <>
                          <tr
                            key={index}
                            className={
                              index % 2 === 0
                                ? " hover:bg-light-violet/20 transition ease-in-out duration-300 bg-white"
                                : "hover:bg-light-violet/20 transition ease-in-out duration-300 bg-light-violet/10"
                            }
                          >
                            <td className="p-3 text-sm text-gray-500">
                              <div className="flex items-center">
                                <div className="mt-2 mr-2">
                                  <Disclosure.Button className="">
                                    {expandedIds.includes(index) && (
                                      <DownArrowIcon
                                        key={index}
                                        className={`${""} w-5 h-5`}
                                        onClick={(e) => {
                                          onExpand(index, "show");
                                        }}
                                      />
                                    )}

                                    {!expandedIds.includes(index) && (
                                      <UpArrow
                                        key={index}
                                        className={`${!open ? "" : ""} w-5 h-5`}
                                        onClick={(e) => {
                                          onExpand(index, "");
                                        }}
                                      />
                                    )}
                                  </Disclosure.Button>
                                </div>
                                <div className="text-yellow-600">
                                  <label
                                    x-radio-group-option=""
                                    className="relative cursor-pointer pr-2"
                                  >
                                    <input
                                      type="checkbox"
                                      name="privacy_setting"
                                      className="h-4 w-4 mt-0.5 cursor-pointer text-indigo-600 border-gray-300 focus:ring-indigo-500"
                                      onChange={() => onParentRowSelect(user)}
                                    />
                                  </label>
                                  {user.lastName}, {user.firstName}
                                </div>
                              </div>
                            </td>
                            <td className="p-3 text-sm text-gray-500">
                              {user.grade}
                            </td>
                            <td className="p-3 text-sm text-gray-500 text-left">
                              {user.studentId}
                            </td>
                            <td className="p-3 text-sm text-gray-500 text-left">
                              {user.logins}
                            </td>
                            <td className="p-3 text-sm text-gray-500 text-left">
                              {user.lastLogin !== null &&
                                user.lastLogin !== undefined && (
                                  <Moment>{user.lastLogin}</Moment>
                                )}
                            </td>
                            <td className="p-3 text-sm text-gray-500 text-left">
                              {user.email}
                            </td>
                            {props.mergeType === "ManualStudentMerge" && (
                              <th
                                scope="col"
                                className="text-left  p-3 text-sm font-normal"
                              >
                                <button
                                  className="bg-transparent border border-primary-violet shadow-sm py-2 px-4 inline-flex justify-center items-center text-sm font-medium text-primary-violet hover:text-white hover:bg-light-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3 transition ease-in-out duration-500"
                                  type="button"
                                  onClick={() => {
                                    setSkipUserId(user.userId);
                                    setShowMergeStudentSearch(true);
                                  }}
                                >
                                  <span className="ml-2 text-sm">Search</span>
                                </button>
                              </th>
                            )}
                          </tr>
                          <tr>
                            <td colSpan={8} className="relative ">
                              <div
                                className={`${
                                  expandedIds.includes(index) ? "hidden" : ""
                                } mt-2 pl-10 px-4 pb-2 text-sm text-gray-500`}
                              >
                                <div className="shadow border-b rounded-lg">
                                  <table className="min-w-full">
                                    <thead>
                                      <tr className="bg-gray-50 border">
                                        <th
                                          scope="col"
                                          className="text-left  p-3 text-sm font-normal"
                                        >
                                          <div className="flex">Name</div>
                                        </th>
                                        <th
                                          scope="col"
                                          className="text-left  p-3 text-sm font-normal"
                                        >
                                          <div className="flex">Grade</div>
                                        </th>
                                        <th
                                          scope="col"
                                          className="text-left  p-3 text-sm font-normal"
                                        >
                                          <div className="flex">ID</div>
                                        </th>
                                        <th
                                          scope="col"
                                          className="text-left  p-3 text-sm font-normal"
                                        >
                                          <div className="flex">Logins</div>
                                        </th>
                                        <th
                                          scope="col"
                                          className="text-left  p-3 text-sm font-normal"
                                        >
                                          <div className="flex">Last Login</div>
                                        </th>
                                        <th
                                          scope="col"
                                          className="text-left  p-3 text-sm font-normal"
                                        >
                                          <div className="flex">Email</div>
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-50">
                                      {user.duplicateUsers.map(
                                        (userToMerge, idx) => (
                                          <tr key={idx}>
                                            <td className="p-3 text-sm text-gray-500">
                                              <label
                                                x-radio-group-option=""
                                                className="relative cursor-pointer pr-2"
                                              >
                                                <input
                                                  key={idx}
                                                  type="checkbox"
                                                  name="privacy_setting"
                                                  className="h-4 w-4 mt-0.5 cursor-pointer text-indigo-600 border-gray-300 focus:ring-indigo-500"
                                                  onChange={() =>
                                                    onChildRowSelect(
                                                      userToMerge.userId,
                                                      user.userId
                                                    )
                                                  }
                                                />
                                              </label>
                                              {userToMerge.lastName},{" "}
                                              {userToMerge.firstName}
                                            </td>
                                            <td className="p-3 text-sm text-gray-500">
                                              {userToMerge.grade}
                                            </td>
                                            <td className="p-3 text-sm text-gray-500 text-left">
                                              {userToMerge.studentId}
                                            </td>
                                            <td className="p-3 text-sm text-gray-500 text-left">
                                              {userToMerge.logins}
                                            </td>
                                            <td className="p-3 text-sm text-gray-500 text-left">
                                              {
                                                <Moment>
                                                  {userToMerge.lastLogin}
                                                </Moment>
                                              }
                                            </td>
                                            <td className="p-3 text-sm text-gray-500 text-left">
                                              {userToMerge.email}
                                            </td>
                                          </tr>
                                        )
                                      )}

                                      {(user.duplicateUsers === null ||
                                        user.duplicateUsers.length === 0) && (
                                        <tr>
                                          <td
                                            className="p-3 text-sm text-gray-500 text-left"
                                            colSpan={6}
                                          >
                                            There are no users identified to
                                            merge.
                                          </td>
                                        </tr>
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                                {user.hasLatestLastLogin === false && (
                                  <WarningMessage
                                    title="Warning Message"
                                    message="Primary user which you have selected does not have the most recent login. If you want to set the user with the most recent login timestamp, then close this modal and select Merge for the desired user from the list"
                                  />
                                )}
                              </div>
                            </td>
                          </tr>
                        </>
                      )}
                    </Disclosure>
                  ))}
                  {users.length === 0 && (
                    <tr>
                      <td colSpan={6}>
                        There are no users identified to merge.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {/* <!-- Action Buttons -->  */}
        <footer className="flex items-center mt-4 justify-end px-6 py-4 sm:flex-row bg-gray-50 dark:bg-gray-800">
          <div className="flex-shrink-0">
            <div className="space-x-3 flex justify-end">
              <button
                className="disabled:opacity-50 align-bottom inline-flex items-center whitespace-nowrap justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 rounded-lg text-sm text-white border border-transparent bg-primary-green focus:shadow-outline-purple w-full sm:w-auto"
                type="button"
                onClick={mergeUsers}
                disabled={isDisabled}
              >
                {" "}
                Merge User
              </button>
              <button
                className="align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 rounded-lg text-sm text-gray-600 border-gray-300 border dark:text-gray-400 focus:outline-none active:bg-transparent hover:border-gray-500 focus:border-gray-500 active:text-gray-500 focus:shadow-outline-gray w-full sm:w-auto"
                type="button"
                onClick={() => {
                  props.showPopUp(false);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </footer>
      </div>
      {showMergeStudentSearch && (
        <MergeUserStudentSearch
          skipUserId={skipUserId}
          showPopup={() => {
            setShowMergeStudentSearch(false);
          }}
          addStudentToMerge={(e) => {
            var updatedUsers: any = users.map((obj) => {
              if (obj.userId === skipUserId) {
                obj.duplicateUsers = obj.duplicateUsers.concat(e);
                return obj;
              } else {
                return obj;
              }
            });
            setUsers(updatedUsers ?? []);
            setShowMergeStudentSearch(false);
          }}
        ></MergeUserStudentSearch>
      )}
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
  };
};

export default connect(mapStateToProps, {})(MergeStudents);

import moment from "moment";
import { Configuration } from "../environment/setup";
import Profile from "../model/users/profile";
import { getContentAreaBySubjectId } from "../api/subjectAPI";
import { IStudentReward } from "../model/interface/studentReward";

export function getBlasterValues(blasterSetting: any, rewards: any) {
  const totalBlasters = 24;
  const blasterImageDefaultPath = `${Configuration.S3bucketImagePath}/images/student/shared/Blasters/`;
  const earnedStars = rewards.earnedStar;
  const totalStars = rewards.totalStar;
  const starsRequiredToUnlockSingleBlaster = Math.floor(
    (totalStars * 0.6) / (totalBlasters - 1)
  );
  const starsRequiredToUnlockLastBlaster = Math.floor(
    totalStars * 0.6 - earnedStars
  );

  var tempBlasters: Array<any> = [];

  let id = 0;
  for (let index = 1; index <= 4; index++) {
    tempBlasters.push({
      id: ++id,
      level: index,
      isEnabled: index <= blasterSetting.cannon,
      starsRequired: 0,
      path: blasterImageDefaultPath + "cannon" + index + ".png",
      type: "cannon",
    });

    tempBlasters.push({
      id: ++id,
      level: index,
      isEnabled: index <= blasterSetting.energy,
      starsRequired: 0,
      path: blasterImageDefaultPath + "energy" + index + ".png",
      type: "energy",
    });

    tempBlasters.push({
      id: ++id,
      level: index,
      isEnabled: index <= blasterSetting.fire,
      starsRequired: 0,
      path: blasterImageDefaultPath + "fire" + index + ".png",
      type: "fire",
    });

    tempBlasters.push({
      id: ++id,
      level: index,
      isEnabled: index <= blasterSetting.ice,
      starsRequired: 0,
      path: blasterImageDefaultPath + "ice" + index + ".png",
      type: "ice",
    });

    tempBlasters.push({
      id: ++id,
      level: index,
      isEnabled: index <= blasterSetting.lazer,
      starsRequired: 0,
      path: blasterImageDefaultPath + "lazer" + index + ".png",
      type: "lazer",
    });

    tempBlasters.push({
      id: ++id,
      level: index,
      isEnabled: index <= blasterSetting.missile,
      starsRequired: 0,
      path: blasterImageDefaultPath + "missile" + index + ".png",
      type: "missile",
    });
  }

  tempBlasters = tempBlasters.map((b, index) => {
    // last blaster
    if (index === totalBlasters - 1) {
      b.starsRequired = starsRequiredToUnlockLastBlaster;
    } else {
      b.starsRequired =
        starsRequiredToUnlockSingleBlaster * index - earnedStars;
    }

    if (b.starsRequired < 0) {
      b.starsRequired = 0;
    }
    return b;
  });

  return tempBlasters;
}

export function isGameLocked(
  isGameLocked: boolean,
  gameLockStartTime: string,
  gameLockEndTime: string
) {
  if (isGameLocked) {
    const format = "hh:mm:ss";
    var time = moment();
    const beforeTime = moment(gameLockStartTime, format);
    const afterTime = moment(gameLockEndTime, format);
    if (time.isBetween(beforeTime, afterTime)) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
}

export function isGameArcadeLockedForStudent(
  isGameLocked: boolean,
  gameLockStartTime: string,
  gameLockEndTime: string
) {
  if (isGameLocked) {
    const startTimeArray: Array<string> = gameLockStartTime?.split(",");
    const endTimeArray: Array<string> = gameLockEndTime?.split(",");
    if (startTimeArray.length > 1 && endTimeArray.length > 1) {
      //when arcade locked in more than one class
      for (var i = 0; i < startTimeArray.length; i++) {
        if (checkIfLockTimeContinue(startTimeArray[i], endTimeArray[i])) {
          return true;
        }
      }
      return false;
    } else {
      if (checkIfLockTimeContinue(gameLockStartTime, gameLockEndTime)) {
        return true;
      } else {
        return false;
      }
    }
  } else {
    return false;
  }
}
export function currentArcadeLockTime(
  isGameLocked: boolean,
  gameLockStartTime: string,
  gameLockEndTime: string
) {
  var currentGameLockStartTime: string | null = null;
  var currentGameLockEndTime: string | null = null;
  const startTimeArray: Array<string> = gameLockStartTime?.split(",");
  const endTimeArray: Array<string> = gameLockEndTime?.split(",");
  if (startTimeArray.length > 1 && endTimeArray.length > 1) {
    //when arcade locked in more than one class
    for (var i = 0; i < startTimeArray.length; i++) {
      if (checkIfLockTimeContinue(startTimeArray[i], endTimeArray[i])) {
        currentGameLockStartTime = startTimeArray[i];
        currentGameLockEndTime = endTimeArray[i];
        break;
      }
    }
  } else {
    if (checkIfLockTimeContinue(gameLockStartTime, gameLockEndTime)) {
      currentGameLockStartTime = gameLockStartTime;
      currentGameLockEndTime = gameLockEndTime;
    }
  }
  return {
    gameLockStartTime: currentGameLockStartTime,
    gameLockEndTime: currentGameLockEndTime,
  };
}

const checkIfLockTimeContinue = (startTime, endTime) => {
  const format = "hh:mm:ss";
  const beforeTime = moment(startTime, format);
  const afterTime = moment(endTime, format);
  var time = moment();
  if (time.isBetween(beforeTime, afterTime)) {
    return true;
  } else return false;
};
export const GetLanguageSetting = async (
  subjectId: number,
  profile: Profile,
  contentAreaId?: number
) => {
  if (!contentAreaId) {
    return await getContentAreaBySubjectId(subjectId).then((res) => {
      if (res.data) {
        var contentAreaId = res.data?.id;
        var languageSettingId: number =
          profile.settings?.find((x) => x.contentAreaId === contentAreaId)
            ?.languageSettingId ?? 1;
        return languageSettingId;
      } else {
        return 1;
      }
    });
  } else {
    var languageSettingId: number =
      profile.settings?.find((x) => x.contentAreaId === contentAreaId)
        ?.languageSettingId ?? 1;
    return languageSettingId;
  }
};
export const GetTTSSetting = async (
  subjectId: number,
  profile: Profile,
  contentAreaId?: number
) => {
  if (!contentAreaId) {
    return await getContentAreaBySubjectId(subjectId).then((res) => {
      if (res.data) {
        var contentAreaId = res.data?.id;
        var isTTSActive: boolean =
          profile.settings?.find((x) => x.contentAreaId === contentAreaId)
            ?.isTTSActive ?? true;
        return isTTSActive;
      } else {
        return true;
      }
    });
  } else {
    var isTTSActive: boolean =
      profile.settings?.find((x) => x.contentAreaId === contentAreaId)
        ?.isTTSActive ?? true;
    return isTTSActive;
  }
};

export const getDefaultRewards = (): IStudentReward => {
  const reward: IStudentReward = {
    plUnlockedRockets: 0,
    loUnlockedRockets: 0,
    plBlasterSetting: {
      ice: 0,
      fire: 0,
      canon: 0,
      energy: 0,
      lazer: 0,
      missile: 0,
    },
    loBlasterSetting: {
      ice: 0,
      fire: 0,
      canon: 0,
      energy: 0,
      lazer: 0,
      missile: 0,
    },
    plStarReward: {
      earnedStar: 0,
      totalStar: 0,
    },
    loStarReward: {
      earnedStar: 0,
      totalStar: 0,
    },
    plRockets: [
      {
        rocketId: 0,
        starsRequiredToUnlock: 0,
        isLocked: false,
      },
    ],
    loRockets: [
      {
        rocketId: 0,
        starsRequiredToUnlock: 0,
        isLocked: false,
      },
    ],
  };

  return reward;
};
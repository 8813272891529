import { Fragment } from "react";
import { NavigationIcon } from "../../../../assets/icons/index";
import { IBreadcrumb } from "../../../../model/common/breadcrumb";
import { useHistory } from "react-router-dom";

interface passedProps {
  items: Array<IBreadcrumb>;
}

function Breadcrumb(props: passedProps) {
  const history = useHistory();
  const { items } = props;
  const handleClick = (item: IBreadcrumb) => {
    if (item.stateObject) {
      history.push({
        pathname: item.url,
        state: { selectedSubject: item.stateObject },
      });
    } else {
      if (item.url) history.push(item.url);
    }
  };
  return (
    <Fragment>
      <nav
        className="lg:flex lg:items-center lg:w-[45%]"
        aria-label="Breadcrumb"
      >
        <ol className="flex items-center gap-x-2 gap-y-1 mb-2 sm:mb-0 sm:gap-x-0 sm:gap-y-0 flex-wrap">
          {items.map((item, i) => {
            return (
              <li key={i}>
                <div className="flex items-center">
                  {i > 0 && <NavigationIcon />}
                  {item.url !== "" ? (
                    <button
                      onClick={() => {
                        handleClick(item);
                      }}
                      className="underscore cursor-pointer text-sm text-gray-500 hover:text-gray-700 border-dashed border-b border-gray-400 hover:border-opacity-0"
                    >
                      {item.objectId ? item.objectId : item.name}
                    </button>
                  ) : (
                    <span className="ml-1 text-sm text-gray-500">
                      {item.objectId ? item.objectId : item.name}
                    </span>
                  )}
                </div>
              </li>
            );
          })}
        </ol>
      </nav>
    </Fragment>
  );
}

export default Breadcrumb;

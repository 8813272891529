import { Fragment, useEffect, useState } from "react";
import "../../../../../../css/style.css";
import { connect } from "react-redux";
import OverviewResult from "./overViewResult";
import LearnosityReport from "../../../../results/learnosityReport";
import Profile from "../../../../../../model/users/profile";
import { UserContextState } from "../../../../../../redux/actions/userContextAction";
import { getStudyPlanResult } from "../../../../../../api/student/studyPlanApi";
import { useTranslation } from "react-i18next";
import ProgressResult from "./progressResult";
import RouteConstant from "../../../../../../utils/constant/routeConstant";
import {useHistory, useLocation} from "react-router-dom";

interface IStudyPlanResult {
  userContext: UserContextState;
  profile: Profile;
  match: any;
  studentId?: number;
  studentGradeId?: number;
  isTeacherView?: boolean;
  sessionId?: number;
}
const StudyPlanResult = (props: IStudyPlanResult) => {
  const history = useHistory();
  var location = useLocation();
  const query = new URLSearchParams(location.search);
  const schoolYearId = parseInt(query.get("schoolYearId") ?? "0") || null;
  const [studyPlanResult, setStudyPlanResult] = useState<any>(null);
  const [title, setTitle] = useState<any>("");
  const sessionId = props.isTeacherView
    ? props.sessionId ?? ""
    : props.match.params.sessionId;
  const { t } = useTranslation();
  const userId =
    props.isTeacherView === true
      ? props.studentId ?? 0
      : props.userContext.userId;
  const [learnosityActivityUserId, setLearnosityActivityUserId] =
    useState<number>(userId);

  useEffect(() => {
    getResult();
  }, []);

  function getResult() {
    getStudyPlanResult(sessionId, schoolYearId).then((res) => {
      setLearnosityActivityUserId(res.data.learnosityActivityUserId ?? userId);
      setStudyPlanResult(res.data);
      setTitle(res.data.domainName + " - " + res.data.standardName);
    }).catch(apiError => {
      if (apiError.response.status === 404) {
        history.push({ pathname: '/notfound' });
      }
    });
  }

  function returnToStudyPlan() {
    var path = RouteConstant.KindergartenStudentRoutes.SubjectStudy;
    path = encodeURI(
      path
        .replace(":subjectId", btoa(studyPlanResult.subjectId.toString()))
        .replace(":subjectName", btoa(studyPlanResult.subjectName))
        .replace(":contentAreaId", btoa("1"))
    );
    history.push({
      pathname: path,
    });
  }

  return (
    <Fragment>
      {studyPlanResult && (
        <div className="relative h-full bg-[#26234c]">
          <div className="p-5 md:py-5 md:px-5 lg:p-5 flex flex-col md:flex-row">
            <OverviewResult data={studyPlanResult} />
            <div className="flex-grow h-auto md:w-1/2">
              <div className="flex w-full mt-6 md:mt-0 pb-6 justify-between items-center">
                <div>
                  <h1 className="text-2xl font-bold text-white capitalize">
                    {t("StudyPlanResult.StudyPlanResult")}
                  </h1>
                  <h2 className="text-base font-medium text-white capitalize">
                    {title}
                  </h2>
                </div>
                {!props.isTeacherView && (
                  <button
                    className={`cursor-pointer bg-orange-light border-[3px] hover:bg-tertiary-color border-orange-dark font-bold rounded-md shadow-sm py-2 px-4 flex justify-center text-base text-gray-700 hover:text-gray-700 hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
                    onClick={() => returnToStudyPlan()}
                  >
                    Return to Study Plan
                  </button>
                )}
              </div>
              <div className="flex-none xxl:flex">
                {studyPlanResult.totalScore < 8 && (
                  <div className="flex-none w-full xl:w-4/5 xxl:w-2/5 rounded-md xl:mr-12 mb-12 xxl:mb-0 overflow-hidden shadow-xl bg-white">
                    <ProgressResult data={studyPlanResult}></ProgressResult>
                  </div>
                )}
                <div className="w-full rounded-md xl:mr-12 mb-12 xxl:mb-0 overflow-hidden">
                  <div className="p-5 bg-gray-50 border rounded-md text-sm font-semibold">
                    <h2>{t("StudyPlanResult.AnswerQuestionStatus")}.</h2>
                    <ul className="flex flex-wrap gap-2 w-full xl:w-1/2 mt-3">
                      {studyPlanResult.questions.map((question, index) => {
                        return (
                          <Fragment key={index}>
                            {question.order > 0 && question.score > 0 && (
                              <li className="w-6 h-6 bg-[#B2D136] border border-[#B2D136] text-white text-xs font-semibold flex items-center rounded-sm shadow-sm justify-center hover:bg-transparent hover:text-[#B2D136]">
                                {index + 1}
                              </li>
                            )}
                            {question.order > 0 && question.score === 0 && (
                              <li className="w-6 h-6 bg-[#e53f71] border border-[#e53f71] text-white text-xs font-semibold flex items-center rounded-sm shadow-sm justify-center hover:bg-transparent hover:text-[#e53f71]">
                                {index + 1}
                              </li>
                            )}
                          </Fragment>
                        );
                      })}
                    </ul>
                  </div>
                  <div className="mt-5 p-5 bg-white rounded-md text-sm font-semibold">
                    <LearnosityReport
                      sessionId={studyPlanResult.lernositySessionId}
                      user_id={learnosityActivityUserId.toString()}
                      show_answers={true}
                      wrapperClass="K1-Wrapper"
                      standardIds={studyPlanResult.standards
                        ?.map((standard) => {
                          return standard.standardId;
                        })
                        ?.join(",")}
                    ></LearnosityReport>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
};
export default connect<{}, {}, {}>(mapStateToProps, {})(StudyPlanResult);

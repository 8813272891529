import { Fragment } from "react";
import "../../../../../css/style.css";
import results from "../../../../../mockData/results.json";
import TotalQuestionsByDomains from "../../../../screen/results/totalQuestionsByDomains";
import TotalQuestionsByStandards from "../../../../screen/results/totalQuestionsByStandards";
import ProgressWidget from "../../../../shared/widgets/progressWidget";

const KGRESULT = (props: any) => {
  const resultsJson: any = results;
  return (
    <Fragment>
      <div className="min-w-0 w-full">
        <div className="px-6 py-5 lg:px-8 border-b border-gray-200">
          <div className="text-lg flex justify-between gap-2 h-auto sm:h-10 items-center">
            <h1 className="text-secondary-teal font-medium flex justify-start">
              Result
            </h1>
          </div>
        </div>
        <div className="sm:px-6 lg:px-8 py-6 bg-gradient-to-l from-[#09132d]  via-[#240e63] to-[#0b1948]">
          <div className="relative">
            <div className="p-5 flex flex-col md:flex-row">
              <div className="bg-gradient-to-b from-[#ddc600] to-[#ff890a] flex-none w-64 h-auto rounded-md shadow-md xl:mr-12 mr-5">
                <div className="text-md text-white text-center px-12 md:px-5 ">
                  <h2 className="text-sm text-white font-medium uppercase text-center my-4">
                    Score
                  </h2>
                  <div className="h-32 w-32 mx-auto border border-8 border-gray-400 rounded-full flex items-center justify-center">
                    25%
                  </div>
                </div>
                <div className="w-full text-lg text-white text-center mb-4"></div>
                <div className="flex flex-none flex-col bg-white px-7 py-8 -mx-2 rounded-md shadow-md mb-4">
                  <div className="flex w-full flex-none justify-between">
                    <div className="capitalize font-myriad font-thin text-md">
                      Score
                    </div>
                    <div className="h-6 ml-3 inline-block text-black-333 flex items-center px-2 font-roboto text-sm font-bold">
                      25%<span className="text-sm pl-1">(3/12)</span>
                    </div>
                  </div>
                  <div className="w-full block mt-2 relative">
                    <div className="w-full h-2 bg-gray-300 rounded-md"></div>
                    <div className="h-2 bg-[#BB0070] rounded-md absolute top-0 z-0 w-1/4"></div>
                  </div>
                  <div className="flex w-full flex-none justify-between mt-5">
                    <div className="capitalize font-myriad font-thin text-md">
                      Retry score
                    </div>
                    <div className=" h-6 ml-3 inline-block text-black-333 flex items-center px-2 font-roboto text-sm font-bold">
                      40%<span className="text-sm pl-1">(5/12)</span>
                    </div>
                  </div>
                  <div className="w-full block mt-2 relative">
                    <div className="w-full h-2 bg-gray-300 rounded-md"></div>
                    <div className="h-2 bg-[#BB0070] rounded-md absolute top-0 z-0 w-3/4"></div>
                  </div>
                </div>
                <div className="flex flex-none flex-col px-7 py-8 -mx-2 rounded-md shadow-md mb-4 bg-gradient-to-b from-orange-dark to-white">
                  <div className="flex flex-col w-full items-center ">
                    <div className="capitalize font-myriad font-semibold text-lg text-gray-700">
                      Galaxy Stars Earned
                    </div>
                    <span className="flex space-x-1 mt-1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 16.3 15.601"
                        className="w-7"
                      >
                        <g
                          id="Group_1"
                          data-name="Group 1"
                          transform="translate(-40 -35)"
                        >
                          <path
                            id="Icon_awesome-star"
                            data-name="Icon awesome-star"
                            d="M8.717.542,6.728,4.576l-4.451.649a.975.975,0,0,0-.539,1.664l3.22,3.138L4.2,14.459a.974.974,0,0,0,1.414,1.027l3.982-2.093,3.982,2.093a.975.975,0,0,0,1.414-1.027l-.762-4.433,3.22-3.138a.975.975,0,0,0-.539-1.664l-4.451-.649L10.466.542a.976.976,0,0,0-1.749,0Z"
                            transform="translate(38.559 35.001)"
                            fill="#ffc11c"
                          ></path>
                          <path
                            id="Icon_awesome-star-2"
                            data-name="Icon awesome-star"
                            d="M6.39.368,5.037,3.112l-3.027.441a.663.663,0,0,0-.367,1.131l2.19,2.134L3.315,9.834a.663.663,0,0,0,.961.7L6.984,9.109l2.708,1.424a.663.663,0,0,0,.961-.7l-.518-3.015,2.19-2.134a.663.663,0,0,0-.367-1.131L8.932,3.112,7.579.368a.664.664,0,0,0-1.189,0Z"
                            transform="translate(41.166 37.496)"
                            fill="#f08a00"
                            opacity="0.836"
                          ></path>
                        </g>
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 16.3 15.601"
                        className="w-7"
                      >
                        <g
                          id="Group_1"
                          data-name="Group 1"
                          transform="translate(-40 -35)"
                        >
                          <path
                            id="Icon_awesome-star"
                            data-name="Icon awesome-star"
                            d="M8.717.542,6.728,4.576l-4.451.649a.975.975,0,0,0-.539,1.664l3.22,3.138L4.2,14.459a.974.974,0,0,0,1.414,1.027l3.982-2.093,3.982,2.093a.975.975,0,0,0,1.414-1.027l-.762-4.433,3.22-3.138a.975.975,0,0,0-.539-1.664l-4.451-.649L10.466.542a.976.976,0,0,0-1.749,0Z"
                            transform="translate(38.559 35.001)"
                            fill="#ffc11c"
                          ></path>
                          <path
                            id="Icon_awesome-star-2"
                            data-name="Icon awesome-star"
                            d="M6.39.368,5.037,3.112l-3.027.441a.663.663,0,0,0-.367,1.131l2.19,2.134L3.315,9.834a.663.663,0,0,0,.961.7L6.984,9.109l2.708,1.424a.663.663,0,0,0,.961-.7l-.518-3.015,2.19-2.134a.663.663,0,0,0-.367-1.131L8.932,3.112,7.579.368a.664.664,0,0,0-1.189,0Z"
                            transform="translate(41.166 37.496)"
                            fill="#f08a00"
                            opacity="0.836"
                          ></path>
                        </g>
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 16.3 15.601"
                        className="w-7"
                      >
                        <g
                          id="Group_1"
                          data-name="Group 1"
                          transform="translate(-40 -35)"
                        >
                          <path
                            id="Icon_awesome-star"
                            data-name="Icon awesome-star"
                            d="M8.717.542,6.728,4.576l-4.451.649a.975.975,0,0,0-.539,1.664l3.22,3.138L4.2,14.459a.974.974,0,0,0,1.414,1.027l3.982-2.093,3.982,2.093a.975.975,0,0,0,1.414-1.027l-.762-4.433,3.22-3.138a.975.975,0,0,0-.539-1.664l-4.451-.649L10.466.542a.976.976,0,0,0-1.749,0Z"
                            transform="translate(38.559 35.001)"
                            fill="#ffc11c"
                          ></path>
                          <path
                            id="Icon_awesome-star-2"
                            data-name="Icon awesome-star"
                            d="M6.39.368,5.037,3.112l-3.027.441a.663.663,0,0,0-.367,1.131l2.19,2.134L3.315,9.834a.663.663,0,0,0,.961.7L6.984,9.109l2.708,1.424a.663.663,0,0,0,.961-.7l-.518-3.015,2.19-2.134a.663.663,0,0,0-.367-1.131L8.932,3.112,7.579.368a.664.664,0,0,0-1.189,0Z"
                            transform="translate(41.166 37.496)"
                            fill="#f08a00"
                            opacity="0.836"
                          ></path>
                        </g>
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 16.3 15.601"
                        className="w-7"
                      >
                        <g
                          id="Group_1"
                          data-name="Group 1"
                          transform="translate(-40 -35)"
                        >
                          <path
                            id="Icon_awesome-star"
                            data-name="Icon awesome-star"
                            d="M8.717.542,6.728,4.576l-4.451.649a.975.975,0,0,0-.539,1.664l3.22,3.138L4.2,14.459a.974.974,0,0,0,1.414,1.027l3.982-2.093,3.982,2.093a.975.975,0,0,0,1.414-1.027l-.762-4.433,3.22-3.138a.975.975,0,0,0-.539-1.664l-4.451-.649L10.466.542a.976.976,0,0,0-1.749,0Z"
                            transform="translate(38.559 35.001)"
                            fill="#ffc11c"
                          ></path>
                          <path
                            id="Icon_awesome-star-2"
                            data-name="Icon awesome-star"
                            d="M6.39.368,5.037,3.112l-3.027.441a.663.663,0,0,0-.367,1.131l2.19,2.134L3.315,9.834a.663.663,0,0,0,.961.7L6.984,9.109l2.708,1.424a.663.663,0,0,0,.961-.7l-.518-3.015,2.19-2.134a.663.663,0,0,0-.367-1.131L8.932,3.112,7.579.368a.664.664,0,0,0-1.189,0Z"
                            transform="translate(41.166 37.496)"
                            fill="#f08a00"
                            opacity="0.836"
                          ></path>
                        </g>
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 16.3 15.601"
                        className="w-7"
                      >
                        <g
                          id="Group_1"
                          data-name="Group 1"
                          transform="translate(-40 -35)"
                        >
                          <path
                            id="Icon_awesome-star"
                            data-name="Icon awesome-star"
                            d="M8.717.542,6.728,4.576l-4.451.649a.975.975,0,0,0-.539,1.664l3.22,3.138L4.2,14.459a.974.974,0,0,0,1.414,1.027l3.982-2.093,3.982,2.093a.975.975,0,0,0,1.414-1.027l-.762-4.433,3.22-3.138a.975.975,0,0,0-.539-1.664l-4.451-.649L10.466.542a.976.976,0,0,0-1.749,0Z"
                            transform="translate(38.559 35.001)"
                            fill="#ffc11c"
                          ></path>
                          <path
                            id="Icon_awesome-star-2"
                            data-name="Icon awesome-star"
                            d="M6.39.368,5.037,3.112l-3.027.441a.663.663,0,0,0-.367,1.131l2.19,2.134L3.315,9.834a.663.663,0,0,0,.961.7L6.984,9.109l2.708,1.424a.663.663,0,0,0,.961-.7l-.518-3.015,2.19-2.134a.663.663,0,0,0-.367-1.131L8.932,3.112,7.579.368a.664.664,0,0,0-1.189,0Z"
                            transform="translate(41.166 37.496)"
                            fill="#f08a00"
                            opacity="0.836"
                          ></path>
                        </g>
                      </svg>
                    </span>
                  </div>
                </div>
                <div className="m-10 flex flex-col flex-none mx-7 text-white border-white border-b border-opacity-25 pb-1 mb-2">
                  <div className="text-xs text-blue-900 capitalize">
                    Date Completed
                  </div>
                  <div className="text-blue-900 font-roboto font-bold capitalize">
                    2021/12/30
                  </div>
                </div>
                <div className="flex flex-col flex-none mx-7 text-white border-white border-b border-opacity-25 pb-1 mb-3">
                  <div className="text-xs text-blue-900 capitalize">
                    Elapsed Time
                  </div>
                  <div className="text-blue-900 font-roboto font-bold">
                    00:00:05
                  </div>
                </div>
                <div className="flex flex-col flex-none mx-7 text-white border-white border-b border-opacity-25 pb-1 mb-3">
                  <div className="text-xs text-white capitalize relative">
                    due date
                  </div>
                  <div className="text-blue-900 font-roboto font-bold">
                    2021/12/30
                  </div>
                </div>
                <div className="flex flex-col flex-none mx-7 text-white border-white border-b border-opacity-25 pb-1 mb-3">
                  <div className="text-xs text-blue-900 capitalize">
                    subject
                  </div>
                  <div className="text-blue-900 text-md font-roboto font-bold capitalize">
                    11th Grade ELA SBAC{" "}
                  </div>
                </div>
                <div className="flex flex-col flex-none mx-7 text-white border-white border-b border-opacity-25 pb-1 mb-3">
                  <div className="text-xs text-blue-900 capitalize">
                    Teachers
                  </div>
                  <div className="text-blue-900 text-md font-roboto font-bold capitalize">
                    Felicia Example
                  </div>
                </div>
                <div className="flex flex-col flex-none mx-7 text-white border-white border-b border-opacity-25 pb-1 mb-3">
                  <div className="text-xs text-blue-900 capitalize">Class</div>
                  <div className="text-blue-900 text-md font-roboto font-bold capitalize">
                    All Subjects{" "}
                  </div>
                </div>
                <div className="flex flex-col bg-gradient-to-b from-orange-dark to-orange-light rounded-md flex-none mx-7 text-white py-1.5 px-1.5 mb-3 border-2 border-blue-700">
                  <div className="text-xs text-gray-900 capitalize">
                    Game Score
                  </div>
                  <div className="text-md font-roboto font-bold capitalize text-gray-900">
                    1025
                  </div>
                </div>
                <div className="flex flex-col bg-gradient-to-b from-orange-dark to-orange-light rounded-md flex-none mx-7 text-white py-1.5 px-1.5 mb-3 border-2 border-blue-700">
                  <div className="text-xs text-gray-900 capitalize">
                    Game Name
                  </div>
                  <div className="text-md font-roboto font-bold capitalize text-gray-900">
                    WormHole
                  </div>
                </div>
              </div>
              <div className="flex-grow h-auto md:w-1/2">
                {/* flex-grow h-auto md:w-1/2 */}
                <div className="flex w-full mt-6 md:mt-0 pb-6">
                  <div>
                    <h1 className="text-2xl font-bold text-white capitalize">
                      Practice Result
                    </h1>

                    <h2 className="text-base font-medium text-white capitalize">
                      Matter and Its Interactions / Periodic table
                    </h2>
                  </div>
                </div>
                {resultsJson?.buttons?.next_batch_assignment?.link && (
                  <a
                    href={resultsJson?.buttons?.next_batch_assignment?.link}
                    className="bg-blues   
                hover:opacity-90 inline-block whitespace-nowrap rounded-full text-sm xl:w-40 xxl:w-40 text-center py-2 text-white mt-2 mb-2"
                  >
                    {resultsJson?.buttons?.next_batch_assignment?.title}
                  </a>
                )}

                <div className="flex-none xxl:flex">
                  <div className="flex-none w-full xl:w-4/5 xxl:w-2/5 bg-gray-50 rounded-md xl:mr-12 mb-12 xxl:mb-0 overflow-hidden">
                    {resultsJson && Object.keys(resultsJson).length > 0 && (
                      <ProgressWidget data={resultsJson} />
                    )}
                  </div>
                  <div className="flex-grow w-full xl:w-4/5 xxl:w-3/5">
                    {resultsJson &&
                      Object.keys(resultsJson).length > 0 &&
                      resultsJson?.results_header?.assignment?.result
                        ?.not_graded === 0 && (
                        <>
                          <TotalQuestionsByDomains data={resultsJson} />
                          <TotalQuestionsByStandards data={resultsJson} />
                        </>
                      )}
                  </div>
                </div>
              </div>
            </div>
            <div className="p-5">
              <div className="p-5 bg-gray-50 border rounded-md text-sm font-semibold">
                <h2>Answer status by Question No.</h2>
                <ul className="flex flex-wrap gap-2 w-full xl:w-1/2 mt-3">
                  <li className="w-6 h-6 border border-green-400 text-gray-900 text-xs flex items-center rounded-sm shadow-sm  justify-center bg-orange-light cursor-pointer">
                    1
                  </li>
                  <li className="w-6 h-6 border border-red-400 text-gray-500 text-xs flex items-center rounded-sm shadow-sm justify-center hover:bg-orange-light hover:text-gray-900 cursor-pointer">
                    2
                  </li>
                  <li className="w-6 h-6 border border-green-400 text-gray-500 text-xs flex items-center rounded-sm shadow-sm justify-center hover:bg-orange-light hover:text-gray-900 cursor-pointer">
                    3
                  </li>
                  <li className="w-6 h-6 border border-green-400 text-gray-500 text-xs flex items-center rounded-sm shadow-sm justify-center hover:bg-orange-light hover:text-gray-900 cursor-pointer">
                    4
                  </li>
                  <li className="w-6 h-6 border border-red-400 text-gray-500 text-xs flex items-center rounded-sm shadow-sm justify-center hover:bg-orange-light hover:text-gray-900 cursor-pointer">
                    5
                  </li>
                  <li className="w-6 h-6 border border-green-400 text-gray-500 text-xs flex items-center rounded-sm shadow-sm justify-center hover:bg-orange-light hover:text-gray-900 cursor-pointer">
                    6
                  </li>
                  <li className="w-6 h-6 border border-green-400 text-gray-500 text-xs flex items-center rounded-sm shadow-sm justify-center hover:bg-orange-light hover:text-gray-900 cursor-pointer">
                    7
                  </li>
                  <li className="w-6 h-6 border border-green-400 text-gray-500 text-xs flex items-center rounded-sm shadow-sm justify-center hover:bg-orange-light hover:text-gray-900 cursor-pointer">
                    8
                  </li>
                  <li className="w-6 h-6 border border-green-400 text-gray-500 text-xs flex items-center rounded-sm shadow-sm justify-center hover:bg-orange-light hover:text-gray-900 cursor-pointer">
                    9
                  </li>
                  <li className="w-6 h-6 border border-green-400 text-gray-500 text-xs flex items-center rounded-sm shadow-sm justify-center hover:bg-orange-light hover:text-gray-900 cursor-pointer">
                    10
                  </li>
                  <li className="w-6 h-6 border border-green-400 text-gray-500 text-xs flex items-center rounded-sm shadow-sm justify-center hover:bg-orange-light hover:text-gray-900 cursor-pointer">
                    11
                  </li>
                  <li className="w-6 h-6 border border-red-400 text-gray-500 text-xs flex items-center rounded-sm shadow-sm justify-center hover:bg-orange-light hover:text-gray-900 cursor-pointer">
                    12
                  </li>
                  <li className="w-6 h-6 border border-red-400 text-gray-500 text-xs flex items-center rounded-sm shadow-sm justify-center hover:bg-orange-light hover:text-gray-900 cursor-pointer">
                    13
                  </li>
                  <li className="w-6 h-6 border border-green-400 text-gray-500 text-xs flex items-center rounded-sm shadow-sm justify-center hover:bg-orange-light hover:text-gray-900 cursor-pointer">
                    14
                  </li>
                  <li className="w-6 h-6 border border-green-400 text-gray-500 text-xs flex items-center rounded-sm shadow-sm justify-center hover:bg-orange-light hover:text-gray-900 cursor-pointer">
                    15
                  </li>
                  <li className="w-6 h-6 border border-green-400 text-gray-500 text-xs flex items-center rounded-sm shadow-sm justify-center hover:bg-orange-light hover:text-gray-900 cursor-pointer">
                    16
                  </li>
                  <li className="w-6 h-6 border border-green-400 text-gray-500 text-xs flex items-center rounded-sm shadow-sm justify-center hover:bg-orange-light hover:text-gray-900 cursor-pointer">
                    17
                  </li>
                  <li className="w-6 h-6 border border-green-400 text-gray-500 text-xs flex items-center rounded-sm shadow-sm justify-center hover:bg-orange-light hover:text-gray-900 cursor-pointer">
                    18
                  </li>
                  <li className="w-6 h-6 border border-red-400 text-gray-500 text-xs flex items-center rounded-sm shadow-sm justify-center hover:bg-orange-light hover:text-gray-900 cursor-pointer">
                    19
                  </li>
                  <li className="w-6 h-6 border border-green-400 text-gray-500 text-xs flex items-center rounded-sm shadow-sm justify-center hover:bg-orange-light hover:text-gray-900 cursor-pointer">
                    20
                  </li>
                  <li className="w-6 h-6 border border-green-400 text-gray-500 text-xs flex items-center rounded-sm shadow-sm justify-center hover:bg-orange-light hover:text-gray-900 cursor-pointer">
                    21
                  </li>
                  <li className="w-6 h-6 border border-green-400 text-gray-500 text-xs flex items-center rounded-sm shadow-sm justify-center hover:bg-orange-light hover:text-gray-900 cursor-pointer">
                    22
                  </li>
                  <li className="w-6 h-6 border border-green-400 text-gray-500 text-xs flex items-center rounded-sm shadow-sm justify-center hover:bg-orange-light hover:text-gray-900 cursor-pointer">
                    23
                  </li>
                  <li className="w-6 h-6 border border-green-400 text-gray-500 text-xs flex items-center rounded-sm shadow-sm justify-center hover:bg-orange-light hover:text-gray-900 cursor-pointer">
                    24
                  </li>
                  <li className="w-6 h-6 border border-green-400 text-gray-500 text-xs flex items-center rounded-sm shadow-sm justify-center hover:bg-orange-light hover:text-gray-900 cursor-pointer">
                    25
                  </li>
                  <li className="w-6 h-6 border border-green-400 text-gray-500 text-xs flex items-center rounded-sm shadow-sm justify-center hover:bg-orange-light hover:text-gray-900 cursor-pointer">
                    26
                  </li>
                  <li className="w-6 h-6 border border-green-400 text-gray-500 text-xs flex items-center rounded-sm shadow-sm justify-center hover:bg-orange-light hover:text-gray-900 cursor-pointer">
                    27
                  </li>
                  <li className="w-6 h-6 border border-green-400 text-gray-500 text-xs flex items-center rounded-sm shadow-sm justify-center hover:bg-orange-light hover:text-gray-900 cursor-pointer">
                    28
                  </li>
                  <li className="w-6 h-6 border border-green-400 text-gray-500 text-xs flex items-center rounded-sm shadow-sm justify-center hover:bg-orange-light hover:text-gray-900 cursor-pointer">
                    29
                  </li>
                  <li className="w-6 h-6 border border-green-400 text-gray-500 text-xs flex items-center rounded-sm shadow-sm justify-center hover:bg-orange-light hover:text-gray-900 cursor-pointer">
                    30
                  </li>
                  <li className="w-6 h-6 border border-green-400 text-gray-500 text-xs flex items-center rounded-sm shadow-sm justify-center hover:bg-orange-light hover:text-gray-900 cursor-pointer">
                    31
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default KGRESULT;

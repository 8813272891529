import { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { GoogleApi } from "../../../../../../api/google/googleApi";
import { CloseIcon } from "../../../../../../assets/icons";
import Profile from "../../../../../../model/users/profile";
import { UserContextState } from "../../../../../../redux/actions/userContextAction";
import Loader from "../../../../../shared/loader";
import { IGoogleCourseDTO } from "../../../../../../model/googleClassroom/googleCourseDTO";

interface ConnectToGoogleClassPopupProps {
  userContext?: UserContextState;
  profile?: Profile;
  classId: number;
  googleCourseId: string;
  showPopup(show: boolean): void;
  onSuccess(): void;
}

function ConnectToGoogleClassPopup(props: ConnectToGoogleClassPopupProps) {
  const [loading, setLoading] = useState(false);
  const [classes, setClasses] = useState<IGoogleCourseDTO[]>([]);
  const [overrideClassDisplayName, setOverrideClassDisplayName] =
    useState(false);
  const [selectedCourse, setSelectedCourse] = useState<IGoogleCourseDTO | null>(
    null
  );

  const getGoogleClasses = () => {
    setLoading(true);
    GoogleApi.getGoogleClassesToImport(
      props?.userContext?.userId!,
      props?.profile?.googleUserId!,
      props?.profile?.googleId!,
      props?.profile?.googleUser?.refreshToken!
    )
      .then((r) => {
        setLoading(false);

        if (r.data && r.data.length > 0) {
          const selectedCourse =
            props.googleCourseId !== null
              ? r.data.filter((c) => {
                  return c.courseId === props.googleCourseId;
                })
              : [];
          const nonImportedCourses = r.data.filter((c) => !c.isImported);
          let allCourses = [...nonImportedCourses];
          allCourses = allCourses.sort((a, b) =>
            a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
          );
          if (selectedCourse.length > 0) {
            setSelectedCourse(selectedCourse[0]);
          }
          setClasses(allCourses);
        }
      })
      .catch((r) => {
        setLoading(false);
      });
  };

  const handleSelectCourse = (course) => {
    setSelectedCourse(course);
  };

  const validate = () => {
    let isValid = true;
    if (selectedCourse === null) {
      isValid = false;
      toast.error("Please select the course");
    }
    return isValid;
  };

  const connectToGoogle = () => {
    if (validate()) {
      setLoading(true);
      GoogleApi.connectToGoogle(
        props.classId,
        props?.userContext?.userId!,
        overrideClassDisplayName,
        selectedCourse!
      )
        .then((r) => {
          setLoading(false);
          props.onSuccess();
          props.showPopup(false);
        })
        .catch((r) => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    getGoogleClasses();
  }, []);

  return (
    <Fragment>
      <div className="fixed inset-0 z-40 flex items-center bg-black bg-opacity-50 sm:items-center sm:justify-center">
        <div className="w-100 flex flex-col justify-between overflow-hidden h-auto max-h-80percent bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:max-w-6xl">
          <header>
            {/* Header */}
            <div className="px-4 py-4 bg-gray-50">
              <div className="flex items-start justify-between space-x-3">
                <div className="space-y-1">
                  <div className="text-lg font-medium text-gray-900">
                    Google Classroom Courses
                  </div>
                </div>
                <div className="h-7 flex items-center">
                  <button
                    type="button"
                    className="inline-flex items-center justify-center w-6 h-6 text-gray-400 transition-colors duration-150 rounded dark:hover:text-gray-200 hover: hover:text-gray-700"
                    onClick={() => props.showPopup(false)}
                  >
                    <CloseIcon />
                  </button>
                </div>
              </div>
            </div>
          </header>
          <div className="w-full h-72 px-4 py-1 relative overflow-auto">
            {loading && <Loader />}

            <div className="my-2">
              {classes.length > 0 &&
                classes.map((c, i) => (
                  <div key={i} className="">
                    <label
                      x-radio-group-option=""
                      className=" flex items-center cursor-pointer text-sm text-gray-500 "
                    >
                      <input
                        type="radio"
                        x-model="value"
                        name="googleclassroom"
                        value="Public access"
                        className="h-4 w-4 cursor-pointer text-primary-violet border-gray-300 focus:ring-primary-violet"
                        aria-labelledby="privacy-setting-0-label"
                        aria-describedby="privacy-setting-0-description"
                        checked={selectedCourse?.courseId === c.courseId}
                        onChange={() => handleSelectCourse(c)}
                      />
                      <div className="ml-3 flex flex-col">
                        <span
                          id="privacy-setting-0-label"
                          className="block text-sm text-gray-500"
                        >
                          {c.name}
                        </span>
                      </div>
                    </label>
                  </div>
                ))}
            </div>
            <div>{classes.length === 0 && "No Records Found"}</div>
          </div>

          {/* <!-- Action Buttons -->  */}
          <footer className="flex items-center justify-between px-4 py-4 sm:flex-row bg-gray-50 dark:bg-gray-800 mt-4">
            <div>
              <label className="flex items-centers cursor-pointer text-sm text-gray-500 ">
                <input
                  type="checkbox"
                  x-model="value"
                  name="privacy_setting"
                  value="Private to Project Members"
                  className="h-4 w-4 mr-2 mt-0.5 cursor-pointer text-blue-600 border-gray-300 focus:ring-blue-500"
                  checked={overrideClassDisplayName}
                  onChange={(e) => {
                    setOverrideClassDisplayName(e.target.checked);
                  }}
                />
                Override class display name
              </label>
            </div>
            <div className="space-x-3">
              <button
                type="button"
                className="w-auto inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                onClick={connectToGoogle}
              >
                Connect
              </button>
            </div>
          </footer>
        </div>
      </div>
    </Fragment>
  );
}

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
};

export default connect<{}, {}, {}>(
  mapStateToProps,
  {}
)(ConnectToGoogleClassPopup);

import { Component, Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import userApi from "../../api/userApi";
import { LogoPlWhite } from "../../assets/icons";
import { UserContextState } from "../../redux/actions/userContextAction";
import Constant from "../../utils/constant/constant";
import Loader from "../shared/loader";
import NoData from "../shared/noData";
interface passedProps {
  props: UserContextState;
  mode: string;
  selectedGrade: number;
  selectedClass: number;
  firstName: string;
  lastName: string;
  username: string;
  studentId: string;
  selectedSchool: number;
  schoolAccountId: number;
  userContext?: UserContextState;
}

export class StudentsIdCardToPrint extends Component<passedProps> {
  render() {
    return (
      <StudentIdCard
        props={this.props.props}
        mode={this.props.mode}
        selectedGrade={this.props.selectedGrade}
        selectedClass={this.props.selectedClass}
        firstName={this.props.firstName}
        lastName={this.props.lastName}
        username={this.props.username}
        studentId={this.props.studentId}
        selectedSchool={this.props.selectedSchool}
        schoolAccountId={this.props.schoolAccountId}
        userContext={this.props.userContext}
      ></StudentIdCard>
    );
  }
}

function StudentIdCard(props: passedProps) {
  const defaultStudentData: Array<any> = [];
  type ExportType = "NONE" | "CSV" | "PDF" | "PasswordCard";
  const [studentsIdCardData, setStudenIdCardtData] =
    useState(defaultStudentData);
  const [showLoader, setLoader] = useState(false);
  const [showNoData, setShowNoData] = useState(false);

  useEffect(() => {
    showStudentsIdCard();
  }, [1]);

  const getRoleId = (mode: string) => {
    if (props.mode === Constant.ManageUserMode.Student) {
      return Constant.UserRoleId.Student;
    } else if (props.mode === Constant.ManageUserMode.Teacher) {
      return Constant.UserRoleId.SchoolTeacher;
    } else if (props.mode === Constant.ManageUserMode.District) {
      return Constant.UserRoleId.District;
    } else {
      return Constant.UserRoleId.Student;
    }
  };

  function showStudentsIdCard(exportType: ExportType = "PasswordCard") {
    setLoader(true);

    const userSearchQuery: any = {
      userId: props.props.userId,
      firstName: props.firstName ?? "",
      lastName: props.lastName ?? "",
      username: props.username ?? "",
      gradeId: props.selectedGrade ?? 0,
      classId: props.selectedClass ?? 0,
      studentId: props.studentId ?? "",
      roleId: getRoleId(props.mode),
      schoolAccountId: props.schoolAccountId,
      OrderByColumn: "LastName",
      SortDir: 0,
      isActive: true,
      PageSize: 1000,
      PageNumber: 1,
      exportType: exportType,
      districtId: props.userContext?.districtId,
    };

    const response = userApi.GetAllUsers(userSearchQuery);
    response
      ?.then((d) => {
        setLoader(false);
        bindIdCardData(d.data.data);
      })
      .catch((err) => {
        setLoader(false);
      });
  }
  function bindIdCardData(data: any) {
    setLoader(false);
    setShowNoData(data && data.length > 0 ? false : true);
    setStudenIdCardtData(data);
  }

  return (
    <Fragment>
      {showLoader && <Loader></Loader>}
      <div className="w-full flex flex-wrap  printpdf-div">
        {studentsIdCardData.length > 0 &&
          studentsIdCardData.map((option, index) => {
            return (
              <div
                key={index}
                className="mr-2 border rounded-lg overflow-hidden inline-block mb-4 w-card27  printpdf"
              >
                <div className="bg-primary-violet px-4 py-3 flex items-center justify-between gap-2">
                  <h2 className="text-xl mb-0 leading-tight text-white">
                    {option.lastName}, {option.firstName}
                  </h2>
                  <LogoPlWhite className="w-16 h-12 p-1" />
                </div>

                <div className="bg-white grid grid-cols-1 gap-x-4 divide-y px-4 py-4">
                  <div className="sm:col-span-1 flex items-center gap-2 py-0.5">
                    <dt className="text-md text-gray-500 font-normal w-28">
                      Account Id
                    </dt>
                    <dd className="mt-0 text-md text-gray-600 font-medium leading-tight font-medium break-all">
                      {option.accountId}
                    </dd>
                  </div>
                  <div className="sm:col-span-1 flex items-center gap-2 py-0.5">
                    <dt className="text-md text-gray-500 font-normal w-28">
                      Username
                    </dt>
                    <dd className="font-roboto-mono mt-0 text-md text-gray-600 font-medium leading-tight break-all">
                      {option.username}
                    </dd>
                  </div>
                  {props.mode === Constant.ManageUserMode.Student && (
                    <>
                      <div className="sm:col-span-1 flex items-center gap-2 py-0.5">
                        <dt className="text-md text-gray-500 font-normal w-28">
                          Student ID
                        </dt>
                        <dd className="mt-0 text-md text-gray-600 font-medium leading-tight">
                          {option.studentId}
                        </dd>
                      </div>

                      <div className="sm:col-span-1 flex items-center gap-2 py-0.5">
                        <dt className="text-md text-gray-500 font-normal w-28">
                          Grade
                        </dt>
                        <dd className="mt-0 text-md text-gray-600 font-medium leading-tight ">
                          {option.grade}
                        </dd>
                      </div>
                    </>
                  )}

                  <div className="sm:col-span-1 flex items-center gap-2 py-0.5">
                    <dt className="text-md text-gray-500 font-normal w-28">
                      Password
                    </dt>
                    <dd className="font-roboto-mono mt-0 text-md text-gray-600 font-medium leading-tight break-all">
                      <div className="flex space-x-1">
                        {atob(option.passwordHash)}
                      </div>
                    </dd>
                  </div>
                </div>
                <div className="flex justify-end pr-2 bg-gray-100 text-sm text-gray-400 hover:underline cursor-pointer border-t text-xs py-0.5">
                  <a href="https://app.progresslearning.com">
                    https://app.progresslearning.com
                  </a>
                </div>
              </div>
            );
          })}
        {showNoData && <NoData></NoData>}
      </div>
    </Fragment>
  );
}

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(StudentIdCard);

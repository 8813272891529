export type StudentType = "KINDERGARTEN" | "ELEMENTARY" | "HIGHSCHOOL";
export type StudyType =
  | "Assignments"
  | "Graded Work"
  | "Diagnostics"
  | "My Study Plan"
  | "Skill Practice"
  | "Alien Arena"
  | "My Galaxies";
export type layoutType =
  | "NONE"
  | "TEACHER"
  | "KINDERGARTEN"
  | "ELEMENTARY"
  | "MIDDLESCHOOL";
export type PreBuildActivityType = "Summative" | "Domain" | "Canned";
export type AssessmentActivityType = "Assessment" | "PremiumAssessment";
export type AssignmentType =
  | "MYASSIGNMENT"
  | "CLASSASSIGNMENT"
  | "SHAREDASSIGNMENT"
  | "SUGGESTEDPRACTICE"
  | "REMEDIATIONASSIGNMENT";

export type SortingOrder = "asc" | "desc";

export enum StudentSchoolLevel {
  Kindergarten,
  Elementary,
  Middle,
  High,
}

export enum PermissionType {
  View = 1,
  Create = 2,
  Modify = 4,
  Delete = 8,
  Upgrade = 16,
}

export interface UserPermission {
  flags: PermissionType;
}

export type StudentAssignmentSetting = "TTS" | "Language";

export enum SubscriptionRequestType {
  Renew = 1,
  Quote = 2,
  Upgrade = 3,
  Trial = 4,
}

export enum NWEATestResultBatchStatus {
  Received = "Received",
  Inprogress = "Inprogress",
  Failed = "Failed",
  Completed = "Completed",
  PartialFailure = "PartialFailure",
}

export type ReportType = 'ASSIGNMENT' | 'ASSESSMENT' | 'PREMIUMASSESSMENT';

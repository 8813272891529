import { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { getAssignmentAssociatedClasses } from "../../../../../api/class/classStudent";
import { getStudentActivitiesStatus } from "../../../../../api/student/studentActivityApi";
import { StudentAssignmentApi } from "../../../../../api/student/studentAssignmentApi";
import {
  CompleteSavedActivityIcon,
  ConstructedResponseIcon,
  DownArrowIcon,
  GeneratedTestIcon,
  LegendGroupAssignmentIcon,
  LegendQuestionsIcon,
  LegendVideoIcon,
  TakeAssignmentIcon,
} from "../../../../../assets/icons";
import ActivityStatusListResponse from "../../../../../model/student/shared/assignment/activityStatusListResponse";
import AssignmentListResponse from "../../../../../model/student/shared/assignment/assignmentListResponse";
import AssignmentSearchModel from "../../../../../model/student/shared/assignment/assignmentSearchModel";
import Loader from "../../../../shared/loader";
import TakeAssignmentDialog from "../../shared/takeAssignmentDialog";
import AssignmentList from "./assignmentList";
import CompleteSavedActivity from "./completeSavedActivity";
import RouteConstant from "../../../../../utils/constant/routeConstant";
import { useHistory } from "react-router-dom";
import UpgradeButtonControl from "../../../../shared/customButtonControl";
import { redirect } from "../../../../../utils/routeRedirectHelper";

function Assignments(props: any) {
  const { t } = useTranslation();
  const history = useHistory();
  const [isDDLOpened, setIsDDLOpened] = useState<boolean>(false);
  const [ddlName, setDDLName] = useState<string>(t("Assignments.ViewByClass"));
  const [btnChangeValue, setBtnChangeValue] = useState<number>(1);
  const [filterData, setFilterData] = useState<any[] | null>([
    {
      id: 1590703,
      name: "Canvas Non LTI Course",
      teacher: {
        salutation: "",
        first: "Joyce",
        middle: "",
        last: "Buhrmester",
        display_name: "Joyce Buhrmester",
      },
    },
    {
      id: 1927576,
      name: "Test Class - New Student UI",
      teacher: {
        salutation: "Dr.",
        first: "Felicia",
        middle: "",
        last: "Example",
        display_name: "Dr. Example",
      },
    },
  ]);
  const [assignments, setAssignments] = useState<Array<AssignmentListResponse>>(
    []
  );
  const [actvities, setActvities] = useState<Array<ActivityStatusListResponse>>(
    []
  );
  const [openAssignmentDialog, setOpenAssignmentDialog] = useState(false);
  const [showCompleteActivity, setShowCompleteActivity] = useState(false);

  const [loading, showLoading] = useState<boolean>(false);

  useEffect(() => {
    getAssignments();
    getStudentActivities();
  }, []);

  function getAssignments(classId?: number) {
    showLoading(true);
    const search: AssignmentSearchModel = {
      studentId: props.userContext?.userId ? props.userContext?.userId : 0,
      subjectId: 0,
      pageNumber: 1,
      pageSize: 99999,
      orderByColumn: "",
      sortDir: 0,
      classId: classId ? classId : undefined,
    };
    StudentAssignmentApi.getStudentAssignments(search).then((r) => {
      setAssignments(r.data.data);
      showLoading(false);
    });
  }

  function getStudentActivities() {
    getStudentActivitiesStatus(props.userContext.userId).then((r) => {
      setActvities(r.data);
    });
  }

  const ddlBtnClick = () => {
    setIsDDLOpened(!isDDLOpened);
    getAssignmentAssociatedClasses(props.userContext?.userId).then((d) => {
      setFilterData(d.data);
    });
  };
  const onGridBtnClick: any = async (param) => {
    setBtnChangeValue(param);
    param === 1
      ? setShowCompleteActivity(false)
      : setShowCompleteActivity(true);
  };

  const filterDataOnChange: any = (
    classId: number,
    className: string
  ): void => {
    if (classId === 0) {
      setDDLName(t("Assignments.ViewByClass"));
      setIsDDLOpened(false);
      getAssignments();
    } else {
      setDDLName(className);
      setIsDDLOpened(false);
      getAssignments(classId);
    }
  };

  useEffect(() => {
    window.addEventListener("click", function (e) {
      const ddl = document.getElementById("custom_ddl");

      if (ddl) {
        const ev: any = e.target;
        if (ev) {
          if (!ddl.contains(ev)) {
            setIsDDLOpened(false);
          }
        }
      }
    });
  }, []);

  const onAssignmentDialogCancelClick = () => {
    setOpenAssignmentDialog(false);
  };
  const onAssignmentDialogOkClick = (
    assignmentCode: any,
    subjectId: number
  ) => {
    if (assignmentCode) {
      startAssessmentActivity(assignmentCode, subjectId);
    }
    setOpenAssignmentDialog(false);
  };

  function startAssessmentActivity(code, subjectId) {
    redirect({
      urlTemplate: RouteConstant.StudentRoute.StudentAssessmentActivity,
      code: code,
      subjectId: subjectId,
      multipleAttemptAllowed: false,
      history: history,
    });
    // var multipleAttemptAllowed = false;
    // history.push(
    //   RouteConstant.StudentRoute.StudentAssessmentActivity.replace(
    //     ":code",
    //     encodeURI(btoa(code.toString()))
    //   )
    //     .replace(
    //       ":multipleAttemptAllowed",
    //       encodeURI(btoa(multipleAttemptAllowed.toString()))
    //     )
    //     .replace(":subjectId", encodeURI(btoa(subjectId.toString())))
    // );
  }

  return (
    <Fragment>
      {loading && <Loader className="z-10" />}
      <TakeAssignmentDialog
        open={openAssignmentDialog}
        onCancelClick={onAssignmentDialogCancelClick}
        onOkClick={onAssignmentDialogOkClick}
      ></TakeAssignmentDialog>

      <div className="w-full">
        <div>
          <div
            className={`flex flex-col lg:flex-row justify-between md:mb-2 xl:mb-0 ${
              showCompleteActivity ? "hidden" : ""
            }`}
          >
            <div
              id="custom_ddl"
              className={`ddl flex mr-auto bg-white hover:shadow-lg text-gray-700 justify-center mb-auto py-2 rounded-lg w-auto relative lg:mr-4 px-3 ${
                showCompleteActivity ? "hidden" : ""
              }`}
              onClick={() => ddlBtnClick()}
            >
              {btnChangeValue === 1 && (
                <button
                  type="button"
                  className="rounded-full flex items-center text-gray-700 text-sm focus:outline-none"
                  aria-expanded="false"
                  aria-haspopup="true"
                >
                  <ul className="">
                    <li className="flex text-left font-medium text-gray-700 text-sm whitespace-nowrap">
                      {ddlName || t("Assignments.ViewByClass")}
                      <span className="flex items-center justify-center w-6 h-6 bg-site-pink bg-opacity-10 rounded-full ml-1.5 inline-block ">
                        <DownArrowIcon className="inline h-5 w-5 text-site-pink" />
                      </span>
                    </li>
                  </ul>
                </button>
              )}
              {isDDLOpened && (
                <div
                  className="left-12 cursor-pointer transition-all origin-top-right absolute top-8 -left-0 mt-2 w-60 z-50 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="user-menu-button"
                  tabIndex={-1}
                >
                  {filterData && filterData.length > 0 && (
                    <div
                      className="px-4 py-2 hide-ddl-text block text-sm text-black-333 font-medium hover:bg-gray-100"
                      key={0}
                      onClick={() => filterDataOnChange(0, "All")}
                    >
                      {"All"}
                    </div>
                  )}

                  {filterData &&
                    filterData.length > 0 &&
                    filterData.map((ds) => {
                      return (
                        <div
                          className="px-4 py-2 hide-ddl-text block text-sm text-black-333 font-medium hover:bg-gray-100"
                          key={ds.id}
                          onClick={() =>
                            filterDataOnChange(
                              ds.classId,
                              ds.className + " - " + ds.lastName
                            )
                          }
                        >
                          {ds.className} - {ds.lastName}
                        </div>
                      );
                    })}

                  {filterData && filterData.length === 0 && (
                    <div className="px-4 py-2 hide-ddl-text block text-sm text-black-333 font-medium hover:bg-gray-100">
                      {t("Assignments.NoRecordsFound")}
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className="h-auto py-3 px-4 w-auto bg-black bg-opacity-20 rounded-lg space-y-2 mt-4 lg:mt-0">
              <ul className="text-white text-sm grid grid-cols-1 md:grid-flow-col md:grid-cols-2 md:grid-rows-3 lg:grid-cols-2 lg:grid-rows-3 2xl:grid-cols-3 2xl:grid-rows-2 xxxl:grid-cols-3 xxxl:grid-rows-2 gap-2">
                <li className="flex items-center text-xs gap-x-1.5">
                  <div className="w-7 h-7 bg-black bg-opacity-20 flex items-center justify-center rounded-full">
                    <TakeAssignmentIcon className="flex-none w-4 h-4 stroke-current text-white opacity-90 block align-text-bottom" />
                  </div>
                  {t("Assignments.Assessments")}
                </li>
                <li className="flex items-center xl:items-start xxxl:items-center text-xs gap-x-1.5">
                  <div className="w-7 h-7 bg-black bg-opacity-20 flex items-center justify-center rounded-full">
                    <ConstructedResponseIcon className="flex-none w-4 h-4 fill-current text-white block align-text-top" />
                  </div>
                  {t("Assignments.ConstructedResponse")}
                </li>
                <li className="flex items-center xl:items-start 2xl:items-center text-xs gap-x-1.5">
                  <div className="w-7 h-7 bg-black bg-opacity-20 flex items-center justify-center rounded-full">
                    <GeneratedTestIcon className="flex-none w-5 h-4 inline-block align-text-bottom" />
                  </div>
                  {t("Assignments.GeneratedTest")}
                </li>
                <li className="flex items-center  2xl:items-start xxxl:items-center text-xs gap-x-1.5">
                  <div className="w-7 h-7 bg-black bg-opacity-20 flex items-center justify-center rounded-full">
                    <LegendVideoIcon className="flex-none w-5 h-5 stroke-current text-white opacity-80 block align-text-top" />
                  </div>
                  {t("Assignments.Video")}
                </li>
                <li className="flex items-center  xl:items-start 2xl:items-center text-xs gap-x-1.5">
                  <div className="w-7 h-7 bg-black bg-opacity-20 flex items-center justify-center rounded-full">
                    <LegendQuestionsIcon className="flex-none w-5 h-5 fill-current text-white opacity-90 block align-text-top" />
                  </div>
                  {t("Assignments.Questions")}
                </li>
                <li className="flex items-center  xl:items-start xxxl:items-center xxxl:items-center text-xs gap-x-1.5">
                  <div className="w-7 h-7 bg-black bg-opacity-20 flex items-center justify-center rounded-full">
                    <LegendGroupAssignmentIcon className="flex-none w-4 h-4 stroke-current text-white block align-text-top" />
                  </div>
                  {t("Assignments.GroupAssignments")}
                </li>
              </ul>
              {/* <ul className="block sm:flex items-center flex-wrap text-white text-sm space-x-0 space-y-2 sm:space-y-0 sm:space-x-4">
                
              </ul> */}
            </div>
          </div>
          <div className="flex flex-col md:flex-row items-end w-full md:gap-x-4 lg:gap-x-0 mt-6">
            <div className="flex gap-x-1">
              <div
                onClick={() => onGridBtnClick(1)}
                className={`lg:px-4 xl:px-2 1xl:px-4 text-center border-t-[3px] text-sm cursor-pointer font-medium whitespace-nowrap ${
                  btnChangeValue === 1
                    ? "border-secondary-teal bg-gray-100 text-gray-700 hover:text-gray-900 py-2.5 tab-box-shadow rounded-t-lg"
                    : "text-gray-500 bg-white/50 rounded-t-lg mt-auto transition-all py-[0.4rem] hover:py-2.5 hover:py-3 hover:rounded-t-lg hover:text-gray-700 border-transparent"
                }`}
              >
                <span className=" px-2 text-sm">
                  {t("Assignments.ViewAllAssignmentsCaps")}{" "}
                </span>
              </div>
              <div
                className={`w-68 order-last sm:order-second text-sm flex  sm:inline-flex justify-start md:justify-center items-center lg:px-4 xl:px-2 1xl:px-4 text-center border-t-2 text-sm cursor-pointer font-medium group false ${
                  btnChangeValue === 2
                    ? "border-secondary-teal bg-gray-100 text-gray-700 hover:text-gray-900 py-2.5 tab-box-shadow rounded-t-lg"
                    : "text-gray-500 bg-white/50 rounded-t-lg mt-auto transition-all py-[0.45rem] hover:py-2.5 hover:py-3 hover:rounded-t-lg hover:text-gray-700 border-transparent"
                }`}
              >
                <span className="relative text-gray-800">
                  <CompleteSavedActivityIcon />
                </span>

                <a
                  href="#!"
                  onClick={() => onGridBtnClick(2)}
                  className={` ${
                    btnChangeValue === 2 ? "" : ""
                  } flex text-left lg:text-center lg:whitespace-nowrap cursor-pointer hover:text-gray-700 text-sm`}
                >
                  <CompleteSavedActivityIcon className="w-6 h-6 flex-none fill-current text-gray-500 mr-1 group-hover:text-gray-700" />{" "}
                  {t("Assignments.CompleteASavedActivity")}
                </a>
              </div>
            </div>
            <div
              className={
                "flex items-center ml-auto order-first md:order-last " +
                (props.profile?.isUpgradeRequired
                  ? "cursor-not-allowed "
                  : "cursor-pointer ")
              }
            >
              <UpgradeButtonControl
                controlType={"a"}
                userContext={props.userContext}
                isUpgradeRequired={props.profile?.isUpgradeRequired}
                isRoleCheck={true}
                className={
                  "inline-flex items-center md:whitespace-nowrap order-first flex items-center bg-black  float-right px-3 py-2 rounded-lg mb-2 mt" +
                  (props.profile?.isUpgradeRequired
                    ? " cursor-not-allowed text-gray-100 bg-opacity-10 hover:text-gray-100 hover:bg-opacity-10"
                    : "cursor-pointer bg-opacity-25 hover:text-white hover:bg-opacity-30 hover:shadow-lg text-white ")
                }
                onClick={() => {
                  setOpenAssignmentDialog(true);
                }}
              >
                <span className="bg-white w-6 h-6 rounded-md flex items-center justify-center relative mr-2">
                  <TakeAssignmentIcon className="w-4 h-4 stroke-current hover:shadow text-site-pink" />
                </span>
                {t("Assignments.TakeAnAssessment")}
              </UpgradeButtonControl>
            </div>
          </div>
        </div>
        {showCompleteActivity ? (
          <CompleteSavedActivity
            activityData={actvities}
            userId={props.userContext.userId}
            plLanguageId={(props.profile?.plLanguageId ?? 1) === 2 ? 2 : 1}
          />
        ) : (
          <AssignmentList
            key={ddlName}
            assignmentData={assignments}
            userId={props.userContext.userId}
          />
        )}
      </div>
    </Fragment>
  );
}

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(Assignments);

import InfoMessage from '../../../../shared/infoMessage';
import { EnglishIcon, MathIcon, ScienceIcon, SocialStudiesIcon } from '../../../../../assets/icons';
import classNames from 'classnames';
import { GradeLevelSubject, IAvailavelClassSubject } from '../../../../../model/teacher/classManagement/IAvailavelClassSubject';

interface SubjectCardProps {
    gradeLevelSubject: GradeLevelSubject;
    onSubjectSelect: Function;
    selectedSubjects: number[];
  }

interface ClassBuilderSubjectsProps {
  availableSubjects: Array<IAvailavelClassSubject>;
  selectedSubjects: number[];
  selectSubject: (subjectId: number) => void;
  handleSaveAndContinue: () => void;
  handleSkipClassSetup: () => void;
  isDetailsValid: boolean;
}

const ClassBuilderSubjects = ({
    availableSubjects,
    selectedSubjects,
    selectSubject,
    handleSaveAndContinue,
    handleSkipClassSetup,
    isDetailsValid,
  }: ClassBuilderSubjectsProps) => {

    // Mark subjects as selected if they are in the selectedSubjects list
  const markSelectedSubjects = (availableSubjects: Array<IAvailavelClassSubject>) => {
    return availableSubjects.map(subject => ({
      ...subject,
      gradeLevelSubject: subject.gradeLevelSubject.map(gls => ({
        ...gls,
        subjects: gls.subjects.map(sub => ({
          ...sub,
          isSelected: selectedSubjects.includes(sub.subjectId)
        }))
      }))
    }));
  };

  const updatedAvailableSubjects = markSelectedSubjects(availableSubjects);


  return (
    <div className="w-full relative overflow-auto px-4 py-6">
      <div className="w-full flex">
        {/* Subject Selection Content */}
        <div className="flex-grow">
          <InfoMessage message="Select the subject(s) you would like associated with your class." />
          <div className="w-full">
            {updatedAvailableSubjects.map((availableSubject) => (
              <div
                className={classNames(
                  'flex flex-col text-white text-lg py-3 px-3',
                  availableSubject.gradeLevel === 'Elementary School'
                    ? 'bg-galaxy-green'
                    : availableSubject.gradeLevel === 'Middle School'
                    ? 'bg-light-blue'
                    : 'bg-light-green'
                )}
              >
                <div>{availableSubject.gradeLevel}</div>
                <div className="flex flex-col gap-y-4 md:flex-row flex-wrap space-x-0 md:gap-4">
                  {availableSubject.gradeLevelSubject.map((gl) => (
                    <div className="flex">
                      <SubjectCard
                        selectedSubjects={selectedSubjects}
                        gradeLevelSubject={gl}
                        onSubjectSelect={selectSubject}
                      />
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="flex flex-col gap-4 ml-4">
          <button
            className="bg-primary-violet border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-dark-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 w-full"
            onClick={handleSaveAndContinue}
            disabled={!isDetailsValid}
            type="button"
          >
            Save and Continue
          </button>
          <button
            type="button"
            onClick={handleSkipClassSetup}
            className="bg-primary-violet border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-dark-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 w-full"
          >
            Skip Class Setup
          </button>
        </div>
      </div>
    </div>
  );
};

const SubjectCard = (props: SubjectCardProps) => {
    return (
      <div className="bg-white w-full sm:w-80 p-2 mt-1 bg-white rounded-lg hover:shadow-lg transition ease-in-out duration-300">
        <dt className="flex items-center bg-gray-700 rounded-lg">
          <div className="flex items-center justify-center m-1.5 h-9 w-12 rounded-md bg-light-violet text-white">
            {props.gradeLevelSubject.contentAreaName === "Math" && (
              <MathIcon title="Math" />
            )}
            {props.gradeLevelSubject.contentAreaName === "English" && (
              <EnglishIcon title="English language arts" />
            )}
            {props.gradeLevelSubject.contentAreaName === "ELA" && (
              <EnglishIcon title="English language arts" />
            )}
            {props.gradeLevelSubject.contentAreaName === "Science" && (
              <ScienceIcon title="Science" />
            )}
            {props.gradeLevelSubject.contentAreaName === "Social Studies" && (
              <SocialStudiesIcon title="Social studies" />
            )}
          </div>
          <p className="text-lg w-full text-left leading-6 font-normal text-white">
            {props.gradeLevelSubject.contentAreaName}
          </p>
        </dt>
        <div className="divide-y divide-gray-200">
          {props.gradeLevelSubject.subjects.map((sub, i) => (
            <label
              key={i}
              onChange={() => {
                props.onSubjectSelect(sub.subjectId);
              }}
              className="flex justify-start min-h-[3rem] py-1 leading-none items-center text-gray-900 mr-auto text-sm font-medium bg-white px-2 hover:bg-gray-200 shadow cursor-pointer"
            >
              <label className="flex">
                <input
                  type="checkbox"
                  data-subjectId={sub.subjectId}
                  className="h-4 w-4 mt-0.5 cursor-pointer text-primary-violet border-gray-300 focus:ring-primary-violet"
                  defaultChecked={sub.isSelected}
                />
                <span className="pl-2 text-gray-500 hover:text-primary-violet transition ease-in-out duration-300">
                  {sub.name}
                </span>
              </label>
            </label>
          ))}
        </div>
      </div>
    );
  };

export default ClassBuilderSubjects;

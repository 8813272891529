import { Configuration } from "../../../environment/setup";
import { AxiosHelper } from "../../../environment/axiosConfig";

export function emailRequestSSO(
  userName: string,
  roleId: number,
  syncOptions: string,
  accountTypes: string,
  roaster: string,
  launchDate: string,
  emailId: string
) {
  const url = `${Configuration.ApiBaseUrl}Profile/EmailRequestSSO`;
  return AxiosHelper.axiosInstance().get(url, {
    params: {
      userName: userName,
      roleId: roleId,
      syncOptions: syncOptions,
      accountTypes: accountTypes,
      roaster: roaster,
      launchDate: launchDate,
      emailId: userName,
    },
  });
}

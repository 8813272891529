import { Fragment, useState } from "react";
import Moment from "moment";
import { XIcon } from "@heroicons/react/solid";
import TagSelector from "../../../shared/tagSelector";
import { IPremiumAssessmentGridDTO } from "../../../../model/interface/assessment";
import { CancelIcon, TagOutlineIcon } from "../../../../assets/icons";
import { TagColor } from "../../../shared/tagColor";
import { getPremiumAssessmentNameWithSubjectPrefix } from "../../../../utils/assignmentHelper";
import { addTag, removeTag } from "../../../../api/teacher/assessment";
import { toast } from "react-toastify";
interface ManageTagProps {
  userId: number;
  premiumAssessments: Array<IPremiumAssessmentGridDTO>;
  showPopup: (popupAction: boolean, refreshData?: boolean) => void;
  refresh: () => void;
  removeAssesmentSelection: (cannedTestId: number) => void;
}

const defaultAssessmentToEdit = {
  premiumAssessment: null as IPremiumAssessmentGridDTO | null,
};

function ManagePremiumAssessmentTag(props: ManageTagProps) {
  const [showTags, setShowTags] = useState<boolean>(false);
  const [selectedTags, setSelectedTags] = useState<Array<number>>([]);
  const [assessmentToEdit, setAssessmentToEdit] = useState(
    defaultAssessmentToEdit
  );

  const getAssessmentName = (assessment: IPremiumAssessmentGridDTO) => {
    return getPremiumAssessmentNameWithSubjectPrefix(assessment);
  };

  const setExistingTags = () => {
    const tags = props.premiumAssessments
      ?.filter((a) => a.tags != null)
      .map((a) => JSON.parse(a.tags));
    const selectedTags: Array<number> = [];
    tags?.map((t) => {
      t.map((tag) => {
        selectedTags.push(parseInt(tag.UserTagId));
      });
    });

    setSelectedTags(Array.from(selectedTags));
  };

  const handleAddTag = (tagId: number, cannedTestIds?: Array<number>) => {
    if (cannedTestIds && props.premiumAssessments.length > 0) {
      addTag(tagId, [], cannedTestIds).then((r) => {
        props.refresh();
        toast.success("Tag has been applied to selected assessments.");
      });
    }
  };

  const handleRemoveTag = (
    tagId: number,
    tagName?: string,
    cannedTestIds?: Array<number>
  ) => {
    if (
      cannedTestIds &&
      cannedTestIds?.length > 0 &&
      props.premiumAssessments.length > 0
    ) {
      removeTag(tagId, [], cannedTestIds).then((r) => {
        props.refresh();
        toast.success(`${tagName} has been removed.`);
      });
    }
  };

  const onTagButtonClicked = () => {
    setExistingTags();
  };

  const onTagSelectionChange = (tag) => {
    const cannedTestIds = props.premiumAssessments?.map((a) => a.cannedTestId);
    if (tag.selected) {
      setSelectedTags([...selectedTags, tag.tagId]);
      handleAddTag(tag.tagId, cannedTestIds);
    } else {
      setSelectedTags(selectedTags.filter((item) => item !== tag.tagId));
      handleRemoveTag(tag.tagId, tag.name, cannedTestIds);
    }
  };

  const onNewTagCreated = () => {
    props.refresh();
  };

  return (
    <Fragment>
      <div className="fixed inset-0 z-40 flex items-center bg-black bg-opacity-50 sm:items-center sm:justify-center">
        <div
          className="w-full flex flex-col justify-between overflow-hidden h-auto max-h-80percent bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:max-w-5xl"
          role="dialog"
        >
          <header>
            {/* Header */}
            <div className="px-4 py-4 bg-gray-50">
              <div className="flex items-start justify-between space-x-3">
                <div className="space-y-1">
                  <div className="text-lg font-medium text-gray-900">
                    <h2 className="text-xl mb-2">
                      {" "}
                      Add Tag to Premium Assessments{" "}
                    </h2>
                  </div>
                </div>
                <div className="h-7 flex items-center">
                  <button
                    type="button"
                    className="text-gray-400 hover:text-gray-500"
                    onClick={() => props.showPopup(false)}
                  >
                    <span className="sr-only">Close panel</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
          </header>
          <div className="w-full relative overflow-auto px-4 py-6">
            <div className="p-3 min-h-25rem">
              <div className="col-span-8 sm:col-span-4 xl:col-span-2 mb-3 w-72">
                <TagSelector
                  label={"Tags"}
                  title="Select one or more assessments using the check boxes to add a tag to 
                      group assessments together to make them searchable."
                  userId={props.userId}
                  selectedTagIds={selectedTags}
                  onTagSelectChange={onTagSelectionChange}
                  showSelectedTags={false}
                  onTagButtonClicked={onTagButtonClicked}
                  onNewTagCreated={onNewTagCreated}
                />
              </div>
              <div className="mt-3 text-center sm:mt-0 sm:text-left">
                <table className="table w-full ">
                  <thead className="mb-5">
                    <tr className="bg-secondary-teal text-white py-3 px-3">
                      <th className="text-left p-2 text-sm font-normal">
                        Date Created
                      </th>
                      <th className="text-left p-2 text-sm font-normal">
                        Name
                      </th>
                      <th className="text-left p-3 text-sm font-normal">Tag</th>
                      <th className="text-left p-2 text-sm font-normal">
                        {/* remove tag */}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {props.premiumAssessments &&
                      props.premiumAssessments.map((assessment, index) => (
                        <Fragment key={index}>
                          <tr
                            className={`hover:bg-secondary-teal/20 transition ease-in-out duration-300 ${
                              index % 2 === 0
                                ? "bg-white"
                                : "bg-secondary-teal/10"
                            }`}
                          >
                            <td className="text-sm p-2">
                              {Moment(assessment.createdDate).format(
                                "MM-DD-yyyy"
                              )}
                            </td>
                            <td className="text-sm p-2">
                              {getAssessmentName(assessment)}
                            </td>

                            <td
                              className="text-sm mt-5 p-3 cursor-pointer relative"
                              onMouseOver={() => {
                                if (assessment.tags) {
                                  setShowTags(true);
                                  setAssessmentToEdit({
                                    ...assessmentToEdit,
                                    premiumAssessment: assessment,
                                  });
                                }
                              }}
                              onMouseOut={() => setShowTags(false)}
                            >
                              <div className="flex gap-1">
                                <span className="mt-1">
                                  <TagOutlineIcon className="w-4 text-gray-500 hover:text-gray-700 cursor-pointer m-auto" />
                                  <div
                                    className={`absolute bg-blue-50 top-10 right-0 transition-all shadow-lg border border-blue-200 p-4 rounded-lg z-50
                                ${
                                  showTags &&
                                  assessment.cannedTestId ===
                                    assessmentToEdit.premiumAssessment
                                      ?.cannedTestId
                                    ? "visible"
                                    : "hidden"
                                }
                                `}
                                    onMouseOver={() => {
                                      setShowTags(true);
                                      setAssessmentToEdit({
                                        ...assessmentToEdit,
                                        premiumAssessment: assessment,
                                      });
                                    }}
                                    onMouseOut={() => setShowTags(false)}
                                  >
                                    <div className="flex gap-1 flex-wrap bg-white/50 p-1">
                                      {assessment.tags &&
                                        JSON.parse(assessment.tags).map(
                                          (tag) => (
                                            <span
                                              key={index}
                                              className={`inline-block rounded-full text-xs shadow-sm py-0.5 px-2 overflow-hidden truncate flex 
                                             ${
                                               TagColor.isDark(tag.Color)
                                                 ? "text-white"
                                                 : ""
                                             } bg-[${tag.Color}]`}
                                            >
                                              {tag.Name}
                                            </span>
                                          )
                                        )}
                                    </div>
                                  </div>
                                </span>
                                <span className="text-blue-500">
                                  {assessment.tags === null
                                    ? 0
                                    : JSON.parse(assessment.tags).length}
                                </span>
                              </div>
                            </td>
                            <td className="text-sm p-2">
                              <CancelIcon
                                onClick={() =>
                                  props.removeAssesmentSelection(
                                    assessment.cannedTestId
                                  )
                                }
                                className="w-4 text-xs font-light cursor-pointer text-gray-500 hover:text-red-500 transaction ease-in-out duration-300"
                              >
                                Remove
                              </CancelIcon>
                            </td>
                          </tr>
                        </Fragment>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          {/* <!-- Action Buttons -->  */}
          <footer className="flex flex-col items-center justify-end px-6 py-4 sm:flex-row bg-gray-50 dark:bg-gray-800">
            <div className="flex-shrink-0">
              <div className="space-x-3 flex justify-end">
                <button
                  className="align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 rounded-lg text-sm text-gray-600 border-gray-300 border dark:text-gray-400 focus:outline-none active:bg-transparent hover:border-gray-500 focus:border-gray-500 active:text-gray-500 focus:shadow-outline-gray sm:w-auto"
                  type="button"
                  onClick={() => {
                    props.showPopup(false);
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </Fragment>
  );
}

export default ManagePremiumAssessmentTag;

import classNames from "classnames";
import { Fragment, useEffect, useState } from "react";
import { MathIcon } from "../../../../assets/icons";
import { getSkillSubject } from "../../../../api/student/skillPracticeApi"
import { ISkillSubject } from "../../../../model/skillPractice/skillSubject";
import Loader from "../../../shared/loader";

interface SkillSubjectProps {
    onSubjectSelected: (skillSubjectId: number) => void
}

function SkillSubject(props: SkillSubjectProps) {
    const [skillSubjects, setSkillSubjects] = useState<Array<ISkillSubject>>([]);
    const [selectedSubject, setSelectedSubject] = useState<number>(0);
    const [loading, showLoading] = useState<boolean>(false);

    useEffect(() => {
        showLoading(true);
        getSkillSubject().then(d => {
            setSkillSubjects(d.data.data);
            showLoading(false);
            setSelectedSubject(d.data.data[0].skillSubjectId);
            props.onSubjectSelected(d.data.data[0].skillSubjectId);
        }).catch(() => {
            showLoading(false);
        });
    }, [])

    return <Fragment>
        <div>
            <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3 ml-5 max-h-44 overflow-y-auto">
                {loading && <Loader />}
                {skillSubjects.map((item) => (
                    <div
                        key={item.skillSubjectId}
                        className={classNames("relative bg-white pt-5 px-4 pb-12 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden border cursor-pointer", selectedSubject === item.skillSubjectId ? "border-black" : "")}
                        onClick={() => { setSelectedSubject(item.skillSubjectId); props.onSubjectSelected(item.skillSubjectId); }}
                    >
                        <dt>
                            <div className="absolute bg-indigo-500 rounded-md p-3">
                                <MathIcon className="h-6 w-6 text-white" aria-hidden="true" />
                            </div>
                            <p className="ml-16 text-sm font-medium text-gray-500 truncate">{item.name}</p>
                        </dt>
                    </div>
                ))}
            </dl>
        </div>
    </Fragment>
}

export default SkillSubject;
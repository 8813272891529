class RouteConstant {
  public readonly ROUTE_DEFAULT = "/";
  public readonly ROUTE_DASHBOARD = "/dashboard";
  public readonly ROUTE_SCHOOL_SELECTION =
    "/schoolselection/:linkedUserId/:loginType";

  public readonly ROUTE_PLAYGROUND = "/playground";
  public readonly ROUTE_LOGIN = "/login";

  public readonly ROUTE_FORGOTPASSWORD = "/forgotPassword";
  public readonly ROUTE_LOGOUT = "/logout";
  public readonly BLANK = "/blank";
  public readonly FORBIDDEN = "/forbidden";
  public readonly EXPIRED = "/expired";
  public readonly BAD_REQUEST = "/bad-request";

  public readonly SIGNIN = "/signIn";
  public readonly CLASSLINKLOGIN = "/classLink";
  public readonly CLEVERLOGIN = "/clever";
  public readonly GWINNETTSSOLOGIN = "/sso/gwinnett";
  public readonly GOOGLEUSERLOGIN = "/googleUserLogin";
  public readonly EXTERNALGOOGLELOGIN = "/externalgoogleLogin";
  public readonly GOOGLESTUDENTLOGIN = "/googleStudentLogin";
  public readonly GOOGLETEACHERLOGIN = "/googleTeacherLogin";
  public readonly GOOGLEUSERMAPPING = "/googleUserMapping/:gmailId/:googleId";
  public readonly GOOGLEMAPPING = "/googleUserMapping";
  public readonly GOOGLEMAP = "/googleMap";
  public readonly GOOGLE_UNAUTHORIZED = "/google/unauthorized";
  public readonly FORGOTPASSWORD = "/forgotPassword";
  public readonly CHANGEPASSWORD = "/changePassword/:recoveryCode";
  public readonly USERREGISTRATION = "/userRegistration/:recoveryCode";
  public readonly RECOVERYCODEEXPIRED = "/link/expired";
  public readonly REQUESTSSO = "/requestsso";
  public readonly CANVASLOGIN = "/canvasLogin";
  public readonly CANVASUSERLOGIN = "/canvasUserLogin";
  public readonly EXTERNALCANVASLOGIN = "/externalCanvasLogin";
  public readonly CANVASUSERMAPPING =
    "/canvasUserMapping/:canvasLoginId/:canvasId";
  public readonly CANVASMAPPING = "/canvasUserMapping";

  public readonly CONTAINER = "/container";
  public readonly VIEWASTUDENT = "/viewAsStudent";
  public readonly TEACHERTRAINING = "/teacherTraining";
  public readonly CAMPUSTRAINING = "/campusTraining";
  public readonly ADMINLIST = "/adminList";
  public readonly IMPERSONATE = "/Impersonate";

  public readonly EGSCHOOLPERFORMANCE = "/egschoolperformance";
  public readonly EGPERFORMANCECOMPARISON = "/egperformancecomparison";
  public readonly EGSCHOOLUSAGE = "/egschoolusage";
  public readonly EGDISTRICTPERFORMANCE = "/egdistrictperformance";
  public readonly EGDISTRICTUSAGE = "/egdistrictusage";
  public readonly EGDISTRICTDIAGNOSTIC = "/egdistrictdiagnostic";
  public readonly EGSCHOOLUSAGECOMPARISON = "/egschoolusagecomparison";
  public readonly EGDIAGNOSTICCOMPARISON = "/egdiagnosticcomparison";
  public readonly EGSCHOOLDIAGNOSTIC = "/egschooldiagnostic";

  public readonly CONTACTUS = "/contactUs";
  public readonly PARENTLETTER = "/parentLetter";

  public readonly LFSCHOOLUSAGE = "/lfschoolusage";
  public readonly LFDISTRICTUSAGE = "/lfdistrictusage";
  public readonly LFSCHOOLUSAGECOMPARISON = "/lfschoolusagecomparison";
  public readonly LFSCHOOLPERFORMANCECOMPARISON =
    "/lfschoolperformancecomparison";
  public readonly LFDISTRICTDIAGNOSTIC = "/lfdistrictdiagnostic";
  public readonly LFSCHOOLPERFORMANCE = "/lfschoolperformance";
  public readonly LFSCHOOLDIAGNOSTIC = "/lfschooldiagnostic";
  public readonly LFDISTRICTPERFORMANCE = "/lfdistrictperformance";
  public readonly LFDIAGNOSTICCOMPARISON = "/lfdiagnosticcomparison";

  public readonly EGARCHIVEDUSAGE = "/egarchivedusage";
  public readonly LOARCHIVEDUSAGE = "/loarchivedusage";
  public readonly HELP = "/help";
  public readonly TRAINING = "/training";
  public readonly UserProfile = "/profile";

  public readonly MANAGESTUDENT = "/ManageStudent";
  public readonly SIMPLESIGNON = "/index";
  public readonly SELECTTEACHER = "/selectTeacher";
  public readonly ENTERPASSWORD = "/enterPassword";

  public readonly VIDEOMESSAGEMANAGEMENT = "/VideoMessageManagement";
  public readonly STUDENT = "/student";

  public readonly ASSIGNMENTBANK = "/assignmentBank";
  public readonly NEWASSIGNMENT = "/assignments/new";
  public readonly EDITASSIGNMENT = "/assignments/:id";
  public readonly ASSIGNMENTPRINT = "/teacher/print";

  public readonly MANAGEASSESSMENT = "/manageClass";
  public readonly NEWASSESSMENT = "/assessments/new";
  public readonly EDITASSESSMENT = "/assessments/:id";
  public readonly ASSESSMENTBANK = "/assessmentsBank";
  public readonly ASSESSMENT = "/assessment";
  public readonly ASSESSMENT_RESULT = "/assessmentResult";
  public readonly RESULT_REPORTS = "/resultReports";
  public readonly GROUPED_RESULT_REPORTS = "/reports/resultsReport";

  public readonly CLASSMANAGEMENT = "/classes";
  public readonly CLASSDETAIL = "/classes/:classId";

  public readonly ELEMENTARYGAMESCORE = "/GameScore";
  public readonly LEADERBOARD = "/leaderboard";
  public readonly LEADERBOARDMS = "/middleschool/leaderboard";
  public readonly SUBJECTSTUDY = "/subjectstudy";
  public readonly ARENA_KG = "/kindergarten/Arena";
  public readonly ARENA_MIDDLE_SCHOOL = "/middleSchool/Arena";
  public readonly ARENA_ELE = "/elementary/Arena";
  public readonly MYSTAT = "/elementary/myStat";
  public readonly MYSTATMS = "/middleschool/myStat";
  public readonly ASSIGNMENTS_MS = "/middleschool/assignments";
  public readonly SUBJECTS_MS = "/middleschool/subjects";

  public readonly SKILLPRACTICEGAMECHOOSE =
    "/skillpractice/gamechoose/:standardId";
  public readonly SKILLPRACTICETEST = "/skillpracticetest";
  public readonly STUDENTASSESSMENT = "/studentassessment";
  public readonly HIGHSCHOOLHOME = "/HSHome";

  // public readonly STUDENTROFILE = "/studentProfile";
  public readonly ALIEN_ARENA = "/teacher/alienArena/new";
  public readonly ALIEN_ARENA_DETAIL =
    "/teacher/alienArena/alienArenaDetails/:alienArenaId";
  public readonly ALIEN_ARENA_SESSIONS = "/teacher/alienArena/sessions";
  public readonly SUPERSHEET = "/supersheet";
  public readonly NEWBUBBLESHEET = "/bubblesheet";
  public readonly Chat = "/chat";

  public readonly QUESTIONBUILDER = "/questionbuilder";
  public readonly QUESTIONBUILDERCARD = "/questionbuildercard/new";
  public readonly AUTHOREDQUESTIONS = "/authoredQuestions";
  public readonly EDITQUESTION = "/questionbuilder/edit/:id";

  public readonly ELEMENTARYRESULT = "/elhome/result";
  public readonly MIDDLESCHOOLRESULT = "/middleschool/result";
  public readonly RESULTS = "/results";

  public readonly EGREPORT = "/egreport";
  public readonly LFREPORT = "/lfreport";
  public readonly LOREPORT = "/loreport";
  public readonly REPORTS = "/reports";
  public readonly PROGRESS_REPORT = "/progressReport";
  public readonly ASSESSMENT_LIVE_MONITORING = "/livemonitoringReport";
  public readonly USAGE_REPORT = "/usageReport";
  public readonly CLASSROOM_GAME = "/classroomGame";

  public readonly SESSION_ACTIVITY_REPORT = "/sessionActivityReport";
  public readonly GALAXY_START_REPORT = "/galaxyStarReport";
  public readonly LIFTOFF_GALAXY_START_REPORT = "/liftoffGalaxyStarReport";
  public readonly SESSION_ACTIVITY_ASSIGNMENTRESULT =
    "/sessionActivityReport/assignmentResult/:studentId/:assignmentId/:assignmentActivityId";
  public readonly SESSION_ACTIVITY_DIAGNOSTICRESULT =
    "/sessionActivityReport/diagnosticResult/:studentId/:sessionId";
  public readonly SESSION_ACTIVITY_STUDYPLANRESULT =
    "/sessionActivityReport/studyplanResult/:studentId/:gradeLevel/:sessionId";
  public readonly SESSION_ACTIVITY_LIFTOFFDIAGNOSTICRESULT =
    "/sessionActivityReport/liftoffdiagResult/:studentId/:liftOffHeaderId";
  public readonly SESSION_ACTIVITY_MYGALAXYRESULT =
    "/sessionActivityReport/mygalaxyResult/:studentId/:standardId";
  public readonly STUDENT_DIAGNOSTICS_STRENGTH_WEAKNESS_REPORT =
    "/strengthWeaknessReport";

  public readonly TeacherRoutes = {
    Classes: "/class-list",
    AlienArena: "/teacher/alienArena/new",
    AlienArenaDetails: "/teacher/alienArena/alienArenaDetails/:alienArenaId",
    AlienArenaSessions: "/teacher/alienArena/sessions",
    ClassesDetails: "/class-details/:classId",
    NewClass: "/classes/new",
    GradeBook: "/teacher/gradebook",
    LiftoffDiagnosticReport: "/reports/liftoffDiagnosticReport",
    LiftoffGrowthReport: "/reports/liftoffGrowthReport",
    LiftoffDetailGrowthReport: "/reports/liftoffDetailGrowthReport",
    LiftoffUsageReport: "/reports/liftoffUsageReport",
    liftoffUsageStudentLevelByClassReport:
      "/reports/liftoffUsageStudentLevelReport/schools/:schoolId/classes/:classId/subjects/:subjectId",
    liftoffUsageStudentLevelByGradeReport:
      "/reports/liftoffUsageStudentLevelReport/schools/:schoolId/grades/:gradeId/subjects/:subjectId",
    LiftoffIndividualDiagnosticReport: "/reports/liftoffIndividualDiagnostic",
    LiftoffStandardDiagnosticReport: "/reports/liftoffStandardDignostic",
    StudentActivitySearchReport: "/reports/studentActivitySearchReport",
    TeacherUsageReport: "/reports/teacherUsageReport",
    DiagnosticIndividualStudentReport:
      "/reports/DiagnosticIndividualStudentReport",
    LiftoffGalaxySummaryReport: "/reports/LiftoffGalaxySummaryReport",
    LiftoffClassGalaxySummaryReport: "/reports/liftoffClassGalaxySummaryReport",
    ProgressReport: "/teacher/progressreport/:classId",
    ProgressReportStudent: "/progressreport/:subjectId/students/:studentUserId",
    ProgressReportHome: "/progressreport",
    LiveMonitoringReportHome: "/livemonitoringreport",
    AssessmentResultsReport: "/reports/assessmentResultsReport",
    Tags: "/teacher/tags",
    InstructionalResources: "/teacher/instructionalResources",
    StudyPlanSubjects: "/teacher/instructionalResources/subjects",
    SuperSheets: "/teacher/superSheets",
    BellRinger: "/teacher/bellRinger",
    BellRingerNew: "/teacher/bellRinger/new",
    BellRingerEdit: "/teacher/bellRinger/:id",
    PreviewQuestion: "/previewQuestion/:id",
    ClassActivity: "/teacher/classActivies",
    StudentActivities: "/teacher/student-activities",
    InstructionalResourceSubjects: "/instructionalResources/subjects",
    manageUsers: "/manageUsers",
    assignmentCenter: "/assignmentCenter",
    manageUser: "/manageUser",
    crReport: "/results/crReport",
    gradeCRQuestion: "/results/gradeCRQuestion",
    UserLiftOffManagement: "/manageLiftoff",
    nweaMapping: "/nwea",
    StudentAssignmentResult:
      "/teacher/student/assignmentResult/:userId/:assignmentId/:assignmentActivityId/:gradeId/:sessionId?",
    StudentPracticeResult:
      "/teacher/student/practiceResult/:userId/:sessionId/:gradeId/:activityType",
    StudentAssignmentResultBySearchActivity:
      "/teacher/student/activitysearch/assignmentResult/:userId/:assignmentId/:assignmentActivityId/:gradeId/:sessionId?",
    StudentPracticeResultBySearchActivity:
      "/teacher/student/activitysearch/practiceResult/:userId/:sessionId/:gradeId/:activityType",
    StudentLiftoffDiagnosticResultBySearchActivity:
      "/teacher/student/activitysearch/liftoffDiagnosticResult/:liftOffHeaderId/:sessionId",
    StudentMyGalaxyStandardResultBySearchActivity:
      "/teacher/student/activitysearch/myGalaxyStandardResult/:standardId/:isStandardGrouped/:sessionId",
    StudentLiftoffDiagnosticResultActivity:
      "/teacher/student/liftoffactivityreport/liftoffDiagnosticResult/:liftOffHeaderId/:sessionId",
    StudentMyGalaxyStandardResultActivity:
      "/teacher/student/liftoffactivityreport/myGalaxyStandardResult/:standardId/:isStandardGrouped/:sessionId",
    StudentAssignmentActivityResult:
      "/teacher/student/:source/assignmentResult/:userId/:assignmentId/:assignmentActivityId/:gradeId/:sessionId?",
    StudentPracticeActivityResult:
      "/teacher/student/:source/practiceResult/:userId/:sessionId/:gradeId/:activityType",
    StudentLiftOffDiagnosticResult:
      "/teacher/student/:source/liftoffDiagnosticResult/:liftOffHeaderId/:sessionId",
    StudentMyGalaxyStandardResult:
      "/teacher/student/:source/myGalaxyStandardResult/:standardId/:isStandardGrouped/:sessionId",

    StudentStandardEfficiencyReport:
      "/teacher/student/standardefficiencyreport/:userId/:gradeId/:sessionId",

    LaunchAlienArena: "/teacher/launchSession",
    ArenaStartSession: "/teacher/arenaStartSession",
    AlienArenaresults: "/teacher/alienArenaResults",
    AlienFinalResult: "/teacher/alienFinalResult",
    ImportCanvasClasses: "/teacher/importCanvasClasses",

    LiftoffActivityReport: "/reports/liftoffActivityReport",
    TestComparisonReport: "/reports/testComparisonReport",
    InstructionalResourceVideos: "/teacher/instructionalResources/videos",
    AssessmentComparisonReport: "/reports/assessmentComparison",
    StudentReportCard: "/reports/studentReportCard",
    LearnosityItemAnalysis: "/teacher/reports/LearnosityItemAnalysis",
    LearnosityLiveMonitoring: "/teacher/reports/LearnosityLiveMonitoring",
    StudentSetting: "/teacher/studentSetting",
    gradeAssesmentWithCR: "/results/gradeAssesmentWithCR",
    ResultsReport : "/reports/resultsReport",
  };

  public readonly LiftOffRoutes = {
    ELEMENTARYHOME: "/elementary/liftoff/home",
    KINDERGARDENHOME: "/kindergarten/liftoff/home",
    ELEMENTARYDIAGNOSTICRESULT:
      "/elementary/liftoff/diagnostic/result/:liftOffHeaderId",
    KINDERGARDENDIAGNOSTICRESULT:
      "/kindergarten/liftoff/diagnostic/result/:liftOffHeaderId",
    ELEMENTARYMYGLAXYRESULT: "/elementary/liftoff/mygalaxy/result/:standardId",
    KINDERGARDENMYGLAXYRESULT:
      "/kindergarten/liftoff/mygalaxy/result/:standardId",
    MiddleSchoolHOME: "/middleschool/liftoff/assignments",
    MiddleSchoolDiagnostic: "/middleschool/liftoff/diagnostics",
    MiddleSchoolMyGalaxySubject: "/middleschool/liftoff/mygalaxy/subject",
    MiddleSchoolMyGalaxy: "/middleschool/liftoff/mygalaxy/subject/:id",
  };

  public readonly KindergartenStudentRoutes = {
    Home: "/kindergarten/home",
    SubjectStudy:
      "/kindergarten/practice/:subjectId/:subjectName/:contentAreaId",
    Assignment: "/kindergarten/assignment",
    GradedWork: "/kindergarten/gradedWork",
    Result: "/kindergarten/results",
    Arena: "/kindergarten/arena",
    SkillPractice: "/kindergarten/skillpractice",
    SkillGameChoose: "/kindergarten/skillpractice/setup/:standardId",
    SkillPracticeTest:
      "/kindergarten/skillpractice/test/:skillStandardId/:skillStudentAssignmentId",
    SkillPracticeSessionSummary:
      "/kindergarten/skillpractice/sessionSummary/:skillStandardId/:skillStudentAssignmentId",
    StudyPlan:
      "/kindergarten/studyplan/:standardId/:standardName/:gameId/:rocketId/:blasterId/:subjectId/:blasterType/:isRecommendedStandard/:param",
    StudyPlanSummary:
      "/kindergarten/studyplan/summary/:standardId/:standardName",
    VideoQuestion: "/kindergarten/video/:standardId/:standardName",
    StudyPlanResult: "/kindergarten/studyplan/result/:sessionId",
    VideoResult: "/kindergarten/video/result/:sessionId",
    VideoQuestionList:
      "/kindergarten/video/:standardId/:standardName/:subjectId",
    VideoQuestionPractice:
      "/kindergarten/videoPractice/:standardId/:standardName/:videoId/:subjectId",
    LiftOffMyGalaxy:
      "/kindergarten/liftoff/mygalaxy/:standardId/:standardName/:gameId/:rocketId/:blasterId/:blasterType",
    GameChoose:
      "/kindergarten/gameChoose/:standardId/:standardName/:mode/:subjectId/:isRecommendedStandard",
    CannedTest: "/kindergarten/cannedtest/:cannedTestId/:subjectId",
    StandardEfficiencyReport:
      "/kindergarten/standardefficiencyreport/:sessionId",
    DiagnosticsTestResult: "/kindergarten/result/:sessionId",
    StudentAssignmentActivity:
      "/kindergarten/assignment/activity/:assignmentActivityId/:assignmentActivityName/:lernosityActivityIdEN/:lernosityActivityIdES/:assignmentType/:assignmentId/:subjectId/:multipleAttemptAllowed",
    AssignmentResult:
      "/kindergarten/assignment/result/:assignmentId/:assignmentActivityId/:runningAssignment/:sessionId?",
    StudentAssessmentActivity:
      "/kindergarten/assessment/activity/:code/:multipleAttemptAllowed/:subjectId",
    ProgressReport: "/kindergarten/progressreport/:subjectId",
    Profile: "/kindergarten/profile",
    StudentRetryAssignmentActivityK1:
      "/kindergarten/assignment/activity/retry/:sessionId",
  };

  public readonly StudentRoute = {
    Home: "/elementary/home",
    SkillPractice: "/student/skillpractice",
    SkillGameChoose: "/student/skillpractice/setup/:standardId",
    SkillPracticeTest:
      "/student/skillpractice/test/:skillStandardId/:skillStudentAssignmentId",
    SkillPracticeSessionSummary:
      "/student/skillpractice/sessionSummary/:skillStandardId/:skillStudentAssignmentId",
    ActivityKG: "/student/kindergarten/activityKG",
    ActivityEL: "/student/elementary/activityEL",
    ActivityMS: "/student/middleSchool/activityMS",
    GameChoose:
      "/student/gameChoose/:standardId/:standardName/:mode/:subjectId/:isRecommendedStandard",
    StudyPlan:
      "/student/elementary/studyplan/:standardId/:standardName/:gameId/:rocketId/:blasterId/:subjectId/:blasterType/:isRecommendedStandard",
    StudyPlanResult: "/student/elementary/studyplan/result/:sessionId",
    VideoResult: "/student/elementary/video/result/:sessionId",
    CannedTest: "/student/elementary/cannedtest/:cannedTestId/:subjectId",
    LiftOffDiagnostic:
      "/student/elementary/liftoff/diagnostic/:activityId/:activityStatus/:activityHeader/:activityHeaderId/:activitySessionId",
    LiftOffMyGalaxy:
      "/student/elementary/liftoff/mygalaxy/:standardId/:standardName/:gameId/:rocketId/:blasterId/:blasterType",

    StudyPlanSummary: "/elementary/studyplan/summary/:standardId/:standardName",
    StandardEfficiencyReport: "/elementary/standardefficiencyreport/:sessionId",
    StudentAssignmentActivityEL:
      "/student/elementary/assignment/activity/:assignmentActivityId/:assignmentActivityName/:lernosityActivityIdEN/:lernosityActivityIdES/:assignmentType/:assignmentId/:subjectId/:multipleAttemptAllowed",
    VideoQuestionList: "/elementary/video/:standardId/:standardName/:subjectId",
    VideoQuestionPractice:
      "/elementary/videoPractice/:standardId/:standardName/:videoId/:subjectId",
    DiagnosticsTestResult: "/elementary/result/:sessionId",
    AssignmentResult:
      "/elementary/assignment/result/:assignmentId/:assignmentActivityId/:runningAssignment/:sessionId?",
    StudentAssessmentActivity:
      "/student/elementary/assessment/activity/:code/:multipleAttemptAllowed/:subjectId",
    ProgressReport: "/elementary/progressreport/:subjectId",
    StudentAlienArena: "/elementary/alienarenadetails",
    StudentAlienArenaPopUp: "/elementary/alienarenapopup",
    StudentAlienArenaFinalResult: "/elementary/alienArenaFinal",
    StudentAlienArenaResults: "/elementary/alienArenaResults",
    StudentAlienArenaStartSession: "/elementary/alienarenastartsession",
    StudyPlanPracticeSection:
      "/elementary/studyPlan/practice-section/:subjectId",
    SummativeTest: "/elementary/summative-test/:subjectId/:testSize",
    DomainTest: "/elementary/domain-test/:domainId/:subjectId",
    CannedTestGC:
      "/elementary/canned-test/:cannedTestId/:subjectId/:learnosityReferenceId",
    SummativeTestResult: "/elementary/summativeTest/result/:sessionId",
    DomainTestResult: "/elementary/domainTest/result/:sessionId",
    cannedTestResult: "/elementary/cannedTest/result/:sessionId",
    Profile: "/elementary/profile",
    StudentRetryAssignmentActivityEL:
      "/student/elementary/assignment/activity/retry/:sessionId",
    StudentLiftOffDiagnosticResultByStudent:
      "/:studentType/:source/liftoffDiagnosticResult/:liftOffHeaderId/:sessionId",
    StudentMyGalaxyStandardResultByStudent:
      "/:studentType/:source/myGalaxyStandardResult/:standardId/:isStandardGrouped/:sessionId",
    ELStudentLiftoffDiagnosticResultByGradedWork:
      "/elementary/gradedwork/liftoffDiagnosticResult/:liftOffHeaderId/:sessionId",
    ELStudentMyGalaxyStandardResultByGradedWork:
      "/elementary/gradedwork/myGalaxyStandardResult/:standardId/:isStandardGrouped/:sessionId",
  };

  public readonly MiddleSchool = {
    Home: "/middleschool/assignments",
    StudyPlan:
      "/middleSchool/studyPlan/:standardId/:standardName/:gameId/:rocketId/:blasterId/:subjectId/:blasterType",
    SummativeTest: "/middleSchool/summative-test/:subjectId/:testSize",
    DomainTest: "/middleSchool/domain-test/:domainId/:subjectId",
    CannedTestGC:
      "/middleSchool/canned-test/:cannedTestId/:subjectId/:learnosityReferenceId",
    StudyPlanResult: "/middleSchool/middleSchool/studyPlan/result/:sessionId",
    VideoResult: "/middleSchool/middleSchool/video/result/:sessionId",
    SummativeTestResult: "/middleSchool/summativeTest/result/:sessionId",
    DomainTestResult: "/middleSchool/domainTest/result/:sessionId",
    cannedTestResult: "/middleSchool/canned-Test/result/:sessionId",
    GameChooseMS:
      "/middleSchool/gameChoose/:standardId/:subjectId/:standardName/:mode",
    TrohpyRoom: "/middleSchool/trophyRoom",
    CannedTest: "/middleschool/cannedtest/:cannedTestId/:subjectId",
    CannedTestSubjects: "/middleschool/diagnosticSubjects",
    StandardEfficiencyReport:
      "/middleschool/standardefficiencyreport/:sessionId",
    StudyPlanSummary:
      "/middleSchool/studyplan/summary/:standardId/:standardName",
    StudyPlanStandards: "/middleschool/standards/:id",
    VideoQuestionList:
      "/middleSchool/video/:standardId/:standardName/:subjectId",
    VideoQuestionPractice:
      "/middleSchool/videoPractice/:subjectId/:standardId/:standardName/:videoId",
    GameChoose:
      "/middleSchool/gameChoose/:standardId/:standardName/:mode/:subjectId",
    home: "/middleschool/assignments",
    assignments: "/middleschool/assignments",
    gradedWork: "/middleschool/gradedWork",
    subjects: "/middleschool/subjects",
    standards: "/middleschool/standards/:id",
    DiagnosticsTestResult: "/middleschool/result/:sessionId",
    CannedTestPractice: "/middleschool/diagnostics/:subjectId",
    recommendedTopics: "/middleschool/recommendedTopics",

    StudyPlanPracticeSection:
      "/middleschool/studyPlan/practice-section/:subjectId",
    LiftOffDiagnostic:
      "/middleschool/liftoff/diagnostic/:activityId/:activityStatus/:activityHeader/:activityHeaderId/:activitySessionId",
    LiftOffMyGalaxy:
      "/middleschool/liftoff/mygalaxy/:standardId/:standardName/:gameId/:rocketId/:blasterId/:blasterType",
    LiftOffGameChooseMS:
      "/middleSchool/gameChoose/:standardId/:standardName/:mode",
    StudentAssignmentActivityMS:
      "/middleSchool/assignment/activity/:assignmentActivityId/:assignmentActivityName/:lernosityActivityIdEN/:lernosityActivityIdES/:assignmentType/:assignmentId/:subjectId/:multipleAttemptAllowed",
    AssignmentResult:
      "/middleSchool/assignment/result/:assignmentId/:assignmentActivityId/:runningAssignment/:sessionId?",
    StudentAssessmentActivity:
      "/middleSchool/assessment/activity/:code/:multipleAttemptAllowed/:subjectId",
    SkillPracticeSubjects: "/middleSchool/skillpracticesubjects",
    SkillGameChoose: "/middleSchool/skillpractice/setup/:standardId",
    SkillPracticeTest:
      "/middleSchool/skillpractice/test/:skillStandardId/:skillStudentAssignmentId",
    SkillPracticeSessionSummary:
      "/middleSchool/skillpractice/sessionSummary/:skillStandardId/:skillStudentAssignmentId",
    ProgressReport: "/middleSchool/progressreport/:subjectId",
    Profile: "/middleSchool/profile",
    StudentRetryAssignmentActivityMS:
      "/middleSchool/assignment/activity/retry/:sessionId",
    Arcade: "/middleSchool/arcade",
    MSStudentLiftoffDiagnosticResultByGradedWork:
      "/middleschool/gradedwork/liftoffDiagnosticResult/:liftOffHeaderId/:sessionId",
    MSStudentMyGalaxyStandardResultByGradedWork:
      "/middleschool/gradedwork/myGalaxyStandardResult/:standardId/:isStandardGrouped/:sessionId",
  };

  public readonly LiftOff = {
    Kindergarten: {
      Home: "/kindergarten/liftoff/home",
      DiagnosticActivity:
        "/kindergarten/liftoff/diagnostic/:activityId/:activityStatus/:activityHeader/:activityHeaderId/:activityStatusId/:subjectId/:subjectName",
      MyGalaxyActivity:
        "/kindergarten/liftoff/mygalaxy/:standardId/:standardName/:gameId/:rocketId/:blasterId/:blasterType/:subjectId/:subjectName/:isStandardGrouped",
      DiagnosticResult:
        "/kindergarten/liftoff/diagnostic/result/:liftOffHeaderId",
      MyGalaxyResult:
        "/kindergarten/liftoff/mygalaxy/result/:standardId/:isStandardGrouped",
      GameChoose:
        "/kindergarten/liftoff/gameChoose/:standardId/:standardName/:mode/:subjectId/:subjectName/:isStandardGrouped",
      MyStat: "/kindergarten/liftoff/myStat",
      Leaderboard: "/kindergarten/liftoff/leaderboard",
    },
    Elementary: {
      Home: "/elementary/liftoff/home",
      DiagnosticActivity:
        "/elementary/liftoff/diagnostic/:activityId/:activityStatus/:activityHeader/:activityHeaderId/:activityStatusId/:subjectId/:subjectName",
      MyGalaxyActivity:
        "/elementary/liftoff/mygalaxy/:standardId/:standardName/:gameId/:rocketId/:blasterId/:blasterType/:subjectId/:subjectName/:isStandardGrouped",
      DiagnosticResult:
        "/elementary/liftoff/diagnostic/result/:liftOffHeaderId",
      MyGalaxyResult:
        "/elementary/liftoff/mygalaxy/result/:standardId/:isStandardGrouped",
      GameChoose:
        "/elementary/liftoff/gameChoose/:standardId/:standardName/:mode/:subjectId/:subjectName/:isStandardGrouped",
      MyStat: "/elementary/liftoff/myStat",
      Leaderboard: "/elementary/liftoff/leaderboard",
    },
    MiddleSchool: {
      Home: "/middleschool/liftoff/home",
      DiagnosticActivity:
        "/middleschool/liftoff/diagnostic/:activityId/:activityStatus/:activityHeader/:activityHeaderId/:activityStatusId/:subjectId/:subjectName",
      MyGalaxyActivity:
        "/middleschool/liftoff/mygalaxy/:standardId/:standardName/:gameId/:rocketId/:blasterId/:blasterType/:subjectId/:subjectName/:isStandardGrouped",
      DiagnosticResult:
        "/middleschool/liftoff/diagnostic/result/:liftOffHeaderId",
      MyGalaxyResult:
        "/middleschool/liftoff/mygalaxy/result/:standardId/:isStandardGrouped",
      GameChoose:
        "/middleschool/liftoff/gameChoose/:standardId/:standardName/:mode/:subjectId/:subjectName/:isStandardGrouped",
      MyStat: "/middleschool/liftoff/myStat",
      Leaderboard: "/middleschool/liftoff/leaderboard",
    },
  };
  public readonly Arcade = {
    Home: "/middleSchool/arcade",
  };

  public readonly ClassroomGame = {
    ThinkOrSwim: "/classroomGame/ThinkOrSwim"
  }
}

export default new RouteConstant();

import { Fragment, useState } from "react";
import { Copy, CopyOnIcon } from "../../assets/icons/index";

interface passedProps {
  className?: string;
}

function ClipboardCopy(props: passedProps) {
  const [copiedTextCss, togglecopiedTextCss] = useState("hidden");
  const [showLightIcon, setShowLightIcon] = useState(false);
  function handleMouseClick() {
    setShowLightIcon(true);
    togglecopiedTextCss("block");
    setTimeout(() => {
      setShowLightIcon(false);
      togglecopiedTextCss("hidden");
    }, 2000);
  }

  return (
    <Fragment>
      <div className="">
        {!showLightIcon && (
          <div className="mx-auto">
            <Copy
              className="w-3 stroke-current text-gray-500 hover:text-gray-900 cursor-pointer mx-auto"
              onClick={() => handleMouseClick()}
            ></Copy>
          </div>
        )}

        {showLightIcon && (
          <div className="mx-auto">
            <CopyOnIcon
              className="w-3 cursor-pointer"
              onClick={() => handleMouseClick()}
            ></CopyOnIcon>
          </div>
        )}
      </div>
      <span
        className={`${copiedTextCss} ${
          props.className ? props.className : ""
        } inline-block transition duration-500 ease-in-out absolute bg-black text-white -top-7 -left-4  z-10 rounded-lg rounded-bl-0 border border-black py-1 px-1.5 border-opacity-25 text-xs`}
      >
        Copied
      </span>
    </Fragment>
  );
}

export default ClipboardCopy;

import { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { getAssignmentAssociatedClasses } from "../../../../../api/class/classStudent";
import { StudentAssignmentApi } from "../../../../../api/student/studentAssignmentApi";
import {
  //AssignmentListViewIcon,
  CompleteSavedActivityIcon,
  ConstructedResponseIcon,
  DownArrowIcon,
  GeneratedTestIcon,
  LegendGroupAssignmentIcon,
  LegendQuestionsIcon,
  LegendVideoIcon,
  TakeAssignmentIcon,
} from "../../../../../assets/icons";
import AssignmentListResponse from "../../../../../model/student/shared/assignment/assignmentListResponse";
import AssignmentSearchModel from "../../../../../model/student/shared/assignment/assignmentSearchModel";
import constant from "../../../../../utils/constant/routeConstant";
import Loader from "../../../../shared/loader";
import TakeAssignmentDialog from "../../shared/takeAssignmentDialog";
import AssignmentList from "./assignmentList";
import CompleteSavedActivity from "./completeSavedActivity";
import Breadcrumb from "../../middleSchool/breadcrumb";
import { IBreadcrumb } from "../../../../../model/common/breadcrumb";
import { getStudentActivitiesStatus } from "../../../../../api/student/studentActivityApi";
import ActivityStatusListResponse from "../../../../../model/student/shared/assignment/activityStatusListResponse";
import RouteConstant from "../../../../../utils/constant/routeConstant";
import { useHistory } from "react-router-dom";
import UpgradeButtonControl from "../../../../shared/customButtonControl";
import { redirect } from "../../../../../utils/routeRedirectHelper";
import {IsLiftOff} from "../../../../../utils/helper";

function Assignments(props: any) {
  const { t } = useTranslation();
  const history = useHistory();

  const [isDDLOpened, setIsDDLOpened] = useState<boolean>(false);
  const [ddlName, setDDLName] = useState<string>(t("Assignments.ViewByClass"));
  const [btnChangeValue, setBtnChangeValue] = useState<number>(1);
  const [filterData, setFilterData] = useState<any[] | null>([]);
  const [actvities, setActvities] = useState<Array<ActivityStatusListResponse>>(
    []
  );
  const [assignments, setAssignments] = useState<Array<AssignmentListResponse>>(
    []
  );
  const [openAssignmentDialog, setOpenAssignmentDialog] = useState(false);
  const [showCompleteActivity, setShowCompleteActivity] = useState(false);
  const [loading, showLoading] = useState<boolean>(false);

  useEffect(() => {
    getAssignments();
    getStudentActivities();
  }, []);

  function getStudentActivities() {
    getStudentActivitiesStatus(props.userContext.userId).then((r) => {
      setActvities(r.data);
    });
  }

  const breadcrumbItems = () => {
    const items: Array<IBreadcrumb> = [
      {
        name: t("Breadcrumb.Home"),
        url: constant.MiddleSchool.assignments,
        textColor: "text-white",
      },
      {
        name: t("Breadcrumb.Assignments"),
        textColor: "text-white",
        url: "",
      },
    ];

    return items;
  };

  function getAssignments(classId?: number) {
    showLoading(true);
    const search: AssignmentSearchModel = {
      studentId: props.userContext?.userId ? props.userContext?.userId : 0,
      subjectId: 0,
      pageNumber: 1,
      pageSize: 99999,
      orderByColumn: "",
      sortDir: 0,
      classId: classId ? classId : undefined,
    };
    StudentAssignmentApi.getStudentAssignments(search).then((r) => {
      setAssignments(r.data.data);
      showLoading(false);
    });
  }
  /* const onBtnToggle: any = () => {
    setShowCompleteActivity(false);
  }; */
  const ddlBtnClick = () => {
    setIsDDLOpened(!isDDLOpened);
  };
  const onGridBtnClick: any = async (param) => {
    setBtnChangeValue(param);
    param === 1
      ? setShowCompleteActivity(false)
      : setShowCompleteActivity(true);
  };

  const filterDataOnChange: any = (
    classId: number,
    className: string
  ): void => {
    if (classId === 0) {
      setDDLName(t("Assignments.ViewByClass"));
      setIsDDLOpened(false);
      getAssignments();
    } else {
      setDDLName(className);
      setIsDDLOpened(false);
      getAssignments(classId);
    }
  };
  useEffect(() => {
    window.addEventListener("click", function (e) {
      const ddl = document.getElementById("custom_ddl");

      if (ddl) {
        const ev: any = e.target;
        if (ev) {
          if (!ddl.contains(ev)) {
            setIsDDLOpened(false);
          }
        }
      }
    });
    getAssignmentAssociatedClasses(props.userContext?.userId).then((d) => {
      setFilterData(d.data);
    });
  }, []);

  const onAssignmentDialogCancelClick = () => {
    setOpenAssignmentDialog(false);
  };
  const onAssignmentDialogOkClick = (
    assignmentCode: any,
    subjectId: number
  ) => {
    if (assignmentCode) {
      startAssessmentActivity(assignmentCode, subjectId);
    }
    setOpenAssignmentDialog(false);
  };

  function startAssessmentActivity(code, subjectId) {
    redirect({
      urlTemplate: RouteConstant.MiddleSchool.StudentAssessmentActivity,
      code: code,
      subjectId: subjectId,
      multipleAttemptAllowed: false,
      history: history,
    });
  }

  return (
    <Fragment>
      {loading && <Loader />}
      <TakeAssignmentDialog
        open={openAssignmentDialog}
        onCancelClick={onAssignmentDialogCancelClick}
        onOkClick={onAssignmentDialogOkClick}
      ></TakeAssignmentDialog>

      <>
          <div className="md:flex md:items-center px-4 py-3 lg:px-8 border-b">
            {!IsLiftOff() && (
              <Breadcrumb items={breadcrumbItems()} />
            )}
            {IsLiftOff() && (
              <div className="md:flex md:items-center md:w-[45%] " />
            )}
            <div className="md:w-[55%] text-lg flex justify-between gap-2 h-auto sm:h-10 items-center">
              <h1 className="text-gray-50 font-medium flex justify-start">
                {t("Breadcrumb.Assignments")}
              </h1>
            </div>
          </div>
        <div className="px-4 py-4 lg:px-8">
          <div className="flex flex-col lg:flex-row justify-between mb-2 sm:mb-0 lg:mb-2 xl:mb-0 ">
            <div
              id="custom_ddl"
              className={`ddl bg-white hover:shadow-lg flex justify-center mb-auto py-2 rounded-md relative mr-auto px-3 ${
                showCompleteActivity ? "hidden" : ""
              }`}
              onClick={() => ddlBtnClick()}
            >
              {btnChangeValue === 1 && (
                <button
                  type="button"
                  className="rounded-full flex items-center text-sm focus:outline-none"
                  aria-expanded="false"
                  aria-haspopup="true"
                >
                  <ul>
                    <li className="text-left font-medium text-gray-700 text-sm">
                      {ddlName || t("Assignments.ViewByClass")}
                      <span className="bg-site-pink bg-opacity-10 rounded-full ml-1.5 inline-block w-6 h-6 text-center">
                        <DownArrowIcon className="inline h-5 w-5 text-site-pink" />
                      </span>
                    </li>
                  </ul>
                </button>
              )}
              {isDDLOpened && (
                <div
                  className="left-12 cursor-pointer transition-all origin-top-right absolute top-8 -left-0 mt-2 w-60 z-50 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="user-menu-button"
                  tabIndex={-1}
                >
                  {filterData && filterData.length > 0 && (
                    <div
                      className="px-4 py-2 hide-ddl-text block text-sm text-black-333 font-medium hover:bg-gray-100"
                      key={0}
                      onClick={() => filterDataOnChange(0, "All")}
                    >
                      {"All"}
                    </div>
                  )}

                  {filterData &&
                    filterData.length > 0 &&
                    filterData.map((ds) => {
                      return (
                        <div
                          className="px-4 py-2 hide-ddl-text block text-sm text-black-333 font-medium hover:bg-gray-100"
                          key={ds.id}
                          onClick={() =>
                            filterDataOnChange(
                              ds.classId,
                              ds.className + " - " + ds.lastName
                            )
                          }
                        >
                          {ds.className} - {ds.lastName}
                        </div>
                      );
                    })}

                  {filterData && filterData.length === 0 && (
                    <div className="px-4 py-2 hide-ddl-text block text-sm text-black-333 font-medium hover:bg-gray-100">
                      {t("Assignments.NoRecordsFound")}
                    </div>
                  )}
                </div>
              )}
            </div>

            <div
              className={`${
                showCompleteActivity
                  ? "hidden"
                  : "order-first mb-3 lg:order-last lg:mb-0"
              }`}
            >
              <div className="h-auto py-2 px-3 mb-4 2xl:mb-0 w-auto bg-black bg-opacity-20 rounded-md space-y-2 order-first 2xl:order-last">
                <ul className="text-white grid grid-cols-1 md:grid-flow-col md:grid-cols-2 md:grid-rows-3 lg:grid-cols-2 lg:grid-rows-3 2xl:grid-cols-3 2xl:grid-rows-2 xxxl:grid-cols-3 xxxl:grid-rows-2 gap-2 text-sm">
                  <li className="flex items-center text-xs gap-x-1.5">
                    <div className="w-7 h-7 bg-black bg-opacity-20 flex items-center justify-center rounded-full">
                      <TakeAssignmentIcon className="flex-none w-4 h-4 stroke-current text-white opacity-90 block align-text-bottom" />
                    </div>
                    {t("Assignments.Assessments")}
                  </li>
                  <li className="flex items-center xl:items-start xxxl:items-center text-xs gap-x-1.5">
                    <div className="w-7 h-7 bg-black bg-opacity-20 flex items-center justify-center rounded-full">
                      <ConstructedResponseIcon className="flex-none w-4 h-4 fill-current text-white block align-text-top" />
                    </div>
                    {t("Assignments.ConstructedResponse")}
                  </li>
                  <li className="flex items-center xl:items-start 2xl:items-center text-xs gap-x-1.5">
                    <div className="w-7 h-7 bg-black bg-opacity-20 flex items-center justify-center rounded-full">
                      <GeneratedTestIcon className="flex-none w-5 h-4 inline-block align-text-bottom" />
                    </div>
                    {t("Assignments.GeneratedTest")}
                  </li>
                  <li className="flex items-center  2xl:items-start xxxl:items-center text-xs gap-x-1.5">
                    <div className="w-7 h-7 bg-black bg-opacity-20 flex items-center justify-center rounded-full">
                      <LegendVideoIcon className="flex-none w-5 h-5 stroke-current text-white opacity-80 block align-text-top" />
                    </div>
                    {t("Assignments.Video")}
                  </li>
                  <li className="flex items-center  xl:items-start 2xl:items-center text-xs gap-x-1.5">
                    <div className="w-7 h-7 bg-black bg-opacity-20 flex items-center justify-center rounded-full">
                      <LegendQuestionsIcon className="flex-none w-5 h-5 fill-current text-white opacity-90 block align-text-top" />
                    </div>
                    {t("Assignments.Questions")}
                  </li>
                  <li className="flex items-center  xl:items-start xxxl:items-center xxxl:items-center text-xs gap-x-1.5">
                    <div className="w-7 h-7 bg-black bg-opacity-20 flex items-center justify-center rounded-full">
                      <LegendGroupAssignmentIcon className="flex-none w-4 h-4 stroke-current text-white block align-text-top" />
                    </div>
                    {t("Assignments.GroupAssignments")}
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="flex flex-col items-start sm:flex-row sm:items-end w-full ">
            <div className="flex order-last sm:order-none gap-x-1">
              <div
                onClick={() => onGridBtnClick(1)}
                className={`cursor-pointer rounded-t-md p-3 text-gray-800 font-medium text-sm flex  md:inline-flex justify-between items-center ${
                  btnChangeValue === 1
                    ? "bg-white border-t-[3px] border-secondary-teal tab-box-shadow"
                    : "bg-white bg-opacity-40 border-t-[3px] border-transparent mt-auto py-2 px-3 hover:py-3 transition-all duration-300 ease-in-out group "
                }`}
              >
                <span
                  className={` ${
                    btnChangeValue === 1
                      ? "text-gray-700"
                      : "text-gray-700 text-opacity-60 group-hover:text-opacity-80"
                  } underline cursor-pointer no-underline text-sm`}
                >
                  {t("Assignments.ViewAllAssignments")}
                </span>
              </div>
              <div
                className={`p-3 text-sm rounded-t-md flex font-medium  sm:inline-flex justify-start md:justify-center items-start 2xl:items-center cursor-pointer false ${
                  btnChangeValue === 2
                    ? "bg-white border-t-[3px] border-secondary-teal tab-box-shadow "
                    : "bg-white bg-opacity-40 border-t-[3px] border-transparent mt-auto py-2 px-3 hover:py-3 transition-all duration-300 ease-in-out group"
                }`}
              >
                <span className="relative">
                  <CompleteSavedActivityIcon className="w-6 h-6 fill-current text-gray-500" />
                </span>

                <span
                  onClick={() => {
                    onGridBtnClick(2);
                  }}
                  className={` ${
                    btnChangeValue === 2
                      ? "text-gray-700"
                      : "text-gray-700 text-opacity-60 group-hover:text-opacity-80"
                  } underline cursor-pointer pl-2  no-underline text-sm`}
                >
                  {t("Assignments.CompleteASavedActivity")}
                </span>
              </div>
            </div>
            {showCompleteActivity && (
              <h2 className="text-sm text-white font-small mb-2 px-3 flex ">
                {t("Assignments.CompletedAssignmentNote")}
              </h2>
            )}
            <div
              className={
                "flex items-center sm:ml-auto " +
                (props.profile?.isUpgradeRequired
                  ? "cursor-not-allowed "
                  : "cursor-pointer ")
              }
            >
              <span className="inline-flex items-center sm:items-start 2xl:items-center order-first text-gray-700 group bg-white bg-opacity-90 hover:shadow-lg float-right px-3 py-2.5 rounded-md mb-3 mt-3 whitespace-nowrap">
                {" "}
                <UpgradeButtonControl
                  controlType={"a"}
                  userContext={props.userContext}
                  isUpgradeRequired={props.profile?.isUpgradeRequired}
                  isRoleCheck={true}
                  className={
                    "inline-flex items-center flex text-gray-700 text-sm  cursor-pointer hover:text-gray-800 " +
                    (props.profile?.isUpgradeRequired
                      ? "cursor-not-allowed "
                      : "")
                  }
                  onClick={() => {
                    setOpenAssignmentDialog(true);
                  }}
                >
                  <span className="bg-site-pink bg-opacity-10 w-6 h-6 rounded-md flex items-center justify-center relative mr-2 ">
                    <TakeAssignmentIcon className="w-4 h-4 stroke-current hover:shadow text-site-pink " />
                  </span>
                  {t("Assignments.TakeAnAssessment")}
                </UpgradeButtonControl>
              </span>
            </div>
          </div>

          {showCompleteActivity ? (
            <>
              <CompleteSavedActivity
                activityData={actvities}
                userId={props.userContext.userId}
                plLanguageId={(props.profile?.plLanguageId ?? 1) === 2 ? 2 : 1}
              />
            </>
          ) : (
            <AssignmentList
              key={ddlName}
              assignmentData={assignments}
              userId={props.userContext.userId}
            />
          )}
        </div>
      </>
    </Fragment>
  );
}

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(Assignments);

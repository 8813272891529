import { CanvasConfiguration } from "../environment/setup";
import { ICanvasAccountSettingDTO } from "../model/canvasClassroom/canvasAccountSettingDTO";
import {
  ICanvasLoginState,
  ICredentials,
} from "../model/canvasClassroom/canvasloginState";
import { Buffer } from "buffer";

export function handleCanvasAuthentication(
  settings: ICanvasAccountSettingDTO,
  accountLoginName: string,
  returnUrl: string
) {
  const state: ICanvasLoginState = {
    AssignmentId: 0,
    AssignmentActivityId: 0,
    ReturnUrl: returnUrl,
    AccountLoginName: accountLoginName,
    ClientId: settings.clientId,
    EndPointURL: settings.endPointURL,
    DefaultToken: settings.defaultToken,
    AdminAccountId: settings.adminAccountId,
    Credentials: null,
  };
  const signInUrl =
    settings.endPointURL +
    CanvasConfiguration.CanvasAuthUrl.replace(
      "{:redirectUrl}",
      CanvasConfiguration.ReturnUrl
    )
      .replace("{:clientId}", settings.clientId)
      .replace(
        "{:state}",
        encodeURI(Buffer.from(JSON.stringify(state)).toString("base64"))
      )
      .replace("{:scope}", CanvasConfiguration.Scopes);

  window.location.href = signInUrl;
}

export function handleCanvasAuthenticationWithCredentials(
  settings: ICanvasAccountSettingDTO,
  returnUrl: string,
  accountLoginName: string,
  username: string,
  passwordHash: string
) {
  const credential: ICredentials = {
    AccountLoginName: accountLoginName,
    Username: username,
    PassowrdHash: passwordHash,
  };
  const state: ICanvasLoginState = {
    AssignmentId: 0,
    AssignmentActivityId: 0,
    ReturnUrl: returnUrl,
    AccountLoginName: accountLoginName,
    ClientId: settings.clientId,
    EndPointURL: settings.endPointURL,
    DefaultToken: settings.defaultToken,
    AdminAccountId: settings.adminAccountId,
    Credentials: credential,
  };
  const signInUrl =
    settings.endPointURL +
    CanvasConfiguration.CanvasAuthUrl.replace(
      "{:redirectUrl}",
      CanvasConfiguration.ReturnUrl
    )
      .replace("{:clientId}", settings.clientId)
      .replace(
        "{:state}",
        encodeURI(Buffer.from(JSON.stringify(state)).toString("base64"))
      )
      .replace("{:scope}", CanvasConfiguration.Scopes);

  window.location.href = signInUrl;
}

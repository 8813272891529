import shell_off from "../../../../../assets/images/thinkorswim/shell_off.png";
import shell_on from "../../../../../assets/images/thinkorswim/shell_on.png";

interface IShellButtonProps {
  text: string,
  isOn: boolean,
  onClick?: () => void
}

function ShellButton(props: IShellButtonProps) {
  return (
    <div 
      className="cursor-pointer relative w-full text-[1.5vw] hover:text-[2vw]"
      onClick={props.onClick}
    >
      <div className="absolute top-[50%] w-full -translate-y-1/2">
        <p
          className="text-black text-center"
        >
          {props.text}
        </p>
      </div>
      <img 
        className="mx-auto"
        src={props.isOn ? shell_on : shell_off}
        alt="shell button"
      />
    </div>
  )
}

export default ShellButton

import React, { useState, useEffect } from "react";
import PIEChart from "../../../../../shared/charts/piechart";
import { useTranslation } from "react-i18next";
import { moveItemsToEnd } from "../../../../../../utils/helper";
import ShowMoreButton from "../../../../../shared/showMoreButton";
export default function ChartQuestionsByStandards({ data }: any) {
  const { t } = useTranslation();
  const [standardDataValues, setStandardDataValue] = useState<Array<any>>([]);
  const [toggleShowMore, setToggleShowMoreOption] = useState<boolean>(false);
  const [standardData, setStandardData] = useState<Array<any>>([]);
  const maxStandardToShow = 10;

  useEffect(() => {
    if (data && data.standards && data.standards.length) {
      let rearrangedStandards = moveItemsToEnd(
        data.standards,
        (s: any) => s.standardName === null
      );
      let chartDivHeight: any = document.getElementById("chart_id");
      if (chartDivHeight) chartDivHeight = chartDivHeight.style.height;
      const stdData: any = [];
      rearrangedStandards.forEach((standard) => {
        stdData.push({
          name: standard.standardName
            ? standard.standardName
            : "Other Standards",
          value: Math.round(
            (Number(standard.totalQuestions) / Number(data.totalQuestions)) *
              100
          ),
        });
      });
      setStandardData(stdData);
      setStandardDataValue(stdData.slice(0, maxStandardToShow));
    }
  }, []);

  const showHideGraphDetails = (): void => {
    if (!toggleShowMore) {
      setStandardDataValue(standardData);
    } else {
      setStandardDataValue(standardData.slice(0, maxStandardToShow));
    }
    setToggleShowMoreOption(!toggleShowMore);
  };

  return (
    <>
      {standardDataValues.length > 1 && (
        <div className="relative border border-gray-100 w-full px-7 py-7 bg-white rounded-md shadow-md shadow-xl xs-chart-fix xs-standards-chart-fix">
          <div
            id="chart_id"
            style={{
              display: "inline-block",
            }}
          >
            <h2 className="text-md font-semibold text-gray-800 mb-2">
              {t("AssignmentResult.TotalQuestionsByStandard")}
            </h2>

            {standardDataValues.length > 1 && (
              <PIEChart data={standardDataValues} innerRadius={40} />
            )}

            <ShowMoreButton
                  toggleShowMore={toggleShowMore}
                  onClick={showHideGraphDetails}
                  maxItemsToShow={maxStandardToShow}
                  totalItems={standardData.length}
              />
          </div>
        </div>
      )}
    </>
  );
}

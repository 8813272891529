import React, { useContext, useEffect, useRef } from "react"
import { CSSTransition as ReactCSSTransition } from "react-transition-group"

interface TransitionContext {
  parent: {
    appear?: string
    show?: boolean
    isInitialRender?: boolean
  }
}
const transitionContext = React.createContext<TransitionContext>({
  parent: {},
})

function useIsInitialRender() {
  const isInitialRender = useRef(true)
  useEffect(() => {
    isInitialRender.current = false
  }, [])
  return isInitialRender.current
}

interface TransitionProps {
  children?: React.ReactNode
  show?: boolean
  enter?: string
  enterFrom?: string
  enterTo?: string
  leave?: string
  leaveFrom?: string
  leaveTo?: string
  appear?: string
}

const CSSTransition: React.FC<TransitionProps> = function CSSTransition({
  show,
  enter = "",
  enterFrom = "",
  enterTo = "",
  leave = "",
  leaveFrom = "",
  leaveTo = "",
  appear,
  children,
}) {
  const enterClasses = enter.split(" ").filter((s) => s.length)
  const enterFromClasses = enterFrom.split(" ").filter((s) => s.length)
  const enterToClasses = enterTo.split(" ").filter((s) => s.length)
  const leaveClasses = leave.split(" ").filter((s) => s.length)
  const leaveFromClasses = leaveFrom.split(" ").filter((s) => s.length)
  const leaveToClasses = leaveTo.split(" ").filter((s) => s.length)

  function addClasses(node: HTMLElement, classes: string[]) {
    classes.length && node.classList.add(...classes)
  }

  function removeClasses(node: HTMLElement, classes: string[]) {
    classes.length && node.classList.remove(...classes)
  }

  return (
    <ReactCSSTransition
      appear={appear}
      unmountOnExit
      in={show}
      addEndListener={(node: HTMLElement, done) => {
        node.addEventListener("transitionend", done, false)
      }}
      onEnter={(node: HTMLElement) => {
        addClasses(node, [...enterClasses, ...enterFromClasses])
      }}
      onEntering={(node: HTMLElement) => {
        removeClasses(node, enterFromClasses)
        addClasses(node, enterToClasses)
      }}
      onEntered={(node: HTMLElement) => {
        removeClasses(node, [...enterToClasses, ...enterClasses])
      }}
      onExit={(node: HTMLElement) => {
        addClasses(node, [...leaveClasses, ...leaveFromClasses])
      }}
      onExiting={(node: HTMLElement) => {
        removeClasses(node, leaveFromClasses)
        addClasses(node, leaveToClasses)
      }}
      onExited={(node: HTMLElement) => {
        removeClasses(node, [...leaveToClasses, ...leaveClasses])
      }}
    >
      {children}
    </ReactCSSTransition>
  )
}

const Transition: React.FC<TransitionProps> = function Transition({ show, appear, ...rest }) {
  const { parent } = useContext(transitionContext)
  const isInitialRender = useIsInitialRender()
  const isChild = show === undefined

  if (isChild) {
    return <CSSTransition appear={parent.appear} show={parent.show} {...rest} />
  } else{
    return (
      <transitionContext.Provider
        value={{
          parent: {
            show,
            isInitialRender,
            appear,
          },
        }}
      >
        <CSSTransition appear={appear} show={show} {...rest} />
      </transitionContext.Provider>
    )
      }
}

export default Transition
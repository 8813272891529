import classNames from "classnames";
import { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";

import constant from "../../../../../utils/constant/constant";

import RouteConstant from "../../../../../utils/constant/routeConstant";
import Breadcrumb from "../../middleSchool/breadcrumb";
import { IBreadcrumb } from "../../../../../model/common/breadcrumb";
import { useTranslation } from "react-i18next";
import { ISkillSubject } from "../../../../../model/skillPractice/skillSubject";
import {
  getSkillStandard,
  getSkillSubject,
} from "../../../../../api/student/skillPracticeApi";
import SkillStandard from "./skillStandard";
import { useHistory } from "react-router-dom";
import { Configuration } from "../../../../../environment/setup";

function SkillPracticeSubject(props) {
  const [skillSubjects, setSkillSubjects] = useState<Array<ISkillSubject>>([]);
  const [selectedSubject, setSelectedSubject] = useState<ISkillSubject>();
  const { t } = useTranslation();
  const elaIcon: string = `${Configuration.S3bucketImagePath}/Icons/ContentArea/reading.svg`;
  const mathIcon: string = `${Configuration.S3bucketImagePath}/Icons/ContentArea/math.svg`;
  const socialStudyIcon: string = `${Configuration.S3bucketImagePath}/Icons/ContentArea/social-science.svg`;
  const history = useHistory();

  useEffect(() => {
    getSkillStandard(0, 0, props.userContext?.gradeId).then((d) => {
      if (d.data.data.length > 0) {
        loadSubjects(d.data.data.map((s) => s.skillSubjectId));
      }
    });
  }, []);

  function loadSubjects(skillSubjectIds) {
    getSkillSubject().then((d) => {
      const validSkillSubjects = d.data.data.filter((s) =>
        skillSubjectIds.includes(s.skillSubjectId)
      );
      setSkillSubjects(validSkillSubjects);
      setSelectedSubject(validSkillSubjects[0]);
    });
  }

  const breadcrumbItems = () => {
    const items: Array<IBreadcrumb> = [
      {
        name: t("Breadcrumb.Home"),
        url: RouteConstant.MiddleSchool.assignments,
        textColor: "text-white",
      },
      {
        name: t("Breadcrumb.SkillsPractice"),
        textColor: "text-white",
        url: "",
      },
    ];

    return items;
  };
  return (
    <Fragment>
      <>
        <div className="md:flex md:items-center px-4 py-3 lg:px-8 border-b">
          <Breadcrumb items={breadcrumbItems()} />
          <div className="md:w-[55%] text-lg flex justify-between gap-2 h-auto sm:h-10 items-center">
            <h1 className="text-gray-50 font-medium flex justify-start">
              {t("StudyType.SkillPractice")}
            </h1>
          </div>
        </div>
        <div className="px-4 py-4 lg:px-8">
          <div className="grid grid-cols-1 xl:grid-cols-10 gap-0 xl:gap-6 mt-4">
            <div className="col-span-4 xl:block md:gap-4 xl:gap-0 space-y-4 xl:space-y-6 md:mb-4 xl:mb-0">
              <div className="grid grid-cols-1 sm:grid-cols-2 bg-white bg-opacity-20 p-3 rounded-md gap-4 shadow-xl">
                {skillSubjects.map((s) => {
                  return (
                    <div
                      key={s.skillSubjectId}
                      className={classNames(
                        "group bg-white w-full px-2 py-2.5 border-[3px] rounded-md transition ease-in-out duration-300",
                        selectedSubject?.skillSubjectId === s.skillSubjectId
                          ? "bg-white border-primary-violet text-primary-violet"
                          : "text-gray-500"
                      )}
                      onClick={() => {
                        setSelectedSubject(s);
                      }}
                    >
                      <button
                        className="flex items-center w-full"
                        onClick={() => {
                          setSelectedSubject(s);
                        }}
                      >
                        {s.name === "Math" && (
                          <span className="w-6 flex-none mr-2">
                            <img
                              className="w-full"
                              srcSet={mathIcon}
                              alt="Subject Icon"
                            />
                          </span>
                        )}

                        {s.name === "SS" && (
                          <span className="w-6 flex-none mr-2">
                            <img
                              className="w-full"
                              srcSet={socialStudyIcon}
                              alt="Subject Icon"
                            />
                          </span>
                        )}

                        {s.name === "Reading" && (
                          <span className="w-6 flex-none mr-2">
                            <img
                              className="w-full"
                              srcSet={elaIcon}
                              alt="Subject Icon"
                            />
                          </span>
                        )}
                        <span className="text-left group-hover:text-primary-violet">
                          {props.profile?.plLanguageId ===
                          constant.Languages.Spanish
                            ? s.spanishName ?? s.name
                            : s.name}
                        </span>
                      </button>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="col-span-6 h-full mt-8 md:mt-4 xl:mt-0 ">
              <div className="rounded-lg bg-black bg-opacity-10 py-5 sm:px-6">
                {selectedSubject && (
                  <SkillStandard
                    skillSubjectId={selectedSubject.skillSubjectId}
                    onStandardSelected={(standardId: number) => {
                      history.push(
                        RouteConstant.MiddleSchool.SkillGameChoose.replace(
                          ":standardId",
                          standardId.toString()
                        )
                      );
                    }}
                  ></SkillStandard>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    </Fragment>
  );
}
const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(SkillPracticeSubject);

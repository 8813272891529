import { useTranslation } from "react-i18next";
import InformationDialog from "../../../../shared/informationDialog";

function QuestionAlreadyAttemptedPopup() {
  const { t } = useTranslation();

  return (
    <InformationDialog 
      informationTitle={t("Assignments.QuestionAlreadyAttmptedHeader")} 
      informationMessage={t("Assignments.QuestionAlreadyAttmptedMessage")} 
      open={true} 
      onOkClick={() => {
        window.location.reload();
      }} 
    />
  )
}

export default QuestionAlreadyAttemptedPopup

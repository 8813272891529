import { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { UserContextState } from "../../../../../redux/actions/userContextAction";
import Constant from "../../../../../utils/constant/constant";
import {
  completeSummativeStudyPlan,
  getSummativeLastSession,
  initializeSummativeTest,
  //updateSummativeTest,
  abandonSummativeTest,
} from "../../../../../api/student/studyPlanApi";
import { IInitalizeStudyPlanResponse } from "../../../../../model/student/studyplan/initializeStudyPlanRespons";
import QuestionAnswer from "../../shared/questionAnswer";
import Profile from "../../../../../model/users/profile";
import BeginActivity from "../../shared/beginActivity";
import RouteConstant from "../../../../../utils/constant/routeConstant";
import Breadcrumb from "../../middleSchool/breadcrumb";
import { IBreadcrumb } from "../../../../../model/common/breadcrumb";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
  getStudentClasses,
  getStudentTeacher,
} from "../../../../../api/student/studentApi";
import {
  GetShowExplanation,
  getTestSizeBySubjectId,
  getTestSizeType,
} from "../../../../../utils/helper";
import { getCalculatorBySubjectId } from "../../../../../api/student/studentSubjectApi";
import {
  GetIsQuestionRetryAllowed,
  GetShowOnlyTextExpalanation,
} from "../../../../../utils/helper";
import { getRetryEligibility } from "../../../../../api/student/studentActivityApi";
import { IQuestionAnswerProps } from "../../../../../model/interface/questionAnswer/questionAnswer";
import { IRetryMissedItemState } from "../../../../../model/interface/activitySession/retryAssignment";
interface IStudyPlanSummativeTest {
  userContext: UserContextState;
  profile: Profile;
  match: any;
  location: any;
}
function StudyPlanSummativeTest(props: IStudyPlanSummativeTest) {
  const { t } = useTranslation();
  let studentType: any = "EL";
  const [summativeTest, setSummativeTest] =
    useState<IInitalizeStudyPlanResponse>();
  const [lastSession, setLastSession] = useState<any>({});
  const subjectId = parseInt(atob(props.match.params.subjectId));
  const subjectName = props.location.state.subjectName ?? "";
  const testSize = parseInt(atob(props.match.params.testSize));
  const history = useHistory();
  const [beginActivityModal, setBeginActivityModal] = useState<any>({
    open: false,
    data: undefined,
  });
  const [testQuestionSize, setTestSize] = useState<number>(0);
  const [calculatorReferenceId, setCalculatorReferenceId] = useState<string>();
  const [isQuestionRetry, setQuestionRetry] = useState<boolean>(false);
  const [showOnlyTextExpalanation, setOnlyTextShowExplanation] =
    useState<boolean>(false);
  const [showExpalanation, setShowExplanation] = useState<boolean>(false);
  const [selectedLanguage, setSelectedLanguage] = useState<number>(1);
  const [contentAreaId, setContentAreaId] = useState<number>(0);

  useEffect(() => {
    callAsyncInitFunctions();
    setQuestionRetry(
      GetIsQuestionRetryAllowed(
        props.userContext?.gradeId,
        props.userContext?.stateId,
        Constant.ActivitySubTypes.PRACTICETEST,
        undefined
      )
    );
    setShowExplanation(
      GetShowExplanation(
        props.userContext?.gradeId,
        props.userContext?.stateId,
        Constant.ActivitySubTypes.PRACTICETEST
      )
    );
    setOnlyTextShowExplanation(
      GetShowOnlyTextExpalanation(
        props.userContext?.gradeId,
        props.userContext?.stateId,
        Constant.ActivitySubTypes.PRACTICETEST
      )
    );
  }, []);

  async function callAsyncInitFunctions () {
    const size = await getTestSizeBySubjectId(testSize, subjectId);
    setTestSize(size);
    if (subjectId > 0) {
      await getCalculatorBySubjectId(subjectId).then((res) => {
        if (res.data) setCalculatorReferenceId(res.data);
      });
    }
    initialize();
  }

  function initialize() {
    var classesResponseApi = getStudentClasses(
      props.userContext.userId,
      subjectId,
      0
    );
    var teacherResponseApi = getStudentTeacher(
      props.userContext.userId,
      subjectId,
      0
    );

    var lastSessionApi = getSummativeLastSession(
      props.userContext.userId,
      subjectId,
      testSize,
      0
    );

    Promise.all([classesResponseApi, teacherResponseApi, lastSessionApi]).then(
      ([classesResponse, teachersResponse, lastSessionResponse]) => {
        setLastSession(lastSessionResponse.data);

        var data: any = {
          classes: classesResponse.data,
          teachers: teachersResponse.data,
          classId:
            lastSessionResponse.data !== null
              ? lastSessionResponse.data.classId
              : undefined,
          teacherId:
            lastSessionResponse.data !== null
              ? lastSessionResponse.data.teacherUserId
              : undefined,
          languageId: lastSessionResponse.data.languageId,
        };
        setBeginActivityModal({ open: true, data });
      }
    );
  }

  function initializeTest(data: any) {
    initializeSummativeTest(
      props.userContext.userId,
      subjectId,
      testSize,
      data.languageId,
      0,
      0,
      0,
      "",
      props.userContext.gradeId,
      data.classId,
      data.teacherId
    ).then((d) => {
      var data = d.data;
      setSummativeTest(d.data);
      setBeginActivityModal({ open: false, data });
    });
  }

  // function update(data: any) {
  //   updateSummativeTest(
  //     lastSession.studentSummativeTestId,
  //     data.teacherId,
  //     data.classId
  //   );
  // }

  function completeStudyPlanActivity() {
    completeSummativeStudyPlan(
      props.userContext.userId,
      summativeTest?.sessionId ?? "",
      summativeTest?.activitySessionId ?? 0
    ).then((res) => {
      handleOnCompleteStudyPlanActivity();
    });
  }

  const handleOnCompleteStudyPlanActivity = async () => {
    const resultUrl = RouteConstant.StudentRoute.SummativeTestResult.replace(
      ":sessionId",
      summativeTest ? summativeTest.sessionId : ""
    );
    const res = await getRetryEligibility(summativeTest?.sessionId!);

    if (res.data.retryEligible) {
      redirectToRetryMissedItems(resultUrl);
    } else {
      history.push(resultUrl);
    }
  };

  function redirectToRetryMissedItems(resultUrl: string) {
    const retryUrl =
      RouteConstant.StudentRoute.StudentRetryAssignmentActivityEL.replace(
        ":sessionId",
        summativeTest?.sessionId!
      );

    const questionAnswer: IQuestionAnswerProps = {
      activityType: Constant.ActivityType.STUDYPLAN,
      activityId: summativeTest!.activityId ?? "",
      teacherActivityId: summativeTest?.sessionId!,
      showActivity: false,
      showQuestions: true,
      questionIds: [],
      hasSections: false,
      hasItemBranching: false,
      isAlienExplanation: true,
      isGameChoosen: false,
      studentId: props.userContext.userId.toString(),
      resumeActivity: false,
      sessionId: summativeTest?.sessionId!, // replace with retry sessionId
      studentType: studentType,
      title: subjectName,
      adaptiveFilter: null,
      onExitRedirectUrl: RouteConstant.StudentRoute.Home,
      questionRetry: isQuestionRetry,
      showExplanation: showExpalanation,
      showTextExpalanation: showOnlyTextExpalanation,
      activitySessionId: summativeTest?.activitySessionId!, // replace with retry activitySessionId
      subjectId: subjectId,
      language: selectedLanguage === Constant.Languages.Spanish ? "es" : "en",
      activitySubType: Constant.ActivitySubTypes.PRACTICETEST,
      calculatorReferenceId: calculatorReferenceId,
      OnSubmit: () => {},
      contentAreaId: contentAreaId,
      questionsState: summativeTest?.questionStates,
    };

    const state: IRetryMissedItemState = {
      activityType: Constant.RetryActivityType.STUDYPLAN_SUMMATIVE,
      languageId: selectedLanguage,
      resultUrl: resultUrl,
      questionAnswer: JSON.stringify(questionAnswer),
    };

    history.push({
      pathname: retryUrl,
      state: state,
    });
  }

  const breadcrumbItems = () => {
    const items: Array<IBreadcrumb> = [
      {
        name: t("Breadcrumb.Home"),
        url: RouteConstant.StudentRoute.Home,
        textColor: "text-gray-500",
      },
      {
        name: t("Breadcrumb.Elementary"),
        textColor: "text-gray-500",
        url: RouteConstant.StudentRoute.Home,
      },
      {
        name: t("Breadcrumb.StudyPlanSummativeTest"),
        textColor: "text-gray-500",
        url: "",
      },
    ];

    return items;
  };

  function onCancel () {
    history.push(`${
      RouteConstant.StudentRoute.Home
    }?tab=${Constant.StudyType.MYSTUDYPLAN.toLowerCase()}&subjectId=${subjectId}`);
  }

  return (
    <Fragment>
      <>
        <div className="px-4 py-3 lg:px-8 border-b">
          <nav className="flex mt-2" aria-label="Breadcrumb">
            <Breadcrumb items={breadcrumbItems()} />
          </nav>
          <div className="text-lg flex justify-between gap-2 h-auto sm:h-10 items-center">
            <h1 className="font-medium flex justify-start">
              {t("StudyPlan.StudyPlanSummativeTest")}
            </h1>
          </div>
        </div>
        {summativeTest !== undefined && summativeTest.sessionId.length > 0 && (
          <QuestionAnswer
            activityType={Constant.ActivityType.STUDYPLAN}
            activityId={
              summativeTest.activityId ? summativeTest.activityId : ""
            }
            teacherActivityId={summativeTest.sessionId}
            showActivity={summativeTest.activityId?.length > 0 ? true : false}
            showQuestions={summativeTest.activityId?.length > 0 ? false : true}
            questionIds={
              summativeTest.itemReferences &&
              summativeTest.itemReferences.length > 0
                ? summativeTest.itemReferences
                : []
            }
            hasSections={false}
            hasItemBranching={false}
            isAlienExplanation={true}
            isGameChoosen={false}
            studentId={props.userContext.userId.toString()}
            resumeActivity={summativeTest.hasPendingSession}
            sessionId={summativeTest.sessionId}
            studentType={studentType}
            title={subjectName}
            adaptiveFilter={null}
            OnSubmit={completeStudyPlanActivity}
            onExitRedirectUrl={RouteConstant.StudentRoute.Home}
            activitySubType={Constant.ActivitySubTypes.PRACTICETEST}
            calculatorReferenceId={calculatorReferenceId}
            questionRetry={isQuestionRetry}
            showExplanation={showExpalanation}
            activitySessionId={summativeTest.activitySessionId}
            showTextExpalanation={showOnlyTextExpalanation}
            language={selectedLanguage === 2 ? "es" : "en"}
            subjectId={subjectId}
            contentAreaId={contentAreaId}
            shuffleItems={summativeTest.isDisplayRandomQuestion}
            questionsState={summativeTest.questionStates}
          />
        )}
        {beginActivityModal.open && (
          <BeginActivity
            activity={getTestSizeType(testSize) + " Practice Test"}
            name={subjectName + " Practice Test"}
            noOfQuestions={testQuestionSize}
            source={"StudyPlan"}
            themeName={"ELEMENTARYHOME"}
            data={beginActivityModal.data}
            hideClass={true}
            hideTeacher={true}
            ActivityLanguageLevel={"UserLevel"}
            onBegin={(data: any, value: any) => {
              setContentAreaId(value.contentAreaId);
              setSelectedLanguage(value.languageId);
              if (
                lastSession === null ||
                lastSession === "" ||
                lastSession === undefined ||
                data.languageId !== value.languageId
              ) {
                abandonSummativeTest(
                  props.userContext.userId,
                  lastSession.lernositySessionId
                ).then((d) => {
                  initializeTest(value);
                });
              } else {
                setSummativeTest({
                  hasPendingSession: true,
                  sessionId: lastSession.lernositySessionId,
                  activityId: lastSession.activityId,
                  activitySessionId: lastSession.activitySessionId,
                  itemReferences: lastSession.itemReferences,
                  learnositySessionGroupId: "",
                  isDisplayRandomQuestion: lastSession.isDisplayRandomQuestion,
                  questionStates: lastSession.questionStates,
                });
                //update(value);
                setBeginActivityModal({ ...beginActivityModal, open: false });
              }
            }}
            onCancel={onCancel}
            subjectId={subjectId}
          />
        )}
      </>
    </Fragment>
  );
}

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(StudyPlanSummativeTest);

import {
  getAssessmentStudentAttemptsReport,
  getAssessmentStudentAttemptsReportExcel,
} from "../../../../../../api/report/resultsReport";
import { UserContextState } from "../../../../../../redux/actions/userContextAction";
import { AssessmentStudentAttemptsReport } from "../../../../../../model/report/resultsReport/StudentAttemptsReport";
import { AxiosResponse } from "axios";
import { toast } from "react-toastify";
import { ReportAssessmentStudentAttemptsTable } from "./ReportAssessmentStudentAttemptsTable";
import { useState } from "react";
import Loader from "../../../../../shared/loader";

interface IResultReportAssessmentStudentAttemptsProps {  
  assessmentId?: number;
  premiumAssessmentId?: number;
  classIds: number[];
  schoolIds: number[];
  schoolYearId: number;
  isDistrictUser: boolean;
  userContext: UserContextState;
}

export const ResultReportAssessmentStudentAttempts = (
  props: IResultReportAssessmentStudentAttemptsProps
) => {
  const [showLoader, setShowLoader] = useState(false); // Declare and initialize setShowLoader variable

  const getReportData = async (activeSortColumn: string, orderType: string, pageNumber: number, pageSize: number): 
    Promise<AssessmentStudentAttemptsReport> => {

    const response = await getAssessmentStudentAttemptsReport(
      props.assessmentId,
      props.premiumAssessmentId,
      props.schoolIds,
      props.classIds,
      props.schoolYearId,
      props.userContext.userId,
      activeSortColumn,
      orderType === "desc",
      pageNumber,
      pageSize
    );

    return response.data;
  };

  const exportReport = () => { 
    setShowLoader(true);
    getAssessmentStudentAttemptsReportExcel(
      props.assessmentId,
        props.premiumAssessmentId,
      props.schoolIds,
      props.classIds,
      props.schoolYearId,
      props.userContext.userId
    )
    .then(response => {
      startFileDownload(response, "AssessmentStudentAttempts.xlsx");
      setShowLoader(false);
    })
    .catch(error => {
      setShowLoader(false);
      toast.error(
        "Export request failed. If the request takes a long time, consider adding filters to decrease the report size.",
        {autoClose: 0}
      );
    })
  }

  const startFileDownload = (
    response: AxiosResponse<Blob>,
    fileName: string,
  ) => {
    const file = response.data;
    const fileURL = URL.createObjectURL(file);
    const link = document.createElement("a");
    link.href = fileURL;
    link.download = fileName;
    link.setAttribute("target", "_blank");
    document.body.appendChild(link);
    link.click();
  }

  return (
    <>
      {showLoader && <Loader></Loader>}
      <ReportAssessmentStudentAttemptsTable
        exportData={exportReport}
        getData={getReportData}
        isDistrictUser={props.isDistrictUser}
        schoolIds={props.schoolIds}
        schoolYearId={props.schoolYearId}
      ></ReportAssessmentStudentAttemptsTable>
    </>    
  );
};

export interface IStudentLiftOffMyGalaxy {
  galaxies: any;
  headers: string[];
  liftOffHeaders: any;
  standards: IStudentLiftOffMyGalaxyStandard[];
}
interface IStudentLiftOffMyGalaxyStandard {
  userId: number;
  gradeId: number;
  subjectId: number;
  subjectName: string | null;
  liftOffHeaderId: number;
  header: string;
  liftOffGalaxyId: number;
  galaxyName: string | null;
  standardId: number;
  standardName: string | null;
  spanishStandardName: string | null;
  orderNumber: number;
  studentLiftOffMyGalaxyStandardId: number;
  schoolYearId: number;
  assignmentStatusId: number;
  dateStarted: number;
  dateCompleted: number;
  earnedStar: number;
  lernositySessionId: number;
  lernosityActivityId: number;
  isDiagnosticTestCompleted: boolean;
  isStandardDisable?: boolean;
  gameId?: boolean;
  rocketId?: boolean;
  blasterId?: boolean;
  isAssignmentInProgress?: boolean;
  blasterType?: string;
  isStandardGrouped: boolean;
}
interface IGalaxy {
  name: string | undefined;
  countStandard: number;
}
interface IGalaxyHeader {
  galaxyName: string | undefined;
  numberOfStandard: number;
  numberOfStar: number;
  isDisable?: boolean;
  header: string | undefined;
  isDiagnosticTestCompleted: boolean;
}
export class MyGalaxyTableBulider {
  galaxyHeaders: any = [];
  galaxyTable: any = [];
  constructor(private studentLiftOffMyGalaxy: IStudentLiftOffMyGalaxy) {}
  async build() {
    this.galaxyHeaders = [];
    this.galaxyTable = [];
    await this.initializeMyGalaxies(this.studentLiftOffMyGalaxy.galaxies);
    await this.buildGalaxyHeaders();
    await this.addMockStandards();
    await this.arrangeStandardOrder();
    await this.bindRow();
    await this.updateLinksVisibility();
    return this.galaxyTable;
  }
  async initializeMyGalaxies(galaxies: string[]) {
    let galaxyList: IGalaxy[] = [];
    let indexCount = galaxies.length > 0 ? galaxies.length : 1;
    galaxies.forEach((galaxy) => {
      galaxyList.push({
        name: galaxy,
        countStandard: 0,
        indexCount: indexCount,
      } as IGalaxy);
      indexCount = indexCount - 1;
    });
    this.studentLiftOffMyGalaxy.galaxies = galaxyList;
  }
  async buildGalaxyHeaders() {
    this.studentLiftOffMyGalaxy?.headers?.forEach((liftOffHeader) => {
      this.galaxyHeaders[liftOffHeader] = [];
      let galaxyCounter = -1;
      for (
        let i = this.studentLiftOffMyGalaxy.galaxies.length - 1;
        i >= 0;
        i--
      ) {
        galaxyCounter = galaxyCounter + 1;
        let galaxy = this.studentLiftOffMyGalaxy.galaxies[i];
        let galaxyHeader: IGalaxyHeader = {
          galaxyName: galaxy.name,
          header: liftOffHeader,
          isDiagnosticTestCompleted: false,
          numberOfStar: 0,
          numberOfStandard: 0,
          isDisable: true,
        };
        let standards = this.studentLiftOffMyGalaxy.standards.filter(
          (x) => x.galaxyName === galaxy.name && x.header === liftOffHeader
        );
        if (standards.length > 0) {
          standards.forEach((standard) => {
            if (standard.isDiagnosticTestCompleted)
              galaxyHeader.isDiagnosticTestCompleted = true;
            galaxyHeader.numberOfStandard = galaxyHeader.numberOfStandard + 1;
            if (standard.earnedStar > 0)
              galaxyHeader.numberOfStar = galaxyHeader.numberOfStar + 1;
          });
        } else {
          galaxyHeader.isDiagnosticTestCompleted = true;
          galaxyHeader.numberOfStandard = 1;
          galaxyHeader.numberOfStar = 1;
          galaxyHeader.isDisable = false;
        }

        if (
          (i === this.studentLiftOffMyGalaxy.galaxies.length - 1 &&
            galaxyHeader.isDiagnosticTestCompleted) ||
          (this.galaxyHeaders[liftOffHeader].length >= 1 &&
            this.galaxyHeaders[liftOffHeader][galaxyCounter - 1]
              .numberOfStar ===
              this.galaxyHeaders[liftOffHeader][galaxyCounter - 1]
                .numberOfStandard &&
            this.galaxyHeaders[liftOffHeader][galaxyCounter - 1].numberOfStar >
              0)
        ) {
          galaxyHeader.isDisable = false;
        }
        this.galaxyHeaders[liftOffHeader].push(galaxyHeader);
      }
    });
  }
  async addMockStandards() {
    this.studentLiftOffMyGalaxy.galaxies.forEach((galaxy) => {
      let totstandard = 0;
      this.studentLiftOffMyGalaxy?.headers?.forEach((header) => {
        let galexyHeader = this.galaxyHeaders[header].filter(
          (x) => x.galaxyName === galaxy.name
        );
        if (totstandard < galexyHeader[0].numberOfStandard)
          totstandard = galexyHeader[0].numberOfStandard;
      });

      galaxy.countStandard = totstandard;
      this.studentLiftOffMyGalaxy?.headers?.forEach((header) => {
        let standards = this.studentLiftOffMyGalaxy.standards.filter(
          (x) => x.galaxyName === galaxy.name && x.header === header
        );
        //sort again due to unorder orderNumber and order again from 1
        standards = standards.sort((a, b) =>
          a.orderNumber < b.orderNumber
            ? -1
            : a.orderNumber > b.orderNumber
            ? 1
            : 0
        );
        for (let i = 1; i <= standards.length; i++) {
          standards[i - 1].orderNumber = i;
        }
        //

        if (standards.length < totstandard) {
          for (let i = standards.length; i < totstandard; i++) {
            this.studentLiftOffMyGalaxy.standards.push({
              galaxyName: galaxy.name,
              gradeId: 0,
              header: header,
              liftOffGalaxyId: 0,
              liftOffHeaderId: 0,
              orderNumber: i + 1,
              spanishStandardName: null,
              standardId: 0,
              standardName: null,
              subjectId: 0,
              subjectName: null,
              userId: 0,
            } as IStudentLiftOffMyGalaxyStandard);
          }
        }

        let orderCounter = 1;
        for (let i = totstandard; i >= 1; i--) {
          let standardOrders = standards.filter((x) => x.orderNumber === i);
          standardOrders.forEach((standardOrder) => {
            standardOrder["newOrderNumber"] = orderCounter;
            orderCounter = orderCounter + 1;
          });
        }
      });
    });
    return true;
  }
  async arrangeStandardOrder() {
    this.studentLiftOffMyGalaxy.galaxies.forEach((galaxy, index) => {
      for (let i = galaxy.countStandard; i >= 1; i--) {
        let td = {};
        td["span"] = 0;
        td["galaxyId"] = galaxy.indexCount;
        if (i === galaxy.countStandard) td["span"] = galaxy.countStandard;
        td["galaxy"] = galaxy.name;
        this.studentLiftOffMyGalaxy?.headers?.forEach((header) => {
          let standard = this.studentLiftOffMyGalaxy.standards.filter(
            (x) =>
              x.galaxyName === galaxy.name &&
              x.header === header &&
              x["newOrderNumber"] === i
          );

          if (standard.length > 0) {
            td[standard[0]?.header] = standard[0];
            if (standard[0].liftOffGalaxyId > 0)
              td["galaxyId"] = standard[0].liftOffGalaxyId;
            else
              td["galaxyId"] =
                this.studentLiftOffMyGalaxy.galaxies.length - index;
          }
        });
        this.galaxyTable.push(td);
      }
    });
  }
  async bindRow() {
    if (this.galaxyTable && this.galaxyTable.length > 0) {
      let td = {};
      this.studentLiftOffMyGalaxy.galaxies.forEach((galaxy, index) => {
        td["galaxyId"] = this.studentLiftOffMyGalaxy.galaxies.length - index;
        td["span"] = 1;
        this.studentLiftOffMyGalaxy?.headers?.forEach((header) => {
          td[header] = {
            isDisplayRocket: true,
            standardName: "",
          };
        });
      });
      this.galaxyTable.push(td);
    }
    return true;
  }
  async updateLinksVisibility() {
    let headerIndex: any = [];
    this.studentLiftOffMyGalaxy?.headers?.forEach((liftOffHeader) => {
      let isFound = false;
      for (let i = 0; i <= this.galaxyTable.length - 2; i++) {
        if (this.galaxyTable[i][liftOffHeader] && !isFound) {
          headerIndex[liftOffHeader] = i;
          isFound = true;
        }
      }
    });

    this.studentLiftOffMyGalaxy?.headers?.forEach((liftOffHeader) => {
      let isDisable = true;
      let starCount = 0;
      let galaxyStandardCount = 0;
      for (let i = this.galaxyTable.length - 2; i >= 0; i--) {
        if (this.galaxyTable[i][liftOffHeader]?.standardName)
          galaxyStandardCount = galaxyStandardCount + 1;

        if (this.galaxyTable[i][liftOffHeader]?.isDiagnosticTestCompleted) {
          if (this.galaxyTable[i][liftOffHeader]?.earnedStar > 0 || isDisable) {
            this.galaxyTable[i][liftOffHeader]["isStandardDisable"] = false;
            if (this.galaxyTable[i][liftOffHeader]?.earnedStar > 0)
              starCount = starCount + 1;
            //Rocket
            else if (isDisable) {
              this.galaxyTable[i][liftOffHeader]["isRocket"] = true;
              this.galaxyTable[this.galaxyTable.length - 1][liftOffHeader][
                "isDisplayRocket"
              ] = false;
              isDisable = false;
            }
          } else {
            let galaxyheader = this.galaxyHeaders[liftOffHeader].filter(
              (x) =>
                x.galaxyName ===
                  this.galaxyTable[i][liftOffHeader].galaxyName &&
                x.isDisable &&
                this.galaxyTable[i][liftOffHeader]
            );
            if (galaxyheader.length > 0) {
              this.galaxyTable[i][liftOffHeader]["isStandardDisable"] = true;
            } else {
              this.galaxyTable[i][liftOffHeader]["isStandardDisable"] = false;
            }
          }
        } else if (this.galaxyTable[i][liftOffHeader]) {
          this.galaxyTable[i][liftOffHeader]["isStandardDisable"] = true;
        }
      }
      if (
        starCount === galaxyStandardCount &&
        this.galaxyTable[headerIndex[liftOffHeader]]
      ) {
        let loh: any = this.studentLiftOffMyGalaxy?.liftOffHeaders.find(
          (h) => h.header === liftOffHeader
        );
        if (loh) {
          loh.isCompleted = true;
        }

        this.galaxyTable[headerIndex[liftOffHeader]][liftOffHeader][
          "isRocket"
        ] = false;
        this.galaxyTable[this.galaxyTable.length - 1][liftOffHeader][
          "isDisplayRocket"
        ] = false;
      }
    });
  }
}

import { Fragment, useEffect, useState } from "react";
import Loader from "../../../shared/loader";
import { getQuestionBreakdown } from "../../../../api/teacher/assessment";
import { XIcon } from "@heroicons/react/outline";
import { IQuestionBreakdown } from "../../../../model/interface/questionBreakdown";
import Chart from "react-google-charts";
import { getRandomColor } from "../../../../utils/helper";
import NoData from "../../../shared/noData";

interface QuestionBreakdownModalProps {
  assessmentId: number;
  showPopUp: (show: boolean) => void;
}

function QuestionBreakdownModal(props: QuestionBreakdownModalProps) {
  const [loading, setLoading] = useState(false);
  const [breakdownByDomain, setBreakdownByDomain] = useState<Array<any>>([]);
  const [randomColors, setRandomColors] = useState<Array<string>>([]);
  const [breakdownByStandard, setBreakdownByStandard] = useState<any>({
    highestPercentage: 0,
    maxIntervalLimit: 0,
    interval: 0,
    progressWidth: 0,
    data: [],
  });
  const percentageLimit = 40;

  const pieChartOptions = {
    title: "",
    colors: randomColors,
    legend: {
      position: "right",
      alignment: "center",
      textStyle: {
        fontSize: 12,
      },
    },
    width: 900,
    height: 500,
    tooltip: { text: "none" },
    chartArea: {
      height: 1000,
      left: 250,
      top: 0,
      width: 1000,
    },
    pieSliceText: "value",
  };

  function getQuestionBreakdownDetails(assessmentId: number) {
    setLoading(true);
    getQuestionBreakdown(assessmentId)
      .then((response) => {
        setLoading(false);
        const questionBreakdown = response.data as IQuestionBreakdown;
        drawQuestionBreakdownByDomain(questionBreakdown);
        drawQuestionBreakdownByStandard(questionBreakdown);
      })
      .catch((err) => {
        setLoading(false);
      });
  }

  const roundPercentages = (numberArray: Array<number>, epsilon: number = 0 ) => {
    return numberArray.map(x => {
      const rounded = Math.round(x + epsilon);
      epsilon += x - rounded;
      return rounded
    })
  }

  function drawQuestionBreakdownByDomain(
    questionBreakdown: IQuestionBreakdown
  ) {
    let chartData: any = [["Domains", "Domains Percentage"]];
    let colors: Array<string> = [];

    const percentages = roundPercentages(
      questionBreakdown?.questionBreakdownByDomain.map(d =>  d.questionPercentage)
    )

    questionBreakdown?.questionBreakdownByDomain.map((d, i) => {
      chartData.push([
        `${d.domainName}   ${percentages[i]}% [${
          d.questionCount
        } Questions]`,
        percentages[i],
      ]);
      colors.push(getRandomColor());
    });

    setBreakdownByDomain(chartData);
    setRandomColors(colors);
  }

  function drawQuestionBreakdownByStandard(
    questionBreakdown: IQuestionBreakdown
  ) {
    let data = questionBreakdown?.questionBreakdownByStandard;
    let highestPercentage = Math.max(...data.map((s) => s.questionPercentage));
    const percentages = roundPercentages(
      data.map(d =>  d.questionPercentage)
    );

    data.forEach((d, i) => d.questionPercentage = percentages[i])

    const value = 10 - (highestPercentage % 10);
    const maxIntervalLimit =
      highestPercentage === value || highestPercentage === 100
        ? highestPercentage
        : highestPercentage + value;

    const progressWidth = 100 / maxIntervalLimit;

    setBreakdownByStandard({
      highestPercentage: highestPercentage,
      maxIntervalLimit: maxIntervalLimit,
      interval: highestPercentage > percentageLimit ? 10 : 5,
      progressWidth: progressWidth,
      data: data,
    });
  }

  function getIntervals(maxIntervalLimit: number) {
    let intervals = [...Array(maxIntervalLimit)].map((a, i) => {
      if (i % breakdownByStandard.interval === 0) {
        return (
          <>
            <div
              style={{
                width:
                  breakdownByStandard.progressWidth *
                    breakdownByStandard.interval +
                  "%",
              }}
            >
              <span className={` ${i !== 0 ? "ml-[-8px]" : ""}`}>{i}%</span>
            </div>
          </>
        );
      }
    });
    return intervals;
  }
  useEffect(() => {
    getQuestionBreakdownDetails(props.assessmentId);
  }, []);

  return (
    <Fragment>
      {loading && <Loader></Loader>}

      <div className="fixed inset-0 z-40 flex items-center bg-black bg-opacity-50 sm:items-center sm:justify-center">
        <div
          className="w-full flex flex-col justify-between overflow-hidden h-auto max-h-[95%] bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:max-w-7xl"
          role="dialog"
        >
          <header>
            {/* Header */}
            <div className="px-4 py-4 bg-gray-50">
              <div className="flex items-start justify-between space-x-3">
                <div className="space-y-1">
                  <div className="text-lg font-medium text-gray-900">
                    <h2 className="text-lg text-gray-700 mb-2">
                      Question breakdown
                    </h2>
                  </div>
                </div>
                <div className="h-7 flex items-center">
                  <button
                    type="button"
                    className="text-gray-400 hover:text-gray-500"
                    onClick={() => props.showPopUp(false)}
                  >
                    <span className="sr-only">Close panel</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
          </header>

          <div className="w-full relative overflow-auto px-4 py-6">
            {(breakdownByDomain?.length > 1 &&
              breakdownByStandard?.data.length > 0 && (
                <>
                  <div className="font-semibold text-center ">
                    Question Breakdown By Domain
                  </div>
                  <div className="pl-4 w-full sm:w-1/2 sm:my-4 sm:px-4 md:px-3  lg:px-4  xl:px-4">
                    <Chart
                      chartType="PieChart"
                      data={breakdownByDomain}
                      options={pieChartOptions}
                      width={"100%"}
                      formatters={[
                        {
                          column: 1,
                          type: "NumberFormat",
                          options: { fractionDigits: 0, suffix: "%" },
                        },
                      ]}
                    />
                  </div>

                  <div className="m-4">
                    <div className="font-semibold text-center ">
                      Question Breakdown By Standard
                    </div>

                    <div className="bg-white py-5   pt-2 mt-4">
                      <div>
                        <table className="w-full">
                          <tr className="border-b border-gray-300">
                            <td className="lg:sticky lg:left-0 z-30 w-96 bg-white">
                              <div className="flex w-full">
                                <div className="w-full font-semibold justify-end px-2 flex items-end py-2  text-xs">
                                  Standards
                                </div>
                                <div className="w-20 text-center text-xs font-semibold bg-gray-100 px-1.5 py-2">
                                  Total Questions
                                </div>
                              </div>
                            </td>
                            <td></td>
                          </tr>

                          {breakdownByStandard?.data.map((s, i) => (
                            <tr key={i}>
                              <td className="lg:sticky lg:left-0 z-30 w-96 bg-white">
                                <div className="flex w-full ">
                                  <div className="w-full text-right whitespace-nowrap cursor-pointer px-2">
                                    ({s.standardCode}) {s.standardName}
                                  </div>
                                  <div className="w-20 text-center whitespace-nowrap bg-gray-100 px-2">
                                    {s.questionCount}
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="flex h-5 min-w-[200px] border-b border-gray-300">
                                  <div
                                    className={`bg-[#f7b26a] h-full text-right`}
                                    style={{
                                      width:
                                        s.questionPercentage *
                                          breakdownByStandard.progressWidth +
                                        "%",
                                    }}
                                  >
                                    <span className="px-1 text-xs">
                                      {s.questionPercentage}%
                                    </span>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          ))}
                          <tr>
                            <td className="lg:sticky lg:left-0 z-30 w-96 bg-white">
                              <div className="flex w-full ">
                                <div className="w-full text-right whitespace-nowrap cursor-pointer px-2"></div>
                                <div className="w-20 text-center whitespace-nowrap bg-gray-100 px-2"></div>
                              </div>
                            </td>
                            <td>
                              <div className="flex h-5 min-w-[200px]">
                                {getIntervals(
                                  breakdownByStandard.maxIntervalLimit
                                )}
                                <div>
                                  <span className="absolute ml-[-8px]">
                                    {breakdownByStandard.maxIntervalLimit}%
                                  </span>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                </>
              )) || <NoData />}
          </div>

          <footer className="flex flex-col items-center justify-end px-6 py-4 sm:flex-row bg-gray-50 dark:bg-gray-800">
            <div className="flex-shrink-0">
              <div className="space-x-3 flex justify-end">
                <button
                  className="align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 rounded-lg text-sm text-gray-600 border-gray-300 border dark:text-gray-400 focus:outline-none active:bg-transparent hover:border-gray-500 focus:border-gray-500 active:text-gray-500 focus:shadow-outline-gray sm:w-auto"
                  type="button"
                  onClick={() => {
                    props.showPopUp(false);
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </Fragment>
  );
}

export default QuestionBreakdownModal;

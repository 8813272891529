import { AxiosHelper } from "../../environment/axiosConfig";
import APIConstant from "../../utils/constant/apiConstant";

export const getGradedWork = (
  StudentId: number,
  AssignmentType: number,
  classId?: number,
  subjectId?: number,
  startDate?: string,
  endDate?: string,
  pageSize: number = 10,
  pageNumber: number = 1
) => {
  return AxiosHelper.axiosInstance().get(
    APIConstant.GRADED_WORK.getGradedWork,
    {
      params: {
        StudentId: StudentId,
        ViewFilter: AssignmentType,
        ClassId: classId,
        SubjectId: subjectId,
        StartDate: startDate,
        EndDate: endDate,
        pageSize: pageSize,
        pageNumber: pageNumber
      },
    }
  );
};

export const getGroupedActivities = (assignmentId: number, studentId) => {
  return AxiosHelper.axiosInstance().get(
    APIConstant.GRADED_WORK.getAssignmentActivities,
    {
      params: {
        StudentId: studentId,
        AssignmentId: assignmentId,
        pageSize: 9999,
        pageNumber: 1,
      },
    }
  );
};
export const getSubjectsByGrade = (gradeId: number) => {
  return AxiosHelper.axiosInstance().get(APIConstant.CMS_API.Subjects, {
    params: {
      GradeId: gradeId,
    },
  });
};
export const updateTeacher = (
  userId: number,
  ID: number,
  module: string,
  teacherUserId: number,
  classId: number
) => {
  return AxiosHelper.axiosInstance().put(
    APIConstant.GRADED_WORK.updateTeacher,
    {
      userId: userId,
      id: ID,
      module: module,
      teacherUserId: teacherUserId,
      classId: classId,
    },
    {}
  );
};

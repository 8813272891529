import { Fragment, useEffect, useState } from "react";
import { XIcon } from "@heroicons/react/solid";
import { getAssessmentStats } from "../../../../api/teacher/assessment";
import { IAssessmentStat } from "../../../../model/interface/assessmentStat";
import Loader from "../../../shared/loader";
interface AssessmentStatProps {
  assessmentId: number;
  name: string;
  togglePopup: (popupAction: boolean) => void;
}

function AssessmentStat(props: AssessmentStatProps) {
  const { assessmentId, name, togglePopup } = props;
  const [assessmentStat, setAssessmentStat] = useState<IAssessmentStat>();
  const [showLoading, setShowLoading] = useState<boolean>(false);
  useEffect(() => {
    setShowLoading(true);
    getAssessmentStats(assessmentId).then((response) => {
      setShowLoading(false);
      setAssessmentStat(response.data);
    });
  }, []);

  return (
    <Fragment>
      {showLoading && <Loader />}
      <div className="fixed inset-0 z-40 flex items-center bg-black bg-opacity-50 sm:items-center sm:justify-center">
        <div
          className="w-1/2 flex flex-col justify-between overflow-hidden h-auto max-h-80percent bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:max-w-6xl"
          role="dialog"
        >
          <header>
            {/* Header */}
            <div className="px-4 py-4 bg-gray-50">
              <div className="flex items-start justify-between space-x-3">
                <div className="space-y-1">
                  <div className="text-lg font-medium text-gray-900">
                    Assessment-{name}
                  </div>
                </div>
                <div className="h-7 flex items-center">
                  <button
                    type="button"
                    className="text-gray-400 hover:text-gray-500"
                    onClick={() => togglePopup(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
          </header>
          <div className="w-full relative overflow-auto px-4 py-1">
            <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
              <div className="p-4">
                <div className="flex items-start">
                  <div className=" w-0 flex-1">
                    <div className="mt-3 flex items-start transition ease-in-out duration-150 gap-4">
                      <div className="flex-shrink-0 flex items-center w-36 justify-start">
                        <label
                          htmlFor="first-name"
                          className="block text-sm font-normal text-gray-500 sm:mt-px font-semibold text-sm"
                        >
                          Subject
                        </label>
                      </div>
                      <div className="">
                        <span className="text-sm text-gray-900">
                          {assessmentStat?.subject}
                        </span>
                      </div>
                    </div>

                    <div className="mt-3 flex items-start transition ease-in-out duration-150 gap-4">
                      <div className="flex-shrink-0 flex items-center w-36 justify-start">
                        <label
                          htmlFor="first-name"
                          className="block text-sm font-normal text-gray-500 sm:mt-px font-semibold text-sm"
                        >
                          Author
                        </label>
                      </div>
                      <div className="">
                        <span className="text-sm text-gray-900">
                          {assessmentStat?.author}
                        </span>
                      </div>
                    </div>
                    <div className="mt-3 flex items-start transition ease-in-out duration-150 gap-4">
                      <div className="flex-shrink-0 flex items-center w-36 justify-start">
                        <label
                          htmlFor="first-name"
                          className="block text-sm font-normal text-gray-500 sm:mt-px font-semibold text-sm"
                        >
                          Length
                        </label>
                      </div>
                      <div className="">
                        <span className="text-sm text-gray-900">
                          {assessmentStat?.questionsLength}
                        </span>
                      </div>
                    </div>

                    <div className="mt-3 flex items-start transition ease-in-out duration-150 gap-4">
                      <div className="flex-shrink-0 flex items-center w-36 justify-start">
                        <label
                          htmlFor="first-name"
                          className="block text-sm font-normal text-gray-500 sm:mt-px font-semibold text-sm"
                        >
                          Question DOK
                        </label>
                      </div>
                      <div className="">
                        <span className="text-sm text-gray-900">
                          {assessmentStat &&
                            assessmentStat.dokBreakDown &&
                            assessmentStat.dokBreakDown.length > 0 &&
                            assessmentStat.dokBreakDown
                              .sort((a, b) => {
                                if (a.value[1] > b.value[1]) {
                                  return 1;
                                } else if (a.value[1] === b.value[1]) {
                                  return 0;
                                } else {
                                  return -1;
                                }
                              })
                              .map((item) => {
                                return (
                                  <div>{`${item.value[1]}-${item.key}:${item.value[0]}`}</div>
                                );
                              })}
                        </span>
                      </div>
                    </div>
                    <div className="mt-3 flex items-start transition ease-in-out duration-150 gap-4">
                      <div className="flex-shrink-0 flex items-center w-36 justify-start">
                        <label
                          htmlFor="first-name"
                          className="block text-sm font-normal text-gray-500 sm:mt-px font-semibold text-sm"
                        >
                          Domain Breakdown
                        </label>
                      </div>
                      <div className="">
                        <span className="text-sm text-gray-900">
                          {assessmentStat &&
                            assessmentStat.domainBreakDown &&
                            assessmentStat.domainBreakDown.length > 0 &&
                            assessmentStat.domainBreakDown.map((item) => {
                              return <div>{`${item.key}:${item.value}`}</div>;
                            })}
                        </span>
                      </div>
                    </div>
                    <div className="mt-3 flex items-start transition ease-in-out duration-150 gap-4">
                      <div className="flex-shrink-0 flex items-center w-36 justify-start">
                        <label
                          htmlFor="first-name"
                          className="block text-sm font-normal text-gray-500 sm:mt-px font-semibold text-sm"
                        >
                          Standards Breakdown
                        </label>
                      </div>
                      <div className="">
                        <span className="text-sm text-gray-900">
                          {assessmentStat &&
                            assessmentStat.standardBreakDown &&
                            assessmentStat.standardBreakDown.length > 0 &&
                            assessmentStat.standardBreakDown.map((item) => {
                              return <div>{`${item.key}:${item.value}`}</div>;
                            })}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <footer className="flex items-center justify-end px-4 py-4 sm:flex-row bg-gray-50 dark:bg-gray-800 mt-4">
            <div className="space-x-3 flex justify-end">
              <button
                onClick={() => togglePopup(false)}
                className="bg-secondary-teal border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-dark-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3"
              >
                OK
              </button>
            </div>
          </footer>
        </div>
      </div>
    </Fragment>
  );
}

export default AssessmentStat;

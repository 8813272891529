import { XIcon } from "@heroicons/react/outline";
import { connect } from "react-redux";
import NoData from "../../shared/noData";
import Pagination from "../../shared/pagination/pagination";
import PageSize from "../../shared/pagination/pageSize";
import { useEffect, useState } from "react";
import {
  DEFAULT_PAGE_NUMBER,
  DEFAULT_PAGE_SIZE,
} from "../../../utils/pagingConstant";
import {
  updateNWEAId,
  searchUnmappedNWEAStudents,
} from "../../../api/teacher/nwea";

const NWEAMappingModal = (props: any) => {
  const [pageNumber, setPageNumber] = useState(DEFAULT_PAGE_NUMBER);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [resetPageNumber, setResetPageNumber] = useState<boolean>(false);
  const [nweaStudents, setNWEAStudents] = useState<Array<any>>([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [nweaId, setNWEAId] = useState<string>("");
  const [firstName, setFirstName] = useState<string>(
    props.selectedUserFirstName
  );
  const [lastName, setLastName] = useState<string>(props.selectedUserLastName);

  // const getNWEAStudentByProfileId = (
  //   profileId: number,
  //   pageNumber: number,
  //   pageSize: number
  // ) => {
  //   getUnmappedNWEAStudentByProfileId(
  //     profileId,
  //     pageNumber,
  //     pageSize,
  //     nweaId,
  //     firstName,
  //     lastName
  //   ).then((response) => {
  //     if (response.data.data.length > 0) {
  //       setTotalRecords(response.data.totalRecords);
  //       const unmappedStudents: Array<any> = [];
  //       response.data.data.forEach((nweaStudent) => {
  //         unmappedStudents.push(nweaStudent);
  //       });
  //       setNWEAStudents(unmappedStudents);
  //     } else {
  //       setNWEAStudents([]);
  //     }
  //   });
  // };

  const searchNWEAStudents = (
    userId: number,
    nweaId: string,
    firstName: string,
    lastName: string,
    pageNumber: number,
    pageSize: number
  ) => {
    searchUnmappedNWEAStudents(
      userId,
      nweaId,
      firstName,
      lastName,
      pageNumber,
      pageSize
    ).then((response) => {
      if (response.data.data.length > 0) {
        setTotalRecords(response.data.totalRecords);
        const unmappedStudents: Array<any> = [];
        response.data.data.forEach((nweaStudent) => {
          unmappedStudents.push(nweaStudent);
        });
        setNWEAStudents(unmappedStudents);
      } else {
        setNWEAStudents([]);
      }
    });
  };

  useEffect(() => {
    searchNWEAStudents(
      props.selectedUserId,
      nweaId,
      firstName,
      lastName,
      pageNumber,
      pageSize
    );
  }, [pageSize, pageNumber, resetPageNumber]);

  const update = async (
    studentProfileId: any,
    nweaId: any,
    studentUserId: any
  ) => {
    await updateNWEAId(studentProfileId, nweaId, props.userId, studentUserId);
    props.updateUnmappedStudents(
      props.userId,
      DEFAULT_PAGE_NUMBER,
      DEFAULT_PAGE_SIZE
    );
  };

  const onPageChange = (pageNo) => {
    setPageNumber(pageNo);
  };

  const onPageSizeChange = (pageSize) => {
    setPageNumber(DEFAULT_PAGE_NUMBER);
    setPageSize(pageSize);
    setResetPageNumber(true);
  };

  return (
    <div className="fixed inset-0 z-40 flex items-center bg-black bg-opacity-50 sm:items-center sm:justify-center">
      <div
        className="w-full flex flex-col justify-between overflow-hidden h-auto max-h-80percent bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:max-w-5xl"
        role="dialog"
      >
        <header>
          <div className="px-4 py-4 bg-gray-50">
            <div className="flex items-start justify-between space-x-3 border-b border-gray-300">
              <div className="space-y-1">
                <div className="text-lg font-medium text-gray-900">
                  <div className="flex items-center mb-2">
                    <h2 className="text-lg text-gray-700 mr-5">Map Student</h2>
                    {props.studentId && (
                      <span className="bg-primary-blue rounded-lg text-white text-xs px-1.5 mr-2">
                        Student ID - {props.studentId}
                      </span>
                    )}
                    <div className="bg-gray-100 border border-gray-400 rounded-lg px-1 flex">
                      <span className="text-xs font-normal text-gray-500 mr-1">
                        {props.selectedUserFirstName}
                      </span>
                      <span className="text-xs font-normal text-gray-500">
                        {props.selectedUserLastName}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="h-7 flex items-center">
                <button
                  type="button"
                  className="text-gray-400 hover:text-gray-500"
                  onClick={() => props.closeModal()}
                >
                  <span className="sr-only">Close panel</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </header>

        <div className="w-full relative overflow-auto px-4 py-3">
          <div>
            <div className="mb-4">
              <div className="flex flex-col w-full">
                <div className="flex flex-col md:flex-row flex-wrap gap-6 md:gap-6 lg:gap-x-6 lg:gap-y-0 xl:gap-6 mt-1 md:items-center">
                  <div>
                    <label className="block mb-1 text-sm text-gray-500 whitespace-nowrap">
                      NWEA Id
                    </label>
                    <input
                      type="text"
                      value={nweaId}
                      maxLength={60}
                      onChange={(e) => {
                        setNWEAId(("" + e.target.value).trim());
                      }}
                      className="border px-5 py-2 px-4 block w-96 shadow-sm text-gray-900 focus:ring-primary-violet focus:border-primary-violet border border-gray-300 rounded-md"
                    />
                  </div>
                  <div>
                    <label className="block mb-1 text-sm text-gray-500 whitespace-nowrap">
                      First Name
                    </label>
                    <input
                      type="text"
                      value={firstName}
                      maxLength={30}
                      onChange={(e) => {
                        setFirstName(("" + e.target.value).trim());
                      }}
                      className="border px-5 py-2 px-4 block text-sm shadow-sm text-gray-900 focus:ring-primary-violet focus:border-primary-violet border border-gray-300 rounded-md"
                    />
                  </div>
                  <div>
                    <label className="block mb-1 text-sm text-gray-500 whitespace-nowrap">
                      Last Name
                    </label>
                    <input
                      type="text"
                      value={lastName}
                      maxLength={30}
                      onChange={(e) => {
                        setLastName(("" + e.target.value).trim());
                      }}
                      className="border px-5 py-2 px-4 block text-sm shadow-sm text-gray-900 focus:ring-primary-violet focus:border-primary-violet border border-gray-300 rounded-md"
                    />
                  </div>
                  <div className="flex-shrink-0 mt-6 ml-0 lg:ml-auto xl:ml-0">
                    <div className="space-x-3 flex justify-end">
                      <button
                        className="align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 rounded-lg text-sm text-white border border-transparent bg-primary-green focus:shadow-outline-purple sm:w-auto"
                        type="button"
                        onClick={() =>
                          searchNWEAStudents(
                            props.selectedUserId,
                            nweaId,
                            firstName,
                            lastName,
                            DEFAULT_PAGE_NUMBER,
                            DEFAULT_PAGE_SIZE
                          )
                        }
                      >
                        Filter
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="shadow overflow-x-auto">
              <table className="table w-full ">
                <thead className="mb-5">
                  <tr className="bg-light-violet  text-white py-3 px-3">
                    <th
                      scope="col"
                      className="text-left  p-3 text-sm font-normal"
                    >
                      <div>First Name</div>
                    </th>
                    <th
                      scope="col"
                      className="text-left  p-3 text-sm font-normal"
                    >
                      <div>Last Name</div>
                    </th>
                    <th
                      scope="col"
                      className="text-left  p-3 text-sm font-normal"
                    >
                      <div>NWEA Id</div>
                    </th>
                    <th
                      scope="col"
                      className="text-left  p-3 text-sm font-normal"
                    >
                      <div>Action</div>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {nweaStudents?.map((student, i) => (
                    <tr
                      className={
                        i % 2 === 0
                          ? " hover:bg-light-violet/20 transition ease-in-out duration-300 bg-white"
                          : "hover:bg-light-violet/20 transition ease-in-out duration-300 bg-light-violet/10"
                      }
                    >
                      <td className="p-3 text-sm text-gray-500">
                        {student.nweaFirstName}
                      </td>
                      <td className="p-3 text-sm text-gray-500">
                        {student.nweaLastName}
                      </td>
                      <td className="p-3 text-sm text-gray-500">
                        {student.nweaId}
                      </td>
                      <td className="p-3 text-sm text-gray-500">
                        <button
                          className="text-indigo-500"
                          type="button"
                          onClick={() => {
                            update(
                              props.studentProfileId,
                              student.nweaId,
                              props.selectedUserId
                            );
                          }}
                        >
                          Map
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="flex px-4 pb-4">
          {nweaStudents?.length === 0 && <NoData />}
          {nweaStudents?.length !== 0 && (
            <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between border-t-2 pt-4 mt-3">
              <Pagination
                totalRecords={totalRecords}
                pageNumber={pageNumber}
                pageSize={pageSize}
                onPageChange={onPageChange}
                reset={resetPageNumber}
                setPageNumber={setPageNumber}
              />
              <PageSize
                pageSize={pageSize}
                onPageSizeChange={onPageSizeChange}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default connect((state: any) => {
  return {
    masterData: state.masterData,
    userContext: state.userContext,
  };
}, {})(NWEAMappingModal);

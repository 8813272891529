import moment from "moment";
import React from "react";
import { LockIcon, RightArrowIcon } from "../../../../../assets/icons";
import { getActivityName } from "../../../../../utils/assignmentHelper";
import { useHistory } from "react-router-dom";
import RouteConstant from "../../../../../utils/constant/routeConstant";
import constant from "../../../../../utils/constant/constant";
import { paramEncode } from "../../../../../utils/urlHelper";
import { useTranslation } from "react-i18next";
import { redirect } from "../../../../../utils/routeRedirectHelper";

export const CompleteSavedActivityGrid = ({
  savedActivityData,
  plLanguageId,
}: any) => {
  const history = useHistory();
  const { t } = useTranslation();
  function startAssignmentActivity(
    subjectId: number,
    assignmentActivityId: number,
    assignmentActivityName: string,
    multipleAttempt: number,
    lernosityActivityIdEN?: string,
    lernosityActivityIdES?: string,
    assignmentId?: number,
    assignmentType?: string
  ) {
    if (lernosityActivityIdEN || lernosityActivityIdES) {
      let multipleAttemptAllowed = multipleAttempt > 0 ? true : false;
      history.push(
        RouteConstant.MiddleSchool.StudentAssignmentActivityMS.replace(
          ":assignmentActivityId",
          encodeURI(btoa(assignmentActivityId.toString()))
        )
          .replace(
            ":lernosityActivityIdEN",
            encodeURI(btoa(lernosityActivityIdEN ? lernosityActivityIdEN : ""))
          )
          .replace(
            ":lernosityActivityIdES",
            encodeURI(btoa(lernosityActivityIdES ? lernosityActivityIdES : ""))
          )
          .replace(
            ":assignmentType",
            encodeURI(btoa(assignmentType ? assignmentType : ""))
          )
          .replace(
            ":assignmentActivityName",
            encodeURI(
              paramEncode(assignmentActivityName ? assignmentActivityName : "")
            )
          )

          .replace(
            ":assignmentId",
            encodeURI(btoa(assignmentId ? assignmentId.toString() : ""))
          )
          .replace(":subjectId", encodeURI(btoa(subjectId.toString())))
          .replace(
            ":multipleAttemptAllowed",
            encodeURI(btoa(multipleAttemptAllowed.toString()))
          )
      );
    }
  }

  function startLiftOffDiagnosticAssignment(
    refId: string,
    diagnosticAssignmentStatus: string,
    liftOffHeader: string,
    liftOffHeaderId: string,
    activityStatusId: string,
    subjectId: number,
    subjectName: string
  ) {
    const route = RouteConstant.LiftOff.MiddleSchool.DiagnosticActivity;
    history.push(
      route
        .replace(":activityId", encodeURI(btoa(refId)))
        .replace(":activityStatus", encodeURI(btoa(diagnosticAssignmentStatus)))
        .replace(":activityHeader", encodeURI(btoa(liftOffHeader)))
        .replace(":activityHeaderId", encodeURI(btoa(liftOffHeaderId)))
        .replace(":activityStatusId", encodeURI(btoa(activityStatusId)))
        .replace(":subjectId", encodeURI(btoa(subjectId.toString())))
        .replace(":subjectName", encodeURI(btoa(subjectName)))
    );
  }

  function startMyGalaxyAssignment(
    standardId,
    standardName,
    gameId,
    rocketId,
    blasterId,
    blasterType,
    subjectId,
    subjectName,
    isStandardGrouped
  ) {
    const route = RouteConstant.LiftOff.MiddleSchool.MyGalaxyActivity;
    history.push(
      route
        .replace(":standardId", encodeURI(btoa(standardId.toString())))
        .replace(":standardName", encodeURI(paramEncode(standardName)))
        .replace(":gameId", encodeURI(btoa(gameId.toString())))
        .replace(
          ":rocketId",
          encodeURI(btoa(rocketId ? rocketId.toString() : "0"))
        )
        .replace(
          ":blasterId",
          encodeURI(btoa(blasterId ? blasterId.toString() : "0"))
        )
        .replace(
          ":blasterType",
          encodeURI(btoa(blasterType ? blasterType.toString() : "0"))
        )
        .replace(
          ":subjectId",
          encodeURI(btoa(subjectId ? subjectId.toString() : "0"))
        )
        .replace(":subjectName", encodeURI(btoa(subjectName ?? "")))
        .replace(":isStandardGrouped", encodeURI(btoa(isStandardGrouped ?? "")))
    );
  }

  function startAssessmentActivity(code, subjectId) {
    redirect({
      urlTemplate: RouteConstant.MiddleSchool.StudentAssessmentActivity,
      code: code,
      subjectId: subjectId,
      multipleAttemptAllowed: false,
      history: history,
    });
  }

  return (
    <>
      <div className="relative overflow-auto rounded-lg rounded-tl-none rounded-tr-none sm:rounded-tr-lg">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="uppercase">
            <tr className="divide-x">
              <th
                scope="col"
                className="bg-gray-100 top-0 xl:px-2 2xl:px-6 py-4 px-6 text-left text-sm font-semibold text-dark-teal uppercase tracking-wider"
              >
                {t("Assignments.SavedDate")}
              </th>
              <th
                scope="col"
                className="bg-gray-100 top-0 xl:px-2 2xl:px-6 py-4 px-6 text-left text-sm font-semibold text-dark-teal uppercase tracking-wider"
              >
                {t("Assignments.DueDate")}
              </th>
              <th
                scope="col"
                className="top-0 bg-gray-100 xl:px-2 2xl:px-6 py-4 px-6 text-left text-sm font-semibold text-dark-teal uppercase tracking-wider"
              >
                {t("Assignments.Test")} {t("Assignments.Name")}
              </th>
              <th
                scope="col"
                className="bg-gray-100 top-0 xl:px-2 2xl:px-6 py-4 px-6 text-left text-sm font-semibold text-dark-teal uppercase tracking-wider"
              >
                {t("Assignments.Questions")}
              </th>
              <th
                scope="col"
                className="top-0 bg-gray-100 xl:px-2 2xl:px-6 py-4 px-6 text-left text-sm font-semibold text-dark-teal uppercase tracking-wider"
              >
                {t("Assignments.Progress")}
              </th>
              <th
                scope="col"
                className="top-0 bg-gray-100 xl:px-2 2xl:px-6 py-4 px-6 text-left text-sm font-semibold text-dark-teal uppercase tracking-wider"
              >
                <span className="sr-only">{t("Assignments.ActionButton")}</span>
              </th>
            </tr>
          </thead>
          <tbody className="bg-white">
            {savedActivityData?.map((savedActivity, index) => {
              const d =
                moment(savedActivity.startDate).format("MM/DD/YYYY") ===
                "01/01/0001"
                  ? ""
                  : moment(savedActivity.startDate).format("MM/DD/YYYY");
              const dueDate = moment(savedActivity.endDate).format(
                "MM/DD/YYYY"
              );

              return (
                <React.Fragment key={index}>
                  <tr
                    className={`border-b-2 shadow ${
                      index % 2 === 0 ? "bg-white" : "bg-gray-100"
                    }`}
                  >
                    <td className="px-6 xl:px-2 2xl:px-6 py-4">
                      <div className="text-sm font-semibold text-gray-500">
                        {d}
                      </div>
                      <div className="text-xs text-site-orange font-semibold capitalize">
                        {savedActivity.activityType !== "LiftOff Diagnostic" &&
                        savedActivity.activityType !== "LiftOff My Galaxy" &&
                        savedActivity.activityType !== "Assessment"
                          ? savedActivity.remainsDays.toFixed(0) +
                            t("Assignments.DaysRemaining")
                          : ""}
                      </div>
                    </td>

                    <td className="px-6 xl:px-2 2xl:px-6 py-4">
                      <div
                        className={`border-l-4  pl-2 ${
                          savedActivity.days_remaining === 0
                            ? "border-site-pink"
                            : "border-blue"
                        }`}
                      >
                        <div
                          className={`text-sm font-semibold ${
                            savedActivity.days_remaining === 0
                              ? "text-site-pink"
                              : "text-blue"
                          }`}
                        >
                          {savedActivity.activityType !==
                            "LiftOff Diagnostic" &&
                          savedActivity.activityType !== "LiftOff My Galaxy" &&
                          savedActivity.activityType !== "Assessment"
                            ? dueDate
                            : ""}
                        </div>
                        {savedActivity.activityType !== "LiftOff Diagnostic" &&
                          savedActivity.activityType !== "LiftOff My Galaxy" &&
                          savedActivity.activityType !== "Assessment" &&
                          savedActivity.days_remaining === 0 && (
                            <div className="text-xs text-gray-500 font-semibold capitalize">
                              {t("Assignments.PastDue")}
                            </div>
                          )}
                      </div>
                    </td>

                    <td className="px-6 py-4 border-r-2">
                      <div className="text-sm text-gray-800 capitalize">
                        <h6 className="font-semibold">
                          {savedActivity.assignmentName}
                        </h6>
                        <span>
                          {savedActivity.activityType !==
                            "LiftOff Diagnostic" &&
                          savedActivity.activityType !== "LiftOff My Galaxy" &&
                          savedActivity.activityType !== "Assessment"
                            ? getActivityName(savedActivity, plLanguageId)
                            : savedActivity.activityType}
                        </span>
                      </div>
                    </td>
                    <td className="px-6 py-4 border-r-2 border-gray-100">
                      <div className="flex xl:justify-center">
                        <div className="flex w-6 h-6 px-4 py-4 bg-dark-teal text-white font-medium rounded-full justify-center items-center text-sm">
                          {savedActivity.activityType !== "LiftOff Diagnostic"
                            ? savedActivity.questionCount
                            : "NA"}
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-4 text-sm text-gray-500 border-r-2 border-gray-100">
                      <div>
                        <span className="mt-2">
                          {savedActivity.activityType !== "LiftOff Diagnostic"
                            ? savedActivity.progress + "%"
                            : "NA"}
                        </span>
                        {savedActivity.activityType !==
                          "LiftOff Diagnostic" && (
                          <div className="w-full block relative">
                            <div className="w-full h-2 bg-gray-300 rounded-md"></div>
                            <div className="w-full h-2 bg-chart-blue rounded-md absolute top-0 z-0 ">
                              <span
                                style={{
                                  width: `${savedActivity.progress}%`,
                                }}
                                className=" bg-orange-dark h-2 absolute left-0 top-0 rounded-md"
                              ></span>
                            </div>
                          </div>
                        )}
                      </div>
                    </td>
                    <td className="px-6 py-4">
                      <div className="flex xl:justify-center">
                        {savedActivity.isLocked ? (
                          <LockIcon />
                        ) : (
                          <a
                            href="#!"
                            title={
                              savedActivity.disabled === 1
                                ? "Disabled"
                                : "Click to continue"
                            }
                            onClick={() => {
                              savedActivity.activityType ===
                              "LiftOff Diagnostic"
                                ? startLiftOffDiagnosticAssignment(
                                    plLanguageId === constant.Languages.Spanish
                                      ? savedActivity.learnosityIdSpanish
                                      : savedActivity.learnosityIdEnglish,
                                    "Inprogress",
                                    savedActivity.assignmentName,
                                    savedActivity.assignmentId,
                                    "1",
                                    savedActivity.subjectId,
                                    savedActivity.subjectName
                                  )
                                : savedActivity.activityType ===
                                  "LiftOff My Galaxy"
                                ? startMyGalaxyAssignment(
                                    savedActivity.standardId,
                                    savedActivity.standardName,
                                    savedActivity.gameId,
                                    savedActivity.rocketId,
                                    savedActivity.blasterId,
                                    savedActivity.blasterType,
                                    savedActivity.subjectId,
                                    savedActivity.subjectName,
                                    savedActivity.isStandardGrouped
                                  )
                                : savedActivity.activityType === "Assessment"
                                ? startAssessmentActivity(
                                    savedActivity.code,
                                    savedActivity.subjectId
                                  )
                                : startAssignmentActivity(
                                    savedActivity.subjectId,
                                    savedActivity.assignmentActivityId,
                                    getActivityName(
                                      savedActivity,
                                      plLanguageId
                                    ),
                                    savedActivity.multipleAttempt ?? 0,
                                    savedActivity.learnosityIdEnglish,
                                    savedActivity.learnosityIdSpanish,
                                    savedActivity.assignmentId,
                                    savedActivity.activityType
                                  );
                            }}
                            className={`bg-blues ${
                              savedActivity.days_remaining === 0
                                ? "bg-site-pink"
                                : ""
                            } ${
                              savedActivity.disabled === 1 &&
                              "pointer-events-none"
                            }
                              hover:bg-secondary-teal transition-all hover:text-gray-100 inline-block rounded-lg whitespace-nowrap text-sm px-5 py-2 text-white cursor-pointer`}
                          >
                            {t("Assignments.Complete")}
                            <RightArrowIcon className="h-6 w-6 ml-2 inline-block" />
                          </a>
                        )}
                      </div>
                    </td>
                  </tr>
                </React.Fragment>
              );
            })}
            {savedActivityData.length === 0 && (
              <tr className="bg-white">
                <td className="px-6 py-4" colSpan={6}>
                  {t("Assignments.NoRecordsFound")}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

import { Fragment, useEffect, useState } from "react";
import { Collapse } from "react-collapse";
import { useTranslation } from "react-i18next";
import { getSkillStandard } from "../../../../../api/student/skillPracticeApi";
import Profile from "../../../../../model/users/profile";
import { StudyType } from "../../../../../types/type";
import MyGalaxySubject from "../../shared/liftoff/myGalaxySubject";
import SkillSubject from "../../skillPractice/elementary/skillSubject";
import CannedTestSubject from "../cannedTests/cannedTestSubject";
import StudyPlanSubject from "../studyplan/studyPlanSubject";
import { useLocation } from "react-router-dom";

interface collapsibleButtonProps {
  studyType: StudyType;
  profile?: Profile;
  onClick: (subjectId: number, studyType: string, subjectName: string) => void;
  expand?: boolean;
  selectedSubjectId?: number;
  newAssignmentsCount?: number;
}

function CollapsibleButton(props: collapsibleButtonProps) {
  const location: any = useLocation();
  let subjectId = location.state?.subjectId
    ? location.state?.subjectId
    : undefined;
  const [expandContent, setExpandContent] = useState(props.expand ?? false);
  const { t } = useTranslation();
  const [showSkillPractice, setShowSkillPractice] = useState<boolean>(false);

  var dicStudyTypeColorMapping = {
    Assignments: "#00b1fb",
    Diagnostics: "#d2ff00",
    "My Study Plan": "#34D9A5",
    "Skill Practice": "#fe3bac",
    "Alien Arena": "#ff4655",
    "My Galaxies": "#fe3bac",
    "Graded Work": "#fb923c",
  };

  function getStudyTypeName(studyType: string) {
    switch (studyType) {
      case "Assignments":
        return t("StudentElementaryHome.Assignments");
      case "Diagnostics":
        return t("StudentElementaryHome.Diagnostics");
      case "My Study Plan":
        return t("StudentElementaryHome.MyStudyPlan");
      case "Skill Practice":
        return t("StudentElementaryHome.SkillPractice");
      case "Alien Arena":
        return t("StudentElementaryHome.AlienArena");
      case "My Galaxies":
        return t("LiftOffElementaryHome.MyGalaxy");
      case "Graded Work":
        return t("Shared.StudentSideBar.GradedWork");
    }
  }

  const handleExpandContent = (studyType: StudyType) => {
    if (studyType === "Alien Arena") {
      props.onClick(0, props.studyType, "");
    } else if (studyType === "Graded Work") {
      props.onClick(0, props.studyType, "");
    } else {
      setExpandContent(!expandContent);
    }
  };

  function checkSkillPracticeExists() {
    getSkillStandard(0, 0, props.profile?.gradeId ?? 0).then((d) => {
      if (d.data.data.length > 0) {
        setShowSkillPractice(true);
      }
    });
  }

  useEffect(() => {
    if (props.studyType === "Skill Practice") checkSkillPracticeExists();
  }, []);

  useEffect(() => {
    if (location.state?.redirectedFrom && props.studyType === "My Study Plan") {
      setExpandContent(true);
    }
  }, [location.state?.redirectedFrom]);

  return (
    <Fragment>
      {((props.studyType === "Skill Practice" && showSkillPractice) ||
        props.studyType !== "Skill Practice") && (
        <>
          <div
            className={`block bg-[${
              dicStudyTypeColorMapping[props.studyType]
            }] py-2 px-3 sm:p-4 rounded-2xl`}
          >
            <div
              className="cursor-pointer flex items-center justify-between"
              onClick={() => {
                if (props.studyType === "Assignments") {
                  props.onClick(0, props.studyType, "");
                } else {
                  handleExpandContent(props.studyType);
                }
              }}
            >
              <h2 className="text-lg py-1">
                {getStudyTypeName(props.studyType)}
              </h2>
              {props.studyType === "Assignments" &&
                props.newAssignmentsCount !== undefined &&
                props.newAssignmentsCount > 0 && (
                  <span
                    id="ribbon"
                    className="bg-[#F15A27] flex items-center text-xs text-white rounded-xl shadow px-1.5 py-1 font-bold"
                  >
                    {t("StudyPlan.New")}
                  </span>
                )}
            </div>

            {props.studyType === "Skill Practice" && showSkillPractice && (
              <Collapse isOpened={expandContent}>
                <SkillSubject
                  isOpened={expandContent}
                  onSubjectSelected={(
                    skillSubjectId: number,
                    subjectName: string
                  ) => {
                    props.onClick(skillSubjectId, props.studyType, subjectName);
                  }}
                  profile={props.profile}
                />
              </Collapse>
            )}
            {props.studyType === "My Study Plan" && (
              <Collapse isOpened={expandContent}>
                <StudyPlanSubject
                  isOpened={expandContent}
                  onSubjectSelected={(
                    subjectId: number,
                    subjectName: string
                  ) => {
                    props.onClick(subjectId, props.studyType, subjectName);
                  }}
                  selectedSubjectId={
                    !subjectId ? props.selectedSubjectId : subjectId
                  }
                />
              </Collapse>
            )}
            {props.studyType === "Diagnostics" && (
              <Collapse isOpened={expandContent}>
                <CannedTestSubject
                  isOpened={expandContent}
                  onSubjectSelected={(
                    subjectId: number,
                    subjectName: string
                  ) => {
                    props.onClick(subjectId, props.studyType, subjectName);
                  }}
                />
              </Collapse>
            )}
            {props.studyType === "My Galaxies" && (
              <Collapse isOpened={expandContent}>
                <MyGalaxySubject
                  isOpened={expandContent}
                  onSubjectSelected={(
                    subjectId: number,
                    subjectName: string
                  ) => {
                    props.onClick(subjectId, props.studyType, subjectName);
                  }}
                />
              </Collapse>
            )}
          </div>
        </>
      )}
    </Fragment>
  );
}

export default CollapsibleButton;

import { XIcon } from "@heroicons/react/outline";
import { Select } from "@windmill/react-ui";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { getContentArea } from "../../../../../../api/contentArea/contentArea";
import { ClassApi } from "../../../../../../api/teacher/classManagementApi";
import PagingResponse from "../../../../../../model/common/pagingResponse";
import ClassStudentModel from "../../../../../../model/teacher/classManagement/classStudentModel";
import { UserContextState } from "../../../../../../redux/actions/userContextAction";
import {
  DEFAULT_PAGE_NUMBER,
  DEFAULT_PAGE_SIZE,
} from "../../../../../../utils/pagingConstant";
import ConfirmationDialog from "../../../../../shared/confirmationDialog";
import InfoMessage from "../../../../../shared/infoMessage";
import PageSize from "../../../../../shared/pagination/pageSize";
import Pagination from "../../../../../shared/pagination/pagination";
import { getNameLabel } from "../../../../../../utils/helper";
import Loader from "../../../../../shared/loader";

interface TransferStudentProps {
  showPopUp: (show: boolean) => void;
  sourceClassId: number;
  sourceClassName: string;
  userContext?: UserContextState;
  studentTransferCallback: () => void;
}

const TransferStudentPopup = (props: TransferStudentProps) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [contentArea, setContentArea] = useState<Array<any>>();
  const [classes, setClasses] = useState<Array<any>>();
  const [destinationClass, setDestinationClass] = useState<number>(0);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [pageNumber, setPageNumber] = useState(DEFAULT_PAGE_NUMBER);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [students, setClassStudent] =
    useState<PagingResponse<ClassStudentModel>>();
  const [resetPageNumber, setResetPageNumber] = useState<boolean>(false);
  const [selectedTransferStudents, setSelectedTransferStudents] = useState<Array<number>>(
    []
  );
  useEffect(() => {
    getAllContentArea();
  }, []);

  useEffect(() => {
    getClassStudent();
  }, [pageNumber, pageSize]);

  useEffect(() => {
    setIsDisabled(selectedTransferStudents.length === 0);
  }, [selectedTransferStudents]);

  function getClassStudent(): void {
    setLoading(true);
    ClassApi.getClassStudent(props.sourceClassId, pageNumber, pageSize).then(
      (d) => {
        let students = d.data.data?.map((student, index) => {
          if (selectedTransferStudents.indexOf(student.userId) > -1) {
            student.isSelected = true;
          } else {
            student.isSelected = false;
          }
          return student;
        });
        d.data.data = students;
        setClassStudent(d.data);
        setLoading(false);
      }
    );
  }

  function getAllContentArea() {
    getContentArea().then((d) => {
      setContentArea(d.data.data);
      getClasses(d.data.data[0].contentAreaId);
    });
  }
  function getClasses(contentAreaId: number) {
    ClassApi.getClassListByContentArea(
      contentAreaId,
      props.userContext?.schoolAccountId
    ).then((d) => {
      let classesWithoutRostering = d.data.filter(classEntry => {
        return !classEntry.hasRostering;
      })
      setClasses(classesWithoutRostering);
      if (d.data.length > 0) setDestinationClass(d.data[0].classId);
    });
    setLoading(false);
  }
  function confirmationCancelClose() {
    setOpenConfirmation(false);
  }
  function confirmationOkClose(callback: () => void) {
    ClassApi.transferClassStudents(
      props.sourceClassId,
      destinationClass,
      selectedTransferStudents
    ).then((d) => {
      var untransferredStudents = d.data.filter(
        (t) => !t.isTransferred
      );
      if (
        untransferredStudents !== undefined &&
        untransferredStudents !== null &&
        untransferredStudents.length
      ) {
        toast.warning(
          "Student transfer failed. Can not transfer " +
            untransferredStudents.length +
            " students."
        );
      } else {
        toast.success("Student transferred successfully");
      }
      callback();
    });

    props.showPopUp(false);
  }
  function selectStudent(userId: any) {
    let selectedStudents = [...selectedTransferStudents];
    if (selectedStudents.includes(userId)) {
      selectedStudents = selectedStudents.filter((ds) => ds !== userId);
      setSelectedTransferStudents(selectedStudents);
    } else {
      selectedStudents.push(userId);
      setSelectedTransferStudents(selectedStudents);
    }
  }

  function selectAllStudent(): void {
    let selectedStudents = Array<number>();
    students?.data.map(student => {
      if (!selectAll) {
        selectedStudents.push(student.userId);
      }
    });
    setSelectedTransferStudents(selectedStudents);
    setSelectAll(!selectAll);
  }

  const onPageSizeChange = (pageSize) => {
    setPageNumber(DEFAULT_PAGE_NUMBER);
    setPageSize(pageSize);
    setResetPageNumber(true);
  };

  function transferStudents() {
    if (isDisabled) {
      toast.warning('You must select one or more students to import data');
      return;
    }
    if (
      destinationClass === undefined ||
      destinationClass === null ||
      destinationClass === 0
    ) {
      toast.warning("Please select a destination class to transfer students");
    } else {
      setOpenConfirmation(true);
    }
  }
  return (
    <div className="fixed inset-0 z-40 flex items-center bg-black bg-opacity-50 sm:items-center sm:justify-center">
      <div
        className="w-full flex flex-col justify-between overflow-auto h-auto max-h-80percent bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:max-w-5xl"
        role="dialog"
      >
        {loading && <Loader />}
        <header>
          {/* Header */}
          <div className="px-4 py-4 bg-gray-50">
            <div className="flex items-start justify-between space-x-3">
              <div className="space-y-1">
                <div className="text-lg font-medium text-gray-900">
                  Transfer Student
                </div>
              </div>
              <div className="h-7 flex items-center">
                <button
                  type="button"
                  className="text-gray-400 hover:text-gray-500"
                  onClick={() => props.showPopUp(false)}
                >
                  <span className="sr-only">Close panel</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </header>
        <div className="w-full relative px-4 py-1">
          <div className="p-1">
            <InfoMessage message="Select students to transfer from your class to another." />
          </div>
          {/* <!-- Divider container -->  */}
          <div className="p-3 sm:py-0 sm:space-y-0 sm:divide-y sm:divide-gray-200 text-sm text-gray-700 dark:text-gray-400">
            <div className="space-y-1 px-2 sm:space-y-0 sm:grid sm:grid-cols-1 sm:gap-4 sm:py-2">
              <label className="font-semibold">
                Current Class{" "}
                <span className="font-semibold text-gray-500">
                  {props.sourceClassName}
                </span>{" "}
              </label>
              <table className="table w-full overflow-hidden">
                <thead className="mb-5">
                  <tr className="bg-light-violet  text-white py-3 px-3 overflow-hidden">
                    <th className="text-left  p-3 text-sm font-normal w-4">
                      {" "}
                      <label
                        x-radio-group-option=""
                        className="relative cursor-pointer"
                      >
                        <input
                          type="checkbox"
                          x-model="value"
                          name="privacy_setting"
                          value="Private to Project Members"
                          className="h-4 w-4 mt-0.5 cursor-pointer text-indigo-600 border-gray-300 focus:ring-indigo-500"
                          aria-labelledby="privacy-setting-1-label"
                          aria-describedby="privacy-setting-1-description"
                          checked={selectAll}
                          onChange={selectAllStudent}
                        />
                      </label>
                    </th>
                    <th className="text-left  p-3 text-sm font-normal">
                      {" "}
                      Student{" "}
                    </th>
                    <th className="text-left p-3 text-sm font-normal">
                      {" "}
                      Grade{" "}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {students?.data?.map((student, index) => {
                    return (
                      <tr
                        key={student.userId}
                        className={`hover:bg-light-violet/20 transition ease-in-out duration-300 ${
                          index % 2 === 0 ? "bg-white" : "bg-light-violet/10"
                        }`}
                      >
                        <td className=" mt-5 p-3 text-center">
                          <input
                            type="checkbox"
                            key={student.userId}
                            checked={selectedTransferStudents.includes(student.userId)}
                            onChange={() => selectStudent(student.userId)}
                          />
                        </td>
                        <td className="text-sm mt-5  p-3">
                          {getNameLabel(student.lastName, student.firstName)}
                        </td>
                        <td className="text-sm mt-5  p-3">{student.grade}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>

              <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between border-t py-4 mt-2 pb-10">
                {students?.totalRecords !== undefined &&
                  students?.totalRecords !== 0 && (
                    <Pagination
                      totalRecords={students?.totalRecords}
                      pageSize={pageSize}
                      pageNumber={pageNumber}
                      onPageChange={(pageNo) => {
                        setPageNumber(pageNo);
                      }}
                      reset={resetPageNumber}
                    />
                  )}
                {students?.totalRecords !== undefined &&
                    students?.totalRecords !== 0 && (
                  <PageSize
                    pageSize={pageSize}
                    onPageSizeChange={onPageSizeChange}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-end px-4 grid grid-cols-12 space-y-2 sm:space-y-0 sm:space-x-6 border-t">
          <div className="col-span-12 sm:col-span-6">
            <label
                htmlFor="contentArea"
                className="block text-sm text-gray-500 sm:mt-px sm:pt-2"
            >
              Content Area
            </label>
            <Select
                id="contentArea"
                onChange={(e) => {
                  getClasses(Number.parseInt(e.target.value));
                }}
                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:ring-primary-violet focus:border-primary-violet sm:text-sm"
            >
              {contentArea?.map((item) => {
                return (
                    <option key={item.contentAreaId} value={item.contentAreaId}>
                      {item.name}
                    </option>
                );
              })}
            </Select>
          </div>

          <div className="col-span-12 sm:col-span-6">
            <label
                htmlFor="newClass"
                className="block text-sm  text-gray-500 sm:mt-px sm:pt-2"
            >
              New Class
            </label>
            <Select
                id="newClass"
                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:ring-primary-violet focus:border-primary-violet sm:text-sm"
                onChange={(e) => {
                  setDestinationClass(Number.parseInt(e.target.value));
                }}
            >
              {classes?.map((item, iterator) => {
                if(item.classId != props.sourceClassId)
                return (
                    <option key={item.classId} value={item.classId} selected={iterator === 0 ? true : false }>
                      {item.name} - {item.teacherName}
                    </option>
                );
              })}
            </Select>
          </div>
        </div>
        {/* <!-- Action Buttons -->  */}
        <footer className="flex items-center justify-end px-4 py-4 sm:flex-row bg-gray-50 dark:bg-gray-800 mt-4">
          <div className="space-x-3 flex justify-end">
            <button
              className={classNames(
                "disabled:opacity-50 align-bottom inline-flex items-center justify-center leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 rounded-lg text-sm text-white border border-transparent bg-primary-green focus:shadow-outline-purple w-full sm:w-auto",
                isDisabled ? "cursor-not-allowed" : "cursor-pointer"
              )}
              type="button"
              onClick={() => {
                transferStudents();
              }}
             >
              {" "}
              Transfer
            </button>
            <button
              className="align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 rounded-lg text-sm text-gray-600 border-gray-300 border dark:text-gray-400 focus:outline-none active:bg-transparent hover:border-gray-500 focus:border-gray-500 active:text-gray-500 focus:shadow-outline-gray w-full sm:w-auto"
              type="button"
              onClick={() => {
                props.showPopUp(false);
              }}
            >
              Cancel
            </button>
          </div>
        </footer>
      </div>

      <ConfirmationDialog
        open={openConfirmation}
        confirmationTitle="Please confirm"
        confirmationMessage="Are you sure you want to transfer students?"
        onCancelClick={confirmationCancelClose}
        onOkClick={() => confirmationOkClose(props.studentTransferCallback)}
        className="fixed z-50 inset-0 overflow-y-auto"
      ></ConfirmationDialog>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(TransferStudentPopup);

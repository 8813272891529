import React, { useState } from "react";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/database";
import "./chatRoom.css";
import ChatRoomProps from "../../../model/chat/chatRoomProps";
import StudentChatRoom from "./studentChat/studentChatRoom";
import TeacherChatRoom from "./teacherChat/teacherChatRoom";
function ChatRoom(props: ChatRoomProps) {
  const [teacherRoles] = useState([4, 5, 6, 7, 8]);
  const [studentRoleId] = useState(9);
  const isTeacher = (roleId: number) => {
    return teacherRoles.includes(roleId);
  };
  return (
    <>
      {props.roleId === studentRoleId && <StudentChatRoom {...props} />}
      {isTeacher(props.roleId) && <TeacherChatRoom {...props} />}
    </>
  );
}
export default ChatRoom;

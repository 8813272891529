import Route from "./model/common/route";
import RouteConstant from "./utils/constant/routeConstant";
import Constant from "./utils/constant/constant";
import LoginComponent from "./component/screen/login";
import LaunchAlienArena from "./component/screen/teacher/alienArena/launchAlienArena";
import ArenaStartSession from "./component/screen/teacher/alienArena/ArenaStartSession";
import AlienFinalResult from "./component/screen/teacher/alienArena/AlienFinalResult";
import SchoolSelectionComponent from "./component/screen/schoolSelection";
import ExternalGoogleLogin from "./component/screen/googleLogin/externalGoogleLogin";
import GoogleUnauthorized from "./component/screen/googleLogin/unauthorized";
import GoogleStudentLogin from "./component/screen/googleLogin/googleStudentLogin";
import GoogleTeacherLogin from "./component/screen/googleLogin/googleTeacherLogin";
import GoogleUserMapping from "./component/screen/googleLogin/googleUserMapping";
import GoogleUserLogin from "./component/screen/googleLogin/googleUserLogin";
import ExternalCanvasLogin from "./component/screen/canvasLogin/externalCanvasLogin";
import CanvasUserMapping from "./component/screen/canvasLogin/canvasUserMapping";
import CanvasUserLogin from "./component/screen/canvasLogin/canvasUserLogin";
import ForgotPasswordComponent from "./component/screen/forgotPassword";
import ContainerComponent from "./component/shared/container";
import ManageUsersComponent from "./component/screen/manageUsers";
import HelpComponent from "./component/screen/help";
import TrainingComponent from "./component/screen/training";
import NotAuthorizedComponent from "./component/screen/notAuthorized";
import teacherTrainingComponent from "./component/screen/teacherTraining";
import adminListComponent from "./component/screen/adminList";
import campusTrainingComponent from "./component/screen/campusTraining";
import BlankComponent from "./component/shared/blank";
import ImpersonateComponent from "./component/screen/impersonate";
import ContactUsComponent from "./component/screen/contactUs/contactUs";
import ParentLetterComponent from "./component/screen/parentLetter";
import VideoMessageManagement from "./component/screen/videoMessage/videoMessageManagement/videoMessageManagement";
import ProfileComponent from "./component/screen/profile/profileContainer";
import StudentComponent from "./component/screen/student/student";
import RequestSSOComponent from "./component/screen/requestSSO/requestSSO";
import KHome from "./component/screen/student/kindergarten/home/home";
import ELHome from "./component/screen/student/elementary/home/home";
import AssignmentCenter from "./component/screen/teacher/assignmentCenter/assignmentCenter";
import AssignmentBank from "./component/screen/teacher/assignment/assignmentBank";
import NewAssignment from "./component/screen/teacher/assignment/newAssignment";
import LeaderboardComponent from "./component/screen/student/elementary/leaderboard/leaderboard";
import SubjectHome from "./component/screen/student/kindergarten/study/subjectHome";
import ArenaKindergarten from "./component/screen/student/kindergarten/arena/alienArenaKindergarten";
import ArenaMiddleSchool from "./component/screen/student/middleSchool/arena/alienArenaMiddleSchool";
import ArenaElementary from "./component/screen/student/elementary/arena/alienArenaElementary";
import AssignmentsMiddleSchool from "./component/screen/student/middleSchool/assignments/assignments";
import MSGradedWork from "./component/screen/student/middleSchool/gradedWork/gradedWork";
import StudyPlanSubjects from "./component/screen/student/middleSchool/studyPlan/studyPlanSubject";
import ClassManagement from "./component/screen/teacher/classManagement/classManagement";
import ClassDetail from "./component/screen/teacher/classManagement/classDetail/classDetail";
import InstructionalResources from "./component/screen/teacher/instructionalResources/instructionalResources";
import MyStat from "./component/screen/student/elementary/myStats/myStat";
import { layoutType } from "./types/type";
import StudentAssessment from "./component/screen/student/shared/learnosityPlayer";
import Dashboard from "./component/screen/dashboard/home";
import ChangePassword from "./component/screen/forgotPassword/changePassword";
import manageUser from "./component/screen/manageUser/manageUser";
import ProgressReport from "./component/screen/teacher/progressReport/progressReport";
import UsageReport from "./component/screen/teacher/reports/usageReport/usageReport";
import StudentActivitySearchReport from "./component/screen/teacher/reports/studentActivitySearchReport/studentActivitySearchReport";
import TeacherUsageReport from "./component/screen/teacher/reports/teacherUsageReport/teacherUsageReport";
import LiftoffActivityReport from "./component/screen/teacher/reports/liftoffActivityReport/liftoffActivityReport";

import GradeBook from "./component/screen/teacher/reports/gradeBook/gradeBook";
import LiftoffGrowthReport from "./component/screen/teacher/reports/liftoffGrowthReport/liftoffGrowth";
import LiftoffDetailGrowthReport from "./component/screen/teacher/reports/liftoffDetailGrowth/liftoffDetailGrowth";
import LiftoffIndividualDiagnostics from "./component/screen/teacher/reports/liftoffIndividualDignostics/liftoffIndividualDignostics";
import DiagnosticStrengthWeaknessReport from "./component/screen/teacher/reports/diagnosticStrengthWeaknessReport/diagnosticStrengthWeaknessReport";
import AssessmentResultsReport from "./component/screen/teacher/reports/assessmentResultsReport/assessmentResultsReport";
import LiftoffDiagnosticReport from "./component/screen/teacher/reports/liftoffDiagnosticsReport/liftoffDiagnosticsReport";
import LiftoffGalaxySummaryReport from "./component/screen/teacher/reports/liftoffGalaxySummaryReport/liftoffGalaxySummary";
import LiftoffClassGalaxySummaryReport from "./component/screen/teacher/reports/liftoffClassGalaxySummaryReport/liftoffClassGalaxySummaryReport";

import LiftoffStandardDiagnostict from "./component/screen/teacher/reports/liftoffStandardDignostict/liftoffStandardDignostict";
import SessionActivity from "./component/screen/teacher/reports/sessionActivity/sessionActivity";
import LiftoffUsageReport from "./component/screen/teacher/reports/liftoffUsageReport/liftoffUsageReport";
import LiftoffUsageStudentLevelReport from "./component/screen/teacher/reports/liftoffUsageReport/liftoffUsageStudentLevelReport";
import GalaxyStarReport from "./component/screen/teacher/reports/galaxyStars/galaxyStarReport";
import LiftoffGalaxyStarReport from "./component/screen/teacher/reports/liftoffGalaxyStarReport/liftoffGalaxyStarReport";
import NewAssessment from "./component/screen/teacher/assessment/newAssessment";
import AssessmentResult from "./component/screen/teacher/assessment/assessmentResult";
import ResultReports from "./component/screen/teacher/reports/results/results";
import AlienArena from "./component/screen/teacher/alienArena/alienArena";
import EditAlienArena from "./component/screen/teacher/alienArena/alienArenaDetails/alienArenaDetails";
import AlienArenaSessions from "./component/screen/teacher/alienArena/alienArenaSessions";
import StudentProfile from "./component/screen/student/profile";
import MSHome from "./component/screen/student/middleSchool/home/home";
import AssessmentBank from "./component/screen/teacher/assessment/assessmentBank";
import SkillPracticeSubjects from "./component/screen/student/skillPractice/middleSchool/skillPracticeSubject";
import SkillPractice from "./component/screen/student/skillPractice/home";
import SkillPracticeTest from "./component/screen/student/skillPractice/test";
import SkillGameChoose from "./component/screen/student/skillPractice/gameChoose";
import SkillPracticeSessionSummary from "./component/screen/student/skillPractice/sessionSummary";
import Results from "./component/screen/results/results";
import ManageTags from "./component/screen/teacher/Tags/manageTags";
import SuperSheet from "./component/screen/teacher/superSheet/superSheet";
import Chat from "./component/screen/chat/chat";
import CleverLogin from "./component/screen/externalLogin/cleverLogin";
import OAuthSSO from "./component/screen/externalLogin/OAuthSSO";
import ClassLinkLogin from "./component/screen/externalLogin/classLinkLogin";
import QuestionBuilder from "./component/screen/teacher/questionBuilder/questionBuilder";
import QuestionBuilderCard from "./component/screen/teacher/questionBuilder/questionBuilderCard";
import BubbleSheet from "./component/screen/teacher/bubbleSheet/bubbleSheet";
import ImportCanvasClasses from "./component/screen/teacher/classManagement/importCanvasClasses";
import ActivityEL from "./component/screen/student/elementary/activityEL";
import ActivityKG from "./component/screen/student/kindergarten/activityKG";
import ActivityMS from "./component/screen/student/middleSchool/activityMS";
import ELRESULT from "./component/screen/student/elementary/result/dashboard";
import KGRESULT from "./component/screen/student/kindergarten/result/dashboard";
import MSRESULT from "./component/screen/student/middleSchool/result/dashboard";
import AuthoredQuestions from "./component/screen/teacher/questionBuilder/authoredQuestions";
import AssignmentPrint from "./component/screen/teacher/shared/print/learnosityPrintPage";
import KGAssignment from "./component/screen/student/kindergarten/assignment/assignments";
import GradedWork from "./component/screen/student/kindergarten/gradedWork/gradedWork";
import LiftOffELHome from "./component/screen/student/elementary/liftoff/home";
import GameChoose from "./component/screen/student/shared/gameChoose";
import StudyPlan from "./component/screen/student/elementary/studyplan/studyPlanPractice";
import CannedTest from "./component/screen/student/elementary/cannedTests/cannedTest";
import ELStandardEfficiencyReport from "./component/screen/student/elementary/cannedTests/standardEfficiencyReport";
import LiftOffDiagnosticActivity from "./component/screen/student/elementary/liftoff/diagnostic/liftOffDiagnosticActivity";
import KindergartenStudyPlan from "./component/screen/student/kindergarten/studyPlan/studyPlanPractice";
import LiftOffMyGalaxyStandardActivity from "./component/screen/student/elementary/liftoff/mygalaxy/liftOffMyGalaxyStandardActivity";
import StudyPlanSummary from "./component/screen/student/shared/studyPlanSummary";
import BellRinger from "./component/screen/teacher/bellRinger/bellRinger";
import ManageBellRinger from "./component/screen/teacher/bellRinger/manageBellRinger";
import PreviewQuestion from "./component/screen/teacher/bellRinger/previewQuestion";
import ELLIFTOFFDIAGNOSTICRESULT from "./component/screen/student/elementary/liftoff/diagnostic/result/result";
import ELLIFTOFFMYGALAXYSTANDARDRESULT from "./component/screen/student/elementary/liftoff/mygalaxy/result/result";
import studyPlanStandard from "./component/screen/student/middleSchool/studyPlan/studyPlanStandard";
import StudyPlanMS from "./component/screen/student/middleSchool/studyPlan/studyPlanPractice";
import VideoQuestionList_EL from "./component/screen/student/elementary/studyplan/VideoPractice/videoQuestionList";
import VideoQuestionPractice_EL from "./component/screen/student/elementary/studyplan/VideoPractice/videoQuestionPractice";
import VideoQuestionList_MS from "./component/screen/student/middleSchool/studyPlan/VideoPractice/videoQuestionList";
import VideoQuestionPractice_MS from "./component/screen/student/middleSchool/studyPlan/VideoPractice/videoQuestionPractice";
import VideoQuestionList_KG from "./component/screen/student/kindergarten/studyPlan/VideoPractice/standardVideoQuestionList";
import VideoQuestionPractice_KG from "./component/screen/student/kindergarten/studyPlan/VideoPractice/videoQuestionPractice";
import VideoQuestionResult_MS from "./component/screen/student/middleSchool/studyPlan/studyPlanResult/videoPracticeResult";
import VideoQuestionResult_EL from "./component/screen/student/elementary/studyplan/studyPlanResult/videoPracticeResult";
import VideoQuestionResult_KG from "./component/screen/student/kindergarten/studyPlan/studyPlanResult/videoPracticeResult";
import TrohpyRoomMS from "./component/screen/student/middleSchool/throphyRoom/trophyRoom";
import Reports from "./component/screen/teacher/reports/reports";
import ClassroomGame from "./component/screen/teacher/classroomGame/classroomGame";
import ThinkOrSwim from "./component/screen/teacher/classroomGame/thinkOrSwim/thinkOrSwim";
import StudentAssignmentActivity from "./component/screen/student/elementary/assignment/assignmentActivity";
import ClassActivity from "./component/screen/teacher/classActivity/classActivity";
import StudyPlanResult_EL from "./component/screen/student/elementary/studyplan/studyPlanResult/result";
import StudyPlanResult_MS from "./component/screen/student/middleSchool/studyPlan/studyPlanResult/studyPlanStandardResult";
import SummativeTestResult_MS from "./component/screen/student/middleSchool/studyPlan/studyPlanResult/summativeTestResult";
import StudyPlanResult_KG from "./component/screen/student/kindergarten/studyPlan/studyPlanResult/result";
import InstructionalResourceSubject from "./component/screen/teacher/instructionalResources/instructionalResourceSubject";
import MSCannedTest from "./component/screen/student/middleSchool/cannedTests/cannedTest";
import MSCannedTestSubjects from "./component/screen/student/middleSchool/cannedTests/cannedTestSubject";
import MSStandardEfficiencyReport from "./component/screen/student/middleSchool/cannedTests/standardEfficiencyReport";
import MyStatMiddleSchool from "./component/screen/student/middleSchool/myStats/myStatMiddleSchool";
import leaderboardMiddleSchool from "./component/screen/student/middleSchool/leaderboard/leaderboardMiddleSchool";
import KGCannedTest from "./component/screen/student/kindergarten/cannedTests/cannedTest";
import KGStandardEfficiencyReport from "./component/screen/student/kindergarten/cannedTests/standardEfficiencyReport";
import StudyPlanSummativeTest from "./component/screen/student/middleSchool/studyPlan/studyPlanSummativeTest";
import StudyPlanCannedTest from "./component/screen/student/middleSchool/studyPlan/studyPlanCannedTest";

import StudyPlanDomainTest from "./component/screen/student/middleSchool/studyPlan/studyPlanDomainTest";
import DomainTestResult_MS from "./component/screen/student/middleSchool/studyPlan/studyPlanResult/domainTestResult";
import CannedTestResult_MS from "./component/screen/student/middleSchool/studyPlan/studyPlanResult/cannedTestResult";

import DiagnosticsMS from "./component/screen/student/middleSchool/liftOff/diagnostics";
import MyGalaxySubjectMS from "./component/screen/student/middleSchool/liftOff/myGalaxySubject";
import MyGalaxyMS from "./component/screen/student/middleSchool/liftOff/myGalaxy";

import KGCannedTestResult from "./component/screen/student/kindergarten/cannedTests/cannedTestResult";
import ELCannedTestResult from "./component/screen/student/elementary/cannedTests/cannedTestResult";
import MSCannedTestResult from "./component/screen/student/middleSchool/cannedTests/cannedTestResult";
import MSCannedTestPractice from "./component/screen/student/middleSchool/cannedTests/cannedTestPractice";
import ConstructedResponseReport from "./component/screen/results/crReport";
import GradeConstructedResponseQuestion from "./component/screen/results/gradeCRQuestion";
import AssignmentResultEL from "./component/screen/student/elementary/assignment/result/result";
import UserLiftoffManagement from "./component/screen/teacher/liftoffSetting/userLiftoffManagement";
import NweaMapping from "./component/screen/nwea/nweaMapping";
import AssignmentResultKG from "./component/screen/student/kindergarten/assignment/result/result";
import AssignmentResultMS from "./component/screen/student/middleSchool/assignments/result/result";
import SessionActivityAssignmentResult from "./component/screen/teacher/reports/sessionActivity/assignmentResult/result";
import SessionActivityDiagnosticResult from "./component/screen/teacher/reports/sessionActivity/diagnosticResult/result";
import SessionActivityLiftOffDiagResult from "./component/screen/teacher/reports/sessionActivity/liftoffDiagResult/result";
import SessionActivityMyGalaxyResult from "./component/screen/teacher/reports/sessionActivity/mygalaxyResult/result";
import SessionActivityStudyPlanResult from "./component/screen/teacher/reports/sessionActivity/studyplanResult/result";
import StudentAssessmentActivity from "./component/screen/student/elementary/assessment/assessmentActivity";
import StudentAssignmentResult from "./component/screen/teacher/reports/studentResult/assignmentResult";
import StudentPracticeResult from "./component/screen/teacher/reports/studentResult/practiceResult";
import StudentStandardEfficiencyResult from "./component/screen/teacher/reports/studentResult/standardsEfficiencyResult";
import StudentAlienArena from "./component/screen/student/shared/AlienArena/alienArena";
import AlienArenaResults from "./component/screen/teacher/alienArena/AlienArenaResults";
import StudentAlienArenaPopUp from "./component/screen/student/shared/AlienArena/alienArenaPopUp";
import StudentAlienArenaFinalResult from "./component/screen/student/shared/AlienArena/alienArenaFinal";
import StudentAlienArenaResults from "./component/screen/student/shared/AlienArena/alienArenaResults";
import StudentAlienArenaStartSession from "./component/screen/student/shared/AlienArena/alienArenaStartSession";
import ForbiddenComponent from "./component/shared/forbidden";
import AccountExpired from "./component/shared/accountExpired";
import StudyPlanSummativeTest_EL from "./component/screen/student/elementary/studyplan/studyPlanSummativeTest";
import StudyPlanCannedTest_EL from "./component/screen/student/elementary/studyplan/studyPlanCannedTest";
import StudyPlanDomainTest_EL from "./component/screen/student/elementary/studyplan/studyPlanDomainTest";
import SummativeTestResult_EL from "./component/screen/student/elementary/studyplan/studyPlanResult/summativeTestResult";
import DomainTestResult_EL from "./component/screen/student/elementary/studyplan/studyPlanResult/domainTestResult";
import CannedTestResult_EL from "./component/screen/student/elementary/studyplan/studyPlanResult/cannedTestResult";
import RetryMissedItems from "./component/screen/student/shared/retryMissedItems";
import GameSelection from "./component/screen/usatpArcade/gameSelection";
import InstructionalResourcesVideos from "./component/screen/teacher/instructionalResources/videos";
import AssessmentComparisonReport from "./component/screen/teacher/reports/testComparisonReport/assessmentComparisonReport";
import BadRequest from "./component/shared/badRequest";
import LearnosityItemAnalysis from "./component/screen/teacher/reports/LearnosityItemAnalysis";
import LearnosityLiveMonitoring from "./component/screen/teacher/reports/LearnosityLiveMonitoring";
import LiveMonitoringReport from "./component/screen/teacher/reports/liveMonitoringReport/liveMonitoringReport";
import StudentSetting from "./component/screen/teacher/studentSetting/studentSetting";
import UserRegistration from "./component/screen/userRegistration/userRegistration";
import RecoveryCodeExpired from "./component/shared/recoveryCodeExpired";
import StudentReportCard from "./component/screen/teacher/reports/studentReportCard/studentReportCard";
import GradeAssesmentWithConstructedResponse from "./component/screen/results/gradeAssesmentWithCR";
import {StudentActivities} from "./component/screen/teacher/instructionalResources/StudentActivities";
import ResultsReport  from "./component/screen/teacher/reports/resultsReport/resultsReport";

export const Routes: Array<Route> = [
  new Route(RouteConstant.CLEVERLOGIN, CleverLogin),
  new Route(RouteConstant.CLASSLINKLOGIN, ClassLinkLogin),
  new Route(RouteConstant.GWINNETTSSOLOGIN, OAuthSSO),
  new Route(RouteConstant.ROUTE_LOGIN, LoginComponent),

  new Route(RouteConstant.ROUTE_SCHOOL_SELECTION, SchoolSelectionComponent),
  new Route(RouteConstant.GOOGLEUSERLOGIN, GoogleUserLogin),
  new Route(RouteConstant.EXTERNALGOOGLELOGIN, ExternalGoogleLogin),
  new Route(RouteConstant.EXTERNALCANVASLOGIN, ExternalCanvasLogin),
  new Route(RouteConstant.GOOGLESTUDENTLOGIN, GoogleStudentLogin),
  new Route(RouteConstant.GOOGLETEACHERLOGIN, GoogleTeacherLogin),
  new Route(RouteConstant.GOOGLEUSERMAPPING, GoogleUserMapping),
  new Route(RouteConstant.GOOGLE_UNAUTHORIZED, GoogleUnauthorized),
  new Route(RouteConstant.CANVASUSERMAPPING, CanvasUserMapping),
  new Route(RouteConstant.CANVASUSERLOGIN, CanvasUserLogin),
  new Route(RouteConstant.ROUTE_DEFAULT, LoginComponent),
  new Route(RouteConstant.ROUTE_FORGOTPASSWORD, ForgotPasswordComponent),
  new Route(RouteConstant.ROUTE_LOGOUT, NotAuthorizedComponent),
  new Route(RouteConstant.FORBIDDEN, ForbiddenComponent),
  new Route(RouteConstant.EXPIRED, AccountExpired),
  new Route(RouteConstant.CONTAINER, ContainerComponent),
  new Route(RouteConstant.TEACHERTRAINING, teacherTrainingComponent),
  new Route(RouteConstant.CAMPUSTRAINING, campusTrainingComponent),
  new Route(RouteConstant.ADMINLIST, adminListComponent),
  new Route(RouteConstant.TeacherRoutes.manageUsers, ManageUsersComponent),
  new Route(RouteConstant.HELP, HelpComponent),
  new Route(RouteConstant.TRAINING, TrainingComponent),
  new Route(RouteConstant.BLANK, BlankComponent),
  new Route(RouteConstant.IMPERSONATE, ImpersonateComponent),
  new Route(RouteConstant.PARENTLETTER, ParentLetterComponent),
  new Route(RouteConstant.BAD_REQUEST, BadRequest),
  new Route(
    RouteConstant.UserProfile,
    ProfileComponent,
    Constant.LayoutType.TEACHER as layoutType
  ),
  new Route(RouteConstant.CONTACTUS, ContactUsComponent),
  new Route(RouteConstant.VIDEOMESSAGEMANAGEMENT, VideoMessageManagement),
  new Route(RouteConstant.REQUESTSSO, RequestSSOComponent),
  new Route(RouteConstant.STUDENTASSESSMENT, StudentAssessment),
  new Route(RouteConstant.CHANGEPASSWORD, ChangePassword),
  new Route(RouteConstant.USERREGISTRATION, UserRegistration),
  new Route(RouteConstant.RECOVERYCODEEXPIRED, RecoveryCodeExpired),
  new Route(
    RouteConstant.MiddleSchool.Profile,
    StudentProfile,
    Constant.LayoutType.MIDDLESCHOOL as layoutType
  ),
  new Route(
    RouteConstant.KindergartenStudentRoutes.Profile,
    StudentProfile,
    Constant.LayoutType.KINDERGARTEN as layoutType
  ),
  new Route(
    RouteConstant.StudentRoute.Profile,
    StudentProfile,
    Constant.LayoutType.ELEMENTARY as layoutType
  ),
  new Route(RouteConstant.ASSIGNMENTPRINT, AssignmentPrint),
  new Route(RouteConstant.TeacherRoutes.PreviewQuestion, PreviewQuestion),
  new Route(
    RouteConstant.NEWASSESSMENT,
    NewAssessment,
    Constant.LayoutType.TEACHER as layoutType
  ),
  new Route(
    RouteConstant.EDITASSESSMENT,
    NewAssessment,
    Constant.LayoutType.TEACHER as layoutType
  ),
  new Route(
    RouteConstant.ASSESSMENT_RESULT,
    AssessmentResult,
    Constant.LayoutType.TEACHER as layoutType
  ),
  new Route(
    RouteConstant.RESULT_REPORTS,
    ResultReports,
    Constant.LayoutType.TEACHER as layoutType
  ),
  new Route(
    RouteConstant.KindergartenStudentRoutes.Home,
    KHome,
    Constant.LayoutType.KINDERGARTEN as layoutType
  ),
  new Route(
    RouteConstant.KindergartenStudentRoutes.SubjectStudy,
    SubjectHome,
    Constant.LayoutType.KINDERGARTEN as layoutType
  ),
  new Route(
    RouteConstant.KindergartenStudentRoutes.Arena,
    ArenaKindergarten,
    Constant.LayoutType.KINDERGARTEN as layoutType
  ),
  new Route(
    RouteConstant.ARENA_MIDDLE_SCHOOL,
    ArenaMiddleSchool,
    Constant.LayoutType.MIDDLESCHOOL as layoutType
  ),
  new Route(
    RouteConstant.ARENA_ELE,
    ArenaElementary,
    Constant.LayoutType.ELEMENTARY as layoutType
  ),
  new Route(
    RouteConstant.MiddleSchool.assignments,
    AssignmentsMiddleSchool,
    Constant.LayoutType.MIDDLESCHOOL as layoutType
  ),
  new Route(
    RouteConstant.MiddleSchool.Arcade,
    GameSelection,
    Constant.LayoutType.MIDDLESCHOOL as layoutType
  ),
  new Route(
    RouteConstant.MiddleSchool.gradedWork,
    MSGradedWork,
    Constant.LayoutType.MIDDLESCHOOL as layoutType
  ),

  new Route(
    RouteConstant.MiddleSchool.subjects,
    StudyPlanSubjects,
    Constant.LayoutType.MIDDLESCHOOL as layoutType
  ),
  new Route(
    RouteConstant.KindergartenStudentRoutes.Assignment,
    KGAssignment,
    Constant.LayoutType.KINDERGARTEN as layoutType
  ),
  new Route(
    RouteConstant.KindergartenStudentRoutes.GradedWork,
    GradedWork,
    Constant.LayoutType.KINDERGARTEN as layoutType
  ),
  new Route(
    RouteConstant.STUDENT,
    StudentComponent,
    Constant.LayoutType.ELEMENTARY as layoutType
  ),
  new Route(
    RouteConstant.MYSTAT,
    MyStat,
    Constant.LayoutType.ELEMENTARY as layoutType
  ),
  new Route(
    RouteConstant.LEADERBOARD,
    LeaderboardComponent,
    Constant.LayoutType.ELEMENTARY as layoutType
  ),
  new Route(
    RouteConstant.StudentRoute.Home,
    ELHome,
    Constant.LayoutType.ELEMENTARY as layoutType
  ),
  new Route(
    RouteConstant.LiftOffRoutes.ELEMENTARYHOME,
    LiftOffELHome,
    Constant.LayoutType.ELEMENTARY as layoutType
  ),
  new Route(
    RouteConstant.LiftOffRoutes.ELEMENTARYDIAGNOSTICRESULT,
    ELLIFTOFFDIAGNOSTICRESULT,
    Constant.LayoutType.ELEMENTARY as layoutType
  ),
  new Route(
    RouteConstant.LiftOffRoutes.ELEMENTARYMYGLAXYRESULT,
    ELLIFTOFFMYGALAXYSTANDARDRESULT,
    Constant.LayoutType.ELEMENTARY as layoutType
  ),
  new Route(
    RouteConstant.MiddleSchool.home,
    MSHome,
    Constant.LayoutType.MIDDLESCHOOL as layoutType
  ),
  new Route(
    RouteConstant.TeacherRoutes.assignmentCenter,
    AssignmentCenter,
    Constant.LayoutType.TEACHER as layoutType
  ),
  new Route(
    RouteConstant.ASSIGNMENTBANK,
    AssignmentBank,
    Constant.LayoutType.TEACHER as layoutType
  ),
  new Route(
    RouteConstant.NEWASSIGNMENT,
    NewAssignment,
    Constant.LayoutType.TEACHER as layoutType
  ),
  new Route(
    RouteConstant.EDITASSIGNMENT,
    NewAssignment,
    Constant.LayoutType.TEACHER as layoutType
  ),
  new Route(
    RouteConstant.TeacherRoutes.Classes,
    ClassManagement,
    Constant.LayoutType.TEACHER as layoutType
  ),
  new Route(
    RouteConstant.TeacherRoutes.ClassesDetails,
    ClassDetail,
    Constant.LayoutType.TEACHER as layoutType
  ),
  new Route(
    RouteConstant.TeacherRoutes.InstructionalResources,
    InstructionalResources,
    Constant.LayoutType.TEACHER as layoutType
  ),
  new Route(
    RouteConstant.TeacherRoutes.StudyPlanSubjects,
    StudyPlanSubjects,
    Constant.LayoutType.TEACHER as layoutType
  ),
  new Route(
    RouteConstant.TeacherRoutes.InstructionalResourceSubjects,
    InstructionalResourceSubject,
    Constant.LayoutType.TEACHER as layoutType
  ),
  new Route(
    RouteConstant.TeacherRoutes.ProgressReport,
    ProgressReport,
    Constant.LayoutType.TEACHER as layoutType
  ),
  new Route(
    RouteConstant.TeacherRoutes.LiveMonitoringReportHome,
    LiveMonitoringReport,
    Constant.LayoutType.TEACHER as layoutType
  ),
  new Route(
    RouteConstant.TeacherRoutes.ProgressReportStudent,
    ProgressReport,
    Constant.LayoutType.TEACHER as layoutType
  ),
  new Route(
    RouteConstant.SESSION_ACTIVITY_REPORT,
    SessionActivity,
    Constant.LayoutType.TEACHER as layoutType
  ),
  new Route(
    RouteConstant.GALAXY_START_REPORT,
    GalaxyStarReport,
    Constant.LayoutType.TEACHER as layoutType
  ),
  new Route(
    RouteConstant.LIFTOFF_GALAXY_START_REPORT,
    LiftoffGalaxyStarReport,
    Constant.LayoutType.TEACHER as layoutType
  ),
  new Route(
    RouteConstant.TeacherRoutes.LiftoffClassGalaxySummaryReport,
    LiftoffClassGalaxySummaryReport,
    Constant.LayoutType.TEACHER as layoutType
  ),
  new Route(
    RouteConstant.TeacherRoutes.LiftoffUsageReport,
    LiftoffUsageReport,
    Constant.LayoutType.TEACHER as layoutType
  ),
  new Route(
    RouteConstant.TeacherRoutes.liftoffUsageStudentLevelByClassReport,
    LiftoffUsageStudentLevelReport,
    Constant.LayoutType.TEACHER as layoutType
  ),
  new Route(
    RouteConstant.TeacherRoutes.liftoffUsageStudentLevelByGradeReport,
    LiftoffUsageStudentLevelReport,
    Constant.LayoutType.TEACHER as layoutType
  ),
  new Route(
    RouteConstant.TeacherRoutes.GradeBook,
    GradeBook,
    Constant.LayoutType.TEACHER as layoutType
  ),
  new Route(
    RouteConstant.TeacherRoutes.AssessmentResultsReport,
    AssessmentResultsReport,
    Constant.LayoutType.TEACHER as layoutType
  ),
  new Route(
    RouteConstant.TeacherRoutes.LiftoffGrowthReport,
    LiftoffGrowthReport,
    Constant.LayoutType.TEACHER as layoutType
  ),
  new Route(
    RouteConstant.TeacherRoutes.LiftoffDetailGrowthReport,
    LiftoffDetailGrowthReport,
    Constant.LayoutType.TEACHER as layoutType
  ),
  new Route(
    RouteConstant.TeacherRoutes.LiftoffIndividualDiagnosticReport,
    LiftoffIndividualDiagnostics,
    Constant.LayoutType.TEACHER as layoutType
  ),
  new Route(
    RouteConstant.STUDENT_DIAGNOSTICS_STRENGTH_WEAKNESS_REPORT,
    DiagnosticStrengthWeaknessReport,
    Constant.LayoutType.TEACHER as layoutType
  ),
  new Route(
    RouteConstant.TeacherRoutes.LiftoffStandardDiagnosticReport,
    LiftoffStandardDiagnostict,
    Constant.LayoutType.TEACHER as layoutType
  ),
  new Route(
    RouteConstant.TeacherRoutes.StudentActivitySearchReport,
    StudentActivitySearchReport,
    Constant.LayoutType.TEACHER as layoutType
  ),
  new Route(
    RouteConstant.TeacherRoutes.TeacherUsageReport,
    TeacherUsageReport,
    Constant.LayoutType.TEACHER as layoutType
  ),
  new Route(
    RouteConstant.TeacherRoutes.LiftoffActivityReport,
    LiftoffActivityReport,
    Constant.LayoutType.TEACHER as layoutType
  ),
  new Route(
    RouteConstant.TeacherRoutes.LiftoffGalaxySummaryReport,
    LiftoffGalaxySummaryReport,
    Constant.LayoutType.TEACHER as layoutType
  ),
  new Route(
    RouteConstant.TeacherRoutes.AlienArena,
    AlienArena,
    Constant.LayoutType.TEACHER as layoutType
  ),
  new Route(
    RouteConstant.TeacherRoutes.LaunchAlienArena,

    LaunchAlienArena,

    Constant.LayoutType.TEACHER as layoutType
  ),
  new Route(
    RouteConstant.TeacherRoutes.ArenaStartSession,

    ArenaStartSession,

    Constant.LayoutType.TEACHER as layoutType
  ),

  new Route(
    RouteConstant.TeacherRoutes.AlienFinalResult,

    AlienFinalResult,

    Constant.LayoutType.TEACHER as layoutType
  ),

  new Route(
    RouteConstant.TeacherRoutes.AlienArenaresults,

    AlienArenaResults,

    Constant.LayoutType.TEACHER as layoutType
  ),
  new Route(
    RouteConstant.TeacherRoutes.AlienArenaDetails,
    EditAlienArena,
    Constant.LayoutType.TEACHER as layoutType
  ),
  new Route(
    RouteConstant.TeacherRoutes.AlienArenaSessions,
    AlienArenaSessions,
    Constant.LayoutType.TEACHER as layoutType
  ),
  new Route(
    RouteConstant.ROUTE_DASHBOARD,
    Dashboard,
    Constant.LayoutType.TEACHER as layoutType
  ),
  new Route(
    RouteConstant.TeacherRoutes.manageUser,
    manageUser,
    Constant.LayoutType.TEACHER as layoutType
  ),
  new Route(
    RouteConstant.ASSESSMENTBANK,
    AssessmentBank,
    Constant.LayoutType.TEACHER as layoutType
  ),
  new Route(
    RouteConstant.StudentRoute.SkillPractice,
    SkillPractice,
    Constant.LayoutType.ELEMENTARY as layoutType
  ),
  new Route(
    RouteConstant.StudentRoute.SkillPracticeTest,
    SkillPracticeTest,
    Constant.LayoutType.ELEMENTARY as layoutType
  ),
  new Route(
    RouteConstant.StudentRoute.SkillGameChoose,
    SkillGameChoose,
    Constant.LayoutType.ELEMENTARY as layoutType
  ),
  new Route(
    RouteConstant.StudentRoute.SkillPracticeSessionSummary,
    SkillPracticeSessionSummary,
    Constant.LayoutType.ELEMENTARY as layoutType
  ),
  new Route(
    RouteConstant.KindergartenStudentRoutes.SkillPractice,
    SkillPractice,
    Constant.LayoutType.KINDERGARTEN as layoutType
  ),
  new Route(
    RouteConstant.KindergartenStudentRoutes.SkillPracticeTest,
    SkillPracticeTest,
    Constant.LayoutType.KINDERGARTEN as layoutType
  ),
  new Route(
    RouteConstant.KindergartenStudentRoutes.SkillGameChoose,
    SkillGameChoose,
    Constant.LayoutType.KINDERGARTEN as layoutType
  ),
  new Route(
    RouteConstant.KindergartenStudentRoutes.SkillPracticeSessionSummary,
    SkillPracticeSessionSummary,
    Constant.LayoutType.KINDERGARTEN as layoutType
  ),
  new Route(
    RouteConstant.MiddleSchool.SkillPracticeSubjects,
    SkillPracticeSubjects,
    Constant.LayoutType.MIDDLESCHOOL as layoutType
  ),
  new Route(
    RouteConstant.MiddleSchool.SkillPracticeTest,
    SkillPracticeTest,
    Constant.LayoutType.MIDDLESCHOOL as layoutType
  ),
  new Route(
    RouteConstant.MiddleSchool.SkillGameChoose,
    SkillGameChoose,
    Constant.LayoutType.MIDDLESCHOOL as layoutType
  ),
  new Route(
    RouteConstant.MiddleSchool.SkillPracticeSessionSummary,
    SkillPracticeSessionSummary,
    Constant.LayoutType.MIDDLESCHOOL as layoutType
  ),
  new Route(
    RouteConstant.TeacherRoutes.Tags,
    ManageTags,
    Constant.LayoutType.TEACHER as layoutType
  ),
  new Route(
    RouteConstant.TeacherRoutes.SuperSheets,
    SuperSheet,
    Constant.LayoutType.TEACHER as layoutType
  ),
  new Route(
    RouteConstant.NEWBUBBLESHEET,
    BubbleSheet,
    Constant.LayoutType.TEACHER as layoutType
  ),
  new Route(
    RouteConstant.QUESTIONBUILDER,
    QuestionBuilder,
    Constant.LayoutType.TEACHER as layoutType
  ),
  new Route(
    RouteConstant.QUESTIONBUILDERCARD,
    QuestionBuilderCard,
    Constant.LayoutType.TEACHER as layoutType
  ),
  new Route(
    RouteConstant.AUTHOREDQUESTIONS,
    AuthoredQuestions,
    Constant.LayoutType.TEACHER as layoutType
  ),
  new Route(
    RouteConstant.EDITQUESTION,
    QuestionBuilder,
    Constant.LayoutType.TEACHER as layoutType
  ),
  new Route(
    RouteConstant.RESULTS,
    Results,
    Constant.LayoutType.MIDDLESCHOOL as layoutType
  ),
  new Route(
    RouteConstant.Chat,
    Chat,
    Constant.LayoutType.TEACHER as layoutType
  ),
  new Route(
    RouteConstant.StudentRoute.ActivityKG,
    ActivityKG,
    Constant.LayoutType.KINDERGARTEN as layoutType
  ),
  new Route(
    RouteConstant.StudentRoute.ActivityEL,
    ActivityEL,
    Constant.LayoutType.ELEMENTARY as layoutType
  ),
  new Route(
    RouteConstant.StudentRoute.ActivityMS,
    ActivityMS,
    Constant.LayoutType.TEACHER as layoutType
  ),
  new Route(
    RouteConstant.ELEMENTARYRESULT,
    ELRESULT,
    Constant.LayoutType.TEACHER as layoutType
  ),
  new Route(
    RouteConstant.KindergartenStudentRoutes.Result,
    KGRESULT,
    Constant.LayoutType.TEACHER as layoutType
  ),
  new Route(
    RouteConstant.TeacherRoutes.ImportCanvasClasses,
    ImportCanvasClasses,
    Constant.LayoutType.TEACHER as layoutType
  ),
  new Route(
    RouteConstant.MIDDLESCHOOLRESULT,
    MSRESULT,
    Constant.LayoutType.MIDDLESCHOOL as layoutType
  ),

  new Route(
    RouteConstant.MIDDLESCHOOLRESULT,
    MSRESULT,
    Constant.LayoutType.MIDDLESCHOOL as layoutType
  ),
  new Route(
    RouteConstant.StudentRoute.GameChoose,
    GameChoose,
    Constant.LayoutType.ELEMENTARY as layoutType
  ),
  new Route(
    RouteConstant.KindergartenStudentRoutes.GameChoose,
    GameChoose,
    Constant.LayoutType.KINDERGARTEN as layoutType
  ),
  new Route(
    RouteConstant.MiddleSchool.GameChoose,
    GameChoose,
    Constant.LayoutType.MIDDLESCHOOL as layoutType
  ),
  new Route(
    RouteConstant.StudentRoute.StudyPlan,
    StudyPlan,
    Constant.LayoutType.ELEMENTARY as layoutType
  ),
  new Route(
    RouteConstant.StudentRoute.CannedTest,
    CannedTest,
    Constant.LayoutType.ELEMENTARY as layoutType
  ),
  new Route(
    RouteConstant.KindergartenStudentRoutes.StudyPlan,
    KindergartenStudyPlan,
    Constant.LayoutType.KINDERGARTEN as layoutType
  ),
  new Route(
    RouteConstant.StudentRoute.LiftOffDiagnostic,
    LiftOffDiagnosticActivity,
    Constant.LayoutType.ELEMENTARY as layoutType
  ),
  new Route(
    RouteConstant.StudentRoute.LiftOffMyGalaxy,
    LiftOffMyGalaxyStandardActivity,
    Constant.LayoutType.ELEMENTARY as layoutType
  ),
  new Route(
    RouteConstant.StudentRoute.StudentAssignmentActivityEL,
    StudentAssignmentActivity,
    Constant.LayoutType.ELEMENTARY as layoutType
  ),
  new Route(
    RouteConstant.StudentRoute.StudyPlanSummary,
    StudyPlanSummary,
    Constant.LayoutType.ELEMENTARY as layoutType
  ),
  new Route(
    RouteConstant.KindergartenStudentRoutes.StudyPlanSummary,
    StudyPlanSummary,
    Constant.LayoutType.KINDERGARTEN as layoutType
  ),
  new Route(
    RouteConstant.TeacherRoutes.BellRinger,
    BellRinger,
    Constant.LayoutType.TEACHER as layoutType
  ),
  new Route(
    RouteConstant.TeacherRoutes.ClassActivity,
    ClassActivity,
    Constant.LayoutType.TEACHER as layoutType
  ),
  new Route(
      RouteConstant.TeacherRoutes.StudentActivities,
      StudentActivities,
      Constant.LayoutType.TEACHER as layoutType
  ),
  new Route(
    RouteConstant.StudentRoute.StandardEfficiencyReport,
    ELStandardEfficiencyReport,
    Constant.LayoutType.ELEMENTARY as layoutType
  ),
  new Route(
    RouteConstant.TeacherRoutes.BellRingerNew,
    ManageBellRinger,
    Constant.LayoutType.TEACHER as layoutType
  ),
  new Route(
    RouteConstant.TeacherRoutes.BellRingerEdit,
    ManageBellRinger,
    Constant.LayoutType.TEACHER as layoutType
  ),
  new Route(
    RouteConstant.MiddleSchool.standards,
    studyPlanStandard,
    Constant.LayoutType.MIDDLESCHOOL as layoutType
  ),
  new Route(
    RouteConstant.MiddleSchool.StudyPlan,
    StudyPlanMS,
    Constant.LayoutType.MIDDLESCHOOL as layoutType
  ),
  new Route(
    RouteConstant.MiddleSchool.GameChooseMS,
    GameChoose,
    Constant.LayoutType.MIDDLESCHOOL as layoutType
  ),
  new Route(
    RouteConstant.StudentRoute.VideoQuestionList,
    VideoQuestionList_EL,
    Constant.LayoutType.ELEMENTARY as layoutType
  ),
  new Route(
    RouteConstant.StudentRoute.VideoQuestionPractice,
    VideoQuestionPractice_EL,
    Constant.LayoutType.ELEMENTARY as layoutType
  ),
  new Route(
    RouteConstant.MiddleSchool.VideoQuestionList,
    VideoQuestionList_MS,
    Constant.LayoutType.MIDDLESCHOOL as layoutType
  ),
  new Route(
    RouteConstant.MiddleSchool.VideoQuestionPractice,
    VideoQuestionPractice_MS,
    Constant.LayoutType.MIDDLESCHOOL as layoutType
  ),
  new Route(
    RouteConstant.KindergartenStudentRoutes.VideoQuestionList,
    VideoQuestionList_KG,
    Constant.LayoutType.KINDERGARTEN as layoutType
  ),
  new Route(
    RouteConstant.KindergartenStudentRoutes.VideoQuestionPractice,
    VideoQuestionPractice_KG,
    Constant.LayoutType.KINDERGARTEN as layoutType
  ),
  new Route(
    RouteConstant.MYSTATMS,
    MyStatMiddleSchool,
    Constant.LayoutType.MIDDLESCHOOL as layoutType
  ),
  new Route(
    RouteConstant.LEADERBOARDMS,
    leaderboardMiddleSchool,
    Constant.LayoutType.MIDDLESCHOOL as layoutType
  ),
  new Route(
    RouteConstant.MiddleSchool.TrohpyRoom,
    TrohpyRoomMS,
    Constant.LayoutType.MIDDLESCHOOL as layoutType
  ),

  new Route(
    RouteConstant.REPORTS,
    Reports,
    Constant.LayoutType.TEACHER as layoutType
  ),
  new Route(
    RouteConstant.PROGRESS_REPORT,
    ProgressReport,
    Constant.LayoutType.TEACHER as layoutType
  ),
  new Route(
    RouteConstant.TeacherRoutes.AssessmentComparisonReport,
    AssessmentComparisonReport,
    Constant.LayoutType.TEACHER as layoutType
  ),
  new Route(
      RouteConstant.TeacherRoutes.StudentReportCard,
      StudentReportCard,
      Constant.LayoutType.TEACHER as layoutType
  ),
  new Route(
    RouteConstant.USAGE_REPORT,
    UsageReport,
    Constant.LayoutType.TEACHER as layoutType
  ),
  new Route(
    RouteConstant.CLASSROOM_GAME,
    ClassroomGame,
    Constant.LayoutType.TEACHER as layoutType
  ),
  new Route(
    RouteConstant.ClassroomGame.ThinkOrSwim,
    ThinkOrSwim,
    Constant.LayoutType.TEACHER as layoutType
  ),
  new Route(
    RouteConstant.StudentRoute.StudyPlanResult,
    StudyPlanResult_EL,
    Constant.LayoutType.ELEMENTARY as layoutType
  ),
  new Route(
    RouteConstant.MiddleSchool.CannedTest,
    MSCannedTest,
    Constant.LayoutType.MIDDLESCHOOL as layoutType
  ),
  new Route(
    RouteConstant.MiddleSchool.CannedTestSubjects,
    MSCannedTestSubjects,
    Constant.LayoutType.MIDDLESCHOOL as layoutType
  ),
  new Route(
    RouteConstant.MiddleSchool.StandardEfficiencyReport,
    MSStandardEfficiencyReport,
    Constant.LayoutType.MIDDLESCHOOL as layoutType
  ),
  new Route(
    RouteConstant.MiddleSchool.StudyPlanResult,
    StudyPlanResult_MS,
    Constant.LayoutType.MIDDLESCHOOL as layoutType
  ),
  new Route(
    RouteConstant.KindergartenStudentRoutes.StudyPlanResult,
    StudyPlanResult_KG,
    Constant.LayoutType.KINDERGARTEN as layoutType
  ),
  new Route(
    RouteConstant.MiddleSchool.StudyPlanSummary,
    StudyPlanSummary,
    Constant.LayoutType.MIDDLESCHOOL as layoutType
  ),
  new Route(
    RouteConstant.KindergartenStudentRoutes.CannedTest,
    KGCannedTest,
    Constant.LayoutType.KINDERGARTEN as layoutType
  ),
  new Route(
    RouteConstant.KindergartenStudentRoutes.StandardEfficiencyReport,
    KGStandardEfficiencyReport,
    Constant.LayoutType.KINDERGARTEN as layoutType
  ),
  new Route(
    RouteConstant.KindergartenStudentRoutes.DiagnosticsTestResult,
    KGCannedTestResult,
    Constant.LayoutType.KINDERGARTEN as layoutType
  ),
  new Route(
    RouteConstant.StudentRoute.DiagnosticsTestResult,
    ELCannedTestResult,
    Constant.LayoutType.ELEMENTARY as layoutType
  ),
  new Route(
    RouteConstant.MiddleSchool.DiagnosticsTestResult,
    MSCannedTestResult,
    Constant.LayoutType.MIDDLESCHOOL as layoutType
  ),
  new Route(
    RouteConstant.MiddleSchool.CannedTestPractice,
    MSCannedTestPractice,
    Constant.LayoutType.MIDDLESCHOOL as layoutType
  ),
  new Route(
    RouteConstant.MiddleSchool.SummativeTest,
    StudyPlanSummativeTest,
    Constant.LayoutType.MIDDLESCHOOL as layoutType
  ),
  new Route(
    RouteConstant.MiddleSchool.CannedTestGC,
    StudyPlanCannedTest,
    Constant.LayoutType.MIDDLESCHOOL as layoutType
  ),
  new Route(
    RouteConstant.LiftOffRoutes.MiddleSchoolHOME,
    AssignmentsMiddleSchool,
    Constant.LayoutType.MIDDLESCHOOLLiftOff as layoutType
  ),
  new Route(
    RouteConstant.LiftOffRoutes.MiddleSchoolDiagnostic,
    DiagnosticsMS,
    Constant.LayoutType.MIDDLESCHOOLLiftOff as layoutType
  ),
  new Route(
    RouteConstant.LiftOffRoutes.MiddleSchoolMyGalaxySubject,
    MyGalaxySubjectMS,
    Constant.LayoutType.MIDDLESCHOOLLiftOff as layoutType
  ),
  new Route(
    RouteConstant.LiftOffRoutes.MiddleSchoolMyGalaxy,
    MyGalaxyMS,
    Constant.LayoutType.MIDDLESCHOOLLiftOff as layoutType
  ),
  new Route(
    RouteConstant.LiftOffRoutes.MiddleSchoolMyGalaxy,
    MyGalaxyMS,
    Constant.LayoutType.MIDDLESCHOOLLiftOff as layoutType
  ),
  new Route(
    RouteConstant.MiddleSchool.LiftOffDiagnostic,
    LiftOffDiagnosticActivity,
    Constant.LayoutType.MIDDLESCHOOLLiftOff as layoutType
  ),
  new Route(
    RouteConstant.MiddleSchool.LiftOffMyGalaxy,
    LiftOffMyGalaxyStandardActivity,
    Constant.LayoutType.MIDDLESCHOOLLiftOff as layoutType
  ),
  new Route(
    RouteConstant.MiddleSchool.LiftOffGameChooseMS,
    GameChoose,
    Constant.LayoutType.MIDDLESCHOOLLiftOff as layoutType
  ),
  new Route(
    RouteConstant.MiddleSchool.SummativeTestResult,
    SummativeTestResult_MS,
    Constant.LayoutType.MIDDLESCHOOL as layoutType
  ),
  new Route(
    RouteConstant.MiddleSchool.DomainTest,
    StudyPlanDomainTest,
    Constant.LayoutType.MIDDLESCHOOL as layoutType
  ),
  new Route(
    RouteConstant.MiddleSchool.DomainTestResult,
    DomainTestResult_MS,
    Constant.LayoutType.MIDDLESCHOOL as layoutType
  ),
  new Route(
    RouteConstant.MiddleSchool.cannedTestResult,
    CannedTestResult_MS,
    Constant.LayoutType.MIDDLESCHOOL as layoutType
  ),
  new Route(
    RouteConstant.TeacherRoutes.crReport,
    ConstructedResponseReport,
    Constant.LayoutType.TEACHER as layoutType
  ),
  new Route(
    RouteConstant.TeacherRoutes.gradeCRQuestion,
    GradeConstructedResponseQuestion,
    Constant.LayoutType.TEACHER as layoutType
  ),
  //LiftOff Routes
  //Kindergarten

  //Elementary
  new Route(
    RouteConstant.LiftOff.Elementary.Home,
    LiftOffELHome,
    Constant.LayoutType.ELEMENTARY as layoutType
  ),
  new Route(
    RouteConstant.LiftOff.Elementary.DiagnosticActivity,
    LiftOffDiagnosticActivity,
    Constant.LayoutType.ELEMENTARY as layoutType
  ),
  new Route(
    RouteConstant.LiftOff.Elementary.MyGalaxyActivity,
    LiftOffMyGalaxyStandardActivity,
    Constant.LayoutType.ELEMENTARY as layoutType
  ),
  new Route(
    RouteConstant.LiftOff.Elementary.DiagnosticResult,
    ELLIFTOFFDIAGNOSTICRESULT,
    Constant.LayoutType.ELEMENTARY as layoutType
  ),
  new Route(
    RouteConstant.LiftOff.Elementary.MyGalaxyResult,
    ELLIFTOFFMYGALAXYSTANDARDRESULT,
    Constant.LayoutType.ELEMENTARY as layoutType
  ),
  new Route(
    RouteConstant.LiftOff.Elementary.GameChoose,
    GameChoose,
    Constant.LayoutType.ELEMENTARY as layoutType
  ),
  new Route(
    RouteConstant.LiftOff.Elementary.MyStat,
    MyStat,
    Constant.LayoutType.ELEMENTARY as layoutType
  ),
  new Route(
    RouteConstant.LiftOff.Elementary.Leaderboard,
    LeaderboardComponent,
    Constant.LayoutType.ELEMENTARY as layoutType
  ),

  //MiddleSchool
  new Route(
    RouteConstant.LiftOff.MiddleSchool.Home,
    LiftOffELHome,
    Constant.LayoutType.MIDDLESCHOOLLiftOff as layoutType
  ),
  new Route(
    RouteConstant.LiftOff.MiddleSchool.DiagnosticActivity,
    LiftOffDiagnosticActivity,
    Constant.LayoutType.MIDDLESCHOOLLiftOff as layoutType
  ),
  new Route(
    RouteConstant.LiftOff.MiddleSchool.MyGalaxyActivity,
    LiftOffMyGalaxyStandardActivity,
    Constant.LayoutType.MIDDLESCHOOLLiftOff as layoutType
  ),
  new Route(
    RouteConstant.LiftOff.MiddleSchool.DiagnosticResult,
    ELLIFTOFFDIAGNOSTICRESULT,
    Constant.LayoutType.MIDDLESCHOOLLiftOff as layoutType
  ),
  new Route(
    RouteConstant.LiftOff.MiddleSchool.MyGalaxyResult,
    ELLIFTOFFMYGALAXYSTANDARDRESULT,
    Constant.LayoutType.MIDDLESCHOOLLiftOff as layoutType
  ),
  new Route(
    RouteConstant.LiftOff.MiddleSchool.GameChoose,
    GameChoose,
    Constant.LayoutType.MIDDLESCHOOLLiftOff as layoutType
  ),
  new Route(
    RouteConstant.LiftOff.MiddleSchool.MyStat,
    MyStat,
    Constant.LayoutType.MIDDLESCHOOLLiftOff as layoutType
  ),
  new Route(
    RouteConstant.LiftOff.MiddleSchool.Leaderboard,
    leaderboardMiddleSchool,
    Constant.LayoutType.MIDDLESCHOOLLiftOff as layoutType
  ),
  new Route(
    RouteConstant.MiddleSchool.StudentAssignmentActivityMS,
    StudentAssignmentActivity,
    Constant.LayoutType.MIDDLESCHOOL as layoutType
  ),
  new Route(
    RouteConstant.KindergartenStudentRoutes.StudentAssignmentActivity,
    StudentAssignmentActivity,
    Constant.LayoutType.KINDERGARTEN as layoutType
  ),

  new Route(
    RouteConstant.StudentRoute.AssignmentResult,
    AssignmentResultEL,
    Constant.LayoutType.ELEMENTARY as layoutType
  ),

  new Route(
    RouteConstant.TeacherRoutes.UserLiftOffManagement,
    UserLiftoffManagement,
    Constant.LayoutType.TEACHER as layoutType
  ),
  new Route(
    RouteConstant.TeacherRoutes.nweaMapping,
    NweaMapping,
    Constant.LayoutType.TEACHER as layoutType
  ),
  new Route(
    RouteConstant.KindergartenStudentRoutes.AssignmentResult,
    AssignmentResultKG,
    Constant.LayoutType.KINDERGARTEN as layoutType
  ),
  new Route(
    RouteConstant.MiddleSchool.AssignmentResult,
    AssignmentResultMS,
    Constant.LayoutType.MIDDLESCHOOL as layoutType
  ),
  new Route(
    RouteConstant.SESSION_ACTIVITY_ASSIGNMENTRESULT,
    SessionActivityAssignmentResult,
    Constant.LayoutType.TEACHER as layoutType
  ),
  new Route(
    RouteConstant.SESSION_ACTIVITY_DIAGNOSTICRESULT,
    SessionActivityDiagnosticResult,
    Constant.LayoutType.TEACHER as layoutType
  ),
  new Route(
    RouteConstant.SESSION_ACTIVITY_LIFTOFFDIAGNOSTICRESULT,
    SessionActivityLiftOffDiagResult,
    Constant.LayoutType.TEACHER as layoutType
  ),
  new Route(
    RouteConstant.SESSION_ACTIVITY_MYGALAXYRESULT,
    SessionActivityMyGalaxyResult,
    Constant.LayoutType.TEACHER as layoutType
  ),
  new Route(
    RouteConstant.SESSION_ACTIVITY_STUDYPLANRESULT,
    SessionActivityStudyPlanResult,
    Constant.LayoutType.TEACHER as layoutType
  ),
  new Route(
    RouteConstant.MiddleSchool.VideoResult,
    VideoQuestionResult_MS,
    Constant.LayoutType.MIDDLESCHOOL as layoutType
  ),
  new Route(
    RouteConstant.StudentRoute.VideoResult,
    VideoQuestionResult_EL,
    Constant.LayoutType.ELEMENTARY as layoutType
  ),
  new Route(
    RouteConstant.KindergartenStudentRoutes.VideoResult,
    VideoQuestionResult_KG,
    Constant.LayoutType.KINDERGARTEN as layoutType
  ),
  new Route(
    RouteConstant.StudentRoute.StudentAssessmentActivity,
    StudentAssessmentActivity,
    Constant.LayoutType.ELEMENTARY as layoutType
  ),
  new Route(
    RouteConstant.KindergartenStudentRoutes.StudentAssessmentActivity,
    StudentAssessmentActivity,
    Constant.LayoutType.KINDERGARTEN as layoutType
  ),
  new Route(
    RouteConstant.MiddleSchool.StudentAssessmentActivity,
    StudentAssessmentActivity,
    Constant.LayoutType.MIDDLESCHOOL as layoutType
  ),
  new Route(
    RouteConstant.TeacherRoutes.StudentAssignmentResult,
    StudentAssignmentResult,
    Constant.LayoutType.TEACHER as layoutType
  ),
  new Route(
    RouteConstant.TeacherRoutes.StudentStandardEfficiencyReport,
    StudentStandardEfficiencyResult,
    Constant.LayoutType.TEACHER as layoutType
  ),
  new Route(
    RouteConstant.TeacherRoutes.StudentPracticeResult,
    StudentPracticeResult,
    Constant.LayoutType.TEACHER as layoutType
  ),
  new Route(
    RouteConstant.TeacherRoutes.StudentAssignmentResultBySearchActivity,
    StudentAssignmentResult,
    Constant.LayoutType.TEACHER as layoutType
  ),
  new Route(
    RouteConstant.TeacherRoutes.StudentPracticeResultBySearchActivity,
    StudentPracticeResult,
    Constant.LayoutType.TEACHER as layoutType
  ),
  new Route(
    RouteConstant.TeacherRoutes.StudentLiftoffDiagnosticResultBySearchActivity,
    ELLIFTOFFDIAGNOSTICRESULT,
    Constant.LayoutType.TEACHER as layoutType
  ),
  new Route(
    RouteConstant.TeacherRoutes.StudentMyGalaxyStandardResultBySearchActivity,
    ELLIFTOFFMYGALAXYSTANDARDRESULT,
    Constant.LayoutType.TEACHER as layoutType
  ),
  new Route(
    RouteConstant.TeacherRoutes.StudentLiftoffDiagnosticResultActivity,
    ELLIFTOFFDIAGNOSTICRESULT,
    Constant.LayoutType.TEACHER as layoutType
  ),
  new Route(
    RouteConstant.StudentRoute.ELStudentLiftoffDiagnosticResultByGradedWork,
    ELLIFTOFFDIAGNOSTICRESULT,
    Constant.LayoutType.ELEMENTARY as layoutType
  ),
  new Route(
    RouteConstant.MiddleSchool.MSStudentLiftoffDiagnosticResultByGradedWork,
    ELLIFTOFFDIAGNOSTICRESULT,
    Constant.LayoutType.MIDDLESCHOOL as layoutType
  ),
  new Route(
    RouteConstant.StudentRoute.ELStudentMyGalaxyStandardResultByGradedWork,
    ELLIFTOFFMYGALAXYSTANDARDRESULT,
    Constant.LayoutType.ELEMENTARY as layoutType
  ),
  new Route(
    RouteConstant.MiddleSchool.MSStudentMyGalaxyStandardResultByGradedWork,
    ELLIFTOFFMYGALAXYSTANDARDRESULT,
    Constant.LayoutType.MIDDLESCHOOL as layoutType
  ),
  new Route(
    RouteConstant.TeacherRoutes.StudentMyGalaxyStandardResultActivity,
    ELLIFTOFFMYGALAXYSTANDARDRESULT,
    Constant.LayoutType.TEACHER as layoutType
  ),

  new Route(
    RouteConstant.KindergartenStudentRoutes.ProgressReport,
    ProgressReport,
    Constant.LayoutType.KINDERGARTEN as layoutType
  ),
  new Route(
    RouteConstant.StudentRoute.ProgressReport,
    ProgressReport,
    Constant.LayoutType.ELEMENTARY as layoutType
  ),
  new Route(
    RouteConstant.MiddleSchool.ProgressReport,
    ProgressReport,
    Constant.LayoutType.MIDDLESCHOOL as layoutType
  ),

  new Route(
    RouteConstant.TeacherRoutes.LiftoffDiagnosticReport,
    LiftoffDiagnosticReport,
    Constant.LayoutType.TEACHER as layoutType
  ),
  new Route(
    RouteConstant.StudentRoute.StudentAlienArena,
    StudentAlienArena,
    Constant.LayoutType.ELEMENTARY as layoutType
  ),
  new Route(
    RouteConstant.StudentRoute.StudentAlienArenaPopUp,
    StudentAlienArenaPopUp,
    Constant.LayoutType.ELEMENTARY as layoutType
  ),
  new Route(
    RouteConstant.StudentRoute.StudentAlienArenaFinalResult,
    StudentAlienArenaFinalResult,
    Constant.LayoutType.ELEMENTARY as layoutType
  ),
  new Route(
    RouteConstant.StudentRoute.StudentAlienArenaResults,
    StudentAlienArenaResults,
    Constant.LayoutType.ELEMENTARY as layoutType
  ),
  new Route(
    RouteConstant.StudentRoute.StudentAlienArenaStartSession,
    StudentAlienArenaStartSession,
    Constant.LayoutType.ELEMENTARY as layoutType
  ),
  new Route(
    RouteConstant.StudentRoute.DomainTest,
    StudyPlanDomainTest_EL,
    Constant.LayoutType.ELEMENTARY as layoutType
  ),
  new Route(
    RouteConstant.StudentRoute.SummativeTest,
    StudyPlanSummativeTest_EL,
    Constant.LayoutType.ELEMENTARY as layoutType
  ),
  new Route(
    RouteConstant.StudentRoute.CannedTestGC,
    StudyPlanCannedTest_EL,
    Constant.LayoutType.ELEMENTARY as layoutType
  ),
  new Route(
    RouteConstant.StudentRoute.SummativeTestResult,
    SummativeTestResult_EL,
    Constant.LayoutType.ELEMENTARY as layoutType
  ),
  new Route(
    RouteConstant.StudentRoute.DomainTestResult,
    DomainTestResult_EL,
    Constant.LayoutType.ELEMENTARY as layoutType
  ),
  new Route(
    RouteConstant.StudentRoute.cannedTestResult,
    CannedTestResult_EL,
    Constant.LayoutType.ELEMENTARY as layoutType
  ),
  new Route(
    RouteConstant.TeacherRoutes.LearnosityItemAnalysis,
    LearnosityItemAnalysis,
    Constant.LayoutType.TEACHER as layoutType
  ),
  new Route(
    RouteConstant.TeacherRoutes.InstructionalResourceVideos,
    InstructionalResourcesVideos,
    Constant.LayoutType.TEACHER as layoutType
  ),
  new Route(
    RouteConstant.KindergartenStudentRoutes.StudentRetryAssignmentActivityK1,
    RetryMissedItems,
    Constant.LayoutType.KINDERGARTEN as layoutType
  ),
  new Route(
    RouteConstant.StudentRoute.StudentRetryAssignmentActivityEL,
    RetryMissedItems,
    Constant.LayoutType.ELEMENTARY as layoutType
  ),
  new Route(
    RouteConstant.MiddleSchool.StudentRetryAssignmentActivityMS,
    RetryMissedItems,
    Constant.LayoutType.MIDDLESCHOOL as layoutType
  ),
  new Route(
    RouteConstant.TeacherRoutes.LearnosityLiveMonitoring,
    LearnosityLiveMonitoring,
    Constant.LayoutType.TEACHER as layoutType
  ),
  new Route(
    RouteConstant.TeacherRoutes.StudentSetting,
    StudentSetting,
    Constant.LayoutType.TEACHER as layoutType
  ),
  new Route(
    RouteConstant.TeacherRoutes.gradeAssesmentWithCR,
    GradeAssesmentWithConstructedResponse,
    Constant.LayoutType.TEACHER as layoutType
  ),
  new Route(
    RouteConstant.TeacherRoutes.ResultsReport,
    ResultsReport,
    Constant.LayoutType.TEACHER as layoutType
  ),
];

import classNames from "classnames";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { promoteGrade, setIsLocked } from "../../../api/student/studentProfile";
import userApi from "../../../api/userApi";
import ImpersonateUserModel from "../../../model/users/impersonateUser";
import Profile from "../../../model/users/profile";
import { fetchProfile } from "../../../redux/actions/userActions";
import * as userContextAction from "../../../redux/actions/userContextAction";
import Constant from "../../../utils/constant/constant";
import RouteConstant from "../../../utils/constant/routeConstant";
import Download from "../../../utils/downloadHelper";
import { impersonateUser } from "../../../utils/impersonateHelper";
import Permissions from "../../../utils/permissions";
import AccessDenied from "../../shared/accessDenied";
import { useConfirmation } from "../../shared/confirmation/confirmationService";
import AllowedTo from "../../shared/rbac";
import PasswordCard from "./popups/passwordCard";
import StudentModal from "../popup/manageUser/studentModal";
import Filter from "./filter";
import Grid from "./grid";
import GroupSction from "./groupSction";
import MergeUsers from "./popups/mergeUsers";
import TeacherClasses from "./teacherClasses";
import Breadcrumb from "../teacher/shared/breadcrumb";
import { IBreadcrumb } from "../../../model/common/breadcrumb";
import { useTranslation } from "react-i18next";
import { DEFAULT_PAGE_SIZE_50 } from "../../../utils/pagingConstant";
import NonEmailLoginUsers from "../popup/manageUser/NonEmailLoginUsers";
import ITabPermission, {
  ITabRequest,
} from "../../../model/interface/tabPermission";
import UpGradeMessagePopup from "../../shared/upGradeMessagePopup";
import { getPermissionBasedTabs } from "../../shared/permissionHelper";
import constant from "../../../utils/constant/constant";
import { SendPasswordResetEmail } from "../../../api/account/accountApi";
import StudentClasses from "./studentClasses";

interface ManageUserProps {
  userContext?: userContextAction.UserContextState;
  profile?: Profile;
  setUserContext: Function;
  setProfile: Function;
}

interface IUser {
  userId: number;
  name: string;
}

const ManageUser = (props: ManageUserProps) => {
  const { t } = useTranslation();
  const getTab = () => {
    const requestedTabs: Array<ITabRequest> = [
      {
        name: "Manage Students",
        href: "#",
        roles: [4, 5, 6, 7],
      },
      {
        name: "Manage Teachers",
        href: "#",
        roles: [4, 5],
        rolesForUpgrade: [7],
      },
      {
        name: "Manage District Users",
        href: "#",
        roles: [4],
      },
    ];
    const tempTab: Array<ITabPermission> = getPermissionBasedTabs(
      requestedTabs,
      props.userContext?.roleId ?? 0
    );
    return tempTab;
  };

  type ExportType =
    | "NONE"
    | "CSV"
    | "PDF"
    | "PasswordCard"
    | "Impersonatedpdf"
    | "Impersonatedcsv";
  type MergeType =
    | "ManualStudentMerge"
    | "ManualTeacherMerge"
    | "PotentialDuplicateStudent";
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE_50);
  const [pageNumber, setPageNumber] = useState(1);
  const [orderColumn, setOrderColumn] = useState("LastName");
  const [sortByDesc, setSortByDesc] = useState(false);
  const [showLoader, setLoader] = useState(false);
  const [studentsData, setStudentData] = useState<any[]>([]);
  const [showNoData, setShowNoData] = useState(true);
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [searchQuery, setSearchQuery] = useState<any>();
  const [isLocked, SetIsLocked] = useState(false);
  const [showInActive, setShowInActive] = useState(false);
  const [showStudentModal, setShowStudentModal] = useState(false);
  const [selectedTab, setSelectedTab] = useState(
    Constant.UserRoleId.District === props.userContext?.roleId
      ? Constant.ManageUserMode.District
      : Constant.ManageUserMode.Student
  );
  const [tabs, setTabs] = useState<ITabPermission[]>(getTab());
  const [selectedUser, setSelectedUser] = useState<any>();
  const [selectedUserIds, setSelectedUserIds] = useState<number[]>([]);
  const [showIdCardModal, setIdCardPopuup] = useState(false);
  const [showMergeUsers, setShowMergeUsers] = useState(false);
  const [showTeacherClasses, setShowTeacherClasses] = useState(false);
  const [selectedTeacherToShowClass, setSelectedTeacherToShowClass] =
    useState<any>({});
  const [selectedStudentToShowClass, setSelectedStudentToShowClass] =
    useState<IUser>({ userId: 0, name: "" } as IUser);
  const [showStudentClasses, setShowStudentClasses] = useState<boolean>(false);
  const [mergeType, setMergeType] = useState<MergeType>("ManualStudentMerge");
  const [mergeUserId, setMergeUserId] = useState<number>(0);
  const [selectAll, setSelectAll] = useState(false);
  const [nonEmailLoginUsers, setNonEmailLoginUsers] = useState<any>();
  const [showMailLoginUsersPopup, setShowMailLoginUsersPopup] = useState(false);
  const [showUpgradePopup, setShowUpgradePopup] = useState<boolean>(false);
  const history = useHistory();
  const confirm = useConfirmation();

  const handleStudentPopupClick = (e) => {
    e.preventDefault();
    setSelectedUser(null);
    setShowStudentModal(!showStudentModal);
  };

  const onHandleSearch = (
    searchFiler: any,
    showLocked: any = undefined,
    showInActive: any = undefined
  ) => {
    setSearchQuery(searchFiler);
    getUser(1, searchFiler, "NONE", pageSize, showLocked, showInActive);
  };

  const handleSortClick = (sortColumn: string, sortBy: number) => {
    setOrderColumn(sortColumn);
    setSortByDesc(sortBy === 1 ? true : false);
  };

  const getUser = (
    pageNumber: number,
    searchFiler: any,
    exportType: ExportType = "NONE",
    currentPageSize: number,
    showLockedParam: any = undefined,
    showInActiveParam: any = undefined
  ) => {
    setSelectedUserIds([]);
    setSelectAll(false);
    setCurrentPageNumber(pageNumber);
    setPageSize(currentPageSize);
    if (!searchFiler) {
      searchFiler = searchQuery;
    }
    var userSearchQuery: any = {
      userId: 0,
      firstName: searchFiler?.firstName ? searchFiler.firstName : "",
      lastName: searchFiler?.lastName ? searchFiler.lastName : "",
      username: searchFiler?.username ? searchFiler.username : "",
      gradeId: searchFiler?.gradeId ? searchFiler.gradeId : 0,
      classId: searchFiler?.classId ? searchFiler.classId : 0,
      studentId: searchFiler?.studentId ? searchFiler.studentId : "",
      roleId:
        selectedTab === Constant.ManageUserMode.Student
          ? Constant.UserRoleId.Student
          : selectedTab === Constant.ManageUserMode.Teacher
          ? Constant.UserRoleId.SchoolTeacher
          : selectedTab === Constant.ManageUserMode.District
          ? Constant.UserRoleId.District
          : 0,
      schoolAccountId: searchFiler?.schoolAccountId ?? 0,
      OrderByColumn: orderColumn,
      SortByDesc: sortByDesc,
      isActive: showInActiveParam ?? !showInActive,
      showLocked: showLockedParam ?? isLocked,
      PageSize:
        exportType === "PDF" || exportType === "CSV"
          ? Constant.Pagination.DefaultPageSize
          : currentPageSize,
      PageNumber:
        exportType === "PDF" || exportType === "CSV"
          ? Constant.Pagination.DefaultPageNumber
          : pageNumber,
      exportType: exportType,
      districtId: props.userContext?.districtId,
    };

    if (userSearchQuery.schoolAccountId !== 0) {
      setLoader(true);
      var response = userApi.GetAllUsers(userSearchQuery);
      response?.then((d) => {
        setLoader(false);
        if (
          exportType === "PDF" ||
          exportType === "CSV" ||
          exportType === "Impersonatedpdf" ||
          exportType === "Impersonatedcsv"
        ) {
          handleUserPrint(d.data.data, exportType);
        } else {
          if (d.data.totalRecords > 0) {
            setShowNoData(false);
            const data = d.data.data;
            setStudentData(data.map((d) => ({ ...d, isSelected: false })));
            setTotalRecords(d.data.totalRecords);
          } else {
            setShowNoData(true);
            setTotalRecords(0);
            setStudentData([]);
          }
        }
      });
    }
  };

  const handleUserEdit = (user: any) => {
    setSelectedUser(user);
    setShowStudentModal(!showStudentModal);
  };

  const handleRefreshData = () => {
    setSelectedUser(null);
    getUser(currentPageNumber, searchQuery, "NONE", pageSize);
  };

  const handleRefreshFilters = () => {
    setOrderColumn("LastName");
    setSortByDesc(false);
  };

  const handlePasswordCardModal = (isClose) => {
    setIdCardPopuup(isClose);
  };

  const handleUserPrint = (data: any, exportType: ExportType) => {
    if (data && data.length > 0 && exportType !== "NONE") {
      if (data[0].base64StringData) {
        const fileName =
          selectedTab === Constant.ManageUserMode.Student
            ? "Manage Student"
            : selectedTab === Constant.ManageUserMode.Teacher
            ? "Manage Teacher"
            : selectedTab === Constant.ManageUserMode.District
            ? "Manage District"
            : "Manage User";
        const hasMultipleFiles = data[0].hasMultipleFiles;
        const extension =
          exportType === "PDF" || exportType === "Impersonatedpdf"
            ? ".pdf"
            : hasMultipleFiles
            ? "zip"
            : "csv";
        Download(data[0].base64StringData, fileName, extension);
      }
    }
  };

  const handleShowMailLoginUsersPopup = (value: boolean) => {
    setShowMailLoginUsersPopup(value);
    if (!value && selectedUserIds.length > 0) {
      const userIds = studentsData
        .filter((stud) => stud.email && selectedUserIds.includes(stud.userId))
        .map((usr) => {
          return usr.userId;
        });
      if (userIds && userIds.length > 0) {
        handleEmailLoginInformation(userIds);
      }
    }
  };

  const clearUserSelection = () => {
    setSelectedUserIds([]);
  };

  const OnHandleShowIsLocked = (isLocked) => {
    SetIsLocked(isLocked);
  };

  const OnHandleShowInActive = (inActive) => {
    setShowInActive(inActive);
  };

  const deleteStudents = () => {
    if (selectedUserIds?.length > 0) {
      confirm({
        variant: "danger",
        title: "Please Confirm",
        description:
          "Warning - you are about to delete all the selected users from the system. Are you sure you want to delete them?",
      }).then((d) => {
        setLoader(true);
        var response = userApi.Delete(
          selectedUserIds,
          props.userContext?.userId ?? 0
        );
        response?.then((d) => {
          setLoader(false);
          setSelectedUserIds([]);
          toast.success("Users deleted successfully");
          handleRefreshData();
        });
      });
    } else {
      toast.error("Please select users to delete.");
    }
  };

  const bulkEmailLoginInformation = () => {
    if (selectedUserIds.length) {
      confirm({
        variant: "danger",
        title: "Please Confirm",
        description:
          "Are you sure you want to send a password reset link to this user? The email will only contain the users Account ID and Username with a link to reset the password.",
      }).then((d) => {
        if (selectedTab === Constant.ManageUserMode.Student) {
          const tempStudents = studentsData.filter(
            (stud) => !stud.email && selectedUserIds.includes(stud.userId)
          );
          if (tempStudents.length > 0) {
            setShowMailLoginUsersPopup(true);
            setNonEmailLoginUsers(tempStudents);
          } else {
            handleEmailLoginInformation(selectedUserIds);
          }
        } else {
          handleEmailLoginInformation(selectedUserIds);
        }
      });
    } else {
      toast.error("Please select users to send email login information.");
    }
  };

  const handleGradePromote = (selectedGradeId: number) => {
    if (selectedUserIds?.length > 0 && selectedGradeId > 0) {
      setLoader(true);
      var response = promoteGrade(
        selectedUserIds,
        props.userContext?.userId ?? 0,
        selectedGradeId
      );
      response?.then((d) => {
        setLoader(false);
        toast.success("Students grade promoted successfully");
        handleRefreshData();
      });
    } else {
      toast.error("Please select users");
    }
  };

  const handleMergeUser = (userId: number) => {
    setMergeUserId(userId);
    if (selectedTab === Constant.ManageUserMode.Student) {
      setMergeType("ManualStudentMerge");
    } else if (selectedTab === Constant.ManageUserMode.Teacher) {
      setMergeType("ManualTeacherMerge");
    }
    setShowMergeUsers(true);
  };

  const handleEmailLoginInformation = (userIds: Array<number>) => {
    SendPasswordResetEmail(userIds).then((r) => {
      toast.info("Login information successfully sent.");
      handleRefreshData();
    });
  };

  const handleLockStudent = (userIds: Array<number>, isLocked: boolean) => {
    setIsLocked(userIds, isLocked, props.userContext?.userId ?? 0).then((d) => {
      toast.info(
        "User(s) " + (isLocked ? "locked" : "unlocked") + " successfully."
      );
      handleRefreshData();
    });
  };

  const loginAsUser = (user: any) => {
    var message = "";
    var title = "";
    if (selectedTab === Constant.ManageUserMode.Student) {
      message =
        "<b>Student: </b>" +
        user.lastName +
        ", " +
        user.firstName +
        " <br/> <br/> This will log you into the selected student's account via impersonation. If you would like to see a demo student view instead, you can do so by navigating to 'Student View' located within Class Center on your sidebar. You will be able to navigate back to your account by selecting 'Return to your account' at the top of the page when you are finished.";

      title = "Login As Student";
    } else if (selectedTab === Constant.ManageUserMode.Teacher) {
      message =
        "<b>Teacher: </b>" +
        user.lastName +
        " " +
        user.firstName +
        " <br/> <br/> This will log you out from your account and login into the teacher's account. You will be able to return to your account when you are finished.";
      title = "Login As Teacher";
    } else if (selectedTab === Constant.ManageUserMode.District) {
      message =
        "<b>District User: </b>" +
        user.lastName +
        " " +
        user.firstName +
        " <br/> <br/> This will log you out from your account and login into the district users's account. You will be able to return to your account when you are finished.";
      title = "Login As District User";
    }
    confirm({
      variant: "danger",
      title: title,
      description: message,
    }).then((d) => {
      loginAsUserConfirm(user);
    });
  };

  const loginAsUserConfirm = (user: any) => {
    if (user !== undefined) {
      userApi.GetUserDetail(user.userId).then((res) => {
        //explicit call to get password
        var impersonateUserDetails = new ImpersonateUserModel(
          user.username,
          atob(res.data.passwordHash),
          user.accountId,
          props.setUserContext,
          props.setProfile,
          props.userContext?.userId,
          localStorage.getItem("AuthToken")?.toString(),
          history,
          RouteConstant.TeacherRoutes.manageUser,
          props.userContext?.activeExternalRoster ?? ""
        );
        impersonateUser(impersonateUserDetails);
      });
    }
  };

  const deleteSingleUser = (userId: number) => {
    var message: string = "";
    if (selectedTab === Constant.ManageUserMode.Student) {
      message = "Are you sure you wish to delete this student?";
    } else if (selectedTab === Constant.ManageUserMode.Teacher) {
      message = "Are you sure you wish to delete this teacher?";
    } else if (selectedTab === Constant.ManageUserMode.District) {
      message = "Are you sure you wish to delete this district user?";
    }
    confirm({
      variant: "danger",
      title: "Please Confirm",
      description: message,
    }).then((d) => {
      setLoader(false);
      var response = userApi.Delete([userId], props.userContext?.userId ?? 0);
      response?.then((d) => {
        setSelectedUserIds([]);
        toast.success("Users deleted successfully");
        handleRefreshData();
      });
    });
  };

  const handleReactivateUser = (userId: number) => {
    setLoader(true);
    var response = userApi.ReActivateUser(
      [userId],
      props.userContext?.userId ?? 0
    );
    response
      ?.then((d) => {
        setLoader(false);
        setSelectedUserIds([]);
        toast.success("User re-activated successfully");
        handleRefreshData();
      })
      .catch((e) => {
        setLoader(false);
      });
  };

  const handleViewTeacherClasses = (userId: number, name: string) => {
    setSelectedTeacherToShowClass({ userId: userId, name: name });
    setShowTeacherClasses(true);
  };

  const handleViewStudentClasses = (userId: number, name: string) => {
    setSelectedStudentToShowClass({ userId: userId, name: name } as IUser);
    setShowStudentClasses(true);
  };

  function handleViewPotentailDuplicateStudents() {
    setMergeType("PotentialDuplicateStudent");
    setShowMergeUsers(true);
  }

  function handleUserRoleChange(userId: number, roleId: number): void {
    userApi
      .UpdateUserRole(userId, roleId, props.userContext?.userId ?? 0)
      .then((d) => {
        toast.success("User role changed successfully.");
        handleRefreshData();
      });
  }

  useEffect(() => {
    setTabs(tabs);
  }, []);

  useEffect(() => {
    getUser(currentPageNumber, searchQuery, "NONE", pageSize);
  }, [orderColumn, sortByDesc]);

  const handleSelectAll = (isChecked: boolean) => {
    let tempStudents = studentsData;
    const data = tempStudents.map((s) => {
      s.isSelected = isChecked;
      return s;
    });
    const selectedUserids = isChecked ? data.map((s) => s.userId) : [];
    setSelectedUserIds(selectedUserids);
    setSelectAll(isChecked);
    setStudentData(data);
  };

  const handleSelectSingle = (isChecked: boolean, userId: number) => {
    let tempStudents = studentsData;
    const data = tempStudents.map((s) => {
      if (s.userId === userId) {
        s.isSelected = isChecked;
      }
      return s;
    });
    if (!isChecked) {
      setSelectAll(false);
    }

    const selectedUserids = data
      .filter((s) => s.isSelected)
      .map((s) => s.userId);
    setSelectedUserIds(selectedUserids);
    setStudentData(data);
  };

  const breadcrumbItems = () => {
    const items: Array<IBreadcrumb> = [
      { name: t("Breadcrumb.Home"), url: RouteConstant.ROUTE_DASHBOARD },
      {
        name: t("Breadcrumb.UserManagement"),
        url: "",
      },
    ];

    return items;
  };

  const getCodeForManageUser = (tabName: string) => {
    let code = "";
    switch (tabName.trim()) {
      case "Manage Students":
        code = Constant.ManageUserMode.Student;
        break;
      case "Manage Teachers":
        code = Constant.ManageUserMode.Teacher;
        break;
      case "Manage District Users":
        code = Constant.ManageUserMode.District;
        break;
    }
    return code;
  };

  return (
    <>
      {showUpgradePopup && (
        <UpGradeMessagePopup
          togglePopup={setShowUpgradePopup}
          roleId={props.userContext?.roleId ?? 0}
          message={constant.UPGRADE_MESSAGE_SCHOOL_ACCOUNT}
        />
      )}
      <AllowedTo
        perform={Permissions.user_management_view}
        no={() => <AccessDenied />}
      >
        <section className="min-w-0 w-full">
          <div>
            <div className="lg:flex lg:items-center px-4 py-3 lg:px-8 border-b">
              <Breadcrumb items={breadcrumbItems()} />
              <div className="lg:w-[55%] text-lg flex justify-between gap-2 h-auto sm:h-10 items-center">
                <h1 className="text-primary-violet font-medium flex justify-start">
                  User Management
                </h1>
              </div>
            </div>
            <div className="sm:px-6 lg:px-8 pt-2 bg-gray-100 mt-2">
              <div>
                <div className="flex mb-2 sm:mb-0 px-4 sm:px-0 flex-col sm:flex-row items-end sm:justify-between sm:items-center">
                  <nav className="flex space-x-2" aria-label="Tabs">
                    {tabs.map((tab) => {
                      const code = getCodeForManageUser(tab.name);
                      return (
                        <>
                          <AllowedTo
                            perform={Permissions.user_management_create}
                            upgradePermission={tab.permissionName}
                          >
                            <a
                              key={tab.name}
                              href={tab.href}
                              onClick={() => {
                                if (tab.isDisabled) {
                                  setShowUpgradePopup(true);
                                } else {
                                  setSelectedTab(code);
                                  setStudentData([]);
                                  setTotalRecords(0);
                                  handleRefreshFilters();
                                }
                              }}
                              className={
                                tab.className +
                                classNames(
                                  code === selectedTab
                                    ? "border-secondary-teal bg-white text-primary-violet hover:text-dark-violet py-3 tab-box-shadow tab-box-shadow"
                                    : "text-primary-violet text-opacity-60 bg-primary-violet/10 py-[0.6rem] mt-auto transition-all hover:py-3 hover:text-opacity-100 hover:text-primary-violet border-[#dee9f1]",
                                  "px-2 sm:px-4 text-center border-t-2 text-sm cursor-pointer font-medium"
                                )
                              }
                            >
                              {tab.name}
                            </a>
                          </AllowedTo>
                        </>
                      );
                    })}
                  </nav>
                  <AllowedTo perform={Permissions.user_management_create}>
                    <button
                      className="bg-primary-violet border border-transparent shadow-sm my-4 sm:my-0 sm:mb-auto py-2 px-4 justify-center text-sm font-medium text-white hover:bg-dark-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      type="button"
                      onClick={(e) => handleStudentPopupClick(e)}
                    >
                      {selectedTab === Constant.ManageUserMode.Student &&
                        "Add Student"}
                      {selectedTab === Constant.ManageUserMode.Teacher &&
                        "Add Teacher"}
                      {selectedTab === Constant.ManageUserMode.District &&
                        "Add District User"}
                    </button>
                  </AllowedTo>
                </div>
              </div>
            </div>
            <div className="px-2 sm:px-6 lg:px-8 py-6">
              <div className="">
                <div className="grid grid-cols-1 md:grid-cols-4 lg:grid-cols-5 gap-y-6 sm:gap-x-8 md:gap-4 lg:gap-12">
                  <Filter
                    mode={selectedTab}
                    onHandleSearch={onHandleSearch}
                    handlePasswordCardModal={handlePasswordCardModal}
                    handlePrint={() => {
                      getUser(
                        currentPageNumber,
                        searchQuery,
                        props.userContext?.impersonatedUser === null
                          ? "PDF"
                          : "Impersonatedpdf",
                        pageSize
                      );
                    }}
                    onHandleShowIsLocked={OnHandleShowIsLocked}
                    onHandleShowInActive={OnHandleShowInActive}
                    handleExport={() => {
                      getUser(
                        currentPageNumber,
                        searchQuery,
                        props.userContext?.impersonatedUser === null
                          ? "CSV"
                          : "Impersonatedcsv",
                        pageSize
                      );
                    }}
                    clearUserSelection={clearUserSelection}
                  ></Filter>
                  <GroupSction
                    mode={selectedTab}
                    deleteStudents={deleteStudents}
                    handleGradePromote={handleGradePromote}
                    bulkEmailLogin={bulkEmailLoginInformation}
                    selectedUserIds={selectedUserIds}
                    handleViewPotentailDuplicateStudents={
                      handleViewPotentailDuplicateStudents
                    }
                  ></GroupSction>
                </div>
              </div>
            </div>
          </div>

          <Grid
            selectedTab={selectedTab}
            showLoader={showLoader}
            showNoData={showNoData}
            pageSize={pageSize}
            pageNumber={pageNumber}
            handlePageSize={(e) => setPageSize(e)}
            handlePageNumber={(e) => setPageNumber(e)}
            totalRecords={totalRecords}
            studentsData={studentsData ?? []}
            handleSortClick={handleSortClick}
            getUser={getUser}
            handleEdit={handleUserEdit}
            handleDeleteUser={deleteSingleUser}
            handleImpersonateUser={loginAsUser}
            handleLockStudent={handleLockStudent}
            handleMergeStudent={handleMergeUser}
            handleViewTeacherClasses={handleViewTeacherClasses}
            handleViewStudentClasses={handleViewStudentClasses}
            handleReactivateUser={handleReactivateUser}
            handleUserRoleChange={handleUserRoleChange}
            isLocked={isLocked}
            handleSelectAll={handleSelectAll}
            handleSelectSingle={handleSelectSingle}
            selectAll={selectAll}
          />

          {showStudentModal && (
            <StudentModal
              showIdCardModa={showStudentModal}
              {...props}
              mode={selectedTab}
              selectedUser={selectedUser}
              handleRefreshData={handleRefreshData}
              showPopUp={(e) => {
                setShowStudentModal(false);
              }}
            ></StudentModal>
          )}
          {showIdCardModal && (
            <PasswordCard
              {...props}
              mode={selectedTab}
              showIdCardModal={showIdCardModal}
              searchQuery={searchQuery}
              handlePasswordCardModal={handlePasswordCardModal}
            ></PasswordCard>
          )}
          {showMailLoginUsersPopup && nonEmailLoginUsers?.length > 0 && (
            <NonEmailLoginUsers
              users={nonEmailLoginUsers}
              showPopup={(e) => {
                handleShowMailLoginUsersPopup(false);
              }}
            ></NonEmailLoginUsers>
          )}
          {showMergeUsers && (
            <MergeUsers
              showPopUp={(e: boolean) => {
                setShowMergeUsers(false);
                handleRefreshData();
              }}
              mergeType={mergeType}
              mergeUserId={mergeUserId}
              schoolAccountId={props.userContext?.schoolAccountId ?? 0}
            ></MergeUsers>
          )}
          {showTeacherClasses && (
            <TeacherClasses
              showPopUp={(e) => {
                setShowTeacherClasses(false);
              }}
              userId={selectedTeacherToShowClass.userId}
              name={selectedTeacherToShowClass.name}
            ></TeacherClasses>
          )}
          {showStudentClasses && (
            <StudentClasses
              showPopUp={(e) => {
                setShowStudentClasses(false);
              }}
              userId={selectedStudentToShowClass?.userId}
              name={selectedStudentToShowClass?.name}
            ></StudentClasses>
          )}
        </section>
      </AllowedTo>
    </>
  );
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setUserContext: (payload: userContextAction.UserContextState) => {
      dispatch(userContextAction.setUserContext(payload));
    },
    setProfile: (
      userId: number,
      roleId: number,
      schoolId: number,
      districtId: number
    ) => {
      dispatch(fetchProfile(userId, roleId, schoolId, districtId));
    },
  };
};

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageUser);

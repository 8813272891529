export class firebaseConfig {
  constructor(
    public apiKey: string = "",
    public authDomain: string = "",
    public projectId: string = "",
    public storageBucket: string = "",
    public messagingSenderId: string = "",
    public appId: string = ""
  ) {}
}

import { XIcon } from "@heroicons/react/outline";
import copy from "copy-to-clipboard";
import ClipboardCopy from "../../../../../shared/clipboardCopy";
import InfoMessage from "../../../../../shared/infoMessage";

interface UserPasswordPopupProps {
  showPopUp: (show: boolean) => void;
  userName: string;
  password: string;
  name: string;
  title?: string;
  isTeacher?: boolean;
}

const UserPasswordPopup = (props: UserPasswordPopupProps) => {

  return (
    <div className="fixed inset-0 z-40 flex items-center bg-black bg-opacity-50 sm:justify-center enter-done">
      <div
        className="w-full overflow-hidden bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:max-w-2xl"
        role="dialog"
      >
        <div className="flex-1">
          {/* Header */}
          <div className="px-4 py-4 bg-gray-50 sm:px-4">
            <div className="flex items-start justify-between space-x-3">
              <div className="space-y-1">
                <div className="text-lg font-medium text-gray-900">
                  {props.title ?? "Student Login"}
                </div>
              </div>
              <div className="h-7 flex items-center">
                <button
                  type="button"
                  className="text-gray-400 hover:text-gray-500"
                  onClick={() => props.showPopUp(false)}
                >
                  <span className="sr-only">Close panel</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
          <div className="px-3">
            <InfoMessage
              message={`${
                props.isTeacher && props.isTeacher === true
                  ? "Teacher"
                  : "Student"
              } username and password details`}
            />
          </div>

          {/* <!-- Divider container -->  */}
          <div className="py-4 px-6 space-y-2 mb-6 text-sm text-gray-700 dark:text-gray-400">
            <div className="flex gap-x-4 border">
              <div className="w-28 text-right bg-gray-100 py-2 px-4">
                <label
                  htmlFor="start-time"
                  className="text-sm font-medium text-gray-900"
                >
                  Name
                </label>
              </div>
              <div className="flex items-center">
                <label id="name">{props.name} </label>
              </div>
            </div>

            <div className="flex gap-x-4 border">
              <div className="w-28 text-right bg-gray-100 py-2 px-4">
                <label
                  htmlFor="start-time"
                  className="text-sm font-medium text-gray-900"
                >
                  Username
                </label>
              </div>
              <div className="flex items-center border-l-0">
                <label id="userName">{props.userName} </label>
                <span
                  className="cursor-pointer ml-2"
                  onClick={() => {
                    copy(props.userName.toString());
                  }}
                >
                  <div className="pl-1 flex items-center relative">
                    <ClipboardCopy />
                  </div>
                </span>
              </div>
            </div>

            <div className="flex gap-x-4 border">
              <div className="w-28 text-right bg-gray-100 py-2 px-4">
                <label
                  htmlFor="end-time"
                  className="text-sm font-medium text-gray-900"
                >
                  Password
                </label>
              </div>
              <div className="flex items-center">
                <label id="password">{props.password} </label>
                <span
                  className="cursor-pointer ml-2"
                  onClick={() => {
                    copy(props.password.toString());
                  }}
                >
                  <div className="pl-1 flex items-center relative">
                    <ClipboardCopy />
                  </div>
                </span>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Action Buttons -->  */}
        <footer className="flex flex-col items-center justify-end px-6 py-4 sm:flex-row bg-gray-50 dark:bg-gray-800">
          <div className="flex-shrink-0 ">
            <div className="space-x-3 flex justify-end">
              <button
                className="align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 rounded-lg text-sm text-gray-600 border-gray-300 border dark:text-gray-400 focus:outline-none active:bg-transparent hover:border-gray-500 focus:border-gray-500 active:text-gray-500 focus:shadow-outline-gray w-full sm:w-auto"
                type="button"
                onClick={() => {
                  props.showPopUp(false);
                }}
              >
                Close
              </button>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default UserPasswordPopup;

import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import RouteConstant from "../../../../../utils/constant/routeConstant";
import Breadcrumb from "../../shared/breadcrumb";
import { IBreadcrumb } from "../../../../../model/common/breadcrumb";
import Constant from "../../../../../utils/constant/constant";
import DynamicComponent from "../../../../dynamicComponent/dynamicComponent";
import {connect} from "react-redux";

const StudentAssignmentResult = (props: any) => {
  const { t } = useTranslation();
  const parameters = new URLSearchParams(props.location.search);
  const userId = parseInt(props.match.params.userId);
  const gradeId = parseInt(props.match.params.gradeId);
  const url = window.location.href;
  type dynamicComponent = { component: any; parameter: any };
  const [resultComponent, setResultComponent] = useState<dynamicComponent>();
  const [schoolYearId] = useState<number>(
      parseInt(parameters.get("schoolYearId") ?? "0")
  );

  useEffect(() => {
    loadStudentView();
  }, []);

  function loadStudentView() {
    const parameter = {
      isTeacherView: true,
      studentGradeId: gradeId,
      studentId: userId,
      assignmentId: props.match.params.assignmentId,
      assignmentActivityId: props.match.params.assignmentActivityId,
      sessionId: props.match.params.sessionId,
      schoolYearId: schoolYearId
    };

    if (gradeId <= Constant.Grade.GRADE1) {
      setResultComponent({ component: "AssignmentResultKG", parameter });
    } else if (
      gradeId > Constant.Grade.GRADE1 &&
      gradeId < Constant.Grade.GRADE6
    ) {
      setResultComponent({ component: "AssignmentResultEL", parameter });
    } else if (gradeId >= Constant.Grade.GRADE6) {
      setResultComponent({ component: "AssignmentResultMS", parameter });
    }
  }

  const breadcrumbItems = () => {
    let items: Array<IBreadcrumb> = [];
    if (url.indexOf("activitysearch") > -1) {
      items = [
        { name: t("Breadcrumb.Home"), url: RouteConstant.ROUTE_DASHBOARD },
        { name: t("Breadcrumb.Reports"), url: RouteConstant.REPORTS },
        {
          name: t("Breadcrumb.StudentActivitySearchReport"),
          url: RouteConstant.TeacherRoutes.StudentActivitySearchReport,
        },
        { name: "Student Result", url: "" },
      ];
    } else {
      items = [
        { name: t("Breadcrumb.Home"), url: RouteConstant.ROUTE_DASHBOARD },
        {
          name: t("Breadcrumb.GradeBook"),
          url: RouteConstant.TeacherRoutes.GradeBook,
        },
        { name: "Student Result", url: "" },
      ];
    }
    return items;
  };

  return (
    <Fragment>
      <div className="px-4 py-5 lg:px-8 border-b">
        <Breadcrumb items={breadcrumbItems()} />
      </div>
      <div className="relative bg-primary-violet w-full">
        {resultComponent && DynamicComponent(resultComponent)}
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state: any) => {
  return {
    profile: state.profile,
  };
};

export default connect(mapStateToProps)(StudentAssignmentResult);

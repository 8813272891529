import { Fragment, useEffect, useState } from "react";
import Moment from "moment";
import Assessment from "../../../../model/teacher/assessment/assessment";
import { XIcon } from "@heroicons/react/solid";
import { CancelIcon, TagOutlineIcon } from "../../../../assets/icons";
import TagSelector from "../../../shared/tagSelector";
import tagListResponse from "../../../../model/teacher/tags/tagListResponse";
import { TagColor } from "../../../shared/tagColor";
import { getAllTags } from "../../../../api/teacher/tags";
interface AddTagProps {
  userId: number;
  selectedAssessments: Array<Assessment>;
  toggleSettingPopup: (popupAction: boolean, refreshData?: boolean) => void;
  addTags: (tagId: number, assessmentIds: Array<number>) => void;
  removeTags: (
    tagId: number,
    tagname: string,
    assessmentIds: Array<number>
  ) => void;
  onNewTagCreated: () => void;
}

function AddTag(props: AddTagProps) {
  const {
    userId,
    selectedAssessments,
    toggleSettingPopup,
    addTags,
    removeTags,
  } = props;
  const [assessments, setAssessments] =
    useState<Array<Assessment>>(selectedAssessments);
  const [selectedTags, setSelectedTags] = useState<Array<number>>([]);
  const [showTags, setShowTags] = useState<boolean>(false);
  const [assessmentToEdit, setAssessmentToEdit] = useState<Assessment>();
  const [allTags, setAllTags] = useState<Array<tagListResponse>>([]);

  function getUserTags() {
    getAllTags(userId).then((response) => {
      setAllTags(response.data.data);
    });
  }

  useEffect(() => {
    setCommonTags();
    getUserTags();
  }, []);

  function handleRemoveAssessments(assessmentId: number) {
    const updatedAssessments = assessments.filter(
      (r) => parseInt(r.assessmentId) !== assessmentId
    );
    setAssessments(updatedAssessments);
  }

  const setCommonTags = () => {
    const tempTags = assessments.map((r) =>
      r.tagIds !== null && r.tagIds !== "" ? r.tagIds.split(",") : null
    );
    const selectedTags: Array<number> = [];
    tempTags?.map((t) => {
      if (t && t.length > 0) {
        t.map((tag) => {
          selectedTags.push(parseInt(tag));
          return null;
        });
      }
      return null;
    });

    setSelectedTags(selectedTags);
  };

  const onTagClicked = () => {
    setSelectedTags([]);
    setCommonTags();
  };

  const onTagSelectionChange = (tag) => {
    const assessmentIds = assessments.map((a) => parseInt(a.assessmentId));
    if (tag.selected) {
      setSelectedTags([...selectedTags, tag.tagId]);
      addTags(tag.tagId, assessmentIds);
    } else {
      setSelectedTags(selectedTags.filter((item) => item !== tag.tagId));
      removeTags(tag.tagId, tag.name, assessmentIds);
    }
  };

  function getExistingTags(tags: string) {
    const tagIds: Array<string> = tags ? tags.split(",") : [];

    const existingTags: Array<tagListResponse> = allTags.filter((t) =>
      tagIds.includes(t.userTagId.toString())
    );

    return existingTags;
  }

  const onNewTagCreated = () => {
    if (props.onNewTagCreated !== undefined) props.onNewTagCreated();
  };

  return (
    <Fragment>
      <div className="fixed inset-0 z-40 flex items-center bg-black bg-opacity-50 sm:items-center sm:justify-center">
        <div
          className="w-full flex flex-col justify-between overflow-hidden h-auto max-h-80percent bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:max-w-5xl"
          role="dialog"
        >
          <header>
            {/* Header */}
            <div className="px-4 py-4 bg-gray-50">
              <div className="flex items-start justify-between space-x-3">
                <div className="space-y-1">
                  <div className="text-lg font-medium text-gray-900">
                    <h2 className="text-xl mb-2">Add Tag to Assessments</h2>
                  </div>
                </div>
                <div className="h-7 flex items-center">
                  <button
                    type="button"
                    className="text-gray-400 hover:text-gray-500"
                    onClick={() => toggleSettingPopup(false)}
                  >
                    <span className="sr-only">Close panel</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
          </header>
          <div className="w-full relative overflow-auto px-4 py-6">
            <div className="p-3 min-h-25rem">
              <div className="col-span-8 sm:col-span-4 xl:col-span-2 mb-3 w-72">
                <TagSelector
                  label={"Tags"}
                  title="Select one or more assessments using the check boxes to add a tag to 
                      group assessments together to make them searchable."
                  userId={userId}
                  selectedTagIds={selectedTags}
                  onTagSelectChange={onTagSelectionChange}
                  showSelectedTags={false}
                  onTagButtonClicked={onTagClicked}
                  onNewTagCreated={onNewTagCreated}
                />
              </div>
              <div className="mt-3 text-center sm:mt-0 sm:text-left ">
                <table className="table w-full ">
                  <thead className="mb-5">
                    <tr className="bg-secondary-teal  text-white py-3 px-3">
                      <th className="text-left  p-2 text-sm font-normal">
                        Date Created
                      </th>
                      <th className="text-left  p-2 text-sm font-normal">
                        Name
                      </th>
                      <th className="text-left  p-2 text-sm font-normal">
                        Code
                      </th>
                      <th className="text-left  p-3 text-sm font-normal">
                        Tag
                      </th>
                      <th className="text-left  p-2 text-sm font-normal"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {assessments &&
                      assessments?.length > 0 &&
                      assessments?.map((assessment, index) => {
                        return (
                          <Fragment key={index}>
                            <tr
                              className={`hover:bg-secondary-teal/20 transition ease-in-out duration-300 ${
                                index % 2 === 0
                                  ? "bg-white"
                                  : "bg-secondary-teal/10"
                              }`}
                            >
                              <td className="text-sm  p-2">
                                {Moment(assessment.createdDate).format(
                                  "MM-DD-yyyy"
                                )}
                              </td>
                              <td className="text-sm p-2">{assessment.name}</td>
                              <td className="text-sm p-2">{assessment.code}</td>
                              <td
                                className="text-sm mt-5  p-3 cursor-pointer relative"
                                onMouseOver={() => {
                                  if (assessment.tagIds) {
                                    setShowTags(true);
                                    setAssessmentToEdit(assessment);
                                  }
                                }}
                                onMouseOut={() => setShowTags(false)}
                              >
                                <div className="flex gap-1">
                                  <span className="mt-1">
                                    <TagOutlineIcon className="w-4 text-gray-500 hover:text-gray-700 cursor-pointer m-auto" />

                                    <div
                                      className={`absolute bg-blue-50 top-10 right-0  transition-all shadow-lg border border-blue-200 p-4 rounded-lg z-20
                                ${
                                  showTags &&
                                  assessment.assessmentId ===
                                    assessmentToEdit?.assessmentId
                                    ? "visible"
                                    : "hidden"
                                }
                                `}
                                      onMouseOver={() => {
                                        setShowTags(true);
                                        setAssessmentToEdit(assessment);
                                      }}
                                      onMouseOut={() => setShowTags(false)}
                                    >
                                      <div className="flex gap-1 flex-wrap bg-white/50 p-1">
                                        {getExistingTags(assessment.tagIds).map(
                                          (tag, index) => (
                                            <span
                                              key={index}
                                              className={`inline-block rounded-full text-xs shadow-sm py-0.5 px-2 overflow-hidden truncate flex 
                                             ${
                                               TagColor.isDark(tag.color)
                                                 ? "text-white"
                                                 : ""
                                             } bg-[${tag.color}]`}
                                            >
                                              {tag.name}
                                            </span>
                                          )
                                        )}
                                      </div>
                                    </div>
                                  </span>
                                  <>
                                    {assessment.tagIds === null ? (
                                      <span>0</span>
                                    ) : (
                                      <span className="text-blue-500">
                                        {assessment.tagIds.split(",").length}
                                      </span>
                                    )}
                                  </>
                                </div>
                              </td>
                              <td className="text-sm p-2">
                                <CancelIcon
                                  onClick={() =>
                                    handleRemoveAssessments(
                                      parseInt(assessment.assessmentId)
                                    )
                                  }
                                  className="w-4 text-xs font-light cursor-pointer text-gray-500 hover:text-red-500 transaction ease-in-out duration-300"
                                >
                                  Remove
                                </CancelIcon>
                              </td>
                            </tr>
                          </Fragment>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          {/* <!-- Action Buttons -->  */}
          <footer className="flex flex-col items-center justify-end px-6 py-4 sm:flex-row bg-gray-50 dark:bg-gray-800">
            <div className="flex-shrink-0">
              <div className="space-x-3 flex justify-end">
                <button
                  className="align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 rounded-lg text-sm text-gray-600 border-gray-300 border dark:text-gray-400 focus:outline-none active:bg-transparent hover:border-gray-500 focus:border-gray-500 active:text-gray-500 focus:shadow-outline-gray sm:w-auto"
                  type="button"
                  onClick={() => toggleSettingPopup(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </Fragment>
  );
}

export default AddTag;

import { Fragment, useState } from "react"
import Header from "../../shared/header/header";
import { connect } from "react-redux";
import { emailRequestSSO } from "./api";
import { Button } from "@windmill/react-ui";
import CheckBox from "../filterControls/checkbox";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

function RequestSSO(props: any) {
    const syncOptions = [
        "Clever",
        "Classlink",
        "Canvas",
      ];

    const accountTypes = [
        "Teachers",
        "Students"
    ];
    
    const [ticketResponse, setTicketResponse] = useState("")

    const [accountArray, setAccountTypes] = useState<Array<any>>([]);
    const [syncArray, setSyncOptions] = useState<Array<any>>([]);
    const [selectedDate, setStartDate] = useState(new Date());
    const[selectedRoster, setRoster] = useState("No");    
   
    function handleClick() {                    
        emailRequestSSO(props.profile.email, props.userContext.roleId, (syncArray.length > 0 ? syncArray.join(",") : "NIL"),
         (accountArray.length > 0 ? accountArray.join(",") : "NIL"), selectedRoster, (selectedDate === null? "NIL" : moment(selectedDate).format("YYYY-MM-DD"))
         , props.profile.email)?.then(r => setTicketResponse(r.data));
    }

    function toggleSyncOptions(e){        
        var label = e.target.defaultValue;
      
        if (syncArray.indexOf(label) === -1 && e.target.checked) {
            syncArray.push(label);
        } else if(!e.target.checked) {
            var index = syncArray.indexOf(label)
            syncArray.splice(index,1); 
        }     
        setSyncOptions(syncArray);       
     
    }

    function toggleAccountTypes(e){        
        var label = e.target.defaultValue; 
           
        if (accountArray.indexOf(label) === -1 && e.target.checked) {
            accountArray.push(label);
        } else if(!e.target.checked) {
            var index = accountArray.indexOf(label)
            accountArray.splice(index,1); 
        }       
        setAccountTypes(accountArray); 
          
    }

    function onRosterChangeValue(event){       
        setRoster(event.target.value);
    }

    return (
        <Fragment>
            <Header></Header>
            <div className="bg-gray-100">
                <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
                    <div className="relative bg-white shadow-xl">
                        <div className="grid grid-cols-1 lg:grid-cols-3">                           
                            {/* Request form */}
                            <div className="py-10 px-6 sm:px-10 lg:col-span-2 xl:p-12">
                                <h2 className="text-2xl text-primary-green font-semibold">Request Account SSO Integration</h2>
                                <p className="mt-6">Fill out this form to submit an integration case to the support team.
                                You will need to know which schools need to be integrated, which sync service, and
                                the expected launch date.
                                </p>
                                <form action="#" className="mt-8 grid grid-cols-3 grid-flow-row gap-1">
                                    <div className="col-span-2">
                                        <label htmlFor="sync_options" className="block text-sm font-medium text-gray-900">
                                            Would you like to sync with Clever, Classlink, and/or Canvas?
                  </label>
                  </div>
                  
                                        <div className="flex">
                                        {syncOptions.map((label) => (
                                            <CheckBox
                                            label={label}
                                            handleCheckboxChange={toggleSyncOptions}
                                            key={label}
                                        />
                                        ))}
                                        </div>
                                    
                                    <div className="col-span-2">
                                        <label htmlFor="accountTypes" className="block text-sm font-medium text-gray-900">
                                            Would you like to sync teacher and/or student accounts?
                  </label>
                  </div> 
                                        <div className="flex">
                                        {accountTypes.map((label) => (
                                            <CheckBox
                                            label={label}
                                            handleCheckboxChange={toggleAccountTypes}
                                            key={label}
                                        />
                                        ))}
                                        </div>
                                  
                                    <div className="col-span-2">
                                        <label htmlFor="accountTypes" className="block text-sm font-medium text-gray-900">
                                            Would you like for the integration to roster classes for teachers?
                  </label>
                  </div>  
                                        <div className="" onChange={onRosterChangeValue}>
                                            <input type="radio" name="needRoster" value="Yes" className="mr-1"/>Yes
                                            <input type="radio" name="needRoster" value="No" className="ml-2 mr-1"/>No
                                        </div>
                                    
                                    <div className="col-span-2">
                                        <label htmlFor="date" className="block text-sm font-medium text-gray-900">
                                            Desired Launch Date: 
                                        </label>
                                        </div>
                                        <div>
                                        <DatePicker className="ml-2 flex react-datepicker__input-container border rounded-lg p-1 text-sm border-gray-300 " 
                                       selected={selectedDate} onChange={date => setStartDate(date)}  dateFromat='YYYY-MM-dd' />
                                        </div>                                
                                        {ticketResponse !== "" &&
                                    <div className="sm:col-span-3 text-sm bg-green-700 text-white p-1">
                                        {ticketResponse}
                                    </div>
                                    }
                                    <div className="sm:col-span-2 sm:flex">
                                        <Button className="text-white-700 bg-primary-green hover:bg-light-green py-1.5 px-2" layout="primary" onClick={() => handleClick()}>
                                            Request Single Sign-On Support
                                        </Button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

const mapStateToProps = (state: any) => {
    return {
        userContext: state.userContext,
        profile: state.profile,
    };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(RequestSSO);

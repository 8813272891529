/* eslint-disable react/prop-types */
import { connect } from "react-redux";
import RouteConstant from "../../../../../utils/constant/routeConstant";
import Breadcrumb from "../../middleSchool/breadcrumb";
import { IBreadcrumb } from "../../../../../model/common/breadcrumb";
import { useTranslation } from "react-i18next";
import { Configuration } from "../../../../../environment/setup";

function TrophyRoom(props): JSX.Element {
  const { t } = useTranslation();
  const breadcrumbItems = () => {
    const items: Array<IBreadcrumb> = [
      {
        name: t("Breadcrumb.Home"),
        url: RouteConstant.MiddleSchool.assignments,
        textColor: "text-gray-500",
      },
      {
        name: t("Breadcrumb.TrophyRoom"),
        textColor: "text-gray-500",
        url: "",
      },
    ];

    return items;
  };
  return (
    <div className="w-full min-w-0 text-gray-500">
      <>
        <div className="px-4 py-5 lg:px-8 border-b">
          <Breadcrumb items={breadcrumbItems()} />
          <div className="text-lg font-medium flex gap-2 h-auto sm:h-10 items-center">
            <h1 className="text-primary-violet font-medium flex justify-start mb-0 pb-0">
              Trophy Room
            </h1>
          </div>
        </div>

        <div className="sm:px-6 lg:px-8 py-6 w-9/12">
          <div className="bg-gray-100 border rounded-lg p-5">
            <div className="flex justify-between gap-4">
              <div className="bg-white rounded-lg shadow-lg">
                <div className="flex justify-between gap-6 p-4">
                  <div className="font-bold text-primary-purple mt-5">
                    Asteroid
                    <img
                      className="h-16 mt-2"
                      src={`${Configuration.S3bucketImagePath}/images/student/shared/Blasters/cannon4.png`}
                      alt="cannon"
                    />
                  </div>

                  <div>
                    <ul>
                      <li className="py-2 flex justify-between gap-2">
                        <span>Total</span>
                        <span className="font-semibold"> 15</span>
                      </li>
                      <li className="py-2 flex justify-between gap-2">
                        <span>Achieved</span>
                        <span className="font-semibold"> 5</span>
                      </li>
                      <li className="py-2 flex justify-between gap-2">
                        <span>Remaining</span>
                        <span className="font-semibold">10</span>
                      </li>
                      <li className="py-2 flex justify-between gap-2">
                        <span>Total Achievement</span>
                        <span className="font-semibold">15</span>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="bg-[#f47104] -mx-2.5 text-white">
                  <div className="flex justify-between gap-3 p-1 flex p-2 before:absolute before:top-[100%] before:left-0 before:block before:w-0 before:h-0 before:border-l-[10px] before:border-t-[10px] relative before:border-l-transparent before:border-t-[#900] after:absolute after:top-[100%] after:right-0 after:block after:w-0 after:h-0 after:border-r-[10px] after:border-t-[10px] relative after:border-r-transparent after:border-t-[#900]">
                    <div className="font-bold text-primary-purple m-auto">
                      <img
                        className="h-16"
                        src={`${Configuration.S3bucketImagePath}/images/student/shared/Blasters/fire3.png`}
                        alt="fire"
                      />
                    </div>

                    <div>
                      <ul>
                        <li className="flex justify-between gap-2">
                          <span>Dominating Dozen</span>
                        </li>
                        <li className="flex justify-between gap-2">
                          <span>Get Correct 20 Ans in a row</span>
                        </li>
                        <li className="flex justify-between gap-2">
                          <span>Frequency</span>
                          <span>Immediate</span>
                        </li>
                      </ul>
                    </div>

                    <div className="font-semibold text-2xl top-2/4 m-auto">
                      0
                    </div>
                  </div>
                </div>

                <div className="rounded-lg shadow-lg border p-1 m-1 mt-3">
                  <div className="flex justify-between gap-3 p-1">
                    <div className="font-bold text-primary-purple m-auto">
                      <img
                        className="h-16"
                        src={`${Configuration.S3bucketImagePath}/images/student/shared/Blasters/fire3.png`}
                        alt="fire"
                      />
                    </div>

                    <div>
                      <ul>
                        <li className="flex justify-between gap-2">
                          <span>Dominating Dozen</span>
                        </li>
                        <li className="flex justify-between gap-2">
                          <span>Get Correct 20 Ans in a row</span>
                        </li>
                        <li className="flex justify-between gap-2">
                          <span>Frequency</span>
                          <span>Immediate</span>
                        </li>
                      </ul>
                    </div>

                    <div className="font-semibold text-2xl top-2/4 m-auto">
                      0
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-white rounded-lg shadow-lg">
                <div className="flex justify-between gap-6 p-4">
                  <div className="font-bold text-primary-purple mt-5">
                    Asteroid
                    <img
                      className="h-16 mt-2"
                      src={`${Configuration.S3bucketImagePath}/images/student/shared/Blasters/cannon4.png`}
                      alt="cannon"
                    />
                  </div>

                  <div>
                    <ul>
                      <li className="py-2 flex justify-between gap-2">
                        <span>Total</span>
                        <span className="font-semibold"> 15</span>
                      </li>
                      <li className="py-2 flex justify-between gap-2">
                        <span>Achieved</span>
                        <span className="font-semibold"> 5</span>
                      </li>
                      <li className="py-2 flex justify-between gap-2">
                        <span>Remaining</span>
                        <span className="font-semibold">10</span>
                      </li>
                      <li className="py-2 flex justify-between gap-2">
                        <span>Total Achievement</span>
                        <span className="font-semibold">15</span>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="rounded-lg shadow-lg border p-1 m-1">
                  <div className="flex justify-between gap-3 p-1">
                    <div className="font-bold text-primary-purple m-auto">
                      <img
                        className="h-16"
                        src={`${Configuration.S3bucketImagePath}/images/student/shared/Blasters/fire3.png`}
                        alt="fire"
                      />
                    </div>

                    <div>
                      <ul>
                        <li className="flex justify-between gap-2">
                          <span>Dominating Dozen</span>
                        </li>
                        <li className="flex justify-between gap-2">
                          <span>Get Correct 20 Ans in a row</span>
                        </li>
                        <li className="flex justify-between gap-2">
                          <span>Frequency</span>
                          <span>Immediate</span>
                        </li>
                      </ul>
                    </div>

                    <div className="font-semibold text-2xl top-2/4 m-auto">
                      0
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-white rounded-lg shadow-lg">
                <div className="flex justify-between gap-6 p-4">
                  <div className="font-bold text-primary-purple mt-5">
                    Asteroid
                    <img
                      className="h-16 mt-2"
                      src={`${Configuration.S3bucketImagePath}/images/student/shared/Blasters/cannon4.png`}
                      alt="cannon"
                    />
                  </div>

                  <div>
                    <ul>
                      <li className="py-2 flex justify-between gap-2">
                        <span>Total</span>
                        <span className="font-semibold"> 15</span>
                      </li>
                      <li className="py-2 flex justify-between gap-2">
                        <span>Achieved</span>
                        <span className="font-semibold"> 5</span>
                      </li>
                      <li className="py-2 flex justify-between gap-2">
                        <span>Remaining</span>
                        <span className="font-semibold">10</span>
                      </li>
                      <li className="py-2 flex justify-between gap-2">
                        <span>Total Achievement</span>
                        <span className="font-semibold">15</span>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="rounded-lg shadow-lg border p-1 m-1">
                  <div className="flex justify-between gap-3 p-1">
                    <div className="font-bold text-primary-purple m-auto">
                      <img
                        className="h-16"
                        src={`${Configuration.S3bucketImagePath}/images/student/shared/Blasters/fire3.png`}
                        alt="fire"
                      />
                    </div>
                    <div>
                      <ul>
                        <li className="flex justify-between gap-2">
                          <span>Dominating Dozen</span>
                        </li>
                        <li className="flex justify-between gap-2">
                          <span>Get Correct 20 Ans in a row</span>
                        </li>
                        <li className="flex justify-between gap-2">
                          <span>Frequency</span>
                          <span>Immediate</span>
                        </li>
                      </ul>
                    </div>

                    <div className="font-semibold text-2xl top-2/4 m-auto">
                      0
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
}

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(TrophyRoom);

import classNames from "classnames";
import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { getMyGalaxySubject } from "../../../../../api/student/liftOff/myGalaxyApi";
import { IMyGalaxySubject } from "../../../../../model/interface/liftOff/myGalaxySubject";
import Profile from "../../../../../model/users/profile";
import { UserContextState } from "../../../../../redux/actions/userContextAction";
import constant from "../../../../../utils/constant/constant";
import { useHistory } from "react-router-dom";
import RouteConstant from "../../../../../utils/constant/routeConstant";

interface IMyGalaxySubjectProps {
  userContext?: UserContextState;
  profile?: Profile;
}
function MyGalaxySubjectMS(props: IMyGalaxySubjectProps) {
  const history = useHistory();
  const [subjects, setSubjects] = useState<Array<IMyGalaxySubject>>([]);
  const [selectedSubject, setSelectedSubject] = useState<IMyGalaxySubject>();
  useEffect(() => {
    loadSubjects();
  });

  function loadSubjects() {
    getMyGalaxySubject(props.userContext?.userId ?? 0).then((d) => {
      setSubjects(d.data);
      if (selectedSubject === undefined && d.data.length > 0) {
        setSelectedSubject(d.data[0]);
      }
    });
  }

  const onSubjectSelect = (id, name) => {
    setSelectedSubject(id);
    history.push({
      pathname: RouteConstant.LiftOffRoutes.MiddleSchoolMyGalaxy.replace(
        ":id",
        id
      ),
      state: { subjectName: name },
    });
  };

  return (
    <Fragment>
      <div className="block p-1 rounded-2xl relative">
        <div className="grid grid-cols-1 md:grid-cols-2 bg-white p-2 rounded-2xl gap-1">
          {subjects.map((s) => {
            return (
              <div
                key={s.subjectId}
                className={classNames(
                  "flex p-2 border rounded m-1 cursor-pointer  hover:bg-gray-100",
                  ""
                )}
                onClick={() => {
                  onSubjectSelect(s.subjectId, s.name);
                }}
              >
                <span className="mr-2 w-10 flex-none">
                  <img
                    className="w-full pt-1"
                    srcSet={s.subjectIcon}
                    alt="Subject Icon"
                  />
                </span>
                {props.profile?.plLanguageId === constant.Languages.Spanish
                  ? s.spanishName ?? s.name
                  : s.name}
              </div>
            );
          })}
        </div>
      </div>
    </Fragment>
  );
}
const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(MyGalaxySubjectMS);

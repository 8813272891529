import { connect } from "react-redux";
import { IBreadcrumb } from "../../../../../model/common/breadcrumb";
import RouteConstant from "../../../../../utils/constant/routeConstant";
import { default as Constant } from "../../../../../utils/constant/constant";
import Breadcrumb from "../../../teacher/shared/breadcrumb";
import { useTranslation } from "react-i18next";
import Asterisk from "../../../../shared/asterisk";
import SelectGroup from "react-select";
import { Fragment, useEffect, useState } from "react";
import { Select } from "@windmill/react-ui";
import { getSchoolByDistrictId } from "../../../../../api/teacher/school";
import { ISelectElement } from "../../../../../model/interface/selectElement";
import { getAllClassesBySubject } from "../../../../../api/teacher/teacher";
import { getAllGrades } from "../../../../../api/gradeApi";
import PageSize from "../../../../shared/pagination/pageSize";
import Pagination from "../../../../shared/pagination/pagination";
import {
  getLiftoffHeaders,
  getLiftoffSubjects,
} from "../../../../../api/student/liftOff/diagnosticApi";
import {
  DEFAULT_PAGE_NUMBER,
  DEFAULT_PAGE_SIZE,
} from "../../../../../utils/pagingConstant";
import {
  getLiftOffDiagnosticsReport,
  getLiftOffDiagnosticsReportExcel,
} from "../../../../../api/report/liftoffDiagnosticsReport";
import Loader from "../../../../shared/loader";
import ReactTooltip from "react-tooltip";
import classNames from "classnames";
import { hasLiftOffAccess } from "../../../../../utils/helper";
import { downloadFile } from "../../../../../api/api";
import ReportButton from "../../shared/reportButton";
import ClassDropDown from "../../shared/classDropDown";
import ITeacherClass from "../../../../../model/interface/teacherclass";
import { ISchool } from "../../../../../model/interface/school";

function LiftoffDiagnosticsReport(props) {
  const { t } = useTranslation();
  const [isDistrictUser] = useState<boolean>(
    props.userContext.roleId === Constant.UserRoleId.District ? true : false
  );
  const [isAdminUser] = useState<boolean>(
    props.userContext.roleId === Constant.UserRoleId.SchoolAdmin ? true : false
  );
  const [schools, setSchools] = useState<Array<ISelectElement>>([]);
  const [classes, setClasses] = useState<Array<ITeacherClass>>([]);
  const [domains, setDomains] = useState<Array<ISelectElement>>([]);
  const [subjects, setSubjects] = useState<Array<ISelectElement>>([]);
  const [grades, setGrades] = useState<Array<ISelectElement>>([]);
  const [selectedSchool, setSelectedSchool] = useState<string>(
    props.userContext.roleId === Constant.UserRoleId.District
      ? "0"
      : props.userContext.schoolId
  );
  const [selectedDomain, setSelectedDomain] = useState<string>("0");
  const [selectedSubject, setSelectedSubject] = useState<string>("0");
  const [selectedViewBy, setSelectedViewBy] = useState<string>(
    props.userContext.roleId === Constant.UserRoleId.District ||
      props.userContext.roleId === Constant.UserRoleId.SchoolAdmin
      ? "0"
      : "1"
  );
  const [selectedGrade, setSelectedGrade] = useState<string>("0");
  const [isClassDisabled, setIsClassDisabled] = useState<boolean>(
    props.userContext.roleId !== Constant.UserRoleId.District ? false : true
  );

  const [isDomainDisabled, setIsDomainDisabled] = useState<boolean>(true);
  const [isSubjectsDisabled, setIsSubjectDisabled] = useState<boolean>(
    props.userContext.roleId !== Constant.UserRoleId.District ? false : true
  );
  const [isGradeDisabled, setIsGradeDisabled] = useState<boolean>(true);
  const [isViewByDisabled, setViewByDisabled] = useState<boolean>(
    props.userContext.roleId !== Constant.UserRoleId.District ? false : true
  );
  const [showReport, setShowReport] = useState<boolean>(false);
  const [noRecordFoundMsg, setNoRecordFoundMsg] = useState<string>("");
  const [reportData, setReportData] = useState<any>([]);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState(DEFAULT_PAGE_NUMBER);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [showLoader, setLoader] = useState<boolean>(false);
  const [resetPageNumber, setResetPageNumber] = useState<boolean>(false);

  const [selectedClassItems, setSelectedClassItems] = useState<
    Array<ISelectElement>
  >([{ label: "All Classes", value: "0" }]);
  const [allSchools, setAllSchools] = useState<ISchool[]>([]);
  const [selectedSchoolAccountId, setSelectedSchoolAccountId] =
    useState<number>(0);

  const breadcrumbItems = () => {
    const items: Array<IBreadcrumb> = [
      { name: t("Breadcrumb.Home"), url: RouteConstant.ROUTE_DASHBOARD },
      { name: t("Breadcrumb.Reports"), url: RouteConstant.REPORTS },
      { name: t("Breadcrumb.LiftoffDiagnosticsReport"), url: "" },
    ];

    return items;
  };
  const nonApplicableGrades: number[] = [1, 2];

  const getSchools = async () => {
    const resp = await getSchoolByDistrictId(
      props.userContext?.districtId ?? 0
    );
    if (resp?.data?.length) {
      let schoolList: Array<ISelectElement> = [];
      resp?.data.forEach((school) => {
        if (!schoolList.find((sl) => sl.value === school.schoolId.toString())) {
          schoolList.push({
            label: school.name,
            value: school.schoolId.toString(),
          });
        }
      });
      setSchools(schoolList);
      setAllSchools(resp?.data);
    }
  };

  const getClasses = async (schoolId: number) => {
    const resp = await getAllClassesBySubject(
      props.userContext.userId,
      props.userContext.roleId,
      null,
      props.profile.currentSchoolYear.schoolYearId,
      schoolId
    );
    setClasses(resp.data.data);
  };

  const getDomains = async (subjectId: number) => {
    const resp = await getLiftoffHeaders(subjectId);
    if (resp?.data?.length) {
      let domainList: Array<ISelectElement> = [];
      resp?.data.forEach((domain) => {
        domainList.push({
          label: domain.name,
          value: domain.id.toString(),
        });
      });
      setDomains(domainList);
    }
  };

  const getSubjects = async (schoolId: number) => {
    const resp = await getLiftoffSubjects(schoolId);
    if (resp?.data?.length) {
      let subjectList: Array<ISelectElement> = [];
      resp?.data.forEach((subject) => {
        subjectList.push({
          label: subject.name,
          value: subject.id.toString(),
        });
      });
      setSubjects(subjectList);
    }
  };

  const getGrades = async () => {
    const resp = await getAllGrades();
    if (resp?.data?.length) {
      let gradeList: Array<ISelectElement> = [
        {
          label: "All Grades",
          value: "0",
        },
      ];
      resp?.data.forEach((grade) => {
        if (
          !gradeList.find((sl) => sl.value === grade.gradeId.toString()) &&
          nonApplicableGrades.indexOf(grade.gradeId) === -1
        ) {
          gradeList.push({
            label: grade.name,
            value: grade.gradeId.toString(),
          });
        }
      });
      setGrades(gradeList);
    }
  };

  const getReport = () => {
    setLoader(true);
    getLiftOffDiagnosticsReport(
      Number(selectedSchool),
      Number(selectedSubject),
      Number(selectedDomain),
      pageNumber,
      pageSize,
      selectedClassItems
        .filter((c) => Number(c.value) > 0)
        .map((c) => Number(c.value)), //Number(selectedClass) === 0 ? undefined : Number(selectedClass),
      Number(selectedGrade) === 0 ? undefined : Number(selectedGrade),
      Number(selectedViewBy) === 0 ? 1 : Number(selectedViewBy) //Default By Class
    )
      .then((res) => {
        if (!res.data.data.data) setNoRecordFoundMsg("No record found");
        setReportData(res.data.data.data);
        setShowReport(true);
        setTotalRecords(res.data.data.totalRecords);
        setLoader(false);
      })
      .catch(() => {
        setLoader(false);
      });
  };

  const exportReport = () => {
    setLoader(true);
    getLiftOffDiagnosticsReportExcel({
      schoolId: Number(selectedSchool),
      subjectId: Number(selectedSubject),
      domainId: Number(selectedDomain),
      classIds: selectedClassItems
        .filter((c) => Number(c.value) > 0)
        .map((c) => Number(c.value)), // Number(selectedClass) === 0 ? undefined : Number(selectedClass),
      gradeId: Number(selectedGrade) === 0 ? undefined : Number(selectedGrade),
      viewBy: Number(selectedViewBy) === 0 ? 1 : Number(selectedViewBy), //Default By Class
    })
      .then((res) => {
        downloadFile(res.data, "LoDiagnosticsReport.xlsx");
        setLoader(false);
      })
      .catch(() => {
        setLoader(false);
      });
  };

  const findSchooltByValue: any = () => {
    if (!selectedSchool || selectedSchool === "0") return null;
    return schools.find((school) => school.value === selectedSchool);
  };

  const findDomainByValue: any = () => {
    if (!selectedDomain || selectedDomain === "0") return null;
    return domains.find((domain) => domain.value === selectedDomain);
  };

  const findSubjectByValue: any = () => {
    if (!selectedSubject || selectedSubject === "0") return null;
    return subjects.find((subject) => subject.value === selectedSubject);
  };

  const findGradeByValue: any = () => {
    return grades.find((cl) => cl.value === selectedGrade);
  };

  const handleSchoolOnChange = (school: ISelectElement) => {
    if (school.value !== selectedSchool) {
      setClasses([]);
      setSelectedViewBy("0");
      setSelectedGrade("0");
      setDomains([]);
      setSelectedDomain("0");
      setSubjects([]);
      setSelectedSubject("0");
      setViewByDisabled(false);
      setIsClassDisabled(false);
      setIsGradeDisabled(false);
      setIsSubjectDisabled(false);
      setIsDomainDisabled(true);

      setSelectedSchool(school.value);
      getClasses(Number(school.value));
      getSubjects(Number(school.value));
      setSelectedSchoolAccountId(
        allSchools.filter((s) => s.schoolId === Number(school.value))[0]
          ?.schoolAccountId ?? 0
      );
    }
  };

  const handleClassOnChange = (cl: Array<ISelectElement>) => {
    setSelectedClassItems(cl);
  };
  const handleGradeOnChange = (grade: ISelectElement) => {
    setSelectedGrade(grade.value);
  };
  const handleDomainOnChange = (domain: ISelectElement) => {
    if (domain.value !== selectedDomain) {
      setSelectedDomain(domain.value);
    }
  };
  const handleSubjectOnChange = (subject: ISelectElement) => {
    setDomains([]);
    setSelectedDomain("0");
    setIsDomainDisabled(false);
    setSelectedSubject(subject.value);
    getDomains(Number(subject.value));
  };
  const handleViewByOnChange = (e) => {
    setSelectedGrade("0");
    setIsGradeDisabled(false);
    setSelectedViewBy(e.target.value);
  };

  const onReset = () => {
    if (props.userContext.roleId === Constant.UserRoleId.District) {
      setSelectedSchool("0");
      setSelectedSchoolAccountId(0);
      setClasses([]);
      setIsClassDisabled(true);
      setSubjects([]);
      setViewByDisabled(true);
      setIsSubjectDisabled(true);
      setSelectedViewBy("0");
    }
    if (props.userContext.roleId === Constant.UserRoleId.SchoolAdmin) {
      setViewByDisabled(false);
      setIsSubjectDisabled(false);
      setSelectedViewBy("0");
    }
    if (
      props.userContext.roleId !== Constant.UserRoleId.SchoolAdmin &&
      props.userContext.roleId !== Constant.UserRoleId.District
    ) {
      setIsSubjectDisabled(false);
      setSelectedViewBy("1");
    }

    setNoRecordFoundMsg("");
    setDomains([]);
    setSelectedDomain("0");
    setSelectedSubject("0");
    setIsDomainDisabled(true);
    setShowReport(false);
    setPageNumber(DEFAULT_PAGE_NUMBER);
  };

  const onRunReport = () => {
    setPageNumber(DEFAULT_PAGE_NUMBER);
    setNoRecordFoundMsg("");
    setShowReport(false);
    setReportData([]);
    setTotalRecords(0);
    getReport();
  };

  const onPageChange = (pageNo) => {
    setPageNumber(pageNo);
  };

  const onPageSizeChange = (pageSize) => {
    setPageNumber(DEFAULT_PAGE_NUMBER);
    setPageSize(pageSize);
    setResetPageNumber(true);
  };

  useEffect(() => {
    hasLiftOffAccess(props.userContext).then((res) => {
      if (!res) {
        window.location.replace(RouteConstant.FORBIDDEN);
      }
    });
    getSchools();
    getGrades();
    if (props.userContext.roleId !== Constant.UserRoleId.District) {
      getSubjects(props.userContext.schoolId);
      getClasses(props.userContext.schoolId);
    }
  }, []);
  useEffect(() => {
    if (showReport) getReport();
  }, [pageSize, pageNumber, resetPageNumber]);

  function customClass(item: any) {
    return item.grade >= Number(item.startingGalaxy) + 2 &&
      item.assignmentStatus !== "Not Started"
      ? "text-[#e53f71] font-bold"
      : "";
  }

  function validateFilter() {    
    return (
      selectedSchool === "0" ||
      selectedViewBy === "0" ||
      selectedSubject === "0" ||
      selectedDomain === "0"
    );
  }
  return (
    <div className="min-w-0 w-full">
      <div className="lg:flex lg:items-center px-4 py-3 lg:px-8 border-b">
        {props.userContext.roleId !== Constant.UserRoleId.Student && (
          <Breadcrumb items={breadcrumbItems()} />
        )}

        <div className="lg:w-[55%] text-lg font-medium flex gap-2 h-auto sm:h-10 items-center">
          <h1 className="text-primary-violet font-medium flex justify-start mb-0 pb-0">
            Liftoff Diagnostics Report
          </h1>
        </div>
      </div>
      <div className="px-2 sm:px-6  lg:px-8 py-1 relative my-4">
        <div className="bg-gray-100 w-full rounded-lg p-5">
          <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-4 mb-4">
            {isDistrictUser && (
              <div className="flex flex-col">
                <label className="block mb-1 text-sm text-gray-500">
                  School <Asterisk />
                </label>
                <SelectGroup
                  placeholder={"Select School"}
                  value={findSchooltByValue()}
                  options={schools}
                  isSearchable={true}
                  onChange={(item) => {
                    handleSchoolOnChange(item);
                  }}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: "#008fbb",
                    },
                  })}
                />
              </div>
            )}
            {(isDistrictUser ||
              props.userContext.roleId === Constant.UserRoleId.SchoolAdmin) && (
              <div className="flex flex-col">
                <label className="block mb-1 text-sm text-gray-500">
                  View By <Asterisk />
                </label>
                <Select
                  value={selectedViewBy}
                  disabled={isViewByDisabled}
                  onChange={(item) => {
                    handleViewByOnChange(item);
                  }}
                  className={
                    "py-2 px-4 block text-sm border border-gray-300 focus:ring-primary-violet focus:border-primary-violet form-select leading-5 p-1 rounded "
                  }
                >
                  <option value={0}>Select</option>
                  <option value={1}>Class</option>
                  <option value={2}>Grade</option>
                </Select>
              </div>
            )}

            {selectedViewBy === "1" && (
              <div className="flex flex-col">
                <label className="block mb-1 text-sm text-gray-500">
                  Class <Asterisk />
                </label>
                <ClassDropDown
                  schoolYearId={props.profile.currentSchoolYear.schoolYearId}                  
                  onChange={handleClassOnChange}
                  value={selectedClassItems}
                  data={classes}
                  isDisabled={isClassDisabled}
                  showArchived={isDistrictUser || isAdminUser}
                  showHidden={true}
                  selectedSchoolAccountId={selectedSchoolAccountId}
                  isMultiSelect={true}
                ></ClassDropDown>
              </div>
            )}
            {selectedViewBy === "2" && (
              <div className="flex flex-col">
                <label className="block mb-1 text-sm text-gray-500">
                  Grade <Asterisk />
                </label>
                <SelectGroup
                  value={findGradeByValue()}
                  options={grades}
                  isSearchable={true}
                  isDisabled={isGradeDisabled}
                  onChange={(item) => {
                    handleGradeOnChange(item);
                  }}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: "#008fbb",
                    },
                  })}
                />
              </div>
            )}
            <div className="flex flex-col">
              <label className="block mb-1 text-sm text-gray-500">
                Subject <Asterisk />
              </label>
              <SelectGroup
                placeholder={"Select Subject"}
                value={findSubjectByValue()}
                options={subjects}
                isSearchable={true}
                isDisabled={isSubjectsDisabled}
                onChange={(item) => {
                  handleSubjectOnChange(item);
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: "#008fbb",
                  },
                })}
              />
            </div>
            <div className="flex flex-col">
              <label className="block mb-1 text-sm text-gray-500">
                Domain <Asterisk />
              </label>
              <SelectGroup
                placeholder={"Select Domain"}
                value={findDomainByValue()}
                options={domains}
                isSearchable={true}
                isDisabled={isDomainDisabled}
                onChange={(item) => {
                  handleDomainOnChange(item);
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: "#008fbb",
                  },
                })}
              />
            </div>
          </div>

          <div className="flex gap-4 justify-end">
            <button
              onClick={onReset}
              className="bg-transparent border my-auto border-primary-violet shadow-sm py-2 px-4 flex justify-center text-sm font-medium text-primary-violet hover:text-white hover:bg-light-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3 transition ease-in-out duration-500"
            >
              Reset
            </button>

            <p
              className="cursor-pointer"
              data-tip
              data-for="incompleteSelection"
            >
              {validateFilter() && (
                <ReactTooltip
                  id="incompleteSelection"
                  class="bg-secondary-teal/10 z-50 "
                  effect="solid"
                >
                  <span>Please complete all required fields</span>
                </ReactTooltip>
              )}

              <div className="cursor-not-allowed">
                <button
                  onClick={onRunReport}
                  className={
                    "bg-primary-violet border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 " +
                    (validateFilter()
                      ? "bg-opacity-20 text-primary-violet text-opacity-40 pointer-events-none"
                      : "hover:bg-dark-violet hover:shadow-lg")
                  }
                >
                  Run Report
                </button>
              </div>
            </p>

            <ReportButton
              caption={"Export"}
              disabledTooltip={"Please run the report first"}
              enabled={showReport}
              onClick={exportReport}
            />
          </div>

          <div className="border-b border-gray-400 py-2 mb-2"></div>
          {showLoader && <Loader></Loader>}
          {noRecordFoundMsg}
          {showReport && (
            <div className="bg-white px-3 pt-2 pb-3">
              <div className="overflow-x-auto">
                <div className="mb-4 pb-4 border-b-2 overflow-x-auto xl:overflow-x-visible">
                  <table className="table w-full ">
                    <thead>
                      <tr className="bg-primary-violet  text-white ">
                        <th className="text-left p-3 text-sm font-normal">
                          Class
                        </th>
                        <th className="text-left p-3 text-sm font-normal">
                          Student Name
                        </th>
                        <th className="text-center p-3 text-sm font-normal">
                          Student Id
                        </th>
                        <th className="text-center p-3 text-sm font-normal">
                          Grade Level
                        </th>
                        <th className="text-left p-3 text-sm font-normal">
                          Status
                        </th>

                        <th className="text-center p-3 text-sm font-normal">
                          Questions Attempted
                        </th>
                        <th className="text-center p-3 text-sm font-normal">
                          Questions Correct
                        </th>
                        <th className="text-center p-3 text-sm font-normal">
                          Score
                        </th>
                        <th className="text-center p-3 text-sm font-normal">
                          Starting Galaxy Level
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {reportData &&
                        reportData.length > 0 &&
                        reportData?.map((item, index) => {
                          return (
                            <Fragment>
                              <tr
                                className={`hover:bg-primary-violet/20 transition ease-in-out duration-300 ${
                                  index % 2 === 0
                                    ? "bg-white"
                                    : "bg-primary-violet/10"
                                }`}
                              >
                                <td className="text-sm p-3  ">
                                  {item.className} 
                                  {item.isArchived && Number(selectedViewBy) === 2 && <span style={{ fontStyle: 'italic', textTransform: 'lowercase' }}> (archived)</span>}
                                </td>
                                <td className="text-sm p-3  ">
                                  {item.lastName}, {item.firstName}
                                </td>
                                <td className="text-sm p-3 text-center ">
                                  {item.studentId}
                                </td>
                                <td className="text-sm p-3 text-center ">
                                  {item.grade}
                                </td>
                                <td className="text-sm p-3 text-left ">
                                  {item.assignmentStatus}
                                </td>
                                <td
                                  className={classNames(
                                    "text-center text-sm p-3 ",
                                    customClass(item)
                                  )}
                                >
                                  {item.assignmentStatus !== "Not Started" &&
                                    item.questionsAttempted}
                                </td>
                                <td
                                  className={classNames(
                                    "text-center text-sm p-3 ",
                                    customClass(item)
                                  )}
                                >
                                  {item.assignmentStatus !== "Not Started" &&
                                    item.questionsCorrect}
                                </td>
                                <td
                                  className={classNames(
                                    "text-center text-sm p-3 ",
                                    customClass(item)
                                  )}
                                >
                                  {item.assignmentStatus !== "Not Started" && (
                                    <>
                                      {item.questionsAttempted &&
                                      item.questionsAttempted > 0
                                        ? (
                                            (item.questionsCorrect /
                                              item.questionsAttempted) *
                                            100
                                          ).toFixed()
                                        : 0}
                                      %
                                    </>
                                  )}
                                </td>
                                <td
                                  className={classNames(
                                    "text-center text-sm p-3 ",
                                    customClass(item)
                                  )}
                                >
                                  {item.assignmentStatus === "Completed" &&
                                    item.startingGalaxy}
                                </td>
                              </tr>
                            </Fragment>
                          );
                        })}
                      {reportData?.length === 0 && (
                        <tr className="bg-white">
                          <td colSpan={6} className="text-sm p-3">
                            No records found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <div
                  className={`${props.pageNumber} hidden sm:flex-1 sm:flex sm:items-center sm:justify-between`}
                >
                  {reportData?.length !== 0 && (
                    <Pagination
                      totalRecords={totalRecords}
                      pageSize={pageSize}
                      onPageChange={onPageChange}
                      reset={resetPageNumber}
                      bgColor="primary-violet"
                      pageNumber={pageNumber}
                    />
                  )}
                  {reportData?.length !== 0 && (
                    <PageSize
                      pageSize={pageSize}
                      onPageSizeChange={onPageSizeChange}
                      bgColor="primary-violet"
                    />
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default connect((state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
})(LiftoffDiagnosticsReport);

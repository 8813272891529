import { Configuration } from "../environment/setup";
import { AxiosHelper } from "../environment/axiosConfig";
import APIConstant from "../utils/constant/apiConstant";

export function addProfile(params) {
  const url = `${Configuration.ApiBaseUrl}app/Profile/profile`;
  return AxiosHelper.axiosInstance().post(url, params);
}

export function subscription(params) {
  const url = `${Configuration.ApiBaseUrl}app/Profile/cancelSubscription`;
  return AxiosHelper.axiosInstance().post(url, params);
}

export function addAccountInfo(params) {
  const url = `${Configuration.ApiBaseUrl}app/Profile/account`;
  return AxiosHelper.axiosInstance().post(url, params);
}

export function addSchoolInfo(params) {
  const url = `${Configuration.ApiBaseUrl}app/Profile/school`;
  return AxiosHelper.axiosInstance().post(url, params);
}

export function updateProfile(params) {
  const url = `${Configuration.ApiBaseUrl}app/Profile/UpdateUserProfile`;
  return AxiosHelper.axiosInstance().put(url, params);
}

export function updateSubscription(params) {
  const url = `${Configuration.ApiBaseUrl}app/Profile/cancelSubscription`;
  return AxiosHelper.axiosInstance().post(url, params);
}

export function updateAccountInfo(params) {
  const url = `${Configuration.ApiBaseUrl}app/Profile/account`;
  return AxiosHelper.axiosInstance().post(url, params);
}

export function updateSchoolInfo(params) {
  const url = `${Configuration.ApiBaseUrl}app/Profile/school`;
  return AxiosHelper.axiosInstance().post(url, params);
}

export function GetSchoolInformation(schoolId: any) {
  return AxiosHelper.axiosInstance().get(
    APIConstant.APP_SCHOOLS_INFORMATION + "?schoolId=" + schoolId,
    {}
  );
}

export function updateSchoolInformation(schoolInfo: any) {
  return AxiosHelper.axiosInstance().put(
    APIConstant.APP_SCHOOLS_INFORMATION,
    schoolInfo
  );
}

export function getDistrictSchoolsInformation() {
  return AxiosHelper.axiosInstance().get(
    APIConstant.APP_DISTRICT_SCHOOLS_INFORMATION
  );
}

import { Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import {
  getSkillQuestion,
  getStudentAssignmentDetails,
  saveSkillStudentAnswer,
} from "../../../../api/student/skillPracticeApi";
import { ISkillQuestion } from "../../../../model/skillPractice/skillQuestion";
import { ITestDetails } from "../../../../model/skillPractice/testDetails";
import { ISkillStudentAnswer } from "../../../../model/skillPractice/skillStudentAnswer";
import parse from "html-react-parser";
import { OutlineCrossIcon, OutlineTickIcon } from "../../../../assets/icons";

import RouteConstant from "../../../../utils/constant/routeConstant";
import { ISkillOpponent } from "../../../../model/skillPractice/skillOpponent";
import Timer from "../../../shared/timer";
import Loader from "../../../shared/loader";
import AlienRacerGame from "../../../screen/game/alienRacer";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import useTextToSpeech from "../shared/textToSpeech/useTextToSpeech";
import constant from "../../../../utils/constant/constant";

function Test(props: any) {
  const [testDetails, setTestDetails] = useState<ITestDetails>();
  const [question, setQuestion] = useState<ISkillQuestion>();
  const [loading, showLoading] = useState<boolean>(false);
  const [isCorrect, setIsCorrect] = useState<boolean>(false);
  const [currentAnswerId, setCurrentAnswerId] = useState<number>(0);
  const { params } = props.match;
  const history = useHistory();
  const [isTestActive] = useState<boolean>(true);
  const [isTestPaused] = useState<boolean>(false);
  const [sessionTimeTaken, setSessionTimeTaken] = useState(0);
  const [prevTimeTaken, setPrevTimeTaken] = useState(0);
  const [isAnswered, setIsAnswered] = useState<boolean>(false);
  const [gameInput, setGameInput] = useState<any>();
  const [gameResult, setGameResult] = useState("");
  const { readElementText, stopSpeaking } = useTextToSpeech();
  const { t } = useTranslation();
  useEffect(() => {
    if (params.skillStandardId !== 0) {
      showLoading(true);
      getStudentAssignmentDetails(
        params.skillStandardId,
        params.skillStudentAssignmentId
      )
        .then((d) => {
          setTestDetails(d.data);
          setSessionTimeTaken(d.data.sessionTimeSecond);
          if (d.data.unattendedQuestionsCount > 0) {
            getQuestion(d.data.unattendedQuestions[0]);
          } else {
            navigateToSessionSummary();
          }
          let opponentDetails: ISkillOpponent = {
            firstName: "",
            lastName: "",
            schoolName: "",
            attendedQuestionCount: 15,
            attendedQuestionCorrectCount: 8,
            attendedAnswers: "101010101010101",
            rocketId: 1,
            totalTime: 400,
          };
          if (
            d.data.opponentDetails !== undefined &&
            d.data.opponentDetails !== null
          ) {
            opponentDetails = d.data.opponentDetails;
          }

          loadGame(
            d.data.rocketId,
            opponentDetails.rocketId,
            opponentDetails.attendedAnswers,
            d.data.studentFirstName.charAt(0) +
              "." +
              (d.data.studentLastName ?? " ").charAt(0),
            d.data.schoolName,
            opponentDetails.firstName.charAt(0) +
              "." +
              (opponentDetails.lastName ?? " ").charAt(0),
            opponentDetails.schoolName
          );

          showLoading(false);
        })
        .catch(() => {
          showLoading(false);
        });
    }
  }, [params.skillStudentAssignmentId, params.skillStandardId]);

  function getQuestion(id) {
    if (id > 0) {
      getSkillQuestion(id)
        .then((d) => {
          setQuestion(d.data);
          setIsAnswered(false);
        })
        .catch(() => {
          showLoading(false);
        });
    }
  }

  const theme = {
    ELEMENTARYHOME: {
      bgcolor: "bg-white ",
      breadcrumbcolor: "text-gray-500 ",
      breadcrumbborder: "border-gray-400 ",
      pgtitle: "text-gray-700",
      breadcrumbpadding: "px-6 py-5",
    },
    KINDERGARTEN: {
      bgcolor: "bg-white mx-8",
      breadcrumbcolor: "text-gray-500 ",
      breadcrumbborder: "border-gray-400 hover:text-gray-700 ",
      pgtitle: "text-gray-700",
      breadcrumbpadding: "p-5",
    },
    MIDDLESCHOOLHOME: {
      bgcolor: "",
      breadcrumbcolor: "text-white ",
      breadcrumbborder: "border-white ",
      pgtitle: "text-white ",
      breadcrumbpadding: "px-4 py-5 lg:px-8",
    },
  };

  function onSaveAnswer(questionId, answerid) {
    setIsAnswered(true);
    let currentIndex =
      testDetails?.unattendedQuestions.indexOf(questionId) ?? 0;
    setPrevTimeTaken(sessionTimeTaken);

    let studentAnswer: ISkillStudentAnswer = {
      skillStudentAssignmentId: params.skillStudentAssignmentId,
      skillStandardId: params.skillStandardId,
      questionId: questionId,
      answerId: answerid,
      timeTakenSecond: sessionTimeTaken - prevTimeTaken,
      hasCompleted:
        currentIndex + 1 === testDetails?.unattendedQuestionsCount
          ? true
          : false,
    };
    saveSkillStudentAnswer(studentAnswer)
      .then((d) => {
        setIsCorrect(d.data?.isCorrect);
        setCurrentAnswerId(answerid);
        sendResultToGame(d.data?.isCorrect === true ? 1 : 0);
        setTimeout(() => {
          if (currentIndex + 1 === testDetails?.unattendedQuestionsCount) {
            navigateToSessionSummary();
          } else {
            let nextQuestionid =
              testDetails?.unattendedQuestions[currentIndex + 1];
            getQuestion(nextQuestionid);
          }
        }, 2000);
      })
      .catch(() => {
        showLoading(false);
      });
  }

  function loadGame(
    rocketId,
    opponentRocketId,
    opponentAnswers,
    firstName,
    schoolName,
    opponentFirstName,
    opponentSchoolName
  ) {
    console.log(
      rocketId,
      opponentRocketId,
      opponentAnswers,
      firstName,
      schoolName,
      opponentFirstName,
      opponentSchoolName
    );
    setGameInput({
      rocketId,
      opponentRocketId,
      opponentAnswers,
      firstName,
      opponentFirstName,
      schoolName,
      opponentSchoolName,
    });
  }

  function sendResultToGame(isCorrect: number) {
    const gameInput = gameResult + isCorrect;
    setGameResult(gameInput);
  }

  function navigateToSessionSummary() {
    const query: string = window.location.pathname;
    let url = "";
    let KinderTestUrl =
      RouteConstant.KindergartenStudentRoutes.SkillPracticeTest.replace(
        ":skillStandardId",
        params.skillStandardId.toString()
      ).replace(
        ":skillStudentAssignmentId",
        params.skillStudentAssignmentId.toString()
      );
    url =
      query.indexOf(KinderTestUrl) > -1
        ? RouteConstant.KindergartenStudentRoutes.SkillPracticeSessionSummary
        : query.indexOf("middleSchool") > -1
        ? RouteConstant.MiddleSchool.SkillPracticeSessionSummary
        : RouteConstant.StudentRoute.SkillPracticeSessionSummary;
    const sessionSummaryUrl = url
      .replace(":skillStandardId", params.skillStandardId.toString())
      .replace(
        ":skillStudentAssignmentId",
        params.skillStudentAssignmentId.toString()
      );
    history.push(sessionSummaryUrl);
  }

  function redirectToHome() {
    const query: string = props.location.pathname;
    let homeUrl = "";
    homeUrl =
      query.indexOf("kindergarten") > -1
        ? RouteConstant.KindergartenStudentRoutes.Home
        : query.indexOf("middleSchool") > -1
        ? RouteConstant.MiddleSchool.Home
        : RouteConstant.StudentRoute.Home;
    history.push({
      pathname: homeUrl,
    });
  }

  const onGameComplete = (e) => {
    console.log(e, ">>>>>>>>>>>>>>>>>>>>");
  };

  const path: string = window.location.pathname;
  const themeName =
    path.indexOf("kindergarten") > -1
      ? "KINDERGARTEN"
      : path.indexOf("middleSchool") > -1
      ? "MIDDLESCHOOLHOME"
      : "ELEMENTARYHOME";

  return (
    <Fragment>
      {loading && <Loader />}
      <section
        className={` ${theme[themeName]?.bgcolor} h-full min-w-0 mb-4 rounded-2xl `}
      >
        <div className={`${theme[themeName]?.breadcrumbpadding}  border-b`}>
          <ol
            className={`${theme[themeName]?.breadcrumbcolor} flex items-center space-x-2 mb-1`}
          >
            <li>
              <div className="flex items-center">
                <span
                  onClick={redirectToHome}
                  className={`${theme[themeName]?.breadcrumbborder} cursor-pointer text-sm border-b border-dashed hover:border-opacity-0`}
                >
                  {t("Breadcrumb.Home")}
                </span>
              </div>
            </li>

            <li>
              <div className="flex items-center">
                <svg
                  className={`${theme[themeName]?.breadcrumbcolor} flex-shrink-0 h-5 w-5`}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
                <span
                  className={`ml-1 text-sm ${theme[themeName]?.breadcrumbcolor}`}
                >
                  {t("Breadcrumb.SkillsPractice")}
                </span>
              </div>
            </li>
          </ol>

          <div className="text-lg font-medium flex gap-2 h-auto sm:h-10 items-center">
            <h1
              className={`${theme[themeName]?.pgtitle} font-medium flex justify-start mb-0 pb-0`}
            >
              {t("SkillPractice.SkillsPracticeTest")}
            </h1>
          </div>
        </div>
        {gameInput !== undefined && gameResult.length >= 0 && (
          <div className="flex">
            <div className="relative mx-auto mt-4 mb-4 px-4 lg:px-8">
              <div className="text-center">
                <AlienRacerGame
                  userrocket={gameInput.rocketId}
                  opporocket={gameInput.opponentRocketId}
                  quizresult={gameInput.opponentAnswers.toString()}
                  username={gameInput.firstName}
                  userschool={gameInput.schoolName}
                  opponentname={gameInput.opponentFirstName}
                  opponentschool={gameInput.opponentSchoolName}
                  userInput={gameResult}
                  gameId={gameInput.rocketId}
                  onGameComplete={onGameComplete}
                />
              </div>

              {question !== undefined && (
                <div>
                  <div className="flex justify-between mt-6 mb-2">
                    <h2 className="text-xl mt-2 pb-0 pt-2">
                      {props.profile?.plLanguageId ===
                      constant.Languages.Spanish
                        ? parse(question?.spanishInstruction) ??
                          parse(question?.instruction)
                        : parse(question?.instruction)}
                    </h2>

                    <div id="timer">
                      <Timer
                        time={sessionTimeTaken}
                        isActive={isTestActive}
                        isPaused={isTestPaused}
                        setSessionTimeTaken={setSessionTimeTaken}
                      ></Timer>
                    </div>
                  </div>

                  <div className="flex flex-col gap-y-4 sm:flex-row gap-x-4 w-full">
                    <div className=" w-full sm:w-3/4 flex items-center justify-center px-4 border py-10 bg-white text-gray-700 rounded-xl shadow-lg">
                      <span className="img-container">
                        {props.profile?.plLanguageId ===
                        constant.Languages.Spanish
                          ? parse(
                              question?.spanishQuestionText ??
                                question?.questionText ??
                                ""
                            )
                          : parse(question?.questionText ?? "")}
                      </span>
                    </div>
                    <div className="flex items-center justify-center w-full sm:w-1/4">
                      <div
                        className="game-qus-option divSkillAnswers w-full flex flex-col justify-between h-full space-y-4"
                        id="divSkillAnswerList"
                      >
                        {question?.answers.map((answer, index) => {
                          return (
                            <button
                              key={index}
                              type="button"
                              disabled={isAnswered}
                              onClick={() =>
                                onSaveAnswer(
                                  question.skillQuestionId,
                                  answer.skillAnswerId
                                )
                              }
                              onMouseEnter={() => {
                                readElementText(
                                  props.profile?.plLanguageId ===
                                    constant.Languages.Spanish
                                    ? answer.spanishAnswerText ??
                                        answer.answerText
                                    : answer.answerText,
                                  (props.profile?.plLanguageId ?? 1) === 2
                                    ? 2
                                    : 1
                                );
                              }}
                              onMouseLeave={() => {
                                stopSpeaking();
                              }}
                              className={`w-full group ${
                                isCorrect === true &&
                                currentAnswerId === answer.skillAnswerId
                                  ? "bg-green-400 hover:bg-green-400 text-white"
                                  : isCorrect === false &&
                                    currentAnswerId === answer.skillAnswerId
                                  ? "bg-red-400 hover:bg-red-400 text-white"
                                  : "bg-white hover:bg-gray-200"
                              }  py-1.5 border flex items-center justify-center h-full rounded-xl shadow-lg relative`}
                              data-questionid={question.skillQuestionId}
                              data-answerid={answer.skillAnswerId}
                            >
                              <span
                                id="Answers"
                                className={`text-4xl px-4 py-1 inline-block flex items-center justify-center rounded-md transition-all ${
                                  isCorrect === true &&
                                  currentAnswerId === answer.skillAnswerId
                                    ? "group-hover:text-white text-white"
                                    : isCorrect === false &&
                                      currentAnswerId === answer.skillAnswerId
                                    ? "group-hover:text-white text-white"
                                    : "text-gray-700"
                                } `}
                              >
                                {props.profile?.plLanguageId ===
                                constant.Languages.Spanish
                                  ? parse(
                                      answer.spanishAnswerText ??
                                        answer.answerText
                                    )
                                  : parse(answer.answerText)}
                              </span>
                              <span className="w-10 h-10 rounded-full ml-2 flex items-center justify-center ml-auto absolute left-0 top-3">
                                {isCorrect === true &&
                                currentAnswerId === answer.skillAnswerId ? (
                                  <span className="w-8 h-8 bg-white rounded-full flex justify-center items-center">
                                    <OutlineTickIcon className="fill-current text-green-400 w-8 h-8"></OutlineTickIcon>
                                  </span>
                                ) : isCorrect === false &&
                                  currentAnswerId === answer.skillAnswerId ? (
                                  <span className="w-8 h-8 bg-white rounded-full justify-center items-center">
                                    <OutlineCrossIcon className="fill-current text-red-400 w-8 h-8"></OutlineCrossIcon>
                                  </span>
                                ) : null}
                              </span>
                            </button>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </section>
    </Fragment>
  );
}
const mapStateToProps = (state: any) => {
  return {
    profile: state.profile,
  };
};
export default connect<{}, {}, {}>(mapStateToProps, {})(Test);

import { Configuration } from "../../../environment/setup";
import { handleLogin } from "../../../utils/googleHelper";
import {
  StudentLoginIcon,
  TeacherLoginIcon,
} from "../../../assets/icons/index";

const GoogleUserLogin = (props: any) => {
  return (
    <>
      <div className="bg-splitColor w-full h-screen flex items-center relative">
        <div className="w-full">
          <h1 className="text-center text-2xl text-white font-semibold pb-6">
            Google Classroom Account
          </h1>
          <div className="bg-white w-1/3 min-h-[24rem] py-6 flex flex-col justify-center mx-auto rounded-lg shadow-xl">
            <img
              className=" w-24 mx-auto mb-8"
              src={`${Configuration.S3bucketImagePath}/images/pl-logo.png`}
              alt="education galaxy"
            />

            <div className="  w-full flex flex-col items-center gap-y-4">
              <div
                id="StudentLoginBtn"
                className="w-3/4 py-3 bg-blue-100 hover:border-blue-300 hover:border-4 transition-all duration-300 cursor-pointer group border-4 border-blue-200 rounded-lg text-xl flex items-center gap-x-4 px-4"
                onClick={(e) => {
                  handleLogin("Student", "");
                }}
              >
                <span className="bg-[#019ED2] w-10 h-10 rounded-lg group-hover:ml-4 transition-all duration-300 flex items-center justify-center">
                  <StudentLoginIcon className="w-7 text-white" />
                </span>
                <span className="text-gray-600 group-hover:text-gray-800 transition-all duration-300">
                  Login as Student
                </span>
              </div>
              <div
                className="w-3/4 py-3 bg-blue-100 hover:border-blue-300 hover:border-4 transition-all duration-300 cursor-pointer group border-4 border-blue-200 rounded-lg text-xl flex items-center gap-x-4 px-4"
                onClick={(e) => {
                  handleLogin("Teacher", "");
                }}
              >
                <span className="bg-[#B23F9D] w-10 h-10 rounded-lg group-hover:ml-4 transition-all duration-300 flex items-center justify-center">
                  <TeacherLoginIcon className="w-7 text-white" />
                </span>
                <span className="text-gray-600 group-hover:text-gray-800 transition-all duration-300">
                  Login as Teacher
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GoogleUserLogin;

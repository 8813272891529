export class Config {

  constructor(
    public ApiBaseUrl: string = "https://localhost:44351/",
    public AuthBaseUrl: string = "",
    public EGBaseUrl: string = "",
    public TrainingUrl: string = "",
    public GroupInstructionUrl: string = "",
    public CleverClientId: string = "",
    public ClassLinkLoginUrl: string = "",
    public ItemBankEndpoint: string = "",
    public IdealStatusTime: number = 120000,
    public SkipButtonTime: number = 15000,
    public S3bucketImagePath: string = "",
    public TexasStateId: number = 46,
    public UserIdleTime: number = 3600000,
    public SkipTextExplanationButtonTime: number = 2000,
    public LearnosityAssetsBaseUrl: string = "",
    public HorizonEducationUrl: string = "",
  ) {}
}

export class GoogleClassroomConfig {
  constructor(
    public GoogleClientId: string = "",
    public GoogleSignInUrl: string = "",
    public TeacherScopes: string = "",
    public StudentScopes: string = "",
    public ReturnUrl: string = "",
    public TeacherGoogleurl: string = "",
    public StudentGoogleurl: string = ""
  ) {}
}
export class CanvasConfig {
  constructor(
    public CanvasAuthUrl: string = "",
    public Scopes: string = "",
    public ReturnUrl: string = "",
    public EnableCanvas: boolean = false
  ) {}
}

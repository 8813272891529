import { Fragment, useEffect, useState } from "react";
import { XIcon } from "@heroicons/react/outline";
import InfoMessage from "../../../../shared/infoMessage";

interface ProgressReportStudentPrintProps {
  progressReportByStudent: any;
  onStudentDotRankPrint: (students: Array<any>) => void;
  showPopUp: (show: boolean) => void;
}

function ProgressReportStudentPrint(props: ProgressReportStudentPrintProps) {
  const { progressReportByStudent, onStudentDotRankPrint, showPopUp } = props;
  const [students, setStudents] = useState<Array<any>>([]);
  const [selectAll, setSelectAll] = useState(false);

  const handleStudentChk = (
    selectAllFlag: boolean,
    id: number,
    value: boolean
  ) => {
    if (selectAllFlag) {
      setStudents(
        students.map((s) => {
          s.isSelected = value;
          return s;
        })
      );
      setSelectAll(value);
    } else {
      setStudents(
        students.map((s) => {
          s.isSelected = s.id === id ? value : s.isSelected;
          return s;
        })
      );

      if (!value) {
        setSelectAll(false);
      } else {
        const selectedUsers = students.filter((s) => s.isSelected);
        if (selectedUsers.length === students.length) {
          setSelectAll(true);
        }
      }
    }
  };

  const handlePrint = () => {
    const data = students.filter((s) => {
      return s.isSelected;
    });
    if (data.length > 0) {
      onStudentDotRankPrint(data);
    }
  };

  useEffect(() => {
    if (progressReportByStudent?.students?.length > 0) {
      let data = progressReportByStudent?.students
        ?.filter((s) => {
          return s.id > 0;
        })
        .map((s) => {
          return { ...s, isSelected: false };
        });
      setStudents(data);
    }
  }, [progressReportByStudent]);

  return (
    <Fragment>
      <div className="fixed inset-0 z-40 flex items-center bg-black bg-opacity-50 sm:items-center sm:justify-center">
        <div
          className="w-full flex flex-col justify-between overflow-hidden h-auto max-h-80percent bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:max-w-5xl"
          role="dialog"
        >
          <header>
            {/* Header */}
            <div className="px-4 py-4 bg-gray-50">
              <div className="flex items-start justify-between space-x-3">
                <div className="space-y-1">
                  <div className="text-lg font-medium text-gray-900">
                    Print Results
                  </div>
                </div>
                <div className="h-7 flex items-center">
                  <button
                    type="button"
                    className="text-gray-400 hover:text-gray-500"
                    onClick={() => showPopUp(false)}
                  >
                    <span className="sr-only">Close panel</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
          </header>
          <div className="w-full relative overflow-auto px-4 py-4">
            <InfoMessage
              className="-mt-2"
              message={
                "Select to print the Progress Report by class or student. Each student report shall be printed separately. The file can also be exported to PDF by selecting ‘Print'."
              }
            ></InfoMessage>
            <h1 className="uppercase text-gray-700 mt-6 mb-2">
              Individual Summaries
            </h1>
            <div className="bg-gray-100 px-2 mb-1">
              <label
                x-radio-group-option=""
                className=" relative  py-2 flex cursor-pointer focus:outline-none border-gray-400 z-10"
              >
                <input
                  type="checkbox"
                  x-model="value"
                  name="privacy-setting"
                  className="h-4 w-4 mt-0.5 cursor-pointer text-primary-violet border-gray-300 focus:ring-primary-violet"
                  checked={selectAll}
                  onChange={(e) => {
                    handleStudentChk(true, 0, e.currentTarget.checked);
                  }}
                />
                <div className="ml-3 flex flex-col">
                  <span
                    id="privacy-setting-0-label"
                    className="text-sm font-medium text-primary-violet focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3"
                  >
                    Select All
                  </span>
                </div>
              </label>
            </div>
            <div className="grid grid-cols-4 ml-2">
              {students.length > 0 &&
                students.map((s, i) => (
                  <label
                    x-radio-group-option=""
                    className=" relative  py-2 flex cursor-pointer focus:outline-none border-gray-400 z-10"
                    key={i}
                  >
                    <input
                      type="checkbox"
                      className="h-4 w-4 mt-0.5 cursor-pointer text-primary-violet border-gray-300 focus:ring-primary-violet"
                      name="privacy-setting"
                      value={s.name}
                      checked={s.isSelected}
                      onChange={() => {
                        handleStudentChk(false, s.id, !s.isSelected);
                      }}
                    />
                    <div className="ml-3 flex flex-col">
                      <span
                        id="privacy-setting-0-label"
                        className="text-sm font-medium text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3"
                      >
                        {s.name}
                      </span>
                    </div>
                  </label>
                ))}
            </div>
          </div>

          <footer className="flex flex-col items-center justify-end px-6 py-4 sm:flex-row bg-gray-50 dark:bg-gray-800">
            <div className="flex-shrink-0">
              <div className="space-x-3 flex justify-end">
                <button
                  className="align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 rounded-lg text-sm text-gray-600 border-gray-300 border dark:text-gray-400 focus:outline-none active:bg-transparent hover:border-gray-500 focus:border-gray-500 active:text-gray-500 focus:shadow-outline-gray sm:w-auto"
                  type="button"
                  onClick={() => {
                    showPopUp(false);
                  }}
                >
                  Close
                </button>
                <button
                  className="align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 rounded-lg text-sm text-white border border-transparent bg-primary-green focus:shadow-outline-purple sm:w-auto"
                  type="button"
                  onClick={handlePrint}
                >
                  Print
                </button>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </Fragment>
  );
}

export default ProgressReportStudentPrint;

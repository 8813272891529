import { Fragment, ReactComponentElement } from "react";
import { ArrowUpSmall, ArrowDownSmall } from "../../assets/icons";
import { SortingOrder } from "../../types/type";

interface SortTableHeaderProps {
  data: any;
  orderType: SortingOrder;
  headerComponent: ReactComponentElement<any, any> | string;
  columnName: string;
  activeSortColumn: string;
  alignment?: "left" | "center" | "right";
  sortRecords: (data: any, column: string, orderType: SortingOrder) => void;
}

function SortTableHeader(props: SortTableHeaderProps) {
  const isActive = props.columnName === props.activeSortColumn;
  const showAscOrder =
    (isActive && props.orderType === "desc") ||
    props.columnName !== props.activeSortColumn;

  return (
    <Fragment>
      <span className={`flex justify-${props.alignment || "left"} items-center p-1 group`}>
        <span>{props.headerComponent}</span>
        {showAscOrder && (
          <span
            onClick={() =>
              props.sortRecords(props.data, props.columnName, "asc")
            }
          >
            <ArrowDownSmall />
          </span>
        )}
        {isActive && props.orderType === "asc" && (
          <span
            onClick={() =>
              props.sortRecords(props.data, props.columnName, "desc")
            }
          >
            <ArrowUpSmall />
          </span>
        )}
      </span>
    </Fragment>
  );
}

export default SortTableHeader;

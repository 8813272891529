import { Switch } from "@headlessui/react";
import classNames from "classnames";
import { Fragment } from "react";

interface IToggleProps {
  checked: boolean;
  onChange: Function;
  disabled?: boolean;
  title?: string;
}

const Toggle = (props: IToggleProps) => {
  return (
    <Fragment>
      <Switch
        checked={props.checked}
        disabled={props.disabled}
        onChange={(e) => {
          props.onChange(e);
        }}
        className={
          "flex-shrink-0 group relative rounded-full inline-flex items-center justify-center h-5 w-10 focus:outline-none " +
          (props.disabled ? " cursor-not-allowed " : " cursor-pointer")
        }
        title={props.title}
      >
        <span className="sr-only">Use setting</span>
        <span
          aria-hidden="true"
          className="pointer-events-none absolute w-full h-full rounded-md"
        />
        <span
          aria-hidden="true"
          className={classNames(
            props.checked ? "bg-primary-violet" : "bg-gray-200",
            "pointer-events-none absolute h-4 w-9 mx-auto rounded-full transition-colors ease-in-out duration-200"
          )}
        />
        <span
          aria-hidden="true"
          className={classNames(
            props.checked ? "translate-x-5" : "translate-x-0",
            "pointer-events-none absolute left-0 inline-block h-5 w-5 border border-gray-200 rounded-full bg-white shadow transform ring-0 transition-transform ease-in-out duration-200"
          )}
        />
      </Switch>
    </Fragment>
  );
};

export default Toggle;

import { useEffect, useState } from "react";
import Select, { MultiValue } from "react-select";
import { getAllClassesBySubject } from "../../../../../api/teacher/teacher";
import { ISelectElement } from "../../../../../model/interface/selectElement";
import TestHeader from "../../../../../model/report/testComparison/testHeader";
import { UserContextState } from "../../../../../redux/actions/userContextAction";
import constant from "../../../../../utils/constant/constant";
import ClassDropDown from "../../shared/classDropDown";
import moment from "moment";
import { getSchoolByDistrictId } from "../../../../../api/teacher/school";
import ITeacherClass from "../../../../../model/interface/teacherclass";
import Profile from "../../../../../model/users/profile";

interface HeaderProps {
  selectedTab: string;
  test1HeaderData?: TestHeader;
  test2HeaderData?: TestHeader;
  subjectName?: string;
  subjectId: number;
  schoolId?: number;
  handleSchoolInsideHeaderChange: (selectedSchool) => void;
  handleClassChange: (selectedClass) => void;
  userContext: UserContextState;
  profile: Profile;
  selectedSchoolAccountId?: number;
}

function Header(props: HeaderProps & any) {
  const [classes, setClasses] = useState<ITeacherClass[]>();
  const [selectedClasses, setSelectedClasses] = useState<Array<ISelectElement>>(
    [{ label: "All Classes", value: "0" }]
  );
  const [selectedSchools, setSelectedSchools] = useState<Array<ISelectElement>>(
    [{ label: "All Schools", value: "0" }]
  );
  const [schools, setSchools] = useState<Array<any>>();
  const [isDistrictUser] = useState<boolean>(
    props.userContext.roleId === constant.UserRoleId.District ? true : false
  );
  const districtUserTabOptions: any = {
    DistrictAssessment: "District",
    SchoolAssessment: "School",
  };
  const getSchools = async () => {
    const resp = await getSchoolByDistrictId(
      props.userContext?.districtId ?? 0, props.subjectId
    );
    if (resp?.data?.length) {
      const schools: Array<ISelectElement> = resp?.data.map((values: any) => {
        return {
          label: values?.name,
          value: values?.schoolId,
        };
      });
      setSchools(schools);
    }
  };

  const handleOnChange = (options: MultiValue<ISelectElement> | null) => {
    if(!options) return;

    let selectedValues: Array<ISelectElement> = options.map((x) => x);
    if (selectedValues && selectedValues.length > 0) {
      const lastSelectedValue = selectedValues[selectedValues.length - 1];
      if (lastSelectedValue.value === "0") {
        selectedValues = [lastSelectedValue];
      } else if (
        selectedValues.some((r) => r.value === "0") &&
        selectedValues.some((r) => r.value !== "0")
      ) {
        selectedValues = selectedValues.filter((r) => r.value !== "0");
      }
    } else {
      selectedValues = [{ label: "All Schools", value: "0" }];
    }

    setSelectedSchools(selectedValues);
    if (typeof props.onChange === "function") props.onChange(selectedValues);
  };
  async function getClasses() {
    setSelectedClasses([{ label: "All Classes", value: "0" }]);
    const resp = await getAllClassesBySubject(
      props.userContext?.userId,
      props.userContext?.roleId,
      props.subjectId,
      props.profile.currentSchoolYear.schoolYearId,
      props.schoolId && props.schoolId > 0
        ? props.schoolId
        : props.userContext?.roleId == constant.UserRoleId.District
        ? null
        : props.userContext?.schoolId
    );
    setClasses(resp.data.data);
  }

  useEffect(() => {
    if (isDistrictUser) {
      getSchools();
    }
  }, [isDistrictUser]);

  useEffect(() => {
    getClasses();
  }, [props.subjectId]);

  return (
    <>
      <div className="overflow-auto">
        <table className="table w-full xxxl:w-3/4 ">
          <thead className="border-b">
            <tr className=" py-3 px-3 divide-x">
              <th className="text-white min-w-[12rem]"></th>
              <th className="w-[50%] bg-light-violet text-white text-left py-3 px-6 font-normal">
                {props.test1HeaderData?.testName} Average{" "}
                {props.test1HeaderData?.averageScore}%
              </th>
              <th className="w-[50%] bg-light-violet text-white text-left py-3 px-6 font-normal">
                {props.test2HeaderData?.testName} Average{" "}
                {props.test2HeaderData?.averageScore}%
              </th>
            </tr>
          </thead>
          <tbody className="divide-y">
            <tr className="hover:bg-light-violet/20 transition ease-in-out duration-300 bg-white">
              <td className="text-right whitespace-nowrap text-gray-800 px-3 border-r">
                Name
              </td>
              <td className="hover:bg-light-violet/20 transition ease-in-out duration-300 bg-light-violet/10 py-3 px-6 text-sm text-gray-500 text-left">
                {props.test1HeaderData?.testName}
              </td>
              <td className="hover:bg-gray-100 transition ease-in-out bg-gray-100/50 py-3 px-6 text-sm text-gray-500 text-left">
                {props.test2HeaderData?.testName}
              </td>
            </tr>
            <tr className="hover:bg-light-violet/20 transition ease-in-out duration-300 bg-white">
              <td className="text-right whitespace-nowrap text-gray-800 px-3 border-r">
                Assessment Code
              </td>
              <td className="hover:bg-light-violet/20 transition ease-in-out duration-300 bg-light-violet/10 py-3 px-6 text-sm text-gray-500 text-left">
                {props.test1HeaderData?.code}
              </td>
              <td className="hover:bg-gray-100 transition ease-in-out bg-gray-100/50 py-3 px-6 text-sm text-gray-500 text-left">
                {props.test2HeaderData?.code}
              </td>
            </tr>
            <tr className="hover:bg-light-violet/20 transition ease-in-out duration-300 bg-white">
              <td className="text-right whitespace-nowrap text-gray-800 px-3 border-r">
                # of Questions
              </td>
              <td className="hover:bg-light-violet/20 transition ease-in-out duration-300 bg-light-violet/10 py-3 px-6 text-sm text-gray-500 text-left">
                {props.test1HeaderData?.numberOfQuestions}
              </td>
              <td className="hover:bg-gray-100 transition ease-in-out bg-gray-100/50 py-3 px-6 text-sm text-gray-500 text-left">
                {props.test2HeaderData?.numberOfQuestions}
              </td>
            </tr>
            <tr className="hover:bg-light-violet/20 transition ease-in-out duration-300 bg-white">
              <td className="text-right whitespace-nowrap text-gray-800 px-3 border-r">
                # of Students
              </td>
              <td className="hover:bg-light-violet/20 transition ease-in-out duration-300 bg-light-violet/10 py-3 px-6 text-sm text-gray-500 text-left">
                {props.test1HeaderData?.numberOfStudentsCompleted}
              </td>
              <td className="hover:bg-gray-100 transition ease-in-out bg-gray-100/50 py-3 px-6 text-sm text-gray-500 text-left">
                {props.test2HeaderData?.numberOfStudentsCompleted}
              </td>
            </tr>
            <tr className="hover:bg-light-violet/20 transition ease-in-out duration-300 bg-white">
              <td className="text-right whitespace-nowrap text-gray-800 px-3 border-r">
                Create Date
              </td>
              <td className="hover:bg-light-violet/20 transition ease-in-out duration-300 bg-light-violet/10 py-3 px-6 text-sm text-gray-500 text-left">
                {props.test1HeaderData?.createdDate
                  ? moment(props.test1HeaderData?.createdDate).format(
                      "MM/DD/YYYY"
                    )
                  : ""}
              </td>
              <td className="hover:bg-gray-100 transition ease-in-out bg-gray-100/50 py-3 px-6 text-sm text-gray-500 text-left">
                {props.test2HeaderData?.createdDate
                  ? moment(props.test2HeaderData?.createdDate).format(
                      "MM/DD/YYYY"
                    )
                  : ""}
              </td>
            </tr>
            <tr className="hover:bg-light-violet/20 transition ease-in-out duration-300 bg-white">
              <td className="text-right whitespace-nowrap text-gray-800 px-3 border-r">
                Subject
              </td>
              <td className="transition ease-in-out duration-300 py-3 px-6 text-sm text-gray-500 text-left">
                {props.subjectName}
              </td>
              <td className="transition ease-in-out py-3 px-6 text-sm text-gray-500 text-left"></td>
            </tr>
            <tr className="hover:bg-light-violet/20 transition ease-in-out duration-300 bg-white">
              <td className="text-right whitespace-nowrap text-gray-800 px-3 border-r">
                {props.userContext?.roleId === constant.UserRoleId.District &&
                props.selectedTab === districtUserTabOptions.DistrictAssessment
                  ? "School"
                  : "Class"}
              </td>
              <td
                colSpan={2}
                className={"py-3 px-6 text-sm text-gray-500 text-left"}
              >
                {props.userContext?.roleId === constant.UserRoleId.District &&
                props.selectedTab ===
                  districtUserTabOptions.DistrictAssessment ? (
                  <div className="max-w-md">
                    <Select
                      options={schools}
                      value={selectedSchools}
                      onChange={(e) => {
                        handleOnChange(e);
                        props.handleSchoolInsideHeaderChange(
                          e.filter((c) => c.value !== "0")
                        );
                      }}
                      menuPortalTarget={document.body}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary: "#008fbb",
                        },
                      })}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        control: (base, state) => ({
                          ...base,
                          background: props.colorStyle ?? "white",
                          borderColor: "#D1D5DB",
                          borderWidth: "1px",
                          "&:hover": {
                            borderColor: state.isFocused ? "#008fbb" : "",
                          },
                        }),

                        input: (base) => ({
                          ...base,
                          color:
                            document.getElementsByClassName("dark")?.length > 0 ? "white" : "",
                        }),
                      }}
                      isMulti
                    />
                  </div>
                ) : (
                  <div className="max-w-md">
                    <ClassDropDown
                      schoolYearId={
                        props.profile.currentSchoolYear.schoolYearId
                      }
                      isMultiSelect={true}
                      onChange={(e) => {
                        props.handleClassChange(
                          e.filter((c) => c.value !== "0")
                        );
                        setSelectedClasses(e);
                      }}
                      value={selectedClasses}
                      data={classes}
                      isDisabled={
                        props.subjectId === 0 ||
                        (props.userContext.roleId ===
                          constant.UserRoleId.District &&
                          props.schoolId === 0)
                          ? true
                          : false
                      }
                      subjectId={props.subjectId}
                      showArchived={true}
                      showHidden={true}
                      selectedSchoolAccountId={props.selectedSchoolAccountId}
                    ></ClassDropDown>
                  </div>
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
}
export default Header;

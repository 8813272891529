import { AxiosHelper } from "../../environment/axiosConfig";
import APIConstant from "../../utils/constant/apiConstant";
import qs from "qs";

export const getLiftOffDiagnosticsReport = (
  schoolId: number,
  subjectId: number,
  domainId: number,
  pageNumber: number,
  pageSize: number,
  classIds: Array<number>,
  gradeId?: number,
  viewBy?: number
) => {
  return AxiosHelper.axiosInstance().get(
    APIConstant.LIFTOFFDIAGNOSTICSREPORT_API.GetLiftoffDiagnosticsReport,
    {
      params: {
        schoolId: schoolId,
        subjectId: subjectId,
        domainId: domainId,
        classIds: classIds,
        gradeId: gradeId,
        pageNumber: pageNumber,
        pageSize: pageSize,
        viewBy: viewBy ?? 1, //Default By Class
      },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    }
  );
};

export const getLiftOffDiagnosticsReportExcel = (params: {
  schoolId: number;
  subjectId: number;
  domainId: number;
  pageNumber?: number;
  pageSize?: number;
  classIds: Array<number>;
  gradeId?: number;
  viewBy?: number;
}) => {
  return AxiosHelper.axiosInstance().get(
    APIConstant.LIFTOFFDIAGNOSTICSREPORT_API.GetLiftoffDiagnosticsReportExcel,
    {
      responseType: "blob",
      params: params,
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    }
  );
};

import { Fragment } from "react";
import { LoadingIcon } from "../../assets/icons";

interface loaderProps {
  className?: string;
  styleChange?: string;
}

function Loader(props: loaderProps) {
  const cssClass = `left-0 top-0 fixed h-screen z-50 w-full bg-white bg-opacity-50 flex items-center justify-center rounded-t-xl ${props.className}`;
  return (
    <Fragment>
      <div className={cssClass}>
        {props && props.styleChange === "custom" && (
          <LoadingIcon className="h-8 w-8"></LoadingIcon>
        )}
        {props && props.styleChange === undefined && (
          <LoadingIcon></LoadingIcon>
        )}
      </div>
    </Fragment>
  );
}

export default Loader;

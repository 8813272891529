import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import PIEChart from "../../../../../shared/charts/piechart";
import { moveItemsToEnd } from "../../../../../../utils/helper";
export default function TotalQuestionsByDomains({ data }: any) {
  const { t } = useTranslation();
  const [domainDataValues, setDomainDataValues] = useState<Array<any>>([]);
  const [toggleShowMore, setToggleShowMoreOption] = useState<boolean>(false);
  const [domainsData, setDomaninsData] = useState<Array<any>>([]);

  useEffect(() => {
    if (data && data.domains && Object.keys(data.domains).length) {
      const rearrangedDomains = moveItemsToEnd(
        data.domains,
        (d: any) => d.domainName === null
      );
      let chartDivHeight: any = document.getElementById("chart_id_domain");
      if (chartDivHeight) chartDivHeight = chartDivHeight.style.height;
      const domainData: any = [];
      rearrangedDomains.forEach((domain) => {
        domainData.push({
          name: domain.domainName ? domain.domainName : "Other Domains",
          value: Math.round(
            (Number(domain.totalQuestions) / Number(data.totalQuestions)) * 100
          ),
        });
      });
      setDomaninsData(domainData);
      setDomainDataValues(domainData.slice(0, 10));
    }
  }, []);

  const showHideGraphDetails = (): void => {
    if (!toggleShowMore) {
      setDomainDataValues(domainsData);
    } else {
      setDomainDataValues(domainsData.slice(0, 10));
    }
    setToggleShowMoreOption(!toggleShowMore);
  };

  return (
    <>
      {domainDataValues.length > 1 && (
        <div className="relative w-full px-7 py-7 bg-white rounded-md shadow-md shadow-xl xs-chart-fix xs-standards-chart-fix">
          <div
            id="chart_id_domain"
            style={{
              display: "inline-block",
            }}
          >
            <h2 className="text-md font-semibold text-gray-800 mb-2">
              {t("AssignmentResult.TotalQuestionsByDomain")}
            </h2>

            {domainDataValues.length > 1 && (
              <PIEChart data={domainDataValues}></PIEChart>
            )}

            {domainDataValues.length >= 10 && (
              <div style={{ textAlign: "center" }} className="show-more">
                {toggleShowMore ? (
                  <button
                    style={{ fontSize: "15px", color: "blue" }}
                    onClick={showHideGraphDetails}
                  >
                    Hide &#x2C4;
                  </button>
                ) : (
                  <button
                    style={{ fontSize: "15px", color: "blue" }}
                    onClick={showHideGraphDetails}
                  >
                    Show More &#x2C5;
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}

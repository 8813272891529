import { Fragment } from "react";
import { CloseIcon } from "../../../../../assets/icons/index";

interface VideoPopupProps {
  videoUrl: string;
  showPopup(show: boolean): void;
}

export default function VideoPopup(props: VideoPopupProps) {
  const { videoUrl, showPopup } = props;
  return (
    <Fragment>
      <div
        className="fixed inset-0 flex items-center bg-black bg-opacity-50 sm:items-center sm:justify-center"
        style={{ zIndex: 9999 }}
      >
        <div className=" w-4/6 flex flex-col justify-between overflow-hidden bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg">
          <header>
            {/* Header */}
            <div className="px-4 py-2 bg-gray-50">
              <div className="flex items-start justify-end space-x-3">
                <button
                  type="button"
                  className="inline-flex items-center justify-center w-6 h-6 text-gray-400 transition-colors duration-150 rounded dark:hover:text-gray-200 hover: hover:text-gray-700"
                  onClick={() => showPopup(false)}
                >
                  <CloseIcon />
                </button>
              </div>
            </div>
          </header>
          <div onContextMenu={(e) => e.preventDefault()} className="flex items-center justify-center px-6 pb-6 sm:flex-row bg-gray-50">
            <video
              className="w-full h-100"
              src={videoUrl}
              autoPlay
              controls
              controlsList="nodownload"
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
}

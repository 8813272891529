import { Fragment, useEffect, useState } from "react";
import { DragAndDropIcon, InformationIcon } from "../../../../assets/icons";
import Select from "react-select";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Disclosure } from "@headlessui/react";
import { IAssessmentQuestion } from "../../../../model/interface/assessmentQuestion";
import { IAssessment } from "../../../../model/interface/assessment";
import QuestionPreview from "../shared/questionPreview/questionPreview";
import {
  getCalculatorTypes,
  updateAssessmentData,
} from "../../../../api/teacher/assessment";
import Constant from "../../../../utils/constant/constant";
import RouteConstant from "../../../../utils/constant/routeConstant";
import { useHistory } from "react-router-dom";
import { ISelectElement } from "../../../../model/interface/selectElement";
import arraySort from "array-sort";
import ConfirmationDialog from "../../../shared/confirmationDialog";
import { IAssignmentActivity } from "../../../../model/interface/assignmentActivity";
import { getActivityTypeId } from "../../../../utils/helper";
import { addAssignment } from "../../../../api/teacher/assignment";
import Loader from "../../../shared/loader";
import QuestionBreakdownModal from "./questionBreakdownModal";
import QuestionOptionPopup from "../shared/questionOptionPopup";
import { RouterPrompt } from "../../../shared/routerPrompt";
import { getAsessementBuilderUpgradePermission } from "../../../shared/permissionHelper";
import Details from "../../../shared/details";
import ReactTooltip from "react-tooltip";
import { toast } from "react-toastify";
import PassageDraggableItems from "./PassageDraggableItems";
import AssessmentQuestionRowContent from "./AssessmentQuestionRowContent";

const groupByOptions = [
  { value: "1", label: "Select" },
  { value: "2", label: "Domain" },
  { value: "3", label: "Standard" },
];
interface PreviewProps {
  userId: number;
  roleId: number;
  districtId: number;
  hasDistrictAssessmentQuestionIcon: boolean;
  assessment: IAssessment;
  updateAssessment: (
    updateAssessment: IAssessment,
    moveToNextTab?: boolean
  ) => void;
  allowEdit: boolean;
  isCalculatorSubject: boolean;
  setUpgrade: Function;
  upgrade: boolean;
  isSchoolAssessment: boolean;
  defaultSchoolYearId: number;
}
interface IQuestionsWithPassageGroups {
  itemRank: number; // represents the initial top level draggable order, not updated by moving questions or groups
  domainOrder?: number;
  standardOrder?: number;
  isGroup: boolean;
  passageId: number;
  subQuestions: Array<IAssessmentQuestion>;
}

function Preview(props: PreviewProps) {
  const history = useHistory();
  const {
    userId,
    roleId,
    districtId,
    hasDistrictAssessmentQuestionIcon,
    assessment,
    updateAssessment,
    allowEdit,
    isCalculatorSubject,
    setUpgrade,
    upgrade,
    isSchoolAssessment,
  } = props;

  const [questions, setQuestions] = useState<Array<IAssessmentQuestion>>(
    assessment.questions ?? []
  );
  const [showGroupPassageInfoModal, setShowGroupPassageInfoModal] =
    useState<boolean>(false);
  const [
    showDisplayRandomQuestionInfoModal,
    setShowDisplayRandomQuestionInfoModal,
  ] = useState<boolean>(false);
  const [groupPassageMessageDisplayed, setGroupPassageMessageDisplayed] =
    useState<boolean>(false);
  const [hasGroupPassages, setHasGroupPassages] = useState<boolean>(false);
  const [questionsByPassageGroups, setQuestionsByPassageGroups] = useState<
    Array<IQuestionsWithPassageGroups>
  >([]);
  const [previewId, setPreviewId] = useState<string>("");
  const [questionIdPreview, setQuestionIdPreview] = useState<number>();
  const [open, setOpen] = useState<boolean>(false);
  const [sequenceChanged, setSequenceChanged] = useState<boolean>(false);
  const [questionText, setQuestionText] = useState<string>("");
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [selectedQuestionId, setSelectedQuestionId] = useState<number>();
  const [questionIndex, setQuestionIndex] = useState<number>(0);
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [standardId, setStandardId] = useState<number>(0);
  const [showQuestionBreakdownPopup, setShowQuestionBreakdownPopup] =
    useState(false);
  const [showReplaceQuestionPopup, setShowReplaceQuestionPopup] =
    useState(false);
  const [assessmentQuestionTypeId, setAssessmentQuestionTypeId] =
    useState<number>(0);
  const [passageId, setPassageId] = useState<number>(0);
  const [spanishVersion, setSpanishVersion] = useState<boolean>(false);
  const [isDataChanged, setDataChanged] = useState<boolean>(false);
  const [optionNavigated, setOptionNavigated] = useState<boolean>(false);
  const [sharedAssessment, setSharedAssessment] = useState<boolean>(
    assessment.isSharedAssessment !== undefined
      ? assessment.isSharedAssessment
      : true
  );
  type DisplayResultType = "NoDetail" | "QuestionsOnly" | "QuestionsAndAnswer";
  const [canAssign, setCanAssign] = useState<boolean>(false);
  const [displayResult, setDisplayResult] =
    useState<DisplayResultType>("NoDetail");
  const [canView, setCanView] = useState<boolean>(false);
  const [canDuplicate, setCanDuplicate] = useState<boolean>(false);
  const [displayRandomQuestion, setDisplayRandomQuestion] = useState<boolean>(
    assessment.isDisplayRandomQuestion !== undefined
      ? assessment.isDisplayRandomQuestion
      : false
  );
  const [calculators, setCalculators] = useState<Array<ISelectElement>>([]);
  const [selectedCalculator, setSelectedCalculator] =
    useState<ISelectElement>();
  const [allowCalculator, setAllowCalculator] = useState<boolean>(
    assessment.isAllowCalculator ? assessment.isAllowCalculator : false
  );

  function orderAndGroupQuestionsByPassage(
    pQuestions: Array<IAssessmentQuestion>
  ): Array<IQuestionsWithPassageGroups> {
    let itemRank = 0;
    let tempQuestionsByPassageGroups: Array<IQuestionsWithPassageGroups> = [];

    pQuestions.forEach(function (question) {
      let tPassageId = question.passageId || null;
      if (!tPassageId) {
        // no passage
        tempQuestionsByPassageGroups[itemRank] = {
          itemRank: itemRank,
          isGroup: false,
          domainOrder: question.domainOrder,
          standardOrder: question.standardOrder,
          subQuestions: [question],
          passageId: 0,
        };
        itemRank++;
      } else {
        // passage
        // if the passage doesn't match any current group, create a new group
        let passageFirstIndex = tempQuestionsByPassageGroups.findIndex(
          (el) => el.passageId == question.passageId
        );
        // set the passageIndex to either the found passage index or to the current item rank if no matching passage is found
        let passageIndex =
          passageFirstIndex != -1 ? passageFirstIndex : itemRank;
        // if there is already a group for this passage
        if (!tempQuestionsByPassageGroups[passageIndex]) {
          // create new group, but a group of 1 does not constitute the isGroup flag being set
          tempQuestionsByPassageGroups[passageIndex] = {
            itemRank: itemRank,
            isGroup: false,
            domainOrder: question.domainOrder,
            standardOrder: question.standardOrder,
            passageId: question.passageId || 0,
            subQuestions: [],
          };
        }

        // add question to subQuestions for passage
        (tempQuestionsByPassageGroups[passageIndex].subQuestions =
          tempQuestionsByPassageGroups[passageIndex].subQuestions || []).push(
          question
        );

        // don't increment itemRank if not first
        if (
          tempQuestionsByPassageGroups[passageIndex].subQuestions?.length == 1
        ) {
          itemRank++;
        } else {
          tempQuestionsByPassageGroups[passageIndex].isGroup = true;
          if (!hasGroupPassages) setHasGroupPassages(true);
        }
      }
    }, {});
    let sequenceNumber = 1;
    tempQuestionsByPassageGroups.map((questionPassageObject, index) => {
      questionPassageObject.subQuestions.map(
        (question) => (question.sequenceNumber = sequenceNumber++)
      );
    });

    return tempQuestionsByPassageGroups;
  }
  function flattenQuestionsByPassageGroups(
    pQuestionsByPassageGroup: Array<IQuestionsWithPassageGroups>
  ) {
    let tempQuestions: Array<IAssessmentQuestion> = [];
    pQuestionsByPassageGroup.map(({ isGroup, subQuestions }) => {
      if (isGroup) {
        subQuestions.forEach((subQuestion) => {
          tempQuestions.push(subQuestion);
        });
      } else {
        tempQuestions.push(subQuestions[0]);
      }
    });
    return tempQuestions;
  }

  const handleDragEnd = (result: any) => {
    if (displayRandomQuestion) {
      setShowDisplayRandomQuestionInfoModal(true);
      return;
    }
    if (!result.destination || questions.length <= 1) return;
    let tempQuestionsWithPassageGroups = [...questionsByPassageGroups];
    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;

    if (result.type === "droppableQuestion") {
      const [selectedGroup] = tempQuestionsWithPassageGroups.splice(
        sourceIndex,
        1
      );
      tempQuestionsWithPassageGroups.splice(destinationIndex, 0, selectedGroup);
      setQuestionsByPassageGroups(tempQuestionsWithPassageGroups);
      setQuestions(
        flattenQuestionsByPassageGroups(tempQuestionsWithPassageGroups)
      );
    } else if (result.type.includes("droppableSubQuestion")) {
      const parentGroupIndex = parseInt(result.type.split("-")[1]);
      if (tempQuestionsWithPassageGroups) {
        const passageGroupSubQuestionMap =
          tempQuestionsWithPassageGroups.reduce((acc, passageGroup) => {
            acc[passageGroup.itemRank] = passageGroup.subQuestions;
            return acc;
          }, {});
        const passageGroupSubQuestions =
          passageGroupSubQuestionMap[parentGroupIndex];
        const [selectedRow] = passageGroupSubQuestions.splice(sourceIndex, 1);
        passageGroupSubQuestions.splice(destinationIndex, 0, selectedRow);
        tempQuestionsWithPassageGroups = tempQuestionsWithPassageGroups.map(
          (passageGroup) => {
            if (passageGroup.itemRank === parentGroupIndex) {
              passageGroup.subQuestions = passageGroupSubQuestions;
            }
            return passageGroup;
          }
        );
      }
      setQuestionsByPassageGroups(tempQuestionsWithPassageGroups);
      setQuestions(
        flattenQuestionsByPassageGroups(tempQuestionsWithPassageGroups)
      );
    }
    setSequenceChanged(true);
    setDataChanged(true);
  };

  function handleGroupBy(selectedItem: ISelectElement | null) {
    if(selectedItem) {
      if (selectedItem.label === "Domain") {
        handleGroupByDomain();
      }
  
      if (selectedItem.label === "Standard") {
        handleGroupByStandard();
      }
    }
  }

  function handleGroupByStandard() {
    const sortByStandards = [...questionsByPassageGroups];
    arraySort(sortByStandards, "standardOrder");
    setQuestionsByPassageGroups(sortByStandards);
    let tempQuestions = flattenQuestionsByPassageGroups(
      questionsByPassageGroups
    );
    setQuestions(tempQuestions);
    setSequenceChanged(true);
  }

  function handleGroupByDomain() {
    const sortByDomains = [...questionsByPassageGroups];
    arraySort(sortByDomains, ["domainOrder", "standardOrder"]);
    setQuestionsByPassageGroups(sortByDomains);
    setQuestions(flattenQuestionsByPassageGroups(questionsByPassageGroups));
    setSequenceChanged(true);
  }

  function checkUpgradeRequired() {
    if (getAsessementBuilderUpgradePermission(roleId)) {
      setSharedAssessment(false);
      setUpgrade(true);
    } else {
      setUpgrade(false);
    }
  }

  async function handleSaveAndContinue() {
    setDataChanged(false);
    if (questions.length === 0) {
      return false;
    }

    setShowLoading(true);
    try {
      const assessmentToSave: IAssessment = {
        ...assessment,
        isSharedAssessment:
          roleId === Constant.UserRoleId.District ? true : sharedAssessment,
        displayResultInDetailId: getDisplayResult(displayResult),
        isDisplayRandomQuestion: displayRandomQuestion,
        isAllowCalculator: allowCalculator,
        calculatorTypeId:
          allowCalculator && selectedCalculator
            ? parseInt(selectedCalculator.value)
            : undefined,
        isSystemGenerated: assessment.isSystemGenerated,
        isPassageQuestion: assessment.isPassageQuestion,
        isAuthoredQuestion: assessment.isAuthoredQuestion,
        testSizeTypeId: assessment.testSizeTypeId,
        questions: questions,
        learnosityIdEnglish: assessment.learnosityIdEnglish,
        learnosityIdSpanish: assessment.learnosityIdSpanish,
        canAssign: canAssign,
        canView: canView,
        canDuplicate: canDuplicate,
      };
      if (sequenceChanged) {
        questions.forEach((question, index) => {
          question.sequenceNumber = index + 1;
        });
        assessmentToSave.questions = questions;
        assessmentToSave.updateQuestions = true;
        const existingTags: any = assessmentToSave.tagIds;
        if (existingTags && existingTags.indexOf(",") > -1) {
          assessmentToSave.tagIds = existingTags.split(",");
        }
      }

      await updateAssessmentData(userId, roleId, assessmentToSave)
        .then(() => {
          toast.success("Assessment saved successfully");
        })
        .catch(() => {
          toast.error(
            "An unexpected error occurred while trying to save the assessment"
          );
        });
      updateAssessment(assessmentToSave, false);
    } finally {
      setShowLoading(false);
      history.push(RouteConstant.ASSESSMENTBANK);
    }
  }

  function createAssignmentAndRedirect() {
    const assignmentActivity: IAssignmentActivity = {
      assignmentActivityId: 0,
      assignmentId: 0,
      activityTypeId: getActivityTypeId(
        Constant.AssignmentActivityType.ASSESSMENT
      ),
      activityType: Constant.AssignmentActivityType.ASSESSMENT,
      sequenceNumber: 1,
      assessmentId: assessment.assessmentId.toString(),
      assessmentName: assessment.name,
      learnosityIdEnglish: assessment.learnosityIdEnglish
        ? assessment.learnosityIdEnglish
        : `${Constant.AssignmentActivityType.ASSESSMENT}-${assessment.assessmentId}-en`,
      learnosityIdSpanish: assessment.learnosityIdSpanish
        ? assessment.learnosityIdSpanish
        : `${Constant.AssignmentActivityType.ASSESSMENT}-${assessment.assessmentId}-es`,
    };

    setShowLoading(true);
    addAssignment(
      userId,
      assessment.name,
      assessment.subjectId,
      [assignmentActivity],
      props.defaultSchoolYearId
    ).then((response) => {
      setTimeout(() => {
        setShowLoading(false);
        const assignmentId = response.data;
        history.push({
          pathname: RouteConstant.EDITASSIGNMENT.replace(":id", assignmentId),
          state: {
            editMode: "AssignStudent",
          },
        });
      }, 2000);
    });
  }

  function handleSaveAndAssign() {
    setDataChanged(false);
    setTimeout(() => {
      if (questions.length === 0) {
        return false;
      }

      const assessmentToSave: IAssessment = {
        ...assessment,
        isSharedAssessment:
          roleId === Constant.UserRoleId.District ? true : sharedAssessment,
        displayResultInDetailId: getDisplayResult(displayResult),
        isDisplayRandomQuestion: displayRandomQuestion,
        isAllowCalculator: allowCalculator,
        calculatorTypeId:
          allowCalculator && selectedCalculator
            ? parseInt(selectedCalculator.value)
            : undefined,
        isSystemGenerated: assessment.isSystemGenerated,
        isPassageQuestion: assessment.isPassageQuestion,
        isAuthoredQuestion: assessment.isAuthoredQuestion,
        testSizeTypeId: assessment.testSizeTypeId,
        questions: questions,
        learnosityIdEnglish: assessment.learnosityIdEnglish,
        learnosityIdSpanish: assessment.learnosityIdSpanish,
        canAssign: canAssign,
        canView: canView,
        canDuplicate: canDuplicate,
      };
      if (sequenceChanged) {
        questions.forEach((question, index) => {
          question.sequenceNumber = index + 1;
        });
        assessmentToSave.questions = questions;
        assessmentToSave.updateQuestions = true;
        updateAssessment(assessmentToSave, false);
        const existingTags: any = assessmentToSave.tagIds;
        if (existingTags && existingTags.indexOf(",") > -1) {
          assessmentToSave.tagIds = existingTags.split(",");
        }
      }
      updateAssessmentData(userId, roleId, assessmentToSave);

      createAssignmentAndRedirect();
    });
  }

  const openModal = (
    learnosityReferenceId,
    questionId,
    questionText,
    currentIndex,
    standardId,
    assessmentQuestionTypeId,
    passageId,
    spanishLearnosityReferenceId
  ) => {
    setOpen(true);
    setPreviewId(
      spanishVersion ? spanishLearnosityReferenceId : learnosityReferenceId
    );
    setQuestionIdPreview(questionId);
    setQuestionText(questionText);
    setQuestionIndex(currentIndex);
    setStandardId(standardId);
    setAssessmentQuestionTypeId(assessmentQuestionTypeId);
    setPassageId(passageId);
  };

  const popUpClose = (value) => {
    setOpen(value);
    setPreviewId("");
  };

  function handleNextQuestion() {
    const nextQuestion = questions[questionIndex + 1];
    if (nextQuestion && nextQuestion.learnosityReferenceId) {
      setPreviewId(
        spanishVersion && nextQuestion.spanishLearnosityReferenceId
          ? nextQuestion.spanishLearnosityReferenceId
          : nextQuestion.learnosityReferenceId
      );
      setQuestionIdPreview(nextQuestion.questionId);
      setQuestionText(
        nextQuestion.questionText ? nextQuestion.questionText : ""
      );
      setStandardId(parseInt(nextQuestion.standardId ?? "0"));
    }
    setQuestionIndex(questionIndex + 1);
  }

  function handlePreviousQuestion() {
    const previousQuestion = questions[questionIndex - 1];
    if (previousQuestion && previousQuestion.learnosityReferenceId) {
      setPreviewId(
        spanishVersion && previousQuestion.spanishLearnosityReferenceId
          ? previousQuestion.spanishLearnosityReferenceId
          : previousQuestion.learnosityReferenceId
      );
      setQuestionIdPreview(previousQuestion.questionId);
      setQuestionText(
        previousQuestion.questionText ? previousQuestion.questionText : ""
      );
      setStandardId(parseInt(previousQuestion.standardId ?? "0"));
    }
    setQuestionIndex(questionIndex - 1);
  }

  function handleDeleteQuestion(questionId: number) {
    setShowConfirmation(true);
    setSelectedQuestionId(questionId);
  }

  function handleOkConfirmation() {
    setShowConfirmation(false);
    deleteQuestion();
    popUpClose(false);
  }

  function handleCancelConfirmation() {
    setShowConfirmation(false);
  }

  function handleSubmissionPassageWarningCancelConfirmation() {
    setShowGroupPassageInfoModal(false);
    setGroupPassageMessageDisplayed(true);
  }
  function handleSubmissionPassageWarningOkConfirmation() {
    setShowGroupPassageInfoModal(false);
    setGroupPassageMessageDisplayed(true);
  }
  function handleRandomOrderConfirmation() {
    setShowDisplayRandomQuestionInfoModal(false);
  }

  function deleteQuestion() {
    let tempQuestionsByPassageGroups;
    if (selectedQuestionId) {
      const questionToDelete = questions.find(
        (r) => r.questionId == selectedQuestionId
      );
      const filteredQuestions = questions.filter(
        (r) => r.questionId !== selectedQuestionId
      );
      let numQuestionsWithMatchingPassage = 0;
      if (questionToDelete?.passageId !== 0) {
        numQuestionsWithMatchingPassage = filteredQuestions.filter(
          (r) => r.passageId == questionToDelete?.passageId
        ).length;
      }

      // delete from passage groups
      if (
        numQuestionsWithMatchingPassage == 1 ||
        numQuestionsWithMatchingPassage == 0
      ) {
        // we have to remove the passageGroup node for single passage questions and non-passage questions
        // note there is no addition back to groups on this screen so we don't have to worry
        // about recreating passage question draggable containers
        tempQuestionsByPassageGroups = questionsByPassageGroups.filter(
          (group) => group.subQuestions[0].questionId !== selectedQuestionId
        );
      } else if (numQuestionsWithMatchingPassage == 2) {
        // if passage parent has no other nodes then change to individual question
        tempQuestionsByPassageGroups = questionsByPassageGroups.map((group) => {
          let returnGroup = {
            ...group,
            subQuestions: group.subQuestions.filter(
              (subQuestion) => subQuestion.questionId !== selectedQuestionId
            ),
          };

          if (
            group.subQuestions.filter(
              (subQuestion) => subQuestion.questionId == selectedQuestionId
            ).length > 0
          ) {
            returnGroup.isGroup = false;
          }
          return returnGroup;
        });
      } else {
        // multiple passages (>2) still present in passage group
        // otherwise just remove the question from the subQuestions node from the passage draggable container
        tempQuestionsByPassageGroups = questionsByPassageGroups.map((group) => {
          return {
            ...group,
            subQuestions: group.subQuestions.filter(
              (subQuestion) => subQuestion.questionId !== selectedQuestionId
            ),
          };
        });
      }
    }
    setQuestionsByPassageGroups(tempQuestionsByPassageGroups);
    const newQuestions = flattenQuestionsByPassageGroups(
      tempQuestionsByPassageGroups
    );
    setQuestions(newQuestions);

    const assessmentToSave: IAssessment = assessment;
    assessmentToSave.questions = newQuestions;
    assessmentToSave.updateQuestions = true;
    updateAssessment(assessmentToSave, false);
    const existingTags: any = assessmentToSave.tagIds;
    if (existingTags && existingTags.indexOf(",") > -1) {
      assessmentToSave.tagIds = existingTags.split(",");
    }

    updateAssessmentData(userId, roleId, assessmentToSave);
  }

  function getDomainCount() {
    // use array.reduce...
    return Array.from(
      new Set(
        questions.map((q) => {
          if (q.domainId) {
            return q.domainId;
          }
        })
      )
    ).length;
  }

  function getStandardCount() {
    // use array.reduce...
    return Array.from(
      new Set(
        questions.map((q) => {
          if (q.standardId) {
            return q.standardId;
          }
        })
      )
    ).length;
  }

  function handleReplaceQuestion(questionId: number) {
    setOpen(false);
    setShowReplaceQuestionPopup(true);
    setSelectedQuestionId(questionId);
  }

  function handleReplacedQuestionUpdate(
    newAssessmentQuestion: IAssessmentQuestion
  ) {
    if (newAssessmentQuestion) {
      let tempQuestionsByPassageGroups;
      const replacedQuestion = questions.filter(
        (r) => r.questionId == selectedQuestionId
      )[0];

      const newPassageQuestionGroup = {
        itemRank: questions.length + 1, // this is just used for uniqueness
        domainOrder: newAssessmentQuestion.domainOrder,
        standardOrder: newAssessmentQuestion.standardOrder,
        isGroup: false,
        passageId: newAssessmentQuestion.passageId,
        subQuestions: [newAssessmentQuestion],
      };
      const newQuestionHasExistingPassageGroup =
        newAssessmentQuestion.passageId != 0 &&
        questionsByPassageGroups.findIndex((el) => {
          return el.passageId == newAssessmentQuestion.passageId;
        }) != -1;

      tempQuestionsByPassageGroups = questionsByPassageGroups.flatMap(
        (group) => {
          let returnGroup = group;
          let groupMatches =
            group.subQuestions.findIndex(
              (q) => q.questionId == replacedQuestion.questionId
            ) != -1;
          if (groupMatches) {
            // make sure we are removing the question from the right group
            if (
              newAssessmentQuestion.passageId == 0 &&
              replacedQuestion.passageId == 0
            ) {
              // if the new question is not a passage question, and the replacement is not a passage just replace the question
              returnGroup = {
                itemRank: group.itemRank,
                domainOrder: newAssessmentQuestion.domainOrder,
                standardOrder: newAssessmentQuestion.standardOrder,
                isGroup: false,
                passageId: 0,
                subQuestions: [newAssessmentQuestion],
              };
            } else if (
              newAssessmentQuestion.passageId == 0 &&
              replacedQuestion.passageId != 0
            ) {
              // if the new question is not a passage and the old one is, then find and remove the passage question
              // change its group status if necessary
              if (group.subQuestions.length > 2) {
                // the group still maintains its integrity because there are more than two items remaining
                returnGroup = {
                  ...group,
                  subQuestions: group.subQuestions.filter(
                    (subQuestion) =>
                      subQuestion.questionId !== selectedQuestionId
                  ),
                };
              } else if (group.subQuestions.length == 2) {
                // the group is now a singular question
                returnGroup = {
                  ...group,
                  isGroup: false,
                  subQuestions: group.subQuestions.filter(
                    (subQuestion) =>
                      subQuestion.questionId !== selectedQuestionId
                  ),
                };
              } else {
                // it was a singular passage question and needs to be deleted just return the new question
                return newPassageQuestionGroup;
              }
              return [returnGroup, newPassageQuestionGroup];
            } else {
              // the only situation remaining is if they are both passage questions
              // note that passageId == 0 for both is handled above, otherwise this would break
              if (
                replacedQuestion.passageId == newAssessmentQuestion.passageId
              ) {
                // if new and replacement have the same passage, just replace the subquestion in the group
                // group status will be unchanged
                let replacementIndex = group.subQuestions.findIndex(
                  (subQuestion) => subQuestion.questionId == selectedQuestionId
                );
                returnGroup = {
                  ...group,
                  subQuestions: group.subQuestions.splice(
                    replacementIndex,
                    1,
                    newAssessmentQuestion
                  ),
                };
              } else if (
                replacedQuestion.passageId != newAssessmentQuestion.passageId &&
                !newQuestionHasExistingPassageGroup
              ) {
                // if the passages differ for new and replaced and it does not belong to any other group (the condition above)
                // just create a new group and put the item in there
                if (group.subQuestions.length > 2) {
                  // the group still maintains its integrity because there are more than two items remaining
                  returnGroup = {
                    ...group,
                    subQuestions: group.subQuestions.filter(
                      (subQuestion) =>
                        subQuestion.questionId !== selectedQuestionId
                    ),
                  };
                } else if (group.subQuestions.length == 2) {
                  // the group is now a singular question
                  returnGroup = {
                    ...group,
                    isGroup: false,
                    subQuestions: group.subQuestions.filter(
                      (subQuestion) =>
                        subQuestion.questionId !== selectedQuestionId
                    ),
                  };
                } else {
                  // it was a singular passage question and needs to be deleted just return the new question
                  return newPassageQuestionGroup;
                }
                return [returnGroup, newPassageQuestionGroup];
              }
            }
          }
          return returnGroup;
        }
      );
      setQuestionsByPassageGroups(tempQuestionsByPassageGroups);
      const newQuestions = flattenQuestionsByPassageGroups(
        tempQuestionsByPassageGroups
      );
      setQuestions(newQuestions);

      const assessmentToSave: IAssessment = assessment;
      assessmentToSave.questions = newQuestions;
      assessmentToSave.updateQuestions = true;
      updateAssessment(assessmentToSave, false);
      updateAssessmentData(userId, roleId, assessmentToSave).catch((err) => {
        console.log(err);
      });
      setShowReplaceQuestionPopup(false);
    }
  }
  function showSpanishVersion(e, questionIdPreview) {
    setSpanishVersion(e.target.checked);
    const currentQuestion = questions?.find(
      (q) => q.questionId === questionIdPreview
    );
    if (currentQuestion) {
      if (e.target.checked && currentQuestion.spanishLearnosityReferenceId) {
        setPreviewId(currentQuestion.spanishLearnosityReferenceId);
      } else if (currentQuestion.learnosityReferenceId) {
        setPreviewId(currentQuestion.learnosityReferenceId);
      }
    }
  }
  function handleOptionNavigation(isNavigated: boolean) {
    setOptionNavigated(isNavigated);
  }

  function getDisplayResult(resultType: DisplayResultType | undefined) {
    let result: number = 0;
    switch (resultType) {
      case "NoDetail":
        result = 1;
        break;
      case "QuestionsOnly":
        result = 2;
        break;
      case "QuestionsAndAnswer":
        result = 3;
        break;
    }

    return result;
  }

  function setDisplayResultValue(value: number) {
    let result: DisplayResultType = "NoDetail";
    switch (value) {
      case 1:
        result = "NoDetail";
        break;
      case 2:
        result = "QuestionsOnly";
        break;
      case 3:
        result = "QuestionsAndAnswer";
        break;
    }
    setDisplayResult(result);
  }

  function setExistingValues() {
    if (assessment) {
      if (assessment.displayResultInDetailId) {
        setDisplayResultValue(assessment.displayResultInDetailId);
      }

      if (assessment.canAssign) {
        setCanAssignValue(assessment.canAssign);
      }
      if (assessment.canView) {
        setCanViewValue(assessment.canView);
      }
      if (assessment.canDuplicate) {
        setCanDuplicateValue(assessment.canDuplicate);
      }
    }
  }

  function getCalculators() {
    setShowLoading(true);
    getCalculatorTypes().then((res) => {
      setShowLoading(false);
      const calculatorTypes = res.data.map((values: any) => {
        return {
          label: values?.name,
          value: values?.calculatorTypeId,
        };
      });
      setCalculators(
        calculatorTypes.filter((r) => r.label.toLowerCase() !== "none")
      );

      if (assessment.isAllowCalculator && assessment.calculatorTypeId) {
        const calculatorType: ISelectElement = calculatorTypes.filter(
          (r) => r.value.toString() === assessment.calculatorTypeId?.toString()
        )[0];
        setSelectedCalculator(calculatorType);
      }
    });
  }

  useEffect(() => {
    if (assessment.questions) {
      const tempQuestionsWithPassageGroups = orderAndGroupQuestionsByPassage(
        assessment.questions
      );
      setQuestionsByPassageGroups(tempQuestionsWithPassageGroups);
      setQuestions(
        flattenQuestionsByPassageGroups(tempQuestionsWithPassageGroups)
      );
    }
    checkUpgradeRequired();
    setExistingValues();
    if (isCalculatorSubject) getCalculators();
  }, []);

  function setCanAssignValue(value: boolean, isChanged: boolean = false) {
    setCanAssign(value);
    if (assessment.assessmentId > 0 && isChanged) {
      setDataChanged(true);
    }
  }

  function setCanViewValue(value: boolean, isChanged: boolean = false) {
    setCanView(value);
    if (assessment.assessmentId > 0 && isChanged) {
      setDataChanged(true);
    }
  }

  function setCanDuplicateValue(value: boolean, isChanged: boolean = false) {
    setCanDuplicate(value);
    if (assessment.assessmentId > 0 && isChanged) {
      setDataChanged(true);
    }
  }

  function handleCalculatorChange(selectdItem: any) {
    setSelectedCalculator(selectdItem);
    if (assessment.assessmentId > 0) {
      setDataChanged(true);
    }
  }

  return (
    <Fragment>
      {showLoading && <Loader></Loader>}
      <ReactTooltip
        multiline={true}
        className="bg-secondary-teal w-1/3"
        backgroundColor="#008fbb"
        place="right"
        effect="float"
      />
      {isDataChanged && !optionNavigated && (
        <RouterPrompt
          key={"preview"}
          when={isDataChanged && !optionNavigated}
          title="Leave this page?"
          saveText="Save Assessment and Leave"
          cancelText="Cancel"
          okText="Cancel Changes and Leave"
          message="You are leaving the Assessment Builder without saving. Would you like to continue?"
          onSaveAndLeave={handleSaveAndContinue}
          onOK={() => true}
          onCancel={() => false}
        />
      )}
      <ConfirmationDialog
        open={showConfirmation}
        confirmationTitle={"Please confirm"}
        confirmationMessage={
          "Are you sure you want to remove this question from the assessment?"
        }
        onCancelClick={handleCancelConfirmation}
        onOkClick={handleOkConfirmation}
      ></ConfirmationDialog>
      <ConfirmationDialog
        open={showGroupPassageInfoModal && !groupPassageMessageDisplayed}
        confirmationTitle={"Passage Groups"}
        confirmationMessage={
          "You have set this assessment to randomize questions and have at least 1 passage with more than 1 question associated. Keeping the setting to randomize will separate passage questions. To keep them together, update the Display questions in random order setting to 'No'."
        }
        onCancelClick={handleSubmissionPassageWarningCancelConfirmation}
        onOkClick={handleSubmissionPassageWarningOkConfirmation}
      ></ConfirmationDialog>
      <ConfirmationDialog
        open={showDisplayRandomQuestionInfoModal}
        confirmationTitle={"Random Order Info"}
        confirmationMessage={
          "Display questions in random order has been set to 'Yes'. Update the setting to 'No' to reorder your questions."
        }
        onCancelClick={handleRandomOrderConfirmation}
        onOkClick={handleRandomOrderConfirmation}
      ></ConfirmationDialog>
      <QuestionPreview
        key={previewId}
        modal={openModal}
        show={open}
        changeValue={popUpClose}
        itemRef={previewId}
        questionIdPreview={questionIdPreview}
        activityName={questionText}
        nextQuestionClick={handleNextQuestion}
        previousQuestionClick={handlePreviousQuestion}
        removeQuestionClick={allowEdit ? handleDeleteQuestion : null}
        currentQuestionIndex={questionIndex}
        totalQuestions={questions.length}
        standardId={standardId}
        replaceQuestionClick={allowEdit ? handleReplaceQuestion : null}
        showSpanishVersion={showSpanishVersion}
        spanishVersion={spanishVersion}
      ></QuestionPreview>
      {showReplaceQuestionPopup && (
        <QuestionOptionPopup
          standardId={standardId}
          assessmentId={assessment.assessmentId}
          questionTypeId={previewId.indexOf("-teacher") !== -1 ? 2 : 1}
          setOpen={setShowReplaceQuestionPopup}
          selectQuestion={handleReplacedQuestionUpdate}
          handleOptionNavigation={handleOptionNavigation}
          assessmentQuestionTypeId={assessmentQuestionTypeId}
          passageId={passageId}
          districtId={props.districtId}
          hasDistrictAssessmentQuestionIcon={props.hasDistrictAssessmentQuestionIcon}
          limit={5}
        ></QuestionOptionPopup>
      )}
      <div className="px-4 sm:px-6  lg:px-8 py-6">
        <div className="relative">
          <div className="text-sm text-gray-700 font-light bg-blue-50 p-4 mt-4 flex w-full rounded-md mb-6 rounded-t-lg sm:rounded-lg overflow-hidden">
            <div className="flex-shrink-0 current-fill text-blue-400">
              <InformationIcon />
            </div>
            <span className="pl-2">
              All content is displayed in the order in which it was added.
              Questions can be manually moved by selecting <DragAndDropIcon />{" "}
              (hamburger menu) and dragging up or down. You also have an option
              to group your questions by domain and standard. Note: If you have
              set the Display Questions in Random Order setting to Yes, then
              your updates will only display in your view as content shall be
              randomized for students.
            </span>
          </div>
          <div className="flex space-y-2 sm:space-y-0 justify-between flex-wrap space-x-4 mb-2 border-b pb-2">
            <p className="self-center block text-base font-medium text-gray-400 ">
              Settings
            </p>
            <div className="flex gap-4">
              <button
                onClick={() => {
                  if (
                    hasGroupPassages &&
                    displayRandomQuestion &&
                    !groupPassageMessageDisplayed
                  ) {
                    setShowGroupPassageInfoModal(true);
                  } else {
                    handleSaveAndContinue();
                  }
                }}
                className="bg-secondary-teal border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-dark-teal hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 whitespace-nowrap"
              >
                Save Assessment
              </button>
              {(roleId === Constant.UserRoleId.SchoolAdmin ||
                roleId === Constant.UserRoleId.FreeTeacher ||
                roleId === Constant.UserRoleId.SchoolTeacher) && (
                <button
                  onClick={() => {
                    if (
                      hasGroupPassages &&
                      displayRandomQuestion &&
                      !groupPassageMessageDisplayed
                    ) {
                      setShowGroupPassageInfoModal(true);
                    } else {
                      handleSaveAndAssign();
                    }
                  }}
                  className="bg-secondary-teal border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-dark-teal hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 whitespace-nowrap"
                >
                  Save and Assign
                </button>
              )}
            </div>
          </div>
          <div className="grid grid-cols-1 gap-y-4 lg:grid-cols-12 lg:gap-4 mb-4">
            <form className="col-span-8 space-y-8 divide-y divide-gray-200">
              <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                <div className="space-y-6 sm:space-y-5">
                  <div className="space-y-6 sm:space-y-5">
                    <div>
                      {(roleId === Constant.UserRoleId.SchoolTeacher ||
                        roleId === Constant.UserRoleId.FreeTeacher ||
                        roleId === Constant.UserRoleId.SchoolAdmin) && (
                        <div
                          role="group"
                          aria-labelledby="label-notifications"
                          className={isSchoolAssessment ? "hidden" : ""}
                        >
                          <div className="sm:grid sm:grid-cols-4 md:grid-cols-2 lg:grid-cols-4 sm:gap-4">
                            <div
                              data-tip={
                                upgrade
                                  ? "Upgrade to a School Account to access this feature."
                                  : "Enabling this setting will allow other teachers to assign/duplicate this assessment."
                              }
                            >
                              <div
                                className="text-sm text-gray-500"
                                id="label-notifications"
                              >
                                Share Assessment
                              </div>
                            </div>
                            <div className="flex col-span-8 sm:col-span-4 xl:col-span-2 gap-4">
                              <label
                                x-radio-group-option=""
                                className=" relative flex cursor-pointer focus:outline-none border-gray-400"
                              >
                                {upgrade && (
                                  <input
                                    disabled={true}
                                    type="radio"
                                    checked={false}
                                    x-model="value"
                                    name="assignment"
                                    value="Public access"
                                    className="h-4 w-4 cursor-pointer text-secondary-teal border-gray-300 focus:ring-secondary-teal"
                                    aria-labelledby="privacy-setting-0-label"
                                    aria-describedby="privacy-setting-0-description"
                                  />
                                )}
                                {!upgrade && (
                                  <input
                                    type="radio"
                                    checked={sharedAssessment}
                                    x-model="value"
                                    name="assignment"
                                    value="Public access"
                                    className="h-4 w-4 cursor-pointer text-secondary-teal border-gray-300 focus:ring-secondary-teal"
                                    aria-labelledby="privacy-setting-0-label"
                                    aria-describedby="privacy-setting-0-description"
                                    onChange={() => {
                                      setSharedAssessment(true);
                                      if (assessment.assessmentId > 0) {
                                        setDataChanged(true);
                                      }
                                    }}
                                  />
                                )}

                                <div className="ml-3 flex flex-col">
                                  <span
                                    id="privacy-setting-0-label"
                                    className="block text-sm text-gray-500"
                                  >
                                    Yes
                                  </span>
                                </div>
                              </label>
                              <label
                                x-radio-group-option=""
                                className=" relative flex cursor-pointer focus:outline-none border-gray-400"
                              >
                                {upgrade && (
                                  <input
                                    disabled={true}
                                    checked={false}
                                    type="radio"
                                    x-model="value"
                                    name="assignment"
                                    value="Public access"
                                    className="h-4 w-4 cursor-pointer text-secondary-teal border-gray-300 focus:ring-secondary-teal"
                                    aria-labelledby="privacy-setting-0-label"
                                    aria-describedby="privacy-setting-0-description"
                                    onChange={() => {
                                      setSharedAssessment(false);
                                      if (assessment.assessmentId > 0) {
                                        setDataChanged(true);
                                      }
                                    }}
                                  />
                                )}

                                {!upgrade && (
                                  <input
                                    checked={!sharedAssessment}
                                    type="radio"
                                    x-model="value"
                                    name="assignment"
                                    value="Public access"
                                    className="h-4 w-4 cursor-pointer text-secondary-teal border-gray-300 focus:ring-secondary-teal"
                                    aria-labelledby="privacy-setting-0-label"
                                    aria-describedby="privacy-setting-0-description"
                                    onChange={() => {
                                      setSharedAssessment(false);
                                      if (assessment.assessmentId > 0) {
                                        setDataChanged(true);
                                      }
                                    }}
                                  />
                                )}
                                <div className="ml-3 flex flex-col">
                                  <span
                                    id="privacy-setting-0-label"
                                    className="block text-sm text-gray-500"
                                  >
                                    No
                                  </span>
                                </div>
                              </label>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div>
                      <div role="group" aria-labelledby="label-notifications">
                        <div className="sm:grid sm:grid-cols-4 lg:grid-cols-4 sm:gap-4">
                          <div>
                            <div
                              className="text-sm text-gray-500 mb-2 sm:mb-0"
                              id="label-notifications"
                              data-tip="This setting provides the ability to disable students from seeing all of the questions and answers, <br /> seeing only the questions without the answers or the full list of both questions with answers from their Results view upon completing an assessment.<br /> Students can still view their grade and scores by Domain and Standard.<br /> This setting can be changed later or after all students have completed the assessment"
                            >
                              Allow students to view item results in detail
                            </div>
                          </div>
                          <div className="flex items-start col-span-6 sm:col-span-3 gap-4">
                            <label
                              x-radio-group-option=""
                              className=" relative flex cursor-pointer focus:outline-none border-gray-400"
                            >
                              <input
                                type="radio"
                                checked={displayResult === "NoDetail"}
                                x-model="value"
                                name="view-item-detail"
                                value="Public access"
                                className="h-4 w-4 cursor-pointer text-secondary-teal border-gray-300 focus:ring-secondary-teal"
                                aria-labelledby="privacy-setting-0-label"
                                aria-describedby="privacy-setting-0-description"
                                onChange={() => {
                                  setDisplayResult("NoDetail");
                                  if (assessment.assessmentId > 0) {
                                    setDataChanged(true);
                                  }
                                }}
                              />
                              <div className="ml-3 flex flex-col">
                                <span
                                  id="privacy-setting-0-label"
                                  className="block text-sm text-gray-500"
                                >
                                  View no detail (Scores only)
                                </span>
                              </div>
                            </label>
                            <label
                              x-radio-group-option=""
                              className=" relative flex cursor-pointer focus:outline-none border-gray-400"
                            >
                              <input
                                checked={displayResult === "QuestionsOnly"}
                                type="radio"
                                x-model="value"
                                name="view-item-detail"
                                value="Public access"
                                className="h-4 w-4 cursor-pointer text-secondary-teal border-gray-300 focus:ring-secondary-teal"
                                aria-labelledby="privacy-setting-0-label"
                                aria-describedby="privacy-setting-0-description"
                                onChange={() => {
                                  setDisplayResult("QuestionsOnly");
                                  if (assessment.assessmentId > 0) {
                                    setDataChanged(true);
                                  }
                                }}
                              />
                              <div className="ml-3 flex flex-col">
                                <span
                                  id="privacy-setting-0-label"
                                  className="block text-sm text-gray-500"
                                >
                                  View questions only
                                </span>
                              </div>
                            </label>
                            <label
                              x-radio-group-option=""
                              className=" relative flex cursor-pointer focus:outline-none border-gray-400"
                            >
                              <input
                                checked={displayResult === "QuestionsAndAnswer"}
                                type="radio"
                                x-model="value"
                                name="view-item-detail"
                                value="Public access"
                                className="h-4 w-4 cursor-pointer text-secondary-teal border-gray-300 focus:ring-secondary-teal"
                                aria-labelledby="privacy-setting-0-label"
                                aria-describedby="privacy-setting-0-description"
                                onChange={() => {
                                  setDisplayResult("QuestionsAndAnswer");
                                  if (assessment.assessmentId > 0) {
                                    setDataChanged(true);
                                  }
                                }}
                              />
                              <div className="ml-3 flex flex-col">
                                <span
                                  id="privacy-setting-0-label"
                                  className="block text-sm text-gray-500"
                                >
                                  View questions and answers
                                </span>
                              </div>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* Allow Teachers to Assign Assessment */}
                    <div>
                      {(isSchoolAssessment ||
                        ((roleId === Constant.UserRoleId.SchoolAdmin ||
                          roleId == Constant.UserRoleId.FreeTeacher) &&
                          assessment.sharedTypeId ===
                            Constant.AssessmentSharedType.SchoolShared)) && (
                        <div role="group" aria-labelledby="label-notifications">
                          <div className="sm:grid sm:grid-cols-4 lg:grid-cols-4 sm:gap-4">
                            <div>
                              <div
                                className="text-sm text-gray-500 mb-2"
                                id="label-notifications"
                                data-tip={
                                  " Enabling this setting will allow teachers to assign the assessment to their students"
                                }
                              >
                                Allow Teachers to Assign Assessment
                              </div>
                            </div>
                            <div className="flex items-start col-span-6 sm:col-span-3 xl:col-span-2 gap-4">
                              <label
                                x-radio-group-option=""
                                className=" relative flex cursor-pointer focus:outline-none border-gray-400"
                              >
                                <input
                                  checked={canAssign}
                                  type="radio"
                                  x-model="value"
                                  name="allow-change-canAssign"
                                  value="Public access"
                                  className="h-4 w-4 cursor-pointer text-secondary-teal border-gray-300 focus:ring-secondary-teal"
                                  aria-labelledby="privacy-setting-0-label"
                                  aria-describedby="privacy-setting-0-description"
                                  onChange={() => {
                                    setCanAssignValue(!canAssign, true);
                                    if (assessment.assessmentId > 0) {
                                      setDataChanged(true);
                                    }
                                  }}
                                />
                                <div className="ml-3 flex flex-col">
                                  <span
                                    id="privacy-setting-0-label"
                                    className="block text-sm text-gray-500"
                                  >
                                    Yes
                                  </span>
                                </div>
                              </label>
                              <label
                                x-radio-group-option=""
                                className=" relative flex cursor-pointer focus:outline-none border-gray-400"
                              >
                                <input
                                  type="radio"
                                  checked={!canAssign}
                                  x-model="value"
                                  name="allow-change-canAssign"
                                  value="Public access"
                                  className="h-4 w-4 cursor-pointer text-secondary-teal border-gray-300 focus:ring-secondary-teal"
                                  aria-labelledby="privacy-setting-0-label"
                                  aria-describedby="privacy-setting-0-description"
                                  onChange={() =>
                                    setCanAssignValue(!canAssign, true)
                                  }
                                />
                                <div className="ml-3 flex flex-col">
                                  <span
                                    id="privacy-setting-0-label"
                                    className="block text-sm text-gray-500"
                                  >
                                    No
                                  </span>
                                </div>
                              </label>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    {/* Allow Teachers to View Assessment Content and Details */}
                    <div>
                      {(isSchoolAssessment ||
                        ((roleId === Constant.UserRoleId.SchoolAdmin ||
                          roleId === Constant.UserRoleId.FreeTeacher) &&
                          assessment.sharedTypeId ===
                            Constant.AssessmentSharedType.SchoolShared)) && (
                        <div role="group" aria-labelledby="label-notifications">
                          <div className="sm:grid sm:grid-cols-4 lg:grid-cols-4 sm:gap-4 ">
                            <div>
                              <div
                                className="text-sm text-gray-500 mb-2"
                                id="label-notifications"
                                data-tip={
                                  " Enabling this setting will allow teachers to view all content related to this assessment and results for students whom the teacher assigned"
                                }
                              >
                                Allow Teachers to View Assessment Content and
                                Details
                              </div>
                            </div>
                            <div className="flex col-span-6 sm:col-span-3 xl:col-span-2 gap-4">
                              <label
                                x-radio-group-option=""
                                className=" relative flex cursor-pointer focus:outline-none border-gray-400"
                              >
                                <input
                                  checked={canView}
                                  type="radio"
                                  x-model="value"
                                  name="allow-change-canView"
                                  value="Public access"
                                  className="h-4 w-4 cursor-pointer text-secondary-teal border-gray-300 focus:ring-secondary-teal"
                                  aria-labelledby="privacy-setting-0-label"
                                  aria-describedby="privacy-setting-0-description"
                                  onChange={() =>
                                    setCanViewValue(!canView, true)
                                  }
                                />
                                <div className="ml-3 flex flex-col">
                                  <span
                                    id="privacy-setting-0-label"
                                    className="block text-sm text-gray-500"
                                  >
                                    Yes
                                  </span>
                                </div>
                              </label>
                              <label
                                x-radio-group-option=""
                                className=" relative flex cursor-pointer focus:outline-none border-gray-400"
                              >
                                <input
                                  type="radio"
                                  checked={!canView}
                                  x-model="value"
                                  name="allow-change-canView"
                                  value="Public access"
                                  className="h-4 w-4 cursor-pointer text-secondary-teal border-gray-300 focus:ring-secondary-teal"
                                  aria-labelledby="privacy-setting-0-label"
                                  aria-describedby="privacy-setting-0-description"
                                  onChange={() =>
                                    setCanViewValue(!canView, true)
                                  }
                                />
                                <div className="ml-3 flex flex-col">
                                  <span
                                    id="privacy-setting-0-label"
                                    className="block text-sm text-gray-500"
                                  >
                                    No
                                  </span>
                                </div>
                              </label>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    {/* Allow Teachers to Duplicate Assessment */}
                    <div>
                      {(isSchoolAssessment ||
                        ((roleId === Constant.UserRoleId.SchoolAdmin ||
                          roleId == Constant.UserRoleId.FreeTeacher) &&
                          assessment.sharedTypeId ===
                            Constant.AssessmentSharedType.SchoolShared)) && (
                        <div role="group" aria-labelledby="label-notifications">
                          <div className="sm:grid sm:grid-cols-4 lg:grid-cols-4 sm:gap-4">
                            <div>
                              <div
                                className="text-sm text-gray-500 mb-2"
                                id="label-notifications"
                                data-tip={
                                  " Enabling this setting will allow teachers to duplicate this assessment"
                                }
                              >
                                Allow Teachers to Duplicate Assessment
                              </div>
                            </div>
                            <div className="flex col-span-6 sm:col-span-3 xl:col-span-2 gap-4">
                              <label
                                x-radio-group-option=""
                                className=" relative flex cursor-pointer focus:outline-none border-gray-400"
                              >
                                <input
                                  checked={canDuplicate}
                                  type="radio"
                                  x-model="value"
                                  name="allow-change-canDuplicate"
                                  value="Public access"
                                  className="h-4 w-4 cursor-pointer text-secondary-teal border-gray-300 focus:ring-secondary-teal"
                                  aria-labelledby="privacy-setting-0-label"
                                  aria-describedby="privacy-setting-0-description"
                                  onChange={() =>
                                    setCanDuplicateValue(!canDuplicate, true)
                                  }
                                />
                                <div className="ml-3 flex flex-col">
                                  <span
                                    id="privacy-setting-0-label"
                                    className="block text-sm text-gray-500"
                                  >
                                    Yes
                                  </span>
                                </div>
                              </label>
                              <label
                                x-radio-group-option=""
                                className=" relative flex cursor-pointer focus:outline-none border-gray-400"
                              >
                                <input
                                  type="radio"
                                  checked={!canDuplicate}
                                  x-model="value"
                                  name="allow-change-canDuplicate"
                                  value="Public access"
                                  className="h-4 w-4 cursor-pointer text-secondary-teal border-gray-300 focus:ring-secondary-teal"
                                  aria-labelledby="privacy-setting-0-label"
                                  aria-describedby="privacy-setting-0-description"
                                  onChange={() =>
                                    setCanDuplicateValue(!canDuplicate, true)
                                  }
                                />
                                <div className="ml-3 flex flex-col">
                                  <span
                                    id="privacy-setting-0-label"
                                    className="block text-sm text-gray-500"
                                  >
                                    No
                                  </span>
                                </div>
                              </label>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div>
                      <div role="group" aria-labelledby="label-notifications">
                        <div className="sm:grid sm:grid-cols-4 lg:grid-cols-4 sm:gap-4">
                          <div>
                            <div
                              className="text-sm text-gray-500 mb-2"
                              id="label-notifications"
                              data-tip="The questions will be shown in a random order for each student. If you have passage questions and apply ‘yes’ to this setting, the passages will be separated."
                            >
                              Display questions in random order
                            </div>
                          </div>
                          <div className="flex col-span-8 sm:col-span-3 xl:col-span-2 gap-4 ">
                            <label
                              x-radio-group-option=""
                              className=" relative flex cursor-pointer focus:outline-none border-gray-400"
                            >
                              <input
                                type="radio"
                                checked={displayRandomQuestion}
                                x-model="value"
                                name="question-random"
                                value="Public access"
                                className="h-4 w-4 cursor-pointer text-secondary-teal border-gray-300 focus:ring-secondary-teal"
                                aria-labelledby="privacy-setting-0-label"
                                aria-describedby="privacy-setting-0-description"
                                onChange={() => {
                                  setDisplayRandomQuestion(true);
                                  if (assessment.assessmentId > 0) {
                                    setDataChanged(true);
                                  }
                                }}
                              />
                              <div className="ml-3 flex flex-col">
                                <span
                                  id="privacy-setting-0-label"
                                  className="block text-sm text-gray-500"
                                >
                                  Yes
                                </span>
                              </div>
                            </label>
                            <label
                              x-radio-group-option=""
                              className=" relative flex cursor-pointer focus:outline-none border-gray-400"
                            >
                              <input
                                checked={!displayRandomQuestion}
                                type="radio"
                                x-model="value"
                                name="question-random"
                                value="Public access"
                                className="h-4 w-4 cursor-pointer text-secondary-teal border-gray-300 focus:ring-secondary-teal"
                                aria-labelledby="privacy-setting-0-label"
                                aria-describedby="privacy-setting-0-description"
                                onChange={() => {
                                  setDisplayRandomQuestion(false);
                                  if (assessment.assessmentId > 0) {
                                    setDataChanged(true);
                                  }
                                }}
                              />
                              <div className="ml-3 flex flex-col">
                                <span
                                  id="privacy-setting-0-label"
                                  className="block text-sm text-gray-500"
                                >
                                  No
                                </span>
                              </div>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    {isCalculatorSubject && (
                      <div>
                        <div role="group" aria-labelledby="label-notifications">
                          <div className="sm:grid sm:grid-cols-4 md:grid-cols-2 lg:grid-cols-4 sm:gap-4">
                            <div>
                              <div
                                className="text-sm text-gray-500 mb-2"
                                id="label-notifications"
                                data-tip={
                                  "Four Function -This calculator allows for basic functionality to add, subtract, multiply and divide. <br />Scientific -This calculator includes basic operations and additional functionality for trigonometry, roots, fractions, exponents, absolute value, pi, and statistics. <br />Graphing-This calculator includes the ability to graph functions, plot data, evaluate equations, and explore translations."
                                }
                              >
                                Allow calculator
                              </div>
                            </div>
                            <div className="flex col-span-8 sm:col-span-3 xl:col-span-3 gap-4 items-center">
                              <label
                                x-radio-group-option=""
                                className=" relative flex cursor-pointer focus:outline-none border-gray-400"
                              >
                                <input
                                  type="radio"
                                  checked={allowCalculator}
                                  x-model="value"
                                  name="allow-calculator"
                                  value="Public access"
                                  className="h-4 w-4 cursor-pointer text-secondary-teal border-gray-300 focus:ring-secondary-teal"
                                  aria-labelledby="privacy-setting-0-label"
                                  aria-describedby="privacy-setting-0-description"
                                  onChange={() => {
                                    setAllowCalculator(true);
                                    if (assessment.assessmentId > 0) {
                                      setDataChanged(true);
                                    }
                                  }}
                                />
                                <div className="ml-3 flex flex-col">
                                  <span
                                    id="privacy-setting-0-label"
                                    className="block text-sm text-gray-500"
                                  >
                                    Yes
                                  </span>
                                </div>
                              </label>
                              <label
                                x-radio-group-option=""
                                className=" relative flex cursor-pointer focus:outline-none border-gray-400"
                              >
                                <input
                                  checked={!allowCalculator}
                                  type="radio"
                                  x-model="value"
                                  name="allow-calculator"
                                  value="Public access"
                                  className="h-4 w-4 cursor-pointer text-secondary-teal border-gray-300 focus:ring-secondary-teal"
                                  aria-labelledby="privacy-setting-0-label"
                                  aria-describedby="privacy-setting-0-description"
                                  onChange={() => {
                                    setAllowCalculator(false);
                                    if (assessment.assessmentId > 0) {
                                      setDataChanged(true);
                                    }
                                  }}
                                />
                                <div className="ml-3 flex flex-col">
                                  <span
                                    id="privacy-setting-0-label"
                                    className="block text-sm text-gray-500"
                                  >
                                    No
                                  </span>
                                </div>
                              </label>
                              <div className="w-72">
                                {allowCalculator && (
                                  <Select
                                    value={selectedCalculator}
                                    options={calculators}
                                    onChange={handleCalculatorChange}
                                    theme={(theme) => ({
                                      ...theme,
                                      colors: {
                                        ...theme.colors,
                                        primary: "#992883",
                                      },
                                    })}
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </form>
            <Details questions={questions.length > 0 ? questions : []} />
          </div>
          <div className="space-y-8 mb-4 border-b border-gray-300 pb-4">
            <div className="flex flex-col space-y-2 sm:space-y-0 sm:flex-row justify-between sm:grid sm:grid-cols-12 sm:gap-4 sm:items-end sm:border-t sm:border-gray-200 sm:pt-5">
              <div className="col-span-4 xl:col-span-3 xxl:col-span-2">
                <label
                  htmlFor="country"
                  className="mb-1 block text-sm text-gray-500"
                >
                  Group By
                </label>
                <Select
                  options={groupByOptions}
                  onChange={handleGroupBy}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: "#008fbb",
                    },
                  })}
                />
              </div>
            </div>
          </div>
          <DragDropContext
            onDragEnd={(result) => {
              handleDragEnd(result);
            }}
          >
            <div className="overflow-auto pt-3">
              <div
                className="w-full flex items-center cursor-pointer"
                onClick={() => {
                  setShowQuestionBreakdownPopup(true);
                }}
              >
                <div className="px-3 py-2 w-5 flex-none"></div>
                <div className="px-3 py-2 w-[20%] lg:w-[20%] xl:w-[20%] 1xl:w-[25%] xxl:w-[35%] xxxl:w-[50%] flex-grow"></div>
                <div className="w-[80px] xl:w-[150px] px-3 py-2 font-semibold text-center flex-none">
                  Point Value
                </div>
                <div className="w-[100px] xl:w-[150px] px-3 py-2 font-semibold flex-none">
                  Question Type
                </div>
                <div className="w-[100px] lg:w-[130px] xl:w-[150px] px-3 py-2 relative border-t border-b border-gray-400 flex-none">
                  <span className="absolute -top-3 -right-16 xl:right-[-75px] bg-white px-1 font-semibold">
                    Assessment Stats
                  </span>
                  Domain:{" "}
                  <span className="text-primary-violet">
                    {getDomainCount()}
                  </span>
                </div>
                <div className="px-3 py-2 w-32 lg:w-32 xl:w-40 whitespace-nowrap border-t border-b border-gray-400 flex-none">
                  Standard:{" "}
                  <span className="text-primary-violet">
                    {getStandardCount()}
                  </span>
                </div>
                <div className="w-[5.5rem] lg:w-[5.5rem] xl:w-28 1xl:w-36 px-3 py-2 flex-none"></div>
                <div className="w-5 flex-none"></div>
              </div>
              <div className="min-w-full divide-y divide-gray-200">
                <Droppable droppableId="tbody" type="droppableQuestion">
                  {(provided) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      {questionsByPassageGroups &&
                        questionsByPassageGroups.map((listItem, index) => (
                          <Draggable
                            draggableId={listItem.itemRank.toString()}
                            key={listItem.itemRank}
                            index={index}
                          >
                            {(provided) => (
                              <>
                                <Disclosure key={index}>
                                  <div
                                    key={index}
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    className={
                                      index % 2 === 0
                                        ? "bg-white"
                                        : "bg-gray-50"
                                    }
                                  >
                                    {listItem.isGroup && (
                                      <div
                                        className={
                                          "w-full bg-gray-50 text-gray-500 p-4 rounded-t-md font-semibold mt-2"
                                        }
                                      >
                                        Passage Group for:{" "}
                                        {listItem.subQuestions[0].passageTitle}
                                      </div>
                                    )}
                                    <div
                                      className={
                                        "w-full flex items-center" +
                                        (listItem.isGroup
                                          ? " bg-gray-100 py-2 pr-2 rounded-b-md mb-2"
                                          : "")
                                      }
                                    >
                                      <div
                                        {...provided.dragHandleProps}
                                        className={
                                          "py-2 w-5 flex-none" +
                                          (listItem.isGroup ? " mr-2 " : "")
                                        }
                                      >
                                        {!listItem.isGroup && (
                                          <DragAndDropIcon
                                            title={"Drag and drop question"}
                                            className="w-6 h-6"
                                          ></DragAndDropIcon>
                                        )}
                                        {listItem.isGroup && (
                                          <>
                                            <DragAndDropIcon
                                              title={
                                                "Drag and drop passage question group"
                                              }
                                              className="w-6 h-6"
                                            ></DragAndDropIcon>
                                            <DragAndDropIcon
                                              title={
                                                "Drag and drop passage question group"
                                              }
                                              className="w-6 h-6"
                                            ></DragAndDropIcon>
                                            <DragAndDropIcon
                                              title={
                                                "Drag and drop passage question group"
                                              }
                                              className="w-6 h-6"
                                            ></DragAndDropIcon>
                                          </>
                                        )}
                                      </div>
                                      {!listItem.isGroup && (
                                        <AssessmentQuestionRowContent
                                          isGroup={false}
                                          index={index}
                                          question={listItem.subQuestions[0]}
                                          openModal={openModal}
                                          allowEdit={allowEdit}
                                          handleDeleteQuestion={
                                            handleDeleteQuestion
                                          }
                                          districtId={districtId}
                                          hasDistrictAssessmentQuestionIcon={hasDistrictAssessmentQuestionIcon}
                                        />
                                      )}
                                      {listItem.isGroup &&
                                        listItem.subQuestions && (
                                          <PassageDraggableItems
                                            index={index}
                                            subQuestions={listItem.subQuestions}
                                            type={listItem.itemRank.toString()}
                                            openModal={openModal}
                                            allowEdit={allowEdit}
                                            handleDeleteQuestion={
                                              handleDeleteQuestion
                                            }
                                            districtId={districtId}
                                            hasDistrictAssessmentQuestionIcon={hasDistrictAssessmentQuestionIcon}
                                          />
                                        )}
                                    </div>
                                  </div>
                                </Disclosure>
                              </>
                            )}
                          </Draggable>
                        ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </div>
            </div>
          </DragDropContext>
        </div>
      </div>
      {showQuestionBreakdownPopup && (
        <QuestionBreakdownModal
          assessmentId={assessment.assessmentId}
          showPopUp={setShowQuestionBreakdownPopup}
        />
      )}
    </Fragment>
  );
}

export default Preview;

import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Breadcrumb from "../../middleSchool/breadcrumb";
import { IBreadcrumb } from "../../../../../model/common/breadcrumb";
import RouteConstant from "../../../../../utils/constant/routeConstant";
import constant from "../../../../../utils/constant/constant";
import {
  GetStudentGalaxyArcadeGameScore,
  GetStudentSchoolArcadeGameScore,
} from "../../../../../api/student/studentArcadeGameScoreApi";
import { Configuration } from "../../../../../environment/setup";
import { connect } from "react-redux";
import { getArcadeGames } from "../../../../../api/student/arcadeGames";
import { IArcadeGames } from "../../../../../model/interface/arcadeGame";
import { IArcadeGameStudentScores } from "../../../../../model/interface/arcadeGameStudentScore";
import moment from "moment";
import Loader from "../../../../shared/loader";

function LeaderboardMiddleSchool(props: any) {
  const { t } = useTranslation();
  const programMode = constant.ProgramMode.PL;
  const [arcadeGames, setArcadeGames] = useState<Array<IArcadeGames>>([]);
  const [schoolArcadeGameScores, setSchoolArcadeGameScores] = useState<
    Array<IArcadeGameStudentScores>
  >([]);
  const [galaxyArcadeGameScores, setGalaxyArcadeGameScores] = useState<
    Array<IArcadeGameStudentScores>
  >([]);
  const [selectedGameName, setSelectedGameName] = useState<string>("");
  const [bgColor, setBgColor] = useState("#00b1fb");
  const [showLoading, setShowLoading] = useState<boolean>(false);

  const bgColors = [
    "#00b1fb",
    "#d2ff00",
    "#00ffff",
    "#e962ff",
    "#00ff37",
    "#ff8300",
    "#ff6293",
    "#34D9A5",
    "#b86be6",
    "#6d92ff",
    "#e74c3c",
    "#5dade2",
  ];

  const breadcrumbItems = () => {
    const items: Array<IBreadcrumb> = [
      {
        name: "Home",
        url: RouteConstant.MiddleSchool.assignments,
        textColor: "text-white",
      },
      {
        name: t("Breadcrumb.Leaderboard"),
        textColor: "text-white",
        url: "",
      },
    ];

    return items;
  };

  useEffect(() => {
    getArcadeGames().then((d) => {
      if (d) {
        setArcadeGames(d.data);
      }
      handleGameSelect(d.data[0]?.arcadeGameId, d.data[0]?.name);
    });
  }, [props.userContext?.userId]);

  function handleGameSelect(arcadeGameId: number, gameName?: string) {
    setShowLoading(true);
    setSelectedGameName(gameName || "");
    GetStudentSchoolArcadeGameScore(
      arcadeGameId,
      programMode === constant.ProgramMode.PL ? 1 : 2,
      props.userContext?.schoolId
    ).then((d) => {
      var gameScoresData = d.data;
      setSchoolArcadeGameScores(gameScoresData.gameScores);
      setShowLoading(false);
    });
    GetStudentGalaxyArcadeGameScore(
      arcadeGameId,
      programMode === constant.ProgramMode.PL ? 1 : 2
    ).then((d) => {
      var gameScoresData = d.data;
      setGalaxyArcadeGameScores(gameScoresData.gameScores);
    });
  }

  return (
    <Fragment>
      {/* main section */}
      <>
        {showLoading && <Loader />}
        <div className="md:flex md:items-center px-4 py-3 lg:px-8 border-b">
          <Breadcrumb items={breadcrumbItems()} />
          <div className="md:w-[55%] text-lg flex justify-between gap-2 h-auto sm:h-10 items-center">
            <h1 className="text-gray-50 font-medium flex justify-start">
              {t("Shared.StudentSideBar.Leaderboard")}
            </h1>
          </div>
        </div>
        <div className="px-4 py-4 lg:px-8">
          <section className="mt-10 mb-4">
            <div className="grid grid-cols-10 gap-6">
              <div className="col-span-10 md:col-span-10 lg:col-span-5 ">
                <div className="grid grid-cols-1 xs:grid-cols-2 md:grid-cols-3 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-3 xxxl:grid-cols-4 gap-6">
                  {arcadeGames.map((game, index) => {
                    return (
                      game.arcadeGameId !== 0 && (
                        <div
                          className={
                            (bgColors[index] === bgColor ? "shadow-2xl" : "") +
                            `block px-4 pt-4 pb-6 rounded-md relative hover:shadow-2xl transition ease-in-out duration-300 `
                          }
                          style={{ backgroundColor: bgColors[index] }}
                        >
                          <span className="relative w-32 rounded-xl overflow-hidden inline-block flex flex-col mx-auto">
                            <img
                              alt="game"
                              title="Game"
                              className="w-full flex-shrink-0 m-auto "
                              src={`${Configuration.S3bucketImagePath}${game.imageurl}`}
                            />
                          </span>
                          <div className="text-center">
                            <h2 className="text-base py-4">{game.name}</h2>
                            <a
                              href="#!"
                              onClick={() => {
                                handleGameSelect(game.arcadeGameId, game.name);
                                setBgColor(bgColors[index]);
                              }}
                              className="py-2 px-5 rounded-md inline-block w-full text-xs text-gray-900 bg-white shadow-lg transition ease-in-out duration-150"
                            >
                              <span className=" ">View Score</span>
                            </a>
                          </div>
                        </div>
                      )
                    );
                  })}
                </div>
              </div>

              <div className="col-span-10 md:col-span-10 lg:col-span-5 md:pb-8 lg:pb-4 xl:mb-0 h-full">
                <div className="h-full bg-[#00b1fb] rounded-md relative px-2 py-2 sm:px-6 lg:px-6">
                  <div className="w-full mt-4">
                    <h3 className="bg-gray-700 rounded-t-md px-6 py-3 capitalize text-white text-lg  font-medium">
                      {selectedGameName} {t("Leaderboard.SchoolRank")}
                    </h3>
                    <div className="rounded-b-md overflow-auto">
                      <table className="table min-w-full text-sm bg-white divide-y divide-gray-200">
                        <thead className=" bg-gray-200 uppercase">
                          <tr className="bg-white px-2 space-x-4">
                            <th className="w-20 bg-gray-200 px-2 xl:px-2 2xl:px-6 py-3 text-center text-sm font-semibold text-dark-teal uppercase tracking-wider ">
                              {t("Leaderboard.Place")}
                            </th>
                            <th className="bg-gray-100 px-2 xl:px-2 2xl:px-6 py-3 text-left text-sm font-semibold text-dark-teal uppercase tracking-wider">
                              {t("Leaderboard.Name")}
                            </th>
                            <th className="w-40 bg-gray-200 px-2 xl:px-2 2xl:px-6 py-3 text-center text-sm font-semibold text-dark-teal uppercase tracking-wider">
                              {t("Leaderboard.Score")}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {schoolArcadeGameScores &&
                            schoolArcadeGameScores.map((gameScore, index) => {
                              return (
                                <tr
                                  className={
                                    (index % 2 === 0
                                      ? "bg-white"
                                      : "bg-opacity-90") + " border-b-2"
                                  }
                                  key={index}
                                >
                                  <td className="px-6 py-4 bg-gray-100 text-center">
                                    {gameScore.position}
                                  </td>
                                  <td className="px-6 py-4 border-r-2 border-gray-100">
                                    {gameScore.studentFirstName.charAt(0)}
                                    {gameScore.studentLastName.charAt(0)}
                                  </td>
                                  <td className="px-6 py-4 text-center border-r-2 border-gray-100">
                                    {gameScore.score}
                                  </td>
                                </tr>
                              );
                            })}
                          <tr>
                            <td colSpan={3}>
                              {schoolArcadeGameScores.length === 0 && (
                                <div className="pl-2 pt-2 bg-white">
                                  No data found.
                                </div>
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="w-full mt-6">
                    <h3 className="bg-gray-700 rounded-t-md px-6 py-3 capitalize text-white text-lg  font-medium">
                      {t("Leaderboard.TOP20SCORES")}
                    </h3>
                    <div className="rounded-md overflow-auto">
                      <table className="table min-w-full text-sm bg-white divide-y divide-gray-200">
                        <thead className=" bg-gray-200 uppercase">
                          <tr className="bg-white px-2 space-x-4">
                            <th className="w-20 bg-gray-200 px-2 xl:px-2 2xl:px-6 py-3 text-center text-sm font-semibold text-dark-teal uppercase tracking-wider ">
                              {t("Leaderboard.Place")}
                            </th>
                            <th className="bg-gray-100 px-2 xl:px-2 2xl:px-6 py-3 text-left text-sm font-semibold text-dark-teal uppercase tracking-wider">
                              {t("Leaderboard.SchoolName")}
                            </th>
                            <th className="bg-gray-200 px-2 xl:px-2 2xl:px-6 py-3 text-left text-sm font-semibold text-dark-teal uppercase tracking-wider">
                              {t("Leaderboard.Name")}
                            </th>
                            <th className="w-40 bg-gray-100 px-2 xl:px-2 2xl:px-6 py-3 text-center text-sm font-semibold text-dark-teal uppercase tracking-wider">
                              {t("Leaderboard.Score")}
                            </th>
                            <th className="w-40 bg-gray-100 px-2 xl:px-2 2xl:px-6 py-3 text-center text-sm font-semibold text-dark-teal uppercase tracking-wider">
                              {t("Leaderboard.Date")}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {galaxyArcadeGameScores &&
                            galaxyArcadeGameScores.map((gameScore, index) => {
                              return (
                                <tr
                                  className={
                                    (index % 2 === 0
                                      ? "bg-white"
                                      : "bg-opacity-90") + " border-b-2"
                                  }
                                  key={index}
                                >
                                  <td className="px-6 py-4 bg-gray-100 text-center">
                                    {" "}
                                    {gameScore.position}
                                  </td>
                                  <td className="px-6 py-4 border-r-2 border-gray-100">
                                    {" "}
                                    {gameScore.schoolName}
                                  </td>
                                  <td className="px-6 py-4 border-r-2 border-gray-100 text-left">
                                    {" "}
                                    {gameScore.studentFirstName.charAt(0)}
                                    {gameScore.studentLastName.charAt(0)}
                                  </td>
                                  <td className="px-6 py-4 border-r-2 border-gray-100 text-center">
                                    {" "}
                                    {gameScore.score}
                                  </td>
                                  <td className="px-6 py-4 border-r-2 border-gray-100 text-center">
                                    {" "}
                                    {moment(gameScore.dateScored).format(
                                      "MM/DD/YYYY"
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                          <tr>
                            <td colSpan={5}>
                              {galaxyArcadeGameScores.length === 0 && (
                                <div className="pl-2 pt-2 bg-white">
                                  No data found.
                                </div>
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
      {/* main section end */}
    </Fragment>
  );
}

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
};

export default connect<{}, {}, {}>(
  mapStateToProps,
  {}
)(LeaderboardMiddleSchool);

import { XIcon } from "@heroicons/react/solid";
import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  getStudentRewards,
  updateStudentBlasters,
} from "../../../../../api/student/studentApi";
import { LockIcon } from "../../../../../assets/icons";
import { UserContextState } from "../../../../../redux/actions/userContextAction";
import { getBlasterValues } from "../../../../../utils/studentHelper";

export interface BlasterPopupProps {
  userContext?: UserContextState;
  rewards?: any;
  type: string;
  showPopup: (show: boolean) => void;
}

const BlasterPopup: React.FC<BlasterPopupProps> = (props) => {
  const { t } = useTranslation();
  type BlasterType = "cannon" | "energy" | "fire" | "ice" | "lazer" | "missile";
  const totalBlasterLevel = 4;
  // const totalBlasters = 24;

  const [blasters, setBlasters] = useState<any>();
  const [cannon, setCannon] = useState<Array<any>>([]);
  const [energy, setEnergy] = useState<Array<any>>([]);
  const [fire, setFire] = useState<Array<any>>([]);
  const [ice, setIce] = useState<Array<any>>([]);
  const [lazer, setLazer] = useState<Array<any>>([]);
  const [missile, setMissile] = useState<Array<any>>([]);

  useEffect(() => {
    if (props.userContext?.userId && props.rewards) {
      getRewards(props.userContext?.userId);
    }
  }, [props.rewards]);

  const getRewards = (userId) => {
    getStudentRewards(userId).then((r) => {
      if (r.data) {
        if (props?.type === "PL") {
          setBlasterValues(r.data.plBlasterSetting);
        } else {
          setBlasterValues(r.data.loBlasterSetting);
        }
      }
    });
  };

  const updateBlaster = (
    blaster: any,
    studentId: number,
    programMode: string
  ) => {
    updateStudentBlasters(blaster, studentId, programMode).then((r) => {
      setBlasterValues(r.data);
    });
  };

  const setBlasterValues = (blasterSetting: any) => {
    setBlasters(blasterSetting);
    let rewards: any = {};
    if (props.type === "PL") {
      rewards = props.rewards.plStarReward;
    } else {
      rewards = props.rewards.loStarReward;
    }

    const blasterValues = getBlasterValues(blasterSetting, rewards);
    const cannon = blasterValues.filter((b: any) => b.type === "cannon");
    const energy = blasterValues.filter((b: any) => b.type === "energy");
    const fire = blasterValues.filter((b: any) => b.type === "fire");
    const ice = blasterValues.filter((b: any) => b.type === "ice");
    const lazer = blasterValues.filter((b: any) => b.type === "lazer");
    const missile = blasterValues.filter((b: any) => b.type === "missile");
    setCannon(cannon);
    setEnergy(energy);
    setFire(fire);
    setIce(ice);
    setLazer(lazer);
    setMissile(missile);
  };

  const upgradeBlasters = (type: BlasterType) => {
    if (isBlasterUpgradeable(type) === 1) {
      const tempBlasters = blasters;
      if (type === "cannon") {
        if (tempBlasters.cannon < totalBlasterLevel) tempBlasters.cannon++;
      } else if (type === "energy") {
        if (tempBlasters.energy < totalBlasterLevel) tempBlasters.energy++;
      } else if (type === "fire") {
        if (tempBlasters.fire < totalBlasterLevel) tempBlasters.fire++;
      } else if (type === "ice") {
        if (tempBlasters.ice < totalBlasterLevel) tempBlasters.ice++;
      } else if (type === "lazer") {
        if (tempBlasters.lazer < totalBlasterLevel) tempBlasters.lazer++;
      } else if (type === "missile") {
        if (tempBlasters.missile < totalBlasterLevel) tempBlasters.missile++;
      }
      updateBlaster(tempBlasters, props.userContext?.userId!, props?.type);
    }
  };

  const isBlasterUpgradeable = (type: BlasterType) => {
    let tempBlaster: any = [];
    if (type === "cannon") {
      tempBlaster = cannon.filter((c) => !c.isEnabled);
    } else if (type === "energy") {
      tempBlaster = energy.filter((c) => !c.isEnabled);
    } else if (type === "fire") {
      tempBlaster = fire.filter((c) => !c.isEnabled);
    } else if (type === "ice") {
      tempBlaster = ice.filter((c) => !c.isEnabled);
    } else if (type === "lazer") {
      tempBlaster = lazer.filter((c) => !c.isEnabled);
    } else if (type === "missile") {
      tempBlaster = missile.filter((c) => !c.isEnabled);
    }

    if (tempBlaster.length > 0 && tempBlaster[0].starsRequired === 0) {
      return 1; // true
    } else if (tempBlaster.length > 0 && tempBlaster[0].starsRequired > 0) {
      return 2; // more stars required
    } else if (tempBlaster.length === 0) {
      return 3; // false
    }
  };

  return (
    <>
      <section>
        <div className="fixed inset-0 z-50 flex bg-black bg-opacity-50 items-center sm:justify-center enter-done">
          <div className="w-full flex flex-col justify-between overflow-hidden h-auto max-h-80percent bg-white rounded-lg dark:bg-gray-800 sm:m-4 sm:max-w-xl">
            <header className="flex justify-between p-4">
              <h2>{t("StudentElementaryHome.Blasters")} </h2>
              <XIcon
                className="cursor-pointer h-4 w-4"
                onClick={() => props.showPopup(false)}
              ></XIcon>
            </header>
            <div
              data-focus-lock-disabled="false"
              className="w-full relative overflow-auto px-4 py-3"
            >
              <div className="max-w-7xl mx-auto bg-[#23afff] p-4 rounded-2xl">
                {cannon.length > 0 &&
                  energy.length > 0 &&
                  fire.length > 0 &&
                  missile.length > 0 &&
                  lazer.length > 0 &&
                  ice.length > 0 && (
                    <ul className="grid grid-cols-2 gap-x-1 gap-y-2 sm:grid-cols-3 sm:gap-x-3 lg:grid-cols-6 xl:gap-x-4">
                      {[...Array(4)].map((a, index) => (
                        <Fragment key={index}>
                          <li className="relative" key={"cannon" + index}>
                            <div
                              title="Cannon"
                              className="group bg-white bg-opacity-50 hover:bg-opacity-80 p-2 block w-full rounded-lg focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden transition ease-in-out duration-500"
                            >
                              <img
                                src={cannon[index].path}
                                alt="cannon"
                                className="object-cover pointer-events-none m-auto h-8"
                              />

                              {!cannon[index].isEnabled && (
                                <div className="flex items-center justify-center">
                                  <div className="absolute bg-white p-1 rounded-lg">
                                    <LockIcon className="w-4 h-4" />
                                  </div>
                                </div>
                              )}
                            </div>
                          </li>

                          <li className="relative" key={"energy" + index}>
                            <div
                              title="Energy"
                              className="group bg-white bg-opacity-50 hover:bg-opacity-80 p-2 block w-full rounded-lg focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden transition ease-in-out duration-500"
                            >
                              <img
                                src={energy[index].path}
                                alt="energy"
                                className="object-cover pointer-events-none m-auto h-8"
                              />

                              {!energy[index].isEnabled && (
                                <div className="flex items-center justify-center">
                                  <div className="absolute bg-white p-1 rounded-lg">
                                    <LockIcon className="w-4 h-4" />
                                  </div>
                                </div>
                              )}
                            </div>
                          </li>

                          <li className="relative" key={"fire" + index}>
                            <div
                              title="Fire"
                              className="group bg-white bg-opacity-50 hover:bg-opacity-80 p-2 block w-full rounded-lg focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden transition ease-in-out duration-500"
                            >
                              <img
                                src={fire[index].path}
                                alt="fire"
                                className="object-cover pointer-events-none m-auto h-8"
                              />

                              {!fire[index].isEnabled && (
                                <div className="flex items-center justify-center">
                                  <div className="absolute bg-white p-1 rounded-lg">
                                    <LockIcon className="w-4 h-4" />
                                  </div>
                                </div>
                              )}
                            </div>
                          </li>

                          <li className="relative" key={"ice" + index}>
                            <div
                              title="Ice"
                              className="group bg-white bg-opacity-50 hover:bg-opacity-80 p-2 block w-full rounded-lg focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden transition ease-in-out duration-500"
                            >
                              <img
                                src={ice[index].path}
                                alt="ice"
                                className="object-cover pointer-events-none m-auto h-8"
                              />

                              {!ice[index].isEnabled && (
                                <div className="flex items-center justify-center">
                                  <div className="absolute bg-white p-1 rounded-lg">
                                    <LockIcon className="w-4 h-4" />
                                  </div>
                                </div>
                              )}
                            </div>
                          </li>

                          <li className="relative" key={"lazer" + index}>
                            <div
                              title="Laser"
                              className="group bg-white bg-opacity-50 hover:bg-opacity-80 p-2 block w-full rounded-lg focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden transition ease-in-out duration-500"
                            >
                              <img
                                src={lazer[index].path}
                                alt="laser"
                                className="object-cover pointer-events-none m-auto h-8"
                              />

                              {!lazer[index].isEnabled && (
                                <div className="flex items-center justify-center">
                                  <div className="absolute bg-white p-1 rounded-lg">
                                    <LockIcon className="w-4 h-4" />
                                  </div>
                                </div>
                              )}
                            </div>
                          </li>

                          <li className="relative" key={"missile" + index}>
                            <div
                              title="Missile"
                              className="group bg-white bg-opacity-50 hover:bg-opacity-80 p-2 block w-full rounded-lg focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden transition ease-in-out duration-500"
                            >
                              <img
                                src={missile[index].path}
                                alt="missile"
                                className="object-cover pointer-events-none m-auto h-8"
                              />

                              {!missile[index].isEnabled && (
                                <div className="flex items-center justify-center">
                                  <div className="absolute bg-white p-1 rounded-lg">
                                    <LockIcon className="w-4 h-4" />
                                  </div>
                                </div>
                              )}
                            </div>
                          </li>
                        </Fragment>
                      ))}

                      {/* cannon */}
                      <button
                        className="align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 rounded-lg text-sm text-white border border-transparent  focus:shadow-outline-purple w-full sm:w-auto bg-primary-blue hover:bg-[#0149c1] gap-2 hover:shadow-md"
                        type="button"
                        onClick={() => upgradeBlasters("cannon")}
                      >
                        {isBlasterUpgradeable("cannon") === 1
                          ? t("Rockets.Upgrade")
                          : isBlasterUpgradeable("cannon") === 2
                          ? t("Rockets.Get") +
                            cannon.filter((c) => !c.isEnabled)[0]
                              .starsRequired +
                            t("Rockets.StarsToUnlock")
                          : isBlasterUpgradeable("cannon") === 3
                          ? t("Rockets.Upgraded")
                          : ""}
                      </button>

                      {/* energy */}
                      <button
                        className="align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 rounded-lg text-sm text-white border border-transparent  focus:shadow-outline-purple w-full sm:w-auto bg-primary-blue hover:bg-[#0149c1] gap-2 hover:shadow-md"
                        type="button"
                        onClick={() => upgradeBlasters("energy")}
                      >
                        {isBlasterUpgradeable("energy") === 1
                          ? t("Rockets.Upgrade")
                          : isBlasterUpgradeable("energy") === 2
                          ? t("Rockets.Get") +
                            energy.filter((c) => !c.isEnabled)[0]
                              .starsRequired +
                            t("Rockets.StarsToUnlock")
                          : isBlasterUpgradeable("energy") === 3
                          ? t("Rockets.Upgraded")
                          : ""}
                      </button>

                      {/* fire */}
                      <button
                        className="align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 rounded-lg text-sm text-white border border-transparent  focus:shadow-outline-purple w-full sm:w-auto bg-primary-blue hover:bg-[#0149c1] gap-2 hover:shadow-md"
                        type="button"
                        onClick={() => upgradeBlasters("fire")}
                      >
                        {isBlasterUpgradeable("fire") === 1
                          ? t("Rockets.Upgrade")
                          : isBlasterUpgradeable("fire") === 2
                          ? t("Rockets.Get") +
                            fire.filter((c) => !c.isEnabled)[0].starsRequired +
                            t("Rockets.StarsToUnlock")
                          : isBlasterUpgradeable("fire") === 3
                          ? t("Rockets.Upgraded")
                          : ""}
                      </button>

                      {/* ice */}
                      <button
                        className="align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 rounded-lg text-sm text-white border border-transparent  focus:shadow-outline-purple w-full sm:w-auto bg-primary-blue hover:bg-[#0149c1] gap-2 hover:shadow-md"
                        type="button"
                        onClick={() => upgradeBlasters("ice")}
                      >
                        {isBlasterUpgradeable("ice") === 1
                          ? t("Rockets.Upgrade")
                          : isBlasterUpgradeable("ice") === 2
                          ? t("Rockets.Get") +
                            ice.filter((c) => !c.isEnabled)[0].starsRequired +
                            t("Rockets.StarsToUnlock")
                          : isBlasterUpgradeable("ice") === 3
                          ? t("Rockets.Upgraded")
                          : ""}
                      </button>

                      {/* lazer */}
                      <button
                        className="align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 rounded-lg text-sm text-white border border-transparent  focus:shadow-outline-purple w-full sm:w-auto bg-primary-blue hover:bg-[#0149c1] gap-2 hover:shadow-md"
                        type="button"
                        onClick={() => upgradeBlasters("lazer")}
                      >
                        {isBlasterUpgradeable("lazer") === 1
                          ? t("Rockets.Upgrade")
                          : isBlasterUpgradeable("lazer") === 2
                          ? t("Rockets.Get") +
                            lazer.filter((c) => !c.isEnabled)[0].starsRequired +
                            t("Rockets.StarsToUnlock")
                          : isBlasterUpgradeable("lazer") === 3
                          ? t("Rockets.Upgraded")
                          : ""}
                      </button>

                      {/* missile */}
                      <button
                        className="align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 rounded-lg text-sm text-white border border-transparent  focus:shadow-outline-purple w-full sm:w-auto bg-primary-blue hover:bg-[#0149c1] gap-2 hover:shadow-md"
                        type="button"
                        onClick={() => upgradeBlasters("missile")}
                      >
                        {isBlasterUpgradeable("missile") === 1
                          ? t("Rockets.Upgrade")
                          : isBlasterUpgradeable("missile") === 2
                          ? t("Rockets.Get") +
                            missile.filter((c) => !c.isEnabled)[0]
                              .starsRequired +
                            t("Rockets.StarsToUnlock")
                          : isBlasterUpgradeable("missile") === 3
                          ? t("Rockets.Upgraded")
                          : ""}
                      </button>
                    </ul>
                  )}
              </div>
            </div>
            <footer className="flex bg-gray-50 flex-col items-center justify-end px-6 py-4 sm:flex-row">
              <button
                className="align-bottom inline-flex font-bold items-center justify-center cursor-pointer leading-5 transition-colors duration-150  focus:outline-none px-5 py-3 rounded-lg text-lg text-white bg-primary-violet hover:shadow-lg hover:bg-dark-violet"
                type="button"
                onClick={() => props.showPopup(false)}
              >
                Ok
              </button>
            </footer>
          </div>
        </div>
      </section>
    </>
  );
};

export default BlasterPopup;

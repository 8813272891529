import React, { Fragment, useState } from "react";
import { Collapse } from "react-collapse";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import {
  DateIcon,
  ExpandIcon,
  GridIcon,
  ListIcon,
  VideoRecorderIcon,
} from "../../../../assets/icons/index";
import VideoMessageData from "../../../../model/videoMessage/videoMessageData";
import VideoMessageFilters from "../../../../model/videoMessage/videoMessageFilters";
import Constants from "../../../../utils/constant/constant";
import ConfirmationDialog from "../../../shared/confirmationDialog";
import Header from "../../../shared/header/header";
import InformationDialog from "../../../shared/informationDialog";
import Loader from "../../../shared/loader";
import MultiselectComponent from "../../../shared/multiselect";
import VideoMessageCardView from "../videoMessageCardView";
import VideoMessageListView from "../videoMessageListView";
import VideoMessageModal, {
  VideoMessageModalProps,
} from "../videoMessageModal";
import {
  deleteVideoMessage,
  getVideoMessage,
  publishData,
  uploadFile,
} from "./api";

export interface VideoMessageManagementProps {}

const VideoMessageManagement: React.FC<VideoMessageManagementProps> = (
  props: any
) => {
  const defaultVideoMessageData: Array<VideoMessageData> = [];

  const [videoMessageData, setVideoMessageData] = useState(
    defaultVideoMessageData
  );
  const [messageTitle, setMessageTitle] = useState("");
  const [viewCount, setviewCount] = useState(-1);
  const [selectedStartDate, setStartDate] = useState<any>("");
  const [selectedEndDate, setEndDate] = useState<any>("");
  const [selectedViewCountRelation, setViewCountRelation] = useState(0);
  const [selectedVideoStatus, setselectedVideoStatus] = useState(-1);
  const [selectedGrade, setGrade] = useState<any[]>([]);
  const [selectedClasses, setClasses] = useState<any[]>([]);
  const [selectedAuthors, setAuthors] = useState<any[]>([]);
  const [selectedStudents, setStudents] = useState<any[]>([]);
  const [multiSelectClassOptions, setMultiSelectClassOptions] = useState<any[]>(
    []
  );
  const [multiSelectAuthorOptions, setMultiSelectAuthorOptions] = useState<
    any[]
  >([]);
  const [multiSelectStudentOptions, setMultiSelectStudentOptions] = useState<
    any[]
  >([]);
  const [deleteMessageId, setDeleteMessageId] = useState<number>(0);
  const [showModal, toggleModalPopup] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [confirmationTitle, setConfirmationTitle] = useState("Confirm Action");
  const [confirmationMessage, setConfirmationMessage] =
    useState("Please confirm");
  const [openInformation, setOpenInformation] = useState(false);
  const [informationTitle, setInformationTitle] = useState("Confirm Action");
  const [informationMessage, setInformationMessage] =
    useState("Please confirm");
  const [showLoader, setLoader] = useState(false);
  const [showPageLoader, setPageLoader] = useState(false);
  const [isGridView, toggleView] = useState(true);
  const [isCollapseOpen, setIsCollapseOpen] = useState(false);
  const RoleId = props.userContext.roleId;

  const defaultModalParam: VideoMessageModalProps = {
    showModal: (show) => {
      toggleModalPopup(show);
    },
    publishMessage: (params: any) => {
      publishVideoMessage(params);
    },
    uploadFile: (formData: FormData, callBack: (data) => void) => {
      uploadFileToAWS(formData, callBack);
    },
  };
  const [modalParam, setModalParam] =
    useState<VideoMessageModalProps>(defaultModalParam);

  const handleTitleChange = (event: any) => {
    setMessageTitle(event.target.value);
    setModalParam(defaultModalParam);
  };

  const handleViewCountChange = (event: any) => {
    const value = event.target.value;
    if (value !== "") {
      setviewCount(parseInt(value));
    }
  };

  const handleViewCountRelationChange = (event: any) => {
    setViewCountRelation(event.target.value);
  };

  const handleVideoStatusChange = (event: any) => {
    setselectedVideoStatus(event.target.value);
  };

  const handleGradeChange = (grades: any) => {
    const gradeIds = grades.map((item) => {
      return item.id;
    });
    const classes = props.masterData.classes
      .filter((item) => {
        return gradeIds.includes(item.gradeId);
      })
      .map((item) => {
        return { name: item.value, id: item.key };
      });
    setGrade(grades);
    setMultiSelectClassOptions(classes);
  };

  const handleClassChange = (classes: any) => {
    const classIds = classes.map((item) => {
      return item.id;
    });
    const teachers = props.masterData.teachers
      .filter((item) => {
        return classIds.includes(item.classId);
      })
      .map((item) => {
        return { name: item.value, id: item.key };
      });
    const students = props.masterData.students
      .filter((item) => {
        return classIds.includes(item.classId);
      })
      .map((item) => {
        return { name: item.value, id: item.key };
      });

    setClasses(classes);
    setMultiSelectAuthorOptions(teachers);
    setMultiSelectStudentOptions(students);
  };

  const handleAuthorChange = (authors: any) => {
    setAuthors(authors);
  };

  const handleStudentChange = (students: any) => {
    setStudents(students);
  };

  const handleSearch = () => {
    setLoader(true);
    const userId = props.userContext.userId;
    const schoolId = props.userContext.schoolId;
    const title = messageTitle;
    const videoWatchCount = viewCount;
    const videoWatchCountRelation = selectedViewCountRelation;
    const videoStatus = selectedVideoStatus;
    const startDate = selectedStartDate;
    const endDate = selectedEndDate;
    const gradeIds = selectedGrade.map((item) => {
      return item.id;
    });
    const classIds = selectedClasses.map((item) => {
      return item.id;
    });
    const studentIds = selectedStudents.map((item) => {
      return item.id;
    });
    const teacherIds = selectedAuthors.map((item) => {
      return item.id;
    });
    const param = new VideoMessageFilters(
      userId,
      schoolId,
      title,
      videoWatchCount,
      videoWatchCountRelation,
      videoStatus,
      startDate,
      endDate,
      gradeIds,
      classIds,
      studentIds,
      teacherIds
    );
    getVideoMessage(param)
      ?.then((r) => {
        setVideoMessageData([]);
        setVideoMessageData(r.data);
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
      });
  };

  const handleDelete = (messageId: number) => {
    setDeleteMessageId(messageId);
    showConfirmation("Delete Message", "Are you sure?");
  };

  const handleEdit = (data: VideoMessageModalProps) => {
    data.showModal = showModalPopup;
    data.publishMessage = publishVideoMessage;
    data.uploadFile = uploadFileToAWS;
    setModalParam(data);
    showModalPopup(true);
  };

  const publishVideoMessage = (params) => {
    setPageLoader(true);
    setInformationTitle("Success");
    setInformationMessage("Message published successfully");

    publishData(params)
      ?.then((r) => {
        setPageLoader(false);
        setOpenInformation(true);
      })
      .catch((err) => {
        setPageLoader(false);
      });
  };

  const uploadFileToAWS = (formData: FormData, callBack: (data) => void) => {
    setPageLoader(true);
    uploadFile(formData)
      ?.then((r) => {
        setPageLoader(false);
        callBack(r);
      })
      .catch((err) => {
        setPageLoader(false);
      });
  };

  const showModalPopup = (show: boolean) => {
    toggleModalPopup(show);
  };

  const createNewMessage = () => {
    setModalParam(defaultModalParam);
    toggleModalPopup(true);
  };

  function showConfirmation(title: string, message: string) {
    setOpenConfirmation(true);
    setConfirmationTitle(title);
    setConfirmationMessage(message);
  }

  function confirmationOkClose() {
    setOpenConfirmation(false);
    setLoader(true);
    deleteVideoMessage(props.userContext.userId, deleteMessageId)
      ?.then((r) => {
        handleSearch();
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
      });
  }

  function confirmationCancelClose() {
    setOpenConfirmation(false);
  }

  return (
    <Fragment>
      <Header></Header>
      <div className="px-4 lg:px-8 mt-6 relative">
        {showPageLoader && <Loader className="z-10"></Loader>}
        <div className="flex px-4 py-5">
          <h1 className="text-2xl text-primary-green font-semibold">
            Video Messaging Management
          </h1>
          <button
            onClick={(e) => {
              createNewMessage();
            }}
            className="align-bottom items-center flex space-x-2 items-center block justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none rounded-lg text-sm border border-transparent active:bg-purple-600 focus:shadow-outline-purple text-gray-700 bg-primary-green hover:bg-light-green py-2 px-5 ml-auto"
            type="button"
          >
            <VideoRecorderIcon className="w-5" />
            <span>Create New Video</span>
          </button>
        </div>
        <div className="mt-0 w-full  space-y-2 sm:space-y-0 sm:grid md:grid-cols-2  lg:grid-cols-6 xl:grid-cols-6 sm:gap-3 lg:gap-12 xl:mx-0 rounded bg-gray-100 p-4">
          <div className="md:col-span-4 bg-white px-5 py-4 rounded-lg shadow-xl border border-gray-100">
            <div className="grid grid-cols-1 gap-y-6 sm:gap-x-8 lg:gap-12">
              <div>
                <div className="grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-8">
                  <div className="col-span-2">
                    <label className="block text-sm font-medium text-gray-900">
                      Message Title
                    </label>
                    <div className="mt-1">
                      <input
                        className="border py-2 px-4 block w-full shadow-sm text-gray-900 focus:outline-none focus:border-gray-700 border-gray-300 rounded-md"
                        type="text"
                        onChange={(e) => {
                          handleTitleChange(e);
                        }}
                      />
                    </div>
                  </div>
                  <div className="text-left mt-5 pt-3">
                    <ExpandIcon
                      onClick={(e) => {
                        setIsCollapseOpen(!isCollapseOpen);
                      }}
                      className="h-6 ml-auto opacity-70 hover:opacity-100 hover:shadow-xl shadow-lg cursor-pointer"
                      type="button"
                    ></ExpandIcon>
                  </div>
                </div>

                <Collapse isOpened={isCollapseOpen}>
                  <div className="grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-8 mt-5">
                    <div>
                      <label className="block text-sm font-medium text-gray-900">
                        Start Date
                      </label>
                      <div className="mt-1 relative v-messaging-date">
                        <DatePicker
                          className="py-2 px-4 block  shadow-sm text-gray-900 focus:outline-none focus:border-gray-700 border border-gray-300 rounded-md w-full"
                          selected={selectedStartDate}
                          onChange={(date) => setStartDate(date)}
                        />
                        <DateIcon className="fill-current w-4 mr-1 opacity-80 absolute right-2 top-3" />
                      </div>
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-900">
                        End Date
                      </label>
                      <div className="mt-1 relative v-messaging-date">
                        <DatePicker
                          className="py-2 px-4 block w-full shadow-sm text-gray-900 focus:outline-none focus:border-gray-700 border border-gray-300 rounded-md"
                          selected={selectedEndDate}
                          onChange={(date) => setEndDate(date)}
                        />
                        <DateIcon className="fill-current w-4 mr-1 opacity-80 absolute right-2 top-3" />
                      </div>
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-900 mb-1">
                        Grade
                      </label>
                      <div className="flex">
                        <MultiselectComponent
                          placeholder={"Select Grade"}
                          options={props.masterData.grades
                            .filter((item) => {
                              return item.key !== 0;
                            })
                            .map((item) => {
                              return { name: item.value, id: item.key };
                            })}
                          displayValue="name"
                          handleOnSelect={(selectedList, selectedItem) => {
                            handleGradeChange(selectedList);
                          }}
                          handleOnRemove={(selectedList, removedItem) => {
                            handleGradeChange(selectedList);
                          }}
                          selectedOptions={selectedGrade}
                          isValid={true}
                        ></MultiselectComponent>
                      </div>
                    </div>
                  </div>

                  <div className="grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-8 mt-5">
                    <div>
                      <label className="block text-sm font-medium text-gray-900">
                        View Count
                      </label>
                      <div className="mt-1">
                        <input
                          type="text"
                          onChange={(e) => {
                            handleViewCountChange(e);
                          }}
                          className="py-2 px-4 block w-full shadow-sm text-gray-900 focus:outline-none focus:border-gray-700 border border-gray-300 rounded-md"
                        />
                      </div>
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-900 mb-1">
                        View Count Relation
                      </label>
                      <div className="flex">
                        <select
                          value={selectedViewCountRelation}
                          onChange={(e) => {
                            handleViewCountRelationChange(e);
                          }}
                          className="py-2.5 px-4 w-full dark:text-gray-300 focus:border-purple-400 dark:border-gray-600 dark:bg-gray-700 focus:shadow-outline-purple dark:focus:shadow-outline-gray dark:focus:border-gray-600 form-select block text-sm focus:outline-none border border-gray-400 sm:focus:border-gray-700 border-opacity-50 focus:shadow-outline-green form-select leading-5 p-1 rounded-md"
                        >
                          <option value="1">Greater Than</option>
                          <option value="0">Equals To</option>
                          <option value="-1">Less Than</option>
                        </select>
                      </div>
                    </div>
                    <div className="">
                      <label className="block text-sm font-medium text-gray-900 mb-1">
                        Class
                      </label>
                      <div className="flex">
                        <MultiselectComponent
                          placeholder={"Select Class"}
                          options={multiSelectClassOptions}
                          displayValue="name"
                          handleOnSelect={(selectedList, selectedItem) => {
                            handleClassChange(selectedList);
                          }}
                          handleOnRemove={(selectedList, removedItem) => {
                            handleClassChange(selectedList);
                          }}
                          selectedOptions={selectedClasses}
                          isValid={true}
                          showCheckbox={true}
                        ></MultiselectComponent>
                      </div>
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-900 mb-1">
                        Student
                      </label>
                      <div className="flex">
                        <MultiselectComponent
                          placeholder={"Select Student"}
                          options={multiSelectStudentOptions}
                          displayValue="name"
                          handleOnSelect={(selectedList, selectedItem) => {
                            handleStudentChange(selectedList);
                          }}
                          handleOnRemove={(selectedList, removedItem) => {
                            handleStudentChange(selectedList);
                          }}
                          selectedOptions={selectedStudents}
                          isValid={true}
                        ></MultiselectComponent>
                      </div>
                    </div>
                    {RoleId === Constants.UserRoleId.SchoolAdmin && (
                      <div>
                        <label className="block text-sm font-medium text-gray-900 mb-1">
                          Author
                        </label>
                        <div className="flex">
                          <MultiselectComponent
                            placeholder={"Select Author"}
                            options={multiSelectAuthorOptions}
                            displayValue="name"
                            handleOnSelect={(selectedList, selectedItem) => {
                              handleAuthorChange(selectedList);
                            }}
                            handleOnRemove={(selectedList, removedItem) => {
                              handleAuthorChange(selectedList);
                            }}
                            selectedOptions={selectedAuthors}
                            isValid={true}
                          ></MultiselectComponent>
                        </div>
                      </div>
                    )}
                  </div>
                </Collapse>

                <div className="text-left mt-5">
                  <button
                    onClick={(e) => {
                      handleSearch();
                    }}
                    className="align-bottom items-center block justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none rounded-lg text-sm border border-transparent active:bg-purple-600 focus:shadow-outline-purple text-gray-700 bg-primary-green hover:bg-light-green py-2 px-5 ml-auto"
                    type="button"
                  >
                    Search
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="lg:border-l col-span-2 max-w-xs">
            <div className="relative  space-y-4 pl-0 lg:pl-12">
              <label className="relative p-4 flex flex-col cursor-pointer md:pl-4 md:pr-6 bg-white rounded-lg border-2 hover:shadow-lg transition-all">
                <div className="flex items-center text-sm w-full">
                  <input
                    type="radio"
                    x-model="value"
                    name="video-status"
                    value="1"
                    onChange={(e) => {
                      handleVideoStatusChange(e);
                    }}
                    className="h-4 w-4 text-primary-green border-gray-300 focus:ring-primary-green"
                    aria-labelledby="video-status-label"
                  />
                  <span
                    id="video-status-label"
                    className="text-gray-900 ml-3 font-medium"
                  >
                    Active Videos
                  </span>
                </div>
              </label>
              <label className="relative p-4 flex flex-col cursor-pointer md:pl-4 md:pr-6 bg-white rounded-lg border-2 hover:shadow-lg transition-all">
                <div className="flex items-center text-sm w-full">
                  <input
                    type="radio"
                    x-model="value"
                    name="video-status"
                    value="0"
                    onChange={(e) => {
                      handleVideoStatusChange(e);
                    }}
                    className="h-4 w-4 text-indigo-600 border-gray-300 focus:ring-indigo-500"
                    aria-labelledby="video-status-label"
                  />
                  <span
                    id="video-status-label"
                    className="text-gray-900 ml-3 font-medium"
                  >
                    Inactive Videos
                  </span>
                </div>
              </label>
              <label className="relative p-4 flex flex-col cursor-pointer md:pl-4 md:pr-6 bg-white rounded-lg border-2 hover:shadow-lg transition-all">
                <div className="flex items-center text-sm w-full">
                  <input
                    type="radio"
                    x-model="value"
                    name="video-status"
                    value="-1"
                    onChange={(e) => {
                      handleVideoStatusChange(e);
                    }}
                    className="h-4 w-4 text-indigo-600 border-gray-300 focus:ring-indigo-500"
                    aria-labelledby="video-status-label"
                  />
                  <span
                    id="video-status-label"
                    className="text-gray-900 ml-3 font-medium"
                  >
                    All
                  </span>
                </div>
              </label>
            </div>
          </div>
        </div>

        <div className="mx-auto py-8 relative">
          {showLoader && <Loader className="z-10"></Loader>}
          <div className="ml-auto  w-full text-right mb-4">
            <div
              onClick={() => {
                toggleView(!isGridView);
              }}
              className="border rounded-lg py-2 px-4 inline-flex shadow-lg hover:bg-gray-100 hover:shadow-2xl hover:border-black transition ease-in-out duration-500 cursor-pointer "
            >
              {isGridView && (
                <Fragment>
                  <ListIcon className="w-5 " />
                  <span className="ml-1">List</span>
                </Fragment>
              )}
              {!isGridView && (
                <Fragment>
                  <GridIcon className="w-5 " />
                  <span className="ml-1">Card</span>
                </Fragment>
              )}
            </div>
          </div>

          {isGridView && (
            <VideoMessageCardView
              handleEdit={handleEdit}
              handleDelete={handleDelete}
              videoMessageData={videoMessageData}
            />
          )}
          {!isGridView && (
            <VideoMessageListView
              handleEdit={handleEdit}
              handleDelete={handleDelete}
              videoMessageData={videoMessageData}
            />
          )}
        </div>
        {showModal && <VideoMessageModal {...modalParam} />}
        <ConfirmationDialog
          open={openConfirmation}
          confirmationTitle={confirmationTitle}
          confirmationMessage={confirmationMessage}
          onCancelClick={confirmationCancelClose}
          onOkClick={confirmationOkClose}
        ></ConfirmationDialog>
        <InformationDialog
          open={openInformation}
          informationTitle={informationTitle}
          informationMessage={informationMessage}
          onOkClick={() => {
            setOpenInformation(false);
          }}
        ></InformationDialog>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state: any) => {
  return {
    masterData: state.masterData,
    userContext: state.userContext,
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(VideoMessageManagement);

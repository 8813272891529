import moment from "moment";
import { useEffect, useState } from "react";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useTranslation } from "react-i18next";
import { GoldCoinIcon } from "../../../../../../assets/icons";
import Constant from "../../../../../../utils/constant/constant";
import { getScoreLabel } from "../../../../../../utils/scoreHelper";
function OverviewResult({ data, redirectToReport }) {
  const { t } = useTranslation();
  const [assignmentOverviewResult, setAssignmentOverviewResult] =
    useState<any>();
  const getRingColor = (value) => {
    let ringColor = "";
    switch (true) {
      case value < 63:
        ringColor = "E53F71";
        break;
      case value >= 63 && value < 80:
        ringColor = "#FFDB3A";
        break;
      case value >= 80:
        ringColor = "#B2D136";
        break;
      default:
        ringColor = "bg-gray-700";
        break;
    }
    return ringColor;
  };
  function overview() {
    if (data) {
      const overviewResultInput = {
        results_header: {
          assignment: {
            result: {
              retry_percent:
                data.retryScore === null
                  ? null
                  : Math.round(
                      (Number(data.totalScoreWithRetry) /
                        Number(data.totalAttempted)) *
                        100
                    ),
              percent:
                data.assignmentActivityTypeId ===
                Constant.AssignmentActivityTypeEnum.CONSTRUCTEDRESPONSE
                  ? data.totalScore
                  : Math.round(
                      (Number(data.totalScore) / Number(data.totalAttempted)) *
                        100
                    ),
              totalAttempted: data.totalAttempted,
              totalScore: data.totalScore,
              retryScore: data.retryScore,
              totalCorrect:
                data.assignmentActivityTypeId ===
                Constant.AssignmentActivityTypeEnum.CONSTRUCTEDRESPONSE
                  ? data.totalAttempted
                  : data.totalScore,
              unmarked: data.unmarked,
              totalScoreWithRetry: data.totalScoreWithRetry,
            },
          },
          elapsed_time: data.sessionDuration,
          date_completed: data.dateCompleted,
          test: {
            name: data.subjectName,
          },
          teacher: {
            first: data.teacherFirstName,
            last: data.teacherLastName,
          },
          student: {
            first: data.studentFirstName ? data.studentFirstName : "",
            last: data.studentLastName ? data.studentLastName : "",
          },
          class: {
            name: data.class,
          },
          earnedStar: data.earnedStar,
          gameScore: data.gameScore ?? null,
        },
      };
      setAssignmentOverviewResult(overviewResultInput);
    }
  }
  useEffect(() => {
    overview();    
  }, []);

  function needsGrading(): boolean {
    return (
      assignmentOverviewResult?.results_header?.assignment?.result?.unmarked
    );
  }

  return (
    <>
      {assignmentOverviewResult && (
        <div className="flex-none md:w-60 lg:w-64 h-auto bg-blue-400 rounded-md shadow-md xl:mr-12 md:mr-4 lg:mr-6 shadow-lg">
          <div className="text-md text-white text-center px-12 md:px-5 ">
            <h2 className="text-lg text-white font-medium uppercase text-center my-4">
              {t("AssignmentResult.Score")}
            </h2>
            {needsGrading() ? (
              <div
                style={{
                  width: 160,
                  height: 160,
                  display: "inline-grid",
                  fontSize: "17px;",
                }}
              >
                <CircularProgressbar
                  value={0}
                  text={`${
                    assignmentOverviewResult?.results_header?.assignment?.result
                      ?.unmarked
                      ? t("AssignmentResult.Pending")
                      : assignmentOverviewResult?.results_header?.assignment
                          ?.result?.percent
                  }`}
                  styles={buildStyles({
                    pathColor: `${getRingColor(
                      assignmentOverviewResult?.results_header?.assignment
                        ?.result?.percent
                    )}`,
                    textColor: "#374151",
                  })}
                />
              </div>
            ) : (
              <div
                style={{
                  width: 140,
                  height: 140,
                  display: "inline-grid",
                  position: "relative",
                }}
              >
                <CircularProgressbar
                  value={assignmentOverviewResult?.results_header?.assignment?.result
                    ?.percent}
                  text={
                    assignmentOverviewResult?.results_header?.assignment?.result
                      ?.unmarked
                      ? t("AssignmentResult.Pending")
                      : `${assignmentOverviewResult?.results_header?.assignment?.result?.percent}%`
                  }
                  styles={buildStyles({
                    pathColor: `${getRingColor(
                      assignmentOverviewResult?.results_header?.assignment
                        ?.result?.percent
                    )}`,
                    textColor: "#374151",
                  })}
                  className="circular-bar-pending-size"
                />
                <span className="absolute top-1/2 left-1/2 transform -translate-x-2/4 -translate-y-2/4 pt-11 text-white">
                  {
                    data.assignmentActivityTypeId !==
                    Constant.AssignmentActivityTypeEnum.CONSTRUCTEDRESPONSE &&
                    !needsGrading() && (
                    <>
                      (
                      {getScoreLabel(
                        assignmentOverviewResult?.results_header?.assignment
                          ?.result?.totalCorrect
                      )}
                      /
                      {
                        assignmentOverviewResult?.results_header?.assignment
                          ?.result?.totalAttempted
                      }
                      )
                    </>
                  )}
                </span>
              </div>
            )}
          </div>
          {<div className="w-full text-lg text-white text-center mb-4"></div>}

          <div className="hidden flex flex-none flex-col px-4 lg:px-7 py-4 mx-4 bg-white rounded-md shadow-md mb-4">
            <div className="flex flex-col w-full items-center ">
              <div className="capitalize font-myriad font-semibold text-lg text-gray-700 pb-2">
                {t("AssignmentResult.TokensEarned")}
              </div>
              <div className="relative w-24 inline-block pb-10 ">
                <span className="inline-block absolute left-0">
                  <GoldCoinIcon className="w-9 h-9"></GoldCoinIcon>
                </span>
                <span className="inline-block absolute left-6">
                  <GoldCoinIcon className="w-9 h-9"></GoldCoinIcon>
                </span>
                <span className="inline-block absolute left-12">
                  <GoldCoinIcon className="w-9 h-9"></GoldCoinIcon>
                </span>
              </div>
            </div>
          </div>
          <div className="mt-10">
            {assignmentOverviewResult?.results_header?.assignment?.result
              ?.retryScore !== null && !needsGrading() && (
              <div className="flex flex-col flex-none mx-7 text-white border-white border-b border-opacity-25 pb-2 mb-3">
                <div className="text-xs text-white capitalize">
                  {t("AssignmentResult.RetryScore")}
                </div>
                <div className="text-white font-roboto font-bold">
                  {
                    assignmentOverviewResult?.results_header?.assignment?.result
                      ?.retry_percent
                  }
                  %{" "}
                  <>
                    (
                    {getScoreLabel(
                      assignmentOverviewResult?.results_header?.assignment
                        ?.result?.totalScoreWithRetry
                    )}
                    /
                    {
                      assignmentOverviewResult?.results_header?.assignment
                        ?.result?.totalAttempted
                    }
                    )
                  </>
                </div>
              </div>
            )}
            <div className="flex flex-col flex-none mx-7 text-white border-white border-b border-opacity-25 pb-2 mb-2">
              <div className="text-xs text-white capitalize">
                {t("AssignmentResult.DateCompleted")}
              </div>
              {assignmentOverviewResult?.results_header?.date_completed !==
                null && (
                <div className="text-white font-roboto font-bold capitalize ">
                  {moment(
                    assignmentOverviewResult?.results_header?.date_completed
                  ).format("MM/DD/YYYY")}
                </div>
              )}
              {assignmentOverviewResult?.results_header?.date_completed ===
                null && (
                <div className="text-white font-roboto font-bold capitalize mt-5"></div>
              )}
            </div>
            <div className="flex flex-col flex-none mx-7 text-white border-white border-b border-opacity-25 pb-2 mb-3">
              <div className="text-xs text-white capitalize">
                {t("AssignmentResult.ElapsedTime")}
              </div>
              <div className="text-white font-roboto font-bold">
                {moment()
                  .startOf("day")
                  .add(
                    assignmentOverviewResult?.results_header?.elapsed_time,
                    "seconds"
                  )
                  .format("HH:mm:ss")}
              </div>
            </div>
            <div className="flex flex-col flex-none mx-7 text-white border-white border-b border-opacity-25 pb-2 mb-3">
              <div className="text-xs text-white capitalize">
                {t("StudyPlanResult.Subject")}
              </div>
              <div className="text-white text-md font-roboto font-bold capitalize">
                {assignmentOverviewResult?.results_header?.test
                  ? `${assignmentOverviewResult?.results_header?.test.name}`
                  : "-"}{" "}
              </div>
            </div>
            {assignmentOverviewResult?.results_header?.class.name && (
              <>
                <div className="flex flex-col flex-none mx-7 text-white border-white border-b border-opacity-25 pb-2 mb-3">
                  <div className="text-xs text-white capitalize">
                    {t("StudyPlanResult.Teacher")}
                  </div>
                  <div className="text-white text-md font-roboto font-bold capitalize">
                    {Object.keys(
                      assignmentOverviewResult?.results_header?.teacher
                    ).length > 0 &&
                      `${assignmentOverviewResult?.results_header?.teacher?.first} ${assignmentOverviewResult?.results_header?.teacher?.last}`}
                    {Object.keys(
                      assignmentOverviewResult?.results_header?.teacher
                    ).length === 0 && (
                      <div className="text-xs font-semibold capitalize cursor-pointer text-white">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-5 w-5 stroke-current text-white inline"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                          />
                        </svg>
                        {""} {t("AssignmentResult.AddTeacher")}
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex flex-col flex-none mx-7 text-white border-white border-b border-opacity-25 pb-2 mb-3">
                  <div className="text-xs text-white capitalize">
                    {t("StudyPlanResult.Class")}
                  </div>
                  <div className="text-white text-md font-roboto font-bold capitalize">
                    {assignmentOverviewResult?.results_header?.class &&
                    Object.keys(assignmentOverviewResult?.results_header?.class)
                      .length > 0
                      ? `${assignmentOverviewResult?.results_header?.class.name}`
                      : "-"}{" "}
                  </div>
                </div>
              </>
            )}
            <div className="flex flex-col flex-none mx-7 text-white border-white border-b border-opacity-25 pb-2 mb-3">
              <div className="text-xs text-white capitalize">
                {" "}
                {t("StudyPlanResult.StudentName")}
              </div>
              <div className="text-white text-md font-roboto font-bold capitalize">
                {Object.keys(assignmentOverviewResult?.results_header?.student)
                  .length > 0 &&
                  `${assignmentOverviewResult?.results_header?.student?.first} ${assignmentOverviewResult?.results_header?.student?.last}`}
              </div>
            </div>
            {data?.isDiagnostic && (
              <div className="flex flex-col flex-none mx-7 text-white border-white border-b border-opacity-25 pb-2 mb-3">
                <div className="text-xs text-white capitalize">
                  {t("Diagnostics.DiagnosticResults")}
                </div>
                <div
                  className="text-white text-md font-roboto underline cursor-pointer hover:font-bold"
                  onClick={() => {
                    redirectToReport();
                  }}
                >
                  {t("Diagnostics.StandardsEfficiencyReport")}
                </div>
              </div>
            )}
            {assignmentOverviewResult?.results_header?.gameScore && (
              <div className="flex flex-col flex-none mx-7 text-white border-white border-b border-opacity-25 pb-2 mb-3">
                <div className="text-xs text-light-teal capitalize">
                  {t("StudyPlanResult.GameScore")}
                </div>
                <div className="text-light-teal text-md font-roboto font-bold capitalize">
                  {assignmentOverviewResult?.results_header?.gameScore}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}
export default OverviewResult;

export class TagColor {
  constructor(
    public id: number,
    public code: string,
    public isTextWhite: boolean
  ) {}

  static _hexToRgb(hexColor: string) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hexColor);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null;
  }

  static _isDarkCache = {};

  static isDark(hexColor?: string) {
    if (!hexColor) return false;

    if (this._isDarkCache[hexColor] === undefined) {
      const rgb = this._hexToRgb(hexColor);
      if (!rgb) return false;

      const lightness = rgb.r * 0.299 + rgb.g * 0.587 + rgb.b * 0.114;
      this._isDarkCache[hexColor] = lightness < 180;
    }

    return this._isDarkCache[hexColor];
  }

  static getTextColor(hexColor?: string) {
    return this.isDark(hexColor) ? "text-white" : "";
  }
}

const getColors: Array<TagColor> = [
  { id: 1, code: "#F3F4F6", isTextWhite: false },
  { id: 2, code: "#E5E7EB", isTextWhite: false },
  { id: 3, code: "#D1D5DB", isTextWhite: false },
  { id: 4, code: "#9CA3AF", isTextWhite: false },
  { id: 5, code: "#6B7280", isTextWhite: true },
  { id: 6, code: "#4B5563", isTextWhite: true },
  { id: 7, code: "#374151", isTextWhite: true },
  { id: 8, code: "#1F2937", isTextWhite: true },
  { id: 9, code: "#111827", isTextWhite: true },

  { id: 10, code: "#FEE2E2", isTextWhite: false },
  { id: 11, code: "#FECACA", isTextWhite: false },
  { id: 12, code: "#FCA5A5", isTextWhite: false },
  { id: 13, code: "#F87171", isTextWhite: false },
  { id: 14, code: "#EF4444", isTextWhite: false },
  { id: 15, code: "#DC2626", isTextWhite: true },
  { id: 16, code: "#B91C1C", isTextWhite: true },
  { id: 17, code: "#991B1B", isTextWhite: true },
  { id: 18, code: "#7F1D1D", isTextWhite: true },

  { id: 19, code: "#FEF3C7", isTextWhite: false },
  { id: 20, code: "#FDE68A", isTextWhite: false },
  { id: 21, code: "#FCD34D", isTextWhite: false },
  { id: 22, code: "#FBBF24", isTextWhite: false },
  { id: 23, code: "#F59E0B", isTextWhite: false },
  { id: 24, code: "#D97706", isTextWhite: true },
  { id: 25, code: "#B45309", isTextWhite: true },
  { id: 26, code: "#92400E", isTextWhite: true },
  { id: 27, code: "#78350F", isTextWhite: true },

  { id: 28, code: "#D1FAE5", isTextWhite: false },
  { id: 29, code: "#A7F3D0", isTextWhite: false },
  { id: 30, code: "#6EE7B7", isTextWhite: false },
  { id: 31, code: "#34D399", isTextWhite: false },
  { id: 32, code: "#10B981", isTextWhite: false },
  { id: 33, code: "#059669", isTextWhite: true },
  { id: 34, code: "#047857", isTextWhite: true },
  { id: 35, code: "#065F46", isTextWhite: true },
  { id: 36, code: "#064E3B", isTextWhite: true },

  { id: 37, code: "#DBEAFE", isTextWhite: false },
  { id: 38, code: "#BFDBFE", isTextWhite: false },
  { id: 39, code: "#93C5FD", isTextWhite: false },
  { id: 40, code: "#60A5FA", isTextWhite: false },
  { id: 41, code: "#3B82F6", isTextWhite: false },
  { id: 42, code: "#2563EB", isTextWhite: true },
  { id: 43, code: "#1D4ED8", isTextWhite: true },
  { id: 44, code: "#1E40AF", isTextWhite: true },
  { id: 45, code: "#1E3A8A", isTextWhite: true },

  { id: 46, code: "#E0E7FF", isTextWhite: false },
  { id: 47, code: "#C7D2FE", isTextWhite: false },
  { id: 48, code: "#A5B4FC", isTextWhite: false },
  { id: 49, code: "#818CF8", isTextWhite: false },
  { id: 50, code: "#6366F1", isTextWhite: false },
  { id: 51, code: "#4F46E5", isTextWhite: true },
  { id: 52, code: "#4338CA", isTextWhite: true },
  { id: 53, code: "#3730A3", isTextWhite: true },
  { id: 54, code: "#312E81", isTextWhite: true },

  { id: 55, code: "#EDE9FE", isTextWhite: false },
  { id: 56, code: "#DDD6FE", isTextWhite: false },
  { id: 57, code: "#C4B5FD", isTextWhite: false },
  { id: 58, code: "#A78BFA", isTextWhite: false },
  { id: 59, code: "#8B5CF6", isTextWhite: false },
  { id: 60, code: "#7C3AED", isTextWhite: true },
  { id: 61, code: "#6D28D9", isTextWhite: true },
  { id: 62, code: "#5B21B6", isTextWhite: true },
  { id: 63, code: "#4C1D95", isTextWhite: true },

  { id: 64, code: "#FCE7F3", isTextWhite: false },
  { id: 65, code: "#FBCFE8", isTextWhite: false },
  { id: 66, code: "#F9A8D4", isTextWhite: false },
  { id: 67, code: "#F472B6", isTextWhite: false },
  { id: 68, code: "#EC4899", isTextWhite: true },
  { id: 69, code: "#DB2777", isTextWhite: true },
  { id: 70, code: "#BE185D", isTextWhite: true },
  { id: 71, code: "#9D174D", isTextWhite: true },
  { id: 72, code: "#831843", isTextWhite: true },
];

export default getColors;

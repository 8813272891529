import bubbleLeft from "../../../../../assets/images/thinkorswim/bubbleLeft.png";
import bubbleRight from "../../../../../assets/images/thinkorswim/bubbleRight.png"

interface ITextBubbleProps {
  text: string,
  textSizeCSSClass?: string,
  isBubblePointingToLeft?: boolean
}

function TextBubble(props: ITextBubbleProps) {
  return (
    <div
      className="relative w-full overflow-hidden"
    >
      <div className="absolute top-[52%] left-[50%] w-full -translate-y-1/2 -translate-x-1/2">
        <div className={`relative overflow-hidden w-[70%] ${props.isBubblePointingToLeft ? "left-[23%]" : "left-[9%]"}`}>
          <div 
            className={`${props.textSizeCSSClass || "text-[1.7vw]"} text-black text-center font-medium`}
          >
            {props.text}
          </div>
        </div>
      </div>
      <img 
        className="w-full"
        src={props.isBubblePointingToLeft ? bubbleLeft : bubbleRight}
        alt="Bubble"
      />
    </div>
  )
}

export default TextBubble

import { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { AxiosHelper } from "../../../../../../environment/axiosConfig";
import { IAdaptiveDataFilter } from "../../../../../../model/interface/adaptiveDataFilter";
import { UserContextState } from "../../../../../../redux/actions/userContextAction";
import APIConstant from "../../../../../../utils/constant/apiConstant";
import Constant from "../../../../../../utils/constant/constant";
import QuestionAnswer from "../../../shared/questionAnswer";
import RouteConstant from "../../../../../../utils/constant/routeConstant";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { getCalculatorBySubjectId } from "../../../../../../api/student/studentSubjectApi";
import { GetIsQuestionRetryAllowed, GetShowExplanation } from "../../../../../../utils/helper";
import BeginActivity from "../../../shared/beginActivity";
import constant from "../../../../../../utils/constant/constant";
import { IBreadcrumb } from "../../../../../../model/common/breadcrumb";
import { useTranslation } from "react-i18next";
import Breadcrumb from "../../../middleSchool/breadcrumb";
import Profile from "../../../../../../model/users/profile";
import { getAdaptiveData } from "../../../../../../api/student/liftOff/adaptiveData";
import {
  getLiftOffDiagnosticLastSession,
  getLiftOffDiagnosticTestStandards,
} from "../../../../../../api/student/liftOff/diagnosticApi";
import QuestionAlreadyAttemptedPopup from "../../../shared/popups/questionAlreadyAttemptedPopup";
import { IsActivitySessionQuestionAttempted } from "../../../../../../api/student/activitySession/activitySessionQuestions";
interface ILiftOffDiagnosticActivity {
  userContext: UserContextState;
  profile: Profile;
  match: any;
}

function LiftOffDiagnosticActivity(props: ILiftOffDiagnosticActivity) {
  const { t } = useTranslation();
  const activityStatus = atob(props.match.params.activityStatus);
  const activityHeaderId = parseInt(atob(props.match.params.activityHeaderId));
  const activityHeader = atob(props.match.params.activityHeader);
  const subjectId = parseInt(atob(props.match.params.subjectId));
  const subjectName = atob(props.match.params.subjectName);
  const [calculatorReferenceId, setCalculatorReferenceId] = useState<string>();
  const [showExpalanation, setShowExplanation] = useState<boolean>(false);
  const [liftOffDiagnosticActivity, setLliftOffDiagnosticActivity] =
    useState<any>();
  const [beginActivityModal, setBeginActivityModal] = useState<any>({
    open: false,
    data: undefined,
  });
  let adaptiveFilter = {
    headerId: activityHeaderId,
    gradeId: props.userContext.gradeId,
  } as IAdaptiveDataFilter;
  const [adaptiveData, setAdaptiveData] = useState<any>();
  const [showQuestionAlreadyAttempted, setShowQuestionAlreadyAttempted] = useState<boolean>(false);
  const [isQuestionRetry, setQuestionRetry] = useState<boolean>(false);

  const history = useHistory();
  const location = useLocation();
  const redirectUrl =
    location.pathname.toLowerCase().indexOf("kindergarten") > -1
      ? RouteConstant.LiftOff.Kindergarten.DiagnosticResult
      : location.pathname.toLowerCase().indexOf("elementary") > -1
      ? RouteConstant.LiftOff.Elementary.DiagnosticResult
      : location.pathname.toLowerCase().indexOf("middleschool") > -1
      ? RouteConstant.LiftOff.MiddleSchool.DiagnosticResult
      : "";
  const exitUrl =
    location.pathname.toLowerCase().indexOf("kindergarten") > -1
      ? RouteConstant.LiftOff.Kindergarten.Home
      : location.pathname.toLowerCase().indexOf("elementary") > -1
      ? RouteConstant.LiftOff.Elementary.Home
      : location.pathname.toLowerCase().indexOf("middleschool") > -1
      ? RouteConstant.LiftOff.MiddleSchool.Home
      : "";
  const [standardIds, setStandardIds] = useState<Array<number>>();

  useEffect(() => {
    if (subjectId > 0) {
      getCalculatorBySubjectId(subjectId).then((res) => {
        if (res.data) setCalculatorReferenceId(res.data);
      });
    }

    setQuestionRetry(
      GetIsQuestionRetryAllowed(
        props.userContext?.gradeId,
        props.userContext?.stateId,
        Constant.ActivitySubTypes.LIFTOFFDIAGNOSTICS,
        undefined
      )
    );

    getAdaptiveData(
      activityHeaderId,
      props.userContext.gradeId,
      props.profile.loLanguageId === constant.Languages.Spanish ? "es" : "en"
    ).then((res) => {
      setAdaptiveData(res.data);
    });

    setShowExplanation(
      GetShowExplanation(
        props.userContext?.gradeId,
        props.userContext?.stateId,
        Constant.ActivitySubTypes.LIFTOFFDIAGNOSTICS,
        undefined,
        undefined,
        subjectName
      )
    );
  }, []);

  const breadcrumbItems = () => {
    const homeUrl =
      location.pathname.toLowerCase().indexOf("kindergarten") > -1
        ? RouteConstant.LiftOff.Kindergarten.Home
        : location.pathname.toLowerCase().indexOf("elementary") > -1
        ? RouteConstant.LiftOff.Elementary.Home
        : location.pathname.toLowerCase().indexOf("middleschool") > -1
        ? RouteConstant.LiftOff.MiddleSchool.Home
        : "";

    const items: Array<IBreadcrumb> = [
      {
        name: t("Breadcrumb.Home"),
        url: homeUrl,
        textColor: "text-gray-500",
      },
      {
        name: t("Breadcrumb.LiftOffDiagnostic"),
        textColor: "text-gray-500",
        url: "",
      },
    ];

    return items;
  };

  function updateStudentLiftOffDiagnosticAssignment(
    userId?: number,
    liftOffHeaderId?: number,
    lernositySessionId?: string,
    liftOffDiagnosticTestStatusId?: number,
    activitySessionId?: number
  ) {
    return AxiosHelper.axiosInstance().put(
      APIConstant.StudentLiftOffAssignment_API.Update,
      {
        userId: userId,
        liftOffHeaderId: liftOffHeaderId,
        lernositySessionId: lernositySessionId,
        liftOffDiagnosticTestStatusId: liftOffDiagnosticTestStatusId,
        activitySessionId: activitySessionId,
      }
    );
  }

  const handleOnSubmit = () => {
    updateStudentLiftOffDiagnosticAssignment(
      props.userContext.userId,
      activityHeaderId,
      liftOffDiagnosticActivity.lernositySessionId,
      Constant.LiftOffDiagnosticAssignmentStatusType.Completed,
      liftOffDiagnosticActivity.activitySessionId
    ).then((res) => {
      history.push(
        redirectUrl.replace(
          ":liftOffHeaderId",
          encodeURI(btoa(activityHeaderId.toString()))
        )
      );
    });
  };

  useEffect(() => {
    initilizeActivity(false);
  }, []);

  function initilizeActivity(isBegin: boolean) {
    getLiftOffDiagnosticLastSession(
      props.userContext.userId,
      activityHeaderId,
      props.profile.loLanguageId !== constant.Languages.Toggle
        ? props.profile.loLanguageId
        : 0
    ).then((assignment) => {
      var data = assignment.data;
      setBeginActivityModal({ open: true, data });
    });
  }

  function OnBeginActivity(data) {
    createStudentLiftOffDiagnosticAssignment(true, data).then((res) => {
      if (res.data.activityId) {
        getLiftOffDiagnosticTestStandards(res.data.activityId).then(
          (standards) => {
            if (standards.data) {
              setStandardIds(standards.data);
            }
          }
        );
      }
      setLliftOffDiagnosticActivity({
        ...res.data,
        languageId: data.languageId,
      });
    });
  }

  function createStudentLiftOffDiagnosticAssignment(
    isBegin: boolean,
    data?: any
  ) {
    return AxiosHelper.axiosInstance().post(
      APIConstant.StudentLiftOffAssignment_API.Add,
      {
        userId: props.userContext.userId,
        liftOffHeaderId: activityHeaderId,
        isBegin: isBegin,
        classId: data?.classId,
        teacherId: data?.teacherId,
        languageId: data?.languageId,
        gradeId: props.userContext?.gradeId,
        subjectId: subjectId,
      }
    );
  }

  function validateDiagnosticActivity(questionId: any) {
    if (liftOffDiagnosticActivity && questionId && !isQuestionRetry) {
      IsActivitySessionQuestionAttempted(
        liftOffDiagnosticActivity.activitySessionId,
        questionId
      ).then(d => {
        if(d.data === true) setShowQuestionAlreadyAttempted(true);
      })
    }
  }

  return (
    <Fragment>
      <div className="relative w-full p-5">
        <div className="flex px-2 pb-3 pt-2 border-b">
          <Breadcrumb items={breadcrumbItems()} />
        </div>
        <div className="text-lg flex justify-between gap-2 h-auto sm:h-10 items-center">
          <h1 className="text-gray-600 font-medium flex justify-start">
            {t("Breadcrumb.LiftOffDiagnostic")}
          </h1>
        </div>
        {liftOffDiagnosticActivity !== undefined &&
          liftOffDiagnosticActivity.lernositySessionId.length > 0 && (
            <>
              <QuestionAnswer
                activityType={Constant.ActivityType.LIFTOFFDIAGNOSTIC}
                activityId={liftOffDiagnosticActivity.activityId}
                teacherActivityId={
                  liftOffDiagnosticActivity.learnositySessionGroupId
                }
                showActivity={true}
                showQuestions={false}
                questionIds={[]}
                hasSections={false}
                hasItemBranching={true}
                isAlienExplanation={false}
                isGameChoosen={false}
                studentId={props.userContext.userId.toString()}
                resumeActivity={activityStatus === "Inprogress" ? true : false}
                title=""
                sessionId={liftOffDiagnosticActivity.lernositySessionId}
                studentType="EL"
                adaptiveFilter={adaptiveFilter}
                OnSubmit={handleOnSubmit}
                onExitRedirectUrl={exitUrl}
                activitySubType={Constant.ActivitySubTypes.LIFTOFFDIAGNOSTICS}
                calculatorReferenceId={calculatorReferenceId}
                questionRetry={false}
                showExplanation={showExpalanation}
                activitySessionId={liftOffDiagnosticActivity.activitySessionId}
                language={
                  liftOffDiagnosticActivity.languageId ===
                  constant.Languages.Spanish
                    ? "es"
                    : "en"
                }
                adaptiveData={adaptiveData}
                standardId={standardIds?.join(",")}
                subjectId={subjectId}
                onUnfocused={validateDiagnosticActivity}
                onFocused={validateDiagnosticActivity}
              ></QuestionAnswer>
            </>
          )}

        {beginActivityModal.open && (
          <BeginActivity
            activity={t("LiftoffDiagnostics.DiagnosticTest")}
            name={activityHeader + " - Diagnostic"}
            noOfQuestions={-1}
            source={"Assignment"}
            themeName={"ELEMENTARYHOME"}
            data={beginActivityModal.data}
            hideSpanishLanguage={
              props.profile.loLanguageId === 1 ? true : false
            }
            hideEnglishLanguage={
              props.profile.loLanguageId === 2 ? true : false
            }
            hideQuestionCount={true}
            hideTeacher={true}
            hideClass={true}
            ActivityLanguageLevel={"UserLevel"}
            onBegin={(data: any, value: any) => {
              OnBeginActivity(value);
              setBeginActivityModal({ ...beginActivityModal, open: false });
            }}
            programMode={constant.ProgramMode.LO}
          />
        )}

        {showQuestionAlreadyAttempted && (<QuestionAlreadyAttemptedPopup />)}
      </div>
    </Fragment>
  );
}

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
};

export default connect<{}, {}, {}>(
  mapStateToProps,
  {}
)(LiftOffDiagnosticActivity);

import { Fragment, useEffect, useState } from "react";
import "../../../../../../css/style.css";
import { getStudentAssignmentResult } from "../../../../../../api/student/studentAssignmentApi";
import { GoogleApi } from "../../../../../../api/google/googleApi";
import { connect } from "react-redux";
import { UserContextState } from "../../../../../../redux/actions/userContextAction";
import Profile from "../../../../../../model/users/profile";
import OverviewResult from "./overviewResult";
import LearnosityReport from "../../../../results/learnosityReport";
import { useTranslation } from "react-i18next";
import ProgressResult from "./progressResult";
import TotalQuestionsByDomains from "./totalQuestionsByDomains";
import TotalQuestionsByStandards from "./totalQuestionsByStandards";
import { CanvasApi } from "../../../../../../api/canvas/canvasApi";
import { getStudentsTeacherFeedbackForActivity } from "../../../../../../api/student/studentActivityApi";
import constant from "../../../../../../utils/constant/constant";
import routeConstant from "../../../../../../utils/constant/routeConstant";
import { IBreadcrumb } from "../../../../../../model/common/breadcrumb";
import Breadcrumb from "../../../middleSchool/breadcrumb";
import { useHistory, useLocation } from "react-router-dom";
import {
  canStudentViewItemDetails,
  canTeacherViewItemDetails,
  canViewAssessmentExplanation,
  redirectToActivity,
  redirectStandardsEfficiencyReport,
  getSessionFromActivities,
} from "../../../../../../utils/assignmentHelper";
import Toggle from "../../../../../shared/toggle";
import {
  validateIsGoogleUser,
  validateIsCanvasUser,
} from "../../../../../../utils/helper";
import { IStandardEfficiencyParams } from "../../../../../../utils/standardEfficiencyHelper";

interface IAssignmentResult {
  userContext: UserContextState;
  profile: Profile;
  match: any;
  studentId?: number;
  studentGradeId?: number;
  isTeacherView?: boolean;
  assignmentId?: number;
  assignmentActivityId?: number;
  sessionId?: string;
  schoolYearId: number;
}
const AssignmentResultEL = (props: IAssignmentResult) => {
  const history = useHistory();
  const { state } = useLocation<any>();
  type DisplayResultType = "NoDetail" | "QuestionsOnly" | "QuestionsAndAnswer";
  const { t } = useTranslation();
  const [assignmentResult, setAssignmentResult] = useState<any>(null);
  const [teacherFeedback, setTeacherFeedback] = useState<string>();
  const [resultscore, setresultscore] = useState<Number>();

  const [toggleRetry, setToggleRetry] = useState(false);
  const assignmentId = props.isTeacherView
    ? props.assignmentId ?? 0
    : parseInt(props.match.params.assignmentId);
  const assignmentActivityId = props.isTeacherView
    ? props.assignmentActivityId ?? 0
    : parseInt(props.match.params.assignmentActivityId);
  const sessionId = props.isTeacherView
    ? props.sessionId
    : props.match.params.sessionId ?? "";
  const userId =
    props.isTeacherView === true
      ? props.studentId ?? 0
      : props.userContext.userId;
  const [learnosityActivityUserId, setLearnosityActivityUserId] =
    useState<number>(userId);
  useEffect(() => {
    getResult(
      props.isTeacherView === true
        ? props.studentId ?? 0
        : props.userContext.userId,
      assignmentId,
      assignmentActivityId
    );
  }, []);

  function showAnswer(value: number) {
    let result: DisplayResultType = "NoDetail";
    switch (value) {
      case 1:
        result = "NoDetail";
        break;
      case 2:
        result = "QuestionsOnly";
        break;
      case 3:
        result = "QuestionsAndAnswer";
        break;
    }

    return result === "QuestionsAndAnswer";
  }

  function getResult(
    userId: number,
    assignmentId: number,
    assignmentActivityId: number
  ) {
    getStudentAssignmentResult(
      props.schoolYearId,
      userId,
      assignmentId,
      assignmentActivityId,
      true,
      sessionId
    ).then((res) => {
      const score = Math.round(
        // totalScore means pointsEarned; totalAttempted means pointsPossible
        (Number(res.data.totalScore) / Number(res.data.totalAttempted)) * 100
      );
      setresultscore(score);
      if (
        validateIsGoogleUser(props?.profile!) &&
        props?.userContext.impersonatedUser === null
      ) {
        GoogleApi.submitGoogleAssignmentScore(
          assignmentId,
          assignmentActivityId,
          props.userContext.googleId,
          props.userContext.userId,
          props.profile?.googleUserId,
          score,
          props.profile?.googleUser?.refreshToken!
        );
      }

      if (
        validateIsCanvasUser(props.profile!) &&
        props?.userContext.impersonatedUser === null
      ) {
        CanvasApi.submitCanvasAssignmentScore(
          assignmentId,
          assignmentActivityId,
          props.userContext.userId,
          score,
          props.profile.canvasAccountSettings
        );
      }
      if (
        res.data?.studentAssignmentActivityId &&
        res.data?.assignmentActivityTypeId ===
          constant.AssignmentActivityTypeEnum.CONSTRUCTEDRESPONSE
      ) {
        GetTeacherFeedback(res.data.studentAssignmentActivityId);
      }

      setLearnosityActivityUserId(res.data.learnosityActivityUserId ?? userId);
      setAssignmentResult(
        res.data && res.data.activities?.length > 0 ? res.data : null
      );
    });
  }
  function GetTeacherFeedback(studentAssignmentActivityId: number) {
    getStudentsTeacherFeedbackForActivity(studentAssignmentActivityId).then(
      (r) => {
        if (r.data) {
          setTeacherFeedback(r.data);
        } else {
          setTeacherFeedback("");
        }
      }
    );
  }

  const breadcrumbItems = () => {
    const items: Array<IBreadcrumb> = [
      {
        name: t("Breadcrumb.Home"),
        url: routeConstant.StudentRoute.Home,
        textColor: "text-gray-500",
      },
      {
        name: t("Breadcrumb.Result"),
        textColor: "text-gray-500",
        url: "",
      },
    ];

    return items;
  };

  function redirectToNextActivity() {
    redirectToActivity(
      history,
      assignmentResult.nextActivityDetail,
      routeConstant.StudentRoute.StudentAssignmentActivityEL,
      props.profile?.plLanguageId
    );
  }

  function redirectToCurrentActivity() {
    redirectToActivity(
      history,
      assignmentResult.currentActivityDetail,
      routeConstant.StudentRoute.StudentAssignmentActivityEL,
      props.profile?.plLanguageId
    );
  }

  return (
    <Fragment>
      {assignmentResult && (
        <div
          className={
            "bg-white flex-grow mb-4" +
            (!(props.isTeacherView ?? false) ? " rounded-2xl " : "")
          }
        >
          {!(props.isTeacherView ?? false) && (
            <div className="px-4 py-3 lg:px-8 border-b">
              <Breadcrumb items={breadcrumbItems()} />
            </div>
          )}
          <div className="p-5 md:py-5 md:px-2 lg:p-5 flex flex-col md:flex-row">
            <OverviewResult
              data={assignmentResult}
              redirectToReport={() => {
                redirectStandardsEfficiencyReport({
                  isTeacherView: props.isTeacherView ?? false,
                  studentGradeId: props.studentGradeId ?? 0,
                  studentId: props.studentId ?? 0,
                  studentUrl:
                    routeConstant.StudentRoute.StandardEfficiencyReport,
                  sessionId: getSessionFromActivities(
                    assignmentResult.activities
                  ),
                  history: history,
                } as IStandardEfficiencyParams);
              }}
            />
            <div className="flex-grow h-auto md:w-1/2">
              {/* flex-grow h-auto md:w-1/2 */}
              <div className="flex w-full mt-6 md:mt-0 pb-6 justify-between">
                <div>
                  <h1 className="text-2xl font-bold text-gray-700 capitalize">
                    {assignmentResult.assignmentName}
                  </h1>
                </div>
                <div className="flex gap-x-3">
                  {!props.isTeacherView && (
                    <button
                      className={`cursor-pointer bg-tertiary-color border-black border-[3px] border-opacity-20 rounded-md shadow-sm py-2 px-4 flex justify-center text-base font-medium text-gray-700 hover:text-gray-700 hover:bg-orange-light hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
                      onClick={() =>
                        history.replace(
                          state?.redirectedFromLO
                            ? routeConstant.LiftOff.Elementary.Home
                            : routeConstant.StudentRoute.Home,
                          []
                        )
                      }
                    >
                      Return to Assignments
                    </button>
                  )}
                  {assignmentResult.nextActivityDetail !== null &&
                    assignmentResult.nextActivityDetail.totalAttempt <
                      assignmentResult.nextActivityDetail.multipleAttempt &&
                    (resultscore || resultscore === 0) &&
                    resultscore >= assignmentResult.minimumScore &&
                    !props.isTeacherView && (
                      <button
                        className={`cursor-pointer bg-tertiary-color border-black border-[3px] border-opacity-20 rounded-md shadow-sm py-2 px-4 flex justify-center text-base font-medium text-gray-700 hover:text-gray-700 hover:bg-orange-light hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
                        onClick={() => redirectToNextActivity()}
                      >
                        {" "}
                        Next Activity
                      </button>
                    )}
                  {assignmentResult.currentActivityDetail !== null &&
                    assignmentResult.currentActivityDetail.totalAttempt <
                      assignmentResult.currentActivityDetail.multipleAttempt &&
                    assignmentResult.attemptAgain &&
                    !props.isTeacherView && (
                      <button
                        className={`cursor-pointer bg-tertiary-color border-black border-[3px] border-opacity-20 rounded-md shadow-sm py-2 px-4 flex justify-center text-base font-medium text-gray-700 hover:text-gray-700 hover:bg-orange-light hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
                        onClick={() => redirectToCurrentActivity()}
                      >
                        {t("Assignments.AttemptAgain")}
                      </button>
                    )}
                </div>
              </div>
              <div className="flex-none xxl:flex">
                  {assignmentResult.unmarked === false && (
                    <div className="flex-none w-full xl:w-4/5 xxl:w-2/5 border border-gray-100 rounded-md xl:mr-12 mb-12 xxl:mb-0 overflow-hidden shadow-xl">
                      <ProgressResult data={assignmentResult} />
                    </div>                
                  )}                
                <div className="flex-grow w-full xl:w-4/5 xxl:w-3/5">
                  {teacherFeedback && (
                    <div className="mb-8">
                      <h2 className="text-md font-semibold text-gray-800 mb-2">
                        Teacher Feedback
                      </h2>
                      <div className="rounded-md bg-blue-100 p-4">
                        <div className="flex">
                          <div className="flex-shrink-0">
                            <svg
                              className="h-7 w-7 text-blue-400"
                              x-description="Heroicon name: solid/information-circle"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                              aria-hidden="true"
                            >
                              <path
                                fillRule="evenodd"
                                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                                clip-rule="evenodd"
                              ></path>
                            </svg>
                          </div>
                          <div className="ml-3 flex-1 md:flex md:justify-between">
                            <p className="text-lg text-blue-700">
                              {teacherFeedback}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <TotalQuestionsByDomains data={assignmentResult} />
                  <TotalQuestionsByStandards data={assignmentResult} />
                </div>
              </div>
            </div>
          </div>

          <>
            {(((props.isTeacherView ?? false) === true &&
              canTeacherViewItemDetails(
                props.userContext.roleId,
                assignmentResult
              )) ||
              ((props.isTeacherView ?? false) === false &&
                canStudentViewItemDetails(assignmentResult))) && (
              <>
                <div className="p-5">
                  <div className="p-5 bg-gray-50 border rounded-md text-sm font-semibold">
                    <div className="flex w-full justify-between gap-x-4">
                      <div>
                        <h2>
                          {" "}
                          {t("AssignmentResult.AnswerStatusByQuestionNo")}
                        </h2>
                        <ul className="flex flex-wrap gap-2 w-full  mt-3">
                          {assignmentResult.questions
                            .filter(
                              (q) =>
                                (!toggleRetry ||
                                  (toggleRetry && q.retryScore !== null)) &&
                                q.order > 0
                            )
                            .map((question, index) => {                              
                              return (
                                <>
                                  {question.unmarked ? 
                                    (
                                      <li className="w-6 h-6 bg-[#ababab] border border-[#ababab] text-white text-xs font-semibold flex items-center justify-center rounded-sm shadow-sm justify-center hover:bg-transparent hover:text-[#ababab]">
                                        {index + 1}
                                      </li>
                                    ) :
                                    (
                                      ((!toggleRetry && question.score === question.pointsPossible) ||
                                        (toggleRetry && question.retryScore === question.pointsPossible)) ? (
                                        <li className="w-6 h-6 bg-[#B2D136] border border-[#B2D136] text-white text-xs font-semibold flex items-center rounded-sm shadow-sm justify-center hover:bg-transparent hover:text-[#B2D136]">
                                          {index + 1}
                                        </li>
                                      ) :
                                      ((!toggleRetry && question.score < question.pointsPossible) ||
                                       (toggleRetry && question.retryScore < question.pointsPossible)) && (
                                        <li className="w-6 h-6 bg-[#e53f71] border border-[#e53f71] text-white text-xs font-semibold flex items-center rounded-sm shadow-sm justify-center hover:bg-transparent hover:text-[#e53f71]">
                                          {index + 1}
                                        </li>
                                      )
                                    )
                                  }
                                </>
                              );
                            })}
                        </ul>
                      </div>
                      {assignmentResult.retryScore !== null && (
                        <div className="flex gap-x-2">
                          <span> Original </span>
                          <Toggle
                            checked={toggleRetry}
                            onChange={(e) => {
                              setToggleRetry(!toggleRetry);
                            }}
                          />
                          <span> Retry </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="p-5">
                  <div className="p-5 border-2 border-blue-400 rounded-md text-sm font-semibold">
                    {assignmentResult &&
                      assignmentResult.activities?.length > 0 && (
                        <>
                          {!toggleRetry && (
                            <LearnosityReport
                              sessionId={
                                assignmentResult.activities[0].sessionId
                              }
                              user_id={learnosityActivityUserId.toString()}
                              show_answers={
                                assignmentResult.assessmentResultDisplayTypeId >
                                0
                                  ? showAnswer(
                                      props.isTeacherView === true
                                        ? 3
                                        : assignmentResult.assessmentResultDisplayTypeId
                                    )
                                  : true
                              }
                              wrapperClass="EL-Wrapper"
                              standardIds={assignmentResult.standards
                                ?.map((standard) => {
                                  return standard.standardId;
                                })
                                ?.join(",")}
                              hideExplanation={
                                assignmentResult.assignmentActivityTypeId ===
                                constant.AssignmentActivityTypeEnum.ASSESSMENT
                                  ? !canViewAssessmentExplanation(
                                      assignmentResult.assignmentActivityTypeId,
                                      assignmentResult.assessmentResultDisplayTypeId,
                                      props.isTeacherView
                                    )
                                  : false
                              }
                              showIndividualQuestionFeedback={true}
                            ></LearnosityReport>
                          )}

                          {toggleRetry && (
                            <LearnosityReport
                              sessionId={
                                assignmentResult.retryLernositySessionId
                              }
                              user_id={learnosityActivityUserId.toString()}
                              show_answers={
                                assignmentResult.assessmentResultDisplayTypeId >
                                0
                                  ? showAnswer(
                                      props.isTeacherView === true
                                        ? 3
                                        : assignmentResult.assessmentResultDisplayTypeId
                                    )
                                  : true
                              }
                              wrapperClass="EL-Wrapper"
                              standardIds={assignmentResult.standards
                                ?.map((standard) => {
                                  return standard.standardId;
                                })
                                ?.join(",")}
                              hideExplanation={
                                assignmentResult.assignmentActivityTypeId ===
                                constant.AssignmentActivityTypeEnum.ASSESSMENT
                                  ? !canViewAssessmentExplanation(
                                      assignmentResult.assignmentActivityTypeId,
                                      assignmentResult.assessmentResultDisplayTypeId,
                                      props.isTeacherView
                                    )
                                  : false
                              }
                              showIndividualQuestionFeedback={true}
                            ></LearnosityReport>
                          )}
                        </>
                      )}
                  </div>
                </div>
              </>
            )}
          </>
        </div>
      )}
    </Fragment>
  );
};

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
};
export default connect<{}, {}, {}>(mapStateToProps, {})(AssignmentResultEL);

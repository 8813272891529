import Moment from "moment";
import { Fragment, useEffect, useState } from "react";
import Select from "react-select";
import {
  getAllDomainsBySubject,
  getAllStandardByDomains,
} from "../../../../api/teacher/assignment";
import { getAllQuestions } from "../../../../api/teacher/question";
import { IAssessmentQuestion } from "../../../../model/interface/assessmentQuestion";
import { ISelectElement } from "../../../../model/interface/selectElement";
import DomainStandard from "../../../../model/teacher/domainStandard";
import Question from "../../../../model/teacher/question";
import SearchQuestionRequest from "../../../../model/teacher/searchQuestionRequest";
import {
  DEFAULT_PAGE_NUMBER,
  DEFAULT_PAGE_SIZE,
} from "../../../../utils/pagingConstant";
import Tooltip from "../../../shared/ellipsisText";
import Loader from "../../../shared/loader";
import PageSize from "../../../shared/pagination/pageSize";
import Pagination from "../../../shared/pagination/pagination";
import QuestionPreview from "../shared/questionPreview/questionPreview";
import Constant from "../../../../utils/constant/constant";
import {Configuration} from "../../../../environment/setup";
interface authoredQuestionProps {
  userId: number;
  districtId: number;
  hasDistrictAssessmentQuestionIcon: boolean;
  subjectId: number;
  selectedQuestions: Array<IAssessmentQuestion>;
  updateQuestion: (question: IAssessmentQuestion) => void;
  updateSelectedQuestions: (standards: Array<string>) => void;
  allowEdit: boolean;
}

function AuthoredQuestions(props: authoredQuestionProps) {
  const {
    userId,
    districtId,
    hasDistrictAssessmentQuestionIcon,
    subjectId,
    selectedQuestions,
    updateQuestion,
    updateSelectedQuestions,
    allowEdit,
  } = props;
  const [pageNumber, setPageNumber] = useState(DEFAULT_PAGE_NUMBER);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [resetPageNumber, setResetPageNumber] = useState<boolean>(false);
  const [domains, setDomains] = useState<Array<any>>([]);
  const [standards, setStandards] = useState<ISelectElement[]>([]);
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [open, setOpen] = useState(false);
  const [selectedDomains, setSelectedDomains] = useState<Array<ISelectElement>>(
    []
  );
  const [selectedStandards, setSelectedStandards] = useState<
    Array<ISelectElement>
  >([]);
  const orderbyColumn = "CreatedDate";
  const [questions, setQuestions] = useState<Array<Question>>([]);
  // const [selectedQuestionId, setSelectedQuestionId] = useState<number>(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const sortByDesc = true;
  const [questionPreviewDetail, setQuestionPreviewDetail] = useState<{
    questionReference: string;
    questionId: number;
    questionText: string;
    currentIndex: number;
  }>();

  // const handleQuestionPreview = (questionId) => {
  //   setShowPreview(true);
  //   setSelectedQuestionId(questionId);
  // };

  function handleDomainChange(e: any) {
    setSelectedDomains(e);
    const selectedDomainIds = Array.prototype.map.call(e, (s) => s.value);
    getStandards(selectedDomainIds.join(","), true);
  }

  function getDomains() {
    setShowLoading(true);
    getAllDomainsBySubject(subjectId).then((response) => {
      setShowLoading(false);
      const results = response.data.data;
      if (selectedQuestions && selectedQuestions.length > 0) {
        const domainIds: Array<number> = [];
        selectedQuestions.map((question) => {
          if (question.domainId) {
            const domainId = parseInt(question.domainId);
            if (!domainIds.includes(domainId)) {
              domainIds.push(domainId);
            }
          }
          return null;
        });

        const existingDomains: Array<ISelectElement> = results.filter((r) =>
          domainIds.includes(parseInt(r.domainId))
        );
        if (existingDomains && existingDomains.length > 0) {
          const selectedDomains: Array<ISelectElement> = existingDomains.map(
            (values: any) => {
              return {
                label: values?.name,
                value: values?.domainId,
              };
            }
          );
          setSelectedDomains(selectedDomains);
          getStandards(domainIds.join(","));
        }
      } else {
        setSelectedDomains([]);
      }

      const domains: Array<ISelectElement> = results.map((values: any) => {
        return {
          label: values?.name,
          value: values?.domainId,
        };
      });
      setDomains(domains);
    });
  }

  function getStandards(domains: string, isDomainChanged: boolean = false) {
    setShowLoading(true);
    getAllStandardByDomains(domains).then((response) => {
      setShowLoading(false);
      const results = response.data.data;
      const standards: Array<ISelectElement> = [];
      const standardIds: Array<number> = [];
      if (response.data.data) {
        selectedQuestions.map((question) => {
          if (question.standardId) {
            const standardId = parseInt(question.standardId);
            if (!standardIds.includes(standardId)) {
              standardIds.push(standardId);
            }
          }
          return null;
        });

        results.forEach(function (domainStandard: DomainStandard) {
          domainStandard.standards.map((values: any) => {
            standards.push({
              label: `${values.code} ${values.name}`,
              value: values?.id,
            });
            return null;
          });
        });
      }
      setStandards(standards);

      const existingStandards: Array<ISelectElement> = standards.filter((r) =>
        standardIds.includes(parseInt(r.value))
      );

      setSelectedStandards(existingStandards);
      if (isDomainChanged) {
        updateSelectedQuestions(existingStandards.map((r) => r.value));
      }

      const selectedDomains: Array<ISelectElement> = [];
      if (domains !== "") {
        const domainIds: Array<string> = domains.split(",");
        domainIds.map((r) => selectedDomains.push({ label: "", value: r }));
      }

      searchAuthoredQuestions(existingStandards, selectedDomains);
    });
  }

  function handleStandardChange(e: any) {
    setSelectedStandards(e);
    searchAuthoredQuestions(e);
    updateSelectedQuestions(e.map((r) => r.value));
  }

  function searchAuthoredQuestions(
    standards?: any,
    domains?: Array<ISelectElement>
  ) {
    const selectedDomainIds = Array.prototype.map.call(
      domains ? domains : selectedDomains,
      (s) => s.value
    );
    const selectedStandardIds = Array.prototype.map.call(
      standards ? standards : selectedStandards,
      (s) => s.value
    );

    let searchQuestion: SearchQuestionRequest = {
      subjectId: subjectId,
      domainIds: selectedDomainIds.join(","),
      standardIds: selectedStandardIds.join(","),
      dok: 0,
      orderbyColumn: orderbyColumn,
      sortByDesc: sortByDesc,
      pageSize: pageSize,
      pageNumber: pageNumber,
      questionCategoryIds: [
        Constant.QuestionCategory.Charts,
        Constant.QuestionCategory.ClassifyMatchAndOrder,
        Constant.QuestionCategory.FillInTheBlanks,
        Constant.QuestionCategory.Graphing,
        Constant.QuestionCategory.MultipleChoice,
        Constant.QuestionCategory.Passages,
        Constant.QuestionCategory.WrittenAndRecorded,
        Constant.QuestionCategory.Math,
        Constant.QuestionCategory.Highlight,
      ].join(","),
      createdByUserId: userId,
      isAssessmentSearch: true,
      includeConstructedResponseQuestionTypes: true
    };
    setShowLoading(true);
    getAllQuestions(searchQuestion).then((response) => {
      setShowLoading(false);
      if (response.data !== null) {
        setQuestions(response.data.data);
        setTotalRecords(response.data.totalRecords);
      }
      setShowLoading(false);
    });
  }

  const onPageChange = (pageNo) => {
    setPageNumber(pageNo);
  };

  const onPageSizeChange = (pageSize) => {
    setPageNumber(DEFAULT_PAGE_NUMBER);
    setPageSize(pageSize);
    setResetPageNumber(true);
  };

  function handleSelectedQuestion(selectedQuestion: Question) {
    const question: IAssessmentQuestion = {
      questionId: selectedQuestion.teacherQuestionId ?? 0,
      sequenceNumber: selectedQuestions.length + 1,
      questionText: selectedQuestion.questionText,
      passageTitle: selectedQuestion.passageTitle,
      domainId: selectedQuestion.domainId
        ? selectedQuestion.domainId.toString()
        : "",
      standardId: selectedQuestion.standardId
        ? selectedQuestion.standardId.toString()
        : "",
      passageId: selectedQuestion.passageId,
      questionTypeId: selectedQuestion.questionTypeId,
      questionType: selectedQuestion.questionTypeName,
      dokId: selectedQuestion.dok,
      learnosityReferenceId: selectedQuestion.questionReferenceId,
      standardCode: selectedQuestion.standardCode,
      standardName: selectedQuestion.standardName,
      domainName: selectedQuestion.domainName,
      assessmentQuestionTypeId:
        Constant.AssessmentQuestionType.AuthoredQuestions,
      domainOrder: selectedQuestion.domainOrder,
      standardOrder: selectedQuestion.standardOrder,
      pointsPossible: selectedQuestion.pointsPossible,
      isTeacherQuestion: true,
    };
    updateQuestion(question);
  }

  const openModal = (questionId, questionText, currentIndex) => {
    setOpen(true);
    setQuestionPreviewDetail({
      questionReference: `${questionId}-teacher`,
      questionId: questionId,
      questionText: questionText,
      currentIndex: currentIndex,
    });
  };

  const popUpClose = (value) => {
    setOpen(value);
    setQuestionPreviewDetail(undefined);
  };

  function addQuestion(questionId: number) {
    const selectedQuestion = questions.find(
      (r) => r.teacherQuestionId === questionId
    );
    if (selectedQuestion) {
      handleSelectedQuestion(selectedQuestion);
    }
  }

  function handleNextQuestion() {
    if (questionPreviewDetail) {
      const nextQuestion = questions[questionPreviewDetail?.currentIndex + 1];
      if (nextQuestion && nextQuestion.teacherQuestionId) {
        setQuestionPreviewDetail({
          questionReference: `${nextQuestion.teacherQuestionId}-teacher`,
          questionId: nextQuestion.teacherQuestionId,
          questionText: nextQuestion.questionText
            ? nextQuestion.questionText
            : "",
          currentIndex: questionPreviewDetail.currentIndex + 1,
        });
      }
    }
  }

  function handlePreviousQuestion() {
    if (questionPreviewDetail) {
      const nextQuestion = questions[questionPreviewDetail?.currentIndex - 1];
      if (nextQuestion && nextQuestion.teacherQuestionId) {
        setQuestionPreviewDetail({
          questionReference: `${nextQuestion.teacherQuestionId}-teacher`,
          questionId: nextQuestion.teacherQuestionId,
          questionText: nextQuestion.questionText
            ? nextQuestion.questionText
            : "",
          currentIndex: questionPreviewDetail.currentIndex - 1,
        });
      }
    }
  }

  useEffect(() => {
    searchAuthoredQuestions();
  }, [pageSize, pageNumber]);

  useEffect(() => {
    if(subjectId !== 0) {
      getDomains();
    }
  }, [subjectId]);

  return (
    <Fragment>
      <div
        className={`col-span-10 lg:col-span-9 xl:col-span-10 ${
          allowEdit ? "" : "pointer-events-none opacity-50"
        }`}
      >
        {showLoading && <Loader />}
        <QuestionPreview
          key={questionPreviewDetail?.questionId}
          modal={openModal}
          show={open}
          changeValue={popUpClose}
          itemRef={questionPreviewDetail?.questionReference}
          questionIdPreview={questionPreviewDetail?.questionId}
          activityName={questionPreviewDetail?.questionText}
          addQuestionClick={addQuestion}
          nextQuestionClick={handleNextQuestion}
          previousQuestionClick={handlePreviousQuestion}
          isQuestionAdded={selectedQuestions.some(
            (r) => r.questionId === questionPreviewDetail?.questionId
          )}
        ></QuestionPreview>
        {subjectId > 0 && (
          <div className="bg-gray-100 w-full rounded-lg h-full">
            <div className="w-full block mx-4 sm:mx-0 lg:col-span-9 xl:col-span-12">
              <div className="min-w-0 block lg:col-span-9 xl:col-span-10 w-full">
                <div className="bg-gray-100  w-full mb-5 rounded-lg p-5 h-full">
                  <div className="grid grid-cols-2 sm:grid-cols-8 lg:grid-cols-8 xl:grid-cols-8 gap-6 pb-4">
                    <div className="col-span-8 sm:col-span-4 xl:col-span-2">
                      <label className="block text-sm text-gray-500">
                        Select Domain(s)
                      </label>
                      <Select
                        value={selectedDomains}
                        options={domains}
                        isMulti={true}
                        closeMenuOnSelect={false}
                        onChange={handleDomainChange}
                      />
                    </div>
                    <div className="col-span-8 sm:col-span-4 xl:col-span-2">
                      <label className="block text-sm text-gray-500">
                        Select Standard(s)
                      </label>
                      <Select
                        value={selectedStandards}
                        options={standards}
                        isMulti={true}
                        closeMenuOnSelect={false}
                        onChange={handleStandardChange}
                      />
                    </div>
                    <div className="flex flex-col md:flex-row gap-4 items-end md:items-center md:justify-between justify-start pb-10"></div>
                  </div>

                  <div>
                    <div className="bg-white py-5 px-5">
                      <div className="mb-4 pb-4 border-b-2 overflow-auto">
                        <table className="table w-full ">
                          <thead className="mb-5">
                            <tr className="border-[3px] border-secondary-teal text-secondary-teal py-3 px-3 overflow-hidden">
                              <th className="text-left p-3 text-sm font-normal w-4"></th>
                              {hasDistrictAssessmentQuestionIcon && (
                                  <th className="text-left text-sm font-normal"></th>)}
                              <th className="text-center p-3 text-sm font-normal">
                                Create Date
                              </th>
                              <th className="text-left  p-3 text-sm font-normal">
                                Question - Click to Preview
                              </th>
                              <th className="text-center p-3 text-sm font-normal">
                                Point Value
                              </th>
                              <th className="text-left p-3 text-sm font-normal">
                                Question Type
                              </th>
                              <th className="text-left p-3 text-sm font-normal">
                                Classification
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {questions &&
                              questions.length > 0 &&
                              questions.map((question, index) => {
                                return (
                                  <Fragment key={index}>
                                    <tr
                                      className={`hover:bg-secondary-teal/20 transition ease-in-out duration-300 ${
                                        index % 2 === 0
                                          ? "bg-white"
                                          : "bg-secondary-teal/10"
                                      }`}
                                    >
                                      <td className=" mt-5 p-3 text-center">
                                        <input
                                          id="styled-checkbox-2"
                                          type="checkbox"
                                          className="checkbox focus:ring-secondary-teal h-4 w-4 text-secondary-teal border-gray-300"
                                          checked={selectedQuestions.some(
                                            (r) =>
                                              r.questionId ===
                                              question.teacherQuestionId
                                          )}
                                          onClick={() =>
                                            handleSelectedQuestion(question)
                                          }
                                        />
                                        <label className="checkbox-label"></label>
                                      </td>
                                      {hasDistrictAssessmentQuestionIcon && (
                                          <td className="text-sm">
                                            <div className="flex items-center justify-center">
                                              {question.isDistrictAssessmentQuestion && (
                                                  <img alt=""
                                                       src={`${Configuration.S3bucketImagePath}/districts/${districtId.toString()}/district-assessment-question-icon.png`}/>)}
                                            </div>
                                          </td>
                                      )}
                                      <td className="text-sm mt-5 p-3 text-center">
                                        {question.createdDate &&
                                          Moment(question.createdDate).format(
                                            "MM-DD-yyyy"
                                          )}
                                      </td>
                                      <td className="text-sm mt-5  p-3 ">
                                        <a
                                          href={void 0}
                                          onClick={() =>
                                            openModal(
                                              question?.teacherQuestionId,
                                              question.questionText,
                                              index
                                            )
                                          }
                                          className="cursor-pointer text-blue-500 underline hover:no-underline"
                                        >
                                          <Tooltip
                                            text={question?.questionText}
                                            isQuestion={true}
                                          />
                                        </a>
                                      </td>
                                      <td className="text-center text-sm mt-5 p-3">
                                        {question.pointsPossible}
                                      </td>
                                      <td className="text-sm mt-5  p-3 ">
                                        {question.questionTypeName}
                                      </td>
                                      <td className="text-sm mt-5  p-3 ">
                                        <div className="grid">
                                          {question.domainName}
                                        </div>
                                        <div className="grid">
                                          {question.standardName}
                                        </div>
                                      </td>
                                    </tr>
                                  </Fragment>
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                      <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                        {totalRecords !== undefined && totalRecords !== 0 && (
                          <Pagination
                            totalRecords={totalRecords}
                            pageSize={pageSize}
                            onPageChange={onPageChange}
                            reset={resetPageNumber}
                            pageNumber={pageNumber}
                            bgColor="secondary-teal"
                          />
                        )}
                        {totalRecords !== 0 && (
                          <PageSize
                            pageSize={pageSize}
                            onPageSizeChange={onPageSizeChange}
                            bgColor="secondary-teal"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </Fragment>
  );
}

export default AuthoredQuestions;

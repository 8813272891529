import { useEffect, useState } from "react";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import Loader from "../../../../shared/loader";
import Breadcrumb from "../../../teacher/shared/breadcrumb";
import { IBreadcrumb } from "../../../../../model/common/breadcrumb";
import RouteConstant from "../../../../../utils/constant/routeConstant";
import { useTranslation } from "react-i18next";
import {
  getLiftoffUsageStudentLevelReport,
  getLiftoffUsageStudentLevelReportExcel,
} from "../../../../../api/report/liftoffUsageReport";
import Pagination from "../../../../shared/pagination/pagination";
import PageSize from "../../../../shared/pagination/pageSize";
import {
  DEFAULT_PAGE_NUMBER,
  DEFAULT_PAGE_SIZE_10,
  DEFAULT_PAGE_SIZE_MAX_99999,
} from "../../../../../utils/pagingConstant";
import { AxiosResponse } from "axios";
import { getEndDate, getStartDate } from "../../../../../utils/dateHelper";
import Constant from "../../../../../utils/constant/constant";
import {useLocation} from "react-router-dom";

function LiftoffUsageStudentLevelReport(props) {
  const schoolId = props.match.params.schoolId;
  const classId = props.match.params.classId;
  const gradeId = props.match.params.gradeId;
  const subjectId = props.match.params.subjectId;
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const schoolYearId = parseInt(query.get("schoolYearId") ?? "0") || null;
  const startDateParam = props.location.state
    ? props.location.state.startDate
    : null;
  const endDateParam = props.location.state
    ? props.location.state.endDate
    : null;
  const DefaultFilters = {
    startDate: new Date(),
    endDate: new Date(),
  };
  type viewByType = "Class" | "Grade";
  const currentDate = new Date();
  const viewBy: viewByType = gradeId === undefined ? "Grade" : "Class";
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState<Date | null>(startDateParam);
  const [endDate, setEndDate] = useState<Date | null>(endDateParam);
  const [isInvalidStartDate, setIsInvalidStartDate] = useState<boolean>(false);
  const [isInvalidEndDate, setIsInvalidEndDate] = useState<boolean>(false);
  const [reportData, setReportData] = useState<any>([]);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState(DEFAULT_PAGE_NUMBER);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE_10);
  const [resetPageNumber, setResetPageNumber] = useState<boolean>(false);
  const [filters, setFilters] = useState(DefaultFilters);

  const breadcrumbItems = () => {
    const items: Array<IBreadcrumb> = [
      { name: t("Breadcrumb.Home"), url: RouteConstant.ROUTE_DASHBOARD },
      { name: t("Breadcrumb.Reports"), url: RouteConstant.REPORTS },
      {
        name: t("Breadcrumb.LiftoffUsageReport"),
        url: RouteConstant.TeacherRoutes.LiftoffUsageReport,
      },
      { name: t("Breadcrumb.LiftoffUsageStudentLevelReport"), url: "" },
    ];
    return items;
  };

  const getFilters = () => {
    const filter =
      "Filters : " +
      (filters.startDate ? filters.startDate.toLocaleDateString() : "") +
      ", " +
      (filters.endDate ? filters.endDate.toLocaleDateString() : "");

    return filter;
  };

  const getReport = () => {
    setLoading(true);
    const sDate = startDate ?? null;
    const eDate = endDate ?? null;

    setFilters({
      startDate: sDate!,
      endDate: eDate!,
    });

    getLiftoffUsageStudentLevelReport(
      Number(schoolId),
      Number(subjectId),
      sDate,
      eDate,
      pageNumber,
      pageSize,
      classId ? Number(classId) : null,
      gradeId ? Number(gradeId) : null,
      schoolYearId
    )
      .then((r) => {
        if (r.data.data && r.data.data.data.length > 0) {
          setReportData(r.data.data.data);
          setTotalRecords(r.data.data.totalRecords);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const getReportExcel = () => {
    setLoading(true);
    const sDate = startDate ?? null;
    const eDate = endDate ?? null;
    const filters = getFilters();

    getLiftoffUsageStudentLevelReportExcel(
      Number(schoolId),
      Number(subjectId),
      sDate,
      eDate,
      DEFAULT_PAGE_NUMBER,
      DEFAULT_PAGE_SIZE_MAX_99999,
      classId ? Number(classId) : null,
      gradeId ? Number(gradeId) : null,
      filters,
      schoolYearId
    )
      .then((r) => {
        if (r.data) {
          startFileDownload(r, "liftoffusagestudentlevelreport.xlsx");
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const startFileDownload = (
    response: AxiosResponse<any>,
    fileName: string
  ) => {
    const file = response.data;
    const fileURL = URL.createObjectURL(file);
    let link = document.createElement("a");
    link.href = fileURL;
    link.download = fileName;
    link.setAttribute("target", "_blank");
    document.body.appendChild(link);
    link.click();
  };

  const handleStartDateChange = (date: Date) => {
    if (endDate && date && date > endDate) {
      setIsInvalidEndDate(true);
    } else {
      setIsInvalidEndDate(false);
    }
    setStartDate(date ? getStartDate(date) : null);
  };

  const handleEndDateChange = (date: Date) => {
    if (startDate && date && date < startDate) {
      setIsInvalidStartDate(true);
    } else {
      setIsInvalidStartDate(false);
    }
    setEndDate(date ? getEndDate(date) : null);
  };

  const handleCalendarOpen = (mode: string) => {
    switch (mode){
      case "end":
        if (!endDate) {
          setEndDate(getEndDate(new Date(), true));
        }
        break;
    }
  };

  const onPageChange = (pageNo) => {
    setPageNumber(pageNo);
  };

  const onPageSizeChange = (pageSize) => {
    setPageNumber(DEFAULT_PAGE_NUMBER);
    setPageSize(pageSize);
    setResetPageNumber(true);
  };

  const isFiltersValid = () => {
    return !isInvalidStartDate && !isInvalidEndDate;
  };

  const onReset = () => {
    setStartDate(null);
    setEndDate(null);
    setIsInvalidStartDate(false);
    setIsInvalidEndDate(false);
    setReportData([]);
  };

  const onRunReport = () => {
    setReportData([]);
    setTotalRecords(0);
    setPageNumber(DEFAULT_PAGE_NUMBER);
    getReport();
  };

  const onExport = () => {
    getReportExcel();
  };

  useEffect(() => {
    if (isFiltersValid()) {
      getReport();
    }
  }, [pageSize, pageNumber, resetPageNumber]);

  return (
    <div className="min-w-0 w-full">
      {loading && <Loader />}
      <div className="lg:flex lg:items-center px-4 py-3 lg:px-8 border-b">
        <Breadcrumb items={breadcrumbItems()} />
        <div className="lg:w-[55%] text-lg font-medium flex gap-2 h-auto sm:h-10 items-center">
          <h1 className="text-primary-violet font-medium flex justify-start mb-0 pb-0">
            Liftoff Usage Student Level Report
          </h1>
        </div>
      </div>
      <div className="px-2 sm:px-6  lg:px-8 py-1 relative my-4">
        <div className="bg-gray-100 w-full rounded-lg p-5">
          <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-4 mb-4">
            <div className="flex flex-col">
              <label className="block mb-1 text-sm text-gray-500">
                Start Date
              </label>
              <DatePicker
                tabIndex={7}
                minDate={Constant.PLMinDate}
                maxDate={new Date(currentDate.toDateString())}
                selected={startDate}
                onChange={handleStartDateChange}
                isClearable={true}
                onCalendarOpen={() => { handleCalendarOpen("start") }}
                className="react-datepicker__input-container py-1.5 px-3 block w-full shadow-sm focus:ring-primary-violet focus:border-primary-violet border border-gray-300 rounded-md "
              />
              {isInvalidStartDate && (
                <span className="pl-2 text-red-600">Invalid Date</span>
              )}
            </div>
            <div className="flex flex-col">
              <label className="block mb-1 text-sm text-gray-500">
                End Date
              </label>

              <DatePicker
                id="dt_end"
                tabIndex={8}
                minDate={Constant.PLMinDate}
                maxDate={new Date(currentDate.toDateString())}
                selected={endDate}
                onChange={handleEndDateChange}
                isClearable={true}
                onCalendarOpen={() => { handleCalendarOpen("end") }}
                className="react-datepicker__input-container py-1.5 px-3 block w-full shadow-sm focus:ring-primary-violet focus:border-primary-violet border border-gray-300 rounded-md "
              />
              {isInvalidEndDate && (
                <span className="pl-2 text-red-600">Invalid Date</span>
              )}
            </div>
          </div>

          <div className="flex gap-4 justify-end">
            <button
              onClick={onReset}
              tabIndex={2}
              className="bg-transparent border my-auto border-primary-violet shadow-sm py-2 px-4 flex justify-center text-sm font-medium text-primary-violet hover:text-white hover:bg-light-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3 transition ease-in-out duration-500"
            >
              Reset
            </button>
            <button
              tabIndex={1}
              onClick={onRunReport}
              className={
                "bg-primary-violet border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-dark-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3 " +
                (isFiltersValid()
                  ? ""
                  : "pointer-events-none text-gray-400 bg-gray")
              }
            >
              Run Report
            </button>
            <button
              onClick={onExport}
              className={
                "bg-primary-violet border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-dark-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3 " +
                (reportData.length > 0
                  ? ""
                  : "pointer-events-none text-gray-400 bg-gray")
              }
            >
              Export Data
            </button>
          </div>

          <div className="border-b border-gray-400 py-2 mb-2"></div>
          {reportData.length > 0 && (
            <div className="bg-white px-3 pt-2 pb-4">
              <div className="overflow-x-auto xl:overflow-x-visible mt-3 shadow">
                <table className="table w-full ">
                  <thead className="mb-5">
                    <tr className="bg-light-violet  text-white py-3 px-3 w-full">
                      {viewBy === "Grade" && (
                        <th
                          scope="col"
                          className="text-left  p-3 text-sm font-normal"
                        >
                          {viewBy}
                        </th>
                      )}
                      <th
                        scope="col"
                        className={`${
                          viewBy === "Grade" ? "text-left" : "text-left"
                        }   p-3 text-sm font-normal`}
                      >
                        <div className="w-full flex flex-col">
                          <div>Student Name</div>
                        </div>
                      </th>
                      <th
                        scope="col"
                        className="text-center  p-3 text-sm font-normal"
                      >
                        <div className="w-full flex flex-col">
                          <div>Student ID</div>
                        </div>
                      </th>
                      <th
                        scope="col"
                        className="text-center  p-3 text-sm font-normal"
                      >
                        <div className="w-full flex flex-col">
                          <div>Hours Worked</div>
                          <div>(DD:HH:MM:SS)</div>
                        </div>
                      </th>
                      <th
                        scope="col"
                        className="text-center p-3 text-sm font-normal"
                      >
                        Questions Answered
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {reportData.map((r, index) => (
                      <tr
                        key={index}
                        className={
                          index % 2 === 0
                            ? " hover:bg-light-violet/20 transition ease-in-out duration-300 bg-white"
                            : "hover:bg-light-violet/20 transition ease-in-out duration-300 bg-light-violet/10"
                        }
                        w-full
                        border-b
                      >
                        {viewBy === "Grade" && (
                          <td className="py-3 px-2">
                            <div className={`flex items-center`}>
                              <span className="ml-2">{r.gradeName}</span>
                            </div>
                          </td>
                        )}
                        <td className="py-3 px-2">
                          {(viewBy === "Grade" && (
                            <div className="flex ">
                              <span>{r.lastName + ", " + r.firstName}</span>
                            </div>
                          )) || (
                            <div className="flex items-center">
                              <span className="ml-2">
                                {r.lastName + ", " + r.firstName}
                              </span>
                            </div>
                          )}
                        </td>
                        <td className="py-3 px-2">
                          <span className="flex justify-center">
                            {r.studentId}
                          </span>
                        </td>
                        <td className="py-3 px-2">
                          <span className="flex justify-center">
                            {r.hoursWorked}
                          </span>
                        </td>
                        <td className="py-3 px-2">
                          <span className="flex justify-center">
                            {r.questionsAnswered}
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              {reportData.length > 0 && (
                <div className="sm:flex-1 sm:flex sm:items-center sm:justify-between border-t-2 mt-5 pt-4 ">
                  {totalRecords > 0 && (
                    <Pagination
                      totalRecords={totalRecords}
                      pageSize={pageSize}
                      onPageChange={onPageChange}
                      reset={resetPageNumber}
                    />
                  )}
                  {totalRecords > 0 && (
                    <PageSize
                      pageSize={pageSize}
                      onPageSizeChange={onPageSizeChange}
                    />
                  )}
                </div>
              )}
            </div>
          )}

          {reportData.length === 0 && (
            <div className="bg-white px-3 pt-2 pb-3">
              <span className="text-color-black">No record found</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default connect((state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
})(LiftoffUsageStudentLevelReport);

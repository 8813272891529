import { XIcon } from "@heroicons/react/outline";
import { IMiniBreak, ISessionTeam } from "../../../../../model/interface/classroomGame/thinkOrSwimSetup";
import PreviewPage from "../../shared/questionPreview/learnosityPreviewPage";
import { ChangeEvent, useEffect, useState } from "react";
import TOSGamePlayPointsAssigner from "./tosGamePlayPointsAssigner";
import ReefRecess from "../../../../../assets/images/thinkorswim/ReefRecess.jpg"
import AnglerfishButton from "./anglerfishButton";
import { waitForElementsToRender } from "../../../../../utils/waitFor";
import TosGamePlayQuestionClosingDialog from "./tosGamePlayQuestionClosingDialog";

interface ITOSGamePlayQuestionProps {
  questionPreviewSettings: any,
  isQuestion: boolean,
  showSpanishQuestion: boolean,
  handleLanguageChange: (showSpanish: boolean) => void,
  setShowQuestion: (showQuestion: boolean) => void,
  sessionTeams: Array<ISessionTeam>,
  selectedTeams: Array<string>,
  changeSelectedTeam: (teamName: string) => void,
  handleAssignPointsClick: (isQuestion: boolean) => void,
  miniBreak: IMiniBreak | undefined,
  reefRecessPointsValue: number,
}

function TOSGamePlayQuestion(props: ITOSGamePlayQuestionProps) {
  const {
    questionPreviewSettings,
    isQuestion,
    showSpanishQuestion,
    handleLanguageChange,
    setShowQuestion,
    sessionTeams,
    selectedTeams,
    changeSelectedTeam,
    handleAssignPointsClick,
    miniBreak,
    reefRecessPointsValue,
  } = props;

  const [showPointsAssigner, setShowPointsAssigner] = useState<boolean>(!isQuestion);
  const [showAnswersButton, setShowAnswersButton] = useState<HTMLButtonElement>();
  const [showClosingDialog, setShowClosingDialog] = useState<boolean>(false);

  const handleLanguageToggler = (e: ChangeEvent<HTMLInputElement>) => {
    handleLanguageChange(e.target.checked);
    setShowPointsAssigner(false);
  }

  const handleRevealAnswer = () => {
    if (showAnswersButton) {
      showAnswersButton.click();
    }
  }

  const handleQuestionClose = () => {
    if (showPointsAssigner) {
      setShowClosingDialog(true);
    } else {
      setShowQuestion(false);
    }
  }

  const handleQuestionCloseContinue = () => {
    setShowQuestion(false);
  }

  const handleQuestionCloseCancel = () => {
    setShowClosingDialog(false);
  }

  useEffect(() => {
    if (isQuestion && questionPreviewSettings.previewId) {
      waitForElementsToRender("",".lrn-author-validate-questions-toggle", 0, 3000)
      .then(() => {
        const showAnswerButton = document.querySelector(".lrn-author-item-toolbar");
        showAnswerButton?.classList.add("hidden");
        const queryAnswersButton = document.querySelector(".lrn-author-validate-questions-toggle") as HTMLButtonElement;
        if (queryAnswersButton) {
          setShowAnswersButton(queryAnswersButton);
        }
      })
    }
  }, [isQuestion, questionPreviewSettings.previewId])

  return (
    <div className="fixed inset-0 z-40 flex items-center bg-black bg-opacity-50 sm:items-center sm:justify-center">
      <div className="w-full h-full flex flex-col justify-between overflow-hidden bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg sm:m-4" role="dialog">
        <header>
          <div className="p-4 bg-gray-50">
            <div className="flex items-center">
              <span className="text-lg font-medium text-gray-900 h-7 pr-6 overflow-hidden">
                {questionPreviewSettings?.standardName || ''}
              </span>
              <div className="mb-0">
                {isQuestion && (
                  <label
                    htmlFor="toggleSpanish"
                    className="flex items-center cursor-pointer pl-1"
                  >
                    <div className="relative">
                      <input
                        id="toggleSpanish"
                        type="checkbox"
                        checked={showSpanishQuestion}
                        className="sr-only"
                        onChange={handleLanguageToggler}
                      />
                      <div
                        className={`${
                          showSpanishQuestion
                            ? "bg-primary-violet"
                            : "bg-gray-400"
                        } w-10 h-4  rounded-full shadow-inner`}
                      ></div>
                      <div
                        className={`${
                          showSpanishQuestion
                            ? "bg-primary-violet toggle-btn"
                            : ""
                        } absolute w-6 h-6 bg-white rounded-full shadow -left-1 -top-1 transition`}
                      ></div>
                    </div>
                    <div className="ml-3 text-gray-700 font-medium">
                      Spanish Version
                    </div>
                  </label>
                )}
              </div>
              <div className="ml-auto h-7 flex items-center">
                <button
                  type="button"
                  className="text-gray-400 hover:text-gray-500"
                  onClick={handleQuestionClose}
                >
                  <span className="sr-only">Close</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </header>
        {!isQuestion && (
          <div className="w-full px-4 py-1 mb-10">
            <div className="text-lg font-medium text-gray-500 mb-5 text-center">
              Reef Recess Activated!
            </div>
            <div className="flex justify-center">
              <img 
                className="w-1/4"
                src={ReefRecess}
                alt="Reef Reccess"
              />
            </div>
            <div className="text-lg font-medium mb-5 text-gray-500">
              Reef Recess Challenge
            </div>
            <div>
              <b>{miniBreak?.nickname}</b>
            </div>
            <div>
              {miniBreak?.instructions}
            </div>
          </div>
        )}
        {showPointsAssigner && (
          <TOSGamePlayPointsAssigner 
            sessionTeams={sessionTeams}
            selectedTeams={selectedTeams}
            isQuestion={isQuestion}
            reefRecessPointsValue={reefRecessPointsValue}
            changeSelectedTeam={changeSelectedTeam}
            handleAssignPointsClick={handleAssignPointsClick}
          />
        )}
        {!showPointsAssigner && (
          <div
            className="w-full flex justify-end"
          >
            <div className="w-[25rem]">
              <AnglerfishButton 
                text="Reveal Answer and Award Points"
                onClick={handleRevealAnswer}
              />
            </div>
          </div>
        )}
        <div className="w-full h-full relative overflow-auto px-4 py-1">
          <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5 ml-8">
            {isQuestion && questionPreviewSettings.previewId && (
              <PreviewPage 
                itemRef={questionPreviewSettings.previewId}
                standardId={questionPreviewSettings.standardId}
                isTotalPointsHidden
                onShowAnswer={() => setShowPointsAssigner(true)}
                isShowAnswerButtonHiddenAfterClick
                isCheckAnswerButtonHidden
              />
            )}
          </div>
        </div>
      </div>
      <TosGamePlayQuestionClosingDialog 
        showClosingDialog={showClosingDialog} 
        handleContinue={handleQuestionCloseContinue}
        handleCancel={handleQuestionCloseCancel}
      />
    </div>
  )
}

export default TOSGamePlayQuestion

import { Modal, ModalBody } from "@windmill/react-ui";
import format from "format-duration";
import { Fragment, useEffect, useState } from "react";
import ReactPlayer from "react-player";
import Select from "react-select";
import {
  getAllDomainsBySubject,
  getAllStandardByDomains,
  getVideoLessonByStandards,
} from "../../../../api/teacher/assignment";
import { FeedbackIcon } from "../../../../assets/icons/index";
import { IAssignment } from "../../../../model/interface/assignment";
import { IAssignmentActivity } from "../../../../model/interface/assignmentActivity";
import {
  IKeyValuePair,
  ISelectElement,
} from "../../../../model/interface/selectElement";
import DomainStandard from "../../../../model/teacher/domainStandard";
import QuestionStandard from "../../../../model/teacher/questionStandard";
import Standard from "../../../../model/teacher/standard";
import Constant from "../../../../utils/constant/constant";
import { getActivityTypeId, OrderByArray } from "../../../../utils/helper";
import Feedback from "../../../shared/feedback";
import Loader from "../../../shared/loader";
import QuestionPreview from "../shared/questionPreview/questionPreview";
interface VideoLessonProps {
  assignment: IAssignment;
  updateAssignment: (updatedAsignment: IAssignment) => void;
  isActivityUpdated: (activityUpdated: boolean) => void;
  canActivityUpdate: boolean;
}

function VideoLesson(props: VideoLessonProps) {
  const { assignment, updateAssignment, isActivityUpdated, canActivityUpdate } =
    props;
  const [domains, setDomains] = useState<Array<ISelectElement>>([]);
  const [videoLessonList, setVideoLessonList] = useState<
    Array<QuestionStandard>
  >([]);
  const [selectedQuestions, setSelectedQuestions] = useState<Array<number>>([]);
  const [showFeedbackPopup, setShowFeedbackPopup] = useState<boolean>(false);
  const [selectedDomains, setSelectedDomains] = useState<Array<ISelectElement>>(
    []
  );
  const [domainStandards, setDomainStandards] = useState<Array<DomainStandard>>(
    []
  );

  const [selectedQuestion, setselectedQuestion] = useState<number>(0);
  const [selectedStandardId, setSelectedStandardId] = useState<number>();
  const [domainStandardIds, setDomainStandardIds] = useState<
    Array<IKeyValuePair>
  >([]);
  const [togglePopup, setTogglePopup] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  const [open, setOpen] = useState<boolean>(false);
  const [activityPreviewDetail, setActivityPreviewDetail] = useState<{
    previewId: string;
    activityIdPreview: number;
    activityName: string;
    standardId: number;
  }>();
  const [loading, setLoading] = useState<boolean>(false);

  function handleFeedbackPopup(id: number, standardId: number) {
    setselectedQuestion(id);
    setSelectedStandardId(standardId);
    toggleFeedback(true);
  }

  function handleDomainChange(selectdItems: any) {
    setSelectedDomains(selectdItems);
    const selectedDomains = Array.prototype.map.call(
      selectdItems,
      (s) => s.value
    );

    bindStandards(selectedDomains.join(","));

    if (assignment.activities && assignment.activities.length > 0) {
      let allActivities: Array<IAssignmentActivity> | undefined =
        assignment.activities;
      if (allActivities) {
        allActivities = allActivities.filter((r) =>
          isActivityValidByDomain(r, selectedDomains)
        );
        assignment.activities = allActivities;
        updateAssignment(assignment);
      }
    }
  }

  function isActivityValidByDomain(
    assignmentActivity: IAssignmentActivity,
    domains: Array<unknown>
  ) {
    if (
      assignmentActivity.activityType !==
      Constant.AssignmentActivityType.VIDEOLESSON
    )
      return true;

    const exist = domains.includes(assignmentActivity.domainId);
    return exist;
  }

  function isActivityValidByStandard(
    assignmentActivity: IAssignmentActivity,
    standards: Array<unknown>
  ) {
    if (
      assignmentActivity.activityType !==
      Constant.AssignmentActivityType.VIDEOLESSON
    )
      return true;

    const exist = standards.includes(assignmentActivity.standardId?.toString());
    return exist;
  }

  function bindStandards(selectedDomains: string) {
    setLoading(true);
    getAllStandardByDomains(selectedDomains).then((response) => {
      setLoading(false);
      const standardsData = response.data.data;
      setDomainStandards(standardsData);
    });
  }

  function handleStandardChange(selectdItems: any, domainId: string) {
    const selectedStandards = Array.prototype.map
      .call(selectdItems, (s) => s.value)
      .join(",");

    const currentDomainStandard: IKeyValuePair = {
      key: domainId,
      value: selectedStandards,
    };

    let domainStandards = [
      ...domainStandardIds.filter(
        (r) => r.key.toString() !== domainId.toString()
      ),
      currentDomainStandard,
    ];

    setDomainStandardIds(domainStandards);

    let allStandards: Array<string> = [];
    domainStandards.forEach((r) => {
      allStandards.push(r.value);
    });
    bindVideoLessons(allStandards.join(","));

    if (assignment.activities && assignment.activities.length > 0) {
      let allActivities: Array<IAssignmentActivity> | undefined =
        assignment.activities;
      if (allActivities) {
        allActivities = allActivities.filter((r) =>
          isActivityValidByStandard(r, allStandards.join(",").split(","))
        );
        assignment.activities = allActivities;
        updateAssignment(assignment);
      }
    }
  }

  function bindVideoLessons(selectedStandards: string) {
    setLoading(true);
    getVideoLessonByStandards(selectedStandards, assignment.subjectId).then(
      (response) => {
        const videos = getUniqueVideos(response.data.data);
        setVideoLessonList(videos);
        setLoading(false);
      }
    );
  }

  function getUniqueVideos(videos: any) {
    const uniqueQuestions = new Set();
    const existingActivities = getExistingActivities();
    return videos?.filter((video) => {
      const questionInActivity = existingActivities?.find(activity => activity.questionId === video.questionId);
      const isValidQuestionStandard = questionInActivity == null 
        || questionInActivity.standardId === video.standardId;
      if (!uniqueQuestions.has(video.questionId) && isValidQuestionStandard) {
        uniqueQuestions.add(video.questionId);
        return true;
      }
      return false;
    });

  }

  function handleSelectedQuestion(
    questionId: number,
    questionText: string,
    domainId: string,
    standardId: string,
    learnosityIdEnglish: string,
    learnosityIdSpanish: string
  ) {
    let selectedQuestionList: Array<number> = new Array<number>();
    const questionExist = selectedQuestions.some(
      (r) => (r as number) === questionId
    );

    if (questionExist) {
      selectedQuestionList = selectedQuestions.filter(
        (r) => (r as number) !== questionId
      );
    } else {
      selectedQuestionList = [...selectedQuestions];
      selectedQuestionList.push(questionId);
    }

    setSelectedQuestions(selectedQuestionList);
    const keyValuePair: IKeyValuePair = {
      key: questionId.toString(),
      value: questionText,
    };
    updateActivity(
      keyValuePair,
      domainId,
      standardId,
      learnosityIdEnglish,
      learnosityIdSpanish
    );
  }

  function getDomains() {
    setLoading(true);
    getAllDomainsBySubject(assignment.subjectId).then((res) => {
      setLoading(false);
      const domains = res.data.data.map((values: any) => {
        return {
          label: values?.name,
          value: values?.domainId,
        };
      });
      setDomains(domains);
      setExistingValues(domains);
    });
  }

  function toggleFeedback(popupAction: boolean) {
    setShowFeedbackPopup(popupAction);
  }

  function updateActivity(
    keyValuePair: IKeyValuePair,
    domainId: string,
    standardId: string,
    learnosityIdEnglish: string,
    learnosityIdSpanish: string
  ) {
    isActivityUpdated(true);
    const existingActivity: IAssignmentActivity | undefined =
      assignment.activities?.filter(
        (r) =>
          r.activityType === Constant.AssignmentActivityType.VIDEOLESSON &&
          r.questionId?.toString() === keyValuePair.key
      )[0];
    if (existingActivity) {
      let allActivities: Array<IAssignmentActivity> | undefined =
        assignment.activities;
      if (allActivities) {
        allActivities = allActivities.filter(
          (r) =>
            (r.activityType === Constant.AssignmentActivityType.VIDEOLESSON &&
              r.questionId?.toString() === keyValuePair.key) === false
        );
        assignment.activities = allActivities;
      }
    } else {
      const activity: IAssignmentActivity = {
        assignmentActivityId: 0,
        assignmentId: assignment.assignmentId,
        activityTypeId: getActivityTypeId(
          Constant.AssignmentActivityType.VIDEOLESSON
        ),
        activityType: Constant.AssignmentActivityType.VIDEOLESSON,
        sequenceNumber: assignment.activities
          ? assignment.activities.length + 1
          : 1,
        questionId: keyValuePair.key,
        questionText: keyValuePair.value,
        domainId: domainId,
        standardId: standardId,
        learnosityIdEnglish: learnosityIdEnglish,
        learnosityIdSpanish: learnosityIdSpanish,
      };

      assignment.activities?.push(activity);
    }

    updateAssignment(assignment);
  }

  function getExistingActivities(): Array<IAssignmentActivity> | undefined  {
    return assignment.activities?.filter(
      (r) => r.activityType === Constant.AssignmentActivityType.VIDEOLESSON
    );
  }

  function setExistingValues(domains: Array<ISelectElement>) {
    const existingActivities = getExistingActivities();

    if (existingActivities && existingActivities.length > 0) {
      const existingQuestionIds: Array<number> = [];
      const domainStandards: Array<IKeyValuePair> = [];
      const existingDomainIds: Array<string> = [];
      let existingStandardIds: Array<string> = [];
      existingActivities.forEach((activity) => {
        if (activity.questionId) {
          existingQuestionIds.push(parseInt(activity.questionId));
        }
        if (
          activity.domainId &&
          !existingDomainIds.includes(activity.domainId)
        ) {
          existingDomainIds.push(activity.domainId);
        }
        if (
          activity.standardId &&
          !existingStandardIds.includes(activity.standardId)
        ) {
          existingStandardIds.push(activity.standardId.toString());
        }
      });

      const existingDomains: Array<ISelectElement> = domains.filter((r) =>
        existingDomainIds.includes(r.value)
      );

      setSelectedDomains(existingDomains);

      bindStandards(existingDomainIds.join(","));

      existingDomainIds.forEach((domainId) => {
        const keyValuePair: IKeyValuePair = {
          key: domainId,
          value: existingActivities
            .filter((r) => r.domainId === domainId)
            .map((s) => s.standardId)
            .join(","),
        };
        domainStandards.push(keyValuePair);
      });

      setDomainStandardIds(domainStandards);
      bindVideoLessons(existingStandardIds.join(","));
      setSelectedQuestions(existingQuestionIds);
    }
  }

  function getDomainStandards(domainId: string) {
    const standards: Array<ISelectElement> = new Array<ISelectElement>();
    const selectedStandards = domainStandardIds.filter(
      (r) => r.key === domainId
    );
    domainStandards
      .filter((r) => r.domainId === domainId)
      .forEach(function (domainStandard: DomainStandard) {
        domainStandard.standards.forEach(function (standard: Standard) {
          if (selectedStandards && selectedStandards.length > 0) {
            const standardIds = selectedStandards[0].value.split(",");
            if (
              standardIds.some((r) => r.toString() === standard.id.toString())
            ) {
              return;
            }
          }
          standards.push({
            label: `${standard.code} ${standard.name}`,
            value: standard.id,
          });
        });
      });

    return standards;
  }

  function getSelectedStandards(domainId: string) {
    let seletcedStandards: Array<string> = [];

    if (
      domainStandardIds &&
      domainStandardIds.some((r) => r.key === domainId)
    ) {
      seletcedStandards = domainStandardIds
        .filter((r) => r.key === domainId)[0]
        .value.split(",");
    }

    const standards: Array<ISelectElement> = new Array<ISelectElement>();

    domainStandards
      .filter((r) => r.domainId === domainId)
      .forEach(function (domainStandard: DomainStandard) {
        domainStandard.standards.forEach(function (standard: Standard) {
          if (seletcedStandards.includes(standard.id.toString())) {
            standards.push({
              label: `${standard.code} ${standard.name}`,
              value: standard.id.toString(),
            });
          }
        });
      });

    return standards;
  }

  function handleVideoClick(videoLesson: QuestionStandard) {
    setTogglePopup(!togglePopup);
    if (videoLesson.videoUrl) {
      setVideoUrl(videoLesson.videoUrl);
    }
  }

  function getDomainQuestions(domainId: string) {
    const data = videoLessonList.filter(
      (r) => r.domainId.toString() === domainId.toString()
    );
    return OrderByArray(data, "questionText");
  }

  const openModal = (
    learnosityReferenceId,
    activityId,
    activityName,
    standardId
  ) => {
    if (!learnosityReferenceId) {
      return false;
    }

    setOpen(true);
    setActivityPreviewDetail({
      previewId: learnosityReferenceId,
      activityIdPreview: activityId,
      activityName: activityName,
      standardId: standardId,
    });
  };

  const popUpClose = (value) => {
    setOpen(value);
    setActivityPreviewDetail(undefined);
  };

  useEffect(() => {
    getDomains();
  }, []);

  return (
    <Fragment>
      {loading && <Loader />}

      {activityPreviewDetail && (
        <QuestionPreview
          modal={openModal}
          show={open}
          changeValue={popUpClose}
          itemRef={activityPreviewDetail.previewId}
          questionIdPreview={activityPreviewDetail.activityIdPreview}
          activityName={activityPreviewDetail.activityName}
          standardId={activityPreviewDetail.standardId}
        ></QuestionPreview>
      )}

      <Modal
        isOpen={togglePopup}
        onClose={() => setTogglePopup(false)}
        className="bg-white rounded-lg p-2"
      >
        <ModalBody>
          <ReactPlayer
            onContextMenu={(e) => e.preventDefault()}
            url={videoUrl}
            className="react-player"
            width="100%"
            height="600px"
            controls={true}
            config={{
              file: {
                attributes: {
                  controlsList: "nodownload",
                },
              },
            }}
          />
        </ModalBody>
      </Modal>
      {showFeedbackPopup && (
        <Feedback
          toggleFeedback={toggleFeedback}
          referenceType={Constant.FeedbackReferenceType.VIDEO}
          referenceId={selectedQuestion}
          subjectId={assignment.subjectId}
          standardId={selectedStandardId}
        />
      )}
      <div className="min-w-0 block lg:col-span-9 xl:col-span-10 w-full sm:px-5">
        <div className="bg-gray-100  w-full mb-5 rounded-lg p-5 h-full">
          <div className="mb-0">
            <div className="grid xl:grid-cols-2">
              <div>
                <label className="block text-sm text-gray-500 mb-1">
                  Domain
                </label>
                <Select
                  value={selectedDomains}
                  options={domains}
                  isMulti={true}
                  closeMenuOnSelect={false}
                  onChange={handleDomainChange}
                  className={`${
                    canActivityUpdate
                      ? ""
                      : "opacity-50 cursor-not-allowed pointer-events-none"
                  }`}
                />
              </div>
            </div>

            {selectedDomains.map((selectedDomain, index) => {
              return (
                <div className=" mt-4" key={index}>
                  <div className="border-b border-gray-300">
                    <label className="block text-sm font-medium text-gray-500">
                      {selectedDomain.label}
                    </label>
                  </div>
                  <div className="grid xl:grid-cols-2">
                    <div className="mt-2">
                      <label className="block text-sm text-gray-500 mb-1">
                        Standard
                      </label>
                      <Select
                        value={getSelectedStandards(selectedDomain.value)}
                        options={getDomainStandards(selectedDomain.value)}
                        isMulti={true}
                        closeMenuOnSelect={false}
                        onChange={(e) =>
                          handleStandardChange(e, selectedDomain.value)
                        }
                        className={`${
                          canActivityUpdate
                            ? ""
                            : "opacity-50 cursor-not-allowed pointer-events-none"
                        }`}
                      />
                    </div>
                  </div>
                  <div className="mt-6 mb-6 bg-white py-3 px-5 shadow hover:shadow-lg">
                    <div className="mb-4 shadow">
                      <table className="table w-full ">
                        <thead className="mb-5 border border border-light-violet">
                          <tr className="bg-light-violet  text-white py-3 px-3">
                            <th className="text-left p-3 text-sm font-normal"></th>
                            <th className="text-left p-3 text-sm font-normal">
                              Title
                            </th>
                            <th className="text-left p-3  text-sm font-normal">
                              Standard(s)
                            </th>
                            <th className="text-center  p-3 text-sm font-normal w-48">
                              Preview
                            </th>
                            <th className="text-center  p-3 text-sm font-normal w-48">
                              Feedback
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {getDomainQuestions(selectedDomain.value).map(
                            (videoLesson, index) => {
                              return (
                                <tr
                                  className={`${
                                    selectedQuestions.some(
                                      (r) =>
                                        (r as number) === videoLesson.questionId
                                    )
                                      ? "bg-light-violet/20 border border-light-violet"
                                      : ""
                                  } hover:bg-light-violet/20 transition ease-in-out duration-300 ${
                                    index % 2 === 0
                                      ? "bg-white"
                                      : "bg-light-violet/10"
                                  } ${
                                    canActivityUpdate
                                      ? ""
                                      : " opacity-50 cursor-not-allowed"
                                  }`}
                                >
                                  <td className=" mt-5 px-3 py-6 w-10">
                                    <input
                                      id="styled-checkbox-2"
                                      type="checkbox"
                                      className={`checkbox ${
                                        canActivityUpdate
                                          ? ""
                                          : "pointer-events-none"
                                      }`}
                                      checked={selectedQuestions.some(
                                        (r) =>
                                          (r as number) ===
                                          videoLesson.questionId
                                      )}
                                      onChange={() =>
                                        handleSelectedQuestion(
                                          videoLesson.questionId,
                                          videoLesson.questionText,
                                          selectedDomain.value,
                                          videoLesson.standardId.toString(),
                                          videoLesson.englishQuestionReferenceId,
                                          videoLesson.spanishQuestionReferenceId
                                        )
                                      }
                                    />
                                    <label className="checkbox-label"></label>
                                  </td>
                                  <td className="text-sm  px-3 py-6 mt-5  p-3">
                                    <a
                                      href="#!"
                                      onClick={() =>
                                        openModal(
                                          videoLesson.englishQuestionReferenceId,
                                          videoLesson.questionId,
                                          `${videoLesson.questionText}`,
                                          videoLesson.standardId
                                        )
                                      }
                                      className="text-primary-blue border-b border-primary-blue"
                                    >
                                      {videoLesson.questionText}
                                    </a>
                                  </td>
                                  <td className="text-sm  px-3 p9y-6 mt-5  p-3">
                                    {videoLesson.standardName}
                                    {videoLesson.standards &&
                                      videoLesson.standards.length > 0 && (
                                        <div className="bg-gray-100 border px-2 py-1 mt-1 rounded-md">
                                          {videoLesson.standards &&
                                            videoLesson.standards
                                              .split(",")
                                              .map((standard, index) => {
                                                return (
                                                  <span
                                                    key={index}
                                                    className="block"
                                                  >
                                                    {standard}
                                                  </span>
                                                );
                                              })}
                                        </div>
                                      )}
                                  </td>
                                  <td className="text-sm  px-3 py-6 mt-5  p-3 relative  w-48 text-right">
                                    <img
                                      onClick={() =>
                                        handleVideoClick(videoLesson)
                                      }
                                      className="cursor-pointer hover:opacity-80 rounded-xl overflow-hidden w-full"
                                      src={videoLesson.thumbnail}
                                      alt=""
                                    />
                                    <div className="items-center text-gray-400 text-xs">
                                      {format(
                                        1000 * videoLesson.lengthInSeconds
                                      )}
                                      s
                                    </div>
                                  </td>
                                  <td className="text-sm mt-5  p-3">
                                    <div className="flex items-center justify-center">
                                      <FeedbackIcon
                                        className={`${
                                          canActivityUpdate
                                            ? "cursor-pointer"
                                            : "pointer-events-none"
                                        } w-5  text-gray-500 hover:text-gray-700`}
                                        onClick={() =>
                                          handleFeedbackPopup(
                                            videoLesson.questionId,
                                            videoLesson.standardId
                                          )
                                        }
                                      />
                                    </div>
                                  </td>
                                </tr>
                              );
                            }
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default VideoLesson;

import { Fragment, useEffect, useState } from "react";
import { DownArrowIcon, UpArrow } from "../../../../../../assets/icons";
import { isAllowHyperlinksToStudentResult } from "../../../../../../utils/assessmentHelper";
import routeConstant from "../../../../../../utils/constant/routeConstant";
import { getScoreLabel } from "../../../../../../utils/scoreHelper";
import { connect } from "react-redux";
import constant from "../../../../../../utils/constant/constant";

function GradeBookByAssignment(props: any) {
  const [gradeBook, setGradeBook] = useState<any>(props.data);

  const [isAssignedRemediationFilter, setIsAssignedRemediationFilter] =
    useState<boolean>(false);
  const [isRetryScoresFilter, setIsRetryScoresFilter] =
    useState<boolean>(false);

  const viewByScore = 0;
  const viewByPoints = 1;
  const viewReportHeight = "700px";

  const handleHorizontalCollapse = (entity, value, entityType) => {
    entity.isCollapse = value;

    if (entityType === "ASSIGNMENT") {
      entity.activities.forEach((activity) => {
        gradeBook.visibility[activity.index] = !value;
      });
    }

    let _gradeBook = {
      ...gradeBook,
    };

    setGradeBook(_gradeBook);
  };

  const handleResultReportClick = (
    assignmentId,
    activityId,
    type,
    activityName,
    subjectId
  ) => {
    const pathname = `${routeConstant.GROUPED_RESULT_REPORTS}?assignmentId=${assignmentId}&activityId=${activityId}&redirectedFrom=${constant.RedirectedFrom.GRADEBOOK}`+
        `&subjectId=${subjectId}&schoolYearId=${props.profile.currentSchoolYear.schoolYearId}`;

      window.open(pathname, "_blank");
  };

  const handleStudentResultViewClick = (
    assignmentId,
    activityId,
    userId,
    gradeId,
    sessionId
  ) => {
    const pathname =
      routeConstant.TeacherRoutes.StudentAssignmentResult.replace(
        ":assignmentId",
        assignmentId
      )
        .replace(":assignmentActivityId", activityId)
        .replace(":userId", userId)
        .replace(":gradeId", gradeId ?? 0)
        .replace(":sessionId?", sessionId);

    window.open(pathname, "_blank");
  };

  const roundOffScore = (
    totalPointsPossible: number,
    totalPointsEarned: number
  ) => {
    if (!totalPointsEarned) return 0;
    return Math.round((totalPointsEarned / totalPointsPossible) * 100);
  };

  const calculateGroupAssignmentAverage = (student, assignment) => {
    let totalGroupAssignmentScore: any = 0;
    totalGroupAssignmentScore =
      student.groupAssignmentAverage?.filter(
        (a) => a.index === assignment.index
      )[0]?.score ?? 0;

    if (
      student.activitiesCompleted[assignment.index] ===
      assignment.activities.length
    ) {
      //all activities completed in a group assignment
      return totalGroupAssignmentScore === 0
        ? getCellValue("-")
        : totalGroupAssignmentScore.toFixed() + "%";
    } else {
      return (
        student.activitiesCompleted[assignment.index] +
        "/" +
        assignment.activities.length
      );
    }
  };

  const calculateGroupAssignmentFraction = (student, assignment) => {
    let totalGroupAssignmentScore: any = 0;
    totalGroupAssignmentScore =
      student.groupAssignmentAverage?.filter(
        (a) => a.index === assignment.index
      )[0]?.score ?? 0;
    return totalGroupAssignmentScore === 0
      ? getCellValue("-")
      : totalGroupAssignmentScore.toFixed() + "/" + "100";
  };

  const calculateActivityScore = (student, activity) => {
    let score = 0;
    if (student.pointsPossible[activity.index] > 0) {
      if (
        isRetryScoresFilter &&
        student.retryPointsEarned[activity.index] !== null
      ) {
        score = roundOffScore(
          student.pointsPossible[activity.index],
          student.correctPointsEarned[activity.index]
        );
      } else {
        score = roundOffScore(
          student.pointsPossible[activity.index],
          student.pointsEarned[activity.index]
        );
      }
    }
    return score.toFixed() + "%";
  };
  const getFractionActivity = (student, activity) => {
    if (isRetryScoresFilter) {
      if (student.retryPointsEarned[activity.index] !== null) {
        return (
          getScoreLabel(student.correctPointsEarned[activity.index]) +
          "/" +
          getScoreLabel(student.pointsPossible[activity.index])
        );
      } else {
        return "";
      }
    } else {
      return (
        getScoreLabel(student.pointsEarned[activity.index]) +
        "/" +
        getScoreLabel(student.pointsPossible[activity.index])
      );
    }
  };
  const getFractionAssignment = (student, assignment) => {
    if (isRetryScoresFilter) {
      if (student.retryPointsEarned[assignment.activities[0].index] != null) {
        return (
          getScoreLabel(
            student.correctPointsEarned[assignment.activities[0].index]
          ) +
          "/" +
          getScoreLabel(student.pointsPossible[assignment.activities[0].index])
        );
      } else {
        return "";
      }
    } else {
      return (
        getScoreLabel(student.pointsEarned[assignment.activities[0].index]) +
        "/" +
        getScoreLabel(student.pointsPossible[assignment.activities[0].index])
      );
    }
  };

  const calculateScoreSingleActivity = (student: any, assignment: any) => {
    if (
      isRetryScoresFilter &&
      student.retryPointsEarned[assignment.activities[0].index] != null
    ) {
      return (
        roundOffScore(
          student.pointsPossible[assignment.activities[0].index],
          student.correctPointsEarned[assignment.activities[0].index]
        ).toFixed() + "%"
      );
    } else {
      return (
        roundOffScore(
          student.pointsPossible[assignment.activities[0].index],
          student.pointsEarned[assignment.activities[0].index]
        ).toFixed() + "%"
      );
    }
  };
  const getMaxCharacterCount = (gradeBook: any) => {
    return Math.max(
      ...gradeBook?.assignments.map((a) => {
        return a.name.length;
      })
    );
  };

  const getPaddingTop = (gradeBook: any) => {
    const charCount = getMaxCharacterCount(gradeBook);
    return (charCount > 50 ? 50 : charCount) * 6.5;
  };

  const getCellValue = (value: string) => {
    if (isRetryScoresFilter) return "   ";
    return value;
  };

  const isGroupActivity = (index: number) => {
    const groupIndexes: Array<Number> = [];
    let nextGroupIndex = 0;
    gradeBook?.assignments?.forEach((element: any) => {
      if (element.activities.length > 1) {
        groupIndexes.push(nextGroupIndex)
      };
      nextGroupIndex += element.activities.length + 1;
    });
    const isGroupActivity = groupIndexes.includes(index);
    return isGroupActivity;
  }

  useEffect(() => {
    setIsAssignedRemediationFilter(props.isAssignedRemediationFilter);
  }, [props.isAssignedRemediationFilter]);
  useEffect(() => {
    setIsRetryScoresFilter(props.isRetryScoresFilter);
  }, [props.isRetryScoresFilter]);

  return (
    <div
      className={`pt-56 overflow-x-auto`}
      style={{
        paddingTop: getPaddingTop(gradeBook),
        height: viewReportHeight,
      }}
    >
      {gradeBook && (
        <table className="">
          <thead className={`sticky bg-white`} style={{ top: 0, zIndex: 12 }}>
            <tr className="bg-white">
              <th className="relative py-1 text-left pl-2 bg-white sticky left-0 z-[11]">
                <span className="text-primary-violet text-base font-semibold">
                  Student
                </span>
                <div
                  className="z-[9]"
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: getPaddingTop(gradeBook),
                    left: "0",
                    top: -getPaddingTop(gradeBook),
                    background: "white",
                  }}
                ></div>
              </th>
              <th className="bg-white sticky left-[144px] z-[11]">
                <p className="text-primary-violet text-sm font-bold w-36">
                  Student ID
                </p>
                <div
                  className="z-[9]"
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: getPaddingTop(gradeBook),
                    left: "0",
                    top: -getPaddingTop(gradeBook),
                    background: "white",
                  }}
                ></div>
              </th>
              {!isAssignedRemediationFilter && !isRetryScoresFilter && (
                <th className="bg-white sticky left-[290px] z-[11]">
                  <p className="text-primary-violet text-sm font-bold w-60">
                    Average Assignment Score
                  </p>
                  <div
                    className="z-[9]"
                    style={{
                      position: "absolute",
                      width: "100%",
                      height: getPaddingTop(gradeBook),
                      left: "0",
                      top: -getPaddingTop(gradeBook),
                      background: "white",
                    }}
                  ></div>
                </th>
              )}

              {gradeBook?.assignments.map((assignment, assignmentIndex) => {
                const assignmentName =
                  assignment.name.length > 50
                    ? assignment.name.substring(0, 50) + "..."
                    : assignment.name;
                return (
                  <>
                    <th className="relative">
                      <p className="w-[271px] absolute z-[10] top-[-5.25rem] left-[-0.75rem] transform -rotate-45 whitespace-nowrap flex items-center text-sm font-bold">
                        {assignment.activities.length > 1 && assignmentName}

                        {assignment.activities.length === 1 &&
                          isAllowHyperlinksToStudentResult(
                            props.userRoleId,
                            assignment.activities[0].canAssignAssessment,
                            assignment.activities[0].canViewAssessment,
                            assignment.activities[0].assessmentSharedTypeId,
                            assignment.activities[0].assessmentCreatedByUserId,
                            props.userId
                          ) && assignment.activitiesCompleted > 0 && (
                            <a
                              href="#!"
                              onClick={() =>
                                handleResultReportClick(
                                  assignment.id,
                                  assignment.activities[0].id,
                                  "ASSIGNMENT",
                                  assignment.name,
                                  assignment.subjectId
                                )
                              }
                            >
                              {assignmentName}
                            </a>
                          )}

                        {assignment.activities.length === 1 &&
                          (!isAllowHyperlinksToStudentResult(
                            props.userRoleId,
                            assignment.activities[0].canAssignAssessment,
                            assignment.activities[0].canViewAssessment,
                            assignment.activities[0].assessmentSharedTypeId,
                            assignment.activities[0].assessmentCreatedByUserId,
                            props.userId
                          ) || assignment.activitiesCompleted === 0)  && <span>{assignmentName}</span>}

                        <div
                          onClick={() =>
                            handleHorizontalCollapse(
                              assignment,

                              !assignment.isCollapse,

                              "ASSIGNMENT"
                            )
                          }
                        >
                          {assignment.activities.length > 1 &&
                            !assignment.isCollapse && (
                              <UpArrow className="w-5 mr-2 cursor-pointer text-blue-600" />
                            )}

                          {assignment.activities.length > 1 &&
                            assignment.isCollapse && (
                              <DownArrowIcon className="w-5 mr-2 cursor-pointer text-blue-600" />
                            )}
                        </div>
                      </p>
                      <div
                        className="z-[9]"
                        style={{
                          position: "absolute",
                          width: "100%",
                          height: getPaddingTop(gradeBook),
                          left: "0",
                          top: -getPaddingTop(gradeBook),
                          background: "white",
                        }}
                      ></div>
                    </th>

                    {!assignment.isCollapse &&
                      assignment.activities.length > 1 &&
                      assignment.activities.map((activity, activityIndex) => (
                        <>
                          <th className="relative">
                            <p className="w-[271px] absolute z-[10] top-[-5.25rem] left-[-0.75rem] transform -rotate-45 whitespace-nowrap flex items-center text-sm font-medium">
                              {isAllowHyperlinksToStudentResult(
                                props.userRoleId,
                                activity.canAssignAssessment,
                                activity.canViewAssessment,
                                activity.assessmentSharedTypeId,
                                activity.assessmentCreatedByUserId,
                                props.userId
                              ) && assignment.activitiesCompleted > 0 && (
                                <a
                                  href="#!"
                                  onClick={() =>
                                    handleResultReportClick(
                                      assignment.id,
                                      activity.id,
                                      "ASSIGNMENT",
                                      activity.name,
                                      assignment.subjectId
                                    )
                                  }
                                >
                                  {activity.name}
                                </a>
                              )}
                              {(!isAllowHyperlinksToStudentResult(
                                props.userRoleId,
                                activity.canAssignAssessment,
                                activity.canViewAssessment,
                                activity.assessmentSharedTypeId,
                                activity.assessmentCreatedByUserId,
                                props.userId
                              ) || assignment.activitiesCompleted === 0) && <span> {activity.name}</span>}
                            </p>
                            <div
                              className="z-[9]"
                              style={{
                                position: "absolute",
                                width: "100%",
                                height: getPaddingTop(gradeBook),
                                left: "0",
                                top: -getPaddingTop(gradeBook),
                                background: "white",
                              }}
                            ></div>
                          </th>
                        </>
                      ))}
                  </>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {gradeBook &&
              gradeBook.students.length > 0 &&
              gradeBook.students.map((student, index) => {
                return (
                  <Fragment>
                    <tr
                      className={
                        index % 2 === 0 ? "bg-primary-violet/10" : "bg-white"
                      }
                    >
                      <td
                        className="text-left p-3 text-sm font-normal sticky left-0 z-[9]"
                        style={{
                          background: index % 2 === 0 ? "#e9f3f9" : "white",
                        }}
                      >
                        <div className="w-[120px]">
                          <a 
                            target="_blank" 
                            className="text-blue-500" 
                            href={`${routeConstant.TeacherRoutes.StudentReportCard}?schoolId=${props.userContext.schoolId}&studentId=${student.id}`} rel="noreferrer"
                          >{student.name}</a>
                        </div>
                      </td>
                      <td
                        className="text-center p-3 text-sm font-normal sticky left-[144px] z-[9]"
                        style={{
                          background: index % 2 === 0 ? "#e9f3f9" : "white",
                        }}
                      >
                        <div className="whitespace-nowrap">
                          {!student.studentId || student.studentId === ""
                            ? "-"
                            : student.studentId}
                        </div>
                      </td>
                      {!isAssignedRemediationFilter && !isRetryScoresFilter && (
                        <td
                          className="text-center p-3 text-sm font-normal sticky left-[290px] z-[9]"
                          style={{
                            background: index % 2 === 0 ? "#e9f3f9" : "white",
                          }}
                        >
                          <div className="whitespace-nowrap">
                            {props.viewBy === viewByScore &&
                              student.totalPointsPossible > 0 && (
                                <>
                                  {(
                                    student["totalScore"] /
                                    student["totalAssignmentCompleted"]
                                  ).toFixed()}
                                  %
                                </>
                              )}

                            {props.viewBy === viewByPoints &&
                              student.totalPointsPossible > 0 && (
                                <>
                                  {student.totalPointsEarned % 1 == 0
                                    ? student.totalPointsEarned.toFixed()
                                    : student.totalPointsEarned % 1 == 0.5
                                    ? student.totalPointsEarned.toFixed(1)
                                    : student.totalPointsEarned.toFixed(2)}
                                  /{""}
                                  {student.totalPointsPossible.toFixed(0)}
                                </>
                              )}

                            {student.totalPointsPossible === 0 && (
                              <>
                                <span className="text-gray-400">
                                  {getCellValue("N/A")}
                                </span>
                              </>
                            )}
                          </div>
                        </td>
                      )}

                      {gradeBook.assignments.map(
                        (assignment, assignmentIndex) => (
                          <>
                            {/* Assignment name */}

                            <td className="text-center p-3 text-sm font-normal whitespace-nowrap">
                              {!student.isAssigned[assignment.index] && (
                                <>
                                  <span className="text-gray-400">
                                    {getCellValue("N/A")}
                                  </span>
                                </>
                              )}

                              {assignment.activities.length > 1 && (
                                <>
                                  {student.isAssigned[assignment.index] && (
                                    <>
                                      {props.viewBy === viewByScore && (
                                        <span className="font-bold">
                                          {calculateGroupAssignmentAverage(
                                            student,
                                            assignment
                                          )}
                                        </span>
                                      )}
                                      {props.viewBy === viewByPoints &&
                                        calculateGroupAssignmentFraction(
                                          student,
                                          assignment
                                        )}
                                    </>
                                  )}
                                </>
                              )}

                              {assignment.activities.length === 1 && (
                                <>
                                  {" "}
                                  {student.activitiesCompleted[
                                    assignment.activities[0].index
                                  ] === 0 &&
                                    student.isAssigned[
                                      assignment.activities[0].index
                                    ] && <>{getCellValue("-")}</>}
                                  {isAllowHyperlinksToStudentResult(
                                    props.userRoleId,
                                    assignment.activities[0]
                                      .canAssignAssessment,
                                    assignment.activities[0].canViewAssessment,
                                    assignment.activities[0]
                                      .assessmentSharedTypeId,
                                    assignment.activities[0]
                                      .assessmentCreatedByUserId,
                                    props.userId
                                  ) && (
                                    <a
                                      href="#!"
                                      className={`${
                                        student.completionStatus[
                                          assignment.activities[0].index
                                        ] === 1
                                          ? "text-blue-500"
                                          : student.completionStatus[
                                              assignment.activities[0].index
                                            ] === 2
                                          ? "text-site-pink"
                                          : ""
                                      }  `}
                                      onClick={() =>
                                        handleStudentResultViewClick(
                                          assignment.id,
                                          assignment.activities[0].id,
                                          student.id,
                                          student.gradeId,
                                          student.sessionId[
                                            assignment.activities[0].index
                                          ]
                                        )
                                      }
                                    >
                                      {student.activitiesCompleted[
                                        assignment.activities[0].index
                                      ] === 1 &&
                                        props.viewBy === viewByScore && (
                                          <>
                                            {student.questionsUngraded[
                                              assignment.activities[0].index
                                            ] > 0
                                              ? getCellValue("NG")
                                              : student.pointsPossible[
                                                  assignment.activities[0].index
                                                ] > 0
                                              ? calculateScoreSingleActivity(
                                                  student,
                                                  assignment
                                                )
                                              : getCellValue("-")}
                                          </>
                                        )}

                                      {student.activitiesCompleted[
                                        assignment.activities[0].index
                                      ] === 1 &&
                                        props.viewBy === viewByPoints && (
                                          <>
                                            {student.questionsUngraded[
                                              assignment.activities[0].index
                                            ] > 0
                                              ? getCellValue("NG")
                                              : getFractionAssignment(
                                                  student,
                                                  assignment
                                                )}
                                          </>
                                        )}
                                    </a>
                                  )}
                                  {!isAllowHyperlinksToStudentResult(
                                    props.userRoleId,
                                    assignment.activities[0]
                                      .canAssignAssessment,
                                    assignment.activities[0].canViewAssessment,
                                    assignment.activities[0]
                                      .assessmentSharedTypeId,
                                    assignment.activities[0]
                                      .assessmentCreatedByUserId,
                                    props.userId
                                  ) && (
                                    <span>
                                      {student.activitiesCompleted[
                                        assignment.activities[0].index
                                      ] === 1 &&
                                        props.viewBy === viewByScore && (
                                          <>
                                            {student.questionsUngraded[
                                              assignment.activities[0].index
                                            ] > 0
                                              ? getCellValue("NG")
                                              : student.pointsPossible[
                                                  assignment.activities[0].index
                                                ] > 0
                                              ? calculateScoreSingleActivity(
                                                  student,
                                                  assignment
                                                )
                                              : getCellValue("-")}
                                          </>
                                        )}

                                      {student.activitiesCompleted[
                                        assignment.activities[0].index
                                      ] === 1 &&
                                        props.viewBy === viewByPoints && (
                                          <>
                                            {student.questionsUngraded[
                                              assignment.activities[0].index
                                            ] > 0
                                              ? getCellValue("NG")
                                              : getFractionAssignment(
                                                  student,
                                                  assignment
                                                )}
                                          </>
                                        )}
                                    </span>
                                  )}
                                </>
                              )}
                            </td>

                            {/* Activities for the assignment*/}

                            {!assignment.isCollapse &&
                              assignment.activities.length > 1 &&
                              assignment.activities.map(
                                (activity, activityIndex) => (
                                  <td className="text-center p-3 text-sm font-normal whitespace-nowrap">
                                    {isAllowHyperlinksToStudentResult(
                                      props.userRoleId,
                                      activity.canAssignAssessment,
                                      activity.canViewAssessment,
                                      activity.assessmentSharedTypeId,
                                      activity.assessmentCreatedByUserId,
                                      props.userId
                                    ) && (
                                      <a
                                        href="#!"
                                        className={`${
                                          student.completionStatus[
                                            activity.index
                                          ] === 1
                                            ? "text-blue-500"
                                            : student.completionStatus[
                                                activity.index
                                              ] === 2
                                            ? "text-site-pink"
                                            : ""
                                        }`}
                                        onClick={() =>
                                          handleStudentResultViewClick(
                                            assignment.id,
                                            activity.id,
                                            student.id,
                                            student.gradeId,
                                            student.sessionId[activity.index]
                                          )
                                        }
                                      >
                                        {student.activitiesCompleted[
                                          activity.index
                                        ] === 1 &&
                                          props.viewBy === viewByScore && (
                                            <>
                                              {student.questionsUngraded[
                                                activity.index
                                              ] > 0
                                                ? getCellValue("NG")
                                                : calculateActivityScore(
                                                    student,
                                                    activity
                                                  )}
                                            </>
                                          )}
                                        {student.activitiesCompleted[
                                          activity.index
                                        ] === 1 &&
                                          props.viewBy === viewByPoints && (
                                            <>
                                              {student.questionsUngraded[
                                                activity.index
                                              ] > 0
                                                ? getCellValue("NG")
                                                : getFractionActivity(
                                                    student,
                                                    activity
                                                  )}
                                            </>
                                          )}
                                      </a>
                                    )}

                                    {!isAllowHyperlinksToStudentResult(
                                      props.userRoleId,
                                      activity.canAssignAssessment,
                                      activity.canViewAssessment,
                                      activity.assessmentSharedTypeId,
                                      activity.assessmentCreatedByUserId,
                                      props.userId
                                    ) && (
                                      <span>
                                        {student.activitiesCompleted[
                                          activity.index
                                        ] === 1 &&
                                          props.viewBy === viewByScore && (
                                            <>
                                              {student.questionsUngraded[
                                                activity.index
                                              ] > 0
                                                ? getCellValue("NG")
                                                : calculateActivityScore(
                                                    student,
                                                    activity
                                                  )}
                                            </>
                                          )}
                                        {student.activitiesCompleted[
                                          activity.index
                                        ] === 1 &&
                                          props.viewBy === viewByPoints && (
                                            <>
                                              {student.questionsUngraded[
                                                activity.index
                                              ] > 0
                                                ? getCellValue("NG")
                                                : getFractionActivity(
                                                    student,
                                                    activity
                                                  )}
                                            </>
                                          )}
                                      </span>
                                    )}
                                    {student.activitiesCompleted[
                                      activity.index
                                    ] === 0 &&
                                      student.isAssigned[activity.index] && (
                                        <>{getCellValue("-")}</>
                                      )}

                                    {!student.isAssigned[activity.index] && (
                                      <>
                                        {" "}
                                        <span className="text-gray-400">
                                          {getCellValue("N/A")}
                                        </span>
                                      </>
                                    )}
                                  </td>
                                )
                              )}
                          </>
                        )
                      )}
                    </tr>
                  </Fragment>
                );
              })}

            {!isAssignedRemediationFilter && !isRetryScoresFilter && (
              <tr className="bg-primary-violet text-white">
                <td className="text-left p-3 text-sm font-normal sticky left-0 z-[9] bg-primary-violet">
                  Average
                </td>
                <td className="text-center p-3 text-sm font-normal sticky left-[135px] z-[9] bg-primary-violet"></td>
                <td className="text-center p-3 text-sm font-normal sticky left-[200px] z-[9] bg-primary-violet"></td>

                {gradeBook.averageScore.length > 0 &&
                  gradeBook.averageScore.map((score, index) => {
                    return (
                      <>
                        {gradeBook.visibility[index] && (
                          <td className="text-center p-3 text-sm font-normal whitespace-nowrap bg-primary-violet">
                            {isGroupActivity(index) ? "" : `${score.score}%`}
                          </td>
                        )}
                      </>
                    );
                  })}
              </tr>
            )}
            {isRetryScoresFilter && (
              <tr className="bg-primary-violet text-white">
                <td className="text-left p-3 text-sm font-normal sticky left-0 z-[9] bg-primary-violet">
                  Average
                </td>
                <td className="text-center p-3 text-sm font-normal sticky left-[135px] z-[9] bg-primary-violet"></td>
                {gradeBook.averageScore.length > 0 &&
                  gradeBook.averageScore.map((score, index) => {
                    return (
                      <>
                        {gradeBook.visibility[index] && (
                          <td className="text-center p-3 text-sm font-normal whitespace-nowrap bg-primary-violet">
                            {score.score}%
                          </td>
                        )}
                      </>
                    );
                  })}
              </tr>
            )}
          </tbody>
        </table>
      )}
    </div>
  );
}

export default connect((state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile
  };
})(GradeBookByAssignment)

export class UrlParams {
  constructor(
    public userId: string = "",
    public roleId: string = "",
    public schoolId: string = "",
    public userName: string = "",
    public password: string = "",
    public displayName: string = "",
    public avatar: string = ""
  ) {}
}

import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { getUrlParams } from "../../../../utils/helper";
import Permissions from "../../../../utils/permissions";
import AllowedTo from "../../../shared/rbac";
import AccessDenied from "../../../shared/accessDenied";
import {
  CancelIcon,
  FeedbackIcon,
  KeyIcon,
} from "../../../../assets/icons/index";
import {
  getWorksheetsBySubject,
  previewWorksheet,
} from "../../../../api/teacher/classActivity";
import Loader from "../../../shared/loader";
import Breadcrumb from "../../teacher/shared/breadcrumb";
import { IBreadcrumb } from "../../../../model/common/breadcrumb";
import RouteConstant from "../../../../utils/constant/routeConstant";
import Feedback from "../../../shared/feedback";
import Constant from "../../../../utils/constant/constant";
import { useTranslation } from "react-i18next";
import InfoMessage from "../../../shared/infoMessage";

function ClassActivity(props) {
  const { t } = useTranslation();
  const [selectedWorksheet, setSelectedWorksheet] = useState<number>(0);
  const [showFeedbackPopup, setShowFeedbackPopup] = useState<boolean>(false);
  const subjectId = getUrlParams()?.subjectId || undefined;
  const subjectName = getUrlParams()?.name || undefined;
  const [worksheetDeails, setWorksheetDeails] = useState<any>();
  const [viewBy, setViewBy] = useState<string>("Outline");
  const [title, setTitle] = useState<string>("");
  const [selectedStandardId, setSelectedStandardId] = useState<number>();
  const [loading, setLoading] = useState<boolean>(false);
  const debouncedSearchTerm = useDebounce(title, 500);
  const selectedViewBy =
    "flex text-white no-underline  hover:text-white hover:bg-opacity-90  bg-teal-light py-1 px-2 uppercase font-semibold shadow-xl rounded-md";
  const unSelectedViewBy =
    "flex text-gray-500 uppercase items-center px-2 group";

  function loadWorksheets() {
    setLoading(true);
    getWorksheetsBySubject(subjectId, title).then((res) => {
      setWorksheetDeails(res.data);
      setLoading(false);
    });
  }

  function downloadWorksheet(worksheetId: number, fileName: string) {
    if (fileName) {
      setLoading(true);
      previewWorksheet(worksheetId, fileName).then((response) => {
        setLoading(false);
        const fileURL = response.data;
        const link = document.createElement("a");
        link.href = fileURL;
        link.setAttribute("target", "_blank");
        document.body.appendChild(link);
        link.click();
      });
    }
  }

  function handleSearchOnChange(e: any) {
    setTitle(e.target.value || "");
  }

  function selectViewBy(value: string) {
    setViewBy(value);
  }

  const filterHandler = (e) => {
    e.preventDefault();
    setWorksheetDeails([]);
    loadWorksheets();
  };

  useEffect(() => {
    setLoading(false);
    loadWorksheets();
  }, []);

  useEffect(() => {
    setLoading(false);
    loadWorksheets();
  }, [debouncedSearchTerm]);

  function handleFeedbackPopup(id: number, standardId: number) {
    setSelectedWorksheet(id);
    setSelectedStandardId(standardId > 0 ? standardId : undefined);
    toggleFeedback(true);
  }

  function toggleFeedback(popupAction: boolean) {
    setShowFeedbackPopup(popupAction);
  }

  function useDebounce(value, delay) {
    // State and setters for debounced value
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(
      () => {
        // Update debounced value after delay
        const handler = setTimeout(() => {
          setDebouncedValue(value);
        }, delay);
        // Cancel the timeout if value changes (also on delay change or unmount)
        // This is how we prevent debounced value from updating if value is changed ...
        // .. within the delay period. Timeout gets cleared and restarted.
        return () => {
          clearTimeout(handler);
        };
      },
      [value, delay] // Only re-call effect if value or delay changes
    );
    return debouncedValue;
  }

  const breadcrumbItems = () => {
    const items: Array<IBreadcrumb> = [
      { name: t("Breadcrumb.Home"), url: RouteConstant.ROUTE_DASHBOARD },
      {
        name: t("Breadcrumb.InstructionalResources"),
        url: "",
      },
      {
        name: t("Breadcrumb.Subjects"),
        url: RouteConstant.TeacherRoutes.InstructionalResourceSubjects,
      },
      {
        name: t("Breadcrumb.Resources"),
        url: RouteConstant.TeacherRoutes.InstructionalResources,
        stateObject: { label: decodeURI(subjectName) || "", value: subjectId },
      },
      { name: t("Breadcrumb.ClassActivities"), url: `` },
    ];

    return items;
  };

  return (
    <AllowedTo
      perform={Permissions.bell_ringer_view}
      no={() => <AccessDenied />}
    >
      {loading && <Loader />}

      {showFeedbackPopup && (
        <Feedback
          toggleFeedback={toggleFeedback}
          referenceType={Constant.FeedbackReferenceType.Worksheet}
          referenceId={selectedWorksheet}
          subjectId={subjectId}
          standardId={selectedStandardId}
        />
      )}

      <div className="min-w-0 w-full">
        <div className="lg:flex lg:items-center px-4 py-3 lg:px-8 border-b">
          <Breadcrumb items={breadcrumbItems()} />
          <div className="lg:w-[55%] text-lg font-medium flex gap-2 h-auto sm:h-10 items-center">
            <h1 className="text-primary-violet font-medium flex justify-start mb-0 pb-0">
              Class Activities
            </h1>
          </div>
        </div>

        <div className="px-2 sm:px-6 lg:px-8 py-6">
          <div className="w-full rounded-lg ">
            <div className="mb-2">
              {worksheetDeails && worksheetDeails.length > 0
                ? worksheetDeails[0].worksheets[0].subjectName
                : ""}
            </div>

            <InfoMessage
              className="mb-8"
              message="Here you will find a variety of printable classroom resources. These include labs, Venn diagrams, ordering, and many other activities designed to challenge your students in diverse ways."
            ></InfoMessage>
          </div>

          <form action="#" onSubmit={filterHandler}>
            <div className=" bg-[#00b1fb] bg-opacity-10 w-full gap-x-2 lg:gap-x-0 rounded-lg py-4 sm:py-2 px-5 flex flex-col sm:flex-row justify-between items-center mt-4">
              <input
                type="text"
                name="txtSearch"
                id="txtSearch"
                className="focus:ring-indigo-500 focus:border-indigo-500 w-60 rounded-md sm:text-sm border-gray-300"
                placeholder="Search by title or keyword"
                value={title}
                onChange={(e) => {
                  handleSearchOnChange(e);
                }}
              />

              <div className="mt-1 flex justify-center mt-2 sm:mt-0 md:justify-start rounded-md shadow-sm w-72 max-w-xs">
                <a
                  href="#!"
                  title="List view"
                  className={
                    viewBy === "Thumbnail" ? unSelectedViewBy : selectedViewBy
                  }
                  onClick={() => {
                    selectViewBy("Outline");
                  }}
                >
                  <svg
                    className="w-4 mr-1 text-white"
                    version="1.0"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 96.000000 96.000000"
                    preserveAspectRatio="xMidYMid meet"
                  >
                    <g
                      transform="translate(0.000000,96.000000) scale(0.100000,-0.100000)"
                      fill={viewBy === "Thumbnail" ? "#6B7280" : "currentColor"}
                      stroke="none"
                    >
                      <path d="M80 760 c-11 -11 -20 -29 -20 -40 0 -26 34 -60 60 -60 26 0 60 34 60 60 0 26 -34 60 -60 60 -11 0 -29 -9 -40 -20z" />
                      <path d="M280 720 l0 -40 300 0 300 0 0 40 0 40 -300 0 -300 0 0 -40z" />
                      <path d="M80 520 c-11 -11 -20 -29 -20 -40 0 -26 34 -60 60 -60 26 0 60 34 60 60 0 26 -34 60 -60 60 -11 0 -29 -9 -40 -20z" />
                      <path d="M280 480 l0 -40 300 0 300 0 0 40 0 40 -300 0 -300 0 0 -40z" />
                      <path d="M80 280 c-11 -11 -20 -29 -20 -40 0 -26 34 -60 60 -60 26 0 60 34 60 60 0 26 -34 60 -60 60 -11 0 -29 -9 -40 -20z" />
                      <path d="M280 240 l0 -40 300 0 300 0 0 40 0 40 -300 0 -300 0 0 -40z" />
                    </g>
                  </svg>
                  LIST VIEW
                </a>
                <a
                  href="#!"
                  title="Detail view"
                  className={
                    viewBy === "Thumbnail" ? selectedViewBy : unSelectedViewBy
                  }
                  onClick={() => {
                    selectViewBy("Thumbnail");
                  }}
                >
                  <svg
                    className="w-4 mr-1 text-white"
                    version="1.0"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 96.000000 96.000000"
                    preserveAspectRatio="xMidYMid meet"
                  >
                    <g
                      transform="translate(0.000000,96.000000) scale(0.100000,-0.100000)"
                      fill={viewBy === "Thumbnail" ? "currentColor" : "#6B7280"}
                      stroke="none"
                    >
                      {" "}
                      <path d="M150 660 l0 -150 150 0 150 0 0 150 0 150 -150 0 -150 0 0 -150z m240 0 l0 -90 -90 0 -90 0 0 90 0 90 90 0 90 0 0 -90z" />
                      <path d="M510 660 l0 -150 150 0 150 0 0 150 0 150 -150 0 -150 0 0 -150z m240 0 l0 -90 -90 0 -90 0 0 90 0 90 90 0 90 0 0 -90z" />
                      <path d="M150 300 l0 -150 150 0 150 0 0 150 0 150 -150 0 -150 0 0 -150z m240 0 l0 -90 -90 0 -90 0 0 90 0 90 90 0 90 0 0 -90z" />
                      <path d="M510 300 l0 -150 150 0 150 0 0 150 0 150 -150 0 -150 0 0 -150z m240 0 l0 -90 -90 0 -90 0 0 90 0 90 90 0 90 0 0 -90z" />
                    </g>
                  </svg>
                  DETAIL VIEW
                </a>
              </div>
            </div>
          </form>

          <div className="w-full rounded-lg py-5">
            {worksheetDeails && worksheetDeails.length > 0 && !loading && (
              <>
                {viewBy === "Thumbnail" && (
                  <div>
                    {worksheetDeails?.map((domain, index) => {
                      return (
                        <Fragment key={index}>
                          <div className="text-lg text-primary-violet font-semibold mb-2">
                            {domain.domainName}
                          </div>
                          <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-4 xxxl:grid-cols-5">
                            {domain.worksheets?.map((worksheet, index) => {
                              return (
                                <li
                                  key={index}
                                  className="border border-gray-50 col-span-1 flex flex-col text-center bg-white rounded-lg shadow divide-y divide-gray-200 hover:bg-gray-50"
                                >
                                  <div className="flex-1 flex flex-col p-8">
                                    <h3 className="h-10 lg:h-14 xl:h-10 text-gray-900 text-left text-sm font-medium">
                                      {worksheet.title}
                                    </h3>

                                    <div
                                      className={`w-28 h-32 mx-auto border text-lg font-semibold relative mt-2 flex items-start justify-center ${
                                        worksheet.worksheetFilePath
                                          ? "cursor-pointer "
                                          : "cursor-not-allowed"
                                      }`}
                                      onClick={() => {
                                        downloadWorksheet(
                                          worksheet.worksheetId,
                                          worksheet.worksheetFilePath
                                        );
                                      }}
                                    >
                                      <span
                                        className={` ${
                                          worksheet.worksheetFilePath
                                            ? "bg-primary-violet"
                                            : "bg-gray-500"
                                        } inline-block px-2 absolute -left-5 top-5 text-white`}
                                      >
                                        {worksheet.worksheetFilePath
                                          ? "Worksheet"
                                          : "Not Available"}
                                      </span>
                                      {worksheet.worksheetThumbnailUrl && (
                                        <img
                                          alt="download"
                                          title="Download worksheet"
                                          className="w-full flex-shrink-0 mx-auto"
                                          src={worksheet.worksheetThumbnailUrl}
                                        />
                                      )}

                                      {!worksheet.worksheetThumbnailUrl && (
                                        <span title="Download worksheet">
                                          <svg
                                            className="opacity-20 w-full mt-2"
                                            version="1.1"
                                            id="Layer_1"
                                            xmlns="http://www.w3.org/2000/svg"
                                            x="0px"
                                            y="0px"
                                            viewBox="0 0 184.153 184.153"
                                            fill="currentColor"
                                          >
                                            <g>
                                              <g>
                                                <g>
                                                  <path
                                                    d="M129.318,0H26.06c-1.919,0-3.475,1.554-3.475,3.475v177.203c0,1.92,1.556,3.475,3.475,3.475h132.034
c1.919,0,3.475-1.554,3.475-3.475V34.131C161.568,22.011,140.771,0,129.318,0z M154.62,177.203H29.535V6.949h99.784
c7.803,0,25.301,18.798,25.301,27.182V177.203z"
                                                  />
                                                  <path
                                                    d="M71.23,76.441c15.327,0,27.797-12.47,27.797-27.797c0-15.327-12.47-27.797-27.797-27.797
c-15.327,0-27.797,12.47-27.797,27.797C43.433,63.971,55.902,76.441,71.23,76.441z M71.229,27.797
c11.497,0,20.848,9.351,20.848,20.847c0,0.888-0.074,1.758-0.183,2.617l-18.071-2.708L62.505,29.735
C65.162,28.503,68.112,27.797,71.229,27.797z M56.761,33.668l11.951,19.869c0.534,0.889,1.437,1.49,2.462,1.646l18.669,2.799
c-3.433,6.814-10.477,11.51-18.613,11.51c-11.496,0-20.847-9.351-20.847-20.847C50.381,42.767,52.836,37.461,56.761,33.668z"
                                                  />
                                                  <rect
                                                    x="46.907"
                                                    y="90.339"
                                                    width="73.058"
                                                    height="6.949"
                                                  />
                                                  <rect
                                                    x="46.907"
                                                    y="107.712"
                                                    width="48.644"
                                                    height="6.949"
                                                  />
                                                  <rect
                                                    x="46.907"
                                                    y="125.085"
                                                    width="62.542"
                                                    height="6.949"
                                                  />
                                                </g>
                                              </g>
                                            </g>
                                            <g></g>
                                            <g></g>
                                            <g></g>
                                            <g></g>
                                            <g></g>
                                            <g></g>
                                            <g></g>
                                            <g></g>
                                            <g></g>
                                            <g></g>
                                            <g></g>
                                            <g></g>
                                            <g></g>
                                            <g></g>
                                            <g></g>
                                          </svg>
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                  <div>
                                    <div className="-mt-px flex divide-x divide-gray-200">
                                      <div
                                        className="w-0 flex-1 flex"
                                        onClick={() =>
                                          handleFeedbackPopup(
                                            worksheet.worksheetId,
                                            worksheet.standardId
                                          )
                                        }
                                      >
                                        <a
                                          href="#!"
                                          className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg group cursor-pointer"
                                        >
                                          <FeedbackIcon
                                            title="Submit feedback"
                                            className="w-4 text-gray-500 group-hover:text-gray-700"
                                          />{" "}
                                          <span className="ml-3 group-hover:underline">
                                            FEEDBACK
                                          </span>
                                        </a>
                                      </div>
                                      {worksheet.worksheetKeyFilePath && (
                                        <div
                                          className="-ml-px w-0 flex-1 flex"
                                          onClick={() => {
                                            downloadWorksheet(
                                              worksheet.worksheetId,
                                              worksheet.worksheetKeyFilePath
                                            );
                                          }}
                                        >
                                          <a
                                            href="#!"
                                            className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg group cursor-pointer"
                                          >
                                            <KeyIcon
                                              title="Download key"
                                              className="w-5 mr-3 text-gray-500 group-hover:text-gray-700"
                                            />
                                            <span className="w-5 group-hover:underline">
                                              KEY
                                            </span>
                                          </a>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </li>
                              );
                            })}
                          </ul>
                        </Fragment>
                      );
                    })}
                  </div>
                )}
                {viewBy === "Outline" && (
                  <div>
                    {worksheetDeails?.map((domain, index) => {
                      return (
                        <Fragment>
                          <div className="text-lg text-primary-violet font-semibold mb-2">
                            {domain.domainName}
                          </div>
                          <div className="mb-6 shadow overflow-x-auto">
                            <table className="table w-full">
                              <thead className="mb-5">
                                <tr className="bg-light-violet text-white py-3 px-3 overflow-hidden">
                                  <th className="text-left p-3 font-normal">
                                    Standard Code
                                  </th>
                                  <th className="text-left p-3 font-normal">
                                    Title
                                  </th>
                                  <th className="text-center p-3 font-normal min-w-[14rem]">
                                    Worksheet
                                  </th>
                                  <th className="text-center p-3 text-sm font-normal w-48">
                                    Key
                                  </th>
                                  <th className="text-center p-3 text-sm font-normal w-44">
                                    Feedback
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {domain.worksheets?.map((worksheet, i) => {
                                  return (
                                    <tr
                                      key={i}
                                      className={
                                        "hover:bg-light-violet/20 transition ease-in-out duration-300 " +
                                        (i % 2 !== 0
                                          ? "bg-white"
                                          : "bg-light-violet/10")
                                      }
                                    >
                                      <td className="text-sm mt-5  p-3">
                                        <span x-description="Upcoming Step">
                                          {worksheet.standardCode}
                                        </span>
                                      </td>
                                      <td className="text-sm mt-5  p-3">
                                        <span
                                          className="inline-block"
                                          x-description="Upcoming Step"
                                        >
                                          {worksheet.title}
                                        </span>
                                      </td>
                                      <td className=" mt-5 p-3 text-center">
                                        {worksheet.worksheetFilePath && (
                                          <span
                                            title="Download worksheet"
                                            className={`inline-block ${
                                              worksheet.worksheetFilePath
                                                ? "cursor-pointer text-blue-500 underline hover:no-underline"
                                                : ""
                                            }`}
                                            onClick={() => {
                                              downloadWorksheet(
                                                worksheet.worksheetId,
                                                worksheet.worksheetFilePath
                                              );
                                            }}
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              fill="currentColor"
                                              className="h-4 w-4 text-gray-500 hover:text-gray-700"
                                              viewBox="0 0 16 16"
                                            >
                                              <path d="M5.5 7a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zM5 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5z"></path>
                                              <path d="M9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.5L9.5 0zm0 1v2A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z"></path>
                                            </svg>
                                          </span>
                                        )}
                                        {!worksheet.worksheetFilePath && (
                                          <CancelIcon className="inline-block h-4 w-4 text-gray-500 hover:text-gray-700"></CancelIcon>
                                        )}
                                      </td>
                                      <td className=" mt-5 p-3 text-center cursor-pointer">
                                        {worksheet.worksheetKeyFilePath && (
                                          <span
                                            onClick={() => {
                                              downloadWorksheet(
                                                worksheet.worksheetId,
                                                worksheet.worksheetKeyFilePath
                                              );
                                            }}
                                            className="inline-block"
                                          >
                                            <KeyIcon
                                              title="Download key"
                                              className="w-4 h-4 text-gray-500 hover:text-gray-700"
                                            />
                                          </span>
                                        )}
                                      </td>
                                      <td className=" mt-5 p-3 text-center cursor-pointer">
                                        <span
                                          className="inline-block"
                                          onClick={() =>
                                            handleFeedbackPopup(
                                              worksheet.worksheetId,
                                              worksheet.standardId
                                            )
                                          }
                                        >
                                          <FeedbackIcon
                                            title="Submit feedback"
                                            className="h-4 w-4 text-gray-500 hover:text-gray-700"
                                          ></FeedbackIcon>
                                        </span>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        </Fragment>
                      );
                    })}
                  </div>
                )}
              </>
            )}
            {worksheetDeails && worksheetDeails.length === 0 && !loading && (
              <span>No data found</span>
            )}
          </div>
        </div>
      </div>
    </AllowedTo>
  );
}

export default connect((state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
})(ClassActivity);

import { Fragment } from "react";
import { Link } from "react-router-dom";
import {
  MathIcon,
  EnglishIcon,
  SocialStudiesIcon,
  ScienceIcon,
} from "../../../../../assets/icons";
import IStudyPlanSubjects from "../../../../../model/student/studyPlanSubjects";
import Profile from "../../../../../model/users/profile";
import constant from "../../../../../utils/constant/constant";
import routeConstant from "../../../../../utils/constant/routeConstant";
import { useTranslation } from "react-i18next";
export default function StudyPlanGradeSubject({
  subjects,
  type,
  onSubjectSelect,
  profile,
}: {
  subjects: IStudyPlanSubjects[];
  type: string;
  onSubjectSelect: any;
  profile: Profile;
}): JSX.Element {
  const orderedCategories: string[] = ["elem", "ms", "hs"];
  const categories: string[] = orderedCategories;
  const { t } = useTranslation();

  return (
    <>
      {type !== "additionalSubjects" ? (
        <div className="inner bg-white bg-opacity-20 rounded-md px-4 py-4 shadow-xl ">
          <div className="flex flex-col md:flex-row flex-wrap gap-4">
            {subjects.length > 0 &&
              subjects.map((sub) => {
                return (
                  <Fragment key={sub.subjectId}>
                    <div className="group flex items-center bg-white w-full sm:w-auto p-2 mt-1 bg-white rounded-md hover:bg-gray-200 hover:shadow-lg transition ease-in-out duration-300">
                      <button
                        className="flex items-center w-full"
                        onClick={() =>
                          onSubjectSelect(
                            sub.subjectId,
                            (profile.plLanguageId ?? 1) ===
                              constant.Languages.Spanish
                              ? sub.spanishName ?? sub.name
                              : sub.name
                          )
                        }
                      >
                        <img
                          className="w-6"
                          srcSet={sub.subjectIcon}
                          alt="Subject Icon"
                        />
                        <span className="pl-2 text-left font-normal group-hover:text-primary-violet">
                          {(profile.plLanguageId ?? 1) ===
                          constant.Languages.Spanish
                            ? sub.spanishName ?? sub.name
                            : sub.name}
                        </span>
                      </button>
                    </div>
                  </Fragment>
                );
              })}
          </div>
        </div>
      ) : (
        categories.map((category, index) => {
          return (
            <div
              className={`inner rounded-top-lg text-white text-lg px-4 py-6 shadow-xl
              ${
                category === "elem"
                  ? "bg-tertiary-green rounded-t-md"
                  : category === "ms"
                  ? "bg-light-blue"
                  : "bg-secondary-teal rounded-b-md"
              }
              
              `}
              key={index}
            >
              <div className="">
                {category === "elem"
                  ? t("StudyPlan.ElementarySchool")
                  : category === "ms"
                  ? t("StudyPlan.MiddleSchool")
                  : t("StudyPlan.HighSchool")}
              </div>
              <div className="flex flex-col md:flex-row flex-wrap gap-3">
                {subjects.length > 0 && (
                  <>
                    <div className="bg-white w-full sm:w-80 p-2 mt-1 bg-white rounded-md hover:shadow-lg transition ease-in-out duration-300">
                      <div
                        className={`flex items-center bg-gray-700 rounded-md`}
                      >
                        <span className="flex items-center justify-center m-1.5 h-9 w-12 rounded-md bg-light-violet text-white">
                          <EnglishIcon title="English language arts" />
                        </span>
                        <span className="text-lg w-full text-left leading-6 font-normal text-white">
                          ELA
                        </span>
                      </div>
                      <div className="w-full mb-4 md:mb-0 bg-white rounded">
                        <ul className="flex flex-col w-full divide-y divide-gray-200">
                          {subjects
                            .filter(
                              (addnlsub) =>
                                addnlsub.contentAreaId === 1 &&
                                category === addnlsub.gradeLevel
                            )
                            .map((thisSubject, index) => {
                              return (
                                <Fragment key={index}>
                                  <Link
                                    to={{
                                      pathname:
                                        routeConstant.MiddleSchool.StudyPlanStandards.replace(
                                          ":id",
                                          thisSubject.subjectId.toString()
                                        ),
                                      state: {
                                        subjectName:
                                          (profile.plLanguageId ?? 1) ===
                                            constant.Languages.Spanish &&
                                          thisSubject.spanishName
                                            ? thisSubject.spanishName
                                            : thisSubject.name,
                                      },
                                    }}
                                  >
                                    <li
                                      className={`group flex justify-start h-12 items-center text-gray-500 mr-auto text-sm font-medium bg-white px-2 hover:bg-gray-200 shadow cursor-pointer ${
                                        index % 2 === 0
                                          ? "bg-white"
                                          : "bg-gray-50"
                                      } px-2 rounded hover:bg-gray-200 shadow cursor-pointer`}
                                    >
                                      <span className="group-hover:text-primary-violet font-normal">
                                        {(profile.plLanguageId ?? 1) ===
                                          constant.Languages.Spanish &&
                                        thisSubject.spanishName
                                          ? thisSubject.spanishName
                                          : thisSubject.name}
                                      </span>
                                    </li>
                                  </Link>
                                </Fragment>
                              );
                            })}
                        </ul>
                      </div>
                    </div>
                  </>
                )}
                {subjects.length > 0 && (
                  <div className="bg-white w-full sm:w-80 p-2 mt-1 bg-white rounded-md hover:shadow-lg transition ease-in-out duration-300">
                    <div className={`flex items-center bg-gray-700 rounded-md`}>
                      <span className="flex items-center justify-center m-1.5 h-9 w-12 rounded-md bg-light-violet text-white">
                        <MathIcon title="Math" />
                      </span>
                      <span className="text-lg w-full text-left leading-6 font-normal text-white">
                        {t("MSSubjectSelection.Math")}
                      </span>
                    </div>
                    <div className="w-full mb-4 md:mb-0 bg-white rounded">
                      <ul className="flex flex-col w-full divide-y divide-gray-200">
                        {subjects
                          .filter(
                            (addnlsub) =>
                              addnlsub.contentAreaId === 2 &&
                              category === addnlsub.gradeLevel
                          )
                          .map((thisSubject, index) => (
                            <Fragment key={index}>
                              <Link
                                to={{
                                  pathname:
                                    routeConstant.MiddleSchool.StudyPlanStandards.replace(
                                      ":id",
                                      thisSubject.subjectId.toString()
                                    ),
                                  state: {
                                    subjectName:
                                      (profile.plLanguageId ?? 1) ===
                                        constant.Languages.Spanish &&
                                      thisSubject.spanishName
                                        ? thisSubject.spanishName
                                        : thisSubject.name,
                                  },
                                }}
                              >
                                <li
                                  className={`group flex justify-start h-12 items-center text-gray-500 mr-auto text-sm font-medium bg-white px-2 hover:bg-gray-200 shadow cursor-pointer ${
                                    index % 2 === 0 ? "bg-white" : "bg-gray-50"
                                  } px-2 rounded hover:bg-gray-200 shadow cursor-pointer`}
                                >
                                  <span className="group-hover:text-primary-violet font-normal">
                                    {(profile.plLanguageId ?? 1) ===
                                      constant.Languages.Spanish &&
                                    thisSubject.spanishName
                                      ? thisSubject.spanishName
                                      : thisSubject.name}
                                  </span>
                                </li>
                              </Link>
                            </Fragment>
                          ))}
                      </ul>
                    </div>
                  </div>
                )}

                {subjects.length > 0 && (
                  <div className="bg-white w-full sm:w-80 p-2 mt-1 bg-white rounded-md hover:shadow-lg transition ease-in-out duration-300">
                    <div className={`flex items-center bg-gray-700 rounded-md`}>
                      <span className="flex items-center justify-center m-1.5 h-9 w-12 rounded-md bg-light-violet text-white">
                        <ScienceIcon title="Science" />
                      </span>
                      <span className="text-lg w-full text-left leading-6 font-normal text-white">
                        {t("MSSubjectSelection.Science")}
                      </span>
                    </div>
                    <div className="w-full mb-4 md:mb-0 bg-white rounded">
                      <ul className="flex flex-col w-full divide-y divide-gray-200">
                        {subjects
                          .filter(
                            (addnlsub) =>
                              addnlsub.contentAreaId === 3 &&
                              category === addnlsub.gradeLevel
                          )
                          .map((thisSubject, index) => (
                            <Fragment key={index}>
                              <Link
                                to={{
                                  pathname:
                                    routeConstant.MiddleSchool.StudyPlanStandards.replace(
                                      ":id",
                                      thisSubject.subjectId.toString()
                                    ),
                                  state: {
                                    subjectName:
                                      (profile.plLanguageId ?? 1) ===
                                        constant.Languages.Spanish &&
                                      thisSubject.spanishName
                                        ? thisSubject.spanishName
                                        : thisSubject.name,
                                  },
                                }}
                              >
                                <li
                                  className={`group flex justify-start h-12 items-center text-gray-500 mr-auto text-sm font-medium bg-white px-2 hover:bg-gray-200 shadow cursor-pointer ${
                                    index % 2 === 0 ? "bg-white" : "bg-gray-50"
                                  } px-2 rounded hover:bg-gray-200 shadow cursor-pointer`}
                                >
                                  <span className="group-hover:text-primary-violet font-normal">
                                    {(profile.plLanguageId ?? 1) ===
                                      constant.Languages.Spanish &&
                                    thisSubject.spanishName
                                      ? thisSubject.spanishName
                                      : thisSubject.name}
                                  </span>
                                </li>
                              </Link>
                            </Fragment>
                          ))}
                      </ul>
                    </div>
                  </div>
                )}
                {subjects.length > 0 && (
                  <div className="bg-white w-full sm:w-80 p-2 mt-1 bg-white rounded-md hover:shadow-lg transition ease-in-out duration-300">
                    <div className={`flex items-center bg-gray-700 rounded-md`}>
                      <span className="flex items-center justify-center m-1.5 h-9 w-12 rounded-md bg-light-violet text-white">
                        <SocialStudiesIcon title="Social studies" />
                      </span>
                      <span className="text-lg w-full text-left leading-6 font-normal text-white">
                        {t("MSSubjectSelection.SocialStudies")}
                      </span>
                    </div>
                    <div className="w-full mb-4 md:mb-0 bg-white rounded">
                      <ul className="flex flex-col w-full divide-y divide-gray-200">
                        {subjects
                          .filter(
                            (addnlsub) =>
                              addnlsub.contentAreaId === 4 &&
                              category === addnlsub.gradeLevel
                          )
                          .map((thisSubject, index) => (
                            <Fragment key={index}>
                              <Link
                                to={{
                                  pathname:
                                    routeConstant.MiddleSchool.StudyPlanStandards.replace(
                                      ":id",
                                      thisSubject.subjectId.toString()
                                    ),
                                  state: {
                                    subjectName:
                                      (profile.plLanguageId ?? 1) ===
                                        constant.Languages.Spanish &&
                                      thisSubject.spanishName
                                        ? thisSubject.spanishName
                                        : thisSubject.name,
                                  },
                                }}
                              >
                                <li
                                  className={`group flex justify-start h-12 items-center text-gray-500 mr-auto text-sm font-medium bg-white px-2 hover:bg-gray-200 shadow cursor-pointer ${
                                    index % 2 === 0 ? "bg-white" : "bg-gray-50"
                                  } px-2 rounded hover:bg-gray-200 shadow cursor-pointer`}
                                >
                                  <span className="group-hover:text-primary-violet font-normal">
                                    {(profile.plLanguageId ?? 1) ===
                                      constant.Languages.Spanish &&
                                    thisSubject.spanishName
                                      ? thisSubject.spanishName
                                      : thisSubject.name}
                                  </span>
                                </li>
                              </Link>
                            </Fragment>
                          ))}
                      </ul>
                    </div>
                  </div>
                )}
              </div>
            </div>
          );
        })
      )}
    </>
  );
}

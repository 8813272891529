import React from 'react';
import { IAvailavelClassSubject } from '../../../../../model/teacher/classManagement/IAvailavelClassSubject';
import PagingResponse from '../../../../../model/common/pagingResponse';
import ClassStudentModel from '../../../../../model/teacher/classManagement/classStudentModel';
import InfoMessage from '../../../../shared/infoMessage';
import Pagination from "../../../../shared/pagination/pagination";
import PageSize from '../../../../shared/pagination/pageSize';

interface ClassBuilderStudentsProps {
  availableSubjects: IAvailavelClassSubject[];
  filterCharList: string[];
  startWith: string;
  gradeList: { gradeId: number; name: string }[];
  studentName: string;
  students?: PagingResponse<ClassStudentModel>;
  selectAll: boolean;
  selectStudentToAdd: number[];
  studentPageSize: number;
  studentPageNumber: number;
  resetStudentPageNumber: boolean;
  isDetailsValid: boolean;
  handleSaveAndContinue: () => void;
  handleSkipClassSetup: () => void;
  handleCharFilter: (char: string) => void;
  setSelectedGrade: (grade: number) => void;
  setStudentName: (name: string) => void;
  searchStudents: () => void;
  selectAllStudent: (e: React.ChangeEvent<HTMLInputElement>, data: ClassStudentModel[]) => void;
  selectStudent: (userId: number) => void;
  getNameLabel: (lastName: string, firstName: string) => string;
  onStudentPageSizeChange: (size: number) => void;
  setStudentPageNumber: (pageNumber: number) => void;
}

const ClassBuilderStudents = ({
  availableSubjects,
  filterCharList,
  startWith,
  gradeList,
  studentName,
  students,
  selectAll,
  selectStudentToAdd,
  studentPageSize,
  studentPageNumber,
  resetStudentPageNumber,
  isDetailsValid,
  handleSaveAndContinue,
  handleSkipClassSetup,
  handleCharFilter,
  setSelectedGrade,
  setStudentName,
  searchStudents,
  selectAllStudent,
  selectStudent,
  getNameLabel,
  onStudentPageSizeChange,
  setStudentPageNumber,
}: ClassBuilderStudentsProps) => {
  return (
    <div className="w-full relative overflow-auto px-4 py-6">
      <div className="w-full flex">
        {/* Students Selection Content */}
        <div className="flex-grow-3">
          <InfoMessage message="Select the students you would like to include in your class. You can filter by last name by selecting a letter, by grade or by entering a student name directly. Once a student has been selected, they will remain so as you continue to filter for additional students." />
          <div className="p-3 sm:py-0 sm:space-y-0 sm:divide-y sm:divide-gray-200 text-sm text-gray-700 dark:text-gray-400">
            <div>
              <div className="mb-4">
                <h1 className="font-bold text-primary-violet mb-2">
                  Available Students
                </h1>
                <ul className="flex flex-wrap gap-2 sm:gap-1 capitalize items-center cursor-pointer">
                  {filterCharList.map((ch) => {
                    if (ch === "All")
                      return (
                        <li
                          key={ch}
                          className={
                            startWith === ch
                              ? "bg-secondary-teal flex items-center justify-center w-10 h-5 text-sm text-white hover:opacity-70"
                              : "flex items-center justify-center bg-primary-blue w-10 h-5 text-sm text-white hover:opacity-70"
                          }
                          onClick={() => {
                            handleCharFilter(ch);
                          }}
                        >
                          {ch}
                        </li>
                      );
                    else
                      return (
                        <li
                          key={ch}
                          className={
                            startWith === ch
                              ? "bg-secondary-teal flex items-center justify-center w-5 h-5 text-sm text-white hover:opacity-70"
                              : "flex items-center justify-center bg-primary-blue w-5 h-5 text-sm text-white hover:opacity-70"
                          }
                          onClick={() => {
                            handleCharFilter(ch);
                          }}
                        >
                          {ch}
                        </li>
                      );
                  })}
                </ul>
                <div className="grid grid-cols-12 gap-6 flex items-end mt-3">
                  <div className="col-span-12 sm:col-span-4 xl:col-span-3">
                    <label className="block text-sm text-gray-500">Grade</label>
                    <select
                      className="mt-1 py-1.5 px-3 block w-full shadow-sm focus:ring-primary-violet focus:border-primary-violet border border-gray-300 rounded-md"
                      onChange={(e) => {
                        setSelectedGrade(Number.parseInt(e.target.value));
                      }}
                    >
                      {gradeList.map((g) => {
                        return (
                          <option key={g.gradeId} value={g.gradeId}>
                            {g.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="col-span-12 sm:col-span-4 xl:col-span-3">
                    <label className="block text-sm text-gray-500">
                      Name / Student id
                    </label>
                    <input
                      type="text"
                      className="mt-1 py-1.5 px-3 block w-full shadow-sm focus:ring-primary-violet focus:border-primary-violet border border-gray-300 rounded-md"
                      value={studentName}
                      onChange={(e) => {
                        setStudentName(e.target.value);
                      }}
                    />
                  </div>
                  <div className="col-span-12 sm:col-span-4 xl:col-span-3">
                    <button
                      id="searchStudent"
                      type="button"
                      onClick={searchStudents}
                      className="bg-primary-violet border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-dark-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      Search
                    </button>
                  </div>
                </div>
              </div>
              <div className="overflow-x-auto shadow">
                <table className="table w-full">
                  <thead className="mb-5 w-full">
                    <tr className="bg-light-violet text-white py-3 px-3 overflow-hidden">
                      <th className="text-left p-3 text-sm font-normal w-4">
                        <label
                          x-radio-group-option=""
                          className="relative cursor-pointer"
                        >
                          <input
                            type="checkbox"
                            x-model="value"
                            name="privacy_setting"
                            value="Private to Project Members"
                            className="h-4 w-4 mt-0.5 cursor-pointer text-primary-violet border-gray-300 focus:ring-primary-violet"
                            aria-labelledby="privacy-setting-1-label"
                            aria-describedby="privacy-setting-1-description"
                            checked={selectAll}
                            onChange={(e) => {
                              selectAllStudent(e, students?.data!);
                            }}
                          />
                        </label>
                      </th>
                      <th className="text-left p-3 text-sm font-normal">
                        Name
                      </th>
                      <th className="text-left p-3 text-sm font-normal">
                        Username
                      </th>
                      <th className="text-center p-3 text-sm font-normal">
                        Student Id
                      </th>
                      <th className="text-center p-3 text-sm font-normal">
                        Grade
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {students?.data?.map((student, index) => {
                      return (
                        <tr
                          key={student.userId}
                          className={`hover:bg-light-violet/20 transition ease-in-out duration-300 ${
                            index % 2 === 0 ? "bg-white" : "bg-light-violet/10"
                          }`}
                        >
                          <td className="mt-5 p-3 text-center">
                            <input
                              type="checkbox"
                              className="h-4 w-4 cursor-pointer text-primary-violet border-gray-300 focus:ring-primary-violet"
                              key={student.userId}
                              checked={selectStudentToAdd.includes(
                                student.userId
                              )}
                              onChange={() => {
                                selectStudent(student.userId);
                              }}
                            />
                          </td>
                          <td className="text-sm mt-5 p-3">
                            {getNameLabel(student.lastName, student.firstName)}
                          </td>
                          <td className="text-sm mt-5 p-3">
                            {student.username}
                          </td>
                          <td className="text-sm text-center mt-5 p-3">
                            {student.studentId}
                          </td>
                          <td className="text-sm text-center mt-5 p-3">
                            {student.grade}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between mt-3">
                {students?.totalRecords !== undefined &&
                  students?.totalRecords !== 0 && (
                    <Pagination
                      totalRecords={students?.totalRecords}
                      pageSize={studentPageSize}
                      onPageChange={(e) => {
                        setStudentPageNumber(e);
                      }}
                      reset={resetStudentPageNumber}
                      pageNumber={studentPageNumber}
                    />
                  )}
                {students?.totalRecords !== 0 && (
                  <PageSize
                    pageSize={studentPageSize}
                    onPageSizeChange={onStudentPageSizeChange}
                  />
                )}
                {students?.totalRecords === 0 && (
                  <span className="text-color-black">No record found</span>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* Buttons Container */}
        <div className="flex flex-col gap-4 ml-4 w-1/5">
          <button
            className="bg-primary-violet border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-dark-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 w-full"
            onClick={handleSaveAndContinue}
            disabled={!isDetailsValid}
            type="button"
          >
            Save and Continue
          </button>
          <button
            type="button"
            onClick={handleSkipClassSetup}
            className="bg-primary-violet border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-dark-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 w-full"
          >
            Skip Class Setup
          </button>
        </div>
      </div>
    </div>
  );
};

export default ClassBuilderStudents;

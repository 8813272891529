import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { getCannedTestReport } from "../../../../../api/student/cannedTestApi";
import StandardEffitiencyReport from "../../../../../model/student/shared/cannedTest/standardEffitiencyReport";
import RouteConstant from "../../../../../utils/constant/routeConstant";
import { useTranslation } from "react-i18next";
import { Configuration } from "../../../../../environment/setup";
import { getAPIParameters, validParameters } from "../../../../../utils/standardEfficiencyHelper";

function StandardEfficiencyReport(props: any) {
  const [report, setReport] = useState<StandardEffitiencyReport>();
  const { t } = useTranslation();
  const history = useHistory();

  function bindResult() {
    if (validParameters(props)) {
      const {
        studentId,
        learnosityReferenceId,
        learnositySessionId
      } = getAPIParameters(props);

      getCannedTestReport(
        studentId,
        learnosityReferenceId,
        learnositySessionId
      ).then((response) => {
        setReport(response.data);
      });
    }
  }

  const redirectToResult = () => {
    history.goBack();
  };

  useEffect(() => {
    bindResult();
  }, []);

  return (
    <div className="bg-white h-full ml-4 mr-2 mb-2 rounded-2xl p-5">
      {
        !props.isTeacherView &&
        <nav className="flex px-2 pb-3 pt-2 border-b" aria-label="Breadcrumb">
          <ol className="flex items-center space-x-0">
            <li>
              <div>
                <Link to={RouteConstant.KindergartenStudentRoutes.Home}>
                  <svg
                    className="flex-shrink-0 h-5 w-5"
                    x-description="Heroicon name: solid/home"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path>
                  </svg>
                  <span className="sr-only">Home</span>
                </Link>
              </div>
            </li>
            <li>
              <div className="flex items-center">
                <svg
                  className="flex-shrink-0 h-5 w-5 text-gray-700"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  aria-hidden="true"
                >
                  <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z"></path>
                </svg>
                <span
                  className="ml-0 text-sm text-gray-700 hover:text-gray-700"
                  aria-current="page"
                >
                  {t("Diagnostics.StandardsEfficiencyReport")}
                </span>
              </div>
            </li>
          </ol>
        </nav>
      }
      <section>
        <h2 className="text-lg mt-2 px-2 pb-3 pt-2">
          {t("Diagnostics.StandardsEfficiencyReport")}
        </h2>
      </section>
      <div className="grid grid-cols-12 gap-6 mb-6">
        <div className="col-span-12 md:col-span-4 pb-10 shadow-lg rounded-lg overflow-hidden">
          <div className="bg-[#61D140] relative h-[12rem] md:h-[12rem] lg:h-[12rem] xl:h-[14rem] 2xl:h-[16rem] xxxl:h-[17rem]">
            <div className="w-full absolute top-[8rem] md:top-[10rem] lg:top-[8rem] left-0 z-0">
              <svg viewBox="0 0 300 100">
                <path
                  d="M-34.667,62.998c0,0,56-45.667,120.316-27.839C167.484,57.842,197,41.332,232.286,30.428
  c53.07-16.399,104.047,36.903,104.047,36.903l1.333,36.667l-372-2.954L-34.667,62.998z"
                  fill="#FFFFFF"
                ></path>
              </svg>
            </div>
            <div className="flex flex-col items-center text-white text-lg">
              <img
                className="w-20 mt-10"
                src={`${Configuration.S3bucketImagePath}/images/student/shared/Areas-of-Strength.png`}
                alt="areas of strength"
                title="Areas of strength"
              />
              <div className="uppercase pt-3 text-center">
                {t("Diagnostics.AreasOfStrength")}
              </div>
            </div>
          </div>
          <ul className="mt-0 md:mt-5 lg:-mt-0 relative z-10 space-y-2 font-light">
            {report &&
              report.strongTopics?.length > 0 &&
              report.strongTopics?.map((standard, index) => {
                return (
                  <li
                    key={index}
                    className="text-center text-base text-gray-700"
                  >
                    {standard}
                  </li>
                );
              })}
          </ul>
        </div>
        <div className="col-span-12 md:col-span-4 pb-10 shadow-lg rounded-lg overflow-hidden">
          <div className="bg-[#F4D220] relative h-[12rem] md:h-[12rem] lg:h-[12rem] xl:h-[14rem] 2xl:h-[16rem] xxxl:h-[17rem]">
            <div className="w-full absolute top-[8rem] md:top-[10rem] lg:top-[8rem] left-0 z-0">
              <svg viewBox="0 0 300 100">
                <path
                  d="M-34.667,62.998c0,0,56-45.667,120.316-27.839C167.484,57.842,197,41.332,232.286,30.428
  c53.07-16.399,104.047,36.903,104.047,36.903l1.333,36.667l-372-2.954L-34.667,62.998z"
                  fill="#FFFFFF"
                ></path>
              </svg>
            </div>
            <div className="flex flex-col items-center text-white text-lg">
              <img
                className="w-20 mt-10"
                src={`${Configuration.S3bucketImagePath}/images/student/shared/study-required.png`}
                alt="some study required"
                title="Some study required"
              />
              <div className="uppercase pt-3 text-center">
                {t("Diagnostics.SomeStudy")}
              </div>
            </div>
          </div>
          <ul className="mt-0 md:mt-5 lg:-mt-0 relative z-10 space-y-2 font-light">
            {report &&
              report.averageTopics?.length > 0 &&
              report.averageTopics?.map((standard, index) => {
                return (
                  <li
                    key={index}
                    className="text-center text-base text-gray-700"
                  >
                    {standard}
                  </li>
                );
              })}
          </ul>
        </div>
        <div className="col-span-12 md:col-span-4 pb-10 shadow-lg rounded-lg overflow-hidden">
          <div className="bg-[#BB0070] relative h-[12rem] md:h-[12rem] lg:h-[12rem] xl:h-[14rem] 2xl:h-[16rem] xxxl:h-[17rem]">
            <div className="w-full absolute top-[8rem] md:top-[10rem] lg:top-[8rem] left-0 z-0">
              <svg viewBox="0 0 300 100">
                <path
                  d="M-34.667,62.998c0,0,56-45.667,120.316-27.839C167.484,57.842,197,41.332,232.286,30.428
  c53.07-16.399,104.047,36.903,104.047,36.903l1.333,36.667l-372-2.954L-34.667,62.998z"
                  fill="#FFFFFF"
                ></path>
              </svg>
            </div>
            <div className="flex flex-col items-center text-white text-lg">
              <img
                className="w-20 mt-10"
                src={`${Configuration.S3bucketImagePath}/images/student/shared/Critical-Need-Study.png`}
                alt="critical need for study"
                title="Critical need for study"
              />
              <div className="uppercase pt-3 text-center">
                {t("Diagnostics.Critical")}
              </div>
            </div>
          </div>
          <ul className="mt-0 md:mt-5 lg:-mt-0 relative space-y-2 z-10 font-light">
            {report &&
              report.criticalTopics?.length > 0 &&
              report.criticalTopics?.map((standard, index) => {
                return (
                  <li
                    key={index}
                    className="text-center text-base text-gray-700"
                  >
                    {standard}
                  </li>
                );
              })}
          </ul>
        </div>
      </div>
      <div className="flex justify-end">
        <span
          onClick={redirectToResult}
          className="cursor-pointer order-first bg-primary-violet hover:bg-dark-violet hover:text-gray-100 text-white px-3 py-2.5 rounded-lg mb-3 mt-2"
        >
          View Results Screen
        </span>
      </div>
    </div>
  );
}

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
};
export default connect<{}, {}, {}>(
  mapStateToProps,
  {}
)(StandardEfficiencyReport);

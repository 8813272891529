import { connect } from "react-redux";
import Select from "react-select";

import Pagination from "../../../../shared/pagination/pagination";
import PageSize from "../../../../shared/pagination/pageSize";

import "../../../../../css/style.css";
import RouteConstant from "../../../../../utils/constant/routeConstant";
import Breadcrumb from "../../shared/breadcrumb";
import { IBreadcrumb } from "../../../../../model/common/breadcrumb";
import { useTranslation } from "react-i18next";
import gradeBook from "../../../../../mockData/reports/gradeBook.json";
import { useEffect } from "react";
import { hasLiftOffAccess } from "../../../../../utils/helper";

function LiftoffGalaxySummaryReport(props) {
  const { t } = useTranslation();

  const breadcrumbItems = () => {
    const items: Array<IBreadcrumb> = [
      { name: t("Breadcrumb.Home"), url: RouteConstant.ROUTE_DASHBOARD },
      {
        name: t("Breadcrumb.Reports"),
        url: RouteConstant.REPORTS,
      },
      { name: t("Liftoff Class Galaxy Summary Report"), url: "" },
    ];

    return items;
  };

  useEffect(() => {
    hasLiftOffAccess(props.userContext).then((res) => {
      if (!res) {
        window.location.replace(RouteConstant.FORBIDDEN);
      }
    });
  });
  return (
    <div className="min-w-0 w-full">
      <div className="lg:flex lg:items-center px-4 py-3 lg:px-8 border-b">
        <Breadcrumb items={breadcrumbItems()} />
        <div className="lg:w-[55%] text-lg flex justify-between gap-2 items-end">
          <h1 className="text-primary-violet font-medium flex justify-start mb-0 pb-0">
            Liftoff Class Galaxy Summary Report
          </h1>
        </div>
      </div>
      <div className="px-2 sm:px-6  lg:px-8 py-1 relative my-4">
        <div className="bg-gray-100 w-full rounded-lg p-5">
          <div className="bg-white px-3 pt-2">
            <div className="overflow-x-auto xl:overflow-x-visible mt-5">
              <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-4 mb-4">
                <div className="flex flex-col">
                  <label className="block mb-1 text-sm text-gray-500">
                    School
                  </label>

                  <Select />
                </div>
                <div className="flex flex-col">
                  <label className="block mb-1 text-sm text-gray-500">
                    Show By
                  </label>
                  <Select />
                </div>
                <div className="flex flex-col">
                  <label className="block mb-1 text-sm text-gray-500">
                    Class
                  </label>

                  <Select />
                </div>
                <div className="flex flex-col">
                  <label className="block mb-1 text-sm text-gray-500">
                    Subject
                  </label>

                  <Select />
                </div>
                <div className="col-span-2 text-right">
                  <span className="bg-gray-100 px-1.5 border-gray-200 border rounded-lg  whitespace-nowrap mr-1.5 mb-1">
                    06/21/22
                  </span>
                </div>
              </div>

              <div className="flex gap-4 justify-end">
                <button className="bg-transparent border my-auto border-primary-violet shadow-sm py-2 px-4 flex justify-center text-sm font-medium text-primary-violet hover:text-white hover:bg-light-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3 transition ease-in-out duration-500">
                  Reset
                </button>
                <button className="bg-primary-violet border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-dark-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3 ">
                  Run Report
                </button>
                <button className="bg-primary-violet border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-dark-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3 ">
                  Export Data
                </button>
                <button className="bg-primary-violet border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-dark-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3 ">
                  Print Report
                </button>
              </div>

              <div className="border-b border-gray-400 py-2 mb-2"></div>
              <div className="bg-white py-5 px-5">
                <div className="w-full overflow-x-auto">
                  <table className="w-full">
                    <thead>
                      <tr className="bg-light-violet  text-white py-3 px-3">
                        <th className="w-32 text-center p-3 text-sm font-normal align-top">
                          Galaxy
                        </th>
                        <th className="text-left p-3 text-sm font-normal align-top">
                          Operations and Algebraic Thinking
                        </th>
                        <th className="text-left p-3 text-sm font-normal align-top">
                          Number and Operations
                        </th>
                        <th className="text-left p-3 text-sm font-normal align-top">
                          Measurement and Data
                        </th>
                        <th className="text-left p-3 text-sm font-normal align-top">
                          Geometry
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="hover:bg-light-violet/20 transition ease-in-out duration-300">
                        <td className="p-3 text-center text-sm font-normal align-top">
                          5
                        </td>
                        <td className="text-left p-3 text-sm font-normal align-top">
                          <div className="inline-block bg-gray-100 px-1.5 border-gray-200 border rounded-lg  whitespace-nowrap mr-1.5 mb-1">
                            Lana Johnson
                          </div>
                          <div className="inline-block bg-gray-100 px-1.5 border-gray-200 border rounded-lg  whitespace-nowrap mr-1.5 mb-1">
                            Lana Johnson2
                          </div>
                        </td>
                        <td className="text-left p-3 text-sm font-normal align-top">
                          <div className="inline-block bg-gray-100 px-1.5 border-gray-200 border rounded-lg  whitespace-nowrap mr-1.5 mb-1">
                            Lana Johnson
                          </div>
                          <div className="inline-block bg-gray-100 px-1.5 border-gray-200 border rounded-lg  whitespace-nowrap mr-1.5 mb-1">
                            Lana Johnson2
                          </div>
                        </td>
                        <td className="text-left p-3 text-sm font-normal align-top flex">
                          <div className="inline-block bg-gray-100 px-1.5 border-gray-200 border rounded-lg  whitespace-nowrap mr-1.5 mb-1">
                            Lana Johnson2
                          </div>
                        </td>
                        <td className="text-left p-3 text-sm font-normal align-top">
                          <div className="inline-block bg-gray-100 px-1.5 border-gray-200 border rounded-lg  whitespace-nowrap mr-1.5 mb-1">
                            Ankur P
                          </div>
                          <div className="inline-block bg-gray-100 px-1.5 border-gray-200 border rounded-lg  whitespace-nowrap mr-1.5 mb-1">
                            Lana Johnson
                          </div>
                          <div className="inline-block bg-gray-100 px-1.5 border-gray-200 border rounded-lg  whitespace-nowrap mr-1.5 mb-1">
                            Lana Johnson2
                          </div>
                        </td>
                      </tr>
                      <tr className="hover:bg-light-violet/20 transition ease-in-out duration-300 bg-light-violet/10">
                        <td className="p-3 text-center text-sm font-normal align-top">
                          4
                        </td>
                        <td className="text-left p-3 text-sm font-normal align-top"></td>
                        <td className="text-left p-3 text-sm font-normal align-top"></td>
                        <td className="text-left p-3 text-sm font-normal align-top">
                          {" "}
                          <div className="inline-block bg-gray-100 px-1.5 border-gray-200 border rounded-lg  whitespace-nowrap mr-1.5 mb-1">
                            Lana Johnson
                          </div>
                        </td>
                        <td className="text-left p-3 text-sm font-normal align-top"></td>
                      </tr>
                      <tr className="hover:bg-light-violet/20 transition ease-in-out duration-300 ">
                        <td className="p-3 text-center text-sm font-normal align-top">
                          3
                        </td>
                        <td className="text-left p-3 text-sm font-normal align-top">
                          <div className="inline-block bg-gray-100 px-1.5 border-gray-200 border rounded-lg  whitespace-nowrap mr-1.5 mb-1">
                            Ankur P
                          </div>
                        </td>
                        <td className="text-left p-3 text-sm font-normal align-top"></td>
                        <td className="text-left p-3 text-sm font-normal align-top"></td>
                        <td className="text-left p-3 text-sm font-normal align-top"></td>
                      </tr>
                      <tr className="hover:bg-light-violet/20 transition ease-in-out duration-300 bg-light-violet/10 ">
                        <td className="p-3 text-center text-sm font-normal align-top">
                          2
                        </td>
                        <td className="text-left p-3 text-sm font-normal align-top"></td>
                        <td className="text-left p-3 text-sm font-normal align-top"></td>
                        <td className="text-left p-3 text-sm font-normal align-top"></td>
                        <td className="text-left p-3 text-sm font-normal align-top"></td>
                      </tr>
                      <tr className="hover:bg-light-violet/20 transition ease-in-out duration-300 ">
                        <td className="p-3 text-center text-sm font-normal align-top">
                          1
                        </td>
                        <td className="text-left p-3 text-sm font-normal align-top"></td>
                        <td className="text-left p-3 text-sm font-normal align-top"></td>
                        <td className="text-left p-3 text-sm font-normal align-top"></td>
                        <td className="text-left p-3 text-sm font-normal align-top"></td>
                      </tr>
                      <tr className="hover:bg-light-violet/20 transition ease-in-out duration-300 bg-light-violet/10 ">
                        <td className="p-3 text-center text-sm font-normal align-top">
                          DIAGNOSTIC NOT COMPLETED
                        </td>
                        <td className="text-left p-3 text-sm font-normal align-top">
                          <div className="inline-block bg-gray-100 px-1.5 border-gray-200 border rounded-lg  whitespace-nowrap mr-1.5 mb-1">
                            Juan Nassiff
                          </div>
                          <div className="inline-block bg-gray-100 px-1.5 border-gray-200 border rounded-lg  whitespace-nowrap mr-1.5 mb-1">
                            Sonam Sonam
                          </div>
                          <div className="inline-block bg-gray-100 px-1.5 border-gray-200 border rounded-lg  whitespace-nowrap mr-1.5 mb-1">
                            Will Elm
                          </div>
                        </td>
                        <td className="text-left p-3 text-sm font-normal align-top">
                          <div className="inline-block bg-gray-100 px-1.5 border-gray-200 border rounded-lg  whitespace-nowrap mr-1.5 mb-1">
                            Ankur P
                          </div>
                          <div className="inline-block bg-gray-100 px-1.5 border-gray-200 border rounded-lg  whitespace-nowrap mr-1.5 mb-1">
                            Juan Nassiff
                          </div>
                          <div className="inline-block bg-gray-100 px-1.5 border-gray-200 border rounded-lg  whitespace-nowrap mr-1.5 mb-1">
                            Sonam Sonam
                          </div>
                          <div className="inline-block bg-gray-100 px-1.5 border-gray-200 border rounded-lg  whitespace-nowrap mr-1.5 mb-1">
                            Will Elm
                          </div>
                        </td>
                        <td className="text-left p-3 text-sm font-normal align-top">
                          <div className="inline-block bg-gray-100 px-1.5 border-gray-200 border rounded-lg  whitespace-nowrap mr-1.5 mb-1">
                            Ankur P
                          </div>
                          <div className="inline-block bg-gray-100 px-1.5 border-gray-200 border rounded-lg  whitespace-nowrap mr-1.5 mb-1">
                            Juan Nassiff
                          </div>
                          <div className="inline-block bg-gray-100 px-1.5 border-gray-200 border rounded-lg  whitespace-nowrap mr-1.5 mb-1">
                            Sonam Sonam
                          </div>
                          <div className="inline-block bg-gray-100 px-1.5 border-gray-200 border rounded-lg  whitespace-nowrap mr-1.5 mb-1">
                            Will Elm
                          </div>
                        </td>
                        <td className="text-left p-3 text-sm font-normal align-top">
                          <div className="inline-block bg-gray-100 px-1.5 border-gray-200 border rounded-lg  whitespace-nowrap mr-1.5 mb-1">
                            Juan Nassiff
                          </div>
                          <div className="inline-block bg-gray-100 px-1.5 border-gray-200 border rounded-lg  whitespace-nowrap mr-1.5 mb-1">
                            Sonam Sonam
                          </div>
                          <div className="inline-block bg-gray-100 px-1.5 border-gray-200 border rounded-lg  whitespace-nowrap mr-1.5 mb-1">
                            Will Elm
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div className="mb-4 pb-4 border-b-2 overflow-x-auto xl:overflow-x-visible"></div>
                <div
                  className={`hidden sm:flex-1 sm:flex sm:items-center sm:justify-between`}
                >
                  {gradeBook.classData.length !== 0 && (
                    <Pagination
                      totalRecords={
                        gradeBook.classData?.length
                          ? gradeBook.classData?.length
                          : 0
                      }
                      pageSize={10}
                      /* onPageChange={onPageChange}
                      reset={resetPageNumber} */
                      bgColor="primary-violet"
                    />
                  )}
                  {gradeBook.classData?.length !== 0 && (
                    <PageSize
                      /* pageSize={pageSize}
                      onPageSizeChange={onPageSizeChange} */
                      bgColor="primary-violet"
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default connect((state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
})(LiftoffGalaxySummaryReport);

import { Fragment } from "react";
import { Info } from "../../assets/icons";

interface InfoMessageProps {
  message: string;
  title?: string;
  className?: string;
}
function InfoMessage(props: InfoMessageProps) {
  return (
    <Fragment>
      <div
        className={`${
          props.className ? props.className : "my-4"
        } text-sm text-gray-700 font-light bg-blue-50 p-4 flex w-full rounded-md rounded-t-lg sm:rounded-lg overflow-hidden`}
      >
        <div className="flex-shrink-0 current-fill text-blue-400 mt-0.5">
          <Info title={props.title ? props.title : ""} />
        </div>
        <div
          className="pl-2"
          dangerouslySetInnerHTML={{ __html: props.message }}
        />
      </div>
    </Fragment>
  );
}

export default InfoMessage;

import { AxiosResponse } from 'axios';
import { AxiosHelper } from "../../environment/axiosConfig";
import MyStatDetail from '../../model/student/shared/myStats/myStatDetail';
import MyStatSummary from '../../model/student/shared/myStats/myStatSummary';
import APIConstant from "../../utils/constant/apiConstant";

export function getMyStatsBySubject(studentId: number, subjectId:number): Promise<AxiosResponse<Array<MyStatDetail>>> {
    return AxiosHelper.axiosInstance().get<Array<MyStatDetail>>(APIConstant.Student_API.MyStatsBySubject, {
        params: {
            studentId: studentId,
            subjectId: subjectId
        }
    });
}

export function getMyStatSummary(studentId: number, programMode: number): Promise<AxiosResponse<MyStatSummary>> {
    return AxiosHelper.axiosInstance().get<MyStatSummary>(APIConstant.Student_API.MyStatSummary, {
        params: {
            studentId: studentId,
            programMode: programMode
        }
    });
}
import React from "react";

export const NotFound: React.FC = () => {
  return (
    <div className="w-full h-screen place-items-center grid">
      <div className="grid text-center">
        <span className="text-3xl">404</span>
        <span className="text-xl">Not Found</span>
        <span className="text-red-500">
          Requested resource could not be found on server!
        </span>
      </div>
    </div>
  );
};

export default NotFound;

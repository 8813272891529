import {
  forwardRef,
  Fragment,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import Select from "react-select";
import { AxiosHelper } from "../../../../../environment/axiosConfig";
import { ISelectElement } from "../../../../../model/interface/selectElement";
import APIConstant from "../../../../../utils/constant/apiConstant";

interface passedProps {
  userId: number;
  roleId: number;
  onChange: any;
  className?: string;
  defaultSubjectId?: number;
  refreshOnDefaultSubjectSelection?: any;
}
const SubjectList = forwardRef((props: passedProps, ref: any) => {
  const defaultValue = { label: "Select", value: "0" };
  const { userId } = props;
  const [subjects, setSubjects] = useState<Array<ISelectElement>>([]);
  const [selectedSubject, setSelectedSubject] = useState<ISelectElement>();
  const getTeacherSubjects = (teacherId: number) => {
    return AxiosHelper.axiosInstance().get(APIConstant.APP_SUBJECTS_ALL, {
      params: {
        TeacherId: teacherId,
      },
    });
  };

  function bindSubjects() {
    getTeacherSubjects(userId).then((response) => {
      const subjectsData = response.data;
      var subjects: Array<ISelectElement> = new Array<ISelectElement>();
      subjectsData.map((values: any) => {
        subjects.push({
          label: values?.name,
          value: values?.subjectId,
        });
        return null;
      });
      setSubjects(subjects);
      if (props.defaultSubjectId) {
        const defaultSubject = subjectsData.find(
          (x) => x.subjectId === Number(props.defaultSubjectId)
        );
        if (defaultSubject) {
          let defaultSelection = {
            label: defaultSubject.name,
            value: defaultSubject.subjectId,
          };
          setSelectedSubject(defaultSelection);
          props.refreshOnDefaultSubjectSelection(defaultSelection);
        } else {
          props.refreshOnDefaultSubjectSelection(null);
        }
      } else {
        props.refreshOnDefaultSubjectSelection(null);
      }
    });
  }

  useImperativeHandle(ref, () => ({
    resetSubject,
    bindSelectedSubject,
  }));

  const resetSubject = () => {
    setSelectedSubject(defaultValue);
  };
  const bindSelectedSubject = (val: any) => {
    setSelectedSubject(val);
  };

  useEffect(() => {
    bindSubjects();
  }, []);

  return (
    <Fragment>
      <label
        className={`block text-sm text-gray-500 ${
          props.className ? props.className : ""
        }`}
      >
        Subject
      </label>
      <Select
        value={selectedSubject}
        options={subjects}
        onChange={props.onChange}
      />
    </Fragment>
  );
});
export default SubjectList;

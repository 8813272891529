import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import MyStatDetail from "../../../../../model/student/shared/myStats/myStatDetail";
import MyStatSummary from "../../../../../model/student/shared/myStats/myStatSummary";
import Breadcrumb from "../../middleSchool/breadcrumb";
import { IBreadcrumb } from "../../../../../model/common/breadcrumb";
import routeConstant from "../../../../../utils/constant/routeConstant";
import {
  getMyStatsBySubject,
  getMyStatSummary,
} from "../../../../../api/student/myStatsApi";
import { fetchProfile } from "../../../../../redux/actions/userActions";
import { connect } from "react-redux";
import * as userContextAction from "../../../../../redux/actions/userContextAction";
import { getStudentSubject } from "../../../../../api/student/studentSubjectApi";
import { ISubject } from "../../../../../model/interface/subject";
import {
  BookIcon,
  EHStarIcon1,
  EHStarIcon2,
  EHStarIcon3,
} from "../../../../../assets/icons";
import constant from "../../../../../utils/constant/constant";

function MyStat(props: any) {
  const { t } = useTranslation();

  const [myStatSummary, setMyStatSummary] = useState<MyStatSummary>();
  const [myStatDetails, setMyStatDetails] = useState<Array<MyStatDetail>>([]);
  const [subjects, setSubjects] = useState<Array<ISubject>>();
  const [selectedSubject, setSelectedSubject] = useState<ISubject>();
  const programMode =
    localStorage.getItem("programMode") ?? constant.ProgramMode.EG;
  const breadcrumbItems = () => {
    const items: Array<IBreadcrumb> = [
      {
        name: t("Breadcrumb.Home"),
        url: programMode !== constant.ProgramMode.LO 
          ? routeConstant.StudentRoute.Home
          : props?.profile?.gradeId >= constant.Grade.GRADE6
            ? routeConstant.LiftOff.MiddleSchool.Home
            : routeConstant.LiftOff.Elementary.Home,
        textColor: "text-gray-500",
      },
      {
        name: t("Breadcrumb.MyStats"),
        textColor: "text-gray-500",
        url: "",
      },
    ];
    return items;
  };

  // const getColor = (name: string) => {
  //   if (name.includes("Math")) {
  //     return "bg-[#00b1fb]";
  //   }
  //   if (name.includes("Reading")) {
  //     return "bg-[#34D9A5]";
  //   }
  // };
  const onSubjectSelect = (subject: ISubject) => {
    getMyStatsBySubject(props.userContext.userId, subject.subjectId).then(
      (response) => {
        setMyStatDetails(response.data);
      }
    );
    setSelectedSubject(subject);
  };
  useEffect(() => {
    getMyStatSummary(
      props.userContext.userId ?? 0,
      programMode === constant.ProgramMode.LO ? 2 : 1
    ).then((response) => {
      setMyStatSummary(response.data);
    });
    if (programMode === constant.ProgramMode.EG) {
      getStudentSubject(props.userContext.userId ?? 0).then((response) => {
        setSubjects(response.data);
        onSubjectSelect(response.data[0]);
      });
    }
  }, []);

  return (
    <Fragment>
      {/* main section */}
      <div className="w-full p-5">
        <nav className="flex px-2 pb-3 pt-2 border-b" aria-label="Breadcrumb">
          <Breadcrumb items={breadcrumbItems()} />
        </nav>
        <section>
          <h2 className="text-lg mt-2 px-2 pb-3 pt-2">
            {t("Shared.StudentSideBar.MyStats")}
          </h2>
        </section>
        <section>
          <div>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 bg-gray-100 px-6 py-6 rounded-2xl border">
              <div className="border-0 lg:border-r">
                <h3 className="text-center sm:text-left text-lg mb-2">
                  {t("MyStats.Score")}
                </h3>
                <div className="grid grid-cols-1 sm:grid-cols-3 bg-white rounded-2xl lg:mr-4 py-3 shadow-sm border-4 border-orange-dark ">
                  <div className="col-span-1 flex flex-col items-center font-light text-base text-center gap-y-2">
                    <h4 className=" ">{t("MyStats.QuestionsAnswered")}</h4>
                    <span className="flex items-center justify-center font-semibold bg-secondary-teal text-white w-8 h-8 rounded-full mt-auto">
                      {myStatSummary?.questionsAnswered}
                    </span>
                  </div>
                  <div className="col-span-1 flex flex-col items-center font-light text-base text-center gap-y-2">
                    <h4>{t("MyStats.QuestionsCorrect")}</h4>
                    <span className="flex items-center justify-center font-semibold bg-secondary-teal text-white w-8 h-8 rounded-full mt-auto">
                      {myStatSummary?.questionsCorrect}
                    </span>
                  </div>
                  <div className="col-span-1 flex flex-col items-center font-light text-base text-center gap-y-2">
                    <h4>{t("MyStats.OverallScore")}</h4>
                    <span className="flex font-semibold mt-auto">
                      <span className="flex items-center justify-center font-semibold bg-secondary-teal text-white w-8 h-8 rounded-full mr-0.5">
                        {myStatSummary?.overallScore}
                      </span>
                      %
                    </span>
                  </div>
                </div>
              </div>
              <div className="flex flex-col">
                <h3 className="text-lg text-center sm:text-left mb-2">
                  {t("MyStats.Time")}
                </h3>
                <div className="grid grid-cols-1 sm:grid-cols-4 bg-white rounded-2xl py-3 shadow-sm flex-grow border-4 border-orange-dark ">
                  <div className="col-span-1 flex flex-col items-center font-light text-base text-center">
                    <h4 className=" leading-4 xl:leading-none mb-1">
                      {t("MyStats.Days")}
                    </h4>
                    <span className="flex items-center justify-center font-semibold bg-secondary-teal text-white w-8 h-8 rounded-full mt-auto">
                      {myStatSummary?.days}
                    </span>
                  </div>
                  <div className="col-span-1 flex flex-col items-center font-light text-base text-center">
                    <h4 className="leading-4 xl:leading-none mb-1">
                      {t("MyStats.Hours")}
                    </h4>
                    <span className="flex items-center justify-center font-semibold bg-secondary-teal text-white w-8 h-8 rounded-full mt-auto">
                      {myStatSummary?.hours}
                    </span>
                  </div>
                  <div className="col-span-1 flex flex-col items-center font-light text-base text-center">
                    <h4 className="leading-4 xl:leading-none mb-1">
                      {t("MyStats.Minutes")}
                    </h4>
                    <span className="flex items-center justify-center font-semibold bg-secondary-teal text-white w-8 h-8 rounded-full mt-auto">
                      {myStatSummary?.minutes}
                    </span>
                  </div>
                  <div className="col-span-1 flex flex-col items-center font-light text-base text-center">
                    <h4 className="leading-4 xl:leading-none mb-1">
                      {t("MyStats.Seconds")}
                    </h4>
                    <span className="flex items-center justify-center font-semibold bg-secondary-teal text-white w-8 h-8 rounded-full mt-auto">
                      {myStatSummary?.seconds}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {programMode === constant.ProgramMode.EG && (
          <section className="mt-10">
            <div className="grid grid-cols-1 xl:grid-cols-10 gap-6">
              <div className="xl:col-span-3 space-y-2 pl-4">
                {subjects?.map((s) => {
                  return (
                    <div
                      className={
                        (selectedSubject && selectedSubject.name === s.name
                          ? "bg-[#00b1fb]"
                          : "hover:bg-gray-100") +
                        " block  px-4 py-1 rounded-lg"
                      }
                    >
                      <button
                        className="w-full"
                        onClick={() => onSubjectSelect(s)}
                      >
                        <h2 className="text-base py-1 flex items-center text-left">
                          {s.subjectIcon && s.subjectIcon !== "null" && (
                            <img
                              className="h-4 mr-2 flex-shrink-0"
                              srcSet={s.subjectIcon}
                              alt="Subject Icon"
                            />
                          )}
                          {s.subjectIcon === "null" && (
                            <>
                              <BookIcon className="h-4 mr-2 flex-shrink-0" />
                            </>
                          )}

                          {s.name}
                        </h2>
                      </button>
                    </div>
                  );
                })}
              </div>
              <div className="xl:col-span-7 h-full">
                <div className="h-full bg-[#00b1fb] rounded-2xl relatives">
                  <span className="py-2 px-5 mt-5 mb-5 left-2 sm:left-6 relative border border-gray-200 inline-flex items-center rounded-md text-sm  text-gray-900 bg-white shadow-xl transition ease-in-out duration-150">
                    {selectedSubject?.subjectIcon &&
                      selectedSubject.subjectIcon !== "null" && (
                        <img
                          className="h-4 mr-2 flex-shrink-0 m-auto"
                          src={selectedSubject.subjectIcon}
                          alt="Subject Icon"
                        />
                      )}
                    {selectedSubject?.subjectIcon === "null" && (
                      <>
                        <BookIcon className="h-4 mr-2 flex-shrink-0 m-auto" />
                      </>
                    )}

                    {selectedSubject?.name}
                  </span>
                  {myStatDetails.map((myStatDetail, index) => {
                    return (
                      <div
                        key={index}
                        className=" mx-auto px-2 py-2 sm:flex-col w-full sm:px-6 lg:px-6 -mt-3"
                      >
                        <h3 className="text-lg font-semibold mb-1 inline-block text-black opacity-70">
                          {myStatDetail.domainName}
                        </h3>
                        <div className="overflow-auto  rounded-lg mb-5">
                          <table className="table min-w-full text-sm bg-white/50 rounded-lg">
                            <thead className=" rounded-2xl overflow-hidden">
                              <tr className="bg-white px-2 rounded-2xl overflow-hidden space-x-4 rounded-2xl overflow-hidden">
                                {/* <th className="font-normal text-left px-2  rounded-tl-lg ">
                                {t("MyStats.Standard")}
                              </th> */}
                                <th className="px-3 py-3 w-20 text-left text-xs font-medium text-gray-500 capitalize tracking-wider bg-gray-100">
                                  {t("MyStats.Name")}
                                </th>
                                <th className="px-3 py-3 w-20 text-center text-xs font-medium text-gray-500 capitalize tracking-wider bg-gray-100">
                                  {t("MyStats.TotalAnswered")}
                                </th>
                                <th className="px-3 py-3 w-20 text-center text-xs font-medium text-gray-500 capitalize tracking-wider bg-gray-100">
                                  {t("MyStats.TotalCorrect")}
                                </th>
                                <th className="px-3 py-3 w-20 text-center text-xs font-medium text-gray-500 capitalize tracking-wider bg-gray-100">
                                  {t("MyStats.OverallScore")}
                                </th>
                                <th className="px-3 py-3 w-20 text-center text-xs font-medium text-gray-500 capitalize tracking-wider bg-gray-100">
                                  {t("MyStats.LastSessionScore")}
                                </th>
                                <th className="px-3 py-3 w-20 text-center text-xs font-medium text-gray-500 capitalize tracking-wider bg-gray-100">
                                  {t("MyStats.GalaxyStar")}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {myStatDetail.standards.map((standard, index) => {
                                return (
                                  <tr
                                    className={
                                      index % 2 === 0
                                        ? "bg-white"
                                        : "bg-opacity-90"
                                    }
                                    key={index}
                                  >
                                    {/* <td className="font-light px-2 py-2 text-yellow-700">
                                    {standard.standard}
                                  </td> */}
                                    <td className="px-3 py-2 text-sm text-gray-500">
                                      {standard.standardName}
                                    </td>
                                    <td className="px-3 py-2 text-center text-sm text-gray-500">
                                      {standard.totalQuestions}
                                    </td>
                                    <td className="px-3 py-2 text-center text-sm text-gray-500">
                                      {standard.totalCorrect}
                                    </td>
                                    <td className="px-3 py-2 text-center text-sm text-gray-500">
                                      {standard.overallScore} %
                                    </td>
                                    <td className="px-3 py-2 text-center text-sm text-gray-500">
                                      {standard.lastSessionScore}%
                                    </td>
                                    <td className="px-3 py-2 text-center text-sm text-gray-500">
                                      <span className="h-6 flex justify-center">
                                        {standard.galaxyStar === 1 && (
                                          <EHStarIcon1></EHStarIcon1>
                                        )}
                                        {standard.galaxyStar === 2 && (
                                          <EHStarIcon2></EHStarIcon2>
                                        )}
                                        {standard.galaxyStar === 3 && (
                                          <EHStarIcon3></EHStarIcon3>
                                        )}
                                      </span>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    );
                  })}
                  {myStatDetails.length === 0 && (
                    <div className="flex px-8 py-4">
                      <span className="text-sm">No records found</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </section>
        )}
      </div>
    </Fragment>
  );
}

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    setUserContext: (payload: userContextAction.UserContextState) => {
      dispatch(userContextAction.setUserContext(payload));
    },
    setProfile: (
      userId: number,
      roleId: number,
      schoolId: number,
      districtId: number
    ) => {
      dispatch(fetchProfile(userId, roleId, schoolId, districtId));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(MyStat);

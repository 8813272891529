import { XIcon } from "@heroicons/react/outline";
import React, { useState } from "react";
import userTag from "../../../../../model/teacher/tags/userTag";
import ColorPicker from "../../../../shared/colorPicker";
import { checkUserTagExists } from "../../../../../api/teacher/tags";

interface TagDetailPopupProps {
  showPopUp: (show: boolean) => void;
  handleSave: (userTag: userTag) => void;
  tag: userTag;
}

const TagDetailPopup = (props: TagDetailPopupProps) => {
  const { tag } = props;
  const [isValid, setIsValid] = useState(true);
  const [isduplicateTagname, setIsDuplicatedTagName] = useState(false);
  const [isActive, setIsActive] = useState<boolean>(props.tag.isActive);

  const handleNameChange = (value) => {
    tag.name = value;
  };

  const handleDescriptionChange = (value) => {
    tag.description = value;
  };

  const handleColorChange = (value) => {
    tag.color = value;
  };

  const handleIsActiveChange = (value) => {
    tag.isActive = value;
    setIsActive(value);
  };

  const validate = (tag: userTag) => {
    let isValid = true;
    if (tag.name === undefined || tag.name === "") {
      isValid = false;
    }
    setIsValid(isValid);
    return isValid;
  };

  const handleSave = () => {
    resetValidation();
    if (validate(tag)) {
      checkUserTagExists(tag.userTagId, tag.name, tag.userId).then((r) => {
        if (!r.data) {
          props.handleSave(tag);
        } else {
          setIsDuplicatedTagName(true);
          setIsValid(false);
        }
      });
    }
  };

  const resetValidation = () => {
    setIsDuplicatedTagName(false);
    setIsValid(true);
  };

  return (
    <div className="fixed inset-0 z-40 flex items-center bg-black bg-opacity-50 sm:items-center sm:justify-center enter-done">
      <div
        className="w-full overflow-hidden bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:max-w-2xl"
        role="dialog"
      >
        <div className="flex-1">
          {/* Header */}
          <div className="px-4 py-4 bg-gray-50 sm:px-4">
            <div className="flex items-start justify-between space-x-3">
              <div className="space-y-1">
                <div className="text-lg font-medium text-gray-900">
                  Tag Details
                </div>
              </div>
              <div className="h-7 flex items-center">
                <button
                  type="button"
                  className="text-gray-400 hover:text-gray-500"
                  onClick={() => props.showPopUp(false)}
                >
                  <span className="sr-only">Close panel</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>

          {/* <!-- Divider container -->  */}
          <div className="text-sm text-gray-700 px-4 py-4">
            <div className="grid grid-cols-2 gap-4">
              <div className="space-y-2">
                <div>
                  <label className="block text-sm text-gray-500">Name</label>
                  <input
                    type="text"
                    id="name"
                    defaultValue={props.tag.name}
                    className="py-1.5 px-3 block w-full shadow-sm focus:ring-primary-violet focus:border-primary-violet border border-gray-300 rounded-md"
                    onChange={(e) => {
                      handleNameChange(e.target.value);
                    }}
                  />
                  <small className="text-red-400">
                    {!isValid && tag.name === "" && (
                      <span>Tag name is required</span>
                    )}
                    {!isValid && isduplicateTagname && (
                      <span>Tag name already exists</span>
                    )}
                  </small>
                </div>

                <div>
                  <label className="block text-sm text-gray-500">
                    Description
                  </label>
                  <input
                    type="text"
                    id="display-name"
                    defaultValue={props.tag.description}
                    className="py-1.5 px-3 block w-full shadow-sm focus:ring-primary-violet focus:border-primary-violet border border-gray-300 rounded-md"
                    onChange={(e) => {
                      handleDescriptionChange(e.target.value);
                    }}
                  />
                </div>
              </div>

              <div>
                <div>
                  <label className="block text-sm text-gray-500 whitespace-nowrap">
                    Color
                  </label>
                  <ColorPicker
                    defaultValue={props.tag.color}
                    setSelectedColor={(e) => {
                      handleColorChange(e);
                    }}
                  />
                </div>

                <div className="mt-10">
                  <label className="cursor-pointer flex items-center text-sm text-gray-500">
                    <input
                      type="checkbox"
                      name="isActive"
                      className="h-4 w-4 mr-2 mt-0.5 cursor-pointer text-blue-600 border-gray-300 focus:ring-blue-500"
                      checked={isActive}
                      onChange={(e) => {
                        handleIsActiveChange(!props.tag.isActive);
                      }}
                    />
                    Active
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Action Buttons -->  */}
        <footer className="flex mt-4 items-center justify-end px-6 py-4 sm:flex-row bg-gray-50 dark:bg-gray-800">
          <div className="flex-shrink-0">
            <div className="space-x-3 flex justify-end">
              <button
                className="align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 rounded-lg text-sm text-white border border-transparent bg-primary-green focus:shadow-outline-purple w-full sm:w-auto"
                type="button"
                onClick={() => {
                  handleSave();
                }}
              >
                Save
              </button>
              <button
                className="align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 rounded-lg text-sm text-gray-600 border-gray-300 border dark:text-gray-400 focus:outline-none active:bg-transparent hover:border-gray-500 focus:border-gray-500 active:text-gray-500 focus:shadow-outline-gray w-full sm:w-auto"
                type="button"
                onClick={() => {
                  props.showPopUp(false);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default TagDetailPopup;

import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { Fragment, useEffect, useState } from "react";
import { fetchProfile } from "../../redux/actions/userActions";
import * as userContextAction from "../../redux/actions/userContextAction";
import { connect } from "react-redux";
import ProfileModel from "../../model/users/profile";
import moment from "moment";
import { IAccountDetailSearch } from "../../model/interface/accountDetail";
import {
  AddSubscriptionRequest,
  GetAccountDetail,
} from "../../api/account/accountApi";
import { toast } from "react-toastify";
import { SubscriptionRequestType } from "../../types/type";
interface UpGradeRequestPopupProps {
  userContext: userContextAction.UserContextState;
  profile?: ProfileModel;
  togglePopup: (popupAction: boolean) => void;
}

function UpGradeRequestPopup(props: UpGradeRequestPopupProps) {
  const { togglePopup } = props;
  const [loading, setLoading] = useState(false);
  const [renewalText, setRenewalText] = useState("");
  const [subjectIds, setsubjectIds] = useState<Array<number>>([]);

  function getPurchasedLicense(schoolId?: number) {
    setsubjectIds([]);
    const accountSearch: IAccountDetailSearch = {
      userId: props.userContext?.userId ?? 0,
      schoolId: schoolId,
    };
    setLoading(true);
    GetAccountDetail(accountSearch)
      .then((d) => {
        if (d.data) {
          let subIds = d.data.purchasedLicense.map((m) => m.subjectId);
          setsubjectIds(subIds);
        }
        getSubjects(d.data);
        setLoading(false);
      })
      .catch((e) => setLoading(false));
  }

  function getRequestor() {
    const accountIdText =
      props.userContext && props.userContext.accountId
        ? `- ${props.userContext.accountId}`
        : "";
    return `${props.profile?.lastName}, ${props.profile?.firstName} ${accountIdText}`;
  }

  function getSubjects(account: any) {
    var subjects = account.purchasedLicense.map((m) => m.name);
    createRequesterText(subjects);
  }

  function createRequesterText(subjects: Array<string>) {
    var text =
      "Hello, I would like to upgrade my account. I am currently subscribed to \n\n";
    var index: number = 1;
    subjects.forEach((sub) => {
      text = text + index.toString() + ". " + sub + "\n";
      index++;
    });
    setRenewalText(text);
  }

  function sendSubscriptionRequest() {
    AddSubscriptionRequest({
      cSMName: "",
      requestorUserId: props.userContext?.userId ?? 0,
      requestText: renewalText,
      subscriptionRequestTypeId: SubscriptionRequestType.Upgrade,
      subjectIds: subjectIds,
      schoolId: props.userContext?.schoolId,
    }).then((d) => {
      toast.info("Subscription upgrade request sent successfully");
      togglePopup(false);
    });
  }
  useEffect(() => {
    getPurchasedLicense();
  }, []);

  return (
    <Fragment>
      <Transition.Root show={true} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-50 inset-0 overflow-y-auto"
          onClose={() => {}}
        >
          <div>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="fixed inset-0 z-40 flex items-center bg-black bg-opacity-50 sm:items-center sm:justify-center">
                <div
                  className="w-full flex flex-col justify-between overflow-hidden h-auto max-h-80percent bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:max-w-2xl"
                  role="dialog"
                >
                  <header>
                    {/* Header */}
                    <div className="px-4 py-4 bg-gray-50">
                      <div className="flex items-start justify-between space-x-3">
                        <div className="space-y-1">
                          <div className="text-lg font-medium text-gray-900">
                            Upgrade Request
                          </div>
                        </div>
                        <div className="h-7 flex items-center">
                          <button
                            type="button"
                            className="text-gray-400 hover:text-gray-500"
                            onClick={() => togglePopup(false)}
                          >
                            <span className="sr-only">Close panel</span>
                            <XIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </header>

                  <div className={`w-full relative overflow-auto px-4 py-3`}>
                    {/* <!-- Divider container -->  */}
                    <div className="p-3 sm:py-0 sm:space-y-0 sm:divide-y sm:divide-gray-200 text-sm text-gray-700 dark:text-gray-400">
                      <div>
                        <div className="mb-4">
                          <div className="grid grid-cols-1 gap-6 flex items-end mt-3">
                            <div className="col-span-12 sm:col-span-4 xl:col-span-3">
                              <label className="block text-sm text-gray-500">
                                Requestor
                              </label>
                              <input
                                type="text"
                                className="bg-gray-100  py-1.5 px-3 block w-full shadow-sm focus:ring-primary-violet focus:border-primary-violet border border-gray-300 rounded-md"
                                disabled={true}
                                value={getRequestor()}
                              />
                            </div>
                            <div className="col-span-12 sm:col-span-4 xl:col-span-3">
                              <label className="block text-sm text-gray-500">
                                Date
                              </label>
                              <input
                                type="text"
                                className="bg-gray-100 py-1.5 px-3 block w-full shadow-sm focus:ring-primary-violet focus:border-primary-violet border border-gray-300 rounded-md"
                                disabled={true}
                                value={moment(new Date()).format("MM/DD/yyyy")}
                              />
                            </div>
                            <div className="col-span-12 sm:col-span-4 xl:col-span-3">
                              <label className="block text-sm text-gray-500">
                                Text
                              </label>
                              <textarea
                                disabled={loading}
                                rows={5}
                                className={
                                  (loading ? "bg-gray-100 " : "") +
                                  "py-1.5 px-3 block w-full shadow-sm focus:ring-primary-violet focus:border-primary-violet border border-gray-300 rounded-md"
                                }
                                value={renewalText}
                                onChange={(e) => {
                                  setRenewalText(e.target.value);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <!-- Action Buttons -->  */}
                  <footer className="flex flex-col items-center justify-end px-6 py-4 sm:flex-row bg-gray-50 dark:bg-gray-800">
                    <div className="flex-shrink-0">
                      <div className="space-x-3 flex justify-end">
                        <button
                          disabled={
                            renewalText && renewalText.trim() ? false : true
                          }
                          className="disabled:opacity-50 align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 rounded-lg text-sm text-white border border-transparent bg-primary-blue focus:shadow-outline-purple w-full sm:w-auto"
                          type="button"
                          onClick={sendSubscriptionRequest}
                        >
                          {" "}
                          Upgrade Request
                        </button>
                        <button
                          className="align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 rounded-lg text-sm text-gray-600 border-gray-300 border dark:text-gray-400 focus:outline-none active:bg-transparent hover:border-gray-500 focus:border-gray-500 active:text-gray-500 focus:shadow-outline-gray w-full sm:w-auto"
                          type="button"
                          onClick={() => {
                            togglePopup(false);
                          }}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </footer>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </Fragment>
  );
}

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setUserContext: (payload: userContextAction.UserContextState) => {
      dispatch(userContextAction.setUserContext(payload));
    },
    setProfile: (
      userId: number,
      roleId: number,
      schoolId: number,
      districtId: number
    ) => {
      dispatch(fetchProfile(userId, roleId, schoolId, districtId));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UpGradeRequestPopup);

import { useEffect, useState, useRef } from 'react'
import classNames from 'classnames'
import '../../../../../css/style.css'
import constant from '../../../../../utils/constant/constant'
import Select from 'react-select'
import { getSchoolByDistrictId } from '../../../../../api/teacher/school'
import ConstructedResponseReport from '../../../../screen/results/crReport'
import moment from 'moment'
import GradeCRQuestion from '../../../../screen/results/gradeCRQuestion'
import GradeAssesmentWithCR from '../../../../screen/results/gradeAssesmentWithCR'
import ClassDropDown from '../../shared/classDropDown'
import ResultsReportByStudentByStandard from './tabs/resultsReportByStudentByStandard'
import { ResultsReportItemAnalysis } from './tabs/resultReportItemAnalysis'
import { ResultsReportItemAnalysisBeta } from './tabs/resultReportItemAnalysisBeta'
import { ISelectElement } from '../../../../../model/interface/selectElement'
import {
  getAllClassesByAssignment,
  getAssignmentByAssignmentActivityId
} from '../../../../../api/teacher/assignment'
import { ResultReportAssessmentStudentDetailsBySchool } from './tabs/resultReportAssessmentStudentDetailsBySchool'
import { ResultReportAssignmentStudentDetailsByClass } from './tabs/resultReportAssignmentStudentDetailsByClass'
import { ResultReportAssessmentMasteryByClass } from './tabs/resultReportAssessmentMasteryByClass'
import { ResultReportAssessmentMasteryBySchool } from './tabs/resultReportAssessmentMasteryBySchool'
import {
  getAssignmentResultReportItemAnalysis,
  getAssessmentResultReportItemAnalysis,
  getAssessmentResultReportHeader,
  getAssignmentResultReportHeader,
  getAssessmentResultReportByStudent,
  getAssignmentResultReportByStudent,
  getPremiumAssessmentResultReportHeader,
  getPremiumAssessmentResultReportByStudent,
  getPremiumAssessmentResultReportItemAnalysis
} from '../../../../../api/report/resultsReport'
import AllowedTo from '../../../../shared/rbac'
import permissions from '../../../../../utils/permissions'
import { getAllClassesBySubject } from '../../../../../api/teacher/teacher'
import ResultReportBySchool from './tabs/resultReportBySchool'
import MasteryReportFilterPrint from './print/masteryReportFilterPrint'
import { useReactToPrint } from 'react-to-print'
import { debounce } from 'lodash'
import { ResultReportAssessmentStudentAttempts } from './tabs/resultReportAssessmentStudentAttempts'
import { ResultReportAssignmentStudentAttempts } from './tabs/resultReportAssignmentStudentAttempts'
import { ReportType } from '../../../../../types/type'
import { UserContextState } from '../../../../../redux/actions/userContextAction'
import Profile from '../../../../../model/users/profile'
import { capitalizeWords } from '../../../../../utils/helper'

interface IClasses {
  classId: number
  displayName: string
  className: string
  isAdditionalClass: false
}

interface IResultDataProps {
  userContext: UserContextState
  profile: Profile
  reportType: ReportType
  assignmentId?: number
  assignmentActivityId?: number
  assessmentId?: number
  schoolYearId?: number
  assessmentSubjectId?: number
  classId?: number
  hasCRActivity?: boolean
  redirectedFrom?: string
  assignmentName?: string
  activityType?: string | null,
  toggleLoader: (value: boolean) => void
}

const ResultsData = ({
  userContext,
  profile,
  reportType,
  assignmentId = 0,
  assignmentActivityId = 0,
  assessmentId = 0,
  schoolYearId = 0,
  assessmentSubjectId = 0,
  classId = 0,
  hasCRActivity = false,
  redirectedFrom = '',
  assignmentName = '',
  activityType = null,
  toggleLoader
}: IResultDataProps) => {  
  const [assignment, setAssignment] = useState<any>()
  const attemptTabNumber = 10

  const [tabs] = useState<any[]>([
    // {
    //   id: 1,
    //   name: "School Summary",
    //   roles: [4],
    // },
    {
      id: 2,
      name: 'School Dot Rank',
      permission_required: false,
      activeClass:
        'border-secondary-teal bg-white text-primary-violet hover:text-dark-violet py-3 tab-box-shadow tab-box-shadow',
      roles: [4, 5]
    },
    // {
    //   id: 3,
    //   name: "Teacher Comparison",
    //   roles: [4, 5],
    // },
    {
      id: 4,
      name: 'Student Dot Rank',
      permission_required: false,
      activeClass:
        'border-secondary-teal bg-white text-primary-violet hover:text-dark-violet py-3 tab-box-shadow tab-box-shadow',
      roles: [4, 5, 6, 7]
    },
    {
      id: 5,
      name: 'Completion Status',
      roles: [4, 5, 6, 7],
      activeClass:
        'border-secondary-teal bg-white text-primary-violet hover:text-dark-violet py-3 tab-box-shadow tab-box-shadow'
    },
    {
      id: 6,
      name: 'Item Analysis',
      permission_required: true,
      permission_name: permissions.reports_results_item_analysis_view,
      activeClass:
        'border-secondary-teal bg-white text-primary-violet hover:text-dark-violet py-3 tab-box-shadow tab-box-shadow',

      roles: [4, 5, 6, 7],
      hideForCR: false
    },
    {
      id: 7,
      name: 'Item Analysis Beta',
      permission_required: true,
      permission_name: permissions.reports_results_item_analysis_beta_view,
      activeClass:
        'border-secondary-teal bg-white text-primary-violet hover:text-dark-violet py-3 tab-box-shadow tab-box-shadow',

      roles: [4, 5, 6, 7],
      hideForCR: true
    },
    {
      id: 8,
      name: 'Grade Constructed Response',
      roles: []
    },
    {
      id: 9,
      name: `${
        reportType === constant.AssessmentTypes.ASSESSMENT ||
        reportType === constant.AssessmentTypes.PREMIUMASSESSMENT
          ? capitalizeWords(constant.AssessmentTypes.ASSESSMENT)
          : capitalizeWords(constant.AssessmentTypes.ASSIGNMENT)
      } Mastery`,
      permission_required: false,
      activeClass:
        'border-secondary-teal bg-white text-primary-violet hover:text-dark-violet py-3 tab-box-shadow tab-box-shadow',
      roles: [4, 5, 6]
    },
    {
      id: attemptTabNumber,
      permission_required: true,
      permission_name: permissions.assessment_bank_students_attempts_tab_view,
      name: 'Student Attempts',
      activeClass:
        'border-secondary-teal bg-white text-primary-violet hover:text-dark-violet py-3 tab-box-shadow tab-box-shadow',
      roles: [4, 5, 6]
    }
  ])

  const [schoolOptions, setSchoolOptions] = useState<Array<ISelectElement>>([])
  const [selectedSchool, setSelectedSchool] = useState<Array<ISelectElement>>(
    []
  )
  const [selectedTab, setSelectedTab] = useState<number>(2)
  const [showSubHeader, setShowSubHeader] = useState<boolean>(false)

  let subjectId: number = 0

  const isDistrictUser = userContext.roleId === constant.UserRoleId.District

  //const [selectedClass, setSelectedClass] = useState<number>(classId)

  const [standardIds, setStandardIds] = useState<Array<number>>([])
  const [randomValue, setRandomValue] = useState<number>(0)
  const [downloadCount, setDownloadCount] = useState<number>(
    JSON.parse(localStorage.getItem('downloadCount')!) ?? 0
  )

  const [resultsReportMasteryData, setResultsReportMasteryData] = useState<
    Array<any>
  >([])

  const componentRefAssessmentMasteryPrint = useRef<HTMLDivElement>(null)
  const handleAssessmentMasteryPrint = useReactToPrint({
    content: () => componentRefAssessmentMasteryPrint.current,
    documentTitle: `ResultsReportMastery${
      downloadCount === 0 ? '' : '(' + downloadCount.toString() + ')'
    }`
  })  

  const defaultAllLabel = 'All Classes'
  const [header, setHeader] = useState<string>('')
  const [subHeader, setSubHeader] = useState<string>()
  const [isDomainActive, setIsDomainActive] = useState<boolean>(true)
  const [isSubHeaderDomainActive, setIsSubHeaderDomainActive] =
    useState<boolean>(true)
  const [crReport, setCRReport] = useState<any>()
  const [showCRReport, setShowCRReport] = useState<boolean>(true)
  const [gradeCRReport, setGradeCRReport] = useState<any>()
  const [averageScore, setAverageScore] = useState<number>(0)
  const [subHeaderAverageScore, setSubHeaderAverageScore] = useState<number>(0)
  const [resultHeader, setResultHeader] = useState<any>()
  const [subject, setSubject] = useState<number>(0)
  const [subjectName, setSubjectName] = useState<string>('')
  const [resultReportByStudent, setResultReportByStudent] = useState<any>()
  const [resultSubHeader, setResultSubHeader] = useState<any>()
  const [showLoader, setShowLoader] = useState<boolean>(false)
  const [noRecordFoundMsg, setNoRecordFoundMsg] = useState<string>('')
  const [resultsReportItemAnalysis, setResultsReportItemAnalysis] =
    useState<any>()
  const [pointsPossible, setPointsPossible] = useState<number>(0)
  const [overallMessage, setOverallMessage] = useState<string>('')

  const isAssessmentActivity = assessmentId !== null && assessmentId !== 0

  const AllSchoolOption: ISelectElement = {
    label: 'All Schools',
    value: '0'
  }

  //Classes
  const [classes, setClasses] = useState<IClasses[]>()
  const [selectedClasses, setSelectedClasses] = useState<Array<ISelectElement>>([{ label: defaultAllLabel, value: '0' }])
  const [selectedClassIds, setSelectedClassIds] = useState<number[]>([])

  const userSchoolIdAsArray = () => userContext.schoolId === 0 ? null : [userContext.schoolId];

  const getClassesByAssignment = async () => {
    const resp = await getAllClassesByAssignment(
      schoolYearId,
      assignmentId,
      assignmentActivityId,
      userContext.userId,
      userContext.roleId,
      userContext.schoolId,
      true
    )
    if (resp?.data?.data?.length) {
      setClasses(resp.data.data)
      if (classId !== 0) {
        let selectedClass: any = resp.data.data.find(
          (c) => c.classId === Number(classId)
        )

        if (selectedClass) {
          setSelectedClasses([
            { label: selectedClass.displayName, value: selectedClass.classId }
          ])
        }
      }
    }
  }

  const getAllTeacherClasses = async () => {
    const resp = await getAllClassesBySubject(
      userContext.userId,
      userContext.roleId,
      assessmentSubjectId,
      schoolYearId,
      userContext.schoolId
    )
    if (resp?.data?.data?.length) {
      setClasses(resp.data.data)
    }
  }

  const getSchools = async () => {
    const resp = await getSchoolByDistrictId(userContext?.districtId ?? 0)
    if (resp?.data.length) {
      let schoolData: any = Array.from(
        new Set(resp.data.map((s) => s.schoolId))
      ).map((schoolId) => {
        return {
          value: schoolId.toString(),
          label: resp.data.find((s) => s.schoolId === schoolId)?.name
        }
      })

      const options: Array<ISelectElement> = [AllSchoolOption]

      setSchoolOptions(options.concat(schoolData))
      setSelectedSchool([{ label: options[0].label, value: options[0].value }])
    }
  }

  const handleClassChange: any = (e: Array<ISelectElement>) => {
    setSelectedClasses(e)
  }

  const onSchoolChange = (e: any, isMulti: boolean) => {
    setSubHeader('')
    setRandomValue(Math.random())
    let selectedValues: Array<ISelectElement> = e

    if (selectedValues && selectedValues.length > 0) {
      const lastSelectedValue = selectedValues[selectedValues.length - 1]
      if (lastSelectedValue.value === '0') {
        selectedValues = [lastSelectedValue]
      } else if (
        selectedValues.some((r) => r.value === '0') &&
        selectedValues.some((r) => r.value !== '0')
      ) {
        selectedValues = selectedValues.filter((r) => r.value !== '0')
      }
    } 
    else if (!isMulti) {
      // Create a new array with the selected value
      selectedValues = [{ label: e.label, value: e.value } as ISelectElement]
    }
    else {
      
      selectedValues = [AllSchoolOption]
    }

    setSelectedSchool(selectedValues)

    const selectedSchools = selectedValues.map((s) => s.value);
    const selectedSchoolIds = selectedValues.map((s) => Number(s.value)).filter(s => s !== 0);

    if (selectedSchools.includes('0')) {
      // all schools
      setSubHeader('')
      setIsDomainActive(true)
      setShowSubHeader(false)
      getResultReportHeaders(null, null)
      getStudentResult(null, null)
      getItemAnalysis(null, null)
    } else {
      setIsSubHeaderDomainActive(true)
      getResultReportHeaders(selectedSchoolIds, null, true)
      getStudentResult(selectedSchoolIds, null)
      getItemAnalysis(selectedSchoolIds, null)
    }
    if (isDistrictUser) {
      if (selectedSchools.includes('0')) {
        setHeader('District')
      } else {
        setHeader('School')
      }
    }
  }

  const getSelectedSchoolsFilter = (): string | null => {
    let schools: string | null = null

    if (isDistrictUser) {
      let schoolIds = selectedSchool.map((s) => s.value)
      if (!schoolIds.includes('0')) {
        schools = String(schoolIds)
      }
    } else {
      schools = userContext.schoolId.toString()
    }

    return schools
  }

  const getSelectedSchoolsFilterAsIntArray = (): number[] => {
    let stringResult = getSelectedSchoolsFilter()
    if (stringResult) {
      return stringResult.split(',').map(Number)
    }
    return []
  }

  const getResultReportHeaders = async (
    schoolIds : Array<number> | null,
    classIds: Array<number> | null,
    hasSubHeader = false
  ) => {
    let response: any
    if (redirectedFrom === constant.RedirectedFrom.MYASSESSMENTS) {
      response = await getAssessmentResultReportHeader(
        assignmentActivityId,
        schoolIds ? String(schoolIds) : "",
        classIds,
        schoolYearId
      )
    } else if (redirectedFrom === constant.RedirectedFrom.PREMIUMASSESSMENTS) {
      response = await getPremiumAssessmentResultReportHeader(
        assignmentActivityId,
        schoolIds ?? [],
        classIds,
        schoolYearId
      )
    } else {
      response = await getAssignmentResultReportHeader(
        assignmentActivityId,
          schoolIds ? String(schoolIds) : "",
        classIds,
        schoolYearId
      )
    }

    if (response.data && response.data.pointsPossible) {
      setPointsPossible(response.data.pointsPossible)
    }

    getOverallResult(response)
    if (hasSubHeader) {
      getOverallSubHeaderResult(response)
    }
  }

  const getOverallResult = (response) => {
    setAverageScore(0)
    setResultHeader(null)

    if (response.data && response.data.subjectId) {
      setResultHeader(response.data)
      setSubjectName(response.data.subject)

      if (subjectId === 0) {
        subjectId = response.data.subjectId
        setSubject(subjectId)
      }
      var standardIds = response.data.subjects?.map((subject, subIndex) =>
        subject?.domains?.map((domain, index) =>
          domain.standards?.map((standard, index) => {
            return standard.id
          })
        )
      )
      setStandardIds(standardIds)
    }
    if (response.data.pointsPossible > 0) {
      setAverageScore(
        Number(
          getPercentage(
            response.data.pointsEarned,
            response.data.pointsPossible
          )
        )
      )
    } else {
      setAverageScore(0)
    }
  }

  const getOverallSubHeaderResult = (response) => {
    setSubHeaderAverageScore(0)
    setResultSubHeader(null)

    setResultSubHeader(response.data)
    if (response.data.pointsPossible > 0) {
      setSubHeaderAverageScore(
        Number(
          getPercentage(
            response.data.pointsEarned,
            response.data.pointsPossible
          )
        )
      )
    }
  }

  const getStudentResult = (schoolIds: Array<number> | null, classIds: Array<number> | null) => {
    setNoRecordFoundMsg('')
    setShowLoader(false)
    setResultReportByStudent(null)

    if (redirectedFrom === constant.RedirectedFrom.MYASSESSMENTS) {
      getAssessmentResultReportByStudent(
        assignmentActivityId,
          schoolIds,
        classIds,
        schoolYearId
      ).then((r) => {
        setShowLoader(false)
        if (r.data.data) {
          setResultReportByStudent(r.data.data)
        } else {
          setNoRecordFoundMsg('No record found')
        }
      })
    } else if (redirectedFrom === constant.RedirectedFrom.PREMIUMASSESSMENTS) {
      getPremiumAssessmentResultReportByStudent(
        assignmentActivityId,
        schoolIds,
        classIds,
        schoolYearId
      ).then((r) => {
        setShowLoader(false)
        if (r.data.data) {
          setResultReportByStudent(r.data.data)
        } else {
          setNoRecordFoundMsg('No record found')
        }
      })
    } else {
      getAssignmentResultReportByStudent(
        assignmentActivityId,
        schoolIds == null ? [] : schoolIds.filter(s => s !== 0),
        classIds,
        schoolYearId
      ).then((r) => {
        setShowLoader(false)
        if (r.data.data) {
          setResultReportByStudent(r.data.data)
        } else {
          setNoRecordFoundMsg('No record found')
        }
      })
    }
  }

  const getItemAnalysis = (schoolIds: Array<number> | null, classIds: string | null) => {
    setNoRecordFoundMsg('')
    setShowLoader(false)
    setResultsReportItemAnalysis(null)

    if (reportType === constant.AssessmentTypes.ASSIGNMENT) {
      getAssignmentResultReportItemAnalysis(
        assignmentActivityId,
        schoolIds ? String(schoolIds) : "",
        classIds,
        schoolYearId
      ).then((r) => {
        handleItemAnalysisResponse(r)
      })
    } else if (reportType === constant.AssessmentTypes.PREMIUMASSESSMENT) {
      getPremiumAssessmentResultReportItemAnalysis(
        assignmentActivityId,
        schoolIds,
        classIds == null ? null : String(classIds).split(',').map(Number),
        schoolYearId
      ).then((r) => {
        handleItemAnalysisResponse(r)
      })
    } else {
      getAssessmentResultReportItemAnalysis(
        assignmentActivityId,
          schoolIds ? String(schoolIds) : null,
        classIds,
        schoolYearId
      ).then((r) => {
        handleItemAnalysisResponse(r)
      })
    }
  }

  function handleItemAnalysisResponse(response) {
    setShowLoader(false)
    if (!response.data.data) {
      setNoRecordFoundMsg('No record found')
    } else {
      var itemAnalysisData = response.data.data
      // eslint-disable-next-line array-callback-return
      itemAnalysisData.questions?.map((question, qindex) => {
        question['total'] = 0
        question['correct'] = 0
        question['wrong'] = 0
        // eslint-disable-next-line array-callback-return
        itemAnalysisData.students?.map((student, _sindex) => {
          question['total'] +=
            student.isCorrect && student.isCorrect.length > 0 ? 1 : 0
          question['correct'] += student.isCorrect[qindex] ? 1 : 0
          question['wrong'] +=
            student.isCorrect[qindex] == null
              ? 0
              : student.isCorrect[qindex]
              ? 0
              : 1
        })
        question['score'] =
          question['total'] > 0
            ? (question['correct'] / question['total']) * 100
            : 0
      })

      setResultsReportItemAnalysis(itemAnalysisData)
    }
  }

  function GradeCR(
    sessionId: string,
    userId: string,
    studentActivityId: number,
    maximumPoint: number,
    gradableStudents: any[],
    studentStatus: string,
    attemptNumber: number,
    onlyView: boolean,
    studentAssessmentActivityId: number
  ) {
    if (hasCRActivity && isAssessmentActivity) {
      setGradeCRReport(
        <GradeAssesmentWithCR
          sessionId={sessionId}
          user_id={userId}
          studentAssignmentActivityId={studentActivityId}
          maximumPoint={maximumPoint}
          studentList={gradableStudents}
          setShowCRReport={setShowCRReport}
          refreshData={refreshData}
          studentStatus={studentStatus}
          attemptNumber={attemptNumber}
          onlyView={onlyView}
          studentAssessmentActivityId={studentAssessmentActivityId}
        />
      )
    } else {
      setGradeCRReport(
        <GradeCRQuestion
          sessionId={sessionId}
          user_id={userId}
          studentAssignmentActivityId={studentActivityId}
          maximumPoint={maximumPoint}
          studentList={gradableStudents}
          setShowCRReport={setShowCRReport}
          refreshData={refreshData}
          studentAssessmentActivityId={studentAssessmentActivityId}
        />
      )
    }
  }

  const getPercentage = (pointsEarned, pointsPossible) => {
    if (!pointsPossible) return 0
    return ((pointsEarned / pointsPossible) * 100).toFixed()
  }

  const getAssignment = async () => {
    const resp = await getAssignmentByAssignmentActivityId(
      assignmentId,
      assignmentActivityId,
      schoolYearId
    )
    if (resp?.data) setAssignment(resp?.data)
  }

  function loadSchoolMatrix() {
    let loadSchoolMatrix: boolean = true
    if (redirectedFrom === 'GRADEBOOK' && randomValue === 0) {
      loadSchoolMatrix = selectedClasses.some((r) => r.value !== '0')
    }
    return loadSchoolMatrix
  }

  function refreshData() {
    getResultReportHeaders(
        userSchoolIdAsArray(),
      classId === 0 ? null : [classId],
      Number(classId) !== 0 ? true : false
    )

    setResultReportByStudent(null);
    setResultsReportItemAnalysis(null);
  }

  const onPrint = () => {
    if (handleAssessmentMasteryPrint !== undefined && selectedTab === 9) {
      setTimeout(() => {
        handleAssessmentMasteryPrint()
        setDownloadCount((prevCount) => prevCount + 1)
      }, 550)
    }
  }

  const allClassesSelected = (): boolean => {
    return (selectedClasses[0].value === '0');
  }

  useEffect(() => {
    if (
        reportType !== constant.AssessmentTypes.ASSESSMENT &&
        reportType !== constant.AssessmentTypes.PREMIUMASSESSMENT
    )
      getAssignment()

    setSubHeaderAverageScore(0)

    //We don't need to pull classes for district users, we do however pull schools for them
    if (userContext.roleId !== constant.UserRoleId.District) {
      if (
          redirectedFrom === constant.RedirectedFrom.MYASSESSMENTS ||
          redirectedFrom === constant.RedirectedFrom.PREMIUMASSESSMENTS
      ) {
        getAllTeacherClasses()
        setOverallMessage('Score reflects overall average across all attempts')
      } else {
        getClassesByAssignment()
        setOverallMessage(
            "Score reflects overall average for students' best attempts"
        )
      }
    } else {
      getSchools()
    }

    setIsDomainActive(true)
    if (isDistrictUser && selectedSchool?.length === 0) {
      setHeader('District')
    } else {
      setHeader('Overall')
    }

    if (!isDistrictUser && Number(classId) !== 0) {
      setIsDomainActive(true)
    }

    getResultReportHeaders(
        userSchoolIdAsArray(),
        classId === 0 ? null : [classId],
        Number(classId) !== 0
    )

    setSelectedTab(
        hasCRActivity
            ? 8
            : userContext.roleId === constant.UserRoleId.District ||
            userContext.roleId === constant.UserRoleId.SchoolAdmin
                ? 2
                : 4
    )
  }, [])

  useEffect(() => {
    setCRReport(
        <ConstructedResponseReport
            userContext={userContext}
            assessmentId={assessmentId}
            assignmentId={assignmentId}
            assignmentActivityId={assignmentActivityId}
            classIds={selectedClassIds}
            gradeCRQuestion={GradeCR}
            setShowCRReport={setShowCRReport}
            setAverageScore={setAverageScore}
            schoolIds={getSelectedSchoolsFilterAsIntArray()}
            hasCRQuestions={hasCRActivity}
            schoolYearId={schoolYearId}
        />
    )
  }, [selectedClassIds, selectedSchool])

  useEffect(() => {
    localStorage.setItem('downloadCount', JSON.stringify(downloadCount))
  }, [downloadCount])

  useEffect(() => {
    const schoolsFilter = getSelectedSchoolsFilterAsIntArray()

    switch (selectedTab) {
      case 6: //Item Analysis
      case 7:
        if (resultsReportItemAnalysis == null) {
          getItemAnalysis(schoolsFilter, (selectedClassIds ?? []).join(','));
        }
        break
      case 4: //Dot Rank Matrix by Student
        if (resultReportByStudent == null) {
          getStudentResult(schoolsFilter, selectedClassIds)
        }
        break
    }
  }, [selectedTab])

  useEffect(() => {
    setRandomValue(Math.random())

    if (allClassesSelected()) {
      // all classes
      setIsDomainActive(true)
      setShowSubHeader(false)
      getResultReportHeaders(userSchoolIdAsArray(), null)
      getItemAnalysis(userSchoolIdAsArray(), null)
      getStudentResult(null, null)
    } else {
      setIsSubHeaderDomainActive(true)
      getResultReportHeaders(userSchoolIdAsArray(), selectedClassIds, true)
      getItemAnalysis(userSchoolIdAsArray(), String(selectedClassIds))
      getStudentResult(null, selectedClassIds)
    }
  }, [selectedClassIds]);

  useEffect(() => {
    let ids: Array<number> = [];
    if (!allClassesSelected()) {
      ids = selectedClasses.map((s) => parseInt(s.value))
    }
    if(JSON.stringify(ids) !== JSON.stringify(selectedClassIds))
      setSelectedClassIds(ids);
  }, [selectedClasses]);

  useEffect(() => {
    toggleLoader(showLoader);
  }, [showLoader])

  return (
    <>      
      <div className='px-2 sm:px-6  lg:px-8 py-1 relative my-4'>
        <div>
          <div className='w-full rounded-lg py-5 font-semibold text-2xl text-gray-700 xl:mb-8'>
            <div className='bg-gray-100 py-2 grid grid-cols-1 md:grid-cols-12 text-gray-700 md:space-x-4 px-4'>
              <div className='px-2 md:px-0 md:col-span-6 2xl:col-span-4 xxxl:col-span-3 space-y-3'>
                {header} Average{' '}
                <span className='text-primary-violet '>
                  {' '}
                  {pointsPossible > 0
                    ? averageScore.toFixed() + '%'
                    : 'Pending'}
                </span>
              </div>
              {showSubHeader && (
                <div className='px-2 md:px-0 md:col-span-6 2xl:col-span-4 xxxl:col-span-3 space-y-3'>
                  {subHeader} Average{' '}
                  <span className='text-primary-violet '>
                    {' '}
                    {subHeaderAverageScore.toFixed()}%
                  </span>
                </div>
              )}
            </div>
            {hasCRActivity && (
              <div className='text-primary-violet text-sm'>
                {overallMessage}
              </div>
            )}
          </div>

          <div className='grid grid-cols-1 md:grid-cols-12 text-gray-700 xl:space-x-4'>
            <div className='px-2 mr-4 xl:mr-0 md:px-0 col-span-12 md:col-span-6 xl:col-span-4 2xl:col-span-4 xxxl:col-span-3 space-y-3'>
              <div className='flex w-full'>
                <div className='w-20 lg:w-40 font-semibold flex-none'>Name</div>
                <div>{resultHeader?.name}</div>
              </div>
              <div className='flex w-full'>
                <div className='w-20 lg:w-40 font-semibold flex-none'>
                  Activity
                </div>
                <div>{resultHeader?.activity}</div>
              </div>
              <div className='flex w-full'>
                <div className='w-20 lg:w-40 font-semibold flex-none'>
                  Author
                </div>
                <div>{resultHeader?.author}</div>
              </div>
              {/* <div className="flex w-full">
                <div className="w-40 font-semibold flex-none">Length</div>
                <div>
                  {hasCRActivity
                    ? "1 Question"
                    : resultHeader?.questionsAttempted}
                </div>
              </div> */}
              <div className='flex w-full'>
                <div className='w-20 lg:w-40 font-semibold flex-none'>
                  Subject
                </div>
                <div>{resultHeader?.subject}</div>
              </div>
              <div className='flex w-full'>
                <div className='w-20 lg:w-40 font-semibold flex-none'>
                  Date Created
                </div>
                <div className='flex flex-wrap'>
                  <span className='bg-gray-100 rounded-lg inline-block px-2 mr-2'>
                    {resultHeader?.dateCreated
                      ? moment(resultHeader?.dateCreated).format('MM/DD/YYYY')
                      : 'N/A'}
                  </span>
                </div>
              </div>

              <div className='flex w-full'>
                <div className='w-20 lg:w-40 font-semibold flex-none'>
                  Due Date
                </div>
                <div>
                  {' '}
                  {resultHeader?.dueDate
                    ? moment(resultHeader?.dueDate).format('MM/DD/YYYY')
                    : 'N/A'}
                </div>
              </div>

              <div className={`${isDistrictUser ? '' : 'hidden'} flex w-full `}>
                <div className='w-20 lg:w-40 font-semibold flex-none'>
                  School
                </div>
                <div className='lg:w-52'>
                  <Select
                    menuPortalTarget={document.body}
                    value={selectedSchool}
                    options={schoolOptions}
                    onChange={(e) => {
                      onSchoolChange(e, redirectedFrom === constant.RedirectedFrom.MYASSESSMENTS)
                    }}
                    isMulti={
                      redirectedFrom === constant.RedirectedFrom.MYASSESSMENTS
                    }
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: '#B3B3B3',
                        primary: '#041E42'
                      }
                    })}
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      control: (base, state) => ({
                        ...base,
                        background:
                          document.getElementsByClassName('dark')?.length > 0
                            ? '#1A1C23'
                            : 'white',
                        borderColor:
                          document.getElementsByClassName('dark')?.length > 0
                            ? '#6B7280'
                            : '#D1D5DB',
                        borderWidth: '1px',
                        '&:hover': {
                          borderColor:
                            state.isFocused &&
                            document.getElementsByClassName('dark')?.length > 0
                              ? '#6b7280'
                              : state.isFocused
                              ? '#4c5563'
                              : ''
                        }
                      }),
                      singleValue: (base) => ({
                        ...base,
                        color:
                          document.getElementsByClassName('dark')?.length > 0
                            ? 'white'
                            : '#374051'
                      }),
                      input: (base) => ({
                        ...base,
                        color:
                          document.getElementsByClassName('dark')?.length > 0
                            ? 'white'
                            : ''
                      })
                    }}
                  ></Select>
                </div>
              </div>
              <div className={`${!isDistrictUser ? '' : 'hidden'} flex w-full`}>
                <div className='w-20 lg:w-40 font-semibold flex-none'>
                  Class
                </div>
                <div className='w-full lg:w-52'>
                  <ClassDropDown
                    schoolYearId={schoolYearId}
                    isMultiSelect={true}
                    onChange={debounce((e) => handleClassChange(e), 500)}
                    value={selectedClasses}
                    isDisabled={false}
                    data={classes}
                    defaultLabel={defaultAllLabel}
                    showArchived={false} //This makes a separate call to pull archived classes, we will include archived classes in "classes" data.
                    showHidden={true}
                  ></ClassDropDown>
                </div>
              </div>
            </div>

            {/* Header */}
            {!showSubHeader && (
              <div className='mt-4 md:mt-0 col-span-12 md:col-span-6 xl:col-span-4 2xl:col-span-5 xxxl:col-span-4 bg-gray-50 shadow-lg py-4 px-3 2xl:px-6 space-y-3'>
                <div className='inline-flex w-full md:w-auto inline-block border text-sm border-gray-300 rounded-md overflow-hidden'>
                  <span
                    onClick={() => setIsDomainActive(true)}
                    className={`${
                      isDomainActive
                        ? 'bg-secondary-teal text-white '
                        : 'bg-white text-gray-400'
                    } w-full md:w-auto inline-block py-1 px-2 text-center cursor-pointer hover:opacity-80`}
                  >
                    Scores by Domain
                  </span>
                  <span
                    onClick={() => setIsDomainActive(false)}
                    className={`${
                      !isDomainActive
                        ? 'bg-secondary-teal text-white '
                        : 'bg-white text-gray-400'
                    } w-full md:w-auto inline-block py-1 px-2 text-center cursor-pointer hover:opacity-80`}
                  >
                    View By Standard
                  </span>
                </div>
                <div>
                  {isDomainActive && (
                    <div className='domain'>
                      {resultHeader?.subjects?.map((subject, subIndex) =>
                        subject?.domains?.map((domain, index) => {
                          return (
                            <div className='mt-2'>
                              <div className='text-blue-400 mb-1'>
                                {domain.name}
                              </div>
                              <div className='flex items-center w-full'>
                                <div className='text-xl font-semibold text-gray-500'>
                                  {getPercentage(
                                    domain.pointsEarned,
                                    domain.pointsPossible
                                  )}
                                  %
                                </div>
                                <div className='bg-gray-200 w-full ml-2 h-5 flex rounded-lg overflow-hidden'>
                                  <div
                                    className='w-[50%] bg-primary-violet'
                                    style={{
                                      width:
                                        getPercentage(
                                          domain.pointsEarned,
                                          domain.pointsPossible
                                        ) + '%'
                                    }}
                                  ></div>
                                  <div className='bg-[#82c95e]'></div>
                                </div>
                              </div>
                            </div>
                          )
                        })
                      )}
                    </div>
                  )}
                  {!isDomainActive && (
                    <div className='standard'>
                      {resultHeader?.subjects?.map((subject, subIndex) =>
                        subject.domains?.map((domain, _index) => {
                          return (
                            <div className='mt-4 p-3 pb-4 rounded-md border border-blue-400'>
                              <div className='text-xl text-blue-400 mb-3 mt-1'>
                                {domain.name}
                              </div>
                              {domain.standards?.map((standard, index) => {
                                return (
                                  <div className='mt-2'>
                                    <div className='text-blue-400 mb-1'>
                                      {standard.name}
                                    </div>
                                    <div className='flex items-center w-full'>
                                      <div className='text-xl font-semibold text-gray-500'>
                                        {getPercentage(
                                          standard.pointsEarned,
                                          standard.pointsPossible
                                        )}
                                        %
                                      </div>
                                      <div className='bg-gray-200 w-full ml-2 h-5 flex rounded-lg overflow-hidden'>
                                        <div
                                          className='w-[70%] bg-primary-violet'
                                          style={{
                                            width:
                                              getPercentage(
                                                standard.pointsEarned,
                                                standard.pointsPossible
                                              ) + '%'
                                          }}
                                        ></div>
                                        <div className='bg-[#82c95e]'></div>
                                      </div>
                                    </div>
                                  </div>
                                )
                              })}
                            </div>
                          )
                        })
                      )}
                    </div>
                  )}
                </div>
              </div>
            )}
            {/* Sub Header */}
            {showSubHeader && (
              <div className='mt-4 md:mt-0 col-span-12 md:col-span-6 xl:col-span-4 2xl:col-span-5 xxxl:col-span-4 bg-gray-50 shadow-lg py-4 px-3 2xl:px-6 space-y-3'>
                <div className='inline-flex inline-block border text-sm border-gray-300 rounded-md overflow-hidden'>
                  <span
                    onClick={() => setIsSubHeaderDomainActive(true)}
                    className={`${
                      isSubHeaderDomainActive
                        ? 'bg-secondary-teal text-white '
                        : 'bg-white text-gray-400'
                    } w-36 inline-block py-0.5 text-center cursor-pointer hover:opacity-80`}
                  >
                    Scores by Domain
                  </span>
                  <span
                    onClick={() => setIsSubHeaderDomainActive(false)}
                    className={`${
                      !isSubHeaderDomainActive
                        ? 'bg-secondary-teal text-white '
                        : 'bg-white text-gray-400'
                    } w-36 inline-block py-0.5 text-center cursor-pointer hover:opacity-80`}
                  >
                    View By Standard
                  </span>
                </div>
                <div>
                  {isSubHeaderDomainActive && (
                    <div className='domain'>
                      {resultSubHeader?.subjects?.map((subject, subIndex) =>
                        subject?.domains?.map((domain, index) => {
                          return (
                            <div className='mt-2'>
                              <div className='text-blue-400 mb-1'>
                                {domain.name}
                              </div>
                              <div className='flex items-center w-full'>
                                <div className='text-xl font-semibold text-gray-500'>
                                  {getPercentage(
                                    domain.pointsEarned,
                                    domain.pointsPossible
                                  )}
                                  %
                                </div>
                                <div className='bg-gray-200 w-full ml-2 h-5 flex rounded-lg overflow-hidden'>
                                  <div
                                    className='w-[50%] bg-primary-violet'
                                    style={{
                                      width:
                                        getPercentage(
                                          domain.pointsEarned,
                                          domain.pointsPossible
                                        ) + '%'
                                    }}
                                  ></div>
                                  <div className='bg-[#82c95e]'></div>
                                </div>
                              </div>
                            </div>
                          )
                        })
                      )}
                    </div>
                  )}
                  {!isSubHeaderDomainActive && (
                    <div className='standard'>
                      {resultSubHeader?.subjects?.map((subject, subIndex) =>
                        subject.domains?.map((domain, index) => {
                          return (
                            <div className='mt-4 p-3 pb-4 rounded-md border border-blue-400'>
                              <div className='text-xl text-blue-400 mb-3 mt-1'>
                                {domain.name}
                              </div>
                              {domain.standards?.map((standard, index) => {
                                return (
                                  <div className='mt-2'>
                                    <div className='text-blue-400 mb-1'>
                                      {standard.name}
                                    </div>
                                    <div className='flex items-center w-full'>
                                      <div className='text-xl font-semibold text-gray-500'>
                                        {getPercentage(
                                          standard.pointsEarned,
                                          standard.pointsPossible
                                        )}
                                        %
                                      </div>
                                      <div className='bg-gray-200 w-full ml-2 h-5 flex rounded-lg overflow-hidden'>
                                        <div
                                          className='w-[70%] bg-primary-violet'
                                          style={{
                                            width:
                                              getPercentage(
                                                standard.pointsEarned,
                                                standard.pointsPossible
                                              ) + '%'
                                          }}
                                        ></div>
                                        <div className='bg-[#82c95e]'></div>
                                      </div>
                                    </div>
                                  </div>
                                )
                              })}
                            </div>
                          )
                        })
                      )}
                    </div>
                  )}
                </div>
              </div>
            )}
            <div className='col-span-12 order-first xl:order-none mb-4 xl:mb-0 xl:col-span-4 2xl:col-span-3 xxxl:col-span-5 relative'>
              <div className='flex gap-1 flex-col bg-gray-100 p-3 text-gray-500 2xl:ml-2 h-full rounded-md'>
                <p>
                  The Dot Rank contains cumulative data based on all questions
                  answered within the activity.
                </p>
                <div className='flex gap-1 flex-col bg-white p-2 mr-auto'>
                  <p className='flex items-center'>
                    <span className='w-3.5 h-3.5 rounded-full bg-[#9e9e9e] border border-black inline-block mr-1'></span>
                    Gray dot: No questions answered
                  </p>
                  <p className='flex items-center'>
                    {' '}
                    <span className='w-3.5 h-3.5 rounded-full bg-[#e53f71] border border-black inline-block mr-1'></span>
                    Pink dot: &lt; 65%
                  </p>
                  <p className='flex items-center'>
                    <span className='w-3.5 h-3.5 rounded-full bg-[#f4d221] border border-black inline-block mr-1'></span>
                    Yellow dot: 65-79%
                  </p>
                  <p className='flex items-center'>
                    <span className='w-3.5 h-3.5 rounded-full bg-[#a3b938] border border-black inline-block mr-1'></span>
                    Green dot: &gt;= 80%
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='bg-gray-100 w-full rounded-lg p-5 mt-8'>
          <div className='bg-white px-3 pt-2 pb-3'>
            <div className='flex items-center bg-gray-100 pt-2 py-1 px-3 flex-col lg:flex-row lg:justify-between overflow-auto'>
              <div className='flex lg:mr-2 xl:mr-0'>
                <nav className='flex space-x-2' aria-label='Tabs'>
                  {hasCRActivity && (
                    <button
                      key='Grade Constructed Response'
                      onClick={() => {
                        setSelectedTab(8)
                      }}
                      className={classNames(
                        8 === selectedTab
                          ? 'border-secondary-teal bg-white text-primary-violet hover:text-dark-violet py-3 tab-box-shadow tab-box-shadow'
                          : 'text-primary-violet text-opacity-60 bg-primary-violet/10 py-[0.6rem] mt-auto transition-all hover:py-3 hover:text-opacity-100 hover:text-primary-violet border-[#dee9f1]',
                        'px-2 sm:px-4 text-center border-t-2 text-sm cursor-pointer font-medium'
                      )}
                    >
                      Grade Constructed Response
                    </button>
                  )}
                  {tabs.map(
                    (tab) =>
                      tab.roles.indexOf(userContext.roleId) !== -1 && (
                        <>
                          {(tab.permission_required ?? false) === true && (
                            <>
                              <AllowedTo perform={tab.permission_name}>
                                {((!(tab.hideForCR ?? false) &&
                                  hasCRActivity) ||
                                  !hasCRActivity) && (
                                  <button
                                    key={tab.name}
                                    onClick={() => {
                                      setSelectedTab(tab.id)
                                    }}
                                    className={classNames(
                                      tab.id === selectedTab
                                        ? tab.activeClass
                                        : 'text-primary-violet text-opacity-60 bg-primary-violet/10 py-[0.6rem] mt-auto transition-all hover:py-3 hover:text-opacity-100 hover:text-primary-violet border-[#dee9f1]',
                                      'px-2 sm:px-4 text-center border-t-2 text-sm cursor-pointer font-medium'
                                    )}
                                  >
                                    {tab.name}
                                  </button>
                                )}
                              </AllowedTo>
                            </>
                          )}

                          {(tab.permission_required ?? false) === false && (
                            <>
                              {((!(tab.hideForCR ?? false) && hasCRActivity) ||
                                !hasCRActivity) && (
                                <button
                                  key={tab.name}
                                  onClick={() => {
                                    setSelectedTab(tab.id)
                                  }}
                                  className={classNames(
                                    tab.id === selectedTab
                                      ? tab.activeClass
                                      : 'text-primary-violet text-opacity-60 bg-primary-violet/10 py-[0.6rem] mt-auto transition-all hover:py-3 hover:text-opacity-100 hover:text-primary-violet border-[#dee9f1]',
                                    'px-2 sm:px-4 text-center border-t-2 text-sm cursor-pointer font-medium'
                                  )}
                                >
                                  {tab.name}
                                </button>
                              )}
                            </>
                          )}
                        </>
                      )
                  )}
                </nav>
              </div>
            </div>
            {selectedTab === 8 && (showCRReport ? crReport : gradeCRReport)}
            {selectedTab === 2 && loadSchoolMatrix() && (
              <div className='flex flex-col'>
                <ResultReportBySchool
                  schoolYearId={schoolYearId}
                  key={randomValue}
                  activityId={assignmentActivityId.toString()}
                  activityType={reportType}
                  schoolIds={String(selectedSchool.map((s) => s.value))
                    .split(',')
                    .map(Number)}
                  classIds={String(selectedClasses.map((s) => s.value))
                    .split(',')
                    .map(Number)}
                  redirectedFrom={redirectedFrom}
                  overallLabel={header}
                  overallScore={averageScore}
                  isDistrictUser={isDistrictUser}
                  header={{
                    name: resultHeader?.name,
                    activity: resultHeader?.activity,
                    author: resultHeader?.author,
                    subject: resultHeader?.subject,
                    dateCreated: resultHeader?.dateCreated,
                    dueDate: resultHeader?.dueDate,
                    schoolName: '',
                    className: ''
                  }}
                  resultHeader={resultHeader}
                  resultSubHeader={resultSubHeader}
                  isDomainActive={isDomainActive}
                  showSubHeader={showSubHeader}
                  isSubHeaderDomainActive={isSubHeaderDomainActive}
                  roleId={userContext.roleId}
                  isAssessmentWithCr={hasCRActivity}
                ></ResultReportBySchool>
              </div>
            )}
            {selectedTab === 5 && isDistrictUser && (
              <ResultReportAssessmentStudentDetailsBySchool
                schoolYearId={schoolYearId}
                assessmentId={assignmentActivityId}
                schoolIds={String(selectedSchool.map((s) => s.value))
                  .split(',')
                  .map(Number)}
                type={reportType}
              ></ResultReportAssessmentStudentDetailsBySchool>
            )}
            {selectedTab === 5 && !isDistrictUser && (
              <ResultReportAssignmentStudentDetailsByClass
                schoolYearId={schoolYearId}
                assignmentActivityId={assignmentActivityId}
                classIds={selectedClassIds}
                type={reportType}
                assignment={
                  reportType !== constant.AssessmentTypes.ASSESSMENT
                    ? assignment
                    : undefined
                }
                redirectedFrom={redirectedFrom}
                userContext={userContext}
              ></ResultReportAssignmentStudentDetailsByClass>
            )}
            {selectedTab === 6 && (
              <div className='flex flex-col'>
                {resultsReportItemAnalysis && (
                  <ResultsReportItemAnalysis
                    roleId={userContext?.roleId}                    
                    data={resultsReportItemAnalysis}
                    assignmentId={assignmentId}
                    assignmentName={assignmentName}
                    activityType={activityType}
                    subjectId={subject}
                    subjectName={subjectName}
                    teacherId={userContext.userId}
                    activityId={assignmentActivityId}
                    assessmentId={assessmentId}
                    selectedClasses={selectedClasses}
                    assignmentClasses={classes}
                    userId={userContext.userId}
                    redirectedFrom={redirectedFrom}
                    standardIds={standardIds}
                  ></ResultsReportItemAnalysis>
                )}
                {!resultsReportItemAnalysis && (
                  <div className='p-3'>{noRecordFoundMsg}</div>
                )}
              </div>
            )}
            {selectedTab === 7 && (
              <div className='flex flex-col'>
                {resultsReportItemAnalysis && (
                  <ResultsReportItemAnalysisBeta
                    data={resultsReportItemAnalysis}
                    standardIds={standardIds}
                  ></ResultsReportItemAnalysisBeta>
                )}
                {!resultsReportItemAnalysis && (
                  <div className='p-3'>{noRecordFoundMsg}</div>
                )}
              </div>
            )}
            {selectedTab === 4 && (
              <div className='flex flex-col'>
                {subject > 0 && resultReportByStudent && (
                  <ResultsReportByStudentByStandard
                    schoolYearId={schoolYearId}
                    data={resultReportByStudent}
                    assignmentId={assignmentId}
                    assignmentName={assignmentName}
                    activityType={activityType}
                    subjectId={subject}
                    schoolIds={String(selectedSchool.map((s) => s.value))
                      .split(',')
                      .map(Number)}
                    classIds={String(selectedClasses.map((s) => s.value))
                      .split(',')
                      .map(Number)}
                    schoolId={userContext.schoolId}
                    teacherId={userContext.userId}
                    activityId={assignmentActivityId}
                    assessmentId={assessmentId}
                    selectedClasses={selectedClasses}
                    assignmentClasses={classes}
                    redirectedFrom={redirectedFrom}
                    userContext={userContext}
                    hasCRActivity={hasCRActivity}
                    roleId={userContext.roleId}
                  ></ResultsReportByStudentByStandard>
                )}
                {!resultReportByStudent && (
                  <div className='p-3'>{noRecordFoundMsg}</div>
                )}
              </div>
            )}
            {selectedTab === 9 && !isDistrictUser && (
              <ResultReportAssessmentMasteryByClass
                schoolYearId={schoolYearId}
                assignmentActivityId={
                  reportType === constant.AssessmentTypes.ASSESSMENT ||
                  reportType === constant.AssessmentTypes.PREMIUMASSESSMENT
                    ? undefined
                    : assignmentActivityId
                }
                assessmentId={
                  reportType === constant.AssessmentTypes.ASSESSMENT
                    ? assignmentActivityId
                    : undefined
                }
                premiumAssessmentId={
                  reportType === constant.AssessmentTypes.PREMIUMASSESSMENT
                    ? assignmentActivityId
                    : undefined
                }
                classIds={selectedClassIds}
                type={reportType}
                assignment={
                  reportType !== constant.AssessmentTypes.ASSESSMENT
                    ? assignment
                    : undefined
                }
                print={onPrint}
                setResultsReportMasteryData={setResultsReportMasteryData}
              ></ResultReportAssessmentMasteryByClass>
            )}
            {selectedTab === 9 && isDistrictUser && (
              <ResultReportAssessmentMasteryBySchool
                schoolYearId={schoolYearId}
                assessmentId={
                  reportType === constant.AssessmentTypes.ASSESSMENT
                    ? assignmentActivityId
                    : undefined
                }
                premiumAssessmentId={
                  reportType === constant.AssessmentTypes.PREMIUMASSESSMENT
                    ? assignmentActivityId
                    : undefined
                }
                schoolIds={String(selectedSchool.map((s) => s.value))
                  .split(',')
                  .map(Number)}
                print={onPrint}
                type={reportType}
                setResultsReportMasteryData={setResultsReportMasteryData}
              ></ResultReportAssessmentMasteryBySchool>
            )}
            {selectedTab === attemptTabNumber &&
              (reportType === constant.AssessmentTypes.ASSESSMENT || reportType === constant.AssessmentTypes.PREMIUMASSESSMENT) && (
                <ResultReportAssessmentStudentAttempts
                  assessmentId={reportType === constant.AssessmentTypes.PREMIUMASSESSMENT ? undefined: assignmentActivityId}
                  premiumAssessmentId={reportType === constant.AssessmentTypes.PREMIUMASSESSMENT ? assignmentActivityId: undefined}
                  classIds={selectedClassIds}
                  schoolIds={getSelectedSchoolsFilterAsIntArray()}
                  schoolYearId={schoolYearId}
                  isDistrictUser={isDistrictUser}
                  userContext={userContext}
                ></ResultReportAssessmentStudentAttempts>
              )}
            {selectedTab === attemptTabNumber &&
              reportType === constant.AssessmentTypes.ASSIGNMENT && (
                <ResultReportAssignmentStudentAttempts
                  assignmentId={assignmentId}
                  classIds={selectedClassIds}
                  schoolId={userContext.schoolId}
                  schoolYearId={schoolYearId}
                ></ResultReportAssignmentStudentAttempts>
              )}
          </div>
        </div>
      </div>

      {/********************  print report ***************************/}
      {resultsReportMasteryData && resultsReportMasteryData.length > 0 && (
        <div className='hidden'>
          <div
            id='mastery-print'
            ref={componentRefAssessmentMasteryPrint}
            className='mx-6'
          >
            {resultHeader !== undefined && (
              <div>
                <MasteryReportFilterPrint
                  filter={{
                    ...resultHeader,
                    header,
                    averageScore,
                    isDistrictUser,
                    selectedClasses: selectedClasses.map((cls) => cls.label),
                    selectedSchool: selectedSchool.map(
                      (school) => school.label
                    ),
                    reportName: 'Results Report Mastery'
                  }}
                ></MasteryReportFilterPrint>
                <div className='bg-white px-3 pt-2 pb-3'>
                  {selectedTab === 9 && !isDistrictUser && (
                    <ResultReportAssessmentMasteryByClass
                      schoolYearId={schoolYearId}
                      assignmentActivityId={
                        reportType === constant.AssessmentTypes.ASSIGNMENT
                          ? assignmentActivityId
                          : undefined
                      }
                      assessmentId={
                        reportType === constant.AssessmentTypes.ASSESSMENT
                          ? assignmentActivityId
                          : undefined
                      }
                      classIds={String(selectedClasses.map((s) => s.value))
                        .split(',')
                        .map(Number)}
                      type={reportType}
                      assignment={
                        reportType !== constant.AssessmentTypes.ASSESSMENT
                          ? assignment
                          : undefined
                      }
                      resultsReportMasteryData={resultsReportMasteryData}
                    ></ResultReportAssessmentMasteryByClass>
                  )}
                  {selectedTab === 9 && isDistrictUser && (
                    <ResultReportAssessmentMasteryBySchool
                      schoolYearId={schoolYearId}
                      assessmentId={
                        reportType === constant.AssessmentTypes.ASSESSMENT
                          ? assignmentActivityId
                          : undefined
                      }
                      premiumAssessmentId={
                        reportType ===
                        constant.AssessmentTypes.PREMIUMASSESSMENT
                          ? assignmentActivityId
                          : undefined
                      }
                      type={reportType}
                      schoolIds={String(selectedSchool.map((s) => s.value))
                        .split(',')
                        .map(Number)}
                      resultsReportMasteryData={resultsReportMasteryData}
                    ></ResultReportAssessmentMasteryBySchool>
                  )}
                </div>
                <div className='w-full rounded-lg py-5 font-semibold text-2xl text-gray-700 xl:mb-8'>
                  <div className='flex items-center justify-between py-2 text-gray-700 md:space-x-4 px-4'>
                    <div className='px-2 md:px-0 md:col-span-6 2xl:col-span-4 xxxl:col-span-3 space-y-3'>
                      <span className='text-gray-500 text-xs'>
                        Created by Progress Learning, LLC{' '}
                        {new Date().getFullYear()}
                      </span>
                    </div>
                    <div className='px-2 md:px-0 md:col-span-6 2xl:col-span-4 xxxl:col-span-3 space-y-3 text-align-right'>
                      <span className='text-gray-500 text-xs'>
                        Printed by {`${profile.firstName} ${profile.lastName}`}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  )
}

export default ResultsData

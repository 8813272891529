import AnglerfishButton from "./anglerfishButton";
import { GetTeamNames } from "../../../../../api/classroomGame/thinkOrSwim";
import { Fragment, useState } from "react";
import Loader from "../../../../shared/loader";
import { toast } from "react-toastify";

interface ITOSGameSetupCreateTeamsProps {
  sessionName: string,
  setSessionName: (sessionName: string) => void,
  teamNames: string[],
  setTeamNames: (teamNames: string[]) => void,
  onContinue: () => void
}

const ThinkOrSwimGameId = 1;

function TOSGameSetupCreateTeams(props: ITOSGameSetupCreateTeamsProps) {

  const {
    sessionName,
    setSessionName,
    teamNames,
    setTeamNames,
    onContinue,
  } = props;

  const [showLoading, setShowLoading] = useState<boolean>(false);

  const handleAddTeam = () => {
    setShowLoading(true);
    GetTeamNames(ThinkOrSwimGameId, 1, teamNames).then(response => {
      setShowLoading(false);
      const newTeamNames = response.data.teamNames;
      setTeamNames([...teamNames, ...newTeamNames])
    }).catch(error => {
      setShowLoading(false);
      toast.error(`${error.message}: Cannot retrieve team names`)
      console.error({error});
    })
  }

  const handleRemoveTeam = (index: number) => {
    const newTeamNames = [...teamNames];
    newTeamNames.splice(index, 1);
    setTeamNames(newTeamNames);
  }

  const handleSetTeamName = (index: number, name: string) => {
    const newTeamNames = [...teamNames];
    newTeamNames[index] = name;
    setTeamNames(newTeamNames);
  }

  const hasDuplicatedTeamNames = (teams: Array<string>): boolean => {
    const trimmedTeams = teams.map(team => team.trim());
    return new Set(trimmedTeams).size !== trimmedTeams.length;
  }

  const hasEmptyTeamNames = (teams: Array<string>): boolean => {
    const hasEmptyTeamNames = teams.reduce((accumulator: boolean, current: string) => !current?.trim() || accumulator, false);
    return hasEmptyTeamNames;
  }

  const handleContinue = () => {
    if(!sessionName?.trim()) {
      toast.warn("Please type a session name");
      return;
    }

    if(!teamNames?.length) {
      toast.warn("Please choose at least 1 team name.");
      return;
    }

    if(hasEmptyTeamNames(teamNames)) {
      toast.warn("Please type all team names");
      return;
    }

    if(hasDuplicatedTeamNames(teamNames)) {
      toast.warn("You have duplicated team names");
      return;
    }

    onContinue();
  }


  return (
    <Fragment>
      {showLoading && <Loader />}
      <div className="flex flex-row justify-between">
        <div className="w-full">
          <p className="text-gray-500 text-left my-4">Choose your team name for each group of students. Ideal team size is 3-5 students.</p>
          <div className="flex flex-col lg:flex-none lg:grid lg:grid-cols-12 gap-4 mb-5">
            <div className="lg:col-span-6 space-y-1">
              <div className="sm:grid sm:grid-cols-12 items-center">
                <label className="block text-sm font-normal text-gray-500 col-span-4">Session Name</label>
                <input 
                  placeholder="Type a Session Name"
                  type="text" 
                  className="col-span-8 max-w-sm w-full block shadow-sm focus:ring-secondary-teal focus:border-secondary-teal sm:text-sm border-gray-300 rounded-md" 
                  maxLength={60}
                  value={sessionName}
                  onChange={e => { setSessionName(e.target.value)}}
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col lg:flex-none lg:grid lg:grid-cols-12 gap-4">
            <div className="lg:col-span-7 space-y-1">
              {teamNames?.map((teamName: string, index: number) => (
                <div key={index} className="sm:grid sm:grid-cols-12 items-center">
                  <label className="block text-sm font-normal text-gray-500 col-span-3">Team {index+1}</label>
                  <input 
                    placeholder="Type a Team Name"
                    type="text" 
                    className="col-span-5 max-w-sm w-full block shadow-sm focus:ring-secondary-teal focus:border-secondary-teal sm:text-sm border-gray-300 rounded-md" 
                    value={teamName}
                    maxLength={50}
                    onChange={e => {handleSetTeamName(index, e.target.value)}}
                  />
                  <span
                    className="ml-5 col-span-4 cursor-pointer text-blue-500 underline hover:no-underline"
                    onClick={() => handleRemoveTeam(index)}
                  >
                    Remove Team
                  </span>
                </div>
              ))}
            </div>
          </div>
          <span 
            className="cursor-pointer text-blue-500 underline hover:no-underline"
            onClick={handleAddTeam}
          >+ Add Team</span>
        </div>
        <div className="w-[21rem]">
          <AnglerfishButton
            text="Continue"
            textSize={1.1}
            canTextShink
            onClick={handleContinue}
          />
        </div>
      </div>
    </Fragment>
  )
}

export default TOSGameSetupCreateTeams

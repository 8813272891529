import { useState } from "react";
import { Configuration } from "../../../environment/setup";
import { toast } from "react-toastify";
import * as userContextAction from "../../../redux/actions/userContextAction";
import Constant from "../../../utils/constant/constant";
import { login } from "../../../api/login.service";
import { useHistory } from "react-router-dom";
import { ExclamationIcon } from "../../../assets/icons";
import Loader from "../../shared/loader";
import { fetchProfile } from "../../../redux/actions/userActions";
import { connect } from "react-redux";
import { CanvasApi } from "../../../api/canvas/canvasApi";
import { handleRedirection } from "../../../utils/helper";
import { jwtToken } from "../../shared/tokenHelper";

const CanvasUserMapping = (props: any) => {
  const canvasLoginId = atob(props.match.params.canvasLoginId);
  const canvasId = atob(props.match.params.canvasId);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [isValidFlag, setIsValidFlag] = useState(true);
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [accountLoginName, setAccountLoginName] = useState("");

  const validate = () => {
    let isValid = true;
    setIsValidFlag(true);
    if (userName === "") {
      isValid = false;
      toast.error("Please enter userName");
    } else if (password === "") {
      isValid = false;
      toast.error("Please enter password");
    } else if (accountLoginName === "") {
      isValid = false;
      toast.error("Please enter account login name");
    }
    if (!isValid) {
      setIsValidFlag(false);
    }
    return isValid;
  };

  const handleGoogleMapping = () => {
    if (validate()) {
      setLoading(true);
      CanvasApi.addCanvasUser(
        accountLoginName,
        userName,
        password,
        canvasId,
        canvasLoginId
      )
        .then((r) => {
          setLoading(false);
          if (r.data > 0) {
            handleLogin(canvasLoginId);
          } else if (r.data === 0) {
            toast.error("Username or password is incorrect");
          } else if (r.data === -1) {
            toast.error("Canvas Login Id is mapped with other user");
          } else if (r.data === -2) {
            toast.error("User is already mapped");
          }
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  const handleLogin = (canvasLoginId: string) => {
    setLoading(true);
    login(
      "none",
      "none",
      accountLoginName,
      0,
      "",
      canvasLoginId,
      Constant.LoginType.CANVAS,
      "",
      false
    )
      .then((response) => {
        setLoading(false);
        if (response) {
          const authToken = response.data["access_token"];
          localStorage.setItem("AuthToken", authToken);
          const user: any = jwtToken(authToken);
          const userId = isNaN(user.userId) ? 0 : parseInt(user.userId);
          const roleId = isNaN(user.roleId) ? 0 : parseInt(user.roleId);
          const gradeId = isNaN(user.gradeId) ? 0 : parseInt(user.gradeId);
          const accountId = isNaN(user.accountId)
            ? 0
            : parseInt(user.accountId);
          const districtId = isNaN(user.districtId)
            ? 0
            : parseInt(user.districtId);
          const schoolId =
            isNaN(user.schoolId) || user.schoolId === ""
              ? 0
              : parseInt(user.schoolId);
          const schoolAccountId =
            isNaN(user.schoolAccountId) || user.schoolAccountId === ""
              ? 0
              : parseInt(user.schoolAccountId);
          const stateId = isNaN(user.stateId) ? 0 : parseInt(user.stateId);
          var context: userContextAction.UserContextState = {
            userId: userId,
            roleId: roleId,
            gradeId: gradeId,
            schoolId: schoolId,
            districtId: districtId,
            accountId: accountId,
            stateId: stateId,
            googleId: user.googleId,
            activeExternalRoster: Constant.ExterRosterType.CANVAS,
            schoolAccountId: schoolAccountId,
            impersonatedUser: null,
            linkedUserId: user.linkedUserId,
          };

          props.setUserContext(context);
          props.setProfile(userId, roleId, schoolId, 0);
          handleRedirection(
            roleId,
            gradeId,
            user.linkedUserId,
            history,
            Constant.ExterRosterType.CANVAS
          );
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <div className="bg-splitColor w-full h-screen flex items-center relative">
        {loading && <Loader />}
        <div className="w-full">
          <h1 className="text-center text-2xl text-white font-semibold pb-6">
            Canvas Account
          </h1>
          <div className="bg-white w-1/3 min-h-[24rem] py-6 flex flex-col justify-center mx-auto rounded-lg shadow-xl">
            <img
              className=" w-24 mx-auto mb-8"
              src={`${Configuration.S3bucketImagePath}/images/pl-logo.png`}
              alt="education galaxy"
            />

            <div className=" w-full flex flex-col items-center gap-y-4 mb-3">
              <p className="w-3/4 text-gray-500">
                You are not mapped with Progress Learning account. Please enter
                Progress Learning credentials for mapping.
              </p>
              <div className="w-3/4 relative group">
                <label className="mb-1">Account Id</label>
                <input
                  type="text"
                  className="py-3 px-4 shadow-sm focus:ring-secondary-teal focus:border-secondary-teal block w-full sm:text-sm border-gray-300 rounded-md"
                  onChange={(event) => setAccountLoginName(event.target.value)}
                />
                {!isValidFlag && accountLoginName === "" && (
                  <span>
                    <span className="opacity-0 group-hover:opacity-100 bg-white text-red-500 border-red-400 border transition duration-500 rounded-sm max-w-70% p-0.5 pl-2 pr-3 text-xs inline-block absolute top-1/2 transform -translate-y-1/2 right-2 ">
                      Account Id is required
                    </span>
                    <ExclamationIcon className="w-1 absolute opacity-100 top-1/2 transform -translate-y-1/2 right-3 cursor-pointer" />
                  </span>
                )}
              </div>
              <div className="w-3/4 relative group">
                <label className="mb-1">Username</label>
                <input
                  type="text"
                  className="py-3 px-4 shadow-sm focus:ring-secondary-teal focus:border-secondary-teal block w-full sm:text-sm border-gray-300 rounded-md"
                  onChange={(event) => setUserName(event.target.value)}
                />
                {!isValidFlag && userName === "" && (
                  <span>
                    <span className="opacity-0 group-hover:opacity-100 bg-white text-red-500 border-red-400 border transition duration-500 rounded-sm max-w-70% p-0.5 pl-2 pr-3 text-xs inline-block absolute top-1/2 transform -translate-y-1/2 right-2 ">
                      Username is required
                    </span>
                    <ExclamationIcon className="w-1 absolute opacity-100 top-1/2 transform -translate-y-1/2 right-3 cursor-pointer"></ExclamationIcon>
                  </span>
                )}
              </div>
              <div className="w-3/4 relative group">
                <label className="mb-1">Password</label>
                <input
                  type="password"
                  className="py-3 px-4 shadow-sm focus:ring-secondary-teal focus:border-secondary-teal block w-full sm:text-sm border-gray-300 rounded-md"
                  onChange={(event) => setPassword(event.target.value)}
                />
                {!isValidFlag && password === "" && (
                  <span>
                    <span className="opacity-0 group-hover:opacity-100 bg-white text-red-500 border-red-400 border transition duration-500 rounded-sm max-w-70% p-0.5 pl-2 pr-3 text-xs inline-block absolute top-1/2 transform -translate-y-1/2 right-2 ">
                      password is required
                    </span>
                    <ExclamationIcon className="w-1 absolute opacity-100 top-1/2 transform -translate-y-1/2 right-3 cursor-pointer"></ExclamationIcon>
                  </span>
                )}
              </div>
              <div className="w-3/4 flex justify-end mt-2">
                <button
                  className="bg-secondary-teal border border-transparent shadow-sm py-2 px-4 justify-center text-sm font-medium text-white hover:bg-dark-teal hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3 "
                  onClick={() => {
                    handleGoogleMapping();
                  }}
                >
                  Login
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    profile: state.profile,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setUserContext: (payload: userContextAction.UserContextState) => {
      dispatch(userContextAction.setUserContext(payload));
    },
    setProfile: (
      userId: number,
      roleId: number,
      schoolId: number,
      districtId: number
    ) => {
      dispatch(fetchProfile(userId, roleId, schoolId, districtId));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CanvasUserMapping);

import {Fragment, useEffect, useState} from "react";
import {connect} from "react-redux";
import {useHistory} from "react-router-dom";
import Constant from "../../../../utils/constant/constant";
import constant from "../../../../utils/constant/constant";
import RouteConstant from "../../../../utils/constant/routeConstant";
import MyAssessment from "./myAssessment";
import Permissions from "../../../../utils/permissions";
import permissions from "../../../../utils/permissions";
import AllowedTo from "../../../shared/rbac";
import AccessDenied from "../../../shared/accessDenied";
import ConvertSchoolAssessment from "./convertSchoolAssessment";
import Breadcrumb from "../../teacher/shared/breadcrumb";
import {IBreadcrumb} from "../../../../model/common/breadcrumb";
import {useTranslation} from "react-i18next";
import UpGradeMessagePopup from "../../../shared/upGradeMessagePopup";
import {
  getAsessementBuilderUpgradePermission,
  getPremiumAssessmentUpgradePermission,
  getRequestedPermission,
  validateAssessmentCount,
} from "../../../shared/permissionHelper";
import Loader from "../../../shared/loader";
import InfoMessage from "../../../shared/infoMessage";
import {getMyAssessmentCount} from "../../../../api/teacher/assessment";
import classNames from "classnames";
import * as uuid from "uuid";
import PremiumAssessment from "./premiumAssessment";
import {GetSchoolYears} from "../../../../api/teacher/school";

function AssessmentBank(props: any) {
  const history = useHistory();
  const { t } = useTranslation();
  const [showConvertToSchoolPopup, setShowConvertToSchoolPopup] =
    useState(false);
  const [refreshKey, setRefreshKey] = useState<number>(0);
  const [upgrade, setUpgrade] = useState(false);
  const [upgradeWithLimit, setUpgradeWithLimit] = useState(false);
  const [showUpgradePopup, setShowUpgradePopup] = useState<boolean>(false);
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [assessmentCount, setAssessmentCount] = useState<number>(0);
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [defaultSchoolYearId, setDefaultSchoolYearId] = useState<number>(2);
  const [upgradeMessage, setUpgradeMessage] = useState<string>("");

  const tabInfo = {
    MY_ASSESSMENTS: {
      id: 0,
      name: "MY ASSESSMENTS",
      isActive: true,
    },
    FULL_ASSESSMENT_BANK: {
      id: 1,
      name: "FULL ASSESSMENT BANK",
      isActive: props.userContext.roleId !== Constant.UserRoleId.FreeTeacher,
    },
    PREMIUM_ASSESSMENTS: {
      id: 2,
      name: "PREMIUM ASSESSMENTS",
      isActive: getRequestedPermission(permissions.premium_assessments_view),
    },
  };
  let [tabs] = useState<any[]>([
    tabInfo.MY_ASSESSMENTS,
    tabInfo.FULL_ASSESSMENT_BANK,
    tabInfo.PREMIUM_ASSESSMENTS,
  ]);

  function toggleSchoolAssessmentPopup(
    popupAction: boolean,
    refreshData?: boolean
  ) {
    setShowConvertToSchoolPopup(popupAction);
    if (refreshData) {
      setRefreshKey(Math.random());
    }
  }

  useEffect(() => {
    checkUpgradeRequired();
  }, []);

  const checkUpgradeRequired = async () => {
    setShowLoading(true);
    if (getAsessementBuilderUpgradePermission(props.userContext.roleId)) {
      setUpgrade(true);
      const assessmentCount: number = await getAssessmentCount(

      );
      setAssessmentCount(assessmentCount);
      const isRestricted: boolean = validateAssessmentCount(
        assessmentCount,
        props.userContext.roleId
      );
      setUpgradeWithLimit(isRestricted);
    } else {
      setUpgradeWithLimit(false);
    }
    setShowLoading(false);
  };

  const getAssessmentCount = async () => {
    return (await getMyAssessmentCount()).data;
  };

  const handleRedirect = async (isSchoolAssessment: boolean) => {
    if (upgradeWithLimit) {
      setShowUpgradePopup(true);
      setUpgradeMessage(constant.UPGRADE_MESSAGE_ASSESSMENT_LIMIT_NEW);
      return;
    }
    await checkUpgradeRequired();
    if (upgradeWithLimit) {
      setUpgradeMessage(constant.UPGRADE_MESSAGE_ASSESSMENT_LIMIT_NEW);
      setShowUpgradePopup(true);
      return;
    }

    history.push({
      pathname: RouteConstant.NEWASSESSMENT,
      state: {
        isSchoolAssessment: isSchoolAssessment,
      },
    });
  };
  const breadcrumbItems = () => {
    const items: Array<IBreadcrumb> = [
      { name: t("Breadcrumb.Home"), url: RouteConstant.ROUTE_DASHBOARD },
      {
        name:
          props.userContext.roleId === Constant.UserRoleId.District
            ? "Assessment Center"
            : "Assessment/Assignment Center",
        url: RouteConstant.TeacherRoutes.assignmentCenter,
      },
      { name: t("Breadcrumb.AssessmentBank"), url: "" },
    ];

    return items;
  };

  const handleSetTab = async (id: number) => {
    if (
      getPremiumAssessmentUpgradePermission(props.userContext.roleId) &&
      id === tabInfo.PREMIUM_ASSESSMENTS.id
    ) {
      setShowUpgradePopup(true);
      setUpgradeMessage(constant.UPGRADE_MESSAGE_SCHOOL_ACCOUNT);
      return;
    }
    setSelectedTab(id);
  };
  useEffect(() => {
    GetSchoolYears(props.userContext?.schoolId!).then((response) => {
      if (response.data) {
        var currentfutureschoolyears = response.data.filter(
          (y) => y.currentYearOffset == 0
        );
        const schoolYearId = currentfutureschoolyears[0].schoolYearId;
        setDefaultSchoolYearId(schoolYearId);
      }
    });
  }, []);
  return (
    <>
      {showLoading && <Loader></Loader>}
      <AllowedTo
        perform={Permissions.assessments_view}
        no={() => <AccessDenied />}
      >
        {showUpgradePopup && (
          <UpGradeMessagePopup
            togglePopup={setShowUpgradePopup}
            roleId={props.userContext?.roleId ?? 0}
            message={upgradeMessage}
          />
        )}
        {showConvertToSchoolPopup && (
          <ConvertSchoolAssessment
            userId={props.userContext.userId}
            togglePopup={toggleSchoolAssessmentPopup}
          />
        )}
        <div className="min-w-0 w-full">
          <div className="lg:flex lg:items-center px-6 py-3 lg:px-8 border-b border-gray-200">
            <Breadcrumb items={breadcrumbItems()} />
            <div className="lg:w-[55%] text-3xl flex justify-between gap-2 h-auto sm:h-10 items-center">
              <h1 className="text-secondary-teal font-medium flex justify-start">
                Assessment Bank
              </h1>
            </div>
          </div>

          <div className="flex bg-gray-100 px-2 sm:px-6 lg:px-8 pt-2 flex-col lg:flex-row lg:justify-between">
            <div className="flex lg:mr-2 xl:mr-0">
              <nav className="flex space-x-2" aria-label="Tabs">
                {tabs
                  .filter((tab) => tab.isActive)
                  .map((tab) => (
                    <button
                      key={uuid.v4()}
                      onClick={() => {
                        handleSetTab(tab.id);
                      }}
                      className={classNames(
                        tab.id === selectedTab
                          ? "border-secondary-teal bg-white text-primary-violet hover:text-dark-violet py-3 tab-box-shadow tab-box-shadow"
                          : "text-primary-violet text-opacity-60 bg-primary-violet/10 py-[0.6rem] mt-auto transition-all hover:py-3 hover:text-opacity-100 hover:text-primary-violet border-[#dee9f1]",
                        "px-2 sm:px-4 text-center border-t-2 text-sm cursor-pointer font-medium"
                      )}
                    >
                      {tab.name}
                    </button>
                  ))}
              </nav>
            </div>
          </div>
          <div className="px-2 sm:px-6 lg:px-8 py-6">
            <div>
              <div className="w-full">
                {upgrade && (
                  <div>
                    <InfoMessage
                      message={`You have created ${assessmentCount}  out of ${constant.TEACHER_FREE_ASSESSMENT_LIMIT} available assessments.`}
                    ></InfoMessage>
                  </div>
                )}
                {selectedTab !== tabInfo.PREMIUM_ASSESSMENTS.id && (
                  <div className="flex flex-wrap space-y-2 sm:flex-row sm:space-y-0 sm:pr-0 justify-end mb-4">
                    {props.userContext.roleId ===
                      Constant.UserRoleId.District && (
                      <button
                        onClick={() => setShowConvertToSchoolPopup(true)}
                        className="ml-4 bg-secondary-teal border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-dark-teal hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      >
                        Convert a School Assessment
                      </button>
                    )}
                    <AllowedTo perform={Permissions.assessments_create}>
                      {(props.userContext.roleId ===
                        Constant.UserRoleId.SchoolTeacher ||
                        props.userContext.roleId ===
                          Constant.UserRoleId.District) && (
                        <button
                          onClick={() => handleRedirect(false)}
                          className={
                            "ml-4 bg-secondary-teal border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-dark-teal hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                          }
                        >
                          Create New Assessment
                        </button>
                      )}
                      {(props.userContext.roleId ===
                        Constant.UserRoleId.SchoolAdmin ||
                        props.userContext.roleId ===
                          Constant.UserRoleId.FreeTeacher) && (
                        <Fragment>
                          <button
                            onClick={() => handleRedirect(false)}
                            type="submit"
                            className={
                              upgradeWithLimit
                                ? "opacity-50 ml-4 bg-secondary-teal border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-dark-teal hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                : "ml-4 bg-secondary-teal border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-dark-teal hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            }
                          >
                            Create New Class Assessment
                          </button>

                          {!upgrade && (
                            <button
                              onClick={() => handleRedirect(true)}
                              type="submit"
                              className="ml-4 bg-secondary-teal border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-dark-teal hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            >
                              Create New School Assessment
                            </button>
                          )}
                        </Fragment>
                      )}
                    </AllowedTo>
                  </div>
                )}
                {selectedTab !== tabInfo.PREMIUM_ASSESSMENTS.id && (
                  <MyAssessment
                    key={refreshKey}
                    userId={props.userContext.userId}
                    roleId={props.userContext.roleId}
                    schoolId={props.userContext.schoolId}
                    districtId={props.userContext.districtId}
                    selectedTabId={selectedTab}
                    defaultSchoolYearId={defaultSchoolYearId}
                    from={selectedTab === tabInfo.FULL_ASSESSMENT_BANK.id ? constant.RedirectedFrom.FULLASSESSMENT : constant.RedirectedFrom.MYASSESSMENTS}
                  ></MyAssessment>
                )}
                {selectedTab == tabInfo.PREMIUM_ASSESSMENTS.id && (
                  <AllowedTo
                    perform={Permissions.premium_assessments_view}
                    no={() => <AccessDenied />}
                  >
                    <PremiumAssessment
                      key={refreshKey}
                      userId={props.userContext.userId}
                      roleId={props.userContext.roleId}
                      schoolId={props.userContext.schoolId}
                      districtId={props.userContext.districtId}
                      defaultSchoolYearId={defaultSchoolYearId}
                    ></PremiumAssessment>
                  </AllowedTo>
                )}
              </div>
            </div>
          </div>
        </div>
      </AllowedTo>
    </>
  );
}

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(AssessmentBank);

import { LoadingIcon } from "../../assets/icons";

function ThemedSuspense() {
  return (
    <div className="text-lg font-large text-gray-600 dark:text-gray-400 dark:bg-gray-900">
      <div className="flex items-center flex-col justify-center absolute w-full h-5/6 bg-white">
        <LoadingIcon className="animate-spin h-10 w-10"></LoadingIcon>
        <h3 className="text-black text-xl animate-bounce relative top-9">
          Loading...
        </h3>
      </div>
    </div>
  );
}

export default ThemedSuspense;

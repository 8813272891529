import { useEffect, useState } from "react";
import { connect } from "react-redux";
import Loader from "../../shared/loader";
import {
  getDistrictSchoolsInformation,
} from "../../../api/profileApi";

const DistrictInfo = (props: any) => {

  const [schoolsInfo, setSchoolsInfo] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);
    getDistrictSchoolsInformation().then((d) => {
      setSchoolsInfo(d.data);
      setIsLoading(false);
    }).catch((e) => setIsLoading(false));
  }, []);

  return (
    <div className="p-4">
      {isLoading && <Loader/>}
      <div className="mt-3 p-2">
        <div>
          <table className="table border-b w-full">
            <thead>
              <tr className="bg-light-violet text-white py-3 px-3">
                <th className="text-center p-4 text-md font-medium">
                  School Name
                </th>
                <th className="text-center p-4 text-md font-medium">
                  Account ID
                </th>
                <th className="text-center p-4 text-md font-medium">
                  Admin Details
                </th>
              </tr>
            </thead>
            <tbody>
              {
                schoolsInfo.map((school, index) => {
                  return (
                    <tr
                      key={school.schoolId}
                      className={`hover:bg-light-violet/20 transition ease-in-out duration-300 ${
                        index % 2 === 0 ? "bg-white" : "bg-light-violet/10"
                      } `}
                    >
                      <td className="text-center p-4 text-sm font-normal">
                        {school.schoolName}
                      </td>
                      <td className="text-center p-4 text-sm font-normal uppercase">
                        {school.accountLoginName}
                      </td>
                      <td className="text-center p-4 text-sm font-normal block">
                        <span className="block mb-2">
                          {school.accountAdminName}
                        </span>
                        <a className="text-blue-700 underline" href={`mailto:${school.accountAdminEmail}`}>
                          {school.accountAdminEmail}
                        </a>
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default connect((state: any) => {
  return {
    profile: state.profile,
    userContext: state.userContext,
  };
})(DistrictInfo);

import classNames from "classnames";

interface IDotRank {
  dotRankId: number;
  score: number;
}
export default function DotRank(props: IDotRank) {
  function getColor() {
    switch (props.dotRankId ?? 0) {
      case 0:
      case 1:
        return "border-gray-400";
      case 2:
        return "border-[#E53F71]"; //"#E53F71";
      case 3:
        return "border-[#FFDB3A]"; //"#FFDB3A";
      case 4:
        return "border-[#B2D136]"; //#B2D136";
    }
  }

  return (
    <div>
      <div
        className={classNames(
          "flex-shrink-0 font-semibold font-size-lg cursor-pointer h-8 w-8 rounded-full flex items-center justify-center border-4",
           getColor()
        )}
      >
        <span>{props.score}</span>
      </div>
    </div>
  );
}

import { Configuration } from "../../environment/setup";
import { AxiosHelper } from "../../environment/axiosConfig";
import { AxiosResponse } from "axios";
import APIConstant from "../../utils/constant/apiConstant";

export function getClassInformation(studentId: number) {
  const url = `${Configuration.ApiBaseUrl}app/classes/${studentId}/student/ClassInformation`;
  return AxiosHelper.axiosInstance().get(url);
}

export function getAssignmentAssociatedClasses(studentId: number) {
  const url = `${Configuration.ApiBaseUrl}app/classes/${studentId}/student/assignmentAssociatedClasses`;
  return AxiosHelper.axiosInstance().get(url);
}

export function getByClassId(classId: number): Promise<AxiosResponse<any>> {
  return AxiosHelper.axiosInstance().get<any>(
    APIConstant.Student_API.GetStudentsByClassId,
    {
      params: { classId: classId },
    }
  );
}

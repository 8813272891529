import moment from "moment";
import { useEffect, useState } from "react";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useTranslation } from "react-i18next";
import { GoldCoinIcon } from "../../../../../../assets/icons";
import { getScoreLabel } from "../../../../../../utils/scoreHelper";

function OverviewResult(params: any) {
  const [studyPlanResult, setStudyPlanResult] = useState<any>();
  const { t } = useTranslation();
  const getRingColor = (value) => {
    let ringColor = "";
    switch (true) {
      case value < 63:
        ringColor = "#E53F71";
        break;
      case value >= 63 && value < 80:
        ringColor = "#FFDB3A";
        break;
      case value >= 80:
        ringColor = "#B2D136";
        break;
      default:
        ringColor = "bg-gray-700";
        break;
    }
    return ringColor;
  };

  function overview() {
    if (params.data) {
      const overviewResultInput = {
        results_header: {
          assignment: {
            result: {
              retry_percent: 0,
              percent:
                Number(params.data.totalAttempted) === 0
                  ? 0
                  : Math.round(
                      (Number(params.data.totalScore) /
                        Number(params.data.totalAttempted)) *
                        100
                    ),
              totalAttempted: params.data.totalAttempted,
              totalScore: params.data.totalScore,
            },
          },
          elapsed_time: params.data.sessionDuration,
          date_completed: params.data.dateCompleted,
          earnedStar: params.data.earnedStar,
          test: {
            name:
              params.data.subjectName ??
              params.data.domainName ??
              params.data.standardName,
          },
          teacher: {
            first: params.data.teacherFirstName,
            last: params.data.teacherLastName,
          },
          student: {
            first: params.data.studentFirstName
              ? params.data.studentFirstName
              : "",
            last: params.data.studentLastName
              ? params.data.studentLastName
              : "",
          },
          class: {
            name: params.data.class,
          },
          game: { name: params.data.gameName, score: params.data.gameScore },
        },
      };
      setStudyPlanResult(overviewResultInput);
    }
  }
  useEffect(() => {
    overview();
  }, []);

  function needsGrading(): boolean {
    return (
      studyPlanResult?.results_header?.assignment?.result?.unmarked
    );
  }

  return (
    <>
      {studyPlanResult && (
        <div className="flex-none md:w-64 h-auto bg-site-teal rounded-md shadow-md xl:mr-12 md:mr-6 shadow-lg">
          <div className="text-md text-white text-center px-12 md:px-5 ">
            <h2 className="text-lg text-white font-medium uppercase text-center my-4">
              {t("StudyPlanResult.Score")}
            </h2>
            {needsGrading() ? (
              <div
                style={{
                  width: 160,
                  height: 160,
                  display: "inline-grid",
                  fontSize: "17px;",
                }}
              >
                <CircularProgressbar
                  value={0}
                  text={`${studyPlanResult?.results_header?.assignment?.result?.percent}`}
                  styles={buildStyles({
                    pathColor: `${getRingColor(
                      studyPlanResult?.results_header?.assignment?.result
                        ?.percent
                    )}`,
                    textColor: "#374151",
                  })}
                />
              </div>
            ) : (
              <div
                style={{
                  width: 140,
                  height: 140,
                  display: "inline-grid",
                  position: "relative",
                }}
              >
                <CircularProgressbar
                  value={
                    studyPlanResult?.results_header?.assignment?.result?.percent
                  }
                  text={`${
                    studyPlanResult?.results_header?.assignment?.result
                      ?.retry_percent ||
                    studyPlanResult?.results_header?.assignment?.result?.percent
                  }%`}
                  styles={buildStyles({
                    pathColor: `${getRingColor(
                      studyPlanResult?.results_header?.assignment?.result
                        ?.percent
                    )}`,
                    textColor: "#374151",
                  })}
                  className="circular-bar-pending-size"
                />
                <span className="absolute top-1/2 left-1/2 transform -translate-x-2/4 -translate-y-2/4 pt-11 text-white">
                  (
                  {
                    getScoreLabel(
                      studyPlanResult?.results_header?.assignment?.result
                        ?.totalScore)
                  }
                  /
                  {
                    studyPlanResult?.results_header?.assignment?.result
                      ?.totalAttempted
                  }
                  )
                </span>
              </div>
            )}
          </div>
          {<div className="w-full text-lg text-white text-center mb-4"></div>}

          <div className="hidden flex flex-none flex-col px-7 py-8 -mx-2 rounded-md shadow-md mb-4 bg-gradient-to-b from-orange-dark to-white shadow-xl">
            <div className="flex flex-col w-full items-center ">
              <div className="capitalize font-myriad font-semibold text-lg text-gray-700 pb-2">
                {t("StudyPlanResult.TokenEarned")}
              </div>
              <div className="relative w-24 inline-block pb-10 ">
                <span className="inline-block absolute left-0">
                  <GoldCoinIcon className="w-9 h-9"></GoldCoinIcon>
                </span>
                <span className="inline-block absolute left-6">
                  <GoldCoinIcon className="w-9 h-9"></GoldCoinIcon>
                </span>
                <span className="inline-block absolute left-12">
                  <GoldCoinIcon className="w-9 h-9"></GoldCoinIcon>
                </span>
              </div>
            </div>
          </div>
          <div className="mt-10 flex flex-col flex-none mx-7 text-white border-white border-b border-opacity-25 pb-2 mb-2">
            <div className="text-xs text-white capitalize">
              {t("StudyPlanResult.DateCompleted")}
            </div>
            <div className="text-white font-roboto font-bold capitalize">
              {studyPlanResult?.results_header?.date_completed
                ? moment(
                    studyPlanResult?.results_header?.date_completed
                  ).format("MM/DD/YYYY")
                : ""}
            </div>
          </div>
          <div className="flex flex-col flex-none mx-7 text-white border-white border-b border-opacity-25 pb-2 mb-3">
            <div className="text-xs text-white capitalize">
              {t("StudyPlanResult.SessionTime")}
            </div>
            <div className="text-white font-roboto font-bold">
              {moment()
                .startOf("day")
                .add(studyPlanResult?.results_header?.elapsed_time, "seconds")
                .format("HH:mm:ss")}
            </div>
          </div>
          <div className="flex flex-col flex-none mx-7 text-white border-white border-b border-opacity-25 pb-1 mb-3">
            <div className="text-xs text-white capitalize">
              {t("StudyPlanResult.Subject")}
            </div>
            <div className="text-white text-md font-roboto font-bold capitalize">
              {studyPlanResult?.results_header?.test
                ? `${studyPlanResult?.results_header?.test.name}`
                : "-"}{" "}
            </div>
          </div>
          <div className="flex flex-col flex-none mx-7 text-white border-white border-b border-opacity-25 pb-2 mb-3">
            <div className="text-xs text-white capitalize">
              {t("StudyPlanResult.StudentName")}
            </div>
            <div className="text-white text-md font-roboto font-bold capitalize">
              {Object.keys(studyPlanResult?.results_header?.student).length >
                0 &&
                `${studyPlanResult?.results_header?.student?.first} ${studyPlanResult?.results_header?.student?.last}`}
            </div>
          </div>
          {studyPlanResult?.results_header?.game.name !== null &&
            studyPlanResult?.results_header?.game.name !== undefined &&
            studyPlanResult?.results_header?.game.name !== "" && (
              <>
                <div className="flex flex-col flex-none mx-7 text-white border-white border-b border-opacity-25 pb-2 mb-3">
                  <div className="text-xs text-light-teal capitalize">
                    {t("StudyPlanResult.GameName")}
                  </div>
                  <div className="text-light-teal text-md font-roboto font-bold capitalize">
                    {studyPlanResult?.results_header?.game
                      ? `${studyPlanResult?.results_header?.game.name}`
                      : "-"}{" "}
                  </div>
                </div>
                <div className="flex flex-col flex-none mx-7 text-white border-white border-b border-opacity-25 pb-2 mb-3">
                  <div className="text-xs text-light-teal capitalize">
                    {t("StudyPlanResult.GameScore")}
                  </div>
                  <div className="text-light-teal text-md font-roboto font-bold capitalize">
                    {studyPlanResult?.results_header?.game
                      ? `${studyPlanResult?.results_header?.game.score}`
                      : "-"}{" "}
                  </div>
                </div>
              </>
            )}
        </div>
      )}
    </>
  );
}
export default OverviewResult;

import { connect } from "react-redux";
import { Fragment, useEffect, useRef, useState } from "react";
import Permissions from "../../../../../utils/permissions";
import "../../../../../css/style.css";
import PrintUsageReport from "./printUsageReport";
//import AddSubscriptionModal from "./addSubscriptionModal";
import AllowedTo from "../../../../shared/rbac";
import AccessDenied from "../../../../shared/accessDenied";
import {
  exportUsageReportBySubject,
  exportUsageReportBySchool,
  getUsageReportBySubject,
} from "../../../../../api/report/usageReport";
import { IReportData } from "../../../../../model/report/reportData";
import { IUsageReportData } from "../../../../../model/report/usageReport/usageReportData";
import { useReactToPrint } from "react-to-print";
import { AxiosResponse } from "axios";
import School from "../../../../shared/dropdowns/school";
import Subject from "../../../../shared/dropdowns/subject";
import { ISelectElement, ISelectSchoolElement } from "../../../../../model/interface/selectElement";
import constant from "../../../../../utils/constant/constant";
import classNames from "classnames";
import Loader from "../../../../shared/loader";
import AddSubscriptionModal from "./addSubscriptionModal";
import Breadcrumb from "../../shared/breadcrumb";
import { IBreadcrumb } from "../../../../../model/common/breadcrumb";
import { useTranslation } from "react-i18next";
import RouteConstant from "../../../../../utils/constant/routeConstant";
import { getEndDate, getStartDate } from "../../../../../utils/dateHelper";
import { ISchoolYear } from "../../../../../model/teacher/schoolYear";
import Moment from "moment";
import { SchoolYearDropDown } from "../../../schoolYearDropdown";

function UsageReport(props) {
  const { t } = useTranslation();
  const ReportViewFilter = {
    NumberOfActivity: "Number Of Activities",
    TotalTime: "Total Time",
    TotalQuestionAnswered: "Total Questions Answered",
  };

  const SubjectExpiryStatus = {
    Expired: 0,
    Active: 1,
    PartiallyExpired: 2,
  };

  const [showAddSubscriptionPopup, setShowAddSubscriptionPopup] =
    useState(false);
  const [startDate, setStartDate] = useState<any>();
  const [endDate, setEndDate] = useState<any>();
  const [usageBySubject, setUsageBySubject] = useState<Array<any>>([]);
  const [reportData, setReportData] = useState<Array<IUsageReportData>>([]);
  const [reportType, setReportType] = useState<string>("");
  const [showLoader, setShowLoader] = useState(false);
  const [selectedSchools, setSelectedSchools] = useState<Array<ISelectSchoolElement>>(
    []
  );
  const [selectedSubjects, setSelectedSubjects] = useState<
    Array<ISelectElement>
  >([]);
  const componentRefUsageReport = useRef<HTMLDivElement>(null);
  const handleUsageReportPrint = useReactToPrint({
    content: () => componentRefUsageReport.current,
  });
  const [schoolYear, setSchoolYear] = useState("Current");
  const [selectedSubjectForPopup, setSelectedSubjectForPopup] =
    useState<number>();
  const [isDistrictUser] = useState<boolean>(
    props.userContext.roleId === constant.UserRoleId.District ? true : false
  );
  const [schoolYearData, setSchoolYearData] = useState<Array<ISchoolYear>>();
  const [selectedSchoolYearId, setSelectedSchoolYearId] = useState<number>();
  const [resetSchoolYear, setResetSchoolYear] = useState<boolean>(false);
  useEffect(() => {
    setReportType(ReportViewFilter.NumberOfActivity);
  }, []);

  useEffect(() => {
    if (selectedSchoolYearId) {
      handleDateFilter(selectedSchoolYearId);
      setSchoolYearById(selectedSchoolYearId);
      setResetSchoolYear(false);
    }
  }, [selectedSchoolYearId]);

  useEffect(() => {
    switch (reportType) {
      case ReportViewFilter.NumberOfActivity:
        getUsageReportByNumberOfActivity();
        break;
      case ReportViewFilter.TotalQuestionAnswered:
        getUsageReportByTotalQuestionAnswered();
        break;
      case ReportViewFilter.TotalTime:
        getUsageReportByTotalTime();
        break;
    }
  }, [reportType]);

  function runReport() {
    setShowLoader(true);
    let schoolIds: Array<number>;
    let subjectIds: Array<number>;
    if (props.userContext.roleId === constant.UserRoleId.District) {
      schoolIds = selectedSchools.filter(s => s.value.schoolId > 0).map(s => s.value.schoolId);
    } else {
      schoolIds = [ props.userContext.schoolId ];
    }

    subjectIds = selectedSubjects.filter((s) => s.value).map((s) => parseInt(s.value));
    setReportType(ReportViewFilter.NumberOfActivity);
    getUsageReportBySubject(schoolIds, subjectIds, props.userContext.districtId, selectedSchoolYearId ?? 0).then((response: any) => {
      const reportData = response.data as IReportData<IUsageReportData>;
      setReportData(reportData.data);
      getUsageReportByNumberOfActivity(reportData.data);
      setShowLoader(false);
    });
  }
  function getUsageReportByNumberOfActivity(data: any = undefined) {
    const usageReportData = data === undefined ? reportData : data;

    const maxUsage = Math.max.apply(
      Math,
      usageReportData.map(function (o) {
        return o.numberOfActivity?.total ?? 0;
      })
    );
    var usage = usageReportData.map((value) => {
      return {
        assignment: value.numberOfActivity?.assignment ?? 0,
        assessment: value.numberOfActivity?.assessment ?? 0,
        studyPlan: value.numberOfActivity?.studyPlan ?? 0,
        total: value.numberOfActivity?.total ?? 0,
        subjectId: value.subjectId,
        subjectName: value.subjectName,
        subjectExpiryStatus: value.subjectExpiryStatus,
        assignmentPercentage: getPercentage(
          value.numberOfActivity?.total ?? 0,
          value.numberOfActivity?.assignment ?? 0
        ),
        assessmentPercentage: getPercentage(
          value.numberOfActivity?.total ?? 0,
          value.numberOfActivity?.assessment ?? 0
        ),
        studyPlanPercentage: getPercentage(
          value.numberOfActivity?.total ?? 0,
          value.numberOfActivity?.studyPlan ?? 0
        ),
        subjectPercentage: getPercentage(
          maxUsage,
          value.numberOfActivity?.total ?? 0
        ),
      };
    });

    usage = usage.sort((a, b) => b.total - a.total);
    setUsageBySubject(usage);
  }
  function getUsageReportByTotalQuestionAnswered(data: any = undefined) {
    const usageReportData = data === undefined ? reportData : data;

    const maxUsage = Math.max.apply(
      Math,
      usageReportData.map(function (o) {
        return o.totalQuestionAnswered?.total ?? 0;
      })
    );

    let usage = usageReportData.map((value) => {
      return {
        assignment: value.totalQuestionAnswered?.assignment ?? 0,
        assessment: value.totalQuestionAnswered?.assessment ?? 0,
        studyPlan: value.totalQuestionAnswered?.studyPlan ?? 0,
        total: value.totalQuestionAnswered?.total ?? 0,
        subjectId: value.subjectId,
        subjectName: value.subjectName,
        subjectExpiryStatus: value.subjectExpiryStatus,
        assignmentPercentage: getPercentage(
          value.totalQuestionAnswered?.total ?? 0,
          value.totalQuestionAnswered?.assignment ?? 0
        ),
        assessmentPercentage: getPercentage(
          value.totalQuestionAnswered?.total ?? 0,
          value.totalQuestionAnswered?.assessment ?? 0
        ),
        studyPlanPercentage: getPercentage(
          value.totalQuestionAnswered?.total ?? 0,
          value.totalQuestionAnswered?.studyPlan ?? 0
        ),
        subjectPercentage: getPercentage(
          maxUsage,
          value.totalQuestionAnswered?.total ?? 0
        ),
      };
    });

    usage = usage.sort((a, b) => b.total - a.total);
    setUsageBySubject(usage);
  }
  function getUsageReportByTotalTime(data: any = undefined) {
    const usageReportData = data === undefined ? reportData : data;

    const maxUsage = Math.max.apply(
      Math,
      usageReportData.map(function (o) {
        return o.totalTime?.total ?? 0;
      })
    );

    let usage = usageReportData.map((value) => {
      return {
        assignment: ConvertSecondToDay(value.totalTime?.assignment ?? 0, true),
        assessment: ConvertSecondToDay(value.totalTime?.assessment ?? 0, true),
        studyPlan: ConvertSecondToDay(value.totalTime?.studyPlan ?? 0, true),
        total: ConvertSecondToDay(value.totalTime?.total ?? 0, false),
        totalVal: value.totalTime?.total ?? 0,
        subjectId: value.subjectId,
        subjectName: value.subjectName,
        subjectExpiryStatus: value.subjectExpiryStatus,
        assignmentPercentage: getPercentage(
          value.totalTime?.total ?? 0,
          value.totalTime?.assignment ?? 0
        ),
        assessmentPercentage: getPercentage(
          value.totalTime?.total ?? 0,
          value.totalTime?.assessment ?? 0
        ),
        studyPlanPercentage: getPercentage(
          value.totalTime?.total ?? 0,
          value.totalTime?.studyPlan ?? 0
        ),
        subjectPercentage: getPercentage(maxUsage, value.totalTime?.total ?? 0),
      };
    });

    usage = usage.sort((a, b) => b.totalVal - a.totalVal);
    setUsageBySubject(usage);
  }
  function getPercentage(total, earned) {
    if (total > 0 && earned > 0) {
      return Math.round((earned / total) * 100);
    }
    return 0;
  }

  function getFormatTimeValue(value: number): string {
    return Math.trunc(value).toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      maximumFractionDigits: 0,
    });
  }

  function ConvertSecondToDay(n, showForChart) {
    var day = parseInt((n / (24 * 3600)).toString());

    n = n % (24 * 3600);
    var hour = parseInt((n / 3600).toString());

    n %= 3600;
    var minutes = n / 60;
    const dayHours = day > 0 ? day * 24 + hour : hour;

    return getFormatTimeValue(dayHours) + ":" + getFormatTimeValue(minutes);
  }

  function onPrint() {
    if (handleUsageReportPrint) handleUsageReportPrint();
  }

  function onReset() {
    setResetSchoolYear(true);
    setSelectedSchools([]);
    setSelectedSubjects([]);
    setUsageBySubject([]);
    setReportData([]);
    setReportType(ReportViewFilter.NumberOfActivity);
    setSchoolYear("Current");
  }
  const getPrintFilters = (): any => {
    let schools: string = "All Schools";
    if (
      selectedSchools !== undefined &&
      selectedSchools !== null &&
      selectedSchools.length > 0
    ) {
      schools = selectedSchools
        .map((cl) => {
          return cl.label;
        })
        .join(", ");
    }

    var subjects: string = "All Subjects";
    if (
      selectedSubjects !== undefined &&
      selectedSubjects !== null &&
      selectedSubjects.length > 0
    ) {
      subjects = selectedSubjects
        .map((cl) => {
          return cl.label;
        })
        .join(", ");
    }
    var filter: any = {
      subjectNames: subjects,
      schoolYear: schoolYear === "Current" ? "Current Year" : "Previous Year",
      schoolNames: schools,
      view: reportType,
    };
    return filter;
  };

  function onExport(bySchool: boolean = false) {
    setShowLoader(true);
    let schoolIds: Array<number>;
    let subjectIds: Array<number>;
    if (props.userContext.roleId === constant.UserRoleId.District) {
      schoolIds = selectedSchools.filter(s => s.value.schoolId > 0).map(s => s.value.schoolId);
    } else {
      schoolIds = [ props.userContext.schoolId ];
    }
    subjectIds = selectedSubjects.filter((s) => s.value).map((s) => parseInt(s.value));

    var filter: string = "";
    var selectedFilter: any = getPrintFilters();
    if (props.userContext.roleId === constant.UserRoleId.District) {
      filter = " School: " + selectedFilter.schoolNames;
      filter += " ";
    }
    filter += " Subject: " + selectedFilter.subjectNames;
    filter += " School Year: " + selectedFilter.schoolYear;
    filter += " View: " + selectedFilter.view;

    if (bySchool){
      exportUsageReportBySchool(
        schoolIds,
        subjectIds,
        props.userContext.districtId,
        selectedSchoolYearId ?? 0,
        reportType,
        filter
      ).then((r) => {
        if (r.data) {
          startFileDownload(r, "SubjectUsageReportbySchool.xlsx");
          setShowLoader(false);
        }
      });
    } else {
      exportUsageReportBySubject(
        schoolIds,
        subjectIds,
        props.userContext.districtId,
        selectedSchoolYearId ?? 0,
        reportType,
        filter
      ).then((r) => {
        if (r.data) {
          startFileDownload(r, "UsageReport.xlsx");
          setShowLoader(false);
        }
      });
    }
  }

  const startFileDownload = (
    response: AxiosResponse<any>,
    fileName: string
  ) => {
    const file = response.data;
    const fileURL = URL.createObjectURL(file);
    let link = document.createElement("a");
    link.href = fileURL;
    link.download = fileName;
    link.setAttribute("target", "_blank");
    document.body.appendChild(link);
    link.click();
  };

  const breadcrumbItems = () => {
    const items: Array<IBreadcrumb> = [
      { name: t("Breadcrumb.Home"), url: RouteConstant.ROUTE_DASHBOARD },
      {
        name: t("Breadcrumb.Reports"),
        url: RouteConstant.REPORTS,
      },
      { name: t("Breadcrumb.UsageReport"), url: "" },
    ];

    return items;
  };

  function handleSchoolYearLoaded(currentSchoolYearId?: number) {
    handleSchoolYearChange(currentSchoolYearId);
  }

  function handleSchoolYearChange(schoolYearId?: number) {
    setSelectedSchoolYearId(schoolYearId);
  }

  function handleSchoolYearData(schoolYearData: Array<ISchoolYear>) {
    setSchoolYearData(schoolYearData);
  }

  function handleDateFilter(schoolYearId?: number) {
    let selectedSchoolYear = schoolYearData?.filter(
      (s) => s.schoolYearId == schoolYearId
    )[0];
    let minDate = selectedSchoolYear
      ? Moment(
          selectedSchoolYear.startDate.toString().split("T")[0],
          "YYYY-MM-DD"
        ).toDate()
      : new Date();

    let maxDate = selectedSchoolYear
      ? Moment(
          selectedSchoolYear.startDate.toString().split("T")[0],
          "YYYY-MM-DD"
        )
          .add(1, "year")
          .subtract(1, "day")
          .toDate()
      : new Date();

    setStartDate(getStartDate(minDate));
    setEndDate(getEndDate(maxDate));
  }

  function setSchoolYearById(schoolYearId?: number) {
    const currentSchoolYear = schoolYearData?.find(
      (s) =>
        parseInt(s.schoolYearId.toString()) ===
          parseInt(schoolYearId ? schoolYearId.toString() : "0") &&
        s.currentYearOffset === 0
    );
    if (currentSchoolYear) setSchoolYear("Current");
    else setSchoolYear("Previous");
  }

  return (
    <AllowedTo
      perform={Permissions.reports_usagereport_view}
      no={() => <AccessDenied />}
    >
      <>
        {showLoader && <Loader />}
        <div className="min-w-0 w-full">
          <div className="lg:flex lg:items-center px-4 py-3 lg:px-8 border-b">
            <Breadcrumb items={breadcrumbItems()} />
            <div className="lg:w-[55%] text-lg font-medium flex gap-2 h-auto sm:h-10 items-center">
              <h1
                className={`font-medium flex justify-start mb-0 pb-0 text-primary-violet`}
              >
                Usage Report by Subject
              </h1>
            </div>
          </div>
          <div className="px-2 sm:px-6  lg:px-8 py-1 relative my-4">
            <div className=" bg-gray-100 w-full rounded-lg p-5">
              <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 2xl:grid-cols-5 gap-4 mb-4 mt-4">
                {props.userContext.roleId === constant.UserRoleId.District && (
                  <School
                    selectedItems={selectedSchools}
                    onChange={setSelectedSchools}
                  />
                )}
                <Subject
                  key={selectedSchools.length}
                  selectedItems={selectedSubjects}
                  setSelectedSubject={setSelectedSubjects}
                  selectedSchools={
                    props.userContext.roleId === constant.UserRoleId.District
                      ? selectedSchools.map((r) => r.value.schoolAccountId)
                      : undefined
                  }
                  placeholder="All Subjects"
                  subjectTypeId={1}
                  themeColor={"#008fbb"}
                />
                <div className="flex flex-col gap-x-2">
                  <label className="relative flex text-gray-500 cursor-pointer focus:outline-none border-gray-400 mb-1">
                    School Year
                  </label>
                  <SchoolYearDropDown
                    className="w-24"
                    reset={resetSchoolYear}
                    offsets={[0, -1]}
                    handleSchoolYearChange={handleSchoolYearChange}
                    handleSchoolYearLoaded={handleSchoolYearLoaded}
                    handleSchoolYearData={handleSchoolYearData}
                  ></SchoolYearDropDown>
                </div>
              </div>
              <div className="flex gap-4 justify-end">
                <button
                  className="cursor-pointer bg-transparent border my-auto border-primary-violet shadow-sm py-2 px-4 flex justify-center text-sm font-medium text-primary-violet hover:text-white hover:bg-light-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3 transition ease-in-out duration-500"
                  onClick={onReset}
                >
                  Reset
                </button>
                <button
                  className="cursor-pointer bg-primary-violet border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-dark-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3 "
                  onClick={runReport}
                >
                  Run Report
                </button>
                <div className="cursor-not-allowed">
                  <button
                    className={classNames(
                      "bg-primary-violet border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ",
                      reportData === undefined || reportData.length === 0
                        ? "bg-opacity-20 text-primary-violet text-opacity-40 pointer-events-none"
                        : "hover:bg-dark-violet hover:shadow-lg"
                    )}
                    onClick={() => onExport(false)}
                    disabled={
                      reportData === undefined || reportData.length === 0
                    }
                  >
                    Export View
                  </button>
                </div>
                {props.userContext.roleId === constant.UserRoleId.District && (
                  <div className="cursor-not-allowed">
                    <button
                      className={classNames(
                        "bg-primary-violet border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ",
                        reportData === undefined || reportData.length === 0
                          ? "bg-opacity-20 text-primary-violet text-opacity-40 pointer-events-none"
                          : "hover:bg-dark-violet hover:shadow-lg"
                      )}
                      onClick={() => onExport(true)}
                      disabled={
                        reportData === undefined || reportData.length === 0
                      }
                    >
                      Export by School
                    </button>
                  </div>
                )}

                <div className="cursor-not-allowed">
                  <button
                    className={classNames(
                      "bg-primary-violet border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ",
                      reportData === undefined || reportData.length === 0
                        ? "bg-opacity-20 text-primary-violet text-opacity-40 pointer-events-none"
                        : "hover:bg-dark-violet hover:shadow-lg"
                    )}
                    onClick={onPrint}
                    disabled={
                      reportData === undefined || reportData.length === 0
                    }
                  >
                    Print Report
                  </button>
                </div>
              </div>
              <div className="border-b border-gray-400 py-2 mb-2"></div>
              {reportData && reportData.length > 0 && (
                <div className="bg-white py-10 px-5 mt-4">
                  <div className="flex gap-x-4 mb-6">
                    <div className="flex gap-1">
                      <input
                        type="radio"
                        id="numberOfActivity"
                        name="time-line"
                        className="h-4 w-4 mt-0.5 cursor-pointer text-primary-violet border-gray-300 focus:ring-primary-violet text-gray-400 bg-gray"
                        onChange={() => {
                          setReportType(ReportViewFilter.NumberOfActivity);
                        }}
                        checked={
                          reportType === ReportViewFilter.NumberOfActivity
                        }
                      />
                      <label
                        htmlFor="numberOfActivity"
                        className="cursor-pointer text-sm text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3"
                      >
                        Number of Activities
                      </label>
                    </div>
                    <div className="flex gap-1">
                      <input
                        id="totalTime"
                        type="radio"
                        name="time-line"
                        className="h-4 w-4 mt-0.5 cursor-pointer text-primary-violet border-gray-300 focus:ring-primary-violet text-gray-400 bg-gray"
                        onChange={() => {
                          setReportType(ReportViewFilter.TotalTime);
                        }}
                        checked={reportType === ReportViewFilter.TotalTime}
                      />
                      <label
                        htmlFor="totalTime"
                        className="cursor-pointer text-sm text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3"
                      >
                        Total Time
                      </label>
                    </div>
                    <div className="flex gap-1">
                      <input
                        id="totalQuestion"
                        type="radio"
                        name="time-line"
                        className="h-4 w-4 mt-0.5 cursor-pointer text-primary-violet border-gray-300 focus:ring-primary-violet text-gray-400 bg-gray"
                        onChange={() => {
                          setReportType(ReportViewFilter.TotalQuestionAnswered);
                        }}
                        checked={
                          reportType === ReportViewFilter.TotalQuestionAnswered
                        }
                      />
                      <label
                        htmlFor="totalQuestion"
                        className="cursor-pointer text-sm text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3"
                      >
                        Total Questions Answered
                      </label>
                    </div>
                  </div>
                  <div className="overflow-auto">
                    <table className="w-full lg:min-w-[1024px] xl:w-full">
                      <thead>
                        <tr className="border-b border-gray-300">
                          <th className="text-right p-2 text-sm font-semibold w-12">
                            Subject
                          </th>
                          <th className="text-center w-14 p-2 text-sm font-semibold bg-gray-100">
                            Total
                            {reportType === ReportViewFilter.TotalTime && (
                              <div className="text-xs text-gray-500">hh:mm</div>
                            )}
                          </th>
                          <th className="text-center w-8 p-2 text-sm font-semibold bg-[#e06666]">
                            Assignments
                            {reportType === ReportViewFilter.TotalTime && (
                              <div className="text-xs text-gray-500">hh:mm</div>
                            )}
                          </th>
                          <th className="text-center w-8 p-2 text-sm font-semibold bg-[#f7b26a]">
                            Assessments
                            {reportType === ReportViewFilter.TotalTime && (
                              <div className="text-xs text-gray-500">hh:mm</div>
                            )}
                          </th>
                          <th className="text-center p-2 text-sm font-semibold bg-[#ffd965] w-24 whitespace-nowrap">
                            Study Plan
                            {reportType === ReportViewFilter.TotalTime && (
                              <div className="text-xs text-gray-500">hh:mm</div>
                            )}
                          </th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {usageBySubject &&
                          usageBySubject.map((value, index) => {
                            return (
                              <Fragment key={index}>
                                <tr className="border-b border-gray-300 divide-x">
                                  <td className="text-sm p-2">
                                    <div
                                      className={`w-full text-right whitespace-nowrap ${
                                        isDistrictUser &&
                                        selectedSchools.length === 0
                                          ? "cursor-pointer hover:underline "
                                          : ""
                                      } px-2
                                        ${
                                          value.subjectExpiryStatus ===
                                          SubjectExpiryStatus.Expired
                                            ? "text-gray-400"
                                            : "text-primary-violet"
                                        }                                        
                                        `}
                                    >
                                      <span
                                        onClick={() => {
                                          if (
                                            isDistrictUser &&
                                            selectedSchools.length === 0
                                          ) {
                                            setShowAddSubscriptionPopup(true);
                                            setSelectedSubjectForPopup(
                                              value.subjectId
                                            );
                                          }
                                        }}
                                      >
                                        {value.subjectName +
                                          (value.subjectExpiryStatus ===
                                          SubjectExpiryStatus.Expired
                                            ? " - expired"
                                            : value.subjectExpiryStatus ===
                                              SubjectExpiryStatus.PartiallyExpired
                                            ? " - partially expired"
                                            : "")}
                                      </span>
                                    </div>
                                  </td>
                                  <td className="text-sm p-2 bg-gray-100 text-center whitespace-nowrap">
                                    {value.total}
                                  </td>
                                  <td className="text-sm p-2 text-center">
                                    {value.assignment}
                                  </td>
                                  <td className="text-sm p-2 text-center">
                                    {value.assessment}
                                  </td>
                                  <td className="text-sm p-2 text-center">
                                    {value.studyPlan}
                                  </td>
                                  <td className="">
                                    <div
                                      className="flex h-4 min-w-[30px]"
                                      style={{
                                        width: value.subjectPercentage + "%",
                                      }}
                                    >
                                      {value.assignment !== 0 &&
                                        value.assignment !== "0: 0" && (
                                          <div
                                            className="bg-[#e06666] h-full flex items-center justify-end"
                                            style={{
                                              width:
                                                value.assignmentPercentage +
                                                "%",
                                            }}
                                            title={
                                              "Assignment Usage - " +
                                              value.assignment
                                            }
                                          ></div>
                                        )}
                                      {value.assessment !== 0 &&
                                        value.assessment !== "0: 0" && (
                                          <div
                                            className="bg-[#f7b26a] h-full flex items-center justify-end"
                                            style={{
                                              width:
                                                value.assessmentPercentage +
                                                "%",
                                            }}
                                            title={
                                              "Assessment Usage - " +
                                              value.assessment
                                            }
                                          ></div>
                                        )}
                                      {value.studyPlan !== 0 &&
                                        value.studyPlan !== "0: 0" && (
                                          <div
                                            className="bg-[#ffd965] h-full flex items-center justify-end"
                                            style={{
                                              width:
                                                value.studyPlanPercentage + "%",
                                            }}
                                            title={
                                              "Study Plan Usage - " +
                                              value.studyPlan
                                            }
                                          ></div>
                                        )}
                                    </div>
                                  </td>
                                </tr>
                              </Fragment>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="hidden">
          <div
            className="bg-white py-5 px-5 pt-2 mt-4"
            ref={componentRefUsageReport}
          >
            <PrintUsageReport
              printFilter={getPrintFilters()}
              data={usageBySubject}
            />
          </div>
        </div>

        {showAddSubscriptionPopup && selectedSubjectForPopup && (
          <AddSubscriptionModal
            showPopUp={setShowAddSubscriptionPopup}
            key={selectedSubjectForPopup}
            districtId={props.userContext.districtId}
            subjectId={selectedSubjectForPopup}
            startDate={startDate}
            endDate={endDate}
          />
        )}
      </>
    </AllowedTo>
  );
}

export default connect((state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
})(UsageReport);

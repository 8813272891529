import { useState, useEffect } from "react";

const Details = (props: any) => { 
  const { questions } = props;
  type Dok = {
    id: number;
    name: string;
    count: number;
  };
  const [questionCount, setQuestionCount] = useState<number>(0);
  const [totalPoints, settotalPoints] = useState<number>(0);
  const [standardCount, setStandardCount] = useState<number>(0);
  const [doks, setDoks] = useState<Array<Dok>>([]);

  const calculateDoks = (tempQuestions: any) => {
    const tempDoks: Array<Dok> = [];
    tempQuestions.forEach((question) => {
      const dokExist = tempDoks.some(
        (dok) => dok.id === question.dokId
      );
      if (dokExist) {
        const ind = tempDoks.findIndex(
          (dok) => dok.id === question.dokId
        );
        const item = tempDoks[ind];
        tempDoks[ind] = {
          ...item,
          count: item.count + 1,
        };
      } else {
        tempDoks.push({
          id: question.dokId,
          name: question.dok,
          count: 1,
        });
      }
    });
    setDoks(tempDoks);
  };

  function getStandardCount() {
    return Array.from(
      new Set(
        questions.map((q) => {
          if (q.standardId) {
            return q.standardId;
          }
        })
      )
    ).length;
  }

  function getPointsPossible() {
    return questions.reduce(
      (total, question) => total + question.pointsPossible,
      0
    );
  }

  const setQuestionsData = () => {
    if (questions.length === 0) {
      calculateDoks([]);
      setQuestionCount(0);
      settotalPoints(0);
      setStandardCount(0);
    } else {
      calculateDoks([]);
      setQuestionCount(questions.length);
      settotalPoints(getPointsPossible());
      setStandardCount(getStandardCount());
      calculateDoks(questions);
    }
  };

  useEffect(() => {
    setQuestionsData();
  }, []);

  useEffect(() => {
    setQuestionsData();
  }, [questions]);

  return (
    <div className="flex order-first lg:order-none gap-3 flex-col bg-gray-50 border py-5 pl-3 pr-5 text-gray-500 text-sm 2xl:ml-2 rounded-md col-span-4">
      <div className="flex flex-row justify-between items-center pl-2">
        <p className="font-semibold">Total Questions</p>
        <span className="inline-flex items-baseline px-2 py-0 text-white rounded-full bg-primary-violet">
          {questionCount}
        </span>
      </div>
      <div className="flex flex-row justify-between items-center pl-2">
        <p className="font-semibold">Total Points</p>
        <span className="inline-flex items-baseline px-2 py-0 text-white rounded-full bg-primary-violet">
          {totalPoints}
        </span>
      </div>
      <div className="flex flex-row justify-between items-center pl-2">
        <p className="font-semibold">Standard count</p>
        <span className="inline-flex items-baseline px-2 py-0 text-white rounded-full bg-primary-violet">
          {standardCount}
        </span>
      </div>
      <div className="flex flex-row divide-x divide-dashed divide-gray-300 gap-2 mt-2 overflow-auto">
        {doks.length > 0 ? (
          doks.sort((a:Dok,b:Dok) => a.id - b.id).map((dok) => (
            <div className="flex flex-col gap-2 justify-between pl-2">
              <p className="font-semibold">Dok {dok.id}</p>
              <p>{dok.name}</p>
              <span className="inline-flex items-baseline px-2 py-0 text-white rounded-full bg-primary-violet self-center">
                {dok.count}
              </span>
            </div>
          ))
        ) : (
          <div className="flex flex-col gap-2">
            <p>N/A</p>
            <span className="inline-flex items-baseline px-2 py-0 text-white rounded-full bg-primary-violet ">
              0
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default Details;

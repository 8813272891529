import { Fragment, useEffect, useState } from "react";
import { XIcon } from "@heroicons/react/solid";
import DefaultAvatar from "../../../../../assets/avatar/defaultAvatar.json";
import {
  getAlienAvatar,
  updateAlienAvatar,
} from "../../../../../api/student/studentProfile";
import { useTranslation } from "react-i18next";
import Constant from "../../../../../utils/constant/constant";
import { IStudentReward } from "../../../../../model/interface/studentReward";

type studentGradeModeType =
  | "Kindergarten"
  | "Elementary"
  | "MiddleSchool"
  | "LiftOff";
interface AlienAvatarProps {
  studentId: number;
  gradeId: number;
  alienData: any;
  showOnQuestions: boolean;
  studentGradeMode: studentGradeModeType;
  applykinderGartenStyle?: boolean;
  isLiftOff?: boolean;
  rewards: IStudentReward;
}

function AlienAvatar(props: AlienAvatarProps) {
  const { t } = useTranslation();
  const {
    studentId,
    gradeId,
    alienData,
    showOnQuestions,
    applykinderGartenStyle,
    isLiftOff,
  } = props;
  const [showAlienPopup, setShowAlienPopup] = useState(false);
  const [alienResponse, setAlienResponse] = useState(false);
  const [isEnabledOnQuestions, setIsEnabledOnQuestions] =
    useState(showOnQuestions);

  function getDefaultAvatar() {
    const defaultAvatar: string = JSON.stringify(DefaultAvatar);
    return defaultAvatar;
  }

  function showAlien() {
    const programMode = isLiftOff
      ? Constant.ProgramMode.LO
      : Constant.ProgramMode.PL;
    getAlienAvatar(studentId, gradeId, programMode).then((data) => {
      setAlienResponse(data.data);
      setShowAlienPopup(true);
    });
  }

  function updateAvatar() {
    const updatedAlienData = window["getUpdatedAvatar"]();
    if (updatedAlienData) {
      setShowAlienPopup(false);
      window["SetSelectedAvatar"](
        { StudentAvatar: updatedAlienData },
        {
          applykinderGartenStyle: applykinderGartenStyle
            ? applykinderGartenStyle
            : false,
        }
      );
      updateAlienAvatar(
        studentId,
        gradeId,
        updatedAlienData,
        isEnabledOnQuestions,
        isLiftOff ?? false
      );
    }
  }

  useEffect(() => {
    const selectedAvatar = {
      StudentAvatar:
        alienData && alienData.trim() !== "" ? alienData : getDefaultAvatar(),
    };
    window["SetSelectedAvatar"](
      selectedAvatar,
      applykinderGartenStyle ? applykinderGartenStyle : false
    );
  }, []);

  useEffect(() => {
    if (showAlienPopup) {
      window["loadInitialFunction"](alienResponse);
    }
  }, [alienResponse, showAlienPopup])

  const alienLabelClasses = t("StudentElementaryHome.AlienAvatar").length > 15 ? "bottom-1 whitespace-normal leading-none" : "bottom-2 whitespace-nowrap leading-tight"

  return (
    <Fragment>
      {props.studentGradeMode === "Kindergarten" && (
        <div className="bg-white bg-opacity-50 cursor-pointer h-full w-full py-4 px-4 text-center rounded-xl hover:shadow-2xl transition ease-in-out duration-500 h-[170px] relative">
          <div
            id="studentAlien"
            className="wrapperAvatar avatar-img right-[75px] bottom-[70px]"
            onClick={() => showAlien()}
          ></div>
          <h5 className={`mt-2 text-lg absolute left-[50%] transform translate-x-[-50%] ${alienLabelClasses}`}>
            {t("StudentElementaryHome.AlienAvatar")}
          </h5>
        </div>
      )}

      {props.studentGradeMode !== "Kindergarten" && (
        <div className="rounded-2xl bg-gradient-to-b from-[#56ebdf] to-[#adfb28] cursor-pointer h-full w-full py-4 px-4 text-center hover:shadow-2xl transition ease-in-out duration-500">
          <div
            id="studentAlien"
            className="avatar-img"
            onClick={() => showAlien()}
          ></div>
          <h5 className="mt-2 text-lg">
            {t("StudentElementaryHome.AlienAvatar")}
          </h5>
        </div>
      )}

      <Fragment>
        {showAlienPopup && (
          <div className={`fixed inset-0 z-40 flex items-center bg-black bg-opacity-50 sm:justify-center enter-done`}>
            <div className="w-full overflow-hidden bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:max-w-[750px]">
              <span className=" w-full flex items-center justify-between p-4">
                <span className="ml-2">
                  {t("StudentElementaryHome.AlienAvatar")}
                </span>
                <XIcon
                  className="cursor-pointer h-4 w-4"
                  onClick={() => setShowAlienPopup(false)}
                ></XIcon>
              </span>
              <div
                id="avatarBuilder"
                className="-ml-5 -mt-1 h-[500px]"
              ></div>
              <div className="flex bg-gray-50 items-end justify-end m-2">
                <div className="pb-2 pr-2 hidden">
                  <input
                    id="styled-checkbox-2"
                    type="checkbox"
                    title="Is Enabled On Questions"
                    className="checkbox"
                    checked={isEnabledOnQuestions}
                    onChange={() =>
                      setIsEnabledOnQuestions(!isEnabledOnQuestions)
                    }
                  />
                  {t("StudentElementaryHome.ShowAvatarOnQuestions")}
                </div>
                <button
                  type="button"
                  onClick={updateAvatar}
                  className="align-bottom inline-flex font-bold items-center justify-center cursor-pointer leading-5 transition-colors duration-150  focus:outline-none px-5 py-3 rounded-lg text-lg text-white bg-primary-violet hover:shadow-lg hover:bg-dark-violet"
                >
                  {t("StudentElementaryHome.Save")}
                </button>
              </div>
            </div>
          </div>
        )}
        
        <input type="hidden" id="hdnShowAvatarOnQuestion" />
        <span className="hidden" id="earnedGalaxyStar">
          {props.studentGradeMode === "LiftOff"
            ? props.rewards.loStarReward.earnedStar +
              "/" +
              props.rewards.loStarReward.totalStar
            : props.rewards.plStarReward.earnedStar +
              "/" +
              props.rewards.plStarReward.totalStar}
        </span>
      </Fragment>
    </Fragment>
  );
}

export default AlienAvatar;

import { Fragment, useEffect, useState } from 'react'
import { connect } from "react-redux";
import { Select } from "@windmill/react-ui";
import { ISelectElement } from "../../../../../model/interface/selectElement";
import Loader from "../../../../shared/loader";
import SelectGroup from "react-select";
import Asterisk from "../../../../shared/asterisk";
import ClassDropDown from "../../shared/classDropDown";
import ITeacherClass from "../../../../../model/interface/teacherclass";
import { IGrade } from "../../../../../model/interface/grade";
import { ISchool } from "../../../../../model/interface/school";
import { getAllGrades } from "../../../../../api/gradeApi";
import { getAllClassesBySubject } from "../../../../../api/teacher/teacher";
import { getByClassId } from '../../../../../api/class/classStudent';
import { getSchoolByDistrictId } from "../../../../../api/teacher/school";
import userApi from "../../../../../api/userApi";
import { default as Constant } from "../../../../../utils/constant/constant";
import StudentReportCardButtons from './studentReportCardButtons';

interface StudentReportCardFiltersProps {
  profile: any,
  userContext: any,
  isDistrictUser: boolean,
  isSchoolAdmin: boolean,
  selectedSchoolId: number,
  setSelectedSchoolId: (number: number) => void,
  selectedStudentId: number,
  setSelectedStudentId: (number: number) => void,
  showReport: boolean,
  setShowReport: (boolean: boolean) => void,
  runReport: () => void,
  printReport: ()=> void,
}

const defaultClass: ISelectElement = { label: "All Classes", value: "0"};
const defaultStudent: ISelectElement = { label: "Select a Student", value: "0"};

function StudentReportCardFilters(props: Readonly<StudentReportCardFiltersProps>) {
  const [schools, setSchools] = useState<ISchool[]>([]);
  const [selectedSchoolAccountId, setSelectedSchoolAccountId] = useState<number>(0);
  const [grades, setGrades] = useState<IGrade[]>([]);
  const [selectedGradeId, setSelectedGradeId] = useState<number>(0);
  const [classes, setClasses] = useState<Array<ITeacherClass>>([]);
  const [selectedClassId, setSelectedClassId] = useState<number>(0);
  const [selectedClasses, setSelectedClasses] = useState<Array<ISelectElement>>([defaultClass])
  const [students, setStudents] = useState<Array<ISelectElement>>([]);
  const [selectedStudent, setSelectedStudent] = useState<ISelectElement>(defaultStudent);
  const [isStudentsLoaded, setIsStudentsLoaded] = useState<boolean>(false);
  const [showLoading, setShowLoading] = useState<boolean>(false);

  const {
    isDistrictUser, 
    isSchoolAdmin,
    selectedSchoolId, 
    setSelectedSchoolId, 
    selectedStudentId, 
    setSelectedStudentId, 
    showReport,
    setShowReport, 
    runReport,
    printReport,
  } = props;

  const getStudents = async(schoolId?: number, schoolAccountId?: number, classId?: number, gradeId?: number) => {
    setShowLoading(true);
    schoolId = schoolId ?? selectedSchoolId;
    schoolAccountId = schoolAccountId ?? selectedSchoolAccountId;
    classId = classId ?? selectedClassId;
    gradeId = gradeId ?? selectedGradeId;
    let studentResponseData: any[];
    if (!classId) {
      const query = {
        gradeId: gradeId,
        classId: 0,
        roleId: Constant.UserRoleId.Student,
        schoolAccountId: schoolAccountId,
        isActive: true,
        showLocked: false,
        pageSize: 99999,
        PageNumber: 1,
        districtId: props.userContext?.districtId,
      };
      const usersResponse = await userApi.GetAllUsers(query);
      studentResponseData = usersResponse.data.data;
    } else {
      const studentsResponse = await getByClassId(classId);
      studentResponseData = studentsResponse.data;
    }

    if (studentResponseData?.length) {
      const studentsOptions: Array<ISelectElement> = studentResponseData
        .filter((u: { gradeId?: number; }) => gradeId === 0 || u.gradeId == gradeId)
        .map((s: { lastName: string; firstName: string; studentId?: number; userId?: number; }) => ({
          label:  `${s.lastName}, ${s.firstName} ${s.studentId ? `- ${s.studentId}` : ""}`,
          value: "" + s.userId
        }))
        .sort((a: ISelectElement, b: ISelectElement) => a.label < b.label ? -1 : a.label > b.label ? 1 : 0);
      setStudents(studentsOptions);
    } else {
      setStudents([]);
    }

    setIsStudentsLoaded(true);
    setShowLoading(false);
  }

  const getClasses = async (schoolId?: number, schoolAccountId?: number) => {
    const selectedSchoolYearId = props.profile.currentSchoolYear.schoolYearId;
    schoolId = schoolId ?? selectedSchoolId;
    schoolAccountId = schoolAccountId ?? selectedSchoolAccountId;
    if (!schoolId || !schoolAccountId)
      return;
    const classesResponse = await getAllClassesBySubject(
      props.userContext.userId,
      props.userContext.roleId,
      null,
      selectedSchoolYearId,
      schoolId
    );
    if (classesResponse?.data?.data?.length) {
      const classesOptions = classesResponse.data.data
        .map((r: { isArchived?: boolean; isAdditionalClass?: boolean }) => 
          ({
            ...r, 
            isArchived: r.isArchived ?? false,
            isAditionalClass: r.isAdditionalClass ?? false,
          })
        );
      setClasses(classesOptions);
    } else {
      setClasses([]);
    }
    setShowLoading(false);
  }

  const getGrades = async () => {
    const gradesResponse = await getAllGrades();
    if (gradesResponse?.data?.length) {
      setGrades(gradesResponse.data);
    } else {
      setGrades([]);
    }
  }

  const getSchools = async () => {
    const schoolsResponse = await getSchoolByDistrictId(
      props.userContext?.districtId ?? 0, 
      0
    );
    if (schoolsResponse?.data?.length) {
      setSchools(schoolsResponse.data);
      if (!isDistrictUser) {
        const schoolId = +props.userContext.schoolId;
        const schoolAccountId = schoolsResponse.data.find(s => s.schoolId === schoolId)?.schoolAccountId ?? 0;
        setSelectedSchoolId(schoolId);
        setSelectedSchoolAccountId(schoolAccountId);
        getClasses(schoolId, schoolAccountId);
        getStudents(schoolId, schoolAccountId);
      }
    } else {
      setSchools([]);
    }
  };

  const resetSelectedValues = () => {
    setSelectedGradeId(0);
    setSelectedClasses([defaultClass]);
    setSelectedClassId(0);
    setSelectedStudent(defaultStudent);
    setSelectedStudentId(0);
  }

  const handleSchoolChange = (selectedId: number) => {
    const schoolAccountId = schools?.find(s => s.schoolId === selectedId)?.schoolAccountId ?? 0;
    setSelectedSchoolId(selectedId);
    setSelectedSchoolAccountId(schoolAccountId);
    getClasses(selectedId, schoolAccountId);
    getStudents(selectedId, schoolAccountId, 0, 0);
    resetSelectedValues();
  }

  const handleGradeChange = (selectedId: number) => {
    setSelectedGradeId(selectedId);
    getStudents(selectedSchoolId, undefined, selectedClassId, selectedId);
    setSelectedStudent(defaultStudent);
  }

  const handleClassChange = (selectedItems: Array<ISelectElement>) => {
    const classId = +selectedItems[selectedItems.length - 1]?.value;
    setSelectedClasses(selectedItems);
    setSelectedClassId(classId);
    setIsStudentsLoaded(false);
    getStudents(selectedSchoolId, undefined, classId, selectedGradeId);
    setSelectedStudent(defaultStudent);
    setSelectedStudentId(0);
    setShowReport(false);
  }

  const handleStudentChange = (selectedItem: ISelectElement) => {
    setSelectedStudent(selectedItem);
    setSelectedStudentId(+selectedItem.value);
  }

  const resetFilters = () => {
    if (isDistrictUser) {
      setSelectedSchoolId(0);
      setSelectedSchoolAccountId(0)
      setClasses([]);
      setStudents([]);
    } else {
      getStudents(undefined, undefined, 0, 0);
    }
    resetSelectedValues();
    setShowReport(false);
  };

  useEffect(() => {
    getSchools();
    getGrades();
    getClasses();
  }, []);

  useEffect(() => {
    if (+selectedStudent.value !== selectedStudentId) {
      const studentToSelect = students.find(s => +s.value === selectedStudentId);
      if (studentToSelect) 
        setSelectedStudent(studentToSelect);
      else {
        setShowReport(false);
        if (isStudentsLoaded) 
          setSelectedStudentId(0);
      }
    }
    setIsStudentsLoaded(false);
  }, [selectedStudentId, isStudentsLoaded])

  return (
    <div>
      {showLoading && <Loader></Loader>}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-4 xxxl:grid-cols-6 gap-4 mb-4 mt-4">
        {isDistrictUser && (
          <div className="flex flex-col">
            <label className="block mb-1 text-sm text-gray-500">
              School <Asterisk />
            </label>
            <Select
              value={selectedSchoolId}
              onChange={e => handleSchoolChange(+e.target.value)}
              className={"rounded-md"}
            >
              {selectedSchoolId === 0 && (<option value={0}>Select School</option>)}
              {schools?.map((item: ISchool, index: number) => 
                (
                  <Fragment key={index}>
                    <option value={item?.schoolId}>{item?.name}</option>
                  </Fragment>
                )
              )}
            </Select>
          </div>
        )}

        <div className="flex flex-col">
          <label className="block mb-1 text-sm text-gray-500">
            Grade
          </label>
          <Select
            value={selectedGradeId}
            onChange={e => handleGradeChange(+e.target.value)}
            className={"rounded-md border-gray-300"}
            disabled={selectedSchoolId === 0}
          >
            <option value={0}>All Grades</option>
            {grades?.map((item: IGrade, index: number) => 
              (
                <Fragment key={index}>
                  <option value={item?.gradeId}>{item?.name}</option>
                </Fragment>
              )
            )}
          </Select>
        </div>

        <div className="flex flex-col">
          <label className="block mb-1 text-sm text-gray-500">
            Class
          </label>
          <ClassDropDown
            schoolYearId={props.profile.currentSchoolYear.schoolYearId}
            isMultiSelect={false}
            onChange={handleClassChange}
            value={selectedClasses}
            data={classes}
            showArchived={isDistrictUser || isSchoolAdmin}
            showHidden={isDistrictUser || isSchoolAdmin}
            isDisabled={selectedSchoolId === 0}
            selectedSchoolAccountId={selectedSchoolAccountId}
          />
        </div>

        <div className="flex flex-col">
          <label className="block mb-1 text-sm text-gray-500">
            Student <Asterisk />
          </label>
          <SelectGroup 
            isMulti={false}
            placeholder={"Select a Student"}
            onChange={(e) => handleStudentChange(e as ISelectElement)}
            options={students}
            value={selectedStudent}
            closeMenuOnSelect={true}
            isSearchable={true}
          />
        </div>
      </div>

      <StudentReportCardButtons 
        resetFilters={resetFilters}
        isRunReportEnabled={selectedSchoolId !== 0 && selectedStudentId !== 0}
        runReport={runReport}
        isPrintReportEnabled={showReport}
        printReport={printReport}
      />
    </div>
  )
}

export default connect((state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
})(StudentReportCardFilters);

import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import RouteConstant from "../../../../../utils/constant/routeConstant";
import Breadcrumb from "../../shared/breadcrumb";
import { IBreadcrumb } from "../../../../../model/common/breadcrumb";
import Constant from "../../../../../utils/constant/constant";
import DynamicComponent from "../../../../dynamicComponent/dynamicComponent";

const StudentPracticeResult = (props: any) => {
  const { t } = useTranslation();

  const gradeId = parseInt(props.match.params.gradeId);
  const activityType = props.match.params.activityType ?? "";
  type dynamicComponent = { component: any; parameter: any };
  const [resultComponent, setResultComponent] = useState<dynamicComponent>();
  const url = window.location.href;
  useEffect(() => {
    loadStudentView();
  }, []);

  function loadStudentView() {
    const parameter = {
      isTeacherView: true,
      studentGradeId: gradeId,
      studentId: parseInt(props.match.params.userId),
      sessionId: props.match.params.sessionId,
    };
    let componentName = "";
    if (
      activityType === Constant.Report_StudentActivityType.STUDY_PLAN_QUESTIONS
    ) {
      componentName = "StudyPlanResult";
    } else if (
      activityType === Constant.Report_StudentActivityType.CANNED_TEST
    ) {
      componentName = "CannedTestResult";
    } else if (
      activityType === Constant.Report_StudentActivityType.STUDY_PLAN_VIDEO
    ) {
      componentName = "VideoPracticeResult";
    }
    if (componentName !== "") {
      if (gradeId <= Constant.Grade.GRADE1) {
        componentName += "KG";
      } else if (
        gradeId > Constant.Grade.GRADE1 &&
        gradeId < Constant.Grade.GRADE6
      ) {
        componentName += "EL";
      } else if (gradeId >= Constant.Grade.GRADE6) {
        componentName += "MS";
      }
    }

    if (
      activityType ===
      Constant.Report_StudentActivityType.STUDY_PLAN_SUMMATIVE_TEST
    ) {
      componentName = "SummativeTestResult";
    } else if (
      activityType ===
      Constant.Report_StudentActivityType.STUDY_PLAN_DOMAIN_TEST
    ) {
      componentName = "DomainTestResult";
    }
    setResultComponent({ component: componentName, parameter });
  }

  const breadcrumbItems = () => {
    let items: Array<IBreadcrumb> = [];
    if (url.indexOf("activitysearch") > -1) {
      items = [
        { name: t("Breadcrumb.Home"), url: RouteConstant.ROUTE_DASHBOARD },
        { name: t("Breadcrumb.Reports"), url: RouteConstant.REPORTS },
        {
          name: t("Breadcrumb.StudentActivitySearchReport"),
          url: RouteConstant.TeacherRoutes.StudentActivitySearchReport,
        },
        { name: "Student Result", url: "" },
      ];
    } else {
      items = [
        { name: t("Breadcrumb.Home"), url: RouteConstant.ROUTE_DASHBOARD },
        {
          name: t("Breadcrumb.GradeBook"),
          url: RouteConstant.TeacherRoutes.GradeBook,
        },
        { name: "Student Result", url: "" },
      ];
    }
    return items;
  };

  return (
    <Fragment>
      <div className="px-4 py-5 lg:px-8 border-b">
        <Breadcrumb items={breadcrumbItems()} />
      </div>
      <div className="relative bg-primary-violet w-full">
        {resultComponent && DynamicComponent(resultComponent)}
      </div>
    </Fragment>
  );
};

export default StudentPracticeResult;

import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { XIcon } from "@heroicons/react/solid";
import { LockIcon } from "../../../../../assets/icons";
import { Configuration } from "../../../../../environment/setup";
import { useConfirmation } from "../../../../shared/confirmation/confirmationService";

interface RocketsProps {
  rewards?: any;
  type: string;
}

const Rockets = (props: RocketsProps) => {
  const { t } = useTranslation();
  const confirm = useConfirmation();
  const [showRocketPopup, setRocketPopup] = useState(false);
  const [rockets, setRockets] = useState<Array<any>>([]);

  useEffect(() => {
    if (props?.type === "PL") {
      setRockets(props?.rewards?.plRockets);
    } else {
      setRockets(props?.rewards?.loRockets);
    }
  }, [props?.rewards]);

  const onRocketClicked = (rocket: any) => {
    if (rocket.isLocked) {
      confirm({
        variant: "info",
        title: "",
        description:
          t("Rockets.UnlockRoketMessage") +
          rocket.starsRequiredToUnlock +
          t("Rockets.GalaxyStarsUnlockRocket"),
      });
    }
  };

  return (
    <>
      <div
        onClick={() => setRocketPopup(true)}
        className="h-full w-full bg-gradient-to-b flex items-center flex-col group justify-end from-[#0062ff] to-[#61efff] rounded-2xl py-4 px-4 text-center md:col-span-2 hover:shadow-2xl transition ease-in-out duration-500 cursor-pointer"
      >
        <img
          className="w-auto h-28 m-auto flex-shrink-0 transition group-hover:scale-110 ease-in-out duration-500 "
          src={`${Configuration.S3bucketImagePath}/images/rocket-img.svg`}
          alt="rocket"
          title="Rocket"
        />

        {props?.rewards && (
          <p className="mt-2 ">{`${
            rockets?.filter((r: any) => {
              return !r.isLocked;
            }).length
          }/${rockets?.length}`}</p>
        )}
        <h5 className="text-lg">{t("StudentElementaryHome.Rockets")} </h5>
      </div>
      {showRocketPopup && (
        <Fragment>
          <div className="fixed inset-0 z-50 flex items-center bg-black bg-opacity-50 sm:items-center sm:justify-center enter-done">
            <div className="w-full flex flex-col justify-between overflow-hidden h-auto max-h-80percent bg-white rounded-lg dark:bg-gray-800 sm:m-4 sm:max-w-xl">
              <header className="flex justify-between p-4">
                <div className="mt-0   text-lg font-semibold text-gray-700 dark:text-gray-300 mb-2">
                  <p>
                    {t("Rockets.YouHave")}{" "}
                    {
                      rockets?.filter((r: any) => {
                        return !r.isLocked;
                      }).length
                    }{" "}
                    {t("Rockets.OutOf")}
                  </p>
                  {rockets?.filter((r: any) => r.isLocked).length > 0 && (
                    <p className="font-normal text-sm">
                      {t("Rockets.Get")}{" "}
                      {
                        rockets?.filter((r: any) => r.isLocked)[0]
                          .starsRequiredToUnlock
                      }{" "}
                      {t("Rockets.More")}
                    </p>
                  )}
                </div>
                <XIcon
                  className="cursor-pointer h-4 w-4"
                  onClick={() => setRocketPopup(false)}
                ></XIcon>
              </header>
              <div className="w-full relative overflow-auto px-6 py-3">
                <div className="max-w-7xl mx-auto rounded-2xl">
                  <ul className="grid grid-cols-2 gap-x-2 gap-y-3 sm:grid-cols-3 sm:gap-x-3 lg:grid-cols-6 xl:gap-x-4">
                    {rockets?.map((r: any, i) => (
                      <li
                        className="relative cursor-pointer"
                        onClick={() => {
                          onRocketClicked(r);
                        }}
                      >
                        <div className="group bg-gradient-to-b from-[#0062ff] to-[#61efff] shadow hover:bg-opacity-80 p-2 block w-full rounded-lg focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden transition ease-in-out duration-500">
                          <img
                            src={`${Configuration.S3bucketImagePath}/images/student/kindergarten/rockets/rocket${i}.png`}
                            alt=""
                            className="object-cover pointer-events-none m-auto h-16"
                          />
                          {r.isLocked && (
                            <div className="flex items-center justify-center">
                              <div className="absolute bg-white p-1 rounded-lg">
                                <LockIcon className="w-5 h-5" />
                              </div>
                            </div>
                          )}
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <footer className="flex flex-col items-center justify-end px-6 py-4 sm:flex-row">
                <button
                  className="align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 rounded-lg text-sm text-white border border-transparent  focus:shadow-outline-purple w-full sm:w-auto bg-primary-blue hover:bg-[#0149c1] gap-2 hover:shadow-md"
                  type="button"
                  onClick={() => setRocketPopup(false)}
                >
                  OK
                </button>
              </footer>
            </div>
          </div>
        </Fragment>
      )}
    </>
  );
};

export default Rockets;

import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { MessageApi } from "../../../../../api/message/messageApi";
import {
  CancelIcon,
  LiftOffIcon,
  LogoPLIcon,
} from "../../../../../assets/icons/index";
import ProfileModel from "../../../../../model/users/profile";
import { fetchProfile } from "../../../../../redux/actions/userActions";
import * as userContextAction from "../../../../../redux/actions/userContextAction";
import RouteConstant from "../../../../../utils/constant/routeConstant";
import Profile from "../../../../shared/header/profile";
import { useCollectionData } from "react-firebase-hooks/firestore";
import constant from "../../../../../utils/constant/constant";

export type HeaderTopProps = {
  userContext: userContextAction.UserContextState;
  profile?: ProfileModel;
  showParentConnectPopUp?: any;
  setUserContext: Function;
  setProfile: Function;
  toggleHeaderLeft: boolean;
  setToggleHeaderLeft(toggle: boolean): void;
};

export class UrlParams {
  userId!: string;
  roleId!: string;
  schoolId!: string;
  userName!: string;
  password!: string;
  encodedUserName!: string;
  encodedPassword!: string;
  displayName!: string;
  avatar!: string;
}

function HeaderTop(props: HeaderTopProps) {
  const [isactiveClass, setIsactiveClass] = useState("");
  const [showMenu, setMenu] = useState(true);
  const history = useHistory();
  const [programMode, setProgramMode] = useState(constant.ProgramMode.EG);
  const chatQuery: any = MessageApi.getRecentChatRef().where(
    "userId",
    "==",
    props.userContext.userId
  );
  const [recentChat]: any = useCollectionData(chatQuery);

  function toggleMenu() {
    setMenu(!showMenu);
    if (!showMenu) {
      setIsactiveClass("is-active");
    } else {
      setIsactiveClass("");
    }
  }

  function handleNaviagation(link: string, programMode?: string) {
    if (programMode) {
      setProgramMode(programMode);
      localStorage.setItem("programMode", programMode);
    }
    history.push(link);
  }

  useEffect(() => {
    MessageApi.handleOnActive(
      props.userContext.userId,
      props.userContext.schoolId
    );
  }, []);

  return (
    <header>
      <div className="flex bg-white justify-between items-center relative">
        <span
          onClick={() => {
            props.setToggleHeaderLeft(!props.toggleHeaderLeft);
          }}
        >
          {(!props.toggleHeaderLeft && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 absolute left-2 top-5 sm:top-6 lg:hidden text-gray-600"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                clipRule="evenodd"
              />
            </svg>
          )) || (
            <CancelIcon className="h-6 w-6 absolute left-2 top-5 sm:top-6 lg:hidden text-gray-600"></CancelIcon>
          )}
        </span>

        <div className="ml-8 sm:ml-7 lg:ml-0 p-2 flex items-end space-x-4">
          {programMode === constant.ProgramMode.LO && (
            <>
              <button
                onClick={() => {
                  handleNaviagation(
                    RouteConstant.LiftOff.MiddleSchool.Home,
                    constant.ProgramMode.LO
                  );
                }}
                className="filter grayscale"
              >
                <LiftOffIcon
                  title="Liftoff"
                  className="block lg:block h-12 sm:h-16 w-auto"
                ></LiftOffIcon>
              </button>
              <button
                onClick={() => {
                  handleNaviagation(
                    RouteConstant.MiddleSchool.assignments,
                    constant.ProgramMode.EG
                  );
                }}
              >
                <LogoPLIcon
                  title="Progress Learning"
                  className="w-20 h-auto md:ml-3 cursor-pointer"
                />
              </button>
            </>
          )}
          {programMode !== constant.ProgramMode.LO && (
            <>
              <button
                onClick={() =>
                  handleNaviagation(
                    RouteConstant.MiddleSchool.assignments,
                    constant.ProgramMode.EG
                  )
                }
              >
                <LogoPLIcon
                  title="Progress Learning"
                  className="w-16 sm:w-20 h-auto sm:ml-3 cursor-pointer"
                />
              </button>
              {props.profile?.isLOActive && (
                <button
                  onClick={() =>
                    handleNaviagation(
                      RouteConstant.LiftOff.MiddleSchool.Home,
                      constant.ProgramMode.LO
                    )
                  }
                  className="filter grayscale"
                >
                  <LiftOffIcon
                    title="Liftoff"
                    className="block lg:block h-12 sm:h-16 w-auto"
                  ></LiftOffIcon>
                </button>
              )}
            </>
          )}
        </div>
        <button
          onClick={() => toggleMenu()}
          className={`ml-auto mr-2 cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block hidden outline-none focus:outline-non navbar-burger focus:outline-none focus:bg-primary-green active:bg-primary-green ${isactiveClass}`}
          data-target="#navigation"
        >
          <span className="block relative w-6 h-px rounded-sm bg-black mt-1.5"></span>

          <span className="block relative w-6 h-px rounded-sm bg-black mt-1.5"></span>

          <span className="block relative w-6 h-px rounded-sm bg-black mt-1.5"></span>
        </button>
        {showMenu && (
          <nav
            className="top-navbar md:inline-flex md-flex-grow md:ml-auto"
            id="navigation"
          >
            <div className="md:inline-flex md:flex-row flex flex-col h-10 justify-center  order-first md:order-last relative nav-hover">
              <div
                id="marker"
                className="absolute left-0 h-1 rounded-2xl w-0 bg-primary-green -bottom-0  duration-700"
              ></div>

              <div>
                <div className="flex h-full">
                  <Profile
                    userContext={props.userContext}
                    profile={props.profile}
                    recentChat={recentChat}
                    name={
                      props.profile
                        ? props.profile.firstName + " " + props.profile.lastName
                        : ""
                    }
                    src={props.profile ? props.profile.profileImage : ""}
                    showContactUs={false}
                  ></Profile>
                </div>
              </div>
            </div>
          </nav>
        )}
      </div>
      <div className="w-full flex">
        <div className="w-1/2 bg-teal-light h-2"></div>
        <div className="w-1/2 bg-light-violet h-2"></div>
      </div>
    </header>
  );
}

HeaderTop.displayName = "Header";
const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setUserContext: (payload: userContextAction.UserContextState) => {
      dispatch(userContextAction.setUserContext(payload));
    },
    setProfile: (
      userId: number,
      roleId: number,
      schoolId: number,
      districtId: number
    ) => {
      dispatch(fetchProfile(userId, roleId, schoolId, districtId));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderTop);

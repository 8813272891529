import { XIcon } from "@heroicons/react/solid";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IGames } from "../../../../../model/interface/game";
import { getGames } from "../../../../../api/teacher/alienArena";
import { UserContextState } from "../../../../../redux/actions/userContextAction";
import Profile from "../../../../../model/users/profile";
import { Configuration } from "../../../../../environment/setup";
import {
  GetStudentGalaxyGameScore,
  GetStudentSchoolGameScore,
} from "../../../../../api/student/studentGameScoreApi";

export interface GameScorePopupProps {
  showPopup: (show: boolean) => void;
  userContext?: UserContextState;
  profile?: Profile;
}

const GameScorePopup: React.FC<GameScorePopupProps> = (props) => {
  const { t } = useTranslation();
  const defaultGameName = "Blast Off";
  const [games, setGames] = useState<Array<IGames>>([]);
  const [schoolGameScores, setSchoolGameScores] = useState<any>([]);
  const [galaxyGameScores, setGalaxyGameScores] = useState<any>([]);
  const [selectedGameName, setSelectedGameName] =
    useState<string>(defaultGameName);

  useEffect(() => {
    getGames(props.userContext?.userId!, props.userContext?.gradeId!, 1).then(
      (d) => {
        setGames(d.data);
      }
    );
    handleGameSelect(1, defaultGameName);
  }, []);

  function handleGameSelect(gameId: number, gameName?: string) {
    setSelectedGameName(gameName || "");
    GetStudentSchoolGameScore(
      props.userContext?.userId!,
      props.userContext?.gradeId!,
      gameId,
      1,
      props.userContext?.schoolId!
    ).then((d) => {
      var gameScoresData = d.data;
      setSchoolGameScores(gameScoresData.gameScores);
    });
    GetStudentGalaxyGameScore(
      props.userContext?.userId!,
      props.userContext?.gradeId!,
      gameId,
      1
    ).then((d) => {
      var gameScoresData = d.data;
      setGalaxyGameScores(gameScoresData.gameScores);
    });
  }

  return (
    <>
      <section className="">
        <div className="fixed inset-0 z-50 flex items-center bg-black bg-opacity-50 sm:items-center sm:justify-center enter-done">
          <div className="w-full flex flex-col justify-between overflow-hidden h-auto max-h-80percent bg-white rounded-lg dark:bg-gray-800 sm:m-4 sm:max-w-5xl">
            <header className="flex justify-end p-4">
              <XIcon
                className="cursor-pointer h-4 w-4"
                onClick={() => props.showPopup(false)}
              ></XIcon>
            </header>

            <div
              data-focus-lock-disabled="false"
              className="w-full relative overflow-auto px-4 py-3 "
            >
              <div className="grid grid-cols-1 lg:grid-cols-2 mb-6 gap-10">
                <div className="max-w-7xl mx-auto rounded-2xl">
                  <ul className="grid grid-cols-3 gap-x-2 gap-y-4 xs:grid-cols-5 sm:grid-cols-5 md:grid-cols-7  sm:gap-x-3 lg:grid-cols-4 xl:gap-x-4">
                    {games.map((game) => (
                      <li
                        className={`group  p-0 block w-full rounded-lg ring-2 ring-gray-400 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden transition ease-in-out duration-500 text-center shadow-md hover:shadow-2xl cursor-pointer bg-white 
                        ${
                          game.isLocked
                            ? "filter grayscale pointer-events-none"
                            : ""
                        }
                      `}
                        onClick={() => {
                          handleGameSelect(game.gameId, game.name);
                        }}
                      >
                        <div>
                          <img
                            src={`${
                              Configuration.S3bucketImagePath
                            }/images/game-thumbnail/${
                              game.imageurl?.toString().split("/")[
                                game.imageurl?.toString().split("/").length - 1
                              ]
                            }`}
                            alt=""
                            className="object-cover pointer-events-none m-auto w-full"
                          />

                          {game.isLocked && (
                            <span className="text-black bg-white/90 flex justify-center items-center absolute rounded-full bottom-12 lg:bottom-8 w-7 h-7 left-0 right-0 mx-auto shadow-lg border">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor"
                                className="w-4"
                                viewBox="0 0 16 16"
                              >
                                <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2z" />
                              </svg>
                            </span>
                          )}
                          <p className="text-sm pb-2 pt-4">
                            {t("Leaderboard." + game.name)}
                          </p>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
                <div>
                  <div>
                    <h2 className="font-Didact text-base mb-1">
                      {t("Leaderboard." + selectedGameName)}{" "}
                      {t("Leaderboard.SchoolRank")}
                    </h2>
                    <div className="overflow-hidden rounded-lg border bg-white shadow-lg">
                      <table className="table w-full text-sm ">
                        <thead className=" bg-[#23afff] border-b-2">
                          <tr>
                            <th className="font-normal text-left pl-2 ">
                              {t("Leaderboard.Place")}{" "}
                            </th>
                            <th className="font-normal text-left whitespace-nowrap pl-2 py-2">
                              {t("Leaderboard.Name")}{" "}
                            </th>
                            <th className="font-normal text-left  whitespace-nowrap pl-2 py-2">
                              {t("Leaderboard.Score")}{" "}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {schoolGameScores.map((gameScore, index) => {
                            return (
                              <tr
                                key={index}
                                className={
                                  index % 2 === 0
                                    ? " hover:bg-light-violet/20 transition ease-in-out duration-300 bg-white"
                                    : "hover:bg-light-violet/20 transition ease-in-out duration-300 bg-light-violet/10"
                                }
                              >
                                <td className="font-light pl-2 py-2">
                                  {gameScore.position}
                                </td>
                                <td className="font-light pl-2 py-2">
                                  {gameScore.studentFirstName}{" "}
                                  {gameScore.studentLastName}
                                </td>
                                <td className="font-light pl-2 py-2">
                                  {gameScore.score}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div className="mt-5">
                    <h2 className="font-Didact text-base mb-1">
                      {t("Leaderboard.GalaxyRank")}
                    </h2>
                    <div className="overflow-hidden rounded-lg border bg-white shadow-lg">
                      <table className="table w-full text-sm">
                        <thead className="bg-[#23afff] border-b-2">
                          <tr>
                            <th className="font-normal text-left px-2  rounded-tl-lg ">
                              {t("Leaderboard.Place")}
                            </th>
                            <th className="font-normal text-left whitespace-nowrap px-2 py-2">
                              {t("Leaderboard.SchoolName")}{" "}
                            </th>
                            <th className="font-normal text-left whitespace-nowrap px-2 py-2">
                              {t("Leaderboard.Name")}{" "}
                            </th>
                            <th className="font-normal text-left  whitespace-nowrap px-2 py-2">
                              {t("Leaderboard.Score")}{" "}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {galaxyGameScores.map((gameScore, index) => {
                            return (
                              <tr
                                key={index}
                                className={
                                  index % 2 === 0
                                    ? " hover:bg-light-violet/20 transition ease-in-out duration-300 bg-white"
                                    : "hover:bg-light-violet/20 transition ease-in-out duration-300 bg-light-violet/10"
                                }
                              >
                                <td className="font-light px-2 py-2">
                                  {gameScore.position}
                                </td>
                                <td className="font-light px-2 py-2">
                                  {" "}
                                  {gameScore.schoolName}
                                </td>
                                <td className="font-light px-2 py-2">
                                  {" "}
                                  {gameScore.studentFirstName}{" "}
                                  {gameScore.studentLastName}
                                </td>
                                <td className="font-light px-2 py-2">
                                  {" "}
                                  {gameScore.score}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <footer className="flex bg-gray-50 flex-col items-center justify-between px-6 py-4 sm:flex-row">
              <div className="mt-0 mb-2 text-lg font-semibold text-gray-700 dark:text-gray-300">
                <p className="">
                  {t("Leaderboard." + selectedGameName)}{" "}
                  {t("Leaderboard.Score")}:{" "}
                  {Math.max(
                    ...schoolGameScores
                      .filter((s) => {
                        if (s.studentID === props.userContext?.userId!) {
                          return s;
                        }
                      })
                      .map((s) => {
                        return s.score;
                      })
                      .concat([0])
                  )}
                </p>
              </div>
              <button
                className="align-bottom inline-flex font-bold items-center justify-center cursor-pointer leading-5 transition-colors duration-150  focus:outline-none px-5 py-3 rounded-lg text-lg text-white bg-primary-violet hover:shadow-lg hover:bg-dark-violet"
                type="button"
                onClick={() => props.showPopup(false)}
              >
                Ok
              </button>
            </footer>
          </div>
        </div>
      </section>
    </>
  );
};

export default GameScorePopup;

import Breadcrumb from "../../shared/breadcrumb";
import ColorPicker from "../../../../shared/colorPicker";
import classNames from "classnames";
import auth from "../../../../../utils/auth";
import Select from "react-select";
import ClassModel from "../../../../../model/teacher/classManagement/classModel";
import RouteConstant from "../../../../../utils/constant/routeConstant";
import PagingResponse from "../../../../../model/common/pagingResponse";
import classListResponse from "../../../../../model/teacher/classManagement/classListResponse";
import { PageAccessMode } from "../../../../../model/common/pageAccessMode";
import { IBreadcrumb } from "../../../../../model/common/breadcrumb";
import { useTranslation } from "react-i18next";
import { UseFormGetValues, UseFormHandleSubmit, UseFormRegister } from "react-hook-form";
import { ISelectElement } from "../../../../../model/interface/selectElement";

interface ClassDetail_MainFormProps {
  mode: PageAccessMode,
  classId: any,
  classDetail: ClassModel,
  classes: PagingResponse<classListResponse>,
  setClassDetail: (classDetail: ClassModel) => void,
  addNewClass: (classDetail: ClassModel) => void,
  updateClass: () => void,
  selectedColor: string,
  setSelectedColor: (color: string) => void,
  selectedSchoolYearId: number,
  setSelectedSchoolYearId: (id: number) => void,
  defaultSchoolYearId: number,
  schoolYears: Array<ISelectElement> | undefined,
  isGoogleEnabled: boolean,
  setIsGoogleEnabled: (isEnabled: boolean) => void,
  isCanvasEnabled: boolean,
  setIsCanvasEnabled: (isEnabled: boolean) => void,
  validateShowGooglePopup: () => boolean,
  subjectCount: number,
  register: UseFormRegister<ClassModel>,
  handleSubmit: UseFormHandleSubmit<ClassModel>,
  getValues: UseFormGetValues<ClassModel>,
  errors: any,
  handleClassChange: (e: any) => void,
  userContext: any,
  profile: any,
}

function ClassDetail_MainForm(props: ClassDetail_MainFormProps) {
  const {
    mode,
    classId,
    classDetail,
    classes,
    setClassDetail,
    addNewClass,
    updateClass,
    selectedColor,
    setSelectedColor,
    selectedSchoolYearId,
    setSelectedSchoolYearId,
    defaultSchoolYearId,
    schoolYears,
    handleClassChange,
    isGoogleEnabled,
    isCanvasEnabled,
    register,
    handleSubmit,
    getValues,
    errors,
  } = props;


  const { t } = useTranslation();

  const breadcrumbItems = () => {
    const items: Array<IBreadcrumb> = [
      { name: t("Breadcrumb.Home"), url: RouteConstant.ROUTE_DASHBOARD },
      {
        name: t("Breadcrumb.ClassManagement"),
        url: RouteConstant.TeacherRoutes.Classes,
      },
      { name: t("Breadcrumb.ClassDetails"), url: "" },
    ];

    return items;
  };

  const handleSchoolYearChange = (e) => {
    setSelectedSchoolYearId(e.value);
  }

  const onSubmit = (data: ClassModel) => {
    classDetail.userId = props.userContext.userId;
    classDetail.name = data.name;
    classDetail.displayName = data.displayName;
    classDetail.startTime =
      data.startTime === undefined ? classDetail.startTime : data.startTime;
    classDetail.endTime =
      data.endTime === undefined ? classDetail.endTime : data.endTime;
    classDetail.colorCode =
      selectedColor === undefined ? classDetail.colorCode : selectedColor;
    classDetail.schoolAccountId = props.userContext?.schoolAccountId;
    classDetail.isGoogleEnabled = isGoogleEnabled;
    classDetail.googleId = props.userContext?.googleId;
    classDetail.canvasId = props.profile?.canvasId;
    classDetail.googleAccessToken = auth.getGoogleAccessToken();
    classDetail.isCanvasEnabled = isCanvasEnabled;
    classDetail.schoolCanvasAccountId = props.profile?.schoolCanvasAccountId;
    classDetail.baseEndPointUrl = auth.getCanvasAccountSettings()?.endPointURL;
    classDetail.canvasAccessToken = auth.getCanvasAccessToken();
    classDetail.schoolYearId = selectedSchoolYearId ?? defaultSchoolYearId;

    setClassDetail(classDetail);

    if (mode === PageAccessMode.Add) {
      addNewClass(classDetail);
    } else {
      updateClass();
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="lg:flex lg:items-center px-4 py-3 lg:px-8 border-b">
        <Breadcrumb items={breadcrumbItems()} />
        <div className="lg:w-[55%] text-lg font-medium flex gap-2 h-auto sm:h-10 items-center">
          <h1 className="text-primary-violet font-medium flex justify-start mb-0 pb-0">
            Class Details
          </h1>
        </div>
      </div>

      <div className="flex">
        <div className={`w-2.5 inline-block m-2 rounded-md shadow-md border bg-[${selectedColor === '#fff' ? 'gray' : selectedColor}]`}>
          {" "}
        </div>
        <div className="px-8 py-5 relative">
          <div className="w-full">
            <div className="border-gray-200 pr-3 mt-4 lg:mt-0">
              <div>
                <div className="grid grid-cols-1 sm:grid-cols-6 xl:grid-cols-12 gap-6 ">
                  {(mode === PageAccessMode.Edit ||
                    mode === PageAccessMode.Readonly) && (
                    <div className="col-span-4 sm:col-span-2 xl:col-span-3">
                      <label className="block text-sm text-gray-500">
                        Class
                      </label>
                      <select
                        title="classes"
                        id="classList"
                        name="classList"
                        value={classId}
                        className="mt-1 block w-full py-2 px-3 pr-7 border border-gray-300 bg-white rounded-md shadow-sm focus:ring-primary-violet focus:border-primary-violet focus:border-gray-500 sm:text-sm"
                        onChange={handleClassChange}
                      >
                        {classes?.data?.map((item) => {
                          return (
                            <option key={item.classId} value={item.classId}>
                              {item.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  )}
                  <div className="col-span-4 sm:col-span-2 xl:col-span-3">
                    <label className="block text-sm text-gray-500">
                      Class Name
                    </label>
                    <input
                      type="text"
                      id="name"
                      defaultValue={classDetail.name}
                      maxLength={40}
                      disabled={mode === PageAccessMode.Readonly}
                      className="mt-1 py-1.5 px-3 block w-full shadow-sm focus:ring-primary-violet focus:border-primary-violet border border-gray-300 rounded-md"
                      {...register("name", { required: true })}
                    />
                    <small className="text-red-400">
                      {errors.name && <span>Class name is required</span>}
                    </small>
                  </div>
                  <div className="col-span-4 sm:col-span-2 xl:col-span-3  whitespace-nowrap lg:whitespace-normal">
                    <label className="block text-sm text-gray-500">
                      Class Display Name
                    </label>
                    <input
                      type="text"
                      id="display-name"
                      disabled={mode === PageAccessMode.Readonly}
                      defaultValue={classDetail.displayName}
                      className="mt-1 py-1.5 px-3 block w-full shadow-sm focus:ring-primary-violet focus:border-primary-violet border border-gray-300 rounded-md"
                      {...register("displayName", { required: true })}
                    />
                    <small className="text-red-400">
                      {errors.displayName && (
                        <span>Class display name is required</span>
                      )}
                    </small>
                  </div>
                  <div className="col-span-4 sm:col-span-1 xl:col-span-3 ">
                    <label className="mb-1 block text-sm text-gray-500 whitespace-nowrap">
                      Class Color
                    </label>

                    <ColorPicker
                      hideInputField={true}
                      defaultValue={classDetail.colorCode}
                      disabled={mode === PageAccessMode.Readonly}
                      title="Class color code can be used to easily distinguish between classes on your assignments calendar on your homepage."
                      setSelectedColor={(c) => {
                        setSelectedColor(c);
                      }}
                      hideRefreshIcon
                    />
                  </div>
                </div>
              </div>
              <div>
                <div className="grid grid-cols-1 sm:grid-cols-12 gap-6 mt-5">
                  <div className="col-span-4 xl:col-span-3">
                    <label className="block text-sm text-gray-500">
                      Class Start Time
                    </label>
                    <input
                      id="startTime"
                      type="time"
                      className="mt-1 py-1.5 px-3 block w-full shadow-sm focus:ring-primary-violet focus:border-primary-violet border border-gray-300 rounded-md cursor-pointer"
                      defaultValue={classDetail.startTime}
                      disabled={mode === PageAccessMode.Readonly}
                      {...register("startTime", {
                        validate: {
                          requiredField: (value) => {
                            return value !== "" && value !== undefined;
                          },
                          timeCompare: (value) => {
                            if (
                              getValues("endTime") !== "" &&
                              getValues("endTime") !== undefined &&
                              value !== "" &&
                              value !== undefined
                            ) {
                              return getValues("endTime") > value;
                            } else return true;
                          },
                        },
                      })}
                    />
                    <small className="text-red-400">
                      {errors.startTime &&
                        errors.startTime.type === "requiredField" && (
                          <span>Start time is required.</span>
                        )}
                    </small>
                    <small className="text-red-400">
                      {errors.startTime &&
                        errors.startTime.type === "timeCompare" && (
                          <span>
                            Start time must be less than end time.
                          </span>
                        )}
                    </small>
                  </div>
                  <div className="col-span-4 xl:col-span-3">
                    <label className="block text-sm text-gray-500">
                      Class End Time
                    </label>
                    <input
                      type="time"
                      defaultValue={classDetail.endTime}
                      disabled={mode === PageAccessMode.Readonly}
                      className="mt-1 py-1.5 px-3 block w-full shadow-sm focus:ring-primary-violet focus:border-primary-violet border border-gray-300 rounded-md cursor-pointer"
                      {...register("endTime", {
                        validate: {
                          requiredField: (value) => {
                            return value !== "" && value !== undefined;
                          },
                        },
                      })}
                    />
                    <small className="text-red-400">
                      {errors.endTime &&
                        errors.endTime.type === "requiredField" && (
                          <span>End time is required.</span>
                        )}
                    </small>
                  </div>
                  <div className="col-span-4 xl:col-span-3">
                    <label className="block text-sm text-gray-500">
                      School Year
                    </label>
                    {classId !== undefined && classId === "new" && (
                      <Select
                        key="schoolyear"
                        id="schoolyear"
                        name="schoolyear"
                        value={schoolYears?.filter(
                          (x) =>
                            x.value === selectedSchoolYearId?.toString()
                        )}
                        onChange={handleSchoolYearChange}
                        options={schoolYears}
                      />
                    )}
                    {classId !== undefined && classId !== "new" && (
                      <label className="mt-1 cursor-not-allowed text-gray-400 py-1.5 px-3 block w-full shadow-sm focus:ring-primary-violet focus:border-primary-violet border border-gray-300 rounded-md ">
                        {
                          schoolYears?.filter(
                            (x) =>
                              x.value === selectedSchoolYearId?.toString()
                          )[0]?.label
                        }
                      </label>
                    )}
                  </div>
                  <div className="col-span-4 xl:col-span-3">
                    <label className="block text-sm text-gray-500">
                      Class Status
                    </label>
                    <span
                      className={classNames(
                        "flex-shrink-0 inline-block px-2 py-0.5 mt-1.5 text-xs font-medium  rounded-full",
                        classDetail.isArchived
                          ? "bg-yellow-100 text-yellow-800"
                          : "",
                        classDetail.isHidden &&
                          classDetail.isArchived === false
                          ? "bg-gray-200 text-gray-800"
                          : "",
                        classDetail.isActive &&
                          classDetail.isArchived === false &&
                          classDetail.isHidden === false
                          ? "bg-green-100 text-green-800"
                          : ""
                      )}
                    >
                      {classDetail.isArchived && <>Archived</>}
                      {classDetail.isHidden &&
                        classDetail.isArchived === false && <>Hidden</>}
                      {classDetail.isActive &&
                        classDetail.isArchived === false &&
                        classDetail.isHidden === false && <>Active</>}
                    </span>
                  </div>

                  {mode !== PageAccessMode.Readonly && (
                    <div className="col-span-12 flex items-end justify-end">
                      <button
                        type="submit"
                        className="bg-primary-violet border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-dark-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      >
                        Update
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  )
}

export default ClassDetail_MainForm

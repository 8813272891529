import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import BellRingerLanding from "./bellRingerLanding";
import { getUrlParams } from "../../../../utils/helper";
import Permissions from "../../../../utils/permissions";
import AllowedTo from "../../../shared/rbac";
import AccessDenied from "../../../shared/accessDenied";
import { getSubjectDetail } from "../../../../api/teacher/assessment";
import Loader from "../../../shared/loader";
import Breadcrumb from "../../teacher/shared/breadcrumb";
import { IBreadcrumb } from "../../../../model/common/breadcrumb";
import RouteConstant from "../../../../utils/constant/routeConstant";
import { toast } from "react-toastify";

function BellRinger(props) {
  const subjectId = getUrlParams()?.subjectId || undefined;
  const [subjectDeails, setSubjectDeails] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);

  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      if (!subjectId || parseInt(subjectId) === 0) {
        toast.error("Invalid Subject");
        return;
      }
      setLoading(true);
      const subDetails = await getSubjectDetail(subjectId);
      setLoading(false);
      if (subDetails?.data) setSubjectDeails(subDetails.data);
    })();
  }, []);

  const breadcrumbItems = () => {
    const items: Array<IBreadcrumb> = [
      { name: t("Breadcrumb.Home"), url: RouteConstant.ROUTE_DASHBOARD },
      {
        name: t("Breadcrumb.InstructionalResources"),
        url: "",
      },
      {
        name: t("Breadcrumb.Subjects"),
        url: RouteConstant.TeacherRoutes.InstructionalResourceSubjects,
      },
      {
        name: t("Breadcrumb.Resources"),
        url: RouteConstant.TeacherRoutes.InstructionalResources,
        stateObject: { label: subjectDeails?.name, value: subjectId },
      },
      { name: t("Breadcrumb.BellRinger"), url: "" },
    ];

    return items;
  };

  return (
    <AllowedTo
      perform={Permissions.bell_ringer_view}
      no={() => <AccessDenied />}
    >
      {loading && <Loader />}
      <div className="min-w-0 w-full">
        <div className="lg:flex lg:items-center px-4 py-3 lg:px-8 border-b">
          <Breadcrumb items={breadcrumbItems()} />
          <div className="lg:w-[55%] text-lg flex justify-between gap-2 h-auto sm:h-10 items-center">
            <h1 className="text-primary-violet font-medium flex justify-start mb-0 pb-0">
              {t("BellRinger.BellRinger")}
            </h1>
          </div>
        </div>

        <div className="px-2 sm:px-6 lg:px-8 py-6">
          <div className="w-full">
            <div className="mb-2 text-gray-700 font-medium">
              {subjectDeails?.name}
            </div>

            <div className="flex items-start justify-between space-x-6 w-full mb-6">
              <div
                className={`text-sm text-gray-700 font-light bg-[#00b1fb] bg-opacity-10 p-4 flex w-full rounded-md rounded-t-lg sm:rounded-lg overflow-hidden`}
              >
                <div className={`flex-shrink-0 current-fill text-[#00b1fb]`}>
                  <svg
                    className="h-5 w-5"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </div>

                <div className="pl-2">
                  <span className="font-semibold text-gray-700 pr-2 mb-1 inline-block">
                    {t("BellRinger.Instructions")}
                  </span>
                  <br />
                  <ul className=" list-disc	list-outside pl-3 space-y-1">
                    <li>{t("BellRinger.Instructions_Row1")}</li>
                    <li>{t("BellRinger.Instructions_Row2")}</li>
                  </ul>
                </div>
              </div>
            </div>

            <BellRingerLanding
              {...props}
              subjectId={subjectId}
              subjectName={subjectDeails?.name}
            />
          </div>
        </div>
      </div>
    </AllowedTo>
  );
}

export default connect((state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
})(BellRinger);

import React, { useEffect, useState } from "react";
import { XIcon } from "@heroicons/react/outline";
import PreviewPage from "./learnosityPreviewPage";
import Classification from "./classification";

export default function QuestionPreview(props: any) {
  const [showSpanish, setShowSpanish] = useState(props.spanishVersion ?? false);
  const showClassification = props.showClassification ?? true;

  useEffect(() => {}, []);
  return (
    <>
      {props.show ? (
        <>
          <div className="fixed inset-0 z-40 flex items-center bg-black bg-opacity-50 sm:items-center sm:justify-center">
            <div
              className={`w-full flex flex-col justify-between overflow-hidden h-auto  bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 max-h-80percent sm:max-w-7xl  ${
                props.isProjectorMode ? "" : ""
              }`}
              role="dialog"
            >
              <header>
                {/* Header */}
                <div className="px-4 py-4 bg-gray-50">
                  <div className="flex items-center">
                    <span className="text-lg font-medium text-gray-900 h-6 pr-4 overflow-hidden">
                      {props.questionIdPreview ??
                        (props.itemRef ? props.itemRef?.split("-")[0] : "")}
                    </span>
                    {!props.itemRef?.includes("teacher") &&
                      props.showSpanishVersion instanceof Function && (
                        <dl className="mb-0">
                          <label
                            htmlFor="toggleSpanish"
                            className="flex items-center cursor-pointer pl-1"
                          >
                            <div className="relative">
                              <input
                                id="toggleSpanish"
                                type="checkbox"
                                checked={showSpanish}
                                className="sr-only"
                                onChange={(e) => {
                                  setShowSpanish(e.target.checked);
                                  props.showSpanishVersion(
                                    e,
                                    props.questionIdPreview
                                  );
                                }}
                              />

                              <div
                                className={`${
                                  props.spanishVersion
                                    ? "bg-primary-violet"
                                    : "bg-gray-400"
                                } w-10 h-4  rounded-full shadow-inner`}
                              ></div>
                              <div
                                className={`${
                                  props.spanishVersion
                                    ? "bg-primary-violet toggle-btn"
                                    : ""
                                } absolute w-6 h-6 bg-white rounded-full shadow -left-1 -top-1 transition`}
                              ></div>
                            </div>
                            <div className="ml-3 text-gray-700 font-medium">
                              Spanish Version
                            </div>
                          </label>
                        </dl>
                      )}
                    <div className="ml-auto mr-4">
                      {props.itemRef && showClassification && (
                        <Classification
                          questionId={parseInt(
                            props.itemRef?.split("-")[0] ?? "0"
                          )}
                          standardId={props.standardId ?? 0}
                          questionTypeId={
                            props.itemRef?.indexOf("-teacher") !== -1 ? 2 : 1
                          }
                        ></Classification>
                      )}
                    </div>
                    <div className="h-7 flex items-center">
                      <button
                        type="button"
                        className="text-gray-400 hover:text-gray-500"
                        onClick={() => props.changeValue(false)}
                      >
                        <span className="sr-only">Close</span>
                        <XIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                </div>
              </header>
              <div className="w-full relative overflow-auto px-4 py-1">
                <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5 ml-8">
                  <PreviewPage
                    itemRef={props.itemRef}
                    activityRef={props.activityRef}
                    isProjectorMode={props.isProjectorMode}
                    standardId={props.standardId}
                    activityIdPreview={props.activityIdPreview}
                    subjectId={props.subjectId}
                  />
                </div>
              </div>
              <footer className="flex items-center justify-end px-4 py-4 sm:flex-row bg-gray-50 dark:bg-gray-800 mt-4">
                <div className="space-x-3 flex justify-end">
                  {props.replaceQuestionClick && (
                    <button
                      onClick={() =>
                        props.replaceQuestionClick(props.questionIdPreview)
                      }
                      className="bg-primary-violet border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-dark-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3"
                    >
                      Replace Question
                    </button>
                  )}
                  {props.previousQuestionClick &&
                    props.currentQuestionIndex !== 0 && (
                      <button
                        onClick={() => props.previousQuestionClick()}
                        className="bg-primary-violet border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-dark-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3"
                      >
                        Previous Question
                      </button>
                    )}
                  {props.addQuestionClick && (
                    <button
                      onClick={() =>
                        props.addQuestionClick(
                          props.questionIdPreview,
                          props.questionStandardId
                        )
                      }
                      className="bg-primary-violet border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-dark-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3"
                    >
                      {`${
                        props.isQuestionAdded
                          ? "Remove Question"
                          : "Add Question"
                      }`}
                    </button>
                  )}
                  {props.removeQuestionClick && (
                    <button
                      onClick={() =>
                        props.removeQuestionClick(props.questionIdPreview)
                      }
                      className="bg-primary-violet border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-dark-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3"
                    >
                      Remove Question
                    </button>
                  )}
                  {props.nextQuestionClick &&
                    props.currentQuestionIndex !== props.totalQuestions - 1 && (
                      <button
                        onClick={() => props.nextQuestionClick()}
                        className="bg-primary-violet border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-dark-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3"
                      >
                        Next Question
                      </button>
                    )}

                  {props.previousPassageClick && (
                    <button
                      onClick={() => props.previousPassageClick()}
                      className="bg-primary-violet border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-dark-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3"
                    >
                      Previous Passage
                    </button>
                  )}
                  {props.nextPassageClick && (
                    <button
                      onClick={() => props.nextPassageClick()}
                      className="bg-primary-violet border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-dark-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3"
                    >
                      Next Passage
                    </button>
                  )}
                </div>
              </footer>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
}

import { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { getSkillStandard } from "../../../../../api/student/skillPracticeApi";
import { ISkillStandard } from "../../../../../model/skillPractice/skillStandard";
import Loader from "../../../../shared/loader";
import RouteConstant from "../../../../../utils/constant/routeConstant";
import { useHistory } from "react-router-dom";
import ColumnHeader from "../../../../../model/gridView/columnHeader";
import DataRow from "../../../../../model/gridView/dataRow";
import ColumnData from "../../../../../model/gridView/columnData";
import GridView from "../../../../shared/gridView";
import { UserContextState } from "../../../../../redux/actions/userContextAction";
import Profile from "../../../../../model/users/profile";
import constant from "../../../../../utils/constant/constant";

interface SkillStandardProps {
  skillSubjectId: number;
  userContext?: UserContextState;
  profile?: Profile;
}

const SkillStandard = (props: SkillStandardProps) => {
  const { t } = useTranslation();
  const [skillStandards, setSkillStandards] = useState<Array<ISkillStandard>>(
    []
  );
  const [loading, showLoading] = useState<boolean>(false);
  const history = useHistory();

  useEffect(() => {
    if (props.skillSubjectId !== 0) {
      showLoading(true);
      getSkillStandard(props.skillSubjectId, 0, props.userContext?.gradeId ?? 0)
        .then((d) => {
          setSkillStandards(d.data.data);
          showLoading(false);
        })
        .catch(() => {
          showLoading(false);
        });
    }
  }, [props.skillSubjectId]);

  function selectStandard(standardId: number): void {
    history.push(
      RouteConstant.StudentRoute.SkillGameChoose.replace(
        ":standardId",
        standardId.toString()
      )
    );
  }

  function onRowClick(data: any) {
    const skillStandardId = data.currentTarget.getAttribute("data-id");
    if (skillStandardId !== null) {
      selectStandard(parseInt(skillStandardId));
    }
  }

  function getHeaders() {
    const columnHeaders: Array<ColumnHeader> = [
      new ColumnHeader(
        t("SkillPractice.Standard") + " " + t("SkillPractice.Name")
      ),
      new ColumnHeader(t("SkillPractice.Action")),
    ];

    return columnHeaders;
  }

  function getRows() {
    const rows: Array<DataRow> = [];

    skillStandards?.forEach((a) => {
      const columnData: Array<ColumnData> = [
        new ColumnData(
          props.profile?.plLanguageId === constant.Languages.Spanish
            ? a.spanishName ?? a.name
            : a.name,
          String
        ),
        new ColumnData(a.skillStandardId, Boolean),
      ];
      rows.push(new DataRow(a.skillStandardId.toString(), columnData));
    });

    return rows;
  }

  console.log(props.profile);
  return (
    <Fragment>
      <div className="relative">
        {loading && <Loader />}
        <GridView
          columnHeaders={getHeaders()}
          rows={getRows()}
          source={"SKILLPRACTICE"}
          onRowClick={onRowClick}
        ></GridView>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(SkillStandard);

import { Fragment } from "react";
import { LeftArrowIcon } from "../../../assets/icons";
import { containsAll } from "../../../utils/helper";
import permissions from "../../../utils/permissions";
import { userPermissions } from "../rbac";
import RouteConstant from "../../../utils/constant/routeConstant";

interface MenuProps {
  icon: any;
  menu: any;
  isActive: boolean;
  isExpandableMenu: boolean;
  expandMenu: boolean;
  toggleSidebarView: boolean;
  onClick: (data: any) => void;
}

const Menu = (props: MenuProps) => {
  const { isActive, isExpandableMenu, expandMenu, toggleSidebarView, menu } =
    props;

  const menuNameBasedOnPermissions = (nav) => {
    if (nav.url === RouteConstant.TeacherRoutes.assignmentCenter) {
      if (
        containsAll(userPermissions(), [
          permissions.assessments_view,
          permissions.assignments_view,
        ])
      ) {
        return "Assessment/ Assignment Center";
      } else if (userPermissions().includes(permissions.assignments_view)) {
        return "Assignment Center";
      } else {
        return "Assessment Center";
      }
    }
    return nav.menu;
  };

  return (
    <>
      <p
        data-tip={menu.menu}
        data-class="custom-tooltip"
        data-for={menu.id.toString()}
      >
        <button
          type="button"
          onClick={(e) => props.onClick(props.menu)}
          className={`flex  items-start mb-1 py-2 px-2 text-left text-sm transition-all  duration-500  cursor-pointer rounded-lg hover:bg-secondary-teal/50 hover:text-gray-700
        ${toggleSidebarView ? " w-full " : " ml-auto "} ${
            isActive
              ? " bg-secondary-teal text-white"
              : expandMenu
              ? "text-gray-600 bg-opacity-50 group"
              : "text-gray-600 bg-opacity-50 group"
          }`}
        >
          <span
            className={`${
              isActive || expandMenu
                ? "mt-[0.15rem]"
                : "opacity-90 group-hover:opacity-100 mt-[0.15rem]"
            }`}
          >
            {props.icon}
          </span>

          {toggleSidebarView && (
            <Fragment>
              <span className="flex-1 ml-2 text-sm font-medium">
                {menuNameBasedOnPermissions(menu)}
              </span>
              {isExpandableMenu && (
                <LeftArrowIcon
                  className={`w-4 transition-all duration-300 my-auto ${
                    expandMenu ? "transform rotate-90" : ""
                  }`}
                ></LeftArrowIcon>
              )}
            </Fragment>
          )}
        </button>
      </p>
    </>
  );
};

export default Menu;

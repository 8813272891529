import { AxiosResponse } from "axios";
import { AxiosHelper } from "../../environment/axiosConfig";
import APIConstant from "../../utils/constant/apiConstant";
import StudentProfile from "../../model/student/studentProfile";

export function promoteGrade(ids: number[], userId: number, gradeId: number) {
  return AxiosHelper.axiosInstance().post(
    APIConstant.STUDENTPROFILE_API.PromoteGrade,
    {
      ids: ids,
      userId: userId,
      gradeId: gradeId,
    }
  );
}

export function setIsLocked(
  studentIds: number[],
  isLocked: boolean,
  userId: number
): Promise<AxiosResponse<number>> {
  return AxiosHelper.axiosInstance().put<number>(
    APIConstant.MANAGEUSER_API.UpdateDisabled,
    {
      studentIds: studentIds,
      userId: userId,
      isDisabled: isLocked,
    }
  );
}

export function updateAlienAvatar(
  studentId: number,
  gradeId: number,
  avatar: string,
  isEnabledOnQuestions: boolean,
  isLiftOff: boolean
) {
  return AxiosHelper.axiosInstance().put(
    APIConstant.STUDENTPROFILE_API.UpdateAvatar,
    {
      studentId: studentId,
      gradeId: gradeId,
      avatar: avatar,
      isEnabledOnQuestions: isEnabledOnQuestions,
      isLiftOff: isLiftOff,
    }
  );
}

export function getAlienAvatar(
  studentId: number,
  gradeId: number,
  programMode: string
) {
  return AxiosHelper.axiosInstance().get(
    APIConstant.STUDENTPROFILE_API.GetAvatar,
    {
      params: {
        studentId: studentId,
        gradeId: gradeId,
        programMode: programMode,
      },
    }
  );
}

export function getStudentProfile(studentId: number, gradeId: number) {
  return AxiosHelper.axiosInstance().get<StudentProfile>(
    APIConstant.STUDENTPROFILE_API.GetProfile,
    {
      params: {
        studentId: studentId,
        gradeId: gradeId,
      },
    }
  );
}

export function CheckNWEAResultExists(userId) {
  return AxiosHelper.axiosInstance().get(
    APIConstant.NWEA_API.CheckNWEAResultExists,
    {
      params: {
        userId: userId,
      },
    }
  );
}

export function SyncNWEAResult(userId) {
  return AxiosHelper.axiosInstance().put(
    APIConstant.NWEA_API.CheckNWEAResultExists,
    {
      params: {
        userId: userId,
      },
    }
  );
}

export function UpdateNWEAResult(
  userId: number
): Promise<AxiosResponse<number>> {
  return AxiosHelper.axiosInstance().put<number>(
    APIConstant.NWEA_API.UpdateNWEAResult,
    {
      userId: userId,
    }
  );
}
export function updateFontSize(
  userId: number,
  fontSize: string
): Promise<AxiosResponse<number>> {
  return AxiosHelper.axiosInstance().put<number>(
    APIConstant.STUDENTPROFILE_API.UpdateFontSize,
    {
      userId: userId,
      fontSize: fontSize,
    }
  );
}

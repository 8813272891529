import { useState } from "react";
import { UserContextState } from "../../redux/actions/userContextAction";
import {
  getCssForDisabled,
  checkIfUpgradeRequired,
  checkIfUpgradeRequiredForStudent,
  cssForDisabledControl,
} from "./permissionHelper";
import UpGradeMessagePopup from "./upGradeMessagePopup";
import constant from "../../utils/constant/constant";
interface CustomButtonControlProps {
  controlType: string;
  controlText: string;
  userContext: UserContextState;
  isUpgradeRequired?: boolean;
  className: string;
  permissionName?: string;
  onClick: () => void;
  children: any;
  isRoleCheck?: boolean;
  title?: string;
}
function CustomButtonControl(props: CustomButtonControlProps & any) {
  const [showUpgradePopup, setShowUpgradePopup] = useState<boolean>(false);

  const getUpgradeFlag = () => {
    let upgradeRequired: boolean = false;
    if (props.isRoleCheck) {
      upgradeRequired = checkIfUpgradeRequiredForStudent(props.isUpgradeRequired);
    } else if (props.permissionName) {
      upgradeRequired = checkIfUpgradeRequired(props.permissionName);
    }
    return upgradeRequired;
  };

  const controlClassNames: string = props.className + (props.isDissableBySuscription ? cssForDisabledControl : getCssForDisabled(props.permissionName, ""));
  const isContentDissabled: boolean = props.isDissabledBySubscription || getUpgradeFlag();

  return (
    <>
      {showUpgradePopup && (
        <UpGradeMessagePopup
          togglePopup={setShowUpgradePopup}
          roleId={props.userContext?.roleId ?? 0}
          message={
            props.userContext?.roleId == constant.UserRoleId.Student
              ? constant.UPGRADE_MESSAGE_STUDENT_ACCOUNT
              : constant.UPGRADE_MESSAGE_SCHOOL_ACCOUNT
          }
        />
      )}
      {props.children && (
        <props.controlType
          onClick={() => {
            if (isContentDissabled) {
              setShowUpgradePopup(true);
            } else {
              props.onClick();
            }
          }}
          className={controlClassNames}
          title={props.title}
        >
          {props.children}
        </props.controlType>
      )}
      {!props.children && (
        <props.controlType
          onClick={() => {
            if (isContentDissabled) {
              setShowUpgradePopup(true);
            } else {
              props.onClick();
            }
          }}
          className={controlClassNames}
          title={props.title}
        >
          {props.controlText}
        </props.controlType>
      )}
    </>
  );
}
export default CustomButtonControl;

class APIConstant {
  public readonly APP_USER_ACTIVITY_STATUS = "app/userActivity/Status";
  public readonly CMS_DOMAIN_ALL = "cms/Domain";
  public readonly CMS_STANDARD = "cms/standard";
  public readonly CMS_CANNEDTEST_ALL = "cms/cannedtests";
  public readonly CMS_CONTENTAREA_ALL = "cms/ContentArea";
  public readonly APP_STANDARD_ALL = "app/Standard";
  public readonly APP_QUESTION_ALL = "app/question";
  public readonly APP_SUBJECTS_ALL = "app/Teacher/Subjects";
  public readonly APP_DISTRICT_ADMIN_SUBJECTS =
    "app/ContentArea/DistrictSubjects";
  public readonly APP_FEEDBACK = "app/feedback";
  public readonly APP_TEACHERSTUDENTS_ALL = "app/teacher/Students";
  public readonly APP_TAGS_ALL = "app/tags";
  public readonly APP_FILEUPLOAD_VIDEOMESSAGE = "/app/fileupload/videomessage";
  public readonly APP_ASSIGNMENT = "app/assignment";
  public readonly APP_ASSIGNMENT_BYID = "app/assignment/id";
  public readonly APP_GRADES_ALL = "app/grades";
  public readonly APP_Profile = "app/profile";
  public readonly APP_QUESTIONTYPES_FOR_TEACHER = "app/question/questiontypes";
  public readonly APP_QUESTIONS_QUESTIONSTATS = "app/question/questionStats";
  public readonly APP_QUESTIONS_QUESTIONCLASSIFICATION =
    "app/question/questionClassification";
  public readonly APP_QUESTIONS_QUESTIONTOTALPOINTS =
    "app/question/questionTotalPoints";
  public readonly APP_QUESTIONTYPES_BY_STANDARD =
    "app/question/questiontypesByStandards";
  public readonly APP_TEACHERQUESTION = "app/teacherquestion";
  public readonly APP_TEACHERQUESTION_SAVE = "app/teacherquestion/Save";
  public readonly APP_TEACHERQUESTION_DUPLICATE =
    "app/teacherquestion/Duplicate";
  public readonly APP_TEACHERPASSAGE = "app/teacherpassage";
  public readonly APP_TEACHERPASSAGEBYID = "app/teacherpassage/details";
  public readonly APP_TEACHERPASSAGEQUESTION = "app/teacherpassagequestion";
  public readonly APP_SCHOOLS_BYDISTRICT = "app/schools";
  public readonly APP_ADAPTIVE_DATA = "app/AdaptiveData";
  public readonly APP_QUESTION_EXPLANATION = "app/question/explanation";
  public readonly APP_QUESTION_EXPLANATIONS = "app/question/explanations";
  public readonly APP_QUESTION_RUBRIC =
    "app/question/RubricByStudentAssignmentActivityId";
  public readonly APP_SCHOOLS_STUDENTS = "app/schools/Students";
  public readonly APP_SCHOOLS_SUBJECTS = "app/schools/Subjects";
  public readonly APP_SCHOOLS_INFORMATION = "app/schools/information";
  public readonly APP_SCHOOLS_GETBYID = "app/schools/getById";
  public readonly APP_SCHOOLSUBSCRIPTION_GETBYSUBJECTS =
    "app/schools/subscription/status";
  public readonly APP_SCHOOL_YEARS = "app/schools/years";

  public readonly APP_TEACHERCLASSES_ALL = "app/teacher/classes";
  public readonly APP_TEACHERQUESTION_VALIDATE = "app/teacherquestion/validate";
  public readonly APP_CLASSES_BY_Teachers = "app/classes/teachers/all";

  public readonly APP_DISTRICT_SCHOOLS_INFORMATION = "app/districts/schools";
  public readonly APP_USER_ACTIVITY_GETREQUEST = "app/userActivity/Request";
  public readonly VIDEOS_API = {
    GetVideosBySubjectId: "app/video",
  };

  public readonly CMS_API = {
    QuestionTypes: "cms/QuestionType",
    SubjectDetail: "cms/Subject/Detail",
    PayloadSchema: "cms/QuestionType/PayloadSchema",
    PayloadTeacherSchema: "cms/QuestionType/payload-teacher-schema",
    Question: "cms/Question/{id}",
    Domain: "cms/Domain",
    Standard: "cms/Standard",
    SkillStandard: "cms/SkillStandard",
    CalculatorTypes: "cms/CalculatorType",
    Subjects: "cms/Subject",
    StandardGroupStandards: "cms/StandardGroupStandard",
    Users: "cms/users",
  };

  public readonly CLASSMANAGEMENT_API = {
    ClassList: "app/classes",
    AddClass: "app/classes",
    UpdateClass: "app/classes",
    DeleteClass: "app/classes",
    SetIsArchive: "app/classes/archive",
    SetIsHidden: "app/classes/hide",
    ClassDetail: "app/classes/",
    TeacherClasses: "app/classes/teacherClasses",
    ValidatePassword: "app/classes/validatePassword",
    JoinClass: "app/classes/joinClass",
    HasDiagnosticsAvailable: "app/classes/{classId}/has-diagnostics-available",

    ClassSubject: "app/classes/subjects",
    AvailableClassSubject: "app/classes/subjects/available",
    SubjectsbyUserId: "app/subject/usersubjects",
    InstructionalResourcesSubjectsbyUserId:
      "app/subject/instructionalResources",
    AddClassSubject: "app/classes/subjects",
    DeleteClassSubject: "app/classes/subjects",

    ClassTeacher: "app/classes/teachers",
    PendingClassTeacher: "app/classes/teachers/pending",
    AddClassTeacher: "app/classes/teachers",
    DeleteClassTeacher: "app/classes/teachers",
    UpdateClassTeacherSetPrimary: "app/classes/teachers",
    PrimaryClassTeacher: "app/classes/teachers/primary",

    GetClassStudent: "app/classes/students",
    GetClassStudentWithPassword: "app/classes/studentsWithPassword",
    AddClassStudent: "app/classes/students",
    DeleteClassStudent: "app/classes/students",
    UpdateClassStudent: "app/classes/students",
    PendingClassStudent: "app/classes/students/pending",
    IsStudentPresent: "app/classes/students/isStudentPresent",
    GetClassStudentsByClassIds: "app/classes/studentsByClassIds",
    GetClassSubjectsByClassIds: "app/classes/subjectsByClassIds",

    ClassByContentArea: "app/classes/contentAreaClasses",
    TransferStudent: "app/classes/students/transfer",
    GetEligibleImportStudents:
      "app/classes/{classId}/getEligibleImportStudents",
    CalculatorBySubjectId: "app/subject/calculator",
    GetClassStudentWithDiagnostics: "app/classes/students/diagnosticsStatus",
    GetStudentSettings: "app/studentsetting/students/settings",
    UpdateStudentLanguageSetting:
      "app/studentsetting/students/settings/language",
    UpdateStudentTTSSetting: "app/studentsetting/students/settings/tts",
    UpdateStudentKTo5SpanishSetting:
      "app/studentsetting/students/settings/kto5spanish",
    UpdateStudentTTSSettingByClassId:
      "app/studentsetting/students/settings/tts/class",
    UpdateStudentLanguageSettingByClassId:
      "app/studentsetting/students/settings/language/class",
    UpdateStudentKTo5SpanishSettingByClassId:
      "app/studentsetting/students/settings/kto5spanish/class",
  };

  public readonly Teacher_API = {
    GetSchoolTeachers: "/app/teacher/{schoolId}/teachers",
    GetChatSettings: "/app/teacher/chatSettings",
    UpdateChatSettings: "/app/teacher/chatSettings",
    UpdateAvailableTeachingTime: "/app/teacher/availableTeachingTime",
    GetTeacherSchools: "/app/teacher/schools",
    UpdateTeacherSchools: "/app/teacher/teacherSchool",
    GetTeacherGuid: "/app/teacher/usatpguid",
    GetClassTeacherByClassId: "/app/teacher/classteachers",
    GetClassStudentByClassId: "/app/student/classstudents",
    GetStudentActivitySearchReport: "/app/report/activitysearchreport",
    GetStudentActivitySearchReportExcel:
      "/app/report/activitysearchreport/excel",
    GetLiftoffActivitySearchReport: "/app/report/liftoff/activityreport",
    GetLiftoffActivitySearchReportExcel:
      "/app/report/liftoff/activityreport/excel",
    GetArchivedClasses: "/app/teacher/archived-classes",
    GetHiddenClasses: "/app/teacher/hidden-classes",
    GetTotalQuestionCount: "/app/teacherquestion/gettotalquestionCount",
    GetTeachersBySchoolAndSubject: "/app/teacher/schools-subjects",
    UpdateMarketingSurvey: "/app/teacher/MarketingSurvey",
    GetMarketingSurvey: "/app/teacher/MarketingSurvey",
  };

  public readonly SKILLPRACTICE_API = {
    CMS_SkillSubject_All: "cms/SkillSubject",
    CMS_SkillStandard_All: "cms/SkillStandard",
    CreateTest: "app/skillpractice/students/createSkillPracticeTest",
    TestDetails: "app/skillpractice/students/testDetails",
    SkillQuestion: "app/SkillPractice/SkillQuestion/",
    SaveStudentAnswer: "app/skillpractice/students/saveAnswer",
    SkillReport: "app/skillpractice/students/skillreport",
    SearchOpponent: "app/skillpractice/students/opponent",
    SessionSummary: "app/skillpractice/students/sessionSummary",
    SchoolRanking: "app/SkillPractice/Ranking",
    StudentRanking: "app/SkillPractice/StudentRanking",
  };

  public readonly MANAGEUSER_API = {
    GetAll: "app/User/all",
    Add: "app/User",
    Update: "app/User",
    Delete: "app/User",
    GetClassesBySchoolAccountId: "app/classes/SchoolClasses",
    AddUsers: "app/AddUsers",
    UpdateDisabled: "app/user/disabled",
    ReActivateUser: "app/user/reactivate",
    DuplicateUser: "app/user/duplicate",
    MergeUsers: "app/user/merge",
    UpdateUserRole: "app/user/userrole",
    MergeUserSearchStudent: "app/user/merge/search",
    UpdateLiftoffSetting: "app/user/updateliftoff",
    ImportUser: "app/userimport",
    ClassesBySubjectId: "app/classes/classesBySubjectId",
    GetUserDetail: "app/user/detail",
    GetLiftoffStudents: "app/User/liftoff",
  };

  public readonly GOOGLE_API = {
    GoogleUser_Add: "app/google/googleUser/add",
    GoogleUser_UnlinkGoogleUser: "app/google/googleUser/unlink-user",
    SubmitGoogleAssignmentScore: "app/google/student/submit-assignment-score",
    GradeGoogleAssignment: "app/google/teacher/grade-assignment",
    GetGoogleClassesToImport: "app/google/teacher/classes-to-import",
    ImportGoogleClasses: "app/google/teacher/import-classes",
    GetGoogleStudentsToImport: "app/google/teacher/students-to-import",
    SyncGoogleStudents: "app/google/teacher/sync-students",
    MapGoogleStudents: "app/google/teacher/map-students",
    AddGoogleCourse: "app/google/teacher/courses",
    AddGoogleAssignments: "app/google/teacher/assignments",
    RemoveGoogleAssignment: "app/google/teacher/assignments",
    ConnectToGoogle: "app/google/teacher/connect-to-course",
    Disconnect: "app/google/teacher/disconnect",
  };

  public readonly CANVAS_API = {
    CanvasUser_Add: "app/canvas/canvasUser/add",
    CanvasUser_UnlinkCanvasUser: "app/canvas/canvasUser/unlinkCanvasUser",
    CanvasUser_GetCanvasAccountSettingsByAccountLoginName:
      "app/canvas/canvasUser/canvasAccountSettingsByAccountLoginName",
    GetStudentPendingInvitations: "app/canvas/student/pendingInvitations",
    SubmitCanvasAssignmentScore: "app/canvas/student/submitAssignmentScore",
    CanvasUser_JoinAllClasses: "app/canvas/student/joinAllPendingClasses",
    GradeCanvasAssignment: "app/canvas/teacher/gradeAssignment",
    GetCanvasClassesToImport: "app/canvas/teacher/canvasClassesToImport",
    ImportCanvasClasses: "app/canvas/teacher/importCanvasClasses",
    GetCanvasUnSyncedStudents: "app/canvas/teacher/unSyncedStudents",
    GetCanvasUnSyncedSectionStudents:
      "app/canvas/teacher/unSyncedSectionStudents",
    SyncCanvasStudents: "app/canvas/teacher/syncCanvasStudents",
    MapCanvasStudents: "app/canvas/teacher/mapCanvasStudents",
    AddCanvasCourse: "app/canvas/teacher/addCanvasCourse",
    AddCanvasAssignments: "app/canvas/teacher/addAssignmentToCanvasCourse",
    RemoveCanvasAssignment: "app/canvas/teacher/removeCanvasAssignment",
    GetClassSections: "app/canvas/teacher/classSections",
    GetClassSectionStudents: "app/canvas/teacher/classSectionStudents",
    SyncClassSections: "app/canvas/teacher/syncClassSections",
    ConnectToCanvas: "app/canvas/teacher/connectToCanvas",
    ConnectToSection: "app/canvas/teacher/connectToSection",
    GetCanvasSections: "app/canvas/teacher/canvasSections",
    DisconnectClass: "app/canvas/teacher/disconnectClass",
    DisconnectSection: "app/canvas/teacher/disconnectSection",
  };

  public readonly STUDENTPROFILE_API = {
    PromoteGrade: "app/Student/PromoteStudents",
    UpdateAvatar: "app/student/update/avatar",
    GetAvatar: "app/student/avatar/detail",
    GetProfile: "app/student/profile",
    GetFontSize: "app/student/fontsize",
    UpdateFontSize: "app/student/fontsize",
  };

  public readonly ASSIGNMENT_API = {
    Assignment: "app/assignment",
    GetById: "app/assignment/id",
    GetStudents: "app/assignment/students",
    GetClasses: "app/assignment/classes",
    GetSubjects: "app/assignment/subjects",
    GetSharedassignmentSubjects: "app/sharedassignment/subjects",
    GetAuthors: "app/assignment/authors",
    UpdateAssignments: "app/assignments/update",
    DuplicateAssignment: "app/assignment/duplicate",
    AddStudents: "app/assignment/addstudents",
    GetConstructedResponse: "app/question/constructedResponse",
    UpdateActivitySettings: "app/assignment/updateActivitySettings",
    Note: "app/assignment/note",
    CreateRemediation: "app/assignment/remediation",
    GetRemediatedStudents: "app/assignment/remediatedStudents",
    GetActivityDetails: "app/assignment/activityDetails",
    GetClassesById: "app/assignment/classesById",
    GetActivityStandardsById: "app/assignment/activityStandards",
    GetByAssignmentActivityId: "app/assignment/activity",
    GetAssignmentStatus: "app/assignment/assignmentStatus",
    GetQuestionReferences: "app/assignments/questionreferences",
  };

  public readonly ASSESSMENT_API = {
    Assessment: "app/assessment",
    GetAll: "app/assessment",
    UpdateAssessments: "app/assessments/update",
    DeleteAssessments: "app/assessments/delete",
    GetById: "app/assessment/id",
    GetMyAssessmentCount: "app/assessment/myassessmentcount",
    GetStats: "app/assessments/stats",
    GetQuestions: "app/assessment/questions",
    GetQuestionSearch: "app/assessment/question-search",
    GetPassgeCategory: "cms/passage/categories",
    GetPassageQuestions: "app/assessment/passagequestions",
    GetPassages: "app/assessment/passages",
    GetPassageSearch: "app/assessment/passage-search",
    GetPassagesCategoriesByStandard:
      "app/question/passageCategoriesByStandards",
    GetSystemGeneratedQuestions: "app/systemGenerated/questions",
    GetFavoriteQuestions: "app/favorite/questions",
    UpdateFavoritequestion: "app/favoritequestions/update",
    AddTag: "app/assessments/addtag",
    RemoveTag: "app/assessments/removetag",
    DuplicateAssessment: "app/assessments/duplicate",
    GetQuestionReferences: "app/assessments/questionreferences",
    ConvertToDistrictAssessment: "app/assessments/convertToDistrict",
    GetByCode: "app/assessment/code",
    GetAttemptCount: "app/assignment/assessment-attempt-count",
    GetTag: "app/assessment/getTag",
    GetWithResults: "app/assessment/withResults",
    QuestionBreakdown: "app/assessment/questionBreakdown",
    GetReplacementQuestions: "app/assessment/replacementquestions",
    GetAssessmentByAssignmentActivityId: "app/assessment/assignmentActivityId",
    LockPremiumAssessments: "app/assessments/premium/lock",
    GetPremiumAssessments: "app/premium-assessments",
    GetPremiumAssessmentQuestionReferences:
      "app/premium-assessments/question-references",
    GetPremiumAssessmentById: "app/premium-assessments/get-by-id",
    GetAuthors: "app/assessment/authors",
  };

  public readonly THIRDPARTYINTEGRATION_API = {
    SyncTeacherClasses: "app/thirdPartyIntegration/teacher-classes",
    SyncTeacherClassEnrollment:
      "app/thirdPartyIntegration/teacher-class-enrollments",
  };

  public readonly APP_ASSIGNMENT_UPDATE = "app/assignmentupdate";

  public readonly ForgotPassword_API = {
    ValidateRecoveryCode: "/app/account/validate",
    UpdatePassword: "/app/account/updatePassword",
  };

  public readonly RegisterUser_API = {
    ValidateRecoveryCode: "/app/account/validate",
    RegisterUser: "/app/account/registerUser",
  };

  public readonly ALIEN_ARENA_API = {
    createAlienArena: "app/AlienArena",
    getAllSessions: "app/AlienArena/Sessions",
    getAllSessionsStudent: "app/AlienArena/StudentSession",
    getSessionById: "app/AlienArena/SessionById",
    deleteAlienArena: "app/AlienArena",
    copyAlienArenaSession: "app/AlienArena/CopySession",
    deleteAlienArenaSessionById: "app/AlienArena/DeleteBySessionId",
    getGames: "app/Game",
    getStandardsBySubject: "app/Standard/SubjectStandards",
  };

  public readonly UserTags_API = {
    Add: "app/usertags",
    Update: "app/usertags",
    Delete: "app/usertags",
    GetAll: "app/usertags",
    GetByUserTagId: "app/usertags/getByUserTagId",
    CheckUserTagExists: "app/usertags/checkUserTagExists",
  };

  public readonly SuperSheet_API = {
    GetAll: "app/supersheet/search",
    Preview: "app/supersheet/preview",
    DownloadAnswerKey: "app/supersheet/downloadanswerkey",
  };

  public readonly BubbleSheet_API = {
    Download: "app/bubblesheet/downloadpdf",
  };

  public readonly Profile_API = {
    GetAuthParams: "app/profile/auth",
    updateShortucts: "app/profile/shortcuts/update",
  };

  public readonly StudentLiftOffAssignment_API = {
    GetByUserId: "app/studentliftOffdiagnosticassignment/getbyuserid",
    Add: "app/studentliftOffdiagnosticassignment/create",
    Update: "app/studentliftOffdiagnosticassignment/update",
    Result: "app/studentliftOffdiagnosticassignment/result",
    Domains: "app/subject/liftoffheaders",
    Subjects: "app/subject/liftoffsubjects",
    LastSession: "app/studentliftOffdiagnosticassignment/lastsession",
    Standards: "app/liftOffdiagnostictest/standards",
  };

  public readonly StudentLiftOffMyGalaxy_API = {
    GetBySubjectId: "app/studentliftOffgalaxy/standard/getbysubjectid",
    CMS_MyGalaxySubject_All: "app/subject/liftoff/mygalaxy",
    Initialize: "app/studentliftOffgalaxy/standard/initialize",
    Update: "app/studentliftOffgalaxy/standard/update",
    UpdateGameScore: "app/studentliftOffgalaxy/standard/updategamescore",
    GetGameDetails: "app/studentliftOffgalaxy/standard/gameDetails",
    Complete: "app/studentliftOffgalaxy/standard/complete",
    Result: "app/studentliftOffgalaxy/standard/result",
    LastSession: "app/studentliftOffgalaxy/lastsession",
  };

  public readonly STUDENTASSIGNMENT_API = {
    StudentAssignments: "app/studentAssignments",
    StudentActivities: "app/studentAssignments/studentAssignmentActivities",
    StudentActivitiesStatus: "app/studentAssignments/activities/status",
    StudentAssignmentResult: "app/studentAssignments/result",
    StudentAssignmentsQuestionCount: "app/studentAssignments/questionCount",
    StudentAssignmentsNewAssignmentsCount:
      "app/studentAssignments/newAssignmentCount",
  };

  public readonly STUDENTASSIGNMENTACTIVITY_API = {
    Initialize: "app/studentAssignment/activity/initialize",
    Update: "app/studentAssignment/activity/update",
    Complete: "app/studentAssignment/activity/complete",
    StudentsReport: "app/studentAssignment/activity/students",
    StudentsTeacherFeedback: "app/studentAssignment/activity/teacherFeedback",
    LastSession: "app/studentAssignment/activity/lastsession",
    ValidateMaxAttempt: "app/studentAssignment/activity/validate-max-attempt",
    GetGameDetails: "app/studentAssignment/gameDetails",
    UpdateGameScore: "app/studentAssignment/updateGameScore",
    ValidateAssignment: "app/studentAssignment/validate",
    AbandonAssignmentActivity: "app/studentAssignment/abandon",
  };

  public readonly StudyPlan_API = {
    StudyPlanStandard: "app/studyplan/standards",
    StudyPlanSubjectStats: "app/studyplan/subject-stats",
    Initialize: "app/studyplan/initialize",
    StudyPlanSubject: "app/subject/studyplan",
    ContentAreaSubject: "app/contentArea/studyplan",
    Summary: "app/studyplan/summary",
    CompleteStudyPlan: "app/studyplan/complete",
    UpdateStudyPlan: "app/studyplan/update",
    UpdateGameScore: "app/studyplan/updateGameScore",
    GetGameDetails: "app/studyplan/gameDetails",
    ClassesBySubject: "app/classes/subjectClasses",
    Result: "app/studyplan/result",
    Domains: "app/studyplan/domains",
    FocusAreas: "app/studyplan/focus-areas",
    LastSession: "app/studyplan/lastsession",
    ActivityStatus: "app/studyplan/activityStatus",
    AbandonStudyPlan: "app/studyplan/abandon",
  };

  public readonly Student_SummativeTest_API = {
    SavedTest: "app/studentSummativeTest/saved-test",
    Initialize: "app/studentSummativeTest/initialize",
    LastSession: "app/studentSummativeTest/lastSession",
    Complete: "app/studentSummativeTest/complete",
    Result: "app/studentSummativeTest/result",
    Update: "app/studentSummativeTest/update",
    Abandon: "app/studentSummativeTest/abandon",
  };

  public readonly Student_DomainTest_API = {
    InProgress: "app/studentDomainTest/inprogress",
    Initialize: "app/studentDomainTest/initialize",
    LastSession: "app/studentDomainTest/lastSession",
    Complete: "app/studentDomainTest/complete",
    Result: "app/studentDomainTest/result",
    Update: "app/studentDomainTest/update",
    Abandon: "app/studentDomainTest/abandon",
  };

  public readonly StudentCannedTest_API = {
    InProgress: "app/studentCannedTest/inprogress",
    Initialize: "app/studentCannedTest/initialize",
    LastSession: "app/studentCannedTest/lastSession",
    Complete: "app/studentCannedTest/complete",
    Result: "app/studentCannedTest/result",
    Update: "app/studentCannedTest/update",
    CannedTestSubject: "app/subject/cannedTestSubjects",
    CannedTests: "app/studentCannedTests",
    Report: "app/studentCannedTest/report",
    Sections: "app/studentCannedTest/sections",
    Instructions: "app/studentCannedTest/instructions",
    TotalQuestionCount: "app/studentCannedTest/totalQuestionCount",
    Abandon: "app/studentCannedTest/abandon",
    Standards: "app/studentCannedTest/standards",
  };

  public readonly StudentVideoAssignment_API = {
    GetAssignments: "app/studentVideoAssignment",
    Initialize: "app/studentVideoAssignment/initialize",
    Complete: "app/studentVideoAssignment/complete",
    Result: "app/studentVideoAssignment/result",
    LastSession: "app/studentVideoAssignment/lastsession",
    LastWithFullScore: "app/studentVideoAssignment/video/last-full-score",
    VideoHasFullscore: "app/studentVideoAssignment/video/has-full-score",
  };

  public readonly Student_API = {
    StudentRewards: "app/student/rewards",
    Blaster: "app/student/blaster",
    StudentGames: "app/games/student",
    Teachers: "app/student/teachers",
    Classes: "app/student/classes",
    UpdateScore: "app/student/studentScore",
    AlienRankngCard: "app/student/alienRankingCards",
    AllAlienRankngCard: "app/student/allalienRankingCards",
    DownloadCertificate: "app/student/downloadCertificate",
    StudentSchoolGameScore: "app/student/StudentSchoolGameScore",
    StudentGalaxyGameScore: "app/student/StudentGalaxyGameScore",
    Time: "app/student/time",
    MyStatsBySubject: "app/myStats/statsBySubject",
    MyStatSummary: "app/myStats/summary",
    LinkedSchools: "app/student/linkedschool",
    GetStudentsByClassId: "app/student/getstudentsbyclassId",
    ArcadeGames: "app/arcadegame",
    TotalTokens: "app/student/token-transactions/total",
    AddTokenTransaction: "app/student/arcade-game-sessions",
    UpdateArcadeGameSession: "app/student/arcade-game-session/update",
    FormerClasses: "app/student/formerclasses",
    StudentSchoolArcadeGameScore: "app/student/school-arcade-game-score",
    StudentGalaxyArcadeGameScore: "app/student/galaxy-arcade-game-score",
    LiftoffStudents: "app/student/liftoff",
    UpdateScores: "app/student/studentScores",
  };

  public readonly BellRinger_API = {
    APP_GET_RANDOM_QUES: "app/Question/getRandomQuestionsByStandardId",
    APP_POST_BELL_RINGER: "app/bellRinger",
    APP_PUT_BELL_RINGER: "app/bellRinger",
    APP_DELETE_BELL_RINGER: "app/bellRinger/delete",
    APP_GET_BELL_RINGER: "app/bellRinger",
    APP_GET_BELL_RINGER_ALL: "app/bellRinger/getAll",
    APP_POST_FAV_QUESTION: "app/favoritequestions/update",
  };

  public readonly ClassActivity_API = {
    SubjectWorksheets: "app/subjectworksheets",
    PreviewWorksheet: "app/worksheet/preview",
  };

  public readonly Message_API = {
    RecentChat: "RecentChat",
    OnlineUsers: "/{:schoolId}/online-users/",
  };

  public readonly WIDGET_API = {
    GetQuickLinks: "app/widget/quickLinks",
    GetWeekelyAssignments: "app/widget/assignments",
    GetClassesOrder: "app/widget/classesOrder",
    SetClassesOrder: "app/widget/classesOrder",
  };
  public readonly REPORT_API = {
    GetSessionActivityReport: "app/Report/SessionActivity",
    GetSessionActivityDetailsReport: "app/Report/SessionActivity/details/excel",
    getSessionActivitySummaryReport: "app/Report/SessionActivity/summary/excel",
    GetStudentReportCard: "app/report/student-report-card",
  };
  public readonly GRADED_WORK = {
    getGradedWork: "app/studentAssignments/gradedwork",
    getAssignmentActivities: "app/studentAssignments/activities/gradedwork",
    updateTeacher: "app/studentAssignments/activities/gradedwork/update",
  };
  public readonly NWEA_API = {
    GetNWEASchoolGrades: "app/nwea/schoolgrade/get",
    MapNWEAStudents: "app/profile/updatemappednwea",
    GetUmmappedStudents: "app/profile/unmappednweastudents",
    GetMappedStudents: "app/profile/mappednweastudents",
    GetUmmappedStudentByProfileId: "app/profile/unmappednweastudentprofileId",
    UpdateNWEAId: "app/profile/updatenwea",
    CheckNWEAResultExists: "app/profile/checknwearesultexists",
    UpdateNWEAResult: "app/profile/updatenwearesult",
    GetNWEAStudentResults: "app/profile/nweastudentresults",
    SearchUmmappedStudents: "app/profile/searchunmappednweastudents",
    UpdateNWEADisabledSetting: "app/profile/updatenweadisabledSetting",
    DeleteNWEALiftOffDiagnosticAssignments:
      "app/studentnwealiftOffdiagnosticassignment/delete",
    GetTestResultMappingStatus: "app/nwea/gettestresultmappingStatus",
    Reset: "app/nwea/reset",
    GetUmmappedStudentsByFilter: "app/students/unmapped-nwea",
    GetMappedStudentsByFilter: "app/students/mapped-nwea",
    MapNWEAStudentsByFilter: "app/students/mapped-nwea/update",
    GetTestResultMappingStatusByFilter: "app/students/mapped-nwea/status",
    AddNWEATestResult: "app/students/mapped-nwea/add/results",
    GetNWEABatchStatus: "app/students/nwea/batch/status",
  };
  public readonly CHAT_API = {
    GET_STUDENT_BY_TEACHER_ID: "app/Teacher/SearchStudent",
    GET_TEACHER_STUDENT_BY_SCHOOL_ID: "app/Teacher/SearchTeacherAndStudent",
    GET_SCHOOLADMIN_BY_DISTRICT_ID: "app/Teacher/SearchSchoolAdmin",
    GET_TEACHER_BY_STUDENT_ID: "app/Student/SearchTeacher",
    GET_STUDENTIDS_BY_CLASS_ID: "app/Classes/studentIds",
    GET_CLASSES_BY_STUDENT_ID:
      "app/classes/{:studentId}/student/ClassInformation",
    GET_TEACHER_CHATSETTINGS: "/app/teacher/chatSettings",
  };

  public readonly Account_API = {
    GetAccountDetails: "app/account/detail",
    AddSubscriptionRequest: "app/account/subscription-request",
    GetSubscriptionType: "app/account/subscriptiontype",
    SendPasswordResetEmail: "app/account/send-password-reset-email",
    SendForgotPasswordEmail: "app/account/send-forgot-password-email",
    GetUserHasFullAccessSubscription:
      "app/account/subscription/user-has-full-access",
    AddTrialSubscriptionRequest:
      "app/account/create-trial-subscription-request",
    Connection: "app/account/connection"
  };

  public readonly STUDENTASSESSMENTACTIVITY_API = {
    Initialize: "app/studentAssessment/activity/initialize",
    Update: "app/studentAssessment/activity/update",
    Complete: "app/studentAssessment/activity/complete",
    LastSession: "app/studentAssessment/activity/lastsession",
  };

  public readonly ACTIVITYSESSIONS_API = {
    AddQuestion: "app/activitysession/question",
    AddTeacherQuestion: "app/activitysession/teacherquestion",
    UpdateTotalQuestionCount: "app/activitysession/totalQuestionCount",
    AttemptedQuestionCount: "app/activitysession/attemptedQuestionCount",
    IsQuestionAttempted: "app/activitysession/IsQuesionAttempted",
    SessionCounter: "app/activitysession/session-counter",
    GetRetryEligibility: "app/activitysession/retry-eligibility",
    InitializeRetry: "app/activitysession/initialize-retry",
    RetryRequested: "app/activitysession/retryrequested",
    RetryCompleted: "app/activitysession/retry-completed",
    UpdateDuration: "app/activitysession/update-duration",
    GetQuestionsBySessionId: "app/activitysession/questions-by-session-id",
    TeacherFeedback: "app/activitysession/teacher-feedback",
    TeacherFeedbacksBySessionId: "app/activitysession/teacher-feedbacks-by-session-id"
  };

  public readonly PROGRESSREPORT_API = {
    GetProgressReportByStandard: "app/report/progress/dotranklist-by-standard",
    GetProgressReportByStandardByStudentIds:
      "app/report/progress/dotranklist-by-standard-by-studentIds",
    GetProgressReportByTeacher: "app/report/progress/dotrankmatrix-by-teacher",
    GetProgressReportByStudent: "app/report/progress/dotrankmatrix-by-student",
    GetProgressReportBySchool: "app/report/progress/dotrankmatrix-by-school",
  };

  public readonly GRADEBOOK_API = {
    GetGradeBookDataByAssignments:
      "app/report/gradebook/assignmentmatrix-by-student",
    ExportGradeBookDataByAssignmentsExcel:
      "app/report/gradebook/assignmentmatrix-by-student/excel",
    GetGradeBookDataByClassResults:
      "app/report/gradebook/classresults-by-studentactivity",
    ExportGradeBookDataByClassResultsExcel:
      "app/report/gradebook/classresults-by-studentactivity/excel",
  };
  public readonly RESULTSREPORT_API = {
    GetAssessmentResultReportHeader: "app/results-reports/assessments/header",
    GetAssessmentResultReportByStudent:
      "app/results-reports/assessments/dotrankmatrix/by-student",
    GetAssessmentResultReportByStudentExcel:
      "app/results-reports/assessments/dotrankmatrix/by-student/excel",

    GetAssessmentResultReportItemAnalysis:
      "app/results-reports/assessments/item-analysis/by-student",

    GetAssignmentResultReportHeader: "app/results-reports/assignments/header",
    GetAssignmentResultReportByStudent:
      "app/results-reports/assignments/dotrankmatrix/by-student",
    GetAssignmentResultReportByStudentExcel:
      "app/results-reports/assignments/dotrankmatrix/by-student/excel",
    GetAssignmentResultReportItemAnalysis:
      "app/results-reports/assignments/item-analysis/by-student",
    GetAssessmentResultsReportBySchool:
      "app/results-reports/assessments/dotrankmatrix/by-school",
    GetAssignmentResultsReportBySchool:
      "app/results-reports/assignments/dotrankmatrix/by-school",
    GetResultsAssessmentStudentDetailsBySchool:
      "app/results-reports/assessments/student-details/by-school",
    GetResultsAssessmentStudentDetailsByClass:
      "app/results-reports/assessments/student-details/by-class",
    GetResultsAssignmentStudentDetailsByClass:
      "app/results-reports/assignments/student-details/by-class",
    GetResultsAssessmentStudentDetailsBySchoolExcel:
      "app/results-reports/assessments/student-details/by-school/excel",
    GetResultsAssessmentStudentDetailsByClassExcel:
      "app/results-reports/assessments/student-details/by-class/excel",
    GetResultsAssignmentStudentDetailsByClassExcel:
      "app/results-reports/assignments/student-details/by-class/excel",
    GetDotMatrixResultsBySchoolExcel:
      "app/results-reports/assignments/dotrankmatrix/by-school/excel",
    GetResultsAssessmentMasteryByClass: "app/results-reports/assessments/mastery/classes",
    GetResultsAssignmentMasteryByClass: "app/results-reports/assignments/mastery/classes",
    GetResultsAssessmentMasteryBySchool:
      "app/results-reports/assessments/mastery/schools",
    GetPremiumAssessmentResultReportHeader:
      "app/results-reports/assessments/premium/header",
    GetPremiumAssessmentResultsReportBySchool:
      "app/results-reports/assessments/premium/dotrankmatrix/by-school",
    GetResultsPremiumAssessmentStudentDetailsByClass:
      "app/results-reports/assessments/premium/student-details/by-class",
    GetResultsPremiumAssessmentStudentDetailsByClassExcel:
      "app/results-reports/assessments/premium/student-details/by-class/excel",
    GetPremiumAssessmentResultReportByStudent:
      "app/results-reports/assessments/premium/dotrankmatrix/by-student",
    GetPremiumAssessmentResultReportByStudentExcel:
      "app/results-reports/assessments/premium/dotrankmatrix/by-student/excel",
    GetPremiumAssessmentResultReportItemAnalysis:
      "app/results-reports/assessments/premium/item-analysis/by-student",
    GetStudentReportCard:
        "app/report/student-report-card",
    GetResultsPremiumAssessmentMasteryByClass:
      "app/results-reports/assessments/premium/mastery/classes",
    GetResultsPremiumAssessmentMasteryBySchool:
      "app/results-reports/assessments/premium/mastery/schools",
    GetResultsPremiumAssessmentStudentDetailsBySchool:
      "app/results-reports/assessments/premium/student-details/by-school",
    GetResultsPremiumAssessmentStudentDetailsBySchoolExcel:
      "app/results-reports/assessments/premium/student-details/by-school/excel",
    GetTeacherUsageReport: "app/report/teacher-usage",
    getTeacherUsageReportExcel: "app/report/teacher-usage/excel",
    GetAssessmentStudentAttemptsReport: "app/results-reports/assessments/student-attempts",
    GetAssessmentStudentAttemptsReportExcel: "app/results-reports/assessments/student-attempts/excel",
    GetAssignmentStudentAttemptsReport: "app/results-reports/assignments/student-attempts",
    GetAssignmentStudentAttemptsReportExcel: "app/results-reports/assignments/student-attempts/excel",
  };

  public readonly ASSESSMENTRESULTSREPORT_API = {
    GetAssessmentResultsByStudent: "app/report/assessment-results/by-student",
    ExportAssessmentResultsByStudentToCSV:
      "app/report/assessment-results/by-student/csv",
    ExportAssessmentResultsByStudentToExcel:
      "app/report/assessment-results/by-student/excel",
  };

  public readonly LIFTOFFDIAGNOSTICSREPORT_API = {
    GetLiftoffDiagnosticsReport: "app/report/liftoffdiagnosticsreport",
    GetLiftoffDiagnosticsReportExcel: "app/report/liftoff-diagnostics/excel",
  };

  public readonly LIFTOFFCLASSGALAXYSUMMARY_API = {
    GetLiftoffClassGalaxySummaryReport:
      "app/report/liftoffClassGalaxySummaryReport",
    GetLiftoffClassGalaxySummaryReportExcel:
      "app/report/liftoff-class-galaxy-summary/excel",
  };

  public readonly GALAXYSTARREPORT_API = {
    GetGALAXYSTARReport: "app/report/galaxystarreport",
    GetGALAXYSTARReportExport: "app/report/galaxystarreport/export",
  };

  public readonly LIFTOFFGROWTHREPORT_API = {
    GetLiftoffGrowthReportByStudentQuery: "app/report/liftoffgrowthreport",
    ExportLiftoffGrowthReportByStudentQuery:
      "app/report/liftoff/liftoffgrowthreport/excel",
  };
  public readonly LIFTOFF_GALAXYSTARREPORT_API = {
    GetGALAXYSTARReport: "app/report/liftoff/galaxystarreport",
    GetGALAXYSTARReportExcel: "app/report/liftoff/galaxystarreport/excel",
    GetGALAXYSTARReportCertificates:
      "app/report/liftoff/galaxystarreport/downloadCertificates",
  };

  public readonly DIAGNOSTICS_API = {
    GetDiagnosticsStrengthWeaknessReport:
      "app/report/diagnosticsStrengthWeaknessReport",
    GetDiagnosticsStrengthWeaknessReportExport:
      "app/report/diagnosticsStrengthWeaknessReport/export",
  };

  public readonly SUBJECT_API = {
    GetUserLiftOffSubjects: "app/subject/userliftoffsubjects",
    GetUserHasLiftOffSubjectAccess: "app/Subject/user-has-liftoff-subjects-access",
    GetUserDiagnosticSubjects: "app/subject/userdiagnosticsubjects",
    GetContentAreaBySubjectId: "app/subject/content-area",
  };

  public readonly LIFTOFFUSAGE_API = {
    GetLiftoffUsageReport: "app/report/liftoff/usagereport",
    GetLiftoffUsageReportExcel: "app/report/liftoff/usagereport/excel",
    GetLiftoffUsageStudentLevelReport:
      "app/report/liftoff/usagereport-studentlevelreport",
    GetLiftoffUsageStudentLevelReportExcel:
      "app/report/liftoff/usagereport-studentlevelreport/excel",
  };

  public readonly USAGEREPORT_API = {
    GetUsageReportBySubject: "app/report/usagereport",
    ExportUsageReportBySubject: "app/report/usagereport/export",
    ExportUsageReportBySchool: "app/report/usage-report/schools/export",
  };

  public readonly TTS_API = {
    GetAudioFromText: "app/text-to-speech",
    GetSpeechMarksFromText: "app/text-to-speech/marks",
    CreatePassageSpeech: "app/text-to-speech/passages",
    GetSubjectUsesSpeechMarks: "app/text-to-speech/subject-speech-marks",
  };

  public readonly TESTCOMPARISONREPORT_API = {
    GetAssessmentFilter: "app/report/testComparison/assessmentfilter",
    GetTestComparisonStudents: "app/report/testComparison/students",
    GetTestComparisonStudentsExport:
      "app/report/testComparison/students/export",
    GetTestComparisonHeader: "app/report/testComparison/header",
    GetTestComparisonStudentsDetails:
      "app/report/testComparison/students/details",
    GetTestComparisonStudentDetailsExport:
      "app/report/testComparison/studentdetails/export",
  };
  public readonly LIVEMONITORINGREPORT_API = {
    GetAssignmentFilter: "app/report/livemonitoring/assignmentfilter",
    GetClassFilter: "app/report/livemonitoring/classfilter",
    GetLiveMonitorReport: "app/report/livemonitoringreport/getstudents",
  };
  public readonly SampleStudentView_API = {
    GetSampleStudent: "app/teachersamplestudent",
    CreateSampleStudent: "app/teachersamplestudent/add",
  };

  public readonly DISTRICT_API = {
    Get: "app/districts",
  };
  public readonly CLASSROOM_GAME = {
    GetTeamNames: "app/classroom-games/team-names",
    CreateSession: "app/classroom-games/sessions",
    UpdateSession: "/app/classroom-games/sessions",
    GetSessions: "app/classroom-games/sessions",
    DeleteSessions: "app/classroom-games/sessions",
    EndedSessions: "app/classroom-games/sessions/ended",
    ResetSessions: "app/classroom-games/sessions/reset",
    AddSessionTeamNames: "app/classroom-games/sessions/teams",
    GetSessionTeamNames: "app/classroom-games/sessions/teams",
    GetSessionStandards: "app/classroom-games/sessions/standards",
    GetEligibleStandards: "app/classroom-games/standards",
    GetSessionItems: "app/classroom-games/session-items",
    UpdateTeamPoint: "app/classroom-games/sessions/{sessionId}/teams/{teamName}",
    SetItemAnswered: "app/classroom-games/sessions/{sessionId}/items/",
    GetMiniBreak: "app/classroom-games/mini-breaks/{miniBreakId}"
  };
}
export default new APIConstant();

import { XIcon } from "@heroicons/react/outline";
import { Fragment } from "react";
import { FailureIcon, SuccessIcon } from "../../../../assets/icons";
import { IAssignmentStatus } from "../../../../model/externalRoster/externalRosterType";

interface RosterAssignmentStatusProps {
  show: (show: boolean) => void;
  assignmentData: Array<IAssignmentStatus>;
}

function RosterAssignmentStatusPopup(props: RosterAssignmentStatusProps) {
  const { assignmentData } = props;
  return (
    <Fragment>
      <div className="fixed inset-0 z-10 flex items-center bg-black bg-opacity-50 sm:items-center sm:justify-center">
        <div
          className="w-full flex flex-col justify-between overflow-hidden h-auto max-h-80percent bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:max-w-6xl"
          role="dialog"
        >
          <header>
            {/* Header */}
            <div className="px-4 py-4 bg-gray-50">
              <div className="flex items-start justify-between space-x-3">
                <div className="space-y-1">
                  <div className="text-lg font-medium text-gray-900">
                    Assignment Status
                  </div>
                </div>
                <div className="h-7 flex items-center">
                  <button
                    type="button"
                    className="text-gray-400 hover:text-gray-500"
                    onClick={() => props.show(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
          </header>
          <div className="w-full relative overflow-auto px-4 py-1">
            <form>
              <div className="flex bg-gray-700 p-2 rounded-lg">
                <table className="table w-full">
                  <thead className="mb-5">
                    <tr className="">
                      <th
                        scope="col"
                        colSpan={3}
                        className="text-left p-1 text-sm font-normal"
                      >
                        <div className="flex border-b pb-1 border-gray-50">
                          <span className="text-white text-base font-semibold">
                            Status
                          </span>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {assignmentData.length > 0 &&
                      assignmentData.map((status, statusIdx) => (
                        <tr
                          key={statusIdx}
                          className={
                            " hover:bg-gray-300/20 transition ease-in-out duration-300"
                          }
                        >
                          <td>
                            {(status.isSuccess && <SuccessIcon />) || (
                              <FailureIcon />
                            )}
                          </td>
                          <td className="p-1 text-sm text-white">
                            <div>{status.className}</div>
                            {status.assignmentName}
                          </td>

                          <td className="p-1 text-sm text-white">
                            {status.message}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </form>
          </div>

          {/* <!-- Action Buttons -->  */}
          <footer className="flex items-center justify-end px-4 py-4 sm:flex-row bg-gray-50 dark:bg-gray-800 mt-4">
            <div className="space-x-3 flex justify-end">
              <button
                onClick={() => props.show(false)}
                className="align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 rounded-lg text-sm text-gray-600 border-gray-300 border dark:text-gray-400 focus:outline-none active:bg-transparent hover:border-gray-500 focus:border-gray-500 active:text-gray-500 focus:shadow-outline-gray w-full sm:w-auto"
              >
                Ok
              </button>
            </div>
          </footer>
        </div>
      </div>
    </Fragment>
  );
}

export default RosterAssignmentStatusPopup;

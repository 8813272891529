import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { CompleteSavedActivityGrid } from "./completeSavedActivityGrid";

const defaultSavedActivities = {
  saved_activities: [
    {
      title: "English I (CA CCSS) - Random Questions",
      disabled: 0,
      length: 10,
      progress: "60%",
      created: 1633050000,
      due_date: 1644551526,
      days_remaining: 0,
      continue_link:
        "https://student.dev.usatestprep.com/modules/questions/qq.php?testid=2820&strand=14500&element=117380&condition=random&totalQuestions=10&cb=3417158",
    },
    {
      title: "8th Grade Physical Science (CA NGSS) - Random Questions",
      disabled: 0,
      length: 10,
      progress: "10%",
      created: 1642730400,
      due_date: 1644551526,
      days_remaining: 10,
      continue_link:
        "https://student.dev.usatestprep.com/modules/questions/qq.php?testid=3261&strand=17179&element=135584&condition=random&totalQuestions=10&cb=4302128",
    },
  ],
};
const CompleteSavedActivity: React.FunctionComponent = () => {
  useState<boolean>(false);
  const { t } = useTranslation();
  const savedActivityData = defaultSavedActivities.saved_activities;
  const isIFrameEnabled = false;

  return (
    <>
      <div>
        {
          <div className="h-auto min-h-half rounded-bl-3xl bg-[#26234c] py-4 pt-0 pb-14">
            <div className="relative">
              {!isIFrameEnabled && (
                <h2 className="text-sm text-white font-small mb-4">
                  {t("Assignments.CompletedAssignmentNote")}
                </h2>
              )}
              <CompleteSavedActivityGrid
                savedActivityData={savedActivityData}
              />
            </div>
          </div>
        }
      </div>
    </>
  );
};

export default CompleteSavedActivity;

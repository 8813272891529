import { 
    getSpeechFromText,
    getSpeechMarks,
    getSubjectUsesSpeechMarks
} from "../../../../../api/textToSpeech/textToSpeechApi";
import constant from "../../../../../utils/constant/constant";
import {
  ISpeechWordHTMLElement,
  ISpeechMarks,
  speakerClass,
  speechConfig
} from "./textToSpeechConfig";
import { Buffer } from "buffer";
  
let lastCT = -1;
let lastSentenceIdx = 0;
let enableHighlight = false;
let itemSubjectId = 0;
let wordsMark: Array<ISpeechMarks> = [];
let timeoutId;
let selectedAudio;
let parentElement;
let originalInnerHTML;
let wordsToBeHightlighted: Array<string> = [];
let wordParentElements: Array<ISpeechWordHTMLElement> = [];
let lastWordIndex = 0;
let timerId;
const lastWordIndexByElement = new Map();
const sre = require("speech-rule-engine");

export const speak = async (text: string, languageId: number) => {
  try {
    if (text === "ELA") {
      text = "E L A";
    }
    let speakerMode = constant.SpeakerMode.Hold;
    if (localStorage.getItem(constant.TTS.SPEAKER_MODE_KEY)) {
      speakerMode = Number(localStorage.getItem(constant.TTS.SPEAKER_MODE_KEY));
    }
    if (speakerMode !== constant.SpeakerMode.Speak || selectedAudio) {
      return
    };
    const lang = languageId === constant.Languages.Spanish ? "es" : "en";
    const blob = await callSpeechFromPolly(text, lang);
    if (blob == null) return
    const blobURL = window.URL.createObjectURL(blob);
    if(!timeoutId) {
      timeoutId = window.setTimeout(() => {
        selectedAudio = new Audio(blobURL);
        selectedAudio.play();
        selectedAudio.onended = () => {
        window.clearTimeout(timeoutId);
          hold()
        }
      }, 500)
    }
  } catch (_error) {
    hold();
  }
}
  
export const readTextNote = async (
  text: string,
  languageId?: number,
  callback?: any
) => {
  try {
    const speakLanguage =
      languageId === constant.Languages.Spanish ? "es" : "en";
    const divElement = getHtmlElementFromText(text);
    const divText = divElement.innerText;
    const blob = await callSpeechFromPolly(divText, speakLanguage);
    if (blob == null) {
        if (callback) callback();
        return
    }
    const blobURL = window.URL.createObjectURL(blob);
    selectedAudio = new Audio(blobURL);
    selectedAudio.play();
    selectedAudio.onended = () => {
      hold();
      if (callback) callback();
    }
  } catch (_error) {
    hold();
    if (callback) callback();
  }
}

export const bindTextToSpeech = (learnosityApp: any, language?: string, subjectId?: number) => {
  resetHighlightValues();
  const item = learnosityApp.getCurrentItem();
  const questions = learnosityApp.getQuestions()
  const lang = language === "es" ? "es" : "en";

  const getElementsWithRetries = (selector: string, retriesLeft: number): Promise<NodeListOf<Element>> => {
    return new Promise((resolve, reject) => {
      const elements = document.querySelectorAll(selector) as NodeListOf<Element>;
      if (elements.length > 0) {
        resolve(elements);
      } else if (retriesLeft > 0) {
        console.info(`Retrying... Attempts left: ${retriesLeft}`);
        setTimeout(() => resolve(getElementsWithRetries(selector, retriesLeft - 1)), 1000);
      } else {
        reject('No elements found and retries are exhausted.');
      }
    });
  };

  getElementsWithRetries(".lrn_sharedpassage, .lrn-mcq-option, .lrn_question", 3)
    .then((lrnElements) => {

      removeSpeechButtons();

      // Process the elements here
      const elementsArray = Array.from(lrnElements);
      elementsArray.forEach(async (element, index) => {
        await processElement(element, index);
      });
      
      if (subjectId && subjectId > 0 && itemSubjectId === 0) {
        itemSubjectId = subjectId;
        verifyItemNeedsHighlight(subjectId);
      } 
      else {
        enableHighlight = false;
      }

    })
    .catch(() => {
      // No elements found after retries, just return
      return;
    });   
  
  const processElement = async (element: Element, index: number) => {
    const questionReference = getQuestionReference(element);
    const question = questionReference ? questions[questionReference]: null
    let entityReference = getElementEntityReference(element);
    if(item.features.length > 0) //Not all items have feature, such as regular MC questions
    {
      if (element.classList.contains("lrn_sharedpassage") && !item.features.some(f => f.feature_id === entityReference)) {
        entityReference = item.features[0].feature_id
      }
    }

    const result = await getTTSElementText(element as HTMLElement, entityReference, lang, item, question);
        if (!result || result === "") return;    

    const elementClassName = getElementClass(element);
    const speechButton = getAudioButtonElement(
      elementClassName,
      entityReference ?? questionReference,
      index
    );
    element.insertAdjacentElement("afterbegin", speechButton);
    const audioElement = getAudioElement(
      elementClassName,
      entityReference ?? questionReference,
      index
    );
    element.insertAdjacentElement("afterbegin", audioElement);

    const button = document.querySelector(
      "#btn" + elementClassName + index
    ) as HTMLButtonElement;

    const audio = document.querySelector(
      "#audio" + elementClassName + index
    ) as HTMLAudioElement;

    button.addEventListener("click", async (event) => {
      try {
        await sre.setupEngine({ ...speechConfig, locale: lang });
          await handleClickSpeechButton(
            event,
            lang,
            audio,
            result
          );
      } catch (_error) {
          resetAudio();
      }
    });
  };
};
  
export const getFullTextFromElementContent = async (text: string, lang: string) => {
  text = text || "";
  const fullText = [];
  const div = document.createElement("div");
  div.innerHTML = text;
  replaceSymbolsBeforeMathElement(div, lang);
  await getTextFromElement(div, fullText);
  const elementText = fullText.join(" ").replace(/\s+/g, " ").trim();
  return replaceMathSymbols(elementText, lang);
};

export const stopTextToSpeech = () => {
  hold();
}

const verifyItemNeedsHighlight = async (subjectId) => {
  await getSubjectUsesSpeechMarks(subjectId).then(
    (res)=> {
      enableHighlight= res.data;
  }).catch((_) => {});
}

const resetHighlightValues = () => {
  enableHighlight = false;
  itemSubjectId = 0;
}

const resetAudio = () => {
  updateSpeakerIcon();
  hold();
};
  
const getElementClass = (element: Element) => {
  if (element.classList.contains(speakerClass.option.learnosity))
    return speakerClass.option.app;
  else if (element.classList.contains(speakerClass.feature.learnosity))
    return speakerClass.feature.app;
  return speakerClass.question.app;
};
  
const getElementEntityReference = (element: Element) => {
  if (element?.classList?.contains(speakerClass.option.learnosity)) {
    return element?.getElementsByTagName("input")[0]?.value;
  } else if (element?.classList?.contains(speakerClass.feature.learnosity)) {
    return (
      element
        ?.parentElement
        ?.getAttribute("data-lrn-widget-container") ?? null
    );
  }
  return null;
};
  
const getQuestionReference = (element: any) => {
  return element
    ?.closest("div .lrn_widget")
    ?.id;
};
  
const removeSpeechButtons = () => {
  const speechDivs = document.querySelectorAll(
    ".div_speaker_question, .div_speaker_feature, .div_speaker_options"
  ) as NodeListOf<Element>;
    speechDivs?.forEach((div) => {
    div.remove();
  });
};
  
const getAudioButtonElement = (
  elementClassName: string,
  reference: string,
  buttonIndex: number
) => {
  const divElement = document.createElement("div");
  divElement.className = elementClassName;
  divElement.id = reference + "_button_" + buttonIndex;
  divElement.innerHTML =
      "<button type='button' id='btn" +
      elementClassName +
      buttonIndex +
      "'" +
      " aria-label='Text To Speech' class='lrn_btn custom_btn text-to-speech " +
      elementClassName +
      " test-speaker-off pos-middle text-to-speech-custom' " +
      "data-original-title='Text To Speech' " +
      "style='z-index:9999'>" +
      "</button>";
  return divElement;
};
  
const getAudioElement = (
  elementClassName: string,
  questionId: string,
  audioIndex: number
) => {
  const audioElement = document.createElement("div");
  audioElement.className = elementClassName;
  audioElement.id = questionId + "_audio_" + audioIndex;
  audioElement.innerHTML =
    "<audio type='audio' id='audio" +
    elementClassName +
    audioIndex +
    "'" +
    " aria-label='Text To Speech' class='custom_audio_tts" +
    elementClassName +
    " pos-middle' style='z-index:9999'  src=''></audio>";
  return audioElement;
};
  
const updateSpeakerIcon = (element: HTMLElement | null = null) => {
  const btnSpeakers = document.querySelectorAll(
    ".text-to-speech-custom"
  ) as NodeListOf<Element>;
  if (element) {
    btnSpeakers?.forEach((btn) => {
      if (btn.id !== element.id) {
        btn.classList.add("text-to-speech-disable");
        btn.classList.add("pointer-events-none");
    }});
    if (element.classList.contains("test-speaker-on")) {
      element.classList.remove("test-speaker-on");
      element.classList.add("test-speaker-off");
    } else {
      element.classList.remove("test-speaker-off");
      element.classList.add("test-speaker-on");
    }
  } else {
    btnSpeakers?.forEach((btn) => {
    btn.classList.remove("text-to-speech-disable");
    btn.classList.remove("pointer-events-none");
    if (btn.classList.contains("test-speaker-on"))
      btn.classList.remove("test-speaker-on");
    if (!btn.classList.contains("test-speaker-off"))
      btn.classList.add("test-speaker-off");
    });
  }
};
  
export const hold = () => {
  enableHighlight && unHighlight();
  parentElement = null;
  wordsMark = [];
  wordsToBeHightlighted = [];
  wordParentElements = [];
  lastWordIndex = 0;
  lastWordIndexByElement.clear();
  timerId = null;
  if(timeoutId){
    window.clearTimeout(timeoutId);
    timeoutId = null;
  }
  if (selectedAudio != null) {
    selectedAudio.pause();
    selectedAudio.currentTime = 0;
    selectedAudio = null;
  }
};

  
const getPageTextContent = (element: HTMLElement, pages: string[]) => {
  if (!element || !pages) return "";
  let index = -1;
  const span = element
    .closest("div .lrn_sharedpassage")
    ?.querySelector(".lrn-sharedpassage-index");
  if (span) {
    const number = span.textContent?.trim()?.split(" of ")[0];
    const order = number ? parseInt(number) : -1;
    index = order - 1;
  }
  return pages[index] ?? "";
};
  
const getTTSElementText = async (
  element: HTMLElement,
  entityReference: string | null,
  language: string,
  item: any,
  activeItemQuestion: any,
) => {
  const divParentElement = document.createElement("div");
  const textArr = [];
  let selectedOption;
  let question;

  if (element.className.indexOf(speakerClass.option.learnosity) > -1) {
    question = activeItemQuestion == null?  item?.questions[0]: activeItemQuestion;
    selectedOption = question?.options?.find(
      (option) => option.value === entityReference
    );
    const optionText = selectedOption?.assistive_label?.label
      ? selectedOption.assistive_label?.label
      : selectedOption?.label;

    divParentElement.innerHTML = optionText || "";
  } else if (element.className.indexOf(speakerClass.question.learnosity) > -1) {
    question = activeItemQuestion == null?  item?.questions[0]: activeItemQuestion;
    const questionText = question?.stimulus || "";
    divParentElement.innerHTML = questionText.trim();
  } else if (element.className.indexOf(speakerClass.feature.learnosity) > -1) {
    const feature = item?.features?.find(
      (feature) => feature?.feature_id === entityReference
    );
    const featureText = feature?.paginated_content
      ? getPageTextContent(element, feature?.pages)
      : feature?.content;
    divParentElement.innerHTML = featureText?.trim() || "";
  }

  replaceSymbolsBeforeMathElement(divParentElement, language);

  await getTextFromElement(divParentElement, textArr);
  const elementText = textArr.join(" ").replace(/\s+/g, " ").trim();
  return replaceMathSymbols(elementText, language);
};
  
const replaceSymbolsBeforeMathElement = (parentElement, language: string) => {
  const elementText = parentElement.innerHTML;
  const minusSymbol = language === "es" ? "menos" : "minus";
  const plusSymbol = language === "es" ? "más" : "plus";
  const innertHtml = elementText
    .replace(/- *<math/g, ` ${minusSymbol} <math`)
    .replace(/\+ *<math/g, `${plusSymbol} <math`);
  parentElement.innerHTML = innertHtml;
};
  
const replaceMathSymbols = (text: string, language: string) => {
  const minusSymbol = language === "es" ? "menos" : "minus";
  const plusSymbol = language === "es" ? "más" : "plus";
    return text
    .replace(/(?![>]-\s*\d+).-\s*(\d+)/g, ` ${minusSymbol} $1`)
    .replace(/(?![>]\+\s*\d+).\+\s*(\d+)/g, ` ${plusSymbol} $1`);
};
  
const isLatexContent = (text: string) => {
  if (!text) return false;
  const regex = /\\\(.+\\\)/g;
  const latexFound = text.match(regex);
  return latexFound && latexFound.length > 0;
}

const getLatextSpeechText = async (text: string) => {
  if (!text) return "";
  const sre = require("speech-rule-engine");
  await sre.engineReady();
  const regex = /\\\(.+\\\)/g;
  const latexFound = text.match(regex);
  if (parentElement && latexFound && latexFound.length > 0) {
    const initindex = latexFound[0].indexOf("(");
    const text = latexFound[0].substring(initindex+1, latexFound[0].length-2);
    const result = document.evaluate(
      `//*[contains(text(),'${text}')]/parent::*`,
      document, null,
      XPathResult.FIRST_ORDERED_NODE_TYPE,
      null
    )?.singleNodeValue;
    if (result) {
      const mathElement = (result as HTMLElement).getElementsByClassName("MathJax")[0];
      if (!mathElement || !mathElement?.getAttribute("data-mathml")) return "";
      return sre.toSpeech(mathElement?.getAttribute("data-mathml"));
    }
  }
  return "";
}

const getMathText = async (mathElement) => {
  await sre.engineReady();
  const mathTextElement = mathElement.innerHTML;
  const speakText = sre.toSpeech(mathTextElement);
  return speakText;
};
  
const getTextFromElement = async (element, arrayText: string[]) => {
  if (
    element.hasChildNodes() &&
    element.classList.contains(speakerClass.arialLabel.learnosity)
  ) {
    if (element.getElementsByClassName("sr-only")[0]?.innerText) {
      arrayText.push(
        element.getElementsByClassName("sr-only")[0]?.innerText?.trim()
      );
    }
    return;

  }
  if (element.nodeType === 3 && element?.data && isLatexContent(element.data)){
    const latextText = await getLatextSpeechText(element.data);
    arrayText.push(latextText);
    return;
  }
  if (
    element.nodeName.toLowerCase() === "p" &&
    !element.hasChildNodes() &&
    isLatexContent(element?.innerText || "")
  ) {
    const latextText = await getLatextSpeechText(element?.innerText);
    arrayText.push(latextText);
    return;
  }
  if (element.nodeType !== 3 &&
    typeof element.getAttribute === "function" &&
    element.getAttribute("aria-label") != null) {
    arrayText.push(element.getAttribute("aria-label").trim());
  }
  if (
    !element.hasChildNodes() ||
    ["img", "table", "math"].includes(element.nodeName.toLowerCase())
  ) {
    if (element.nodeType === 3) {
      if (element.data && element.data.trim().length > 0) {
        arrayText.push(element.data.trim());
      }
    } else {
      if (element.nodeName.toLowerCase() === "img") {
        return;
      } else if (element.nodeName.toLowerCase() === "table") {
        arrayText.push(element.innerText.replace(/\xA0/g, " ").trim());
        return;
      } else if (element.nodeName.toLowerCase() === "math") {
        const mathText = await getMathText(element);
        arrayText.push(`${mathText} .`);
      } else {
        if (element.innerText && element.innerText.trim().length > 0) {
          arrayText.push(element.innerText.trim());
        }
      }
    }
  } else {
    for (const child of element.childNodes) {
      await getTextFromElement(child, arrayText);
    }
  }
};

const callSpeechFromPolly = async (text: string, speakLanguage: string) => {
  text = text.substring(0, constant.TTS.MAX_LENGTH_STORAGE_TTS);
  return await getSpeechFromText(text, speakLanguage)
    .then((resp) => {
      if (!resp.data.byteLength) return;
      const buffer = Buffer.from(resp.data);
      return new Blob([buffer], { type: "audio/mp3" });
    })
    .catch(() => {
      return;
    });
};

const callSpeechMarksFromPolly = async (text: string, speakLanguage: string) => {
  text = text.substring(0, constant.TTS.MAX_LENGTH_STORAGE_TTS);
  return await getSpeechMarks(text, speakLanguage)
    .then((resp) => {
        return resp.data;
    })
    .catch(() => {
        return [];
    })
}

const findWord = (currentTime) => {
  if (!wordsMark) return
  if (lastCT > currentTime) {
    lastSentenceIdx = 0;
  }
  lastCT = currentTime;
  for ( let i=lastSentenceIdx; i<wordsMark.length; i++){
    const diff = wordsMark[i].time - currentTime;
    if (diff === 0) {
      lastSentenceIdx = i;
    } else if (diff > 0) {
      return null;
    } else if (i === wordsMark.length -1) {
      lastSentenceIdx = i;
      return wordsMark[wordsMark.length-1]
    } else {
      const diff2 = wordsMark[i+1].time - currentTime;
      lastSentenceIdx = i;
      if(diff2 > 0) {
        return wordsMark[i];
      } else continue;
    }
  }
}

const getWordIndex = (marks: ISpeechMarks): number => {
  const word = marks.value as never;
  let index = wordsToBeHightlighted.indexOf(word, lastWordIndex);
  if (index === lastWordIndex && lastWordIndex !== 0) return -1;
  else if (index > 0) lastWordIndex = index;
  return lastWordIndex;
}

const highlightBackground = (word: string): string =>
  `<span style="background-color:yellow;">${word}</span>`;

const unHighlight = () => {
  if(parentElement){
    parentElement.innerHTML = originalInnerHTML;
  }
}

const validInnerHTMLToHighlight = (): boolean => {
  if (!parentElement) return false;
  return !isLatexContent(parentElement.innerText)
    && !parentElement.innerHTML.includes(speakerClass.arialLabel.learnosity)
    && !parentElement.getElementsByTagName('math')[0]
}

/*
  We need to escape special characters for regex, otherwise characters such as '?' will cause an exception
  This was occuring in getValidWordIndex method below
 */
const escapeRegExp = (text) => {
  return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
};

const getValidWordIndex = (fullText: string, word: string, startIndex: number = 0): number => {
  let safeWord = escapeRegExp(word); //We need to escape special characters for regex, otherwise characters such as '?' will cause an exception
  let wordIndex = fullText.substring(startIndex).search(new RegExp(`\\b${safeWord}\\b`));
  if (wordIndex < 0) return wordIndex;
  wordIndex += startIndex;
  const openTagIndex = fullText.substring(0, wordIndex).lastIndexOf("<");
  const closeTagIndex = fullText.substring(0, wordIndex).lastIndexOf(">");

  const nextOpenTagIndex = fullText.indexOf("<", wordIndex+word.length);
  const nextCloseTagIndex = fullText.indexOf(">", wordIndex+word.length);
  
  if ((closeTagIndex-openTagIndex) < 0 && (nextCloseTagIndex-nextOpenTagIndex) < 0) {
      return getValidWordIndex(fullText, word, wordIndex+word.length)
  }
  return wordIndex;
}

const cleanUpWord = (word: string): string => {
  const cleanWord = word.trim()
    .replace(/[“”'"]$/g, '')
    .replace(/^[“”'"]/g, '')
    .replace(/[.,!^*;:{}~()[\]?]$/g, '')
    .replace(/^[.,!^*;:{}~()[\]?]/g, '');
  if (word.length === 1) {
    return ["/", "\\", "-"].includes(word) ? '' : word;
  }
  return cleanWord;
}

const getText = (element, words: Array<string>, wordParentElement: Array<ISpeechWordHTMLElement>) => {
  if (element.nodeName.toLowerCase() === "img") return;
  if (element.hasChildNodes()){
    for (var j = 0; j< element.childNodes.length; j++) {
        getText(element.childNodes[j], words, wordParentElement);
    }
  }
  if (element.nodeType !== 3) return;
  const letters = element.nodeValue.toString().replace(/\xA0/g, " ").split(" ")
  letters.forEach(word => {
    if (word.trim().length > 0) {
      word = cleanUpWord(word);
      let startIndex = lastWordIndexByElement.has(element.parentElement) 
        ? lastWordIndexByElement.get(element.parentElement) + 1
        : 0;
      let wordIndex = getValidWordIndex(element.parentElement.innerHTML, word, startIndex);
      if (wordIndex > 0)
        lastWordIndexByElement.set(element.parentElement, wordIndex)
        words.push(word);
        wordParentElement.push({
          elementTag: (element.parentElement as HTMLElement).tagName,
          innerHTML: element.parentElement.innerHTML,
          index: wordIndex
        });
    }  
  });
  return;
}

const getHTMLElement = (index: number) => {
  const element = wordParentElements[index];
  if (element.innerHTML === parentElement.innerHTML) return parentElement;
  const domElements = parentElement.getElementsByTagName(element.elementTag);
  if (!domElements) return;
  for (let domElement of domElements) {
    if (domElement.innerHTML === element.innerHTML) return domElement;
  }
}

const highlightWord = (
  marks: ISpeechMarks,
  speechWordElement: ISpeechWordHTMLElement,
  elementParent: HTMLElement
) => {
  const wordToBeWrapped = marks.value;
  if (elementParent && speechWordElement && speechWordElement.index >= 0) {
      const innerHTML = speechWordElement.innerHTML;
      elementParent.innerHTML =  innerHTML.substring(0, speechWordElement.index) 
        + highlightBackground(wordToBeWrapped)
        + innerHTML.substring(speechWordElement.index+wordToBeWrapped.length);
  }
}

const getParent = (element: HTMLElement) => {
  let parent;
  if (
    element.classList.contains(speakerClass.question.app) &&
    element.closest(`div .${speakerClass.question.learnosity}`)
  ){
    const container = element
      .closest(`div .${speakerClass.question.learnosity}`)
      ?.getElementsByClassName("lrn_stimulus_content")[0];
    parent = container?.firstElementChild ?? container;
  } else if (
    element.classList.contains(speakerClass.option.app) &&
    element.closest(`div .${speakerClass.option.learnosity}`)
  ) {
    parent = element
      .closest(`div .${speakerClass.option.learnosity}`)
      ?.getElementsByClassName("lrn_contentWrapper")[0];

  } else if (
    element.classList.contains(speakerClass.feature.app) &&
    element.closest(`div .${speakerClass.feature.learnosity}`)
  ){
    parent = element
      .closest(`div .${speakerClass.feature.learnosity}`)
      ?.getElementsByClassName("lrn_clearfix")[0];
  }
  parentElement = parent ? parent : null;
  originalInnerHTML = parent ? parent.innerHTML : null;
}

const getHtmlElementFromText = (text: string) => {
  var divElement = document.createElement("div");
  divElement.innerHTML = text;
  return divElement as HTMLElement;
}
  
const playItemAudio = async (
  audio: HTMLAudioElement,
  text: string,
  language: string
) => {
  const speakLanguage = language === "es" ? "es" : "en";
  if (text == null || text.length === 0) {
    resetAudio();
    return;
  }
  selectedAudio = audio;
  const blob = await callSpeechFromPolly(text, speakLanguage);
  if (blob == null) {
    resetAudio();
    return;
  }
  enableHighlight = enableHighlight && validInnerHTMLToHighlight();
  if (enableHighlight){
    const response = await callSpeechMarksFromPolly(text, speakLanguage);
    wordsMark = response || [];
  }
  const url = window.URL.createObjectURL(blob);
  audio.src = url;
  audio.pause();
  audio.load();
  if (selectedAudio == null) return;
  if (parentElement && enableHighlight)
    getText(parentElement, wordsToBeHightlighted, wordParentElements);
  const promiseResponse = audio.play();
  promiseResponse
    .then(() => {
      if (parentElement && enableHighlight) {
        let delayToCallNextExecution = 100;
        timerId = setTimeout(function startHighlightProcess () {
          highlightText();
          if (selectedAudio && selectedAudio.currentTime < selectedAudio.duration)
            timerId = setTimeout(startHighlightProcess, delayToCallNextExecution)
        }, delayToCallNextExecution)
      }
    })
    .catch(() => {
      if (timerId) clearTimeout(timerId);
      resetAudio();
    });
  audio.onended = () => {
    if (timerId) clearTimeout(timerId);
    resetAudio();
  };
};

const highlightText = () => {
  try {
    if (!selectedAudio || !parentElement) return;
    const words : Array<ISpeechMarks>  = [];
    const currentTime = selectedAudio.currentTime * 1000;
    highlight(currentTime, words);
  } catch (_error) {
    unHighlight();
  }
}

const restoreSpeechMarksWord = (marksValue: string): string => {
  return marksValue.replace(/&amp;/g, "&")
    .replace(/&quot;/g, "\"")
    .replace(/&apos;/g, "'")
    .replace(/&lt;/g, "<")
    .replace(/&gt;/g, ">");
}

const highlight = (currentTime: number, words: Array<ISpeechMarks>) => {
  const marks = findWord(currentTime);
  if (!enableHighlight || !marks) return;
  marks.value = restoreSpeechMarksWord(marks.value);
  if (words.includes(marks)) return;
  words.push(marks);
  const index = getWordIndex(marks);
  if (index < 0) return;
  unHighlight();
  if (wordsToBeHightlighted[index] !== marks.value) return;
  const elementParent = getHTMLElement(index);
  highlightWord(marks, wordParentElements[index], elementParent);
}
  
const handleClickSpeechButton = async (
  event: MouseEvent,
  language: string,
  audioElement: HTMLAudioElement,
  text: string
) => {
  if (!event?.target) return;
  const button = event.target as HTMLElement;
  if (button.classList.contains("test-speaker-on")) {
    resetAudio();
    return;
  }
  updateSpeakerIcon(button);
  getParent(button);

  await playItemAudio(audioElement, text, language);
};
  
import local from "./local.json";
import development from "./dev.json";
import qa from "./qa.json";
import production from "./production.json";
import sandbox from "./sandbox.json";
import { Config, GoogleClassroomConfig, CanvasConfig } from "./configuration";
import Environments from "../utils/constant/environment";
import { firebaseConfig } from "./firebaseConfiguration";
import { getEnvironment } from "../utils/environmentHelper";

const PROGRESSLEARNING: string = "progresslearning.com"
const hostname = window && window.location && window.location.hostname;
let configuration: Config = new Config();
let googleClassroomConfiguration: GoogleClassroomConfig =
  new GoogleClassroomConfig();
let canvasConfiguration: CanvasConfig = new CanvasConfig();
let firebaseConfiguration: firebaseConfig = new firebaseConfig();
let curEnvironment: string;
let reHost: RegExp = /^app(-[a-z\\-]+)?/i;
let reHostMatch = hostname.match(reHost);
let envSuffix = reHostMatch != null && reHostMatch[1] !== undefined ? reHostMatch[1] : "";

switch (getEnvironment()) {
  case Environments.Environment.LOCAL:
    configuration = Object.assign(new Config(), local.configuration);
    curEnvironment = Environments.Environment?.LOCAL;
    firebaseConfiguration = local.firebaseConfiguration;
    googleClassroomConfiguration = local.googleClassroomConfiguration;
    canvasConfiguration = local.canvasConfiguration;
    break;
  case Environments.Environment.DEV:
    configuration = Object.assign(new Config(), development.configuration);
    curEnvironment = Environments?.Environment?.DEV;
    firebaseConfiguration = development.firebaseConfiguration;
    googleClassroomConfiguration = development.googleClassroomConfiguration;
    canvasConfiguration = development.canvasConfiguration;
    break;
  case Environments.Environment.QA:
    configuration = Object.assign(new Config(), qa.configuration);
    curEnvironment = Environments?.Environment?.QA;
    firebaseConfiguration = qa.firebaseConfiguration;
    googleClassroomConfiguration = qa.googleClassroomConfiguration;
    canvasConfiguration = qa.canvasConfiguration;
    break;
  case Environments.Environment.SANDBOX:
    configuration = Object.assign(new Config(), sandbox.configuration);
    curEnvironment = Environments?.Environment?.SANDBOX;
    firebaseConfiguration = sandbox.firebaseConfiguration;
    googleClassroomConfiguration = sandbox.googleClassroomConfiguration;
    canvasConfiguration = sandbox.canvasConfiguration;
    break;
  case Environments.Environment.PRODUCTION:
    configuration = Object.assign(new Config(), production.configuration);
    curEnvironment = Environments?.Environment?.PRODUCTION;
    firebaseConfiguration = production.firebaseConfiguration;
    googleClassroomConfiguration = production.googleClassroomConfiguration;
    canvasConfiguration = production.canvasConfiguration;

    //User production config but with local base Urls for auth and api
    if(hostname === `app-localprod.${PROGRESSLEARNING}`)
    {
      configuration.AuthBaseUrl = local.configuration.AuthBaseUrl;
      configuration.ApiBaseUrl = local.configuration.ApiBaseUrl;
    }
    break;
  default:
    configuration = Object.assign(new Config(), development.configuration);
    curEnvironment = Environments?.Environment?.LOCAL;
    firebaseConfiguration = development.firebaseConfiguration;
    googleClassroomConfiguration = development.googleClassroomConfiguration;
    canvasConfiguration = development.canvasConfiguration;
}

configuration.ApiBaseUrl = configuration.ApiBaseUrl.replace("$env$", envSuffix);
configuration.AuthBaseUrl = configuration.AuthBaseUrl.replace("$env$", envSuffix);

export const Configuration = configuration;
export const CurrentEnvironment = curEnvironment;
export const FirebaseConfiguration = firebaseConfiguration;
export const GoogleClassroomConfiguration = googleClassroomConfiguration;
export const CanvasConfiguration = canvasConfiguration;

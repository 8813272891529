import { Fragment, useEffect, useState } from "react";
import { handleCanvasAuthentication } from "../../../utils/canvasHelper";
import { toastr } from "react-redux-toastr";
import { CanvasIcon } from "../../../assets/icons";
import { ICanvasAccountSettingDTO } from "../../../model/canvasClassroom/canvasAccountSettingDTO";
import { CanvasApi } from "../../../api/canvas/canvasApi";
import { toast } from "react-toastify";
import Loader from "../../shared/loader";

type MappingMode = "MAPPING" | "SIGNIN";
interface CanvasSignInModalProps {
  mappingMode: MappingMode;
  returnUrl: string;
  accountLoginName: string;
  showPopUp: (show: boolean) => void;
}
function CanvasSignInModal(props: CanvasSignInModalProps) {
  const [loading, setLoading] = useState<boolean>(false);

  function redirectToCanvasLogin(settings: ICanvasAccountSettingDTO) {
    handleCanvasAuthentication(
      settings,
      props.accountLoginName!,
      props.returnUrl
    );
  }

  function closeCanvasModal() {
    props.showPopUp(false);
  }

  function setToastr(settings: ICanvasAccountSettingDTO) {
    const toastrOptions = {
      timeOut: 0,
      showCloseButton: true,
      closeOnToastrClick: true,
      position: "top-center",
      onCloseButtonClick: () => closeCanvasModal(),
      component: (
        <Fragment>
          <div>
            <div>
              <div className="px-4 pb-4 pt-0">
                <div className="toastr-left-container"></div>
                <div className="toastr-middle-container">
                  <div className="flex space-x-2 items-start">
                    <div className="w-20 pt-0.5">
                      <CanvasIcon className="w-6" />
                    </div>
                    <div>
                      <div className="title text-left text-sm">
                        {props.mappingMode === "SIGNIN" &&
                          "Your account is linked with Canvas. In order to utilize Canvas LTI, you need to login with your Canvas account"}
                        {props.mappingMode === "MAPPING" &&
                          " Your account is not linked with Canvas. In order to utilize Canvas LTI, you need to map your account to your Canvas account"}
                      </div>
                      <div className=" mt-6 inline-block flex items-center justify-between">
                        {props.mappingMode === "SIGNIN" && (
                          <span
                            onClick={() => closeCanvasModal()}
                            data-dismiss="modal"
                            className="cursor-pointer text-sm text-blue-800 hover:text-blue-800 block pr-4 focus:outline-none pr-10"
                          >
                            Continue without Canvas
                          </span>
                        )}

                        <div
                          className="focus:outline-none"
                          onClick={() => redirectToCanvasLogin(settings)}
                        >
                          <div className=" inline-flex space-x-2 bg-[#E6252A]  text-white border border-[#E6252A]  pr-4">
                            <div className="abcRioButtonIcon p-2 bg-white">
                              <div className="abcRioButtonSvgImageWithFallback abcRioButtonIconImage abcRioButtonIconImage18">
                                <CanvasIcon className="w-5"></CanvasIcon>
                              </div>
                            </div>
                            <span className="abcRioButtonContents text-xs flex items-center">
                              <span id="not_signed_int8aitmh8ix7c ml-4">
                                Sign in with Canvas
                              </span>
                              <span id="connectedt8aitmh8ix7c"></span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      ),
    };

    toastr.message("Canvas", "", toastrOptions);
  }

  const getCanvasAccountSettings = () => {
    setLoading(true);
    CanvasApi.getCanvasAccountSettingsByAccountLoginName(
      props.accountLoginName!
    )
      .then((r) => {
        setLoading(false);
        if (r.data && r.data.endPointURL) {
          setToastr(r.data);
        } else {
          toast.error(
            "Your school has not configured canvas account settings yet please contact administrator."
          );
          closeCanvasModal();
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getCanvasAccountSettings();
  }, []);
  return <> {loading && <Loader />}</>;
}
export default CanvasSignInModal;

import { ISkillOpponent } from "./../../model/skillPractice/skillOpponent";
import { ISkillReport } from "./../../model/skillPractice/skillReport";
import { ISkillStandard } from "./../../model/skillPractice/skillStandard";
import { ISkillSubject } from "./../../model/skillPractice/skillSubject";
import { ITestDetails } from "./../../model/skillPractice/testDetails";
import { ISkillQuestion } from "./../../model/skillPractice/skillQuestion";
import { ISkillStudentAnswer } from "./../../model/skillPractice/skillStudentAnswer";
import { ISkillStudentAnswerResponse } from "./../../model/skillPractice/skillStudentAnswerResponse";
import { ISkillPracticeSessionSummary } from "./../../model/skillPractice/skillPracticeSessionSummary";
import { ISkillStudentRanking } from "./../../model/skillPractice/skillStudentRanking";
import APIConstant from "../../utils/constant/apiConstant";
import PagingResponse from "../../model/common/pagingResponse";
import { AxiosResponse } from "axios";
import { AxiosHelper } from "../../environment/axiosConfig";

export const getSkillSubject = (): Promise<
  AxiosResponse<PagingResponse<ISkillSubject>>
> => {
  return AxiosHelper.axiosInstance().get<PagingResponse<ISkillSubject>>(
    APIConstant.SKILLPRACTICE_API.CMS_SkillSubject_All,
    {
      params: {
        IsActive: true,
        IsApproved: true,
        OrderByColumn: "Name",
        SortDir: "Asc",
        PageNumber: 1,
        PageSize: 999,
      },
    }
  );
};

export const getSkillStandard = (
  skillSubjectId: number,
  contentAreaId: number,
  gradeId: number
): Promise<AxiosResponse<PagingResponse<ISkillStandard>>> => {
  return AxiosHelper.axiosInstance().get<PagingResponse<ISkillStandard>>(
    APIConstant.SKILLPRACTICE_API.CMS_SkillStandard_All,
    {
      params: {
        SkillSubjectId: skillSubjectId,
        IsActive: true,
        IsApproved: true,
        OrderByColumn: "Name",
        SortDir: "Asc",
        PageNumber: 1,
        GradeId: gradeId,
        PageSize: 999,
        contentAreaId: contentAreaId,
      },
    }
  );
};

export const getSkillReport = (
  skillStandardId: number,
  userId: number,
  schoolAccountId: number
): Promise<AxiosResponse<ISkillReport>> => {
  return AxiosHelper.axiosInstance().get<ISkillReport>(
    APIConstant.SKILLPRACTICE_API.SkillReport,
    {
      params: {
        skillStandardId: skillStandardId,
        userId: userId,
        schoolAccountId: schoolAccountId,
      },
    }
  );
};

export const createStudentAssignment = (
  skillStandardId: number,
  studentId: number,
  opponentId: number,
  opponentAssignmentId: number,
  dummyOpponentDetails: string,
  rocketId: number,
  opponentRocketId: number
): Promise<AxiosResponse<number>> => {
  return AxiosHelper.axiosInstance().post<number>(
    APIConstant.SKILLPRACTICE_API.CreateTest,
    {
      skillStandardId: skillStandardId,
      studentId: studentId,
      opponentId: opponentId,
      opponentAssignmentId: opponentAssignmentId,
      dummyOpponentDetails: dummyOpponentDetails,
      rocketId: rocketId,
      opponentRocketId: opponentRocketId,
    }
  );
};

export const getStudentAssignmentDetails = (
  skillStandardId: number,
  skillStudentAssignmentId: number
): Promise<AxiosResponse<ITestDetails>> => {
  return AxiosHelper.axiosInstance().get<ITestDetails>(
    APIConstant.SKILLPRACTICE_API.TestDetails,
    {
      params: {
        skillStandardId: skillStandardId,
        skillStudentAssignmentId: skillStudentAssignmentId,
      },
    }
  );
};

export const getSkillQuestion = (skillQuestionId: number) => {
  return AxiosHelper.axiosInstance().get<ISkillQuestion>(
    APIConstant.SKILLPRACTICE_API.SkillQuestion + skillQuestionId
  );
};

export const saveSkillStudentAnswer = (
  skillStudentAnswer: ISkillStudentAnswer
) => {
  return AxiosHelper.axiosInstance().post<ISkillStudentAnswerResponse>(
    APIConstant.SKILLPRACTICE_API.SaveStudentAnswer,
    skillStudentAnswer
  );
};

export const getOpponent = (
  studentId: number,
  gradeId: number,
  skillStandardId: number
): Promise<AxiosResponse<ISkillOpponent>> => {
  return AxiosHelper.axiosInstance().get<ISkillOpponent>(
    APIConstant.SKILLPRACTICE_API.SearchOpponent,
    {
      params: {
        studentId: studentId,
        gradeId: gradeId,
        skillStandardId: skillStandardId,
      },
    }
  );
};
export const getSessionSummary = (
  skillStandardId: number,
  skillStudentAssignmentId: number
): Promise<AxiosResponse<ISkillPracticeSessionSummary>> => {
  return AxiosHelper.axiosInstance().get<ISkillPracticeSessionSummary>(
    APIConstant.SKILLPRACTICE_API.SessionSummary,
    {
      params: {
        skillStandardId: skillStandardId,
        skillStudentAssignmentId: skillStudentAssignmentId,
      },
    }
  );
};
export const getSchoolRanking = (
  skillStandardId: number,
  schoolAccountId: number,
  studentId: number
): Promise<AxiosResponse<ISkillStudentRanking[]>> => {
  return AxiosHelper.axiosInstance().get<ISkillStudentRanking[]>(
    APIConstant.SKILLPRACTICE_API.SchoolRanking,
    {
      params: {
        skillStandardId: skillStandardId,
        schoolAccountId: schoolAccountId,
        studentId: studentId,
      },
    }
  );
};
export const getStudentRanking = (
  skillStandardId: number,
  studentId: number
): Promise<AxiosResponse<ISkillStudentRanking>> => {
  return AxiosHelper.axiosInstance().get<ISkillStudentRanking>(
    APIConstant.SKILLPRACTICE_API.StudentRanking,
    {
      params: {
        skillStandardId: skillStandardId,
        studentId: studentId,
      },
    }
  );
};

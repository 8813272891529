import { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { getAssignmentAssociatedClasses } from "../../../../../api/class/classStudent";
import { StudentAssignmentApi } from "../../../../../api/student/studentAssignmentApi";
import {
  AssignmentListViewIcon,
  CompleteSavedActivityIcon,
  ConstructedResponseIcon,
  DownArrowIcon,
  GeneratedTestIcon,
  LegendGroupAssignmentIcon,
  LegendQuestionsIcon,
  LegendVideoIcon,
  TakeAssignmentIcon,
} from "../../../../../assets/icons";
import AssignmentListResponse from "../../../../../model/student/shared/assignment/assignmentListResponse";
import AssignmentSearchModel from "../../../../../model/student/shared/assignment/assignmentSearchModel";
import Loader from "../../../../shared/loader";
import TakeAssignmentDialog from "../../shared/takeAssignmentDialog";
import AssignmentList from "./assignmentList";
import CompleteSavedActivity from "./completeSavedActivity";
import RouteConstant from "../../../../../utils/constant/routeConstant";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { redirect } from "../../../../../utils/routeRedirectHelper";

function Assignments(props: any) {
  const { t } = useTranslation();
  const enabled = false;
  const history = useHistory();
  const [isDDLOpened, setIsDDLOpened] = useState<boolean>(false);
  const [ddlName, setDDLName] = useState<string>("View By Class");
  const [btnChangeValue, setBtnChangeValue] = useState<number>(1);
  const [filterData, setFilterData] = useState<any[] | null>([
    {
      id: 1590703,
      name: "Canvas Non LTI Course",
      teacher: {
        salutation: "",
        first: "Joyce",
        middle: "",
        last: "Buhrmester",
        display_name: "Joyce Buhrmester",
      },
    },
    {
      id: 1927576,
      name: "Test Class - New Student UI",
      teacher: {
        salutation: "Dr.",
        first: "Felicia",
        middle: "",
        last: "Example",
        display_name: "Dr. Example",
      },
    },
  ]);
  const [assignments, setAssignments] = useState<Array<AssignmentListResponse>>(
    []
  );
  const [openAssignmentDialog, setOpenAssignmentDialog] = useState(false);
  const [showCompleteActivity, setShowCompleteActivity] = useState(false);
  const [loading, showLoading] = useState<boolean>(false);

  useEffect(() => {
    getAssignments();
  }, []);

  function getAssignments(classId?: number) {
    showLoading(true);
    const search: AssignmentSearchModel = {
      studentId: props.userContext?.userId ? props.userContext?.userId : 0,
      subjectId: 0,
      pageNumber: 1,
      pageSize: 99999,
      orderByColumn: "",
      sortDir: 0,
      classId: classId ? classId : undefined,
    };
    StudentAssignmentApi.getStudentAssignments(search).then((r) => {
      setAssignments(r.data.data);
      showLoading(false);
    });
  }
  const onBtnToggle: any = () => {
    setShowCompleteActivity(false);
  };
  const ddlBtnClick = () => {
    setIsDDLOpened(!isDDLOpened);
    getAssignmentAssociatedClasses(props.userContext?.userId).then((d) => {
      setFilterData(d.data);
    });
  };
  const onGridBtnClick: any = async (param) => {
    setBtnChangeValue(param);
  };

  const filterDataOnChange: any = (
    classId: number,
    className: string
  ): void => {
    if (classId === 0) {
      setDDLName("View By Class");
      setIsDDLOpened(false);
      getAssignments();
    } else {
      setDDLName(className);
      setIsDDLOpened(false);
      getAssignments(classId);
    }
  };
  useEffect(() => {
    window.addEventListener("click", function (e) {
      const ddl = document.getElementById("custom_ddl");

      if (ddl) {
        const ev: any = e.target;
        if (ev) {
          if (!ddl.contains(ev)) {
            setIsDDLOpened(false);
          }
        }
      }
    });
  }, []);

  const onAssignmentDialogCancelClick = () => {
    setOpenAssignmentDialog(false);
  };
  const onAssignmentDialogOkClick = (
    assignmentCode: any,
    subjectId: number
  ) => {
    if (assignmentCode) {
      redirect({
        urlTemplate: RouteConstant.StudentRoute.StudentAssessmentActivity,
        code: assignmentCode,
        subjectId: subjectId,
        multipleAttemptAllowed: false,
        history: history,
      });
    }
    setOpenAssignmentDialog(false);
  };

  return (
    <Fragment>
      {loading && <Loader />}
      <TakeAssignmentDialog
        open={openAssignmentDialog}
        onCancelClick={onAssignmentDialogCancelClick}
        onOkClick={onAssignmentDialogOkClick}
      ></TakeAssignmentDialog>

      <div className="min-w-0 w-full h-full bg-[#26234c]">
        {/* <div className="px-4 py-3 lg:px-8 border-b">
          <nav className="flex mt-2" aria-label="Breadcrumb"></nav>
          <div className="text-lg flex justify-between gap-2 h-auto sm:h-10 items-center">
            <h2 className="py-3 font-Didact relative text-lg tracking-wide lg:text-xl xl:text-4xl text-white hover:shadow-2xl text-left pl-8 items-center">
              Assignments
            </h2>
          </div>
        </div> */}
        <div className="px-4 py-4 lg:px-8">
          <div className="hidden flex flex-col lg:flex-row justify-between lg:mb-2 xl:mb-0 ">
            <div
              id="custom_ddl"
              className={`ddl bg-white hover:bg-opacity-80 flex justify-center mb-auto py-2 rounded-md w-44 relative md:mr-4 lg:mr-0 ${
                showCompleteActivity ? "hidden" : ""
              }`}
              onClick={() => ddlBtnClick()}
            >
              {btnChangeValue === 1 && (
                <button
                  type="button"
                  className="rounded-full flex items-center text-sm focus:outline-none"
                  aria-expanded="false"
                  aria-haspopup="true"
                >
                  <ul className="ml-3">
                    <li className="text-left font-medium text-gray-700 text-sm">
                      {ddlName || "View by Class"}
                      <DownArrowIcon className="inline h-5 w-5 ml-1" />
                    </li>
                  </ul>
                </button>
              )}
              {isDDLOpened && (
                <div
                  className="left-12 cursor-pointer transition-all origin-top-right absolute top-8 -left-0 mt-2 w-60 z-50 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="user-menu-button"
                  tabIndex={-1}
                >
                  {filterData && filterData.length > 0 && (
                    <div
                      className="px-4 py-2 hide-ddl-text block text-sm text-black-333 font-medium hover:bg-gray-100"
                      key={0}
                      onClick={() => filterDataOnChange(0, "All")}
                    >
                      {"All"}
                    </div>
                  )}

                  {filterData &&
                    filterData.length > 0 &&
                    filterData.map((ds) => {
                      return (
                        <div
                          className="px-4 py-2 hide-ddl-text block text-sm text-black-333 font-medium hover:bg-gray-100"
                          key={ds.id}
                          onClick={() =>
                            filterDataOnChange(ds.classId, ds.className)
                          }
                        >
                          {ds.className}
                        </div>
                      );
                    })}

                  {filterData && filterData.length === 0 && (
                    <div className="px-4 py-2 hide-ddl-text block text-sm text-black-333 font-medium hover:bg-gray-100">
                      {t("Assignments.NoRecordsFound")}
                    </div>
                  )}
                </div>
              )}
            </div>

            <div
              className={`${
                showCompleteActivity
                  ? "hidden"
                  : "order-first mb-3 lg:order-last lg:mb-0"
              }`}
            >
              <div className="h-auto py-3 px-4 w-auto lg:w-100 bg-black bg-opacity-20 rounded-md space-y-2 mt-4 md:mt-0">
                <ul className="block sm:flex items-center flex-wrap text-gray-500 text-sm space-x-0 space-y-2 sm:space-y-0 sm:space-x-4">
                  <li className="block flex items-center text-sm text-white">
                    <TakeAssignmentIcon className="mr-2 w-4 h-4 stroke-current text-white" />
                    Assessment
                  </li>
                  <li className="block flex items-center text-sm text-white">
                    <ConstructedResponseIcon className="mr-2 w-4 h-4 stroke-current text-white" />
                    Constructed Response
                  </li>
                  <li className="block flex items-center text-sm text-white">
                    <GeneratedTestIcon className="mr-2 w-4 h-4 stroke-current text-white" />
                    Generated Test
                  </li>
                </ul>
                <ul className="block sm:flex items-center flex-wrap text-gray-500 text-sm space-x-0 space-y-2 sm:space-y-0 sm:space-x-4">
                  <li className="block flex items-center text-sm text-white">
                    <LegendVideoIcon className="mr-2 w-4 h-4 stroke-current text-white" />
                    Video
                  </li>
                  <li className="block flex items-center text-sm text-white">
                    <LegendQuestionsIcon className="mr-2 w-4 h-4 fill-current text-white" />
                    Questions
                  </li>
                  <li className="block flex items-center text-sm text-white">
                    <LegendGroupAssignmentIcon className="mr-2 w-4 h-4 stroke-current text-white" />
                    Group Assignments
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="hidden flex flex-col md:flex-row items-end w-full ">
            <div
              onClick={() => onGridBtnClick(1)}
              className={`cursor-pointer rounded-md md:rounded-b-none md:rounded-t-lg  h-11 w-48 order-last md:order-first font-medium text-sm flex  md:inline-flex justify-between items-center px-4 md:mb-0 mb-3 ${
                btnChangeValue === 1 && "bg-white"
              }`}
            >
              <span className="mr-8 text-sm text-site-orange">View </span>
              {!enabled && (
                <button
                  onClick={() => onBtnToggle()}
                  type="button"
                  className="bg-site-orange text-white relative inline-flex flex-shrink-0 h-6 w-10 border-2 border-transparent text-opacity-60 rounded-full cursor-pointer text-lg transition-colors ease-in-out duration-200 focus:outline-none focus:ring-none "
                  role="switch"
                  aria-checked="false"
                >
                  <span
                    aria-hidden="true"
                    className="translate-x-0 pointer-events-none inline-block h-7 text-site-orange w-12 relative -left-1 bottom-1 rounded-full border-2 border-site-orange bg-white shadow transform ring-0 transition ease-in-out duration-200"
                  >
                    <AssignmentListViewIcon />
                  </span>
                </button>
              )}

              {enabled && (
                <button
                  onClick={() => onBtnToggle()}
                  type="button"
                  className="bg-site-orange text-white relative inline-flex flex-shrink-0 h-6 w-10 border-2 border-transparent text-opacity-60 rounded-full cursor-pointer text-lg transition-colors ease-in-out duration-200 focus:outline-none focus:ring-none "
                  role="switch"
                  aria-checked="false"
                >
                  <AssignmentListViewIcon />
                </button>
              )}
            </div>
            <div className="cursor-pointer flex   md:inline-flex order-first items-center sm:ml-4 mb-2">
              <div
                className={`order-last sm:order-second text-sm flex  sm:inline-flex justify-start md:justify-center items-center false ${
                  btnChangeValue === 2 && "bg-gray-700"
                }`}
              >
                <span className="relative hidden">
                  <CompleteSavedActivityIcon className="w-6 h-6 fill-current text-white" />
                </span>

                <a
                  href="#!"
                  onClick={() => setShowCompleteActivity(true)}
                  className={` ${
                    btnChangeValue === 2 ? "" : "text-white"
                  } hidden underline cursor-pointer pl-2 hover:no-underline hover:text-gray-100 text-sm`}
                >
                  Complete a Saved Activity
                </a>
              </div>
            </div>
            <div className="hidden cursor-pointer flex items-center ml-auto">
              <span className="cursor-pointer inline-flex items-center order-first text-gray-700 bg-white group hover:bg-opacity-80 float-right px-3 py-2.5 rounded-md mb-3 mt-2">
                {" "}
                <span className="relative">
                  <TakeAssignmentIcon className="hidden w-4 h-4 stroke-current text-gray-500 mr-2" />
                </span>
                <a
                  href="#!"
                  className="text-gray-700 text-sm  cursor-pointer hover:text-gray-700 "
                  onClick={() => setOpenAssignmentDialog(true)}
                >
                  Take an Assessment
                </a>
              </span>
            </div>
          </div>

          {showCompleteActivity ? (
            <CompleteSavedActivity />
          ) : (
            <AssignmentList
              assignmentData={assignments}
              userId={props.userContext.userId}
            />
          )}
        </div>
      </div>
    </Fragment>
  );
}

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(Assignments);

import React from "react";
import StudentView from "../screen/teacher/studentView/studentView";

const GalaxyStar = React.lazy(
  () => import("../screen/dashboard/widget/reportCards/galaxyStar/galaxyStar")
);
const RecentActivity = React.lazy(
  () =>
    import(
      "../screen/dashboard/widget/reportCards/recentActivity/recentActivity"
    )
);
const Performance = React.lazy(
  () => import("../screen/dashboard/widget/reportCards/performance/performance")
);
const SchoolUsage = React.lazy(
  () => import("../screen/dashboard/widget/reportCards/schoolUsage/schoolUsage")
);
const OverallStats = React.lazy(
  () => import("../screen/dashboard/widget/overallStats/overallStats")
);
const Trainings = React.lazy(
  () => import("../screen/dashboard/widget/training/trainings")
);
const TrainingDocuments = React.lazy(
  () => import("../screen/dashboard/widget/trainingDocuments/trainingDocuments")
);
const QuickLink = React.lazy(
  () => import("../screen/dashboard/widget/quickLink/quickLink")
);
const AssignmentDetails = React.lazy(
  () => import("../screen/dashboard/widget/assignmentDetails/assignmentDetails")
);
const GalaxyMessages = React.lazy(
  () => import("../screen/dashboard/widget/galaxyMessages/galaxyMessages")
);
const Dropdown = React.lazy(() => import("../screen/filterControls/dropdown"));
const Range = React.lazy(() => import("../screen/filterControls/range"));
const OverallStatsGraph = React.lazy(
  () => import("../screen/dashboard/widget/overallStatGraph/overallStatGraph")
);
const ViewAsStudent = React.lazy(
  () => import("../screen/dashboard/widget/viewAsStudent/viewAsStudent")
);
const DistrictPerformance = React.lazy(
  () =>
    import(
      "../screen/dashboard/widget/reportCards/districtPerformance/districtPerformance"
    )
);
const Impersonate = React.lazy(
  () => import("../screen/dashboard/widget/impersonate/impersonate")
);
const GroupWidget = React.lazy(
  () => import("../screen/dashboard/widget/groupWidget/groupWidget")
);
const DistrictTrainings = React.lazy(
  () => import("../screen/dashboard/widget/districtTraining/districtTrainings")
);
const MultiSelect = React.lazy(
  () => import("../screen/filterControls/multiselect")
);
const ClassManagement = React.lazy(
  () => import("../screen/dashboard/widget/classDetail/classDetail")
);
const AssignmentCalendar = React.lazy(
  () =>
    import("../screen/dashboard/widget/assignmentCalendar/assignmentCalendar")
);
const TrainingQuicklinks = React.lazy(
  () => 
    import ("../screen/dashboard/widget/trainingQuicklinks/trainingQuicklinks")
);
const MostActiveStudents = React.lazy(
  () =>
    import("../screen/dashboard/widget/mostActiveStudents/mostActiveStudents")
);
const TopSubjects = React.lazy(
  () => import("../screen/dashboard/widget/topSubjects/topSubjects")
);
const TopSchools = React.lazy(
  () => import("../screen/dashboard/widget/topSchools/topSchools")
);
const AssignmentResultKG = React.lazy(
  () => import("../screen/student/kindergarten/assignment/result/result")
);
const AssignmentResultEL = React.lazy(
  () => import("../screen/student/elementary/assignment/result/result")
);
const AssignmentResultMS = React.lazy(
  () => import("../screen/student/middleSchool/assignments/result/result")
);
const StudyPlanResultKG = React.lazy(
  () =>
    import("../screen/student/kindergarten/studyPlan/studyPlanResult/result")
);
const StudyPlanResultEL = React.lazy(
  () => import("../screen/student/elementary/studyplan/studyPlanResult/result")
);
const StudyPlanResultMS = React.lazy(
  () =>
    import(
      "../screen/student/middleSchool/studyPlan/studyPlanResult/studyPlanStandardResult"
    )
);
const CannedTestResultKG = React.lazy(
  () => import("../screen/student/kindergarten/cannedTests/cannedTestResult")
);
const CannedTestResultEL = React.lazy(
  () => import("../screen/student/elementary/cannedTests/cannedTestResult")
);
const CannedTestResultMS = React.lazy(
  () => import("../screen/student/middleSchool/cannedTests/cannedTestResult")
);
const VideoPracticeResultKG = React.lazy(
  () =>
    import(
      "../screen/student/kindergarten/studyPlan/studyPlanResult/videoPracticeResult"
    )
);
const VideoPracticeResultEL = React.lazy(
  () =>
    import(
      "../screen/student/elementary/studyplan/studyPlanResult/videoPracticeResult"
    )
);
const VideoPracticeResultMS = React.lazy(
  () =>
    import(
      "../screen/student/middleSchool/studyPlan/studyPlanResult/videoPracticeResult"
    )
);
const SummativeTestResult = React.lazy(
  () =>
    import(
      "../screen/student/middleSchool/studyPlan/studyPlanResult/summativeTestResult"
    )
);
const DomainTestResult = React.lazy(
  () =>
    import(
      "../screen/student/middleSchool/studyPlan/studyPlanResult/domainTestResult"
    )
);
const CannedTestResult = React.lazy(
  () =>
    import(
      "../screen/student/middleSchool/studyPlan/studyPlanResult/cannedTestResult"
    )
);
const TrainingCalendar = React.lazy(
  () => import("../screen/dashboard/widget/trainingCalendar")
);
const StandardEfficiencyReportKG = React.lazy(
  () => import("../screen/student/kindergarten/cannedTests/standardEfficiencyReport")
);
const StandardEfficiencyReportEL = React.lazy(
  () => import("../screen/student/elementary/cannedTests/standardEfficiencyReport")
);
const StandardEfficiencyReportMS = React.lazy(
  () => import("../screen/student/middleSchool/cannedTests/standardEfficiencyReport")
);

const ComponentRegistry: any = {
  GalaxyStar: GalaxyStar,
  RecentActivity: RecentActivity,
  Performance: Performance,
  OverallStats: OverallStats,
  Trainings: Trainings,
  TrainingDocuments: TrainingDocuments,
  QuickLink: QuickLink,
  AssignmentDetails: AssignmentDetails,
  GalaxyMessages: GalaxyMessages,
  Dropdown: Dropdown,
  SchoolUsage: SchoolUsage,
  Range: Range,
  OverallStatsGraph: OverallStatsGraph,
  ViewAsStudent: ViewAsStudent,
  DistrictPerformance: DistrictPerformance,
  Impersonate: Impersonate,
  GroupWidget: GroupWidget,
  DistrictTrainings: DistrictTrainings,
  MultiSelect: MultiSelect,
  ClassManagement: ClassManagement,
  AssignmentCalendar: AssignmentCalendar,
  TrainingQuicklinks: TrainingQuicklinks,
  TopSchools: TopSchools,
  TopSubjects: TopSubjects,
  MostActiveStudents: MostActiveStudents,
  AssignmentResultEL: AssignmentResultEL,
  AssignmentResultKG: AssignmentResultKG,
  AssignmentResultMS: AssignmentResultMS,
  StudyPlanResultKG: StudyPlanResultKG,
  StudyPlanResultEL: StudyPlanResultEL,
  StudyPlanResultMS: StudyPlanResultMS,
  CannedTestResultKG: CannedTestResultKG,
  CannedTestResultEL: CannedTestResultEL,
  CannedTestResultMS: CannedTestResultMS,
  VideoPracticeResultKG: VideoPracticeResultKG,
  VideoPracticeResultEL: VideoPracticeResultEL,
  VideoPracticeResultMS: VideoPracticeResultMS,
  SummativeTestResult: SummativeTestResult,
  DomainTestResult: DomainTestResult,
  CannedTestResult:CannedTestResult,
  TrainingCalendar:TrainingCalendar,
  StudentView: StudentView,
  StandardEfficiencyReportKG: StandardEfficiencyReportKG,
  StandardEfficiencyReportEL: StandardEfficiencyReportEL,
  StandardEfficiencyReportMS: StandardEfficiencyReportMS
};
export default ComponentRegistry;

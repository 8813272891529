import { CloseIcon } from "../../../../../assets/icons"
import scoreboard from "../../../../../assets/images/thinkorswim/scoreboard.jpg";
import AnglerfishButton from "./anglerfishButton"
import { ISessionTeam } from "../../../../../model/interface/classroomGame/thinkOrSwimSetup";
import { useHistory } from "react-router-dom";
import routeConstant from "../../../../../utils/constant/routeConstant";
import { Fragment, useState } from "react";
import InformationDialog from "../../../../shared/informationDialog";

interface ITOSGamePlayScoreboard {
  sessionTeams: Array<ISessionTeam>,
  handleAddSingleTeamPoints: (teamName: string, points: number) => void,
  onClose: () => void,
  onEndGame: () => void,
}

function TOSGamePlayScoreboard(props: ITOSGamePlayScoreboard) {
  const {
    sessionTeams,
    handleAddSingleTeamPoints,
    onClose,
    onEndGame,
  } = props;

  const history = useHistory();
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [dialogTitle, setDialogTitle] = useState<string>("");
  const [dialogMessage, setDialogMessage] = useState<string>("");
  const [isDialogBonus, setIsDialogBonus] = useState<boolean>(true);

  const bonusPointsValue = 25;
  const penaltyPointsValue = -25;

  const exitGame = () => {
    history.push({
      pathname: routeConstant.ClassroomGame.ThinkOrSwim
    });
  }

  const addBonus = (team: ISessionTeam) => {
    const messages = [
      `${team.name} has been awarded an additional ${bonusPointsValue} points! That's Sea-rrific!`,
      `${team.name} found some buried treasure and have been awarded an additional ${bonusPointsValue} points! Arrrrgh, matey!`,
      `Dive into the deep blue sea of success, because ${team.name} has just been awarded ${bonusPointsValue} points!`,
      `${team.name} have been awarded ${bonusPointsValue} shiny points that are as precious as pearls from the deep sea!`,
      `${team.name} swam in the ocean and found ${bonusPointsValue} points! Whale done!`,
      `${team.name} had the tides on their side and received ${bonusPointsValue} points! Swell done!`,
      `${team.name} had their daily dose of Vitamin Sea and received ${bonusPointsValue} points!`,
    ];

    const messageNumber = Math.floor(Math.random() * messages.length);

    setDialogMessage(messages[messageNumber]);
    setDialogTitle("Bonus points");
    setIsDialogBonus(true);

    handleAddSingleTeamPoints(team.name, bonusPointsValue);

    setShowDialog(true);
  }

  const addPenalty = (team: ISessionTeam) => {
    const messages = [
      `${team.name} has been penalized ${Math.abs(penaltyPointsValue)} points and drifted out to sea.`,
      `${team.name} has found themselves lost at sea and lost ${Math.abs(penaltyPointsValue)} points.`,
      `${team.name} was found not wearing their lifejacket and lost ${Math.abs(penaltyPointsValue)} points.`,
      `${team.name} was penalized ${Math.abs(penaltyPointsValue)} points. Oh, the hu-manatee!`,
      `${team.name} was penalized ${Math.abs(penaltyPointsValue)} points. Shorely they can recover!`,
    ];

    const messageNumber = Math.floor(Math.random() * messages.length);

    setDialogMessage(messages[messageNumber]);
    setDialogTitle("Penalty points");
    setIsDialogBonus(false);

    handleAddSingleTeamPoints(team.name, penaltyPointsValue);

    setShowDialog(true);
  }

  return (
    <Fragment>
    <InformationDialog 
      informationTitle={dialogTitle} 
      informationMessage={dialogMessage} 
      open={showDialog} 
      onOkClick={() => setShowDialog(false)} 
      isError={!isDialogBonus}
    />
    <div
      className="fixed inset-0 flex flex items-center bg-black bg-opacity-50 sm:items-center sm:justify-center z-[9999]"
    >
      <div className="flex flex-col justify-between overflow-hidden bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg">
        <header>
          <div className="px-4 py-2 bg-gray-50">
            <div className="flex justify-end space-x-3">
              <button
              title="close"
                type="button"
                className="inline-flex items-center justify-center w-6 h-6 text-gray-400 transition-colors duration-150 rounded dark:hover:text-gray-200 hover:text-gray-700"
                onClick={onClose}
              >
                <CloseIcon />
              </button>
            </div>
          </div>
        </header>
        <div 
          className="relative mx-3 mb-3 min-h-[442px] bg-[rgb(66,124,224)] p-4"
          style={{backgroundImage: `url(${scoreboard})`, backgroundSize: 'auto', backgroundRepeat: "no-repeat", backgroundPosition: "center"}}
        >
          <div className="h-[50px] w-full"></div>
          {sessionTeams.sort((a: ISessionTeam, b: ISessionTeam) => b.points - a.points).map((team: ISessionTeam, index: number) => (
            <div key={index} className="relative w-full my-3 grid grid-cols-12 gap-4 items-center text-sm text-white font-medium">
              <div className="col-span-4">
                {team.name}
              </div>
              <div className="col-span-3 text-right">
                {team.points} pts
              </div>
              <div className="col-span-5 justify-end flex flex-row gap-1">
                <button
                  type="button"
                  className="bg-galaxy-green border border-white focus:outline-none focus:ring-1 focus:ring-offset-1 focus:bg-green-600 hover:shadow-lg hover:bg-green-600 rounded-md shadow-sm py-1 px-2 text-white text-sm"
                  onClick={() => {addBonus(team)}}
                >
                  Add Bonus
                </button>
                <button
                  type="button"
                  className="bg-red-block border border-white focus:outline-none focus:ring-1 focus:ring-offset-1 focus:bg-yellow-600 hover:shadow-lg hover:bg-yellow-600 rounded-md shadow-sm p-1 px-2 text-white text-sm"
                  onClick={() => {addPenalty(team)}}
                >
                  Penalty
                </button>
              </div>
            </div>
          ))}
        </div>
        <div className="flex justify-center mb-4">
          <div className="w-[20rem]">
            <AnglerfishButton
              text="Continue"
              onClick={onClose}
            />
          </div>
        </div>
        <div className="flex justify-center gap-2 mb-4">
          <span
            className="cursor-pointer text-blue-500 underline hover:no-underline"
            onClick={exitGame}
          >
            Save Game And Continue Later
          </span>
          |
          <span
            className="cursor-pointer text-blue-500 underline hover:no-underline"
            onClick={onEndGame}
          >
            End Game
          </span>
        </div>
      </div>
    </div>
    </Fragment>
  )
}

export default TOSGamePlayScoreboard

import { AxiosResponse } from "axios";
import { AxiosHelper } from "../../environment/axiosConfig";
import { IStudentVideoAssignment } from "../../model/interface/studentVideoAssignment";
import { IInitalizeVideoQuestionResponse } from "../../model/student/studyplan/initializeVideoQuestionResponse";
import APIConstant from "../../utils/constant/apiConstant";

export function initializeVideoQuestion(
  userId: number,
  videoId: number,
  standardId: number,
  language: string,
  gradeId: number
): Promise<AxiosResponse<IInitalizeVideoQuestionResponse>> {
  return AxiosHelper.axiosInstance().post<IInitalizeVideoQuestionResponse>(
    APIConstant.StudentVideoAssignment_API.Initialize,
    {
      userId: userId,
      standardId: standardId,
      videoId: videoId,
      language: language,
      gradeId: gradeId,
    }
  );
}

export function completeVideoQuesion(
  userId: number,
  sessionId: string,
  activitySessionId: number
): Promise<AxiosResponse<number>> {
  return AxiosHelper.axiosInstance().post<number>(
    APIConstant.StudentVideoAssignment_API.Complete,
    {
      userId: userId,
      sessionId: sessionId,
      activitySessionId: activitySessionId,
    }
  );
}

export function getVideoAssignment(
  userId: number,
  subjectId: number,
  standardId: number
): Promise<AxiosResponse<Array<IStudentVideoAssignment>>> {
  return AxiosHelper.axiosInstance().get<Array<IStudentVideoAssignment>>(
    APIConstant.StudentVideoAssignment_API.GetAssignments,
    {
      params: {
        userId: userId,
        subjectId: subjectId,
        standardId: standardId,
      },
    }
  );
}

export function getVideoQuestionLastSession(
  userId: number,
  videoId: number,
  languageId: number
  
): Promise<AxiosResponse<IStudentVideoAssignment>> {
  return AxiosHelper.axiosInstance().get<IStudentVideoAssignment>(
    APIConstant.StudentVideoAssignment_API.LastSession,
    {
      params: {
        userId: userId,
        videoId: videoId,
        languageId: languageId,   
      },  
    }
  );
}

export function getLastWithFullScore(
  studentId: number,
  videoId: number,
  languageId: number
  
): Promise<AxiosResponse<IStudentVideoAssignment>> {
  return AxiosHelper.axiosInstance().get<IStudentVideoAssignment>(
    APIConstant.StudentVideoAssignment_API.LastWithFullScore,
    {
      params: {
        studentId,
        videoId,
        languageId
      },  
    }
  );
}

export function getVideoHasFullScore(
  studentId: number,
  videoId: number
  
): Promise<AxiosResponse<boolean>> {
  return AxiosHelper.axiosInstance().get<boolean>(
    APIConstant.StudentVideoAssignment_API.VideoHasFullscore,
    {
      params: {
        studentId,
        videoId
      },  
    }
  );
}

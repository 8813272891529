import APIConstant from "../../utils/constant/apiConstant";
import { AxiosHelper } from "../../environment/axiosConfig";
import { ISessionActivityReportSearch } from "../../model/interface/report/sessionActivityReportSearch";
import PagingResponse from "../../model/common/pagingResponse";
import { SessionActivityResponse } from "../../model/report/sessionActivityResponse";
import { getTimezoneOffset } from "../../utils/helper";

export const getSessionActivityReport = (
  sessionActivitySearch: ISessionActivityReportSearch
) => {
  return AxiosHelper.axiosInstance().get<
    PagingResponse<SessionActivityResponse>
  >(APIConstant.REPORT_API.GetSessionActivityReport, {
    params: {
      teacherId: sessionActivitySearch.teacherId,
      schoolAccountId: sessionActivitySearch.schoolAccountId,
      classIds: sessionActivitySearch.classIds,
      subjectIds: sessionActivitySearch.subjectIds,
      studentIds: sessionActivitySearch.studentIds,
      activityTypes: sessionActivitySearch.activityTypes,
      dateCompletedMin: sessionActivitySearch.dateCompletedMin,
      dateCompletedMax: sessionActivitySearch.dateCompletedMax,
      pageNumber: sessionActivitySearch.pageNumber,
      pageSize: sessionActivitySearch.pageSize,
      userId: sessionActivitySearch.userId,
      roleId: sessionActivitySearch.roleId,
      schoolId: sessionActivitySearch.schoolId,
      schoolYearId: sessionActivitySearch.schoolYearId,
    },
  });
};
export const getSessionActivityDetailsReport = (
  sessionActivitySearch: ISessionActivityReportSearch,
  filters: string
) => {
  return AxiosHelper.axiosInstance().get(
    APIConstant.REPORT_API.GetSessionActivityDetailsReport,
    {
      responseType: "blob",
      params: {
        teacherId: sessionActivitySearch.teacherId,
        schoolAccountId: sessionActivitySearch.schoolAccountId,
        classIds: sessionActivitySearch.classIds,
        subjectIds: sessionActivitySearch.subjectIds,
        studentIds: sessionActivitySearch.studentIds,
        activityTypes: sessionActivitySearch.activityTypes,
        dateCompletedMin: sessionActivitySearch.dateCompletedMin,
        dateCompletedMax: sessionActivitySearch.dateCompletedMax,
        pageNumber: sessionActivitySearch.pageNumber,
        pageSize: sessionActivitySearch.pageSize,
        userId: sessionActivitySearch.userId,
        roleId: sessionActivitySearch.roleId,
        schoolId: sessionActivitySearch.schoolId,
        filters: filters,
        timezoneOffset: getTimezoneOffset(),
        schoolYearId: sessionActivitySearch.schoolYearId,
      },
    }
  );
};
export const getSessionActivitySummaryReport = (
  sessionActivitySearch: ISessionActivityReportSearch,
  filters: string
) => {
  return AxiosHelper.axiosInstance().get(
    APIConstant.REPORT_API.getSessionActivitySummaryReport,
    {
      responseType: "blob",
      params: {
        teacherId: sessionActivitySearch.teacherId,
        schoolAccountId: sessionActivitySearch.schoolAccountId,
        classIds: sessionActivitySearch.classIds,
        subjectIds: sessionActivitySearch.subjectIds,
        studentIds: sessionActivitySearch.studentIds,
        activityTypes: sessionActivitySearch.activityTypes,
        dateCompletedMin: sessionActivitySearch.dateCompletedMin,
        dateCompletedMax: sessionActivitySearch.dateCompletedMax,
        pageNumber: sessionActivitySearch.pageNumber,
        pageSize: sessionActivitySearch.pageSize,
        userId: sessionActivitySearch.userId,
        roleId: sessionActivitySearch.roleId,
        schoolId: sessionActivitySearch.schoolId,
        filters: filters,
        timezoneOffset: getTimezoneOffset(),
        schoolYearId: sessionActivitySearch.schoolYearId,
      },
    }
  );
};

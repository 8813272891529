import { ArrowDownSmall, ArrowUpSmall } from '../../assets/icons';

interface ShowMoreButtonProps {
  toggleShowMore: boolean;
  onClick: () => void;
  maxItemsToShow: number;
  totalItems: number;
}

export default function ShowMoreButton({
    toggleShowMore,
    onClick,
    maxItemsToShow,
    totalItems,
  }: ShowMoreButtonProps) {
  if (totalItems <= maxItemsToShow) {
    return null;
  }

  return (
    <div style={{ textAlign: 'center' }} className="show-more">
      {toggleShowMore ? (
        <button
          style={{ fontSize: '15px', color: 'blue' }}
          onClick={onClick}
          className="flex items-center justify-center"
        >
          <ArrowUpSmall title="Hide" className="inline-block w-4 h-4 ml-6" />
          Hide
        </button>
      ) : (
        <button
          style={{ fontSize: '15px', color: 'blue' }}
          onClick={onClick}
          className="flex items-center justify-center"
        >
          <ArrowDownSmall title="Show More" className="inline-block w-4 h-4 ml-6" />
          Show More
        </button>
      )}
    </div>
  );
};

import { AxiosHelper } from "../../environment/axiosConfig";
import APIConstant from "../../utils/constant/apiConstant";

export const getNWEASettings = (userId: number) => {
  return AxiosHelper.axiosInstance().get(
    APIConstant.NWEA_API.GetNWEASchoolGrades,
    {
      params: {
        userId: userId,
      },
    }
  );
};

export const mapNWEAStudents = (userId: number, gradeId: any) => {
  return AxiosHelper.axiosInstance().put(
    `${APIConstant.NWEA_API.MapNWEAStudents}`,
    {
      updatedByUserId: userId,
      gradeId: gradeId,
    }
  );
};

export const getMappedNWEAStudents = (
  userId: number,
  pageNumber: number,
  pageSize: number,
  gradeId: number
) => {
  return AxiosHelper.axiosInstance().get(
    `${APIConstant.NWEA_API.GetMappedStudents}`,
    {
      params: {
        userId: userId,
        pageNumber: pageNumber,
        pageSize: pageSize,
        gradeId: gradeId,
      },
    }
  );
};

export const getUnmappedNWEAStudents = (
  userId: number,
  pageNumber: number,
  pageSize: number,
  gradeId: number
) => {
  return AxiosHelper.axiosInstance().get(
    `${APIConstant.NWEA_API.GetUmmappedStudents}`,
    {
      params: {
        userId: userId,
        pageNumber: pageNumber,
        pageSize: pageSize,
        gradeId: gradeId,
      },
    }
  );
};

export const getUnmappedNWEAStudentByProfileId = (
  profileId: number,
  pageNumber: number,
  pageSize: number,
  nweaId: string,
  firstName: string,
  lastName: string
) => {
  return AxiosHelper.axiosInstance().get(
    `${APIConstant.NWEA_API.GetUmmappedStudentByProfileId}`,
    {
      params: {
        studentProfileId: profileId,
        pageNumber: pageNumber,
        pageSize: pageSize,
        nweaId: nweaId ? nweaId : null,
        firstName: firstName,
        lastName: lastName,
      },
    }
  );
};

export const updateNWEAId = (
  profileId: number,
  nweaId: string,
  userId: number,
  studentUserId: number
) => {
  return AxiosHelper.axiosInstance().put(
    `${APIConstant.NWEA_API.UpdateNWEAId}`,
    {
      studentProfileId: profileId,
      nweaId: nweaId,
      updatedByUserId: userId,
      studentUserId: studentUserId,
    }
  );
};

export const updateNWEADisabledSetting = (
  profileId: number,
  isNWEADisabled: boolean,
  userId: number
) => {
  return AxiosHelper.axiosInstance().put(
    `${APIConstant.NWEA_API.UpdateNWEADisabledSetting}`,
    {
      studentProfileId: profileId,
      isNWEADisabled: isNWEADisabled,
      updatedByUserId: userId,
    }
  );
};

export const getNWEAStudentResult = (
  userId?: number,
  nweaId?: string,
  pageNumber?: number,
  pageSize?: number
) => {
  return AxiosHelper.axiosInstance().get(
    `${APIConstant.NWEA_API.GetNWEAStudentResults}`,
    {
      params: {
        userId: userId ? userId : null,
        nweaId: nweaId ? nweaId : null,
        pageNumber: pageNumber,
        pageSize: pageSize,
      },
    }
  );
};

export const searchUnmappedNWEAStudents = (
  userId: number,
  nweaId: string,
  firstName: string,
  lastName: string,
  pageNumber: number,
  pageSize: number
) => {
  return AxiosHelper.axiosInstance().get(
    `${APIConstant.NWEA_API.SearchUmmappedStudents}`,
    {
      params: {
        userId: userId,
        nweaId: nweaId ? nweaId : null,
        firstName: firstName,
        lastName: lastName,
        pageNumber: pageNumber,
        pageSize: pageSize,
      },
    }
  );
};

export const deleteNWEALiftOffDiagnosticAssignments = (
  studentId: number,
  updatedByUserId: number
) => {
  return AxiosHelper.axiosInstance().put(
    `${APIConstant.NWEA_API.DeleteNWEALiftOffDiagnosticAssignments}`,
    {
      studentId: studentId,
      updatedByUserId: updatedByUserId,
    }
  );
};
export const getNWEATestResultMappingStatus = (
  userId?: number,
  gradeId?: number,
  pageNumber?: number,
  pageSize?: number
) => {
  return AxiosHelper.axiosInstance().get(
    `${APIConstant.NWEA_API.GetTestResultMappingStatus}`,
    {
      params: {
        userId: userId ? userId : null,
        gradeId: gradeId ? gradeId : null,
        pageNumber: pageNumber,
        pageSize: pageSize,
      },
    }
  );
};

export const resetNWEAResult = (params) => {
  return AxiosHelper.axiosInstance().post(
    `${APIConstant.NWEA_API.Reset}`,
    params
  );
};

export const getMappedNWEAStudentsByFilter = (
  userId: number,
  pageNumber: number,
  pageSize: number,
  classIds: number[] | undefined,
  gradeIds: number[] | undefined,
  teacherUserIds: number[] | undefined,
  studentUserIds: number[] | undefined
) => {
  const params = {
    userId: userId,
    pageNumber: pageNumber,
    pageSize: pageSize,
    classIds: classIds,
    gradeIds: gradeIds,
    teacherUserIds: teacherUserIds,
    studentUserIds: studentUserIds,
  };
  return AxiosHelper.axiosInstance().post(
    `${APIConstant.NWEA_API.GetMappedStudentsByFilter}`,
    params,
    {}
  );
};

export const getUnmappedNWEAStudentsByFilter = (
  userId: number,
  pageNumber: number,
  pageSize: number,
  classIds: number[] | undefined,
  gradeIds: number[] | undefined,
  teacherUserIds: number[] | undefined,
  studentUserIds: number[] | undefined
) => {
  const params = {
    userId: userId,
    pageNumber: pageNumber,
    pageSize: pageSize,
    classIds: classIds,
    gradeIds: gradeIds,
    teacherUserIds: teacherUserIds,
    studentUserIds: studentUserIds,
  };
  return AxiosHelper.axiosInstance().post(
    `${APIConstant.NWEA_API.GetUmmappedStudentsByFilter}`,
    params,
    {}
  );
};

export const mapNWEAStudentsByFilter = (
  userId: number,
  classIds: number[] | undefined,
  gradeIds: number[] | undefined,
  teacherUserIds: number[] | undefined,
  studentUserIds: number[] | undefined
) => {
  return AxiosHelper.axiosInstance().put(
    `${APIConstant.NWEA_API.MapNWEAStudentsByFilter}`,
    {
      updatedByUserId: userId,
      classIds: classIds,
      gradeIds: gradeIds,
      teacherUserIds: teacherUserIds,
      studentUserIds: studentUserIds,
    }
  );
};

export const getNWEATestResultMappingStatusByFilter = (
  userId: number | undefined,
  pageNumber: number | undefined,
  pageSize: number | undefined,
  classIds: number[] | undefined,
  gradeIds: number[] | undefined,
  teacherUserIds: number[] | undefined,
  studentUserIds: number[] | undefined
) => {
  const params = {
    userId: userId,
    pageNumber: pageNumber,
    pageSize: pageSize,
    classIds: classIds,
    gradeIds: gradeIds,
    teacherUserIds: teacherUserIds,
    studentUserIds: studentUserIds,
  };
  return AxiosHelper.axiosInstance().post(
    `${APIConstant.NWEA_API.GetTestResultMappingStatusByFilter}`,
    params,
    {}
  );
};

export const addNWEATestResult = (
  classIds: number[] | undefined,
  gradeIds: number[] | undefined,
  teacherUserIds: number[] | undefined,
  studentUserIds: number[] | undefined
) => {
  const params = {
    classIds: classIds,
    gradeIds: gradeIds,
    teacherUserIds: teacherUserIds,
    studentUserIds: studentUserIds,
  };
  return AxiosHelper.axiosInstance().post(
    `${APIConstant.NWEA_API.AddNWEATestResult}`,
    params,
    {}
  );
};

export const getNWEABatchStatus = (
  userId: number,
  pageNumber?: number,
  pageSize?: number
) => {
  return AxiosHelper.axiosInstance().get(
    `${APIConstant.NWEA_API.GetNWEABatchStatus}`,
    {
      params: {
        userId: userId ? userId : null,
        pageNumber: pageNumber,
        pageSize: pageSize,
      },
    }
  );
};

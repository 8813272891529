import { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { CanvasApi } from "../../../../../../api/canvas/canvasApi";
import { CloseIcon } from "../../../../../../assets/icons";
import { ICanvasClassDTO } from "../../../../../../model/canvasClassroom/canvasClassDTO";
import Profile from "../../../../../../model/users/profile";
import { UserContextState } from "../../../../../../redux/actions/userContextAction";
import {
  DEFAULT_PAGE_NUMBER,
  DEFAULT_PAGE_SIZE_100,
} from "../../../../../../utils/pagingConstant";
import Loader from "../../../../../shared/loader";
import { MinusCircleIcon, PlusCircleIcon } from "@heroicons/react/solid";
interface ConnectToCanvasClassPopupProps {
  userContext?: UserContextState;
  profile?: Profile;
  classId: number;
  canvasCourseId: string;
  showPopup(show: boolean): void;
  onSuccess(): void;
}

type SelectedEntity = {
  course: ICanvasClassDTO | null;
  section: any | null;
  entityType: number;
};

const EntityType = {
  COURSE: 1,
  SECTION: 2,
};

function ConnectToCanvasClassPopup(props: ConnectToCanvasClassPopupProps) {
  const [loading, setLoading] = useState<boolean>(false);
  const [pageNumber, setPageNumber] = useState(DEFAULT_PAGE_NUMBER);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE_100);
  const [classes, setClasses] = useState<Array<ICanvasClassDTO>>([]);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [overrideClassDisplayName, setOverrideClassDisplayName] =
    useState(false);
  const [selectedEntity, setSelectedEntity] = useState<SelectedEntity>();

  const getCanvasClassesToImport = async (
    pageNumber: number,
    pageSize: number
  ) => {
    setPageNumber(pageNumber);
    setPageSize(pageSize);
    setLoading(true);
    try {
      const response = await CanvasApi.getCanvasClassesToImport(
        props?.userContext?.userId!,
        props?.profile?.canvasId!,
        "",
        "",
        false,
        pageSize,
        pageNumber,
        props?.profile?.canvasAccountSettings!
      );
      setLoading(false);

      let allCourses = response.data;
      allCourses = allCourses.sort((a, b) =>
        a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
      );

      setHasMore(response?.data?.length > 0);
      return allCourses;
    } catch (r) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  const loadMore = async (pageNumber: number, pageSize: number) => {
    const resp = await getCanvasClassesToImport(pageNumber, pageSize);
    setClasses([...classes, ...resp!]);
  };

  const getCanvasClasses = async (pageNumber: number, pageSize: number) => {
    const resp = await getCanvasClassesToImport(pageNumber, pageSize);
    setClasses(resp!);
  };

  const getCanvasSections = async (courseId: string) => {
    setLoading(true);
    const resp = await CanvasApi.getCanvasSections(
      courseId,
      props.profile?.canvasAccountSettings!
    );
    if (resp.data && resp.data.length > 0) {
      const allClasses = classes;
      const modifiedSelection = allClasses.map((c) => {
        if (c.courseId === courseId) {
          c.sections = resp.data;
        }
        return c;
      });
      setClasses(modifiedSelection);
    }
    setLoading(false);
  };

  const handleCollapseExpand = (courseId: string, e: boolean) => {
    const allClasses = classes;
    const modifiedSelection = allClasses.map((c) => {
      if (c.courseId === courseId) {
        c.isExpanded = e;
      }
      return c;
    });
    setClasses(Array.from(modifiedSelection));
  };

  const handleSelection = (data: any, entityTypeId: number) => {
    console.log(data);
    if (entityTypeId === EntityType.COURSE) {
      setSelectedEntity({
        course: data,
        section: null,
        entityType: entityTypeId,
      });
    } else if (entityTypeId === EntityType.SECTION) {
      setSelectedEntity({
        course: null,
        section: data,
        entityType: entityTypeId,
      });
    }
  };

  const validate = () => {
    let isValid = true;
    if (
      selectedEntity &&
      selectedEntity?.course === null &&
      selectedEntity?.section === null
    ) {
      isValid = false;
      toast.error("Please select the course");
    }
    return isValid;
  };

  const connectToCourse = () => {
    setLoading(true);
    CanvasApi.connectToCanvas(
      props.classId,
      props?.userContext?.userId!,
      overrideClassDisplayName,
      selectedEntity?.course?.courseId!,
      selectedEntity?.course?.name!
    )
      .then((r) => {
        setLoading(false);
        props.onSuccess();
        props.showPopup(false);
      })
      .catch((r) => {
        setLoading(false);
        toast.error("Something went wrong, cannot connect to canvas");
      });
  };

  const connectToSection = () => {
    setLoading(true);
    CanvasApi.connectToSection(
      props.classId,
      selectedEntity?.section?.courseId,
      overrideClassDisplayName,
      selectedEntity?.section?.sectionId!,
      selectedEntity?.section?.name!
    )
      .then((r) => {
        setLoading(false);
        props.onSuccess();
        props.showPopup(false);
      })
      .catch((r) => {
        setLoading(false);
        toast.error("Something went wrong, cannot connect to canvas");
      });
  };

  const connectToCanvas = () => {
    if (validate()) {
      if (selectedEntity?.entityType === EntityType.COURSE) {
        connectToCourse();
      } else if (selectedEntity?.entityType === EntityType.SECTION) {
        connectToSection();
      }
    }
  };

  useEffect(() => {
    getCanvasClasses(DEFAULT_PAGE_NUMBER, DEFAULT_PAGE_SIZE_100);
  }, []);

  return (
    <Fragment>
      <div className="fixed inset-0 z-40 flex items-center bg-black bg-opacity-50 sm:items-center sm:justify-center">
        <div className="w-100 flex flex-col justify-between overflow-hidden h-auto max-h-80percent bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:max-w-6xl">
          <header>
            {/* Header */}
            <div className="px-4 py-4 bg-gray-50">
              <div className="flex items-start justify-between space-x-3">
                <div className="space-y-1">
                  <div className="text-lg font-medium text-gray-900">
                    Canvas Courses
                  </div>
                </div>
                <div className="h-7 flex items-center">
                  <button
                    type="button"
                    className="inline-flex items-center justify-center w-6 h-6 text-gray-400 transition-colors duration-150 rounded dark:hover:text-gray-200 hover: hover:text-gray-700"
                    onClick={() => props.showPopup(false)}
                  >
                    <CloseIcon />
                  </button>
                </div>
              </div>
            </div>
          </header>
          <div className="w-full h-72 px-4 py-1 relative overflow-auto">
            {loading && <Loader />}

            <div className="px-3 xl:px-2 xl:py-3 1xl:p-3">
              {classes.length > 0 &&
                classes.map((c, i) => (
                  <>
                    <div key={c.courseId} className="flex">
                      <div className="mr-3 py-2 whitespace-nowrap text-sm text-gray-500">
                        {c.isExpanded ? (
                          <MinusCircleIcon
                            className="w-5 mr-2 cursor-pointer"
                            onClick={() => {
                              handleCollapseExpand(c.courseId, !c.isExpanded);
                            }}
                          />
                        ) : (
                          <PlusCircleIcon
                            className="w-5 mr-2 cursor-pointer"
                            onClick={() => {
                              handleCollapseExpand(c.courseId, !c.isExpanded);
                              getCanvasSections(c.courseId);
                            }}
                          />
                        )}
                      </div>
                      <label className="flex items-center cursor-pointer text-sm text-gray-500">
                        <input
                          type={c.isImported ? "checkbox" : "radio"}
                          x-model="value"
                          name="course"
                          disabled={c.isImported}
                          className="h-4 w-4 cursor-pointer text-primary-violet border-gray-300 focus:ring-primary-violet disabled:opacity-50 disabled:cursor-not-allowed"
                          checked={
                            c.isImported
                              ? true
                              : selectedEntity?.entityType ===
                                  EntityType.COURSE &&
                                selectedEntity?.course?.courseId === c.courseId
                          }
                          onChange={() => handleSelection(c, EntityType.COURSE)}
                        />
                        <div className="ml-3 flex flex-col">
                          <span
                            id="privacy-setting-0-label"
                            className="block text-sm text-gray-500"
                          >
                            {c.name}
                          </span>
                        </div>
                      </label>
                    </div>
                    {c.isExpanded &&
                      c.sections &&
                      c.sections.map((s, i) => (
                        <div className=" ml-[2.5rem]">
                          <label className="flex items-center cursor-pointer text-sm text-gray-500 ">
                            <input
                              type={s.isImported ? "checkbox" : "radio"}
                              name="section"
                              disabled={s.isImported}
                              className="h-4 w-4 cursor-pointer text-primary-violet border-gray-300 focus:ring-primary-violet disabled:opacity-50 disabled:cursor-not-allowed"
                              checked={
                                s.isImported
                                  ? true
                                  : selectedEntity?.entityType ===
                                      EntityType.SECTION &&
                                    selectedEntity.section?.sectionId ===
                                      s.sectionId
                              }
                              onChange={() => {
                                const sectionOfClassId = c.classId;
                                handleSelection(
                                  { ...s, sectionOfClassId },
                                  EntityType.SECTION
                                );
                              }}
                            />
                            <div className="ml-3 flex flex-col">
                              <span className="block text-sm text-gray-500">
                                {s.name}
                              </span>
                            </div>
                          </label>
                        </div>
                      ))}
                  </>
                ))}
            </div>
            <div>{classes.length === 0 && "No Records Found"}</div>
            {classes && classes.length > 0 && hasMore && (
              <div className="flex justify-end mt-2">
                <button
                  className="bg-transparent border border-primary-violet shadow-sm py-2 px-4 inline-flex justify-center items-center text-sm font-medium text-primary-violet hover:text-white hover:bg-light-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3 transition ease-in-out duration-500"
                  type="button"
                  onClick={() => {
                    loadMore(pageNumber + 1, pageSize);
                  }}
                >
                  <span className="text-sm">Load More</span>
                </button>
              </div>
            )}
          </div>

          {/* <!-- Action Buttons -->  */}
          <footer className="flex items-center justify-between px-4 py-4 sm:flex-row bg-gray-50 dark:bg-gray-800 mt-4">
            <div>
              <label className="flex items-centers cursor-pointer text-sm text-gray-500 ">
                <input
                  type="checkbox"
                  x-model="value"
                  name="privacy_setting"
                  value="Private to Project Members"
                  className="h-4 w-4 mr-2 mt-0.5 cursor-pointer text-blue-600 border-gray-300 focus:ring-blue-500"
                  checked={overrideClassDisplayName}
                  onChange={(e) => {
                    setOverrideClassDisplayName(e.target.checked);
                  }}
                />
                Override class display name
              </label>
            </div>
            <div className="space-x-3">
              <button
                type="button"
                className="w-auto inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                onClick={connectToCanvas}
              >
                Connect
              </button>
            </div>
          </footer>
        </div>
      </div>
    </Fragment>
  );
}

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
};

export default connect<{}, {}, {}>(
  mapStateToProps,
  {}
)(ConnectToCanvasClassPopup);

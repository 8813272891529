import { XIcon } from "@heroicons/react/solid";
import { Fragment, useEffect, useState } from "react";
import Select from "react-select";
import { toast } from "react-toastify";
import ReactTooltip from "react-tooltip";
import {
  getCalculatorTypes,
  updateAssessmentData,
} from "../../../../api/teacher/assessment";
import { getAssociatedSubjects } from "../../../../api/teacher/assignment";
import { getAllTags } from "../../../../api/teacher/tags";
import { IAssessment } from "../../../../model/interface/assessment";
import { ISelectElement } from "../../../../model/interface/selectElement";
import constant from "../../../../utils/constant/constant";
import Constant from "../../../../utils/constant/constant";
import Loader from "../../../shared/loader";
import TagSelector from "../../../shared/tagSelector";
interface SettingPopupProps {
  userId: number;
  roleId: number;
  assessmentForEdit: IAssessment;
  toggleSettingPopup: (popupAction: boolean, refreshData?: boolean) => void;
  upgrade: boolean;
}

function SettingPopup(props: SettingPopupProps) {
  const { userId, roleId, assessmentForEdit, toggleSettingPopup } = props;

  type DisplayResultType = "NoDetail" | "QuestionsOnly" | "QuestionsAndAnswer";
  const calculatorContentArea: Array<string> = [
    "Math",
    "Science",
    "Social Studies",
  ];
  const defaultValue = { label: "Select", value: "0" };
  const [selectedSubject, setSelectedSubject] =
    useState<ISelectElement>(defaultValue);
  const assessment = assessmentForEdit;
  const [assessmentName, setAssessmentName] = useState(assessment.name);
  const [sharedAssessment, setSharedAssessment] = useState<boolean>(
    assessment.isSharedAssessment ? assessment.isSharedAssessment : false
  );
  const [displayResult, setDisplayResult] = useState<DisplayResultType>();
  const [displayRandomQuestion, setDisplayRandomQuestion] = useState<boolean>(
    assessment.isDisplayRandomQuestion
      ? assessment.isDisplayRandomQuestion
      : false
  );

  const [allowCalculator, setAllowCalculator] = useState<boolean>(
    assessment.isAllowCalculator ? assessment.isAllowCalculator : false
  );
  const [calculators, setCalculators] = useState<Array<ISelectElement>>([]);
  const [selectedCalculator, setSelectedCalculator] =
    useState<ISelectElement>();
  const [selectedTags, setSelectedTags] = useState<Array<number>>([]);
  const [loading, setShowLoading] = useState<boolean>(false);
  const [calculatorSubjects, setCalculatorSubjects] = useState<Array<number>>(
    []
  );
  const [canView, setCanView] = useState<boolean>(false);
  const [canDuplicate, setCanDuplicate] = useState<boolean>(false);
  const [canAssign, setCanAssign] = useState<boolean>(false);

  function handleSave() {
    if (assessmentName.trim() === "") {
      toast.error("Assessment name cannot be empty.");
      return false;
    } else if (selectedSubject.value === "0") {
      toast.error("Please select a subject.");
      return false;
    } else if (assessmentForEdit.authorId !== userId) {
      toast.error(
        "Only an assessment's author is allowed to edit that assessment's settings."
      );
      return false;
    }

    const tags = selectedTags;

    if (assessment) {
      const assessmentToSave: IAssessment = {
        assessmentId: assessment.assessmentId,
        name: assessmentName,
        subjectId: parseInt(selectedSubject.value),
        tagIds: tags,
        isSharedAssessment: sharedAssessment,
        sharedTypeId: assessment.sharedTypeId,
        displayResultInDetailId: getDisplayResult(displayResult),
        isDisplayRandomQuestion: displayRandomQuestion,
        isAllowCalculator: allowCalculator,
        calculatorTypeId:
          allowCalculator && selectedCalculator
            ? parseInt(selectedCalculator.value)
            : undefined,
        isSystemGenerated: assessment.isSystemGenerated,
        isPassageQuestion: assessment.isPassageQuestion,
        isAuthoredQuestion: assessment.isAuthoredQuestion,
        testSizeTypeId: assessment.testSizeTypeId,
        questions: assessment.questions,
        updateQuestions: false,
        learnosityIdEnglish: assessment.learnosityIdEnglish,
        learnosityIdSpanish: assessment.learnosityIdSpanish,
        canAssign: canAssign,
        canView: canView,
        canDuplicate: canDuplicate,
      };

      if (assessment.assessmentId > 0) {
        setShowLoading(true);
        updateAssessmentData(userId, roleId, assessmentToSave).then(() => {
          setShowLoading(false);
          toast.success("Assessment settings updated successfully.");
          toggleSettingPopup(false, true);
        });
      }
    }
  }

  function getDisplayResult(resultType: DisplayResultType | undefined) {
    let result: number = 0;
    switch (resultType) {
      case "NoDetail":
        result = 1;
        break;
      case "QuestionsOnly":
        result = 2;
        break;
      case "QuestionsAndAnswer":
        result = 3;
        break;
    }

    return result;
  }

  function setDisplayResultValue(value: number) {
    let result: DisplayResultType = "NoDetail";
    switch (value) {
      case 1:
        result = "NoDetail";
        break;
      case 2:
        result = "QuestionsOnly";
        break;
      case 3:
        result = "QuestionsAndAnswer";
        break;
    }
    setDisplayResult(result);
  }

  function handleTagChange(tag) {
    setSelectedTags([...selectedTags, tag.tagId]);
  }

  function getSubjects() {
    setShowLoading(true);
    getAssociatedSubjects(userId).then((response) => {
      setShowLoading(false);
      const subjectsData = response.data;
      const subjects: Array<ISelectElement> = subjectsData
        .filter((r) => r.isAdditionalSubject === false)
        .map((values: any) => {
          return {
            label: values?.name,
            value: values?.subjectId,
          };
        });

      const additionalSubjects: Array<ISelectElement> = subjectsData
        .filter((r) => r.isAdditionalSubject === true)
        .map((values: any) => {
          return {
            label: values?.name,
            value: values?.subjectId,
          };
        });

      const calculatorSubjectIds: Array<number> = subjectsData
        .filter((r) => calculatorContentArea.includes(r.contentAreaName))
        .map((r) => r.subjectId);

      setCalculatorSubjects(calculatorSubjectIds);
      let selectedSubjects = subjects.filter(
        (r) => r.value.toString() === assessment.subjectId.toString()
      );

      if (selectedSubjects && selectedSubjects.length === 0) {
        selectedSubjects = additionalSubjects.filter(
          (r) => r.value.toString() === assessment.subjectId.toString()
        );
      }

      if (selectedSubjects && selectedSubjects.length > 0) {
        setSelectedSubject(selectedSubjects[0]);
      }
    });
  }

  function setCanViewValue(value: boolean) {
    setCanView(value);
  }

  function setCanDuplicateValue(value: boolean) {
    setCanDuplicate(value);
  }

  function setCanAssignValue(value: boolean) {
    setCanAssign(value);
  }
  function getTags() {
    setShowLoading(true);
    getAllTags(userId).then((response) => {
      setShowLoading(false);
      const tagsData = response.data.data;

      if (assessment.tagIds) {
        const existingTagIds: Array<number> = assessment.tagIds;
        const selectedTags = tagsData
          .filter((r) => existingTagIds.includes(parseInt(r.userTagId)))
          .map((t) => t.userTagId);
        setSelectedTags(selectedTags);
      }
    });
  }

  function setExistingValues() {
    if (assessment) {
      if (assessment.displayResultInDetailId) {
        setDisplayResultValue(assessment.displayResultInDetailId);
      }
      if (assessment.canAssign) {
        setCanAssignValue(assessment.canAssign);
      }
      if (assessment.canView) {
        setCanViewValue(assessment.canView);
      }
      if (assessment.canDuplicate) {
        setCanDuplicateValue(assessment.canDuplicate);
      }
    }
  }

  function handleAssignmentNameChange(e: any) {
    const assessmentName = e.target.value;
    setAssessmentName(assessmentName);
  }

  function getCalculators() {
    setShowLoading(true);
    getCalculatorTypes().then((res) => {
      setShowLoading(false);
      const calculatorTypes = res.data.map((values: any) => {
        return {
          label: values?.name,
          value: values?.calculatorTypeId,
        };
      });
      setCalculators(calculatorTypes);

      if (assessment.isAllowCalculator && assessment.calculatorTypeId) {
        const calculatorType: ISelectElement = calculatorTypes.filter(
          (r) => r.value?.toString() === assessment.calculatorTypeId?.toString()
        );
        setSelectedCalculator(calculatorType);
      }
    });
  }

  function handleCalculatorChange(selectdItem: any) {
    setSelectedCalculator(selectdItem);
  }

  useEffect(() => {
    if (props.upgrade) setSharedAssessment(false);
    getSubjects();
    getTags();
    setExistingValues();
    getCalculators();
  }, []);

  return (
    <Fragment>
      {loading && <Loader />}
      <ReactTooltip
        multiline={true}
        className="bg-secondary-teal w-1/3"
        backgroundColor="#008fbb"
        place="right"
        effect="float"
      />
      <div className="fixed inset-0 z-40 flex items-center bg-black bg-opacity-50 sm:items-center sm:justify-center">
        <div
          className="w-full flex flex-col justify-between overflow-hidden h-auto max-h-80percent bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:max-w-5xl"
          role="dialog"
        >
          <header>
            {/* Header */}
            <div className="px-4 py-4 bg-gray-50">
              <div className="flex items-start justify-between space-x-3">
                <div className="space-y-1">
                  <div className="text-lg font-medium text-gray-900">
                    Edit Assessment Settings
                  </div>
                </div>
                <div className="h-7 flex items-center">
                  <button
                    type="button"
                    className="text-gray-400 hover:text-gray-500"
                    onClick={() => toggleSettingPopup(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
          </header>
          <div className="w-full relative overflow-auto px-4 py-1">
            <form>
              <div>
                <div className="pt-8 px-4 sm:py-6 ">
                  <div className="space-y-6 sm:space-y-5">
                    <div className="sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start">
                      <label
                        htmlFor="first-name"
                        className="block text-sm font-normal text-gray-500 sm:mt-px sm:pt-2"
                      >
                        Assessment Name
                      </label>
                      <div className="mt-1 sm:mt-0 sm:col-span-2">
                        <input
                          autoComplete="off"
                          type="text"
                          name="first-name"
                          id="first-name"
                          maxLength={60}
                          value={assessmentName}
                          onChange={(e) => handleAssignmentNameChange(e)}
                          className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                        />
                      </div>
                    </div>
                    <div className="sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                      <label
                        htmlFor="country"
                        className="block text-sm font-normal text-gray-500 sm:mt-px sm:pt-2 "
                      >
                        Subject
                      </label>
                      <div className="mt-1 sm:col-span-2 items-start">
                        {assessment.subjectName}
                      </div>
                    </div>
                    <div className="sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                      <label
                        htmlFor="country"
                        className="block text-sm font-normal text-gray-500 sm:mt-px sm:pt-2 sm:col-span-1"
                      >
                        Add Tag(s)
                      </label>
                      <div className="mt-1 sm:mt-0 sm:col-span-3">
                        <TagSelector
                          label={"Tags"}
                          userId={userId}
                          selectedTagIds={selectedTags}
                          showSelectedTags={true}
                          onTagSelectChange={handleTagChange}
                        />
                      </div>
                    </div>
                    <div>
                      {(roleId === Constant.UserRoleId.SchoolTeacher ||
                        roleId === Constant.UserRoleId.FreeTeacher ||
                        roleId === Constant.UserRoleId.SchoolAdmin) && (
                        <div
                          role="group"
                          aria-labelledby="label-notifications"
                          className={
                            props.assessmentForEdit.sharedTypeId ===
                            constant.AssessmentSharedType.SchoolShared
                              ? "hidden"
                              : ""
                          }
                        >
                          <div className="sm:grid sm:grid-cols-4 sm:gap-4 sm:items-baseline">
                            <div>
                              <div
                                className="text-sm text-gray-500"
                                id="label-notifications"
                                data-tip={
                                  props.upgrade
                                    ? "Upgrade to a School Account to access this feature."
                                    : "Enabling this setting will allow other teachers to assign/duplicate this assessment."
                                }
                              >
                                Share Assessment
                              </div>
                            </div>
                            <div className="flex col-span-8 sm:col-span-4 xl:col-span-2 gap-4">
                              <label
                                x-radio-group-option=""
                                className=" relative flex cursor-pointer focus:outline-none border-gray-400"
                              >
                                {props.upgrade && (
                                  <input
                                    disabled={true}
                                    type="radio"
                                    checked={false}
                                    x-model="value"
                                    name="assignment"
                                    value="Public access"
                                    className="h-4 w-4 cursor-pointer text-secondary-teal border-gray-300 focus:ring-secondary-teal"
                                    aria-labelledby="privacy-setting-0-label"
                                    aria-describedby="privacy-setting-0-description"
                                  />
                                )}
                                {!props.upgrade && (
                                  <input
                                    type="radio"
                                    checked={sharedAssessment}
                                    x-model="value"
                                    name="assignment"
                                    value="Public access"
                                    className="h-4 w-4 cursor-pointer text-secondary-teal border-gray-300 focus:ring-secondary-teal"
                                    aria-labelledby="privacy-setting-0-label"
                                    aria-describedby="privacy-setting-0-description"
                                    onChange={() => setSharedAssessment(true)}
                                  />
                                )}

                                <div className="ml-3 flex flex-col">
                                  <span
                                    id="privacy-setting-0-label"
                                    className="block text-sm text-gray-500"
                                  >
                                    Yes
                                  </span>
                                </div>
                              </label>
                              <label
                                x-radio-group-option=""
                                className=" relative flex cursor-pointer focus:outline-none border-gray-400"
                              >
                                {props.upgrade && (
                                  <input
                                    disabled={true}
                                    checked={false}
                                    type="radio"
                                    x-model="value"
                                    name="assignment"
                                    value="Public access"
                                    className="h-4 w-4 cursor-pointer text-secondary-teal border-gray-300 focus:ring-secondary-teal"
                                    aria-labelledby="privacy-setting-0-label"
                                    aria-describedby="privacy-setting-0-description"
                                  />
                                )}
                                {!props.upgrade && (
                                  <input
                                    checked={!sharedAssessment}
                                    type="radio"
                                    x-model="value"
                                    name="assignment"
                                    value="Public access"
                                    className="h-4 w-4 cursor-pointer text-secondary-teal border-gray-300 focus:ring-secondary-teal"
                                    aria-labelledby="privacy-setting-0-label"
                                    aria-describedby="privacy-setting-0-description"
                                    onChange={() => setSharedAssessment(false)}
                                  />
                                )}

                                <div className="ml-3 flex flex-col">
                                  <span
                                    id="privacy-setting-0-label"
                                    className="block text-sm text-gray-500"
                                  >
                                    No
                                  </span>
                                </div>
                              </label>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div>
                      <div role="group" aria-labelledby="label-notifications">
                        <div className="sm:grid sm:grid-cols-4 sm:gap-4 sm:items-baseline">
                          <div>
                            <div
                              className="text-sm text-gray-500"
                              id="label-notifications"
                              data-tip="This setting provides the ability to disable students from seeing all of the questions and answers, <br /> seeing only the questions without the answers or the full list of both questions with answers from their Results view upon completing an assessment.<br /> Students can still view their grade and scores by Domain and Standard.<br /> This setting can be changed later or after all students have completed the assessment"
                            >
                              Allow students to view item results in detail
                            </div>
                          </div>
                          <div className="flex col-span-6 sm:col-span-4 xl:col-span-3 gap-4">
                            <label
                              x-radio-group-option=""
                              className=" relative flex cursor-pointer focus:outline-none border-gray-400"
                            >
                              <input
                                type="radio"
                                checked={displayResult === "NoDetail"}
                                x-model="value"
                                name="view-item-detail"
                                value="Public access"
                                className="h-4 w-4 cursor-pointer text-secondary-teal border-gray-300 focus:ring-secondary-teal"
                                aria-labelledby="privacy-setting-0-label"
                                aria-describedby="privacy-setting-0-description"
                                onChange={() => setDisplayResult("NoDetail")}
                              />
                              <div className="ml-3 flex flex-col">
                                <span
                                  id="privacy-setting-0-label"
                                  className="block text-sm text-gray-500"
                                >
                                  View no detail (Scores only)
                                </span>
                              </div>
                            </label>
                            <label
                              x-radio-group-option=""
                              className=" relative flex cursor-pointer focus:outline-none border-gray-400"
                            >
                              <input
                                checked={displayResult === "QuestionsOnly"}
                                type="radio"
                                x-model="value"
                                name="view-item-detail"
                                value="Public access"
                                className="h-4 w-4 cursor-pointer text-secondary-teal border-gray-300 focus:ring-secondary-teal"
                                aria-labelledby="privacy-setting-0-label"
                                aria-describedby="privacy-setting-0-description"
                                onChange={() =>
                                  setDisplayResult("QuestionsOnly")
                                }
                              />
                              <div className="ml-3 flex flex-col">
                                <span
                                  id="privacy-setting-0-label"
                                  className="block text-sm text-gray-500"
                                >
                                  View questions only
                                </span>
                              </div>
                            </label>
                            <label
                              x-radio-group-option=""
                              className=" relative flex cursor-pointer focus:outline-none border-gray-400"
                            >
                              <input
                                checked={displayResult === "QuestionsAndAnswer"}
                                type="radio"
                                x-model="value"
                                name="view-item-detail"
                                value="Public access"
                                className="h-4 w-4 cursor-pointer text-secondary-teal border-gray-300 focus:ring-secondary-teal"
                                aria-labelledby="privacy-setting-0-label"
                                aria-describedby="privacy-setting-0-description"
                                onChange={() =>
                                  setDisplayResult("QuestionsAndAnswer")
                                }
                              />
                              <div className="ml-3 flex flex-col">
                                <span
                                  id="privacy-setting-0-label"
                                  className="block text-sm text-gray-500"
                                >
                                  View questions and answers
                                </span>
                              </div>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Allow Teachers to Assign Assessment */}
                    <div>
                      {((roleId === Constant.UserRoleId.SchoolAdmin &&
                        assessmentForEdit.sharedTypeId ===
                          Constant.AssessmentSharedType.SchoolShared) ||
                        (roleId === Constant.UserRoleId.FreeTeacher &&
                          assessmentForEdit.sharedTypeId ===
                            Constant.AssessmentSharedType.SchoolShared) ||
                        assessmentForEdit.sharedTypeId ===
                          Constant.AssessmentSharedType.DistrictShared) && (
                        <div role="group" aria-labelledby="label-notifications">
                          <div className="sm:grid sm:grid-cols-4 md:grid-cols-2 lg:grid-cols-4 sm:gap-4 sm:items-baseline">
                            <div>
                              <div
                                className="text-sm text-gray-500 mb-2"
                                id="label-notifications"
                                data-tip={
                                  " Enabling this setting will allow teachers to assign the assessment to their students"
                                }
                              >
                                Allow Teachers to Assign Assessment
                              </div>
                            </div>
                            <div className="flex col-span-6 sm:col-span-4 xl:col-span-2 gap-4">
                              <label
                                x-radio-group-option=""
                                className=" relative flex cursor-pointer focus:outline-none border-gray-400"
                              >
                                <input
                                  checked={canAssign}
                                  type="radio"
                                  x-model="value"
                                  name="allow-change-canAssign"
                                  value="Public access"
                                  className="h-4 w-4 cursor-pointer text-secondary-teal border-gray-300 focus:ring-secondary-teal"
                                  aria-labelledby="privacy-setting-0-label"
                                  aria-describedby="privacy-setting-0-description"
                                  onChange={() => setCanAssignValue(!canAssign)}
                                />
                                <div className="ml-3 flex flex-col">
                                  <span
                                    id="privacy-setting-0-label"
                                    className="block text-sm text-gray-500"
                                  >
                                    Yes
                                  </span>
                                </div>
                              </label>
                              <label
                                x-radio-group-option=""
                                className=" relative flex cursor-pointer focus:outline-none border-gray-400"
                              >
                                <input
                                  type="radio"
                                  checked={!canAssign}
                                  x-model="value"
                                  name="allow-change-canAssign"
                                  value="Public access"
                                  className="h-4 w-4 cursor-pointer text-secondary-teal border-gray-300 focus:ring-secondary-teal"
                                  aria-labelledby="privacy-setting-0-label"
                                  aria-describedby="privacy-setting-0-description"
                                  onChange={() => setCanAssignValue(!canAssign)}
                                />
                                <div className="ml-3 flex flex-col">
                                  <span
                                    id="privacy-setting-0-label"
                                    className="block text-sm text-gray-500"
                                  >
                                    No
                                  </span>
                                </div>
                              </label>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    {/* Allow Teachers to View Assessment Content and Details */}
                    <div>
                      {((roleId === Constant.UserRoleId.SchoolAdmin &&
                        assessmentForEdit.sharedTypeId ===
                          Constant.AssessmentSharedType.SchoolShared) ||
                        (roleId === Constant.UserRoleId.FreeTeacher &&
                          assessmentForEdit.sharedTypeId ===
                            Constant.AssessmentSharedType.SchoolShared) ||
                        assessmentForEdit.sharedTypeId ===
                          Constant.AssessmentSharedType.DistrictShared) && (
                        <div role="group" aria-labelledby="label-notifications">
                          <div className="sm:grid sm:grid-cols-4 md:grid-cols-2 lg:grid-cols-4 sm:gap-4 sm:items-baseline">
                            <div>
                              <div
                                className="text-sm text-gray-500 mb-2"
                                id="label-notifications"
                                data-tip={
                                  " Enabling this setting will allow teachers to view all content related to this assessment and results for students whom the teacher assigned"
                                }
                              >
                                Allow Teachers to View Assessment Content and
                                Details
                              </div>
                            </div>
                            <div className="flex col-span-6 sm:col-span-4 xl:col-span-2 gap-4">
                              <label
                                x-radio-group-option=""
                                className=" relative flex cursor-pointer focus:outline-none border-gray-400"
                              >
                                <input
                                  checked={canView}
                                  type="radio"
                                  x-model="value"
                                  name="allow-change-canView"
                                  value="Public access"
                                  className="h-4 w-4 cursor-pointer text-secondary-teal border-gray-300 focus:ring-secondary-teal"
                                  aria-labelledby="privacy-setting-0-label"
                                  aria-describedby="privacy-setting-0-description"
                                  onChange={() => setCanViewValue(!canView)}
                                />
                                <div className="ml-3 flex flex-col">
                                  <span
                                    id="privacy-setting-0-label"
                                    className="block text-sm text-gray-500"
                                  >
                                    Yes
                                  </span>
                                </div>
                              </label>
                              <label
                                x-radio-group-option=""
                                className=" relative flex cursor-pointer focus:outline-none border-gray-400"
                              >
                                <input
                                  type="radio"
                                  checked={!canView}
                                  x-model="value"
                                  name="allow-change-canView"
                                  value="Public access"
                                  className="h-4 w-4 cursor-pointer text-secondary-teal border-gray-300 focus:ring-secondary-teal"
                                  aria-labelledby="privacy-setting-0-label"
                                  aria-describedby="privacy-setting-0-description"
                                  onChange={() => setCanViewValue(!canView)}
                                />
                                <div className="ml-3 flex flex-col">
                                  <span
                                    id="privacy-setting-0-label"
                                    className="block text-sm text-gray-500"
                                  >
                                    No
                                  </span>
                                </div>
                              </label>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    {/* Allow Teachers to Duplicate Assessment */}
                    <div>
                      {((roleId === Constant.UserRoleId.SchoolAdmin &&
                        assessmentForEdit.sharedTypeId ===
                          Constant.AssessmentSharedType.SchoolShared) ||
                        (roleId === Constant.UserRoleId.FreeTeacher &&
                          assessmentForEdit.sharedTypeId ===
                            Constant.AssessmentSharedType.SchoolShared) ||
                        assessmentForEdit.sharedTypeId ===
                          Constant.AssessmentSharedType.DistrictShared) && (
                        <div role="group" aria-labelledby="label-notifications">
                          <div className="sm:grid sm:grid-cols-4 md:grid-cols-2 lg:grid-cols-4 sm:gap-4 sm:items-baseline">
                            <div>
                              <div
                                className="text-sm text-gray-500 mb-2"
                                id="label-notifications"
                                data-tip={
                                  " Enabling this setting will allow teachers to duplicate this assessment"
                                }
                              >
                                Allow Teachers to Duplicate Assessment
                              </div>
                            </div>
                            <div className="flex col-span-6 sm:col-span-4 xl:col-span-2 gap-4">
                              <label
                                x-radio-group-option=""
                                className=" relative flex cursor-pointer focus:outline-none border-gray-400"
                              >
                                <input
                                  checked={canDuplicate}
                                  type="radio"
                                  x-model="value"
                                  name="allow-change-canDuplicate"
                                  value="Public access"
                                  className="h-4 w-4 cursor-pointer text-secondary-teal border-gray-300 focus:ring-secondary-teal"
                                  aria-labelledby="privacy-setting-0-label"
                                  aria-describedby="privacy-setting-0-description"
                                  onChange={() =>
                                    setCanDuplicateValue(!canDuplicate)
                                  }
                                />
                                <div className="ml-3 flex flex-col">
                                  <span
                                    id="privacy-setting-0-label"
                                    className="block text-sm text-gray-500"
                                  >
                                    Yes
                                  </span>
                                </div>
                              </label>
                              <label
                                x-radio-group-option=""
                                className=" relative flex cursor-pointer focus:outline-none border-gray-400"
                              >
                                <input
                                  type="radio"
                                  checked={!canDuplicate}
                                  x-model="value"
                                  name="allow-change-canDuplicate"
                                  value="Public access"
                                  className="h-4 w-4 cursor-pointer text-secondary-teal border-gray-300 focus:ring-secondary-teal"
                                  aria-labelledby="privacy-setting-0-label"
                                  aria-describedby="privacy-setting-0-description"
                                  onChange={() =>
                                    setCanDuplicateValue(!canDuplicate)
                                  }
                                />
                                <div className="ml-3 flex flex-col">
                                  <span
                                    id="privacy-setting-0-label"
                                    className="block text-sm text-gray-500"
                                  >
                                    No
                                  </span>
                                </div>
                              </label>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>

                    <div>
                      <div role="group" aria-labelledby="label-notifications">
                        <div className="sm:grid sm:grid-cols-4 sm:gap-4 sm:items-baseline">
                          <div>
                            <div
                              className="text-sm text-gray-500"
                              id="label-notifications"
                              data-tip="The questions will be shuffled in a different order for each student. This will also shuffle questions that relate to the same passage. To keep these question grouped, this setting should be set to No."
                            >
                              Display questions in random order
                            </div>
                          </div>
                          <div className="flex col-span-8 sm:col-span-4 xl:col-span-2 gap-4 ">
                            <label
                              x-radio-group-option=""
                              className=" relative flex cursor-pointer focus:outline-none border-gray-400"
                            >
                              <input
                                type="radio"
                                checked={displayRandomQuestion}
                                x-model="value"
                                name="question-random"
                                value="Public access"
                                className="h-4 w-4 cursor-pointer text-secondary-teal border-gray-300 focus:ring-secondary-teal"
                                aria-labelledby="privacy-setting-0-label"
                                aria-describedby="privacy-setting-0-description"
                                onChange={() => setDisplayRandomQuestion(true)}
                              />
                              <div className="ml-3 flex flex-col">
                                <span
                                  id="privacy-setting-0-label"
                                  className="block text-sm text-gray-500"
                                >
                                  Yes
                                </span>
                              </div>
                            </label>
                            <label
                              x-radio-group-option=""
                              className=" relative flex cursor-pointer focus:outline-none border-gray-400"
                            >
                              <input
                                checked={!displayRandomQuestion}
                                type="radio"
                                x-model="value"
                                name="question-random"
                                value="Public access"
                                className="h-4 w-4 cursor-pointer text-secondary-teal border-gray-300 focus:ring-secondary-teal"
                                aria-labelledby="privacy-setting-0-label"
                                aria-describedby="privacy-setting-0-description"
                                onChange={() => setDisplayRandomQuestion(false)}
                              />
                              <div className="ml-3 flex flex-col">
                                <span
                                  id="privacy-setting-0-label"
                                  className="block text-sm text-gray-500"
                                >
                                  No
                                </span>
                              </div>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    {calculatorSubjects.some(
                      (r) => r.toString() === selectedSubject.value.toString()
                    ) && (
                      <div>
                        <div role="group" aria-labelledby="label-notifications">
                          <div className="sm:grid sm:grid-cols-4 sm:gap-4 sm:items-center">
                            <div>
                              <div
                                className="text-sm text-gray-500"
                                id="label-notifications"
                                data-tip={
                                  "Four Function -This calculator allows for basic functionality to add, subtract, multiply and divide. <br />Scientific -This calculator includes basic operations and additional functionality for trigonometry, roots, fractions, exponents, absolute value, pi, and statistics. <br />Graphing-This calculator includes the ability to graph functions, plot data, evaluate equations, and explore translations."
                                }
                              >
                                Allow calculator
                              </div>
                            </div>
                            <div className="flex col-span-8 sm:col-span-3 xl:col-span-3 gap-4 items-center">
                              <label
                                x-radio-group-option=""
                                className=" relative flex cursor-pointer focus:outline-none border-gray-400"
                              >
                                <input
                                  type="radio"
                                  checked={allowCalculator}
                                  x-model="value"
                                  name="allow-calculator"
                                  value="Public access"
                                  className="h-4 w-4 cursor-pointer text-secondary-teal border-gray-300 focus:ring-secondary-teal"
                                  aria-labelledby="privacy-setting-0-label"
                                  aria-describedby="privacy-setting-0-description"
                                  onChange={() => setAllowCalculator(true)}
                                />
                                <div className="ml-3 flex flex-col">
                                  <span
                                    id="privacy-setting-0-label"
                                    className="block text-sm text-gray-500"
                                  >
                                    Yes
                                  </span>
                                </div>
                              </label>
                              <label
                                x-radio-group-option=""
                                className=" relative flex cursor-pointer focus:outline-none border-gray-400"
                              >
                                <input
                                  checked={!allowCalculator}
                                  type="radio"
                                  x-model="value"
                                  name="allow-calculator"
                                  value="Public access"
                                  className="h-4 w-4 cursor-pointer text-secondary-teal border-gray-300 focus:ring-secondary-teal"
                                  aria-labelledby="privacy-setting-0-label"
                                  aria-describedby="privacy-setting-0-description"
                                  onChange={() => setAllowCalculator(false)}
                                />
                                <div className="ml-3 flex flex-col">
                                  <span
                                    id="privacy-setting-0-label"
                                    className="block text-sm text-gray-500"
                                  >
                                    No
                                  </span>
                                </div>
                              </label>
                              <div className="w-72">
                                {allowCalculator && (
                                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                                    <Select
                                      value={selectedCalculator}
                                      options={calculators}
                                      onChange={handleCalculatorChange}
                                      theme={(theme) => ({
                                        ...theme,
                                        colors: {
                                          ...theme.colors,
                                          primary: "#008fbb",
                                        },
                                      })}
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>

          {/* <!-- Action Buttons -->  */}
          <footer className="flex items-center justify-end px-4 py-4 sm:flex-row bg-gray-50 dark:bg-gray-800 mt-4">
            <div className="space-x-3 flex justify-end">
              <button
                onClick={() => handleSave()}
                className="align-middle inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 rounded-lg text-sm text-white border border-transparent bg-secondary-teal hover:bg-dark-teal focus:shadow-indigo-500 w-full sm:w-auto"
              >
                Save Assessment
              </button>
              <button
                onClick={() => toggleSettingPopup(false)}
                className="align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 rounded-lg text-sm text-gray-600 border-gray-300 border dark:text-gray-400 focus:outline-none active:bg-transparent hover:border-gray-500 focus:border-gray-500 active:text-gray-500 focus:shadow-outline-gray w-full sm:w-auto"
              >
                Close
              </button>
            </div>
          </footer>
        </div>
      </div>
    </Fragment>
  );
}

export default SettingPopup;

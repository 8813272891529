import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { Fragment, useEffect, useState } from "react";
import constant from "../../utils/constant/constant";
import { Info } from "../../assets/icons";
import UpGradeRequestPopup from "./upGradeRequestPopup";

interface UpGradeMessagePopupProps {
  roleId: number;
  togglePopup: (popupAction: boolean) => void;
  message: string;
}

function UpGradeMessagePopup(props: UpGradeMessagePopupProps) {
  const { togglePopup, roleId } = props;
  const message = !props.message
    ? roleId == constant.UserRoleId.FreeTeacher
      ? constant.UPGRADE_MESSAGE_SCHOOL_ACCOUNT
      : constant.UserRoleId.Student
      ? constant.UPGRADE_MESSAGE_STUDENT_ACCOUNT
      : ""
    : props.message;
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [showUpgradeRequestPopup, setShowUpgradeRequestPopup] =
    useState<boolean>(false);

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  useEffect(() => {
    if (!showUpgradeRequestPopup && togglePopup && isLoaded) togglePopup(false);
  }, [showUpgradeRequestPopup]);

  return (
    <Fragment>
      <Transition.Root show={true} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-50 inset-0 overflow-y-auto"
          onClose={() => togglePopup(false)}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>
            {showUpgradeRequestPopup && (
              <UpGradeRequestPopup togglePopup={setShowUpgradeRequestPopup} />
            )}

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xl sm:w-full sm:p-6">
                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="h-15 w-15 bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none "
                    onClick={() => togglePopup(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="w-full relative overflow-auto px-4 py-3">
                  <div className="flex-1">
                    <div className="p-1">                     
                        <div
                          className={`text-sm text-gray-700 font-light bg-blue-50 p-4 flex w-full rounded-md rounded-t-lg sm:rounded-lg overflow-hidden`}
                        >
                          <div className="flex-shrink-0 current-fill text-blue-400">
                            <Info title={""} />
                          </div>
                          <div className="pl-2">
                            <>
                              {message === constant.UPGRADE_MESSAGE_SCHOOL_ACCOUNT ? constant.UPGRADE_MESSAGE_SCHOOL_ACCOUNT_Popup : message}{" "}
                              <a
                                className="text-primary-violet"
                                onClick={() => {
                                  setShowUpgradeRequestPopup(true);
                                }}
                              >
                                here
                              </a>
                              .
                            </>
                          </div>
                        </div>
                     
                    </div>
                  </div>
                </div>
                <div className="mt-2 sm:mt-2 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-violet text-base font-medium text-white hover:bg-primary-color focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-color sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => togglePopup(false)}
                  >
                    Ok
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </Fragment>
  );
}

export default UpGradeMessagePopup;

import { useState } from "react";
import ActivityStatusListResponse from "../../../../../model/student/shared/assignment/activityStatusListResponse";
import { CompleteSavedActivityGrid } from "./completeSavedActivityGrid";

interface listProps {
  activityData: ActivityStatusListResponse[];
  userId: number;
  plLanguageId?: number;
}

function CompleteSavedActivity(props: listProps) {
  useState<boolean>(false);
  const savedActivityData = props.activityData;


  return (
    <>
      <div>
        {
          <div className="h-auto min-h-half rounded-bl-3xl py-4 pt-0 pb-14">
            <div className="relative">
             
              <CompleteSavedActivityGrid
                savedActivityData={savedActivityData}
                plLanguageId={props.plLanguageId}
              />
            </div>
          </div>
        }
      </div>
    </>
  );
}

export default CompleteSavedActivity;

export function paramEncode(param: any) {
  if (param) {
    //1 - Must first encodeUriComponent to ensure non-ascii multi-byte chars are first url encoded to single byte. btoa doesn't work with multi-byte chars.
    //2 - Base64 encode the url encoded string
    //3 - encodeUriComponent the base64 string since it has querystring-unsafe chars such as + and =.
    return encodeURIComponent(btoa(encodeURIComponent(param)));
  }
  return param;
}

export function paramDecode(param: any) {
  if (param) {
    //Perform inverse operations in reverse order
    return decodeURIComponent(atob(decodeURIComponent(param)));
  }
  return param;
}

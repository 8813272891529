import { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  ArchieveIcon,
  ArrowRight,
  CanvasIcon,
  DeleteFillIcon,
  GoogleClassroomIcon,
  PasswordCardIcon,
} from "../../../../../assets/icons/index";
import { UserContextState } from "../../../../../redux/actions/userContextAction";
import Permissions from "../../../../../utils/permissions";
import AllowedTo from "../../../../shared/rbac";
import Constant from "../../../../../utils/constant/constant";
import moment from "moment";
import { LockOpenIcon } from "@heroicons/react/solid";
import { isGameLocked } from "../../../../../utils/studentHelper";
import ReactTooltip from "react-tooltip";
import ClassTeacherModel from "../../../../../model/teacher/classManagement/classTeacherModel";
import { getClassManagementUpgradePermission } from "../../../../shared/permissionHelper";
import UpGradeMessagePopup from "../../../../shared/upGradeMessagePopup";
import constant from "../../../../../utils/constant/constant";

interface ClassFeatureProps {
  handlePasswordCard: () => void;
  handleArchieveClass: () => void;
  handleArcadeOrAvatar: () => void;
  handleClassPassword: () => void;
  handleDeleteClass: () => void;
  handleUpdateClass: () => void;
  handleTransferStudent: () => void;
  handleImportStudentClassData: () => void;
  handleConnectGoogleClassroom: () => void;
  handleDisconnectGoogleClassroom: () => void;
  handleConnectCanvas: () => void;
  handleDisconnectCanvas: () => void;
  isArchived: boolean;
  isGoogleEnabled: boolean;
  isCanvasEnabled: boolean;
  isReadOnly: boolean;
  userContext?: UserContextState;
  higherGradeStudentCount: number;
  isGameLocked: boolean;
  lockStartTime?: string;
  lockEndTime?: string;
  primaryTeacher: ClassTeacherModel | undefined;
  isEditingEnabled: boolean;
}

const ClassFeature = (props: ClassFeatureProps) => {
  const timeFormat = (time) => {
    return moment(time, "hh:mm:ss").format("hh:mm A");
  };
  const [upgrade, setUpgrade] = useState(false);
  const [showUpgradePopup, setShowUpgradePopup] = useState<boolean>(false);
  const isLocked: boolean = isGameLocked(
    props.isGameLocked,
    props.lockStartTime ?? "",
    props.lockEndTime ?? ""
  );
  const arcadeLockMessage = isLocked
    ? `Game Arcade for this class is locked from ${timeFormat(
        props.lockStartTime
      )} - ${timeFormat(props.lockEndTime)}.`
    : "This feature can be used to lock the Game Arcade for your students during your class time.";

  useEffect(() => {
    checkUpgradeRequired();
  }, []);

  const checkUpgradeRequired = async () => {
    if (getClassManagementUpgradePermission(props.userContext?.roleId ?? 0)) {
      setUpgrade(true);
    } else {
      setUpgrade(false);
    }
  };

  const handlePasswordCard = () => {
    props.handlePasswordCard();
  };
  const handleArchieveClass = () => {
    props.handleArchieveClass();
  };
  const handleArcadeOrAvatar = () => {
    props.handleArcadeOrAvatar();
  };
  const handleClassPassword = () => {
    props.handleClassPassword();
  };
  const handleDeleteClass = () => {
    props.handleDeleteClass();
  };
  const handleTransferStudent = () => {
    props.handleTransferStudent();
  };
  const handleImportStudentClassData = () => {
    props.handleImportStudentClassData();
  };
  return (
    <Fragment>
      {showUpgradePopup && (
        <UpGradeMessagePopup
          togglePopup={setShowUpgradePopup}
          roleId={props.userContext?.roleId ?? 0}
          message={constant.UPGRADE_MESSAGE_SCHOOL_ACCOUNT}
        />
      )}
      <li className="relative overflow-hidden">
        <div className="flex flex-wrap gap-4 py-5 items-center">
          {props.userContext?.impersonatedUser === null && (
            <button
              className={
                (props.isEditingEnabled
                  ? "hover:text-white hover:bg-light-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3 transition ease-in-out duration-500 text-primary-violet border-primary-violet"
                  : "cursor-not-allowed text-gray-400 border-gray-300") +
                " bg-transparent border shadow-sm py-2 px-4 inline-flex items-center justify-center text-sm font-medium"
              }
              onClick={() => {
                if (props.isEditingEnabled) {
                  handlePasswordCard();
                }
              }}
            >
              <PasswordCardIcon className="w-4 opacity-70" />
              <span className="ml-2 text-sm">Password Card</span>
            </button>
          )}{" "}
          {props.isReadOnly === false && (
            <button
              type="button"
              disabled={props.isReadOnly}
              className="bg-transparent border border-primary-violet shadow-sm py-2 px-4 inline-flex items-center justify-center text-sm font-medium text-primary-violet hover:text-white hover:bg-light-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3 transition ease-in-out duration-500"
              onClick={() => {
                handleArchieveClass();
              }}
            >
              <ArchieveIcon className="w-4 opacity-70" />
              <span className="ml-2 text-sm">
                {props.isArchived ? "Unarchive Class" : "Archive Class"}
              </span>
            </button>
          )}
          {props.isReadOnly === false && (
            <button
              className={
                (props.isEditingEnabled
                  ? "hover:text-white hover:bg-light-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3 transition ease-in-out duration-500 text-primary-violet border-primary-violet"
                  : "cursor-not-allowed text-gray-400 border-gray-300") +
                " bg-transparent border shadow-sm py-2 px-4 inline-flex items-center justify-center text-sm font-medium"
              }
              onClick={() => {
                if (props.isEditingEnabled) {
                  handleClassPassword();
                }
              }}
            >
              <PasswordCardIcon className="w-4 opacity-70" />
              <span className="ml-2 text-sm">Class Password</span>
            </button>
          )}
          {props.isReadOnly === false && props.higherGradeStudentCount > 0 && (
            <button
              type="button"
              className={
                (props.isEditingEnabled
                  ? "hover:text-white hover:bg-light-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3 transition ease-in-out duration-500 text-primary-violet border-primary-violet"
                  : "cursor-not-allowed text-gray-400 border-gray-300") +
                " bg-transparent border shadow-sm py-2 px-4 inline-flex items-center justify-center text-sm font-medium"
              }
              onClick={() => {
                if (props.isEditingEnabled) {
                  handleArcadeOrAvatar();
                }
              }}
              title={arcadeLockMessage}
            >
              <LockOpenIcon className="w-5 opacity-70" />
              <span className="ml-2 text-sm">
                {isLocked ? "Unlock Arcade" : "Arcade Lock"}
              </span>
              <span className="inline-block align-middle"></span>
            </button>
          )}
          {props.userContext?.roleId === Constant.UserRoleId.SchoolAdmin &&
            !props.isGoogleEnabled &&
            !props.isCanvasEnabled && (
              <AllowedTo perform={Permissions.class_management_transferstudent}>
                {props.isReadOnly === false && (
                  <button
                    className={
                      (props.isEditingEnabled
                        ? "hover:text-white hover:bg-light-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3 transition ease-in-out duration-500 text-primary-violet border-primary-violet"
                        : "cursor-not-allowed text-gray-400 border-gray-300") +
                      " bg-transparent border shadow-sm py-2 px-4 inline-flex items-center justify-center text-sm font-medium"
                    }
                    onClick={() => {
                      if (props.isEditingEnabled) {
                        handleTransferStudent();
                      }
                    }}
                  >
                    <ArrowRight className="w-4 opacity-70" />
                    <span className="ml-2 text-sm">Transfer Students</span>
                  </button>
                )}
              </AllowedTo>
            )}
          {props.userContext?.roleId === Constant.UserRoleId.SchoolAdmin &&
            !props.isGoogleEnabled &&
            !props.isCanvasEnabled && (
              <AllowedTo perform={Permissions.class_management_transferstudent}>
                {props.isReadOnly === false && (
                  <button
                    className={
                      (props.isEditingEnabled
                        ? "hover:text-white hover:bg-light-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3 transition ease-in-out duration-500 text-primary-violet border-primary-violet"
                        : "cursor-not-allowed text-gray-400 border-gray-300") +
                      " bg-transparent border shadow-sm py-2 px-4 inline-flex items-center justify-center text-sm font-medium"
                    }
                    onClick={() => {
                      if (props.isEditingEnabled) {
                        handleImportStudentClassData();
                      }
                    }}
                  >
                    <ArrowRight className="w-4 opacity-70" />
                    <span className="ml-2 text-sm">Import Student Data</span>
                  </button>
                )}
              </AllowedTo>
            )}
          <AllowedTo perform={Permissions.class_management_delete}>
            {props.isReadOnly === false && (
              <p
                className="cursor-pointer"
                data-tip
                data-for="disableCoTeacherOption"
              >
                {(!props.primaryTeacher?.userId ||
                  props.primaryTeacher?.userId !==
                    props.userContext?.userId) && (
                  <ReactTooltip
                    id="disableCoTeacherOption"
                    class="bg-secondary-teal/10 z-50 "
                    effect="solid"
                    place="bottom"
                  >
                    <span>
                      This action can only be completed by the primary teacher.
                    </span>
                  </ReactTooltip>
                )}

                <div className="cursor-not-allowed">
                  <button
                    className={
                      "border border-red-500 shadow-sm py-2 px-4 inline-flex items-center justify-center text-sm font-medium text-red-500 hover:text-white hover:bg-red-400 hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3 transition ease-in-out duration-500 " +
                      (!props.primaryTeacher?.userId ||
                      props.primaryTeacher?.userId !== props.userContext?.userId
                        ? "bg-gray-50 opacity-40 pointer-events-none"
                        : "bg-transparent")
                    }
                    onClick={() => {
                      handleDeleteClass();
                    }}
                  >
                    <DeleteFillIcon className="w-4 opacity-70 " />
                    <span className="ml-2 text-sm">Delete Class</span>
                  </button>
                </div>
              </p>
            )}
          </AllowedTo>
          <>
            {(!props.isGoogleEnabled && (
              <button
                type="button"
                className={
                  (upgrade ? "opacity-50 " : "") +
                  (props.isEditingEnabled
                    ? "hover:text-white hover:bg-light-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3 transition ease-in-out duration-500 text-primary-violet border-primary-violet"
                    : "cursor-not-allowed text-gray-400 border-gray-300") +
                  "disabled:opacity-50 bg-transparent border shadow-sm py-2 px-4 inline-flex items-center justify-center text-sm font-medium"
                }
                onClick={() => {
                  if (props.isEditingEnabled) {
                    if (upgrade) {
                      setShowUpgradePopup(true);
                    } else {
                      props.handleConnectGoogleClassroom();
                    }
                  }
                }}
                disabled={props.isGoogleEnabled}
                title="This feature is used to connect the class to Google Classroom"
              >
                <GoogleClassroomIcon className="w-5 opacity-70" />
                <span className="ml-2 text-sm">
                  Connect to Google Classroom
                </span>
                <span className="inline-block align-middle"></span>
              </button>
            )) || (
              <button
                type="button"
                className={
                  (props.isEditingEnabled
                    ? "hover:text-white hover:bg-light-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3 transition ease-in-out duration-500 text-primary-violet border-primary-violet"
                    : "cursor-not-allowed text-gray-400 border-gray-300") +
                  " bg-transparent border shadow-sm py-2 px-4 inline-flex items-center justify-center text-sm font-medium"
                }
                onClick={() => {
                  if (props.isEditingEnabled) {
                    props.handleDisconnectGoogleClassroom();
                  }
                }}
                title="This feature is used to disconnect the class from Google Classroom"
              >
                <GoogleClassroomIcon className="w-5 opacity-70" />
                <span className="ml-2 text-sm">Disconnect</span>
                <span className="inline-block align-middle"></span>
              </button>
            )}
          </>
          {(!props.isCanvasEnabled && (
            <button
              type="button"
              className={
                (upgrade ? "opacity-50 " : "") +
                (props.isEditingEnabled
                  ? "hover:text-white hover:bg-light-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3 transition ease-in-out duration-500 text-primary-violet border-primary-violet"
                  : "cursor-not-allowed text-gray-400 border-gray-300") +
                "disabled:opacity-50 bg-transparent border shadow-sm py-2 px-4 inline-flex items-center justify-center text-sm font-medium"
              }
              onClick={() => {
                if (props.isEditingEnabled) {
                  if (upgrade) {
                    setShowUpgradePopup(true);
                  } else {
                    props.handleConnectCanvas();
                  }
                }
              }}
              title="This feature is used to connect the class to Canvas"
            >
              <CanvasIcon className="w-5 opacity-70" />
              <span className="ml-2 text-sm">Connect to Canvas</span>
              <span className="inline-block align-middle"></span>
            </button>
          )) || (
            <button
              type="button"
              className={
                (props.isEditingEnabled
                  ? "hover:text-white hover:bg-light-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3 transition ease-in-out duration-500 text-primary-violet border-primary-violet"
                  : "cursor-not-allowed text-gray-400 border-gray-300") +
                " bg-transparent border shadow-sm py-2 px-4 inline-flex items-center  justify-center text-sm font-medium"
              }
              onClick={() => {
                if (props.isEditingEnabled) {
                  props.handleDisconnectCanvas();
                }
              }}
              title="This feature is used to connect the class to Canvas"
            >
              <CanvasIcon className="w-5 opacity-70" />
              <span className="ml-2 text-sm">Disconnect</span>
              <span className="inline-block align-middle"></span>
            </button>
          )}
          {!props.isEditingEnabled && (
            <span className="text-sm font-semibold">
              Previous school year classes can only be Archived or Deleted.
            </span>
          )}
        </div>
      </li>
    </Fragment>
  );
};

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(ClassFeature);

import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { getFocusAreas } from "../../../../../api/student/studyPlanApi";
import { useHistory } from "react-router-dom";
import RouteConstant from "../../../../../utils/constant/routeConstant";
import constant from "../../../../../utils/constant/constant";
import { StarDarkIcon, StarIcon } from "../../../../../assets/icons";
import useTextToSpeech from "../../shared/textToSpeech/useTextToSpeech";
import { paramEncode } from "../../../../../utils/urlHelper";
import Loader from "../../../../shared/loader";
import { IStudyPlanFocusAreasStandard } from "../../../../../model/interface/studyPlanFocusAreas";
import { Configuration } from "../../../../../environment/setup";

function FocusAreas(props: any) {
  const { t } = useTranslation();
  const [recTopicsMastery, setRecTopicsSome] = useState<
    Array<IStudyPlanFocusAreasStandard>
  >([]);
  const [recTopicsCritical, setRecTopicsCritical] = useState<
    Array<IStudyPlanFocusAreasStandard>
  >([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { startSpeaking, stopSpeaking } = useTextToSpeech();
  const history = useHistory();
  var topicNumber = 1;

  useEffect(() => {
    getFocusAreasStandards();
  }, []);

  const getFocusAreasStandards = async () => {
    setIsLoading(true);
    try {
      const focusAreas = await getFocusAreas(
        props?.userContext?.userId ?? 0,
        props.subjectId
      );
      if (focusAreas && focusAreas.data) {
        setRecTopicsSome(
          focusAreas.data.filter(
            (ds) =>
              ds.standardStatus ===
              constant.FocusAreaStatusType.APPROACHINGMASTERY
          )
        );
        setRecTopicsCritical(
          focusAreas.data.filter(
            (ds) => ds.standardStatus === constant.FocusAreaStatusType.CRITICAL
          )
        );
      } else {
        setRecTopicsSome([]);
        setRecTopicsCritical([]);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const startAssignment = (standard: IStudyPlanFocusAreasStandard) => {
    if (
      standard.assignmentStatusId === constant.AssignmentStatusId.INPROGRESS
    ) {
      history.push(
        RouteConstant.KindergartenStudentRoutes.StudyPlan.replace(
          ":standardId",
          encodeURI(btoa(standard.standardId.toString()))
        )
          .replace(
            ":standardName",
            encodeURI(paramEncode(standard.standardName))
          )
          .replace(":gameId", encodeURI(btoa("0")))
          .replace(":rocketId", encodeURI(btoa("0")))
          .replace(":blasterId", encodeURI(btoa("0")))
          .replace(":blasterType", encodeURI(btoa("0")))
          .replace(":subjectId", encodeURI(btoa(props.subjectId.toString())))
          .replace(":isRecommendedStandard", encodeURI(btoa("true"))) +
          "?s=" +
          props.subjectName +
          "&c=" +
          props.contentAreaId
      );
    } else {
      var route = RouteConstant.KindergartenStudentRoutes.GameChoose.replace(
        ":standardId",
        encodeURI(btoa(standard.standardId.toString()))
      )
        .replace(":standardName", encodeURI(paramEncode(standard.standardName)))
        .replace(
          ":mode",
          encodeURI(btoa(constant.GameChooseMode.KinderGartenStudyPlan))
        )
        .replace(":subjectId", encodeURI(btoa(props.subjectId.toString())))
        .replace(":isRecommendedStandard", encodeURI(btoa("true")));

      history.push(route);
    }
  };

  return (
    <Fragment>
      {isLoading && <Loader />}
      <h3 className="py-4 px-7 bg-[#4e4b75] rounded-t-lg tracking-wider font-Didact relative text-lg  lg:text-xl xl:text-2xl text-white hover:shadow-2xl flex items-center">
        {t("StudyType.FocusAreas")}
      </h3>
      <div className="bg-[#403d6a] rounded-b-lg">
        {recTopicsMastery.length > 0 && (
          <div className="mt-6 md:mt-0">
            <div>
              <h3 className="py-4 px-8 relative text-md lg:text-lg xl:text-xl text-white">
                {t("StudyPlan.ApproachingMastery")}
              </h3>
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 gap-12 text-center flex flex-col mb-6 p-4">
                {recTopicsMastery?.map((topic, index) => (
                  <div
                    key={index}
                    className="bg-white group rounded-lg hover:shadow-2xl cursor-pointer border-8 border-[#FFDB3A] hover:bg-[#fcf0b8] px-2"
                    onClick={() => {
                      startAssignment(topic);
                    }}
                    onMouseEnter={() => {
                      startSpeaking(
                        topic.standardName,
                        (props.profile?.plLanguageId ?? 1) === 2 ? 2 : 1
                      );
                    }}
                    onMouseLeave={() => {
                      stopSpeaking();
                    }}
                  >
                    <label className="flex-shrink-0 text-white text-stroke font-semibold text-2xl cursor-pointer h-14 w-14 bg-[#FFDB3A] hover:opacity-90 mt-4 rounded-full flex inline-block m-4 mb-0 items-center justify-center">
                      {topicNumber++}
                    </label>
                    <div className="px-4 py-2 text-base m-auto w-40 mt-4">
                      <img
                        className="w-full"
                        src={`${Configuration.S3bucketImagePath}/images/mastertopicicon/1%20M%200620193414043447.png`}
                        alt=""
                      />
                    </div>
                    <div className="flex gap-2 mt-6 items-center justify-center">
                      {Array.from({ length: 3 }).map((ds, i) => (
                        <>
                          {i + 1 <= topic.starEarned && (
                            <StarDarkIcon className="w-9" />
                          )}
                          {i + 1 > topic.starEarned && (
                            <StarIcon className="w-9" />
                          )}
                        </>
                      ))}
                    </div>
                    <p className="group-hover:font-bold group-hover:text-black group-hover:scale-110 transform transition-all duration-300 font-Didact my-6  text-lg tracking-wide lg:text-xl xl:text-2xl  text-center items-center">
                      {topic.standardName}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
        {recTopicsCritical.length > 0 && (
          <div className="pt-6 md:pt-0">
            <Fragment>
              <h3 className="py-4 px-8 relative text-md lg:text-lg xl:text-xl text-white">
                {t("Diagnostics.Critical")}
              </h3>
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 gap-12 text-center flex flex-col mb-6 p-4 pb-8">
                {recTopicsCritical?.map((topic, index) => (
                  <div
                    key={index}
                    className="bg-white group rounded-lg hover:shadow-2xl cursor-pointer border-8 border-[#E53F71] hover:bg-[#FFE3EB] px-2"
                    onClick={() => {
                      startAssignment(topic);
                    }}
                    onMouseEnter={() => {
                      startSpeaking(
                        topic.standardName,
                        (props.profile?.plLanguageId ?? 1) === 2 ? 2 : 1
                      );
                    }}
                    onMouseLeave={() => {
                      stopSpeaking();
                    }}
                  >
                    <label className="flex-shrink-0 text-white font-semibold text-2xl cursor-pointer h-14 w-14 bg-[#E53F71] hover:opacity-90 mt-4 rounded-full flex inline-block m-4 mb-0 items-center justify-center">
                      {topicNumber++}
                    </label>
                    <div className="px-4 py-2 text-base m-auto w-40 mt-4">
                      <img
                        className="w-full"
                        src={`${Configuration.S3bucketImagePath}/images/1%20M%200620193414043447.png`}
                        alt=""
                      />
                    </div>
                    <div className="flex gap-2 mt-6 items-center justify-center">
                      {Array.from({ length: 3 }).map((ds, i) => (
                        <>
                          {i + 1 <= topic.starEarned && (
                            <StarDarkIcon className="w-9" />
                          )}
                          {i + 1 > topic.starEarned && (
                            <StarIcon className="w-9" />
                          )}
                        </>
                      ))}
                    </div>
                    <p className="group-hover:font-bold group-hover:text-black group-hover:scale-110 transform transition-all duration-300 font-Didact my-6  text-lg tracking-wide lg:text-xl xl:text-2xl  text-center items-center">
                      {topic.standardName}
                    </p>
                  </div>
                ))}
              </div>
            </Fragment>
          </div>
        )}

        {recTopicsCritical.length === 0 &&
          recTopicsMastery.length === 0 &&
          !isLoading && (
            <h1 className="py-4 px-7 text-lg text-white text-center mb-1 inline-block">
              {t("StudyPlan.NoFocusAreasActivities")}
            </h1>
          )}
      </div>
    </Fragment>
  );
}

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(FocusAreas);

import { XIcon } from "@heroicons/react/outline";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { AddTrialSubscriptionRequest } from "../../api/account/accountApi";
import Profile from "../../model/users/profile";
import { UserContextState } from "../../redux/actions/userContextAction";
import { SubscriptionRequestType } from "../../types/type";
import { InformationIcon } from "../../assets/icons";
import { Fragment, useEffect, useState } from "react";
import Loader from "./loader";

interface ITrialSubscriptionRequestPopup {
  showPopUp: (show: boolean) => void;
  subjects: string[];
  subjectIds: number[];
  requestType: SubscriptionRequestType;
  schoolId: number | undefined;
  userContext?: UserContextState;
  profile?: Profile;
}
function TrialSubscriptionRequest(props: ITrialSubscriptionRequestPopup) {
  const [requestText, setRequestText] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  useEffect(() => {
    var text = "Hello, I would like to request a trial for the following: \n\n";
    var index: number = 1;
    props.subjects.forEach((sub) => {
      text = text + index.toString() + ". " + sub + "\n";
      index++;
    });
    setRequestText(text);
  }, [props.subjects]);

  function sendSubscriptionRequest() {
    setShowLoader(true);
    AddTrialSubscriptionRequest({
      requestText: requestText,
      subjectIds: props.subjectIds,
      subscriptionRequestTypeId: props.requestType,
      schoolId: props.schoolId,
    })
      .then((d) => {
        toast.info("Subscription trial request sent successfully");
        setShowLoader(false);
        props.showPopUp(false);
      })
      .catch((error) => {
        toast.error(error.response.data);
        setShowLoader(false);
        props.showPopUp(false);
      });
  }

  return (
    <Fragment>
      {showLoader && <Loader></Loader>}
      <div className="fixed inset-0 z-40 flex items-center bg-black bg-opacity-50 sm:items-center sm:justify-center">
        <div
          className="w-full flex flex-col justify-between overflow-hidden h-auto max-h-80percent bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:max-w-2xl"
          role="dialog"
        >
          <header>
            {/* Header */}
            <div className="px-4 py-4 bg-gray-50">
              <div className="flex items-start justify-between space-x-3">
                <div className="space-y-1">
                  <div className="flex">
                    <div className="flex-shrink-0 current-fill text-blue-400 py-1">
                      <InformationIcon className="h-8" />
                    </div>
                    <span className="px-2 py-2 font-medium">
                      You are requesting a trial for the following{" "}
                      {props.subjects.length == 1 ? "subject:" : "subjects:"}
                    </span>
                  </div>
                </div>
                <div className="h-7 flex items-center">
                  <button
                    type="button"
                    className="text-gray-400 hover:text-gray-500"
                    onClick={() => props.showPopUp(false)}
                  >
                    <span className="sr-only">Close panel</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
          </header>

          <div className={`w-full relative overflow-auto px-4`}>
            {/* <!-- Divider container -->  */}
            <div className="p-3 sm:py-0 sm:space-y-0 sm:divide-y sm:divide-gray-200 text-sm text-gray-700 dark:text-gray-400">
              <div>
                <div className="mb-4">
                  <div className="grid grid-cols-1 gap-6 flex items-end mt-3">
                    <div
                      className="col-span-12 sm:col-span-4 xl:col-span-3"
                      style={{
                        maxHeight: "150px",
                        overflowY: "auto",
                        padding: "10px",
                        border:
                          props.subjects.length <= 5
                            ? "none"
                            : "1px solid #ccc",
                      }}
                    >
                      {props.subjects.map((subject, index) => (
                        <div className="font-medium" key={index}>{`${
                          index + 1
                        }. ${subject}`}</div>
                      ))}
                    </div>

                    <span className="font-medium">
                      The approval process is typically handled within 1
                      business day. Are you sure you want to submit this
                      request?
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <!-- Action Buttons -->  */}
          <footer className="flex flex-col items-center justify-end px-6 py-4 sm:flex-row bg-gray-50 dark:bg-gray-800">
            <div className="flex-shrink-0">
              <div className="space-x-3 flex justify-end">
                <button
                  className="disabled:opacity-50 align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 rounded-lg text-sm text-white border border-transparent bg-primary-blue focus:shadow-outline-purple w-full sm:w-auto"
                  type="button"
                  onClick={sendSubscriptionRequest}
                >
                  Ok
                </button>
                <button
                  className="align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 rounded-lg text-sm text-gray-600 border-gray-300 border dark:text-gray-400 focus:outline-none active:bg-transparent hover:border-gray-500 focus:border-gray-500 active:text-gray-500 focus:shadow-outline-gray w-full sm:w-auto"
                  type="button"
                  onClick={() => {
                    props.showPopUp(false);
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </Fragment>
  );
}

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
};

export default connect<{}, {}, {}>(
  mapStateToProps,
  {}
)(TrialSubscriptionRequest);

import { createRef, Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import LearnosityPlayer from "./learnosityPlayer";
import { useTranslation } from "react-i18next";
import { IQuestionAnswerProps } from "../../../../model/interface/questionAnswer/questionAnswer";
import { GetTTSSetting } from "../../../../utils/studentHelper";
import Constant from "../../../../utils/constant/constant";
import { IsLiftOff } from "../../../../utils/helper";
import { LearnosityVideoPreview } from "./learnosityVideoPreview";
import { GetActivitySessionCounter } from "../../../../api/student/activitySession/activitySessionQuestions";
import { updateDuration } from "../../../../api/student/studentActivityApi";
import {
  getStudentProfile,
  updateFontSize,
} from "../../../../api/student/studentProfile";

const theme = {
  EL: {
    bgPanel: "bg-gray-50",
    questionFeedback: "",
    questionFeedbackText: "",
    contentPadding: "sm:px-6 lg:px-8",
    textColor: "text-gray-700",
  },
  K1: {
    bgPanel: "bg-gray-50",
    questionFeedback: "",
    questionFeedbackText: "text-gray-700",
    contentPadding: "sm:px-6 lg:px-8",
    textColor: "text-gray-700",
  },
  MS: {
    bgPanel: "",
    questionFeedback: "",
    questionFeedbackText: "text-white",
    contentPadding: "sm:px-1 lg:px-3",
    textColor: "text-white",
  },
};

const QuestionAnswer = (props: IQuestionAnswerProps) => {
  const [itemId, setItemId] = useState<string>("");
  const [sessionCounter, setSessionCounter] = useState<number>(0);
  const themeName = props.studentType;
  const { t } = useTranslation();
  const questionRef = createRef<HTMLSpanElement>();
  const [showTextToSpeech, setShowTextToSpeech] = useState<boolean | undefined>(
    undefined
  );
  const [fontSize, setFontSize] = useState<string>();
  const [liftOfHierachyName, setLiftOfHierachyName] = useState<string>("");
  const [showFontSize, setShowFontSize] = useState<boolean>(false);

  useEffect(() => {
    if (props.adaptiveData) {
      let data = props.adaptiveData.steps.filter(
        (x) => x.id?.split("-")[0] === itemId?.split("-")[0]
      );
      if (data && data.length > 0) {
        setLiftOfHierachyName(data[0].hierarchyName);
      }
    }
  }, [itemId]);

  useEffect(() => {
    //Get activity session counter and increment it with 1 for this activity
    GetActivitySessionCounter(props.activitySessionId).then((d) => {
      setSessionCounter(d.data + 1);
    });
    if (props.profile) {
      getStudentProfile(
        props.profile?.userId,
        props.profile?.gradeId ? props.profile?.gradeId : 0
      ).then((res) => {
        if (res.data) setFontSize(res.data.fontSize);
        setShowFontSize(true);
      });
      if (IsLiftOff()) {
        setShowTextToSpeech(props.profile?.isLOTTSActive ?? false);
      } else if (!IsLiftOff()) {
        if (
          props.activityType === Constant.ActivityType.ASSIGNMENT &&
          props.isTTSActive != null
        ) {
          setShowTextToSpeech(props.isTTSActive);
        } else {
          getTTSSetting();
        }
      } else {
        setShowTextToSpeech(true);
      }
    }
  }, [props.profile]);

  function onFocused() {
    if (props.onFocused)
      props.onFocused(document.getElementById("questionId")?.innerText);
  }
  function onUnFocused() {
    if (props.onUnfocused)
      props.onUnfocused(document.getElementById("questionId")?.innerText);
  }

  async function getTTSSetting() {
    if (
      ((props.subjectId && props.subjectId > 0) ||
        (props.contentAreaId && props.contentAreaId > 0)) &&
      props.profile
    ) {
      var showTextToSpeech = await GetTTSSetting(
        props.subjectId ?? 0,
        props.profile,
        props.contentAreaId ?? 0
      );
      setShowTextToSpeech(showTextToSpeech);
    } else {
      setShowTextToSpeech(true);
    }
  }

  async function onExit(durationInSeconds: number) {
    await updateDuration(props.activitySessionId, durationInSeconds);
  }
  async function onUpdateFontSize(fontSize: string) {
    await updateFontSize(props.profile?.userId ?? 0, fontSize);
  }

  return (
    <Fragment>
      <section className="min-w-0 w-full">
        <div
          className={` ${theme[themeName]?.contentPadding}  py-1 relative my-4`}
        >
          <div className="min-w-0 block lg:col-span-12 xl:col-span-12 w-full">
            <div
              className={` ${theme[themeName]?.bgPanel}  w-full rounded-2xl p-5`}
            >
              <div className="flex justify-between items-center">
                <div className="w-full mb-2">
                  <div className="relative flex justify-between items-center">
                    <div className="flex flex-col">
                      <div className="flex items-center">
                        <label
                          className={`${theme[themeName]?.textColor} ml-0.5 font-semibold`}
                        >
                          {props.title} {liftOfHierachyName}
                        </label>
                      </div>
                      <div className="flex items-center">
                        <label
                          className={`${theme[themeName]?.textColor} ml-0.5`}
                        >
                          {t("StudentResponse.QuestionId")} :{" "}
                          <span id="questionId" ref={questionRef}>
                            {itemId?.split("-")[0]}
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col md:flex-row w-full mt-2 space-x-4">
                <div className="bg-white w-full rounded-2xl shadow-lg">
                  {sessionCounter > 0 &&
                    showTextToSpeech !== undefined &&
                    showFontSize &&
                    !props.isVideoPreview && (
                      <LearnosityPlayer
                        activityType={props.activityType}
                        activityId={props.activityId}
                        teacherActivityId={props.teacherActivityId}
                        showActivity={props.showActivity}
                        showQuestions={props.showQuestions}
                        questionIds={props.questionIds}
                        hasSections={props.hasSections}
                        hasItemBranching={props.hasItemBranching}
                        isAlienExplanation={props.isAlienExplanation}
                        isGameChoosen={props.isGameChoosen}
                        studentId={props.studentId}
                        resumeActivity={props.resumeActivity}
                        setItemId={setItemId}
                        sessionId={props.sessionId}
                        adaptiveFilter={props.adaptiveFilter}
                        OnSubmit={props.OnSubmit}
                        checkAnswer={false}
                        onExitRedirectUrl={props.onExitRedirectUrl}
                        studentType={props.studentType}
                        calculatorReferenceId={
                          props.calculatorReferenceId &&
                          props.calculatorReferenceId === "None"
                            ? undefined
                            : props.calculatorReferenceId
                        }
                        activitySubType={props.activitySubType}
                        questionRetry={props.questionRetry}
                        timeLimitInSeconds={
                          props.timeLimitInMinutes &&
                          props.timeLimitInMinutes > 0
                            ? props.timeLimitInMinutes * 60
                            : 0
                        }
                        cannedTestId={props.cannedTestId}
                        showExplanation={props.showExplanation}
                        setQuestionsAttemptedTillTimeLimit={
                          props.setQuestionsAttemptedTillTimeLimit ?? undefined
                        }
                        showTextToSpeech={showTextToSpeech}
                        activitySessionId={props.activitySessionId ?? 0}
                        language={props.language}
                        showTextExpalanation={
                          props.showTextExpalanation ?? false
                        }
                        shuffleItems={props.shuffleItems ?? false}
                        showScored100PercentVideo={
                          props.showScored100PercentVideo ?? true
                        }
                        eliminateAnswerForSingleMCQ={
                          props.eliminateAnswerForSingleMCQ ?? false
                        }
                        onUnfocused={onUnFocused}
                        onFocused={onFocused}
                        subjectId={props.subjectId}
                        questionsState={props.questionsState}
                        hideSaveBtn={props.hideSaveBtn ?? false}
                        abandonSession={props.abandonSession ?? undefined}
                        sessionCounter={sessionCounter}
                        OnExit={onExit}
                        allowPartialSubmit={
                          props.activitySubType ===
                            Constant.ActivitySubTypes.ASSESSMENT ||
                          props.activitySubType ===
                            Constant.ActivitySubTypes.PRACTICETEST ||
                          (props.activityType ===
                            Constant.ActivityType.ASSIGNMENT &&
                            props.activitySubType ===
                              Constant.ActivitySubTypes.GENERATEDTEST)
                        }
                        fontSize={fontSize}
                        OnUpdateFontSize={onUpdateFontSize}
                      ></LearnosityPlayer>
                    )}
                  {props.isVideoPreview && (
                    <LearnosityVideoPreview
                      itemsIds={props.questionIds ?? []}
                      sessionId={props.sessionId}
                      setItemId={setItemId}
                      activityType={props.activityType}
                      studentType={props.studentType}
                      studentId={props.studentId}
                      onExitRedirectUrl={props.onExitRedirectUrl}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(QuestionAnswer);

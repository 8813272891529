import { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { IBreadcrumb } from "../../../../../model/common/breadcrumb";
import { default as Constant } from "../../../../../utils/constant/constant";
import Breadcrumb from "../../../teacher/shared/breadcrumb";
import RouteConstant from "../../../../../utils/constant/routeConstant";
import { useTranslation } from "react-i18next";
import { Select } from "@windmill/react-ui";
import { ISchool } from "../../../../../model/interface/school";
import { getSchoolByDistrictId } from "../../../../../api/teacher/school";
import { ISelectElement } from "../../../../../model/interface/selectElement";
import DatePicker from "react-datepicker";
import PageSize from "../../../../shared/pagination/pageSize";
import Pagination from "../../../../shared/pagination/pagination";

import {
  getLiftoffActivityReport,
  getAllClassesBySubject,
  exportLiftoffActivityReport,
} from "../../../../../api/teacher/teacher";
import SelectGroup from "react-select";
import Asterisk from "../../../../shared/asterisk";
import NoData from "../../../../shared/noData";
import Loader from "../../../../shared/loader";
import moment from "moment";
import {
  DEFAULT_PAGE_NUMBER,
  DEFAULT_PAGE_SIZE,
} from "../../../../../utils/pagingConstant";
import {
  liftoffDiagnosticResultRedirect,
  myGalaxyStandardResultRedirect,
} from "../studentResultRedirection";
import { getClassesBySchoolAccountId } from "../../../../../api/manageUser/class";
import userApi from "../../../../../api/userApi";
import { getAllGrades } from "../../../../../api/gradeApi";
import { getAssociatedSubjects } from "../../../../../api/teacher/assignment";
import { SortingOrder } from "../../../../../types/type";
import SortTableHeader from "../../../../shared/sortTableHeader";
import ClassDropDown from "../../shared/classDropDown";
import ITeacherClass from "../../../../../model/interface/teacherclass";
import { getEndDate, getStartDate } from "../../../../../utils/dateHelper";
import { SchoolYearDropDown } from "../../../schoolYearDropdown";
import { ISchoolYear } from "../../../../../model/teacher/schoolYear";
import Moment from "moment";
import { getScoreLabel } from "../../../../../utils/scoreHelper";
interface ISubjects {
  subjectId: number;
  name: string;
}

function LiftoffActivityReport(props: any) {
  const { t } = useTranslation();
  const [isDistrictUser] = useState<boolean>(
    props.userContext.roleId === Constant.UserRoleId.District ? true : false
  );
  //let defaultDate: Date = Configuration.getSchoolStartDate();
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [isLoadingClasses, setIsLoadingClasses] = useState<boolean>(false);
  const [pageNumber, setPageNumber] = useState(DEFAULT_PAGE_NUMBER);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [resetPageNumber, setResetPageNumber] = useState<boolean>(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [selectedSchool, setSelectedSchool] = useState<number>(0);
  const [schools, setSchools] = useState<ISchool[]>();
  const [classes, setClasses] = useState<ITeacherClass[]>();
  const [subjects, setSubjects] = useState<ISubjects[]>();
  const [grades, setGrades] = useState<Array<ISelectElement>>([]);
  const [selectedGrade, setSelectedGrade] = useState<number>(0);
  const [selectedClasses, setSelectedClasses] = useState<Array<ISelectElement>>(
    [{ label: "All Classes", value: "0" }]
  );
  const [selectedSubjects, setSelectedSubjects] = useState<
    Array<ISelectElement>
  >([]);
  const [activityReport, setActivityReport] = useState<any>();
  const [selectedActivities, setSelectedActivities] = useState<
    Array<ISelectElement>
  >([]);
  const [selectedStudents, setSelectedStudents] = useState<
    Array<ISelectElement>
  >([]);
  const [students, setStudents] = useState<Array<any>>();
  const [selectedStartDate, setSelectedStartDate] = useState<Date | null>(null);
  const [selectedEndDate, setSelectedEndDate] = useState<Date | null>(null);
  const [isInvalidStartDate, setIsInvalidStartDate] = useState<boolean>(false);
  const [isInvalidEndDate, setIsInvalidEndDate] = useState<boolean>(false);
  const [activeSortColumn, setActiveSortColumn] = useState("");
  const [orderType, setOrderType] = useState<SortingOrder>("desc");
  const nonApplicableGrades: number[] = [1, 2];
  const defaultActivities: number[] = [7, 8];

  const [mintDate, setMinDate] = useState<Date>(new Date());
  const [maxDate, setMaxDate] = useState<Date>(new Date());
  const [selectedSchoolAccountId, setSelectedSchoolAccountId] =
    useState<number>(0);
  const [schoolYearData, setSchoolYearData] = useState<Array<ISchoolYear>>();
  const [selectedSchoolYearId, setSelectedSchoolYearId] = useState<number>();
  const [resetSchoolYear, setResetSchoolYear] = useState<boolean>(false);
  const activities: any = [
    {
      label: "All Activities",
      value: 0,
    },
    {
      label: "Liftoff Diagnostics",
      value: 7,
    },
    {
      label: "My Galaxies",
      value: 8,
    },
  ];

  const breadcrumbItems = () => {
    const items: Array<IBreadcrumb> = [
      { name: t("Breadcrumb.Home"), url: RouteConstant.ROUTE_DASHBOARD },
      { name: t("Breadcrumb.Reports"), url: RouteConstant.REPORTS },
      { name: t("Breadcrumb.LiftoffActivityReport"), url: "" },
    ];

    return items;
  };

  useEffect(() => {
    getGrades();
  }, []);

  useEffect(() => {
    if (selectedSchoolYearId) {
      setClasses([]);
      setStudents([]);
      setSelectedClasses([{ label: "All Classes", value: "0" }]);
      setSelectedStudents([]);
      handleDateFilter(selectedSchoolYearId);
      setResetSchoolYear(false);
      getSchools();
    }
  }, [selectedSchoolYearId]);
  useEffect(() => {
    if (props.classId)
      getStudents(getSchoolAccountId(schools, selectedSchool), props.classId);
  }, [props.classId]);

  useEffect(() => {
    setPageNumber(DEFAULT_PAGE_NUMBER);
    setResetPageNumber(!resetPageNumber);
  }, [orderType, activeSortColumn]);

  const getSchools = async (subjectId = 0) => {
    const resp = await getSchoolByDistrictId(
      props.userContext?.districtId ?? 0,
      subjectId
    );
    if (resp?.data?.length) {
      let schooleResult: any = [];
      resp?.data?.map((school: any) => {
        if (!schooleResult.find((s) => s.schoolId === school.schoolId))
          schooleResult.push(school);
      });
      setSchools(schooleResult);
      if (!isDistrictUser) {
        setSelectedSchool(props.userContext.schoolId);
        getSubjects(props.userContext.schoolId);
        getClasses(
          props.userContext.schoolId,
          getSchoolAccountId(schooleResult, props.userContext.schoolId)
        );
        getStudents(
          getSchoolAccountId(schooleResult, props.userContext.schoolId)
        );
      } else setSelectedSchool(0);
    }
  };

  const getSubjects = (schoolId: number) => {
    getAssociatedSubjects(
      props.userContext.userId,
      Constant.SUBJECTTYPE.LIFTOFF,
      schoolId
    ).then((response) => {      
      if (response?.data?.length) {
        const options: ISubjects[] = createSubjectOptions(response?.data);    
        setSubjects(options);
      } else {
        setSubjects([]);
      }
    });
  };

  function handleSchoolYearLoaded(currentSchoolYearId?: number) {
    handleSchoolYearChange(currentSchoolYearId);
  }
  function handleSchoolYearChange(schoolYearId?: number) {
    setSelectedSchoolYearId(schoolYearId);
  }
  function handleSchoolYearData(schoolYearData: Array<ISchoolYear>) {
    setSchoolYearData(schoolYearData);
  }

  function handleDateFilter(schoolYearId?: number) {
    let selectedSchoolYear = schoolYearData?.filter(
      (s) => s.schoolYearId == schoolYearId
    )[0];
    let minDate = selectedSchoolYear
      ? Moment(
          selectedSchoolYear.startDate.toString().split("T")[0],
          "YYYY-MM-DD"
        ).toDate()
      : new Date();

    let maxDate = selectedSchoolYear
      ? Moment(
          selectedSchoolYear.startDate.toString().split("T")[0],
          "YYYY-MM-DD"
        )
          .add(1, "year")
          .subtract(1, "day")
          .toDate()
      : new Date();
    if (maxDate > new Date()) {
      maxDate = new Date();
    }
    setMinDate(minDate);
    setMaxDate(maxDate);
    setSelectedStartDate(getStartDate(minDate, true));
    setSelectedEndDate(getEndDate(maxDate, true));
  }
  const getClasses = async (schoolId?: number, schoolAccountId?: number) => {
    try {
      setIsLoadingClasses(true);
      if (isDistrictUser) {
        const resp = await getClassesBySchoolAccountId(
          schoolAccountId,
          selectedSchoolYearId ?? props.profile.currentSchoolYear.schoolYearId
        );
        if (resp?.data?.length) {
          const options: Array<ITeacherClass> = resp.data.map((values: any) => {
            return {
              classId: values?.classId,
              displayName: values?.displayName,
              teacherFirstName: values?.primaryTeacherFirstName,
              teacherLastName: values?.primaryTeacherLastName,
              isAdditionalClass: true,
              isArchived: values?.isArchived,
              schoolYearId:
                selectedSchoolYearId ??
                props.profile.currentSchoolYear.schoolYearId,
            };
          });
          setClasses(options);
        }
      } else {
        const resp = await getAllClassesBySubject(
          props.userContext.userId,
          props.userContext.roleId,
          null,
          selectedSchoolYearId ?? props.profile.currentSchoolYear.schoolYearId,
          !isDistrictUser ? props.userContext.schoolId : schoolId
        );
        if (resp?.data?.data?.length) {
          setClasses(resp?.data?.data);
        } else {
          setClasses([]);
        }
      }
    } finally {
      setIsLoadingClasses(false);
    }
  };

  const getGrades = async () => {
    const resp = await getAllGrades();
    if (resp?.data?.length) {
      let gradeList: Array<ISelectElement> = [
        {
          label: "All Grades",
          value: "0",
        },
      ];
      resp?.data.forEach((grade) => {
        if (
          !gradeList.find((sl) => sl.value === grade.gradeId.toString()) &&
          nonApplicableGrades.indexOf(grade.gradeId) === -1
        ) {
          gradeList.push({
            label: grade.name,
            value: grade.gradeId.toString(),
          });
        }
      });
      setGrades(gradeList);
    }
  };

  const createSubjectOptions = (data): ISubjects[] => {
    const options: ISubjects[] = [
      {
        name: "All Subjects",
        subjectId: 0,
      },
    ];
    data.map((value: any) => {
      options.push({
        name: value?.name,
        subjectId: value?.subjectId,
      });
    });
    return options;
  };

  const formatDuration = (durationInSeconds: number): string => {
    var date = new Date(0);
    date.setSeconds(durationInSeconds); // specify value for SECONDS here
    var timeString = date.toISOString().substring(11, 19);
    return timeString;
  };

  const getStudents = async (schoolAccountId?: number, classId?: number) => {
    setSelectedStudents([]);
    setStudents([]);
    var response = userApi.GetLiftoffStudents(
      selectedSchoolYearId ?? props.profile.currentSchoolYear.schoolYearId,
      schoolAccountId,
      classId
    );
    response?.then((res) => {
      if (res?.data?.length) {
        const students: Array<ISelectElement> = [
          { label: "All Students", value: "0" },
        ];
        res.data.map((value: any) => {
          students.push({
            label:
              value?.lastName +
              ", " +
              value?.firstName +
              (value.studentId ? " (" + value.studentId + ")" : ""),
            value: value?.userId,
          });
        });
        setStudents(students);
      } else {
        setStudents([]);
      }
    });
  };

  const handleSchoolChange = (schoolId: any) => {
    setSelectedSchool(parseInt(schoolId));
    setSelectedGrade(0);
    setSelectedSubjects([]);
    setSelectedClasses([]);
    setSelectedStudents([]);
    setSelectedActivities([]);
    if (schoolId) {
      getSubjects(schoolId);
      getClasses(schoolId, getSchoolAccountId(schools, schoolId));
      getStudents(getSchoolAccountId(schools, schoolId));
      setSelectedSchoolAccountId(
        schools?.filter((s) => s.schoolId === Number(schoolId))[0]
          ?.schoolAccountId ?? 0
      );
    }
  };

  const getSchoolAccountId = (schools: any, schoolId?: number) => {
    var school: any = schools?.find((s) => s.schoolId === Number(schoolId));
    return school?.schoolAccountId;
  };
  const handleSubjectChange: any = (e: any) => {
    if (e && e.length > 0) {
      if (e[e.length - 1].value != 0) {
        setSelectedSubjects(e);
      } else {
        setSelectedSubjects([]);
      }
    } else {
      setSelectedSubjects([]);
    }
  };

  const handleClassChange: any = (e: any) => {
    if (e && e.length > 0) {
      if (e[e.length - 1].value != 0) {
        setSelectedClasses(e);
      } else {
        setSelectedClasses([]);
      }
    } else {
      setSelectedClasses([]);
    }
  };

  const handleStudentChange: any = (e: any) => {
    if (e && e.length > 0) {
      if (e[e.length - 1].value != 0) {
        setSelectedStudents(e);
      } else {
        setSelectedStudents([]);
      }
    } else {
      setSelectedStudents([]);
    }
  };

  const handleActivityChange: any = (e: any) => {
    if (e && e.length > 0) {
      if (e[e.length - 1].value != 0) {
        setSelectedActivities(e);
      } else {
        setSelectedActivities([]);
      }
    } else {
      setSelectedActivities([]);
    }
  };

  const handleGradeOnChange = (grade: ISelectElement) => {
    setSelectedGrade(Number(grade.value));
  };

  const findGradeByValue: any = () => {
    return grades.find((cl) => cl.value === selectedGrade.toString());
  };

  const onPageChange = (pageNo) => {
    setPageNumber(pageNo);
  };

  const onPageSizeChange = (pageSize) => {
    setPageNumber(DEFAULT_PAGE_NUMBER);
    setPageSize(pageSize);
    setResetPageNumber(true);
  };

  const getList = (selectedItems: any) => {
    let ids: any = [];
    if (selectedItems) {
      if (selectedItems.length == 1 && selectedItems[0].value == "0") {
        ids = undefined;
      } else {
        selectedItems.forEach((cs) => {
          ids.push(Number(cs.value));
        });
      }
    }
    return ids;
  };
  
  const getPercentage = (pointsEarned: number, pointsPossible: number) => {

    if(!pointsPossible) return 0;

    return ((pointsEarned / pointsPossible) * 100).toFixed();
  };


  const handleSortRecords = (
    _data: any,
    columnName: string,
    sortType: SortingOrder
  ) => {
    setActiveSortColumn(columnName);
    setOrderType(sortType);
  };

  const resetReport = () => {
    setResetSchoolYear(true);
    setActivityReport(null);
    setSelectedSchool(0);
    setSelectedGrade(0);
    setSelectedSubjects([]);
    setSelectedClasses([]);
    setSelectedStudents([]);
    setSelectedActivities([]);
    //setSelectedStartDate(defaultDate);
    setSelectedEndDate(new Date());
    if (!isDistrictUser) {
      setSelectedSchool(props.userContext.schoolId);
    }
  };

  const getReport = async () => {
    setShowLoader(true);
    const resp = await getLiftoffActivityReport(
      selectedSchool,
      getList(selectedSubjects),
      props.classId != undefined ? [props.classId] : getList(selectedClasses),
      selectedGrade === 0 ? undefined : selectedGrade,
      getList(selectedStudents),
      selectedActivities?.length && selectedActivities.length > 0
        ? getList(selectedActivities)
        : defaultActivities,
      selectedStartDate !== null ? getStartDate(selectedStartDate, true) : null,
      selectedEndDate !== null ? getEndDate(selectedEndDate, true) : null,
      activeSortColumn,
      orderType == "desc",
      pageSize,
      pageNumber,
      selectedSchoolYearId
    );
    setShowLoader(false);
    if (resp?.data?.data?.data?.length > 0) {
      setActivityReport(resp?.data?.data?.data);
      setTotalRecords(resp?.data.data.totalRecords);
    } else {
      setActivityReport([]);
      setTotalRecords(0);
    }
  };

  const exportToExcel = () => {
    setShowLoader(true);
    exportLiftoffActivityReport(
      selectedSchool,
      getList(selectedSubjects),
      props.classId != undefined ? [props.classId] : getList(selectedClasses),
      selectedGrade === 0 ? undefined : selectedGrade,
      getList(selectedStudents),
      selectedActivities?.length && selectedActivities.length > 0
        ? getList(selectedActivities)
        : defaultActivities,
      selectedStartDate !== null ? getStartDate(selectedStartDate) : null,
      selectedEndDate !== null ? getEndDate(selectedEndDate) : null,
      selectedSchoolYearId
    ).then((response) => {
      setShowLoader(false);
      const file = response.data;
      const fileURL = URL.createObjectURL(file);
      const link = document.createElement("a");
      link.href = fileURL;
      link.download = "LiftoffActivityReport.xlsx";
      link.setAttribute("target", "_blank");
      document.body.appendChild(link);
      link.click();
    });
  };

  useEffect(() => {
    if (activityReport && activityReport?.length !== 0) getReport();
  }, [pageSize, pageNumber, resetPageNumber]);

  const runReport = async () => {
    setPageNumber(DEFAULT_PAGE_NUMBER);
    setTotalRecords(0);
    getReport();
  };

  const redirectToResult = (activity: any) => {
    if (activity.activity.indexOf("Liftoff Diagnostic") > -1) {
      liftoffDiagnosticResultRedirect(
        activity.sessionId,
        "liftoffactivityreport"
      );
    } else if (activity.activity.indexOf("Liftoff My Galaxies") > -1) {
      myGalaxyStandardResultRedirect(
        activity.sessionId,
        "liftoffactivityreport"
      );
    }
  };

  return (
    <>
      {(showLoader || isLoadingClasses) && <Loader></Loader>}
      <div className="min-w-0 w-full">
        {!props.hideBreadcrumb && (
          <div className="lg:flex lg:items-center px-4 py-3 lg:px-8 border-b">
            {props.userContext.roleId !== Constant.UserRoleId.Student && (
              <Breadcrumb items={breadcrumbItems()} />
            )}
            <div className="lg:w-[55%] text-lg font-medium flex gap-2 h-auto sm:h-10 items-center">
              <h1
                className={`font-medium flex justify-start mb-0 pb-0 ${
                  props.userContext.roleId !== Constant.UserRoleId.Student
                    ? "text-primary-violet"
                    : "text-white"
                }
        `}
              >
                Liftoff Activity Report
              </h1>
            </div>
          </div>
        )}

        <div className="py-1 relative my-4">
          <div className="w-full rounded-lg">
            <div className="flex flex-col 1xl:flex-row">
              <div className="w-full">
                <div className="grid gap-x-8 mb-4">
                  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 1xl:grid-cols-4 2xl:grid-cols-6 gap-4 mb-4 mt-4 order-last md:order-1">
                    {isDistrictUser && (
                      <div className="flex flex-col">
                        <div className="flex gap-2">
                          <label className="block mb-1 text-sm text-gray-500">
                            School <Asterisk />
                          </label>
                        </div>
                        <Select
                          value={selectedSchool}
                          onChange={(e) => {
                            handleSchoolChange(e.target.value);
                          }}
                          className={
                            "py-2.5 px-4 block text-sm border border-gray-400 border-opacity-50 focus:ring-primary-violet focus:border-primary-violet form-select leading-5 p-1 rounded-lg "
                          }
                        >
                          <option value={0}>Select School</option>
                          {schools?.map((school: ISchool, index) => {
                            return (
                              <Fragment key={index}>
                                <option value={school.schoolId}>
                                  {school.name}
                                </option>
                              </Fragment>
                            );
                          })}
                        </Select>
                      </div>
                    )}
                    {props.userContext.roleId !==
                      Constant.UserRoleId.Student && (
                      <div className="flex flex-col">
                        <div className="flex flex-col">
                          <label className="block mb-1 text-sm text-gray-500">
                            Subject
                          </label>
                          <SelectGroup
                            isDisabled={selectedSchool > 0 ? false : true}
                            isMulti={true}
                            onChange={handleSubjectChange}
                            value={selectedSubjects}
                            placeholder={"All Subjects"}
                            options={subjects?.map(subject => ({
                              value: subject.subjectId.toString(),
                              label: subject.name
                            }))}
                            isSearchable={true}
                            closeMenuOnSelect={false}
                            theme={(theme) => ({
                              ...theme,
                              colors: {
                                ...theme.colors,
                                primary: "#008fbb",
                              },
                            })}
                          />
                        </div>
                      </div>
                    )}
                    <div
                      className={
                        "flex flex-col" +
                        (props.hideClassDropdown ? " hidden " : "")
                      }
                    >
                      <label className="block mb-1 text-sm text-gray-500">
                        Class
                      </label>
                      <ClassDropDown
                        schoolYearId={
                          props.profile.currentSchoolYear.schoolYearId
                        }
                        defaultLabel="All Classes"
                        isMultiSelect={true}
                        onChange={handleClassChange}
                        value={selectedClasses}
                        data={classes}
                        isDisabled={
                          (selectedSchool > 0 ? false : true) &&
                          isLoadingClasses
                        }
                        showArchived={true}
                        showHidden={true}
                        selectedSchoolAccountId={selectedSchoolAccountId}
                      ></ClassDropDown>
                    </div>
                    <div
                      className={
                        "flex flex-col" +
                        (props.hideGradeDropdown ? " hidden " : "")
                      }
                    >
                      <label className="block mb-1 text-sm text-gray-500">
                        Grade
                      </label>
                      <SelectGroup
                        isDisabled={selectedSchool > 0 ? false : true}
                        value={findGradeByValue()}
                        options={grades}
                        isSearchable={true}
                        onChange={(item) => {
                          handleGradeOnChange(item);
                        }}
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary: "#008fbb",
                          },
                        })}
                      />
                    </div>
                    <div className="flex flex-col">
                      <label className="block mb-1 text-sm text-gray-500">
                        Student
                      </label>
                      <SelectGroup
                        isDisabled={
                          selectedSchool > 0
                            ? props.source === "gradebook" && !props.classId
                              ? true
                              : false
                            : true
                        }
                        isMulti={true}
                        placeholder={"All Students"}
                        onChange={handleStudentChange}
                        value={selectedStudents}
                        options={students}
                        closeMenuOnSelect={false}
                        isSearchable={true}
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary: "#008fbb",
                          },
                        })}
                      />
                    </div>
                    <div className="flex flex-col">
                      <label className="block mb-1 text-sm text-gray-500">
                        Activity
                      </label>
                      <SelectGroup
                        isDisabled={selectedSchool > 0 ? false : true}
                        isMulti={true}
                        placeholder={"All Activities"}
                        onChange={handleActivityChange}
                        options={activities}
                        value={selectedActivities}
                        closeMenuOnSelect={false}
                        isSearchable={true}
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary: "#008fbb",
                          },
                        })}
                      />
                    </div>
                    <div className="flex flex-col">
                      <label className="block mb-1 text-sm text-gray-500">
                        School Year
                      </label>
                      <SchoolYearDropDown
                        reset={resetSchoolYear}
                        offsets={[0, -1]}
                        handleSchoolYearChange={handleSchoolYearChange}
                        handleSchoolYearLoaded={handleSchoolYearLoaded}
                        handleSchoolYearData={handleSchoolYearData}
                      ></SchoolYearDropDown>
                    </div>
                    <div className="flex flex-col">
                      <label className="block mb-1 text-sm text-gray-500">
                        Start Date
                      </label>
                      <DatePicker
                        selected={selectedStartDate}
                        minDate={mintDate}
                        maxDate={maxDate}
                        onChange={(e) => {
                          if(!e) {
                            setIsInvalidStartDate(true);
                            setSelectedStartDate(e);
                            return;
                          }
                          if (
                            selectedEndDate &&
                            e > selectedEndDate &&
                            selectedStartDate! > new Date()
                          ) {
                            setIsInvalidStartDate(true);
                          } else {
                            setIsInvalidStartDate(false);
                          }
                          setSelectedStartDate(e);
                        }}
                        className={
                          "react-datepicker__input-container py-1.5 px-3 block w-full shadow-sm focus:ring-primary-violet focus:border-primary-violet border border-gray-300 rounded " +
                          (selectedSchool === 0
                            ? "pointer-events-none text-gray-400 bg-gray"
                            : "")
                        }
                      />
                      {isInvalidStartDate && (
                        <span className="pl-2 text-red-600">Invalid Date</span>
                      )}
                    </div>
                    <div className="flex flex-col">
                      <label className="block mb-1 text-sm text-gray-500">
                        End Date
                      </label>

                      <DatePicker
                        selected={selectedEndDate}
                        minDate={mintDate}
                        maxDate={maxDate}
                        onChange={(e) => {
                          if(!e) {
                            setIsInvalidEndDate(true);
                            setSelectedEndDate(e);
                            return;
                          }
                          if (selectedStartDate && e < selectedStartDate) {
                            setIsInvalidEndDate(true);
                          } else {
                            setIsInvalidEndDate(false);
                          }
                          setSelectedEndDate(e);
                        }}
                        className={
                          "react-datepicker__input-container py-1.5 px-3 block w-full shadow-sm focus:ring-primary-violet focus:border-primary-violet border border-gray-300 rounded " +
                          (selectedSchool === 0
                            ? "pointer-events-none text-gray-400 bg-gray"
                            : "")
                        }
                      />
                      {isInvalidEndDate && (
                        <span className="pl-2 text-red-600">Invalid Date</span>
                      )}
                    </div>
                  </div>
                </div>

                <div className="flex gap-4 justify-end">
                  <button
                    className="bg-transparent border my-auto border-primary-violet shadow-sm py-2 px-4 flex justify-center text-sm font-medium text-primary-violet hover:text-white hover:bg-light-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3 transition ease-in-out duration-500"
                    onClick={resetReport}
                  >
                    Reset
                  </button>
                  <div className="cursor-not-allowed">
                    <button
                      className={
                        "bg-primary-violet border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 " +
                        (selectedSchool === 0 ||
                        !selectedStartDate ||
                        !selectedEndDate ||
                        isInvalidStartDate ||
                        isInvalidEndDate ||
                        props.disableButtons
                          ? "bg-opacity-20 text-primary-violet text-opacity-40 pointer-events-none"
                          : "hover:bg-dark-violet hover:shadow-lg")
                      }
                      onClick={runReport}
                    >
                      Run Report
                    </button>
                  </div>

                  <div className="cursor-not-allowed">
                    <button
                      onClick={() => exportToExcel()}
                      className={
                        "bg-primary-violet border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 " +
                        (activityReport &&
                        activityReport.length > 0 &&
                        !props.disableButtons
                          ? "hover:bg-dark-violet hover:shadow-lg"
                          : "bg-opacity-20 text-primary-violet text-opacity-40 pointer-events-none")
                      }
                    >
                      Export Data
                    </button>
                  </div>
                </div>
                {activityReport && (
                  <>
                    <div className="border-b border-gray-400 py-2 mb-2"></div>
                    <div className="bg-white py-5 px-5">
                      <div className="mb-5 shadow overflow-x-auto xl:overflow-x-visible">
                        <table className="table w-full ">
                          <thead className="sticky top-0">
                            <tr className="bg-primary-violet  text-white ">
                              <th className="text-left p-3 text-sm font-normal">
                                <SortTableHeader
                                  data={[]}
                                  columnName="DateCompleted"
                                  headerComponent="Completion Date"
                                  orderType={orderType}
                                  activeSortColumn={activeSortColumn}
                                  sortRecords={handleSortRecords}
                                />
                              </th>
                              <th className="text-left p-3 text-sm font-normal">
                                <SortTableHeader
                                  data={[]}
                                  columnName="Duration"
                                  headerComponent="Duration"
                                  orderType={orderType}
                                  activeSortColumn={activeSortColumn}
                                  sortRecords={handleSortRecords}
                                />
                              </th>
                              <th className="text-left p-3 text-sm font-normal">
                                <SortTableHeader
                                  data={[]}
                                  columnName="Student"
                                  headerComponent="Student"
                                  orderType={orderType}
                                  activeSortColumn={activeSortColumn}
                                  sortRecords={handleSortRecords}
                                />
                              </th>
                              <th className="text-left p-3 text-sm font-normal">
                                <SortTableHeader
                                  data={[]}
                                  columnName="StudentId"
                                  headerComponent="Student ID"
                                  orderType={orderType}
                                  activeSortColumn={activeSortColumn}
                                  sortRecords={handleSortRecords}
                                />
                              </th>
                              <th className="text-left p-3 text-sm font-normal">
                                <SortTableHeader
                                  data={[]}
                                  columnName="Subject"
                                  headerComponent="Subject"
                                  orderType={orderType}
                                  activeSortColumn={activeSortColumn}
                                  sortRecords={handleSortRecords}
                                />
                              </th>
                              <th className="text-left p-3 text-sm font-normal">
                                Activity
                              </th>
                              <th className="text-left p-3 text-sm font-normal"></th>
                              <th className="text-left p-3 text-sm font-normal">
                                Score
                              </th>
                              <th className="text-left p-3 text-sm font-normal"></th>
                            </tr>
                          </thead>
                          <tbody>
                            {activityReport?.map((activity, index) => {
                              return (
                                <Fragment>
                                  <tr
                                    className={`hover:bg-primary-violet/20 transition ease-in-out duration-300 ${
                                      index % 2 === 0
                                        ? "bg-white"
                                        : "bg-primary-violet/10"
                                    }`}
                                  >
                                    <td className="text-sm p-3 ">
                                      {moment(activity.dateCompleted).format(
                                        "MM-DD-YYYY"
                                      )}
                                    </td>
                                    <td className="text-sm p-3">
                                      {formatDuration(
                                        activity.durationInSeconds
                                      )}
                                    </td>
                                    <td className="text-sm p-3 ">
                                      {activity.studentName}
                                    </td>
                                    <td className="text-sm p-3 ">
                                      {!activity.studentId ||
                                      activity.studentId === ""
                                        ? "-"
                                        : activity.studentId}
                                    </td>
                                    <td className="text-sm p-3 ">
                                      {activity.subject}
                                    </td>
                                    <td className="text-sm p-3 ">
                                      {activity.activity}
                                    </td>
                                    <td className="text-sm p-3 text-right">
                                      {activity?.pointsEarned != null &&
                                        activity?.pointsPossible != null &&
                                        `(${getScoreLabel(activity.pointsEarned)}/${activity.pointsPossible})`}
                                    </td>
                                    <td className="text-sm p-3 ">
                                      {getPercentage(
                                        activity.pointsEarned,
                                        activity.pointsPossible
                                      ) + "%"}
                                    </td>
                                    <td className="text-sm p-3 ">
                                      {activity.canViewAssessment != false && (
                                        <button
                                          onClick={() =>
                                            redirectToResult(activity)
                                          }
                                        >
                                          <h2 className="text-blue-500 hover:underline flex justify-start mb-0 pb-0">
                                            {" "}
                                            View{" "}
                                          </h2>
                                        </button>
                                      )}
                                    </td>
                                  </tr>
                                </Fragment>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>

                      {activityReport?.length === 0 && <NoData />}
                      {activityReport?.length !== 0 && (
                        <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between border-t-2 pt-4 mt-3">
                          <Pagination
                            totalRecords={totalRecords}
                            pageNumber={pageNumber}
                            pageSize={pageSize}
                            onPageChange={onPageChange}
                            reset={resetPageNumber}
                            setPageNumber={setPageNumber}
                          />
                          <PageSize
                            pageSize={pageSize}
                            onPageSizeChange={onPageSizeChange}
                          />
                        </div>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default connect((state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
})(LiftoffActivityReport);

import Constant from "./constant/constant";

export const validateIfIsTeacherRole = (roleId: number | undefined) => {
    if (
      roleId === Constant.UserRoleId.SchoolTeacher ||
      roleId === Constant.UserRoleId.FreeTeacher ||
      roleId === Constant.UserRoleId.PayTeacher
    ) {
      return true;
    }
    return false;
  };

export const validateIfIsStudentRole = (roleId: number | undefined) => {
    if (
        roleId === Constant.UserRoleId.Student
    ) {
      return true;
    }
    return false;
  };
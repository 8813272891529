import { useEffect, useState } from "react";
import { UserContextState } from "../../../../../redux/actions/userContextAction";
import { getSchoolRanking } from "../../../../../api/student/skillPracticeApi";
import { ISkillStudentRanking } from "../../../../../model/skillPractice/skillStudentRanking";

interface SkillPracticeRankingProps {
  skillStandardId: number;
  userContext?: UserContextState;
  schoolAccountId: number;
  studentId: number;
  isSchoolRanking: boolean;
}

function SkillPracticeRanking(props: SkillPracticeRankingProps) {
  const [schoolRanking, setSchoolRanking] = useState<ISkillStudentRanking[]>();
  useEffect(() => {
    if (props.skillStandardId !== 0 && props.schoolAccountId !== -1) {
      getSchoolRanking(
        props.skillStandardId,
        props.isSchoolRanking ? props.schoolAccountId : 0,
        props.studentId
      ).then((d) => {
        setSchoolRanking(d.data);
      });
    }
  }, [props.skillStandardId, props.schoolAccountId]);

  function getStudentDisplayName(name: string) {
    var nameArr = name.split(" ");
    return (
      nameArr[0].charAt(0) +
      ". " +
      (nameArr.length >= 2 ? nameArr[1].charAt(0) : "")
    );
  }
  return (
    <div className="flex flex-col space-y-2 px-1">
      {schoolRanking && schoolRanking.length === 0 && (
        <span>No data found</span>
      )}
      {schoolRanking &&
        schoolRanking.map((ranking, index) => {
          return (
            <div key={index} className="flex justify-between text-sm pt-2">
              <div className="text-gray-600 flex items-center w-[5rem] lg:w-[10rem] 2xl:w-[13rem] xxxl:w-[16rem] ">
                <span className="bg-secondary-teal min-w-[1.25rem] min-h-[1.25rem] mr-2 flex justify-center items-center rounded-full text-md text-white font-semibold inline-block">
                  {ranking?.position}
                </span>
                <div>
                  <div className="font-semibold">
                    {getStudentDisplayName(ranking?.name)}
                  </div>
                  <div className="text-xs">{ranking?.school}</div>
                </div>
              </div>
              <div className="space-y-1 flex flex-col justify-center">
                <div className="flex items-center">
                  <span className="w-10 text-green-300 text-xs">Wins</span>
                  <span className=" bg-green-300 px-1 rounded-full text-xs inline-block">
                    {ranking?.wins}
                  </span>
                </div>
                <div className="flex items-center">
                  <span className="w-10 text-red-300 text-xs">Losses</span>
                  <span className=" bg-red-300  px-1 rounded-full text-xs inline-block">
                    {ranking?.losses}
                  </span>
                </div>
                <div className="flex items-center">
                  <span className="w-10 text-blue-300 text-xs">Ties</span>
                  <span className=" bg-blue-300  px-1 rounded-full text-xs inline-block">
                    {ranking?.ties}
                  </span>
                </div>
              </div>
              <div className="border-l-2 ml-2 pl-2 flex flex-col justify-center items-center font-semibold w-12 lg:w-14">
                <span>Win%</span>
                <span>{ranking?.winPercentage}</span>
              </div>
            </div>
          );
        })}
    </div>
  );
}

export default SkillPracticeRanking;

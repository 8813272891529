import Select from "react-select";
import { MinusCircleIcon, PlusCircleIcon } from "@heroicons/react/solid";
import { IKeyValuePair, ISelectElement } from "../../../../model/interface/selectElement";
import { IAssessmentQuestion } from "../../../../model/interface/assessmentQuestion";
import { Fragment } from "react";
import { IAssessmentPassage } from "../../../../model/interface/assessmentPassage";
import Constant from "../../../../utils/constant/constant";
import Standard from "../../../../model/teacher/standard";
import PassageQuestionPassagesQuestions from "./passageQuestionPassagesQuestions";

interface PassageQuestionPassagesProps {
  districtId: number,
  hasDistrictAssessmentQuestionIcon: boolean,
  passages: Array<IAssessmentPassage>,
  passageQuestions: Array<IAssessmentQuestion>,
  displayRows: Array<IKeyValuePair>,
  setDisplayRows: (displayRows: Array<IKeyValuePair>) => void,
  selectedDomains: Array<ISelectElement>,
  selectedQuestions: Array<IAssessmentQuestion>,
  updateQuestion: (question: IAssessmentQuestion) => void,
  multiPassageQuestions: Array<number>,
  expandedPassagesIds:  Array<number>,
  selectedSortOption: Array<{ domainId: number; selectedValue: ISelectElement }>,
  favoriteQuestionsIds: Array<number>,
  handleQuestionsSortBy: (e: any, domainIdToSort: string) => void,
  getSortedPassages: (domainId: number) => any,
  getSortedPassageQuestions: (passageId: number, domainId: number) => any,
  handleFavoriteQuestion: (questionId: number) => void,
  handleFeedbackPopup: (id: number, standardList: Array<Standard>) => void,
  handlePassageQuestionsCollapse: (
    passageId: number | undefined,
    viewAll: boolean,
    domainId: number
  ) => void,
  openQuestionPreview: (
    learnosityReferenceId: any,
    questionId: any,
    questionText: any,
    currentIndex: any,
    isPassgae: boolean,
    standardId: any,
    spanishLearnosityReferenceId: any,
    domainId: any,
    passageId: any
  ) => void,
  errorMessagePopup: boolean,
  setErrorMessagePopup: (errorMessagePopup: boolean) => void,
}

const passageQuestionSortOptions = [
  { value: "1", label: "Alphabetical" },
  { value: "3", label: "Newest" },
  { value: "4", label: "Question Count" },
  { value: "5", label: "Word Count" },
  { value: "6", label: "Lexile Level" },
  { value: "7", label: "Question Type" },
  { value: "7", label: "Favorites" },
];

function PassageQuestionPassages(props: PassageQuestionPassagesProps) {
  const {
    districtId,
    hasDistrictAssessmentQuestionIcon,
    passages,
    passageQuestions,
    displayRows,
    setDisplayRows,
    selectedDomains,
    selectedQuestions,
    updateQuestion,
    multiPassageQuestions,
    expandedPassagesIds,
    selectedSortOption,
    favoriteQuestionsIds,
    handleQuestionsSortBy,
    getSortedPassages,
    getSortedPassageQuestions,
    handleFavoriteQuestion,
    handleFeedbackPopup,
    handlePassageQuestionsCollapse,
    openQuestionPreview,
    errorMessagePopup,
    setErrorMessagePopup,
  } = props;

  const getDomainsList = () => {
    const domainsIds = Array.from(new Set(passages.map(passage => passage.domainId)));
    const domainsToDisplay: Array<ISelectElement> = domainsIds.map(domainId => {
      const domainData = selectedDomains.find(domain => +domain.value === domainId);
      const passageData = passages.find(passage => passage.domainId === domainId);
      return {
        label: domainData ? domainData.label : passageData?.domainName ?? "",
        value: ""+domainId
      }
    });
    return domainsToDisplay;
  }
  
  const handleSelectedQuestion = (
    question: IAssessmentQuestion,
    passageId: number
  ) => {
    if (
      !selectedQuestions.some((r) => r.questionId === question.questionId) &&
      multiPassageQuestions.some((r) => r === question.questionId)
    ) {
      setErrorMessagePopup(!errorMessagePopup);
    }
    question.assessmentQuestionTypeId = Constant.AssessmentQuestionType.PassageQuestions;
    question.passageId = passageId;
    updateQuestion(question);
  }
  
  const canLoadMoreQuestions = (passageId: number) => {
    const passageQuestionsCount: number = passageQuestions.filter(passage => passage.passageId === passageId).length;
    const canLoadMoreQuestions: boolean = passageQuestionsCount < 10
      ? false
      : !(parseInt(displayRows.find(rows => rows.key === passageId.toString())?.value ?? "0") >= passageQuestionsCount)
  
    return canLoadMoreQuestions;
  }
  
  const handleLoadMoreQuestions = (passageId: number) => {
    if (displayRows?.length > 0) {
      const updatedDisplayRows: Array<IKeyValuePair> = displayRows.map(rowSetting => ({
        key: rowSetting.key,
        value: rowSetting.key === passageId.toString()
          ? (+rowSetting.value + 10).toString()
          : rowSetting.value
      }));
      setDisplayRows(updatedDisplayRows);
    } else {
      setDisplayRows([]);
    }
  }

  return (
    <div>
      {getDomainsList().map(domain => (
        <div className="space-y-4 pb-4">
          {passages.filter(r => r.domainId === +domain.value).length > 0 && (
            <Fragment>
              <div className="flex justify-between bg-gray-100 mb-3 py-2 px-3">
                <div className="flex items-center space-x-2">
                  <div className="text-secondary-teal font-bold">
                    {domain.label}
                  </div>
                </div>
                <div className="justify-end w-1/2 sm:w-1/2 md:1/4 lg:w-1/4 xl:w-1/4">
                  <Select
                    value={
                      selectedSortOption.find(
                        (r) =>
                          r.domainId.toString() ===
                          domain.value
                      )?.selectedValue
                    }
                    placeholder={"Sort By"}
                    options={passageQuestionSortOptions}
                    onChange={(e) =>
                      handleQuestionsSortBy(e, domain.value)
                    }
                  />
                </div>
              </div>
              {passages && passages.length > 0 &&
                getSortedPassages(parseInt(domain.value))
                  .map((passage: IAssessmentPassage, index: number) => (
                    <div
                      className="border border-2 border-secondary-teal pt-3 px-3"
                      key={passage.passageId}
                    >
                      <div className="pb-2">
                        <span
                          className="cursor-pointer text-blue-500 underline hover:no-underline"
                          onClick={() =>
                            openQuestionPreview(
                              passage.learnosityReferenceId,
                              passage.passageId,
                              passage.title,
                              index,
                              true,
                              0,
                              passage.learnosityReferenceId?.replace(
                                "en",
                                "es"
                              ),
                              0,
                              0
                            )
                          }
                        >
                          {`${passage.title}`}
                        </span>
                        <div className="flex justify-end items-center">
                          <span className="flex space-x-4 items-center ">
                            <span className="flex flex-col border-r-4 pr-4 border-gray-200">
                              <span className="text-sm text-gray-500">
                                Lexile Score{" "}
                                <span className="text-gray-700">
                                  {passage.lexileLevel > 0
                                    ? passage.lexileLevel
                                    : "N/A"}
                                </span>
                              </span>
                              <span className="text-sm text-gray-500">
                                Word Count{" "}
                                <span className="text-gray-700">
                                  {passage.wordCount}
                                </span>
                              </span>
                            </span>
                            <span className="flex space-x-2 text-gray-700">
                              <span className="bg-green-200 w-4 flex inline-flex items-center justify-center h-4 p-2.5 rounded-full text-sm">
                                {
                                  selectedQuestions.filter(
                                    (r) =>
                                      r.passageId ===
                                      passage.passageId
                                  ).length
                                }
                              </span>
                              <span>of</span>
                              <span className="bg-yellow-200 w-4 flex inline-flex items-center justify-center h-4 p-2.5 rounded-full text-sm">
                                {passage.questionCount}
                              </span>
                              <span>questions selected</span>
                            </span>
                          </span>
                        </div>
                        <div className="flex py-2 justify-between">
                          {passage.passageId &&
                          expandedPassagesIds?.some(
                            (r) => r === passage.passageId
                          ) ? (
                            <MinusCircleIcon
                              className="w-5 h-5 cursor-pointer"
                              onClick={() =>
                                handlePassageQuestionsCollapse(
                                  passage.passageId,
                                  false,
                                  passage.domainId
                                )
                              }
                            />
                          ) : (
                            <PlusCircleIcon
                              className="w-5 h-5 cursor-pointer"
                              onClick={() =>
                                handlePassageQuestionsCollapse(
                                  passage.passageId,
                                  false,
                                  passage.domainId
                                )
                              }
                            />
                          )}
                          <a
                            href={void 0}
                            className="pr-4 underline text-blue-500 hover:no-underline"
                            onClick={() =>
                              handlePassageQuestionsCollapse(
                                passage.passageId,
                                true,
                                passage.domainId
                              )
                            }
                          >
                            View all questions associated with passage
                          </a>
                        </div>
                      </div>
                      {passage.passageId && expandedPassagesIds?.some(r => r === passage.passageId) && (
                        <PassageQuestionPassagesQuestions 
                          districtId={districtId}
                          hasDistrictAssessmentQuestionIcon={hasDistrictAssessmentQuestionIcon}
                          passage={passage}
                          selectedQuestions={selectedQuestions}
                          favoriteQuestionsIds={favoriteQuestionsIds}
                          getSortedPassageQuestions={getSortedPassageQuestions}
                          canLoadMoreQuestions={canLoadMoreQuestions}
                          handleFavoriteQuestion={handleFavoriteQuestion}
                          handleFeedbackPopup={handleFeedbackPopup}
                          handleLoadMoreQuestions={handleLoadMoreQuestions}
                          handleSelectedQuestion={handleSelectedQuestion}
                          openQuestionPreview={openQuestionPreview}
                        />
                      )}
                    </div>
                  )
                )}
            </Fragment>
          )}
        </div>
      ))}
    </div>
  )
}

export default PassageQuestionPassages

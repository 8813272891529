import { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { UserContextState } from "../../../../../redux/actions/userContextAction";
import Constant from "../../../../../utils/constant/constant";
import {
  completeDomainTestStudyPlan,
  getDomainLastSession,
  initializeDomainTest,
  //updateDomainTest,
  abandonDomainTest,
} from "../../../../../api/student/studyPlanApi";
import { IInitalizeStudyPlanResponse } from "../../../../../model/student/studyplan/initializeStudyPlanRespons";
import QuestionAnswer from "../../shared/questionAnswer";
import Profile from "../../../../../model/users/profile";
import BeginActivity from "../../shared/beginActivity";
import RouteConstant from "../../../../../utils/constant/routeConstant";
import Breadcrumb from "../../middleSchool/breadcrumb";
import { IBreadcrumb } from "../../../../../model/common/breadcrumb";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
  getStudentClasses,
  getStudentTeacher,
} from "../../../../../api/student/studentApi";
import { getCalculatorBySubjectId } from "../../../../../api/student/studentSubjectApi";
import {
  GetIsQuestionRetryAllowed,
  GetShowOnlyTextExpalanation,
  GetShowExplanation,
} from "../../../../../utils/helper";
import { getSubjectDetail } from "../../../../../api/teacher/assessment";
import { getRetryEligibility } from "../../../../../api/student/studentActivityApi";
import { IQuestionAnswerProps } from "../../../../../model/interface/questionAnswer/questionAnswer";
import { IRetryMissedItemState } from "../../../../../model/interface/activitySession/retryAssignment";

interface IStudyPlanDomainTest {
  userContext: UserContextState;
  profile: Profile;
  match: any;
  location: any;
}
function StudyPlanDomainTest(props: IStudyPlanDomainTest) {
  const { t } = useTranslation();
  const studentType: any = "EL";
  const [domainTest, setDomainTest] = useState<IInitalizeStudyPlanResponse>();
  const [lastSession, setLastSession] = useState<any>({});
  const subjectId = parseInt(atob(props.match.params.subjectId));
  const domainId = parseInt(atob(props.match.params.domainId));
  const domainName: string = props.location.state?.domainName ?? "";
  const subjectName = props.location.state?.subjectName ?? "";
  const history = useHistory();
  const [beginActivityModal, setBeginActivityModal] = useState<any>({
    open: false,
    data: undefined,
  });
  const [testSize, setTestSize] = useState<number>(0);
  const [calculatorReferenceId, setCalculatorReferenceId] = useState<string>();
  const [isQuestionRetry, setQuestionRetry] = useState<boolean>(false);
  const [showOnlyTextExpalanation, setOnlyTextShowExplanation] =
    useState<boolean>(false);
  const [showExpalanation, setShowExplanation] = useState<boolean>(false);
  const [contentAreaId, setContentAreaId] = useState<number>(0);
  useEffect(() => {
    initialize();
    if (subjectId > 0) {
      getSubjectDetail(subjectId).then((response) => {
        let result: number = 10;
        result = response?.data.grades.some((r) => r.id > 2) ? 20 : 10;
        setTestSize(result);
      });
      getCalculatorBySubjectId(subjectId).then((res) => {
        if (res.data) setCalculatorReferenceId(res.data);
      });
    }
    setQuestionRetry(
      GetIsQuestionRetryAllowed(
        props.userContext?.gradeId,
        props.userContext?.stateId,
        Constant.ActivitySubTypes.PRACTICETEST,
        undefined
      )
    );
    setShowExplanation(
      GetShowExplanation(
        props.userContext?.gradeId,
        props.userContext?.stateId,
        Constant.ActivitySubTypes.PRACTICETEST
      )
    );
    setOnlyTextShowExplanation(
      GetShowOnlyTextExpalanation(
        props.userContext?.gradeId,
        props.userContext?.stateId,
        Constant.ActivitySubTypes.PRACTICETEST
      )
    );
  }, []);

  function initialize() {
    var classesResponseApi = getStudentClasses(
      props.userContext.userId,
      subjectId,
      0
    );
    var teacherResponseApi = getStudentTeacher(
      props.userContext.userId,
      subjectId,
      0
    );

    var lastSessionApi = getDomainLastSession(
      props.userContext.userId,
      domainId,
      0
    );

    Promise.all([classesResponseApi, teacherResponseApi, lastSessionApi]).then(
      ([classesResponse, teachersResponse, lastSessionResponse]) => {
        setLastSession(lastSessionResponse.data);

        var data: any = {
          classes: classesResponse.data,
          teachers: teachersResponse.data,
          classId:
            lastSessionResponse.data !== null
              ? lastSessionResponse.data.classId
              : undefined,
          teacherId:
            lastSessionResponse.data !== null
              ? lastSessionResponse.data.teacherUserId
              : undefined,
          languageId: lastSessionResponse.data.languageId,
        };
        setBeginActivityModal({ open: true, data });
      }
    );
  }

  function initializeTest(data: any) {
    initializeDomainTest(
      props.userContext.userId,
      domainId,
      data.languageId,
      0,
      0,
      0,
      "",
      props.userContext.gradeId,
      data.classId,
      data.teacherId,
      subjectId
    ).then((d) => {
      var data = d.data;
      setDomainTest(d.data);
      setBeginActivityModal({ open: false, data });
    });
  }

  function completeStudyPlanActivity() {
    completeDomainTestStudyPlan(
      props.userContext.userId,
      domainTest?.sessionId ?? "",
      domainTest?.activitySessionId ?? 0
    ).then((res) => {
      handleOnCompleteStudyPlanActivity();
    });
  }

  const breadcrumbItems = () => {
    const items: Array<IBreadcrumb> = [
      {
        name: t("Breadcrumb.Home"),
        url: RouteConstant.StudentRoute.Home,
        textColor: "text-gray-500",
      },
      {
        name: t("Breadcrumb.Elementary"),
        textColor: "text-gray-500",
        url: RouteConstant.StudentRoute.Home,
      },
      {
        name: t("Breadcrumb.StudyPlanDomainTest"),
        textColor: "text-gray-500",
        url: "",
      },
    ];

    return items;
  };

  // function update(data: any) {
  //   updateDomainTest(
  //     lastSession.studentDomainTestId,
  //     data.teacherId,
  //     data.classId
  //   );
  // }

  const handleOnCompleteStudyPlanActivity = async () => {
    const resultUrl = RouteConstant.StudentRoute.DomainTestResult.replace(
      ":sessionId",
      domainTest ? domainTest.sessionId : ""
    );
    const res = await getRetryEligibility(domainTest?.sessionId!);

    if (res.data.retryEligible) {
      redirectToRetryMissedItems(resultUrl);
    } else {
      history.push(resultUrl);
    }
  };

  function redirectToRetryMissedItems(resultUrl: string) {
    const retryUrl =
      RouteConstant.StudentRoute.StudentRetryAssignmentActivityEL.replace(
        ":sessionId",
        domainTest?.sessionId!
      );

    const questionAnswer: IQuestionAnswerProps = {
      activityType: Constant.ActivityType.STUDYPLAN,
      activityId: domainTest!.activityId ?? "",
      teacherActivityId: domainTest?.sessionId!,
      showActivity: false,
      showQuestions: true,
      questionIds: [],
      hasSections: false,
      hasItemBranching: false,
      isAlienExplanation: true,
      isGameChoosen: false,
      studentId: props.userContext.userId.toString(),
      resumeActivity: false,
      sessionId: domainTest?.sessionId!, // replace with retry sessionId
      studentType: studentType,
      title: subjectName,
      adaptiveFilter: null,
      onExitRedirectUrl: RouteConstant.StudentRoute.Home,
      questionRetry: isQuestionRetry,
      showExplanation: showExpalanation,
      showTextExpalanation: showOnlyTextExpalanation,
      activitySessionId: domainTest?.activitySessionId!, // replace with retry activitySessionId
      subjectId: subjectId,
      activitySubType: Constant.ActivitySubTypes.PRACTICETEST,
      calculatorReferenceId: calculatorReferenceId,
      domainId: domainId,
      OnSubmit: () => {},
      contentAreaId: contentAreaId,
      questionsState: domainTest?.questionStates,
    };

    const state: IRetryMissedItemState = {
      activityType: Constant.RetryActivityType.STUDYPLAN_DOMAIN,
      languageId: Constant.Languages.English,
      resultUrl: resultUrl,
      questionAnswer: JSON.stringify(questionAnswer),
    };

    history.push({
      pathname: retryUrl,
      state: state,
    });
  }

  function onCancel () {
    history.push(`${
      RouteConstant.StudentRoute.Home
    }?tab=${Constant.StudyType.MYSTUDYPLAN.toLowerCase()}&subjectId=${subjectId}`);
  }

  return (
    <Fragment>
      <>
        <div className="px-4 py-3 lg:px-8 border-b">
          <nav className="flex mt-2" aria-label="Breadcrumb">
            <Breadcrumb items={breadcrumbItems()} />
          </nav>
          <div className="text-lg flex justify-between gap-2 h-auto sm:h-10 items-center">
            <h1 className="font-medium flex justify-start">
              {t("StudyPlan.StudyPlanDomainTest")}
            </h1>
          </div>
        </div>
        {domainTest !== undefined && domainTest.sessionId.length > 0 && (
          <QuestionAnswer
            activityType={Constant.ActivityType.STUDYPLAN}
            activityId={domainTest.activityId ? domainTest.activityId : ""}
            teacherActivityId={domainTest.sessionId}
            showActivity={domainTest.activityId?.length > 0 ? true : false}
            showQuestions={domainTest.activityId?.length > 0 ? false : true}
            questionIds={
              domainTest.itemReferences && domainTest.itemReferences.length > 0
                ? domainTest.itemReferences
                : []
            }
            hasSections={false}
            hasItemBranching={false}
            isAlienExplanation={true}
            isGameChoosen={false}
            studentId={props.userContext.userId.toString()}
            resumeActivity={domainTest.hasPendingSession}
            sessionId={domainTest.sessionId}
            studentType={studentType}
            title={subjectName}
            adaptiveFilter={null}
            OnSubmit={completeStudyPlanActivity}
            onExitRedirectUrl={RouteConstant.StudentRoute.Home}
            activitySubType={Constant.ActivitySubTypes.PRACTICETEST}
            calculatorReferenceId={calculatorReferenceId}
            questionRetry={isQuestionRetry}
            showExplanation={showExpalanation}
            activitySessionId={domainTest.activitySessionId}
            showTextExpalanation={showOnlyTextExpalanation}
            subjectId={subjectId}
            domainId={domainId}
            contentAreaId={contentAreaId}
            shuffleItems={domainTest.isDisplayRandomQuestion}
            questionsState={domainTest.questionStates}
          />
        )}
        {beginActivityModal.open && (
          <BeginActivity
            activity={"Practice Test - " + domainName}
            name={subjectName + " Practice Test"}
            noOfQuestions={testSize}
            source={"StudyPlan"}
            themeName={"ELEMENTARYHOME"}
            data={beginActivityModal.data}
            hideClass={true}
            hideTeacher={true}
            ActivityLanguageLevel={"UserLevel"}
            onBegin={(data: any, value: any) => {
              setContentAreaId(value.contentAreaId);
              if (
                lastSession === null ||
                lastSession === "" ||
                lastSession === undefined ||
                data.languageId !== value.languageId
              ) {
                abandonDomainTest(
                  props.userContext.userId,
                  lastSession.lernositySessionId
                ).then((d) => {
                  initializeTest(value);
                });
              } else {
                setDomainTest({
                  hasPendingSession: true,
                  sessionId: lastSession.lernositySessionId,
                  activityId: lastSession.activityId,
                  activitySessionId: lastSession.activitySessionId,
                  itemReferences: lastSession.itemReferences,
                  learnositySessionGroupId: "",
                  isDisplayRandomQuestion: lastSession.isDisplayRandomQuestion,
                  questionStates: lastSession.questionStates
                });
                //update(value);
                setBeginActivityModal({ ...beginActivityModal, open: false });
              }
            }}
            onCancel={onCancel}
            subjectId={subjectId}
          />
        )}
      </>
    </Fragment>
  );
}

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(StudyPlanDomainTest);

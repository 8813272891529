import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  Fragment,
} from "react";
import {
  CloseIcon,
  MinusIcon,
  SettingsIcon,
  ChatFilterIcon,
} from "../../../../assets/icons/index";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/database";
import TeacherChatMessage from "./teacherChatMessage";
import "../chatRoom.css";
import ChatRoomProps from "../../../../model/chat/chatRoomProps";
import { useIdleTimer } from "react-idle-timer";

import GetStudentByTeacherId from "../../../../api/chat/student";
import {
  GetSchoolAdminByDistrictId,
  GetTeacherAndStusentsBySchoolId,
} from "../../../../api/chat/teacher";
import debounce from "lodash.debounce";
import { Configuration } from "../../../../environment/setup";
import UserTile from "../shared/userTile";
import { useCollectionData } from "react-firebase-hooks/firestore";
import Filter from "./filter";
import { filterType } from "../../../../model/chat/type/types";
import Constant from "../../../../utils/constant/constant";

interface ChatSearch {
  id: number;
  displayName: string;
  isClass: boolean;
  profileUrl: string;
  recentMassageCount: number;
  colorCode: string;
  roleId: number;
  isChatSettingEnabled: boolean;
}

function TeacherChatRoom(props: ChatRoomProps) {
  firebase.initializeApp(props.configuration);
  const db = firebase.database();
  const firestore = firebase.firestore();
  const [sendBy] = useState(props.userId);
  const [presenceCollection] = useState<string>(props.presenceCollection);
  const [isCollapse, setIsCollapse] = useState(true);
  const [onlineUsers, setOnlineUsers] = useState([]);
  const [chatItems, setchatItems] = useState([]);
  const [idleTimer] = useState<number>(Configuration.IdealStatusTime);
  const [serachQuery, setSerachQuery] = useState<string>("");
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize] = useState<number>(10);
  const [hightClass, setHightClass] = useState<string>("h-auto");
  const [filter, setFilter] = useState<filterType>("BOTH");
  const [showFilter, setShowFilter] = useState(false);
  const listInnerRef = useRef<any>();
  var [position] = useState<number>(100);
  const recentChatRef = firestore.collection(props.recentChatcollectionName);
  const [chatWindow, setChatWindow] = useState([
    {
      isActive: false,
      sendTO: {
        id: 0,
        displayName: "",
        isClass: false,
        profileUrl: "",
        colorCode: "",
        roleId: 0,
        isChatSettingEnabled: false,
        isCollapse: false,
        hightClass: "auto",
      },
    },
    {
      isActive: false,
      sendTO: {
        id: 0,
        displayName: "",
        isClass: false,
        profileUrl: "",
        colorCode: "",
        roleId: 0,
        isChatSettingEnabled: false,
        isCollapse: false,
        hightClass: "auto",
      },
    },
    {
      isActive: false,
      sendTO: {
        id: 0,
        displayName: "",
        isClass: false,
        profileUrl: "",
        colorCode: "",
        roleId: 0,
        isChatSettingEnabled: false,
        isCollapse: false,
        hightClass: "auto",
      },
    },
    {
      isActive: false,
      sendTO: {
        id: 0,
        displayName: "",
        isClass: false,
        profileUrl: "",
        colorCode: "",
        roleId: 0,
        isChatSettingEnabled: false,
        isCollapse: false,
        hightClass: "auto",
      },
    },
  ]);

  const chatQuery: any = recentChatRef.where("userId", "==", props.userId);
  const [reactChats, loading]: any = useCollectionData(chatQuery);

  const changeHandler = (event: any) => {
    setSerachQuery(event.target.value);
    getChatItems(event.target.value, filter);
  };

  useIdleTimer({
    timeout: idleTimer,
    onActive: () => handleOnActive(),
    onIdle: () => handleOnIdle(),
    debounce: 300
  });

  const debouncedChangeHandler = useCallback(debounce(changeHandler, 1000), []);

  useEffect(() => {
    const ref = db.ref(presenceCollection);
    ref.on(
      "value",
      (snapshot) => {
        if (snapshot.val()) {
          setOnlineUsers(snapshot.val());
        } else {
          setOnlineUsers([]);
        }
      },
      (errorObject) => {
        console.log("The read failed: " + errorObject.name);
      }
    );
    handleOnActive();
    getChatItems(serachQuery, filter);
  }, []);

  useEffect(() => {
    setPageNumber(1);
  }, [filter]);

  const getChatItems = (
    query: string,
    filter: filterType,
    pageNumber?: number
  ) => {
    if (props.roleId === Constant.UserRoleId.District) {
      getSchoolAdmins(query, filter, pageNumber);
    } else if (props.roleId === Constant.UserRoleId.SchoolAdmin) {
      getTeacherAndStudents(query, filter, pageNumber);
    } else {
      getStudent(query, filter);
    }
  };

  const getStudent = (query: string, filter: filterType, pgNumber?: number) => {
    query = query ? query : serachQuery;
    var students: any = chatItems;
    var studentSearchQuery: any = {
      teacherId: props.userId,
      filterType: filter,
      searchQuery: query,
      pageNumber: pgNumber ? pgNumber : pageNumber,
      pageSize: pageSize,
    };

    GetStudentByTeacherId(props.token, studentSearchQuery)
      .then((response) => {
        var data: ChatSearch[] = response.data.data;
        if (data.length > 0) {
          let studentList = data.filter((item) => item.isClass === false);
          for (let index = 0; index < studentList.length; index++) {
            let studentModal = studentList[index];
            let student = students.find(
              (item: any) =>
                item.id === studentModal.id && item.isClass === false
            );
            if (!student) {
              students.push({
                id: studentModal.id,
                displayName: studentModal.displayName,
                isClass: false,
                recentMassageCount: 0,
                colorCode: "",
                roleId: studentModal.roleId,
                isChatSettingEnabled: studentModal.isChatSettingEnabled,
              });
            }
          }
          let classList = data.filter((item) => item.isClass === true);
          for (let index = 0; index < classList.length; index++) {
            let classModal = classList[index];
            let student = students.find(
              (item: any) => item.id === classModal.id && item.isClass === true
            );
            if (!student) {
              students.push({
                id: classModal.id,
                displayName: classModal.displayName,
                isClass: true,
                recentMassageCount: 0,
                colorCode: classModal.colorCode,
                roleId: 0,
                isChatSettingEnabled: classModal.isChatSettingEnabled,
              });
            }
          }
        }

        if (query) {
          students = students.filter((item: any) =>
            item.displayName
              .toLocaleLowerCase()
              .includes(query.toLocaleLowerCase())
          );
        }

        setchatItems(Array.from(students));
        setHightClass("height-100");
      })
      .catch((error) => {});
  };

  const getTeacherAndStudents = (
    query: string,
    filter: filterType,
    pgNumber?: number
  ) => {
    query = query ? query : serachQuery;
    var students: any = chatItems;
    var searchQuery: any = {
      schoolAdminId: props.userId,
      schoolId: props.schoolId,
      districtId: props.districtId,
      filterType: filter,
      searchQuery: query,
      pageNumber: pgNumber ? pgNumber : pageNumber,
      pageSize: pageSize,
    };

    GetTeacherAndStusentsBySchoolId(props.token, searchQuery)
      .then((response) => {
        var data: ChatSearch[] = response.data.data;
        if (data.length > 0) {
          let studentList = data.filter((item) => item.isClass === false);
          for (let index = 0; index < studentList.length; index++) {
            let studentModal = studentList[index];
            let student = students.find(
              (item: any) =>
                item.id === studentModal.id && item.isClass === false
            );
            if (!student) {
              students.push({
                id: studentModal.id,
                displayName: studentModal.displayName,
                isClass: false,
                recentMassageCount: 0,
                colorCode: "",
                roleId: studentModal.roleId,
                isChatSettingEnabled: studentModal.isChatSettingEnabled,
              });
            }
          }
          let classList = data.filter((item) => item.isClass === true);
          for (let index = 0; index < classList.length; index++) {
            let classModal = classList[index];
            let student = students.find(
              (item: any) => item.id === classModal.id && item.isClass === true
            );
            if (!student) {
              students.push({
                id: classModal.id,
                displayName: classModal.displayName,
                isClass: true,
                recentMassageCount: 0,
                colorCode: classModal.colorCode,
                roleId: 0,
                isChatSettingEnabled: classModal.isChatSettingEnabled,
              });
            }
          }
        }

        if (query) {
          students = students.filter((item: any) =>
            item.displayName
              .toLocaleLowerCase()
              .includes(query.toLocaleLowerCase())
          );
        }

        setchatItems(Array.from(students));
        setHightClass("height-100");
      })
      .catch((error) => {});
  };

  const getSchoolAdmins = (
    query: string,
    filter: filterType,
    pgNumber?: number
  ) => {
    query = query ? query : serachQuery;
    var students: any = chatItems;
    var searchQuery: any = {
      districtAdminId: props.userId,
      districtId: props.districtId,
      filterType: filter,
      searchQuery: query,
      pageNumber: pgNumber ? pgNumber : pageNumber,
      pageSize: pageSize,
    };

    GetSchoolAdminByDistrictId(props.token, searchQuery)
      .then((response) => {
        var data: ChatSearch[] = response.data.data;
        if (data.length > 0) {
          let studentList = data.filter((item) => item.isClass === false);
          for (let index = 0; index < studentList.length; index++) {
            let studentModal = studentList[index];
            let student = students.find(
              (item: any) =>
                item.id === studentModal.id && item.isClass === false
            );
            if (!student) {
              students.push({
                id: studentModal.id,
                displayName: studentModal.displayName,
                isClass: false,
                recentMassageCount: 0,
                colorCode: "",
                roleId: studentModal.roleId,
                isChatSettingEnabled: studentModal.isChatSettingEnabled,
              });
            }
          }
          // let classList = data.filter((item) => item.isClass === true);
          // for (let index = 0; index < classList.length; index++) {
          //   let classModal = classList[index];
          //   let student = students.find(
          //     (item: any) => item.id === classModal.id && item.isClass === true
          //   );
          //   if (!student) {
          //     students.push({
          //       id: classModal.id,
          //       displayName: classModal.displayName,
          //       isClass: true,
          //       recentMassageCount: 0,
          //       colorCode: classModal.colorCode,
          //     });
          //   }
          // }
        }

        if (query) {
          students = students.filter((item: any) =>
            item.displayName
              .toLocaleLowerCase()
              .includes(query.toLocaleLowerCase())
          );
        }

        setchatItems(Array.from(students));
        setHightClass("height-100");
      })
      .catch((error) => {});
  };

  const handleStartChat = (chatSearch: ChatSearch) => {
    if (sendBy <= 0) return;

    resetChatCount(chatSearch);
    let tempChatWindow = [...chatWindow];
    var isPresent =
      chatWindow[0].sendTO.id === chatSearch.id ||
      chatWindow[1].sendTO.id === chatSearch.id ||
      chatWindow[2].sendTO.id === chatSearch.id ||
      chatWindow[3].sendTO.id === chatSearch.id;

    if (isPresent) {
      tempChatWindow = tempChatWindow.map((t) => {
        if (t.sendTO.id === chatSearch.id) {
          t.sendTO.isCollapse = true;
          t.sendTO.hightClass = "height-100";
        } else {
          t.sendTO.isCollapse = false;
          t.sendTO.hightClass = "h-auto";
        }
        return t;
      });
      const arrayvalue = [...tempChatWindow];
      setChatWindow([]);
      setChatWindow(arrayvalue);
      return;
    }

    const activeWindowCount = chatWindow.filter((cw) => cw.isActive).length;

    if (activeWindowCount === chatWindow.length) {
      tempChatWindow.shift();
      tempChatWindow.push({
        isActive: false,
        sendTO: {
          id: 0,
          displayName: "",
          isClass: false,
          profileUrl: "",
          colorCode: "",
          isCollapse: true,
          roleId: 0,
          isChatSettingEnabled: false,
          hightClass: "height-100",
        },
      });
    }

    for (let index = 0; index < tempChatWindow.length; index++) {
      const element = tempChatWindow[index];
      if (!element.isActive && element.sendTO.id === 0) {
        element.isActive = true;
        element.sendTO = {
          id: chatSearch.id,
          displayName: chatSearch.displayName,
          isClass: chatSearch.isClass,
          profileUrl: chatSearch.profileUrl,
          colorCode: chatSearch.colorCode,
          roleId: chatSearch.roleId,
          isChatSettingEnabled: chatSearch.isChatSettingEnabled,
          isCollapse: true,
          hightClass: "height-100",
        };
        tempChatWindow[index] = element;
        break;
      } else {
        tempChatWindow[index].sendTO.isCollapse = false;
        tempChatWindow[index].sendTO.hightClass = "h-auto";
      }
    }

    const arrayvalue = [...tempChatWindow];
    setChatWindow([]);
    setChatWindow(arrayvalue);
  };

  const handleCollapse = () => {
    setIsCollapse(!isCollapse);
    if (isCollapse) {
      setHightClass("h-auto");
    } else {
      setHightClass("height-100");
    }
  };

  const handleOnActive = () => {
    const reference = firebase
      .database()
      .ref(`/${presenceCollection}/` + sendBy);
    reference
      .set(Constant.USERACTIVITY.Active)
      .then(() => console.log("Online presence set"));
    reference
      .onDisconnect()
      .remove()
      .then(() => console.log("On disconnect function configured."));
  };

  const handleOnIdle = () => {
    const reference = firebase
      .database()
      .ref(`/${presenceCollection}/` + sendBy);
    reference
      .set(Constant.USERACTIVITY.Ideal)
      .then(() => console.log("Online presence set"));
    reference
      .onDisconnect()
      .remove()
      .then(() => console.log("On disconnect function configured."));
  };

  const closeChatWindow = (window: number) => {
    let element = chatWindow[window];
    element.isActive = false;
    element.sendTO = {
      id: 0,
      displayName: "",
      isClass: false,
      profileUrl: "",
      colorCode: "",
      roleId: 0,
      isChatSettingEnabled: false,
      isCollapse: false,
      hightClass: "auto",
    };
    chatWindow[window] = element;
    const arrayvalue = [...chatWindow];
    setChatWindow([]);
    setChatWindow(arrayvalue);
  };

  const handleCollapseChat = (window: number) => {
    let element = chatWindow[window];
    element.sendTO.isCollapse = !element.sendTO.isCollapse;
    element.sendTO.hightClass = element.sendTO.isCollapse
      ? "height-100"
      : "h-auto";
    chatWindow[window] = element;
    const arrayvalue = [...chatWindow];
    setChatWindow([]);
    setChatWindow(arrayvalue);
  };

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;

      if (scrollTop + clientHeight >= scrollHeight - 1) {
        var tempPageNumer = pageNumber + 1;
        setPageNumber(0);
        setPageNumber(tempPageNumer);
        getChatItems(serachQuery, filter);
      }
    }
  };

  const handleCloseChat = () => {
    props.closeChatWindow();
  };

  const handleFilterChange = (value: filterType) => {
    setPageNumber(1);

    setFilter(value);
    getChatItems(serachQuery, value, 1);
  };

  const handleSettingsClicked = () => {
    props.onSettingsClicked();
  };

  const redirectToClassDetails = (classId: number) => {
    props.redirectToClassDetails(classId);
  };

  const roleBasedFilter = (roleId: number) => {
    if (roleId === 0 && (filter === "BOTH" || filter === "CLASSES")) {
      return true;
    }
    if (filter === "BOTH") {
      return true;
    }
    if (Constant.UserRoleId.Student === roleId && filter === "STUDENTS") {
      return true;
    }
    if (
      (Constant.UserRoleId.PayTeacher === roleId ||
        Constant.UserRoleId.FreeTeacher === roleId ||
        Constant.UserRoleId.SchoolTeacher === roleId) &&
      filter === "TEACHERS"
    ) {
      return true;
    }
    if (Constant.UserRoleId.District === roleId && filter === "DISTRICTADMIN") {
      return true;
    }

    return false;
  };

  const resetChatCount = async (chatSearch: ChatSearch) => {
    if (chatSearch.isClass) {
      // await firestore.runTransaction(async (t: any) => {
      //   const studentId = chatSearch.id;
      //   let studentRef = firestore
      //     .collection(props.recentChatcollectionName)
      //     .doc("" + studentId);
      //   let recentChatStudent = await t.get(studentRef);
      //   if (recentChatStudent.data()) {
      //     let tempdata = recentChatStudent
      //       .data()
      //       .classes.find((item: any) => item.id === props.sendTo.id);
      //     if (tempdata) {
      //       let classList = recentChatStudent.data().classes;
      //       let objIndex = classList.findIndex(
      //         (item: any) => item.id === props.sendTo.id
      //       );
      //       classList[objIndex].recentMassageCount = 0;
      //       t.update(studentRef, { classes: classList });
      //     }
      //   }
      // });
    } else {
      await firestore.runTransaction(async (t: any) => {
        const recentChatSendByRef = firestore
          .collection(props.recentChatcollectionName)
          .doc("" + sendBy);

        const recentChatSendBy = await t.get(recentChatSendByRef);
        if (recentChatSendBy.data()) {
          let data = recentChatSendBy
            .data()
            .chatTo.find((item: any) => item.id === chatSearch.id);
          if (data) {
            let chatToList = recentChatSendBy.data().chatTo;
            let objIndex = chatToList.findIndex(
              (item: any) => item.id === chatSearch.id
            );
            chatToList[objIndex].recentMassageCount = 0;
            t.update(recentChatSendByRef, { chatTo: chatToList });
          }
        }
      });
    }
  };

  const onMessageAreaClicked = (chatSearch: any) => {
    resetChatCount(chatSearch);
  };

  return (
    <>
      <div className="w-full fixed bottom-0 right-0 z-50 ">
        <div className="flex items-end justify-end mr-24">
          {chatWindow.map((element, index) => (
            <Fragment key={index}>
              {element.isActive && element.sendTO.id > 0 && sendBy > 0 && (
                <>
                  <TeacherChatMessage
                    messagecollectionName={props.messagecollectionName}
                    recentChatcollectionName={props.recentChatcollectionName}
                    position={"right-" + position}
                    sendBy={sendBy}
                    displayName={props.displayName}
                    sendTo={element.sendTO}
                    onlineUsers={onlineUsers}
                    firestore={firestore}
                    db={db}
                    isClass={element.sendTO.isClass}
                    token={props.token}
                    schoolId={props.schoolId}
                    districtId={props.districtId}
                    sendByRoleId={props.roleId}
                    impersonatedUser={props.impersonatedUser}
                    closeChatWindow={() => closeChatWindow(index)}
                    redirectToClassDetails={redirectToClassDetails}
                    handleCollapse={() => {
                      handleCollapseChat(index);
                    }}
                    isCollapse={element.sendTO.isCollapse}
                    hightClass={element.sendTO.hightClass}
                    onMessageAreaClicked={() => {
                      onMessageAreaClicked(element.sendTO);
                    }}
                  />
                  <span className="invisible">
                    {(position = position + 10)}
                  </span>
                </>
              )}
            </Fragment>
          ))}
          <div className="w-96 bottom-0 right-28 bg-tertiary-green z-50 rounded-xl overflow-hidden shadow-2xl ">
            <div
              className={`flex-1 justify-between flex flex-col ${hightClass} max-h-46 shadow-lg`}
            >
              <div className="flex sm:items-center justify-between py-3 bg-tertiary-green px-3">
                <div className="flex items-center space-x-4">
                  <div className="flex flex-col leading-tight">
                    <div className="mt-1 flex items-center ml-1">
                      <span className="text-white mr-3 text-base">
                        Messaging
                      </span>
                    </div>
                  </div>
                </div>
                <div className="flex items-center">
                  <button
                    className="inline-flex group items-center justify-center rounded-full h-6 w-6 transition duration-500 ease-in-out text-white hover:bg-white focus:outline-none"
                    onClick={handleSettingsClicked}
                  >
                    <SettingsIcon className="w-4 h-4 fill-current text-white group-hover:text-tertiary-green " />
                  </button>
                  <button
                    onClick={handleCollapse}
                    type="button"
                    className="inline-flex items-end group justify-center rounded-full h-6 w-6 transition duration-500 ease-in-out text-white hover:bg-white focus:outline-none"
                  >
                    <MinusIcon className="w-4 h-4 fill-current text-white group-hover:text-tertiary-green"></MinusIcon>
                  </button>
                  <button
                    type="button"
                    onClick={handleCloseChat}
                    className="inline-flex items-center justify-center group rounded-full h-6 w-6 transition duration-500 ease-in-out text-white hover:bg-white focus:outline-none"
                  >
                    <CloseIcon className="w-4 h-4 fill-current text-white group-hover:text-tertiary-green "></CloseIcon>
                  </button>
                </div>
              </div>
              {isCollapse && (
                <>
                  <div className="px-3 flex items-center bg-tertiary-green pb-2">
                    <input
                      type="text"
                      onChange={debouncedChangeHandler}
                      placeholder="Search"
                      className="w-full focus:outline-none text-xs focus:placeholder-gray-400 border border-gray-300 text-gray-600 placeholder-gray-400 px-4 bg-white rounded-lg py-1.5"
                    />
                    {props.roleId !== Constant.UserRoleId.District && (
                      <div
                        className={`ml-1 p-1 w-6 h-6 group cursor-pointer inline-flex items-center justify-center rounded-full h-6 w-6 transition duration-500 ease-in-out hover:bg-white hover:shadow-lg focus:outline-none
                    ${
                      filter === "STUDENTS" || filter === "CLASSES"
                        ? "bg-white shadow-lg text-tertiary-green"
                        : "text-white"
                    }
                  `}
                        onClick={() => {
                          setShowFilter(true);
                        }}
                      >
                        <ChatFilterIcon className="w-4 h-4 group-hover:text-tertiary-green" />
                      </div>
                    )}
                  </div>

                  <div className="pl-4 flex items-center py-2 rounded-t-xl bg-white shadow-inner">
                    {!showFilter && (
                      <>
                        <span className="text-sm text-gray-500">Recent</span>
                        {filter !== "BOTH" && (
                          <span className="ml-1 text-xs flex mr-auto items-center px-1.5 h-4 bg-blue-400 text-white rounded-xl">
                            Filter applied {filter.toLowerCase()}
                          </span>
                        )}
                      </>
                    )}
                  </div>

                  <>
                    <div
                      id="chatUser"
                      className={`flex flex-col relative h-full  overflow-y-auto bg-white scrollbar-thumb-blue scrollbar-thumb-rounded scrollbar-track-blue-lighter scrollbar-w-2 scrolling-touch
                      ${hightClass} 
                      `}
                      onScroll={onScroll}
                      ref={listInnerRef}
                    >
                      {showFilter && (
                        <Filter
                          roleId={props.roleId}
                          filterType={filter}
                          handleShowFilter={setShowFilter}
                          saveFilter={handleFilterChange}
                        />
                      )}
                      {!showFilter && (
                        <>
                          {!loading &&
                            reactChats?.length > 0 &&
                            reactChats[0].chatTo
                              .filter((item: any) =>
                                item.displayName
                                  .toLocaleLowerCase()
                                  .includes(serachQuery.toLocaleLowerCase())
                              )
                              .filter((item: any) =>
                                roleBasedFilter(item.roleId)
                              )
                              .sort((a, b) => b.updatedAt - a.updatedAt)
                              .map((chatsearch: ChatSearch, index: number) => (
                                <Fragment key={index}>
                                  {(chatsearch.isClass = false)}
                                  <UserTile
                                    onlineUsers={onlineUsers}
                                    chatSearch={chatsearch}
                                    roleId={props.roleId}
                                    handleStartChat={handleStartChat}
                                  ></UserTile>
                                </Fragment>
                              ))}
                          {!loading &&
                            reactChats?.length > 0 &&
                            reactChats[0].classes
                              .filter((item: any) =>
                                item.displayName
                                  .toLocaleLowerCase()
                                  .includes(serachQuery.toLocaleLowerCase())
                              )
                              .filter((item: any) =>
                                roleBasedFilter(item.roleId)
                              )
                              .map((chatsearch: ChatSearch, index: number) => (
                                <Fragment key={index}>
                                  {(chatsearch.isClass = true)}
                                  <UserTile
                                    onlineUsers={onlineUsers}
                                    chatSearch={chatsearch}
                                    roleId={props.roleId}
                                    handleStartChat={handleStartChat}
                                  ></UserTile>
                                </Fragment>
                              ))}
                          {!loading &&
                            reactChats?.length > 0 &&
                            chatItems &&
                            chatItems
                              .filter(
                                (item: any) =>
                                  !reactChats[0].chatTo.includes(
                                    (el: any) =>
                                      el.id === item.id && !item.isClass
                                  )
                              )
                              .filter((item: any) =>
                                roleBasedFilter(item.roleId)
                              )
                              .map((chatsearch: ChatSearch, index) => (
                                <Fragment key={index}>
                                  {!chatsearch.isClass &&
                                    !reactChats[0].chatTo.find(
                                      (el: any) => el.id === chatsearch.id
                                    ) && (
                                      <UserTile
                                        onlineUsers={onlineUsers}
                                        chatSearch={chatsearch}
                                        roleId={props.roleId}
                                        handleStartChat={handleStartChat}
                                      ></UserTile>
                                    )}
                                  {chatsearch.isClass &&
                                    !reactChats[0].classes.find(
                                      (el: any) => el.id === chatsearch.id
                                    ) && (
                                      <UserTile
                                        onlineUsers={onlineUsers}
                                        chatSearch={chatsearch}
                                        roleId={props.roleId}
                                        handleStartChat={handleStartChat}
                                      ></UserTile>
                                    )}
                                </Fragment>
                              ))}
                          {!loading &&
                            (reactChats?.length === 0 || !reactChats) &&
                            chatItems &&
                            chatItems
                              .filter((chatsearch: ChatSearch) => {
                                return (
                                  filter === "BOTH" ||
                                  chatsearch.isClass === (filter === "CLASSES")
                                );
                              })
                              .filter((item: any) =>
                                roleBasedFilter(item.roleId)
                              )
                              .map((chatsearch: ChatSearch, index) => (
                                <Fragment key={index}>
                                  <UserTile
                                    onlineUsers={onlineUsers}
                                    chatSearch={chatsearch}
                                    roleId={props.roleId}
                                    handleStartChat={handleStartChat}
                                  ></UserTile>
                                </Fragment>
                              ))}
                        </>
                      )}
                    </div>
                  </>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default TeacherChatRoom;

import { useCallback, useEffect, useRef } from "react";
import { initRequest } from "./learnosityAuthorInit";
import { getRequest } from "../../../../api/userActivity";

export default function LearnosityQuestionEditor(props: any) {
  let authorApp;
  const isInitialized = useRef(false);

  const initialization = useCallback(async () => {
    if (isInitialized.current) return;
      
    isInitialized.current = true;
    const callbacks = {
      readyListener: await async function () {
        await authorApp.on("render:item", async function () {
          props.setQuestionApp(authorApp);
          const questions = authorApp.getItem().questions;
          if (questions.length > 0 && props.questionData === undefined) {
            const itemRef = authorApp.getItem().item.reference;
            const widgetRef = questions[props.questionIndex].reference;
            return authorApp.navigate(
              "items/" + itemRef + "/widgets/" + widgetRef + ""
            );
          }
          if (props.questionData) {
            authorApp.setWidget(props.questionData);
            props.setQuestionData(props.questionData);
          } else {
            authorApp.setWidget(props.payload, props.payloadLabel);
            props.setQuestionData(props.payload);
          }
        });
        authorApp.on("widgetedit:preview:changed", function (event) {
          props.setQuestionData(authorApp.getWidget());
        });
      },
      errorListener: function (e) {
        console.log("Error Code ", e.code);
        console.log("Error Message ", e.msg);
        console.log("Error Detail ", e.detail);
      },
    };

    const request = initRequest(
      props.itemRef.toString(),
      "questions",
      props.questionTypeId
    );
    getRequest("author", JSON.stringify(request)).then(async (response) => {
      if (response.data) {
        authorApp = await window["LearnosityAuthor"]?.init(
          response.data,
          callbacks
        );
      }
    });
  }, []);

  const replaceheading = () => {
    let a = document.querySelector(
      ".lrn-qe-toolbar-left h3 span[data-lrn-qe-layout-widget-title='rendered']"
    );
    let b = document.querySelector(".lrn-qe-toolbar-left h3");
    const check = document.querySelector(
      ".lrn-qe-toolbar-left h3 #heading-question-builder"
    );
    if (a?.innerHTML == "Choice matrix – standard" && b && !check) {
      const div = document.createElement("div");
      div.classList.add("lrn-qe-h3");
      div.classList.add("lrn-qe-inline");
      div.classList.add("lrn-question-type-name");
      div.setAttribute("id", "heading-question-builder");
      div.innerText = "Match table Grid";

      b.appendChild(div);
    } else if (a?.innerHTML == "Multiple choice – standard" && b && !check) {
      const div = document.createElement("div");
      div.classList.add("lrn-qe-h3");
      div.classList.add("lrn-qe-inline");
      div.classList.add("lrn-question-type-name");
      div.setAttribute("id", "heading-question-builder");
      div.innerText = "Multiple choice";
      b.appendChild(div);
    } else if (a && b && !check) {
      const div = document.createElement("div");
      div.classList.add("lrn-qe-h3");
      div.classList.add("lrn-qe-inline");
      div.classList.add("lrn-question-type-name");
      div.setAttribute("id", "heading-question-builder");
      div.innerHTML = a.innerHTML;
      b.appendChild(div);
    }
  };

  useEffect(() => {
    initialization();
  }, [initialization]);
  useEffect(() => {
    replaceheading();
  });
  return (
    <div>
      <div className="main-content">
        <div id="learnosity-author"></div>
      </div>
    </div>
  );
}

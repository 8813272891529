import { AxiosHelper } from "../../environment/axiosConfig";
import APIConstant from "../../utils/constant/apiConstant";
import { ISuperSheetSearch } from "../../model/interface/superSheetSearch";

export const getSuperSheetSubjects = (
    teacherId: number,
    roleId: number,
  ) => {
    return AxiosHelper.axiosInstance().get(APIConstant.ASSIGNMENT_API.GetSubjects, {
      params: {
        teacherId: teacherId,
        roleId: roleId,
      },
    });
};
export const getSuperSheetGrades = (
  ) => {
    return AxiosHelper.axiosInstance().get(APIConstant.APP_GRADES_ALL);
};

export const getSuperSheets = (superSheetSearch: ISuperSheetSearch) => {
  return AxiosHelper.axiosInstance().get(APIConstant.SuperSheet_API.GetAll, {
    params: {
      teacherId: superSheetSearch.teacherId,
      roleId: superSheetSearch.roleId,
      subjectId: superSheetSearch.subjectId,
      gradeId: superSheetSearch.gradeId,    
      pageNumber: superSheetSearch.pageNumber,
      pageSize: superSheetSearch.pageSize,
    },
  });
};

export const previewSuperSheet = (
  path:string
) => {
  return AxiosHelper.axiosInstance().get(APIConstant.SuperSheet_API.Preview,
    {
    params:{
      supersheetFile:path
    },
  });
};

export const downloadaAnswerKeySupersheet = (
  supersheetId: any,
  userId: any
) => {
  return AxiosHelper.axiosInstance().get(
    APIConstant.SuperSheet_API.DownloadAnswerKey,
    {
      responseType: "blob",
      params: {
        supersheetId: supersheetId,
        userId: userId,
      },
    }
  );
};
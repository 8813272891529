import { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import Select, { MultiValue } from "react-select";
import DatePicker from "react-datepicker";
import {
  DEFAULT_PAGE_NUMBER,
  DEFAULT_PAGE_SIZE,
} from "../../../../../utils/pagingConstant";
import Pagination from "../../../../shared/pagination/pagination";
import PageSize from "../../../../shared/pagination/pageSize";

import "../../../../../css/style.css";
import { getSchoolByDistrictId } from "../../../../../api/teacher/school";
import RouteConstant from "../../../../../utils/constant/routeConstant";
import Breadcrumb from "../../shared/breadcrumb";
import { IBreadcrumb } from "../../../../../model/common/breadcrumb";
import InfoMessage from "../../../../shared/infoMessage";
import { useTranslation } from "react-i18next";
import gradeBook from "../../../../../mockData/reports/gradeBook.json";
import * as uuid from "uuid";
import { ISelectElement } from "../../../../../model/interface/selectElement";
import { getClassesBySchoolAccountId } from "../../../../../api/manageUser/class";
import { getAllSubjectsByUserId } from "../../../../../api/student/studentSubjectApi";
import { ClassApi } from "../../../../../api/teacher/classManagementApi";
import Constant from "../../../../../utils/constant/constant";
import { getSchoolSubjectsBySchoolAccountId } from "../../../../../api/teacher/school";
import liftoffGrowth from "../../../../../mockData/reports/liftoffGrowth.json";
import { LineGraphIcon } from "../../../../../assets/icons";
import { hasLiftOffAccess } from "../../../../../utils/helper";

function LiftoffGrowthReport(props) {
  const { t } = useTranslation();
  const [pageNumber, setPageNumber] = useState(DEFAULT_PAGE_NUMBER);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [resetPageNumber, setResetPageNumber] = useState<boolean>(false);
  const defaultValue = { label: "All", value: "0" };
  const [schools, setSchools] = useState<Array<any>>();
  const [classes, setClasses] = useState<Array<any>>();
  const [subjects, setSubjects] = useState<Array<any>>();
  const [selectedSchool, setSelectedSchool] =
    useState<ISelectElement>(defaultValue);
  const [selectedClass, setSelectedClass] = useState<Array<ISelectElement>>([]);
  const [selectedSubject, setSelectedSubject] =
    useState<Array<ISelectElement>>();
  const [selectedStartDate, setSelectedStartDate] = useState<
    Date | undefined
  >();
  const [selectedEndDate, setSelectedEndDate] = useState<Date | undefined>();
  const [selectedStandard, setSelectedStandard] = useState<
    Array<ISelectElement>
  >([]);
  const [isDistrictUser] = useState<boolean>(
    props.userContext.districtId ? true : false
  );
  const [isSchoolAdmin] = useState<boolean>(
    props.userContext.roleId === Constant.UserRoleId.SchoolAdmin ? true : false
  );
  const [isTeacher] = useState<boolean>(
    props.userContext.roleId === Constant.UserRoleId.SchoolTeacher
      ? true
      : false
  );
  const [selectedfrequency, setSelectedfrequency] = useState<
    Array<ISelectElement>
  >([]);
  const [noOffrequency, setNoOffrequency] = useState<Array<ISelectElement>>([]);
  const onReset = () => {
    setSelectedStartDate(undefined);
    setSelectedEndDate(undefined);
  };

  const breadcrumbItems = () => {
    const items: Array<IBreadcrumb> = [
      { name: t("Breadcrumb.Home"), url: RouteConstant.ROUTE_DASHBOARD },
      {
        name: t("Breadcrumb.Reports"),
        url: RouteConstant.REPORTS,
      },
      { name: t("Breadcrumb.LiftoffGrowthReport"), url: "" },
    ];

    return items;
  };
  const onPageChange = (pageNo) => {
    setPageNumber(pageNo);
  };

  const onPageSizeChange = (pageSize) => {
    setPageNumber(DEFAULT_PAGE_NUMBER);
    setPageSize(pageSize);
    setResetPageNumber(true);
  };

  useEffect(() => {
    hasLiftOffAccess(props.userContext).then((res) => {
      if (!res) {
        window.location.replace(RouteConstant.FORBIDDEN);
      }
    });
    if (isDistrictUser) {
      getSchools();
    } else {
      getClasses();
      getSubjects();
    }
  }, [isDistrictUser]);

  useEffect(() => {
    getClasses();
  }, [selectedSchool]);

  useEffect(() => {
    setSubjects([]);
    getSubjects();
  }, [selectedClass]);

  const getSchools = async () => {
    const resp = await getSchoolByDistrictId(
      props.userContext?.districtId ?? 0
    );
    if (resp?.data?.length) {
      const schools: Array<ISelectElement> = resp?.data.map((values: any) => {
        return {
          label: values?.name,
          value: values?.schoolAccountId,
        };
      });

      setSchools(schools);
    }
  };

  const getClasses = async () => {
    let resp;
    if (isSchoolAdmin || isDistrictUser) {
      const schoolId = isDistrictUser
        ? selectedSchool.value
        : props.userContext?.schoolAccountId;
      resp = await getClassesBySchoolAccountId(
        schoolId || 0,
        props.profile?.currentSchoolYear.schoolYearId ?? 0
      );
    } else if (isTeacher) {
      resp = await ClassApi.getTeacherClasses(
        props.userContext?.userId || 0,
        0,
        props.profile.currentSchoolYear.schoolYearId
      );
    }
    if (resp?.data?.length) {
      const classes: Array<ISelectElement> = resp?.data.map((values: any) => {
        return {
          label:
            values?.primaryTeacherLastName && values?.primaryTeacherFirstName
              ? values?.displayName +
                "- " +
                values?.primaryTeacherLastName +
                ", " +
                values?.primaryTeacherFirstName
              : values?.name,
          value: values?.classId,
        };
      });
      setClasses(classes);
    }
  };

  const getSubjects = async () => {
    let resp;
    if (selectedClass?.length) {
      const selectedClassIds = Array.prototype.map.call(
        selectedClass,
        (s) => s.value
      );
      resp = await ClassApi.getClassSubjectsByClassIds(
        selectedClassIds?.join(",")
      );
    } else if (isTeacher) {
      resp = await getAllSubjectsByUserId(props.userContext?.userId);
    } else if (isSchoolAdmin) {
      resp = await getSchoolSubjectsBySchoolAccountId([
        props.userContext?.schoolAccountId,
      ]);
    }

    if (resp?.data?.data?.length || resp?.data.length) {
      var data = resp?.data.data ?? resp?.data;
      const subjects: Array<ISelectElement> = data.map((values: any) => {
        return {
          label: values?.name,
          value: values?.subjectId ?? values?.id,
        };
      });
      setSubjects(subjects);
    }
  };

  function handleClassChange(e: any) {
    setSelectedClass(e);
  }

  function handleSubjectChange(e: any) {
    setSelectedSubject(e);
  }

  function handleSchoolChange(option: ISelectElement | null) {
    if (option) {
      setSelectedSchool(option);
    }
  }

  function handleStandardChange(options: MultiValue<ISelectElement> | null) {
    if (options) {
      setSelectedStandard(options.map((option) => option));
    }
  }

  function handleFrequencyChange(options: MultiValue<ISelectElement> | null) {
    if (options) {
      setSelectedfrequency(options.map((option) => option));
    }
  }

  function handleNoOfFrequencyChange(options: MultiValue<ISelectElement> | null) {
    if (options) {
      setNoOffrequency(options.map((option) => option));
    }
  }

  return (
    <div className="min-w-0 w-full">
      <div className="lg:flex lg:items-center px-4 py-3 lg:px-8 border-b">
        <Breadcrumb items={breadcrumbItems()} />
        <div className="lg:w-[55%] text-lg flex justify-between gap-2 items-end">
          <h1 className="text-primary-violet font-medium flex justify-start mb-0 pb-0">
            Liftoff Growth Report
          </h1>
        </div>
      </div>
      <div className="px-2 sm:px-6  lg:px-8 py-1 relative my-4">
        <div className="bg-gray-100 w-full rounded-lg p-5">
          <InfoMessage message="View activities that have been assigned to your class.You can select a specific assignment to open in new window to display the results for the activity, expand grouped activities and select students to view student details including progress report,student graded work and usage" />
          <div className="bg-white px-3 pt-2">
            <div className="shadow overflow-x-auto xl:overflow-x-visible mt-5">
              <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-4 mb-4">
                {isDistrictUser && (
                  <div className="flex flex-col">
                    <label className="block mb-1 text-sm text-gray-500">
                      School
                    </label>

                    <Select
                      options={schools}
                      value={selectedSchool}
                      onChange={handleSchoolChange}
                    />
                  </div>
                )}

                <div className="flex flex-col">
                  <label className="block mb-1 text-sm text-gray-500">
                    Class
                  </label>
                  <Select
                    options={classes}
                    value={selectedClass}
                    onChange={(e) => handleClassChange(e)}
                    isMulti={true}
                    closeMenuOnSelect={false}
                  />
                </div>

                <div className="flex flex-col">
                  <label className="block mb-1 text-sm text-gray-500">
                    Subject
                  </label>

                  <Select
                    options={subjects}
                    value={selectedSubject}
                    isMulti={true}
                    closeMenuOnSelect={false}
                    onChange={(e) => handleSubjectChange(e)}
                  />
                </div>
                <div className="flex flex-col">
                  <label className="block mb-1 text-sm text-gray-500">
                    Standard
                  </label>

                  <Select
                    options={liftoffGrowth.standards.map(standard => ({
                      value: standard.value.toString(), // Convert number to string
                      label: standard.label
                    }))}
                    value={selectedStandard}
                    onChange={handleStandardChange}
                    isMulti={true}
                    closeMenuOnSelect={false}
                  />
                </div>
                <div className="flex flex-col">
                  <label className="block mb-1 text-sm text-gray-500">
                    How Often
                  </label>
                  <Select
                    value={selectedfrequency}
                    options={liftoffGrowth.frequency.map(freq => ({
                      value: freq.value.toString(), // Convert number to string
                      label: freq.label
                    }))}
                    isMulti={true}
                    closeMenuOnSelect={false}
                    onChange={handleFrequencyChange}
                  />
                </div>
                <div className="flex flex-col">
                  <label className="block mb-1 text-sm text-gray-500">
                    Number of weeks or Months
                  </label>
                  <Select
                    value={noOffrequency}
                    options={liftoffGrowth.noOffrequency.map(noFreq => ({
                      value: noFreq.value.toString(), // Convert number to string
                      label: noFreq.label                    
                    }))}
                    isMulti={true}
                    closeMenuOnSelect={false}
                    onChange={handleNoOfFrequencyChange}
                  />
                </div>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-4 mb-4 mt-4">
                <div className="flex flex-col">
                  <label className="block mb-1 text-sm text-gray-500">
                    From Date
                  </label>

                  <DatePicker
                    selected={selectedStartDate}
                    onChange={(e) => setSelectedStartDate(e)}
                    className="react-datepicker__input-container py-1.5 px-3 block w-full shadow-sm focus:ring-primary-violet focus:border-primary-violet border border-gray-300 rounded-md "
                  />
                </div>

                <div className="flex flex-col">
                  <label className="block mb-1 text-sm text-gray-500">
                    To Date
                  </label>

                  <DatePicker
                    selected={selectedEndDate}
                    onChange={(e) => setSelectedEndDate(e)}
                    className="react-datepicker__input-container py-1.5 px-3 block w-full shadow-sm focus:ring-primary-violet focus:border-primary-violet border border-gray-300 rounded-md "
                  />
                </div>
              </div>

              <div className="flex gap-4 justify-end">
                <button
                  onClick={onReset}
                  className="bg-transparent border my-auto border-primary-violet shadow-sm py-2 px-4 flex justify-center text-sm font-medium text-primary-violet hover:text-white hover:bg-light-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3 transition ease-in-out duration-500"
                >
                  Reset
                </button>
                <button className="bg-primary-violet border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-dark-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3 ">
                  Run Report
                </button>
                <button className="bg-primary-violet border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-dark-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3 ">
                  Export Data
                </button>
                <button className="bg-primary-violet border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-dark-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3 ">
                  Print Report
                </button>
              </div>

              <div className="border-b border-gray-400 py-2 mb-2"></div>
              <div className="bg-white py-5 px-5">
                <div className="mb-4 pb-4 border-b-2 overflow-x-auto xl:overflow-x-visible">
                  <table className="table w-full ">
                    <thead>
                      <tr className="bg-primary-violet  text-white ">
                        <th className="text-left p-3 text-sm font-normal">
                          Students
                        </th>
                        <th className="text-left p-3 text-sm font-normal">
                          Starting Grade
                        </th>
                        <th className="text-left p-3 text-sm font-normal">
                          Current Grade
                        </th>
                        <th className="text-left p-3 text-sm font-normal">
                          Growth Graph
                        </th>
                        <th className="text-left p-3 text-sm font-normal">
                          12/01
                        </th>
                        <th className="text-left p-3 text-sm font-normal">
                          12/08
                        </th>
                        <th className="text-left p-3 text-sm font-normal">
                          12/15
                        </th>
                        <th className="text-left p-3 text-sm font-normal">
                          12/22
                        </th>
                        <th className="text-left p-3 text-sm font-normal">
                          12/29
                        </th>
                        <th className="text-left p-3 text-sm font-normal">
                          01/05
                        </th>
                        <th className="text-left p-3 text-sm font-normal">
                          01/12
                        </th>
                        <th className="text-left p-3 text-sm font-normal">
                          01/19
                        </th>
                        <th className="text-left p-3 text-sm font-normal">
                          01/26
                        </th>

                        <th className="text-left p-3 text-sm font-normal">
                          02/02
                        </th>
                        <th className="text-left p-3 text-sm font-normal">
                          02/09
                        </th>
                        <th className="text-left p-3 text-sm font-normal">
                          02/16
                        </th>
                        <th className="text-left p-3 text-sm font-normal">
                          02/23
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {liftoffGrowth.data &&
                        liftoffGrowth.data.length > 0 &&
                        liftoffGrowth.data?.map((classItem, index) => {
                          return (
                            <Fragment key={uuid.v4()}>
                              <tr
                                className={`hover:bg-primary-violet/20 transition ease-in-out duration-300 ${
                                  index % 2 === 0
                                    ? "bg-white"
                                    : "bg-primary-violet/10"
                                }`}
                              >
                                <td className="text-sm p-3 ">
                                  {classItem.studentName}
                                </td>
                                <td className="text-sm p-3 ">
                                  {classItem.startingGrade}
                                </td>
                                <td className="text-sm p-3 ">
                                  {classItem.currentGrade}
                                </td>
                                <td className="text-sm p-3 ">
                                  <LineGraphIcon className="h-4 w-4" />
                                </td>
                                <td className="text-sm p-3 ">{classItem.W1}</td>
                                <td className="text-sm p-3 ">{classItem.W2}</td>
                                <td className="text-sm p-3 ">{classItem.W3}</td>
                                <td className="text-sm p-3 ">{classItem.W4}</td>
                                <td className="text-sm p-3 ">{classItem.W5}</td>
                                <td className="text-sm p-3 ">{classItem.W6}</td>

                                <td className="text-sm p-3 ">{classItem.W7}</td>
                                <td className="text-sm p-3 ">{classItem.W8}</td>
                                <td className="text-sm p-3 ">{classItem.W9}</td>
                                <td className="text-sm p-3 ">
                                  {classItem.W10}
                                </td>
                                <td className="text-sm p-3 ">
                                  {classItem.W11}
                                </td>
                                <td className="text-sm p-3 ">
                                  {classItem.W12}
                                </td>
                                <td className="text-sm p-3 ">
                                  {classItem.W13}
                                </td>
                              </tr>
                            </Fragment>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
                <div
                  className={`${pageNumber} hidden sm:flex-1 sm:flex sm:items-center sm:justify-between`}
                >
                  {gradeBook.classData.length !== 0 && (
                    <Pagination
                      totalRecords={
                        gradeBook.classData?.length
                          ? gradeBook.classData?.length
                          : 0
                      }
                      pageSize={pageSize}
                      pageNumber={pageNumber}
                      onPageChange={onPageChange}
                      reset={resetPageNumber}
                      bgColor="primary-violet"
                    />
                  )}
                  {gradeBook.classData?.length !== 0 && (
                    <PageSize
                      pageSize={pageSize}
                      onPageSizeChange={onPageSizeChange}
                      bgColor="primary-violet"
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default connect((state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
})(LiftoffGrowthReport);

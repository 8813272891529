import { connect } from "react-redux";
import { useEffect, useState } from "react";
import { Button, Alert } from "@windmill/react-ui";
import {
  CanvasIcon,
  CleverIcon,
  ClassLinkIcon,
  GoogleClassroomIcon,
  GoogleLoginIcon,
} from "../../../assets/icons/index";
import { updateProfile } from "../../../api/profileApi";
import Constant from "../../../utils/constant/constant";
import UserProfileModal from "../../../model/users/userProfile";
import "react-html5-camera-photo/build/css/index.css";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { handleLoginWithCredentials } from "../../../utils/googleHelper";
import RouteConstant from "../../../utils/constant/routeConstant";
import { GoogleApi } from "../../../api/google/googleApi";
import { useConfirmation } from "../../shared/confirmation/confirmationService";
import { handleCanvasAuthenticationWithCredentials } from "../../../utils/canvasHelper";
import { CanvasApi } from "../../../api/canvas/canvasApi";
import Loader from "../../shared/loader";
import { CanvasConfiguration } from "../../../environment/setup";
import userApi from "../../../api/userApi";
import { getProfileUpgradePermission } from "../../shared/permissionHelper";
import constant from "../../../utils/constant/constant";
import UpGradeMessagePopup from "../../shared/upGradeMessagePopup";

const Profile = (props: any) => {
  const confirm = useConfirmation();
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [firstName, setFirstName] = useState(props.profile.firstName);
  const [lastName, setLastname] = useState(props.profile.lastName);
  const [password, setPassword] = useState(props.profile.passwordHash);
  const [loading, showLoading] = useState<boolean>(false);
  const [confirmPassword, setConfirmPassword] = useState(
    props.profile.passwordHash
  );
  const [email, setEmail] = useState(props.profile.email);
  const [isPassWordNotMatch, setIsPassWordNotMatch] = useState(false);
  const [credentials, setCredentials] = useState({
    accountLoginName: "",
    username: "",
    passwordHash: "",
  });
  const [upgrade, setUpgrade] = useState(false);
  const [showUpgradePopup, setShowUpgradePopup] = useState<boolean>(false);

  useEffect(() => {
    checkUpgradeRequired();
  }, []);

  const checkUpgradeRequired = async () => {
    if (getProfileUpgradePermission(props.userContext?.roleId ?? 0)) {
      setUpgrade(true);
    } else {
      setUpgrade(false);
    }
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  function handleConfirmPassword(confirmPassword) {
    setConfirmPassword(confirmPassword);
    if (password !== confirmPassword) {
      setIsPassWordNotMatch(true);
    } else {
      setIsPassWordNotMatch(false);
    }
  }

  function bind(data) {
    setIsAlertOpen(true);
  }

  function handleUnLinkGoogle() {
    confirm({
      variant: "danger",
      title: "Please Confirm",
      description:
        "Are you sure you want to unlink the Google Classroom Account?",
    }).then((d) => {
      showLoading(true);
      GoogleApi.unlinkGoogleUser(props?.profile?.userId)
        .then((r) => {
          showLoading(false);
          window.location.reload();
        })
        .catch(() => {
          showLoading(false);
        });
    });
  }

  function getLoginDetails(userId: number) {
    userApi.GetUserDetail(userId).then((res) => {
      if (res.data) {
        setCredentials({
          accountLoginName: res.data.accountId,
          username: res.data.username,
          passwordHash: res.data.passwordHash,
        });
        setPassword(atob(res.data.passwordHash));
        setConfirmPassword(atob(res.data.passwordHash));
      }
    });
  }

  useEffect(() => {
    getLoginDetails(props.profile.userId);
  }, []);

  function handleUnLinkCanvas() {
    confirm({
      variant: "danger",
      title: "Please Confirm",
      description: "Are you sure you want to unlink the Canvas Account?",
    }).then((d) => {
      showLoading(true);
      CanvasApi.unlinkCanvasUser(props?.profile?.userId)
        .then((r) => {
          showLoading(false);
          window.location.reload();
        })
        .catch(() => {
          showLoading(false);
        });
    });
  }

  function handleProfileSubmit() {
    var isPasswordMatch = false;
    if (password) {
      if (password !== confirmPassword) {
        isPasswordMatch = false;
      } else {
        isPasswordMatch = true;
      }
    } else {
      isPasswordMatch = true;
    }
    if (isPasswordMatch) {
      var userProfileModal = new UserProfileModal(
        Constant.Profile.PROFILE,
        props.profile.userId,
        firstName,
        lastName,
        email,
        password,
        "",
        "",
        0,
        0,
        "",
        "",
        "",
        0,
        false
      );
      updateProfile(userProfileModal)?.then((r) => {
        bind(r);
        toast.success("Profile updated successfully");
      });
    }
  }

  function redirectToGoogleclassNameroomLogin() {
    handleLoginWithCredentials(
      "Teacher",
      RouteConstant.UserProfile,
      credentials.accountLoginName,
      credentials.username,
      credentials.passwordHash
    );
  }

  function redirectToCanvasLogin() {
    getCanvasAccountSettings();
  }

  const getCanvasAccountSettings = () => {
    showLoading(true);
    CanvasApi.getCanvasAccountSettingsByAccountLoginName(
      props.profile?.accountLoginName!
    )
      .then((r) => {
        showLoading(false);
        if (r.data && r.data.endPointURL) {
          handleCanvasAuthenticationWithCredentials(
            r.data,
            RouteConstant.UserProfile,
            credentials.accountLoginName,
            credentials.username,
            credentials.passwordHash
          );
        } else {
          toast.error(
            "Your school has not configured canvas account settings yet please contact administrator."
          );
        }
      })
      .catch(() => {
        showLoading(false);
      });
  };

  return (
    <>
      {isAlertOpen && (
        <Alert
          type="success"
          onClose={() => {
            setIsAlertOpen(false);
          }}
        >
          Data save successfully
        </Alert>
      )}

      <form onSubmit={handleSubmit(handleProfileSubmit)}>
        {loading && <Loader />}

        {showUpgradePopup && (
          <UpGradeMessagePopup
            togglePopup={setShowUpgradePopup}
            roleId={props.userContext?.roleId ?? 0}
            message={constant.UPGRADE_MESSAGE_SCHOOL_ACCOUNT}
          />
        )}
        <div className="grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-2">
          <div>
            <label className="block text-sm font-medium text-gray-500">
              Account ID
            </label>
            <input
              type="text"
              disabled
              defaultValue={props.profile.accountLoginName}
              className="mt-1 bg-gray-100 focus:ring-primary-green focus:border-primary-green block w-full  max-w-sm shadow-sm sm:text-sm border border-gray-300 rounded h-10 px-2 py-4"
            />
          </div>
          <div></div>
          <div>
            <label className="block text-sm font-medium text-gray-500">
              Username
            </label>

            <input
              type="text"
              disabled
              defaultValue={props.profile.username}
              className="mt-1 bg-gray-100 focus:ring-primary-green focus:border-primary-green block w-full  max-w-sm shadow-sm sm:text-sm border border-gray-300 rounded h-10 px-2 py-4"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-500">
              E-mail
            </label>

            <input
              type="email"
              id="state"
              value={email}
              className="mt-1 focus:ring-primary-green focus:border-primary-green block w-full max-w-sm shadow-sm sm:text-sm border-gray-300 rounded-md  border border-gray-300 rounded h-10 px-2 py-4"
              {...register("email", {
                onChange: (e) => setEmail(e.target.value),
                required: "Email is required",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "Invalid email address",
                },
              })}
            />

            {errors.email?.message && (
              <>
                <span className="text-red-500 max-w-70% p-0.5 mt-2 pr-3 text-sm">
                  {errors.email.message}
                </span>
              </>
            )}
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-500">
              First Name
            </label>

            <input
              type="text"
              id="user_firstName"
              value={firstName}
              className="mt-1 focus:ring-primary-green focus:border-primary-green block w-full max-w-sm shadow-sm sm:text-sm border-gray-300 rounded-md  border border-gray-300 rounded h-10 px-2 py-4"
              {...register("firstName", {
                onChange: (e) => setFirstName(e.target.value),
                required: "First name is required",
              })}
            />

            {errors.firstName?.message && (
              <>
                <span className="text-red-500 max-w-70% p-0.5 mt-2 pr-3 text-sm">
                  {errors.firstName.message}
                </span>
              </>
            )}
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-500">
              Last Name
            </label>

            <input
              type="text"
              id="user_lastName"
              value={lastName}
              maxLength={60}
              className="mt-1 focus:ring-primary-green focus:border-primary-green block w-full max-w-sm shadow-sm sm:text-sm border-gray-300 rounded-md  border border-gray-300 rounded h-10 px-2 py-4"
              {...register("lastName", {
                onChange: (e) => setLastname(e.target.value),
                required: "Last name is required",
              })}
            />

            {errors.lastName?.message && (
              <>
                <span className="text-red-500 max-w-70% p-0.5 mt-2 pr-3 text-sm">
                  {errors.lastName.message}
                </span>
              </>
            )}
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-500">
              Password
            </label>

            <input
              type="password"
              id="state"
              value={password}
              className="mt-1 focus:ring-primary-green focus:border-primary-green block w-full max-w-sm shadow-sm sm:text-sm border-gray-300 rounded-md  border border-gray-300 rounded h-10 px-2 py-4"
              {...register("password", {
                onChange: (e) => setPassword(e.target.value),
                required: "Password is required",
                pattern: {
                  value: /^[\w `~!@#$%^&*()_\-+={}[\]|:;“"'<>,.\/\\?]{6,60}$/,
                  message:
                    "Password should contains alphanumerics and '@', '.', '_'",
                },
              })}
            />

            {errors.password?.message && (
              <>
                <span className="text-red-500 max-w-70% p-0.5 mt-2 pr-3 text-sm">
                  {errors.password.message}
                </span>
              </>
            )}
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-500">
              Confirm Password
            </label>

            <input
              type="password"
              id="user_password"
              value={confirmPassword}
              onChange={(e) => handleConfirmPassword(e.target.value)}
              className="mt-1 focus:ring-primary-green focus:border-primary-green block w-full max-w-sm shadow-sm sm:text-sm border-gray-300 rounded-md  border border-gray-300 rounded h-10 px-2 py-4"
            />

            {isPassWordNotMatch && (
              <span className="text-red-500 max-w-70% p-0.5 mt-2 pr-3 text-sm">
                Passwords must match
              </span>
            )}
          </div>
        </div>
        <div className="text-base text-gray-700 font-semibold my-6 pb-1 border-b mr-20">
          3rd Party Integration
        </div>

        <div className="grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-2">
          {props.profile.classLinkId !== null && (
            <div>
              <div className="flex items-center text-xs">
                <ClassLinkIcon className="w-5"></ClassLinkIcon>
                <label className="ml-1 text-sm text-gray-700">Classlink</label>
              </div>

              <input
                className="mt-1 bg-gray-200 block w-full max-w-sm shadow-sm sm:text-sm border-gray-300 rounded-md  border border-gray-300 rounded h-10 px-2 py-4"
                value={props.profile.classLinkId}
                disabled={true}
              />
            </div>
          )}
          {props.profile.cleverId !== null && (
            <div>
              <div className="flex items-center text-xs">
                <CleverIcon className="w-5"></CleverIcon>
                <label className="text-sm text-gray-700">Clever</label>
              </div>

              <input
                className="mt-1 bg-gray-200 block w-full max-w-sm shadow-sm sm:text-sm border-gray-300 rounded-md  border border-gray-300 rounded h-10 px-2 py-4"
                value={props.profile.cleverId}
                disabled={true}
              />
            </div>
          )}
        </div>
        {CanvasConfiguration.EnableCanvas && (
          <div>
            <div className="flex items-center text-xs my-2">
              <CanvasIcon className="w-5"></CanvasIcon>
              <label className="ml-1 text-sm text-gray-700">Canvas</label>
            </div>

            <div className="grid grid-cols-6 gap-2">
              <div className="col-span-6 sm:col-span-2 lg:col-span-2">
                <label className="block text-sm font-medium text-gray-500">
                  Login Id
                </label>
                <input
                  className="mt-1 focus:ring-primary-green focus:border-primary-green block w-full max-w-sm shadow-sm sm:text-sm border-gray-300 rounded-md  border border-gray-300 rounded h-10 px-2 py-4"
                  value={props.profile.canvasLoginId}
                  disabled
                />
              </div>

              <div className="col-span-6 sm:col-span-2 lg:col-span-2">
                <label className="block text-sm font-medium text-gray-500">
                  Canvas Id
                </label>
                <input
                  type="text"
                  id="postal_code"
                  value={props.profile.canvasId ? props.profile.canvasId : ""}
                  disabled
                  className="bg-gray-100 mt-1 focus:ring-primary-green focus:border-primary-green block w-full max-w-sm shadow-sm sm:text-sm border-gray-300 rounded-md  border border-gray-300 rounded h-10 px-2 py-4"
                />
              </div>
              {(props.profile.canvasLoginId && (
                <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                  <label className="block text-sm font-medium text-gray-500">
                    Canvas Link Status{" "}
                  </label>
                  <Button
                    className="w-full sm:w-auto mt-1 border-red-500 text-red-500"
                    layout="outline"
                    onClick={(e) => {
                      handleUnLinkCanvas();
                    }}
                  >
                    Unlink Canvas
                  </Button>
                </div>
              )) || (
                <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                  <label className="block text-sm font-medium text-gray-500 mb-1">
                    Canvas Link Status{" "}
                  </label>

                  <div
                    className={
                      (upgrade ? "opacity-50 " : "") +
                      " inline-flex space-x-2 border-[#E6252A] text-white border bg-[#E6252A] rounded pr-4 cursor-pointer"
                    }
                    onClick={() => {
                      if (upgrade) {
                        setShowUpgradePopup(true);
                      } else {
                        redirectToCanvasLogin();
                      }
                    }}
                  >
                    <span className="flex rounded bg-white w-16 justify-center items-center h-10">
                      <CanvasIcon className="w-5"></CanvasIcon>
                    </span>

                    <span className="w-full pl-[32px] md:pl-[16px] 2xl:pl-[28px] xxxl:pl-[32px] text-sm xxxl:text-base text-white font-Barlow inline-block bg-class-blue flex items-center">
                      Sign in with Canvas
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
        <div className="mt-4">
          <div className="flex items-center text-xs my-2">
            <GoogleClassroomIcon className="w-5"></GoogleClassroomIcon>
            <label className="ml-1 text-sm text-gray-700">
              Google Classroom
            </label>
          </div>

          <div className="grid grid-cols-6 gap-2">
            <div className="col-span-6 sm:col-span-2 lg:col-span-2">
              <label className="block text-sm font-medium text-gray-500">
                Gmail Id
              </label>
              <input
                className="mt-1 focus:ring-primary-green focus:border-primary-green block w-full max-w-sm shadow-sm sm:text-sm border-gray-300 rounded-md  border border-gray-300 rounded h-10 px-2 py-4"
                value={props.profile.gmailId}
                disabled
              />
            </div>

            <div className="col-span-6 sm:col-span-2 lg:col-span-2">
              <label className="block text-sm font-medium text-gray-500">
                Google Id
              </label>
              <input
                type="text"
                id="postal_code"
                value={props.profile.googleId ? props.profile.googleId : ""}
                disabled
                className="bg-gray-100 mt-1 focus:ring-primary-green focus:border-primary-green block w-full max-w-sm shadow-sm sm:text-sm border-gray-300 rounded-md  border border-gray-300 rounded h-10 px-2 py-4"
              />
            </div>
            {(props.profile.gmailId && (
              <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                <label className="block text-sm font-medium text-gray-500">
                  Google Link Status{" "}
                </label>
                <Button
                  className="w-full sm:w-auto mt-1 border-red-500 text-red-500"
                  layout="outline"
                  onClick={(e) => {
                    handleUnLinkGoogle();
                  }}
                >
                  Unlink Google Classroom
                </Button>
              </div>
            )) || (
              <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                <label className="block text-sm font-medium text-gray-500 mb-1">
                  Google Link Status{" "}
                </label>

                <div
                  className={
                    (upgrade ? "opacity-50 " : "") +
                    " inline-flex space-x-2 bg-blue-600 text-white border border-blue-600 pr-4 cursor-pointer"
                  }
                  onClick={() => {
                    if (upgrade) {
                      setShowUpgradePopup(true);
                    } else {
                      redirectToGoogleclassNameroomLogin();
                    }
                  }}
                >
                  <div className="abcRioButtonIcon p-2 bg-white">
                    <div className="abcRioButtonSvgImageWithFallback abcRioButtonIconImage abcRioButtonIconImage18">
                      <GoogleLoginIcon title="Google Classroom" />
                    </div>
                  </div>
                  <span className="abcRioButtonContents text-xs flex items-center">
                    <span id="not_signed_int8aitmh8ix7c ml-4">
                      Sign in with Google
                    </span>
                    <span id="connectedt8aitmh8ix7c"></span>
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className={`px-4 py-3 bg-gray-50 my-6 flex items-end 2xl:mr-16`}>
          <button
            type="submit"
            className="bg-primary-violet border border-transparent shadow-sm py-2 px-4 justify-end text-sm font-medium text-white hover:bg-dark-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 whitespace-nowrap ml-auto"
          >
            Save
          </button>
        </div>
      </form>
    </>
  );
};

export default connect((state: any) => {
  return {
    profile: state.profile,
    userContext: state.userContext,
  };
})(Profile);

import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import Select from "react-select";
import {
  getDOK,
  getDomainsBySubjectId,
  getStandardsByDomainId,
} from "../../../../api/cmsApi";
import { getAssociatedSubjects } from "../../../../api/teacher/assignment";
import { getAllQuestionTypes } from "../../../../api/teacher/questionType";
import {
  ChartIcon,
  ChevronRightIcon,
  ClassifyIcon,
  EditIcon,
  FillBlanksIcon,
  GraphingIcon,
  MultipleIcon,
  PassageIcon,
  SquareRootIcon,
  HighlightIcon,
    ChemistryIcon,
} from "../../../../assets/icons";
import { Configuration } from "../../../../environment/setup";
import { ISelectElement } from "../../../../model/interface/selectElement";
import RouteConstant from "../../../../utils/constant/routeConstant";
import Permissions from "../../../../utils/permissions";
import AccessDenied from "../../../shared/accessDenied";
import InfoMessage from "../../../shared/infoMessage";
import AllowedTo from "../../../shared/rbac";
import arraySort from "array-sort";
import Asterisk from "../../../shared/asterisk";
import constant from "../../../../utils/constant/constant";

function QuestionBuilderCard(props: any) {
  const history = useHistory();
  const { params } = props.match;
  const defaultValue = { label: "Select", value: "0" };
  const [questionTypes, setQuestionTypes] = useState<any[]>();
  const [selectedQuestionType, setSelectedQuestionType] = useState<string>("");
  const [selectedSubject, setSelectedSubject] =
    useState<ISelectElement>(defaultValue);
  const [selectedDomain, setSelectedDomain] =
    useState<ISelectElement>(defaultValue);
  const [subjects, setSubjects] = useState<Array<any>>([]);
  const [domains, setDomains] = useState<Array<any>>([]);
  const [selectedStandard, setSelectedStandard] =
    useState<ISelectElement>(defaultValue);
  const [standards, setStandards] = useState<ISelectElement[]>([]);
  const [doks, setDoks] = useState<ISelectElement[]>([]);
  const [selectedDok, setSelectedDok] = useState<ISelectElement>(defaultValue);
  const mapOptions: any = (data) => {
    const options: any[] = [];

    data?.map((item) => {
      const groupItems: any[] = [];
      const group = {
        label: item.questionCategoryName,
        options: groupItems,
      };

      item?.subTypes?.map((option) => {
        return groupItems.push({
          label: item.questionCategoryName + " - " + option.name,
          value: option.questionTypeId,
          data: option,
        });
      });
      options.push(group);
      return null;
    });

    return options;
  };

  function getQuestionTypes() {
    getAllQuestionTypes().then((res) => {
      const options = mapOptions(res.data);
      setQuestionTypes(options);
    });
  }

  function redirectToQuestionBuilder(questionTypeCode, label, value) {
    const passageQuestion = label.includes("Passages") ? true : false;
    history.push({
      pathname: RouteConstant.QUESTIONBUILDER,
      state: {
        questionTypeCode: questionTypeCode,
        payloadLabel: label,
        passageQuestion: passageQuestion,
        questionTypeId: value,
        standardId:
          selectedStandard.value === "0" ? 0 : parseInt(selectedStandard.value),
        stateId: props.userContext?.stateId,
        dok: selectedDok.value === "0" ? 0 : parseInt(selectedDok.value),
      },
    });
  }

  function setQuestionType(label) {
    setSelectedQuestionType(label);
  }

  function getSubjects() {
    getAssociatedSubjects(props.userContext?.userId).then((response) => {
      if (props.userContext.roleId === constant.UserRoleId.District) {
        const subjectsELA: Array<ISelectElement> = response.data
          .filter((item) => item.contentAreaId === 1)
          ?.map((values: any) => {
            return {
              label: values?.name,
              value: values?.subjectId,
            };
          });
        const subjectsMath: Array<ISelectElement> = response.data
          .filter((item) => item.contentAreaId === 2)
          ?.map((values: any) => {
            return {
              label: values?.name,
              value: values?.subjectId,
            };
          });
        const subjectsScience: Array<ISelectElement> = response.data
          .filter((item) => item.contentAreaId === 3)
          ?.map((values: any) => {
            return {
              label: values?.name,
              value: values?.subjectId,
            };
          });
        const subjectsSocial: Array<ISelectElement> = response.data
          .filter((item) => item.contentAreaId === 4)
          ?.map((values: any) => {
            return {
              label: values?.name,
              value: values?.subjectId,
            };
          });
        const options = [
          {
            label: "ELA",
            options: arraySort(subjectsELA, "label"),
          },
          {
            label: "Math",
            options: arraySort(subjectsMath, "label"),
          },
          {
            label: "Science",
            options: arraySort(subjectsScience, "label"),
          },
          {
            label: "Social Studies",
            options: arraySort(subjectsSocial, "label"),
          },
        ];
        setSubjects(options);
      } else {
        const subjects: Array<ISelectElement> = response.data
          .filter((r) => r.isAdditionalSubject === false)
          .map((values: any) => {
            return {
              label: values?.name,
              value: values?.subjectId,
            };
          });

        const additionalSubjects: Array<ISelectElement> = response.data
          .filter((r) => r.isAdditionalSubject === true)
          .map((values: any) => {
            return {
              label: values?.name,
              value: values?.subjectId,
            };
          });

        const options = [
          {
            label: "My Subjects",
            options: arraySort(subjects, "label"),
          },
          {
            label: "Additional Subjects",
            options: arraySort(additionalSubjects, "label"),
          },
        ];
        setSubjects(options);
      }

      if (params.subjectId !== undefined) {
        let selectedSubjects = subjects.filter(
          (r) => r.value.toString() === params.subjectId.toString()
        );

        if (selectedSubjects && selectedSubjects.length > 0) {
          setSelectedSubject(selectedSubjects[0]);
          getDomains(params.subjectId);
        }
      }
    });
  }

  function getDomains(subjectId: number) {
    getDomainsBySubjectId(subjectId).then((response) => {
      const domains: Array<ISelectElement> = response.data.data.map(
        (values: any) => {
          return {
            label: values?.name,
            value: values?.domainId,
          };
        }
      );
      setDomains(domains);
    });
  }

  function getStandards(domainId: number) {
    getStandardsByDomainId(domainId).then((response) => {
      const standards =
        response.data.totalRecords > 0 &&
        response.data.data.map((values: any) => {
          return {
            label: "[" + values?.code + "] " + values?.name,
            value: values?.standardId,
          };
        });
      setStandards(standards);
    });
  }

  function getDoks() {
    const data = getDOK();
    const doks = data.map((values: any) => {
      return {
        label: values?.label,
        value: values?.value,
      };
    });
    setDoks(doks);
  }

  function handleSubjectChange(e: any) {
    setSelectedSubject(e);
    setSelectedDomain(defaultValue);
    setSelectedStandard(defaultValue);
    getDomains(e.value);
  }

  function handleDomainChange(e: any) {
    setSelectedStandard(defaultValue);
    setSelectedDomain(e);
    getStandards(e.value);
  }

  function handleStandardChange(e: any) {
    setSelectedStandard(e);
  }
  function handleDokChange(e: any) {
    setSelectedDok(e);
  }

  useEffect(() => {
    getQuestionTypes();
    getSubjects();
    getDoks();
    if (params.subjectId !== undefined && params.subjectId > 0)
      getStandards(params.subjectId);
  }, []);

  return (
    <AllowedTo
      perform={Permissions.authoured_questions_create}
      no={() => <AccessDenied />}
    >
      <div className="w-full">
        <div className="lg:flex lg:items-center px-4 py-3 lg:px-8 border-b border-gray-200 ">
          <ol className="lg:w-[45%] flex items-center space-x-2 mb-1">
            <li>
              <Link
                to={RouteConstant.AUTHOREDQUESTIONS}
                className="cursor-pointer text-sm text-gray-500 hover:text-gray-700 border-dashed border-b border-gray-400 hover:border-opacity-0"
              >
                Manage My Questions
              </Link>
            </li>
            <li>
              <div className="flex items-center">
                <ChevronRightIcon className="flex-shrink-0 h-5 w-5 text-gray-400" />
                <span className="ml-1 text-sm text-gray-500 capitalize">
                  Create New Question
                </span>
              </div>
            </li>
          </ol>
          <div className="w-[55%] text-lg font-medium flex gap-2 h-auto sm:h-10 items-center">
            <h1 className="text-primary-violet flex items-center justify-between w-full pr-4">
              Create New Question
            </h1>
          </div>
        </div>
        <div></div>
        <div className="px-2 sm:px-6  lg:px-8 py-1 relative mt-4">
          <InfoMessage message="Select the Subject, Domain, Standard and the Depth of Knowledge (DOK) in order to select the Question Type." />
          <div className="">
            <div className="grid grid-cols-4 xxl:grid-cols-6 xxxl:grid-cols-8 gap-6 border-b pb-4">
              <div>
                <label
                  htmlFor="country"
                  className="block text-sm font-medium text-gray-500"
                >
                  Subject <Asterisk />
                </label>
                <div className="mt-1">
                  <Select
                    value={selectedSubject}
                    formatGroupLabel={(data) => {
                      return (
                        <div
                          className="bg-gray-200 -mx-3"
                          style={constant.SubjectDDLCSS.groupStyles}
                        >
                          <span className="text-secondary-teal pl-2">
                            {data.label}
                          </span>

                          <span
                            style={constant.SubjectDDLCSS.groupBadgeStyles()}
                          >
                            {data.options.length}
                          </span>
                        </div>
                      );
                    }}
                    options={subjects}
                    isSearchable={true}
                    onChange={handleSubjectChange}
                    className="w-full"
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: "#008fbb",
                      },
                    })}
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="domain"
                  className="block text-sm font-medium text-gray-500"
                >
                  Domain <Asterisk />
                </label>
                <div className="mt-1">
                  <Select
                    value={selectedDomain}
                    options={domains}
                    isSearchable={true}
                    onChange={handleDomainChange}
                    className="w-full"
                    isDisabled={selectedSubject.value === "0"}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: "#008fbb",
                      },
                    })}
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="standard"
                  className="block text-sm font-medium text-gray-500"
                >
                  Standard <Asterisk />
                </label>
                <div className="mt-1">
                  <Select
                    value={selectedStandard}
                    options={standards}
                    isSearchable={true}
                    onChange={handleStandardChange}
                    className="w-full"
                    isDisabled={selectedDomain.value === "0"}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: "#008fbb",
                      },
                    })}
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="dok"
                  className="block text-sm font-medium text-gray-500"
                >
                  DOK <Asterisk />
                </label>
                <div className="mt-1">
                  <Select
                    value={selectedDok}
                    options={doks}
                    isSearchable={true}
                    onChange={handleDokChange}
                    className="w-full"
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: "#008fbb",
                      },
                    })}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="px-2 sm:px-6  lg:px-8 py-1 relative mb-4">
          <h1 className="text-gray-700 flex w-full pr-4 text-sm font-bold mb-2 mt-1">
            Select Question Type
          </h1>
          <div className="flex flex-col md:flex-row space-x-6">
            <div className="flex flex-col md:flex-row space-x-6 border">
              <ul className="">
                {questionTypes?.map((questionType, index) => (
                  <>
                    <li
                      onClick={() => {
                        if (
                          selectedStandard.value !== "0" &&
                          selectedDok.value !== "0"
                        )
                          setQuestionType(questionType.label);
                      }}
                      className={`flex items-center text-sm text-gray-500 cursor-pointer border-l-4 hover:bg-gray-100 hover:text-gray-700 transition-all transition-duration-300 ${
                        selectedStandard.value !== "0" &&
                        selectedDok.value !== "0"
                          ? ""
                          : ""
                      } ${
                        selectedQuestionType === questionType.label
                          ? "border-primary-violet hover:border-primary-violet bg-gray-100 text-gray-700"
                          : "hover:border-gray-300"
                      }
                      `}
                    >
                      <div className="flex items-center p-3 border-b h-full w-full">
                        {questionType.label.includes("Multiple") && (
                          <MultipleIcon className="w-4 h-4 mr-2 cursor-pointer"></MultipleIcon>
                        )}
                        {questionType.label.includes("Passages") && (
                          <PassageIcon className="w-4 h-4 mr-2 cursor-pointer"></PassageIcon>
                        )}

                        {questionType.label.includes("Fill") && (
                          <FillBlanksIcon className="w-4 h-4 mr-2 cursor-pointer"></FillBlanksIcon>
                        )}
                        {questionType.label.includes("Classify") && (
                          <ClassifyIcon className="w-4 h-4 mr-2 cursor-pointer"></ClassifyIcon>
                        )}
                        {questionType.label.includes("Written") && (
                          <EditIcon className="w-4 h-4 mr-2 cursor-pointer"></EditIcon>
                        )}
                        {questionType.label.includes("Math") && (
                          <SquareRootIcon className="w-4 h-4 mr-2 cursor-pointer"></SquareRootIcon>
                        )}
                        {questionType.label.includes("Graphing") && (
                          <GraphingIcon className="w-4 h-4 mr-2 cursor-pointer"></GraphingIcon>
                        )}
                        {questionType.label.includes("Charts") && (
                          <ChartIcon className="w-4 h-4 mr-2 cursor-pointer"></ChartIcon>
                        )}
                        {questionType.label.includes("Highlight") && (
                          <HighlightIcon className="w-4 h-4 mr-2 cursor-pointer"></HighlightIcon>
                        )}
                        {questionType.label.includes("Chemistry") && (
                            <ChemistryIcon className="w-6 h-6 cursor-pointer"></ChemistryIcon>
                        )}
                        {questionType.label.split(" |,")[0]}
                      </div>
                    </li>
                  </>
                ))}
              </ul>
            </div>
            <div className="2xl:w-3/4">
              <div className="flex flex-wrap gap-5">
                {questionTypes &&
                  questionTypes
                    .find((x) => x.label === selectedQuestionType)
                    ?.options.map((subtypes, index) => (
                      <div
                        key={index}
                        className="w-64 border group cursor-pointer"
                        onClick={() =>
                          redirectToQuestionBuilder(
                            subtypes.data.externalCode,
                            subtypes.label,
                            subtypes.value
                          )
                        }
                      >
                        <div className="bg-primary-violet h-16 p-3 text-sm text-white flex items-center">
                          {subtypes.label}
                        </div>
                        <div className="p-3 text-xs group-hover:bg-gray-50">
                          {subtypes.label.includes("Passages") ? (
                            <img
                              alt="passage"
                              src={
                                `${Configuration.S3bucketImagePath}/images/psg-` +
                                subtypes.data.externalCode +
                                ".png"
                              }
                            />
                          ) : (
                            <img
                              alt="passage"
                              src={
                                `${Configuration.S3bucketImagePath}/images/` +
                                subtypes.data.externalCode +
                                ".png"
                              }
                            />
                          )}
                        </div>
                      </div>
                    ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </AllowedTo>
  );
}

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(QuestionBuilderCard);

class Permissions {
  public readonly dashboard_view = "pl_dashboard_view" as string;
  public readonly class_management_view = "pl_class_management_view" as string;
  public readonly class_management_transferstudent =
    "pl_class_management_transferstudent_view" as string;
  public readonly class_management_modify =
    "pl_class_management_modify" as string;
  public readonly class_management_create =
    "pl_class_management_create" as string;
  public readonly class_management_delete =
    "pl_class_management_delete" as string;
  public readonly video_message = "pl_video_message_view" as string;
  public readonly assessment_center_view =
    "pl_assessment_center_view" as string;
  public readonly assessment_center_modify =
    "pl_assessment_center_modify" as string;
  public readonly assessment_center_create =
    "pl_assessment_center_create" as string;
  public readonly assessment_center_delete =
    "pl_assessment_center_delete" as string;
  public readonly assessments_view = "pl_assessments_view" as string;
  public readonly assessments_modify = "pl_assessments_modify" as string;
  public readonly assessments_create = "pl_assessments_create" as string;
  public readonly assessments_delete = "pl_assessments_delete" as string;
  public readonly assessments_results_view =
    "pl_assessments_results_view" as string;
  public readonly assignments_view = "pl_assignments_view" as string;
  public readonly assignments_modify = "pl_assignments_modify" as string;
  public readonly assignments_create = "pl_assignments_create" as string;
  public readonly assignments_delete = "pl_assignments_delete" as string;
  public readonly remediations_view = "pl_remediations_view" as string;
  public readonly authoured_questions_view =
    "pl_authoured_questions_view" as string;
  public readonly authoured_questions_modify =
    "pl_authoured_questions_modify" as string;
  public readonly authoured_questions_create =
    "pl_authoured_questions_create" as string;
  public readonly authoured_questions_delete =
    "pl_authoured_questions_delete" as string;
  public readonly user_management_view = "pl_user_management_view" as string;
  public readonly user_management_modify =
    "pl_user_management_modify" as string;
  public readonly user_management_create =
    "pl_user_management_create" as string;
  public readonly user_management_delete =
    "pl_user_management_delete" as string;
  public readonly alien_arena_setup_view =
    "pl_alien_arena_setup_view" as string;
  public readonly alien_arena_setup_modify =
    "pl_alien_arena_setup_modify" as string;
  public readonly alien_arena_setup_create =
    "pl_alien_arena_setup_create" as string;
  public readonly alien_arena_setup_delete =
    "pl_alien_arena_setup_delete" as string;
  public readonly alien_arena_sessions_view =
    "pl_alien_arena_sessions_view" as string;
  public readonly alien_arena_sessions_modify =
    "pl_alien_arena_sessions_modify" as string;
  public readonly alien_arena_sessions_create =
    "pl_alien_arena_sessions_create" as string;
  public readonly alien_arena_sessions_delete =
    "pl_alien_arena_sessions_delete" as string;
  public readonly tags_view = "pl_tags_view" as string;
  public readonly tags_modify = "pl_tags_modify" as string;
  public readonly tags_create = "pl_tags_create" as string;
  public readonly tags_delete = "pl_tags_delete" as string;
  public readonly supersheets_view = "pl_supersheets_view" as string;
  public readonly supersheets_modify = "pl_supersheets_modify" as string;
  public readonly supersheets_create = "pl_supersheets_create" as string;
  public readonly supersheets_delete = "pl_supersheets_delete" as string;
  public readonly training_center_view = "pl_training_center_view" as string;
  public readonly support_view = "pl_support_view" as string;
  public readonly family_connect_view = "pl_family_connect_view" as string;
  public readonly instructional_resources_view =
    "pl_instructional_resources_view" as string;
  public readonly bell_ringer_view = "pl_bell_ringer_view" as string;
  public readonly bell_ringer_modify = "pl_bell_ringer_modify" as string;
  public readonly bell_ringer_create = "pl_bell_ringer_create" as string;
  public readonly bell_ringer_delete = "pl_bell_ringer_delete" as string;

  public readonly liftoff_management_view =
    "pl_liftoff_management_view" as string;
  public readonly liftoff_management_modify =
    "pl_liftoff_management_modify" as string;
  public readonly liftoff_management_create =
    "pl_liftoff_management_create" as string;
  public readonly liftoff_management_delete =
    "pl_liftoff_management_delete" as string;

  public readonly nwea_mapping_view = "pl_nwea_mapping_view" as string;
  public readonly nwea_mapping_modify = "pl_nwea_mapping_modify" as string;
  public readonly nwea_mapping_create = "pl_nwea_mapping_create" as string;
  public readonly nwea_mapping_delete = "pl_nwea_mapping_delete" as string;

  public readonly account_information_view =
    "pl_account_information_view" as string;
  public readonly account_information_modify =
    "pl_account_information_modify" as string;
  public readonly account_information_create =
    "pl_account_information_create" as string;
  public readonly account_information_delete =
    "pl_account_information_delete" as string;

  public readonly reports_progressreport_view =
    "pl_report_progressreport_view" as string;
  public readonly reports_gradebook_view = "pl_report_gradebook_view" as string;
  public readonly reports_galaxystar_view =
    "pl_report_galaxystar_view" as string;
  public readonly reports_sessionactivity_view =
    "pl_report_sessionactivity_view" as string;
  public readonly reports_liftoff_individual_diagnostic_view =
    "pl_report_liftoffindividualreport_view" as string;
  public readonly reports_liftoff_diagnostic_view =
    "pl_report_liftoffdiagnosticreport_view" as string;
  public readonly reports_liftoff_detail_growth_view =
    "pl_report_liftoffdetailgrowthreport_view" as string;
  public readonly reports_results_item_analysis_view =
    "pl_report_itemanalysis_view" as string;
  public readonly reports_assessment_results_view =
    "pl_report_assessment_result_view" as string;
  public readonly reports_liftoff_class_galaxy_summary_view =
    "pl_report_liftoffgalaxysummaryreport_view" as string;
  public readonly reports_liftoff_galaxystar_view =
    "pl_report_liftoffgalaxystarreport_view";
  public readonly reports_liftoff_usage_view =
    "pl_report_liftoffusagereport_view";
  public readonly reports_liftoff_activity_view =
    "pl_report_liftoffactivityreport_view";
  public readonly dev_mode_view = "dev_mode_view" as string;
  public readonly dev_mode_modify = "dev_mode_modify" as string;
  public readonly dev_mode_create = "dev_mode_create" as string;
  public readonly dev_mode_delete = "dev_mode_delete" as string;
  public readonly reports_activity_search_view =
    "pl_report_activity_search_view" as string;
  public readonly reports_diagnosticsstrengthweaknessreport_view =
    "pl_report_diagnosticsstrengthweaknessreport_view";
  public readonly reports_usagereport_view =
    "pl_reports_usagereport_view" as string;

  public readonly nwea_sync_view = "pl_nwea_sync_view" as string;
  public readonly studentview_view = "pl_studentview_view" as string;

  public readonly reports_testcomparisonreport_view =
    "pl_report_testcomparisonreport_view" as string;

  public readonly reports_resultsreport_view =
    "pl_report_resultsreport_view" as string;

  public readonly reports_results_item_analysis_beta_view =
    "pl_report_itemanalysis_beta_view" as string;
  public readonly reports_livemonitoring_view =
    "pl_report_livemonitoringreport_view" as string;
  public readonly user_management_district_users =
    "pl_user_management_tab_district_users" as string;
  public readonly user_management_students =
    "pl_user_management_tab_students" as string;
  public readonly user_management_teachers =
    "pl_user_management_tab_teachers" as string;
  public readonly reports_export_upgrade = "pl_report_export_upgrade" as string;
  public readonly remediations_upgrade = "pl_remediations_upgrade" as string;
  public readonly reports_galaxystar_upgrade =
    "pl_report_galaxystar_upgrade" as string;
  public readonly reports_sessionactivity_upgrade =
    "pl_report_sessionactivity_upgrade" as string;
  public readonly reports_diagnosticsstrengthweaknessreport_upgrade =
    "pl_report_diagnosticsstrengthweaknessreport_upgrade";
  public readonly reports_activity_search_upgrade =
    "pl_report_activity_search_upgrade" as string;
  public readonly reports_testcomparisonreport_upgrade =
    "pl_report_testcomparisonreport_upgrade" as string;
  public readonly reports_usagereport_upgrade =
    "pl_reports_usagereport_upgrade" as string;
  public readonly reports_liftoff_diagnostic_upgrade =
    "pl_report_liftoffdiagnosticreport_upgrade" as string;
  public readonly reports_liftoff_individual_diagnostic_upgrade =
    "pl_report_liftoffindividualreport_upgrade" as string;
  public readonly reports_liftoff_detail_growth_upgrade =
    "pl_report_liftoffdetailgrowthreport_upgrade" as string;
  public readonly reports_liftoff_class_galaxy_summary_upgrade =
    "pl_report_liftoffgalaxysummaryreport_upgrade" as string;
  public readonly reports_liftoff_galaxystar_upgrade =
    "pl_report_liftoffgalaxystarreport_upgrade";
  public readonly reports_liftoff_usage_upgrade =
    "pl_report_liftoffusagereport_upgrade";
  public readonly reports_liftoff_activity_upgrade =
    "pl_report_liftoffactivityreport_upgrade";
  public readonly studentview_upgrade = "pl_studentview_upgrade" as string;
  public readonly alien_arena_setup_upgrade =
    "pl_alien_arena_setup_upgrade" as string;
  public readonly ins_resource_videos_upgrade =
    "pl_ins_resource_videos_upgrade" as string;
  public readonly printable_resource_upgrade =
    "pl_printable_resource_upgrade" as string;
  public readonly vocab_upgrade = "pl_vocab_upgrade" as string;
  public readonly supersheets_upgrade = "pl_supersheets_upgrade" as string;
  public readonly authored_question_upgrade =
    "pl_authored_question_upgrade" as string;
  public readonly student_settings_view = "pl_student_settings_view" as string;
  public readonly reports_livemonitoring_upgrade =
    "pl_report_livemonitoringreport_upgrade" as string;
  public readonly premium_assessments_view =
    "pl_premium_assessments_view" as string;
  public readonly premium_assessments_modify =
    "pl_premium_assessments_modify" as string;
  public readonly premium_assessments_create =
    "pl_premium_assessments_create" as string;
  public readonly premium_assessments_delete =
    "pl_premium_assessments_delete" as string;
  public readonly assessment_bank_students_attempts_tab_view = 
    "pl_assessment_bank_students_attempts_tab_view" as string;
}

export default new Permissions();

import React from "react";
import { CompletionInfo } from "./interface";

const AssignmentQuestionsIcon: React.FunctionComponent<CompletionInfo> = ({
  is_saved,
  past_due,
}: CompletionInfo) => {
  return (
    <div
      className={`flex-shrink-0 cursor-pointer h-12 w-12 bg-blues ${
        is_saved === 1 ? "bg-site-orange" : ""
      } ${
        past_due === 1 ? "bg-site-pink" : ""
      }  hover:opacity-90 rounded-full flex items-center justify-center`}
    >
      <svg
        className="w-8 h-8 fill-current text-white"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 32 32"
      >
        <path d="M 16 4 C 9.382813 4 4 9.382813 4 16 C 4 22.617188 9.382813 28 16 28 C 22.617188 28 28 22.617188 28 16 C 28 9.382813 22.617188 4 16 4 Z M 16 6 C 21.535156 6 26 10.464844 26 16 C 26 21.535156 21.535156 26 16 26 C 10.464844 26 6 21.535156 6 16 C 6 10.464844 10.464844 6 16 6 Z M 16 10 C 13.800781 10 12 11.800781 12 14 L 14 14 C 14 12.882813 14.882813 12 16 12 C 17.117188 12 18 12.882813 18 14 C 18 14.765625 17.507813 15.445313 16.78125 15.6875 L 16.375 15.8125 C 15.558594 16.082031 15 16.863281 15 17.71875 L 15 19 L 17 19 L 17 17.71875 L 17.40625 17.59375 C 18.945313 17.082031 20 15.621094 20 14 C 20 11.800781 18.199219 10 16 10 Z M 15 20 L 15 22 L 17 22 L 17 20 Z"></path>
      </svg>
    </div>
  );
};

export default AssignmentQuestionsIcon;

import { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { ClassApi } from "../../../../../api/teacher/classManagementApi";
import { PageAccessMode } from "../../../../../model/common/pageAccessMode";
import PagingResponse from "../../../../../model/common/pagingResponse";
import ClasStudentModel from "../../../../../model/teacher/classManagement/classStudentModel";
import { IUpdateClassStudentSetting } from "../../../../../model/teacher/classManagement/IUpdateClassStudentSetting";
import { UserContextState } from "../../../../../redux/actions/userContextAction";
import {
  DEFAULT_PAGE_NUMBER,
  DEFAULT_PAGE_SIZE_50,
} from "../../../../../utils/pagingConstant";
import ConfirmationDialog from "../../../../shared/confirmationDialog";
import InfoMessage from "../../../../shared/infoMessage";
import Loader from "../../../../shared/loader";
import PageSize from "../../../../shared/pagination/pageSize";
import Pagination from "../../../../shared/pagination/pagination";
import Toggle from "../../../../shared/toggle";
import ApplyAllSettingContextMenu from "../../shared/applyAllSettingContextMenu";
import { getNameLabel } from "../../../../../utils/helper";
import UserTile from "../../../../shared/UserTile";
import { MessageApi } from "../../../../../api/message/messageApi";
interface ClassStudentSettingProps {
  classId: number;
  mode: PageAccessMode;
  userContext?: UserContextState;
  isEditingEnabled: boolean;
}

const ClassStudentSetting = (props: ClassStudentSettingProps) => {
  enum ConfrimationType {
    UpdateSingleUserSetting,
    UpdateToAllSetting,
  }

  const [classStudents, setClassStudent] =
    useState<PagingResponse<ClasStudentModel>>();
  const [pageNumber, setPageNumber] = useState(DEFAULT_PAGE_NUMBER);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE_50);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [confirmationTitle, setConfirmationTitle] = useState("Confirm Action");
  const [confirmationMessage, setConfirmationMessage] =
    useState("Please confirm");
  const [confirmationType, setConfirmationType] = useState<ConfrimationType>();
  const [updateUserSettingValue, setUpdateUserSetting] =
    useState<IUpdateClassStudentSetting>({
      value: "",
      userIds: [],
      classId: 0,
      setting: "",
      updatedByUserId: 0,
    });
  const [resetPageNumber, setResetPageNumber] = useState<boolean>(false);
  const [loading, showLoading] = useState(false);
  const [hoverElement, setHoverElement] = useState<string>("");
  const [onlineUsers, setOnlineUsers] = useState([]);
  const [showSkillPracticeSetting, setShowSkillPracticeSetting] =
    useState(false);

  function getOnlineUsers() {
    MessageApi.getOnlineUsersRef(props?.userContext?.schoolId!).on(
      "value",
      (snapshot) => {
        if (snapshot.val()) {
          setOnlineUsers(snapshot.val());
        } else {
          setOnlineUsers([]);
        }
      }
    );
  }

  useEffect(() => {
    getOnlineUsers();
    checkDiagnosticAndSkillPractice();
  }, []);

  useEffect(() => {
    getClassStudent();
  }, [pageNumber, pageSize]);

  function checkDiagnosticAndSkillPractice() {
    ClassApi.getClassSubject(props.classId, 1, 999).then((d) => {
      if (d.data.totalRecords === 0) {
        setShowSkillPracticeSetting(false);
      } else {
        var skillContentArea: Array<number> = d.data.data.map(
          (t) => t.contentAreaId
        );
        var skillContent = skillContentArea.findIndex(
          (t) => t === 1 || t === 2
        );
        if (skillContent !== -1) {
          setShowSkillPracticeSetting(true);
        }
      }
    });
  }
  function getClassStudent(): void {
    showLoading(true);
    ClassApi.getClassStudent(props.classId, pageNumber, pageSize)
      .then((d) => {
        setClassStudent(d.data);
        showLoading(false);
      })
      .catch(() => {
        showLoading(false);
      });
  }

  function updateAllUserSetting(
    setting: string,
    value: string,
    studentIds: Array<number>
  ): void {
    const updateUserInfo: IUpdateClassStudentSetting = {
      classId: props.classId,
      userIds: studentIds,
      setting: setting,
      value: value,
      updatedByUserId:
        props.userContext?.userId === undefined ? 0 : props.userContext?.userId,
    };
    setUpdateUserSetting(updateUserInfo);
    showConfirmation(
      "Please confirm",
      "Are you sure you want update multiple users settings?",
      ConfrimationType.UpdateToAllSetting
    );
  }

  function updateAllUserSettingConfirm(): void {
    showLoading(true);
    ClassApi.updateClassStudentSetting(updateUserSettingValue).then((d) => {
      showLoading(false);
      confirmationCancelClose();
      getClassStudent();
      if (d.data.isSuccess === true)
        toast.success("Student setting's updated successfully");
      if (
        d.data.isSuccess === false
      ) {
        toast.warning(
          "A diagnostic is already in progress and cannot be disabled for the student"
        );
      }
    });
  }

  function updateUserSetting(
    studentId: number,
    setting: string,
    value: any
  ): void {
    const updateUserSettingValue: IUpdateClassStudentSetting = {
      classId: props.classId,
      userIds: [studentId],
      setting: setting,
      value: value,
      updatedByUserId: props.userContext?.userId,
    };
    setUpdateUserSetting(updateUserSettingValue);
    showLoading(true);
    ClassApi.updateClassStudentSetting(updateUserSettingValue).then((d) => {
      showLoading(false);
      confirmationCancelClose();
      getClassStudent();
      if (d.data.isSuccess === true)
        toast.success("Student setting's updated successfully");
      if (
        d.data.isSuccess === false &&
        (setting === "isPLPreTestActive" || setting === "isPLPostTestActive")
      ) {
        toast.warning(
          "A diagnostic is already in progress and cannot be disabled for the student"
        );
      }
    });
  }

  function updateUserSettingConfirm() {
    showLoading(true);
    ClassApi.updateClassStudentSetting(updateUserSettingValue).then((d) => {
      showLoading(false);
      confirmationCancelClose();
      getClassStudent();
      if (d.data.isSuccess === true)
        toast.success("Student setting's updated successfully");
      if (
        d.data.isSuccess === false &&
        (updateUserSettingValue.setting === "isPLPreTestActive" ||
          updateUserSettingValue.setting === "isPLPostTestActive")
      ) {
        toast.warning(
          "A diagnostic is already in progress and cannot be disabled for the student"
        );
      }
    });
  }

  function showConfirmation(
    title: string,
    message: string,
    type: ConfrimationType
  ) {
    setOpenConfirmation(true);
    setConfirmationTitle(title);
    setConfirmationMessage(message);
    setConfirmationType(type);
  }

  function confirmationOkClose() {
    switch (confirmationType) {
      case ConfrimationType.UpdateSingleUserSetting:
        updateUserSettingConfirm();
        break;
      case ConfrimationType.UpdateToAllSetting:
        updateAllUserSettingConfirm();
        break;
    }
  }
  function confirmationCancelClose() {
    setOpenConfirmation(false);
  }
  const onPageSizeChange = (pageSize) => {
    setPageNumber(DEFAULT_PAGE_NUMBER);
    setPageSize(pageSize);
    setResetPageNumber(true);
  };

  function applyAll(setting: string, userId: number, value: string): void {
    showLoading(true);
    ClassApi.getClassStudent(props.classId, 1, 99999)
      .then((d) => {
        var users = d.data?.data.map((e) => e.userId);
        updateAllUserSetting(setting, value, users ?? []);
        showLoading(false);
      })
      .catch(() => {
        showLoading(false);
      });
  }
  function applyAllAbove(setting: string, userId: number, value: string): void {
    var userIndex = classStudents?.data.findIndex((e) => e.userId === userId);
    var filteredStudents = classStudents?.data.filter((v, index) => {
      return index < (userIndex ?? 999);
    });
    if (filteredStudents !== undefined) {
      var users = filteredStudents.map((e) => e.userId);
      updateAllUserSetting(setting, value, users);
    }
  }

  function applyAllBelow(setting: string, userId: number, value: string): void {
    var userIndex = classStudents?.data.findIndex((e) => e.userId === userId);
    var filteredStudents = classStudents?.data.filter((v, index) => {
      return index > (userIndex ?? 0);
    });
    if (filteredStudents !== undefined) {
      var users = filteredStudents.map((e) => e.userId);
      updateAllUserSetting(setting, value, users);
    }
  }
  return (
    <Fragment>
      <div className="flex flex-col relative">
        {loading && <Loader />}
        <div className="flex items-center justify-between ml-2">
          <h1 className="font-bold text-primary-violet">Settings</h1>
        </div>
        <div className="p-1">
          <InfoMessage message="These settings apply to your students for the Progress Learning subjects associated with your class." />
        </div>
        <div className="overflow-x-auto xl:overflow-x-visible shadow">
          <table className="table w-full">
            <thead className="mb-5">
              <tr className="bg-light-violet  text-white py-3 px-3 overflow-hidden">
                <th className="text-left  p-3 text-sm font-normal w-4"> </th>
                <th className="text-left  p-3 text-sm font-normal">Student</th>                
                {showSkillPracticeSetting && (
                  <>
                    <th className="text-center p-3 text-sm font-normal">
                      Skills Practice
                    </th>
                  </>
                )}
              </tr>
            </thead>
            <tbody>
              {classStudents?.data?.map((student, index) => {
                return (
                  <tr
                    key={student.userId}
                    className={`hover:bg-light-violet/20 transition ease-in-out duration-300 ${
                      index % 2 === 0 ? "bg-white" : "bg-light-violet/10"
                    }`}
                    onMouseOver={() => {
                      setHoverElement("row-" + student.userId);
                    }}
                  >
                    <td className=" mt-5 p-3 text-center">
                      <span className="relative">
                        <UserTile
                          userId={student.userId}
                          onlineUsers={onlineUsers}
                          isLoggedInUser={false}
                        />
                      </span>
                    </td>
                    <td className="text-sm mt-5  p-3 text-left">
                      {getNameLabel(student.lastName, student.firstName)}
                    </td>                    
                    {showSkillPracticeSetting && (
                      <td className="text-sm mt-5  p-3 ">
                        <div className="flex justify-center">
                          <Toggle
                            disabled={!props.isEditingEnabled || props.mode === PageAccessMode.Readonly}
                            checked={student.isPLSkillPracticeActive}
                            onChange={() => {
                              if (props.isEditingEnabled) {
                                updateUserSetting(
                                  student.userId,
                                  "isPLSkillPracticeActive",
                                  !student.isPLSkillPracticeActive
                                );
                              }
                            }}
                          />
                          <div className="w-6">
                            {props.mode !== PageAccessMode.Readonly &&
                              hoverElement === "row-" + student.userId && (
                                <ApplyAllSettingContextMenu
                                  isFirst={(index === 0).toString()}
                                  isLast={(
                                    index === pageSize - 1 ||
                                    index === classStudents.totalRecords - 1
                                  ).toString()}
                                  applyAll={applyAll}
                                  applyAllAbove={applyAllAbove}
                                  applyAllBelow={applyAllBelow}
                                  setting="isPLSkillPracticeActive"
                                  userId={student.userId}
                                  value={student.isPLSkillPracticeActive.toString()}
                                  isEditingEnabled={props.isEditingEnabled}
                                />
                              )}
                          </div>
                        </div>
                      </td>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between border-t-2 pt-4 mt-5">
          {classStudents?.totalRecords !== undefined &&
            classStudents?.totalRecords !== 0 && (
              <Pagination
                totalRecords={classStudents?.totalRecords}
                pageSize={pageSize}
                onPageChange={(e) => {
                  setPageNumber(e);
                }}
                reset={resetPageNumber}
                pageNumber={pageNumber}
              />
            )}
          {classStudents?.totalRecords !== 0 && (
            <PageSize pageSize={pageSize} onPageSizeChange={onPageSizeChange} />
          )}
          {classStudents?.totalRecords === 0 && (
            <span className="text-color-black">No record found</span>
          )}
        </div>
      </div>

      <ConfirmationDialog
        open={openConfirmation}
        confirmationTitle={confirmationTitle}
        confirmationMessage={confirmationMessage}
        onCancelClick={confirmationCancelClose}
        onOkClick={confirmationOkClose}
      ></ConfirmationDialog>
    </Fragment>
  );
};

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(ClassStudentSetting);

import { Fragment } from "react";
import Moment from "moment";
import { connect } from "react-redux";
import { LogoPLIcon } from "../../../../../assets/icons";
import constant from "../../../../../utils/constant/constant";
import ProfileModel from "../../../../../model/users/profile";
import ReportFooter from "../../../../shared/footer/reportFooter";

interface IPrintUsageReportProps {
  printFilter: any;
  data: Array<any>;
  userContext?: any;
  profile?: ProfileModel;
}

function PrintUsageReport(props: IPrintUsageReportProps) {
  const SubjectExpiryStatus = {
    Expired: 0,
    Active: 1,
    PartiallyExpired: 2,
  };
  return (
    <Fragment>
      <div className="w-full flex flex-col justify-between bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:max-w-5xl">
        <div className="w-full relative">
          <div className="flex items-center justify-between border-b-2 pb-4">
            <LogoPLIcon
              title="Progress Learning logo"
              className="w-20 h-auto cursor-pointer mr-5"
            />
            <div>
              <span className="text-base font-semibold text-primary-violet">
                Usage Report by Subject
              </span>
            </div>
            <span className="text-gray-400 text-xs">
              <span className="font-medium mr-1">Report generated on:</span>
              {Moment(new Date()).format("MM-DD-yyyy")}
            </span>
          </div>
          <div>
            <div className="flex gap-x-2 mt-4 ">
              <span className="font-semibold text-sm text-gray-600">
                Filters
              </span>
              <span className="border border-gray-300 text-xs rounded-lg px-2 flex items-center">
                {props.printFilter?.subjectNames}
              </span>
              {props.userContext.roleId === constant.UserRoleId.District && (
                <span className="border border-gray-300 text-xs rounded-lg px-2 flex items-center">
                  {props.printFilter?.schoolNames}
                </span>
              )}
              <span className="border border-gray-300 text-xs rounded-lg px-2 flex items-center">
                {props.printFilter?.view}
              </span>
              <span className="border border-gray-300 text-xs rounded-lg px-2 flex items-center">
                {props.printFilter?.schoolYear}
              </span>
            </div>
            <table className="w-full mt-4">
              <thead>
                <tr className="border-b border-t border-gray-600 divide-x divide-gray-600 mt-3">
                  <th className="text-right px-2 py-2 font-medium">Subject</th>
                  <th className="bg-gray-100 px-2 py-2 font-medium">Total</th>
                  <th className="bg-[#e06666] px-2 py-2 font-medium">
                    Assessments
                  </th>
                  <th className="bg-[#f7b26a] px-2 py-2 font-medium">
                    Assignments
                  </th>
                  <th className="bg-[#ffd965] px-2 py-2 font-medium">
                    Study Plan
                  </th>
                </tr>
              </thead>
              <tbody>
                {props.data &&
                  props.data?.length > 0 &&
                  props.data?.map((value, index) => {
                    return (
                      <tr
                        className="border-b border-gray-600 divide-x divide-gray-600"
                        key={index}
                      >
                        <td className="text-right px-2 py-1">
                          {value.subjectName +
                            (value.subjectExpiryStatus ===
                            SubjectExpiryStatus.Expired
                              ? " - expired"
                              : value.subjectExpiryStatus ===
                                SubjectExpiryStatus.PartiallyExpired
                              ? " - partially expired"
                              : "")}
                        </td>
                        <td className="bg-gray-100 text-center px-2 py-1">
                          {value.total}
                        </td>
                        <td className="text-center px-2 py-1">
                          {value.assignment}
                        </td>
                        <td className="text-center px-2 py-1">
                          {value.assessment}
                        </td>
                        <td className="text-center px-2 py-1">
                          {value.studyPlan}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
        <ReportFooter
          firstName={props.profile?.firstName}
          lastName={props.profile?.lastName}
        ></ReportFooter>
      </div>
    </Fragment>
  );
}

export default connect((state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
})(PrintUsageReport);

import { Fragment, useState } from "react";
import { connect } from "react-redux";
import { getNameLabel } from "../../../../utils/helper";
import IStudentSetting, {
  IUpdateKTo5StudentSetting,
  IUpdateLanguageStudentSetting,
} from "../../../../model/teacher/studentSetting";
import Toggle from "../../../shared/toggle";
import {
  updateStudentLanguageSetting,
  updateStudentKTo5SpanishSetting,
  updateStudentKTo5SpanishSettingByClassId,
  updateStudentLanguageSettingByClassId,
} from "../../../../api/teacher/studentSetting";
import { toast } from "react-toastify";
import Loader from "../../../shared/loader";
import { UserContextState } from "../../../../redux/actions/userContextAction";
import ApplyAllSettingContextMenu from "../shared/applyAllSettingContextMenu";
import ConfirmationDialog from "../../../shared/confirmationDialog";

interface LanguageSettingProps {
  UserContext: UserContextState | undefined;
  ContentAreas: any;
  Students: Array<IStudentSetting> | undefined;
  ClassId: number;
  GetSettings: Function;
}

const LanguageSetting = (props: LanguageSettingProps) => {
  enum ConfrimationType {
    UpdateUserSpanishSetting,
    UpdateStudentLanguageSetting,
  }
  const contentAreas = props.ContentAreas;
  const students: Array<IStudentSetting> | undefined =
    props.Students ?? undefined;
  const [loading, showLoading] = useState(false);
  const classId = props.ClassId;
  const [hoverElement, setHoverElement] = useState<string>("");
  const [updateKTo5StudentSetting, setUpdateKTo5StudentSetting] =
    useState<IUpdateKTo5StudentSetting>();
  const [updateLanguageStudentSetting, setUpdateLanguageStudentSetting] =
    useState<IUpdateLanguageStudentSetting>();

  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [confirmationTitle, setConfirmationTitle] = useState("Confirm Action");
  const [confirmationMessage, setConfirmationMessage] =
    useState("Please confirm");
  const [confirmationType, setConfirmationType] = useState<ConfrimationType>();

  function updateUserLanguageSetting(
    studentProfileId: number,
    contentAreaId: number,
    languageSettingId: number
  ): void {
    showLoading(true);
    updateStudentLanguageSetting(
      languageSettingId,
      contentAreaId,
      [studentProfileId],
      classId
    )
      .then((d) => {
        showLoading(false);
        props.GetSettings();
        if (d.data.isSuccess === true)
          toast.success("Student setting's updated successfully");
        else {
          toast.warning("Student setting's cannot be updated.");
        }
      })
      .catch(() => {
        showLoading(false);
      });
  }
  function updateUserSpanishSetting(
    studentProfileId: number,
    isKTo5SpanishActive: boolean
  ): void {
    showLoading(true);
    updateStudentKTo5SpanishSetting(
      isKTo5SpanishActive,
      [studentProfileId],
      classId
    )
      .then((d) => {
        showLoading(false);
        props.GetSettings();
        if (d.data.isSuccess === true)
          toast.success("Student setting's updated successfully");
        else {
          toast.warning("Student setting's cannot be updated.");
        }
      })
      .catch(() => {
        showLoading(false);
      });
  }

  function applyAllUserSpanishSetting(
    setting: string,
    studentProfileId: number,
    value: string
  ): void {
    setConfirmationType(ConfrimationType.UpdateUserSpanishSetting);
    updateAllUserSpanishSetting(value, []);
  }

  function applyAllUserLanguageSetting(
    contentAreaId: string,
    studentProfileId: number,
    value: string
  ): void {
    setConfirmationType(ConfrimationType.UpdateStudentLanguageSetting);
    updateAllUserLanguageSetting(parseInt(contentAreaId), value, []);
  }
  function updateAllUserLanguageSetting(
    contentAreaId: number,
    value: string,
    studentProfileIds: Array<number>
  ): void {
    const updateUserInfo: IUpdateLanguageStudentSetting = {
      classId: props.ClassId,
      studentProfileIds: studentProfileIds,
      contentAreaId: contentAreaId,
      value: parseInt(value),
      setting: "LanguageSetting",
    };
    setUpdateLanguageStudentSetting(updateUserInfo);
    showConfirmation(
      "Please confirm",
      "Are you sure you want to update the user settings?"
    );
  }
  function updateAllUserSpanishSetting(
    value: string,
    studentProfileIds: Array<number>
  ): void {
    const updateUserInfo: IUpdateKTo5StudentSetting = {
      classId: props.ClassId,
      studentProfileIds: studentProfileIds,
      value: value === "true" ? true : false,
      setting: "KTo5SpanishActive",
    };
    setUpdateKTo5StudentSetting(updateUserInfo);
    showConfirmation(
      "Please confirm",
      "Are you sure you want to update the user settings?"
    );
  }
  function showConfirmation(title: string, message: string) {
    setOpenConfirmation(true);
    setConfirmationTitle(title);
    setConfirmationMessage(message);
  }
  function confirmationOkClose() {
    switch (confirmationType) {
      case ConfrimationType.UpdateUserSpanishSetting:
        updateAllUserSpanishSettingConfirm();
        break;
      case ConfrimationType.UpdateStudentLanguageSetting:
        updateAllUserLanguageSettingConfirm();
        break;
    }

    if (confirmationType === ConfrimationType.UpdateUserSpanishSetting)
      updateAllUserSpanishSettingConfirm();
  }
  function confirmationCancelClose() {
    setOpenConfirmation(false);
  }
  function updateAllUserSpanishSettingConfirm() {
    if (updateKTo5StudentSetting) {
      showLoading(true);
      updateStudentKTo5SpanishSettingByClassId(
        updateKTo5StudentSetting.value,
        updateKTo5StudentSetting.classId
      ).then(() => {
        showLoading(false);
        confirmationCancelClose();
        props.GetSettings();
        toast.success("Student setting's updated successfully");
      });
    }
  }
  function updateAllUserLanguageSettingConfirm() {
    if (updateLanguageStudentSetting) {
      showLoading(true);
      updateStudentLanguageSettingByClassId(
        updateLanguageStudentSetting.value,
        updateLanguageStudentSetting.contentAreaId,
        updateLanguageStudentSetting.classId
      ).then(() => {
        showLoading(false);
        confirmationCancelClose();
        props.GetSettings();
        toast.success("Student setting's updated successfully");
      });
    }
  }
  function getLanguageStudentSetting(
    student: IStudentSetting | undefined,
    contentAreaId: number
  ) {
    return student?.settings?.find((f) => f.contentAreaId === contentAreaId)
      ?.languageSettingId;
  }

  return (
    <Fragment>
      <div className="flex flex-col relative">
        {loading && <Loader />}
        <div className="shadow overflow-x-auto">
          <table className="table w-full">
            <thead className="mb-5">
              <tr className="text-white py-3 px-3 overflow-hidden border-[3px] border-primary-violet">
                <th className="text-left  px-3 pb-1.5 pt-1.5 text-sm font-normal align-bottom text-primary-violet">
                  <div className="flex w-auto">Name</div>
                </th>
                <th className="text-left px-3 pb-1.5 pt-1.5 text-sm font-normal align-bottom text-primary-violet">
                  <div className="flex w-auto">Student Id</div>
                </th>
                <th className="text-left px-3 pb-1.5 pt-1.5 text-sm font-normal align-bottom text-primary-violet">
                  <div className="flex w-auto justify-center">
                    Dashboard in Spanish K-5 Students Only
                  </div>
                </th>
                <th
                  colSpan={4}
                  className="text-center text-sm font-normal text-primary-violet"
                >
                  <div className="bg-gray-100 py-1.5 text-gray-600">
                    Language Settings
                  </div>

                  <table className="w-full">
                    <tr>
                      {contentAreas?.map((contentArea) => {
                        return (
                          <td className="py-1.5 w-16">{contentArea.name}</td>
                        );
                      })}
                    </tr>
                  </table>
                </th>
              </tr>
            </thead>
            <tbody>
              {students?.map((student, index) => {
                return (
                  <tr
                    key={student.studentProfileId}
                    className={
                      index % 2 === 0
                        ? " hover:bg-light-violet/20 transition ease-in-out duration-300 bg-white"
                        : "hover:bg-light-violet/20 transition ease-in-out duration-300 bg-light-violet/10"
                    }
                  >
                    <td className="text-sm p-3 text-left text-gray-700">
                      {getNameLabel(student.lastName, student.firstName)}
                    </td>
                    <td className="text-sm p-3 text-left text-gray-700">
                      {student.studentId}
                    </td>
                    <td
                      className="text-sm p-3 text-left text-gray-700"
                      onMouseOver={() => {
                        setHoverElement(
                          `isKTo5SpanishActive-${student.studentProfileId}`
                        );
                      }}
                    >
                      {student.isKTo5SpanishActive !== undefined &&
                        student.isKTo5SpanishActive !== null && (
                          <div className="w-full flex justify-center items-center text-gray-700 gap-x-1">
                            Off
                            <Toggle
                              disabled={false}
                              checked={student.isKTo5SpanishActive ?? false}
                              onChange={() => {
                                updateUserSpanishSetting(
                                  student.studentProfileId,
                                  !student.isKTo5SpanishActive
                                );
                              }}
                            />
                            On
                            <div className="w-6">
                              {hoverElement ===
                                `isKTo5SpanishActive-${student.studentProfileId}` && (
                                <ApplyAllSettingContextMenu
                                  isFirst={undefined}
                                  isLast={undefined}
                                  applyAll={applyAllUserSpanishSetting}
                                  setting="KTo5SpanishActive"
                                  userId={student.studentProfileId}
                                  value={(
                                    student.isKTo5SpanishActive ?? false
                                  ).toString()}
                                />
                              )}
                            </div>
                          </div>
                        )}
                    </td>
                    {contentAreas?.map((contentArea) => {
                      return (
                        <td
                          className="text-sm p-3 text-gray-700"
                          onMouseOver={() => {
                            setHoverElement(
                              `LanguageSetting-${contentArea.contentAreaId}-${student.studentProfileId}`
                            );
                          }}
                        >
                          <div className="flex justify-between items-center">
                            <div className="flex flex-col gap-y-1">
                              <label
                                id={"ToggleEnglish-" + student.studentProfileId}
                                htmlFor={
                                  "toggle" +
                                  student.studentProfileId.toString() +
                                  contentArea.contentAreaId.toString()
                                }
                                className="text-sm cursor-pointer flex gap-x-1.5 items-center whitespace-nowrap"
                              >
                                <input
                                  id={
                                    "toggle" +
                                    student.studentProfileId.toString() +
                                    contentArea.contentAreaId.toString()
                                  }
                                  type="radio"
                                  name={
                                    "toggle" +
                                    student.studentProfileId.toString() +
                                    contentArea.contentAreaId.toString()
                                  }
                                  value="1"
                                  checked={
                                    student.settings?.find(
                                      (f) =>
                                        f.contentAreaId ===
                                        contentArea.contentAreaId
                                    )?.languageSettingId === 1
                                  }
                                  className="h-4 w-4  border-gray-300 focus:ring-primary-violet text-primary-violet"
                                  onChange={() => {
                                    updateUserLanguageSetting(
                                      student.studentProfileId,
                                      contentArea.contentAreaId,
                                      1
                                    );
                                  }}
                                />
                                Toggle: English Default
                              </label>

                              <label
                                id={
                                  "ToggleSpanish-" +
                                  student.studentProfileId.toString()
                                }
                                htmlFor={
                                  "toggle" +
                                  student.studentProfileId.toString() +
                                  contentArea.contentAreaId.toString()
                                }
                                className="text-sm cursor-pointer flex gap-x-1.5 items-center whitespace-nowrap"
                              >
                                <input
                                  id={
                                    "toggle" +
                                    student.studentProfileId.toString() +
                                    contentArea.contentAreaId.toString()
                                  }
                                  type="radio"
                                  name={
                                    "toggle" +
                                    student.studentProfileId.toString() +
                                    contentArea.contentAreaId.toString()
                                  }
                                  value="2"
                                  checked={
                                    student.settings?.find(
                                      (f) =>
                                        f.contentAreaId ===
                                        contentArea.contentAreaId
                                    )?.languageSettingId === 2
                                  }
                                  className="h-4 w-4  border-gray-300 focus:ring-primary-violet text-primary-violet"
                                  onChange={() => {
                                    updateUserLanguageSetting(
                                      student.studentProfileId,
                                      contentArea.contentAreaId,
                                      2
                                    );
                                  }}
                                />
                                Toggle: Spanish Default
                              </label>

                              <label
                                id={
                                  "English-" +
                                  student.studentProfileId.toString()
                                }
                                htmlFor={
                                  "toggle" +
                                  student.studentProfileId.toString() +
                                  contentArea.contentAreaId.toString()
                                }
                                className="text-sm cursor-pointer flex gap-x-1.5 items-center whitespace-nowrap"
                              >
                                <input
                                  id={
                                    "toggle" +
                                    student.studentProfileId.toString() +
                                    contentArea.contentAreaId.toString()
                                  }
                                  type="radio"
                                  name={
                                    "toggle" +
                                    student.studentProfileId.toString() +
                                    contentArea.contentAreaId.toString()
                                  }
                                  value="3"
                                  checked={
                                    student.settings?.find(
                                      (f) =>
                                        f.contentAreaId ===
                                        contentArea.contentAreaId
                                    )?.languageSettingId === 3
                                  }
                                  className="h-4 w-4  border-gray-300 focus:ring-primary-violet text-primary-violet"
                                  onChange={() => {
                                    updateUserLanguageSetting(
                                      student.studentProfileId,
                                      contentArea.contentAreaId,
                                      3
                                    );
                                  }}
                                />
                                English Only
                              </label>
                            </div>
                            <div className="w-6">
                              {hoverElement ===
                                `LanguageSetting-${contentArea.contentAreaId}-${student.studentProfileId}` && (
                                <ApplyAllSettingContextMenu
                                  isFirst={undefined}
                                  isLast={undefined}
                                  applyAll={applyAllUserLanguageSetting}
                                  setting={contentArea.contentAreaId}
                                  userId={student.studentProfileId}
                                  value={
                                    getLanguageStudentSetting(
                                      student,
                                      contentArea.contentAreaId
                                    )?.toString() ?? "-1"
                                  }
                                />
                              )}
                            </div>
                          </div>
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <ConfirmationDialog
        open={openConfirmation}
        confirmationTitle={confirmationTitle}
        confirmationMessage={confirmationMessage}
        onCancelClick={confirmationCancelClose}
        onOkClick={confirmationOkClose}
      ></ConfirmationDialog>
    </Fragment>
  );
};
const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(LanguageSetting);

export type IStandardEfficiencyParams = {
    isTeacherView: boolean,
    studentId: number,
    studentGradeId: number,
    sessionId: string,
    studentUrl: string,
    history: any
}

export const validParameters = (props: any) => {
    return (!props.isTeacherView && props?.match?.params) || 
        (props.isTeacherView && props.sessionId && props.studentId);
}

export const getAPIParameters  = (props: any) => {
    const parameters = props?.match?.params;
    return {
        studentId: props.isTeacherView ? props.studentId : parameters?.studentId,
        learnosityReferenceId:  parameters?.learnosityReferenceId,
        learnositySessionId: props.isTeacherView ? props.sessionId : parameters?.sessionId,
    }
}


import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getQuestionClassification } from "../../../../../api/teacher/question";
import QuestionClassification from "../../../../../model/teacher/questionClassification";

const theme = {
  ELEMENTARY: {
    bgPanel: "bg-gray-50",
    questionFeedback: "",
    questionFeedbackText: "",
    contentPadding: "sm:px-6 lg:px-8",
    textColor: "text-gray-700",
    textDomain: "text-primary-violet",
  },
  KINDERGARTEN: {
    bgPanel: "bg-gray-50",
    questionFeedback: "",
    questionFeedbackText: "text-gray-700",
    contentPadding: "sm:px-6 lg:px-8",
    textColor: "text-gray-700",
    textDomain: "text-primary-violet",
  },
  MIDDLESCHOOL: {
    bgPanel: "",
    questionFeedback: "",
    questionFeedbackText: "text-white",
    contentPadding: "sm:px-1 lg:px-3",
    textColor: "text-white",
    textDomain: "text-white opacity-70",
  },
  TEACHER: {
    bgPanel: "",
    questionFeedback: "",
    questionFeedbackText: "text-white",
    contentPadding: "sm:px-1 lg:px-3",
    textColor: "text-gray-700",
    textDomain: "text-primary-violet",
  },
};
interface ClassificationProps {
  questionId: number;
  questionTypeId?: number;
  standardId?: string;
  isSpanish?: boolean;
  subjectId?: number;
  domainId?: number;
  assessmentId?: number;
  setStandardId?: (standardId: number) => void;
}
export default function Classification(props: ClassificationProps) {
  const history = useHistory();

  const url = history.location.pathname;

  const themeName = url.toUpperCase().includes("ELEMENTARY")
    ? "ELEMENTARY"
    : url.toUpperCase().includes("MIDDLESCHOOL")
    ? "MIDDLESCHOOL"
    : url.toUpperCase().includes("KINDERGARTEN")
    ? "KINDERGARTEN"
    : "TEACHER";
  const [classification, setQuestionClassification] =
    useState<QuestionClassification>();

  useEffect(() => {
    getQuestionClassification(
      props.questionId,
      props.questionTypeId ?? 1,
      props.standardId ?? "0",
      props.subjectId,
      props.domainId,
      props.assessmentId
    ).then((res) => {
      if (res.data) {
        setQuestionClassification(res.data);

        if (res.data.standardId && props.setStandardId)
          props.setStandardId(res.data.standardId);
      }
    });
  }, [props.questionId]);

  return (
    <>
      {classification && (
        <div className="flex flex-col">
          <div>
            <span
              className={`${theme[themeName]?.textColor} font-semibold text-base`}
            >
              {props.isSpanish
                ? classification?.spanishSubjectName ??
                  classification?.subjectName
                : classification.subjectName}{" "}
              /
            </span>
            <span className={`${theme[themeName]?.textDomain} ml-1`}>
              {props.isSpanish
                ? classification?.spanishDomainName ??
                  classification?.domainName
                : classification.domainName}
            </span>
          </div>
          <div className="mt-1">
            <span className={`${theme[themeName]?.textColor}`}>
              [{classification?.standardCode}]{" "}
              {props.isSpanish
                ? classification?.spanishStandardName ??
                  classification.standardName
                : classification.standardName}
            </span>
            {classification?.dokName && (
              <span className="px-1 border border-gray-300 bg-gray-200 rounded-lg ml-1.5 mt-1">
                {classification?.dokId + " - " + classification?.dokName}
              </span>
            )}
          </div>
        </div>
      )}
    </>
  );
}

import { XIcon } from "@heroicons/react/outline";
import { Select } from "@windmill/react-ui";
import { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { getClassInformation } from "../../../../api/class/classStudent";
import { getAllGrades } from "../../../../api/gradeApi";
import { getSchoolByDistrictId } from "../../../../api/teacher/school";
import UserApi from "../../../../api/userApi";
import { Eye, EyeOff } from "../../../../assets/icons";
import { IGrade } from "../../../../model/interface/grade";
import { ISchool } from "../../../../model/interface/school";
import User from "../../../../model/manageUser/user";
import userError from "../../../../model/manageUser/userError";
import {
  default as Constant,
  default as constant,
} from "../../../../utils/constant/constant";
import Loader from "../../../shared/loader";

function StudentModal(props: any) {
  const alphaNumericExpression = new RegExp(/^[a-zA-Z0-9_@.-]*$/);
  const numericExpression = new RegExp(/^[0-9\b]+$/);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [schools, setSchools] = useState<ISchool[]>();
  const [grades, setGrades] = useState<IGrade[]>();
  const [defaultGradeId, setDefaultGradeId] = useState(0);
  const [showLoader, setLoader] = useState(false);
  const [userErrors, setUserErrors] = useState({
    firstName: new userError(false, constant.UserErrorMessage.FirstName),
    lastName: new userError(false, constant.UserErrorMessage.LastName),
    username: new userError(false, constant.UserErrorMessage.UserName),
    password: new userError(false, constant.UserErrorMessage.Password),
    grade: new userError(false, constant.UserErrorMessage.Grade),
    email: new userError(false, constant.UserErrorMessage.Email),
    emailFormat: new userError(false, constant.UserErrorMessage.EmailFormat),
    usernameLength: new userError(
      false,
      constant.UserErrorMessage.UsernameLength
    ),
    passwordLength: new userError(
      false,
      constant.UserErrorMessage.PasswordLength
    ),
    schoolAccountId: new userError(
      false,
      constant.UserErrorMessage.SchoolAccountId
    ),
  });
  const [studentClasses, setStudentClasses] = useState<Array<any>>([]);
  const [user, setUser] = useState<User>({
    firstName: props.selectedUser?.firstName,
    createdByUserId: props.userContext.userId,
    email: props.selectedUser?.email,
    gradeId: props.selectedUser ? props.selectedUser?.gradeId : defaultGradeId,
    lastName: props.selectedUser?.lastName,
    passwordHash:
      parseInt(props.selectedUser?.roleId) === Constant.UserRoleId.Student
        ? atob(props.selectedUser?.passwordHash)
        : "",
    userId: props.selectedUser?.userId,
    username: props.selectedUser?.username,
    studentProfileId: props.selectedUser?.studentProfileId ?? 0,
    teacherProfileId: props.selectedUser?.teacherProfileId ?? 0,
    isTrial: props.selectedUser?.isTrail,
    middleName: props.selectedUser?.middleName,
    roleId: parseInt(props.selectedUser?.roleId),
    stateId: props.selectedUser?.stateId ? props.selectedUser?.stateId : 1,
    schoolId: props.userContext.schoolId,
    schoolAccountId: props.selectedUser?.schoolAccountId,
    updatedByUserId: 0,
    isActive: props.selectedUser?.isActive
      ? props.selectedUser?.isActive
      : false,
    isDisabled: props.selectedUser?.isDisabled,
    studentId: props.selectedUser?.studentId ?? "",
    districtId: props.userContext?.districtId,
  });
  const handleOnClose = () => {
    props.showPopUp(false);
    props.handleRefreshData();
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    var addUser = user;
    addUser.isDisabled =
      addUser.isDisabled === null ? false : addUser.isDisabled;
    if (props.selectedUser && user) {
      addUser.updatedByUserId = props.userContext.userId;
      addUser.isActive = true;
      if (addUser.roleId !== constant.UserRoleId.Student) {
        addUser.gradeId = 0;
        addUser.studentProfileId = 0;
      } else {
        addUser.teacherProfileId = 0;
      }
      setUser(addUser);
      if (Validation(addUser)) {
        return;
      }

      setLoader(true);
      var response = UserApi.UpdateUser(addUser);
      response?.then((d) => {
        if (d.data.isSuccess === true) {
          handleOnClose();
          toast.success("User information updated successfully.");
        } else {
          toast.warning(d.data.message);
        }
        setLoader(false);
      });
    } else {
      var roleId =
        props.mode === constant.ManageUserMode.Student
          ? constant.UserRoleId.Student
          : props.mode === constant.ManageUserMode.Teacher
          ? constant.UserRoleId.SchoolTeacher
          : props.mode === constant.ManageUserMode.District
          ? constant.UserRoleId.District
          : 0;
      addUser = user;
      addUser.roleId = roleId;
      setUser(addUser);
      if (Validation(addUser)) {
        return;
      }
      setLoader(true);
      var updateResponse = UserApi.AddUser(addUser);
      updateResponse?.then((d) => {
        if (d.data.isSuccess === true) {
          toast.success("User added successfully.");
          handleOnClose();
        } else {
          toast.warning(d.data.message);
        }
        setLoader(false);
      });
    }
  };
  const ShowStudentClasses = () => {
    getClassInformation(props.selectedUser?.userId).then((d) => {
      setStudentClasses(d.data);
    });
  };
  function isValidateEmail(email): boolean {
    var reg = /^[A-Z0-9._%+-]+@([A-Z0-9-]+\.)+[A-Z]{2,4}$/i;
    if (reg.test(email)) {
      return true;
    }
    return false;
  }
  useEffect(() => {
    setUser((prevState) => {
      return {
        ...prevState,
        passwordHash: props.selectedUserPassword?.passwordHash,
      };
    });
  }, [props]);

  const Validation = (user: User) => {
    var error = userErrors;
    error.schoolAccountId.isError =
      (!user.schoolAccountId && props.userContext?.roleId === constant.UserRoleId.District &&
      props.mode !== Constant.ManageUserMode.District)
        ? true
        : false;
    error.firstName.isError = !user.firstName ? true : false;
    error.lastName.isError = !user.lastName ? true : false;
    error.username.isError = !user.username ? true : false;
    error.usernameLength.isError = !user.username
      ? false
      : user.username.length < 6 || user.username.length > 60;

    if (constant.ManageUserMode.Student !== props.mode) {
      error.email.isError = !user.email ? true : false;
      error.emailFormat.isError = !user.email
        ? false
        : !isValidateEmail(user.email);
    } else {
      error.grade.isError = !user.gradeId || user.gradeId <= 0 ? true : false;
    }
    setUserErrors({ ...error });
    return (
      error.firstName.isError ||
      error.lastName.isError ||
      error.username.isError ||
      error.grade.isError ||
      error.email.isError ||
      error.emailFormat.isError ||
      error.usernameLength.isError ||
      error.passwordLength.isError ||
      error.schoolAccountId.isError
    );
  };
  useEffect(() => {
    var gradeResponse = getAllGrades();

    gradeResponse?.then((d) => {
      setGrades(d.data);
      setDefaultGradeId(d.data[0].gradeId);
    });

    if (props.mode === Constant.ManageUserMode.Student) {
      ShowStudentClasses();
    }

    if (
      props.userContext?.roleId === constant.UserRoleId.District &&
      (props.mode === Constant.ManageUserMode.Student ||
        props.mode === Constant.ManageUserMode.Teacher)
    ) {
      getSchools();
    }
  }, [false]);

  function getSchools() {
    getSchoolByDistrictId(props.userContext?.districtId ?? 0).then((d) => {
      setSchools(d.data);
    });
  }

  const isStudentUser =
    parseInt(props.selectedUser?.roleId) === Constant.UserRoleId.Student;
  const isSchoolAdminOrDistrict =
    parseInt(props.userContext.roleId) === Constant.UserRoleId.SchoolAdmin ||
    parseInt(props.userContext.roleId) === Constant.UserRoleId.District;
  const isExternalRosterEnabled = props.selectedUser
    ? props.selectedUser.isSchoolRosterEnabled ??
      props.selectedUser.isExternalRosterUser
    : false;
  const isEditUser =
    props.selectedUser !== null && props.selectedUser !== undefined;

  return (
    <Fragment>
      <div className="fixed inset-0 z-40 flex items-center bg-black bg-opacity-50 sm:items-center sm:justify-center">
        <div
          className="w-full flex flex-col justify-between overflow-hidden h-auto max-h-80percent bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:max-w-5xl"
          role="dialog"
        >
          <header>
            {/* Header */}
            <div className="px-4 py-4 bg-gray-50">
              <div className="flex items-start justify-between space-x-3">
                <div className="space-y-1">
                  <div className="text-lg font-medium text-gray-900">
                    {props.mode === Constant.ManageUserMode.Student && (
                      <h2 className="text-lg text-gray-700 mb-2">
                        {" "}
                        Add/Edit Student{" "}
                      </h2>
                    )}
                    {props.mode === Constant.ManageUserMode.Teacher && (
                      <h2 className="text-xl mb-2">Add/Edit Teacher </h2>
                    )}
                    {props.mode === Constant.ManageUserMode.District && (
                      <h2 className="text-xl mb-2">Add/Edit District User </h2>
                    )}
                  </div>
                </div>
                <div className="h-7 flex items-center">
                  <button
                    type="button"
                    className="text-gray-400 hover:text-gray-500"
                    onClick={() => props.showPopUp(false)}
                  >
                    <span className="sr-only">Close panel</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
          </header>
          <div className="w-full relative overflow-auto px-4 py-6">
            <div>
              {showLoader && <Loader></Loader>}

              <div className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 xl:grid-cols-2 sm:gap-x-8">
                {props.userContext?.roleId === constant.UserRoleId.District &&
                  props.mode !== Constant.ManageUserMode.District && (
                    <>
                      <div>
                        <label
                          htmlFor="user_name"
                          className="block text-sm text-gray-500"
                        >
                          School <span className="text-red-500">*</span>
                        </label>
                        <div className="mt-1">
                          <Select
                            value={user.schoolAccountId}
                            onChange={(e) => {
                              setUser({
                                ...user,
                                schoolAccountId: parseInt(e.target.value),
                              });
                            }}
                            className="py-2.5 px-4 block text-sm border border-gray-400 
             border-opacity-50 focus:ring-primary-violet focus:border-primary-violet form-select leading-5 p-1 rounded-lg"
                          >
                            <option value="">{"Select School"}</option>
                            {schools?.map((school: ISchool, index) => {
                              return (
                                <Fragment key={index}>
                                  <option value={school.schoolAccountId}>
                                    {school.name}
                                  </option>
                                </Fragment>
                              );
                            })}
                          </Select>
                          {userErrors.schoolAccountId.isError && (
                            <span className="text-red-500">
                              {userErrors.schoolAccountId.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div></div>
                    </>
                  )}
                <div>
                  <label
                    htmlFor="user_name"
                    className="block text-sm text-gray-500"
                  >
                    First Name <span className="text-red-500">*</span>
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="user_firstName"
                      id="user_firstName"
                      value={user.firstName}
                      maxLength={60}
                      onChange={(e) => {
                        setUser({ ...user, firstName: e.target.value });
                      }}
                      className="border py-2 px-4 block w-full shadow-sm text-gray-900 focus:ring-primary-violet focus:border-primary-violet border border-gray-300 rounded-md"
                    />
                    {userErrors.firstName.isError && (
                      <span className="text-red-500">
                        {userErrors.firstName.message}
                      </span>
                    )}
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="student_id"
                    className="block text-sm  text-gray-500"
                  >
                    Last Name <span className="text-red-500">*</span>
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="user_lastName"
                      id="user_lastName"
                      value={user.lastName}
                      maxLength={60}
                      onChange={(e) => {
                        setUser({ ...user, lastName: e.target.value });
                      }}
                      className="border py-2 px-4 block w-full shadow-sm text-gray-900 focus:ring-primary-violet focus:border-primary-violet border border-gray-300 rounded-md"
                    />
                    {userErrors.lastName.isError && (
                      <span className="text-red-500">
                        {userErrors.lastName.message}
                      </span>
                    )}
                  </div>
                </div>
                {props.mode === Constant.ManageUserMode.Student && (
                  <div>
                    <label
                      htmlFor="user_grade"
                      className="block text-sm  text-gray-500"
                    >
                      Grade <span className="text-red-500">*</span>
                    </label>
                    <div className="mt-1">
                      <Select
                        name="user_grade"
                        value={user.gradeId}
                        onChange={(e) => {
                          setUser({
                            ...user,
                            gradeId: parseInt(e.target.value),
                          });
                        }}
                        className="py-2.5 px-4 block text-sm focus:ring-primary-violet focus:border-primary-violet border border-gray-400
                                border-opacity-50 form-select
                                leading-5 p-1 rounded-lg"
                      >
                        <option key={0} value={0}>
                          Select Grade
                        </option>
                        {grades?.map((item: IGrade) => {
                          return (
                            <Fragment key={item.gradeId}>
                              <option key={item.gradeId} value={item.gradeId}>
                                {item?.name}
                              </option>
                            </Fragment>
                          );
                        })}
                      </Select>
                      {userErrors.grade.isError && (
                        <span className="text-red-500">
                          {userErrors.grade.message}
                        </span>
                      )}
                    </div>
                  </div>
                )}

                <div>
                  <label
                    htmlFor="user_username"
                    className="block text-sm  text-gray-500"
                  >
                    Username <span className="text-red-500">*</span>
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="user_username"
                      id="user_username"
                      value={user.username}
                      autoComplete="off"
                      maxLength={60}
                      minLength={6}
                      onChange={(e) => {
                        if (
                          alphaNumericExpression.test(e.target.value.toString())
                        ) {
                          setUser({ ...user, username: e.target.value });
                        }
                      }}
                      className="border py-2 px-4 block w-full shadow-sm text-gray-900 focus:ring-primary-violet focus:border-primary-violet border border-gray-300 rounded-md"
                    />
                    {userErrors.username.isError && (
                      <span className="text-red-500">
                        {userErrors.username.message}
                      </span>
                    )}
                    {userErrors.usernameLength.isError && (
                      <span className="text-red-500">
                        {userErrors.usernameLength.message}
                      </span>
                    )}
                  </div>
                </div>

                {((isEditUser &&
                  isStudentUser &&
                  isSchoolAdminOrDistrict &&
                  isExternalRosterEnabled === false) ||
                  isEditUser === false) && (
                  <div>
                    <label
                      htmlFor="user_password"
                      className="block text-sm  text-gray-500"
                    >
                      Password <span className="text-red-500">*</span>
                    </label>
                    <div className="mt-1">
                      <div className="flex">
                        <input
                          type={showPassword === true ? "text" : "password"}
                          name="user_password"
                          id="user_password"
                          autoComplete="new-password"
                          value={user.passwordHash}
                          maxLength={60}
                          minLength={6}
                          onChange={(e) => {
                            setUser({
                              ...user,
                              passwordHash: e.target.value,
                            });
                          }}
                          className="border py-2 px-4 block w-full shadow-sm text-gray-900 focus:ring-primary-violet focus:border-primary-violet border border-gray-300 rounded-md"
                        />
                        {showPassword === true && (
                          <Eye
                            onClick={() => {
                              setShowPassword(!showPassword);
                            }}
                            title="Hide password"
                            className="ml-2 mt-1 w-5 text-gray-500 cursor-pointer"
                          />
                        )}
                        {showPassword === false && (
                          <EyeOff
                            onClick={() => {
                              setShowPassword(!showPassword);
                            }}
                            title="Show password"
                            className="ml-2 mt-1 w-5 text-gray-500 cursor-pointer"
                          />
                        )}
                      </div>
                      {userErrors.password.isError && (
                        <span className="text-red-500">
                          {userErrors.password.message}
                        </span>
                      )}
                      {userErrors.passwordLength.isError && (
                        <span className="text-red-500">
                          {userErrors.passwordLength.message}
                        </span>
                      )}
                    </div>
                  </div>
                )}

                {props.mode === constant.ManageUserMode.Student && (
                  <div>
                    <label
                      htmlFor="user_username"
                      className="block text-sm  text-gray-500"
                    >
                      Student Id
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        name="user_studentId"
                        id="user_studentId"
                        value={user.studentId}
                        onChange={(e) => {
                          if (
                            numericExpression.test(e.target.value.toString()) ||
                            e.target.value.toString() === ""
                          ) {
                            setUser({ ...user, studentId: e.target.value });
                          }
                        }}
                        className="border py-2 px-4 block w-full shadow-sm text-gray-900 focus:ring-primary-violet focus:border-primary-violet border border-gray-300 rounded-md"
                      />
                    </div>
                  </div>
                )}
                <div>
                  <label
                    htmlFor="user_email"
                    className="block text-sm  text-gray-500"
                  >
                    Email{" "}
                    {props.mode !== Constant.ManageUserMode.Student && (
                      <span className="text-red-500">*</span>
                    )}
                    {props.mode === Constant.ManageUserMode.Student && (
                      <span className="text-xs">
                        {" "}
                        Optional, but recommended
                      </span>
                    )}
                  </label>

                  <div className="mt-1">
                    <input
                      type="text"
                      name="user_email"
                      id="user_email"
                      value={user.email}
                      maxLength={60}
                      onChange={(e) => {
                        setUser({ ...user, email: e.target.value });
                      }}
                      className="border py-2 px-4 block w-full shadow-sm text-gray-900 focus:ring-primary-violet focus:border-primary-violet border border-gray-300 rounded-md"
                    />
                    {userErrors.email.isError && (
                      <span className="text-red-500">
                        {userErrors.email.message}
                      </span>
                    )}
                    {userErrors.emailFormat.isError && (
                      <span className="text-red-500">
                        {userErrors.emailFormat.message}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              {props.mode === Constant.ManageUserMode.Student &&
                props.selectedUser &&
                user && (
                  <div className="border-t mt-4 pt-2">
                    <div className="col-span-6 border-b pb-2">
                      <span className="h1 text-base font-semibold text-gray-700 inline-block mt-2">
                        Student Classes
                      </span>
                    </div>
                    <table className="table-fixed w-full text-sm bg-gray-50 rounded-lg">
                      <thead className=" rounded-2xl overflow-hidden">
                        <tr className="bg-light-violet text-white py-3 px-3">
                          <th className="text-left  p-3 text-sm font-normal">
                            Class Name
                          </th>
                          <th className="text-left  p-3 text-sm font-normal">
                            Teacher
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {studentClasses.map((studentclass, index) => {
                          return (
                            <tr
                              className={
                                index % 2 === 0
                                  ? " hover:bg-light-violet/20 transition ease-in-out duration-300 bg-white"
                                  : "hover:bg-light-violet/20 transition ease-in-out duration-300 bg-light-violet/10"
                              }
                              key={index}
                            >
                              <td className="p-3 whitespace-nowrap text-sm text-gray-500">
                                {studentclass.className}
                              </td>
                              <td className="p-3 whitespace-nowrap text-sm text-gray-500">
                                {studentclass.firstName} {studentclass.lastName}
                              </td>
                            </tr>
                          );
                        })}
                        {(studentClasses === null ||
                          studentClasses.length === 0) && (
                          <tr key={0}>
                            <td colSpan={2}>No classes exists</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                )}
            </div>
          </div>
          {/* <!-- Action Buttons -->  */}
          <footer className="flex flex-col items-center justify-end px-6 py-4 sm:flex-row bg-gray-50 dark:bg-gray-800">
            <div className="flex-shrink-0">
              <div className="space-x-3 flex justify-end">
                <button
                  className="align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 rounded-lg text-sm text-gray-600 border-gray-300 border dark:text-gray-400 focus:outline-none active:bg-transparent hover:border-gray-500 focus:border-gray-500 active:text-gray-500 focus:shadow-outline-gray sm:w-auto"
                  type="button"
                  onClick={() => {
                    props.showPopUp(false);
                  }}
                >
                  Cancel
                </button>
                <button
                  className="align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 rounded-lg text-sm text-white border border-transparent bg-primary-green focus:shadow-outline-purple sm:w-auto"
                  type="button"
                  onClick={(e) => {
                    handleSubmit(e);
                  }}
                >
                  Save
                </button>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </Fragment>
  );
}

const mapStateToProps = (state: any) => {
  return {
    masterData: state.masterData,
    userContext: state.userContext,
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(StudentModal);

import { Fragment, useState } from "react";
import HeaderLeft from "./headerLeft";
import HeaderTop from "./headerTop";
import { backToOriginalUser } from "../../../../../utils/impersonateHelper";
import * as userContextAction from "../../../../../redux/actions/userContextAction";
import { fetchProfile } from "../../../../../redux/actions/userActions";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import Footer from "./footer";
import classNames from "classnames";
import constant from "../../../../../utils/constant/constant";
const PlColor: string = "from-[#0be600] to-[#adfb28]";
const loColor: string = "from-[#59e3ff] to-[#3dbbff]";

const Layout = ({ children, ...props }) => {
  const history = useHistory();
  const location = useLocation();
  const lsPGMode = localStorage.getItem("programMode");
  const [programMode, setProgramMode] = useState<string>(
    lsPGMode ? lsPGMode : "EG"
  );

  function backToTeacher(): void {
    backToOriginalUser(
      props.userContext,
      props.setUserContext,
      props.setProfile,
      history
    );
  }

  const gradeLevel =
  location.pathname.toLowerCase().indexOf("kindergarten") > -1
    ? "kindergarten"
    : location.pathname.toLowerCase().indexOf("elementary") > -1
    ? "elementary"
    : location.pathname.toLowerCase().indexOf("middleschool") > -1
    ? "middleschool"
    : "elementary";

  if (programMode == constant.ProgramMode.LO && location.pathname.toLowerCase().indexOf("liftoff") === -1) {
    localStorage.setItem("programMode", constant.ProgramMode.EG);
    setProgramMode(constant.ProgramMode.EG);
  }

  return (
    <Fragment>
      <div
        className={classNames(
          "flex w-full min-h-screen bg-gradient-to-t border-4 border-secondary-teal",
          programMode === "EG" ? PlColor : loColor
        )}
      >
        {props.userContext?.impersonatedUser !== null && (
          <div className="w-full fixed pointer-events-none min-h-full z-50 flex justify-center  ">
            <span className="bg-secondary-teal h-8  px-3  flex items-center  text-white ">
              You are logged in as {props.profile?.lastName},{" "}
              {props.profile?.firstName}
              <span
                className="pl-2 text-white hover:underline cursor-pointer pointer-events-auto font-semibold"
                onClick={backToTeacher}
              >
                Return to your account
              </span>
            </span>
          </div>
        )}

        <div className="flex flex-col w-full min-h-full justify-between">
          <div className={`flex ${gradeLevel !== "elementary" && gradeLevel !== "middleschool" ? "flex-grow" : ""}`}>
            <div className={`w-${gradeLevel !== "elementary" && gradeLevel !== "middleschool" ? "36" : "0"} flex-none pt-5 flex justify-center hidden md:block`}>
              <HeaderLeft
                onProgramModeChange={(mode: string) => {
                  setProgramMode(mode);
                }}
              />
            </div>
            <div className="w-full flex-1 flex flex-col justify-between">
              <HeaderTop
                showParentConnectPopUp={(show) => null}
                onProgramModeChange={(mode: string) => {
                  setProgramMode(mode);
                }}
              />
              {children}
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setUserContext: (payload: userContextAction.UserContextState) => {
      dispatch(userContextAction.setUserContext(payload));
    },
    setProfile: (
      userId: number,
      roleId: number,
      schoolId: number,
      districtId: number
    ) => {
      dispatch(fetchProfile(userId, roleId, schoolId, districtId));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);

import { Fragment, useEffect, useState } from "react";
import Loader from "../../../shared/loader";
import { useTranslation } from "react-i18next";
import { UserContextState } from "../../../../redux/actions/userContextAction";
import { EyeIcon } from "@heroicons/react/outline";
import { getStudyPlanSummary } from "../../../../api/student/studyPlanApi";
import { IStudyPlanSummary } from "../../../../model/interface/studyPlanSummary";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import routeConstant from "../../../../utils/constant/routeConstant";
import Breadcrumb from "../middleSchool/breadcrumb";
import constant from "../../../../utils/constant/constant";
import moment from "moment";
import { paramDecode } from "../../../../utils/urlHelper";

const theme = {
  ELEMENTARY: {
    background: "",
    text: "text-gray-700",
    bodyText: "",
    tableRowHeader: "bg-gray-50 font-medium text-gray-500",
    tableRow: " text-gray-700",
    evenRow: "bg-white text-gray-700",
    oddRow: "bg-green-100 text-gray-700",
    tableHead:
      "px-3 py-3 w-60 text-left text-xs font-medium text-gray-500 capitalize tracking-wider bg-gray-100",
  },
  KINDERGARTEN: {
    background: "bg-purple-700",
    text: "text-white",
    bodyText: "text-site-teal",
    tableRowHeader: "bg-purple-300",
    tableRow: "bg-purple-400",
    evenRow: "bg-white",
    oddRow: "bg-gray-50",
    tableHead:
      "px-3 py-3 w-60 text-left text-xs font-medium text-gray-500 capitalize tracking-wider bg-gray-100",
  },
  MIDDLESCHOOL: {
    background: "bg-primary-violet",
    text: "text-white",
    bodyText: "",
    tableRowHeader: "bg-gray-50 font-medium text-gray-500",
    tableRow: "text-gray-700",
    evenRow: "bg-white",
    oddRow: "bg-gray-100",
    tableHead: "px-3 py-3 text-sm font-normal text-dark-teal uppercase",
  },
};

interface IStudyPlanSummaryProp {
  standardId?: number;
  userContext: UserContextState;
  match: any;
}
function StudyPlanSummary(props: IStudyPlanSummaryProp) {
  const themeName = getThemeName();
  const standardId = parseInt(atob(props.match.params.standardId));
  const standardName = paramDecode(props.match.params.standardName);
  const history = useHistory();
  const loading = false;
  const [summary, setSummary] = useState<Array<IStudyPlanSummary>>([]);
  const [totalQuestions, setTotalQuestions] = useState(0);
  const [totalCorrectQuestions, setTotalCorrectQuestions] = useState(0);
  const { t } = useTranslation();
  const location = useLocation();
  const parameters = location.state as any;
  const subjectName = parameters?.subjectName ?? "";
  const subjectId = parameters?.subjectId ?? 0;

  useEffect(() => {
    getSummary();
  }, []);

  function getSummary() {
    getStudyPlanSummary(props.userContext.userId, standardId).then((d) => {
      setSummary(d.data);
      var tempTotalQuestions = 0;
      var tempTotalCorrectQuestions = 0;
      d.data.forEach((t) => {
        tempTotalQuestions += t.totalQuestions;
        tempTotalCorrectQuestions += t.correctQuestions;
      });
      setTotalQuestions(tempTotalQuestions);
      setTotalCorrectQuestions(tempTotalCorrectQuestions);
    });
  }
  function getThemeName() {
    var url = window.location.href;
    if (url.indexOf("elementary") > 0) {
      return "ELEMENTARY";
    } else if (url.indexOf("kindergarten") > 0) {
      return "KINDERGARTEN";
    } else {
      return "MIDDLESCHOOL";
    }
  }
  function getPercentage(totalQuestions: number, correctQuestions: number) {
    return totalQuestions === 0
      ? 0
      : Math.round((correctQuestions * 100) / totalQuestions);
  }
  function showResult(session: IStudyPlanSummary) {
    const isVideo =
      (session?.activityName ?? "").indexOf("Video") !== -1 ? true : false;
    const isAssignment =
      (session?.activityName ?? "").indexOf("Assignment") !== -1 ? true : false;

    switch (getThemeName()) {
      case "MIDDLESCHOOL":
        if (isVideo) {
          history.push({
            pathname: routeConstant.MiddleSchool.VideoResult.replace(
              ":sessionId",
              session.sessionId
            ),
            state: { videoTitle: session?.activityName },
          });
        } else if (isAssignment) {
          history.push(
            routeConstant.MiddleSchool.AssignmentResult.replace(
              ":assignmentId",
              (session.assignmentId ?? "").toString()
            ).replace(
              ":assignmentActivityId",
              (session.assignmentActivityId ?? "").toString()
            )
          );
        } else {
          history.push(
            routeConstant.MiddleSchool.StudyPlanResult.replace(
              ":sessionId",
              session.sessionId
            )
          );
        }
        break;
      case "KINDERGARTEN":
        if (isVideo) {
          history.push(
            routeConstant.KindergartenStudentRoutes.VideoResult.replace(
              ":sessionId",
              session.sessionId
            )
          );
        } else if (isAssignment) {
          history.push(
            routeConstant.KindergartenStudentRoutes.AssignmentResult.replace(
              ":assignmentId",
              (session.assignmentId ?? "").toString()
            ).replace(
              ":assignmentActivityId",
              (session.assignmentActivityId ?? "").toString()
            )
          );
        } else {
          history.push(
            routeConstant.KindergartenStudentRoutes.StudyPlanResult.replace(
              ":sessionId",
              session.sessionId
            )
          );
        }
        break;
      case "ELEMENTARY":
        if (isVideo) {
          history.push(
            routeConstant.StudentRoute.VideoResult.replace(
              ":sessionId",
              session.sessionId
            )
          );
        } else if (isAssignment) {
          history.push(
            routeConstant.StudentRoute.AssignmentResult.replace(
              ":assignmentId",
              (session.assignmentId ?? "").toString()
            ).replace(
              ":assignmentActivityId",
              (session.assignmentActivityId ?? "").toString()
            )
          );
        } else {
          history.push(
            routeConstant.StudentRoute.StudyPlanResult.replace(
              ":sessionId",
              session.sessionId
            )
          );
        }
        break;
    }
  }
  const breadcrumbItems = () => {
    switch (getThemeName()) {
      case "MIDDLESCHOOL":
        return [
          {
            name: t("Breadcrumb.Home"),
            url: routeConstant.MiddleSchool.assignments,
            textColor: "text-white",
          },
          {
            name: t("Breadcrumb.StudyPlanSubjects"),
            textColor: "text-white",
            url: routeConstant.MiddleSchool.subjects,
          },
          {
            name: subjectName,
            textColor: "text-white",
            url: routeConstant.MiddleSchool.standards.replace(":id", subjectId),
            stateObject: subjectName,
          },
          {
            name: t("Breadcrumb.StandardsSummary"),
            textColor: "text-white",
            url: "",
          },
        ];
      case "KINDERGARTEN":
        return [
          {
            name: t("Breadcrumb.Home"),
            url: routeConstant.KindergartenStudentRoutes.Home,
            textColor: "text-gray-500",
          },
          {
            name: t("Breadcrumb.StandardsSummary"),
            textColor: "text-gray-500",
            url: "",
          },
        ];
      case "ELEMENTARY":
        return [
          {
            name: t("Breadcrumb.Home"),
            url: routeConstant.StudentRoute.Home,
            textColor: "text-gray-500",
          },
          {
            name: subjectName,
            textColor: "text-gray-500",
            url: `${
              routeConstant.StudentRoute.Home
            }?tab=${constant.StudyType.MYSTUDYPLAN.toLowerCase()}&subjectId=${subjectId}&subjectName=${subjectName}`,
          },
          {
            name: t("Breadcrumb.StandardsSummary"),
            textColor: "text-gray-500",
            url: "",
          },
        ];
    }
  };
  return (
    <Fragment>
      {loading && <Loader />}

      <div className={`${theme[themeName]?.background}  w-full min-w-0`}>
        <div className="px-4 py-3 lg:px-8 border-b">
          <Breadcrumb items={breadcrumbItems()} />
          <div className="text-lg flex justify-between gap-2 h-auto sm:h-10 items-center">
            <h1
              className={`${theme[themeName]?.text}  flex justify-start font-medium`}
            >
              {t("StudyPlan.StandardSummary")}
            </h1>
          </div>
        </div>
        <div className="px-4 py-4 lg:px-8">
          <div className="mt-2">
            <span
              className={`${theme[themeName]?.text}  text-lg text-white font-semibold`}
            >
              {summary.length > 0 ? "(" + summary[0].standardCode + ")" : ""}{" "}
              {standardName}
            </span>
          </div>
          <div className="overflow-x-auto rounded-lg w-full mt-3 shadow-lg ">
            <table
              className={`table w-full divide-y divide-gray-200 ${theme[themeName]?.background}`}
            >
              <thead>
                <tr
                  className={`${theme[themeName]?.tableRowHeader} ${theme[themeName]?.text} font-weight: 600 `}
                >
                  <th
                    className={`${theme[themeName]?.tableHead} ${theme[themeName]?.text} text-left align-top  `}
                  >
                    {t("StudyPlanSummary.Activities")}
                    <div> </div>
                  </th>
                  <th
                    className={`${theme[themeName]?.tableHead} ${theme[themeName]?.text} align-top `}
                  >
                    <div className="flex flex-col justify-center">
                      <div className="text-center">
                        {t("StudyPlanSummary.PracticeTotalScore")}
                      </div>
                      <div className="font-bold text-gray-700 text-center mt-1.5">
                        {getPercentage(totalQuestions, totalCorrectQuestions)}%
                      </div>
                    </div>
                  </th>
                  <th
                    className={`${theme[themeName]?.tableHead} ${theme[themeName]?.text} align-top `}
                  >
                    <div className="flex flex-col justify-center">
                      <div className="text-center">
                        {t("StudyPlanSummary.TotalQuestionsAnswered")}
                      </div>
                      <div className="font-bold text-gray-700 text-center mt-1.5">
                        {totalQuestions}
                      </div>
                    </div>
                  </th>
                  <th
                    className={`${theme[themeName]?.tableHead} ${theme[themeName]?.text} align-top `}
                  >
                    <div className="flex flex-col justify-center">
                      <div className="text-center">
                        {t("StudyPlanSummary.TotalQuestionsCorrect")}
                      </div>
                      <div className="font-bold text-gray-700 text-center mt-1.5">
                        {totalCorrectQuestions}
                      </div>
                    </div>
                  </th>
                  <th
                    className={`${theme[themeName]?.tableHead} ${theme[themeName]?.text} align-top `}
                  >
                    <div className="flex space-x-2 justify-center">
                      {t("StudyPlanSummary.DateCompleted")}
                    </div>
                  </th>
                  <th
                    className={`${theme[themeName]?.tableHead} ${theme[themeName]?.text} align-top `}
                  >
                    <div className="flex justify-center">
                      {t("StudyPlanSummary.Result")}
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {summary &&
                  summary?.length > 0 &&
                  summary?.map((summary, index) => {
                    return (
                      <Fragment key={index}>
                        <tr
                          className={` ${theme[themeName]?.tableRow} ${
                            index % 2 === 0
                              ? theme[themeName]?.evenRow
                              : theme[themeName]?.oddRow
                          }`}
                        >
                          <td className="text-sm p-3 ">
                            {summary.activityName ??
                              t("StudyPlanSummary.Practice")}
                          </td>

                          <td className="text-center text-sm p-3 ">
                            {getPercentage(
                              summary.totalQuestions,
                              summary.correctQuestions
                            )}
                            %
                          </td>
                          <td className="text-center text-sm p-3 ">
                            {summary.totalQuestions}
                          </td>
                          <td className="text-center text-sm p-3 ">
                            {summary.correctQuestions}
                          </td>
                          <td className="text-center text-sm p-3 ">
                            {moment(summary.dateCompleted).format("MM/DD/YYYY")}
                          </td>
                          <td className="text-center text-sm p-3 ">
                            <span title={t("StudyPlanSummary.ShowResult")}>
                              <EyeIcon
                                className="w-4 mx-auto text-gray-500 hover:text-gray-700 cursor-pointer"
                                onClick={() => {
                                  showResult(summary);
                                }}
                              />
                            </span>
                          </td>
                        </tr>
                      </Fragment>
                    );
                  })}
                {summary && summary.length === 0 && (
                  <tr
                    className={` ${theme[themeName]?.text} ${theme[themeName]?.evenRow}`}
                  >
                    <td
                      colSpan={6}
                      className="text-left text-gray-700 text-sm p-3 "
                    >
                      {t("StudyPlanSummary.NoActivityFound")}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(StudyPlanSummary);

function Checkbox(passedProps: any) {
  const props: any = passedProps;

  return (
    <div className="checkbox mr-2">
      <label>
        <input
          className="mr-1"
          type="checkbox"
          value={props.label}
          checked={props.isChecked}
          onChange={props.handleCheckboxChange}
        />

        {props.label}
      </label>
    </div>
  );
}

export default Checkbox;

import { Fragment, useState } from "react";
import { connect } from "react-redux";
import { getNameLabel } from "../../../../utils/helper";
import IStudentSetting, {
  IUpdateTTSStudentSetting,
} from "../../../../model/teacher/studentSetting";
import Toggle from "../../../shared/toggle";
import { UserContextState } from "../../../../redux/actions/userContextAction";
import { toast } from "react-toastify";
import Loader from "../../../shared/loader";
import {
  updateStudentTTSSetting,
  updateStudentTTSSettingByClassId,
} from "../../../../api/teacher/studentSetting";
import ApplyAllSettingContextMenu from "../shared/applyAllSettingContextMenu";
import ConfirmationDialog from "../../../shared/confirmationDialog";

interface TTSSettingProps {
  UserContext: UserContextState | undefined;
  ContentAreas: any;
  Students: Array<IStudentSetting> | undefined;
  ClassId: number;
  GetSettings: Function;
}

const TextToSpeechSetting = (props: TTSSettingProps) => {
  const contentAreas = props.ContentAreas;
  const students: Array<IStudentSetting> | undefined = props.Students;
  const [loading, showLoading] = useState(false);
  const classId = props.ClassId;
  const [hoverElement, setHoverElement] = useState<string>("");
  const [updateUserSettingValue, setUpdateUserSetting] =
    useState<IUpdateTTSStudentSetting>();
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [confirmationTitle, setConfirmationTitle] = useState("Confirm Action");
  const [confirmationMessage, setConfirmationMessage] =
    useState("Please confirm");

  function updateUserTTSSetting(
    studentProfileId: number,
    contentAreaId: number,
    isTTSActive: boolean
  ): void {
    showLoading(true);
    updateStudentTTSSetting(
      isTTSActive,
      contentAreaId,
      [studentProfileId],
      classId
    )
      .then((d) => {
        showLoading(false);
        props.GetSettings();
        if (d.data.isSuccess === true)
          toast.success("Student setting's updated successfully");
        else {
          toast.warning("Student setting's cannot be updated.");
        }
      })
      .catch(() => {
        showLoading(false);
      });
  }

  function applyAll(
    contentAreaId: string,
    studentProfileId: number,
    value: string
  ): void {
    updateAllUserSetting(parseInt(contentAreaId), value, []);
  }

  function updateAllUserSetting(
    contentAreaId: number,
    value: string,
    studentProfileIds: Array<number>
  ): void {
    const updateUserInfo: IUpdateTTSStudentSetting = {
      classId: props.ClassId,
      studentProfileIds: studentProfileIds,
      contentAreaId: contentAreaId,
      value: value === "true" ? true : false,
      setting: "TTS",
    };
    setUpdateUserSetting(updateUserInfo);
    showConfirmation(
      "Please confirm",
      "Are you sure you want to update the user settings?"
    );
  }

  function showConfirmation(title: string, message: string) {
    setOpenConfirmation(true);
    setConfirmationTitle(title);
    setConfirmationMessage(message);
  }

  function confirmationOkClose() {
    updateAllUserSettingConfirm();
  }
  function confirmationCancelClose() {
    setOpenConfirmation(false);
  }

  function updateAllUserSettingConfirm() {
    if (updateUserSettingValue) {
      showLoading(true);
      updateStudentTTSSettingByClassId(
        updateUserSettingValue.value,
        updateUserSettingValue.contentAreaId,
        updateUserSettingValue.classId
      ).then(() => {
        showLoading(false);
        confirmationCancelClose();
        props.GetSettings();
        toast.success("Student setting's updated successfully");
      });
    }
  }

  function getStudentSetting(
    student: IStudentSetting | undefined,
    contentAreaId: number
  ) {
    return (
      !!student?.settings?.find((f) => f.contentAreaId === contentAreaId)
        ?.isTTSActive || false
    );
  }

  return (
    <Fragment>
      <div className="flex flex-col relative">
        {loading && <Loader />}
        <div className="shadow overflow-x-auto">
          <table className="table w-full">
            <thead className="mb-5">
              <tr className="text-white py-3 px-3 overflow-hidden border-[3px] border-primary-violet">
                <th className="text-left  px-3 pb-1.5 pt-1.5 text-sm font-normal align-bottom text-primary-violet">
                  <div className="flex w-auto">Name</div>
                </th>
                <th className="text-left  px-3 pb-1.5 pt-1.5 text-sm font-normal align-bottom text-primary-violet">
                  <div className="flex w-auto">Student ID</div>
                </th>
                <th
                  colSpan={4}
                  className="text-center text-sm font-normal text-primary-violet"
                >
                  <div className="bg-gray-100 py-1.5 text-gray-600">
                    Text to Speech
                  </div>
                  <table className="w-full">
                    <tr>
                      {contentAreas?.map((contentArea) => {
                        return (
                          <td className="py-1.5 w-16">{contentArea.name}</td>
                        );
                      })}
                    </tr>
                  </table>
                </th>
              </tr>
            </thead>
            <tbody>
              {students?.map((student, index) => {
                return (
                  <tr
                    key={student.studentProfileId}
                    className={
                      index % 2 === 0
                        ? " hover:bg-light-violet/20 transition ease-in-out duration-300 bg-white"
                        : "hover:bg-light-violet/20 transition ease-in-out duration-300 bg-light-violet/10"
                    }
                  >
                    <td className="text-sm p-3 text-left text-gray-700">
                      {getNameLabel(student.lastName, student.firstName)}
                    </td>
                    <td className="text-sm p-3 text-left text-gray-700">
                      {student.studentId}
                    </td>
                    {contentAreas?.map((contentArea) => {
                      return (
                        <td
                          className="text-sm p-3 text-left text-gray-700"
                          onMouseOver={() => {
                            setHoverElement(
                              `isTTSActive-${contentArea.contentAreaId}-${student.studentProfileId}`
                            );
                          }}
                        >
                          <div className="w-full flex justify-center items-center text-gray-700 gap-x-1">
                            Off
                            <Toggle
                              disabled={false}
                              checked={
                                student.settings?.find(
                                  (f) =>
                                    f.contentAreaId ===
                                    contentArea.contentAreaId
                                )?.isTTSActive ?? false
                              }
                              onChange={(e) => {
                                updateUserTTSSetting(
                                  student.studentProfileId,
                                  contentArea.contentAreaId,
                                  !student.settings?.find(
                                    (f) =>
                                      f.contentAreaId ===
                                      contentArea.contentAreaId
                                  )?.isTTSActive
                                );
                              }}
                            />
                            On
                            <div className="w-6">
                              {hoverElement ===
                                `isTTSActive-${contentArea.contentAreaId}-${student.studentProfileId}` && (
                                <ApplyAllSettingContextMenu
                                  isFirst={undefined}
                                  isLast={undefined}
                                  applyAll={applyAll}
                                  setting={contentArea.contentAreaId}
                                  userId={student.studentProfileId}
                                  value={getStudentSetting(
                                    student,
                                    contentArea.contentAreaId
                                  ).toString()}
                                />
                              )}
                            </div>
                          </div>
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <ConfirmationDialog
        open={openConfirmation}
        confirmationTitle={confirmationTitle}
        confirmationMessage={confirmationMessage}
        onCancelClick={confirmationCancelClose}
        onOkClick={confirmationOkClose}
      ></ConfirmationDialog>
    </Fragment>
  );
};
const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(TextToSpeechSetting);

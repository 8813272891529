// Initial plan here is for this data to be static for simplicity. If later we find we need to change this often
// we can easily have this value returned from the api which can use database tables with a caching solution.
// This was coded such that it should be simple to change this method to call the API and return the same structure as above.
// Also, all resources are referencing the original resource on the marketing wordpress site.

export interface IStudentActivityResourceGroup {
    name: string,
    iconFile: string,
    resources: Array<IStudentActivityResource>
}
export interface IStudentActivityResource {
    name: string,
    iconFile: string,
    url: string
}

const studentActivityResources: Array<IStudentActivityResourceGroup> = [
    {
        name: "Get to Know You",
        iconFile: "get-to-know-icon.png",
        resources: [
            {
                name: "All About Me Student Activity (K-5)",
                iconFile: "1.png",
                url: "https://docs.progresslearning.com/hubfs/Instructional%20Resources/Teacher%20Resources/PL%20-%20All%20About%20Me.pdf"
            },
            {
                name: "Student Time Capsule Activity (All Levels)",
                iconFile: "2.png",
                url: "https://docs.progresslearning.com/hubfs/Instructional%20Resources/Teacher%20Resources/PL%20-%20All%20About%20Me%20Time%20Capsule.pdf"
            },
            {
                name: "ECE Student Scavenger Hunt",
                iconFile: "3.png",
                url: "https://docs.progresslearning.com/hubfs/Instructional%20Resources/Teacher%20Resources/_ECE%20Student%20Scavenger%20Hunt%20.pdf"
            },
            {
                name: "2-5 Student Scavenger Hunt",
                iconFile: "4.png",
                url: "https://docs.progresslearning.com/hubfs/Instructional%20Resources/Teacher%20Resources/Elementary%20(2nd-5th)%20Student%20Scavenger%20Hunt%20.pdf"
            },
            {
                name: "Liftoff Student Scavenger Hunt",
                iconFile: "5.png",
                url: "https://docs.progresslearning.com/hubfs/Instructional%20Resources/Teacher%20Resources/Liftoff%20Student%20Scavenger%20Hunt%20.pdf"
            }
        ]
    },
    {
        name: "Getting Started with Progress Learning",
        iconFile: "getting-started-icon.png",
        resources: [
            {
                name: "Stamina Chart (1 page)",
                iconFile: "6.png",
                url: "https://docs.progresslearning.com/hubfs/Instructional%20Resources/Teacher%20Resources/PL%20-%20Stamina%20Chart.pdf"
            },
            {
                name: "2023 Fall Implementation Guide",
                iconFile: "7.png",
                url: "https://docs.progresslearning.com/hubfs/CSM%20and%20Training%20Teams/Guides/_2023-2024%20Fall%20Guide.pdf"
            },
            {
                name: "ECE Quick Start Guide",
                iconFile: "8.png",
                url: "https://docs.progresslearning.com/hubfs/CSM%20and%20Training%20Teams/Guides/ECE%20Quick%20Start%20Guide.pdf"
            },
            {
                name: "Elementary Quick Start Guide",
                iconFile: "9.png",
                url: "https://docs.progresslearning.com/hubfs/CSM%20and%20Training%20Teams/Guides/Elementary%20Quick%20Start%20Guide.pdf"
            },
            {
                name: "Liftoff Quick Start Guide",
                iconFile: "10.png",
                url: "https://docs.progresslearning.com/hubfs/CSM%20and%20Training%20Teams/Guides/Liftoff%20Student%20Quick%20Start%20Guide.pdf"
            },
            {
                name: "Secondary Quick Start Guide",
                iconFile: "11.png",
                url: "https://docs.progresslearning.com/hubfs/CSM%20and%20Training%20Teams/Guides/Secondary%20Quick%20Start%20Guide.pdf"
            },
            {
                name: "Teacher Quick Start Guide",
                iconFile: "12.png",
                url: "https://docs.progresslearning.com/hubfs/CSM%20and%20Training%20Teams/Guides/Teacher%20Quick%20Start%20Guide.pdf"
            },
            {
                name: "Admin Quick Start Guide",
                iconFile: "13.png",
                url: "https://docs.progresslearning.com/hubfs/CSM%20and%20Training%20Teams/Guides/Admin%20Quick%20Start%20Guide.pdf"
            },
            {
                name: "District Admin Quick Start Guide",
                iconFile: "14.png",
                url: "https://docs.progresslearning.com/hubfs/CSM%20and%20Training%20Teams/Guides/PL%20-%20District%20Admin%20Quick%20Start%20Guide.pdf"
            },
            {
                name: "Implementing PL First Days of School Day-by-Day Guide",
                iconFile: "15.png",
                url: "https://docs.progresslearning.com/hubfs/CSM%20and%20Training%20Teams/Guides/Implementing%20PL%20First%20Days%20of%20School%20Day-by-Day%20Guide.pdf"
            },
            {
                name: "Training Catalog",
                iconFile: "16.png",
                url: "https://docs.progresslearning.com/hubfs/CSM%20and%20Training%20Teams/Training%20Team%20Info/PL%20-%20Training%20Catalog.pdf"
            }
        ]
    },
    {
        name: "Instructional Resources",
        iconFile: "instructional-resources-icon.png",
        resources: [
            {
                name: "Sentence Stems",
                iconFile: "17.png",
                url: "https://docs.progresslearning.com/hubfs/Instructional%20Resources/Teacher%20Resources/PL%20-%20Progress%20Learning%20Sentence%20Stems.pdf"
            },
            {
                name: "Math Sentence Stems",
                iconFile: "18.png",
                url: "https://docs.progresslearning.com/hubfs/Instructional%20Resources/Teacher%20Resources/PL%20-%20Progress%20Learning%20Sentence%20Stems%20MATH.pdf"
            },
            {
                name: "Science Sentence Stems",
                iconFile: "19.png",
                url: "https://docs.progresslearning.com/hubfs/Instructional%20Resources/Teacher%20Resources/PL%20-%20Progress%20Learning%20Sentence%20Stems%20SCIENCE.pdf"
            },
            {
                name: "Graphic Organizers",
                iconFile: "20.png",
                url: "https://docs.progresslearning.com/hubfs/Instructional%20Resources/Teacher%20Resources/PL%20-%20Graphic%20Organizers.pdf"
            },
            {
                name: "March Madness Student Activity",
                iconFile: "march-madness.png",
                url: "https://docs.progresslearning.com/hubfs/Instructional%20Resources/Seasonal/March%20Madness%20Student%20Activity.pdf"
            },
            {
                name: "Daily Lesson Plans with Progress Learning Template",
                iconFile: "21.png",
                url: "https://docs.progresslearning.com/hubfs/Instructional%20Resources/Teacher%20Resources/PL%20-%20Daily%20Lesson%20Plans%20with%20Progress%20Learning.pdf"
            },
            {
                name: "Targeted Tutoring with Progress Learning",
                iconFile: "22.png",
                url: "https://docs.progresslearning.com/hubfs/Instructional%20Resources/Teacher%20Resources/PL%20-%20Targeted%20Tutoring%20with%20Progress%20Learning.pdf"
            },
            {
                name: "Implementing Targeted PD",
                iconFile: "23.png",
                url: "https://docs.progresslearning.com/hubfs/Instructional%20Resources/Teacher%20Resources/PL%20-%20Implementing%20a%20Targeted%20Professional%20Development%20Data%20Day%20with%20Progress%20Learning.pdf"
            },
            {
                name: "K-1 Student Dashboard Anchor Charts",
                iconFile: "24.png",
                url: "https://docs.progresslearning.com/hubfs/Instructional%20Resources/PL%20Tools/Guides/K-1%20Anchor%20Charts.pdf"
            },
            {
                name: "Labor Day Thank You Student Activity",
                iconFile: "25.png",
                url: "https://docs.progresslearning.com/hubfs/Instructional%20Resources/Teacher%20Resources/PL%20-%20Student%20Community%20Thank%20You%2c%20Card%20Activity.pdf"
            },
            {
                name: "Thanksgiving Thankful Notes",
                iconFile: "thanksgiving.png",
                url: "https://docs.progresslearning.com/hubfs/Instructional%20Resources/Teacher%20Resources/PL%20-%20Thanksgiving%20Activity.pdf"
            },
            {
                name: "Earth Day Activities",
                iconFile: "26.png",
                url: "https://docs.progresslearning.com/hubfs/Instructional%20Resources/Seasonal/Earth%20Day%20Activities.pdf"
            },
            {
                name: "Close Reading Resources",
                iconFile: "27.png",
                url: "https://docs.progresslearning.com/hubfs/Instructional%20Resources/Teacher%20Resources/PL%20-%20Close%20Reading.pdf"
            }
        ]
    },
    {
        name: "Goal Setting Resources/Intervention",
        iconFile: "goal-setting-icon.png",
        resources: [
            {
                name: "Student Trackers Template",
                iconFile: "28.png",
                url: "https://docs.google.com/document/d/1OAVWSi55iaJW4EIGkNqOujlEaYhBhAx1WBNnVcYyQEI/edit?usp=sharing"
            },
            {
                name: "Mastery Punch Cards",
                iconFile: "29.png",
                url: "https://docs.progresslearning.com/hubfs/Instructional%20Resources/Student%20Rewards/Mastery%20Punch%20Cards.pdf"
            },
            {
                name: "Student Encouragement Cards",
                iconFile: "30.png",
                url: "https://docs.progresslearning.com/hubfs/Instructional%20Resources/Student%20Rewards/Student%20Encouragement%20Cards.pdf"
            },
            {
                name: "K-1 Intervention Choice Board",
                iconFile: "31.png",
                url: "https://docs.progresslearning.com/hubfs/Instructional%20Resources/PL%20Tools/Choice%20Boards/K-1%20Intervention%20Choice%20Board.pdf"
            },
            {
                name: "2nd-8th Intervention Choice Board",
                iconFile: "32.png",
                url: "https://docs.progresslearning.com/hubfs/Instructional%20Resources/PL%20Tools/Choice%20Boards/2-5%20Station%20Must%20Do%20May%20Do%20Choice%20Board%20Google%20Slides.pptx"
            },
            {
                name: "Middle School Choice Board",
                iconFile: "32.png",
                url: "https://docs.progresslearning.com/hubfs/Instructional%20Resources/Teacher%20Resources/PL%20-%20Middle%20School%20Choice%20Board%20for%20Intervention%20(6th-8th%20Grade).pdf"
            },
            {
                name: "Goal Setting Templates",
                iconFile: "Goal-Setting-scaled.jpg",
                url: "https://docs.progresslearning.com/hubfs/Instructional%20Resources/Goal%20Setting/English%20Student%20Goal-Setting%20Templates.pdf"
            },
            {
                name: "6-12 Student Data Goal Sheet",
                iconFile: "6-12-student-data.png",
                url: "https://docs.progresslearning.com/hubfs/Instructional%20Resources/Goal%20Setting/6-12%20Student%20Data%20Goal%20Sheet.pdf"
            },
            {
                name: "Fall Goal Setting",
                iconFile: "20.png",
                url: "https://docs.progresslearning.com/hubfs/Instructional%20Resources/Teacher%20Resources/PL%20-%20Growing%20with%20Goals%20this%20Fall.pdf"
            },
            {
                name: "Holiday Wishlist",
                iconFile: "33.png",
                url: "https://docs.progresslearning.com/hubfs/Instructional%20Resources/Seasonal/Student%20Holiday%20Wishlist.pdf"
            },
            {
                name: "Valentine Buddy Peer Review Activity",
                iconFile: "34.png",
                url: "https://docs.progresslearning.com/hubfs/Instructional%20Resources/Seasonal/Valentine%20Buddy%20Activity.pdf"
            },
            {
                name: "Spring Goal Setting",
                iconFile: "\t35.png",
                url: "https://docs.progresslearning.com/hubfs/Instructional%20Resources/Seasonal/Easter%20-%20Spring%20Student%20Goal%20Setting.pdf"
            },
            {
                name: "Graphic Organizers",
                iconFile: "graphic-organizer.png",
                url: "https://docs.progresslearning.com/hubfs/Instructional%20Resources/Teacher%20Resources/PL%20-%20Graphic%20Organizers.pdf"
            },
            {
                name: "My 2024 Word of the Year",
                iconFile: "36.png",
                url: "https://docs.progresslearning.com/hubfs/Instructional%20Resources/Teacher%20Resources/PL%20-%20Goal%20Setting%20in%202024.pdf"
            }
        ]
    },
    {
        name: "Student Engagement Resources",
        iconFile: "student-engagement-icon-1.png",
        resources: [
            {
                name: "Space Cadet Alien Ranking Cards-English and Spanish",
                iconFile: "37.png",
                url: "https://docs.progresslearning.com/hubfs/Instructional%20Resources/Student%20Rewards/Space%20Cadet%20Alien%20Ranking%20Cards/Space%20Cadet%20Alien%20Ranking%20Cards-English%20and%20Spanish.pdf"
            },
            {
                name: "Space Cadet Alien Ranking Prestige Cards",
                iconFile: "38.png",
                url: "https://docs.progresslearning.com/hubfs/Instructional%20Resources/Student%20Rewards/Space%20Cadet%20Alien%20Ranking%20Cards/Space%20Cadet%20Prestige%20Cards.pdf"
            },
            {
                name: "Space Cadet Alien Ranking Certificates",
                iconFile: "39.png",
                url: "https://docs.progresslearning.com/hubfs/Instructional%20Resources/Student%20Rewards/Space%20Cadet%20Alien%20Ranking%20Cards/Space%20Cadet%20Alien%20Ranking%20Certificates.pdf"
            },
            {
                name: "Space Cadet Alien Ranking Coloring Sheets",
                iconFile: "40.png",
                url: "https://docs.progresslearning.com/hubfs/Instructional%20Resources/Student%20Rewards/Space%20Cadet%20Alien%20Ranking%20Cards/Space%20Cadet%20Alien%20Ranking%20Coloring%20Sheets.pdf"
            },
            {
                name: "Digital Space Cadet Alien Ranking Cards",
                iconFile: "41.png",
                url: "https://docs.progresslearning.com/hubfs/Instructional%20Resources/Student%20Rewards/Space%20Cadet%20Alien%20Ranking%20Cards/Digital%20Alien%20Ranking%20Cards%20Space%20Cadet.pdf"
            }
        ]
    }
]

export const getStudentActivityResources = (): Array<IStudentActivityResourceGroup> => {
    return studentActivityResources;
};

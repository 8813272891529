import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { getStudentActivities } from "../../../../../api/student/studentActivityApi";
import { LockIcon, RightArrowIcon } from "../../../../../assets/icons";
import AssignmentAssessmentIcon from "../../../../../assets/icons/student/middleschool/assignments/AssignmentAssessmentIcon";
import AssignmentGroupedAssignmentExpandedIcon from "../../../../../assets/icons/student/middleschool/assignments/AssignmentGroupedAssignmentExpandedIcon";
import AssignmentPerformanceTaskIcon from "../../../../../assets/icons/student/middleschool/assignments/AssignmentPerformanceTaskIcon";
import AssignmentQuestionsIcon from "../../../../../assets/icons/student/middleschool/assignments/AssignmentQuestionsIcon";
import AssignmentsGroupedAssignmentCollapsedIcon from "../../../../../assets/icons/student/middleschool/assignments/AssignmentsGroupedAssignmentCollapsedIcon";
import AssignmentVideoIcon from "../../../../../assets/icons/student/middleschool/assignments/AssignmentVideoIcon";
import ConstructedResponseIcon from "../../../../../assets/icons/student/middleschool/assignments/ConstructedResponseIcon";
import PreBuildIcon from "../../../../../assets/icons/student/middleschool/assignments/PreBuildIcon";
import ActivityListResponse from "../../../../../model/student/shared/assignment/activityListResponse";
import AssignmentListResponse from "../../../../../model/student/shared/assignment/assignmentListResponse";
import { getActivityName } from "../../../../../utils/assignmentHelper";
import Constant from "../../../../../utils/constant/constant";
import Loader from "../../../../shared/loader";
import RouteConstant from "../../../../../utils/constant/routeConstant";
import { useHistory } from "react-router-dom";
import Profile from "../../../../../model/users/profile";
import { UserContextState } from "../../../../../redux/actions/userContextAction";
import { paramEncode } from "../../../../../utils/urlHelper";
import { sanitizeNoteContent } from "../../../../../utils/sanitize";
interface listProps {
  assignmentData: AssignmentListResponse[];
  userId: number;
  userContext?: UserContextState;
  profile?: Profile;
}
function AssignmentList(props: listProps) {
  const { t } = useTranslation();
  const history = useHistory();
  const [notesFromTeacher, setNotesFromTeacher] = useState<string | null>(null);
  const [expandedEvent, setExpandedEvent] = useState<any>({
    index: [],
  });
  const [activities, setActivities] = useState<Array<ActivityListResponse>>([]);
  const { assignmentData } = props;
  const [loading, showLoading] = useState<boolean>(false);

  useEffect(() => {
    const innerUl = document.getElementById("ul1");
    if (innerUl) innerUl.classList.add("anim-0");
  }, [assignmentData]);

  const onGroupIconChange: any = (
    index,
    assignment: AssignmentListResponse
  ) => {
    if (!expandedEvent.index.includes(index)) {
      const indexArr = [...expandedEvent.index, index];
      setExpandedEvent({
        index: indexArr,
      });
      //  setExpandedAssignment(assignment);
      getActivities(assignment.assignmentId).then((assignmentActivities) => {
        const groupedActivities =
          assignmentActivities as Array<ActivityListResponse>;
        setActivities(groupedActivities);
      });
    } else {
      const indexArr = expandedEvent.index.filter(function (item) {
        return item !== index;
      });
      setExpandedEvent({ index: indexArr });
      //   setExpandedAssignment(undefined);
      setActivities([]);
    }
  };

  async function getActivities(assignmentId) {
    showLoading(true);
    return await getStudentActivities(props.userId, assignmentId)
      .then((r) => {
        if (r.data.length > 0) {
          const merged = activities.concat([], r.data);
          let completeInOrder = merged[0].completeInOrder;
          let isContinueAssignment = false;
          if (completeInOrder) {
            let isEnable = false;
            merged.forEach((activity) => {
              let status = activity.assignmentStatus;
              if (
                status?.toUpperCase() === Constant.AssignmentStatus.SAVE &&
                activity.totalAttempt === 1 &&
                !isContinueAssignment
              )
                isContinueAssignment = true;

              if (
                status?.toUpperCase() === Constant.AssignmentStatus.NEW &&
                !isContinueAssignment &&
                !isEnable
              ) {
                activity.isEnable = true;
                isEnable = true;
              } else if (
                status?.toUpperCase() === Constant.AssignmentStatus.NEW &&
                (isContinueAssignment || isEnable)
              ) {
                activity.isEnable = false;
              } else {
                activity.isEnable = true;
              }
            });
          } else {
            merged.forEach((activity) => {
              activity.isEnable = true;
            });
          }
          return merged;
        }
      })
      .catch((_) => {
        return [];
      })
      .finally(() => showLoading(false));
  }

  function goToAssignment(
    subjectId: number,
    assignmentActivityId: number,
    assignmentActivityName: string,
    multipleAttemptAllowed: boolean,
    assignmentId: number,
    lernosityActivityIdEN?: string,
    lernosityActivityIdES?: string,
    assignmentType?: string,
    assignment?: ActivityListResponse
  ) {
    assignmentType = assignmentType ? assignmentType : "";
    if (lernosityActivityIdEN || lernosityActivityIdES) {
      const assignmentUrl =
        RouteConstant.KindergartenStudentRoutes.StudentAssignmentActivity.replace(
          ":assignmentActivityId",
          encodeURI(btoa(assignmentActivityId.toString()))
        )
          .replace(
            ":assignmentActivityName",
            paramEncode(assignmentActivityName)
          )
          .replace(
            ":lernosityActivityIdEN",
            encodeURI(btoa(lernosityActivityIdEN ? lernosityActivityIdEN : ""))
          )
          .replace(
            ":lernosityActivityIdES",
            encodeURI(
              btoa(
                lernosityActivityIdES
                  ? lernosityActivityIdES
                  : lernosityActivityIdEN
                  ? lernosityActivityIdEN
                  : ""
              )
            )
          )
          .replace(":assignmentType", encodeURI(btoa(assignmentType)))
          .replace(":assignmentId", encodeURI(btoa(assignmentId.toString())))
          .replace(":subjectId", encodeURI(btoa(subjectId.toString())))
          .replace(
            ":multipleAttemptAllowed",
            encodeURI(btoa(multipleAttemptAllowed.toString()))
          );

      const prebuildTestType =
        (assignment?.summativeTestId ?? 0) > 0
          ? (assignment?.summativeTestName ?? "") + " Practice Test"
          : (assignment?.domainId ?? 0) > 0
          ? "Practice Test - " + assignment?.domainName ?? ""
          : "";

      if (
        assignmentType === Constant.AssignmentPart.PRACTICEQUESTIONS &&
        assignment?.assignmentStatusId !==
          Constant.AssignmentStatusId.INPROGRESS
      ) {
        var route = RouteConstant.KindergartenStudentRoutes.GameChoose.replace(
          ":standardId",
          encodeURI(btoa(String(assignment?.standardId!)))
        )
          .replace(
            ":standardName",
            encodeURI(paramEncode(assignment?.standardName))
          )
          .replace(":subjectId", encodeURI(btoa(subjectId.toString())))
          .replace(
            ":mode",
            encodeURI(btoa(Constant.GameChooseMode.KinderGartenPracticeTest))
          );
        history.push({
          pathname: route,
          state: {
            assignmentUrl: assignmentUrl,
            prebuildTestType: prebuildTestType,
          },
        });
      } else {
        history.push({
          pathname: assignmentUrl,
          state: {
            prebuildTestType: prebuildTestType,
          },
        });
      }
    }
  }

  function startAssignmentActivity(
    subjectId: number,
    assignmentActivityId: number,
    assignmentActivityName: string,
    multipleAttempt: number,
    assignmentId: number,
    lernosityActivityIdEN?: string,
    lernosityActivityIdES?: string,
    assignmentType?: string,
    currentActivity?: ActivityListResponse
  ) {
    let multipleAttemptAllowed = multipleAttempt > 0 ? true : false;
    const assignment = currentActivity
      ? currentActivity
      : activities.filter(
          (a) => a.assignmentActivityId === assignmentActivityId
        )[0];

    if (!assignment) {
      getActivities(assignmentId).then((activities) => {
        if (activities) {
          goToAssignment(
            subjectId,
            assignmentActivityId,
            assignmentActivityName,
            multipleAttemptAllowed,
            assignmentId,
            lernosityActivityIdEN,
            lernosityActivityIdES,
            assignmentType,
            activities[0]
          );
        }
      });
    } else {
      goToAssignment(
        subjectId,
        assignmentActivityId,
        assignmentActivityName,
        multipleAttemptAllowed,
        assignmentId,
        lernosityActivityIdEN,
        lernosityActivityIdES,
        assignmentType,
        assignment
      );
    }
  }

  function isPastDate(dueDate: Date | undefined) {
    let isPastDate: boolean = false;
    isPastDate = moment(dueDate, ["YYYY-MM-DD"]).isBefore(
      moment(new Date(), ["YYYY-MM-DD"]),
      "day"
    );

    return isPastDate;
  }

  return (
    <Fragment>
      {loading && <Loader />}

      <div>
        <div className="grid grid-cols-5 gap-5">
          <div className="col-span-6">
            <div className="bg-[#403d6a] rounded-t-lg">
              <h3 className="py-4 px-7 bg-[#4e4b75] rounded-t-lg tracking-wider font-Didact relative text-lg  lg:text-xl xl:text-2xl text-[#f5d500] text-white hover:shadow-2xl h-full flex items-center justify-center">
                {t("Breadcrumb.Assignments")}
              </h3>
              <div className="p-4">
                <div className="flex justify-end mb-3">
                  <div className="h-auto py-3 px-4 w-full lg:w-auto bg-black bg-opacity-20 rounded-md space-y-2 mt-4 md:mt-0">
                    <ul className="grid md:grid-cols-12 lg:grid-cols-8 gap-2 text-gray-500 text-sm     lg:flex items-center flex-wrap text-gray-500 text-sm space-x-0 space-y-2 sm:space-y-0 sm:gap-x-4 sm:gap-y-2">
                      <li className="md:col-span-3 flex items-center text-sm text-white gap-x-1.5">
                        <div className="w-7 h-7 bg-black bg-opacity-40 flex items-center justify-center rounded-full">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 16 19"
                            className="w-4 h-4 stroke-current opacity-70 text-white"
                          >
                            <g
                              id="Group_13"
                              data-name="Group 13"
                              transform="translate(-1377 -738)"
                            >
                              <g
                                id="Rectangle_3"
                                data-name="Rectangle 3"
                                transform="translate(1377 738)"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="1"
                              >
                                <rect stroke="none"></rect>
                                <rect
                                  x="0.5"
                                  y="0.5"
                                  width="15"
                                  height="18"
                                  fill="none"
                                ></rect>
                              </g>
                              <line
                                id="Line_12"
                                data-name="Line 12"
                                x2="15"
                                transform="translate(1377.5 739.5)"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                              ></line>
                              <g id="Group_9" data-name="Group 9">
                                <line
                                  id="Line_13"
                                  data-name="Line 13"
                                  x2="8"
                                  transform="translate(1382.5 743.5)"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="1.3"
                                ></line>
                                <line
                                  id="Line_14"
                                  data-name="Line 14"
                                  x2="1.6"
                                  transform="translate(1379.5 743.5)"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="1.3"
                                ></line>
                              </g>
                              <g
                                id="Group_10"
                                data-name="Group 10"
                                transform="translate(0 3)"
                              >
                                <line
                                  id="Line_13-2"
                                  data-name="Line 13"
                                  x2="8"
                                  transform="translate(1382.5 743.5)"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="1.3"
                                ></line>
                                <line
                                  id="Line_14-2"
                                  data-name="Line 14"
                                  x2="1.6"
                                  transform="translate(1379.5 743.5)"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="1.3"
                                ></line>
                              </g>
                              <g
                                id="Group_11"
                                data-name="Group 11"
                                transform="translate(0 6)"
                              >
                                <line
                                  id="Line_13-3"
                                  data-name="Line 13"
                                  x2="8"
                                  transform="translate(1382.5 743.5)"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="1.3"
                                ></line>
                                <line
                                  id="Line_14-3"
                                  data-name="Line 14"
                                  x2="1.6"
                                  transform="translate(1379.5 743.5)"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="1.3"
                                ></line>
                              </g>
                              <g
                                id="Group_12"
                                data-name="Group 12"
                                transform="translate(0 9)"
                              >
                                <line
                                  id="Line_13-4"
                                  data-name="Line 13"
                                  x2="8"
                                  transform="translate(1382.5 743.5)"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="1.3"
                                ></line>
                                <line
                                  id="Line_14-4"
                                  data-name="Line 14"
                                  x2="1.6"
                                  transform="translate(1379.5 743.5)"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="1.3"
                                ></line>
                              </g>
                            </g>
                          </svg>
                        </div>
                        {t("Assignments.Assessments")}
                      </li>
                      <li className="md:col-span-5 flex items-center text-sm text-white gap-x-1.5">
                        <div className="w-7 h-7 bg-black bg-opacity-40 flex items-center justify-center rounded-full">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 38 26"
                            className="w-4 h-4 stroke-current opacity-90 text-white"
                          >
                            <g
                              id="Group_1"
                              data-name="Group 1"
                              transform="translate(-249 -189)"
                            >
                              <g
                                id="Rectangle_2"
                                data-name="Rectangle 2"
                                transform="translate(249 189)"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                              >
                                <rect
                                  width="38"
                                  height="26"
                                  rx="5"
                                  stroke="none"
                                ></rect>
                                <rect
                                  x="1"
                                  y="1"
                                  width="36"
                                  height="24"
                                  rx="4"
                                  fill="none"
                                ></rect>
                              </g>
                              <line
                                id="Line_1"
                                data-name="Line 1"
                                x2="23"
                                transform="translate(257 195.5)"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="1"
                              ></line>
                              <line
                                id="Line_1-2"
                                data-name="Line 1"
                                x2="23"
                                transform="translate(257 199.5)"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="1"
                              ></line>
                              <line
                                id="Line_1-3"
                                data-name="Line 1"
                                x2="23"
                                transform="translate(257 203.5)"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="1"
                              ></line>
                              <line
                                id="Line_1-4"
                                data-name="Line 1"
                                x2="23"
                                transform="translate(257 207.5)"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="1"
                              ></line>
                            </g>
                          </svg>
                        </div>
                        {t("Assignments.ConstructedResponse")}
                      </li>
                      <li className="md:col-span-4 flex items-center text-sm text-white gap-x-1.5">
                        <div className="w-7 h-7 bg-black bg-opacity-40 flex items-center justify-center rounded-full">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 65.418 61.447"
                            className="w-4 h-4 stroke-current text-white"
                          >
                            <g
                              id="Group_26"
                              data-name="Group 26"
                              transform="translate(-417.59 -1119)"
                            >
                              <path
                                id="Path_13"
                                data-name="Path 13"
                                d="M697.045,48H738.2v59.447H694.667V48Z"
                                transform="translate(-276.077 1072)"
                                fill="none"
                                stroke="currentColor"
                                stroke-linejoin="round"
                                stroke-width="2"
                              ></path>
                              <path
                                id="Path_15"
                                data-name="Path 15"
                                d="M720.667,149.144l2.774,2.675,5.35-5.152"
                                transform="translate(-295.067 981.185)"
                                fill="none"
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-width="2"
                              ></path>
                              <path
                                id="Path_18"
                                data-name="Path 18"
                                d="M789.333,155h14.82"
                                transform="translate(-348.977 975.335)"
                                fill="none"
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-width="2"
                              ></path>
                              <path
                                id="Path_15-2"
                                data-name="Path 15"
                                d="M720.667,149.144l2.774,2.675,5.35-5.152"
                                transform="translate(-295.067 993.967)"
                                fill="none"
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-width="2"
                              ></path>
                              <path
                                id="Path_18-2"
                                data-name="Path 18"
                                d="M789.333,155h14.82"
                                transform="translate(-348.977 988.116)"
                                fill="none"
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-width="2"
                              ></path>
                              <path
                                id="Path_15-3"
                                data-name="Path 15"
                                d="M720.667,149.144l2.774,2.675,5.35-5.152"
                                transform="translate(-295.067 1006.748)"
                                fill="none"
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-width="2"
                              ></path>
                              <path
                                id="Path_18-3"
                                data-name="Path 18"
                                d="M789.333,155h14.82"
                                transform="translate(-348.977 1000.897)"
                                fill="none"
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-width="2"
                              ></path>
                              <path
                                id="Path_15-4"
                                data-name="Path 15"
                                d="M720.667,149.144l2.774,2.675,5.35-5.152"
                                transform="translate(-295.067 1019.529)"
                                fill="none"
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-width="2"
                              ></path>
                              <path
                                id="Path_18-4"
                                data-name="Path 18"
                                d="M789.333,155h14.82"
                                transform="translate(-348.977 1013.678)"
                                fill="none"
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-width="2"
                              ></path>
                              <path
                                id="Path_83"
                                data-name="Path 83"
                                d="M3.369,0,0,8.669V46.914H6.737V8.669Z"
                                transform="translate(452.078 1174.762) rotate(-141)"
                                fill="#fff"
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                              ></path>
                              <line
                                id="Line_41"
                                data-name="Line 41"
                                x2="6.836"
                                transform="translate(457.464 1168.032) rotate(-141)"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                              ></line>
                              <line
                                id="Line_42"
                                data-name="Line 42"
                                x2="6.836"
                                transform="translate(476.357 1144.702) rotate(-141)"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                              ></line>
                            </g>
                          </svg>
                        </div>
                        {t("Assignments.GeneratedTest")}
                      </li>
                      <li className="md:col-span-3 flex items-center text-sm text-white gap-x-1.5">
                        <div className="w-7 h-7 bg-black bg-opacity-40 flex items-center justify-center rounded-full">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            className="w-4 h-4 stroke-current opacity-80 text-white"
                          >
                            <path
                              stroke-width="2"
                              d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z"
                            ></path>
                            <path
                              stroke-width="2"
                              d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                            ></path>
                          </svg>
                        </div>
                        Video
                      </li>
                      <li className="md:col-span-5 flex items-center text-sm text-white gap-x-1.5">
                        <div className="w-7 h-7 bg-black bg-opacity-40 flex items-center justify-center rounded-full">
                          <svg
                            className="w-[17px] h-[17px] fill-current opacity-90 text-white"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 32 32"
                          >
                            <path d="M 16 4 C 9.382813 4 4 9.382813 4 16 C 4 22.617188 9.382813 28 16 28 C 22.617188 28 28 22.617188 28 16 C 28 9.382813 22.617188 4 16 4 Z M 16 6 C 21.535156 6 26 10.464844 26 16 C 26 21.535156 21.535156 26 16 26 C 10.464844 26 6 21.535156 6 16 C 6 10.464844 10.464844 6 16 6 Z M 16 10 C 13.800781 10 12 11.800781 12 14 L 14 14 C 14 12.882813 14.882813 12 16 12 C 17.117188 12 18 12.882813 18 14 C 18 14.765625 17.507813 15.445313 16.78125 15.6875 L 16.375 15.8125 C 15.558594 16.082031 15 16.863281 15 17.71875 L 15 19 L 17 19 L 17 17.71875 L 17.40625 17.59375 C 18.945313 17.082031 20 15.621094 20 14 C 20 11.800781 18.199219 10 16 10 Z M 15 20 L 15 22 L 17 22 L 17 20 Z"></path>
                          </svg>
                        </div>
                        {t("Assignments.Questions")}
                      </li>
                      <li className="md:col-span-4 flex items-center text-sm text-white gap-x-1.5">
                        <div className="w-7 h-7 bg-black bg-opacity-40 flex items-center justify-center rounded-full">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 18.871 19.444"
                            className="w-4 h-4 stroke-current opacity-90 text-white"
                          >
                            <g
                              id="Group_8"
                              data-name="Group 8"
                              transform="translate(-1345 -738.556)"
                            >
                              <g
                                id="Rectangle_1"
                                data-name="Rectangle 1"
                                transform="translate(1345 742)"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="1"
                              >
                                <rect
                                  width="16"
                                  height="16"
                                  stroke="none"
                                ></rect>
                                <rect
                                  x="0.5"
                                  y="0.5"
                                  width="15"
                                  height="15"
                                  fill="none"
                                ></rect>
                              </g>
                              <g
                                id="Group_7"
                                data-name="Group 7"
                                transform="translate(0.5)"
                              >
                                <line
                                  id="Line_11"
                                  data-name="Line 11"
                                  x2="7.935"
                                  transform="translate(1348.5 749.967)"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="1"
                                ></line>
                              </g>
                              <g
                                id="Group_16"
                                data-name="Group 16"
                                transform="translate(2102.934 -602.5) rotate(90)"
                              >
                                <line
                                  id="Line_11-2"
                                  data-name="Line 11"
                                  x2="7.935"
                                  transform="translate(1348.5 749.967)"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="1"
                                ></line>
                              </g>
                              <path
                                id="Path_46"
                                data-name="Path 46"
                                d="M1348.115,742.245v-3.189h15.255v15.5h-2.692"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="1"
                              ></path>
                            </g>
                          </svg>
                        </div>
                        {t("Assignments.GroupAssignments")}
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="relative overflow-auto rounded-lg">
                  <table
                    id="assignment-list-tbl"
                    className="w-full divide-y divide-gray-200"
                  >
                    <thead className="bg-gray-200 uppercase">
                      <tr>
                        <th
                          scope="col"
                          className="bg-gray-200 top-0 px-2 xxl:px-6 py-3 text-center text-base font-medium text-dark-teal uppercase tracking-wider"
                        >
                          {t("Assignments.Type")}
                        </th>
                        <th
                          scope="col"
                          className="top-0 bg-gray-100 px-2 xxl:px-6 py-3 text-left text-base font-medium  text-dark-teal uppercase tracking-wider"
                        >
                          {t("Assignments.DueDate")}
                        </th>
                        <th
                          scope="col"
                          className="bg-gray-200 top-0 px-2 xxl:px-6 py-3 text-left text-base font-medium  text-dark-teal tracking-wider"
                        >
                          {t("Assignments.Name")}
                        </th>
                        <th
                          scope="col"
                          className="top-0 bg-gray-100 px-2 xxl:px-6 py-3 text-left text-base font-medium  text-dark-teal uppercase tracking-wider"
                        >
                          {t("Assignments.Teacher/Class")}
                        </th>
                        <th
                          scope="col"
                          className="bg-gray-200 top-0 px-2 xxl:px-6 py-3 text-left text-base font-medium  text-dark-teal uppercase tracking-wider"
                        >
                          {t("Assignments.Notes")}
                        </th>
                        <th
                          scope="col"
                          className="bg-gray-100 top-0 px-2 xxl:px-6 w-20"
                        >
                          <span className="sr-only">Action button</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white">
                      {assignmentData?.map((assignment, index) => {
                        index = index + 1;
                        return (
                          <Fragment key={index}>
                            {
                              <tr className={`border-b-2`}>
                                <td className="w-10 px-6 py-4 bg-gray-100">
                                  <div
                                    className="flex items-center"
                                    onClick={() =>
                                      onGroupIconChange(index, assignment)
                                    }
                                  >
                                    {!assignment.isGroupedAssignment ? (
                                      <div>
                                        {assignment.assignmentType ===
                                          Constant.AssignmentActivityType
                                            .ASSESSMENT && (
                                          <span title="Assessment">
                                            <AssignmentAssessmentIcon
                                              is_saved={
                                                assignment.assignmentStatus?.toUpperCase() ===
                                                Constant.AssignmentStatus.SAVE
                                                  ? 1
                                                  : 0
                                              }
                                              past_due={
                                                isPastDate(assignment.endDate)
                                                  ? 1
                                                  : 0
                                              }
                                            />
                                          </span>
                                        )}
                                        {assignment.assignmentType ===
                                          Constant.AssignmentActivityType
                                            .PERFORMANCETASKS && (
                                          <span title="Performance task">
                                            <AssignmentPerformanceTaskIcon
                                              is_saved={
                                                assignment.assignmentStatus?.toUpperCase() ===
                                                Constant.AssignmentStatus.SAVE
                                                  ? 1
                                                  : 0
                                              }
                                              past_due={
                                                isPastDate(assignment.endDate)
                                                  ? 1
                                                  : 0
                                              }
                                            />
                                          </span>
                                        )}
                                        {assignment.assignmentType ===
                                          Constant.AssignmentActivityType
                                            .PREBUILTTESTS && (
                                          <span title="Generated test">
                                            <PreBuildIcon
                                              is_saved={
                                                assignment.assignmentStatus?.toUpperCase() ===
                                                Constant.AssignmentStatus.SAVE
                                                  ? 1
                                                  : 0
                                              }
                                              past_due={
                                                isPastDate(assignment.endDate)
                                                  ? 1
                                                  : 0
                                              }
                                            />
                                          </span>
                                        )}
                                        {assignment.assignmentType ===
                                          Constant.AssignmentActivityType
                                            .VIDEOLESSON && (
                                          <span title="Video">
                                            <AssignmentVideoIcon
                                              is_saved={
                                                assignment.assignmentStatus?.toUpperCase() ===
                                                Constant.AssignmentStatus.SAVE
                                                  ? 1
                                                  : 0
                                              }
                                              past_due={
                                                isPastDate(assignment.endDate)
                                                  ? 1
                                                  : 0
                                              }
                                            />
                                          </span>
                                        )}
                                        {assignment.assignmentType ===
                                          Constant.AssignmentActivityType
                                            .PRACTICEQUESTIONS && (
                                          <span title="Questions">
                                            <AssignmentQuestionsIcon
                                              is_saved={
                                                assignment.assignmentStatus?.toUpperCase() ===
                                                Constant.AssignmentStatus.SAVE
                                                  ? 1
                                                  : 0
                                              }
                                              past_due={
                                                isPastDate(assignment.endDate)
                                                  ? 1
                                                  : 0
                                              }
                                            />
                                          </span>
                                        )}
                                        {assignment.assignmentType ===
                                          Constant.AssignmentActivityType
                                            .CONSTRUCTEDRESPONSE && (
                                          <span title="Constructed response">
                                            <ConstructedResponseIcon
                                              is_saved={
                                                assignment.assignmentStatus?.toUpperCase() ===
                                                Constant.AssignmentStatus.SAVE
                                                  ? 1
                                                  : 0
                                              }
                                              past_due={
                                                isPastDate(assignment.endDate)
                                                  ? 1
                                                  : 0
                                              }
                                            />
                                          </span>
                                        )}
                                      </div>
                                    ) : expandedEvent &&
                                      expandedEvent.index.includes(index) ? (
                                      <span title="Group assignments">
                                        <AssignmentsGroupedAssignmentCollapsedIcon
                                          is_saved={
                                            assignment.assignmentStatus?.toUpperCase() ===
                                            Constant.AssignmentStatus.SAVE
                                              ? 1
                                              : 0
                                          }
                                          past_due={
                                            isPastDate(assignment.endDate)
                                              ? 1
                                              : 0
                                          }
                                        />
                                      </span>
                                    ) : (
                                      <span title="Group assignments">
                                        <AssignmentGroupedAssignmentExpandedIcon
                                          is_saved={
                                            assignment.assignmentStatus?.toUpperCase() ===
                                            Constant.AssignmentStatus.SAVE
                                              ? 1
                                              : 0
                                          }
                                          past_due={
                                            isPastDate(assignment.endDate)
                                              ? 1
                                              : 0
                                          }
                                        />
                                      </span>
                                    )}
                                  </div>
                                </td>
                                <td className="px-3 py-4 border-r-2 border-gray-100">
                                  <div
                                    className={`border-l-4 border-blue h-10 flex flex-col justify-center  ${
                                      (
                                        assignment.isGroupedAssignment &&
                                        assignment.assignmentStatus?.toUpperCase() ===
                                          Constant.AssignmentStatus.SAVE
                                          ? true
                                          : false
                                      )
                                        ? "border-site-orange"
                                        : ""
                                    } ${
                                      isPastDate(assignment.endDate)
                                        ? "border-site-pink"
                                        : ""
                                    } pl-2`}
                                  >
                                    <div
                                      className={`text-sm font-medium text-gray-700 ${
                                        (
                                          assignment.isGroupedAssignment &&
                                          assignment.assignmentStatus?.toUpperCase() ===
                                            Constant.AssignmentStatus.SAVE
                                            ? true
                                            : false
                                        )
                                          ? "text-site-orange"
                                          : ""
                                      } ${
                                        isPastDate(assignment.endDate)
                                          ? "text-site-pink"
                                          : ""
                                      }`}
                                    >
                                      {assignment.endDate &&
                                        moment(assignment.endDate).format(
                                          "MM/DD/YYYY"
                                        )}
                                    </div>
                                    <div className="text-sm text-gray-500 font-semibold capitalize">
                                      <div>
                                        {isPastDate(assignment.endDate)
                                          ? t("Assignments.PastDue")
                                          : (
                                              assignment.isGroupedAssignment &&
                                              assignment.assignmentStatus?.toUpperCase() ===
                                                Constant.AssignmentStatus.SAVE
                                                ? true
                                                : false
                                            )
                                          ? t("Assignments.Saved")
                                          : ""}
                                      </div>
                                      <div>
                                        {!isPastDate(assignment.endDate) &&
                                          (assignment.assignmentStatus?.toUpperCase() ===
                                            Constant.AssignmentStatus.NEW ||
                                            assignment.assignmentStatus?.toLowerCase() ===
                                              "in progress" ||
                                            assignment.assignmentStatus?.toLowerCase() ===
                                              "in_progress") &&
                                          t("Assignments.NotStarted")}
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td className="px-3 py-4 border-r-2 border-gray-100">
                                  <div className="text-sm text-gray-700 capitalize font-normal">
                                    {assignment.name}
                                  </div>
                                </td>
                                <td className="px-3 py-4 text-sm text-gray-500 border-r-2 border-gray-100">
                                  <div className="text-sm text-gray-700 capitalize font-normal">
                                    {assignment.teacherName}
                                  </div>
                                  <div className="text-sm text-gray-700 capitalize font-normal">
                                    {assignment.className}
                                  </div>
                                </td>
                                <td className="px-3 py-4 text-left text-sm  border-r-2 border-gray-100 relative">
                                  {!assignment.isGroupedAssignment && assignment.minimumScore > 0 && (
                                      <>Minimum score required - {assignment.minimumScore}%</>
                                  )}
                                  {assignment.textNote && (
                                    <div
                                      onClick={() =>
                                        setNotesFromTeacher(
                                          assignment.assignmentId +
                                            "inx" +
                                            index.toString()
                                        )
                                      }
                                      className={`${
                                        assignment &&
                                        assignment.textNote &&
                                        assignment.textNote.length > 0
                                          ? "bg-yellow-100"
                                          : "cursor-not-allowed "
                                      } px-2 py-2 ${
                                        assignment.isLocked
                                          ? "pointer-events-none"
                                          : "cursor-pointer"
                                      }`}
                                    >
                                      <p className="line-clamp-2 text-gray-700">
                                        {assignment.isLocked ? (
                                          "Locked"
                                        ) : <span
                                            dangerouslySetInnerHTML={{
                                              __html: sanitizeNoteContent(
                                                assignment.textNote
                                              ),
                                            }}
                                          ></span> ? (
                                          assignment.textNote.length > 50 ? (
                                            <span
                                              dangerouslySetInnerHTML={{
                                                __html:
                                                  sanitizeNoteContent(
                                                    assignment.textNote.substring(
                                                      0,
                                                      50
                                                    )
                                                  ) + "...",
                                              }}
                                            ></span>
                                          ) : (
                                            <span
                                              dangerouslySetInnerHTML={{
                                                __html: sanitizeNoteContent(
                                                  assignment.textNote
                                                ),
                                              }}
                                            ></span>
                                          )
                                        ) : (
                                          ""
                                        )}
                                      </p>
                                    </div>
                                  )}

                                  {assignment &&
                                    assignment.assignmentId + "inx" + index ===
                                      notesFromTeacher &&
                                    assignment?.isLocked === false &&
                                    assignment.textNote &&
                                    assignment.textNote.length > 0 && (
                                      <div
                                        aria-live="assertive"
                                        className={`absolute top-12 inset-0 flex px-4 py-6 pointer-events-none items-start ${
                                          index === assignmentData.length
                                            ? " -top-12"
                                            : " top-12"
                                        }`}
                                      >
                                        <div className="w-full flex flex-col items-center space-y-4 sm:items-start z-10">
                                          <div className="max-w-sm w-full bg-yellow-200 shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
                                            <div className="p-4">
                                              <div className="flex items-start">
                                                <div className="w-0 flex-1 pt-0.5">
                                                  <p className="text-sm font-semibold text-blue">
                                                    Notes from your teacher
                                                  </p>
                                                  <p className="mt-1 text-sm text-gray-800">
                                                    <span
                                                      dangerouslySetInnerHTML={{
                                                        __html:
                                                          sanitizeNoteContent(
                                                            assignment.textNote
                                                          ),
                                                      }}
                                                    ></span>
                                                  </p>
                                                </div>
                                                <div className="ml-4 flex-shrink-0 flex">
                                                  <button className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-0 ">
                                                    <span className="sr-only">
                                                      Close
                                                    </span>
                                                    <svg
                                                      className="h-5 w-5 bg-yellow-200"
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      viewBox="0 0 20 20"
                                                      aria-hidden="true"
                                                      onClick={() =>
                                                        setNotesFromTeacher(
                                                          null
                                                        )
                                                      }
                                                    >
                                                      <path
                                                        fillRule="evenodd"
                                                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                                        clipRule="evenodd"
                                                      ></path>
                                                    </svg>
                                                  </button>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                </td>
                                <td className="px-3 py-4 border-r-2 border-gray-100">
                                  <div
                                    className={`flex justify-center ${
                                      assignment &&
                                      assignment.multipleAttempt ===
                                        assignment.totalAttempt &&
                                      assignment.multipleAttempt > 0 &&
                                      assignment.totalAttempt > 0 &&
                                      assignment.assignmentStatus?.toUpperCase() ===
                                        Constant.AssignmentStatus.COMPLETE
                                        ? "cursor-not-allowed pointer-events-none bg-opacity-50"
                                        : ""
                                    }`}
                                  >
                                    {assignment.isLocked ? (
                                      <LockIcon />
                                    ) : (
                                      <>
                                        {
                                          //Single Assignment Without Expand
                                          !assignment.isGroupedAssignment && (
                                            <a
                                              href="#!"
                                              onClick={() => {
                                                startAssignmentActivity(
                                                  assignment.subjectId,
                                                  assignment.assignmentActivityId,
                                                  getActivityName(
                                                    assignment,
                                                    props.profile
                                                      ?.plLanguageId ?? 1
                                                  ),
                                                  assignment.multipleAttempt,
                                                  assignment.assignmentId,
                                                  assignment.learnosityIdEnglish,
                                                  assignment.learnosityIdSpanish,
                                                  assignment.assignmentType
                                                );
                                              }}
                                              className={
                                                (!assignment.isGroupedAssignment &&
                                                assignment.multipleAttempt ===
                                                  assignment.totalAttempt &&
                                                assignment.multipleAttempt >
                                                  0 &&
                                                assignment.totalAttempt > 0 &&
                                                assignment.assignmentStatus?.toUpperCase() ===
                                                  Constant.AssignmentStatus
                                                    .COMPLETE
                                                  ? "bg-opacity-50 "
                                                  : "") +
                                                `bg-blues ${
                                                  (
                                                    !assignment.isGroupedAssignment &&
                                                    assignment.assignmentStatus
                                                      ?.toUpperCase()
                                                      .toString() ===
                                                      Constant.AssignmentStatus
                                                        .SAVE
                                                      ? true
                                                      : false
                                                  )
                                                    ? "bg-site-orange"
                                                    : ""
                                                } ${
                                                  isPastDate(assignment.endDate)
                                                    ? "bg-site-pink"
                                                    : ""
                                                } ${
                                                  assignment &&
                                                  assignment.allowRetry ===
                                                    false &&
                                                  assignment.assignmentStatus?.toUpperCase() ===
                                                    Constant.AssignmentStatus
                                                      .COMPLETE
                                                    ? ""
                                                    : ""
                                                }
                                    hover:bg-secondary-teal transition-all hover:text-gray-100 inline-block whitespace-nowrap rounded-lg text-sm w-40 text-center py-2 text-white`
                                              }
                                            >
                                              {!assignment.isGroupedAssignment &&
                                              assignment.assignmentStatus?.toUpperCase() ===
                                                Constant.AssignmentStatus.SAVE
                                                ? t("Assignments.Continue")
                                                : assignment.assignmentStatus
                                                    ?.toString()
                                                    .toUpperCase() ===
                                                  Constant.AssignmentStatus.NEW
                                                ? t("Assignments.Begin")
                                                : assignment.assignmentStatus
                                                    ?.toString()
                                                    .toLowerCase() ===
                                                  "in progress"
                                                ? t("Assignments.Begin")
                                                : assignment &&
                                                  assignment.allowRetry ===
                                                    false &&
                                                  (assignment.assignmentStatus
                                                    ?.toString()
                                                    .toUpperCase() ===
                                                    Constant.AssignmentStatus
                                                      .ATTEMPTED ||
                                                    assignment.assignmentStatus
                                                      ?.toString()
                                                      .toUpperCase() ===
                                                      Constant.AssignmentStatus
                                                        .COMPLETE)
                                                ? "Attempt Again"
                                                : assignment &&
                                                  assignment.assignmentStatus
                                                    ?.toString()
                                                    .toUpperCase() ===
                                                    Constant.AssignmentStatus
                                                      .COMPLETE
                                                ? "Attempt Again"
                                                : ""}

                                              {assignment &&
                                                assignment.assignmentStatus
                                                  ?.toString()
                                                  .toUpperCase() ===
                                                  Constant.AssignmentStatus
                                                    .NEW && (
                                                  <RightArrowIcon className="h-6 w-6 ml-1 inline-block" />
                                                )}
                                            </a>
                                          )
                                        }

                                        {
                                          //Group Assignments Without Expand
                                          assignment.isGroupedAssignment && (
                                            <a
                                              href="#!"
                                              onClick={() => onGroupIconChange(index, assignment)}
                                              className={`bg-blues ${
                                                (
                                                  assignment.isGroupedAssignment &&
                                                  assignment.assignmentStatus
                                                    ?.toUpperCase()
                                                    .toString() ===
                                                    Constant.AssignmentStatus
                                                      .INPROGRESS
                                                    ? true
                                                    : false
                                                )
                                                  ? "bg-site-orange"
                                                  : ""
                                              } ${
                                                isPastDate(assignment.endDate)
                                                  ? "bg-site-pink"
                                                  : ""
                                              } ${
                                                assignment &&
                                                assignment.allowRetry ===
                                                  false &&
                                                assignment.assignmentStatus?.toUpperCase() ===
                                                  Constant.AssignmentStatus
                                                    .COMPLETE
                                                  ? ""
                                                  : ""
                                              }
                                              hover:bg-secondary-teal transition-all hover:text-gray-100 inline-block whitespace-nowrap rounded-lg text-sm w-40 text-center py-2 text-white`}
                                            >
                                              {expandedEvent &&
                                                expandedEvent.index.includes(index) ? (
                                                  "- " + t("Assignments.CollapseView")
                                                )
                                                : ("+ " + t("Assignments.ExpandToView"))}
                                            </a>
                                          )
                                        }
                                      </>
                                    )}
                                  </div>
                                </td>
                              </tr>
                            }

                            {assignment.isGroupedAssignment &&
                              expandedEvent &&
                              expandedEvent.index.includes(index) &&
                              activities?.map((item, idx) => (
                                  <tr
                                      key={idx}
                                      className={`${
                                          assignment.assignmentId ===
                                          item.assignmentId
                                              ? ""
                                              : "hidden"
                                      } border-b-2`}
                                  >
                                    <td className="w-10 px-6 py-4 bg-gray-200"></td>
                                    <td className="px-3 py-4 border-r-2 border-gray-100">
                                      <div className="flex items-center">
                                        {item.activityType &&
                                            item.activityType ===
                                            Constant.AssignmentActivityType
                                                .ASSESSMENT && (
                                                <span title="Assessment">
                                            <AssignmentAssessmentIcon
                                                is_saved={
                                                  item.assignmentStatus?.toUpperCase() ===
                                                  Constant.AssignmentStatus.SAVE
                                                      ? 1
                                                      : 0
                                                }
                                                past_due={
                                                  moment(
                                                      assignment.endDate
                                                  ).format("MM/DD/YYYY") <
                                                  moment(new Date()).format(
                                                      "MM/DD/YYYY"
                                                  )
                                                      ? 1
                                                      : 0
                                                }
                                            />
                                          </span>
                                            )}
                                        {item.activityType &&
                                            item.activityType ===
                                            Constant.AssignmentActivityType
                                                .PREBUILTTESTS && (
                                                <span title="Generated test">
                                            <PreBuildIcon
                                                is_saved={
                                                  item.assignmentStatus?.toUpperCase() ===
                                                  Constant.AssignmentStatus.SAVE
                                                      ? 1
                                                      : 0
                                                }
                                                past_due={
                                                  moment(
                                                      assignment.endDate
                                                  ).format("MM/DD/YYYY") <
                                                  moment(new Date()).format(
                                                      "MM/DD/YYYY"
                                                  )
                                                      ? 1
                                                      : 0
                                                }
                                            />
                                          </span>
                                            )}
                                        {item.activityType ===
                                            Constant.AssignmentActivityType
                                                .PERFORMANCETASKS && (
                                                <span title="Generated test">
                                          <AssignmentPerformanceTaskIcon
                                              is_saved={
                                                item.assignmentStatus?.toUpperCase() ===
                                                Constant.AssignmentStatus.SAVE
                                                    ? 1
                                                    : 0
                                              }
                                              past_due={
                                                moment(assignment.endDate).format(
                                                    "MM/DD/YYYY"
                                                ) <
                                                moment(new Date()).format(
                                                    "MM/DD/YYYY"
                                                )
                                                    ? 1
                                                    : 0
                                              }
                                          />
                                        </span>
                                            )}
                                        {item.activityType ===
                                            Constant.AssignmentActivityType
                                                .VIDEOLESSON && (
                                                <span title="Video">
                                          <AssignmentVideoIcon
                                              is_saved={
                                                item.assignmentStatus?.toUpperCase() ===
                                                Constant.AssignmentStatus.SAVE
                                                    ? 1
                                                    : 0
                                              }
                                              past_due={
                                                moment(assignment.endDate).format(
                                                    "MM/DD/YYYY"
                                                ) <
                                                moment(new Date()).format(
                                                    "MM/DD/YYYY"
                                                )
                                                    ? 1
                                                    : 0
                                              }
                                          />
                                        </span>
                                            )}
                                        {item.activityType ===
                                            Constant.AssignmentActivityType
                                                .PRACTICEQUESTIONS && (
                                                <span title="Questions">
                                          <AssignmentQuestionsIcon
                                              is_saved={
                                                item.assignmentStatus?.toUpperCase() ===
                                                Constant.AssignmentStatus.SAVE
                                                    ? 1
                                                    : 0
                                              }
                                              past_due={
                                                moment(assignment.endDate).format(
                                                    "MM/DD/YYYY"
                                                ) <
                                                moment(new Date()).format(
                                                    "MM/DD/YYYY"
                                                )
                                                    ? 1
                                                    : 0
                                              }
                                          />
                                        </span>
                                            )}
                                        {item.activityType ===
                                            Constant.AssignmentActivityType
                                                .CONSTRUCTEDRESPONSE && (
                                                <span title="Constructed Response">
                                          <ConstructedResponseIcon
                                              is_saved={
                                                item.assignmentStatus?.toUpperCase() ===
                                                Constant.AssignmentStatus.SAVE
                                                    ? 1
                                                    : 0
                                              }
                                              past_due={
                                                moment(assignment.endDate).format(
                                                    "MM/DD/YYYY"
                                                ) <
                                                moment(new Date()).format(
                                                    "MM/DD/YYYY"
                                                )
                                                    ? 1
                                                    : 0
                                              }
                                          />
                                        </span>
                                            )}
                                      </div>
                                    </td>
                                    <td className="px-3 py-4 border-r-2 border-gray-100">
                                      <div className="text-sm text-gray-700 capitalize font-normal">
                                        {getActivityName(
                                            item,
                                            props.profile?.plLanguageId ?? 1
                                        )}
                                      </div>
                                    </td>
                                    <td className="px-3 py-4 text-sm text-gray-500 border-r-2 border-gray-100">
                                      <div className="text-sm text-gray-700 capitalize font-normal">
                                        {assignment.teacherName}
                                      </div>
                                      <div className="text-sm text-gray-800 capitalize font-medium">
                                        {assignment.className}
                                      </div>
                                    </td>
                                    <td className="px-3 py-4 text-left text-sm border-r-2 border-gray-100">
                                      {item.minimumScore > 0 && (
                                          <div>
                                            Minimum score required - {item.minimumScore}%
                                          </div>
                                      )}
                                    </td>
                                    <td
                                        className={`${
                                            item &&
                                            (item.allowRetry === false ||
                                                (item.multipleAttempt > 0 &&
                                                    item.multipleAttempt ===
                                                    item.totalAttempt)) &&
                                            item.assignmentStatus?.toUpperCase() ===
                                            Constant.AssignmentStatus.COMPLETE
                                                ? "cursor-text"
                                                : ""
                                        }`}
                                    >
                                      <div className="flex justify-center">
                                        {assignment.isLocked ? (
                                            <LockIcon/>
                                        ) : (
                                            <button
                                                onClick={() => {
                                                  startAssignmentActivity(
                                                      assignment.subjectId,
                                                      item.assignmentActivityId,
                                                      getActivityName(
                                                          item,
                                                          props.profile?.plLanguageId ?? 1
                                                      ),
                                                      item.multipleAttempt,
                                                      assignment.assignmentId,
                                                      item.learnosityIdEnglish,
                                                      item.learnosityIdSpanish,
                                                      item.activityType
                                                  );
                                                }}
                                                className={`${
                                                    item &&
                                                    item.multipleAttempt > 0 &&
                                                    item.multipleAttempt ===
                                                    item.totalAttempt &&
                                                    !item.allowRetry &&
                                                    item.assignmentStatus?.toUpperCase() ===
                                                    Constant.AssignmentStatus.COMPLETE
                                                        ? "pointer-events-none bg-opacity-50  bg-blues "
                                                        : (item.assignmentStatus
                                                                ?.toString()
                                                                .toUpperCase() ===
                                                            Constant.AssignmentStatus
                                                                .NEW &&
                                                            !item.isEnable) ||
                                                        (item.completeInOrder &&
                                                            idx > 0 &&
                                                            activities[idx - 1].score <
                                                            activities[idx - 1]
                                                                .minimumScore)
                                                            ? " pointer-events-none bg-opacity-50  bg-blues "
                                                            : "hover:opacity-90 inline-block text-white bg-blues"
                                                }
                                        
                                        ${
                                                    item.assignmentStatus
                                                        ?.toString()
                                                        .toUpperCase() ===
                                                    Constant.AssignmentStatus.SAVE ||
                                                    item.assignmentStatus
                                                        ?.toString()
                                                        .toLowerCase() === "in progress"
                                                        ? "bg-site-orange"
                                                        : ""
                                                } ${
                                                    isPastDate(assignment.endDate)
                                                        ? "bg-site-pink"
                                                        : ""
                                                } 
                                    
                                    ${
                                                    item &&
                                                    (item.allowRetry === false ||
                                                        (item.multipleAttempt > 0 &&
                                                            item.multipleAttempt ===
                                                            item.totalAttempt))
                                                        ? "text-white "
                                                        : ""
                                                } rounded-lg whitespace-nowrap text-sm px-2 w-40 hover:bg-secondary-teal transition-all hover:text-gray-100 text-center py-2 `}
                                            >
                                              {item.assignmentStatus?.toUpperCase() ===
                                              Constant.AssignmentStatus.SAVE
                                                  ? t("Assignments.Continue")
                                                  : item.assignmentStatus
                                                      ?.toString()
                                                      .toUpperCase() ===
                                                  Constant.AssignmentStatus.NEW
                                                      ? t("Assignments.Begin")
                                                      : item.assignmentStatus
                                                          ?.toString()
                                                          .toLowerCase() === "in progress"
                                                          ? t("Assignments.Continue")
                                                          : item &&
                                                          (item.allowRetry === false ||
                                                              (item.multipleAttempt > 0 &&
                                                                  item.multipleAttempt ===
                                                                  item.totalAttempt)) &&
                                                          (item.assignmentStatus
                                                                  ?.toString()
                                                                  .toUpperCase() ===
                                                              Constant.AssignmentStatus
                                                                  .ATTEMPTED ||
                                                              item.assignmentStatus
                                                                  ?.toString()
                                                                  .toUpperCase() ===
                                                              Constant.AssignmentStatus
                                                                  .COMPLETE)
                                                              ? "Attempt Again"
                                                              : item &&
                                                              item.assignmentStatus
                                                                  ?.toString()
                                                                  .toUpperCase() ===
                                                              Constant.AssignmentStatus
                                                                  .COMPLETE
                                                                  ? "Attempt Again"
                                                                  : ""}

                                              {item &&
                                                  item.assignmentStatus
                                                      ?.toString()
                                                      .toUpperCase() ===
                                                  Constant.AssignmentStatus.NEW && (
                                                      <RightArrowIcon className="h-6 w-6 ml-1 inline-block"/>
                                                  )}
                                            </button>
                                        )}
                                      </div>
                                    </td>
                                  </tr>
                              ))}
                          </Fragment>
                        );
                      })}

                      {assignmentData?.length === 0 && (
                          <tr className="bg-white">
                            <td colSpan={6} className="text-sm px-6 py-4">
                              {t("Assignments.NoRecordsFound")}
                            </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(AssignmentList);

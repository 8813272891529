import { Fragment, useEffect, useState } from "react";
import "../../../../../css/style.css";
import { connect } from "react-redux";

import { useTranslation } from "react-i18next";
import { UserContextState } from "../../../../../redux/actions/userContextAction";
import Profile from "../../../../../model/users/profile";
import LearnosityReport from "../../../results/learnosityReport";
import { getCannedTestResult } from "../../../../../api/student/cannedTestApi";
import CannedTestOverViewResult from "./cannedTestOverViewResult";
import ProgressResult from "./progressResult";
import TotalQuestionsByDomains from "./totalQuestionsByDomains";
import TotalQuestionsByStandards from "./totalQuestionsByStandards";

interface ICannedTestResult {
  userContext: UserContextState;
  profile: Profile;
  match: any;
  studentId?: number;
  studentGradeId?: number;
  isTeacherView?: boolean;
  sessionId?: number;
}
const CannedTestResult = (props: ICannedTestResult) => {
  const [result, setResult] = useState<any>(null);
  const sessionId = props.isTeacherView
    ? props.sessionId ?? ""
    : props.match.params.sessionId;
  const { t } = useTranslation();
  const userId =
    props.isTeacherView === true
      ? props.studentId ?? 0
      : props.userContext.userId;
  const [learnosityActivityUserId, setLearnosityActivityUserId] =
    useState<number>(userId);

  useEffect(() => {
    getResult();
  }, []);

  function getResult() {
    getCannedTestResult(sessionId).then((res) => {
      setLearnosityActivityUserId(res.data.learnosityActivityUserId ?? userId);
      setResult(res.data);
    });
  }

  return (
    <Fragment>
      {result && (
        <div className="relative h-full bg-[#26234c]">
          <div className="p-5 md:py-5 md:px-5 lg:p-5 flex flex-col md:flex-row">
            <CannedTestOverViewResult data={result} />
            <div className="flex-grow h-auto md:w-1/2">
              <div className="flex w-full mt-6 md:mt-0 pb-6">
                <div>
                  <h1 className="text-2xl font-bold text-white capitalize">
                    {t("DiagnosticResult.Title")} {result.type}
                  </h1>
                </div>
              </div>
              <div className="flex-none xxl:flex">
                <div className="flex-none w-full xl:w-4/5 xxl:w-2/5 bg-gray-50 rounded-md xl:mr-12 mb-12 xxl:mb-0 overflow-hidden">
                  <ProgressResult data={result} />
                </div>
                <div className="flex-grow w-full xl:w-4/5 xxl:w-3/5">
                  <TotalQuestionsByDomains data={result} />
                  <TotalQuestionsByStandards data={result} />
                </div>
              </div>
              <div className="bg-white mt-6 rounded-md">
                <div className="p-5">
                  <div className="p-5 bg-gray-50 border rounded-md text-sm font-semibold">
                    <h2>{t("StudyPlanResult.AnswerQuestionStatus")}.</h2>
                    <ul className="flex flex-wrap gap-2 w-full xl:w-1/2 mt-3">
                      {result.questions.map((question, index) => {
                        return (
                          <>
                            {question.order > 0 && question.score > 0 && (
                              <li className="w-6 h-6 bg-[#B2D136] border border-[#B2D136] text-white text-xs font-semibold flex items-center rounded-sm shadow-sm justify-center hover:bg-transparent hover:text-[#B2D136]">
                                {index + 1}
                              </li>
                            )}
                            {question.order > 0 && question.score === 0 && (
                              <li className="w-6 h-6 bg-[#e53f71] border border-[#e53f71] text-white text-xs font-semibold flex items-center rounded-sm shadow-sm justify-center hover:bg-transparent hover:text-[#e53f71]">
                                {index + 1}
                              </li>
                            )}
                          </>
                        );
                      })}
                    </ul>
                  </div>
                </div>
                <div className="p-5">
                  <div className="p-5 bg-white rounded-md text-sm font-semibold">
                    <LearnosityReport
                      sessionId={result.lernositySessionId}
                      user_id={learnosityActivityUserId.toString()}
                      show_answers={true}
                      wrapperClass="K1-Wrapper"
                      standardIds={result.standards
                        ?.map((standard) => {
                          return standard.standardId;
                        })
                        ?.join(",")}
                    ></LearnosityReport>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
};
export default connect<{}, {}, {}>(mapStateToProps, {})(CannedTestResult);

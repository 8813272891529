import MasterData from "../../model/masterData/masterData";
import * as actionType from "../actions/actionTypes"

export const masterDataReducer = (state: MasterData | null = null, action: any) => {
    switch (action.type) {
        case actionType.GET_MASTERDATA: {    
            return state;
        }
        case actionType.SET_MASTERDATA: {    
            if (action.payload !== undefined) {
                state = action.payload
            }  
            return state;
        }
        default:
            return state;
    }
}
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import DatePicker from "react-datepicker";
import classNames from "classnames";
import "../../../../../css/style.css";
import RouteConstant from "../../../../../utils/constant/routeConstant";
import Breadcrumb from "../../shared/breadcrumb";
import { IBreadcrumb } from "../../../../../model/common/breadcrumb";
import InfoMessage from "../../../../shared/infoMessage";
import { useTranslation } from "react-i18next";
import * as uuid from "uuid";
import GradeBookByAssignment from "./tabs/gradeBookByAssignments";
import {
  getGradeBookDataByAssignments,
  getGradeBookDataByClassResults,
  exportGradeBookDataByAssignmentsExcel,
  exportGradeBookDataByClassResultsExcel,
} from "../../../../../api/report/gradeBook";
import { ClassApi } from "../../../../../api/teacher/classManagementApi";
import GradeBookByClassResult from "./tabs/gradeBookByClassResults";
import {
  DEFAULT_PAGE_NUMBER,
  DEFAULT_PAGE_SIZE_50,
} from "../../../../../utils/pagingConstant";
import { ISelectElement } from "../../../../../model/interface/selectElement";
import Loader from "../../../../shared/loader";
import { AxiosResponse } from "axios";
import { toast } from "react-toastify";
import { SortingOrder } from "../../../../../types/type";
import LiftoffActivityReport from "../liftoffActivityReport/liftoffActivityReport";
import Asterisk from "../../../../shared/asterisk";
import ClassDropDown from "../../shared/classDropDown";
import ITeacherClass from "../../../../../model/interface/teacherclass";
import { getAllClassesBySubject } from "../../../../../api/teacher/teacher";
import ExportControl from "../../../../shared/customButtonControl";
import { checkIfUpgradeRequired } from "../../../../shared/permissionHelper";
import permissions from "../../../../../utils/permissions";
import { getEndDate, getStartDate, getMinSchoolYearDate, getMaxSchoolYearDate } from "../../../../../utils/dateHelper";
import AllowedTo from "../../../../shared/rbac";
import constant from "../../../../../utils/constant/constant";
import { UserContextState } from "../../../../../redux/actions/userContextAction";
import Profile from "../../../../../model/users/profile";
import CallOutMessage from "../../../../shared/callOutMessage";

interface IGradeBookProps {
  userContext: UserContextState;
  profile: Profile;
  location: any;
}

function GradeBook(props: IGradeBookProps) {
  const ACTIVITY_TYPE_DIAGNOSTICS = "DIAGNOSTICS";
  const { t } = useTranslation();
  const classId: number = parseInt(
    props.location?.state?.classId ? props.location?.state?.classId : 0
  );

  let [tabs] = useState<any[]>([
    {
      id: 0,
      name: "Assignments",
    },
    {
      id: 1,
      name: "Class Results",
    },
  ]);
  if (
    !constant.RolesRequireUpgrade.includes(props.userContext.roleId) &&
    tabs.filter((t) => t.id == 2).length == 0
  ) {
    tabs.push({
      id: 2,
      name: "Liftoff Results",
    });
  }

  const activityTypes = [
    { value: "", label: "All Activities" },
    { value: "RETRY", label: "Activities with a Retry" },
    { value: "ASSIGNMENTS", label: "Assignments" },
    { value: ACTIVITY_TYPE_DIAGNOSTICS, label: "Diagnostics" },
    { value: "PRACTICE_QUESTIONS", label: "Independent Practice Questions" },
    { value: "PRACTICE_TESTS", label: "Independent Practice Tests" },
    { value: "PRACTICE_VIDEOS", label: "Independent Practice Videos" },
  ];

  const viewByOptions = [
    { value: "0", label: "Score" },
    { value: "1", label: "Points" },
  ];
  const displayOptions = [
    { value: "0", label: "Current" },
    { value: "1", label: "Assigned Remediation" },
    { value: "2", label: "Retry Scores" },
  ];
  const [pageNumber, setPageNumber] = useState(DEFAULT_PAGE_NUMBER);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE_50);
  const [activeSortColumn, setActiveSortColumn] = useState("completionDate");
  const [orderType, setOrderType] = useState<SortingOrder>("desc");

  const [resetPageNumber, setResetPageNumber] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [viewBy, setViewBy] = useState<ISelectElement>(viewByOptions[0]);
  const [hasDiagnosticsAvailable, setHasDiagnosticsAvailable] = useState(false);
  const [selectedStartDate, setSelectedStartDate] = useState<Date>(new Date());
  const [selectedEndDate, setSelectedEndDate] = useState<Date>(new Date());
  const [isInvalidStartDate, setIsInvalidStartDate] = useState<boolean>(false);
  const [isInvalidEndDate, setIsInvalidEndDate] = useState<boolean>(false);
  const [selectedClassStartDate, setSelectedClassStartDate] = useState<Date>(new Date());
  const [selectedClassEndDate, setSelectedClassEndDate] = useState<Date>(new Date());
  const [isInvalidClassStartDate, setIsInvalidClassStartDate] = useState<boolean>(false);
  const [isInvalidClassEndDate, setIsInvalidClassEndDate] = useState<boolean>(false);
  const [isDistrictUser] = useState<boolean>(
    props.userContext.districtId ? true : false
  );
  const [classes, setClasses] = useState<Array<ITeacherClass>>([]);
  const [classSubjects, setClassSubjects] = useState<Array<any>>([]);
  const [classStudents, setClassStudents] = useState<Array<any>>([]);
  const [selectedClass, setSelectedClass] = useState<ISelectElement>({
    label: "Select Class",
    value: "0",
  });
  const [selectedSubject, setSelectedSubject] =
    useState<ISelectElement | null>();
  const [selectedActivityType, setSelectedActivityType] =
    useState<ISelectElement | null>();
  const [selectedStudent, setSelectedStudent] =
    useState<ISelectElement | null>();
  const [gradeBookByAssignments, setGradeBookByAssignments] = useState<any>();
  const [showGradeBookByAssignments, setShowGradeBookByAssignments] =
    useState<boolean>(true);
  const [gradeBookByClassResults, setGradeBookByClassResults] = useState<any>();
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [isLoadingClasses, setIsLoadingClasses] = useState<boolean>(false);
  const [display, setDisplay] = useState<ISelectElement>(displayOptions[0]);
  const [isAssignedRemediationFilter, setIsAssignedRemediationFilter] =
    useState<boolean>(false);
  const [isRetryScoresFilter, setIsRetryScoresFilter] =
    useState<boolean>(false);
  const [isInvalidDate, setIsInvalidDate] = useState<boolean>(false);
  const [minDate, setMinDate] = useState<Date>(new Date());
  const [maxDate, setMaxDate] = useState<Date>(new Date());

  const onReset = () => {
    setShowGradeBookByAssignments(false);
    setViewBy(viewByOptions[0]);
    setSelectedStartDate(minDate);
    setSelectedEndDate(maxDate);
    setDisplay(displayOptions[0]);
    setIsAssignedRemediationFilter(false);
    setIsRetryScoresFilter(false);
    setGradeBookByAssignments(null);
  };

  const onResetClassResults = () => {
    setSelectedStudent(null);
    setSelectedSubject(null);
    setSelectedActivityType(null);
    setSelectedClassStartDate(minDate);
    setSelectedClassEndDate(maxDate);
    setIsInvalidDate(false);
    setPageNumber(DEFAULT_PAGE_NUMBER);
  };

  const getClasses = async () => {
    setIsLoadingClasses(true);
    const response = getAllClassesBySubject(
      props.userContext.userId,
      props.userContext.roleId,
      null,
      props.profile?.currentSchoolYear?.schoolYearId,

      props.userContext.schoolId
    );
    response
      ?.then((d) => {
        setClasses(d.data.data);
        const selectedClass = d.data.data.filter(
          (c) => c.classId.toString() === classId.toString()
        )[0];

        if (selectedClass) {
          setSelectedClass({
            value: selectedClass.classId.toString(),
            label: selectedClass.name,
          });
          getSubjects(selectedClass.classId);
          getStudents(selectedClass.classId);
        }
        if (classId > 0) {
          getGradeBookByAssignments();
        }
      })
      .catch(() => {})
      .finally(() => {
        setIsLoadingClasses(false);
      });
  };

  const handleClassChange = (classId: number) => {
    getSubjects(classId);
    getStudents(classId);
    getHasDiagnosticsAvailable(classId);
  };

  const handleSortRecords = (
    _data: any,
    columnName: string,
    sortType: SortingOrder
  ) => {
    setActiveSortColumn(columnName);
    setOrderType(sortType);
  };

  const getSubjects = (classId: number) => {
    setClassSubjects([]);
    ClassApi.getClassSubject(classId, pageNumber, pageSize).then((d) => {
      if (d.data.data?.length > 0) {
        let subjects = [{ value: "", label: "All Subjects" }];
        subjects = subjects.concat(
          d.data.data.map((s) => {
            return {
              label: s.name,
              value: s.subjectId.toString(),
            };
          })
        );
        setClassSubjects(subjects);
      }
    });
  };

  const getStudents = (classId: number) => {
    setClassStudents([]);
    ClassApi.getClassStudent(classId, pageNumber, pageSize).then((d) => {
      let students = [{ value: "", label: "All Students" }];
      students = students.concat(
        d.data.data.map((student) => {
          return {
            value: student.userId.toString(),
            label: student.lastName + ", " + student.firstName,
          };
        })
      );
      setClassStudents(students);
    });
  };

  const getHasDiagnosticsAvailable = async (classId: number) => {
    if (classId > 0) {
      ClassApi.hasDiagnosticsAvailable(classId).then((resp) => {
        setHasDiagnosticsAvailable(resp.data);
      });
    } else setHasDiagnosticsAvailable(false);
  };

  const getGradeBookByAssignments = () => {
    setShowLoader(true);
    setShowGradeBookByAssignments(true);
    setGradeBookByAssignments(null);
    getGradeBookDataByAssignments(
      parseInt(
        selectedClass?.value && selectedClass.value !== "0"
          ? selectedClass.value
          : classId.toString()
      ),
      selectedStartDate !== null ? getStartDate(selectedStartDate) : null,
      selectedEndDate !== null ? getEndDate(selectedEndDate) : null,
      isAssignedRemediationFilter,
      isRetryScoresFilter
    )
      .then((res) => {
        processingAssignments(res.data.data);
        setShowLoader(false);
      })
      .catch(() => {
        setShowLoader(false);
      });
  };

  const exportGradeBookDataByAssignment = () => {
    setShowLoader(true);
    let className = selectedClass?.label ? selectedClass?.label : null;
    let exportClassId = parseInt(
      selectedClass?.value ? selectedClass?.value : classId.toString()
    );
    let startDate =
      selectedStartDate !== null ? getStartDate(selectedStartDate) : null;
    let endDate = selectedEndDate !== null ? getEndDate(selectedEndDate) : null;
    let useRemediation = isAssignedRemediationFilter;
    let viewByValue = parseInt(viewBy.value);

    exportGradeBookDataByAssignmentsExcel(
      exportClassId,
      className,
      startDate,
      endDate,
      useRemediation,
      viewByValue,
      isRetryScoresFilter
    )
      .then((response) => {
        startFileDownload(
          response,
          "Gradebook Assignments - " + className + ".xlsx"
        );
        setShowLoader(false);
      })
      .catch(() => {
        toast.error(
          "Export request failed. If the request takes a long time, consider adding filters to decrease the report size.",
          { autoClose: 0 }
        );
        setShowLoader(false);
      });
  };

  const exportGradeBookDataByClass = () => {
    setShowLoader(true);
    let className = selectedClass?.label ? selectedClass?.label : null;
    let districtId = props.userContext.districtId;
    let exportClassId = selectedClass?.value
      ? selectedClass?.value
      : classId.toString();
    let subjectId = selectedSubject?.value ? selectedSubject?.value : "";
    let startDate =
      selectedClassStartDate !== null
        ? getStartDate(selectedClassStartDate)
        : null;
    let endDate =
      selectedClassEndDate !== null ? getEndDate(selectedClassEndDate) : null;
    let studentId = selectedStudent?.value ? selectedStudent?.value : "";
    let activityType = selectedActivityType?.value
      ? selectedActivityType?.value
      : "";
    exportGradeBookDataByClassResultsExcel(
      districtId,
      exportClassId,
      subjectId,
      startDate,
      endDate,
      studentId,
      activityType,
      className,
      selectedActivityType?.value === "RETRY" ? true : false
    )
      .then((response) => {
        startFileDownload(response, "Class Results - " + className + ".xlsx");
        setShowLoader(false);
      })
      .catch(() => {
        toast.error(
          "Export request failed. If the request takes a long time, consider adding filters to decrease the report size.",
          { autoClose: 0 }
        );
        setShowLoader(false);
      });
  };

  const startFileDownload = (
    response: AxiosResponse<any>,
    fileName: string
  ) => {
    const file = response.data;
    const fileURL = URL.createObjectURL(file);
    let link = document.createElement("a");
    link.href = fileURL;
    link.download = fileName;
    link.setAttribute("target", "_blank");
    document.body.appendChild(link);
    link.click();
    setShowLoader(false);
  };

  const roundOffScore = (
    totalPointsPossible: number,
    totalPointsEarned: number
  ) => {
    if (!totalPointsEarned) return 0;
    return Math.round((totalPointsEarned / totalPointsPossible) * 100);
  };

  const calculateGroupAssignmentAverage = (student, assignment) => {
    let score: number = 0;
    let totalGroupAssignmentScore: number = 0;
    let activityCount: number = 0;
    assignment.activities.map((activity) => {
      const activityIndex = activity.index;
      const isCompleted =
        student.completionStatus[activityIndex] > 0 &&
        student.questionsUngraded[activityIndex] === 0;

      let totalPointsPossible = 0;
      let totalPointsEarned = 0;
      if (isCompleted) {
        activityCount += 1;
        totalPointsPossible = student.pointsPossible[activityIndex];
        totalPointsEarned = student.pointsEarned[activityIndex];
      }

      if (totalPointsPossible > 0) {
        score = roundOffScore(totalPointsPossible, totalPointsEarned);
      } else {
        score = 0;
      }
      totalGroupAssignmentScore += score;
    });

    return totalGroupAssignmentScore > 0
      ? totalGroupAssignmentScore / activityCount
      : 0;
  };
  const calculateRetryGroupAssignmentAverage = (student, assignment) => {
    let score: number = 0;
    let totalGroupAssignmentScore: number = 0;
    let activityCount: number = 0;
    assignment.activities.map((activity) => {
      const activityIndex = activity.index;
      const isCompleted =
        student.completionStatus[activityIndex] > 0 &&
        student.questionsUngraded[activityIndex] === 0;
      let totalPointsPossible = 0;
      let totalPointsEarned = 0;
      if (isCompleted) {
        activityCount += 1;
        totalPointsPossible = student.pointsPossible[activityIndex];
        if (student.retryPointsEarned[activityIndex] !== null) {
          totalPointsEarned += student.correctPointsEarned[activityIndex];
        }
      }
      if (totalPointsPossible > 0) {
        score = roundOffScore(totalPointsPossible, totalPointsEarned);
      } else {
        score = 0;
      }
      totalGroupAssignmentScore += score;
    });

    return totalGroupAssignmentScore > 0
      ? totalGroupAssignmentScore / activityCount
      : 0;
  };

  const processingAssignments = (data) => {
    setShowLoader(true);
    let index = 0;
    let visibility: any = [];
    let averageScore: any = [];
    data?.assignments?.forEach((assignment) => {
      let assignmentChild = 0;
      assignment["index"] = index;
      assignment["isCollapse"] = true;
      visibility.push(true);
      index = index + 1;
      let totalGroupAssignmentAverageScore = 0;
      let studentCount = 0;

      assignment.activities?.forEach((activity, activityIndex) => {
        activity["index"] = index;
        activity["isCollapse"] = true;
        assignmentChild = assignmentChild + 1;
        visibility.push(false);
        index = index + 1;

        //find avg score
        let studentScore = 0;
        let totalStudents = 0;

        data.students.map((student, index) => {
          student["showResultReport"] = false;
          //add objects
          if (!student.hasOwnProperty("groupAssignmentAverage")) {
            student["groupAssignmentAverage"] = [];
          }
          if (!student.hasOwnProperty("totalPointsPossible")) {
            student["totalPointsPossible"] = 0;
            student["totalPointsEarned"] = 0;
          }
          if (!student.hasOwnProperty("totalScore")) {
            student["totalScore"] = 0;
            student["totalAssignmentCompleted"] = 0;
          }
          //add objects

          if (
            student["groupAssignmentAverage"].filter(
              (a) => a.index === assignment.index
            ).length === 0 &&
            student.isAssigned[assignment.index]
          ) {
            const score = isRetryScoresFilter
              ? calculateRetryGroupAssignmentAverage(student, assignment)
              : calculateGroupAssignmentAverage(student, assignment);
            if (student.questionsAttempted[assignment.index] > 0) {
              studentCount += 1;
            }

            student["groupAssignmentAverage"].push({
              index: assignment.index,
              score: score,
            });

            totalGroupAssignmentAverageScore += score;
          }

          if (
            averageScore.filter((a) => a.index === assignment.index).length ===
            0
          ) {
            if (student.activitiesCompleted[assignment.index] > 0) {
              student["showResultReport"] = true;

              //assignment avg
              if (
                assignment?.activities.length === 1 &&
                student.activitiesCompleted[activity.index] > 0 &&
                student.questionsUngraded[activity.index] === 0
              ) {
                if (isRetryScoresFilter) {
                  if (student.retryPointsEarned[assignment.index] !== null) {
                    student["totalPointsPossible"] +=
                      student.pointsPossible[assignment.index];
                    student["totalPointsEarned"] +=
                      student.correctPointsEarned[assignment.index];
                    student["totalScore"] += parseInt(
                      roundOffScore(
                        student.pointsPossible[assignment.index],
                        student.correctPointsEarned[assignment.index]
                      ).toFixed()
                    );
                    student["totalAssignmentCompleted"] += 1;
                  }
                } else {
                  student["totalPointsPossible"] +=
                    student.pointsPossible[assignment.index];
                  student["totalPointsEarned"] +=
                    student.pointsEarned[assignment.index];
                  student["totalScore"] += parseInt(
                    roundOffScore(
                      student.pointsPossible[assignment.index],
                      student.pointsEarned[assignment.index]
                    ).toFixed()
                  );
                  student["totalAssignmentCompleted"] += 1;
                }
              }
            }
          }

          if (
            assignment.activities.length > 1 &&
            student.activitiesCompleted[activity.index] > 0 &&
            student.questionsUngraded[activity.index] === 0
          ) {
            if (isRetryScoresFilter) {
              if (student.retryPointsEarned[activity.index] !== null) {
                studentScore =
                  studentScore +
                  roundOffScore(
                    student.pointsPossible[activity.index],
                    student.correctPointsEarned[activity.index]
                  );

                totalStudents += 1;
                student["totalPointsPossible"] +=
                  student.pointsPossible[activity.index];
                student["totalPointsEarned"] +=
                  student.correctPointsEarned[activity.index];
                student["totalScore"] += parseInt(
                  roundOffScore(
                    student.pointsPossible[activity.index],
                    student.correctPointsEarned[activity.index]
                  ).toFixed()
                );
                student["totalAssignmentCompleted"] += 1;
              }
            } else {
              studentScore =
                studentScore +
                roundOffScore(
                  student.pointsPossible[activity.index],
                  student.pointsEarned[activity.index]
                );
              totalStudents += 1;
              student["totalPointsPossible"] +=
                student.pointsPossible[activity.index];
              student["totalPointsEarned"] +=
                student.pointsEarned[activity.index];
              student["totalScore"] += parseInt(
                roundOffScore(
                  student.pointsPossible[activity.index],
                  student.pointsEarned[activity.index]
                ).toFixed()
              );
              student["totalAssignmentCompleted"] += 1;
            }
          }

          return null;
        });

        //start:inserting to averageScore object
        //for group assignment
        if (
          averageScore.filter((a) => a.index === assignment.index).length === 0
        ) {
          averageScore.push({
            index: assignment.index,
            score: (studentCount > 0
              ? Math.round(totalGroupAssignmentAverageScore / studentCount)
              : 0
            ).toFixed(),
          });
        }
        //for single assignment
        if (
          averageScore.filter((a) => a.index === activity.index).length === 0
        ) {
          averageScore.push({
            index: activity.index,
            score:
              totalStudents > 0
                ? Math.round(studentScore / totalStudents).toFixed()
                : 0,
          });
        }

        //end:inserting to averageScore object
      });
      assignment["childCount"] = assignmentChild;
    });

    if (data) {
      data.averageScore = averageScore;
      data.visibility = visibility;
    }
    setShowLoader(false);
    setGradeBookByAssignments(data);
  };

  const getGradeBookByClassResults = () => {
    setShowLoader(true);
    getGradeBookDataByClassResults(
      props.userContext.districtId,
      selectedClass?.value ? selectedClass?.value : "",
      selectedSubject?.value ? selectedSubject?.value : "",
      selectedClassStartDate !== null
        ? getStartDate(selectedClassStartDate)
        : null,
      selectedClassEndDate !== null ? getEndDate(selectedClassEndDate) : null,
      selectedStudent?.value ? selectedStudent?.value : "",
      selectedActivityType?.value && selectedActivityType?.value !== "RETRY"
        ? selectedActivityType?.value
        : "",
      pageNumber,
      pageSize,
      orderType,
      activeSortColumn,
      selectedActivityType?.value === "RETRY" ? true : false
    )
      .then((res) => {
        setGradeBookByClassResults(res.data.data);
        setShowLoader(false);
      })
      .catch(() => {
        setShowLoader(false);
      });
  };
  const onPageChange = (pageNo) => {
    setPageNumber(pageNo);
  };

  const onPageSizeChange = (pageSize) => {
    setPageNumber(DEFAULT_PAGE_NUMBER);
    setPageSize(pageSize);
    setResetPageNumber(!resetPageNumber);
  };
  useEffect(() => {
    getClasses();

    const schoolStartDate = getMinSchoolYearDate(props.profile.currentSchoolYear);
    const schoolEndDate = getMaxSchoolYearDate(props.profile.currentSchoolYear);

    setMinDate(schoolStartDate);
    setMaxDate(schoolEndDate);

    setSelectedStartDate(schoolStartDate);
    setSelectedEndDate(schoolEndDate);

    setSelectedClassStartDate(schoolStartDate);
    setSelectedClassEndDate(schoolEndDate);
  }, []);

  useEffect(() => {
    handleClassChange(
      parseInt(selectedClass?.value ? selectedClass?.value : "0")
    );
  }, [selectedClass]);

  useEffect(() => {
    setPageNumber(DEFAULT_PAGE_NUMBER);
    setResetPageNumber(!resetPageNumber);
  }, [orderType, activeSortColumn]);

  useEffect(() => {
    if (selectedClass && selectedClass.value !== "0")
      getGradeBookByClassResults();
  }, [pageSize, pageNumber, resetPageNumber]);

  useEffect(() => {
    if (display.value === "1") {
      setIsAssignedRemediationFilter(true);
      setIsRetryScoresFilter(false);
    } else if (display.value === "2") {
      setIsRetryScoresFilter(true);
      setIsAssignedRemediationFilter(false);
    } else {
      setIsAssignedRemediationFilter(false);
      setIsRetryScoresFilter(false);
    }
  }, [display]);

  useEffect(() => {
    if (!hasDiagnosticsAvailable) {
      if (selectedActivityType?.value === ACTIVITY_TYPE_DIAGNOSTICS)
        setSelectedActivityType(null);
    }
  }, [hasDiagnosticsAvailable]);

  const breadcrumbItems = () => {
    const items: Array<IBreadcrumb> = [
      { name: t("Breadcrumb.Home"), url: RouteConstant.ROUTE_DASHBOARD },
      {
        name: t("Breadcrumb.Reports"),
        url: RouteConstant.REPORTS,
      },
      { name: t("Breadcrumb.GradeBook"), url: "" },
    ];

    return items;
  };

  function handleViewByChange(option: ISelectElement | null) {
    if (option) {
      setViewBy(option);
    }
  }

  function handleDisplayChange(option: ISelectElement | null) {
    if (option) {
      setDisplay(option);
      setGradeBookByAssignments(null);
      setShowGradeBookByAssignments(false);
    }
  }

  return (
    <div className="min-w-0 w-full">
      {(showLoader || isLoadingClasses) && <Loader></Loader>}

      <div className="lg:flex lg:items-center px-4 py-3 lg:px-8 border-b">
        <Breadcrumb items={breadcrumbItems()} />
        <div className="lg:w-[55%] text-lg font-medium flex gap-2 h-auto sm:h-10 items-center">
          <h1 className="text-primary-violet font-medium flex justify-start mb-0 pb-0">
            Gradebook
          </h1>
        </div>
      </div>
      <div className="px-2 sm:px-6  lg:px-8 py-1 relative my-4">
        <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-4 mb-4 mt-4">
          <div className="flex flex-col">
            <label className="block mb-1 text-sm text-gray-500">
              Switch Class <Asterisk />
            </label>
            <ClassDropDown
              schoolYearId={props.profile.currentSchoolYear.schoolYearId}
              defaultLabel="Select Class"
              isMultiSelect={false}
              onChange={(item) => setSelectedClass(item[0])}
              value={selectedClass}
              data={classes}
              isDisabled={isLoadingClasses}
              showArchived={true}
              showHidden={true}
              colorStyle="white"
            ></ClassDropDown>
          </div>
        </div>
        <div className="bg-gray-100 w-full rounded-lg p-5">
          <div className="bg-white px-5 py-5">
            <div className="flex bg-gray-100 px-3 pt-2 flex-col lg:flex-row lg:justify-between">
              <div className="flex lg:mr-2 xl:mr-0">
                <nav className="flex space-x-2" aria-label="Tabs">
                  {tabs.map((tab) => (
                    <button
                      key={uuid.v4()}
                      onClick={() => {
                        setSelectedTab(tab.id);
                      }}
                      className={classNames(
                        tab.id === selectedTab
                          ? "border-secondary-teal bg-white text-primary-violet hover:text-dark-violet py-3 tab-box-shadow tab-box-shadow"
                          : "text-primary-violet text-opacity-60 bg-primary-violet/10 py-[0.6rem] mt-auto transition-all hover:py-3 hover:text-opacity-100 hover:text-primary-violet border-[#dee9f1]",
                        "px-2 sm:px-4 text-center border-t-2 text-sm cursor-pointer font-medium"
                      )}
                    >
                      {tab.name}
                    </button>
                  ))}
                </nav>
              </div>
            </div>

            {selectedTab === 0 && (
              <div className="flex flex-col">
                <InfoMessage
                  message="View activities that have been assigned to your class. You can select a specific assignment to open a new window to display the results screen for the activity, expand group activities and select students to view student details including 
                  the progress report, student graded work and usage."
                />
                <div className="grid md:grid-cols-2 gap-x-8 mb-4">
                  <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-3 gap-4 mb-4 mt-4 order-last md:order-1">
                    {isDistrictUser && (
                      <div className="flex flex-col">
                        <label className="block mb-1 text-sm text-gray-500">
                          View By
                        </label>

                        <Select
                          menuPortalTarget={document.body}
                          value={viewBy}
                          options={viewByOptions}
                          onChange={handleViewByChange}
                          className=""
                          theme={(theme) => ({
                            ...theme,
                            colors: {
                              ...theme.colors,
                              primary: "#008fbb",
                            },
                          })}
                          styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                          }}
                        ></Select>
                      </div>
                    )}
                    <div className="flex flex-col">
                      <label className="block mb-1 text-sm text-gray-500">
                        Display
                      </label>

                      <Select
                        menuPortalTarget={document.body}
                        value={display}
                        options={displayOptions}
                        onChange={handleDisplayChange}
                        className=""
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary: "#008fbb",
                          },
                        })}
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                      ></Select>
                    </div>
                    <div className="flex flex-col">
                      <label className="block mb-1 text-sm text-gray-500">
                        From Date
                      </label>

                      <DatePicker
                        selected={selectedStartDate}
                        minDate={minDate}
                        maxDate={maxDate}
                        onChange={(e) => {
                          if(!e) {
                            setIsInvalidStartDate(true);
                            setSelectedStartDate(e);
                            return;
                          }
                          if (selectedEndDate && e > selectedEndDate) {
                            setIsInvalidStartDate(true);
                          } else {
                            setIsInvalidStartDate(false);
                          }
                          setSelectedStartDate(e);
                        }}
                        className="react-datepicker__input-container py-1.5 px-3 block w-full shadow-sm focus:ring-primary-violet focus:border-primary-violet border border-gray-300 rounded "
                      />
                      {isInvalidStartDate && (
                        <span className="pl-2 text-red-600">
                          {" "}
                          {"Invalid Date"}
                        </span>
                      )}
                    </div>

                    <div className="flex flex-col">
                      <label className="block mb-1 text-sm text-gray-500">
                        To Date
                      </label>

                      <DatePicker
                        selected={selectedEndDate}
                        minDate={minDate}
                        maxDate={maxDate}
                        onChange={(e) => {
                          if(!e) {
                            setIsInvalidEndDate(true);
                            setSelectedEndDate(e);
                            return;
                          }
                          if (selectedStartDate && e < selectedStartDate) {
                            setIsInvalidEndDate(true);
                          } else {
                            setIsInvalidEndDate(false);
                          }
                          setSelectedEndDate(e);
                        }}
                        className="react-datepicker__input-container py-1.5 px-3 block w-full shadow-sm focus:ring-primary-violet focus:border-primary-violet border border-gray-300 rounded "
                      />
                      {isInvalidEndDate && (
                        <span className="pl-2 text-red-600">
                          {" "}
                          {"Invalid Date"}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="grid order-1 md:order-last">
                    <div className="flex flex-col justify-end col-span-2 bg-gray-50 p-4 mb-auto lg:mt-auto ml-auto border">
                      <ul className="text-gray-500 text-sm grid gap-y-1">
                        <li className="flex">
                          <span className="text-pink-500 bg-white w-14 flex-none mb-auto mr-2 border inline-block text-center">
                            Pink
                          </span>
                          Completed past due date
                        </li>
                        <li className="flex">
                          <span className="bg-white text-blue-500 w-14 flex-none mb-auto mr-2 border inline-block text-center">
                            Blue
                          </span>
                          Hyperlink to student results
                        </li>
                        <li className="flex">
                          <span className="text-gray-700 w-14 flex-none mb-auto mr-2 bg-white border inline-block text-center">
                            N/A
                          </span>
                          Not assigned
                        </li>
                        <li className="flex">
                          <span className="text-gray-700 w-14 flex-none mb-auto mr-2 bg-white border inline-block text-center">
                            NG
                          </span>
                          Not graded (Requires Manual Grading)
                        </li>
                        <li className="flex">
                          <span className="text-gray-700 w-14 flex-none mb-auto mr-2 bg-white border inline-block text-center">
                            —
                          </span>
                          Assigned not completed
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="flex gap-4 justify-end">
                  <button
                    onClick={onReset}
                    className="bg-transparent border my-auto border-primary-violet shadow-sm py-2 px-4 flex justify-center text-sm font-medium text-primary-violet hover:text-white hover:bg-light-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3 transition ease-in-out duration-500"
                  >
                    Reset
                  </button>
                  <div className="cursor-not-allowed">
                    <button
                      onClick={getGradeBookByAssignments}
                      className={
                        "bg-primary-violet border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 " +
                        (selectedClass === undefined ||
                        Number(selectedClass.value) === 0 ||
                        isInvalidDate
                          ? "bg-opacity-20 text-primary-violet text-opacity-40 pointer-events-none"
                          : "hover:bg-dark-violet hover:shadow-lg")
                      }
                    >
                      Run Report
                    </button>
                  </div>
                  <div className="cursor-not-allowed">
                    <ExportControl
                      controlType={"button"}
                      controlText={"Export to Excel"}
                      userContext={props.userContext}
                      permissionName={permissions.reports_export_upgrade}
                      className={
                        "bg-primary-violet border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 " +
                        (selectedClass != null &&
                          !isInvalidDate &&
                          gradeBookByAssignments &&
                          gradeBookByAssignments.students.length > 0 &&
                          (!checkIfUpgradeRequired(
                            permissions.reports_export_upgrade
                          )
                            ? "hover:bg-dark-violet hover:shadow-lg"
                            : "bg-opacity-20 text-primary-violet text-opacity-40 "))
                      }
                      onClick={exportGradeBookDataByAssignment}
                    ></ExportControl>
                  </div>
                </div>
                <div className="border-b border-gray-400 py-2 mb-2"></div>
                <CallOutMessage message="Your assignment results will show the student's best result. You can access details on each attempt by searching in the Class Results tab of your Gradebook." />
                {showGradeBookByAssignments && (
                  <>
                    {gradeBookByAssignments && (
                      <div className="border-b border-gray-400">
                        <GradeBookByAssignment
                          classId={selectedClass?.value}
                          data={gradeBookByAssignments}
                          viewBy={parseInt(viewBy.value)}
                          isAssignedRemediationFilter={
                            isAssignedRemediationFilter
                          }
                          isRetryScoresFilter={isRetryScoresFilter}
                          userRoleId={props.userContext.roleId}
                          userId={props.userContext.userId}
                        ></GradeBookByAssignment>
                      </div>
                    )}
                    {gradeBookByAssignments === null && (
                      <>
                        <div className="flex py-4">
                          <span className="text-sm">No records found</span>
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>
            )}

            {selectedTab === 1 && (
              <div className="mt-5">
                <InfoMessage message="Class Results is an all inclusive view of all of the activities completed by the students in your class including assigned tasks and independent practice." />
                <div className="grid gap-x-8 mb-4">
                  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 2xl:grid-cols-6 gap-4 mb-4 mt-4 order-last md:order-1">
                    <div className="flex flex-col">
                      <label className="block mb-1 text-sm text-gray-500">
                        Student
                      </label>

                      <Select
                        value={selectedStudent}
                        options={classStudents}
                        isSearchable={true}
                        onChange={setSelectedStudent}
                        className="w-full mt-1 "
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary: "#008fbb",
                          },
                        })}
                      ></Select>
                    </div>

                    <div className="flex flex-col">
                      <label className="block mb-1 text-sm text-gray-500">
                        Activity
                      </label>

                      <Select
                        value={selectedActivityType}
                        options={activityTypes}
                        onChange={setSelectedActivityType}
                        filterOption={(option) =>
                          option.value !== ACTIVITY_TYPE_DIAGNOSTICS ||
                          hasDiagnosticsAvailable
                        }
                        className="mt-1 "
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary: "#008fbb",
                          },
                        })}
                      ></Select>
                    </div>
                    <div className="flex flex-col">
                      <label className="block mb-1 text-sm text-gray-500">
                        Subject
                      </label>

                      <Select
                        value={selectedSubject}
                        options={classSubjects}
                        isSearchable={true}
                        onChange={setSelectedSubject}
                        className="mt-1"
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary: "#008fbb",
                          },
                        })}
                      ></Select>
                    </div>
                    <div className="flex flex-col">
                      <label className="block mb-1 text-sm text-gray-500">
                        From Date
                      </label>

                      <DatePicker
                        selected={selectedClassStartDate}
                        minDate={minDate}
                        maxDate={maxDate}
                        onChange={(e) => {
                          if(!e) {
                            setIsInvalidClassStartDate(true);
                            setSelectedClassStartDate(e);
                            return;
                          }
                          if (selectedClassEndDate && e > selectedClassEndDate) {
                            setIsInvalidClassStartDate(true);
                          } else {
                            setIsInvalidClassStartDate(false);
                          }
                          setSelectedClassStartDate(e);
                        }}
                        className="react-datepicker__input-container py-1.5 px-3 block w-full shadow-sm focus:ring-primary-violet focus:border-primary-violet border border-gray-300 rounded "
                      />
                      {isInvalidClassStartDate && (
                          <span className="pl-2 text-red-600">Invalid Date</span>
                      )}
                    </div>

                    <div className="flex flex-col">
                      <label className="block mb-1 text-sm text-gray-500">
                        To Date
                      </label>

                      <DatePicker
                        selected={selectedClassEndDate}
                        minDate={minDate}
                        maxDate={maxDate}
                        onChange={(e) => {
                          if(!e) {
                            setIsInvalidClassEndDate(true);
                            setSelectedClassEndDate(e);
                            return;
                          }
                          if (selectedClassStartDate && e < selectedClassStartDate) {
                            setIsInvalidClassEndDate(true);
                          } else {
                            setIsInvalidClassEndDate(false);
                          }
                          setSelectedClassEndDate(e);
                        }}
                        className="react-datepicker__input-container py-1.5 px-3 block w-full shadow-sm focus:ring-primary-violet focus:border-primary-violet border border-gray-300 rounded "
                      />
                      {isInvalidClassEndDate && (
                          <span className="pl-2 text-red-600">Invalid Date</span>
                      )}
                    </div>
                  </div>
                </div>

                <div className="flex gap-4 justify-end">
                  <button
                    onClick={onResetClassResults}
                    className="bg-transparent border my-auto border-primary-violet shadow-sm py-2 px-4 flex justify-center text-sm font-medium text-primary-violet hover:text-white hover:bg-light-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3 transition ease-in-out duration-500"
                  >
                    Reset
                  </button>
                  <div className="cursor-not-allowed">
                    <button
                      onClick={getGradeBookByClassResults}
                      className={
                        "bg-primary-violet border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 " +
                        (selectedClass === undefined
                          ? "bg-opacity-20 text-primary-violet text-opacity-40 pointer-events-none"
                          : "hover:bg-dark-violet hover:shadow-lg")
                      }
                    >
                      Run Report
                    </button>
                  </div>
                  <div className="cursor-not-allowed">
                    <ExportControl
                      controlType={"button"}
                      controlText={"Export to Excel"}
                      userContext={props.userContext}
                      permissionName={permissions.reports_export_upgrade}
                      className={
                        "bg-primary-violet border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 " +
                        (selectedClass != null &&
                        gradeBookByClassResults &&
                        gradeBookByClassResults?.data?.length > 0 &&
                        !checkIfUpgradeRequired(
                          permissions.reports_export_upgrade
                        )
                          ? "hover:bg-dark-violet hover:shadow-lg"
                          : "bg-opacity-20 text-primary-violet text-opacity-40 ")
                      }
                      onClick={exportGradeBookDataByClass}
                    ></ExportControl>
                  </div>
                </div>
                <div className="border-b border-gray-400 py-2 mb-2"></div>
                <div className="">
                  {showLoader && <Loader></Loader>}
                  {gradeBookByClassResults && (
                    <GradeBookByClassResult
                      data={gradeBookByClassResults}
                      pageNumber={pageNumber}
                      pageSize={pageSize}
                      setPageNumber={setPageNumber}
                      resetPageNumber={resetPageNumber}
                      onPageSizeChange={onPageSizeChange}
                      onPageChange={onPageChange}
                      handleSortRecords={handleSortRecords}
                      activeSortColumn={activeSortColumn}
                      orderType={orderType}
                      userRoleId={props.userContext.roleId}
                      userId={props.userContext.userId}
                    ></GradeBookByClassResult>
                  )}
                </div>
              </div>
            )}

            <AllowedTo perform={permissions.reports_liftoff_activity_view}>
              <div className={"mt-5 " + (selectedTab !== 2 ? " hidden " : "")}>
                <InfoMessage message="View the individual activities completed by students within Liftoff including scores and details." />
                <div className="">
                  {showLoader && <Loader></Loader>}
                  <LiftoffActivityReport
                    hideBreadcrumb={true}
                    hideClassDropdown={true}
                    hideGradeDropdown={true}
                    disableButtons={selectedClass === undefined ? true : false}
                    classId={selectedClass?.value}
                    source={"gradebook"}
                  ></LiftoffActivityReport>
                </div>
              </div>
            </AllowedTo>
          </div>
        </div>
      </div>
    </div>
  );
}

export default connect((state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
})(GradeBook);

import { useEffect, useState } from 'react'
import Profile from '../../../../../model/users/profile'
import { UserContextState } from '../../../../../redux/actions/userContextAction'
import InfoMessage from '../../../../shared/infoMessage'
import { SchoolYearDropDown } from '../../../schoolYearDropdown'
import { ISchoolYear } from '../../../../../model/teacher/schoolYear'
import Subject from '../../../../shared/dropdowns/subject'
import { ISelectElement } from '../../../../../model/interface/selectElement'
import Asterisk from '../../../../shared/asterisk'
import Select from 'react-select'
import Moment from 'moment'
import { getEndDate, getStartDate } from '../../../../../utils/dateHelper'
import { IAssignment } from '../../../../../model/interface/assignment'
import { getAssignments } from '../../../../../api/teacher/assignment'
import { IAssignmentSearch } from '../../../../../model/interface/assignmentSearch'
import { AssignmentType } from '../../../../../types/type'
import { getActivityName } from '../../../../../utils/assignmentHelper'
import {isAllowViewAssignmentAssessment} from "../../../../../utils/assessmentHelper";
import { useLocation } from 'react-router-dom'
import constant from '../../../../../utils/constant/constant'
import { IAssignmentActivity } from '../../../../../model/interface/assignmentActivity'


interface IResultsReportAssigments {
  userContext: UserContextState
  profile: Profile
  match?: any
  location: any,
  schoolYearId: number,
  setSchoolYearId: (schoolYearId: number) => void,
  selectedSubjectId: number,
  redirectedFrom: string,
  toggleLoader: (value: boolean) => void,
  onAssignmentSelected: (assignmentId: number,  schoolYear: number, assignmentActivityId?: number, assessmentId?: number, hasCRActivity?: boolean) => void,
  onReset: () => void
  onRunReport: () => void,
  toggleShowResults: (value: boolean) => void
}

type IFoundAssignment = {
  assignment: IAssignment;
  activity?: IAssignmentActivity;
  selectedElement: ISelectCustomElement;
};

type ISelectCustomElement = {
  label: string;
  value: string;
  name: string;
  assignmentId: number | undefined;
  assignmentActivityId?: number;
  assessmentId?: number;
  hasCRActivity?: boolean;
  activities?: IAssignmentActivity[];
};

const ResultsReportAssigments = (props: IResultsReportAssigments) => {
  //** Constants **//
  const { userId } = props.userContext
  const defaultValue = { label: 'Select', value: '0' }  
  const orderByColumn = 'Name';
  
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const [selectedSchoolYearId, setSelectedSchoolYearId] = useState<number>(props.schoolYearId)
  const [resetSchoolYear, setResetSchoolYear] = useState<boolean>(false)
  const [schoolYearData, setSchoolYearData] = useState<Array<ISchoolYear>>()
  const [selectedSubject, setSelectedSubject] = useState<ISelectElement>()
  
  const myAssigmentsText = 'My Assignments';
  const sharedAssigmentsText = 'Shared Class Assignments';
  const assignedRemediationText = 'Assigned Remediation';

  useState<ISelectCustomElement>()
  const [selectedAssignment, setSelectedAssignment] = useState<ISelectCustomElement | undefined>();
  const [assignments, setAssignments] = useState<Array<ISelectCustomElement>>()  
  const [myAssigmentsData, setMyAssigmentsData] = useState<Array<IAssignment>>([])
  const [sharedAssignmentsData, setsharedAssignmentsData] = useState<Array<IAssignment>>([])
  const [remediationData, setRemediationData] = useState<Array<IAssignment>>([])
  const [startDate, setStartDate] = useState<any>()
  const [endDate, setEndDate] = useState<any>()
  const [selectedAssignmentType, setSelectedAssignmentType] =
    useState<string>('My Assignments')

  const MyAssignmentType = 'MYASSIGNMENT';
  const SharedAssignmentType = 'CLASSASSIGNMENT';
  const RemediationAssignmentType = 'REMEDIATIONASSIGNMENT';

  //** Hooks **//

  useEffect(() => {
    if (props.redirectedFrom === constant.RedirectedFrom.MYASSIGNMENTS) {
      const type = getAssignmentTypeFromUrl();
      if (type) {
        setSelectedAssignmentType(type);
      }
    }

    if (props.schoolYearId) {
      handleDateFilter(props.schoolYearId);
    }
  }, []);

  useEffect(() => {
    if (selectedSchoolYearId && selectedSubject && selectedSubject.value !== '0' && selectedSubject.value !== '' && startDate && endDate) {
      setSelectedAssignment(undefined);
      searchAssignments(MyAssignmentType);
      searchAssignments(SharedAssignmentType);
      searchAssignments(RemediationAssignmentType);
    }
  }, [selectedSubject, startDate, endDate]); 

  useEffect(() => {
    setSelectedAssignment(undefined);
    if (selectedAssignmentType === myAssigmentsText) {
      setAssignments(filterAssignments(myAssigmentsData));
    } else if (selectedAssignmentType === sharedAssigmentsText) {
      setAssignments(filterAssignments(sharedAssignmentsData));
    } else if (selectedAssignmentType === assignedRemediationText) {
      setAssignments(filterAssignments(remediationData));
    }
  }, [selectedAssignmentType, myAssigmentsData, sharedAssignmentsData, remediationData]);

  useEffect(() => {
    const assignmentIdParam = query.get('assignmentId');
      if (assignmentIdParam) {
        const assignment = findAssignmentInDataSets(Number(assignmentIdParam));
        if (assignment) {
          handleAssignmentSelection(assignment.selectedElement);
          props.toggleShowResults(true);
        }
      }
  }, [myAssigmentsData, sharedAssignmentsData, remediationData]);

  //** Handlers **/

  const findAssignmentInDataSets = (assignmentId: number): IFoundAssignment | undefined => {
    const activityIdParam = query.get('activityId');
    const activityId = activityIdParam ? Number(activityIdParam) : undefined;    
  
    const searchInDataSet = (dataSet: IAssignment[], assignmentType: string): IFoundAssignment | undefined => {
      const foundAssignment = dataSet.find((a) => a.assignmentId === assignmentId);
  
      if (foundAssignment) {
        if (activityId) {
          const foundActivity = foundAssignment.activities?.find((activity) => activity.assignmentActivityId === activityId);
          if (foundActivity) {
            setSelectedAssignmentType(assignmentType);
            return {
              assignment: foundAssignment,
              activity: foundActivity,
              selectedElement: buildCustomElement(foundAssignment, foundActivity),
            };
          }
        }
  
        setSelectedAssignmentType(assignmentType);
        return {
          assignment: foundAssignment,
          selectedElement: {
            value: foundAssignment.assignmentId.toString(),
            label: foundAssignment.name,
            name: foundAssignment.name,
            assignmentId: foundAssignment.assignmentId,
            assessmentId: foundAssignment.assignmentActivity?.assessmentId
              ? parseInt(foundAssignment.assignmentActivity?.assessmentId)
              : undefined,
            assignmentActivityId: foundAssignment.assignmentActivity?.assignmentActivityId,
            hasCRActivity: foundAssignment.assignmentActivity?.isAssessmentWithCR || foundAssignment.unGraded !== null,
          },
        };
      }
  
      return undefined;
    };
  
    let assignment = searchInDataSet(myAssigmentsData, myAssigmentsText);
    if (assignment) return assignment;
  
    assignment = searchInDataSet(sharedAssignmentsData, sharedAssigmentsText);
    if (assignment) return assignment;
  
    assignment = searchInDataSet(remediationData, assignedRemediationText);
    if (assignment) return assignment;
  
    return undefined;
  };

  const getAssignmentTypeFromUrl = () => {
    let assignmentType = query.get('assignmentType');
    if (assignmentType) {
      // Now, we need to check from where it has been redirected
      if (assignmentType === constant.AssignmentType.MYASSIGNMENT) {
        return myAssigmentsText
      } 
      else if (assignmentType === constant.AssignmentType.CLASSASSIGNMENT) {
        return sharedAssigmentsText
      }
      else if (
        assignmentType === constant.AssignmentType.REMEDIATIONASSIGNMENT
      ) {
        return assignedRemediationText
      } else {
        return myAssigmentsText
      }
    }
  }

  const handleSchoolYearLoaded = (currentSchoolYearId?: number) => {
    props.toggleShowResults(false);
    handleSchoolYearChange(currentSchoolYearId)
  }

  const handleSchoolYearChange = (schoolYearId?: number) => {
    props.toggleShowResults(false);
    setSelectedSchoolYearId(schoolYearId ?? 0)
    props.setSchoolYearId(schoolYearId ?? 0);
  }

  const handleSchoolYearData = (schoolYearData: Array<ISchoolYear>) => {
    props.toggleShowResults(false);
    setSchoolYearData(schoolYearData)
  }

  const handleSubjectChange = (selectedItem: any) => {
    props.toggleShowResults(false);
    setSelectedSubject(selectedItem);
  }

  const handleAssignmentSelection = (selectedItem: ISelectCustomElement) => {
    props.toggleShowResults(false);
    setSelectedAssignment(selectedItem);
    props.onAssignmentSelected(selectedItem.assignmentId!, selectedSchoolYearId!, selectedItem.assignmentActivityId,
       selectedItem.assessmentId, selectedItem.hasCRActivity);
  }

  const handleDateFilter = (schoolYearId?: number) => {
    let selectedSchoolYear = schoolYearData?.filter(
      (s) => s.schoolYearId === schoolYearId
    )[0]
    let minDate = selectedSchoolYear
      ? Moment(
          selectedSchoolYear.startDate.toString().split('T')[0],
          'YYYY-MM-DD'
        ).toDate()
      : new Date()

    let maxDate = selectedSchoolYear
      ? Moment(
          selectedSchoolYear.startDate.toString().split('T')[0],
          'YYYY-MM-DD'
        )
          .add(1, 'year')
          .subtract(1, 'day')
          .toDate()
      : new Date()

    setStartDate(getStartDate(minDate))
    setEndDate(getEndDate(maxDate))
  }  

  const filterAssignments = (assignments: IAssignment[]) => {
    let filteredAssignments: ISelectCustomElement[] = []

    if (assignments && assignments.length > 0) {
      if (selectedAssignmentType === myAssigmentsText) {
        filteredAssignments = getAssignmentSelectItems(assignments, true)
      } else if (selectedAssignmentType === sharedAssigmentsText) {
        filteredAssignments = getAssignmentSelectItems(assignments, true)
      } else if (selectedAssignmentType === assignedRemediationText) {
        filteredAssignments = getAssignmentSelectItems(assignments)
      }
  
      return filteredAssignments;
    }
    else return []
  }

  const getAssignmentSelectItems = (
    assignments: IAssignment[],
    includeActivities: boolean = true
  ): ISelectCustomElement[] => {
    return assignments
      .sort((a, b) => a.name.localeCompare(b.name))
      .flatMap((assignment): ISelectCustomElement[] => {
        if (includeActivities && assignment.activities && assignment.activities.length > 1) {
          // For grouped assignments, create a separate element for each activity
          return assignment.activities.filter(f => f.completed && f.completed > 0).map(activity => buildCustomElement(assignment, activity));
        } else {
          // For non-grouped assignments or when activities are not included
          if ((assignment.assignmentActivity?.completed ?? 0 > 0) && isAllowViewAssignmentAssessment(
              props.userContext.roleId,
              assignment.assignmentActivity
                  ?.canViewAssessment,
              assignment.assignmentActivity?.activityType,
              assignment.assignmentActivity
                  ?.assessmentCreatedByUserId,
              props.userContext.userId,
              assignment.assignmentActivity
                  ?.assessmentSharedTypeId
          ))
          {
              return [{
                value: assignment.assignmentId.toString(),
                label: assignment.name,
                name: assignment.name,
                assessmentId: assignment.assignmentActivity?.assessmentId ? parseInt(assignment.assignmentActivity?.assessmentId) : undefined,
                assignmentId: assignment.assignmentId,
                assignmentActivityId: assignment.assignmentActivity?.assignmentActivityId,
                hasCRActivity: assignment.assignmentActivity?.isAssessmentWithCR || assignment.unGraded !== null,
                activities: []
              }];
          }
          else return [];
        }
      });
  };

  const buildCustomElement = (assignment: IAssignment, activity: IAssignmentActivity): ISelectCustomElement => {    
    return {
      value: activity.assignmentActivityId.toString(),
            label: `${assignment.name} - ${getActivityName(activity)}`,
            name: `${assignment.name} - ${getActivityName(activity)}`,
            assignmentId: assignment.assignmentId,
            assessmentId: activity?.assessmentId ? parseInt(activity?.assessmentId) : undefined,
            assignmentActivityId: activity.assignmentActivityId,
            hasCRActivity: activity.isAssessmentWithCR || activity.unGraded !== null,
            activities: assignment.activities
    }
  }

  const searchAssignments = (assignmentType: AssignmentType) => {

    const assignmentSearch: IAssignmentSearch = {
      teacherId: userId,
      studentId: undefined,
      classId: undefined,
      subjectId:
        selectedSubject && selectedSubject.value !== "0"
          ? parseInt(selectedSubject.value)
          : undefined,
      isAssigned: undefined,
      dateType: undefined,
      fromDate: startDate,
      toDate: endDate,
      isArchived: false,
      name: "",
      assignmentType: assignmentType,
      pageNumber: 1,
      pageSize: 1000,
      orderByColumn: orderByColumn,
      sortByDesc: false,
      schoolYearId: selectedSchoolYearId,
      usePrimaryDataSource: false,
      includeActivitiesInformation: true
    };
    props.toggleLoader(true);
    getAssignments(assignmentSearch).then((r) => {
      props.toggleLoader(false);
      let assignmentData = r.data.data as IAssignment[];      
      if (assignmentType === MyAssignmentType) setMyAssigmentsData(assignmentData);
      else if (assignmentType === SharedAssignmentType) setsharedAssignmentsData(assignmentData);
      else if (assignmentType === RemediationAssignmentType) setRemediationData(assignmentData);      
    });
  }  

  const onReset = () => {
    setSelectedSubject(defaultValue)
    setSelectedAssignment(undefined)
    setAssignments([])
    setMyAssigmentsData([])
    setsharedAssignmentsData([])
    setRemediationData([])
    setSelectedAssignmentType(myAssigmentsText)
    setResetSchoolYear(true);    
    props.onReset();
  }

  const runReport = () => {
    props.onRunReport()
  }

  return (
    <>      
      <div className='mt-5'>
        <InfoMessage
          message='Selecting to view by assignment will allow you to see all results for all students to which the activity has been assigned. 
                        This includes aggregated data and the ability to drill down to individual students. Remediation for assessments and practice test activities is available on the Student Dot Rank tab after a class has been selected from the filter.'
        />
        <div>
          <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 2xl:grid-cols-6 gap-4 mb-4 mt-4 order-last md:order-1'>
            <div className='flex flex-col'>
              <label className='block mb-1 text-sm text-gray-500'>
                School Year <Asterisk />
              </label>
              <SchoolYearDropDown
                reset={resetSchoolYear}
                offsets={[0, -1]}
                handleSchoolYearChange={handleSchoolYearChange}
                handleSchoolYearLoaded={handleSchoolYearLoaded}
                handleSchoolYearData={handleSchoolYearData}
                sortByGreatestFirst={true}
                schoolYearId={selectedSchoolYearId}
              ></SchoolYearDropDown>
            </div>
            <div className='flex flex-col'>
              <Subject
                setSelectedSubject={(items) => handleSubjectChange(items[0])}
                selectedItems={selectedSubject}
                isMulti={false}
                isRequired={true}
                themeColor={'#008fbb'}
                subjectId={props.selectedSubjectId}
              ></Subject>
            </div>
            <div className='flex flex-col'>
              <label className='block mb-1 text-sm text-gray-500'>
                Assignment Type <Asterisk />
              </label>
              <Select
                value={{
                  label: selectedAssignmentType,
                  value: selectedAssignmentType
                }}
                options={[
                  {
                    label: myAssigmentsText,
                    value: myAssigmentsText
                  },
                  {
                    label: sharedAssigmentsText,
                    value: sharedAssigmentsText
                  },
                  {
                    label: assignedRemediationText,
                    value: assignedRemediationText
                  }
                ]}
                onChange={(e) => setSelectedAssignmentType(e?.value!)}
                isMulti={false}
                closeMenuOnSelect={true}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: '#008fbb'
                  }
                })}
              />
            </div>
            <div className='flex flex-col col-span-2'>
              <label className='block mb-1 text-sm text-gray-500'>
                Assignments <Asterisk />
              </label>
              <Select
                value={selectedAssignment || null}
                options={assignments}
                onChange={(e) =>
                  handleAssignmentSelection(e as ISelectCustomElement)
                }
                isMulti={false}
                closeMenuOnSelect={true}
                className={
                  selectedSubject === undefined
                    ? 'pointer-events-none text-gray-400 bg-gray'
                    : ''
                }
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: '#008fbb'
                  }
                })}
              />
            </div>
          </div>
          <div className='flex gap-4 justify-end'>
            <button
              onClick={onReset}
              className='bg-transparent border my-auto border-primary-violet shadow-sm py-2 px-4 flex justify-center text-sm font-medium text-primary-violet hover:text-white hover:bg-light-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3 transition ease-in-out duration-500'
            >
              Reset
            </button>
            <div className='cursor-not-allowed'>
              <button
                onClick={runReport}
                className={
                  'bg-primary-violet border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ' +
                  (selectedSubject === undefined ||
                  !selectedAssignment ||
                  (selectedSubject?.value ?? '0') === '0'
                    ? 'bg-opacity-20 text-primary-violet text-opacity-40 pointer-events-none'
                    : 'hover:bg-dark-violet hover:shadow-lg')
                }
              >
                Run Report
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ResultsReportAssigments;
import { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { UserContextState } from "../../../../../redux/actions/userContextAction";
import Constant from "../../../../../utils/constant/constant";
import {
  completeStudyPlan,
  getStudyPlanLastSession,
  initializeStudyPlan,
  //updateStudyPlan,
  abandonStudyPlan,
} from "../../../../../api/student/studyPlanApi";
import { IInitalizeStudyPlanResponse } from "../../../../../model/student/studyplan/initializeStudyPlanRespons";
import QuestionAnswer from "../../shared/questionAnswer";
import Profile from "../../../../../model/users/profile";
import BeginActivity from "../../shared/beginActivity";
import { useHistory } from "react-router-dom";
import RouteConstant from "../../../../../utils/constant/routeConstant";
import routeConstant from "../../../../../utils/constant/routeConstant";
import Breadcrumb from "../../middleSchool/breadcrumb";
import { IBreadcrumb } from "../../../../../model/common/breadcrumb";
import { useTranslation } from "react-i18next";
import {
  getStudentClasses,
  getStudentTeacher,
} from "../../../../../api/student/studentApi";
import { getCalculatorBySubjectId } from "../../../../../api/student/studentSubjectApi";
import {
  GetIsQuestionRetryAllowed,
  GetShowExplanation,
  GetShowOnlyTextExpalanation,
} from "../../../../../utils/helper";
import AlienRankingCard from "../../../../../model/student/shared/alienRanking/alienRankingCard";
import VideoPopup from "../../shared/popups/videoPopup";
import constant from "../../../../../utils/constant/constant";
import MessagePopup from "../../../../shared/messagePopup";
import { paramDecode } from "../../../../../utils/urlHelper";
import { IsActivitySessionQuestionAttempted } from "../../../../../api/student/activitySession/activitySessionQuestions";
import NetworkErrorMessagePopup from "../../shared/popups/networkErrorMessagePopup";
import QuestionAlreadyAttemptedPopup from "../../shared/popups/questionAlreadyAttemptedPopup";
interface IStudyPlanPractice {
  userContext: UserContextState;
  profile: Profile;
  match: any;
}
function StudyPlanPracticeMS(props: IStudyPlanPractice) {
  const { t } = useTranslation();

  const [studyPlan, setStudyPlan] = useState<IInitalizeStudyPlanResponse>();
  const standardId = parseInt(atob(props.match.params.standardId));
  const standardName = paramDecode(props.match.params.standardName);
  const gameId: number = parseInt(atob(props.match.params.gameId));
  const rocketId: number = parseInt(atob(props.match.params.rocketId));
  const blasterId: number = parseInt(atob(props.match.params.blasterId));
  const subjectId = parseInt(atob(props.match.params.subjectId));
  const blasterType = atob(props.match.params.blasterType);
  const isRecommendedStandard =
    props.match.params.isRecommendedStandard &&
    props.match.params.isRecommendedStandard !== ":isRecommendedStandard"
      ? atob(props.match.params.isRecommendedStandard) === "true"
        ? true
        : false
      : false;

  const [lastSession, setLastSession] = useState<any>({});
  const history = useHistory();
  const [beginActivityModal, setBeginActivityModal] = useState<any>({
    open: false,
    data: undefined,
  });
  const [calculatorReferenceId, setCalculatorReferenceId] = useState<string>();
  const [isQuestionRetry, setQuestionRetry] = useState<boolean>(false);
  const [showExpalanation, setShowExplanation] = useState<boolean>(false);
  const [showOnlyTextExpalanation, setOnlyShowExplanation] =
    useState<boolean>(false);
  const [selectedLanguage, setSelectedLanguage] = useState<number>(1);
  const [showVideoPopup, setShowVideoPopup] = useState(false);
  const [currenRankingCard, setCurrentRankingCard] =
    useState<AlienRankingCard>();
  const [errorMessagePopup, setErrorMessagePopup] = useState<boolean>(false);
  const [showQuestionAlreadyAttempted, setShowQuestionAlreadyAttempted] =
    useState<boolean>(false);
  const [showNetworkErrorPopup, setShowNetworkErrorPopup] = useState<boolean>(false);
  const [contentAreaId, setContentAreaId] = useState<number>(0);
  useEffect(() => {
    initialize();
    if (subjectId > 0) {
      getCalculatorBySubjectId(subjectId).then((res) => {
        if (res.data) setCalculatorReferenceId(res.data);
      }).catch(() => {setShowNetworkErrorPopup(true);});
    }
    setQuestionRetry(
      GetIsQuestionRetryAllowed(
        props.userContext?.gradeId,
        props.userContext?.stateId,
        Constant.ActivitySubTypes.STUDYPLANPRACTICEQUESTIONS,
        undefined
      )
    );
    setShowExplanation(
      GetShowExplanation(
        props.userContext?.gradeId,
        props.userContext?.stateId,
        Constant.ActivitySubTypes.STUDYPLANPRACTICEQUESTIONS,
        undefined,
        undefined,
        undefined
      )
    );
    setOnlyShowExplanation(
      GetShowOnlyTextExpalanation(
        props.userContext?.gradeId,
        props.userContext?.stateId,
        Constant.ActivitySubTypes.STUDYPLANPRACTICEQUESTIONS
      )
    );
  }, []);

  function initialize() {
    var classesResponseApi = getStudentClasses(
      props.userContext.userId,
      subjectId,
      0
    );
    var teacherResponseApi = getStudentTeacher(
      props.userContext.userId,
      subjectId,
      0
    );

    var lastSessionApi = getStudyPlanLastSession(
      props.userContext.userId,
      standardId,
      0
    );

    Promise.all([classesResponseApi, teacherResponseApi, lastSessionApi]).then(
      ([classesResponse, teachersResponse, lastSessionResponse]) => {
        setLastSession(lastSessionResponse.data);

        var data: any = {
          classes: classesResponse.data,
          teachers: teachersResponse.data,
          classId:
            lastSessionResponse.data !== null
              ? lastSessionResponse.data.classId
              : undefined,
          teacherId:
            lastSessionResponse.data !== null
              ? lastSessionResponse.data.teacherUserId
              : undefined,
          languageId: lastSessionResponse.data.languageId,
        };
        if(data.languageId == null)
          data.languageId = 0;
        setBeginActivityModal({ open: true, data });
      }
    ).catch(() => {setShowNetworkErrorPopup(true);});
  }

  function initializeTest(data: any) {
    initializeStudyPlan(
      props.userContext.userId,
      standardId,
      data.languageId,
      gameId,
      rocketId,
      blasterId,
      blasterType,
      data.classId,
      data.teacherId,
      props.userContext?.gradeId
    ).then((d) => {
      var data = d.data;
      if (
        !data.isSuccess &&
        data.code === constant.ErrorType.CREATEACTIVITYFAILED
      ) {
        setErrorMessagePopup(true);
        setBeginActivityModal({ ...beginActivityModal, open: false });
      } else {
        setStudyPlan(data);
        setBeginActivityModal({ open: false, data });
      }
    }).catch(() => {setShowNetworkErrorPopup(true);});
  }

  function closeMessagePopup() {
    setErrorMessagePopup(false);
    history.goBack();
  }

  function completeStudyPlanActivity() {
    completeStudyPlan(
      props.userContext.userId,
      studyPlan?.sessionId ?? "",
      studyPlan?.activitySessionId ?? 0
    ).then((d) => {
      if (d.data && d.data.hasAlienCardChanged === true) {
        setCurrentRankingCard(d.data.alienRankingCard);
        setShowVideoPopup(true);
      } else {
        setTimeout(() => {
          history.push(
            routeConstant.StudentRoute.StudyPlanResult.replace(
              ":sessionId",
              studyPlan ? studyPlan.sessionId : ""
            )
          );
        }, 1500);
      }
    });
  }

  useEffect(() => {
    if (!showVideoPopup && currenRankingCard) {
      history.push(
        routeConstant.StudentRoute.StudyPlanResult.replace(
          ":sessionId",
          studyPlan ? studyPlan.sessionId : ""
        )
      );
    }
  }, [showVideoPopup]);

  const breadcrumbItems = () => {
    const items: Array<IBreadcrumb> = [
      {
        name: t("Breadcrumb.Home"),
        url: RouteConstant.StudentRoute.Home,
        textColor: "text-gray-500",
      },
      {
        name: isRecommendedStandard
          ? t("Breadcrumb.StudyPlanFocusAreas")
          : t("Breadcrumb.StudyPlanStandards"),
        textColor: "text-gray-500",
        url: "",
      },
    ];

    return items;
  };

  function validateStudyPlan(questionId: any) {
    if (studyPlan !== undefined && questionId !== undefined && questionId > 0 && !isQuestionRetry) {
      IsActivitySessionQuestionAttempted(
        studyPlan.activitySessionId,
        questionId
      ).then((d) => {
        if (d.data === true) setShowQuestionAlreadyAttempted(true);
      }).catch(() => {setShowNetworkErrorPopup(true);});
    }
  }

  function onCancel () {
    history.push(`${
      RouteConstant.StudentRoute.Home
    }?tab=${constant.StudyType.MYSTUDYPLAN.toLowerCase()}&subjectId=${subjectId}`);
  }

  return (
    <Fragment>
      {errorMessagePopup && (
        <MessagePopup
          message={constant.ERRORMESSAGE}
          togglePopup={closeMessagePopup}
        />
      )}
      {showVideoPopup && currenRankingCard && (
        <VideoPopup
          videoUrl={currenRankingCard?.videoUrl ?? ""}
          showPopup={setShowVideoPopup}
        />
      )}
      <div className="min-w-0 w-full">
        <div className="px-4 py-3 lg:px-8 border-b">
          <nav className="flex mt-2 text-gray-600" aria-label="Breadcrumb">
            <Breadcrumb items={breadcrumbItems()} />
          </nav>
          <div className="text-lg flex justify-between gap-2 h-auto sm:h-10 items-center">
            <h1 className="text-gray-600 font-medium flex justify-start">
              {isRecommendedStandard
                ? t("Breadcrumb.StudyPlanFocusAreas")
                : t("Breadcrumb.StudyPlanPractice")}
            </h1>
          </div>
        </div>
        {studyPlan !== undefined && studyPlan.sessionId.length > 0 && (
          <QuestionAnswer
            activityType={Constant.ActivityType.STUDYPLAN}
            activityId={studyPlan.activityId ? studyPlan.activityId : ""}
            teacherActivityId={studyPlan.sessionId}
            showActivity={studyPlan.activityId?.length > 0 ? true : false}
            showQuestions={studyPlan.activityId?.length > 0 ? false : true}
            questionIds={
              studyPlan.itemReferences && studyPlan.itemReferences.length > 0
                ? studyPlan.itemReferences
                : []
            }
            hasSections={false}
            hasItemBranching={false}
            isAlienExplanation={true}
            isGameChoosen={lastSession?.gameId > 0 || gameId > 0}
            studentId={props.userContext.userId.toString()}
            resumeActivity={lastSession ? true : false}
            sessionId={studyPlan.sessionId}
            studentType="EL"
            title={standardName}
            adaptiveFilter={null}
            OnSubmit={completeStudyPlanActivity}
            onExitRedirectUrl={RouteConstant.StudentRoute.Home}
            activitySubType={Constant.ActivitySubTypes.PRACTICEQUESTIONS}
            calculatorReferenceId={calculatorReferenceId}
            questionRetry={isQuestionRetry}
            showExplanation={showExpalanation}
            activitySessionId={studyPlan.activitySessionId}
            showTextExpalanation={showOnlyTextExpalanation}
            language={selectedLanguage === 2 ? "es" : "en"}
            standardId={standardId.toString()}
            onUnfocused={validateStudyPlan}
            onFocused={validateStudyPlan}
            subjectId={subjectId}
            contentAreaId={contentAreaId}
            shuffleItems={studyPlan.isDisplayRandomQuestion}
          />
        )}
        {beginActivityModal.open && (
          <BeginActivity
            activity={t("StudyPlan.Questions")}
            name={standardName + " - Practice Questions"}
            noOfQuestions={10}
            source={"StudyPlan"}
            themeName={"ELEMENTARYHOME"}
            data={beginActivityModal.data}
            hideClass={true}
            hideTeacher={true}
            ActivityLanguageLevel={"UserLevel"}
            onBegin={(data: any, value: any) => {
              setContentAreaId(value.contentAreaId);
              setSelectedLanguage(value.languageId);
              if (
                lastSession === null ||
                lastSession === "" ||
                lastSession === undefined ||
                data.languageId !== value.languageId
              ) {
                abandonStudyPlan(
                  props.userContext.userId,
                  lastSession.lernositySessionId
                ).then((d) => {
                  initializeTest(value);
                }).catch(() => {setShowNetworkErrorPopup(true);});
              } else {
                setStudyPlan({
                  hasPendingSession: true,
                  sessionId: lastSession.lernositySessionId,
                  activityId: lastSession.lernosityActivityId,
                  activitySessionId: lastSession.activitySessionId,
                  itemReferences: lastSession.itemReferences,
                  learnositySessionGroupId: "",
                  isDisplayRandomQuestion: lastSession.isDisplayRandomQuestion,
                });
                //updateStudyPlanActivity(value, lastSession.lernositySessionId);
                setBeginActivityModal({ ...beginActivityModal, open: false });
              }
            }}
            onCancel={onCancel}
            subjectId={subjectId}
          />
        )}
        {showQuestionAlreadyAttempted && (<QuestionAlreadyAttemptedPopup />)}
      </div>
      {showNetworkErrorPopup && (<NetworkErrorMessagePopup/>)}
    </Fragment>
  );
}

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(StudyPlanPracticeMS);

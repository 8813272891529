import { combineReducers } from "redux";
import { userContextReducer } from "./userContextReducer";
import { UserContextState } from "../actions/userContextAction";
import * as userReducer from "./profileReducer";
import * as masterDataReducer from "./masterDataReducer";
import Profile from "../../model/users/profile";
import MasterData from "../../model/masterData/masterData";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { reducer as toastrReducer } from "react-redux-toastr";
import { encryptTransform } from "redux-persist-transform-encrypt";
import auth from "../../utils/auth";
import { ISessionValues } from "../actions/sessionValuesAction";
import { sessionValuesReducer } from "./sessionValuesReducer";
export interface State {
  userContext: UserContextState;
  profile: Profile | null;
  masterData: MasterData | null;
  toastr: any;
  sessionValues: ISessionValues;
}

const getSecretKey = () => {
  const secretKey = localStorage.getItem("AuthToken");
  return secretKey;
};

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["userContext", "profile", "masterData"],
  transforms: [
    encryptTransform({
      secretKey: `${getSecretKey}`,
      onError: function (error) {
        auth.logout();
      },
    }),
  ],
};

const rootReducerr = combineReducers<State>({
  userContext: userContextReducer,
  profile: userReducer.profileReducer,
  masterData: masterDataReducer.masterDataReducer,
  toastr: toastrReducer,
  sessionValues: sessionValuesReducer,
});

export const rootReducer = persistReducer(persistConfig, rootReducerr);

import { Fragment } from "react"
import Header from "../shared/header/header";

function ParentLetter() {
    return (
        <Fragment>
            <Header></Header>
            <div className="mt-5 mb-5 max-w-7xl mx-auto bg-primary-green lg:bg-transparent lg:px-8">
                <div className="lg:grid lg:grid-cols-12">
                    <div className="relative z-10 lg:col-start-1 lg:row-start-1 lg:col-span-4 lg:py-16 lg:bg-transparent">
                        <div className="absolute inset-x-0 h-1/2 bg-blue-gray-50 lg:hidden" aria-hidden="true" />
                        <div className="max-w-md mx-auto px-4 sm:max-w-3xl sm:px-6 lg:max-w-none lg:p-0">
                            <div className="mr-40 aspect-w-10 aspect-h-6 sm:aspect-w-2 sm:aspect-h-1 lg:aspect-w-1" style={{ paddingRight: "20px", width: "500px", height: "400px" }}>
                                <iframe title="Paren Letter" className="w-full h-full" src="https://fast.wistia.net/embed/iframe/yn6jcy1kk3?autoPlay=false"></iframe>
                            </div>
                        </div>
                    </div>

                    <div className="relative bg-primary-green lg:col-start-3 lg:row-start-1 lg:col-span-10 lg:rounded-3xl lg:grid lg:grid-cols-10 lg:items-center">
                        <div className="hidden absolute inset-0 overflow-hidden rounded-3xl lg:block" aria-hidden="true">
                            <svg
                                className="absolute bottom-full left-full transform translate-y-1/3 -translate-x-2/3 xl:bottom-auto xl:top-0 xl:translate-y-0"
                                width={404}
                                height={384}
                                fill="none"
                                viewBox="0 0 404 384"
                                aria-hidden="true"
                            >
                                <defs>
                                    <pattern
                                        id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
                                        x={0}
                                        y={0}
                                        width={20}
                                        height={20}
                                        patternUnits="userSpaceOnUse"
                                    >
                                        <rect x={0} y={0} width={4} height={4} className="text-primary-green" fill="currentColor" />
                                    </pattern>
                                </defs>
                                <rect width={404} height={384} fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)" />
                            </svg>
                            <svg
                                className="absolute top-full transform -translate-y-1/3 -translate-x-1/3 xl:-translate-y-1/2"
                                width={404}
                                height={384}
                                fill="none"
                                viewBox="0 0 404 384"
                                aria-hidden="true"
                            >
                                <defs>
                                    <pattern
                                        id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
                                        x={0}
                                        y={0}
                                        width={20}
                                        height={20}
                                        patternUnits="userSpaceOnUse"
                                    >
                                        <rect x={0} y={0} width={4} height={4} className="text-blue-500" fill="currentColor" />
                                    </pattern>
                                </defs>
                                <rect width={404} height={384} fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)" />
                            </svg>
                        </div>
                        <div className="relative max-w-md mx-auto py-12 px-4 space-y-6 sm:max-w-3xl sm:py-16 sm:px-6 lg:max-w-none lg:p-0 lg:col-start-4 lg:col-span-6">
                            <h2 className="text-3xl font-extrabold text-white" id="join-heading">
                                Tell your parents about Education Galaxy
                  </h2>
                            <p className="text-lg text-white">
                                Education Galaxy is perfect for use in the classroom or at home. Education Galaxy can be accessed anywhere there is an internet connection and on any device. Get the most out of Education Galaxy by communicating its benefits to your parents, with our parent connect app. We make it easy for you to notify parents about our resource. Simply download the parent letter below, and print or email a copy to them. We also suggest printing off two sets of the student login cards, one for school and one for home. The video below will walk you through printing out your student login cards.
                  </p>
                            <a
                                className="mr-5 block w-full py-3 px-5 text-center bg-white border border-transparent rounded-md shadow-md text-base font-medium text-primary-green hover:bg-light-green sm:inline-block sm:w-auto"
                                href="documents/Parent Letter.pdf"
                                download
                            >
                                Download Parent Letter
                  </a>
                            <a
                                className="block w-full py-3 px-5 text-center bg-white border border-transparent rounded-md shadow-md text-base font-medium text-primary-green hover:bg-light-green sm:inline-block sm:w-auto"
                                href="documents/Parent Letter Spanish.pdf"
                                download
                            >
                                Download Spanish Parent Letter
                  </a>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default ParentLetter
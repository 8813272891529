import axios from "axios";
import { Configuration } from "../environment/setup";
import auth from "../utils/auth";
import routeConstant from "../utils/constant/routeConstant";

const RefreshToken_Expired_Error = "refreshtoken_expired";

export const axiosInstance = axios.create({
  baseURL: `${Configuration.ApiBaseUrl}`,
  headers: {
    common: {
      "x-requested-with": "XMLHttpRequest",
      Authorization: "Bearer " + auth.getAuthToken(),
    },
  },
});
export class AxiosHelper {
  public static axiosInstance() {
    const axiosInstance = axios.create({
      baseURL: Configuration.ApiBaseUrl,
      headers: {
        Authorization: "Bearer " + auth.getAuthToken(),
        "Content-Type": "application/json",
      },
    });

    axiosInstance.interceptors.request.use(
      function (request) {
        if (auth.isTokenExpired()) {
          auth.logout();
          return Promise.reject("Token has expired");
        }
        return request;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    axiosInstance.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        const errorCode = parseInt(error.response && error.response.status);
        if (errorCode === 403) {
          window.location.replace(routeConstant.FORBIDDEN);
          return Promise.reject(error.message);
        }

        if (
          errorCode === 401 &&
          error.response?.data?.message?.toLowerCase() ===
            RefreshToken_Expired_Error.toLowerCase()
        ) {
          window.location.replace(
            routeConstant.GOOGLE_UNAUTHORIZED +
              "?redirectUrl=" +
              encodeURIComponent(window.location.pathname)
          );
        } else if (errorCode === 401) {
          auth.logout();
        }

        return Promise.reject(error);
      }
    );

    return axiosInstance;
  }
}

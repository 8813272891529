import { connect } from "react-redux";
import {
  CancelIcon,
  ChatIcon,
  LiftOffIcon,
  MessageIcon,
  ParentConnectIcon,
  GoogleClassroomIcon,
  CanvasIcon,
  LogoPLIcon,
  OutlinePersonIcon,
} from "../../../../../assets/icons";
import { useTranslation } from "react-i18next";
import * as userContextAction from "../../../../../redux/actions/userContextAction";
import ProfileModel from "../../../../../model/users/profile";
import { fetchProfile } from "../../../../../redux/actions/userActions";
import auth from "../../../../../utils/auth";
import { FirebaseConfiguration } from "../../../../../environment/setup";

import { Fragment, useEffect, useState } from "react";
import ParentConnectPopup from "../../../../screen/student/elementary/popup/parentConnect";

import { MessageApi } from "../../../../../api/message/messageApi";
import { useCollectionData } from "react-firebase-hooks/firestore";

import JoinClassPopUp from "../../../../screen/student/shared/popups/joinClassPopup";
import ChatRoom from "../../../../screen/chat/chatRoom";
import DefaultUserIcon from "../../../../../assets/images/userIcon.png";
import StudentSchoolCode from "../../../header/studentSchoolCode";
import RouteConstant from "../../../../../utils/constant/routeConstant";
import Constant from "../../../../../utils/constant/constant";
import { useHistory, useLocation } from "react-router-dom";
import { Menu, Transition } from "@headlessui/react";
import classNames from "classnames";
import { handleRedirection } from "../../../../../utils/helper";
import UpgradeButtonControl from "../../../customButtonControl";

interface HeaderTopProps {
  showParentConnectPopUp: (show: boolean) => void;
  onProgramModeChange: (mode: string) => void;
  userContext: userContextAction.UserContextState;
  profile?: ProfileModel;
}

function HeaderTop(props?: HeaderTopProps) {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const [isOpneChat, SetIsOpenChat] = useState(false);
  const [showParentConnect, setShowParentConnect] = useState(false);
  const [recentMessageCount, setRecentMessageCount] = useState(0);
  const chatQuery: any = MessageApi.getRecentChatRef().where(
    "userId",
    "==",
    props?.userContext?.userId
  );
  const [recentChat]: any = useCollectionData(chatQuery);
  const [openJoinClassPopUp, setOpenJoinClassPopUp] = useState(false);
  const [chatRoomProps, setChatProps] = useState<any>({});
  const [toggleMenu, settoggleMenu] = useState(false);
  const [programMode, setProgramMode] = useState(
    localStorage.getItem("programMode")
      ? localStorage.getItem("programMode")
      : Constant.ProgramMode.EG
  );

  const closeChatWindow = () => {
    SetIsOpenChat(false);
  };

  const logout = () => {
    auth.logout();
  };

  useEffect(() => {
    MessageApi.handleOnActive(
      props?.userContext?.userId!,
      props?.userContext?.schoolId!
    );
  }, []);

  useEffect(() => {
    if (props?.profile?.firstName && props?.profile?.lastName) {
      setChatProps({
        userId: props?.userContext?.userId,
        configuration: FirebaseConfiguration,
        messagecollectionName: "Messages",
        recentChatcollectionName: "RecentChat",
        presenceCollection: props?.userContext?.schoolId + "/online-users",
        roleId: props?.userContext?.roleId,
        districtId: props?.userContext?.districtId,
        token: auth.getAuthToken(),
        displayName: props?.profile?.firstName + " " + props?.profile?.lastName,
        schoolId: props?.userContext?.schoolId,
        impersonatedUser: props?.userContext?.impersonatedUser,
        closeChatWindow(): void {
          closeChatWindow();
        },
      });
    }
  }, [props?.profile?.firstName, props?.profile?.lastName]);

  useEffect(() => {
    if (!props?.profile?.isUpgradeRequired) setMessageCount();
    if (!props?.profile?.isUpgradeRequired) setMessageCount();
  }, [recentChat]);

  const setMessageCount = () => {
    const count =
      recentChat &&
      recentChat.length > 0 &&
      recentChat[0].chatTo &&
      recentChat[0].chatTo
        .map((c) => {
          return c.recentMassageCount;
        })
        .reduce((a, b) => a + b);
    setRecentMessageCount(count);
  };

  const onJoinClassCancelClick = () => {
    setOpenJoinClassPopUp(false);
  };

  const onJoinClassOkClick = () => {
    setOpenJoinClassPopUp(false);
  };

  const handleNaviagation = (link: string, programMode?: string) => {
    if (programMode) {
      localStorage.setItem("programMode", programMode);
      setProgramMode(programMode);
    }

    if (props?.onProgramModeChange !== undefined && programMode) {
      props.onProgramModeChange(programMode);
    }

    if (programMode === Constant.ProgramMode.LO) {
      history.push(link);
    } else {
      handleRedirection(
        props?.userContext.roleId!,
        props?.userContext.gradeId,
        "",
        history
      );
    }
  };

  function openProfileModal() {
    const url =
      props?.userContext.gradeId! >= Constant.Grade.GRADE6
        ? RouteConstant.MiddleSchool.Profile
        : RouteConstant.StudentRoute.Profile;
    history.push({
      pathname: url,
    });
  }

  const gradeLevel =
    location.pathname.toLowerCase().indexOf("kindergarten") > -1
      ? "kindergarten"
      : location.pathname.toLowerCase().indexOf("elementary") > -1
      ? "elementary"
      : location.pathname.toLowerCase().indexOf("middleschool") > -1
      ? "middleschool"
      : "elementary";

  return (
    <header className="bg-[#fff] md:bg-transparent mb-8 md:mb-0 shadow-xl md:shadow-none">
      <div>
        <nav className="">
          <div className="hidden md:block mx-auto px-4">
            <div className="flex items-center justify-between h-18">
              <div className="flex items-center">
                {(gradeLevel === "elementary" ||
                  gradeLevel === "middleschool") && (
                  <>
                    {programMode === Constant.ProgramMode.LO && (
                      <>
                        <button
                          onClick={() => {
                            handleNaviagation(
                              gradeLevel === "elementary"
                                ? RouteConstant.LiftOff.Elementary.Home
                                : RouteConstant.LiftOff.MiddleSchool.Home,
                              Constant.ProgramMode.LO
                            );
                          }}
                          className="filter mr-5"
                        >
                          <LiftOffIcon
                            title="Liftoff"
                            className="block lg:block h-20 w-auto"
                          ></LiftOffIcon>
                        </button>
                        <button
                          className="filter grayscale"
                          onClick={() => {
                            handleNaviagation(
                              RouteConstant.StudentRoute.Home,
                              Constant.ProgramMode.EG
                            );
                          }}
                        >
                          <LogoPLIcon
                            title="Progress Learning logo"
                            className="block lg:block h-12 w-auto"
                          ></LogoPLIcon>
                        </button>
                      </>
                    )}
                    {programMode !== Constant.ProgramMode.LO && (
                      <>
                        <button
                          onClick={() =>
                            handleNaviagation(
                              RouteConstant.StudentRoute.Home,
                              Constant.ProgramMode.EG
                            )
                          }
                          className="mr-5"
                        >
                          <>
                            <LogoPLIcon
                              title="Progress Learning"
                              className="block lg:block h-12 w-auto"
                            ></LogoPLIcon>
                          </>
                        </button>

                        {props?.profile?.isLOActive && (
                          <button
                            onClick={() =>
                              handleNaviagation(
                                gradeLevel === "elementary"
                                  ? RouteConstant.LiftOff.Elementary.Home
                                  : RouteConstant.LiftOff.MiddleSchool.Home,
                                Constant.ProgramMode.LO
                              )
                            }
                            className="filter grayscale"
                          >
                            <LiftOffIcon
                              title="Liftoff"
                              className="block lg:block h-20 w-auto"
                            ></LiftOffIcon>
                          </button>
                        )}
                      </>
                    )}
                  </>
                )}
              </div>
              <div>
                <StudentSchoolCode theme="ELEMENTARY"></StudentSchoolCode>
              </div>
              <div className="sm:ml-6 sm:block">
                <div className="flex items-center my-auto gap-x-5">
                  <div className="flex space-x-4">
                    <UpgradeButtonControl
                      controlType={"span"}
                      userContext={props?.userContext}
                      isUpgradeRequired={props?.profile?.isUpgradeRequired}
                      isRoleCheck={true}
                      className={
                        "bg-white/70 text-gray-600 px-3 py-1.5 rounded-full text-sm  flex items-center space-x-2 " +
                        (props?.profile?.isUpgradeRequired
                          ? "cursor-not-allowed bg-opacity-20 "
                          : "cursor-pointer hover:bg-white hover:text hover:text-gray-800 ")
                      }
                      onClick={() => {
                        SetIsOpenChat(true);
                      }}
                    >
                      <span className="relative">
                        {recentMessageCount > 0 && (
                          <>
                            <span className=" animate-ping w-4 h-4 inline-block absolute -top-2 -left-1 flex items-center justify-center   rounded-full bg-yellow-500 "></span>
                            <span className="w-4 h-4 inline-block absolute -top-2 -left-1 flex items-center justify-center text-[0.6rem] rounded-full bg-yellow-500 text-white font-semibold">
                              {recentMessageCount}
                            </span>
                          </>
                        )}
                        <ChatIcon
                          aria-hidden="true"
                          className="flex-none group-hover:text-gray-500 w-5 h-5 text-[#ee5914]"
                          title="Student Messaging"
                        ></ChatIcon>
                      </span>
                      <span> {t("Shared.StudentHeader.TeacherChat")} </span>
                    </UpgradeButtonControl>
                    <span
                      className="hidden cursor-pointer text-gray-600 px-3 py-2 rounded-md text-sm  flex items-center space-x-2 group hover:text-gray-800"
                      onClick={() => {
                        setOpenJoinClassPopUp(true);
                      }}
                    >
                      <ParentConnectIcon title="Join Class"></ParentConnectIcon>
                      <span> {t("JoinClass.JoinClass")} </span>
                    </span>
                  </div>
                  <div className="relative">
                    <Menu
                      as="div"
                      className="relative flex items-start sm:items-center justify-center"
                    >
                      <Menu.Button className="bg-transparent inline-flex items-start items-center justify-center text-gray-400 rounded-full hover:text-gray-500">
                        <div>
                          <button
                            type="button"
                            className="flex text-sm items-center rounded-full"
                            id="user-menu-button"
                          >
                            <span className="text-gray-700 px-2 text-xs max-w-[8rem] text-left">
                              <span className="break-words line-clamp-1">
                                {props?.profile
                                  ? props.profile.firstName +
                                    " " +
                                    props.profile.lastName
                                  : ""}
                              </span>
                              <div className="text-center bg-primary-violet text-white rounded-md text-xs">
                                {props?.profile
                                  ? props?.profile?.gradeId -
                                    1 +
                                    (props?.profile?.gradeId === 3
                                      ? "nd"
                                      : props?.profile?.gradeId === 4
                                      ? "rd"
                                      : "th") +
                                    " Grade"
                                  : ""}
                              </div>
                            </span>
                            <span className="rounded-full p-2 bg-white/70 hover:bg-white">
                              <img
                                className="h-5 w-auto rounded filter brightness-75"
                                src={DefaultUserIcon}
                                alt="Avatar"
                                title="Avatar"
                              />
                            </span>
                            {props?.userContext?.activeExternalRoster ===
                              "GOOGLE" && (
                              <a
                                href="#!"
                                className="no-underline md:text-gray-700 flex items-center hover:text-black transition-all text-xs lg:text-sm py-3 md:py-5"
                              >
                                <GoogleClassroomIcon
                                  className="h-6 mr-2"
                                  title="Google Classroom"
                                ></GoogleClassroomIcon>
                                <span className=" block md:hidden">
                                  Google Classroom
                                </span>
                              </a>
                            )}
                            {props?.userContext.activeExternalRoster ===
                              Constant.ExterRosterType.CANVAS && (
                              <a
                                href="#!"
                                className="no-underline md:text-gray-700 flex items-center hover:text-black transition-all text-xs lg:text-sm py-3 md:py-5"
                              >
                                <CanvasIcon
                                  className="h-6 mr-2"
                                  title="Canvas"
                                ></CanvasIcon>
                                <span className=" block md:hidden">Canvas</span>
                              </a>
                            )}
                          </button>
                        </div>

                        <svg
                          className="w-4 h-4 ml-[-0.6rem] mt-3"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M6.1018 8C5.02785 8 4.45387 9.2649 5.16108 10.0731L10.6829 16.3838C11.3801 17.1806 12.6197 17.1806 13.3169 16.3838L18.8388 10.0731C19.5459 9.2649 18.972 8 17.898 8H6.1018Z"
                            fill="#555"
                          />
                        </svg>
                      </Menu.Button>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="right-0 absolute top-5 w-48 mt-4 rounded-md shadow-lg z-30 bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none">
                          <div className="py-1">
                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  href="#!"
                                  onClick={openProfileModal}
                                  className={classNames(
                                    active
                                      ? "bg-gray-100 text-gray-900"
                                      : "text-gray-700",
                                    "group flex items-center px-4 py-2 text-sm"
                                  )}
                                >
                                  <OutlinePersonIcon
                                    className="w-4 h-4 mr-3"
                                    aria-hidden="true"
                                  />
                                  {t("Shared.Profile.Profile")}
                                </a>
                              )}
                            </Menu.Item>
                          </div>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                    <div className="hidden origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <span className="cursor-pointer block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                        Your Profile
                      </span>
                    </div>
                  </div>
                  <button
                    title="Logout"
                    className=" text-[#ee5914] focus:outline-none bg-white/70 hover:bg-white rounded-full px-2 py-2 text-sm"
                    onClick={() => {
                      logout();
                    }}
                  >
                    <span className="sr-only">Logout</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      fill="currentColor"
                      viewBox="0 0 16 16"
                    >
                      <path d="M7.5 1v7h1V1h-1z" />
                      <path d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z" />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            <div className="-mr-2 flex hidden">
              <button
                type="button"
                className="inline-flex items-center justify-center p-2 rounded-md text-gray-600 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
              >
                <span className="sr-only">Open main menu</span>
              </button>
            </div>
          </div>
          <div
            x-description="Mobile menu, show/hide based on menu state."
            className="md:hidden z-10 relative"
            id="mobile-menu"
            x-show="open"
          >
            <div className="px-5 pt-3 pb-2 border-b border-gray-300 flex items-center justify-between">
              <div
                className={`${
                  programMode === Constant.ProgramMode.LO
                    ? "flex flex-row-reverse gap-2"
                    : "flex flex-row gap-2"
                }
              `}
              >
                <button
                  className={`${
                    programMode === Constant.ProgramMode.EG
                      ? ""
                      : "filter grayscale"
                  }`}
                  onClick={() =>
                    handleNaviagation(
                      RouteConstant.StudentRoute.Home,
                      Constant.ProgramMode.EG
                    )
                  }
                >
                  <>
                    <LogoPLIcon
                      title="Progress Learning"
                      className="block lg:block h-10 w-auto"
                    ></LogoPLIcon>
                  </>
                  {/* )} */}
                </button>

                <button
                  className={`${
                    programMode === Constant.ProgramMode.LO
                      ? ""
                      : "filter grayscale"
                  }`}
                  onClick={() =>
                    handleNaviagation(
                      gradeLevel === "elementary"
                        ? RouteConstant.LiftOff.Elementary.Home
                        : RouteConstant.LiftOff.MiddleSchool.Home,
                      Constant.ProgramMode.LO
                    )
                  }
                >
                  <LiftOffIcon
                    title="LiftOff"
                    className="block lg:block h-20 w-auto"
                  ></LiftOffIcon>
                </button>
              </div>

              {/* Mobile View */}
              <div className="md:hidden ml-auto mr-2">
                <StudentSchoolCode theme="ELEMENTARY"></StudentSchoolCode>
              </div>
              <span
                className="bg-gray-100 border p-1 rounded-sm my-auto cursor-pointer hover:opacity-80"
                onClick={() => {
                  settoggleMenu(!toggleMenu);
                }}
              >
                {(!toggleMenu && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 text-gray-600"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                      clipRule="evenodd"
                    />
                  </svg>
                )) || (
                  <CancelIcon className="h-6 w-6 text-gray-600"></CancelIcon>
                )}
              </span>
            </div>

            <div
              className={`transform transition-all duration-1000 ease-in-out overflow-hidden
            ${toggleMenu ? "max-h-[700px]" : "max-h-[0]"}`}
            >
              <div className="px-5 pt-2 pb-3 space-y-1 bg-gray-50">
                <span className="cursor-pointer bg-primary-green text-white block px-3 py-2 rounded-md text-base font-medium">
                  Home
                </span>
                <span className="cursor-pointer text-gray-600 hover:bg-primary-green hover:text-white block px-3 py-2 rounded-md text-base font-medium">
                  My Stats
                </span>
                <span className="cursor-pointer text-gray-600 hover:bg-primary-green hover:text-white block px-3 py-2 rounded-md text-base font-medium">
                  Leaderboard
                </span>
              </div>
              <div className="pt-4 pb-3 border-t border-gray-300 bg-gray-50">
                <div className="flex items-center px-5 mb-3 flex space-x-2">
                  <div className="flex-shrink-0 bg-gray-200 w-10 p-1 sm:p-0 sm:bg-transparent">
                    <img
                      className="h-6 w-6 mx-auto shadow-xl rounded-full"
                      src={DefaultUserIcon}
                      alt=""
                    />
                  </div>
                  <div>
                    <div className="text-base font-medium text-gray-600">
                      {props?.profile
                        ? props.profile.firstName + " " + props.profile.lastName
                        : ""}
                    </div>
                  </div>
                </div>
                <div className="flex flex-col space-y-1.5">
                  <UpgradeButtonControl
                    controlType={"span"}
                    userContext={props?.userContext}
                    isUpgradeRequired={props?.profile?.isUpgradeRequired}
                    permissionName={
                      props?.profile?.isUpgradeRequired ? "upgrade" : ""
                    }
                    isRoleCheck={true}
                    className={
                      "px-5 py-2 flex space-x-2 group " +
                      (props?.profile?.isUpgradeRequired
                        ? "cursor-not-allowed bg-opacity-20 "
                        : "cursor-pointer ")
                    }
                    onClick={() => {
                      SetIsOpenChat(true);
                    }}
                  >
                    <span className="relative w-10 flex justify-center">
                      {recentMessageCount > 0 && (
                        <>
                          <span className=" animate-ping w-4 h-4 inline-block absolute -top-2 -left-1 flex items-center justify-center   rounded-full bg-yellow-500 "></span>
                          <span className="w-4 h-4 inline-block absolute -top-2 -left-1 flex items-center justify-center text-[0.6rem] rounded-full bg-yellow-500 text-white font-semibold">
                            {recentMessageCount}
                          </span>
                        </>
                      )}
                      <ChatIcon
                        aria-hidden="true"
                        className="flex-none w-6 h-6 text-gray-500 group-hover:text-gray-500"
                        title="Student Messaging"
                      ></ChatIcon>
                    </span>
                    <span className="text-base font-medium text-gray-600 group-hover:text-blue-500">
                      {t("Shared.StudentHeader.TeacherChat")}{" "}
                    </span>
                  </UpgradeButtonControl>
                  <span
                    className="cursor-pointer text-gray-600 px-5 py-2 rounded-md text-sm  flex items-center space-x-2 group"
                    onClick={() => {
                      setOpenJoinClassPopUp(true);
                    }}
                  >
                    <span className="w-10 flex justify-center">
                      <ParentConnectIcon className="flex-none w-5 h-5 text-gray-500 group-hover:text-gray-500"></ParentConnectIcon>
                    </span>
                    <span className="text-base font-medium text-gray-900 group-hover:text-blue-500">
                      {t("JoinClass.JoinClass")}{" "}
                    </span>
                  </span>
                  <span
                    className="hidden text-gray-600 px-5 py-2 rounded-md text-sm  flex items-center space-x-2 group"
                    onClick={() => {
                      setShowParentConnect(true);
                    }}
                  >
                    <span className="w-10 flex justify-center">
                      <ParentConnectIcon className="flex-none w-5 h-5 text-gray-500 group-hover:text-gray-500"></ParentConnectIcon>
                    </span>
                    <span className="text-base font-medium text-gray-600 group-hover:text-blue-500">
                      {t("Shared.StudentHeader.ParentConnect")}{" "}
                    </span>
                  </span>
                  <span className="cursor-pointer text-gray-600 px-5 py-2 rounded-md text-sm flex items-center space-x-2 group">
                    <span className="w-10 flex justify-center">
                      <MessageIcon className="flex-none w-5 h-5 text-gray-500 group-hover:text-gray-500"></MessageIcon>
                    </span>
                    <span className="text-base font-medium text-gray-600 group-hover:text-blue-500">
                      {t("Shared.StudentHeader.Messages")}{" "}
                    </span>
                  </span>
                </div>
                <div className="border-t border-gray-300 mt-3">
                  <button
                    className="flex block group space-x-2 w-full mt-1.5 px-5 py-2 rounded-md text-base font-medium text-gray-600 hover:text-white hover:bg-primary-green"
                    onClick={() => {
                      logout();
                    }}
                  >
                    <span className="w-10 flex justify-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5 group-hover:text-white"
                        fill="currentColor"
                        viewBox="0 0 16 16"
                      >
                        <path d="M7.5 1v7h1V1h-1z"></path>
                        <path d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z"></path>
                      </svg>
                    </span>
                    <span>Sign out</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
      {openJoinClassPopUp && (
        <JoinClassPopUp
          userContext={props?.userContext!}
          profile={props?.profile!}
          open={openJoinClassPopUp}
          onCancelClick={onJoinClassCancelClick}
          onOkClick={onJoinClassOkClick}
        ></JoinClassPopUp>
      )}

      {isOpneChat && <ChatRoom {...chatRoomProps}></ChatRoom>}
      {showParentConnect && (
        <ParentConnectPopup
          showPopUp={(show) => {
            setShowParentConnect(show);
          }}
        />
      )}
    </header>
  );
}

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setUserContext: (payload: userContextAction.UserContextState) => {
      dispatch(userContextAction.setUserContext(payload));
    },
    setProfile: (
      userId: number,
      roleId: number,
      schoolId: number,
      districtId: number
    ) => {
      dispatch(fetchProfile(userId, roleId, schoolId, districtId));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderTop);

import { Fragment, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Select } from "@windmill/react-ui";
import DatePicker from "react-datepicker";
import classNames from "classnames";
import { ISchool } from "../../../../model/interface/school";
import { Info } from "../../../../assets/icons/index";
import { getSchoolByDistrictId } from "../../../../api/teacher/school";
import { ClassApi } from "../../../../api/teacher/classManagementApi";
import { getStudentProfile } from "../../../../api/student/studentProfile";
import { getClassesBySchoolAccountId } from "../../../../api/manageUser/class";
import Constant from "../../../../utils/constant/constant";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import "../../../../css/style.css";
import RouteConstant from "../../../../utils/constant/routeConstant";
import Breadcrumb from "../../teacher/shared/breadcrumb";
import StudentBreadCrumb from "../../../screen/student/middleSchool/breadcrumb"; //"../../middleSchool/breadcrumb";
import { IBreadcrumb } from "../../../../model/common/breadcrumb";
import progressReport1 from "../../../../mockData/reports/progressReport.json";

import { useTranslation } from "react-i18next";
import ProgressReportByDistrict from "./tabs/progressReportByDistrict";
import ProgressReportByTeacherByCount from "./tabs/progressReportByTeacherByCount";
import ProgressReportByTeacherByStandard from "./tabs/progressReportByTeacherByStandard";
import ProgressReportByStudentByCount from "./tabs/progressReportByStudentByCount";
import ProgressReportByStudentByStandard from "./tabs/progressReportByStudentByStandard";
import ProgressReportBySchoolByCount from "./tabs/progressReportBySchoolByCount";
import ProgressReportBySchoolByStandard from "./tabs/progressReportBySchoolByStandard";
import { getAllAssessmentTags } from "../../../../api/teacher/assessment";
import { ISelectElement } from "../../../../model/interface/selectElement";
import {
  getProgressReportDataBySchool,
  getProgressReportDataByStandard,
  getProgressReportDataByStandardByStudentIds,
  getProgressReportDataByStudent,
  getProgressReportDataByTeacher,
} from "../../../../api/report/progressReport";
import { getAllClassesBySubject } from "../../../../api/teacher/teacher";
import ClassDropDown from "../shared/classDropDown";
import Loader from "../../../../../src/component/shared/loader";
import { SortingOrder } from "../../../../types/type";
import StudentProfile from "../../../../model/student/studentProfile";
import ProgressReportFilterPrint from "./print/progressReportFilterPrint";
import { useReactToPrint } from "react-to-print";
import StudentDotRankPrint from "./print/studentDotRankPrint";
import Subject from "../../../shared/dropdowns/subject";
import ExportControl from "../../../shared/customButtonControl";
import permissions from "../../../../utils/permissions";
import { getCssForDisabled } from "../../../shared/permissionHelper";
import { getEndDate, getStartDate } from "../../../../utils/dateHelper";
import { UserContextState } from "../../../../redux/actions/userContextAction";
import Profile from "../../../../model/users/profile";
import { SchoolYearDropDown } from "../../schoolYearDropdown";
import Asterisk from "../../../shared/asterisk";
import { waitForElementsToRender } from "../../../../utils/waitFor";
interface IClasses {
  classId: number;
  name: string;
}

interface IProgressReportProps {
  userContext: UserContextState;
  profile: Profile;
  match: any;
}

function ProgressReport(props: IProgressReportProps) {
  const findStudentUserId = () => {
    if (props.userContext.roleId === Constant.UserRoleId.Student)
      return props.userContext.userId;
    else return props.match.params.studentUserId;
  };

  const subjectId = props.match.params.subjectId;
  const studentUserId = findStudentUserId();
  const classId = props.match.params.classId;
  const isStudentView: boolean =
    (studentUserId != null && studentUserId > 0) ||
    props.userContext.roleId === Constant.UserRoleId.Student;

  const [schools, setSchools] = useState<ISchool[]>();
  const [classes, setClasses] = useState<IClasses[]>();
  const [studentProfile, setStudentProfile] = useState<StudentProfile>();
  const [assessmentTags, setAssessmentTags] = useState<Array<any>>();
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [selectedSchool, setSelectedSchool] = useState<number>(0);
  const [selectedClass, setSelectedClass] = useState<number>(0);
  const [selectedSubject, setSelectedSubject] = useState<number>(
    subjectId ? subjectId : 0
  );
  const [loader, setLoader] = useState(false);
  const [selectedAssessmentTag, setSelectedAssessmentTag] = useState<number>(0);
  const [selectedStartDate, setSelectedStartDate] = useState<Date | null>(null);
  const [selectedEndDate, setSelectedEndDate] = useState<Date | null>(null);

  const [includeAssignments, setIncludeAssignments] = useState(true);
  const [includeStudyPlan, setIncludeStudyPlan] = useState(true);
  const [isReportSearched, setIsReportSearched] = useState(false);
  const [noDataForStandard, setNoDataForStandard] = useState("");
  const [noDataForTeacher, setNoDataForTeacher] = useState("");
  const [noDataForStudent, setNoDataForStudent] = useState("");
  const [noDataForSchool, setNoDataForSchool] = useState("");
  const [isDistrictUser] = useState<boolean>(
    props.userContext.roleId === Constant.UserRoleId.District ? true : false
  );
  const { t } = useTranslation();
  const [selectedTeacherViewByCount, setSelectedTeacherViewByCount] =
    useState<boolean>(false);
  const [selectedStudentViewByCount, setSelectedStudentViewByCount] =
    useState<boolean>(false);
  const [selectedSchoolViewByCount, setSelectedSchoolViewByCount] =
    useState<boolean>(false);

  const [progressReportByStandard, setProgressReportByStandard] =
    useState<any>(null);
  const [progressReportByTeacher, setProgressReportByTeacher] = useState<any>();
  const [progressReportByStudent, setProgressReportByStudent] = useState<any>();
  const [progressReportBySchool, setProgressReportBySchool] = useState<any>();

  const [tabs, setTabs] = useState<any[]>([]);
  const [isInvalidStartDate, setIsInvalidStartDate] = useState<boolean>(false);
  const [isInvalidEndDate, setIsInvalidEndDate] = useState<boolean>(false);

  const [selectedClasses, setSelectedClasses] = useState<Array<ISelectElement>>(
    [{ label: "All Classes", value: "0" }]
  );

  const [orderType, setOrderType] = useState<SortingOrder>("asc");
  const [activeSortColumn, setActiveSortColumn] = useState<string>("name");
  const [
    progressReportByStandardPrintPdf,
    setProgressReportByStandardPrintPdf,
  ] = useState<Array<any>>([]);
  const [printFilter, setPrintFilter] = useState<any>(undefined);
  const componentRefSchoolDotRank = useRef<HTMLDivElement>(null);

  const componentRefStudentDotRankPrint = useRef<HTMLDivElement>(null);
  const [isPrinting, setIsPrinting] = useState<boolean>(false);


  const handleSchoolDotRankPrint = useReactToPrint({
    content: () => componentRefSchoolDotRank.current,
    onAfterPrint: () => {setIsPrinting(false)},
  });
  const handleStudentDotRankPrint = useReactToPrint({
    content: () => componentRefStudentDotRankPrint.current,
    documentTitle: "ProgressReport_StudentDotRank",
    onAfterPrint: () => {setIsPrinting(false)},
  });
  const [showStudentDotRankPrintPopup, setShowStudentDotRankPrintPopup] =
    useState(false);
  const [selectedSubjectOption, setSelectedSubjectOption] = useState<
    Array<ISelectElement>
  >([{ label: "Select Subject", value: "0" }]);
  const [selectedSchoolAccountId, setSelectedSchoolAccountId] =
    useState<number>(0);
  const studentType =
    window.location.href.toLowerCase().indexOf("kindergarten") !== -1
      ? "K1"
      : window.location.href
          .toString()
          .toLowerCase()
          .indexOf("middleschool") !== -1
      ? "MS"
      : "EL";
  const isUpgradeRequiredForStudent: boolean =
    (props.profile?.isUpgradeRequired ?? false) &&
    props.userContext.roleId == Constant.UserRoleId.Student;
  const [resetSchoolYear, setResetSchoolYear] = useState<boolean>(false);
  const [selectedSchoolYearId, setSelectedSchoolYearId] = useState<number>();
  const minDate = Constant.PLMinDate;
  const maxDate = new Date();

  useEffect(() => {
    getSchools();
    getAssessmentTags();
    if (studentUserId) {
      getStudentProfileById(studentUserId);
      runReport();
    }
    setTabs(getTabs());
  }, []);

  useEffect(() => {
    if (selectedSchoolYearId) {
      setResetSchoolYear(false);
      if (classId) {
        getClassSubjects();
      }
    }
  }, [selectedSchoolYearId]);

  useEffect(() => {
    if(isPrinting) {
      setLoader(true);
    }
    else {
      setLoader(false);
    }
  }, [isPrinting]);

  const getFirstActiveTab = () => {
    return getTabs().filter((t) => !t.isDisabled)[0]?.id;
  };

  const getTabs = () => {
    return [
      {
        id: -1,
        name: "District Dot Rank",
        visibility:
          props.userContext.roleId === Constant.UserRoleId.District &&
          selectedSchool > 0,
        activeClass:
          "border-secondary-teal bg-white text-primary-violet hover:text-dark-violet py-3 tab-box-shadow tab-box-shadow font-medium ",
        deactiveClass:
          "text-primary-violet text-opacity-60 bg-primary-violet/10 py-[0.6rem] mt-auto transition-all hover:py-3 hover:text-opacity-100 hover:text-primary-violet border-[#dee9f1] font-medium",
        disabledClass:
          "pointer-events-none bg-gray-50 text-[#afb3ba] py-[0.6rem] mt-auto border-gray-50",
        isDisabled: true,
      },
      {
        id: 0,
        name: isStudentView
          ? "Student Dot Rank"
          : props.userContext.roleId === Constant.UserRoleId.District &&
            selectedSchool === 0
          ? "District Dot Rank"
          : (props.userContext.roleId === Constant.UserRoleId.SchoolAdmin &&
              selectedClass === 0) ||
            (props.userContext.roleId === Constant.UserRoleId.District &&
              selectedSchool > 0)
          ? "School Dot Rank"
          : props.userContext.roleId === Constant.UserRoleId.SchoolAdmin &&
            selectedClass > 0
          ? "Class Dot Rank"
          : props.userContext.roleId === Constant.UserRoleId.SchoolTeacher ||
            props.userContext.roleId === Constant.UserRoleId.FreeTeacher
          ? "Class Dot Rank"
          : props.userContext.roleId === Constant.UserRoleId.SchoolTeacher ||
            props.userContext.roleId === Constant.UserRoleId.FreeTeacher
          ? "Student Dot Rank"
          : props.userContext.roleId === Constant.UserRoleId.Student
          ? "Student Dot Rank"
          : "",
        visibility: true,
        activeClass:
          "border-secondary-teal bg-white text-primary-violet hover:text-dark-violet py-3 tab-box-shadow tab-box-shadow font-medium ",
        deactiveClass:
          "text-primary-violet text-opacity-60 bg-primary-violet/10 py-[0.6rem] mt-auto transition-all hover:py-3 hover:text-opacity-100 hover:text-primary-violet border-[#dee9f1] font-medium",
        disabledClass:
          "pointer-events-none bg-gray-50 text-[#afb3ba] py-[0.6rem] mt-auto border-gray-50",
        isDisabled: false,
      },
      {
        id: 1,
        name: "School Dot Rank",
        visibility:
          !isStudentView &&
          props.userContext.roleId === Constant.UserRoleId.District &&
          selectedSchool === 0
            ? true
            : false,
        activeClass:
          "border-secondary-teal bg-white text-primary-violet hover:text-dark-violet py-3 tab-box-shadow tab-box-shadow font-medium ",
        deactiveClass:
          "text-primary-violet text-opacity-60 bg-primary-violet/10 py-[0.6rem] mt-auto transition-all hover:py-3 hover:text-opacity-100 hover:text-primary-violet border-[#dee9f1] font-medium",
        disabledClass:
          "pointer-events-none bg-gray-50 text-[#afb3ba] py-[0.6rem] mt-auto border-gray-50",
        isDisabled: false,
      },
      {
        id: 2,
        name: "Teacher Dot Rank",
        visibility:
          !isStudentView &&
          (props.userContext.roleId === Constant.UserRoleId.District ||
            (props.userContext.roleId === Constant.UserRoleId.SchoolAdmin &&
              selectedClass === 0))
            ? true
            : false,
        activeClass:
          "border-secondary-teal bg-white text-primary-violet hover:text-dark-violet py-3 tab-box-shadow tab-box-shadow font-medium ",
        deactiveClass:
          "text-primary-violet text-opacity-60 bg-primary-violet/10 py-[0.6rem] mt-auto transition-all hover:py-3 hover:text-opacity-100 hover:text-primary-violet border-[#dee9f1] font-medium",
        disabledClass:
          "pointer-events-none bg-gray-50 text-[#afb3ba] py-[0.6rem] mt-auto border-gray-50",
        isDisabled:
          props.userContext.roleId === Constant.UserRoleId.District &&
          selectedSchool === 0
            ? true
            : false,
      },
      {
        id: 3,
        name: "Student Dot Rank",
        visibility:
          !isStudentView &&
          (props.userContext.roleId === Constant.UserRoleId.District ||
            props.userContext.roleId === Constant.UserRoleId.SchoolAdmin ||
            props.userContext.roleId === Constant.UserRoleId.SchoolTeacher ||
            props.userContext.roleId === Constant.UserRoleId.FreeTeacher)
            ? true
            : false,
        activeClass:
          "border-secondary-teal bg-white text-primary-violet hover:text-dark-violet py-3 tab-box-shadow tab-box-shadow font-medium ",
        deactiveClass:
          "text-primary-violet text-opacity-60 bg-primary-violet/10 py-[0.6rem] mt-auto transition-all hover:py-3 hover:text-opacity-100 hover:text-primary-violet border-[#dee9f1] font-medium",
        disabledClass:
          "pointer-events-none bg-gray-50 text-[#afb3ba] py-[0.6rem] mt-auto border-gray-50",
        isDisabled:
          props.userContext.roleId === Constant.UserRoleId.District &&
          selectedSchool === 0
            ? true
            : false,
      },
    ];
  };

  const getClassSubjects = async () => {
    const resp = await ClassApi.getClassSubject(classId, 1, 1);
    if (resp?.data?.data?.length) {
      getSubjectClasses(resp.data.data[0].subjectId, classId);
    }
  };

  const handleClassChange: any = (e: any) => {
    setSelectedClasses(e);
    setSelectedClass(Number(e[e.length - 1].value));
  };

  const getSchools = async (subjectId: number = 0) => {
    const resp = await getSchoolByDistrictId(
      props.userContext?.districtId ?? 0,
      subjectId
    );
    if (resp?.data?.length) setSchools(resp.data);
  };

  const getClasses = async () => {
    const resp = await getClassesBySchoolAccountId(
      props.userContext?.schoolAccountId || 0,
      selectedSchoolYearId ?? props.profile?.currentSchoolYear.schoolYearId ?? 0
    );
    if (resp?.data?.length) {
      setClasses(resp.data);
    }
  };

  const getStudentProfileById = async (userId) => {
    const resp = await getStudentProfile(userId, 0);
    setStudentProfile(resp.data);
  };

  const getSubjectClasses = async (
    subjectId,
    classId?: number,
    schoolId?: number
  ) => {
    let intSubjectId: number = 0;
    if (subjectId) {
      intSubjectId = parseInt(subjectId)
      setSelectedSubject(intSubjectId);
    } else {
      setSelectedSubject(0);
      setSelectedClass(classId ?? 0);
      setSelectedClasses([{ label: "All Classes", value: "0" }]);
    }

    if (props.userContext.roleId === Constant.UserRoleId.District) {
      setSelectedSchool(0);
      if (intSubjectId > 0) {
        await getSchools(intSubjectId);
      }
    }

    if (intSubjectId === 0 && !classId) {
      getClasses();
    } else {
      setClasses([]);
      setSelectedClasses([{ label: "All Classes", value: "0" }]);
      const resp = await getAllClassesBySubject(
        props.userContext.userId,
        props.userContext.roleId,
        intSubjectId,
        selectedSchoolYearId ?? props.profile.currentSchoolYear.schoolYearId,
        schoolId && schoolId > 0 ? schoolId : props.userContext.schoolId
      );
      if (resp?.data?.data?.length) setClasses(resp.data.data);
      if (classId) {
        setSelectedClass(classId);
        let selectedClass = resp.data.data.filter(
          (x) => x.classId === Number(classId)
        );
        if (selectedClass.length > 0)
          setSelectedClasses([
            { label: selectedClass[0].name, value: selectedClass[0].classId },
          ]);
      } else {
        setSelectedClass(0);
      }
    }
  };

  const getAssessmentTags = async () => {
    const resp = await getAllAssessmentTags(props.userContext?.userId);
    if (resp?.data?.length) setAssessmentTags(resp.data);
  };

  const onReset = () => {
    setIsReportSearched(false);
    setSelectedTab(0);
    setSelectedSchool(0);
    setSelectedSchoolAccountId(0);
    setSelectedClass(0);
    setSelectedClasses([{ label: "All Classes", value: "0" }]);
    setSelectedSubject(subjectId ? subjectId : 0);
    setSelectedAssessmentTag(0);

    setIncludeAssignments(true);
    setIncludeStudyPlan(true);
    setIsInvalidStartDate(false);
    setIsInvalidEndDate(false);
    setProgressReportByStandard(null);
    setProgressReportByTeacher(null);
    setProgressReportByStudent(null);
    setProgressReportBySchool(null);
    changeOrderOptions("name", "asc");
    setSelectedSubjectOption([{ label: "Select Subject", value: "0" }]);
    setSelectedSchoolYearId(props.profile.currentSchoolYear.schoolYearId);
  };

  const runReport = () => {
    const activeTab = getFirstActiveTab();
    setProgressReportByStandard(null);
    setNoDataForStandard("");
    setIsReportSearched(true);
    handleSelectedTab(activeTab, true);
    setTabs(getTabs());
    setSelectedTab(activeTab);
    setProgressReportByTeacher(null);
    setProgressReportByStudent(null);
    setProgressReportBySchool(null);
    changeOrderOptions("name", "asc");
  };

  const handleSelectedTab = (id, searchButton = false) => {
    setSelectedTab(id);
    changeOrderOptions("name", "asc");
    if (isReportSearched || searchButton) {
      if (id === 0) {
        setNoDataForStandard("");
        getProgressReportByStandard();
      } else if (id === 1) {
        setNoDataForSchool("");
        setSelectedSchoolViewByCount(false);
        getProgressReportBySchool();
      } else if (id === 2) {
        setNoDataForTeacher("");
        setSelectedTeacherViewByCount(false);
        getProgressReportByTeacher();
      } else if (id === 3 && progressReportByStudent === null) {
        setNoDataForStudent("");
        setSelectedStudentViewByCount(false);
        getProgressReportByStudent();
      }
    }
  };

  const getProgressReportByStandard = async () => {
    setLoader(true);
    const classes = selectedClasses.map((cl) => cl.value.toString()).toString();
    setProgressReportByStandard(null);
    getProgressReportDataByStandard(
      selectedSubject,
      selectedStartDate !== null
        ? getStartDate(selectedStartDate, true)
        : null,
      selectedEndDate !== null
        ? getEndDate(selectedEndDate, true)
        : null,
      selectedSchool,
      selectedClass === 0 ? null : classes,
      selectedAssessmentTag,
      includeAssignments,
      includeStudyPlan,
      studentUserId,
      selectedSchoolYearId ?? props.profile.currentSchoolYear.schoolYearId
    )
      .then((res) => {
        if (!res.data.data) setNoDataForStandard("No record found");
        setProgressReportByStandard(res.data.data);
        setLoader(false);
      })
      .catch(() => {
        setLoader(false);
      });
  };

  const getProgressReportByTeacher = async () => {
    setLoader(true);
    const classes = selectedClasses.map((cl) => cl.value.toString()).toString();
    setProgressReportByTeacher(null);
    getProgressReportDataByTeacher(
      selectedSubject,
      selectedStartDate !== null ? getStartDate(selectedStartDate, true) : null,
      selectedEndDate !== null ? getEndDate(selectedEndDate, true) : null,
      selectedSchool,
      selectedClass === 0 ? null : classes,
      selectedAssessmentTag,
      includeAssignments,
      includeStudyPlan,
      selectedSchoolYearId ?? props.profile.currentSchoolYear.schoolYearId
    )
      .then((res) => {
        if (!res.data.data) setNoDataForTeacher("No record found");
        setProgressReportByTeacher(res.data.data);
        setLoader(false);
      })
      .catch(() => {
        setLoader(false);
      });
  };

  const getProgressReportByStudent = async () => {
    setLoader(true);
    const classes = selectedClasses.map((cl) => cl.value.toString()).toString();
    setProgressReportByStudent(null);
    getProgressReportDataByStudent(
      selectedSubject,
      selectedStartDate !== null ? getStartDate(selectedStartDate, true) : null,
      selectedEndDate !== null ? getEndDate(selectedEndDate, true) : null,
      selectedSchool,
      selectedClass === 0 ? null : classes,
      selectedAssessmentTag,
      includeAssignments,
      includeStudyPlan,
      selectedSchoolYearId ?? props.profile.currentSchoolYear.schoolYearId
    )
      .then((res) => {
        if (!res.data?.data?.students) {
          setNoDataForStudent("No record found");
        } else {
          setProgressReportByStudent(res.data.data);
        }
        setLoader(false);
      })
      .catch(() => {
        setLoader(false);
      });
  };

  const getProgressReportBySchool = async () => {
    setLoader(true);
    const classes = selectedClasses.map((cl) => cl.value.toString()).toString();
    setProgressReportBySchool(null);
    getProgressReportDataBySchool(
      selectedSubject,
      selectedStartDate !== null ? getStartDate(selectedStartDate, true) : null,
      selectedEndDate !== null ? getEndDate(selectedEndDate, true) : null,
      selectedSchool,
      selectedClass === 0 ? null : classes,
      selectedAssessmentTag,
      includeAssignments,
      includeStudyPlan,
      selectedSchoolYearId ?? props.profile.currentSchoolYear.schoolYearId
    )
      .then((res) => {
        if (!res.data.data) setNoDataForSchool("No record found");
        setProgressReportBySchool(res.data.data);
        setLoader(false);
      })
      .catch(() => {
        setLoader(false);
      });
  };

  const getProgressReportByStandardByStudentIds = async (
    studentUserIds: Array<number>
  ) => {
    const classes = selectedClasses.map((cl) => cl.value.toString()).toString();
    return getProgressReportDataByStandardByStudentIds(
      selectedSubject,
      selectedStartDate !== null ? getStartDate(selectedStartDate, true) : null,
      selectedEndDate !== null ? getEndDate(selectedEndDate, true) : getEndDate(new Date(), true),
      selectedSchool,
      selectedClass === 0 ? null : classes,
      selectedAssessmentTag,
      includeAssignments,
      includeStudyPlan,
      String(studentUserIds),
      selectedSchoolYearId ?? props.profile.currentSchoolYear.schoolYearId
    );
  };

  const getStudentDotRankPrintData = async (students: Array<any>) => {
    setLoader(true);
    setProgressReportByStandardPrintPdf([]);
    const studentIds = students.map((s) => s.id);
    const response = await getProgressReportByStandardByStudentIds(studentIds);
    const data = response.data;
    data.map((d, i) => {
      d.studentName = students[i].name;
    });
    setProgressReportByStandardPrintPdf(Array.from(data));
    setLoader(false);

    if (handleStudentDotRankPrint !== undefined) {
      setTimeout(() => {
        handleStudentDotRankPrint();
      }, 550);
    }
  };

  const breadcrumbItems = () => {
    const items: Array<IBreadcrumb> = [
      { name: t("Breadcrumb.Home"), url: RouteConstant.ROUTE_DASHBOARD },
      { name: t("Breadcrumb.Reports"), url: RouteConstant.REPORTS },
      { name: t("Breadcrumb.ProgressReports"), url: "" },
    ];

    return items;
  };

  const getStudentBreadcrumbItems = () => {
    const items: Array<IBreadcrumb> = [
      {
        name: "Home",
        url: RouteConstant.MiddleSchool.assignments,
        textColor: studentType === "EL" ? "text-gray-500" : "",
      },
      {
        name: t("Breadcrumb.StudyPlanSubjects"),
        url: RouteConstant.MiddleSchool.subjects,
        textColor: studentType === "EL" ? "text-gray-500" : "",
      },
      {
        name: t("Breadcrumb.StudyPlanStandards"),
        url: RouteConstant.MiddleSchool.standards.replace(":id", subjectId),
        textColor: studentType === "EL" ? "text-gray-500" : "",
      },
      {
        name: t("Breadcrumb.ProgressReports"),
        url: "",
        textColor: studentType === "EL" ? "text-gray-500" : "",
      },
    ];

    return items;
  };

  const handleTeacherViewByCount = (value) => {
    setSelectedTeacherViewByCount(value);
  };

  const handleStudentViewByCount = (value) => {
    setSelectedStudentViewByCount(value);
  };

  const handleSchoolViewByCount = (value) => {
    setSelectedSchoolViewByCount(value);
  };

  const changeOrderOptions = (column: string, orderType: SortingOrder) => {
    setOrderType(orderType);
    setActiveSortColumn(column);
  };

  const showPrintButton = (): boolean => {
    var tab = getTabs().find((t) => t.id === selectedTab);
    return selectedTab === 0 || tab?.name === "Student Dot Rank";
  };

  const onPrint = async () => {
    setPrintFilters();
    if (handleSchoolDotRankPrint !== undefined && selectedTab === 0) {
      setIsPrinting(true);
      waitForElementsToRender('#schooldotrank-print','#progress-report-filter-print',0,600).then(() => {
        handleSchoolDotRankPrint();
      })
    } else if (selectedTab === 3) {
      setShowStudentDotRankPrintPopup(true);
    }
  };

  const setPrintFilters = () => {
    if (progressReportByStandard) {
      var classes = selectedClasses
        .map((cl) => {
          return cl.label;
        })
        .join(",");
      var tag =
        selectedAssessmentTag === 0
          ? "All"
          : assessmentTags?.find((t) => t.userTagId === selectedAssessmentTag)
              ?.name;
      var schoolName: string | undefined = "";
      if (
        props.userContext.roleId === Constant.UserRoleId.District &&
        selectedSchool > 0
      ) {
        schoolName = schools?.find((t) => t.schoolId === selectedSchool)?.name;
      }
      setPrintFilter({
        subjectName: progressReportByStandard?.subjects[0].name,
        classes: classes,
        startDate: selectedStartDate,
        endDate: selectedEndDate,
        assessmentTag: tag,
        includeAssignments: includeAssignments,
        includeStudyPlan: includeStudyPlan,
        schoolName: schoolName,
      });
    }
  };

  const getSummaryIndexes = (data) => {
    let summaryIndexes = [0];
    if (!data?.subjects || data?.subjects?.length <= 0) return [];

    data.subjects[0]?.domains?.forEach((domain) => {
      if (domain?.index) summaryIndexes.push(domain.index);
    });
    return summaryIndexes;
  };

  const handleEndDateChange = (date) => {
    if (!date) {
      setIsInvalidEndDate(false);
      setSelectedEndDate(date);
      return;
    }
    if (
      selectedStartDate &&
      getDateWithoutTime(date) <
      getDateWithoutTime(selectedStartDate)
    ) {
      setIsInvalidEndDate(true);
    } else {
      setIsInvalidEndDate(false);
    }
    setSelectedEndDate(date);
  };

  const handleStartDateChange = (date) => {
    if (!date) {
      setIsInvalidStartDate(false);
      setSelectedStartDate(date);
      return;
    }
    if (
      selectedEndDate &&
      getDateWithoutTime(date) >
      getDateWithoutTime(selectedEndDate)
    ) {
      setIsInvalidStartDate(true);
    } else {
      setIsInvalidStartDate(false);
    }
    setSelectedStartDate(date);
  };

  const handleCalendarOpen = (mode: string) => {
    switch (mode){
      case "end":
        if (!selectedEndDate) {
          setSelectedEndDate(getEndDate(new Date(), true));
        }
        break;
    }
  };

  useEffect(() => {
    if (selectedAssessmentTag && selectedAssessmentTag > 0) {
      setIncludeAssignments(true);
      setIncludeStudyPlan(false);
    }
  }, [selectedAssessmentTag]);

  function handleSchoolYearLoaded(currentSchoolYearId?: number) {
    handleSchoolYearChange(currentSchoolYearId);
  }

  function handleSchoolYearChange(schoolYearId?: number) {
    setSelectedSchoolYearId(schoolYearId);
    setSelectedTab(0);
    setSelectedSchool(0);
    setSelectedSchoolAccountId(0);
    setSelectedClasses([{ label: "All Classes", value: "0" }]);
    setSelectedClass(0);
    setSelectedSubject(subjectId ? subjectId : 0);
    setSelectedSubjectOption([{ label: "Select Subject", value: "0" }]);
    setProgressReportByStandard(null);
    setProgressReportByTeacher(null);
    setProgressReportByStudent(null);
    setProgressReportBySchool(null);
    setSelectedStartDate(null);
    setSelectedEndDate(null);
  }

  function getDateWithoutTime(date: Date) {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
  }

  return (
    <>
      <div className="min-w-0 w-full">
        {loader && <Loader />}
        <div className="lg:flex lg:items-center px-4 py-3 lg:px-8 border-b">
          {props.userContext.roleId !== Constant.UserRoleId.Student && (
            <Breadcrumb items={breadcrumbItems()} />
          )}

          {props.userContext.roleId === Constant.UserRoleId.Student &&
            studentType === "MS" && (
              <StudentBreadCrumb items={getStudentBreadcrumbItems()} />
            )}

          <div className="lg:w-[55%] text-lg font-medium flex gap-2 h-auto sm:h-10 items-center">
            <h1
              className={`font-medium flex justify-start mb-0 pb-0 ${
                props.userContext.roleId !== Constant.UserRoleId.Student
                  ? "text-primary-violet"
                  : studentType === "MS" || studentType === "K1"
                  ? "text-white"
                  : studentType === "EL"
                  ? "text-gray-700"
                  : "text-white"
              }
        `}
            >
              {t("StudyPlan.ProgressReport")}
              {studentProfile && (
                <>
                  {" "}
                  - {studentProfile.firstName} {studentProfile.lastName}
                </>
              )}
            </h1>
          </div>
        </div>
        <div className="px-2 sm:px-6  lg:px-8 py-1 relative my-4">
          <div className="bg-gray-100 w-full rounded-lg p-5">
            <div className="flex flex-col 1xl:flex-row">
              <div className="w-full 1xl:w-3/4 1xl:pr-4 xxxl:pr-0">
                <div
                  className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 1xl:grid-cols-4 2xl:grid-cols-4 xxxl:grid-cols-5 gap-4 mb-4">
                  <div className="flex flex-col">
                    <label className="block mb-1 text-sm text-gray-500">
                      {t("StudyPlan.SchoolYear")} <Asterisk/>
                    </label>

                    <SchoolYearDropDown
                      schoolId={
                        selectedSchool > 0
                          ? selectedSchool
                          : props.userContext.schoolId
                      }
                      reset={resetSchoolYear}
                      offsets={[0, -1]}
                      handleSchoolYearChange={handleSchoolYearChange}
                      handleSchoolYearLoaded={handleSchoolYearLoaded}
                      handleSchoolYearData={() => {}}
                    ></SchoolYearDropDown>
                  </div>
                  {!isStudentView && (
                    <>
                      <div className="flex flex-col">
                        <Subject
                          setSelectedSubject={(item) => {
                            setSelectedSubjectOption(item[0]);
                            getSubjectClasses(
                              item[0].value,
                              undefined,
                              selectedSchool
                            );
                          }}
                          selectedItems={selectedSubjectOption}
                          isMulti={false}
                          isRequired={true}
                          themeColor={"#008fbb"}
                        ></Subject>
                      </div>
                      {isDistrictUser && (
                        <div className="flex flex-col">
                          <div className="flex gap-2">
                            <label className="block mb-1 text-sm text-gray-500">
                              School
                            </label>
                            <Info
                              title="Select a specific school to populate and enable your Teacher Dot Rank and Student Dot Rank tabs."/>
                          </div>

                          <Select
                            value={selectedSchool}
                            onChange={(e) => {
                              getSubjectClasses(
                                selectedSubject,
                                undefined,
                                Number(e.target.value)
                              );
                              setSelectedSchool(parseInt(e.target.value));
                              setSelectedSchoolAccountId(
                                schools?.filter(
                                  (s) => s.schoolId == parseInt(e.target.value)
                                )[0]?.schoolAccountId ?? 0
                              );
                            }}
                            className={
                              "py-2 px-4 block text-sm border border-gray-300 focus:ring-primary-violet focus:border-primary-violet form-select leading-5 p-1 rounded " +
                              (selectedSubject === 0
                                ? "pointer-events-none text-gray-400 bg-gray"
                                : "")
                            }
                          >
                            <option value={0}>All Schools</option>
                            {schools?.map((school: ISchool, index) => {
                              return (
                                <Fragment key={index}>
                                  <option value={school.schoolId}>
                                    {school.name}
                                  </option>
                                </Fragment>
                              );
                            })}
                          </Select>
                        </div>
                      )}
                      <div className="flex flex-col">
                        <label className="block mb-1 text-sm text-gray-500">
                          Class
                        </label>
                        <ClassDropDown
                          schoolYearId={
                            selectedSchoolYearId ??
                            props.profile.currentSchoolYear.schoolYearId
                          }
                          isMultiSelect={true}
                          onChange={handleClassChange}
                          value={selectedClasses}
                          data={classes}
                          isDisabled={
                            selectedSubject === 0 ||
                            (props.userContext.roleId ===
                              Constant.UserRoleId.District &&
                              selectedSchool === 0)
                              ? true
                              : false
                          }
                          showArchived={true}
                          showHidden={true}
                          subjectId={selectedSubject}
                          selectedSchoolAccountId={selectedSchoolAccountId}
                        ></ClassDropDown>
                      </div>
                      <div className="flex flex-col">
                        <label className="block mb-1 text-sm text-gray-500">
                          Assessment Tag
                        </label>

                        <Select
                          value={selectedAssessmentTag}
                          onChange={(e) =>
                            setSelectedAssessmentTag(parseInt(e.target.value))
                          }
                          className={
                            "py-2 px-4 block text-sm border border-gray-400 border-opacity-50 focus:ring-primary-violet focus:border-primary-violet form-select leading-5 p-1 rounded " +
                            (selectedSubject === 0
                              ? "pointer-events-none text-gray-400 bg-gray"
                              : "")
                          }
                        >
                          <option value={0}>All Assessments</option>
                          {assessmentTags?.map((tag: any, index) => {
                            return (
                              <Fragment key={index}>
                                <option value={tag.userTagId}>
                                  {tag.name}
                                </option>
                              </Fragment>
                            );
                          })}
                        </Select>
                      </div>
                    </>
                  )}
                </div>

                <div
                  className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 1xl:grid-cols-4 2xl:grid-cols-4 xxxl:grid-cols-5 gap-4 mb-4">
                  <div className="flex flex-col">
                    <label className="block mb-1 text-sm text-gray-500">
                      {t("StudyPlan.StartDate")}
                    </label>

                    <DatePicker
                      selected={selectedStartDate}
                      onChange={handleStartDateChange}
                      minDate={minDate}
                      maxDate={maxDate}
                      className={
                        "react-datepicker__input-container py-1.5 px-3 block w-full shadow-sm focus:ring-primary-violet focus:border-primary-violet border border-gray-300 rounded " +
                        (selectedSubject === 0
                          ? "pointer-events-none text-gray-400 bg-gray"
                          : "")
                      }
                      isClearable={true}
                      onCalendarOpen={() => { handleCalendarOpen("start") }}
                    />
                    {isInvalidStartDate && (
                      <span className="pl-2 text-red-600">
                        {" "}
                        {t("StudyPlan.InvalidDate")}
                      </span>
                    )}
                  </div>

                  <div className="flex flex-col">
                    <label className="block mb-1 text-sm text-gray-500">
                      {t("StudyPlan.EndDate")}
                    </label>

                    <DatePicker
                      selected={selectedEndDate}
                      minDate={minDate}
                      maxDate={maxDate}
                      onChange={handleEndDateChange}
                      className={
                        "react-datepicker__input-container py-1.5 px-3 block w-full shadow-sm focus:ring-primary-violet focus:border-primary-violet border border-gray-300 rounded " +
                        (selectedSubject === 0
                          ? "pointer-events-none text-gray-400 bg-gray"
                          : "")
                      }
                      isClearable={true}
                      onCalendarOpen={() => { handleCalendarOpen("end") }}
                    />
                    {isInvalidEndDate && (
                      <span className="pl-2 text-red-600">
                        {t("StudyPlan.InvalidDate")}
                      </span>
                    )}
                  </div>
                </div>

                <div className="text-gray-500 mb-1">
                  {t("StudyPlan.Sources")}
                </div>
                <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-3 xl:grid-cols-3 1xl:grid-cols-3 2xl:grid-cols-3 xxxl:grid-cols-5 gap-4 mb-4">
                  <div className="flex gap-3">
                    <input
                      type="checkbox"
                      name="time-line"
                      className={
                        "h-4 w-4 mt-0.5 cursor-pointer text-primary-violet border-gray-300 focus:ring-primary-violet " +
                        (selectedSubject === 0
                          ? "pointer-events-none text-gray-400 bg-gray"
                          : "")
                      }
                      checked={includeAssignments}
                      onChange={() =>
                        setIncludeAssignments(!includeAssignments)
                      }
                    />
                    <span className="text-sm text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3">
                      {t("StudyPlan.Assessments")}/{t("StudyPlan.Assignments")}
                    </span>
                  </div>
                  <div className="flex gap-3">
                    <input
                      type="checkbox"
                      name="time-line"
                      className={
                        "h-4 w-4 mt-0.5 cursor-pointer text-primary-violet border-gray-300 focus:ring-primary-violet " +
                        (selectedSubject === 0 || selectedAssessmentTag > 0
                          ? "pointer-events-none text-gray-400 bg-gray"
                          : "")
                      }
                      checked={includeStudyPlan}
                      onChange={() => setIncludeStudyPlan(!includeStudyPlan)}
                      disabled={
                        selectedAssessmentTag && selectedAssessmentTag > 0
                          ? true
                          : false
                      }
                    />
                    <span className="text-sm text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3">
                      {t("StudyPlan.StudyPlanPractice")}
                    </span>
                  </div>
                </div>
              </div>
              <div className="w-full 1xl:w-1/4">
                <div className="flex gap-1 flex-col bg-gray-50 p-3 text-gray-500 mb-3 rounded-md">
                  <p>{t("StudyPlan.DotRankToolTip")}</p>

                  <div className="flex gap-1 flex-col bg-white/50 p-1">
                    <p className="flex items-center">
                      <span className="w-3.5 h-3.5 rounded-full bg-[#9e9e9e] border border-black inline-block mr-1"></span>
                      {t("StudyPlan.GrayDotRankToolTip")}
                    </p>
                    <p className="flex items-center">
                      {" "}
                      <span className="w-3.5 h-3.5 rounded-full bg-[#e53f71] border border-black inline-block mr-1"></span>
                      {t("StudyPlan.PinkDotRankToolTip")}
                    </p>
                    <p className="flex items-center">
                      <span className="w-3.5 h-3.5 rounded-full bg-[#f4d221] border border-black inline-block mr-1"></span>
                      {t("StudyPlan.YellowDotRankToolTip")}
                    </p>
                    <p className="flex items-center">
                      <span className="w-3.5 h-3.5 rounded-full bg-[#a3b938] border border-black inline-block mr-1"></span>
                      {t("StudyPlan.GreenDotRankToolTip")}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex gap-4 justify-end">
              <button
                onClick={onReset}
                className="bg-transparent border my-auto border-primary-violet shadow-sm py-2 px-4 flex justify-center text-sm font-medium text-primary-violet hover:text-white hover:bg-light-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3 transition ease-in-out duration-500"
              >
                {t("StudyPlan.Reset")}
              </button>
              <div className="cursor-not-allowed">
                <button
                  className={
                    "bg-primary-violet border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 " +
                    (selectedSubject === 0 ||
                    isInvalidStartDate ||
                    isInvalidEndDate ||
                    (!includeAssignments && !includeStudyPlan)
                      ? "bg-opacity-20 text-primary-violet text-opacity-40 pointer-events-none"
                      : "hover:bg-dark-violet hover:shadow-lg")
                  }
                  onClick={runReport}
                >
                  {t("StudyPlan.RunReport")}
                </button>
              </div>
            </div>

            <div className="border-b border-gray-400 py-2 mb-2"></div>

            <div className="bg-white px-3 pt-2 pb-3">
              <div className="flex items-start bg-gray-100 pt-2 px-3 flex-col lg:flex-row lg:justify-between">
                <div className="flex lg:mr-2 xl:mr-0">
                  <nav
                    className="grid grid-cols-2 md:grid-cols-none md:grid-rows-none md:flex md:space-x-2 cursor-not-allowed"
                    aria-label="Tabs"
                  >
                    {tabs &&
                      tabs.map(
                        (tab) =>
                          tab.visibility && (
                            <button
                              key={tab.name}
                              onClick={() => {
                                handleSelectedTab(tab.id);
                              }}
                              className={classNames(
                                tab.id === selectedTab
                                  ? tab.activeClass
                                  : tab.isDisabled
                                  ? tab.disabledClass
                                  : tab.deactiveClass,
                                "px-2 sm:px-4 text-center border-t-2 text-sm cursor-pointer"
                              )}
                            >
                              {t("StudyPlan." + tab.name)}
                            </button>
                          )
                      )}
                  </nav>
                </div>
                <div className="flex gap-4 justify-end">
                  {showPrintButton() && (
                    <ExportControl
                      controlType={"button"}
                      controlText={"Print"}
                      userContext={props.userContext}
                      permissionName={
                        isUpgradeRequiredForStudent
                          ? null
                          : permissions.reports_export_upgrade
                      }
                      className={
                        "bg-transparent border my-auto border-primary-violet shadow-sm py-2 px-4 flex justify-center text-sm font-medium text-primary-violet hover:text-white hover:bg-light-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3 transition ease-in-out duration-500" +
                        (isUpgradeRequiredForStudent
                          ? " hover:text-primary-violet hover:bg-transparent "
                          : getCssForDisabled(
                              permissions.reports_export_upgrade,
                              " hover:text-primary-violet hover:bg-transparent "
                            ))
                      }
                      onClick={onPrint}
                      isRoleCheck={isUpgradeRequiredForStudent}
                      isUpgradeRequired={isUpgradeRequiredForStudent}
                    ></ExportControl>
                  )}
                </div>
              </div>

              {!progressReportByStandard && selectedTab === 0 && (
                <div className="p-3">
                  {isReportSearched ? noDataForStandard : ""}
                </div>
              )}
              {progressReportByStandard && selectedTab === 0 && (
                <ProgressReportByDistrict
                  data={progressReportByStandard}
                  startDate={selectedStartDate}
                  endDate={selectedEndDate}
                  selectedSchool={selectedSchool}
                  selectedClass={selectedClasses
                    .map((cl) => cl.value.toString())
                    .toString()}
                  includeAssignments={includeAssignments}
                  includeStudyPlan={includeStudyPlan}
                  userId={props.userContext.userId}
                  roleId={props.userContext.roleId}
                  subjectId={selectedSubject}
                  classId={selectedClasses[0].value}
                  isMultiClassSelected={
                    selectedClasses.length > 1 ||
                    selectedClasses[0].value === "0"
                  }
                  printFlag={false}
                  userContext={props.userContext}
                  profile={props.profile}
                  schoolYearId={
                    selectedSchoolYearId ??
                    props.profile.currentSchoolYear.schoolYearId
                  }
                ></ProgressReportByDistrict>
              )}

              {!progressReportBySchool && selectedTab === 1 && (
                <div className="p-3">
                  {isReportSearched ? noDataForSchool : ""}
                </div>
              )}
              {progressReportBySchool && selectedTab === 1 && (
                <div className="flex flex-col">
                  <div className="mt-5 flex gap-3">
                    <div className="flex gap-3">
                      <input
                        defaultChecked={!selectedSchoolViewByCount}
                        type="radio"
                        name="school-dot-rank"
                        className="h-4 w-4 mt-0.5 cursor-pointer text-primary-violet border-gray-300 focus:ring-primary-violet"
                        onChange={(e) => handleSchoolViewByCount(false)}
                      />
                      <span className="text-sm text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3">
                        View By Standard
                      </span>
                    </div>

                    <div className="flex gap-3">
                      <input
                        defaultChecked={selectedSchoolViewByCount}
                        type="radio"
                        name="school-dot-rank"
                        className="h-4 w-4 mt-0.5 cursor-pointer text-primary-violet border-gray-300 focus:ring-primary-violet"
                        onChange={(e) => handleSchoolViewByCount(true)}
                      />
                      <span className="text-sm text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3">
                        View By Count
                      </span>
                    </div>
                  </div>

                  {progressReportBySchool && selectedSchoolViewByCount && (
                    <ProgressReportBySchoolByCount
                      data={progressReportBySchool}
                      activeSortColumn={activeSortColumn}
                      orderType={orderType}
                      setOrderOptions={changeOrderOptions}
                      getSummaryIndexes={getSummaryIndexes}
                    ></ProgressReportBySchoolByCount>
                  )}
                  {progressReportBySchool && !selectedSchoolViewByCount && (
                    <ProgressReportBySchoolByStandard
                      data={progressReportBySchool}
                    ></ProgressReportBySchoolByStandard>
                  )}
                </div>
              )}

              {!progressReportByTeacher && selectedTab === 2 && (
                <div className="p-3">
                  {isReportSearched ? noDataForTeacher : ""}
                </div>
              )}
              {progressReportByTeacher && selectedTab === 2 && (
                <div className="flex flex-col">
                  <div className="mt-5 flex flex-col md:flex-row items-center space-x-6">
                    <div className="flex gap-3 mt-5">
                      <div className="flex gap-3">
                        <div className="flex gap-3 ">
                          <input
                            defaultChecked={!selectedTeacherViewByCount}
                            type="radio"
                            name="teacher-dot-rank"
                            className="h-4 w-4 mt-0.5 cursor-pointer text-primary-violet border-gray-300 focus:ring-primary-violet"
                            onChange={(e) => handleTeacherViewByCount(false)}
                          />
                          <span className="text-sm text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3">
                            View By Standard
                          </span>
                        </div>
                      </div>

                      <div className="flex gap-3">
                        <input
                          defaultChecked={selectedTeacherViewByCount}
                          type="radio"
                          name="teacher-dot-rank"
                          className="h-4 w-4 mt-0.5 cursor-pointer text-primary-violet border-gray-300 focus:ring-primary-violet"
                          onChange={(e) => handleTeacherViewByCount(true)}
                        />
                        <span className="text-sm text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3">
                          View By Count
                        </span>
                      </div>
                    </div>
                  </div>
                  {progressReportByTeacher && selectedTeacherViewByCount && (
                    <ProgressReportByTeacherByCount
                      data={progressReportByTeacher}
                      activeSortColumn={activeSortColumn}
                      orderType={orderType}
                      setOrderOptions={changeOrderOptions}
                      getSummaryIndexes={getSummaryIndexes}
                    ></ProgressReportByTeacherByCount>
                  )}
                  {progressReportByTeacher && !selectedTeacherViewByCount && (
                    <ProgressReportByTeacherByStandard
                      data={progressReportByTeacher}
                    ></ProgressReportByTeacherByStandard>
                  )}
                </div>
              )}

              {!progressReportByStudent && selectedTab === 3 && (
                <div className="p-3">
                  {isReportSearched ? noDataForStudent : ""}
                </div>
              )}
              {progressReportByStudent && selectedTab === 3 && (
                <div className="flex flex-col">
                  <div className="mt-5 flex gap-3">
                    <div className="flex gap-3">
                      <input
                        defaultChecked={!selectedStudentViewByCount}
                        type="radio"
                        name="school-dot-rank"
                        className="h-4 w-4 mt-0.5 cursor-pointer text-primary-violet border-gray-300 focus:ring-primary-violet"
                        onChange={(e) => handleStudentViewByCount(false)}
                      />
                      <span className="text-sm text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3">
                        View By Standard
                      </span>
                    </div>
                    <div className="flex gap-3">
                      <input
                        defaultChecked={selectedStudentViewByCount}
                        type="radio"
                        name="school-dot-rank"
                        className="h-4 w-4 mt-0.5 cursor-pointer text-primary-violet border-gray-300 focus:ring-primary-violet"
                        onChange={(e) => handleStudentViewByCount(true)}
                      />
                      <span className="text-sm text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3">
                        View By Count
                      </span>
                    </div>
                  </div>

                  {progressReportByStudent && selectedStudentViewByCount && (
                    <ProgressReportByStudentByCount
                      data={progressReportByStudent}
                      activeSortColumn={activeSortColumn}
                      orderType={orderType}
                      setOrderOptions={changeOrderOptions}
                      getSummaryIndexes={getSummaryIndexes}
                    ></ProgressReportByStudentByCount>
                  )}
                  {progressReportByStudent && !selectedStudentViewByCount && (
                    <ProgressReportByStudentByStandard
                      data={progressReportByStudent}
                      userId={props.userContext.userId}
                      roleId={props.userContext.roleId}
                      subjectId={selectedSubject}
                      classId={selectedClasses[0].value}
                      isMultiClassSelected={
                        selectedClasses.length > 1 ||
                        selectedClasses[0].value === "0"
                      }
                      userContext={props.userContext}
                      profile={props.profile}
                    ></ProgressReportByStudentByStandard>
                  )}
                </div>
              )}

              {selectedTab === 4 && (
                <>
                  <div className="flex flex-col md:flex-row md:justify-between items-center">
                    <div className="mt-5 flex gap-3">
                      <div className="flex gap-3">
                        <input
                          type="radio"
                          name="student-dot-rank"
                          className="h-4 w-4 mt-0.5 cursor-pointer text-primary-violet border-gray-300 focus:ring-primary-violet"
                        />
                        <span className="text-sm text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3">
                          View By District
                        </span>
                      </div>

                      <div className="flex gap-3">
                        <input
                          type="radio"
                          name="student-dot-rank"
                          className="h-4 w-4 mt-0.5 cursor-pointer text-primary-violet border-gray-300 focus:ring-primary-violet"
                        />
                        <span className="text-sm text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3">
                          View By School
                        </span>
                      </div>
                    </div>

                    <div className="mt-5 flex gap-3">
                      <div className="flex gap-3">
                        <input
                          type="radio"
                          name="student-dot-rank"
                          className="h-4 w-4 mt-0.5 cursor-pointer text-primary-violet border-gray-300 focus:ring-primary-violet"
                        />
                        <span className="text-sm text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3">
                          View By Subject
                        </span>
                      </div>

                      <div className="flex gap-3">
                        <input
                          type="radio"
                          name="student-dot-rank"
                          className="h-4 w-4 mt-0.5 cursor-pointer text-primary-violet border-gray-300 focus:ring-primary-violet"
                        />
                        <span className="text-sm text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3">
                          View By Domain
                        </span>
                      </div>

                      <div className="flex gap-3">
                        <input
                          type="radio"
                          name="student-dot-rank"
                          className="h-4 w-4 mt-0.5 cursor-pointer text-primary-violet border-gray-300 focus:ring-primary-violet"
                        />
                        <span className="text-sm text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3">
                          View By Standard
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 h-100">
                    <ResponsiveContainer width="100%" height="100%">
                      <LineChart
                        width={300}
                        height={100}
                        data={progressReport1.charts}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Line
                          type="monotone"
                          dataKey="Monthly Progress"
                          stroke="#008fbb"
                          activeDot={{ r: 8 }}
                          isAnimationActive={false}
                        />
                      </LineChart>
                    </ResponsiveContainer>
                    <div className="w-full flex justify-center items-center text-primary-violet">
                      Monthly Progress
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>

        {/********************  print report ***************************/}
        <div className="hidden">
          <div>
            <div id="schooldotrank-print" ref={componentRefSchoolDotRank}>
              {printFilter !== undefined && (
                <div>
                  <ProgressReportFilterPrint
                    filter={printFilter}
                  ></ProgressReportFilterPrint>
                  <div className="bg-white px-3 pt-2 pb-3">
                    {progressReportByStandard && selectedTab === 0 && (
                      <ProgressReportByDistrict
                        data={progressReportByStandard}
                        startDate={selectedStartDate}
                        endDate={selectedEndDate}
                        selectedSchool={selectedSchool}
                        selectedClass={selectedClasses
                          .map((cl) => cl.value.toString())
                          .toString()}
                        includeAssignments={includeAssignments}
                        includeStudyPlan={includeStudyPlan}
                        userId={props.userContext.userId}
                        roleId={props.userContext.roleId}
                        subjectId={selectedSubject}
                        classId={selectedClasses[0].value}
                        isMultiClassSelected={
                          selectedClasses.length > 1 ||
                          selectedClasses[0].value === "0"
                        }
                        printFlag={true}
                      ></ProgressReportByDistrict>
                    )}

                    {!progressReportBySchool && selectedTab === 1 && (
                      <div className="p-3">
                        {isReportSearched ? noDataForSchool : ""}
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>

            <div
              id="studentdorrank-print"
              ref={componentRefStudentDotRankPrint}
            >
              {/* {printFilter !== undefined && */}

              <div>
                <ProgressReportFilterPrint
                  filter={printFilter}
                ></ProgressReportFilterPrint>
                {progressReportByStandardPrintPdf.length > 0 &&
                  progressReportByStandardPrintPdf.map((s, index) => (
                    <>
                      <div className="text-lg font-medium flex gap-2 h-auto sm:h-10 items-center ml-4">
                        <h1 className="font-medium flex justify-center mb-0 pb-0 text-primary-violet mt-5">
                          Progress Report - {s.studentName}
                        </h1>
                      </div>

                      <div className="bg-white px-3 pt-2 pb-3">
                        {/* {progressReportByStandard && selectedTab === 0 && ( */}
                        <ProgressReportByDistrict
                          key={index}
                          data={s.data}
                          startDate={selectedStartDate}
                          endDate={selectedEndDate}
                          selectedSchool={selectedSchool}
                          selectedClass={selectedClasses
                            .map((cl) => cl.value.toString())
                            .toString()}
                          includeAssignments={includeAssignments}
                          includeStudyPlan={includeStudyPlan}
                          userId={props.userContext.userId}
                          roleId={props.userContext.roleId}
                          subjectId={selectedSubject}
                          classId={selectedClasses[0].value}
                          isMultiClassSelected={
                            selectedClasses.length > 1 ||
                            selectedClasses[0].value === "0"
                          }
                          printFlag={true}
                        ></ProgressReportByDistrict>

                        {!progressReportBySchool && selectedTab === 1 && (
                          <div className="p-3">
                            {isReportSearched ? noDataForStudent : ""}
                          </div>
                        )}
                      </div>
                      {index != progressReportByStandardPrintPdf.length - 1 && (
                        <div className="pagebreak"> </div>
                      )}
                    </>
                  ))}
              </div>
            </div>
          </div>
        </div>
        {showStudentDotRankPrintPopup && (
          <StudentDotRankPrint
            progressReportByStudent={progressReportByStudent}
            onStudentDotRankPrint={getStudentDotRankPrintData}
            showPopUp={setShowStudentDotRankPrintPopup}
          />
        )}
      </div>
    </>
  );
}

export default connect((state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
})(ProgressReport);

import { connect } from "react-redux";

const ActivityKG = (props: any) => {
  return <>KG</>;
};

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(ActivityKG);

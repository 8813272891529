export default class PublishMessageParams {
    constructor(
        public userId: number,
        public schoolId: number,
        public title: string,
        public isActive: boolean,
        public startDate: Date,
        public endDate: Date,
        public gradeId: number,
        public classIds: number[],
        public studentIds: number[],
        public messageId: number,
        public linkedItemId: number,
        public messageType: number,
        public messageTargetType: number,
        public linkedItemTitle: string,
        public messageContent: string
    ) { }
}
import { Fragment, useEffect, useState } from "react";
import {
  getResultsAssessmentMasteryBySchool,
  getResultsPremiumAssessmentMasteryBySchool,
} from "../../../../../../api/report/resultsReport";
import {
  DEFAULT_PAGE_NUMBER,
  DEFAULT_PAGE_SIZE,
} from "../../../../../../utils/pagingConstant";
import Loader from "../../../../../shared/loader";
import NoData from "../../../../../shared/noData";
import PageSize from "../../../../../shared/pagination/pageSize";
import Pagination from "../../../../../shared/pagination/pagination";
import { PrintIcon, Info } from "../../../../../../assets/icons";

interface IResultReportAssessmentMasteryBySchoolInput {
  schoolYearId: number;
  schoolIds: number[];
  assessmentId?: number;
  premiumAssessmentId?: number;
  type: string;
  print?: () => void;
  resultsReportMasteryData?: any[];
  setResultsReportMasteryData?: (data: any[]) => void;
}

export const ResultReportAssessmentMasteryBySchool = (
  props: IResultReportAssessmentMasteryBySchoolInput
) => {
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [pageNumber, setPageNumber] = useState(DEFAULT_PAGE_NUMBER);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [resetPageNumber, setResetPageNumber] = useState<boolean>(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [resultDetails, setResultDetails] = useState<Array<any>>(
    props.resultsReportMasteryData ?? []
  );
  const [isSchoolChanged, setIsSchoolChanged] = useState<boolean>(true);

  const onPageChange = (pageNo) => {
    setIsSchoolChanged(false);
    setPageNumber(pageNo);
  };

  const onPageSizeChange = (pageSize) => {
    setIsSchoolChanged(false);
    setPageNumber(DEFAULT_PAGE_NUMBER);
    setPageSize(pageSize);
    setResetPageNumber(true);
  };

  const getAssessmentsMasteryBySchool = (
    schoolIds: number[],
    assessmentId?: number,
    pageNumber?: number,
    pageSize?: number
  ) => {
    setShowLoader(true);
    if (props.type === "ASSESSMENT") {
      getResultsAssessmentMasteryBySchool(
        props.schoolYearId,
        schoolIds.includes(0) ? null : props.schoolIds,
        assessmentId,
        pageNumber,
        pageSize
      ).then((response) => {
        setShowLoader(false);
        if (!response.data.data) {
          setErrorMessage("No record found");
          setResultDetails([]);
          setTotalRecords(0);
        } else {
          setResultDetails(response.data.data);
          if (props.setResultsReportMasteryData) {
            props.setResultsReportMasteryData(response.data.data);
          }
          setTotalRecords(response.data.data.length);
        }
      });
    } else if (props.type === "PREMIUMASSESSMENT") {
      getResultsPremiumAssessmentMasteryBySchool(
        props.schoolYearId,
        schoolIds.includes(0) ? null : props.schoolIds,
        props.premiumAssessmentId,
        pageNumber,
        pageSize
      ).then((response) => {
        setShowLoader(false);
        if (!response.data.data) {
          setErrorMessage("No record found");
          setResultDetails([]);
          setTotalRecords(0);
        } else {
          setResultDetails(response.data.data);
          if (props.setResultsReportMasteryData) {
            props.setResultsReportMasteryData(response.data.data);
          }
          setTotalRecords(response.data.data.length);
        }
      });
    }
  };

  useEffect(() => {
    if (!isSchoolChanged) {
      getAssessmentsMasteryBySchool(
        props.schoolIds,
        props.assessmentId,
        pageNumber,
        pageSize
      );
    }
  }, [pageSize, pageNumber, resetPageNumber]);

  useEffect(() => {
    if (props.print) {
      setResultDetails([]);
      setIsSchoolChanged(true);
      setPageNumber(DEFAULT_PAGE_NUMBER);
      setPageSize(DEFAULT_PAGE_SIZE);
      setResetPageNumber(false);
      getAssessmentsMasteryBySchool(
        props.schoolIds,
        props.assessmentId,
        DEFAULT_PAGE_NUMBER,
        DEFAULT_PAGE_SIZE
      );
    }
  }, [JSON.stringify(props.schoolIds)]);

  useEffect(() => {
    if (props.resultsReportMasteryData) {
      setResultDetails(props.resultsReportMasteryData);
    }
  }, [props.resultsReportMasteryData]);

  const handleReportPrint = () => {
    if (props.print) {
      props.print();
    }
  };

  return (
    <>
      {showLoader && <Loader></Loader>}
      {errorMessage !== "" && <div className="p-3">{errorMessage}</div>}
      {resultDetails && (
        <div className="mt-5">
          <div className="text-sm text-white font-light bg-secondary-teal px-4 py-2 mr-auto mt-2 ml-3 leading-5 flex overflow-hidden">
            <div className="flex-shrink-0 current-fill mt-0.5">
              <Info />
            </div>
            <div className="pl-2">
              Using the Progress Learning Dot Rank, mastery is defined by
              students earning a green dot. Yellow and pink dots show that
              additional practice is required. Only completed activities are
              included in the data.
            </div>
          </div>
          <div className="flex flex-col w-full justify-end items-center mb-4">
            {props.print && (
              <div
                className={`self-end ${
                  resultDetails?.length === 0 ? "cursor-not-allowed" : ""
                }`}
              >
                <button
                  className={`bg-primary-violet border border-transparent shadow-sm py-2 px-4 inline-flex items-center justify-center text-sm font-medium 
                            text-white hover:bg-dark-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3 
                            ${
                              resultDetails?.length === 0
                                ? "text-white bg-light-violet bg-opacity-20 text-primary-violet text-opacity-40 pointer-events-none"
                                : ""
                            }`}
                  type="button"
                  onClick={() => handleReportPrint()}
                  disabled={resultDetails?.length === 0}
                >
                  <PrintIcon className="w-4 opacity-70"></PrintIcon>
                  <span className="ml-2 text-sm">Print</span>
                </button>
              </div>
            )}
          </div>
          <div>
            <div className="overflow-x-auto xl:overflow-x-visible">
              <table className="table w-full">
                <thead>
                  <tr className="text-primary-violet text-base border-b border-gray-300">
                    <th className="text-left text-base font-semibold p-3 w-[20%]">
                      School
                    </th>
                    <th className="text-center text-base font-semibold p-3 w-[15%]">
                      # Completed
                    </th>
                    <th className="text-center text-sm font-semibold p-3 w-[15%]">
                      Not Mastered
                    </th>
                    <th className="text-center text-sm font-semibold p-3 w-[15%]">
                      Mastered
                    </th>
                    <th className="min-w-[40%]"></th>
                  </tr>
                </thead>
                <tbody>
                  {resultDetails &&
                    resultDetails?.map((activity, index) => {
                      return (
                        <Fragment>
                          <tr className="transition ease-in-out duration-300 border-b border-gray-300 divide-x">
                            <td className="text-sm p-3 ">
                              {activity.schoolName}
                            </td>
                            <td className="text-sm p-3 text-center ">
                              {activity.completedCount}
                            </td>
                            <td className="text-sm p-3 text-center">
                              {(
                                activity.studyNeededPercent +
                                activity.criticalNeedPercent
                              ).toFixed(2)}
                              %
                            </td>
                            <td className="text-sm p-3 text-center">
                              {activity.masteredPercent.toFixed(2)}%
                            </td>
                            <td className="">
                              <div className="flex h-5 min-w-[300px]">
                                <div
                                  className="bg-[#E53F71] h-full flex items-center justify-end p-2"
                                  style={{
                                    width: activity.criticalNeedPercent + "%",
                                    display:
                                      activity.criticalNeedPercent > 0
                                        ? ""
                                        : "none",
                                  }}
                                  title={`Pink = ${activity.criticalNeedPercent}% Not Mastered `}
                                >
                                  {activity.criticalNeedPercent}
                                </div>
                                <div
                                  className="bg-[#FFD83A] h-full flex items-center justify-end p-2"
                                  style={{
                                    width: activity.studyNeededPercent + "%",
                                    display:
                                      activity.studyNeededPercent > 0
                                        ? ""
                                        : "none",
                                  }}
                                  title={`Yellow = ${activity.studyNeededPercent}% Approaching Mastery`}
                                >
                                  {activity.studyNeededPercent}
                                </div>
                                <div
                                  className="bg-[#B2D136] h-full flex items-center justify-end p-2"
                                  style={{
                                    width: activity.masteredPercent + "%",
                                    display:
                                      activity.masteredPercent > 0
                                        ? ""
                                        : "none",
                                  }}
                                  title={`Green = ${activity.masteredPercent}% Mastery Achieved`}
                                >
                                  {activity.masteredPercent}
                                </div>
                              </div>
                            </td>
                          </tr>
                        </Fragment>
                      );
                    })}
                </tbody>
              </table>
            </div>

            {resultDetails?.length === 0 && <NoData />}
            {resultDetails?.length !== 0 && (
              <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between border-t-2 pt-4 mt-3">
                <Pagination
                  totalRecords={totalRecords}
                  pageNumber={pageNumber}
                  pageSize={pageSize}
                  onPageChange={onPageChange}
                  reset={resetPageNumber}
                  setPageNumber={setPageNumber}
                />
                <PageSize
                  pageSize={pageSize}
                  onPageSizeChange={onPageSizeChange}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

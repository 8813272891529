import { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import Loader from "../../../../shared/loader";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import constant from "../../../../../utils/constant/constant";
import { useLocation } from "react-router-dom";
import RouteConstant from "../../../../../utils/constant/routeConstant";
import Breadcrumb from "../../middleSchool/breadcrumb";
import { IBreadcrumb } from "../../../../../model/common/breadcrumb";
import StudyPlanStandardsTab from "./studyPlanStandardsTab";
import { IStudyPlanFocusAreasStandard } from "../../../../../model/interface/studyPlanFocusAreas";
import { getFocusAreas } from "../../../../../api/student/studyPlanApi";
import StudyPlanFocusAreasTab from "./studyPlanFocusAreasTab";

function StudyPlanStandard(props) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [loadingFocusAreas, setLoadingFocusAreas] = useState<boolean>(false);
  const [subjectId] = useState<number>(Number(props.match.params.id));
  const location = useLocation();
  const parameters = location.state as any;
  const [subjectName, setSubjectName] = useState<string>(
    parameters?.subjectName ?? parameters?.selectedSubject ?? ""
  );
  const [selectedStudyType, setSelectedStudyType] = useState<string>(
    constant.StudyType.MYSTUDYPLAN
  );
  const [showFocusAreas, setShowFocusAreas] = useState<boolean>(false);
  const [focusAreas, setFocusAreas] = useState<
    Array<IStudyPlanFocusAreasStandard>
  >([]);
  const themeName = "MIDDLESCHOOL";

  useEffect(() => {
    setSelectedStudyType(constant.StudyType.MYSTUDYPLAN);
    checkFocusAreas();
  }, [subjectId]);

  async function checkFocusAreas() {
    setLoadingFocusAreas(true);
    try {
      const resp = await getFocusAreas(
        props.userContext?.userId ?? 0,
        subjectId
      );
      setFocusAreas(resp.data);
      setShowFocusAreas(resp.data.length > 0);
    } finally {
      setLoadingFocusAreas(false);
    }
  }

  const breadcrumbItems = () => {
    const items: Array<IBreadcrumb> = [
      {
        name: t("Breadcrumb.Home"),
        url: RouteConstant.MiddleSchool.assignments,
        textColor: "",
      },
      {
        name: t("Breadcrumb.StudyPlanSubjects"),
        textColor: "",
        url: RouteConstant.MiddleSchool.subjects,
      },
      {
        name: t("Breadcrumb.StudyPlanStandards"),
        textColor: "",
        url: "",
      },
    ];

    return items;
  };

  return (
    <Fragment>
      {(loading || loadingFocusAreas) && <Loader />}
      <>
        <div className="md:flex md:items-center px-4 py-3 lg:px-8 border-b">
          <Breadcrumb items={breadcrumbItems()} />
          <div className="md:w-[55%] text-lg flex justify-between gap-2 h-auto sm:h-10 items-center">
            <h1 className="text-gray-50 font-medium flex justify-start">
              {t("StudyPlan.StudyPlan")}
            </h1>
          </div>
        </div>
        <div className="px-8 py-4 w-full flex flex-col justify-between md:flex-row">
          <div className="inline-block overflow-hidden border-2 border-white border-opacity-70 mb-auto rounded-lg bg-black bg-opacity-30">
            <button
              className={classNames(
                selectedStudyType === constant.StudyType.MYSTUDYPLAN
                  ? "text-white bg-black bg-opacity-30 font-semibold transition-all"
                  : "text-white text-opacity-90 transition-all inner-btn-shadow font-semibold hover:text-opacity-100",
                " inline-block whitespace-nowrap text-base transition-all text-center pb-2.5 pt-3 px-8 tracking-wide"
              )}
              onClick={() => {
                setSelectedStudyType(constant.StudyType.MYSTUDYPLAN);
              }}
            >
              {t("StudyPlan.StudyPlan")}
            </button>
            {showFocusAreas && (
              <button
                className={classNames(
                  selectedStudyType === constant.StudyType.FOCUSAREAS
                    ? "text-white bg-black bg-opacity-30 font-semibold transition-all"
                    : "text-white text-opacity-90 transition-all font-semibold inner-btn-shadow hover:text-opacity-100",
                  " inline-block whitespace-nowrap text-base transition-all text-center pb-2.5 pt-3 px-8 tracking-wide"
                )}
                onClick={() => {
                  setSelectedStudyType(constant.StudyType.FOCUSAREAS);
                }}
              >
                {t("StudyType.FocusAreas")}
              </button>
            )}
          </div>
          {selectedStudyType === constant.StudyType.FOCUSAREAS && (
            <div className="px-5 max-w-lg py-4 bg-white bg-opacity-90 rounded-lg text-gray-500">
              <div className="flex gap-x-3 mb-1.5">
                <div className="text-sm flex items-center font-medium">
                  <label className="bg-[#FFDB3A] p-2 rounded mr-1.5 border border-gray-300">
                    {" "}
                  </label>
                  {t("StudyPlan.ApproachingMastery")}
                </div>
                <div className="text-sm flex items-center font-medium">
                  <label className="bg-[#E53F71] p-2 rounded mr-1.5 border border-gray-300">
                    {" "}
                  </label>
                  {t("Diagnostics.Critical")}
                </div>
              </div>
              <div>{t("StudyPlan.Earn80%OrMore")}</div>
            </div>
          )}
        </div>
        {selectedStudyType === constant.StudyType.MYSTUDYPLAN && (
          <StudyPlanStandardsTab
            setLoading={setLoading}
            userContext={props.userContext}
            profile={props.profile}
            subjectName={subjectName}
            subjectId={subjectId}
            setSubjectName={setSubjectName}
            themeName={themeName}
          />
        )}
        {selectedStudyType === constant.StudyType.FOCUSAREAS && (
          <StudyPlanFocusAreasTab
            setLoading={setLoadingFocusAreas}
            userContext={props.userContext}
            profile={props.profile}
            subjectName={subjectName}
            subjectId={subjectId}
            themeName={themeName}
            focusAreas={focusAreas}
          />
        )}
      </>
    </Fragment>
  );
}
const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(StudyPlanStandard);

import { LiftOffIcon, LogoPLIcon } from "../../../../../assets/icons";
import { useHistory, useLocation } from "react-router-dom";
import RouteConstant from "../../../../../utils/constant/routeConstant";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import Constant from "../../../../../utils/constant/constant";
import { HomeIcon, PaperAirplaneIcon } from "@heroicons/react/outline";
import { connect } from "react-redux";

function HeaderLeft(props: any) {
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const [activeLink, setActiveLink] = useState(RouteConstant.STUDENT);
  const [programMode, setProgramMode] = useState(
    localStorage.getItem("programMode")
      ? localStorage.getItem("programMode")
      : Constant.ProgramMode.EG
  );

  function handleNaviagation(link: string, programMode?: string) {
    if (programMode) {
      setProgramMode(programMode);
      localStorage.setItem("programMode", programMode);
    }

    if (props.onProgramModeChange !== undefined && programMode) {
      props.onProgramModeChange(programMode);
    }
    setActiveLink(link);
    history.push(link);
  }

  const gradeLevel =
    location.pathname.toLowerCase().indexOf("kindergarten") > -1
      ? "kindergarten"
      : location.pathname.toLowerCase().indexOf("elementary") > -1
      ? "elementary"
      : location.pathname.toLowerCase().indexOf("middleschool") > -1
      ? "middleschool"
      : "elementary";

  const activeLinkClass =
    "w-full space-x-2 items-center justify-start bg-white text-gray-900 group flex px-1.5 py-3 text-sm border-[3px] bg-opacity-90 border-orange-dark border-l-0 rounded-r-2xl shadow-2xl";
  const inActiveLinkClass =
    "w-full space-x-2 items-center justify-start text-gray-900 group flex px-1.5  py-3 text-sm rounded-r-2xl hover:bg-white hover:bg-opacity-50 transition ease-in-out duration-500 group";

  const activeLinkIconClass = "w-5 flex-none text-gray-500";
  const inActiveLinkIconClass = "w-5 flex-none text-gray-900";

  return (
    <div className={`w-36 ${(gradeLevel === "elementary" || gradeLevel === "middleschool") ? "hidden " : ""}pr-4 fixed `}>
      <div className="w-full">
        <div className="flex-shrink-0 flex items-center flex-col gap-y-3">
          {gradeLevel === "kindergarten" && (
            <>
              {programMode === Constant.ProgramMode.LO && (
                <>
                  <button
                    onClick={() =>
                      handleNaviagation(
                        RouteConstant.LiftOff.Kindergarten.Home,
                        Constant.ProgramMode.LO
                      )
                    }
                    className="mt-5 filter"
                  >
                    <LiftOffIcon
                      title="Liftoff"
                      className="block lg:block h-20 w-auto"
                    ></LiftOffIcon>
                  </button>
                  <button
                    onClick={() =>
                      handleNaviagation(
                        RouteConstant.KindergartenStudentRoutes.Home,
                        Constant.ProgramMode.EG
                      )
                    }
                    className="mt-2 filter grayscale"
                  >
                    {/* {props.userContext.stateId ===
                      Configuration.TexasStateId && (
                      <>
                        <img
                          className="block lg:hidden h-8 w-auto"
                          src={`${Configuration.S3bucketImagePath}/images/EG-poweredby-PL-logo-black.png`}
                          alt="Education Galaxy powered by Progress Learning logo"
                          title="Education Galaxy powered by Progress Learning logo"
                        ></img>
                        <img
                          className="block lg:block h-16 w-auto"
                          src={`${Configuration.S3bucketImagePath}/images/EG-poweredby-PL-logo-black.png`}
                          alt="Education Galaxy powered by Progress Learning logo"
                          title="Education Galaxy powered by Progress Learning logo"
                        ></img>
                      </>
                    )} */}
                    {/* {props.userContext.stateId !==
                      Configuration.TexasStateId && ( */}
                    <LogoPLIcon
                      title="Progress Learning logo"
                      className="block lg:block h-12 w-auto"
                    ></LogoPLIcon>
                    {/* )} */}
                  </button>
                </>
              )}
              {programMode !== Constant.ProgramMode.LO && (
                <>
                  <button
                    onClick={() =>
                      handleNaviagation(
                        RouteConstant.KindergartenStudentRoutes.Home,
                        Constant.ProgramMode.EG
                      )
                    }
                  >
                    {/* {props.userContext.stateId ===
                      Configuration.TexasStateId && (
                      <>
                        <img
                          className="block lg:hidden h-8 w-auto"
                          src={`${Configuration.S3bucketImagePath}/images/EG-poweredby-PL-logo-black.png`}
                          alt="Education Galaxy powered by Progress Learning logo"
                          title="Education Galaxy powered by Progress Learning logo"
                        ></img>
                        <img
                          className="block lg:block h-16 w-auto"
                          src={`${Configuration.S3bucketImagePath}/images/EG-poweredby-PL-logo-black.png`}
                          alt="Education Galaxy powered by Progress Learning logo"
                          title="Education Galaxy powered by Progress Learning logo"
                        ></img>
                      </>
                    )} */}
                    {/* {props.userContext.stateId !==
                      Configuration.TexasStateId && ( */}
                    <LogoPLIcon
                      title="Progress Learning logo"
                      className="block lg:block h-12 w-auto"
                    ></LogoPLIcon>
                    {/* )} */}
                  </button>

                  {props.profile?.isLOActive && (
                    <button
                      onClick={() =>
                        handleNaviagation(
                          RouteConstant.LiftOff.Kindergarten.Home,
                          Constant.ProgramMode.LO
                        )
                      }
                      className="filter grayscale"
                    >
                      <LiftOffIcon
                        title="Liftoff"
                        className="block lg:block h-20 w-auto"
                      ></LiftOffIcon>
                    </button>
                  )}
                </>
              )}
            </>
          )}

          {gradeLevel === "elementary" && programMode === Constant.ProgramMode.LO && (
            <>
              {programMode === Constant.ProgramMode.LO && (
                <>
                  <button
                    onClick={() =>
                      handleNaviagation(
                        RouteConstant.LiftOff.Elementary.Home,
                        Constant.ProgramMode.LO
                      )
                    }
                    className="filter"
                  >
                    <LiftOffIcon
                      title="Liftoff"
                      className="block lg:block h-20 w-auto"
                    ></LiftOffIcon>
                  </button>
                  <button
                    className="filter grayscale"
                    onClick={() =>
                      handleNaviagation(
                        RouteConstant.StudentRoute.Home,
                        Constant.ProgramMode.EG
                      )
                    }
                  >
                    {/* {props.userContext.stateId ===
                      Configuration.TexasStateId && (
                      <>
                        <img
                          className="block lg:hidden h-8 w-auto"
                          src={`${Configuration.S3bucketImagePath}/images/EG-poweredby-PL-logo-black.png`}
                          alt="Education Galaxy powered by Progress Learning logo"
                          title="Education Galaxy powered by Progress Learning logo"
                        ></img>
                        <img
                          className="block lg:block h-16 w-auto"
                          src={`${Configuration.S3bucketImagePath}/images/EG-poweredby-PL-logo-black.png`}
                          alt="Education Galaxy powered by Progress Learning logo"
                          title="Education Galaxy powered by Progress Learning logo"
                        ></img>
                      </>
                    )} */}
                    {/* {props.userContext.stateId !==
                      Configuration.TexasStateId && ( */}
                    <>
                      <LogoPLIcon
                        title="Progress Learning logo"
                        className="block lg:block h-12 w-auto"
                      ></LogoPLIcon>
                    </>
                    {/* )} */}
                  </button>
                </>
              )}
              {programMode !== Constant.ProgramMode.LO && (
                <>
                  <button
                    onClick={() =>
                      handleNaviagation(
                        RouteConstant.StudentRoute.Home,
                        Constant.ProgramMode.EG
                      )
                    }
                  >
                    {/* {props.userContext.stateId ===
                      Configuration.TexasStateId && (
                      <>
                        <img
                          className="block lg:hidden h-8 w-auto"
                          src={`${Configuration.S3bucketImagePath}/images/EG-poweredby-PL-logo-black.png`}
                          alt="Education Galaxy powered by Progress Learning logo"
                          title="Education Galaxy powered by Progress Learning logo"
                        ></img>
                        <img
                          className="block lg:block h-16 w-auto"
                          src={`${Configuration.S3bucketImagePath}/images/EG-poweredby-PL-logo-black.png`}
                          alt="Education Galaxy powered by Progress Learning logo"
                          title="Education Galaxy powered by Progress Learning logo"
                        ></img>
                      </>
                    )} */}
                    {/* {props.userContext.stateId !==
                      Configuration.TexasStateId && ( */}
                    <>
                      <LogoPLIcon
                        title="Progress Learning"
                        className="block lg:block h-12 w-auto"
                      ></LogoPLIcon>
                    </>
                    {/* )} */}
                  </button>

                  {props.profile?.isLOActive && (
                    <button
                      onClick={() =>
                        handleNaviagation(
                          RouteConstant.LiftOff.Elementary.Home,
                          Constant.ProgramMode.LO
                        )
                      }
                      className="filter grayscale"
                    >
                      <LiftOffIcon
                        title="Liftoff"
                        className="block lg:block h-20 w-auto"
                      ></LiftOffIcon>
                    </button>
                  )}
                </>
              )}
            </>
          )}
          {gradeLevel === "middleschool" && (
            <>
              {programMode === Constant.ProgramMode.LO && (
                <>
                  <button
                    onClick={() =>
                      handleNaviagation(
                        RouteConstant.LiftOff.MiddleSchool.Home,
                        Constant.ProgramMode.LO
                      )
                    }
                    className="mt-5 filter"
                  >
                    <LiftOffIcon
                      title="Liftoff"
                      className="block lg:block h-20 w-auto"
                    ></LiftOffIcon>
                  </button>
                  <button
                    className="filter grayscale"
                    onClick={() =>
                      handleNaviagation(
                        RouteConstant.MiddleSchool.assignments,
                        Constant.ProgramMode.EG
                      )
                    }
                  >
                    <LogoPLIcon
                      title="Progress Learning"
                      className="block lg:block h-12 w-auto"
                    ></LogoPLIcon>
                  </button>
                </>
              )}
              {programMode !== Constant.ProgramMode.LO && (
                <>
                  <button
                    onClick={() =>
                      handleNaviagation(
                        RouteConstant.MiddleSchool.assignments,
                        Constant.ProgramMode.EG
                      )
                    }
                  >
                    <LogoPLIcon
                      title="Progress Learning"
                      className="block lg:block h-12 w-auto"
                    ></LogoPLIcon>
                  </button>
                  {props.profile?.isLOActive && (
                    <button
                      onClick={() =>
                        handleNaviagation(
                          RouteConstant.LiftOff.MiddleSchool.Home,
                          Constant.ProgramMode.LO
                        )
                      }
                      className="filter grayscale"
                    >
                      <LiftOffIcon
                        title="Liftoff"
                        className="block lg:block h-20 w-auto"
                      ></LiftOffIcon>
                    </button>
                  )}
                </>
              )}
            </>
          )}
        </div>

        {/* navbar */}
        {gradeLevel === "kindergarten" && (
          <>
            {programMode === Constant.ProgramMode.EG && (
              <nav
                className="flex-1 space-y-4 mt-24 hidden sm:block"
                aria-label="Sidebar"
              >
                <button
                  onClick={() =>
                    handleNaviagation(RouteConstant.LiftOff.Kindergarten.Home)
                  }
                  className={
                    activeLink === RouteConstant.STUDENT
                      ? activeLinkClass
                      : inActiveLinkClass
                  }
                >
                  <HomeIcon className="w-5" />
                  <span className="text-center pt-1">
                    {t("Shared.StudentSideBar.Home")}
                  </span>
                </button>
                <button
                  onClick={() =>
                    handleNaviagation(RouteConstant.LiftOff.Kindergarten.MyStat)
                  }
                  className="hidden"
                  // {
                  //   activeLink === RouteConstant.MYSTAT
                  //     ? activeLinkClass
                  //     : inActiveLinkClass
                  // }
                >
                  <svg
                    version="1.0"
                    className="w-5 h-5 flex-none"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 48.000000 48.000000"
                    preserveAspectRatio="xMidYMid meet"
                  >
                    <g
                      transform="translate(0.000000,48.000000) scale(0.100000,-0.100000)"
                      fill="currentColor"
                      stroke="none"
                    >
                      <path
                        d="M30 275 l0 -145 85 0 c84 0 85 0 85 -25 0 -22 -4 -25 -35 -25 -19 0
-35 -4 -35 -10 0 -6 43 -10 110 -10 67 0 110 4 110 10 0 6 -16 10 -35 10 -31
0 -35 3 -35 25 0 25 1 25 85 25 l85 0 0 145 0 145 -210 0 -210 0 0 -145z m400
0 l0 -125 -190 0 -190 0 0 125 0 125 190 0 190 0 0 -125z m-170 -170 c0 -18
-5 -25 -20 -25 -15 0 -20 7 -20 25 0 18 5 25 20 25 15 0 20 -7 20 -25z"
                      ></path>
                      <path
                        d="M170 320 c0 -36 -2 -40 -25 -40 -22 0 -25 -4 -25 -35 0 -22 -6 -38
-15 -41 -42 -17 0 -24 135 -24 135 0 177 7 135 24 -11 4 -15 22 -15 71 l0 65
-35 0 c-24 0 -35 -5 -35 -15 0 -8 -9 -15 -20 -15 -15 0 -20 7 -20 25 0 23 -4
25 -40 25 l-40 0 0 -40z m60 -50 c0 -63 -2 -70 -20 -70 -18 0 -20 7 -20 70 0
63 2 70 20 70 18 0 20 -7 20 -70z m110 -10 c0 -47 -3 -60 -15 -60 -12 0 -15
13 -15 60 0 47 3 60 15 60 12 0 15 -13 15 -60z m-50 -15 c0 -38 -3 -45 -20
-45 -17 0 -20 7 -20 45 0 38 3 45 20 45 17 0 20 -7 20 -45z m-120 -15 c0 -20
-5 -30 -15 -30 -10 0 -15 10 -15 30 0 20 5 30 15 30 10 0 15 -10 15 -30z"
                      ></path>
                    </g>
                  </svg>
                  <span className="text-left pt-1">
                    {t("Shared.StudentSideBar.MyStats")}
                  </span>
                </button>
                <button
                  onClick={() =>
                    handleNaviagation(
                      RouteConstant.LiftOff.Kindergarten.Leaderboard
                    )
                  }
                  className="hidden"
                  // {
                  //   activeLink === RouteConstant.LEADERBOARD
                  //     ? activeLinkClass
                  //     : inActiveLinkClass
                  // }
                >
                  <PaperAirplaneIcon className="w-5 flex-none" />
                  <span className="text-left pt-1">
                    {t("Shared.StudentSideBar.Leaderboard")}
                  </span>
                </button>
              </nav>
            )}
            {programMode === Constant.ProgramMode.LO && (
              <nav
                className="flex-1 space-y-4 mt-24 hidden sm:block"
                aria-label="Sidebar"
              >
                <button
                  onClick={() =>
                    handleNaviagation(RouteConstant.LiftOff.Kindergarten.Home)
                  }
                  className={
                    activeLink === RouteConstant.STUDENT
                      ? activeLinkClass
                      : inActiveLinkClass
                  }
                >
                  <HomeIcon className="w-5" />
                  <span className="text-left pt-1">
                    {t("Shared.StudentSideBar.Home")}
                  </span>
                </button>
                <button
                  onClick={() =>
                    handleNaviagation(RouteConstant.LiftOff.Kindergarten.MyStat)
                  }
                  className={`hidden ${
                    activeLink === RouteConstant.MYSTAT
                      ? activeLinkClass
                      : inActiveLinkClass
                  }`}
                >
                  <svg
                    version="1.0"
                    className="w-5 h-5 flex-none"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 48.000000 48.000000"
                    preserveAspectRatio="xMidYMid meet"
                  >
                    <g
                      transform="translate(0.000000,48.000000) scale(0.100000,-0.100000)"
                      fill="currentColor"
                      stroke="none"
                    >
                      <path
                        d="M30 275 l0 -145 85 0 c84 0 85 0 85 -25 0 -22 -4 -25 -35 -25 -19 0
-35 -4 -35 -10 0 -6 43 -10 110 -10 67 0 110 4 110 10 0 6 -16 10 -35 10 -31
0 -35 3 -35 25 0 25 1 25 85 25 l85 0 0 145 0 145 -210 0 -210 0 0 -145z m400
0 l0 -125 -190 0 -190 0 0 125 0 125 190 0 190 0 0 -125z m-170 -170 c0 -18
-5 -25 -20 -25 -15 0 -20 7 -20 25 0 18 5 25 20 25 15 0 20 -7 20 -25z"
                      ></path>
                      <path
                        d="M170 320 c0 -36 -2 -40 -25 -40 -22 0 -25 -4 -25 -35 0 -22 -6 -38
-15 -41 -42 -17 0 -24 135 -24 135 0 177 7 135 24 -11 4 -15 22 -15 71 l0 65
-35 0 c-24 0 -35 -5 -35 -15 0 -8 -9 -15 -20 -15 -15 0 -20 7 -20 25 0 23 -4
25 -40 25 l-40 0 0 -40z m60 -50 c0 -63 -2 -70 -20 -70 -18 0 -20 7 -20 70 0
63 2 70 20 70 18 0 20 -7 20 -70z m110 -10 c0 -47 -3 -60 -15 -60 -12 0 -15
13 -15 60 0 47 3 60 15 60 12 0 15 -13 15 -60z m-50 -15 c0 -38 -3 -45 -20
-45 -17 0 -20 7 -20 45 0 38 3 45 20 45 17 0 20 -7 20 -45z m-120 -15 c0 -20
-5 -30 -15 -30 -10 0 -15 10 -15 30 0 20 5 30 15 30 10 0 15 -10 15 -30z"
                      ></path>
                    </g>
                  </svg>
                  <span className="text-left pt-1">
                    {t("Shared.StudentSideBar.MyStats")}
                  </span>
                </button>
                <button
                  onClick={() =>
                    handleNaviagation(
                      RouteConstant.LiftOff.Kindergarten.Leaderboard
                    )
                  }
                  className={
                    activeLink === RouteConstant.LEADERBOARD
                      ? activeLinkClass
                      : inActiveLinkClass
                  }
                >
                  <PaperAirplaneIcon className="w-5 flex-none" />
                  <span className="text-left pt-1">
                    {t("Shared.StudentSideBar.Leaderboard")}
                  </span>
                </button>
              </nav>
            )}
          </>
        )}
        {gradeLevel === "elementary" && programMode === Constant.ProgramMode.LO && (
          <>
            {programMode === Constant.ProgramMode.EG && (
              <nav
                className="flex-1 space-y-4 mt-24 hidden sm:block"
                aria-label="Sidebar"
              >
                <button
                  onClick={() =>
                    handleNaviagation(RouteConstant.StudentRoute.Home)
                  }
                  className={
                    activeLink === RouteConstant.StudentRoute.Home ||
                    activeLink === RouteConstant.STUDENT
                      ? activeLinkClass
                      : inActiveLinkClass
                  }
                >
                  <HomeIcon
                    className={
                      activeLink === RouteConstant.StudentRoute.Home ||
                      activeLink === RouteConstant.STUDENT
                        ? activeLinkIconClass
                        : inActiveLinkIconClass
                    }
                  />
                  <span className="text-left">
                    {t("Shared.StudentSideBar.Home")}
                  </span>
                </button>
                <button
                  onClick={() => handleNaviagation(RouteConstant.MYSTAT)}
                  className={`hidden
                  ${
                    activeLink === RouteConstant.MYSTAT
                      ? activeLinkClass
                      : inActiveLinkClass
                  }`}
                >
                  <svg
                    version="1.0"
                    className={
                      activeLink === RouteConstant.MYSTAT
                        ? activeLinkIconClass
                        : inActiveLinkIconClass
                    }
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 48.000000 48.000000"
                    preserveAspectRatio="xMidYMid meet"
                  >
                    <g
                      transform="translate(0.000000,48.000000) scale(0.100000,-0.100000)"
                      fill="currentColor"
                      stroke="none"
                    >
                      <path
                        d="M30 275 l0 -145 85 0 c84 0 85 0 85 -25 0 -22 -4 -25 -35 -25 -19 0
-35 -4 -35 -10 0 -6 43 -10 110 -10 67 0 110 4 110 10 0 6 -16 10 -35 10 -31
0 -35 3 -35 25 0 25 1 25 85 25 l85 0 0 145 0 145 -210 0 -210 0 0 -145z m400
0 l0 -125 -190 0 -190 0 0 125 0 125 190 0 190 0 0 -125z m-170 -170 c0 -18
-5 -25 -20 -25 -15 0 -20 7 -20 25 0 18 5 25 20 25 15 0 20 -7 20 -25z"
                      ></path>
                      <path
                        d="M170 320 c0 -36 -2 -40 -25 -40 -22 0 -25 -4 -25 -35 0 -22 -6 -38
-15 -41 -42 -17 0 -24 135 -24 135 0 177 7 135 24 -11 4 -15 22 -15 71 l0 65
-35 0 c-24 0 -35 -5 -35 -15 0 -8 -9 -15 -20 -15 -15 0 -20 7 -20 25 0 23 -4
25 -40 25 l-40 0 0 -40z m60 -50 c0 -63 -2 -70 -20 -70 -18 0 -20 7 -20 70 0
63 2 70 20 70 18 0 20 -7 20 -70z m110 -10 c0 -47 -3 -60 -15 -60 -12 0 -15
13 -15 60 0 47 3 60 15 60 12 0 15 -13 15 -60z m-50 -15 c0 -38 -3 -45 -20
-45 -17 0 -20 7 -20 45 0 38 3 45 20 45 17 0 20 -7 20 -45z m-120 -15 c0 -20
-5 -30 -15 -30 -10 0 -15 10 -15 30 0 20 5 30 15 30 10 0 15 -10 15 -30z"
                      ></path>
                    </g>
                  </svg>
                  <span className="text-left">
                    {t("Shared.StudentSideBar.MyStats")}
                  </span>
                </button>
                <button
                  onClick={() => handleNaviagation(RouteConstant.LEADERBOARD)}
                  className={`hidden
                  ${
                    activeLink === RouteConstant.LEADERBOARD
                      ? activeLinkClass
                      : inActiveLinkClass
                  }`}
                >
                  <PaperAirplaneIcon
                    className={
                      activeLink === RouteConstant.LEADERBOARD
                        ? activeLinkIconClass
                        : inActiveLinkIconClass
                    }
                  />
                  <span className="text-left">
                    {t("Shared.StudentSideBar.Leaderboard")}
                  </span>
                </button>
              </nav>
            )}
            {programMode === Constant.ProgramMode.LO && (
              <nav
                className="flex-1 space-y-4 mt-24 hidden sm:block"
                aria-label="Sidebar"
              >
                <button
                  onClick={() =>
                    handleNaviagation(RouteConstant.LiftOff.Elementary.Home)
                  }
                  className={
                    activeLink === RouteConstant.LiftOff.Elementary.Home ||
                    activeLink === RouteConstant.STUDENT
                      ? activeLinkClass
                      : inActiveLinkClass
                  }
                >
                  <HomeIcon
                    className={
                      activeLink === RouteConstant.LiftOff.Elementary.Home ||
                      activeLink === RouteConstant.STUDENT
                        ? activeLinkIconClass
                        : inActiveLinkIconClass
                    }
                  />
                  <span className="text-left">
                    {t("Shared.StudentSideBar.Home")}
                  </span>
                </button>
                <button
                  onClick={() =>
                    handleNaviagation(RouteConstant.LiftOff.Elementary.MyStat)
                  }
                  className={` hidden 
                  ${
                    activeLink === RouteConstant.LiftOff.Elementary.MyStat
                      ? activeLinkClass
                      : inActiveLinkClass
                  }`}
                >
                  <svg
                    version="1.0"
                    className={
                      activeLink === RouteConstant.LiftOff.Elementary.MyStat
                        ? activeLinkIconClass
                        : inActiveLinkIconClass
                    }
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 48.000000 48.000000"
                    preserveAspectRatio="xMidYMid meet"
                  >
                    <g
                      transform="translate(0.000000,48.000000) scale(0.100000,-0.100000)"
                      fill="currentColor"
                      stroke="none"
                    >
                      <path
                        d="M30 275 l0 -145 85 0 c84 0 85 0 85 -25 0 -22 -4 -25 -35 -25 -19 0
-35 -4 -35 -10 0 -6 43 -10 110 -10 67 0 110 4 110 10 0 6 -16 10 -35 10 -31
0 -35 3 -35 25 0 25 1 25 85 25 l85 0 0 145 0 145 -210 0 -210 0 0 -145z m400
0 l0 -125 -190 0 -190 0 0 125 0 125 190 0 190 0 0 -125z m-170 -170 c0 -18
-5 -25 -20 -25 -15 0 -20 7 -20 25 0 18 5 25 20 25 15 0 20 -7 20 -25z"
                      ></path>
                      <path
                        d="M170 320 c0 -36 -2 -40 -25 -40 -22 0 -25 -4 -25 -35 0 -22 -6 -38
-15 -41 -42 -17 0 -24 135 -24 135 0 177 7 135 24 -11 4 -15 22 -15 71 l0 65
-35 0 c-24 0 -35 -5 -35 -15 0 -8 -9 -15 -20 -15 -15 0 -20 7 -20 25 0 23 -4
25 -40 25 l-40 0 0 -40z m60 -50 c0 -63 -2 -70 -20 -70 -18 0 -20 7 -20 70 0
63 2 70 20 70 18 0 20 -7 20 -70z m110 -10 c0 -47 -3 -60 -15 -60 -12 0 -15
13 -15 60 0 47 3 60 15 60 12 0 15 -13 15 -60z m-50 -15 c0 -38 -3 -45 -20
-45 -17 0 -20 7 -20 45 0 38 3 45 20 45 17 0 20 -7 20 -45z m-120 -15 c0 -20
-5 -30 -15 -30 -10 0 -15 10 -15 30 0 20 5 30 15 30 10 0 15 -10 15 -30z"
                      ></path>
                    </g>
                  </svg>
                  <span className="text-left">
                    {t("Shared.StudentSideBar.MyStats")}
                  </span>
                </button>
                <button
                  onClick={() =>
                    handleNaviagation(
                      RouteConstant.LiftOff.Elementary.Leaderboard
                    )
                  }
                  className={`hidden
                  ${
                    activeLink === RouteConstant.LEADERBOARD
                      ? activeLinkClass
                      : inActiveLinkClass
                  }`}
                >
                  <PaperAirplaneIcon
                    className={
                      activeLink === RouteConstant.LEADERBOARD
                        ? activeLinkIconClass
                        : inActiveLinkIconClass
                    }
                  />
                  <span className="text-left">
                    {t("Shared.StudentSideBar.Leaderboard")}
                  </span>
                </button>
              </nav>
            )}
          </>
        )}
        {gradeLevel === "middleschool" && (
          <>
            {programMode === Constant.ProgramMode.EG && (
              <nav
                className="flex-1 space-y-2 mt-24 hidden sm:block"
                aria-label="Sidebar"
              >
                <button
                  onClick={() => handleNaviagation(RouteConstant.STUDENT)}
                  className={
                    activeLink === RouteConstant.STUDENT
                      ? activeLinkClass
                      : inActiveLinkClass
                  }
                >
                  <HomeIcon className="w-5" />
                  <span className="text-left pt-1">
                    {t("Shared.StudentSideBar.Home")}
                  </span>
                </button>
                <button
                  onClick={() =>
                    handleNaviagation(RouteConstant.LiftOff.MiddleSchool.MyStat)
                  }
                  className="hidden"
                  // {
                  //   activeLink === RouteConstant.MYSTAT
                  //     ? activeLinkClass
                  //     : inActiveLinkClass
                  // }
                >
                  <svg
                    version="1.0"
                    className="w-5 h-5 flex-none"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 48.000000 48.000000"
                    preserveAspectRatio="xMidYMid meet"
                  >
                    <g
                      transform="translate(0.000000,48.000000) scale(0.100000,-0.100000)"
                      fill="currentColor"
                      stroke="none"
                    >
                      <path
                        d="M30 275 l0 -145 85 0 c84 0 85 0 85 -25 0 -22 -4 -25 -35 -25 -19 0
-35 -4 -35 -10 0 -6 43 -10 110 -10 67 0 110 4 110 10 0 6 -16 10 -35 10 -31
0 -35 3 -35 25 0 25 1 25 85 25 l85 0 0 145 0 145 -210 0 -210 0 0 -145z m400
0 l0 -125 -190 0 -190 0 0 125 0 125 190 0 190 0 0 -125z m-170 -170 c0 -18
-5 -25 -20 -25 -15 0 -20 7 -20 25 0 18 5 25 20 25 15 0 20 -7 20 -25z"
                      ></path>
                      <path
                        d="M170 320 c0 -36 -2 -40 -25 -40 -22 0 -25 -4 -25 -35 0 -22 -6 -38
-15 -41 -42 -17 0 -24 135 -24 135 0 177 7 135 24 -11 4 -15 22 -15 71 l0 65
-35 0 c-24 0 -35 -5 -35 -15 0 -8 -9 -15 -20 -15 -15 0 -20 7 -20 25 0 23 -4
25 -40 25 l-40 0 0 -40z m60 -50 c0 -63 -2 -70 -20 -70 -18 0 -20 7 -20 70 0
63 2 70 20 70 18 0 20 -7 20 -70z m110 -10 c0 -47 -3 -60 -15 -60 -12 0 -15
13 -15 60 0 47 3 60 15 60 12 0 15 -13 15 -60z m-50 -15 c0 -38 -3 -45 -20
-45 -17 0 -20 7 -20 45 0 38 3 45 20 45 17 0 20 -7 20 -45z m-120 -15 c0 -20
-5 -30 -15 -30 -10 0 -15 10 -15 30 0 20 5 30 15 30 10 0 15 -10 15 -30z"
                      ></path>
                    </g>
                  </svg>
                  <span className="text-left pt-1">
                    {t("Shared.StudentSideBar.MyStats")}
                  </span>
                </button>
                <button
                  onClick={() =>
                    handleNaviagation(
                      RouteConstant.LiftOff.MiddleSchool.Leaderboard
                    )
                  }
                  className={
                    activeLink === RouteConstant.LEADERBOARD
                      ? activeLinkClass
                      : inActiveLinkClass
                  }
                >
                  <PaperAirplaneIcon className="w-5 flex-none" />
                  <span className="text-left pt-1">
                    {t("Shared.StudentSideBar.Leaderboard")}
                  </span>
                </button>
              </nav>
            )}
            {programMode === Constant.ProgramMode.LO && (
              <nav
                className="flex-1 space-y-4 mt-24 hidden sm:block"
                aria-label="Sidebar"
              >
                <button
                  onClick={() =>
                    handleNaviagation(RouteConstant.LiftOff.MiddleSchool.Home)
                  }
                  className={
                    activeLink === RouteConstant.LiftOff.MiddleSchool.Home ||
                    activeLink === RouteConstant.STUDENT
                      ? activeLinkClass
                      : inActiveLinkClass
                  }
                >
                  <HomeIcon
                    className={
                      activeLink === RouteConstant.LiftOff.MiddleSchool.Home ||
                      activeLink === RouteConstant.STUDENT
                        ? activeLinkIconClass
                        : inActiveLinkIconClass
                    }
                  />
                  <span className="text-left">
                    {t("Shared.StudentSideBar.Home")}
                  </span>
                </button>
                <button
                  onClick={() =>
                    handleNaviagation(RouteConstant.LiftOff.MiddleSchool.MyStat)
                  }
                  className={` hidden ${
                    activeLink === RouteConstant.LiftOff.MiddleSchool.MyStat
                      ? activeLinkClass
                      : inActiveLinkClass
                  }`}
                >
                  <svg
                    version="1.0"
                    className={
                      activeLink === RouteConstant.LiftOff.MiddleSchool.MyStat
                        ? activeLinkIconClass
                        : inActiveLinkIconClass
                    }
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 48.000000 48.000000"
                    preserveAspectRatio="xMidYMid meet"
                  >
                    <g
                      transform="translate(0.000000,48.000000) scale(0.100000,-0.100000)"
                      fill="currentColor"
                      stroke="none"
                    >
                      <path
                        d="M30 275 l0 -145 85 0 c84 0 85 0 85 -25 0 -22 -4 -25 -35 -25 -19 0
-35 -4 -35 -10 0 -6 43 -10 110 -10 67 0 110 4 110 10 0 6 -16 10 -35 10 -31
0 -35 3 -35 25 0 25 1 25 85 25 l85 0 0 145 0 145 -210 0 -210 0 0 -145z m400
0 l0 -125 -190 0 -190 0 0 125 0 125 190 0 190 0 0 -125z m-170 -170 c0 -18
-5 -25 -20 -25 -15 0 -20 7 -20 25 0 18 5 25 20 25 15 0 20 -7 20 -25z"
                      ></path>
                      <path
                        d="M170 320 c0 -36 -2 -40 -25 -40 -22 0 -25 -4 -25 -35 0 -22 -6 -38
-15 -41 -42 -17 0 -24 135 -24 135 0 177 7 135 24 -11 4 -15 22 -15 71 l0 65
-35 0 c-24 0 -35 -5 -35 -15 0 -8 -9 -15 -20 -15 -15 0 -20 7 -20 25 0 23 -4
25 -40 25 l-40 0 0 -40z m60 -50 c0 -63 -2 -70 -20 -70 -18 0 -20 7 -20 70 0
63 2 70 20 70 18 0 20 -7 20 -70z m110 -10 c0 -47 -3 -60 -15 -60 -12 0 -15
13 -15 60 0 47 3 60 15 60 12 0 15 -13 15 -60z m-50 -15 c0 -38 -3 -45 -20
-45 -17 0 -20 7 -20 45 0 38 3 45 20 45 17 0 20 -7 20 -45z m-120 -15 c0 -20
-5 -30 -15 -30 -10 0 -15 10 -15 30 0 20 5 30 15 30 10 0 15 -10 15 -30z"
                      ></path>
                    </g>
                  </svg>
                  <span className="text-left">
                    {t("Shared.StudentSideBar.MyStats")}
                  </span>
                </button>
                <button
                  onClick={() =>
                    handleNaviagation(
                      RouteConstant.LiftOff.MiddleSchool.Leaderboard
                    )
                  }
                  className="hidden"
                  // {
                  //   activeLink === RouteConstant.LEADERBOARD
                  //     ? activeLinkClass
                  //     : inActiveLinkClass
                  // }
                >
                  <PaperAirplaneIcon className="w-5 flex-none" />
                  <span className="text-left">
                    {t("Shared.StudentSideBar.Leaderboard")}
                  </span>
                </button>
              </nav>
            )}
          </>
        )}
      </div>
    </div>
  );
}
const mapStateToProps = (state: any) => {
  return {
    masterData: state.masterData,
    userContext: state.userContext,
    profile: state.profile,
  };
};
export default connect<{}, {}, {}>(mapStateToProps, {})(HeaderLeft);

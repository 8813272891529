import { AxiosHelper } from "../../environment/axiosConfig"
import { AxiosResponse } from "axios";
import APIConstant from "../../utils/constant/apiConstant"
import qs from "qs"
import { IGameSessionItems, IGameSessionRequest, IGameSessionResponse, IGameSessionTeamNamesRequest, IGameSessionUpdateRequest, IGameSessionsRequest, IGameSessionsResponse, IMiniBreak, ISelectedStandards, ISessionTeam, ISessionTeams, ISessionTeamsNames, IDomainStandardDTO } from "../../model/interface/classroomGame/thinkOrSwimSetup";
import { IResult } from "../../model/interface/IResult";
import PagingResponse from "../../model/common/pagingResponse";

export type setupAction = "Create" | "Duplicate" | "Edit";
export type TOSScreens = "Sessions" | "Setup" | "GamePlay";

export const GetTeamNames = (
  classroomGameId: number,
  requestedCount: number,
  excludedNames: Array<string> = []
): Promise<AxiosResponse<ISessionTeamsNames>> => 
AxiosHelper.axiosInstance().get<ISessionTeamsNames>(
  APIConstant.CLASSROOM_GAME.GetTeamNames, 
  {
    params: {
      classroomGameId,
      requestedCount,
      excludedNames
    },
    paramsSerializer: params => qs.stringify(params),
  }
);

export const CreateGameSession = (
  params: IGameSessionRequest
): Promise<AxiosResponse<IGameSessionResponse>> => 
AxiosHelper.axiosInstance().post<IGameSessionResponse>(
  APIConstant.CLASSROOM_GAME.CreateSession,
  params,
);

export const UpdateGameSession = (
  params: IGameSessionUpdateRequest
): Promise<AxiosResponse<IGameSessionResponse>> =>
AxiosHelper.axiosInstance().put<IGameSessionResponse>(
  APIConstant.CLASSROOM_GAME.UpdateSession,
  params,
);

export const SaveGameSessionTeamNames = (
  params: IGameSessionTeamNamesRequest
): Promise<AxiosResponse<IResult<string>>> =>
AxiosHelper.axiosInstance().post<IResult<string>>(
  APIConstant.CLASSROOM_GAME.AddSessionTeamNames,
  params,
);

export const GetGameSessions = (
  request: IGameSessionsRequest
): Promise<AxiosResponse<IGameSessionsResponse>> =>
AxiosHelper.axiosInstance().get<IGameSessionsResponse>(
  APIConstant.CLASSROOM_GAME.GetSessions,
  {
    params: {...request},
    paramsSerializer: params => qs.stringify(params),
  }
);

export const GetGameSession = (
  classroomGameSessionId: number,
  classroomGameId: number,
): Promise<AxiosResponse<IGameSessionResponse>> =>
AxiosHelper.axiosInstance().get<IGameSessionResponse>(
  `${APIConstant.CLASSROOM_GAME.GetSessions}/${classroomGameSessionId}/game/${classroomGameId}`
);

export const GetGameSessionStandards = (
  classroomGameId: number,
  classroomGameSessionId: number
): Promise<AxiosResponse<ISelectedStandards>> =>
AxiosHelper.axiosInstance().get<ISelectedStandards>(
  APIConstant.CLASSROOM_GAME.GetSessionStandards,
  {
    params: {
      classroomGameId,
      classroomGameSessionId
    },
    paramsSerializer: params => qs.stringify(params),
  }
);

export const GetGameSessionTeamNames = (
  classroomGameSessionId: number
): Promise<AxiosResponse<ISessionTeams>> =>
AxiosHelper.axiosInstance().get<ISessionTeams>(
  APIConstant.CLASSROOM_GAME.GetSessionTeamNames,
  {
    params: {
      classroomGameSessionId
    },
    paramsSerializer: params => qs.stringify(params),
  }
);

export const GetGameSessionItems = (
  classroomGameSessionId: number
): Promise<AxiosResponse<IGameSessionItems>> => 
AxiosHelper.axiosInstance().get<IGameSessionItems>(
  APIConstant.CLASSROOM_GAME.GetSessionItems,
  {
    params: {
      classroomGameSessionId
    },
    paramsSerializer: params => qs.stringify(params),
  }
);

export const DeleteGameSession = (
  classroomGameSessionId: number,
  updatedByUserId: number
): Promise<AxiosResponse<IResult<string>>> =>
AxiosHelper.axiosInstance().delete<IResult<string>>(
  APIConstant.CLASSROOM_GAME.DeleteSessions,
  {
    data: {
      classroomGameSessionId,
      updatedByUserId,
    },
    paramsSerializer: params => qs.stringify(params),
  }
);

export const SetSessionEnded = (
  classroomGameSessionId: number,
  sessionHasEnded: boolean
): Promise<AxiosResponse<IResult<string>>> =>
AxiosHelper.axiosInstance().put<IResult<string>>(
  APIConstant.CLASSROOM_GAME.EndedSessions,
  {
    classroomGameSessionId,
    sessionHasEnded,
  }
);

export const ResetSession = (
  classroomGameSessionId: number,
): Promise<AxiosResponse<IResult<string>>> =>
  AxiosHelper.axiosInstance().put<IResult<string>>(
    APIConstant.CLASSROOM_GAME.ResetSessions,
    {
      classroomGameSessionId,
    }
  );

export const UpdateTeamPoints = (
  classroomGameSessionId: number,
  team: ISessionTeam
): Promise<AxiosResponse> => 
AxiosHelper.axiosInstance().put(
  APIConstant.CLASSROOM_GAME.UpdateTeamPoint
  .replace("{sessionId}", ""+classroomGameSessionId)
  .replace("{teamName}", team.name),
  {
    "Points": team.points,
  }
)

export const SetItemAnswered = (
  classroomGameSessionId: number,
  ThinkOrSwimSessionItemId: number
): Promise<AxiosResponse<IResult<string>>> =>
AxiosHelper.axiosInstance().put<IResult<string>>(
  APIConstant.CLASSROOM_GAME.SetItemAnswered
  .replace("{sessionId}", ""+classroomGameSessionId),
  {
    itemId: ThinkOrSwimSessionItemId
  }
);

export const getMinibreak = (
  miniBreakId: number
): Promise<AxiosResponse<IMiniBreak>> =>
AxiosHelper.axiosInstance().get<IMiniBreak>(
  APIConstant.CLASSROOM_GAME.GetMiniBreak.replace("{miniBreakId}", ""+miniBreakId)
);

export const getStandardsWithQuestionFilters = (
  domainIds: number[],
  hideHiddenStandards: boolean = true,
  minimumQuestionCount: number | undefined,
): Promise<AxiosResponse<PagingResponse<IDomainStandardDTO>>> => {
  return AxiosHelper.axiosInstance().get<PagingResponse<IDomainStandardDTO>>(APIConstant.CLASSROOM_GAME.GetEligibleStandards, {
    params: {
      domainIds: domainIds,
      hideHiddenStandards: hideHiddenStandards,
      minimumQuestionCount: minimumQuestionCount,
    },
    paramsSerializer: params => qs.stringify(params),
  });
};
import { AxiosResponse } from "axios";
import { AxiosHelper } from "../../../environment/axiosConfig";
import APIConstant from "../../../utils/constant/apiConstant";

export function getLiftOffDiagnosticResult(
  userId: number,
  liftOffHeaderId?: number,
  sessionId?: any
): Promise<AxiosResponse<any>> {
  return AxiosHelper.axiosInstance().get<any>(
    APIConstant.StudentLiftOffAssignment_API.Result,
    {
      params: {
        userId: userId,
        liftOffHeaderId: liftOffHeaderId,
        sessionId: sessionId,
      },
    }
  );
}

export const getLiftoffHeaders = (
  subjectId: number
): Promise<AxiosResponse<Array<any>>> => {
  return AxiosHelper.axiosInstance().get<Array<any>>(
    APIConstant.StudentLiftOffAssignment_API.Domains,
    {
      params: {
        subjectId: subjectId,
      },
    }
  );
};

export const getLiftoffSubjects = (
  schoolId: number
): Promise<AxiosResponse<Array<any>>> => {
  return AxiosHelper.axiosInstance().get<Array<any>>(
    APIConstant.StudentLiftOffAssignment_API.Subjects,
    {
      params: {
        schoolId: schoolId,
      },
    }
  );
};

export function getLiftOffDiagnosticLastSession(
  userId: number,
  liftOffHeaderId: number,
  languageId: number
) {
  return AxiosHelper.axiosInstance().get<any>(
    APIConstant.StudentLiftOffAssignment_API.LastSession,
    {
      params: {
        userId: userId,
        liftOffHeaderId: liftOffHeaderId,
        languageId: languageId,
      },
    }
  );
}

export function getLiftOffDiagnosticTestStandards(activityId: string) {
  return AxiosHelper.axiosInstance().get<any>(
    APIConstant.StudentLiftOffAssignment_API.Standards,
    {
      params: {
        activityId: activityId,
      },
    }
  );
}

import { AxiosHelper } from "../environment/axiosConfig";
import { Configuration } from "../environment/setup";

export function fetchData(userId: number, roleId: number) {
  const url = `${Configuration.ApiBaseUrl}ManageUser`;
  return AxiosHelper.axiosInstance().get(url, {
    params: {
      userId: userId,
      roleId: roleId,
    },
  });
}

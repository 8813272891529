import classNames from "classnames";
import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { getMyGalaxySubject } from "../../../../../api/student/liftOff/myGalaxyApi";
import { IMyGalaxySubject } from "../../../../../model/interface/liftOff/myGalaxySubject";
import Profile from "../../../../../model/users/profile";
import { UserContextState } from "../../../../../redux/actions/userContextAction";
import constant from "../../../../../utils/constant/constant";

interface IMyGalaxySubjectProps {
  userContext?: UserContextState;
  profile?: Profile;
  isOpened: boolean;
  onSubjectSelected: (subjectId: number, subjectName: string) => void;
}
function MyGalaxySubject(props: IMyGalaxySubjectProps) {
  const [subjects, setSubjects] = useState<Array<IMyGalaxySubject>>([]);
  const [selectedSubject, setSelectedSubject] = useState<IMyGalaxySubject>();
  useEffect(() => {
    if (subjects.length === 0 && props.isOpened === true) loadSubjects();
  }, [props.isOpened]);

  function loadSubjects() {
    getMyGalaxySubject(props.userContext?.userId ?? 0).then((d) => {
      setSubjects(d.data);
      if (selectedSubject === undefined && d.data.length > 0) {
        setSelectedSubject(d.data[0]);
        props.onSubjectSelected(d.data[0].subjectId, d.data[0].name);
      }
    });
  }
  return (
    <Fragment>
      <div className="block bg-[#fe3bac] p-1 rounded-2xl relative">
        <div className="grid grid-cols-1 2xl:grid-cols-2 bg-white border-[#e3178d] border-[3px] px-3 py-4 rounded-2xl gap-3">
          {subjects.map((s) => {
            return (
              <div
                key={s.subjectId}
                className={classNames(
                  "flex px-2 py-1.5 border-[3px] cursor-pointer items-center text-left rounded-lg",
                  selectedSubject?.subjectId === s.subjectId
                    ? "bg-[#e3178d] text-white hover:bg-opacity-90 border-[#F9D228] border-dashed"
                    : "border-[#e3178d] opacity-70 hover:opacity-100"
                )}
                onClick={() => {
                  props.onSubjectSelected(s.subjectId, s.name);
                  setSelectedSubject(s);
                }}
              >
                <span className="mr-2 w-6 flex-none">
                  {s.subjectIcon && s.subjectIcon !== "null" && (
                    <img
                      className={`w-full  ${
                        selectedSubject?.subjectId === s.subjectId
                          ? "filter invert"
                          : ""
                      }`}
                      srcSet={s.subjectIcon}
                      alt="Subject Icon"
                    />
                  )}
                </span>
                {props.profile?.plLanguageId === constant.Languages.Spanish
                  ? s.spanishName ?? s.name
                  : s.name}
              </div>
            );
          })}
        </div>
      </div>
    </Fragment>
  );
}
const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(MyGalaxySubject);

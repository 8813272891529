import Permissions from "../../../../utils/permissions";
import AllowedTo from "../../../shared/rbac";

interface AssessmentFilterActionsProps {
  onReset: Function;
  onSearchAssessments: Function;
  onHandleTagManager: Function;
}

  
const AssessmentFilterActions = (props: AssessmentFilterActionsProps) => {
  return (
    <div className="flex flex-col xl:flex-row gap-4 items-end xl:items-center md:justify-between justify-start pb-5 pt-3">
      <AllowedTo perform={Permissions.tags_view}>
        <button
          onClick={() => props.onHandleTagManager()}
          className="bg-secondary-teal border border-transparent shadow-sm py-2 px-4 inline-flex items-center justify-center text-sm font-medium text-white hover:bg-dark-teal hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Assessment Tag Manager
        </button>
      </AllowedTo>
      <div className="grid grid-cols-2 gap-4">
        <button
          onClick={() => props.onReset()}
          className="bg-transparent border my-auto border-secondary-teal shadow-sm py-2 px-4 flex inline-end justify-end text-sm font-medium text-secondary-teal hover:text-white hover:bg-secondary-teal hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition ease-in-out duration-500"
        >
          Reset
        </button>
        <button
          onClick={() => props.onSearchAssessments()}
          className="bg-secondary-teal border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-dark-teal hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 "
        >
          Filter
        </button>
      </div>
    </div>
  )
}

export default AssessmentFilterActions;
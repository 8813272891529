import { Fragment, useEffect, useState } from "react";
import "../../../../../../css/style.css";
import { connect } from "react-redux";
import OverviewResult from "./overviewResult";
import { useTranslation } from "react-i18next";
import { UserContextState } from "../../../../../../redux/actions/userContextAction";
import Profile from "../../../../../../model/users/profile";
import { getStandardAssignmentResult } from "../../../../../../api/student/liftOff/myGalaxyApi";
import LearnosityReport from "../../../../results/learnosityReport";

interface ILiftOffMyGalaxyStandardResult {
  userContext: UserContextState;
  profile: Profile;
  match: any;
}
const ELLIFTOFFMYGALAXYSTANDARDRESULT = (
  props: ILiftOffMyGalaxyStandardResult
) => {
  const { t } = useTranslation();
  const [liftOffGalaxyStadardResult, setLiftOffGalaxyStadardResult] =
    useState<any>(null);
  //const standardId = parseInt(atob(props.match.params.standardId));
  const standardId = parseInt(props.match.params.standardId);
  const studentId = parseInt(props.match.params.studentId);
  const [learnosityActivityUserId, setLearnosityActivityUserId] =
    useState<number>(studentId);

  useEffect(() => {
    getResult(props.userContext.userId, standardId);
  }, []);

  function getResult(userId: number, liftOffHeaderId?: number) {
    getStandardAssignmentResult(
      props.userContext.userId,
      false,
      liftOffHeaderId
    ).then((res) => {
      setLearnosityActivityUserId(
        res.data.headers[0].learnosityActivityUserId ?? studentId
      );
      setLiftOffGalaxyStadardResult(
        res.data && res.data.headers.length > 0 ? res.data.headers[0] : null
      );
    });
  }
  return (
    <Fragment>
      {liftOffGalaxyStadardResult && (
        <div className="relative bg-teal-light">
          <div className="p-5 flex flex-col md:flex-row">
            <OverviewResult data={liftOffGalaxyStadardResult} />
            <div className="flex-grow h-auto md:w-1/2">
              {/* flex-grow h-auto md:w-1/2 */}
              <div className="flex w-full mt-6 md:mt-0 pb-6">
                <div>
                  <h1 className="text-2xl font-bold text-white capitalize">
                    {t("MYGalaxyStandardResult.Title")}
                  </h1>
                  <h2 className="text-base font-medium text-white capitalize">
                    {liftOffGalaxyStadardResult?.domains[0]?.domainName}-
                    {liftOffGalaxyStadardResult?.standards[0]?.standardName}
                  </h2>
                </div>
              </div>
              <div className="flex-none xxl:flex">
                <div className="w-full bg-gray-50 rounded-md xl:mr-12 mb-12 xxl:mb-0 overflow-hidden">
                  {/* <ProgressResult data={liftOffGalaxyStadardResult} /> */}
                  <div className="p-5 bg-gray-50 border rounded-md text-sm font-semibold">
                    <h2>
                      {" "}
                      {t("MYGalaxyStandardResult.AnswerStatusByQuestionNo")}
                    </h2>
                    <ul className="flex flex-wrap gap-2 w-full xl:w-1/2 mt-3">
                      {liftOffGalaxyStadardResult.questions.map(
                        (question, index) => {
                          return (
                            <>
                              {question.order > 0 && question.score === question.pointsPossible && (
                                <li className="w-6 h-6 border border-green-400 text-gray-500 text-xs flex items-center rounded-sm shadow-sm justify-center hover:bg-orange-light hover:text-gray-900 cursor-pointer">
                                  {index + 1}
                                </li>
                              )}
                              {question.order > 0 && question.score < question.pointsPossible && (
                                <li className="w-6 h-6 border border-red-400 text-gray-500 text-xs flex items-center rounded-sm shadow-sm justify-center hover:bg-orange-light hover:text-gray-900 cursor-pointer">
                                  {index + 1}
                                </li>
                              )}
                            </>
                          );
                        }
                      )}
                    </ul>
                  </div>
                  <div className="p-5 border-2 border-blue-400 rounded-md text-sm font-semibold">
                    <LearnosityReport
                      sessionId={liftOffGalaxyStadardResult.lernositySessionId}
                      user_id={learnosityActivityUserId.toString()}
                      show_answers={true}
                      wrapperClass=""
                    ></LearnosityReport>
                  </div>
                </div>
                {/* <div className="flex-grow w-full xl:w-4/5 xxl:w-3/5">
                  <TotalQuestionsByDomains data={liftOffGalaxyStadardResult} />
                  <TotalQuestionsByStandards data={liftOffGalaxyStadardResult} />
                </div> */}
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
};
export default connect<{}, {}, {}>(
  mapStateToProps,
  {}
)(ELLIFTOFFMYGALAXYSTANDARDRESULT);

import { Fragment, useEffect, useState } from "react";
import "../../../../../../../css/style.css";
import { getLiftOffDiagnosticResult } from "../../../../../../../api/student/liftOff/diagnosticApi";
import { connect } from "react-redux";
import { UserContextState } from "../../../../../../../redux/actions/userContextAction";
import Profile from "../../../../../../../model/users/profile";
import OverviewResult from "./overviewResult";
import LearnosityReport from "../../../../../results/learnosityReport";
import { useTranslation } from "react-i18next";
import RouteConstant from "../../../../../../../utils/constant/routeConstant";
import Constant from "../../../../../../../utils/constant/constant";
import Breadcrumb from "../../../../../teacher/shared/breadcrumb";
import MiddleSchoolBreadcrumb from "../../../../middleSchool/breadcrumb";
import { IBreadcrumb } from "../../../../../../../model/common/breadcrumb";
import { useLocation } from "react-router-dom";
interface ILiftOffDaignosticResult {
  userContext: UserContextState;
  profile: Profile;
  match: any;
}
const ELLIFTOFFDIAGNOSTICRESULT = (props: ILiftOffDaignosticResult) => {
  const location = useLocation();
  const { t } = useTranslation();
  const [diagnosticHeaderResult, setDiagnosticHeaderResult] =
    useState<any>(null);
  const liftOffHeaderId = parseInt(atob(props.match.params.liftOffHeaderId));
  const sessionId = props.match.params.sessionId;
  const url = window.location.href;
  const [learnosityActivityUserId, setLearnosityActivityUserId] =
    useState<number>(props.userContext.userId);

  useEffect(() => {
    getResult(props.userContext.userId, liftOffHeaderId, sessionId);
  }, []);

  function getResult(
    userId: number,
    liftOffHeaderId?: number,
    sessionId?: any
  ) {
    getLiftOffDiagnosticResult(
      props.userContext.userId,
      liftOffHeaderId,
      sessionId
    ).then((res) => {
      setLearnosityActivityUserId(
        res.data.learnosityActivityUserId ?? res.data.userId
      );
      setDiagnosticHeaderResult(
        res.data && res.data.headers?.length > 0 ? res.data.headers[0] : null
      );
    });
  }

  const breadcrumbItems = () => {
    let items: Array<IBreadcrumb> = [];
    if (url.indexOf("activitysearch") > -1) {
      items = [
        { name: t("Breadcrumb.Home"), url: RouteConstant.ROUTE_DASHBOARD },
        { name: t("Breadcrumb.Reports"), url: RouteConstant.REPORTS },
        {
          name: t("Breadcrumb.StudentActivitySearchReport"),
          url: RouteConstant.TeacherRoutes.StudentActivitySearchReport,
        },
        { name: "Student Result", url: "" },
      ];
    } else if (url.indexOf("liftoffactivityreport") > -1) {
      items = [
        { name: t("Breadcrumb.Home"), url: RouteConstant.ROUTE_DASHBOARD },
        { name: t("Breadcrumb.Reports"), url: RouteConstant.REPORTS },
        {
          name: t("Breadcrumb.LiftoffActivityReport"),
          url: RouteConstant.TeacherRoutes.LiftoffActivityReport,
        },
        { name: "Student Result", url: "" },
      ];
    } else {
      items = [];
      if (props.userContext.roleId === Constant.UserRoleId.Student) {
        if (location.pathname.toLowerCase().indexOf("gradedwork") > -1) {
          items.push({
            name: t("Breadcrumb.GradedWork"),
            url:
              location.pathname.toLowerCase().indexOf("kindergarten") > -1
                ? RouteConstant.KindergartenStudentRoutes.GradedWork
                : location.pathname.toLowerCase().indexOf("elementary") > -1
                ? RouteConstant.StudentRoute.Home
                : location.pathname.toLowerCase().indexOf("middleschool") > -1
                ? RouteConstant.MiddleSchool.gradedWork
                : "",
          });
        } else {
          items.push({
            name: t("Breadcrumb.LiftOffDiagnostic"),
            url:
              location.pathname.toLowerCase().indexOf("kindergarten") > -1
                ? RouteConstant.LiftOff.Kindergarten.Home
                : location.pathname.toLowerCase().indexOf("elementary") > -1
                ? RouteConstant.LiftOff.Elementary.Home
                : location.pathname.toLowerCase().indexOf("middleschool") > -1
                ? RouteConstant.LiftOff.MiddleSchool.Home
                : "",
          });
        }
      }
      items.push({ name: "Result", url: "" });
    }
    return items;
  };

  return (
    <Fragment>
      {location.pathname.toLowerCase().indexOf("middleschool") > -1 && (
        <div className="px-4 py-5 lg:px-8 border-b">
          <MiddleSchoolBreadcrumb items={breadcrumbItems()} />
        </div>
      )}
      {diagnosticHeaderResult && (
        <div className="bg-white flex-grow rounded-2xl mb-4 m-5">
          <div className="px-4 py-5 lg:px-8 border-b">
            {location.pathname.toLowerCase().indexOf("middleschool") === -1 && (
              <Breadcrumb items={breadcrumbItems()} />
            )}
          </div>
          <div className="p-5 md:py-5 md:px-2 lg:p-5 flex flex-col md:flex-row">
            <OverviewResult data={diagnosticHeaderResult} />
            <div className="flex-grow h-auto md:w-1/2">
              <div className="flex w-full mt-6 md:mt-0 pb-6">
                <div>
                  <h1 className="text-2xl font-bold text-gray-700 capitalize">
                    {t("LiftOffDiagnosticResult.Title")}{" "}
                    {diagnosticHeaderResult.liftOffHeader}
                  </h1>
                </div>
              </div>
              <div>
                <div className="">
                  <div className="p-5 bg-gray-50 border rounded-md text-sm font-semibold">
                    <h2>
                      {" "}
                      {t("LiftOffDiagnosticResult.AnswerStatusByQuestionNo")}
                    </h2>

                    <ul className="flex flex-wrap gap-2 w-full xl:w-1/2 mt-3">
                      {diagnosticHeaderResult.questions.map(
                        (question, index) => {
                          return (
                            <>
                              {question.order > 0 &&
                                question.score === question.pointsPossible && (
                                  <li className="w-6 h-6 bg-[#B2D136] border border-[#B2D136] text-white text-xs font-semibold flex items-center rounded-sm shadow-sm justify-center hover:bg-transparent hover:text-[#B2D136]">
                                    {index + 1}
                                  </li>
                                )}
                              {question.order > 0 &&
                                question.score < question.pointsPossible && (
                                  <li className="w-6 h-6 bg-[#e53f71] border border-[#e53f71] text-white text-xs font-semibold flex items-center rounded-sm shadow-sm justify-center hover:bg-transparent hover:text-[#e53f71]">
                                    {index + 1}
                                  </li>
                                )}
                            </>
                          );
                        }
                      )}
                    </ul>
                  </div>
                </div>
                <div className="mt-5">
                  <div className="p-5 border-2 border-blue-400 rounded-md text-sm font-semibold">
                    <LearnosityReport
                      sessionId={diagnosticHeaderResult.lernositySessionId}
                      user_id={learnosityActivityUserId.toString()}
                      show_answers={true}
                      wrapperClass="EL-Wrapper"
                      standardIds={diagnosticHeaderResult.standards
                        ?.map((standard) => {
                          return standard.standardId;
                        })
                        ?.join(",")}
                      subjectId={diagnosticHeaderResult.subjectId}
                    ></LearnosityReport>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
};
export default connect<{}, {}, {}>(
  mapStateToProps,
  {}
)(ELLIFTOFFDIAGNOSTICRESULT);

import classNames from "classnames";
import { Fragment, useEffect, useRef, useState } from "react";
import ReactToPrint from "react-to-print";
import {
  exportDotMatrixResultsBySchoolExcel,
  getAssessmentResultReportBySchool,
  getAssignmentResultReportBySchool,
  getPremiumAssessmentResultReportBySchool,
} from "../../../../../../api/report/resultsReport";
import { DownArrowIcon, Info, UpArrow } from "../../../../../../assets/icons";
import constant from "../../../../../../utils/constant/constant";
import Loader from "../../../../../shared/loader";
import DotRank from "../../../../../shared/widgets/dotRank";
import { ResultReportBySchoolPrint } from "./resultReportBySchoolPrint";
import NoData from "../../../../../shared/noData";
interface ResultReportBySchoolProps {
  activityId: string;
  activityType: string;
  schoolIds: number[];
  classIds: number[];
  redirectedFrom: string;
  overallLabel: string;
  overallScore: number;
  isDistrictUser: boolean;
  header: {
    name: string;
    activity: string;
    author: string;
    subject: string;
    dateCreated: any;
    dueDate: any;
    schoolName: string;
    className: string;
  };
  resultHeader: any;
  resultSubHeader: any;
  isDomainActive: boolean;
  showSubHeader: boolean;
  isSubHeaderDomainActive: boolean;
  roleId: number;
  isAssessmentWithCr: boolean;
  schoolYearId: number;
}

function ResultReportBySchool(props: ResultReportBySchoolProps) {
  const componentRef = useRef(null);
  const {
    activityId,
    activityType,
    schoolIds,
    classIds,
    redirectedFrom,
    overallLabel,
    overallScore,
    isDistrictUser,
    header,
    resultSubHeader,
    resultHeader,
    isDomainActive,
    showSubHeader,
    isSubHeaderDomainActive,
    roleId,
    schoolYearId,
  } = props;

  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [schoolMatrixResult, setSchoolMatrixResult] = useState<any>();

  const getPercentage = (pointsEarned, pointsPossible, questionsAttempted) => {
    if (
      pointsEarned === null ||
      pointsPossible === null
      // || questionsAttempted < 5
    )
      return null;

    return pointsPossible === 0 ? 0 : pointsEarned / pointsPossible;
  };

  const setVisibilityForSubjects = (data) => {
    let index = 0;
    let visibility: any = [];
    let contentTypes: any = [];
    visibility.push(true);
    contentTypes.push(0);
    index = index + 1;
    let activity = data?.activity;
    activity["isCollapse"] = false;

    data?.activity.subjects?.forEach((subject) => {
      let subjectChild = 0;
      subject["index"] = index;
      subject["isCollapse"] = false;
      visibility.push(false);
      contentTypes.push(0);
      index = index + 1;
      subject.domains?.forEach((domain) => {
        let domaintChild = 0;
        domain["index"] = index;
        domain["isCollapse"] = false;
        subjectChild = subjectChild + 1;
        visibility.push(true);
        contentTypes.push(1);
        index = index + 1;
        domain.standards?.forEach((standard) => {
          standard["displayName"] = "(" + standard.code + ") " + standard.name;
          standard["index"] = index;
          standard["isCollapse"] = false;
          subjectChild = subjectChild + 1;
          domaintChild = domaintChild + 1;
          visibility.push(true);
          contentTypes.push(2);
          index = index + 1;
        });
        domain["childCount"] = domaintChild;
      });
      subject["childCount"] = subjectChild;
    });

    if (data) {
      data.visibility = visibility;
      data.contentTypes = contentTypes;
    }
  };

  const setVisibilityForSchools = (data) => {
    data?.schools?.forEach((school, i) => {
      school["isCollapse"] = true;
      school.visibility = [];
      if (i !== 0) school.pctCorrect = [];
      school?.pointsEarned?.forEach((pct, j) => {
        //Hide Subject
        if (j === 1) school.visibility.push(false);
        else school.visibility.push(true);

        if (i !== 0) {
          school.pctCorrect.push(
            getPercentage(
              school?.pointsEarned[j],
              school?.pointsPossible[j],
              school?.questionsAttempted[j]
            )
          );
        }
      });
    });
  };

  const setAllSchoolsSummary = (data) => {
    if (
      data.schools &&
      data.schools.filter((x) => x.name === "District Average").length === 0
    ) {
      let allSchoolsScore: any = {
        questionCount: 0,
        schoolCount: 0,
        questionsAttempted: 0,
        questionsCorrect: 0,
        classes: [],
        pctCorrect: [],
        pointsEarned: [],
        index: 0,
        id: 0,
        name: "District Average",
      };

      //Actvity Total
      allSchoolsScore.questionsAttempted = data?.activity.questionsAttempted;
      allSchoolsScore.questionsCorrect = data?.activity.questionsCorrect;
      allSchoolsScore.pointsEarned.push(data?.activity.pointsEarned);
      allSchoolsScore.pctCorrect.push(
        getPercentage(
          data?.activity.pointsEarned,
          data?.activity.pointsPossible,
          data?.activity.questionsAttempted
        )
      );

      data?.activity.subjects?.forEach((subject) => {
        allSchoolsScore.questionsAttempted = subject.questionsAttempted;
        allSchoolsScore.questionsCorrect = subject.questionsCorrect;
        allSchoolsScore.pointsEarned.push(subject.pointsEarned);
        allSchoolsScore.pctCorrect.push(
          getPercentage(
            subject.pointsEarned,
            subject.pointsPossible,
            subject.questionsAttempted
          )
        );

        subject.domains?.forEach((domain) => {
          allSchoolsScore.pointsEarned.push(domain.pointsEarned);
          allSchoolsScore.pctCorrect.push(
            getPercentage(
              domain.pointsEarned,
              domain.pointsPossible,
              domain.questionsAttempted
            )
          );

          domain.standards?.forEach((standard) => {
            allSchoolsScore.pointsEarned.push(standard.pointsEarned);
            allSchoolsScore.pctCorrect.push(
              getPercentage(
                standard.pointsEarned,
                standard.pointsPossible,
                standard.questionsAttempted
              )
            );
          });
        });
      });
      data?.schools?.forEach((school) => {
        allSchoolsScore.questionCount += school.questionCount;
        allSchoolsScore.schoolCount += school.schoolCount;
      });

      data.schools = [allSchoolsScore, ...data.schools];
    }
  };

  const getResult = () => {
    setShowLoader(true);
    var result =
      activityType === constant.AssessmentTypes.ASSESSMENT
        ? getAssessmentResultReportBySchool(
            parseInt(activityId),

            schoolIds.includes(0) ? null : schoolIds,
            classIds.includes(0) ? null : classIds,
            schoolYearId
          )
        : activityType === constant.AssessmentTypes.PREMIUMASSESSMENT
        ? getPremiumAssessmentResultReportBySchool(
            parseInt(activityId),
            schoolIds.includes(0) ? null : schoolIds,
            classIds.includes(0) ? null : classIds,
            props.schoolYearId
          )
        : getAssignmentResultReportBySchool(
            parseInt(activityId),
            schoolIds.includes(0) ? null : schoolIds,
            classIds.includes(0) ? null : classIds,
            schoolYearId
          );

    result?.then((response) => {
      setShowLoader(false);
      if (!response.data.data) {
        setErrorMessage("No record found");
      } else {
        let resultData = response.data.data;
        setAllSchoolsSummary(resultData);
        setVisibilityForSchools(resultData);
        setVisibilityForSubjects(resultData);
        setSchoolMatrixResult(resultData);
      }
    });
  };

  const handleHorizontalCollapse = (entity, value, entityType) => {
    entity.isCollapse = value;
    if (entityType === "ACTIVITY") {
      schoolMatrixResult.visibility[entity.index] = true;
      entity.subjects.forEach((subject) => {
        if (entity.isCollapse && !value)
          schoolMatrixResult.visibility[subject.index] = false;
        else schoolMatrixResult.visibility[subject.index] = !value;

        subject.domains.forEach((domain) => {
          if (subject.isCollapse)
            schoolMatrixResult.visibility[domain.index] = false;
          else schoolMatrixResult.visibility[domain.index] = !value;

          domain.standards.forEach((standard) => {
            if ((subject.isCollapse || domain.isCollapse) && !value)
              schoolMatrixResult.visibility[standard.index] = false;
            else schoolMatrixResult.visibility[standard.index] = !value;
          });
        });
      });
    } else if (entityType === "SUBJECT") {
      schoolMatrixResult.visibility[entity.index] = true;
      entity.domains.forEach((domain) => {
        schoolMatrixResult.visibility[domain.index] = !value;
        domain.standards.forEach((standard) => {
          if (domain.isCollapse && !value)
            schoolMatrixResult.visibility[standard.index] = false;
          else schoolMatrixResult.visibility[standard.index] = !value;
        });
      });
    } else if (entityType === "DOMAIN") {
      entity.standards.forEach((standard) => {
        schoolMatrixResult.visibility[standard.index] = !value;
      });
    }

    schoolMatrixResult?.schools?.forEach((school) => {
      school?.pctCorrect?.forEach((pct, index) => {
        //Hide Subject
        if (index !== 1)
          school.visibility[index] = schoolMatrixResult.visibility[index];
      });
    });

    let _schoolMatrixResult = {
      ...schoolMatrixResult,
    };
    setSchoolMatrixResult(_schoolMatrixResult);
  };

  const exportToExcel = () => {
    setShowLoader(true);
    exportDotMatrixResultsBySchoolExcel(
      activityType === constant.AssessmentTypes.ASSESSMENT
        ? "ASSESSMENT"
        : activityType === constant.AssessmentTypes.PREMIUMASSESSMENT
        ? "PREMIUMASSESSMENT"
        : "ASSIGNMENT",
      parseInt(activityId),
      parseInt(activityId),
      schoolIds.includes(0) ? null : schoolIds,
      classIds.includes(0) ? null : classIds,
      activityType === constant.AssessmentTypes.PREMIUMASSESSMENT
        ? parseInt(activityId)
        : null,
      schoolYearId
    )
      .then((response) => {
        setShowLoader(false);
        const file = response.data;
        const fileURL = URL.createObjectURL(file);
        const link = document.createElement("a");
        link.href = fileURL;
        link.download = "resultschooldotrank.xlsx";
        link.setAttribute("target", "_blank");
        document.body.appendChild(link);
        link.click();
      })
      .catch(() => {
        setShowLoader(false);
      });
  };

  useEffect(() => {
    getResult();
  }, []);

  return (
    <Fragment>
      {showLoader && <Loader></Loader>}
      {errorMessage !== '' && <div className='p-3'>{errorMessage}</div>}
      <div className='flex'>
        <ReactToPrint
          trigger={() => (
            <button
              className='hidden cursor-pointer mr-auto mt-2 ml-3 leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 rounded-lg text-sm text-white border border-transparent bg-primary-green focus:shadow-outline-purple w-auto'
              type='button'
            >
              Print
            </button>
          )}
          content={() => componentRef.current}
        />
        <button
          className='cursor-pointer mt-2 ml-3 leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 rounded-lg text-sm text-white border border-transparent bg-primary-green focus:shadow-outline-purple w-auto'
          type='button'
          onClick={() => exportToExcel()}
        >
          Export
        </button>
        {(redirectedFrom === constant.RedirectedFrom.MYASSIGNMENTS ||
          redirectedFrom === constant.RedirectedFrom.MYASSESSMENTS ||
          props.redirectedFrom ===
            constant.RedirectedFrom.PREMIUMASSESSMENTS) && (
          <div className='text-sm text-white font-light bg-secondary-teal px-4 py-2 mr-auto mt-2 ml-3 leading-5 flex overflow-hidden'>
            <div className='flex-shrink-0 current-fill mt-0.5'>
              <Info />
            </div>
            <div className='pl-2'>
              {redirectedFrom === constant.RedirectedFrom.MYASSIGNMENTS &&
                "Your assignment results will show the student's best result. You can access details on each attempt by searching in the Class Results tab of your Gradebook."}
              {(redirectedFrom === constant.RedirectedFrom.MYASSESSMENTS ||
                props.redirectedFrom ===
                  constant.RedirectedFrom.PREMIUMASSESSMENTS) &&
                'Your assessment results include the average across all attempts each student has made. You can view the individual attempts by exporting the assessment results under the Actions column in your assessment bank.'}
            </div>
          </div>
        )}
      </div>

      {schoolMatrixResult && schoolMatrixResult?.schools?.length > 1 && (
        <div className='pt-48 overflow-x-auto pl-1'>
          {schoolMatrixResult && (
            <Fragment>
              <div className='hidden'>
                <ResultReportBySchoolPrint
                  ref={componentRef}
                  schoolMatrixResult={schoolMatrixResult}
                  redirectedFrom={redirectedFrom}
                  overallLabel={overallLabel}
                  overallScore={overallScore}
                  isDistrictUser={isDistrictUser}
                  header={header}
                  resultHeader={resultHeader}
                  resultSubHeader={resultSubHeader}
                  isDomainActive={isDomainActive}
                  showSubHeader={showSubHeader}
                  isSubHeaderDomainActive={isSubHeaderDomainActive}
                ></ResultReportBySchoolPrint>
              </div>

              <table className='border-b border-primary-violet'>
                <tr className='border-b border-primary-violet'>
                  <th className='py-1 text-left pl-2 bg-white sticky left-[-5px] z-[9999]'>
                    <span className='text-primary-violet text-base font-semibold'>
                      School
                    </span>
                  </th>

                  <th
                    className='relative'
                    title={schoolMatrixResult.activity?.name}
                  >
                    <p className='w-[271px] absolute top-[-5.25rem] left-[-0.75rem] transform -rotate-45 whitespace-nowrap flex items-center text-md font-bold'>
                      {schoolMatrixResult.activity?.name?.length > 40
                        ? schoolMatrixResult.activity?.name?.substring(0, 40) +
                          '...'
                        : schoolMatrixResult.activity?.name}
                      <div
                        onClick={() =>
                          handleHorizontalCollapse(
                            schoolMatrixResult.activity,
                            !schoolMatrixResult.activity.isCollapse,
                            'ACTIVITY'
                          )
                        }
                      >
                        {!schoolMatrixResult.activity.isCollapse && (
                          <UpArrow className='w-5 mr-2 cursor-pointer text-green-600' />
                        )}
                        {schoolMatrixResult.activity.isCollapse && (
                          <DownArrowIcon className='w-5 mr-2 cursor-pointer text-green-600' />
                        )}
                      </div>
                    </p>
                  </th>

                  {!schoolMatrixResult.activity.isCollapse &&
                    schoolMatrixResult.activity?.subjects?.map(
                      (subject, subjectIndex) => (
                        <>
                          {!schoolMatrixResult.activity.isCollapse &&
                            !subject.isCollapse &&
                            subject.domains.map((domain, domainIndex) => (
                              <>
                                <th className='relative' title={domain.name}>
                                  <p className='w-[271px] absolute top-[-5.25rem] left-[-0.75rem] transform -rotate-45 whitespace-nowrap flex items-center text-sm font-medium'>
                                    {domain.name.length > 40
                                      ? domain.name.substring(0, 40) + '...'
                                      : domain.name}
                                    <div
                                      onClick={() =>
                                        handleHorizontalCollapse(
                                          domain,
                                          !domain.isCollapse,
                                          'DOMAIN'
                                        )
                                      }
                                    >
                                      {!domain.isCollapse && (
                                        <UpArrow className='w-5 mr-2 cursor-pointer text-red-600' />
                                      )}
                                      {domain.isCollapse && (
                                        <DownArrowIcon className='w-5 mr-2 cursor-pointer text-red-600' />
                                      )}
                                    </div>
                                  </p>
                                </th>

                                {!schoolMatrixResult.activity.isCollapse &&
                                  !subject.isCollapse &&
                                  !domain.isCollapse &&
                                  domain.standards.map(
                                    (standard, standardIndex) => (
                                      <th
                                        className='relative'
                                        title={standard.displayName}
                                      >
                                        <p className='w-[271px] absolute top-[-5.25rem] left-[-0.75rem] transform -rotate-45 whitespace-nowrap flex items-center text-xs font-normal'>
                                          {standard.displayName?.length > 40
                                            ? standard.displayName.substring(
                                                0,
                                                40
                                              ) + '...'
                                            : standard.displayName}
                                        </p>
                                      </th>
                                    )
                                  )}
                              </>
                            ))}
                        </>
                      )
                    )}
                </tr>

                {schoolMatrixResult.schools.map((school, schoolIndex) => (
                  <>
                    <tr
                      className={`border-l border-r border-primary-violet${
                        schoolIndex % 2 !== 0 ? ' bg-primary-violet/10' : ''
                      } ${
                        roleId === constant.UserRoleId.SchoolAdmin &&
                        schoolIndex === 0
                          ? ' hidden'
                          : ''
                      }`}
                    >
                      <td className='whitespace-nowrap bg-white sticky z-[9999]'>
                        <div
                          className={classNames(
                            schoolIndex === 0 ? 'font-semibold ' : '',
                            redirectedFrom !==
                              constant.RedirectedFrom.MYASSESSMENTS &&
                              schoolIndex !== 0
                              ? ''
                              : '',
                            'flex items-center justify-start w-full  px-2 '
                          )}
                        >
                          {school.name}
                        </div>
                      </td>
                      {school.pctCorrect?.length
                        ? school.pctCorrect.map(
                            (pct, index) =>
                              school.visibility[index] && (
                                <td
                                  className={
                                    'text-center p-3 text-sm font-normal ' +
                                    (schoolMatrixResult.contentTypes[index] ===
                                    1
                                      ? 'border-l border-gray-500'
                                      : '')
                                  }
                                >
                                  <DotRank
                                    displayScore={true}
                                    value={
                                      !pct && pct !== 0
                                        ? undefined
                                        : Math.round(pct * 100)
                                    }
                                    isAssessmentWithCr={
                                      props.isAssessmentWithCr
                                    }
                                  ></DotRank>
                                </td>
                              )
                          )
                        : [
                            ...Array(
                              schoolMatrixResult.schools[0].pctCorrect.length -
                                1
                            )
                          ].map((e, i) => (
                            <td
                              key={i}
                              className={`text-center ${
                                props.isAssessmentWithCr ? '' : 'opacity-0'
                              } p-3 text-sm font-normal ${
                                i === 0 ? 'border-r border-gray-500' : ''
                              }`}
                            >
                              {props.isAssessmentWithCr ? 'NG' : '-'}
                            </td>
                          ))}
                    </tr>
                  </>
                ))}
              </table>
            </Fragment>
          )}
        </div>
      )}

      {schoolMatrixResult && schoolMatrixResult.schools.length <= 1 && <NoData></NoData>}
    </Fragment>
  )
}

export default ResultReportBySchool;

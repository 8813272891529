import { AxiosResponse } from "axios";
import { AxiosHelper } from "../../../environment/axiosConfig";
import { IQuestionExplanationDto } from "../../../model/interface/questions/QuestionExplanationDto";
import APIConstant from "../../../utils/constant/apiConstant";
import qs from "qs";

export const getQuestionExplanation = (
  questionRefId: string,
  selectedLanguage: string,
  isForResultScreen?: boolean | undefined
): Promise<AxiosResponse<IQuestionExplanationDto>> => {
  return AxiosHelper.axiosInstance().get<IQuestionExplanationDto>(APIConstant.APP_QUESTION_EXPLANATION, {
    params: {
      questionReferenceId: questionRefId,
      language: selectedLanguage,
      isForResultScreen: isForResultScreen ?? false,
    },
  });
};


export function getQuestionExplanations(
  questionReferenceIds: Array<string>,
  selectedLanguage: string,
  isForResultScreen?: boolean | undefined
): Promise<AxiosResponse<IQuestionExplanationDto[]>> { 
  return AxiosHelper.axiosInstance().get<IQuestionExplanationDto[]>(
    APIConstant.APP_QUESTION_EXPLANATIONS,
    {
      params: { 
        QuestionReferenceIds: questionReferenceIds,
        language: selectedLanguage,
        isForResultScreen: isForResultScreen ?? false,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      }
    });
}
import { IStudentReward } from "./../../model/interface/studentReward";
import { AxiosResponse } from "axios";
import { AxiosHelper } from "../../environment/axiosConfig";
import APIConstant from "../../utils/constant/apiConstant";

import { IScoreUpdate, ResponseScore } from "./../../model/teacher/assignment/responseScore";
import StudentTime from "./../../model/student/time";
import qs from "qs";
import ClassListModel from "../../model/dropdowns/classList";
import { AssessmentType } from "../../model/common/AssessmentType";
import { IActivitySessionQuestionFeedback } from "../../model/interface/activitySession/activitySessionQuestionFeedback";

export const getStudentRewards = (
  studentId: number
): Promise<AxiosResponse<IStudentReward>> => {
  return AxiosHelper.axiosInstance().get<IStudentReward>(
    APIConstant.Student_API.StudentRewards,
    {
      params: {
        studentId: studentId,
      },
    }
  );
};

export function getStudentClasses(
  userId: number,
  subjectId: number,
  standardId: number
) {
  return AxiosHelper.axiosInstance().get<Array<any>>(
    APIConstant.Student_API.Classes,
    {
      params: { userId: userId, subjectId: subjectId, standardId: standardId },
    }
  );
}

export function getStudentTeacher(
  userId: number,
  subjectId: number,
  standardId: number
) {
  return AxiosHelper.axiosInstance().get<Array<any>>(
    APIConstant.Student_API.Teachers,
    {
      params: { userId: userId, subjectId: subjectId, standardId: standardId },
    }
  );
}

export function getAlienRankingCards(
  userId: number,
  gradeId: number,
  programMode: number
) {
  return AxiosHelper.axiosInstance().get<Array<any>>(
    APIConstant.Student_API.AlienRankngCard,
    {
      params: { userId: userId, gradeId: gradeId, programMode: programMode },
    }
  );
}

export function downloadCertificate(
  firstName: string,
  lastName: string,
  level: number,
  isPrestigeMode: boolean
) {
  return AxiosHelper.axiosInstance().get<Array<any>>(
    APIConstant.Student_API.DownloadCertificate,
    {
      responseType: "blob",
      params: {
        firstName: firstName,
        lastName: lastName,
        level: level,
        isPrestigeMode: isPrestigeMode,
      },
    }
  );
}

export function updateStudentResponseScore(
  feedback: string,
  studentAssignmentActivityId: number,
  responseScore: ResponseScore,
  score: number,
  maxPoints: number,
  isTeacherQuestion: boolean,
  questionId: number,
  assessmentType: AssessmentType
) {
  return AxiosHelper.axiosInstance().put(APIConstant.Student_API.UpdateScore, {
    feedback: feedback,
    studentAssignmentActivityId: studentAssignmentActivityId,
    responseScore: responseScore,
    score: score,
    maxPoints: maxPoints,
    isTeacherQuestion: isTeacherQuestion,
    questionId: questionId,
    assessmentType: assessmentType
  });  
}

export function updateStudentResponseScores(
  feedbacks: IActivitySessionQuestionFeedback[],
  studentAssignmentActivityId: number,  
  scoreUpdates: IScoreUpdate[]
) {
  return AxiosHelper.axiosInstance().put(APIConstant.Student_API.UpdateScores, {
    feedbacks: feedbacks,
    studentAssignmentActivityId: studentAssignmentActivityId,
    scoreUpdates: scoreUpdates
  });  
}

export function getStudentTime(
  userIds: Array<number>,
  subjectIds: Array<number>
) {
  return AxiosHelper.axiosInstance().get<Array<StudentTime>>(
    APIConstant.Student_API.Time,
    {
      params: {
        userIds: userIds,
        subjectIds: subjectIds,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    }
  );
}

export function updateStudentBlasters(
  blaster: any,
  studentId: number,
  programMode: string
) {
  return AxiosHelper.axiosInstance().put(APIConstant.Student_API.Blaster, {
    blaster: blaster,
    studentId: studentId,
    programMode: programMode,
  });
}

export function getLinkedSchools(linkedUserId: any) {
  return AxiosHelper.axiosInstance().get(
    APIConstant.Student_API.LinkedSchools,
    {
      params: {
        linkedUserId: linkedUserId,
      },
    }
  );
}

export function getAllAlienRankingCards() {
  return AxiosHelper.axiosInstance().get<Array<any>>(
    APIConstant.Student_API.AllAlienRankngCard
  );
}

export function getFormerStudentClasses() {
  return AxiosHelper.axiosInstance().get<Array<ClassListModel>>(
    APIConstant.Student_API.FormerClasses,
    {
      params: {},
    }
  );
}

export const getLiftoffStudents = (
  classIds: number[] | undefined,
  gradeIds: number[] | undefined,
  teacherUserIds: number[] | undefined
) => {
  const params = {
    classIds: classIds,
    gradeIds: gradeIds,
    teacherUserIds: teacherUserIds,
  };
  return AxiosHelper.axiosInstance().post(
    APIConstant.Student_API.LiftoffStudents,
    params,
    {}
  );
};

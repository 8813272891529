import { Fragment, useEffect, useRef, useState } from "react";
import Select from "react-select";
import {
  downloadaAnswerKeySupersheet,
  getSuperSheetGrades,
  getSuperSheets,
  getSuperSheetSubjects,
  previewSuperSheet,
} from "../../../../api/teacher/superSheet";
import { PdfFileIcon } from "../../../../assets/icons";
import PagingResponse from "../../../../model/common/pagingResponse";
import { ISelectElement } from "../../../../model/interface/selectElement";
import { ISuperSheetSearch } from "../../../../model/interface/superSheetSearch";
import SuperSheetModel from "../../../../model/teacher/superSheet/superSheetModel";
import {
  DEFAULT_PAGE_NUMBER,
  DEFAULT_PAGE_SIZE,
} from "../../../../utils/pagingConstant";
import PageSize from "../../../shared/pagination/pageSize";
import Pagination from "../../../shared/pagination/pagination";
import SubjectList from "../shared/subjectList/subjectList";

interface passedProps {
  userId: number;
  roleId: number;
  defaultSubjectId?: number;
}

function SuperSheetList(props: passedProps) {
  const defaultValue = { label: "Select", value: "0" };
  const { userId, roleId } = props;
  const componentRef = useRef();
  const [pageNumber, setPageNumber] = useState(DEFAULT_PAGE_NUMBER);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [resetPageNumber, setResetPageNumber] = useState<boolean>(false);
  const [superSheets, setSuperSheets] =
    useState<PagingResponse<SuperSheetModel>>();
  const [selectedGrade, setSelectedGrade] = useState<ISelectElement>();
  const [selectedSubject, setSelectedSubject] = useState<ISelectElement>({
    label: "",
    value: props.defaultSubjectId ? props.defaultSubjectId.toString() : "0",
  });

  const [grades, setGrades] = useState<Array<ISelectElement>>([]);
  function resetFilters() {
    let cref: any = componentRef.current;
    cref.resetSubject();
    setSelectedSubject(defaultValue);
    setSelectedGrade(defaultValue);

    const superSheetSearch: ISuperSheetSearch = {
      teacherId: userId,
      roleId: roleId,
      subjectId: 0,
      gradeId: 0,
    };

    getSuperSheets(superSheetSearch).then((r) => {
      setSuperSheets(r.data.data);
    });
  }

  function searchSuperSheets() {
    const superSheetSearch: ISuperSheetSearch = {
      teacherId: userId,
      roleId: roleId,
      subjectId:
        selectedSubject && selectedSubject.value !== "0"
          ? parseInt(selectedSubject.value)
          : undefined,
      gradeId:
        selectedGrade && selectedGrade.value !== "0"
          ? parseInt(selectedGrade.value)
          : undefined,

      pageNumber: pageNumber,
      pageSize: pageSize,
    };
    getSuperSheets(superSheetSearch).then((r) => {
      setSuperSheets(r.data);
    });
  }

  function bindSubjects() {
    getSuperSheetSubjects(userId, roleId).then((response) => {
      const subjectsData = response.data.data;
      var subjects: Array<ISelectElement> = new Array<ISelectElement>();
      subjectsData.forEach(function (keyValuePair: any) {
        subjects.push({ label: keyValuePair.name, value: keyValuePair.id });
      });
      //  setSubjects(subjects);
    });
  }

  function bindGrades() {
    getSuperSheetGrades().then((response) => {
      const gradeData = response.data;
      var grades: Array<ISelectElement> = new Array<ISelectElement>();
      gradeData.forEach(function (keyValuePair: any) {
        grades.push({ label: keyValuePair.name, value: keyValuePair.gradeId });
      });
      setGrades(grades);
    });
  }

  function handleSubjectChange(selectdItem: any) {
    let cref: any = componentRef.current;
    cref.bindSelectedSubject(selectdItem);
    setSelectedSubject(selectdItem);
  }
  function handleGradeChange(selectdItem: any) {
    setSelectedGrade(selectdItem);
  }

  function handleFileDownload(path: any) {
    previewSuperSheet(path).then((response) => {
      const fileURL = response.data;
      const link = document.createElement("a");
      link.href = fileURL;
      link.setAttribute("target", "_blank");
      document.body.appendChild(link);
      link.click();
    });
  }

  function downloadAnswerKeyFile(supersheetId: any) {
    downloadaAnswerKeySupersheet(supersheetId, userId).then((response) => {
      const file = response.data;
      const fileURL = URL.createObjectURL(file);
      const link = document.createElement("a");
      link.href = fileURL;
      link.setAttribute("target", "_blank");
      document.body.appendChild(link);
      link.click();
    });
  }

  const onPageChange = (pageNo) => {
    setPageNumber(pageNo);
  };
  const onPageSizeChange = (pageSize) => {
    setPageNumber(DEFAULT_PAGE_NUMBER);
    setPageSize(pageSize);
    setResetPageNumber(true);
  };

  function refreshOnDefaultSubjectSelection(selectedSubject: any) {
    const superSheetSearch: ISuperSheetSearch = {
      teacherId: userId,
      roleId: roleId,
      subjectId:
        selectedSubject && selectedSubject.value !== "0"
          ? parseInt(selectedSubject.value)
          : undefined,
      gradeId:
        selectedGrade && selectedGrade.value !== "0"
          ? parseInt(selectedGrade.value)
          : undefined,
      pageNumber: pageNumber,
      pageSize: pageSize,
    };

    if (selectedSubject) {
      setSelectedSubject({
        label: selectedSubject.label,
        value: selectedSubject.value,
      });
    }

    getSuperSheets(superSheetSearch).then((r) => {
      setSuperSheets(r.data);
    });
  }

  useEffect(() => {
    bindSubjects();
    bindGrades();
  }, []);

  useEffect(() => {
    if (selectedSubject) refreshOnDefaultSubjectSelection(selectedSubject);
  }, [pageSize, pageNumber]);

  return (
    <Fragment>
      <div className="min-w-0 block lg:col-span-9 xl:col-span-10 w-full">
        <div className="w-full mb-5 rounded-lg h-full ">
          <div className="hidden grid grid-cols-2 sm:grid-cols-8 lg:grid-cols-8 xl:grid-cols-8 gap-6 pb-4">
            <div className="col-span-8 sm:col-span-4 xl:col-span-2">
              <label className="block text-sm text-gray-500">Grade</label>
              <Select
                value={selectedGrade}
                options={grades}
                onChange={handleGradeChange}
              />
            </div>
            <div className="col-span-8 sm:col-span-4 xl:col-span-2">
              <SubjectList
                userId={props.userId}
                roleId={props.roleId}
                onChange={handleSubjectChange}
                className={"block text-sm text-gray-500"}
                ref={componentRef}
                defaultSubjectId={props.defaultSubjectId}
                refreshOnDefaultSubjectSelection={
                  refreshOnDefaultSubjectSelection
                }
              ></SubjectList>
            </div>
          </div>
          <div className="hidden flex flex-col md:flex-row gap-4 items-start md:items-center md:justify-between justify-start pb-10">
            <div className="grid grid-cols-2 gap-4">
              <button
                onClick={() => resetFilters()}
                className="bg-transparent border my-auto border-primary-violet shadow-sm py-2 px-4 flex justify-center text-sm font-medium text-primary-violet hover:text-white hover:bg-light-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3 transition ease-in-out duration-500"
              >
                {" "}
                Reset
              </button>
              <button
                onClick={() => searchSuperSheets()}
                className="bg-primary-violet border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-dark-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3 "
              >
                Filter
              </button>
            </div>
          </div>

          <div className="mb-2">{selectedSubject.label}</div>
          <div className="flex items-start justify-between space-x-6 w-full mb-6">
            <div className="text-sm text-gray-700 font-light bg-[#00b1fb] bg-opacity-10 p-4 flex w-full rounded-md rounded-t-lg sm:rounded-lg overflow-hidden">
              <div className="flex-shrink-0 current-fill text-[#00b1fb]">
                <svg
                  className="h-5 w-5"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </div>
              <div className="pl-2">
                <span className="font-semibold text-gray-700 pr-2 mb-1 inline-block">
                  Instructions
                </span>
                <br />
                <ul className=" list-disc	list-outside pl-3 space-y-1">
                  <li>
                    SuperSheets are 8-12 question formative assessments that
                    include distractor rationales in the answer key.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="bg-gray-100  w-full mb-5 rounded-lg p-5 h-full ">
            <div className="bg-white p-5">
              <div className="mb-4 shadow overflow-x-auto xl:overflow-x-visible">
                <table className="table w-full ">
                  <thead className="mb-5">
                    <tr className="bg-light-violet  text-white py-3 px-3 overflow-hidden">
                      <th className="p-3 text-left text-sm font-normal">
                        Title
                      </th>
                      <th className="text-left p-3 text-sm font-normal">
                        Standard
                      </th>
                      <th className="p-3 text-center text-sm font-normal">
                        Number Of Questions
                      </th>
                      <th className="text-center p-3 text-sm font-normal">
                        English
                      </th>
                      <th className="text-center p-3 text-sm font-normal">
                        Spanish
                      </th>
                      <th className="text-center p-3 text-sm font-normal">
                        Answer Key
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {superSheets &&
                      superSheets.data?.length > 0 &&
                      superSheets.data?.map((superSheet, index) => {
                        return (
                          <Fragment key={index}>
                            <tr
                              className={`hover:bg-light-violet/20 transition ease-in-out duration-300 ${
                                index % 2 === 0
                                  ? "bg-white"
                                  : "bg-light-violet/10"
                              }`}
                            >
                              <td className="text-left text-sm mt-5  p-3">
                                <a href="#!" className="text-dark-blue">
                                  {superSheet.title}
                                </a>
                              </td>
                              <td className="text-left text-sm mt-5  p-3">
                                <a href="#!" className="text-dark-blue">
                                  {superSheet.standard}
                                </a>
                              </td>

                              <td className="text-center text-sm mt-5  p-3">
                                <a href="#!" className="text-dark-blue">
                                  {superSheet.answerKeyCount > 0
                                    ? superSheet.answerKeyCount
                                    : ""}
                                </a>
                              </td>
                              <td className="text-center text-sm mt-5  p-3">
                                {superSheet.englishPdfName && (
                                  <PdfFileIcon
                                    title={superSheet.englishPdfName}
                                    className="w-4 opacity-50 hover:opacity-80 cursor-pointer m-auto"
                                    onClick={() => {
                                      handleFileDownload(
                                        superSheet.englishPdfPath
                                      );
                                    }}
                                  />
                                )}
                              </td>
                              <td className="text-center text-sm mt-5  p-3">
                                {superSheet.spanishPdfPath && (
                                  <PdfFileIcon
                                    title={superSheet.spanishPdfPath}
                                    className="w-4 opacity-50 hover:opacity-80 cursor-pointer m-auto"
                                    onClick={() => {
                                      handleFileDownload(
                                        superSheet.spanishPdfPath
                                      );
                                    }}
                                  />
                                )}
                              </td>
                              <td className="text-center text-sm mt-5  p-3">
                                {superSheet.answerKeyCount > 0 && (
                                  <PdfFileIcon
                                    title={superSheet.answerKeyPdfName}
                                    className="w-4 opacity-50 hover:opacity-80 cursor-pointer m-auto"
                                    onClick={() => {
                                      downloadAnswerKeyFile(
                                        superSheet.superSheetId
                                      );
                                    }}
                                  />
                                )}
                              </td>
                            </tr>
                          </Fragment>
                        );
                      })}
                  </tbody>
                </table>
              </div>
              <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between border-t-2 pt-4 mt-5">
                {superSheets?.totalRecords !== undefined &&
                  superSheets?.totalRecords !== 0 && (
                    <Pagination
                      totalRecords={superSheets?.totalRecords}
                      pageSize={pageSize}
                      onPageChange={onPageChange}
                      reset={resetPageNumber}
                      pageNumber={pageNumber}
                    />
                  )}
                {superSheets?.totalRecords !== 0 && (
                  <PageSize
                    pageSize={pageSize}
                    onPageSizeChange={onPageSizeChange}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default SuperSheetList;

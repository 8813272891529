import { Fragment, useEffect } from "react";
import { XIcon } from "@heroicons/react/outline";
import React, { useState } from "react";
import InfoMessage from "../../../shared/infoMessage";
import { getSkillReport } from "../../../../api/student/skillPracticeApi";
import { UserContextState } from "../../../../redux/actions/userContextAction";
import { connect } from "react-redux";
import { ISkillReport } from "../../../../model/skillPractice/skillReport";

interface SkillReportProps {
  skillStandardId: number;
  showPopUp: (show: boolean) => void;
  userContext?: UserContextState;
}

function SkillReport(props: SkillReportProps) {
  const [skillReport, setSkillReport] = useState<ISkillReport>();

  useEffect(() => {
    if (props.skillStandardId !== 0) {
      getSkillReport(
        props.skillStandardId,
        props.userContext?.userId ?? 0,
        props.userContext?.schoolAccountId ?? 0
      ).then((d) => {
        setSkillReport(d.data);
      });
    }
  }, [props.skillStandardId]);
  return (
    <Fragment>
      <div className="fixed inset-0 z-40 flex items-center bg-black bg-opacity-50 sm:items-center sm:justify-center">
        <div
          className="w-full flex flex-col justify-between overflow-hidden h-auto max-h-80percent bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:max-w-3xl"
          role="dialog"
        >
          {/* Header */}
          <header className="px-4 py-4 bg-gray-50 sm:px-4">
            <div className="flex items-start justify-between space-x-3">
              <div className="space-y-1">
                <div className="text-lg font-medium text-gray-900">
                  Skill Standard Report
                </div>
              </div>
              <div className="h-7 flex items-center">
                <button
                  type="button"
                  className="text-gray-400 hover:text-gray-500"
                  onClick={() => props.showPopUp(false)}
                >
                  <span className="sr-only">Close panel</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
            </div>
          </header>

          <div className="w-full relative overflow-auto px-4 py-3 ">
            <div>
              <InfoMessage message="Skill standard report." />
            </div>

            {/* <!-- Divider container -->  */}
            <div className="text-sm text-gray-700 grid grid-cols-12  sm:space-x-6">
              <div className="col-span-12 sm:col-span-3 border-r pr-6 ">
                <label className="block text-sm text-gray-500 mb-2 text-center">
                  Win
                </label>
                <label className="border block p-1 rounded-md bg-gray-200  text-center">
                  {" "}
                  {skillReport?.winCount}{" "}
                </label>
              </div>
              <div className="col-span-12 sm:col-span-3 border-r  pr-6">
                <label className="block text-sm text-gray-500 mb-2 text-center">
                  Losses
                </label>
                <label className="border block p-1 rounded-md bg-gray-200  text-center">
                  {" "}
                  {skillReport?.looseCount}{" "}
                </label>
              </div>
              <div className="col-span-12 sm:col-span-3 border-r  pr-6">
                <label className="block text-sm text-gray-500 mb-2 text-center">
                  Total Answers
                </label>
                <label className="border block p-1 rounded-md bg-gray-200  text-center">
                  {" "}
                  {skillReport?.totalAnswers}{" "}
                </label>
              </div>
              <div className="col-span-12 sm:col-span-3">
                <label className="block text-sm text-gray-500 mb-2 text-center">
                  Total Correct Answers
                </label>
                <label className="border p-1 block rounded-md bg-gray-200  text-center">
                  {" "}
                  {skillReport?.totalCorrectAnswers}{" "}
                </label>
              </div>
            </div>
          </div>

          {/* <!-- Action Buttons -->  */}
          <footer className="flex flex-col items-center justify-end px-6 py-4 sm:flex-row bg-gray-50 dark:bg-gray-800">
            <div className="flex-shrink-0">
              <div className="space-x-3 flex justify-end">
                <button
                  className="align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 rounded-lg text-sm text-gray-600 border-gray-300 border dark:text-gray-400 focus:outline-none active:bg-transparent hover:border-gray-500 focus:border-gray-500 active:text-gray-500 focus:shadow-outline-gray w-full sm:w-auto"
                  type="button"
                  onClick={() => {
                    props.showPopUp(false);
                  }}
                >
                  Close
                </button>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </Fragment>
  );
}

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(SkillReport);

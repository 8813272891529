import { IQuestionStates } from "../../../../model/interface/questionState";
import constant from "../../../../utils/constant/constant";

interface QuestionNavigationPlayerProps {
  questions: Array<IQuestionStates>;
  changeQuestion: (questionIndex: number) => void;
  currentItem?: string;
  initialMapIndex: number;
  allowPartialSubmit: boolean;
  Exitlabel: string;
  OnExitClick: () => void;
  showQuestionMap: boolean;
}

const colors = {
  CURRENT: "#FFBE41",
  ANSWERED: "#00A2D4",
  NOTANSWERED: "#D1D5DB",
};

const QuestionNavigationPlayer = (props: QuestionNavigationPlayerProps) => {
  const { questions, currentItem, initialMapIndex, changeQuestion } = props;

  const getColor = (reference: string, answered: boolean) => {
    if (currentItem && reference === currentItem)
      return colors[constant.QuestionMapStatus.CURRENT];
    else if (answered) return colors[constant.QuestionMapStatus.ANSWERED];
    else return colors[constant.QuestionMapStatus.NOTANSWERED];
  };

  return (
    <div className="m-4 mb-6 bg-white items-center flex gap-y-2 justify-end">
      {props.showQuestionMap && (
        <div className="flex flex-wrap">
          {questions.map((question, index) => {
            return (
              <div
                onClick={() => changeQuestion(index)}
                className="p-1 mx-1 flex h-7 w-7 items-center justify-center rounded-full cursor-pointer"
                style={{
                  background: getColor(question.reference, question.answered),
                  fontSize: "13px",
                }}
              >
                <p>{index + initialMapIndex + 1}</p>
              </div>
            );
          })}
        </div>
      )}
      {props.allowPartialSubmit && (
        <button
          onClick={(e) => props.OnExitClick()}
          className="ml-2 bg-gray-200 border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-gray-700 hover:bg-gray-500 hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 hover:text-white"
        >
          {props.Exitlabel}
        </button>
      )}
    </div>
  );
};

export default QuestionNavigationPlayer;

import { Dialog, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import AnglerfishButton from './anglerfishButton';

interface TosGamePlayQuestionClosingDialogProps {
  showClosingDialog: boolean,
  handleContinue: () => void,
  handleCancel: () => void,
}

function TosGamePlayQuestionClosingDialog(props: TosGamePlayQuestionClosingDialogProps) {
  const {
    showClosingDialog,
    handleContinue,
    handleCancel,
  } = props;

  return (
    <Transition.Root show={showClosingDialog} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-50 inset-10 overflow-y-auto"
        open={showClosingDialog}
        onClose={handleCancel}
      >
        <div className="flex items-center justify-center h-full p-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="easeout duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div 
              className="rounded-lg shadow-xl transform transition-all max-w-4xl text-left p-5"
              style={{backgroundImage: "linear-gradient(rgba(74,145,233,1), rgba(62,114,223,1))"}}
            >
              <div className="text-sm text-white mb-10">
                You have not recorded points earned for this question. If you choose to continue, the question will remain unanswered and no points will be awarded. If you select to cancel, then you will be able to mark the question complete and award points by selecting 'Record Points'.
              </div>
              <div className="flex justify-end">
                <div className="w-[22vw]">
                  <AnglerfishButton 
                    text="Continue and Close"
                    textSize={1.1}
                    onClick={handleContinue}
                    textSecondOption="Cancel"
                    onClickSecondOption={handleCancel}  
                                  
                  />
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default TosGamePlayQuestionClosingDialog

import React, { useState, useEffect } from "react";
import { CloseIcon } from "../../../../../assets/icons/index";
import { getQRCode } from "../../../../../api/student/familyApi";
interface ParentConnectPopupProps {
  showPopUp: (show: boolean) => void;
}

const ParentConnectPopup = (props: ParentConnectPopupProps) => {
  const [showConnectedParents, setShowConnectedParents] = useState(false);
  const [Base64QRCode, setQRData] = useState<any>([]);
  const [studentFamily, setStudentFamily] = useState<any>([]);

  const handleShowConnectedParents = (show: boolean) => {
    setShowConnectedParents(show);
  };

  const getQRDisplayCode = () => {
    var response = getQRCode(6);
    response?.then((d) => {
      setQRData(d.data.base64QRCode);
      setStudentFamily(d.data.studentFamilyMembers);
    });
  };
  useEffect(() => {
    getQRDisplayCode();
  }, []);

  return (
    <div className="fixed inset-0 z-40 flex items-center bg-black bg-opacity-50 sm:justify-center enter-done">
      <div
        className="w-full px-6 py-4 overflow-hidden bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:max-w-xl"
        role="dialog"
      >
        <div>
          <header className="flex justify-end">
            <button
              className="inline-flex items-center justify-center w-6 h-6 text-gray-400 transition-colors duration-150 rounded dark:hover:text-gray-200 hover: hover:text-gray-700"
              onClick={() => {
                props.showPopUp(false);
              }}
            >
              <CloseIcon />
            </button>
          </header>
          <p className="mb-2 text-lg font-semibold text-gray-700 dark:text-gray-300">
            Link Parent Account
          </p>
          <div className="flex">
            <div className="w-1/2 mt-6">
              <img
                className=" rounded-lg focus:outline-none m-auto focus:ring-offset-2 focus:ring-offset-gray-800 ring-white ring-2 ring-gray-200 ring-offset-4"
                src={Base64QRCode}
                alt="QRCode"
                width="150"
                height="150"
              />
              <div className="mb-6 mt-6 text-sm text-gray-700 dark:text-gray-400 ">
                <div className="flex gap-3 mb-3 justify-center">
                  <button
                    className="align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 rounded-lg text-sm text-white bg-primary-violet border border-transparent active:bg-dark-violet hover:bg-dark-violet focus:shadow-outline-purple hidden"
                    type="button"
                    onClick={() => {
                      handleShowConnectedParents(!showConnectedParents);
                    }}
                  >
                    <span className="ml-1">Show Linked Parents</span>
                  </button>
                </div>
                <div className="flex gap-3 mb-3 justify-center">
                  <small className="d-block mt-3 z1 z--3 line-height1 text-black-50 fs--2">
                    The above code can only be used once and is valid for the
                    next 12 hours
                  </small>
                </div>
              </div>
            </div>
            {studentFamily.length > 0 && (
              <div className=" mx-auto space-y-3 px-4 pt-4 pb-2 gap-5  w-1/2 ">
                <div className="col-span-6  pt-5 border-b pb-2">
                  <h3 className="h1 text-base">Family</h3>
                </div>
                <table className="table-fixed w-full text-sm bg-gray-50 rounded-lg">
                  <thead className=" rounded-2xl overflow-hidden">
                    <tr className="bg-gray-200 px-2 rounded-2xl overflow-hidden space-x-4 rounded-2xl overflow-hidden">
                      <th className="font-normal text-left px-2  rounded-tl-lg ">
                        Name
                      </th>
                      <th className="font-normal text-left px-2 ">Relation</th>
                    </tr>
                  </thead>
                  <tbody>
                    {studentFamily.map((family, index) => {
                      return (
                        <tr className="" key={index}>
                          <td className="font-light px-2 py-2">
                            {family.parentName}
                          </td>
                          <td className="font-light px-2 py-2">
                            {family.relation}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            )}
          </div>
          <footer className="flex flex-col items-center justify-center px-6 py-4 -mx-6 -mb-4 space-y-4 sm:space-y-0 sm:space-x-6 sm:flex-row bg-gray-50 dark:bg-gray-800">
            <button
              className="align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 rounded-lg text-sm text-white bg-purple-600 border border-transparent active:bg-purple-600 hover:bg-purple-700 focus:shadow-outline-purple w-full sm:w-auto"
              type="button"
            >
              Parent Student Tutorial Link
            </button>
          </footer>
        </div>
      </div>
    </div>
  );
};

export default ParentConnectPopup;

import { AxiosHelper } from "../../environment/axiosConfig";
import APIConstant from "../../utils/constant/apiConstant";
import { GetCurrentSchoolYearId } from "../../utils/helper";

export async function getClassesBySchoolAccountId(
  schoolAccountId: any,
  schoolYearId: number
) {
  var _schoolYearId =
    schoolYearId === 0 ? await GetCurrentSchoolYearId(0) : schoolYearId;
  return AxiosHelper.axiosInstance().get(
    APIConstant.MANAGEUSER_API.GetClassesBySchoolAccountId,
    {
      params: { schoolAccountId: schoolAccountId, schoolYearId: _schoolYearId },
    }
  );
}

export function getClassesBySubjectId(subjectId: any) {
  return AxiosHelper.axiosInstance().get(
    APIConstant.MANAGEUSER_API.ClassesBySubjectId,
    { params: { subjectId: subjectId } }
  );
}

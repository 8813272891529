import { Fragment, useState } from "react";

function Impersonate(props: any) {
    window.scrollTo(0, 0);
    const [contentHeight, setcontentHeight] = useState(0)
    const [toggleIframe, settoggleIframe] = useState(false)
    const [showhideIframe, setshowhideIframe] = useState("invisible")
    function setHeight(e: any) {
        setcontentHeight(e.target.scrollHeight)
        settoggleIframe(true);
        setshowhideIframe("visible");
    }

    return (
        <Fragment>
            {props.location.state &&
                <Fragment>
                    {!toggleIframe ?
                        <div>
                            <div className="text-center flex items-center justify-center flex-col h-screen w-screen">
                                <svg className="animate-spin h-14 w-14 text-primary-green" fill="none" viewBox="0 0 24 24"><circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle><path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>
                            </div>
                        </div>
                        :
                        null
                    }
                    <div className={`${showhideIframe} flex`}>
                        <iframe title="impersonate"
                            onLoad={(e) => setHeight(e)}
                            style={{ minHeight: "100vh", height: contentHeight }} width="100%" src={props.location.state.url} allow="camera; microphone"></iframe>
                    </div>
                </Fragment>
            }
        </Fragment>
    );
}

export default Impersonate;

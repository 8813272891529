import * as action from "./actionTypes";
import makeAction, { makeActionWithPayload } from "./makeAction";

export interface ISessionValues {
  teacherClassesCount: number,
};

export const getSessionValues = makeAction(action.GET_SESSIONVALUES);
export const setSessionValues = makeActionWithPayload<ISessionValues>(
  action.SET_SESSIONVALUES
);

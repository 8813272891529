import Permissions from "../../../../utils/permissions";
import AllowedTo from "../../../shared/rbac";
import AccessDenied from "../../../shared/accessDenied";
import { LogoPLIcon, PrintIcon } from "../../../../assets/icons";
import { XIcon } from "@heroicons/react/outline";
import { useRef, useState } from "react";
import ReactToPrint from "react-to-print";
import React from "react";

interface AssessmentCardProps {
  code: string;
  togglePopup: (popupAction: boolean) => void;
}
interface componentToPrintProps {
  code: string;
  cardsCount: number;
}

export const ComponentToPrint = React.forwardRef(
  (props: componentToPrintProps, ref: any) => {
    return (
      <div
        ref={ref}
        className="w-full flex flex-wrap  printpdf-div overflow-auto pl-2"
      >
        {Array.from({ length: props.cardsCount }).map(function (_) {
          return (
            <div className="mr-2 border-2 border-dashed border-gray-700 rounded-lg overflow-hidden inline-block mb-4 w-card27  printpdf">
              <div className="px-4 py-3 flex items-center justify-center gap-2">
                <LogoPLIcon title="Progress Learning logo" className="w-52 p-1" />
              </div>
              <div className="bg-white grid grid-cols-1 gap-x-4 gap-y-1 px-4 py-4">
                <div className="justify-center sm:col-span-1 text-base text-primary-violet font-semibold flex items-start gap-2">
                  Assessment Card
                </div>
                <div className="justify-center sm:col-span-1 flex items-start gap-2">
                  Title: District Assessment
                </div>
                <div className="justify-center sm:col-span-1 font-semibold text-gray-700 flex items-start gap-2">
                  Code: {props.code}
                </div>
                <div className="justify-center sm:col-span-1 flex items-start gap-2">
                  <a
                    className="text-blue-400 hover:underline"
                    href="https://app.progresslearning.com"
                  >
                    https://app.progresslearning.com
                  </a>
                </div>
              </div>
              <div className="flex justify-center pr-2 text-sm text-gray-400 hover:underline cursor-pointer border-t text-sm"></div>
            </div>
          );
        })}
      </div>
    );
  }
);

function AssessmentCard(props: AssessmentCardProps) {
  const componentRef = useRef(null);
  const { code, togglePopup } = props;
  const [printCopies, setPrintCopies] = useState<number>(6);

  return (
    <AllowedTo
      perform={Permissions.assessments_view}
      no={() => <AccessDenied />}
    >
      <div className="fixed inset-0 z-40 flex items-center bg-black bg-opacity-50 sm:items-center sm:justify-center ">
        <div
          className="w-full flex flex-col justify-between overflow-hidden h-auto max-h-80percent bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:w-full"
          role="dialog"
        >
          <header>
            {/* Header */}
            <div className="px-4 py-4 bg-gray-50 sm:px-4">
              <div className="flex items-start justify-between space-x-3">
                <div className="space-y-1 flex">
                  <div className="text-lg text-gray-900">
                    Assessment Code Cards
                    <span className="pl-4">
                      Number of Cards
                      <input
                        autoComplete="off"
                        type="number"
                        value={printCopies}
                        onChange={(e) =>
                          setPrintCopies(parseInt(e.target.value))
                        }
                        className="ml-2 max-w-lg w-14 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                      />
                    </span>
                    <div></div>
                  </div>
                </div>
                <div className="h-7 flex items-center">
                  <button
                    type="button"
                    className="text-gray-400 hover:text-gray-500"
                    onClick={() => togglePopup(false)}
                  >
                    <span className="sr-only">Close panel</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
          </header>
          <ComponentToPrint
            code={code}
            cardsCount={printCopies}
            ref={componentRef}
          ></ComponentToPrint>
          <footer className="flex items-center justify-end px-6 py-4 sm:flex-row bg-gray-50 dark:bg-gray-800">
            {/* <!-- Action Buttons -->  */}
            <div className="flex-shrink-0">
              <div className="space-x-3 flex justify-end">
                <ReactToPrint
                  trigger={() => (
                    <button
                      className="align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 rounded-lg text-sm text-white border border-transparent bg-primary-green focus:shadow-outline-purple w-full sm:w-auto"
                      type="button"
                    >
                      <PrintIcon></PrintIcon>
                      Print
                    </button>
                  )}
                  content={() => componentRef.current}
                />
                <button
                  className="align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 rounded-lg text-sm text-gray-600 border-gray-300 border dark:text-gray-400 focus:outline-none active:bg-transparent hover:border-gray-500 focus:border-gray-500 active:text-gray-500 focus:shadow-outline-gray w-full sm:w-auto"
                  type="button"
                  onClick={() => {
                    togglePopup(false);
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </AllowedTo>
  );
}

export default AssessmentCard;

import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import App from "./app";
import reportWebVitals from "./reportWebVitals";
import { configureStore } from "./redux/store";
import { BrowserRouter } from "react-router-dom"
import "./i18Next"
import { persistStore } from "redux-persist"
import { PersistGate } from "redux-persist/integration/react";
import ReduxToastr from "react-redux-toastr";
import "react-redux-toastr/lib/css/react-redux-toastr.min.css"
import "./tailwind.css";

const store = configureStore();
const persistor = persistStore(store);

const container = document.getElementById("root") as HTMLElement;
const rootContainer = ReactDOM.createRoot(container);

rootContainer.render(
  <BrowserRouter>
    <React.StrictMode>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <Suspense fallback="<div>loading</div>">
            <App />
          </Suspense>
        </PersistGate>
        <ReduxToastr
          timeOut={0}
          newestOnTop={false}
          preventDuplicates
          position="top-right"
          closeOnToastrClick={false} />
      </Provider>
    </React.StrictMode>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

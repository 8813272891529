import { v4 as uuidv4 } from "uuid";
const uuid = uuidv4();

export function initRequest(items: string[], showAnswers: boolean) {
  return {
    user_id: "DemoTest",
    rendering_type: "inline",
    name: "Items API Test",
    state: "review",
    session_id: uuid,
    type: "local_practice",
    items: items,
    config: {
      showCorrectAnswers: showAnswers,
      showInstructorStimulus: showAnswers,
      fontsize: "normal",
      renderSubmitButton: false,
      questions_api_init_options: {
        beta_flags: {
          reactive_views: true,
        },
      },
    },
  };
}

import moment from "moment";
import constant from "./constant/constant";
import Constant from "./constant/constant";
import {ISchoolYear} from "../model/teacher/schoolYear";

export const getStartDate = (date: Date, local: boolean = false): Date => {
  if (local)
  {
    return new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      0,
      0,
      0,
      0
    );
  }

  return new Date(
    Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      0,
      0,
      0,
      0
    )
  );
};

export const getEndDate = (date: Date, local: boolean = false): Date => {
  if (local)
  {
    return new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      23,
      59,
      59,
      999
    );
  }

  return new Date(
    Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      23,
      59,
      59,
      999
    )
  );
};

export const formatSecondsToTime = (totalSeconds: number): string => {
  const remainingSecs = totalSeconds % constant.SECONDS_PER_DAY;
  const formatOptions = { minimumIntegerDigits: 2 };
  return `${
    Math.floor(totalSeconds / constant.SECONDS_PER_DAY)
      .toLocaleString("en-US", formatOptions)
      .replace(/,/g, "") +
    ":" +
    Math.floor(
      moment.duration(remainingSecs, "seconds").asHours()
    ).toLocaleString("en-US", formatOptions) +
    ":" +
    moment
      .duration(remainingSecs, "seconds")
      .minutes()
      .toLocaleString("en-US", formatOptions)
  }`;
};

export const getMinSchoolYearDate = (
  currentSchoolYear: ISchoolYear | undefined
) => {
  return currentSchoolYear
    ? moment(currentSchoolYear.startDate, "YYYY-MM-DD").toDate()
    : Constant.PLMinDate;
};

export const getMaxSchoolYearDate = (
  currentSchoolYear: ISchoolYear | undefined
) => {
  return currentSchoolYear
    ? moment(currentSchoolYear.startDate, "YYYY-MM-DD")
        .add(1, "year")
        .subtract(1, "day")
        .toDate()
    : new Date();
};

export const convertTo12HourFormat = (time: string) => {
  let [hoursString, minutesString] = time.split(':');
  let hours = parseInt(hoursString);
  const suffix = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12 || 12;
  hoursString = String(hours).padStart(2, '0');
  minutesString = minutesString.padStart(2, '0');
  return `${hoursString}:${minutesString} ${suffix}`;
}

/**
 * Returns a boolean value depending on whether the start of a specific month has already passed this year. 
 * The function can be use like: 
 * const booleanResult = isMonthOrLater(monthNumber);
 * @param monthNumber Number of the month to be checked, months numbers are zero based (Jan=0, Feb=1)
 * @returns A boolean value based on current date
 */
export const isMonthOrLater = (monthNumber: number): boolean => {
  const today = new Date();
  const month = today.getMonth();
  const isMonthOrLater = (month >= monthNumber);
  return isMonthOrLater;
}

/**
 * Returns a boolean value depending on whether a specific date has already passed. 
 * The function can be use like: 
 * const booleanResult = hasDatePassed(date);
 * @param date Date to be Checked
 * @returns A boolean value based on current date
 */
export const hasDatePassed = (date: Date): boolean => {
  const today = new Date();
  const todayUTC = new Date(`${today.toLocaleDateString()} 00:00:00 GMT`)
  const reminderDateUTC = new Date(date);
  const hasDatePassed = reminderDateUTC <= todayUTC;
  return hasDatePassed;
}

import { useCallback, useEffect, useRef } from "react";
import { initPreviewRequest } from "../../../../utils/initPreviewAssessment";
import { getRequest } from "../../../../api/userActivity";
import { v4 as uuidv4 } from "uuid";
import { useTranslation } from "react-i18next";
import {
  createCloseTranscriptionButton,
  createShowTranscriptionButton,
  createTranscriptionContainer
} from "../../../../utils/learnosityVideoHelpter";


interface LearnosityPlayerPreviewProps {
  setItemId:  (itemId: string) => void;
  itemsIds: Array<string>;
  sessionId?: string;
  activityType: string;
  studentType: string;
  studentId: string;
  onExitRedirectUrl?: string;
}

const themeStyle = {
  K1: {
    color: "bg-[#018fbb]",
    text: "text-white text-sm"
  },
  EL: {
    color: "bg-[#a3ed2b]",
    text: "text-gray-800 text-sm"
  },
  MS: {
    color: "bg-[#6cc8e7]",
    text: "text-gray-800 text-sm"
  }
}

export const LearnosityVideoPreview = (props: LearnosityPlayerPreviewProps) => {
  const { t } = useTranslation();
  const isInitialized = useRef(false);
  let itemsApp;

  function resetApp () {
    if (itemsApp && itemsApp.reset) {
      itemsApp.reset();
    }
  }

  function showTranscriptDiv(id) {
    var el = document.getElementById(id);
    if (el) {
      el.classList.remove("hidden");
    }
  }
  function hideTranscriptDiv(id) {
    var el = document.getElementById(id);
    if (el) {
      el.classList.add("hidden");
    }
  }

  function getVideoSuccessPopUpInnerHTML() {
    const style = `${themeStyle[props.studentType]?.color} ${themeStyle[props.studentType]?.text}`;
    return `
      <div class="video-question-message-full-score">
        <div
          class="w-96 p-6 rounded-md ${style} opacity-100"
        >
            ${t("StudentResponse.VideoFullScoreMessage")}
        </div>
      </div>`
  }

  function showVideoMessage(){
    const container = document
      .querySelector("div[data-lrn-widget-type='question']")
      ?.parentElement;
    
    if (!container) return;
    container.style.position = "relative";
    const div = document.createElement("div");
    div.innerHTML = getVideoSuccessPopUpInnerHTML();
    container.appendChild(div)
  }

  function addVideoTranscriptionElements(videoObject: any) {
    var featureElelement = document.getElementById(
      videoObject.feature_id
    ) as HTMLElement;
    const showButton = createShowTranscriptionButton(videoObject.feature_id);
    showButton.onclick = () =>
      showTranscriptDiv("transcriptDiv_" + videoObject.feature_id);
      featureElelement?.append(showButton);

    const transcriptContainer = createTranscriptionContainer(videoObject.feature_id);

    const textElement = document.createElement("div");
    textElement.innerHTML = videoObject.metadata?.transcript;

    const closeContainer = document.createElement("div");
    closeContainer.className = "mt-4 text-right";

    const closeButton = createCloseTranscriptionButton(
      videoObject.feature_id,
      t("StudentResponse.Close")
    )
    closeButton.onclick = () =>
      hideTranscriptDiv("transcriptDiv_" + videoObject.feature_id);
    closeContainer.append(closeButton);

    transcriptContainer.appendChild(textElement);
    transcriptContainer.appendChild(closeContainer);
    featureElelement?.append(transcriptContainer);
  }

  const initialize = useCallback(() => {
    if (isInitialized.current) return;
      
    isInitialized.current = true;

    var callbacks = {
      readyListener: function () {
        let itemReference = itemsApp?.getCurrentItem()?.reference;
        props.setItemId(itemReference);

        itemsApp.on("item:load", function () {
          let currentItem = itemsApp.getCurrentItem();
          props.setItemId(currentItem?.reference);
          let isVideoItem =
              currentItem.features?.length > 0 &&
              currentItem.features[0].type === "videoplayer";
          showVideoMessage();
          if (isVideoItem) {
            var videoObject = currentItem.features[0];
              if (videoObject.metadata?.transcript) {
                addVideoTranscriptionElements(videoObject);
            }
          }

        });
      }
    };

    const request = initPreviewRequest(
        props.studentId,
        props.itemsIds,
        props.studentType,
        uuidv4(),
        props.onExitRedirectUrl ?? ""
    )
    getRequest("items", JSON.stringify(request))
      .then((response) => {
        if (response.data) {
          resetApp()
          itemsApp = window["LearnosityItems"]?.init(
            response.data,
            callbacks
          );
        }
    });
  }, []);


  useEffect(() => {
    initialize();
    return () => resetApp();
  }, [initialize, props.activityType])

  return(
    <>
      <div className={props.studentType + "-Wrapper preview-activity-player main-content"}>
        <div
          id="learnosity_assess"
          style={{ position: "relative", zIndex: 0 }}
        >
        </div>
      </div>
    </>
  );
}
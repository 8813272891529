import { ISessionValues } from "../actions/sessionValuesAction";
import * as actionType from "../actions/actionTypes";
import * as actions from "../actions/makeAction";

export const sessionValuesInitialState: ISessionValues = {
  teacherClassesCount: 0,
}

export const sessionValuesReducer = (
  state: ISessionValues = sessionValuesInitialState,
  action: actions.IActionPayload<ISessionValues>
) => {
  switch (action.type) {
    case actionType.GET_SESSIONVALUES: {
      return state;
    }
    case actionType.SET_SESSIONVALUES: {
      state = action.payload ?? sessionValuesInitialState;
      return state;
    }
    default:
      return state;
  }
};

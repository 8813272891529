import Moment from "moment";
import {Fragment, useEffect, useState} from "react";
import DatePicker from "react-datepicker";
import {useHistory} from "react-router-dom";
import Select from "react-select";
import {toast} from "react-toastify";
import ReactTooltip from "react-tooltip";
import {
  addTag,
  duplicateAssessment,
  getAssessmentAuthors,
  getAssessmentById,
  getAssessments,
  getMyAssessmentCount,
  removeTag,
  updateAssessments,
} from "../../../../api/teacher/assessment";
import {addAssignment} from "../../../../api/teacher/assignment";
import {getAllTags} from "../../../../api/teacher/tags";
import {
  ArchieveIcon,
  CancelIcon,
  CaretDownSolidIcon,
  CaretUpSolidIcon,
  DeleteIcon,
  EditIcon,
  EditOutlineIcon,
  GradingIcon,
  OutlineSettingIcon,
  TagIcon,
  TagOutlineIcon,
  UserAdd,
} from "../../../../assets/icons";
import PagingResponse from "../../../../model/common/pagingResponse";
import {IAssessment} from "../../../../model/interface/assessment";
import {IAssessmentSearch} from "../../../../model/interface/assessmentSearch";
import {ICustomKeyValue, ISelectElement, ISelectOption,} from "../../../../model/interface/selectElement";
import Assessment from "../../../../model/teacher/assessment/assessment";
import tagListResponse from "../../../../model/teacher/tags/tagListResponse";
import Constant from "../../../../utils/constant/constant";
import constant from "../../../../utils/constant/constant";
import RouteConstant from "../../../../utils/constant/routeConstant";
import {DEFAULT_PAGE_NUMBER, DEFAULT_PAGE_SIZE,} from "../../../../utils/pagingConstant";
import Permissions from "../../../../utils/permissions";
import AccessDenied from "../../../shared/accessDenied";
import ConfirmationDialog from "../../../shared/confirmationDialog";
import Loader from "../../../shared/loader";
import PageSize from "../../../shared/pagination/pageSize";
import Pagination from "../../../shared/pagination/pagination";
import AllowedTo from "../../../shared/rbac";
import {TagColor} from "../../../shared/tagColor";
import ContextMenu from "../../teacher/shared/contextMenu";
import PrintDialog from "../shared/print/learnosityPrintPopUp";
import QuestionPreview from "../shared/questionPreview/questionPreview";
import AddTag from "./addTag";
import AssessmentCard from "./assessmentCard";
import AssessmentStat from "./assessmentStat";
import DeleteAssessment from "./deleteAssessment";
import CompareAssessmentPopup from "./compareAssessmentPopup";
import EditOption from "./editOption";
import SettingPopup from "./settingPopup";
import MessagePopup from "../../../shared/messagePopup";
import {IAssignmentActivity} from "../../../../model/interface/assignmentActivity";
import {getActivityTypeId} from "../../../../utils/helper";
import {
  exportAssessmentResultsByStudentToExcel,
  getAssessmentResultsByStudent,
} from "../../../../api/report/assessmentResultsReport";
import classNames from "classnames";
import {
  isAllowAssignOnlyOption,
  isAllowEditAssessment,
  isAllowViewActionsAssessment,
  isAllowViewAssessment,
  isAllowViewSettingAssessment,
} from "../../../../utils/assessmentHelper";
import Subject from "../../../shared/dropdowns/subject";
import UpGradeMessagePopup from "../../../shared/upGradeMessagePopup";
import {getAsessementBuilderUpgradePermission, validateAssessmentCount,} from "../../../shared/permissionHelper";
import {getEndDate, getStartDate} from "../../../../utils/dateHelper";
import {ArrowDownIcon, ArrowUpIcon} from "@heroicons/react/solid";
import {verifyUserHasFullAccessSubscription} from "../../../../api/account/accountApi";
import {GetSchoolYears} from "../../../../api/teacher/school";
import {ISchoolYear} from "../../../../model/teacher/schoolYear";
import AssessmentFilterActions from "./assessmentFilterActions";

const options = [
  {
    value: "1",
    label: "Answer Key",
    roles: [
      Constant.UserRoleId.District,
      Constant.UserRoleId.SchoolAdmin,
      Constant.UserRoleId.SchoolTeacher,
      Constant.UserRoleId.FreeTeacher,
    ],
    isActive: true,
    isUpgrade: false,
  },
  {
    value: "2",
    label: "Compare",
    roles: [
      Constant.UserRoleId.District,
      Constant.UserRoleId.SchoolAdmin,
      Constant.UserRoleId.SchoolTeacher,
      Constant.UserRoleId.FreeTeacher,
    ],
    isActive: true,
    isUpgrade: false,
  },
  {
    value: "3",
    label: "Duplicate",
    roles: [
      Constant.UserRoleId.District,
      Constant.UserRoleId.SchoolAdmin,
      Constant.UserRoleId.SchoolTeacher,
      Constant.UserRoleId.FreeTeacher,
    ],
    isActive: true,
    isUpgrade: false,
  },
  {
    value: "4",
    label: "Export Results",
    roles: [
      Constant.UserRoleId.District,
      Constant.UserRoleId.SchoolAdmin,
      Constant.UserRoleId.SchoolTeacher,
      Constant.UserRoleId.FreeTeacher,
    ],
    isActive: true,
    isUpgrade: false,
  },
  {
    value: "5",
    label: "Preview",
    roles: [
      Constant.UserRoleId.District,
      Constant.UserRoleId.SchoolAdmin,
      Constant.UserRoleId.SchoolTeacher,
      Constant.UserRoleId.FreeTeacher,
    ],
    isActive: true,
    isUpgrade: false,
  },
  {
    value: "6",
    label: "Print",
    roles: [
      Constant.UserRoleId.District,
      Constant.UserRoleId.SchoolAdmin,
      Constant.UserRoleId.SchoolTeacher,
      Constant.UserRoleId.FreeTeacher,
    ],
    isActive: true,
    isUpgrade: false,
  },
  {
    value: "7",
    label: "Projector Mode",
    roles: [
      Constant.UserRoleId.SchoolAdmin,
      Constant.UserRoleId.SchoolTeacher,
      Constant.UserRoleId.FreeTeacher,
    ],
    isActive: true,
    isUpgrade: false,
  },
  {
    value: "8",
    label: "Print Codes",
    roles: [Constant.UserRoleId.District],
    isActive: true,
    isUpgrade: false,
  },
];
interface passedProps {
  userId: number;
  roleId: number;
  schoolId: number;
  districtId: number;
  selectedTabId: number;
  defaultSchoolYearId: number;
  from: string;
}
interface ICompareAssessment {
  name?: string;
  Id?: number;
}
interface IActivityPreviewDetail {
  previewId: string;
  activityIdPreview: number;
  activityName: string;
  isProjectorMode: boolean;
  subjectId?: number;
}
function MyAssessment(props: passedProps) {
  type ActionButton =
    | "Archive"
    | "Delete"
    | "Duplicate"
    | "Unarchive"
    | "AddTag"
    | "RemoveTag";
  const viewByOptions = [
    { value: "Current", label: "Current" },
    { value: "Archived", label: "Archived" },
    { value: "Orphaned", label: "Orphaned" },
  ];
  const defaultValue = { label: "Select", value: "0" };
  const defaultAllValue = { label: "All", value: "0" };
  const [selectedViewByFilter, setSelectedViewByFilter] = useState<any>({
    label: "Current",
    value: "Current",
  });
  const { userId, roleId, schoolId, districtId } = props;
  const [pageNumber, setPageNumber] = useState(DEFAULT_PAGE_NUMBER);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [resetPageNumber, setResetPageNumber] = useState<boolean>(false);
  const [selectedSubject, setSelectedSubject] = useState<ISelectElement>();
  const [selectedFromDate, setFromDate] = useState<Date>();
  const [selectedToDate, setToDate] = useState<Date>();
  const [assessments, setAssessments] = useState<PagingResponse<Assessment>>();
  const [isArchived, setIsArchived] = useState<boolean>(false);
  const [isOrphaned, setIsOrphaned] = useState<boolean>(false);
  const [name, setName] = useState<string>("");
  const [code, setCode] = useState<string>("");
  const [selectedAssessments, setSelectedAssessments] = useState<Array<number>>(
    []
  );
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [showConfirmation, setshowConfirmation] = useState<boolean>(false);
  const [confirmationMessage, setConfirmationMessage] = useState<string>("");
  const [buttonAction, setButtonAction] = useState<ActionButton>();
  const [toggleSelectAll, setToggleSelectAll] = useState<boolean>(false);
  const [showTags, setShowTags] = useState<boolean>(false);
  const [tagData, setTagData] = useState<Array<tagListResponse>>([]);
  const [tags, setTags] = useState<Array<ISelectElement>>([]);
  const [selectedTag, setSelectedTag] =
    useState<ISelectElement>(defaultAllValue);
  const [showAddTagPopup, setShowAddTagPopup] = useState<boolean>(false);
  const [showSettingPopup, setShowSettingPopup] = useState<boolean>(false);
  const [assessmentToEdit, setAssessmentToEdit] = useState<Assessment>();
  const [assessmentSetting, setAssessmentSetting] = useState<IAssessment>();
  const [selectedAssessmentId, setSelectedAssessmentId] = useState<number>(0);
  const [selectedLearnosityId, setSelectedLearnosityId] = useState<string>("");
  const [isAnswerKeyToPrint, setIsAnswerKeyToPrint] = useState<boolean>(false);
  const [orderByColumn, setOrderByColumn] = useState<string>("CreateDate");
  const [sortByDesc, setSortByDesc] = useState<boolean>(true);
  const [showPrintPopup, setShowPrintPopup] = useState<boolean>(false);
  const [showComparePopup, setShowComparePopup] = useState<boolean>(false);
  const [assessmentToCompare, setAssessmentToCompare] =
    useState<ICompareAssessment>({});
  const [activityPreviewDetail, setActivityPreviewDetail] =
    useState<IActivityPreviewDetail>();
  const [open, setOpen] = useState<boolean>(false);
  const [togglePrintToCode, setTogglePrintToCode] = useState<boolean>(false);
  const [toggleStatsPopup, setToggleStatsPopup] = useState<boolean>(false);
  const [toggleDeleteAssessmentPopup, setToggleDeleteAssessmentPopup] =
    useState<boolean>(false);
  const [assessmentName, setAssessmentName] = useState<string>("");
  const [codeToPrint, setCodeToPrint] = useState<string>("");
  const [selectedAssessmentToCompare, setSelectedAssessmentToCompare] =
    useState<number>(0);
  const [toggleEditPopup, setToggleEditPopup] = useState<boolean>(false);
  const [editPopupMessage, setEditPopupMessage] = useState<string>("");
  const [allowContinue, setAllowContinue] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [errorMessagePopup, setErrorMessagePopup] = useState<boolean>(false);
  const [activityName, setActivityName] = useState<string>("");
  const [viewMyAssessmentOnly, setViewMyAssessmentOnly] =
    useState<boolean>(true);
  const [upgrade, setUpgrade] = useState(false);
  const [upgradeWithLimit, setUpgradeWithLimit] = useState(false);
  const history = useHistory();
  const [showUpgradePopup, setShowUpgradePopup] = useState<boolean>(false);
  const [showUpgradeLimitPopup, setShowUpgradeLimitPopup] =
    useState<boolean>(false);
  const [assessmentCount, setAssessmentCount] = useState<number>(0);
  const [authors, setAuthors] = useState<Array<ISelectElement>>([]);
  const [selectedAuthor, setSelectedAuthor] = useState<Array<ISelectElement>>();
  const [showFilter, setShowFilter] = useState(false);
  const [showDuplicationResultPopup, setShowDuplicationResultPopup] = useState<boolean>(false);
  const [duplicationResultPopupMessage, setDuplicationResultPopupMessage] = useState<string>('');
  const [duplicationResultAssessmentId, setDuplicationResultAssessmentId] = useState('');

  const [currentSchoolYearId, setCurrentSchoolYearId] = useState<number>(0);
  const [subscriptionIsPremium, setSubscriptionIsPremium] =
    useState<boolean>(false);
  const [upgradeMessage, setUpgradeMessage] = useState<string>("");
  const [startDate, setStartDate] = useState<any>();
  const [endDate, setEndDate] = useState<any>();
  const getSubscriptionPremiumStatus = () => {
    verifyUserHasFullAccessSubscription()
      .then((response) => setSubscriptionIsPremium(response.data))
      .catch((_) => setSubscriptionIsPremium(false));
  };

  useEffect(() => {
    getCurrentSchoolYearId();
    checkUpgradeRequired();
    getSubscriptionPremiumStatus();
    getTags();
  }, []);

  function getCurrentSchoolYearId() {
    GetSchoolYears(schoolId).then((r) => {
      const currentSchoolYearId = r.data.filter(
        (y) => y.currentYearOffset === 0
      )[0]?.schoolYearId;
      setCurrentSchoolYearId(currentSchoolYearId);
      handleDateFilter(currentSchoolYearId, r.data);
    });
  }
  function handleDateFilter(
    schoolYearId?: number,
    schoolYearData?: ISchoolYear[]
  ) {
    let selectedSchoolYear = schoolYearData?.filter(
      (s) => s.schoolYearId == schoolYearId
    )[0];
    let minDate = selectedSchoolYear
      ? Moment(
          selectedSchoolYear.startDate.toString().split("T")[0],
          "YYYY-MM-DD"
        ).toDate()
      : new Date();

    let maxDate = selectedSchoolYear
      ? Moment(
          selectedSchoolYear.startDate.toString().split("T")[0],
          "YYYY-MM-DD"
        )
          .add(1, "year")
          .subtract(1, "day")
          .toDate()
      : new Date();

    setStartDate(getStartDate(minDate));
    setEndDate(getEndDate(maxDate));
  }
  function checkUpgradeRequired() {
    if (getAsessementBuilderUpgradePermission(props.roleId)) {
      checkUpgradeLimit();
      setUpgrade(true);
    } else {
      setUpgrade(false);
    }
  }

  async function checkUpgradeLimit() {
    if (upgradeWithLimit) return true;

    const assessmentCount: number = await getAssessmentCount();
    setAssessmentCount(assessmentCount);
    const isRestricted: boolean = validateAssessmentCount(
      assessmentCount,
      props.roleId
    );
    setUpgradeWithLimit(isRestricted);
    return isRestricted;
  }

  const getAssessmentCount = async () => {
    return (await getMyAssessmentCount()).data;
  };

  function setUpgradeOptions(options: any) {
    if (upgrade) {
      //Export Result
      var option = options.find((o) => o.value == "4");
      if (option) {
        option.isUpgrade = true;
        option.isActive = false;
      }
      if (upgrade && upgradeWithLimit) {
        var option = options.find((o) => o.value == "3");
        if (option) {
          option.isUpgrade = true;
          option.isActive = false;
        }
      }
      return options;
    }
    return options;
  }
  function getOptions(
    canView: boolean,
    canDuplicate: boolean,
    authorId: number,
    userId: number,
    sharedTypeId: number
  ) {
    if (
      authorId === userId ||
      sharedTypeId === constant.AssessmentSharedType.ClassShared ||
      (roleId === constant.UserRoleId.SchoolAdmin &&
        sharedTypeId === constant.AssessmentSharedType.SchoolShared) ||
      (roleId === constant.UserRoleId.District &&
        sharedTypeId === constant.AssessmentSharedType.DistrictShared)
    ) {
      return setUpgradeOptions(options);
    }
    const newOptions = JSON.parse(JSON.stringify(options));
    newOptions.forEach((option) => {
      option.isActive = canView;
      if (option.value === "3") option.isActive = canDuplicate;
    });
    return setUpgradeOptions(newOptions);
  }

  function handleSubjectChange(selectedItem: any) {
    setSelectedSubject(selectedItem);
  }

  function isActivityValid(learnosityId: string) {
    if (learnosityId === "") {
      setErrorMessage(
        "You cannot perform this action because there are no questions associated with this assessment."
      );
      setErrorMessagePopup(true);
      return false;
    }
    return true;
  }

  function handleOptionChange(
    selectedItem: any,
    assessmentId: number,
    learnosityId: string,
    activityName: string,
    value?: any
  ) {
    setActivityName(activityName);
    switch (selectedItem.label) {
      case "Duplicate":
        handleDuplicateConfirmationPopup("Duplicate", assessmentId);
        break;
      case "Print":
        if (isActivityValid(learnosityId)) {
          handlePrintPopup(learnosityId, assessmentId, false, value);
        }
        break;
      case "Preview":
        if (isActivityValid(learnosityId)) {
          openModal({
            previewId: learnosityId,
            activityIdPreview: assessmentId,
            activityName: activityName,
            isProjectorMode: false,
          });
        }
        break;
      case "Print Codes":
        setTogglePrintToCode(true);
        setCodeToPrint(value);
        break;
      case "Projector Mode":
        if (isActivityValid(learnosityId)) {
          openModal({
            previewId: learnosityId,
            activityIdPreview: assessmentId,
            activityName: activityName,
            isProjectorMode: true,
          });
        }
        break;
      case "Answer Key":
        if (isActivityValid(learnosityId)) {
          handlePrintPopup(learnosityId, assessmentId, true, value);
        }
        break;
      case "Compare":
        handleComparePopup(assessmentId, activityName);
        break;
      case "Export Results":
        handleExportResults(assessmentId, activityName);
        break;
    }
  }

  function handleFromDateChange(selectedItem: any) {
    if (selectedToDate && selectedItem > selectedToDate) {
      return false;
    }
    setFromDate(selectedItem);
  }

  function handleToDateChange(selectedItem: any) {
    if (selectedFromDate && selectedItem < selectedFromDate) {
      return false;
    }
    setToDate(selectedItem);
  }

  function handleOptionUpgrade(selectedOption: ISelectOption) {
    if (selectedOption.value == "3") {
      //duplicate
      setUpgradeMessage(constant.UPGRADE_MESSAGE_ASSESSMENT_LIMIT_NEW);
    } else setUpgradeMessage(constant.UPGRADE_MESSAGE_SCHOOL_ACCOUNT);
    setShowUpgradePopup(true);
  }

  function handleUpdateAssessments(
    action: string,
    isArchived: boolean = false
  ) {
    const assessmentIds = selectedAssessments.join(",");
    setShowLoading(true);
    updateAssessments(userId, assessmentIds, action, isArchived)
      .then((response) => {
        setShowLoading(false);
        var message = "";
        switch (action) {
          case Constant.AssignmentUpdateAction.ARCHIVE:
            message = `selected assessments ${
              isArchived ? "archived" : "unarchived"
            } successfully.`;
            break;
        }
        toast.success(message);
        searchAssessments();
      })
      .catch(() => {
        setShowLoading(false);
        setToggleSelectAll(false);
      });
  }

  function createDuplicationResultPopupMessage({subjectName, questionsMovedCount, questionsRemovedCount}) {
    let message = `<i>Recent updates were made to <b>"${subjectName}"</b>. 
      <br><br>These updates may include changes with questions or standards. Because of this, the duplicated version of the assessment is different from the original. 
      <br><br>Please preview the assessment and edit if changes are necessary.<br></i>`;
    if (questionsMovedCount)
      message += questionsMovedCount > 1 
        ? `<br><i><b>${questionsMovedCount} questions have been moved to another standard.</b></i>`
        : `<br><i><b>${questionsMovedCount} question has been moved to another standard.</b></i>`;
    if (questionsRemovedCount)
      message += questionsRemovedCount > 1
        ? `<br><i><b>${questionsRemovedCount} questions have been removed from the subject.</b></i>`
        : `<br><i><b>${questionsRemovedCount} question has been removed from the subject.</b></i>`;
    return message;
  }

  function handleDuplicateAssignment() {
    setShowLoading(true);
    duplicateAssessment(userId, selectedAssessmentId).then((response) => {
      setShowLoading(false);
      setDuplicationResultAssessmentId(response.data.assessmentId);
      if (response.data.questionsMovedCount === 0 && response.data.questionsRemovedCount === 0) {
        redirectToDuplicatedAssessment(response.data.assessmentId);
      } else {
        setDuplicationResultPopupMessage(createDuplicationResultPopupMessage(response.data));
        setShowDuplicationResultPopup(true);
      }
    });
  }

  function handleDuplicationResultPopupConfirm() {
    redirectToDuplicatedAssessment(duplicationResultAssessmentId);
  }

  function redirectToDuplicatedAssessment(assessmentId: string) {
    history.push({
      pathname: RouteConstant.EDITASSESSMENT.replace(":id", assessmentId),
      state: {
        editMode: "Edit",
      },
    });
  }

  function handleAddTag(tagId?: number, assessmentIds?: Array<number>) {
    if (tagId && assessmentIds) {
      setShowLoading(true);
      addTag(tagId, assessmentIds, []).then((r) => {
        setShowLoading(false);
        toast.success("Tag has been applied to selected assessments.");
        toggleAddTagPopup(true, true);
      });
    }
  }

  function handleRemoveTag(
    tagId?: number,
    tagName?: string,
    assessmentIds?: Array<number>
  ) {
    if (tagId && assessmentIds && assessmentIds?.length > 0) {
      removeTag(tagId, assessmentIds, []).then((r) => {
        toast.success(`${tagName} has been removed.`);

        searchAssessments();
      });
    }
  }

  function getUserTags() {
    getAllTags(userId).then((response) => {
      setTagData(response.data.data);
    });
  }

  function getTags() {
    let tags: Array<ISelectElement> = [];
    tags.push(defaultAllValue);
    setShowLoading(true);
    getAllTags(userId).then((response) => {
      setShowLoading(false);
      setTagData(response.data.data);
      response.data.data.map((values: any) => {
          tags.push({ label: values.name, value: values.userTagId });
          return null;
      });
      setTags(tags);
    });
  }

  function getExistingTags(tags: string) {
    const tagIds: Array<string> = tags ? tags.split(",") : [];

    const existingTags: Array<tagListResponse> = tagData.filter((t) =>
      tagIds.includes(t.userTagId.toString())
    );

    return existingTags;
  }

  function resetFilters(search: boolean = true) {
    setSelectedSubject(defaultValue);
    setSelectedTag(defaultAllValue);
    setName("");
    setCode("");
    setFromDate(new Date());
    setToDate(new Date());
    setIsArchived(false);
    setIsOrphaned(false);
    setViewMyAssessmentOnly(props.selectedTabId == 0 ? true : false);
    setResetPageNumber(true);
    setFromDate(undefined);
    setToDate(undefined);
    setSelectedViewByFilter(viewByOptions[0]);
    setSelectedAuthor([]);

    if(search) {
      const assignmentSearch: IAssessmentSearch = {
        teacherId: userId,
        fromDate: new Date(),
        toDate: new Date(),
        pageNumber: DEFAULT_PAGE_NUMBER,
        pageSize: DEFAULT_PAGE_SIZE,
        orderByColumn: "name",
        sortByDesc: false,
        viewMyAssessmentOnly: true,
        canViewValue: undefined,
        canDuplicateValue: undefined,
        canAssignValue: undefined,
      };

      setShowLoading(true);
      getAssessments(assignmentSearch).then((r) => {
        setAssessments(r.data);
        setSelectedAssessments([]);
        setShowLoading(false);
      });
    }
  }

  function searchAssessments(resetPageNumber: boolean = false) {
    let utcFromDate: Date | undefined;
    let utcToDate: Date | undefined;

    if (selectedFromDate != null) {
      utcFromDate = getStartDate(selectedFromDate);
    }
    if (selectedToDate != null) {
      utcToDate = getEndDate(selectedToDate);
    }

    if (resetPageNumber) {
      setPageNumber(DEFAULT_PAGE_NUMBER);
    }
    setShowLoading(true);
    const assessmentSearch: IAssessmentSearch = {
      teacherId: userId,
      subjectId:
        selectedSubject && selectedSubject.value !== "0"
          ? parseInt(selectedSubject.value)
          : undefined,
      dateType: selectedFromDate && selectedToDate ? "Create Date" : undefined,
      tagId:
        selectedTag && selectedTag.value !== "0"
          ? parseInt(selectedTag.value)
          : undefined,
      fromDate: utcFromDate,
      toDate: utcToDate,
      isArchived: isArchived,
      isOrphaned: isOrphaned,
      name: name,
      code: code,
      viewMyAssessmentOnly: viewMyAssessmentOnly,
      pageNumber: pageNumber,
      pageSize: pageSize,
      orderByColumn: orderByColumn,
      sortByDesc: sortByDesc,
      canViewValue: undefined,
      canDuplicateValue: undefined,
      canAssignValue: undefined,
      authorIds:
        props.selectedTabId == 1 && (selectedAuthor?.length ?? 0) > 0
          ? selectedAuthor?.map((e) => Number(e.value))
          : undefined,
    };

    setShowLoading(true);
    getAssessments(assessmentSearch).then((r) => {
      setShowLoading(false);
      let assessmentTags: Array<string> = [];
      const assessmentData = r.data.data;
      if (assessmentData && assessmentData.length > 0) {
        assessmentData.map((assessment) => {
          if (assessment.tagIds && assessment.tagIds !== "") {
            assessmentTags.push(...assessment.tagIds.split(","));
          }
          return null;
        });
      }
      setAssessments(r.data);

      setShowLoading(false);
      setToggleSelectAll(false);
    });
  }

  const handleRedirect = (
    assessmentId: string,
    isSharedAssessment: boolean,
    mode: string,
    sharedTypeId: number,
    isAssessmentStarted: boolean,
    authorId: number
  ) => {
    setSelectedAssessmentId(parseInt(assessmentId));
    if (
      sharedTypeId === Constant.AssessmentSharedType.DistrictShared &&
      roleId !== Constant.UserRoleId.District
    ) {
      setToggleEditPopup(true);
      setEditPopupMessage(
        "District Assessments are not permitted to be edited. You may duplicate this assessment if you wish to create a similar assessment that can be edited."
      );
      setAllowContinue(false);
    } else if (userId !== authorId) {
      setToggleEditPopup(true);
      const errorMessage = `${
        isSharedAssessment ? "Shared " : ""
      }Assessments are not permitted to be edited. You may duplicate this assessment if you wish to create a similar assessment that can be edited.`;
      setEditPopupMessage(errorMessage);
      setAllowContinue(false);
    } else if (isAssessmentStarted) {
      setToggleEditPopup(true);
      setAllowContinue(true);
      setEditPopupMessage(
        "This assessment has already been started by at least one student. You may still edit settings but the content shall be disabled. You may duplicate this assessment if you wish to create a similar assessment that can be fully edited."
      );
    } else {
      history.push({
        pathname: RouteConstant.EDITASSESSMENT.replace(":id", assessmentId),
        state: {
          editMode: mode,
          isSchoolAssessment:
            sharedTypeId === Constant.AssessmentSharedType.SchoolShared,
        },
      });
    }
  };

  function canEditOrDuplicate(assessment: Assessment) {
    let canEditOrDuplicate: boolean = true;

    if (
      assessment.authorId !== userId &&
      assessment.isSharedAssessment &&
      (assessment.sharedTypeId === Constant.AssessmentSharedType.SchoolShared ||
        assessment.sharedTypeId ===
          Constant.AssessmentSharedType.DistrictShared) &&
      !assessment.canDuplicate
    ) {
      canEditOrDuplicate = false;
    }

    return canEditOrDuplicate;
  }

  function handleSelectedAssignments(assignmentId: number) {
    var selectedAssignmentList: Array<number> = new Array<number>();

    const assignmentExist = selectedAssessments.some(
      (r) => (r as number) === assignmentId
    );

    if (assignmentExist) {
      selectedAssignmentList = selectedAssessments.filter(
        (r) => (r as number) !== assignmentId
      );
    } else {
      selectedAssignmentList = [...selectedAssessments, assignmentId];
    }

    setSelectedAssessments(selectedAssignmentList);
  }

  function handleSetting(
    assessmentId: number,
    sharedTypeId: number,
    userId: number,
    authorId: number,
    isSharedAssessment: boolean,
    isAssessmentStarted: boolean
  ) {
    setSelectedAssessmentId(assessmentId);
    if (
      sharedTypeId === Constant.AssessmentSharedType.DistrictShared &&
      roleId !== Constant.UserRoleId.District
    ) {
      setToggleEditPopup(true);
      setEditPopupMessage(
        "District Assessments are not permitted to be edited. You may duplicate this assessment if you wish to create a similar assessment that can be edited."
      );
      setAllowContinue(false);
    } else if (userId !== authorId) {
      setToggleEditPopup(true);
      const errorMessage = `${
        isSharedAssessment ? "Shared " : ""
      }Assessments are not permitted to be edited. You may duplicate this assessment if you wish to create a similar assessment that can be edited.`;
      setEditPopupMessage(errorMessage);
      setAllowContinue(false);
    } else {
      setShowLoading(true);
      getAssessmentById(assessmentId).then((response) => {
        setShowLoading(false);
        let assessment: IAssessment = response.data;
        const tags = response.data.tagIds;
        if (tags && tags !== "") {
          assessment.tagIds = tags.split(",").map((r) => parseInt(r));
        }

        setShowSettingPopup(true);
        setAssessmentSetting(assessment);
      });
    }
  }

  function createAssignmentAndRedirect(assessment: Assessment) {
    const assignmentActivity: IAssignmentActivity = {
      assignmentActivityId: 0,
      assignmentId: 0,
      activityTypeId: getActivityTypeId(
        Constant.AssignmentActivityType.ASSESSMENT
      ),
      activityType: Constant.AssignmentActivityType.ASSESSMENT,
      sequenceNumber: 1,
      assessmentId: assessment.assessmentId.toString(),
      assessmentName: assessment.name,
      learnosityIdEnglish: assessment.learnosityIdEnglish,
      learnosityIdSpanish: assessment.learnosityIdSpanish,
    };

    setShowLoading(true);
    addAssignment(
      userId,
      assessment.name,
      assessment.subjectId,
      [assignmentActivity],
      props.defaultSchoolYearId
    ).then((response) => {
      setShowLoading(false);
      const assignmentId = response.data;
      history.push({
        pathname: RouteConstant.EDITASSIGNMENT.replace(":id", assignmentId),
        state: {
          editMode: "AssignStudent",
        },
      });
    });
  }

  const handleAssign = (assessment: Assessment, questionsCount: number) => {
    if (questionsCount === 0) {
      setErrorMessagePopup(true);
      setErrorMessage(
        "You cannot assign this assessment as this does not contain any questions."
      );
      return false;
    }

    if (
      questionsCount > 0 &&
      (!assessment.learnosityIdEnglish ||
        assessment.learnosityIdEnglish.length <= 0)
    ) {
      setErrorMessagePopup(true);
      setErrorMessage("Something went wrong with this assessment.");
      return false;
    }

    createAssignmentAndRedirect(assessment);
  };

  function handleSelectAll() {
    const isSelected = !toggleSelectAll;
    if (isSelected && assessments && assessments.data) {
      setSelectedAssessments(
        assessments.data.map((r) => {
          return parseInt(r.assessmentId);
        })
      );
    } else {
      setSelectedAssessments([]);
    }
    setToggleSelectAll(!toggleSelectAll);
  }

  async function handleConfirmation(
    action: ActionButton,
    assignmentId: number = 0
  ) {
    setButtonAction(action);
    setshowConfirmation(true);
    var message: string = "";
    switch (action) {
      case "Archive":
        message =
          "Are you sure you want to archive the selected assessment(s)?";
        break;
      case "Delete":
        message = "Are you sure you want to delete selected assessments.?";
        break;
      case "Duplicate":
        message = "Are you sure you want to duplicate selected assessments.?";
        //setDuplicateAssignmentId(assignmentId);
        break;
      case "Unarchive":
        message = "Are you sure you want to unarchive selected assessments.?";
        break;
    }

    setConfirmationMessage(message);
  }
  async function handleDuplicateConfirmationPopup(
    action: ActionButton,
    assessmentId?: number
  ) {
    let message =
      "You are about to duplicate the content of this assessment. Are you sure you want to duplicate this?";
    if (upgrade) {
      if (await checkUpgradeLimit()) {
        setUpgradeMessage(constant.UPGRADE_MESSAGE_ASSESSMENT_LIMIT_NEW);
        setShowUpgradeLimitPopup(true);
        return;
      }
      message = `You have created ${assessmentCount} of ${constant.TEACHER_FREE_ASSESSMENT_LIMIT} assessments for your account. You are about to duplicate this assessment which will count towards your ${constant.TEACHER_FREE_ASSESSMENT_LIMIT} maximum. Are you sure you want to duplicate the content of this assessment?`;
    }

    if (assessmentId) {
      setSelectedAssessmentId(assessmentId);
    }
    setButtonAction(action);
    setshowConfirmation(true);
    setConfirmationMessage(message);
  }

  function handlePrintPopup(
    learnosityId: string,
    assessmentId?: number,
    isAnswerKey?: boolean,
    code?: string
  ) {
    if (assessmentId) {
      setSelectedAssessmentId(assessmentId);
    }
    if (learnosityId) {
      setSelectedLearnosityId(learnosityId);
    }
    if (isAnswerKey) {
      setIsAnswerKeyToPrint(isAnswerKey);
    }
    if (code) {
      setCodeToPrint(code);
    }
    setShowPrintPopup(true);
  }
  function handleComparePopup(assessmentId: number, assessementName: string) {
    setShowComparePopup(true);
    setAssessmentToCompare({ name: assessementName, Id: assessmentId });
  }

  function handleExportResults(assessmentId: number, assessementName: string) {
    const subjectId =
      assessments?.data.filter(
        (a) => parseInt(a.assessmentId) === assessmentId
      )[0]?.subjectId ?? 0;
    getAssessmentResultsByStudent(
      districtId,
      subjectId,
      schoolId,
      userId,
      assessmentId.toString(),
      currentSchoolYearId,
      startDate,
      endDate
    ).then((d) => {
      if (d.data.data?.rows?.length > 0) {
        exportAssessmentResultsByStudentToExcel(
          districtId,
          subjectId,
          schoolId,
          userId,
          assessmentId.toString(),
          "",
          currentSchoolYearId,
          startDate,
          endDate
        ).then((response) => {
          const file = response.data;
          const fileURL = URL.createObjectURL(file);
          const link = document.createElement("a");
          link.href = fileURL;
          link.download =
            assessementName.replace(/ /g, "") + "ResultsExport.xlsx";
          link.setAttribute("target", "_blank");
          document.body.appendChild(link);
          link.click();
        });
      } else {
        toast.info("Results are not available for the selected assessment.");
      }
    });
  }

  function handleOkConfirmation() {
    setshowConfirmation(false);
    setToggleSelectAll(!toggleSelectAll);
    switch (buttonAction) {
      case "Archive":
        handleUpdateAssessments(Constant.AssignmentUpdateAction.ARCHIVE, true);
        break;
      case "Unarchive":
        handleUpdateAssessments(Constant.AssignmentUpdateAction.ARCHIVE, false);
        break;
      // case "Delete":
      //   handleDeleteAssessments();
      //   break;
      case "Duplicate":
        handleDuplicateAssignment();
        break;
    }
    setSelectedAssessments([]);
  }

  function toggleAddTagPopup(
    popupAction: boolean,
    refreshData: boolean = false
  ) {
    setShowAddTagPopup(popupAction);
    if (refreshData) {
      searchAssessments();
    }
  }

  function handleDeleteConfirmationPopup(
    popupAction: boolean,
    refreshData: boolean = false
  ) {
    setToggleDeleteAssessmentPopup(popupAction);
    if (refreshData) {
      searchAssessments();
    }
  }

  function handleToggleDeletePopup() {
    const assessmentsToDelete =
      assessments &&
      assessments.data.filter((r) =>
        selectedAssessments.includes(parseInt(r.assessmentId))
      );

    const includedNonAuthoredAssessment: boolean | undefined =
      assessmentsToDelete &&
      assessmentsToDelete.some((r) => r.authorId !== userId);

    if (includedNonAuthoredAssessment) {
      setErrorMessage(
        "You cannot perform this action because you have selected assessment(s) that are not authored by you."
      );
      setErrorMessagePopup(true);
    } else {
      setToggleDeleteAssessmentPopup(true);
    }
  }

  function handleToggleArchivePopup() {
    const assessmentsToArchive =
      assessments &&
      assessments.data.filter((r) =>
        selectedAssessments.includes(parseInt(r.assessmentId))
      );

    const includedNonAuthoredAssessment: boolean | undefined =
      assessmentsToArchive &&
      assessmentsToArchive.some((r) => r.authorId !== userId);

    if (includedNonAuthoredAssessment) {
      setErrorMessage(
        "You cannot perform this action because you have selected assessment(s) that are not authored by you."
      );
      setErrorMessagePopup(true);
    } else {
      handleConfirmation(isArchived ? "Unarchive" : "Archive");
    }
  }

  function toggleSettingPopup(
    popupAction: boolean,
    refreshData: boolean = false
  ) {
    setShowSettingPopup(popupAction);
    setAssessmentSetting(undefined);
    if (refreshData) {
      searchAssessments();
    }
  }

  function handleCancelConfirmation() {
    setshowConfirmation(false);
    setButtonAction(undefined);
    setConfirmationMessage("");
  }
  function handleCancelPrint() {
    setShowPrintPopup(false);
    setSelectedAssessmentId(0);
    setSelectedLearnosityId("");
    setIsAnswerKeyToPrint(false);
    setCodeToPrint("");
  }
  function handleCancelCompare() {
    setShowComparePopup(false);
  }
  const handleCompareSelected = () => {
    if (selectedAssessmentToCompare === 0) {
    }
    setShowComparePopup(false);
    history.push(RouteConstant.ASSESSMENT_RESULT);
  };

  const onNewTagCreated = () => {
    getUserTags();
  };
  const onPageChange = (pageNo) => {
    const elem: any = document.querySelectorAll("input[type=checkbox]");
    if (elem.length) {
      elem.forEach((el) => (el.checked = false));
    }

    setPageNumber(pageNo);
  };
  const onPageSizeChange = (pageSize) => {
    setPageNumber(DEFAULT_PAGE_NUMBER);
    setPageSize(pageSize);
    setResetPageNumber(true);
  };
  const handleSortBy = (column: string) => {
    setSortByDesc(!sortByDesc);
    setOrderByColumn(column);
  };

  function isDistrictAssessment(authorId: number, sharedType: number) {
    if (sharedType === Constant.AssessmentSharedType.DistrictShared) {
      if (roleId !== Constant.UserRoleId.District) {
        return true;
      } else {
        return userId !== authorId;
      }
    } else {
      return false;
    }
  }

  useEffect(() => {
    resetFilters(false); //Don't want resetFilters() to trigger a search. setViewMyAssessmentOnly for tab switch will trigger the search
    if (props.selectedTabId === 0) {
      setViewMyAssessmentOnly(true);
    } else {
      setViewMyAssessmentOnly(false);
    }
    if (props.selectedTabId === 1) {
      bindAuthors();
    }
  }, [props.selectedTabId]);

  useEffect(() => {
    searchAssessments();
  }, [viewMyAssessmentOnly,pageSize, pageNumber, sortByDesc]);

  useEffect(() => {
    switch (selectedViewByFilter.value) {
      case "Current":
        setIsOrphaned(false);
        setIsArchived(false);
        break;

      case "Archived":
        setIsArchived(true);
        setIsOrphaned(false);
        break;

      case "Orphaned":
        setIsOrphaned(true);
        setIsArchived(false);
        break;
    }
  }, [selectedViewByFilter]);

  const openModal = (activityDetail: IActivityPreviewDetail) => {
    if (!activityDetail.previewId) {
      return false;
    }

    setOpen(true);
    setActivityPreviewDetail(activityDetail);
  };

  function handleContinueClick() {
    // get the current assessment by id
    const assessment = assessments?.data.find(
      a => a.assessmentId.toString() === selectedAssessmentId.toString());
      
    history.push({
      pathname: RouteConstant.EDITASSESSMENT.replace(
        ":id",
        selectedAssessmentId.toString()
      ),
      state: {
        editMode: "EditSetting",
        isSchoolAssessment:
          assessment?.sharedTypeId === Constant.AssessmentSharedType.SchoolShared,
      },
    });
  }

  function handleTagManager() {
    history.push({
      pathname: RouteConstant.TeacherRoutes.Tags,
    });
  }

  const popUpCancel = () => {
    setToggleEditPopup(false);
  };

  const toggleErrorMessagePopup = () => {
    setErrorMessagePopup(false);
  };

  const popUpClose = (value) => {
    setOpen(value);
    setActivityPreviewDetail(undefined);
  };

  const alphaNumericExpression = new RegExp(/^[ A-Za-z0-9_@./#&+\-$!%]*$/);

  const handleResultsClick = (
    assessmentId,
    subjectId,    
    isSharedAssessment
  ) => {

    const redirectFrom = props.from === constant.RedirectedFrom.FULLASSESSMENT && isSharedAssessment === false 
      ? constant.RedirectedFrom.MYASSESSMENTS : props.from;

    const pathname = `${
      RouteConstant.GROUPED_RESULT_REPORTS
    }?assessmentId=${assessmentId}&redirectedFrom=${redirectFrom}&subjectId=${subjectId}&schoolYearId=${currentSchoolYearId}`;
    window.open(pathname, "_blank");
  };

  function bindAuthors() {
    setShowLoading(true);
    getAssessmentAuthors(userId, roleId).then((response) => {
      setShowLoading(false);
      const authorsData = response.data.data;
      var authors: Array<ISelectElement> = new Array<ISelectElement>();
      authorsData.forEach(function (keyValuePair: ICustomKeyValue) {
        authors.push({
          label: keyValuePair.name,
          value: keyValuePair.id.toString(),
        });
      });

      setAuthors(authors);
    });
  }
  function handleAuthorChange(selectedItem: any) {
    setSelectedAuthor(selectedItem);
  }

  function handleSetSelectedTagChange(option: ISelectElement | null) {
    if (option) {
      setSelectedTag(option);
    }
  }

  const isSharedAssessment = (assessment: Assessment) => {
    return (
      (userId === assessment.authorId &&
        assessment.isSharedAssessment &&
        assessment.sharedTypeId ===
          Constant.AssessmentSharedType.ClassShared) ||
      (userId !== assessment.authorId &&
        assessment.isSharedAssessment &&
        assessment.sharedTypeId ===
          Constant.AssessmentSharedType.ClassShared) ||
      (userId !== assessment.authorId &&
        assessment.isSharedAssessment &&
        assessment.sharedTypeId ===
          Constant.AssessmentSharedType.SchoolShared) ||
      (userId !== assessment.authorId &&
        isDistrictAssessment(assessment.authorId, assessment.sharedTypeId))
    )
  }
  
  return (
    <AllowedTo
      perform={Permissions.assessments_view}
      no={() => <AccessDenied />}
    >
      <ReactTooltip
        multiline={true}
        className="bg-secondary-teal"
        backgroundColor="#008fbb"
      />
      <EditOption
        message={editPopupMessage}
        allowContinue={allowContinue}
        open={toggleEditPopup}
        onContinueClick={handleContinueClick}
        onDuplicateClick={handleDuplicateAssignment}
        onCancelClick={popUpCancel}
      />
      {errorMessagePopup && (
        <MessagePopup
          message={errorMessage}
          togglePopup={toggleErrorMessagePopup}
        />
      )}
      {showDuplicationResultPopup && (
        <MessagePopup
          message={duplicationResultPopupMessage}
          togglePopup={handleDuplicationResultPopupConfirm}
        />
      )}

      {showUpgradePopup && (
        <UpGradeMessagePopup
          togglePopup={setShowUpgradePopup}
          roleId={props.roleId ?? 0}
          message={upgradeMessage}
        />
      )}
      {showUpgradeLimitPopup && (
        <UpGradeMessagePopup
          togglePopup={setShowUpgradeLimitPopup}
          roleId={props.roleId ?? 0}
          message={constant.UPGRADE_MESSAGE_ASSESSMENT_LIMIT}
        />
      )}

      {togglePrintToCode && (
        <AssessmentCard
          code={codeToPrint}
          togglePopup={setTogglePrintToCode}
        ></AssessmentCard>
      )}
      {toggleStatsPopup && (
        <AssessmentStat
          name={assessmentName}
          assessmentId={selectedAssessmentId}
          togglePopup={setToggleStatsPopup}
        ></AssessmentStat>
      )}
      {toggleDeleteAssessmentPopup &&
        assessments &&
        assessments.data.length > 0 &&
        selectedAssessments.length > 0 && (
          <DeleteAssessment
            userId={userId}
            assessments={assessments.data.filter((r) =>
              selectedAssessments.includes(parseInt(r.assessmentId))
            )}
            togglePopup={handleDeleteConfirmationPopup}
          ></DeleteAssessment>
        )}
      {showAddTagPopup && assessments && (
        <AddTag
          userId={userId}
          selectedAssessments={assessments.data.filter((r) =>
            selectedAssessments.includes(parseInt(r.assessmentId))
          )}
          toggleSettingPopup={toggleAddTagPopup}
          addTags={handleAddTag}
          removeTags={handleRemoveTag}
          onNewTagCreated={onNewTagCreated}
        />
      )}

      {showSettingPopup && assessmentSetting && (
        <SettingPopup
          userId={userId}
          roleId={roleId}
          assessmentForEdit={assessmentSetting}
          toggleSettingPopup={toggleSettingPopup}
          upgrade={upgrade}
        />
      )}

      <ConfirmationDialog
        open={showConfirmation}
        confirmationTitle={"Please confirm"}
        confirmationMessage={confirmationMessage}
        onCancelClick={handleCancelConfirmation}
        onOkClick={handleOkConfirmation}
      ></ConfirmationDialog>

      {showPrintPopup && (
        <PrintDialog
          learnosityId={selectedLearnosityId}
          assessmentId={selectedAssessmentId}
          onCancelClick={handleCancelPrint}
          open={showPrintPopup}
          isAnswerKey={isAnswerKeyToPrint}
          activityName={activityName}
          code={codeToPrint}
        ></PrintDialog>
      )}
      {showComparePopup && (
        <CompareAssessmentPopup
          onCancelClick={handleCancelCompare}
          handleCompareSelected={handleCompareSelected}
          setSelectedAssessmentToCompare={setSelectedAssessmentToCompare}
          assessmentToCompare={assessmentToCompare}
        ></CompareAssessmentPopup>
      )}
      {activityPreviewDetail && (
        <QuestionPreview
          modal={openModal}
          show={open}
          changeValue={popUpClose}
          activityRef={activityPreviewDetail.previewId}
          activityIdPreview={activityPreviewDetail.activityIdPreview}
          activityName={activityPreviewDetail.activityName}
          isProjectorMode={activityPreviewDetail.isProjectorMode}
        ></QuestionPreview>
      )}

      <div className="min-w-0 block lg:col-span-9 xl:col-span-10 w-full">
        <div className="bg-gray-100  w-full mb-5 rounded-lg p-5 h-full">
          <div
            className="inline-flex relative items-center cursor-pointer"
            onClick={() => {
              if (showFilter) {
                setShowFilter(false);
              } else {
                setShowFilter(true);
              }
            }}
          >
            <span className="bg-black w-10 h-10 rounded-full inline-block flex items-center justify-center ">
              {!showFilter && (
                <ArrowDownIcon className="w-6 text-white opacity-90" />
              )}
              {showFilter && (
                <ArrowUpIcon className="w-6 text-white opacity-90" />
              )}
            </span>
            <span className="mx-2 text-gray-700">
              {!showFilter
                ? "Expand Filter Options"
                : "Collapse Filter Options"}
            </span>
          </div>
          <div
            className={`${
              showFilter
                ? "max-h-[1000px] opacity-100 mt-5 pb-2"
                : "max-h-0 opacity-0 -mt-5 pb-0 pointer-events-none"
            } w-full bg-white transform transition-all ease-in-out duration-300 relative z-0 p-5 shadow`}
          >
            <div className="grid grid-cols-2 sm:grid-cols-8 lg:grid-cols-8 xl:grid-cols-8 gap-6 pb-4">
              <div className="col-span-8 sm:col-span-4 xl:col-span-2">
                <label className="block text-sm text-gray-500"> Name</label>
                <input
                  type="text"
                  name="Assignment-name"
                  id="Assignment-name"
                  autoComplete="off"
                  value={name}
                  onChange={(e) => {
                    if (
                      alphaNumericExpression.test(e.target.value.toString())
                    ) {
                      setName(e.target.value);
                    }
                  }}
                  className="mt-1 shadow-sm focus:ring-secondary-teal focus:border-secondary-teal h-10 block w-full text-lg border border-gray-200 text-gray-500 px-3 rounded-sm"
                />
              </div>
              <div className="col-span-8 sm:col-span-4 xl:col-span-2">
                <label className="block text-sm text-gray-500"> Code</label>
                <input
                  type="text"
                  autoComplete="off"
                  value={code}
                  onChange={(e) => {
                    if (
                      alphaNumericExpression.test(e.target.value.toString())
                    ) {
                      setCode(e.target.value);
                    }
                  }}
                  className="mt-1 shadow-sm focus:ring-secondary-teal focus:border-secondary-teal h-10 block w-full text-lg border border-gray-200 text-gray-500 px-3 rounded-sm"
                />
              </div>
              <div className="col-span-8 sm:col-span-4 xl:col-span-2">
                <Subject
                  setSelectedSubject={(items) => handleSubjectChange(items[0])}
                  selectedItems={selectedSubject}
                  isMulti={false}
                  themeColor={"#992883"}
                ></Subject>
              </div>
              <div className="col-span-8 sm:col-span-4 xl:col-span-2">
                <label className="block text-sm text-gray-500">Tag</label>
                <Select
                  value={selectedTag}
                  options={tags}
                  onChange={handleSetSelectedTagChange}
                  className="mt-1"
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: "#992883",
                    },
                  })}
                />
              </div>
              {props.selectedTabId === 1 && (
                <div className="col-span-8 sm:col-span-4 xl:col-span-2">
                  <label className="block text-sm text-gray-500">Author</label>
                  <Select
                    placeholder={"All"}
                    value={selectedAuthor}
                    options={authors}
                    onChange={handleAuthorChange}
                    className="mt-1"
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: "#992883",
                      },
                    })}
                    isMulti={true}
                  />
                </div>
              )}
              <div className="col-span-8 sm:col-span-4 xl:col-span-2 grid grid-cols-2 gap-4">
                <div>
                  <label className="block text-sm text-gray-500" htmlFor="">
                    From{" "}
                  </label>
                  <DatePicker
                    className="mt-1 react-datepicker__input-container py-1.5 px-3 block w-full shadow-sm focus:ring-secondary-teal focus:border-secondary-teal border border-gray-300 rounded-md text-gray-500"
                    selected={selectedFromDate}
                    onChange={(date) => handleFromDateChange(date)}
                  />
                </div>
                <div>
                  <label className="block text-sm text-gray-500" htmlFor="">
                    To{" "}
                  </label>
                  <DatePicker
                    className="mt-1 react-datepicker__input-container py-1.5 px-3 block w-full shadow-sm focus:ring-secondary-teal focus:border-secondary-teal border text-gray-500 border-gray-300 rounded-md "
                    selected={selectedToDate}
                    onChange={(date) => handleToDateChange(date)}
                  />
                </div>
              </div>
              {props.selectedTabId === 0 &&
                roleId !== Constant.UserRoleId.FreeTeacher && (
                  <div className="col-span-8 sm:col-span-4 xl:col-span-2">
                    <label className="block text-sm text-gray-500">
                      View By
                    </label>
                    <Select
                      value={selectedViewByFilter}
                      options={viewByOptions}
                      onChange={(e) => setSelectedViewByFilter(e)}
                      className="mt-1"
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary: "#992883",
                        },
                      })}
                    />
                  </div>
                )}
            </div>
            <AssessmentFilterActions
              onReset={resetFilters}
              onHandleTagManager={handleTagManager}
              onSearchAssessments={() => searchAssessments(true)}
            />
          </div>
          <div className="bg-white mt-5 py-5 px-5 ">
            {!showAddTagPopup && showLoading && <Loader></Loader>}
            <div className="flex flex-col gap-y-4 xl:gap-y-0 xl:flex-row xl:justify-between xl:items-end mb-5">
              <div className="grid grid-cols-2 sm:grid-cols-4 gap-4">
                <AllowedTo perform={Permissions.assessments_modify}>
                  <button
                    onClick={() => setShowAddTagPopup(true)}
                    disabled={selectedAssessments.length > 0 ? false : true}
                    className={`${
                      selectedAssessments.length > 0
                        ? "hover:text-white hover:bg-secondary-teal hover:shadow-lg"
                        : "border-opacity-50 text-opacity-50 cursor-not-allowed"
                    } bg-transparent border border-secondary-teal whitespace-nowrap shadow-sm py-2 px-4 inline-flex items-center justify-center text-sm font-medium text-secondary-teal  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition ease-in-out duration-500`}
                  >
                    <TagIcon className="w-4" />
                    <span className="ml-2">Add Tag</span>
                  </button>
                </AllowedTo>

                <AllowedTo perform={Permissions.assessments_modify}>
                  {!upgrade && (
                    <button
                      disabled={selectedAssessments.length <= 0}
                      onClick={() => handleToggleArchivePopup()}
                      className={`${
                        selectedAssessments.length > 0
                          ? "hover:text-white hover:bg-secondary-teal hover:shadow-lg"
                          : "border-opacity-50 text-opacity-50 cursor-not-allowed"
                      } bg-transparent border border-secondary-teal shadow-sm py-2 px-4 inline-flex items-center justify-center text-sm font-medium text-secondary-teal  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition ease-in-out duration-500`}
                    >
                      <ArchieveIcon />
                      <span className="ml-2">
                        {isArchived ? "Unarchive" : "Archive"}
                      </span>
                    </button>
                  )}
                </AllowedTo>
                {!upgrade && (
                  <AllowedTo perform={Permissions.assessments_delete}>
                    <button
                      disabled={selectedAssessments.length <= 0}
                      onClick={() => handleToggleDeletePopup()}
                      className={`${
                        selectedAssessments.length > 0
                          ? "hover:text-white hover:bg-red-500 hover:shadow-lg"
                          : "border-opacity-50 text-opacity-50 cursor-not-allowed"
                      } className="bg-transparent group border border-red-500 shadow-sm py-2 px-4 inline-flex items-center justify-center text-sm font-medium text-red-500 transition ease-in-out duration-500 "`}
                    >
                      <DeleteIcon className="w-4" />
                      <span className="ml-2">Delete</span>
                    </button>
                  </AllowedTo>
                )}
              </div>
              {subscriptionIsPremium && (
                <div className="flex flex-col gap-x-4 gap-y-3 sm:flex-row 2xl:gap-6 md:items-center xl:items-start 2xl:items-center sm:justify-end mt-2">
                  <div className="flex items-center xl:items-start 2xl:items-center">
                    <GradingIcon className="w-4 text-gray-400" />
                    <span className="text-xs ml-2 text-gray-500">
                      Grading Required
                    </span>
                  </div>
                  {roleId !== Constant.UserRoleId.District && (
                    <div className="flex items-center xl:items-start 2xl:items-center">
                      <div className="ml-1 ring-1 ring-paua ring-inset pointer-events-auto inline-flex items-baseline px-2 py-0 rounded-full text-xs font-medium bg-white text-paua lg:mt-0">
                        S
                      </div>
                      <span className="text-xs ml-2 text-gray-500">
                        Shared by You
                      </span>
                    </div>
                  )}
                  {roleId !== Constant.UserRoleId.District &&
                    props.selectedTabId !== 0 && (
                      <div className="flex items-center xl:items-start 2xl:items-center">
                        <div className="ml-1 pointer-events-auto inline-flex items-baseline px-2 py-0 rounded-full text-xs font-medium bg-paua text-white lg:mt-0">
                          S
                        </div>
                        <span className="text-xs ml-2 text-gray-500">
                          Teacher Shared
                        </span>
                      </div>
                    )}
                  {props.selectedTabId !== 0 && (
                    <div className="flex items-center xl:items-start 2xl:items-center">
                      <div className="ml-1 pointer-events-auto inline-flex items-baseline px-2 py-0 rounded-full text-xs font-medium bg-[#FFDB3A] text-dark-blue md:mt-2 lg:mt-0">
                        D
                      </div>
                      <span className="text-xs ml-2 text-gray-500">
                        District
                      </span>
                    </div>
                  )}
                  {roleId !== Constant.UserRoleId.District &&
                    props.selectedTabId !== 0 && (
                      <div className="flex items-center xl:items-start 2xl:items-center">
                        <div className="ml-1 pointer-events-auto inline-flex items-baseline px-2 py-0 rounded-full text-xs font-medium bg-[#F89F5A] text-dark-blue md:mt-2 lg:mt-0">
                          A
                        </div>
                        <span className="text-xs ml-2 text-gray-500">
                          School Admin
                        </span>
                      </div>
                    )}
                </div>
              )}
            </div>
            <div className="mb-4 pb-4 border-b-2 overflow-x-auto xl:overflow-x-visible">
              <table className="table w-full ">
                <thead className="mb-5">
                  <tr className="text-secondary-teal border-[3px] border-secondary-teal py-3 px-3">
                    <th className="text-left p-3 w-4 font-normal"></th>
                    <th className="text-left p-3 text-sm font-normal w-4">
                      <input
                        id="styled-checkbox-2"
                        type="checkbox"
                        className="checkbox focus:ring-secondary-teal h-4 w-4 text-secondary-teal border-secondary-teal border-2"
                        checked={toggleSelectAll}
                        onChange={() => handleSelectAll()}
                      />
                    </th>
                    <th className="text-center p-3 text-sm font-normal">
                      <div className="flex justify-center">
                        Create Date
                        <span className="cursor-pointer">
                          {sortByDesc && orderByColumn === "CreateDate" ? (
                            <CaretUpSolidIcon
                              onClick={() => handleSortBy("CreateDate")}
                              className="w-5 h-5"
                            ></CaretUpSolidIcon>
                          ) : (
                            <CaretDownSolidIcon
                              onClick={() => handleSortBy("CreateDate")}
                              className="w-5 h-5"
                            ></CaretDownSolidIcon>
                          )}
                        </span>
                      </div>
                    </th>
                    <th className="text-left p-3 text-sm font-normal"></th>
                    <th className="text-left p-3 text-sm font-normal">
                      <span className="flex">
                        Name
                        <span className="cursor-pointer">
                          {sortByDesc && orderByColumn === "name" ? (
                            <CaretUpSolidIcon
                              onClick={() => handleSortBy("name")}
                              className="w-5 h-5"
                            ></CaretUpSolidIcon>
                          ) : (
                            <CaretDownSolidIcon
                              onClick={() => handleSortBy("name")}
                              className="w-5 h-5"
                            ></CaretDownSolidIcon>
                          )}
                        </span>
                      </span>
                    </th>
                    <th className="text-left  p-3 text-sm font-normal">Code</th>
                    <AllowedTo perform={Permissions.assessments_results_view}>
                      <th className="text-center  p-3 text-sm font-normal">
                        Current Year Results
                      </th>
                    </AllowedTo>
                    <th className="text-center  p-3 text-sm font-normal">
                      Tag
                    </th>
                    <th className="text-center  p-3 text-sm font-normal">
                      Settings
                    </th>
                    <th className="text-center  p-3 text-sm font-normal">
                      Action
                    </th>
                    {(roleId === Constant.UserRoleId.SchoolAdmin ||
                      roleId === Constant.UserRoleId.SchoolTeacher) && (
                      <th className="text-center  p-3 text-sm font-normal">
                        Assign
                      </th>
                    )}
                    <th className="text-center  p-3 text-sm font-normal">
                      Edit
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {assessments && assessments?.totalRecords === 0 && (
                    <tr>
                      <td
                        className=" mt-5 p-3 text-left text-gray-500"
                        colSpan={6}
                      >
                        No record found
                      </td>
                    </tr>
                  )}
                  {assessments &&
                    assessments.data?.length > 0 &&
                    assessments.data?.map((assessment, index) => {
                      return (
                        <Fragment key={index}>
                          <tr
                            className={`hover:bg-secondary-teal/20 transition ease-in-out duration-300 ${
                              index % 2 === 0
                                ? "bg-white"
                                : "bg-secondary-teal/10"
                            }`}
                          >
                            <td className=" mt-5 p-3 text-center text-gray-500">
                              {userId === assessment.authorId &&
                                assessment.isSharedAssessment &&
                                assessment.sharedTypeId ===
                                  Constant.AssessmentSharedType.ClassShared && (
                                  <div className="ml-1 ring-1 ring-paua ring-inset pointer-events-auto inline-flex items-baseline px-2 py-0 rounded-full text-xs font-medium bg-white text-paua md:mt-2 lg:mt-0">
                                    S
                                  </div>
                                )}
                              {userId !== assessment.authorId &&
                                assessment.isSharedAssessment &&
                                assessment.sharedTypeId ===
                                  Constant.AssessmentSharedType.ClassShared && (
                                  <div className="ml-1 pointer-events-auto inline-flex items-baseline px-2 py-0 rounded-full text-xs font-medium bg-paua text-white md:mt-2 lg:mt-0">
                                    S
                                  </div>
                                )}
                              {userId !== assessment.authorId &&
                                assessment.isSharedAssessment &&
                                assessment.sharedTypeId ===
                                  Constant.AssessmentSharedType
                                    .SchoolShared && (
                                  <div className="ml-1 pointer-events-auto inline-flex items-baseline px-2 py-0 rounded-full text-xs font-medium bg-[#F89F5A] text-dark-blue md:mt-2 lg:mt-0">
                                    A
                                  </div>
                                )}
                              {userId !== assessment.authorId &&
                                isDistrictAssessment(
                                  assessment.authorId,
                                  assessment.sharedTypeId
                                ) && (
                                  <div className="ml-1 pointer-events-auto inline-flex items-baseline px-2 py-0 rounded-full text-xs font-medium bg-[#FFDB3A] text-dark-blue md:mt-2 lg:mt-0">
                                    D
                                  </div>
                                )}
                            </td>
                            <td className=" mt-5 p-3 text-center">
                              <input
                                id="chk"
                                type="checkbox"
                                className="checkbox focus:ring-secondary-teal h-4 w-4 text-secondary-teal border-gray-300"
                                checked={selectedAssessments.some(
                                  (r) =>
                                    (r as number) ===
                                    parseInt(assessment.assessmentId)
                                )}
                                onChange={() =>
                                  handleSelectedAssignments(
                                    parseInt(assessment.assessmentId)
                                  )
                                }
                              />
                              <label className="checkbox-label"></label>
                            </td>
                            <td className="text-center text-sm mt-5 p-3">
                              {Moment(assessment.createdDate).format(
                                "MM-DD-yyyy"
                              )}
                            </td>
                            <td className="text-sm mt-5 p-3 text-center">
                              {assessment.unGraded > 0 && isAllowViewAssessment(
                                props.roleId,
                                assessment.canView,
                                assessment.sharedTypeId,
                                assessment.authorId,
                                props.userId
                              ) && (
                                <GradingIcon
                                  title="Constructed Response"
                                  className={
                                    "inline-flex ml-2 w-5 h-5 text-red-500 hover:text-red-700 cursor-pointer"
                                  }
                                  onClick={() => {
                                    handleResultsClick(
                                      assessment.assessmentId,
                                      assessment.subjectId,
                                      isSharedAssessment(assessment)
                                    );
                                  }}
                                />
                              )}
                            </td>
                            <td
                              className="text-sm mt-5 p-3 cursor-pointer text-blue-500 underline hover:no-underline"
                              onClick={() => {
                                setToggleStatsPopup(true);
                                setAssessmentName(assessment.name);
                                setSelectedAssessmentId(
                                  parseInt(assessment.assessmentId)
                                );
                              }}
                            >
                              <div className="xl:w-28 1xl:w-auto break-words">
                                {assessment.name}
                              </div>
                            </td>
                            <td className="text-sm mt-5  p-3">
                              {assessment.code}
                            </td>
                            <AllowedTo
                              perform={Permissions.assessments_results_view}
                            >
                              {isAllowViewAssessment(
                                props.roleId,
                                assessment.canView,
                                assessment.sharedTypeId,
                                assessment.authorId,
                                props.userId
                              ) && (
                                <td
                                  className={classNames(
                                    "text-sm text-center mt-5 p-3 ",
                                    assessment.completed > 0
                                      ? "cursor-pointer text-blue-500 underline hover:no-underline"
                                      : ""
                                  )}
                                  onClick={() => {
                                    if (assessment.completed > 0)
                                      handleResultsClick(
                                        assessment.assessmentId,
                                        assessment.subjectId,                                        
                                        isSharedAssessment(assessment)
                                      );
                                  }}
                                >
                                  {assessment.completed}
                                </td>
                              )}
                              {!isAllowViewAssessment(
                                props.roleId,
                                assessment.canView,
                                assessment.sharedTypeId,
                                assessment.authorId,
                                props.userId
                              ) && (
                                <td
                                  className={classNames(
                                    "text-sm text-center mt-5 p-3 ",
                                    ""
                                  )}
                                >
                                  {" "}
                                  {assessment.completed}
                                </td>
                              )}
                            </AllowedTo>
                            <td
                              className="text-sm mt-5  p-3 cursor-pointer relative"
                              onMouseOver={() => {
                                if (assessment.tagIds) {
                                  setShowTags(true);
                                  setAssessmentToEdit(assessment);
                                }
                              }}
                              onMouseOut={() => setShowTags(false)}
                            >
                              <div className="flex justify-center gap-1">
                                <span className="mt-1">
                                  <TagOutlineIcon
                                    title="Tag"
                                    className="w-4 text-gray-500 hover:text-gray-700 cursor-pointer m-auto"
                                  />

                                  <div
                                    className={`absolute bg-blue-50 top-10 left-0  transition-all shadow-lg border border-blue-200 p-4 rounded-lg z-20
                                ${
                                  showTags &&
                                  assessment.assessmentId ===
                                    assessmentToEdit?.assessmentId
                                    ? "visible"
                                    : "hidden"
                                }
                                `}
                                    onMouseOver={() => {
                                      setShowTags(true);
                                      setAssessmentToEdit(assessment);
                                    }}
                                    onMouseOut={() => setShowTags(false)}
                                  >
                                    <div className="flex gap-1 flex-wrap bg-white/50 p-1">
                                      {getExistingTags(assessment.tagIds).map(
                                        (tag, index) => (
                                          <span
                                            className={`inline-block rounded-full text-xs shadow-sm py-0.5 px-2 overflow-hidden truncate flex 
                                             ${
                                               TagColor.isDark(tag.color)
                                                 ? "text-white"
                                                 : ""
                                             } bg-[${tag.color}]`}
                                          >
                                            {tag.name}
                                            <div className="flex w-4 ml-1 justify-center">
                                              <CancelIcon
                                                className="w-4 text-xs font-light cursor-pointer hover:text-red-500 transaction ease-in-out duration-300"
                                                onClick={() => {
                                                  handleRemoveTag(
                                                    tag.userTagId,
                                                    tag.name,
                                                    [
                                                      parseInt(
                                                        assessment.assessmentId
                                                      ),
                                                    ]
                                                  );
                                                }}
                                              ></CancelIcon>
                                            </div>
                                          </span>
                                        )
                                      )}
                                    </div>
                                  </div>
                                </span>
                                <>
                                  {assessment.tagIds === null ? (
                                    <span>0</span>
                                  ) : (
                                    <span className="text-blue-500">
                                      {assessment.tagIds.split(",").length}
                                    </span>
                                  )}
                                </>
                              </div>
                            </td>
                            <td className="text-sm mt-5  p-3">
                              {isAllowViewSettingAssessment(
                                props.roleId,
                                assessment.canView,
                                assessment.canDuplicate,
                                assessment.sharedTypeId,
                                assessment.authorId,
                                props.userId
                              ) && (
                                <OutlineSettingIcon
                                  title="Edit assessment settings"
                                  className={`cursor-pointer  w-4 m-auto text-gray-500 hover:text-gray-700`}
                                  onClick={() =>
                                    handleSetting(
                                      parseInt(assessment.assessmentId),
                                      assessment.sharedTypeId,
                                      props.userId,
                                      assessment.authorId,
                                      assessment.isSharedAssessment,
                                      assessment.completed > 0 ? true : false
                                    )
                                  }
                                />
                              )}
                              {!isAllowViewSettingAssessment(
                                props.roleId,
                                assessment.canView,
                                assessment.canDuplicate,
                                assessment.sharedTypeId,
                                assessment.authorId,
                                props.userId
                              ) && (
                                <OutlineSettingIcon
                                  title="Edit assessment settings"
                                  className={`w-4 m-auto text-gray-300 hover:text-gray-700`}
                                />
                              )}
                            </td>

                            <td className="text-sm mt-5 p-3 z-10 items-center">
                              {isAllowViewActionsAssessment(
                                props.roleId,
                                assessment.canView,
                                assessment.canDuplicate,
                                assessment.sharedTypeId,
                                assessment.authorId,
                                props.userId
                              ) && (
                                <ContextMenu
                                  items={getOptions(
                                    assessment.canView,
                                    assessment.canDuplicate,
                                    assessment.authorId,
                                    props.userId,
                                    assessment.sharedTypeId
                                  ).filter(
                                    (r) =>
                                      r.roles.some((r) => r === roleId) &&
                                      r.label !== "Compare"
                                  )}
                                  onChange={(
                                    selectdItem,
                                    assessmentId,
                                    learnosityId,
                                    activityName,
                                    _,
                                    value?: any
                                  ) => {
                                    handleOptionChange(
                                      selectdItem,
                                      assessmentId,
                                      learnosityId,
                                      activityName,
                                      value
                                    );
                                  }}
                                  entityId={parseInt(assessment.assessmentId)}
                                  learnosityId={assessment.learnosityIdEnglish}
                                  activityName={assessment.name}
                                  activityType={
                                    constant.ContextMenuActivityType.ASSESSMENT
                                  }
                                  value={assessment.code}
                                  onUpgrade={handleOptionUpgrade}
                                />
                              )}
                              {!isAllowViewActionsAssessment(
                                props.roleId,
                                assessment.canView,
                                assessment.canDuplicate,
                                assessment.sharedTypeId,
                                assessment.authorId,
                                props.userId
                              ) && (
                                <ContextMenu
                                  items={[]}
                                  isDisabled={true}
                                  onChange={(
                                    selectdItem,
                                    assessmentId,
                                    learnosityId,
                                    activityName,
                                    _,
                                    value?: any
                                  ) => {
                                    handleOptionChange(
                                      selectdItem,
                                      assessmentId,
                                      learnosityId,
                                      activityName,
                                      value
                                    );
                                  }}
                                  entityId={parseInt(assessment.assessmentId)}
                                  learnosityId={assessment.learnosityIdEnglish}
                                  activityName={assessment.name}
                                  activityType={
                                    constant.ContextMenuActivityType.ASSESSMENT
                                  }
                                  value={assessment.code}
                                  onUpgrade={handleOptionUpgrade}
                                />
                              )}
                            </td>
                            {(roleId === Constant.UserRoleId.SchoolAdmin ||
                              roleId === Constant.UserRoleId.SchoolTeacher) && (
                              <td className="text-sm mt-5  p-3">
                                {isAllowAssignOnlyOption(
                                  props.roleId,
                                  assessment.canAssign,
                                  assessment.sharedTypeId,
                                  assessment.authorId,
                                  props.userId
                                ) && (
                                  <UserAdd
                                    title="Assign assessment"
                                    className="disabled w-4 text-gray-500 hover:text-gray-700 cursor-pointer m-auto"
                                    onClick={() =>
                                      handleAssign(
                                        assessment,
                                        assessment.questionsCount
                                      )
                                    }
                                  />
                                )}
                                {!isAllowAssignOnlyOption(
                                  props.roleId,
                                  assessment.canAssign,
                                  assessment.sharedTypeId,
                                  assessment.authorId,
                                  props.userId
                                ) && (
                                  <UserAdd
                                    title="Assign assessment"
                                    className="w-4 text-gray-300 hover:text-gray-700  m-auto"
                                  />
                                )}
                              </td>
                            )}

                            <td
                              className={`${
                                canEditOrDuplicate(assessment)
                                  ? "cursor-pointer"
                                  : "pointer-events-none opacity-50"
                              }  text-sm mt-5 p-3`}
                            >
                              {((assessment.sharedTypeId !==
                                Constant.AssessmentSharedType.DistrictShared &&
                                assessment.isAllowEdit) ||
                                (assessment.sharedTypeId ===
                                  Constant.AssessmentSharedType
                                    .DistrictShared &&
                                  assessment.completed === 0)) && (
                                <EditOutlineIcon
                                  title="Edit assessment"
                                  onClick={() =>
                                    handleRedirect(
                                      assessment.assessmentId,
                                      assessment.isSharedAssessment,
                                      "Edit",
                                      assessment.sharedTypeId,
                                      false,
                                      assessment.authorId
                                    )
                                  }
                                  className="w-4 text-gray-500 hover:text-gray-700 cursor-pointer m-auto"
                                />
                              )}
                              {((assessment.sharedTypeId !==
                                Constant.AssessmentSharedType.DistrictShared &&
                                !assessment.isAllowEdit) ||
                                (assessment.sharedTypeId ===
                                  Constant.AssessmentSharedType
                                    .DistrictShared &&
                                  assessment.completed > 0)) &&
                                isAllowEditAssessment(
                                  props.roleId,
                                  assessment.canDuplicate,
                                  assessment.sharedTypeId,
                                  assessment.authorId,
                                  props.userId
                                ) && (
                                  <EditIcon
                                    title="Edit assessment"
                                    onClick={() =>
                                      handleRedirect(
                                        assessment.assessmentId,
                                        assessment.isSharedAssessment,
                                        "Edit",
                                        assessment.sharedTypeId,
                                        true,
                                        assessment.authorId
                                      )
                                    }
                                    className="w-4 text-gray-500 hover:text-gray-700 cursor-pointer m-auto"
                                  />
                                )}
                              {((assessment.sharedTypeId !==
                                Constant.AssessmentSharedType.DistrictShared &&
                                !assessment.isAllowEdit) ||
                                (assessment.sharedTypeId ===
                                  Constant.AssessmentSharedType
                                    .DistrictShared &&
                                  assessment.completed > 0)) &&
                                !isAllowEditAssessment(
                                  props.roleId,
                                  assessment.canDuplicate,
                                  assessment.sharedTypeId,
                                  assessment.authorId,
                                  props.userId
                                ) && (
                                  <EditIcon
                                    title="Edit assessment"
                                    className="w-4 text-gray-300 hover:text-gray-700 m-auto"
                                  />
                                )}
                            </td>
                          </tr>
                        </Fragment>
                      );
                    })}
                </tbody>
              </table>
            </div>
            <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
              {assessments?.totalRecords !== undefined &&
                assessments?.totalRecords !== 0 && (
                  <Pagination
                    totalRecords={assessments?.totalRecords}
                    pageSize={pageSize}
                    onPageChange={onPageChange}
                    reset={resetPageNumber}
                    pageNumber={pageNumber}
                    bgColor="secondary-teal"
                  />
                )}
              {assessments?.totalRecords !== 0 && (
                <PageSize
                  pageSize={pageSize}
                  onPageSizeChange={onPageSizeChange}
                  bgColor="secondary-teal"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </AllowedTo>
  );
}

export default MyAssessment;

import {} from "axios";
import { axiosInstance } from "../../../environment/axiosConfig";
import APIConstant from "../../../utils/constant/apiConstant";

export function validateRecoveryCode(code: string) {
  return axiosInstance.get(
    APIConstant.ForgotPassword_API.ValidateRecoveryCode,
    {
      params: {
        recoverycode: code,
      },
    }
  );
}

export function updatePassword(
  passwordRecoveryCode: string,
  newPassword?: string
) {
  const data = {
    recoveryCode: passwordRecoveryCode,
    password: newPassword,
  };
  return axiosInstance.put<any>(
    APIConstant.ForgotPassword_API.UpdatePassword,
    data
  );
}

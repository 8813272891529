import { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { GoogleApi } from "../../../../api/google/googleApi";
import { CloseIcon } from "../../../../assets/icons";
import { IGoogleCourseDTO } from "../../../../model/googleClassroom/googleCourseDTO";
import Profile from "../../../../model/users/profile";
import { UserContextState } from "../../../../redux/actions/userContextAction";
import Loader from "../../../shared/loader";

interface ImportClassPopupProps {
  userContext?: UserContextState;
  profile?: Profile;
  showPopup(show: boolean): void;
  onImportCompleted(): void;
}

function ImportClassPopup(props: ImportClassPopupProps) {
  const [loading, setLoading] = useState(false);
  const [classes, setClasses] = useState<IGoogleCourseDTO[]>([]);
  const [selectedCourseIds, setSelectedCourseIds] = useState<string[]>([]);

  const getGoogleClassesToImport = () => {
    setLoading(true);
    GoogleApi.getGoogleClassesToImport(
      props?.userContext?.userId!,
      props?.profile?.googleUserId!,
      props?.profile?.googleId!,
      props?.profile?.googleUser?.refreshToken!
    )
      .then((r) => {
        setLoading(false);
        if (r.data && r.data.length > 0) {
          const nonImportedCourses = r.data.filter((c) => !c.isImported);
          let allCourses = [...nonImportedCourses];
          allCourses = allCourses.sort((a, b) =>
            a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
          );
          setClasses(allCourses);
        }
      })
      .catch((r) => {
        setLoading(false);
      });
  };

  const importGoogleClasses = () => {
    const classesToImport = classes.filter((c) =>
      selectedCourseIds.includes(c.courseId)
    );
    if (classesToImport.length > 0) {
      setLoading(true);
      GoogleApi.importGoogleClasses(
        classesToImport,
        props?.userContext?.schoolAccountId!,
        props?.userContext?.userId!
      )
        .then((r) => {
          setLoading(false);
          props.onImportCompleted();
        })
        .catch((r) => {
          setLoading(false);
        });
    }
  };

  const handleSelectClasses = (courseId: string, e: any) => {
    const courseIds = selectedCourseIds;
    if (e.target.checked) {
      setSelectedCourseIds([...courseIds, courseId]);
    } else {
      const filteredClassIds = courseIds.filter((item) => item !== courseId);
      setSelectedCourseIds(filteredClassIds);
    }
  };

  useEffect(() => {
    getGoogleClassesToImport();
  }, []);

  return (
    <Fragment>
      <div className="fixed inset-0 z-40 flex items-center bg-black bg-opacity-50 sm:items-center sm:justify-center">
        <div className="w-100 flex flex-col justify-between overflow-hidden h-auto max-h-80percent bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:max-w-6xl">
          <header>
            {/* Header */}
            <div className="px-4 py-4 bg-gray-50">
              <div className="flex items-start justify-between space-x-3">
                <div className="space-y-1">
                  <div className="text-lg font-medium text-gray-900">
                    Google Classroom Courses
                  </div>
                </div>
                <div className="h-7 flex items-center">
                  <button
                    type="button"
                    className="inline-flex items-center justify-center w-6 h-6 text-gray-400 transition-colors duration-150 rounded dark:hover:text-gray-200 hover: hover:text-gray-700"
                    onClick={() => props.showPopup(false)}
                  >
                    <CloseIcon />
                  </button>
                </div>
              </div>
            </div>
          </header>
          <div className="w-full h-72 px-4 py-1 relative overflow-auto">
            {loading && <Loader />}
            <p> Select the courses you want to import</p>

            <div className="my-2">
              {classes.length > 0 &&
                classes.map((c, i) => (
                  <div key={i} className="">
                    <label className="flex items-centers cursor-pointer text-sm text-gray-500 ">
                      <input
                        type="checkbox"
                        x-model="value"
                        name="privacy_setting"
                        value="Private to Project Members"
                        className="h-4 w-4 mr-2 mt-0.5 cursor-pointer text-blue-600 border-gray-300 focus:ring-blue-500"
                        checked={selectedCourseIds.includes(c.courseId)}
                        onChange={(e) => {
                          handleSelectClasses(c.courseId, e);
                        }}
                      />
                      {c.name}
                    </label>
                  </div>
                ))}
            </div>
            <div>{classes.length === 0 && "No Records Found"}</div>
          </div>

          {/* <!-- Action Buttons -->  */}
          <footer className="flex items-center justify-end px-4 py-4 sm:flex-row bg-gray-50 dark:bg-gray-800 mt-4">
            <div className="space-x-3 flex justify-end">
              <button
                type="button"
                className="w-auto inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                onClick={importGoogleClasses}
              >
                Import
              </button>
            </div>
          </footer>
        </div>
      </div>
    </Fragment>
  );
}

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(ImportClassPopup);

import classNames from "classnames";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { ClassApi } from "../../../../api/teacher/classManagementApi";
import RouteConstant from "../../../../utils/constant/routeConstant";
import {
  EnglishIcon,
  MathIcon,
  ScienceIcon,
  SocialStudiesIcon,
} from "../../../../assets/icons";
import {
  GradeLevelSubject,
  IAvailavelClassSubject,
} from "../../../../model/teacher/classManagement/IAvailavelClassSubject";
import { UserContextState } from "../../../../redux/actions/userContextAction";
import { useHistory } from "react-router-dom";
import { IBreadcrumb } from "../../../../model/common/breadcrumb";
import routeConstant from "../../../../utils/constant/routeConstant";
import { useTranslation } from "react-i18next";
import Breadcrumb from "../../teacher/shared/breadcrumb";
//import ConfirmationDialog from "../../../shared/confirmationDialog";
import TrialRequestPopup from "./trialRequestPopup";
import { SubscriptionRequestType } from "../../../../types/type";
import TrialSubscriptionRequest from "../../../shared/trialSubscriptionRequestPopup";
import Constants from "../../../../utils/constant/constant";
import InstructionalResourcesHorizonEducation from "./instructionalResourcesHorizonEducation";
interface SubjectCardProps {
  gradeLevelSubject: GradeLevelSubject;
  onSubjectSelect: Function;
}

interface SubjectPopupProps {
  showPopUp: (show: boolean) => void;
  classId: number;
  userContext?: UserContextState;
  selectedSubjectIds?: number[];
}

const InstructionalResourceSubject = (props: SubjectPopupProps) => {
  const [availableSubjects, setAvailableSubjects] = useState<
    IAvailavelClassSubject[]
  >([]);
  const [showConfirmation, setshowConfirmation] = useState<boolean>(false);
  const [selectedSubject, setSelectedSubject] = useState<Array<number>>(
    props.selectedSubjectIds ?? []
  );
  const [trialSubject, setTrialSubject] = useState<string>("");
  const [trialSubjectId, setTrialSubjectId] = useState<number>(0);
  const [ShowTrialRequestPopup, setShowTrialRequestPopup] = useState(false);
  const history = useHistory();
  useEffect(() => {
    getAvailabelSubjects();
  }, []);
  const [requestType, setRequestType] = useState<SubscriptionRequestType>(
    SubscriptionRequestType.Trial
  );
  const [showTeacherFreeTrialRequest, setShowTeacherFreeTrialRequest] = useState<boolean>(false);
  const { t } = useTranslation();

  const breadcrumbItems = () => {
    const items: Array<IBreadcrumb> = [
      { name: t("Breadcrumb.Home"), url: routeConstant.ROUTE_DASHBOARD },
      { name: t("Breadcrumb.InstructionalResources"), url: "" },
      { name: t("Breadcrumb.Subjects"), url: "" },
    ];

    return items;
  };

  function getAvailabelSubjects() {
    ClassApi.getInstructionalResourcesSubjectsByUserId(
      props.userContext?.userId
    ).then((d) => {
      var filterdSubject: Array<IAvailavelClassSubject> = [];
      var hasAvailabelSubject: boolean = false;
      d.data.forEach((element) => {
        element.gradeLevelSubject.forEach((glSubject) => {
          if (glSubject.subjects.length > 0) {
            glSubject.subjects.forEach((subject) => {
              subject.isSelected =
                (props.selectedSubjectIds ?? []).indexOf(subject.subjectId) > -1
                  ? true
                  : false;
            });

            hasAvailabelSubject = true;
          }
        });

        if (hasAvailabelSubject) {
          filterdSubject.push(element);
        }
        hasAvailabelSubject = false;
      });
      setAvailableSubjects(filterdSubject);
    });
  }

  const selectSubject = (id, name?, isAdditional?) => {
    console.log("props from the right place", props.userContext?.roleId);
    if (isAdditional) {

      if(props.userContext?.roleId === Constants.UserRoleId.FreeTeacher){
        // Show the teacher free message        
        setShowTeacherFreeTrialRequest(true);
      }
      else {
        setshowConfirmation(true);
        setTrialSubject(name);
        setTrialSubjectId(id);
      }
      
    } else {
      const filterdArray = selectedSubject.filter((r) => {
        return r === id;
      });
      if (window.location.pathname.includes("instructionalResources")) {
        const selectedSubject = { value: id, label: name };
        history.push({
          pathname: RouteConstant.TeacherRoutes.InstructionalResources,
          state: { selectedSubject: selectedSubject },
        });
      } else {
        setSelectedSubject(filterdArray);
        history.push({
          pathname: RouteConstant.MiddleSchool.standards.replace(":id", id),
        });
      }
    }
  };

  function handleOkConfirmation() {
    setshowConfirmation(false);
    trialRequest();
  }

  function handleOkConfirmationTeacherFree() {
    setShowTeacherFreeTrialRequest(false);
  }

  function handleCancelConfirmation() {
    setshowConfirmation(false);
    setShowTrialRequestPopup(false);
  }
  function trialRequest() {
    if (trialSubject) {
      setRequestType(SubscriptionRequestType.Trial);
      setShowTrialRequestPopup(true);
    }
  }

  const getHasMiddleOrHighSchoolSubjects = (availableSubjects: IAvailavelClassSubject[]): boolean => {
    const middleSchoolSubject = availableSubjects.find(subject => subject.gradeLevel === "Middle School");
    const highSchoolSubjects = availableSubjects.find(subject => subject.gradeLevel === "High School");
    const hasMiddleOrHighSchoolSubjects = middleSchoolSubject?.gradeLevelSubject.length || highSchoolSubjects?.gradeLevelSubject.length;
    return !!hasMiddleOrHighSchoolSubjects;
  }

  return (
    <div className="w-full px-3 pb-3">
      <div className="w-full">
        <div className="lg:flex lg:items-center px-4 py-3 lg:px-8 border-b border-gray-200">
          <Breadcrumb items={breadcrumbItems()} />
          <div className="lg:w-[55%] text-lg font-medium flex gap-2 h-auto sm:h-10 items-center">
            <h1 className="text-primary-violet font-medium flex justify-start">
              Instructional Resources Subjects
            </h1>
          </div>
        </div>
        {availableSubjects.map((availableSubject, index) => (
          <div
            key={index}
            className={classNames(
              "flex flex-col text-white text-lg py-3 px-3",
              availableSubject.gradeLevel === "Elementary"
                ? "bg-galaxy-green"
                : availableSubject.gradeLevel === "Middle School"
                ? "bg-light-blue"
                : "bg-light-green"
            )}
          >
            <div>{availableSubject.gradeLevel}</div>
            <div className="flex flex-col gap-y-4 lg:flex-row flex-wrap space-x-0 lg:gap-4">
              {availableSubject.gradeLevelSubject.map((gl, index) => (
                <div className="flex" key={index}>
                  {" "}
                  <SubjectCard
                    gradeLevelSubject={gl}
                    onSubjectSelect={selectSubject}
                  />{" "}
                </div>
              ))}
            </div>
          </div>
        ))}
        {getHasMiddleOrHighSchoolSubjects(availableSubjects) && <InstructionalResourcesHorizonEducation />}
      </div>
      <TrialRequestPopup
        open={showConfirmation}
        confirmationTitle={"Request Trial"}
        confirmationMessage={`This subject is not currently included in your
        subscription. Would you like to submit a request for a 30
        day trial?`}
        onCancelClick={handleCancelConfirmation}
        onOkClick={handleOkConfirmation}
        okButtonText="Request Trial"
      ></TrialRequestPopup>
      <TrialRequestPopup
        open={showTeacherFreeTrialRequest}
        confirmationTitle={"Request a Quote"}
        confirmationMessage={`This subject is not currently included in your Teacher Free account.
          You may reach out to <a class="blue-underline-link" href="mailto:success@progresslearning.com">success@progresslearning.com</a> to learn more about upgrading to a School account.`}
        onCancelClick={() => {}}
        onOkClick={handleOkConfirmationTeacherFree}
        okButtonText="Ok"
        hideCancelButton={true}
      ></TrialRequestPopup>
      {ShowTrialRequestPopup === true && (
        <TrialSubscriptionRequest
          showPopUp={(show: boolean) => {
            setShowTrialRequestPopup(false);
          }}
          subjects={[trialSubject]}
          subjectIds={[trialSubjectId]}
          requestType={requestType}
          schoolId={props.userContext?.schoolId}
        />
      )}
    </div>
  );
};

const SubjectCard = (props: SubjectCardProps) => {
  return (
    <div className="bg-white w-full sm:w-80 p-2 mt-1 bg-white rounded-lg hover:shadow-lg transition ease-in-out duration-300">
      <dt className="flex items-center bg-gray-700 rounded-lg">
        <div className="flex items-center justify-center m-1.5 h-9 w-12 rounded-md bg-light-violet text-white">
          {props.gradeLevelSubject.contentAreaName === "Math" && (
            <MathIcon title="Math" />
          )}
          {props.gradeLevelSubject.contentAreaName === "English" && (
            <EnglishIcon title="English language arts" />
          )}
          {props.gradeLevelSubject.contentAreaName === "ELA" && (
            <EnglishIcon title="English language arts" />
          )}
          {props.gradeLevelSubject.contentAreaName === "Science" && (
            <ScienceIcon title="Science" />
          )}
          {props.gradeLevelSubject.contentAreaName === "Social Studies" && (
            <SocialStudiesIcon title="Social studies" />
          )}
        </div>
        <p className="text-lg w-full text-left leading-6 font-normal text-white">
          {props.gradeLevelSubject.contentAreaName}
        </p>
      </dt>
      <div className="divide-y divide-gray-200">
        {props.gradeLevelSubject.subjects.length > 0 &&
          props.gradeLevelSubject.subjects.map((sub, i) => (
            <dd
              key={i}
              className="flex justify-start min-h-[3rem] py-1 items-center text-gray-900 mr-auto text-sm font-medium bg-white px-2 hover:bg-gray-200 shadow cursor-pointer"
              onClick={() => {
                props.onSubjectSelect(
                  sub.subjectId,
                  sub.name,
                  sub.isAdditional
                );
              }}
            >
              <label className="w-full">
                <span
                  className={`sm:pl-2 ${
                    sub.isAdditional
                      ? "text-gray-400 opacity-70"
                      : "text-gray-500"
                  } block leading-tight hover:text-primary-violet transition ease-in-out duration-300 cursor-pointer`}
                >
                  <span>{sub.name} </span>
                  {sub.subscriptionRequestType === "Trial" && (
                    <span className="bg-red-200 text-gray-700 rounded-xl px-2 my-auto">
                      Trial
                    </span>
                  )}
                </span>
              </label>
            </dd>
          ))}
        {props.gradeLevelSubject.subjects.length === 0 && (
          <>
            <dd>
              <label>
                <span className="text-sm pl-2 text-gray-400">
                  No subjects are available
                </span>
              </label>
            </dd>
            )
          </>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
  };
};

export default connect<{}, {}, {}>(
  mapStateToProps,
  {}
)(InstructionalResourceSubject);

export default class VideoMessageFilters {
    constructor(
        public userId: number,
        public schoolId: number,
        public title: string,
        public videoWatchCount: number,
        public videoWatchCountRelation: number,
        public videoStatus: number,
        public startDate: Date,
        public endDate: Date,
        public gradeIds: number[],
        public classIds: number[],
        public studentIds: number[],
        public teacherIds: number[],
    ) { }
}
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  getDomainsBySubjectId,
  getStandardsBySubjectAndDomain,
} from "../../../../api/cmsApi";
import { ISelectElement } from "../../../../model/interface/selectElement";
import { connect } from "react-redux";
import { getAllQuestionTypes } from "../../../../api/teacher/questionType";
import ReactSelect from "react-select";
import Loader from "../../../shared/loader";
import QuestionSet from "./questionSet";
import { QuestionIcon } from "../../../../assets/icons";
import { getUrlParams } from "../../../../utils/helper";
import constant from "../../../../utils/constant/routeConstant";
import { useHistory } from "react-router-dom";
import Permissions from "../../../../utils/permissions";
import AllowedTo from "../../../shared/rbac";
import AccessDenied from "../../../shared/accessDenied";
import Select from "react-select";
import { getSubjectDetail } from "../../../../api/teacher/assessment";

import Breadcrumb from "../../teacher/shared/breadcrumb";
import { IBreadcrumb } from "../../../../model/common/breadcrumb";
import RouteConstant from "../../../../utils/constant/routeConstant";
import { toast } from "react-toastify";
import {sanitizeNoteContent} from "../../../../utils/sanitize";

function BellRinger(props) {
  const history = useHistory();
  const idToEdit = props.match.params.id || "";
  const subjectId = getUrlParams()?.subjectId || undefined;
  const active = getUrlParams()?.active || undefined;
  const { t } = useTranslation();
  const [domains, setDomains] = useState<Array<any>>([]);
  const [questionTypes, setQuestionTypes] = useState<any[]>([]);
  const [selectedQuestionType, setSelectedQuestionType] =
    useState<ISelectElement>();
  const [standards, setStandards] = useState<Array<any>>([]);
  const [selectedStandard, setSelectedStandard] = useState<ISelectElement>();
  const [selectedStandardCode, setSelectedStandardCode] = useState("");
  const [loading, setLoading] = useState<boolean>(false);
  const [loadQuestions, setLoadQuestions] = useState<boolean>(
    idToEdit || active ? true : false
  );
  const [selectedDomain, setSelectedDomain] = useState({
    label: "",
    value: 0,
  });
  const [newBellRinger, setNewBellRinger] = useState<boolean>(
    idToEdit || active ? false : true
  );
  const [randomStandard, setRandomStandard] = useState<ISelectElement>();
  const [subjectDeails, setSubjectDeails] = useState<any>();
  const groupStyles = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    fontSize: 14,
  };

  useEffect(() => {
    if (!idToEdit) {
      if (subjectId) getDomains(subjectId);
      getQuestionTypes();
    }

    (async () => {
      if (!subjectId || parseInt(subjectId) === 0) {
        toast.error("Invalid Subject");
        return;
      }
      setLoading(true);
      const subDetails = await getSubjectDetail(subjectId);
      setLoading(false);
      if (subDetails?.data) setSubjectDeails(subDetails.data);
    })();
  }, []);

  const getQuestionTypes = async () => {
    setLoading(true);
    const response = await getAllQuestionTypes();
    const questionTypes = mapOptions(response.data);
    setQuestionTypes(questionTypes);
    setSelectedQuestionType({ label: "All Types", value: "0" });
    setLoading(false);
  };

  const getDomains = async (subjectId) => {
    setLoading(true);
    const response: any = await getDomainsBySubjectId(subjectId);
    const domains = response.data.data.map((values: any) => {
      return {
        label: values?.name,
        value: values?.domainId,
      };
    });
    setDomains(domains);
    setLoading(false);
  };

  const mapOptions: any = (data) => {
    const defaultGroupItems: any[] = [];
    defaultGroupItems.push({
      label: "All Types",
      value: "0",
    });
    const AllGroup = {
      label: "All",
      options: defaultGroupItems,
    };

    const options: any[] = [];
    options.push(AllGroup);
    data?.map((item) => {
      const groupItems: any[] = [];
      const group = {
        label: item.questionCategoryName,
        options: groupItems,
      };

      item?.subTypes?.map((option) => {
        groupItems.push({
          label: item.questionCategoryName + " - " + option.name,
          value: option.questionTypeId,
          data: option,
        });
        return null;
      });
      options.push(group);
      return null;
    });

    return options;
  };

  const findItemByValue: any = () => {
    if (!selectedQuestionType?.value) return null;

    return questionTypes?.reduce((prev, curr) => {
      return (
        prev ||
        (curr &&
          curr.options.find(
            (option) =>
              parseInt(option.value) === parseInt(selectedQuestionType?.value)
          ))
      );
    }, null);
  };

  const handleOnChange = (e) => {
    setSelectedQuestionType({ label: e.label, value: e.value });
    if (typeof props.onChange === "function") props.onChange(e);
  };

  const formatGroupLabel = (data: any) => {
    return (
      <div style={groupStyles}>
        <span>{data.label}</span>
      </div>
    );
  };

  const onDomainChange = async (e) => {
    if (!e.value) return;
    setLoading(true);
    setSelectedStandard(undefined);
    setSelectedDomain(domains.filter((ds) => ds.value === Number(e.value))[0]);
    const standards: any = await getStandardsBySubjectAndDomain(e.value);
    setLoading(false);
    if (standards?.data?.data) {
      const filtered = standards.data.data.filter(
        (standard) => standard?.isActive && standard?.isApproved
      );
      setStandards([{ name: "Any Standard", standardId: 0 }, ...filtered]);
    }
  };

  const onStandardSelect = (standardCode, standard) => {
    if (!standard.value) {
      const stands = standards.filter((ds) => ds.standardId !== 0);
      let randomStd = stands[(Math.random() * stands.length) | 0];
      setSelectedStandardCode(randomStd.code);
      randomStd = { label: randomStd.name, value: randomStd.standardId };
      setRandomStandard(randomStd);
    } else {
      setRandomStandard(undefined);
    }

    if (selectedStandard?.value !== standard.value) {
      setSelectedStandard(standard);
      if (standardCode) {
        setSelectedStandardCode(standardCode);
      }
    } else {
      setSelectedStandard(undefined);
    }
  };

  const startOver = () => {
    localStorage.removeItem("bellRinger");
    setNewBellRinger(true);
    setLoadQuestions(false);
    setStandards([]);
    setSelectedStandard(undefined);
    setLoadQuestions(false);
    setSelectedQuestionType(undefined);
    setSelectedDomain({
      label: "",
      value: 0,
    });

    if (idToEdit) {
      history.push(
        constant.TeacherRoutes.BellRinger + `?subjectId=${subjectId}`
      );
    }
  };
  const breadcrumbItems = () => {
    const items: Array<IBreadcrumb> = [
      { name: t("Breadcrumb.Home"), url: RouteConstant.ROUTE_DASHBOARD },
      {
        name: t("Breadcrumb.Subjects"),
        url: RouteConstant.TeacherRoutes.InstructionalResourceSubjects,
      },
      {
        name: t("Breadcrumb.InstructionalResources"),
        url: RouteConstant.TeacherRoutes.InstructionalResources,
        stateObject: { label: subjectDeails?.name, value: subjectId },
      },
      {
        name: t("Breadcrumb.BellRinger"),
        url: `/teacher/bellRinger?subjectId=${subjectId}`,
      },
      { name: t("Breadcrumb.EditBellRinger"), url: `` },
    ];

    return items;
  };

  return (
    <AllowedTo
      perform={[Permissions.bell_ringer_create, Permissions.bell_ringer_modify]}
      no={() => <AccessDenied />}
    >
      {loading && <Loader />}

      <div className="min-w-0 w-full">
        <div className="lg:flex lg:items-center px-4 py-3 lg:px-8 border-b">
          <Breadcrumb items={breadcrumbItems()} />
          <div className="lg:w-[55%] text-lg font-medium flex gap-2 h-auto sm:h-10 items-center">
            <h1 className="text-primary-violet font-medium flex justify-start mb-0 pb-0">
              {t("BellRinger.BellRinger")}
            </h1>
          </div>
        </div>

        <div className="px-2 sm:px-6 lg:px-8 py-6">
          <div className="w-full rounded-lg">
            <div className="mb-2 text-gray-700 font-medium">
              {subjectDeails?.name}
            </div>

            <div className="flex items-start justify-between space-x-6 w-full mb-6">
              <div
                className={`text-sm text-gray-700 font-light bg-[#00b1fb] bg-opacity-10 p-4 flex w-full rounded-md rounded-t-lg sm:rounded-lg overflow-hidden`}
              >
                <div className={`flex-shrink-0 current-fill text-[#00b1fb]`}>
                  <svg
                    className="h-5 w-5"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </div>

                <div className="pl-2">
                  <span className="font-semibold text-gray-700 pr-2 mb-1 inline-block">
                    {t("BellRinger.Instructions")}
                  </span>
                  <br />
                  <ul className=" list-disc	list-outside pl-3 space-y-1">
                    <li>{t("BellRinger.Instructions_Row1")}</li>
                    <li>{t("BellRinger.Instructions_Row2")}</li>
                  </ul>
                </div>
              </div>
            </div>

            {!loadQuestions && newBellRinger && (
              <>
                <div className="w-2/3  grid grid-cols-12 gap-8">
                  <div className="col-span-6 2xl:col-span-5">
                    <label className="block mb-1 text-sm text-gray-500">
                      Domain
                    </label>

                    <Select
                      placeholder={""}
                      value={selectedDomain}
                      options={domains}
                      onChange={onDomainChange}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary: "#008fbb",
                        },
                      })}
                    />
                  </div>

                  <div className="hidden col-span-6 2xl:col-span-5">
                    <label className="block mb-1 text-sm text-gray-500">
                      Question Type
                    </label>

                    <ReactSelect
                      className="w-full"
                      options={questionTypes}
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                      placeholder={""}
                      onChange={handleOnChange}
                      value={findItemByValue()}
                      formatGroupLabel={formatGroupLabel}
                      isDisabled={
                        props.isEdit
                          ? true
                          : props.languages === "ES"
                          ? true
                          : false
                      }
                      isSearchable={true}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary25: "#B3B3B3",
                          primary: "#008fbb",
                        },
                      })}
                      styles={{
                        control: (base, state) => ({
                          ...base,
                          background: "white",
                          borderWidth: "1px",
                          "&:hover": {
                            borderColor:
                              state.isFocused && state.isFocused
                                ? "#042757"
                                : "",
                          },
                        }),
                        singleValue: (base) => ({
                          ...base,
                          color: "#374051",
                        }),
                        menu: (base) => ({
                          ...base,
                          color: "#262626",
                          marginTop: 0,
                        }),
                        input: (base) => ({
                          ...base,
                          background: "white",
                        }),
                      }}
                    />
                  </div>
                </div>

                <div className="mt-5 shadow overflow-x-auto">
                  <table className="table w-full ">
                    <thead className="mb-5 border border-light-violet">
                      <tr className="bg-light-violet  text-white py-3 px-3 overflow-hidden">
                        <td></td>
                        <th className="text-left p-3 font-normal w-1/2">
                          Standard
                        </th>
                        <th className="text-left p-3   font-normal"></th>
                      </tr>
                    </thead>

                    <tbody>
                      {standards?.map((standard, i) => {
                        return (
                          <tr
                            key={i}
                            onClick={() =>
                              onStandardSelect(standard.code, {
                                label: standard.name,
                                value: standard.standardId,
                              })
                            }
                            className={`${
                              selectedStandard &&
                              selectedStandard?.value === standard.standardId
                                ? "bg-light-violet/20 border border-light-violet"
                                : ""
                            } ${
                              i % 2 === 0 ? "bg-white" : "bg-light-violet/10"
                            } cursor-pointer hover:bg-light-violet/20 transition ease-in-out duration-300`}
                          >
                            <td className="p-3 w-4">
                              <input
                                id="styled-checkbox-2"
                                type="radio"
                                className="checkbox"
                                checked={
                                  selectedStandard &&
                                  selectedStandard?.value ===
                                    standard.standardId
                                }
                                onChange={() => {}}
                              />
                            </td>

                            <td className="text-xs mt-5  p-3">
                              {i !== 0 && `${standard.code} `}
                              {standard.name}
                              <br/>
                              <div className="mt-1 italic bg-opacity-20"
                                   dangerouslySetInnerHTML={{__html: sanitizeNoteContent(standard.description)}}></div>
                            </td>
                            <td className="text-sm mt-5  p-3"></td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  {standards.length === 0 && (
                    <div className="p-3">No records found</div>
                  )}
                </div>

                <div
                  className={`${
                    standards.length === 0 || !selectedStandard
                      ? "cursor-not-allowed"
                      : ""
                  } mt-5 flex justify-end`}
                >
                  <a
                    href="#!"
                    onClick={() => setLoadQuestions(true)}
                    className={`${
                      standards.length === 0 || !selectedStandard
                        ? "pointer-events-none"
                        : ""
                    } bg-transparent group border my-auto border-primary-violet shadow-sm py-2 px-4 flex inline-end justify-end text-sm font-medium text-primary-violet hover:text-white hover:bg-light-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3 transition ease-in-out duration-500 whitespace-nowrap`}
                  >
                    <QuestionIcon className="w-5 mr-1 fill-current text-primary-violet group-hover:text-white transition ease-in-out duration-500 " />
                    Load Questions
                  </a>
                </div>
              </>
            )}

            {loadQuestions && (
              <QuestionSet
                startOver={startOver}
                {...props}
                {...{
                  selectedQuestionType,
                  selectedStandard:
                    selectedStandard && Number(selectedStandard.value) === 0
                      ? randomStandard
                      : selectedStandard,
                  selectedDomain,
                  subjectId,
                  subjectName: subjectDeails?.name,
                  selectedStandardCode,
                }}
              />
            )}
          </div>
        </div>
      </div>
    </AllowedTo>
  );
}

export default connect((state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
})(BellRinger);

import { Fragment, useEffect } from "react";
import { connect } from "react-redux";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
} from "@windmill/react-ui";
import { CloseIcon } from "../../../assets/icons/index";
import DefaultUserIcon from "../../../assets/images/userIcon.png";
import { updateProfile } from "../../../api/profileApi";
import Constant from "../../../utils/constant/constant";
import UserProfileModal from "../../../model/users/userProfile";
import { Configuration } from "../../../environment/setup";
import DefaultAvatar from "../../../mockData/avatar.json";
import constant from "../../../utils/constant/constant";
import { getNameLabel } from "../../../utils/helper";
import { Alert } from "@windmill/react-ui";
import Profile from "./profile";
import AccountInfo from "./accountInfo";
import SchoolInfo from "./schoolInfo";
import DistrictInfo from "./districtInfo";
import Settings from "./settings";
import { fetchProfile } from "../../../redux/actions/userActions";
import * as userContextAction from "../../../redux/actions/userContextAction";
import { toast } from "react-toastify";
import classNames from "classnames";
import { getProfileUpgradePermission } from "../../shared/permissionHelper";
import UpGradeMessagePopup from "../../shared/upGradeMessagePopup";

const ProfileContainer = (props: any) => {
  type TabType =
    | "PROFILE"
    | "ACCOUNT"
    | "SCHOOLINFORMATION"
    | "DISTRICTINFORMATION"
    | "SETTING";
  const commonCss =
    "px-2 sm:px-4 text-center border-t-2 text-sm cursor-pointer font-medium";
  const activeCss =
    "border-secondary-teal bg-white text-primary-violet hover:text-dark-violet py-3 tab-box-shadow tab-box-shadow  " +
    commonCss;
  const inactiveCss =
    "text-primary-violet text-opacity-60 bg-primary-violet/10 py-[0.6rem] mt-auto transition-all hover:py-3 hover:text-opacity-100 hover:text-primary-violet border-[#dee9f1] " +
    commonCss;
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [isRemoveAllClassOpen, setIsRemoveAllClassOpen] = useState(false);
  const [firstName] = useState(props.profile.firstName);
  const [lastName] = useState(props.profile.lastName);
  const [profileFromList, setProfileFromList] = useState("");
  const [profileFromLocal, setProfileFromLocal] = useState("");
  const [profileFromCamera, setProfileFromCamera] = useState("");
  const [filenmae, setFileName] = useState("");
  const [showCamera, setShowCamera] = useState(false);
  const [defaultImage, setDefaultImage] = useState(
    props.profile === null || props.profile.profileImage === null
      ? DefaultUserIcon
      : props.profile.profileImage
  );
  const [tabType, setTabType] = useState("PROFILE");
  const [tabProfileClass, setTabProfileClass] = useState(activeCss);
  const [tabAccountClass, setTabAccountClass] = useState(inactiveCss);
  const [tabSchoolInformationClass, setTabSchoolInformationClass] =
    useState(inactiveCss);
  const [tabDistrictInformationClass, setTabDistrictInformationClass] =
    useState(inactiveCss);
  const [tabSettingClass, setTabSettingClass] = useState(inactiveCss);
  const tabProfileAllowRole = [
    constant.UserRoleId.SchoolAdmin,
    constant.UserRoleId.SchoolTeacher,
    constant.UserRoleId.District,
    constant.UserRoleId.FreeTeacher,
  ];
  const tabAccountAllowRole = [
    constant.UserRoleId.SchoolAdmin,
    constant.UserRoleId.District,
    constant.UserRoleId.FreeTeacher,
    constant.UserRoleId.SchoolTeacher,
  ];
  const tabSchoolInformationAllowRole = [
    constant.UserRoleId.SchoolAdmin,
    constant.UserRoleId.FreeTeacher,
  ];
  const tabDistrictInformationAllowRole = [constant.UserRoleId.District];
  const tabSettingAllowRole = [
    constant.UserRoleId.SchoolTeacher,
    constant.UserRoleId.SchoolAdmin,
    constant.UserRoleId.FreeTeacher,
  ];
  const [successMessage, setSuccessMessage] = useState("");
  const location = useLocation();
  const parameters = location.state as any;
  const [upgrade, setUpgrade] = useState(false);
  const [showUpgradePopup, setShowUpgradePopup] = useState<boolean>(false);

  useEffect(() => {
    checkUpgradeRequired();
  }, []);

  const checkUpgradeRequired = async () => {
    if (getProfileUpgradePermission(props.userContext?.roleId ?? 0)) {
      setUpgrade(true);
    } else {
      setUpgrade(false);
    }
  };

  useEffect(() => {
    tabClickHandle(
      parameters?.defaultTab
        ? "ACCOUNT"
        : parameters?.tabSelected
        ? parameters?.tabSelected
        : "PROFILE"
    );
  }, []);

  function openModal() {
    setIsModalOpen(true);
  }

  function closeModal() {
    setIsModalOpen(false);
  }

  function bind(data) {
    props.setProfile(
      props.userContext.userId,
      props.userContext.roleId,
      props.userContext.schoolId,
      props.userContext.districtId
    );
    setIsAlertOpen(true);
    setSuccessMessage("Successfully Saved.");
    setTimeout(() => {
      setIsAlertOpen(false);
      setSuccessMessage("");
    }, 1000);
  }

  function handleRemoveAllClass() {
    var userProfileModal = new UserProfileModal(
      Constant.Profile.REMOVESCHOOLCLASS,
      props.profile.userId,
      "",
      "",
      "",
      "",
      "",
      "",
      props.userContext.schoolId,
      props.userContext.schoolAccountId,
      "",
      ""
    );
    updateProfile(userProfileModal)?.then((r) => {
      bind(r);
    });

    setIsRemoveAllClassOpen(false);
  }

  function hadleAvatarList(e, imagePath) {
    setProfileFromLocal("");
    setProfileFromCamera("");
    setProfileFromList(e.target.currentSrc);
    setDefaultImage(e.target.currentSrc);
    setShowCamera(false);
    DefaultAvatar.forEach((t) => {
      if (t.ImagePath === imagePath) {
        t.CustomClass = "border border-primary-green";
      } else {
        t.CustomClass = "";
      }
    });
  }

  function hadlProfileFromLocalt(e) {
    setFileName(e.target.value);
    setProfileFromList("");
    setProfileFromCamera("");
    var promis = new Promise((resolve) => {
      let reader = new FileReader();

      reader.readAsDataURL(e.target.files[0]);

      reader.onload = () => {
        var baseURL = reader.result !== null ? reader.result : "";
        resolve(baseURL);
      };
    });
    promis.then((result) => {
      let outputResult = "" + result;
      setProfileFromLocal(outputResult);
      setProfileFromList("");
      setDefaultImage(outputResult);
    });
  }

  function uploadProfile() {
    setLoading(true);
    var userProfileModal: any = {};
    if (profileFromList) {
      userProfileModal = new UserProfileModal(
        Constant.Profile.UPLOADIMAGE,
        props.profile.userId,
        "",
        "",
        "",
        "",
        "",
        "",
        props.userContext.schoolId,
        props.userContext.schoolAccountId,
        "",
        "",
        profileFromList,
        3,
        true
      );
      updateProfile(userProfileModal)?.then((r) => {
        setLoading(false);
        toast.success("Profile avatar updated successfully");
        bind(r);
      });
    } else if (profileFromLocal) {
      const base64Image = profileFromLocal.replace(
        "data:image/png;base64,",
        ""
      );
      userProfileModal = new UserProfileModal(
        Constant.Profile.UPLOADIMAGE,
        props.profile.userId,
        "",
        "",
        "",
        "",
        "",
        "",
        props.userContext.schoolId,
        props.userContext.schoolAccountId,
        "",
        "",
        base64Image,
        2,
        true
      );
      updateProfile(userProfileModal)?.then((r) => {
        setLoading(false);
        toast.success("Profile avatar updated successfully");
        bind(r);
      });
    } else if (profileFromCamera) {
      const base64Image = profileFromCamera.replace(
        "data:image/png;base64,",
        ""
      );
      userProfileModal = new UserProfileModal(
        Constant.Profile.UPLOADIMAGE,
        props.profile.userId,
        "",
        "",
        "",
        "",
        "",
        "",
        props.userContext.schoolId,
        props.userContext.schoolAccountId,
        "",
        "",
        base64Image,
        1,
        true
      );
      updateProfile(userProfileModal)?.then((r) => {
        setLoading(false);
        toast.success("Profile avatar updated successfully");
        bind(r);
      });
    }
  }

  function tabClickHandle(type: TabType) {
    setTabType(type);
    setTabProfileClass(inactiveCss);
    setTabSchoolInformationClass(inactiveCss);
    setTabDistrictInformationClass(inactiveCss);
    setTabSettingClass(inactiveCss);
    setTabAccountClass(inactiveCss);
    switch (type) {
      case "PROFILE":
        setTabProfileClass(activeCss);
        break;
      case "ACCOUNT":
        setTabAccountClass(activeCss);
        break;
      case "SCHOOLINFORMATION":
        setTabSchoolInformationClass(activeCss);
        break;
      case "DISTRICTINFORMATION":
        setTabDistrictInformationClass(activeCss);
        break;
      case "SETTING":
        setTabSettingClass(activeCss);
        break;
    }
  }

  return (
    <Fragment>
      {isAlertOpen && (
        <Alert
          type="success"
          onClose={() => {
            setIsAlertOpen(false);
          }}
        >
          Data save successfully
        </Alert>
      )}
      {showUpgradePopup && (
        <UpGradeMessagePopup
          togglePopup={setShowUpgradePopup}
          roleId={props.userContext?.roleId ?? 0}
          message={constant.UPGRADE_MESSAGE_SCHOOL_ACCOUNT}
        />
      )}
      <Modal
        isOpen={isRemoveAllClassOpen}
        onClose={() => setIsRemoveAllClassOpen(false)}
      >
        <ModalHeader>Warning!!!</ModalHeader>

        <ModalBody>
          Are you sure?!?! This will erase all the classes for all teachers in
          your school. This cannot be undone.
        </ModalBody>

        <ModalFooter>
          <Button
            className="w-full sm:w-auto"
            layout="outline"
            onClick={() => {
              setIsRemoveAllClassOpen(false);
            }}
          >
            Cancel
          </Button>
          <Button
            className="w-full sm:w-auto"
            onClick={() => {
              handleRemoveAllClass();
            }}
          >
            Remove All Classess
          </Button>
        </ModalFooter>
      </Modal>

      <div className="min-w-0 w-full">
        <article className="bg-gray-100 rounded-lg mt-4 pb-8 sm:px-6 lg:px-8 sm:mx-6 lg:mx-8">
          <div>
            <div className="flex flex-row items-center max-w-5xl">
              <div className="w-full flex items-center sm:space-x-5 pt-8">
                <div className="sm:flex-1 sm:min-w-0 sm:flex sm:items-center sm:justify-end sm:space-x-6 sm:pb-1">
                  <div className="sm:hidden xxl:block min-w-0 flex-1">
                    <h1 className="text-2xl text-gray-700 truncate w-48">
                      {getNameLabel(lastName, firstName)}
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col lg:flex-row w-full lg:space-x-4 mt-2">
            <div className="flex mr-auto flex-col relative mb-auto">
              <div className="relative" title="Avatar">
                <button
                  title="Edit avatar"
                  onClick={openModal}
                  className="w-10 h-10 flex items-center justify-center bg-primary-green absolute bottom-0 -right-1 rounded-full ring-2 ring-gray-200 ring-offset-2 cursor-pointer  focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 hover:opacity-90 "
                >
                  <span className="">
                    <svg
                      id="Capa_1"
                      className="h-3 w-3 fill-current text-white"
                      data-name="Capa 1"
                      viewBox="0 0 383.95 383.95"
                    >
                      <polygon points="0 303.95 0 383.95 80 383.95 316.05 147.89 236.05 67.89 0 303.95" />
                      <path d="M377.71,56.05,327.89,6.24a21.37,21.37,0,0,0-30.18,0l-39,39,80,80,39-39A21.38,21.38,0,0,0,377.71,56.05Z" />
                    </svg>
                  </span>

                  <input
                    id="file-upload"
                    name="file-upload"
                    type="file"
                    className="sr-only"
                  />
                </button>

                <span className="bg-splitColor inline-block">
                  <img
                    className="w-56 rounded-md ring-4 ring-white"
                    srcSet={defaultImage}
                    alt="avatar"
                  />
                </span>
              </div>
              <p className="rounded-md bg-[#0ea5e9] text-white text-[10px] shadow-sm py-2 px-3 uppercase mt-2">
                {parseInt(props.userContext.roleId) ===
                constant.UserRoleId.SchoolAdmin
                  ? "School Administrator"
                  : parseInt(props.userContext.roleId) ===
                    constant.UserRoleId.District
                  ? "District Administrator"
                  : "Teacher"}
              </p>
            </div>
            <div className="bg-white rounded-md shadow w-full mt-2 lg:mt-0">
              <div className="mt-6 sm:mt-2 xxl:mt-5">
                <div className="border-b border-gray-200">
                  <div className="max-w-5xl px-2 lg:px-8">
                    <nav className="-mb-px flex space-x-2" aria-label="Tabs">
                      {tabProfileAllowRole.includes(
                        parseInt(props.userContext.roleId)
                      ) === true &&
                        props.userContext?.impersonatedUser === null && (
                          <span
                            className={`${tabProfileClass}`}
                            onClick={() => tabClickHandle("PROFILE")}
                          >
                            Profile
                          </span>
                        )}

                      {tabDistrictInformationAllowRole.includes(
                        parseInt(props.userContext.roleId)
                      ) === true && (
                        <span
                          className={`${tabDistrictInformationClass}`}
                          onClick={() => tabClickHandle("DISTRICTINFORMATION")}
                        >
                          District Info
                        </span>
                      )}

                      {tabAccountAllowRole.includes(
                        parseInt(props.userContext.roleId)
                      ) === true && (
                        <span
                          className={`${tabAccountClass}`}
                          onClick={() => tabClickHandle("ACCOUNT")}
                        >
                          Account
                        </span>
                      )}

                      {tabSchoolInformationAllowRole.includes(
                        parseInt(props.userContext.roleId)
                      ) === true && (
                        <span
                          className={`${tabSchoolInformationClass}`}
                          onClick={() => tabClickHandle("SCHOOLINFORMATION")}
                        >
                          School Information
                        </span>
                      )}

                      {tabSettingAllowRole.includes(
                        parseInt(props.userContext.roleId)
                      ) === true &&
                        props.userContext?.impersonatedUser === null && (
                          <span
                            className={
                              (upgrade ? "opacity-50 " : "") +
                              `${tabSettingClass}`
                            }
                            onClick={() => {
                              if (upgrade) {
                                setShowUpgradePopup(true);
                              } else {
                                tabClickHandle("SETTING");
                              }
                            }}
                          >
                            Settings
                          </span>
                        )}
                    </nav>
                  </div>
                </div>
              </div>

              <div className="mt-6 max-w-5xl px-4 sm:px-6 lg:px-8 pb-8">
                {successMessage && (
                  <div className="px-6 pt-5 text-primary-green">
                    <p>{successMessage}</p>
                  </div>
                )}

                {tabType === "PROFILE" && <Profile />}

                {tabType === "ACCOUNT" && <AccountInfo />}

                {tabType === "SCHOOLINFORMATION" && <SchoolInfo />}

                {tabType === "DISTRICTINFORMATION" && <DistrictInfo />}

                {tabType === "SETTING" && <Settings />}
              </div>
            </div>
          </div>
        </article>
      </div>

      <div
        className={classNames(
          isModalOpen === true ? "display-block" : "hidden",
          "fixed inset-0 z-40 flex items-center bg-black bg-opacity-50 sm:items-center sm:justify-center"
        )}
      >
        <div className="w-full flex flex-col justify-between overflow-hidden h-auto max-h-80percent bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:max-w-5xl">
          <header>
            <div className="px-4 py-4 bg-gray-50">
              <div className="flex items-start justify-between space-x-3">
                <div className="space-y-1">
                  <div className="text-lg font-medium text-gray-900">
                    {getNameLabel(lastName, firstName)}
                  </div>
                </div>

                <div className="h-7 flex items-center">
                  <button
                    type="button"
                    className="inline-flex items-center justify-center w-6 h-6 text-gray-400 transition-colors duration-150 rounded dark:hover:text-gray-200 hover: hover:text-gray-700"
                    onClick={() => closeModal()}
                  >
                    <CloseIcon />
                  </button>
                </div>
              </div>
            </div>
          </header>

          <div className="w-full relative overflow-auto px-4 py-3 ">
            <div className="md:grid md:grid-cols-10 md:gap-0 py-4">
              <div className="md:col-span-3 pt-3">
                <div className="relative flex justify-center">
                  {!showCamera && (
                    <span className="bg-splitColor inline-block rounded-md shadow-xl">
                      <img
                        className="w-56 rounded-lg mx-auto"
                        src={defaultImage}
                        alt="avatar"
                      />
                    </span>
                  )}
                </div>

                <div className="flex items-center justify-center pt-5">
                  <label className="w-3/4" htmlFor="file-upload">
                    <input
                      id="file-upload"
                      accept="image/*"
                      type="file"
                      value={filenmae}
                      onChange={(e) => hadlProfileFromLocalt(e)}
                    />
                  </label>
                </div>
              </div>

              <div className="md:col-span-7 border-l pl-4">
                <ul className="flex flex-wrap">
                  {DefaultAvatar.map((obj) => {
                    return (
                      <Fragment key={obj.ImagePath}>
                        <li
                          value={obj.ImagePath}
                          onClick={(e) => hadleAvatarList(e, obj.ImagePath)}
                          className={`${obj.CustomClass} cursor-pointer inline-block bg-splitColor px-1 w-20 max-w-xs overflow-hidden rounded-lg shadow-md bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out m-2 hover:border hover:border-gray-200`}
                        >
                          <img
                            className="pt-2"
                            src={`${Configuration.S3bucketImagePath}${obj.ImagePath}`}
                            alt="default avatar"
                          />
                        </li>
                      </Fragment>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>

          <footer className="flex items-center justify-end px-4 py-4 sm:flex-row bg-gray-50 dark:bg-gray-800 mt-4">
            <div className="space-x-3 flex justify-end">
              <Button
                disabled={loading ? true : false}
                onClickCapture={() => uploadProfile()}
                className="bg-primary-violet border border-transparent shadow-sm py-2 px-4 justify-end text-sm font-medium text-white hover:bg-dark-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 whitespace-nowrap ml-auto"
              >
                {loading && (
                  <svg
                    className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      stroke-width="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                )}
                Update
              </Button>
            </div>
          </footer>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state: any) => {
  return {
    profile: state.profile,
    userContext: state.userContext,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setUserContext: (payload: userContextAction.UserContextState) => {
      dispatch(userContextAction.setUserContext(payload));
    },
    setProfile: (
      userId: number,
      roleId: number,
      schoolId: number,
      districtId: number
    ) => {
      dispatch(fetchProfile(userId, roleId, schoolId, districtId));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileContainer);

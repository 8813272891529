import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import constant from "../../../../../utils/constant/constant";
import { useHistory } from "react-router-dom";
import RouteConstant from "../../../../../utils/constant/routeConstant";
import { PlayIcon, QuestionMarkCircleIcon } from "@heroicons/react/solid";
import DotRank from "../../shared/dotRank";
import { InfoIcon } from "../../../../../assets/icons";
import { paramEncode } from "../../../../../utils/urlHelper";
import Profile from "../../../../../model/users/profile";
import { UserContextState } from "../../../../../redux/actions/userContextAction";
import { IStudyPlanFocusAreasStandard } from "../../../../../model/interface/studyPlanFocusAreas";
import StudyPlanVideoTestPopup from "../../shared/popups/studyPlanVideoTestsPopup";
import { IStudentVideoAssignment } from "../../../../../model/interface/studentVideoAssignment";
import { getVideoAssignment } from "../../../../../api/student/studentVideoAssignmentApi";

interface IStudyPlaFocusAreasTabProps {
  subjectId: number;
  subjectName: string;
  userContext: UserContextState;
  profile: Profile;
  setLoading(value: boolean): void;
  focusAreas: Array<IStudyPlanFocusAreasStandard>;
  themeName: "ELEMENTARY" | "MIDDLESCHOOL";
}

function StudyPlanFocusAreasTab(props: IStudyPlaFocusAreasTabProps) {
  const { focusAreas, themeName, subjectName, setLoading } = props;
  const history = useHistory();
  const { t } = useTranslation();
  const [openVideoTestModal, setOpenVideoTestModal] = useState<boolean>(false);
  const [selectedStandardName, setSelectedStandardName] = useState<string>("");
  const [standardVideos, setStandardVideos] = useState<
    Array<IStudentVideoAssignment>
  >([]);
  const [showDescriptionInfo, setShowDescriptionInfo] =
    useState<boolean>(false);
  const [showDotRankInfo, setShowDotRankInfo] = useState<boolean>(false);
  const [mouseOverID, setMouseOverID] = useState<number>();

  function startStudyPlan(standard: IStudyPlanFocusAreasStandard) {
    history.push(
      RouteConstant.MiddleSchool.StudyPlan.replace(
        ":standardId",
        encodeURI(btoa(standard.standardId.toString()))
      )
        .replace(":standardName", encodeURI(paramEncode(standard.standardName)))
        .replace(":gameId", encodeURI(btoa("0")))
        .replace(":rocketId", encodeURI(btoa("0")))
        .replace(":blasterId", encodeURI(btoa("0")))
        .replace(":subjectId", encodeURI(btoa(props.subjectId.toString())))
        .replace(":blasterType", encodeURI(btoa("0")))
    );
  }

  function startVideoPractice(video: IStudentVideoAssignment) {
    history.push({
      pathname: RouteConstant.MiddleSchool.VideoQuestionPractice.replace(
        ":standardName",
        encodeURI(btoa(selectedStandardName))
      )
        .replace(":subjectId", encodeURI(btoa(props.subjectId.toString())))
        .replace(":standardId", encodeURI(btoa(video.standardId.toString())))
        .replace(":videoId", encodeURI(btoa(video.videoId.toString()))),
      state: {
        videoTitle:
          props.profile?.plLanguageId === constant.Languages.Spanish
            ? video.spanishVideoTitle ?? video.videoTitle
            : video.videoTitle,
      },
    });
  }

  function openVideoTestPopup(standard: IStudyPlanFocusAreasStandard) {
    setLoading(true);
    setSelectedStandardName(standard.standardName);
    getVideoAssignment(
      props.userContext?.userId ?? 0,
      props.subjectId,
      standard.standardId
    )
      .then((d) => {
        setStandardVideos(d.data);
      })
      .finally(() => {
        setLoading(false);
        setOpenVideoTestModal(true);
      });
  }

  return (
    <Fragment>
      <>
        {openVideoTestModal && (
          <StudyPlanVideoTestPopup
            standardVideos={standardVideos}
            standardName={selectedStandardName}
            themeName={themeName}
            userContext={props.userContext}
            profile={props.profile}
            onClose={() => {
              setOpenVideoTestModal(false);
              setSelectedStandardName("");
              setStandardVideos([]);
            }}
            startVideoPractice={startVideoPractice}
          />
        )}
        <div className="px-4 py-2 lg:px-8 flex justify-between items-end">
          <span className="text-xl text-white inline-block">{subjectName}</span>
        </div>
        <div className="px-4 pb-4 pt-2 lg:px-8">
          <div className="overflow-x-auto rounded-lg md:overflow-visible w-full border-4 border-gray-700 border-opacity-20 shadow-xl">
            <table className="min-w-full divide-y divide-gray-200 roundedTable">
              <thead className="bg-gray-200 overflow-hidden">
                <tr>
                  <th
                    scope="col"
                    className="rounded-tl-lg bg-gray-100 min-w-74 pl-7 pr-3 py-3 text-left text-sm font-normal text-dark-teal tracking-wider"
                  >
                    {t("StudyPlan.Name")}
                  </th>
                  <th
                    scope="col"
                    className="bg-gray-200 px-3 py-3 text-center font-normal text-dark-teal tracking-wider"
                  >
                    <div className="text-sm">
                      {t("StudyPlan.OverallDotRank")}
                    </div>
                    <div className="text-[12px] normal-case">
                      {t("StudyPlan.DotRankSubHeader")}
                    </div>
                  </th>
                  <th
                    scope="col"
                    className="bg-gray-100 px-3 py-3 text-center text-sm font-normal text-dark-teal tracking-wider"
                  >
                    {t("StudyPlan.QuestionsAnswered")}
                  </th>
                  <th
                    scope="col"
                    className="bg-gray-200 px-3 py-3 text-center text-sm font-normal text-dark-teal tracking-wider"
                  >
                    {t("StudyPlan.Videos")}
                  </th>
                  <th
                    scope="col"
                    className="rounded-tr-lg bg-gray-100 px-3 py-3 text-center text-sm font-normal text-dark-teal tracking-wider"
                  >
                    {t("StudyPlan.Questions")}
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white">
                {focusAreas.map((standard, index) => (
                  <tr
                    key={index}
                    className={
                      index % 2 === 0
                        ? "bg-white border-b-2"
                        : "bg-gray-100 border-b-2"
                    }
                  >
                    <td
                      className="px-3 py-4 border-r-2 border-gray-100"
                      onMouseOver={() => {
                        setShowDescriptionInfo(true);
                        setMouseOverID(standard.standardId);
                      }}
                      onMouseOut={() => {
                        setShowDescriptionInfo(false);
                        setMouseOverID(0);
                      }}
                    >
                      <div className="relative mx-4">
                        [{standard.standardCode}] {standard.standardName}
                      </div>
                      <div className="flex gap-1 relative">
                        <span className="mt-1">
                          <div
                            className={`w-96 absolute bg-blue-50 top-0 left-0  transition-all shadow-lg border border-blue-200 p-4 rounded-lg z-50
                          ${
                            showDescriptionInfo &&
                            mouseOverID === standard.standardId
                              ? "visible"
                              : "hidden"
                          }
                          `}
                            onMouseOver={() => {
                              setShowDescriptionInfo(true);
                              setMouseOverID(standard.standardId);
                            }}
                            onMouseOut={() => {
                              setShowDescriptionInfo(false);
                              setMouseOverID(0);
                            }}
                          >
                            <div className="flex gap-1 flex-col bg-white/50 p-1">
                              <div className="flex">{standard.description}</div>
                            </div>
                          </div>
                        </span>
                      </div>
                    </td>
                    <td className="px-3 py-2 w-60 max-w-60 border-r-2 border-gray-100">
                      <div className="flex justify-center">
                        <DotRank
                          dotRankId={standard.dotRankId}
                          score={standard.overallScore}
                        />
                        <InfoIcon
                          className="w-4 h-4"
                          onMouseOver={() => {
                            setShowDotRankInfo(true);
                            setMouseOverID(standard.standardId);
                          }}
                          onMouseOut={() => {
                            setShowDotRankInfo(false);
                            setMouseOverID(0);
                          }}
                        />
                        <div className="flex gap-1 relative">
                          <span className="mt-1">
                            <div
                              className={`w-96 absolute bg-blue-50 top-10 left-0  transition-all shadow-lg border border-blue-200 p-4 rounded-lg z-20
                          ${
                            showDotRankInfo &&
                            mouseOverID === standard.standardId
                              ? "visible"
                              : "hidden"
                          }
                          `}
                              onMouseOver={() => {
                                setShowDotRankInfo(true);
                                setMouseOverID(standard.standardId);
                              }}
                              onMouseOut={() => {
                                setShowDotRankInfo(false);
                                setMouseOverID(0);
                              }}
                            >
                              <div className="flex gap-1 flex-col bg-white/50 p-1">
                                <div>
                                  <p>{t("StudyPlan.DotRankToolTip")}</p>
                                </div>
                                <div className="flex gap-1 flex-col bg-white/50 p-1">
                                  <p className="flex items-center">
                                    <span className="w-3.5 h-3.5 rounded-full bg-[#9e9e9e] border border-black inline-block mr-1"></span>
                                    {t("StudyPlan.GrayDotRankToolTip")}
                                  </p>
                                  <p className="flex items-center">
                                    <span className="w-3.5 h-3.5 rounded-full bg-[#e53f71] border border-black inline-block mr-1"></span>{" "}
                                    {t("StudyPlan.PinkDotRankToolTip")}
                                  </p>
                                  <p className="flex items-center">
                                    <span className="w-3.5 h-3.5 rounded-full bg-[#f4d221] border border-black inline-block mr-1"></span>
                                    {t("StudyPlan.YellowDotRankToolTip")}
                                  </p>
                                  <p className="flex items-center">
                                    <span className="w-3.5 h-3.5 rounded-full bg-[#a3b938] border border-black inline-block mr-1"></span>
                                    {t("StudyPlan.GreenDotRankToolTip")}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </span>
                        </div>
                      </div>
                    </td>
                    <td
                      className="px-3 py-2 border-r-2 border-gray-100 min-w-40"
                      title={t("StudyPlan.QuestionsAnswered")}
                    >
                      <div className="flex items-center justify-center">
                        {standard.totalQuestionsAnswered}
                      </div>
                    </td>

                    <td
                      className="px-3 py-2 border-r-2 border-gray-100 w-56"
                      title={t("StudyPlan.Learn")}
                    >
                      {standard.totalVideoQuestions > 0 && (
                        <div className="relative w-32 mx-auto">
                          {standard.inProgressVideoQuestions > 0 && (
                            <label className="absolute -top-2 right-0 w-4 h-4 flex items-center justify-center text-gray-700 font-semibold font-lg inline-block cursor-pointer bg-yellow-400 border border-yellow-500 hover:opacity-90 rounded-full"></label>
                          )}
                          <button
                            className="flex items-center mx-auto justify-center bg-white bg-opacity-50 w-32 border rounded-lg p-1 hover:border-gray-500 space-x-0.5"
                            onClick={() => {
                              openVideoTestPopup(standard);
                            }}
                          >
                            <PlayIcon className="w-5 cursor-pointer text-green-400" />
                            <span className="pl-2">
                              {" "}
                              {standard.completedVideoQuestions}/
                              {standard.totalVideoQuestions}
                            </span>
                          </button>
                        </div>
                      )}
                    </td>
                    <td className="px-3 py-2 border-r-2 border-gray-100 w-56">
                      <div className="relative w-32 mx-auto">
                        {standard.assignmentStatusId ===
                          constant.AssignmentStatusId.INPROGRESS && (
                          <label className="absolute -top-2 right-0 w-4 h-4 flex items-center justify-center text-gray-700 font-semibold font-lg inline-block cursor-pointer bg-yellow-400 border border-yellow-500 hover:opacity-90 rounded-full"></label>
                        )}
                        <button
                          className="flex items-center mx-auto justify-center bg-white bg-opacity-50 w-32 border rounded-lg p-1 hover:border-gray-500 space-x-0.5"
                          onClick={() => {
                            startStudyPlan(standard);
                          }}
                        >
                          <QuestionMarkCircleIcon className="w-5 cursor-pointer text-gray-500" />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </>
    </Fragment>
  );
}

export default StudyPlanFocusAreasTab;

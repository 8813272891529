import classNames from "classnames";
import React, { Fragment, useEffect, useState } from "react";
import { getLinkedSchools } from "../../api/student/studentApi";
import Constant from "../../utils/constant/constant";
import RouteConstant from "../../utils/constant/routeConstant";
import { LogoPLIcon, SchoolIcon } from "../../assets/icons/index";
import { useHistory } from "react-router-dom";
import { login } from "../../api/login.service";
import * as userContextAction from "../../redux/actions/userContextAction";
import { getThemeByGrade } from "../../utils/helper";
import { fetchProfile } from "../../redux/actions/userActions";
import { connect } from "react-redux";
import Loader from "../../../src/component/shared/loader";
import { jwtToken } from "../shared/tokenHelper";

const SchoolSelection = (props: any) => {
  const loginType = props.match.params.loginType;
  const [schools, setSchools] = useState<Array<any>>();
  const [selectedSchoolId, setSelectedSchoolId] = useState(0);
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getLinkedSchool();
    const user: any = jwtToken(localStorage.getItem("AuthToken") ?? "");
    const schoolId =
      isNaN(user.schoolId) || user.schoolId === ""
        ? 0
        : parseInt(user.schoolId);
    setSelectedSchoolId(schoolId);
  }, []);

  function getLinkedSchool() {
    setLoading(true);
    getLinkedSchools(props.match.params.linkedUserId).then((d) => {
      setSchools(d.data);
      setLoading(false);
    });
  }

  function continueLogin() {
    const user: any = jwtToken(localStorage.getItem("AuthToken") ?? "");
    const schoolId =
      isNaN(user.schoolId) || user.schoolId === ""
        ? 0
        : parseInt(user.schoolId);
    const gradeId = isNaN(user.gradeId) ? 0 : parseInt(user.gradeId);
    const roleId = isNaN(user.roleId) ? 0 : parseInt(user.roleId);

    if (selectedSchoolId === schoolId) {
      handleRedirection(roleId, gradeId);
    } else {
      reLogin();
    }
  }

  function reLogin() {
    setLoading(true);

    var school: any = schools?.find((t) => t.schoolId === selectedSchoolId);

    localStorage.setItem("accountLoginName", school.accountLoginName);
    login(
      school.username,
      school.passwordHash,
      school.accountLoginName,
      0,
      "",
      "",
      Constant.LoginType.FORM,
      "",
      false
    )
      .then((response) => {
        if (!response) {
          showLoginFailedMessage();
        } else {
          const authToken = response.data["access_token"];
          localStorage.setItem("AuthToken", authToken);
          const user: any = jwtToken(authToken);
          const userId = isNaN(user.userId) ? 0 : parseInt(user.userId);
          const roleId = isNaN(user.roleId) ? 0 : parseInt(user.roleId);
          const gradeId = isNaN(user.gradeId) ? 0 : parseInt(user.gradeId);
          const linkedUserId = user.linkedUserId;
          const accountId = isNaN(user.accountId)
            ? 0
            : parseInt(user.accountId);
          const districtId = isNaN(user.districtId)
            ? 0
            : parseInt(user.districtId);

          const schoolId =
            isNaN(user.schoolId) || user.schoolId === ""
              ? 0
              : parseInt(user.schoolId);
          const schoolAccountId =
            isNaN(user.schoolAccountId) || user.schoolAccountId === ""
              ? 0
              : parseInt(user.schoolAccountId);
          const userName = !user.userName ? "" : user.userName;
          const stateId = isNaN(user.stateId) ? 0 : parseInt(user.stateId);

          const context: userContextAction.UserContextState = {
            userId: userId,
            roleId: roleId,
            gradeId: gradeId,
            schoolId: schoolId,
            accountId: accountId,
            districtId: districtId,
            stateId: stateId,
            schoolAccountId: schoolAccountId,
            impersonatedUser: null,
            googleId: "",
            activeExternalRoster:
              loginType.toUpperCase() === Constant.ExterRosterType.FORM
                ? ""
                : loginType.toUpperCase(),
            theme: getThemeByGrade(gradeId),
            userName: userName,
            linkedUserId: linkedUserId,
          };

          props.setUserContext(context);
          setTimeout(() => {
            props.setProfile(userId, roleId, schoolId, 0);
            handleRedirection(roleId, gradeId);
          }, 600);
        }
        setLoading(false);
      })
      .catch(() => {
        showLoginFailedMessage();
        setLoading(false);
      });
  }

  function showLoginFailedMessage() {}
  function handleRedirection(roleId: number, gradeId: number) {
    if (
      roleId === Constant.UserRoleId.SchoolAdmin ||
      roleId === Constant.UserRoleId.PayTeacher ||
      roleId === Constant.UserRoleId.FreeTeacher ||
      roleId === Constant.UserRoleId.SchoolTeacher ||
      roleId === Constant.UserRoleId.District ||
      roleId === Constant.UserRoleId.SuperAdmin ||
      roleId === 0
    ) {
      history.push(RouteConstant.ROUTE_DASHBOARD);
    } else if (roleId === Constant.UserRoleId.Student) {
      if (gradeId <= Constant.Grade.GRADE1) {
        history.push(RouteConstant.KindergartenStudentRoutes.Home);
      } else if (
        gradeId > Constant.Grade.GRADE1 &&
        gradeId < Constant.Grade.GRADE6
      ) {
        history.push(RouteConstant.StudentRoute.Home);
      } else if (gradeId >= Constant.Grade.GRADE6) {
        history.push(RouteConstant.MiddleSchool.assignments);
      } else {
        history.push(RouteConstant.StudentRoute.Home);
      }
    }
  }
  return (
    <>
      <div className="w-full h-screen relative">
        {loading && <Loader />}
        <div className="w-full min-h-full bg-gradient-to-b from-primary-violet to-dark-teal pb-6 flex flex-col justify-center">
          <h1 className="text-center text-3xl text-white font-semibold py-6">
            School Selection
          </h1>
          <div className="bg-white px-8 w-full md:w-[90%] lg:w-3/4 xl:w-2/3 1xl:w-2/4 xxxl:w-[47rem] min-h-[24rem] py-6 flex flex-col justify-center mx-auto rounded-lg shadow-xl">
            <span className="bg-white mx-auto p-2 mb-12 rounded-md">
              <LogoPLIcon className=" w-24 mx-auto" />
            </span>

            <div className="grid w-full sm:grid-cols-2 gap-6">
              {schools?.map((school, index) => {
                return (
                  <Fragment key={index}>
                    <div
                      id="StudentLoginBtn"
                      className={classNames(
                        "relative w-full py-3 group transition-all duration-300 cursor-pointer group border-2 rounded-lg text-xl flex flex-col items-center gap-x-4 gap-y-2 px-4",
                        selectedSchoolId === school.schoolId
                          ? "bg-light-violet shadow-lg shadow-2xl border-gray-700"
                          : "border-primary-violet"
                      )}
                      onClick={() => {
                        setSelectedSchoolId(school.schoolId);
                      }}
                    >
                      <span
                        className={classNames(
                          "w-8 h-8 opacity-0 transition-all duration-300 rounded-full absolute top-[-1.2rem] -right-1 flex items-center justify-center bg-green-400",
                          selectedSchoolId === school.schoolId
                            ? "opacity-100 "
                            : ""
                        )}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          strokeWidth="2"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M5 13l4 4L19 7"
                          />
                        </svg>
                      </span>
                      <div className="w-full flex justify-center relative">
                        <span
                          className={classNames(
                            "text-gray-500 transition-all duration-300 text-sm absolute right-0 top-0 opacity-90",
                            selectedSchoolId === school.schoolId
                              ? "text-gray-100 "
                              : ""
                          )}
                        >
                          {school.schoolCode}
                        </span>
                        <span
                          className={classNames(
                            "relative w-10 h-10 rounded-lg flex items-center justify-center",
                            selectedSchoolId === school.schoolId
                              ? "bg-white"
                              : "bg-light-violet"
                          )}
                        >
                          <SchoolIcon
                            className={classNames(
                              "w-5 fill-current  ",
                              selectedSchoolId === school.schoolId
                                ? "text-primary-violet"
                                : "text-white"
                            )}
                          />
                        </span>
                      </div>
                      <span
                        className={classNames(
                          "text-center text-lg font-bold ",
                          selectedSchoolId === school.schoolId
                            ? "text-white"
                            : "text-gray-700"
                        )}
                      >
                        {school.schoolName}
                      </span>
                    </div>
                  </Fragment>
                );
              })}
            </div>
            <div>
              <button
                className="bg-light-violet cursor-pointer hover:bg-dark-violet mt-6 w-full h-12 xxxl:h-14 text-lg xxxl:text-xl flex items-center justify-center text-white font-Barlow font-semibold"
                onClick={continueLogin}
              >
                {loading ? "Logging in..." : "Continue"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    profile: state.profile,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setUserContext: (payload: userContextAction.UserContextState) => {
      dispatch(userContextAction.setUserContext(payload));
    },
    setProfile: (
      userId: number,
      roleId: number,
      schoolId: number,
      districtId: number
    ) => {
      dispatch(fetchProfile(userId, roleId, schoolId, districtId));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SchoolSelection);

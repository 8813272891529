import { Fragment } from "react";
import NweaIcon from "../../../../../src/assets/images/nwea-linked.png";
import { getCopyrightText } from "../../../../utils/helper";

function Footer() {
  return (
    <Fragment>
      <div className="flex flex-col lg:flex-row w-full z-0 md:z-40">
        <div className="flex flex-col items-center justify-center lg:flex-row lg:justify-between w-full lg:w-full xl:w-9/12 bg-primary-violet py-1 px-4 md:py-0.5">
          <span className="flex flex-col md:flex-row md:space-x-2 lg:space-x-3 items-center">
            <span className="text-white text-opacity-80 text-xs">
              {getCopyrightText()}
            </span>
            <span className="flex items-center space-x-2 xl:space-x-3">
              <a
                href="https://progresslearning.com/product-terms-of-use/"
                className="text-xs cursor-pointer"
              >
                <span className="text-white opacity-80 hover:opacity-100">
                  Terms
                </span>
              </a>
              <span className="text-white opacity-80">|</span>
              <a
                href="https://progresslearning.com/product-privacy-policy/"
                className="text-xs cursor-pointer"
              >
                <span className="text-white opacity-80 hover:opacity-100">
                  Privacy
                </span>
              </a>
            </span>
            <span className="text-white text-xs">PHONE 1-877-377-9537</span>
            <span className="text-white text-opacity-80 hidden lg:inline-block">
              |
            </span>
            <span className="text-white text-xs">FAX 1-877-816-0808</span>
            <a
              href="https://progresslearning.com/news-blog/"
              className="text-white text-xs cursor-pointer hover:opacity-70"
            >
              Blog
            </a>
          </span>
          <img
            src={NweaIcon}
            className="hidden my-1 lg:my-0 w-24"
            alt="NWEA Icon"
          />
        </div>
        <div className="w-full lg:w-36 xl:w-3/12 bg-secondary-teal flex items-center justify-center space-x-4 lg:space-x-0 lg:justify-end px-4 py-0.5"></div>
      </div>
    </Fragment>
  );
}

export default Footer;

import { AxiosHelper } from "../../environment/axiosConfig";
import { LiveMonitoringResult } from "../../model/report/LiveMonitoringResult";
import APIConstant from "../../utils/constant/apiConstant";

export const getLiveMonitoringReport = (
  assignmentId: number,
  isCannedTest: boolean,
  classId: number
) => {
  return AxiosHelper.axiosInstance().get<LiveMonitoringResult>(
    APIConstant.LIVEMONITORINGREPORT_API.GetLiveMonitorReport,
    {
      params: {
        assignmentId: assignmentId,
        isCannedTest: isCannedTest,
        classId: classId,
      },
    }
  );
};

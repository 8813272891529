import Moment from "moment";
import { Fragment, useEffect, useState } from "react";
import { getAssignments } from "../../../../../../api/teacher/assignment";
import {
  RemoveIcon,
  GroupAssignmentIcon,
  AssessmentIcon,
  GeneratedTestIcon,
  QuestionIcon,
  VideoIcon,
  ConstructedResponseIcon,
} from "../../../../../../assets/icons";
import PagingResponse from "../../../../../../model/common/pagingResponse";
import { IAssignmentSearch } from "../../../../../../model/interface/assignmentSearch";
import MyAssignmentDetails from "../../../../../../model/teacher/assignment";
import ClassStudentModel from "../../../../../../model/teacher/classManagement/classStudentModel";
import {
  DEFAULT_PAGE_NUMBER,
  DEFAULT_PAGE_SIZE,
} from "../../../../../../utils/pagingConstant";
import InfoMessage from "../../../../../shared/infoMessage";
import PageSize from "../../../../../shared/pagination/pageSize";
import Pagination from "../../../../../shared/pagination/pagination";
import { getNameLabel } from "../../../../../../utils/helper";
import UserTile from "../../../../../shared/UserTile";
import Constant from "../../../../../../utils/constant/constant";
interface passedProps {
  userId: number;
  roleId: number;
  classId: number;
  students: Array<ClassStudentModel>;
  selectAssignments: (assignmentIds: Array<number>) => void;
  removeStudent: (studentId: number) => void;
  onlineUsers?: Array<any>;
  schoolYearId?: number;
}

function ClassAssignment(props: passedProps) {
  const { userId, students, selectAssignments, removeStudent } = props;
  const [pageNumber, setPageNumber] = useState(DEFAULT_PAGE_NUMBER);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [resetPageNumber, setResetPageNumber] = useState<boolean>(false);
  const [assignments, setAssignments] =
    useState<PagingResponse<MyAssignmentDetails>>();
  const [toggleSelectAll, setToggleSelectAll] = useState<boolean>(false);
  const [selectedAssignments, setSelectedAssignments] = useState<Array<number>>(
    []
  );

  function handleSelectAll() {
    const isSelected = !toggleSelectAll;
    var selectedAssignmentList: Array<number> = new Array<number>();
    if (isSelected && assignments && assignments.data) {
      selectedAssignmentList = assignments.data.map((r) => {
        return parseInt(r.assignmentId);
      });
      setSelectedAssignments(selectedAssignmentList);
    } else {
      setSelectedAssignments([]);
    }

    selectAssignments(selectedAssignmentList);

    setToggleSelectAll(!toggleSelectAll);
  }

  function handleSelectedAssignments(assignmentId: number) {
    var selectedAssignmentList: Array<number> = new Array<number>();

    const assignmentExist = selectedAssignments.some(
      (r) => (r as number) === assignmentId
    );

    if (assignmentExist) {
      selectedAssignmentList = selectedAssignments.filter(
        (r) => (r as number) !== assignmentId
      );
    } else {
      selectedAssignmentList = [...selectedAssignments, assignmentId];
    }

    selectAssignments(selectedAssignmentList);

    setSelectedAssignments(selectedAssignmentList);
  }

  const onPageChange = (pageNo) => {
    setPageNumber(pageNo);
  };

  const onPageSizeChange = (pageSize) => {
    setPageNumber(DEFAULT_PAGE_NUMBER);
    setPageSize(pageSize);
    setResetPageNumber(true);
  };

  function searchAssignments() {
    const assignmentSearch: IAssignmentSearch = {
      teacherId: userId,
      assignmentType: "MYASSIGNMENT",
      pageNumber: pageNumber,
      pageSize: pageSize,
      classId: props.classId,
      schoolYearId: props.schoolYearId,
    };

    // setShowLoading(true);
    getAssignments(assignmentSearch).then((r) => {
      setAssignments(r.data);
      //setShowLoading(false);
    });
  }

  useEffect(() => {
    searchAssignments();
  }, [pageSize, pageNumber]);

  return (
    <Fragment>
      <InfoMessage message="You may add assignments to new students. For an assignment to appear in this list, it must be unlocked, not past due, and assigned to this class. To add an assignment not shown in this list, go to that specific assignment's settings, located in your Assignments section." />
      <div className="flex flex-wrap -mx-2 overflow-hidden sm:-mx-1 md:-mx-1 lg:-mx-px xl:-mx-px">
        <div className="my-2 px-2 w-full overflow-hidden sm:my-1 sm:px-1 sm:w-full md:my-1 md:px-1 md:w-full lg:my-px lg:px-px lg:w-1/4 xl:my-px xl:px-px xl:w-1/4">
          <div
            className="mt-1 space-y-1"
            role="group"
            aria-labelledby="desktop-teams-headline"
          >
            {students.map((student) => {
              return (
                <span className="cursor-pointer group flex items-center px-3 py-2 text-sm font-medium text-gray-700 rounded-md hover:text-gray-900 hover:bg-gray-50">
                  <div className="flex justify-between gap-2 items-center">
                    <UserTile
                      userId={student.userId}
                      onlineUsers={props?.onlineUsers!}
                      isLoggedInUser={false}
                    />
                    <span className="truncate">
                      {getNameLabel(student.lastName, student.firstName)}
                    </span>
                  </div>
                  <RemoveIcon
                    className="w-4 ml-auto"
                    onClick={() => removeStudent(student.userId)}
                  />
                </span>
              );
            })}
          </div>
        </div>

        <div className="my-2 px-2 w-full overflow-hidden sm:my-1 sm:px-1 sm:w-full md:my-1 md:px-1 md:w-full lg:my-px lg:px-px lg:w-3/4 xl:my-px xl:px-px xl:w-3/4">
          <div className="mb-4 pb-3 overflow-auto shadow">
            <table className="table w-full ">
              <thead className="mb-5">
                <tr className="bg-light-violet  text-white py-3 px-3 overflow-hidden">
                  <th className="text-left p-3 text-sm font-normal w-4">
                    <input
                      id="styled-checkbox-2"
                      type="checkbox"
                      className="checkbox"
                      checked={toggleSelectAll}
                      onChange={() => handleSelectAll()}
                    />
                  </th>
                  <th className="text-center p-3 text-sm font-normal">
                    Due Date
                  </th>
                  <th className="text-left  p-3 text-sm font-normal">Name</th>
                  <th className="text-center p-3 text-sm font-normal">Type</th>
                </tr>
              </thead>
              <tbody>
                {assignments &&
                  assignments.data?.length > 0 &&
                  assignments.data?.map((assignment, index) => {
                    return (
                      <Fragment key={index}>
                        <tr
                          className={`hover:bg-light-violet/20 transition ease-in-out duration-300 ${
                            index % 2 === 0 ? "bg-white" : "bg-light-violet/10"
                          }`}
                        >
                          <td className=" mt-5 p-3 text-center">
                            <input
                              id="styled-checkbox-2"
                              type="checkbox"
                              className="checkbox"
                              checked={selectedAssignments.some(
                                (r) =>
                                  (r as number) ===
                                  parseInt(assignment.assignmentId)
                              )}
                              onChange={() =>
                                handleSelectedAssignments(
                                  parseInt(assignment.assignmentId)
                                )
                              }
                            />
                            <label className="checkbox-label"></label>
                          </td>
                          <td className="text-sm mt-5  p-3 text-center">
                            {assignment.endDate &&
                              Moment(assignment.endDate).format("MM-DD-yyyy")}
                          </td>

                          <td className="text-sm mt-5  p-3">
                            <a href="#!" className="text-dark-blue">
                              {assignment.name}
                            </a>
                          </td>
                          <td className="text-sm mt-5 p-3 text-center">
                            {assignment.hasActicity &&
                              assignment.activityCount > 1 && (
                                <GroupAssignmentIcon className="w-4 stroke-current text-gray-500 hover:text-gray-700 mx-auto" />
                              )}
                            {assignment.hasActicity &&
                              assignment.assignmentActivity &&
                              assignment.activityCount === 1 && (
                                <div>
                                  {assignment.assignmentActivity
                                    .activityType ===
                                    Constant.AssignmentActivityType
                                      .ASSESSMENT && (
                                    <AssessmentIcon className="w-4 stroke-current text-gray-500 hover:text-gray-700 mx-auto" />
                                  )}
                                  {assignment.assignmentActivity
                                    .activityType ===
                                    Constant.AssignmentActivityType
                                      .PREBUILTTESTS && (
                                    <GeneratedTestIcon className="w-4 stroke-current text-gray-500 hover:text-gray-700 mx-auto" />
                                  )}
                                  {assignment.assignmentActivity
                                    .activityType ===
                                    Constant.AssignmentActivityType
                                      .PRACTICEQUESTIONS && (
                                    <QuestionIcon className="w-4 stroke-current text-gray-500 hover:text-gray-700 mx-auto" />
                                  )}
                                  {assignment.assignmentActivity
                                    .activityType ===
                                    Constant.AssignmentActivityType
                                      .VIDEOLESSON && (
                                    <VideoIcon className="w-4 stroke-current text-gray-500 hover:text-gray-700 mx-auto" />
                                  )}
                                  {assignment.assignmentActivity
                                    .activityType ===
                                    Constant.AssignmentActivityType
                                      .CONSTRUCTEDRESPONSE && (
                                    <ConstructedResponseIcon className="w-4 stroke-current text-gray-500 hover:text-gray-700 mx-auto" />
                                  )}
                                </div>
                              )}
                          </td>
                        </tr>
                      </Fragment>
                    );
                  })}
              </tbody>
            </table>
          </div>
          <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between ">
            {assignments?.totalRecords !== undefined &&
              assignments?.totalRecords !== 0 && (
                <Pagination
                  totalRecords={assignments?.totalRecords}
                  pageSize={pageSize}
                  onPageChange={onPageChange}
                  reset={resetPageNumber}
                  pageNumber={pageNumber}
                />
              )}
            {assignments?.totalRecords !== 0 && (
              <PageSize
                pageSize={pageSize}
                onPageSizeChange={onPageSizeChange}
              />
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default ClassAssignment;

import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  getAlienRankingCards,
  downloadCertificate,
} from "../../../../../api/student/studentApi";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  PlayIcon,
  DownloadCertificatelayIcon,
} from "../../../../../assets/icons/index";
import AlienRankingCard from "../../../../../model/student/shared/alienRanking/alienRankingCard";
import { XIcon } from "@heroicons/react/solid";

import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import VideoPopup from "../popups/videoPopup";
import constant from "../../../../../utils/constant/constant";

interface AlienRankingProps {
  studentId: number;
  gradeId: number;
  firstName: string;
  lastName: string;
  type: string;
}

const AlienRanking = (props: AlienRankingProps) => {
  const { t } = useTranslation();

  const { studentId, gradeId } = props;
  const [showAlienRankPopup, setShowAlienRankPopup] = useState(false);
  const [showVideoPopup, setShowVideoPopup] = useState(false);
  const [rankingCards, setRankingCards] = useState<Array<AlienRankingCard>>([]);
  const [selectedCardIndex, setSelectedCardIndex] = useState(0);
  const [selectedBackSideCardIndex, setselectedBackSideCardIndex] =
    useState<number>();
  const [currenRankingCard, setCurrentRankingCard] =
    useState<AlienRankingCard>();
  const isSpanish =
    localStorage.getItem(constant.LocalStorageKeys.i18nextLng) === "es"
      ? true
      : false;

  useEffect(() => {
    getRankingCards();
  }, []);

  useEffect(() => {
    if (showAlienRankPopup) {
      getRankingCards();
    }
  }, [showAlienRankPopup]);

  const getRankingCards = () => {
    getAlienRankingCards(studentId, gradeId, props.type === "PL" ? 1 : 2).then(
      (r) => {
        const cards: Array<AlienRankingCard> = r.data;
        const unlockedCards = cards.filter((c) => !c.isLocked && c.isActive);
        const maxLevelCard = unlockedCards.reduce((prev, current) =>
          prev.level > current.level ? prev : current
        );
        setRankingCards(cards);
        setCurrentRankingCard(maxLevelCard);
        setSelectedCardIndex(unlockedCards.length - 1);
        setselectedBackSideCardIndex(undefined);
      }
    );
  };

  const next = () => {
    if (selectedCardIndex < rankingCards.length - 1) {
      setSelectedCardIndex(selectedCardIndex + 1);
    }
  };

  const prev = () => {
    if (selectedCardIndex > 0) {
      setSelectedCardIndex(selectedCardIndex - 1);
    }
  };

  const downloadStudentCertificate = () => {
    downloadCertificate(
      props.firstName,
      props.lastName,
      rankingCards[selectedCardIndex].level,
      rankingCards[selectedCardIndex].isPrestigeMode
    ).then((response) => {
      const file: any = response.data;
      const fileURL = URL.createObjectURL(file);
      const link = document.createElement("a");
      link.href = fileURL;
      link.setAttribute("download", "certificate.pdf");
      document.body.appendChild(link);
      link.click();

      // Clean up and remove the link
      link.parentNode!.removeChild(link);
    });
  };

  const getImageUrl = (card: AlienRankingCard, index: number) => {
    if (card.level <= 10 && selectedBackSideCardIndex !== undefined) {
      return selectedBackSideCardIndex === index
        ? isSpanish
          ? card.spanshCardBackImageUrl
          : card.cardBackImageUrl
        : isSpanish
        ? card.spanshCardImageUrl
        : card.cardImageUrl;
    }
    return isSpanish ? card.spanshCardImageUrl : card.cardImageUrl;
  };

  const toggleCardBackSide = (index: number) => {
    if (selectedBackSideCardIndex === index) {
      setselectedBackSideCardIndex(undefined);
    } else {
      setselectedBackSideCardIndex(index);
    }
  };

  return (
    <>
      <div
        onClick={() => setShowAlienRankPopup(true)}
        className="h-full w-full bg-gradient-to-b from-[#0062ff] to-[#61efff] rounded-2xl py-4 px-4 text-center hover:shadow-2xl transition ease-in-out duration-500 group flex flex-col cursor-pointer"
      >
        <img
          className="w-auto h-40 m-auto flex-shrink-0 transition group-hover:scale-110 ease-in-out duration-500"
          src={
            isSpanish
              ? currenRankingCard?.spanshCardImageUrl
              : currenRankingCard?.cardImageUrl
          }
          alt="alien ranking"
          title="Alien ranking"
        />
        <h5 className="mt-2 text-lg">
          {t("StudentElementaryHome.AlienRanking")}
        </h5>
      </div>
      {showVideoPopup && (
        <VideoPopup
          videoUrl={rankingCards[selectedCardIndex].videoUrl}
          showPopup={setShowVideoPopup}
        />
      )}

      {showAlienRankPopup && (
        <Fragment>
          <div className="fixed inset-0 z-40 flex items-center bg-black bg-opacity-50 sm:justify-center enter-done">
            <div className="w-full px-6 py-4 overflow-hidden bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:max-w-xl">
              <div>
                <header className="flex items-center justify-between">
                  <h2>
                    {t("AlienRanking.MyCurrentRanking")}{" "}
                    {t("AlienRanking.Level")} {currenRankingCard?.level}{" "}
                    {isSpanish
                      ? currenRankingCard?.spanishName
                      : currenRankingCard?.name}{" "}
                  </h2>
                  <XIcon
                    className="cursor-pointer h-4 w-4"
                    onClick={() => setShowAlienRankPopup(false)}
                  ></XIcon>
                </header>
                <div className="mt-3 bg-gradient-to-b from-[#0062ff] to-[#61efff] rounded-2xl py-1 px-4 py-4 text-center hover:shadow-2xl transition ease-in-out duration-500">
                  <div className="mt-4 mb-2 text-lg font-semibold text-gray-700 dark:text-gray-300 mb-6 rounded-2xl overflow-auto text-center">
                    <div>
                      <Carousel
                        dynamicHeight={false}
                        selectedItem={selectedCardIndex}
                        showIndicators={false}
                        showThumbs={false}
                        showStatus={false}
                        showArrows={false}
                      >
                        {rankingCards.map((card, i) => (
                          <div
                            key={i}
                            onClick={() => {
                              toggleCardBackSide(i);
                            }}
                          >
                            {(!card.isLocked && (
                              <img
                                alt="card"
                                src={getImageUrl(card, i)}
                                style={{ width: "36%" }}
                              />
                            )) || (
                              <p className="align-bottom inline-flex items-center justify-center cursor-pointer  transition-colors duration-150 font-medium focus:outline-none px-4 py-4 rounded-lg text-base text-white border border-transparent  focus:shadow-outline-purple w-full sm:w-auto bg-[#0062ff] border-2 border-white max-w-xs min-h-[200px] gap-2 hover:shadow-md  ">
                                {t("AlienRanking.Text1")} {card.level}{" "}
                                {t("AlienRanking.Text2")}.{" "}
                                {t("AlienRanking.Text3")}{" "}
                                {card.starsRequiredToUnlock}{" "}
                                {t("AlienRanking.Text4")} {card.level}.
                              </p>
                            )}

                            {!card.isLocked && (
                              <h3 className="text-lg mt-2 font-medium text-black">
                                {t("AlienRanking.Level")} {card.level} :{" "}
                                {isSpanish ? card.spanishName : card.name}
                              </h3>
                            )}
                          </div>
                        ))}
                      </Carousel>
                    </div>
                  </div>

                  <div className="flex flex-col gap-4 sm:gap-0 items-center justify-start">
                    <div>{t("AlienRanking.GalaxyStars")}</div>
                    <div className="w-full justify-between items-center flex mt-[-16px]">
                      <a href="#!" onClick={prev}>
                        <ChevronLeftIcon className="w-8 text-white transition duration-700 ease-in-out" />
                      </a>

                      <a href="#!" onClick={next}>
                        <ChevronRightIcon className="w-8 text-white" />
                      </a>
                    </div>
                  </div>
                </div>

                <footer className="flex  flex-col items-center justify-center px-6 py-3 -mx-6 -mb-4  bg-gray-50 dark:bg-gray-800">
                  <div className="h-10 space-y-4 sm:space-y-0 sm:space-x-6 sm:flex-row">
                    {rankingCards.length > 0 &&
                      rankingCards[selectedCardIndex].videoUrl &&
                      !rankingCards[selectedCardIndex].isLocked && (
                        <>
                          <button
                            className="align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 rounded-lg text-sm text-white border border-transparent  focus:shadow-outline-purple w-full sm:w-auto  bg-primary-blue hover:bg-[#0149c1] gap-2 hover:shadow-md"
                            type="button"
                            onClick={() => {
                              setShowVideoPopup(true);
                            }}
                          >
                            <PlayIcon className="text-white w-3"></PlayIcon>
                            {t("AlienRanking.PlayVideo")}
                          </button>

                          <button
                            className="align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 rounded-lg text-sm text-white border border-transparent  focus:shadow-outline-purple w-full sm:w-auto bg-primary-blue hover:bg-[#0149c1] gap-2 hover:shadow-md"
                            onClick={downloadStudentCertificate}
                          >
                            <DownloadCertificatelayIcon className="w-5 text-white" />
                            {t("AlienRanking.DownloadCertificate")}
                          </button>
                        </>
                      )}
                  </div>
                </footer>
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </>
  );
};

export default AlienRanking;

import { Fragment, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { login } from "../../../api/login.service";
import { syncTeacherClasses } from "../../../api/thirdPartyIntegration/thirdPartyIntegration";
import { fetchProfile } from "../../../redux/actions/userActions";
import * as userContextAction from "../../../redux/actions/userContextAction";
import Constant from "../../../utils/constant/constant";
import RouteConstant from "../../../utils/constant/routeConstant";
import { jwtToken } from "../../shared/tokenHelper";
import LoginErrorResponse from "../../../model/loginErrorResponse";

function CleverLogin(props: any) {
  const history = useHistory();

  function loginWithCleverToken() {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    const accessCode = params.get("code");
    const errorCode = params.get("err");

    if (
      (errorCode === "" || errorCode === null) &&
      accessCode !== undefined &&
      accessCode !== null &&
      accessCode !== ""
    ) {
      login(
        "clever_user",
        "clever_pwdnpm",
        "",
        0,
        "",
        "",
        Constant.LoginType.CLEVER,
        accessCode,
        false
      ).then((response) => {
        if (!response) {
          showLoginFailed();
        } else {
          const authToken = response.data["access_token"];
          localStorage.setItem("AuthToken", authToken);
          const user: any = jwtToken(authToken);

          const userId = isNaN(user.userId) ? 0 : parseInt(user.userId);
          const roleId = isNaN(user.roleId) ? 0 : parseInt(user.roleId);
          const gradeId = isNaN(user.gradeId) ? 0 : parseInt(user.gradeId);
          const accountId = isNaN(user.accountId)
            ? 0
            : parseInt(user.accountId);
          const linkedUserId = user.linkedUserId;
          const schoolId =
            isNaN(user.schoolId) || user.schoolId === ""
              ? 0
              : parseInt(user.schoolId);
          const schoolAccountId =
            isNaN(user.schoolAccountId) || user.schoolAccountId === ""
              ? 0
              : parseInt(user.schoolAccountId);

          if (
            roleId === Constant.UserRoleId.SchoolAdmin ||
            roleId === Constant.UserRoleId.SchoolTeacher
          ) {
            syncTeacherClasses(userId, schoolId, [
              Constant.ThirdPartyIntegrationType.ClassLink,
              Constant.ThirdPartyIntegrationType.CleverLink,
            ]);
          }
          const context: userContextAction.UserContextState = {
            userId: userId,
            roleId: roleId,
            gradeId: gradeId,
            schoolId: schoolId,
            accountId: accountId,
            districtId: isNaN(user.districtId) ? 0 : parseInt(user.districtId),
            stateId: isNaN(user.stateId) ? 0 : parseInt(user.stateId),
            googleId: "",
            activeExternalRoster: "CLEVER",
            schoolAccountId: schoolAccountId,
            impersonatedUser: null,
            linkedUserId: linkedUserId,
          };

          props.setUserContext(context);
          setTimeout(() => {
            props.setProfile(userId, roleId, schoolId, 0);
            handleRedirection(roleId, gradeId, linkedUserId);
          }, 600);
        }
      })
      .catch((err) => {
        const loginErrorResponse: LoginErrorResponse = err.response.data;
          if (loginErrorResponse && loginErrorResponse.isExpired) {
            handleShowAccountExpiryPage(loginErrorResponse);
          } else {
            showLoginFailed();
          }        
      });
    } else {
      showLoginFailed();
    }
    const handleRedirection = (
      roleId: number,
      gradeId: number = 0,
      linkedUserId: string = ""
    ) => {
      console.log(roleId);
      console.log(gradeId);
      console.log(linkedUserId);

      let schoolSelectionUrl: string =
        RouteConstant.ROUTE_SCHOOL_SELECTION.replace(
          ":linkedUserId",
          linkedUserId
        ).replace(":loginType", Constant.ExterRosterType.CLEVER);
      if (
        roleId === Constant.UserRoleId.SchoolAdmin ||
        roleId === Constant.UserRoleId.PayTeacher ||
        roleId === Constant.UserRoleId.FreeTeacher ||
        roleId === Constant.UserRoleId.SchoolTeacher ||
        roleId === Constant.UserRoleId.District ||
        roleId === Constant.UserRoleId.SuperAdmin ||
        roleId === 0
      ) {
        history.push(
          linkedUserId === ""
            ? RouteConstant.ROUTE_DASHBOARD
            : schoolSelectionUrl
        );
      } else if (roleId === Constant.UserRoleId.Student) {
        if (gradeId <= Constant.Grade.GRADE1) {
          console.log("kindergarten");
          history.push(
            linkedUserId === ""
              ? RouteConstant.KindergartenStudentRoutes.Home
              : schoolSelectionUrl
          );
        } else if (
          gradeId > Constant.Grade.GRADE1 &&
          gradeId < Constant.Grade.GRADE6
        ) {
          console.log("elementary");
          history.push(
            linkedUserId === ""
              ? RouteConstant.StudentRoute.Home
              : schoolSelectionUrl
          );
        } else if (gradeId >= Constant.Grade.GRADE6) {
          console.log("middleschool");
          history.push(
            linkedUserId === ""
              ? RouteConstant.MiddleSchool.assignments
              : schoolSelectionUrl
          );
        } else {
          history.push(
            linkedUserId === ""
              ? RouteConstant.StudentRoute.Home
              : schoolSelectionUrl
          );
        }
      } else {
        console.log("default case");
        history.push(
          linkedUserId === ""
            ? RouteConstant.TeacherRoutes.assignmentCenter
            : schoolSelectionUrl
        );
      }
    };

    function showLoginFailed() {
      history.push({
        pathname: RouteConstant.ROUTE_LOGIN,
        state: {
          loginFailedType: "Clever",
        },
      });
    }

    const handleShowAccountExpiryPage = (errorResponse: LoginErrorResponse) => {
      history.push({
        pathname: RouteConstant.EXPIRED,
        state: { userRoleId: errorResponse.userRoleId },
      });
    };    
  }
  useEffect(() => {
    loginWithCleverToken();
  }, []);

  return (
    <Fragment>
      <div className="flex h-screen">
        <div className="m-auto flex">
          <span className="flex items-center justify-center space-x-2 mr-2">
            Authenticating please wait...
          </span>
          <div>
            <div
              style={{ borderTopColor: "transparent" }}
              className="w-6 h-6 border-4 border-blue-400 border-solid rounded-full animate-spin"
            ></div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

const mapStateToProps = (state: any) => {
  return {
    profile: state.profile,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setUserContext: (payload: userContextAction.UserContextState) => {
      dispatch(userContextAction.setUserContext(payload));
    },
    setProfile: (
      userId: number,
      roleId: number,
      schoolId: number,
      districtId: number
    ) => {
      dispatch(fetchProfile(userId, roleId, schoolId, districtId));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CleverLogin);

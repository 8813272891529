function ResultReportItemAnalysisHeaderGraph({question}) {
  return (
    <div className="lrn-ra-head-cell-bottom w-full">
      <div className="lrn-ra-head-cell-bottom-chart">
        <div className="lrn-module-charts-histogram">
          <span className="lrn-module-charts-histogram-column item-correct">
            <span className="lrn-module-charts-histogram-column-label-top">{question.correctCount}</span>
            <span className="lrn-module-charts-histogram-column-value" style={{height: `calc(${question.correctCount / question.total} * 32px + 1px)`}}></span>
            <span className="lrn-module-charts-histogram-column-label-bottom">&#10003;</span>
          </span>
          {(question.questionTypeId !== 1 && question.questionTypeId !== 3 || question.partiallyCorrectCount !== 0) && (
            <span className="lrn-module-charts-histogram-column item-partially-correct">
              <span className="lrn-module-charts-histogram-column-label-top">{question.partiallyCorrectCount}</span>
              <span className="lrn-module-charts-histogram-column-value" style={{height: `calc(${question.partiallyCorrectCount / question.total} * 32px + 1px)`}}></span>
              <span className="lrn-module-charts-histogram-column-label-bottom">P</span>
            </span>
          )}
          <span className="lrn-module-charts-histogram-column">
            <span className="lrn-module-charts-histogram-column-label-top">{question.incorrectCount}</span>
            <span className="lrn-module-charts-histogram-column-value" style={{height: `calc(${question.incorrectCount / question.total} * 32px + 1px)`}}></span>
            <span className="lrn-module-charts-histogram-column-label-bottom">&times;</span>
          </span>
          <span className="lrn-module-charts-histogram-column item-not-attempted">
            <span className="lrn-module-charts-histogram-column-label-top">{question.total - question.isAttemptedCount}</span>
            <span className="lrn-module-charts-histogram-column-value" style={{height: `calc(${(question.total - question.isAttemptedCount) / question.total} * 32px + 1px)`}}></span>
            <span className="lrn-module-charts-histogram-column-label-bottom">-</span>
          </span>
        </div>
      </div>
    </div>
  )
}

export default ResultReportItemAnalysisHeaderGraph

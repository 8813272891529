import { AxiosHelper } from "../../environment/axiosConfig";
import { IArcadeGameScores } from "../../model/interface/arcadeGameScore";
import APIConstant from "../../utils/constant/apiConstant";


export const GetStudentSchoolArcadeGameScore = (
  arcadeGameId: number,
  programMode: number,
  schoolId: number
) => {
  return AxiosHelper.axiosInstance().get<IArcadeGameScores>(
    APIConstant.Student_API.StudentSchoolArcadeGameScore,
    {
      params: {
        arcadeGameId: arcadeGameId,
        programMode: programMode,
        schoolId: schoolId,
      },
    }
  );
};
export const GetStudentGalaxyArcadeGameScore = (
  arcadeGameId: number,
  programMode: number
) => {
  return AxiosHelper.axiosInstance().get<IArcadeGameScores>(
    APIConstant.Student_API.StudentGalaxyArcadeGameScore,
    {
      params: { 
        arcadeGameId: arcadeGameId,
        programMode: programMode,
      },
    }
  );
};

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Fragment, useEffect, useState } from "react";
import { Select } from "@windmill/react-ui";
import { useHistory } from "react-router-dom";
import classNames from "classnames";
import constant from "../../../../utils/constant/constant";
import { useConfirmation } from "../../../shared/confirmation/confirmationService";
import { getAssignmentsQuestionCount } from "../../../../api/student/studentActivityApi";
import { GetLanguageSetting } from "../../../../utils/studentHelper";
import { getContentAreaBySubjectId } from "../../../../api/subjectAPI";
import { IsLiftOff } from "../../../../utils/helper";

const theme = {
  ELEMENTARYHOME: {
    header: "bg-[#a3ed2b]",
    headerTextColor: "text-lg text-gray-700",
    bodyText: "text-gray-700",
    table: "",
    beginButton:
      "bg-[#a3ed2b] shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-gray-700 hover:bg-opacity-80 hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 rounded-lg",
    cancelButton:
      "w-auto inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm",
  },
  KINDERGARTEN: {
    header: "bg-yellow-400",
    headerTextColor: "text-lg font-medium text-gray-700",
    bodyText: "text-yellow-500",
    table: "",
    beginButton:
      "align-bottom inline-flex font-bold items-center justify-center cursor-pointer leading-5 transition-colors duration-150  focus:outline-none px-5 py-3 rounded-lg text-lg text-white bg-primary-violet hover:shadow-lg hover:bg-dark-violet",
    cancelButton:
      "align-bottom border-2 border-gray-800 inline-flex font-bold items-center justify-center cursor-pointer leading-5 transition-colors duration-150  focus:outline-none px-5 py-3 rounded-lg text-lg text-gray-700 bg-white hover:border-[#dec80a] ",
  },
  MIDDLESCHOOLHOME: {
    header: "bg-site-teal",
    headerTextColor: "text-lg font-medium text-white",
    bodyText: "text-site-teal",
    table: "",
    beginButton:
      "bg-primary-violet border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-dark-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 rounded-lg",
    cancelButton:
      "w-auto inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm",
  },
};
interface IBeginActivity {
  name: string;
  activity: string;
  assignmentActivityName?: string;
  noOfQuestions: number;
  data: any;
  themeName: "ELEMENTARYHOME" | "KINDERGARTEN" | "MIDDLESCHOOLHOME";
  source: "StudyPlan" | "Assignment";
  onBegin: Function;
  ActivityLanguageLevel: "UserLevel" | "ClassLevel" | "AssignmentLevel";
  profile?: any;
  sessionActivityId?: string;
  hideToggle?: boolean;
  hideTeacher?: boolean;
  hideClass?: boolean;
  code?: string;
  assignmentId?: number;
  activityType?: string;
  hideQuestionCount?: boolean;
  hideSpanishLanguage?: boolean;
  hideEnglishLanguage?: boolean;
  programMode?: string;
  onCancel?: Function;
  subjectId?: number;
  languageSettingId?: number;
}

function BeginActivity(props: IBeginActivity) {
  const { t } = useTranslation();
  const themeName = props.themeName;
  const [selectedClass, setSelectedClass] = useState(props.data.classId);
  const [selectedTeacher, setSelectedTeacher] = useState(props.data.teacherId);
  const [onBeginActivityClick, setOnBeginActivityClick] = useState(false);
  const [isLiftOff, setIsLiftOff] = useState<boolean>(false);

  const [selectedLanguage, setSelectedLanguage] = useState(
    props.data.languageId ??
      ((props.profile.plLanguageId === constant.Languages.Spanish ||
        props.profile.loLanguageId === constant.Languages.Spanish) &&
      props.activity.indexOf("Canned") === -1
        ? 2
        : 1)
  );

  const history = useHistory();
  const [activityQuestionCount, setActivityQuestionCount] = useState(0);
  const [languageSetting, setLanguageSetting] = useState<number>(
    props.languageSettingId ?? 0
  );
  const [contentAreaId, setContentAreaId] = useState<number>(0);
  const teachers: any[] = [];
  if (props.data.teachers && props.data.teachers.length > 0) {
    props.data.teachers
      .filter((t) => t.isPrimary)
      .forEach(function (item) {
        var i = teachers.findIndex((x) => x?.userId === item.userId);
        if (i <= -1) {
          teachers.push({
            userId: item.userId,
            firstName: item.firstName,
            lastName: item.lastName,
          });
        }
      });
  }

  const getQuestionCount = async () => {
    if (props.sessionActivityId) {
      if (props.sessionActivityId === "VIDEOLESSONCONSTRUCTEDRESPONSE") {
        setActivityQuestionCount(1);
      } else {
        setActivityQuestionCount(
          await (
            await getAssignmentsQuestionCount(props.sessionActivityId)
          ).data
        );
      }
    } else {
      setActivityQuestionCount(props.noOfQuestions);
    }
  };
  async function GetContentArea() {
    return await getContentAreaBySubjectId(props.subjectId ?? 0).then((res) => {
      if (res.data) {
        var contentAreaId: number = res.data?.id;
        setContentAreaId(contentAreaId);
      } else setContentAreaId(0);
    });
  }
  const confirm = useConfirmation();

  useEffect(() => {
    getQuestionCount();
    var isLO = IsLiftOff();
    setIsLiftOff(isLO);

    if (props.hideTeacher === true) {
      setSelectedTeacher(0);
    } else if (props.data.teacherId) {
      setSelectedTeacher(props.data.teacherId ? props.data.teacherId : 0);
    } else {
      setSelectedTeacher(teachers?.length > 0 ? teachers[0].userId : 0);
    }

    if (props.hideClass === true) {
      setSelectedClass(0);
    } else if (props.data.classId) {
      setSelectedClass(props.data.classId);
    } else {
      setSelectedClass(
        props.data.classes?.length > 0 ? props.data.classes[0].classId : null
      );
    }

    if (props.ActivityLanguageLevel === "UserLevel") {
      if (props.subjectId && props.subjectId > 0) {
        GetContentArea();
      } else {
        setSelectedLanguage(
          props.data.languageId ??
            (((window.location.pathname.toLowerCase().indexOf("liftoff") ===
              -1 &&
              props.profile.plLanguageId === constant.Languages.Spanish) ||
              (window.location.pathname.toLowerCase().indexOf("liftoff") > -1 &&
                props.profile.loLanguageId === constant.Languages.Spanish)) &&
            props.activity.indexOf("Canned") === -1
              ? 2
              : 1)
        );
      }
    } else if (props.ActivityLanguageLevel === "ClassLevel") {
      setSelectedLanguage(props.data.classLanguageId);
    } else if (props.ActivityLanguageLevel === "AssignmentLevel") {
      setSelectedLanguage(props.languageSettingId);
    }
  }, []);

  useEffect(() => {
    if (languageSetting > 0) {
      var language =
        props.data.languageId > 0 &&
        languageSetting !== constant.LanguageSetting.EnglishOnly
          ? props.data.languageId
          : languageSetting === constant.LanguageSetting.EnglishOnly ||
            languageSetting === constant.LanguageSetting.EnglishDefault
          ? constant.Languages.English
          : constant.Languages.Spanish;
      setSelectedLanguage(
        isLiftOff &&
          props.profile.loLanguageId === constant.Languages.Spanish &&
          props.activity.indexOf("Canned") === -1
          ? 2
          : isLiftOff &&
            props.profile.loLanguageId === constant.Languages.English &&
            props.activity.indexOf("Canned") === -1
          ? 1
          : language
      );
    }
  }, [languageSetting]);

  useEffect(() => {
    if (props.ActivityLanguageLevel === "UserLevel") {
      GetLanguageSetting(
        props.subjectId ?? 0,
        props.profile,
        contentAreaId
      ).then((res) => {
        if (res) setLanguageSetting(res);
      });
    }
  }, [contentAreaId]);

  const onCancel = () => {
    if (props.onCancel) {
      props.onCancel();
    } else {
      history.goBack();
    }
  };
  useEffect(() => {
    if (props.ActivityLanguageLevel === "UserLevel") {
      if (props.subjectId && props.subjectId > 0) {
        GetContentArea();
      } else {
        setSelectedLanguage(
          props.data.languageId ??
            (((!isLiftOff &&
              props.profile.plLanguageId === constant.Languages.Spanish) ||
              (isLiftOff &&
                props.profile.loLanguageId === constant.Languages.Spanish)) &&
            props.activity.indexOf("Canned") === -1
              ? 2
              : 1)
        );
      }
    } else if (props.ActivityLanguageLevel === "ClassLevel") {
      setSelectedLanguage(props.data.classLanguageId);
    }
  }, [isLiftOff]);

  const getLanguageToggle = () => {
    return (
      <>
        {!props?.hideEnglishLanguage && (
          <div className="flex items-center text-gray-700">
            <input
              id="english"
              name="language"
              type="radio"
              className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300"
              checked={
                (selectedLanguage && selectedLanguage !== 2) ||
                props.hideSpanishLanguage ||
                languageSetting === constant.LanguageSetting.EnglishOnly
              }
              onChange={() => {
                if (props.data.languageId === constant.Languages.Spanish) {
                  confirm({
                    variant: "danger",
                    title: "Please Confirm",
                    description:
                      "Changing your language setting will restart this activity from the beginning. Are you sure you want to restart?",
                    catchOnCancel: true,
                  })
                    .then((d) => {
                      setSelectedLanguage(1);
                    })
                    .catch((d) => {
                      setSelectedLanguage(2);
                    });
                } else {
                  setSelectedLanguage(1);
                }
              }}
            />
            <label
              className="ml-2 block text-sm text-gray-700"
              htmlFor="english"
            >
              English
            </label>
          </div>
        )}
        {!props?.hideSpanishLanguage &&
          languageSetting !== constant.LanguageSetting.EnglishOnly && (
            <div className="flex items-center">
              <input
                id="spanish"
                name="language"
                type="radio"
                className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300"
                defaultChecked={
                  (selectedLanguage && selectedLanguage === 2) ||
                  props.hideEnglishLanguage
                }
                checked={
                  (selectedLanguage && selectedLanguage === 2) ||
                  props.hideEnglishLanguage
                }
                onChange={() => {
                  if (props.data.languageId === 1) {
                    confirm({
                      variant: "danger",
                      title: "Please Confirm",
                      description:
                        "Changing your language setting will restart this activity from the beginning. Are you sure you want to restart?",
                      catchOnCancel: true,
                    })
                      .then((d) => {
                        setSelectedLanguage(2);
                      })
                      .catch((d) => {
                        setSelectedLanguage(1);
                      });
                  } else {
                    setSelectedLanguage(2);
                  }
                }}
              />
              <label
                className="ml-2 block text-sm text-gray-700"
                htmlFor="spanish"
              >
                Spanish
              </label>
            </div>
          )}
      </>
    );
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center bg-black bg-opacity-50 sm:items-center sm:justify-center">
      <div
        className="w-full flex flex-col justify-between overflow-hidden h-auto max-h-80percent bg-white  dark:bg-gray-800 rounded-lg sm:m-4 sm:max-w-5xl"
        role="dialog"
      >
        <header>
          <div className={`px-4 py-4 ${theme[themeName]?.header}`}>
            <div className="flex items-start justify-between space-x-3">
              <div className="space-y-1">
                <div className={` ${theme[themeName]?.headerTextColor}`}>
                  {t("BeginActivity.BeginActivity")}
                </div>
              </div>
            </div>
          </div>
        </header>

        <div className={`w-full relative overflow-auto px-6 py-6 `}>
          <div className="flex items-start justify-between space-x-6 w-full mb-6">
            <div
              className={`text-sm text-gray-700 font-light bg-[#00b1fb] bg-opacity-10 p-4 flex w-full rounded-md rounded-t-lg sm:rounded-lg overflow-hidden`}
            >
              <div className={`flex-shrink-0 current-fill text-[#00b1fb]`}>
                <svg
                  className="h-5 w-5"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </div>
              <div className="pl-2">
                <span className="font-semibold text-gray-700 pr-2 mb-1 inline-block">
                  {t("BeginActivity.Instructions")}
                </span>
                <br />
                <ul className=" list-disc	list-outside pl-3 space-y-1">
                  <li>{t("BeginActivity.Instructions_Row1")}</li>
                  <li>{t("BeginActivity.Instructions_Row4")}</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="flex justify-between mb-5">
            <div className="flex items-center">
              <h1
                className={`text-lg ${
                  theme[themeName]?.bodyText
                } border-gray-300  ${
                  !props.hideQuestionCount && props.name?.length > 0
                    ? " pr-3 mr-3 border-r-2"
                    : "pr-0 mr-0 border-r-0 "
                }`}
              >
                {props.name}
              </h1>

              {!props.hideQuestionCount && (
                <>
                  <span className="text-xs mr-2 text-gray-500">
                    {t("BeginActivity.QuestionCount")}
                  </span>
                  <span className="flex flex-none items-center justify-center bg-yellow-600 text-white text-xs rounded-full w-5 h-5">
                    {props.sessionActivityId
                      ? activityQuestionCount === -1
                        ? "NA"
                        : activityQuestionCount
                      : props.noOfQuestions}
                  </span>
                </>
              )}
            </div>
          </div>

          <div className="mb-4">
            <div
              className={`grid grid-cols-12 w-full text-sm text-gray-700 ${theme[themeName]?.table} bg-opacity-10 `}
            >
              {props.assignmentActivityName && (
                <div className="col-span-3 sm:col-span-2 font-semibold py-2 pl-3">
                  {t("BeginActivity.Activity")}
                </div>
              )}
              {props.assignmentActivityName && (
                <div className="col-span-9 sm:col-span-10 py-2 pl-2">
                  {props.assignmentActivityName}
                </div>
              )}
              <div className="col-span-3 sm:col-span-2 font-semibold py-2 pl-3">
                {t("BeginActivity.Language")}
              </div>
              <div className="col-span-9 sm:col-span-10 py-2 pl-2">
                {(props.ActivityLanguageLevel === "UserLevel" ||
                  props.ActivityLanguageLevel === "AssignmentLevel") && (
                  <div className="flex space-x-2">
                    {((languageSetting !==
                      constant.LanguageSetting.EnglishOnly &&
                      (props.programMode ?? constant.ProgramMode.EG) !==
                        constant.ProgramMode.LO) ||
                      (props.profile.loLanguageId ===
                        constant.Languages.Toggle &&
                        (props.programMode ?? constant.ProgramMode.EG) ===
                          constant.ProgramMode.LO)) &&
                      props.activity.indexOf("Canned") === -1 &&
                      !props.hideToggle && <>{getLanguageToggle()}</>}

                    {((languageSetting ===
                      constant.LanguageSetting.EnglishOnly &&
                      (props.programMode ?? constant.ProgramMode.EG) !==
                        constant.ProgramMode.LO) ||
                      (props.profile.loLanguageId !==
                        constant.Languages.Toggle &&
                        (props.programMode ?? constant.ProgramMode.EG) ===
                          constant.ProgramMode.LO) ||
                      props.activity.indexOf("Canned") !== -1 ||
                      (props.hideToggle ?? false)) && (
                      <label className="block text-sm text-gray-700">
                        {languageSetting ===
                          constant.LanguageSetting.EnglishOnly &&
                        (props.programMode ?? constant.ProgramMode.EG) !==
                          constant.ProgramMode.LO
                          ? "English"
                          : (props.profile.loLanguageId ===
                              constant.Languages.Spanish &&
                              (props.programMode ?? constant.ProgramMode.EG) ===
                                constant.ProgramMode.LO) ||
                            (props.data.languageId ===
                              constant.Languages.Spanish &&
                              props.activity.indexOf("Canned") === -1)
                          ? t("BeginActivity.Spanish")
                          : "English"}
                      </label>
                    )}
                  </div>
                )}
                {props.ActivityLanguageLevel === "ClassLevel" && (
                  <div className="flex space-x-2">
                    {props.data &&
                      props.data.classLanguageId ===
                        constant.Languages.Toggle &&
                      props.activity.indexOf("Canned") === -1 &&
                      !props.hideToggle && <>{getLanguageToggle()}</>}

                    {((props.data &&
                      props.data.classLanguageId !==
                        constant.Languages.Toggle) ||
                      props.activity.indexOf("Canned") !== -1 ||
                      (props.hideToggle ?? false)) && (
                      <label className="block text-sm text-gray-700">
                        {props.data.classLanguageId ===
                          constant.Languages.Spanish &&
                        props.activity.indexOf("Canned") === -1
                          ? "Spanish"
                          : "English"}
                      </label>
                    )}
                  </div>
                )}
              </div>
            </div>
            {!props.hideTeacher && (
              <div
                className={`grid grid-cols-12 w-full text-sm text-gray-700 ${theme[themeName]?.table} bg-opacity-10 `}
              >
                <div className="col-span-3 sm:col-span-2 font-semibold py-2 pl-3">
                  {t("BeginActivity.Teacher")}
                </div>
                <div className="col-span-9 sm:col-span-4 py-2 pl-2">
                  {teachers && teachers.length === 1 && (
                    <>
                      {" "}
                      {teachers[0].lastName}, {teachers[0].firstName}
                    </>
                  )}
                  {teachers && teachers.length > 1 && (
                    <>
                      <Select
                        value={selectedTeacher}
                        onChange={(e) => {
                          setSelectedTeacher(e.target.value);
                        }}
                        className="py-2.5 px-4 block text-sm border border-gray-400 
                      border-opacity-50 focus:ring-primary-violet focus:border-primary-violet form-select leading-5 p-1 rounded-lg"
                      >
                        {teachers.map((t) => {
                          return (
                            <Fragment key={t.userId}>
                              <option value={t.userId} key={t.userId}>
                                {t.lastName}, {t.firstName}
                              </option>
                            </Fragment>
                          );
                        })}
                      </Select>
                    </>
                  )}
                </div>
              </div>
            )}
            {!props.hideClass && (
              <div className="grid grid-cols-12 w-full text-sm text-gray-700 bg-white  ">
                <div className="col-span-3 sm:col-span-2 font-semibold py-2 pl-3">
                  {t("BeginActivity.Class")}
                </div>
                <div className="col-span-9 sm:col-span-4 py-2 pl-2">
                  {props.data.classes && props.data.classes.length === 1 && (
                    <> {props.data.classes[0].name}</>
                  )}
                  {props.data.classes && props.data.classes.length > 1 && (
                    <>
                      <Select
                        value={selectedClass}
                        onChange={(e) => {
                          setSelectedClass(e.target.value);
                        }}
                        className="py-2.5 px-4 block text-sm border border-gray-400 
                    border-opacity-50 focus:ring-primary-violet focus:border-primary-violet form-select leading-5 p-1 rounded-lg"
                      >
                        {props.data.classes.map((t) => {
                          return (
                            <Fragment key={t.classId}>
                              <option value={t.classId} key={t.classId}>
                                {t.name}
                              </option>
                            </Fragment>
                          );
                        })}
                      </Select>
                    </>
                  )}
                </div>
              </div>
            )}
            <div
              className={`grid grid-cols-12 w-full text-sm text-gray-700 ${theme[themeName]?.table} bg-opacity-10  `}
            >
              <div className="col-span-3 sm:col-span-2 font-semibold py-2 pl-3">
                {t("BeginActivity.ActivityType")}
              </div>
              <div className="col-span-9 sm:col-span-10 py-2 pl-2">
                {props.activity}
              </div>
              {props.code && (
                <div className="col-span-3 sm:col-span-2 font-semibold py-2 pl-3">
                  {t("BeginActivity.Code")}
                </div>
              )}
              {props.code && (
                <div className="col-span-9 sm:col-span-10 py-2 pl-2">
                  {props.code}
                </div>
              )}
            </div>
          </div>
        </div>
        <footer className="flex flex-col items-center justify-end px-6 py-4 sm:flex-row bg-gray-50 dark:bg-gray-800">
          <div className="flex-shrink-0">
            <div className="space-x-3 flex justify-end">
              <button
                onClick={() => onCancel()}
                className={`${theme[themeName]?.cancelButton}`}
              >
                {t("BeginActivity.Cancel")}
              </button>
              <button
                onClick={() => {
                  setOnBeginActivityClick(true);
                  props.onBegin(props.data, {
                    classId: selectedClass ? selectedClass : 0,
                    teacherId: selectedTeacher ? selectedTeacher : 0,
                    languageId: selectedLanguage,
                    timeInMinutes: 0,
                    contentAreaId: contentAreaId,
                  });
                }}
                className={classNames(`${theme[themeName]?.beginButton}`)}
                type="button"
              >
                {onBeginActivityClick === true && (
                  <svg
                    role="status"
                    className="inline w-4 h-4 mr-3 text-white animate-spin"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="#E5E7EB"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentColor"
                    />
                  </svg>
                )}
                {onBeginActivityClick && <>{t("BeginActivity.Loading")}...</>}
                {onBeginActivityClick === false && (
                  <>{t("BeginActivity.Begin")}</>
                )}
              </button>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
}

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(BeginActivity);

import {Droppable, Draggable} from "react-beautiful-dnd";
import {IAssessmentQuestion} from "../../../../model/interface/assessmentQuestion";
import AssessmentQuestionRowContent from "./AssessmentQuestionRowContent";
import {DragAndDropIcon} from "../../../../assets/icons";

interface PassageDraggableProps {
    index: number;
    subQuestions: Array<IAssessmentQuestion>
    type: string;
    openModal: ( learnosityReferenceId,
                 questionId,
                 questionText,
                 currentIndex,
                 standardId,
                 assessmentQuestionTypeId,
                 passageId,
                 spanishLearnosityReferenceId) => void;
    allowEdit: boolean;
    handleDeleteQuestion: (questionId: number) => void;
    districtId: number;
    hasDistrictAssessmentQuestionIcon: boolean;
};

function PassageDraggableItems(props: PassageDraggableProps) {

    return (
        <Droppable
            droppableId={props.type}
            type={`droppableSubQuestion-${props.type}`}
        >
            {(provided, snapshot) => (
                <div className={"w-full"}
                    ref={provided.innerRef}
                >
                    {props.subQuestions.map((question, index) => (
                        <Draggable key={question.questionId} draggableId={question.questionId.toString()} index={index}>
                            {(provided, snapshot) => (

                                    <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        className={ "ml-3 " + (
                                            index % 2 === 0
                                                ? "bg-white"
                                                : "bg-gray-50")
                                        }
                                    >
                                        <div className="w-full flex items-center">
                                            <div
                                                {...provided.dragHandleProps}
                                                className="pr-3 py-2 w-5 flex-none"
                                            >
                                                <DragAndDropIcon
                                                    title="Drag and drop question"
                                                    className="w-6 h-6"
                                                ></DragAndDropIcon>
                                            </div>
                                            <AssessmentQuestionRowContent
                                                isGroup={true}
                                                index={index}
                                                question={question}
                                                openModal={props.openModal}
                                                allowEdit={props.allowEdit}
                                                handleDeleteQuestion={props.handleDeleteQuestion}
                                                districtId={props.districtId}
                                                hasDistrictAssessmentQuestionIcon={props.hasDistrictAssessmentQuestionIcon}
                                            />
                                        </div>
                                </div>
                            )}
                        </Draggable>
                    ))}
                    {provided.placeholder}
                </div>
            )}
        </Droppable>
    );

};

export default PassageDraggableItems;
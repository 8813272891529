import { XIcon } from "@heroicons/react/outline";
import moment from "moment";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { AddSubscriptionRequest } from "../../../../api/account/accountApi";
import Profile from "../../../../model/users/profile";
import { UserContextState } from "../../../../redux/actions/userContextAction";
import { SubscriptionRequestType } from "../../../../types/type";

interface ISubscriptionRequest {
  showPopUp: (show: boolean) => void;
  subjects: Array<any>;
  subjectIds: number[];
  requestType: SubscriptionRequestType;
  title: string;
  text: string;
  okButtonText: string;
  showCSM: boolean;
  userContext?: UserContextState;
  profile?: Profile;
  schoolId: number | undefined;
}
function SubscriptionRequest(props: ISubscriptionRequest) {
  const [requestText, setRequestText] = useState("");
  useEffect(() => {
    var text = props.text;
    var index: number = 1;
    props.subjects.forEach((sub) => {
      text = text + index.toString() + ". " + sub + "\n";
      index++;
    });
    setRequestText(text);
  }, [props.subjects]);

  function sendSubscriptionRequest() {
    AddSubscriptionRequest({
      cSMName: "",
      requestorUserId: props.userContext?.userId ?? 0,
      requestText: requestText,
      subscriptionRequestTypeId: props.requestType,
      subjectIds: props.subjectIds,
      schoolId: props.schoolId,
    }).then((d) => {
      toast.info("Subscription renewal request sent successfully");
      props.showPopUp(false);
    });
  }

  function getRequestor() {
    const accountIdText =
      props.userContext && props.userContext.accountId
        ? `- ${props.userContext.accountId}`
        : "";
    return `${props.profile?.lastName}, ${props.profile?.firstName} ${accountIdText}`;
  }

  return (
    <div className="fixed inset-0 z-40 flex items-center bg-black bg-opacity-50 sm:items-center sm:justify-center">
      <div
        className="w-full flex flex-col justify-between overflow-hidden h-auto max-h-80percent bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:max-w-2xl"
        role="dialog"
      >
        <header>
          {/* Header */}
          <div className="px-4 py-4 bg-gray-50">
            <div className="flex items-start justify-between space-x-3">
              <div className="space-y-1">
                <div className="text-lg font-medium text-gray-900">
                  {props.title}
                </div>
              </div>
              <div className="h-7 flex items-center">
                <button
                  type="button"
                  className="text-gray-400 hover:text-gray-500"
                  onClick={() => props.showPopUp(false)}
                >
                  <span className="sr-only">Close panel</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </header>

        <div className={`w-full relative overflow-auto px-4 py-3`}>
          {/* <!-- Divider container -->  */}
          <div className="p-3 sm:py-0 sm:space-y-0 sm:divide-y sm:divide-gray-200 text-sm text-gray-700 dark:text-gray-400">
            <div>
              <div className="mb-4">
                <div className="grid grid-cols-1 gap-6 flex items-end mt-3">
                  {props.showCSM && (
                    <div className="col-span-12 sm:col-span-4 xl:col-span-3">
                      <label className="block text-sm text-gray-500">
                        CSM Name
                      </label>
                      <input
                        type="text"
                        className="bg-gray-100 py-1.5 px-3 block w-full shadow-sm focus:ring-primary-violet focus:border-primary-violet border border-gray-300 rounded-md"
                        disabled={true}
                      />
                    </div>
                  )}

                  <div className="col-span-12 sm:col-span-4 xl:col-span-3">
                    <label className="block text-sm text-gray-500">
                      Requestor
                    </label>
                    <input
                      type="text"
                      className="bg-gray-100  py-1.5 px-3 block w-full shadow-sm focus:ring-primary-violet focus:border-primary-violet border border-gray-300 rounded-md"
                      disabled={true}
                      value={getRequestor()}
                    />
                  </div>
                  <div className="col-span-12 sm:col-span-4 xl:col-span-3">
                    <label className="block text-sm text-gray-500">Date</label>
                    <input
                      type="text"
                      className="bg-gray-100 py-1.5 px-3 block w-full shadow-sm focus:ring-primary-violet focus:border-primary-violet border border-gray-300 rounded-md"
                      disabled={true}
                      value={moment(new Date()).format("MM/DD/yyyy")}
                    />
                  </div>
                  <div className="col-span-12 sm:col-span-4 xl:col-span-3">
                    <label className="block text-sm text-gray-500">Text</label>
                    <textarea
                      rows={5}
                      className="py-1.5 px-3 block w-full shadow-sm focus:ring-primary-violet focus:border-primary-violet border border-gray-300 rounded-md"
                      value={requestText}
                      onChange={(e) => {
                        setRequestText(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Action Buttons -->  */}
        <footer className="flex flex-col items-center justify-end px-6 py-4 sm:flex-row bg-gray-50 dark:bg-gray-800">
          <div className="flex-shrink-0">
            <div className="space-x-3 flex justify-end">
              <button
                className="disabled:opacity-50 align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 rounded-lg text-sm text-white border border-transparent bg-primary-blue focus:shadow-outline-purple w-full sm:w-auto"
                type="button"
                onClick={sendSubscriptionRequest}
              >
                {" "}
                {props.okButtonText}
              </button>
              <button
                className="align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 rounded-lg text-sm text-gray-600 border-gray-300 border dark:text-gray-400 focus:outline-none active:bg-transparent hover:border-gray-500 focus:border-gray-500 active:text-gray-500 focus:shadow-outline-gray w-full sm:w-auto"
                type="button"
                onClick={() => {
                  props.showPopUp(false);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
}

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(SubscriptionRequest);

import classNames from "classnames";
import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useTranslation } from "react-i18next";
import { GoldCoinIcon } from "../../../../../../assets/icons";
import { getScoreLabel } from "../../../../../../utils/scoreHelper";

interface IOverviewResult {
  data: any;
}

function OverviewResult(props: IOverviewResult) {
  const [studyPlanResult, setStudyPlanResult] = useState<any>();
  const { t } = useTranslation();
  const getRingColor = (value) => {
    let ringColor = "";
    switch (true) {
      case value < 63:
        ringColor = "#E53F71";
        break;
      case value >= 63 && value < 80:
        ringColor = "#FFDB3A";
        break;
      case value >= 80:
        ringColor = "#B2D136";
        break;
      default:
        ringColor = "bg-gray-700";
        break;
    }
    return ringColor;
  };

  function overview() {
    if (props.data) {
      const overviewResultInput = {
        results_header: {
          assignment: {
            result: {
              retry_percent:
                props.data.retryScore === null
                  ? null
                  : Math.round(
                      (Number(props.data.totalScoreWithRetry) /
                        Number(props.data.totalAttempted)) *
                        100
                    ),
              percent:
                Number(props.data.totalAttempted) === 0
                  ? 0
                  : Math.round(
                      (Number(props.data.totalScore) /
                        Number(props.data.totalAttempted)) *
                        100
                    ),
              totalAttempted: props.data.totalAttempted,
              totalScore: props.data.totalScore,
              retryScore: props.data.retryScore,
              totalScoreWithRetry: props.data.totalScoreWithRetry,
            },
          },
          elapsed_time: props.data.sessionDuration,
          date_completed: props.data.dateCompleted,
          earnedStar: props.data.earnedStar,
          earnedToken: props.data.earnedToken,
          test: {
            name:
              props.data.subjectName ??
              props.data.domainName ??
              props.data.standardName,
          },
          teacher: {
            first: props.data.teacherFirstName,
            last: props.data.teacherLastName,
          },
          student: {
            first: props.data.studentFirstName
              ? props.data.studentFirstName
              : "",
            last: props.data.studentLastName ? props.data.studentLastName : "",
          },
          class: {
            name: props.data.class,
          },
          game: { name: props.data.gameName, score: props.data.gameScore },
        },
      };
      setStudyPlanResult(overviewResultInput);
    }
  }
  useEffect(() => {
    overview();
  }, []);

  function needsGrading(): boolean {
    return (
      studyPlanResult?.results_header?.assignment?.result?.unmarked
    );
  }

  return (
    <>
      {studyPlanResult && (
        <div className="flex-none md:w-64 h-auto bg-blues rounded-md shadow-md md:mr-6 xl:mr-12 xxl:mr-6 xxxl:mr-12 shadow-lg">
          <div className="text-md text-white text-center px-12 md:px-5 ">
            <h2 className="text-lg text-white font-medium uppercase text-center my-4">
              {t("StudyPlanResult.Score")}
            </h2>
            {needsGrading() ? (
              <div
                style={{
                  width: 160,
                  height: 160,
                  display: "inline-grid",
                  fontSize: "17px;",
                }}
              >
                <CircularProgressbar
                  value={0}
                  text={`${studyPlanResult?.results_header?.assignment?.result?.percent}`}
                  styles={buildStyles({
                    pathColor: `${getRingColor(
                      studyPlanResult?.results_header?.assignment?.result
                        ?.percent
                    )}`,
                    textColor: "#ffffff",
                  })}
                />
              </div>
            ) : (
              <div
                style={{
                  width: 140,
                  height: 140,
                  display: "inline-grid",
                  position: "relative",
                }}
              >
                <CircularProgressbar
                  value={
                    studyPlanResult?.results_header?.assignment?.result?.percent
                  }
                  text={`${studyPlanResult?.results_header?.assignment?.result?.percent}%`}
                  styles={buildStyles({
                    pathColor: `${getRingColor(
                      studyPlanResult?.results_header?.assignment?.result
                        ?.percent
                    )}`,
                    textColor: "#ffffff",
                  })}
                  className="circular-bar-pending-size"
                />
                <span className="absolute top-1/2 left-1/2 transform -translate-x-2/4 -translate-y-2/4 pt-11 text-white">
                  (
                  {
                    getScoreLabel(
                      studyPlanResult?.results_header?.assignment?.result
                        ?.totalScore)
                  }
                  /
                  {
                    studyPlanResult?.results_header?.assignment?.result
                      ?.totalAttempted
                  }
                  )
                </span>
              </div>
            )}
          </div>
          {<div className="w-full text-lg text-white text-center mb-4"></div>}

          <div className="flex flex-none flex-col bg-white px-7 py-4 mx-4 rounded-md mb-8 mt-10 shadow-lg">
            <div className="flex flex-col w-full items-center ">
              <div className="capitalize font-myriad font-semibold text-lg text-gray-700 pb-2">
                {t("StudyPlanResult.TokenEarned")}
              </div>
              <div className="relative w-24 inline-block pb-10 text-center">
                {studyPlanResult?.results_header?.earnedToken > 0 &&
                  Array.from(
                    Array(studyPlanResult?.results_header?.earnedToken),
                    (e, i) => {
                      return (
                        <Fragment key={i}>
                          <span
                            className={classNames(
                              "inline-block absolute ",
                              i === 0 ? "left-0" : "",
                              i === 1 ? "left-4" : "",
                              i === 2 ? "left-8" : "",
                              i === 3 ? "left-12" : ""
                            )}
                          >
                            <GoldCoinIcon className="w-9 h-9"></GoldCoinIcon>
                          </span>{" "}
                        </Fragment>
                      );
                    }
                  )}
                {studyPlanResult?.results_header?.earnedToken === 0 && (
                  <span className="text-center text-sm">
                    {t("StudyPlanResult.NoTokenEarned")}
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="mt-10">
            {studyPlanResult?.results_header?.assignment?.result?.retryScore !==
              null && (
              <div className="flex flex-col flex-none mx-7 text-white border-white border-b border-opacity-25 pb-2 mb-2">
                <div className="text-xs text-white capitalize">
                  {t("AssignmentResult.RetryScore")}
                </div>
                <div className="text-white font-roboto font-bold capitalize">
                  {
                    studyPlanResult?.results_header?.assignment?.result
                      ?.retry_percent
                  }
                  %{" "}
                  <>
                    (
                    {getScoreLabel(
                      studyPlanResult?.results_header?.assignment?.result
                        ?.totalScoreWithRetry)}
                    /
                    {
                      studyPlanResult?.results_header?.assignment?.result
                        ?.totalAttempted
                    }
                    )
                  </>
                </div>
              </div>
            )}
            <div className="flex flex-col flex-none mx-7 text-white border-white border-b border-opacity-25 pb-2 mb-2">
              <div className="text-xs text-white capitalize">
                {t("StudyPlanResult.DateCompleted")}
              </div>
              <div className="text-white font-roboto font-bold capitalize">
                {studyPlanResult?.results_header?.date_completed
                  ? moment(
                      studyPlanResult?.results_header?.date_completed
                    ).format("MM/DD/YYYY")
                  : ""}
              </div>
            </div>
            <div className="flex flex-col flex-none mx-7 text-white border-white border-b border-opacity-25 pb-1 mb-3">
              <div className="text-xs text-white capitalize">
                {t("StudyPlanResult.SessionTime")}
              </div>
              <div className="text-white font-roboto font-bold">
                {moment()
                  .startOf("day")
                  .add(studyPlanResult?.results_header?.elapsed_time, "seconds")
                  .format("HH:mm:ss")}
              </div>
            </div>
            <div className="flex flex-col flex-none mx-7 text-white border-white border-b border-opacity-25 pb-1 mb-3">
              <div className="text-xs text-white capitalize">
                {t("StudyPlanResult.Subject")}
              </div>
              <div className="text-white text-md font-roboto font-bold capitalize">
                {studyPlanResult?.results_header?.test
                  ? `${studyPlanResult?.results_header?.test.name}`
                  : "-"}{" "}
              </div>
            </div>
            <div className="flex flex-col flex-none mx-7 text-white border-white border-b border-opacity-25 pb-2 mb-3">
              <div className="text-xs text-white capitalize">
                {" "}
                {t("StudyPlanResult.StudentName")}
              </div>
              <div className="text-white text-md font-roboto font-bold capitalize">
                {Object.keys(studyPlanResult?.results_header?.student).length >
                  0 &&
                  `${studyPlanResult?.results_header?.student?.first} ${studyPlanResult?.results_header?.student?.last}`}
              </div>
            </div>

            {studyPlanResult?.results_header?.game.name !== null &&
              studyPlanResult?.results_header?.game.name !== undefined &&
              studyPlanResult?.results_header?.game.name !== "" && (
                <>
                  <div className="flex flex-col flex-none mx-7 text-white border-white border-b border-opacity-25 pb-2 mb-3">
                    <div className="text-xs text-light-teal capitalize">
                      {t("StudyPlanResult.GameName")}
                    </div>
                    <div className="text-light-teal text-md font-roboto font-bold capitalize">
                      {studyPlanResult?.results_header?.game
                        ? `${studyPlanResult?.results_header?.game.name}`
                        : "-"}{" "}
                    </div>
                  </div>
                  <div className="flex flex-col flex-none mx-7 text-white border-white border-b border-opacity-25 pb-2 mb-3">
                    <div className="text-xs text-light-teal capitalize">
                      {t("StudyPlanResult.GameScore")}
                    </div>
                    <div className="text-light-teal text-md font-roboto font-bold capitalize">
                      {studyPlanResult?.results_header?.game
                        ? `${studyPlanResult?.results_header?.game.score}`
                        : "-"}{" "}
                    </div>
                  </div>
                </>
              )}
          </div>
        </div>
      )}
    </>
  );
}
export default OverviewResult;

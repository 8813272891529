import { Fragment } from "react";
import PageSize from "../../../../../shared/pagination/pageSize";
import Pagination from "../../../../../shared/pagination/pagination";
import moment from "moment";
import routeConstant from "../../../../../../utils/constant/routeConstant";
import { getScoreLabel } from "../../../../../../utils/scoreHelper";
import constant from "../../../../../../utils/constant/constant";
import SortTableHeader from "../../../../../shared/sortTableHeader";
import { isAllowViewGradeBookClassResult } from "../../../../../../utils/assessmentHelper";

export default function GradeBookByClassResult(props: any) {
  const gradeBook = props?.data?.data;
  const totalRecords = props?.data?.totalRecords;

  const handleStudentResultViewClick = (item: any) => {
    let pathname = "";
    if (
      item.type === constant.Report_StudentActivityType.ASSIGNMENT ||
      item.type === constant.Report_StudentActivityType.ASSESSMENT
    ) {
      pathname = routeConstant.TeacherRoutes.StudentAssignmentResult.replace(
        ":assignmentId",
        item.assignmentId ?? 0
      )
        .replace(
          ":assignmentActivityId",
          item.type === constant.Report_StudentActivityType.ASSESSMENT
            ? item.assessmentId
            : item.assignmentActivityId
        )
        .replace(":userId", item.studentUserId)
        .replace(":gradeId", item.studentGradeId)
        .replace(":sessionId?", item.sessionId);
    } else {
      pathname = routeConstant.TeacherRoutes.StudentPracticeResult.replace(
        ":sessionId",
        item.sessionId
      )
        .replace(":userId", item.studentUserId)
        .replace(":activityType", item.type)
        .replace(":gradeId", item.studentGradeId);
    }
    window.open(pathname, "_blank");
  };

  const formatDuration = (durationInSeconds: number): string => {
    var date = new Date(0);
    date.setSeconds(durationInSeconds); // specify value for SECONDS here
    var timeString = date.toISOString().substring(11, 19);
    return timeString;
  };

  return (
    <div>
      <div className="mb-4 overflow-x-auto xl:overflow-x-visible shadow">
        <table className="table w-full ">
          <thead className="sticky top-0">
            <tr className="bg-primary-violet  text-white ">
              <th className="text-center p-3 text-sm font-normal whitespace-nowrap">
                <div className="w-36 flex justify-center">
                  <SortTableHeader
                    data={[]}
                    columnName="completionDate"
                    headerComponent="Completion Date"
                    orderType={props.orderType}
                    activeSortColumn={props.activeSortColumn}
                    sortRecords={props.handleSortRecords}
                  />
                </div>
              </th>
              <th className="text-center p-3 text-sm font-normal">Duration</th>
              <th className="text-left p-3 text-sm font-normal">
                <SortTableHeader
                  data={[]}
                  columnName="student"
                  headerComponent="Student"
                  orderType={props.orderType}
                  activeSortColumn={props.activeSortColumn}
                  sortRecords={props.handleSortRecords}
                />
              </th>
              <th className="text-left p-3 text-sm font-normal whitespace-nowrap">
                <div className="w-28 flex justify-center">
                  <SortTableHeader
                    data={[]}
                    columnName="studentId"
                    headerComponent="Student ID"
                    orderType={props.orderType}
                    activeSortColumn={props.activeSortColumn}
                    sortRecords={props.handleSortRecords}
                  />
                </div>
              </th>
              <th className="text-left p-3 text-sm font-normal  ">
                <div className="w-20 flex">
                  <SortTableHeader
                    data={[]}
                    columnName="subject"
                    headerComponent="Subject"
                    orderType={props.orderType}
                    activeSortColumn={props.activeSortColumn}
                    sortRecords={props.handleSortRecords}
                  />
                </div>
              </th>
              <th className="text-left p-3 text-sm font-normal">
                <SortTableHeader
                  data={[]}
                  columnName="assignment"
                  headerComponent="Assignment"
                  orderType={props.orderType}
                  activeSortColumn={props.activeSortColumn}
                  sortRecords={props.handleSortRecords}
                />
              </th>
              <th className="text-left p-3 text-sm font-normal">
                <SortTableHeader
                  data={[]}
                  columnName="activity"
                  headerComponent="Activity"
                  orderType={props.orderType}
                  activeSortColumn={props.activeSortColumn}
                  sortRecords={props.handleSortRecords}
                />
              </th>
              <th className="text-center p-3 text-sm font-normal">
                <div className="flex justify-center">
                  <SortTableHeader
                    data={[]}
                    columnName="score"
                    headerComponent="Score"
                    orderType={props.orderType}
                    activeSortColumn={props.activeSortColumn}
                    sortRecords={props.handleSortRecords}
                  />
                </div>
              </th>
              <th className="text-left p-3 text-sm font-normal"></th>
            </tr>
          </thead>
          <tbody>
            {gradeBook &&
              gradeBook.length > 0 &&
              gradeBook?.map((classItem, index) => {
                return (
                  <Fragment>
                    <tr
                      className={`hover:bg-primary-violet/20 transition ease-in-out duration-300 ${
                        index % 2 === 0 ? "bg-white" : "bg-primary-violet/10"
                      }`}
                    >
                      <td className="text-sm p-3 text-center">
                        {classItem.dateCompleted
                          ? moment(classItem.dateCompleted).format("MM/DD/YYYY")
                          : "N/A"}
                      </td>
                      <td className="text-sm p-3">
                        {classItem.durationInSeconds
                          ? formatDuration(classItem.durationInSeconds)
                          : "N/A"}
                      </td>
                      <td className="text-sm p-3 ">
                        {classItem.studentLastName},{" "}
                        {classItem.studentFirstName}
                      </td>
                      <td className="text-center text-sm p-3 ">
                        {!classItem.studentId || classItem.studentId === ""
                          ? "-"
                          : classItem.studentId}
                      </td>
                      <td className="text-sm p-3 ">{classItem.subject}</td>
                      <td className="text-sm p-3 ">
                        {classItem.assignmentName}
                      </td>
                      <td className="text-sm p-3 ">
                        {classItem.activityTitle}
                      </td>
                      <td className="text-sm text-center p-3">
                        <div className="flex flex-col items-center">
                          <div className="flex gap-x-4 font-bold px-1">
                            <div>
                              {classItem.questionsUngraded > 0
                                ? "NG"
                                : getScoreLabel(classItem.pointsEarned) +
                                  "/" +
                                  getScoreLabel(classItem.pointsPossible)}
                            </div>
                            <div>
                              {classItem.questionsUngraded > 0
                                ? "NG"
                                : (classItem.pointsPossible > 0
                                    ? (
                                        (classItem.pointsEarned /
                                          classItem.pointsPossible) *
                                        100
                                      ).toFixed()
                                    : 0) + "%"}
                            </div>
                          </div>
                          {classItem.retryPointsEarned !== null && (
                            <>
                              <div className="italic font-normal text-center w-full text-teal-light">
                                Retry
                              </div>
                              <div className="flex gap-x-4 italic text-teal-light border border-gray-400 rounded-lg px-1">
                                <div>
                                  {classItem.questionsUngraded > 0
                                    ? "NG"
                                    : "(" +
                                      getScoreLabel(
                                        classItem.correctPointsEarned
                                      ).toString() +
                                      "/" +
                                      getScoreLabel(classItem.pointsPossible) +
                                      ")"}
                                </div>
                                <div>
                                  {classItem.questionsUngraded > 0
                                    ? "NG"
                                    : (classItem.pointsPossible > 0
                                        ? (
                                            (classItem.correctPointsEarned /
                                              classItem.pointsPossible) *
                                            100
                                          ).toFixed()
                                        : 0) + "%"}
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </td>

                      <td className="text-sm p-3 ">
                        {isAllowViewGradeBookClassResult(
                          props.userRoleId,
                          classItem.canViewAssessment,
                          classItem.assessmentCreatedByUserId,
                          props.userId,
                          classItem.assessmentSharedTypeId
                        ) && (
                          <button
                            className="text-blue-500 hover:underline"
                            onClick={() =>
                              handleStudentResultViewClick(classItem)
                            }
                          >
                            View{" "}
                          </button>
                        )}
                        {!isAllowViewGradeBookClassResult(
                          props.userRoleId,
                          classItem.canViewAssessment,
                          classItem.assessmentCreatedByUserId,
                          props.userId,
                          classItem.assessmentSharedTypeId
                        ) && ""}
                      </td>
                    </tr>
                  </Fragment>
                );
              })}
            {gradeBook?.length === 0 && (
              <tr className="bg-white">
                <td colSpan={6} className="text-sm p-3">
                  No records found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div
        className={`${props.pageNumber} hidden sm:flex-1 sm:flex sm:items-center sm:justify-between border-t-2 pt-4 mt-4`}
      >
        {gradeBook?.length !== 0 && (
          <Pagination
            totalRecords={totalRecords}
            pageSize={props.pageSize}
            onPageChange={props.onPageChange}
            reset={props.resetPageNumber}
            bgColor="primary-violet"
            pageNumber={props.pageNumber}
          />
        )}
        {gradeBook?.length !== 0 && (
          <PageSize
            pageSize={props.pageSize}
            onPageSizeChange={props.onPageSizeChange}
            bgColor="primary-violet"
          />
        )}
      </div>
    </div>
  );
}

import React from "react";
import { CompletionInfo } from "./interface";

const AssignmentVideoIcon: React.FunctionComponent<CompletionInfo> = ({
  is_saved,
  past_due,
}: CompletionInfo) => {
  return (
    <div
      className={`flex-shrink-0 cursor-pointer h-12 w-12 bg-blues ${
        is_saved === 1 ? "bg-site-orange" : ""
      } ${
        past_due === 1 ? "bg-site-pink" : ""
      }  hover:opacity-90 rounded-full flex items-center justify-center`}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="w-7 h-7 stroke-current text-white"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z"
        ></path>
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
        ></path>
      </svg>
    </div>
  );
};

export default AssignmentVideoIcon;

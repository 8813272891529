import { Fragment, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import { useHistory } from "react-router-dom";
import classNames from "classnames";
import { DotsVerticalIcon } from "@heroicons/react/solid";
import {
  deleteAlienArena,
  copyAlienArenaSession,
} from "../../../../api/teacher/alienArena";
import ConfirmationDialog from "../../../shared/confirmationDialog";
import CopySessionConfirmationDialog from "../../../shared/copySessionConfirmationDialog";
import { toast } from "react-toastify";
import {
  Info,
  EditIcon,
  DeleteIcon,
  PlayIcon,
  Copy,
  LaunchIcon,
} from "../../../../assets/icons";
import RouteConstant from "../../../../utils/constant/routeConstant";
import { IAlienArenaSession } from "../../../../model/interface/alienArenaSessions";
import SessionModal from "./sessionModal";
import Permissions from "../../../../utils/permissions";
import AllowedTo from "../../../shared/rbac";

interface AlienArenaSessionsContextMenuProp {
  isFirst: string;
  isLast: string;
  sessionId: string;
  sessionName: string;
  alienArenaId: number;
  status: string;
  refreshSessions: () => void;
  session: IAlienArenaSession;
}
function AlienArenaSessionsContextMenu(
  props: AlienArenaSessionsContextMenuProp
) {
  const history = useHistory();
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [confirmationTitle, setConfirmationTitle] = useState("Confirm Action");
  const [confirmationMessage, setConfirmationMessage] =
    useState("Please confirm");
  const [openCopyConfirmation, setCopyOpenConfirmation] = useState(false);
  const [copyConfirmationTitle, setCopyConfirmationTitle] =
    useState("Confirm Action");
  const [copyConfirmationMessage, setCopyConfirmationMessage] =
    useState("Please confirm");
  const [sessionNameToCopy, setSessionNameToCopy] = useState("");
  const [selectedSession, setSelectedSession] = useState<IAlienArenaSession>();
  const [showSessionModal, setShowSessionModal] = useState(false);

  const handleDelete = () => {
    showConfirmation(
      "Please Confirm",
      "Are you sure you want to delete session?"
    );
  };
  function showConfirmation(title: string, message: string) {
    setOpenConfirmation(true);
    setConfirmationTitle(title);
    setConfirmationMessage(message);
  }
  function confirmationCancelClose() {
    setOpenConfirmation(false);
  }
  function confirmationOkClose() {
    deleteSession();
  }

  const handleCopySession = () => {
    showCopyConfirmation(
      "Please Confirm",
      `Are you sure you want to copy (${props.sessionName}) session?`
    );
  };
  function showCopyConfirmation(title: string, message: string) {
    setCopyOpenConfirmation(true);
    setCopyConfirmationTitle(title);
    setCopyConfirmationMessage(message);
  }
  function copyConfirmationCancelClose() {
    setCopyOpenConfirmation(false);
  }
  function copyConfirmationOkClose() {
    copySession();
  }

  function deleteSession() {
    deleteAlienArena(props.sessionId).then(() => {
      confirmationCancelClose();
      toast.success("Session deleted successfully");
      props.refreshSessions();
    });
  }

  function copySession() {
    copyAlienArenaSession(props.alienArenaId, sessionNameToCopy).then(() => {
      copyConfirmationCancelClose();
      toast.success("Session copied successfully");
      props.refreshSessions();
    });
  }

  const editAlienArena = () => {
    if (props.alienArenaId) {
      history.push(
        RouteConstant.TeacherRoutes.AlienArenaDetails.replace(
          ":alienArenaId",
          props.alienArenaId.toString()
        )
      );
    }
  };

  const handleInfoClick = (session: IAlienArenaSession) => {
    setShowSessionModal(true);
    setSelectedSession(session);
  };
  return (
    <Fragment>
      {showSessionModal && (
        <SessionModal
          alienArenaId={selectedSession?.alienArenaId}
          showSessionModal={showSessionModal}
          setShowSessionModal={setShowSessionModal}
        ></SessionModal>
      )}
      <ConfirmationDialog
        open={openConfirmation}
        confirmationTitle={confirmationTitle}
        confirmationMessage={confirmationMessage}
        onCancelClick={confirmationCancelClose}
        onOkClick={confirmationOkClose}
      ></ConfirmationDialog>

      <CopySessionConfirmationDialog
        open={openCopyConfirmation}
        confirmationTitle={copyConfirmationTitle}
        confirmationMessage={copyConfirmationMessage}
        onCancelClick={copyConfirmationCancelClose}
        onOkClick={copyConfirmationOkClose}
        setSessionNameToCopy={setSessionNameToCopy}
      ></CopySessionConfirmationDialog>

      <Menu as="div" className="relative flex items-center">
        <Menu.Button className="bg-transparent inline-flex items-center justify-center text-gray-400 rounded-full hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bg-primary-color">
          <span className="sr-only">Open options</span>
          <DotsVerticalIcon className="w-5 h-5" aria-hidden="true" />
        </Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="mx-3 transform -translate-y-full origin-top-right absolute right-3 top-5 w-48 mt-1 rounded-md shadow-lg z-10 bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none">
            <div className="py-1">
              <Menu.Item>
                {() => (
                  <button
                    onClick={() => {
                      handleInfoClick(props.session);
                    }}
                    className={classNames(
                      props.isFirst === "true"
                        ? "bg-white hover:bg-gray-100 text-gray-500 hover:text-blue-500 group"
                        : "text-gray-700 cursor-pointer",
                      "group flex items-center justify-start px-4 py-2 text-sm w-full"
                    )}
                  >
                    <span className="w-5 flex justify-center mr-0.5">
                      <Info className="text-gray-500 w-4 group-hover:text-blue-500" />
                    </span>
                    Info
                  </button>
                )}
              </Menu.Item>
              <AllowedTo perform={Permissions.alien_arena_setup_modify}>
                {props.status === "New" && (
                  <Menu.Item>
                    {() => (
                      <button
                        onClick={() => {
                          editAlienArena();
                        }}
                        className={classNames(
                          props.isLast === "true"
                            ? "bg-white hover:bg-gray-100 text-gray-500 hover:text-blue-500 group"
                            : "text-gray-700 cursor-pointer",
                          "group flex items-center justify-start px-4 py-2 text-sm w-full"
                        )}
                      >
                        <span className="w-5 flex justify-center  mr-0.5">
                          <EditIcon className="text-gray-500 w-3 group-hover:text-blue-500" />
                        </span>
                        Edit
                      </button>
                    )}
                  </Menu.Item>
                )}
              </AllowedTo>

              <AllowedTo perform={Permissions.alien_arena_setup_modify}>
                <Menu.Item>
                  {() => (
                    <button
                      onClick={() => {
                        handleCopySession();
                      }}
                      className={classNames(
                        props.isLast === "true"
                          ? "bg-white hover:bg-gray-100 text-gray-500 hover:text-blue-500 group"
                          : "text-gray-700 cursor-pointer bg-white hover:bg-gray-100  hover:text-blue-500 group",
                        "group flex items-center px-4 py-2 justify-start text-sm  w-full"
                      )}
                    >
                      <span className="w-5 flex justify-center  mr-0.5">
                        <Copy className="text-gray-500 w-3 stroke-current group-hover:text-blue-500" />
                      </span>
                      Copy
                    </button>
                  )}
                </Menu.Item>
              </AllowedTo>

              {(props.status === "New" || props.status === "Ready") && (
                <Menu.Item>
                  {() => (
                    <button
                      className={classNames(
                        props.isLast === "true"
                          ? "bg-white hover:bg-gray-100 text-gray-500 hover:text-blue-500 group"
                          : "text-gray-700 cursor-pointer bg-white hover:bg-gray-100  hover:text-blue-500 group",
                        "group flex items-center px-4 py-2 justify-start text-sm  w-full"
                      )}
                    >
                      <span className="w-5 flex justify-center  mr-0.5">
                        <LaunchIcon className="text-gray-500 w-5 fill-current group-hover:text-blue-500" />
                      </span>
                      Launch
                    </button>
                  )}
                </Menu.Item>
              )}
              {props.status === "InProgress" && (
                <Menu.Item>
                  {() => (
                    <button
                      className={classNames(
                        props.isLast === "true"
                          ? "bg-white hover:bg-gray-100 text-gray-500 hover:text-blue-500 group"
                          : "text-gray-700 cursor-pointer bg-white hover:bg-gray-100  hover:text-blue-500 group",
                        "group flex items-center px-4 py-2 justify-start text-sm  w-full"
                      )}
                    >
                      <span className="w-5 flex justify-center  mr-0.5">
                        <PlayIcon className="text-gray-500 w-3 group-hover:text-blue-500" />
                      </span>
                      Resume
                    </button>
                  )}
                </Menu.Item>
              )}

              <AllowedTo perform={Permissions.alien_arena_setup_delete}>
                <Menu.Item>
                  {() => (
                    <button
                      onClick={() => {
                        handleDelete();
                      }}
                      className={classNames(
                        props.isLast === "true"
                          ? "bg-white hover:bg-gray-100 text-gray-500 hover:text-blue-500 group"
                          : "text-gray-700 cursor-pointer bg-white hover:bg-gray-100  hover:text-blue-500 group",
                        "group flex items-center px-4 py-2 justify-start text-sm  w-full"
                      )}
                    >
                      <span className="w-5 flex justify-center mr-0.5">
                        <DeleteIcon className="text-gray-500 w-3 group-hover:text-blue-500" />
                      </span>
                      Delete
                    </button>
                  )}
                </Menu.Item>
              </AllowedTo>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </Fragment>
  );
}
export default AlienArenaSessionsContextMenu;

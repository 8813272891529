import { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { ClassApi } from "../../../../../api/teacher/classManagementApi";
import { RemoveIcon } from "../../../../../assets/icons/index";
import { PageAccessMode } from "../../../../../model/common/pageAccessMode";
import PagingResponse from "../../../../../model/common/pagingResponse";
import ClassSubjectModel from "../../../../../model/teacher/classManagement/classSubjectModel";
import { UserContextState } from "../../../../../redux/actions/userContextAction";
import {
  DEFAULT_PAGE_NUMBER,
  DEFAULT_PAGE_SIZE,
} from "../../../../../utils/pagingConstant";
import ConfirmationDialog from "../../../../shared/confirmationDialog";
import Loader from "../../../../shared/loader";
import PageSize from "../../../../shared/pagination/pageSize";
import Pagination from "../../../../shared/pagination/pagination";
import SubjectPopup from "./popups/subjectPopup";
import { syncTeacherClassEnrollments } from "../../../../../api/thirdPartyIntegration/thirdPartyIntegration";
import { ClassTeacher } from "../../../../../model/interface/classDetails/ClassTeacher";

interface ClassSubjectProps {
  classId: number;
  schoolAccountId: number;
  mode: PageAccessMode;
  setSubjectCount: (e) => void;
  classExternalRoster: Array<any>;
  userContext?: UserContextState;
  isEditingEnabled: boolean;
}

const ClassSubject = (props: ClassSubjectProps) => {
  const [classSubject, setClassSubject] =
    useState<PagingResponse<ClassSubjectModel>>();
  const [pageNumber, setPageNumber] = useState(DEFAULT_PAGE_NUMBER);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [confirmationTitle, setConfirmationTitle] = useState("Confirm Action");
  const [confirmationMessage, setConfirmationMessage] =
    useState("Please confirm");
  const [openAddSubjectPopup, setOpenAddSubjectPopup] =
    useState<boolean>(false);
  const [deleteSubjectId, setDeleteSubjectId] = useState<number>(0);
  const [resetPageNumber, setResetPageNumber] = useState<boolean>(false);
  const [loading, showLoading] = useState(false);
  const [isEditMode, setIsEditMode] = useState(props.mode === PageAccessMode.Edit);


  useEffect(() => {

    // Checking if the curret user is able to edit the subjects
    if(!isEditMode) {
      // Get list of teachers
      ClassApi.getClassTeacherList(props.classId, pageNumber, pageSize).then((response: any) => {
        const classTeachers: ClassTeacher[] = response.data.data;        
        const isTeacher = classTeachers.some(teacher => teacher.userId === props.userContext?.userId);
        if(isTeacher) {
          setIsEditMode(true);
        }
      });
    }
    
  }, []);

  useEffect(() => {
    getClassSubject();
  }, [pageNumber, pageSize]);

  function getClassSubject(syncExternalStudents?: boolean | undefined) {
    showLoading(true);
    ClassApi.getClassSubject(props.classId, pageNumber, pageSize)
      .then((d) => {
        setClassSubject(d.data);
        props.setSubjectCount(d.data.totalRecords);
        showLoading(false);

        //If Class having subjects and external roster configured then call the sync job
        if (
          syncExternalStudents === true &&
          d.data.totalRecords > 0 &&
          props.classExternalRoster !== undefined &&
          props.classExternalRoster !== null &&
          props.classExternalRoster.length > 0
        ) {
          syncTeacherClassEnrollments(
            props.userContext?.userId ?? 0,
            props.classId
          );
          toast.info("Class external student sync started");
        }
      })
      .catch(() => {
        showLoading(false);
      });
  }
  function deleteClassSubject(subjectId: number): void {
    setDeleteSubjectId(subjectId);
    let msg = "Are you sure you want to delete selected subject?";
    if (classSubject?.totalRecords === 1) {
      msg =
        "A subject is required for each class. A missing subject association will result in reporting issues for grades and for student activities.";
    }
    showConfirmation("Please confirm", msg);
  }
  function showConfirmation(title: string, message: string) {
    setOpenConfirmation(true);
    setConfirmationTitle(title);
    setConfirmationMessage(message);
  }

  function confirmationOkClose() {
    ClassApi.deleteClassSubject(
      props.classId,
      deleteSubjectId.toString(),
      props.userContext?.userId
    ).then(() => {
      confirmationCancelClose();
      toast.success("Subject deleted successfully");
      getClassSubject();
    });
  }
  function confirmationCancelClose() {
    setOpenConfirmation(false);
  }
  function addClassSubject() {
    setOpenAddSubjectPopup(true);
  }
  const onPageSizeChange = (pageSize) => {
    setPageNumber(DEFAULT_PAGE_NUMBER);
    setPageSize(pageSize);
    setResetPageNumber(true);
  };

  return (
    <Fragment>
      <div className="flex flex-col relative">
        {loading && <Loader />}
        <div>
          <div className="flex items-end justify-between mb-4">
            <h1 className="font-bold text-primary-violet">Class Subjects</h1>
            {isEditMode && (
              <button
                id="addStudent"
                type="button"
                onClick={() => {
                  if (props.isEditingEnabled) {
                    addClassSubject();
                  }
                }}
                className={
                  (props.isEditingEnabled
                    ? "bg-primary-violet hover:bg-dark-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3 transition ease-in-out duration-500 text-white" 
                    : "cursor-not-allowed text-gray-400 border-gray-300 bg-gray-300")
                  + " shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium"
                }
              >
                Add Subject
              </button>
            )}
          </div>
          <div className="overflow-x-auto shadow">
            <table className="table w-full">
              <thead className="mb-5">
                <tr className="bg-light-violet  text-white py-3 px-3 overflow-hidden">
                  <th className="text-left  p-3 text-sm font-normal">
                    Subject
                  </th>
                  <th className=" text-center  p-3 text-sm font-normal">
                    Remove
                  </th>
                </tr>
              </thead>
              <tbody>
                {classSubject?.data?.map((subject, index) => {
                  return (
                    <tr
                      key={subject.subjectId}
                      className={`hover:bg-light-violet/20 transition ease-in-out duration-300 ${
                        index % 2 === 0 ? "bg-white" : "bg-light-violet/10"
                      }`}
                    >
                      <td className="text-sm mt-5  p-3">{subject.name}</td>
                      <td className="p-3">
                        {isEditMode && (
                          <div className={(props.isEditingEnabled ? "" : "cursor-not-allowed text-gray-400") + " flex items-center justify-center cursor-pointer"}>
                            <span                              
                              onClick={() => {
                                if (props.isEditingEnabled) {
                                  deleteClassSubject(subject.subjectId);
                                }                                
                              }}
                            >
                              <RemoveIcon
                                title="Remove Subject"
                                className={(props.isEditingEnabled ? "text-gray-500 hover:text-gray-700 cursor-pointer" : "cursor-not-allowed text-gray-400") + "pl-2 h-4 opacity-70 hover:opacity-100 m-auto"}
                              />
                            </span>
                          </div>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between border-t-2 pt-4 mt-5">
            {classSubject?.totalRecords !== undefined &&
              classSubject?.totalRecords !== 0 && (
                <Pagination
                  totalRecords={classSubject?.totalRecords}
                  pageSize={pageSize}
                  onPageChange={(e) => {
                    setPageNumber(e);
                  }}
                  reset={resetPageNumber}
                  pageNumber={pageNumber}
                />
              )}
            {classSubject?.totalRecords !== 0 && (
              <PageSize
                pageSize={pageSize}
                onPageSizeChange={onPageSizeChange}
              />
            )}
            {classSubject?.totalRecords === 0 && (
              <span className="text-color-black">No record found</span>
            )}
          </div>
        </div>
      </div>

      <ConfirmationDialog
        open={openConfirmation}
        confirmationTitle={confirmationTitle}
        confirmationMessage={confirmationMessage}
        onCancelClick={confirmationCancelClose}
        onOkClick={confirmationOkClose}
      ></ConfirmationDialog>

      {openAddSubjectPopup && (
        <SubjectPopup
          showPopUp={(e: boolean) => {
            setOpenAddSubjectPopup(false);
            getClassSubject(true);
          }}
          classId={props.classId}
          selectedSubjectIds={classSubject?.data?.map((c) => {
            return c.subjectId;
          })}
        ></SubjectPopup>
      )}
    </Fragment>
  );
};

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(ClassSubject);

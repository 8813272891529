import { Fragment, useEffect, useState } from "react";
import Select from "react-select";
import ReactTooltip from "react-tooltip";
import {
  getAllDomainsBySubject,
  getAllStandardByDomains,
} from "../../../../api/teacher/assignment";
import { IAssignment } from "../../../../model/interface/assignment";
import { IAssignmentActivity } from "../../../../model/interface/assignmentActivity";
import {
  IKeyValuePair,
  ISelectElement,
} from "../../../../model/interface/selectElement";
import DomainStandard from "../../../../model/teacher/domainStandard";
import Standard from "../../../../model/teacher/standard";
import Constant from "../../../../utils/constant/constant";
import { getActivityTypeId } from "../../../../utils/helper";
import Feedback from "../../../shared/feedback";
import Loader from "../../../shared/loader";
import MessagePopup from "../../../shared/messagePopup";
interface PracticeQuestionProps {
  assignment: IAssignment;
  updateAssignment: (updatedAsignment: IAssignment) => void;
  isActivityUpdated: (activityUpdated: boolean) => void;
  canActivityUpdate: boolean;
}

function PracticeQuestion(props: PracticeQuestionProps) {
  type ActivityValueType = "Domains" | "Standards" | "CombineActivity";
  const maxSelectActivity = 10;
  const { assignment, updateAssignment, isActivityUpdated, canActivityUpdate } =
    props;
  const [domains, setDomains] = useState<Array<ISelectElement>>([]);
  const [selectedDomains, setSelectedDomains] = useState<Array<ISelectElement>>(
    []
  );
  const [selectedStandards, setSelectedStandards] = useState<Array<number>>([]);
  const [showFeedbackPopup, setShowFeedbackPopup] = useState<boolean>(false);
  const [showMessagePopup, setShowMessagePopup] = useState<boolean>(false);
  const [combineActivity, setCombineActivity] = useState<boolean>(false);
  const [domainStandards, setDomainStandards] = useState<Array<DomainStandard>>(
    []
  );
  const [selectedStandard] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);

  function updateActivity(keyValuePair: IKeyValuePair, domainId: string) {
    isActivityUpdated(true);
    const existingActivity: IAssignmentActivity | undefined =
      assignment.activities?.filter(
        (r) =>
          r.activityType ===
            Constant.AssignmentActivityType.PRACTICEQUESTIONS &&
          r.standardId?.toString() === keyValuePair.key
      )[0];
    if (existingActivity) {
      var allActivities: Array<IAssignmentActivity> | undefined =
        assignment.activities;
      if (allActivities) {
        allActivities = allActivities.filter(
          (r) =>
            (r.activityType ===
              Constant.AssignmentActivityType.PRACTICEQUESTIONS &&
              r.standardId?.toString() === keyValuePair.key) === false
        );
        assignment.activities = allActivities;
      }
    } else {
      const activity: IAssignmentActivity = {
        assignmentActivityId: 0,
        assignmentId: assignment.assignmentId,
        activityTypeId: getActivityTypeId(
          Constant.AssignmentActivityType.PRACTICEQUESTIONS
        ),
        activityType: Constant.AssignmentActivityType.PRACTICEQUESTIONS,
        sequenceNumber: assignment.activities
          ? assignment.activities.length + 1
          : 1,
        isCombinedActivity: combineActivity,
        domainId: domainId,
        standardId: keyValuePair.key,
        standardName: keyValuePair.value,
      };

      assignment.activities?.push(activity);
    }

    updateAssignment(assignment);
  }

  function setExistingValues(
    activityValueType: ActivityValueType,
    subjectDomains: Array<ISelectElement> = []
  ) {
    const existingActivities: Array<IAssignmentActivity> | undefined =
      assignment.activities?.filter(
        (r) =>
          r.activityType === Constant.AssignmentActivityType.PRACTICEQUESTIONS
      );

    if (existingActivities && existingActivities.length > 0) {
      switch (activityValueType) {
        case "Domains":
          if (existingActivities[0].isCombinedActivity) {
            setCombineActivity(existingActivities[0].isCombinedActivity);
          }

          const selectedDomains: Array<ISelectElement> = subjectDomains.filter(
            (r) => existingActivities.some((s) => s.domainId === r.value)
          );
          setSelectedDomains(selectedDomains);
          bindStandards(existingActivities.map((s) => s.domainId).join(","));
          break;
        case "Standards":
          const existingStandardIds: Array<number> = [];
          existingActivities.forEach((standard) => {
            if (standard && standard.standardId) {
              existingStandardIds.push(parseInt(standard.standardId));
            }
          });
          setSelectedStandards(existingStandardIds);
          break;
      }
    }
  }

  function handleDomainChange(selectdItems: any) {
    const selectedDomains = Array.prototype.map.call(
      selectdItems,
      (s) => s.value
    );

    setSelectedDomains(selectdItems);

    bindStandards(selectedDomains.join(","));

    if (assignment.activities && assignment.activities.length > 0) {
      var allActivities: Array<IAssignmentActivity> | undefined =
        assignment.activities;
      if (allActivities) {
        allActivities = allActivities.filter((r) =>
          isActivityValid(r, selectedDomains)
        );
        assignment.activities = allActivities;
      }
    }
  }

  function isActivityValid(
    assignmentActivity: IAssignmentActivity,
    domains: Array<unknown>
  ) {
    if (
      assignmentActivity.activityType !==
      Constant.AssignmentActivityType.PRACTICEQUESTIONS
    )
      return true;

    const exist = domains.includes(assignmentActivity.domainId);
    return exist;
  }

  function toggleFeedback(popupAction: boolean) {
    setShowFeedbackPopup(popupAction);
  }

  function toggleCombineActivity() {
    const combineActivityValue = !combineActivity;
    if (combineActivityValue && selectedStandards.length > maxSelectActivity) {
      setShowMessagePopup(true);
      return false;
    }
    setCombineActivity(!combineActivity);

    var allActivities: Array<IAssignmentActivity> | undefined =
      assignment.activities;
    if (allActivities && allActivities.length > 0) {
      allActivities.forEach((activity) => {
        if (
          activity.activityType ===
          Constant.AssignmentActivityType.PRACTICEQUESTIONS
        ) {
          activity.isCombinedActivity = combineActivityValue;
        }
      });
    }

    assignment.activities = allActivities;
    updateAssignment(assignment);
    isActivityUpdated(true);
  }

  function toggleMessagePopup(popupAction: boolean) {
    setShowMessagePopup(popupAction);
  }

  function handleSelectedStandard(
    standardId: number,
    standardName: string,
    domainId: string
  ) {
    var selectedStandardList: Array<number> = new Array<number>();
    const standardExist = selectedStandards.some(
      (r) => (r as number) === standardId
    );

    if (standardExist) {
      selectedStandardList = selectedStandards.filter(
        (r) => (r as number) !== standardId
      );
    } else {
      selectedStandardList = [...selectedStandards];
      selectedStandardList.push(standardId);
    }

    if (combineActivity && selectedStandardList.length > maxSelectActivity) {
      selectedStandardList = selectedStandardList.filter(
        (r) => (r as number) !== standardId
      );
      setShowMessagePopup(true);
      return;
    }

    setSelectedStandards(selectedStandardList);
    const keyValuePair: IKeyValuePair = {
      key: standardId.toString(),
      value: standardName,
    };
    updateActivity(keyValuePair, domainId);
  }

  function bindStandards(domains: string) {
    setLoading(true);
    getAllStandardByDomains(domains, false, true).then((response) => {
      setLoading(false);
      const standardsData = response.data.data;
      var allStandards: Array<number> = new Array<number>();

      standardsData.forEach(function (domainStandard: DomainStandard) {
        if (domainStandard.standards) {
          domainStandard.standards.forEach(function (standard: Standard) {
            allStandards.push(parseInt(standard.id));
          });
        }
      });

      const validSelectedStandards = selectedStandards.filter((element) =>
        allStandards.includes(element)
      );
      setSelectedStandards(validSelectedStandards);

      setDomainStandards(standardsData);

      setExistingValues("Standards");
    });
  }

  function getDomains() {
    setLoading(true);
    getAllDomainsBySubject(assignment.subjectId).then((res) => {
      setLoading(false);
      const domains = res.data.data.map((values: any) => {
        return {
          label: values?.name,
          value: values?.domainId,
        };
      });
      setDomains(domains);
      setExistingValues("Domains", domains);
    });
  }

  useEffect(() => {
    getDomains();
  }, []);

  return (
    <Fragment>
      {loading && <Loader />}
      {showMessagePopup && (
        <MessagePopup
          togglePopup={toggleMessagePopup}
          message={
            "The max number of standards that can be selected for this 10 question activity is 10."
          }
        />
      )}
      <div className="min-w-0 block lg:col-span-9 xl:col-span-10 w-full sm:px-5">
        <div className="bg-gray-100  w-full mb-5 rounded-lg p-5 h-full">
          <div className="mb-0">
            <div className="grid xl:grid-cols-2">
              <div>
                <label className="block text-sm text-gray-500 mb-1">
                  Domain
                </label>
                <Select
                  value={selectedDomains}
                  options={domains}
                  isMulti={true}
                  closeMenuOnSelect={false}
                  onChange={handleDomainChange}
                  className={`${
                    canActivityUpdate
                      ? ""
                      : "opacity-50 cursor-not-allowed pointer-events-none"
                  }`}
                />
              </div>
            </div>
            <ReactTooltip
              multiline={true}
              className="bg-primary-violet"
              backgroundColor="#992883"
            />
            <div className="mt-5 flex items-center">
              <input
                id="styled-checkbox-2"
                type="checkbox"
                className={`checkbox h-4 w-4 cursor-pointer text-primary-violet border-gray-300 focus:ring-primary-violet ${
                  canActivityUpdate
                    ? ""
                    : "opacity-50 cursor-not-allowed pointer-events-none"
                }`}
                checked={combineActivity}
                onChange={(e) => {
                  toggleCombineActivity();
                }}
                data-tip="Enabling this option allows you to select up to 10 standards to make up your 10 question activity.<br /> When unchecked, each standard shall be handled as a separate activity that will include 10 questions each. <br />Only one option can be selected for Practice question between Combined standards or single standards"
              />
              <span className="text-sm text-gray-500 font-bold">
                Combine into single activity questions (max of 10 standards)
              </span>
            </div>

            <div className=" mt-10">
              {domainStandards && domainStandards.length > 0 && (
                <div className="bg-white pt-5 pb-3 px-5 shadow hover:shadow-lg">
                  <div className="">
                    {domainStandards.map((domainStandard, index) => {
                      return (
                        <Fragment key={index}>
                          <h3>{domainStandard.domainName}</h3>
                          <div className="shadow mt-2 mb-5">
                            <table className="table w-full">
                              <thead className="mb-5 border border border-light-violet">
                                <tr className="bg-light-violet  text-white py-3 px-3 overflow-hidden">
                                  <td></td>
                                  <th className="text-left p-3 font-normal w-1/2">
                                    Standard
                                  </th>
                                  <th className="text-left p-3   font-normal"></th>
                                </tr>
                              </thead>
                              <tbody>
                                {domainStandard.standards &&
                                  domainStandard.standards.map(
                                    (standard, index) => {
                                      return (
                                        <tr
                                          key={index}
                                          onClick={() =>
                                            handleSelectedStandard(
                                              parseInt(standard.id),
                                              standard.name,
                                              domainStandard.domainId
                                            )
                                          }
                                          className={`${
                                            selectedStandards.some(
                                              (r) =>
                                                (r as number) ===
                                                parseInt(standard.id)
                                            )
                                              ? "bg-light-violet/20 border border-light-violet"
                                              : ""
                                          } hover:bg-light-violet/20 transition ease-in-out duration-300 ${
                                            index % 2 === 0
                                              ? "bg-white"
                                              : "bg-light-violet/10"
                                          } ${
                                            canActivityUpdate
                                              ? "cursor-pointer"
                                              : "opacity-50 cursor-not-allowed pointer-events-none"
                                          } `}
                                        >
                                          <td className="p-3 w-4">
                                            <input
                                              id="styled-checkbox-2"
                                              type="checkbox"
                                              className="checkbox h-4 w-4 cursor-pointer text-primary-violet border-gray-300 focus:ring-primary-violet"
                                              checked={selectedStandards.some(
                                                (r) =>
                                                  (r as number) ===
                                                  parseInt(standard.id)
                                              )}
                                            />
                                          </td>
                                          <td className="text-sm mt-5  p-3">
                                            {standard.code} {standard.name}
                                          </td>
                                          <td className="text-sm mt-5  p-3">
                                            {!combineActivity && (
                                              <>10 Questions</>
                                            )}
                                          </td>
                                        </tr>
                                      );
                                    }
                                  )}
                              </tbody>
                            </table>
                          </div>
                          {showFeedbackPopup && (
                            <Feedback
                              toggleFeedback={toggleFeedback}
                              referenceType={
                                Constant.FeedbackReferenceType.STANDARD
                              }
                              referenceId={selectedStandard}
                            />
                          )}
                        </Fragment>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default PracticeQuestion;

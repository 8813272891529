import { Fragment, useEffect, useState } from "react";
import {
  getAllCannedTestsBySubject,
  getAllDomainsBySubject,
} from "../../../../api/teacher/assignment";
import {
  IKeyValuePair,
  ISelectElement,
} from "../../../../model/interface/selectElement";
import { IAssignment } from "../../../../model/interface/assignment";
import { IAssignmentActivity } from "../../../../model/interface/assignmentActivity";
import Constant from "../../../../utils/constant/constant";
import { getActivityTypeId } from "../../../../utils/helper";
import { PreBuildActivityType } from "../../../../types/type";
import Loader from "../../../shared/loader";
import { getSubjectDetail } from "../../../../api/teacher/assessment";
import { PreviewIcon } from "../../../../assets/icons";
import QuestionPreview from "../shared/questionPreview/questionPreview";
import MessagePopup from "../../../shared/messagePopup";
interface PrebuildTestProps {
  assignment: IAssignment;
  updateAssignment: (updatedAsignment: IAssignment) => void;
  isActivityUpdated: (activityUpdated: boolean) => void;
  canActivityUpdate: boolean;
  allowViewPreBuiltTests: boolean;
}

function PrebuildTest(props: PrebuildTestProps) {
  const { assignment, updateAssignment, isActivityUpdated, canActivityUpdate } =
    props;
  type TestType = "Small" | "Medium" | "Large";
  const [open, setOpen] = useState<boolean>(false);
  const [activityPreviewDetail, setActivityPreviewDetail] = useState<{
    previewId: string;
    activityIdPreview: number;
    activityName: string;
    isProjectorMode: boolean;
  }>();
  const [tetsTypes, setTetsTypes] = useState<Array<TestType>>([]);
  const [domains, setDomains] = useState<Array<ISelectElement>>([]);
  const [cannedTests, setCannedTests] = useState<Array<any>>([]);
  const [selectedDomains, setSelectedDomains] = useState<Array<number>>([]);
  const [selectedCannedTests, setSelectedCannedTests] = useState<Array<number>>(
    []
  );
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [subjectDetail, setSubjectDetail] = useState<any>();
  const [errroMessage, setErrroMessage] = useState<string>("");
  const [errorMessagePopup, setErrorMessagePopup] = useState<boolean>(false);

  function handleTestTypeChange(testTypeId: number, testType: TestType) {
    var updatedTestTypes: Array<TestType> = [];
    const testTypeExist = tetsTypes.includes(testType);
    if (testTypeExist) {
      updatedTestTypes = tetsTypes.filter((r) => r !== testType);
    } else {
      updatedTestTypes = [...tetsTypes, testType];
    }

    setTetsTypes(updatedTestTypes);

    const keyValuePair: IKeyValuePair = {
      key: testTypeId.toString(),
      value: testType,
    };
    updateActivity(keyValuePair, "Summative", testType.toString());
  }

  function isActivityExist(
    key: string,
    activity: IAssignmentActivity,
    activityType: PreBuildActivityType
  ) {
    var isExist: boolean = false;
    switch (activityType) {
      case "Summative":
        isExist = activity.summativeTestId?.toString() === key;
        break;
      case "Domain":
        isExist = activity.domainId?.toString() === key;
        break;
      case "Canned":
        isExist = activity.cannedTestId?.toString() === key;
        break;
    }

    return isExist;
  }

  function updateActivity(
    keyValuePair: IKeyValuePair,
    activityType: PreBuildActivityType,
    summativeTestType: string = "",
    learnosityReferenseEnglish: string = "",
    learnosityReferenseSpanish: string = ""
  ) {
    isActivityUpdated(true);
    const existingActivities: Array<IAssignmentActivity> | undefined =
      assignment.activities?.filter(
        (r) =>
          r.activityType === Constant.AssignmentActivityType.PREBUILTTESTS &&
          isActivityExist(keyValuePair.key, r, activityType)
      );
    if (existingActivities && existingActivities.length > 0) {
      var allActivities: Array<IAssignmentActivity> | undefined =
        assignment.activities;
      if (allActivities) {
        allActivities = allActivities.filter(
          (r) =>
            (r.activityType === Constant.AssignmentActivityType.PREBUILTTESTS &&
              isActivityExist(keyValuePair.key, r, activityType)) === false
        );
        assignment.activities = allActivities;
      }
    } else {
      const activity: IAssignmentActivity = {
        assignmentActivityId: 0,
        assignmentId: assignment.assignmentId,
        activityTypeId: getActivityTypeId(
          Constant.AssignmentActivityType.PREBUILTTESTS
        ),
        activityType: Constant.AssignmentActivityType.PREBUILTTESTS,
        sequenceNumber: assignment.activities
          ? assignment.activities.length + 1
          : 1,
        testSize: summativeTestType,
        learnosityIdEnglish:
          learnosityReferenseEnglish !== ""
            ? learnosityReferenseEnglish
            : undefined,
        learnosityIdSpanish:
          learnosityReferenseSpanish !== ""
            ? learnosityReferenseSpanish
            : undefined,
      };

      switch (activityType) {
        case "Summative":
          activity.summativeTestId = keyValuePair.key;
          activity.summativeTestName = keyValuePair.value;
          break;
        case "Domain":
          activity.domainId = keyValuePair.key;
          activity.domainName = keyValuePair.value;
          break;
        case "Canned":
          activity.cannedTestId = keyValuePair.key;
          activity.cannedTestName = keyValuePair.value;
          break;
      }

      assignment.activities?.push(activity);
    }

    updateAssignment(assignment);
  }

  function handleSelectedDomain(domainId: number, name: string) {
    const domainExist = selectedDomains.some((r) => (r as number) === domainId);
    var selectedDomainList: Array<number> = new Array<number>();
    if (domainExist) {
      selectedDomainList = selectedDomains.filter(
        (r) => (r as number) !== domainId
      );
    } else {
      selectedDomainList = [...selectedDomains];
      selectedDomainList.push(domainId);
    }

    const keyValuePair: IKeyValuePair = {
      key: domainId.toString(),
      value: name,
    };
    setSelectedDomains(selectedDomainList);
    updateActivity(keyValuePair, "Domain");
  }

  function handleSelectedCannedTest(
    cannedTestId: number,
    name: string,
    learnosityIdEnglish: string,
    learnosityIdSpanish: string
  ) {
    const cannedTestExist = selectedCannedTests.some(
      (r) => (r as number) === cannedTestId
    );
    var selectedCannedTestList: Array<number> = new Array<number>();
    if (cannedTestExist) {
      selectedCannedTestList = selectedCannedTests.filter(
        (r) => (r as number) !== cannedTestId
      );
    } else {
      selectedCannedTestList = [...selectedCannedTests];
      selectedCannedTestList.push(cannedTestId);
    }

    const keyValuePair: IKeyValuePair = {
      key: cannedTestId.toString(),
      value: name,
    };
    setSelectedCannedTests(selectedCannedTestList);
    updateActivity(
      keyValuePair,
      "Canned",
      "",
      learnosityIdEnglish,
      learnosityIdSpanish
    );
  }

  function getDomains() {
    setShowLoading(true);
    getAllDomainsBySubject(assignment.subjectId).then((res) => {
      setShowLoading(false);
      const domains = res.data.data.map((values: any) => {
        return {
          label: values?.name,
          value: values?.domainId,
        };
      });
      setDomains(domains);
    });
  }

  function bindSubjectDetail() {
    if (assignment.subjectId > 0){
      getSubjectDetail(assignment.subjectId).then((response) => {
        setSubjectDetail(response.data);
      });
    }
  }

  function getTestSize(resultType: TestType) {
    let result: number = 0;
    if (subjectDetail) {
      const elementaryGrades: Array<string> = ["K", "1", "2", "3", "4", "5"];
      const subjectGrades: Array<string> = subjectDetail.grades.map((grade) => {
        return grade.name;
      });

      switch (resultType) {
        case "Small":
          result = elementaryGrades.some((r) => subjectGrades.includes(r))
            ? 10
            : 20;
          break;
        case "Medium":
          result = elementaryGrades.some((r) => subjectGrades.includes(r))
            ? 20
            : 40;
          break;
        case "Large":
          result = elementaryGrades.some((r) => subjectGrades.includes(r))
            ? 30
            : 60;
          break;
        default:
          result = elementaryGrades.some((r) => subjectGrades.includes(r))
            ? 10
            : 20;
          break;
      }
    }

    return result;
  }

  function getCannedTests() {
    setShowLoading(true);
    getAllCannedTestsBySubject(assignment.subjectId).then((response) => {
      setShowLoading(false);
      var result = response.data.data;
      if (result && result.length > 0) {
        setCannedTests(result);
      }
    });
  }

  function setExistingValues() {
    const existingActivities: Array<IAssignmentActivity> | undefined =
      assignment.activities?.filter(
        (r) => r.activityType === Constant.AssignmentActivityType.PREBUILTTESTS
      );

    if (existingActivities && existingActivities.length > 0) {
      const existingDomainIds: Array<number> = [];
      const existingcannedTestIds: Array<number> = [];
      const existingSummativeTest: Array<TestType> = [];
      existingActivities.forEach((activity) => {
        if (activity && activity.domainId) {
          existingDomainIds.push(parseInt(activity.domainId));
        }
        if (activity && activity.cannedTestId) {
          existingcannedTestIds.push(parseInt(activity.cannedTestId));
        }
        if (activity && activity.summativeTestId) {
          existingSummativeTest.push(activity.summativeTestName as TestType);
        }
      });
      setSelectedDomains(existingDomainIds);
      setSelectedCannedTests(existingcannedTestIds);
      setTetsTypes(existingSummativeTest);
    }
  }

  const handlePreviewPopup = (cannedTest: any) => {
    console.log(cannedTest);
    if (isActivityValid(cannedTest.englishExternalReferenceId)) {
      openModal(
        cannedTest.englishExternalReferenceId,
        cannedTest.cannedTestId,
        cannedTest.name,
        false
      );
    }
  };

  const openModal = (
    learnosityReferenceId,
    activityId,
    activityName,
    isProjectorMode
  ) => {
    if (!learnosityReferenceId) {
      return false;
    }

    setOpen(true);
    setActivityPreviewDetail({
      previewId: learnosityReferenceId,
      activityIdPreview: activityId,
      activityName: activityName,
      isProjectorMode: isProjectorMode,
    });
  };

  const popUpClose = (value) => {
    setOpen(value);
    setActivityPreviewDetail(undefined);
  };

  function isActivityValid(learnosityId: string) {
    if (learnosityId === "") {
      setErrroMessage(
        "You cannot perform this action because there are no questions associated with this assessment."
      );
      setErrorMessagePopup(true);
      return false;
    }
    return true;
  }
  const toggleErrorMessagePopup = () => {
    setErrorMessagePopup(false);
  };

  useEffect(() => {
    bindSubjectDetail();
    getDomains();
    if (assignment.subjectId > 0) {
      getCannedTests();
    }
    setExistingValues();
  }, []);

  return (
    <Fragment>
      {showLoading && <Loader></Loader>}

      {errorMessagePopup && (
        <MessagePopup
          message={errroMessage}
          togglePopup={toggleErrorMessagePopup}
        />
      )}

      {activityPreviewDetail && (
        <QuestionPreview
          modal={openModal}
          show={open}
          changeValue={popUpClose}
          activityRef={activityPreviewDetail.previewId}
          activityIdPreview={activityPreviewDetail.activityIdPreview}
          activityName={activityPreviewDetail.activityName}
          isProjectorMode={activityPreviewDetail.isProjectorMode}
          subjectId={assignment.subjectId}
        ></QuestionPreview>
      )}
      <div className="min-w-0 block lg:col-span-9 xl:col-span-10 w-full sm:px-5">
        <div className="bg-gray-100  w-full mb-5 rounded-lg p-5 h-full">
          <div className="mb-0">
            {cannedTests && cannedTests.length > 0 && props.allowViewPreBuiltTests && (
              <div className="bg-white py-6 px-5 mb-10 shadow hover:shadow-lg">
                <fieldset>
                  <h3 className="mb-2 mt-6 text-lg">
                    Pre-built Practice Tests
                  </h3>
                  {cannedTests.map((cannedTest, index) => {
                    return (
                      <div
                        key={index}
                        onClick={() =>
                          handleSelectedCannedTest(
                            parseInt(cannedTest.cannedTestId),
                            cannedTest.name,
                            cannedTest.englishExternalReferenceId,
                            cannedTest.spanishExternalReferenceId
                          )
                        }
                        className={`${
                          selectedCannedTests.some(
                            (r) =>
                              (r as number) ===
                              parseInt(cannedTest.cannedTestId)
                          )
                            ? "bg-light-violet/20 border border-light-violet"
                            : ""
                        } ${
                          canActivityUpdate
                            ? ""
                            : "opacity-50 cursor-not-allowed pointer-events-none"
                        } relative border p-4 flex flex-col cursor-pointer md:pl-4 md:pr-6 focus:outline-none`}
                      >
                        <div className="flex justify-between">
                          <div>
                            <input
                              id="styled-checkbox-2"
                              type="checkbox"
                              className="checkbox"
                              checked={selectedCannedTests.some(
                                (r) =>
                                  (r as number) ===
                                  parseInt(cannedTest.cannedTestId)
                              )}
                            />
                            <label className="checkbox-label text-sm text-gray-500">
                              {cannedTest.name}
                            </label>
                          </div>

                          <div
                            className="flex mt-1.5 items-center justify-center"
                            onClick={() => handlePreviewPopup(cannedTest)}
                          >
                            <PreviewIcon className="mr-3 h-5 w-5 text-xs font-light cursor-pointer text-gray-400 hover:text-secondary-color transaction ease-in-out duration-300" />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </fieldset>
              </div>
            )}
            <div className="bg-white py-6 px-5 mb-10 shadow hover:shadow-lg">
              <fieldset>
                <h3 className="mb-2 text-lg">Summative Tests</h3>
                <div
                  className={`relative bg-white rounded-md -space-y-px grid grid-cols-1 md:grid-cols-4 gap-4 ${
                    canActivityUpdate ? "" : "opacity-50 cursor-not-allowed"
                  }`}
                >
                  <div
                    onClick={() => handleTestTypeChange(1, "Small")}
                    className={`${
                      tetsTypes.includes("Small")
                        ? "bg-light-violet/20 border-light-violet"
                        : "border-gray-200"
                    } ${
                      canActivityUpdate
                        ? "cursor-pointer"
                        : "pointer-events-none"
                    } relative border p-4 flex flex-col md:pl-4 md:pr-6 focus:outline-none hover:bg-light-violet/20 transition ease-in-out duration-300`}
                  >
                    <div>
                      <div className="flex items-center">
                        <input
                          id="styled-checkbox-2"
                          type="checkbox"
                          className="checkbox h-4 w-4 cursor-pointer text-primary-violet border-gray-300 focus:ring-primary-violet"
                          checked={tetsTypes.includes("Small") ? true : false}
                        />
                        <label className="checkbox-label text-sm text-gray-500">
                          Small
                        </label>
                      </div>
                      <span className="mt-2 block text-sm text-gray-900">
                        {subjectDetail && `${getTestSize("Small")} Questions`}
                      </span>
                    </div>
                  </div>

                  <div
                    onClick={() => handleTestTypeChange(2, "Medium")}
                    x-radio-group-option=""
                    className={`${
                      tetsTypes.includes("Medium")
                        ? "bg-light-violet/20 border-light-violet"
                        : "border-gray-200"
                    } ${
                      canActivityUpdate
                        ? "cursor-pointer"
                        : "pointer-events-none"
                    } relative border p-4 flex flex-col  md:pl-4 md:pr-6 focus:outline-none hover:bg-light-violet/20 transition ease-in-out duration-300`}
                  >
                    <div>
                      <div className="flex items-center">
                        <input
                          id="styled-checkbox-2"
                          type="checkbox"
                          className="checkbox"
                          checked={tetsTypes.includes("Medium") ? true : false}
                        />
                        <label className="checkbox-label text-sm text-gray-500">
                          Medium
                        </label>
                      </div>
                      <span className="mt-2 block text-sm text-gray-900">
                        {subjectDetail && `${getTestSize("Medium")} Questions`}
                      </span>
                    </div>
                  </div>
                  <div
                    onClick={() => handleTestTypeChange(3, "Large")}
                    x-radio-group-option=""
                    className={`${
                      tetsTypes.includes("Large")
                        ? "bg-light-violet/20 border-light-violet"
                        : "border-gray-200"
                    } ${
                      canActivityUpdate
                        ? "cursor-pointer"
                        : "pointer-events-none"
                    } relative border p-4 flex flex-col md:pl-4 md:pr-6 focus:outline-none hover:bg-light-violet/20 transition ease-in-out duration-300`}
                  >
                    <div>
                      <div className="flex items-center">
                        <input
                          id="styled-checkbox-2"
                          type="checkbox"
                          className="checkbox"
                          checked={tetsTypes.includes("Large") ? true : false}
                        />
                        <label className="checkbox-label text-sm text-gray-500">
                          Large
                        </label>
                      </div>
                      <span className="mt-2 block text-sm text-gray-900">
                        {subjectDetail && `${getTestSize("Large")} Questions`}
                      </span>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
            <div className="bg-white py-6 px-5 mb-0 shadow hover:shadow-lg">
              <fieldset>
                <div>
                  <h3 className="mb-2 text-lg">Domain Tests</h3>
                  <div className="relative bg-white rounded-md">
                    {domains &&
                      domains.map((domain, index) => {
                        return (
                          <div
                            key={index}
                            onClick={() =>
                              handleSelectedDomain(
                                parseInt(domain.value),
                                domain.label
                              )
                            }
                            className={`${
                              selectedDomains.some(
                                (r) => (r as number) === parseInt(domain.value)
                              )
                                ? "bg-light-violet/20 border border-light-violet"
                                : ""
                            } ${
                              canActivityUpdate
                                ? ""
                                : "opacity-50 cursor-not-allowed pointer-events-none"
                            } relative border p-4 flex flex-col cursor-pointer md:pl-4 md:pr-6 focus:outline-none hover:bg-light-violet/20 transition ease-in-out duration-300`}
                          >
                            <div className="flex items-center">
                              <input
                                id="styled-checkbox-2"
                                type="checkbox"
                                className="checkbox h-4 w-4 cursor-pointer text-primary-violet border-gray-300 focus:ring-primary-violet"
                                checked={selectedDomains.some(
                                  (r) =>
                                    (r as number) === parseInt(domain.value)
                                )}
                              />
                              <label className="checkbox-label text-sm text-gray-500">
                                {domain.label} - 20 Questions
                              </label>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </fieldset>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default PrebuildTest;

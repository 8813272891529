import { useEffect, useState } from "react";
import MainScreen from '../../../../../assets/images/thinkorswim/MainScreen.jpg';
import { IGameSessionResponse, IGameSessionsRequest } from "../../../../../model/interface/classroomGame/thinkOrSwimSetup";
import { DeleteGameSession, GetGameSessions, ResetSession, TOSScreens, setupAction } from "../../../../../api/classroomGame/thinkOrSwim";
import Loader from "../../../../shared/loader";
import { toast } from "react-toastify";
import { DeleteIcon, EditIcon } from "../../../../../assets/icons";
import { DuplicateIcon } from "@heroicons/react/solid";
import ConfirmationDialog from "../../../../shared/confirmationDialog";
import { useHistory } from "react-router-dom";
import routeConstant from "../../../../../utils/constant/routeConstant";
import Moment from "moment";

interface ITOSSessionsProps {
  setSelectedScreen: (screen: TOSScreens) => void,
  selectSetupAction: (action: setupAction) => void,
  setSelectedClassroomGameSessionId: (id: number) => void,
  teacherId: number,
}

type ConfirmationActionType = "Duplicate" | "Delete";

function TOSSessions(props: ITOSSessionsProps) {
  const {
    setSelectedScreen,
    selectSetupAction,
    setSelectedClassroomGameSessionId,
    teacherId,
  } = props;

  const history = useHistory();
  const [sessions, setSession] = useState<Array<IGameSessionResponse>>();
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [confirmationMessage, setConfirmationMessage] = useState<string>("");
  const [showConfirmationDialog, setShowConfirmationDialog] = useState<boolean>(false);
  const [confirmationAction, setConfirmationAction] = useState<ConfirmationActionType>("Duplicate");
  const [classroomGameSessionIdToDelete, setClassroomGameSessionIdToDelete] = useState<number>(0);
  const [classroomGameSessionIdToDuplicate, setClassroomGameSessionIdToDuplicate] = useState<number>(0);

  const loadSessions = () => {
    const GameSessionsRequest: IGameSessionsRequest = {
      classroomGameId: 1,
      teacherId: teacherId,
    }

    setShowLoading(true);
    GetGameSessions(GameSessionsRequest)
      .then(response => {
        setShowLoading(false);
        const newSessions: Array<IGameSessionResponse> = response.data.sessions.map(session => ({
          ...session,
          createdDate: new Date(session.createdDate),
          completedDate: session.completedDate && new Date(session.completedDate)
        }))
        setSession(newSessions);
      })
      .catch(error => {
        setShowLoading(false);
        toast.error(`${error.message} Cannot get Sessions list`);
      })
  }

  const handleEditSession = (classroomGameSessionId: number) => {
    if(classroomGameSessionId) {
      selectSetupAction("Edit");
      setSelectedClassroomGameSessionId(classroomGameSessionId);
      setSelectedScreen("Setup");
    }
  }

  const handleDuplicateSession = (classroomGameSessionId: number) => {
    const session = sessions?.find(s => s.classroomGameSessionId === classroomGameSessionId);

    if(session) {
      setConfirmationMessage(`You are about to duplicate the content of the game session <b>${session.name}</b>. Are you sure you want to duplicate this?`);
      setClassroomGameSessionIdToDuplicate(classroomGameSessionId);
      setConfirmationAction("Duplicate");
      setShowConfirmationDialog(true);
    }
  }

  const handleDuplicateSessionConfirmation = () => {
    setShowConfirmationDialog(false);
    if(classroomGameSessionIdToDuplicate) {
      selectSetupAction("Duplicate");
      setSelectedClassroomGameSessionId(classroomGameSessionIdToDuplicate);
      setSelectedScreen("Setup");
    }
  }

  const handleDeleteSession = (classroomGameSessionId: number) => {
    const session = sessions?.find(s => s.classroomGameSessionId === classroomGameSessionId);

    if(session) {
      setConfirmationMessage(`Are you sure you want to delete the game session <b>${session.name}</b>?`);
      setClassroomGameSessionIdToDelete(classroomGameSessionId);
      setConfirmationAction("Delete");
      setShowConfirmationDialog(true);
    }
  }

  const handleDeleteSessionConfirmation = () => {
    setShowConfirmationDialog(false);
    if(classroomGameSessionIdToDelete) {
      setShowLoading(true);
      DeleteGameSession(classroomGameSessionIdToDelete, teacherId)
      .then(() => {
        setShowLoading(false);
        loadSessions();
      })
      .catch(error => {
        setShowLoading(false);
        toast.error(`${error.message || ''} Cannot delete Session`);
      })
    }
  }

  const handleConfirmation = () => {
    switch (confirmationAction) {
      case "Duplicate":
        handleDuplicateSessionConfirmation();
        break;
    
      case "Delete":
        handleDeleteSessionConfirmation();
        break;
    }
  }

  const handleCreateSesion = () => {
    selectSetupAction("Create");
    setSelectedScreen("Setup");
  }

  const handleStartSession = (classroomGameSessionId: number) => {
    history.push({
      pathname: routeConstant.ClassroomGame.ThinkOrSwim,
      search: `?sessionId=${classroomGameSessionId}`
    });
  }

  const handlePlayAgainSession = (classroomGameSessionId: number) => {
    setShowLoading(true);
    ResetSession(classroomGameSessionId)
    .then(() => {
      setShowLoading(false);
      handleStartSession(classroomGameSessionId);
    })
    .catch(error => {
      setShowLoading(false);
      toast.error(`${error.message || ''} Cannot reset Session`);
    })
  }

  useEffect(() => {
    loadSessions();
  }, [])

  return (
    <div>
      {showLoading && (<Loader />)}
      <ConfirmationDialog 
        confirmationTitle={"Please Confirm"} 
        confirmationMessage={confirmationMessage} 
        open={showConfirmationDialog} 
        onOkClick={ handleConfirmation } 
        onCancelClick={ () => setShowConfirmationDialog(false) }      
      />
      <img
        className="w-full"
        src={MainScreen}
        alt='Think of Swim'
      />
      <div className="m-8">
        <dl className="mb-8">
          <dt className="text-primary-violet text-left text-lg mb-5">
            Welcome to Think or Swim! 
          </dt>
          <dd className="text-gray-500 text-left">
            This is a team-based, quiz-show style game that is best played on a screen in the classroom. Teachers are the hosts and will conduct this game for each team.
          </dd>
        </dl>
        <h2 className='text-white bg-primary-violet p-3 font-semibold mb-4'>
          Game sessions
        </h2>
        <div className="flex">
          <div className="flex-auto p-4">
            <table className="w-full mobile-optimised table">
              <thead className="mb-5">
                <tr className="bg-light-violet text-white p-3">
                  <th className="text-left p-3 font-normal">
                    Session Name
                  </th>
                  <th className="text-left p-3 font-normal">
                    Create Date
                  </th>
                  <th className="text-left p-3 font-normal">
                    Completion Date
                  </th>
                  <th></th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {sessions && (sessions.map((session: IGameSessionResponse, index: number) => (
                  <tr key={index} className={index % 2 ? "bg-white" : "bg-light-violet/20"}>
                    <td className="text-ms p-3 items-center">
                      {session.status === "Start" && (
                        <a 
                          href="#"
                          className="text-blue-500 underline hover:no-underline cursor-pointer flex flex-wrap"
                          onClick={() => handleEditSession(session.classroomGameSessionId)}
                        >
                          {session.name}<EditIcon className="w-4 ml-1" />
                        </a>
                      )}
                      {session.status !== "Start" && (
                        session.name
                      )}
                    </td>
                    <td className="text-ms p-3 items-center">
                      {Moment(session.createdDate).format("MM-DD-yyyy")}
                    </td>
                    <td className="text-ms p-3 items-center">
                      {session.completedDate ? Moment(session.completedDate).format("MM-DD-yyyy") : ''}
                    </td>
                    <td className="p-3 items-center">
                      <button
                        type="button"
                        className="w-full inline-flex items-center justify-center bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 focus:bg-secondary-teal focus:text-white hover:text-white hover:shadow-lg hover:bg-secondary-teal ring-1 ring-secondary-teal rounded-md shadow-sm p-2 text-secondary-teal"
                        onClick={() => handleDuplicateSession(session.classroomGameSessionId)}
                      >
                        <DuplicateIcon className="w-4" />
                        <span className="ml-2">Duplicate</span>
                      </button>
                    </td>
                    <td className="p-3 items-center">
                      <button
                        type="button"
                        className="w-full inline-flex items-center justify-center bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 focus:bg-red-500 focus:text-white hover:text-white hover:shadow-lg hover:bg-red-600 ring-1 ring-red-500 rounded-md shadow-sm p-2 text-red-600"
                        onClick={() => handleDeleteSession(session.classroomGameSessionId)}
                      >
                        <DeleteIcon className="w-4"/>
                        <span className="ml-2">Delete</span>
                      </button>
                    </td>
                    <td className="p-3 items-center">
                      <button
                        type="button"
                        className={
                          "w-full focus:outline-none focus:ring-2 focus:ring-offset-2  focus:ring-indigo-500 hover:shadow-lg rounded-md shadow-sm p-2 text-white " + ( 
                            session.status === "Start" ?
                            "bg-green-500 focus:bg-green-600 hover:bg-green-600" :
                            "bg-secondary-teal focus:bg-dark-teal hover:bg-dark-teal"
                          )
                        }
                        onClick={
                          () => session.status === "Play Again" 
                          ? handlePlayAgainSession(session.classroomGameSessionId) 
                          : handleStartSession(session.classroomGameSessionId)
                        }
                      >
                        {session.status}
                      </button>
                    </td>
                  </tr>
                )))}
              </tbody>
            </table>
          </div>
          <div className="col-span-2 p-4">
            <ul className="grid grid-cols-1 gap-4">
              <li className="col-span-1">
                <button 
                  type="button"
                  className="w-full bg-primary-violet focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 focus:bg-dark-violet hover:shadow-lg hover:bg-dark-violet rounded-md shadow-sm p-2 text-white mb-8"
                  onClick={handleCreateSesion}
                >
                  Create Session
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TOSSessions

import React from "react";
import { CompletionInfo } from "./interface";

const AssignmentsGroupedAssignmentCollapsedIcon: React.FunctionComponent<
  CompletionInfo
> = ({ is_saved, past_due }: CompletionInfo) => {
  return (
    <div
      className={`flex-shrink-0 cursor-pointer h-12 w-12 bg-blues text-white ${
        is_saved === 1 ? "bg-site-orange" : ""
      } ${
        past_due === 1 ? "bg-site-pink" : ""
      }  hover:opacity-90 rounded-full flex items-center justify-center`}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="flex-shrink-0 h-5 w-5 stroke-current text-white"
        viewBox="0 0 65.418 61.447"
      >
        <g
          id="Group_26"
          data-name="Group 26"
          transform="translate(-417.59 -1119)"
        >
          <path
            id="Path_13"
            data-name="Path 13"
            d="M697.045,48H738.2v59.447H694.667V48Z"
            transform="translate(-276.077 1072)"
            fill="none"
            stroke="currentColor"
            stroke-linejoin="round"
            strokeWidth="2"
          />
          <path
            id="Path_15"
            data-name="Path 15"
            d="M720.667,149.144l2.774,2.675,5.35-5.152"
            transform="translate(-295.067 981.185)"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeWidth="2"
          />
          <path
            id="Path_18"
            data-name="Path 18"
            d="M789.333,155h14.82"
            transform="translate(-348.977 975.335)"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeWidth="2"
          />
          <path
            id="Path_15-2"
            data-name="Path 15"
            d="M720.667,149.144l2.774,2.675,5.35-5.152"
            transform="translate(-295.067 993.967)"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeWidth="2"
          />
          <path
            id="Path_18-2"
            data-name="Path 18"
            d="M789.333,155h14.82"
            transform="translate(-348.977 988.116)"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeWidth="2"
          />
          <path
            id="Path_15-3"
            data-name="Path 15"
            d="M720.667,149.144l2.774,2.675,5.35-5.152"
            transform="translate(-295.067 1006.748)"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeWidth="2"
          />
          <path
            id="Path_18-3"
            data-name="Path 18"
            d="M789.333,155h14.82"
            transform="translate(-348.977 1000.897)"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeWidth="2"
          />
          <path
            id="Path_15-4"
            data-name="Path 15"
            d="M720.667,149.144l2.774,2.675,5.35-5.152"
            transform="translate(-295.067 1019.529)"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeWidth="2"
          />
          <path
            id="Path_18-4"
            data-name="Path 18"
            d="M789.333,155h14.82"
            transform="translate(-348.977 1013.678)"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeWidth="2"
          />
          <path
            id="Path_83"
            data-name="Path 83"
            d="M3.369,0,0,8.669V46.914H6.737V8.669Z"
            transform="translate(452.078 1174.762) rotate(-141)"
            fill="#fff"
            stroke="currentColor"
            strokeLinecap="round"
            stroke-linejoin="round"
            strokeWidth="2"
          />
          <line
            id="Line_41"
            data-name="Line 41"
            x2="6.836"
            transform="translate(457.464 1168.032) rotate(-141)"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
          />
          <line
            id="Line_42"
            data-name="Line 42"
            x2="6.836"
            transform="translate(476.357 1144.702) rotate(-141)"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
          />
        </g>
      </svg>
    </div>
  );
};

export default AssignmentsGroupedAssignmentCollapsedIcon;

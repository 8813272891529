import { useCallback, useEffect, useRef, useState } from "react";
import { initRequest } from "./learnosityAuthorInit";
import { getRequest } from "../../../../api/userActivity";

export default function LearnosityPassageEditor(props: any) {
  let authorApp;
  const [headingLoaded, setHeadingLoaded] = useState(false);

  const headingLoadedRef = useRef(headingLoaded);
  const isInitialized = useRef(false);

  const initialization = useCallback(async () => {
    if (isInitialized.current) return;
      
    isInitialized.current = true;
    const callbacks = {
      readyListener: await async function () {
        await authorApp.on("render:item", async function () {
          props.setPassageApp(authorApp);
          const features = authorApp.getItem().features;
          if (features.length > 0 && props.passageData === undefined) {
            const itemRef = authorApp.getItem().item.reference;
            const widgetRef = features[0].reference;
            var widget = authorApp.navigate(
              "items/" + itemRef + "/widgets/" + widgetRef + ""
            );
            props.setPassageData(widget);
            return widget;
          }
          if (props.passageData) {
            authorApp.setWidget(props.passageData);
            props.setPassageData(props.passageData);
          } else {
            authorApp.setWidget(props.payload, props.payloadLabel);
            props.setPassageData(props.payload);
          }
        });
        authorApp.on("widgetedit:preview:changed", function (event) {
          props.setPassageData(authorApp.getWidget());
        });
      },
      errorListener: function (e) {
        console.log("Error Code ", e.code);
        console.log("Error Message ", e.msg);
        console.log("Error Detail ", e.detail);
      },
    };

    const request = initRequest(props.itemRef.toString(), "features", 0);
    getRequest("author", JSON.stringify(request)).then(async (response) => {
      if (response.data) {
        authorApp = await window["LearnosityAuthor"]?.init(
          response.data,
          callbacks
        );
      }
    });
  }, []);
  const replaceheading = () => {
    let a = document.querySelector(
      ".lrn-qe-toolbar-left h3 span[data-lrn-qe-layout-widget-title='rendered']"
    );
    let b = document.querySelector(".lrn-qe-toolbar-left h3");
    const check = document.querySelector(
      ".lrn-qe-toolbar-left h3 #heading-question-builder"
    );
    if (a && b && !check) {
      setHeadingLoaded(true);
      const div = document.createElement("div");
      div.classList.add("lrn-qe-h3");
      div.classList.add("lrn-qe-inline");
      div.classList.add("lrn-question-type-name");
      div.setAttribute("id", "heading-question-builder");
      div.innerText = a.textContent!;
      b.appendChild(div);
    }
  };
  useEffect(() => {
    headingLoadedRef.current = headingLoaded;
  }, [headingLoaded]);

  useEffect(() => {
    initialization().then(() => {
      const timer = setInterval(() => {
        if (headingLoadedRef.current) {
          clearInterval(timer);
        } else {
          replaceheading();
        }
      }, 3000);
    });
  }, [initialization]);

  return (
    <div>
      <div className="main-content">
        <div id="learnosity-author"></div>
      </div>
    </div>
  );
}

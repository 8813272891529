import { Fragment, useEffect, useState } from "react";
import Select from "react-select";
import { getFormerStudentClasses, getStudentClasses } from "../../../api/student/studentApi";
import ClassListModel from "../../../model/dropdowns/classList";
import { ISelectElement } from "../../../model/interface/selectElement";
import Loader from "../loader";

interface StudentClassesProps {
  userId: number;
  isMulti?: boolean;
  includeFormerClass?: boolean;
  selectedItems: Array<ISelectElement>;
  className: string;
  onChange: (selectedItems: Array<ISelectElement>) => void;
}

function StudentClasses(props: StudentClassesProps & any) { 
  const [classes, setClasses] = useState<Array<ISelectElement>>();
  const [showLoading, setShowLoading] = useState<boolean>(false);

  const getClasses = () => {  
    setShowLoading(true);    
    var presentStudentClassesApi = getStudentClasses(props.userId, 0, 0);      
    
    var formerStudentClassesApi =  getFormerStudentClasses();
    Promise.all([presentStudentClassesApi,
                  formerStudentClassesApi          
      ])
      .then(([
        presentClassResponse,
        formerClassResponse
      ]) => {
        const presentClasses = presentClassResponse.data?.map((values: ClassListModel) => {
          return {
            label:
              values?.name +
              (values?.teacherName !== null ? " - " + values?.teacherName : ""),
            value: values?.classId.toString(),
          };
        });
  
        const formerClasses = formerClassResponse.data?.map((values: ClassListModel) => {
          return {
            label:
              values?.name +
              (values?.teacherName !== null ? " - " + values?.teacherName : ""),
            value: values?.classId.toString(),
          };
        });     
        setClasses(props.includeFormerClass ? presentClasses.concat(formerClasses) : presentClasses);   
        setShowLoading(false);  
      })
      .catch(() => setShowLoading(false));
      
  }

  useEffect(() => {
    getClasses();
  }, []);

  return (
    <Fragment>
      {showLoading && <Loader></Loader>}
      <div className={`flex flex-col ${props.className}`}>       
        <Select
          value={props.selectedItems}
          options={classes}
          isMulti={props.isMulti ?? false}
          isSearchable={true}
          onChange={props.onChange}
          closeMenuOnSelect={!(props.isMulti ?? false)}         
          placeholder={"All Classes"}
        />
      </div>
    </Fragment>
  );
}

export default StudentClasses;

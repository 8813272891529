import { XIcon } from "@heroicons/react/outline";
import { useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import AssignmentPrint from "./learnosityPrintPage";
import PrintConfirmationDialog from "./printConfrimationPopup";
import { LogoPLIcon } from "../../../../../assets/icons";
interface PrintDialogProp {
  assessmentId?: number;
  cannedTestId?: number;
  learnosityId: string;
  open: boolean;
  onCancelClick: () => void;
  className?: string;
  isAnswerKey: boolean;
  activityName: string;
  code: string;
  isAssignment?: boolean;
  isQuestionReference?: boolean;
}

export default function PrintDialog(props: PrintDialogProp) {
  let innerHTML = "";
  const componentRef = useRef<HTMLDivElement>(null);
  const [showPrintConfirmationPopup, setShowPrintConfirmationPopup] =
    useState<boolean>(false);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onBeforeGetContent: () => {
      return new Promise((resolve: Function) => {
        innerHTML = "";
        const element = componentRef.current;
        if (element) {
          const scrollableFeatures = element.getElementsByClassName(
            "lrn_scrollablepassage"
          );
          Array.from(scrollableFeatures || []).forEach((feature) => {
            feature.parentElement?.removeAttribute("style");
          });

          const tabFeatures = element.getElementsByClassName("tabs");
          if (tabFeatures && tabFeatures.length > 0) {
            changeTabsFormat(tabFeatures);
          }
          innerHTML = element.innerHTML;
          element.innerHTML = element.innerHTML
            ?.replace(/row col-separator/g, "row")
            .replace(/lrn lrn_widget lrn_feature lrn_scrollablepassage/g, "");
        }
        resolve();
      });
    },
    onAfterPrint: () => {
      if (componentRef.current && innerHTML?.length > 0) {
        componentRef.current.innerHTML = innerHTML;
      }
    },
  });

  function changeTabsFormat(tabs: HTMLCollection) {
    Array.from(tabs).forEach((tab) => {
      tab.innerHTML = tab.innerHTML
        .replace(/nav nav-tabs/g, "hidden")
        .replace(/tab-pane(?![ ]active)/g, "tab-pane active");
      const rowTab = tab.closest("div .row");
      if (rowTab) {
        rowTab.innerHTML = rowTab.innerHTML.replace(
          /col-xs-6/g,
          "col-xs-12 mb-6"
        );
      }
    });
  }

  function handleCancelConfirmPrintDialog() {
    setShowPrintConfirmationPopup(false);
  }
  const [disablePrintButton, setDisablePrintbutton] = useState<boolean>(true);
  return (
    <div className="fixed inset-0 z-40 flex items-center bg-black bg-opacity-50 sm:items-center sm:justify-center">
      {showPrintConfirmationPopup && (
        <PrintConfirmationDialog
          onCancelClick={handleCancelConfirmPrintDialog}
          open={showPrintConfirmationPopup}
          handlePrint={handlePrint}
        ></PrintConfirmationDialog>
      )}
      <div
        className="w-full flex flex-col justify-between overflow-hidden h-auto max-h-80percent bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:max-w-6xl"
        role="dialog"
      >
        <header>
          {/* Header */}
          <div className="px-2 py-2 bg-gray-50">
            <div className="flex items-start justify-between space-x-3">
              <div className="space-y-1">
                <div className="text-lg font-medium text-gray-900 flex items-center">
                  <LogoPLIcon
                    className="block lg:block h-16 w-auto pr-4"
                    title="Progress Learning logo"
                  ></LogoPLIcon>
                  {props.activityName}
                  {props.code && <>- [{props.code}]</>}
                </div>
              </div>
              <div className="h-7 flex items-center">
                <button
                  type="button"
                  className="text-gray-400 hover:text-gray-500"
                  onClick={() => props.onCancelClick()}
                >
                  <span className="sr-only">Close panel</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </header>

        {props.open && (
          <div className="flex flex-wrap overflow-auto">
            <div ref={componentRef} className="w-full p-12">
              <AssignmentPrint
                id={props.assessmentId}
                cannedTestId={props.cannedTestId}
                activityId={props.learnosityId}
                isAnswerKey={props.isAnswerKey}
                setDisablePrintbutton={setDisablePrintbutton}
                activityName={props.activityName}
                code={props.code}
                isAssignment={props.isAssignment}
                questionReference={props.isQuestionReference ? props.learnosityId : null}
              ></AssignmentPrint>
            </div>
          </div>
        )}
        {/* <!-- Action Buttons -->  */}
        <footer className="flex flex-col items-center justify-end px-6 py-4 sm:flex-row bg-gray-50 dark:bg-gray-800">
          <div className="flex-shrink-0">
            <div className="space-x-3 flex justify-end">
              <button
                disabled={disablePrintButton}
                className="ml-4 bg-secondary-teal border border-transparent shadow-sm py-2 px-4 justify-end text-sm font-medium text-white hover:bg-dark-teal hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 whitespace-nowrap"
                type="button"
                onClick={() => setShowPrintConfirmationPopup(true)}
              >
                Print
              </button>
              <button
                className="align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 rounded-lg text-sm text-gray-600 border-gray-300 border dark:text-gray-400 focus:outline-none active:bg-transparent hover:border-gray-500 focus:border-gray-500 active:text-gray-500 focus:shadow-outline-gray w-full sm:w-auto"
                type="button"
                onClick={() => {
                  props.onCancelClick();
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
}

import { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { OutlineStarIcon } from "../../../../../assets/icons";
import { AxiosHelper } from "../../../../../environment/axiosConfig";
import Profile from "../../../../../model/users/profile";
import { UserContextState } from "../../../../../redux/actions/userContextAction";
import APIConstant from "../../../../../utils/constant/apiConstant";
import constant from "../../../../../utils/constant/constant";
import RouteConstant from "../../../../../utils/constant/routeConstant";
import { useLocation } from "react-router-dom";
import {
  IStudentLiftOffMyGalaxy,
  MyGalaxyTableBulider,
} from "./myGalaxyTableBuilder";
import { Configuration } from "../../../../../environment/setup";
import { useConfirmation } from "../../../../shared/confirmation/confirmationService";
import { paramEncode } from "../../../../../utils/urlHelper";

interface IMygalaxyStandardProps {
  subjectId?: number;
  border?: string;
  userId?: number;
  userContext?: UserContextState;
  profile?: Profile;
  subjectName?: string;
}

function LiftOffMyGalaxy(props: IMygalaxyStandardProps) {
  const confirm = useConfirmation();
  const [standardsEntries, setStandardsEntries] = useState<any>([]);
  const [headers, setheaders] = useState<any>([]);
  const [liftoffHeaders, setLiftoffHeaders] = useState<any>([]);
  const history = useHistory();
  const location = useLocation();
  const backgroundColours = [
    {},
    { light: "bg-[#B5F9EB]", dark: "bg-[#84F5DE]" },
    { light: "bg-[#F591A9]", dark: "bg-[#EF476F]" },
    { light: "bg-[#FABAA6]", dark: "bg-[#F78C6B]" },
    { light: "bg-[#FFE3A3]", dark: "bg-[#FFD166]" },
    { light: "bg-[#B5E5B5]", dark: "bg-[#83D483]" },
    { light: "bg-[#B5F9EB]", dark: "bg-[#84F5DE]" },
    { light: "bg-[#F591A9]", dark: "bg-[#EF476F]" },
    { light: "bg-[#FABAA6]", dark: "bg-[#F78C6B]" },
    { light: "bg-[#FFE3A3]", dark: "bg-[#FFD166]" },
    { light: "bg-[#B5E5B5]", dark: "bg-[#83D483]" },
  ];
  function getStudentLiftOffMyGalaxyStandards(
    userId?: number,
    subjectId?: number
  ) {
    return AxiosHelper.axiosInstance().get(
      APIConstant.StudentLiftOffMyGalaxy_API.GetBySubjectId,
      {
        params: {
          userId: userId,
          subjectId: subjectId,
        },
      }
    );
  }
  function startAssignment(standard: any) {
    const mode =
      location.pathname.toLowerCase().indexOf("kindergarten") > -1
        ? "kindergarten"
        : location.pathname.toLowerCase().indexOf("elementary") > -1
        ? "elementary"
        : location.pathname.toLowerCase().indexOf("middleschool") > -1
        ? "middleschool"
        : "elementary";

    const gameChooseMode =
      mode === "kindergarten"
        ? constant.GameChooseMode.KinderGartenLiftOffMyGalaxyStandard
        : mode === "elementary"
        ? constant.GameChooseMode.ElementaryLiftOffMyGalaxyStandard
        : mode === "middleschool"
        ? constant.GameChooseMode.MiddleSchoolLiftOffMyGalaxyStandard
        : "";

    if (standard.isAssignmentInProgress) {
      const route =
        mode === "kindergarten"
          ? RouteConstant.LiftOff.Kindergarten.MyGalaxyActivity
          : mode === "elementary"
          ? RouteConstant.LiftOff.Elementary.MyGalaxyActivity
          : mode === "middleschool"
          ? RouteConstant.LiftOff.MiddleSchool.MyGalaxyActivity
          : "";

      history.push(
        route
          .replace(
            ":standardId",
            encodeURI(btoa(standard.standardId.toString()))
          )
          .replace(
            ":standardName",
            encodeURI(paramEncode(standard.standardName))
          )
          .replace(
            ":gameId",
            encodeURI(btoa(standard.gameId ? standard.gameId.toString() : "0"))
          )
          .replace(
            ":rocketId",
            encodeURI(
              btoa(standard.rocketId ? standard.rocketId.toString() : "0")
            )
          )
          .replace(
            ":blasterId",
            encodeURI(
              btoa(standard.blasterId ? standard.blasterId.toString() : "0")
            )
          )
          .replace(
            ":blasterType",
            encodeURI(
              btoa(standard.blasterId ? standard.blasterType.toString() : "0")
            )
          )
          .replace(
            ":subjectId",
            encodeURI(btoa(props.subjectId ? props.subjectId.toString() : "0"))
          )
          .replace(":subjectName", encodeURI(btoa(props.subjectName ?? "")))
          .replace(
            ":isStandardGrouped",
            encodeURI(btoa(standard.isStandardGrouped ?? ""))
          )
      );
    } else {
      const route =
        location.pathname.toLowerCase().indexOf("kindergarten") > -1
          ? RouteConstant.LiftOff.Kindergarten.GameChoose
          : location.pathname.toLowerCase().indexOf("elementary") > -1
          ? RouteConstant.LiftOff.Elementary.GameChoose
          : location.pathname.toLowerCase().indexOf("middleschool") > -1
          ? RouteConstant.LiftOff.MiddleSchool.GameChoose
          : "";

      history.push(
        route
          .replace(
            ":standardId",
            encodeURI(btoa(standard.standardId.toString()))
          )
          .replace(
            ":standardName",
            encodeURI(paramEncode(standard.standardName))
          )
          .replace(":mode", encodeURI(btoa(gameChooseMode)))
          .replace(
            ":subjectId",
            encodeURI(btoa(props.subjectId ? props.subjectId.toString() : "0"))
          )
          .replace(":subjectName", encodeURI(btoa(props.subjectName ?? "")))
          .replace(
            ":isStandardGrouped",
            encodeURI(btoa(standard.isStandardGrouped ?? ""))
          )
      );
    }
  }
  const getStandards = async () => {
    getStudentLiftOffMyGalaxyStandards(props.userId, props.subjectId).then(
      async (response) => {
        const data = response.data as IStudentLiftOffMyGalaxy;
        data?.liftOffHeaders?.forEach((loh) => {
          loh["isCompleted"] = false;
        });

        if (data.standards.length > 0) {
          setLiftoffHeaders(data.liftOffHeaders);
          setheaders(data.headers);
          let table = await new MyGalaxyTableBulider(data).build();
          setStandardsEntries(table);
          setLiftoffHeaders([...data.liftOffHeaders]);
        } else {
          setheaders([]);
          setStandardsEntries([]);
        }
      }
    );
  };

  const handleDisableStandards = (isDiagnosticTestCompleted: boolean) => {
    if (!isDiagnosticTestCompleted) {
      confirm({
        variant: "info",
        title: "",
        description:
          "You have not completed your diagnostic for this section. " +
          "Please take ths diagnostic first before attempting a topic in this section.",
      });
    }
  };

  useEffect(() => {
    if (props.subjectId !== 0) {
      getStandards();
    }
  }, [props.subjectId]);

  return (
    <Fragment>
      <div
        className={`shadow overflow-x-auto rounded-lg border-4 ${props.border}`}
      >
        <table className="text-xs text-left rounded-lg w-full">
          <thead>
            <tr className="text-xs font-normal bg-gray-700 text-center text-white">
              <th className="p-5"></th>
              {headers &&
                headers.length > 0 &&
                headers.map((header, index) => {
                  return (
                    <th key={index} colSpan={2} className="p-5">
                      {header}
                    </th>
                  );
                })}
            </tr>
          </thead>
          <tbody>
            <tr
              className={`bg-white  text-xs font-normal text-center text-white`}
            >
              {liftoffHeaders && liftoffHeaders.length > 0 && (
                <>
                  <td></td>
                  {liftoffHeaders.map((h, index) => {
                    return (
                      <td key={index} colSpan={2} className="p-5 relative">
                        <img
                          src={`${Configuration.S3bucketImagePath}/images/teacher/arena/rockets/rocket190001.png`}
                          className={
                            "absolute w-8 top-1 -left-4 space-x-1 " +
                            (!h.isCompleted ? "hidden" : "")
                          }
                          alt="Liftoff rocket"
                        ></img>
                      </td>
                    );
                  })}
                </>
              )}
            </tr>
            {standardsEntries.map((entry, index) => {
              return (
                <tr key={index}>
                  {entry.span > 0 && (
                    <td
                      className={`${
                        entry.galaxyId < backgroundColours.length
                          ? backgroundColours[entry.galaxyId].light
                          : backgroundColours[1].light
                      } w-10 ${entry.galaxy ? " h-48" : ""}        `}
                      rowSpan={entry.span}
                    >
                      <div className="transform rotate-90 text-center text-2xl uppercase whitespace-nowrap  w-10 flex justify-center">
                        {entry.galaxy}
                      </div>
                    </td>
                  )}
                  {headers &&
                    headers.length > 0 &&
                    headers.map((header, index) => {
                      return (
                        <Fragment key={index}>
                          <td className="w-1 bg-gray-700"></td>
                          <td
                            className={`relative ${
                              entry.galaxyId < backgroundColours.length
                                ? backgroundColours[entry.galaxyId]?.dark
                                : backgroundColours[1]?.dark
                            }`}
                          >
                            {entry[header]?.isDisplayRocket &&
                              entry[header].isDisplayRocket && (
                                <div className="pt-8">
                                  <img
                                    src={`${Configuration.S3bucketImagePath}/images/teacher/arena/rockets/rocket190001.png`}
                                    className="absolute w-8 top-1 -left-4"
                                    alt="Liftoff rocket"
                                  ></img>
                                </div>
                              )}
                            {entry[header]?.standardName && (
                              <>
                                {entry[header]?.isRocket && (
                                  <img
                                    src={`${Configuration.S3bucketImagePath}/images/teacher/arena/rockets/rocket190001.png`}
                                    className="absolute w-8 top-1 -left-4 space-x-1"
                                    alt="Liftoff rocket"
                                  ></img>
                                )}
                                {entry[header] &&
                                  entry[header].isStandardDisable && (
                                    <div
                                      className="flex items-start space-x-1 py-2 pr-2 pl-1 rounded my-1 mx-2.5 cursor-pointer bg-white bg-opacity-20"
                                      onClick={() => {
                                        handleDisableStandards(
                                          entry[header]
                                            .isDiagnosticTestCompleted
                                        );
                                      }}
                                    >
                                      {entry[header]?.earnedStar > 0 && (
                                        <OutlineStarIcon className="flex-none w-4" />
                                      )}
                                      {entry[header]?.earnedStar === 0 && (
                                        <OutlineStarIcon className="flex-none w-4 invisible" />
                                      )}
                                      <span className="opacity-90">
                                        {entry[header]?.standardName}
                                      </span>
                                    </div>
                                  )}
                                {entry[header] &&
                                  !entry[header].isStandardDisable && (
                                    <div
                                      className={
                                        entry[header]?.earnedStar > 0
                                          ? "flex items-start space-x-1 py-2 pr-2 pl-1 rounded my-1 mx-2.5 cursor-not-allowed  bg-white bg-opacity-70 pointer-events-none"
                                          : "flex items-start space-x-1 py-2 pr-2 pl-1 rounded my-1 mx-1.5 cursor-pointer  bg-white bg-opacity-90"
                                      }
                                      onClick={() =>
                                        startAssignment(entry[header])
                                      }
                                    >
                                      {entry[header]?.earnedStar > 0 && (
                                        <OutlineStarIcon className="flex-none w-4" />
                                      )}
                                      {entry[header]?.earnedStar === 0 && (
                                        <OutlineStarIcon className="flex-none w-4 invisible" />
                                      )}
                                      <span
                                        className={
                                          entry[header]?.earnedStar > 0
                                            ? "opacity-90"
                                            : ""
                                        }
                                      >
                                        {entry[header]?.standardName}
                                      </span>
                                    </div>
                                  )}
                              </>
                            )}
                          </td>
                        </Fragment>
                      );
                    })}
                </tr>
              );
            })}

            <tr
              className={`text-xs font-normal text-center text-white ${backgroundColours[1]?.dark}`}
            >
              <th className={`p-5 ${backgroundColours[1]?.light}`}></th>
              {headers &&
                headers.length > 0 &&
                headers.map((header, index) => {
                  return (
                    <td key={index} colSpan={2} className="p-5 relative">
                      <img
                        src={`${Configuration.S3bucketImagePath}/images/student/shared/rocket-launcher.png`}
                        className="absolute w-8 top-0 -top-1.5 -left-1"
                        alt="Liftoff rocket launcher"
                      ></img>
                    </td>
                  );
                })}
            </tr>
          </tbody>
        </table>
      </div>
    </Fragment>
  );
}

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(LiftOffMyGalaxy);

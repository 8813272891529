import { XIcon } from "@heroicons/react/outline";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { ClassApi } from "../../../../../../api/teacher/classManagementApi";
import PagingResponse from "../../../../../../model/common/pagingResponse";
import ClassTeacherModel from "../../../../../../model/teacher/classManagement/classTeacherModel";
import { UserContextState } from "../../../../../../redux/actions/userContextAction";
import {
  DEFAULT_PAGE_NUMBER,
  DEFAULT_PAGE_SIZE,
} from "../../../../../../utils/pagingConstant";
import ConfirmationDialog from "../../../../../shared/confirmationDialog";
import InfoMessage from "../../../../../shared/infoMessage";
import PageSize from "../../../../../shared/pagination/pageSize";
import Pagination from "../../../../../shared/pagination/pagination";

interface TeacherPopupProps {
  showPopUp: (show: boolean) => void;
  classId: number;
  userContext?: UserContextState;
  refreshParent?: () => void;
}

const TeacherPopup = (props: TeacherPopupProps) => {
  const filterCharList = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
    "All",
  ];
  const [schoolTeachers, setSchoolTeachers] =
    useState<PagingResponse<ClassTeacherModel>>();
  const [pageNumber, setPageNumber] = useState(DEFAULT_PAGE_NUMBER);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [startWith, setStartWith] = useState<string>("View All");
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [confirmationTitle, setConfirmationTitle] = useState("Confirm Action");
  const [confirmationMessage, setConfirmationMessage] =
    useState("Please confirm");
  const [selectAll, setSelectAll] = useState(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [resetPageNumber, setResetPageNumber] = useState<boolean>(false);

  useEffect(() => {
    getSchoolTeacher();
  }, [pageNumber, pageSize]);

  // function searchSchoolteachers(): void {
  //   setPageNumber(DEFAULT_PAGE_NUMBER);
  //   setPageSize(DEFAULT_PAGE_SIZE);
  //   getSchoolTeacher();
  // }

  function handleCharFilter(char: string) {
    setStartWith(char);
    getSchoolTeacher(char);
  }

  function getSchoolTeacher(charToSearch?: string): void {
    ClassApi.getClassPendingTeacher(
      props.classId,
      pageNumber,
      pageSize,
      props.userContext?.schoolAccountId,
      charToSearch ? charToSearch : startWith,
      props.userContext?.userId
    ).then((d) => {
      setSchoolTeachers(d.data);
    });
  }
  function selectTeacher(userId: any) {
    const teachers = schoolTeachers?.data.find((e) => e.userId === userId);
    if (teachers) {
      teachers.isSelected =
        teachers.isSelected === undefined ? true : !teachers.isSelected;
    }
    setIsDisabled(getSelectedTeachers().length === 0);
  }

  function selectAllteacher(): void {
    if (schoolTeachers?.data) {
      for (const key in schoolTeachers?.data) {
        const obj = schoolTeachers?.data[key];
        obj.isSelected = !selectAll;
      }
    }
    setSelectAll(!selectAll);
    setIsDisabled(getSelectedTeachers().length === 0);
  }
  function getSelectedTeachers() {
    if (schoolTeachers?.data) {
      const SelectedTeachers = schoolTeachers?.data?.filter((e) => {
        return e.isSelected === true;
      });
      return SelectedTeachers.map((e) => e.userId);
    }
    return [];
  }
  function addClassTeachers() {
    showConfirmation(
      "Please Confirm",
      "Are you sure you want to add the selected teachers to the class?"
    );
  }

  function addClassTeachersConfirm() {
    const selectedTeachers = getSelectedTeachers();
    ClassApi.addClassTeacher(
      props.userContext?.userId,
      props.classId,
      selectedTeachers
    ).then(() => {
      confirmationCancelClose();
      toast.success("Teacher(s) added successfully.");
      props.showPopUp(false);
      if (props.refreshParent !== undefined) props.refreshParent();
    });
  }

  function showConfirmation(title: string, message: string) {
    setOpenConfirmation(true);
    setConfirmationTitle(title);
    setConfirmationMessage(message);
  }

  function confirmationCancelClose() {
    setOpenConfirmation(false);
  }
  function confirmationOkClose() {
    addClassTeachersConfirm();
  }
  const onPageSizeChange = (pageSize) => {
    setPageNumber(DEFAULT_PAGE_NUMBER);
    setPageSize(pageSize);
    setResetPageNumber(true);
  };

  return (
    <div className="fixed inset-0 z-40 flex items-center bg-black bg-opacity-50 sm:items-center sm:justify-center">
      <div
        className="w-full flex flex-col justify-between overflow-hidden h-auto max-h-80percent bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:max-w-5xl"
        role="dialog"
      >
        <header>
          {/* Header */}
          <div className="px-4 py-6 bg-gray-50 sm:px-6">
            <div className="flex items-start justify-between space-x-3">
              <div className="space-y-1">
                <div className="text-lg font-medium text-gray-900">
                  Available Teachers
                </div>
              </div>
              <div className="h-7 flex items-center">
                <button
                  type="button"
                  className="text-gray-400 hover:text-gray-500"
                  onClick={() => props.showPopUp(false)}
                >
                  <span className="sr-only">Close panel</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </header>

        <div className="w-full relative overflow-auto px-4 py-3">
          <div className="p-1">
            <InfoMessage message="Select teachers to add to your class. They will automatically be set as secondary teachers." />
          </div>

          {/* <!-- Divider container -->  */}
          <div className="p-3 space-y-6 sm:py-0 sm:space-y-0 sm:divide-y sm:divide-gray-200 text-sm text-gray-700 dark:text-gray-400">
            <div>
              <div>
                <div className="mb-3">
                  <h1 className="font-bold text-primary-violet mb-2 ">
                    Available Teachers
                  </h1>

                  <div className="grid grid-cols-12 gap-6 flex items-center">
                    <div className="col-span-9 ">
                      <ul className="flex gap-2 sm:gap-1 capitalize cursor-pointer">
                        {filterCharList.map((ch) => {
                          if (ch === "All")
                            return (
                              <li
                                key={ch}
                                className={
                                  startWith === ch
                                    ? "bg-secondary-teal flex items-center justify-center w-10 h-5 text-sm text-white hover:opacity-70"
                                    : "flex items-center justify-center bg-secondary-teal w-10 h-5 text-sm text-white hover:opacity-70"
                                }
                                onClick={() => {
                                  handleCharFilter(ch);
                                }}
                              >
                                {ch}
                              </li>
                            );
                          else
                            return (
                              <li
                                key={ch}
                                className={
                                  startWith === ch
                                    ? "bg-secondary-teal flex items-center justify-center w-5 h-5 text-sm text-white hover:opacity-70"
                                    : "flex items-center justify-center bg-primary-blue w-5 h-5 text-sm text-white hover:opacity-70"
                                }
                                onClick={() => {
                                  handleCharFilter(ch);
                                }}
                              >
                                {ch}
                              </li>
                            );
                        })}
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="overflow-x-auto shadow">
                  <table className="table w-full overflow-auto">
                    <thead className="mb-5">
                      <tr className="bg-light-violet  text-white py-3 px-3 overflow-hidden">
                        <th className="text-left  p-3 text-sm font-normal w-4">
                          <label
                            x-radio-group-option=""
                            className="relative cursor-pointer"
                          >
                            <input
                              type="checkbox"
                              x-model="value"
                              name="privacy_setting"
                              value="Private to Project Members"
                              className="h-4 w-4 cursor-pointer text-primary-violet border-gray-300 focus:ring-primary-violet"
                              aria-labelledby="privacy-setting-1-label"
                              aria-describedby="privacy-setting-1-description"
                              checked={selectAll}
                              onChange={selectAllteacher}
                            />
                          </label>
                        </th>
                        <th className="text-left  p-3 text-sm font-normal">
                          {" "}
                          Teacher{" "}
                        </th>
                        <th className="text-left  p-3 text-sm font-normal">
                          {" "}
                          Email{" "}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {schoolTeachers?.data?.map((teacher, index) => {
                        return (
                          <tr
                            key={teacher.userId}
                            className={`hover:bg-light-violet/20 transition ease-in-out duration-300 ${
                              index % 2 === 0
                                ? "bg-white"
                                : "bg-light-violet/10"
                            }`}
                          >
                            <td className=" mt-5 p-3 text-center">
                              <input
                                type="checkbox"
                                className="h-4 w-4 cursor-pointer text-primary-violet border-gray-300 focus:ring-primary-violet"
                                key={teacher.userId}
                                defaultChecked={false}
                                checked={
                                  selectAll ? teacher.isSelected : undefined
                                }
                                onChange={() => {
                                  selectTeacher(teacher.userId);
                                }}
                              />
                            </td>
                            <td className="text-sm mt-5  p-3">
                              {teacher.lastName}, {teacher.firstName}
                            </td>
                            <td className="text-sm mt-5  p-3 text-left">
                              {teacher.email}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between mt-2">
                  {schoolTeachers?.totalRecords !== undefined &&
                    schoolTeachers?.totalRecords !== 0 && (
                      <Pagination
                        totalRecords={schoolTeachers?.totalRecords}
                        pageSize={pageSize}
                        pageNumber={pageNumber}
                        onPageChange={(e) => {
                          setPageNumber(e);
                        }}
                        reset={resetPageNumber}
                      />
                    )}
                  {schoolTeachers?.totalRecords !== 0 && (
                    <PageSize
                      pageSize={pageSize}
                      onPageSizeChange={onPageSizeChange}
                    />
                  )}
                  {schoolTeachers?.totalRecords === 0 && (
                    <span className="text-color-black">No record found</span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Action Buttons -->  */}
        <footer className="flex flex-col items-center justify-end px-6 py-4 sm:flex-row bg-gray-50 dark:bg-gray-800">
          <div className="flex-shrink-0">
            <div className="space-x-3 flex justify-end">
              <button
                className="disabled:opacity-50 align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 rounded-lg text-sm text-white border border-transparent bg-primary-green focus:shadow-outline-purple w-full sm:w-auto"
                disabled={isDisabled}
                type="button"
                onClick={addClassTeachers}
              >
                {" "}
                Add Teacher
              </button>
              <button
                className="align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 rounded-lg text-sm text-gray-600 border-gray-300 border dark:text-gray-400 focus:outline-none active:bg-transparent hover:border-gray-500 focus:border-gray-500 active:text-gray-500 focus:shadow-outline-gray w-full sm:w-auto"
                type="button"
                onClick={() => {
                  props.showPopUp(false);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </footer>
      </div>
      <ConfirmationDialog
        open={openConfirmation}
        confirmationTitle={confirmationTitle}
        confirmationMessage={confirmationMessage}
        onCancelClick={confirmationCancelClose}
        onOkClick={confirmationOkClose}
        className="fixed z-50 inset-0 overflow-y-auto"
      ></ConfirmationDialog>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(TeacherPopup);

import classNames from "classnames";
import { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { getStudentCannedTestSubject } from "../../../../../api/student/studentSubjectApi";
import { BookIcon } from "../../../../../assets/icons";
import { ICannedTestSubject } from "../../../../../model/interface/cannedTestSubject";
import Profile from "../../../../../model/users/profile";
import { UserContextState } from "../../../../../redux/actions/userContextAction";
import constant from "../../../../../utils/constant/constant";

interface ICannedTestSubjectProps {
  userContext?: UserContextState;
  profile?: Profile;
  isOpened: boolean;
  onSubjectSelected: (subjectId: number, subjectName: string) => void;
}

function CannedTestSubject(props: ICannedTestSubjectProps) {
  const [subjects, setSubjects] = useState<Array<ICannedTestSubject>>([]);
  const [selectedSubject, setSelectedSubject] = useState<ICannedTestSubject>();
  useEffect(() => {
    if (subjects.length === 0 && props.isOpened === true) loadSubjects();
  }, [props.isOpened]);

  function loadSubjects() {
    getStudentCannedTestSubject(props.userContext?.userId ?? 0, 0).then((d) => {
      const subjects = d.data.filter((s) => s.isPLPreTestActive === true);
      setSubjects(subjects);
      if (selectedSubject === undefined && subjects.length > 0) {
        setSelectedSubject(subjects[0]);
        props.onSubjectSelected(subjects[0].subjectId, subjects[0].name);
      }
    });
  }

  return (
    <Fragment>
      {props.isOpened}
      <div className="block p-1 rounded-2xl relative j">
        <div className="grid grid-cols-1 2xl:grid-cols-2 bg-white px-3 py-4 rounded-2xl gap-3">
          {subjects.map((s) => {
            return (
              <button
                key={s.subjectId}
                className={classNames(
                  "flex px-2 py-1.5 border-[3px] cursor-pointer items-center text-left rounded-lg ",
                  selectedSubject?.subjectId === s.subjectId
                    ? "bg-[#99bb07] text-white hover:bg-opacity-90 border-[#F9D228] border-dashed"
                    : "border-[#99bb07] opacity-70 hover:opacity-100"
                )}
                onClick={() => {
                  props.onSubjectSelected(s.subjectId, s.name);
                  setSelectedSubject(s);
                }}
              >
                <span className="mr-2 w-6 flex-none">
                  {s.subjectIcon && s.subjectIcon !== "null" && (
                    <img
                      className={`w-full  ${
                        selectedSubject?.subjectId === s.subjectId
                          ? "filter invert"
                          : ""
                      }`}
                      srcSet={s.subjectIcon}
                      alt="Subject Icon"
                    />
                  )}

                  {s.subjectIcon === "null" && (
                    <>
                      <BookIcon className="w-full" />
                    </>
                  )}
                </span>
                {props.profile?.plLanguageId === constant.Languages.Spanish
                  ? s.spanishName ?? s.name
                  : s.name}
              </button>
            );
          })}
        </div>
      </div>
    </Fragment>
  );
}
const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(CannedTestSubject);

import APIConstant from "../../utils/constant/apiConstant";
import { AxiosHelper } from "../../environment/axiosConfig";

export default function GetStudentByTeacherId(token: string, query: any) {
  return AxiosHelper.axiosInstance().get(
    APIConstant.CHAT_API.GET_STUDENT_BY_TEACHER_ID,
    {
      params: query,
    }
  );
}

import { IStandard } from "../../interface/standard";

export default class StudentRemediation {
  constructor(
    public studentId: number,
    public studentName: string,
    public classId: number,
    public standards: Array<IStandard>
  ) {}
}

import {useHistory} from "react-router-dom";
import {login} from "../../../api/login.service";
import Constant from "../../../utils/constant/constant";
import {jwtToken} from "../../shared/tokenHelper";
import * as userContextAction from "../../../redux/actions/userContextAction";
import LoginErrorResponse from "../../../model/loginErrorResponse";
import RouteConstant from "../../../utils/constant/routeConstant";
import {useEffect, useState} from "react";
import {fetchProfile} from "../../../redux/actions/userActions";
import {connect} from "react-redux";
import Loader from "../../shared/loader";

export const OAuthSSO = (props: any) => {
    const history = useHistory();
    const [showLoader, setShowLoader] = useState<boolean>(false);

    let idpName = "";

    async function loginWithToken() {
        setShowLoader(true);
        let search = window.location.search;
        let params = new URLSearchParams(search);

        //Check querystring to ensure code is present without errors
        const accessCode = params.get("code");
        const errorCode = params.get("err");
        if(errorCode || !accessCode) {
            showLoginFailed();
            return;
        }

        //Check that the identity provider name is found in the path (whitelist allowed sso provider names)
        const idpNameMatch = window.location.pathname.match(/\/sso\/(gwinnett)$/);
        if(idpNameMatch == null)
        {
            showLoginFailed();
            return;
        }

        try {
            idpName = idpNameMatch[1];
            const response = await login(
                "sso",
                accessCode,
                "",
                0,
                "",
                "",
                idpName,
                accessCode,
                false);
            console.log(response);

            if (!response) {
                showLoginFailed();
                return;
            }

            const authToken = response.data["access_token"];
            localStorage.setItem("AuthToken", authToken);
            const user: any = jwtToken(authToken);

            const userId = isNaN(user.userId) ? 0 : parseInt(user.userId);
            const roleId = isNaN(user.roleId) ? 0 : parseInt(user.roleId);
            const gradeId = isNaN(user.gradeId) ? 0 : parseInt(user.gradeId);
            const accountId = isNaN(user.accountId)
                ? 0
                : parseInt(user.accountId);
            const linkedUserId = user.linkedUserId;
            const schoolId =
                isNaN(user.schoolId) || user.schoolId === ""
                    ? 0
                    : parseInt(user.schoolId);
            const schoolAccountId =
                isNaN(user.schoolAccountId) || user.schoolAccountId === ""
                    ? 0
                    : parseInt(user.schoolAccountId);

            const context: userContextAction.UserContextState = {
                userId: userId,
                roleId: roleId,
                gradeId: gradeId,
                schoolId: schoolId,
                accountId: accountId,
                districtId: isNaN(user.districtId) ? 0 : parseInt(user.districtId),
                stateId: isNaN(user.stateId) ? 0 : parseInt(user.stateId),
                googleId: "",
                activeExternalRoster: "SSO",
                schoolAccountId: schoolAccountId,
                impersonatedUser: null,
                linkedUserId: linkedUserId,
            };

            props.setUserContext(context);
            setTimeout(() => {
                props.setProfile(userId, roleId, schoolId, 0);
                handleRedirection(roleId, gradeId, linkedUserId);
            }, 600);
        }
        catch (e) {
            console.log(e);
            const loginErrorResponse: LoginErrorResponse = e.response.data;
            if (loginErrorResponse && loginErrorResponse.isExpired) {
                handleShowAccountExpiryPage(loginErrorResponse);
            } else {
                showLoginFailed();
            }
        }
        setShowLoader(false);
    }
    const handleRedirection = (
        roleId: number,
        gradeId: number = 0,
        linkedUserId: string = ""
    ) => {
        console.log(roleId);
        console.log(gradeId);
        console.log(linkedUserId);

        let schoolSelectionUrl: string =
            RouteConstant.ROUTE_SCHOOL_SELECTION.replace(
                ":linkedUserId",
                linkedUserId
            ).replace(":loginType", idpName.toUpperCase());
        if (
            roleId === Constant.UserRoleId.SchoolAdmin ||
            roleId === Constant.UserRoleId.PayTeacher ||
            roleId === Constant.UserRoleId.FreeTeacher ||
            roleId === Constant.UserRoleId.SchoolTeacher ||
            roleId === Constant.UserRoleId.District ||
            roleId === Constant.UserRoleId.SuperAdmin ||
            roleId === 0
        ) {
            history.push(
                linkedUserId === ""
                    ? RouteConstant.ROUTE_DASHBOARD
                    : schoolSelectionUrl
            );
        } else if (roleId === Constant.UserRoleId.Student) {
            if (gradeId <= Constant.Grade.GRADE1) {
                console.log("kindergarten");
                history.push(
                    linkedUserId === ""
                        ? RouteConstant.KindergartenStudentRoutes.Home
                        : schoolSelectionUrl
                );
            } else if (
                gradeId > Constant.Grade.GRADE1 &&
                gradeId < Constant.Grade.GRADE6
            ) {
                console.log("elementary");
                history.push(
                    linkedUserId === ""
                        ? RouteConstant.StudentRoute.Home
                        : schoolSelectionUrl
                );
            } else if (gradeId >= Constant.Grade.GRADE6) {
                console.log("middleschool");
                history.push(
                    linkedUserId === ""
                        ? RouteConstant.MiddleSchool.assignments
                        : schoolSelectionUrl
                );
            } else {
                history.push(
                    linkedUserId === ""
                        ? RouteConstant.StudentRoute.Home
                        : schoolSelectionUrl
                );
            }
        } else {
            console.log("default case");
            history.push(
                linkedUserId === ""
                    ? RouteConstant.TeacherRoutes.assignmentCenter
                    : schoolSelectionUrl
            );
        }
    };

    function showLoginFailed() {
        history.push({
            pathname: RouteConstant.ROUTE_LOGIN,
            state: {
                loginFailedType: idpName,
            },
        });
    }

    const handleShowAccountExpiryPage = (errorResponse: LoginErrorResponse) => {
        history.push({
            pathname: RouteConstant.EXPIRED,
            state: { userRoleId: errorResponse.userRoleId },
        });
    };

    useEffect(() => {
        const login = async () => {
            await loginWithToken();
        }
        login().catch(console.error);
    }, []);

    return (
        <>
            {showLoader && <Loader></Loader>}
        </>
    );
};

const mapStateToProps = (state: any) => {
    return {
        profile: state.profile,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        setUserContext: (payload: userContextAction.UserContextState) => {
            dispatch(userContextAction.setUserContext(payload));
        },
        setProfile: (
            userId: number,
            roleId: number,
            schoolId: number,
            districtId: number
        ) => {
            dispatch(fetchProfile(userId, roleId, schoolId, districtId));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(OAuthSSO);
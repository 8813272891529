import React, { Fragment } from "react";
import VideoMessageData from "../../../model/videoMessage/videoMessageData";
import VideoCard from "./videoCard";


export interface VideoMessageCardViewProps {
    videoMessageData: VideoMessageData[],
    handleDelete: (messageId: number) => void,
    handleEdit: (data: any) => void,
}

const VideoMessageCardView: React.FC<VideoMessageCardViewProps> = (props) => {

    const handleDelete = (messageId: number) => {
        props.handleDelete(messageId);
    }

    const handleEdit = (data: any) => {
        props.handleEdit(data);
    }

    return (<Fragment>

        <ul className="space-y-12 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-9 sm:space-y-0 lg:grid-cols-4 lg:gap-x-8">
            {props.videoMessageData.map((item) => (
                <VideoCard
                    title={item.title}
                    teacherName={item.teacherName}
                    startDate={item.startDate}
                    endDate={item.endDate}
                    messageCreatedDate={item.messageCreatedDate}
                    grade={"Grade " + (item.grade)}
                    gradeId={item.gradeId}
                    location={"Location " + item.targetLocation}
                    role={"Teacher"}
                    mediaUrl={item.messageContent}
                    messageId={item.messageId}
                    linkedItemId={item.linkedItemId}
                    isActive={item.isActive}
                    classIds={item.classIds.split(",").map(Number)}
                    studentIds={item.studentIds.split(",").map(Number)}
                    handleDeleteEvent={(messageId) => { handleDelete(messageId) }}
                    handleEditEvent={(data) => { handleEdit(data) }}
                ></VideoCard>
            ))}
        </ul>
    </Fragment>);
}

export default VideoMessageCardView;
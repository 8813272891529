import { Fragment, useEffect, useState } from "react";
import CannedTestList from "../../../../../model/student/shared/cannedTest/cannedTestList";
import { getCannedTests } from "../../../../../api/student/cannedTestApi";
import ColumnHeader from "../../../../../model/gridView/columnHeader";
import Loader from "../../../../shared/loader";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import RouteConstant from "../../../../../utils/constant/routeConstant";
import Constant from "../../../../../utils/constant/constant";
import { EyeIcon, PlayIcon } from "@heroicons/react/outline";
import constant from "../../../../../utils/constant/constant";
import { getDiagnosticStatusClassname } from "../../../../../utils/assignmentHelper";

interface assignmentProps {
  subjectId: number;
  userId: number;
  isDiagnostics?: boolean;
  languageId?: number;
}

function TestList(props: assignmentProps) {
  const { t } = useTranslation();
  const { subjectId, userId } = props;
  const [loading, setShowLoading] = useState(false);
  const [tests, setTests] = useState<Array<CannedTestList>>([]);
  const [subjectName, setSubjectName] = useState("");
  const history = useHistory();

  useEffect(() => {
    getTests();
  }, [subjectId]);

  function getTests() {
    setShowLoading(true);
    getCannedTests(userId, subjectId, props.isDiagnostics ?? true)
      .then((r) => {
        setShowLoading(false);
        setTests(r.data);
        if (r.data[0]) {
          setSubjectName(
            props.languageId === constant.Languages.Spanish
              ? r.data[0].spanishSubjectName ?? r.data[0].subjectName
              : r.data[0].subjectName
          );
        }
      })
      .catch((r) => {
        setShowLoading(false);
      });
  }

  function getHeaders() {
    const columnHeaders: Array<ColumnHeader> = [
      new ColumnHeader(subjectName),
      new ColumnHeader(t("Diagnostics.TotalAnswered")),
      new ColumnHeader(t("Diagnostics.TotalCorrect")),
      new ColumnHeader(t("Diagnostics.OverallScore")),
      new ColumnHeader(t("Diagnostics.Status")),
      new ColumnHeader(""),
    ];

    return columnHeaders;
  }

  function onRowClick(test: CannedTestList) {
    history.push({
      pathname: RouteConstant.MiddleSchool.CannedTest.replace(
        ":cannedTestId",
        encodeURI(btoa(test.cannedTestId.toString()))
      ).replace(":subjectId", encodeURI(btoa(subjectId.toString()))),
      search: "",
      state: {
        cannedTestTypeId: test.cannedTestTypeId,
        subjectName: test.subjectName,
      },
    });
  }

  function redirectToReport(cannedTest: CannedTestList) {
    history.push({
      pathname: RouteConstant.MiddleSchool.StandardEfficiencyReport.replace(
        ":sessionId",
        cannedTest?.learnositySessionId
      ),
      state: {
        studentId: userId,
        learnosityReferenceId: cannedTest.learnosityReferenceId,
        learnositySessionId: cannedTest.learnositySessionId,
        subjectId: subjectId,
      },
    });
  }

  return (
    <Fragment>
      <div className="relative">
        {loading && <Loader />}

        <div>
          <div className="shadow overflow-x-auto rounded-lg border-black border-opacity-10 border-4">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  {getHeaders().map((header, index) => {
                    return (
                      <th
                        key={index}
                        scope="col"
                        className={`${
                          index === 0 || index === getHeaders().length - 2
                            ? "text-left"
                            : "text-center"
                        } px-3 py-3  text-xs font-medium text-secondary-teal uppercase tracking-wider`}
                      >
                        {header.title}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {tests.map((test, index) => (
                  <tr
                    key={index}
                    className={
                      index % 2 === 0 ? "bg-white bg-opacity-90" : "bg-white"
                    }
                  >
                    <td className="px-3 py-4  text-sm text-gray-500 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      {test.cannedTestType}
                    </td>
                    <td className="px-3 py-4  text-sm text-gray-500 text-center">
                      {test.questionCount}
                    </td>
                    <td className="px-3 py-4  text-sm text-gray-500 text-center">
                      {(test?.statusId ?? 0) ===
                      Constant.AssignmentStatusId.COMPLETE
                        ? test.correctAnswerCount
                        : "-"}
                    </td>
                    <td className="px-3 py-4  text-sm text-gray-500 text-center">
                      {(test?.statusId ?? 0) ===
                      Constant.AssignmentStatusId.COMPLETE
                        ? test.overAllScore.toFixed(2) + " %"
                        : "-"}
                    </td>
                    <td className="px-3 py-4  text-sm text-gray-500">
                      <div className="w-full flex">
                        <span
                          className={`text-white w-full px-3 py-0.5 whitespace-pre text-xs rounded-xl text-center ${getDiagnosticStatusClassname(
                            test.statusId,
                            test.status
                          )}`}
                        >
                          {test.status === "InProgress"
                            ? "In Progress"
                            : test.status}
                        </span>
                      </div>
                    </td>
                    <td className="px-3 py-4  text-sm text-gray-500 text-center">
                      {test.status !== Constant.CANNEDTEST_LOCKED &&
                        test.statusId <
                          Constant.AssignmentStatusId.COMPLETE && (
                          <button
                            className={`flex items-center mx-auto w-full justify-center  hover:bg-opacity-80  px-2  text-xs py-1 text-white rounded-lg p-1 space-x-0.5 
                          ${
                            test.statusId ===
                            Constant.AssignmentStatusId.INPROGRESS
                              ? "bg-blue-500"
                              : "bg-blues"
                          }
                          `}
                            onClick={() => onRowClick(test)}
                          >
                            {test.statusId ===
                              Constant.AssignmentStatusId.INPROGRESS && (
                              <span>{t("StudyPlan.Resume")}</span>
                            )}
                            {test.statusId <
                              Constant.AssignmentStatusId.INPROGRESS && (
                              <span>{t("StudyPlan.Start")}</span>
                            )}
                            <PlayIcon
                              className="w-5 cursor-pointer"
                              onClick={() => onRowClick(test)}
                            />
                          </button>
                        )}
                      {test.statusId ===
                        Constant.AssignmentStatusId.COMPLETE && (
                        <span title="View">
                          <EyeIcon
                            className="w-5 cursor-pointer"
                            onClick={() => {
                              redirectToReport(test);
                            }}
                          />
                        </span>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="mt-3 text-right text-white">
            {tests.length > 0 &&
              `Showing 1 to ${tests.length} of ${tests.length} entries`}
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default TestList;

import { useEffect, useState } from "react";
import { DownArrowIcon, UpArrow } from "../../../../../assets/icons";
import DotRank from "../../../../shared/widgets/dotRank";
import SortTableHeader from "../../../../shared/sortTableHeader";
import { SortingOrder } from "../../../../../types/type";
import { sortReport } from "../../../../../utils/sortProgressReportHelper";

export default function ProgressReportByTeacherByCount(props: any) {
  const [progressReportByTeacher, setProgressReportByTeacher] = useState<any>();

  useEffect(() => {
    if (!props?.data?.modified) {
      setPercentageForClasses();
    }
    props.data.modified = true;
    setProgressReportByTeacher(props.data);
    sortProgressReportByTeacher(props.data, props.activeSortColumn, props.orderType);
  }, []);

  const handleCollapse = (teacher, value) => {
    teacher.isCollapse = value;
    let _progressReportByTeacher = { ...progressReportByTeacher };
    setProgressReportByTeacher(_progressReportByTeacher);
  };

  const setPercentageForClasses = () => {
    const summaryIndexes = props.getSummaryIndexes(props.data);
    props.data?.teachers?.forEach((teacher) => {
      teacher.classes?.forEach((cl) => {
        cl.pctCorrect.forEach((pct, pctIndex) => {
          let value = pct != null ? Math.round(pct * 100) : -1;
          cl["lessThan65"] = cl["lessThan65"] ? cl["lessThan65"] : 0;
          cl["lessThan80"] = cl["lessThan80"] ? cl["lessThan80"] : 0;
          cl["greaterThan80"] = cl["greaterThan80"] ? cl["greaterThan80"] : 0;
          cl["default"] = cl["default"] ? cl["default"] : 0;
          switch (true) {
            case summaryIndexes.includes(pctIndex):
              break;
            case value === -1:
              cl["default"] = Number(cl["default"]) + 1;
              break;
            case value < 65:
              cl["lessThan65"] = Number(cl["lessThan65"]) + 1;
              break;
            case value >= 65 && value < 80:
              cl["lessThan80"] = Number(cl["lessThan80"]) + 1;
              break;
            case value >= 80:
              cl["greaterThan80"] = Number(cl["greaterThan80"]) + 1;
              break;
            default:
              cl["default"] = Number(cl["default"]) + 1;
              break;
          }
        });
        teacher["lessThan65"] =
          (teacher["lessThan65"] ? teacher["lessThan65"] : 0) +
          cl["lessThan65"];

        teacher["lessThan80"] =
          (teacher["lessThan80"] ? teacher["lessThan80"] : 0) +
          cl["lessThan80"];

        teacher["greaterThan80"] =
          (teacher["greaterThan80"] ? teacher["greaterThan80"] : 0) +
          cl["greaterThan80"];

        teacher["default"] =
          (teacher["default"] ? teacher["default"] : 0) + cl["default"];

        //Summary
        if (props.data?.teachers) {
          props.data.teachers[0]["lessThan65"] =
            (props.data.teachers[0]["lessThan65"]
              ? props.data.teachers[0]["lessThan65"]
              : 0) + cl["lessThan65"];

          props.data.teachers[0]["lessThan80"] =
            (props.data.teachers[0]["lessThan80"]
              ? props.data.teachers[0]["lessThan80"]
              : 0) + cl["lessThan80"];

          props.data.teachers[0]["greaterThan80"] =
            (props.data.teachers[0]["greaterThan80"]
              ? props.data.teachers[0]["greaterThan80"]
              : 0) + cl["greaterThan80"];

          props.data.teachers[0]["default"] =
            (props.data.teachers[0]["default"]
              ? props.data.teachers[0]["default"]
              : 0) + cl["default"];
        }
      });
    });
  };

  const sortTeacherClasses = (data: any, column: string, orderType: SortingOrder) => {
    if (data?.teachers == null) return
    data.teachers.forEach(teacher => {
      sortReport({data: teacher?.classes, column, orderType})
    });
  };

  const sortProgressReportByTeacher = (data: any, column: string, orderType: SortingOrder) => {
    sortReport({
      data: data?.teachers, column, orderType, defaultName: "All Teachers", defaultSurnameOrder: true
    });
    sortTeacherClasses(data, column, orderType);
    props.setOrderOptions(column, orderType);
  };

  return (
    <>
      {progressReportByTeacher && (
        <div className="pt-10 overflow-x-auto">
          <div className="overflow-x-auto">
            <table className="divide-y border-b w-full">
              <tr className="bg-light-violet text-white py-3 px-3">
                <th className="text-left p-3 text-sm font-normal">
                  <SortTableHeader
                    data={progressReportByTeacher}
                    orderType={props.orderType}
                    headerComponent="Teacher"
                    columnName="name"
                    activeSortColumn={props.activeSortColumn}
                    sortRecords={sortProgressReportByTeacher}
                  />
                </th>
                <th className="text-center p-3 text-sm font-normal">
                  <span className="flex justify-center">
                    <SortTableHeader
                      data={progressReportByTeacher}
                      orderType={props.orderType}
                      headerComponent="Student Count"
                      columnName="studentCount"
                      activeSortColumn={props.activeSortColumn}
                      sortRecords={sortProgressReportByTeacher}
                    />
                  </span>
                </th>
                <th className="text-center p-3 text-sm font-normal">
                  <span className="flex justify-center">
                    <SortTableHeader
                      data={progressReportByTeacher}
                      orderType={props.orderType}
                      headerComponent="Question Count"
                      columnName="questionCount"
                      activeSortColumn={props.activeSortColumn}
                      sortRecords={sortProgressReportByTeacher}
                    />
                  </span>
                </th>
                <th className="text-center p-3 text-sm font-normal">
                  <span className="flex justify-center">
                    <SortTableHeader
                      data={progressReportByTeacher}
                      orderType={props.orderType}
                      headerComponent={
                        <DotRank
                          value={86}
                          displayColorName={true}
                        />
                      }
                      columnName="greaterThan80"
                      activeSortColumn={props.activeSortColumn}
                      sortRecords={sortProgressReportByTeacher}
                    />
                  </span>
                </th>
                <th className="text-center p-3 text-sm font-normal">
                  <span className="flex justify-center">
                    <SortTableHeader
                      data={progressReportByTeacher}
                      orderType={props.orderType}
                      headerComponent={
                        <DotRank
                          value={65}
                          displayColorName={true}
                        />
                      }
                      columnName="lessThan80"
                      activeSortColumn={props.activeSortColumn}
                      sortRecords={sortProgressReportByTeacher}
                    />
                  </span>
                </th>
                <th className="text-center p-3 text-sm font-normal">
                  <span className="flex justify-center">
                    <SortTableHeader
                      data={progressReportByTeacher}
                      orderType={props.orderType}
                      headerComponent={
                        <DotRank
                          value={64}
                          displayColorName={true}
                        />
                      }
                      columnName="lessThan65"
                      activeSortColumn={props.activeSortColumn}
                      sortRecords={sortProgressReportByTeacher}
                    />
                  </span>
                </th>
                <th className="text-center p-3 text-sm font-normal">
                  <span className="flex justify-center">
                    <SortTableHeader
                      data={progressReportByTeacher}
                      orderType={props.orderType}
                      headerComponent={
                        <DotRank
                          value={undefined}
                          displayColorName={true}
                        />
                      }
                      columnName="default"
                      activeSortColumn={props.activeSortColumn}
                      sortRecords={sortProgressReportByTeacher}
                    />
                  </span>
                </th>
              </tr>

              {progressReportByTeacher.teachers.map((teacher, index) => (
                <>
                  <tr className="border-l border-r">
                    <td className="text-left text-sm font-normal flex">
                      <div
                        className="flex px-2 items-center cursor-pointer"
                        onClick={() =>
                          handleCollapse(teacher, !teacher.isCollapse)
                        }
                      >
                        {!teacher.isCollapse && index !== 0 && (
                          <UpArrow className="w-4 h-4 stroke-current text-gray-500 hover:text-gray-700" />
                        )}
                        {teacher.isCollapse && index !== 0 && (
                          <DownArrowIcon className="w-4 h-4 stroke-current text-gray-500 hover:text-gray-700" />
                        )}
                      </div>
                      <span
                        className={
                          (index === 0 ? "font-semibold " : "text-sm ") +
                          "text-left p-3 flex"
                        }
                      >
                        {teacher.name}
                      </span>
                    </td>
                    <td
                      className={
                        (index === 0 ? "font-semibold " : "text-sm ") +
                        "text-center p-3"
                      }
                    >
                      {teacher.studentCount}
                    </td>
                    <td
                      className={
                        (index === 0 ? "font-semibold " : "text-sm ") +
                        "text-center p-3"
                      }
                    >
                      {teacher.questionCount}
                    </td>
                    <td
                      className={
                        (index === 0 ? "font-semibold " : "text-sm ") +
                        "text-center p-3 "
                      }
                    >
                      {teacher.greaterThan80}
                    </td>
                    <td
                      className={
                        (index === 0 ? "font-semibold " : "text-sm ") +
                        "text-center p-3 "
                      }
                    >
                      {teacher.lessThan80}
                    </td>
                    <td
                      className={
                        (index === 0 ? "font-semibold " : "text-sm ") +
                        "text-center p-3  "
                      }
                    >
                      {teacher.lessThan65}
                    </td>
                    <td
                      className={
                        (index === 0 ? "font-semibold " : "text-sm ") +
                        "text-center p-3  "
                      }
                    >
                      {teacher.default}
                    </td>
                  </tr>

                  {!teacher.isCollapse &&
                    teacher?.classes?.map((cl, clindex) => (
                      <tr className="border-l border-r bg-primary-violet/10">
                        <td className="text-left p-3 pl-10 text-sm font-normal">
                          {cl.name}
                        </td>
                        <td className="text-center p-3 text-sm font-normal">
                          {cl.studentCount}
                        </td>
                        <td className="text-center p-3 text-sm font-normal">
                          {cl.questionCount}
                        </td>
                        <td className="text-center p-3 text-sm font-normal">
                          {cl.greaterThan80}
                        </td>
                        <td className="text-center p-3 text-sm font-normal">
                          {cl.lessThan80}
                        </td>
                        <td className="text-center p-3 text-sm font-normal">
                          {cl.lessThan65}
                        </td>
                        <td className="text-center p-3 text-sm font-normal">
                          {cl.default}
                        </td>
                      </tr>
                    ))}
                </>
              ))}
            </table>
          </div>
        </div>
      )}
    </>
  );
}

import React from "react";
import { CompletionInfo } from "./interface";

const AssignmentGroupedAssignmentExpandedIcon: React.FunctionComponent<
  CompletionInfo
> = ({ is_saved, past_due }: CompletionInfo) => {
  return (
    <div
      className={`flex-shrink-0 cursor-pointer h-12 w-12 bg-blues ${
        is_saved === 1 ? "bg-site-orange" : ""
      } ${
        past_due === 1 ? "bg-site-pink" : ""
      }  hover:opacity-90 rounded-full flex items-center justify-center`}
    >
      <svg
        className="w-5 h-5 fill-current stroke-current text-white"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 18.871 19.444"
      >
        <g
          id="Group_8"
          data-name="Group 8"
          transform="translate(-1345 -738.556)"
        >
          <g
            id="Rectangle_1"
            data-name="Rectangle 1"
            transform="translate(1345 742)"
            fill="none"
            stroke="#fff"
            strokeWidth="1"
          >
            <rect width="16" height="16" stroke="none"></rect>
            <rect x="0.5" y="0.5" width="15" height="15" fill="none"></rect>
          </g>
          <g id="Group_7" data-name="Group 7" transform="translate(0.5)">
            <line
              id="Line_11"
              data-name="Line 11"
              x2="7.935"
              transform="translate(1348.5 749.967)"
              fill="none"
              stroke="#fff"
              strokeWidth="1"
            ></line>
          </g>
          <g
            id="Group_16"
            data-name="Group 16"
            transform="translate(2102.934 -602.5) rotate(90)"
          >
            <line
              id="Line_11-2"
              data-name="Line 11"
              x2="7.935"
              transform="translate(1348.5 749.967)"
              fill="none"
              stroke="#fff"
              strokeWidth="1"
            ></line>
          </g>
          <path
            id="Path_46"
            data-name="Path 46"
            d="M1348.115,742.245v-3.189h15.255v15.5h-2.692"
            fill="none"
            stroke="#fff"
            strokeWidth="1"
          ></path>
        </g>
      </svg>
    </div>
  );
};

export default AssignmentGroupedAssignmentExpandedIcon;

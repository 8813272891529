import { AxiosHelper, axiosInstance } from "../../environment/axiosConfig";
import {
  IAccount,
  IAccountDetailSearch,
  ITrialSubscriptionRequest,
} from "../../model/interface/accountDetail";
import APIConstant from "../../utils/constant/apiConstant";
import { AxiosResponse } from "axios";

export function GetAccountDetail(accountSearch: IAccountDetailSearch) {
  return AxiosHelper.axiosInstance().get(
    APIConstant.Account_API.GetAccountDetails,
    {
      params: {
        userId: accountSearch.userId,
        schoolId: accountSearch.schoolId,
      },
    }
  );
}

export function verifyUserHasFullAccessSubscription(): Promise<
  AxiosResponse<boolean>
> {
  return AxiosHelper.axiosInstance().get(
    APIConstant.Account_API.GetUserHasFullAccessSubscription
  );
}

export function AddSubscriptionRequest(data: any) {
  return AxiosHelper.axiosInstance().post(
    APIConstant.Account_API.AddSubscriptionRequest,
    data
  );
}

export function SendPasswordResetEmail(
  userIds: number[]
): Promise<AxiosResponse<boolean>> {
  return AxiosHelper.axiosInstance().post(
    APIConstant.Account_API.SendPasswordResetEmail,
    userIds
  );
}

export function SendForgotPasswordEmail(
  accountLoginName: string,
  email: string
): Promise<AxiosResponse<IAccount>> {
  return axiosInstance.post(APIConstant.Account_API.SendForgotPasswordEmail, {
    accountLoginName: accountLoginName,
    email: email,
  });
}

export function AddTrialSubscriptionRequest(data: ITrialSubscriptionRequest) {
  return AxiosHelper.axiosInstance().post(
    APIConstant.Account_API.AddTrialSubscriptionRequest,
    data
  );
}

//Tests that a successful connection can be made to the api
//Setting timeout to 5 seconds, much shorter than a regular request since the server just needs to  return an ack
export async function Connection(): Promise<boolean> {
  try {
    let response = await AxiosHelper.axiosInstance().get(
        APIConstant.Account_API.Connection,
        {
          timeout: 5000
        });
    return response.status === 200;
  }
  catch(error){
    return false;
  }
}

import { useEffect, useState } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import DatePicker from "react-datepicker";
import Loader from "../../../../shared/loader";
import Breadcrumb from "../../../teacher/shared/breadcrumb";
import { IBreadcrumb } from "../../../../../model/common/breadcrumb";
import RouteConstant from "../../../../../utils/constant/routeConstant";
import Asterisk from "../../../../shared/asterisk";
import { getSchoolByDistrictId } from "../../../../../api/teacher/school";
import { ISelectElement } from "../../../../../model/interface/selectElement";
import Constant from "../../../../../utils/constant/constant";
import { useTranslation } from "react-i18next";
import { getLiftoffSubjects } from "../../../../../api/student/liftOff/diagnosticApi";
import {
  getLiftoffUsageReport,
  getLiftoffUsageReportExcel,
} from "../../../../../api/report/liftoffUsageReport";
import Pagination from "../../../../shared/pagination/pagination";
import PageSize from "../../../../shared/pagination/pageSize";
import {
  DEFAULT_PAGE_NUMBER,
  DEFAULT_PAGE_SIZE_10,
  DEFAULT_PAGE_SIZE_MAX_99999,
} from "../../../../../utils/pagingConstant";
import routeConstant from "../../../../../utils/constant/routeConstant";
import { useHistory } from "react-router-dom";
import { AxiosResponse } from "axios";
import { SchoolYearDropDown } from "../../../schoolYearDropdown";
import { getEndDate } from "../../../../../utils/dateHelper";

function LiftoffUsageReport(props) {
  const history = useHistory();
  const { t } = useTranslation();
  const defaultViewByOptions: Array<ISelectElement> = [
    {
      label: "Class",
      value: "1",
    },
    {
      label: "Grade",
      value: "2",
    },
  ];
  const defaultOptions: ISelectElement = {
    label: "Select",
    value: "0",
  };
  const viewBy = {
    class: "1",
    grade: "2",
  };
  const DefaultFilters = {
    school: defaultOptions,
    viewBy: defaultOptions,
    subject: defaultOptions,
    startDate: new Date(),
    endDate: new Date(),
  };
  const [isDistrictUser] = useState<boolean>(
    props.userContext.roleId === Constant.UserRoleId.District
  );
  const [isSchoolAdminUser] = useState<boolean>(
    props.userContext.roleId === Constant.UserRoleId.SchoolAdmin
  );
  const [isTeacherUser] = useState<boolean>(
    props.userContext.roleId === Constant.UserRoleId.SchoolTeacher
  );
  const [isViewByDisabled, setViewByDisabled] = useState<boolean>(
    props.userContext.roleId === Constant.UserRoleId.District
  );
  const [isSubjectsDisabled, setSubjectDisabled] = useState<boolean>(
    props.userContext.roleId === Constant.UserRoleId.District
  );
  const [loading, setLoading] = useState(false);
  const [schoolData, setSchoolData] = useState<Array<any>>([]);
  const [schools, setSchools] = useState<Array<ISelectElement>>([]);
  const [subjects, setSubjects] = useState<Array<ISelectElement>>([]);
  const [selectedViewBy, setSelectedViewBy] = useState(defaultOptions);
  const [selectedSchool, setSelectedSchool] = useState(defaultOptions);
  const [selectedSubject, setSelectedSubject] = useState(defaultOptions);
  const [minDate] = useState<Date | null>(Constant.PLMinDate);
  const [maxDate] = useState<Date>(new Date());
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [isInvalidStartDate, setIsInvalidStartDate] = useState<boolean>(false);
  const [isInvalidEndDate, setIsInvalidEndDate] = useState<boolean>(false);
  const [reportData, setReportData] = useState<any>([]);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState(DEFAULT_PAGE_NUMBER);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE_10);
  const [resetPageNumber, setResetPageNumber] = useState<boolean>(false);
  const [filters, setFilters] = useState(DefaultFilters);
  const [resetSchoolYear, setResetSchoolYear] = useState<boolean>(false);
  const [selectedSchoolYearId, setSelectedSchoolYearId] = useState<number>();

  const breadcrumbItems = () => {
    const items: Array<IBreadcrumb> = [
      { name: t("Breadcrumb.Home"), url: RouteConstant.ROUTE_DASHBOARD },
      { name: t("Breadcrumb.Reports"), url: RouteConstant.REPORTS },
      { name: t("Breadcrumb.LiftoffUsageReport"), url: "" },
    ];
    return items;
  };

  const getFilters = () => {
    const filter =
      "Filters : " +
      (filters.school.value !== "0" ? filters.school.label + ", " : " ") +
      filters.viewBy.label +
      ", " +
      filters.subject.label +
      ", " +
      (startDate ? startDate.toLocaleDateString() : "") +
      ", " +
      (endDate ? endDate.toLocaleDateString() : "");

    return filter;
  };

  const getSchools = async () => {
    const resp = await getSchoolByDistrictId(
      props.userContext?.districtId ?? 0
    );
    if (resp?.data?.length) {
      const data = resp?.data;
      setSchoolData(data);
      let schoolList: Array<ISelectElement> = [];
      resp?.data.forEach((school) => {
        if (!schoolList.find((sl) => sl.value === school.schoolId.toString())) {
          schoolList.push({
            label: school.name,
            value: school.schoolId.toString(),
          });
        }
      });
      setSchools(schoolList);
    }
  };

  const getSubjects = async (schoolId: number) => {
    const resp = await getLiftoffSubjects(schoolId);
    if (resp?.data?.length) {
      let subjectList: Array<ISelectElement> = [];
      resp?.data.forEach((subject) => {
        subjectList.push({
          label: subject.name,
          value: subject.id.toString(),
        });
      });
      setSubjects(subjectList);
    }
  };

  const getReport = () => {
    setLoading(true);

    const sDate = startDate ?? null;
    const eDate = endDate ?? null;

    setFilters({
      school: selectedSchool,
      subject: selectedSubject,
      viewBy: selectedViewBy,
      startDate: sDate!,
      endDate: eDate!,
    });

    getLiftoffUsageReport(
      Number(selectedSchool.value),
      Number(selectedSubject.value),
      Number(selectedViewBy.value),
      sDate,
      eDate,
      pageNumber,
      pageSize,
      selectedSchoolYearId ?? props.profile.currentSchoolYear.schoolYearId
    )
      .then((r) => {
        if (r.data.data && r.data.data.data.length > 0) {
          setReportData(r.data.data.data);
          setTotalRecords(r.data.data.totalRecords);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const getReportExcel = () => {
    setLoading(true);
    const sDate = startDate ?? null;
    const eDate = endDate ?? null;
    const filters = getFilters();

    getLiftoffUsageReportExcel(
      Number(selectedSchool.value),
      Number(selectedSubject.value),
      Number(selectedViewBy.value),
      sDate,
      eDate,
      DEFAULT_PAGE_NUMBER,
      DEFAULT_PAGE_SIZE_MAX_99999,
      filters,
      selectedSchoolYearId ?? props.profile.currentSchoolYear.schoolYearId
    )
      .then((r) => {
        if (r.data) {
          startFileDownload(r, "liftoffusagereport.xlsx");
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const startFileDownload = (
    response: AxiosResponse<any>,
    fileName: string
  ) => {
    const file = response.data;
    const fileURL = URL.createObjectURL(file);
    let link = document.createElement("a");
    link.href = fileURL;
    link.download = fileName;
    link.setAttribute("target", "_blank");
    document.body.appendChild(link);
    link.click();
  };

  const setDefaultStartDate = (schoolId: number) => {
    let dt = schoolData?.find((x) => x.schoolId === schoolId)?.startDate;
    setStartDate(dt ? dt : null);// : new Date(academicYear, 6, 1, 0, 0, 0));
  };

  const handleSchoolOnChange = (option: ISelectElement | null) => {
    if(option) {
      setSelectedSchool(option);
      getSubjects(Number(option.value));
      setSubjectDisabled(false);
      setViewByDisabled(false);
      setDefaultStartDate(Number(option.value));
    }
  };
  function handleSchoolYearLoaded(currentSchoolYearId?: number) {
    handleSchoolYearChange(currentSchoolYearId);
  }

  function handleSchoolYearChange(schoolYearId?: number) {
    setSelectedSchoolYearId(schoolYearId);
  }

  const handleCalendarOpen = (mode: string) => {
    switch (mode){
      case "end":
        if (!endDate) {
          setEndDate(getEndDate(new Date(), true));
        }
      break;
    }
  };

  const handleViewByOnChange = (viewBy: ISelectElement | null) => {
    if(viewBy) {
      setSelectedViewBy(viewBy);
    }    
  };

  const handleSubjectOnChange = (subject: ISelectElement | null) => {
    if(subject) {
      setSelectedSubject(subject);
    }
  };

  const handleStartDateChange = (date: Date) => {
    if (!date) {
      setIsInvalidStartDate(false); // Allow nulls
      setIsInvalidEndDate(false)
      setStartDate(date);
      return;
    }
    if (
        endDate &&
        getDateWithoutTime(date) >
        getDateWithoutTime(endDate)
    ) {
      setIsInvalidStartDate(true);
    } else {
      setIsInvalidStartDate(false);
    }
    setStartDate(date);
  };

  const handleEndDateChange = (date: any) => {
    if (!date) {
      setIsInvalidEndDate(false); // Allow nulls
      setIsInvalidStartDate(false);
      setEndDate(date);
      return;
    }
    if (
        startDate &&
        getDateWithoutTime(date) <
        getDateWithoutTime(startDate)
    ) {
      setIsInvalidEndDate(true);
    } else {
      setIsInvalidEndDate(false);
    }
    setEndDate(date);
  };

  const onPageChange = (pageNo) => {
    setPageNumber(pageNo);
  };

  const onPageSizeChange = (pageSize) => {
    setPageNumber(DEFAULT_PAGE_NUMBER);
    setPageSize(pageSize);
    setResetPageNumber(true);
  };

  const isFiltersValid = () => {
    let isValid = false;
    if (isDistrictUser) {
      isValid =
        Number(selectedSchool.value) > 0 &&
        Number(selectedViewBy.value) > 0 &&
        Number(selectedSubject.value) > 0 &&
        !isInvalidStartDate && !isInvalidEndDate;
    } else if (isSchoolAdminUser) {
      isValid =
        Number(selectedViewBy.value) > 0 &&
        Number(selectedSubject.value) > 0 &&
        !isInvalidStartDate && !isInvalidEndDate;
    } else if (isTeacherUser) {
      isValid = Number(selectedSubject.value) > 0 && !isInvalidStartDate && !isInvalidEndDate;
    }
    return isValid;
  };

  const onReset = () => {
    if (isDistrictUser) {
      setSelectedSchool(defaultOptions);
      setSelectedViewBy(defaultOptions);
      setViewByDisabled(true);
      setSubjectDisabled(true);
    } else if (isSchoolAdminUser) {
      setSelectedViewBy(defaultOptions);
    }

    setSelectedSubject(defaultOptions);
    setDefaultStartDate(0);
    setEndDate(new Date());
    setReportData([]);
  };

  const onRunReport = () => {
    setReportData([]);
    setTotalRecords(0);
    setPageNumber(DEFAULT_PAGE_NUMBER);
    getReport();
  };

  const onExport = () => {
    getReportExcel();
  };

  const redirectToStudentLevel = (classId: number, gradeId: number, schoolYearId: number | undefined) => {
    let url = "";
    if (classId > 0) {
      url = routeConstant.TeacherRoutes.liftoffUsageStudentLevelByClassReport
        .replace(":schoolId", filters.school.value)
        .replace(":classId", String(classId))
        .replace(":subjectId", filters.subject.value);
    } else if (gradeId > 0) {
      url = routeConstant.TeacherRoutes.liftoffUsageStudentLevelByGradeReport
        .replace(":schoolId", filters.school.value)
        .replace(":gradeId", String(gradeId))
        .replace(":subjectId", filters.subject.value);
    }

    history.push({
      pathname: url,
      state: { startDate: startDate, endDate: endDate },
      search: schoolYearId && schoolYearId > 0 ? "schoolYearId=" + schoolYearId : "",
    });
  };

  useEffect(() => {
    if (isDistrictUser) {
      getSchools();
    } else {
      getSubjects(props.userContext.schoolId);
      setDefaultStartDate(props.userContext.schoolId);
      setSelectedSchool({
        label: "",
        value: String(props.userContext.schoolId),
      });
    }
  }, [isDistrictUser]);

  useEffect(() => {
    if (isTeacherUser) {
      setSelectedViewBy({
        label: "",
        value: viewBy.class,
      });
    }
  }, [isTeacherUser]);

  useEffect(() => {
    if (isFiltersValid()) {
      getReport();
    }
  }, [pageSize, pageNumber, resetPageNumber]);

  useEffect(() => {
    if (selectedSchoolYearId) {
      setResetSchoolYear(false);
    }
  }, [selectedSchoolYearId]);

  function getDateWithoutTime(date: Date) {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
  }

  return (
    <div className="min-w-0 w-full">
      {loading && <Loader />}
      <div className="lg:flex lg:items-center px-4 py-3 lg:px-8 border-b">
        <Breadcrumb items={breadcrumbItems()} />
        <div className="lg:w-[55%] text-lg font-medium flex gap-2 h-auto sm:h-10 items-center">
          <h1 className="text-primary-violet font-medium flex justify-start mb-0 pb-0">
            Liftoff Usage Report
          </h1>
        </div>
      </div>
      <div className="px-2 sm:px-6  lg:px-8 py-1 relative my-4">
        <div className="bg-gray-100 w-full rounded-lg p-5">
          <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-4 mb-4">
            <div className="flex flex-col">
              <label className="block mb-1 text-sm text-gray-500">
                School Year <Asterisk/>
              </label>
              <SchoolYearDropDown
                reset={resetSchoolYear}
                offsets={[0, -1]}
                handleSchoolYearChange={handleSchoolYearChange}
                handleSchoolYearLoaded={handleSchoolYearLoaded}
                handleSchoolYearData={() => {}}
              ></SchoolYearDropDown>
            </div>
            {isDistrictUser && (
              <div className="flex flex-col">
                <label className="block mb-1 text-sm text-gray-500">
                  School <Asterisk/>
                </label>
                <Select
                  placeholder={"Select School"}
                  options={schools}
                  value={selectedSchool}
                  onChange={(e) => handleSchoolOnChange(e)}
                />
              </div>
            )}

            {(isDistrictUser || isSchoolAdminUser) && (
              <div className="flex flex-col">
                <label className="block mb-1 text-sm text-gray-500">
                  View By <Asterisk/>
                </label>
                <Select
                  placeholder={"Select View By"}
                  value={selectedViewBy}
                  isDisabled={isViewByDisabled}
                  options={defaultViewByOptions}
                  onChange={(e) => {
                    handleViewByOnChange(e);
                  }}
                ></Select>
              </div>
            )}

            <div className="flex flex-col">
              <label className="block mb-1 text-sm text-gray-500">
                Subject <Asterisk/>
              </label>
              <Select
                placeholder={"Select Subject"}
                value={selectedSubject}
                options={subjects}
                isDisabled={isSubjectsDisabled}
                onChange={(e) => {
                  handleSubjectOnChange(e);
                }}
              ></Select>
            </div>
            <div className="flex flex-col">
              <label className="block mb-1 text-sm text-gray-500">
                Start Date
              </label>

              <DatePicker
                tabIndex={7}
                minDate={minDate}
                maxDate={maxDate}
                selected={startDate}
                onChange={handleStartDateChange}
                isClearable={true}
                onCalendarOpen={() => { handleCalendarOpen("start") }}
                className="react-datepicker__input-container py-1.5 px-3 block w-full shadow-sm focus:ring-primary-violet focus:border-primary-violet border border-gray-300 rounded "
              />
              {isInvalidStartDate && (
                <span className="pl-2 text-red-600">Invalid Date</span>
              )}
            </div>
            <div className="flex flex-col">
              <label className="block mb-1 text-sm text-gray-500">
                End Date
              </label>

              <DatePicker
                tabIndex={8}
                minDate={minDate}
                maxDate={maxDate}
                selected={endDate}
                onChange={handleEndDateChange}
                isClearable={true}
                onCalendarOpen={() => { handleCalendarOpen("end") }}
                className="react-datepicker__input-container py-1.5 px-3 block w-full shadow-sm focus:ring-primary-violet focus:border-primary-violet border border-gray-300 rounded "
              />
              {isInvalidEndDate && (
                <span className="pl-2 text-red-600">Invalid Date</span>
              )}
            </div>
          </div>

          <div className="flex gap-4 justify-end">
            <button
              onClick={onReset}
              tabIndex={2}
              className="bg-transparent border my-auto border-primary-violet shadow-sm py-2 px-4 flex justify-center text-sm font-medium text-primary-violet hover:text-white hover:bg-light-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3 transition ease-in-out duration-500"
            >
              Reset
            </button>
            <div className="cursor-not-allowed">
              <button
                tabIndex={1}
                onClick={onRunReport}
                className={
                  "bg-primary-violet border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 " +
                  (isFiltersValid()
                    ? "hover:bg-dark-violet hover:shadow-lg"
                    : "bg-opacity-20 text-primary-violet text-opacity-40 pointer-events-none")
                }
              >
                Run Report
              </button>
            </div>
            <div className="cursor-not-allowed">
              <button
                onClick={onExport}
                className={
                  "bg-primary-violet border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 " +
                  (reportData.length > 0
                    ? "hover:bg-dark-violet hover:shadow-lg"
                    : "bg-opacity-20 text-primary-violet text-opacity-40 pointer-events-none")
                }
              >
                Export Data
              </button>
            </div>
          </div>

          <div className="border-b border-gray-400 py-2 mb-2"></div>
          {reportData.length > 0 && (
            <div className="bg-white px-3 pt-2 pb-4">
              <div className="overflow-x-auto xl:overflow-x-visible mt-3 shadow">
                <table className="table w-full ">
                  <thead className="mb-5">
                    <tr className="bg-light-violet  text-white py-3 px-3 w-full">
                      <th
                        scope="col"
                        className="text-left p-3 text-sm font-normal"
                      >
                        {filters.viewBy.value === viewBy.class
                          ? "Class"
                          : "Grade"}
                      </th>
                      <th
                        scope="col"
                        className="text-center  p-3 text-sm font-normal"
                      >
                        <div className="w-full flex flex-col">
                          <div>Hours Worked</div>
                          <div>(DD:HH:MM:SS)</div>
                        </div>
                      </th>
                      <th
                        scope="col"
                        className="text-center p-3 text-sm font-normal"
                      >
                        Questions Answered
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {reportData.map((r, index) => (
                      <>
                        <tr
                          key={index}
                          className={
                            index % 2 === 0
                              ? " hover:bg-light-violet/20 transition ease-in-out duration-300 bg-white"
                              : "hover:bg-light-violet/20 transition ease-in-out duration-300 bg-light-violet/10"
                          }
                          w-full
                          border-b
                        >
                          <td>
                            <div
                              className={`flex py-3 px-2 items-center cursor-pointer`}
                              onClick={() => {
                                redirectToStudentLevel(r.classId, r.gradeId, selectedSchoolYearId);
                              }}
                            >
                              <span className="ml-2 text-primary-violet underline">
                                {filters.viewBy.value === viewBy.class
                                  ? r.className
                                  : r.gradeName}
                              </span>
                            </div>
                          </td>
                          <td>
                            <span className="flex justify-center">
                              {r.hoursWorked}
                            </span>
                          </td>
                          <td>
                            <span className="flex justify-center">
                              {r.questionsAnswered}
                            </span>
                          </td>
                        </tr>
                      </>
                    ))}
                  </tbody>
                </table>
              </div>

              {reportData.length > 0 && (
                <div className="sm:flex-1 sm:flex sm:items-center sm:justify-between border-t-2 pt-4 mt-5">
                  {totalRecords > 0 && (
                    <Pagination
                      totalRecords={totalRecords}
                      pageSize={pageSize}
                      onPageChange={onPageChange}
                      reset={resetPageNumber}
                    />
                  )}
                  {totalRecords > 0 && (
                    <PageSize
                      pageSize={pageSize}
                      onPageSizeChange={onPageSizeChange}
                    />
                  )}
                </div>
              )}
            </div>
          )}

          {reportData.length === 0 && (
            <div className="bg-white px-3 pt-2 pb-4">
              <span className="text-color-black">No record found</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default connect((state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
})(LiftoffUsageReport);

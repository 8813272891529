import moment from "moment";
import { useEffect, useState } from "react";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useTranslation } from "react-i18next";
import { getScoreLabel } from "../../../../../../utils/scoreHelper";
function OverviewResult({ data }) {
  const { t } = useTranslation();
  const [diagnosticOverviewResult, setDiagnosticOverviewResult] =
    useState<any>();
  const getRingColor = (value) => {
    let ringColor = "";
    switch (true) {
      case value < 63:
        ringColor = "#E53F71";
        break;
      case value >= 63 && value < 80:
        ringColor = "#FFDB3A";
        break;
      case value >= 80:
        ringColor = "#B2D136";
        break;
      default:
        ringColor = "bg-gray-700";
        break;
    }
    return ringColor;
  };
  function overview() {
    if (data) {
      const overviewResultInput = {
        results_header: {
          assignment: {
            result: {
              retry_percent: 0,
              percent: Math.round(
                (Number(data.totalScore) / Number(data.totalAttempted)) * 100
              ),
              totalAttempted: data.totalAttempted,
              totalScore: data.totalScore,
            },
          },
          elapsed_time: data.sessionDuration,
          date_completed: data.dateCompleted,
          test: {
            name: data.subjectName,
          },
          teacher: {
            first: data.teacherFirstName,
            last: data.teacherLastName,
          },
          student: {
            first: data.studentFirstName ? data.studentFirstName : "",
            last: data.studentLastName ? data.studentLastName : "",
          },
          class: {
            name: data.class,
          },
        },
      };
      setDiagnosticOverviewResult(overviewResultInput);
    }
  }
  useEffect(() => {
    overview();
  }, []);

  function needsGrading(): boolean {
    return (
      diagnosticOverviewResult?.results_header?.assignment?.result?.unmarked
    );
  }

  return (
    <>
      {diagnosticOverviewResult && (
        <div className="flex-none md:w-64 h-auto bg-blue-400 rounded-md shadow-md xl:mr-12 md:mr-12 shadow-lg">
          <div className="text-md text-white text-center px-12 md:px-5 ">
            <h2 className="text-lg text-white font-medium uppercase text-center my-4">
              {t("LiftOffDiagnosticResult.Score")}
            </h2>
            {needsGrading() ? (
              <div
                style={{
                  width: 160,
                  height: 160,
                  display: "inline-grid",
                  fontSize: "17px;",
                }}
              >
                <CircularProgressbar
                  value={0}
                  text={`${diagnosticOverviewResult?.results_header?.assignment?.result?.percent}`}
                  styles={buildStyles({
                    pathColor: `${getRingColor(
                      diagnosticOverviewResult?.results_header?.assignment
                        ?.result?.percent
                    )}`,
                    textColor: "#374151",
                  })}
                />
              </div>
            ) : (
              <div
                style={{
                  width: 140,
                  height: 140,
                  display: "inline-grid",
                  position: "relative",
                }}
              >
                <CircularProgressbar
                  value={
                    diagnosticOverviewResult?.results_header?.assignment?.result
                      ?.percent
                  }
                  text={`${
                    diagnosticOverviewResult?.results_header?.assignment?.result
                      ?.retry_percent ||
                    diagnosticOverviewResult?.results_header?.assignment?.result
                      ?.percent
                  }%`}
                  styles={buildStyles({
                    pathColor: `${getRingColor(
                      diagnosticOverviewResult?.results_header?.assignment
                        ?.result?.percent
                    )}`,
                    textColor: "#374151",
                  })}
                  className="circular-bar-pending-size"
                />
                <span className="absolute top-1/2 left-1/2 transform -translate-x-2/4 -translate-y-2/4 pt-11 text-white">
                  (
                  {
                    getScoreLabel(diagnosticOverviewResult?.results_header?.assignment?.result
                      ?.totalScore)
                  }
                  /(
                  {
                    getScoreLabel(diagnosticOverviewResult?.results_header?.assignment?.result
                      ?.totalAttempted)
                  }
                  )
                </span>
              </div>
            )}
          </div>
          {<div className="w-full text-lg text-white text-center mb-4"></div>}

          <div className="flex flex-none flex-col bg-white px-7 py-4 mx-4 rounded-md mb-8 mt-10 shadow-lg">
            <div className="flex w-full flex-none justify-between">
              <div className="capitalize font-myriad font-semibold text-md">
                {t("LiftOffDiagnosticResult.Score")}
              </div>

              <div className="h-6 ml-3 inline-block text-black-333 flex items-center px-2 font-roboto text-sm font-bold">
                {
                  diagnosticOverviewResult?.results_header?.assignment?.result
                    ?.percent
                }
                %
                <span className="text-xs pl-1">
                  (
                  {
                    diagnosticOverviewResult?.results_header?.assignment?.result
                      ?.totalScore
                  }
                  /
                  {
                    diagnosticOverviewResult?.results_header?.assignment?.result
                      ?.totalAttempted
                  }
                  )
                </span>
              </div>
            </div>
            <div className="w-full block mt-2 relative">
              <div className="w-full h-2 bg-gray-300 rounded-md"></div>
              <div
                style={{
                  width: `${diagnosticOverviewResult?.results_header?.assignment?.result.percent}%`,
                }}
                className={
                  "h-2 bg-[" +
                  getRingColor(
                    diagnosticOverviewResult?.results_header?.assignment?.result
                      ?.percent
                  ) +
                  "] rounded-md absolute top-0 z-0"
                }
              ></div>
            </div>
          </div>

          <div className="mt-10 flex flex-col flex-none mx-7 text-white border-white border-b border-opacity-25 pb-2 mb-2">
            <div className="text-xs text-light-teal capitalize">
              {t("LiftOffDiagnosticResult.DateCompleted")}
            </div>
            <div className="text-white font-roboto font-bold capitalize">
              {moment(
                diagnosticOverviewResult?.results_header?.date_completed
              ).format("MM/DD/YYYY")}
            </div>
          </div>
          <div className="flex flex-col flex-none mx-7 text-white border-white border-b border-opacity-25 pb-1 mb-3">
            <div className="text-xs text-white capitalize">
              {t("LiftOffDiagnosticResult.ElapsedTime")}
            </div>
            <div className="text-white font-roboto font-bold">
              {moment()
                .startOf("day")
                .add(
                  diagnosticOverviewResult?.results_header?.elapsed_time,
                  "seconds"
                )
                .format("HH:mm:ss")}
            </div>
          </div>

          <div className="flex flex-col flex-none mx-7 text-white border-white border-b border-opacity-25 pb-2 mb-3">
            <div className="text-xs text-white capitalize">{t("StudyPlanResult.Subject")}</div>
            <div className="text-white text-md font-roboto font-bold capitalize">
              {diagnosticOverviewResult?.results_header?.test
                ? `${diagnosticOverviewResult?.results_header?.test.name}`
                : "-"}{" "}
            </div>
          </div>
          <div className="flex flex-col flex-none mx-7 text-white border-white border-b border-opacity-25 pb-2 mb-3">
            <div className="text-xs text-white capitalize">{t("StudyPlanResult.Teacher")}</div>
            <div className="text-white text-md font-roboto font-bold capitalize">
              {Object.keys(diagnosticOverviewResult?.results_header?.teacher)
                .length > 0 &&
                `${diagnosticOverviewResult?.results_header?.teacher?.first} ${diagnosticOverviewResult?.results_header?.teacher?.last}`}
              {Object.keys(diagnosticOverviewResult?.results_header?.teacher)
                .length === 0 && (
                <div className="text-xs font-semibold capitalize cursor-pointer text-white">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5 stroke-current text-white inline"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                    />
                  </svg>
                  {""} {t("LiftOffDiagnosticResult.AddTeacher")}
                </div>
              )}
            </div>
          </div>
          <div className="flex flex-col flex-none mx-7 text-white border-white border-b border-opacity-25 pb-2 mb-3">
            <div className="text-xs text-white capitalize">{t("StudyPlanResult.Class")}</div>
            <div className="text-white text-md font-roboto font-bold capitalize">
              {diagnosticOverviewResult?.results_header?.class &&
              Object.keys(diagnosticOverviewResult?.results_header?.class)
                .length > 0
                ? `${diagnosticOverviewResult?.results_header?.class.name}`
                : "-"}{" "}
            </div>
          </div>
          <div className="flex flex-col flex-none mx-7 text-white border-white border-b border-opacity-25 pb-2 mb-3">
            <div className="text-xs text-white capitalize">
              {" "}
              {t("StudyPlanResult.StudentName")}
            </div>
            <div className="text-white text-md font-roboto font-bold capitalize">
              {Object.keys(diagnosticOverviewResult?.results_header?.student)
                .length > 0 &&
                `${diagnosticOverviewResult?.results_header?.student?.first} ${diagnosticOverviewResult?.results_header?.student?.last}`}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
export default OverviewResult;

import { Fragment, useEffect, useRef, useState } from "react";
import { InformationIcon } from "../../assets/icons/index";

interface IInfoNote {
  message: string;
  className?: string;
}

function InfoNote(props: IInfoNote) {
  const ref: any = useRef(null);
  const [show, setShow] = useState(false);

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setShow(false);
      }
    }
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [ref]);

  return (
    <Fragment>
      <span
        className={`${
          show ? "block" : "hidden"
        } w-56 transition duration-800 ease-in-out absolute bg-white text-black z-10 rounded-lg rounded-bl-0 border border-black border-opacity-25 p-2 text-xs`}
      >
        {props.message}
      </span>
      <InformationIcon
        ref={ref}
        className={`${
          props.className ?? "flex-shrink-0 cursor-pointer w-5 h-5"
        }`}
        onClick={() => setShow(!show)}
      />
    </Fragment>
  );
}

export default InfoNote;

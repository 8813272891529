import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import TOSSessions from './tosSessions';
import TOSGameSetup from './tosGameSetup';
import TOSGamePlay from './tosGamePlay';
import { TOSScreens, setupAction } from '../../../../../api/classroomGame/thinkOrSwim';

function ThinkOrSwim(props) {
  const [SelectedScreen, setSelectedScreen] = useState<TOSScreens>();
  const [setupScreenAction, setSetupScreenAction] = useState<setupAction>("Create");
  const [selectedClassroomGameSessionId, setSelectedClassroomGameSessionId] = useState<number>(0);

  const classroomGameSessionId: number = Number(new URLSearchParams(props.location.search).get("sessionId"));

  const handleSelectScreen = (screen: TOSScreens) => {
    setSelectedScreen(screen);
  }

  const selectSetupAction = (action: setupAction) => {
    setSetupScreenAction(action);
  }

  useEffect(() => {
    if (classroomGameSessionId) {
      setSelectedClassroomGameSessionId(classroomGameSessionId);
      setSelectedScreen("GamePlay");
    } else {
      setSelectedScreen("Sessions");
    }
  }, [classroomGameSessionId])

  return (
    <div>
      {SelectedScreen === "Sessions" && (
        <TOSSessions 
          setSelectedScreen={handleSelectScreen} 
          selectSetupAction={selectSetupAction} 
          setSelectedClassroomGameSessionId={setSelectedClassroomGameSessionId} 
          teacherId={props.userContext.userId} 
        />
      )}
      {SelectedScreen === "Setup" && (
        <TOSGameSetup 
          setSelectedScreen={handleSelectScreen}
          setupScreenAction={setupScreenAction} 
          selectedClassroomGameSessionId={selectedClassroomGameSessionId} 
        />
      )}
      {SelectedScreen === "GamePlay" && (
        <TOSGamePlay 
          selectedClassroomGameSessionId={selectedClassroomGameSessionId}
        />
      )}
    </div>
  )
}

export default connect((state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
})(ThinkOrSwim)

import { useCallback, useEffect, useRef, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { ContentState, EditorState, convertToRaw } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
  ResponseScore,
  Session,
  Response,
} from "../../../model/teacher/assignment/responseScore";
import { updateStudentResponseScore } from "../../../api/student/studentApi";
import Loader from "../../shared/loader";
import { getStudentsTeacherFeedbackForActivity } from "../../../api/student/studentActivityApi";
import { toast } from "react-toastify";
import { getQuestionExplanation } from "../../../api/student/question/explanation";
import { getRequest } from "../../../api/userActivity";
import { AssessmentType } from "../../../model/common/AssessmentType";

export interface GradeCRQuestionProps {
  sessionId: string;
  user_id: string;
  studentAssignmentActivityId: number;
  maximumPoint: number;
  studentList: any[];
  setShowCRReport: any;
  refreshData: Function;
  studentAssessmentActivityId: number;
}

export default function GradeCRQuestion(props: GradeCRQuestionProps) {
  var reportApp;
  const [feedback, setFeedback] = useState<{
    isFeedbackLoaded: boolean;
    feedback: string;
  }>({ isFeedbackLoaded: false, feedback: "" });
  const [defaultMaxPoint] = useState<number>(100);
  const [responseId, setResponseId] = useState<string>();
  const [score, setScore] = useState<number>();
  const [points, setPoints] = useState<number>();
  const [sessionId, setSessionId] = useState<string>(props.sessionId ?? "");
  const [userId, setUserId] = useState<string>(props.user_id ?? "");
  const [maximumPoint] = useState<number>(
    props.maximumPoint ?? defaultMaxPoint
  );
  const [studentAssignmentActivityId, setStudentAssignmentActivityId] =
    useState<number>(props.studentAssignmentActivityId ?? 0);
  const [currentstudent, setCurrentStudent] = useState<any>();
  const [isTeacherQuestion, setIsTeacherQuestion] = useState<boolean>(false);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const handleFeedbackChange = (text: string) => {
    setFeedback({ isFeedbackLoaded: true, feedback: text });
  };
  const [loading, showLoading] = useState<boolean>(false);
  const [key, setKey] = useState<string>("");
  const [itemReference, setItemReference] = useState<string>("");
  const [questionId, setQuestionId] = useState<number>(0);
  const isInitialized = useRef(false);

  function TextToSingleLine(text) {
    var singleLine = "";
    text.forEach((element) => {
      singleLine += element.text + "\n";
    });
    return singleLine.endsWith("\n")
      ? singleLine.substring(0, singleLine.length - 1)
      : singleLine;
  }

  function UpdateScore(e) {
    ValidateScore(e.target.value);
    var percentScore = (e.target.value / maximumPoint) * 100;
    setScore(percentScore);
    setPoints(e.target.value);
  }

  function ValidateScore(score) {
    const re = /^[0-9\b]+$/;
    var el = document.getElementById("gradeError");
    if (re.test(score) && score <= maximumPoint) {
      if (el) el.style.display = "none";
      return true;
    } else {
      if (el) el.style.display = "block";
      return false;
    }
  }
  function SubmitGrade() {
    var isValidScore = ValidateScore(points);
    if (isValidScore) {
      const responsesObj: Response[] = [];
      responsesObj.push({
        responseId: responseId ?? "",
        score: Math.round(score ?? 0),
        attempted: true,
        maxScore: 100,
      });
      const sessionObj: Session[] = [];
      sessionObj.push({
        sessionId: sessionId,
        userId: userId.toString(),
        responses: responsesObj,
      });
      const responseScoreObj: ResponseScore = {
        sessions: sessionObj,
      };

      updateStudentResponseScore(
        feedback.feedback!,
        studentAssignmentActivityId,
        responseScoreObj,
        points ?? 0,
        maximumPoint,
        isTeacherQuestion,
        questionId,
        AssessmentType.ConstructedResponse
      ).then((res) => {
        if (res.data === 1) {
          toast.info("Grades Updated Successfully");
          setEditorState(EditorState.createEmpty());
          var studentCR = props.studentList.find(
            (s) => s.studentAssignmentActivityId === studentAssignmentActivityId
          );
          studentCR.status = "Re-Grade";
          NextStudent();
          props.refreshData();
        } else {
          toast.info("Grades could not be updated.");
        }
      });
    }
  }
  function GetTeacherFeedback() {
    getStudentsTeacherFeedbackForActivity(studentAssignmentActivityId).then(
      (r) => {
        if (r.data) {
          setFeedback({ isFeedbackLoaded: true, feedback: "" + r.data });
          const editor = EditorState.push(
            editorState,
            ContentState.createFromText("" + r.data)
          );
          setEditorState(editor);
        } else {
          setFeedback({ isFeedbackLoaded: true, feedback: "" });
          const editor = EditorState.push(
            editorState,
            ContentState.createFromText("")
          );
          setEditorState(editor);
        }
      }
    );
  }
  async function GetExplanation() {
    await getQuestionExplanation(itemReference, "en", true).then((res) => {
      if (res.data && res.data.textExplanation) {
        setKey(res.data.textExplanation);
      } else {
        setKey("");
      }
    });
  }

  function NextStudent() {
    let nextIndex = -1;
    let index = 0;
    if (props.studentList) {
      props.studentList.forEach((st, inx) => {
        st.index = inx;
      });
      index = props.studentList?.find(
        (element, index) =>
          element.studentAssignmentActivityId === studentAssignmentActivityId
      ).index;
      //Check next one
      let pendingGrades: any = props.studentList
        .filter(
          (element) => element.status === "Grade" && element.index > index
        )
        .sort((a, b) => {
          return a.index - b.index;
        });
      nextIndex =
        pendingGrades && pendingGrades.length > 0 ? pendingGrades[0].index : -1;
      //Go back from start
      if (nextIndex === -1) {
        pendingGrades = props.studentList
          ?.filter(
            (element) => element.status === "Grade" && element.index < index
          )
          .sort((a, b) => {
            return a.index - b.index;
          });
        nextIndex =
          pendingGrades && pendingGrades.length > 0
            ? pendingGrades[0].index
            : -1;
      }

      if (index !== undefined && nextIndex !== -1) {
        if (nextIndex === props.studentList?.length) {
          GoToList();
        } else {
          const nextStudent = props.studentList[nextIndex];
          if (nextStudent) {
            setCurrentStudent(nextStudent);
            setStudentAssignmentActivityId(
              nextStudent.studentAssignmentActivityId
            );
            setSessionId(nextStudent.learnositySessionId);
            setUserId(nextStudent.userId);
          } else {
            GoToList();
          }
        }
      } else {
        GoToList();
      }
    } else {
      GoToList();
    }
  }

  function GoToList() {
    props.setShowCRReport(true);
  }

  function extractNumber(sheetReference): number {
    // Regex pattern to match numbers at the start of the string
    const regex = /^(\d+)/;
  
    // Use match() to find the numbers
    const match = sheetReference.match(regex);
  
    // match[0] will contain the matched number if found
    return match ? parseInt(match[0], 10) : 0;
  }

  const initialization = useCallback(() => {
    if (isInitialized.current) return;
      
      isInitialized.current = true;
    var callbacks = {
      readyListener: function () {
        showLoading(false);
        var report = reportApp.getReport("learnosity-CRReport");
        report.on("ready:itemsApi", function (itemsApp) {
          itemsApp.getQuestions(function (questions) {
            Object.values(questions).forEach(function (question: any) {
              setResponseId(question.response_id);
              let questionId = extractNumber(question.metadata.sheet_reference);
              setQuestionId(questionId);
            });
          });
        });
        report.on("load:data", function (data) {
          setItemReference(
            data?.items[0].item_reference?.replace("-es", "-en")
          );
          if (data?.items[0].item_reference.includes("teacher")) {
            setIsTeacherQuestion(true);
          }
          setPoints(Math.round((data?.items[0]?.score / 100) * maximumPoint));
          setScore(data?.items[0]?.score);
        });
      },
    };

    const request = {
      reports: [
        {
          id: "learnosity-CRReport",
          type: "session-detail-by-item",
          session_id: sessionId.toString(),
          user_id: userId.toString(),
        },
      ],
    };

    showLoading(true);
    getRequest("reports", JSON.stringify(request)).then((response) => {
      if (response.data) {
        reportApp = window["LearnosityReports"]?.init(response.data, callbacks);
      }
    });
  }, [sessionId, userId]);

  useEffect(() => {
    if (
      currentstudent &&
      sessionId === currentstudent.learnositySessionId &&
      userId === currentstudent.userId
    ) {
      initialization();
    } else if (!currentstudent) {
      initialization();
    }
  }, [sessionId, userId]);

  useEffect(() => {
    if (studentAssignmentActivityId) {
      GetTeacherFeedback();
      var currentIndex = props.studentList?.findIndex(
        (element, index) =>
          element.studentAssignmentActivityId === studentAssignmentActivityId
      );
      var currentStudent = props.studentList[currentIndex];
      setCurrentStudent(currentStudent);
    }
  }, [studentAssignmentActivityId]);

  useEffect(() => {
    if (itemReference.length > 0 && !isTeacherQuestion) GetExplanation();
  }, [itemReference]);

  return (
    <div className="min-w-0 block lg:col-span-9 xl:col-span-10 w-full">
      {loading && <Loader />}
      <div className="w-full mb-5 rounded-lg sm:px-2 lg:px-2 mt-5 h-full">
        <div className="mt-4 mb-4">
          <label className="font-medium rounded-lg text-gray-700 mr-1.5">
            User Name
          </label>
          {currentstudent?.userName}
        </div>

        <div className="crGradeWrapper main-content w-full bg-gray-50 rounded-lg border border-gray-200 shadow-lg px-6 mb-12">
          <div id="learnosity-CRReport"></div>
        </div>

        <div className="w-full mb-5 rounded-lg sm:px-6 lg:px-8 mt-5 h-full">
          <div className="crGradeWrapper main-content w-full bg-gray-50 rounded-lg border border-gray-200 shadow-lg px-6 mb-12">
            <div id="learnosity-CRReport"></div>
          </div>
          <div className="flex flex-col sm:flex-row space-y-8 sm:space-y-0 justify-between">
            <div className="bg-gray-50 border border-gray-200 rounded-lg shadow-lg px-6 py-6 w-full lg:w-1/2 xl:w-2/3 xxxl:w-1/2">
              <div className="mb-4">
                <span>Enter grade: </span>
                <input
                  type="number"
                  id="score"
                  min={"0"}
                  max={maximumPoint}
                  value={points}
                  onChange={(e) => UpdateScore(e)}
                  className="mx-2"
                />
                /{maximumPoint}
              </div>
              <div
                id="gradeError"
                className="hidden bg-red-50 p-3 text-red-700 font-medium mb-2 rounded-md"
              >
                Invalid Score
              </div>
              <span>Type your feedback below</span>

              <div className="w-full mt-2">
                {feedback.isFeedbackLoaded && (
                  <Editor
                    key={1}
                    wrapperClassName="texteditor-wrapper"
                    editorClassName="bg-white demo-editor min-h-[12rem] max-h-auto overflow-hidden"
                    defaultContentState={convertToRaw(
                      ContentState.createFromText(
                        feedback.feedback ? feedback.feedback : ""
                      )
                    )}
                    value={feedback.feedback}
                    editorState={editorState}
                    onEditorStateChange={(e) => {
                      setEditorState(e);
                      handleFeedbackChange(
                        TextToSingleLine(
                          convertToRaw(e.getCurrentContent()).blocks
                        )
                      );
                    }}
                  />
                )}
              </div>
            </div>
            {key && (
              <div
                className="bg-gray-50 rounded-lg border border-gray-200 shadow-lg px-6 py-6 w-full lg:w-1/2 xl:1/3 xxxl:w-1/2"
                dangerouslySetInnerHTML={{
                  __html: key ?? "",
                }}
              ></div>
            )}
          </div>
          <div className="space-x-2 flex mt-8 items-end col-span-2 justify-end pb-4">
            <button
              className="bg-secondary-teal border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-dark-teal hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              type="button"
              onClick={(e) => {
                GoToList();
              }}
            >
              {" "}
              Go To List
            </button>

            <button
              className="bg-secondary-teal border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-dark-teal hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              type="button"
              onClick={(e) => {
                SubmitGrade();
              }}
            >
              {" "}
              Submit Grade
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

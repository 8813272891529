import { XIcon } from "@heroicons/react/outline";
import { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { ClassApi } from "../../../../../../api/teacher/classManagementApi";
import { LogoPlWhite } from "../../../../../../assets/icons";
import ClassStudentModel from "../../../../../../model/teacher/classManagement/classStudentModel";
import InfoMessage from "../../../../../shared/infoMessage";
import Loader from "../../../../../shared/loader";
import NoData from "../../../../../shared/noData";

interface PasswordCardPopupProps {
  classId: number;
  showPopUp: (show: boolean) => void;
}

const PasswordCardPopup = (props: PasswordCardPopupProps) => {
  const componentRef = useRef<HTMLDivElement>(null);
  const [showLoader, setShowLoader] = useState(false);
  const [classStudents, setClassStudents] = useState<Array<ClassStudentModel>>(
    []
  );

  useEffect(() => {
    getClassStudents(props.classId);
  }, []);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  function getClassStudents(
    classId: number,
    pageNumber: number = 1,
    pageSize: number = 99999
  ) {
    setShowLoader(true);
    ClassApi.getClassStudentwithPassword(classId, pageNumber, pageSize)
      .then((r) => {
        setClassStudents(r.data.data);
        setShowLoader(false);
      })
      .catch((r) => {
        setShowLoader(false);
      });
  }

  return (
    <div className="fixed inset-0 z-40 flex items-center bg-black bg-opacity-50 sm:items-center sm:justify-center ">
      {showLoader && <Loader></Loader>}
      <div
        className="w-full flex flex-col justify-between overflow-hidden h-auto max-h-80percent bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:w-full"
        role="dialog"
      >
        <header>
          {/* Header */}
          <div className="px-4 py-4 bg-gray-50 sm:px-4">
            <div className="flex items-start justify-between space-x-3">
              <div className="space-y-1">
                <div className="text-lg text-gray-900">Password Cards</div>
              </div>
              <div className="h-7 flex items-center">
                <button
                  type="button"
                  className="text-gray-400 hover:text-gray-500"
                  onClick={() => props.showPopUp(false)}
                >
                  <span className="sr-only">Close panel</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </header>

        <div
          className={`${
            classStudents.length === 0
              ? "px-6 my-4"
              : "w-full relative overflow-auto px-4 py-3"
          }`}
        >
          <div className="p-1">
            <InfoMessage message="Print the password card for your class students." />
          </div>

          <div
            ref={componentRef}
            className="w-full flex flex-wrap  printpdf-div"
          >
            {classStudents.map((student, index) => (
              <StudentIdCard key={index} {...student} />
            ))}
          </div>
          {classStudents.length === 0 && <NoData />}
        </div>
        <footer className="flex items-center justify-end px-6 py-4 sm:flex-row bg-gray-50 dark:bg-gray-800">
          {/* <!-- Action Buttons -->  */}
          <div className="flex-shrink-0">
            <div className="space-x-3 flex justify-end">
              <button
                className="align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 rounded-lg text-sm text-white border border-transparent bg-primary-green focus:shadow-outline-purple w-full sm:w-auto"
                type="button"
                onClick={handlePrint}
              >
                Print
              </button>
              <button
                className="align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 rounded-lg text-sm text-gray-600 border-gray-300 border dark:text-gray-400 focus:outline-none active:bg-transparent hover:border-gray-500 focus:border-gray-500 active:text-gray-500 focus:shadow-outline-gray w-full sm:w-auto"
                type="button"
                onClick={() => {
                  props.showPopUp(false);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
};

const StudentIdCard = (props: ClassStudentModel) => {
  return (
    <div className="mr-2 border rounded-lg overflow-hidden inline-block mb-4 w-card26 xxl:w-card27 printpdf">
      <div className="bg-primary-violet px-4 py-3 flex items-center justify-between gap-2">
        <h2 className="text-xl mb-0 leading-tight text-white">
          {props.lastName}, {props.firstName}
        </h2>
        <LogoPlWhite className="w-16 h-12 p-1" />
      </div>

      <div className="px-4 bg-teal-light text-sm text-white py-1">
        School Code : <span className="font-medium">{props.schoolCode}</span>
      </div>
      <div className="bg-white grid grid-cols-1 gap-x-4 divide-y px-4 py-4">
        <div className="sm:col-span-1 flex items-center gap-2 py-0.5">
          <dt className="text-md text-gray-500 font-normal w-28">Account Id</dt>
          <dd className="mt-0 text-md text-gray-600 font-medium leading-tight break-all">
            {props.accountId}
          </dd>
        </div>
        <div className="sm:col-span-1 flex items-center gap-2 py-0.5">
          <dt className="text-md text-gray-500 font-normal w-28">Username</dt>
          <dd className="font-roboto-mono mt-0 text-md text-gray-600 font-medium leading-tight break-all">
            {props.username}
          </dd>
        </div>
        <div className="sm:col-span-1 flex items-center gap-2 py-0.5">
          <dt className="text-md text-gray-500 font-normal w-28">Student ID</dt>
          <dd className="mt-0 text-md text-gray-600 font-medium leading-tight">
            {props.studentId}
          </dd>
        </div>
        <div className="sm:col-span-1 flex items-center gap-2 py-0.5">
          <dt className="text-md text-gray-500 font-normal w-28">Grade</dt>
          <dd className="mt-0 text-md text-gray-600 font-medium leading-tight ">
            {props.grade}
          </dd>
        </div>

        <div className="sm:col-span-1 flex items-center gap-2 py-0.5">
          <dt className="text-md text-gray-500 font-normal w-28">Password</dt>
          <dd className="font-roboto-mono mt-0 text-md text-gray-600 font-medium leading-tight break-all mt-1">
            <div className="flex space-x-1">{atob(props.passwordHash)}</div>
          </dd>
        </div>
      </div>
      <div className="flex justify-end pr-2 bg-gray-100 text-sm text-gray-400 hover:underline cursor-pointer border-t text-xs py-0.5">
        <a href="https://app.progresslearning.com">
          https://app.progresslearning.com
        </a>
      </div>
    </div>
  );
};

export default PasswordCardPopup;

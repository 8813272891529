import React, { useState, useRef, useEffect } from "react";
import {
  CloseIcon,
  MinusIcon,
  SmileFaceIcon,
  ClassIcon,
  AvatarIcon,
} from "../../../../assets/icons/index";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/database";
import { useCollectionData } from "react-firebase-hooks/firestore";

import { GetStudentIdsClassId } from "../../../../api/chat/class";
import { GetTeacherChatSettings } from "../../../../api/chat/teacher";
import VideoModal from "../shared/videoModal";
import EmojiTile from "../shared/emojiTile";
import Constant from "../../../../utils/constant/constant";
import { TagColor } from "../../../shared/tagColor";
import moment from "moment";
import { ImpersonatedUser } from "../../../../redux/actions/userContextAction";

interface sentTo {
  id: number;
  displayName: string;
  isClass: boolean;
  profileUrl: string;
  colorCode: string;
  roleId: number;
}
interface chatMessageProps {
  messagecollectionName: string;
  recentChatcollectionName: string;
  position: string;
  sendBy: number;
  classes: Array<number>;
  displayName: string;
  sendTo: sentTo;
  onlineUsers: never[];
  db: any;
  firestore: any;
  isClass: boolean;
  token: string;
  schoolId: number;
  sendByRoleId: number;
  isCollapse: boolean;
  hightClass: string;
  impersonatedUser: ImpersonatedUser | null;
  closeChatWindow(): void;
  handleCollapse(): void;
  onMessageAreaClicked(): void;
}
function StudentChatMessage(props: chatMessageProps) {
  const dummy = useRef<any>();
  const messagesRef = props.firestore.collection(props.messagecollectionName);
  const recentChatSendByRef = props.firestore
    .collection(props.recentChatcollectionName)
    .doc("" + props.sendBy);
  const recentChatsendToRef = props.firestore
    .collection(props.recentChatcollectionName)
    .doc("" + props.sendTo.id);
  const [isCollapse, setIsCollapse] = useState(props.isCollapse);
  const [formValue, setFormValue] = useState("");
  const [hightClass, setHightClass] = useState<string>(props.hightClass);
  const [zIndex, setZIndex] = useState<string>("z-50");
  const [videoUrl, setVideoUrl] = useState<string>("");
  const [isOpenVideoModal, setIsOpenVideoModal] = useState<boolean>(false);
  const batch = props.firestore.batch();
  const [defaultAvatar] = useState(
    props.sendTo.profileUrl ? props.sendTo.profileUrl : ""
  );

  const [isOpenEmojiTile, setIsOpenEmojiTile] = useState(false);
  const [sendNotification, setSendNotification] = useState(true);
  const [isTeacherAvailable, setIsTeacherAvailable] = useState(true);
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");

  const openEmojiTile = () => {
    setIsOpenEmojiTile(true);
  };

  const handleCloseEmojiTile = () => {
    setIsOpenEmojiTile(false);
  };

  const onEmojiClick = (emojiObject: any) => {
    let data = formValue + " " + emojiObject.emoji;
    setFormValue(data);
    handleCloseEmojiTile();
  };

  // teacher chat message query
  const teacherChatQuery = messagesRef
    .orderBy("createdAt")
    .where("chatBetween", "in", [
      [props.sendTo.id, props.sendBy],
      [props.sendBy, props.sendTo.id],
    ])
    .where("schoolId", "==", props.schoolId);

  let chatData: Array<any> = [];

  const [teacherMessage] = useCollectionData(teacherChatQuery, {
    idField: "uid",
  });

  chatData.push(teacherMessage);

  // class chat message queries
  for (let i = 0; i < props.classes.length; i += 10) {
    const classQuery = messagesRef
      .orderBy("createdAt")
      .where("classId", "in", props.classes.slice(i, i + 10))
      .where("schoolId", "==", props.schoolId)
      .where("sendBy", "==", props.sendTo.id);

    const [classMessage] = useCollectionData(classQuery, {
      idField: "uid",
    });

    chatData.push(classMessage);
  }

  const [m1, m2, m3, m4, m5, m6, m7, m8, m9, m10] = chatData;

  const messages = m1
    ?.concat(
      m2 ?? [],
      m3 ?? [],
      m4 ?? [],
      m5 ?? [],
      m6 ?? [],
      m7 ?? [],
      m8 ?? [],
      m9 ?? [],
      m10 ?? []
    )
    .sort(function (a: any, b: any) {
      return a.createdAt - b.createdAt;
    });

  const sendMessage = async (e: any) => {
    e.preventDefault();
    var inputValue = formValue;
    setFormValue("");
    const { uid, sendTo } = {
      uid: props.sendBy,
      sendTo: props.sendTo.id,
    };

    await messagesRef.add({
      text: inputValue,
      createdAt: Date.now(),
      sendBy: uid,
      sendTo: props.isClass ? 0 : sendTo,
      chatBetween: props.isClass ? [] : [uid, sendTo],
      isClass: props.isClass,
      className: props.isClass ? props.sendTo.displayName : "",
      classId: props.isClass ? props.sendTo.id : 0,
      schoolId: props.schoolId,
    });

    sendNotification &&
      (await props.firestore.runTransaction(async (t: any) => {
        const recentChatSendBy = await t.get(recentChatSendByRef);
        const recentChatsendTo = await t.get(recentChatsendToRef);
        if (props.isClass) {
          await SaveStudentClassRecentChatData();
        } else {
          await SaveStudentRecentChatData(recentChatSendBy, t);
        }

        if (!props.isClass) {
          if (recentChatsendTo.data()) {
            let data = recentChatsendTo
              .data()
              .chatTo.find((item: any) => item.id === props.sendBy);
            if (!data) {
              let tempList = recentChatsendTo.data().chatTo;
              tempList.push({
                id: props.sendBy,
                displayName: props.displayName,
                recentMassageCount: 0,
                roleId: props.sendByRoleId,
                updatedAt: Date.now(),
              });
              t.update(recentChatsendToRef, { chatTo: tempList });
            } else {
              let chatToList = recentChatsendTo.data().chatTo;
              let objIndex = chatToList.findIndex(
                (item: any) => item.id === props.sendBy
              );
              chatToList[objIndex].recentMassageCount =
                chatToList[objIndex].recentMassageCount + 1;

              chatToList[objIndex].roleId = props.sendByRoleId;
              t.update(recentChatsendToRef, { chatTo: chatToList });
            }
          } else {
            await recentChatsendToRef.set({
              userId: props.sendTo.id,
              chatTo: [
                {
                  id: props.sendBy,
                  displayName: props.displayName,
                  recentMassageCount: 0,
                  roleId: props.sendByRoleId,
                  updatedAt: Date.now(),
                },
              ],
              classes: [],
            });
          }
        }
      }));
  };

  async function SaveStudentRecentChatData(recentChatSendBy: any, t: any) {
    if (recentChatSendBy.data()) {
      let data = recentChatSendBy
        .data()
        .chatTo.find((item: any) => item.id === props.sendTo.id);
      if (!data) {
        let chatToList = recentChatSendBy.data().chatTo;
        chatToList.push({
          id: props.sendTo.id,
          displayName: props.sendTo.displayName,
          recentMassageCount: 0,
          roleId: props.sendTo.roleId,
          updatedAt: Date.now(),
        });
        t.update(recentChatSendByRef, { chatTo: chatToList });
      } else {
        let chatToList = recentChatSendBy.data().chatTo;
        let objIndex = chatToList.findIndex(
          (item: any) => item.id === props.sendTo.id
        );
        chatToList[objIndex].updatedAt = Date.now();
        t.update(recentChatSendByRef, { chatTo: chatToList });
      }
    } else {
      await recentChatSendByRef.set({
        userId: props.sendBy,
        chatTo: [
          {
            id: props.sendTo.id,
            displayName: props.sendTo.displayName,
            recentMassageCount: 0,
            roleId: props.sendTo.roleId,
            updatedAt: Date.now(),
          },
        ],
        classes: [],
      });
    }
  }

  async function SaveStudentClassRecentChatData() {
    GetStudentIdsClassId(props.token, props.sendTo.id).then(
      async (response) => {
        var data: number[] = response.data;
        if (!data) return;
        for (let index = 0; index < data.length; index++) {
          await props.firestore.runTransaction(async (t: any) => {
            const studentId = data[index];
            let studentRef = props.firestore
              .collection(props.recentChatcollectionName)
              .doc("" + studentId);
            let recentChatStudent = await t.get(studentRef);
            if (recentChatStudent.data()) {
              let tempdata = recentChatStudent
                .data()
                .classes.find((item: any) => item.id === props.sendTo.id);
              if (!tempdata) {
                let classList = recentChatStudent.data().classes;
                classList.push({
                  id: props.sendTo.id,
                  displayName: props.sendTo.displayName,
                  recentMassageCount: props.sendBy === studentId ? 0 : 1,
                  roleId: 0,
                  colorCode: props.sendTo.colorCode,
                });
                batch.update(studentRef, { classes: classList });
              } else {
                let classList = recentChatStudent.data().classes;
                let objIndex = classList.findIndex(
                  (item: any) => item.id === props.sendTo.id
                );
                classList[objIndex].recentMassageCount =
                  props.sendBy === studentId
                    ? 0
                    : classList[objIndex].recentMassageCount + 1;
                classList[objIndex].colorCode = props.sendTo.colorCode;
                batch.update(studentRef, { classes: classList });
              }
            } else {
              batch.set(studentRef, {
                userId: studentId,
                chatTo: [],
                classes: [
                  {
                    id: props.sendTo.id,
                    displayName: props.sendTo.displayName,
                    recentMassageCount: 1,
                    colorCode: props.sendTo.colorCode,
                    roleId: 0,
                  },
                ],
              });
            }
          });
        }
        await batch.commit();
      }
    );
  }

  const handleCollapse = () => {
    props.handleCollapse();
  };

  const handleCloseIcon = () => {
    props.closeChatWindow();
  };

  const updateScrollbar = () => {
    if (dummy.current) {
      dummy.current.scrollTop = dummy.current?.scrollHeight;
    }
  };

  // const resetChatCount = async () => {
  //   if (props.isClass) {
  //     await props.firestore.runTransaction(async (t: any) => {
  //       const studentId = props.sendBy;
  //       let studentRef = props.firestore
  //         .collection(props.recentChatcollectionName)
  //         .doc("" + studentId);
  //       let recentChatStudent = await t.get(studentRef);

  //       if (recentChatStudent.data()) {
  //         let tempdata = recentChatStudent
  //           .data()
  //           .classes.find((item: any) => item.id === props.sendTo.id);

  //         if (tempdata) {
  //           let classList = recentChatStudent.data().classes;
  //           let objIndex = classList.findIndex(
  //             (item: any) => item.id === props.sendTo.id
  //           );

  //           classList[objIndex].recentMassageCount = 0;

  //           t.update(studentRef, { classes: classList });
  //         }
  //       }
  //     });
  //   } else {
  //     await props.firestore.runTransaction(async (t: any) => {
  //       const recentChatSendBy = await t.get(recentChatSendByRef);
  //       if (recentChatSendBy.data()) {
  //         let data = recentChatSendBy
  //           .data()
  //           .chatTo.find((item: any) => item.id === props.sendTo.id);
  //         if (data) {
  //           let chatToList = recentChatSendBy.data().chatTo;
  //           let objIndex = chatToList.findIndex(
  //             (item: any) => item.id === props.sendTo.id
  //           );
  //           chatToList[objIndex].recentMassageCount = 0;
  //           t.update(recentChatSendByRef, { chatTo: chatToList });
  //         }
  //       }
  //     });
  //   }
  // };

  const isValidHttpUrl = (message: string) => {
    let url;
    try {
      url = new URL(message);
    } catch (_) {
      return false;
    }

    return url.protocol === "http:" || url.protocol === "https:";
  };

  const openVideoModal = (videoUrl: string) => {
    setZIndex("z-40");
    setVideoUrl(videoUrl);
    setIsOpenVideoModal(true);
  };

  const closeVideoModal = () => {
    setZIndex("z-50");
    setIsOpenVideoModal(false);
    setVideoUrl("");
  };

  const notVideoUrl = (url: string) => {
    closeVideoModal();
    window.open(url);
  };

  const manageSendNotification = () => {
    GetTeacherChatSettings(props.token, props.sendTo.id).then((r) => {
      if (r.data) {
        const classes = r.data.classes;
        const startTime = r.data.startTime;
        const endTime = r.data.endTime;
        const notificationDisabledClassIds: Array<number> = classes
          .filter((c: any) => !c.allowStudentChat)
          .map((c: any) => c.classId);

        const notificationEnabledClassIds: Array<number> = classes
          .filter((c: any) => c.allowStudentChat)
          .map((c: any) => c.classId);

        const notificationEnabledClasses = notificationEnabledClassIds.filter(
          (value) => props.classes.includes(value)
        );
        const notificationDisabledClasses = notificationDisabledClassIds.filter(
          (value) => props.classes.includes(value)
        );

        if (notificationEnabledClasses.length > 0) {
          setSendNotification(true);
        } else if (notificationDisabledClasses.length > 0) {
          setSendNotification(false);
        } else {
          setSendNotification(true);
        }

        manageTeacherAvailabelTime(startTime, endTime);
      }
    });
  };

  const manageTeacherAvailabelTime = (startTime: string, endTime: string) => {
    const start = moment(startTime, ["h:mm A"]);
    const end = moment(endTime, ["h:mm A"]);
    const currentTime = new Date().toLocaleTimeString();
    const current = moment(currentTime, ["h:mm A"]);

    if (moment(current).isAfter(start) && moment(current).isBefore(end)) {
      setIsTeacherAvailable(true);
    } else {
      setIsTeacherAvailable(false);
    }

    setStartTime(startTime);
    setEndTime(endTime);
  };

  useEffect(() => {
    updateScrollbar();
  }, [messages?.length]);

  useEffect(() => {
    setIsCollapse(props.isCollapse);
    setHightClass(props.hightClass);
  }, [props.isCollapse, props.hightClass]);

  useEffect(() => {
    if (isCollapse && hightClass === "height-100") {
      updateScrollbar();
    }
  }, [isCollapse, hightClass]);

  useEffect(() => {
    manageSendNotification();
  }, [props.sendTo.id]);

  return (
    <>
      {isOpenVideoModal && (
        <VideoModal
          url={videoUrl}
          notVideoUrl={notVideoUrl}
          isOpne={isOpenVideoModal}
          closeVideoModal={closeVideoModal}
        />
      )}
      <div className="relative">
        <div
          className={`bottom-0 rounded-xl overflow-hidden shadow-2xl ${props.position} max-w-24 bg-yellow-500 ${zIndex}`}
        >
          <div
            className={`flex-1 justify-between flex flex-col ${hightClass} shadow-lg`}
          >
            <div
              className={`flex sm:items-center justify-between ${
                isCollapse ? "py-3" : "py-1"
              } bg-yellow-500 px-3`}
            >
              <div className="flex items-center space-x-4">
                <span className="relative">
                  {props.onlineUsers[props.sendTo.id] ===
                    Constant.USERACTIVITY.Active &&
                    !props.isClass && (
                      <span className="text-green-500 absolute -right-0 -bottom-0">
                        <svg width="10" height="10">
                          <circle
                            cx="5"
                            cy="5"
                            r="5"
                            fill="currentColor"
                          ></circle>
                        </svg>
                      </span>
                    )}
                  {props.onlineUsers[props.sendTo.id] ===
                    Constant.USERACTIVITY.Ideal &&
                    !props.isClass && (
                      <span className="text-yellow-300 absolute -right-0 -bottom-0">
                        <svg width="10" height="10">
                          <circle
                            cx="5"
                            cy="5"
                            r="5"
                            fill="currentColor"
                          ></circle>
                        </svg>
                      </span>
                    )}
                  {!props.onlineUsers[props.sendTo.id] && !props.isClass && (
                    <span className="text-gray-500 absolute -right-0 -bottom-0">
                      <svg width="10" height="10">
                        <circle
                          cx="5"
                          cy="5"
                          r="5"
                          fill="currentColor"
                        ></circle>
                      </svg>
                    </span>
                  )}

                  {props.isClass && (
                    <span
                      className={`w-10 h-10 rounded-xl flex items-center justify-center border-2 border-white`}
                      style={{ backgroundColor: `${props.sendTo.colorCode}` }}
                    >
                      <ClassIcon
                        className={`w-6 h-6 fill-current opacity-80 
                      ${
                        TagColor.isDark(props.sendTo.colorCode)
                          ? "text-white"
                          : ""
                      }
                    `}
                      />
                    </span>
                  )}

                  {!props.isClass && defaultAvatar === "" && (
                    <span
                      className={`w-10 h-10 rounded-xl flex items-center justify-center border-2 border-white bg-gray-300`}
                      style={{ backgroundColor: `${props.sendTo.colorCode}` }}
                    >
                      <AvatarIcon
                        className={`w-6 h-6 fill-current text-white opacity-90 `}
                      />
                    </span>
                  )}

                  {!props.isClass && defaultAvatar !== "" && (
                    <img
                      src={defaultAvatar}
                      alt=""
                      className="w-10 h-10 rounded-xl border border-gray-300"
                    />
                  )}
                </span>
                <div className="flex flex-col leading-tight">
                  <div className="mt-1 ml-2 flex flex-col items-start">
                    <span className="text-white mr-3 text-sm">
                      {props.sendTo.displayName}
                    </span>
                    {isCollapse && (
                      <span className="font-xsmall text-gray-50">
                        {(props.isClass && "Class") || "Teacher"}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className="flex items-center">
                <button
                  type="button"
                  className="inline-flex items-center group justify-center rounded-full h-6 w-6 transition duration-500 ease-in-out text-white hover:bg-white focus:outline-none"
                  onClick={handleCollapse}
                >
                  <MinusIcon className="w-4 h-4 fill-current text-white group-hover:text-yellow-500 "></MinusIcon>
                </button>
                <button
                  type="button"
                  title="Close"
                  onClick={handleCloseIcon}
                  className="inline-flex items-center group justify-center rounded-full h-6 w-6 transition duration-500 ease-in-out text-white hover:bg-white focus:outline-none"
                >
                  <CloseIcon className="w-4 h-4 fill-current text-white group-hover:text-yellow-500 "></CloseIcon>
                </button>
              </div>
            </div>
            {isCollapse && (
              <>
                <div
                  id="messages"
                  ref={dummy}
                  className="whitespace-pre-line break-all flex flex-col space-y-2 p-3 bg-white rounded-t-xl h-96 overflow-y-auto overflow-x-hidden scrollbar-thumb-blue scrollbar-thumb-rounded scrollbar-track-blue-lighter scrollbar-w-2 scrolling-touch"
                  onClick={props.onMessageAreaClicked}
                >
                  {!isTeacherAvailable && (
                    <span className="px-2 py-1 mb-2 rounded-lg inline-block bg-blue-50 shadow-lg text-xs border border-blue-200 text-blue-500">
                      Your teacher is available between {startTime} to {endTime}
                    </span>
                  )}
                  {messages &&
                    messages.map((msg: any, index: any) => (
                      <div key={index}>
                        {msg.sendBy !== props.sendBy && (
                          <div className="chat-message">
                            <div className="flex items-end">
                              <div className="flex flex-col space-y-1 text-xs max-w-xs mx-2 order-2 items-start">
                                <div>
                                  {isValidHttpUrl(msg.text) && (
                                    <span
                                      className="px-2 py-1 rounded-lg inline-block rounded-bl-none bg-gray-300 text-gray-600 "
                                      onClick={() => openVideoModal(msg.text)}
                                    >
                                      <text>{msg.text}</text>
                                    </span>
                                  )}
                                  {!isValidHttpUrl(msg.text) && (
                                    <span className="px-2 py-1 rounded-lg inline-block rounded-bl-none bg-gray-300 text-gray-600 ">
                                      <text className="break-normal">
                                        {msg.text}
                                      </text>
                                    </span>
                                  )}
                                </div>
                                <div className="font-xsmall text-gray-400 ml-auto">
                                  {new Intl.DateTimeFormat("en-US", {
                                    year: "numeric",
                                    month: "2-digit",
                                    day: "2-digit",
                                    hour: "2-digit",
                                    minute: "2-digit",
                                  }).format(msg.createdAt)}
                                </div>
                              </div>

                              {!props.isClass && defaultAvatar === "" && (
                                <span
                                  className={`w-6 h-6 rounded-lg flex items-center justify-center border-2 border-white bg-gray-300`}
                                >
                                  <AvatarIcon
                                    className={`w-4 h-4 fill-current text-white opacity-90 `}
                                  />
                                </span>
                              )}

                              {!props.isClass && defaultAvatar !== "" && (
                                <img
                                  src={defaultAvatar}
                                  alt=""
                                  className="w-6 h-6 rounded-lg border border-gray-300"
                                />
                              )}
                            </div>
                          </div>
                        )}
                        {msg.sendBy === props.sendBy && (
                          <div className="chat-message">
                            <div className="flex items-end justify-end">
                              <div className="flex flex-col space-y-1 text-xs max-w-xs mx-2 order-1 items-end">
                                <div>
                                  {isValidHttpUrl(msg.text) && (
                                    <span
                                      className="px-2 py-1 rounded-lg rounded-br-none inline-block bg-yellow-300 text-gray-700"
                                      onClick={() => openVideoModal(msg.text)}
                                    >
                                      <text>{msg.text}</text>
                                    </span>
                                  )}
                                  {!isValidHttpUrl(msg.text) && (
                                    <span className="px-2 py-1 rounded-lg rounded-br-none inline-block bg-yellow-300 text-gray-700">
                                      <text
                                        className="break-normal"
                                        style={{ flexShrink: 1 }}
                                      >
                                        {msg.text}
                                      </text>
                                    </span>
                                  )}{" "}
                                </div>
                                <div className="font-xsmall text-gray-400 mr-auto">
                                  {new Intl.DateTimeFormat("en-US", {
                                    year: "numeric",
                                    month: "2-digit",
                                    day: "2-digit",
                                    hour: "2-digit",
                                    minute: "2-digit",
                                  }).format(msg.createdAt)}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    ))}
                </div>

                <div className="bg-white px-4 pb-2">
                  <div className="relative flex border-t">
                    {props.impersonatedUser === null && (
                      <form onSubmit={sendMessage}>
                        <div className="w-full flex justify-center ">
                          <textarea
                            name="text-message"
                            placeholder="Type a new message"
                            onChange={(e) => setFormValue(e.target.value)}
                            className="w-80 focus:outline-none border-0 text-sm focus:placeholder-gray-400 text-gray-600 pl-8 pr-5 pt-3"
                            value={formValue}
                            style={{ resize: "none", overflow: "auto" }}
                          />
                        </div>

                        <div className="absolute right-0 items-center inset-y-0 hidden sm:flex">
                          <button
                            type="submit"
                            disabled={!formValue}
                            className="inline-flex items-center justify-center transition duration-500 ease-in-out text-white focus:outline-none"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                              className="h-5 w-5 fill-current text-blue-500 transform rotate-90"
                            >
                              <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z"></path>
                            </svg>
                          </button>
                        </div>
                      </form>
                    )}
                    <button type="button" onClick={openEmojiTile}>
                      <SmileFaceIcon className="w-4 opacity-50 cursor-pointer hover:opacity-100 absolute left-2 top-3.5"></SmileFaceIcon>
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        {isCollapse && isOpenEmojiTile && (
          <EmojiTile
            hightClass={hightClass}
            zIndex={zIndex}
            position={props.position}
            handleCloseIcon={handleCloseEmojiTile}
            onEmojiClick={onEmojiClick}
          ></EmojiTile>
        )}
      </div>
    </>
  );
}
export default StudentChatMessage;

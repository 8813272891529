import { useEffect, useState } from "react";
import { connect } from "react-redux";
import QuestionAnswer from "./questionAnswer";
import Profile from "../../../../model/users/profile";
import RetryMissedItemsPopup from "./retryMissedItemsPopup";
import {
  getRetryEligibility,
  initializeRetry,
  retryCompleted,
  updateRetryRequested,
} from "../../../../api/student/studentActivityApi";
import { UserContextState } from "../../../../redux/actions/userContextAction";
import { IQuestionAnswerProps } from "../../../../model/interface/questionAnswer/questionAnswer";
import { IRetryMissedItemState } from "../../../../model/interface/activitySession/retryAssignment";
import { useHistory, useLocation } from "react-router-dom";
import Constant from "../../../../utils/constant/constant";
import Loader from "../../../shared/loader";
import constant from "../../../../utils/constant/constant";
import { RouterPrompt } from "../../../shared/routerPrompt";

interface IRetryMissedItem {
  userContext: UserContextState;
  profile: Profile;
  match: any;
}

function RetryMissedItems(props: IRetryMissedItem) {
  const history = useHistory();
  const location = useLocation<IRetryMissedItemState>();
  const state = location?.state;
  const languageId = state?.languageId;
  const resultUrl = state?.resultUrl;
  const prevQuestionAnswer: IQuestionAnswerProps = state
    ? JSON.parse(state?.questionAnswer)
    : undefined;
  const sessionId = props.match.params.sessionId;
  const [confirmRetryPopup, setConfirmRetryPopup] = useState(false);
  const [questionAnswer, setQuestionAnswer] = useState<IQuestionAnswerProps>();
  const [showPrompt, setShowPrompt] = useState(true);
  const [loading, showLoading] = useState(false);

  const validateRetryEligibility = () => {
    showLoading(true);
    getRetryEligibility(sessionId)
      .then((res) => {
        showLoading(false);
        if (res.data.retryEligible) {
          setConfirmRetryPopup(true);
        } else {
          redirectToResult();
        }
      })
      .catch(() => {
        showLoading(false);
      });
  };

  const initializeRetryAssignmentActivity = () => {
    showLoading(true);
    initializeRetry(
      sessionId,
      props.userContext.userId,
      languageId === Constant.Languages.Spanish
        ? Constant.Languages.Spanish
        : Constant.Languages.English
    )
      .then((res) => {
        showLoading(false);
        setConfirmRetryPopup(false);
        const questionAnswer = prevQuestionAnswer;
        questionAnswer.sessionId = res.data.newSessionId;
        questionAnswer.activitySessionId = res.data.activitySessionId;
        questionAnswer.questionIds = res.data.questions;
        questionAnswer.teacherActivityId = res.data.newSessionId;
        questionAnswer.OnSubmit = () => {
          onCompleted(res.data.newSessionId, res.data.activitySessionId);
        };
        if(prevQuestionAnswer.questionsState) {
          questionAnswer.questionsState = [];
        }
        setQuestionAnswer(questionAnswer);
      })
      .catch(() => {
        showLoading(false);
      });
  };

  const updateDoNotRetryActivitySession = () => {
    showLoading(true);
    updateRetryRequested(sessionId, false)
      .then(() => {
        redirectToResult();
      })
      .catch(() => {
        showLoading(false);
      });
  };

  const onCompleted = (sessionId: string, activitySessionId: number) => {
    retryCompleted(sessionId, activitySessionId)
      .then(() => {
        redirectToResult();
      })
      .catch(() => {
        redirectToResult();
      });
  };

  const redirectToResult = () => {
    setShowPrompt(false);
    setTimeout(function () {
      history.push(resultUrl);
    }, 2000);
  };

  const handleRetryMissedPopup = (e: boolean) => {
    if (e) {
      initializeRetryAssignmentActivity();
    } else {
      setShowPrompt(false);
      updateDoNotRetryActivitySession();
    }
  };

  useEffect(() => {
    if (state === undefined) {
      redirectToResult();
    } else {
      validateRetryEligibility();
    }
  }, []);

  return (
    <>
      {loading && <Loader />}
      {showPrompt && (
        <RouterPrompt
          key={"preview"}
          when={showPrompt}
          title="Leave this page?"
          cancelText="Cancel"
          saveText="Leave & Discard"
          message="You are attempting to leave this activity. Your retry will be discarded and you will not be able to return to complete it. Are you sure you wish to delete your retry?"
          onSaveAndLeave={() => true}
          onOK={() => true}
          onCancel={() => false}
        />
      )}
      <div className="min-w-0 w-full h-full">
        {questionAnswer !== undefined && (
          <QuestionAnswer
            {...{ ...questionAnswer, hideSaveBtn: true }}
          ></QuestionAnswer>
        )}
      </div>
      {confirmRetryPopup && (
        <RetryMissedItemsPopup
          languageCode={
            languageId === constant.Languages.Spanish
              ? constant.LanguageCode.Spanish
              : constant.LanguageCode.English
          }
          show={setConfirmRetryPopup}
          onSuccess={handleRetryMissedPopup}
        />
      )}
    </>
  );
}

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(RetryMissedItems);

import { Fragment } from "react"
import { StudentType } from "../../../types/type"
// import KinderGartenHome from "../student/kindergarten/home/home";
import ElementaryHome from "../student/elementary/home/home";

interface StudentProps {
    studentType: StudentType
}

function Student(props: StudentProps) {
    return (
        <Fragment>
            {/* {props.studentType === "ELEMENTARY" &&
                <ElementartHome />
            } */}
            <ElementaryHome />
        </Fragment>
    )
}

export default Student
import { Fragment, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { ExclamationIcon, XIcon } from "@heroicons/react/outline";
import { MinusCircleIcon, PlusCircleIcon } from "@heroicons/react/solid";
import { IAssignment } from "../../../../model/interface/assignment";
import {
  addStudents,
  getAssignmentById,
  updateAssignmentData,
} from "../../../../api/teacher/assignment";
import Loader from "../../../shared/loader";
import { ITreeItem } from "../../../../model/interface/treeItem";
import { IClassStudent } from "../../../../model/interface/classStudent";
import { getAllStudents } from "../../../../api/teacher/teacher";
import { Editor } from "react-draft-wysiwyg";
import { convertToRaw, ContentState, convertFromHTML } from "draft-js";
import Constant from "../../../../utils/constant/constant";
import { uploadVideoMessage } from "../../../../api/teacher/videoMessage";
import MessagePopup from "../../../shared/messagePopup";
import { toast } from "react-toastify";
import { UserContextState } from "../../../../redux/actions/userContextAction";
import Profile from "../../../../model/users/profile";
import { connect } from "react-redux";
import {
  CanvasNewIcon,
  ClassLinkIcon,
  CleverIcon,
  GoogleClassroomIcon,
} from "../../../../assets/icons";
import ResetStudentAssignment from "./resetStudentAssignment";
import { GoogleApi } from "../../../../api/google/googleApi";
import {
  validateIsCanvasUser,
  validateIsGoogleUser,
} from "../../../../utils/helper";
import { CanvasApi } from "../../../../api/canvas/canvasApi";
import { getAssignmentBankUpgradePermission } from "../../../shared/permissionHelper";
import Toggle from "../../../shared/toggle";
import { getStudentSettingValue } from "../../../../utils/assignmentHelper";
import { GetSchoolYears } from "../../../../api/teacher/school";
import { ISchoolYear } from "../../../../model/teacher/schoolYear";
import {
  getMaxSchoolYearDate,
  getMinSchoolYearDate,
} from "../../../../utils/dateHelper";
import { useConfirmation } from "../../../shared/confirmation/confirmationService";
import ReactToolTip from "react-tooltip";
import { validateSyncGoogleAssignment } from "../../../../utils/googleHelper";
import ReactTooltip from "react-tooltip";
import { ReactMediaRecorder } from "react-media-recorder";
import VideoRecorder from "../../../shared/videoRecorder";

interface SettingPopupProps {
  userId: number;
  roleId: number;
  assignmentId: number;
  userContext?: UserContextState;
  profile?: Profile;
  toggleSettingPopup: (popupAction: boolean, refreshData?: boolean) => void;
  selectedSchoolYearId?: number;
}

function SettingPopup(props: SettingPopupProps) {
  const {
    userId,
    roleId,
    assignmentId,
    toggleSettingPopup,
    selectedSchoolYearId,
  } = props;
  type NotesType = "None" | "Text" | "Video";
  const maxRecordingTimeLimit = 46000;
  const defaultAssignment: IAssignment = {
    assignmentId: 0,
    name: "",
    subjectId: 0,
    activities: [],
    students: [],
    isGoogleAssignment: false,
    isCanvasAssignment: false,
    completed: 0,
  };
  const [assignment, setAssignment] = useState<IAssignment>(defaultAssignment);
  const [loading, setLoading] = useState(false);
  const [assignmentName, setAssignmentName] = useState(assignment.name);
  const [activitiesInOrder, setActivitiesInOrder] = useState<boolean>(false);
  const [selectedStartDate, setStartDate] = useState<Date>();
  const [selectedDueDate, setDueDate] = useState<Date>();
  const [addToSchoolBank, setAddToSchoolBank] = useState<boolean>(false);
  const [addToGoogleClassroom, setAddToGoogleClassroom] =
    useState<boolean>(false);
  const [addToCanvasClassroom, setAddToCanvasClassroom] =
    useState<boolean>(false);
  const [notesType, setNotesType] = useState<NotesType>("None");
  const [messageContent, setMessageContent] = useState("");
  const [selectedfile, setfile] = useState(new File([], ""));
  const [isMessageApproved, setIsMessageApproved] = useState(false);
  const [videoRecordMode, setVideoRecordMode] = useState<boolean>(false);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [enableAddSchoolBank, setEnableAddSchoolBank] = useState<boolean>(true);
  const [studentsTree, setStudentsTree] = useState<Array<ITreeItem>>([]);
  const [expandedClasses, setExpandedClasses] = useState<Array<number>>([]);
  const [additionalClasses, setAdditionalClasses] = useState<Array<number>>([]);
  const [toggleAdditionalClass, setToggleAdditionalClass] = useState(false);
  const [selectedStudents, setSelectedStudents] = useState<
    Array<IClassStudent>
  >([]);
  const [selectedClasses, setSelectedClasses] = useState<Array<number>>([]);
  const [toggleClearAssignmentPopup, setToggleClearAssignmentPopup] =
    useState<boolean>(false);
  const [isCRAssignment, setIsCRAssignment] = useState<boolean>(false);
  const [upgrade, setUpgrade] = useState(false);
  const [assignmentSchoolYear, setAssignmentSchoolYear] =
    useState<ISchoolYear>();

  const confirm = useConfirmation();

  useEffect(() => {
    checkUpgradeRequired();
  }, []);

  const checkUpgradeRequired = async () => {
    if (getAssignmentBankUpgradePermission(roleId ?? 0)) {
      setUpgrade(true);
    } else {
      setUpgrade(false);
    }
  };

  function bindStudents(
    students: Array<IClassStudent> | undefined,
    subjectId: number
  ) {
    setLoading(true);
    if (students && students.length > 0) {
      setSelectedStudents(students);
    }

    getAllStudents(userId, roleId, subjectId, selectedSchoolYearId).then(
      (response) => {
        setLoading(false);
        setTreeData(response.data.data, students);
      }
    );
  }

  function setTreeData(
    classStudentdata: Array<any>,
    classStudents: Array<IClassStudent> | undefined
  ) {
    const studentsTree: Array<ITreeItem> = [];
    const allClasses: Array<number> = [];
    const additionalClassesId: Array<number> = [];
    classStudentdata.forEach((classStudent) => {
      const students: Array<ITreeItem> = [];
      classStudent.students.forEach((student) => {
        const currentStudent = classStudents?.find(
          (s) => s.studentId === student.studentId
        );
        const assignmentTTS_Setting = currentStudent
          ? currentStudent.isTTSActive
          : null;
        const globalTTS_Setting = student.isTTSActive;

        const treeItem: ITreeItem = {
          key: student.studentId,
          title: student.studentName,
          children: [],
          isClassGoogleEnabled: false,
          isStudentGoogleEnabled: student.isStudentGoogleEnabled,
          externalRosterProviderIds: "",
          userExternalProviderIds: student.userExternalProviderIds,
          isTTSActive: assignmentTTS_Setting ?? globalTTS_Setting,
        };
        students.push(treeItem);
      });

      const treeItem: ITreeItem = {
        key: classStudent.classId,
        title: classStudent.name,
        teacherName: classStudent.teacherName,
        isAdditionalClass: classStudent.isAdditionalClass,
        externalRosterProviderIds: classStudent.externalRosterProviderIds,
        userExternalProviderIds: classStudent.userExternalProviderIds,
        isClassGoogleEnabled: classStudent.isClassGoogleEnabled,
        isStudentGoogleEnabled: false,
        children: students,
      };

      studentsTree.push(treeItem);
      if (!classStudent.isAdditionalClass) {
        allClasses.push(classStudent.classId);
      } else {
        additionalClassesId.push(classStudent.classId);
      }
    });

    setStudentsTree(studentsTree);
    setAdditionalClasses(additionalClassesId);
    if (classStudents) {
      setExisitngClasses(studentsTree, classStudents);
    }
  }

  function toggleExpandAll() {
    const toggleValue = !toggleAdditionalClass;
    setToggleAdditionalClass(toggleValue);
    let updatedClasses: Array<number> = [];
    if (toggleValue) {
      updatedClasses = [...expandedClasses, ...additionalClasses];
    } else {
      updatedClasses = expandedClasses.filter(
        (r) => !additionalClasses.includes(r)
      );
    }
    setExpandedClasses(updatedClasses);
  }

  function setExisitngClasses(
    studentsTrees: Array<ITreeItem>,
    students: Array<IClassStudent>
  ) {
    let existingClasses: Array<number> = [];
    studentsTrees.map((studentsTree) => {
      if (
        studentsTree &&
        studentsTree.children &&
        studentsTree.children.length > 0
      ) {
        const classStudents = studentsTree.children.length;
        const selectedStudents = students.filter(
          (r) => r.classId === studentsTree.key
        ).length;
        if (classStudents === selectedStudents) {
          existingClasses.push(studentsTree.key);
        }
      }
      return null;
    });
    setSelectedClasses(existingClasses);
  }

  const handleUploadVideoMessage = async () => {
    const file = selectedfile;
    var formData = new FormData();
    formData.append("file", file);
    setLoading(true);
    return uploadVideoMessage(formData);
  };

  const handleVideoStopEvent = (blob: Blob) => {
    var fileName =
      Math.floor(Math.random() * 9000000000000) + 1000000000 + ".webm";
    var file = new File([blob], fileName);
    setfile(file);
    setVideoRecordMode(false);
  };

  const handleRecordAgain = () => {
    assignment.videoNote = "";
    var file = new File([], "");
    setfile(file);
    setVideoRecordMode(true);
  };

  const handleMessageTypeChange = (type: NotesType) => {
    if (assignment.videoNote && assignment.videoNote !== "") {
      setVideoRecordMode(false);
    } else {
      setVideoRecordMode(true);
    }
    setNotesType(type);
  };

  function bindExistingData(assignment: IAssignment) {
    setAssignmentName(assignment.name);
    setActivitiesInOrder(assignment.completeInOrder ?? false);
    if (assignment.startDate) {
      setStartDate(new Date(assignment.startDate));
    }
    if (assignment.endDate) {
      setDueDate(new Date(assignment.endDate));
    }

    setAddToSchoolBank(assignment.isSharedWithSchool ?? false);
    if (assignment.textNote && assignment.textNote !== "") {
      setMessageContent(assignment.textNote);
      setNotesType("Text");
    } else if (assignment.videoNote && assignment.videoNote !== "") {
      setNotesType("Video");
      setVideoRecordMode(false);
      setIsMessageApproved(true);
    }

    const assessments =
      assignment.activities &&
      assignment.activities.filter(
        (r) => r.activityType === Constant.AssignmentActivityType.ASSESSMENT
      );
    if (assessments && assessments.length > 0) {
      const selectedAssessments = assessments.length;
      const sharedAssessments = assessments.filter(
        (r) => r.isSharedAssessment
      ).length;

      setEnableAddSchoolBank(selectedAssessments === sharedAssessments);
    }
    if (
      assignment?.activities?.length === 1 &&
      assignment.activities[0].activityType ===
        Constant.AssignmentActivityType.CONSTRUCTEDRESPONSE
    ) {
      setIsCRAssignment(true);
    }
  }

  function handleAssignmentNameChange(e: any) {
    const assignmentName = e.target.value;
    setAssignmentName(assignmentName);
  }

  function handleStartDate(date: any) {
    if (selectedDueDate && date > selectedDueDate) {
      return false;
    }

    let startDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      0,
      0,
      0
    );
    setStartDate(date);
    assignment.startDate = startDate.toISOString().slice(0, -1);
  }

  function handleDueDate(date: any) {
    if (selectedStartDate && date < selectedStartDate) {
      return false;
    }
    let endDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      23,
      59,
      59
    );
    setDueDate(endDate);
    assignment.endDate = endDate.toISOString().slice(0, -1);
  }

  const handleMessageTextChange = (text: string) => {
    setMessageContent(text);
    assignment.videoNote = "";
    assignment.textNote = text;
  };

  async function bindAssignment() {
    setLoading(true);
    const schoolYearsResponse = await GetSchoolYears(
      props.userContext?.schoolId!
    );
    const schoolYears = schoolYearsResponse.data;
    getAssignmentById(assignmentId).then((response) => {
      if (schoolYears && schoolYears.length > 0) {
        const selectedSchoolYear = schoolYears.find(
          (schoolYear) => schoolYear.schoolYearId === response.data.schoolYearId
        );
        setAssignmentSchoolYear(selectedSchoolYear);
      }
      setLoading(false);
      const result: IAssignment = response.data;
      setAssignment(result);
      console.log(result);
      bindExistingData(result);
      bindStudents(result.students, result.subjectId);
      setAddToGoogleClassroom(result.isGoogleAssignment);
      setAddToCanvasClassroom(result.isCanvasAssignment);
    });
  }

  function handleExpand(classId: number) {
    const existingClasses: Array<number> = expandedClasses;
    let updatedClasses: Array<number> = [];
    if (existingClasses.includes(classId)) {
      updatedClasses = existingClasses.filter((r) => r !== classId);
    } else {
      updatedClasses = [...existingClasses, classId];
    }
    setExpandedClasses(updatedClasses);
  }

  function handleSelectClass(classId: number) {
    const existingClasses: Array<number> = selectedClasses;
    let updatedClasses: Array<number> = [];
    const classExist = existingClasses.includes(classId);
    if (classExist) {
      updatedClasses = existingClasses.filter((r) => r !== classId);
    } else {
      updatedClasses = [...existingClasses, classId];
    }

    const classStudents: Array<ITreeItem> = studentsTree.filter(
      (r) => r.key === classId
    );
    setSelectedClasses(updatedClasses);
    if (classStudents && classStudents.length > 0) {
      const students = classStudents[0].children;
      if (students && students.length > 0) {
        if (!classExist) {
          const studentIds: Array<IClassStudent> = [];
          students.map((s) => {
            studentIds.push({ studentId: s.key, classId: classId });
            return null;
          });
          setSelectedStudents([
            ...selectedStudents.filter((r) => r.classId !== classId),
            ...studentIds,
          ]);
        } else {          
          const updatedStudents = selectedStudents.filter(
            (r) => r.classId !== classId || isAssignmentInProgress(r.studentId)
          );          
          setSelectedStudents(updatedStudents);
        }
      }
    }
  }

  function lockStudentAssignment(studentId: number, classId: number) {
    const existingStudents: Array<IClassStudent> = selectedStudents;
    const updatedStudents: Array<IClassStudent> = [];
    if (existingStudents && existingStudents.length > 0) {
      existingStudents.map((existingStudent) => {
        if (
          existingStudent.studentId === studentId &&
          existingStudent.classId === classId
        ) {
          existingStudent.isLocked = !existingStudent.isLocked;
        }
        updatedStudents.push(existingStudent);
      });
    }

    setSelectedStudents(updatedStudents);
  }

  function resetAttempt(studentId: number, classId: number) {
    const existingStudents: Array<IClassStudent> = selectedStudents;
    const updatedStudents: Array<IClassStudent> = [];
    if (existingStudents && existingStudents.length > 0) {
      existingStudents.map((existingStudent) => {
        if (
          existingStudent.studentId === studentId &&
          existingStudent.classId === classId
        ) {
          existingStudent.isReset = !existingStudent.isReset;
        }
        updatedStudents.push(existingStudent);
      });

      setSelectedStudents(updatedStudents);
    }
  }

  function clearAssignment(studentId: number, classId: number) {
    const existingStudents: Array<IClassStudent> = selectedStudents;
    const updatedStudents: Array<IClassStudent> = [];
    if (existingStudents && existingStudents.length > 0) {
      existingStudents.map((existingStudent) => {
        if (
          existingStudent.studentId === studentId &&
          existingStudent.classId === classId
        ) {
          existingStudent.isClear = !existingStudent.isClear;
        }
        updatedStudents.push(existingStudent);
      });

      setSelectedStudents(updatedStudents);
    }
  }

  function isAssignmentInProgress(studentId: number) {
    return assignment.students?.some(
      (r) => r.studentId === studentId && r.isStarted
    ) && assignment.assignmentId > 0
      ? true
      : false;
  }

  function handleCheckStudent(studentId: number, classId: number) {
    if (isAssignmentInProgress(studentId)) {
      return false;
    }

    const existingStudents: Array<IClassStudent> = selectedStudents;
    const student: IClassStudent = { studentId: studentId, classId: classId };
    let updatedStudents: Array<IClassStudent> = [];
    if (
      existingStudents.some(
        (r) => r.studentId === studentId && r.classId === classId
      )
    ) {
      updatedStudents = existingStudents.filter(
        (r) => (r.studentId === studentId && r.classId === classId) === false
      );
    } else {
      updatedStudents = [...existingStudents, student];
    }

    setSelectedStudents(updatedStudents);

    let updatedClasses: Array<number> = [];
    const classStudentsLength = studentsTree.filter((r) => r.key === classId)[0]
      .children?.length;
    if (
      classStudentsLength ===
      updatedStudents.filter((r) => r.classId === classId).length
    ) {
      updatedClasses = [...selectedClasses, classId];
    } else {
      updatedClasses = selectedClasses.filter((r) => r !== classId);
    }

    setSelectedClasses(updatedClasses);
  }

  async function handleSave(showConfirmation: boolean) {
    if (showConfirmation) {
      if (selectedStudents.some((r) => r.isClear)) {
        setToggleClearAssignmentPopup(true);
        return false;
      }
    }

    if (assignmentName.trim() === "") {
      toast.error("Assignment name cannot be empty");
      return false;
    }

    if (selectedStudents.length > 0) {
      let hasError: boolean = false;
      if (!selectedStartDate && !selectedDueDate) {
        toast.error("Start date and due date cannot be empty.");
        hasError = true;
      } else if (!selectedStartDate && selectedDueDate) {
        toast.error("Start date cannot be empty.");
        hasError = true;
      } else if (selectedStartDate && !selectedDueDate) {
        toast.error("Due date cannot be empty.");
        hasError = true;
      }

      if (hasError) {
        return false;
      }
    }

    const updatedAssignment: IAssignment = assignment;
    updatedAssignment.name = assignmentName;
    updatedAssignment.isSharedWithSchool = addToSchoolBank;
    updatedAssignment.completeInOrder = activitiesInOrder;
    if (notesType === "Text" && messageContent !== "") {
      updatedAssignment.textNote = messageContent;
    }

    if (notesType === "Video" && selectedfile.name !== "") {
      if (!isMessageApproved) {
        setShowAlert(true);
        return false;
      }
      const response = handleUploadVideoMessage();
      const mediaUrl = (await response).data;
      updatedAssignment.videoNote = mediaUrl;
      updatedAssignment.textNote = "";
    }

    if (selectedStartDate) {
      assignment.startDate = selectedStartDate.toISOString().slice(0, -1);
    }
    if (selectedDueDate) {
      assignment.endDate = selectedDueDate.toISOString().slice(0, -1);
    }

    setLoading(true);
    updateAssignmentData(userId, updatedAssignment).then(() => {
      setLoading(false);
      toggleSettingPopup(false, true);
    });

    await addStudents(
      userId,
      [assignment.assignmentId],
      selectedStudents,
      props?.profile?.googleUserId!,
      true
    );

    if (validateIsCanvasUser(props.profile!)) {
      if (addToCanvasClassroom) {
        handleSyncAssignmentToCanvasClassroom();
      } else if (assignment.isCanvasAssignment) {
        handleRemoveCanvasAssignment();
      }
    }

    if (validateIsGoogleUser(props.profile!)) {
      if (addToGoogleClassroom) {
        if (
          validateSyncGoogleAssignment(
            assignment,
            selectedStartDate,
            selectedDueDate,
            toast
          )
        ) {
          return false;
        }

        handleSyncAssignmentToGoogleClassroom();
      } else if (assignment.isGoogleAssignment) {
        handleRemoveGoogleAssignment();
      }
    }
  }

  function handleClearAssignmentConfirmation(
    popupAction: boolean,
    isDelete?: boolean
  ) {
    setToggleClearAssignmentPopup(popupAction);
    if (isDelete) {
      handleSave(false);
    }
  }

  useEffect(() => {
    bindAssignment();
  }, []);

  const getExternalRosterIcon = (extId) => {
    if (extId === Constant.ExternalRosterProviders.Clever) {
      return <CleverIcon className="w-5 h-5" />;
    } else if (extId === Constant.ExternalRosterProviders.ClassLink) {
      return <ClassLinkIcon className="w-5 h-5" />;
    } else if (extId === Constant.ExternalRosterProviders.Google && validateIsGoogleUser(props.profile!)) {
      return <GoogleClassroomIcon className="w-5 h-5" />;
    } else if (extId === Constant.ExternalRosterProviders.Canvas && validateIsCanvasUser(props.profile!)) {
      return <CanvasNewIcon className="w-5 h-5" />;
    }
  };

  function handleAddtoGoogleClassroom(value: boolean) {
    if (props.userContext?.impersonatedUser !== null) {
      confirm({
        variant: "info",
        title: "Impersonation Detected",
        description:
          "This feature can only be accessed directly from the users login. Please reach out to the impersonated user to access this functionality.",
      });
      return false;
    } else if (!validateIsGoogleUser(props.profile!)) {
      return false;
    }

    if (
      !studentsTree.some((s) =>
        selectedStudents.some(
          (st) => st.classId === s.key && s.isClassGoogleEnabled
        )
      ) &&
      value
    ) {
      toast.warning(
        "Please assign the assignment to a Google Classroom synced class in order to sync the assignment"
      );
      return false;
    }

    if (!dateValidation()) {
      return false;
    }
    setAddToGoogleClassroom(value);
  }

  function handleSyncAssignmentToGoogleClassroom() {
    setLoading(true);
    GoogleApi.syncAssignmentsToGoogle(
      assignment.assignmentId,
      props.userId,
      props.profile?.googleUserId!,
      props.profile?.accountLoginName!,
      selectedStartDate!,
      selectedDueDate!,
      props.profile?.googleUser?.refreshToken!
    )
      .then((r) => {
        setLoading(false);
        if (r.data.isSuccess)
        {
          if (r.data.code === "ASSIGNMENT_PARTIALLY_SYNCED") {
            toast.warning(r.data.message);
          } else {
            toast.success("Assignment synced successfully.");
          }
        } else {
          toast.error(r.data.message);
        }
      })
      .catch((r) => {
        setLoading(false);
        toast.error("Failed to sync assignment. " + (r.response?.data?.Message !== undefined ? r.response?.data?.Message : ""));
      });
  }

  function handleRemoveGoogleAssignment() {
    if (!validateIsGoogleUser(props.profile!)) {
      return false;
    }

    GoogleApi.removeGoogleAssignment(
      assignment.assignmentId,
      props.profile?.googleUser?.refreshToken!
    )
      .then((r) => {
        setLoading(false);
        if (r.data) {
          setAddToGoogleClassroom(false);
          toast.success(
            "Assignment removed successfully from Google Classroom."
          );
        } else {
          toast.warning("Cannot remove assignment from Google Classroom");
        }
      })
      .catch((r) => {
        setLoading(false);
        toast.error("Failed to remove assignment.");
      });
  }

  function handleAddtoCanvasClassroom(value: boolean) {
    if (props.userContext?.impersonatedUser !== null) {
      confirm({
        variant: "info",
        title: "Impersonation Detected",
        description:
          "This feature can only be accessed directly from the users login. Please reach out to the impersonated user to access this functionality.",
      });
      return false;
    } else if (!validateIsCanvasUser(props.profile!)) {
      return false;
    }

    if (
      !studentsTree.some((s) =>
        selectedStudents.some(
          (st) =>
            st.classId === s.key &&
            s.externalRosterProviderIds?.includes(
              Constant.ExternalRosterProviders.Canvas.toString()
            )
        )
      ) &&
      value
    ) {
      toast.warning(
        "Please assign the assignment to canvas class in order to sync the assignment"
      );
      return false;
    }

    if (value && !dateValidation()) {
      return false;
    }
    setAddToCanvasClassroom(value);
  }

  function handleSyncAssignmentToCanvasClassroom() {
    setLoading(true);
    CanvasApi.syncAssignemntsToCanvas(
      assignment.assignmentId,
      props.userId,
      selectedDueDate!,
      props.profile?.accountLoginName!,
      props.profile?.canvasAccountSettings!
    )
      .then((r) => {
        setLoading(false);
        if (r.data.length > 0) {
          const statusFailed = r.data.filter((s) => !s.isSuccess);
          if (statusFailed.length > 0) {
            toast.error(statusFailed[0].message);
          } else {
            toast.success(r.data[0].message);
          }
        }
      })
      .catch((r) => {
        setLoading(false);
        toast.error("Failed to sync assignment.");
      });
  }

  function handleRemoveCanvasAssignment() {
    if (!validateIsCanvasUser(props.profile!)) {
      return false;
    }

    CanvasApi.removeCanvasAssignment(
      assignment.assignmentId,
      props.profile?.canvasAccountSettings!
    )
      .then((r) => {
        setLoading(false);
        if (r.data) {
          setAddToCanvasClassroom(false);
          toast.success(
            "Assignment removed successfully from Canvas Classroom."
          );
        } else {
          toast.warning("Cannot remove assignment from Canvas Classroom");
        }
      })
      .catch((r) => {
        setLoading(false);
        toast.error("Failed to remove assignment.");
      });
  }

  function dateValidation() {
    let isValid: boolean = true;
    if (selectedStudents.length === 0) {
      toast.error(
        "Please assign the assignment to students in order to sync the assignment on the roster classes"
      );
      isValid = false;
    } else if (!selectedStartDate && !selectedDueDate) {
      toast.error("Start date and due date cannot be empty.");
      isValid = false;
    } else if (!selectedStartDate && selectedDueDate) {
      toast.error("Start date cannot be empty.");
      isValid = false;
    } else if (selectedStartDate && !selectedDueDate) {
      toast.error("Due date cannot be empty.");
      isValid = false;
    } else if (selectedDueDate! < new Date()) {
      toast.error("Due date must be in the future.");
      isValid = false;
    }
    return isValid;
  }

  function handleCheckStudentEliminateAnswer(
    studentId: number,
    classId: number
  ) {
    let existingStudents: Array<IClassStudent> = selectedStudents;
    let updatedStudents: Array<IClassStudent> = [];
    if (
      existingStudents.some(
        (r) => r.studentId === studentId && r.classId === classId
      )
    ) {
      var existingStudent = existingStudents.find(
        (r) => r.studentId === studentId && r.classId === classId
      );
      const student: IClassStudent = {
        studentId: studentId,
        classId: classId,
        eliminateAnswerForSingleMCQ:
          !existingStudent?.eliminateAnswerForSingleMCQ,
      };
      updatedStudents = existingStudents.filter(
        (r) => (r.studentId === studentId && r.classId === classId) === false
      );
      updatedStudents = [...updatedStudents, student];
      setSelectedStudents(updatedStudents);
    }
  }

  function handleToggleTTS(
    studentId: number,
    classId: number,
    studentItem: ITreeItem
  ) {
    let existingStudents: Array<IClassStudent> = selectedStudents;
    let updatedStudents: Array<IClassStudent> = [];
    if (
      existingStudents.some(
        (r) => r.studentId === studentId && r.classId === classId
      )
    ) {
      var existingStudent = existingStudents.find(
        (r) => r.studentId === studentId && r.classId === classId
      );

      const student: IClassStudent = {
        ...existingStudent,
        studentId: studentId,
        classId: classId,
        isTTSActive: !getStudentSettingValue(
          classId,
          studentId,
          studentItem,
          "TTS",
          selectedStudents
        ),
      };

      updatedStudents = existingStudents.filter(
        (r) => (r.studentId === studentId && r.classId === classId) === false
      );
      updatedStudents = [...updatedStudents, student];
      setSelectedStudents(updatedStudents);
    }
  }

  function canActivityUpdate() {
    return assignment.assignmentId > 0 &&
      assignment.assignmentStatus &&
      (assignment.assignmentStatus.toLocaleUpperCase() ===
        Constant.AssignmentStatus.INPROGRESS ||
        assignment.assignmentStatus.toLocaleUpperCase() ===
          Constant.AssignmentStatus.COMPLETE)
      ? false
      : true;
  }

  return (
    <Fragment>
      {showAlert && (
        <MessagePopup
          togglePopup={setShowAlert}
          message={
            "Please check the approved checkbox to confirm that the content is appropriate for your students."
          }
        />
      )}

      {toggleClearAssignmentPopup && (
        <ResetStudentAssignment
          togglePopup={handleClearAssignmentConfirmation}
        ></ResetStudentAssignment>
      )}

      {loading && <Loader />}
      <div className="fixed inset-0 z-10 flex items-center bg-black bg-opacity-50 sm:items-center sm:justify-center">
        <div
          className="w-full flex flex-col justify-between overflow-hidden h-auto max-h-80percent bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:max-w-6xl"
          role="dialog"
        >
          <header>
            {/* Header */}
            <div className="px-4 py-4 bg-gray-50">
              <div className="flex items-start justify-between space-x-3">
                <div className="space-y-1">
                  <div className="text-lg font-medium text-gray-900">
                    Edit Assignment Settings
                  </div>
                </div>
                <div className="h-7 flex items-center">
                  <button
                    type="button"
                    className="text-gray-400 hover:text-gray-500"
                    onClick={() => toggleSettingPopup(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
          </header>
          <div className="w-full relative overflow-auto px-4 py-1">
            <form>
              <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5 ml-8">
                <div className="pt-8 sm:pt-6 space-y-6 sm:space-y-5">
                  <div className="space-y-6 sm:space-y-5">
                    <div className="sm:grid sm:grid-cols-4 sm:gap-4 sm:items-center">
                      <label
                        htmlFor="first-name"
                        className="block text-sm font-normal text-gray-500"
                      >
                        Assignment Name
                      </label>
                      <div className="mt-1 sm:mt-0 sm:col-span-2">
                        <input
                          autoComplete="off"
                          type="text"
                          name="first-name"
                          id="first-name"
                          maxLength={60}
                          value={assignmentName}
                          onChange={(e) => handleAssignmentNameChange(e)}
                          className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                        />
                      </div>
                    </div>
                    {assignment.activities &&
                      assignment.activities.length > 1 && (
                        <div role="group" aria-labelledby="label-notifications">
                            <div className="sm:grid sm:grid-cols-4 sm:gap-4 sm:items-baseline">
                              <div>
                                <div
                                  className="text-sm text-gray-500"
                                  id="label-notifications"
                                >
                                  Completion Order
                                </div>
                              </div>
                              <div 
                                data-tip
                                data-for="toggleDisabledTooltip"
                                className={`flex col-span-8 sm:col-span-4 xl:col-span-2 gap-4 ${!canActivityUpdate() ? 'tooltip-disabled' : ''}`}>
                                <label
                                  x-radio-group-option=""
                                  className=" relative flex cursor-pointer focus:outline-none border-gray-400"
                                >
                                  <input
                                    type="radio"
                                    checked={activitiesInOrder}
                                    x-model="value"
                                    name="completionorder"
                                    value="Public access"
                                    className="h-4 w-4 cursor-pointer text-primary-violet border-gray-300 focus:ring-primary-violet"
                                    aria-labelledby="privacy-setting-0-label"
                                    aria-describedby="privacy-setting-0-description"
                                    onChange={() => setActivitiesInOrder(true)}
                                    disabled={!canActivityUpdate()}
                                  />
                                  <div className="ml-3 flex flex-col">
                                    <span
                                      id="privacy-setting-0-label"
                                      className={`block text-sm text-gray-500 ${!canActivityUpdate() ? 'opacity-50' : ''}`}
                                    >
                                      In Specific Order
                                    </span>
                                  </div>
                                </label>
                                <label
                                  x-radio-group-option=""
                                  className=" relative flex cursor-pointer focus:outline-none border-gray-400"
                                >
                                  <input
                                    checked={!activitiesInOrder}
                                    type="radio"
                                    x-model="value"
                                    name="completionorder"
                                    value="Public access"
                                    className="h-4 w-4 cursor-pointer text-primary-violet border-gray-300 focus:ring-primary-violet"
                                    aria-labelledby="privacy-setting-0-label"
                                    aria-describedby="privacy-setting-0-description"
                                    onChange={() => setActivitiesInOrder(false)}
                                    disabled={!canActivityUpdate()}
                                  />
                                  <div className="ml-3 flex flex-col">
                                    <span
                                      id="privacy-setting-0-label"
                                      className={`block text-sm text-gray-500 ${!canActivityUpdate() ? 'opacity-50' : ''}`}
                                    >
                                      Any Order
                                    </span>
                                  </div>
                                </label>
                                {!canActivityUpdate() && (
                                  <ReactTooltip
                                    id="toggleDisabledTooltip"
                                    class="bg-secondary-teal/10 z-50"
                                    effect="solid"
                                  >
                                    <span>This setting is disabled because at least one student has started the activity.</span>
                                  </ReactTooltip>
                                )}
                              </div>                              
                            </div>
                        </div>
                        
                        
                      )}
                    <div className="sm:grid sm:grid-cols-4 sm:gap-4 sm:items-center">
                      <label className="block text-sm text-gray-500 ">
                        Start Date
                      </label>
                      <DatePicker
                        disabled={selectedStudents.length === 0}
                        className="react-datepicker__input-container py-1.5 px-3 block w-full shadow-sm focus:ring-primary-violet focus:border-primary-violet border border-gray-300 rounded-md "
                        selected={selectedStartDate}
                        onChange={(date) => handleStartDate(date)}
                        minDate={
                          getMinSchoolYearDate(assignmentSchoolYear) <
                          new Date()
                            ? new Date()
                            : getMinSchoolYearDate(assignmentSchoolYear)
                        }
                        maxDate={getMaxSchoolYearDate(assignmentSchoolYear)}
                      />
                    </div>
                    <div className="sm:grid sm:grid-cols-4 sm:gap-4 sm:items-center">
                      <label className="block text-sm text-gray-500">
                        Due Date
                      </label>
                      <DatePicker
                        disabled={selectedStudents.length === 0}
                        className="react-datepicker__input-container py-1.5 px-3 block w-full shadow-sm focus:ring-primary-violet focus:border-primary-violet border border-gray-300 rounded-md "
                        selected={selectedDueDate}
                        onChange={(date) => handleDueDate(date)}
                        minDate={
                          getMinSchoolYearDate(assignmentSchoolYear) <
                          new Date()
                            ? new Date()
                            : getMinSchoolYearDate(assignmentSchoolYear)
                        }
                        maxDate={getMaxSchoolYearDate(assignmentSchoolYear)}
                      />
                    </div>
                    <div role="group" aria-labelledby="label-notifications">
                      <div className="sm:grid sm:grid-cols-4 sm:gap-4 sm:items-baseline">
                        <div>
                          <div
                            className="text-sm text-gray-500"
                            id="label-notifications"
                          >
                            Add to School bank
                          </div>
                        </div>
                        <div className="flex col-span-8 sm:col-span-4 xl:col-span-2 gap-4">
                          <label
                            x-radio-group-option=""
                            className=" relative flex cursor-pointer focus:outline-none border-gray-400"
                          >
                            {!upgrade && (
                              <input
                                type="radio"
                                checked={addToSchoolBank}
                                disabled={enableAddSchoolBank ? false : true}
                                x-model="value"
                                name="schoolbank"
                                value="Public access"
                                className="h-4 w-4 cursor-pointer text-primary-violet border-gray-300 focus:ring-primary-violet"
                                aria-labelledby="privacy-setting-0-label"
                                aria-describedby="privacy-setting-0-description"
                                onChange={() => setAddToSchoolBank(true)}
                              />
                            )}
                            {upgrade && (
                              <input
                                type="radio"
                                checked={false}
                                disabled={true}
                                x-model="value"
                                name="schoolbank"
                                value="Public access"
                                className="h-4 w-4 cursor-pointer text-primary-violet border-gray-300 focus:ring-primary-violet"
                                aria-labelledby="privacy-setting-0-label"
                                aria-describedby="privacy-setting-0-description"
                              />
                            )}

                            <div className="ml-3 flex flex-col">
                              <span
                                id="privacy-setting-0-label"
                                className="block text-sm text-gray-500 opacity-50"
                              >
                                Yes
                              </span>
                            </div>
                          </label>
                          <label
                            x-radio-group-option=""
                            className=" relative flex cursor-pointer focus:outline-none border-gray-400"
                          >
                            {!upgrade && (
                              <input
                                checked={!addToSchoolBank}
                                type="radio"
                                x-model="value"
                                name="schoolbank"
                                value="Public access"
                                className="h-4 w-4 cursor-pointer text-primary-violet border-gray-300 focus:ring-primary-violet"
                                aria-labelledby="privacy-setting-0-label"
                                aria-describedby="privacy-setting-0-description"
                                onChange={() => setAddToSchoolBank(false)}
                              />
                            )}
                            {upgrade && (
                              <input
                                type="radio"
                                x-model="value"
                                name="schoolbank"
                                value="Public access"
                                className="h-4 w-4 cursor-pointer text-primary-violet border-gray-300 focus:ring-primary-violet"
                                aria-labelledby="privacy-setting-0-label"
                                aria-describedby="privacy-setting-0-description"
                                checked={false}
                                disabled={true}
                              />
                            )}
                            <div className="ml-3 flex flex-col">
                              <span
                                id="privacy-setting-0-label"
                                className="block text-sm text-gray-500 opacity-50"
                              >
                                No
                              </span>
                            </div>
                          </label>
                        </div>
                      </div>
                    </div>

                    {!upgrade && (
                      <>
                        {!enableAddSchoolBank && (
                          <div
                            role="group"
                            aria-labelledby="label-notifications"
                          >
                            <div className="sm:grid sm:grid-cols-4 sm:gap-4 sm:items-baseline">
                              <div>
                                <div
                                  className="text-sm text-gray-500"
                                  id="label-notifications"
                                ></div>
                              </div>
                              <div className="flex col-span-8 sm:col-span-4 xl:col-span-3 gap-4">
                                <div className="text-sm text-gray-700 font-light bg-yellow-50 p-4 flex w-full rounded-md rounded-t-lg sm:rounded-lg overflow-hidden">
                                  <div className="flex-shrink-0 current-fill text-yellow-400">
                                    <ExclamationIcon className="w-4 text-yellow-500 mr-2" />
                                  </div>
                                  <div
                                    className="pl-2"
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        "This assignment cannot be added to the school bank because it contains an assessment that is not shared.",
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {validateIsGoogleUser(props.profile!) && (
                          <div role="group" aria-labelledby="label-notifications">
                            <div className="sm:grid sm:grid-cols-4 sm:gap-4 sm:items-baseline">
                              <div>
                                <div
                                  className="text-sm text-gray-500"
                                  id="label-notifications"
                                >
                                  Add to Google Classroom
                                </div>
                              </div>
                              <div className="flex col-span-8 sm:col-span-4 xl:col-span-2 gap-4">
                                <label
                                  x-radio-group-option=""
                                  className=" relative flex cursor-pointer focus:outline-none border-gray-400"
                                >
                                  <input
                                    type="radio"
                                    x-model="value"
                                    name="googleclassroom"
                                    value="Public access"
                                    className="h-4 w-4 cursor-pointer text-primary-violet border-gray-300 focus:ring-primary-violet"
                                    aria-labelledby="privacy-setting-0-label"
                                    aria-describedby="privacy-setting-0-description"
                                    checked={addToGoogleClassroom}
                                    onChange={() =>
                                      handleAddtoGoogleClassroom(true)
                                    }
                                  />
                                  <div className="ml-3 flex flex-col">
                                    <span
                                      id="privacy-setting-0-label"
                                      className="block text-sm text-gray-500"
                                    >
                                      Yes
                                    </span>
                                  </div>
                                </label>
                                <label
                                  x-radio-group-option=""
                                  className=" relative flex cursor-pointer focus:outline-none border-gray-400"
                                >
                                  <input
                                    type="radio"
                                    x-model="value"
                                    name="googleclassroom"
                                    value="Public access"
                                    className="h-4 w-4 cursor-pointer text-primary-violet border-gray-300 focus:ring-primary-violet"
                                    aria-labelledby="privacy-setting-0-label"
                                    aria-describedby="privacy-setting-0-description"
                                    disabled={
                                      !validateIsGoogleUser(props.profile!)
                                    }
                                    checked={!addToGoogleClassroom}
                                    onChange={() =>
                                      handleAddtoGoogleClassroom(false)
                                    }
                                  />
                                  <div className="ml-3 flex flex-col">
                                    <span
                                      id="privacy-setting-0-label"
                                      className="block text-sm text-gray-500"
                                    >
                                      No
                                    </span>
                                  </div>
                                </label>
                              </div>
                            </div>
                          </div>
                        )}
                        
                        {validateIsCanvasUser(props.profile!) && (
                          <div role="group" aria-labelledby="label-notifications">
                            <div className="sm:grid sm:grid-cols-4 sm:gap-4 sm:items-baseline">
                              <div>
                                <div
                                  className="text-sm text-gray-500"
                                  id="label-notifications"
                                >
                                  Add to Canvas Classroom
                                </div>
                              </div>
                              <div className="flex col-span-8 sm:col-span-4 xl:col-span-2 gap-4">
                                <label className=" relative flex cursor-pointer focus:outline-none border-gray-400">
                                  <input
                                    type="radio"
                                    x-model="value"
                                    name="canvasclassroom"
                                    value="Public access"
                                    className="h-4 w-4 cursor-pointer text-primary-violet border-gray-300 focus:ring-primary-violet"
                                    aria-labelledby="privacy-setting-0-label"
                                    aria-describedby="privacy-setting-0-description"
                                    checked={addToCanvasClassroom}
                                    onChange={() =>
                                      handleAddtoCanvasClassroom(true)
                                    }
                                  />
                                  <div className="ml-3 flex flex-col">
                                    <span className="block text-sm text-gray-500">
                                      Yes
                                    </span>
                                  </div>
                                </label>
                                <label className=" relative flex cursor-pointer focus:outline-none border-gray-400">
                                  <input
                                    type="radio"
                                    x-model="value"
                                    name="canvasclassroom"
                                    value="Public access"
                                    className="h-4 w-4 cursor-pointer text-primary-violet border-gray-300 focus:ring-primary-violet"
                                    aria-labelledby="privacy-setting-0-label"
                                    aria-describedby="privacy-setting-0-description"
                                    disabled={
                                      !validateIsCanvasUser(props.profile!)
                                    }
                                    checked={!addToCanvasClassroom}
                                    onChange={() =>
                                      handleAddtoCanvasClassroom(false)
                                    }
                                  />
                                  <div className="ml-3 flex flex-col">
                                    <span className="block text-sm text-gray-500">
                                      No
                                    </span>
                                  </div>
                                </label>
                              </div>
                            </div>
                          </div>
                        )}
                        
                      </>
                    )}
                    {upgrade && (
                      <>
                        {validateIsGoogleUser(props.profile!) && (
                          <div role="group" aria-labelledby="label-notifications">
                            <div className="sm:grid sm:grid-cols-4 sm:gap-4 sm:items-baseline">
                              <div>
                                <div
                                  className="text-sm text-gray-500"
                                  id="label-notifications"
                                >
                                  Add to Google Classroom
                                </div>
                              </div>
                              <div className="flex col-span-8 sm:col-span-4 xl:col-span-2 gap-4">
                                <label
                                  x-radio-group-option=""
                                  className=" relative flex cursor-pointer focus:outline-none border-gray-400 opacity-50"
                                >
                                  <input
                                    type="radio"
                                    x-model="value"
                                    name="googleclassroom"
                                    value="Public access"
                                    className="h-4 w-4 cursor-pointer text-primary-violet border-gray-300 focus:ring-primary-violet"
                                    aria-labelledby="privacy-setting-0-label"
                                    aria-describedby="privacy-setting-0-description"
                                    disabled={true}
                                    checked={false}
                                  />
                                  <div className="ml-3 flex flex-col">
                                    <span
                                      id="privacy-setting-0-label"
                                      className="block text-sm text-gray-500"
                                    >
                                      Yes
                                    </span>
                                  </div>
                                </label>
                                <label
                                  x-radio-group-option=""
                                  className=" relative flex cursor-pointer focus:outline-none border-gray-400"
                                >
                                  <input
                                    type="radio"
                                    x-model="value"
                                    name="googleclassroom"
                                    value="Public access"
                                    className="h-4 w-4 cursor-pointer text-primary-violet border-gray-300 focus:ring-primary-violet"
                                    aria-labelledby="privacy-setting-0-label"
                                    aria-describedby="privacy-setting-0-description"
                                    disabled={true}
                                    checked={false}
                                  />
                                  <div className="ml-3 flex flex-col">
                                    <span
                                      id="privacy-setting-0-label"
                                      className="block text-sm text-gray-500 opacity-50"
                                    >
                                      No
                                    </span>
                                  </div>
                                </label>
                              </div>
                            </div>
                          </div>
                        )}
                        
                        {validateIsCanvasUser(props.profile!) && (
                          <div role="group" aria-labelledby="label-notifications">
                            <div className="sm:grid sm:grid-cols-4 sm:gap-4 sm:items-baseline">
                              <div>
                                <div
                                  className="text-sm text-gray-500"
                                  id="label-notifications"
                                >
                                  Add to Canvas Classroom
                                </div>
                              </div>
                              <div className="flex col-span-8 sm:col-span-4 xl:col-span-2 gap-4">
                                <label className=" relative flex cursor-pointer focus:outline-none border-gray-400 opacity-50 ">
                                  <input
                                    type="radio"
                                    x-model="value"
                                    name="canvasclassroom"
                                    value="Public access"
                                    className="h-4 w-4 cursor-pointer text-primary-violet border-gray-300 focus:ring-primary-violet"
                                    aria-labelledby="privacy-setting-0-label"
                                    aria-describedby="privacy-setting-0-description"
                                    disabled={true}
                                    checked={false}
                                  />
                                  <div className="ml-3 flex flex-col">
                                    <span className="block text-sm text-gray-500">
                                      Yes
                                    </span>
                                  </div>
                                </label>
                                <label className=" relative flex cursor-pointer focus:outline-none border-gray-400">
                                  <input
                                    type="radio"
                                    x-model="value"
                                    name="canvasclassroom"
                                    value="Public access"
                                    className="h-4 w-4 cursor-pointer text-primary-violet border-gray-300 focus:ring-primary-violet"
                                    aria-labelledby="privacy-setting-0-label"
                                    aria-describedby="privacy-setting-0-description"
                                    disabled={true}
                                    checked={false}
                                  />
                                  <div className="ml-3 flex flex-col">
                                    <span className="block text-sm text-gray-500 opacity-50">
                                      No
                                    </span>
                                  </div>
                                </label>
                              </div>
                            </div>
                          </div>
                        )}
                        
                      </>
                    )}

                    {(!validateIsGoogleUser(props.profile!) || !validateIsCanvasUser(props.profile!)) && !upgrade && (
                      <div className="flex col-span-8 sm:col-span-4 xl:col-span-3 gap-4">
                        <div className="text-sm text-gray-700 font-light bg-yellow-50 p-4 flex w-full rounded-md rounded-t-lg sm:rounded-lg overflow-hidden">
                          <div className="flex-shrink-0 current-fill text-yellow-400">
                            <ExclamationIcon className="w-4 text-yellow-500 mr-2" />
                          </div>
                          <div
                            className="pl-2"
                            dangerouslySetInnerHTML={{
                              __html:
                                "In order to add your activity to Google Classroom or Canvas, you need to login via your respective account.",
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start border-b border-gray-100 pb-1 sticky -top-1 z-10 bg-white">
                    <label
                      htmlFor="first-name"
                      className="block text-sm font-normal text-gray-500"
                    ></label>
                    <div className="mt-1 sm:mt-0 sm:col-span-3">
                      <div className="flex w-full">
                        <div className="flex w-[29.6rem]"></div>
                        <div className="grid grid-cols-5 w-full ">
                          <div className="text-center">
                            Eliminate 1 Answer for Single-Answer MC Questions
                          </div>
                          <div className="text-center">
                            Clear Saved Assignment
                          </div>
                          <div className="text-center">Lock Assignment</div>
                          <div className="text-center">
                            Reset Attempt Counter
                          </div>
                          <div
                            className="text-center cursor-pointer"
                            title="Setting allows you to temporarily override the Text to Speech settings for the single assignment."
                          >
                            Assignment Text To Speech Settings
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start">
                    <label
                      htmlFor="first-name"
                      className="block text-sm font-normal text-gray-500"
                    >
                      Classes
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-3">
                      <div className="mb-4">My Classes</div>
                      {studentsTree &&
                        studentsTree.length > 0 &&
                        studentsTree
                          .filter((r) => !r.isAdditionalClass)
                          .map((studentTree, index) => {
                            return (
                              <div
                                key={index}
                                className={`relative flex flex-col items-start px-2  ${
                                  index % 2 === 0
                                    ? "bg-light-violet/10"
                                    : "bg-white"
                                }`}
                              >
                                <div className="flex w-full">
                                  <div className="flex my-2 w-[29.6rem]">
                                    <div className="flex items-center h-5">
                                      {expandedClasses &&
                                      expandedClasses.length > 0 &&
                                      expandedClasses.some(
                                        (r) => r === studentTree.key
                                      ) ? (
                                        <MinusCircleIcon
                                          className="w-5 mr-2 cursor-pointer"
                                          onClick={() =>
                                            handleExpand(studentTree.key)
                                          }
                                        />
                                      ) : (
                                        <PlusCircleIcon
                                          className="w-5 mr-2 cursor-pointer"
                                          onClick={() =>
                                            handleExpand(studentTree.key)
                                          }
                                        />
                                      )}
                                      <input
                                        onChange={() =>
                                          handleSelectClass(studentTree.key)
                                        }
                                        checked={selectedClasses.some(
                                          (r) => r === studentTree.key
                                        )}
                                        type="checkbox"
                                        className="cursor-pointer focus:ring-primary-violet h-4 w-4 text-primary-violet border-gray-500"
                                      />
                                    </div>
                                    <div className="ml-3 text-sm">
                                      <div className="flex gap-2">
                                        <label className="font-medium text-gray-700">
                                          {studentTree.title}
                                        </label>
                                        <span className="flex gap-2 justify-center items-center">
                                          {studentTree.externalRosterProviderIds &&
                                            studentTree.externalRosterProviderIds
                                              .split(",")
                                              .map((ext) =>
                                                getExternalRosterIcon(
                                                  parseInt(ext)
                                                )
                                              )}
                                          {studentTree.isClassGoogleEnabled &&
                                            getExternalRosterIcon(
                                              Constant.ExternalRosterProviders
                                                .Google
                                            )}
                                        </span>
                                      </div>
                                      <p className="text-gray-500 mt-1">
                                        {selectedStudents.filter(
                                          (r) => r.classId === studentTree.key
                                        ).length !==
                                          studentTree.children?.length && (
                                          <div
                                            className={`mr-1 inline-flex items-baseline px-2.5 py-0.5 rounded-md text-xs font-medium bg-${
                                              selectedStudents.filter(
                                                (r) =>
                                                  r.classId === studentTree.key
                                              ).length === 0
                                                ? "red"
                                                : "blue"
                                            }-100 text-${
                                              selectedStudents.filter(
                                                (r) =>
                                                  r.classId === studentTree.key
                                              ).length === 0
                                                ? "red"
                                                : "blue"
                                            }-800 md:mt-2 lg:mt-0`}
                                          >
                                            {
                                              selectedStudents.filter(
                                                (r) =>
                                                  r.classId === studentTree.key
                                              ).length
                                            }
                                          </div>
                                        )}
                                        {selectedStudents.filter(
                                          (r) => r.classId === studentTree.key
                                        ).length ===
                                        studentTree.children?.length
                                          ? "All"
                                          : "of"}
                                        <div className="ml-1 mr-1 inline-flex items-baseline px-2.5 py-0.5 rounded-md text-xs font-medium bg-green-100 text-green-800 md:mt-2 lg:mt-0">
                                          {studentTree.children?.length}
                                        </div>
                                        students selected
                                      </p>
                                    </div>
                                  </div>
                                  <div className="grid grid-cols-5 w-full divide-x divide-primary-violet/50">
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                  </div>
                                </div>
                                {expandedClasses.some(
                                  (r) => r === studentTree.key
                                ) &&
                                  studentTree.children &&
                                  studentTree.children.length > 0 && (
                                    <div className="w-full">
                                      {studentTree.children.map(
                                        (student, index) => {
                                          return (
                                            <Fragment key={index}>
                                              <div className="flex w-full">
                                                <div className="flex w-[29.6rem]">
                                                  <div className="flex items-center h-5 pl-14">
                                                    {isAssignmentInProgress(
                                                      student.key
                                                    ) && (
                                                      <ReactToolTip
                                                        key={student.key}
                                                        id={
                                                          "assignmentAssignedToolTip" +
                                                          student.key +
                                                          studentTree.key
                                                        }
                                                      />
                                                    )}

                                                    <input
                                                      onChange={() =>
                                                        handleCheckStudent(
                                                          student.key,
                                                          studentTree.key
                                                        )
                                                      }
                                                      checked={selectedStudents.some(
                                                        (r) =>
                                                          r.studentId ===
                                                            student.key &&
                                                          r.classId ===
                                                            studentTree.key
                                                      )}
                                                      disabled={selectedStudents.some(
                                                        (r) =>
                                                          r.studentId ===
                                                            student.key &&
                                                          r.classId !==
                                                            studentTree.key
                                                      )}
                                                      type="checkbox"
                                                      className={`${
                                                        isAssignmentInProgress(
                                                          student.key
                                                        )
                                                          ? "opacity-50 cursor-not-allowed "
                                                          : ""
                                                      } focus:ring-primary-violet h-4 w-4 text-primary-violet border-gray-500`}
                                                      data-tip={
                                                        isAssignmentInProgress(
                                                          student.key
                                                        )
                                                          ? "Student has already been assigned and started the assignment."
                                                          : ""
                                                      }
                                                      data-for={
                                                        "assignmentAssignedToolTip" +
                                                        student.key +
                                                        studentTree.key
                                                      }
                                                    />
                                                  </div>
                                                  <div className="ml-3 text-sm">
                                                    <div className="flex gap-2">
                                                      <label className="font-normal text-gray-700 break-all">
                                                        {student.title}
                                                      </label>
                                                      {student.userExternalProviderIds &&
                                                        student.userExternalProviderIds
                                                          .split(",")
                                                          .map((ext) =>
                                                            getExternalRosterIcon(
                                                              parseInt(ext)
                                                            )
                                                          )}
                                                      {student.isStudentGoogleEnabled &&
                                                        getExternalRosterIcon(
                                                          Constant
                                                            .ExternalRosterProviders
                                                            .Google
                                                        )}
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="grid grid-cols-5 w-full divide-x divide-primary-violet/50">
                                                  <div className="text-center pb-1">
                                                    <input
                                                      onChange={() =>
                                                        handleCheckStudentEliminateAnswer(
                                                          student.key,
                                                          studentTree.key
                                                        )
                                                      }
                                                      type="checkbox"
                                                      checked={selectedStudents.some(
                                                        (r) =>
                                                          r.studentId ===
                                                            student.key &&
                                                          r.classId ===
                                                            studentTree.key &&
                                                          r.eliminateAnswerForSingleMCQ ===
                                                            true
                                                      )}
                                                      disabled={
                                                        !selectedStudents.some(
                                                          (r) =>
                                                            r.studentId ===
                                                              student.key &&
                                                            r.classId ===
                                                              studentTree.key
                                                        ) || isCRAssignment
                                                      }
                                                      className="focus:ring-primary-violet h-4 w-4 text-primary-violet border-gray-500"
                                                    />
                                                  </div>
                                                  <div className="text-center pb-1">
                                                    <input
                                                      onChange={() =>
                                                        clearAssignment(
                                                          student.key,
                                                          studentTree.key
                                                        )
                                                      }
                                                      type="checkbox"
                                                      checked={selectedStudents.some(
                                                        (r) =>
                                                          r.studentId ===
                                                            student.key &&
                                                          r.classId ===
                                                            studentTree.key &&
                                                          r.isClear === true
                                                      )}
                                                      className="focus:ring-primary-violet h-4 w-4 text-primary-violet border-gray-500"
                                                    />
                                                  </div>
                                                  <div className="text-center">
                                                    <input
                                                      disabled={
                                                        !selectedStudents.some(
                                                          (r) =>
                                                            r.studentId ===
                                                              student.key &&
                                                            r.classId ===
                                                              studentTree.key
                                                        )
                                                      }
                                                      onChange={() =>
                                                        lockStudentAssignment(
                                                          student.key,
                                                          studentTree.key
                                                        )
                                                      }
                                                      type="checkbox"
                                                      checked={selectedStudents.some(
                                                        (r) =>
                                                          r.studentId ===
                                                            student.key &&
                                                          r.classId ===
                                                            studentTree.key &&
                                                          r.isLocked === true
                                                      )}
                                                      className="focus:ring-primary-violet h-4 w-4 text-primary-violet border-gray-500"
                                                    />
                                                  </div>
                                                  <div className="text-center">
                                                    <input
                                                      onChange={() =>
                                                        resetAttempt(
                                                          student.key,
                                                          studentTree.key
                                                        )
                                                      }
                                                      type="checkbox"
                                                      checked={selectedStudents.some(
                                                        (r) =>
                                                          r.studentId ===
                                                            student.key &&
                                                          r.classId ===
                                                            studentTree.key &&
                                                          r.isReset === true
                                                      )}
                                                      className="focus:ring-primary-violet h-4 w-4 text-primary-violet border-gray-500"
                                                    />
                                                  </div>
                                                  <div className="text-center">
                                                    <div className="ml-4 flex gap-2 justify-center">
                                                      Off
                                                      <Toggle
                                                        checked={getStudentSettingValue(
                                                          studentTree.key,
                                                          student.key,
                                                          student,
                                                          "TTS",
                                                          selectedStudents
                                                        )}
                                                        disabled={
                                                          !selectedStudents.some(
                                                            (r) =>
                                                              r.studentId ===
                                                                student.key &&
                                                              r.classId ===
                                                                studentTree.key
                                                          ) || isCRAssignment
                                                        }
                                                        onChange={() => {
                                                          handleToggleTTS(
                                                            student.key,
                                                            studentTree.key,
                                                            student
                                                          );
                                                        }}
                                                      />
                                                      On
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </Fragment>
                                          );
                                        }
                                      )}
                                    </div>
                                  )}
                              </div>
                            );
                          })}
                      {roleId === Constant.UserRoleId.SchoolAdmin && (
                        <div className="border-b border-gray-200 pb-2 pt-4 mb-4 flex">
                          Additional School Classes
                          {toggleAdditionalClass ? (
                            <MinusCircleIcon
                              className="w-5 mr-2 cursor-pointer"
                              onClick={() => toggleExpandAll()}
                            />
                          ) : (
                            <PlusCircleIcon
                              className="w-5 mr-2 cursor-pointer"
                              onClick={() => toggleExpandAll()}
                            />
                          )}
                        </div>
                      )}
                      {roleId === Constant.UserRoleId.SchoolAdmin &&
                        studentsTree &&
                        studentsTree.length > 0 &&
                        studentsTree
                          .filter((r) => r.isAdditionalClass)
                          .map((studentTree, index) => {
                            return (
                              <div
                                key={index}
                                className={`relative flex flex-col items-start px-2  ${
                                  index % 2 === 0
                                    ? "bg-light-violet/10"
                                    : "bg-white"
                                }`}
                              >
                                <div className="flex w-full">
                                  <div className="flex my-2 w-[29.6rem]">
                                    <div className="flex items-center h-5">
                                      {expandedClasses &&
                                      expandedClasses.length > 0 &&
                                      expandedClasses.some(
                                        (r) => r === studentTree.key
                                      ) ? (
                                        <MinusCircleIcon
                                          className="w-5 mr-2 cursor-pointer"
                                          onClick={() =>
                                            handleExpand(studentTree.key)
                                          }
                                        />
                                      ) : (
                                        <PlusCircleIcon
                                          className="w-5 mr-2 cursor-pointer"
                                          onClick={() =>
                                            handleExpand(studentTree.key)
                                          }
                                        />
                                      )}
                                      <input
                                        onChange={() =>
                                          handleSelectClass(studentTree.key)
                                        }
                                        checked={selectedClasses.some(
                                          (r) => r === studentTree.key
                                        )}
                                        type="checkbox"
                                        className="cursor-pointer focus:ring-primary-violet h-4 w-4 text-primary-violet border-gray-500"
                                      />
                                    </div>
                                    <div className="ml-3 text-sm">
                                      <div className="flex gap-2">
                                        <label className="font-medium text-gray-700">
                                          {`${studentTree.title}-${studentTree.teacherName}`}
                                        </label>
                                        <span className="flex gap-2 justify-center items-center">
                                          {studentTree.externalRosterProviderIds &&
                                            studentTree.externalRosterProviderIds
                                              .split(",")
                                              .map((ext) =>
                                                getExternalRosterIcon(
                                                  parseInt(ext)
                                                )
                                              )}
                                          {studentTree.isClassGoogleEnabled &&
                                            getExternalRosterIcon(
                                              Constant.ExternalRosterProviders
                                                .Google
                                            )}
                                        </span>
                                      </div>
                                      <p className="text-gray-500 mt-1">
                                        {selectedStudents.filter(
                                          (r) => r.classId === studentTree.key
                                        ).length !==
                                          studentTree.children?.length && (
                                          <div
                                            className={`mr-1 inline-flex items-baseline px-2.5 py-0.5 rounded-md text-xs font-medium bg-${
                                              selectedStudents.filter(
                                                (r) =>
                                                  r.classId === studentTree.key
                                              ).length === 0
                                                ? "red"
                                                : "blue"
                                            }-100 text-${
                                              selectedStudents.filter(
                                                (r) =>
                                                  r.classId === studentTree.key
                                              ).length === 0
                                                ? "red"
                                                : "blue"
                                            }-800 md:mt-2 lg:mt-0`}
                                          >
                                            {
                                              selectedStudents.filter(
                                                (r) =>
                                                  r.classId === studentTree.key
                                              ).length
                                            }
                                          </div>
                                        )}
                                        {selectedStudents.filter(
                                          (r) => r.classId === studentTree.key
                                        ).length ===
                                        studentTree.children?.length
                                          ? "All"
                                          : "of"}
                                        <div className="ml-1 mr-1 inline-flex items-baseline px-2.5 py-0.5 rounded-md text-xs font-medium bg-green-100 text-green-800 md:mt-2 lg:mt-0">
                                          {studentTree.children?.length}
                                        </div>
                                        students selected
                                      </p>
                                    </div>
                                  </div>
                                  <div className="grid grid-cols-5 w-full divide-x divide-primary-violet/50">
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                  </div>
                                </div>
                                {expandedClasses.some(
                                  (r) => r === studentTree.key
                                ) &&
                                  studentTree.children &&
                                  studentTree.children.length > 0 && (
                                    <div className="w-full">
                                      {studentTree.children.map(
                                        (student, index) => {
                                          return (
                                            <Fragment key={index}>
                                              <div className="flex w-full">
                                                <div className="flex w-[29.6rem]">
                                                  <div className="flex items-center h-5 pl-14">
                                                    {isAssignmentInProgress(
                                                      student.key
                                                    ) && (
                                                      <ReactToolTip
                                                        key={student.key}
                                                        id={
                                                          "assignmentAssignedToolTip" +
                                                          student.key +
                                                          studentTree.key
                                                        }
                                                      />
                                                    )}
                                                    <input
                                                      onChange={() =>
                                                        handleCheckStudent(
                                                          student.key,
                                                          studentTree.key
                                                        )
                                                      }
                                                      checked={selectedStudents.some(
                                                        (r) =>
                                                          r.studentId ===
                                                            student.key &&
                                                          r.classId ===
                                                            studentTree.key
                                                      )}
                                                      disabled={selectedStudents.some(
                                                        (r) =>
                                                          r.studentId ===
                                                            student.key &&
                                                          r.classId !==
                                                            studentTree.key
                                                      )}
                                                      type="checkbox"
                                                      className={`${
                                                        isAssignmentInProgress(
                                                          student.key
                                                        )
                                                          ? "opacity-50 cursor-not-allowed "
                                                          : ""
                                                      } focus:ring-primary-violet h-4 w-4 text-primary-violet border-gray-500`}
                                                      data-tip={
                                                        isAssignmentInProgress(
                                                          student.key
                                                        )
                                                          ? "Student has already been assigned and started the assignment."
                                                          : ""
                                                      }
                                                      data-for={
                                                        "assignmentAssignedToolTip" +
                                                        student.key +
                                                        studentTree.key
                                                      }
                                                    />
                                                  </div>
                                                  <div className="ml-3 text-sm">
                                                    <div className="flex gap-2">
                                                      <label className="font-normal text-gray-700 break-all">
                                                        {student.title}
                                                      </label>

                                                      {student.userExternalProviderIds &&
                                                        student.userExternalProviderIds
                                                          .split(",")
                                                          .map((ext) =>
                                                            getExternalRosterIcon(
                                                              parseInt(ext)
                                                            )
                                                          )}

                                                      {student.isStudentGoogleEnabled &&
                                                        getExternalRosterIcon(
                                                          Constant
                                                            .ExternalRosterProviders
                                                            .Google
                                                        )}
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="grid grid-cols-5 w-full divide-x divide-primary-violet/50">
                                                  <div className="text-center pb-1">
                                                    <input
                                                      onChange={() =>
                                                        handleCheckStudentEliminateAnswer(
                                                          student.key,
                                                          studentTree.key
                                                        )
                                                      }
                                                      type="checkbox"
                                                      checked={selectedStudents.some(
                                                        (r) =>
                                                          r.studentId ===
                                                            student.key &&
                                                          r.classId ===
                                                            studentTree.key &&
                                                          r.eliminateAnswerForSingleMCQ ===
                                                            true
                                                      )}
                                                      disabled={
                                                        !selectedStudents.some(
                                                          (r) =>
                                                            r.studentId ===
                                                              student.key &&
                                                            r.classId ===
                                                              studentTree.key
                                                        ) || isCRAssignment
                                                      }
                                                      className="focus:ring-primary-violet h-4 w-4 text-primary-violet border-gray-500"
                                                    />
                                                  </div>

                                                  <div className="text-center pb-1">
                                                    <input
                                                      onChange={() =>
                                                        clearAssignment(
                                                          student.key,
                                                          studentTree.key
                                                        )
                                                      }
                                                      type="checkbox"
                                                      checked={selectedStudents.some(
                                                        (r) =>
                                                          r.studentId ===
                                                            student.key &&
                                                          r.classId ===
                                                            studentTree.key &&
                                                          r.isClear === true
                                                      )}
                                                      className="focus:ring-primary-violet h-4 w-4 text-primary-violet border-gray-500"
                                                    />
                                                  </div>

                                                  <div className="text-center">
                                                    <input
                                                      disabled={
                                                        !selectedStudents.some(
                                                          (r) =>
                                                            r.studentId ===
                                                              student.key &&
                                                            r.classId ===
                                                              studentTree.key
                                                        )
                                                      }
                                                      onChange={() =>
                                                        lockStudentAssignment(
                                                          student.key,
                                                          studentTree.key
                                                        )
                                                      }
                                                      type="checkbox"
                                                      checked={selectedStudents.some(
                                                        (r) =>
                                                          r.studentId ===
                                                            student.key &&
                                                          r.classId ===
                                                            studentTree.key &&
                                                          r.isLocked === true
                                                      )}
                                                      className="focus:ring-primary-violet h-4 w-4 text-primary-violet border-gray-500"
                                                    />
                                                  </div>

                                                  <div className="text-center">
                                                    <input
                                                      onChange={() =>
                                                        resetAttempt(
                                                          student.key,
                                                          studentTree.key
                                                        )
                                                      }
                                                      type="checkbox"
                                                      checked={selectedStudents.some(
                                                        (r) =>
                                                          r.studentId ===
                                                            student.key &&
                                                          r.classId ===
                                                            studentTree.key &&
                                                          r.isReset === true
                                                      )}
                                                      className="focus:ring-primary-violet h-4 w-4 text-primary-violet border-gray-500"
                                                    />
                                                  </div>

                                                  <div className="text-center">
                                                    <div className="ml-4 flex gap-2 justify-center">
                                                      Off
                                                      <Toggle
                                                        checked={getStudentSettingValue(
                                                          studentTree.key,
                                                          student.key,
                                                          student,
                                                          "TTS",
                                                          selectedStudents
                                                        )}
                                                        disabled={
                                                          !selectedStudents.some(
                                                            (r) =>
                                                              r.studentId ===
                                                                student.key &&
                                                              r.classId ===
                                                                studentTree.key
                                                          ) || isCRAssignment
                                                        }
                                                        onChange={() => {
                                                          handleToggleTTS(
                                                            student.key,
                                                            studentTree.key,
                                                            student
                                                          );
                                                        }}
                                                      />
                                                      On
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </Fragment>
                                          );
                                        }
                                      )}
                                    </div>
                                  )}
                              </div>
                            );
                          })}
                    </div>
                  </div>
                  <div role="group" aria-labelledby="label-notifications">
                    <div className="sm:grid sm:grid-cols-4 sm:gap-4 sm:items-baseline">
                      <div>
                        <div
                          className="text-sm text-gray-500"
                          id="label-notifications"
                        >
                          Notes for Students
                        </div>
                      </div>
                      <div className="flex col-span-8 sm:col-span-4 xl:col-span-2 gap-4">
                        <label
                          x-radio-group-option=""
                          className=" relative flex cursor-pointer focus:outline-none border-gray-400"
                        >
                          <input
                            type="radio"
                            checked={notesType === "Text"}
                            x-model="value"
                            name="notes"
                            value="Public access"
                            className="h-4 w-4 cursor-pointer text-primary-violet border-gray-300 focus:ring-primary-violet"
                            aria-labelledby="privacy-setting-0-label"
                            aria-describedby="privacy-setting-0-description"
                            onChange={() => handleMessageTypeChange("Text")}
                          />
                          <div className="ml-3 flex flex-col">
                            <span
                              id="privacy-setting-0-label"
                              className="block text-sm text-gray-500"
                            >
                              Text message
                            </span>
                          </div>
                        </label>
                        <label
                          x-radio-group-option=""
                          className=" relative flex cursor-pointer focus:outline-none border-gray-400"
                        >
                          <input
                            checked={notesType === "Video"}
                            type="radio"
                            x-model="value"
                            name="notes"
                            value="Public access"
                            className="h-4 w-4 cursor-pointer text-primary-violet border-gray-300 focus:ring-primary-violet"
                            aria-labelledby="privacy-setting-0-label"
                            aria-describedby="privacy-setting-0-description"
                            onChange={() => handleMessageTypeChange("Video")}
                          />
                          <div className="ml-3 flex flex-col">
                            <span
                              id="privacy-setting-0-label"
                              className="block text-sm text-gray-500"
                            >
                              Video message
                            </span>
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start">
                    <label
                      htmlFor="first-name"
                      className="block text-sm font-normal text-gray-500"
                    ></label>
                    <div className="sm:mt-0 sm:col-span-2">
                      {notesType === "Text" && (
                        <Editor
                          wrapperClassName="texteditor-wrapper"
                          editorClassName="bg-white demo-editor h-48 overflow-hidden"
                          defaultContentState={convertToRaw(
                            ContentState.createFromBlockArray(
                              convertFromHTML(
                                assignment.textNote ? assignment.textNote : ""
                              )
                            )
                          )}
                          value={messageContent}
                          onEditorStateChange={(e) => {
                            handleMessageTextChange(
                              convertToRaw(e.getCurrentContent()).blocks
                                .length > 0
                                ? convertToRaw(e.getCurrentContent()).blocks[0]
                                    .text
                                : ""
                            );
                          }}
                        />
                      )}
                      {notesType === "Video" && (
                        <div className="w-full lg:w-2/3 xxl:w-3/4 ">
                          {videoRecordMode && (
                            <div>
                              <ReactMediaRecorder
                                stopStreamsOnStop={true}
                                video
                                mediaRecorderOptions={{
                                  mimeType: "video/webm;codecs:vp9,opus"
                                }}
                                onStop={(blobUrl, blob) => {
                                  handleVideoStopEvent(blob);
                                }}
                                blobPropertyBag={{
                                  type: Constant.MimeTypes.MP4,
                                }}
                                askPermissionOnMount={true}
                                render={({ startRecording, stopRecording, mediaBlobUrl, previewStream }) => (
                                  <div>
                                    {mediaBlobUrl && <video src={mediaBlobUrl} controls autoPlay loop />}
                                    <VideoRecorder 
                                      stream={previewStream} 
                                      onStartRecording={startRecording} 
                                      maxRecordingTimeLimit={maxRecordingTimeLimit}
                                      onStopRecording={() => {
                                        stopRecording();
                                        setVideoRecordMode(false);                                
                                      }} 
                                    />
                                  </div>
                                )}
                              />
                            </div>
                          )}
                          {!videoRecordMode && (
                            <video
                              className="w-full"
                              controls
                              preload="none"
                              src={
                                assignment.videoNote &&
                                assignment.videoNote !== ""
                                  ? assignment.videoNote
                                  : window.URL.createObjectURL(selectedfile)
                              }
                            />
                          )}

                          <p className="text-sm text-yellow-700 bg-yellow-50 border border-yellow-300 p-4 block rounded-md mt-4">
                            <div className="text-sm text-gray-500 flex items-center space-x-1 mb-1">
                              <input
                                type="checkbox"
                                id="chkApprove"
                                onChange={(e) => {
                                  setIsMessageApproved(e.target.checked);
                                }}
                                checked={isMessageApproved}
                              />
                              <label htmlFor="chkApprove"> Approved </label>
                            </div>
                            Please preview the video once if you haven't
                            already, you need to approve that the content is
                            appropriate for your students.
                          </p>
                          <button
                            onClick={() => handleRecordAgain()}
                            className="mt-2 bg-transparent border my-auto border-primary-violet shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-primary-violet hover:text-white hover:bg-light-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3 transition ease-in-out duration-500 "
                            type="button"
                          >
                            Record Another Video
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>

          {/* <!-- Action Buttons -->  */}
          <footer className="flex items-center justify-end px-4 py-4 sm:flex-row bg-gray-50 dark:bg-gray-800 mt-4">
            <div className="space-x-3 flex justify-end">
              <button
                onClick={() => handleSave(true)}
                className="bg-primary-violet border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-dark-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3"
              >
                Save Assignment
              </button>
              <button
                onClick={() => toggleSettingPopup(false)}
                className="align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 rounded-lg text-sm text-gray-600 border-gray-300 border dark:text-gray-400 focus:outline-none active:bg-transparent hover:border-gray-500 focus:border-gray-500 active:text-gray-500 focus:shadow-outline-gray w-full sm:w-auto"
              >
                Close
              </button>
            </div>
          </footer>
        </div>
      </div>
    </Fragment>
  );
}

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(SettingPopup);

import { Fragment, useEffect, useState } from "react";
import "../../../../../../../css/style.css";
import { getStandardAssignmentResult } from "../../../../../../../api/student/liftOff/myGalaxyApi";
import { connect } from "react-redux";
import { UserContextState } from "../../../../../../../redux/actions/userContextAction";
import Profile from "../../../../../../../model/users/profile";
import OverviewResult from "./overviewResult";
import { useTranslation } from "react-i18next";
import LearnosityReport from "../../../../../results/learnosityReport";
import { getStandardGroupStandards } from "../../../../../../../api/cmsApi";
import RouteConstant from "../../../../../../../utils/constant/routeConstant";
import Constant from "../../../../../../../utils/constant/constant";
import Breadcrumb from "../../../../../teacher/shared/breadcrumb";
import MiddleSchoolBreadcrumb from "../../../../middleSchool/breadcrumb";
import { IBreadcrumb } from "../../../../../../../model/common/breadcrumb";
import Loader from "../../../../../../shared/loader";
import { useLocation } from "react-router-dom";

interface ILiftOffMyGalaxyStandardResult {
  userContext: UserContextState;
  profile: Profile;
  match: any;
}
const ELLIFTOFFMYGALAXYSTANDARDRESULT = (
  props: ILiftOffMyGalaxyStandardResult
) => {
  const location = useLocation();
  const { t } = useTranslation();
  const [liftOffGalaxyStadardResult, setLiftOffGalaxyStadardResult] =
    useState<any>(null);
  const standardId = parseInt(props.match.params.standardId);
  const isStandardGrouped = props.match.params.isStandardGrouped;
  const sessionId = props.match.params.sessionId;
  const url = window.location.href;
  const [standardIds, setStandardIds] = useState<Array<number>>();
  const [learnosityActivityUserId, setLearnosityActivityUserId] =
    useState<number>(props.userContext.userId);
  const [noData, setNoData] = useState<Boolean>(false);
  const [loading, setLoading] = useState<Boolean>(false);

  useEffect(() => {
    if (isStandardGrouped == "true") {
      getStandardGroupStandards(standardId).then((response) => {
        if (response.data.data) {
          setStandardIds(
            response.data?.data.map((s) => {
              return s.standardId;
            })
          );
        }
      });
    }

    getResult(
      props.userContext.userId,
      isStandardGrouped === "true" ? true : false,
      standardId,
      sessionId
    );
  }, []);

  function getResult(
    userId: number,
    isStandardGrouped: boolean,
    standardId?: number,
    sessionId?: any
  ) {
    setLoading(true);
    getStandardAssignmentResult(
      props.userContext.userId,
      isStandardGrouped,
      standardId,
      sessionId
    ).then((res) => {
      if (
        res.data.totalQuestions === 0 &&
        res.data.totalAttempted === 0 &&
        res.data.headers === null
      ) {
        setNoData(true);
      } else {
        setLearnosityActivityUserId(
          res.data.learnosityActivityUserId ?? res.data.userId
        );
        setLiftOffGalaxyStadardResult(
          res.data && res.data.headers && res.data.headers.length > 0
            ? res.data.headers[0]
            : null
        );
      }
      setLoading(false);
    });
  }

  const breadcrumbItems = () => {
    let items: Array<IBreadcrumb> = [];
    if (url.indexOf("activitysearch") > -1) {
      items = [
        { name: t("Breadcrumb.Home"), url: RouteConstant.ROUTE_DASHBOARD },
        { name: t("Breadcrumb.Reports"), url: RouteConstant.REPORTS },
        {
          name: t("Breadcrumb.StudentActivitySearchReport"),
          url: RouteConstant.TeacherRoutes.StudentActivitySearchReport,
        },
        { name: "Student Result", url: "" },
      ];
    } else if (url.indexOf("liftoffactivityreport") > -1) {
      items = [
        { name: t("Breadcrumb.Home"), url: RouteConstant.ROUTE_DASHBOARD },
        { name: t("Breadcrumb.Reports"), url: RouteConstant.REPORTS },
        {
          name: t("Breadcrumb.LiftoffActivityReport"),
          url: RouteConstant.TeacherRoutes.LiftoffActivityReport,
        },
        { name: "Student Result", url: "" },
      ];
    } else {
      items = [];
      if (props.userContext.roleId === Constant.UserRoleId.Student) {
        if (location.pathname.toLowerCase().indexOf("gradedwork") > -1) {
          items.push({
            name: t("Breadcrumb.GradedWork"),
            url:
              location.pathname.toLowerCase().indexOf("kindergarten") > -1
                ? RouteConstant.KindergartenStudentRoutes.GradedWork
                : location.pathname.toLowerCase().indexOf("elementary") > -1
                ? RouteConstant.StudentRoute.Home
                : location.pathname.toLowerCase().indexOf("middleschool") > -1
                ? RouteConstant.MiddleSchool.gradedWork
                : "",
          });
        } else {
          items.push({
            name: t("Breadcrumb.LiftOffMyGalaxy"),
            url:
              location.pathname.toLowerCase().indexOf("kindergarten") > -1
                ? RouteConstant.LiftOff.Kindergarten.Home
                : location.pathname.toLowerCase().indexOf("elementary") > -1
                ? RouteConstant.LiftOff.Elementary.Home
                : location.pathname.toLowerCase().indexOf("middleschool") > -1
                ? RouteConstant.LiftOff.MiddleSchool.Home
                : "",
          });
        }
      }
      items.push({ name: "Result", url: "" });
    }
    return items;
  };

  return (
    <Fragment>
      {loading && <Loader />}
      {location.pathname.toLowerCase().indexOf("middleschool") > -1 && (
        <div className="px-4 py-5 lg:px-8 border-b">
          <MiddleSchoolBreadcrumb items={breadcrumbItems()} />
        </div>
      )}
      <div className="">
        {noData && (
          <div className="flex items-center px-4 py-5">
            No Data Available For The Selected Activity
          </div>
        )}
      </div>
      {liftOffGalaxyStadardResult && (
        <div className="bg-white flex-grow rounded-2xl mb-4 m-5">
          <div className="px-4 py-5 lg:px-8 border-b">
            {location.pathname.toLowerCase().indexOf("middleschool") === -1 && (
              <Breadcrumb items={breadcrumbItems()} />
            )}
          </div>
          <div className="p-5 flex flex-col md:flex-row">
            <OverviewResult data={liftOffGalaxyStadardResult} />
            <div className="flex-grow h-auto md:w-1/2">
              <div className="flex w-full mt-6 md:mt-0 pb-6">
                <div>
                  <h1 className="text-2xl font-bold text-gray-700 capitalize">
                    {t("MYGalaxyStandardResult.Title")}
                  </h1>
                  <h2 className="text-base font-medium text-gray-700 capitalize">
                    {liftOffGalaxyStadardResult?.domains[0]?.domainName}-
                    {liftOffGalaxyStadardResult?.standards[0]?.standardName}
                  </h2>
                </div>
              </div>
              <div className="">
                <div className="p-5 bg-gray-50 border rounded-md text-sm font-semibold">
                  <h2>
                    {" "}
                    {t("MYGalaxyStandardResult.AnswerStatusByQuestionNo")}
                  </h2>
                  <ul className="flex flex-wrap gap-2 w-full xl:w-1/2 mt-3">
                    {liftOffGalaxyStadardResult.questions.map(
                      (question, index) => {
                        return (
                          <>
                            {question.order > 0 &&
                              question.score === question.pointsPossible && (
                                <li className="w-6 h-6 bg-[#B2D136] border border-[#B2D136] text-white text-xs font-semibold flex items-center rounded-sm shadow-sm justify-center hover:bg-transparent hover:text-[#B2D136]">
                                  {index + 1}
                                </li>
                              )}
                            {question.order > 0 &&
                              question.score < question.pointsPossible && (
                                <li className="w-6 h-6 bg-[#e53f71] border border-[#e53f71] text-white text-xs font-semibold flex items-center rounded-sm shadow-sm justify-center hover:bg-transparent hover:text-[#e53f71]">
                                  {index + 1}
                                </li>
                              )}
                          </>
                        );
                      }
                    )}
                  </ul>
                </div>
                <div className="mt-5 p-5 border-2 border-blue-400 rounded-md text-sm font-semibold">
                  <LearnosityReport
                    sessionId={liftOffGalaxyStadardResult.lernositySessionId}
                    user_id={learnosityActivityUserId.toString()}
                    show_answers={true}
                    wrapperClass="EL-Wrapper"
                    standardIds={
                      isStandardGrouped == "true"
                        ? standardIds?.join(",")
                        : standardId?.toString()
                    }
                    subjectId={liftOffGalaxyStadardResult.subjectId}
                  ></LearnosityReport>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
};
export default connect<{}, {}, {}>(
  mapStateToProps,
  {}
)(ELLIFTOFFMYGALAXYSTANDARDRESULT);

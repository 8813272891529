import { Fragment, useEffect, useState } from "react";

import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { getVideoAssignment } from "../../../../../../api/student/studentVideoAssignmentApi";

import { IStudentVideoAssignment } from "../../../../../../model/interface/studentVideoAssignment";
import Profile from "../../../../../../model/users/profile";
import { UserContextState } from "../../../../../../redux/actions/userContextAction";
import routeConstant from "../../../../../../utils/constant/routeConstant";
import useTextToSpeech from "../../../shared/textToSpeech/useTextToSpeech";
import constant from "../../../../../../utils/constant/constant";

interface IVideoQuestion {
  match?: any;
  userContext?: UserContextState;
  profile?: Profile;
}

function StandardVideoQuestionList(props: IVideoQuestion) {
  const history = useHistory();
  const standardId = props.match?.params?.standardId
    ? parseInt(atob(props.match.params.standardId))
    : 0;
  const subjectId = props.match?.params?.subjectId
    ? parseInt(atob(props.match.params.subjectId))
    : 0;

  const [standardVideos, setStandardVideos] = useState<
    Array<IStudentVideoAssignment>
  >([]);
  const { startSpeaking, stopSpeaking } = useTextToSpeech();

  useEffect(() => {
    if (standardId > 0 && subjectId > 0) {
      getVideoAssignment(
        props.userContext?.userId ?? 0,
        subjectId,
        standardId
      ).then((d) => {
        setStandardVideos(d.data);
      });
    }
  }, []);

  function getStatus(a: IStudentVideoAssignment) {
    switch (a.assignmentStatusId) {
      case 0:
        return "Not Started";
      case 2:
        return "Continue";
      case 3:
        return a.score >= constant.VIDEO_PASSING_SCORE
          ? "Complete - " + a.score.toString() + "%"
          : "Try Again";
      default:
        return "";
    }
  }

  function startVideoPractice(
    videoId: number,
    standardId: number,
    standardName: string
  ) {
    history.push({
      pathname:
        routeConstant.KindergartenStudentRoutes.VideoQuestionPractice.replace(
          ":standardName",
          encodeURI(btoa(standardName))
        )
          .replace(":subjectId", encodeURI(btoa(subjectId.toString())))
          .replace(":standardId", encodeURI(btoa(standardId.toString())))
          .replace(":videoId", encodeURI(btoa(videoId.toString()))),
      state: {
        subjectName: "",
        contentAreaId: "",
        subjectId: "",
      },
    });
  }

  return (
    <Fragment>
      <div className="h-full pt-7">
        <div className="px-4 lg:px-8 grid grid-cols-1 md:grid-cols-5 md:gap-5 mb-8">
          <div className="col-span-3 lg:col-span-4 order-last md:order-none">
            <div className="bg-[#403d6a] rounded-lg mt-6 md:mt-0">
              <h3 className="py-4 px-7 bg-[#4e4b75] rounded-t-lg tracking-wider font-Didact relative text-lg  lg:text-xl xl:text-2xl text-[#f5d500] text-white hover:shadow-2xl h-full flex items-center justify-center">
                Videos
              </h3>
              <div className="p-4">
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 gap-12 text-center flex flex-col mb-6">
                  {standardVideos.map((video) => {
                    return (
                      <Fragment key={video.videoId}>
                        <div
                          className="bg-white group rounded-lg hover:shadow-2xl cursor-pointer flex flex-col justify-between overflow-hidden border-8 border-[#C038D3] hover:shadow-2xl hover:bg-[#f19afc]"
                          onClick={() => {
                            startVideoPractice(
                              video.videoId,
                              video.standardId,
                              video.videoTitle
                            );
                          }}
                          onMouseEnter={() => {
                            startSpeaking(
                              video.videoTitle,
                              (props.profile?.plLanguageId ?? 1) === 2 ? 2 : 1
                            );
                          }}
                          onMouseLeave={() => {
                            stopSpeaking();
                          }}
                        >
                          <div className=" flex flex-col h-full">
                            <span className="inline-block p-4">
                              <img
                                className="group-hover:scale-110 transform transition-all duration-300 rounded-xl h-36 mx-auto cursor-pointer"
                                srcSet={
                                  "https://assets.learnosity.com/organisations/771/videos/" +
                                  video.externalId +
                                  "/thumbnail-1-1.jpg"
                                }
                                alt="video questions"
                                title="Video questions"
                              />
                            </span>
                            <div className="justify-between flex-grow p-4 bg-gray-100 transition-all">
                              <div className="flex w-full justify-between items-center">
                                <span className="block font-semibold text-lg text-gray-700 group-hover:text-gray-900 mb-1">
                                  {getStatus(video)}
                                </span>
                              </div>
                              <div className="flex w-full">
                                <span className="block text-xl text-left font-semibold text-secondary-teal uppercase">
                                  {video.videoTitle}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Fragment>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
};

export default connect<{}, {}, {}>(
  mapStateToProps,
  {}
)(StandardVideoQuestionList);

import { XIcon } from "@heroicons/react/outline";
import { Select } from "@windmill/react-ui";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { ClassApi } from "../../../../../../api/teacher/classManagementApi";
import { UserContextState } from "../../../../../../redux/actions/userContextAction";
import {
  DEFAULT_PAGE_NUMBER,
  DEFAULT_PAGE_SIZE,
} from "../../../../../../utils/pagingConstant";
import ConfirmationDialog from "../../../../../shared/confirmationDialog";
import InfoMessage from "../../../../../shared/infoMessage";
import PageSize from "../../../../../shared/pagination/pageSize";
import Pagination from "../../../../../shared/pagination/pagination";
import {
  IEligibleImportClassStudentData,
  IEligibleImportClassData
} from "../../../../../../model/teacher/classManagement/IEligibleImportClassStudentData";
import Loader from "../../../../../shared/loader";

interface ImportStudentClassDataProps {
  showPopUp: (show: boolean) => void;
  currentClassId: number;
  currentClassName: string;
  userContext?: UserContextState;
}

const ImportStudentClassDataPopup = (props: ImportStudentClassDataProps) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [classes, setClasses] = useState<Array<any>>();
  const [importClassStudents, setImportClassStudents] = useState<Array<IEligibleImportClassStudentData>>();
  const [sourceClass, setSourceClass] = useState<number>(0);
  const [sourceClassStudents, setSourceClassStudents] = useState<Array<any>>();
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [pageNumber, setPageNumber] = useState(DEFAULT_PAGE_NUMBER);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [resetPageNumber, setResetPageNumber] = useState<boolean>(false);
  const [selectedImportStudents, setSelectedImportStudents] = useState<Array<number>>(
    []
  );
  useEffect(() => {
    setLoading(true);
    ClassApi.getEligibleImportStudents(props.currentClassId).then(responseData => {
      processStudentImportEligibilityData(responseData.data);
      }
    );
  }, []);

  useEffect(() => {
    setIsDisabled(selectedImportStudents.length === 0);
  }, [selectedImportStudents]);

  function processStudentImportEligibilityData(eligibleImportClassStudentData: Array<IEligibleImportClassStudentData>) {
    let tempClasses: Array<IEligibleImportClassData> = [];

    eligibleImportClassStudentData.forEach(
        item => tempClasses.push({
          classId: item.classId,
          classDisplayName: item.classDisplayName,
          teacherName: item.teacherName,
        })

    );
    setClasses(tempClasses);
    setImportClassStudents(eligibleImportClassStudentData);
    setLoading(false);
  }

  function updateSourceClass(classId: number) {
    setSourceClass(classId);
    setSourceClassStudents(importClassStudents?.filter(student => student.classId == classId));
  }

  function confirmationCancelClose() {
    setOpenConfirmation(false);
  }

  function confirmationOkClose() {
    ClassApi.importStudentClassData(
      sourceClass,
      props.currentClassId,
      selectedImportStudents
    ).then((d) => {
      let failedStudents = d.data.filter(
        (t) => !t.isTransferred
      );
      if (
          failedStudents !== undefined &&
          failedStudents !== null &&
          failedStudents.length
      ) {
        toast.warning(
          "Student data import failed. Can not import data for " +
            failedStudents.length +
            " student."
        );
      } else {
        toast.success("Student data imported successfully.");
      }
    });

    props.showPopUp(false);
  }
  function selectStudent(userId: any) {
    let selectedStudents = [...selectedImportStudents];
    if (selectedStudents.includes(userId)) {
      selectedStudents = selectedStudents.filter((ds) => ds !== userId);
      setSelectedImportStudents(selectedStudents);
    } else {
      selectedStudents.push(userId);
      setSelectedImportStudents(selectedStudents);
    }
  }

  function selectAllStudent(): void {
    let selectedStudents = Array<number>();
    sourceClassStudents?.map(student => {
      if (!selectAll) {
        selectedStudents.push(student.userId);
      }
    });
    setSelectedImportStudents(selectedStudents);
    setSelectAll(!selectAll);
  }

  const onPageSizeChange = (pageSize) => {
    setPageNumber(DEFAULT_PAGE_NUMBER);
    setPageSize(pageSize);
    setResetPageNumber(true);
    setLoading(false);
  };

  function ImportStudentClassData() {
    if (isDisabled) {
      toast.warning('You must select one or more students to import data');
      return;
    }
    if (
      sourceClass === undefined ||
      sourceClass === null ||
      sourceClass === 0
    ) {
      toast.warning("Please select a source class to import student data");
    } else {
      setOpenConfirmation(true);
    }
  }
  return (
    <div className="fixed inset-0 z-40 flex items-center bg-black bg-opacity-50 sm:items-center sm:justify-center">
      <div
        className="w-full flex flex-col justify-between overflow-auto h-auto max-h-80percent bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:max-w-5xl"
        role="dialog"
      >
      {loading && <Loader />}
        <header>
          {/* Header */}
          <div className="px-4 py-4 bg-gray-50">
            <div className="flex items-start justify-between space-x-3">
              <div className="space-y-1">
                <div className="text-lg font-medium text-gray-900">
                  Import Student Data
                </div>
              </div>
              <div className="h-7 flex items-center">
                <button
                  type="button"
                  className="text-gray-400 hover:text-gray-500"
                  onClick={() => props.showPopUp(false)}
                >
                  <span className="sr-only">Close panel</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </header>
        <div className="flex justify-end px-4 grid grid-cols-12 space-y-2 sm:space-y-0 sm:space-x-6 border-t">
          <div className="col-span-12 sm:col-span-6">
            <label
                htmlFor="sourceClass"
                className="block text-sm  text-gray-500 sm:mt-px sm:pt-2"
            >
              Class To Import From
            </label>
            <Select
                id="sourceClass"
                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:ring-primary-violet focus:border-primary-violet sm:text-sm"
                onChange={(e) => {
                  updateSourceClass(Number.parseInt(e.target.value));
                }}
            >
              <option key={"default"} value={""}>No Selection</option>
              {classes?.map((item) => {
                if(item.classId != props.currentClassId)
                  return (
                      <option key={item.classId} value={item.classId}>
                        {item.classDisplayName} - {item.teacherName}
                      </option>
                  );
              })}
            </Select>
          </div>
        </div>
        <div className="w-full relative overflow-show px-4 py-1">
          <div className="p-1">
            <InfoMessage message="Select a source class and then a student within that class to import assignment data
            where the assignment is present in both classes. Only eligible classes and students will appear.
            If the student is not in the current class (import into class), then you can go to the class where the data
            is housed (import from class) and use the transfer student feature.
            The assignment data will no longer be available for the class that the data is migrated from." />
          </div>
          {/* <!-- Divider container -->  */}
          <div className="p-3 sm:py-0 sm:space-y-0 sm:divide-y sm:divide-gray-200 text-sm text-gray-700 dark:text-gray-400">
            <div className="space-y-1 px-2 sm:space-y-0 sm:grid sm:grid-cols-1 sm:gap-4 sm:py-2">
              <label className="font-semibold">
                Current Class (import into):{" "}
                <span className="font-semibold text-gray-500">
                  {props.currentClassName}
                </span>{" "}
              </label>
              <table className="table w-full overflow-hidden">
                <thead className="mb-5">
                  <tr className="bg-light-violet  text-white py-3 px-3 overflow-hidden">
                    <th className="text-left  p-3 text-sm font-normal w-4">
                      {" "}
                      <label
                        x-radio-group-option=""
                        className="relative cursor-pointer"
                      >
                        <input
                          type="checkbox"
                          x-model="value"
                          name="privacy_setting"
                          value="Private to Project Members"
                          className="h-4 w-4 mt-0.5 cursor-pointer text-indigo-600 border-gray-300 focus:ring-indigo-500"
                          aria-labelledby="privacy-setting-1-label"
                          aria-describedby="privacy-setting-1-description"
                          checked={selectAll}
                          onChange={selectAllStudent}
                        />
                      </label>
                    </th>
                    <th className="text-left  p-3 text-sm font-normal">
                      {" "}
                      Student{" "}
                    </th>
                    <th className="text-left p-3 text-sm font-normal">
                      {" "}
                      Grade{" "}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {sourceClassStudents?.map((student, index) => {
                    return (
                      <tr
                        key={student.userId}
                        className={`hover:bg-light-violet/20 transition ease-in-out duration-300 ${
                          index % 2 === 0 ? "bg-white" : "bg-light-violet/10"
                        }`}
                      >
                        <td className=" mt-5 p-3 text-center">
                          <input
                            type="checkbox"
                            key={student.userId}
                            checked={selectedImportStudents.includes(student.userId)}
                            onChange={() => {
                              selectStudent(student.userId);
                            }}
                          />
                        </td>
                        <td className="text-sm mt-5  p-3">
                          {student.studentName}
                        </td>
                        <td className="text-sm mt-5  p-3">
                          {student.gradeId}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>

              <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between border-t py-4 mt-2 pb-10">
                {sourceClassStudents?.length !== undefined &&
                    sourceClassStudents?.length !== 0 && (
                    <Pagination
                      totalRecords={sourceClassStudents?.length}
                      pageSize={pageSize}
                      pageNumber={pageNumber}
                      onPageChange={(pageNo) => {
                        setPageNumber(pageNo);
                      }}
                      reset={resetPageNumber}
                    />
                  )}
                {sourceClassStudents?.length !== undefined &&
                    sourceClassStudents?.length !== 0 && (
                  <PageSize
                    pageSize={pageSize}
                    onPageSizeChange={onPageSizeChange}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Action Buttons -->  */}
        <footer className="flex items-center justify-end px-4 py-4 sm:flex-row bg-gray-50 dark:bg-gray-800 mt-4">
          <div className="space-x-3 flex justify-end">
            <button
              className={classNames(
                "disabled:opacity-50 align-bottom inline-flex items-center justify-center leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 rounded-lg text-sm text-white border border-transparent bg-primary-green focus:shadow-outline-purple w-full sm:w-auto",
                isDisabled ? "cursor-not-allowed" : "cursor-pointer"
              )}
              type="button"
              onClick={() => {
                ImportStudentClassData();
              }}
            >
              {" "}
              Import
            </button>
            <button
              className="align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 rounded-lg text-sm text-gray-600 border-gray-300 border dark:text-gray-400 focus:outline-none active:bg-transparent hover:border-gray-500 focus:border-gray-500 active:text-gray-500 focus:shadow-outline-gray w-full sm:w-auto"
              type="button"
              onClick={() => {
                props.showPopUp(false);
              }}
            >
              Cancel
            </button>
          </div>
        </footer>
      </div>

      <ConfirmationDialog
        open={openConfirmation}
        confirmationTitle="Please confirm"
        confirmationMessage="Are you sure you want to import student data?"
        onCancelClick={confirmationCancelClose}
        onOkClick={confirmationOkClose}
        className="fixed z-50 inset-0 overflow-y-auto"
      ></ConfirmationDialog>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(ImportStudentClassDataPopup);

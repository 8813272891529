import { RadioGroup } from "@headlessui/react";
import { CheckCircleIcon } from "@heroicons/react/solid";
import { ContentState, convertToRaw, convertFromHTML } from "draft-js";
import { Fragment, useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { stateToHTML } from "draft-js-export-html";
import { toast } from "react-toastify";
import { updateAssignmentData } from "../../../../api/teacher/assignment";
import { uploadVideoMessage } from "../../../../api/teacher/videoMessage";
import { IAssignment } from "../../../../model/interface/assignment";
import InfoMessage from "../../../shared/infoMessage";
import Loader from "../../../shared/loader";
import Constant from "../../../../utils/constant/constant";
import MessagePopup from "../../../shared/messagePopup";
import { sanitizeNoteContent } from "../../../../utils/sanitize";
import { ReactMediaRecorder } from "react-media-recorder";
import VideoRecorder from "../../../shared/videoRecorder";
import { browserName } from "react-device-detect";

interface NotesProps {
  userId: number;
  assignment: IAssignment;
  updateAssignment: (
    updatedAsignment: IAssignment,
    moveToNextTab?: boolean
  ) => void;
}

function Note(props: NotesProps) {
  const { userId, assignment, updateAssignment } = props;
  const noMessage = 0;
  const textMessage = 1;
  const videoMessage = 2;

  const maxRecordingTimeLimit = 46000; // 46 seconds
  const [selectedFile, setSelectedFile] = useState(new File([], ""));
  const [isMessageApproved, setIsMessageApproved] = useState(false);
  const [loading, setLoading] = useState(false);
  const [messageType, setMessageType] = useState(0);
  const [messageContent, setMessageContent] = useState("");
  const [videoRecordMode, setVideoRecordMode] = useState<boolean>(true);
  const [textToSpeech, setTextToSpeech] = useState<boolean>(false);
  const [errorMessagePopup, setErrorMessagePopup] = useState<boolean>(false);
  const [videoUrl, setVideoUrl] = useState<string>();

  const toggleErrorMessagePopup = () => {
    setErrorMessagePopup(false);
  };

  const handleVideoStopEvent = (blob: Blob) => {
    const mimeTypeWithoutCodec = getBrowserMimeType().split(";")[0];
    console.log(mimeTypeWithoutCodec);
    var fileName =
      Math.floor(Math.random() * 9000000000000) + 1000000000 + (mimeTypeWithoutCodec === Constant.MimeTypes.MP4 ? ".mp4" : ".webm");
    var file = new File([blob], fileName, {
      type: mimeTypeWithoutCodec
    });
    setSelectedFile(file);
    setVideoUrl(URL.createObjectURL(file));
    setVideoRecordMode(false);
  };

  const handleRecordAgain = () => {
    assignment.videoNote = "";
    var file = new File([], "");
    setSelectedFile(file);
    setVideoUrl("");
    setVideoRecordMode(true);
  };

  const getTextLength = (text: string) => {
    const allText =
      convertToRaw(
        ContentState.createFromBlockArray(convertFromHTML(text))
      )?.blocks?.map((element) => element.text) || [];
    const totalTextLength = allText.join(" ").length;
    const totalBreakLines = (text.match(/<p><br><\/p>/g) || []).length;
    return totalTextLength + totalBreakLines;
  };

  const handleMessageTextChange = (text: string) => {
    setMessageContent(sanitizeNoteContent(text));
    assignment.videoNote = "";
    assignment.textNote = text;
    updateAssignment(assignment);
  };

  const handleUploadVideoMessage = async () => {
    const file = selectedFile;
    var formData = new FormData();
    formData.append("file", file);
    setLoading(true);
    uploadVideoMessage(formData)
      .then((r) => {
        const mediaUrl = r.data.videoUrl;
        const signedMediaUrl = r.data.signedUrl;
        if (mediaUrl !== "") {
          assignment.videoNote = mediaUrl;
          assignment.signedVideoNote = signedMediaUrl;
          assignment.textNote = "";
          setVideoUrl(signedMediaUrl);
          updateAssignment(assignment);
          updateNotes("", mediaUrl);
        }
        else {
          setVideoUrl("");
        }
        setLoading(false);
      })
      .catch((r) => {
        setLoading(false);
      });
  };

  const validate = () => {
    if (
      messageType === videoMessage &&
      (assignment.videoNote === "" || assignment.videoNote === null) &&
      selectedFile.name === ""
    ) {
      toast.error("Please record message");
      return false;
    } else if (messageType === videoMessage && !isMessageApproved) {
      toast.error("Please preview the video once and approve it");
      return false;
    } else if (
      messageType === textMessage &&
      messageContent.trim().length === 0
    ) {
      toast.error("Please enter text message");
      return false;
    } else if (messageType === textMessage && messageContent.length > 1500) {
      toast.error(
        "Please, remove text format/images/hyperlinks, the 1500-styled characters limit must not be exceeded"
      );
      return false;
    } else if (
      messageType === textMessage &&
      getTextLength(messageContent) > 500
    ) {
      toast.error(
        "Please enter text message less than or equal to 500 characters"
      );
      return false;
    }
    return true;
  };

  const handleSaveMessage = async () => {
    if (validate()) {
      if (messageType === textMessage) {
        updateNotes(messageContent, "");
      } else if (messageType === noMessage) {
        updateNotes("", "");
      } else if (messageType === videoMessage) {
        if (selectedFile.name !== "") {
          handleUploadVideoMessage();
        } else {
          updateNotes("", assignment.videoNote ? assignment.videoNote : "");
        }
      }
    }
  };

  function updateNotes(textNote: string, videoNote: string) {
    if (assignment.assignmentId > 0) {
      const updatedAssignment: IAssignment = {
        assignmentId: assignment.assignmentId,
        name: assignment.name,
        subjectId: assignment.subjectId,
        startDate: assignment.startDate,
        endDate: assignment.endDate,
        textNote: textNote,
        videoNote: videoNote,
        isSharedWithSchool: assignment.isSharedWithSchool,
        displayAnswerExplanation: assignment.displayAnswerExplanation,
        completeInOrder: assignment.completeInOrder,
        textToSpeech: textToSpeech,
        assignmentStatusId: assignment.assignmentStatusId
          ? assignment.assignmentStatusId
          : Constant.AssignmentStatusId.DRAFT,
        students: assignment.students,
        isGoogleAssignment: false,
        isCanvasAssignment: false,
        schoolYearId: assignment.schoolYearId,
        completed: assignment.completed
      };
      setLoading(true);

      updateAssignmentData(userId, updatedAssignment)
        .then((response) => {
          setLoading(false);
          updatedAssignment.activities = assignment.activities;
          updateAssignment(updatedAssignment, true);
          toast.success("Notes saved successfully.");
        })
        .catch(() => {
          setLoading(false);
          setErrorMessagePopup(true);
        });
    }
  }

  useEffect(() => {
    bindData();

    const supportedVideos = getSupportedMimeTypes("video", ["mp4", "webm"], ["vp8", "vp9", "opus"]);
    console.log(supportedVideos);
  }, [assignment]);

  const bindData = () => {
    if (assignment.textNote && assignment.textNote !== "") {
      setMessageContent(assignment.textNote);
      setMessageType(textMessage);
      setTextToSpeech(
        assignment.textToSpeech && assignment.textToSpeech === true
          ? true
          : false
      );
    } else if (assignment.videoNote && assignment.videoNote !== "") {
      setMessageType(videoMessage);
      setVideoRecordMode(false);
      setIsMessageApproved(true);
      setVideoUrl(assignment.signedVideoNote);
    } else {
      setMessageType(noMessage);
    }
  };

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const noteTypes = [
    {
      id: 0,
      title: "No Notes",
      description: "No note to be attached for the students.",
    },
    {
      id: 1,
      title: "Text Notes",
      description: "Text note attached for the students.",
    },
    {
      id: 2,
      title: "Video Notes",
      description: "Video note attached for the students.",
    },
  ];

  function handleOptionChange(e: any) {
    setSelectedNoteType(e);
    setMessageType(e.id);
  }

  function getBrowserMimeType(){
    if (getSupportedMimeTypes("video", ["webm"], ["vp9"]).length) {
      // Chrome requires equals sign.
      return browserName === "Chrome" ? `${Constant.MimeTypes.WEBM};codecs=vp9,opus` : `${Constant.MimeTypes.WEBM};codecs:vp9,opus`;
    }
    return `${Constant.MimeTypes.MP4};codecs:vp8,opus`;
  }

  // https://stackoverflow.com/questions/41739837/all-mime-types-supported-by-mediarecorder-in-firefox-and-chrome
  function getSupportedMimeTypes(media, types, codecs) {
    const isSupported = window["MediaRecorder"].isTypeSupported;
    const supported:string[] = [];
    types.forEach((type) => {
      const mimeType = `${media}/${type}`;
      codecs.forEach((codec) => [
        `${mimeType};codecs:${codec}`,
        `${mimeType};codecs:${codec.toUpperCase()}`,
      ].forEach(variation => {
        if (isSupported(variation))
          supported.push(variation);
      }));
      if (isSupported(mimeType))
        supported.push(mimeType);
    });
    return supported;
  }

  const [selectedNoteType, setSelectedNoteType] = useState(noteTypes[0]);  

  return (
    <Fragment>
      <div className="my-4 relative px-4 sm:px-6  lg:px-8">
        {loading && <Loader />}
        <InfoMessage message="Add a text or video message for your class to include additional instructions or comments. Students shall see your message on their assignment list screen." />
        {errorMessagePopup && (
          <MessagePopup
            message={Constant.UserErrorMessage.AssignmentCreateError}
            togglePopup={toggleErrorMessagePopup}
          />
        )}
        <div className="flex flex-col space-y-2 sm:space-y-0 sm:grid sm:grid-cols-12 sm:gap-6">
          <div className="col-span-3">
            <RadioGroup
              value={selectedNoteType}
              onChange={(e) => handleOptionChange(e)}
            >
              <RadioGroup.Label className="text-base font-medium text-gray-900">
                Notes
              </RadioGroup.Label>

              <div className="mt-4 space-y-4">
                {noteTypes.map((notes) => (
                  <RadioGroup.Option
                    key={notes.id}
                    value={notes}
                    className={({ checked, active }) =>
                      classNames(
                        active || messageType === notes.id
                          ? "border-primary-violet bg-primary-violet/10"
                          : "border-gray-300 bg-white hover:bg-primary-violet/10",
                        "relative border rounded-lg shadow-sm p-4 flex cursor-pointer focus:outline-none"
                      )
                    }
                  >
                    {({ checked, active }) => (
                      <>
                        <div className="flex-1 flex">
                          <div className="flex flex-col">
                            <RadioGroup.Label
                              as="span"
                              className="block text-sm font-medium text-gray-900"
                            >
                              {notes.title}
                            </RadioGroup.Label>
                            <RadioGroup.Description
                              as="span"
                              className="mt-1 flex items-center text-sm text-gray-500"
                            >
                              {notes.description}
                            </RadioGroup.Description>
                          </div>
                        </div>
                        <CheckCircleIcon
                          className={classNames(
                            active || messageType === notes.id
                              ? ""
                              : "invisible",
                            "h-5 w-5 text-primary-violet"
                          )}
                          aria-hidden="true"
                        />
                        <div
                          className={classNames(
                            active || messageType === notes.id
                              ? "border"
                              : "border-2",
                            checked
                              ? "border-primary-violet"
                              : "border-transparent",
                            "absolute -inset-px rounded-lg pointer-events-none"
                          )}
                          aria-hidden="true"
                        />
                      </>
                    )}
                  </RadioGroup.Option>
                ))}
              </div>
            </RadioGroup>
          </div>
          <div className="col-span-9 md:pt-9 flex flex-col min-h-full">
            <div className="flex justify-end mb-4">
              <button
                onClick={() => handleSaveMessage()}
                className="mb-auto lg:block bg-primary-violet border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-dark-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 "
              >
                Save and Continue
              </button>
            </div>
            <div className="bg-gray-100 flex-grow w-full rounded-lg p-5">
              <div className="flex space-y-2 lg:space-y-0 flex-col lg:flex-row md:justify-between">
                {messageType === textMessage && (
                  <div className="w-full lg:w-2/3 3xl:w-1/2 ">
                    <div className="mb-2">
                      <input
                        id="styled-checkbox-2"
                        type="checkbox"
                        className="checkbox cursor-pointer"
                        checked={textToSpeech}
                        onChange={() => setTextToSpeech(!textToSpeech)}
                        data-tip="Enabling this option allows you to select up to 10 standards to make up your 10 question activity.<br /> When unchecked, each standard shall be handled as a separate activity that will include 10 questions each. <br />Only one option can be selected for Practice question between Combined standards or single standards"
                      />
                      <span className="text-sm text-gray-500">
                        Text to speech
                      </span>
                    </div>
                    <Editor
                      wrapperClassName="texteditor-wrapper"
                      editorClassName="bg-white demo-editor h-48 overflow-hidden"
                      defaultContentState={convertToRaw(
                        ContentState.createFromBlockArray(
                          convertFromHTML(
                            assignment.textNote ? assignment.textNote : ""
                          )
                        )
                      )}
                      value={assignment.textNote}
                      onEditorStateChange={(e) => {
                        handleMessageTextChange(
                          stateToHTML(e.getCurrentContent())
                        );
                      }}
                      toolbar={{
                        options: [
                          "inline",
                          "blockType",
                          "fontSize",
                          "fontFamily",
                          "list",
                          "textAlign",
                          "colorPicker",
                          "link",
                          "emoji",
                          "image",
                          "remove",
                          "history",
                        ],
                      }}
                    />
                  </div>
                )}

                {messageType === videoMessage && (
                  <div className="w-full lg:w-2/3 xxxl:w-2/3 videonotes">
                    {videoRecordMode && (
                      <div>
                        <ReactMediaRecorder
                          stopStreamsOnStop={true}
                          video
                          mediaRecorderOptions={{
                            mimeType: getBrowserMimeType()
                          }}
                          onStop={(blobUrl, blob) => {
                            handleVideoStopEvent(blob);
                          }}
                          askPermissionOnMount={true}
                          render={({ startRecording, stopRecording, mediaBlobUrl, previewStream }) => (
                            <div>
                              {mediaBlobUrl && <video src={mediaBlobUrl} controls autoPlay loop />}
                              <VideoRecorder 
                                stream={previewStream} 
                                onStartRecording={startRecording} 
                                maxRecordingTimeLimit={maxRecordingTimeLimit}
                                onStopRecording={() => {
                                  stopRecording();
                                  setVideoRecordMode(false);                                
                                }} 
                              />
                            </div>
                          )}
                        />
                    </div>
                    )}               
                    {!videoRecordMode && videoUrl && (
                      <video
                        className="w-full"
                        controls
                        preload="auto">
                        <source
                          src={videoUrl}
                          type={Constant.MimeTypes.MP4}
                        >
                        </source>
                        <source
                          src={videoUrl}
                          type={Constant.MimeTypes.WEBM}>
                        </source>
                      </video>
                    )}

                    <p
                      className="text-sm text-yellow-700 bg-yellow-50 border border-yellow-300 p-4 block rounded-md mt-4">
                    <div className="text-sm text-gray-500 flex items-center space-x-1 mb-1">
                        <input
                          type="checkbox"
                          id="chkApprove"
                          onChange={(e) => {
                            setIsMessageApproved(e.target.checked);
                          }}
                          checked={isMessageApproved}
                        />
                        <label htmlFor="chkApprove"> Approved </label>
                      </div>
                      Please preview the video once if you haven't already, you
                      need to approve that the content is appropriate for your
                      students.
                    </p>
                  </div>
                )}
                <div>
                  {messageType === videoMessage && !videoRecordMode && (
                    <button
                      onClick={() => handleRecordAgain()}
                      type="submit"
                      className="mt-2 mb-auto lg:block bg-primary-violet border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-dark-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 "
                    >
                      Record Another Video
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Note;

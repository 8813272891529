import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { ISelectElement } from "../../model/interface/selectElement";
import { getAllFeedbackTypes, addFeedback } from "../../api/feedback.service";
import { IFeedback } from "../../model/interface/feedback";
import { InformationIcon } from "../../assets/icons/index";
import { connect } from "react-redux";
import { toast } from "react-toastify";
interface FeedbackProps {
  referenceId: number;
  referenceType: string;
  toggleFeedback: (popupAction: boolean) => void;
  subjectId?: number;
  standardIds?: string;
  standardId?: number;
}

function Feedback(props: FeedbackProps & any) {
  const { referenceId, referenceType, toggleFeedback, profile } = props;

  const [comments, setComments] = useState<string>("");
  const [feedbackType, setFeedbackType] = useState<number>(0);

  const [feedbackTypes, setFeedbackTypes] = useState<Array<ISelectElement>>([]);

  function getFeedbackTypes() {
    getAllFeedbackTypes().then((response) => {
      const domains = response.data.data.map((values: any) => {
        return {
          label: values?.name,
          value: values?.id,
        };
      });
      setFeedbackTypes(domains);
    });
  }

  function submitFeedback() {
    if (comments) {
      const feedback: IFeedback = {
        reference: referenceId,
        referenceType: referenceType,
        feedbackTypeId: feedbackType,
        comments: comments,
        submittedBy: props.userContext.userId,
      };

      if(props.subjectId > 0)
        feedback.subjectId = props.subjectId;

      if(props.standardId > 0)
        feedback.standardId = props.standardId;

      addFeedback(feedback).then(() => {
        toast.success("Feedback submitted successfully.");
      }).catch(() => {
        toast.error("An error occurred while attempting to submit your feedback.")
      });
      toggleFeedback(false);
    } else {
      toast.error("Comments should not be empty!.");
    }
  }

  useEffect(() => {
    getFeedbackTypes();
  }, []);

  return (
    <Fragment>
      <Transition.Root show={true} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          onClose={() => toggleFeedback(false)}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xl ">
                <div className="px-4 py-4 bg-gray-50 sm:px-4">
                  <div className="flex items-start justify-between space-x-3">
                    <div className="space-y-1">
                      <div className="text-lg font-medium text-gray-900">
                        Feedback
                      </div>
                    </div>
                    <div className="h-7 flex items-center">
                      <button
                        type="button"
                        className="text-gray-400 hover:text-gray-500"
                        onClick={() => toggleFeedback(false)}
                      >
                        <span className="sr-only">Close panel</span>
                        <XIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:text-left">
                    <div className="">
                      <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden transform transition-all sm:align-middle w-full sm:max-w-2xl xl:max-w-3xl sm:w-full">
                        <div className="relative mt-4 mx-4">
                          <div className="text-sm text-gray-700 font-light bg-blue-50 p-3 flex rounded-md mb-6">
                            <div className="flex-shrink-0 current-fill text-blue-400">
                              <InformationIcon />
                            </div>
                            <span className="pl-2">
                              Dear{" "}
                              <span className="font-semibold">{`${profile?.firstName} ${profile?.lastName}`}</span>
                              , we take pride in listening to our users. Please
                              suggest your feedback for this question.
                            </span>
                          </div>
                        </div>
                        <div className="mx-4">
                          <span>Subject :{props.subjectName}</span>
                        </div>
                        <div className="flex flex-col sm:flex-row mt-8 sm:space-x-3 mx-4">
                          <div className="w-80">
                            <span className="block text-sm text-gray-500 mb-1">
                              Main Issue
                            </span>
                            {feedbackTypes.map((feedbackType) => {
                              return (
                                <label className=" relative py-1 flex cursor-pointer focus:outline-none border-gray-400">
                                  <input
                                    type="radio"
                                    name="privacy-setting"
                                    onChange={() =>
                                      setFeedbackType(
                                        parseInt(feedbackType.value)
                                      )
                                    }
                                    className="h-4 w-4 mt-0.5 cursor-pointer text-primary-violet border-gray-300 focus:ring-primary-violet"
                                  />
                                  <div className="ml-3 flex flex-col">
                                    <span className="block text-sm text-primary-violet capitalize">
                                      {feedbackType.label}
                                    </span>
                                  </div>
                                </label>
                              );
                            })}
                          </div>
                          <div className="w-full mt-5 sm:mt-0 sm:pl-5">
                            <span className="block text-sm text-gray-500 mb-1">
                              Comments
                            </span>
                            <textarea
                              rows={12}
                              id="about"
                              name="about"
                              value={comments}
                              onChange={(e) => setComments(e.target.value)}
                              className="shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-lg"
                            ></textarea>
                          </div>
                        </div>

                        <footer className="flex flex-col items-center justify-end px-6 py-4 sm:flex-row bg-gray-50 dark:bg-gray-800">
                          <div className="flex-shrink-0">
                            <div className="space-x-3 flex justify-end">
                              <button
                                className="align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 rounded-lg text-sm text-gray-600 border-gray-300 border dark:text-gray-400 focus:outline-none active:bg-transparent hover:border-gray-500 focus:border-gray-500 active:text-gray-500 focus:shadow-outline-gray w-full sm:w-auto"
                                type="button"
                                onClick={() => toggleFeedback(false)}
                              >
                                Cancel
                              </button>

                              <button
                                className="align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 rounded-lg text-sm text-white border border-transparent bg-primary-green focus:shadow-outline-purple w-full sm:w-auto"
                                type="button"
                                onClick={() => submitFeedback()}
                              >
                                {" "}
                                Save
                              </button>
                            </div>
                          </div>
                        </footer>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </Fragment>
  );
}

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(Feedback);

import { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Select } from "@windmill/react-ui";
import {
  DEFAULT_PAGE_NUMBER,
  DEFAULT_PAGE_SIZE_10,
} from "../../../../../utils/pagingConstant";
import Pagination from "../../../../shared/pagination/pagination";
import PageSize from "../../../../shared/pagination/pageSize";

import "../../../../../css/style.css";
import RouteConstant from "../../../../../utils/constant/routeConstant";
import Breadcrumb from "../../shared/breadcrumb";
import { IBreadcrumb } from "../../../../../model/common/breadcrumb";
import { useTranslation } from "react-i18next";
import { CertificateIcon } from "../../../../../assets/icons";
import { getClassesBySchoolAccountId } from "../../../../../api/manageUser/class";
import { getAllGrades } from "../../../../../api/gradeApi";
import constant from "../../../../../utils/constant/constant";
import { ISchool } from "../../../../../model/interface/school";
import { getSchoolByDistrictId } from "../../../../../api/teacher/school";
import { ISelectElement } from "../../../../../model/interface/selectElement";
import Asterisk from "../../../../shared/asterisk";
import ClassDropDown from "../../shared/classDropDown";
import { getAllClassesBySubject } from "../../../../../api/teacher/teacher";
import {
  downloadCertificate,
  getAllAlienRankingCards,
} from "../../../../../api/student/studentApi";
import {
  getGalaxyStarReport,
  getGalaxyStarReportExport,
} from "../../../../../api/report/galaxystarReport";
import ReactTooltip from "react-tooltip";
import moment from "moment";
import Loader from "../../../../shared/loader";
import Subject from "../../../../shared/dropdowns/subject";
import { AxiosResponse } from "axios";
import { UserContextState } from "../../../../../redux/actions/userContextAction";
import Profile from "../../../../../model/users/profile";

interface IClasses {
  classId: number;
  displayName: string;
}

interface IGalaxyStarReportProps {
  userContext: UserContextState;
  profile: Profile;
}

function GalaxyStarReport(props: IGalaxyStarReportProps) {
  const { t } = useTranslation();
  const [schools, setSchools] = useState<ISchool[]>();
  const [pageNumber, setPageNumber] = useState(DEFAULT_PAGE_NUMBER);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE_10);
  const [resetPageNumber, setResetPageNumber] = useState<boolean>(false);
  const [selectedClass, setSelectedClass] = useState<number>(-1);
  const [selectedSubject, setSelectedSubject] = useState<number>(0);
  const [selectedGrade, setSelectedGrade] = useState<number>(0);
  const [selectedSchool, setSelectedSchool] = useState<number>(0);
  const [selectedAlienRank, setSelectedAlienRank] = useState<number>(0);
  const [noRecordFoundMsg, setNoRecordFoundMsg] = useState<string>("");
  const [showReport, setShowReport] = useState<boolean>(false);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [reportData, setReportData] = useState<any>([]);
  const [classes, setClasses] = useState<IClasses[]>();
  const [grades, setGrades] = useState<Array<any>>();
  const [alienRankings, setAlienRankings] = useState<Array<any>>();
  const [selectedClasses, setSelectedClasses] = useState<Array<ISelectElement>>(
    [{ label: "All Classes", value: "0" }]
  );
  const [isInvalidDate, setIsInvalidDate] = useState<boolean>(false);
  const [showLoader, setLoader] = useState<boolean>(false);
  const [selectedSchoolAccountId, setSelectedSchoolAccountId] =
    useState<number>(0);
  const getSchools = async (subjectId: number = 0) => {
    const resp = await getSchoolByDistrictId(
      props.userContext?.districtId ?? 0,
      subjectId
    );
    if (resp?.data?.length) {
      let schoolList: ISchool[] = [];
      resp?.data.forEach((school) => {
        if (!schoolList.find((sl) => sl.schoolId === school.schoolId)) {
          schoolList.push(school);
        }
      });
      setSchools(schoolList);
      if (props.userContext.roleId !== constant.UserRoleId.District) {
        setSelectedSchool(props.userContext.schoolId);
        setSelectedSchoolAccountId(
          schoolList?.filter((s) => s.schoolId == props.userContext.schoolId)[0]
            ?.schoolAccountId ?? 0
        );
      }
    }
  };
  const [selectedSubjectName, setSelectedSubjectName] = useState<string>();
  const [subjectName, setSubjectName] = useState<string>();
  const [selectedSubjectOption, setSelectedSubjectOption] = useState<
    Array<ISelectElement>
  >([{ label: "Select Subject", value: "0" }]);
  const getClasses = async () => {
    const resp = await getClassesBySchoolAccountId(
      props.userContext?.schoolAccountId || 0,
      props.profile?.currentSchoolYear.schoolYearId ?? 0
    );
    if (resp?.data?.length) setClasses(resp.data);
  };

  const getSubjectClasses = async (
    subjectId,
    classId?: number,
    schoolId?: number
  ) => {
    let intSubjectId: number = 0;
    if (subjectId) {
      intSubjectId = parseInt(subjectId)
      setSelectedSubject(intSubjectId);
    } else {
      setSelectedSubject(0);
      setSelectedClass(classId ?? 0);
      setSelectedClasses([{ label: "All Classes", value: "0" }]);
    }

    if (props.userContext.roleId === constant.UserRoleId.District) {
      setSelectedSchool(0);
      if (intSubjectId > 0) {
        await getSchools(intSubjectId);
      }
    }

    if (intSubjectId === 0 && !classId) {
      getClasses();
    } else {
      setClasses([]);
      const resp = await getAllClassesBySubject(
        props.userContext.userId,
        props.userContext.roleId,
        intSubjectId,
        props.profile.currentSchoolYear.schoolYearId,
        schoolId && schoolId > 0 ? schoolId : props.userContext.schoolId
      );
      if (resp?.data?.data?.length) setClasses(resp.data.data);
      if (classId) {
        setSelectedClass(classId);
        let selectedClass = resp.data.data.filter(
          (x) => x.classId === Number(classId)
        );
        if (selectedClass.length > 0)
          setSelectedClasses([
            { label: selectedClass[0].name, value: selectedClass[0].classId },
          ]);
      } else {
        setSelectedClass(0);
      }
    }
  };

  const getGrades = async () => {
    const resp = await getAllGrades();
    if (resp?.data?.length) {
      let grades = resp.data.filter((x) => x.gradeId <= 6);
      setGrades(grades);
    }
  };

  const getAlienRankingCards = async () => {
    const resp = await getAllAlienRankingCards();
    if (resp?.data?.length) setAlienRankings(resp.data);
  };

  const downloadStudentCertificate = (
    firstName,
    lastName,
    level,
    isPrestigeMode
  ) => {
    downloadCertificate(firstName, lastName, level, isPrestigeMode).then(
      (response) => {
        const file: any = response.data;
        const fileURL = URL.createObjectURL(file);
        const link = document.createElement("a");
        link.href = fileURL;
        link.setAttribute("download", "certificate.pdf");
        document.body.appendChild(link);
        link.click();

        // Clean up and remove the link
        link.parentNode!.removeChild(link);
      }
    );
  };

  useEffect(() => {
    getAlienRankingCards();
    getGrades();
    getSchools();
  }, []);

  useEffect(() => {
    if (showReport) getReport();
  }, [pageSize, pageNumber, resetPageNumber]);

  const getReport = () => {
    setLoader(true);
    setSubjectName(selectedSubjectName);
    getGalaxyStarReport(
      Number(selectedSchool),
      Number(selectedSubject),
      pageNumber,
      pageSize,
      Number(selectedAlienRank) === 0 ? undefined : Number(selectedAlienRank),
      Number(selectedClass) <= 0 ? undefined : Number(selectedClass),
      Number(selectedGrade) <= 0 ? undefined : Number(selectedGrade)
    )
      .then((res) => {
        if (!res.data.data.data || res.data.data.totalRecords === 0) {
          setNoRecordFoundMsg("No record found");
          setShowReport(false);
        } else {
          setReportData(res.data.data.data);
          setShowReport(true);
          setTotalRecords(res.data.data.totalRecords);
        }
        setLoader(false);
      })
      .catch(() => {
        setLoader(false);
      });
  };

  const onRunReport = () => {
    resetReport();
    getReport();
  };

  const resetReport = () => {
    setPageNumber(DEFAULT_PAGE_NUMBER);
    setNoRecordFoundMsg("");
    setShowReport(false);
    setReportData([]);
    setTotalRecords(0);
  };

  const exportReport = () => {
    setLoader(true);
    setSubjectName(selectedSubjectName);
    getGalaxyStarReportExport(
      Number(selectedSchool),
      Number(selectedSubject),
      pageNumber,
      pageSize,
      Number(selectedAlienRank) === 0 ? undefined : Number(selectedAlienRank),
      Number(selectedClass) <= 0 ? undefined : Number(selectedClass),
      Number(selectedGrade) === 0 ? undefined : Number(selectedGrade)
    )
      .then((response) => {
        startFileDownload(response, "Galaxy Star Report.xlsx");
        setLoader(false);
      })
      .catch(() => {
        setLoader(false);
      });
  };

  const startFileDownload = (
    response: AxiosResponse<any>,
    fileName: string
  ) => {
    const file = response.data;
    const fileURL = URL.createObjectURL(file);
    let link = document.createElement("a");
    link.href = fileURL;
    link.download = fileName;
    link.setAttribute("target", "_blank");
    document.body.appendChild(link);
    link.click();
    setLoader(false);
  };

  const onReset = () => {
    setReportData([]);
    setNoRecordFoundMsg("");
    setShowReport(false);
    setSelectedSubject(0);
    if (props.userContext.roleId === constant.UserRoleId.District) {
      setSelectedSchool(0);
      setSelectedSchoolAccountId(0);
    }
    setSelectedGrade(0);
    setIsInvalidDate(false);
    setSelectedAlienRank(0);
    setSelectedClasses([{ label: "Select Class", value: "-1" }]);
    setSelectedSubjectOption([{ label: "Select Subject", value: "0" }]);
  };
  const breadcrumbItems = () => {
    const items: Array<IBreadcrumb> = [
      { name: t("Breadcrumb.Home"), url: RouteConstant.ROUTE_DASHBOARD },
      {
        name: t("Breadcrumb.Reports"),
        url: RouteConstant.REPORTS,
      },
      { name: t("Breadcrumb.GalaxyStarsReport"), url: "" },
    ];

    return items;
  };
  const onPageChange = (pageNo) => {
    setPageNumber(pageNo);
  };

  const onPageSizeChange = (pageSize) => {
    setPageNumber(DEFAULT_PAGE_NUMBER);
    setPageSize(pageSize);
    setResetPageNumber(true);
  };

  const handleClassChange: any = (e: any) => {
    resetReport();
    setSelectedClasses(e);
    setSelectedClass(Number(e[e.length - 1].value));
  };

  const handleGradeChange: any = (e: any) => {
    resetReport();
    setSelectedGrade(parseInt(e.target.value));
  };

  function enableRunReport() {
    return !(selectedSubject === 0 ||
        selectedSchool === 0 ||
        isInvalidDate);
  }

  return (
    <div className="min-w-0 w-full">
      <div className="lg:flex lg:items-center px-4 py-3 lg:px-8 border-b">
        <Breadcrumb items={breadcrumbItems()} />
        <div className="lg:w-[55%] text-lg font-medium flex gap-2 h-auto sm:h-10 items-center">
          <h1 className="text-primary-violet font-medium flex justify-start mb-0 pb-0">
            Galaxy Star Report
          </h1>
        </div>
      </div>
      <div className="px-2 sm:px-6  lg:px-8 py-1 relative my-4">
        <div className=" bg-gray-100 w-full rounded-lg p-5">
          <div>
            <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-4 mb-4 mt-4">
              <div className="flex flex-col">
                <Subject
                  setSelectedSubject={(item) => {
                    const subject = item[0];
                    setSelectedSubjectOption(subject);
                    getSubjectClasses(subject.value, undefined, selectedSchool);
                    setSelectedSubjectName(subject.label);
                  }}
                  selectedItems={selectedSubjectOption}
                  isMulti={false}
                  isRequired={true}
                  themeColor={"#008fbb"}
                ></Subject>
              </div>
              {props.userContext.roleId === constant.UserRoleId.District && (
                <div className="flex flex-col">
                  <label className="block mb-1 text-sm text-gray-500">
                    School <Asterisk />
                  </label>

                  <Select
                    value={selectedSchool}
                    onChange={(e) => {
                      getSubjectClasses(
                        selectedSubject,
                        undefined,
                        Number(e.target.value)
                      );
                      setSelectedSchool(parseInt(e.target.value));
                      setSelectedSchoolAccountId(
                        schools?.filter(
                          (s) => s.schoolId === parseInt(e.target.value)
                        )[0]?.schoolAccountId ?? 0
                      );
                    }}
                    className={
                      "py-2.5 px-4 block text-sm border border-gray-400 border-opacity-50 focus:ring-primary-violet focus:border-primary-violet form-select leading-5 p-1 rounded-lg " +
                      (selectedSubject === 0
                        ? "pointer-events-none text-gray-400 bg-gray"
                        : "")
                    }
                  >
                    <option value={0}>Select School</option>
                    {schools?.map((school: ISchool, index) => {
                      return (
                        <Fragment key={index}>
                          <option value={school.schoolId}>{school.name}</option>
                        </Fragment>
                      );
                    })}
                  </Select>
                </div>
              )}

              <div className="flex flex-col">
                <label className="block mb-1 text-sm text-gray-500">
                  Class{" "}
                  {props.userContext.roleId !== constant.UserRoleId.District &&
                    props.userContext.roleId !==
                      constant.UserRoleId.SchoolAdmin && <Asterisk />}
                </label>
                <ClassDropDown
                  schoolYearId={props.profile.currentSchoolYear.schoolYearId}
                  isMultiSelect={false}
                  onChange={handleClassChange}
                  value={selectedClasses}
                  data={classes}
                  isDisabled={
                    selectedSubject === 0 ||
                    (props.userContext.roleId ===
                      constant.UserRoleId.District &&
                      selectedSchool === 0)
                      ? true
                      : false
                  }
                  showArchived={true}
                  showHidden={true}
                  subjectId={selectedSubject}
                  selectedSchoolAccountId={selectedSchoolAccountId}
                ></ClassDropDown>
              </div>

              <div className="flex flex-col">
                <label className="block mb-1 text-sm text-gray-500">
                  Grade
                </label>

                <Select
                  value={selectedGrade}
                  onChange={handleGradeChange}
                  className="py-2 px-4 block text-sm border border-gray-300 focus:ring-primary-violet focus:border-primary-violet form-select leading-5 p-1 rounded"
                >
                  <option value={0}>All Grades</option>
                  {grades?.map((item: any, index) => {
                    return (
                      <Fragment key={index}>
                        <option value={item.gradeId}>{item?.name}</option>
                      </Fragment>
                    );
                  })}
                </Select>
              </div>

              <div className="flex flex-col">
                <label className="block mb-1 text-sm text-gray-500">
                  Alien Rankings
                </label>

                <Select
                  value={selectedAlienRank}
                  onChange={(e) =>
                    setSelectedAlienRank(parseInt(e.target.value))
                  }
                  className="py-2 px-4 block text-sm border border-gray-300 focus:ring-primary-violet focus:border-primary-violet form-select leading-5 p-1 rounded"
                >
                  <option value={0}>All</option>
                  {alienRankings?.map((item: any, index) => {
                    return (
                      <Fragment key={index}>
                        <option value={item.alienRankingCardId}>
                          {item?.name +
                            (item.isPrestigeMode ? " (Prestige)" : "")}
                        </option>
                      </Fragment>
                    );
                  })}
                </Select>
              </div>
            </div>

            <div className="flex gap-4 justify-end">
              <button
                onClick={onReset}
                className="bg-transparent border my-auto border-primary-violet shadow-sm py-2 px-4 flex justify-center text-sm font-medium text-primary-violet hover:text-white hover:bg-light-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3 transition ease-in-out duration-500"
              >
                Reset
              </button>

              <p
                className="cursor-pointer"
                data-tip
                data-for="incompleteSelection"
              >
                {!enableRunReport() && (
                  <ReactTooltip
                    id="incompleteSelection"
                    class="bg-secondary-teal/10 z-50 "
                    effect="solid"
                  >
                    <span>Please complete all required fields</span>
                  </ReactTooltip>
                )}

                <div className="cursor-not-allowed">
                  <button
                    onClick={onRunReport}
                    className={
                      "bg-primary-violet border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 " +
                      (!enableRunReport()
                        ? "bg-opacity-20 text-primary-violet text-opacity-40 pointer-events-none"
                        : "hover:bg-dark-violet hover:shadow-lg")
                    }
                  >
                    Run Report
                  </button>
                </div>
              </p>

              <div className="cursor-not-allowed">
                <button
                  onClick={exportReport}
                  className={
                    "bg-primary-violet border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-dark-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3 " +
                    (enableRunReport() && reportData?.length > 0
                      ? ""
                      : "bg-opacity-20 text-primary-violet text-opacity-40 pointer-events-none")
                  }
                >
                  Export Data
                </button>
              </div>
            </div>

            <div className="border-b border-gray-400 py-2 mb-2"></div>
            {showLoader && <Loader></Loader>}
            {noRecordFoundMsg}
            {showReport && (
              <div className="bg-white py-5 px-5 pt-2 mt-4">
                <div className="mb-4 pb-4 border-b-2 overflow-x-auto xl:overflow-x-visible">
                  <table className="table w-full ">
                    <thead>
                      <tr className="border border-white ">
                        <th className="text-left p-3 text-sm font-normal bg-white"></th>
                        <th
                          colSpan={2}
                          className="p-3 text-sm font-normal col-span-2 bg-primary-violet text-white text-center font-bold"
                        >
                          {subjectName} Achievements
                        </th>
                        <th
                          colSpan={4}
                          className="text-white  p-3 text-sm font-normal bg-gray-500 text-center  font-bold"
                        >
                          Achievements Across All Subjects
                        </th>
                      </tr>
                      <tr className="bg-primary-violet  text-white ">
                        <td className="text-left p-3 text-sm font-normal border-r border-white">
                          Name
                        </td>
                        <td className="text-center p-3 text-sm font-normal">
                          {subjectName} Stars Earned
                        </td>
                        <td className="text-center p-3 text-sm font-normal">
                          {subjectName} Possible Stars
                        </td>
                        <td className="text-center p-3 text-sm font-normal bg-gray-500">
                          Total Stars Earned
                        </td>
                        <td className="text-center p-3 text-sm font-normal bg-gray-500">
                          Total Possible Stars
                        </td>
                        <td className="text-center p-3 text-sm font-normal bg-gray-500">
                          Current Alien Ranking
                        </td>
                        <td className="text-center p-3 text-sm font-normal bg-gray-500">
                          Date Earned
                        </td>
                        <td className="text-sm p-3 border-l border-gray-200 bg-gray-500">
                          {" "}
                          Certificate
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      {reportData &&
                        reportData.length > 0 &&
                        reportData?.map((item, index) => {
                          return (
                            <Fragment key={index}>
                              <tr
                                className={`hover:bg-primary-violet/20 transition ease-in-out duration-300 ${
                                  index % 2 === 0
                                    ? "bg-white"
                                    : "bg-primary-violet/10"
                                }`}
                              >
                                <td className="text-sm p-3 ">
                                  {item.lastName + ", " + item.firstName}
                                </td>
                                <td
                                  className={`text-sm p-3  hover:bg-primary-violet/20 transition ease-in-out duration-300 text-center border-l border-gray-200`}
                                >
                                  {item.earnedStarsSubjects}
                                </td>
                                <td
                                  className={`text-sm p-3  hover:bg-primary-violet/20 transition ease-in-out duration-300 text-center border-r border-gray-200`}
                                >
                                  {item.totalStarsSubjects}
                                </td>
                                <td
                                  className={`text-sm p-3  hover:bg-primary-violet/20 transition ease-in-out duration-300 text-center`}
                                >
                                  {item.earnedStars}
                                </td>
                                <td
                                  className={`text-sm p-3  hover:bg-primary-violet/20 transition ease-in-out duration-300 text-center`}
                                >
                                  {item.totalStars}
                                </td>
                                <td
                                  className={`text-sm p-3  hover:bg-primary-violet/20 transition ease-in-out duration-300 text-center`}
                                >
                                  {item.alienRankingName}
                                </td>
                                <td
                                  className={`text-sm p-3  hover:bg-primary-violet/20 transition ease-in-out duration-300 text-center`}
                                >
                                  {item.dateCompleted
                                    ? moment(item.dateCompleted).format(
                                        "MM/DD/YYYY hh:mm:ss A"
                                      )
                                    : ""}
                                </td>

                                <td className="text-sm p-3  border-l border-gray-200 ">
                                  <div
                                    className="cursor-pointer"
                                    onClick={() => {
                                      downloadStudentCertificate(
                                        item.firstName,
                                        item.lastName,
                                        item.alienRankingLevel,
                                        item.alienRankingIsPrestigeMode
                                      );
                                    }}
                                  >
                                    <CertificateIcon className="h-10 w-10 mx-auto" />
                                  </div>
                                </td>
                              </tr>
                            </Fragment>
                          );
                        })}

                      {reportData?.length === 0 && (
                        <tr className="bg-white">
                          <td colSpan={6} className="text-sm p-3">
                            No records found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <div
                  className={`${pageNumber} hidden sm:flex-1 sm:flex sm:items-center sm:justify-between`}
                >
                  {reportData?.length !== 0 && (
                    <Pagination
                      totalRecords={totalRecords}
                      pageSize={pageSize}
                      pageNumber={pageNumber}
                      onPageChange={onPageChange}
                      reset={resetPageNumber}
                      bgColor="primary-violet"
                    />
                  )}
                  {reportData?.length !== 0 && (
                    <PageSize
                      pageSize={pageSize}
                      onPageSizeChange={onPageSizeChange}
                      bgColor="primary-violet"
                    />
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default connect((state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
})(GalaxyStarReport);

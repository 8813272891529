export const initActivityRequest = (activityRef: string) => {
  return {
    mode: "activity_edit",
    reference: activityRef,
    config: {
      activity_edit: {
        mode: {
          default: "preview",
          show: false,
        },
        reference: activityRef,
        activity_preview: {
          reference: {
            show: false,
          },
        },
        back: false,
        save: false,
      },
    },
    user: {
      id: "demoTest",
      firstname: "",
      lastname: "",
      email: "",
    },
  };
};

import { Fragment, useEffect, useState } from "react";
import Chart from "react-google-charts";
import { IQuestionSearch } from "../../../../model/interface/questionSearch";
import InfoMessage from "../../../shared/infoMessage";
import { IAssessmentQuestion } from "../../../../model/interface/assessmentQuestion";
import { getSystemGeneratedQuestions } from "../../../../api/teacher/assessment";
import { getAllDomainsBySubject } from "../../../../api/teacher/assignment";
import { ISelectElement } from "../../../../model/interface/selectElement";
import { getRandomColor } from "../../../../utils/helper";
import Loader from "../../../shared/loader";
import Constant from "../../../../utils/constant/constant";
import ConfirmationDialog from "../../../shared/confirmationDialog";
interface SystemGeneratedQuestionsProps {
  subjectId: number;
  subjectName: string | undefined;
  testSizeTypeId: number | undefined;
  subjectDetail: any;
  updateQuestions: (
    testSizeTypeId: number | undefined,
    standards: Array<IAssessmentQuestion>
  ) => void;
  allQuestions: Array<IAssessmentQuestion>;
  allowEdit: boolean;
  resetQuestions: boolean;
  onResetComplete: () => void;
}

function SystemGeneratedQuestions(props: SystemGeneratedQuestionsProps) {
  const {
    subjectId,
    subjectName,
    testSizeTypeId,
    subjectDetail,
    updateQuestions,
    allQuestions,
    allowEdit,
    resetQuestions,
    onResetComplete
  } = props;

  type TestSizeType = "None" | "Small" | "Medium" | "Large";

  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [testSizeType, setTestSizeType] = useState<TestSizeType>();
  const [domainPercentages, setDomainPercentages] = useState<Array<any>>([]);
  const [randomColors, setRandomColors] = useState<Array<string>>([]);
  const [showConfirmation, setshowConfirmation] = useState<boolean>(false);
  const [confirmTestSizeType, setConfirmTestSizeType] =
    useState<TestSizeType>();

  const options = {
    title: "",
    colors: randomColors,
    legend: {
      position: "right",
      alignment: "center",
      textStyle: {
        fontSize: 12,
      },
    },
    tooltip: { text: "none" },
  };

  function getDomains() {
    setShowLoading(true);
    getAllDomainsBySubject(subjectId).then((res) => {
      setShowLoading(false);
      let domainsData: Array<ISelectElement> = [];
      let chartData: any = [["Domains", "Domains Percentage"]];
      let colors: Array<string> = [];
      res.data.data.map((values: any) => {
        domainsData.push({ label: values?.name, value: values?.domainId });
        chartData.push([
          `${values?.name}   ${values?.testPercentage}%`,
          values?.testPercentage,
        ]);
        colors.push(getRandomColor());
        return null;
      });
      setDomainPercentages(chartData);
      setRandomColors(colors);
    });
  }

  function getTestSize(resultType: TestSizeType | undefined) {
    let result: number = 0;
    const elementaryGrades: Array<string> = ["K", "1", "2", "3", "4", "5"];
    const subjectGrades: Array<string> = subjectDetail?.grades.map((grade) => {
      return grade.name;
    });

    switch (resultType) {
      case "Small":
        result = elementaryGrades.some((r) => subjectGrades.includes(r))
          ? 10
          : 20;
        break;
      case "Medium":
        result = elementaryGrades.some((r) => subjectGrades.includes(r))
          ? 20
          : 40;
        break;
      case "Large":
        result = elementaryGrades.some((r) => subjectGrades.includes(r))
          ? 30
          : 60;
        break;
      default:
        result = 0;
        break;
    }

    return result;
  }

  function setTestSizeTypeValue(value: number | undefined) {
    let result: TestSizeType = "None";
    switch (value) {
      case 1:
        result = "Small";
        break;
      case 2:
        result = "Medium";
        break;
      case 3:
        result = "Large";
        break;
    }
    setTestSizeType(result);
  }

  function getTestSizeType(resultType: TestSizeType) {
    let result: number | undefined;
    switch (resultType) {
      case "None":
        result = undefined;
        break;
      case "Small":
        result = 1;
        break;
      case "Medium":
        result = 2;
        break;
      case "Large":
        result = 3;
        break;
    }

    return result;
  }

  function getTestSizeTypeValue(value: number | undefined) {
    let result: TestSizeType = "None";
    switch (value) {
      case 1:
        result = "Small";
        break;
      case 2:
        result = "Medium";
        break;
      case 3:
        result = "Large";
        break;
    }
    return result;
  }

  function bindSystemGeneratedQuestions(
    testSize: number,
    testTypeId: number | undefined
  ) {
    const existingSelectedQuestions: Array<IAssessmentQuestion> =
      allQuestions.filter(
        (r) =>
          r.assessmentQuestionTypeId ===
            Constant.AssessmentQuestionType.NonPassageQuestions ||
          r.assessmentQuestionTypeId ===
            Constant.AssessmentQuestionType.PassageQuestions
          && r.isTeacherQuestion !== true
      );
    const questionSearch: IQuestionSearch = {
      subjectId: subjectId,
      testSize: testSize,
      excludedQuestions: existingSelectedQuestions.map((s) => s.questionId),
    };

    let questions: Array<IAssessmentQuestion> = [];

    setShowLoading(true);
    getSystemGeneratedQuestions(questionSearch).then((response) => {
      setShowLoading(false);
      questions = response.data.data;
      questions.map(
        (r) =>
          (r.assessmentQuestionTypeId =
            Constant.AssessmentQuestionType.SystemGenerated)
      );
      updateQuestions(testTypeId, questions);
    });
  }

  function handleTestTypeChange(testSize: TestSizeType) {
    if (testSize === testSizeType) {
      testSize = "None";
    }

    if (testSize !== "None" && testSizeType !== "None") {
      setConfirmTestSizeType(testSize);
      setshowConfirmation(true);
      return false;
    }
    setTestSizeType(testSize);
    bindSystemGeneratedQuestions(
      getTestSize(testSize),
      getTestSizeType(testSize)
    );
  }

  function handleOkConfirmation() {
    setshowConfirmation(false);
    setTestSizeType(confirmTestSizeType);
    bindSystemGeneratedQuestions(
      getTestSize(confirmTestSizeType),
      getTestSizeType(confirmTestSizeType ?? "None")
    );
  }

  function handleCancelConfirmation() {
    setshowConfirmation(false);
  }

  useEffect(() => {
    if (allowEdit || testSizeTypeId) {
      if (subjectId !== 0) {
        getDomains();
      }
      setTestSizeTypeValue(testSizeTypeId);
      const type = getTestSizeTypeValue(testSizeTypeId);
      if (
        type !== "None" &&
        !allQuestions.some(
          (r) =>
            r.assessmentQuestionTypeId ===
            Constant.AssessmentQuestionType.SystemGenerated
        )
      ) {
        bindSystemGeneratedQuestions(getTestSize(type), getTestSizeType(type));
      }
    }
  }, [subjectId]);

  useEffect(() => {
    if (resetQuestions) {
      setTestSizeType("None");
      setDomainPercentages([]);
      onResetComplete(); // Notify the parent that reset is complete
    }
  }, [resetQuestions, onResetComplete]);

  return (
    <Fragment>
      <ConfirmationDialog
        open={showConfirmation}
        confirmationTitle={"Please confirm"}
        confirmationMessage={`The questions assigned from the ${testSizeType} selection shall be first removed. Anything added from ‘I will select the questions’ will remain, do you want to continue?`}
        onCancelClick={handleCancelConfirmation}
        onOkClick={handleOkConfirmation}
      ></ConfirmationDialog>
      {showLoading && <Loader></Loader>}
      {subjectId > 0 && (
        <div
          className={`col-span-10 lg:col-span-9 xl:col-span-10 ${
            allowEdit ? "" : "pointer-events-none opacity-50"
          }`}
        >
          <InfoMessage
            className="mb-2"
            message="Questions shall be chosen for ALL domains, according to the percentages in the pie chart defined by state testing standards."
          />
          <div className="bg-gray-100 w-full rounded-lg p-5">
            <div>
              <h3 className="text-base mb-2 text-gray-700">Assessment Length</h3>
              <div className="w-full flex flex-wrap -mx-4 sm:-mx-4 md:-mx-3 lg:-mx-4 xl:-mx-4">
                <div className="my-4 pl-4  w-full sm:w-1/2 sm:my-4 sm:px-4 md:my-3 md:px-3 lg:my-4 lg:px-4 xl:my-4 xl:px-4 ">
                  <div
                    onClick={() => handleTestTypeChange("Small")}
                    className={`${
                      testSizeType === "Small"
                        ? "bg-secondary-teal/20 border-secondary-teal z-10"
                        : "border-gray-200"
                    } bg-white relative border p-4 flex flex-col cursor-pointer md:pl-4 md:pr-6 focus:outline-none`}
                  >
                    <div>
                      <div className="flex items-center">
                        <input
                          id="styled-checkbox-2"
                          type="checkbox"
                          name="testSize"
                          className="checkbox focus:ring-secondary-teal h-4 w-4 text-secondary-teal border-gray-300"
                          checked={testSizeType === "Small" ? true : false}
                        />
                        <label className="checkbox-label text-sm text-gray-500">
                          Small
                        </label>
                      </div>
                      <span className="mt-2 block text-sm text-gray-900">
                        {`${getTestSize("Small")} Questions`}
                      </span>
                    </div>
                  </div>
                  <div
                    onClick={() => handleTestTypeChange("Medium")}
                    className={`${
                      testSizeType === "Medium"
                        ? "bg-secondary-teal/20 border-secondary-teal z-10"
                        : "border-gray-200"
                    } bg-white relative border p-4 flex flex-col cursor-pointer md:pl-4 md:pr-6 focus:outline-none`}
                  >
                    <div>
                      <div className="flex items-center">
                        <input
                          id="styled-checkbox-2"
                          type="checkbox"
                          name="testSize"
                          className="checkbox focus:ring-secondary-teal h-4 w-4 text-secondary-teal border-gray-300"
                          checked={testSizeType === "Medium" ? true : false}
                        />
                        <label className="checkbox-label text-sm text-gray-500">
                          Medium
                        </label>
                      </div>
                      <span className="mt-2 block text-sm text-gray-900">
                        {`${getTestSize("Medium")} Questions`}
                      </span>
                    </div>
                  </div>
                  <div
                    onClick={() => handleTestTypeChange("Large")}
                    className={`${
                      testSizeType === "Large"
                        ? "bg-secondary-teal/20 border-secondary-teal z-10"
                        : "border-gray-200"
                    } bg-white relative border p-4 flex flex-col cursor-pointer md:pl-4 md:pr-6 focus:outline-none`}
                  >
                    <div>
                      <div className="flex items-center">
                        <input
                          id="styled-checkbox-2"
                          type="checkbox"
                          name="testSize"
                          className="checkbox focus:ring-secondary-teal h-4 w-4 text-secondary-teal border-gray-300"
                          checked={testSizeType === "Large" ? true : false}
                        />
                        <label className="checkbox-label text-sm text-gray-500">
                          Large
                        </label>
                      </div>
                      <span className="mt-2 block text-sm text-gray-900">
                        {`${getTestSize("Large")} Questions`}
                      </span>
                    </div>
                  </div>
                </div>

                {domainPercentages && domainPercentages.length > 0 && (
                  <div className="pl-4 w-full sm:w-1/2 sm:my-4 sm:px-4 md:px-3  lg:px-4  xl:px-4 ">
                    {subjectName}
                    <Chart
                      chartType="PieChart"
                      data={domainPercentages}
                      options={options}
                      width={"100%"}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
}

export default SystemGeneratedQuestions;

import { AxiosHelper } from "../../../environment/axiosConfig";
import APIConstant from "../../../utils/constant/apiConstant";
import { AxiosResponse } from "axios";

export const UpdateActivitySessionTotalQuestionCount = (
  activitySessionId: number,
  questionCount: number
): Promise<AxiosResponse<number>> => {
  return AxiosHelper.axiosInstance().put<number>(
    APIConstant.ACTIVITYSESSIONS_API.UpdateTotalQuestionCount,
    {
      activitySessionId: activitySessionId,
      totalQuestionCount: questionCount,
    }
  );
};

import { ChevronRightIcon } from "@heroicons/react/solid";
import { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import {
  getDOK,
  getDomainsBySubjectId,
  getStandardsByDomainId,
} from "../../../../api/cmsApi";
import { getAssociatedSubjects } from "../../../../api/teacher/assignment";
import { postPassage, updatePassage } from "../../../../api/teacher/passage";
import {
  addQuestion,
  saveQuestion,
  saveTeacherPassageQuestion,
  getTeacherPassageQuestions,
  validateTeacherQuestion,
  getQuestionClassification,
} from "../../../../api/teacher/question";
import { getPayload } from "../../../../api/teacher/questionType";
import { ISelectElement } from "../../../../model/interface/selectElement";
import { Passage, PassageInfo } from "../../../../model/teacher/passage";
import Question from "../../../../model/teacher/question";
import Constant from "../../../../utils/constant/constant";
import RouteConstant from "../../../../utils/constant/routeConstant";
import Permissions from "../../../../utils/permissions";
import AccessDenied from "../../../shared/accessDenied";
import Loader from "../../../shared/loader";
import AllowedTo from "../../../shared/rbac";
import LearnosityPassageEditor from "../questionBuilder/learnosityAuthorPassageEditor";
import LearnosityQuestionEditor from "../questionBuilder/learnosityAuthorQuestionEditor";
import Classification from "../shared/questionPreview/classification";
import PreviewPage from "../shared/questionPreview/learnosityPreviewPage";
import Select from "react-select";
import QuestionClassification from "../../../../model/teacher/questionClassification";
import arraySort from "array-sort";
import { toast } from "react-toastify";
import { getFullTextFromElementContent } from "../../student/shared/textToSpeech/textToSpeech";
import constant from "../../../../utils/constant/constant";
import { IPassageSpeech } from "../../../../model/passageSpeech";
import { createPassageSpeech } from "../../../../api/textToSpeech/textToSpeechApi";
import {
  getAuthoredQuestionCountUpgradePermission,
  getAuthoredQuestionsUpgradePermission,
} from "../../../shared/permissionHelper";
import UpGradeMessagePopup from "../../../shared/upGradeMessagePopup";
import { isAnswerSet } from "./questionBuilderValidator";

function QuestionBuilder(props: any) {
  const location = useLocation();
  const history = useHistory();
  const parameters = location.state as any;
  const [activeTab, setActiveTab] = useState(
    parameters?.passageQuestion
      ? Constant.QuestionBuilderTab.PASSAGE
      : Constant.QuestionBuilderTab.QUESTION
  );
  const questionTypeCode = parameters?.questionTypeCode;
  const passageQuestion = parameters?.passageQuestion ? true : false;
  const [payload, setPayload] = useState<any>();
  const payloadLabel = parameters?.payloadLabel;
  const [questionEditor, setQuestionEditor] = useState<any>();
  const passagePayload = {
    type: "sharedpassage",
    content: "",
  };
  const [passageEditor, setPassageEditor] = useState<any>();
  const questionTypeId = parameters?.questionTypeId;
  const [questionId, setQuestionId] = useState<number>(
    props.match.params.id ?? 0
  );
  const [passageId, setPassageId] = useState<number>(0);
  const [questionApp, setQuestionApp] = useState<any>();
  const [passageApp, setPassageApp] = useState<any>();
  const [questionData, setQuestionData] = useState<any>();
  const [passageData, setPassageData] = useState<any>();
  const [editFlag] = useState<boolean>(false);
  const [showLoader, setLoader] = useState<boolean>(false);
  const itemReferenceId = parameters?.itemReferenceId;
  const [authorized, setAuthorized] = useState<boolean>(false);
  const [subjects, setSubjects] = useState<Array<any>>([]);
  const [domains, setDomains] = useState<Array<any>>([]);
  const [standards, setStandards] = useState<ISelectElement[]>([]);
  const [doks, setDoks] = useState<ISelectElement[]>([]);
  const defaultValue = { label: "Select", value: "0" };
  const [selectedDok, setSelectedDok] = useState<ISelectElement>(defaultValue);
  const [selectedStandard, setSelectedStandard] =
    useState<ISelectElement>(defaultValue);
  const [selectedDomain, setSelectedDomain] =
    useState<ISelectElement>(defaultValue);
  const [selectedSubject, setSelectedSubject] =
    useState<ISelectElement>(defaultValue);
  const [classification, setQuestionClassification] =
    useState<QuestionClassification>();
  const questionPointValue = useRef<number>();
  const alternatePoints = useRef<number[]>([]);

  const [upgradeWithLimit, setUpgradeWithLimit] = useState(false);
  const [showUpgradeLimitPopup, setShowUpgradeLimitPopup] =
    useState<boolean>(false);

  useEffect(() => {
    checkUpgradeRequired();
  }, []);

  const checkUpgradeRequired = async () => {
    let isRestricted: boolean = false;
    if (getAuthoredQuestionsUpgradePermission(props.userContext.roleId)) {
      isRestricted = await getAuthoredQuestionCountUpgradePermission(
        props.userContext.roleId
      );
      setUpgradeWithLimit(isRestricted);
    } else {
      setUpgradeWithLimit(false);
    }
    return isRestricted;
  };

  useEffect(() => {
    GetPayloadSchema();
    if (questionId > 0) {
      getQuestionClassification(questionId, 2, 0, 0, 0).then((response) => {
        setQuestionClassification(response.data);
      });
    }
    if (passageQuestion && questionId > 0) {
      getTeacherPassageQuestions(
        props.match.params.id,
        props.userContext?.userId
      ).then((response) => {
        setPassageId(response.data[0]?.teacherPassageId);
      });
    }
    if (questionId === 0) {
      CreateQuestion();
      if (passageQuestion) {
        CreatePassage();
      }
    }
  }, []);

  useEffect(() => {
    if (classification) {
      getSubjects();
      getDomains(classification.subjectId);
      getStandards(classification.domainId);
      getDoks();
    }
  }, [classification]);

  function getSubjects() {
    getAssociatedSubjects(props.userContext?.userId).then((response) => {
      let subjectsAvailables = Array<ISelectElement>();
      if (props.userContext.roleId === constant.UserRoleId.District) {
        const subjectsELA: Array<ISelectElement> = response.data
          .filter((item) => item.contentAreaId === 1)
          ?.subjects?.map((values: any) => {
            return {
              label: values?.name,
              value: values?.subjectId,
            };
          });
        const subjectsMath: Array<ISelectElement> = response.data
          .filter((item) => item.contentAreaId === 2)
          ?.subjects?.map((values: any) => {
            return {
              label: values?.name,
              value: values?.subjectId,
            };
          });
        const subjectsScience: Array<ISelectElement> = response.data
          .filter((item) => item.contentAreaId === 3)
          ?.subjects?.map((values: any) => {
            return {
              label: values?.name,
              value: values?.subjectId,
            };
          });
        const subjectsSocial: Array<ISelectElement> = response.data
          .filter((item) => item.contentAreaId === 4)
          ?.subjects?.map((values: any) => {
            return {
              label: values?.name,
              value: values?.subjectId,
            };
          });
        const options = [
          {
            label: "ELA",
            options: arraySort(subjectsELA, "label"),
          },
          {
            label: "Math",
            options: arraySort(subjectsMath, "label"),
          },
          {
            label: "Science",
            options: arraySort(subjectsScience, "label"),
          },
          {
            label: "Social Studies",
            options: arraySort(subjectsSocial, "label"),
          },
        ];
        setSubjects(options);
        subjectsAvailables = [
          ...subjectsELA,
          ...subjectsMath,
          ...subjectsScience,
          ...subjectsSocial,
        ];
      } else {
        const subjects: Array<ISelectElement> = response.data
          .filter((r) => r.isAdditionalSubject === false)
          .map((values: any) => {
            return {
              label: values?.name,
              value: values?.subjectId,
            };
          });

        const additionalSubjects: Array<ISelectElement> = response.data
          .filter((r) => r.isAdditionalSubject === true)
          .map((values: any) => {
            return {
              label: values?.name,
              value: values?.subjectId,
            };
          });

        const options = [
          {
            label: "My Subjects",
            options: arraySort(subjects, "label"),
          },
          {
            label: "Additional Subjects",
            options: arraySort(additionalSubjects, "label"),
          },
        ];
        setSubjects(options);
        subjectsAvailables = [...subjects, ...additionalSubjects];
      }
      if (classification?.subjectId !== undefined && subjectsAvailables) {
        let selectedSubjects = subjectsAvailables.filter(
          (r) => r.value?.toString() === classification.subjectId.toString()
        );

        if (selectedSubjects && selectedSubjects.length > 0) {
          setSelectedSubject(selectedSubjects[0]);
        }
      }
    });
  }

  function getDomains(subjectId: number) {
    getDomainsBySubjectId(subjectId).then((response) => {
      const domains: Array<ISelectElement> = response.data.data.map(
        (values: any) => {
          return {
            label: values?.name,
            value: values?.domainId,
          };
        }
      );
      setDomains(domains);
      if (classification?.domainId) {
        let selectedDomain = domains.filter(
          (r) => r.value.toString() === classification.domainId.toString()
        )[0];
        if (selectedDomain) {
          setSelectedDomain(selectedDomain);
        }
      }
    });
  }

  function getStandards(domainId: number) {
    getStandardsByDomainId(domainId).then((response) => {
      const standards =
        response.data.totalRecords > 0 &&
        response.data.data.map((values: any) => {
          return {
            label: "[" + values?.code + "] " + values?.name,
            value: values?.standardId,
          };
        });
      setStandards(standards);
      if (classification?.standardId) {
        let selectedStandard = standards.filter(
          (r) => r.value.toString() === classification.standardId.toString()
        )[0];
        if (selectedStandard) {
          setSelectedStandard(selectedStandard);
        }
      }
    });
  }

  function getDoks() {
    const data = getDOK();
    const doks = data.map((values: any) => {
      return {
        label: values?.label,
        value: values?.value,
      };
    });
    setDoks(doks);
    if (classification?.dokId) {
      let selectedDok = doks.filter(
        (r) => r.value.toString() === classification?.dokId?.toString()
      );
      setSelectedDok(selectedDok[0]);
    }
  }

  function handleSubjectChange(e: any) {
    setSelectedSubject(e);
    setSelectedDomain(defaultValue);
    setSelectedStandard(defaultValue);
    getDomains(e.value);
  }

  function handleDomainChange(e: any) {
    setSelectedStandard(defaultValue);
    setSelectedDomain(e);
    getStandards(e.value);
  }

  function handleStandardChange(e: any) {
    setSelectedStandard(e);
  }
  function handleDokChange(e: any) {
    setSelectedDok(e);
  }

  function GetPayloadSchema() {
    getPayload(questionTypeCode).then((res) => {
      setPayload(res.data);
    });
  }

  async function CreateQuestion() {
    if (upgradeWithLimit) {
      setShowUpgradeLimitPopup(true);
      history.push({
        pathname: RouteConstant.AUTHOREDQUESTIONS,
      });
      return;
    }
    const isRestricted: boolean = await checkUpgradeRequired();
    if (isRestricted) {
      setShowUpgradeLimitPopup(true);
      history.push({
        pathname: RouteConstant.AUTHOREDQUESTIONS,
      });
      return;
    }

    const question: Question = {
      createdByUserId: props.userContext?.userId || 1,
      stateId: props.userContext?.stateId || 1,
      standardId: parameters?.standardId || 0,
      isActive: true,
      isPublished: true,
      questionTypeId: questionTypeId,
      dok: parameters?.dok || 1,
    };
    addQuestion(question).then((response: any) => {
      setAuthorized(true);
      setQuestionId(response.data);
    });
  }

  async function CreatePassage() {
    if (upgradeWithLimit) {
      setShowUpgradeLimitPopup(true);
      history.push({
        pathname: RouteConstant.AUTHOREDQUESTIONS,
      });
      return;
    }
    const isRestricted: boolean = await checkUpgradeRequired();
    if (isRestricted) {
      setShowUpgradeLimitPopup(true);
      history.push({
        pathname: RouteConstant.AUTHOREDQUESTIONS,
      });
      return;
    }
    const passageInput: Passage = {
      userId: props.userContext?.userId || 1,
      isApproved: true,
      isActive: true,
      gradeId: 1,
    };
    postPassage(passageInput).then((response: any) => {
      setPassageId(response.data);
    });
  }

  function hasValidQuestionPointsValue() {
    if (
      !questionData?.validation?.valid_response?.score &&
      !questionData?.validation?.alt_responses
    )
      return true;
    const validQuestionPoint = isValidQuestionPointValue();
    const validAlternatePoints = areValidAlternatePointsValue();
    return validQuestionPoint && validAlternatePoints;
  }

  function isValidQuestionPointValue(): boolean {
    if (!questionData?.validation?.valid_response?.score) return true;
    const isValidPoint = checkPointValue(
      questionData.validation.valid_response.score,
      "valid_response"
    );
    if (!isValidPoint)
      toast.error(constant.QuestionBuilderErrors.InvalidPointValue);
    return isValidPoint;
  }

  function areValidAlternatePointsValue(): boolean {
    if (!questionData?.validation?.alt_responses) return true;
    const validPoints = getValidAlternatePointsValues().every(Boolean);
    if (!validPoints)
      toast.error(constant.QuestionBuilderErrors.InvalidAlternatePointValue);
    return validPoints;
  }

  function getValidAlternatePointsValues(): boolean[] {
    return questionData.validation.alt_responses.map((scoring, index) => {
      if (scoring?.score == null) return true;
      return checkPointValue(scoring.score, `alt_responses[${index}]`);
    });
  }

  function checkAlternatesPointValue() {
    if (!questionData?.validation?.alt_responses) return true;
    const validPoints = getValidAlternatePointsValues();
    validPoints.forEach((isValidPoint, index) => {
      if (
        !isValidPoint &&
        alternatePoints.current &&
        alternatePoints.current[index] !==
          questionData.validation.alt_responses[index]?.score
      )
        toast.error(constant.QuestionBuilderErrors.InvalidAlternatePointValue);
    });
    alternatePoints.current = questionData?.validation?.alt_responses?.map(
      (scoring) => scoring.score
    );
  }

  function checkPointValue(score: number, className: string) {
    const isValidScore = Number.isInteger(score);
    changePointValueInputStyle(isValidScore, className);
    return isValidScore;
  }

  function changePointValueInputStyle(
    isValidPoint: boolean,
    className: string
  ) {
    const element = document.querySelector(
      `div[data-lrn-qe-input-path='validation.${className}.score'] input`
    );
    if (!element) return;
    (element as HTMLElement).style.border = !isValidPoint
      ? "1px solid red"
      : "";
  }

  function SaveQuestionData(redirect: boolean) {
    if (!hasValidQuestionPointsValue()) return;
    if (isAnswerSet(questionData)) {
      setLoader(true);
      const learnoQueReq = CreateLearnosityQuestionRequest();
      const passageArray: PassageInfo[] = [];
      if (passageData && passageId > 0) {
        passageArray.push({
          Reference: passageId + "-p-teacher",
          Type: passageData["type"],
          Data: {
            content: passageData["content"]
              ? passageData["content"]
              : undefined,
            heading: passageData["heading"]
              ? passageData["heading"]
              : undefined,
            instructorStimulus: passageData["instructor_stimulus"]
              ? passageData["instructor_stimulus"]
              : undefined,
            isMath: passageData["is_math"] ? passageData["is_math"] : undefined,
            metadata: passageData["metadata"]
              ? {
                  fleschKincaid: passageData["metadata"]["flesch_kincaid"],
                  lexile: passageData["metadata"]["lexile"],
                }
              : undefined,
            pages: passageData["pages"] ? passageData["pages"] : undefined,
            paginatedContent: passageData["paginated_content"]
              ? passageData["paginated_content"]
              : undefined,
            type: passageData["type"],
          },
        });
        UpdatePassage();
        const params = {
          teacherPassageId: passageId,
          teacherQuestionId: questionId,
          userId: props.userContext.userId || 1,
        };

        saveTeacherPassageQuestion(params);
      }
      const selectedStandardId =
        selectedStandard.value !== defaultValue.value
          ? parseInt(selectedStandard.value)
          : parameters?.standardId;
      const question: Question = {
        teacherQuestionId: questionId,
        createdByUserId: props.userContext?.userId || 1,
        stateId: parameters?.stateId || 1,
        standardId: selectedStandardId || 0,
        isActive: true,
        isPublished: true,
        questionTypeId: questionTypeId,
        questionReferenceId: questionId + "-teacher",
        questionText: questionData?.["stimulus"],
        updatedByUserId: props.userContext?.userId || 1,
        questionFeatureReferenceId: questionId + "-q-01-teacher",
        questionRequest: learnoQueReq,
        passages: passageArray,
        dok: selectedDok.value ? parseInt(selectedDok.value) : parameters?.dok,
      };
      saveQuestion(question)
        .then((response: any) => {
          setLoader(false);
          setActiveTab(Constant.QuestionBuilderTab.REVIEW);
          if (redirect) {
            history.push({
              pathname: RouteConstant.AUTHOREDQUESTIONS,
            });
          }
        })
        .catch((err) => {
          setLoader(false);
        });
    }
  }

  const CreateLearnosityQuestionRequest = () => {
    const questionArray: any[] = [];
    if (questionData) {
      UpdateMetadataForSingleMCQ(questionData);
      questionArray.push({
        type: questionData.type,
        reference: !editFlag
          ? questionId + "-q-01-teacher"
          : questionData.getItem().questions[0]
          ? questionData.getItem().questions[0].reference
          : questionId + "-q-01-teacher",
        data: questionData,
      });
    }
    return JSON.stringify({
      questions: questionArray,
    });
  };

  function UpdatePassage() {
    const passage: Passage = {
      isApproved: true,
      isActive: true,
      teacherPassageId: passageId,
      passageReferenceId: passageId + "-p-teacher",
      title: passageData ? passageData["heading"] : undefined,
      userId: props.userContext?.userId || 1,
      passage: passageData ? passageData["heading"] : undefined,
      wordCount: passageData ? GetEnglishWordCount() : 0,
      gradeId: 1,
    };
    updatePassage(passage).then((_res) => {
      updatePassageTTS().then((_res) => {});
    });
  }

  const getPassageSpeechRequest = async (text: string) => {
    const language = "en";
    const contentText = await getFullTextFromElementContent(text, language);
    return {
      language: language,
      text: contentText.substring(0, constant.TTS.MAX_LENGTH_STORAGE_TTS),
    };
  };

  const getPassagePagesContent = async () => {
    const pages = passageData?.pages;
    const pagesContent = [] as IPassageSpeech[];
    for (const page of pages) {
      pagesContent.push(await getPassageSpeechRequest(page));
    }
    return pagesContent;
  };

  const getPassageTextContent = async () => {
    const content = await getPassageSpeechRequest(passageData?.content);
    return [content];
  };

  const updatePassageTTS = async () => {
    try {
      const emptyData =
        !passageData || !(passageData?.content || passageData?.pages);
      if (emptyData || !selectedSubject?.value) return;
      let content: IPassageSpeech[] = [];
      if (!passageData["paginated_content"]) {
        content = await getPassageTextContent();
      } else {
        content = await getPassagePagesContent();
      }
      const passages = content.filter(
        (passage) => passage?.text?.length > constant.TTS.MIN_LENGTH_STORAGE_TTS
      );
      if (passages.length > 0) {
        const subjectId = selectedSubject.value
          ? parseInt(selectedSubject.value)
          : 0;
        await createPassageSpeech(subjectId, passages);
      }
    } catch (_error) {
      toast.error("Couldn't update text to speech audio");
    }
  };
  const GetEnglishWordCount = () => {
    let wordCount = 0;
    if (passageData["content"]) {
      wordCount = WordCount(passageData["content"]);
    } else if (passageData["paginated_content"]) {
      let contentStr = "";
      for (let i = 0; i < passageData["pages"].length; i++) {
        contentStr += " " + passageData["pages"][i];
      }
      wordCount = WordCount(contentStr);
    }
    return wordCount;
  };

  function WordCount(str) {
    return (
      str
        .trim()
        // eslint-disable-next-line no-control-regex
        .split(new RegExp(" |\n"))
        .filter(function (n) {
          return n !== "";
        }).length
    );
  }
  function QuestionTab() {
    setActiveTab(Constant.QuestionBuilderTab.QUESTION);
    if (passageData) {
      setPassageData(passageApp.getWidget());
    }
  }
  function ReviewTab() {
    if (questionData) {
      setLoader(true);
      SaveQuestionData(false);
    }
  }
  const UpdateMetadataForSingleMCQ = (element) => {
    if (
      (element["multiple_responses"] == null ||
        (element["multiple_responses"] != null &&
          element["multiple_responses"] === false)) &&
      element["validation"] !== undefined &&
      element["validation"]["valid_response"] !== undefined &&
      element["validation"]["valid_response"]["value"] !== undefined &&
      element["validation"]["valid_response"]["value"].length === 1 &&
      element["options"] !== undefined
    ) {
      const valid_response =
        element["validation"]["valid_response"]["value"][0];
      const options_value = element["options"]
        .filter((x) => x["value"] !== valid_response)
        .map((x) => x["value"]);
      const random_val =
        options_value[Math.floor(Math.random() * options_value.length)];

      if (element["metadata"] != null) {
        element["metadata"]["auto_mask_response"] = random_val;
      } else {
        const metadata: any = {
          auto_mask_response: random_val,
        };
        element["metadata"] = metadata;
      }
      setQuestionData(element);
    }
  };
  useEffect(() => {
    if (payload && questionId > 0) {
      if (authorized) {
        setPassageEditor(
          passageQuestion && passageId > 0 ? (
            <LearnosityPassageEditor
              itemRef={
                itemReferenceId?.length > 0
                  ? itemReferenceId
                  : passageId + "-p-teacher"
              }
              payload={passagePayload}
              payloadLabel={payloadLabel}
              setPassageApp={setPassageApp}
              setPassageData={setPassageData}
              passageData={passageData}
            ></LearnosityPassageEditor>
          ) : (
            ""
          )
        );
        setQuestionEditor(
          <LearnosityQuestionEditor
            itemRef={
              itemReferenceId?.length > 0
                ? itemReferenceId
                : questionId + "-teacher"
            }
            payload={payload}
            payloadLabel={payloadLabel}
            setQuestionApp={setQuestionApp}
            setQuestionData={setQuestionData}
            questionData={questionData}
            questionIndex={0}
            questionApp={questionApp}
            questionTypeId={questionTypeId}
          ></LearnosityQuestionEditor>
        );
      } else {
        setQuestionEditor("");
        setPassageEditor("");
      }
    } else {
      setQuestionEditor("");
      setPassageEditor("");
    }
  }, [payload, questionId, passageId, passageData, questionData, authorized]);

  useEffect(() => {
    if (
      questionPointValue.current !==
      questionData?.validation?.valid_response?.score
    ) {
      questionPointValue.current =
        questionData?.validation?.valid_response?.score;
      isValidQuestionPointValue();
    }
    if (
      questionData?.validation?.alt_responses &&
      JSON.stringify(alternatePoints.current) !==
        JSON.stringify(
          questionData?.validation?.alt_responses?.map(
            (scoring) => scoring.score
          )
        )
    ) {
      checkAlternatesPointValue();
    }
  }, [questionData]);

  useEffect(() => {
    if (questionId > 0 && !authorized) {
      validateTeacherQuestion(questionId).then((res) => {
        if (res.data) {
          setAuthorized(true);
        } else {
          setAuthorized(false);
        }
      });
    }
  }, [questionId]);

  return (
    <AllowedTo
      perform={Permissions.authoured_questions_modify}
      no={() => <AccessDenied />}
    >
      <div className="min-w-0 w-full">
        {showLoader && <Loader />}
        {showUpgradeLimitPopup && (
          <UpGradeMessagePopup
            togglePopup={setShowUpgradeLimitPopup}
            roleId={props.userContext?.roleId ?? 0}
            message={constant.UPGRADE_MESSAGE_SCHOOL_ACCOUNT}
          />
        )}
        <div className="lg:flex lg:items-center px-4 py-5 lg:px-8 border">
          <ol className="lg:w-[45%] flex items-center space-x-2 mb-1">
            <li>
              <div className="flex items-center">
                <Link
                  to={RouteConstant.AUTHOREDQUESTIONS}
                  className="cursor-pointer text-sm text-gray-500 hover:text-gray-700 border-dashed border-b border-gray-400 hover:border-opacity-0"
                >
                  Manage My Questions
                </Link>
              </div>
            </li>
            <li>
              <div className="flex items-center">
                <ChevronRightIcon className="flex-shrink-0 h-5 w-5 text-gray-400" />
                <span className="ml-1 text-sm text-gray-500 capitalize">
                  Question Builder
                </span>
              </div>
            </li>
          </ol>

          <div className="w-[55%] text-lg font-medium flex gap-2 h-auto sm:h-10 items-center">
            <h1 className="text-primary-violet flex items-center justify-between w-full pr-4">
              Question Builder
            </h1>
          </div>
        </div>
        {questionId > 0 && classification != null && (
          <div className="py-1 relative mt-4 pb-4 border-b">
            <div className="px-4 lg:px-8">
              <div className="grid grid-cols-12 gap-6">
                <div className="col-span-2">
                  <label
                    htmlFor="country"
                    className="block text-sm font-medium text-gray-500"
                  >
                    Subject
                  </label>
                  <div className="mt-1">
                    <Select
                      value={selectedSubject}
                      options={subjects}
                      isSearchable={true}
                      onChange={handleSubjectChange}
                      className="w-full"
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary: "#008fbb",
                        },
                      })}
                    />
                  </div>
                </div>
                <div className="col-span-2">
                  <label
                    htmlFor="domain"
                    className="block text-sm font-medium text-gray-500"
                  >
                    Domain
                  </label>
                  <div className="mt-1">
                    <Select
                      value={selectedDomain}
                      options={domains}
                      isSearchable={true}
                      onChange={handleDomainChange}
                      className="w-full"
                      isDisabled={selectedSubject?.value === "0"}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary: "#008fbb",
                        },
                      })}
                    />
                  </div>
                </div>
                <div className="col-span-2">
                  <label
                    htmlFor="standard"
                    className="block text-sm font-medium text-gray-500"
                  >
                    Standard
                  </label>
                  <div className="mt-1">
                    <Select
                      value={selectedStandard}
                      options={standards}
                      isSearchable={true}
                      onChange={handleStandardChange}
                      className="w-full"
                      isDisabled={selectedDomain?.value === "0"}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary: "#008fbb",
                        },
                      })}
                    />
                  </div>
                </div>
                <div className="col-span-2">
                  <label
                    htmlFor="dok"
                    className="block text-sm font-medium text-gray-500"
                  >
                    DOK
                  </label>
                  <div className="mt-1">
                    <Select
                      value={selectedDok}
                      options={doks}
                      isSearchable={true}
                      onChange={handleDokChange}
                      className="w-full"
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary: "#008fbb",
                        },
                      })}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="lg:border-b lg:border-gray-200 ">
          <nav className="px-4 sm:pl-6 lg:pl-8 lg:pr-0" aria-label="Progress">
            <ol className="rounded-md overflow-hidden lg:flex lg:border-l lg:border-r lg:border-gray-200 lg:rounded-none">
              <li
                className={`${
                  passageQuestion ? "" : "hidden"
                } relative overflow-hidden lg:flex-1 
                ${
                  activeTab === Constant.QuestionBuilderTab.PASSAGE
                    ? "bg-primary-violet"
                    : ""
                }`}
                onClick={() =>
                  setActiveTab(Constant.QuestionBuilderTab.PASSAGE)
                }
              >
                <div
                  className={`${
                    activeTab === Constant.QuestionBuilderTab.PASSAGE
                      ? ""
                      : "border border-white"
                  }   border border-b-0 border-gray-200 overflow-hidden lg:border-0`}
                >
                  <a href="#!" aria-current="step" x-description="Current Step">
                    <span
                      className={`absolute top-0 left-0 w-1 h-full  ${
                        activeTab === Constant.QuestionBuilderTab.PASSAGE
                          ? "bg-primary-violet"
                          : "bg-transparent group-hover:bg-gray-200 "
                      } lg:w-full lg:h-0.5 lg:bottom-0 lg:top-auto`}
                      aria-hidden="true"
                    ></span>
                    <span className="px-6 py-3 flex items-center text-sm font-medium lg:pl-9">
                      <span className="flex-shrink-0">
                        <span
                          className={`w-10 h-10 flex items-center justify-center border-2  ${
                            activeTab === Constant.QuestionBuilderTab.PASSAGE
                              ? "border-white"
                              : "border-gray-300"
                          } rounded-full`}
                        >
                          <span
                            className={
                              activeTab === Constant.QuestionBuilderTab.PASSAGE
                                ? "text-white"
                                : "text-gray-500"
                            }
                          >
                            01
                          </span>
                        </span>
                      </span>
                      <span className="mt-0.5 ml-4 min-w-0 flex flex-col">
                        <span
                          className={`text-sm font-semibold  ${
                            activeTab === Constant.QuestionBuilderTab.PASSAGE
                              ? "text-white"
                              : "text-gray-500"
                          } tracking-wide uppercase`}
                        >
                          Passage
                        </span>
                        <span
                          className={`text-xs ${
                            activeTab === Constant.QuestionBuilderTab.PASSAGE
                              ? "text-gray-200"
                              : "text-gray-400"
                          } tracking-wide`}
                        >
                          Add content for your passage here.
                        </span>
                      </span>
                    </span>
                  </a>
                </div>
              </li>
              <li
                className={`relative overflow-hidden lg:flex-1 ${
                  activeTab === Constant.QuestionBuilderTab.QUESTION
                    ? "bg-primary-violet"
                    : ""
                } `}
                onClick={() => QuestionTab()}
              >
                <div
                  className={`${
                    activeTab === Constant.QuestionBuilderTab.QUESTION
                      ? ""
                      : "border border-white"
                  }   border border-b-0 border-gray-200 overflow-hidden lg:border-0`}
                >
                  <a href="#!" aria-current="step" x-description="Current Step">
                    <span
                      className={`absolute top-0 left-0 w-1 h-full  ${
                        activeTab === Constant.QuestionBuilderTab.QUESTION
                          ? "bg-primary-violet"
                          : "bg-transparent group-hover:bg-gray-200 "
                      } lg:w-full lg:h-0.5 lg:bottom-0 lg:top-auto`}
                      aria-hidden="true"
                    ></span>
                    <span className="px-6 py-3 flex items-center text-sm font-medium lg:pl-9">
                      <span className="flex-shrink-0">
                        <span
                          className={`w-10 h-10 flex items-center justify-center border-2  ${
                            activeTab === Constant.QuestionBuilderTab.QUESTION
                              ? "border-white"
                              : "border-gray-300"
                          } rounded-full`}
                        >
                          <span
                            className={
                              activeTab === Constant.QuestionBuilderTab.QUESTION
                                ? "text-white"
                                : "text-gray-500"
                            }
                          >
                            {passageQuestion ? "02" : "01"}
                          </span>
                        </span>
                      </span>
                      <span className="mt-0.5 ml-4 min-w-0 flex flex-col">
                        <span
                          className={`text-sm font-semibold  ${
                            activeTab === Constant.QuestionBuilderTab.QUESTION
                              ? "text-white"
                              : "text-gray-500"
                          } tracking-wide uppercase`}
                        >
                          Question
                        </span>
                        <span
                          className={`text-xs ${
                            activeTab === Constant.QuestionBuilderTab.QUESTION
                              ? "text-gray-200"
                              : "text-gray-400"
                          } tracking-wide`}
                        >
                          Author your questions here.
                        </span>
                      </span>
                    </span>
                  </a>
                  <div
                    className={`hidden absolute top-0 -left-${
                      activeTab === Constant.QuestionBuilderTab.PASSAGE
                        ? "0.5"
                        : "0"
                    } w-3 inset-0 lg:block`}
                    aria-hidden="true"
                  >
                    {activeTab !== Constant.QuestionBuilderTab.QUESTION && (
                      <svg
                        className={`h-full w-full ${
                          activeTab === Constant.QuestionBuilderTab.PASSAGE
                            ? " text-primary-violet fill-current"
                            : " text-gray-300"
                        }`}
                        viewBox="0 0 12 82"
                        fill="none"
                        preserveAspectRatio="none"
                      >
                        <path
                          d="M0.5 0V31L10.5 41L0.5 51V82"
                          stroke="currentcolor"
                          vectorEffect="non-scaling-stroke"
                        ></path>
                      </svg>
                    )}
                  </div>
                </div>
              </li>

              <li
                className={`relative overflow-hidden lg:flex-1 ${
                  activeTab === Constant.QuestionBuilderTab.REVIEW
                    ? "bg-primary-violet"
                    : ""
                } `}
                onClick={() => ReviewTab()}
              >
                <div
                  className={`${
                    activeTab === Constant.QuestionBuilderTab.REVIEW
                      ? ""
                      : "border lg:border-0 border-gray-200 "
                  }  border lg:border-0 border-b border-gray-200 overflow-hidden lg:border-00`}
                >
                  <a href="#!" aria-current="step" x-description="Current Step">
                    <span
                      className={`absolute top-0 left-0 w-1 h-full  ${
                        activeTab === Constant.QuestionBuilderTab.REVIEW
                          ? "bg-primary-violet"
                          : "bg-transparent group-hover:bg-gray-200 "
                      } lg:w-full lg:h-0.5 lg:bottom-0 lg:top-auto`}
                      aria-hidden="true"
                    ></span>
                    <span className="px-6 py-3 flex items-center text-sm font-medium lg:pl-9">
                      <span className="flex-shrink-0">
                        <span
                          className={`w-10 h-10 flex items-center justify-center border-2  ${
                            activeTab === Constant.QuestionBuilderTab.REVIEW
                              ? "border-white"
                              : "border-gray-300"
                          } rounded-full`}
                        >
                          <span
                            className={
                              activeTab === Constant.QuestionBuilderTab.REVIEW
                                ? "text-white"
                                : "text-gray-500"
                            }
                          >
                            {passageQuestion ? "03" : "02"}
                          </span>
                        </span>
                      </span>
                      <span className="mt-0.5 ml-4 min-w-0 flex flex-col">
                        <span
                          className={`text-sm font-semibold  ${
                            activeTab === Constant.QuestionBuilderTab.REVIEW
                              ? "text-white"
                              : "text-gray-500"
                          } tracking-wide uppercase`}
                        >
                          Review
                        </span>
                        <span
                          className={`text-xs ${
                            activeTab === Constant.QuestionBuilderTab.REVIEW
                              ? "text-gray-200"
                              : "text-gray-400"
                          } tracking-wide`}
                        >
                          Review your questions and ....
                        </span>
                      </span>
                    </span>
                  </a>
                  <div
                    className={`hidden absolute top-0 -left-${
                      activeTab === Constant.QuestionBuilderTab.QUESTION
                        ? "0.5"
                        : "0"
                    } w-3 inset-0 lg:block`}
                    aria-hidden="true"
                  >
                    {activeTab !== Constant.QuestionBuilderTab.REVIEW && (
                      <svg
                        className={`h-full w-full ${
                          activeTab === Constant.QuestionBuilderTab.QUESTION
                            ? " text-primary-violet fill-current"
                            : " text-gray-300"
                        }`}
                        viewBox="0 0 12 82"
                        fill="none"
                        preserveAspectRatio="none"
                      >
                        <path
                          d="M0.5 0V31L10.5 41L0.5 51V82"
                          stroke="currentcolor"
                          vectorEffect="non-scaling-stroke"
                        ></path>
                      </svg>
                    )}
                  </div>
                </div>
              </li>
            </ol>
          </nav>
        </div>

        {activeTab === Constant.QuestionBuilderTab.PASSAGE && (
          <div className="my-4 relative px-4 sm:px-6  lg:px-8 ">
            <div className="bg-gray-100 h-101  mb-5 rounded-lg p-5 h-full ">
              <div className="flex flex-col lg:flex-row justify-between w-full">
                <div className="w-full">{passageEditor}</div>
                <div className="order-first lg:order-last text-right mb-4 lg:mb-0">
                  <button
                    onClick={() => QuestionTab()}
                    className="whitespace-nowrap ml-4 mb-auto bg-primary-violet border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-dark-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Save & Continue
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        {activeTab === Constant.QuestionBuilderTab.QUESTION && (
          <div className="my-4 relative px-4 sm:px-6  lg:px-8 ">
            <div className="bg-gray-100  h-101 mb-5 rounded-lg p-5 h-full ">
              <div className="flex flex-col lg:flex-row justify-between w-full">
                <div className="w-full">{questionEditor}</div>
                <div className="order-first lg:order-last text-right mb-4 lg:mb-0">
                  <button
                    type="button"
                    onClick={() => SaveQuestionData(false)}
                    className="whitespace-nowrap ml-4 mb-auto bg-primary-violet border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-dark-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Save & Continue
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        {activeTab === Constant.QuestionBuilderTab.REVIEW && (
          <div className="my-4 relative px-4 sm:px-6  lg:px-8 ">
            <div className="bg-gray-100 h-101 mb-5 rounded-lg p-5 h-full ">
              <div className="flex flex-wrap space-y-2 md:space-y-0 justify-end mb-4">
                <div>
                  <button
                    onClick={() =>
                      setActiveTab(Constant.QuestionBuilderTab.QUESTION)
                    }
                    className="whitespace-nowrap mb-auto ml-2 bg-transparent border my-auto border-primary-violet shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-primary-violet hover:text-white hover:bg-light-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3 transition ease-in-out duration-500"
                  >
                    Go back & Modify
                  </button>
                </div>
                <div>
                  <button
                    onClick={() => SaveQuestionData(true)}
                    className="whitespace-nowrap ml-4 mb-auto bg-primary-violet border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-dark-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Close
                  </button>
                </div>
              </div>
              <div className="flex justify-between w-full">
                <div className="w-full">
                  <div className="mb-2">
                    <span className="font-semibold text-gray-700 text-base">
                      Question Id: {questionId}
                    </span>
                    <Classification
                      questionId={questionId}
                      standardId={parameters?.standardId ?? 0}
                      questionTypeId={2}
                    ></Classification>
                  </div>
                  {questionData ? (
                    <PreviewPage itemRef={questionId + "-teacher"} />
                  ) : (
                    "Review your content here.."
                  )}
                </div>
              </div>
            </div>
          </div>
        )}

        {/* <div className="px-4 py-3 bg-gray-50 text-right sm:px-6  block lg:hidden">
          <button
            type="submit"
            className="bg-primary-violet border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Save & Continue
          </button>
        </div> */}
      </div>
    </AllowedTo>
  );
}

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(QuestionBuilder);

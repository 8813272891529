import { AxiosHelper } from "../../environment/axiosConfig";
import APIConstant from "../../utils/constant/apiConstant";
import { IVideo } from "../../model/teacher/video";

export const getVideobySubjectId = (
  subjectId: number,
  videoTitle: string | undefined
) => {
  return AxiosHelper.axiosInstance().get<IVideo[]>(
    APIConstant.VIDEOS_API.GetVideosBySubjectId,
    {
      params: {
        SubjectId: subjectId,
        VideoTitle: videoTitle,
      },
    }
  );
};

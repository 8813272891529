import { useState } from "react";
import { connect } from "react-redux";
import "../../../../../css/style.css";
import RouteConstant from "../../../../../utils/constant/routeConstant";
import Breadcrumb from "../../shared/breadcrumb";
import { IBreadcrumb } from "../../../../../model/common/breadcrumb";
import { useTranslation } from "react-i18next";
import { ISelectElement } from "../../../../../model/interface/selectElement";
import Loader from "../../../../shared/loader";
import Subject from "../../../../shared/dropdowns/subject";
import { getLiveMonitoringReport } from "../../../../../api/report/liveMonitoringReport";
import {
  getAssignmentFilterForLiveMonitoringReport,
  getClassFilterForLiveMonitoringReport,
} from "../../../../../api/teacher/assignment";
import LearnosityLiveMonitoring from "../LearnosityLiveMonitoring";
import { UserContextState } from "../../../../../redux/actions/userContextAction";
import Profile from "../../../../../model/users/profile";
import { ILearnosityLiveMonitoringUser } from "../../../../../model/report/LearnosityLiveMonitoringUser";
import {
  AssignmentType,
  LiveMonitoringActivity,
  ClassType,
} from "../../../../../model/report/LiveMonitoringActivity";
import Assignment from "../../../../shared/dropdowns/assignment";
import Select from "react-select";
import Asterisk from "../../../../shared/asterisk";
import AllowedTo from "../../../../shared/rbac";
import permissions from "../../../../../utils/permissions";
import AccessDenied from "../../../../shared/accessDenied";
import { checkIfUpgradeRequired } from "../../../../shared/permissionHelper";
import UpGradeMessagePopup from "../../../../shared/upGradeMessagePopup";
import constant from "../../../../../utils/constant/constant";
interface LiveMonitoringReportProps {
  userContext: UserContextState;
  profile: Profile;
}
function LiveMonitoringReport(props: LiveMonitoringReportProps) {
  const { t } = useTranslation();
  const [selectedSubject, setSelectedSubject] = useState<ISelectElement>({
    label: "Select Subject",
    value: "0",
  });
  const [selectedAssignment, setSelectedAssignment] = useState<any>({
    label: "Select Assignment",
    value: "0",
  });
  const [selectedClass, setSelectedClass] = useState<any>({
    label: "Select Class",
    value: "0",
  });
  const [noRecordFoundMsg, setNoRecordFoundMsg] = useState<string>("");
  const [showReport, setShowReport] = useState<boolean>(false);
  const [reportUserData, setReportUserData] = useState<
    ILearnosityLiveMonitoringUser[]
  >([]);
  const [reportActivityData, setReportActivityData] =
    useState<LiveMonitoringActivity>();
  const [showLoader, setLoader] = useState<boolean>(false);
  const [assignments, setAssignments] = useState<Array<AssignmentType>>();
  const [Allclasses, setAllClasses] = useState<Array<ClassType>>();

  const isUpgradeRequired = checkIfUpgradeRequired(
    permissions.reports_livemonitoring_upgrade
  );
  const [showUpgradePopup, setShowUpgradePopup] =
    useState<boolean>(isUpgradeRequired);

  function fetchAssignments(subjectId) {
    setLoader(true);
    const assignmentSearch = {
      subjectId: subjectId,
    };

    getAssignmentFilterForLiveMonitoringReport(assignmentSearch)
      .then((r) => {
        setAssignments(r.data);
        setLoader(false);
      })
      .finally(() => {
        setLoader(false);
      });
  }

  function fetchClasses(AssignmentId) {
    setLoader(true);
    const classSearch = {
      assignmentId: AssignmentId,
    };

    getClassFilterForLiveMonitoringReport(classSearch)
      .then((r) => {
        setAllClasses(mapOptions(r.data));
        setLoader(false);
      })
      .finally(() => {
        setLoader(false);
      });
  }

  const getReport = () => {
    setLoader(true);
    getLiveMonitoringReport(
      Number(selectedAssignment?.value),
      selectedAssignment.isCannedTest,
      Number(selectedClass?.value)
    )
      .then((res) => {
        if (!res.data || res.data.students.length === 0) {
          setNoRecordFoundMsg("No record found");
          setShowReport(false);
        } else {
          setReportUserData(res.data.students);
          setReportActivityData(res.data.activity);
          setShowReport(true);
        }

        setLoader(false);
      })
      .catch(() => {
        setLoader(false);
      });
  };

  const onRunReport = () => {
    setNoRecordFoundMsg("");
    setShowReport(false);
    setReportUserData([]);
    setReportActivityData(undefined);
    getReport();
  };

  const onReset = () => {
    setReportUserData([]);
    setReportActivityData(undefined);
    setNoRecordFoundMsg("");
    setShowReport(false);
    setSelectedSubject({
      label: "Select Subject",
      value: "0",
    });
    setSelectedAssignment({
      label: "Select Assignment",
      value: "0",
    });
    setSelectedClass({
      label: "Select Class",
      value: "0",
    });
  };

  const breadcrumbItems = () => {
    const items: Array<IBreadcrumb> = [
      { name: t("Breadcrumb.Home"), url: RouteConstant.ROUTE_DASHBOARD },
      {
        name: t("Breadcrumb.AssessmentAssignmentCenter"),
        url: RouteConstant.TeacherRoutes.assignmentCenter,
      },
      { name: t("Breadcrumb.LiveMonitoringReport"), url: "" },
    ];

    return items;
  };

  function handleSubjectChange(selectdItem: any) {
    setSelectedAssignment({
      label: "Select Assignment",
      value: "0",
    });
    setSelectedClass({
      label: "Select Class",
      value: "0",
    });
    setShowReport(false);

    setSelectedSubject(selectdItem);
    fetchAssignments(selectdItem?.value);
  }

  function handleAssignmentChange(selectedItem: any) {
    setSelectedClass({
      label: "Select Class",
      value: "0",
    });
    setShowReport(false);

    setSelectedAssignment(selectedItem);
    fetchClasses(selectedItem?.value);
  }

  function handleClassChange(selectedValue) {
    if (!selectedValue) {
      selectedValue = { label: "Select Class", value: "0" };
    }
    setShowReport(false);

    setSelectedClass(selectedValue);
  }

  const mapOptions: any = (data) => {
    const options: any = [];

    const classes: Array<ISelectElement> = data
      .filter((r) => r.isAdditionalClass === false)
      .map((values: any) => {
        return {
          label: values?.displayName,
          value: values?.classId,
        };
      });
    const additionalClasses: Array<ISelectElement> = data
      .filter((r) => r.isAdditionalClass === true)
      .map((values: any) => {
        return {
          label:
            values?.displayName +
            " - " +
            values.teacherLastName +
            ", " +
            values.teacherFirstName,
          value: values?.classId,
        };
      });

    if (classes.length > 0) {
      options.push({
        label: "My Classes",
        options: classes,
      });
    }
    if (additionalClasses.length > 0) {
      options.push({
        label: "Additional Classes",
        options: additionalClasses,
      });
    }
    if (
      classes.length === 0 &&
      additionalClasses.length === 0 &&
      data.length > 0
    ) {
      data.map((values: any) => {
        options.push({
          label: values?.displayName,
          value: values?.classId,
        });
      });
    }
    return options;
  };

  return (
    <AllowedTo
      perform={permissions.reports_livemonitoring_view}
      no={() => <AccessDenied />}
    >
      {!isUpgradeRequired && (
        <div className="min-w-0 w-full">
          <div className="lg:flex lg:items-center px-4 py-3 lg:px-8 border-b">
            <Breadcrumb items={breadcrumbItems()} />
            <div className="lg:w-[55%] text-lg font-medium flex gap-2 h-auto sm:h-10 items-center">
              <h1 className="text-primary-violet font-medium flex justify-start mb-0 pb-0">
                Live Monitoring Report
              </h1>
            </div>
          </div>
          <div className="px-2 sm:px-6  lg:px-8 py-1 relative my-4">
            <div className=" bg-gray-100 w-full rounded-lg p-5">
              <div>
                <div className="flex flex-col 1xl:flex-row justify-between gap-x-6">
                  <div className="w-full order-last 1xl:order-1 grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 1xl:grid-cols-2 1xl:mb-auto 2xl:grid-cols-2 xxxl:grid-cols-4 gap-4 mb-4 mt-4">
                    <div className="flex flex-col">
                      <Subject
                        setSelectedSubject={(items) =>
                          handleSubjectChange(items[0])
                        }
                        selectedItems={selectedSubject}
                        isMulti={false}
                        isRequired={true}
                        themeColor={"#008fbb"}
                      ></Subject>
                    </div>
                    <div className="flex flex-col">
                      <Assignment
                        label={"Assignment"}
                        defaultText={"Select Assignment"}
                        setSelectedAssignment={(items) =>
                          handleAssignmentChange(items[0])
                        }
                        selectedItems={selectedAssignment}
                        assignments={assignments}
                        isMulti={false}
                        isRequired={true}
                        themeColor={"#008fbb"}
                        showDiagTest={true}
                        diagTestTypeIds={[1, 2, 3]}
                        isDisabled={selectedSubject.value == "0" ? true : false}
                      ></Assignment>
                    </div>

                    <div className="flex flex-col">
                      <div>
                        <label className="block text-sm text-gray-500 mb-1">
                          Class <Asterisk />
                        </label>
                      </div>

                      <Select
                        value={selectedClass}
                        options={Allclasses}
                        isClearable
                        isSearchable
                        getOptionValue={(value) => {
                          return value.label;
                        }}
                        onChange={handleClassChange}
                        isMulti={false}
                        closeMenuOnSelect={true}
                        isDisabled={
                          selectedAssignment.value === "0" ? true : false
                        }
                      />
                    </div>
                  </div>
                  <div className="order-1 1xl:order-last w-full xl:w-3/4 1xl:w-[400px] 2xl:w-[620px] flex-none bg-gray-50 p-3 mb-auto">
                    <ul className="text-gray-500 text-sm flex flex-col gap-y-1">
                      <li className="flex items-start gap-x-1.5">
                        <span className="w-4 h-4 flex-none inline-block bg-gray-300 mt-1"></span>
                        Activity has not been started
                      </li>
                      <li className="flex items-start gap-x-1.5">
                        <span className="w-4 h-4 flex-none inline-block bg-yellow-500 mt-1"></span>
                        In Progress (Student has started the activity and is
                        currently in progress)
                      </li>
                      <li className="flex items-start gap-x-1.5">
                        <span className="w-4 h-4 flex-none inline-block bg-blue-500 mt-1"></span>
                        Suspended (Teacher has Saved and Exited the activity for
                        the Student(s)
                      </li>
                      <li className="flex items-start gap-x-1.5">
                        <span className="w-4 h-4 flex-none inline-block bg-red-500 mt-1"></span>
                        Discarded (Teacher has Discarded and Exited the activity
                        for the Student(s)
                      </li>
                      <li className="flex items-start gap-x-1.5">
                        <span className="w-4 h-4 flex-none inline-block bg-green-500 mt-1"></span>
                        Completed/Submitted (Student has completed the activity)
                      </li>
                    </ul>
                    <span className="inline-block text-gray-700 flex mt-2">
                      <span className="text-red-500 mr-1">*</span> Actions will
                      require you to select the checkbox next to the applicable
                      student(s) name.
                    </span>
                  </div>
                </div>

                <div className="flex flex-col lg:flex-row items-end justify-between mt-4">
                  <div>
                    Live Monitoring is currently available for assignments
                    started and completed in English only
                  </div>
                  <div className="flex mt-4 lg:mt-0 gap-4 justify-end">
                    <button
                      className="bg-transparent border my-auto border-primary-violet shadow-sm py-2 px-4 flex justify-center text-sm font-medium text-primary-violet hover:text-white hover:bg-light-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3 transition ease-in-out duration-500"
                      onClick={onReset}
                    >
                      Reset
                    </button>
                    <div className="cursor-not-allowed">
                      <button
                        className={
                          "bg-primary-violet whitespace-nowrap border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 " +
                          (selectedSubject.value !== "0" &&
                          selectedAssignment?.value !== "0" &&
                          selectedClass?.value !== "0"
                            ? "hover:bg-dark-violet hover:shadow-lg"
                            : "bg-opacity-20 text-primary-violet text-opacity-40 pointer-events-none")
                        }
                        disabled={selectedClass.value == "0" ? true : false}
                        onClick={onRunReport}
                      >
                        Populate Activity
                      </button>
                    </div>
                  </div>
                </div>
                <div className="border-b border-gray-400 py-2 mb-2"></div>
                {showLoader && <Loader></Loader>}
                {noRecordFoundMsg}
                {showReport && (
                  <div className="bg-white py-5 px-5 pt-2 mt-4">
                    <div className="mb-4 pb-4 border-b-2 overflow-x-auto xl:overflow-x-visible">
                      <LearnosityLiveMonitoring
                        activityId={
                          reportActivityData?.learnositySessionGroupIdEn?.toString() ??
                          ""
                        }
                        activityName={
                          reportActivityData?.activityName as string
                        }
                        userContext={props.userContext}
                        users={reportUserData}
                        profile={props.profile}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {showUpgradePopup && (
        <UpGradeMessagePopup
          togglePopup={setShowUpgradePopup}
          roleId={props.userContext?.roleId ?? 0}
          message={constant.UPGRADE_MESSAGE_SCHOOL_ACCOUNT}
        />
      )}
    </AllowedTo>
  );
}

export default connect((state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
})(LiveMonitoringReport);

import { XIcon } from "@heroicons/react/solid";
import { Fragment, useState } from "react";
import InfoMessage from "../../../../../shared/infoMessage";
import DatePicker from "react-datepicker";
import moment from "moment";
import { IStudent } from "../../../../../../model/interface/student";
import { addRemediation } from "../../../../../../api/teacher/assignment";
import Constant from "../../../../../../utils/constant/constant";
import StudentRemediation from "../../../../../../model/teacher/assignment/studentRemediation";
import Loader from "../../../../../shared/loader";
import arraySort from "array-sort";
import MessagePopup from "../../../../../shared/messagePopup";
interface CreateRemediationProps {
  teacherId: number;
  subjectId: number;
  standardId: number;
  standardName: string;
  stadardCode: string;
  classId: number;
  togglePopup: (popupAction: boolean) => void;
  isClassRemediation: boolean;
  students: Array<IStudent>;
}

function ProgressReportRemediation(props: CreateRemediationProps) {
  const {
    teacherId,
    subjectId,
    standardId,
    standardName,
    stadardCode,
    classId,
    togglePopup,
    isClassRemediation,
    students,
  } = props;
  const [selectedStartDate, setStartDate] = useState<Date>();
  const [selectedDueDate, setDueDate] = useState<Date>();
  const [selectedStudents, setSelectedStudents] = useState<Array<IStudent>>([]);
  const [toggleSelectAll, setToggleSelectAll] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [startDateCorrect, setStartDateCorrect] = useState<boolean>(true);
  const [dueDateCorrect, setDueDateCorrect] = useState<boolean>(true);
  const [errorMessagePopup, setErrorMessagePopup] = useState<boolean>(false);

  function handleAssignClick() {
    if (!selectedStartDate || !selectedDueDate) {
      return false;
    }

    if (selectedStartDate > selectedDueDate) {
      return false;
    }

    const studentsForRemediate: Array<IStudent> = isClassRemediation
      ? selectedStudents
      : students;
    const studentRemediations: Array<StudentRemediation> = [];
    studentsForRemediate.map((student) => {
      const studentRemediation: StudentRemediation = new StudentRemediation(
        student.studentId,
        student.name,
        classId,
        [{ id: standardId, name: standardName }]
      );
      studentRemediations.push(studentRemediation);
    });

    if (
      selectedStartDate &&
      selectedDueDate &&
      studentRemediations.length > 0
    ) {
      const startDate = selectedStartDate.toISOString().slice(0, -1);
      const endDate = new Date(
        selectedDueDate.getFullYear(),
        selectedDueDate.getMonth(),
        selectedDueDate.getDate(),
        23,
        59,
        59
      )
        .toISOString()
        .slice(0, -1);

      setLoading(true);
      addRemediation(
        teacherId,
        Constant.RemediationSource.PROGRESSREPORT,
        subjectId,
        isClassRemediation,
        studentRemediations,
        startDate,
        endDate
      ).then(() => {
        setLoading(false);
        togglePopup(false);
      });
    }
  }

  function handleStartDate(date: any) {
    if (selectedDueDate && date > selectedDueDate) {
      setStartDateCorrect(false);
      setErrorMessagePopup(true);
    } else {
      setStartDateCorrect(true);
      setDueDateCorrect(true);
    }

    setStartDate(date);
  }

  function handleDueDate(date: any) {
    if (selectedStartDate && date < selectedStartDate) {
      setDueDateCorrect(false);
      setErrorMessagePopup(true);
    } else {
      setStartDateCorrect(true);
      setDueDateCorrect(true);
    }

    setDueDate(date);
  }

  function handleSelectAll() {
    const updatedValue: boolean = !toggleSelectAll;

    let updatedStudents: Array<IStudent> = [];
    if (updatedValue) {
      updatedStudents = students;
    }

    setSelectedStudents(updatedStudents);
    setToggleSelectAll(updatedValue);
  }

  function handleSelectStudent(student: IStudent) {
    const studentExist = selectedStudents.some(
      (r) => r.studentId === student.studentId
    );

    let updatedStudents: Array<IStudent> = [];
    if (studentExist) {
      updatedStudents = selectedStudents.filter(
        (r) => r.studentId !== student.studentId
      );
    } else {
      updatedStudents = [...selectedStudents, student];
    }

    setSelectedStudents(updatedStudents);
  }

  return (
    <Fragment>
      {errorMessagePopup && (
        <MessagePopup
          message={"Invalid Date"}
          togglePopup={setErrorMessagePopup}
        />
      )}
      {loading && <Loader />}
      <div className="fixed inset-0 z-40 flex items-center bg-black bg-opacity-50 sm:items-center sm:justify-center">
        <div
          className="w-1/2 flex flex-col justify-between h-auto max-h-80percent bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:max-w-6xl"
          role="dialog"
        >
          <header className="px-4 py-4 bg-gray-50 rounded-t-lg flex-none h-14">
            {/* Header */}
            <div className="flex items-start justify-between space-x-3">
              <div className="space-y-1">
                <div className="text-lg font-medium text-gray-900">
                  Assign Additional Practice
                </div>
              </div>
              <div className="h-7 flex items-center">
                <button
                  type="button"
                  className="text-gray-400 hover:text-gray-500"
                  onClick={() => togglePopup(false)}
                >
                  <span className="sr-only">Close</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
            </div>
          </header>
          <div id="remeditionpopup" className="flex flex-col w-full relative px-4 py-1 overflow-y-auto flex-1">
            <div className="flex-none">
              {`(${stadardCode}) ${standardName}`}
              {}
              <InfoMessage
                  message={`${
                      isClassRemediation
                          ? "Select students to assign targeted remediation. Remediation is available for students with an average of less than 80%. You can view these assignments in ‘My Assignments’ from your Assessment/Assignment Center screen under the Remediation Assignments menu option."
                          : "Assign targeted remediation for the selected student. You can view these assignments in ‘My Assignments’ from your Assessment/Assignment Center screen under the Remediation Assignments menu option."
                  }`}
              />
            </div>
            <div className="flex-none gap-8 text-base font-medium text-gray-700 mb-2">
              Assignment Dates
            </div>
            <div className="flex-none gap-8">
              <div>
                <label className="block text-sm text-gray-500">
                  Start Date
                </label>
                <DatePicker
                  className={`react-datepicker__input-container mt-1 py-1.5 px-3 block w-full shadow-sm focus:ring-primary-violet focus:border-primary-violet border border-${
                    startDateCorrect ? "gray-300" : "red-500"
                  } rounded-md`}
                  selected={selectedStartDate}
                  onChange={(date) => handleStartDate(date)}
                  minDate={moment().toDate()}
                />
              </div>
              <div className="relative">
                <label className="block text-sm text-gray-500">Due Date</label>
                <DatePicker
                  className={`react-datepicker__input-container mt-1 py-1.5 px-3 block w-full shadow-sm focus:ring-primary-violet focus:border-primary-violet border border-${
                    dueDateCorrect ? "gray-300" : "red-500"
                  } rounded-md`}
                  selected={selectedDueDate}
                  onChange={(date) => handleDueDate(date)}
                  minDate={moment().toDate()}
                />
              </div>
            </div>
            <div className="flex-none gap-8 text-base font-medium text-gray-700 mt-4">
              {`${isClassRemediation ? "Students" : "Student"}`}
            </div>
            <div className="flex-1 gap-8 mt-2 border rounded-md p-2 max-h-[10rem] overflow-y-auto min-h-5rem">
              <div>
                {students && students.length > 0 && !isClassRemediation && (
                  <div className="flex">{`${students[0].name}`}</div>
                )}

                {isClassRemediation && (
                  <div className="flex text-sm font-medium text-gray-700 mb-2 bg-gray-100 py-1 px-2">
                    <input
                      type="checkbox"
                      onChange={() => handleSelectAll()}
                      className="mr-2 cursor-pointer focus:ring-primary-violet h-4 w-4 text-primary-violet border-gray-500"
                    />
                    Select All
                  </div>
                )}

                {students &&
                  students.length > 0 &&
                  isClassRemediation &&
                  arraySort(students, "name").map((student) => {
                    return (
                      <div className="flex items-center px-2 mb-1">
                        <input
                          type="checkbox"
                          checked={selectedStudents.some(
                            (r) => r.studentId === student.studentId
                          )}
                          onChange={() => handleSelectStudent(student)}
                          className="mr-2 cursor-pointer focus:ring-primary-violet h-4 w-4 text-primary-violet border-gray-500"
                        />
                        {student.name}
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
          <footer className="flex-none items-center justify-end px-4 py-4 sm:flex-row bg-gray-50 dark:bg-gray-800 mt-4 rounded-b-lg">
            <div className="space-x-3 flex justify-end">
              <button
                onClick={() => handleAssignClick()}
                className="bg-primary-violet border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-dark-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3"
              >
                Assign
              </button>

              <button
                onClick={() => togglePopup(false)}
                className="bg-primary-violet border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-dark-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3"
              >
                Close
              </button>
            </div>
          </footer>
        </div>
      </div>
    </Fragment>
  );
}

export default ProgressReportRemediation;

import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { RouteComponentProps } from "react-router";
import { LogoIcon } from "../../../assets/icons/index";
import { Configuration } from "../../../environment/setup";
import { updatePassword, validateRecoveryCode } from "./api";

interface ChangePasswordParams {
  recoveryCode: string;
}
function ChangePassword(props: RouteComponentProps<ChangePasswordParams>) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [recoveryCode, setRecoveryCode] = useState(
    props.match.params.recoveryCode
  );
  const [linkExpired, setLinkExpired] = useState(false);
  const [loading, setLoading] = useState(true);

  const passwordRef = useRef({});
  passwordRef.current = watch("password", "");

  function handlePasswordChange(e: ChangeEvent<HTMLInputElement>) {
    setPassword(e.target.value);
  }

  function handleConfirmPasswordChange(e: ChangeEvent<HTMLInputElement>) {
    setConfirmPassword(e.target.value);
  }

  function handleOnSubmit() {
    updatePassword(recoveryCode, password)?.then((res) => {
      if (res.data) {
        setShowSuccessMessage(true);
        setPassword("");
        setConfirmPassword("");
        setErrorMessage("");
        setRecoveryCode("");
      }
    });
  }

  useEffect(() => {
    if (!showSuccessMessage) {
      validateRecoveryCode(recoveryCode)?.then((res) => {
        setLoading(false);
        if (!res.data) setLinkExpired(true);
        else setLinkExpired(false);
      });
    }
  }, []);

  return (
    <>
      {!loading && !linkExpired && (
        <form onSubmit={handleSubmit(handleOnSubmit)}>
          <div className="min-h-screen bg-white flex">
            <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
              <div className="mx-auto w-full max-w-sm lg:w-96">
                <div>
                  <LogoIcon />
                  <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
                    Password Recovery
                  </h2>
                  <p>Specify your new password and then confirm it.</p>
                </div>

                <div className="mt-8">
                  <div className="mt-6">
                    <div className="space-y-6">
                      <div>
                        <label className="block text-sm font-medium text-gray-700">
                          New Password:
                        </label>
                        <div className="mt-1">
                          <input
                            value={password}
                            type="password"
                            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            {...register("password", {
                              onChange: handlePasswordChange,
                              required: "You must specify a password",
                              minLength: {
                                value: 8,
                                message:
                                  "Password must have at least 8 characters",
                              },
                              pattern: {
                                value:
                                  /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,50}$/,
                                message:
                                  "Password must contain: Minimum 8 characters atleast 1 Alphabet,1 Special Character and 1 Number.",
                              },
                            })}
                          />
                          <small className="text-red-400">
                            {errors.password && (
                              <span>{errors.password.message}</span>
                            )}
                          </small>
                        </div>
                      </div>
                      <div>
                        <label className="block text-sm font-medium text-gray-700">
                          Confirm New Password:
                        </label>
                        <div className="mt-1">
                          <input
                            value={confirmPassword}
                            type="password"
                            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            {...register("confirmPassword", {
                              onChange: handleConfirmPasswordChange,
                              validate: (value) =>
                                value === passwordRef.current ||
                                "The passwords do not match",
                            })}
                          />
                          <small className="text-red-400">
                            {errors.confirmPassword && (
                              <span>{errors.confirmPassword.message}</span>
                            )}
                          </small>
                        </div>
                      </div>
                      <div>
                        <button
                          type="submit"
                          className="mb-5 w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-green hover:bg-primary-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                          Set New Password
                        </button>
                        <span className="text-red-500">{errorMessage}</span>
                        {showSuccessMessage && (
                          <span className="text-green-500">
                            You have succesfully changed your password. Now you
                            can log in using your username and new password.
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="hidden lg:block relative w-0 flex-1 bg-gradient-to-l from-sky-800 to-cyan-700 mix-blend-multiply">
              <img
                className="absolute inset-0 h-full w-full object-cover bg-gradient-to-l from-sky-800 to-cyan-700 mix-blend-multiply"
                src={`${Configuration.S3bucketImagePath}/images/login-background.png`}
                alt=""
              />
            </div>
          </div>
        </form>
      )}
      {!loading && linkExpired && (
        <div className="min-h-screen bg-white flex">
          <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
            <div className="mx-auto w-full max-w-sm lg:w-96">
              <div>
                <LogoIcon />
                <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
                  Link Expired
                </h2>
                <p>
                  Sorry, this link has expired.{" "}
                  <p>
                    The content you were looking for is no longer available. You
                    can reset password again with Forgot Password option.
                    <br />
                    <br />
                    <p>
                      Please contact the administrator for further assistance or
                      if you have any questions.
                    </p>
                  </p>
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ChangePassword;

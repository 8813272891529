import React from "react";
// import constant from "../../../utils/constant/constant";
import { AlienRacer } from "@educationgalaxy/alien-racer/lib";

interface GameProps {
  userrocket: any;
  opporocket: any;
  quizresult: any;
  username: any;
  userschool: any;
  opponentname: any;
  opponentschool: any;
  userInput: any;
  gameId: any;
  onGameComplete(data): void;
}

const AlienRacerGame = (props: GameProps) => {
  const {
    userrocket,
    opporocket,
    quizresult,
    username,
    userschool,
    opponentname,
    opponentschool,
    userInput,
    gameId,
    onGameComplete,
  } = props;

  return (
    <>
      <div className="w-[70vw] h-[220px] sm:h-[180px] md:h-[250px] lg:h-[300px] xl:h-[320px] 2xl:h-[400px] xxxl:h-[500px]">
        <AlienRacer
          rocketId={userrocket-1}//passing rocket index
          opponentrocketId={opporocket}
          quizResult={quizresult}
          userName={username}
          userSchool={userschool}
          oppoName={opponentname}
          oppoSchool={opponentschool}
          userInput={userInput}
          onGameComplete={function (data: { result: number[] }): void {
            console.log(data.result, gameId);
            onGameComplete(data.result);
            //throw new Error("Function not implemented.");
          }}
        />
      </div>
    </>
  );
};

export default AlienRacerGame;

import { connect } from "react-redux";
import moment from "moment";

function MasteryReportFilterPrint(props: any) {
    const {
        header,
        name,
        activity,
        author,
        subject,
        dueDate,
        dateCreated,
        isDistrictUser,
        selectedClasses,
        selectedSchool,
        averageScore,
        reportName
    } = props?.filter
    return (
        <div>
            <div className="w-full rounded-lg py-5 font-semibold text-2xl text-gray-700 xl:mb-8">
                <div className="px-2 mb-3 md:px-0 md:col-span-6 2xl:col-span-4 xxxl:col-span-3 space-y-3 text-align-right">
                    <span className="text-gray">
                        {reportName}
                    </span>
                </div>
                <div className="flex items-center justify-between bg-gray-100 py-2 text-gray-700 md:space-x-4 px-4">
                    <div className="px-2 md:px-0 md:col-span-6 2xl:col-span-4 xxxl:col-span-3 space-y-3">
                        {header} Average<span className="text-primary-violet "> {averageScore}%</span>
                    </div>
                    <div className="px-2 md:px-0 md:col-span-6 2xl:col-span-4 xxxl:col-span-3 space-y-3 text-align-right">
                        <span className="text-gray-600 text-xs">
                            Generated on {moment(new Date()).format("MM/DD/YYYY")}
                        </span>
                    </div>
                </div>
            </div>
            <div className="flex justify-between border rounded-lg p-5  text-gray-700 xl:space-x-4">
                <div className="px-2 mr-4 xl:mr-0 md:px-0 col-span-12 md:col-span-6 xl:col-span-4 2xl:col-span-4 xxxl:col-span-3 space-y-3">
                    <div className="flex w-full">
                        <div className="w-20 lg:w-40 font-semibold flex-none">Name</div>
                        <div>{name}</div>
                    </div>
                    <div className="flex w-full">
                        <div className="w-20 lg:w-40 font-semibold flex-none">Activity</div>
                        <div>{activity}</div>
                    </div>
                    <div className="flex w-full">
                        <div className="w-20 lg:w-40 font-semibold flex-none">Author</div>
                        <div>{author}</div>
                    </div>
                    <div className="flex w-full">
                        <div className="w-20 lg:w-40 font-semibold flex-none">Subject</div>
                        <div>{subject}</div>
                    </div>
                    <div className="flex w-full">
                        <div className="w-20 lg:w-40 font-semibold flex-none">Date Created</div>
                        <div className="flex flex-wrap">
                            <span className="bg-gray-100 rounded-lg inline-block px-2 mr-2">
                                {dateCreated
                                    ? moment(dateCreated).format("MM/DD/YYYY")
                                    : "N/A"}
                            </span>
                        </div>
                    </div>
                    <div className="flex w-full">
                        <div className="w-20 lg:w-40 font-semibold flex-none">Due Date</div>
                        <div>
                            {dueDate
                                ? moment(dueDate).format("MM/DD/YYYY")
                                : "N/A"}
                        </div>
                    </div>
                    <div className="flex w-full ">
                        <div className="w-20 lg:w-40 font-semibold flex-none">
                            {isDistrictUser ? "School" : "Class"}
                        </div>
                        <div>
                            {isDistrictUser
                                ? selectedSchool.length > 0
                                    ? selectedSchool.toString()
                                    : "All"
                                : selectedClasses.length > 0
                                    ? selectedClasses.toString()
                                    : "All"
                            }
                        </div>
                    </div>
                </div>
                <div className="col-span-12 xl:order-none mb-4 xl:mb-0 xl:col-span-4 2xl:col-span-3 xxxl:col-span-5 relative">
                    <div className="flex gap-1 flex-col bg-gray-100 p-3 text-gray-500 2xl:ml-2 h-full rounded-md">
                        <p>The Dot Rank contains cumulative data based on all questions answered within the activity.</p>
                        <div className="flex gap-1 flex-col bg-white p-1 mr-auto">
                            <p className="flex items-center">
                                <span className="w-3.5 h-3.5 rounded-full bg-[#9e9e9e] border border-black inline-block mr-1">
                                </span>Gray dot: No questions answered
                            </p>
                            <p className="flex items-center">
                                <span className="w-3.5 h-3.5 rounded-full bg-[#e53f71] border border-black inline-block mr-1">
                                </span>
                                Pink dot: &lt; 65%
                            </p>
                            <p className="flex items-center">
                                <span className="w-3.5 h-3.5 rounded-full bg-[#f4d221] border border-black inline-block mr-1">
                                </span>
                                Yellow dot: 65-79%
                            </p>
                            <p className="flex items-center">
                                <span className="w-3.5 h-3.5 rounded-full bg-[#a3b938] border border-black inline-block mr-1">
                                </span>
                                Green dot: &gt;= 80%
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default connect((state: any) => {
    return {
        userContext: state.userContext,
        profile: state.profile,
    };
})(MasteryReportFilterPrint);

import { XIcon } from "@heroicons/react/solid";
import { Fragment, useState } from "react";
import ReactTooltip from "react-tooltip";
interface ICompareAssessment {
  name?: string;
  Id?: number;
}
interface CompareAssessmentPopupProps {
  onCancelClick: () => void;
  handleCompareSelected: (assessmentId: number) => void;
  setSelectedAssessmentToCompare: (assementId: number) => void;
  assessmentToCompare?: ICompareAssessment;
}

const defaultAssessmets = [
  {
    id: 1,
    name: "Beginning of the Year Diagnostic (WEJEGUTATA)",
  },
  {
    id: 2,
    name: "Chemistry Diagnostic (MUNULUZABA)",
  },
  {
    id: 3,
    name: "Practice Saving 1 (SUKAMOMODA)",
  },
  {
    id: 4,
    name: "Bonus Questions (GUYAKEYOXA)",
  },
  {
    id: 5,
    name: "tryday assessment (JABURESONU)",
  },
  {
    id: 6,
    name: "tryday assessment 2 (NESOMEWORO)",
  },
];
function CompareAssessmentPopup(props: CompareAssessmentPopupProps) {
  const { assessmentToCompare, onCancelClick, handleCompareSelected } = props;

  const [selectedAssessmentId, setSelectedAssessmentId] = useState<number>(0);
  return (
    <Fragment>
      <ReactTooltip
        multiline={true}
        className="bg-secondary-teal"
        backgroundColor="#008fbb"
      />
      <div className="fixed inset-0 z-40 flex items-center bg-black bg-opacity-50 sm:items-center sm:justify-center">
        <div
          className="w-full flex flex-col justify-between overflow-hidden h-auto max-h-80percent bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:max-w-5xl"
          role="dialog"
        >
          <header>
            {/* Header */}
            <div className="px-4 py-4 bg-gray-50">
              <div className="flex items-start justify-between space-x-3">
                <div className="space-y-1">
                  <div className="text-lg font-medium text-gray-900">
                    Choose Assessment to compare to{" "}
                    <span className="text-primary-violet">
                      {assessmentToCompare?.name}
                    </span>
                  </div>
                </div>
                <div className="h-7 flex items-center">
                  <button
                    type="button"
                    className="text-gray-400 hover:text-gray-500"
                    onClick={() => onCancelClick()}
                  >
                    <span className="sr-only">Close</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
          </header>
          <div className="w-full relative overflow-auto px-4 py-1">
            <form>
              <div>
                <div className="pt-8 px-4 sm:py-6 ">
                  <div className="space-y-6 sm:space-y-5">
                    <div>
                      <div role="group" aria-labelledby="label-notifications">
                        <div className="sm:grid sm:grid-cols-4 sm:gap-4 sm:items-baseline">
                          {defaultAssessmets.map((item, index) => {
                            return (
                              <div
                                key={index}
                                className="flex col-span-8 sm:col-span-4 xl:col-span-2 gap-4"
                              >
                                <label
                                  x-radio-group-option=""
                                  className=" relative flex cursor-pointer focus:outline-none border-gray-400"
                                >
                                  <input
                                    type="radio"
                                    x-model="value"
                                    name="assignment"
                                    value="Public access"
                                    className="h-4 w-4 cursor-pointer text-secondary-teal border-gray-300 focus:ring-secondary-teal"
                                    aria-labelledby="privacy-setting-0-label"
                                    aria-describedby="privacy-setting-0-description"
                                    onClick={() =>
                                      setSelectedAssessmentId(item.id)
                                    }
                                  />
                                  <div className="ml-3 flex flex-col">
                                    <span
                                      id="privacy-setting-0-label"
                                      className="block text-sm text-gray-500"
                                    >
                                      {item.name}
                                    </span>
                                  </div>
                                </label>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>

          {/* <!-- Action Buttons -->  */}
          <footer className="flex items-center justify-end px-4 py-4 sm:flex-row bg-gray-50 dark:bg-gray-800 mt-4">
            <div className="space-x-3 flex justify-end">
              <button
                onClick={() => onCancelClick()}
                className="align-middle inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 rounded-lg text-sm text-white border border-transparent bg-secondary-teal hover:bg-dark-teal focus:shadow-indigo-500 w-full sm:w-auto"
              >
                Close
              </button>
              <button
                onClick={() => handleCompareSelected(selectedAssessmentId)}
                className="align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 rounded-lg text-sm text-gray-600 border-gray-300 border dark:text-gray-400  active:bg-transparent hover:border-gray-500 focus:border-gray-500 active:text-gray-500 focus:shadow-outline-gray w-full sm:w-auto"
              >
                Compare Selected
              </button>
            </div>
          </footer>
        </div>
      </div>
    </Fragment>
  );
}

export default CompareAssessmentPopup;

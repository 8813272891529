import { XIcon } from "@heroicons/react/outline";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ClassApi } from "../../../../../api/teacher/classManagementApi";
import ClassStudentModel from "../../../../../model/teacher/classManagement/classStudentModel";
import { getNameLabel } from "../../../../../utils/helper";
import InfoMessage from "../../../../shared/infoMessage";
import classNames from "classnames";

interface ClassStudentPopupProps {
  classId: number;
  subjectId: number;
  schoolId: number;
  sourceClassName: string;
  selectStudentsToExport: (selectedStudents: Array<number>) => void;
  showPopUp: (show: boolean) => void;
}

const ClassStudentPopup: React.FC<ClassStudentPopupProps> = (props) => {
  const [selectAll, setSelectAll] = useState(false);
  const [students, setClassStudent] = useState<Array<ClassStudentModel>>();
  const [selectStudentToAdd, setSelectStudentToAdd] = useState<Array<number>>(
    []
  );
  const [isDisabled, setIsDisabled] = useState<boolean>(true);

  useEffect(() => {
    getClassStudent();
  }, []);

  function getClassStudent(): void {
    ClassApi.getClassStudentForDiagnostics(
      props.classId,
      props.subjectId,
      props.schoolId
    ).then((d) => {
      let students = d.data?.map((student, index) => {
        if (selectStudentToAdd.indexOf(student.userId) > -1) {
          student.isSelected = true;
        }
        return student;
      });
      d.data = students;
      setClassStudent(d.data);
    });
  }

  function selectStudent(userId: any) {
    const student = students?.find((e) => e.userId === userId);
    if (student) {
      student.isSelected =
        student.isSelected === undefined ? true : !student.isSelected;
    }
    if (selectStudentToAdd.includes(userId)) {
      setSelectStudentToAdd(selectStudentToAdd.filter((ds) => ds !== userId));
    } else {
      const arr = selectStudentToAdd;
      arr.push(userId);
      setSelectStudentToAdd(arr);
    }
    setIsDisabled(getSelectedStudents().length === 0);
  }

  function selectAllStudent(e): void {
    if (e.target.checked) {
      let arr =
        students?.filter(s => s.hasDiagnosticsCompleted).map((s) => {         
            s.isSelected = e.target.checked;
            return s.userId;                
        }) || [];
      setSelectStudentToAdd(arr);
    }
    setSelectAll(!selectAll);
    setIsDisabled(selectAll);
  }

  function getSelectedStudents() {
    if (students) {
      const selectedStudents = students?.filter((e) => {
        return e.isSelected === true;
      });

      const studentIdsToAdd = selectedStudents
        ?.filter((s) => {
          return selectStudentToAdd.indexOf(s.userId) === -1;
        })
        .map((s) => {
          return s.userId;
        });
      selectStudentToAdd.concat(studentIdsToAdd);
      setSelectStudentToAdd(selectStudentToAdd);
      return selectStudentToAdd;
    }
    return [];
  }

  function exportStudents() {
    if (setSelectStudentToAdd.length === 0) {
      toast.warning("Please select a destination class to transfer students");
    } else {
      props.selectStudentsToExport(selectStudentToAdd);
      props.showPopUp(false);
    }
  }
  return (
    <div className="fixed inset-0 z-40 flex items-center bg-black bg-opacity-50 sm:items-center sm:justify-center">
      <div
        className="w-full flex flex-col justify-between overflow-hidden h-auto max-h-80percent bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:max-w-5xl"
        role="dialog"
      >
        <header>
          {/* Header */}
          <div className="px-4 py-4 bg-gray-50">
            <div className="flex items-start justify-between space-x-3">
              <div className="space-y-1">
                <div className="text-lg font-medium text-gray-900">
                  Class Students
                </div>
              </div>
              <div className="h-7 flex items-center">
                <button
                  type="button"
                  className="text-gray-400 hover:text-gray-500"
                  onClick={() => props.showPopUp(false)}
                >
                  <span className="sr-only">Close panel</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </header>
        <div className="w-full relative overflow-auto px-4 py-4">
            <InfoMessage
              className="-mt-2"
              message={
                "Select to print Student Diagnostics Strengths and Weaknesses Report by class or student. Each student report shall be printed separately. The file can also be exported to PDF by selecting ‘Print‘.Both the Pre-test and Post-test results shall be printed only if data exists."
              }
            ></InfoMessage>
            <h1 className="uppercase text-gray-700 mt-6 mb-2">
              Individual Summaries
            </h1>
            <div className="bg-gray-100 px-2 mb-1">
              <label
                x-radio-group-option=""
                className=" relative  py-2 flex cursor-pointer focus:outline-none border-gray-400 z-10"
              >
                <input
                  type="checkbox"
                  x-model="value"
                  name="privacy-setting"
                  className="h-4 w-4 mt-0.5 cursor-pointer text-primary-violet border-gray-300 focus:ring-primary-violet"
                  checked={selectAll}
                  onChange={(e) => {
                    selectAllStudent(e);
                  }}
                />
                <div className="ml-3 flex flex-col">
                  <span
                    id="privacy-setting-0-label"
                    className="text-sm font-medium text-primary-violet focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3"
                  >
                    Select All
                  </span>
                </div>
              </label>
            </div>
            <div className="grid grid-cols-4 ml-2">
              {(students?.length??0) > 0 &&
                students?.map((student, i) => (
                  <label
                    x-radio-group-option=""
                    className={`relative  py-2 flex focus:outline-none border-gray-400 z-10 
                    ${student.hasDiagnosticsCompleted ? "cursor-pointer" : "cursor-not-allowed"}`}
                    key={i}
                  >
                    <input
                     type="checkbox"
                     key={student.userId}
                     defaultChecked={student.isSelected}
                     disabled={!student.hasDiagnosticsCompleted}
                     checked={selectAll ? student.isSelected : undefined}
                     onChange={() => {
                       selectStudent(student.userId);
                     }}
                    />
                    <div className="ml-3 flex flex-col">
                      <span
                        id="privacy-setting-0-label"
                        className={`text-sm font-medium text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3
                        ${student.hasDiagnosticsCompleted ? "" : "cursor-not-allowed text-gray-700"}`
                      }
                      >
                        {getNameLabel(student.lastName, student.firstName)}
                      </span>
                    </div>
                  </label>
                ))}
            </div>
          </div>      

        {/* <!-- Action Buttons -->  */}
        <footer className="flex items-center justify-end px-4 py-4 sm:flex-row bg-gray-50 dark:bg-gray-800 mt-4">
          <div className="space-x-3 flex justify-end">
            <button
              className={classNames(
                "disabled:opacity-50 align-bottom inline-flex items-center justify-center leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 rounded-lg text-sm text-white border border-transparent bg-primary-green focus:shadow-outline-purple w-full sm:w-auto",
                isDisabled ? "cursor-not-allowed" : "cursor-pointer"
              )}
              type="button"
              onClick={exportStudents}
              disabled={isDisabled}
            >
              {" "}
              Export/Print
            </button>
            <button
              className="align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 rounded-lg text-sm text-gray-600 border-gray-300 border dark:text-gray-400 focus:outline-none active:bg-transparent hover:border-gray-500 focus:border-gray-500 active:text-gray-500 focus:shadow-outline-gray w-full sm:w-auto"
              type="button"
              onClick={() => {
                props.showPopUp(false);
              }}
            >
              Cancel
            </button>
          </div>
        </footer>
      </div>
    </div>
  );
};
export default ClassStudentPopup;

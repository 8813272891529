import { UserContextState } from "../../../../../redux/actions/userContextAction";
import { connect } from "react-redux";
import Profile from "../../../../../model/users/profile";
import { Fragment, useEffect, useState } from "react";
import QuestionAnswer from "../../shared/questionAnswer";
import BeginActivity from "../../shared/beginActivity";
import Constant from "../../../../../utils/constant/constant";
import RouteConstant from "../../../../../utils/constant/routeConstant";
import { useLocation } from "react-router-dom";
import {
  initializeStudentAssignmentActivity,
  completeStudentAssignmentActivity,
  updateStudentAssignmentActivity,
  getStudentAssignmentLastSession,
  validateAssignmentMaxAttempt,
  getRetryEligibility,
  abandonAssignmentActivity,
} from "../../../../../api/student/studentActivityApi";
import { useHistory } from "react-router-dom";
import {
  getAssignmentActivityStandardsById,
  getAssignmentById,
  getAssignmentNoteById,
} from "../../../../../api/teacher/assignment";
import VideoMessagePopup from "../../shared/popups/videoMessagePopup";
import TextMessagePopup from "../../shared/popups/textMessagePopup";
import {
  hasSectionsGetByCannedtestTypeId,
  getBeginActivityType,
  GetIsQuestionRetryAllowed,
  GetShowExplanation,
  GetShowOnlyTextExpalanation,
} from "../../../../../utils/helper";
import { getCalculatorBySubjectId } from "../../../../../api/student/studentSubjectApi";
import { getStudentProfile } from "../../../../../api/student/studentProfile";
import StudentProfile from "../../../../../model/student/studentProfile";
import constant from "../../../../../utils/constant/constant";
import routeConstant from "../../../../../utils/constant/routeConstant";
import InformationDialog from "../../../../shared/informationDialog";
import { useTranslation } from "react-i18next";
import { IsActivitySessionQuestionAttempted } from "../../../../../api/student/activitySession/activitySessionQuestions";
import { paramDecode } from "../../../../../utils/urlHelper";
import { IQuestionStates } from "../../../../../model/interface/questionState";
import { IRetryMissedItemState } from "../../../../../model/interface/activitySession/retryAssignment";
import { IQuestionAnswerProps } from "../../../../../model/interface/questionAnswer/questionAnswer";

interface IStudentAssignmentActivity {
  userContext: UserContextState;
  profile: Profile;
  match: any;
  location: any;
}

type IInitializeStudentAssignmentActivityResponse = {
  hasPendingSession: boolean;
  sessionId: string;
  questionIds: Array<string>;
  activityId: string;
  calculatorReferenceId?: string;
  activitySessionId: number;
  languageId: number;
  cannedTestId?: number;
  isDisplayRandomQuestion?: boolean;
  isCombinedActivity?: boolean;
  isTTSActive?: boolean | null;
  subjectId: number;
  studentGameId: number;
  questionStates: Array<IQuestionStates>;
  learnositySessionGroupId: string;
};

function StudentAssignmentActivity(props: IStudentAssignmentActivity) {
  const [studentAssigmentActivity, setStudentAssigmentActivity] =
    useState<IInitializeStudentAssignmentActivityResponse>();
  const assignmentActivityId = parseInt(
    atob(props.match.params.assignmentActivityId)
  );
  const assignmentActivityName = paramDecode(
    props.match.params.assignmentActivityName
  );
  const assignmentId = parseInt(atob(props.match.params.assignmentId));
  const location = useLocation<any>();
  const { redirectedFromLO } = location?.state;

  const lernosityActivityIdEN = atob(props.match.params.lernosityActivityIdEN);
  const lernosityActivityIdES = atob(props.match.params.lernosityActivityIdES);
  const assignmentType = atob(props.match.params.assignmentType);

  const subjectId = parseInt(atob(props.match.params.subjectId));
  const allowMultipleAttempts = Boolean(
    atob(props.match.params.multipleAttemptAllowed)
  );
  const prebuildTestType = props.location?.state?.prebuildTestType ?? "";
  const gameSettings = location?.state?.gameSettings ?? null;
  const [beginActivityModal, setBeginActivityModal] = useState<any>({
    open: false,
    data: undefined,
  });
  let activitySessionId: string = "";

  const [questionAttemptedTillTimeLimit, setQuestionsAttemptedTillTimeLimit] =
    useState<number>(0);
  const [timeInMinutes, setSelectedTimeInMinutes] = useState<number>(0);
  const [showMaxAttemptReached, setShowMaxAttemptReached] = useState(false);
  const history = useHistory();
  let classId: any = undefined;
  let teacherId: any = undefined;
  let languageId: any = undefined;
  let studentType: any = Constant.StudentType.EL;
  const { t } = useTranslation();

  const isCannedTest =
    atob(props.match.params.lernosityActivityIdEN).lastIndexOf("CannedTest") ===
    -1
      ? false
      : true;

  if (location.pathname.toLowerCase().indexOf("kindergarten") > -1)
    studentType = Constant.StudentType.K1;
  else if (location.pathname.toLowerCase().indexOf("middleschool") > -1)
    studentType = Constant.StudentType.MS;

  const redirectUrl =
    location.pathname.toLowerCase().indexOf("kindergarten") > -1
      ? RouteConstant.KindergartenStudentRoutes.Assignment
      : location.pathname.toLowerCase().indexOf("elementary") > -1
      ? RouteConstant.StudentRoute.Home
      : location.pathname.toLowerCase().indexOf("middleschool") > -1
      ? RouteConstant.MiddleSchool.Home
      : "";

  const resultUrl =
    location.pathname.toLowerCase().indexOf("kindergarten") > -1
      ? RouteConstant.KindergartenStudentRoutes.AssignmentResult
      : location.pathname.toLowerCase().indexOf("elementary") > -1
      ? RouteConstant.StudentRoute.AssignmentResult
      : location.pathname.toLowerCase().indexOf("middleschool") > -1
      ? RouteConstant.MiddleSchool.AssignmentResult
      : "";
  const theme =
    location.pathname.toLowerCase().indexOf("kindergarten") > -1
      ? "KINDERGARTEN"
      : location.pathname.toLowerCase().indexOf("elementary") > -1
      ? "ELEMENTARYHOME"
      : location.pathname.toLowerCase().indexOf("middleschool") > -1
      ? "MIDDLESCHOOLHOME"
      : "ELEMENTARYHOME";

  const [isQuestionRetry, setQuestionRetry] = useState<boolean>(false);
  const [isAllowMissedRetry, setAllowMissedRetry] = useState<boolean>(false);
  const [showExpalanation, setShowExplanation] = useState<boolean>(false);
  const [showOnlyTextExpalanation, setShowOnlyTextExplanation] =
    useState<boolean>(false);
  const [calculatorReferenceId, setCalculatorReferenceId] = useState<string>();

  const [assignmentData, setAssignmentData] = useState<any>({});
  const [showVideoMessage, setShowVideoMessage] = useState<any>("");
  const [showTextMessage, setShowTextMessage] = useState<any>("");
  const [studentProfile, setStudentProfile] = useState<StudentProfile>();
  const [updateActivity, setUpdateActivity] = useState<Boolean>(false);
  const [assignmentName, setAssignmentName] = useState<string>("");
  const [code, setAssessmentCode] = useState<string>("");
  const [cannedTestId, setCannedTestId] = useState<number>(0);
  const [standardIds, setStandardIds] = useState<Array<number>>();
  const [eliminateAnswerForSingleMCQ, setEliminateAnswerForSingleMCQ] =
    useState<boolean>(false);

  const [showQuestionAlreadyAttempted, setShowQuestionAlreadyAttempted] =
    useState<boolean>(false);
  const [contentAreaId, setContentAreaId] = useState<number>(0);
  const [languageSettingId, setLanguageSettingId] = useState<number>(0);
  const [hasSections, setHasSections] = useState(false);

  useEffect(() => {
    if (props.profile && props.profile.userId > 0) {
      bindStudentProfile();
      if (subjectId > 0) {
        getCalculatorBySubjectId(subjectId).then((res) => {
          if (res.data) setCalculatorReferenceId(res.data);
        });
      }

      getAssignmentById(assignmentId).then((response) => {
        setAssignmentName(response.data.name);
        setLanguageSettingId(response.data.languageSettingId);
        if (assignmentType === Constant.AssignmentActivityType.ASSESSMENT) {
          const assignment = response.data.activities.filter(
            (a) => a.assignmentActivityId === assignmentActivityId
          )[0];
          setAssessmentCode(assignment.code);
        }

        if (isCannedTest) {
          const assignment = response.data.activities.filter(
            (a) => a.assignmentActivityId === assignmentActivityId
          )[0];
          setCannedTestId(assignment.cannedTestId);
          setHasSections(
            hasSectionsGetByCannedtestTypeId(assignment.cannedTestTypeId)
          );
        }
        if (
          response.data.students.length > 0 &&
          assignmentType !== Constant.AssignmentActivityType.CONSTRUCTEDRESPONSE
        ) {
          var studentData = response.data.students.filter(
            (s) => s.studentId === props.userContext?.userId
          )[0];
          setEliminateAnswerForSingleMCQ(
            studentData?.eliminateAnswerForSingleMCQ ?? false
          );
        }

        const activity = response.data.activities.filter(
          (a) => a.assignmentActivityId === assignmentActivityId
        )[0];

        setAllowMissedRetry(activity.allowRetry);
      });
      setQuestionRetry(
        GetIsQuestionRetryAllowed(
          props.userContext?.gradeId,
          props.userContext?.stateId,
          assignmentType,
          allowMultipleAttempts
        )
      );
      setShowExplanation(
        GetShowExplanation(
          props.userContext?.gradeId,
          props.userContext?.stateId,
          assignmentType,
          undefined,
          allowMultipleAttempts,
          undefined
        )
      );
      setShowOnlyTextExplanation(
        GetShowOnlyTextExpalanation(
          props.userContext?.gradeId,
          props.userContext?.stateId,
          assignmentType
        )
      );
      getAssignmentActivityStandardsById(assignmentActivityId).then(
        (response) => {
          setStandardIds(response.data);
        }
      );
      initializeActivity(false);
    }
  }, [props.profile]);

  function bindStudentProfile() {
    getStudentProfile(
      props.userContext!.userId,
      props.profile?.gradeId ? props.profile?.gradeId : 0
    ).then((response) => {
      setStudentProfile(response.data);
    });
  }

  function initializeActivity(isBegin: boolean) {
    validateAssignmentMaxAttempt(
      props.userContext.userId,
      assignmentActivityId
    ).then((d) => {
      if (d.data.isSuccess === true) {
        getLastSession();
      } else {
        setShowMaxAttemptReached(true);
      }
    });
  }

  function getLastSession() {
    getStudentAssignmentLastSession(
      props.userContext.userId,
      assignmentId,
      assignmentActivityId,
      0
    ).then((d) => {
      var data = d.data;

      if (data.activitySessionId > 0) {
        activitySessionId = data.activitySessionId;
      }
      setBeginActivityModal({ open: true, data });
    });
  }

  const handleOnSubmit = () => {
    validateAssignmentMaxAttempt(
      props.userContext.userId,
      assignmentActivityId
    ).then((d) => {
      if (d.data.isSuccess === true) {
        completeStudentAssignmentActivity(
          props.userContext.userId,
          studentAssigmentActivity?.sessionId,
          timeInMinutes,
          questionAttemptedTillTimeLimit,
          studentAssigmentActivity?.activitySessionId
        ).then((res) => {
          handleOnCompleteStudentAssignmentActivity();
        });
      } else {
        setShowMaxAttemptReached(true);
      }
    });
  };

  const handleOnCompleteStudentAssignmentActivity = async () => {
    const assignmentResultUrl = resultUrl
      .replace(":assignmentId", assignmentId.toString())
      .replace(":assignmentActivityId", assignmentActivityId.toString())
      .replace(":runningAssignment", "Yes")
      .replace("/:sessionId?", "");

    if (isAllowMissedRetry) {
      const res = await getRetryEligibility(
        studentAssigmentActivity?.sessionId!
      );
      if (res.data.retryEligible) {
        redirectToRetryMissedItems(assignmentResultUrl);
      } else {
        redirectToResult(assignmentResultUrl);
      }
    } else {
      redirectToResult(assignmentResultUrl);
    }
  };

  const abandonSession = () => {
    abandonAssignmentActivity(
      props.userContext.userId,
      studentAssigmentActivity?.sessionId ?? ""
    ).then(() =>
      history.push({
        pathname: redirectUrl,
      })
    );
  };

  function redirectToRetryMissedItems(resultUrl: string) {
    let retryUrl =
      RouteConstant.StudentRoute.StudentRetryAssignmentActivityEL.replace(
        ":sessionId",
        studentAssigmentActivity!.sessionId!
      );

    if (studentType === Constant.StudentType.K1) {
      retryUrl =
        RouteConstant.KindergartenStudentRoutes.StudentRetryAssignmentActivityK1.replace(
          ":sessionId",
          studentAssigmentActivity!.sessionId!
        );
    } else if (studentType === Constant.StudentType.MS) {
      retryUrl =
        RouteConstant.MiddleSchool.StudentRetryAssignmentActivityMS.replace(
          ":sessionId",
          studentAssigmentActivity!.sessionId!
        );
    }

    const questionAnswer: IQuestionAnswerProps = {
      activityType: Constant.ActivityType.ASSIGNMENT,
      activityId: studentAssigmentActivity!.activityId,
      teacherActivityId:
        studentAssigmentActivity!.learnositySessionGroupId ??
        studentAssigmentActivity!.sessionId!,
      showActivity: false,
      showQuestions: true,
      questionIds: [],
      hasSections: hasSections,
      hasItemBranching: false,
      isAlienExplanation: false,
      isGameChoosen: studentAssigmentActivity!.studentGameId > 0,
      studentId: props.userContext.userId.toString(),
      resumeActivity: false,
      sessionId: studentAssigmentActivity!.sessionId!, // replace with retry sessionId
      studentType: studentType,
      title: assignmentActivityName,
      adaptiveFilter: null,
      onExitRedirectUrl: redirectUrl,
      questionRetry: isQuestionRetry,
      showExplanation: showExpalanation,
      showTextExpalanation: showOnlyTextExpalanation,
      timeLimitInMinutes: timeInMinutes,
      activitySessionId: studentAssigmentActivity!.activitySessionId, // replace with retry activitySessionId
      cannedTestId: cannedTestId,
      shuffleItems: studentAssigmentActivity!.isDisplayRandomQuestion ?? false,
      isTTSActive: studentAssigmentActivity!.isTTSActive,
      standardId: standardIds?.join(","),
      eliminateAnswerForSingleMCQ: eliminateAnswerForSingleMCQ,
      subjectId: studentAssigmentActivity!.subjectId,
      showScored100PercentVideo: !(
        studentAssigmentActivity!.isCombinedActivity ?? false
      ),
      language:
        studentAssigmentActivity!.languageId === constant.Languages.Spanish
          ? "es"
          : "en",
      activitySubType:
        assignmentType === Constant.AssignmentActivityType.PREBUILTTESTS
          ? Constant.ActivitySubTypes.GENERATEDTEST
          : assignmentType,
      calculatorReferenceId:
        assignmentType === Constant.AssignmentActivityType.ASSESSMENT
          ? studentAssigmentActivity!.calculatorReferenceId
          : calculatorReferenceId,
      OnSubmit: () => {},
      contentAreaId: contentAreaId,
      questionsState: studentAssigmentActivity?.questionStates,
    };

    const state: IRetryMissedItemState = {
      activityType: Constant.RetryActivityType.ASSIGNMENT,
      languageId: studentAssigmentActivity!.languageId,
      resultUrl: resultUrl,
      questionAnswer: JSON.stringify(questionAnswer),
    };

    history.push({
      pathname: retryUrl,
      state: state,
    });
  }

  function redirectToResult(assignmentResultUrl) {
    history.push({
      pathname: assignmentResultUrl,
      state: {
        time: timeInMinutes,
        questionAttemptedTillTimeLimit: questionAttemptedTillTimeLimit,
        redirectedFromLO: redirectedFromLO,
      },
    });
  }

  useEffect(() => {
    if (updateActivity && assignmentData) {
      var data = assignmentData;
      classId = data.classId;
      teacherId = data.teacherId;
      languageId = data.languageId;
      initializeStudentAssignmentActivity(
        props.userContext.userId,
        assignmentId,
        assignmentActivityId,
        assignmentActivityName,
        languageId === 3 ? 1 : languageId,
        languageId === 2 ? lernosityActivityIdES : lernosityActivityIdEN,
        true,
        studentProfile?.gradeId ?? 0,
        teacherId,
        classId,
        gameSettings !== null ? gameSettings.gameId : 0,
        gameSettings !== null ? gameSettings.rocketId : 0,
        gameSettings !== null ? gameSettings.blasterId : 0,
        gameSettings !== null ? gameSettings.blasterType : null
      ).then((d) => {
        var data = d.data;
        setStudentAssigmentActivity(data);
        updateStudentAssignmentActivity(
          props.userContext.userId,
          data.sessionId,
          classId,
          teacherId
        ).then((d) => {});
      });
    }
  }, [assignmentData, updateActivity]);

  function handleCloseMessagePopup(show: boolean) {
    setShowVideoMessage("");
    setShowTextMessage("");
    setUpdateActivity(true);
  }

  function handleTeacherMessage() {
    getAssignmentNoteById(assignmentId).then((r) => {
      const data = r.data;
      if (data) {
        if (data.videoNote) {
          setShowVideoMessage(
            <VideoMessagePopup
              videoUrl={data.videoNote}
              themeName={theme}
              showPopup={handleCloseMessagePopup}
            />
          );
        } else if (data.textNote) {
          setShowTextMessage(
            <TextMessagePopup
              textToSpeech={data.textToSpeech}
              languageId={props.profile.plLanguageId}
              text={data.textNote}
              themeName={theme}
              showPopup={handleCloseMessagePopup}
            />
          );
        } else {
          setUpdateActivity(true);
        }
      }
    });
  }

  function validateAssignment(questionId: any) {
    validateAssignmentMaxAttempt(
      props.userContext.userId,
      assignmentActivityId
    ).then((d) => {
      if (d.data.isSuccess === false) {
        setShowMaxAttemptReached(true);
      }
    });
    if (
      studentAssigmentActivity !== undefined &&
      questionId !== undefined &&
      questionId > 0 &&
      !isQuestionRetry
    ) {
      IsActivitySessionQuestionAttempted(
        studentAssigmentActivity.activitySessionId,
        questionId
      ).then((d) => {
        if (d.data === true) setShowQuestionAlreadyAttempted(true);
      });
    }
  }

  return (
    <Fragment>
      <div className="min-w-0 w-full h-full">
        {studentAssigmentActivity !== undefined &&
          studentAssigmentActivity.sessionId.length > 0 &&
          assignmentType !== Constant.AssignmentActivityType.VIDEOLESSON &&
          assignmentType !==
            Constant.AssignmentActivityType.CONSTRUCTEDRESPONSE && (
            <QuestionAnswer
              activityType={Constant.ActivityType.ASSIGNMENT}
              activityId={studentAssigmentActivity.activityId}
              teacherActivityId={
                studentAssigmentActivity.learnositySessionGroupId
              }
              showActivity={true}
              showQuestions={false}
              questionIds={[]}
              hasSections={hasSections}
              hasItemBranching={false}
              isAlienExplanation={false}
              isGameChoosen={studentAssigmentActivity.studentGameId > 0}
              studentId={props.userContext.userId.toString()}
              resumeActivity={studentAssigmentActivity.hasPendingSession}
              sessionId={studentAssigmentActivity.sessionId}
              studentType={studentType}
              title={assignmentActivityName}
              adaptiveFilter={null}
              OnSubmit={handleOnSubmit}
              onExitRedirectUrl={redirectUrl}
              calculatorReferenceId={
                assignmentType === Constant.AssignmentActivityType.ASSESSMENT
                  ? studentAssigmentActivity.calculatorReferenceId
                  : calculatorReferenceId
              }
              activitySubType={
                assignmentType === Constant.AssignmentActivityType.PREBUILTTESTS
                  ? Constant.ActivitySubTypes.GENERATEDTEST
                  : assignmentType
              }
              questionRetry={isQuestionRetry}
              showExplanation={showExpalanation}
              showTextExpalanation={showOnlyTextExpalanation}
              timeLimitInMinutes={timeInMinutes}
              setQuestionsAttemptedTillTimeLimit={
                setQuestionsAttemptedTillTimeLimit
              }
              activitySessionId={studentAssigmentActivity.activitySessionId}
              language={
                studentAssigmentActivity.languageId ===
                constant.Languages.Spanish
                  ? "es"
                  : "en"
              }
              cannedTestId={cannedTestId}
              shuffleItems={
                studentAssigmentActivity.isDisplayRandomQuestion ?? false
              }
              showScored100PercentVideo={
                !(studentAssigmentActivity.isCombinedActivity ?? false)
              }
              isTTSActive={studentAssigmentActivity.isTTSActive}
              onUnfocused={validateAssignment}
              onFocused={validateAssignment}
              standardId={standardIds?.join(",")}
              eliminateAnswerForSingleMCQ={eliminateAnswerForSingleMCQ}
              subjectId={studentAssigmentActivity.subjectId}
              questionsState={studentAssigmentActivity.questionStates}
              abandonSession={abandonSession}
              contentAreaId={contentAreaId}
            ></QuestionAnswer>
          )}

        {studentAssigmentActivity !== undefined &&
          studentAssigmentActivity.sessionId.length > 0 &&
          (assignmentType === Constant.AssignmentActivityType.VIDEOLESSON ||
            assignmentType ===
              Constant.AssignmentActivityType.CONSTRUCTEDRESPONSE) && (
            <QuestionAnswer
              activityType={Constant.ActivityType.ASSIGNMENT}
              activityId={""}
              teacherActivityId={studentAssigmentActivity.sessionId}
              showActivity={false}
              showQuestions={true}
              questionIds={[studentAssigmentActivity.activityId]}
              hasSections={false}
              hasItemBranching={false}
              isAlienExplanation={false}
              isGameChoosen={false}
              studentId={props.userContext.userId.toString()}
              resumeActivity={studentAssigmentActivity.hasPendingSession}
              sessionId={studentAssigmentActivity.sessionId}
              studentType={
                window.location.href.toLowerCase().indexOf("kindergarten") !==
                -1
                  ? "K1"
                  : window.location.href
                      .toString()
                      .toLowerCase()
                      .indexOf("middleschool") !== -1
                  ? "MS"
                  : "EL"
              }
              title={assignmentActivityName}
              adaptiveFilter={null}
              OnSubmit={handleOnSubmit}
              onExitRedirectUrl={redirectUrl}
              calculatorReferenceId={
                assignmentType === Constant.AssignmentActivityType.ASSESSMENT
                  ? studentAssigmentActivity.calculatorReferenceId
                  : calculatorReferenceId
              }
              activitySubType={
                assignmentType === Constant.AssignmentActivityType.VIDEOLESSON
                  ? Constant.ActivitySubTypes.VIDEO
                  : assignmentType
              }
              questionRetry={isQuestionRetry}
              showExplanation={showExpalanation}
              showTextExpalanation={showOnlyTextExpalanation}
              timeLimitInMinutes={timeInMinutes}
              setQuestionsAttemptedTillTimeLimit={
                setQuestionsAttemptedTillTimeLimit
              }
              activitySessionId={studentAssigmentActivity.activitySessionId}
              language={
                studentAssigmentActivity.languageId ===
                constant.Languages.Spanish
                  ? "es"
                  : "en"
              }
              shuffleItems={
                studentAssigmentActivity.isDisplayRandomQuestion ?? false
              }
              showScored100PercentVideo={
                !(studentAssigmentActivity.isCombinedActivity ?? false)
              }
              isTTSActive={studentAssigmentActivity.isTTSActive}
              eliminateAnswerForSingleMCQ={eliminateAnswerForSingleMCQ}
              questionsState={studentAssigmentActivity.questionStates}
              contentAreaId={contentAreaId}
              subjectId={studentAssigmentActivity.subjectId}
            ></QuestionAnswer>
          )}

        {beginActivityModal.open && (
          <BeginActivity
            sessionActivityId={
              assignmentType === Constant.AssignmentActivityType.VIDEOLESSON ||
              assignmentType ===
                Constant.AssignmentActivityType.CONSTRUCTEDRESPONSE
                ? "VIDEOLESSONCONSTRUCTEDRESPONSE"
                : activitySessionId
                ? activitySessionId
                : props.profile.plLanguageId === 2
                ? lernosityActivityIdES
                : lernosityActivityIdEN
            }
            activity={getBeginActivityType(assignmentType, prebuildTestType)}
            name={assignmentName}
            assignmentActivityName={assignmentActivityName}
            assignmentId={assignmentId}
            activityType={assignmentType}
            noOfQuestions={10}
            source={"Assignment"}
            themeName={theme}
            data={beginActivityModal.data}
            code={code}
            ActivityLanguageLevel={
              languageSettingId ? "AssignmentLevel" : "UserLevel"
            }
            languageSettingId={languageSettingId}
            onBegin={(data: any, value: any) => {
              setContentAreaId(value.contentAreaId);
              setSelectedTimeInMinutes(value.timeInMinutes);
              setAssignmentData(value);
              setSelectedTimeInMinutes(value?.timeInMinutes);
              setBeginActivityModal({ ...beginActivityModal, open: false });
              handleTeacherMessage();
            }}
            subjectId={subjectId}
            onCancel={() => {
              if (theme === Constant.Theme.KINDERGARTEN) {
                history.push(RouteConstant.KindergartenStudentRoutes.Home);
              } else if (theme === Constant.Theme.ELEMENTARY) {
                history.push(RouteConstant.StudentRoute.Home);
              } else if (theme === Constant.Theme.MIDDLESCHOOL) {
                history.push(RouteConstant.MiddleSchool.assignments);
              }
            }}
          />
        )}
        {showVideoMessage}
        {showTextMessage}
      </div>
      {showMaxAttemptReached && (
        <InformationDialog
          informationMessage={t("Assignments.MaxAttemptReachedMessage")}
          informationTitle={t("Assignments.MaxAttemptReachedHeader")}
          open={showMaxAttemptReached}
          onOkClick={() => {
            var path =
              window.location.href.toLowerCase().indexOf("kindergarten") !== -1
                ? routeConstant.KindergartenStudentRoutes.Home
                : window.location.href
                    .toString()
                    .toLowerCase()
                    .indexOf("middleschool") !== -1
                ? routeConstant.MiddleSchool.Home
                : routeConstant.StudentRoute.Home;

            history.push({
              pathname: path,
            });
          }}
        />
      )}

      {showQuestionAlreadyAttempted && (
        <InformationDialog
          informationMessage={t("Assignments.QuestionAlreadyAttmptedMessage")}
          informationTitle={t("Assignments.QuestionAlreadyAttmptedHeader")}
          open={showQuestionAlreadyAttempted}
          onOkClick={() => {
            window.location.reload();
          }}
        />
      )}
    </Fragment>
  );
}

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
};

export default connect<{}, {}, {}>(
  mapStateToProps,
  {}
)(StudentAssignmentActivity);

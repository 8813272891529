import { Fragment, useEffect, useState } from "react";
import "../../../../../../css/style.css";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { UserContextState } from "../../../../../../redux/actions/userContextAction";
import Profile from "../../../../../../model/users/profile";
import RouteConstant from "../../../../../../utils/constant/routeConstant";
import LearnosityReport from "../../../../results/learnosityReport";
import { getCannedTestResult } from "../../../../../../api/student/cannedTestApi";
import CannedTestOverViewResult from "./overViewResult";
import Breadcrumb from "../../../shared/breadcrumb";
import { IBreadcrumb } from "../../../../../../model/common/breadcrumb";

interface ICannedTestResult {
  userContext: UserContextState;
  profile: Profile;
  match: any;
}
const CannedTestResult = (props: ICannedTestResult) => {
  const [result, setResult] = useState<any>(null);
  const [title, setTitle] = useState<any>("");
  const sessionId = props.match.params.sessionId;
  const { t } = useTranslation();
  const studentId = parseInt(props.match.params.studentId);
  const [learnosityActivityUserId, setLearnosityActivityUserId] =
    useState<number>(studentId);

  useEffect(() => {
    getResult();
  }, []);

  function getResult() {
    getCannedTestResult(sessionId).then((res) => {
      setLearnosityActivityUserId(
        res.data.learnosityActivityUserId ?? studentId
      );

      setResult(res.data);
      setTitle(res.data.standardName);
    });
  }
  const breadcrumbItems = () => {
    const items: Array<IBreadcrumb> = [
      { name: t("Breadcrumb.Home"), url: RouteConstant.ROUTE_DASHBOARD },
      { name: t("Breadcrumb.Reports"), url: RouteConstant.REPORTS },
      { name: t("Breadcrumb.SessionActivityReport"), url: "" },
    ];

    return items;
  };
  return (
    <Fragment>
      {result && (
        <div className="relative w-full p-5 ">
          <div className="flex px-2 pb-3 pt-2 border-b">
            <Breadcrumb items={breadcrumbItems()} />
          </div>
          <div className="flex flex-col md:flex-row mt-4">
            <CannedTestOverViewResult data={result} />
            <div className="flex-grow h-auto md:w-1/2">
              <div className="flex w-full mt-6 md:mt-0 pb-6">
                <div>
                  <h1 className="text-2xl font-bold text-gray-700 capitalize">
                    {t("StudyPlanResult.StudyPlanResult")}
                  </h1>
                  <h2 className="text-base font-medium text-gray-700 capitalize">
                    {title}
                  </h2>
                </div>
              </div>
              <div className="flex-none xxl:flex">
                <div className="w-full bg-gray-50 rounded-md xl:mr-12 mb-12 xxl:mb-0 overflow-hidden shadow-lg">
                  <div className="p-5 bg-gray-50 border rounded-md text-sm font-semibold">
                    <h2>{t("StudyPlanResult.AnswerQuestionStatus")}.</h2>
                    <ul className="flex flex-wrap gap-2 w-full xl:w-1/2 mt-3">
                      {result.questions.map((question, index) => {
                        return (
                          <>
                            {question.order > 0 && question.score > 0 && (
                              <li className="w-6 h-6 bg-[#B2D136] border border-[#B2D136] text-white text-xs font-semibold flex items-center rounded-sm shadow-sm justify-center hover:bg-transparent hover:text-[#B2D136]">
                                {index + 1}
                              </li>
                            )}
                            {question.order > 0 && question.score === 0 && (
                              <li className="w-6 h-6 bg-[#e53f71] border border-[#e53f71] text-white text-xs font-semibold flex items-center rounded-sm shadow-sm justify-center hover:bg-transparent hover:text-[#e53f71]">
                                {index + 1}
                              </li>
                            )}
                          </>
                        );
                      })}
                    </ul>
                  </div>
                  <div className="p-5 border-2 border-blue-400 rounded-md text-sm font-semibold">
                    <LearnosityReport
                      sessionId={result.lernositySessionId}
                      user_id={learnosityActivityUserId.toString()}
                      show_answers={true}
                      wrapperClass=""
                    ></LearnosityReport>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
};
export default connect<{}, {}, {}>(mapStateToProps, {})(CannedTestResult);

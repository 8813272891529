import { Link } from "react-router-dom";
import { Eye, HelpVideo } from "../../../../assets/icons";
import { useHistory } from "react-router-dom";
import UpgradeButtonControl from "../../../shared/customButtonControl";
import { UserContextState } from "../../../../redux/actions/userContextAction";
import { checkIfUpgradeRequired } from "../../../shared/permissionHelper";
interface ReportElementProps {
  title: string;
  description: string;
  imageUrl: string;
  path: string;
  userContext: UserContextState;
  upgradePermission?: string;
}
export default function ReportElement(props: ReportElementProps) {
  const history = useHistory();
  const upgradeIsRequired: boolean = checkIfUpgradeRequired(
    props.upgradePermission ?? ""
  );
  return (
    <li
      className={
        "col-span-1 flex flex-col text-center rounded-lg shadow divide-y divide-gray-200 " +
        (upgradeIsRequired
          ? "bg-gray-50 bg-opacity-70 "
          : "bg-white cursor-pointer hover:bg-gray-50 ")
      }
    >
      <UpgradeButtonControl
        controlType={"a"}
        userContext={props?.userContext}
        permissionName={props.upgradePermission ?? ""}
        className={"flex-1 flex flex-col p-8 "}
        onClick={() => {
          history.push({
            pathname: props.path,
          });
        }}
      >
        <h3
          className={
            "h-auto md:h-28 lg:h-24 xl:h-16 text-gray-900 text-left text-sm font-medium " +
            (upgradeIsRequired ? "opacity-50 " : "opacity-1 ")
          }
        >
          {props.title}
        </h3>
        <img
          className={
            "w-full h-32 flex-shrink-0 mx-auto " +
            (upgradeIsRequired ? "opacity-60 " : "opacity-1 ")
          }
          src={props.imageUrl}
          alt={props.title}
          title={props.title}
        />
        <dl className="mt-4 flex-grow flex flex-col justify-between">
          <dd
            className={
              "text-gray-500 text-left text-sm " +
              (upgradeIsRequired ? "opacity-70 " : "opacity-1 ")
            }
          >
            {props.description}
          </dd>
        </dl>
      </UpgradeButtonControl>
      <div>
        <div className="-mt-px flex divide-x divide-gray-200">
          <div className=" hidden w-0 flex-1 flex">
            <Link
              to={props.path}
              className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500"
            >
              <HelpVideo
                title="Help Video"
                className="w-5 h-5 opacity-80 hover:opacity-100"
              ></HelpVideo>
              <span className="ml-3">Help Video</span>
            </Link>
          </div>
          <div className="-ml-px w-0 flex-1 flex">
            <UpgradeButtonControl
              controlType={"a"}
              userContext={props?.userContext}
              permissionName={props.upgradePermission}
              className={
                "relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500 "
              }
              onClick={() => {
                history.push({
                  pathname: props.path,
                });
              }}
            >
              {!upgradeIsRequired && (
                <>
                  <Eye
                    title="View Report"
                    className="w-5 h-5 text-gray-500 hover:text-gray-700"
                  ></Eye>
                  <span className="ml-3">View Report</span>
                </>
              )}
              {upgradeIsRequired && (
                <span className="py-0.5 rounded-md cursor-pointer bg-tertiary-color text-sm shadow-md text-gray-700 w-full inline-block mx-8">
                  Upgrade to View
                </span>
              )}
            </UpgradeButtonControl>
          </div>
        </div>
      </div>
    </li>
  );
}

import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  IContentAreaSubject,
  ISubject,
} from "../../../../model/interface/subject";
import Profile from "../../../../model/users/profile";
import { UserContextState } from "../../../../redux/actions/userContextAction";
import SubjectSelectionPopup from "./popup/subjectSelectionPopup";
import Planet from "./planet";
import { getContentAreaName, getSubjectName } from "../../../../utils/helper";
const astronautImage = "/images/student/shared/k1/astronaut.svg";
const galaxyImage = "/images/student/shared/k1/galaxy.png";
const bluePlanet = "/images/student/shared/k1/blue-planet.png";
const greenPlanet = "/images/student/shared/k1/green-planet.png";
const redPlanet = "/images/student/shared/k1/red-planet.png";
const yellowPlanet = "/images/student/shared/k1/yellow-planet.png";
const readingIcon = "/images/student/shared/k1/reading-icon.png";
const mathIcon = "/images/student/shared/k1/math-icon.png";
const scienceIcon = "/images/student/shared/k1/science-icon.png";
const socialStudiesIcon = "/images/student/shared/k1/social-studies-icon.png";

export interface SubjectContentAreasProps {
  userContext?: UserContextState;
  profile?: Profile;
  subjectsData: Array<IContentAreaSubject>,
  onSubjectSelected: (
    subjectId: number,
    subjectName: string,
    contentAreaId: number
  ) => void;
}


const SubjectContentAreas: React.FC<SubjectContentAreasProps> = (props) => {
  const [subjects, setSubjects] = useState<Array<IContentAreaSubject>>([]);
  const defaultContentArea = {
    name: "",
    spanishName: "",
    subjects: []
  };
  const [readingContentArea, setReadingContentArea] = useState<IContentAreaSubject>({
    contentAreaId: 1,
    ...defaultContentArea
  });
  const [mathContentArea, setMathContentArea] = useState<IContentAreaSubject>({
    contentAreaId: 2,
    ...defaultContentArea
  });
  const [scienceContentArea, setScienceContentArea] = useState<IContentAreaSubject>({
    contentAreaId: 3,
    ...defaultContentArea
  });
  const [socialStudiesContentArea, setSocialStudiesContentArea] = useState<IContentAreaSubject>({
    contentAreaId: 4,
    ...defaultContentArea
  });
  const [subjectPopup, setSubjectPopup] = useState<any>({
    show: false,
    subjects: [],
  });

  useEffect(() => {
    if (subjects.length === 0) {
      const contentAreaSubjects: Array<IContentAreaSubject> = [...props.subjectsData];
      loadContentAreaName(contentAreaSubjects);
      setSubjects(contentAreaSubjects);
    }
  }, []);

  const loadContentAreaName = (subjects: Array<IContentAreaSubject>) => {
    subjects.forEach(subject => {
      subject.k1DisplayName = getContentAreaName(subject) === "English" ||
      getContentAreaName(subject) === "ELA"
          ? "Reading"
          : getContentAreaName(subject);
      switch (subject.contentAreaId) {
        case 1:
          setReadingContentArea(subject);
          break;
        case 2:
          setMathContentArea(subject);
          break;
        case 3:
          setScienceContentArea(subject);
          break;
        case 4:
          setSocialStudiesContentArea(subject)
          break;
        default:
          // Why would you do this
      }
    })
  }

  function onContentAreaSelected(c: IContentAreaSubject) {
    if (c.subjects.length === 1) {
      props.onSubjectSelected(
        c.subjects[0].subjectId,
        getSubjectName(c.subjects[0], props.profile),
        c.contentAreaId
      );
    } else {
      setSubjectPopup({ show: true, subjects: c.subjects });
    }
  }
  return (
    <Fragment key="planets">
      <div className="flex flex-col justify-center gap-y-10 md:gap-y-16">
          {readingContentArea.subjects.length !== 0 &&
              <Planet
                  contentArea={readingContentArea}
                  onContentAreaSelected={onContentAreaSelected}
                  ttsText={readingContentArea.k1DisplayName ?? getContentAreaName(readingContentArea, props.profile)}
                  planetImage={redPlanet}
                  iconImage={readingIcon}
              />
          }
          {mathContentArea.subjects.length !== 0 &&
              <Planet
                  contentArea={mathContentArea}
                  onContentAreaSelected={onContentAreaSelected}
                  ttsText={mathContentArea.k1DisplayName ?? getContentAreaName(mathContentArea, props.profile)}
                  planetImage={bluePlanet}
                  iconImage={mathIcon}
              />
          }
      </div>
      <div className="  flex flex-col items-center justify-center ">
        <h1 className="font-Barlow text-2xl md:text-4xl xl:text-5xl font-bold text-white whitespace-nowrap mb-4 md:mb-10 text-center">
          Study Plan
        </h1>
        <img
            className="flex-shrink-0 animate-animate_tilt absolute w-[50%]"
            src={galaxyImage}
            alt=""
        />
        <img
            alt="astronaut"
            className="flex-shrink-0 w-[5rem] md:w-40  "
            src={astronautImage}
        />
      </div>
      <div className="flex flex-col justify-center gap-y-10 md:gap-y-16">
          {scienceContentArea.subjects.length !== 0 &&

              <Planet
                  contentArea={scienceContentArea}
                  onContentAreaSelected={onContentAreaSelected}
                  ttsText={scienceContentArea.k1DisplayName ?? getContentAreaName(scienceContentArea, props.profile)}
                  planetImage={greenPlanet}
                  iconImage={scienceIcon}
              />
          }
          {socialStudiesContentArea.subjects.length !== 0 &&
              <Planet
                  contentArea={socialStudiesContentArea}
                  onContentAreaSelected={onContentAreaSelected}
                  ttsText={socialStudiesContentArea.k1DisplayName ?? getContentAreaName(socialStudiesContentArea, props.profile)}
                  planetImage={yellowPlanet}
                  iconImage={socialStudiesIcon}
              />
          }
      </div>
        {subjectPopup.show && (
            <SubjectSelectionPopup
                showPopup={(s) => {
                    setSubjectPopup({ show: false, subjects: [] });
                }}
                subjects={subjectPopup.subjects}
                selectSubject={(s) => {
                    props.onSubjectSelected(
                        s.subjectId,
                        getSubjectName(s),
                        s.contentAreaId
                    );
                }}
                formatSubject={(s: ISubject) => {
                  return getSubjectName(s, props.profile);
                }}
            />
        )}
    </Fragment>
  );
}
const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(SubjectContentAreas);

import { Fragment } from "react";
import { CloseIcon } from "../../../../../assets/icons";
import { useTranslation } from "react-i18next";
import { IStudentVideoAssignment } from "../../../../../model/interface/studentVideoAssignment";
import UpgradeButtonControl from "../../../../shared/customButtonControl";
import constant from "../../../../../utils/constant/constant";
import { UserContextState } from "../../../../../redux/actions/userContextAction";

const theme = {
  ELEMENTARY: {
    header: "bg-[#34D9A5]",
    headerTextColor: "text-lg text-gray-700"
  },
  MIDDLESCHOOL: {
    header: "bg-site-teal",
    headerTextColor: "text-lg font-medium text-white"
  },
}

interface VideoTestPopupProps {
  themeName: "ELEMENTARY" | "MIDDLESCHOOL";
  standardName: string; 
  standardVideos: Array<IStudentVideoAssignment>;
  onClose(): void;
  startVideoPractice(video: IStudentVideoAssignment): void;
  userContext?:  UserContextState;
  profile?: any;
}

const StudyPlanVideoTestPopup = (props: VideoTestPopupProps) => {
  const {
    themeName,
    standardName,
    standardVideos,
    startVideoPractice,
    onClose
  } = props;
  const { t } = useTranslation();

  function getStatus(a: IStudentVideoAssignment) {
    switch (a.assignmentStatusId) {
      case 0:
        return t("StudyPlan.NotStarted");
      case 2:
        return t("Assignments.Continue");
      case 3:
        return a.score >= constant.VIDEO_PASSING_SCORE
          ? t("Assignments.Complete") + " - " + a.score.toString() + "%"
          : t("Assignments.TryAgain");
      default:
        return "";
    }
  }

  return (
    <Fragment>
      <div className="fixed inset-0 z-50 flex items-center bg-black bg-opacity-50 sm:items-center sm:justify-center">
        <div
          className="w-full flex flex-col m-4 overflow-hidden min-h-[200px] max-h-[550px] bg-white  dark:bg-gray-800 rounded-lg sm:m-4 sm:max-w-5xl"
          role="dialog"
        >
          <header>
            <div className={`px-6 py-4 ${theme[themeName]?.header}`}>
              <div className="flex items-start justify-between space-x-3">
                <div className={` ${theme[themeName]?.headerTextColor}`}>
                  {standardName}
                </div>
                <div>
                  <button
                    type="button"
                    className="inline-flex items-center justify-center w-6 h-6 text-gray-400 transition-colors duration-150 rounded dark:hover:text-gray-200 hover: hover:text-gray-700"
                    onClick={onClose}
                  >
                    <CloseIcon className="text-white"/>
                  </button>
                </div>
              </div>
            </div>
          </header>
          <div className="px-8 py-6 mt-4 overflow-auto"
            style={{ maxHeight: "450px" }}
          >
            <div className="flex flex-wrap gap-5">
              {standardVideos.map((d) => {
                return (
                  <Fragment key={d.videoId}>
                    <UpgradeButtonControl
                        controlType={"div"}              
                        userContext={props?.userContext}
                        isUpgradeRequired={props?.profile?.isUpgradeRequired}                   
                        isRoleCheck={true}               
                        className={"flex flex-col shadow-lg overflow-hidden w-56 bg-white border-4 rounded-xl group " + (props?.profile?.isUpgradeRequired ? " cursor-not-allowed bg-opacity-20 " : " cursor-pointer hover:bg-gray-100 hover:border-4 hover:border-primary-violet/50 transition-all")}
                        onClick={() => {
                          startVideoPractice(d);
                        }}
                      > 
                      <span className="inline-block p-4">
                        <img
                          className="rounded-xl w-full h-36 mx-auto cursor-pointer"
                          srcSet={
                            "https://assets.learnosity.com/organisations/771/videos/" +
                            d.externalId +
                            "/thumbnail-1-1.jpg"
                          }
                          alt="Video Questions"
                        />
                      </span>
                      <div className="justify-between flex-grow p-4 bg-gray-100 transition-all">
                        <div className="flex w-full justify-between items-center">
                          <span className="block font-semibold text-sm text-gray-700 group-hover:text-gray-900 mb-1">
                            {getStatus(d)}
                          </span>
                        </div>
                        <div className="flex w-full">
                          <span className="block text-xs font-semibold text-secondary-teal uppercase">
                            {props.profile?.plLanguageId ===
                            constant.Languages.Spanish
                              ? d.spanishVideoTitle ?? d.videoTitle
                              : d.videoTitle}
                          </span>
                        </div>
                      </div>
                  
                    </UpgradeButtonControl>
                  </Fragment>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default StudyPlanVideoTestPopup;
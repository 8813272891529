import Select from "react-select";
import Loader from "../../../shared/loader";
import AllowedTo from "../../../shared/rbac";
import { useHistory } from "react-router-dom";
import { Fragment, useEffect, useState } from "react";
import Permissions from "../../../../utils/permissions";
import Subject from "../../../shared/dropdowns/subject";
import { getAllTags } from "../../../../api/teacher/tags";
import Constant from "../../../../utils/constant/constant";
import {
  CancelIcon, CaretDownSolidIcon, CaretUpSolidIcon,
  LockIcon,
  TagIcon,
  TagOutlineIcon,
  UnLockIcon,
  UserAdd,
} from "../../../../assets/icons";
import PagingResponse from "../../../../model/common/pagingResponse";
import RouteConstant from "../../../../utils/constant/routeConstant";
import { ISelectElement } from "../../../../model/interface/selectElement";
import {
  getPremiumAssessments,
  lockPremiumAssessments,
  removeTag,
} from "../../../../api/teacher/assessment";
import {
  getPremiumAssessmentName,
  getPremiumAssessmentNameWithSubjectPrefix,
  isActivityValid,
} from "../../../../utils/assignmentHelper";
import { IPremiumAssessmentGridDTO } from "../../../../model/interface/assessment";
import ContextMenu from "../shared/contextMenu";
import { ErrorMessage } from "../../../../model/common/common";
import MessagePopup from "../../../shared/messagePopup";
import QuestionPreview from "../shared/questionPreview/questionPreview";
import { TagColor } from "../../../shared/tagColor";
import PageSize from "../../../shared/pagination/pageSize";
import Pagination from "../../../shared/pagination/pagination";
import {
  DEFAULT_PAGE_NUMBER,
  DEFAULT_PAGE_SIZE,
  DEFAULT_PAGE_SIZE_10,
} from "../../../../utils/pagingConstant";
import { IAssignmentActivity } from "../../../../model/interface/assignmentActivity";
import { getActivityTypeId } from "../../../../utils/helper";
import { addAssignment } from "../../../../api/teacher/assignment";
import constant from "../../../../utils/constant/constant";
import ManagePremiumAssessmentTag from "./managePremiumAssessmentTag";
import { toast } from "react-toastify";
import PrintDialog from "../shared/print/learnosityPrintPopUp";
import { ArrowDownIcon, ArrowUpIcon } from "@heroicons/react/solid";
import ReactTooltip from "react-tooltip";
import { ISchoolYear } from "../../../../model/teacher/schoolYear";
import AssessmentFilterActions from "./assessmentFilterActions";
import { GetSchoolYears } from "../../../../api/teacher/school";

const options = [
  {
    value: "1",
    label: "Answer Key",
    roles: [
      Constant.UserRoleId.District,
      Constant.UserRoleId.SchoolAdmin,
      Constant.UserRoleId.SchoolTeacher,
      Constant.UserRoleId.FreeTeacher,
    ],
    isActive: true,
    isUpgrade: false,
  },
  {
    value: "2",
    label: "Export Results",
    roles: [
      Constant.UserRoleId.District,
      Constant.UserRoleId.SchoolAdmin,
      Constant.UserRoleId.SchoolTeacher,
      Constant.UserRoleId.FreeTeacher,
    ],
    isActive: false,
    isUpgrade: false,
  },
  {
    value: "3",
    label: "Preview",
    roles: [
      Constant.UserRoleId.District,
      Constant.UserRoleId.SchoolAdmin,
      Constant.UserRoleId.SchoolTeacher,
      Constant.UserRoleId.FreeTeacher,
    ],
    isActive: true,
    isUpgrade: false,
  },
  {
    value: "4",
    label: "Print",
    roles: [
      Constant.UserRoleId.District,
      Constant.UserRoleId.SchoolAdmin,
      Constant.UserRoleId.SchoolTeacher,
      Constant.UserRoleId.FreeTeacher,
    ],
    isActive: true,
    isUpgrade: false,
  },
  {
    value: "5",
    label: "Projector Mode",
    roles: [
      Constant.UserRoleId.SchoolAdmin,
      Constant.UserRoleId.SchoolTeacher,
      Constant.UserRoleId.FreeTeacher,
    ],
    isActive: true,
    isUpgrade: false,
  },
];

interface passedProps {
  userId: number;
  roleId: number;
  schoolId: number;
  districtId: number;
  defaultSchoolYearId: number;
}

interface IActivityPreviewDetail {
  previewId: string;
  activityIdPreview: number;
  activityName: string;
  isProjectorMode: boolean;
  subjectId: number;
}

type FilterType = "name" | "subject" | "assessmenttype" | "tag" | "schoolyear";
const defaultSelectValue: ISelectElement = { label: "All", value: "0" };
const defaultErrorMessage: ErrorMessage = { message: "", show: false };
const assessmentTypeOptions = [
  defaultSelectValue,
  {
    value: Constant.CannedTestTypeId.CannedTest.toString(),
    label: "ACT/PSAT/SAT/AP",
  },
  {
    value: [
      Constant.CannedTestTypeId.PreTest,
      Constant.CannedTestTypeId.PostTest,
    ].toString(),
    label: "Diagnostic Pre/Post Tests",
  },
  {
    value: Constant.CannedTestTypeId.PracticeTest.toString(),
    label: "State Practice Assessments",
  },
];
const defaultFilter = {
  name: "",
  subject: defaultSelectValue,
  assessmenttype: defaultSelectValue,
  tag: defaultSelectValue,
};
const defaultData: PagingResponse<IPremiumAssessmentGridDTO> = {
  data: [],
  totalRecords: 0,
  page: DEFAULT_PAGE_NUMBER,
  pageSize: DEFAULT_PAGE_SIZE_10,
};
const defaultPopup = {
  print: false,
  preview: false,
  assessmentTags: false,
  manageAssessmentTags: false,
};

function PremiumAssessment(props: passedProps) {
  const history = useHistory();
  const { userId, roleId, schoolId } = props;
  const [loading, showLoading] = useState(false);
  const [tags, setTags] = useState<Array<ISelectElement>>([]);
  const [filter, setFilter] = useState(defaultFilter);
  const [popup, setPopup] = useState(defaultPopup);
  const [selectAll, setSelectAll] = useState(false);
  const [pageNumber, setPageNumber] = useState(DEFAULT_PAGE_NUMBER);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [resetPageNumber, setResetPageNumber] = useState(false);
  const [assessments, setAssessments] =
    useState<PagingResponse<IPremiumAssessmentGridDTO>>(defaultData);
  const [assessmentToEdit, setAssessmentToEdit] =
    useState<IPremiumAssessmentGridDTO>();
  const [errorMessage, setErrorMessage] =
    useState<ErrorMessage>(defaultErrorMessage);
  const [activityPreview, setActivityPreview] =
    useState<IActivityPreviewDetail>();
  const [printPreview, setPrintPreview] = useState<{
    cannedTestId: number;
    learnosityId: string;
    activityName: string;
    isAnswerKey: boolean;
  }>();
  const [showFilter, setShowFilter] = useState(false);
  const [currentSchoolYear, setCurrentSchoolYear] = useState<ISchoolYear>();
  const [selectedAssessments, setSelectedAssessments] = useState<
    Array<IPremiumAssessmentGridDTO>
  >([]);
  const [sortByDesc, setSortByDesc] = useState<boolean>(false);
  const [orderByColumn, setOrderByColumn] = useState<string>("Subject");

  const getTags = () => {
    let validTags: Array<ISelectElement> = [];
    validTags.push(defaultSelectValue);
    showLoading(true);
    getAllTags(userId).then((response) => {
      showLoading(false);
      response.data.data.map((values: any) => {
        validTags.push({ label: values.name, value: values.userTagId });
      });

      setTags(validTags);
    });
  };

  const getOptions = () => {
    return options;
  };

  const searchAssessments = (
    resetPageNumber?: boolean,
    resetFilter?: boolean
  ) => {
    let pageNo = pageNumber;
    let param = filter;

    if (resetPageNumber) {
      pageNo = DEFAULT_PAGE_NUMBER;
      setPageNumber(DEFAULT_PAGE_NUMBER);
    }

    if (resetFilter) {
      param = defaultFilter;
    }

    showLoading(true);
    getPremiumAssessments(
      param.name === "" ? null : param.name,
      param.assessmenttype.value === defaultSelectValue.value
        ? null
        : param.assessmenttype.value,
      param.subject.value === defaultSelectValue.value
        ? null
        : parseInt(param.subject.value),
      param.tag.value === defaultSelectValue.value
        ? null
        : parseInt(param.tag.value),
      null,
      null,
      pageNo,
      pageSize,
      orderByColumn,
      sortByDesc,
      currentSchoolYear?.schoolYearId
    )
      .then((r) => {
        const selectedAssessmentIds = selectedAssessments.map(
          (a) => a.cannedTestId
        );
        const data: Array<IPremiumAssessmentGridDTO> = r.data.data.map((d) => {
          return {
            ...d,
            isSelected: selectedAssessmentIds.includes(d.cannedTestId),
          };
        });
        const response: PagingResponse<IPremiumAssessmentGridDTO> = {
          data: data,
          totalRecords: r.data.totalRecords,
          page: r.data.page,
          pageSize: r.data.pageSize,
        };
        setAssessments(response);
        setSelectAll(isAllAssessmentSelected(response));
      })
      .finally(() => {
        showLoading(false);
      });
  };

  const getAssessmentName = (assessment: IPremiumAssessmentGridDTO) => {
    return getPremiumAssessmentNameWithSubjectPrefix(assessment);
  };

  const showError = (message: string, show: boolean) => {
    setErrorMessage({
      message: message,
      show: show,
    });
  };

  const validateActivity = (learnosityId: string) => {
    const isValid = isActivityValid(learnosityId);
    if (!isValid) {
      showError(
        "You cannot perform this action because there are no questions associated with this assessment.",
        true
      );
    }

    return isValid;
  };

  const isAllAssessmentSelected = (
    assessments: PagingResponse<IPremiumAssessmentGridDTO>
  ) => {
    let isSelected = false;
    if (
      assessments.data.filter((a) => a.isSelected).length ===
      assessments.data.length
    ) {
      isSelected = true;
    } else {
      isSelected = false;
    }
    return isSelected;
  };

  const handleSelectAll = (isSelected: boolean) => {
    if (assessments?.totalRecords! > 0) {
      const data = assessments.data.map((a) => {
        a.isSelected = isSelected;
        return a;
      });
      setSelectAll(isSelected);
      setAssessments({ ...assessments, data: Array.from(data) });
      if (isSelected) {
        setSelectedAssessments([
          ...selectedAssessments,
          ...data.filter((a) => a.isSelected),
        ]);
      } else {
        setSelectedAssessments([]);
      }
    }
  };

  const handleSelect = (isSelected: boolean, index: number) => {
    if (assessments?.totalRecords! > 0) {
      var selectedAssessmentList: Array<IPremiumAssessmentGridDTO> =
        new Array<IPremiumAssessmentGridDTO>();
      const assessment = assessments.data[index];
      assessments.data[index].isSelected = isSelected;
      const data = assessments.data;
      setAssessments({ ...assessments, data: Array.from(data) });

      const assessmentExist = selectedAssessments.some(
        (r) => r.cannedTestId === assessment.cannedTestId
      );

      if (assessmentExist && !isSelected) {
        selectedAssessmentList = selectedAssessments.filter(
          (r) => r.cannedTestId !== assessment.cannedTestId
        );
      } else {
        selectedAssessmentList = [...selectedAssessments, assessment];
      }

      setSelectedAssessments(selectedAssessmentList);
    }

    setSelectAll(isAllAssessmentSelected(assessments));
  };

  const handleSelectById = (isSelected: boolean, canneTestId: number) => {
    if (assessments?.totalRecords! > 0) {
      const index = assessments.data.findIndex(
        (a) => a.cannedTestId === canneTestId
      );
      handleSelect(isSelected, index);
    }
  };

  const handleFilterChange = (name: FilterType, value: any) => {
    setFilter({
      ...filter,
      [name]: value,
    });
  };

  const handleTagManager = () => {
    history.push({
      pathname: RouteConstant.TeacherRoutes.Tags,
    });
  };

  const handleOptionChange = (
    selectdItem: any,
    assessmentId: number,
    cannedTestId: number,
    learnosityId: string,
    activityName: string,
    subjectId: number
  ) => {
    switch (selectdItem.label) {
      case "Print":
        if (validateActivity(learnosityId)) {
          handlePrint(cannedTestId, learnosityId, activityName, false, true);
        }
        break;
      case "Preview":
        if (validateActivity(learnosityId)) {
          openModal({
            previewId: learnosityId,
            activityIdPreview: assessmentId,
            activityName: activityName,
            isProjectorMode: false,
            subjectId: subjectId,
          });
        }
        break;

      case "Projector Mode":
        if (validateActivity(learnosityId)) {
          openModal({
            previewId: learnosityId,
            activityIdPreview: assessmentId,
            activityName: activityName,
            isProjectorMode: true,
            subjectId: subjectId,
          });
        }
        break;
      case "Answer Key":
        if (validateActivity(learnosityId)) {
          handlePrint(cannedTestId, learnosityId, activityName, true, true);
        }
        break;
      case "Export Results":
        // handle export
        break;
    }
  };

  const handleRemoveTag = (
    tagId: number,
    tagName?: string,
    cannedTestIds?: Array<number>
  ) => {
    showLoading(true);
    if (cannedTestIds && cannedTestIds?.length > 0) {
      removeTag(tagId, [], cannedTestIds).then((r) => {
        showLoading(false);
        searchAssessments();
        toast.success(`${tagName} has been removed.`);
      });
    }
  };

  const handlePrint = (
    cannedTestId: number,
    learnosityId: string,
    activityName: string,
    isAnswerKey: boolean,
    show: boolean
  ) => {
    if (show) {
      setPrintPreview({
        cannedTestId: cannedTestId,
        learnosityId: learnosityId,
        activityName: activityName,
        isAnswerKey: isAnswerKey,
      });
    } else {
      setPrintPreview(undefined);
    }
    setPopup({ ...popup, print: show });
  };

  const openModal = (activityDetail: IActivityPreviewDetail) => {
    if (!activityDetail.previewId) {
      return false;
    }
    setPreviewModel(true);
    setActivityPreview(activityDetail);
  };

  const setPreviewModel = (show: boolean) => {
    setPopup({ ...popup, preview: show });
    if (!show) {
      setActivityPreview(undefined);
    }
  };

  const createAssignmentAndRedirect = (
    assessment: IPremiumAssessmentGridDTO
  ) => {
    const assessmentName = getPremiumAssessmentName(
      assessment.name,
      assessment.cannedTestType,
      assessment.cannedTestTypeId
    );
    const assignmentActivity: IAssignmentActivity = {
      assignmentActivityId: 0,
      assignmentId: 0,
      activityTypeId: getActivityTypeId(
        Constant.AssignmentActivityType.ASSESSMENT
      ),
      activityType: Constant.AssignmentActivityType.ASSESSMENT,
      sequenceNumber: 1,
      cannedTestId: assessment.cannedTestId.toString(),
      assessmentName: assessmentName,
      learnosityIdEnglish: assessment.learnosityIdEnglish,
      learnosityIdSpanish: assessment.learnosityIdSpanish,
    };

    showLoading(true);
    addAssignment(
      userId,
      assessmentName,
      assessment.subjectId,
      [assignmentActivity],
      props.defaultSchoolYearId
    ).then((response) => {
      showLoading(false);
      const assignmentId = response.data;
      history.push({
        pathname: RouteConstant.EDITASSIGNMENT.replace(":id", assignmentId),
        state: {
          editMode: "AssignStudent",
        },
      });
    });
  };

  const handleAssign = (assessment: IPremiumAssessmentGridDTO) => {
    if (validateActivity(assessment.learnosityIdEnglish)) {
      createAssignmentAndRedirect(assessment);
    }
  };

  const resetFilters = () => {
    setFilter(defaultFilter);
    setPageNumber(DEFAULT_PAGE_NUMBER);
    setPageSize(DEFAULT_PAGE_SIZE);
    searchAssessments(true, true);
  };

  const onPageChange = (pageNo) => {
    setPageNumber(pageNo);
  };

  const onPageSizeChange = (pageSize) => {
    setPageNumber(DEFAULT_PAGE_NUMBER);
    setPageSize(pageSize);
    setResetPageNumber(true);
  };

  const handleResultsClick = (assessmentId, subjectId) => {
    const pathname = `${
      RouteConstant.GROUPED_RESULT_REPORTS
    }?assessmentId=${assessmentId}&redirectedFrom=PREMIUMASSESSMENTS&type=PREMIUMASSESSMENT&subjectId=${subjectId}`;
    window.open(pathname + (props.defaultSchoolYearId > 0 ? "&schoolYearId=" + props.defaultSchoolYearId : ""), "_blank");
  };

  const getSelectedStateAssessments = () => {
    return assessments.data.filter(
      (a) =>
        a.isSelected &&
        a.cannedTestTypeId == Constant.CannedTestTypeId.PracticeTest
    );
  };

  const onAssessmentLockClick = () => {
    if (
      selectedAssessments &&
      selectedAssessments.filter(
        (d) => d.cannedTestTypeId == Constant.CannedTestTypeId.PracticeTest
      ).length > 0
    ) {
      const assessmentIds = selectedAssessments
        .filter(
          (d) => d.cannedTestTypeId == Constant.CannedTestTypeId.PracticeTest
        )
        .map((d) => d.cannedTestId);

      lockPremiumAssessments(assessmentIds).then((res) => {
        searchAssessments();
      });
    } else {
      toast.error("Select state premium assessments to Lock/unLock");
    }
  };

  const getCurrentSchoolYear = () => {
      GetSchoolYears(schoolId).then(r => {
      const currentSchoolYear = r.data.filter(
        year => year.currentYearOffset === 0
      )[0];
      setCurrentSchoolYear(currentSchoolYear);
    });
  }
  const handleSortBy = (column: string) => {
    if (orderByColumn !== column){
      setSortByDesc(false);
      setOrderByColumn(column);
    }
    else {
      setSortByDesc(!sortByDesc);
    }
  };
  
  useEffect(() => {
    getCurrentSchoolYear();
    getTags();
  }, []);

  useEffect(() => {
    searchAssessments();
  }, [pageSize, pageNumber, sortByDesc, orderByColumn]);

  return (
    <>
      <ReactTooltip
        id={"lockToolTip"}
        className="bg-primary-violet z-50"
        place={"right"}
        multiline={true}
      ></ReactTooltip>
      <div className="min-w-0 block lg:col-span-9 xl:col-span-10 w-full">
        <div className="bg-gray-100  w-full mb-5 rounded-lg p-5 h-full">
          <div
            className="flex relative items-center cursor-pointer"
            onClick={() => {
              if (showFilter) {
                setShowFilter(false);
              } else {
                setShowFilter(true);
              }
            }}
          >
            <span className="bg-black w-10 h-10 rounded-full inline-block flex items-center justify-center ">
              {!showFilter && (
                <ArrowDownIcon className="w-6 text-white opacity-90" />
              )}
              {showFilter && (
                <ArrowUpIcon className="w-6 text-white opacity-90" />
              )}
            </span>
            <span className="mx-2 text-gray-700">
              {!showFilter
                ? "Expand Filter Options"
                : "Collapse Filter Options"}
            </span>
          </div>
          <div
            className={`${
              showFilter
                ? "max-h-[1000px] opacity-100 mt-5 pb-2"
                : "max-h-0 opacity-0 -mt-5 pb-0 pointer-events-none"
            } w-full bg-white transform transition-all ease-in-out duration-300 relative z-0 p-5 shadow`}
          >
            <div className="grid grid-cols-2 sm:grid-cols-8 lg:grid-cols-8 xl:grid-cols-8 gap-6 pb-4">
              <div className="col-span-8 sm:col-span-4 xl:col-span-2">
                <label className="block text-sm text-gray-500">Name</label>
                <input
                  type="text"
                  autoComplete="off"
                  value={filter.name}
                  onChange={(event) => {
                    handleFilterChange("name", event.target.value);
                  }}
                  className="mt-1 shadow-sm focus:ring-secondary-teal focus:border-secondary-teal h-10 block w-full text-lg border border-gray-200 text-gray-500 px-3 rounded-sm"
                />
              </div>
              <div className="col-span-8 sm:col-span-4 xl:col-span-2">
                <Subject
                  setSelectedSubject={(items) => {
                    handleFilterChange("subject", items[0]);
                  }}
                  selectedItems={filter.subject}
                  isMulti={false}
                  themeColor={"#992883"}
                ></Subject>
              </div>
              <div className="col-span-8 sm:col-span-4 xl:col-span-2">
                <label className="block text-sm text-gray-500">
                  Assessment Type
                </label>
                <Select
                  value={filter.assessmenttype}
                  options={assessmentTypeOptions}
                  onChange={(e) => {
                    handleFilterChange("assessmenttype", e);
                  }}
                  className="mt-1"
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: "#992883",
                    },
                  })}
                />
              </div>
              <div className="col-span-8 sm:col-span-4 xl:col-span-2">
                <label className="block text-sm text-gray-500">Tag</label>
                <Select
                  value={filter.tag}
                  options={tags}
                  onChange={(e) => {
                    handleFilterChange("tag", e);
                  }}
                  className="mt-1"
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: "#992883",
                    },
                  })}
                />
              </div>
            </div>
            <AssessmentFilterActions
              onReset={resetFilters}
              onHandleTagManager={handleTagManager}
              onSearchAssessments={() => {
                searchAssessments(true, false);
                setSelectedAssessments([]);
              }}
            />
          </div>
          <div className="bg-white mt-5 py-5 px-5 ">
            {loading && <Loader />}
            <div className="flex flex-col gap-y-4 xl:gap-y-0 xl:flex-row xl:justify-between xl:items-end mb-5">
              <div className="grid grid-cols-2 sm:grid-cols-4 gap-4">
                <AllowedTo perform={Permissions.assessments_modify}>
                  <button
                    disabled={selectedAssessments.length <= 0}
                    className={`${
                      selectedAssessments.length > 0
                        ? "hover:text-white hover:bg-secondary-teal hover:shadow-lg"
                        : "opacity-50 cursor-not-allowed"
                    } bg-transparent border border-secondary-teal shadow-sm py-2 px-4 inline-flex items-center justify-center text-sm font-medium text-secondary-teal  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3 transition ease-in-out duration-500`}
                    onClick={() =>
                      setPopup({ ...popup, manageAssessmentTags: true })
                    }
                  >
                    <TagIcon className="w-4" />
                    <span className="ml-2">Add Tag</span>
                  </button>
                </AllowedTo>

                <AllowedTo perform={Permissions.assessments_modify}>
                  {roleId === Constant.UserRoleId.SchoolAdmin && (
                    <button
                      data-for={"lockToolTip"}
                      data-tip={
                        "This allows you to lock or unlock State Practice Assessments. Teachers can still see the assessment in their list when in a locked status, but all details and ability to assign will be disabled."
                      }
                      onClick={onAssessmentLockClick}
                      disabled={
                        getSelectedStateAssessments().length <= 0
                      }
                      className={`${
                        getSelectedStateAssessments().length > 0
                          ? "hover:text-white hover:bg-secondary-teal hover:shadow-lg"
                          : "opacity-50 cursor-not-allowed"
                      } bg-transparent border border-secondary-teal shadow-sm py-2 px-4 inline-flex items-center justify-center text-sm font-medium text-secondary-teal  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3 transition ease-in-out duration-500`}
                    >
                      <LockIcon className="w-4" />
                      <span className="ml-2">Lock/Unlock</span>
                    </button>
                  )}
                </AllowedTo>
              </div>
            </div>
            <div className="mb-4 pb-4 border-b-2 overflow-x-auto xl:overflow-x-visible">
              <table className="table w-full">
                <thead className="mb-5">
                  <tr className="text-secondary-teal border-[3px] border-secondary-teal py-3 px-3">
                    <th className="text-left p-3 text-sm font-normal w-4">
                      <input
                        id="select-all"
                        type="checkbox"
                        className="cursor-pointer checkbox focus:ring-secondary-teal h-4 w-4 text-secondary-teal border-secondary-teal border-2"
                        checked={selectAll}
                        onChange={(e) => handleSelectAll(e.target.checked)}
                      />
                    </th>
                    <th className="text-left p-3 w-4 font-normal">
                      {/* lock */}
                    </th>
                    <th className="text-left p-3 text-sm font-normal">
                      <span className="flex" onClick={() => handleSortBy("Subject")} style={{cursor: 'pointer'}}>
                        Subject
                        <span className="cursor-pointer">
                           {!sortByDesc && orderByColumn === "Subject" && (
                             <CaretUpSolidIcon
                               className="w-5 h-5"
                             ></CaretUpSolidIcon>
                           )}
                          {sortByDesc && orderByColumn === "Subject" && (
                            <CaretDownSolidIcon
                              className="w-5 h-5"
                            ></CaretDownSolidIcon>
                          )}

                        </span>
                      </span>
                    </th>
                    <th className="text-left p-3 text-sm font-normal">
                      <span className="flex" onClick={() => handleSortBy("Name")} style={{cursor: 'pointer'}}>
                        Name
                        <span className="cursor-pointer">
                          {!sortByDesc && orderByColumn === "Name" && (
                            <CaretUpSolidIcon
                              className="w-5 h-5"
                            ></CaretUpSolidIcon>
                          )}
                          {sortByDesc && orderByColumn === "Name" && (
                            <CaretDownSolidIcon
                              className="w-5 h-5"
                            ></CaretDownSolidIcon>
                          )}
                        </span>
                      </span>
                    </th>
                    <AllowedTo perform={Permissions.assessments_results_view}>
                      <th className="text-center  p-3 text-sm font-normal">
                        Current Year Results
                      </th>
                    </AllowedTo>
                    <th className="text-center  p-3 text-sm font-normal">
                      Tag
                    </th>

                    <th className="text-center  p-3 text-sm font-normal">
                      Action
                    </th>
                    {(roleId === Constant.UserRoleId.SchoolAdmin ||
                      roleId === Constant.UserRoleId.SchoolTeacher) && (
                      <th className="text-center  p-3 text-sm font-normal">
                        Assign
                      </th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {assessments && !loading &&
                    assessments.data.map((assessment, index) => (
                      <Fragment key={index}>
                        <tr
                          key={index}
                          className={`hover:bg-secondary-teal/20 transition ease-in-out duration-300 ${
                            index % 2 === 0
                              ? "bg-white"
                              : "bg-secondary-teal/10"
                          }`}
                        >
                          <td className=" mt-5 p-3 text-center">
                            <input
                              name="select-assessment"
                              type="checkbox"
                              checked={selectedAssessments
                                .map((a) => a.cannedTestId)
                                .includes(assessment.cannedTestId)}
                              onChange={(e) => {
                                handleSelect(e.target.checked, index);
                              }}
                              className="cursor-pointer checkbox focus:ring-secondary-teal h-4 w-4 text-secondary-teal border-gray-300"
                            />
                            <label className="checkbox-label"></label>
                          </td>
                          <td className=" mt-5 p-3 text-left text-gray-500">
                            {assessment.cannedTestTypeId ==
                              constant.CannedTestTypeId.PracticeTest &&
                              props.roleId !== constant.UserRoleId.District &&
                              (assessment.isLocked ? (
                                <LockIcon />
                              ) : (
                                <UnLockIcon />
                              ))}
                          </td>

                          <td className="text-left text-sm mt-5 p-3">
                            {assessment.subjectName}
                          </td>

                          <td className="text-left text-sm mt-5 p-3 ">
                            {getAssessmentName(assessment)}
                          </td>
                          <AllowedTo
                            perform={Permissions.assessments_results_view}
                          >
                            <td
                              className={
                                "text-sm text-center mt-5 p-3 " +
                                (assessment.completed > 0
                                  ? "cursor-pointer text-blue-500 underline hover:no-underline"
                                  : "")
                                }
                              onClick={() => {
                                if (assessment.completed)
                                  handleResultsClick(
                                    assessment.cannedTestId,
                                    assessment.subjectId
                                  );
                              }}
                            >
                              {assessment.completed}
                            </td>
                          </AllowedTo>
                          <td
                            className="text-center text-sm mt-5  p-3 cursor-pointer relative"
                            onMouseOver={() => {
                              if (assessment.tags) {
                                setPopup({
                                  ...popup,
                                  assessmentTags: true,
                                });
                                setAssessmentToEdit(assessment);
                              }
                            }}
                            onMouseOut={() =>
                              setPopup({
                                ...popup,
                                assessmentTags: false,
                              })
                            }
                          >
                            <div className="flex justify-center gap-1">
                              <span className="mt-1">
                                <TagOutlineIcon
                                  className="w-4 text-gray-500 hover:text-gray-700 cursor-pointer m-auto"
                                  title="Tag"
                                />
                                <div
                                  className={`absolute bg-blue-50 top-10 left-0 transition-all shadow-lg border border-blue-200 p-4 rounded-lg z-20
                                      ${
                                        popup.assessmentTags &&
                                        assessmentToEdit?.cannedTestId ===
                                          assessment.cannedTestId
                                          ? "visible"
                                          : "hidden"
                                      }
                                      `}
                                  onMouseOver={() => {
                                    setPopup({
                                      ...popup,
                                      assessmentTags: true,
                                    });
                                    setAssessmentToEdit(assessment);
                                  }}
                                  onMouseOut={() =>
                                    setPopup({
                                      ...popup,
                                      assessmentTags: false,
                                    })
                                  }
                                >
                                  <div className="flex gap-1 flex-wrap bg-white/50 p-1">
                                    {assessment.tags &&
                                      JSON.parse(assessment.tags).map((tag) => (
                                        <span
                                          className={`inline-block rounded-full text-xs shadow-sm py-0.5 px-2 overflow-hidden truncate flex
                                              bg-[${tag.Color}]
                                             ${TagColor.getTextColor(
                                               tag.Color
                                             )} `}
                                        >
                                          {tag.Name}
                                          <div className="flex w-4 ml-1 justify-center">
                                            <CancelIcon
                                              className="w-4 text-xs font-light cursor-pointer hover:text-red-500 transaction ease-in-out duration-300"
                                              onClick={() => {
                                                handleRemoveTag(
                                                  tag.UserTagId,
                                                  tag.Name,
                                                  [assessment.cannedTestId]
                                                );
                                              }}
                                            />
                                          </div>
                                        </span>
                                      ))}
                                  </div>
                                </div>
                              </span>
                              <>
                                {assessment.tags === null ? (
                                  <span>0</span>
                                ) : (
                                  <span className="text-blue-500">
                                    {JSON.parse(assessment.tags).length}
                                  </span>
                                )}
                              </>
                            </div>
                          </td>
                          <td className="text-sm mt-5 p-3 z-10 items-center">
                            <ContextMenu
                              items={getOptions().filter((r) =>
                                r.roles.some((r) => r === roleId)
                              )}
                              onChange={(
                                selectdItem,
                                assessmentId,
                                learnosityId,
                                activityName,
                                _,
                                _value?
                              ) => {
                                handleOptionChange(
                                  selectdItem,
                                  assessmentId,
                                  assessment.cannedTestId,
                                  learnosityId,
                                  activityName,
                                  assessment.subjectId
                                );
                              }}
                              learnosityId={assessment.learnosityIdEnglish}
                              activityName={getAssessmentName(assessment)}
                              onUpgrade={() => {
                                console.log("");
                              }}
                              isLocked={
                                assessment.isLocked &&
                                roleId === Constant.UserRoleId.SchoolTeacher
                              }
                              activityType={
                                constant.ContextMenuActivityType
                                  .PREMIUMASSESSMENT
                              }
                            />
                          </td>
                          <td className="text-sm mt-5  p-3">
                            {(roleId === Constant.UserRoleId.SchoolAdmin ||
                              (roleId === Constant.UserRoleId.SchoolTeacher &&
                                !assessment.isLocked)) && (
                              <UserAdd
                                title="Assign assessment"
                                className="w-4 text-gray-500 hover:text-gray-700 cursor-pointer m-auto"
                                onClick={() => {
                                  handleAssign(assessment);
                                }}
                              />
                            )}
                          </td>
                        </tr>
                        {assessments && assessments.totalRecords === 0 && (
                          <tr>
                            <td
                              className=" mt-5 p-3 text-left text-gray-500"
                              colSpan={6}
                            >
                              No record found
                            </td>
                          </tr>
                        )}
                      </Fragment>
                    ))}
                </tbody>
              </table>
            </div>
            <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
              {assessments?.totalRecords !== 0 && (
                <Pagination
                  totalRecords={assessments.totalRecords}
                  pageSize={pageSize}
                  onPageChange={onPageChange}
                  reset={resetPageNumber}
                  pageNumber={pageNumber}
                  bgColor="secondary-teal"
                />
              )}
              {assessments?.totalRecords !== 0 && (
                <PageSize
                  pageSize={pageSize}
                  onPageSizeChange={onPageSizeChange}
                  bgColor="secondary-teal"
                />
              )}
            </div>
          </div>
        </div>
      </div>

      {errorMessage.show && (
        <MessagePopup
          message={errorMessage.message}
          togglePopup={(show) => {
            showError("", show);
          }}
        />
      )}
      {activityPreview && (
        <QuestionPreview
          modal={openModal}
          show={popup.preview}
          changeValue={setPreviewModel}
          activityRef={activityPreview.previewId}
          activityIdPreview={activityPreview.activityIdPreview}
          activityName={activityPreview.activityName}
          isProjectorMode={activityPreview.isProjectorMode}
          subjectId={activityPreview.subjectId}
        ></QuestionPreview>
      )}
      {popup.manageAssessmentTags && (
        <ManagePremiumAssessmentTag
          userId={userId}
          premiumAssessments={selectedAssessments}
          showPopup={(show) => {
            setPopup({ ...popup, manageAssessmentTags: show });
          }}
          removeAssesmentSelection={(cannedTestId) => {
            handleSelectById(false, cannedTestId);
          }}
          refresh={searchAssessments}
        />
      )}
      {popup.print && printPreview && (
        <PrintDialog
          cannedTestId={printPreview.cannedTestId}
          learnosityId={printPreview.learnosityId}
          isAnswerKey={printPreview.isAnswerKey}
          activityName={printPreview.activityName}
          open={popup.print}
          code={""}
          onCancelClick={() => {
            handlePrint(0, "", "", false, false);
          }}
        ></PrintDialog>
      )}
    </>
  );
}

export default PremiumAssessment;

import { Fragment, useEffect, useState } from "react";
import { IAssessment } from "../../../../model/interface/assessment";
import { XIcon } from "@heroicons/react/solid";
import { getAssessmentById } from "../../../../api/teacher/assessment";
import _ from "lodash";

interface AssessmentDetailProps {
  assessmentId: number;
  toggleDetailPopup: (popupAction: boolean) => void;
}

function AssessmentDetail(props: AssessmentDetailProps) {
  const { assessmentId, toggleDetailPopup } = props;
  const [assessment, setAssessment] = useState<IAssessment>();

  useEffect(() => {
    getAssessmentById(assessmentId).then((response) => {
      setAssessment(response.data);
    });
  }, []);

  return (
    <Fragment>
      <div className="fixed inset-0 z-40 flex items-center bg-black bg-opacity-50 sm:items-center sm:justify-center">
        <div
          className="w-1/2 flex flex-col justify-between overflow-hidden h-auto max-h-80percent bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:max-w-6xl"
          role="dialog"
        >
          <header>
            {/* Header */}
            <div className="px-4 py-4 bg-gray-50">
              <div className="flex items-start justify-between space-x-3">
                <div className="space-y-1">
                  <div className="text-lg font-medium text-gray-900">
                    {assessment?.name}
                  </div>
                </div>
                <div className="h-7 flex items-center">
                  <button
                    type="button"
                    className="text-gray-400 hover:text-gray-500"
                    onClick={() => toggleDetailPopup(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
          </header>
          <div className="w-full relative overflow-auto px-4 py-1">
            <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
              <div className="p-4">
                <div className="flex items-start">
                  <div className=" w-0 flex-1 flex flex-col gap-y-1">
                    <div className="flex border">
                      <div className="flex flex-none flex-1 items-center w-28 justify-end bg-gray-100 px-4 py-2">
                        <label
                          htmlFor="first-name"
                          className="block text-sm font-medium text-gray-700 sm:mt-px"
                        >
                          Subject
                        </label>
                      </div>
                      <div className="flex items-center px-4 py-2">
                        <span className="text-sm text-gray-500">
                          {assessment?.subjectName}
                        </span>
                      </div>
                    </div>

                    <div className="flex border">
                      <div className="flex flex-none flex-1 items-center w-28 justify-end bg-gray-100 px-4 py-2">
                        <label
                          htmlFor="first-name"
                          className="block text-sm font-medium text-gray-700 sm:mt-px"
                        >
                          Domains
                        </label>
                      </div>
                      <div className="flex items-center px-4 py-2">
                        <span className="text-sm text-gray-500">
                          {assessment?.isSystemGenerated &&
                            "All Domains for the Subject"}
                          {!assessment?.isSystemGenerated &&
                            assessment?.questions &&
                            assessment?.questions.length > 0 &&
                            _.uniqBy(assessment?.questions, "domainId").map(
                              (question) => {
                                return <div>{question.domainName}</div>;
                              }
                            )}
                        </span>
                      </div>
                    </div>

                    <div className="flex border">
                      <div className="flex flex-none flex-1 items-center w-28 justify-end bg-gray-100 px-4 py-2">
                        <label
                          htmlFor="first-name"
                          className="block text-sm font-medium text-gray-700 sm:mt-px"
                        >
                          Standards
                        </label>
                      </div>
                      <div className="flex items-center px-4 py-2">
                        <span className="text-sm text-gray-500">
                          {assessment?.isSystemGenerated &&
                            "All Standards for the Subject"}
                          {!assessment?.isSystemGenerated &&
                            assessment?.questions &&
                            assessment?.questions.length > 0 &&
                            _.uniqBy(assessment?.questions, "standardId").map(
                              (question) => {
                                return <div>{question.standardName}</div>;
                              }
                            )}
                        </span>
                      </div>
                    </div>

                    <div className="flex border">
                      <div className="flex flex-none flex-1 items-center w-28 justify-end bg-gray-100 px-4 py-2">
                        <label
                          htmlFor="first-name"
                          className="block text-sm font-medium text-gray-700 sm:mt-px"
                        >
                          Questions
                        </label>
                      </div>
                      <div className="flex items-center px-4 py-2">
                        <span className="text-sm text-gray-500">
                          {assessment?.questions?.length}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <footer className="flex items-center justify-end px-4 py-4 sm:flex-row bg-gray-50 dark:bg-gray-800 mt-4">
            <div className="space-x-3 flex justify-end">
              <button
                onClick={() => toggleDetailPopup(false)}
                className="bg-primary-violet border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-dark-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3"
              >
                OK
              </button>
            </div>
          </footer>
        </div>
      </div>
    </Fragment>
  );
}

export default AssessmentDetail;

import { useEffect, useState } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import { BellIcon, PrinterIcon } from "../../../../assets/icons/index";
import constant from "../../../../utils/constant/routeConstant";
import Permissions from "../../../../utils/permissions";
import AllowedTo from "../../../shared/rbac";
import AccessDenied from "../../../shared/accessDenied";
import InstructionalResourceSubject from "./instructionalResourceSubject";
import DomainStandardList from "../../teacher/shared/domainStandardList";
import { connect } from "react-redux";
import routeConstant from "../../../../utils/constant/routeConstant";
import Breadcrumb from "../../teacher/shared/breadcrumb";
import { IBreadcrumb } from "../../../../model/common/breadcrumb";
import { getSuperSheets } from "../../../../api/teacher/superSheet";
import { ISuperSheetSearch } from "../../../../model/interface/superSheetSearch";
import { useTranslation } from "react-i18next";
import Loader from "../../../shared/loader";
import { Configuration } from "../../../../environment/setup";
import { PlayIcon } from "@heroicons/react/solid";
import UpgradeButtonControl from "../../../shared/customButtonControl";
import permissions from "../../../../utils/permissions";
import { checkIfUpgradeRequired } from "../../../shared/permissionHelper";
import { verifyUserHasFullAccessSubscription } from "../../../../api/account/accountApi";
import UtilConstant from "../../../../utils/constant/constant";

function InstructionalResources(props) {
  const [selectedSubject, setSelectedSubject] = useState<any>({
    label: "",
    value: 0,
  });
  const location = useLocation();
  const parameters = location.state as any;
  const [supersheetExist, setSupersheetExist] = useState<boolean>(false);
  const history = useHistory();
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [subscriptionIsPremium, setSubscriptionIsPremium] =
    useState<boolean>(false);

  const getSubscriptionPremiumStatus = () => {
    verifyUserHasFullAccessSubscription()
      .then((response) => setSubscriptionIsPremium(response.data))
      .catch((_) => setSubscriptionIsPremium(false));
  };

  useEffect(() => {
    if (parameters?.selectedSubject) {
      setSelectedSubject(parameters?.selectedSubject);
      searchSupersheets(parameters?.selectedSubject.value);
      getSubscriptionPremiumStatus();
    }
    return () => {
      localStorage.removeItem("bellRinger");
    };
  }, [parameters?.selectedSubject]);

  function searchSupersheets(id) {
    const superSheetSearch: ISuperSheetSearch = {
      teacherId: props.userContext.userId,
      roleId: props.userContext.roleId,
      subjectId: id !== "0" ? parseInt(id) : undefined,
      gradeId: 0,
      pageNumber: 1,
      pageSize: 10,
    };
    setLoading(true);
    getSuperSheets(superSheetSearch).then((r) => {
      setLoading(false);
      if (r.data.data.length > 0) {
        setSupersheetExist(true);
      } else {
        setSupersheetExist(false);
      }
    });
  }

  const onSubjectSelect = (id, name?) => {
    setSelectedSubject({ label: name, value: id });
    searchSupersheets(id);
  };
  const breadcrumbItems = () => {
    const items: Array<IBreadcrumb> = [
      { name: t("Breadcrumb.Home"), url: routeConstant.ROUTE_DASHBOARD },
      { name: t("Breadcrumb.InstructionalResources"), url: "" },
      {
        name: t("Breadcrumb.Subjects"),
        url: routeConstant.TeacherRoutes.InstructionalResourceSubjects,
      },
      { name: t("Breadcrumb.Resources"), url: "" },
    ];

    return items;
  };

  const handleAlienArenaClick = () => {
    history.push({
      pathname: routeConstant.ALIEN_ARENA_SESSIONS,
      state: { selectedSubject: selectedSubject },
    });
  };

  const upgradeBanner = (
    <span className="py-0.5 rounded-md bg-tertiary-color text-xs shadow-md text-gray-700 w-[100%]">
      Upgrade to View
    </span>
  );
  const resourceClassNames: string =
    "col-span-1 max-w-xs xxxl:max-w-none flex flex-col text-center bg-white rounded-lg shadow group" +
    (subscriptionIsPremium
      ? " bg-white hover:shadow-lg cursor-pointer"
      : " bg-opacity-50 cursor-not-allowed");

  return (
    <AllowedTo
      perform={Permissions.instructional_resources_view}
      no={() => <AccessDenied />}
    >
      {loading && <Loader />}
      {!parameters?.selectedSubject && (
        <InstructionalResourceSubject
          {...props}
          onSubjectSelect={onSubjectSelect}
        />
      )}

      {selectedSubject?.value > 0 && (
        <div className="min-w-0 w-full">
          <div className="lg:flex lg:items-center px-4 py-3 lg:px-8 border-b">
            <Breadcrumb items={breadcrumbItems()} />
            <div className="lg:w-[55%] text-lg font-medium flex gap-2 h-auto sm:h-10 items-center">
              <h1 className="text-primary-violet font-medium flex justify-start mb-0 pb-0">
                Instructional Resources
              </h1>
            </div>
          </div>
          <div className="px-2 sm:px-6 lg:px-8 py-6">
            <h3 className="pl-4 sm:pl-0 mb-2 text-lg">
              {selectedSubject?.label}
            </h3>
            <div className="bg-gray-100 flex flex-col xl:flex-row p-5 mb-6 xl:space-x-4 xxxl:space-x-6 rounded-lg">
              <div className="w-full xl:w-1/2 ">
                <h3 className="text-gray-700 text-sm font-semibold border-b border-gray-300 pb-1 mb-6">
                  Online Resources{" "}
                </h3>
                <ul className="grid grid-cols-1 gap-4 sm:gap-2 xxl:gap-4 xxxl:gap-6 sm:grid-cols-2 md:grid-cols-2 xxl:grid-cols-2">
                  <AllowedTo perform={Permissions.bell_ringer_view}>
                    <li className="col-span-1 max-w-xs xxxl:max-w-none flex flex-col text-center bg-white rounded-lg shadow group hover:shadow-lg cursor-pointer">
                      <Link
                        to={
                          constant.TeacherRoutes.BellRinger +
                          `?subjectId=${selectedSubject?.value}`
                        }
                      >
                        <div className="flex-1 flex flex-col p-8">
                          <div className="w-32 h-32 flex-shrink-0 mx-auto flex justify-center items-center rounded-full bg-gray-100">
                            <BellIcon
                              title="Bell ringer"
                              className="w-24 h-24 fill-current text-yellow-500 transform group-hover:scale-110 transition-all  flex-shrink-0"
                            />
                          </div>
                          <h3 className="mt-6 text-gray-900 text-sm font-medium">
                            Bell Ringer
                          </h3>
                          <dl className="mt-1 flex-grow flex flex-col justify-between">
                            <dd className="text-gray-500 text-sm">
                              Display a single question of your choice as a
                              warm-up activity or ticket out of the door.
                            </dd>
                          </dl>
                        </div>
                      </Link>
                    </li>
                  </AllowedTo>
                  <li className={resourceClassNames}>
                    <UpgradeButtonControl
                      controlType={"a"}
                      userContext={props?.userContext}
                      permissionName={permissions.ins_resource_videos_upgrade}
                      className={
                        !subscriptionIsPremium ? "cursor-not-allowed" : ""
                      }
                      isDissabledBySubscription={!subscriptionIsPremium}
                      onClick={() => {
                        history.push(
                          constant.TeacherRoutes.InstructionalResourceVideos +
                            `?subjectId=${selectedSubject?.value}`
                        );
                      }}
                    >
                      <div className="flex-1 flex flex-col p-8">
                        <div
                          className={
                            !subscriptionIsPremium ||
                            props.userContext?.roleId ===
                              UtilConstant.UserRoleId.FreeTeacher
                              ? "w-32 h-32 flex-shrink-0 mx-auto flex justify-center items-center rounded-full bg-gray-100 cursor-not-allowed opacity-50"
                              : "w-32 h-32 flex-shrink-0 mx-auto flex justify-center items-center rounded-full bg-gray-100"
                          }
                        >
                          <PlayIcon className="w-24 h-24 fill-current text-primary-violet transform group-hover:scale-110 transition-all  flex-shrink-0" />
                        </div>
                        <h3
                          className={
                            !subscriptionIsPremium ||
                            props.userContext?.roleId ===
                              UtilConstant.UserRoleId.FreeTeacher
                              ? "mt-6 text-gray-900 text-sm font-medium opacity-50"
                              : " mt-6 text-gray-900 text-sm font-medium"
                          }
                        >
                          Videos
                        </h3>
                        <dl
                          className={
                            "mt-1 flex-grow flex flex-col justify-between" +
                            (!subscriptionIsPremium ||
                            props.userContext?.roleId ===
                              UtilConstant.UserRoleId.FreeTeacher
                              ? "mt-1 flex-grow flex flex-col justify-between opacity-50 mb-2"
                              : "mt-1 flex-grow flex flex-col justify-between")
                          }
                        >
                          <dd className="text-gray-500 text-sm">
                            Browse all available instructional videos by title
                            and by domain.
                          </dd>
                        </dl>
                        {(!subscriptionIsPremium ||
                          checkIfUpgradeRequired(
                            permissions.ins_resource_videos_upgrade
                          )) &&
                          upgradeBanner}
                      </div>
                    </UpgradeButtonControl>
                  </li>
                  <li className="hidden col-span-1 max-w-xs xxxl:max-w-none flex flex-col text-center bg-white rounded-lg shadow group hover:shadow-lg cursor-pointer">
                    <UpgradeButtonControl
                      controlType={"button"}
                      userContext={props?.userContext}
                      permissionName={permissions.alien_arena_setup_upgrade}
                      className={""}
                      onClick={() => {
                        handleAlienArenaClick();
                      }}
                    >
                      <div className="flex-1 flex flex-col p-8">
                        <div className="w-32 h-32 flex-shrink-0 mx-auto flex justify-center items-center rounded-full bg-gray-100">
                          <img
                            title="Alien arena"
                            alt="alien arena"
                            src={`${Configuration.S3bucketImagePath}/images/alien_arena.png`}
                            className="w-28 transform group-hover:scale-110 transition-all  flex-shrink-0"
                          />
                        </div>
                        <h3 className="mt-6 text-gray-900 text-sm font-medium">
                          Alien Arena
                        </h3>
                        <dl className="mt-1 flex-grow flex flex-col justify-between">
                          <dd className="text-gray-500 text-sm">
                            Challenge your students with team competitions to
                            answer questions and earn the highest game score.
                          </dd>
                        </dl>
                      </div>
                      <div></div>
                    </UpgradeButtonControl>
                  </li>
                </ul>
                <h3 className="text-gray-700 text-sm font-semibold border-b border-gray-300 pb-1 my-6">
                  Printable Resources
                </h3>
                <ul className="grid grid-cols-1 gap-4 sm:gap-2 xxl:gap-4 xxxl:gap-6 sm:grid-cols-2 md:grid-cols-2 xxl:grid-cols-2">
                  <li className={resourceClassNames}>
                    <UpgradeButtonControl
                        controlType={"a"}
                        userContext={props?.userContext}
                        permissionName={permissions.printable_resource_upgrade}
                        className={
                          !subscriptionIsPremium ? "cursor-not-allowed" : ""
                        }
                        isDissabledBySubscription={!subscriptionIsPremium}
                        onClick={() => {
                          history.push(
                              constant.TeacherRoutes.ClassActivity +
                              `?subjectId=${selectedSubject?.value}&name=${selectedSubject.label}`
                          );
                        }}
                    >
                      <div className="flex-1 flex flex-col p-8">
                        <div
                            className={
                              !subscriptionIsPremium ||
                              props.userContext?.roleId ===
                              UtilConstant.UserRoleId.FreeTeacher
                                  ? "w-32 h-32 flex-shrink-0 mx-auto flex justify-center items-center rounded-full bg-gray-100 cursor-not-allowed opacity-50"
                                  : "w-32 h-32 flex-shrink-0 mx-auto flex justify-center items-center rounded-full bg-gray-100"
                            }
                        >
                          <PrinterIcon
                              title="Class activities"
                              className="w-24 h-24 transform group-hover:scale-110 transition-all  flex-shrink-0"
                          />
                        </div>
                        <h3
                            className={
                              !subscriptionIsPremium ||
                              props.userContext?.roleId ===
                              UtilConstant.UserRoleId.FreeTeacher
                                  ? "mt-6 text-gray-900 text-sm font-medium opacity-50"
                                  : "mt-6 text-gray-900 text-sm font-medium"
                            }
                        >
                          Class Activities
                        </h3>
                        <dl
                            className={
                              !subscriptionIsPremium ||
                              props.userContext?.roleId ===
                              UtilConstant.UserRoleId.FreeTeacher
                                  ? "mt-1 flex-grow flex flex-col justify-between opacity-50 mb-2"
                                  : "mt-1 flex-grow flex flex-col justify-between"
                            }
                        >
                          <dd className="text-gray-500 text-sm">
                            Browse an extensive collection of ready-to-print
                            worksheets and answer keys.
                          </dd>
                        </dl>
                        {(!subscriptionIsPremium ||
                                checkIfUpgradeRequired(
                                    permissions.printable_resource_upgrade
                                )) &&
                            upgradeBanner}
                      </div>
                    </UpgradeButtonControl>
                    <div></div>
                  </li>
                  {supersheetExist && (
                      <li className="col-span-1 max-w-xs xxxl:max-w-none flex flex-col text-center bg-white rounded-lg shadow group hover:shadow-lg cursor-pointer">
                        <UpgradeButtonControl
                            controlType={"a"}
                            userContext={props?.userContext}
                            permissionName={permissions.supersheets_upgrade}
                            className={""}
                            onClick={() => {
                              history.push(
                                  constant.TeacherRoutes.SuperSheets +
                                  `?subjectId=${selectedSubject?.value}&name=${selectedSubject.label}`
                              );
                            }}
                        >
                          <div className="flex-1 flex flex-col p-8">
                            <div
                                className="w-32 h-32 flex-shrink-0 mx-auto flex justify-center items-center rounded-full bg-gray-100">
                              <img
                                  title="Supersheets"
                                  alt="supersheet"
                                  src={`${Configuration.S3bucketImagePath}/images/SuperSheet.png`}
                                  className="w-28 transform group-hover:scale-110 transition-all  flex-shrink-0"
                              />
                            </div>
                            <h3 className="mt-6 text-gray-900 text-sm font-medium">
                              Supersheets
                            </h3>
                            <dl className="mt-1 flex-grow flex flex-col justify-between">
                              <dd className="text-gray-500 text-sm">
                                Print pre-built assessments that are aligned to
                                your state standards with item-answer rationals.
                              </dd>
                            </dl>
                            {checkIfUpgradeRequired(
                                permissions.supersheets_upgrade
                            ) && upgradeBanner}
                          </div>
                        </UpgradeButtonControl>
                      </li>
                  )}
                  <li className="col-span-1 max-w-xs xxxl:max-w-none flex flex-col text-center bg-white rounded-lg shadow group hover:shadow-lg cursor-pointer">
                    <a onClick={() => {
                      history.push(
                        constant.TeacherRoutes.StudentActivities +
                        `?subjectId=${selectedSubject?.value}&name=${selectedSubject.label}`)}}>
                      <div className="flex-1 flex flex-col p-8">
                        <div
                            className="w-32 h-32 flex-shrink-0 mx-auto flex justify-center items-center rounded-full bg-gray-100">
                          <img
                              title="Supersheets"
                              alt="supersheet"
                              src={`${Configuration.S3bucketImagePath}/images/activity-documents.svg`}
                              className="w-28 transform group-hover:scale-110 transition-all  flex-shrink-0"
                          />
                        </div>
                        <h3 className="mt-6 text-gray-900 text-sm font-medium">
                          Student Activities
                        </h3>
                        <dl className="mt-1 flex-grow flex flex-col justify-between">
                          <dd className="text-gray-500 text-sm">
                            Browse instructional materials to assist students with goal-setting, getting started, student engagement, and additional classroom resources.
                          </dd>
                        </dl>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="w-full xl:w-1/2">
                <DomainStandardList subjectId={selectedSubject?.value}/>
              </div>
            </div>
          </div>
        </div>
      )}
    </AllowedTo>
  );
}

export default connect<{}, {}, {}>((state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
}, {})(InstructionalResources);

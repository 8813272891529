import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Breadcrumb from "../../middleSchool/breadcrumb";
import { IBreadcrumb } from "../../../../../model/common/breadcrumb";
import RouteConstant from "../../../../../utils/constant/routeConstant";
import { IGames } from "../../../../../model/interface/game";
import { getGames } from "../../../../../api/teacher/alienArena";
import { connect } from "react-redux";
import {
  GetStudentSchoolGameScore,
  GetStudentGalaxyGameScore,
} from "../../../../../api/student/studentGameScoreApi";
import { UserContextState } from "../../../../../redux/actions/userContextAction";
import constant from "../../../../../utils/constant/constant";
import { Configuration } from "../../../../../environment/setup";

interface ILeaderboardProps {
  userContext: UserContextState;
  match: any;
}
function Leaderboard(props: ILeaderboardProps) {
  const [games, setGames] = useState<Array<IGames>>([]);
  const [schoolGameScores, setSchoolGameScores] = useState<any>([]);
  const [galaxyGameScores, setGalaxyGameScores] = useState<any>([]);
  const [selectedGameName, setSelectedGameName] = useState<string>("BlastOff");
  const [bgColor, setBgColor] = useState("#00b1fb");
  //const mode = atob(props.match.params.mode);
  const programMode = constant.ProgramMode.PL;
  // mode === constant.GameChooseMode.ElementaryLiftOffMyGalaxyStandard ||
  // mode === constant.GameChooseMode.KinderGartenLiftOffMyGalaxyStandard
  //   ? constant.ProgramMode.LO
  //   : constant.ProgramMode.PL;
  const bgColors = [
    "#00b1fb",
    "#d2ff00",
    "#00ffff",
    "#e962ff",
    "#00ff37",
    "#ff8300",
    "#ff6293",
    "#34D9A5",
    "#b86be6",
    "#6d92ff",
    "#e74c3c",
    "#5dade2",
  ];
  useEffect(() => {
    var tempGames = [
      {
        gameId: 0,
        name: "No game",
        isLocked: false,
        starsRequired: 0,
        isRocketRequired: false,
      },
    ];

    getGames(
      props.userContext?.userId,
      props.userContext?.gradeId,
      programMode === constant.ProgramMode.PL ? 1 : 2
    ).then((d) => {
      tempGames = tempGames.concat(d.data).filter(g => g.gameId !== 0);
      setGames(tempGames);
      handleGameSelect(tempGames[0]?.gameId, tempGames[0]?.name);
    });
  }, [props.userContext?.userId]);

  function handleGameSelect(gameId: number, gameName?: string) {
    setSelectedGameName(gameName || "");
    GetStudentSchoolGameScore(
      props.userContext?.userId,
      props.userContext?.gradeId,
      gameId,
      programMode === constant.ProgramMode.PL ? 1 : 2,
      props.userContext?.schoolId
    ).then((d) => {
      var gameScoresData = d.data;
      setSchoolGameScores(gameScoresData.gameScores);
    });
    GetStudentGalaxyGameScore(
      props.userContext?.userId,
      props.userContext?.gradeId,
      gameId,
      programMode === constant.ProgramMode.PL ? 1 : 2
    ).then((d) => {
      var gameScoresData = d.data;
      setGalaxyGameScores(gameScoresData.gameScores);
    });
  }

  const { t } = useTranslation();

  const breadcrumbItems = () => {
    const items: Array<IBreadcrumb> = [
      {
        name: "Home",
        url: RouteConstant.StudentRoute.Home,
        textColor: "text-gray-500",
      },
      {
        name: t("Breadcrumb.Leaderboard"),
        textColor: "text-gray-500",
        url: "",
      },
    ];

    return items;
  };

  return (
    <Fragment>
      {/* main section */}
      <main className="w-full p-5">
        <nav className="pb-3 border-b" aria-label="Breadcrumb">
          <Breadcrumb items={breadcrumbItems()} />
          <section className="text-lg flex justify-between gap-2 h-auto sm:h-10 items-center">
            <h2 className="text-gray-700  flex justify-start font-medium">
              {" "}
              {t("Shared.StudentSideBar.Leaderboard")}
            </h2>
          </section>
        </nav>

        <section className="mt-10">
          <div className="grid grid-cols-10 gap-6">
            <div className="col-span-10 md:col-span-4 lg:col-span-5 xl:col-span-4 2xl:col-span-5 px-4 ">
              <div className="grid grid-cols-1 xs:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-4 mt-12 gap-6">
                {games.map((game, index) => {
                  return (
                    game.gameId !== 0 && (
                      <div
                        key={game.gameId}
                        className={
                          (bgColors[index] === bgColor ? "shadow-2xl" : "") +
                          ` block px-4 pt-4 pb-6 rounded-2xl relative hover:shadow-2xl transition ease-in-out duration-300 mb-14` 
                        }
                        style={{ backgroundColor: bgColors[index] }}
                      >
                        <span className={`-top-12 absolute left-0 right-0 items-center ${game.isLocked ? " filter grayscale" : ""}`} >
                          <img
                            className="w-24 h-24 flex-shrink-0 m-auto rounded-2xl "
                            src={`${
                              Configuration.S3bucketImagePath
                            }/images/game-thumbnail/${
                              game.imageurl?.toString().split("/")[
                                game.imageurl?.toString().split("/").length - 1
                              ]
                            }`}
                            alt="game"
                            title="game"
                          />
                          {game.isLocked && (
                            <span title="Locked" className="text-black w-7 h-7 bg-white/90 absolute rounded-full p-1.5 -bottom-3 left-0 flex justify-center right-0 mx-auto shadow-lg border">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor"
                                className="w-4"
                                viewBox="0 0 16 16"
                              >
                                <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2z" />
                              </svg>
                            </span>
                          )}
                        </span>
                        <div className="text-center mt-8 flex flex-col  h-full justify-around">
                          <h2 className="text-base py-2">{game.name}</h2>
                          <span
                            className="py-2 px-5 mb-3 rounded-full text-xs  text-gray-900 hover:text-gray-900 bg-white shadow-lg transition ease-in-out duration-150"
                            onClick={() => {
                              if (!game.isLocked) {
                                handleGameSelect(game.gameId, game.name);
                                setBgColor(bgColors[index]);
                              }
                            }}
                          >
                            <span className=" ">View Score</span>
                          </span>
                        </div>
                      </div>
                    )
                  );
                })}
              </div>
            </div>

            <div className="col-span-10 md:col-span-6 lg:col-span-5 xl:col-span-6 2xl:col-span-5 h-full">
              <div
                className="h-full  rounded-2xl relative sm:mt-5"
                style={{ backgroundColor: bgColor }}
              >
                <div className=" mx-auto px-2 py-2 pt-6 sm:flex-col w-full sm:px-6 lg:px-6">
                  <h3 className="text-lg font-semibold mb-1 inline-block text-black opacity-70">
                  {selectedGameName} {t("Leaderboard.SchoolRank")}
                  </h3>
                  <div className="overflow-x-auto rounded-lg mb-5">
                    <table className="table-fixed w-full text-sm bg-white/50 rounded-lg">
                      <thead className=" rounded-2xl overflow-hidden">
                        <tr className="">
                          <th className="w-14 px-3 py-3 text-center text-xs font-medium text-gray-500 capitalize tracking-wider bg-gray-100">
                            {t("Leaderboard.Place")}
                          </th>
                          <th className="px-3 py-3 text-left text-xs font-medium text-gray-500 capitalize tracking-wider bg-gray-100">
                            {t("Leaderboard.Name")}
                          </th>
                          <th className="px-3 py-3 w-24 text-center text-xs font-medium text-gray-500 capitalize tracking-wider bg-gray-100 ">
                            {t("Leaderboard.Score")}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {schoolGameScores.map((gameScore, index) => {
                          return (
                            <tr
                              className={
                                index % 2 === 0 ? "bg-white" : "bg-opacity-90"
                              }
                              key={index}
                            >
                              <td className="px-3 py-2 text-center text-sm text-gray-500">
                                {gameScore.position}
                              </td>
                              <td className="px-3 py-2 text-sm text-gray-500">
                                {gameScore.studentFirstName.charAt(0)}{gameScore.studentLastName.charAt(0)}
                              </td>
                              <td className="px-3 py-2 text-center text-sm text-gray-500">
                                {gameScore.score}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className=" mx-auto space-y-3 px-2 py-2 pt-0 sm:flex-col w-full sm:px-6 lg:px-6">
                  <h3 className="text-lg font-semibold mb-1 inline-block text-black opacity-70">
                    {selectedGameName}
                  </h3>
                  <div className="overflow-x-auto rounded-lg mb-5">
                    <table className="table-fixed w-full text-sm bg-white/50 rounded-lg">
                      <thead className=" rounded-2xl overflow-hidden">
                        <tr className="bg-white px-2 rounded-2xl overflow-hidden space-x-4 rounded-2xl overflow-hidden">
                          <th className="w-14 px-3 py-3 text-center text-xs font-medium text-gray-500 capitalize tracking-wider bg-gray-100  ">
                            {t("Leaderboard.Place")}
                          </th>
                          <th className="px-3 py-3 text-left text-xs font-medium text-gray-500 capitalize tracking-wider bg-gray-100">
                            {t("Leaderboard.SchoolName")}
                          </th>
                          <th className="w-1/2 px-3 py-3 text-left text-xs font-medium text-gray-500 capitalize tracking-wider bg-gray-100">
                            {t("Leaderboard.Name")}
                          </th>
                          <th className="px-3 py-3 w-24 text-center text-xs font-medium text-gray-500 capitalize tracking-wider bg-gray-100 ">
                            {t("Leaderboard.Score")}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {galaxyGameScores.map((gameScore, index) => {
                          return (
                            <tr
                              className={
                                index % 2 === 0 ? "bg-white" : "bg-opacity-90"
                              }
                              key={index}
                            >
                              <td className="px-3 py-2 text-center text-sm text-gray-500">
                                {gameScore.position}
                              </td>
                              <td className="px-3 py-2 text-sm text-gray-500">
                                {" "}
                                {gameScore.schoolName}
                              </td>
                              <td className="px-3 py-2 text-sm text-gray-500">
                                {" "}
                                {gameScore.studentFirstName.charAt(0)}{gameScore.studentLastName.charAt(0)}
                              </td>
                              <td className="px-3 py-2 text-center text-sm text-gray-500">
                                {" "}
                                {gameScore.score}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      {/* main section end */}
    </Fragment>
  );
}

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(Leaderboard);

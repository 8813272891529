import classNames from "classnames";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

import { getStudentRewards } from "../../../../api/student/studentApi";
import { getGames } from "../../../../api/teacher/alienArena";
import { LegendVideoIcon, LockIcon } from "../../../../assets/icons";
import "../../../../css/style.css";
import { Configuration } from "../../../../environment/setup";
import { IBreadcrumb } from "../../../../model/common/breadcrumb";
import { IGames } from "../../../../model/interface/game";
import Profile from "../../../../model/users/profile";
import { UserContextState } from "../../../../redux/actions/userContextAction";
import constant from "../../../../utils/constant/constant";
import routeConstant from "../../../../utils/constant/routeConstant";
import RouteConstant from "../../../../utils/constant/routeConstant";
import { getBlasterValues } from "../../../../utils/studentHelper";
import { paramDecode, paramEncode } from "../../../../utils/urlHelper";
import { useConfirmation } from "../../../shared/confirmation/confirmationService";
import Breadcrumb from "../middleSchool/breadcrumb";

interface IGameChooseProps {
  userContext: UserContextState;
  profile: Profile;
  match: any;
}

function GameChooseWrapper(props: IGameChooseProps) {
  const mode = atob(props.match.params.mode);
  const standardId = parseInt(atob(props.match.params.standardId));
  const standardName = paramDecode(props.match.params.standardName);

  const [studentGradeMode, setStudentGradeMode] =
    useState<string>("Elementary");

  const { t } = useTranslation();

  useEffect(() => {
    setStudentMode();
  }, [props]);

  const setStudentMode = () => {
    if (
      mode === constant.GameChooseMode.ElementaryStudyPlan ||
      mode === constant.GameChooseMode.ElementaryLiftOffMyGalaxyStandard
    ) {
      setStudentGradeMode("Elementary");
    } else if (
      mode === constant.GameChooseMode.KinderGartenLiftOffMyGalaxyStandard ||
      mode === constant.GameChooseMode.KinderGartenStudyPlan
    ) {
      setStudentGradeMode("Kindergarten");
    } else if (
      mode === constant.GameChooseMode.MiddleSchoolStudyPlan ||
      mode === constant.GameChooseMode.MiddleSchoolSummativeTest
    ) {
      setStudentGradeMode("Middleschool");
    }
  };
  const breadcrumbItems = () => {
    var homePath: string = "";
    var color: string = "text-white";
    if (
      mode === constant.GameChooseMode.ElementaryStudyPlan ||
      mode === constant.GameChooseMode.ElementaryLiftOffMyGalaxyStandard
    ) {
      homePath = routeConstant.StudentRoute.Home;
      color = "text-gray-600";
    } else if (
      mode === constant.GameChooseMode.KinderGartenLiftOffMyGalaxyStandard ||
      mode === constant.GameChooseMode.KinderGartenStudyPlan
    ) {
      homePath = routeConstant.KindergartenStudentRoutes.Home;
      color = "text-gray-600";
    } else if (
      mode === constant.GameChooseMode.MiddleSchoolStudyPlan ||
      mode === constant.GameChooseMode.MiddleSchoolSummativeTest
    ) {
      homePath = RouteConstant.MiddleSchool.assignments;
    }
    const items: Array<IBreadcrumb> = [
      {
        name: t("Breadcrumb.Home"),
        url: homePath,
        textColor: color,
      },
      {
        name: t("Breadcrumb.GameChoose"),
        textColor: color,
        url: "",
      },
    ];

    return items;
  };

  return (
    <Fragment>
      {/* --------[ Elementary Layout ]---------*/}
      {/* ---------------------------------------- */}
      {studentGradeMode === "Elementary" && (
        <>
          <div className="bg-white h-full mb-2 rounded-2xl p-5">
            <div className=" py-3 lg:px-8 border-b">
              <Breadcrumb items={breadcrumbItems()} />
            </div>
            <div>
              <h1 className="text-lg mt-2 px-2 pb-3 pt-2">
                {standardId !== 0 && <> {standardName ?? ""}</>}
              </h1>
            </div>
            <GameChoose
              match={props.match}
              profile={props.profile}
              userContext={props.userContext}
            />
          </div>
        </>
      )}
      {/* --------[ Middle School Layout ]---------*/}
      {/* ---------------------------------------- */}
      {studentGradeMode === "Middleschool" && (
        <>
          <>
            <div className="px-4 py-3 lg:px-8 border-b">
              <Breadcrumb items={breadcrumbItems()} />
              <div className="text-lg flex justify-between gap-2 h-auto sm:h-10 items-center">
                <h1 className="text-gray-50 font-medium flex justify-start">
                  Choose your Game
                </h1>
              </div>
            </div>
            <div className="px-4 py-4 lg:px-8">
              <h1 className="text-lg mt-2 px-2 pb-3 pt-2 text-white">
                {standardId !== 0 && <> {standardName ?? ""}</>}
              </h1>
              <div className="bg-white rounded-lg py-4 px-4">
                <GameChoose
                  match={props.match}
                  profile={props.profile}
                  userContext={props.userContext}
                />
              </div>
            </div>
          </>
        </>
      )}

      {/* --------[ KinderGarten Layout ]---------*/}
      {/* ---------------------------------------- */}
      {studentGradeMode === "Kindergarten" && (
        <>
          <div className="bg-white h-full mx-8 mb-2 rounded-2xl p-5">
            <Breadcrumb items={breadcrumbItems()} />
            <div>
              <h1 className="text-lg mt-2 px-2 pb-3 pt-2">
                {standardId !== 0 && <> {standardName ?? ""}</>}
              </h1>
            </div>
            <GameChoose
              match={props.match}
              profile={props.profile}
              userContext={props.userContext}
            />
          </div>
        </>
      )}
    </Fragment>
  );
}

function GameChoose(props: IGameChooseProps) {
  const defaultGame: IGames = {
    gameId: 0,
    isLocked: true,
    isRocketRequired: false,
    starsRequired: 0,
    isBlasterNeeded: false,
  };
  const { t } = useTranslation();
  const location = useLocation<any>();
  const confirm = useConfirmation();
  const [games, setGames] = useState<Array<IGames>>([]);
  const [selectedRocket, setSelectedRocket] = useState<number>(0);
  const [selectedBlaster, setSelectedBlaster] = useState<any>({
    id: 1,
    level: 1,
    isEnabled: true,
    path: "",
    type: "cannon",
  });
  const [studentGradeMode, setStudentGradeMode] =
    useState<string>("Elementary");
  const [selectedGame, setSelectedGame] = useState<IGames>(defaultGame);
  const [rockets, setRockets] = useState<Array<any>>([]);
  const [blasters, setBlasters] = useState<Array<any>>([]);
  const standardId = parseInt(atob(props.match.params.standardId));
  const subjectId = props.match.params.subjectId
    ? parseInt(atob(props.match.params.subjectId))
    : 0;
  const subjectName = atob(props.match.params.subjectName ?? "");
  const standardName = paramDecode(props.match.params.standardName);
  const isStandardGrouped = atob(props.match.params.isStandardGrouped ?? "");
  const isRecommendedStandard =
    props.match.params.isRecommendedStandard &&
    props.match.params.isRecommendedStandard !== ":isRecommendedStandard"
      ? atob(props.match.params.isRecommendedStandard) === "true"
        ? true
        : false
      : false;

  const mode = atob(props.match.params.mode);
  const history = useHistory();
  const query = new URLSearchParams(useLocation().search);
  const programMode =
    mode === constant.GameChooseMode.ElementaryLiftOffMyGalaxyStandard ||
    mode === constant.GameChooseMode.KinderGartenLiftOffMyGalaxyStandard ||
    mode === constant.GameChooseMode.MiddleSchoolLiftOffMyGalaxyStandard
      ? constant.ProgramMode.LO
      : constant.ProgramMode.PL;

  const rocketSlidersettings = {
    dots: false,
    infinite: true,
    autoplaySpeed: 300,
    autoplay: false,
    slidesToShow: 6,
    slidesToScroll: 4,
    lazyLoad: false,
  };
  const blasterSlidersettings = {
    dots: false,
    infinite: true,
    autoplaySpeed: 300,
    autoplay: false,
    slidesToShow: 6,
    slidesToScroll: 4,
    lazyLoad: false,
  };
  const kindergartenButtonCssClass =
    "align-bottom inline-flex font-bold items-center justify-center cursor-pointer leading-5 transition-colors duration-150  focus:outline-none px-5 py-3 rounded-lg text-lg text-white bg-primary-violet hover:shadow-lg hover:bg-dark-violet";
  const ElementaryButtonCssClass =
    "bg-[#a3ed2b] shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-gray-700 hover:bg-opacity-80 hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 rounded-lg";
  const MiddleschoolButtonCssClass =
    "bg-primary-violet border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-dark-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 rounded-lg";

  const setStudentMode = () => {
    if (
      mode === constant.GameChooseMode.ElementaryStudyPlan ||
      mode === constant.GameChooseMode.ElementaryLiftOffMyGalaxyStandard
    ) {
      setStudentGradeMode("Elementary");
    } else if (
      mode === constant.GameChooseMode.KinderGartenLiftOffMyGalaxyStandard ||
      mode === constant.GameChooseMode.KinderGartenStudyPlan
    ) {
      setStudentGradeMode("Kindergarten");
    } else if (
      mode === constant.GameChooseMode.MiddleSchoolStudyPlan ||
      mode === constant.GameChooseMode.MiddleSchoolSummativeTest
    ) {
      setStudentGradeMode("Middleschool");
    }
  };

  useEffect(() => {
    setStudentMode();
  }, [props.match.params.mode]);

  useEffect(() => {
    var tempGames = [
      {
        gameId: 0,
        name: "No game",
        isLocked: false,
        starsRequired: 0,
        isRocketRequired: false,
      },
    ];

    getGames(
      props.userContext?.userId,
      props.userContext?.gradeId,
      programMode === constant.ProgramMode.PL ? 1 : 2
    ).then((d) => {
      tempGames = tempGames.concat(d.data);
      setGames(tempGames);
    });
    getStudentRewards(props.userContext?.userId ?? 0).then((d) => {
      if (programMode === constant.ProgramMode.PL) {
        setRocketValues(d.data.plRockets);
        setBlasterValues(d.data.plBlasterSetting, d.data.plStarReward);
      } else {
        setRocketValues(d.data.loRockets);
        setBlasterValues(d.data.loBlasterSetting, d.data.loStarReward);
      }
    });
  }, [props.userContext?.userId]);

  function setRocketValues(rockets: Array<any>) {
    const rocketImageDefaultPath: string = `${Configuration.S3bucketImagePath}/images/student/kindergarten/rockets/`;
    const tempRocket: Array<any> = [];
    rockets?.map((rocket, index) => {
      tempRocket.push({
        rocketId: rocket.rocketId,
        path: rocketImageDefaultPath + "rocket" + index.toString() + ".png",
        isEnabled: !rocket.isLocked,
      });
      return null;
    });
    setRockets(tempRocket);
  }

  function setBlasterValues(plBlasterSetting: any, reward: any) {
    const blasterValues = getBlasterValues(plBlasterSetting, reward);
    setBlasters(blasterValues);
  }
  function getParameterByName(name, url = window.location.href) {
    name = name.replace(/[[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }
  function startAssignment() {
    if (mode === constant.GameChooseMode.ElementaryStudyPlan) {
      history.push(
        RouteConstant.StudentRoute.StudyPlan.replace(
          ":standardId",
          encodeURI(btoa(standardId.toString()))
        )
          .replace(":standardName", encodeURI(paramEncode(standardName)))
          .replace(":gameId", encodeURI(btoa(selectedGame.gameId.toString())))
          .replace(":rocketId", encodeURI(btoa(selectedRocket.toString())))
          .replace(
            ":blasterId",
            encodeURI(btoa(selectedBlaster.level.toString()))
          )
          .replace(":subjectId", encodeURI(btoa(subjectId.toString())))
          .replace(":blasterType", encodeURI(btoa(selectedBlaster.type)))
          .replace(
            ":isRecommendedStandard",
            encodeURI(btoa(isRecommendedStandard.toString()))
          )
      );
    } else if (mode === constant.GameChooseMode.KinderGartenStudyPlan) {
      history.push(
        RouteConstant.KindergartenStudentRoutes.StudyPlan.replace(
          ":standardId",
          encodeURI(btoa(standardId.toString()))
        )
          .replace(":standardName", encodeURI(paramEncode(standardName)))
          .replace(":gameId", encodeURI(btoa(selectedGame.gameId.toString())))
          .replace(":rocketId", encodeURI(btoa(selectedRocket.toString())))
          .replace(
            ":blasterId",
            encodeURI(btoa(selectedBlaster.level.toString()))
          )
          .replace(":subjectId", encodeURI(btoa(subjectId.toString())))
          .replace(":blasterType", encodeURI(btoa(selectedBlaster.type)))
          .replace(
            ":isRecommendedStandard",
            encodeURI(btoa(isRecommendedStandard.toString()))
          ) +
          "&s=" +
          getParameterByName("s") +
          "&c=" +
          getParameterByName("c")
      );
    } else if (mode === constant.GameChooseMode.MiddleSchoolStudyPlan) {
      history.push(
        RouteConstant.MiddleSchool.StudyPlan.replace(
          ":standardId",
          encodeURI(btoa(standardId.toString()))
        )
          .replace(":standardName", encodeURI(paramEncode(standardName)))
          .replace(":gameId", encodeURI(btoa(selectedGame.gameId.toString())))
          .replace(":rocketId", encodeURI(btoa(selectedRocket.toString())))
          .replace(
            ":blasterId",
            encodeURI(btoa(selectedBlaster.level.toString()))
          )
          .replace(":subjectId", encodeURI(btoa(subjectId.toString())))
          .replace(":blasterType", encodeURI(btoa(selectedBlaster.type)))
      );
    } else if (mode === constant.GameChooseMode.MiddleSchoolSummativeTest) {
      var testSize = query.get("testSize")?.toString();

      history.push(
        RouteConstant.MiddleSchool.SummativeTest.replace(
          ":subjectId",
          encodeURI(btoa(subjectId.toString()))
        )
          .replace(":testSize", encodeURI(testSize ?? ""))
          .replace(":gameId", encodeURI(btoa(selectedGame.gameId.toString())))
          .replace(":rocketId", encodeURI(btoa(selectedRocket.toString())))
          .replace(
            ":blasterId",
            encodeURI(btoa(selectedBlaster.level.toString()))
          )
          .replace(":blasterType", encodeURI(btoa(selectedBlaster.type)))
      );
    } else if (mode === constant.GameChooseMode.MiddleSchoolDomainTest) {
      var domainId = query.get("domainId")?.toString();

      history.push(
        RouteConstant.MiddleSchool.DomainTest.replace(
          ":subjectId",
          encodeURI(btoa(subjectId.toString()))
        )
          .replace(":domainId", encodeURI(domainId ?? "0"))
          .replace(":gameId", encodeURI(btoa(selectedGame.gameId.toString())))
          .replace(":rocketId", encodeURI(btoa(selectedRocket.toString())))
          .replace(
            ":blasterId",
            encodeURI(btoa(selectedBlaster.level.toString()))
          )
          .replace(":blasterType", encodeURI(btoa(selectedBlaster.type)))
      );
    } else if (mode === constant.GameChooseMode.MiddleSchoolCannedTest) {
      var cannedTestId = query.get("cannedTestId")?.toString();

      history.push(
        RouteConstant.MiddleSchool.CannedTestGC.replace(
          ":subjectId",
          encodeURI(btoa(subjectId.toString()))
        )
          .replace(":cannedTestId", encodeURI(cannedTestId ?? "0"))
          .replace(":gameId", encodeURI(btoa(selectedGame.gameId.toString())))
          .replace(":rocketId", encodeURI(btoa(selectedRocket.toString())))
          .replace(
            ":blasterId",
            encodeURI(btoa(selectedBlaster.level.toString()))
          )
          .replace(":blasterType", encodeURI(btoa(selectedBlaster.type)))
      );
    } else if (
      mode === constant.GameChooseMode.KinderGartenLiftOffMyGalaxyStandard
    ) {
      history.push(
        RouteConstant.LiftOff.Kindergarten.MyGalaxyActivity.replace(
          ":subjectId",
          encodeURI(btoa(subjectId.toString()))
        )
          .replace(":standardId", encodeURI(btoa(standardId.toString())))
          .replace(":standardName", encodeURI(paramEncode(standardName)))
          .replace(":gameId", encodeURI(btoa(selectedGame.gameId.toString())))
          .replace(":rocketId", encodeURI(btoa(selectedRocket.toString())))
          .replace(
            ":blasterId",
            encodeURI(btoa(selectedBlaster.level.toString()))
          )
          .replace(":blasterType", encodeURI(btoa(selectedBlaster.type)))
          .replace(":subjectName", encodeURI(btoa(subjectName ?? "")))
          .replace(
            ":isStandardGrouped",
            encodeURI(btoa(isStandardGrouped ?? "false"))
          )
      );
    } else if (
      mode === constant.GameChooseMode.ElementaryLiftOffMyGalaxyStandard
    ) {
      history.push(
        RouteConstant.LiftOff.Elementary.MyGalaxyActivity.replace(
          ":subjectId",
          encodeURI(btoa(subjectId.toString()))
        )
          .replace(":standardId", encodeURI(btoa(standardId.toString())))
          .replace(":standardName", encodeURI(paramEncode(standardName)))
          .replace(":gameId", encodeURI(btoa(selectedGame.gameId.toString())))
          .replace(":rocketId", encodeURI(btoa(selectedRocket.toString())))
          .replace(
            ":blasterId",
            encodeURI(btoa(selectedBlaster.level.toString()))
          )
          .replace(":blasterType", encodeURI(btoa(selectedBlaster.type)))
          .replace(":subjectName", encodeURI(btoa(subjectName ?? "")))
          .replace(
            ":isStandardGrouped",
            encodeURI(btoa(isStandardGrouped ?? "false"))
          )
      );
    } else if (
      mode === constant.GameChooseMode.MiddleSchoolLiftOffMyGalaxyStandard
    ) {
      history.push(
        RouteConstant.LiftOff.MiddleSchool.MyGalaxyActivity.replace(
          ":subjectId",
          encodeURI(btoa(subjectId.toString()))
        )
          .replace(":standardId", encodeURI(btoa(standardId.toString())))
          .replace(":standardName", encodeURI(paramEncode(standardName)))
          .replace(":gameId", encodeURI(btoa(selectedGame.gameId.toString())))
          .replace(":rocketId", encodeURI(btoa(selectedRocket.toString())))
          .replace(
            ":blasterId",
            encodeURI(btoa(selectedBlaster.level.toString()))
          )
          .replace(":blasterType", encodeURI(btoa(selectedBlaster.type)))
          .replace(":subjectName", encodeURI(btoa(subjectName ?? "")))
          .replace(
            ":isStandardGrouped",
            encodeURI(btoa(isStandardGrouped ?? "false"))
          )
      );
    } else if (mode === constant.GameChooseMode.ElementaryPracticeTest) {
      const gameSettings = {
        gameId: selectedGame.gameId,
        rocketId: selectedRocket,
        blasterId: selectedBlaster.level,
        blasterType: selectedBlaster.type,
      };
      history.push({
        pathname: location?.state?.assignmentUrl,
        state: {
          prebuildTestType: location.state.prebuildTestType,
          gameSettings: gameSettings,
        },
      });
    } else if (mode === constant.GameChooseMode.MiddleSchoolPracticeTest) {
      const gameSettings = {
        gameId: selectedGame.gameId,
        rocketId: selectedRocket,
        blasterId: selectedBlaster.level,
        blasterType: selectedBlaster.type,
      };
      history.push({
        pathname: location?.state?.assignmentUrl,
        state: {
          prebuildTestType: location.state.prebuildTestType,
          gameSettings: gameSettings,
        },
      });
    } else if (mode === constant.GameChooseMode.KinderGartenPracticeTest) {
      const gameSettings = {
        gameId: selectedGame.gameId,
        rocketId: selectedRocket,
        blasterId: selectedBlaster.level,
        blasterType: selectedBlaster.type,
      };
      history.push({
        pathname: location?.state?.assignmentUrl,
        state: {
          prebuildTestType: location.state.prebuildTestType,
          gameSettings: gameSettings,
        },
      });
    }
  }

  function handleGameSelection(game: IGames) {
    if (!game.isLocked) {
      setSelectedGame(game);
    } else {
      confirm({
        variant: "info",
        title: "",
        description:
          t("GameChoose.GameLocked") +
          Math.round(game.starsRequired) +
          t("GameChoose.UnlockGame"),
      });
    }
  }

  return (
    <Fragment>
      <div className="p-2 m-auto">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 xl:grid-cols-5 2xl:grid-cols-5 xxxl:grid-cols-5 gap-4 lg:gap-0 xl:gap-0 2xl:gap-4 xxxl:gap-8 xxxl:gap-y-12 mb-4">
          {games.map((game) => {
            return (
              <div key={game.gameId}>
                <button
                  onClick={() => {
                    handleGameSelection(game);
                  }}
                  className={`${
                    selectedGame.gameId === game.gameId
                      ? "h-36 w-60 lg:w-44 lg:h-20 xl:w-48 xl:h-24 2xl:w-56 2xl:h-32 xxxl:h-36 xxxl:w-60 rounded-lg cursor-pointer border-8 border-[#0be600] bg-primary-violet overflow-hidden transition-all relative"
                      : "rounded-lg w-60 h-36 lg:w-44 lg:h-20 xl:w-48 xl:h-24 2xl:w-56 2xl:h-32 xxxl:h-36 xxxl:w-60 cursor-pointer overflow-hidden group relative"
                  }`}
                  key={game.gameId}
                >
                  {game.gameId === 0 && (
                    <img
                      className=""
                      src={`${Configuration.S3bucketImagePath}/images/game-thumbnail/no-game.jpg`}
                      alt="no game"
                      title="No game"
                    />
                  )}
                  {game.gameId !== 0 && (
                    <div className="flex items-center justify-center">
                      <img
                        title="Game"
                        className={`w-full h-36 lg:h-20 xl:h-24 2xl:h-32 xxxl:h-36 hover:opacity-70
                            ${game.isLocked ? "opacity-70" : ""}
                          `}
                        src={`${
                          Configuration.S3bucketImagePath
                        }/images/game-thumbnail/${
                          game.imageurl?.toString().split("/")[
                            game.imageurl?.toString().split("/").length - 1
                          ]
                        }`}
                        alt="game"
                      />
                      <div className="absolute bg-white p-1 rounded-lg">
                        {(game.isLocked && (
                          <LockIcon
                            title="Game is locked"
                            className="w-8 h-8"
                          />
                        )) || (
                          <LegendVideoIcon
                            title="Play game"
                            className="w-8 h-8"
                          />
                        )}
                      </div>
                    </div>
                  )}

                  <div className="text-base absolute bottom-0 text-white p-1 flex justify-center bg-primary-violet w-full transform transition duration-500 translate-y-20 group-hover:opacity-100 group-hover:translate-y-0">
                    {t("Leaderboard." + game.name + "")}
                  </div>
                </button>
              </div>
            );
          })}
        </div>
        <div className="pl-8 gap-4 grid grid-cols-2">
          <div>
            <div className=" py-2 w-11/12">
              <Slider
                {...rocketSlidersettings}
                //className={"opacity-70 cursor-not-allowed"}
              >
                {rockets?.map((item) => {
                  return (
                    <div key={item.rocketId} className="flex">
                      <div key={item.rocketId} className="flex">
                        <button
                          disabled={
                            item.isEnabled && selectedGame.isRocketRequired
                              ? false
                              : true
                          }
                          className={classNames(
                            "disabled:opacity-50 flex border-2 border-gray-200 p-1 items-center rounded w-28 h-20  bg-gray-50  inline text-center ml-2 group transition-all",
                            selectedRocket === item.rocketId &&
                              item.isEnabled &&
                              selectedGame.isRocketRequired
                              ? "border-primary-violet bg-white transition-all"
                              : "",
                            item.isEnabled && selectedGame.isRocketRequired
                              ? "cursor-pointer hover:border-primary-violet hover:bg-white"
                              : "cursor-not-allowed"
                          )}
                          onClick={() => {
                            if (item.isEnabled)
                              setSelectedRocket(item.rocketId);
                          }}
                        >
                          <img
                            srcSet={item.path}
                            className="h-16 mx-auto group-hover:h-14 transition-all"
                            alt="rocket"
                            title="Rocket"
                          ></img>
                        </button>
                      </div>
                    </div>
                  );
                })}
              </Slider>
            </div>
          </div>
          <div>
            <div className=" py-2 w-11/12">
              <Slider {...blasterSlidersettings}>
                {blasters.map((item, index) => {
                  return (
                    <div key={index} className="flex">
                      <div key={index} className="flex">
                        <button
                          disabled={
                            item.isEnabled && selectedGame.isBlasterNeeded
                              ? false
                              : true
                          }
                          className={classNames(
                            "disabled:opacity-50 flex border-2 border-gray-200 p-1 items-center rounded w-28 h-20  bg-gray-50  inline text-center ml-2 group transition-all",
                            selectedBlaster &&
                              selectedBlaster.id === item.id &&
                              item.isEnabled &&
                              selectedGame.isBlasterNeeded
                              ? "border-primary-violet bg-white transition-all"
                              : "",
                            item.isEnabled && selectedGame.isBlasterNeeded
                              ? "cursor-pointer hover:border-primary-violet hover:bg-white"
                              : "cursor-not-allowed"
                          )}
                          onClick={() => {
                            if (item.isEnabled) setSelectedBlaster(item);
                          }}
                        >
                          <img
                            srcSet={item.path}
                            className="h-10 mx-auto group-hover:h-8 transition-all"
                            alt="blaster"
                            title="Blaster"
                          ></img>
                        </button>
                      </div>
                    </div>
                  );
                })}
              </Slider>
            </div>
          </div>
        </div>
        <div className="mt-6 text-center">
          <button
            type="button"
            className={`${
              studentGradeMode === "Kindergarten"
                ? kindergartenButtonCssClass
                : studentGradeMode === "Elementary"
                ? ElementaryButtonCssClass
                : MiddleschoolButtonCssClass
            }`}
            onClick={startAssignment}
          >
            {t("GameChoose.Start")}
          </button>
        </div>
      </div>
    </Fragment>
  );
}

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(GameChooseWrapper);

import { Select } from "@windmill/react-ui";
import { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { getClassesBySchoolAccountId } from "../../../../api/manageUser/class";
import { ClassApi } from "../../../../api/teacher/classManagementApi";
import UserSearchQuery from "../../../../model/users/userSearchQuery";
import { UserContextState } from "../../../../redux/actions/userContextAction";
import constant from "../../../../utils/constant/constant";
import ClassSearchDTO from "../../../../model/teacher/classManagement/classSearchModel";
import { IGrade } from "../../../../model/interface/grade";
import { ISchool } from "../../../../model/interface/school";
import { getAllGrades } from "../../../../api/gradeApi";
import { getSchoolByDistrictId } from "../../../../api/teacher/school";
import Breadcrumb from "../shared/breadcrumb";
import { IBreadcrumb } from "../../../../model/common/breadcrumb";
import RouteConstant from "../../../../utils/constant/routeConstant";
import { useTranslation } from "react-i18next";
import PageSize from "../../../shared/pagination/pageSize";
import Loader from "../../../shared/loader";
import NoData from "../../../shared/noData";
import Pagination from "../../../shared/pagination/pagination";
import {
  DEFAULT_PAGE_NUMBER,
  DEFAULT_PAGE_SIZE_50,
} from "../../../../utils/pagingConstant";
import InfoMessage from "../../../shared/infoMessage";
import UserTile from "../../../shared/UserTile";
import { getNameLabel } from "../../../../utils/helper";
import { MessageApi } from "../../../../api/message/messageApi";
import Toggle from "../../../shared/toggle";
import ApplyAllSettingContextMenu from "../shared/applyAllSettingContextMenu";
import userApi from "../../../../api/userApi";
import PagingResponse from "../../../../model/common/pagingResponse";
import { useConfirmation } from "../../../shared/confirmation/confirmationService";
import { toast } from "react-toastify";
import AccessDenied from "../../../shared/accessDenied";
import AllowedTo from "../../../shared/rbac";
import Permissions from "../../../../utils/permissions";
import Profile from "../../../../model/users/profile";

interface IUserLiftoffManagement {
  userContext: UserContextState;
  profile: Profile;
}
function UserLiftoffManagement(props: IUserLiftoffManagement) {
  const alphaNumericSymbolExpression = new RegExp(/^[a-zA-Z0-9_@.-]*$/);
  const alphaNumericExpression = new RegExp(/^[a-zA-Z0-9]*$/);
  const [grades, setGrades] = useState<IGrade[]>();
  const [schools, setSchools] = useState<ISchool[]>();
  const [classes, setClasses] = useState<any>();
  const [showLoader, setShowLoader] = useState(false);
  const [showNoData, setShowNoData] = useState(false);
  const [pageNumber, setPageNumber] = useState(DEFAULT_PAGE_NUMBER);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE_50);
  const [resetPageNumber, setResetPageNumber] = useState<boolean>(false);
  const [students, setStudents] = useState<PagingResponse<any>>();
  const [onlineUsers, setOnlineUsers] = useState<any>({});
  const [hoverElement, setHoverElement] = useState<string>("");
  const confirm = useConfirmation();

  function getOnlineUsers() {
    MessageApi.getOnlineUsersRef(props?.userContext?.schoolId!).on(
      "value",
      (snapshot) => {
        if (snapshot.val()) {
          setOnlineUsers(snapshot.val());
        } else {
          setOnlineUsers([]);
        }
      },
      (errorObject) => {
        console.log("The read failed: " + errorObject.name);
      }
    );
  }
  const [searchQuery, setSearchQuery] = useState({
    classId: 0,
    gradeId: 0,
    firstName: "",
    lastName: "",
    studentId: "",
    username: "",
    schoolAccountId:
      props.userContext.roleId === constant.UserRoleId.District
        ? 0
        : props.userContext?.schoolAccountId ?? 0,
  });
  const { t } = useTranslation();
  useEffect(() => {
    getOnlineUsers();
    getClasses(undefined);
    var gradeResponse = getAllGrades();
    gradeResponse?.then((d) => {
      var grades = d.data?.filter((x) => x.gradeId >= 3 && x.gradeId <= 13);
      setGrades(grades);
    });

    if (props.userContext?.roleId === constant.UserRoleId.District) {
      getSchools();
    } else {
      getUser();
    }
  }, [false]);
  useEffect(() => {
    getUser();
  }, [pageNumber, pageSize]);

  function getClasses(schoolAccountId: number | undefined) {
    if (props.userContext?.roleId === constant.UserRoleId.SchoolTeacher) {
      getTeacherClasses();
    } else {
      var response = getClassesBySchoolAccountId(
        schoolAccountId ?? props.userContext?.schoolAccountId ?? 0,
        props.profile?.currentSchoolYear.schoolYearId ?? 0
      );
      response?.then((d) => {
        setClasses(d.data);
      });
    }
  }

  function getTeacherClasses() {
    const classSearch: ClassSearchDTO = {
      showArchive: false,
      showCurrent: true,
      showHidden: false,
      userId: props.userContext?.userId ?? 0,
      schoolId: props.userContext?.schoolId ?? 0,
      schoolAccountId: props.userContext?.schoolAccountId ?? 0,
      pageNumber: 1,
      pageSize: 100,
    };
    const response = ClassApi.getClassList(classSearch);
    response?.then((d) => {
      setClasses(d.data.data);
    });
  }

  function getSchools() {
    getSchoolByDistrictId(props.userContext?.districtId ?? 0).then((d) => {
      setSchools(d.data);
      if (d.data !== null && d.data.length >= 1) {
        setSearchQuery({
          ...searchQuery,
          schoolAccountId:
            d.data.find((t) => t.isLOActive === true)?.schoolAccountId ?? 0,
        });
        getClasses(
          d.data.find((t) => t.isLOActive === true)?.schoolAccountId ?? 0
        );
      }
    });
  }

  function resetFilter() {
    const userSearchQuery: UserSearchQuery = {
      classId: 0,
      gradeId: 0,
      firstName: "",
      lastName: "",
      studentId: "",
      username: "",
      schoolAccountId: searchQuery.schoolAccountId,
    };
    setSearchQuery(userSearchQuery);
    getUser();
  }

  const breadcrumbItems = () => {
    const items: Array<IBreadcrumb> = [
      { name: t("Breadcrumb.Home"), url: RouteConstant.ROUTE_DASHBOARD },
      {
        name: t("Breadcrumb.LiftoffManagement"),
        url: "",
      },
    ];

    return items;
  };

  function updateUserSetting(
    studentId: number,
    setting: string,
    value: any
  ): void {
    updateAllUserSetting(setting, value, [studentId]);
  }

  async function applyAll(
    setting: string,
    userId: number,
    value: string
  ): Promise<void> {
    if (students?.data !== undefined) {
      var users = (await getAllUsers())?.data.map((e) => e.userId);
      updateAllUserSetting(setting, value, users);
    }
  }
  function applyAllAbove(setting: string, userId: number, value: string): void {
    var userIndex = students?.data.findIndex((e) => e.userId === userId);
    var filteredStudents = students?.data.filter((v, index) => {
      return index < (userIndex ?? 999);
    });
    if (filteredStudents !== undefined) {
      var users = filteredStudents.map((e) => e.userId);
      updateAllUserSetting(setting, value, users);
    }
  }

  function applyAllBelow(setting: string, userId: number, value: string): void {
    var userIndex = students?.data.findIndex((e) => e.userId === userId);
    var filteredStudents = students?.data.filter((v, index) => {
      return index > (userIndex ?? 0);
    });
    if (filteredStudents !== undefined) {
      var users = filteredStudents.map((e) => e.userId);
      updateAllUserSetting(setting, value, users);
    }
  }

  function updateAllUserSetting(
    setting: string,
    value: string,
    studentIds: Array<number>
  ): void {
    confirm({
      variant: "danger",
      title: "Please Confirm",
      description: "Are you sure you want to update the user setting?",
    }).then((d) => {
      userApi
        .updateStudentLiftoffSetting({
          classId: 0,
          setting: setting,
          updatedByUserId: props.userContext.userId,
          value: value,
          userIds: studentIds,
        })
        .then((d) => {
          toast.success("Liftoff setting updated successfully");
          getUser();
        });
    });
  }
  const onPageSizeChange = (pageSize) => {
    setPageNumber(DEFAULT_PAGE_NUMBER);
    setPageSize(pageSize);
    setResetPageNumber(true);
  };

  const getUser = () => {
    var userSearchQuery: any = {
      userId: 0,
      firstName: searchQuery?.firstName ? searchQuery.firstName : "",
      lastName: searchQuery?.lastName ? searchQuery.lastName : "",
      username: searchQuery?.username ? searchQuery.username : "",
      gradeId: searchQuery?.gradeId ? searchQuery.gradeId : 0,
      classId: searchQuery?.classId ? searchQuery.classId : 0,
      studentId: searchQuery?.studentId ? searchQuery.studentId : "",
      roleId: constant.UserRoleId.Student,
      schoolAccountId: searchQuery?.schoolAccountId ?? 0,
      OrderByColumn: "LastName",
      SortByDesc: false,
      isActive: true,
      PageSize: pageSize,
      PageNumber: pageNumber,
      exportType: "NONE",
      districtId: props.userContext?.districtId,
      isLiftOff: searchQuery?.gradeId ? false : true,
    };

    if (userSearchQuery.schoolAccountId !== 0) {
      setShowLoader(true);
      var response = userApi.GetAllUsers(userSearchQuery);
      response?.then((d) => {
        setShowLoader(false);
        setStudents(d.data);
        if (d.data.totalRecords > 0) {
          setShowNoData(false);
        } else {
          setShowNoData(true);
        }
      });
    }
  };

  const getAllUsers = async () => {
    var userSearchQuery: any = {
      userId: 0,
      firstName: "",
      lastName: "",
      username: "",
      gradeId: 0,
      classId: 0,
      studentId: "",
      roleId: constant.UserRoleId.Student,
      schoolAccountId: searchQuery?.schoolAccountId ?? 0,
      OrderByColumn: "LastName",
      SortByDesc: false,
      isActive: true,
      PageSize: 99999,
      PageNumber: 1,
      exportType: "NONE",
      districtId: props.userContext?.districtId,
      isLiftOff: searchQuery?.gradeId ? false : true,
    };

    if (userSearchQuery.schoolAccountId !== 0) {
      setShowLoader(true);
      var response = await userApi.GetAllUsers(userSearchQuery);
      setShowLoader(false);
      return response?.data;
    }
  };

  // filter users and pagination excluded
  const getAllFilteredUsers = async () => {
    var userSearchQuery: any = {
      userId: 0,
      firstName: searchQuery?.firstName ? searchQuery.firstName : "",
      lastName: searchQuery?.lastName ? searchQuery.lastName : "",
      username: searchQuery?.username ? searchQuery.username : "",
      gradeId: searchQuery?.gradeId ? searchQuery.gradeId : 0,
      classId: searchQuery?.classId ? searchQuery.classId : 0,
      studentId: searchQuery?.studentId ? searchQuery.studentId : "",
      roleId: constant.UserRoleId.Student,
      schoolAccountId: searchQuery?.schoolAccountId ?? 0,
      OrderByColumn: "LastName",
      SortByDesc: false,
      isActive: true,
      PageSize: constant.Pagination.DefaultPageSize,
      PageNumber: constant.Pagination.DefaultPageNumber,
      exportType: "NONE",
      districtId: props.userContext?.districtId,
      isLiftOff: searchQuery?.gradeId ? false : true,
    };

    if (userSearchQuery.schoolAccountId !== 0) {
      setShowLoader(true);
      var response = await userApi.GetAllUsers(userSearchQuery);
      setShowLoader(false);

      return response?.data;
    }
    return [];
  };

  async function bulkEnableOrDisableLiftOff(value: boolean): Promise<void> {
    if (students?.data !== undefined) {
      var users = (await getAllFilteredUsers())?.data.map((e) => e.userId);
      console.log(users);
      updateAllUserSetting("isLOActive", value.toString(), users);
    }
  }

  return (
    <AllowedTo
      perform={Permissions.liftoff_management_view}
      no={() => <AccessDenied />}
    >
      <div>
        <div className="lg:flex lg:items-center px-4 py-3 lg:px-8 border-b">
          <Breadcrumb items={breadcrumbItems()} />
          <div className="lg:w-[55%] text-lg flex justify-between gap-2 h-auto sm:h-10 items-center">
            <h1 className="text-primary-violet font-medium flex justify-start">
              Liftoff Management
            </h1>
          </div>
        </div>
        <div className="px-2 sm:px-6 lg:px-8 py-6">
          <div className="md:col-span-2 lg:col-span-3">
            <div className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 xl:grid-cols-4 sm:gap-x-8">
              {props.userContext?.roleId === constant.UserRoleId.District && (
                <div className="">
                  <label className="block mb-1 text-sm text-gray-500">
                    School
                  </label>
                  <div className="flex">
                    <Select
                      value={searchQuery.schoolAccountId}
                      onChange={(e) => {
                        setSearchQuery({
                          ...searchQuery,
                          schoolAccountId: parseInt(e.target.value),
                        });
                        getClasses(parseInt(e.target.value));
                      }}
                      className="py-2.5 px-4 block text-sm border border-gray-400 
             border-opacity-50 focus:ring-primary-violet focus:border-primary-violet form-select leading-5 p-1 rounded-lg"
                    >
                      {schools?.map((school: ISchool, index) => {
                        return (
                          <Fragment key={index}>
                            <option
                              value={school.schoolAccountId}
                              disabled={!school.isLOActive}
                            >
                              {school.name}
                            </option>
                          </Fragment>
                        );
                      })}
                    </Select>
                  </div>
                </div>
              )}
              <div className="">
                <label className="block mb-1 text-sm text-gray-500">
                  Grade
                </label>
                <div className="flex">
                  <Select
                    value={searchQuery.gradeId}
                    onChange={(e) => {
                      setSearchQuery({
                        ...searchQuery,
                        gradeId: parseInt(e.target.value),
                      });
                    }}
                    className="py-2.5 px-4 block text-sm border border-gray-400 
              border-opacity-50 focus:ring-primary-violet focus:border-primary-violet form-select leading-5 p-1 rounded-lg"
                  >
                    <option value={0}>All Grades</option>
                    {grades?.map((grade: any, index) => {
                      return (
                        <Fragment key={index}>
                          <option value={grade.gradeId}>{grade?.name}</option>
                        </Fragment>
                      );
                    })}
                  </Select>
                </div>
              </div>

              <div className="">
                <label className="block mb-1 text-sm text-gray-500">
                  Class
                </label>
                <div className="flex">
                  <Select
                    value={searchQuery.classId}
                    onChange={(e) => {
                      setSearchQuery({
                        ...searchQuery,
                        classId: parseInt(e.target.value),
                      });
                    }}
                    className="py-2.5 px-4 block text-sm border border-gray-400
              border-opacity-50 focus:ring-primary-violet focus:border-primary-violet form-select leading-5 p-1 rounded-lg"
                  >
                    <option value={0}>All Classes</option>
                    {classes?.map((item: any, index) => {
                      return (
                        <Fragment key={index}>
                          <option value={item.classId}>
                            {item?.displayName}
                            {(item.primaryTeacherFirstName ?? "").length > 0
                              ? " - " +
                                item.primaryTeacherFirstName +
                                " " +
                                item.primaryTeacherLastName
                              : ""}
                          </option>
                        </Fragment>
                      );
                    })}
                  </Select>
                </div>
              </div>

              <div>
                <label
                  htmlFor="user_name"
                  className="block text-sm text-gray-500"
                >
                  Username
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="user_name"
                    id="user_name"
                    maxLength={100}
                    value={searchQuery.username}
                    onChange={(e) => {
                      if (
                        alphaNumericSymbolExpression.test(
                          e.target.value.toString()
                        ) &&
                        e.target.value.toString().length <= 60
                      ) {
                        setSearchQuery({
                          ...searchQuery,
                          username: e.target.value,
                        });
                      }
                    }}
                    className="border py-2 px-4 block w-full shadow-sm text-gray-900 focus:ring-primary-violet focus:border-primary-violet
            border border-gray-300 rounded-md"
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="student_id"
                  className="block text-sm  text-gray-500"
                >
                  Student ID
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="student_id"
                    id="student_id"
                    value={searchQuery.studentId}
                    maxLength={20}
                    onChange={(e) => {
                      if (
                        alphaNumericExpression.test(e.target.value.toString())
                      ) {
                        setSearchQuery({
                          ...searchQuery,
                          studentId: e.target.value,
                        });
                      }
                    }}
                    className="border py-2 px-4 block w-full shadow-sm text-gray-900 focus:ring-primary-violet focus:border-primary-violet
            border border-gray-300 rounded-md"
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="first_name"
                  className="block text-sm  text-gray-500"
                >
                  First Name
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="first_name"
                    id="first_name"
                    maxLength={60}
                    value={searchQuery.firstName}
                    onChange={(e) => {
                      if (
                        alphaNumericExpression.test(e.target.value.toString())
                      ) {
                        setSearchQuery({
                          ...searchQuery,
                          firstName: e.target.value,
                        });
                      }
                    }}
                    className="border py-2 px-4 block w-full shadow-sm text-gray-900 focus:ring-primary-violet focus:border-primary-violet
            border border-gray-300 rounded-md"
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="last_name"
                  className="block text-sm  text-gray-500"
                >
                  Last Name
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="last_name"
                    id="last_name"
                    maxLength={60}
                    value={searchQuery.lastName}
                    onChange={(e) => {
                      if (
                        alphaNumericExpression.test(e.target.value.toString())
                      ) {
                        setSearchQuery({
                          ...searchQuery,
                          lastName: e.target.value,
                        });
                      }
                    }}
                    className="border py-2 px-4 block w-full shadow-sm text-gray-900 focus:ring-primary-violet focus:border-primary-violet border border-gray-300 rounded-md"
                  />
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="flex mt-5 pt-5 text-right flex-col items-end lg:flex-row md:items-end justify-between border-t">
              <div className="flex flex-col gap-2">
                <label className="block text-sm  text-gray-500">
                  Enable / Disable Liftoff for all students in the filtered
                  results
                </label>

                <div className="flex justify-end lg:justify-start gap-2 mb-2 lg:mb-0">
                  <button
                    className="bg-transparent border border-primary-violet shadow-sm py-2 px-4 inline-flex justify-center items-center text-sm font-medium text-primary-violet hover:text-white hover:bg-light-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3 transition ease-in-out duration-500"
                    type="button"
                    onClick={() => {
                      bulkEnableOrDisableLiftOff(true);
                    }}
                  >
                    <span className="text-sm">Enable</span>
                  </button>
                  <button
                    className="bg-transparent border border-primary-violet shadow-sm py-2 px-4 inline-flex justify-center items-center text-sm font-medium text-primary-violet hover:text-white hover:bg-light-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3 transition ease-in-out duration-500"
                    type="button"
                    onClick={() => {
                      bulkEnableOrDisableLiftOff(false);
                    }}
                  >
                    <span className="text-sm">Disable</span>
                  </button>
                </div>
              </div>

              <div className=" flex space-x-3 mt-2 lg:mt-0">
                <button
                  className="bg-transparent border border-primary-violet shadow-sm py-2 px-4 inline-flex justify-center items-center text-sm font-medium text-primary-violet hover:text-white hover:bg-light-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3 transition ease-in-out duration-500"
                  type="button"
                  onClick={resetFilter}
                >
                  <span className="text-sm">Reset</span>
                </button>
                <button
                  className="bg-primary-violet border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-dark-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  type="button"
                  onClick={getUser}
                >
                  Filter
                </button>
              </div>
            </div>
          </div>

          <section className="mt-3">
            <div className="bg-gray-100 rounded-lg p-4">
              <div className="shadow hover:shadow-lg bg-white py-2 px-5">
                <div className="flex flex-col relative">
                  {showLoader && <Loader></Loader>}
                  <div>
                    <div className="p-1">
                      <InfoMessage message="These Liftoff settings apply to students across all Liftoff subjects. Be sure to coordinate with other teachers when disabling Liftoff for a student." />
                    </div>
                    <div className="overflow-x-auto xl:overflow-x-visible shadow">
                      <table className="table w-full">
                        <thead className="mb-5">
                          <tr className="bg-light-violet  text-white py-3 px-3 overflow-hidden">
                            <th className="text-left  p-3 text-sm font-normal w-4">
                              {" "}
                            </th>
                            <th className="text-left  p-3 text-sm font-normal">
                              {" "}
                              Student{" "}
                            </th>
                            <th className="text-center  p-3 text-sm font-normal">
                              {" "}
                              <span className="flex flex-col items-center">
                                <span>Grade </span>
                              </span>
                            </th>
                            <th className="text-center  p-3 text-sm font-normal">
                              {" "}
                              <span className="flex flex-col items-center">
                                <span>Liftoff </span>
                              </span>
                            </th>
                            <th className="text-center  p-3 text-sm font-normal ">
                              {" "}
                              <span className="flex flex-col items-center">
                                <span>Text to Speech</span>
                              </span>
                            </th>
                            <th className="text-center  p-3 text-sm font-normal">
                              {" "}
                              <span className="flex flex-col items-center">
                                <span>Language Settings</span>
                              </span>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {students?.data?.map((student, index) => {
                            return (
                              <tr
                                key={student.userId}
                                className={`hover:bg-light-violet/20 transition ease-in-out duration-300 ${
                                  index % 2 === 0
                                    ? "bg-white"
                                    : "bg-light-violet/10"
                                }`}
                              >
                                <td className=" mt-5 p-3 text-center">
                                  <span className="relative">
                                    <UserTile
                                      userId={student.userId}
                                      onlineUsers={onlineUsers}
                                      isLoggedInUser={false}
                                    />
                                  </span>
                                </td>
                                <td className="text-sm mt-5  p-3 text-left">
                                  {getNameLabel(
                                    student.lastName,
                                    student.firstName
                                  )}
                                </td>
                                <td className="text-sm mt-5  p-3 text-center">
                                  {student.grade}
                                </td>
                                <td className="text-sm mt-5  p-3">
                                  <div
                                    className="flex justify-center"
                                    onMouseOver={() => {
                                      setHoverElement(
                                        "isLOActive-" + student.userId
                                      );
                                    }}
                                  >
                                    <Toggle
                                      disabled={
                                        !AllowedTo({
                                          perform:
                                            Permissions.liftoff_management_modify,
                                          asFunction: true,
                                        })
                                      }
                                      checked={student.isLOActive}
                                      onChange={() => {
                                        updateUserSetting(
                                          student.userId,
                                          "isLOActive",
                                          !student.isLOActive
                                        );
                                      }}
                                    />
                                    <div className="w-6">
                                      <AllowedTo
                                        perform={
                                          Permissions.liftoff_management_modify
                                        }
                                      >
                                        {hoverElement ===
                                          "isLOActive-" + student.userId && (
                                          <ApplyAllSettingContextMenu
                                            isFirst={(index === 0).toString()}
                                            isLast={(
                                              index ===
                                              pageSize - 1
                                            ).toString()}
                                            applyAll={applyAll}
                                            applyAllAbove={applyAllAbove}
                                            applyAllBelow={applyAllBelow}
                                            setting="isLOActive"
                                            userId={student.userId}
                                            value={student.isLOActive.toString()}
                                          />
                                        )}
                                      </AllowedTo>
                                    </div>
                                    <label>
                                      {student.isLOActive
                                        ? "Enabled"
                                        : "Disabled"}
                                    </label>
                                  </div>
                                </td>
                                <td className="text-sm mt-5  p-3 ">
                                  <div
                                    className="flex justify-center"
                                    onMouseOver={() => {
                                      setHoverElement(
                                        "isLOTTSActive-" + student.userId
                                      );
                                    }}
                                  >
                                    <Toggle
                                      disabled={
                                        !AllowedTo({
                                          perform:
                                            Permissions.liftoff_management_modify,
                                          asFunction: true,
                                        })
                                      }
                                      checked={student.isLOTTSActive}
                                      onChange={() => {
                                        updateUserSetting(
                                          student.userId,
                                          "isLOTTSActive",
                                          !student.isLOTTSActive
                                        );
                                      }}
                                    />
                                    <div className="w-6">
                                      <AllowedTo
                                        perform={
                                          Permissions.liftoff_management_modify
                                        }
                                      >
                                        {hoverElement ===
                                          "isLOTTSActive-" + student.userId && (
                                          <ApplyAllSettingContextMenu
                                            isFirst={(index === 0).toString()}
                                            isLast={(
                                              index ===
                                              pageSize - 1
                                            ).toString()}
                                            applyAll={applyAll}
                                            applyAllAbove={applyAllAbove}
                                            applyAllBelow={applyAllBelow}
                                            setting="isLOTTSActive"
                                            userId={student.userId}
                                            value={student.isLOTTSActive.toString()}
                                          />
                                        )}
                                      </AllowedTo>
                                    </div>
                                    <label>
                                      {student.isLOTTSActive
                                        ? "Enabled"
                                        : "Disabled"}
                                    </label>
                                  </div>
                                </td>
                                <td className="text-sm mt-5  p-3">
                                  <div
                                    className="relative  rounded-md flex justify-center gap-3 "
                                    onMouseOver={() => {
                                      setHoverElement(
                                        "loLanguageId-" + student.userId
                                      );
                                    }}
                                  >
                                    <div className="flex items-center text-xs">
                                      <input
                                        id="rdbToggle"
                                        type="radio"
                                        name={
                                          "spanishTranslation" +
                                          student.userId.toString()
                                        }
                                        value="Toggle"
                                        checked={student.loLanguageId === 3}
                                        className="h-4 w-4  border-gray-300 focus:ring-violet-500"
                                        onChange={() => {
                                          updateUserSetting(
                                            student.userId,
                                            "lOLanguageId",
                                            3
                                          );
                                        }}
                                        disabled={false}
                                      />
                                      <label
                                        id={"Toggle-" + student.userId}
                                        htmlFor="rdbToggle"
                                        className="ml-1 text-sm"
                                      >
                                        Toggle
                                      </label>
                                    </div>
                                    <div className="flex items-center text-xs">
                                      <input
                                        id="rdbToggle"
                                        type="radio"
                                        name={
                                          "spanishTranslation" +
                                          student.userId.toString()
                                        }
                                        value="SpanishOnly"
                                        checked={
                                          student.loLanguageId ===
                                          constant.Languages.Spanish
                                        }
                                        className="h-4 w-4  border-gray-300 focus:ring-violet-500"
                                        onChange={() => {
                                          updateUserSetting(
                                            student.userId,
                                            "lOLanguageId",
                                            2
                                          );
                                        }}
                                        disabled={
                                          !AllowedTo({
                                            perform:
                                              Permissions.liftoff_management_modify,
                                            asFunction: true,
                                          })
                                        }
                                      />
                                      <label
                                        id={"Spanish-" + student.userId}
                                        htmlFor="rdbToggle"
                                        className="ml-1  text-sm"
                                      >
                                        Spanish Only
                                      </label>
                                    </div>
                                    <div className="flex items-center text-xs">
                                      <input
                                        id="rdbToggle"
                                        type="radio"
                                        name={
                                          "spanishTranslation" +
                                          student.userId.toString()
                                        }
                                        value="None"
                                        checked={student.loLanguageId === 1}
                                        className="h-4 w-4  border-gray-300 focus:ring-violet-500"
                                        onChange={() => {
                                          updateUserSetting(
                                            student.userId,
                                            "lOLanguageId",
                                            1
                                          );
                                        }}
                                        disabled={
                                          !AllowedTo({
                                            perform:
                                              Permissions.liftoff_management_modify,
                                            asFunction: true,
                                          })
                                        }
                                      />
                                      <label
                                        id={"None-" + student.userId}
                                        htmlFor="rdbToggle"
                                        className="ml-1 text-sm"
                                      >
                                        English Only
                                      </label>
                                    </div>
                                    <div className="w-6">
                                      <AllowedTo
                                        perform={
                                          Permissions.liftoff_management_modify
                                        }
                                      >
                                        {hoverElement ===
                                          "loLanguageId-" + student.userId && (
                                          <ApplyAllSettingContextMenu
                                            isFirst={(index === 0).toString()}
                                            isLast={(
                                              index ===
                                              pageSize - 1
                                            ).toString()}
                                            applyAll={applyAll}
                                            applyAllAbove={applyAllAbove}
                                            applyAllBelow={applyAllBelow}
                                            setting="loLanguageId"
                                            userId={student.userId}
                                            value={student.loLanguageId.toString()}
                                          />
                                        )}
                                      </AllowedTo>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {showNoData && <NoData></NoData>}
                </div>
                <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between border-t-2 pt-3 mt-4 mb-3">
                  {students?.totalRecords !== undefined &&
                    students.totalRecords !== 0 && (
                      <Pagination
                        totalRecords={students.totalRecords}
                        pageSize={pageSize}
                        pageNumber={pageNumber}
                        onPageChange={(e) => {
                          setPageNumber(e);
                        }}
                        reset={resetPageNumber}
                      />
                    )}
                  {students?.totalRecords !== 0 && (
                    <PageSize
                      pageSize={pageSize}
                      onPageSizeChange={onPageSizeChange}
                    />
                  )}
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </AllowedTo>
  );
}

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(UserLiftoffManagement);

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Profile from "../../../model/users/profile";
import { useTranslation } from "react-i18next";
import { UserContextState } from "../../../redux/actions/userContextAction";
import { validateIfScoreLessThan80 } from "../../../utils/scoreHelper";
import {
  validateIfIsTeacherRole,
  validateIfIsStudentRole,
} from "../../../utils/roleHelper";

interface StyleProps {
  styleProps: {
    tracker: number;
    header: string;
    subHeader: string;
    bars: any;
    barBackgroundColor: string;
    badgeColor: string;
    badgeText: string;
    badgeTextColor: string;
    percentText?: string;
    remediationLinks?: any;
  };
  profile?: Profile;
  totalVideoQuestions?: number | null;
  userContext?: UserContextState;
}

const validateEnableVideoAndQuestions = (
  roleId: number | undefined,
  percent: number | undefined
) => {
  return (
    validateIfScoreLessThan80(percent) &&
    (validateIfIsStudentRole(roleId) ||
      validateIfIsTeacherRole(roleId))
  );
};

function ProgressWidget({ styleProps, userContext, totalVideoQuestions }: StyleProps) {
  const { t } = useTranslation();
  const [showVideoAndQuestion, setShowVideoAndQuestion] =
    useState<boolean>(false);
  const [enableVideoAndQuestion, setEnableVideoAndQuestion] =
    useState<boolean>(false);
  const [bars, setBars] = useState<any[]>([]);

  useEffect(() => {
    setEnableVideoAndQuestion(
      validateEnableVideoAndQuestions(
        userContext?.roleId,
        bars[1]?.percent
      )
    );
  }, [bars]);

  useEffect(() => {
    const path = (
      styleProps.remediationLinks?.remediation_video_link ?? ""
    ).split("/");

    if (path.length >= 6 && path[3] !== "" && path[5] !== "") {
      try {
        const standardId = parseInt(atob(path[3])) || 0;
        if (standardId > 0) {
          setShowVideoAndQuestion(true);
        }
      } catch (e) {}
    }

    if (!styleProps.bars) return;
    setBars(() => {
      return styleProps.bars?.map((bar, index) => ({
        percent: bar.percent,
        barColor: bar.barColor,
        isRetry: index !== 0,
      }));
    });
  }, [styleProps]);

  let marginLeft = styleProps.percentText
    ? parseInt(styleProps.percentText)
    : 0;
  if (marginLeft < 8) marginLeft = 0;
  else if (marginLeft < 96) marginLeft = marginLeft - 6;
  else if (marginLeft >= 96) marginLeft = 92;
  let prevPercent = 0;

  const isValidPercentage = (index: number): boolean => {
    return bars[index]?.percent > 0 || bars[index]?.isRetry === false;
  };

  return (
    <>
      <div>
        <div className="flex w-full">
          <div className="w-full px-7"></div>
          {showVideoAndQuestion && (
            <div className="w-36 text-xs font-semibold pr-7 mb-2 flex-none text-teal-light whitespace-nowrap">
              Suggested Practice
            </div>
          )}
        </div>
        <div className={`flex flex-none pb-6 w-full`}>
          <div className="flex flex-col w-full px-7">
            <div className="flex w-full flex-none justify-between">
              <div className="text-sm font-semibold">
                {styleProps.header === "null" ? "" : styleProps.header}
                <span className="block font-medium pl-2">
                  {styleProps.subHeader}
                </span>
              </div>
              {styleProps.badgeText && (
                <div
                  className={`h-6 ml-3 inline-block rounded-md flex items-center px-2 text-sm font-roboto font-semibold ${styleProps.badgeColor} ${styleProps.badgeTextColor}`}
                >
                  {styleProps.badgeText}
                </div>
              )}
            </div>
            <div className="w-full block mt-2 relative">
              <div
                className={`overflow-hidden h-2 text-xs flex rounded ${styleProps.barBackgroundColor}`}
              >
                {bars
                  .sort((a, b) => a?.percent - b?.percent)
                  .map((data, index) => {
                    if (index === 0) prevPercent = data.percent;
                    return (
                      <>
                        <div
                          key={index}
                          title={data.percent + "%"}
                          style={{
                            width:
                              index === 0
                                ? data.percent + "%"
                                : data.percent - prevPercent <= 0
                                ? 0
                                : data.percent - prevPercent + "%",
                          }}
                          className={`shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center ${data.barColor}`}
                        ></div>
                      </>
                    );
                  })}
              </div>
              {bars && bars.length > 0 && (
                <div className=" text-black-333 text-lg font-roboto font-semibold pt-2 inline-block whitespace-nowrap float-right">
                  {isValidPercentage(0) && `${bars[0]?.percent}%`}
                  {isValidPercentage(1) && (
                    <>
                      {isValidPercentage(0) && " / "}
                      <span className="italic">{bars[1]?.percent}%</span>
                    </>
                  )}
                </div>
              )}
            </div>
          </div>

          <div className="flex w-36 flex-none pr-7">
            {showVideoAndQuestion && (totalVideoQuestions === undefined /* not set */
              || (totalVideoQuestions ?? 1) > 0) && (
              <Link
                className="cursor-pointer mr-2"
                to={
                  enableVideoAndQuestion
                    ? {
                        pathname:
                          styleProps?.remediationLinks?.remediation_video_link,
                        state: {
                          type: "Video Task",
                          resultParams: window.location.search,
                          redirectUrl:
                            styleProps?.remediationLinks
                              ?.remediation_video_link,
                        },
                      }
                    : "#"
                }
              >
                <div>
                  <div className="flex flex-col items-center">
                    <div className="flex-shrink-0 cursor-pointer h-12 w-12 bg-blue-900 hover:opacity-90 rounded-md flex items-center justify-center">
                      <span title="Videos">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-7 h-7 stroke-current text-white"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z"
                          ></path>

                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                          ></path>
                        </svg>
                      </span>
                    </div>

                    <span className="py-2 capitalize text-xs">
                      {t("ProgressBar.Videos")}
                    </span>
                  </div>
                </div>
              </Link>
            )}
            {showVideoAndQuestion && (
              <Link
                className="cursor-pointer"
                to={
                  enableVideoAndQuestion
                    ? {
                        pathname:
                          styleProps?.remediationLinks
                            ?.remediation_questions_link,
                        state: {
                          type: "Question Task",
                          resultParams: window.location.search,
                          redirectUrl:
                            styleProps?.remediationLinks
                              ?.remediation_questions_link,
                        },
                      }
                    : "#"
                }
              >
                <div>
                  <div className="flex flex-col items-center">
                    <div className="flex-shrink-0 cursor-pointer h-12 w-12 bg-blue-900 hover:opacity-90 rounded-md flex items-center justify-center">
                      <span title="Questions">
                        <svg
                          className="w-8 h-8 fill-current text-white"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 32 32"
                        >
                          <path d="M 16 4 C 9.382813 4 4 9.382813 4 16 C 4 22.617188 9.382813 28 16 28 C 22.617188 28 28 22.617188 28 16 C 28 9.382813 22.617188 4 16 4 Z M 16 6 C 21.535156 6 26 10.464844 26 16 C 26 21.535156 21.535156 26 16 26 C 10.464844 26 6 21.535156 6 16 C 6 10.464844 10.464844 6 16 6 Z M 16 10 C 13.800781 10 12 11.800781 12 14 L 14 14 C 14 12.882813 14.882813 12 16 12 C 17.117188 12 18 12.882813 18 14 C 18 14.765625 17.507813 15.445313 16.78125 15.6875 L 16.375 15.8125 C 15.558594 16.082031 15 16.863281 15 17.71875 L 15 19 L 17 19 L 17 17.71875 L 17.40625 17.59375 C 18.945313 17.082031 20 15.621094 20 14 C 20 11.800781 18.199219 10 16 10 Z M 15 20 L 15 22 L 17 22 L 17 20 Z"></path>
                        </svg>
                      </span>
                    </div>

                    <span className="py-2 capitalize text-xs">
                      {t("ProgressBar.Questions")}
                    </span>
                  </div>
                </div>
              </Link>
            )}
            <div className="hidden">
              <div className="flex flex-col items-center">
                <div className="flex-shrink-0 cursor-pointer h-12 w-12 bg-blue-900 hover:opacity-90 rounded-md flex items-center justify-center">
                  <span title="Performance task">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="23.762"
                      height="16.714"
                      viewBox="0 0 23.762 16.714"
                    >
                      <g
                        id="Group_15"
                        data-name="Group 15"
                        transform="translate(-1294.203 -742)"
                      >
                        <g
                          id="Rectangle_5"
                          data-name="Rectangle 5"
                          transform="translate(1299 742)"
                          fill="none"
                          stroke="#fff"
                          strokeWidth="1"
                        >
                          <rect width="16" height="13" stroke="none" />
                          <rect
                            x="0.5"
                            y="0.5"
                            width="15"
                            height="12"
                            fill="none"
                          />
                        </g>
                        <path
                          id="Path_42"
                          data-name="Path 42"
                          d="M1299.494,750.044l4.161-2.978,4.214,2.978,6.339,4.48"
                          fill="none"
                          stroke="#fff"
                          strokeWidth="1"
                        />
                        <path
                          id="Path_43"
                          data-name="Path 43"
                          d="M1308.419,750.8l6.239-4.032"
                          fill="#fff"
                          stroke="#fff"
                          strokeWidth="1"
                        />
                        <g
                          id="Ellipse_13"
                          data-name="Ellipse 13"
                          transform="translate(1306 744)"
                          fill="#fff"
                          stroke="#fff"
                          strokeWidth="1"
                        >
                          <circle cx="2" cy="2" r="2" stroke="none" />
                          <circle cx="2" cy="2" r="1.5" fill="none" />
                        </g>
                        <g
                          id="Group_14"
                          data-name="Group 14"
                          transform="matrix(0.588, -0.809, 0.809, 0.588, -74.871, 1385.679)"
                        >
                          <g
                            id="Path_45"
                            data-name="Path 45"
                            transform="translate(1325 748)"
                            fill="#fff"
                          >
                            <path
                              d="M 7.042020797729492 4.365721702575684 L 1.067518353462219 4.365721702575684 L 4.054769515991211 0.7810234427452087 L 7.042020797729492 4.365721702575684 Z"
                              stroke="none"
                            />
                            <path
                              d="M 4.054769515991211 1.562045097351074 L 2.135037422180176 3.865721702575684 L 5.974501609802246 3.865721702575684 L 4.054769515991211 1.562045097351074 M 4.054769515991211 1.9073486328125e-06 L 8.109539985656738 4.865721702575684 L -9.5367431640625e-07 4.865721702575684 L 4.054769515991211 1.9073486328125e-06 Z"
                              stroke="none"
                              fill="#fff"
                            />
                          </g>
                          <line
                            id="Line_15"
                            data-name="Line 15"
                            y2="3.244"
                            transform="translate(1329.055 752.055)"
                            fill="none"
                            stroke="#fff"
                            strokeWidth="2"
                          />
                        </g>
                        <path
                          id="Path_44"
                          data-name="Path 44"
                          d="M1297.357,746.763H1292.7v11.451h12.034v-3.361"
                          transform="translate(2)"
                          fill="none"
                          stroke="#fff"
                          strokeWidth="1"
                          strokeDasharray="1"
                        />
                      </g>
                    </svg>
                  </span>
                </div>

                <span className="py-2 capitalize text-xs">
                  {t("ProgressBar.PerformanceTask")}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state: any) => {
  return {
    profile: state.profile,
    userContext: state.userContext,
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(ProgressWidget);

import { connect } from "react-redux";
import { IBreadcrumb } from "../../../../../model/common/breadcrumb";
import RouteConstant from "../../../../../utils/constant/routeConstant";
import { default as Constant } from "../../../../../utils/constant/constant";
import Breadcrumb from "../../../teacher/shared/breadcrumb";
import { useTranslation } from "react-i18next";
import Asterisk from "../../../../shared/asterisk";
import SelectGroup from "react-select";
import { useEffect, useState } from "react";
import { getSchoolByDistrictId } from "../../../../../api/teacher/school";
import { ISelectElement } from "../../../../../model/interface/selectElement";
import { getAllClassesBySubject } from "../../../../../api/teacher/teacher";
import { getAllGrades } from "../../../../../api/gradeApi";
import PageSize from "../../../../shared/pagination/pageSize";
import Pagination from "../../../../shared/pagination/pagination";
import { getLiftoffSubjects } from "../../../../../api/student/liftOff/diagnosticApi";
import {
  DEFAULT_PAGE_NUMBER,
  DEFAULT_PAGE_SIZE_10,
} from "../../../../../utils/pagingConstant";
import ReactTooltip from "react-tooltip";
import {
  exportLiftoffGrowthReport,
  getLiftoffGrowthReport,
} from "../../../../../api/report/liftoffGrowthReport";
import Loader from "../../../../shared/loader";
import { hasLiftOffAccess } from "../../../../../utils/helper";
import ClassDropDown from "../../shared/classDropDown";
import ITeacherClass from "../../../../../model/interface/teacherclass";
import { ISchool } from "../../../../../model/interface/school";
import { SchoolYearDropDown } from "../../../schoolYearDropdown";

function LiftoffDetailGrowthReport(props) {
  const { t } = useTranslation();
  const [isDistrictUser] = useState<boolean>(
    props.userContext.roleId === Constant.UserRoleId.District ? true : false
  );
  const [schools, setSchools] = useState<Array<ISelectElement>>([]);
  const [classes, setClasses] = useState<Array<ITeacherClass>>([]);
  const [subjects, setSubjects] = useState<Array<ISelectElement>>([]);
  const [grades, setGrades] = useState<Array<ISelectElement>>([]);
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [selectedSchool, setSelectedSchool] = useState<string>(
    props.userContext.roleId === Constant.UserRoleId.District
      ? "0"
      : props.userContext.schoolId
  );
  const [selectedClass, setSelectedClass] = useState<string>("0");
  const [className, setClassName] = useState<string>("");
  const [selectedSubject, setSelectedSubject] = useState<string>("0");
  const [selectedSubjectName, setSelectedSubjectName] = useState<string>("");
  const [subjectName, setSubjectName] = useState<string>("");
  const [selectedGrade, setSelectedGrade] = useState<string>("0");
  const [grade, setGrade] = useState<string>("");
  const [isClassDisabled, setIsClassDisabled] = useState<boolean>(
    props.userContext.roleId !== Constant.UserRoleId.District ? false : true
  );

  const [isSubjectsDisabled, setIsSubjectDisabled] = useState<boolean>(
    props.userContext.roleId !== Constant.UserRoleId.District ? false : true
  );
  const [isGradeDisabled, setIsGradeDisabled] = useState<boolean>(false);
  const [showReport, setShowReport] = useState<boolean>(false);
  const [noRecordFoundMsg, setNoRecordFoundMsg] = useState<string>("");
  const [reportData, setReportData] = useState<any>([]);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState(DEFAULT_PAGE_NUMBER);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE_10);
  const [resetPageNumber, setResetPageNumber] = useState<boolean>(false);
  const defaultSelectedClass = {
    label: "Select Class",
    value: "0",
  };
  const [selectedClassItem, setSelectedClassItem] =
    useState<ISelectElement>(defaultSelectedClass);
  const [allSchools, setAllSchools] = useState<ISchool[]>([]);
  const [selectedSchoolAccountId, setSelectedSchoolAccountId] =
    useState<number>(0);

  const [selectedSchoolYearId, setSelectedSchoolYearId] = useState<number>();
  const [resetSchoolYear, setResetSchoolYear] = useState<boolean>(false);
  const breadcrumbItems = () => {
    const items: Array<IBreadcrumb> = [
      { name: t("Breadcrumb.Home"), url: RouteConstant.ROUTE_DASHBOARD },
      {
        name: t("Breadcrumb.Reports"),
        url: RouteConstant.REPORTS,
      },
      { name: t("Breadcrumb.LiftoffDetailGrowthReport"), url: "" },
    ];

    return items;
  };

  const getSchools = async () => {
    const resp = await getSchoolByDistrictId(
      props.userContext?.districtId ?? 0
    );
    if (resp?.data?.length) {
      let schoolList: Array<ISelectElement> = [];
      resp?.data.forEach((school) => {
        if (!schoolList.find((sl) => sl.value === school.schoolId.toString())) {
          schoolList.push({
            label: school.name,
            value: school.schoolId.toString(),
          });
        }
      });
      setSchools(schoolList);
      setAllSchools(resp?.data);
    }
  };

  const getClasses = async (schoolId: number) => {
    const resp = await getAllClassesBySubject(
      props.userContext.userId,
      props.userContext.roleId,
      null,
      selectedSchoolYearId ?? props.profile.currentSchoolYear.schoolYearId,
      schoolId
    );
    setClasses(resp.data.data);
  };

  const getSubjects = async (schoolId: number) => {
    const resp = await getLiftoffSubjects(schoolId);
    if (resp?.data?.length) {
      let subjectList: Array<ISelectElement> = [];
      resp?.data.forEach((subject) => {
        subjectList.push({
          label: subject.name,
          value: subject.id.toString(),
        });
      });
      setSubjects(subjectList);
    }
  };

  const getGrades = async () => {
    const resp = await getAllGrades();
    if (resp?.data?.length) {
      let gradeList: Array<ISelectElement> = [];
      resp?.data.forEach((grade, index) => {
        if (index > 0) {
          if (!gradeList.find((sl) => sl.value === grade.gradeId.toString())) {
            //K and 1st grade Students don't have liftoff mode
            if (grade.gradeId > 2) {
              gradeList.push({
                label: grade.name,
                value: grade.gradeId.toString(),
              });
            }
          }
        }
      });
      setGrades(gradeList);
    }
  };

  const getReport = () => {
    setShowLoader(true);
    getLiftoffGrowthReport(
      Number(selectedSchool),
      Number(selectedSubject),
      pageNumber,
      pageSize,
      selectedSchoolYearId ?? props.profile.currentSchoolYear.schoolYearId,
      Number(selectedClass) === 0 ? undefined : Number(selectedClass),
      Number(selectedGrade) === 0 ? undefined : Number(selectedGrade)
    ).then((res) => {
      if (res.data.data.totalRecords > 0) {
        setReportData(res.data.data.data);
        setShowReport(true);
        setTotalRecords(res.data.data.totalRecords);
      } else {
        setNoRecordFoundMsg("No record found");
      }
      setShowLoader(false);
    });
  };

  const findSchooltByValue: any = () => {
    if (!selectedSchool || selectedSchool === "0") return null;
    return schools.find((school) => school.value === selectedSchool);
  };

  const findSubjectByValue: any = () => {
    if (!selectedSubject || selectedSubject === "0") return null;
    return subjects.find((subject) => subject.value === selectedSubject);
  };

  const findGradeByValue: any = () => {
    if (!selectedGrade || selectedGrade === "0") return null;
    return grades.find((cl) => cl.value === selectedGrade);
  };

  const handleSchoolOnChange = (school: ISelectElement) => {
    if (school.value !== selectedSchool) {
      setClasses([]);
      setSelectedClass("0");
      setSelectedGrade("0");
      setSubjects([]);
      setSelectedSubject("0");
      setIsClassDisabled(false);
      setIsGradeDisabled(false);
      setIsSubjectDisabled(false);

      setSelectedSchool(school.value);
      getClasses(Number(school.value));
      getSubjects(Number(school.value));
      setSelectedSchoolAccountId(
        allSchools.filter((s) => s.schoolId === Number(school.value))[0]
          ?.schoolAccountId ?? 0
      );
    }
  };

  const handleClassOnChange = (cl: ISelectElement) => {
    setSelectedClass(cl.value);
    setClassName(cl.label);
    setSelectedClassItem(cl);
  };

  const handleGradeOnChange = (grade: ISelectElement) => {
    setSelectedGrade(grade.value);
    setGrade(grade.label);
  };

  const handleSubjectOnChange = (subject: ISelectElement) => {
    setSelectedSubject(subject.value);
    setSelectedSubjectName(subject.label);
  };

  const onReset = () => {
    if (props.userContext.roleId === Constant.UserRoleId.District) {
      setSubjects([]);
      setClasses([]);
      setSelectedSchool("0");
      setSelectedGrade("0");
      setGrade("");
      setSelectedClass("0");
      setClassName("");
      setSelectedSubject("0");
      setSelectedSubjectName("");
      setIsClassDisabled(true);
      setIsSubjectDisabled(true);
      setResetSchoolYear(true);
    }
    if (props.userContext.roleId === Constant.UserRoleId.SchoolAdmin) {
      setSelectedSubject("0");
      setSelectedGrade("0");
      setSelectedClass("0");
      setIsSubjectDisabled(false);
      setIsGradeDisabled(false);
    }
    if (
      props.userContext.roleId !== Constant.UserRoleId.SchoolAdmin &&
      props.userContext.roleId !== Constant.UserRoleId.District
    ) {
      setSelectedSubject("0");
      setSelectedGrade("0");
      setSelectedClass("0");
      setIsSubjectDisabled(false);
      setIsGradeDisabled(false);
    }
    setSelectedClassItem(defaultSelectedClass);
    setNoRecordFoundMsg("");
    setShowReport(false);
    setPageNumber(DEFAULT_PAGE_NUMBER);
    setReportData([]);
  };

  const onRunReport = () => {
    setSubjectName(selectedSubjectName);
    setPageNumber(DEFAULT_PAGE_NUMBER);
    setNoRecordFoundMsg("");
    setShowReport(false);
    setReportData([]);
    setTotalRecords(0);
    getReport();
  };

  const exportToExcel = () => {
    setShowLoader(true);
    exportLiftoffGrowthReport(
      Number(selectedSchool),
      Number(selectedSubject),
      selectedSchoolYearId ?? props.profile.currentSchoolYear.schoolYearId,
      Number(selectedClass) === 0 ? undefined : Number(selectedClass),
      Number(selectedGrade) === 0 ? undefined : Number(selectedGrade),
      subjectName,
      className,
      grade
    ).then((response) => {
      if (response.data) {
        setShowLoader(false);
        const file = response.data;
        const fileURL = URL.createObjectURL(file);
        const link = document.createElement("a");
        link.href = fileURL;
        link.download = "LoDetailGrowthReport.xlsx";
        link.setAttribute("target", "_blank");
        document.body.appendChild(link);
        link.click();
      } else {
        setShowLoader(false);
      }
    });
  };

  const onPageChange = (pageNo) => {
    setPageNumber(pageNo);
  };

  const onPageSizeChange = (pageSize) => {
    setPageNumber(DEFAULT_PAGE_NUMBER);
    setPageSize(pageSize);
    setResetPageNumber(true);
  };

  const handleSchoolYearLoaded = (currentSchoolYearId?: number) => {
    handleSchoolYearChange(currentSchoolYearId);
  };

  const handleSchoolYearChange = (schoolYearId?: number) => {
    setSelectedClass("0");
    setSelectedClassItem(defaultSelectedClass);
    setSelectedSchoolYearId(schoolYearId);
  };

  useEffect(() => {
    hasLiftOffAccess(props.userContext).then((res) => {
      if (!res) {
        window.location.replace(RouteConstant.FORBIDDEN);
      }
    });
    getSchools();
    getGrades();
    if (props.userContext.roleId !== Constant.UserRoleId.District) {
      getSubjects(props.userContext.schoolId);
    }
  }, []);

  useEffect(() => {
    if (selectedSchoolYearId) {
      if (
        props.userContext.roleId === Constant.UserRoleId.District &&
        Number(selectedSchool) > 0
      ) {
        getClasses(Number(selectedSchool));
      } else {
        getClasses(props.userContext.schoolId);
      }
      setResetSchoolYear(false);
    }
  }, [selectedSchoolYearId]);

  useEffect(() => {
    if (showReport) getReport();
  }, [pageSize, pageNumber, resetPageNumber]);

  return (
    <div className="min-w-0 w-full">
      {showLoader && <Loader></Loader>}

      <div className="lg:flex lg:items-center px-4 py-3 lg:px-8 border-b">
        {props.userContext.roleId !== Constant.UserRoleId.Student && (
          <Breadcrumb items={breadcrumbItems()} />
        )}

        <div className="lg:w-[55%] text-lg font-medium flex gap-2 h-auto sm:h-10 items-center">
          <h1 className="text-primary-violet font-medium flex justify-start mb-0 pb-0">
            Liftoff Detail Growth Report
          </h1>
        </div>
      </div>
      <div className="px-2 sm:px-6  lg:px-8 py-1 relative my-4">
        <div className="bg-gray-100 w-full rounded-lg p-5">
          <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-4 mb-4">
          <div className="flex flex-col">
              <label className="block mb-1 text-sm text-gray-500">
                School Year
              </label>
              <SchoolYearDropDown
                className=""
                reset={resetSchoolYear}
                offsets={[0, -1]}
                handleSchoolYearChange={handleSchoolYearChange}
                handleSchoolYearLoaded={handleSchoolYearLoaded}
                handleSchoolYearData={(e) => {}}
              ></SchoolYearDropDown>
            </div>

            {isDistrictUser && (
              <div className="flex flex-col">
                <label className="block mb-1 text-sm text-gray-500">
                  School <Asterisk />
                </label>
                <SelectGroup
                  placeholder={"Select School"}
                  value={findSchooltByValue()}
                  options={schools}
                  isSearchable={true}
                  onChange={(item) => {
                    handleSchoolOnChange(item);
                  }}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: "#008fbb",
                    },
                  })}
                />
              </div>
            )}

            <div className="flex flex-col">
              <label className="block mb-1 text-sm text-gray-500">
                Subject <Asterisk />
              </label>
              <SelectGroup
                placeholder={"Select Subject"}
                value={findSubjectByValue()}
                options={subjects}
                isSearchable={true}
                isDisabled={isSubjectsDisabled}
                onChange={(item) => {
                  handleSubjectOnChange(item);
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: "#008fbb",
                  },
                })}
              />
            </div>

            <div className="flex flex-col">
              <label className="block mb-1 text-sm text-gray-500">
                Class <Asterisk />
              </label>
              <ClassDropDown
                schoolYearId={
                  selectedSchoolYearId ??
                  props.profile.currentSchoolYear.schoolYearId
                }
                defaultLabel={"Select Class"}
                onChange={(item) => handleClassOnChange(item[0])}
                value={selectedClassItem}
                data={classes}
                isDisabled={isClassDisabled}
                showArchived={true}
                showHidden={true}
                selectedSchoolAccountId={selectedSchoolAccountId}
                colorStyle="white"
              ></ClassDropDown>
            </div>

            <div className="flex flex-col">
              <label className="block mb-1 text-sm text-gray-500">
                Grade <Asterisk />
              </label>
              <SelectGroup
                value={findGradeByValue()}
                placeholder={"Select Grade"}
                options={grades}
                isSearchable={true}
                isDisabled={isGradeDisabled}
                onChange={(item) => {
                  handleGradeOnChange(item);
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: "#008fbb",
                  },
                })}
              />
            </div>
          </div>

          <div className="flex gap-4 justify-end">
            <button
              onClick={onReset}
              className="bg-transparent border my-auto border-primary-violet shadow-sm py-2 px-4 flex justify-center text-sm font-medium text-primary-violet hover:text-white hover:bg-light-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3 transition ease-in-out duration-500"
            >
              Reset
            </button>

            <p
              className="cursor-pointer"
              data-tip
              data-for="incompleteSelection"
            >
              {/* District Admin*/}
              {props.userContext.roleId === Constant.UserRoleId.District &&
                (selectedSchool === "0" ||
                  selectedSubject === "0" ||
                  selectedGrade === "0") && (
                  <ReactTooltip
                    id="incompleteSelection"
                    class="bg-secondary-teal/10 z-50 "
                    effect="solid"
                  >
                    <span>Please complete all required fields</span>
                  </ReactTooltip>
                )}

              {/* School Admin*/}
              {props.userContext.roleId === Constant.UserRoleId.SchoolAdmin &&
                (selectedSchool === "0" ||
                  selectedSubject === "0" ||
                  (selectedClass === "0" && selectedGrade === "0")) && (
                  <ReactTooltip
                    id="incompleteSelection"
                    class="bg-secondary-teal/10 z-50 "
                    effect="solid"
                  >
                    <span>Please complete all required fields</span>
                  </ReactTooltip>
                )}

              {/* Teacher */}
              {props.userContext.roleId !== Constant.UserRoleId.District &&
                props.userContext.roleId !== Constant.UserRoleId.SchoolAdmin &&
                (selectedSchool === "0" ||
                  selectedSubject === "0" ||
                  selectedClass === "0") && (
                  <ReactTooltip
                    id="incompleteSelection"
                    class="bg-secondary-teal/10 z-50 "
                    effect="solid"
                  >
                    <span>Please complete all required fields</span>
                  </ReactTooltip>
                )}

              <div className="cursor-not-allowed">
                <button
                  onClick={onRunReport}
                  className={
                    "bg-primary-violet border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 " +
                    (props.userContext.roleId ===
                      Constant.UserRoleId.District &&
                    (selectedSchool === "0" ||
                      selectedSubject === "0" ||
                      (selectedClass === "0" && selectedGrade === "0"))
                      ? "bg-opacity-20 text-primary-violet text-opacity-40 pointer-events-none"
                      : props.userContext.roleId ===
                          Constant.UserRoleId.SchoolAdmin &&
                        (selectedSchool === "0" ||
                          selectedSubject === "0" ||
                          (selectedClass === "0" && selectedGrade === "0"))
                      ? "bg-opacity-20 text-primary-violet text-opacity-40 pointer-events-none"
                      : props.userContext.roleId !==
                          Constant.UserRoleId.District &&
                        props.userContext.roleId !==
                          Constant.UserRoleId.SchoolAdmin &&
                        (selectedSchool === "0" ||
                          selectedSubject === "0" ||
                          (selectedClass === "0" && selectedGrade === "0"))
                      ? "bg-opacity-20 text-primary-violet text-opacity-40 pointer-events-none"
                      : "hover:bg-dark-violet hover:shadow-lg")
                  }
                >
                  Run Report
                </button>
              </div>
            </p>
            <div className="cursor-not-allowed">
              <button
                onClick={() => exportToExcel()}
                className={
                  "bg-primary-violet border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-dark-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3 " +
                  (reportData &&
                  reportData.length > 0 &&
                  reportData[0].liftoffHeaders &&
                  reportData[0].liftoffHeaders.length > 0
                    ? ""
                    : "bg-opacity-20 text-primary-violet text-opacity-40 pointer-events-none")
                }
              >
                Export Data
              </button>
            </div>

            <p
              className="cursor-pointer"
              data-tip
              data-for="incompleteSelection"
            ></p>
            {/* <button className="bg-primary-violet border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-dark-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3 ">
              Export Data
            </button>
            <button className="bg-primary-violet border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-dark-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3 ">
              Print Report
            </button> */}
          </div>

          <div className="border-b border-gray-400 py-2 mb-2">
            <div className="flex items-center  border-yellow-500 inline-block  mx-auto rounded-sm ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="w-5 h-5 mr-1 text-yellow-500"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M12 10.5v3.75m-9.303 3.376C1.83 19.126 2.914 21 4.645 21h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 4.88c-.866-1.501-3.032-1.501-3.898 0L2.697 17.626zM12 17.25h.007v.008H12v-.008z"
                />
              </svg>
              Note: You must select a Grade and/or Class to run this report.
            </div>
          </div>

          {noRecordFoundMsg}
          {showReport && (
            <div className="bg-white px-3 pt-2 pb-3">
              <div className="pt-5 overflow-x-auto">
                <div className="mb-4 pb-4 border-b-2 overflow-x-auto xl:overflow-x-visible">
                  <table className="table w-full ">
                    <thead>
                      <tr className="text-white border border-white ">
                        <th className="text-left p-3 text-sm font-normal"></th>
                        <th className="text-left p-3 text-sm font-normal"></th>
                        <th className="text-left p-3 text-sm font-normal"></th>
                        <th className="text-left p-3 text-sm font-normal"></th>
                        <th
                          colSpan={3}
                          className="text-white  p-3 text-sm font-normal bg-primary-violet text-center  font-bold border-r border-white"
                        >
                          {subjectName} Level Overall
                        </th>
                        {reportData &&
                          reportData.length > 0 &&
                          reportData[0].liftoffHeaders &&
                          reportData[0].liftoffHeaders.length > 0 &&
                          reportData[0].liftoffHeaders?.map((item, index) => {
                            return (
                              <th
                                colSpan={2}
                                className="text-white  p-3 text-sm font-normal bg-gray-500 text-center  font-bold border-r border-white"
                              >
                                {item.header}
                              </th>
                            );
                          })}
                      </tr>

                      <tr className="bg-primary-violet  text-white ">
                        <th className="text-left p-3 text-sm font-normal">
                          First Name
                        </th>
                        <th className="text-left p-3 text-sm font-normal">
                          Last Name
                        </th>
                        <th className="text-left p-3 text-sm font-normal">
                          Student Id
                        </th>
                        <th className="text-left p-3 text-sm font-normal  border-r border-white">
                          Grade Level
                        </th>

                        <th className="text-center p-3 text-sm font-normal">
                          Starting Grade Level
                        </th>
                        <th className="text-center p-3 text-sm font-normal">
                          Current Grade Level
                        </th>
                        <th className="text-center p-3 text-sm font-normal border-r border-white">
                          Overall Growth
                        </th>
                        {reportData &&
                          reportData.length > 0 &&
                          reportData[0].liftoffHeaders &&
                          reportData[0].liftoffHeaders.length > 0 &&
                          reportData[0].liftoffHeaders?.map((item, index) => {
                            return (
                              <>
                                <th className="text-center p-3 text-sm font-normal bg-gray-500">
                                  Starting Grade Level
                                </th>
                                <th className="text-center p-3 text-sm font-normal bg-gray-500 border-r border-white">
                                  Current Grade Level
                                </th>
                              </>
                            );
                          })}
                      </tr>
                    </thead>
                    <tbody>
                      {reportData &&
                        reportData.length > 0 &&
                        reportData[0].students &&
                        reportData[0].students.length > 0 &&
                        reportData[0].students?.map((item, index) => {
                          return (
                            <>
                              <tr
                                className={`hover:bg-primary-violet/20 transition ease-in-out duration-300 ${
                                  index % 2 === 0
                                    ? "bg-white"
                                    : "bg-primary-violet/10"
                                }`}
                              >
                                <td className="text-sm p-3 text-left ">
                                  {item.firstName}
                                </td>
                                <td className="text-sm p-3 text-left ">
                                  {item.lastName}
                                </td>
                                <td className="text-sm p-3 text-center ">
                                  {item.studentId}
                                </td>
                                <td className="text-sm p-3 text-center  border-r border-gray-200 ">
                                  {item.grade}
                                </td>

                                {item.data &&
                                  item.data.length > 0 &&
                                  item.data?.map((liftoffGrade, index) => {
                                    return (
                                      <td
                                        className={
                                          "text-sm p-3 text-center " +
                                          (index !== 0 && index % 2 === 0
                                            ? "border-r border-gray-200 "
                                            : "")
                                        }
                                      >
                                        {liftoffGrade}
                                      </td>
                                    );
                                  })}
                              </tr>
                            </>
                          );
                        })}
                      {reportData?.length === 0 && (
                        <tr className="bg-white">
                          <td colSpan={6} className="text-sm p-3">
                            No records found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <div
                  className={`${props.pageNumber} hidden sm:flex-1 sm:flex sm:items-center sm:justify-between`}
                >
                  {reportData?.length !== 0 && (
                    <Pagination
                      totalRecords={totalRecords}
                      pageSize={pageSize}
                      onPageChange={onPageChange}
                      reset={resetPageNumber}
                      bgColor="primary-violet"
                      pageNumber={pageNumber}
                    />
                  )}
                  {reportData?.length !== 0 && (
                    <PageSize
                      pageSize={pageSize}
                      onPageSizeChange={onPageSizeChange}
                      bgColor="primary-violet"
                    />
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
export default connect((state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
})(LiftoffDetailGrowthReport);

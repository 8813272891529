import { AxiosHelper } from "../environment/axiosConfig";
import APIConstant from "../utils/constant/apiConstant";
import { IFeedback } from "../model/interface/feedback";

export const getAllFeedbackTypes = () => {
  return AxiosHelper.axiosInstance().get(APIConstant.APP_FEEDBACK, {});
};

export const addFeedback = (feedback: IFeedback) => {
  return AxiosHelper.axiosInstance().post(
    APIConstant.APP_FEEDBACK,
    feedback,
    {}
  );
};

import { useEffect, useState } from 'react'
import Profile from '../../../../../model/users/profile'
import { UserContextState } from '../../../../../redux/actions/userContextAction'
import Constant from '../../../../../utils/constant/constant'
import Loader from '../../../../shared/loader'
import AllowedTo from '../../../../shared/rbac'
import Permissions from '../../../../../utils/permissions'
import AccessDenied from '../../../../shared/accessDenied'
import * as uuid from 'uuid'
import classNames from 'classnames'
import { connect } from 'react-redux'
import InfoMessage from '../../../../shared/infoMessage'
import { SchoolYearDropDown } from '../../../schoolYearDropdown'
import { ISchoolYear } from '../../../../../model/teacher/schoolYear'
import Subject from '../../../../shared/dropdowns/subject'
import { ISelectElement } from '../../../../../model/interface/selectElement'
import Asterisk from '../../../../shared/asterisk'
import Select from 'react-select'
import { ISelectCustomElement } from '../assessmentResultsReport/assessmentResultsReport'
import { getAssessmentsWithResults, getPremiumAssessments } from '../../../../../api/teacher/assessment'
import { IAssessmentSearch } from '../../../../../model/interface/assessmentSearch'
import Moment from 'moment'
import { getEndDate, getStartDate } from '../../../../../utils/dateHelper'
import { IAssessment } from '../../../../../model/interface/assessment'
import ResultsReportAssigments from './resultsReportAssigments'
import ResultsData from '../results/resultsData'
import constant from '../../../../../utils/constant/constant'
import { ReportType } from '../../../../../types/type'
import { useLocation } from 'react-router-dom'
import { getPremiumAssessmentNameWithSubjectPrefix } from '../../../../../utils/assignmentHelper'
import { toast } from 'react-toastify'

interface IResultsReportProps {
  userContext: UserContextState
  profile: Profile
  match?: any
  location: any
}

const ResultsReport = (props: IResultsReportProps) => {
  //** Constants **//
  const { userId } = props.userContext;
  const isDistrictUser: boolean = props.userContext.roleId === constant.UserRoleId.District;

  const defaultValue = { label: "Select", value: "0" };
  const myAsessmentsText = 'My Assessments';
  const sharedAsessmentsText = 'Shared Assessments';
  const premiumAsessmentsText = 'Premium Assessments';

  const tabInfo = {
    VIEW_BY_ASSESSMENT: {
      id: 0,
      name: 'View by Assessment',
      isActive: true
    },
    VIEW_BY_ASSIGNMENT: {
      id: 1,
      name: 'View by Assignment',
      isActive: props.userContext.roleId !== Constant.UserRoleId.FreeTeacher
    }
  }
  
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const redirectedFrom = query.get('redirectedFrom');
  
  const [showLoading, setShowLoading] = useState<boolean>(false)

  const [selectedTab, setSelectedTab] = useState<number>(
    redirectedFrom ? redirectedFrom === constant.RedirectedFrom.MYASSIGNMENTS || redirectedFrom === constant.RedirectedFrom.GRADEBOOK
    ? tabInfo.VIEW_BY_ASSIGNMENT.id : tabInfo.VIEW_BY_ASSESSMENT.id : tabInfo.VIEW_BY_ASSESSMENT.id)

  const [selectedSchoolYearId, setSelectedSchoolYearId] = useState<number> (query.get('schoolYearId') ? Number(query.get('schoolYearId')) : 0)
  const [resetSchoolYear, setResetSchoolYear] = useState<boolean>(false)
  const [schoolYearData, setSchoolYearData] = useState<Array<ISchoolYear>>()
  const [selectedSubject, setSelectedSubject] = useState<ISelectElement>()
  const [selectedAssessmentOption, setSelectedAssessmentOption] =
    useState<ISelectCustomElement>()
  const [assessments, setAssessments] = useState<Array<ISelectCustomElement>>()
  const [allAssessments, setAllAssessments] = useState<Array<IAssessment>>([])
  const [startDate, setStartDate] = useState<any>()
  const [endDate, setEndDate] = useState<any>()
  const [selectedAssessmentType, setSelectedAssessmentType] =
    useState<string>(myAsessmentsText)
  const [showResults, setShowResults] = useState<boolean>(false);  
  const [selectedAssessmentId, setSelectedAssessmentId] = useState<number>(0);
  const [selectedAssignmentId, setSelectedAssignmentId] = useState<number>(0);
  const [selectedAssignmentActivityId, setSelectedAssignmentActivityId] = useState<number>(0);
  const [assessmentHasCrActivity, setAssessmentHasCrActivity] = useState<boolean | undefined>(false);
  const [selectedSubjectId, setSelectedSubjectId] = useState<number>(query.get('subjectId') ? Number(query.get('subjectId')) : 0);
  const [arePremiumAssessmentAvailables, setArePremiumAssessmentAvailables] = useState<boolean>(false);
  
  

  let [tabs] = useState<any[]>([
    tabInfo.VIEW_BY_ASSESSMENT,
    tabInfo.VIEW_BY_ASSIGNMENT
  ])

  const assessmentsTypes = [
    {
      label: myAsessmentsText,
      value: myAsessmentsText
    },
    {
      label: sharedAsessmentsText,
      value: sharedAsessmentsText
    },
  ];

  if (arePremiumAssessmentAvailables) 
    assessmentsTypes.push({
      label: premiumAsessmentsText,
      value: premiumAsessmentsText
    });

  //** Hooks **//
  
  useEffect(() => {
    // Check for the redirected from query parameter
    const type = getAssessmentTypeFromUrl();
    if (type) {
      setSelectedAssessmentType(type);
    }    
  }, [])

  useEffect(() => {    
    if(schoolYearData) {
      // Check if we have selected school year id
      var querySchoolYearId = query.get('schoolYearId');
      if (querySchoolYearId) {
        handleDateFilter(Number(querySchoolYearId));
      }
    }
  }, [schoolYearData])

  useEffect(() => {
    if (
      selectedSchoolYearId &&
      selectedSubject &&
      selectedSubject.value !== '0' &&
      selectedSubject.value !== '' &&
      startDate &&
      endDate
    ) {
      setSelectedAssessmentOption(undefined);      
      fetchAssessments();
      setShowResults(false);
    }
  }, [selectedSubject, startDate, endDate])

  useEffect(() => {
    if (selectedSchoolYearId) {
      handleDateFilter(selectedSchoolYearId);
      setResetSchoolYear(false);
      setSelectedAssessmentOption(undefined);    
      checkPremiumAssessmentsAvailables();  
    }
  }, [selectedSchoolYearId])

  useEffect(() => {
    if (allAssessments) {
      const filteredAssessments = filterAssessments(allAssessments);
      setAssessments(filteredAssessments);
    }

    setSelectedAssessmentId(0);
    setSelectedAssessmentOption(undefined);
  }, [selectedAssessmentType, allAssessments])

  useEffect(() => {
    // Now, if we have a selected assessment id then we need to show the results
    var assessmentIdParam = query.get('assessmentId');
    
    if (assessmentIdParam && assessments && assessments.length > 0) {
      // Now, we try to get the option
      let option = assessments.filter(a => a.value === assessmentIdParam)[0];
      handleAssessmentSelection(option);
      setShowResults(true);
    }

  }, [assessments])
  

  //** Handlers **/

  const getAssessmentTypeFromUrl = () => {
    let assessmentType = query.get('redirectedFrom')
    if (assessmentType) {
      // Now, we need to check from where it has been redirected
      if (assessmentType === constant.RedirectedFrom.MYASSESSMENTS) {
        return myAsessmentsText
      } 
      else if (assessmentType === constant.RedirectedFrom.FULLASSESSMENT) {
        return sharedAsessmentsText
      }
      else if (
        assessmentType === constant.RedirectedFrom.PREMIUMASSESSMENTS
      ) {
        return premiumAsessmentsText
      } else {
        return myAsessmentsText
      }
    }
  }

  const checkPremiumAssessmentsAvailables = () => {
    getPremiumAssessments(
      null,
      null,
      null,
      null,
      null,
      null,
      1,
      1,
      "Name",
      false,
      selectedSchoolYearId
    )
    .then(response => {
      setArePremiumAssessmentAvailables(response.data.data.length > 0);
    })
    .catch(error => {
      toast.error(`${error.message} Cannot load Premium Assessments`);
    })
  }

  const handleSchoolYearLoaded = (currentSchoolYearId?: number) => {
    setShowResults(false);
    handleSchoolYearChange(currentSchoolYearId);
  }

  const handleSchoolYearChange = (schoolYearId?: number) => {
    setShowResults(false);
    setSelectedSchoolYearId(schoolYearId ?? 0);
  }

  const handleSchoolYearData = (schoolYearData: Array<ISchoolYear>) => {
    setShowResults(false);
    setSchoolYearData(schoolYearData);
  }

  const handleSubjectChange = (selectedItem: any) => {
    setShowResults(false);
    setSelectedSubject(selectedItem);
  }

  const handlesSelectedAssessmentType = (selectedItem: any) => {
    setShowResults(false);
    setSelectedAssessmentType(selectedItem?.value!)
  }

  const handleAssessmentSelection = (selectedItem: ISelectCustomElement) => {  
    setShowResults(false);
    setSelectedAssessmentOption(selectedItem);

    if (selectedItem) {
      // Now, we get the assessment from the list of all assessments
      let assessment = allAssessments.filter(
        (a) => a.assessmentId === parseInt(selectedItem.value)
      )[0];

      setSelectedAssessmentId(assessment.assessmentId);
      setSelectedAssignmentId(0);
      setSelectedAssignmentActivityId(assessment.assessmentId);
      setAssessmentHasCrActivity(assessment.isAssessmentWithCR);
      setSelectedSubjectId(assessment.subjectId);
    }
  }

  const handleDateFilter = (schoolYearId?: number) => {
    let selectedSchoolYear = schoolYearData?.filter(
      (s) => s.schoolYearId === schoolYearId
    )[0];
    let minDate = selectedSchoolYear
      ? Moment(
          selectedSchoolYear.startDate.toString().split('T')[0],
          'YYYY-MM-DD'
        ).toDate()
      : new Date();

    let maxDate = selectedSchoolYear
      ? Moment(
          selectedSchoolYear.startDate.toString().split('T')[0],
          'YYYY-MM-DD'
        )
          .add(1, 'year')
          .subtract(1, 'day')
          .toDate()
      : new Date();

    setStartDate(getStartDate(minDate));
    setEndDate(getEndDate(maxDate));
  }

  const fetchAssessments = async () => {
    try {
      setShowLoading(true)
      const assessmentSearch: IAssessmentSearch = {
        teacherId: userId,
        subjectId: parseInt(selectedSubject?.value!),
        canViewValue: true,
        schoolYearId: selectedSchoolYearId,
        fromDate: startDate,
        toDate: endDate,
        includeUngraded: true,
        includePremium: false,
        pageNumber: 1,
        pageSize: 1000
      }

      const assessmentsPromise = getAssessmentsWithResults(assessmentSearch)
      const premiumAssessmentsPromise = getPremiumAssessments(
        null,
        null,
        parseInt(selectedSubject?.value!),
        null,
        null,
        null,
        1,
        1000,
        'Name',
        false,
        selectedSchoolYearId
      )

      const [assessmentsResult, premiumAssessmentsResult] = await Promise.all([
        assessmentsPromise,
        premiumAssessmentsPromise
      ])

      let assessmentFromServer: IAssessment[] = [...assessmentsResult.data]

      const premiumAssessments: IAssessment[] =
        premiumAssessmentsResult.data.data
          .filter((x) => x.completed > 0)
          .map((x) => ({
            assessmentId: x.cannedTestId,
            name: getPremiumAssessmentNameWithSubjectPrefix(x),
            code: x.name,
            subjectId: x.subjectId,
            authorFirstName: '',
            authorLastName: '',
            createdDate: x.createdDate,
            diagTestTypeId: x.cannedTestTypeId,
            isAdditional: false,
            isSharedAssessment: false,
            isAssessmentWithCR: false
          }))

      assessmentFromServer = [...assessmentFromServer, ...premiumAssessments]

      setAllAssessments(assessmentFromServer)

      const filteredAssessments = filterAssessments(assessmentFromServer)

      setAssessments(filteredAssessments)
    } catch (error) {
      console.error('Error fetching assessments', error)
    } finally {
      setShowLoading(false)
    }
  }

  const filterAssessments = (assessments: IAssessment[]) => {
    let filteredAssessments: ISelectCustomElement[] = [];

    if (selectedAssessmentType === myAsessmentsText) {
      filteredAssessments = getMyAssessments(assessments);
    } else if (selectedAssessmentType === sharedAsessmentsText) {
      filteredAssessments = getSharedAssessments(assessments);
    } else if (selectedAssessmentType === premiumAsessmentsText) {
      filteredAssessments = buildPremiumAssessments(assessments);
    }

    return filteredAssessments;
  }

  const getMyAssessments = (
    assessments: IAssessment[]
  ): ISelectCustomElement[] => {
    return assessments
      .filter(
        (assessment: IAssessment) =>
          !assessment.isAdditional &&          
          !assessment.diagTestTypeId
      )
      .sort((a, b) => a.name.localeCompare(b.name))
      .map(
        (
          assessment
        ): {
          createdDate: Date | undefined
          label: string
          value: string
          name: string
          code: string | undefined
          isPremiumAssessment: boolean
        } => {
          return {
            value: assessment.assessmentId.toString(),
            label: assessment.name + ' - ' + assessment.code,
            name: assessment.name,
            createdDate: assessment.createdDate,
            code: assessment.code,
            isPremiumAssessment: false
          }
        }
      );
  }

  const getSharedAssessments = (
    assessments: IAssessment[]
  ): ISelectCustomElement[] => {
    return assessments
      .filter(
        (assessment) => assessment.isAdditional || assessment.isSharedAssessment
      )
      .sort((a, b) => {
        let aValue = [a.authorLastName, a.authorFirstName, a.name].join('')
        let bValue = [b.authorLastName, b.authorFirstName, b.name].join('')
        return aValue.localeCompare(bValue)
      })
      .map(
        (
          assessment
        ): {
          createdDate: Date | undefined
          label: string
          value: string
          name: string
          code: string | undefined
          isPremiumAssessment: boolean
        } => {
          return {
            value: assessment.assessmentId.toString(),
            label: assessment.name + ' - ' + assessment.code,
            name: assessment.name,
            createdDate: assessment.createdDate,
            code: assessment.code,
            isPremiumAssessment: false
          }
        }
      );
  }

  const buildPremiumAssessments = (
    assessments: IAssessment[]
  ): ISelectCustomElement[] => {
    return assessments
      .filter(
        (assessment) =>
          !assessment.isAdditional &&
          !assessment.isSharedAssessment &&
          assessment.diagTestTypeId
      )
      .sort((a, b) => {
        let aValue = [a.authorLastName, a.authorFirstName, a.name].join('')
        let bValue = [b.authorLastName, b.authorFirstName, b.name].join('')
        return aValue.localeCompare(bValue)
      })
      .map(
        (
          assessment
        ): {
          createdDate: Date | undefined
          label: string
          value: string
          name: string
          code: string | undefined
          isPremiumAssessment: boolean
        } => {
          return {
            value: assessment.assessmentId.toString(),
            label: assessment.name,
            name: assessment.name,
            createdDate: assessment.createdDate,
            code: assessment.name,
            isPremiumAssessment: true
          }
        }
      );
  }

  const onReset = () => {
    setShowResults(false);
    setSelectedSubject(defaultValue);
    setSelectedAssessmentOption(undefined);    
    setAssessments([]);    
    setSelectedAssessmentId(0);
    setSelectedAssignmentId(0);
    setSelectedAssignmentActivityId(0);
    setAssessmentHasCrActivity(false);
    setSelectedSubjectId(0);
    setResetSchoolYear(true);    
    setSelectedAssessmentType(myAsessmentsText);
  };

  const runReport = () => {    
    setShowResults(true);
  };

  const handleToggleLoader = (show: boolean) => {
    setShowLoading(show);
  }

  const handleToggleShowResults = (show: boolean) => {
    setShowResults(show);
  }

  const handleTabChange = (tabId: number) => {
    setSelectedTab(tabId);
    onReset();
  }

  const handleAssignmentSelected = (assignmentId: number, schoolYear: number, assignmentActivityId?: number, assessmentId?: number, hasCRActivity?: boolean) => {    
    setSelectedAssessmentId(assessmentId ?? 0);
    setSelectedAssignmentId(assignmentId);
    setSelectedSchoolYearId(schoolYear);
    setSelectedAssignmentActivityId(assignmentActivityId ?? 0);
    setAssessmentHasCrActivity(hasCRActivity);
    setSelectedSubjectId(0);
  }

  const getFilteredTabs = () => {    
    // We need to filter that if the user is District user we remove the view by assignment tab
    if (isDistrictUser) {      
      return tabs.filter(f => (f.id !== tabInfo.VIEW_BY_ASSIGNMENT.id));
    } else {
      return tabs;
    }
  }

  return (
    <>
      {showLoading && <Loader></Loader>}
      <AllowedTo
        perform={Permissions.assessments_view}
        no={() => <AccessDenied />}
      >
        <div className='min-w-0 w-full'>
        <div className="lg:flex lg:items-center px-6 py-3 lg:px-8 border-b border-gray-200">
          <div className="lg:w-full text-3xl flex justify-center h-auto sm:h-10 items-center">
            <h1 className="text-primary-violet font-medium flex justify-center">
              Results Report
            </h1>
          </div>
        </div>
          <div className='px-2 sm:px-6  lg:px-8 py-1 relative my-4'>
            <div className='bg-gray-100 w-full rounded-lg p-5'>
              <div className='bg-white px-5 py-5'>
                <div className='flex bg-gray-100 px-3 pt-2 flex-col lg:flex-row lg:justify-between'>
                  <div className='flex lg:mr-2 xl:mr-0'>
                    <nav className='flex space-x-2' aria-label='Tabs'>
                      {getFilteredTabs().map((tab) => (
                        <button
                          key={uuid.v4()}
                          onClick={() => handleTabChange(tab.id)}
                          className={classNames(
                            tab.id === selectedTab
                              ? 'border-secondary-teal bg-white text-primary-violet hover:text-dark-violet py-3 tab-box-shadow tab-box-shadow'
                              : 'text-primary-violet text-opacity-60 bg-primary-violet/10 py-[0.6rem] mt-auto transition-all hover:py-3 hover:text-opacity-100 hover:text-primary-violet border-[#dee9f1]',
                            'px-2 sm:px-4 text-center border-t-2 text-sm cursor-pointer font-medium'
                          )}
                        >
                          {tab.name}
                        </button>
                      ))}
                    </nav>
                  </div>
                </div>

                {selectedTab === tabInfo.VIEW_BY_ASSESSMENT.id && (
                  <div className='mt-5'>
                    <InfoMessage
                      message='Selecting to view by assessment will allow you to see all results regardless of assignment. Because a single assessment can be assigned at different times to different students under multiple assignments, 
                      this view will provide you with the overall picture for all students who completed the single assessment with the ability to drill down to additional levels. Remediation is not available from this view.'
                    />
                    <div>
                      <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 2xl:grid-cols-6 gap-4 mb-4 mt-4 order-last md:order-1'>
                        <div className='flex flex-col'>
                          <label className='block mb-1 text-sm text-gray-500'>
                            School Year <Asterisk />
                          </label>
                          <SchoolYearDropDown
                            reset={resetSchoolYear}
                            offsets={[0, -1]}
                            handleSchoolYearChange={handleSchoolYearChange}
                            handleSchoolYearLoaded={handleSchoolYearLoaded}
                            handleSchoolYearData={handleSchoolYearData}
                            sortByGreatestFirst={true}
                            schoolYearId={selectedSchoolYearId}
                          ></SchoolYearDropDown>
                        </div>
                        <div className='flex flex-col'>
                          <Subject
                            setSelectedSubject={(items) =>
                              handleSubjectChange(items[0])
                            }
                            selectedItems={selectedSubject}
                            isMulti={false}
                            isRequired={true}
                            themeColor={'#008fbb'}
                            subjectId={selectedSubjectId}
                          ></Subject>
                        </div>
                        <div className='flex flex-col'>
                          <label className='block mb-1 text-sm text-gray-500'>
                            Assessment Type
                          </label>
                          <Select
                            value={{
                              label: selectedAssessmentType,
                              value: selectedAssessmentType
                            }}
                            options={assessmentsTypes}
                            onChange={handlesSelectedAssessmentType}
                            isMulti={false}
                            closeMenuOnSelect={true}
                            theme={(theme) => ({
                              ...theme,
                              colors: {
                                ...theme.colors,
                                primary: '#008fbb'
                              }
                            })}
                          />
                        </div>
                        <div className='flex flex-col col-span-2'>
                          <label className='block mb-1 text-sm text-gray-500'>
                            Assessments <Asterisk />
                          </label>
                          <Select
                            value={selectedAssessmentOption || null}
                            options={assessments}
                            onChange={(e) =>
                              handleAssessmentSelection(
                                e as ISelectCustomElement
                              )
                            }
                            isMulti={false}
                            closeMenuOnSelect={true}
                            className={
                              selectedSubject === undefined
                                ? 'pointer-events-none text-gray-400 bg-gray'
                                : ''
                            }
                            theme={(theme) => ({
                              ...theme,
                              colors: {
                                ...theme.colors,
                                primary: '#008fbb'
                              }
                            })}
                          />
                        </div>
                      </div>
                      <div className='flex gap-4 justify-end'>
                        <button
                          onClick={onReset}
                          className='bg-transparent border my-auto border-primary-violet shadow-sm py-2 px-4 flex justify-center text-sm font-medium text-primary-violet hover:text-white hover:bg-light-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3 transition ease-in-out duration-500'
                        >
                          Reset
                        </button>
                        <div className='cursor-not-allowed'>
                          <button
                            onClick={runReport}
                            className={
                              'bg-primary-violet border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ' +
                              (selectedSubject === undefined ||
                              !selectedAssessmentOption ||                              
                              (selectedSubject?.value ?? '0') === '0'                              
                                ? 'bg-opacity-20 text-primary-violet text-opacity-40 pointer-events-none'
                                : 'hover:bg-dark-violet hover:shadow-lg')
                            }
                          >
                            Run Report
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {!isDistrictUser && selectedTab === tabInfo.VIEW_BY_ASSIGNMENT.id && (
                  <ResultsReportAssigments
                    userContext={props.userContext}
                    profile={props.profile}
                    match={props.match}
                    location={props.location}
                    onReset={onReset}
                    onRunReport={runReport}
                    toggleLoader={handleToggleLoader}
                    onAssignmentSelected={handleAssignmentSelected}
                    toggleShowResults={handleToggleShowResults}
                    schoolYearId={selectedSchoolYearId}
                    setSchoolYearId={setSelectedSchoolYearId}
                    selectedSubjectId={selectedSubjectId}
                    redirectedFrom={redirectedFrom === null ? constant.RedirectedFrom.MYASSIGNMENTS : redirectedFrom}
                  />
                )}
              </div>
            </div>
          </div>
          { showResults && (selectedAssessmentId > 0 || selectedAssignmentId > 0) && (
            <ResultsData
              userContext={props.userContext}
              profile={props.profile}
              reportType = {selectedTab === tabInfo.VIEW_BY_ASSIGNMENT.id ?
                 constant.AssessmentTypes.ASSIGNMENT as ReportType 
                 : selectedAssessmentType === premiumAsessmentsText 
                 ? constant.AssessmentTypes.PREMIUMASSESSMENT as ReportType 
                 : constant.AssessmentTypes.ASSESSMENT as ReportType
                }
              assessmentId={selectedAssessmentId}
              assignmentId={selectedAssignmentId}
              assignmentActivityId={selectedAssignmentActivityId}
              hasCRActivity={assessmentHasCrActivity}
              redirectedFrom={selectedTab === tabInfo.VIEW_BY_ASSIGNMENT.id 
                ? constant.RedirectedFrom.MYASSIGNMENTS 
                : selectedAssessmentType === premiumAsessmentsText ? constant.RedirectedFrom.PREMIUMASSESSMENTS : constant.RedirectedFrom.MYASSESSMENTS}
              assessmentSubjectId={selectedSubjectId}
              schoolYearId={selectedSchoolYearId}
              toggleLoader={handleToggleLoader}
            ></ResultsData>
          )}
        </div>
      </AllowedTo>
    </>
  )
}

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile
  }
}

export default connect<{}, {}, {}>(mapStateToProps, {})(ResultsReport)

import PrintHeader from "../../../shared/print/printHeader"

interface IStudentReportCardPrintProps {
  studentName: string,
  chartOptions: any,
  chartData: any
}

function StudentReportCardPrint(props: IStudentReportCardPrintProps) {
  const { studentName, chartData } = props;

  const startDate = chartData.labels[0] || "";
  const endDate = chartData.labels[chartData.labels.length - 1] || "";

  return (
    <div id="student-report-card-print-header" className="w-full p-5">
      <PrintHeader ReportName="Student Report Card" />
      <div className="w-full border rounded-lg py-1 px-5 grid grid-cols-3 gap-4">
        <div className="flex flex-row gap-2">
          <label className="block text-sm text-gray-700">
            Student:
          </label>
          <label className="block text-sm text-gray-500">
            {studentName}
          </label>
        </div>
        <div className="flex flex-row gap-2">
          <label className="block text-sm text-gray-700">
            Start Date:
          </label>
          <label className="block text-sm text-gray-500">
            {startDate}
          </label>
        </div>
        <div className="flex flex-row gap-2">
          <label className="block text-sm text-gray-700">
            End Date:
          </label>
          <label className="block text-sm text-gray-500">
            {endDate}
          </label>
        </div>
      </div>
    </div>
  )
}

export default StudentReportCardPrint

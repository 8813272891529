import { useEffect, useState } from "react";
import DotRank from "../../../../shared/widgets/dotRank";
import SortTableHeader from "../../../../shared/sortTableHeader";
import { SortingOrder } from "../../../../../types/type";
import { sortReport } from "../../../../../utils/sortProgressReportHelper";

export default function ProgressReportByStudentByCount(props: any) {
  const [progressReportByStudent, setProgressReportByStudent] = useState<any>();

  useEffect(() => {
    if (!props?.data?.modified) {
      setPercentageForStudents();
    }
    props.data.modified = true;
    setProgressReportByStudent(props.data);
    sortProgressReportByStudent(
      props.data,
      props.activeSortColumn,
      props.orderType
    );
  }, []);

  const setPercentageForStudents = () => {
    const summaryIndexes = props.getSummaryIndexes(props.data);
    props.data?.students?.forEach((student, studentIndex) => {
      student["isCollapse"] = true;
      if (studentIndex !== 0) {
        student.pctCorrect.forEach((pct, pctIndex) => {
          let value = Math.round(pct * 100);
          student["lessThan65"] = student["lessThan65"]
            ? student["lessThan65"]
            : 0;

          student["lessThan80"] = student["lessThan80"]
            ? student["lessThan80"]
            : 0;

          student["greaterThan80"] = student["greaterThan80"]
            ? student["greaterThan80"]
            : 0;
          student["default"] = student["default"] ? student["default"] : 0;

          switch (true) {
            case summaryIndexes.includes(pctIndex):
              break;
            case pct == null:
              student["default"] = Number(student["default"]) + 1;
              break;
            case value < 65:
              student["lessThan65"] = Number(student["lessThan65"]) + 1;
              break;
            case value >= 65 && value < 80:
              student["lessThan80"] = Number(student["lessThan80"]) + 1;
              break;
            case value >= 80:
              student["greaterThan80"] = Number(student["greaterThan80"]) + 1;
              break;
            default:
              student["default"] = Number(student["default"]) + 1;
              break;
          }
        });

        //Summary
        if (props.data?.students) {
          props.data.students[0]["lessThan65"] =
            (props.data.students[0]["lessThan65"]
              ? props.data.students[0]["lessThan65"]
              : 0) + student["lessThan65"];

          props.data.students[0]["lessThan80"] =
            (props.data.students[0]["lessThan80"]
              ? props.data.students[0]["lessThan80"]
              : 0) + student["lessThan80"];

          props.data.students[0]["greaterThan80"] =
            (props.data.students[0]["greaterThan80"]
              ? props.data.students[0]["greaterThan80"]
              : 0) + student["greaterThan80"];

          props.data.students[0]["default"] =
            (props.data.students[0]["default"]
              ? props.data.students[0]["default"]
              : 0) + student["default"];
        }
      }
    });
  };

  const sortProgressReportByStudent = (
    data: any,
    column: string,
    orderType: SortingOrder
  ) => {
    sortReport({
      data: data?.students,
      column,
      orderType,
      defaultName: "All Students",
      defaultSurnameOrder: true,
    });
    props.setOrderOptions(column, orderType);
  };

  return (
    <>
      {progressReportByStudent && (
        <div className="pt-10 overflow-x-auto">
          <div className="overflow-x-auto">
            <table className="divide-y border-b w-full">
              <tr className="bg-light-violet text-white py-3 px-3">
                <th className="text-left px-3 text-sm font-normal">
                  <SortTableHeader
                    data={progressReportByStudent}
                    orderType={props.orderType}
                    headerComponent="Student"
                    columnName="name"
                    activeSortColumn={props.activeSortColumn}
                    sortRecords={sortProgressReportByStudent}
                  />
                </th>
                <th className="text-center px-3 text-sm font-normal">
                  Student ID
                </th>
                <th className="text-center p-3 text-sm font-normal">
                  <span className="flex justify-center">
                    <SortTableHeader
                      data={progressReportByStudent}
                      orderType={props.orderType}
                      headerComponent={
                        <DotRank value={86} displayColorName={true} />
                      }
                      columnName="greaterThan80"
                      activeSortColumn={props.activeSortColumn}
                      sortRecords={sortProgressReportByStudent}
                    />
                  </span>
                </th>
                <th className="text-center p-3 text-sm font-normal">
                  <span className="flex justify-center">
                    <SortTableHeader
                      data={progressReportByStudent}
                      orderType={props.orderType}
                      headerComponent={
                        <DotRank value={65} displayColorName={true} />
                      }
                      columnName="lessThan80"
                      activeSortColumn={props.activeSortColumn}
                      sortRecords={sortProgressReportByStudent}
                    />
                  </span>
                </th>
                <th className="text-center p-3 text-sm font-normal">
                  <span className="flex justify-center">
                    <SortTableHeader
                      data={progressReportByStudent}
                      orderType={props.orderType}
                      headerComponent={
                        <DotRank value={64} displayColorName={true} />
                      }
                      columnName="lessThan65"
                      activeSortColumn={props.activeSortColumn}
                      sortRecords={sortProgressReportByStudent}
                    />
                  </span>
                </th>
                <th className="text-center p-3 text-sm font-normal">
                  <span className="flex justify-center">
                    <SortTableHeader
                      data={progressReportByStudent}
                      orderType={props.orderType}
                      headerComponent={
                        <DotRank value={undefined} displayColorName={true} />
                      }
                      columnName="default"
                      activeSortColumn={props.activeSortColumn}
                      sortRecords={sortProgressReportByStudent}
                    />
                  </span>
                </th>
              </tr>

              {progressReportByStudent.students.map((student, index) => (
                <>
                  <tr
                    className={
                      "border-l border-r " +
                      (index % 2 !== 0 ? "bg-primary-violet/10" : "")
                    }
                  >
                    <td className="text-left  text-sm font-normal flex">
                      <span
                        className={
                          (index === 0 ? "font-semibold " : "text-sm ") +
                          "text-center p-3  "
                        }
                      >
                        {student.name}
                      </span>
                    </td>
                    <td className="text-center  text-sm font-normal">
                      <span>{student.studentId}</span>
                    </td>
                    <td
                      className={
                        (index === 0 ? "font-semibold " : "text-sm ") +
                        "text-center p-3  "
                      }
                    >
                      {student.greaterThan80}
                    </td>
                    <td
                      className={
                        (index === 0 ? "font-semibold " : "text-sm ") +
                        "text-center p-3  "
                      }
                    >
                      {student.lessThan80}
                    </td>
                    <td
                      className={
                        (index === 0 ? "font-semibold " : "text-sm ") +
                        "text-center p-3  "
                      }
                    >
                      {student.lessThan65}
                    </td>
                    <td
                      className={
                        (index === 0 ? "font-semibold " : "text-sm ") +
                        "text-center p-3  "
                      }
                    >
                      {student.default}
                    </td>
                  </tr>
                </>
              ))}
            </table>
          </div>
        </div>
      )}
    </>
  );
}

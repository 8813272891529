type shape = "square" | "circle";
interface DotRankProps {
  value?: number;
  attempted?: number;
  displayScore?: boolean;
  tooltip?: string;
  shape?: shape;
  displayColorName?: boolean;
  isAssessmentWithCr?: boolean;
}

const reportResultColors = {
  grey: {
    color: "#E9E9E9",
    name: "Grey",
  },
  ceries: {
    color: "#E53F71",
    name: "Pink",
  },
  yellow: {
    color: "#FFDB3A",
    name: "Yellow",
  },
  green: {
    color: "#B2D136",
    name: "Green",
  },
};

export default function DotRank(props: DotRankProps) {
  const dotRankColor = (value) => {
    value = Math.round(value);
    let badgeBg = "";
    switch (true) {
      case value === undefined:
        badgeBg = reportResultColors.grey.color;
        break;
      case (props.attempted && props.attempted < 2) || props.attempted === 0:
        badgeBg = "#E9E9E9";
        break;
      case value < 65:
        badgeBg = reportResultColors.ceries.color;
        break;
      case value >= 65 && value <= 79:
        badgeBg = reportResultColors.yellow.color;
        break;
      case value >= 80:
        badgeBg = reportResultColors.green.color;
        break;
      default:
        badgeBg = reportResultColors.grey.color;
        break;
    }
    return badgeBg;
  };

  const dotRankColorName = (value) => {
    return Object.values(reportResultColors).find(
      (color) => color.color === dotRankColor(value)
    )?.name;
  };

  const renderValue = () => {
    if (props.isAssessmentWithCr && props.value === undefined) {
      return "NG";
    }
    return props.value;
  };

  return (
    <>
      {!props.displayScore && (
        <div className="flex items-center">
          <span
            className={
              "inline-block rounded-full bg-[" +
              dotRankColor(props.value) +
              "]" +
              " h-5 w-5"
            }
            style={{
              background: dotRankColor(props.value),
            }}
            title={props.tooltip}
          ></span>
          {props.displayColorName && (
            <span className="block justify-center text-sm text-white font-medium mx-1">
              {dotRankColorName(props.value) || ""}
            </span>
          )}
        </div>
      )}
      {props.displayScore && (!props.shape || props.shape == "square") && (
        <div
          className={
            "border-4 border-red-400 h-8 w-8 rounded-full flex items-center justify-center text-xs"
          }
          style={{
            borderColor: dotRankColor(props.value),
          }}
        >
           {renderValue()}
        </div>
      )}
      {props.displayScore && props.shape == "circle" && (
        <div
          className={
            "border-4 border-red-400 h-8 w-8 rounded-full flex items-center justify-center p-3 text-xs "
          }
          style={{
            borderColor: dotRankColor(props.value),
          }}
        >
           {renderValue()}
        </div>
      )}
    </>
  );
}

import { Fragment } from "react";

interface SkillStudentStatsProps {
  statName: string;
  studentData: number;
  opponentData: number;
  studentTotal: number;
  opponentTotal: number;
}

function SkillStudentStats(props: SkillStudentStatsProps) {
  let studentProgress = 0;
  let opponentProgress = 0;

  const calculateProgressBarWidth = (numerator: number, denomin: number) => {
    let widthPercentage = 0;
    if (denomin > 0) {
      widthPercentage = numerator / denomin;
    } else {
      widthPercentage = 0;
    }
    return Math.round(widthPercentage * 100);
  };

  return (
    <Fragment>
      <div className="flex flex-col items-center relative pb-6">
        <div className="flex space-x-2 w-full">
          <div className="w-1/2 relative">
            <div className="relative">
              <span className="bg-gray-300 rounded h-10 inline-block w-full absolute top-0 left-0"></span>

              <span
                className={`${
                  studentProgress > opponentProgress
                    ? "bg-primary-violet"
                    : "bg-secondary-teal"
                } rounded h-10 inline-block absolute top-0 right-0`}
                style={{
                  width:
                    calculateProgressBarWidth(
                      props.studentData,
                      props.studentTotal
                    ).toString() + "%",
                }}
              ></span>
            </div>
          </div>

          <div className="flex flex-col items-center relative w-20">
            <span className=" flex justify-center mx-4">{props.statName}</span>
            <span className="w-full inline-block flex  justify-center space-x-4 text-lg font-semibold">
              <div className="text-gray-400 font-semibold">
                {props.studentData}
              </div>
              <div className="text-green-400 font-semibold">
                {props.opponentData}
              </div>
            </span>
          </div>

          <div className="w-1/2 relative flex flex-col">
            <div className="relative">
              <span className="bg-gray-300 rounded h-10 inline-block w-full absolute top-0 left-0"></span>
              <span
                className={`${
                  studentProgress > opponentProgress
                    ? "bg-secondary-teal"
                    : "bg-primary-violet"
                } rounded h-10 inline-block absolute top-0 left-0`}
                style={{
                  width:
                    calculateProgressBarWidth(
                      props.opponentData,
                      props.opponentTotal
                    ).toString() + "%",
                }}
              ></span>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
export default SkillStudentStats;

import { MinusCircleIcon, PlusCircleIcon } from "@heroicons/react/solid";
import Moment from "moment";
import { Fragment, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import {
  duplicateAssignment,
  getAssignmentAuthors,
  getAssignmentById,
  getAssignments,
  getSharedAssignmentSubjects,
} from "../../../../api/teacher/assignment";
import {
  CaretDownSolidIcon,
  CaretUpSolidIcon,
  GroupAssignmentIcon,
} from "../../../../assets/icons";
import PagingResponse from "../../../../model/common/pagingResponse";
import { IAssignmentActivity } from "../../../../model/interface/assignmentActivity";
import { IAssignmentSearch } from "../../../../model/interface/assignmentSearch";
import { ISelectElement } from "../../../../model/interface/selectElement";
import MyAssignmentDetails from "../../../../model/teacher/assignment";
import { getActivityName } from "../../../../utils/assignmentHelper";
import RouteConstant from "../../../../utils/constant/routeConstant";
import { OrderByArray } from "../../../../utils/helper";
import {
  DEFAULT_PAGE_NUMBER,
  DEFAULT_PAGE_SIZE,
} from "../../../../utils/pagingConstant";
import ConfirmationDialog from "../../../shared/confirmationDialog";
import Loader from "../../../shared/loader";
import MessagePopup from "../../../shared/messagePopup";
import PageSize from "../../../shared/pagination/pageSize";
import Pagination from "../../../shared/pagination/pagination";
import AssignmentSetting from "../shared/assignmentSetting";
import { getEndDate, getStartDate } from "../../../../utils/dateHelper";
import ArrowUpIcon from "@heroicons/react/outline/ArrowUpIcon";
import ArrowDownIcon from "@heroicons/react/outline/ArrowDownIcon";
import { ISchoolYear } from "../../../../model/teacher/schoolYear";
import { SchoolYearDropDown } from "../../schoolYearDropdown";
import {toast} from "react-toastify";
import Constant from "../../../../utils/constant/constant";

interface passedProps {
  userId: number;
  roleId: number;
  schoolYearData?: Array<ISchoolYear>;
}

function SchoolAssignment(props: passedProps) {
  const history = useHistory();
  const [pageNumber, setPageNumber] = useState(DEFAULT_PAGE_NUMBER);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [resetPageNumber, setResetPageNumber] = useState<boolean>(false);
  const defaultValue = { label: "Select", value: "0" };
  const { userId, roleId } = props;
  const [subjects, setSubjects] = useState<Array<ISelectElement>>([]);
  const [authors, setAuthors] = useState<Array<ISelectElement>>([]);
  const [selectedAuthor, setSelectedAuthor] = useState<ISelectElement>();
  const [selectedSubject, setSelectedSubject] = useState<ISelectElement>();
  const [selectedFromDate, setFromDate] = useState<Date>();
  const [selectedToDate, setToDate] = useState<Date>();
  const [assignments, setAssignments] =
    useState<PagingResponse<MyAssignmentDetails>>();
  const [name, setName] = useState<string>("");
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [selectedAssignment, setSelectedAssignment] =
    useState<MyAssignmentDetails>();
  const [showSettingPopup, setShowSettingPopup] = useState<boolean>(false);
  const [selectedActivity, setSelectedActivity] =
    useState<IAssignmentActivity>();
  const [assignmentActivities, setAssignmentActivities] =
    useState<Array<IAssignmentActivity>>();
  const [selectedAssignmentId, setSelectedAssignmentId] = useState<number>(0);
  const [showConfirmation, setshowConfirmation] = useState<boolean>(false);
  const [duplicateAssignmentId, setDuplicateAssignmentId] = useState<number>();
  const [orderByColumn, setOrderByColumn] = useState<string>("CreateDate");
  const [sortByDesc, setSortByDesc] = useState<boolean>(true);
  const [errroMessage, setErrorMessage] = useState<string>("");
  const [errorMessagePopup, setErrorMessagePopup] = useState<boolean>(false);
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [selectedSchoolYearId, setSelectedSchoolYearId] = useState<number>();
  const [isDataRefreshed, setIsDataRefreshed] = useState<boolean>(false);
  const [schoolYearData, setSchoolYearData] = useState<Array<ISchoolYear>>();
  const [schoolYearMinDate, setSchoolYearMinDate] = useState<Date>();
  const [schoolYearMaxDate, setSchoolYearMaxDate] = useState<Date>();

  function handleAuthorChange(selectdItem: any) {
    setSelectedAuthor(selectdItem);
  }

  function handleSubjectChange(selectdItem: any) {
    setSelectedSubject(selectdItem);
  }

  function resetFilters() {
    setSelectedAuthor(defaultValue);
    setSelectedSubject(defaultValue);
    setName("");
    setFromDate(undefined);
    setToDate(undefined);
    setResetPageNumber(true);

    const assignmentSearch: IAssignmentSearch = {
      teacherId: userId,
      roleId: roleId,
      assignmentType: "SHAREDASSIGNMENT",
      orderByColumn: "CreateDate",
      pageNumber: DEFAULT_PAGE_NUMBER,
      pageSize: DEFAULT_PAGE_SIZE,
      schoolYearId: selectedSchoolYearId,
    };

    setShowLoading(true);
    getAssignments(assignmentSearch).then((r) => {
      setAssignments(r.data);
      setShowLoading(false);
    });
  }

  const handleSortBy = (column: string) => {
    setIsDataRefreshed(true);
    setSortByDesc(!sortByDesc);
    setOrderByColumn(column);
  };

  function searchAssignments(
    resetPageNumber: boolean = false,
    resetDates: boolean = false
  ) {
    let utcFromDate: Date | undefined;
    let utcToDate: Date | undefined;

    if(selectedFromDate != null)
      utcFromDate = getStartDate(selectedFromDate);
    if(selectedToDate != null)
      utcToDate = getEndDate(selectedToDate);

    if (resetPageNumber) {
      setPageNumber(DEFAULT_PAGE_NUMBER);
    }
    setShowLoading(true);
    const assignmentSearch: IAssignmentSearch = {
      teacherId: userId,
      roleId: roleId,
      authorId:
        selectedAuthor && selectedAuthor.value !== "0"
          ? parseInt(selectedAuthor.value)
          : undefined,
      subjectId:
        selectedSubject && selectedSubject.value !== "0"
          ? parseInt(selectedSubject.value)
          : undefined,
      fromDate: !resetDates ? utcFromDate : undefined,
      toDate: !resetDates ? utcToDate : undefined,
      name: name,
      assignmentType: "SHAREDASSIGNMENT",
      pageNumber: resetPageNumber ? DEFAULT_PAGE_NUMBER : pageNumber,
      pageSize: pageSize,
      orderByColumn: orderByColumn,
      sortByDesc: sortByDesc,
      schoolYearId: selectedSchoolYearId,
    };
    getAssignments(assignmentSearch).then((r) => {
      setAssignments(r.data);
      setShowLoading(false);
    });
  }

  function bindSubjects() {
    setShowLoading(true);
    getSharedAssignmentSubjects(userId, roleId).then((response) => {
      setShowLoading(false);
      const subjectsData = response.data.data;
      var subjects: Array<ISelectElement> = new Array<ISelectElement>();

      subjectsData.forEach(function (keyValuePair: any) {
        subjects.push({
          label: keyValuePair.name,
          value: keyValuePair.subjectId,
        });
      });

      setSubjects(subjects);
    });
  }

  function bindAuthors() {
    setShowLoading(true);
    getAssignmentAuthors(userId, roleId).then((response) => {
      setShowLoading(false);
      const authorsData = response.data.data;
      var authors: Array<ISelectElement> = new Array<ISelectElement>();

      authorsData.forEach(function (keyValuePair: any) {
        authors.push({ label: keyValuePair.name, value: keyValuePair.id });
      });

      setAuthors(authors);
    });
  }

  function handleSettingPopup(
    assignment: MyAssignmentDetails,
    activity?: IAssignmentActivity
  ) {
    toggleSettingPopup(true);
    setSelectedAssignment(assignment);
    setSelectedActivity(activity);
  }

  function toggleSettingPopup(popupAction: boolean) {
    setShowSettingPopup(popupAction);
  }

  function handleActivityDetails(assignmentId: number) {
    if (selectedAssignmentId === assignmentId) {
      setSelectedAssignmentId(0);
      setAssignmentActivities([]);
      return;
    }
    setShowLoading(true);
    setSelectedAssignmentId(assignmentId);
    getAssignmentById(assignmentId).then((response) => {
      setShowLoading(false);
      var activiyDetails: Array<IAssignmentActivity> = response.data.activities;
      var activities: Array<IAssignmentActivity> = [];
      const hasCombineActivity = activiyDetails.some(
        (r) => r.isCombinedActivity
      );
      if (hasCombineActivity) {
        const combineActivity = activiyDetails.filter(
          (r) => r.isCombinedActivity
        )[0];
        const otherActivities = activiyDetails.filter(
          (r) => !r.isCombinedActivity
        );
        activities = [...otherActivities, combineActivity];
        setAssignmentActivities(OrderByArray(activities, "sequenceNumber"));
      } else {
        setAssignmentActivities(OrderByArray(activiyDetails, "sequenceNumber"));
      }
    });
  }

  const onPageChange = (pageNo) => {
    setIsDataRefreshed(true);
    setPageNumber(pageNo);
  };

  const onPageSizeChange = (pageSize) => {
    setIsDataRefreshed(true);
    setPageNumber(DEFAULT_PAGE_NUMBER);
    setPageSize(pageSize);
    setResetPageNumber(true);
  };

  function handleFromDateChange(selectedItem: any) {
    if (selectedToDate && selectedItem > selectedToDate) {
      return false;
    }
    setFromDate(selectedItem);
  }

  function handleToDateChange(selectedItem: any) {
    if (selectedFromDate && selectedItem < selectedFromDate) {
      return false;
    }
    setToDate(selectedItem);
  }

  function handleOkConfirmation() {
    if (duplicateAssignmentId) {
      handleDuplicateAssignments(duplicateAssignmentId);
    }
    setshowConfirmation(false);
  }

  function handleConfirmation(assignmentId: number) {
    setshowConfirmation(true);
    setDuplicateAssignmentId(assignmentId);
  }

  const toggleErrorMessagePopup = () => {
    setErrorMessagePopup(false);
  };

  function handleDuplicateAssignments(assignmentId: number) {
    setShowLoading(true);
    duplicateAssignment(userId, assignmentId)
        .then((response) => {
          setShowLoading(false);
          if (response.data.isSuccess) {
            const newAssignmentId: number = response.data.data;
            history.push({
              pathname: RouteConstant.EDITASSIGNMENT.replace(
                  ":id",
                  newAssignmentId.toString()
              ),
              state: {
                editMode: "Edit",
              },
            });
            return;
          }

          toast.error("Assignment duplication not successful");
          switch (response.data.code) {
            case 1: //Assignment contains activity with a Guid
              setErrorMessagePopup(true);
              setErrorMessage(Constant.DialogMessages.AssignmentDuplicationFailCode1);
              break;
            default:
              setErrorMessagePopup(true);
              setErrorMessage(response.data.message); //Message in this case coming from the server should not reveal sensative data
              break;
          }
        })
        .catch(reason => {
          setShowLoading(false);
          toast.error("An error has occurred while duplicating the assignment");
        });
  }

  function handleCancelConfirmation() {
    setshowConfirmation(false);
    setDuplicateAssignmentId(undefined);
  }

  useEffect(() => {
    if (isDataRefreshed) searchAssignments();
  }, [pageSize, pageNumber, sortByDesc]);

  useEffect(() => {
    bindSubjects();
    bindAuthors();
  }, []);

  function handleSchoolYearLoaded(currentSchoolYearId?: number) {
    handleSchoolYearChange(currentSchoolYearId);
  }

  function handleSchoolYearChange(schoolYearId?: number) {
    setIsDataRefreshed(false);
    setSelectedSchoolYearId(schoolYearId);
  }

  function handleSchoolYearData(schoolYearData: Array<ISchoolYear>) {
    setSchoolYearData(schoolYearData);
  }

  function handleDateFilter(schoolYearId?: number) {
    let selectedSchoolYear = schoolYearData?.filter(
      (s) => s.schoolYearId == schoolYearId
    )[0];
    let minDate = selectedSchoolYear
      ? Moment(
          selectedSchoolYear.startDate.toString().split("T")[0],
          "YYYY-MM-DD"
        ).toDate()
      : new Date();

    let maxDate = selectedSchoolYear
      ? Moment(
          selectedSchoolYear.startDate.toString().split("T")[0],
          "YYYY-MM-DD"
        )
          .add(1, "year")
          .subtract(1, "day")
          .toDate()
      : new Date();

    setSchoolYearMinDate(getStartDate(minDate));
    setSchoolYearMaxDate(getEndDate(maxDate));
  }

  useEffect(() => {
    if (selectedSchoolYearId) {
      handleDateFilter(selectedSchoolYearId);
      setFromDate(undefined);
      setToDate(undefined);
      searchAssignments(true, true);
    }
  }, [selectedSchoolYearId]);

  return (
    <Fragment>
      <ConfirmationDialog
        open={showConfirmation}
        confirmationTitle={"Please confirm"}
        confirmationMessage={
          "Are you sure you want to assign this assignment to your students?"
        }
        onCancelClick={handleCancelConfirmation}
        onOkClick={handleOkConfirmation}
      ></ConfirmationDialog>
      {showLoading && <Loader></Loader>}
      {showSettingPopup && selectedAssignment && (
        <AssignmentSetting
          assignment={selectedAssignment}
          activity={selectedActivity}
          toggleSettingPopup={toggleSettingPopup}
        />
      )}
      {errorMessagePopup && (
        <MessagePopup
          message={errroMessage}
          togglePopup={toggleErrorMessagePopup}
        />
      )}
      <div className="min-w-0 block lg:col-span-9 xl:col-span-10 w-full">
        <div className="bg-gray-100  w-full mb-5 rounded-lg p-5 h-full">
          <div className="flex flex-col mb-5">
            <div className="flex w-full justify-between relative items-center">
              <div
                className="flex items-center  cursor-pointer"
                onClick={() => {
                  if (!showFilter) {
                    setShowFilter(true);
                  } else {
                    setShowFilter(false);
                  }
                }}
              >
                <span className="bg-black w-10 h-10 rounded-full inline-block flex items-center justify-center cursor-pointer">
                  {!showFilter && (
                    <ArrowDownIcon className="w-6 text-white opacity-90" />
                  )}
                  {showFilter && (
                    <ArrowUpIcon className="w-6 text-white opacity-90" />
                  )}
                </span>
                <span className="mx-2 text-gray-700">
                  {!showFilter
                    ? "Expand Filter Options"
                    : "Collapse Filter Options"}
                </span>
              </div>
              <div
                className="flex items-center gap-x-3 bg-gray-500 pl-4 pr-2 py-1 rounded-sm"
                title="Assignments have been associated with a school year. To reuse an assignment each year, duplicate it from the previous school year."
              >
                <label className="block text-sm font-semibold text-white whitespace-nowrap">
                  School Year
                </label>
                <SchoolYearDropDown
                  className="w-24"
                  schoolYearData={props.schoolYearData}
                  handleSchoolYearChange={handleSchoolYearChange}
                  handleSchoolYearLoaded={handleSchoolYearLoaded}
                  handleSchoolYearData={handleSchoolYearData}
                ></SchoolYearDropDown>
              </div>
            </div>
            <div
              className={`${
                showFilter
                  ? "max-h-[1000px] opacity-100 mt-5 pb-2"
                  : "max-h-0 opacity-0 -mt-5 pb-0 pointer-events-none"
              } w-full bg-white transform transition-all ease-in-out duration-300 relative z-0 p-5 shadow `}
            >
              <div className="grid grid-cols-2 sm:grid-cols-8 lg:grid-cols-8 xl:grid-cols-8 gap-6 pb-4">
                <div className="col-span-8 sm:col-span-4 xl:col-span-2">
                  <label className="block text-sm text-gray-500">Author</label>
                  <Select
                    className="mt-1"
                    value={selectedAuthor}
                    options={authors}
                    onChange={handleAuthorChange}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: "#008fbb",
                      },
                    })}
                  />
                </div>
                <div className="col-span-8 sm:col-span-4 xl:col-span-2">
                  <label className="block text-sm text-gray-500">Subject</label>
                  <Select
                    className="mt-1"
                    value={selectedSubject}
                    options={subjects}
                    onChange={handleSubjectChange}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: "#008fbb",
                      },
                    })}
                  />
                </div>
                <div className="col-span-8 sm:col-span-4 xl:col-span-2">
                  <label className="block text-sm text-gray-500"> Name</label>
                  <input
                    type="text"
                    name="Assignment-name"
                    id="Assignment-name"
                    autoComplete="off"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="mt-1 shadow-sm focus:ring-primary-violet focus:border-primary-violet h-10 block w-full text-lg border border-gray-200 text-gray-500 px-3 rounded-sm"
                  />
                </div>
                <div className="col-span-8 sm:col-span-4 xl:col-span-4 xxxl:col-span-2 grid grid-cols-2 gap-6">
                  <div>
                    <label className="block text-sm text-gray-500" htmlFor="">
                      From{" "}
                    </label>
                    <DatePicker
                      wrapperClassName="w-full"
                      className="mt-1 text-gray-500 react-datepicker__input-container py-1.5 px-3 block w-full shadow-sm focus:ring-primary-violet focus:border-primary-violet border border-gray-300 rounded-md "
                      selected={selectedFromDate}
                      onChange={(date) => handleFromDateChange(date)}
                      minDate={schoolYearMinDate}
                      maxDate={schoolYearMaxDate}
                    />
                  </div>
                  <div>
                    <label className="block text-sm text-gray-500" htmlFor="">
                      To{" "}
                    </label>
                    <DatePicker
                      wrapperClassName="w-full"
                      className="mt-1 text-gray-500 react-datepicker__input-container py-1.5 px-3 block w-full shadow-sm focus:ring-primary-violet focus:border-primary-violet border border-gray-300 rounded-md "
                      selected={selectedToDate}
                      onChange={(date) => handleToDateChange(date)}
                      minDate={schoolYearMinDate}
                      maxDate={schoolYearMaxDate}
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-col md:flex-row gap-4 items-end justify-end pb-5">
                <div className="grid grid-cols-2 gap-4">
                  <button
                    onClick={() => resetFilters()}
                    className="bg-transparent border my-auto border-primary-violet shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-primary-violet hover:text-white hover:bg-light-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3 transition ease-in-out duration-500"
                  >
                    Reset
                  </button>

                  <button
                    onClick={() => searchAssignments(true)}
                    className="bg-primary-violet border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-dark-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3 "
                  >
                    Filter
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-white py-5 px-5 shadow">
            <div className="mb-4 pb-4 border-b-2 overflow-auto">
              <table className="table w-full ">
                <thead className="mb-5">
                  <tr className="bg-light-violet  text-white py-3 px-3 overflow-hidden">
                    <th className="text-left p-3 text-sm font-normal w-4"></th>
                    <th className="text-center  p-3 text-sm font-normal">
                      <span className="flex whitespace-nowrap">
                        Create Date
                        <span className="cursor-pointer">
                          {sortByDesc && orderByColumn === "CreateDate" ? (
                            <CaretUpSolidIcon
                              onClick={() => handleSortBy("CreateDate")}
                              className="w-5 h-5"
                            ></CaretUpSolidIcon>
                          ) : (
                            <CaretDownSolidIcon
                              onClick={() => handleSortBy("CreateDate")}
                              className="w-5 h-5"
                            ></CaretDownSolidIcon>
                          )}
                        </span>
                      </span>
                    </th>
                    <th className="text-center p-3 text-sm font-normal"></th>
                    <th className="text-left  p-3 text-sm font-normal">
                      <span className="flex">
                        Name
                        <span className="cursor-pointer">
                          {sortByDesc && orderByColumn === "name" ? (
                            <CaretUpSolidIcon
                              onClick={() => handleSortBy("name")}
                              className="w-5 h-5"
                            ></CaretUpSolidIcon>
                          ) : (
                            <CaretDownSolidIcon
                              onClick={() => handleSortBy("name")}
                              className="w-5 h-5"
                            ></CaretDownSolidIcon>
                          )}
                        </span>
                      </span>
                    </th>
                    <th className="text-center p-3 text-sm font-normal">
                      <span className="flex">
                        Author
                        <span className="cursor-pointer">
                          {sortByDesc && orderByColumn === "Author" ? (
                            <CaretUpSolidIcon
                              onClick={() => handleSortBy("Author")}
                              className="w-5 h-5"
                            ></CaretUpSolidIcon>
                          ) : (
                            <CaretDownSolidIcon
                              onClick={() => handleSortBy("Author")}
                              className="w-5 h-5"
                            ></CaretDownSolidIcon>
                          )}
                        </span>
                      </span>
                    </th>
                    <th className="text-left  p-3 text-sm font-normal"></th>
                  </tr>
                </thead>
                <tbody>
                  {assignments &&
                    assignments.data?.length > 0 &&
                    assignments.data?.map((assignment, index) => {
                      return (
                        <Fragment key={index}>
                          <tr
                            className={`hover:bg-light-violet/20 transition ease-in-out duration-300 ${
                              index % 2 === 0
                                ? "bg-white"
                                : "bg-light-violet/10"
                            }`}
                          >
                            <td className=" mt-5 p-3 text-center">
                              {assignment.hasActicity &&
                                assignment.activityCount > 1 &&
                                selectedAssignmentId !==
                                  parseInt(assignment.assignmentId) && (
                                  <span title="Expand row">
                                    <PlusCircleIcon
                                      className="w-5 text-gray-500 hover:text-gray-700 cursor-pointer"
                                      onClick={() =>
                                        handleActivityDetails(
                                          parseInt(assignment.assignmentId)
                                        )
                                      }
                                    />
                                  </span>
                                )}
                              {assignment.hasActicity &&
                                assignment.activityCount > 1 &&
                                selectedAssignmentId ===
                                  parseInt(assignment.assignmentId) && (
                                  <span title="Collapse row">
                                    <MinusCircleIcon
                                      className="w-5 text-gray-500 hover:text-gray-700 cursor-pointer"
                                      onClick={() =>
                                        handleActivityDetails(
                                          parseInt(assignment.assignmentId)
                                        )
                                      }
                                    />
                                  </span>
                                )}
                            </td>
                            <td className="text-sm text-center mt-5  p-3">
                              {Moment(assignment.createdDate).format(
                                "MM-DD-yyyy"
                              )}
                            </td>

                            <td className="text-sm mt-5  p-3 text-center">
                              {assignment.hasActicity &&
                                assignment.activityCount > 1 && (
                                  <GroupAssignmentIcon
                                    title="Group assignment"
                                    onClick={() =>
                                      handleActivityDetails(
                                        parseInt(assignment.assignmentId)
                                      )
                                    }
                                  />
                                )}
                            </td>
                            <td className="text-sm mt-5  p-3">
                              {!assignment.hasActicity && (
                                <a
                                  href="#!"
                                  className="text-blue-500 underline hover:no-underline"
                                  onClick={() => handleSettingPopup(assignment)}
                                >
                                  {assignment.name}
                                </a>
                              )}
                              {assignment.hasActicity &&
                                assignment.activityCount > 1 &&
                                assignment.name}
                              {assignment.hasActicity &&
                                assignment.assignmentActivity &&
                                assignment.activityCount === 1 && (
                                  <div>
                                    <div>{assignment.name}</div>
                                    <div className="flex items-center">
                                      <a
                                        href="#!"
                                        className="text-blue-500 underline hover:no-underline"
                                        onClick={() =>
                                          handleSettingPopup(
                                            assignment,
                                            assignment.assignmentActivity
                                          )
                                        }
                                      >
                                        {getActivityName(
                                          assignment.assignmentActivity
                                        )}
                                      </a>
                                    </div>
                                  </div>
                                )}
                            </td>
                            <td className="text-sm mt-5 whitespace-nowrap p-3 ">
                              {assignment.author}
                            </td>
                            <td className="text-sm mt-5 text-right p-3">
                              <button
                                onClick={() =>
                                  handleConfirmation(
                                    parseInt(assignment.assignmentId)
                                  )
                                }
                                className="bg-transparent whitespace-nowrap border border-primary-violet shadow-sm py-2 px-4 md:px-2 inline-flex justify-center text-sm font-medium text-primary-violet hover:text-white hover:bg-light-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3 transition ease-in-out duration-500 "
                              >
                                Assign to My Students
                              </button>
                            </td>
                          </tr>
                          {assignmentActivities &&
                            assignmentActivities.length > 0 &&
                            assignmentActivities[0].assignmentId ===
                              parseInt(assignment.assignmentId) &&
                            assignmentActivities.map(
                              (assignmentActivity, index) => {
                                return (
                                  <tr
                                    key={index}
                                    className={`hover:bg-light-violet/20 transition ease-in-out duration-300 ${
                                      index % 2 === 0
                                        ? "bg-white"
                                        : "bg-light-violet/10"
                                    }`}
                                  >
                                    <td className=" mt-5 p-3"></td>
                                    <td className=" mt-5 p-3"></td>
                                    <td className="text-sm mt-5  p-3 "></td>
                                    <td className="text-sm mt-5  p-3">
                                      <div>
                                        <div className="flex items-center">
                                          <a
                                            href="#!"
                                            className="text-blue-500 underline hover:no-underline"
                                            onClick={() =>
                                              handleSettingPopup(
                                                assignment,
                                                assignmentActivity
                                              )
                                            }
                                          >
                                            {getActivityName(
                                              assignmentActivity
                                            )}
                                          </a>
                                        </div>
                                      </div>
                                    </td>
                                    <td className="text-sm mt-5  p-3 ">
                                      {assignment.author}
                                    </td>
                                    <td className=" mt-5 p-3"></td>
                                  </tr>
                                );
                              }
                            )}
                        </Fragment>
                      );
                    })}
                  {assignments?.data.length === 0 && (
                    <tr>
                      <td colSpan={6}>
                        <div className="text-gray-700 w-full mt-2">
                          No records found
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
              {assignments?.totalRecords !== undefined &&
                assignments?.totalRecords !== 0 && (
                  <Pagination
                    totalRecords={assignments?.totalRecords}
                    pageSize={pageSize}
                    onPageChange={onPageChange}
                    reset={resetPageNumber}
                    pageNumber={pageNumber}
                  />
                )}
              {assignments?.totalRecords !== 0 && (
                <PageSize
                  pageSize={pageSize}
                  onPageSizeChange={onPageSizeChange}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default SchoolAssignment;

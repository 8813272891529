import APIConstant from "../../utils/constant/apiConstant";
import { AxiosHelper } from "../../environment/axiosConfig";

export const postPassage = (passageData) => {
  return AxiosHelper.axiosInstance().post(
    APIConstant.APP_TEACHERPASSAGE,
    passageData,
    {
      headers: {
        "x-requested-with": "XMLHttpRequest",
      },
    }
  );
};
export const updatePassage = (updatedPassageData) => {
  return AxiosHelper.axiosInstance().put(
    APIConstant.APP_TEACHERPASSAGE,
    updatedPassageData,
    {
      headers: {
        "x-requested-with": "XMLHttpRequest",
      },
    }
  );
};
export const deletePassage = (passageId, userId) => {
  return AxiosHelper.axiosInstance().delete(APIConstant.APP_TEACHERPASSAGE, {
    headers: {
      "x-requested-with": "XMLHttpRequest",
    },
    params: {
      id: passageId,
      userId: userId,
    },
  });
};
export const getAllPassages = (passageData) => {
  return AxiosHelper.axiosInstance().get(APIConstant.APP_TEACHERPASSAGE, {
    params: {
      Title: passageData.filterTitle,
      OrderByColumn: passageData.orderByColumn,
      SortByDesc: passageData.sortByDesc,
      PageSize: passageData.pageSize,
      PageNumber: passageData.pageNumber,
      IsApproved: passageData.isApproved,
      IsActive: passageData.isActive,
      passageId: passageData.passageId,
    },
  });
};
export const getPassageById = (id) => {
  return AxiosHelper.axiosInstance().get(
    `${APIConstant.APP_TEACHERPASSAGEBYID}/${id}`
  );
};

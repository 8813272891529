import { Fragment } from "react";
import { CloseIcon } from "../../../../../assets/icons";
import { useTranslation } from "react-i18next";

const theme = {
  ELEMENTARY: {
    header: "bg-[#34D9A5]",
    headerTextColor: "text-lg text-gray-700"
  },
  MIDDLESCHOOL: {
    header: "bg-site-teal",
    headerTextColor: "text-lg font-medium text-white"
  },
}

interface SubjectTestPopupProps {
  themeName: "ELEMENTARY" | "MIDDLESCHOOL";
  children: (string | JSX.Element | JSX.Element[]);
  onClose(): void;
}

const StudyPlanSubjectTestPopup = (props: SubjectTestPopupProps) => {
  const { themeName, children, onClose } = props;
  const { t } = useTranslation();

  return (
    <Fragment>
      <div className="fixed inset-0 z-50 flex items-center bg-black bg-opacity-50 sm:items-center sm:justify-center">
        <div
          className="  w-full flex flex-col justify-between overflow-hidden h-auto max-h-80percent bg-white  dark:bg-gray-800 rounded-lg sm:m-4 sm:max-w-xl"
          role="dialog"
        >
          <header>
            <div className={`px-6 py-4 ${theme[themeName]?.header}`}>
              <div className="flex items-start justify-between space-x-3">
                <div className={` ${theme[themeName]?.headerTextColor}`}>
                  {t("StudyPlan.SubjectTests")}
                </div>
                <div>
                  <button
                    type="button"
                    className="inline-flex items-center justify-center w-6 h-6 text-gray-400 transition-colors duration-150 rounded dark:hover:text-gray-200 hover: hover:text-gray-700"
                    onClick={onClose}
                  >
                    <CloseIcon className="text-white"/>
                  </button>
                </div>
              </div>
            </div>
          </header>
          <div className="px-6 py-6 pb-12 overflow-auto min-h-4rem max-h-80">
            {children}
          </div>
          <div className="pt-8">
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default StudyPlanSubjectTestPopup;
import { AxiosResponse } from "axios";
import { AxiosHelper } from "../../environment/axiosConfig";
import APIConstant from "../../utils/constant/apiConstant";
import { IArcadeGames } from "../../model/interface/arcadeGame";
import {IResult} from "../../model/interface/IResult";

export function getArcadeGames(): Promise<AxiosResponse<Array<IArcadeGames>>> {
  return AxiosHelper.axiosInstance().get<Array<IArcadeGames>>(
    APIConstant.Student_API.ArcadeGames,
    {
      params: {},
    }
  );
}

export function getTotalTokens(): Promise<AxiosResponse<number>> {
  return AxiosHelper.axiosInstance().get<number>(
    APIConstant.Student_API.TotalTokens,
    {
      params: {},
    }
  );
}

export function addTokenTransaction(tokenTransactionTypeId: number,  
  costInTokens: number, 
  arcadeGameId?: number,
  gameScore?: number
  ): Promise<AxiosResponse<IResult<number>>> {
  return AxiosHelper.axiosInstance().post<IResult<number>>(
    APIConstant.Student_API.AddTokenTransaction,
    {
      tokenTransactionTypeId: tokenTransactionTypeId,
      costInTokens: costInTokens,     
      arcadeGameId: arcadeGameId,
      gameScore: gameScore
    }
  );
}

export function updateArcadeGameSession(studentTokenTransactionId: number, 
  arcadeGameId?: number,
  gameScore?: number
  ): Promise<AxiosResponse<number>> {
  return AxiosHelper.axiosInstance().put<number>(
    APIConstant.Student_API.UpdateArcadeGameSession,
    {
      studentTokenTransactionId: studentTokenTransactionId,     
      arcadeGameId: arcadeGameId,
      gameScore: gameScore
    }
  );
}
import { MinusCircleIcon, PlusCircleIcon } from "@heroicons/react/solid";
import { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { addStudents } from "../../../../api/teacher/assignment";
import { getAllStudents } from "../../../../api/teacher/teacher";
import {
  CanvasNewIcon,
  ClassLinkIcon,
  CleverIcon,
  GoogleClassroomIcon,
} from "../../../../assets/icons";
import { IAssignment } from "../../../../model/interface/assignment";
import { IClassStudent } from "../../../../model/interface/classStudent";
import { ITreeItem } from "../../../../model/interface/treeItem";
import Profile from "../../../../model/users/profile";
import { UserContextState } from "../../../../redux/actions/userContextAction";
import Constant from "../../../../utils/constant/constant";
import InfoMessage from "../../../shared/infoMessage";
import Loader from "../../../shared/loader";
import MessagePopup from "../../../shared/messagePopup";
import Select from "react-select";
import Toggle from "../../../shared/toggle";
import { getStudentSettingValue } from "../../../../utils/assignmentHelper";
interface ClassProps {
  userId: number;
  roleId: number;
  assignment: IAssignment;
  userContext?: UserContextState;
  profile?: Profile;
  updateAssignment: (
    updatedAsignment: IAssignment,
    moveToNextTab?: boolean
  ) => void;
}

function Class(props: ClassProps) {
  const { userId, roleId, assignment, updateAssignment } = props;
  const [studentsTree, setStudentsTree] = useState<Array<ITreeItem>>([]);
  const [expandedClasses, setExpandedClasses] = useState<Array<number>>([]);
  const [additionalClasses, setAdditionalClasses] = useState<Array<number>>([]);
  const [selectedStudents, setSelectedStudents] = useState<
    Array<IClassStudent>
  >([]);
  const [selectedClasses, setSelectedClasses] = useState<Array<number>>([]);
  const [toggleWithoutAssign, setToggleWithoutAssign] =
    useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [toggleAdditionalClass, setToggleAdditionalClass] = useState(false);
  const [errorMessagePopup, setErrorMessagePopup] = useState<boolean>(false);
  const [isCRAssignment, setIsCRAssignment] = useState<boolean>(false);

  const AllAssignmentLanguageOptions = [
    { label: "Toggle: English Default", value: 1 },
    { label: "Toggle: Spanish Default", value: 2 },
    { label: "English Only", value: 3 },
  ];

  function handleExpand(classId: number) {
    const existingClasses: Array<number> = expandedClasses;
    let updatedClasses: Array<number> = [];
    if (existingClasses.includes(classId)) {
      updatedClasses = existingClasses.filter((r) => r !== classId);
    } else {
      updatedClasses = [...existingClasses, classId];
    }
    setExpandedClasses(updatedClasses);
  }

  function isAssignmentInProgress(studentId: number) {
    return assignment.students?.some(
      (r) => r.studentId === studentId && r.isStarted
    ) && assignment.assignmentId > 0
      ? true
      : false;
  }

  function handleCheckStudent(
    studentId: number,
    classId: number,
    languageSettingId: number | undefined
  ) {
    if (isAssignmentInProgress(studentId)) {
      return false;
    }

    const existingStudents: Array<IClassStudent> = selectedStudents;
    const student: IClassStudent = {
      studentId: studentId,
      classId: classId,
      languageSettingId: languageSettingId,
    };
    let updatedStudents: Array<IClassStudent> = [];
    if (
      existingStudents.some(
        (r) => r.studentId === studentId && r.classId === classId
      )
    ) {
      updatedStudents = existingStudents.filter(
        (r) => (r.studentId === studentId && r.classId === classId) === false
      );
    } else {
      updatedStudents = [...existingStudents, student];
    }

    setSelectedStudents(updatedStudents);
    setToggleWithoutAssign(false);

    let updatedClasses: Array<number> = [];
    const classStudentsLength = studentsTree.filter((r) => r.key === classId)[0]
      .children?.length;
    if (
      classStudentsLength ===
      updatedStudents.filter((r) => r.classId === classId).length
    ) {
      updatedClasses = [...selectedClasses, classId];
    } else {
      updatedClasses = selectedClasses.filter((r) => r !== classId);
    }

    setSelectedClasses(updatedClasses);
  }

  function handleCheckStudentEliminateAnswer(
    studentId: number,
    classId: number
  ) {
    let existingStudents: Array<IClassStudent> = selectedStudents;
    let updatedStudents: Array<IClassStudent> = [];
    if (
      existingStudents.some(
        (r) => r.studentId === studentId && r.classId === classId
      )
    ) {
      var existingStudent = existingStudents.find(
        (r) => r.studentId === studentId && r.classId === classId
      );
      const student: IClassStudent = {
        ...existingStudent,
        studentId: studentId,
        classId: classId,
        eliminateAnswerForSingleMCQ:
          !existingStudent?.eliminateAnswerForSingleMCQ,
      };
      updatedStudents = existingStudents.filter(
        (r) => (r.studentId === studentId && r.classId === classId) === false
      );
      updatedStudents = [...updatedStudents, student];
      setSelectedStudents(updatedStudents);
    }
  }

  function handleToggleTTS(
    studentId: number,
    classId: number,
    studentItem: ITreeItem
  ) {
    let existingStudents: Array<IClassStudent> = selectedStudents;
    let updatedStudents: Array<IClassStudent> = [];
    if (
      existingStudents.some(
        (r) => r.studentId === studentId && r.classId === classId
      )
    ) {
      var existingStudent = existingStudents.find(
        (r) => r.studentId === studentId && r.classId === classId
      );

      const student: IClassStudent = {
        ...existingStudent,
        studentId: studentId,
        classId: classId,
        isTTSActive: !getStudentSettingValue(
          classId,
          studentId,
          studentItem,
          "TTS",
          selectedStudents
        ),
      };

      updatedStudents = existingStudents.filter(
        (r) => (r.studentId === studentId && r.classId === classId) === false
      );
      updatedStudents = [...updatedStudents, student];
      setSelectedStudents(updatedStudents);
    }
  }

  function handleSelectClass(classId: number) {
    const existingClasses: Array<number> = selectedClasses;
    let updatedClasses: Array<number> = [];
    const classExist = existingClasses.includes(classId);
    if (classExist) {
      updatedClasses = existingClasses.filter((r) => r !== classId);
    } else {
      updatedClasses = [...existingClasses, classId];
    }

    const classStudents: Array<ITreeItem> = studentsTree.filter(
      (r) => r.key === classId
    );
    setSelectedClasses(updatedClasses);
    if (classStudents && classStudents.length > 0) {
      const students = classStudents[0].children;
      if (students && students.length > 0) {
        if (!classExist) {
          const studentIds: Array<IClassStudent> = [];
          students.map((s) => {
            if (!selectedStudents.some((r) => r.studentId === s.key)) {
              studentIds.push({ studentId: s.key, classId: classId });
            }
            return null;
          });
          setSelectedStudents([
            ...selectedStudents.filter((r) => r.classId !== classId),
            ...studentIds,
          ]);
        } else {
          const updatedStudents = selectedStudents.filter(
            (r) => r.classId !== classId
          );
          setSelectedStudents(updatedStudents);
        }
      }
    }
    setToggleWithoutAssign(false);
  }

  function getStudents() {
    setLoading(true);
    const students: Array<IClassStudent> | undefined = assignment.students;
    if (students && students.length > 0) {
      setSelectedStudents(students);
    } else {
      setToggleWithoutAssign(true);
    }
    getAllStudents(
      userId,
      roleId,
      assignment.subjectId,
      assignment.schoolYearId
    ).then((response) => {
      setLoading(false);
      setTreeData(response.data.data, students);
    });
  }

  const toggleErrorMessagePopup = () => {
    setErrorMessagePopup(false);
  };

  function setTreeData(
    classStudentdata: Array<any>,
    classStudents: Array<IClassStudent> | undefined
  ) {
    const studentsTree: Array<ITreeItem> = [];
    const allClasses: Array<number> = [];
    const additionalClassesId: Array<number> = [];
    classStudentdata.forEach((classStudent) => {
      const students: Array<ITreeItem> = [];
      classStudent.students.forEach((student) => {
        const currentStudent = classStudents?.find(
          (s) => s.studentId === student.studentId
        );
        const assignmentTTS_Setting = currentStudent
          ? currentStudent.isTTSActive
          : null;
        const globalTTS_Setting = student.isTTSActive;

        const treeItem: ITreeItem = {
          key: student.studentId,
          title: student.studentName,
          children: [],
          isClassGoogleEnabled: false,
          isStudentGoogleEnabled: student.isStudentGoogleEnabled,
          isTTSActive: assignmentTTS_Setting ?? globalTTS_Setting,
          externalRosterProviderIds: student.externalRosterProviderIds,
          userExternalProviderIds: student.userExternalProviderIds,
          languageSettingId: student.languageSettingId,
        };
        students.push(treeItem);
      });

      const treeItem: ITreeItem = {
        key: classStudent.classId,
        title: classStudent.name,
        teacherName: classStudent.teacherName,
        isAdditionalClass: classStudent.isAdditionalClass,
        externalRosterProviderIds: classStudent.externalRosterProviderIds,
        userExternalProviderIds: classStudent.userExternalProviderIds,
        isClassGoogleEnabled: classStudent.isClassGoogleEnabled,
        isStudentGoogleEnabled: false,
        languageSettingId: classStudent.languageSettingId,
        children: students,
      };

      studentsTree.push(treeItem);
      if (!classStudent.isAdditionalClass) {
        allClasses.push(classStudent.classId);
      } else {
        additionalClassesId.push(classStudent.classId);
      }
    });

    setStudentsTree(studentsTree);
    setAdditionalClasses(additionalClassesId);
    if (classStudents) {
      setExisitngClasses(studentsTree, classStudents);
    }
  }

  function toggleExpandAll() {
    const toggleValue = !toggleAdditionalClass;
    setToggleAdditionalClass(toggleValue);
    let updatedClasses: Array<number> = [];
    if (toggleValue) {
      updatedClasses = [...expandedClasses, ...additionalClasses];
    } else {
      updatedClasses = expandedClasses.filter(
        (r) => !additionalClasses.includes(r)
      );
    }
    setExpandedClasses(updatedClasses);
  }
  function handleAssignmentLanguageChange(
    selectedValue,
    studentId: number,
    classId: number
  ) {
    if (!selectedValue) {
      selectedValue = { label: "Select Assignment Language", value: 0 };
    }
    let existingStudents: Array<IClassStudent> = selectedStudents;
    let updatedStudents: Array<IClassStudent> = [];
    if (
      existingStudents.some(
        (r) => r.studentId === studentId && r.classId === classId
      )
    ) {
      var existingStudent = existingStudents.find(
        (r) => r.studentId === studentId && r.classId === classId
      );
      const student: IClassStudent = {
        ...existingStudent,
        studentId: studentId,
        classId: classId,
        isTTSActive: existingStudent?.isTTSActive,
        languageSettingId: selectedValue.value,
      };
      updatedStudents = existingStudents.filter(
        (r) => (r.studentId === studentId && r.classId === classId) === false
      );
      updatedStudents = [...updatedStudents, student];
      setSelectedStudents(updatedStudents);
    }
  }

  function setExisitngClasses(
    studentsTrees: Array<ITreeItem>,
    students: Array<IClassStudent>
  ) {
    let existingClasses: Array<number> = [];
    studentsTrees.map((studentsTree) => {
      if (
        studentsTree &&
        studentsTree.children &&
        studentsTree.children.length > 0
      ) {
        const classStudents = studentsTree.children.length;
        const selectedStudents = students.filter(
          (r) => r.classId === studentsTree.key
        ).length;
        if (classStudents === selectedStudents) {
          existingClasses.push(studentsTree.key);
        }
      }
      return null;
    });

    setSelectedClasses(existingClasses);
  }

  function handleSaveStudents() {
    if (selectedStudents.length === 0 && !toggleWithoutAssign) {
      toast.error("Please select students");
      return false;
    }

    assignment.students = selectedStudents;
    assignment.isWithoutClass = toggleWithoutAssign;
    updateAssignment(assignment, true);
    if (selectedStudents.length > 0) {
      addStudents(
        userId,
        [assignment.assignmentId],
        selectedStudents,
        props?.profile?.googleUserId!,
        true
      )
        .then(() => {
          toast.success("Assignment assigned to students.");
        })
        .catch((err) => {
          setErrorMessagePopup(true);
        });
    }
  }

  function handleTogggleWithoutClass() {
    const value: boolean = !toggleWithoutAssign;
    setToggleWithoutAssign(value);
    if (value) {
      setSelectedStudents([]);
      setSelectedClasses([]);
    }
  }

  useEffect(() => {
    getStudents();
    if (
      assignment?.activities?.length === 1 &&
      assignment.activities[0].activityType ===
        Constant.AssignmentActivityType.CONSTRUCTEDRESPONSE
    ) {
      setIsCRAssignment(true);
    }
  }, []);

  const getExternalRosterIcon = (extId) => {
    if (extId === Constant.ExternalRosterProviders.Clever) {
      return <CleverIcon className="w-5 h-5" />;
    } else if (extId === Constant.ExternalRosterProviders.ClassLink) {
      return <ClassLinkIcon className="w-5 h-5" />;
    } else if (extId === Constant.ExternalRosterProviders.Google) {
      return <GoogleClassroomIcon className="w-5 h-5" />;
    } else if (extId === Constant.ExternalRosterProviders.Canvas) {
      return <CanvasNewIcon className="w-5 h-5" />;
    }
  };

  return (
    <Fragment>
      <div className="my-4 relative px-4 sm:px-6  lg:px-8 ">
        {loading && <Loader />}
        <InfoMessage message="Select one or more classes or individual students" />
        {errorMessagePopup && (
          <MessagePopup
            message={Constant.UserErrorMessage.AssignmentCreateError}
            togglePopup={toggleErrorMessagePopup}
          />
        )}
        <div className="lg:bg-gray-100  w-full mb-5 rounded-lg lg:p-5 h-full">
          <div className="relative">
            <div className="flex justify-end mb-4">
              <button
                onClick={() => handleSaveStudents()}
                type="submit"
                className="hidden lg:block mb-auto lg:block bg-primary-violet border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-dark-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 "
              >
                Save and Continue
              </button>
            </div>
            <div className="bg-white lg:py-5 lg:px-5 overflow-auto">
              <div className="mb-2 inline-block xl:block">
                <div className="relative flex items-center py-4">
                  <div className="min-w-0 text-sm">
                    <p className="text-gray-500">
                      Create Assignment without assigning to a Class
                    </p>
                  </div>
                  <div className="ml-3 flex items-center h-5">
                    <input
                      type="checkbox"
                      onChange={() => handleTogggleWithoutClass()}
                      checked={toggleWithoutAssign}
                      className="focus:ring-primary-violet h-4 w-4 text-primary-violet border-gray-500"
                    />
                  </div>
                </div>
                <div className="flex w-full xl:w-full xxxl:w-3/4 border-b border-gray-200 pb-2 px-2 mb-4">
                  <div className="flex w-full items-end">
                    <div className="w-[21.6rem] xl:w-[24.6rem] 1xl:w-[24.6rem] 2xl:w-[29.6rem] flex-none">
                      My Classes{" "}
                    </div>
                    <div className="text-center w-24 1xl:w-48 flex-none">
                      Eliminate 1 Answer for Multiple Choice Questions
                    </div>

                    <div
                      className="text-center w-48 cursor-pointer flex-none"
                      title="Setting allows you to temporarily override the Text to Speech settings for the single assignment."
                    >
                      Assignment Text To Speech Settings
                    </div>
                    <div
                      className="text-center w-48 flex-none"
                      title="Setting allows you to temporarily override the language settings for the single assignment."
                    >
                      Assignment Language Settings{" "}
                    </div>
                  </div>
                </div>

                {studentsTree &&
                  studentsTree.length > 0 &&
                  studentsTree
                    .filter((r) => !r.isAdditionalClass)
                    .map((studentTree, index) => {
                      return (
                        <div
                          key={index}
                          className={`relative flex flex-col items-start px-2 w-full xl:w-full xxxl:w-3/4  ${
                            index % 2 === 0 ? "bg-light-violet/10" : "bg-white"
                          }`}
                        >
                          <div className="flex w-full">
                            <div className="flex py-2 w-[21.6rem] xl:w-[24.6rem] 1xl:w-[24.6rem] 2xl:w-[29.6rem] border-r border-primary-violet/50 flex-none">
                              <div className="flex items-center h-5">
                                {expandedClasses &&
                                expandedClasses.length > 0 &&
                                expandedClasses.some(
                                  (r) => r === studentTree.key
                                ) ? (
                                  <MinusCircleIcon
                                    className="w-5 mr-2 cursor-pointer"
                                    onClick={() =>
                                      handleExpand(studentTree.key)
                                    }
                                  />
                                ) : (
                                  <PlusCircleIcon
                                    className="w-5 mr-2 cursor-pointer"
                                    onClick={() =>
                                      handleExpand(studentTree.key)
                                    }
                                  />
                                )}
                                <input
                                  onChange={() =>
                                    handleSelectClass(studentTree.key)
                                  }
                                  checked={selectedClasses.some(
                                    (r) => r === studentTree.key
                                  )}
                                  type="checkbox"
                                  className="cursor-pointer focus:ring-primary-violet h-4 w-4 text-primary-violet border-gray-500"
                                />
                              </div>
                              <div className="ml-3 text-sm w-full">
                                <div className="flex gap-2">
                                  <label className="font-medium text-gray-700">
                                    {studentTree.title}
                                  </label>
                                  <span className="flex gap-2 justify-center items-center">
                                    {studentTree.externalRosterProviderIds &&
                                      studentTree.externalRosterProviderIds
                                        .split(",")
                                        .map((ext) =>
                                          getExternalRosterIcon(parseInt(ext))
                                        )}
                                    {studentTree.isClassGoogleEnabled &&
                                      getExternalRosterIcon(
                                        Constant.ExternalRosterProviders.Google
                                      )}
                                  </span>
                                </div>
                                <div className="flex w-full justify-between gap-x-2 lg:gap-x-0">
                                  <p className="text-gray-500 mt-1">
                                    {selectedStudents.filter(
                                      (r) => r.classId === studentTree.key
                                    ).length !==
                                      studentTree.children?.length && (
                                      <div
                                        className={`mr-1 inline-flex items-baseline px-2.5 py-0.5 rounded-md text-xs font-medium bg-${
                                          selectedStudents.filter(
                                            (r) => r.classId === studentTree.key
                                          ).length === 0
                                            ? "red"
                                            : "blue"
                                        }-100 text-${
                                          selectedStudents.filter(
                                            (r) => r.classId === studentTree.key
                                          ).length === 0
                                            ? "red"
                                            : "blue"
                                        }-800 `}
                                      >
                                        {
                                          selectedStudents.filter(
                                            (r) => r.classId === studentTree.key
                                          ).length
                                        }
                                      </div>
                                    )}
                                    {selectedStudents.filter(
                                      (r) => r.classId === studentTree.key
                                    ).length === studentTree.children?.length
                                      ? "All"
                                      : "of"}
                                    <div className="ml-1 mr-1 inline-flex items-baseline px-2.5 py-0.5 rounded-md text-xs font-medium bg-green-100 text-green-800">
                                      {studentTree.children?.length}
                                    </div>
                                    students selected
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="w-24 1xl:w-48 border-r border-primary-violet/50 flex-none"></div>

                            <div className="w-48 border-r border-primary-violet/50 flex-none"></div>

                            <div className="w-48 flex-none"></div>
                          </div>
                          {expandedClasses.some((r) => r === studentTree.key) &&
                            studentTree.children &&
                            studentTree.children.length > 0 && (
                              <div className="w-full">
                                {studentTree.children.map((student, index) => {
                                  return (
                                    <div key={index} className="flex w-full">
                                      <div className="flex w-[21.6rem] xl:w-[24.6rem] 1xl:w-[24.6rem] 2xl:w-[29.6rem] border-r border-primary-violet/50 flex-none">
                                        <div className="flex items-center h-5 ml-12 lg:ml-14">
                                          <input
                                            onChange={() =>
                                              handleCheckStudent(
                                                student.key,
                                                studentTree.key,
                                                student.languageSettingId
                                              )
                                            }
                                            checked={selectedStudents.some(
                                              (r) =>
                                                r.studentId === student.key &&
                                                r.classId === studentTree.key
                                            )}
                                            disabled={selectedStudents.some(
                                              (r) =>
                                                r.studentId === student.key &&
                                                r.classId !== studentTree.key
                                            )}
                                            type="checkbox"
                                            className={`${
                                              isAssignmentInProgress(
                                                student.key
                                              )
                                                ? "opacity-50 cursor-not-allowed "
                                                : ""
                                            } focus:ring-primary-violet h-4 w-4 text-primary-violet border-gray-500`}
                                          />
                                        </div>
                                        <div className="ml-3 text-sm">
                                          <div className="flex gap-2">
                                            <label className="font-normal text-gray-700">
                                              {student.title}
                                            </label>
                                            {student.userExternalProviderIds &&
                                              student.userExternalProviderIds
                                                .split(",")
                                                .map((ext) =>
                                                  getExternalRosterIcon(
                                                    parseInt(ext)
                                                  )
                                                )}

                                            {student.isStudentGoogleEnabled &&
                                              getExternalRosterIcon(
                                                Constant.ExternalRosterProviders
                                                  .Google
                                              )}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="text-sm w-24 1xl:w-48 border-r border-primary-violet/50 flex-none">
                                        <div className="flex gap-2 justify-center">
                                          <input
                                            onChange={(e) =>
                                              handleCheckStudentEliminateAnswer(
                                                student.key,
                                                studentTree.key
                                              )
                                            }
                                            checked={selectedStudents.some(
                                              (r) =>
                                                r.eliminateAnswerForSingleMCQ ===
                                                  true &&
                                                r.studentId === student.key &&
                                                r.classId === studentTree.key
                                            )}
                                            disabled={
                                              !selectedStudents.some(
                                                (r) =>
                                                  r.studentId === student.key &&
                                                  r.classId === studentTree.key
                                              ) || isCRAssignment
                                            }
                                            type="checkbox"
                                            className={`${
                                              isAssignmentInProgress(
                                                student.key
                                              )
                                                ? "opacity-50 cursor-not-allowed "
                                                : ""
                                            } focus:ring-primary-violet h-4 w-4 text-primary-violet border-gray-500`}
                                          />
                                        </div>
                                      </div>
                                      <div className="text-sm w-48 border-r border-primary-violet/50 flex-none">
                                        <div className="text-sm w-48 ">
                                          <div className="flex gap-2 justify-center">
                                            Off
                                            <Toggle
                                              checked={getStudentSettingValue(
                                                studentTree.key,
                                                student.key,
                                                student,
                                                "TTS",
                                                selectedStudents
                                              )}
                                              disabled={
                                                !selectedStudents.some(
                                                  (r) =>
                                                    r.studentId ===
                                                      student.key &&
                                                    r.classId ===
                                                      studentTree.key
                                                )
                                              }
                                              onChange={() => {
                                                handleToggleTTS(
                                                  student.key,
                                                  studentTree.key,
                                                  student
                                                );
                                              }}
                                            />
                                            On
                                          </div>
                                        </div>
                                      </div>
                                      <div className="text-sm w-48 flex-none">
                                        <div className="text-sm w-full pb-2">
                                          <Select
                                            className="mx-2"
                                            value={AllAssignmentLanguageOptions.find(
                                              (r) =>
                                                getStudentSettingValue(
                                                  studentTree.key,
                                                  student.key,
                                                  student,
                                                  "Language",
                                                  selectedStudents
                                                ) == r.value
                                            )}
                                            options={
                                              AllAssignmentLanguageOptions
                                            }
                                            isClearable
                                            getOptionValue={(value) => {
                                              return value.label;
                                            }}
                                            onChange={(e) => {
                                              handleAssignmentLanguageChange(
                                                e,
                                                student.key,
                                                studentTree.key
                                              );
                                            }}
                                            isMulti={false}
                                            closeMenuOnSelect={true}
                                            isDisabled={
                                              !selectedStudents.some(
                                                (r) =>
                                                  r.studentId === student.key &&
                                                  r.classId === studentTree.key
                                              )
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            )}
                        </div>
                      );
                    })}

                {roleId === Constant.UserRoleId.SchoolAdmin && (
                  <div className="flex justify-between w-full xl:w-3/4 1xl:w-3/4 xxxl:w-1/2 border-b border-gray-200 px-2 pb-2 pt-4 mb-4 flex">
                    <div className="flex w-full items-end">
                      <div className="flex w-[21.6rem] xl:w-[24.6rem] 1xl:w-[24.6rem] 2xl:w-[29.6rem] gap-x-2 flex-none">
                        Additional School Classes
                        {toggleAdditionalClass ? (
                          <MinusCircleIcon
                            className="w-5 mr-2 cursor-pointer"
                            onClick={() => toggleExpandAll()}
                          />
                        ) : (
                          <PlusCircleIcon
                            className="w-5 mr-2 cursor-pointer"
                            onClick={() => toggleExpandAll()}
                          />
                        )}
                      </div>
                      <div className="text-center w-24 1xl:w-48 flex-none">
                        Eliminate 1 Answer for Multiple Choice Questions
                      </div>
                      <div
                        className="text-center w-48 cursor-pointer flex-none"
                        title="Setting allows you to temporarily override the Text to Speech settings for the single assignment."
                      >
                        Assignment Text To Speech Settings
                      </div>
                      <div
                        className="text-center w-48 flex-none"
                        title="Setting allows you to temporarily override the language settings for the single assignment."
                      >
                        Assignment Language Settings
                      </div>
                    </div>
                  </div>
                )}
                {roleId === Constant.UserRoleId.SchoolAdmin &&
                  studentsTree &&
                  studentsTree.length > 0 &&
                  studentsTree
                    .filter((r) => r.isAdditionalClass)
                    .map((studentTree, index) => {
                      return (
                        <div
                          key={index}
                          className={`relative flex flex-col items-start px-2 w-full xl:w-full xxxl:w-3/4  ${
                            index % 2 === 0 ? "bg-light-violet/10" : "bg-white"
                          }`}
                        >
                          <div className="flex w-full">
                            <div className="flex py-2 w-[21.6rem] xl:w-[24.6rem] 1xl:w-[24.6rem] 2xl:w-[29.6rem] border-r border-primary-violet/50 flex-none">
                              <div className="flex items-center h-5">
                                {expandedClasses &&
                                expandedClasses.length > 0 &&
                                expandedClasses.some(
                                  (r) => r === studentTree.key
                                ) ? (
                                  <MinusCircleIcon
                                    className="w-5 mr-2 cursor-pointer"
                                    onClick={() =>
                                      handleExpand(studentTree.key)
                                    }
                                  />
                                ) : (
                                  <PlusCircleIcon
                                    className="w-5 mr-2 cursor-pointer"
                                    onClick={() =>
                                      handleExpand(studentTree.key)
                                    }
                                  />
                                )}
                                <input
                                  onChange={() =>
                                    handleSelectClass(studentTree.key)
                                  }
                                  checked={selectedClasses.some(
                                    (r) => r === studentTree.key
                                  )}
                                  type="checkbox"
                                  className="cursor-pointer focus:ring-primary-violet h-4 w-4 text-primary-violet border-gray-500"
                                />
                              </div>
                              <div className="ml-3 text-sm w-full">
                                <div className="flex gap-2">
                                  <label className="font-medium text-gray-700">
                                    {`${studentTree.title}-${studentTree.teacherName}`}
                                  </label>
                                  <span className="flex gap-2 justify-center items-center">
                                    {studentTree.externalRosterProviderIds &&
                                      studentTree.externalRosterProviderIds
                                        .split(",")
                                        .map((ext) =>
                                          getExternalRosterIcon(parseInt(ext))
                                        )}
                                    {studentTree.isClassGoogleEnabled &&
                                      getExternalRosterIcon(
                                        Constant.ExternalRosterProviders.Google
                                      )}
                                  </span>
                                </div>
                                <div className="flex w-full justify-between gap-x-2 lg:gap-x-0">
                                  <p className="text-gray-500 mt-1">
                                    {selectedStudents.filter(
                                      (r) => r.classId === studentTree.key
                                    ).length !==
                                      studentTree.children?.length && (
                                      <div
                                        className={`mr-1 inline-flex items-baseline px-2.5 py-0.5 rounded-md text-xs font-medium bg-${
                                          selectedStudents.filter(
                                            (r) => r.classId === studentTree.key
                                          ).length === 0
                                            ? "red"
                                            : "blue"
                                        }-100 text-${
                                          selectedStudents.filter(
                                            (r) => r.classId === studentTree.key
                                          ).length === 0
                                            ? "red"
                                            : "blue"
                                        }-800`}
                                      >
                                        {
                                          selectedStudents.filter(
                                            (r) => r.classId === studentTree.key
                                          ).length
                                        }
                                      </div>
                                    )}
                                    {selectedStudents.filter(
                                      (r) => r.classId === studentTree.key
                                    ).length === studentTree.children?.length
                                      ? "All"
                                      : "of"}
                                    <div className="ml-1 mr-1 inline-flex items-baseline px-2.5 py-0.5 rounded-md text-xs font-medium bg-green-100 text-green-800">
                                      {studentTree.children?.length}
                                    </div>
                                    students selected
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="w-24 1xl:w-48 border-r border-primary-violet/50 flex-none"></div>

                            <div className="w-48 border-r border-primary-violet/50 flex-none"></div>

                            <div className="w-48 flex-none"></div>
                          </div>
                          {expandedClasses.some((r) => r === studentTree.key) &&
                            studentTree.children &&
                            studentTree.children.length > 0 && (
                              <div className="w-full">
                                {studentTree.children.map((student, index) => {
                                  return (
                                    <div key={index} className="flex w-full">
                                      <div className="flex w-[21.6rem] xl:w-[24.6rem] 1xl:w-[24.6rem] 2xl:w-[29.6rem] border-r border-primary-violet/50 flex-none">
                                        <div className="flex items-center h-5 ml-14">
                                          <input
                                            onChange={() =>
                                              handleCheckStudent(
                                                student.key,
                                                studentTree.key,
                                                student.languageSettingId
                                              )
                                            }
                                            checked={selectedStudents.some(
                                              (r) =>
                                                r.studentId === student.key &&
                                                r.classId === studentTree.key
                                            )}
                                            disabled={selectedStudents.some(
                                              (r) =>
                                                r.studentId === student.key &&
                                                r.classId !== studentTree.key
                                            )}
                                            type="checkbox"
                                            className={`${
                                              isAssignmentInProgress(
                                                student.key
                                              )
                                                ? "opacity-50 cursor-not-allowed "
                                                : ""
                                            } focus:ring-primary-violet h-4 w-4 text-primary-violet border-gray-500`}
                                          />
                                        </div>
                                        <div className="ml-3 text-sm">
                                          <div className="flex gap-2">
                                            <label className="font-normal text-gray-700">
                                              {student.title}
                                            </label>
                                            {student.userExternalProviderIds &&
                                              student.userExternalProviderIds
                                                .split(",")
                                                .map((ext) =>
                                                  getExternalRosterIcon(
                                                    parseInt(ext)
                                                  )
                                                )}

                                            {student.isStudentGoogleEnabled &&
                                              getExternalRosterIcon(
                                                Constant.ExternalRosterProviders
                                                  .Google
                                              )}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="text-sm w-24 1xl:w-48 border-r border-primary-violet/50 flex-none">
                                        <div className="flex gap-2 justify-center">
                                          <input
                                            onChange={(e) =>
                                              handleCheckStudentEliminateAnswer(
                                                student.key,
                                                studentTree.key
                                              )
                                            }
                                            checked={selectedStudents.some(
                                              (r) =>
                                                r.eliminateAnswerForSingleMCQ ===
                                                  true &&
                                                r.studentId === student.key &&
                                                r.classId === studentTree.key
                                            )}
                                            disabled={
                                              !selectedStudents.some(
                                                (r) =>
                                                  r.studentId === student.key &&
                                                  r.classId === studentTree.key
                                              ) || isCRAssignment
                                            }
                                            type="checkbox"
                                            className={`${
                                              isAssignmentInProgress(
                                                student.key
                                              )
                                                ? "opacity-50 cursor-not-allowed "
                                                : ""
                                            } focus:ring-primary-violet h-4 w-4 text-primary-violet border-gray-500`}
                                          />
                                        </div>
                                      </div>
                                      <div className="text-sm w-48 border-r border-primary-violet/50 flex-none">
                                        <div className="flex gap-2 justify-center">
                                          Off
                                          <Toggle
                                            checked={getStudentSettingValue(
                                              studentTree.key,
                                              student.key,
                                              student,
                                              "TTS",
                                              selectedStudents
                                            )}
                                            disabled={
                                              !selectedStudents.some(
                                                (r) =>
                                                  r.studentId === student.key &&
                                                  r.classId === studentTree.key
                                              )
                                            }
                                            onChange={() => {
                                              handleToggleTTS(
                                                student.key,
                                                studentTree.key,
                                                student
                                              );
                                            }}
                                          />
                                          On
                                        </div>
                                      </div>
                                      <div className="text-sm w-48 flex-none">
                                        <div className="pb-2">
                                          <Select
                                            className="mx-2"
                                            value={AllAssignmentLanguageOptions.find(
                                              (r) =>
                                                selectedStudents.find(
                                                  (r) =>
                                                    r.studentId ===
                                                      student.key &&
                                                    r.classId ===
                                                      studentTree.key
                                                )?.languageSettingId == r.value
                                            )}
                                            options={
                                              AllAssignmentLanguageOptions
                                            }
                                            isClearable
                                            getOptionValue={(value) => {
                                              return value.label;
                                            }}
                                            onChange={(e) => {
                                              handleAssignmentLanguageChange(
                                                e,
                                                student.key,
                                                studentTree.key
                                              );
                                            }}
                                            isMulti={false}
                                            closeMenuOnSelect={true}
                                            isDisabled={
                                              !selectedStudents.some(
                                                (r) =>
                                                  r.studentId === student.key &&
                                                  r.classId === studentTree.key
                                              )
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            )}
                        </div>
                      );
                    })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(Class);

import { UserContextState } from "../../../../../redux/actions/userContextAction";
import { connect } from "react-redux";
import Profile from "../../../../../model/users/profile";
import { Fragment, useEffect, useState } from "react";
import QuestionAnswer from "../../shared/questionAnswer";
import BeginActivity from "../../shared/beginActivity";
import Constant from "../../../../../utils/constant/constant";
import RouteConstant from "../../../../../utils/constant/routeConstant";
import { useLocation } from "react-router-dom";
import {
  initializeStudentAssessmentActivity,
  completeStudentAssessmentActivity,
  updateStudentAssessmenttActivity,
  getStudentAssessmentLastSession,
  getAssessmentAttemptCount,
} from "../../../../../api/student/studentAssessmentActivityApi";
import { useHistory } from "react-router-dom";
import { GetShowExplanation } from "../../../../../utils/helper";
import constant from "../../../../../utils/constant/constant";
import InformationDialog from "../../../../shared/informationDialog";
import routeConstant from "../../../../../utils/constant/routeConstant";
import { useTranslation } from "react-i18next";
import { getAssessmentById } from "../../../../../api/teacher/assessment";
import { IsActivitySessionQuestionAttempted } from "../../../../../api/student/activitySession/activitySessionQuestions";
import { IQuestionStates } from "../../../../../model/interface/questionState";

interface IStudentAssessmentActivity {
  userContext: UserContextState;
  profile: Profile;
  match: any;
}

type IInitializeStudentAssignmentActivityRespons = {
  hasPendingSession: boolean;
  lernositySessionId: string;
  questionIds: Array<string>;
  lernosityActivityId: string;
  calculatorReferenceId?: string;
  activitySessionId: number;
  languageId: number;
  isDisplayRandomQuestion?: boolean;
  questionStates: Array<IQuestionStates>;
  learnositySessionGroupId: string;
};

function StudentAssessmentActivity(props: IStudentAssessmentActivity) {
  const [studentAssessmentActivity, setStudentAssessmentActivity] =
    useState<IInitializeStudentAssignmentActivityRespons>();
  const location = useLocation();
  const code = atob(props.match.params.code);
  const subjectId = atob(props.match.params.subjectId);
  const [beginActivityModal, setBeginActivityModal] = useState<any>({
    open: false,
    data: undefined,
  });
  const allowMultipleAttempts = Boolean(
    atob(props.match.params.multipleAttemptAllowed)
  );
  const [assessmentId, setAssessmentId] = useState<number>(0);
  const [showMaxAttemptReached, setShowMaxAttemptReached] = useState(false);
  const { t } = useTranslation();
  const history = useHistory();
  let classId: any = undefined;
  let teacherId: any = undefined;
  let languageId: any = undefined;
  const redirectUrl =
    location.pathname.toLowerCase().indexOf("kindergarten") > -1
      ? RouteConstant.KindergartenStudentRoutes.Assignment
      : location.pathname.toLowerCase().indexOf("elementary") > -1
      ? RouteConstant.StudentRoute.Home
      : location.pathname.toLowerCase().indexOf("middleschool") > -1
      ? RouteConstant.MiddleSchool.Home
      : "";
  const resultUrl =
    location.pathname.toLowerCase().indexOf("kindergarten") > -1
      ? RouteConstant.KindergartenStudentRoutes.AssignmentResult
      : location.pathname.toLowerCase().indexOf("elementary") > -1
      ? RouteConstant.StudentRoute.AssignmentResult
      : location.pathname.toLowerCase().indexOf("middleschool") > -1
      ? RouteConstant.MiddleSchool.AssignmentResult
      : "";
  const theme =
    location.pathname.toLowerCase().indexOf("kindergarten") > -1
      ? "KINDERGARTEN"
      : location.pathname.toLowerCase().indexOf("elementary") > -1
      ? "ELEMENTARYHOME"
      : location.pathname.toLowerCase().indexOf("middleschool") > -1
      ? "MIDDLESCHOOLHOME"
      : "ELEMENTARYHOME";
  let studentType: any = Constant.StudentType.EL;
  if (location.pathname.toLowerCase().indexOf("kindergarten") > -1)
    studentType = Constant.StudentType.K1;
  else if (location.pathname.toLowerCase().indexOf("middleschool") > -1)
    studentType = Constant.StudentType.MS;

  const [showExpalanation, setShowExplanation] = useState<boolean>(false);
  const [standardIds, setStandardIds] = useState<Array<number>>();
  const [showQuestionAlreadyAttempted, setShowQuestionAlreadyAttempted] =
    useState<boolean>(false);
  const [contentAreaId, setContentAreaId] = useState<number>(0);

  useEffect(() => {
    setShowExplanation(
      GetShowExplanation(
        props.userContext?.gradeId,
        props.userContext?.stateId,
        Constant.AssignmentActivityType.ASSESSMENT,
        undefined,
        allowMultipleAttempts,
        undefined
      )
    );
    initializeActivity(false);
  }, []);

  function initializeActivity(isBegin: boolean) {
    getStudentAssessmentLastSession(props.userContext.userId, code, 0).then(
      (d) => {
        var data = d.data;
        setAssessmentId(data.assessmentId ?? 0);
        setBeginActivityModal({ open: true, data });
      }
    );
  }

  const handleOnSubmit = () => {
    getAssessmentAttemptCount(code, props.userContext?.userId ?? 0).then(
      (d) => {
        if (d.data > 0) {
          setShowMaxAttemptReached(true);
        } else {
          completeStudentAssessmentActivity(
            props.userContext.userId,
            studentAssessmentActivity?.lernositySessionId,
            studentAssessmentActivity?.activitySessionId
          ).then((res) => {
            let validAssessmentId = assessmentId > 0 ? assessmentId : res.data;
            if (validAssessmentId > 0 ) {
              history.push({
                pathname: resultUrl
                  .replace(":assignmentId", "0")
                  .replace(":assignmentActivityId", validAssessmentId.toString())
                  .replace(":runningAssignment", "No")
                  .replace("/:sessionId?", ""),
                state: {
                  time: 0,
                  questionAttemptedTillTimeLimit: 0,
                },
              });
            } else {
              history.push(redirectUrl);
            }
          });
        }
      }
    );
  };

  function updateActivity(data: any, sessionId: string) {
    classId = data.classId;
    teacherId = data.teacherId;
    languageId = data.languageId;
    getAssessmentAttemptCount(code, props.userContext?.userId ?? 0).then(
      (d) => {
        if (d.data > 0) {
          setShowMaxAttemptReached(true);
        } else {
          initialize(data);
        }
      }
    );
  }

  function initialize(data) {
    initializeStudentAssessmentActivity(
      props.userContext.userId,
      languageId === constant.Languages.Spanish ? "es" : "en",
      code,
      true,
      data.classId,
      data.teacherId,
      props.userContext.gradeId
    ).then((d) => {
      var data = d.data;
      if ((data.assessmentId ?? 0) > 0) {
        //get assessment standards
        getAssessmentById(data.assessmentId).then((response) => {
          var standardIds = response.data.questions?.map(
            (question, questionIndex) => {
              return question.standardId;
            }
          );
          setStandardIds(standardIds);
        });
      }
      setStudentAssessmentActivity(data);

      updateStudentAssessmenttActivity(
        props.userContext.userId,
        data.lernositySessionId,
        classId,
        teacherId,
        languageId === constant.Languages.Spanish
          ? constant.Languages.Spanish
          : constant.Languages.English
      ).then((d) => {});
    });
  }

  function validateAssessment(questionId: any) {
    getAssessmentAttemptCount(code, props.userContext?.userId ?? 0).then(
      (d) => {
        if (d.data > 0) {
          setShowMaxAttemptReached(true);
        }
      }
    );

    if (
      studentAssessmentActivity !== undefined &&
      questionId !== undefined &&
      questionId > 0
    ) {
      IsActivitySessionQuestionAttempted(
        studentAssessmentActivity.activitySessionId,
        questionId
      ).then((d) => {
        if (d.data === true) setShowQuestionAlreadyAttempted(true);
      });
    }
  }

  return (
    <Fragment>
      <div className="min-w-0 w-full h-full">
        {studentAssessmentActivity !== undefined &&
          studentAssessmentActivity.lernositySessionId.length > 0 && (
            <QuestionAnswer
              activityType={Constant.ActivityType.ASSIGNMENT}
              activityId={studentAssessmentActivity.lernosityActivityId}
              teacherActivityId={
                studentAssessmentActivity.learnositySessionGroupId
              }
              showActivity={true}
              showQuestions={false}
              questionIds={[]}
              hasSections={false}
              hasItemBranching={false}
              isAlienExplanation={false}
              isGameChoosen={false}
              studentId={props.userContext.userId.toString()}
              resumeActivity={studentAssessmentActivity.hasPendingSession}
              sessionId={studentAssessmentActivity.lernositySessionId}
              studentType={studentType}
              title={""}
              adaptiveFilter={null}
              OnSubmit={handleOnSubmit}
              onExitRedirectUrl={redirectUrl}
              questionRetry={false}
              activitySubType={Constant.ActivitySubTypes.ASSESSMENT}
              calculatorReferenceId={
                studentAssessmentActivity.calculatorReferenceId
              }
              showExplanation={showExpalanation}
              activitySessionId={studentAssessmentActivity.activitySessionId}
              language={
                studentAssessmentActivity.languageId ===
                constant.Languages.Spanish
                  ? "es"
                  : "en"
              }
              shuffleItems={
                studentAssessmentActivity.isDisplayRandomQuestion ?? false
              }
              onUnfocused={validateAssessment}
              onFocused={validateAssessment}
              standardId={standardIds?.join(",").toString()}
              questionsState={studentAssessmentActivity.questionStates}
              contentAreaId={contentAreaId}
              subjectId={parseInt(subjectId)}
            ></QuestionAnswer>
          )}

        {beginActivityModal.open && (
          <BeginActivity
            activity={"Assessment Test"}
            name={beginActivityModal.data.assessmentName}
            assignmentActivityName={beginActivityModal.data.assessmentName}
            code={code}
            noOfQuestions={beginActivityModal.data.questionCount}
            source={"Assignment"}
            themeName={theme}
            hideClass={true}
            hideTeacher={true}
            data={beginActivityModal.data}
            ActivityLanguageLevel={"UserLevel"}
            onBegin={(data: any, value: any) => {
              setContentAreaId(value.contentAreaId);
              updateActivity(value, beginActivityModal.data.sessionId);
              setBeginActivityModal({ ...beginActivityModal, open: false });
            }}
            subjectId={parseInt(subjectId)}
          />
        )}
      </div>
      {showMaxAttemptReached && (
        <InformationDialog
          informationMessage={t(
            "Assignments.MaxAttemptReachedAssessmentMessage"
          )}
          informationTitle={t("Assignments.MaxAttemptReachedHeader")}
          open={showMaxAttemptReached}
          onOkClick={() => {
            var path =
              window.location.href.toLowerCase().indexOf("kindergarten") !== -1
                ? routeConstant.KindergartenStudentRoutes.Home
                : window.location.href
                    .toString()
                    .toLowerCase()
                    .indexOf("middleschool") !== -1
                ? routeConstant.MiddleSchool.Home
                : routeConstant.StudentRoute.Home;

            history.push({
              pathname: path,
            });
          }}
        />
      )}
      {showQuestionAlreadyAttempted && (
        <InformationDialog
          informationMessage={t("Assignments.QuestionAlreadyAttmptedMessage")}
          informationTitle={t("Assignments.QuestionAlreadyAttmptedHeader")}
          open={showQuestionAlreadyAttempted}
          onOkClick={() => {
            window.location.reload();
          }}
        />
      )}
    </Fragment>
  );
}

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
};

export default connect<{}, {}, {}>(
  mapStateToProps,
  {}
)(StudentAssessmentActivity);

import { Fragment, useEffect, useState } from "react";
import "../../../../../css/style.css";
import { connect } from "react-redux";

import { useTranslation } from "react-i18next";
import { UserContextState } from "../../../../../redux/actions/userContextAction";
import Profile from "../../../../../model/users/profile";
import { IBreadcrumb } from "../../../../../model/common/breadcrumb";
import Breadcrumb from "../../middleSchool/breadcrumb";

import RouteConstant from "../../../../../../src/utils/constant/routeConstant";
import LearnosityReport from "../../../results/learnosityReport";
import { getCannedTestResult } from "../../../../../api/student/cannedTestApi";
import CannedTestOverViewResult from "./cannedTestOverViewResult";
import ProgressResult from "./progressResult";
import TotalQuestionsByDomains from "./totalQuestionsByDomains";
import TotalQuestionsByStandards from "./totalQuestionsByStandards";

interface ICannedTestResult {
  userContext: UserContextState;
  profile: Profile;
  match: any;
  studentId?: number;
  studentGradeId?: number;
  isTeacherView?: boolean;
  sessionId?: number;
}
const CannedTestResult = (props: ICannedTestResult) => {
  const [result, setResult] = useState<any>(null);
  const sessionId = props.isTeacherView
    ? props.sessionId ?? ""
    : props.match.params.sessionId;
  const { t } = useTranslation();
  const userId =
    props.isTeacherView === true
      ? props.studentId ?? 0
      : props.userContext.userId;
  const [learnosityActivityUserId, setLearnosityActivityUserId] =
    useState<number>(userId);

  useEffect(() => {
    getResult();
  }, []);

  function getResult() {
    getCannedTestResult(sessionId).then((res) => {
      if (res.data?.learnosityActivityUserId !== null) {
        setLearnosityActivityUserId(res.data?.learnosityActivityUserId);
      }
      setResult(res.data);
    });
  }
  const breadcrumbItems = () => {
    const items: Array<IBreadcrumb> = [
      {
        name: t("Breadcrumb.Home"),
        url: RouteConstant.StudentRoute.Home,
        textColor: "text-gray-500",
      },
      {
        name: t("Breadcrumb.Result"),
        textColor: "text-gray-500",
        url: "",
      },
    ];

    return items;
  };
  return (
    <Fragment>
      {result && (
        <div className="relative w-full p-5 ">
          <div className="flex px-2 pb-3 pt-2 border-b">
            {!props.isTeacherView && <Breadcrumb items={breadcrumbItems()} />}
          </div>
          <div className="flex flex-col md:flex-row mt-4">
            <CannedTestOverViewResult data={result} />
            <div className="flex-grow h-auto md:w-1/2">
              <div className="flex w-full mt-6 md:mt-0 pb-6">
                <div>
                  <h1 className="text-2xl font-bold text-gray-700 capitalize">
                    {t("DiagnosticResult.Title")} {result.type}
                  </h1>
                </div>
              </div>
              <div className="flex-none xxl:flex">
                <div className="flex-none w-full xxl:w-2/5 bg-gray-50 rounded-md xl:mr-12 mb-12 xxl:mb-0 overflow-hidden shadow hover:shadow-xl">
                  <ProgressResult data={result} />
                </div>
                <div className="flex-grow w-full xxl:w-3/5">
                  <div className="relative w-full h-full px-7 py-7 bg-white rounded-md shadow hover:shadow-xl xs-chart-fix xs-standards-chart-fix grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 gap-4">
                    <TotalQuestionsByDomains data={result} />
                    <TotalQuestionsByStandards data={result} />
                  </div>
                </div>
              </div>
              <div>
                <div className="mt-10">
                  <div className="p-5 bg-gray-50 border rounded-md text-sm font-semibold">
                    <h2>{t("StudyPlanResult.AnswerQuestionStatus")}.</h2>
                    <ul className="flex flex-wrap gap-2 w-full xl:w-1/2 mt-3">
                      {result.questions.map((question, index) => {
                        return (
                          <>
                            {question.order > 0 && question.score > 0 && (
                              <li className="w-6 h-6 bg-[#B2D136] border border-[#B2D136] text-white text-xs font-semibold flex items-center rounded-sm shadow-sm justify-center hover:bg-transparent hover:text-[#B2D136]">
                                {index + 1}
                              </li>
                            )}
                            {question.order > 0 && question.score === 0 && (
                              <li className="w-6 h-6 bg-[#e53f71] border border-[#e53f71] text-white text-xs font-semibold flex items-center rounded-sm shadow-sm justify-center hover:bg-transparent hover:text-[#e53f71]">
                                {index + 1}
                              </li>
                            )}
                          </>
                        );
                      })}
                    </ul>
                  </div>
                </div>
                <div className="mt-5">
                  <div className="p-5 bg-white border-2 border-blue-400 rounded-md text-sm font-semibold">
                    <LearnosityReport
                      sessionId={result.lernositySessionId}
                      user_id={learnosityActivityUserId.toString()}
                      show_answers={true}
                      wrapperClass="EL-Wrapper"
                      standardIds={result.standards
                        ?.map((standard) => {
                          return standard.standardId;
                        })
                        ?.join(",")}
                    ></LearnosityReport>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
};
export default connect<{}, {}, {}>(mapStateToProps, {})(CannedTestResult);

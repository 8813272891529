import { ISessionTeam } from '../../../../../model/interface/classroomGame/thinkOrSwimSetup'
import AnglerfishButton from './anglerfishButton'

interface ITOSGamePlayPointsAssignerProps {
  sessionTeams: Array<ISessionTeam>,
  selectedTeams: Array<string>,
  isQuestion: boolean,
  reefRecessPointsValue: number,
  changeSelectedTeam: (teamName: string) => void,
  handleAssignPointsClick: (isQuestion: boolean) => void,
}

function TOSGamePlayPointsAssigner(props: ITOSGamePlayPointsAssignerProps) {
  const {
    sessionTeams,
    selectedTeams,
    isQuestion,
    reefRecessPointsValue,
    changeSelectedTeam,
    handleAssignPointsClick,
  } = props;

  return (
    <div className="w-full flex justify-between mt-3">
      <div className="w-full flex flex-col justify-center">
        <div className="py-1 text-center">
          <b>Teacher</b>, 
          {isQuestion && (<span> check the box next to each team that answered the question correctly.</span>)}
          {!isQuestion && (<span> select the winning team or teams to add {reefRecessPointsValue} Reef Recess points.</span>)}
        </div>
        <div className="w-full px-4 py-1 flex justify-around">
          {sessionTeams.map((team: ISessionTeam, index: number) => (
            <div key={index} className="flex-initial">
              <label className="cursor-pointer">
                <input 
                  type="checkbox" 
                  className="mr-2 cursor-pointer" 
                  checked={!!selectedTeams.find(n => n === team.name)}
                  onChange={() => changeSelectedTeam(team.name)} 
                />
                {team.name}
              </label>
            </div>
          ))}
        </div>
      </div>
      <div className="w-[20rem] lg:mr-5">
        <AnglerfishButton 
          text="Record Points"
          textSize={1.1}
          canTextShink
          onClick={() => handleAssignPointsClick(isQuestion)}
        />
      </div>
    </div>
  )
}

export default TOSGamePlayPointsAssigner

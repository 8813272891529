import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import ReactTooltip from "react-tooltip";
import { MessageApi } from "../../../../../api/message/messageApi";
import { ClassApi } from "../../../../../api/teacher/classManagementApi";
import { PageAccessMode } from "../../../../../model/common/pageAccessMode";
import PagingResponse from "../../../../../model/common/pagingResponse";
import classListResponse from "../../../../../model/teacher/classManagement/classListResponse";
import ClassModel from "../../../../../model/teacher/classManagement/classModel";
import ClassSearchModel from "../../../../../model/teacher/classManagement/classSearchModel";
import { UserContextState } from "../../../../../redux/actions/userContextAction";
import Constant from "../../../../../utils/constant/constant";
import RouteConstant from "../../../../../utils/constant/routeConstant";
import {
  DEFAULT_PAGE_NUMBER,
  DEFAULT_PAGE_SIZE,
} from "../../../../../utils/pagingConstant";
import Permissions from "../../../../../utils/permissions";
import AccessDenied from "../../../../shared/accessDenied";
import ConfirmationDialog from "../../../../shared/confirmationDialog";
import Loader from "../../../../shared/loader";
import AllowedTo from "../../../../shared/rbac";
import ClassFeature from "./classFeature";
import ClassStudent from "./classStudent";
import ClassStudentSetting from "./classStudentSetting";
import ClassSubject from "./classSubject";
import ClassTeacher from "./classTeacher";
import LiftOffSetting from "./liftOffSetting";
import ArcadeAvatarPopup from "./popups/arcadeAvatarPopup";
import ClassPasswordPopup from "./popups/classPasswordPopup";
import PasswordCardPopup from "./popups/passwordCardPopup";
import WarningMessage from "../../../../shared/warningMessage";
import { useConfirmation } from "../../../../shared/confirmation/confirmationService";
import GoogleSignInModal from "../../../popup/googleSignInModal";
import GoogleClassStudentMapping from "./googleClassStudentMapping";
import GoogleMappingModal from "../../../popup/googleMappingModal";
import { GoogleApi } from "../../../../../api/google/googleApi";
import Profile from "../../../../../model/users/profile";
import CanvasClassStudentMapping from "./canvasClassStudentMapping";
import { CanvasApi } from "../../../../../api/canvas/canvasApi";
import CanvasSignInModal from "../../../popup/canvasSignInModal";
import {
  validateIsCanvasUser,
  validateIsGoogleUser,
} from "../../../../../utils/helper";
import TransferStudentPopup from "./popups/transferStudentPopup";
import ImportStudentClassDataPopup from "./popups/importStudentClassDataPopup";
import ConnectToGoogleClassPopup from "./popups/connectToGoogleClassPopup";
import ConnectToCanvasClassPopup from "./popups/connectToCanvasClassPopup";
import { isGameLocked } from "../../../../../utils/studentHelper";
import ClassTeacherModel from "../../../../../model/teacher/classManagement/classTeacherModel";
import { ISelectElement } from "../../../../../model/interface/selectElement";
import { GetSchoolYears } from "../../../../../api/teacher/school";
import { ISchoolYear } from "../../../../../model/teacher/schoolYear";
import userApi from "../../../../../api/userApi";
import ClassBuilder from "../classBuilder/classBuilder";
import ClassDetail_SettingTabs from "./classDetailSettingTabs";
import ClassDetail_MainForm from "./classDetailMainForm";

interface ClassDetailProps {
  userContext: UserContextState;
  profile: Profile;
  match?: any;
}

const defaultData: PagingResponse<classListResponse> = {
  data: [],
  totalRecords: 0,
  page: 1,
  pageSize: 10,
};

const ClassDetail = (props: ClassDetailProps & any) => {
  enum ConfrimationType {
    None,
    DeleteClass,
    ArchiveClass,
    UpdateClass,
    UnlockArcade,
  }

  let classId: any = props.match.params.classId;
  const parameters = props.location.state;
  const [defaultSchoolYearId, setDefaultSchoolYearId] = useState<number>(1);
  const classModel: ClassModel = {
    userId:
      props.userContext?.userId === undefined ? 0 : props.userContext?.userId,
    classId: classId,
    schoolId:
      props.userContext?.schoolId === undefined
        ? 0
        : props.userContext?.schoolId,
    colorCode: "",
    avatarLockDuration: 0,
    displayName: "",
    endTime: "",
    gameLockDuration: 0,
    isActive: true,
    isArchived: false,
    isAvatarLocked: false,
    isGameLocked: false,
    name: "",
    passwordHash: "",
    startTime: "",
    avatarLockStartTime: "",
    avatarLockEndTime: "",
    studentCount: 0,
    teacherCount: 0,
    subjectCount: 0,
    isLiftOffEnabled: false,
    isHidden: false,
    classExternalRoster: [],
    schoolAccountId: 0,
    higherGradeStudentCount: 0,
    googleId: "",
    canvasId: "",
    isGoogleEnabled: false,
    googleClassId: 0,
    courseId: "",
    googleAccessToken: "",
    schoolCanvasAccountId: "",
    canvasCourseId: "",
    isCanvasEnabled: false,
    baseEndPointUrl: "",
    canvasAccessToken: "",
    schoolYearId: defaultSchoolYearId,
  };
  const history = useHistory();
  const [classDetail, setClassDetail] = useState<ClassModel>(classModel);
  const [selectedClassSetting, setClassSetting] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [showArcadeAvatarPopup, setShowArcadeAvatarPopup] = useState(false);
  const [showPasswordCardPopup, setShowPasswordCardPopup] = useState(false);
  const [showClassPassword, setShowClassPassword] = useState(false);
  const [showTransferStudent, setShowTransferStudent] = useState(false);
  const [showImportStudentClassData, setShowImportStudentClassData] =
    useState(false);
  const [shouldUpdateClassStudentKey, setShouldUpdateClassStudentKey] =
    useState(0);

  const [mode, setMode] = useState<PageAccessMode>(PageAccessMode.None);
  const [classes, setClasses] =
    useState<PagingResponse<classListResponse>>(defaultData);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [confirmationTitle, setConfirmationTitle] = useState("Confirm Action");
  const [confirmationMessage, setConfirmationMessage] =
    useState("Please confirm");
  const [confirmationType, setConfirmationType] = useState<ConfrimationType>(
    ConfrimationType.None
  );
  const [studentCount, setStudentCount] = useState<number>(0);
  const [subjectCount, setSubjectCount] = useState<number>(0);
  const [unMappedStudentCount, setUnMappedStudentCount] = useState<number>(0);
  const [teacherCount, setTeacherCount] = useState<number>(0);
  const [selectedColor, setSelectedColor] = useState<string>("");
  const [isGoogleEnabled, setIsGoogleEnabled] = useState<boolean>(false);
  const [isCanvasEnabled, setIsCanvasEnabled] = useState<boolean>(false);
  const [showGooglePopup, setShowGooglePopup] = useState(false);
  const [showGoogleMappingPopup, setShowGoogleMappingPopup] = useState(false);
  const [showConnectToGoogle, setShowConnectToGooglePopup] = useState(false);
  const [showConnectToCanvas, setShowConnectToCanvasPopup] = useState(false);
  const [showCanvasPopup, setShowCanvasPopup] = useState({
    isopen: false,
    mode: "SIGNIN",
  });
  const [onlineUsers, setOnlineUsers] = useState([]);
  const [isClassRosterConfirmationShown, setIsClassRosterConfirmationShown] =
    useState(false);
  const [isArchived, setIsArchived] = useState<boolean>(
    parameters ? parameters.isArchieved : false
  );
  const [isHidden] = useState<boolean>(
    parameters ? parameters.isHidden : false
  );
  const [primaryTeacher, setPrimaryTeacher] = useState<ClassTeacherModel>();
  const [schoolYears, setSchoolYears] = useState<Array<ISelectElement>>();
  const [schoolYearData, setSchoolYearData] = useState<Array<ISchoolYear>>();
  const [selectedSchoolYearId, setSelectedSchoolYearId] = useState<number>(0);
  const [isDemoAccount, setIsDemoAccount] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm<ClassModel>();
  const confirm = useConfirmation();

  const getOnlineUsers = () => {
    MessageApi.getOnlineUsersRef(props?.userContext?.schoolId!).on(
      "value",
      (snapshot) => {
        if (snapshot.val()) {
          setOnlineUsers(snapshot.val());
        } else {
          setOnlineUsers([]);
        }
      }
    );
  }

  const getClassDetails = (classId: number): void => {
    setShowLoader(true);
    ClassApi.getClassDetail(classId, props.userContext.userId)
      .then((r) => {
        setClassDetail({ ...r.data, userId: props.userContext.userId });
        setShowLoader(false);
        setStudentCount(r.data.studentCount);
        setTeacherCount(r.data.teacherCount);
        setSubjectCount(r.data.subjectCount);
        setValue("name", r.data.name);
        setValue("displayName", r.data.displayName);
        setValue("colorCode", r.data.colorCode);
        setValue("startTime", r.data.startTime);
        setValue("endTime", r.data.endTime);
        setSelectedColor(r.data.colorCode);
        setIsGoogleEnabled(r.data.isGoogleEnabled);
        setIsCanvasEnabled(r.data.isCanvasEnabled);
        setSelectedSchoolYearId(r.data.schoolYearId ?? defaultSchoolYearId);

        if (
          r.data.classExternalRoster !== null &&
          r.data.classExternalRoster !== undefined &&
          r.data.classExternalRoster.length > 0 &&
          r.data.studentCount === 0 &&
          r.data.subjectCount === 0 &&
          isClassRosterConfirmationShown === false
        ) {
          confirm({
            variant: "danger",
            title: "Class Rostering",
            description:
              "You must add a subject to the class in order to enable rostering. Once a subject has been associated, then the process will automatically begin to sync your students to your class. Do you want to add a subject now?",
          }).then((d) => {
            setClassSetting(Constant.ClassSettings.SUBJECTS);
            setIsClassRosterConfirmationShown(true);
          });
        }
      })
      .catch((r) => {
        setShowLoader(false);
      });
  }

  const getPrimaryClassTeacher = (classId: number): void => {
    setShowLoader(true);
    ClassApi.getPrimaryClassTeacher(classId)
      .then((d) => {
        setPrimaryTeacher(d.data);
        if (
          d.data.userId === props.userContext.userId &&
          isHidden === false &&
          isArchived === false
        ) {
          setMode(PageAccessMode.Edit);
        } else {
          setMode(PageAccessMode.Readonly);
        }
      })
      .finally(() => {
        setShowLoader(false);
      });
  }

  const getGoogleUnMappedStudents = (classId: number, courseId: string) => {
    if (props?.userContext.impersonatedUser === null) {
      setShowLoader(true);
      GoogleApi.getGoogleStudentToImport(
        classId,
        courseId,
        DEFAULT_PAGE_SIZE,
        "",
        props?.profile?.googleUser?.refreshToken!
      )
        .then((r) => {
          setShowLoader(false);
          if (r.data && r.data.students.length > 0) {
            const unMappedStudents = r.data.students.filter(
              (s) => !s.isImported
            );
            setUnMappedStudentCount(unMappedStudents.length);
          } else {
            setUnMappedStudentCount(0);
          }
        })
        .catch((r) => {
          setShowLoader(false);
        });
    }
  }

  const getCanvasUnMappedStudents = (classId: number, courseId: string) => {
    if (props?.userContext.impersonatedUser === null) {
      setShowLoader(true);
      CanvasApi.getCanvasUnSyncedStudents(
        classId,
        courseId,
        props.profile?.canvasAccountSettings
      )
        .then((r) => {
          setShowLoader(false);
          if (r.data && r.data.length > 0) {
            setUnMappedStudentCount(r.data.filter((s) => !s.isImported).length);
          } else {
            setUnMappedStudentCount(0);
          }
        })
        .catch((r) => {
          setShowLoader(false);
        });
    }
  }

  const getSectionStudentsToImport = (
    classId: number,
    courseId: string,
    sectionId: string
  ) => {
    if (props?.userContext.impersonatedUser === null) {
      setShowLoader(true);
      CanvasApi.getCanvasUnSyncedSectionStudents(
        classId,
        courseId,
        sectionId!,
        props.profile?.canvasAccountSettings
      )
        .then((r) => {
          setShowLoader(false);
          if (r.data && r.data.length > 0) {
            setUnMappedStudentCount(r.data.filter((s) => !s.isImported).length);
          } else {
            setUnMappedStudentCount(0);
          }
        })
        .catch((r) => {
          setShowLoader(false);
        });
    }
  };

  const redirectToClassList = (): void => {
    history.push({
      pathname: RouteConstant.TeacherRoutes.Classes,
    });
  }

  const showConfirmation = (
    title: string,
    message: string,
    type: ConfrimationType
  ) => {
    setOpenConfirmation(true);
    setConfirmationTitle(title);
    setConfirmationMessage(message);
    setConfirmationType(type);
  }

  const confirmationOkClose = () => {
    switch (confirmationType) {
      case ConfrimationType.ArchiveClass:
        archiveClassConfirm();
        break;
      case ConfrimationType.DeleteClass:
        deleteClassConfirm();
        break;
      case ConfrimationType.UpdateClass:
        updateClassConfirm();
        break;
      case ConfrimationType.UnlockArcade:
        unLockArcade();
        break;
    }
  }

  const confirmationCancelClose = () => {
    setOpenConfirmation(false);
  }

  const addNewClass = (classDetail: ClassModel): void => {
    setShowLoader(true);
    const response = ClassApi.addClass(classDetail);
    response
      ?.then((d) => {
        setShowLoader(false);
        history.push({
          pathname: RouteConstant.TeacherRoutes.ClassesDetails.replace(
            ":classId",
            d.data
          ),
        });
      })
      .catch((r) => {
        setShowLoader(false);
      });
  }

  const deleteClassConfirm = () => {
    setShowLoader(true);
    ClassApi.deleteClass(
      classId,
      props.userContext?.userId,
      classDetail.isGoogleEnabled,
      classDetail.googleClassId,
      classDetail.courseId
    ).then(() => {
      setShowLoader(false);
      confirmationCancelClose();
      toast.success("Class deleted successfully.");
      redirectToClassList();
    });
  }

  const archiveClassConfirm = (): void => {
    setShowLoader(true);
    ClassApi.setIsArchive(
      classId,
      props.userContext?.userId,
      !classDetail.isArchived
    ).then(() => {
      setShowLoader(false);
      confirmationCancelClose();
      toast.success(
        "Class " +
          (classDetail.isArchived ? "unarchived" : "archived") +
          " successfully."
      );
      getClassDetails(classDetail.classId);
      setIsArchived(!classDetail.isArchived);
      getTeacherClasses(!classDetail.isArchived);
    });
  }

  const updateClass = (): void => {
    setConfirmationType(ConfrimationType.UpdateClass);
    showConfirmation(
      "Please confirm",
      "Are you sure you want to update this class?",
      ConfrimationType.UpdateClass
    );
  }

  const updateClassConfirm = (): void => {
    confirmationCancelClose();
    setShowLoader(true);
    ClassApi.updateClass(classDetail)
      .then((d) => {
        setShowLoader(false);
        getClassDetails(classDetail.classId);
        toast.success("Class updated successfully.");
      })
      .then(() => {
        getTeacherClasses(classDetail.isArchived);
      });
  }

  const getTeacherClasses = (isArchive: boolean): void => {
    const classSearch: ClassSearchModel = {
      pageNumber: DEFAULT_PAGE_NUMBER,
      pageSize: 999,
      showArchive: isArchive ? true : false,
      showCurrent: isArchive || isHidden ? false : true,
      showHidden: isHidden,
      userId: props.userContext?.userId,
      schoolId: props.userContext?.schoolId,
      schoolAccountId: props.userContext.schoolAccountId,
    };
    setShowLoader(true);
    ClassApi.getClassList(classSearch).then((d) => {
      setShowLoader(false);
      setClasses(d.data);
    });
  }

  const validateShowGooglePopup = () => {
    if (classDetail.isCanvasEnabled) {
      toast.warning(
        "Cannot connect to Google Classroom because this class is already mapped with Canvas."
      );
      return false;
    } else if (!validateIsGoogleUser(props?.profile!)) {
      setShowGooglePopup(true);
      return false;
    } else if (!props.profile?.gmailId) {
      setShowGoogleMappingPopup(true);
      return false;
    }
    return true;
  };

  const validateShowCanvasPopup = () => {
    if (classDetail.isGoogleEnabled) {
      toast.warning(
        "Cannot connect to Canvas because this class is already mapped with Google Classroom."
      );
      return false;
    } else if (!validateIsCanvasUser(props.profile!)) {
      setShowCanvasPopup({ isopen: true, mode: "MAPPING" });
      return false;
    }
    return true;
  };

  const unLockArcade = () => {
    classDetail.isGameLocked = false;
    setClassDetail(classDetail);
    updateClassConfirm();
  };

  const isEditingEnabled = () => {
    if (isDemoAccount) {
      return true;
    }
    return classDetail.schoolYearId >= defaultSchoolYearId;
  };

  const handleClassChange = (e): void => {
    const value = e.target.value;
    history.push({
      pathname: RouteConstant.TeacherRoutes.ClassesDetails.replace(
        ":classId",
        value
      ),
    });
    classId = value;
    setMode(PageAccessMode.Edit);
    // window.location.reload();
  }

  const handlePasswordCard = () => {
    setShowPasswordCardPopup(true);
  };

  const handleArchieveClass = () => {
    setConfirmationType(ConfrimationType.ArchiveClass);
    showConfirmation(
      "Please confirm",
      "Are you sure you want to " +
        (classDetail.isArchived ? "unarchvie" : "archive") +
        " this class?",
      ConfrimationType.ArchiveClass
    );
  };

  const handleArcadeOrAvatar = () => {
    const isLocked: boolean = isGameLocked(
      classDetail.isGameLocked,
      classDetail.avatarLockStartTime,
      classDetail.avatarLockEndTime
    );
    if (isLocked) {
      showConfirmation(
        "Please confirm",
        "You are unlocking the Game Arcade for the students within this class. Are you sure?",
        ConfrimationType.UnlockArcade
      );
    } else {
      setShowArcadeAvatarPopup(true);
    }
  };

  const handleDeleteClass = () => {
    const confirmationMsg = `You are about to delete this class and all of the associations. This action cannot be undone. Are you sure you want to delete this class?
    ${
      classDetail.classExternalRoster &&
      classDetail.classExternalRoster.length > 0
        ? "Note for auto-rostered classes: this action will not work. You must select to ‘Hide’ the class from the Class Management screen."
        : ""
    }`;
    setConfirmationType(ConfrimationType.DeleteClass);
    showConfirmation(
      "Please confirm",
      confirmationMsg,
      ConfrimationType.DeleteClass
    );
  };

  const handleClassSetting = (setting: string) => {
    setClassSetting(setting);
  };

  const handleSaveClassArcadeAvatar = (classDetails: ClassModel) => {
    setClassDetail(classDetails);
    setShowArcadeAvatarPopup(false);
    setClassDetail(classDetail);
    updateClassConfirm();
  };

  const handleConnectGoogleClassroom = () => {
    if (validateShowGooglePopup()) {
      setShowConnectToGooglePopup(true);
    }
  };

  const handleDisconnectGoogleClassroom = () => {
    confirm({
      variant: "danger",
      title: "Please confirm",
      description:
        "Are you sure you want to disconnect this class from Google Classroom?",
    }).then((d) => {
      GoogleApi.disconnect(classDetail.classId).then((r) => {
        toast.success("Class disconnected from Google Classroom");
        getClassDetails(classDetail.classId);
      });
    });
  };

  const handleConnectCanvas = () => {
    if (validateShowCanvasPopup()) {
      setShowConnectToCanvasPopup(true);
    }
  };

  const handleDisconnectCanvas = () => {
    confirm({
      variant: "danger",
      title: "Please confirm",
      description:
        "Are you sure you want to disconnect this class from Canvas?",
    }).then((d) => {
      if (classDetail.sectionId != null) {
        CanvasApi.disconnectSection(
          classDetail.classId,
          classDetail.sectionId!
        ).then((r) => {
          toast.success("Class disconnected from Canvas");
          getClassDetails(classDetail.classId);
        });
      } else {
        CanvasApi.disconnectClass(
          classDetail.classId,
          classDetail.canvasCourseId
        ).then((r) => {
          toast.success("Class disconnected from Canvas");
          getClassDetails(classDetail.classId);
        });
      }
    });
  };

  useEffect(() => {
    userApi.GetUserDetail(props?.userContext?.userId).then((res) => {
      if (res.data) {
        setIsDemoAccount(res.data.isDemo);
      }
    });
    getTeacherClasses(isArchived);
    if (classId !== undefined && classId !== "new") {
      getClassDetails(classId);
      getPrimaryClassTeacher(classId);
      setClassSetting(Constant.ClassSettings.STUDENT);
    } else {
      setMode(PageAccessMode.Add);
      setClassSetting("");
    }
  }, [classId, props.match.params.classId]);

  useEffect(() => {
    if (
      classDetail.isGoogleEnabled &&
      classDetail.courseId &&
      validateIsGoogleUser(props?.profile!)
    ) {
      getGoogleUnMappedStudents(classDetail.classId, classDetail.courseId);
    }
  }, [classDetail.courseId]);

  useEffect(() => {
    if (
      classDetail.isCanvasEnabled &&
      classDetail.canvasCourseId &&
      validateIsCanvasUser(props?.profile!)
    ) {
      if (classDetail.sectionId) {
        getSectionStudentsToImport(
          classDetail.classId,
          classDetail.canvasCourseId,
          classDetail.sectionId
        );
      } else {
        getCanvasUnMappedStudents(
          classDetail.classId,
          classDetail.canvasCourseId
        );
      }
    }
  }, [classDetail.canvasCourseId]);

  useEffect(() => {
    if (schoolYearData && schoolYearData?.length > 0) {
      var currentSchoolYear: ISchoolYear = schoolYearData.filter(
        (x) => x.currentYearOffset === 0
      )[0];
      setDefaultSchoolYearId(currentSchoolYear.schoolYearId);
      if (classId !== undefined && classId === "new") {
        setSelectedSchoolYearId(currentSchoolYear.schoolYearId);
      }
    }
  }, [schoolYearData]);

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === "name" && value.name) {
        setValue("displayName", value.name);        
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, setValue]);

  useEffect(() => {
    GetSchoolYears(props?.userContext?.schoolId!).then((response) => {
      if (response.data) {
        var currentFutureSchoolYears =
          classId !== undefined && classId === "new"
            ? response.data.filter((y) => y.currentYearOffset >= 0)
            : response.data;
        setSchoolYearData(currentFutureSchoolYears);
        var schoolYears: Array<ISelectElement> = currentFutureSchoolYears.map(
          (d: ISchoolYear) => {
            return {
              label: d.name,
              value: d.schoolYearId.toString(),
            };
          }
        );
        setSchoolYears(schoolYears);
      }
    });

    getOnlineUsers();
  }, []);

  return (
    <AllowedTo
      perform={[
        Permissions.class_management_create,
        Permissions.class_management_modify,
      ]}
      no={() => <AccessDenied />}
    >
      {showLoader && <Loader></Loader>}

      {mode === PageAccessMode.Add ? (
        <ClassBuilder 
          userContext={props?.userContext} 
          profile={props?.profile} 
          match={props?.match} 
          location={props?.location}  />
      ) : (
        <>
        <section className="min-w-0 w-full">
        <ClassDetail_MainForm 
          mode={mode}
          classId={classId}
          classDetail={classDetail}
          classes={classes}
          setClassDetail={setClassDetail}
          addNewClass={addNewClass}
          updateClass={updateClass}
          selectedColor={selectedColor}
          setSelectedColor={setSelectedColor}
          selectedSchoolYearId={selectedSchoolYearId}
          setSelectedSchoolYearId={setSelectedSchoolYearId}
          defaultSchoolYearId={defaultSchoolYearId}
          schoolYears={schoolYears}
          isGoogleEnabled={isGoogleEnabled}
          setIsGoogleEnabled={setIsGoogleEnabled}
          isCanvasEnabled={isCanvasEnabled}
          setIsCanvasEnabled={setIsCanvasEnabled}
          validateShowGooglePopup={validateShowGooglePopup}
          subjectCount={subjectCount}
          register={register}
          handleSubmit={handleSubmit}
          getValues={getValues}
          errors={errors}
          handleClassChange={handleClassChange}
          userContext={props.userContext}
          profile={props.profile}
        />
        {(mode === PageAccessMode.Edit || mode === PageAccessMode.Readonly) &&
          props.userContext?.impersonatedUser == null && (
            <div className="lg:border-b lg:border-gray-200 border-t">
              <nav
                className=" px-4 sm:pl-6 lg:pl-8 lg:mb-5 xl:mb-0 pr-0"
                aria-label="Progress"
              >
                <ol className="rounded-md overflow-hidden flex flex-col xl:flex-row items-left justify-start xl:justify-between pr-8 lg:border-gray-200 lg:rounded-none">
                  <ClassFeature
                    handlePasswordCard={handlePasswordCard}
                    handleArchieveClass={handleArchieveClass}
                    handleArcadeOrAvatar={handleArcadeOrAvatar}
                    handleConnectGoogleClassroom={handleConnectGoogleClassroom}
                    handleDisconnectGoogleClassroom={
                      handleDisconnectGoogleClassroom
                    }
                    handleConnectCanvas={handleConnectCanvas}
                    handleDisconnectCanvas={handleDisconnectCanvas}
                    handleClassPassword={() => {
                      setShowClassPassword(true);
                    }}
                    handleDeleteClass={handleDeleteClass}
                    handleUpdateClass={() => {
                      updateClass();
                    }}
                    handleTransferStudent={() => {
                      setShowTransferStudent(true);
                    }}
                    handleImportStudentClassData={() => {
                      setShowImportStudentClassData(true);
                    }}
                    isArchived={classDetail.isArchived}
                    isGoogleEnabled={classDetail.isGoogleEnabled}
                    isCanvasEnabled={classDetail.isCanvasEnabled}
                    isReadOnly={mode === PageAccessMode.Readonly}
                    higherGradeStudentCount={
                      classDetail.higherGradeStudentCount
                    }
                    isGameLocked={classDetail.isGameLocked}
                    lockStartTime={classDetail.avatarLockStartTime}
                    lockEndTime={classDetail.avatarLockEndTime}
                    primaryTeacher={primaryTeacher}
                    isEditingEnabled={isEditingEnabled()}
                  />
                </ol>
              </nav>
            </div>
          )}
        <div className="sm:px-6  lg:px-8 sm:py-6">
          {
            (studentCount === 0 ||
              teacherCount === 0 ||
              subjectCount === 0) && (
              <WarningMessage message="Your class setup is pending, please complete the section(s) marked below." />
            )}

          <ClassDetail_SettingTabs 
            handleClassSetting={handleClassSetting}
            mode={mode}
            studentCount={studentCount}
            subjectCount={subjectCount}
            teacherCount={teacherCount}
            unMappedStudentCount={unMappedStudentCount}
            showStudentMapping={classDetail.isGoogleEnabled || classDetail.isCanvasEnabled}
            showCanvasSection={classDetail.isCanvasEnabled}
          />

          <div className=" mx-auto lg:grid lg:grid-cols-12 lg:gap-0 mt-2 px-4 lg:px-0">
            <div className="min-w-0 block lg:col-span-12 xl:col-span-12 w-full  lg:px-5">
              <div className="bg-gray-100  w-full mb-5 rounded-lg p-5 h-full ">
                {(mode === PageAccessMode.Edit ||
                  mode === PageAccessMode.Readonly) && (
                  <div className="bg-white py-5 px-5 w-full shadow hover:shadow-lg">
                    <div>
                      {selectedClassSetting ===
                        Constant.ClassSettings.STUDENT && (
                        <ClassStudent
                          key={classDetail.classId}
                          classId={classDetail.classId}
                          schoolAccountId={props.userContext.schoolAccountId}
                          mode={
                            classDetail.isArchived
                              ? PageAccessMode.Readonly
                              : mode
                          }
                          className={classDetail.displayName}
                          onlineUsers={onlineUsers}
                          setStudentCount={(e) => {
                            setStudentCount(e);
                            getClassDetails(classId);
                          }}
                          isGoogleEnabled={isGoogleEnabled}
                          isCanvasEnabled={isCanvasEnabled}
                          googleClassId={classDetail.googleClassId}
                          courseId={classDetail.courseId}
                          canvasCourseId={classDetail.canvasCourseId}
                          shouldUpdateKey={shouldUpdateClassStudentKey}
                          isEditingEnabled={isEditingEnabled()}
                        />
                      )}
                      {selectedClassSetting ===
                        Constant.ClassSettings.TEACHER && (
                        <ClassTeacher
                          classId={classDetail.classId}
                          className={classDetail.displayName}
                          schoolAccountId={props.userContext.schoolAccountId}
                          classExternalRoster={classDetail.classExternalRoster}
                          mode={
                            classDetail.isArchived
                              ? PageAccessMode.Readonly
                              : mode
                          }
                          onlineUsers={onlineUsers}
                          setTeacherCount={(e) => {
                            setTeacherCount(e);
                          }}
                          setPrimaryTeacher={setPrimaryTeacher}
                          primaryTeacher={primaryTeacher}
                          isEditingEnabled={isEditingEnabled()}
                        />
                      )}
                      {selectedClassSetting ===
                        Constant.ClassSettings.SETTINGS && (
                        <ClassStudentSetting
                          classId={classDetail.classId}
                          mode={
                            classDetail.isArchived
                              ? PageAccessMode.Readonly
                              : mode
                          }
                          isEditingEnabled={isEditingEnabled()}
                        />
                      )}
                      {selectedClassSetting ===
                        Constant.ClassSettings.LIFTOFFSETTINGS && (
                        <LiftOffSetting
                          classId={classDetail.classId}
                          schoolAccountId={props.userContext.schoolAccountId}
                          mode={
                            classDetail.isArchived
                              ? PageAccessMode.Readonly
                              : mode
                          }
                        />
                      )}
                      {selectedClassSetting ===
                        Constant.ClassSettings.SUBJECTS && (
                        <ClassSubject
                          classId={classDetail.classId}
                          schoolAccountId={props.userContext.schoolAccountId}
                          mode={
                            classDetail.isArchived
                              ? PageAccessMode.Readonly
                              : mode
                          }
                          setSubjectCount={(e) => {
                            setSubjectCount(e);
                          }}
                          classExternalRoster={classDetail.classExternalRoster}
                          isEditingEnabled={isEditingEnabled()}
                        />
                      )}
                      {selectedClassSetting ===
                        Constant.ClassSettings.STUDENTMAPPING &&
                        classDetail.isGoogleEnabled && (
                          <GoogleClassStudentMapping
                            classId={classDetail.classId}
                            courseId={classDetail.courseId}
                            mappingStudentCount={(cnt) => {
                              setUnMappedStudentCount(cnt);
                            }}
                            onMappingCompleted={() => {
                              getClassDetails(classDetail.classId);
                            }}
                          />
                        )}
                      {selectedClassSetting ===
                        Constant.ClassSettings.STUDENTMAPPING &&
                        classDetail.isCanvasEnabled && (
                          <CanvasClassStudentMapping
                            courseId={classDetail.canvasCourseId}
                            classId={classDetail.classId}
                            sectionId={classDetail.sectionId}
                            mappingStudentCount={(cnt) => {
                              setUnMappedStudentCount(cnt);
                            }}
                            onMappingCompleted={() => {
                              getClassDetails(classDetail.classId);
                            }}
                          />
                        )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      {showArcadeAvatarPopup && (
        <ArcadeAvatarPopup
          showPopUp={(show) => {
            setShowArcadeAvatarPopup(show);
          }}
          classDetail={classDetail}
          handleSave={handleSaveClassArcadeAvatar}
        />
      )}
      {showPasswordCardPopup && (
        <PasswordCardPopup
          classId={classDetail.classId}
          showPopUp={(show) => {
            setShowPasswordCardPopup(show);
          }}
        />
      )}
      {showClassPassword && (
        <ClassPasswordPopup
          showPopUp={(show) => {
            setShowClassPassword(show);
          }}
          password={classDetail.passwordHash}
          setPassword={(pwd) => {
            classDetail.passwordHash = pwd;
            setClassDetail(classDetail);
            updateClassConfirm();
            setShowClassPassword(false);
          }}
        />
      )}
      {showTransferStudent &&
        !classDetail.isGoogleEnabled &&
        !classDetail.isCanvasEnabled && (
          <TransferStudentPopup
            sourceClassId={classId}
            sourceClassName={classDetail.name}
            showPopUp={(show) => {
              setShowTransferStudent(show);
            }}
            studentTransferCallback={() => {
              setShouldUpdateClassStudentKey(shouldUpdateClassStudentKey + 1);
            }}
          />
        )}
      {showImportStudentClassData &&
        !classDetail.isGoogleEnabled &&
        !classDetail.isCanvasEnabled && (
          <ImportStudentClassDataPopup
            currentClassId={classId}
            currentClassName={classDetail.name}
            showPopUp={(show) => {
              setShowImportStudentClassData(show);
            }}
          />
        )}
      <section>
        <ConfirmationDialog
          open={openConfirmation}
          confirmationTitle={confirmationTitle}
          confirmationMessage={confirmationMessage}
          onCancelClick={confirmationCancelClose}
          onOkClick={confirmationOkClose}
        ></ConfirmationDialog>
      </section>
      <ReactTooltip />
      {showGooglePopup && (
        <GoogleSignInModal
          roleId={props.userContext.roleId}
          returnUrl={
            mode === PageAccessMode.Readonly
              ? RouteConstant.TeacherRoutes.NewClass
              : RouteConstant.TeacherRoutes.ClassesDetails.replace(
                  ":classId",
                  classId.toString()
                )
          }
          mode={Constant.GoogleSignInMode.SSO}
          showPopUp={setShowGooglePopup}
        />
      )}
      {showGoogleMappingPopup && (
        <GoogleMappingModal
          roleId={props.userContext.roleId}
          returnUrl={
            mode === PageAccessMode.Readonly
              ? RouteConstant.TeacherRoutes.NewClass
              : RouteConstant.TeacherRoutes.ClassesDetails.replace(
                  ":classId",
                  classId.toString()
                )
          }
          showPopUp={setShowGoogleMappingPopup}
        />
      )}
      {showCanvasPopup.isopen && (
        <CanvasSignInModal
          mappingMode={showCanvasPopup.mode as any}
          returnUrl={
            mode === PageAccessMode.Readonly
              ? RouteConstant.TeacherRoutes.NewClass
              : RouteConstant.TeacherRoutes.ClassesDetails.replace(
                  ":classId",
                  classId.toString()
                )
          }
          showPopUp={(show) => {
            setShowCanvasPopup({ isopen: show, mode: showCanvasPopup.mode });
          }}
          accountLoginName={props.profile?.accountLoginName!}
        />
      )}

      {showConnectToGoogle && (
        <ConnectToGoogleClassPopup
          classId={classDetail.classId}
          googleCourseId={classDetail.courseId}
          onSuccess={() => {
            getClassDetails(classDetail.classId);
          }}
          showPopup={setShowConnectToGooglePopup}
        />
      )}

      {showConnectToCanvas && (
        <ConnectToCanvasClassPopup
          classId={classDetail.classId}
          canvasCourseId={classDetail.canvasCourseId}
          onSuccess={() => {
            getClassDetails(classDetail.classId);
          }}
          showPopup={setShowConnectToCanvasPopup}
        />
      )}
        </>
      )}
      
    </AllowedTo>
  );
};

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(ClassDetail);

import classNames from "classnames";
import { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { getTestComparisonStudents } from "../../../../../api/report/testComparisonReport";
import PagingResponse from "../../../../../model/common/pagingResponse";
// import { Info } from "../../../../../assets/icons";
import { UserContextState } from "../../../../../redux/actions/userContextAction";
import constant from "../../../../../utils/constant/constant";
import {
  DEFAULT_PAGE_NUMBER,
  DEFAULT_PAGE_SIZE,
} from "../../../../../utils/pagingConstant";
import Loader from "../../../../shared/loader";
import Pagination from "../../../../shared/pagination";
import PageSize from "../../../../shared/pagination/pageSize";
import IndividualStudentsDetail from "./individualStudentsDetail";

interface IndividualStudentsProps {
  selectedTab: string;
  subjectId: number;
  preAssessmentId: number | null;
  preDiagnosticTestId: number | null;
  preAssessmentName: string;
  postAssessmentId: number | null;
  postDiagnosticTestId: number | null;
  postAssessmentName: string;
  classIds: number | null;
  schoolIds: string | null;
  userContext?: UserContextState;
  setEnableExport: (e) => void;
  schoolYearId: number | null;
  refreshCounter: number | 0;
}
function IndividualStudents(props: IndividualStudentsProps) {
  const [students, setStudents] = useState<PagingResponse<any>>();
  const [pageNumber, setPageNumber] = useState(DEFAULT_PAGE_NUMBER);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [showDetails, setShowDetails] = useState({});
  const districtUserTabOptions: any = {
    DistrictAssessment: "District",
    SchoolAssessment: "School",
  };

  useEffect(() => {
    getStudent();
  }, [
    pageNumber,
    pageSize,
    props.preAssessmentId,
    props.preDiagnosticTestId,
    props.postAssessmentId,
    props.postDiagnosticTestId,
    props.classIds,
    props.schoolIds,
  ]);

  useEffect(() => {
    getStudent();
  }, [props.refreshCounter]);

  useEffect(() => {
    setPageNumber(DEFAULT_PAGE_NUMBER);
    setPageSize(DEFAULT_PAGE_SIZE);
    getStudent();
  }, [
    props.preAssessmentId,
    props.preDiagnosticTestId,
    props.postAssessmentId,
    props.postDiagnosticTestId,
    props.classIds,
  ]);

  const onPageChange = (pageNo) => {
    setPageNumber(pageNo);
  };

  const onPageSizeChange = (pageSize) => {
    setPageNumber(DEFAULT_PAGE_NUMBER);
    setPageSize(pageSize);
  };

  function getStudent() {
    setShowLoading(true);
    getTestComparisonStudents(
      props.subjectId,
      props.preAssessmentId,
      props.preDiagnosticTestId,
      props.postAssessmentId,
      props.postDiagnosticTestId,
      props.userContext?.userId ?? 0,
      props.classIds,
      props.schoolIds,
      props.schoolYearId,
      pageNumber,
      pageSize
    )
      .then((d) => {
        setStudents(d.data);
        setShowLoading(false);
        props.setEnableExport(d.data && d.data.totalRecords > 0);
      })
      .finally(() => {
        setShowLoading(false);
      });
  }

  function getClassOrSchoolName(student: any) {
    if (
      props.userContext?.roleId === constant.UserRoleId.District &&
      props.selectedTab === districtUserTabOptions.DistrictAssessment
    ) {
      return student.schoolName;
    } else if (student.classes && student.classes.length > 0) {
      var classNames: string = "";
      student.classes?.forEach((cl) => {
        classNames =
          classNames +
          "<div class='mb-2'> <span>" +
          cl.firstName +
          ", " +
          cl.lastName +
          "<span><br /> <i>" +
          cl.className +
          "</i></div>";
      });
      return classNames;
    } else {
      return "";
    }
  }

  const toggleDetails = (id) => {
    setShowDetails((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  return (
    <div>
      <div className="overflow-auto shadow">
        {showLoading && <Loader />}
        <table className="table w-full mt-5">
          <thead>
            <tr className="bg-light-violet text-white py-3 px-3">
              <th className="text-left p-3 font-normal w-[25%]">Student</th>
              <th className="text-left p-3 font-normal w-[30%]">
                {props.userContext?.roleId === constant.UserRoleId.District &&
                props.selectedTab === districtUserTabOptions.DistrictAssessment
                  ? "School"
                  : "Class"}
              </th>
              <th className="text-center p-3 font-normal w-[16%]">
                {props.preAssessmentName}
              </th>
              <th className="text-center p-3 font-normal w-[16%]">
                {props.postAssessmentName}
              </th>
              <th className="w-[13%]"></th>
            </tr>
          </thead>
          <tbody>
            {students &&
              students?.data?.map((student, index) => {
                return (
                  <Fragment key={index}>
                    <tr
                      className={classNames(
                        "hover:bg-light-violet/20 transition ease-in-out duration-300",
                        index % 2 === 0 ? "bg-white" : "bg-light-violet/10"
                      )}
                    >
                      <td className="p-3 text-sm text-gray-500 text-left w-[25%]">
                        {student.lastName}, {student.firstName}
                      </td>
                      <td className="p-3 text-sm text-gray-500 text-left w-[30%]">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: getClassOrSchoolName(student),
                          }}
                        ></div>
                      </td>
                      <td className="p-3 text-sm text-gray-500 text-center w-[16%]">
                        {student.preAssessmentScore &&
                          student.preAssessmentScore}
                      </td>
                      <td className="p-3 text-sm text-gray-500 text-center w-[16%]">
                        {student.postAssessmentScore &&
                          student.postAssessmentScore}
                      </td>
                      <td className="p-3 text-sm text-gray-500 text-center w-[13%]">
                        <button
                          className="bg-primary-violet border border-transparent shadow-sm py-1 px-3 inline-flex justify-center text-sm font-medium text-white hover:bg-dark-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3"
                          onClick={() => {
                            toggleDetails(student.userId);
                          }}
                        >
                          {showDetails[student.userId] ? "hide" : "view"}
                        </button>
                      </td>
                    </tr>
                    {showDetails[student.userId] && (
                      <tr
                        className={classNames(
                          "hover:bg-light-violet/20 transition ease-in-out duration-300",
                          index % 2 === 0 ? "bg-white" : "bg-light-violet/10"
                        )}
                      >
                        <td colSpan={5} key={student.userId}>
                          <IndividualStudentsDetail
                            subjectId={props.subjectId}
                            schoolYearId={props.schoolYearId}
                            preActivitySessionId={
                              student.preTestActivitySessionId
                            }
                            postActivitySessionId={
                              student.postTestActivitySessionId
                            }
                            preTestName={props.preAssessmentName}
                            postTestName={props.postAssessmentName}
                          />
                        </td>
                      </tr>
                    )}
                  </Fragment>
                );
              })}
          </tbody>
        </table>
      </div>
      <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between pt-4 border-t-2 mt-5">
        {students &&
          students?.totalRecords !== undefined &&
          students?.totalRecords !== 0 && (
            <>
              <Pagination
                totalResults={students.totalRecords}
                resultsPerPage={pageSize}
                onChange={(e) => {
                  onPageChange(e);
                }}
                label=""
              />

              <PageSize
                pageSize={pageSize}
                onPageSizeChange={onPageSizeChange}
              />
            </>
          )}
      </div>
    </div>
  );
}

export default connect((state: any) => {
  return {
    userContext: state.userContext,
  };
})(IndividualStudents);

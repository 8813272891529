import { AxiosResponse } from "axios";
import { AxiosHelper } from "../environment/axiosConfig";
import { ISubject } from "../model/interface/subject";
import APIConstant from "../utils/constant/apiConstant";
import { IResult } from "../model/interface/IResult";

export function getSubjectDetail(
  subjectId: number
): Promise<AxiosResponse<ISubject>> {
  return AxiosHelper.axiosInstance().get(
    `${APIConstant.CMS_API.SubjectDetail}/${subjectId}`,
    {
      params: {},
    }
  );
}

export const getUserLiftoffSubjects = (): Promise<
  AxiosResponse<Array<any>>
> => {
  return AxiosHelper.axiosInstance().get<Array<any>>(
    APIConstant.SUBJECT_API.GetUserLiftOffSubjects,
    {
      params: {},
    }
  );
};

export const getUserHasLiftoffAccess = (): Promise<AxiosResponse<IResult<boolean>>> =>
AxiosHelper.axiosInstance().get<IResult<boolean>>(
  APIConstant.SUBJECT_API.GetUserHasLiftOffSubjectAccess,
  {
    params: {},
  }
)

export const getUserDiagnosticSubjects = (): Promise<
  AxiosResponse<Array<any>>
> => {
  return AxiosHelper.axiosInstance().get<Array<any>>(
    APIConstant.SUBJECT_API.GetUserDiagnosticSubjects,
    {
      params: {},
    }
  );
};

export function getContentAreaBySubjectId(
  subjectId: number
): Promise<AxiosResponse<any>> {
  return AxiosHelper.axiosInstance().get<any>(
    APIConstant.SUBJECT_API.GetContentAreaBySubjectId,
    {
      params: { SubjectId: subjectId },
    }
  );
}

/* eslint-disable react/prop-types */
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import SubjectSelection from "./studyPlanGradeSubjects";
import IStudyPlanSubjects from "../../../../../model/student/studyPlanSubjects";
import { DownArrowIcon } from "../../../../../assets/icons";
import { getStudyPlanStudentSubjects } from "../../../../../api/student/studentSubjectApi";
import { connect } from "react-redux";
import Loader from "../../../../shared/loader";
import RouteConstant from "../../../../../utils/constant/routeConstant";
import Breadcrumb from "../../middleSchool/breadcrumb";
import { IBreadcrumb } from "../../../../../model/common/breadcrumb";
import { useTranslation } from "react-i18next";

function StudyPlanSubject(props: any): JSX.Element {
  const [toggleAdditionalSubject, setToggleAdditionalSubject] =
    useState<boolean>(false);
  const [subjects, setSubjects] = useState<IStudyPlanSubjects[]>([]);
  const [additionalSubjects, setAdditionalSubjects] = useState<
    IStudyPlanSubjects[]
  >([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedSubject, setSelectedSubject] = useState<number>(0);
  const history = useHistory();
  const { t } = useTranslation();

  const toggleHandler = () => {
    setToggleAdditionalSubject(!toggleAdditionalSubject);
  };

  function loadSubjects() {
    setLoading(true);
    getStudyPlanStudentSubjects(props.userContext?.userId ?? 0).then((d) => {
      setLoading(false);
      setSubjects(
        d.data.filter((item) => {
          return item.isAdditionalSubject === false;
        })
      );
      setAdditionalSubjects(
        d.data.filter((item) => {
          return item.isAdditionalSubject === true;
        })
      );
    });
  }

  useEffect(() => {
    loadSubjects();
  }, []);

  const onSubjectSelect = (id, name?) => {
    if (window.location.pathname.includes("instructionalResources")) {
      const selectedSubject = { value: id, label: name };
      history.push({
        pathname: RouteConstant.TeacherRoutes.InstructionalResources,
        state: { selectedSubject: selectedSubject },
      });
    } else if (window.location.pathname.includes("recommendedTopics")) {
      props.selectedSubject({ value: id, label: name });
      props.showSubjects(false);
    } else {
      setSelectedSubject(id);
      history.push({
        pathname: RouteConstant.MiddleSchool.standards.replace(":id", id),
        state: { subjectName: name },
      });
    }
  };
  const breadcrumbItems = () => {
    const items: Array<IBreadcrumb> = [
      {
        name: t("Breadcrumb.Home"),
        url: RouteConstant.MiddleSchool.assignments,
        textColor: "",
      },
      {
        name: t("Breadcrumb.StudyPlanSubjects"),
        textColor: "",
        url: "",
      },
    ];

    return items;
  };
  return (
    <>
      {loading && <Loader />}
      <>
        {selectedSubject === 0 && (
          <>
            {!window.location.pathname.includes("recommendedTopics") && (
              <div className="md:flex md:items-center px-4 py-3 lg:px-8 border-b">
                <Breadcrumb items={breadcrumbItems()} />
                <div className="md:w-[55%] text-lg flex justify-between gap-2 h-auto sm:h-10 items-center">
                  <h1 className="text-gray-50 font-medium flex justify-start">
                    {t("MSSubjectSelection.Subject")}
                  </h1>
                </div>
              </div>
            )}
            <div className="sm:px-6  lg:px-8 py-6">
              <div>
                <div className="w-full mb-12">
                  {subjects.length > 0 && (
                    <SubjectSelection
                      subjects={subjects}
                      type="subjects"
                      onSubjectSelect={onSubjectSelect}
                      profile={props.profile}
                    />
                  )}
                  <ul className="mt-8">
                    <li>
                      <span
                        className="toggle flex justify-start relative"
                        onClick={toggleHandler}
                      >
                        <h2 className="px-4 sm:px-0 text-xl font-medium text-white mb-3 cursor-pointer">
                          {t("MSSubjectSelection.AdditionalSubject")}
                        </h2>
                        <div className="mr-auto pl-4  text-white  cursor-pointer">
                          <DownArrowIcon
                            onClick={toggleHandler}
                            className={`${
                              toggleAdditionalSubject === true
                                ? "rotate-180"
                                : ""
                            } h-6 w-6 transform transition-all duration-300`}
                          />
                        </div>
                      </span>
                      {toggleAdditionalSubject && (
                        <SubjectSelection
                          subjects={additionalSubjects}
                          type="additionalSubjects"
                          onSubjectSelect={onSubjectSelect}
                          profile={props.profile}
                        />
                      )}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </>
        )}
      </>
    </>
  );
}

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(StudyPlanSubject);

import { Configuration } from "../environment/setup";
import { ICanvasAccountSettingDTO } from "../model/canvasClassroom/canvasAccountSettingDTO";
import Constant from "./constant/constant";
import jwt_decode from "jwt-decode";

class Auth {
  private TokenKey = "AuthToken";
  private GoogleAccessTokenKey = "GoogleAccessToken";
  private GoogleRefreshTokenKey = "GoogleRefreshToken";
  private CanvasAccessTokenKey = "CanvasAccessToken";
  private CanvasAccountSettingKey = "CanvasAccountSettings";
  private AccountLoginName = "accountLoginName";
  private isAuthenticatedUser: boolean = false;

  login(token: string) {
    var accountLoginName = localStorage.getItem("accountLoginName");
    const isGooglePopup = window.sessionStorage.getItem("IsGooglePopUp");
    window.sessionStorage.clear();
    const impersonatorId =
      window.localStorage.getItem(Constant.IMPERSONATORKEY) !== null
        ? window.localStorage.getItem(Constant.IMPERSONATORKEY)
        : "";
    const switchContextValue =
      window.localStorage.getItem(Constant.SWITCHEGCONTEXT) !== null
        ? window.localStorage.getItem(Constant.SWITCHEGCONTEXT)
        : "";
    window.localStorage.clear();
    if (impersonatorId && impersonatorId !== "") {
      window.localStorage.setItem(Constant.IMPERSONATORKEY, impersonatorId);
    }

    if (switchContextValue && switchContextValue !== "") {
      window.localStorage.setItem(Constant.SWITCHEGCONTEXT, switchContextValue);
    }

    window.sessionStorage.setItem("IsGooglePopUp", isGooglePopup ?? "");
    window.localStorage.setItem(this.TokenKey, token);
    if (accountLoginName)
      localStorage.setItem("accountLoginName", accountLoginName);
    this.isAuthenticatedUser = true;
  }

  logout() {
    this.clearSession();
    window.open(Configuration.EGBaseUrl, "_self");
  }

  clearSession() {
    const toggle = localStorage.getItem(
      Constant.LocalStorageKeys.ToggleSideMenu
    );
    var accountLoginName = localStorage.getItem("accountLoginName");
    window.sessionStorage.clear();
    window.localStorage.clear();
    localStorage.setItem(
      Constant.LocalStorageKeys.ToggleSideMenu,
      toggle ? toggle : "false"
    );

    this.isAuthenticatedUser = false;
    if (accountLoginName)
      localStorage.setItem("accountLoginName", accountLoginName);
  }

  getAuthToken(): string {
    const authToken = window.localStorage.getItem(this.TokenKey);
    return authToken === null ? "" : authToken;
  }

  getGoogleAccessToken(): string {
    const token = window.localStorage.getItem(this.GoogleAccessTokenKey);
    return token === null ? "" : token;
  }

  getGoogleRefreshToken(): string {
    const token = window.localStorage.getItem(this.GoogleRefreshTokenKey);
    return token === null ? "" : token;
  }

  getCanvasAccessToken(): string {
    const token = window.localStorage.getItem(this.CanvasAccessTokenKey);
    return token === null ? "" : token;
  }

  getCanvasAccountSettings(): ICanvasAccountSettingDTO {
    const data = window.localStorage.getItem(this.CanvasAccountSettingKey);
    const settings = JSON.parse(data!) as ICanvasAccountSettingDTO;
    return settings;
  }

  getAccountLoginName(): string | null {
    return localStorage.getItem(this.AccountLoginName);
  }

  setAccountLoginName(accountLoginName: string) {
    localStorage.setItem(this.AccountLoginName, accountLoginName);
  }

  setCanvasAccountSettings(settings: ICanvasAccountSettingDTO) {
    localStorage.setItem(
      this.CanvasAccountSettingKey,
      JSON.stringify(settings)
    );
  }

  isAuthenticated(): boolean {
    return this.isAuthenticatedUser;
  }

  isTokenExpired() {
    let isTokenExpired = true;
    const token = this.getAuthToken();
    if (token) {
      const decodedJwt: any = jwt_decode(token);
      isTokenExpired = decodedJwt.exp * 1000 < Date.now();
    }
    return isTokenExpired;
  }
}

export default new Auth();

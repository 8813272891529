import { useEffect, useCallback, useState, useRef } from "react";
import QuestionClassification from "../../../../model/teacher/questionClassification";
import { getQuestionClassification } from "../../../../api/teacher/question";
import QuestionExplanationPopup from "../../student/shared/popups/questionExplanationPopup";
import { getQuestionExplanation } from "../../../../api/student/question/explanation";
import { renderClassificationDetailsItemAnalysisHtml } from "../../../../utils/learnosityHelper";
import { TextIcon, VideoIcon } from "../../../../assets/icons";
import {
  ILearnosityItemAnalysis,
  ItemAnalysisItemReferenceMap,
} from "../../../../model/report/LearnosityItemAnalysis";
import { getRequest } from "../../../../api/userActivity";

type questionRefClassificationType = {
  key: number;
  value: QuestionClassification;
};
interface ItemAnalysisProps {
  data: ILearnosityItemAnalysis;
  classification: Array<questionRefClassificationType>;
  language: string;
  standardIds: number[];
}
function LearnosityItemAnalysis(props: ItemAnalysisProps) {
  var reportsApp;
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [textExplanation, setTextExplanation] = useState<string>("");
  const [videoUrl, setVideoUrl] = useState<string>("");
  const [showVideoExplanation, setShowVideoExplanation] =
    useState<boolean>(false);
  const [itemId, setItemId] = useState<string>();
  const [showClassfication, setShowClassfication] = useState<boolean>(false);
  const [itemsArray, setItemArrays] = useState<ItemAnalysisItemReferenceMap[]>(
    []
  );
  const isInitialized = useRef(false);
  let rightItemId: string | null | undefined = "";
  let leftItemId: string | null | undefined = "";
  const questionClassification: Array<questionRefClassificationType> =
    props.classification;
  let itemReferences: string[] = [];
  let sessions: string[] = [];

  function headCellClicked(itemid) {
    setItemId(itemid);
    setTimeout(function () {
      var _all_button = document.querySelector(
        ".lrn-ra-head-cell-top-return-all"
      );
      if (_all_button !== null) {
        _all_button.addEventListener("click", backToGridView);
      }
      var sortHeaders = document.querySelectorAll(".lrn-ra-head-sort  ");
      sortHeaders.forEach((s) => {
        s.addEventListener("click", SortHeaderClickedForDetailedView);
      });
      var mainbodyElement = document.querySelector(".lrn-ra-main-body ");
      mainbodyElement?.addEventListener("click", MainBodyClicked);
      var histogramElement = document.querySelector(
        ".lrn-module-charts-histogram"
      );
      histogramElement?.addEventListener("click", HistogramClicked);
      var leftItemEl = document.querySelector(
        ".lrn-ra-details-header-right-prev "
      );
      leftItemEl?.addEventListener("click", LeftItemClick);
      leftItemId = leftItemEl?.getAttribute("data-item-id");
      var rightItemEl = document.querySelector(
        ".lrn-ra-details-header-right-next "
      );
      rightItemEl?.addEventListener("click", RightItemClick);
      rightItemId = rightItemEl?.getAttribute("data-item-id");

      adjustRowHeight();
    }, 500);
  }

  async function BindElementsWithEvents() {
    var _all_button = document.querySelector(
      ".lrn-ra-head-cell-top-return-all"
    );
    _all_button?.addEventListener("click", backToGridView);
    var sortHeaders = document.querySelectorAll(".lrn-ra-head-sort  ");
    sortHeaders.forEach((s) => {
      s.addEventListener("click", SortHeaderClickedForDetailedView);
    });
    var mainbodyElement = document.querySelector(".lrn-ra-main-body ");
    mainbodyElement?.addEventListener("click", MainBodyClicked);
    var histogramElement = document.querySelector(
      ".lrn-module-charts-histogram"
    );
    histogramElement?.addEventListener("click", HistogramClicked);
    var leftItemEl = document.querySelector(
      ".lrn-ra-details-header-right-prev "
    );
    leftItemEl?.addEventListener("click", LeftItemClick);
    leftItemId = leftItemEl?.getAttribute("data-item-id");
    var rightItemEl = document.querySelector(
      ".lrn-ra-details-header-right-next "
    );
    rightItemEl?.addEventListener("click", RightItemClick);
    rightItemId = rightItemEl?.getAttribute("data-item-id");
    adjustRowHeight();
  }

  function backToGridView() {
    setItemId("");
    setTimeout(function () {
      BindStandardCodeWithQuestionRef();
      itemReferences.forEach((item: string) => {
        var element = document.querySelector(
          "div[data-item-id='" + item + "']"
        ) as HTMLElement;
        element?.addEventListener("mousedown", () => {
          headCellClicked(item);
        });
      });
      adjustRowHeight();
      var sortHeaders = document.querySelectorAll(".lrn-ra-head-sort  ");
      sortHeaders.forEach((s) => {
        s.addEventListener("click", sortHeaderClicked);
        s.addEventListener("mousedown", (e) => e.stopPropagation());
      });
    }, 500);
  }

  function sortHeaderClicked(e) {
    setTimeout(function () {
      var sortHeaders = document.querySelectorAll(".lrn-ra-head-sort  ");
      sortHeaders.forEach((s) => {
        s.addEventListener("click", sortHeaderClicked);
        s.addEventListener("mousedown", (e) => e.stopPropagation());
      });
      itemReferences.forEach((item: string) => {
        var element = document.querySelector(
          "div[data-item-id='" + item + "']"
        ) as HTMLElement;
        element?.addEventListener("mousedown", () => {
          headCellClicked(item);
        });
      });
      BindStandardCodeWithQuestionRef();

      adjustRowHeight();
    }, 100);
  }

  function adjustRowHeight() {
    sessions.forEach((session) => {
      var nameElement = document.querySelector(
        "[data-session-id='" + session + "'][data-lrn-ra-column='student_name']"
      ) as HTMLElement;
      var pointElement = document.querySelector(
        "[data-session-id='" +
          session +
          "'][data-lrn-ra-column='student_points']"
      ) as HTMLElement;
      var answerElements = document.querySelectorAll<HTMLElement>(
        "[data-session-id='" +
          session +
          "'][data-lrn-ra-column='student_answer']"
      );
      if (nameElement?.innerText?.length > 29) {
        const height = 30 * Math.ceil(nameElement.innerText.length / 29) + "px";
        nameElement.style.height = height;
        pointElement.style.height = height;
        answerElements.forEach((i) => {
          i.style.height = height;
        });
      }
    });
  }

  const initialization = useCallback(() => {
    if (isInitialized.current) return;
      
    isInitialized.current = true;
    var callbacks = {
      readyListener: function () {
        var report = reportsApp.getReport("response-analysis-report");

        report.on("load:data", function (data) {
          var itemRefs: string[] = Array.from(
            new Set(data?.items?.map((item) => item.item_id))
          );

          BindStandardCodeWithQuestionRef();

          itemReferences = itemRefs;
          sessions =
            data?.sessions?.length > 0
              ? Array.from(Object.keys(data.sessions))
              : [];

          adjustRowHeight();

          itemRefs.forEach((item) => {
            var element = document.querySelector(
              "div[data-item-id='" + item + "']"
            ) as HTMLElement;
            element?.addEventListener("mousedown", () => {
              headCellClicked(item);
            });
          });

          var sortHeaders = document.querySelectorAll(".lrn-ra-head-sort  ");
          sortHeaders.forEach((s) => {
            s.addEventListener("click", sortHeaderClicked);
            s.addEventListener("mousedown", (e) => e.stopPropagation());
          });
        });
      },
      dataListener: function (data) {},
      errorListener: function (e) {
        // Adds a listener to all error codes.
        console.log("Error Code ", e.code);
        console.log("Error Message ", e.msg);
        console.log("Error Detail ", e.detail);
      },
    };

    const request = {
      reports: [
        {
          id: "response-analysis-report",
          type: "response-analysis-by-item",
          session_ids: props.data?.sessionIds,
          users: props.data?.users,
          item_reference_map: props.data?.itemReferenceMap,
          render: true,
        },
      ],
      label_bundle: {
        item: "Question",
        total: "Results",
      },
    };

    getRequest("reports", JSON.stringify(request)).then(async (response) => {
      if (response.data) {
        reportsApp = window["LearnosityReports"]?.init(
          response.data,
          callbacks
        );
      }
    });
  }, []);

  function BindStandardCodeWithQuestionRef() {
    questionClassification?.forEach(async function (item: any) {
      var id = item.key + "-" + props.language;
      var element = document.querySelector(
        "div[data-item-id='" +
          id +
          "'] .lrn-ra-head-cell-top .lrn-ra-head-cell-top-question .lrn-ra-head-cell-top-question-type"
      ) as HTMLElement;

      let divEl = document.createElement("div");
      divEl.innerHTML = item.value.standardCode;
      divEl.className = "break-all";
      element?.insertAdjacentElement("afterend", divEl);
    });
  }

  async function SortHeaderClickedForDetailedView() {
    setTimeout(function () {
      BindElementsWithEvents();
    }, 500);
  }

  async function MainBodyClicked() {
    setTimeout(function () {
      BindElementsWithEvents();
    }, 500);
  }

  async function HistogramClicked() {
    setTimeout(function () {
      BindElementsWithEvents();
    }, 500);
  }

  async function GetExplanationData() {
    if (itemId) {
      var language = itemId?.includes("teacher") ? "en" : itemId?.split("-")[1];
      getQuestionExplanation(itemId, language, true).then((res: any) => {
        if (res.data) {
          if (res.data.videoUrl) {
            setVideoUrl(res.data.videoUrl);
          }
          if (res.data.textExplanation) {
            setTextExplanation(res.data.textExplanation);
          }
        }
      });
    }
  }

  function ShowExplanation(showVideoExplanation) {
    setShowVideoExplanation(showVideoExplanation);
    setShowPopup(true);
  }

  async function BindClassification() {
    let classification: QuestionClassification = props.classification?.filter(
      (c) => c?.key === parseInt(itemId?.split("-")[0] ?? "0")
    )[0]?.value;
    const questionId = parseInt(itemId?.split("-")[0] ?? "0");
    const questionTypeId = itemId?.indexOf("-teacher") !== -1 ? 2 : 1;
    if (!classification) {
      await getQuestionClassification(
        questionId,
        questionTypeId,
        props.standardIds?.join(","),
        0,
        0
      ).then((res) => {
        if (res.data) {
          const qclassification: QuestionClassification = res.data;
          if (qclassification) classification = qclassification;
        }
      });
    }

    setTimeout(function () {
      var element = document.querySelector("#question-content");
      element?.querySelector("[class^=classification-]")?.remove();
      renderClassificationDetailsItemAnalysisHtml(
        element,
        questionId,
        undefined,
        classification
      );
    }, 500);
  }

  async function LeftItemClick() {
    if (leftItemId) setItemId(leftItemId);
    setTimeout(function () {
      BindElementsWithEvents();
    }, 500);
  }

  async function RightItemClick() {
    if (rightItemId !== null) setItemId(rightItemId);
    setTimeout(function () {
      BindElementsWithEvents();
    }, 500);
  }
  async function GetItemReferenceSequence(sessionId, userId) {
    var sessioncallbacks = {
      dataListener: function (data) {
        var array: ItemAnalysisItemReferenceMap[] = [];
        data[0].data?.items?.forEach((item) => {
          var _item: ItemAnalysisItemReferenceMap = {
            reference: item.item_reference,
            name: "Item " + item.item_number,
          };
          array.push(_item);
        });
        setItemArrays(array);
      },
      errorListener: function (e) {
        // Adds a listener to all error codes.
        console.log("Error Code ", e.code);
        console.log("Error Message ", e.msg);
        console.log("Error Detail ", e.detail);
      },
    };
    const sessionRequest = {
      reports: [
        {
          id: sessionId,
          type: "session-detail-by-item",
          session_id: sessionId,
          user_id: userId,
          render: false,
        },
      ],
    };

    getRequest("reports", JSON.stringify(sessionRequest)).then(
      async (response) => {
        if (response.data) {
          window["LearnosityReports"]?.init(response.data, sessioncallbacks);
        }
      }
    );
  }
  useEffect(() => {
    if (itemId && itemId.length > 0) {
      GetExplanationData();
      setShowClassfication(true);
      BindClassification();
    } else {
      setVideoUrl("");
      setTextExplanation("");
      setShowVideoExplanation(false);
      setShowClassfication(false);
    }
  }, [itemId]);

  useEffect(() => {
    var sessionId =
      props.data?.sessionIds?.length > 0 ? props.data.sessionIds[0] : "";
    var user = props.data?.users?.length > 0 ? props.data.users[0] : null;

    GetItemReferenceSequence(sessionId, user?.id);
  }, []);

  useEffect(() => {
    if (itemsArray.length > 0) {
      props.data.itemReferenceMap = itemsArray;
    }
    initialization();
  }, [itemsArray]);
  return (
    <>
      <div>
        {showPopup && (
          <QuestionExplanationPopup
            showPopUp={(show) => {
              setShowPopup(show);
            }}
            textExplanation={!showVideoExplanation ? textExplanation : ""}
            videoUrl={showVideoExplanation ? videoUrl : ""}
            studentType={"K1"}
            itemReference={itemId}
          />
        )}
        <div className="flex justify-between px-3.5 mt-2 ">
          {showClassfication && (
            <>
              <div id="question-content"></div>
            </>
          )}
          <div className="flex gap-x-3 items-end mb-4">
            {videoUrl.length > 0 && (
              <div>
                <a
                  href="#!"
                  className="flex text-xs items-center gap-x-1 group"
                  onClick={() => ShowExplanation(true)}
                >
                  <VideoIcon className="w-4 h-4 text-gray-500 group-hover:text-gray-700" />
                  <span className="text-primary-violet underline hover:no-underline">
                    Video Explanation
                  </span>
                </a>
              </div>
            )}
            {textExplanation && textExplanation.length > 0 && (
              <div>
                <a
                  href="#!"
                  className="flex text-xs items-center gap-x-1 group"
                  onClick={() => ShowExplanation(false)}
                >
                  <TextIcon className="w-4 h-4 text-gray-700 group-hover:text-gray-900" />
                  <span className="text-primary-violet underline hover:no-underline">
                    Text Explanation
                  </span>
                </a>
              </div>
            )}
          </div>
        </div>
        <div id="response-analysis-report"></div>
      </div>
    </>
  );
}
export default LearnosityItemAnalysis;

import { connect } from 'react-redux';
import { Fragment, useState } from "react"
import { IGameSessionRequest, IGameSessionTeamNamesRequest, IGameSessionUpdateRequest, ISelectedStandard } from "../../../../../model/interface/classroomGame/thinkOrSwimSetup"
import Toggle from "../../../../shared/toggle"
import AnglerfishButton from "./anglerfishButton";
import { CreateGameSession, SaveGameSessionTeamNames, UpdateGameSession, setupAction } from '../../../../../api/classroomGame/thinkOrSwim';
import { toast } from 'react-toastify';
import Loader from '../../../../shared/loader';
import { useHistory } from 'react-router-dom';
import routeConstant from '../../../../../utils/constant/routeConstant';

interface ITOSGameSetupInstructions {
  sessionName: string,
  teamNames: string[],
  selectedSubject: Array<{label: string, value: number }>,
  selectedStandards: ISelectedStandard[],
  setupScreenAction: setupAction,
  includeReefRecess: boolean,
  setIncludeReefRecess: (value: boolean) => void,
  selectedClassroomGameSessionId: number,
  onSave: () => void,
}
const ThinkOrSwimGameId = 1;

function TOSGameSetupInstructions(props: ITOSGameSetupInstructions | any) {

  const {
    sessionName,
    teamNames,
    selectedSubject,
    selectedStandards,
    includeReefRecess,
    setIncludeReefRecess,
    selectedClassroomGameSessionId,
    onSave,
    userContext
  } = props;

  const setupScreenAction: setupAction = props.setupScreenAction;

  const history = useHistory();
  const [showLoading, setShowLoading] = useState<boolean>(false);

  const startGame = (classroomGameSessionId: number) => {
    history.push({
      pathname: routeConstant.ClassroomGame.ThinkOrSwim,
      search: `?sessionId=${classroomGameSessionId}`
    });
  }

  const returnToSessionsList = () => {
    onSave();
  }

  const saveNewSession = (start: boolean) => {
    const gameSession: IGameSessionRequest = {
      classroomGameId: ThinkOrSwimGameId,
      name: sessionName,
      subjectId: selectedSubject[0].value,
      standardIds: selectedStandards.map((standard: ISelectedStandard) => standard.standardId),
      includeMiniBreaks: includeReefRecess,
      createdByUserId: userContext.userId,
    }

    setShowLoading(true);
    CreateGameSession(gameSession).then(response => {
      const newClassroomGameSessionId = response.data.classroomGameSessionId;
      const sessionTeamNames: IGameSessionTeamNamesRequest = {
        classroomGameSessionId: newClassroomGameSessionId,
        names: teamNames,
      }
      SaveGameSessionTeamNames(sessionTeamNames).then(_response => {
        setShowLoading(false);
        if(start) 
          startGame(newClassroomGameSessionId);
        else
          returnToSessionsList();
      }).catch(error => {
        setShowLoading(false);
        toast.error(`${error.message} Cannot add team names to game session`);
      })
    }).catch(error => {
      setShowLoading(false);
      toast.error(`${error.message} Cannot create game session`);
      console.error({error});
    })
  }

  const updateSession = (start: boolean) => {
    const gameSession: IGameSessionUpdateRequest = {
      classroomGameSessionId: selectedClassroomGameSessionId,
      classroomGameId: ThinkOrSwimGameId,
      name: sessionName,
      subjectId: selectedSubject[0].value,
      standardIds: selectedStandards.map((standard: ISelectedStandard) => standard.standardId),
      includeMiniBreaks: includeReefRecess,
      updatedByUserId: userContext.userId,
    }

    setShowLoading(true);
    UpdateGameSession(gameSession).then(response => {
      const sessionTeamNames: IGameSessionTeamNamesRequest = {
        classroomGameSessionId: selectedClassroomGameSessionId,
        names: teamNames,
      }
      SaveGameSessionTeamNames(sessionTeamNames).then(_response => {
        setShowLoading(false);
        if (start)
          startGame(selectedClassroomGameSessionId);
        else 
          returnToSessionsList();
      }).catch(error => {
        setShowLoading(false);
        toast.error(`${error.message} Cannot update team names to game session`);
      })
    }).catch(error => {
      setShowLoading(false);
      toast.error(`${error.message} Cannot update game session`);
      console.error({error});
    })
  }

  const handleContinue = (start: boolean) => {
    switch (setupScreenAction) {
      case "Create":
      case "Duplicate":
        saveNewSession(start);
        break;
    
      case "Edit":
        updateSession(start);
        break;
    }
  }

  return (
    <Fragment>
      {showLoading && (<Loader />)}
      <div className="flex flex-row justify-between">
        <div className="w-full">
          <p className="text-gray-500 my-4">Students should be grouped into teams of 3-5 students. Each team will take a turn selecting a shell to reveal a question. All teams will respond by holding up their answer when the host calls time. When you have all team responses, reveal the answer and mark which team(s) have earned points for the round. If the host has chosen to include Reef Recess, then each team will perform the actions on the card for a chance to earn additional points. The game ends when all questions have been answered or when time is up!</p>
          <p className="text-primary-violet text-lg">Session Name:</p>
          <p className="text-gray-500">{sessionName}</p>
          <p className="text-primary-violet text-lg mt-4">Teams:</p>
          {teamNames.map((teamName: string, index: number) => (
            <p key={index} className="text-gray-500">{teamName}</p>
          ))}
          <p className="text-primary-violet text-lg mt-4">Standards:</p>
          {selectedStandards.map((standard: ISelectedStandard, index: number) => (
            <p key={index} className="text-gray-500">{standard.standardCode} {standard.standardName}</p>
          ))}
          <div className="flex flex-row mt-4">
            <div className="mt-1 mr-2">
              <Toggle 
                checked={includeReefRecess} 
                onChange={() => {setIncludeReefRecess((prevState: boolean) => !prevState)}} 
              />
            </div>
            <div className="text-sm text-gray-500">
              Include Reef Recess mini break challenges
            </div>
          </div>
        </div>
        <div className='w-[42rem]'>
          <AnglerfishButton 
            text="Save for Later"
            textSize={1.0}
            canTextShink
            onClick={() => handleContinue(false)}
            textSecondOption="Save and Begin"
            onClickSecondOption={() => handleContinue(true)}
          />
        </div>
      </div>
    </Fragment>
  )
}

export default connect((state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
})(TOSGameSetupInstructions)

import ReactTooltip from "react-tooltip";

export default function ReportButton(props: { caption: string, disabledTooltip?: string, enabled: boolean, onClick: () => void }) {
    return <p
        className="cursor-pointer"
        data-tip
        data-for="incompleteSelectionExport"
    >
        {!props.enabled && (
            <ReactTooltip
                id="incompleteSelectionExport"
                class="bg-secondary-teal/10 z-50 "
                effect="solid"
            >
                <span>{props.disabledTooltip}</span>
            </ReactTooltip>
        )}
        <div className="cursor-not-allowed">
            <button
                onClick={props.onClick}
                className={
                    "bg-primary-violet border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 " +
                    (!props.enabled
                        ? "bg-opacity-20 text-primary-violet text-opacity-40 pointer-events-none"
                        : "hover:bg-dark-violet hover:shadow-lg")
                }
            >
                {props.caption}
            </button>
        </div>
    </p>;
}
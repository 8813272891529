import { Fragment, useEffect, useState } from "react";
import Select from "react-select";
import DatePicker from "react-datepicker";
import MyAssignmentDetails from "../../../../model/teacher/assignment";
import {
  getAssignmentStudents,
  getAssignments,
  getAssignmentClasses,
  getAssignmentSubjects,
  updateAssignments,
  duplicateAssignment,
  getAssignmentById,
} from "../../../../api/teacher/assignment";
import Moment from "moment";
import {
  EditIcon,
  OutlineSettingIcon,
  LockIcon,
  UnLockIcon,
  DeleteIcon,
  ArchieveIcon,
  GroupAssignmentIcon,
  CaretUpSolidIcon,
  CaretDownSolidIcon,
  EditOutlineIcon,
  CanvasNewIcon,
  GoogleClassroomIcon,
  UpArrow,
  DownArrowIcon,
  GradingIcon,
} from "../../../../assets/icons";
import Constant from "../../../../utils/constant/constant";
import RouteConstant from "../../../../utils/constant/routeConstant";
import { useHistory } from "react-router-dom";
import { ISelectElement } from "../../../../model/interface/selectElement";
import { IAssignmentSearch } from "../../../../model/interface/assignmentSearch";
import Loader from "../../../shared/loader";
import ConfirmationDialog from "../../../shared/confirmationDialog";
import { toast } from "react-toastify";
import AssignmentSetting from "../shared/assignmentSetting";
import { IAssignmentActivity } from "../../../../model/interface/assignmentActivity";
import {
  getActivityName,
  getContextMenuActivityType,
  getIsParentGroupedActivityType,
  hasLockedPremiumAssessments,
  isGroupedAssignment,
} from "../../../../utils/assignmentHelper";
import { OrderByArray } from "../../../../utils/helper";
import Pagination from "../../../shared/pagination/pagination";
import PageSize from "../../../shared/pagination/pageSize";
import {
  DEFAULT_PAGE_NUMBER,
  DEFAULT_PAGE_SIZE,
} from "../../../../utils/pagingConstant";
import PagingResponse from "../../../../model/common/pagingResponse";
import Permissions from "../../../../utils/permissions";
import AllowedTo from "../../../shared/rbac";
import AccessDenied from "../../../shared/accessDenied";
import SettingPopup from "./settingPopup";
import QuestionPreview from "../shared/questionPreview/questionPreview";
import EditConfirmationDialog from "./editConfirmation";
import MessagePopup from "../../../shared/messagePopup";
import { isAllowViewAssignmentAssessment } from "../../../../utils/assessmentHelper";
import Subject from "../../../shared/dropdowns/subject";
import SharedByIcon from "../../../shared/icons/sharedIcon";
import { getAssignmentBankUpgradePermission } from "../../../shared/permissionHelper";
import UpGradeMessagePopup from "../../../shared/upGradeMessagePopup";
import constant from "../../../../utils/constant/constant";
import { getEndDate, getStartDate } from "../../../../utils/dateHelper";
import { getAssignmentsQuestionCount } from "../../../../api/student/studentActivityApi";
import { ArrowDownIcon, ArrowUpIcon } from "@heroicons/react/solid";
import { SchoolYearDropDown } from "../../schoolYearDropdown";
import { ISchoolYear } from "../../../../model/teacher/schoolYear";
import ContextMenu from "../shared/contextMenu";
import PrintDialog from "../shared/print/learnosityPrintPopUp";

const dates = [
  { value: "1", label: "Due Date" },
  { value: "2", label: "Create Date" },
  { value: "3", label: "Assigned Date" },
];

const assigned = [
  { value: "0", label: "All" },
  { value: "1", label: "Yes" },
  { value: "2", label: "No" },
];
const dateLabels = {
  createDate: "Create Date",
  dueDate: "Due Date",
  assignedDate: "Assigned Date",
};

const options = [
  {
    value: "1",
    label: "Duplicate",
    showForGroupActivity: true,
    roles: [
      Constant.UserRoleId.District,
      Constant.UserRoleId.SchoolAdmin,
      Constant.UserRoleId.SchoolTeacher,
      Constant.UserRoleId.FreeTeacher,
    ],
    isActive: true,
    isUpgrade: false,
  },
  {
    value: "2",
    label: "Preview",
    showForGroupActivity: false,
    roles: [
      Constant.UserRoleId.District,
      Constant.UserRoleId.SchoolAdmin,
      Constant.UserRoleId.SchoolTeacher,
      Constant.UserRoleId.FreeTeacher,
    ],
    isActive: true,
    isUpgrade: false,
  },
  {
    value: "3",
    label: "Print",
    showForGroupActivity: false,
    roles: [
      Constant.UserRoleId.District,
      Constant.UserRoleId.SchoolAdmin,
      Constant.UserRoleId.SchoolTeacher,
      Constant.UserRoleId.FreeTeacher,
    ],
    isActive: true,
    isUpgrade: false,
  },
];

interface passedProps {
  userId: number;
  roleId: number;
  schoolYearData?: Array<ISchoolYear>;
}

function MyAssignment(props: passedProps) {
  const defaultOrderByColumn: string = "DueDate";
  type ActionButton = "Lock" | "Archive" | "Delete" | "Duplicate" | "Unarchive";
  const defaultValue = { label: "Select", value: "0" };
  const assignedDefaultValue = { label: "All", value: "0" };
  const datesDefaultValue = { label: "Due Date", value: "1" };
  const { userId, roleId } = props;
  const [pageNumber, setPageNumber] = useState(DEFAULT_PAGE_NUMBER);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [resetPageNumber, setResetPageNumber] = useState<boolean>(false);
  const [selectedDateType, setSelectedDateType] =
    useState<ISelectElement>(datesDefaultValue);
  const [students, setStudents] = useState<Array<ISelectElement>>([]);
  const [selectedStudent, setSelectedStudent] = useState<ISelectElement>();
  const [classes, setClasses] = useState<Array<ISelectElement>>([]);
  const [selectedClass, setSelectedClass] = useState<ISelectElement>();
  const [subjects, setSubjects] = useState<any>();
  const [selectedSubject, setSelectedSubject] = useState<ISelectElement>();
  const [selectedAssigned, setSelectedAssigned] =
    useState<ISelectElement>(assignedDefaultValue);
  const [selectedFromDate, setFromDate] = useState<Date>();
  const [selectedToDate, setToDate] = useState<Date>();
  const [assignments, setAssignments] =
    useState<PagingResponse<MyAssignmentDetails>>();
  const [isArchived, setIsArchived] = useState<boolean>(false);
  const [isUnassigned, setIsUnassigned] = useState<boolean>(false);
  const [name, setName] = useState<string>("");
  const [selectedAssignments, setSelectedAssignments] = useState<Array<number>>(
    []
  );
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [showConfirmation, setshowConfirmation] = useState<boolean>(false);
  const [showEditConfirmation, setShowEditConfirmation] =
    useState<boolean>(false);
  const [confirmationMessage, setConfirmationMessage] = useState<string>("");
  const [buttonAction, setButtonAction] = useState<ActionButton>();
  const [duplicateAssignmentId, setDuplicateAssignmentId] = useState<number>(0);
  const [showSettingPopup, setShowSettingPopup] = useState<boolean>(false);
  const [selectedAssignment, setSelectedAssignment] =
    useState<MyAssignmentDetails>();
  const [selectedActivity, setSelectedActivity] =
    useState<IAssignmentActivity>();
  const [assignmentActivities, setAssignmentActivities] =
    useState<Array<IAssignmentActivity>>();
  const [selectedAssignmentId, setSelectedAssignmentId] = useState<number>(0);
  const [toggleSelectAll, setToggleSelectAll] = useState<boolean>(false);
  const [orderByColumn, setOrderByColumn] = useState<string>("DueDate");
  const [sortByDesc, setSortByDesc] = useState<boolean>(true);
  const [showEditSettingPopup, setShowEditSettingPopup] =
    useState<boolean>(false);
  const [activityPreviewDetail, setActivityPreviewDetail] = useState<{
    previewId: string;
    activityIdPreview: number;
    activityName: string;
    activityType: string;
    standardId: number;
    subjectId: number;
  }>();
  const [open, setOpen] = useState<boolean>(false);
  const [errroMessage, setErrorMessage] = useState<string>("");
  const [errorMessagePopup, setErrorMessagePopup] = useState<boolean>(false);
  const [upgrade, setUpgrade] = useState(false);
  const [showUpgradePopup, setShowUpgradePopup] = useState<boolean>(false);
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [selectedSchoolYearId, setSelectedSchoolYearId] = useState<number>();
  const [isDataRefreshed, setIsDataRefreshed] = useState<boolean>(false);
  const [schoolYearData, setSchoolYearData] = useState<Array<ISchoolYear>>();
  const [schoolYearMinDate, setSchoolYearMinDate] = useState<Date>();
  const [schoolYearMaxDate, setSchoolYearMaxDate] = useState<Date>();
  const [isPreviousYear, setIsPreviousYear] = useState<boolean>(false);
  const previousYearErroMessage: string =
    "Prior year activities cannot be edited but can be duplicated to edit and assign.";
  const [previousYearErrorMessagePopup, setPreviousYearErrorMessagePopup] =
    useState<boolean>(false);
  const [selectedLearnosityId, setSelectedLearnosityId] = useState<string>("");
  const [showPrintPopup, setShowPrintPopup] = useState<boolean>(false);
  const [activityName, setActivityName] = useState<string>("");
  const [selectedActivityTypeId, setSelectedActivityTypeId] =
    useState<number>();

  const history = useHistory();
  useEffect(() => {
    checkUpgradeRequired();
  }, []);

  const checkUpgradeRequired = async () => {
    if (getAssignmentBankUpgradePermission(props.roleId)) {
      setUpgrade(true);
    } else {
      setUpgrade(false);
    }
  };

  const openModal = async (
    learnosityReferenceId,
    activityId,
    activityName,
    activityType,
    standardId,
    subjectId
  ) => {
    if (!learnosityReferenceId) {
      return false;
    }

    if (activityType == Constant.AssignmentActivityType.ASSESSMENT) {
      //get assessmentId for classification instaed of assignmentId
      activityId = learnosityReferenceId.split("-")[1];
    }
    if (activityType === Constant.AssignmentActivityType.PRACTICEQUESTIONS) {
      await getAssignmentsQuestionCount(learnosityReferenceId);
    }
    setActivityPreviewDetail({
      previewId: learnosityReferenceId,
      activityIdPreview: activityId,
      activityName: activityName,
      activityType: activityType,
      standardId: standardId,
      subjectId: subjectId,
    });
    setOpen(true);
  };

  const popUpClose = (value) => {
    setOpen(value);
    setActivityPreviewDetail(undefined);
  };

  function handleStudentChange(selectdItem: any) {
    setSelectedStudent(selectdItem);
  }

  function handleClassChange(selectdItem: any) {
    setSelectedClass(selectdItem);
  }

  function handleSubjectChange(selectdItem: any) {
    setSelectedSubject(selectdItem);
  }

  function handleAssignedChange(selectdItem: any) {
    setSelectedAssigned(selectdItem);
    if (selectdItem.value === "2") {
      //unassigned
      var createDate = dates.filter((d) => d.label == dateLabels.createDate)[0];
      if (createDate) {
        setSelectedDateType(createDate);
        setOrderByColumn("CreateDate");
      }
    } else {
      var dueDate = dates.filter((d) => d.label == dateLabels.dueDate)[0];
      if (dueDate) {
        setSelectedDateType(dueDate);
        setOrderByColumn("DueDate");
      }
    }
    setSortByDesc(true);
  }

  function handleDateChange(selectdItem: any) {
    setSelectedDateType(selectdItem);
  }

  function handleFromDateChange(selectedItem: any) {
    if (selectedToDate && selectedItem > selectedToDate) {
      return false;
    }
    setFromDate(selectedItem);
  }

  function handleToDateChange(selectedItem: any) {
    if (selectedFromDate && selectedItem < selectedFromDate) {
      return false;
    }
    setToDate(selectedItem);
  }

  function handleSettingPopup(
    assignment: MyAssignmentDetails,
    activity?: IAssignmentActivity
  ) {
    toggleSettingPopup(true);
    setSelectedAssignment(assignment);
    setSelectedActivity(activity);
  }

  function toggleSettingPopup(
    popupAction: boolean,
    refreshData: boolean = false
  ) {
    setShowSettingPopup(popupAction);
    if (refreshData) {
      searchAssignments(true);
    }
  }

  function toggleEditSettingPopup(
    popupAction: boolean,
    refreshData: boolean = false
  ) {
    setShowEditSettingPopup(popupAction);
    if (refreshData) {
      searchAssignments(false, false, true);
    }
  }

  function handleUpdateAssignments(
    action: string,
    isArchived: boolean = false
  ) {
    const assignmentIds = selectedAssignments.join(",");
    setShowLoading(true);
    updateAssignments(userId, assignmentIds, action, isArchived).then(
      (response) => {
        setShowLoading(false);
        var message = "";
        switch (action) {
          case Constant.AssignmentUpdateAction.LOCK:
            message = "Selected assignments locked/unlocked successfully.";
            break;
          case Constant.AssignmentUpdateAction.DELETE:
            message = "Selected assignments deleted successfully.";
            break;
          case Constant.AssignmentUpdateAction.ARCHIVE:
            message = `Selected assignments ${
              isArchived ? "archived" : "unarchived"
            } successfully.`;
            break;
        }
        toast.success(message);
        searchAssignments();
      }
    );
  }

  function handleDuplicateAssignments(assignmentId: number) {
    setShowLoading(true);
    duplicateAssignment(userId, assignmentId)
        .then((response) => {
          setShowLoading(false);
          if (response.data.isSuccess) {
            history.push(`/assignments/${response.data.data}`);
            return;
          }

          toast.error("Assignment duplication not successful");
          switch (response.data.code) {
            case 1: //Assignment contains activity with a Guid
              setErrorMessagePopup(true);
              setErrorMessage(Constant.DialogMessages.AssignmentDuplicationFailCode1);
              break;
            default:
              setErrorMessagePopup(true);
              setErrorMessage(response.data.message); //Message in this case coming from the server should not reveal sensative data
              break;
          }
        })
        .catch(reason => {
          setShowLoading(false);
          toast.error("An error has occurred while duplicating the assignment");
        });
  }

  function isDefaultLoading() {
    let isDefaultLoading: boolean = false;
    if (
      (!selectedStudent || selectedStudent?.value === defaultValue.value) &&
      (!selectedClass || selectedClass?.value === defaultValue.value) &&
      (!selectedSubject || selectedSubject?.value === defaultValue.value) &&
      (!selectedAssigned ||
        selectedAssigned.value === assignedDefaultValue.value) &&
      (!selectedDateType ||
        selectedDateType.value === datesDefaultValue.value) &&
      name === "" &&
      selectedFromDate === undefined &&
      selectedToDate === undefined &&
      orderByColumn === "DueDate" &&
      sortByDesc === false
    ) {
      isDefaultLoading = true;
    }

    return isDefaultLoading;
  }

  function resetFilters() {
    setSelectedStudent(defaultValue);
    setSelectedClass(defaultValue);
    setSelectedSubject(defaultValue);
    setSelectedAssigned(assignedDefaultValue);
    setSelectedDateType(datesDefaultValue);
    setName("");
    setFromDate(undefined);
    setToDate(undefined);
    setOrderByColumn(defaultOrderByColumn);
    const assignmentSearch: IAssignmentSearch = {
      teacherId: userId,
      assignmentType: "MYASSIGNMENT",
      orderByColumn: defaultOrderByColumn,
      pageNumber: DEFAULT_PAGE_NUMBER,
      pageSize: DEFAULT_PAGE_SIZE,
      sortByDesc: true,
      schoolYearId: selectedSchoolYearId,
    };

    setShowLoading(true);
    getAssignments(assignmentSearch).then((r) => {
      setAssignments(r.data);
      setSelectedAssignments([]);
      setShowLoading(false);
      setPageNumber(DEFAULT_PAGE_NUMBER);
      setResetPageNumber(!resetPageNumber);
    });
  }

  const handleSortBy = (column: string) => {
    setIsDataRefreshed(true);
    setSortByDesc(!sortByDesc);
    setOrderByColumn(column);
  };

  function searchAssignments(
    resetPageNumber: boolean = false,
    resetDates: boolean = false,
    usePrimaryDataSource: boolean = false
  ) {
    let utcFromDate: Date | undefined;
    let utcToDate: Date | undefined;

    if(selectedFromDate != null)
      utcFromDate = getStartDate(selectedFromDate);
    if(selectedToDate != null)
      utcToDate = getEndDate(selectedToDate);

    if (resetPageNumber) {
      setPageNumber(DEFAULT_PAGE_NUMBER);
      setResetPageNumber(!resetPageNumber);
    }
    setShowLoading(true);
    let orderByColumnValue: string = orderByColumn;
    if (isDefaultLoading()) {
      orderByColumnValue = defaultOrderByColumn;
    }
    const assignmentSearch: IAssignmentSearch = {
      teacherId: userId,
      studentId:
        selectedStudent && selectedStudent.value !== "0"
          ? parseInt(selectedStudent.value)
          : undefined,
      classId:
        selectedClass && selectedClass.value !== "0"
          ? parseInt(selectedClass.value)
          : undefined,
      subjectId:
        selectedSubject && selectedSubject.value !== "0"
          ? parseInt(selectedSubject.value)
          : undefined,
      isAssigned: selectedAssigned
        ? selectedAssigned.value === "0"
          ? undefined
          : selectedAssigned.value === "1"
          ? true
          : false
        : undefined,
      dateType:
        !resetDates &&
        selectedDateType &&
        selectedFromDate &&
        selectedToDate &&
        selectedDateType.value !== "0"
          ? selectedDateType.label
          : undefined,
      fromDate: !resetDates ? utcFromDate : undefined,
      toDate: !resetDates ? utcToDate : undefined,
      isArchived: isArchived,
      name: name,
      assignmentType: "MYASSIGNMENT",
      pageNumber: resetPageNumber ? DEFAULT_PAGE_NUMBER : pageNumber,
      pageSize: pageSize,
      orderByColumn:
        selectedDateType.label === dateLabels.createDate
          ? "CreateDate"
          : orderByColumnValue,
      sortByDesc: sortByDesc,
      schoolYearId: selectedSchoolYearId,
      usePrimaryDataSource: usePrimaryDataSource,
    };

    if (isUnassigned) {
      assignmentSearch.isAssigned = false;
    }

    getAssignments(assignmentSearch).then((r) => {
      setAssignments(r.data);
      setSelectedAssignments([]);
      setShowLoading(false);
      setToggleSelectAll(false);
    });
  }

  function bindStudents() {
    setShowLoading(true);
    getAssignmentStudents(userId, roleId).then((response) => {
      setShowLoading(false);
      const studentsData = response.data.data;
      var students: Array<ISelectElement> = new Array<ISelectElement>();

      studentsData.forEach(function (keyValuePair: any) {
        students.push({ label: keyValuePair.name, value: keyValuePair.id });
      });

      setStudents(students);
    });
  }

  function bindClasses() {
    setShowLoading(true);
    getAssignmentClasses(userId, roleId).then((response) => {
      setShowLoading(false);
      const classData = response.data.data;
      var classes: Array<ISelectElement> = new Array<ISelectElement>();

      classData.forEach(function (keyValuePair: any) {
        classes.push({ label: keyValuePair.name, value: keyValuePair.id });
      });

      setClasses(classes);
    });
  }

  function bindSubjects() {
    setShowLoading(true);
    getAssignmentSubjects(userId, roleId).then((response) => {
      setShowLoading(false);
      const subjectsData = response.data;
      setSubjects(subjectsData);
    });
  }

  const handleEditAssignmentPopup = (assignmentId: string) => {
    if (isPreviousYear) {
      setPreviousYearErrorMessagePopup(true);
    } else {
      setSelectedAssignmentId(parseInt(assignmentId));
      setShowEditSettingPopup(true);
    }
  };

  const handleRedirect = (assignmentId: string, mode: string) => {
    if (isPreviousYear) {
      setPreviousYearErrorMessagePopup(true);
    } else {
      history.push({
        pathname: RouteConstant.EDITASSIGNMENT.replace(":id", assignmentId),
        state: {
          editMode: mode,
        },
      });
    }
  };

  const toggleErrorMessagePopup = () => {
    setErrorMessagePopup(false);
  };

  const togglePreviousYearErrorMessagePopup = () => {
    setPreviousYearErrorMessagePopup(false);
  };

  function handleEditConfirmation(assignmentId: number) {
    if (isPreviousYear) {
      setPreviousYearErrorMessagePopup(true);
    } else {
      setShowEditConfirmation(true);
      setSelectedAssignmentId(assignmentId);
    }
  }

  function handleSelectedAssignments(assignmentId: number) {
    var selectedAssignmentList: Array<number> = new Array<number>();

    const assignmentExist = selectedAssignments.some(
      (r) => (r as number) === assignmentId
    );

    if (assignmentExist) {
      selectedAssignmentList = selectedAssignments.filter(
        (r) => (r as number) !== assignmentId
      );
    } else {
      selectedAssignmentList = [...selectedAssignments, assignmentId];
    }

    setSelectedAssignments(selectedAssignmentList);
  }

  function handleSelectAll() {
    const isSelected = !toggleSelectAll;
    if (isSelected && assignments && assignments.data) {
      setSelectedAssignments(
        assignments.data.map((r) => {
          return parseInt(r.assignmentId);
        })
      );
    } else {
      setSelectedAssignments([]);
    }
    setToggleSelectAll(!toggleSelectAll);
  }

  function handleConfirmation(action: ActionButton, assignmentId: number = 0) {
    setButtonAction(action);
    setshowConfirmation(true);
    var message: string = "";
    switch (action) {
      case "Lock":
        message =
          "Are you sure you want to lock/unlock the selected assignment(s)?";
        break;
      case "Archive":
        message =
          "Are you sure you want to archive the selected assignment(s)?";
        break;
      case "Delete":
        message =
          "Are you sure you want to delete the selected assignment(s)? <br /><br /> This will remove the assignment(s) from your class gradebook. <br />";
        break;
      case "Duplicate":
        message = isPreviousYear
          ? "Are you sure you want to duplicate the selected assignment? The duplicate will be associated with the current school year only."
          : "Are you sure you want to duplicate the selected assignment?";
        setDuplicateAssignmentId(assignmentId);
        break;
      case "Unarchive":
        message =
          "Are you sure you want to unarchive the selected assignment(s)?";
        break;
    }

    setConfirmationMessage(message);
  }

  function handleOkConfirmation() {
    setshowConfirmation(false);
    setToggleSelectAll(false);
    switch (buttonAction) {
      case "Lock":
        handleUpdateAssignments(Constant.AssignmentUpdateAction.LOCK);
        break;
      case "Archive":
        handleUpdateAssignments(Constant.AssignmentUpdateAction.ARCHIVE, true);
        break;
      case "Unarchive":
        handleUpdateAssignments(Constant.AssignmentUpdateAction.ARCHIVE, false);
        break;
      case "Delete":
        handleUpdateAssignments(Constant.AssignmentUpdateAction.DELETE);
        break;
      case "Duplicate":
        handleDuplicateAssignments(duplicateAssignmentId);
        break;
    }
  }

  function handleContinueClick(assignmentId: number) {
    history.push({
      pathname: RouteConstant.EDITASSIGNMENT.replace(
        ":id",
        assignmentId.toString()
      ),
      state: {
        editMode: "Edit",
      },
    });
  }

  function handleDuplicateClick(assignmentId: number) {
    if (validateDuplicateAssignment(assignmentId)) {
      handleDuplicateAssignments(assignmentId);
    }
    setShowEditConfirmation(false);
  }

  function handleEditCancel() {
    setShowEditConfirmation(false);
  }

  function handleCancelConfirmation() {
    setshowConfirmation(false);
    setButtonAction(undefined);
    setConfirmationMessage("");
  }

  function handleActivityDetails(assignmentId: number) {
    if (selectedAssignmentId === assignmentId) {
      setSelectedAssignmentId(0);
      setAssignmentActivities([]);
      return;
    }
    setShowLoading(true);
    setSelectedAssignmentId(assignmentId);
    getAssignmentById(assignmentId, selectedSchoolYearId).then((response) => {
      setShowLoading(false);
      const activityDetails: Array<IAssignmentActivity> = response.data.activities;
      let activities: Array<IAssignmentActivity> = [];
      const hasCombineActivity = activityDetails.some(
        (r) => r.isCombinedActivity
      );
      if (hasCombineActivity) {
        const combineActivity = activityDetails.filter(
          (r) => r.isCombinedActivity
        )[0];
        const otherActivities = activityDetails.filter(
          (r) => !r.isCombinedActivity
        );
        activities = [...otherActivities, combineActivity];
        setAssignmentActivities(OrderByArray(activities, "sequenceNumber"));
      } else {
        setAssignmentActivities(OrderByArray(activityDetails, "sequenceNumber"));
      }
    });
  }

  const onPageChange = (pageNo) => {
    setIsDataRefreshed(true);
    setPageNumber(pageNo);
  };

  const onPageSizeChange = (pageSize) => {
    setIsDataRefreshed(true);
    setPageNumber(DEFAULT_PAGE_NUMBER);
    setPageSize(pageSize);
    setResetPageNumber(true);
  };

  function getActivityRef(activityType: string, reference: string) {
    let referenceId: string | null = null;
    switch (activityType) {
      case Constant.AssignmentActivityType.ASSESSMENT:
      case Constant.AssignmentActivityType.PREBUILTTESTS:
      case Constant.AssignmentActivityType.PRACTICEQUESTIONS:
        referenceId = reference;
        break;
    }

    return referenceId;
  }

  function getItemRef(activityType: string, reference: string) {
    let referenceId: string | null = null;
    switch (activityType) {
      case Constant.AssignmentActivityType.VIDEOLESSON:
      case Constant.AssignmentActivityType.CONSTRUCTEDRESPONSE:
        referenceId = reference;
        break;
    }

    return referenceId;
  }

  useEffect(() => {
    if (isDataRefreshed) searchAssignments();
  }, [pageSize, pageNumber, sortByDesc]);

  function handleSchoolYearLoaded(currentSchoolYearId?: number) {
    handleSchoolYearChange(currentSchoolYearId);
  }

  function handleSchoolYearChange(schoolYearId?: number) {
    setIsDataRefreshed(false);
    setSelectedSchoolYearId(schoolYearId);
  }

  function handleSchoolYearData(schoolYearData: Array<ISchoolYear>) {
    setSchoolYearData(schoolYearData);
  }

  function handleDateFilter(schoolYearId?: number) {
    let selectedSchoolYear = schoolYearData?.filter(
      (s) => s.schoolYearId == schoolYearId
    )[0];
    let minDate = selectedSchoolYear
      ? Moment(
          selectedSchoolYear.startDate.toString().split("T")[0],
          "YYYY-MM-DD"
        ).toDate()
      : new Date();

    let maxDate = selectedSchoolYear
      ? Moment(
          selectedSchoolYear.startDate.toString().split("T")[0],
          "YYYY-MM-DD"
        )
          .add(1, "year")
          .subtract(1, "day")
          .toDate()
      : new Date();

    setSchoolYearMinDate(getStartDate(minDate));
    setSchoolYearMaxDate(getEndDate(maxDate));
  }

  useEffect(() => {
    if (selectedSchoolYearId) {
      handleDateFilter(selectedSchoolYearId);
      setFromDate(undefined);
      setToDate(undefined);
      if (schoolYearData) {
        var currentSchoolYear: ISchoolYear = schoolYearData.filter(
          (x) => x.currentYearOffset === 0
        )[0];
        var selectedSchoolYear: ISchoolYear = schoolYearData.filter(
          (x) =>
            parseInt(x.schoolYearId.toString()) ===
            parseInt(selectedSchoolYearId.toString())
        )[0];
        if (
          currentSchoolYear.startDate &&
          selectedSchoolYear.startDate &&
          new Date(selectedSchoolYear.startDate) <
            new Date(currentSchoolYear.startDate)
        ) {
          setIsPreviousYear(true);
        } else {
          setIsPreviousYear(false);
        }
      }

      searchAssignments(true, true);
    }
  }, [selectedSchoolYearId]);

  useEffect(() => {
    bindStudents();
    bindClasses();
    bindSubjects();
  }, []);

  const handleResultsClick = (
    assignmentId: number,
    activityId: number,
    activityType: string,
    hasCRActivity: boolean = false,
    assessmentId: string | undefined,
    canViewAssessment?: boolean,
    assessmentSharedTypeId?: number,
    assessmentCreatedByUserId?: number,
    subjectId?: number
  ) => {
    if (
      !isAllowViewAssignmentAssessment(
        props.roleId,
        canViewAssessment,
        activityType,
        assessmentCreatedByUserId,
        props.userId,
        assessmentSharedTypeId
      )
    )
    {
      return;
    }
      
    const pathname = `${RouteConstant.GROUPED_RESULT_REPORTS}?assignmentId=${assignmentId}&activityId=${activityId}&redirectedFrom=MYASSIGNMENTS` +
     `&assignmentType=${constant.AssignmentType.MYASSIGNMENT}&subjectId=${subjectId}&schoolYearId=${selectedSchoolYearId}`;
    window.open(pathname, "_blank");
  };

  function getOptions(
    isGroupActivity: boolean,
    canDuplicate: boolean,
    authorId: number,
    userId: number,
    canPrint: Boolean
  ) {
    let newOptions = JSON.parse(JSON.stringify(options));
    if (isGroupActivity) {
      newOptions = newOptions.filter(
        (o) => o.showForGroupActivity == isGroupActivity
      );
    } else if (!canDuplicate) {
      newOptions = newOptions.filter((o) => o.label != "Duplicate");
    }
    newOptions.forEach((option) => {
      if (option.label == "Print") {
        option.isActive = canPrint;
      }
    });
    return newOptions;
  }

  function isActivityValid(learnosityId: string) {
    if (learnosityId === "") {
      setErrorMessage(
        "You cannot perform this action because there are no questions associated with this assessment."
      );
      setErrorMessagePopup(true);
      return false;
    }
    return true;
  }

  function handlePrintPopup(learnosityId: string) {
    setSelectedLearnosityId(learnosityId);
    setShowPrintPopup(true);
  }

  function validateDuplicateAssignment(assignmentId: number) {
    let isValid = true;
    if (hasLockedPremiumAssessments(assignments!.data, assignmentId)) {
      setErrorMessagePopup(true);
      setErrorMessage(
        "This assignment cannot be duplicated as it contains locked premium assessment."
      );
      isValid = false;
    }
    return isValid;
  }

  function handleOptionChange(
    selectdItem: any,
    entityId: number,
    learnosityId: string,
    activityName: string,
    activityType: string,
    value?: any
  ) {
    const assignmentActivity =
      (assignmentActivities?.length ?? 0) > 0
        ? assignmentActivities?.filter(
            (s) => s.assignmentActivityId == entityId
          )[0]
        : assignments?.data.filter(
            (s) => s.assignmentActivity?.assignmentActivityId == entityId
          )[0]?.assignmentActivity;

    const assignmentId = assignmentActivity
      ? assignmentActivity.assignmentId
      : null;
    const assignment = assignments?.data.filter(
      (a) => a.assignmentId == assignmentId?.toString()
    )[0];
    setActivityName(assignment?.name ?? "");
    switch (selectdItem.label) {
      case "Duplicate":
        if (validateDuplicateAssignment(entityId)) {
          let assignmentIdToDuplicate = assignmentActivity?.assignmentId ?? 0;
          if (getIsParentGroupedActivityType(activityType)) {
            const parentGroupedActivity = assignments?.data?.find(
              (s) => s.assignmentId?.toString() === entityId.toString()
            );
            assignmentIdToDuplicate = parentGroupedActivity
              ? parseInt(parentGroupedActivity.assignmentId)
              : 0;
          }
          handleConfirmation("Duplicate", Number(assignmentIdToDuplicate));
        }
        break;
      case "Print":
        if (isActivityValid(learnosityId)) {
          handlePrintPopup(learnosityId);
        }
        setSelectedActivityTypeId(assignmentActivity?.activityTypeId);
        break;
      case "Preview":
        if (validatePreview(assignment)) {
          openModal(
            assignmentActivity?.learnosityIdEnglish,
            assignmentId,
            assignmentActivity ? getActivityName(assignmentActivity) : "",
            assignmentActivity?.activityType,
            assignmentActivity?.standardId,
            assignment?.subjectId
          );
        }
        break;
    }
  }

  const handleCancelPrint = () => {
    setShowPrintPopup(false);
    setSelectedLearnosityId("");
  };

  const validatePreview = (assignment: MyAssignmentDetails | undefined) => {
    if (assignment) {
      return assignment.hasActicity && assignment.activityCount === 1
        ? isAllowViewAssignmentAssessment(
            props.roleId,
            assignment.assignmentActivity?.canViewAssessment,
            assignment.assignmentActivity?.activityType,
            assignment.assignmentActivity?.assessmentCreatedByUserId,
            props.userId,
            assignment.assignmentActivity?.assessmentSharedTypeId
          )
        : isAllowViewAssignmentAssessment(
            props.roleId,
            assignment.assignmentActivity?.canViewAssessment,
            assignment.assignmentActivity?.activityType,
            assignment.assignmentActivity?.assessmentCreatedByUserId,
            props.userId,
            assignment.assignmentActivity?.assessmentSharedTypeId
          );
    }
    return false;
  };

  const isQuestionReference = (activityTypeId: number) => {
    return activityTypeId ==
      constant.AssignmentActivityTypeEnum.CONSTRUCTEDRESPONSE
      ? true
      : false;
  };
  const showPrintOption = (activityTypeId: number) => {
    return activityTypeId == 0 ||
      activityTypeId == constant.AssignmentActivityTypeEnum.VIDEOLESSON
      ? false
      : true;
  };
  return (
    <AllowedTo
      perform={Permissions.assignments_view}
      no={() => <AccessDenied />}
    >
      {showLoading && <Loader></Loader>}
      {showPrintPopup && (
        <PrintDialog
          learnosityId={selectedLearnosityId}
          onCancelClick={handleCancelPrint}
          open={showPrintPopup}
          isAnswerKey={false}
          activityName={activityName}
          code={""}
          isAssignment={true}
          isQuestionReference={isQuestionReference(selectedActivityTypeId ?? 0)}
        ></PrintDialog>
      )}
      {activityPreviewDetail && (
        <QuestionPreview
          modal={openModal}
          show={open}
          changeValue={popUpClose}
          activityRef={getActivityRef(
            activityPreviewDetail.activityType,
            activityPreviewDetail.previewId
          )}
          itemRef={getItemRef(
            activityPreviewDetail.activityType,
            activityPreviewDetail.previewId
          )}
          activityIdPreview={activityPreviewDetail.activityIdPreview}
          activityName={activityPreviewDetail.activityName}
          standardId={activityPreviewDetail.standardId}
          subjectId={activityPreviewDetail.subjectId}
        ></QuestionPreview>
      )}

      {showEditSettingPopup && (
        <SettingPopup
          userId={userId}
          roleId={roleId}
          assignmentId={selectedAssignmentId}
          toggleSettingPopup={toggleEditSettingPopup}
          selectedSchoolYearId={selectedSchoolYearId}
        />
      )}
      {showSettingPopup && selectedAssignment && (
        <AssignmentSetting
          assignment={selectedAssignment}
          activity={selectedActivity}
          toggleSettingPopup={toggleSettingPopup}
        />
      )}
      <ConfirmationDialog
        open={showConfirmation}
        confirmationTitle={"Please confirm"}
        confirmationMessage={confirmationMessage}
        onCancelClick={handleCancelConfirmation}
        onOkClick={handleOkConfirmation}
        okButtonText={buttonAction === "Delete" ? "Delete" : undefined}
        cancelButtonText={
          buttonAction === "Delete" ? "Don't Delete" : undefined
        }
      ></ConfirmationDialog>
      <EditConfirmationDialog
        open={showEditConfirmation}
        assignmentId={selectedAssignmentId}
        onContinueClick={handleContinueClick}
        onDuplicateClick={handleDuplicateClick}
        onCancelClick={handleEditCancel}
      ></EditConfirmationDialog>
      {errorMessagePopup && (
        <MessagePopup
          message={errroMessage}
          togglePopup={toggleErrorMessagePopup}
        />
      )}
      {previousYearErrorMessagePopup && (
        <MessagePopup
          message={previousYearErroMessage}
          togglePopup={togglePreviousYearErrorMessagePopup}
        />
      )}

      {showUpgradePopup && (
        <UpGradeMessagePopup
          togglePopup={setShowUpgradePopup}
          roleId={props.roleId ?? 0}
          message={constant.UPGRADE_MESSAGE_SCHOOL_ACCOUNT}
        />
      )}
      <div className="min-w-0 block lg:col-span-9 xl:col-span-10 w-full">
        <div className="bg-gray-100 w-full mb-5 rounded-lg p-5 h-full">
          <div className="flex flex-col mb-5">
            <div className="flex w-full justify-between relative items-center">
              <div
                className="flex items-center  cursor-pointer"
                onClick={() => {
                  if (!showFilter) {
                    setShowFilter(true);
                  } else {
                    setShowFilter(false);
                  }
                }}
              >
                <span className="bg-black w-10 h-10 rounded-full inline-block flex items-center justify-center cursor-pointer">
                  {!showFilter && (
                    <ArrowDownIcon className="w-6 text-white opacity-90" />
                  )}
                  {showFilter && (
                    <ArrowUpIcon className="w-6 text-white opacity-90" />
                  )}
                </span>
                <span className="mx-2 text-gray-700">
                  {!showFilter
                    ? "Expand Filter Options"
                    : "Collapse Filter Options"}
                </span>
              </div>
              <div
                className="flex items-center gap-x-3 bg-gray-500 pl-4 pr-2 py-1 rounded-sm"
                title="Assignments have been associated with a school year. To reuse an assignment each year, duplicate it from the previous school year."
              >
                <label className="block text-sm font-semibold text-white whitespace-nowrap">
                  School Year
                </label>
                <SchoolYearDropDown
                  className="w-24"
                  schoolYearData={props.schoolYearData}
                  handleSchoolYearChange={handleSchoolYearChange}
                  handleSchoolYearLoaded={handleSchoolYearLoaded}
                  handleSchoolYearData={handleSchoolYearData}
                ></SchoolYearDropDown>
              </div>
            </div>

            <div
              className={`${
                showFilter
                  ? "max-h-[1000px] opacity-100 mt-5 pb-2"
                  : "max-h-0 opacity-0 -mt-5 pb-0 pointer-events-none"
              } w-full bg-white transform transition-all ease-in-out duration-300 relative z-0 p-5 shadow `}
            >
              <div className="grid grid-cols-2 sm:grid-cols-8 lg:grid-cols-8 xl:grid-cols-8 gap-6 pb-4">
                <div className="col-span-8 sm:col-span-4 lg:col-span-6 xl:col-span-2">
                  <label className="block text-sm text-gray-500">Student</label>
                  <Select
                    className="mt-1"
                    value={selectedStudent}
                    options={students}
                    onChange={handleStudentChange}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: "#008fbb",
                      },
                    })}
                  />
                </div>
                <div className="col-span-8 sm:col-span-4 lg:col-span-6 xl:col-span-2">
                  <label className="block text-sm text-gray-500">Class</label>
                  <Select
                    className="mt-1"
                    value={selectedClass}
                    options={classes}
                    onChange={handleClassChange}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: "#008fbb",
                      },
                    })}
                  />
                </div>
                <div className="col-span-8 sm:col-span-4 lg:col-span-6 xl:col-span-2">
                  <Subject
                    setSelectedSubject={(items) =>
                      handleSubjectChange(items[0])
                    }
                    selectedItems={selectedSubject}
                    isMulti={false}
                    themeColor={"#008fbb"}
                    subjects={subjects}
                  ></Subject>
                </div>
                <div className="col-span-8 sm:col-span-4 lg:col-span-6 xl:col-span-2">
                  <label className="block text-sm text-gray-500">
                    Assigned
                  </label>
                  <Select
                    className="mt-1"
                    value={selectedAssigned}
                    options={assigned}
                    onChange={handleAssignedChange}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: "#008fbb",
                      },
                    })}
                  />
                </div>
                <div className="col-span-8 sm:col-span-4 lg:col-span-6 xl:col-span-2">
                  <label className="block text-sm text-gray-500"> Name</label>
                  <input
                    type="text"
                    name="Assignment-name"
                    id="Assignment-name"
                    autoComplete="off"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="mt-1 shadow-sm focus:ring-primary-violet focus:border-primary-violet h-10 block w-full text-lg border border-gray-200 text-gray-500 px-3 rounded-sm"
                  />
                </div>
                <div className="col-span-8 sm:col-span-4 lg:col-span-6 xl:col-span-2">
                  <label className="block text-sm text-gray-500">Dates</label>
                  <Select
                    className="mt-1"
                    value={selectedDateType}
                    options={dates}
                    onChange={handleDateChange}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: "#008fbb",
                      },
                    })}
                  />
                </div>
                <div className="col-span-8 sm:col-span-8 lg:col-span-6 xl:col-span-4 xxxl:col-span-2 grid grid-cols-2 gap-6">
                  <div>
                    <label className="block text-sm text-gray-500" htmlFor="">
                      From{" "}
                    </label>
                    <DatePicker
                      wrapperClassName="w-full mt-1"
                      className="react-datepicker__input-container py-1.5 px-3 block w-full shadow-sm focus:ring-primary-violet focus:border-primary-violet border border-gray-300 rounded-md text-gray-500"
                      selected={selectedFromDate}
                      onChange={(date) => handleFromDateChange(date)}
                      minDate={schoolYearMinDate}
                      maxDate={schoolYearMaxDate}
                    />
                  </div>
                  <div>
                    <label className="block text-sm text-gray-500" htmlFor="">
                      To{" "}
                    </label>
                    <DatePicker
                      wrapperClassName="w-full"
                      className="react-datepicker__input-container mt-1 py-1.5 px-3 block w-full shadow-sm focus:ring-primary-violet focus:border-primary-violet border text-gray-500 border-gray-300 rounded-md "
                      selected={selectedToDate}
                      onChange={(date) => handleToDateChange(date)}
                      minDate={schoolYearMinDate}
                      maxDate={schoolYearMaxDate}
                    />
                  </div>
                </div>
                <div className="col-span-8 flex w-full gap-x-6">
                  <label
                    x-radio-group-option=""
                    className=" relative flex items-center cursor-pointer focus:outline-none border-gray-400"
                  >
                    <input
                      type="radio"
                      x-model="value"
                      name="assignment"
                      defaultChecked={true}
                      value="Public access"
                      className="h-4 w-4 cursor-pointer text-primary-violet border-gray-300 focus:ring-primary-violet"
                      aria-labelledby="privacy-setting-0-label"
                      aria-describedby="privacy-setting-0-description"
                      onChange={() => {
                        setIsArchived(false);
                        setIsUnassigned(false);
                      }}
                    />
                    <div className="ml-3 flex flex-col">
                      <span
                        id="privacy-setting-0-label"
                        className="block text-sm text-primary-violet"
                      >
                        Current
                      </span>
                    </div>
                  </label>
                  <label
                    x-radio-group-option=""
                    className=" relative flex items-center cursor-pointer focus:outline-none border-gray-400"
                  >
                    <input
                      type="radio"
                      x-model="value"
                      name="assignment"
                      value="Public access"
                      className="h-4 w-4 cursor-pointer text-primary-violet border-gray-300 focus:ring-primary-violet"
                      aria-labelledby="privacy-setting-0-label"
                      aria-describedby="privacy-setting-0-description"
                      onClick={() => {
                        setIsArchived(true);
                        setIsUnassigned(false);
                      }}
                    />
                    <div className="ml-3 flex flex-col">
                      <span
                        id="privacy-setting-0-label"
                        className="block text-sm text-primary-violet"
                      >
                        Archived
                      </span>
                    </div>
                  </label>
                </div>
              </div>
              <div className="flex w-full justify-end pb-5">
                <div className="flex gap-4">
                  <button
                    onClick={() => resetFilters()}
                    className="bg-transparent border my-auto border-primary-violet shadow-sm py-2 px-4 flex justify-center text-sm font-medium text-primary-violet hover:text-white hover:bg-light-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3 transition ease-in-out duration-500"
                  >
                    {" "}
                    Reset
                  </button>
                  <button
                    onClick={() => searchAssignments(true)}
                    className="bg-primary-violet border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-dark-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3 "
                  >
                    Filter
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-white py-5 px-5 shadow">
            <div className="flex flex-col lg:flex-row justify-between gap-x-3 mb-5 mt-2">
              <div className="flex gap-x-4 gap-y-3 mb-4 lg:mb-0 my-auto ">
                <AllowedTo perform={Permissions.assignments_modify}>
                  <button
                    disabled={selectedAssignments.length > 0 ? false : true}
                    onClick={() => handleConfirmation("Lock")}
                    className={`${
                      selectedAssignments.length > 0
                        ? "hover:text-white hover:bg-light-violet hover:shadow-lg"
                        : "border-opacity-50 text-opacity-50 cursor-not-allowed"
                    } bg-transparent border border-primary-violet shadow-sm py-2 px-4 inline-flex items-center justify-center text-sm font-medium text-primary-violet  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3 transition ease-in-out duration-500`}
                  >
                    <LockIcon className="w-4" />
                    <span className="ml-2">Lock/Unlock</span>
                  </button>
                </AllowedTo>

                <AllowedTo perform={Permissions.assignments_modify}>
                  <button
                    disabled={selectedAssignments.length > 0 ? false : true}
                    onClick={() =>
                      handleConfirmation(isArchived ? "Unarchive" : "Archive")
                    }
                    className={`${
                      selectedAssignments.length > 0
                        ? "hover:text-white hover:bg-light-violet hover:shadow-lg"
                        : "border-opacity-50 text-opacity-50 cursor-not-allowed"
                    } bg-transparent border border-primary-violet shadow-sm py-2 px-4 inline-flex items-center justify-center text-sm font-medium text-primary-violet  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3 transition ease-in-out duration-500`}
                  >
                    <ArchieveIcon />
                    <span className="ml-2">
                      {isArchived ? "Unarchive" : "Archive"}
                    </span>
                  </button>
                </AllowedTo>

                <AllowedTo perform={Permissions.assignments_delete}>
                  {!upgrade && (
                    <button
                      disabled={selectedAssignments.length > 0 ? false : true}
                      onClick={() => handleConfirmation("Delete")}
                      className={`${
                        selectedAssignments.length > 0
                          ? "hover:text-white hover:bg-red-500 hover:shadow-lg"
                          : "border-opacity-50 text-opacity-50 cursor-not-allowed"
                      } className="bg-transparent group border border-red-500 shadow-sm py-2 px-4 inline-flex items-center justify-center text-sm font-medium text-red-500 right-6 top-3 transition ease-in-out duration-500 "`}
                    >
                      <DeleteIcon className="w-4" />
                      <span className="ml-2">Delete</span>
                    </button>
                  )}
                  {upgrade && (
                    <button
                      onClick={() => setShowUpgradePopup(true)}
                      className="border-opacity-50 text-opacity-50 bg-transparent group border border-red-500 shadow-sm py-2 px-4 inline-flex items-center justify-center text-sm font-medium text-red-500 right-6 top-3 transition ease-in-out duration-500"
                    >
                      <DeleteIcon className="w-4" />
                      <span className="ml-2">Delete</span>
                    </button>
                  )}
                </AllowedTo>
              </div>
              <div className="flex flex-wrap gap-x-6 gap-y-2 items-center lg:justify-end ">
                <div className="flex items-center">
                  <GradingIcon className="w-4 text-gray-400" />
                  <span className="text-xs ml-2 text-gray-500">
                    Grading Required
                  </span>
                </div>

                {roleId !== Constant.UserRoleId.FreeTeacher && (
                  <div className="flex items-center">
                    <SharedByIcon />
                    <span className="text-xs ml-2 text-gray-500">
                      Shared by you
                    </span>
                  </div>
                )}
              </div>
            </div>
            <div className="mb-4 pb-4 border-b-2 overflow-x-auto xl:overflow-x-visible">
              <table className="table w-full ">
                <thead className="mb-5">
                  <tr className="bg-light-violet  text-white py-3 px-3 overflow-hidden">
                    <th className="text-left p-3 w-4 font-normal"></th>
                    <th className="text-left p-3 text-sm font-normal w-4">
                      <input
                        id="styled-checkbox-2"
                        type="checkbox"
                        className="checkbox"
                        checked={toggleSelectAll}
                        onChange={() => handleSelectAll()}
                      />
                    </th>
                    <th></th>
                    <th className="text-left p-3 text-sm font-normal w-4"></th>
                    {selectedDateType &&
                      selectedDateType.label === dateLabels.createDate && (
                        <th className="text-left  p-3 text-sm font-normal">
                          <span className="flex">
                            Create Date
                            <span className="cursor-pointer">
                              {sortByDesc && orderByColumn === "CreateDate" ? (
                                <CaretUpSolidIcon
                                  onClick={() => handleSortBy("CreateDate")}
                                  className="w-5 h-5"
                                ></CaretUpSolidIcon>
                              ) : (
                                <CaretDownSolidIcon
                                  onClick={() => handleSortBy("CreateDate")}
                                  className="w-5 h-5"
                                ></CaretDownSolidIcon>
                              )}
                            </span>
                          </span>
                        </th>
                      )}

                    {selectedDateType &&
                      selectedDateType.label === dateLabels.dueDate && (
                        <th className="text-left p-3 text-sm font-normal">
                          <span className="flex whitespace-nowrap">
                            Due Date
                            <span className="cursor-pointer">
                              {sortByDesc && orderByColumn === "DueDate" ? (
                                <CaretUpSolidIcon
                                  onClick={() => handleSortBy("DueDate")}
                                  className="w-5 h-5"
                                ></CaretUpSolidIcon>
                              ) : (
                                <CaretDownSolidIcon
                                  onClick={() => handleSortBy("DueDate")}
                                  className="w-5 h-5"
                                ></CaretDownSolidIcon>
                              )}
                            </span>
                          </span>
                        </th>
                      )}
                    {selectedDateType &&
                      selectedDateType.label === dateLabels.assignedDate && (
                        <th className="text-left  p-3 text-sm font-normal">
                          <span className="flex">
                            Assigned Date
                            <span className="cursor-pointer">
                              {sortByDesc &&
                              orderByColumn === "AssignedDate" ? (
                                <CaretUpSolidIcon
                                  onClick={() => handleSortBy("AssignedDate")}
                                  className="w-5 h-5"
                                ></CaretUpSolidIcon>
                              ) : (
                                <CaretDownSolidIcon
                                  onClick={() => handleSortBy("AssignedDate")}
                                  className="w-5 h-5"
                                ></CaretDownSolidIcon>
                              )}
                            </span>
                          </span>
                        </th>
                      )}
                    <th className="text-center  p-3 text-sm font-normal"></th>
                    <th className="text-left  p-3 text-sm font-normal">
                      <span className="flex">
                        Name
                        <span className="cursor-pointer">
                          {sortByDesc && orderByColumn === "name" ? (
                            <CaretUpSolidIcon
                              onClick={() => handleSortBy("name")}
                              className="w-5 h-5"
                            ></CaretUpSolidIcon>
                          ) : (
                            <CaretDownSolidIcon
                              onClick={() => handleSortBy("name")}
                              className="w-5 h-5"
                            ></CaretDownSolidIcon>
                          )}
                        </span>
                      </span>
                    </th>
                    <th className="text-center  p-3 text-sm font-normal">
                      Results
                    </th>
                    <th className="text-center p-3 text-sm font-normal">
                      Action
                    </th>
                    <th className="text-left  p-3 text-sm font-normal">
                      Assigned
                    </th>
                    <th className="text-center  p-3 text-sm font-normal">
                      Settings
                    </th>
                    <th className="text-center  p-3 text-sm font-normal">
                      Edit
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {assignments &&
                    assignments.data?.length > 0 &&
                    assignments.data?.map((assignment, index) => {
                      const hasMultipleActivities = isGroupedAssignment(
                        assignment.hasActicity,
                        assignment.activityCount
                      );
                      return (
                        <Fragment key={index}>
                          <tr
                            className={`hover:bg-light-violet/20 transition ease-in-out duration-300 ${
                              index % 2 === 0
                                ? "bg-white"
                                : "bg-light-violet/10"
                            }`}
                          >
                            <td className=" mt-5 p-3 text-center">
                              {hasMultipleActivities &&
                                selectedAssignmentId !==
                                  parseInt(assignment.assignmentId) && (
                                  <span title="Expand row ">
                                    <DownArrowIcon
                                      className="w-5 h-5 stroke-current text-gray-500 hover:text-gray-700 cursor-pointer"
                                      onClick={() =>
                                        handleActivityDetails(
                                          parseInt(assignment.assignmentId)
                                        )
                                      }
                                    />
                                  </span>
                                )}
                              {hasMultipleActivities &&
                                selectedAssignmentId ===
                                  parseInt(assignment.assignmentId) && (
                                  <span title="Collapse row">
                                    <UpArrow
                                      className="w-5 h-5 stroke-current text-gray-500 hover:text-gray-700 cursor-pointer"
                                      onClick={() =>
                                        handleActivityDetails(
                                          parseInt(assignment.assignmentId)
                                        )
                                      }
                                    />
                                  </span>
                                )}
                            </td>
                            <td className=" mt-5 p-3 text-center">
                              <input
                                id="styled-checkbox-2"
                                type="checkbox"
                                className="checkbox"
                                checked={selectedAssignments.some(
                                  (r) =>
                                    (r as number) ===
                                    parseInt(assignment.assignmentId)
                                )}
                                onChange={() =>
                                  handleSelectedAssignments(
                                    parseInt(assignment.assignmentId)
                                  )
                                }
                              />
                              <label className="checkbox-label"></label>
                            </td>
                            <td className="mt-5 pr-2">
                              {assignment.isSharedWithSchool && (
                                <SharedByIcon />
                              )}
                            </td>
                            <td className=" mt-5 p-3 text-center text-gray-500">
                              {assignment.isLocked ? (
                                <LockIcon
                                  title="Locked assignment"
                                  className="fill-current w-4"
                                />
                              ) : (
                                <UnLockIcon
                                  title="Open assignment"
                                  className="w-4"
                                />
                              )}
                            </td>

                            {selectedDateType &&
                              selectedDateType.label ===
                                dateLabels.createDate && (
                                <td className="text-sm mt-5  p-3">
                                  {Moment(assignment.createdDate).format(
                                    "MM-DD-yyyy"
                                  )}
                                </td>
                              )}

                            {selectedDateType &&
                              selectedDateType.label === "Due Date" && (
                                <td className="text-sm mt-5  p-3">
                                  {assignment.isAssigned &&
                                    assignment.endDate &&
                                    Moment(assignment.endDate).format(
                                      "MM-DD-yyyy"
                                    )}
                                  {!assignment.isAssigned && "Unassigned"}
                                </td>
                              )}

                            {selectedDateType &&
                              selectedDateType.label === "Assigned Date" && (
                                <td className="text-sm mt-5  p-3">
                                  {assignment.assignedDate &&
                                    Moment(assignment.assignedDate).format(
                                      "MM-DD-yyyy"
                                    )}
                                </td>
                              )}

                            <td className="text-sm mt-5 p-3 text-center">
                              {hasMultipleActivities && (
                                <>
                                  <GroupAssignmentIcon
                                    title="Group assignments"
                                    className="w-3.5 mx-auto cursor-pointer opacity-70 stroke-current hover:opacity-100 inline-flex"
                                    onClick={() =>
                                      handleActivityDetails(
                                          parseInt(assignment.assignmentId)
                                      )
                                    }
                                  />
                                  {(assignment.unGraded ?? 0) > 0 && (
                                    <GradingIcon
                                      title="Constructed Response"
                                      className={
                                        "inline-flex ml-2 w-5 h-5 text-red-500 hover:text-red-700"
                                      }
                                    />
                                    )}
                                </>
                              )}
                              {!hasMultipleActivities && (assignment.unGraded ?? 0) > 0 && (
                                <GradingIcon
                                title="Constructed Response"
                                className={"inline-flex ml-2 w-5 h-5 text-red-500 hover:text-red-700 cursor-pointer"}
                                onClick={() =>
                                    handleResultsClick(
                                            parseInt(assignment.assignmentId),
                                            assignment.assignmentActivity
                                                ? assignment.assignmentActivity
                                                    .assignmentActivityId
                                                : 0,
                                            assignment.assignmentActivity
                                                ?.activityType ?? "",
                                            assignment.assignmentActivity
                                                ?.activityType ===
                                            Constant.AssignmentActivityType
                                                .CONSTRUCTEDRESPONSE ||
                                            assignment.assignmentActivity
                                                ?.isAssessmentWithCR!,
                                            assignment.assignmentActivity
                                                ?.assessmentId,
                                            assignment.assignmentActivity
                                                ?.canViewAssessment,
                                            assignment.assignmentActivity
                                                ?.assessmentSharedTypeId,
                                            assignment.assignmentActivity
                                                ?.assessmentCreatedByUserId,
                                            assignment.subjectId
                                        )}
                                />
                            )}
                            </td>
                            <td className="text-sm mt-5  p-3">
                              {!assignment.hasActicity && (
                                <Fragment>
                                  <a
                                    href="#!"
                                    className="text-blue-500 underline hover:no-underline"
                                    onClick={() =>
                                      handleSettingPopup(assignment)
                                    }
                                  >
                                    {assignment.name}
                                  </a>
                                </Fragment>
                              )}
                              {assignment.hasActicity &&
                                assignment.assignmentActivity &&
                                assignment.activityCount >= 1 && (
                                  <div>
                                    <div className="flex items-center">
                                      {assignment.name}
                                      {assignment.isCanvasAssignment && (
                                        <div className="ml-1 pointer-events-auto inline-flex items-baseline px-2 py-0 rounded-full text-xs font-medium text-dark-blue md:mt-2 lg:mt-0">
                                          <CanvasNewIcon className="w-4 h-4" />
                                        </div>
                                      )}
                                      {assignment.isGoogleAssignment && (
                                        <div className="ml-1 pointer-events-auto inline-flex items-baseline px-2 py-0 rounded-full text-xs font-medium text-dark-blue md:mt-2 lg:mt-0">
                                          <GoogleClassroomIcon className="w-4 h-4" />
                                        </div>
                                      )}
                                    </div>
                                    <div className="flex items-center">
                                      <div className="w-60">
                                        <a
                                          href="#!"
                                          className="text-blue-500 underline hover:no-underline"
                                          onClick={() =>
                                            handleSettingPopup(
                                              assignment,
                                              assignment.assignmentActivity
                                            )
                                          }
                                          dangerouslySetInnerHTML={{
                                            __html: getActivityName(
                                              assignment.assignmentActivity
                                            ),
                                          }}
                                        ></a>
                                      </div>
                                    </div>
                                  </div>
                                )}
                            </td>
                            <td
                              className={`text-sm mt-5 text-center p-3  ${
                                assignment.hasActicity &&
                                assignment.activityCount === 1 &&
                                assignment.completed > 0 &&
                                isAllowViewAssignmentAssessment(
                                  props.roleId,
                                  assignment.assignmentActivity
                                    ?.canViewAssessment,
                                  assignment.assignmentActivity?.activityType,
                                  assignment.assignmentActivity
                                    ?.assessmentCreatedByUserId,
                                  props.userId,
                                  assignment.assignmentActivity
                                    ?.assessmentSharedTypeId
                                )
                                  ? "cursor-pointer text-blue-500 underline hover:no-underline"
                                  : ""
                              }`}
                              onClick={() =>
                                assignment.activityCount === 1 &&
                                assignment.completed > 0
                                  ? handleResultsClick(
                                      parseInt(assignment.assignmentId),
                                      assignment.assignmentActivity
                                        ? assignment.assignmentActivity
                                            .assignmentActivityId
                                        : 0,
                                      assignment.assignmentActivity
                                        ?.activityType ?? "",
                                      assignment.assignmentActivity
                                        ?.activityType ===
                                        Constant.AssignmentActivityType
                                          .CONSTRUCTEDRESPONSE ||
                                        assignment.assignmentActivity
                                          ?.isAssessmentWithCR!,
                                      assignment.assignmentActivity
                                        ?.assessmentId,
                                      assignment.assignmentActivity
                                        ?.canViewAssessment,
                                      assignment.assignmentActivity
                                        ?.assessmentSharedTypeId,
                                      assignment.assignmentActivity
                                        ?.assessmentCreatedByUserId,
                                      assignment.subjectId
                                    )
                                  : undefined
                              }
                            >
                              {assignment.hasActicity &&
                              assignment.activityCount === 1
                                ? `${assignment.completed}/${assignment.assigned}`
                                : ""}
                              {hasMultipleActivities &&
                                selectedAssignmentId !==
                                  parseInt(assignment.assignmentId) && (
                                  <a
                                    className="cursor-pointer text-blue-500 underline hover:no-underline"
                                    onClick={() =>
                                      handleActivityDetails(
                                        parseInt(assignment.assignmentId)
                                      )
                                    }
                                  >
                                    Expand to view
                                  </a>
                                )}
                              {hasMultipleActivities &&
                                selectedAssignmentId ===
                                  parseInt(assignment.assignmentId) && (
                                  <a
                                    className="cursor-pointer text-blue-500 underline hover:no-underline"
                                    onClick={() =>
                                      handleActivityDetails(
                                        parseInt(assignment.assignmentId)
                                      )
                                    }
                                  >
                                    Collapse view
                                  </a>
                                )}
                            </td>
                            <td className="text-sm mt-5 p-3">
                              <ContextMenu
                                items={getOptions(
                                  hasMultipleActivities,
                                  true,
                                  Number(assignment.author),
                                  props.userId,
                                  showPrintOption(
                                    assignment.assignmentActivity
                                      ?.activityTypeId ?? 0
                                  )
                                ).filter((r) =>
                                  r.roles.some((r) => r === roleId)
                                )}
                                entityId={
                                  hasMultipleActivities
                                    ? assignment.assignmentId
                                      ? parseInt(assignment.assignmentId)
                                      : 0
                                    : assignment.assignmentActivity
                                        ?.assignmentActivityId
                                }
                                onChange={handleOptionChange}
                                learnosityId={
                                  assignment.assignmentActivity
                                    ?.learnosityIdEnglish
                                }
                                activityName={assignment.name}
                                activityType={getContextMenuActivityType(
                                  hasMultipleActivities
                                )}
                                value={""}
                                onUpgrade={() => {}}
                              />
                            </td>
                            <td className="text-sm mt-5  p-3 text-left">
                              {assignment.isAssigned === true ? "Y" : "N"}
                            </td>
                            <td className="text-sm mt-5  p-3 cursor-pointer">
                              <OutlineSettingIcon
                                title="Edit assignment settings"
                                className={
                                  (isPreviousYear ? "opacity-25 " : "") +
                                  "w-4 m-auto text-gray-500 hover:text-gray-700 cursor-pointer"
                                }
                                onClick={() =>
                                  handleEditAssignmentPopup(
                                    assignment.assignmentId
                                  )
                                }
                              />
                            </td>
                            <td className="text-sm mt-5 p-3 cursor-pointer">
                              {assignment.assignmentStatus.toLocaleUpperCase() ===
                                Constant.AssignmentStatus.NEW ||
                              assignment.assignmentStatus.toLocaleUpperCase() ===
                                Constant.AssignmentStatus.DRAFT ? (
                                <EditOutlineIcon
                                  title="edit assignment"
                                  onClick={() =>
                                    handleRedirect(
                                      assignment.assignmentId,
                                      "Edit"
                                    )
                                  }
                                  className={
                                    (isPreviousYear ? "opacity-25 " : "") +
                                    "w-4 text-gray-500 hover:text-gray-700 cursor-pointer m-auto"
                                  }
                                />
                              ) : (
                                <EditIcon
                                  title="Edit assignment"
                                  onClick={() =>
                                    handleEditConfirmation(
                                      parseInt(assignment.assignmentId)
                                    )
                                  }
                                  className={
                                    (isPreviousYear ? "opacity-25 " : "") +
                                    "w-4 text-gray-500 hover:text-gray-700 cursor-pointer m-auto"
                                  }
                                />
                              )}
                            </td>
                          </tr>
                          {assignmentActivities &&
                            assignmentActivities.length > 0 &&
                            assignmentActivities[0].assignmentId ===
                              parseInt(assignment.assignmentId) &&
                            assignmentActivities.map(
                              (assignmentActivity, index) => {
                                return (
                                  <tr
                                    key={index}
                                    className={`hover:bg-light-violet/20 transition ease-in-out duration-300 ${
                                      index % 2 === 0
                                        ? "bg-white"
                                        : "bg-light-violet/10"
                                    }`}
                                  >
                                    <td className=" mt-5 p-3"></td>
                                    <td className="mt-5"></td>
                                    <td className=" mt-5 p-3"></td>
                                    <td className=" mt-5 p-3"></td>
                                    {selectedDateType &&
                                      selectedDateType.label !== "Select" && (
                                        <td className=" mt-5 p-3"></td>
                                      )}

                                    <td className="text-sm mt-5  p-3 "></td>
                                    <td className="text-sm mt-5  p-3">
                                      <div className="flex items-center">
                                        <div className="mr-2 w-4 ">
                                          {(assignmentActivity.unGraded ?? 0) > 0 && (
                                              <GradingIcon
                                                title="Constructed Response"
                                                className=" w-5 h-5 text-red-500 hover:text-red-700 cursor-pointer"
                                                onClick={(e) =>
                                                  assignmentActivity.completed &&
                                                  assignmentActivity.completed >
                                                    0
                                                    ? handleResultsClick(
                                                        parseInt(
                                                          assignment.assignmentId
                                                        ),
                                                        assignmentActivity.assignmentActivityId,
                                                        assignmentActivity.activityType,
                                                        true,
                                                        assignmentActivity.assessmentId,
                                                        assignmentActivity?.canViewAssessment,
                                                        assignmentActivity?.assessmentSharedTypeId,
                                                        assignmentActivity?.assessmentCreatedByUserId,
                                                        assignment.subjectId
                                                      )
                                                    : undefined
                                                }
                                              />
                                            )}
                                        </div>
                                        <div>
                                          <a
                                            href="#!"
                                            className="text-blue-500 underline hover:no-underline"
                                            onClick={() =>
                                              handleSettingPopup(
                                                assignment,
                                                assignmentActivity
                                              )
                                            }
                                            dangerouslySetInnerHTML={{
                                              __html:
                                                getActivityName(
                                                  assignmentActivity
                                                ),
                                            }}
                                          ></a>
                                        </div>
                                      </div>
                                    </td>
                                    <td
                                      className={`text-sm mt-5 text-center p-3  ${
                                        assignmentActivity.completed &&
                                        assignmentActivity.completed > 0 &&
                                        isAllowViewAssignmentAssessment(
                                          props.roleId,
                                          assignmentActivity?.canViewAssessment,
                                          assignmentActivity?.activityType,
                                          assignmentActivity?.assessmentCreatedByUserId,
                                          props.userId,
                                          assignmentActivity?.assessmentSharedTypeId
                                        )
                                          ? "cursor-pointer text-blue-500 underline hover:no-underline"
                                          : ""
                                      }`}
                                      onClick={(e) =>
                                        assignmentActivity.completed &&
                                        assignmentActivity.completed > 0
                                          ? handleResultsClick(
                                              parseInt(assignment.assignmentId),
                                              assignmentActivity.assignmentActivityId,
                                              assignmentActivity.activityType,
                                              assignmentActivity.activityType ===
                                                Constant.AssignmentActivityType
                                                  .CONSTRUCTEDRESPONSE ||
                                                assignmentActivity?.isAssessmentWithCR!,
                                              assignmentActivity.assessmentId,
                                              assignmentActivity?.canViewAssessment,
                                              assignmentActivity?.assessmentSharedTypeId,
                                              assignmentActivity?.assessmentCreatedByUserId,
                                              assignment.subjectId
                                            )
                                          : undefined
                                      }
                                    >
                                      {`${
                                        assignmentActivity.completed
                                          ? assignmentActivity.completed
                                          : 0
                                      }/${assignment.assigned}`}
                                    </td>
                                    <td className="text-sm mt-5 p-3">
                                      <ContextMenu
                                        items={getOptions(
                                          false,
                                          false,
                                          Number(assignment.author),
                                          props.userId,
                                          showPrintOption(
                                            assignmentActivity.activityTypeId
                                          )
                                        ).filter((r) =>
                                          r.roles.some((r) => r === roleId)
                                        )}
                                        entityId={
                                          assignmentActivity.assignmentActivityId
                                        }
                                        onChange={handleOptionChange}
                                        learnosityId={
                                          assignmentActivity?.learnosityIdEnglish
                                        }
                                        activityName={getActivityName(
                                          assignmentActivity
                                        )}
                                        activityType={
                                          constant.ContextMenuActivityType
                                            .GROUPEDACTIVITYASSIGNMENT
                                        }
                                        value={""}
                                        onUpgrade={() => {}}
                                      />
                                    </td>
                                    <td className="text-sm mt-5  p-3 text-center"></td>
                                    <td className="text-sm mt-5  p-3 cursor-pointer"></td>
                                    <td className="text-sm mt-5  p-3 cursor-pointer"></td>
                                  </tr>
                                );
                              }
                            )}
                        </Fragment>
                      );
                    })}
                  {assignments?.data.length === 0 && (
                    <tr>
                      <td colSpan={12}>
                        <div className="text-gray-700 w-full mt-2">
                          No records found
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="sm:flex-1 sm:flex sm:items-center sm:justify-between">
              {assignments?.totalRecords !== undefined &&
                assignments?.totalRecords !== 0 && (
                  <Pagination
                    totalRecords={assignments?.totalRecords}
                    pageSize={pageSize}
                    onPageChange={onPageChange}
                    reset={resetPageNumber}
                    pageNumber={pageNumber}
                  />
                )}
              {assignments?.totalRecords !== 0 && (
                <PageSize
                  pageSize={pageSize}
                  onPageSizeChange={onPageSizeChange}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </AllowedTo>
  );
}

export default MyAssignment;

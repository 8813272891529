import axios from "axios";
import auth from "../../../utils/auth";
import { Configuration } from "../../../environment/setup";

export function updateContactUs(
  userId: number,
  roleId: number,
  emailId: string,
  message: string
) {
  const token = auth.getAuthToken();
  const url = `${Configuration.ApiBaseUrl}Profile/UpdateContactUs/${userId}/${roleId}/${emailId}/${message}`;

  return axios.get(url, {
    headers: {
      Authorization: `bearer ${token}`,
    },
  });
}

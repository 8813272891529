import React, { useState, useEffect } from "react";
import ProgressBar from "./progressBar";

export default function ProgressWidget({ data }: any) {
  const [radioValue, setRadioValue] = useState<string>();
  const [viewPracticeNeeded, setViewPracticeNeeded] = useState<boolean>();
  const [isGraded, setIsGraded] = useState<boolean>(false);

  useEffect(() => {
    let isView: any = false;

    if (data.results_header) {
      const percent = data.results_header.assignment.result.percent;
      isView = percent < 80 ? true : false;
      setIsGraded(data?.results_header?.assignment?.result?.not_graded === 1);
    }

    setViewPracticeNeeded(isView);
    setRadioValue(isView ? "1" : "2");
  }, []);

  const badgeBgColor = (value, isCompleted) => {
    let badgeBg = "";
    switch (true) {
      case value < 56:
        badgeBg = "bg-[#BB0070]";
        break;
      case value >= 56 && value <= 85:
        badgeBg = "bg-[#F4D220]";
        break;
      case value > 85:
        badgeBg = "bg-site-green";
        break;
      case value > 85 && isCompleted:
        badgeBg = "bg-site-gold";
        break;
      default:
        badgeBg = "badge-bg-grey";
        break;
    }

    return badgeBg;
  };

  const practiceNeeded = () => {
    if (viewPracticeNeeded) {
      const percentLessThan80: any = [];
      if (data.domains) {
        Object.entries(data.elements).map(([key, value]: any) => {
          const percent = value.retry_percent_correct
            ? value.retry_percent_correct
            : value.percent_correct || 0;
          if (percent < 80)
            percentLessThan80.push({ ...value, isPracticeOpen: false });
          return null;
        });
      }

      return (
        <div>
          {Object.entries(percentLessThan80).map(([key, value]: any, index) => {
            return (
              <div key={key}>
                <ProgressBar
                  styleProps={{
                    tracker: index,
                    header: `${value.name}`,
                    subHeader: "",
                    remediationLinks: value.remediation_links,
                    bars: [
                      {
                        barColor: "bg-[#BB0070]",
                        percent: value.percent_correct || 0,
                      },
                      {
                        barColor: "bg-site-teal",
                        percent: value.retry_percent_correct || 0,
                      },
                    ],
                    barBackgroundColor: "bg-gray-300",
                    badgeText: `${
                      value.retry_correct ? value.retry_correct : value.correct
                    }/${value.total_points || 0}`,
                    badgeTextColor: "text-white",
                    badgeColor: `${badgeBgColor(
                      (value.retry_correct
                        ? value.retry_correct
                        : value.correct * 100) / value.total_points || 0,
                      false
                    )}`,
                    // percentText: `${
                    //   (value.correct * 100) / value.total_points||0
                    // }`,
                    percentText: value.retry_percent_correct
                      ? value.retry_percent_correct
                      : value.percent_correct || 0,
                  }}
                />
              </div>
            );
          })}
          {percentLessThan80.length === 0 && (
            <div className="px-9 md:px-4 lg:px-4 xl:px-9 2xl:px-9 3xl:px-9 pb-4">
              {" "}
              No Data Available
            </div>
          )}

          {/* <h1 className="text-sm font-medium uppercase px-7 py-7 font-medium">
            additional practice
          </h1> */}
        </div>
      );
    } else {
      <div
        className={"bg-gray-100  py-5 px-8"}
        style={{ margin: "-10px -30px 0px -30px", zIndex: 10 }}
      ></div>;
    }
  };

  const domain = () => {
    const domains: any = [];
    if (data.domains) {
      Object.entries(data.domains).map(([key, value]: any) => {
        domains.push(value);
        return null;
      });
    }

    return (
      <div>
        {Object.entries(domains).map(([key, value]: any, index) => {
          return (
            <div
              className={`flex flex-none flex-col pt-4 pb-1 ${
                index % 2 === 0 ? "bg-gray-200 bg-opacity-50" : "bg-white"
              }`}
              key={key}
            >
              <ProgressBar
                styleProps={{
                  tracker: index,
                  header: `${value.name}`,
                  subHeader: "",
                  bars: [
                    {
                      barColor: "bg-chart-blue",
                      percent: value.percent_correct || 0,
                    },
                    {
                      barColor: "bg-site-teal",
                      percent: value.retry_percent_correct || 0,
                    },
                  ],
                  barBackgroundColor: "bg-gray-300",
                  badgeColor: `${badgeBgColor(
                    (value.retry_correct
                      ? value.retry_correct
                      : value.correct * 100) / value.total_points || 0,
                    false
                  )}`,
                  badgeText: `${
                    value.retry_correct ? value.retry_correct : value.correct
                  }/${value.total_points || 0}`,
                  badgeTextColor: "text-white",
                  percentText: value.retry_percent_correct
                    ? value.retry_percent_correct
                    : value.percent_correct || 0,
                }}
                totalVideoQuestions={value.totalVideoQuestions}
              />
            </div>
          );
        })}
      </div>
    );
  };

  const standard = () => {
    const elements: any = [];
    if (data.elements) {
      Object.entries(data.elements).map(([key, value]: any) => {
        elements.push(value);
        return null;
      });
    }

    return (
      <div>
        {Object.entries(elements).map(([key, value]: any, index) => {
          return (
            <div
              className={`flex flex-none flex-col pt-4 pb-1 ${
                index % 2 === 0 ? "bg-gray-200 bg-opacity-50" : "bg-white"
              }`}
              key={key}
            >
              <ProgressBar
                styleProps={{
                  tracker: index,
                  header: `${value.name}`,
                  subHeader: "",
                  bars: [
                    {
                      barColor: "bg-chart-blue",
                      percent: value.percent_correct || 0,
                    },
                    {
                      barColor: "bg-site-teal",
                      percent: value.retry_percent_correct || 0,
                    },
                  ],
                  barBackgroundColor: "bg-gray-300",
                  badgeColor: `${badgeBgColor(
                    (value.retry_correct
                      ? value.retry_correct
                      : value.correct * 100) / value.total_points || 0,
                    false
                  )}`,
                  badgeText: `${
                    value.retry_correct ? value.retry_correct : value.correct
                  }/${value.total_points || 0}`,
                  badgeTextColor: "text-white",
                  percentText: value.retry_percent_correct
                    ? value.retry_percent_correct
                    : value.percent_correct || 0,
                }}
                totalVideoQuestions={value.totalVideoQuestions}
              />
            </div>
          );
        })}
      </div>
    );
  };

  const onRadioChange = (e) => {
    setRadioValue(e.target.value);
  };

  return (
    <>
      <div className="flex flex-col xs:flex-row items-center space-y-4 xs:space-y-0 justify-between md:space-x-1 px-7 md:px-2 lg:px-2 xl:px-7 2xl:px-7 3xl:px-7 py-7">
        <div className="flex w-full xs:w-auto items-end lg:ml-2">
          <input
            className="focus:ring-radio-blue h-4 w-4 text-radio-blue border-gray-900 relative bottom-1"
            key={"1"}
            name="site_name"
            type="radio"
            value={1}
            defaultChecked={viewPracticeNeeded}
            onChange={(e) => onRadioChange(e)}
          />
          <div className="ml-3 flex flex-col">
            <span
              id="privacy-setting-0-label"
              className="text-black-333 w-full text-sm font-medium"
            >
              Practice
            </span>
            <span
              id="privacy-setting-0-description"
              className="text-black block text-xl font-semibold w-full"
            >
              Needed
            </span>
          </div>
        </div>
        <div className="flex w-full xs:w-auto items-end">
          <input
            name="site_name"
            type="radio"
            key={"2"}
            value={2}
            defaultChecked={!viewPracticeNeeded}
            onChange={(e) => onRadioChange(e)}
            className="focus:radio-blue h-4 w-4 text-radio-blue border-gray-900 relative bottom-1"
          />
          <div className="ml-3 flex flex-col">
            <span
              id="privacy-setting-1-label"
              className="text-black-333 w-full text-sm font-medium"
            >
              Scores by
            </span>
            <span
              id="privacy-setting-1-description"
              className="text-gray-400 block text-xl font-semibold w-full"
            >
              Domain
            </span>
          </div>
        </div>
        <div className="flex w-full xs:w-auto items-end lg:mr-2">
          <input
            key={"3"}
            type="radio"
            name="site_name"
            value={3}
            defaultChecked={false}
            onChange={(e) => onRadioChange(e)}
            className="focus:radio-blue h-4 w-4 text-radio-blue border-gray-900 relative bottom-1"
          />
          <div className="ml-3 flex flex-col">
            <span
              id="privacy-setting-2-label"
              className="text-black-333 w-full text-sm font-medium"
            >
              Scores by
            </span>
            <span
              id="privacy-setting-2-description"
              className="text-gray-400 block text-xl font-semibold w-full"
            >
              Standard
            </span>
          </div>
        </div>
      </div>

      {viewPracticeNeeded &&
        data &&
        data.results_header &&
        data.results_header.assignment &&
        data.results_header.assignment.result &&
        data.results_header.assignment.result.retry_percent && (
          <div className="flex flex-none justify-between px-7 py-7">
            <div className="flex items-center">
              <p className="sm-text font-medium">Original</p>
              <span className="w-6 h-6 ml-3 inline-block bg-chart-blue border border-black rounded-md sm-text font-medium "></span>
            </div>

            <div className="flex items-center">
              Retry
              <span className="w-6 h-6 ml-3 inline-block bg-site-teal border border-black rounded-md sm-text font-medium "></span>
            </div>
          </div>
        )}

      {!viewPracticeNeeded && radioValue === "1" && !isGraded && (
        <div className="grid  grid-flow-col text-center">
          <div className={"my-8"}>
            <div className={"text-lg capitalize font-semibold"}>
              Congratulations! You scored over 80% in all standards!
            </div>
          </div>
        </div>
      )}

      {radioValue === "1" && !isGraded && practiceNeeded()}
      {radioValue === "2" && !isGraded && domain()}
      {radioValue === "3" && !isGraded && standard()}
    </>
  );
}

import { XIcon } from "@heroicons/react/outline";
import { useState } from "react";
import ClassModel from "../../../../../../model/teacher/classManagement/classModel";
import InfoMessage from "../../../../../shared/infoMessage";

interface ArcadeAvatarPopupProps {
  showPopUp: (show: boolean) => void;
  handleSave: (classDetails: ClassModel) => void;
  classDetail: ClassModel;
}

const ArcadeAvatarPopup = (props: ArcadeAvatarPopupProps) => {
  const [isLocked, setIsLocked] = useState<boolean>(
    props.classDetail.isGameLocked
  );
  const [startTime, setStartTime] = useState<string>(
    props.classDetail.avatarLockStartTime === undefined ||
      props.classDetail.avatarLockStartTime === null
      ? props.classDetail.startTime
      : props.classDetail.avatarLockStartTime
  );
  const [endTime, setEndTime] = useState<string>(
    props.classDetail.avatarLockEndTime === undefined ||
      props.classDetail.avatarLockEndTime === null
      ? props.classDetail.endTime
      : props.classDetail.avatarLockEndTime
  );

  const handleSave = () => {
    const updatedClassDetail = props.classDetail;
    //updatedClassDetail.isAvatarLocked = isLocked;
    updatedClassDetail.isGameLocked = isLocked;
    updatedClassDetail.avatarLockStartTime = startTime;
    updatedClassDetail.avatarLockEndTime = endTime;
    props.handleSave(updatedClassDetail);
  };

  return (
    <div className="fixed inset-0 z-40 flex items-center bg-black bg-opacity-50 sm:items-center sm:justify-center enter-done">
      <div
        className="w-full overflow-hidden bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:max-w-2xl"
        role="dialog"
      >
        <div className="flex-1">
          {/* Header */}
          <div className="px-4 py-4 bg-gray-50 sm:px-4">
            <div className="flex items-start justify-between space-x-3">
              <div className="space-y-1">
                <div className="text-lg font-medium text-gray-900">
                  Arcade Lock setting
                </div>
              </div>
              <div className="h-7 flex items-center">
                <button
                  type="button"
                  className="text-gray-400 hover:text-gray-500"
                  onClick={() => props.showPopUp(false)}
                >
                  <span className="sr-only">Close panel</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
          <div className="py-1 px-4">
            <InfoMessage
              message="Enter the time for your class period to enable Arcade Lock. 
              This will lock all students within this class from accessing Game Arcade for the time chosen. You may turn this off at any time."
            />
          </div>

          {/* <!-- Divider container -->  */}
          <div className="text-sm text-gray-700 px-4 grid grid-cols-12 space-y-2 sm:space-y-0 sm:space-x-6">
            <div className="col-span-12 sm:col-span-3">
              <label
                htmlFor="start-time"
                className="block text-sm text-gray-500"
              >
                Start Time
              </label>
              <input
                id="start-time"
                type="time"
                className="mt-1 py-1.5 px-3 block w-full shadow-sm focus:ring-primary-violet focus:border-primary-violet border border-gray-300 rounded-md cursor-pointer"
                name="avatarLockStartTime"
                onChange={(e) => {
                  setStartTime(e.target.value);
                }}
                value={startTime}
              />
            </div>
            <div className="col-span-12 sm:col-span-3">
              <label
                htmlFor="start-time"
                className="block text-sm text-gray-500"
              >
                End Time
              </label>
              <input
                id="end-time"
                type="time"
                className="mt-1 py-1.5 px-3 block w-full shadow-sm focus:ring-primary-violet focus:border-primary-violet border border-gray-300 rounded-md cursor-pointer"
                name="avatarLockEndTime"
                onChange={(e) => {
                  setEndTime(e.target.value);
                }}
                value={endTime}
              />
            </div>
            <div className="col-span-12 sm:col-span-2 flex items-end">
              <div className="flex items-center pt-3">
                <input
                  id="locked"
                  type="checkbox"
                  onChange={() => {
                    setIsLocked(!isLocked);
                  }}
                  checked={isLocked}
                  className="py-1.5 px-3 "
                />
                <label
                  htmlFor="locked"
                  className="block ml-2 text-sm text-gray-500"
                >
                  Locked
                </label>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Action Buttons -->  */}
        <footer className="flex mt-4 items-center justify-end px-6 py-4 sm:flex-row bg-gray-50 dark:bg-gray-800">
          <div className="flex-shrink-0">
            <div className="space-x-3 flex justify-end">
              <button
                className="align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 rounded-lg text-sm text-white border border-transparent bg-primary-green focus:shadow-outline-purple w-full sm:w-auto"
                type="button"
                onClick={() => {
                  handleSave();
                }}
              >
                {" "}
                Save
              </button>
              <button
                className="align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 rounded-lg text-sm text-gray-600 border-gray-300 border dark:text-gray-400 focus:outline-none active:bg-transparent hover:border-gray-500 focus:border-gray-500 active:text-gray-500 focus:shadow-outline-gray w-full sm:w-auto"
                type="button"
                onClick={() => {
                  props.showPopUp(false);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default ArcadeAvatarPopup;

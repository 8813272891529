import React, { useState, useEffect, useRef, ReactText } from "react";
import { getAllTags, addUserTags } from "../../api/teacher/tags";
import { ApproveIcon, CancelIcon, TagIcon, PlusIcon } from "../../assets/icons";
import { TagColor } from "../shared/tagColor";
import { ITagItem } from "../../model/teacher/tags/tagItem";
import { toast } from "react-toastify";
import userTag from "../../model/teacher/tags/userTag";

const TagSelector: React.FC<any> = (props: {
  label: string;
  userId: number;
  selectedTagIds?: number[];
  title?: string;
  showSelectedTags: boolean;
  onTagSelectChange: (tags: ITagItem) => void;
  onTagButtonClicked: () => void;
  onNewTagCreated: () => void;
}) => {
  const defaultColor = "#F3F4F6";
  const toastId = React.useRef<ReactText>("");
  const [tags, setTags] = useState<ITagItem[]>([]);
  const [filter, setFilter] = useState<string>("");
  const [newTagName, setNewTagName] = useState<string>("");
  const [manageTag, setManageTag] = useState<boolean>(false);
  const tagRef: any = useRef(null);
  const tagIconRef: any = useRef(null);

  useEffect(() => {
    getAllUserTags();
  }, []);

  useEffect(() => {
    const newTags = tags.map((t) => {
      t.selected = props.selectedTagIds
        ? props.selectedTagIds.indexOf(t.tagId) >= 0
        : false;
      return t;
    });
    setTags(newTags);
  }, [props.selectedTagIds]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        tagRef.current &&
        !tagRef.current.contains(event.target) &&
        tagIconRef.current &&
        !tagIconRef.current.contains(event.target)
      ) {
        setManageTag(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [tagRef]);

  const getAllUserTags = () => {
    getAllTags(props.userId).then((resp) => {
      const tags: ITagItem[] = resp.data.data.map((t) => {
        return {
          tagId: t.userTagId,
          name: t.name,
          color: t.color,
          selected:
            props.selectedTagIds &&
            props.selectedTagIds.indexOf(t.userTagId) >= 0,
          description: t.description,
          isActive: t.isActive,
        };
      });
      setTags(tags);
    });
  };

  const handleSelectChangeClick = (e, tag, selected) => {
    const allTags = tags.map((item) => {
      if (item.tagId === tag.tagId) {
        item.selected = selected;
      }
      return item;
    });
    tag.selected = selected;
    setTags(allTags);
    if (typeof props.onTagSelectChange === "function") {
      props.onTagSelectChange(tag);
    }
  };

  const handleTagNameChange = (name: string) => {
    setNewTagName(name);
  };

  const validate = () => {
    let isValid = true;
    const tag = tags.filter((t) => t.name === newTagName);

    // Prevent duplicate notification
    if (toast.isActive(toastId.current)) {
      return false;
    }

    if (tag.length > 0) {
      toastId.current = toast.error("Tag name already exists");
      isValid = false;
    } else if (newTagName === "") {
      toastId.current = toast.error("Please enter tag name");
      isValid = false;
    }

    return isValid;
  };

  const addNewTag = () => {
    if (validate()) {
      const defaultTag: userTag = {
        userTagId: 0,
        name: newTagName,
        isActive: true,
        description: "",
        color: defaultColor,
        categoryId: 1,
        userId: props.userId,
      };
      addUserTags(defaultTag).then(() => {
        getAllUserTags();
        toast.success("Tags saved successfully.");
        setNewTagName("");
        if (props.onNewTagCreated !== undefined) props.onNewTagCreated();
      });
    }
  };

  return (
    <>
      <div className="flex flex-col">
        <div
          className="flex gap-1 text-sm text-gray-700 cursor-pointer"
          onClick={() => {
            setManageTag(!manageTag);
            if (typeof props.onTagButtonClicked === "function") {
              props.onTagButtonClicked();
            }
          }}
          ref={tagIconRef}
        >
          <div className="flex border p-2 rounded-lg" title={props.title}>
            <TagIcon className="h-5 w-5 cursor-pointer text-gray-400 hover:text-gray-600" />
            {props.label}
            <PlusIcon className="ml-2 h-5 w-5 cursor-pointer text-gray-400 hover:text-gray-600" />
          </div>
        </div>
        {props.showSelectedTags && (
          <div className="flex gap-1 flex-wrap">
            {tags
              .filter((item) => item.selected === true)
              .map((item, index) => (
                <div key={item.tagId} className={"flex h-8 items-center"}>
                  <div className={"flex flex-1 min-w-0"}>
                    <span
                      className={`inline-block bg-[${item.color}] ${
                        TagColor.isDark(item.color) ? "text-white" : ""
                      } rounded-full text-xs shadow-sm py-0.5 px-2 overflow-hidden truncate flex`}
                    >
                      {item.name}
                      <div className={"flex w-4 ml-1 justify-center"}>
                        {item.selected && (
                          <CancelIcon
                            className={
                              "w-4 text-xs font-light cursor-pointer hover:text-red-500 transaction ease-in-out duration-300"
                            }
                            onClick={(e) => {
                              handleSelectChangeClick(e, item, false);
                            }}
                          ></CancelIcon>
                        )}
                      </div>
                    </span>
                  </div>
                </div>
              ))}
          </div>
        )}
        {manageTag && (
          <div
            className=" bg-white absolute z-50  border shadow-md"
            ref={tagRef}
          >
            <div className="flex-col relative">
              <div className="px-4 py-2 text-sm text-gray-700">
                {props.label}
              </div>
              <div className="px-4 border-b border-t py-2">
                <input
                  id="search"
                  name="search"
                  type="text"
                  autoComplete="off"
                  value={filter}
                  className="block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-primary-color focus:border-primary-color dark:focus:border-gray-500 dark:focus:ring-gray-500 dark:bg-custom-black dark:text-white sm:text-sm "
                  onChange={(e) => setFilter(e.target.value)}
                />
              </div>

              <div
                className={
                  " h-72 overflow-y-auto divide-y divide-solid border-b  z-20 bg-white  shadow-md top-11 w-full"
                }
              >
                {tags
                  .filter(
                    (tag) =>
                      tag.name.toUpperCase().indexOf(filter.toUpperCase()) >= 0
                  )
                  .sort((a, b) => {
                    return a.selected === b.selected
                      ? a.name.localeCompare(b.name)
                      : a.selected
                      ? -1
                      : 1;
                  })
                  .map((tag, index) => (
                    <div key={tag.tagId} className={"flex h-8 items-center"}>
                      <div className={"flex w-8 justify-center"}>
                        {tag.selected && <ApproveIcon></ApproveIcon>}
                      </div>
                      <div
                        className={"flex flex-1 min-w-0 cursor-pointer"}
                        onClick={(e) => handleSelectChangeClick(e, tag, true)}
                      >
                        <span
                          className={`inline-block bg-[${tag.color}] ${
                            TagColor.isDark(tag.color) ? "text-white" : ""
                          } rounded-full text-xs shadow-sm py-0.5 px-3 overflow-hidden truncate`}
                        >
                          {tag.name}
                        </span>
                      </div>
                      <div className={"flex w-8 justify-center"}>
                        {tag.selected && (
                          <CancelIcon
                            className={
                              "w-4 text-xs font-light cursor-pointer hover:text-red-500 transaction ease-in-out duration-300"
                            }
                            onClick={(e) =>
                              handleSelectChangeClick(e, tag, false)
                            }
                          ></CancelIcon>
                        )}
                      </div>
                    </div>
                  ))}
              </div>
              <div className="flex gap-2 p-2 items-center bg-white  shadow-md">
                <input
                  type="text"
                  autoComplete="off"
                  value={newTagName}
                  placeholder="Add New Tag"
                  className="block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-primary-color focus:border-primary-color dark:focus:border-gray-500 dark:focus:ring-gray-500 dark:bg-custom-black dark:text-white sm:text-sm "
                  onChange={(e) => handleTagNameChange(e.target.value)}
                />
                <PlusIcon
                  className="h-5 w-5 cursor-pointer"
                  onClick={() => {
                    addNewTag();
                  }}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default TagSelector;

import Breadcrumb from "../../shared/breadcrumb";
import { IBreadcrumb } from "../../../../../model/common/breadcrumb";
import RouteConstant from "../../../../../utils/constant/routeConstant";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { useState, useEffect } from "react";
import { ISelectElement } from "../../../../../model/interface/selectElement";
import { connect } from "react-redux";
import { getAssessmentsWithResults } from "../../../../../api/teacher/assessment";
import { IAssessmentSearch } from "../../../../../model/interface/assessmentSearch";
import {
  exportAssessmentResultsByStudentToExcel,
  getAssessmentResultsByStudent,
} from "../../../../../api/report/assessmentResultsReport";
import moment from "moment";
import Loader from "../../../../shared/loader";
import EllipsisText from "../../../../shared/ellipsisText";
import classNames from "classnames";
import { IAssessment } from "../../../../../model/interface/assessment";
import Subject from "../../../../shared/dropdowns/subject";
import Asterisk from "../../../../shared/asterisk";
import { SchoolYearDropDown } from "../../../schoolYearDropdown";
import Moment from "moment";
import { getEndDate, getStartDate } from "../../../../../utils/dateHelper";
import { ISchoolYear } from "../../../../../model/teacher/schoolYear";
import { toastr } from "react-redux-toastr";

export type ISelectCustomElement = {
  label: string;
  value: string;
  name: string;
  Date?: Date;
  code?: string;
  isPremiumAssessment?: boolean;
};

function AssessmentResultsReport(props) {
  const { t } = useTranslation();
  const defaultValue = { label: "Select", value: "0" };
  const [selectedSubject, setSelectedSubject] =
    useState<ISelectElement>(defaultValue);
  const [viewByScore, setViewByScore] = useState<boolean>(false);
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [assessments, setAssessments] = useState<Array<ISelectCustomElement>>();
  const [selectedAssessments, setSelectedAssessments] = useState<
    Array<ISelectCustomElement>
  >([]);

  const [assessmentResults, setAssessmentResults] = useState<any>();
  const [tabs, setTabs] = useState<any[]>([]);
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [tabAssessmentResults, setTabAssessmentResults] = useState<any>([]);
  const userId = props.userContext.userId;
  const assessmentLimit = 10;
  const [selectedSchoolYearId, setSelectedSchoolYearId] = useState<number>();
  const [resetSchoolYear, setResetSchoolYear] = useState<boolean>(false);
  const [schoolYearData, setSchoolYearData] = useState<Array<ISchoolYear>>();
  const [startDate, setStartDate] = useState<any>();
  const [endDate, setEndDate] = useState<any>();
  type ExportType = "PDF" | "CSV" | "Excel";

  const columnAlignment = [
    {
      key: "ActivitySessionId",
      class: "text-left",
    },
    {
      key: "AssessmentId",
      class: "text-left",
    },
    {
      key: "StudentFirstName",
      class: "text-left",
    },
    {
      key: "StudentLastName",
      class: "text-left",
    },
    {
      key: "StudentId",
      class: "text-center",
    },
    {
      key: "School",
      class: "text-left",
    },
    {
      key: "Grade",
      class: "text-center",
    },
    {
      key: "Teacher",
      class: "text-left",
    },
    {
      key: "Class",
      class: "text-left",
    },
    {
      key: "DateCompleted",
      class: "text-center",
    },
    {
      key: "DurationInSeconds",
      class: "text-center",
    },
    {
      key: "Questions",
      class: "text-center",
    },
    {
      key: "PointsPossible",
      class: "text-center",
    },
    {
      key: "PointsEarned",
      class: "text-center",
    },
    {
      key: "Score",
      class: "text-center",
    },
    {
      key: "AssessmentName",
      class: "text-left",
    },
    {
      key: "AssessmentCode",
      class: "text-left",
    },
  ];

  useEffect(() => {
    if (selectedSchoolYearId) {
      handleDateFilter(selectedSchoolYearId);
      setResetSchoolYear(false);
      setAssessmentResults(null);
      setSelectedAssessments([]);
    }
  }, [selectedSchoolYearId]);

  useEffect(() => {
    if (
      selectedSchoolYearId &&
      selectedSubject &&
      selectedSubject.value !== "0" &&
      selectedSubject.value !== "" &&
      startDate &&
      endDate
    ) {
      setTabAssessmentResults([]);
      setTabs([]);
      setAssessmentResults(null);
      setSelectedAssessments([]);
      fetchAssessments();
    }
  }, [selectedSubject, startDate, endDate]);

  function handleSchoolYearLoaded(currentSchoolYearId?: number) {
    handleSchoolYearChange(currentSchoolYearId);
  }

  function handleSchoolYearChange(schoolYearId?: number) {
    setSelectedSchoolYearId(schoolYearId);
    setSelectedAssessments([]);
  }
  function handleSchoolYearData(schoolYearData: Array<ISchoolYear>) {
    setSchoolYearData(schoolYearData);
  }
  function handleSubjectChange(selectdItem: any) {
    setSelectedAssessments([]);
    setSelectedSubject(selectdItem);
  }

  function handleDateFilter(schoolYearId?: number) {
    let selectedSchoolYear = schoolYearData?.filter(
      (s) => s.schoolYearId == schoolYearId
    )[0];
    let minDate = selectedSchoolYear
      ? Moment(
          selectedSchoolYear.startDate.toString().split("T")[0],
          "YYYY-MM-DD"
        ).toDate()
      : new Date();

    let maxDate = selectedSchoolYear
      ? Moment(
          selectedSchoolYear.startDate.toString().split("T")[0],
          "YYYY-MM-DD"
        )
          .add(1, "year")
          .subtract(1, "day")
          .toDate()
      : new Date();

    setStartDate(getStartDate(minDate));
    setEndDate(getEndDate(maxDate));
  }

  function getMyAssessments(
    assessments: IAssessment[]
  ): ISelectCustomElement[] {
    return assessments
      .filter(
        (assessment: any) =>
          !assessment.isAdditional && !assessment.diagTestTypeId
      )
      .sort((a, b) => a.name.localeCompare(b.name))
      .map(
        (
          assessment
        ): {
          createdDate: Date | undefined;
          label: string;
          value: string;
          name: string;
          code: string | undefined;
          isPremiumAssessment: boolean;
        } => {
          return {
            value: assessment.assessmentId.toString(),
            label: assessment.name + " - " + assessment.code,
            name: assessment.name,
            createdDate: assessment.createdDate,
            code: assessment.code,
            isPremiumAssessment: false,
          };
        }
      );
  }

  function getAdditionalAssessments(
    assessments: IAssessment[]
  ): ISelectCustomElement[] {
    return assessments
      .filter((assessment) => assessment.isAdditional)
      .sort((a, b) => {
        let aValue = [a.authorLastName, a.authorFirstName, a.name].join("");
        let bValue = [b.authorLastName, b.authorFirstName, b.name].join("");
        return aValue.localeCompare(bValue);
      })
      .map(
        (
          assessment
        ): {
          createdDate: Date | undefined;
          label: string;
          value: string;
          name: string;
          code: string | undefined;
          isPremiumAssessment: boolean;
        } => {
          return {
            value: assessment.assessmentId.toString(),
            label:
              assessment.name +
              " - " +
              assessment.code +
              " - " +
              assessment.authorLastName +
              ", " +
              assessment.authorFirstName,
            name: assessment.name,
            createdDate: assessment.createdDate,
            code: assessment.code,
            isPremiumAssessment: false,
          };
        }
      );
  }

  function getPremiumAssessments(
    assessments: IAssessment[]
  ): ISelectCustomElement[] {
    return assessments
      .filter(
        (assessment) => !assessment.isAdditional && assessment.diagTestTypeId
      )
      .sort((a, b) => {
        let aValue = [a.authorLastName, a.authorFirstName, a.name].join("");
        let bValue = [b.authorLastName, b.authorFirstName, b.name].join("");
        return aValue.localeCompare(bValue);
      })
      .map(
        (
          assessment
        ): {
          createdDate: Date | undefined;
          label: string;
          value: string;
          name: string;
          code: string | undefined;
          isPremiumAssessment: boolean;
        } => {
          return {
            value: assessment.assessmentId.toString(),
            label: assessment.name,
            name: assessment.name,
            createdDate: assessment.createdDate,
            code: assessment.name,
            isPremiumAssessment: true,
          };
        }
      );
  }

  function fetchAssessments() {
    setShowLoading(true);
    const assessmentSearch: IAssessmentSearch = {
      teacherId: userId,
      subjectId: parseInt(selectedSubject.value),
      canViewValue: true,
      schoolYearId: selectedSchoolYearId,
      fromDate: startDate,
      toDate: endDate,
    };

    getAssessmentsWithResults(assessmentSearch)
      .then((r) => {
        setShowLoading(false);

        const premiumAssessments = getPremiumAssessments(r.data);
        const myAssessments = getMyAssessments(r.data);
        const additionalAssessments = getAdditionalAssessments(r.data);

        const assessmentData: any = [
          {
            label: "Premium Assessments",
            options: premiumAssessments,
          },
        ];
        assessmentData.push({
          label: "My Assessments",
          options: myAssessments,
        });

        assessmentData.push({
          label: "Additional Assessments",
          options: additionalAssessments,
        });

        setAssessments(assessmentData);
        setShowLoading(false);
      })
      .finally(() => {
        setShowLoading(false);
      });
  }

  const onReset = () => {
    setSelectedSubject(defaultValue);
    setSelectedAssessments([]);
    setTabAssessmentResults([]);
    setAssessmentResults(null);
    setTabs([]);
    setAssessments([]);
  };

  const runReport = async () => {
    createTabs();
    let report: any = await getAssessmentResult(
      selectedAssessments[0].value,
      selectedAssessments[0].isPremiumAssessment ?? false
    );
    setAssessmentResults(report);
    let tabResult: any = [
      {
        id: selectedAssessments[0].value,
        result: report,
      },
    ];
    setTabAssessmentResults(tabResult);
  };

  const getAssessmentResult = async (
    assessmentId: string,
    isPremiumAssessment: boolean
  ) => {
    try {
      setShowLoading(true);
      let result: any = await getAssessmentResultsByStudent(
        props.userContext.districtId,
        parseInt(selectedSubject.value),
        props.userContext.schoolId,
        userId,
        assessmentId,
        selectedSchoolYearId ?? props.profile.currentSchoolYear.schoolYearId,
        startDate,
        endDate,
        isPremiumAssessment ?? false
      );
      if (result) {
        return result.data.data;
      }
      return null;
    } finally {
      setShowLoading(false);
    }
  };

  const handleAssessmentSelection = (selectedItems: any) => {
    if(selectedItems.length > assessmentLimit) {
      toastr.warning("", `You may only search up to ${assessmentLimit} Assessments at a time.`, { timeOut: 10000, icon: <></>});
      return;
    }

    var isRemove: boolean = false;
    if (selectedItems.length === 1 && selectedAssessments.length > 0) {
      var item = selectedAssessments.find(
        (a) => a.value === selectedItems[0].value
      );
      if (item) isRemove = true;
    }

    if (
      selectedItems.length <= assessmentLimit ||
      selectedItems.length === 0 ||
      isRemove
    )
      setSelectedAssessments(selectedItems);
  };

  const exportData = (exportType: ExportType) => {
    setShowLoading(true);
    let sortedAssessments: any = sortByCreatedDateAsc(selectedAssessments);
    exportAssessmentResultsByStudentToExcel(
      props.userContext.districtId,
      parseInt(selectedSubject.value),
      props.userContext.schoolId,
      userId,
      sortedAssessments
        .filter((x) => !x.isPremiumAssessment)
        .map((a) => a.value)
        .join(","),
      sortedAssessments
        .filter((x) => x.isPremiumAssessment)
        .map((a) => a.value)
        .join(","),
      selectedSchoolYearId ?? props.profile.currentSchoolYear.schoolYearId,
      startDate,
      endDate,
      viewByScore
    )
      .then((response) => {
        const file = response.data;
        const fileURL = URL.createObjectURL(file);
        const link = document.createElement("a");
        link.href = fileURL;
        link.download = "ResultsExport.xlsx";
        link.setAttribute("target", "_blank");
        document.body.appendChild(link);
        link.click();
      })
      .finally(() => {
        setShowLoading(false);
      });
  };

  const sortByCreatedDateDesc = (assessments: ISelectCustomElement[]) => {
    return assessments.sort((a, b) => {
      let aDate = a.Date ?? new Date();
      let bDate = b.Date ?? new Date();
      return aDate < bDate ? 1 : -1;
    });
  };
  const sortByCreatedDateAsc = (assessments: ISelectCustomElement[]) => {
    return assessments.sort((a, b) => {
      let aDate = a.Date ?? new Date();
      let bDate = b.Date ?? new Date();
      return aDate < bDate ? -1 : 1;
    });
  };

  const createTabs = () => {
    let assessmentTabs: any = [];
    let sortedAssessments: any = sortByCreatedDateDesc(selectedAssessments);
    sortedAssessments.forEach((assessment, index) => {
      assessmentTabs.push({
        id: assessment.value,
        name: assessment.name,
        isPremiumAssessment: assessment.isPremiumAssessment ?? false,
      });
      if (index === 0) {
        setSelectedTab(Number(assessment.value));
      }
    });
    setTabs(assessmentTabs);
  };

  const findPoints = (possible: number, earned: number) => {
    return "" + earned + "/" + possible;
  };

  const findScore = (possible: number, earned: number) => {
    return (100 * earned / possible).toFixed(0) + "%";
  };

  const processColumnData = (row: any, column: any, viewByScore: boolean = false) => {
    let columnValue = "-";
    if (row[column.index] !== null) {
      if (typeof row[column.index] === "object") {
        if (viewByScore) {
          columnValue = findScore(
              row[column.index]?.possible ?? 0,
              row[column.index]?.earned ?? 0
          );
        } else {
          columnValue = findPoints(
              row[column.index]?.possible ?? 0,
              row[column.index]?.earned ?? 0
          );
        }
      } else if (column.key === "DateCompleted") {
        columnValue = moment(row[column.index]).format("MM/DD/YYYY HH:mm:ss");
      } else if (column.key === "DurationInSeconds"){
        columnValue = moment.utc(row[column.index] * 1000).format("HH:mm:ss");
      } else if (column.key === "Score") {
        columnValue = (row[column.index] * 100).toFixed(0)+"%";
      } else {
        columnValue = row[column.index] ?? "-";
      }
    }
    return columnValue;
  };

  const breadcrumbItems = () => {
    const items: Array<IBreadcrumb> = [
      { name: t("Breadcrumb.Home"), url: RouteConstant.ROUTE_DASHBOARD },
      {
        name: t("Breadcrumb.Reports"),
        url: RouteConstant.REPORTS,
      },
      { name: "Assessment Results Report", url: "" },
    ];

    return items;
  };
  const handleTabChange = async (id, isPremiumAssessment) => {
    setSelectedTab(id);
    let tab: any = tabAssessmentResults.find(
      (r) => r.id === id && r.isPremiumAssessment === isPremiumAssessment
    );
    if (tab) {
      setAssessmentResults(tab.result);
    } else {
      let report: any = await getAssessmentResult(id, isPremiumAssessment);
      let tabResult: any = tabAssessmentResults;
      tabResult.push({
        id: id,
        result: report,
      });
      setTabAssessmentResults(tabResult);
      setAssessmentResults(report);
    }
  };

  return (
    <div className="min-w-0 w-full">
      <div className="lg:flex lg:items-center px-4 py-3 lg:px-8 border-b">
        <Breadcrumb items={breadcrumbItems()} />
        <div className="lg:w-[55%] text-lg font-medium flex gap-2 h-auto sm:h-10 items-center">
          <h1 className="text-primary-violet font-medium flex justify-start mb-0 pb-0">
            Assessment Results Report
          </h1>
        </div>
      </div>
      <div className="px-2 sm:px-6  lg:px-8 py-1 relative my-4">
        <div className="bg-gray-100 w-full rounded-lg p-5">
          <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-4 mb-4 mt-4">
            <div className="flex flex-col">
              <label className="block mb-1 text-sm text-gray-500">
                School Year <Asterisk />
              </label>
              <SchoolYearDropDown
                reset={resetSchoolYear}
                offsets={[0, -1]}
                handleSchoolYearChange={handleSchoolYearChange}
                handleSchoolYearLoaded={handleSchoolYearLoaded}
                handleSchoolYearData={handleSchoolYearData}
              ></SchoolYearDropDown>
            </div>
            <div className="flex flex-col">
              <Subject
                setSelectedSubject={(items) => handleSubjectChange(items[0])}
                selectedItems={selectedSubject}
                isMulti={false}
                isRequired={true}
                themeColor={"#008fbb"}
              ></Subject>
            </div>
            <div className="flex flex-col col-span-2">
              <label className="block mb-1 text-sm text-gray-500">
                Assessments <Asterisk />
              </label>
              <Select
                value={selectedAssessments}
                options={assessments}
                onChange={handleAssessmentSelection}
                isMulti={true}
                closeMenuOnSelect={false}
                className={
                  selectedSubject === undefined
                    ? "pointer-events-none text-gray-400 bg-gray"
                    : ""
                }
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: "#008fbb",
                  },
                })}
              ></Select>
            </div>
            <div className="flex flex-row col-span-2">
              <span className={"py-1 pl-4 mb-1 text-sm text-gray-500"}>
                View By:
              </span>
              <span className={"py-[2px] pl-4"}>
                <input type="radio"
                       name="viewByScore"
                       value={0}
                       checked={!viewByScore}
                       onClick={() => setViewByScore(false)}
                />
              </span>
              <label className="mb-1 text-sm text-gray-500 py-1 pl-2">
                Points
              </label>
              <span className={"py-[2px] pl-4"}>
                <input type="radio"
                  name="viewByScore"
                  value={1}
                  checked={viewByScore}
                       onClick={() => setViewByScore(true)}
                />
              </span>
              <label className="mb-1 text-sm text-gray-500 py-1 pl-2">
                Score
              </label>
            </div>
          </div>
          <div className="flex flex-col sm:flex-row gap-4 justify-end">
            <button
              onClick={onReset}
              className="bg-transparent border my-auto border-primary-violet shadow-sm py-2 px-4 flex justify-center text-sm font-medium text-primary-violet hover:text-white hover:bg-light-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3 transition ease-in-out duration-500"
            >
              Reset
            </button>
            <div className="cursor-not-allowed">
              <button
                onClick={runReport}
                className={
                  "bg-primary-violet border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 " +
                  (selectedSubject === undefined ||
                  !selectedAssessments ||
                  selectedAssessments.length === 0 ||
                  (selectedSubject?.value ?? "0") === "0" ||
                  setSelectedAssessments.length === 0
                    ? "bg-opacity-20 text-primary-violet text-opacity-40 pointer-events-none"
                    : "hover:bg-dark-violet hover:shadow-lg")
                }
              >
                Run Report
              </button>
            </div>
            <div className="cursor-not-allowed">
              <button
                onClick={() => exportData("Excel")}
                className={
                  "bg-primary-violet border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 " +
                  (assessmentResults === undefined || assessmentResults === null
                    ? "bg-opacity-20 text-primary-violet text-opacity-40 pointer-events-none"
                    : "hover:bg-dark-violet hover:shadow-lg")
                }
              >
                Export to Excel
              </button>
            </div>
          </div>

          <div className="border-b border-gray-400 py-2 mb-2"></div>
          <div className="bg-white px-3 pt-2 pb-3">
            <div className="bg-gray-100 pt-2 px-3">
              {tabs &&
                tabs.map((tab) => (
                  <>
                    <button
                      key={tab.name}
                      onClick={() => {
                        handleTabChange(tab.id, tab.isPremiumAssessment);
                      }}
                      className={classNames(
                        tab.id === selectedTab.toString()
                          ? "border-secondary-teal bg-white text-primary-violet hover:text-dark-violet py-3 tab-box-shadow tab-box-shadow"
                          : "text-primary-violet text-opacity-60 bg-primary-violet/10 py-[0.6rem] mt-auto transition-all hover:py-3 hover:text-opacity-100 hover:text-primary-violet border-[#dee9f1]",
                        "px-2 sm:px-4 text-center border-t-2 text-sm cursor-pointer font-medium"
                      )}
                    >
                      <EllipsisText text={tab.name} length={30} />
                    </button>
                  </>
                ))}
            </div>

            <div>
              {showLoading && <Loader></Loader>}

              {assessmentResults === null && tabs.length > 0 && (
                <div className="bg-white pt-2 pb-3">
                  <div className="bg-white">
                    <span className="text-sm p-3">No records found</span>
                  </div>
                </div>
              )}

              {assessmentResults &&
                assessmentResults.rows.length > 0 &&
                assessmentResults?.rows.length > 0 && (
                  <div className="overflow-auto pt-44 ">
                    <>
                      <table className="table w-full ">
                        <thead>
                          <tr className="border-b border-primary-violet">
                            {assessmentResults?.columns?.length > 0 &&
                              assessmentResults?.columns
                                .filter((c) => c.isVisible)
                                .map((column) => (
                                  <>
                                    {column.index >= 17 && (
                                      <th className="relative">
                                        <p className="w-[271px] absolute top-[-5.25rem] left-[-0.75rem] transform -rotate-45 whitespace-nowrap flex items-center text-sm font-medium">
                                          <EllipsisText
                                            text={column.name}
                                            length={40}
                                          />
                                        </p>
                                      </th>
                                    )}
                                    {column.index < 17 && (
                                      <th
                                        className={`py-1 pl-2 whitespace-nowrap ${
                                          columnAlignment[column.index]
                                            ?.class ?? "text-center"
                                        }`}
                                      >
                                        {column.key !== "DurationInSeconds" && (
                                          <span className="text-primary-violet text-base font-semibold">
                                            {column.name}
                                          </span>
                                        )}
                                        {column.key === "DurationInSeconds" && (
                                          <div className="flex flex-col items-center">
                                            <span className="text-primary-violet text-base font-semibold">
                                              {column.name}
                                            </span>

                                            <span className="text-primary-violet text-base font-semibold">
                                              hh:mm:ss
                                            </span>
                                          </div>
                                        )}
                                      </th>
                                    )}
                                  </>
                                ))}
                          </tr>
                        </thead>
                        <tbody>
                          {assessmentResults?.rows?.length > 0 &&
                            assessmentResults.rows?.map((row, index) => (
                              <>
                                <tr
                                  key={index}
                                  className={`hover:bg-primary-violet/20 transition ease-in-out duration-300 ${
                                    index % 2 === 0
                                      ? "bg-white"
                                      : "bg-primary-violet/10"
                                  }`}
                                >
                                  {assessmentResults?.columns?.length > 0 &&
                                    assessmentResults?.columns
                                      .filter((c) => c.isVisible)
                                      .map((column, colIndex) => {
                                        return (
                                          <>
                                            <td
                                              className={`text-sm p-3 whitespace-nowrap ${
                                                columnAlignment[column.index]
                                                  ?.class ?? "text-center"
                                              }`}
                                            >
                                              {processColumnData(row, column, viewByScore)}
                                            </td>
                                          </>
                                        );
                                      })}
                                </tr>
                              </>
                            ))}
                        </tbody>
                      </table>
                    </>
                  </div>
                )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
  };
};

export default connect<{}, {}, {}>(
  mapStateToProps,
  {}
)(AssessmentResultsReport);

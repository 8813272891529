import { exterRosterType } from "../externalRoster/externalRosterType";

export default class ImpersonateUserModel {
    constructor(
        public userName: string,
        public password: string,
        public accountLoginName: string,
        public setUserContext: Function | undefined,
        public setProfile: Function | undefined,
        public impersonatedByUserId: number | undefined,
        public impersonatedByUserToken: string | undefined,
        public history: any,
        public returnUrl: string,
        public impersonatedByUserExternalRoster: exterRosterType
    ) { }
}
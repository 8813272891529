import { IAssessmentQuestion } from "../../../../model/interface/assessmentQuestion";
import {
  OutlineMessagesIcon,
  StarDarkIcon,
  StarIcon,
} from "../../../../assets/icons";
import { getFormatedMathText } from "../../../../utils/helper";
import {Configuration} from "../../../../environment/setup";
import Standard from "../../../../model/teacher/standard";

interface PassageQuestionPassagesQuestionsPorps {
  districtId: number,
  hasDistrictAssessmentQuestionIcon: boolean,
  passage: any,
  selectedQuestions: Array<IAssessmentQuestion>,
  favoriteQuestionsIds: Array<number>,
  getSortedPassageQuestions: (passageId: number, domainId: number) => any,
  canLoadMoreQuestions: (passageId: number) => boolean,
  handleFavoriteQuestion: (questionId: number) => void,
  handleFeedbackPopup: (id: number, standardList: Array<Standard>) => void,
  handleLoadMoreQuestions: (passageId: number) => void,
  handleSelectedQuestion: (
    question: IAssessmentQuestion,
    passageId: number
  ) => void,
  openQuestionPreview: (
    learnosityReferenceId: any,
    questionId: any,
    questionText: any,
    currentIndex: any,
    isPassgae: boolean,
    standardId: any,
    spanishLearnosityReferenceId: any,
    domainId: any,
    passageId: any
  ) => void,
}

function PassageQuestionPassagesQuestions(props: PassageQuestionPassagesQuestionsPorps) {
  const {
    districtId,
    hasDistrictAssessmentQuestionIcon,
    passage,
    selectedQuestions,
    favoriteQuestionsIds,
    getSortedPassageQuestions,
    canLoadMoreQuestions,
    handleFavoriteQuestion,
    handleFeedbackPopup,
    handleLoadMoreQuestions,
    handleSelectedQuestion,
    openQuestionPreview,
  } = props;

  return (
    <div className="overflow-x-auto">
    <table className="table w-full">
      <thead className="mb-5">
        <tr className="border-[3px] border-secondary-teal text-secondary-teal py-3 px-3 ">
          <th className="text-left p-3 text-sm font-normal w-5">
            Select
          </th>
          {hasDistrictAssessmentQuestionIcon && (
              <th className="text-left text-sm font-normal"></th>)}
          <th className="text-left p-3 text-sm font-normal">
            Question - Click to View
          </th>
          <th className="text-center p-3 text-sm font-normal">
            Point Value
          </th>
          <th className="text-left p-3 text-sm font-normal">
            Question Type
          </th>
          <th className="text-left p-3 text-sm font-normal">
            Standards
          </th>
          <th className="text-left p-3 text-sm font-normal">
            DOK
          </th>
          <th className="text-center p-3 text-sm font-normal">
            Favorites
          </th>
          <th className="text-center p-3 text-sm font-normal">
            Feedback
          </th>
        </tr>
      </thead>
      <tbody>
        {getSortedPassageQuestions(
          passage.passageId,
          passage.domainId
        ).map((question, index) => (
          <tr
            key={question.questionId}
            className="hover:bg-secondary-teal/20 transition ease-in-out duration-300"
          >
            <td className="text-sm mt-5 p-3">
              <input
                id="candidates"
                name="candidates"
                type="checkbox"
                className="focus:ring-secondary-teal h-4 w-4 text-secondary-teal border-gray-500"
                onChange={() =>
                  handleSelectedQuestion(
                    question,
                    passage.passageId ?? 0
                  )
                }
                checked={selectedQuestions.some(
                  (r) =>
                    r.questionId ===
                    question.questionId
                )}
              />
            </td>
            {hasDistrictAssessmentQuestionIcon && (
                <td className="text-sm">
                  <div className="flex items-center justify-center">
                  {question.isDistrictAssessmentQuestion && (
                      <img alt=""
                            src={`${Configuration.S3bucketImagePath}/districts/${districtId.toString()}/district-assessment-question-icon.png`}/>)}
                  </div>
                </td>
            )}
            <td className="text-sm mt-5 p-3">
              <span
                className="cursor-pointer text-blue-500 w-[26rem] flex-wrap hover:underline"
                onClick={() =>
                  openQuestionPreview(
                    question.learnosityReferenceId,
                    question.questionId,
                    question.questionText,
                    index,
                    false,
                    question.standardId,
                    question.spanishLearnosityReferenceId,
                    passage.domainId,
                    passage.passageId
                  )
                }
              >
                {getFormatedMathText(question.questionText ?? "")
                  ?.map((element, index) => (
                    <>
                      {element.type === "math" && (
                        <>
                          <span
                            dangerouslySetInnerHTML={{
                              __html:
                                element.object ??
                                "",
                            }}
                          ></span>
                        </>
                      )}
                      {element.type === "text" && (
                        <span
                          className="flex"
                          dangerouslySetInnerHTML={{
                            __html:
                              element.object ??
                              "",
                          }}
                        ></span>
                      )}
                    </>
                  )
                )}
              </span>
            </td>
            <td className="text-center text-sm mt-5 p-3">
              {question.pointsPossible}
            </td>
            <td className="text-sm mt-5 p-3">
              <div className="flex space-x-2">
                <span className="bg-gray-100 p-1 text-sm">
                  {question.questionType}
                </span>
              </div>
            </td>
            <td className="text-sm mt-5 p-3">
              {question.standardDetails?.map((standard: any) => (
                <span
                  key={standard.id}
                  className="block"
                >
                  {`[${standard.code}] ${standard.name}`}
                </span>
              ))}
            </td>
            <td className="text-sm mt-5 p-3">
              {question.dokId &&
              question.dokId > 0
                ? question.dokId
                : "N/A"}
            </td>
            <td className="text-sm mt-5 p-3">
              {favoriteQuestionsIds.some(q => q === question.questionId) 
                ? (<StarDarkIcon
                  className="w-5 h-5 cursor-pointer mx-auto fill-current text-yellow-300"
                  onClick={() =>
                    handleFavoriteQuestion(
                      question.questionId
                    )
                  }
                />) 
                : (<StarIcon
                  className="w-5 h-5 cursor-pointer mx-auto fill-current text-gray-500"
                  onClick={() =>
                    handleFavoriteQuestion(
                      question.questionId
                    )
                  }
                />)
              }
            </td>
            <td className="text-sm mt-5 p-3 w-5 h-5">
              <OutlineMessagesIcon
                className="w-5 h-5 fill-current text-gray-500 mx-auto"
                onClick={() =>
                  handleFeedbackPopup(
                    question.questionId,
                    question.standardDetails
                      ? question.standardDetails
                      : []
                  )
                }
              />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
    {canLoadMoreQuestions(passage.passageId) && (
      <span
        onClick={() =>
          handleLoadMoreQuestions(
            passage.passageId
              ? passage.passageId
              : 0
          )
        }
        className="flex justify-end cursor-pointer text-blue-500 underline hover:no-underline"
      >
        Load More Questions
      </span>
    )}
  </div>
  )
}

export default PassageQuestionPassagesQuestions

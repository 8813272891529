import { useCallback, useEffect, useRef, useState } from "react";
import { initRequest } from "../../../../../utils/initAssessment";
import { v4 as uuidv4 } from "uuid";
import XIcon from "@heroicons/react/outline/XIcon";
import { useTranslation } from "react-i18next";
import PreviewPage from "../../../teacher/shared/questionPreview/learnosityPreviewPage";
import { bindTextToSpeech } from "../textToSpeech/textToSpeech";
import { getRequest } from "../../../../../api/userActivity";
import { waitForElementsToRender } from "../../../../../utils/waitFor";
import Constant from "../../../../../utils/constant/constant";

const theme = {
  EL: {
    headerText: "text-gray-700",
    headerBg: "bg-[#a9fb24]",
    closeBtn: "bg-[#a9fb24] hover:bg-opacity-80 hover:shadow-lg text-gray-700",
  },
  K1: {
    headerText: "text-gray-700",
    headerBg: "bg-yellow-400",
    closeBtn:
      "align-bottom inline-flex font-bold items-center justify-center cursor-pointer leading-5 transition-colors duration-150  focus:outline-none px-5 py-3 rounded-lg text-lg text-white bg-primary-violet hover:shadow-lg hover:bg-dark-violet",
  },
  MS: {
    headerText: "text-white",
    headerBg: "bg-site-teal",
    closeBtn:
      "bg-primary-violet border border-transparent hover:bg-dark-violet",
  },
};
interface StudentResponsePopupProps {
  showPopUp: (show: boolean) => void;
  setMessage: (message: string) => void;
  setVideoUrl: (videoUrl: string) => void;
  setTextExplanation: (textExplanation: string) => void;
  showCancelIcon: (cancelIcon: boolean) => void;
  cancelIcon: boolean;
  message: string;
  videoUrl: string;
  itemRef: string;
  textExplanation: string;
  showSkipButton: (skipButton: boolean) => void;
  skipButton: boolean;
  setIsPopUpClosed: (isPopUpClosed: boolean) => void;
  studentType: string;
  onExitRedirectUrl: string;
  questionRetry: boolean;
  isLastItem: boolean;
  language?: string;
  eliminateAnswerForSingleMCQ?: boolean;
  showTextToSpeech?: boolean;
  fontSize?: string;
}

const StudentResponsePopup = (props: StudentResponsePopupProps) => {
  const [showPlayer, setShowPlayer] = useState<boolean>(false);
  const [arrayOfcomponents, setArrayOfComponents] = useState<Array<any>>();
  const [conatinsMath, setContainsMath] = useState<boolean>(false);
  type objectType = {
    type: string;
    object: any;
  };
  const { t } = useTranslation();
  const themeName = props.studentType;
  const isInitialized = useRef(false);
  var singleItemApp;

  const fixAnswerTTSButtonAlignment = () => {
    const answers = document.querySelectorAll(
      ".lrn-mcq-option"
    ) as NodeListOf<HTMLElement>;
    answers.forEach((e) => {
      e.style.display = "Flex";
    });
  };

  const addTTSButtons = async (singleItemApp: any) => {
    const currentItem = singleItemApp.getCurrentItem();
    const optionsCount = currentItem
        .questions.filter(y => y.options).reduce(
          (prev: number, curr: any) => prev + curr.options.length,
          0
        );

    if(optionsCount === 0)
      return;

    waitForElementsToRender(
      ".studentResponsePopup",
      ".lrn-mcq-option",
      optionsCount,
      3000
    ).then((_response) => {

      // Get the current item from local storage
      const learnosityPlayerStorage = JSON.parse(localStorage.getItem(Constant.LearnosityPlayerStorage) || "{}");
      const storedCurrentItemReference = learnosityPlayerStorage.currentQuestionReference;
      const currentItemId = currentItem.reference as string;

      // If the current item is the same as the stored item, bind the TTS buttons
      if(storedCurrentItemReference === null || storedCurrentItemReference === currentItemId) {
        bindTextToSpeech(singleItemApp, props.language);
        fixAnswerTTSButtonAlignment();
      }
      else {
        // Entering here means that race condition occurred and the current item has changed
        console.info("Skipping TTS binding for item: ", currentItemId);
      }      
    });
  };

  const initialization = useCallback((itemReference: string) => {
    if (isInitialized.current) return;
      
    isInitialized.current = true;
    var callbacks = {
      readyListener: function () {
        var assessApp = singleItemApp.assessApp();
        var questions = singleItemApp.questions();
        Object.values(questions).forEach(function (question: any) {
          question.on("validated", async function () {
            var next_btn = document.getElementById("next_btn");
            if (next_btn) {
              next_btn.style.display = "block";
            }
            var checkBtn = document.getElementsByClassName(
              "lrn_btn lrn_validate"
            )[0] as HTMLButtonElement;
            if (checkBtn) {
              checkBtn.disabled = true;
            }
          });
        });
        assessApp.on("item:beforeunload", function (event) {
          Object.values(singleItemApp.getCurrentItem().response_ids).forEach(
            function (response_id, index) {
              var questionObject = singleItemApp.question(response_id);
              var valid_response_count =
                questionObject.getQuestion().metadata.valid_response_count;
              var questionResponse = questionObject.getResponse();
              if (valid_response_count) {
                // calculate the currentResponseCount using .reduce() to count the student responses
                var currentResponseCount = questionResponse
                  ? questionResponse.value.reduce(function (acc, curr) {
                      return acc + (curr ? 1 : 0);
                    }, 0)
                  : 0;

                if (currentResponseCount < valid_response_count) {
                  // display a custom dialog to the student
                  singleItemApp
                    .assessApp()
                    .dialogs()
                    .custom.show({
                      header:
                        props.language === "es"
                          ? "Se requieren más respuestas"
                          : t("StudentResponse.MoreAnswersRequired"),
                      body:
                        (props.language === "es"
                          ? "Esta pregunta requiere "
                          : t("StudentResponse.ThisQuestionRequires")) +
                        valid_response_count +
                        (valid_response_count === 1
                          ? props.language === "es"
                            ? " responder!"
                            : t("StudentResponse.Answer")
                          : props.language === "es"
                          ? " respuestas!"
                          : t("StudentResponse.Answers")),
                    });

                  // preventDefault() to stop the assessment navigation
                  event.preventDefault();
                  return false;
                }
              }
            }
          );
        });
        singleItemApp.on("item:load", async function () {
          if (props.showTextToSpeech) {
            addTTSButtons(singleItemApp);
          }
          var submit_btn = document.querySelector(
            "#learnosity_assessQuestion #lrn_assess_next_btn"
          ) as HTMLButtonElement;
          if (submit_btn) {
            submit_btn.style.display = "none";
          }
          if (props.eliminateAnswerForSingleMCQ) {
            var Questions = singleItemApp.getCurrentItem().questions;

            Object.values(Questions).forEach(async function (
              question: any,
              index: number
            ) {
              var value = question.metadata?.auto_mask_response
                ? question.metadata?.auto_mask_response
                : null;

              if (value) {
                var response_id = question.response_id;
                var maskEls = document.querySelectorAll(
                  "[data-original-title='Response Masking']"
                ) as NodeListOf<Element>;
                var maskEL =
                  maskEls.length === 2
                    ? (document.querySelectorAll(
                        "[data-original-title='Response Masking']"
                      )[1] as HTMLElement)
                    : null;
                var btnMaskedClicked = maskEL?.getAttribute("aria-pressed");
                if (maskEL && btnMaskedClicked === "false") maskEL.click();
                var parent_div = document.querySelector(
                  "[id='" + response_id + "']"
                ) as HTMLElement;
                var el = parent_div
                  ?.querySelector(".lrn_response_wrapper")
                  ?.querySelector("[value='" + value + "']") as HTMLElement;
                setTimeout(function () {
                  var parent_li_el = el.parentElement as HTMLElement;
                  var btnMask = parent_li_el?.querySelector(
                    "button.lrn-mask"
                  ) as HTMLElement;
                  if (btnMask) btnMask.click();
                  var _btnMaskedClicked = maskEL?.getAttribute("aria-pressed");
                  if (
                    maskEL &&
                    _btnMaskedClicked === "true" &&
                    Questions.length === index + 1
                  )
                    maskEL.click();
                }, 200);
              }
            });
          }
        });
      },
      errorListener: function (e: any) {
        console.log("Error Code ", e.code);
        console.log("Error Message ", e.msg);
        console.log("Error Detail ", e.detail);
      },
    };
    initRequest(
      "",
      false,
      true,
      [itemReference],
      false,
      false,
      uuidv4(),
      "initial",
      "DemoTest",
      itemReference,
      null,
      props.language ?? "en",
      false,
      "",
      true,
      null,
      props.studentType,
      undefined,
      props.onExitRedirectUrl,
      undefined,
      undefined,
      undefined,
      undefined,
      false,
      true,
      props.fontSize
    ).then((requestObj) => {
      getRequest("items", JSON.stringify(requestObj)).then((response) => {
        if (response.data) {
          if (singleItemApp) {
            singleItemApp.reset();
          }
          singleItemApp = window["LearnosityItems"]?.init(
            response.data,
            "learnosity_assessQuestion",
            callbacks
          );
        }
      });
    });
  }, []);
  function SkipExplanation() {
    if (props.questionRetry) {
      setShowPlayer(true);
      initialization(props.itemRef);
    } else {
      props.showPopUp(false);
      props.setIsPopUpClosed(true);
    }
  }
  function ClosePopUp() {
    singleItemApp?.reset();
    props.showPopUp(false);
    props.setIsPopUpClosed(true);
  }

  useEffect(() => {
    if (
      props.videoUrl?.length === 0 &&
      props.textExplanation?.length === 0 &&
      props.message?.length === 0 &&
      !showPlayer &&
      props.itemRef
    ) {
      setShowPlayer(true);
      initialization(props.itemRef);
    } else if (props.textExplanation && !showPlayer && !props.message) {
      initialization(props.itemRef);
      const mainArray: objectType[] = [];
      if (props.textExplanation.includes("<math>")) {
        setContainsMath(true);
        const obj: objectType = {
          type: "math",
          object:
            "<MathJaxContext><MathJax>" +
            props.textExplanation +
            "</MathJax></MathJaxContext>",
        };
        mainArray.push(obj);
      } else {
        const obj: objectType = {
          type: "text",
          object: props.textExplanation,
        };
        mainArray.push(obj);
      }
      setArrayOfComponents(mainArray);
    }
  }, []);
  return (
    <div
      className="studentResponsePopup fixed inset-0 flex items-center bg-black bg-opacity-50 sm:items-center sm:justify-center"
      style={{ zIndex: 9999 }}
    >
      <div
        className={`xs:w-[100%] flex flex-col overflow-hidden xs:h-auto bg-white dark:bg-gray-800 rounded-lg sm:m-4 sm:w-[75%] ${
          props.videoUrl && !showPlayer
            ? "lg:h-[83%] xl:h-[95%]"
            : "max-h-80percent"
        } `}
        role="dialog"
      >
        {/* Header */}
        <header>
          <div className={` ${theme[themeName]?.headerBg}  px-4 py-4 sm:px-4 `}>
            <div className="flex items-start justify-between space-x-3">
              <div className="space-y-1">
                <div
                  className={` ${theme[themeName]?.headerText} text-lg font-medium `}
                >
                  {showPlayer
                    ? props.language === "es"
                      ? "Reintentar última pregunta"
                      : t("StudentResponse.RetryLastQuestion")
                    : ""}
                </div>
              </div>

              <div className="h-7 flex items-center">
                {props.message && props.message.length > 0 && (
                  <button
                    type="button"
                    className="text-gray-400 hover:text-gray-500"
                    onClick={() => props.showPopUp(false)}
                  >
                    <span className="sr-only">
                      {props.language === "es"
                        ? "Cerrar panel"
                        : t("StudentResponse.ClosePanel")}
                    </span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                )}
              </div>
            </div>
          </div>
        </header>

        {props.message && props.message.length > 0 && (
          <div className="flex flex-col w-full h-full relative overflow-auto px-8 py-6">
            <div className="flex h-full space-x-6">
              <svg
                version="1.0"
                xmlns="http://www.w3.org/2000/svg"
                className="w-16 text-gray-500 mb-auto"
                viewBox="0 0 96.000000 96.000000"
                preserveAspectRatio="xMidYMid meet"
              >
                <g
                  transform="translate(0.000000,96.000000) scale(0.100000,-0.100000)"
                  fill="currentColor"
                  stroke="none"
                >
                  <path
                    d="M111 846 c-47 -26 -50 -46 -51 -284 0 -301 1 -302 185 -302 l104 0
61 -80 c33 -44 65 -80 70 -80 5 0 37 36 70 80 l61 80 104 0 c184 0 185 2 185
299 0 238 -4 262 -51 287 -39 20 -702 20 -738 0z m729 -46 c19 -19 20 -33 20
-240 0 -276 9 -260 -155 -260 l-114 0 -51 -67 c-29 -38 -55 -68 -60 -68 -5 0
-31 30 -60 67 l-51 68 -114 0 c-164 0 -155 -16 -155 260 0 207 1 221 20 240
19 19 33 20 360 20 327 0 341 -1 360 -20z"
                  />
                  <path
                    d="M225 689 c-16 -25 21 -29 261 -27 213 3 249 5 249 18 0 13 -36 15
-252 18 -175 2 -254 -1 -258 -9z"
                  />
                  <path
                    d="M225 569 c-16 -25 21 -29 261 -27 213 3 249 5 249 18 0 13 -36 15
-252 18 -175 2 -254 -1 -258 -9z"
                  />
                  <path
                    d="M225 449 c-16 -25 21 -29 261 -27 213 3 249 5 249 18 0 13 -36 15
-252 18 -175 2 -254 -1 -258 -9z"
                  />
                </g>
              </svg>
              <div className="text-lg text-gray-700 font-light bg-blue-50 p-8 flex w-full flex-grow rounded-md rounded-t-lg sm:rounded-lg  ">
                <div className="pl-2">message={props.message}</div>
              </div>
            </div>
          </div>
        )}
        {props.videoUrl && props.videoUrl.length > 0 && !showPlayer && (
          <div onContextMenu={(e) => e.preventDefault()} className="w-full h-full relative overflow-hidden flex items-center justify-center px-8 py-6">
            <div className="w-full h-full relative" style={{ maxWidth: '85%' }}>
              <video
                title="Video"
                className="w-full h-full"
                src={props.videoUrl + "?autoPlay=true"}
                controls
                autoPlay
                controlsList="nodownload"
                style={{ display: 'block', margin: 'auto', zIndex: 1000 }}
              >
                Your browser does not support the video tag.
              </video>
            </div>
        </div>
        )}
        {props.textExplanation &&
          props.textExplanation.length > 0 &&
          !showPlayer && (
            <div
              className={`${
                props.fontSize
                  ? "fontSize-" + props.fontSize
                  : "fontSize-normal;"
              } flex flex-col w-full h-full relative overflow-auto px-8 py-6`}
            >
              <h1 className="text-gray-700 font-medium text-center mt-4 mb-10">
                {props.questionRetry
                  ? "You have answered the question incorrectly. Please answer it correctly after following the explanation below."
                  : "You have answered the question incorrectly. Please view the following explanation below."}
              </h1>

              <div className="flex flex-col h-full w-full">
                <div className="w-full">
                  <PreviewPage
                    itemRef={props.itemRef}
                    isProjectorMode={false}
                    isStudentScreen={true}
                  />
                </div>
                <div className="w-full order-first text-gray-700 font-light bg-blue-50 p-8 flex flex-grow rounded-md rounded-t-lg sm:rounded-lg ">
                  {conatinsMath && (
                    <div className="mathdiv flex-wrap">
                      {arrayOfcomponents?.map((element, index) => {
                        return (
                          <>
                            <span
                              dangerouslySetInnerHTML={{
                                __html: element.object ?? "",
                              }}
                            ></span>
                          </>
                        );
                      })}
                    </div>
                  )}
                  {!conatinsMath &&
                    arrayOfcomponents?.map((element, index) => {
                      return (
                        <>
                          {element.type === "text" && (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: element.object ?? "",
                              }}
                            ></div>
                          )}
                        </>
                      );
                    })}
                </div>
              </div>
            </div>
          )}

        <div className="flex flex-col items-center justify-end px-6 py-4 sm:flex-row bg-gray-50 dark:bg-gray-800 rounded-b-lg">
          <div className="flex-shrink-0">
            <div className="space-x-3 flex justify-center">
              {props.skipButton && !showPlayer && (
                <button
                  type="button"
                  className={` ${theme[themeName]?.closeBtn}  border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 rounded-lg `}
                  onClick={() => SkipExplanation()}
                >
                  {" "}
                  {props.language === "es"
                    ? "Cerrar"
                    : t("StudentResponse.Close")}
                </button>
              )}
            </div>
          </div>
        </div>

        {/* <!-- Divider container -->  */}
        <div
          className={
            !showPlayer
              ? "hidden"
              : "flex flex-col w-full h-full relative overflow-auto px-8 py-6 studentResponse"
          }
        >
          <div id="learnosity_assessQuestion"></div>
        </div>

        {/* <!-- Action Buttons -->  */}
        {showPlayer && (
          <div className="flex flex-col items-center justify-end px-6 py-4 sm:flex-row bg-gray-50 dark:bg-gray-800 rounded-b-lg">
            <div className="flex-shrink-0">
              <div className="space-x-3 flex justify-center">
                <button
                  id="next_btn"
                  type="button"
                  className={` ${theme[themeName]?.closeBtn} hidden border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 rounded-lg `}
                  onClick={() => ClosePopUp()}
                >
                  {" "}
                  {props.isLastItem
                    ? props.language === "es"
                      ? "Enviar"
                      : t("StudentResponse.Submit")
                    : props.language === "es"
                    ? "Próxima pregunta"
                    : t("StudentResponse.NextQuestion")}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default StudentResponsePopup;

import { getAssessmentById } from "../api/teacher/assessment";
import { getAssignmentActivityStandardsById } from "../api/teacher/assignment";
import QuestionClassification from "../model/teacher/questionClassification";

export async function getActivityStandards(activityRef, standardId?) {
  if (activityRef?.indexOf("PREBUILDS") !== -1) {
    //domain or summative test
    return "";
  } else {
    let standardIds: Array<number> = [];
    const id = activityRef?.split("-")[1] ?? 0;
    const isAssessment =
      activityRef?.indexOf("ASSESSMENT") !== -1 ? true : false;
    if (isAssessment) {
      return await getAssessmentById(id).then((response) => {
        standardIds = response.data.questions?.map(
          (question, questionIndex) => {
            return question.standardId;
          }
        );
        return standardIds?.join(",");
      });
    } else {
      if (standardId) {
        return standardId;
      } else {
        return await getAssignmentActivityStandardsById(id).then((response) => {
          standardIds = response.data;
          return standardIds?.join(",");
        });
      }
    }
  }
}

export function renderClassificationDetailsStudentResult(
  element,
  questionId,
  isSpanish = false
) {
  var divElement = document.createElement("div");

  var html = `<div class="flex-col items-center mb-4 p-4 bg-gray-50 border rounded-md">  
  <span class="font-semibold text-gray-700">
    ${
      isSpanish ? "identificación de la pregunta" : "Question Id"
    }: ${questionId}
  </span> </div>
`;
  divElement.innerHTML = html;
  element?.insertAdjacentElement("afterbegin", divElement);
}

export function renderClassificationDetailsHtml(
  element,
  questionId,
  isSpanish = false,
  classification?: QuestionClassification
) {
  //Return if classifications have already been set.
  if (element.dataset.hasOwnProperty("hasClassifications")) return;

  element.dataset.hasClassifications = "";

  var divElement = document.createElement("div");
  divElement.className = "classification-" + questionId;
    var html = `<div class="mb-4">
      <span class="font-semibold text-gray-700 text-base">
      ${
        isSpanish ? "identificación de la pregunta" : "Question Id"
      }: ${questionId}
      </span>
      ${!classification?"": `<div class="ml-auto mr-4">
      <div class="flex flex-col">
                        <div>
                            <span class="font-semibold text-gray-700 text-base">
                        ${
                          isSpanish
                            ? classification.spanishSubjectName ??
                              classification.subjectName
                            : classification.subjectName
                        } /
                            </span>
                            <span class="text-primary-violet ml-1">
                            ${
                              isSpanish
                                ? classification.spanishDomainName ??
                                  classification.domainName
                                : classification.domainName
                            }
                            </span>
                        </div>
                        <div class="mt-1">
                            <span class="">
                            [${classification.standardCode}]
                            ${
                              isSpanish
                                ? classification.spanishStandardName ??
                                  classification.standardName
                                : classification.standardName
                            } 
                            </span>                          
                            <span class="px-1 border border-gray-300 bg-gray-200 ml-1 mt-1">
                            ${
                              classification?.dokId +
                              " - " +
                              classification?.dokName
                            }
                            </span>                            
                        </div>
                        </div>
                        </div>
                         `}
                        </div>`;

    divElement.innerHTML = html;
    if (
      document.querySelectorAll(".classification-" + questionId).length === 0
    ) {
      element?.insertAdjacentElement("afterbegin", divElement);
    }
}
export function renderClassificationDetailsItemAnalysisHtml(
  element,
  questionId,
  isSpanish = false,
  questionclassification?
) {
  var divElement = document.createElement("div");
  divElement.className = "classification-" + questionId;
  if (questionclassification) {
    const classification: QuestionClassification = questionclassification;
    var html = `<div class="text-xs mb-4">
      <span class="font-semibold text-gray-700">
      ${
        isSpanish ? "identificación de la pregunta" : "Question Id"
      }: ${questionId}
      </span>
      <div class="ml-auto mr-4">
      <div class="flex flex-col">
                        <div>
                            <span class="font-semibold text-gray-700 ">
                        ${
                          isSpanish
                            ? classification.spanishSubjectName ??
                              classification.subjectName
                            : classification.subjectName
                        } /
                            </span>
                            <span class="text-primary-violet ml-1">
                            ${
                              isSpanish
                                ? classification.spanishDomainName ??
                                  classification.domainName
                                : classification.domainName
                            }
                            </span>
                        </div>
                        <div class="mt-1">
                            <span class="">
                            [${classification.standardCode}]
                            ${
                              isSpanish
                                ? classification.spanishStandardName ??
                                  classification.standardName
                                : classification.standardName
                            } 
                            </span>                          
                            <span class="px-1 border border-gray-300 bg-gray-200 ml-1 mt-1">
                            ${
                              classification?.dokId +
                              " - " +
                              classification?.dokName
                            }
                            </span>                            
                        </div>
                        </div>
                        </div>
                        </div>`;
    divElement.innerHTML = html;
    if (
      document.querySelectorAll(".classification-" + questionId).length === 0
    ) {
      element?.insertAdjacentElement("afterbegin", divElement);
    }
  }
}

export abstract class LearnosityEnums {
    static AttemptStatus = class {
        public static readonly FULLY_ATTEMPTED = "fully_attempted";
        public static readonly NOT_ATTEMPTED = "not_attempted";
    };
}

export abstract class LearnosityDom {
    public static setContinueSectionWarningButtonVisibility(hide: boolean) {
        const continueButton = this.getContinueSectionWarningButton();
        if (!continueButton) return;
        if(hide) {
            continueButton.style.display = "none";
        } else continueButton.style.display = '';
    }

    public static getCalculators(): HTMLCollectionOf<Element> {
        return document.getElementsByClassName("desmos-calculator-name");
    }

    public static getCalculatorContainers(): HTMLCollectionOf<Element> {
        return document.getElementsByClassName("desmos-calculator-container");
    }

    public static getContinueSectionWarningButton() {
        return document.querySelector(
            "div[id^='section-warning-change-modal'] div[aria-labelledby^='item-warning-change-modal'] button.continue"
        ) as HTMLElement;
    }

    public static getBarSideButtons(): HTMLCollectionOf<Element> {
        return document.getElementsByClassName("lrn_btn test-resource pos-middle");
    }

    public static getCalculatorButtons(): HTMLCollectionOf<Element> {
        return document.getElementsByClassName("lrn_btn lrn-calc-toggle");
    }

    public static getResponseMaskingButton(language: string | undefined): HTMLElement {
        return language === "es"
            ? (document.querySelector(
                "[data-original-title='Enmascaramiento de respuesta']"
            ) as HTMLElement)
            : (document.querySelector(
                "[data-original-title='Response Masking']"
            ) as HTMLElement);
    }

    public static getImageResponses(): NodeListOf<Element> {
        return document.querySelectorAll(".lrn_imagecloze_response");
    }

    public static getSubmitButton() {
        return document.querySelector(".lrn_btn.lrn_btn_blue.test-submit.item-next.pos-right");
    }

    public static getResumeButton() {
        return document.querySelector("button[class='test-dialog-resume lrn_btn_blue']") as HTMLElement;
    }

    // We get the text editor container, which is a div with a specific data-reference attribute
    public static getTextEditor(itemReference: string) {
        var containerSelector = `div[data-reference="${itemReference}"] .lrn_texteditor_editable.lrn_editor_area`;
        return document.querySelector(containerSelector);
    }

    public static getNextOrSubmitButton() {
        return document.getElementById('lrn_assess_next_btn') as HTMLButtonElement;
    }
}
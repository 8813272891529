import { AxiosResponse } from "axios";
import { AxiosHelper } from "../../environment/axiosConfig";
import APIConstant from "../../utils/constant/apiConstant";
export function initializeStudentAssessmentActivity(
  userId: number,
  language: string,
  code: string,
  isBegin: boolean,
  classId?: number,
  teacherId?: number,
  gradeId?: number
): Promise<AxiosResponse<any>> {
  return AxiosHelper.axiosInstance().post<any>(
    APIConstant.STUDENTASSESSMENTACTIVITY_API.Initialize,
    {
      userId: userId,
      language: language,
      code: code,
      isBegin: isBegin,
      classId: classId,
      teacherId: teacherId,
      gradeId: gradeId,
    }
  );
}
export function completeStudentAssessmentActivity(
  userId?: number,
  learnositySessionId?: string,
  activitySessionId?: number
) {
  return AxiosHelper.axiosInstance().put(APIConstant.STUDENTASSESSMENTACTIVITY_API.Complete, {
    userId: userId,
    sessionId: learnositySessionId,
    activitySessionId: activitySessionId,
  });
}
export function updateStudentAssessmenttActivity(
  userId?: number,
  learnositySessionId?: string,
  classId?: number,
  teacherId?: number,
  languageId?: number
) {
  return AxiosHelper.axiosInstance().put(APIConstant.STUDENTASSESSMENTACTIVITY_API.Update, {
    userId: userId,
    sessionId: learnositySessionId,
    classId: classId,
    teacherId: teacherId,
    languageId: languageId,
  });
}
export function getAssessmentByCode(code: string, userId: number) {
  return AxiosHelper.axiosInstance().get(APIConstant.ASSESSMENT_API.GetByCode, {
    params: {
      code: code,
      userId: userId
    },
  });
}

export function getStudentAssessmentLastSession(
  userId: number,
  code: string,
  languageId: number
) {
  return AxiosHelper.axiosInstance().get(
    APIConstant.STUDENTASSESSMENTACTIVITY_API.LastSession,
    {
      params: {
        userId: userId,
        code: code,       
        languageId: languageId
      },
    }
  );
}

export function getAssessmentAttemptCount(code: string, userId: number) {
  return AxiosHelper.axiosInstance().get(APIConstant.ASSESSMENT_API.GetAttemptCount, {
    params: {
      code: code,
      userId: userId
    },
  });
}
import React, { useState, useEffect } from "react";
import PIEChart from "../../shared/charts/piechart";

export default function TotalQuestionsByDomains({ data }: any) {
  const [domainDataValues, setDomainDataValues] = useState<Array<any>>([]);
  const [toggleShowMore, setToggleShowMoreOption] = useState<boolean>(false);
  const [domainsData, setDomaninsData] = useState<Array<any>>([]);

  useEffect(() => {
    if (data && data.domains && Object.keys(data.domains).length) {
      let chartDivHeight: any = document.getElementById("chart_id");
      if (chartDivHeight) chartDivHeight = chartDivHeight.style.height;

      let totalQuestions = 0;

      if (data && data.domains && Object.keys(data.domains).length > 0) {
        Object.entries(data.domains).forEach(
          ([key, domain]: any) =>
            (totalQuestions += domain.total_points
              ? parseInt(domain.total_points)
              : 0)
        );
      }

      const domainData: any = [];
      Object.entries(data.domains).forEach(([key, value]: any) => {
        domainData.push({
          name: value.name,
          value: Number(
            ((value.total_points * 100) / totalQuestions).toFixed(2)
          ),
        });
      });

      setDomaninsData(domainData);
      setDomainDataValues(domainData.slice(0, 10));
    }
  }, []);

  const showHideGraphDetails = (): void => {
    if (!toggleShowMore) {
      setDomainDataValues(domainsData);
    } else {
      setDomainDataValues(domainsData.slice(0, 10));
    }
    setToggleShowMoreOption(!toggleShowMore);
  };

  return (
    <>
      {domainDataValues.length > 1 && (
        <div className="relative w-full px-7 py-7 bg-white rounded-md shadow-md xs-chart-fix xs-standards-chart-fix">
          <div
            style={{
              display: "inline-block",
            }}
          >
            <h2 className="text-md font-semibold text-gray-800 mb-2">
              Question Breakdown Percent by Domain
            </h2>

            {domainDataValues.length > 1 && (
              <PIEChart data={domainDataValues}></PIEChart>
            )}

            {domainDataValues.length >= 10 && (
              <div style={{ textAlign: "center" }} className="show-more">
                {toggleShowMore ? (
                  <button
                    style={{ fontSize: "15px", color: "blue" }}
                    onClick={showHideGraphDetails}
                  >
                    Hide &#x2C4;
                  </button>
                ) : (
                  <button
                    style={{ fontSize: "15px", color: "blue" }}
                    onClick={showHideGraphDetails}
                  >
                    Show More &#x2C5;
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}

import { Fragment } from "react";
import { ExclamationIcon } from "../../assets/icons";

interface WarningMessageProps {
  message: string;
  title?: string;
}
function WarningMessage(props: WarningMessageProps) {
  return (
    <Fragment>
      <div className="mx-4 sm:mx-0 my-4 text-sm text-gray-700 font-light bg-red-200 p-4 flex rounded-md rounded-t-lg sm:rounded-lg overflow-hidden">
        <div className="flex-shrink-0 current-fill text-blue-400">
          <ExclamationIcon
            title={props.title ? props.title : ""}
            className="w-1 mr-2"
          />
        </div>
        <div
          className="pl-1"
          dangerouslySetInnerHTML={{ __html: props.message }}
        />
      </div>
    </Fragment>
  );
}

export default WarningMessage;

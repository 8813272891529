import { XIcon } from "@heroicons/react/solid";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Configuration } from "../../../../../environment/setup";
import { useConfirmation } from "../../../../shared/confirmation/confirmationService";

export interface RocketPopupProps {
  rewards?: any;
  showPopup: (show: boolean) => void;
}

const RocketPopup: React.FC<RocketPopupProps> = (props) => {
  const { t } = useTranslation();
  const confirm = useConfirmation();
  const [rockets, setRockets] = useState<Array<any>>([]);

  useEffect(() => {
    setRockets(props?.rewards?.plRockets);
  }, [props?.rewards]);

  const onRocketClicked = (rocket: any) => {
    if (rocket.isLocked) {
      confirm({
        variant: "info",
        title: "",
        description:
          t("Rockets.UnlockRoketMessage") +
          rocket.starsRequiredToUnlock +
          t("Rockets.GalaxyStarsUnlockRocket"),
      });
    }
  };

  return (
    <>
      <section>
        <div className="fixed inset-0 z-50 flex items-center bg-black bg-opacity-50 sm:items-center sm:justify-center enter-done">
          <div className="w-full flex flex-col justify-between overflow-hidden h-auto max-h-80percent bg-white rounded-lg dark:bg-gray-800 sm:m-4 sm:max-w-xl">
            <header className="flex justify-end p-4">
              <XIcon
                className="cursor-pointer h-4 w-4"
                onClick={() => props.showPopup(false)}
              ></XIcon>
            </header>
            <div
              data-focus-lock-disabled="false"
              className="w-full relative overflow-auto px-4 py-3"
            >
              <div className="mt-0 mb-2 text-lg font-semibold text-gray-700 dark:text-gray-300 mb-6">
                <p>
                  {t("Rockets.YouHave")}{" "}
                  {
                    rockets?.filter((r: any) => {
                      return !r.isLocked;
                    }).length
                  }{" "}
                  {t("Rockets.OutOf")}
                </p>
                {rockets?.filter((r: any) => r.isLocked).length > 0 && (
                  <p className="font-normal text-sm">
                    {t("Rockets.Get")}{" "}
                    {
                      rockets?.filter((r: any) => r.isLocked)[0]
                        .starsRequiredToUnlock
                    }{" "}
                    {t("Rockets.More")}
                  </p>
                )}
              </div>
              <div className="max-w-7xl mx-auto bg-[#23afff] p-4 rounded-2xl">
                <ul className="grid grid-cols-2 gap-x-2 gap-y-3 sm:grid-cols-3 sm:gap-x-3 lg:grid-cols-6 xl:gap-x-4">
                  {rockets?.map((r: any, i) => (
                    <li
                      className="relative cursor-pointer"
                      onClick={() => {
                        onRocketClicked(r);
                      }}
                    >
                      <div
                        title="Rocket"
                        className="group bg-white bg-opacity-50 hover:bg-opacity-80 p-2 block w-full rounded-lg focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden transition ease-in-out duration-500"
                      >
                        {(!r.isLocked && (
                          <img
                            src={`${Configuration.S3bucketImagePath}/images/student/kindergarten/rockets/rocket${i}.png`}
                            alt="Rocket"
                            title="Rocket"
                            className="object-cover pointer-events-none m-auto h-16"
                          />
                        )) || (
                          <img
                            src={`${Configuration.S3bucketImagePath}/images/student/kindergarten/rockets/rocket_locked${i}.png`}
                            alt="Rocket"
                            title="Rocket"
                            className="object-cover pointer-events-none m-auto h-16"
                          />
                        )}
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <footer className="flex bg-gray-50 flex-col items-center justify-end px-6 py-4 sm:flex-row">
              <button
                className="align-bottom inline-flex font-bold items-center justify-center cursor-pointer leading-5 transition-colors duration-150  focus:outline-none px-5 py-3 rounded-lg text-lg text-white bg-primary-violet hover:shadow-lg hover:bg-dark-violet"
                type="button"
                onClick={() => props.showPopup(false)}
              >
                Ok
              </button>
            </footer>
          </div>
        </div>
      </section>
    </>
  );
};

export default RocketPopup;

import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { OutlineBackCurveIcon } from "../../../../assets/icons";
import PreviewPage from "../shared/questionPreview/learnosityPreviewPage";
import constant from "../../../../utils/constant/routeConstant";
import { getUrlParams } from "../../../../utils/helper";
import { useTranslation } from "react-i18next";
import Classification from "../shared/questionPreview/classification";

function PreviewQuestion(props) {
  const { t } = useTranslation();
  const subjectId = getUrlParams()?.subjectId || undefined;
  const bellRingerId = getUrlParams()?.bellRingerId || undefined;
  let LS: any = localStorage.getItem("bellRinger");
  LS = JSON.parse(LS);
  const handleRedirect = () => {
    if (!bellRingerId) {
      return (
        constant.TeacherRoutes.BellRingerNew +
        `?subjectId=${subjectId}&active=1`
      );
    } else {
      return (
        constant.TeacherRoutes.BellRingerEdit.replace(":id", bellRingerId) +
        `?subjectId=${subjectId}`
      );
    }
  };

  return (
    <div className="p-5 bg-gray-100 w-full rounded-lg h-screen">
      <div className="flex justify-start">
        <Link
          to={handleRedirect()}
          className="group bg-transparent border border-primary-violet shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-primary-violet hover:text-white hover:bg-light-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3 transition ease-in-out duration-300"
        >
          <OutlineBackCurveIcon className="w-5 mr-2 group-hover:text-white text-primary-violet" />
          Back To Bell Ringer
        </Link>
      </div>

      <div className="mt-5 bg-white shadow border py-4 px-4">
        {LS.instructions && (
          <div className="p-3 flex items-start justify-between space-x-6 w-full mb-6">
            <div
              className={`text-sm text-gray-700 font-light bg-[#00b1fb] bg-opacity-10 p-4 flex w-full rounded-md rounded-t-lg sm:rounded-lg overflow-hidden`}
            >
              <div className={`flex-shrink-0 current-fill text-[#00b1fb]`}>
                <svg
                  className="h-5 w-5"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </div>

              <div className="pl-2">
                <span className="font-semibold text-gray-700 pr-2 mb-1 inline-block">
                  {t("BellRinger.Instructions")}
                </span>
                <br />
                <p>{LS.instructions}</p>
              </div>
            </div>
          </div>
        )}
        <div>
          <span className="font-semibold text-gray-700 text-base">
            Question Id: {parseInt(props.match.params.id?.split("-")[0] ?? "0")}
          </span>
        </div>
        <Classification
          questionId={parseInt(props.match.params.id?.split("-")[0] ?? "0")}
          standardId={LS.standardId ?? 0}
          questionTypeId={
            props.match.params.id?.indexOf("-teacher") !== -1 ? 2 : 1
          }
        ></Classification>
        <PreviewPage
          itemRef={props.match.params.id}
          activityRef={props.activityRef}
          isProjectorMode={props.isProjectorMode}
        />
      </div>
    </div>
  );
}

export default connect((state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
})(PreviewQuestion);

import { SortingOrder } from "../types/type"

export type ISortReportOptions = {
  data: any,
  column: string,
  orderType: SortingOrder,
  defaultName?: string,
  defaultSurnameOrder?: boolean
}

function isValidString (value?: string) {
  return value != null && value.toString().length > 0;
};

function sortNames (firstEntityName: string, secondEntityName: string, orderType: SortingOrder) {
  const firstString = firstEntityName != null 
    ? firstEntityName.split(" ").reverse().join(" ")
    : firstEntityName;
  const secondString = secondEntityName != null
    ? secondEntityName.split(" ").reverse().join(" ")
    : secondEntityName;

  return sortStrings(firstString, secondString, orderType);
}

function sortStrings (firstString: string, secondString: string , orderType: SortingOrder) {
  return orderType === 'asc' 
    ?  firstString.toString().toLowerCase().localeCompare(secondString.toString().toLowerCase())
    : secondString.toString().toLowerCase().localeCompare(firstString.toString().toLowerCase());
};

function sortNumbers (firstNumber: number, secondNumber: number, orderType: SortingOrder) {
  return orderType === 'asc' 
    ?  firstNumber - secondNumber
    : secondNumber - firstNumber;
}

export function sortReport (options: ISortReportOptions) {
  const { data, column, orderType, defaultName, defaultSurnameOrder } = options
  if (data == null ) return data

  const compareRecords = (firstItem: any, secondItem: any) => {
    if (isValidString(defaultName) && firstItem.name === defaultName) {
      return -1;
    } else if (isValidString(defaultName) && secondItem.name === defaultName) {
      return 1;
    } else if (!isNaN(firstItem[column]) && !isNaN(secondItem[column])) {
      return sortNumbers(firstItem[column], secondItem[column], orderType);
    } else if (column === 'name' && defaultSurnameOrder) {
      return sortNames(firstItem[column], secondItem[column], orderType)
    }
    return sortStrings(firstItem[column], secondItem[column], orderType);
  }

  data.sort(compareRecords);
  return data;
}
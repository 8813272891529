import React, { useEffect, useState } from "react";
import { RefreshIcon } from "@heroicons/react/outline";
import { ColorPaletteIcon, ResetIcon } from "../../assets/icons/index";
import getColors from "../shared/tagColor";
import classNames from "classnames";

const ColorPicker: React.FC<any> = (props) => {
  const [colorCode, setColorCode] = useState<string>("");
  const [toggleColorPalette, setColorPalette] = useState<boolean>(false);
  const [isColorChanged, setColorChanged] = useState<boolean>(false);

  useEffect(() => {
    const tagPopup: any = document.getElementById("tagPopup");
    const tagPopupBtn: any = document.getElementById("tagPopupBtn");

    if (tagPopup) {
      window.onclick = function (event) {
        if (
          event.target !== tagPopup &&
          event.target !== tagPopupBtn &&
          toggleColorPalette
        ) {
          setColorPalette(false);
        }
      };
    }
  }, [toggleColorPalette]);

  useEffect(() => {
    var color = getRandomColor();
    setColorCode(props.defaultValue === "" ? color : props.defaultValue);
    props.setSelectedColor(
      props.defaultValue === "" ? color : props.defaultValue
    );
  }, [props.defaultValue]);

  function randomNumber(min, max) {
    return Math.random() * (max - min) + min;
  }
  function getRandomColor() {
    var colors: Array<string> = [
      "#F3F4F6",
      "#E5E7EB",
      "#D1D5DB",
      "#9CA3AF",
      "#6B7280",
      "#4B5563",
      "#374151",
      "#1F2937",
      "#111827",
      "#FEE2E2",
      "#FECACA",
      "#FCA5A5",
      "#F87171",
      "#EF4444",
      "#DC2626",
      "#B91C1C",
      "#991B1B",
      "#7F1D1D",
      "#FEF3C7",
      "#FDE68A",
      "#FCD34D",
      "#FBBF24",
      "#F59E0B",
      "#D97706",
      "#B45309",
      "#92400E",
      "#78350F",
      "#D1FAE5",
      "#A7F3D0",
      "#6EE7B7",
      "#34D399",
      "#10B981",
      "#059669",
      "#047857",
      "#065F46",
      "#064E3B",
      "#DBEAFE",
      "#BFDBFE",
      "#93C5FD",
      "#60A5FA",
      "#3B82F6",
      "#2563EB",
      "#1D4ED8",
      "#1E40AF",
      "#1E3A8A",
      "#E0E7FF",
      "#C7D2FE",
      "#A5B4FC",
      "#818CF8",
      "#6366F1",
      "#4F46E5",
      "#4338CA",
      "#3730A3",
      "#312E81",
      "#EDE9FE",
      "#DDD6FE",
      "#C4B5FD",
      "#A78BFA",
      "#8B5CF6",
      "#7C3AED",
      "#6D28D9",
      "#5B21B6",
      "#4C1D95",
      "#FCE7F3",
      "#FBCFE8",
      "#F9A8D4",
      "#F472B6",
      "#EC4899",
      "#DB2777",
      "#BE185D",
      "#9D174D",
      "#831843",
    ];
    var rNumber = parseInt(randomNumber(1, 70));
    return colors[rNumber];
  }
  function handlePaletteClick(selectedColor: string) {
    setColorCode(selectedColor);
    setColorPalette(!toggleColorPalette);
    props.setSelectedColor(selectedColor);
    setColorChanged(true);
  }

  function getTextColor() {
    let textColor = "";
    const selectedColor = getColors.filter((r) => r.code === colorCode);
    if (selectedColor && selectedColor.length > 0) {
      if (selectedColor[0].isTextWhite) {
        textColor = "text-white ";
      }
    }

    return textColor;
  }

  function handleRandomColor() {
    const existingColors = getColors;
    const randomColor =
      existingColors[Math.floor(Math.random() * existingColors.length)];
    setColorCode(randomColor.code);
    props.setSelectedColor(randomColor.code);
    setColorChanged(true);
  }
  function handleResetColor() {
    const defaultColor = "#FFFFFF";
    setColorCode(defaultColor);
    props.setSelectedColor(defaultColor);
  }

  return (
    <div className="flex gap-2">
      <input
        title="Class color code can be used to easily distinguish between classes on your assignments calendar on your homepage"
        id="color"
        type="text"
        autoComplete="off"
        defaultValue={colorCode}
        className={classNames(
          props.hideInputField ? "hidden" : "",
          "h-10 w-18 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:ring-primary-violet focus:border-primary-violet sm:text-sm"
        )}
        disabled={props.disabled}
      />
      <div className="flex justify-end space-x-2 cursor-pointer">
        <span
          title={props.title}
          id="tagPopupBtn"
          className={`w-10 h-10 bg-[${colorCode}] ${getTextColor()} cursor-pointer rounded-lg p-2 flex items-center justify-center `}
          onClick={() => setColorPalette(!toggleColorPalette)}
        >
          <ColorPaletteIcon className="pointer-events-none w-5" />
        </span>
        {!props.hideRefreshIcon && (
          <span
            title="Refresh Class Color"
            className="cursor-pointer w-10 h-10 bg-gray-200 text-gray-700 rounded-lg p-2 flex items-center justify-center"
          >
            <RefreshIcon onClick={() => handleRandomColor()} className="w-5" />
          </span>
        )}
        {isColorChanged && (
          <span
            title="Reset Class Color"
            className="cursor-pointer w-10 h-10 bg-gray-200 text-gray-700 rounded-lg p-2 flex items-center justify-center"
          >
            <ResetIcon onClick={() => handleResetColor()} className="w-5" />
          </span>
        )}
      </div>

      <div
        id="tagPopup"
        className={`rounded-md bg-white shadow p-2 w-72 absolute z-40 ${
          toggleColorPalette ? "" : "hidden"
        }`}
      >
        <div className="flex">
          {[...Array(getColors.length / 9)].map((x, i) => (
            <div key={i}>
              {getColors.slice(i * 9, i * 9 + 9).map((color) => {
                return (
                  <div
                    key={color.id}
                    onClick={() => handlePaletteClick(color.code)}
                    className={`cursor-pointer w-6 h-6 rounded-full mx-1 my-1 bg-[${color.code}]`}
                  ></div>
                );
              })}
            </div>
          ))}
        </div>
      </div>

      <div className="hidden">
        <span className="bg-[#F3F4F6]"></span>
        <span className="bg-[#E5E7EB]"></span>
        <span className="bg-[#D1D5DB]"></span>
        <span className="bg-[#9CA3AF]"></span>
        <span className="bg-[#6B7280]"></span>
        <span className="bg-[#4B5563]"></span>
        <span className="bg-[#374151]"></span>
        <span className="bg-[#1F2937]"></span>
        <span className="bg-[#111827]"></span>
        <span className="bg-[#FEE2E2]"></span>
        <span className="bg-[#FECACA]"></span>
        <span className="bg-[#FCA5A5]"></span>
        <span className="bg-[#F87171]"></span>
        <span className="bg-[#EF4444]"></span>
        <span className="bg-[#DC2626]"></span>
        <span className="bg-[#B91C1C]"></span>
        <span className="bg-[#991B1B]"></span>
        <span className="bg-[#7F1D1D]"></span>
        <span className="bg-[#FEF3C7]"></span>
        <span className="bg-[#FDE68A]"></span>
        <span className="bg-[#FCD34D]"></span>
        <span className="bg-[#FBBF24]"></span>
        <span className="bg-[#F59E0B]"></span>
        <span className="bg-[#D97706]"></span>
        <span className="bg-[#B45309]"></span>
        <span className="bg-[#92400E]"></span>
        <span className="bg-[#78350F]"></span>
        <span className="bg-[#D1FAE5]"></span>
        <span className="bg-[#A7F3D0]"></span>
        <span className="bg-[#6EE7B7]"></span>
        <span className="bg-[#34D399]"></span>
        <span className="bg-[#10B981]"></span>
        <span className="bg-[#059669]"></span>
        <span className="bg-[#047857]"></span>
        <span className="bg-[#065F46]"></span>
        <span className="bg-[#064E3B]"></span>
        <span className="bg-[#DBEAFE]"></span>
        <span className="bg-[#BFDBFE]"></span>
        <span className="bg-[#93C5FD]"></span>
        <span className="bg-[#60A5FA]"></span>
        <span className="bg-[#3B82F6]"></span>
        <span className="bg-[#2563EB]"></span>
        <span className="bg-[#1D4ED8]"></span>
        <span className="bg-[#1E40AF]"></span>
        <span className="bg-[#1E3A8A]"></span>
        <span className="bg-[#E0E7FF]"></span>
        <span className="bg-[#C7D2FE]"></span>
        <span className="bg-[#A5B4FC]"></span>
        <span className="bg-[#818CF8]"></span>
        <span className="bg-[#6366F1]"></span>
        <span className="bg-[#4F46E5]"></span>
        <span className="bg-[#4338CA]"></span>
        <span className="bg-[#3730A3]"></span>
        <span className="bg-[#312E81]"></span>
        <span className="bg-[#EDE9FE]"></span>
        <span className="bg-[#DDD6FE]"></span>
        <span className="bg-[#C4B5FD]"></span>
        <span className="bg-[#A78BFA]"></span>
        <span className="bg-[#8B5CF6]"></span>
        <span className="bg-[#7C3AED]"></span>
        <span className="bg-[#6D28D9]"></span>
        <span className="bg-[#5B21B6]"></span>
        <span className="bg-[#4C1D95]"></span>
        <span className="bg-[#FCE7F3]"></span>
        <span className="bg-[#FBCFE8]"></span>
        <span className="bg-[#F9A8D4]"></span>
        <span className="bg-[#F472B6]"></span>
        <span className="bg-[#EC4899]"></span>
        <span className="bg-[#DB2777]"></span>
        <span className="bg-[#BE185D]"></span>
        <span className="bg-[#9D174D]"></span>
        <span className="bg-[#831843]"></span>
      </div>
    </div>
  );
};

export default ColorPicker;

import React, { useState, useEffect, Fragment } from "react";
import moment from "moment";
import { InfoBox } from "./interfaces";
import { CloseIcon, RightArrowIcon } from "../../../../../assets/icons";

import { useHistory } from "react-router-dom";

import Breadcrumb from "../breadcrumb";
import { IBreadcrumb } from "../../../../../model/common/breadcrumb";
import routeConstant from "../../../../../utils/constant/routeConstant";
import { useTranslation } from "react-i18next";
import { getGradedWork } from "../../../../../api/student/gradedWork";
// import Loader from "../../../../shared/loader";
import Select from "react-select";
import { connect } from "react-redux";
import {
  DEFAULT_PAGE_NUMBER,
  DEFAULT_PAGE_SIZE,
} from "../../../../../utils/pagingConstant";
import Pagination from "../../../../shared/pagination/student/pagination";
import PageSize from "../../../../shared/pagination/student/pageSize";
import { getStudentSubject } from "../../../../../api/student/studentSubjectApi";
import DatePicker from "react-datepicker";
import StudentClasses from "../../../../shared/dropdowns/studentClasses";
import {
  getScoreLabel,
  getScoreRounded,
} from "../../../../../utils/scoreHelper";
import {
  liftoffDiagnosticResultRedirectByStudent,
  myGalaxyStandardcResultRedirectByStudent,
} from "../../../teacher/reports/studentResultRedirection";
function GradedWork(props: any): JSX.Element {
  const [gradedWorkData, setGradedWorkData] = useState<any>();
  const [infoText, setInfoText] = useState<InfoBox>();
  const [selectedClass, setSelectedClass] = useState<any>();
  const [pageNumber, setPageNumber] = useState(DEFAULT_PAGE_NUMBER);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [resetPageNumber, setResetPageNumber] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const history = useHistory();

  const [assignmentType, setAssignmentType] = useState<number>(0);
  const [subjects, setSubjects] = useState<any>([]);
  const [selectedSubject, setSelectedSubject] = useState<any>();
  const { t } = useTranslation();

  const onPageChange = (pageNo) => {
    setPageNumber(pageNo);
  };

  const handleViewClick = (data) => {
    if (data.activityType === "STUDYPLAN") {
      history.push(
        routeConstant.MiddleSchool.StudyPlanResult.replace(
          ":sessionId",
          data.sessionId
        )
      );
    } else if (data.activityType === "SUMMATIVETEST") {
      history.push(
        routeConstant.MiddleSchool.SummativeTestResult.replace(
          ":sessionId",
          data.sessionId
        )
      );
    } else if (data.activityType === "DOMAINTEST") {
      history.push(
        routeConstant.MiddleSchool.DomainTestResult.replace(
          ":sessionId",
          data.sessionId
        )
      );
    } else if (data.activityType === "CANNEDTEST") {
      history.push(
        routeConstant.MiddleSchool.cannedTestResult.replace(
          ":sessionId",
          data.sessionId
        )
      );
    } else if (data.activityType === "DIAGNOSTIC") {
      history.push(
        routeConstant.MiddleSchool.DiagnosticsTestResult.replace(
          ":sessionId",
          data.sessionId
        )
      );
    } else if (data.activityType === "VIDEOLEARN") {
      history.push(
        routeConstant.MiddleSchool.VideoResult.replace(
          ":sessionId",
          data.sessionId
        )
      );
    } else if (data.activityType === "LIFTOFFDIAGNOSTIC") {
      liftoffDiagnosticResultRedirectByStudent(
        data.sessionId,
        "middleschool",
        "gradedwork"
      );
    } else if (data.activityType === "LIFTOFFMYGALAXY") {
      myGalaxyStandardcResultRedirectByStudent(
        data.sessionId,
        "middleschool",
        "gradedwork"
      );
    } else {
      history.push(
        routeConstant.MiddleSchool.AssignmentResult.replace(
          ":assignmentId",
          data.assignmentId
        )
          .replace(":assignmentActivityId", data.activityId)
          .replace(":runningAssignment", "No")
          .replace(":sessionId", data.sessionId)
      );
    }
  };

  const getSubjects = () => {
    getStudentSubject(props.userContext?.userId ?? 0).then((res) => {
      const subjects = res.data?.map((values: any) => {
        return {
          label: values?.name,
          value: values?.subjectId,
        };
      });
      setSubjects(subjects);
    });
  };

  function handleStartDate(date: any) {
    if (endDate && date > endDate) {
      return false;
    }
    setStartDate(date);
  }

  function handleDueDate(date: any) {
    if (startDate && date < startDate) {
      return false;
    }
    setEndDate(date);
  }

  const fetchGradedWork = (viewType: number, isClear?: boolean) => {
    setAssignmentType(viewType);
    getGradedWork(
      props.userContext.userId,
      viewType ?? assignmentType,
      isClear ? 0 : selectedClass?.value ? selectedClass?.value : 0,
      isClear ? 0 : selectedSubject?.value ? selectedSubject?.value : 0,
      startDate ? moment(startDate).format("MM-DD-yyyy") : undefined,
      endDate ? moment(endDate).format("MM-DD-yyyy") : undefined,
      pageSize,
      pageNumber
    ).then((res) => {
      setGradedWorkData(res.data);
    });
  };
  useEffect(() => {
    getSubjects();
    fetchGradedWork(assignmentType);
    const innerUl = document.getElementById("ul1");
    if (innerUl) innerUl.classList.add("anim-0");
  }, [pageSize, pageNumber]);

  const breadcrumbItems = () => {
    const items: Array<IBreadcrumb> = [
      {
        name: t("Breadcrumb.Home"),
        url: routeConstant.MiddleSchool.assignments,
        textColor: "text-white",
      },
      {
        name: t("Breadcrumb.GradedWork"),
        textColor: "text-white",
        url: "",
      },
    ];

    return items;
  };
  const renderInfo: any = (idx: any) => {
    return (
      <div
        aria-live="assertive"
        className="absolute inset-0 flex px-4 py-6 pointer-events-none items-start"
      >
        <div
          className={`md:top-2/4 top-2/4 ${
            idx === 2 && "md:left-1/4"
          } absolute w-full flex flex-col items-center space-y-4 sm:items-start z-10`}
        >
          <div className="max-w-sm w-full bg-yellow-200 shadow-lg rounded-md pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
            <div className="p-4">
              <div className="flex items-start">
                <div className="w-0 flex-1 pt-0.5">
                  <p className="text-sm font-semibold text-blue">
                    {infoText && infoText.header}
                  </p>
                  <p className="mt-1 text-sm text-gray-800">
                    {infoText && infoText.body}{" "}
                  </p>
                </div>
                <div className="ml-4 flex-shrink-0 flex">
                  <button className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-0 ">
                    <span className="sr-only">Close</span>
                    <Fragment>
                      <span
                        onClick={() =>
                          setInfoText({ header: "", body: "", index: 0 })
                        }
                      >
                        <CloseIcon />
                      </span>
                    </Fragment>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const onPageSizeChange = (pageSize) => {
    setPageNumber(DEFAULT_PAGE_NUMBER);
    setPageSize(pageSize);
    setResetPageNumber(true);
  };

  const teacherClassOnChange: any = (
    event: React.FormEvent<EventTarget>
  ): void => {
    setSelectedClass(event);
  };

  const handleViewFilter = (viewType: number) => {
    setPageNumber(DEFAULT_PAGE_NUMBER);
    setAssignmentType(viewType);
    fetchGradedWork(viewType);
  };

  const handleSubjectChange = (e) => {
    setSelectedSubject(e);
  };

  const handleSearch = () => {
    fetchGradedWork(0);
  };

  const handleClear = () => {
    setSelectedClass({ label: "", value: 0 });
    setSelectedSubject({ label: "", value: 0 });
    setStartDate(undefined);
    setEndDate(undefined);
    fetchGradedWork(0, true);
  };

  function isPastDate(completedDate: Date, dueDate: Date | undefined) {
    if (dueDate === undefined) {
      return false;
    }

    let isPastDate: boolean = false;
    isPastDate = moment(completedDate, ["YYYY-MM-DD"]).isAfter(
      moment(dueDate, ["YYYY-MM-DD"]),
      "day"
    );

    return isPastDate;
  }

  function isPendingScore(assignmentData) {
    return (
      (assignmentData.activityType === "CONSTRUCTED RESPONSE" ||
        assignmentData.activityType === "ASSESSMENT") &&
      assignmentData.unmarked
    );
  }

  return (
    <Fragment>
      {/* {showLoading && <Loader></Loader>} */}

      <>
        <div className="md:flex md:items-center px-4 py-3 lg:px-8 border-b">
          <Breadcrumb items={breadcrumbItems()} />
          <div className="md:w-[55%] text-lg flex justify-between gap-2 h-auto sm:h-10 items-center">
            <h1 className="text-gray-50 font-medium flex justify-start">
              {t("Breadcrumb.GradedWork")}
            </h1>
          </div>
        </div>
        <div className="px-4 py-4 lg:px-8">
          <div className="flex flex-col mb-4">
            <div className="w-full bg-white rounded-md shadow">
              <div className="grid grid-cols-12 space-y-6 xs:space-y-0 xs:gap-6 mb-5 px-6 py-4">
                <div
                  className={`col-span-12 md:col-span-4 xl:col-span-3 2xl:col-span-2`}
                >
                  <div className="text-gray-500 font-medium whitespace-nowrap">
                    {t("Assignments.Teacher/Class")}
                  </div>
                  <StudentClasses
                    className="mt-1"
                    userId={props.userContext.userId}
                    isMulti={false}
                    includeFormerClass={true}
                    selectedItems={selectedClass}
                    onChange={(e) => teacherClassOnChange(e)}
                  />
                </div>

                <div
                  className={`col-span-12 md:col-span-4 xl:col-span-3 2xl:col-span-2`}
                >
                  <div className="text-gray-500 font-medium">
                    {" "}
                    {t("Assignments.Subject")}
                  </div>
                  <Select
                    className="mt-1"
                    value={selectedSubject}
                    options={subjects}
                    onChange={(e) => handleSubjectChange(e)}
                  />
                </div>

                <div
                  className={`col-span-12 md:col-span-4 xl:col-span-3 2xl:col-span-2`}
                >
                  <div>
                    <div className="text-gray-500 font-medium">
                      {t("Assignments.StartDate")}
                    </div>
                    <DatePicker
                      className="mt-1 react-datepicker__input-container py-1.5 px-3 block w-full shadow-sm focus:ring-primary-violet focus:border-primary-violet border border-gray-300 rounded-md "
                      onChange={(date) => handleStartDate(date)}
                      selected={startDate}
                    />
                  </div>
                </div>
                <div
                  className={`col-span-12 md:col-span-4 xl:col-span-3 2xl:col-span-2`}
                >
                  <div className="relative">
                    <div className="text-gray-500 font-medium">
                      {t("Assignments.EndDate")}
                    </div>
                    <DatePicker
                      className="mt-1 react-datepicker__input-container py-1.5 px-3 block w-full shadow-sm focus:ring-primary-violet focus:border-primary-violet border border-gray-300 rounded-md "
                      onChange={(date) => handleDueDate(date)}
                      selected={endDate}
                    />
                  </div>
                </div>
                <div className="col-span-12 md:col-span-4 xl:col-span-12 xl:flex xl:justify-end 2xl:col-span-4 2xl:justify-start flex-col items-end justify-end xl:justify-start  xs:flex-row flex md:items-end space-x-3">
                  <button
                    onClick={() => handleSearch()}
                    className="bg-blues px-4 h-10 hover:opacity-90 inline-block whitespace-nowrap rounded-md text-sm w-36 text-center py-2 text-white flex items-center justify-center"
                  >
                    {t("GradedWork.Search")}
                    <RightArrowIcon className="h-6 w-6 ml-1 inline-block" />
                  </button>
                  <button
                    onClick={() => handleClear()}
                    className="bg-white px-4 border-2 hover:opacity-80 border-gray-500 text-gray-700 h-10 hover:opacity-90 inline-block whitespace-nowrap rounded-md text-sm w-36 text-center py-2 text-white"
                  >
                    {t("GradedWork.Clear")}
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="flex justify-between items-start flex-col 1xl:flex-row 1xl:items-end">
            <div className="flex flex-col">
              <div className="flex flex-col sm:flex-row items-start sm:items-end gap-1">
                <div>
                  <span
                    className={`cursor-pointer rounded-t-md p-3 text-gray-800 font-medium text-sm cursor-pointer inline-block group ${
                      assignmentType === 0
                        ? "bg-white border-t-[3px] border-secondary-teal tab-box-shadow "
                        : "bg-white bg-opacity-40 border-t-[3px] border-transparent mt-auto py-2 px-3 hover:py-3 transition-all duration-300 ease-in-out "
                    }`}
                    onClick={() => {
                      setAssignmentType(0);
                      handleViewFilter(0);
                    }}
                  >
                    <span
                      className={` ${
                        assignmentType === 0
                          ? "text-gray-700 "
                          : "text-gray-700 text-opacity-60 group-hover:text-opacity-80 "
                      }`}
                    >
                      {t("GradedWork.ViewAllResults")}
                    </span>
                  </span>
                </div>

                <div>
                  <span
                    className={`cursor-pointer rounded-t-md p-3 text-gray-800 font-medium text-sm cursor-pointer inline-block group ${
                      assignmentType === 1
                        ? "bg-white border-t-[3px] border-secondary-teal tab-box-shadow "
                        : "bg-white bg-opacity-40 border-t-[3px] border-transparent mt-auto py-2 px-3 hover:py-3 transition-all duration-300 ease-in-out "
                    }`}
                    onClick={() => {
                      setAssignmentType(1);
                      handleViewFilter(1);
                    }}
                  >
                    <span
                      className={` ${
                        assignmentType === 1
                          ? "text-gray-700 "
                          : "text-gray-700 text-opacity-60 group-hover:text-opacity-80 "
                      }`}
                    >
                      {t("GradedWork.ViewAssignmentResults")}
                    </span>
                  </span>
                  {infoText &&
                    Object.keys(infoText).length > 0 &&
                    infoText.index === 1 &&
                    renderInfo(1)}
                </div>

                <div>
                  <span
                    className={`cursor-pointer rounded-t-md p-3 text-gray-800 font-medium text-sm cursor-pointer inline-block group ${
                      assignmentType === 2
                        ? "bg-white border-t-[3px] border-secondary-teal tab-box-shadow "
                        : "bg-white bg-opacity-40 border-t-[3px] border-transparent mt-auto py-2 px-3 hover:py-3 transition-all duration-300 ease-in-out "
                    }`}
                    onClick={() => {
                      setAssignmentType(2);
                      handleViewFilter(2);
                    }}
                  >
                    <span
                      className={` ${
                        assignmentType === 2
                          ? "text-gray-700 "
                          : "text-gray-700 text-opacity-60 group-hover:text-opacity-80 "
                      }`}
                    >
                      {t("GradedWork.ViewIndependentPractice")}
                    </span>
                  </span>
                  {infoText &&
                    Object.keys(infoText).length > 0 &&
                    infoText.index === 2 &&
                    renderInfo(2)}
                </div>
              </div>
            </div>
          </div>

          {/* Deskop table */}
          <div className="relative">
            <div>
              <div className="relative overflow-auto">
                <table
                  id="gradedwork-table"
                  className="min-w-full divide-y divide-gray-200"
                >
                  <thead className="bg-gray-100 uppercase" id="top">
                    <tr className="divide-x divide-gray-200">
                      <th
                        scope="col"
                        className="top-0 bg-gray-100 px-6 2xl:px-6 py-3 text-left text-sm font-semibold text-dark-teal uppercase tracking-wider"
                      >
                        {t("Assignments.Name")}
                      </th>
                      <th
                        scope="col"
                        className="top-0 bg-gray-100 px-2 2xl:px-6 py-3 text-center text-sm font-semibold text-dark-teal tracking-wider"
                      >
                        {t("Assignments.Completed")}
                      </th>
                      <th
                        scope="col"
                        className="top-0 bg-gray-100 px-6 2xl:px-6 py-3 text-left text-sm font-semibold text-dark-teal uppercase tracking-wider"
                      >
                        {t("Assignments.Teacher/Class")}
                      </th>
                      <th
                        scope="col"
                        className="top-0 bg-gray-100 px-2 2xl:px-6 py-3 text-center text-sm font-semibold text-dark-teal tracking-wider"
                      >
                        {t("AssignmentResult.Score")}
                      </th>
                      <th
                        scope="col"
                        className="top-0 bg-gray-100 px-2 2xl:px-6"
                      >
                        <span className="sr-only">
                          {t("Assignments.ActionButton")}
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white">
                    {gradedWorkData &&
                      gradedWorkData?.data?.map((data, index) => {
                        return (
                          <>
                            <tr key={index} className={`border-b-2 `}>
                              <td className="px-6 py-4 border-r-2 border-gray-100">
                                <div className="text-sm font-medium text-site-black w-72 xxl:w-80 font-semibold">
                                  {data.name}
                                </div>
                              </td>

                              <td className="px-6 py-4 border-r-2 border-gray-100">
                                <div className="text-sm text-center font-medium text-site-black">
                                  {data.endDate
                                    ? moment(data.endDate).format("MM/DD/YYYY")
                                    : ""}
                                </div>
                                <div className="text-xs text-indigo-700 font-semibold capitalize">
                                  {data &&
                                    data.result &&
                                    moment()
                                      .startOf("day")
                                      .add(data.result.elapsed_time, "seconds")
                                      .format("HH:mm:ss")}
                                </div>
                              </td>

                              <td className="px-6 py-4 text-sm text-gray-500 border-r-2 border-gray-100">
                                {data &&
                                  data.activityType !== "STUDYPLAN" &&
                                  data.activityType !== "DOMAINTEST" &&
                                  data.activityType !== "SUMMATIVETEST" &&
                                  data.activityType !== "CANNEDTEST" &&
                                  data.activityType !== "VIDEOLEARN" &&
                                  data.activityType !== "DIAGNOSTIC" && (
                                    <>
                                      <div className="text-sm text-black-333 capitalize font-semibold w-36 2xl:w-52 xxl:w-96">
                                        {data.teacherName}{" "}
                                      </div>

                                      {data && (
                                        <div className="text-sm text-gray-800 capitalize font-medium w-36 2xl:w-52 xxl:w-96">
                                          {data.className}
                                        </div>
                                      )}
                                    </>
                                  )}
                              </td>

                              <td className="px-6 py-4 text-center text-sm font-medium border-r-2 border-gray-100 relative">
                                <div className="grid text-sm font-medium text-center text-site-black capitalize">
                                  {(data.retryScore === null && (
                                    <>
                                      {!data.isGroupedAssignment &&
                                      data.score !== null ? (
                                        <>
                                          <div>
                                            {isPendingScore(data) && (
                                                <>{t("Assignments.Pending")}</>)}
                                          </div>

                                          {!isPendingScore(data) && (
                                            <>
                                              {data.score !== null && (
                                                  <div>
                                                    {getScoreRounded(data.score)}%
                                                  </div>
                                              )}
                                              <div>
                                                ({getScoreLabel(data.correct)}/
                                                {data.questionCount})
                                              </div>
                                            </>
                                          )}
                                        </>
                                      ) : (
                                        <span className="text-sm text-gray-500"></span>
                                      )}
                                    </>
                                  )) ||
                                    (!isPendingScore(data) && (
                                      <>
                                        {data.score !== null && (
                                          <div>
                                            {getScoreRounded(data.score)}%
                                          </div>
                                        )}
                                        <div>
                                          ({getScoreLabel(data.correct)}/
                                          {data.questionCount})
                                        </div>

                                        {data.retryScore && (
                                          <div className="italic text-gray-400">
                                            <span className="text-xs">
                                              Retry{" "}
                                            </span>
                                            {getScoreRounded(data.retryScore)}%
                                            <div>
                                              (
                                              {getScoreLabel(data.retryCorrect)}
                                              /{data.questionCount})
                                            </div>
                                          </div>
                                        )}
                                      </>
                                    ))}
                                  {data.minimumScore > 0 && data.score < data.minimumScore && (
                                      <div className="text-[#d90000]">
                                        Minimum Score Required = {data.minimumScore}%
                                      </div>
                                  )}
                                </div>
                              </td>

                              <td className="px-6 py-4">
                                {!data.isGroupedAssignment && (
                                  <div className="flex justify-center">
                                    <button
                                      onClick={() => handleViewClick(data)}
                                      className={`${
                                        (
                                          data.activityType !== "STUDYPLAN" &&
                                          isPastDate(data.endDate, data.dueDate)
                                            ? 1
                                            : 0
                                        )
                                          ? "bg-site-pink"
                                          : "bg-blues"
                                      } hover:bg-secondary-teal transition-all hover:text-gray-100 inline-block whitespace-nowrap rounded-md text-sm w-full px-4 w-full text-center py-2 text-white `}
                                    >
                                      {t("Assignments.View")}
                                      <RightArrowIcon className="h-6 w-6 ml-1 inline-block" />
                                    </button>
                                  </div>
                                )}
                              </td>
                            </tr>
                          </>
                        );
                      })}
                    {gradedWorkData && gradedWorkData?.totalRecords === 0 && (
                      <tr className="bg-white">
                        <td colSpan={7} className="text-sm px-6 py-4">
                          {t("Assignments.NoRecordsFound")}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between text-white">
                {gradedWorkData?.totalRecords !== undefined &&
                  gradedWorkData?.totalRecords !== 0 && (
                    <Pagination
                      totalRecords={gradedWorkData?.totalRecords}
                      pageSize={pageSize}
                      onPageChange={onPageChange}
                      reset={resetPageNumber}
                    />
                  )}
                {gradedWorkData?.totalRecords !== 0 && (
                  <PageSize onPageSizeChange={onPageSizeChange} />
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    </Fragment>
  );
}
const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(GradedWork);

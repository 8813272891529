import { connect } from "react-redux";
import { Eye, HelpVideo } from "../../../../assets/icons";

import Breadcrumb from "../../teacher/shared/breadcrumb";
import { IBreadcrumb } from "../../../../model/common/breadcrumb";
import RouteConstant from "../../../../utils/constant/routeConstant";
import { useTranslation } from "react-i18next";
import routeConstant from "../../../../utils/constant/routeConstant";
import { Link, useHistory } from "react-router-dom";
import { Configuration } from "../../../../environment/setup";
import { useEffect, useState } from "react";
import { ISelectElement } from "../../../../model/interface/selectElement";
import Select from "react-select";
import constant from "../../../../utils/constant/constant";
import AllowedTo from "../../../shared/rbac";
import permissions from "../../../../utils/permissions";
import { ClassApi } from "../../../../api/teacher/classManagementApi";
import ClassSearchDTO from "../../../../model/teacher/classManagement/classSearchModel";
import { DEFAULT_PAGE_NUMBER } from "../../../../utils/pagingConstant";
import Loader from "../../../shared/loader";
import {
  hasDiagnosticAccess,
  hasLiftOffAccess,
} from "../../../../utils/helper";
//import { default as Constant } from "../../../../utils/constant/constant";
import ReportElement from "./reportElement";
import userApi from "../../../../api/userApi";
import studentReportCardIcon from "../../../../assets/images/reports/studentReportCardIcon.png";
import teacherUsageReportIcon from "../../../../assets/images/reports/teacherUsageReport.png";

function Reports(props) {
  const history = useHistory();
  const { t } = useTranslation();
  const [selectedReportType, setSelectedReportType] = useState<ISelectElement>({
    label: "All Reports",
    value: "0",
  });
  const [reportTypes, setReportTypes] = useState<Array<ISelectElement>>([]);
  const [userClasses, setClasses] = useState<Array<any>>([]);
  var reportTypesData: Array<ISelectElement> = new Array<ISelectElement>();
  const [showLoader, setShowLoader] = useState<boolean>(true);
  const [hasLiftOffSubjectsAccess, setHasLiftOffSubjectsAccess] =
    useState<boolean>();
  const [hasDiagnosticSubjects, setHasDiagnosticSubjects] = useState<boolean>();
  const [isAccountStateTexas, setIsAccountStateTexas] = useState<boolean>(false);
  const texasStateName = "Texas";

  const checkUserAccountState = () => {
    userApi.GetUserDetail(props.userContext.userId).then((response) => {
      setIsAccountStateTexas(response.data.state === texasStateName);
    })
  }

  function bindReportTypes() {
    reportTypesData.push({ label: "All Reports", value: "0" });
    reportTypesData.push({
      label: "Progress Monitoring",
      value: constant.ReportType.ProgressMonitoring,
    });

    reportTypesData.push({
      label: "Activity Results Reports",
      value: constant.ReportType.ActivityResults,
    });

    if (hasLiftOffSubjectsAccess) {
      reportTypesData.push({
        label: "Liftoff Reports",
        value: constant.ReportType.LiftoffReports,
      });
    }
    reportTypesData.push({
      label: "School Reports",
      value: constant.ReportType.SchoolReports,
    });
    setReportTypes(reportTypesData);
  }
  function handleReportTypeChange(selectdItem: any) {
    setSelectedReportType(selectdItem);
  }
  useEffect(() => {
    if (constant.RolesRequireUpgrade.includes(props.userContext.roleId)) {
      //to show the reports as disabled
      setHasLiftOffSubjectsAccess(true);
    } else {
      hasLiftOffAccess(props.userContext).then((res) => {
        setHasLiftOffSubjectsAccess(res);
      });
    }
    hasDiagnosticAccess(props.userContext).then((res) => {
      setHasDiagnosticSubjects(res);
    });
    getUserClasses();
    checkUserAccountState();
  }, []);

  useEffect(() => {
    bindReportTypes();
  }, [hasLiftOffSubjectsAccess]);

  const breadcrumbItems = () => {
    const items: Array<IBreadcrumb> = [
      { name: t("Breadcrumb.Home"), url: RouteConstant.ROUTE_DASHBOARD },
      {
        name: t("Breadcrumb.Reports"),
        url: "",
      },
    ];

    return items;
  };

  const getUserClasses = async () => {
    setShowLoader(true);
    const classSearch: ClassSearchDTO = {
      pageNumber: DEFAULT_PAGE_NUMBER,
      pageSize: DEFAULT_PAGE_NUMBER,
      showArchive: false,
      showCurrent: true,
      showHidden: false,
      userId: props.userContext.userId,
      schoolId: props.userContext.schoolId,
      schoolAccountId: props.userContext.schoolAccountId,
    };
    const response = ClassApi.getClassList(classSearch);
    response?.then((d) => {
      setClasses(d.data.data);
    });
    setShowLoader(false);
  };

  return (
    <div className="min-w-0 w-full">
      {showLoader && <Loader></Loader>}
      <div className="lg:flex lg:items-center px-4 py-3 lg:px-8 border-b">
        <Breadcrumb items={breadcrumbItems()} />
        <div className="lg:w-[55%] text-lg font-medium flex gap-2 h-auto sm:h-10 items-center">
          <h1 className="text-primary-violet font-medium flex justify-start mb-0 pb-0">
            Reports
          </h1>
        </div>
      </div>
      <div className="px-2 sm:px-6  lg:px-8 py-1 relative my-4">
        <div className="grid grid-cols-12 mb-6">
          <div className="col-span-12">
            <Select
              className="mt-1 block w-full max-w-xs py-2 text-base border-gray-300 focus:outline-none focus:ring-primary-violet focus:border-primary-violet sm:text-sm rounded-md"
              value={selectedReportType}
              options={reportTypes}
              onChange={handleReportTypeChange}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary: "#008fbb",
                },
              })}
            />
          </div>
        </div>

        <div className="bg-gray-100 w-full rounded-lg p-5">
          {(selectedReportType?.value ===
            constant.ReportType.ProgressMonitoring ||
            selectedReportType?.value === constant.ReportType.AllReports) && (
            <div>
              <h2 className="text-white bg-primary-violet p-3 font-semibold uppercase mb-4">
                Progress Monitoring Reports
              </h2>
              <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-4 xxxl:grid-cols-5">
                <AllowedTo perform={permissions.reports_progressreport_view}>
                  <li className="col-span-1 flex flex-col text-center bg-white rounded-lg shadow divide-y divide-gray-200 cursor-pointer hover:bg-gray-50">
                    <div
                      className="flex-1 flex flex-col p-8"
                      onClick={() =>
                        history.push({
                          pathname: RouteConstant.PROGRESS_REPORT,
                        })
                      }
                    >
                      <h3 className="h-auto md:h-28 lg:h-24 xl:h-16 text-gray-900 text-left text-sm font-medium">
                        Progress Report
                      </h3>
                      <img
                        className="w-full h-32 flex-shrink-0 mx-auto"
                        src={`${Configuration.S3bucketImagePath}/images/reportscreen.png`}
                        alt="progress report"
                        title="Progress Report"
                      />
                      <dl className="mt-4 flex-grow flex flex-col justify-between">
                        <dd className="text-gray-500 text-left text-sm">
                          View the cumulative current scores for all student
                          activities by subject down to state standard and track
                          Dot Ranking.
                        </dd>
                      </dl>
                    </div>
                    <div>
                      <div className="-mt-px flex divide-x divide-gray-200">
                        <div className=" hidden w-0 flex-1 flex">
                          <Link
                            to={RouteConstant.TeacherRoutes.ProgressReport}
                            className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500"
                          >
                            <HelpVideo
                              title="Help Video"
                              className="w-5 h-5 opacity-80 hover:opacity-100"
                            ></HelpVideo>
                            <span className="ml-3">Help Video</span>
                          </Link>
                        </div>
                        <div className="-ml-px w-0 flex-1 flex">
                          <Link
                            to={RouteConstant.TeacherRoutes.ProgressReportHome}
                            className="relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500"
                          >
                            <Eye
                              title="View Report"
                              className="w-5 h-5 text-gray-500 hover:text-gray-700"
                            ></Eye>
                            <span className="ml-3">View Report</span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </li>
                </AllowedTo>
                <AllowedTo perform={permissions.reports_progressreport_view}>
                  <ReportElement
                      title="Student Report Card"
                      imageUrl={studentReportCardIcon}
                      path={routeConstant.TeacherRoutes.StudentReportCard}
                      description="View student scores over time across all subjects and monitor progress."
                      userContext={props.userContext}
                  ></ReportElement>
                </AllowedTo>
                {userClasses && userClasses.length > 0 && (
                  <AllowedTo perform={permissions.reports_gradebook_view}>
                    {props.userContext.roleId !==
                      constant.UserRoleId.District && (
                      <li className="col-span-1 flex flex-col text-center bg-white rounded-lg shadow divide-y divide-gray-200 cursor-pointer hover:bg-gray-50">
                        <div
                          className="flex-1 flex flex-col p-8"
                          onClick={() =>
                            history.push({
                              pathname: RouteConstant.TeacherRoutes.GradeBook,
                            })
                          }
                        >
                          <h3 className="h-auto md:h-28 lg:h-24 xl:h-16 text-gray-900 text-left text-sm font-medium">
                            Gradebook
                          </h3>
                          <img
                            className="w-full h-32 flex-shrink-0 mx-auto"
                            src={`${Configuration.S3bucketImagePath}/images/reports/gradebook.png`}
                            alt="gradebook"
                            title="Gradebook"
                          />
                          <dl className="mt-4 flex-grow flex flex-col justify-between">
                            <dd className="text-gray-500 text-left text-sm">
                              Track results for assignments and student
                              independent practice activities for your classes.
                            </dd>
                          </dl>
                        </div>
                        <div>
                          <div className="-mt-px flex divide-x divide-gray-200">
                            <div className="hidden w-0 flex-1 flex">
                              <Link
                                to={RouteConstant.TeacherRoutes.GradeBook}
                                className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500"
                              >
                                <HelpVideo
                                  title="Help Video"
                                  className="w-5 h-5 opacity-80 hover:opacity-100"
                                ></HelpVideo>
                                <span className="ml-3">Help Video</span>
                              </Link>
                            </div>
                            <div className="-ml-px w-0 flex-1 flex">
                              <Link
                                to={routeConstant.TeacherRoutes.GradeBook}
                                className="relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500"
                              >
                                <Eye
                                  title="View Report"
                                  className="w-5 h-5 text-gray-500 hover:text-gray-700"
                                ></Eye>
                                <span className="ml-3">View Report</span>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </li>
                    )}
                  </AllowedTo>
                )}
                <AllowedTo
                  perform={permissions.reports_galaxystar_view}
                  upgradePermission={permissions.reports_galaxystar_upgrade}
                >
                  <ReportElement
                    title="Galaxy Star"
                    imageUrl={`${Configuration.S3bucketImagePath}/images/reports/galaxystar.png`}
                    path={RouteConstant.GALAXY_START_REPORT}
                    description="View the performance of students by tracking the number of Galaxy Stars earned out of the total stars per subject and current Alien Rankings across all subjects. You can also print certificates to reward students as they move up in Alien Rank."
                    userContext={props.userContext}
                    upgradePermission={permissions.reports_galaxystar_upgrade}
                  ></ReportElement>
                </AllowedTo>
                <AllowedTo
                  perform={permissions.reports_sessionactivity_view}
                  upgradePermission={
                    permissions.reports_sessionactivity_upgrade
                  }
                >
                  <ReportElement
                    title="Session Activity Report"
                    imageUrl={`${Configuration.S3bucketImagePath}/images/reports/SessionActivityReport.png`}
                    path={RouteConstant.SESSION_ACTIVITY_REPORT}
                    description="Tracks overall and per topic session activity and
                        scores per student."
                    userContext={props.userContext}
                    upgradePermission={
                      permissions.reports_sessionactivity_upgrade
                    }
                  ></ReportElement>
                </AllowedTo>
              </ul>
            </div>
          )}

          {(selectedReportType?.value === constant.ReportType.ActivityResults ||
            selectedReportType?.value === constant.ReportType.AllReports) && (
            <div>
              <h2 className="text-white bg-primary-violet p-3 font-semibold uppercase mb-4 mt-7">
                Activity Results Reports
              </h2>
              <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-4 xxxl:grid-cols-5">
                <AllowedTo
                  perform={permissions.reports_assessment_results_view}
                >
                  <li className="col-span-1 flex flex-col text-center bg-white rounded-lg shadow divide-y divide-gray-200 cursor-pointer hover:bg-gray-50">
                    <div
                      className="flex-1 flex flex-col p-8"
                      onClick={() =>
                        history.push({
                          pathname:
                            routeConstant.TeacherRoutes.AssessmentResultsReport,
                        })
                      }
                    >
                      <h3 className="h-auto md:h-28 lg:h-24 xl:h-16 text-gray-900 text-left text-sm font-medium">
                        Assessment Results Report
                      </h3>
                      <img
                        title="Session activity report"
                        className="w-full h-32 flex-shrink-0 mx-auto"
                        src={`${Configuration.S3bucketImagePath}/images/reports/AssessmentResultsReport.png`}
                        alt="session activity report"
                      />

                      <dl className="mt-4 flex-grow flex flex-col justify-between">
                        <dd className="text-gray-500 text-left text-sm">
                          Select one or more assessments to export into a
                          spreadsheet view that contains student results down to
                          the standard for each assessment.
                        </dd>
                      </dl>
                    </div>
                    <div>
                      <div className="-mt-px flex divide-x divide-gray-200">
                        <div className="hidden w-0 flex-1 flex">
                          <Link
                            to={
                              routeConstant.TeacherRoutes
                                .AssessmentResultsReport
                            }
                            className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500"
                          >
                            <HelpVideo
                              title="Help video"
                              className="w-5 h-5 opacity-80 hover:opacity-100"
                            ></HelpVideo>
                            <span className="ml-3">Help Video</span>
                          </Link>
                        </div>
                        <div className="-ml-px w-0 flex-1 flex">
                          <Link
                            to={
                              routeConstant.TeacherRoutes
                                .AssessmentResultsReport
                            }
                            className="relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500"
                          >
                            <Eye
                              title="View report"
                              className="w-5 h-5 text-gray-500 hover:text-gray-700"
                            ></Eye>
                            <span className="ml-3">View Report</span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </li>
                </AllowedTo>
                <AllowedTo
                  perform={permissions.reports_testcomparisonreport_view}
                  upgradePermission={
                    permissions.reports_testcomparisonreport_upgrade
                  }
                >
                  <ReportElement
                    title="Assessment Comparison Report"
                    imageUrl={`${Configuration.S3bucketImagePath}/images/reports/test-comparison-report.png`}
                    path={
                      routeConstant.TeacherRoutes.AssessmentComparisonReport
                    }
                    description="Compare student results across 2 assessments. This report also includes Progress Learning Premium Assessments."
                    userContext={props.userContext}
                    upgradePermission={
                      permissions.reports_testcomparisonreport_upgrade
                    }
                  ></ReportElement>
                </AllowedTo>
                {hasDiagnosticSubjects && isAccountStateTexas && (
                  <AllowedTo
                    perform={
                      permissions.reports_diagnosticsstrengthweaknessreport_view
                    }
                    upgradePermission={
                      permissions.reports_diagnosticsstrengthweaknessreport_upgrade
                    }
                  >
                    <ReportElement
                      title="Student Diagnostics Strengths and Weaknesses"
                      imageUrl={`${Configuration.S3bucketImagePath}/images/reports/StudentDiagnosticsStrengthsandWeaknesses.png`}
                      path={
                        routeConstant.STUDENT_DIAGNOSTICS_STRENGTH_WEAKNESS_REPORT
                      }
                      description="View overall scores, ranking, areas of strength, and
                                areas where additional study is needed by an
                                individual student based on completing the Progress
                                Learning Diagnostic."
                      userContext={props.userContext}
                      upgradePermission={
                        permissions.reports_diagnosticsstrengthweaknessreport_upgrade
                      }
                    ></ReportElement>
                  </AllowedTo>
                )}
                <AllowedTo perform={permissions.reports_resultsreport_view}>
                  <ReportElement
                    title='Results Report'
                    imageUrl={`${Configuration.S3bucketImagePath}/images/reports/grouped-assessment-report.png`}
                    path={routeConstant.TeacherRoutes.ResultsReport}
                    description='View the results for assessments and assignments including aggregated views, student views and item details.'
                    userContext={props.userContext}
                  ></ReportElement>
                </AllowedTo>           
              </ul>
            </div>
          )}

          {(hasLiftOffSubjectsAccess && 
            (selectedReportType?.value === constant.ReportType.LiftoffReports ||
            selectedReportType?.value === constant.ReportType.AllReports)) && (
            <div>
              <h2 className="text-white bg-primary-violet p-3 font-semibold uppercase mb-4 mt-7">
                Liftoff reports
              </h2>
              <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-4 xxxl:grid-cols-5">
                <AllowedTo
                  perform={permissions.reports_liftoff_diagnostic_view}
                  upgradePermission={
                    permissions.reports_liftoff_diagnostic_upgrade
                  }
                >
                  <ReportElement
                    title="Liftoff Diagnostic Report"
                    imageUrl={`${Configuration.S3bucketImagePath}/images/liftoff-diagnostic-report.png`}
                    path={routeConstant.TeacherRoutes.LiftoffDiagnosticReport}
                    description="Track the performance of each student in Liftoff diagnostic for the selected subject and domain."
                    userContext={props.userContext}
                    upgradePermission={
                      permissions.reports_liftoff_diagnostic_upgrade
                    }
                  ></ReportElement>
                </AllowedTo>
                <AllowedTo
                  perform={
                    permissions.reports_liftoff_individual_diagnostic_view
                  }
                  upgradePermission={
                    permissions.reports_liftoff_individual_diagnostic_upgrade
                  }
                >
                  <ReportElement
                    title="Liftoff My Galaxies Report"
                    imageUrl={`${Configuration.S3bucketImagePath}/images/liftoff-individual-report.png`}
                    path={
                      routeConstant.TeacherRoutes
                        .LiftoffIndividualDiagnosticReport
                    }
                    description="Track current student performance by level for a selected Liftoff subject."
                    userContext={props.userContext}
                    upgradePermission={
                      permissions.reports_liftoff_individual_diagnostic_upgrade
                    }
                  ></ReportElement>
                </AllowedTo>
                <AllowedTo
                  perform={permissions.reports_liftoff_detail_growth_view}
                  upgradePermission={
                    permissions.reports_liftoff_detail_growth_upgrade
                  }
                >
                  <ReportElement
                    title="Liftoff Detail Growth Report"
                    imageUrl={`${Configuration.S3bucketImagePath}/images/reports/LiftoffDetailGrowthReport.png`}
                    path={routeConstant.TeacherRoutes.LiftoffDetailGrowthReport}
                    description="Track student performance for each domain of a subject, including the starting level based on the diagnostic/NWEA result to the current level achieved."
                    userContext={props.userContext}
                    upgradePermission={
                      permissions.reports_liftoff_detail_growth_upgrade
                    }
                  ></ReportElement>
                </AllowedTo>
                <AllowedTo
                  perform={
                    permissions.reports_liftoff_class_galaxy_summary_view
                  }
                  upgradePermission={
                    permissions.reports_liftoff_class_galaxy_summary_upgrade
                  }
                >
                  <ReportElement
                    title="Liftoff Class Galaxy Summary Report"
                    imageUrl={`${Configuration.S3bucketImagePath}/images/reports/LiftoffClassGalaxySummaryReport.png`}
                    path={
                      routeConstant.TeacherRoutes
                        .LiftoffClassGalaxySummaryReport
                    }
                    description="Select a class to view student Galaxy Level by domain."
                    userContext={props.userContext}
                    upgradePermission={
                      permissions.reports_liftoff_class_galaxy_summary_upgrade
                    }
                  ></ReportElement>
                </AllowedTo>
                <AllowedTo
                  perform={permissions.reports_liftoff_galaxystar_view}
                  upgradePermission={
                    permissions.reports_liftoff_galaxystar_upgrade
                  }
                >
                  <ReportElement
                    title=" Liftoff Galaxy Star Report"
                    imageUrl={`${Configuration.S3bucketImagePath}/images/reports/galaxystar.png`}
                    path={routeConstant.LIFTOFF_GALAXY_START_REPORT}
                    description="View the performance of students using Liftoff by tracking the number of Galaxy Stars earned out of the total stars per subject and current Alien Rankings across all subjects. You can also print certificates to reward students as they move up in Alien Rank."
                    userContext={props.userContext}
                    upgradePermission={
                      permissions.reports_liftoff_galaxystar_upgrade
                    }
                  ></ReportElement>
                </AllowedTo>
                <AllowedTo
                  perform={permissions.reports_liftoff_usage_view}
                  upgradePermission={permissions.reports_liftoff_usage_upgrade}
                >
                  <ReportElement
                    title="Liftoff Usage Report"
                    imageUrl={`${Configuration.S3bucketImagePath}/images/reports/liftoff-usage-report.png`}
                    path={routeConstant.TeacherRoutes.LiftoffUsageReport}
                    description="Track the time spent practicing in Liftoff by grade and class for your school down to a single student."
                    userContext={props.userContext}
                    upgradePermission={
                      permissions.reports_liftoff_usage_upgrade
                    }
                  ></ReportElement>
                </AllowedTo>
                <AllowedTo
                  perform={permissions.reports_liftoff_activity_view}
                  upgradePermission={
                    permissions.reports_liftoff_activity_upgrade
                  }
                >
                  <ReportElement
                    title="Liftoff Activity Report"
                    imageUrl={`${Configuration.S3bucketImagePath}/images/reports/liftoffActivityReport.png`}
                    path={routeConstant.TeacherRoutes.LiftoffActivityReport}
                    description="View the individual activities completed by students within Liftoff, including scores and details."
                    userContext={props.userContext}
                    upgradePermission={
                      permissions.reports_liftoff_activity_upgrade
                    }
                  ></ReportElement>
                </AllowedTo>
              </ul>
            </div>
          )}
          {(selectedReportType?.value === constant.ReportType.SchoolReports ||
            selectedReportType?.value === constant.ReportType.AllReports) && (
            <div>
              <h2 className="text-white bg-primary-violet p-3 font-semibold uppercase mb-4 mt-7">
                School Reports
              </h2>
              <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-4 xxxl:grid-cols-5">
                <AllowedTo
                  perform={permissions.reports_usagereport_view}
                  upgradePermission={permissions.reports_usagereport_upgrade}
                >
                  <ReportElement
                    title="Usage Report by Subject"
                    imageUrl={`${Configuration.S3bucketImagePath}/images/reports/usage-report.png`}
                    path={routeConstant.USAGE_REPORT}
                    description="View the usage totals for each subject within your account. Data can be viewed by total number of activities, total time, or total questions answered."
                    userContext={props.userContext}
                    upgradePermission={permissions.reports_usagereport_upgrade}
                  ></ReportElement>
                </AllowedTo>
                {(props.userContext.roleId === constant.UserRoleId.District ||
                  props.userContext.roleId ===
                    constant.UserRoleId.SchoolAdmin ||
                  props.userContext.roleId ===
                    constant.UserRoleId.FreeTeacher) && (
                  <AllowedTo
                    perform={permissions.reports_activity_search_view}
                    upgradePermission={
                      permissions.reports_activity_search_upgrade
                    }
                  >
                    <ReportElement
                      title="Student Activity Search Report"
                      imageUrl={`${Configuration.S3bucketImagePath}/images/reports/ActivitySearchReport.png`}
                      path={
                        routeConstant.TeacherRoutes.StudentActivitySearchReport
                      }
                      description="Drill down to student level data and create the
                                    report you want to see. The report includes the
                                    ability to filter by any combination of Subject,
                                    Class, Teacher, Student or Activity Type with the
                                    added ability to view student-specific details."
                      userContext={props.userContext}
                      upgradePermission={
                        permissions.reports_activity_search_upgrade
                      }
                    ></ReportElement>
                  </AllowedTo>
                )}
                {(props.userContext.roleId === constant.UserRoleId.District ||
                  props.userContext.roleId === constant.UserRoleId.SchoolAdmin) && (
                  <AllowedTo
                    perform={permissions.reports_activity_search_view}
                    upgradePermission={permissions.reports_activity_search_upgrade}
                  >
                    <ReportElement
                      title="Teacher Usage Report"
                      imageUrl={teacherUsageReportIcon}
                      path={routeConstant.TeacherRoutes.TeacherUsageReport}
                      description="View the teacher usage information within a school including logins and activity creation."
                      userContext={props.userContext}
                      upgradePermission={permissions.reports_activity_search_upgrade}
                    ></ReportElement>
                  </AllowedTo>
                )}
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default connect((state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
})(Reports);

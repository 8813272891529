import Moment from "moment";
import { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { getDOK, getDomainsBySubjectId } from "../../../../api/cmsApi";
import {
  getAllStandardByDomains,
  getAssociatedSubjects,
  getDistrictAdminSubjects,
} from "../../../../api/teacher/assignment";
import {
  deleteQuestion,
  duplicateQuestion,
  getAllQuestions,
} from "../../../../api/teacher/question";
import {
  CaretDownSolidIcon,
  CaretUpSolidIcon,
  DeleteIcon,
  DuplicateIcon,
  EditIcon,
} from "../../../../assets/icons";
import { ISelectElement } from "../../../../model/interface/selectElement";
import DomainStandard from "../../../../model/teacher/domainStandard";
import Question from "../../../../model/teacher/question";
import SearchQuestionRequest from "../../../../model/teacher/searchQuestionRequest";
import { UserContextState } from "../../../../redux/actions/userContextAction";
import RouteConstant from "../../../../utils/constant/routeConstant";
import {
  DEFAULT_PAGE_NUMBER,
  DEFAULT_PAGE_SIZE,
} from "../../../../utils/pagingConstant";
import Permissions from "../../../../utils/permissions";
import AccessDenied from "../../../shared/accessDenied";
import ConfirmationDialog from "../../../shared/confirmationDialog";
import Tooltip from "../../../shared/ellipsisText";
import Loader from "../../../shared/loader";
import PageSize from "../../../shared/pagination/pageSize";
import Pagination from "../../../shared/pagination/pagination";
import AllowedTo from "../../../shared/rbac";
import QuestionTypeSelect from "./../shared/questionTypeSelect";
import arraySort from "array-sort";
import Breadcrumb from "../../teacher/shared/breadcrumb";
import { IBreadcrumb } from "../../../../model/common/breadcrumb";
import QuestionPreview from "../shared/questionPreview/questionPreview";
import { useTranslation } from "react-i18next";
import constant from "../../../../utils/constant/constant";
import InfoMessage from "../../../shared/infoMessage";
import MessagePopup from "../../../shared/messagePopup";
import {
  getAuthoredQuestionCountUpgradePermission,
  getAuthoredQuestionsUpgradePermission,
} from "../../../shared/permissionHelper";
import UpGradeMessagePopup from "../../../shared/upGradeMessagePopup";
interface passedProps {
  userContext: UserContextState;
}

function AuthoredQuestions(props: passedProps) {
  type ActionButton = "Delete" | "Duplicate";
  const defaultValue = { label: "All", value: "0" };
  const [pageNumber, setPageNumber] = useState(DEFAULT_PAGE_NUMBER);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [orderByColumn, setOrderByColumn] = useState<string>("CreatedDate");
  const [sortByDesc, setSortByDesc] = useState<boolean>(true);
  const [resetPageNumber, setResetPageNumber] = useState<boolean>(false);
  const [selectedSubject, setSelectedSubject] =
    useState<ISelectElement>(defaultValue);
  const [selectedDomains, setSelectedDomains] = useState<Array<ISelectElement>>(
    []
  );
  const [subjects, setSubjects] = useState<Array<any>>([]);
  const [districtAdminSubjects, setDistrictAdminSubjects] = useState<
    Array<any>
  >([]);
  const [domains, setDomains] = useState<Array<any>>([]);
  const [selectedStandards, setSelectedStandards] = useState<
    Array<ISelectElement>
  >([]);
  const [standards, setStandards] = useState<Array<any>>([]);
  const [doks, setDoks] = useState<ISelectElement[]>([]);
  const [selectedDok, setSelectedDok] = useState<ISelectElement>(defaultValue);
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [showConfirmation, setshowConfirmation] = useState<boolean>(false);
  const [confirmationMessage, setConfirmationMessage] = useState<string>("");
  const [buttonAction, setButtonAction] = useState<ActionButton>();
  const [actionQuestionId, setActionQuestionId] = useState<number>(0);
  const [toggleSelectAll, setToggleSelectAll] = useState<boolean>(false);
  const [questions, setQuestions] = useState<Array<Question>>([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [openInformation, setOpenInformation] = useState(false);
  const [informationMessage, setInformationMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [selectedQuestionTypes, setSelectedQuestionTypes] = useState<
    Array<ISelectElement>
  >([]);
  const [selectedQuestionTypeIds, setSelectedQuestionTypeIds] = useState<
    Array<number>
  >([]);
  const [questionPreviewDetail, setQuestionPreviewDetail] = useState<{
    questionReference: string;
    questionId: number;
    questionText: string;
    currentIndex: number;
  }>();
  const [upgrade, setUpgrade] = useState(false);
  const [upgradeWithLimit, setUpgradeWithLimit] = useState(false);
  const [showUpgradeLimitPopup, setShowUpgradeLimitPopup] =
    useState<boolean>(false);
  const [showUpgradePopup, setShowUpgradePopup] = useState<boolean>(false);

  const history = useHistory();
  const { t } = useTranslation();
  const deleteMessage = `This question has already been used on an assigned assessment form this school year and cannot be deleted. 
  The question can be deleted once the question is no longer part of an active assessment`;

  useEffect(() => {
    checkUpgradeRequired();
  }, []);

  const checkUpgradeRequired = async () => {
    let isRestricted: boolean = false;
    setShowLoading(true);
    if (getAuthoredQuestionsUpgradePermission(props.userContext.roleId)) {
      setUpgrade(true);
      isRestricted = await getAuthoredQuestionCountUpgradePermission(
        props.userContext.roleId
      );
      setUpgradeWithLimit(isRestricted);
    } else {
      setUpgradeWithLimit(false);
    }
    setShowLoading(false);
    return isRestricted;
  };

  const fetchDistrictAdminSubjects = () => {
    setShowLoading(true);
    getDistrictAdminSubjects(props.userContext?.userId).then((response) => {
      setShowLoading(false);
      const subjectsELA: Array<ISelectElement> = response.data
        .filter((item) => item.contentAreaId === 1)[0]
        ?.subjects?.map((values: any) => {
          return {
            label: values?.name,
            value: values?.subjectId,
          };
        });
      const subjectsMath: Array<ISelectElement> = response.data
        .filter((item) => item.contentAreaId === 2)[0]
        ?.subjects?.map((values: any) => {
          return {
            label: values?.name,
            value: values?.subjectId,
          };
        });
      const subjectsScience: Array<ISelectElement> = response.data
        .filter((item) => item.contentAreaId === 3)[0]
        ?.subjects?.map((values: any) => {
          return {
            label: values?.name,
            value: values?.subjectId,
          };
        });
      const subjectsSocial: Array<ISelectElement> = response.data
        .filter((item) => item.contentAreaId === 4)[0]
        ?.subjects?.map((values: any) => {
          return {
            label: values?.name,
            value: values?.subjectId,
          };
        });
      const options = [
        {
          label: "ELA",
          options: arraySort(subjectsELA, "label"),
        },
        {
          label: "Math",
          options: arraySort(subjectsMath, "label"),
        },
        {
          label: "Science",
          options: arraySort(subjectsScience, "label"),
        },
        {
          label: "Social Studies",
          options: arraySort(subjectsSocial, "label"),
        },
      ];

      setDistrictAdminSubjects(options);
    });
  };
  function getSubjects() {
    setShowLoading(true);
    getAssociatedSubjects(props.userContext?.userId).then((response) => {
      setShowLoading(false);
      const subjects: Array<ISelectElement> = response.data
        .filter((r) => r.isAdditionalSubject === false)
        .map((values: any) => {
          return {
            label: values?.name,
            value: values?.subjectId,
          };
        });

      const additionalSubjects: Array<ISelectElement> = response.data
        .filter((r) => r.isAdditionalSubject === true)
        .map((values: any) => {
          return {
            label: values?.name,
            value: values?.subjectId,
          };
        });

      const options = [
        {
          label: "My Subjects",
          options: arraySort(subjects, "label"),
        },
        {
          label: "Additional Subjects",
          options: arraySort(additionalSubjects, "label"),
        },
      ];
      setSubjects(options);
      setDistrictAdminSubjects(options);
    });
  }
  const getSchoolAdminSubjects = () => {
    setShowLoading(true);
    getAssociatedSubjects(props.userContext?.userId).then((response) => {
      setShowLoading(false);
      const subjectsELA: Array<ISelectElement> = response.data
        .filter((item) => item.contentAreaId === 1)
        ?.map((values: any) => {
          return {
            label: values?.name,
            value: values?.subjectId,
          };
        });
      const subjectsMath: Array<ISelectElement> = response.data
        .filter((item) => item.contentAreaId === 2)
        ?.map((values: any) => {
          return {
            label: values?.name,
            value: values?.subjectId,
          };
        });
      const subjectsScience: Array<ISelectElement> = response.data
        .filter((item) => item.contentAreaId === 3)
        ?.map((values: any) => {
          return {
            label: values?.name,
            value: values?.subjectId,
          };
        });
      const subjectsSocial: Array<ISelectElement> = response.data
        .filter((item) => item.contentAreaId === 4)
        ?.map((values: any) => {
          return {
            label: values?.name,
            value: values?.subjectId,
          };
        });
      const options = [
        {
          label: "ELA",
          options: arraySort(subjectsELA, "label"),
        },
        {
          label: "Math",
          options: arraySort(subjectsMath, "label"),
        },
        {
          label: "Science",
          options: arraySort(subjectsScience, "label"),
        },
        {
          label: "Social Studies",
          options: arraySort(subjectsSocial, "label"),
        },
      ];
      setSubjects(options);
    });
  };

  function getDomains(subjectId: number) {
    setShowLoading(true);
    getDomainsBySubjectId(subjectId).then((response) => {
      setShowLoading(false);
      const domains: Array<ISelectElement> = response.data.data.map(
        (values: any) => {
          return {
            label: values?.name,
            value: values?.domainId,
          };
        }
      );
      setDomains(domains);
    });
  }

  function getStandards(domains: string) {
    setShowLoading(true);
    getAllStandardByDomains(domains).then((response) => {
      setShowLoading(false);
      const standards: Array<ISelectElement> = [];
      if (response.data.data) {
        response.data.data.forEach(function (domainStandard: DomainStandard) {
          domainStandard.standards.map((values: any) => {
            standards.push({
              label: `[${values?.code}] ${values?.name}`,
              value: values?.id,
            });
            return null;
          });
        });
      }
      setStandards(standards);
    });
  }

  function getDoks() {
    const data = getDOK();
    const doks = data.map((values: any) => {
      return {
        label: values?.label,
        value: values?.value,
      };
    });
    setDoks(doks);
  }

  function handleSubjectChange(e: any) {
    setSelectedSubject(e);
    setSelectedDomains([]);
    setSelectedStandards([]);
    getDomains(e.value);
  }

  function handleDomainChange(e: any) {
    setSelectedDomains(e);
    const selectedDomainIds = Array.prototype.map.call(e, (s) => s.value);
    getStandards(selectedDomainIds.join(","));
  }

  function handleStandardChange(e: any) {
    setSelectedStandards(e);
  }
  function handleDokChange(e: any) {
    setSelectedDok(e);
  }
  function handleQuestionTypeChange(e: any) {
    const questionTypeIds = e.map((q, index) => {
      return q.value;
    });
    setSelectedQuestionTypeIds(questionTypeIds);
    setSelectedQuestionTypes(e);
  }

  function handleDuplicateQuestion(questionId: number) {
    setShowLoading(true);
    duplicateQuestion(questionId, props.userContext.userId).then((d) => {
      setShowLoading(false);
      //redirect to edit question screen
      if (d.data > 0) {
        const newQuestionId = d.data;
        const question = questions.filter(
          (q) => q.teacherQuestionId === questionId
        )[0];
        history.push({
          pathname: RouteConstant.EDITQUESTION.replace(
            ":id",
            newQuestionId.toString()
          ),
          state: {
            questionTypeCode: question.questionTypeCode,
            payloadLabel: "",
            passageQuestion: question.isPassageQuestion,
            questionTypeId: question.questionTypeId,
            standardId: question.standardId,
            stateId: props.userContext?.stateId,
            dok: question.dok,
            itemReferenceId: newQuestionId.toString() + "-teacher",
          },
        });
      }
    });
  }

  function handleDeleteQuestion(questionId: number) {
    setShowLoading(true);
    deleteQuestion(questionId).then((d) => {
      setShowLoading(false);
      searchAuthoredQuestions();
    });
  }

  function resetFilters() {
    setSelectedSubject(defaultValue);
    setSelectedDomains([]);
    setSelectedStandards([]);
    setSelectedDok(defaultValue);
    setSelectedQuestionTypeIds([]);
    setSelectedQuestionTypes([]);
  }

  function searchAuthoredQuestions() {
    const selectedDomainIds = Array.prototype.map.call(
      selectedDomains,
      (s) => s.value
    );
    const selectedStandardIds = Array.prototype.map.call(
      selectedStandards,
      (s) => s.value
    );

    let searchQuestion: SearchQuestionRequest = {
      subjectId: parseInt(selectedSubject.value ?? "0"),
      domainIds: selectedDomainIds.join(","),
      standardIds: selectedStandardIds.join(","),
      dok: parseInt(selectedDok.value ?? "0"),
      orderbyColumn: orderByColumn,
      sortByDesc: sortByDesc,
      pageSize: pageSize,
      pageNumber: pageNumber,
      questionTypeIds: selectedQuestionTypeIds?.join(","),
      createdByUserId: props?.userContext?.userId,
    };
    setShowLoading(true);
    getAllQuestions(searchQuestion).then((response) => {
      if (response.data !== null) {
        setQuestions(response.data.data);
        setTotalRecords(response.data.totalRecords);
      }
      setShowLoading(false);
    });
  }

  const handleRedirect = (question: Question) => {
    const passageQuestion = question.isPassageQuestion;
    history.push({
      pathname: RouteConstant.EDITQUESTION.replace(
        ":id",
        (question?.teacherQuestionId ?? 0).toString()
      ),
      state: {
        questionTypeCode: question.questionTypeCode,
        payloadLabel: "",
        passageQuestion: passageQuestion,
        questionTypeId: question.questionTypeId,
        standardId: question.standardId,
        stateId: props.userContext?.stateId,
        dok: question.dok,
        itemReferenceId: question.questionReferenceId,
      },
    });
  };

  const redirectToQuestionBuilder = async () => {
    if (upgradeWithLimit) {
      setShowUpgradePopup(true);
      return;
    }
    const isRestricted: boolean = await checkUpgradeRequired();
    if (isRestricted) {
      setShowUpgradePopup(true);
      return;
    }

    history.push({
      pathname: RouteConstant.QUESTIONBUILDERCARD,
    });
  };

  async function handleConfirmation(
    action: ActionButton,
    questionId: number = 0,
    isEditable: boolean
  ) {
    if (action === "Duplicate") {
      if (upgradeWithLimit) {
        setShowUpgradeLimitPopup(true);
        return;
      }
      const isRestricted: boolean = await checkUpgradeRequired();
      if (isRestricted) {
        setShowUpgradeLimitPopup(true);
        return;
      }
    }

    setActionQuestionId(questionId);
    if (action === "Delete" && !isEditable) {
      setOpenInformation(true);
      setInformationMessage(deleteMessage);
    } else {
      setButtonAction(action);
      setshowConfirmation(true);
      var message: string =
        action === "Delete"
          ? "Are you sure you want to delete this question? Once you delete this cannot be undone"
          : "Are you sure you want to duplicate this question?";
      setConfirmationMessage(message);
    }
  }

  function handleOkConfirmation() {
    setshowConfirmation(false);
    setToggleSelectAll(!toggleSelectAll);
    switch (buttonAction) {
      case "Delete":
        handleDeleteQuestion(actionQuestionId);
        break;
      case "Duplicate":
        handleDuplicateQuestion(actionQuestionId);
        break;
    }
  }

  function handleCancelConfirmation() {
    setshowConfirmation(false);
    setButtonAction(undefined);
    setConfirmationMessage("");
  }

  const onPageChange = (pageNo) => {
    setPageNumber(pageNo);
  };

  const onPageSizeChange = (pageSize) => {
    setPageNumber(DEFAULT_PAGE_NUMBER);
    setPageSize(pageSize);
    setResetPageNumber(true);
  };

  const openModal = (questionId, questionText, currentIndex) => {
    setOpen(true);
    setQuestionPreviewDetail({
      questionReference: `${questionId}-teacher`,
      questionId: questionId,
      questionText: questionText,
      currentIndex: currentIndex,
    });
  };

  const popUpClose = (value) => {
    setOpen(value);
    setQuestionPreviewDetail(undefined);
  };

  function handleNextQuestion() {
    if (questionPreviewDetail) {
      const nextQuestion = questions[questionPreviewDetail?.currentIndex + 1];
      if (nextQuestion && nextQuestion.teacherQuestionId) {
        setQuestionPreviewDetail({
          questionReference: `${nextQuestion.teacherQuestionId}-teacher`,
          questionId: nextQuestion.teacherQuestionId,
          questionText: nextQuestion.questionText
            ? nextQuestion.questionText
            : "",
          currentIndex: questionPreviewDetail.currentIndex + 1,
        });
      }
    }
  }

  function handlePreviousQuestion() {
    if (questionPreviewDetail) {
      const nextQuestion = questions[questionPreviewDetail?.currentIndex - 1];
      if (nextQuestion && nextQuestion.teacherQuestionId) {
        setQuestionPreviewDetail({
          questionReference: `${nextQuestion.teacherQuestionId}-teacher`,
          questionId: nextQuestion.teacherQuestionId,
          questionText: nextQuestion.questionText
            ? nextQuestion.questionText
            : "",
          currentIndex: questionPreviewDetail.currentIndex - 1,
        });
      }
    }
  }
  const toggleInformationMessagePopup = () => {
    setOpenInformation(false);
  };
  function decodeHtml(html) {
    var txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
  }
  const handleSortBy = (column: string) => {
    setSortByDesc(!sortByDesc);
    setOrderByColumn(column);
  };
  useEffect(() => {
    searchAuthoredQuestions();
  }, [pageSize, pageNumber, sortByDesc]);

  useEffect(() => {
    if (props.userContext.roleId === constant.UserRoleId.District) {
      fetchDistrictAdminSubjects();
    } else if (props.userContext.roleId === constant.UserRoleId.SchoolAdmin) {
      getSchoolAdminSubjects();
    } else {
      getSubjects();
    }
    getDoks();
  }, []);
  const breadcrumbItems = () => {
    const items: Array<IBreadcrumb> = [
      { name: t("Breadcrumb.Home"), url: RouteConstant.ROUTE_DASHBOARD },
      {
        name: "Assessment/Assignment Center",
        url: RouteConstant.TeacherRoutes.assignmentCenter,
      },
      { name: t("Breadcrumb.ManageMyQuestions"), url: "" },
    ];

    return items;
  };

  return (
    <AllowedTo
      perform={Permissions.authoured_questions_view}
      no={() => <AccessDenied />}
    >
      {showLoading && <Loader></Loader>}
      <div className="min-w-0 w-full">
        <div className="lg:flex lg:items-center px-6 py-3 lg:px-8 border-b border-gray-200">
          <Breadcrumb items={breadcrumbItems()} />
          <div className="lg:w-[55%] text-lg flex justify-between gap-2 h-auto sm:h-10 items-center">
            <h1 className="text-primary-violet font-medium flex justify-start">
              Manage My Questions
            </h1>
          </div>
        </div>
        <QuestionPreview
          key={questionPreviewDetail?.questionId}
          modal={openModal}
          show={open}
          changeValue={popUpClose}
          itemRef={questionPreviewDetail?.questionReference}
          questionIdPreview={questionPreviewDetail?.questionId}
          activityName={questionPreviewDetail?.questionText}
          nextQuestionClick={handleNextQuestion}
          previousQuestionClick={handlePreviousQuestion}
        ></QuestionPreview>
        <ConfirmationDialog
          open={showConfirmation}
          confirmationTitle={"Please confirm"}
          confirmationMessage={confirmationMessage}
          onCancelClick={handleCancelConfirmation}
          onOkClick={handleOkConfirmation}
        ></ConfirmationDialog>
        {openInformation && (
          <MessagePopup
            message={informationMessage}
            togglePopup={toggleInformationMessagePopup}
          ></MessagePopup>
        )}
        {showUpgradePopup && (
          <UpGradeMessagePopup
            togglePopup={setShowUpgradePopup}
            roleId={props.userContext?.roleId ?? 0}
            message={constant.UPGRADE_MESSAGE_SCHOOL_ACCOUNT}
          />
        )}
        {showUpgradeLimitPopup && (
          <UpGradeMessagePopup
            togglePopup={setShowUpgradeLimitPopup}
            roleId={props.userContext?.roleId ?? 0}
            message={constant.UPGRADE_AUTHOR_QUESTION_LIMIT}
          />
        )}
        <div className="px-2 sm:px-6 lg:px-8 py-6">
          {upgrade && (
            <div>
              <InfoMessage
                message={`Your account allows you to author up to ${constant.TEACHER_FREE_AUTHOR_QUESTION_LIMIT} questions. Your questions are available for quick access when creating assessments.`}
              ></InfoMessage>
            </div>
          )}

          <InfoMessage message="These are questions that you authored. They will NOT appear in the Progress Learning question database and will NOT be accessible by other teachers at your school. They are available for quick access when creating assessments." />

          <div className="mt-4">
            <div>
              <AllowedTo perform={Permissions.authoured_questions_create}>
                <div className="flex justify-end mb-4">
                  <button
                    onClick={redirectToQuestionBuilder}
                    className={
                      (upgradeWithLimit ? "opacity-50 " : "") +
                      "whitespace-nowrap ml-4 mb-auto bg-primary-violet border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-dark-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    }
                  >
                    Create New Question
                  </button>
                </div>
              </AllowedTo>
              <div className="min-w-0 block lg:col-span-9 xl:col-span-10 w-full">
                <div className="bg-gray-100  w-full mb-5 rounded-lg p-5 h-full">
                  <div className="grid grid-cols-2 sm:grid-cols-8 lg:grid-cols-8 xl:grid-cols-8 gap-6 pb-4">
                    <div className="col-span-8 sm:col-span-4 xl:col-span-2">
                      <label className="block text-sm text-gray-500">
                        Subject
                      </label>
                      {props.userContext.roleId ===
                      constant.UserRoleId.District ? (
                        <Select
                          value={selectedSubject}
                          formatGroupLabel={(data) => {
                            return (
                              <div
                                className="bg-gray-200 -mx-3"
                                style={constant.SubjectDDLCSS.groupStyles}
                              >
                                <span className="text-secondary-teal pl-2">
                                  {data.label}
                                </span>

                                <span
                                  style={constant.SubjectDDLCSS.groupBadgeStyles()}
                                >
                                  {data.options.length}
                                </span>
                              </div>
                            );
                          }}
                          options={districtAdminSubjects}
                          onChange={handleSubjectChange}
                          theme={(theme) => ({
                            ...theme,
                            colors: {
                              ...theme.colors,
                              primary: "#008fbb",
                            },
                          })}
                        />
                      ) : (
                        <Select
                          value={selectedSubject}
                          formatGroupLabel={(data) => {
                            return (
                              <div
                                className="bg-gray-200 -mx-3"
                                style={constant.SubjectDDLCSS.groupStyles}
                              >
                                <span className="text-secondary-teal pl-2">
                                  {data.label}
                                </span>

                                <span
                                  style={constant.SubjectDDLCSS.groupBadgeStyles()}
                                >
                                  {data.options.length}
                                </span>
                              </div>
                            );
                          }}
                          options={subjects}
                          onChange={handleSubjectChange}
                          theme={(theme) => ({
                            ...theme,
                            colors: {
                              ...theme.colors,
                              primary: "#008fbb",
                            },
                          })}
                        />
                      )}
                    </div>
                    <div className="col-span-8 sm:col-span-4 xl:col-span-2">
                      <label className="block text-sm text-gray-500">
                        Domain
                      </label>
                      <Select
                        value={selectedDomains}
                        options={domains}
                        isMulti={true}
                        closeMenuOnSelect={false}
                        onChange={handleDomainChange}
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary: "#008fbb",
                          },
                        })}
                      />
                    </div>
                    <div className="col-span-8 sm:col-span-4 xl:col-span-2">
                      <label className="block text-sm text-gray-500">
                        Standard
                      </label>
                      <Select
                        value={selectedStandards}
                        options={standards}
                        isMulti={true}
                        closeMenuOnSelect={false}
                        onChange={handleStandardChange}
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary: "#008fbb",
                          },
                        })}
                      />
                    </div>
                    <div className="col-span-8 sm:col-span-4 xl:col-span-2">
                      <label className="block text-sm text-gray-500">DOK</label>
                      <Select
                        value={selectedDok}
                        options={doks}
                        onChange={handleDokChange}
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary: "#008fbb",
                          },
                        })}
                      />
                    </div>
                    <div className="col-span-8 sm:col-span-4 xl:col-span-2">
                      <label className="block text-sm text-gray-500">
                        Question Type
                      </label>
                      <QuestionTypeSelect
                        onChange={handleQuestionTypeChange}
                        value={selectedQuestionTypes}
                        teacherId={props.userContext?.userId}
                      ></QuestionTypeSelect>
                    </div>
                  </div>
                  <div className="flex flex-col md:flex-row justify-end mb-4 pb-10">
                    <div className="grid grid-cols-2 gap-4">
                      <button
                        onClick={() => resetFilters()}
                        className="bg-transparent border my-auto border-primary-violet shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-primary-violet hover:text-white hover:bg-light-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition ease-in-out duration-500"
                      >
                        Reset
                      </button>
                      <button
                        onClick={() => searchAuthoredQuestions()}
                        className="bg-primary-violet border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-dark-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 "
                      >
                        Filter
                      </button>
                    </div>
                  </div>
                  <div>
                    <div className="bg-white py-5 px-5">
                      <div className="mb-4 pb-4 border-b-2 overflow-x-auto xl:overflow-x-visible">
                        <table className="table w-full shadow">
                          <thead className="mb-5">
                            <tr className="bg-light-violet  text-white py-3 px-3 overflow-hidden">
                              <th className="text-center  p-3 text-sm font-normal">
                                <div className="flex justify-center">
                                  <span className="flex">
                                    Create Date
                                    <span className="cursor-pointer">
                                      {sortByDesc &&
                                      orderByColumn === "CreatedDate" ? (
                                        <CaretUpSolidIcon
                                          onClick={() =>
                                            handleSortBy("CreatedDate")
                                          }
                                          className="w-5 h-5"
                                        ></CaretUpSolidIcon>
                                      ) : (
                                        <CaretDownSolidIcon
                                          onClick={() =>
                                            handleSortBy("CreatedDate")
                                          }
                                          className="w-5 h-5"
                                        ></CaretDownSolidIcon>
                                      )}
                                    </span>
                                  </span>
                                </div>
                              </th>
                              <th className="text-left  p-3 text-sm font-normal">
                                Question - Click to Preview
                              </th>
                              <th className="text-left  p-3 text-sm font-normal">
                                <span className="flex">
                                  Subject
                                  <span className="cursor-pointer">
                                    {sortByDesc &&
                                    orderByColumn === "Subject" ? (
                                      <CaretUpSolidIcon
                                        onClick={() => handleSortBy("Subject")}
                                        className="w-5 h-5"
                                      ></CaretUpSolidIcon>
                                    ) : (
                                      <CaretDownSolidIcon
                                        onClick={() => handleSortBy("Subject")}
                                        className="w-5 h-5"
                                      ></CaretDownSolidIcon>
                                    )}
                                  </span>
                                </span>
                              </th>
                              <th className="text-left p-3 text-sm font-normal">
                                Classification
                              </th>
                              <th className="text-center  p-3 text-sm font-normal">
                                Edit
                              </th>
                              <th className="text-center  p-3 text-sm font-normal">
                                Duplicate
                              </th>
                              <th className="text-center  p-3 text-sm font-normal">
                                Delete
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {questions &&
                              questions.length > 0 &&
                              questions.map((question, index) => {
                                return (
                                  <Fragment key={index}>
                                    <tr
                                      className={`hover:bg-light-violet/20 transition ease-in-out duration-300 ${
                                        index % 2 === 0
                                          ? "bg-white"
                                          : "bg-light-violet/10"
                                      }`}
                                    >
                                      <td className="text-sm text-center mt-5  p-3">
                                        {question.createdDate &&
                                          Moment(question.createdDate).format(
                                            "MM-DD-yyyy"
                                          )}
                                      </td>
                                      <td className="text-sm mt-5  p-3 ">
                                        <a
                                          href="#!"
                                          className="text-blue-500 underline hover:no-underline"
                                          onClick={() =>
                                            openModal(
                                              question?.teacherQuestionId,
                                              question.questionText,
                                              index
                                            )
                                          }
                                        >
                                          <Tooltip
                                            text={decodeHtml(
                                              question?.questionText
                                            )}
                                            isQuestion={true}
                                          />
                                        </a>
                                      </td>
                                      <td className="text-sm mt-5  p-3 ">
                                        {question.subjectName}
                                      </td>
                                      <td className="text-sm mt-5  p-3 ">
                                        <div className="grid">
                                          {question.domainName}
                                        </div>
                                        <div className="grid">
                                          [{question.standardCode}]{" "}
                                          {question.standardName}
                                        </div>
                                      </td>
                                      <td className="text-sm mt-5 p-3 cursor-pointer">
                                        <AllowedTo
                                          perform={
                                            Permissions.authoured_questions_modify
                                          }
                                        >
                                          <EditIcon
                                            title="Edit question"
                                            onClick={() =>
                                              handleRedirect(question)
                                            }
                                            className="w-4 text-gray-500 hover:text-gray-700 cursor-pointer m-auto"
                                          />
                                        </AllowedTo>
                                      </td>
                                      <td className="text-sm mt-5 p-3">
                                        <AllowedTo
                                          perform={
                                            Permissions.authoured_questions_modify
                                          }
                                        >
                                          <DuplicateIcon
                                            title="Duplicate question"
                                            onClick={() =>
                                              handleConfirmation(
                                                "Duplicate",
                                                question.teacherQuestionId,
                                                question.isEditable ?? true
                                              )
                                            }
                                            className="w-4 m-auto cursor-pointer text-gray-500 hover:text-gray-700"
                                          />
                                        </AllowedTo>
                                      </td>
                                      <td className="text-sm text-center mt-5 p-3">
                                        <AllowedTo
                                          perform={
                                            Permissions.authoured_questions_delete
                                          }
                                        >
                                          {!upgrade && (
                                            <DeleteIcon
                                              title="Delete question"
                                              className="w-4 m-auto cursor-pointer text-gray-500 hover:text-gray-700"
                                              onClick={() =>
                                                handleConfirmation(
                                                  "Delete",
                                                  question.teacherQuestionId,
                                                  question.isEditable ?? true
                                                )
                                              }
                                            />
                                          )}
                                          {upgrade && (
                                            <DeleteIcon
                                              title="Delete question"
                                              className="opacity-50 cursor-not-allowed w-4 m-auto cursor-pointer text-gray-500 hover:text-gray-700"
                                            />
                                          )}
                                        </AllowedTo>
                                      </td>
                                    </tr>
                                  </Fragment>
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                      <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                        {totalRecords !== undefined && totalRecords !== 0 && (
                          <Pagination
                            totalRecords={totalRecords}
                            pageSize={pageSize}
                            pageNumber={pageNumber}
                            onPageChange={onPageChange}
                            reset={resetPageNumber}
                          />
                        )}
                        {totalRecords !== 0 && (
                          <PageSize
                            pageSize={pageSize}
                            onPageSizeChange={onPageSizeChange}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AllowedTo>
  );
}

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(AuthoredQuestions);

import React, { Fragment } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { connect } from "react-redux";
import VideoMessageData from "../../../model/videoMessage/videoMessageData";
import NoData from "../../shared/noData";
import { VideoMessageModalProps } from "./videoMessageModal";
import { DeleteIcon, EditIcon } from "../../../assets/icons/index";
import Moment from "react-moment";
export interface VideoMessageListViewProps {
  videoMessageData: VideoMessageData[];
  handleDelete: (messageId) => void;
  handleEdit: (data) => void;
}

const VideoMessageListView: React.FC<VideoMessageListViewProps> = (
  props: any
) => {
  const [showNoData, setShowNoData] = useState(false);

  useEffect(() => {
    if (props.videoMessageData && props.videoMessageData.length === 0) {
      setShowNoData(true);
    } else {
      setShowNoData(false);
    }
  });

  const handleDeleteEvent = (messageId: number) => {
    props.handleDelete(messageId);
  };

  const handleEditEvent = (videoMessageData: VideoMessageData) => {
    let data: VideoMessageModalProps = {
      messageId: videoMessageData.messageId,
      linkedItemId: videoMessageData.linkedItemId,
      title: videoMessageData.title,
      startDate: videoMessageData.startDate,
      endDate: videoMessageData.endDate,
      messageContent: videoMessageData.messageContent,
      gradeId: videoMessageData.gradeId,
      classIds: videoMessageData.classIds.split(",").map(Number),
      studentIds: videoMessageData.studentIds.split(",").map(Number),
      isActive: videoMessageData.isActive,
      showModal: (data) => {},
      publishMessage: (data) => {},
      uploadFile: (data, callBack) => {},
    };
    props.handleEdit(data);
  };

  return (
    <Fragment>
      <div className="mt-3">
        <div>
          <div className="flex flex-col">
            <div className="-my-2 overflow-x-auto">
              <div className="py-2 align-middle inline-block min-w-full">
                <div className="shadow overflow-hidden sm:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-primary-green bg-opacity-75 ">
                      <tr>
                        <th
                          scope="col"
                          className="px-6 py-1 text-left text-xs font-medium text-gray-700 uppercase tracking-wider "
                        >
                          Message Title
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-1 text-center text-xs font-medium text-gray-700 uppercase tracking-wider"
                        >
                          Start Date
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-1 text-center text-xs font-medium text-gray-700 uppercase tracking-wider"
                        >
                          End Date
                        </th>
                        <th
                          scope="col"
                          className="px-6  text-left pl-14 text-xs font-medium text-gray-700 uppercase tracking-wider"
                        >
                          Student
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-1 text-center text-xs font-medium text-gray-700 uppercase tracking-wider"
                        >
                          Class
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-1 text-center text-xs font-medium text-gray-700 uppercase tracking-wider"
                        >
                          Grade
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-1 text-center text-xs font-medium text-gray-700 uppercase tracking-wider"
                        >
                          Video Status
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-1 text-center text-xs font-medium text-gray-700 uppercase tracking-wider"
                        >
                          Republished
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-1 text-center text-xs font-medium text-gray-700 uppercase tracking-wider"
                        >
                          View Count
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-1 text-center text-xs font-medium text-gray-700 uppercase tracking-wider"
                        >
                          Recorded Date
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-1 text-center text-xs font-medium text-gray-700 uppercase tracking-wider"
                        >
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {props.videoMessageData.map((data, index) => (
                        <tr
                          key={data.messageId}
                          className="px-6 py-1 whitespace-nowrap text-xs text-gray-500 "
                        >
                          <td className="px-6 py-1 whitespace-nowrap text-xs text-gray-500">
                            {data.title}
                          </td>
                          <td className="px-6 py-1 whitespace-nowrap text-xs text-gray-500 text-center">
                            <Moment>{data.startDate}</Moment>
                          </td>
                          <td className="px-6 py-1 whitespace-nowrap text-xs text-gray-500 text-center">
                            <Moment>{data.endDate}</Moment>
                          </td>
                          <td className="px-6 py-1 whitespace-nowrap text-xs text-gray-500 text-center">
                            <div>
                              <select className="py-2.5 px-4 w-full dark:text-gray-300  dark:bg-gray-700 focus:shadow-outline-purple dark:focus:shadow-outline-gray form-select block text-sm focus:outline-none border-0 border-gray-400 sm:focus:border-gray-700 border-opacity-50 focus:shadow-outline-green form-select leading-5 p-1 rounded-md">
                                {data.studentNames
                                  ?.split(",")
                                  .filter((item) => {
                                    return item !== "";
                                  })
                                  .map((name, index) => (
                                    <option value="">{name}</option>
                                  ))}
                              </select>
                            </div>
                          </td>
                          <td className="px-6 py-1 whitespace-nowrap text-xs text-gray-500 text-center">
                            <div>
                              <select className="py-2.5 px-4 w-full dark:text-gray-300  dark:bg-gray-700 focus:shadow-outline-purple dark:focus:shadow-outline-gray form-select block text-sm focus:outline-none border-0 border-gray-400 sm:focus:border-gray-700 border-opacity-50 focus:shadow-outline-green form-select leading-5 p-1 rounded-md">
                                {data.classNames
                                  ?.split(",")
                                  .filter((item) => {
                                    return item !== "";
                                  })
                                  .map((name, index) => (
                                    <option value="">{name}</option>
                                  ))}
                              </select>
                            </div>
                          </td>
                          <td className="px-6 py-1 whitespace-nowrap text-xs text-gray-500 text-center">
                            {data.grade}
                          </td>
                          <td className="px-6 py-1 whitespace-nowrap text-xs text-gray-500 text-center">
                            {data.isActive ? "Active" : "InActive"}
                          </td>
                          <td className="px-6 py-1 whitespace-nowrap text-xs text-gray-500 text-center">
                            {data.isRepublished ? "Yes" : "No"}
                          </td>
                          <td className="px-6 py-1 whitespace-nowrap text-xs text-gray-500 text-center">
                            {data.viewCount}
                          </td>
                          <td className="px-6 py-1 whitespace-nowrap text-xs text-gray-500 text-center">
                            <Moment>{data.messageCreatedDate}</Moment>
                          </td>
                          <td className="px-6 py-1 whitespace-nowrap text-xs text-gray-500 text-center">
                            <div className="flex ">
                              <a
                                href="#!"
                                className="cursor-pointer  text-gray-500 border-black pl-2"
                                onClick={() => {
                                  handleDeleteEvent(data.messageId);
                                }}
                              >
                                <DeleteIcon className="w-5" />
                              </a>
                              <a
                                href="#!"
                                className="cursor-pointer  text-gray-500 border-black pl-2"
                                onClick={() => {
                                  handleEditEvent(data);
                                }}
                              >
                                <EditIcon className="w-5" />
                              </a>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showNoData && <NoData></NoData>}
    </Fragment>
  );
};

const mapStateToProps = (state: any) => {
  return {
    masterData: state.masterData,
    userContext: state.userContext,
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(VideoMessageListView);

import { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { UserContextState } from "../../../../../redux/actions/userContextAction";
import Constant from "../../../../../utils/constant/constant";
import { useHistory } from "react-router-dom";
import {
  completeCannedTest,
  getCannedTestQuestionStandards,
  getCannedTestTotalQuestionCount,
} from "../../../../../api/student/cannedTestApi";
import { IInitalizeStudyPlanResponse } from "../../../../../model/student/studyplan/initializeStudyPlanRespons";
import QuestionAnswer from "../../shared/questionAnswer";
import RouteConstant from "../../../../../utils/constant/routeConstant";
import {
  getCannedTestLastSession,
  initializeCannedTest,
  //updatCannedTest,
} from "../../../../../api/student/cannedTestApi";
import {
  getStudentClasses,
  getStudentTeacher,
} from "../../../../../api/student/studentApi";
import BeginActivity from "../../shared/beginActivity";
import { getCalculatorBySubjectId } from "../../../../../api/student/studentSubjectApi";
import { GetShowExplanation } from "../../../../../utils/helper";
import Profile from "../../../../../model/users/profile";
import { useTranslation } from "react-i18next";
interface ICannedTest {
  userContext: UserContextState;
  location: any;
  match: any;
  profile: Profile;
}
function CannedTest(props: ICannedTest) {
  const { t } = useTranslation();
  const [cannedTest, setCannedTest] = useState<IInitalizeStudyPlanResponse>();
  const [beginActivityModal, setBeginActivityModal] = useState<any>({
    open: false,
    data: undefined,
  });
  const cannedTestId: number = parseInt(atob(props.match.params.cannedTestId));
  const [lastSession, setLastSession] = useState<any>({});
  const subjectId = parseInt(atob(props.match.params.subjectId));
  const subjectName = props.location.state?.subjectName ?? "";
  const cannedTestTypeId = parseInt(
    props.location.state?.cannedTestTypeId ?? 1
  );
  const [totalQuestionCount, setTotalQuestionCount] = useState<number>(0);
  const history = useHistory();
  const [calculatorReferenceId, setCalculatorReferenceId] = useState<string>();
  const [showExpalanation, setShowExplanation] = useState<boolean>(false);
  const [selectedLanguage, setSelectedLanguage] = useState<number>(1);
  const [standardIds, setStandardIds] = useState<Array<number>>();
  const [contentAreaId, setContentAreaId] = useState<number>(0);
  useEffect(() => {
    initialize();
    getCannedTestQuestionStandards(cannedTestId).then((response) => {
      if (response.data) {
        setStandardIds(response.data);
      }
    });
    if (subjectId > 0) {
      getCalculatorBySubjectId(subjectId).then((res) => {
        if (res.data) setCalculatorReferenceId(res.data);
      });
    }
    setShowExplanation(
      GetShowExplanation(
        props.userContext?.gradeId,
        props.userContext?.stateId,
        Constant.ActivityType.DIAGNOSTIC,
        undefined,
        undefined,
        undefined
      )
    );
  }, []);

  function initialize() {
    var classesResponseApi = getStudentClasses(
      props.userContext.userId,
      subjectId,
      0
    );
    var teacherResponseApi = getStudentTeacher(
      props.userContext.userId,
      subjectId,
      0
    );

    var lastSessionApi = getCannedTestLastSession(
      props.userContext.userId,
      cannedTestId,
      0
    );

    var totalQuestionResponseApi = getCannedTestTotalQuestionCount(
      cannedTestId,
      cannedTestTypeId
    );

    Promise.all([
      classesResponseApi,
      teacherResponseApi,
      lastSessionApi,
      totalQuestionResponseApi,
    ]).then(
      ([
        classesResponse,
        teachersResponse,
        lastSessionResponse,
        totalQuestionResponse,
      ]) => {
        setLastSession(lastSessionResponse.data);
        setTotalQuestionCount(totalQuestionResponse.data);
        var data: any = {
          classes: classesResponse.data,
          teachers: teachersResponse.data,
          classId:
            lastSessionResponse.data !== null
              ? lastSessionResponse.data.classId
              : undefined,
          teacherId:
            lastSessionResponse.data !== null
              ? lastSessionResponse.data.teacherUserId
              : undefined,
          languageId: lastSessionResponse.data.languageId,
        };
        setBeginActivityModal({ open: true, data });
      }
    );
  }

  function OnSubmit() {
    completeCannedTest(
      cannedTest?.activitySessionId ?? 0,
      props.userContext.userId,
      cannedTest?.sessionId,
      Constant.AssignmentStatusId.COMPLETE
    ).then((res) => {
      history.push(
        RouteConstant.KindergartenStudentRoutes.StandardEfficiencyReport.replace(
          ":sessionId",
          cannedTest ? cannedTest.sessionId : ""
        )
      );
    });
  }

  function initializeTest(data: any) {
    initializeCannedTest(
      props.userContext.userId,
      cannedTestId,
      data.classId,
      data.teacherId,
      data.languageId,
      props.userContext?.gradeId
    ).then((d) => {
      var data = d.data;
      setCannedTest(d.data);
      setBeginActivityModal({ open: false, data });
    });
  }

  // function update(data: any) {
  //   updatCannedTest(cannedTestId, data.teacherId, data.classId);
  // }
  return (
    <Fragment>
      {cannedTest && cannedTest.sessionId?.length > 0 && (
        <QuestionAnswer
          activityType={Constant.ActivityType.DIAGNOSTIC}
          activityId={cannedTest.activityId}
          teacherActivityId={cannedTest.learnositySessionGroupId}
          showActivity={true}
          showQuestions={false}
          hasSections={false}
          hasItemBranching={false}
          isAlienExplanation={false}
          isGameChoosen={false}
          studentId={props.userContext.userId.toString()}
          resumeActivity={true}
          sessionId={cannedTest.sessionId}
          studentType="K1"
          questionIds={[]}
          title={""}
          adaptiveFilter={null}
          OnSubmit={OnSubmit}
          onExitRedirectUrl={RouteConstant.KindergartenStudentRoutes.Home}
          activitySubType={Constant.ActivitySubTypes.DIAGNOSTICS}
          calculatorReferenceId={calculatorReferenceId}
          questionRetry={false}
          showExplanation={showExpalanation}
          activitySessionId={cannedTest.activitySessionId}
          language={selectedLanguage === 2 ? "es" : "en"}
          standardId={standardIds?.join(",")}
          contentAreaId={contentAreaId}
          questionsState={cannedTest.questionStates}
          subjectId={subjectId}
        ></QuestionAnswer>
      )}
      {beginActivityModal.open && (
        <BeginActivity
          activity={t("LiftoffDiagnostics.DiagnosticTest")}
          name={subjectName + " Diagnostic"}
          noOfQuestions={totalQuestionCount}
          source={"StudyPlan"}
          themeName={"KINDERGARTEN"}
          data={beginActivityModal.data}
          hideToggle={false}
          hideClass={true}
          hideTeacher={true}
          ActivityLanguageLevel={"UserLevel"}
          onBegin={(data: any, value: any) => {
            setContentAreaId(value.contentAreaId);
            setSelectedLanguage(value.languageId);
            if (
              lastSession === null ||
              lastSession === "" ||
              lastSession === undefined ||
              data.languageId !== value.languageId
            ) {
              initializeTest(value);
            } else {
              setCannedTest({
                hasPendingSession: true,
                sessionId: lastSession.learnositySessionId,
                activityId: lastSession.learnosityReferenceId,
                activitySessionId: lastSession.activitySessionId,
                learnositySessionGroupId: lastSession.learnositySessionGroupId,
                isDisplayRandomQuestion: false,
                questionStates: lastSession.questionStates,
              });
              //update(value);
              setBeginActivityModal({ ...beginActivityModal, open: false });
            }
          }}
          subjectId={subjectId}
        />
      )}
    </Fragment>
  );
}

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(CannedTest);

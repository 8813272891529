import { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import "../../../../../css/style.css";
import { getSchoolByDistrictId } from "../../../../../api/teacher/school";
import RouteConstant from "../../../../../utils/constant/routeConstant";
import Breadcrumb from "../../shared/breadcrumb";
import { IBreadcrumb } from "../../../../../model/common/breadcrumb";
import { useTranslation } from "react-i18next";
import { ISelectElement } from "../../../../../model/interface/selectElement";
import { ClassApi } from "../../../../../api/teacher/classManagementApi";
import Constant from "../../../../../utils/constant/constant";
import classNames from "classnames";
import { Configuration } from "../../../../../environment/setup";
import DiagnosticsStrengthWeaknessData from "../../../../../model/report/diagnostics/diagnosticsStrengthWeaknessData";
import { getDiagnosticsStrengthWeaknessReport } from "../../../../../api/report/diagnosticStrengthWeaknessStudentReport";
import { getAllClassesBySubject } from "../../../../../api/teacher/teacher";
import ClassDropDown from "../../shared/classDropDown";
import ClassStudentPopup from "./classStudentPopup";
import DiagnosticStrengthWeaknessReportPrint from "./diagnosticStrengthWeaknessReportPrint";
import Asterisk from "../../../../shared/asterisk";
import Subject from "../../../../shared/dropdowns/subject";
import Loader from "../../../../shared/loader";
import { UserContextState } from "../../../../../redux/actions/userContextAction";
import Profile from "../../../../../model/users/profile";

interface IDiagnosticStrengthWeaknessReportProps {
  userContext: UserContextState;
  profile: Profile;
}
function DiagnosticStrengthWeaknessReport(
  props: IDiagnosticStrengthWeaknessReportProps
) {
  const { t } = useTranslation();
  const defaultValue = { label: "Select", value: "0" };
  const [schools, setSchools] = useState<Array<any>>();
  const [classes, setClasses] = useState<Array<any>>();
  const [students, setStudents] = useState<Array<any>>();
  const [selectedSchool, setSelectedSchool] = useState<ISelectElement>({
    label: "Select School",
    value: "0",
  });
  const [selectedClass, setSelectedClass] = useState<ISelectElement>({
    label: "Select Class",
    value: "0",
  });
  const [selectedSubject, setSelectedSubject] = useState<ISelectElement>({
    label: "Select Subject",
    value: "0",
  });
  const [selectedStudent, setSelectedStudent] = useState<ISelectElement>({
    label: "Select Student",
    value: "0",
  });
  const [isDistrictUser] = useState<boolean>(
    props.userContext.roleId === Constant.UserRoleId.District ? true : false
  );
  const [schoolIds, setSchoolIds] = useState<Array<any>>();
  const [tabs] = useState<any[]>([
    {
      id: 1,
      name: "Pre-Test",
    },
    {
      id: 2,
      name: "Post-Test",
    },
  ]);
  const [selectedTab, setSelectedTab] = useState<number>(1);
  const [preTestReportData, setPreTestReportData] =
    useState<DiagnosticsStrengthWeaknessData | null>();
  const [postTestReportData, setPostTestReportData] =
    useState<DiagnosticsStrengthWeaknessData | null>();
  const [showClassStudentPopup, setShowClassStudentPopup] =
    useState<boolean>(false);
  const [selectedStudentsToPrint, setSelectedStudentsToPrint] = useState<
    Array<number>
  >([]);
  const [showPrintPopup, setShowPrintPopup] = useState<boolean>(false);
  const [showLoading, setShowLoading] = useState<boolean>(false);

  const onReset = () => {
    setSelectedClass({ label: "Select Class", value: "0" });
    setSelectedSchool({ label: "Select School", value: "0" });
    setSelectedStudent({ label: "Select student", value: "0" });
    setSelectedSubject({ label: "Select Subject", value: "0" });
    setPreTestReportData(null);
    setPostTestReportData(null);
  };

  const breadcrumbItems = () => {
    const items: Array<IBreadcrumb> = [
      { name: t("Breadcrumb.Home"), url: RouteConstant.ROUTE_DASHBOARD },
      {
        name: t("Breadcrumb.Reports"),
        url: RouteConstant.REPORTS,
      },
      { name: t("Breadcrumb.DiagnosticStrengthWeaknessReport"), url: "" },
    ];

    return items;
  };

  useEffect(() => {
    if (isDistrictUser) {
      getSchools();
    } else {
      getClasses();
    }
  }, [isDistrictUser]);

  useEffect(() => {
    setSelectedClass({ label: "Select Class", value: "0" });
    setSelectedStudent({ label: "Select Student", value: "0" });
    setSelectedSubject({ label: "Select Subject", value: "0" });
    getClasses();
    getStudents();
  }, [selectedSchool]);

  useEffect(() => {
    if (selectedClass && selectedClass.value !== defaultValue.value) {
      getStudents();
    }
  }, [selectedClass]);

  useEffect(() => {
    if (selectedSubject && selectedSubject.value !== defaultValue.value) {
      getClasses();
    }
  }, [selectedSubject]);

  useEffect(() => {
    if (
      (selectedTab == 1 && preTestReportData == null) ||
      (selectedTab == 2 && postTestReportData == null)
    ) {
      runReport();
    }
  }, [selectedTab]);

  const getSchools = async () => {
    try {
      setShowLoading(true);
      const resp = await getSchoolByDistrictId(
        props.userContext?.districtId ?? 0
      );
      if (resp?.data?.length) {
        const schools: Array<ISelectElement> = resp?.data.map((values: any) => {
          return {
            label: values?.name,
            value: values?.schoolId,
          };
        });

        setSchools(schools);

        const schoolIds: Array<any> = resp?.data.map((values: any) => {
          return {
            schoolAccountId: values?.schoolAccountId,
            schoolId: values?.schoolId,
          };
        });
        setSchoolIds(schoolIds);
      }
    } finally {
      setShowLoading(false);
    }
  };

  const getClasses = async () => {
    setClasses([]);
    let resp;
    let schoolId = props.userContext.schoolId;
    if (isDistrictUser) {
      schoolId = parseInt(selectedSchool.value);
    }
    let subjectId =
      selectedSubject.value !== defaultValue.value
        ? Number(selectedSubject.value)
        : null;
    try {
      setShowLoading(true);
      resp = await getAllClassesBySubject(
        props.userContext.userId,
        props.userContext.roleId,
        subjectId,
        props.profile.currentSchoolYear.schoolYearId,
        schoolId
      );
      if (resp?.data?.data?.length) {
        setClasses(resp?.data.data);
      }
    } finally {
      setShowLoading(false);
    }
  };

  function getStudents() {
    setShowLoading(true);
    setStudents([]);
    const classId = selectedClass.value;
    ClassApi.getClassStudentByClassIds(classId)
      .then((resp) => {
        if (resp?.data?.length || resp?.data.length) {
          var data = resp?.data ?? resp?.data;
          const students: Array<ISelectElement> = data.map((values: any) => {
            return {
              label: values?.lastName + ", " + values?.firstName,
              value: values?.userId,
            };
          });
          setStudents(students);
        }
      })
      .finally(() => {
        setShowLoading(false);
      });
  }

  function handleClassChange(e: any) {
    setSelectedClass(e[0]);
  }

  function handleSubjectChange(e: any) {
    setSelectedSubject(e);
  }

  function runReport() {
    setShowLoading(true);
    let schoolId = props.userContext.schoolId;
    var d = schoolIds?.find(
      (x) => x.schoolId === parseInt(selectedSchool.value)
    );
    if (d) {
      schoolId = d?.schoolId;
    }
    getDiagnosticsStrengthWeaknessReport(
      selectedStudent.value,
      selectedSubject.value,
      schoolId,
      selectedClass.value,
      selectedTab
    )
      .then((response) => {
        if (response.data) {
          if (selectedTab == 1) {
            setPreTestReportData(response.data.data);
          } else {
            setPostTestReportData(response.data.data);
          }
        }
      })
      .finally(() => {
        setShowLoading(false);
      });
  }

  function getSchoolId() {
    let schoolId = props.userContext.schoolId;
    var d = schoolIds?.find(
      (x) => x.schoolId === parseInt(selectedSchool.value)
    );
    if (d) {
      schoolId = d?.schoolId;
    }
    return schoolId;
  }

  function getSchoolAccountId() {
    let schoolAccountId = props.userContext.schoolAccountId;
    var d = schoolIds?.find(
      (x) => x.schoolId === parseInt(selectedSchool.value)
    );
    if (d) {
      schoolAccountId = d?.schoolAccountId;
    }
    return schoolAccountId;
  }

  useEffect(() => {
    if (selectedStudentsToPrint.length > 0) {
      setShowPrintPopup(true);
    }
  }, [selectedStudentsToPrint]);

  function handleSelectSchoolChange(option: ISelectElement | null) {
    if (option) {
      setSelectedSchool(option);
    }
  }

  function handleSelectStudentChange(option: ISelectElement | null) {
    if (option) {
      setSelectedStudent(option);
    }
  }

  return (
    <div className="min-w-0 w-full">
      {showLoading && <Loader />}
      {showClassStudentPopup && (
        <ClassStudentPopup
          showPopUp={setShowClassStudentPopup}
          classId={parseInt(selectedClass.value ?? "0")}
          subjectId={parseInt(selectedSubject.value)}
          schoolId={selectedSchool.value ? getSchoolId() : 0}
          sourceClassName={selectedClass.label}
          selectStudentsToExport={setSelectedStudentsToPrint}
        ></ClassStudentPopup>
      )}
      {showPrintPopup && (
        <DiagnosticStrengthWeaknessReportPrint
          showPrint={setShowPrintPopup}
          classId={parseInt(selectedClass.value ?? "0")}
          selectedStudentIds={selectedStudentsToPrint.join(",")}
          subjectId={parseInt(selectedSubject.value ?? "0")}
          schoolId={selectedSchool.value ? getSchoolId() : 0}
        ></DiagnosticStrengthWeaknessReportPrint>
      )}
      <div className="lg:flex lg:items-center px-4 py-3 lg:px-8 border-b">
        <Breadcrumb items={breadcrumbItems()} />
        <div className="lg:w-[55%] text-lg flex justify-between gap-2 items-end">
          <h1 className="text-primary-violet font-medium flex justify-start mb-0 pb-0">
            {t("Breadcrumb.DiagnosticStrengthWeaknessReport")}
          </h1>
        </div>
      </div>
      <div className="px-2 sm:px-6  lg:px-8 py-1 relative my-4">
        <div className="bg-gray-100 w-full rounded-lg p-5">
          <div>
            <div className="overflow-x-auto xl:overflow-x-visible mt-5">
              <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-4 mb-4">
                {isDistrictUser && (
                  <div className="flex flex-col">
                    <label className="block mb-1 text-sm text-gray-500">
                      School <Asterisk />
                    </label>

                    <Select
                      options={schools}
                      value={selectedSchool}
                      onChange={handleSelectSchoolChange}
                    />
                  </div>
                )}

                <div className="flex flex-col">
                  <label className="block mb-1 text-sm text-gray-500">
                    Class <Asterisk />
                  </label>
                  <ClassDropDown
                    schoolYearId={props.profile.currentSchoolYear.schoolYearId}
                    isMultiSelect={false}
                    onChange={handleClassChange}
                    value={selectedClass}
                    data={classes}
                    isDisabled={
                      props.userContext.roleId ===
                        Constant.UserRoleId.District &&
                      selectedSchool.value === "0"
                        ? true
                        : false
                    }
                    showAllOption={false}
                    showArchived={true}
                    showHidden={true}
                    selectedSchoolAccountId={getSchoolAccountId()}
                  ></ClassDropDown>
                </div>
                <div className="flex flex-col">
                  <label className="block mb-1 text-sm text-gray-500">
                    Student <Asterisk />
                  </label>

                  <Select
                    options={students}
                    value={selectedStudent}
                    onChange={handleSelectStudentChange}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: "#008fbb",
                      },
                    })}
                  />
                </div>
                <div className="flex flex-col">
                  <Subject
                    setSelectedSubject={(items) =>
                      handleSubjectChange(items[0])
                    }
                    selectedItems={selectedSubject}
                    isMulti={false}
                    isRequired={true}
                    className="w-full"
                    schoolId={selectedSchool.value}
                    subjectTypeId={Constant.SUBJECTTYPE.PL}
                    themeColor={"#008fbb"}
                  ></Subject>
                </div>
              </div>

              <div className="flex gap-4 justify-end">
                <button
                  onClick={onReset}
                  className="bg-transparent border my-auto border-primary-violet shadow-sm py-2 px-4 flex justify-center text-sm font-medium text-primary-violet hover:text-white hover:bg-light-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3 transition ease-in-out duration-500"
                >
                  Reset
                </button>
                <div className="cursor-not-allowed">
                  <button
                    onClick={runReport}
                    className={
                      "bg-primary-violet border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 " +
                      (selectedClass.value === defaultValue.value ||
                      selectedSubject.value === defaultValue.value ||
                      selectedStudent.value === defaultValue.value
                        ? "bg-opacity-20 text-primary-violet text-opacity-40 pointer-events-none"
                        : "hover:bg-dark-violet hover:shadow-lg")
                    }
                  >
                    Run Report
                  </button>
                </div>
                <div className="cursor-not-allowed">
                  <button
                    onClick={() => {
                      setShowClassStudentPopup(true);
                    }}
                    className={
                      "bg-primary-violet border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 " +
                      (preTestReportData || postTestReportData
                        ? "hover:bg-dark-violet hover:shadow-lg"
                        : "bg-opacity-20 text-primary-violet text-opacity-40 pointer-events-none")
                    }
                  >
                    Export/Print
                  </button>
                </div>
              </div>

              <div className="border-b border-gray-400 py-2 mb-2"></div>
              <div className="bg-white px-3 pt-2 pb-3">
                <div className="flex items-center bg-gray-100 pt-2 py-1 px-3 flex-col lg:flex-row lg:justify-between mb-4">
                  <div className="flex lg:mr-2 xl:mr-0">
                    <nav className="flex space-x-2" aria-label="Tabs">
                      {tabs.map((tab) => (
                        <button
                          key={tab.name}
                          onClick={() => {
                            setSelectedTab(tab.id);
                          }}
                          className={classNames(
                            tab.id === selectedTab
                              ? "border-secondary-teal bg-white text-primary-violet hover:text-dark-violet py-3 tab-box-shadow tab-box-shadow"
                              : "text-primary-violet text-opacity-60 bg-primary-violet/10 py-[0.6rem] mt-auto transition-all hover:py-3 hover:text-opacity-100 hover:text-primary-violet border-[#dee9f1]",
                            "px-2 sm:px-4 text-center border-t-2 text-sm cursor-pointer font-medium"
                          )}
                        >
                          {tab.name}
                        </button>
                      ))}
                    </nav>
                  </div>
                </div>
                {selectedTab === 1 && preTestReportData && (
                  <Fragment>
                    <div className="flex flex-col lg:flex-row gap-y-6 xl:gap-y-0 gap-x-6 p-3 mb-6 rounded-lg">
                      <div className="w-full lg:w-1/2 2xl:w-1/3 xxxl:w-1/4 bg-white shadow-lg border border-gray-100 rounded-lg">
                        <div className="border-l-[10px] border-primary-violet px-6 h-full pb-2">
                          <div className="py-3">
                            <span className="text-lg text-gray-700 uppercase">
                              Overall Score
                            </span>
                          </div>
                          <div className="flex border-t py-2 gap-x-3">
                            <span className="w-40 flex items-center">
                              Questions Answered
                            </span>
                            <span className="text-gray-500 text-lg font-semibold">
                              {preTestReportData.overallScore.questionsAnswered}
                            </span>
                          </div>
                          <div className="flex border-t py-2 gap-x-3">
                            <span className="w-40 flex items-center">
                              Questions Correct
                            </span>
                            <span className="text-gray-500 text-lg font-semibold ">
                              {preTestReportData.overallScore.questionsCorrect}
                            </span>
                          </div>
                          <div className="flex border-t  py-2 gap-x-3">
                            <span className="w-40 flex items-center">
                              Overall Score
                            </span>
                            <span className="text-gray-500 text-lg font-semibold ">
                              {preTestReportData.overallScore.score}%
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="w-full lg:w-1/2 2xl:w-1/3 xxxl:w-1/4 bg-white shadow-lg border border-gray-100">
                        <div className="border-l-[10px] border-primary-violet px-6 h-full pb-2">
                          <div className="py-3">
                            <span className="text-lg text-gray-700 uppercase">
                              Ranking against peers
                            </span>
                          </div>
                          <div className="flex border-t py-2 gap-x-3">
                            <span className="w-40 flex items-center">
                              Total Students Tested
                            </span>
                            <span className="text-gray-500 text-lg font-semibold">
                              {preTestReportData.ranking.totalStudents}
                            </span>
                          </div>
                          <div className="flex border-t py-2 gap-x-3">
                            <span className="w-40 flex items-center">
                              Average Score
                            </span>
                            <span className="text-gray-500 text-lg font-semibold ">
                              {preTestReportData.ranking.averageScore.toFixed(
                                2
                              )}
                              %
                            </span>
                          </div>
                          <div className="flex border-t  py-2 gap-x-3">
                            <span className="w-40 flex items-center">
                              Your Ranking
                            </span>
                            <span className="text-gray-500 text-lg font-semibold ">
                              {preTestReportData.ranking.studentRank}
                            </span>
                          </div>
                          <div className="flex border-t  py-2 gap-x-3">
                            <span className="w-40 flex items-center">
                              Percentile
                            </span>
                            <span className="text-gray-500 text-lg font-semibold ">
                              {preTestReportData.ranking.percentile}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mb-48 flex flex-col lg:flex-row gap-y-6 lg:gap-y-0 justify-between gap-x-6">
                      <div className="w-full lg:w-1/3 rounded-lg overflow-hidden border border-primary-violet shadow">
                        <div className="bg-green-block flex lg:flex-col xl:flex-row items-center w-full py-3 px-2">
                          <img
                            alt="areas of strength"
                            title="Areas of strength"
                            className="w-16"
                            src={`${Configuration.S3bucketImagePath}/images/student/shared/Areas-of-Strength.png`}
                          />
                          <div className="text-white lg:text-center xl:text-left text-lg uppercase py-3 px-2">
                            AREAS OF STRENGTH
                          </div>
                        </div>
                        <div className="py-3 px-6 flex flex-col divide-y gap-y-2">
                          {preTestReportData.standardEfficiencyReport.strongTopics?.map(
                            (topic) => {
                              return (
                                <>
                                  <div className="flex items-center text-base text-gray-700  pt-2">
                                    {topic}
                                  </div>
                                </>
                              );
                            }
                          )}
                        </div>
                      </div>
                      <div className="w-full lg:w-1/3 rounded-lg overflow-hidden border border-primary-violet shadow">
                        <div className="bg-yellow-block flex lg:flex-col xl:flex-row items-center w-full py-3 px-2">
                          <img
                            alt="some study required"
                            title="Some study required"
                            className="w-16 ml-2 mr-1 lg:mr-0 lg:ml-0 xl:ml-2 xl:mr-1 "
                            src="https://assets-dev.progresslearning.com/images/student/shared/study-required.png"
                          ></img>
                          <div className="text-white lg:text-center xl:text-left text-lg uppercase py-3 px-2">
                            Some study required
                          </div>
                        </div>
                        <div className="py-3 px-6 flex flex-col divide-y gap-y-2">
                          {preTestReportData.standardEfficiencyReport.averageTopics.map(
                            (topic) => {
                              return (
                                <>
                                  <div className="flex items-center text-base text-gray-700  pt-2">
                                    {topic}
                                  </div>
                                </>
                              );
                            }
                          )}
                        </div>
                      </div>
                      <div className="w-full lg:w-1/3 rounded-lg overflow-hidden border border-primary-violet shadow">
                        <div className="bg-red-block flex lg:flex-col xl:flex-row items-center w-full py-3 px-2">
                          <img
                            alt="critical need for study"
                            title="Critical need for study"
                            className="w-16 ml-4 mr-3 lg:ml-0 lg:mr-0 xl:ml-4 xl:mr-3"
                            src="https://assets-dev.progresslearning.com/images/student/shared/Critical-Need-Study.png"
                          ></img>
                          <div className="text-white lg:text-center xl:text-left text-lg uppercase py-3 px-2">
                            CRITICAL NEED FOR STUDY
                          </div>
                        </div>
                        <div className="py-3 px-6 flex flex-col divide-y gap-y-2">
                          {preTestReportData.standardEfficiencyReport.criticalTopics?.map(
                            (topic) => {
                              return (
                                <>
                                  <div className="flex items-center text-base text-gray-700  pt-2">
                                    {topic}
                                  </div>
                                </>
                              );
                            }
                          )}
                        </div>
                      </div>
                    </div>
                  </Fragment>
                )}
                {selectedTab === 2 && postTestReportData && (
                  <Fragment>
                    <div className="flex flex-col lg:flex-row gap-y-6 xl:gap-y-0 gap-x-6 p-3 mb-6 rounded-lg">
                      <div className="w-full lg:w-1/2 2xl:w-1/3 xxxl:w-1/4 bg-white shadow-lg border border-gray-100 rounded-lg">
                        <div className="border-l-[10px] border-primary-violet px-6 h-full pb-2">
                          <div className="py-3">
                            <span className="text-lg text-gray-700 uppercase">
                              Overall Score
                            </span>
                          </div>
                          <div className="flex border-t py-2 gap-x-3">
                            <span className="w-40 flex items-center">
                              Questions Answered
                            </span>
                            <span className="text-green-400 text-lg font-semibold">
                              {
                                postTestReportData.overallScore
                                  .questionsAnswered
                              }
                            </span>
                          </div>
                          <div className="flex border-t py-2 gap-x-3">
                            <span className="w-40 flex items-center">
                              Questions Correct
                            </span>
                            <span className="text-green-400 text-lg font-semibold ">
                              {postTestReportData.overallScore.questionsCorrect}
                            </span>
                          </div>
                          <div className="flex border-t  py-2 gap-x-3">
                            <span className="w-40 flex items-center">
                              Overall Score
                            </span>
                            <span className="text-green-400 text-lg font-semibold ">
                              {postTestReportData.overallScore.score}%
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="w-full lg:w-1/2 2xl:w-1/3 xxxl:w-1/4 bg-white shadow-lg border border-gray-100">
                        <div className="border-l-[10px] border-primary-violet px-6 h-full pb-2">
                          <div className="py-3">
                            <span className="text-lg text-gray-700 uppercase">
                              Ranking against peers
                            </span>
                          </div>
                          <div className="flex border-t py-2 gap-x-3">
                            <span className="w-40 flex items-center">
                              Total Students Tested
                            </span>
                            <span className="text-green-400 text-lg font-semibold">
                              {postTestReportData.ranking.totalStudents}
                            </span>
                          </div>
                          <div className="flex border-t py-2 gap-x-3">
                            <span className="w-40 flex items-center">
                              Average Score
                            </span>
                            <span className="text-green-400 text-lg font-semibold ">
                              {postTestReportData.ranking.averageScore.toFixed(
                                2
                              )}
                              %
                            </span>
                          </div>
                          <div className="flex border-t  py-2 gap-x-3">
                            <span className="w-40 flex items-center">
                              Your Ranking
                            </span>
                            <span className="text-green-400 text-lg font-semibold ">
                              {postTestReportData.ranking.studentRank}
                            </span>
                          </div>
                          <div className="flex border-t  py-2 gap-x-3">
                            <span className="w-40 flex items-center">
                              Percentile
                            </span>
                            <span className="text-green-400 text-lg font-semibold ">
                              {postTestReportData.ranking.percentile}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mb-48 flex flex-col lg:flex-row gap-y-6 lg:gap-y-0 justify-between gap-x-6">
                      <div className="w-full lg:w-1/3 rounded-lg overflow-hidden border border-primary-violet shadow">
                        <div className="bg-green-block flex lg:flex-col xl:flex-row items-center w-full bg-primary-violet py-3 px-2">
                          <img
                            alt="areas of strength"
                            title="Areas of strength"
                            className="w-16"
                            src={`${Configuration.S3bucketImagePath}/images/student/shared/Areas-of-Strength.png`}
                          />
                          <div className="text-white lg:text-center xl:text-left text-lg uppercase py-3 px-2">
                            AREAS OF STRENGTH
                          </div>
                        </div>
                        <div className="py-3 px-6 flex flex-col divide-y gap-y-2">
                          {postTestReportData.standardEfficiencyReport.strongTopics?.map(
                            (topic) => {
                              return (
                                <>
                                  <div className="flex items-center text-base text-gray-700  pt-2">
                                    {topic}
                                  </div>
                                </>
                              );
                            }
                          )}
                        </div>
                      </div>
                      <div className="w-full lg:w-1/3 rounded-lg overflow-hidden border border-primary-violet shadow">
                        <div className="flex lg:flex-col xl:flex-row items-center w-full bg-yellow-block py-3 px-2">
                          <img
                            alt="some study required"
                            title="Some study required"
                            className="w-16 ml-2 mr-1 lg:mr-0 lg:ml-0 xl:ml-2 xl:mr-1 "
                            src="https://assets-dev.progresslearning.com/images/student/shared/study-required.png"
                          ></img>
                          <div className="text-white lg:text-center xl:text-left text-lg uppercase py-3 px-2">
                            Some study required
                          </div>
                        </div>
                        <div className="py-3 px-6 flex flex-col divide-y gap-y-2">
                          {postTestReportData.standardEfficiencyReport.averageTopics?.map(
                            (topic) => {
                              return (
                                <>
                                  <div className="flex items-center text-base text-gray-700  pt-2">
                                    {topic}
                                  </div>
                                </>
                              );
                            }
                          )}
                        </div>
                      </div>
                      <div className="w-full lg:w-1/3 rounded-lg overflow-hidden border border-primary-violet shadow">
                        <div className="bg-red-block flex lg:flex-col xl:flex-row items-center w-full py-3 px-2">
                          <img
                            alt="critical need for study"
                            title="Critical need for study"
                            className="w-16 ml-4 mr-3 lg:ml-0 lg:mr-0 xl:ml-4 xl:mr-3"
                            src="https://assets-dev.progresslearning.com/images/student/shared/Critical-Need-Study.png"
                          ></img>
                          <div className="text-white lg:text-center xl:text-left text-lg uppercase py-3 px-2">
                            CRITICAL NEED FOR STUDY
                          </div>
                        </div>
                        <div className="py-3 px-6 flex flex-col divide-y gap-y-2">
                          {postTestReportData.standardEfficiencyReport.criticalTopics?.map(
                            (topic) => {
                              return (
                                <>
                                  <div className="flex items-center text-base text-gray-700  pt-2">
                                    {topic}
                                  </div>
                                </>
                              );
                            }
                          )}
                        </div>
                      </div>
                    </div>
                  </Fragment>
                )}
                {((!preTestReportData && selectedTab == 1) ||
                  (!postTestReportData && selectedTab == 2)) && (
                  <div className="p-3 text-sm font-normal align-top">
                    No data available
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default connect((state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
})(DiagnosticStrengthWeaknessReport);

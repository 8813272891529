import APIConstant from "../../utils/constant/apiConstant";
import { AxiosHelper } from "../../environment/axiosConfig";

export function GetTeachersByStudentId(token: string, query: any) {
  return AxiosHelper.axiosInstance().get(
    APIConstant.CHAT_API.GET_TEACHER_BY_STUDENT_ID,
    {
      params: query,
    }
  );
}

export function GetTeacherAndStusentsBySchoolId(token: string, query: any) {
  return AxiosHelper.axiosInstance().get(
    APIConstant.CHAT_API.GET_TEACHER_STUDENT_BY_SCHOOL_ID,
    {
      params: query,
    }
  );
}

export function GetSchoolAdminByDistrictId(token: string, query: any) {
  return AxiosHelper.axiosInstance().get(
    APIConstant.CHAT_API.GET_SCHOOLADMIN_BY_DISTRICT_ID,
    {
      params: query,
    }
  );
}

export const GetTeacherChatSettings = (token: string, teacherId: number) => {
  return AxiosHelper.axiosInstance().get(
    APIConstant.CHAT_API.GET_TEACHER_CHATSETTINGS,
    {
      params: {
        teacherId: teacherId,
      },
    }
  );
};

import { useEffect, useState } from "react";
import Constants from "../../../../../utils/constant/constant";
import { PageAccessMode } from "../../../../../model/common/pageAccessMode";
import { ExclamationIcon } from "../../../../../assets/icons";

interface ClassDetail_SettingTabsProps {
  handleClassSetting: (setting: string) => void;
  mode: PageAccessMode;
  studentCount: number;
  subjectCount: number;
  teacherCount: number;
  unMappedStudentCount: number;
  showStudentMapping: boolean;
  showCanvasSection: boolean;
}

const defaultAllClassSettings = [
  Constants.ClassSettings.STUDENT,
  Constants.ClassSettings.TEACHER,
  Constants.ClassSettings.SUBJECTS,
  Constants.ClassSettings.SETTINGS,
  Constants.ClassSettings.STUDENTMAPPING,
];

function ClassDetail_SettingTabs(props: ClassDetail_SettingTabsProps) {
  const {
    handleClassSetting,
    mode,
    studentCount,
    subjectCount,
    teacherCount,
    unMappedStudentCount,
    showStudentMapping,
    showCanvasSection,
  } = props;

  const [selectedClassSetting, setSelectedClassSetting] = useState<string>("");
  const [allClassSettings, setAllClassSetting] = useState<Array<string>>(defaultAllClassSettings);

  const calcClassSettingCount = (setting: string): number => {
    switch (setting) {
      case Constants.ClassSettings.STUDENT:
        return studentCount;
      case Constants.ClassSettings.TEACHER:
        return teacherCount;
      case Constants.ClassSettings.SUBJECTS:
        return subjectCount;
      case Constants.ClassSettings.STUDENTMAPPING:
        return unMappedStudentCount;
      default:
        return 1;
    }
  };

  const calcIsTabWarningStatus = (setting: string): boolean => {
    return (calcClassSettingCount(setting) === 0 && setting !== Constants.ClassSettings.STUDENTMAPPING && mode === PageAccessMode.Edit) ||
    (calcClassSettingCount(setting) > 0 && setting === Constants.ClassSettings.STUDENTMAPPING && mode === PageAccessMode.Edit);
  }

  const calcTabStyle = (setting: string): string => {
    return (
      !modeEditAllowed 
      ? disabledTabStyle 
      : selectedClassSetting === setting 
        ? activeTabStyle 
        : inactiveTabStyle
    ) + (
      calcIsTabWarningStatus(setting)
      ? warningTabStyle 
      : ""
    );
  }

  const classSettingChangeHandler = (setting: string) => {
    if (mode === PageAccessMode.Add) {
      return;
    }
    setSelectedClassSetting(setting);
    handleClassSetting(setting);
  };

  useEffect(() => {
    if (
      props.mode === PageAccessMode.Edit ||
      props.mode === PageAccessMode.Readonly
    ) {
      setSelectedClassSetting(Constants.ClassSettings.STUDENT);
    } else {
      setSelectedClassSetting("");
    }
  }, [mode]);

  useEffect(() => {
    const filteredClassSettings = defaultAllClassSettings.filter(setting => 
      (showStudentMapping || setting !== Constants.ClassSettings.STUDENTMAPPING) &&
      (showCanvasSection || setting !== Constants.ClassSettings.SECTIONS)
    );
      
    setAllClassSetting(filteredClassSettings);
  }, [showStudentMapping, showCanvasSection]);

  const activeTabStyle = "flex border-secondary-teal bg-white text-primary-violet hover:text-dark-violet py-3 tab-box-shadow tab-box-shadow font-medium px-2 sm:px-4 text-center border-t-2 text-sm cursor-pointer";
  const inactiveTabStyle = "flex text-primary-violet text-opacity-60 bg-primary-violet/10 py-[0.6rem] mt-auto transition-all hover:py-3 hover:text-opacity-100 hover:text-primary-violet border-[#dee9f1] font-medium px-2 sm:px-4 text-center border-t-2 text-sm cursor-pointer";
  const disabledTabStyle = "flex text-primary-violet text-opacity-60 bg-primary-violet/10 py-[0.6rem] mt-auto border-[#dee9f1] font-medium px-2 sm:px-4 text-center border-t-2 text-sm cursor-not-allowed";
  const warningTabStyle = " border-2 border-b-0 border-red-500";
  const modeEditAllowed = mode === PageAccessMode.Edit || mode === PageAccessMode.Readonly;

  return (
    <div className="w-full p-4 pb-0 bg-gray-100 rounded-t">
      <nav className="grid grid-cols-2 md:grid-cols-none md:grid-rows-none md:flex md:space-x-2">
        {allClassSettings.map((setting) => (
          <button
            type="button"
            className={calcTabStyle(setting)}
            disabled={mode !== PageAccessMode.Edit && mode !== PageAccessMode.Readonly}
            onClick={() => classSettingChangeHandler(setting)}
          >
            {setting}
            {calcIsTabWarningStatus(setting) && (
              <ExclamationIcon className="w-1 ml-2 pt-1"/>
            )}
            {setting !== Constants.ClassSettings.SETTINGS && (
              <span className="text-[0.6rem] text-white font-medium bg-primary-violet rounded-full px-1.5 py-0 ml-2 mt-1.5">
                {calcClassSettingCount(setting)}
              </span>
            )}
            
          </button>
        ))}
      </nav>
    </div>
  )
}

export default ClassDetail_SettingTabs

import { IAssessmentQuestion } from "../model/interface/assessmentQuestion";
import constant from "./constant/constant";

export function getHtmlParsedQuestions(questions: Array<IAssessmentQuestion>) {
  let questionsWithoutImage: Array<IAssessmentQuestion> = [];
  questions.map((question) => {
    question.questionText = question.questionText
      ?.replace(/<img .*?>/g, "")
      .replace(/<br\s*\/?>/gi, "");
    questionsWithoutImage.push(question);
    return null;
  });

  return questionsWithoutImage;
}

export function isAllowViewAssignmentAssessment(
  userRoleId?: number,
  canView?: boolean,
  activityType?: string,
  assessmentCreatedByUserId?: number,
  userId?: number,
  assessmentSharedTypeId?: number
) {
  if (
    (userId &&
      assessmentCreatedByUserId &&
      userId === assessmentCreatedByUserId) ||
    activityType !== constant.AssignmentActivityType.ASSESSMENT ||
    assessmentSharedTypeId === constant.AssessmentSharedType.ClassShared ||
    (userRoleId === constant.UserRoleId.SchoolAdmin &&
      assessmentSharedTypeId === constant.AssessmentSharedType.SchoolShared) ||
    (userRoleId === constant.UserRoleId.District &&
      assessmentSharedTypeId === constant.AssessmentSharedType.DistrictShared)
  ) {
    return true;
  } else if (canView === null || canView === true) {
    return true;
  }
  return false;
}

export function isAllowViewAssessment(
  userRoleId: number,
  canView?: boolean,
  assessmentSharedTypeId?: number,
  assessmentCreatedByUserId?: number,
  userId?: number
) {
  if (
    (userId &&
      assessmentCreatedByUserId &&
      userId === assessmentCreatedByUserId) ||
    assessmentSharedTypeId === constant.AssessmentSharedType.ClassShared ||
    (userRoleId === constant.UserRoleId.SchoolAdmin &&
      assessmentSharedTypeId === constant.AssessmentSharedType.SchoolShared) ||
    userRoleId === constant.UserRoleId.District 
  ) {
    return true;
  } else if (
    (canView === null || canView === true) &&
    userRoleId === constant.UserRoleId.SchoolTeacher
  ) {
    return true;
  } else if (
    (canView === null || canView === true) &&
    userRoleId === constant.UserRoleId.SchoolAdmin &&
    assessmentSharedTypeId === constant.AssessmentSharedType.DistrictShared
  ) {
    return true;
  }
  return false;
}

export function isAllowViewSettingAssessment(
  userRoleId: number,
  canView?: boolean,
  canDuplicate?: boolean,
  assessmentSharedTypeId?: number,
  assessmentCreatedByUserId?: number,
  userId?: number
) {
  if (
    (userId &&
      assessmentCreatedByUserId &&
      userId === assessmentCreatedByUserId) ||
    assessmentSharedTypeId === constant.AssessmentSharedType.ClassShared ||
    (userRoleId === constant.UserRoleId.SchoolAdmin &&
      assessmentSharedTypeId === constant.AssessmentSharedType.SchoolShared) ||
    (userRoleId === constant.UserRoleId.District &&
      assessmentSharedTypeId === constant.AssessmentSharedType.DistrictShared)
  ) {
    return true;
  } else if (
    assessmentCreatedByUserId != userId &&
    userRoleId === constant.UserRoleId.SchoolTeacher &&
    !canView
  ) {
    return false;
  } else if (
    canDuplicate === false &&
    userRoleId === constant.UserRoleId.SchoolTeacher
  ) {
    return false;
  } else if (
    assessmentSharedTypeId === constant.AssessmentSharedType.DistrictShared &&
    userRoleId !== constant.UserRoleId.District &&
    canDuplicate === false
  ) {
    return false;
  }
  return true;
}

export function isAllowEditAssessment(
  userRoleId: number,
  canDuplicate?: boolean,
  assessmentSharedTypeId?: number,
  assessmentCreatedByUserId?: number,
  userId?: number
) {
  if (
    (userId &&
      assessmentCreatedByUserId &&
      userId === assessmentCreatedByUserId) ||
    assessmentSharedTypeId === constant.AssessmentSharedType.ClassShared ||
    (userRoleId === constant.UserRoleId.SchoolAdmin &&
      assessmentSharedTypeId === constant.AssessmentSharedType.SchoolShared) ||
    (userRoleId === constant.UserRoleId.District &&
      assessmentSharedTypeId === constant.AssessmentSharedType.DistrictShared)
  ) {
    return true;
  } else if (
    assessmentCreatedByUserId != userId &&
    userRoleId === constant.UserRoleId.SchoolTeacher
  ) {
    return true;
  } else if (
    canDuplicate === false &&
    userRoleId === constant.UserRoleId.SchoolTeacher
  ) {
    return false;
  } else if (
    assessmentSharedTypeId === constant.AssessmentSharedType.DistrictShared &&
    userRoleId !== constant.UserRoleId.District &&
    canDuplicate === false
  ) {
    return false;
  }
  return true;
}

export function isAllowViewActionsAssessment(
  userRoleId: number,
  canView: boolean,
  canDuplicate: boolean,
  assessmentSharedTypeId?: number,
  assessmentCreatedByUserId?: number,
  userId?: number
) {
  if (
    (userId &&
      assessmentCreatedByUserId &&
      userId === assessmentCreatedByUserId) ||
    assessmentSharedTypeId === constant.AssessmentSharedType.ClassShared ||
    (userRoleId === constant.UserRoleId.SchoolAdmin &&
      assessmentSharedTypeId === constant.AssessmentSharedType.SchoolShared) ||
    (userRoleId === constant.UserRoleId.District &&
      assessmentSharedTypeId === constant.AssessmentSharedType.DistrictShared)
  ) {
    return true;
  } else if (
    (canView || canDuplicate) &&
    userRoleId === constant.UserRoleId.SchoolTeacher
  ) {
    return true;
  } else if (
    (canView || canDuplicate) &&
    userRoleId === constant.UserRoleId.SchoolAdmin &&
    assessmentSharedTypeId === constant.AssessmentSharedType.DistrictShared
  ) {
    return true;
  }

  return false;
}

export function isAllowAssignOnlyOption(
  userRoleId: number,
  canAssign: boolean,
  assessmentSharedTypeId?: number,
  assessmentCreatedByUserId?: number,
  userId?: number
) {
  if (
    (userId &&
      assessmentCreatedByUserId &&
      userId === assessmentCreatedByUserId) ||
    assessmentSharedTypeId === constant.AssessmentSharedType.ClassShared ||
    (userRoleId === constant.UserRoleId.SchoolAdmin &&
      assessmentSharedTypeId === constant.AssessmentSharedType.SchoolShared) ||
    (userRoleId === constant.UserRoleId.District &&
      assessmentSharedTypeId === constant.AssessmentSharedType.DistrictShared)
  ) {
    return true;
  } else if (
    canAssign === true &&
    userRoleId === constant.UserRoleId.SchoolTeacher
  ) {
    return true;
  } else if (
    canAssign === true &&
    userRoleId === constant.UserRoleId.SchoolAdmin &&
    assessmentSharedTypeId === constant.AssessmentSharedType.DistrictShared
  ) {
    return true;
  }

  return false;
}

export function isAllowHyperlinksToStudentResult(
  userRoleId: number,
  canAssign?: boolean,
  canView?: boolean,
  assessmentSharedTypeId?: number,
  assessmentCreatedByUserId?: number,
  userId?: number
) {
  if (
    (userId &&
      assessmentCreatedByUserId &&
      userId === assessmentCreatedByUserId) ||
    assessmentSharedTypeId === constant.AssessmentSharedType.ClassShared ||
    (userRoleId === constant.UserRoleId.SchoolAdmin &&
      assessmentSharedTypeId === constant.AssessmentSharedType.SchoolShared) ||
    (userRoleId === constant.UserRoleId.District &&
      assessmentSharedTypeId === constant.AssessmentSharedType.DistrictShared)
  ) {
    return true;
  } else if (constant.UserRoleId.SchoolTeacher) {
    if (canView === null || canView) {
      return true;
    }
  } else if (
    userRoleId === constant.UserRoleId.SchoolTeacher &&
    assessmentSharedTypeId === constant.AssessmentSharedType.DistrictShared
  ) {
    if (canView === null || canView) {
      return true;
    }
  }
  return false;
}

export function isDisplayContentViewMessage(
  userRoleId?: number,
  canAssign?: boolean,
  canView?: boolean,
  assessmentSharedTypeId?: number,
  assessmentCreatedByUserId?: number,
  userId?: number
) {
  if (
    (userId &&
      assessmentCreatedByUserId &&
      userId === assessmentCreatedByUserId) ||
    assessmentSharedTypeId === constant.AssessmentSharedType.ClassShared ||
    (userRoleId === constant.UserRoleId.SchoolAdmin &&
      assessmentSharedTypeId === constant.AssessmentSharedType.SchoolShared) ||
    (userRoleId === constant.UserRoleId.District &&
      assessmentSharedTypeId === constant.AssessmentSharedType.DistrictShared)
  ) {
    return false;
  } else if (
    canAssign &&
    canView === false &&
    userRoleId === constant.UserRoleId.SchoolTeacher
  ) {
    return true;
  } else if (
    canAssign &&
    canView === false &&
    userRoleId === constant.UserRoleId.SchoolAdmin &&
    assessmentSharedTypeId === constant.AssessmentSharedType.DistrictShared
  ) {
    return true;
  }

  return false;
}

export function isAllowViewGradeBookClassResult(
  userRoleId?: number,
  canView?: boolean,
  assessmentCreatedByUserId?: number,
  userId?: number,
  assessmentSharedTypeId?: number
) {
  if (
    (userId &&
      assessmentCreatedByUserId &&
      userId === assessmentCreatedByUserId) ||
    assessmentSharedTypeId === constant.AssessmentSharedType.ClassShared ||
    (userRoleId === constant.UserRoleId.SchoolAdmin &&
      assessmentSharedTypeId === constant.AssessmentSharedType.SchoolShared) ||
    (userRoleId === constant.UserRoleId.District &&
      assessmentSharedTypeId === constant.AssessmentSharedType.DistrictShared)
  ) {
    return true;
  } else if (canView === null || canView === true) {
    return true;
  }
  return false;
}

export function isAllowAssignmentAssessmentPreview(
  userRoleId?: number,
  canAssign?: boolean,
  canView?: boolean,
  assessmentSharedTypeId?: number,
  assessmentCreatedByUserId?: number,
  userId?: number,
  activityType?: string
) {
  if (
    (userId &&
      assessmentCreatedByUserId &&
      userId === assessmentCreatedByUserId) ||
    assessmentSharedTypeId === constant.AssessmentSharedType.ClassShared ||
    activityType !== constant.AssignmentActivityType.ASSESSMENT ||
    (userRoleId === constant.UserRoleId.SchoolAdmin &&
      assessmentSharedTypeId === constant.AssessmentSharedType.SchoolShared) ||
    (userRoleId === constant.UserRoleId.District &&
      assessmentSharedTypeId === constant.AssessmentSharedType.DistrictShared)
  ) {
    return true;
  } else if (
    assessmentSharedTypeId === constant.AssessmentSharedType.SchoolShared &&
    canAssign &&
    !canView
  ) {
    return false;
  } else if (
    assessmentSharedTypeId === constant.AssessmentSharedType.DistrictShared &&
    canAssign &&
    !canView
  ) {
    return false;
  }
  return true;
}

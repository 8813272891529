import { AxiosResponse } from "axios";
import { AxiosHelper } from "../../environment/axiosConfig";
import APIConstant from "../../utils/constant/apiConstant";

export const getWorksheetsBySubject = (
    subjectId: number,
    title:string,
  ): Promise<AxiosResponse<any>> => {
    return AxiosHelper.axiosInstance().get<any>(
      APIConstant.ClassActivity_API.SubjectWorksheets,
      {
        params: {
            subjectId: subjectId,
            title:title
        },
      }
    );
  };

  export const previewWorksheet = (
    id:number,
    fileName:string
  ) => {
    return AxiosHelper.axiosInstance().get(APIConstant.ClassActivity_API.PreviewWorksheet,
      {
      params:{
        id:id,
        worksheetFile:fileName
      },
    });
  };
  
import sanitizeHtml from 'sanitize-html';

export const sanitizeNoteContent = (text?: string): string => {  
    if (!text) return "";
  const allowedAttributes = { ...sanitizeHtml.defaults.allowedAttributes };
  allowedAttributes['a'] = (allowedAttributes['a'] || []).concat('class');

  return sanitizeHtml(text, {
    allowedTags: sanitizeHtml.defaults.allowedTags.concat([ 'img', ]),
    allowedAttributes: allowedAttributes
  });
}
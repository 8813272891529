import APIConstant from "../../utils/constant/apiConstant";
import { AxiosHelper } from "../../environment/axiosConfig";

export function GetStudentIdsClassId(token: string, classId: number) {
  return AxiosHelper.axiosInstance().get(
    APIConstant.CHAT_API.GET_STUDENTIDS_BY_CLASS_ID,
    {
      params: { ClassId: classId },
    }
  );
}

export function GetClassesByStudentId(token: string, studentId: number) {
  return AxiosHelper.axiosInstance().get(
    APIConstant.CHAT_API.GET_CLASSES_BY_STUDENT_ID.replace(
      "{:studentId}",
      studentId.toString()
    ),
    {}
  );
}

import classNames from "classnames";
import { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { getStudentSubject } from "../../../../../api/student/studentSubjectApi";
import { Configuration } from "../../../../../environment/setup";
import { ISubject } from "../../../../../model/interface/subject";
import Profile from "../../../../../model/users/profile";
import { UserContextState } from "../../../../../redux/actions/userContextAction";
import constant from "../../../../../utils/constant/constant";

interface IStudyPlanSubjectProps {
  userContext?: UserContextState;
  profile?: Profile;
  isOpened: boolean;
  onSubjectSelected: (subjectId: number, subjectName: string) => void;
  selectedSubjectId?: number;
}
function StudyPlanSubject(props: IStudyPlanSubjectProps) {
  const [subjects, setSubjects] = useState<Array<ISubject>>([]);
  const [contentAreas, setContentAreas] = useState<Array<any>>([]);
  const [selectedSubject, setSelectedSubject] = useState<ISubject>();
  const elaIcon: string = `${Configuration.S3bucketImagePath}/Icons/ContentArea/reading.svg`;
  const mathIcon: string = `${Configuration.S3bucketImagePath}/Icons/ContentArea/math.svg`;
  const scienceIcon: string = `${Configuration.S3bucketImagePath}/Icons/ContentArea/science.svg`;
  const socialStudyIcon: string = `${Configuration.S3bucketImagePath}/Icons/ContentArea/social-science.svg`;
  useEffect(() => {
    if (subjects.length === 0 && props.isOpened === true) loadSubjects();
  }, [props.isOpened]);

  function loadSubjects() {
    getStudentSubject(props.userContext?.userId ?? 0).then((d) => {
      setSubjects(d.data);
      if (selectedSubject === undefined && d.data.length > 0) {
        const defaultSubject =
          d.data.filter((s) => s.subjectId === props.selectedSubjectId)[0] ??
          d.data[0];
        setSelectedSubject(defaultSubject);
        props.onSubjectSelected(
          defaultSubject.subjectId,
          props.profile?.plLanguageId === constant.Languages.Spanish &&
            defaultSubject.spanishName
            ? defaultSubject.spanishName
            : defaultSubject.name
        );
        var tempContentArea: Array<any> = [];
        d.data.forEach((sub) => {
          if (
            tempContentArea.findIndex(
              (t) => t.contentAreaId === sub.contentAreaId
            ) === -1
          ) {
            tempContentArea.push({
              contentAreaId: sub.contentAreaId,
              contentAreaName: sub.contentAreaName,
              spanishContentAreaName: sub.spanishContentAreaName,
            });
          }
        });
        setContentAreas(tempContentArea);
      }
    });
  }
  return (
    <Fragment>
      <div className="block bg-[#34D9A5] p-1 rounded-md">
        <div className="grid lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-1">
          {contentAreas !== undefined &&
            contentAreas.map((c) => {
              return (
                <>
                  <div className="-mt-5 pr-3">
                    <span className="py-2 pl-2 pr-4 flex text-gray-700 font-medium text-base block bg-[#FFD224] border-4 border-[#12B481] rounded-xl mr-3 ml-3 relative top-7 z-10">
                      {c.contentAreaName === "English" && (
                        <img
                          className="w-6 mr-2"
                          srcSet={elaIcon}
                          alt="Content Area Icon"
                          title="English language arts"
                        />
                      )}

                      {c.contentAreaName === "Math" && (
                        <img
                          className="w-6 mr-2"
                          srcSet={mathIcon}
                          alt="Content Area Icon"
                          title="Math"
                        />
                      )}

                      {c.contentAreaName === "Science" && (
                        <img
                          className="w-6 mr-2"
                          srcSet={scienceIcon}
                          alt="Content Area Icon"
                          title="Science"
                        />
                      )}

                      {c.contentAreaName === "Social Studies" && (
                        <img
                          className="w-6 mr-2"
                          srcSet={socialStudyIcon}
                          alt="Content Area Icon"
                          title="Social studies"
                        />
                      )}
                      {props.profile?.plLanguageId ===
                        constant.Languages.Spanish && c.spanishContentAreaName
                        ? c.spanishContentAreaName
                        : c.contentAreaName}
                    </span>
                    <div className="grid grid-cols-1 gap-3 px-2.5 pb-4 mb-3  bg-white border-[#34D9A5] border-[3px] border-[#12B481] rounded-xl w-full relative pt-10">
                      {subjects
                        .filter((t) => t.contentAreaId === c.contentAreaId)
                        .map((s) => {
                          return (
                            <>
                              <button
                                key={s.subjectId}
                                className={classNames(
                                  "flex px-2 py-1.5 border-[3px] cursor-pointer items-center text-left rounded-lg",
                                  selectedSubject?.subjectId === s.subjectId
                                    ? "bg-[#12B481] text-white hover:bg-opacity-90 border-[#F9D228] border-dashed"
                                    : " border-[#12B481] opacity-70 hover:opacity-100"
                                )}
                                onClick={() => {
                                  props.onSubjectSelected(
                                    s.subjectId,
                                    props.profile?.plLanguageId ===
                                      constant.Languages.Spanish &&
                                      s.spanishName
                                      ? s.spanishName
                                      : s.name
                                  );
                                  setSelectedSubject(s);
                                }}
                              >
                                {props.profile?.plLanguageId ===
                                constant.Languages.Spanish
                                  ? s.spanishName ?? s.name
                                  : s.name}
                              </button>
                            </>
                          );
                        })}
                    </div>
                  </div>
                </>
              );
            })}
        </div>
      </div>
    </Fragment>
  );
}
const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(StudyPlanSubject);

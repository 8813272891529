import { Fragment, useEffect, useState } from "react";
import { getAlienArenaSessionById } from "../../../../api/teacher/alienArena";
import { XIcon } from "@heroicons/react/outline";
import Moment from "moment";
interface IParticipants {
  teamName: string;
  students: [];
}
interface IStudents {
  studentName: string;
}
function SessionModal(props: any) {
  const handleOnClose = () => {
    props.setShowSessionModal(false);
  };
  const [alienArenaSession, setAlienArenaSession] = useState<any>({});
  const [participants, setParticipants] = useState<Array<IParticipants>>([]);
  const [studends, setStudents] = useState<Array<IStudents>>([]);
  const getAlienArenaSessionData = () => {
    getAlienArenaSessionById(props.alienArenaId).then((res) => {
      setAlienArenaSession(res.data);

      setParticipants(getPraticipants(res.data));
      setStudents(getCombinedPraticipants(res.data));
    });
  };
  const getPraticipants = (res: any) => {
    var arr: any = [];

    for (const [key, value] of Object.entries(res?.alienArenaTeams!)) {
      if (key !== "") {
      }
      var teamObject: any = value;
      var students: any = [];
      if (teamObject.name !== "Students") {
        for (let i = 0; i < teamObject.items?.length; i++) {
          students.push(teamObject.items[i].studentName);
        }
        arr.push({ teamName: teamObject.name, students: students });
      }
    }
    return arr;
  };
  const getCombinedPraticipants = (res: any) => {
    var students: any = [];

    for (const [key, value] of Object.entries(res?.alienArenaTeams!)) {
      if (key !== "") {
      }
      var teamObject: any = value;

      if (teamObject.name !== "Students") {
        for (let i = 0; i < teamObject.items?.length; i++) {
          students.push({ studentName: teamObject.items[i].studentName });
        }
      }
    }
    return students;
  };
  const getRandomColor = () => {
    var letters = "0123456789ABCDEF";
    var color = "#";
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  useEffect(() => {
    getAlienArenaSessionData();
  }, [props.alienArenaId]);
  return (
    <Fragment>
      <div
        className={`fixed inset-0 z-40 flex items-center bg-black bg-opacity-50 sm:items-center sm:justify-center ${
          !props.showSessionModal ? "hidden" : ""
        }`}
      >
        <div
          className="w-full flex flex-col justify-between overflow-hidden h-auto max-h-80percent bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:max-w-5xl"
          role="dialog"
        >
          <header>
            {/* Header */}
            <div className="px-4 py-4 bg-gray-50 sm:px-4">
              <div className="flex items-start justify-between space-x-3">
                <div className="space-y-1">
                  <div className="text-lg text-gray-900">
                    Alien Arena Session
                  </div>
                </div>
                <div className="h-7 flex items-center">
                  <button
                    type="button"
                    className="text-gray-400 hover:text-gray-500"
                    onClick={() => handleOnClose()}
                  >
                    <span className="sr-only">Close panel</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
          </header>
          <div
            className={`${
              alienArenaSession.length === 0
                ? "px-6 my-4"
                : "w-full relative overflow-auto px-4 py-3"
            }`}
          >
            <div className="flex flex-col w-full mb-3 bg-blue-50 space-y-1 rounded-lg p-4">
              <div className="grid grid-cols-12 space-y-1 w-full">
                <label
                  htmlFor="user_name"
                  className="block text-sm text-gray-500 col-span-2"
                >
                  Session Name
                </label>
                <div className="col-span-10">
                  {alienArenaSession.alienArena?.sessionName}
                </div>
                <label
                  htmlFor="user_name"
                  className="block text-sm text-gray-500 col-span-2"
                >
                  Class
                </label>
                <div className="col-span-10">
                  {alienArenaSession.alienArena?.className}
                </div>
              </div>

              <div className="grid grid-cols-12 space-y-1 w-full">
                <label
                  htmlFor="user_name"
                  className="block text-sm text-gray-500 col-span-2"
                >
                  Subject
                </label>
                <div className="col-span-10">
                  {alienArenaSession.alienArena?.subjectName}
                </div>
                <label
                  htmlFor="user_name"
                  className="block text-sm text-gray-500 col-span-2"
                >
                  Date
                </label>
                <div className="col-span-10">
                  {Moment(alienArenaSession.alienArena?.startDate).format(
                    "MM-DD-yyyy HH:MM:SS A"
                  )}
                </div>
              </div>
            </div>
            <table className="table w-full">
              <thead>
                <tr className="bg-primary-violet  text-white">
                  <th className="text-left p-3 text-sm font-normal">
                    Standard Id
                  </th>
                  <th className="text-left p-3 text-sm font-normal">
                    Standard Name
                  </th>
                  <th className="text-left p-3 text-sm font-normal">
                    Total Questions
                  </th>
                  <th className="text-left p-3 text-sm font-normal">
                    Total Correct
                  </th>
                  <th className="text-left p-3 text-sm font-normal">
                    % Correct
                  </th>
                </tr>
              </thead>
              <tbody>
                {alienArenaSession.alienArena &&
                  alienArenaSession?.alienArena?.standards &&
                  alienArenaSession?.alienArena?.standards?.length > 0 &&
                  alienArenaSession?.alienArena?.standards?.map(
                    (stand, index) => {
                      return (
                        <Fragment>
                          <tr
                            className={`hover:bg-primary-violet/20 transition ease-in-out duration-300 ${
                              index % 2 === 0
                                ? "bg-white"
                                : "bg-primary-violet/10"
                            }`}
                          >
                            <td className="text-sm text-left p-3 ">
                              {stand.code}
                            </td>
                            <td className="text-sm p-3 ">{stand.name}</td>
                            <td className="text-sm p-3 ">
                              {stand.totalQuestions}
                            </td>
                            <td className="text-sm p-3">
                              {stand.totalQuestions}
                            </td>
                            <td className="text-sm p-3 ">
                              {stand.totalQuestions}
                            </td>
                          </tr>
                        </Fragment>
                      );
                    }
                  )}
              </tbody>
            </table>
            <div className="mt-4 mb-2 pt-4">Participants</div>
            <table className="table w-full">
              <thead>
                <tr className="bg-primary-violet text-white">
                  <th className="text-left p-3 text-sm font-normal w-40">
                    Team Name
                  </th>
                  <th className="text-left  p-3 text-sm font-normal">
                    Students
                  </th>
                </tr>
              </thead>
              <tbody>
                {participants &&
                  participants?.length > 0 &&
                  participants?.map((participant, index) => {
                    return (
                      <Fragment key={index}>
                        <tr
                          className={`hover:bg-primary-violet/20 transition ease-in-out duration-300 ${
                            index % 2 === 0
                              ? "bg-white"
                              : "bg-primary-violet/10"
                          }`}
                        >
                          <td className="text-sm p-3 ">
                            {participant.teamName}
                          </td>
                          <td className="text-sm p-3 ">
                            {participant.students.length > 0 &&
                              participant.students.map((ele, index) => {
                                return (
                                  <div
                                    key={index}
                                    className="w-auto inline-block py-0.5 px-1.5 text-xs text-white rounded-lg"
                                    style={{ background: getRandomColor() }}
                                  >
                                    {ele}
                                  </div>
                                );
                              })}
                          </td>
                        </tr>
                      </Fragment>
                    );
                  })}
              </tbody>
            </table>
            {alienArenaSession.alienArena &&
              alienArenaSession?.alienArena?.standards &&
              alienArenaSession?.alienArena?.standards?.length > 0 &&
              alienArenaSession?.alienArena?.standards?.map((stand, index) => {
                return (
                  <div key={index}>
                    <div className="mt-4 mb-2 pt-4">
                      Topic Summary: {stand.name}
                    </div>
                    <table className="table w-full">
                      <thead className="mb-5">
                        <tr className="bg-primary-violet  text-white py-3 px-3 overflow-hidden">
                          <th className="text-left p-3 text-sm font-normal">
                            Student Name
                          </th>
                          <th className="text-left  p-3 text-sm font-normal">
                            Standard Id
                          </th>
                          <th className="text-left  p-3 text-sm font-normal">
                            Standard Code
                          </th>
                          <th className="text-left  p-3 text-sm font-normal">
                            Total Answered
                          </th>
                          <th className="text-left  p-3 text-sm font-normal">
                            Total Correct
                          </th>
                          <th className="text-left p-3 text-sm font-normal">
                            % Correct
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {studends?.length > 0 &&
                          studends.map((participant, index) => (
                            <tr
                              key={index}
                              className={`hover:bg-primary-violet/20 transition ease-in-out duration-300 ${
                                index % 2 === 0
                                  ? "bg-white"
                                  : "bg-primary-violet/10"
                              }`}
                            >
                              <td className="text-sm text-left p-3 ">
                                {participant.studentName}
                              </td>
                              <td className="text-sm mt-5 p-3 ">
                                {stand.code}
                              </td>
                              <td className="text-sm mt-5 p-3 ">
                                {stand.name}
                              </td>
                              <td className="text-sm mt-5 p-3 ">
                                {stand.totalQuestions}
                              </td>
                              <td className="text-sm mt-5 p-3 ">
                                {stand.totalQuestions}
                              </td>
                              <td className="text-sm mt-5 p-3 ">
                                {stand.totalQuestions}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                );
              })}
          </div>
          <footer className="flex items-center justify-end px-6 py-4 sm:flex-row bg-gray-50 dark:bg-gray-800">
            {/* <!-- Action Buttons -->  */}
            <div className="flex-shrink-0">
              <div className="space-x-3 flex justify-end">
                <button
                  className="align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 rounded-lg text-sm text-gray-600 border-gray-300 border dark:text-gray-400 focus:outline-none active:bg-transparent hover:border-gray-500 focus:border-gray-500 active:text-gray-500 focus:shadow-outline-gray sm:w-auto"
                  type="button"
                  onClick={() => {
                    handleOnClose();
                  }}
                >
                  Ok
                </button>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </Fragment>
  );
}
export default SessionModal;

import React from "react";
import DashboardRegistry from "./dashboardRegistry";

const DashboardComposer = (item: any, props: any) => {
  if (typeof DashboardRegistry[item] !== "undefined") {
    return React.createElement(DashboardRegistry[item], props);
  }
  return React.createElement(() => <div></div>);
};

export default DashboardComposer;

import { CSSProperties, useEffect, useState } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import {ISelectElement, ISelectElementAssessmentComparisonReport} from "../../../model/interface/selectElement";
import constant from "../../../utils/constant/constant";
import { Info, LogoPLShootingStarIcon } from "../../../assets/icons";
import Asterisk from "../asterisk";

interface AssessmentProps {
  label?: string;
  infoTitle?: string;
  defaultText?: string;
  selectedItems: Array<ISelectElementAssessmentComparisonReport>;
  setSelectedAssessment: (selectedItems: Array<ISelectElementAssessmentComparisonReport>) => void;
  isMulti?: boolean;
  isRequired?: boolean;
  subjectTypeId?: number;
  themeColor?: string;
  assessments?: Array<any>;
  hideAdditionalSubjects?: boolean;
  isDisabled?: boolean;
}
function Assessment(props: AssessmentProps & any) {
  const plAssessmentLabel: string = "Premium Assessments";
  const {
    infoTitle,
    selectedItems,
    setSelectedAssessment,
    label,
    defaultText,
  } = props;
  const [assessments, setAssessments] = useState<Array<any>>([]);
  const groupStyles = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    fontSize: 14,
  };
  const groupBadgeStyles: CSSProperties = {
    backgroundColor: "#EBECF0",
    borderRadius: "2em",
    color: "#172B4D",
    display: "inline-block",
    fontSize: 14,
    fontWeight: "normal",
    lineHeight: "1",
    minWidth: 1,
    padding: "0.16666666666667em 0.5em",
    textAlign: "center",
  };

  const colourStyles = {
    option: (provided, state) => ({
      ...provided,
      color:
        state.label === "My Subjects" || state.label === "Additional Subjects"
          ? props.themeColor ?? "#992883"
          : "default",
    }),
  };

  const categorizeOptions = async (response: any) => {
    const results = response;
    const additionalRoles: Array<number> = [
      constant.UserRoleId.SchoolAdmin,
      constant.UserRoleId.SchoolTeacher,
      constant.UserRoleId.District,
    ];

    const showAdditionalAssessment: boolean = additionalRoles.some(
      (r) => r === props.userContext.roleId
    );
    const myAssessments: Array<any> = [];
    const additionalAssessments: Array<any> = [];

    const additionalAssessmentCount = results.filter(
      (r) => r.isAdditional === true
    ).length;
    var options = getSortedAssessments(results, false);

    var additionalOptions = getSortedAssessments(results, true);

    var plPremiumAssessments: Array<any> = [];
    constant.cannedTestTypeIds.map((id: number) => {
      var premiumAssessments = getCannedTests(results, id);
      plPremiumAssessments.push(...premiumAssessments);
    });

    if (plPremiumAssessments.length > 0) {
      myAssessments.push({
        label: plAssessmentLabel,
        options: plPremiumAssessments,
      });
    }

    myAssessments.push({
      label: "My Assessments",
      options: options,
    });

    additionalAssessments.push({
      label: "Additional Assessments",
      options: additionalOptions,
    });

    let assessmentOptions = myAssessments;
    if (showAdditionalAssessment && additionalAssessmentCount > 0) {
      assessmentOptions.push(...additionalAssessments);
    }
    //let cannedTestOptions = assessmentOptions;
    setAssessments(assessmentOptions);
  };

  const getSortedAssessments = (results: any, isAdditional: boolean) => {
    var assessments: Array<ISelectElement> = results
      .filter(
        (r) => r.isAdditional === isAdditional && r.diagTestTypeId == null
      )
      .map((values: any) => {
        return {
          label: isAdditional
            ? formatAdditionalAssessmentName(values)
            : values?.name,
          value: values?.id,
        };
      });
    return assessments;
  };

  const getCannedTests = (results: any, diagTestTypeIds: number) => {
    var tests: Array<ISelectElement> = results
      .filter((r) => r.diagTestTypeId === diagTestTypeIds)
      .map((values: any) => {
        return {
          label: values?.name,
          value: values?.id,
          cannedTestTypeId: values?.diagTestTypeId,
        };
      });
    return tests;
  };

  const formatAdditionalAssessmentName = (value) => {
    return (
      value?.name +
      " - " +
      value?.code +
      " - " +
      value?.authorLastName +
      ", " +
      value?.authorFirstName
    );
  };

  function onChange(e: any) {
    let selectedValues: Array<ISelectElementAssessmentComparisonReport> = e;
    if (selectedValues && selectedValues.length > 0) {
      const lastSelectedValue = selectedValues[selectedValues.length - 1];
      if (lastSelectedValue.value === "") {
        selectedValues = [lastSelectedValue];
      } else if (
        selectedValues.some((r) => r.value === "") &&
        selectedValues.some((r) => r.value !== "")
      ) {
        selectedValues = selectedValues.filter((r) => r.value !== "");
      }
    } else if (typeof e === "object" && e !== null && e.value) {
      selectedValues = [e];
    } else {
      selectedValues = [
        { label: defaultText ?? "Select Assessment", value: "0" },
      ];
    }
    setSelectedAssessment(selectedValues);
    console.log("onChange", selectedValues);
  }

  const formatGroupLabel = (data: any) => {
    return (
      <div style={groupStyles}>
        <span className="flex gap-x-1">
          {data.label == plAssessmentLabel && (
            <LogoPLShootingStarIcon></LogoPLShootingStarIcon>
          )}
          {data.label}
        </span>
        <span style={groupBadgeStyles}>{data.options.length}</span>
      </div>
    );
  };

  useEffect(() => {
    if (props.assessments) {
      categorizeOptions(props.assessments);
    }
  }, [props.assessments]);

  return (
    <>
      <div className="nested-optgroup">
        <div>
          <label className="block text-sm text-gray-500">
            {label ? label : "Assessment"} {props.isRequired && <Asterisk />}
          </label>
          {infoTitle && <Info title={infoTitle} />}
        </div>
        <div className="mt-1">
          <Select
            value={selectedItems}
            options={assessments}
            isClearable
            isSearchable
            formatGroupLabel={formatGroupLabel}
            formatOptionLabel={(value: ISelectElementAssessmentComparisonReport) => {
              if (value.cannedTestTypeId === 1) {
                return "Diagnostic Pre-Test";
              }
              if (value.cannedTestTypeId === 2) {
                return "Diagnostic Post-Test";
              }
              return value.label;
            }}
            getOptionValue={(value) => {
              return value.value;
            }}
            onChange={onChange}
            isMulti={false}
            closeMenuOnSelect={true}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary: props.themeColor ?? "#041E42",
              },
            })}
            styles={colourStyles}
            isDisabled={props.isDisabled ?? false}
          />
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(Assessment);

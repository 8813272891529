import { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { IBreadcrumb } from "../../../../../model/common/breadcrumb";
import { default as Constant } from "../../../../../utils/constant/constant";
import Breadcrumb from "../../../teacher/shared/breadcrumb";
import RouteConstant from "../../../../../utils/constant/routeConstant";
import { useTranslation } from "react-i18next";
import { Select } from "@windmill/react-ui";
import { ISchool } from "../../../../../model/interface/school";
import { getSchoolByDistrictId } from "../../../../../api/teacher/school";
import { ISelectElement } from "../../../../../model/interface/selectElement";
import DatePicker from "react-datepicker";
import PageSize from "../../../../shared/pagination/pageSize";
import Pagination from "../../../../shared/pagination/pagination";

import {
  exportActivitySearchReportExcel,
  getActivitySearchReport,
  getAllClassesBySubject,
  getClassStudentByClassId,
} from "../../../../../api/teacher/teacher";
import SelectGroup from "react-select";
import Asterisk from "../../../../shared/asterisk";
import NoData from "../../../../shared/noData";
import Loader from "../../../../shared/loader";
import moment from "moment";
import {
  DEFAULT_PAGE_NUMBER,
  DEFAULT_PAGE_SIZE,
} from "../../../../../utils/pagingConstant";
import {
  assignmentResultRedirect,
  liftoffDiagnosticResultRedirect,
  myGalaxyStandardResultRedirect,
  studyPlanPracticeResultRedirect,
} from "../studentResultRedirection";
import { getClassesBySchoolAccountId } from "../../../../../api/manageUser/class";
import userApi from "../../../../../api/userApi";
import Subject from "../../../../shared/dropdowns/subject";
import { isAllowViewAssessment } from "../../../../../utils/assessmentHelper";
import ClassDropDown from "../../shared/classDropDown";
import ITeacherClass from "../../../../../model/interface/teacherclass";
import { getEndDate, getStartDate } from "../../../../../utils/dateHelper";
import { SchoolYearDropDown } from "../../../schoolYearDropdown";
import { SortingOrder } from "../../../../../types/type";
import SortTableHeader from "../../../../shared/sortTableHeader";

function StudentActivitySearchReport(props) {
  const { t } = useTranslation();
  const [isDistrictUser] = useState<boolean>(
    props.userContext.roleId === Constant.UserRoleId.District
  );
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [isLoadingClasses, setIsLoadingClasses] = useState<boolean>(false);
  const [pageNumber, setPageNumber] = useState(DEFAULT_PAGE_NUMBER);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [resetPageNumber, setResetPageNumber] = useState<boolean>(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [selectedSchool, setSelectedSchool] = useState<number>(0);
  const [selectedSubject, setSelectedSubject] = useState<any>(0);
  const [selectedClass, setSelectedClass] = useState<number>(0);
  const [schools, setSchools] = useState<ISchool[]>();
  const [classes, setClasses] = useState<ITeacherClass[]>();
  const [selectedClasses, setSelectedClasses] = useState<Array<ISelectElement>>(
    [{ label: "All Classes", value: "0" }]
  );
  const [activityReport, setActivityReport] = useState<any>();
  const [selectedActivities, setSelectedActivities] = useState<
    Array<ISelectElement>
  >([]);
  const [selectedStudents, setSelectedStudents] = useState<
    Array<ISelectElement>
  >([]);
  const [selectedTeachers, setSelectedTeachers] = useState<
    Array<ISelectElement>
  >([]);

  const [teachers, setTeachers] = useState<Array<any>>();
  const [students, setStudents] = useState<Array<any>>();
  const [selectedStartDate, setSelectedStartDate] = useState<Date | null>(null);
  const [selectedEndDate, setSelectedEndDate] = useState<Date | null>(null);
  const [isInvalidStartDate, setIsInvalidStartDate] = useState<boolean>(false);
  const [isInvalidEndDate, setIsInvalidEndDate] = useState<boolean>(false);
  const [selectedSubjectOption, setSelectedSubjectOption] =
    useState<ISelectElement>({ label: "All Subjects", value: "0" });
  const [isRetriesOnly, setRetriesOnly] = useState<boolean>(false);
  const [selectedSchoolAccountId, setSelectedSchoolAccountId] =
    useState<number>(0);
  const [selectedSchoolYearId, setSelectedSchoolYearId] = useState<number>();
  const [resetSchoolYear, setResetSchoolYear] = useState<boolean>(false);
  const [activeSortColumn, setActiveSortColumn] = useState("");
  const [orderType, setOrderType] = useState<SortingOrder>("desc");

  const activities: any = [
    {
      label: "All Activities",
      value: 0,
    },
    {
      label: "Assessments",
      value: 1,
    },
    {
      label: "Assignments",
      value: 2,
    },
    {
      label: "Progress Learning Diagnostics",
      value: 3,
    },
    {
      label: "Study Plan",
      value: 4,
    },
  ];
  const breadcrumbItems = () => {
    const items: Array<IBreadcrumb> = [
      { name: t("Breadcrumb.Home"), url: RouteConstant.ROUTE_DASHBOARD },
      { name: t("Breadcrumb.Reports"), url: RouteConstant.REPORTS },
      { name: t("Breadcrumb.StudentActivitySearchReport"), url: "" },
    ];

    return items;
  };
  const maxDate = new Date();
  useEffect(() => {
    setSelectedStartDate(null);
    setSelectedEndDate(null);
    getSchools();
  }, []);

  useEffect(() => {
    handleClassMenuClose();
  }, [selectedClass]);

  useEffect(() => {
    if (selectedSchoolYearId) {
      setResetSchoolYear(false);
      if (selectedSchool > 0) {
        setSelectedClasses([]);
        getClasses(selectedSchool, getSchoolAccountId(schools, selectedSchool));
      }
    }
  }, [selectedSchoolYearId]);

  useEffect(() => {
    setPageNumber(DEFAULT_PAGE_NUMBER);
    setResetPageNumber(!resetPageNumber);
  }, [orderType, activeSortColumn]);

  const getSchools = async (subjectId = 0) => {
    const resp = await getSchoolByDistrictId(
      props.userContext?.districtId ?? 0,
      subjectId
    );
    if (resp?.data?.length) {
      let schooleResult: any = [];
      resp?.data?.map((school: any) => {
        if (!schooleResult.find((s) => s.schoolId === school.schoolId))
          schooleResult.push(school);
      });
      setSchools(schooleResult);
      if (!isDistrictUser) {
        setSelectedSchool(props.userContext.schoolId);
        getClasses(
          props.userContext.schoolId,
          getSchoolAccountId(schooleResult, props.userContext.schoolId)
        );
        getTeachers(
          props.userContext.schoolId,
          getSchoolAccountId(schooleResult, props.userContext.schoolId)
        );
        getStudents(
          props.userContext.schoolId,
          0,
          0,
          getSchoolAccountId(schooleResult, props.userContext.schoolId)
        );
      } else {
        setSelectedSchool(0);
      }
    }
  };

  const getClasses = async (schoolId?: number, schoolAccountId?: number) => {
    try {
      setIsLoadingClasses(true);
      if (isDistrictUser) {
        const resp = await getClassesBySchoolAccountId(
          schoolAccountId,
          selectedSchoolYearId ?? props.profile.currentSchoolYear.schoolYearId
        );
        if (resp?.data?.length) {
          const options: Array<ITeacherClass> = resp.data.map((values: any) => {
            return {
              classId: values?.classId,
              displayName: values?.displayName,
              teacherFirstName: values?.primaryTeacherFirstName,
              teacherLastName: values?.primaryTeacherLastName,
              isAdditionalClass: true,
              isArchived: values?.isArchived
            };
          });
          setClasses(options);
        }
      } else {
        const resp = await getAllClassesBySubject(
          props.userContext.userId,
          props.userContext.roleId,
          null,
          selectedSchoolYearId ?? props.profile.currentSchoolYear.schoolYearId,
          !isDistrictUser ? props.userContext.schoolId : schoolId
        );
        if (resp?.data?.data?.length) {
          setClasses(resp.data.data);
        } else {
          setClasses([]);
        }
      }
    } finally {
      setIsLoadingClasses(false);
    }
  };

  const getDisplayName = (lastName: any, firstName: any, separator: any) => {
    let name: any = "";
    if (lastName || firstName) name = separator;
    if (lastName) name = name + lastName;
    if (lastName && firstName) name = name + ", " + firstName;
    if (!lastName && firstName) name = name + firstName;
    return name;
  };

  const getTeacherDisplayName = (
    lastName: string | null,
    firstName: string | null,
    separator: string,
    className: string | null
  ) => {
    let name = "";
    if (
      lastName !== null &&
      firstName !== null &&
      className !== null &&
      lastName.length > 0 &&
      firstName.length > 0
    ) {
      name = getDisplayName(lastName, firstName, separator);
      name = name + " / " + className;
    }
    return name ?? "";
  };

  const formatDuration = (durationInSeconds: number): string => {
    var date = new Date(0);
    date.setSeconds(durationInSeconds); // specify value for SECONDS here
    var timeString = date.toISOString().substring(11, 19);
    return timeString;
  };

  const getTeachers = async (schoolId: number, schoolAccountId?: number) => {
    var query: any = {
      userId: 0,
      firstName: "",
      lastName: "",
      username: "",
      gradeId: 0,
      classId: 0,
      studentId: "",
      roleId: Constant.UserRoleId.SchoolTeacher,
      schoolAccountId: schoolAccountId,
      OrderByColumn: "LastName",
      SortByDesc: false,
      isActive: true,
      showLocked: false,
      pageSize: 99999,
      PageNumber: 1,
      exportType: "NONE",
      districtId: props.userContext?.districtId,
    };
    var response = userApi.GetAllUsers(query);
    response?.then((res) => {
      if (res?.data?.data?.length) {
        const teachers: Array<ISelectElement> = [
          { label: "All Teachers", value: "0" },
        ];
        res.data.data.map((value: any) => {
          teachers.push({
            label: value?.lastName + ", " + value?.firstName,
            value: value?.userId,
          });
        });
        setTeachers(teachers);
      } else {
        setTeachers([]);
      }
    });
  };

  const getStudents = async (
    schoolId: number,
    subjectId?: number,
    classIds?: any,
    schoolAccountId?: number
  ) => {
    subjectId = subjectId == 0 ? undefined : subjectId;
    classIds = selectedClass == 0 ? undefined : classIds;

    if (!subjectId && !classIds) {
      var query: any = {
        userId: 0,
        firstName: "",
        lastName: "",
        username: "",
        gradeId: 0,
        classId: 0,
        studentId: "",
        roleId: Constant.UserRoleId.Student,
        schoolAccountId: schoolAccountId,
        OrderByColumn: "LastName",
        SortByDesc: false,
        isActive: true,
        showLocked: false,
        pageSize: 99999,
        PageNumber: 1,
        exportType: "NONE",
        districtId: props.userContext?.districtId,
      };
      var response = userApi.GetAllUsers(query);
      response?.then((res) => {
        if (res?.data?.data?.length) {
          const students: Array<ISelectElement> = [
            { label: "All Students", value: "0" },
          ];
          res.data.data.map((value: any) => {
            students.push({
              label:
                value?.lastName +
                ", " +
                value?.firstName +
                (value.studentId ? " (" + value.studentId + ")" : ""),
              value: value?.userId,
            });
          });
          setStudents(students);
        } else {
          setStudents([]);
        }
      });
    } else {
      const resp = await getClassStudentByClassId(
        schoolId,
        subjectId,
        classIds
      );
      if (resp?.data?.length) {
        const students: Array<ISelectElement> = [
          { label: "All Students", value: "0" },
        ];
        resp.data.map((value: any) => {
          students.push({
            label:
              value?.lastName +
              ", " +
              value?.firstName +
              (value.studentId ? " (" + value.studentId + ")" : ""),
            value: value?.userId,
          });
        });
        setStudents(students);
      } else {
        setStudents([]);
      }
    }
  };

  const handleSchoolChange = (schoolId: any) => {
    setSelectedSchool(parseInt(schoolId));
    setSelectedSubject(0);
    setSelectedClass(0);
    setSelectedClasses([]);
    setSelectedStudents([]);
    setSelectedTeachers([]);
    setSelectedActivities([]);
    getClasses(schoolId, getSchoolAccountId(schools, schoolId));
    getTeachers(schoolId, getSchoolAccountId(schools, schoolId));
    getStudents(schoolId, 0, 0, getSchoolAccountId(schools, schoolId));
    setSelectedSchoolAccountId(
      schools?.filter((s) => s.schoolId === Number(schoolId))[0]
        ?.schoolAccountId ?? 0
    );
  };

  const getSchoolAccountId = (schools: any, schoolId?: number) => {
    var school: any = schools?.find((s) => s.schoolId === Number(schoolId));
    return school?.schoolAccountId;
  };

  const handleSubjectChange = (subjectId, schoolId?: number) => {
    setSelectedSubject(subjectId);
    setSelectedStudents([]);
    getStudents(
      selectedSchool,
      subjectId,
      getList(selectedClasses),
      getSchoolAccountId(schools, selectedSchool)
    );
  };

  const handleClassChange: any = (e: any) => {
    setSelectedStudents([]);
    if (e && e.length > 0) {
      if (e[e.length - 1].value != 0) {
        setSelectedClasses(e);
        setSelectedClass(Number(e[e.length - 1].value));
      } else {
        setSelectedClasses([]);
        setSelectedClass(0);
      }
    } else {
      setSelectedClasses([]);
      setSelectedClass(0);
    }
  };

  const handleTeacherChange: any = (e: any) => {
    if (e && e.length > 0) {
      if (e[e.length - 1].value != 0) {
        setSelectedTeachers(e);
      } else {
        setSelectedTeachers([]);
      }
    } else {
      setSelectedTeachers([]);
    }
  };

  const handleStudentChange: any = (e: any) => {
    if (e && e.length > 0) {
      if (e[e.length - 1].value != 0) {
        setSelectedStudents(e);
      } else {
        setSelectedStudents([]);
      }
    } else {
      setSelectedStudents([]);
    }
  };

  const handleClassMenuClose: any = () => {
    getStudents(
      selectedSchool,
      selectedSubject == 0 ? undefined : selectedSubject,
      getList(selectedClasses),
      getSchoolAccountId(schools, selectedSchool)
    );
  };

  const handleActivityChange: any = (e: any) => {
    if (e && e.length > 0) {
      if (e[e.length - 1].value != 0) {
        setSelectedActivities(e);
      } else {
        setSelectedActivities([]);
      }
    } else {
      setSelectedActivities([]);
    }
  };

  const onPageChange = (pageNo) => {
    setPageNumber(pageNo);
  };

  const onPageSizeChange = (pageSize) => {
    setPageNumber(DEFAULT_PAGE_NUMBER);
    setPageSize(pageSize);
    setResetPageNumber(true);
  };

  const getList = (selectedItems: any) => {
    let ids: any = [];
    if (selectedItems) {
      if (selectedItems.length == 1 && selectedItems[0].value == "0") {
        ids = undefined;
      } else {
        selectedItems.forEach((cs) => {
          ids.push(Number(cs.value));
        });
      }
    }
    return ids;
  };

  const getFilters = () => {
    var filters: string = "";
    if (selectedSchool > 0) {
      filters = filters.concat(
        "School-" +
          schools?.filter((x) => x.schoolId === selectedSchool)[0].name
      );
    }
    if (selectedSubject > 0) {
      filters = filters.concat(filters !== "" ? ", " : "");
      filters = filters.concat("Subject-" + selectedSubjectOption.label);
    } else {
      filters = filters.concat(filters !== "" ? ", " : "");
      filters = filters.concat("All Subjects");
    }
    if (
      selectedClasses.length === 0 ||
      (selectedClasses.length === 1 && selectedClasses[0].value === "0")
    ) {
      filters = filters.concat(", All Classes");
    } else {
      filters = filters.concat(", Classes-");
      selectedClasses.forEach((cs) => {
        filters = filters.concat("," + cs.label);
      });
    }
    if (
      selectedTeachers.length === 0 ||
      (selectedTeachers.length === 1 && selectedTeachers[0].value === "0")
    ) {
      filters = filters.concat(", All Teachers");
    } else {
      filters = filters.concat(", Teachers-");
      selectedTeachers.forEach((cs) => {
        filters = filters.concat("," + cs.label);
      });
    }
    if (
      selectedStudents.length === 0 ||
      (selectedStudents.length === 1 && selectedStudents[0].value === "0")
    ) {
      filters = filters.concat(", All Students");
    } else {
      filters = filters.concat(", Students-");
      selectedStudents.forEach((cs) => {
        filters = filters.concat("," + cs.label);
      });
    }
    if (
      selectedActivities.length === 0 ||
      (selectedActivities.length === 1 && selectedActivities[0].value === "0")
    ) {
      filters = filters.concat(", All Activities");
    } else {
      filters = filters.concat(", Activities-");
      selectedActivities.forEach((cs) => {
        filters = filters.concat("," + cs.label);
      });
    }
    filters = filters.concat(
      selectedStartDate ? "," + selectedStartDate.toLocaleDateString() : ""
    );
    filters = filters.concat(
      selectedEndDate ? "," + selectedEndDate.toLocaleDateString() : ""
    );

    filters = filters.concat(
      isRetriesOnly ? "," + "Activities with a retry" : ""
    );

    return filters;
  };

  const getOverallScore = (pointsEarned, pointsPossible) => {
    if (pointsEarned === 0) {
      return 0;
    }
    return Math.round((Number(pointsEarned) / Number(pointsPossible)) * 100);
  };

  const resetReport = () => {
    setActivityReport(null);
    setSelectedSchool(0);
    setSelectedSubject(0);
    setSelectedClass(0);
    setSelectedClasses([]);
    setSelectedStudents([]);
    setSelectedTeachers([]);
    setSelectedActivities([]);
    getSchools();
    setSelectedStartDate(null);
    setSelectedEndDate(null);
    setSelectedSubjectOption({ label: "All Subjects", value: "0" });
    setRetriesOnly(false);
    setResetSchoolYear(true);
    setActiveSortColumn("");
    setOrderType("desc");
  };

  const getReport = async () => {
    setShowLoader(true);
    const resp = await getActivitySearchReport(
      selectedSchool,
      selectedSchoolYearId!,
      selectedSubject === 0 || selectedSubject === ""
        ? undefined
        : selectedSubject,
      getList(selectedClasses),
      getList(selectedTeachers),
      getList(selectedStudents),
      getList(selectedActivities),
      selectedStartDate !== null ? getStartDate(selectedStartDate, true) : null,
      selectedEndDate !== null ? getEndDate(selectedEndDate, true) : null,
      pageNumber,
      pageSize,
      isRetriesOnly,
      activeSortColumn,
      orderType == "desc" ? true : false
    );
    setShowLoader(false);
    if (resp?.data?.data?.data?.length > 0) {
      setActivityReport(resp?.data?.data?.data);
      setTotalRecords(resp?.data.data.totalRecords);
    } else {
      setActivityReport([]);
      setTotalRecords(0);
    }
  };

  const exportToExcel = () => {
    setShowLoader(true);
    exportActivitySearchReportExcel(
      1,
      totalRecords,
      selectedSchool,
      selectedSchoolYearId!,
      selectedSubject === 0 || selectedSubject === ""
        ? undefined
        : selectedSubject,
      getList(selectedClasses),
      getList(selectedTeachers),
      getList(selectedStudents),
      getList(selectedActivities),
      selectedStartDate !== null ? getStartDate(selectedStartDate) : null,
      selectedEndDate !== null ? getEndDate(selectedEndDate) : null,
      getFilters(),
      isRetriesOnly
    ).then((response) => {
      setShowLoader(false);
      const file = response.data;
      const fileURL = URL.createObjectURL(file);
      const link = document.createElement("a");
      link.href = fileURL;
      link.download = "StudentActivitySearch.xlsx";
      link.setAttribute("target", "_blank");
      document.body.appendChild(link);
      link.click();
    });
  };

  useEffect(() => {
    if (activityReport && activityReport?.length !== 0) getReport();
  }, [pageSize, pageNumber, resetPageNumber]);

  const runReport = async () => {
    setPageNumber(DEFAULT_PAGE_NUMBER);
    setTotalRecords(0);
    getReport();
  };

  const redirectToResult = (activity: any) => {
    switch (activity.activityType){
      case Constant.Report_StudentActivityType.ASSIGNMENT:
      case Constant.Report_StudentActivityType.ASSESSMENT:
        assignmentResultRedirect(
          activity.assignmentId,
          activity.assignmentActivityId,
          activity.studentUserId,
          activity.gradeId,
          activity.sessionId,
          "activitysearch",
          selectedSchoolYearId
        );
        break;
      case Constant.Report_StudentActivityType.LO_DIAGNOSTIC_ASSIGNMENTS:
        liftoffDiagnosticResultRedirect(activity.sessionId, "activitysearch", selectedSchoolYearId);
        break;
      case Constant.Report_StudentActivityType.LO_MY_GALAXY_STANDARD:
        myGalaxyStandardResultRedirect(activity.sessionId, "activitysearch", selectedSchoolYearId);
        break;
      case Constant.Report_StudentActivityType.STUDY_PLAN_QUESTIONS:
      case Constant.Report_StudentActivityType.DIAGNOSTIC_TEST:
      case Constant.Report_StudentActivityType.CANNED_TEST:
      case Constant.Report_StudentActivityType.STUDY_PLAN_VIDEO:
      case Constant.Report_StudentActivityType.STUDY_PLAN_SUMMATIVE_TEST:
      case Constant.Report_StudentActivityType.STUDY_PLAN_DOMAIN_TEST:
        studyPlanPracticeResultRedirect(
          activity.activityType,
          activity.studentUserId,
          activity.gradeId,
          activity.sessionId,
          "activitysearch",
          selectedSchoolYearId);
        break;
      default:
        break;
    }
  };

  function handleStartDateChange(date) {
    if (selectedEndDate && date && date > selectedEndDate) {
      setIsInvalidStartDate(true);
    } else {
      setIsInvalidStartDate(false);
    }
    setSelectedStartDate(date);
  }

  function handleEndDateChange(date) {
    if (selectedStartDate && date && date < selectedStartDate) {
      setIsInvalidEndDate(true);
    } else {
      setIsInvalidEndDate(false);
    }
    setSelectedEndDate(date);
  }

  const handleCalendarOpen = (mode: string) => {
    switch (mode){
      case "end":
        if (!selectedEndDate) {
          setSelectedEndDate(getEndDate(new Date(), true));
        }
        break;
    }
  };

  const handleSchoolYearLoaded = (currentSchoolYearId?: number) => {
    handleSchoolYearChange(currentSchoolYearId);
  };

  const handleSchoolYearChange = (schoolYearId?: number) => {
    setSelectedSchoolYearId(schoolYearId);
  };

  const handleSortRecords = (
    _data: any,
    columnName: string,
    sortType: SortingOrder
  ) => {
    setActiveSortColumn(columnName);
    setOrderType(sortType);
  };

  return (
    <>
      {(showLoader || isLoadingClasses) && <Loader></Loader>}
      <div className="min-w-0 w-full">
        <div className="lg:flex lg:items-center px-4 py-3 lg:px-8 border-b">
          {props.userContext.roleId !== Constant.UserRoleId.Student && (
            <Breadcrumb items={breadcrumbItems()} />
          )}
          <div className="lg:w-[55%] text-lg font-medium flex gap-2 h-auto sm:h-10 items-center">
            <h1
              className={`font-medium flex justify-start mb-0 pb-0 ${
                props.userContext.roleId !== Constant.UserRoleId.Student
                  ? "text-primary-violet"
                  : "text-white"
              }
        `}
            >
              Student Activity Search Report
            </h1>
          </div>
        </div>

        <div className="px-2 sm:px-6  lg:px-8 py-1 relative my-4">
          <div className="bg-gray-100 w-full rounded-lg p-5">
            <div className="flex flex-col 1xl:flex-row">
              <div className="w-full">
                <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-5 gap-4 mb-4">
                  <div className="flex flex-col gap-x-2">
                    <label className="relative flex text-gray-500 cursor-pointer focus:outline-none border-gray-400 mb-1">
                      School Year
                    </label>
                    <SchoolYearDropDown
                      className=""
                      schoolId={
                        !isDistrictUser ? props.userContext.schoolId : undefined
                      }
                      reset={resetSchoolYear}
                      offsets={[0, -1]}
                      handleSchoolYearChange={handleSchoolYearChange}
                      handleSchoolYearLoaded={handleSchoolYearLoaded}
                      handleSchoolYearData={() => {}}
                    ></SchoolYearDropDown>
                  </div>
                  {isDistrictUser && (
                    <div className="flex flex-col">
                      <div className="flex gap-2">
                        <label className="block mb-1 text-sm text-gray-500">
                          School <Asterisk />
                        </label>
                      </div>
                      <Select
                        value={selectedSchool}
                        onChange={(e) => {
                          handleSchoolChange(e.target.value);
                        }}
                        className={
                          "py-2.5 px-4 block text-sm border border-gray-400 border-opacity-50 focus:ring-primary-violet focus:border-primary-violet form-select leading-5 p-1 rounded-lg "
                        }
                      >
                        <option value={0}>Select School</option>
                        {schools?.map((school: ISchool, index) => {
                          return (
                            <Fragment key={index}>
                              <option value={school.schoolId}>
                                {school.name}
                              </option>
                            </Fragment>
                          );
                        })}
                      </Select>
                    </div>
                  )}
                  {props.userContext.roleId !== Constant.UserRoleId.Student && (
                    <div className="flex flex-col">
                      <Subject
                        isDisabled={selectedSchool > 0 ? false : true}
                        setSelectedSubject={(item) => {
                          setSelectedSubjectOption(item[0]);
                          handleSubjectChange(item[0].value, selectedSchool);
                        }}
                        selectedItems={selectedSubjectOption}
                        isMulti={false}
                        isRequired={false}
                        themeColor={"#008fbb"}
                        subjectTypeId={Constant.SUBJECTTYPE.PL}
                        schoolId={selectedSchool}
                      ></Subject>
                    </div>
                  )}
                  <div className="flex flex-col">
                    <label className="block mb-1 text-sm text-gray-500">
                      Class
                    </label>
                    <ClassDropDown
                      schoolYearId={selectedSchoolYearId ?? props.profile.currentSchoolYear.schoolYearId}
                      defaultLabel="All Classes"
                      isMultiSelect={true}
                      onChange={handleClassChange}
                      value={selectedClasses}
                      data={classes}
                      isDisabled={
                        (selectedSchool > 0 ? false : true) && isLoadingClasses
                      }
                      showArchived={true}
                      showHidden={true}
                      selectedSchoolAccountId={selectedSchoolAccountId}
                    ></ClassDropDown>
                  </div>
                  <div className="flex flex-col">
                    <label className="block mb-1 text-sm text-gray-500">
                      Teacher
                    </label>
                    <SelectGroup
                      isDisabled={selectedSchool > 0 ? false : true}
                      isMulti={true}
                      placeholder={"All Teachers"}
                      onChange={handleTeacherChange}
                      value={selectedTeachers}
                      options={teachers}
                      closeMenuOnSelect={false}
                      isSearchable={true}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary: "#008fbb",
                        },
                      })}
                    />
                  </div>
                  <div className="flex flex-col">
                    <label className="block mb-1 text-sm text-gray-500">
                      Student
                    </label>
                    <SelectGroup
                      isDisabled={selectedSchool > 0 ? false : true}
                      isMulti={true}
                      placeholder={"All Students"}
                      onChange={handleStudentChange}
                      value={selectedStudents}
                      options={students}
                      closeMenuOnSelect={false}
                      isSearchable={true}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary: "#008fbb",
                        },
                      })}
                    />
                  </div>
                  <div className="flex flex-col">
                    <label className="block mb-1 text-sm text-gray-500">
                      Activity
                    </label>
                    <SelectGroup
                      isDisabled={selectedSchool > 0 ? false : true}
                      isMulti={true}
                      placeholder={"All Activities"}
                      onChange={handleActivityChange}
                      options={activities}
                      value={selectedActivities}
                      closeMenuOnSelect={false}
                      isSearchable={true}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary: "#008fbb",
                        },
                      })}
                    />
                  </div>
                  <div className="flex flex-col">
                    <label className="block mb-1 text-sm text-gray-500">
                      Start Date
                    </label>
                    <DatePicker
                      selected={selectedStartDate}
                      minDate={Constant.PLMinDate}
                      maxDate={maxDate}
                      onChange={handleStartDateChange}
                      className={
                        "react-datepicker__input-container py-1.5 px-3 block w-full shadow-sm focus:ring-primary-violet focus:border-primary-violet border border-gray-300 rounded " +
                        (selectedSchool === 0
                          ? "pointer-events-none text-gray-400 bg-gray"
                          : "")
                      }
                      isClearable={true}
                      onCalendarOpen={() => { handleCalendarOpen("start") }}
                    />
                    {isInvalidStartDate && (
                      <span className="pl-2 text-red-600">Invalid Date</span>
                    )}
                  </div>
                  <div className="flex flex-col">
                    <label className="block mb-1 text-sm text-gray-500">
                      End Date
                    </label>

                    <DatePicker
                      selected={selectedEndDate}
                      minDate={Constant.PLMinDate}
                      maxDate={maxDate}
                      onChange={handleEndDateChange}
                      className={
                        "react-datepicker__input-container py-1.5 px-3 block w-full shadow-sm focus:ring-primary-violet focus:border-primary-violet border border-gray-300 rounded " +
                        (selectedSchool === 0
                          ? "pointer-events-none text-gray-400 bg-gray"
                          : "")
                      }
                      isClearable={true}
                      onCalendarOpen={() => { handleCalendarOpen("end") }}
                    />
                    {isInvalidEndDate && (
                      <span className="pl-2 text-red-600">Invalid Date</span>
                    )}
                  </div>
                  <div className="flex  gap-x-4 mt-10">
                    <input
                      type="checkbox"
                      name="time-line"
                      className={
                        "block mb-1 text-sm text-gray-500 h-4 w-4 mt-0.5 cursor-pointer text-primary-violet border-gray-300 focus:ring-primary-violet "
                      }
                      checked={isRetriesOnly}
                      onChange={() => setRetriesOnly(!isRetriesOnly)}
                    />
                    Activities with a Retry
                  </div>
                </div>

                <div className="flex gap-4 justify-end">
                  <button
                    className="bg-transparent border my-auto border-primary-violet shadow-sm py-2 px-4 flex justify-center text-sm font-medium text-primary-violet hover:text-white hover:bg-light-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3 transition ease-in-out duration-500"
                    onClick={resetReport}
                  >
                    Reset
                  </button>
                  <div className="cursor-not-allowed">
                    <button
                      className={
                        "bg-primary-violet border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 " +
                        (selectedSchool === 0 ||
                        isInvalidStartDate ||
                        isInvalidEndDate
                          ? "bg-opacity-20 text-primary-violet text-opacity-40 pointer-events-none"
                          : "hover:bg-dark-violet hover:shadow-lg")
                      }
                      onClick={runReport}
                    >
                      Run Report
                    </button>
                  </div>

                  <div className="cursor-not-allowed">
                    <button
                      onClick={() => exportToExcel()}
                      className={
                        "bg-primary-violet border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 " +
                        (activityReport && activityReport.length > 0
                          ? "hover:bg-dark-violet hover:shadow-lg"
                          : "bg-opacity-20 text-primary-violet text-opacity-40 pointer-events-none")
                      }
                    >
                      Export Data
                    </button>
                  </div>
                </div>
                {activityReport && (
                  <>
                    <div className="border-b border-gray-400 py-2 mb-2"></div>
                    <div className="bg-white py-5 px-5">
                      <div className="overflow-x-auto xl:overflow-x-visible shadow">
                        <table className="table w-full ">
                          <thead>
                            <tr className="bg-primary-violet  text-white ">
                              <th className="text-left p-3 text-sm font-normal">
                                <SortTableHeader
                                  data={[]}
                                  columnName="DateCompleted"
                                  headerComponent="Completion Date"
                                  orderType={orderType}
                                  activeSortColumn={activeSortColumn}
                                  sortRecords={handleSortRecords}
                                />
                              </th>
                              <th className="text-left p-3 text-sm font-normal">
                                <SortTableHeader
                                  data={[]}
                                  columnName="Student"
                                  headerComponent="Student"
                                  orderType={orderType}
                                  activeSortColumn={activeSortColumn}
                                  sortRecords={handleSortRecords}
                                />
                              </th>
                              <th className="text-left p-3 text-sm font-normal">
                                <SortTableHeader
                                  data={[]}
                                  columnName="StudentId"
                                  headerComponent="Student ID"
                                  orderType={orderType}
                                  activeSortColumn={activeSortColumn}
                                  sortRecords={handleSortRecords}
                                />
                              </th>
                              <th className="text-left p-3 text-sm font-normal">
                                <SortTableHeader
                                  data={[]}
                                  columnName="Teacher"
                                  headerComponent="Teacher"
                                  orderType={orderType}
                                  activeSortColumn={activeSortColumn}
                                  sortRecords={handleSortRecords}
                                />
                              </th>
                              <th className="text-left p-3 text-sm font-normal">
                                <SortTableHeader
                                  data={[]}
                                  columnName="Subject"
                                  headerComponent="Subject"
                                  orderType={orderType}
                                  activeSortColumn={activeSortColumn}
                                  sortRecords={handleSortRecords}
                                />
                              </th>
                              <th className="text-left p-3 text-sm font-normal">
                                <SortTableHeader
                                  data={[]}
                                  columnName="Assignment"
                                  headerComponent="Assignment"
                                  orderType={orderType}
                                  activeSortColumn={activeSortColumn}
                                  sortRecords={handleSortRecords}
                                />
                              </th>
                              <th className="text-left p-3 text-sm font-normal">
                                <SortTableHeader
                                  data={[]}
                                  columnName="Activity"
                                  headerComponent="Activity"
                                  orderType={orderType}
                                  activeSortColumn={activeSortColumn}
                                  sortRecords={handleSortRecords}
                                />
                              </th>

                              <th className="text-center p-3 text-sm font-normal">
                                <SortTableHeader
                                  data={[]}
                                  columnName="Score"
                                  headerComponent="Score"
                                  orderType={orderType}
                                  activeSortColumn={activeSortColumn}
                                  alignment="center"
                                  sortRecords={handleSortRecords}
                                />
                              </th>
                              <th className="text-left p-3 text-sm font-normal"></th>
                            </tr>
                          </thead>
                          <tbody>
                            {activityReport?.map((activity, index) => {
                              return (
                                <Fragment>
                                  <tr
                                    className={`hover:bg-primary-violet/20 transition ease-in-out duration-300 ${
                                      index % 2 === 0
                                        ? "bg-white"
                                        : "bg-primary-violet/10"
                                    }`}
                                  >
                                    <td className="text-sm p-3 ">
                                      {moment(activity.dateCompleted).format(
                                        "MM-DD-YYYY"
                                      )}
                                      <div>
                                        {formatDuration(
                                          activity.durationInSeconds
                                        )}
                                      </div>
                                    </td>
                                    <td className="text-sm p-3 ">
                                      {getDisplayName(
                                        activity.studentLastName,
                                        activity.studentFirstName,
                                        ""
                                      )}
                                    </td>
                                    <td className="text-sm p-3 ">
                                      {activity.studentId}
                                    </td>
                                    <td className="text-sm p-3 ">
                                      {getTeacherDisplayName(
                                        activity.teacherLastName,
                                        activity.teacherFirstName,
                                        "",
                                        activity.className
                                      )}
                                    </td>
                                    <td className="text-sm p-3 ">
                                      {activity.subject}
                                    </td>
                                    <td className="text-sm p-3 ">
                                      {activity.assignmentName}
                                    </td>
                                    <td className="text-sm p-3 ">
                                      {activity.activityTitle}
                                    </td>
                                    <td className="text-sm p-3">
                                      <div className="flex flex-col items-center">
                                        <div className="flex gap-x-4 font-bold px-1">
                                          <div>
                                            {activity.questionsUngraded > 0
                                              ? "NG"
                                              : activity?.pointsEarned !=
                                                  null &&
                                                activity?.pointsPossible !=
                                                  null &&
                                                `(${activity.pointsEarned}/${activity.pointsPossible})`}
                                          </div>
                                          <div>
                                            {activity.questionsUngraded > 0
                                              ? "NG"
                                              : getOverallScore(
                                                  activity.pointsEarned,
                                                  activity.pointsPossible
                                                ) + "%"}
                                          </div>
                                        </div>
                                        {activity.retryPointsEarned !==
                                          null && (
                                          <>
                                            <div className="italic font-normal text-center w-full text-teal-light">
                                              Retry
                                            </div>
                                            <div className="flex gap-x-4 italic text-teal-light border border-gray-400 rounded-lg px-1">
                                              <div>
                                                {activity.questionsUngraded > 0
                                                  ? "NG"
                                                  : activity?.pointsEarned !=
                                                      null &&
                                                    activity?.pointsPossible !=
                                                      null &&
                                                    "(" +
                                                      Number(
                                                        activity.correctPointsEarned
                                                      ).toString() +
                                                      "/" +
                                                      activity.pointsPossible +
                                                      ")"}
                                              </div>
                                              <div>
                                                {activity.questionsUngraded > 0
                                                  ? "NG"
                                                  : getOverallScore(
                                                      activity.correctPointsEarned,
                                                      activity.pointsPossible
                                                    ) + "%"}
                                              </div>
                                            </div>
                                          </>
                                        )}
                                      </div>
                                    </td>

                                    <td className="text-sm p-3 ">
                                      {(activity.canViewAssessment != false ||
                                        isAllowViewAssessment(
                                          props.userContext.roleId,
                                          activity.canViewAssessment,
                                          activity.assessmentSharedTypeId,
                                          activity.assessmentCreatedByUserId,
                                          props.userContext.userId
                                        )) && (
                                        <>
                                          <button
                                            className="text-indigo-500 hover:underline"
                                            onClick={() =>
                                              redirectToResult(activity)
                                            }
                                          >
                                            View
                                          </button>
                                        </>
                                      )}
                                    </td>
                                  </tr>
                                </Fragment>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>

                      {activityReport?.length === 0 && <NoData />}
                      {activityReport?.length !== 0 && (
                        <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between border-t-2 pt-4 mt-4">
                          <Pagination
                            totalRecords={totalRecords}
                            pageNumber={pageNumber}
                            pageSize={pageSize}
                            onPageChange={onPageChange}
                            reset={resetPageNumber}
                            setPageNumber={setPageNumber}
                          />
                          <PageSize
                            pageSize={pageSize}
                            onPageSizeChange={onPageSizeChange}
                          />
                        </div>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default connect((state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
})(StudentActivitySearchReport);

import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { getStudentActivities } from "../../../../../api/student/studentActivityApi";
import { LockIcon, RightArrowIcon } from "../../../../../assets/icons";
import AssignmentAssessmentIcon from "../../../../../assets/icons/student/middleschool/assignments/AssignmentAssessmentIcon";
import AssignmentGroupedAssignmentExpandedIcon from "../../../../../assets/icons/student/middleschool/assignments/AssignmentGroupedAssignmentExpandedIcon";
import AssignmentPerformanceTaskIcon from "../../../../../assets/icons/student/middleschool/assignments/AssignmentPerformanceTaskIcon";
import AssignmentQuestionsIcon from "../../../../../assets/icons/student/middleschool/assignments/AssignmentQuestionsIcon";
import AssignmentsGroupedAssignmentCollapsedIcon from "../../../../../assets/icons/student/middleschool/assignments/AssignmentsGroupedAssignmentCollapsedIcon";
import AssignmentVideoIcon from "../../../../../assets/icons/student/middleschool/assignments/AssignmentVideoIcon";
import ConstructedResponseIcon from "../../../../../assets/icons/student/middleschool/assignments/ConstructedResponseIcon";
import PreBuildIcon from "../../../../../assets/icons/student/middleschool/assignments/PreBuildIcon";
import ActivityListResponse from "../../../../../model/student/shared/assignment/activityListResponse";
import AssignmentListResponse from "../../../../../model/student/shared/assignment/assignmentListResponse";
import { getActivityName } from "../../../../../utils/assignmentHelper";
import Constant from "../../../../../utils/constant/constant";
import Loader from "../../../../shared/loader";
import RouteConstant from "../../../../../utils/constant/routeConstant";
import { useHistory } from "react-router-dom";
import Profile from "../../../../../model/users/profile";
import { UserContextState } from "../../../../../redux/actions/userContextAction";
import { paramEncode } from "../../../../../utils/urlHelper";
import { sanitizeNoteContent } from "../../../../../utils/sanitize";
import { redirect } from "../../../../../utils/routeRedirectHelper";
interface listProps {
  assignmentData: AssignmentListResponse[];
  userId: number;
  userContext?: UserContextState;
  profile?: Profile;
}
function AssignmentList(props: listProps) {
  const { t } = useTranslation();
  const history = useHistory();
  const [notesFromTeacher, setNotesFromTeacher] = useState<string | null>(null);
  const [expandedEvent, setExpandedEvent] = useState<any>({
    index: [],
  });
  const [activities, setActivities] = useState<Array<ActivityListResponse>>([]);
  const { assignmentData } = props;
  const [loading, showLoading] = useState<boolean>(false);

  useEffect(() => {
    const innerUl = document.getElementById("ul1");
    if (innerUl) innerUl.classList.add("anim-0");
  }, [assignmentData]);

  const onGroupIconChange: any = (
    index,
    assignment: AssignmentListResponse
  ) => {
    if (!expandedEvent.index.includes(index)) {
      const indexArr = [...expandedEvent.index, index];
      setExpandedEvent({
        index: indexArr,
      });
      //  setExpandedAssignment(assignment);
      showLoading(true);
      getStudentActivities(props.userId, assignment.assignmentId).then((r) => {
        if (r.data.length > 0) {
          const merged = activities.concat([], r.data);
          let completeInOrder = merged[0].completeInOrder;
          let isContinueAssignment = false;
          if (completeInOrder) {
            let isEnable = false;
            merged.forEach((activity) => {
              let status = activity.assignmentStatus;
              if (
                status?.toUpperCase() === Constant.AssignmentStatus.SAVE &&
                activity.totalAttempt === 1 &&
                !isContinueAssignment
              )
                isContinueAssignment = true;

              if (
                status?.toUpperCase() === Constant.AssignmentStatus.NEW &&
                !isContinueAssignment &&
                !isEnable
              ) {
                activity.isEnable = true;
                isEnable = true;
              } else if (
                status?.toUpperCase() === Constant.AssignmentStatus.NEW &&
                (isContinueAssignment || isEnable)
              ) {
                activity.isEnable = false;
              } else {
                activity.isEnable = true;
              }
            });
          } else {
            merged.forEach((activity) => {
              activity.isEnable = true;
            });
          }
          setActivities(merged);
        }
        showLoading(false);
      });
    } else {
      const indexArr = expandedEvent.index.filter(function (item) {
        return item !== index;
      });
      setExpandedEvent({ index: indexArr });
      //   setExpandedAssignment(undefined);
      setActivities([]);
    }
  };

  function startAssignmentActivity(
    subjectId: number,
    assignmentActivityId: number,
    assignmentActivityName: string,
    multipleAttempt: number,
    assignmentId: number,
    lernosityActivityIdEN?: string,
    lernosityActivityIdES?: string,
    assignmentType?: string,
    currentActivity?: ActivityListResponse
  ) {
    let multipleAttemptAllowed = multipleAttempt > 0 ? true : false;
    assignmentType = assignmentType ? assignmentType : "";
    const assignment = currentActivity
      ? currentActivity
      : activities.filter(
          (a) => a.assignmentActivityId === assignmentActivityId
        )[0];
    if (lernosityActivityIdEN || lernosityActivityIdES) {
      const assignmentUrl =
        RouteConstant.MiddleSchool.StudentAssignmentActivityMS.replace(
          ":assignmentActivityId",
          encodeURI(btoa(assignmentActivityId.toString()))
        )
          .replace(
            ":assignmentActivityName",
            paramEncode((assignmentActivityName))
          )
          .replace(
            ":lernosityActivityIdEN",
            encodeURI(btoa(lernosityActivityIdEN ? lernosityActivityIdEN : ""))
          )
          .replace(
            ":lernosityActivityIdES",
            encodeURI(
              btoa(
                lernosityActivityIdES
                  ? lernosityActivityIdES
                  : lernosityActivityIdEN
                  ? lernosityActivityIdEN
                  : ""
              )
            )
          )
          .replace(":assignmentType", encodeURI(btoa(assignmentType)))
          .replace(":assignmentId", encodeURI(btoa(assignmentId.toString())))
          .replace(":subjectId", encodeURI(btoa(subjectId.toString())))
          .replace(
            ":multipleAttemptAllowed",
            encodeURI(btoa(multipleAttemptAllowed.toString()))
          );

      const prebuildTestType =
        (assignment?.summativeTestId ?? 0) > 0
          ? (assignment?.summativeTestName ?? "") + " Practice Test"
          : (assignment?.domainId ?? 0) > 0
          ? "Practice Test - " + assignment?.domainName ?? ""
          : "";

      const redirectedFromLO = history.location.pathname.toLowerCase().includes('liftoff');
      history.push({
        pathname: assignmentUrl,
        state: {
          prebuildTestType: prebuildTestType,
          redirectedFromLO : redirectedFromLO
        },
      });
    }
  }

  function startAssessmentActivity(code, subjectId) {
    redirect({
      urlTemplate: RouteConstant.StudentRoute.StudentAssessmentActivity,
      code: code,
      subjectId: subjectId,
      multipleAttemptAllowed: false,
      history: history,
    });
  }

  function isPastDate(dueDate: Date | undefined) {
    let isPastDate: boolean = false;
    isPastDate = moment(dueDate, ["YYYY-MM-DD"]).isBefore(
      moment(new Date(), ["YYYY-MM-DD"]),
      "day"
    );

    return isPastDate;
  }

  return (
    <Fragment>
      {loading && <Loader />}

      <div className="pb-5">
        <div className="relative">
          <div>
            <div className="relative overflow-auto xl:overflow-visible rounded-b-lg sm:rounded-tr-lg">
              <table
                id="assignment-list-tbl"
                className="w-full divide-y divide-gray-200"
              >
                <thead className="bg-gray-200 uppercase">
                  <tr className="divide-x">
                    <th
                      scope="col"
                      className="bg-gray-100 top-0 px-3 xl:px-2 xxl:px-6 py-3 text-center text-sm font-medium text-dark-teal uppercase tracking-wider"
                    >
                      {t("Assignments.Type")}
                    </th>
                    <th
                      scope="col"
                      className="top-0 bg-gray-100 px-3 xl:px-2 xxl:px-6 py-3 text-left text-sm font-medium  text-dark-teal uppercase tracking-wider"
                    >
                      {t("Assignments.DueDate")}
                    </th>
                    <th
                      scope="col"
                      className="bg-gray-100 top-0 px-3 xl:px-2 xxl:px-6 py-3 text-left text-sm font-medium  text-dark-teal tracking-wider"
                    >
                      {t("Assignments.Name")}
                    </th>
                    <th
                      scope="col"
                      className="top-0 bg-gray-100 px-3 xl:px-2 xxl:px-6 py-3 text-left text-sm font-medium  text-dark-teal uppercase tracking-wider"
                    >
                      {t("Assignments.Teacher/Class")}
                    </th>
                    <th
                      scope="col"
                      className="bg-gray-100 top-0 px-3 xl:px-2 xxl:px-6 py-3 text-left text-sm font-medium  text-dark-teal uppercase tracking-wider"
                    >
                      {t("Assignments.Notes")}
                    </th>
                    <th
                      scope="col"
                      className="bg-gray-100 top-0 px-3 xl:px-2 xxl:px-6"
                    >
                      <span className="sr-only">
                        {t("Assignments.ActionButton")}
                      </span>
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white">
                  {assignmentData?.map((assignment, index) => {
                    index = index + 1;
                    return (
                      <Fragment key={index}>
                        {
                          <tr className={`border-b-2`}>
                            <td className="w-10 px-6 py-4 bg-gray-100">
                              <div
                                className="flex items-center"
                                onClick={() =>
                                  onGroupIconChange(index, assignment)
                                }
                              >
                                {!assignment.isGroupedAssignment ? (
                                  <div>
                                    {assignment.assignmentType ===
                                      Constant.AssignmentActivityType
                                        .ASSESSMENT && (
                                      <span title="Assessment">
                                        <AssignmentAssessmentIcon
                                          is_saved={
                                            assignment.assignmentStatus?.toUpperCase() ===
                                            Constant.AssignmentStatus.SAVE
                                              ? 1
                                              : 0
                                          }
                                          past_due={
                                            isPastDate(assignment.endDate)
                                              ? 1
                                              : 0
                                          }
                                        />
                                      </span>
                                    )}
                                    {assignment.assignmentType ===
                                      Constant.AssignmentActivityType
                                        .PERFORMANCETASKS && (
                                      <span title="performance task">
                                        <AssignmentPerformanceTaskIcon
                                          is_saved={
                                            assignment.assignmentStatus?.toUpperCase() ===
                                            Constant.AssignmentStatus.SAVE
                                              ? 1
                                              : 0
                                          }
                                          past_due={
                                            isPastDate(assignment.endDate)
                                              ? 1
                                              : 0
                                          }
                                        />
                                      </span>
                                    )}
                                    {assignment.assignmentType ===
                                      Constant.AssignmentActivityType
                                        .PREBUILTTESTS && (
                                      <span title="Generated test">
                                        <PreBuildIcon
                                          is_saved={
                                            assignment.assignmentStatus?.toUpperCase() ===
                                            Constant.AssignmentStatus.SAVE
                                              ? 1
                                              : 0
                                          }
                                          past_due={
                                            isPastDate(assignment.endDate)
                                              ? 1
                                              : 0
                                          }
                                        />
                                      </span>
                                    )}
                                    {assignment.assignmentType ===
                                      Constant.AssignmentActivityType
                                        .VIDEOLESSON && (
                                      <span title="Video">
                                        <AssignmentVideoIcon
                                          is_saved={
                                            assignment.assignmentStatus?.toUpperCase() ===
                                            Constant.AssignmentStatus.SAVE
                                              ? 1
                                              : 0
                                          }
                                          past_due={
                                            isPastDate(assignment.endDate)
                                              ? 1
                                              : 0
                                          }
                                        />
                                      </span>
                                    )}
                                    {assignment.assignmentType ===
                                      Constant.AssignmentActivityType
                                        .PRACTICEQUESTIONS && (
                                      <span title="questions">
                                        <AssignmentQuestionsIcon
                                          is_saved={
                                            assignment.assignmentStatus?.toUpperCase() ===
                                            Constant.AssignmentStatus.SAVE
                                              ? 1
                                              : 0
                                          }
                                          past_due={
                                            isPastDate(assignment.endDate)
                                              ? 1
                                              : 0
                                          }
                                        />
                                      </span>
                                    )}
                                    {assignment.assignmentType ===
                                      Constant.AssignmentActivityType
                                        .CONSTRUCTEDRESPONSE && (
                                      <span title="constructed response">
                                        <ConstructedResponseIcon
                                          is_saved={
                                            assignment.assignmentStatus?.toUpperCase() ===
                                            Constant.AssignmentStatus.SAVE
                                              ? 1
                                              : 0
                                          }
                                          past_due={
                                            isPastDate(assignment.endDate)
                                              ? 1
                                              : 0
                                          }
                                        />
                                      </span>
                                    )}
                                  </div>
                                ) : expandedEvent &&
                                  expandedEvent.index.includes(index) ? (
                                  <span title="Group assignments">
                                    <AssignmentsGroupedAssignmentCollapsedIcon
                                      is_saved={
                                        assignment.assignmentStatus?.toUpperCase() ===
                                        Constant.AssignmentStatus.SAVE
                                          ? 1
                                          : 0
                                      }
                                      past_due={
                                        isPastDate(assignment.endDate) ? 1 : 0
                                      }
                                    />
                                  </span>
                                ) : (
                                  <span title="Group assignments">
                                    <AssignmentGroupedAssignmentExpandedIcon
                                      is_saved={
                                        assignment.assignmentStatus?.toUpperCase() ===
                                        Constant.AssignmentStatus.SAVE
                                          ? 1
                                          : 0
                                      }
                                      past_due={
                                        isPastDate(assignment.endDate) ? 1 : 0
                                      }
                                    />
                                  </span>
                                )}
                              </div>
                            </td>
                            <td className="px-3 py-4 border-r-2 border-gray-100">
                              <div
                                className={`border-l-4 border-blue h-10 flex flex-col justify-center  ${
                                  (
                                    assignment.isGroupedAssignment &&
                                    assignment.assignmentStatus?.toUpperCase() ===
                                      Constant.AssignmentStatus.SAVE
                                      ? true
                                      : false
                                  )
                                    ? "border-site-orange"
                                    : ""
                                } ${
                                  isPastDate(assignment.endDate)
                                    ? "border-site-pink"
                                    : ""
                                } pl-2`}
                              >
                                <div
                                  className={`text-sm font-medium text-gray-700 ${
                                    (
                                      assignment.isGroupedAssignment &&
                                      assignment.assignmentStatus?.toUpperCase() ===
                                        Constant.AssignmentStatus.SAVE
                                        ? true
                                        : false
                                    )
                                      ? "text-site-orange"
                                      : ""
                                  } ${
                                    isPastDate(assignment.endDate)
                                      ? "text-site-pink"
                                      : ""
                                  }`}
                                >
                                  {assignment.endDate &&
                                    moment(assignment.endDate).format(
                                      "MM/DD/YYYY"
                                    )}
                                </div>
                                <div className="text-sm text-gray-500 font-semibold capitalize">
                                  <div>
                                    {isPastDate(assignment.endDate)
                                      ? t("Assignments.PastDue")
                                      : (
                                          assignment.isGroupedAssignment &&
                                          assignment.assignmentStatus?.toUpperCase() ===
                                            Constant.AssignmentStatus.SAVE
                                            ? true
                                            : false
                                        )
                                      ? t("Assignments.Saved")
                                      : ""}
                                  </div>
                                  <div>
                                    {!isPastDate(assignment.endDate) &&
                                      (assignment.assignmentStatus?.toUpperCase() ===
                                        Constant.AssignmentStatus.NEW ||
                                        assignment.assignmentStatus?.toLowerCase() ===
                                          "in progress" ||
                                        assignment.assignmentStatus?.toLowerCase() ===
                                          "in_progress") &&
                                      t("Assignments.NotStarted")}
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td className="px-3 py-4 border-r-2 border-gray-100">
                              <div className="text-sm text-gray-700 capitalize font-normal">
                                {assignment.name}
                              </div>
                            </td>
                            <td className="px-3 py-4 text-sm text-gray-500 border-r-2 border-gray-100">
                              <div className="text-sm text-gray-700 capitalize font-normal">
                                {assignment.teacherName}
                              </div>
                              <div className="text-sm text-gray-700 capitalize font-normal">
                                {assignment.className}
                              </div>
                            </td>
                            <td className="px-3 py-4 text-left text-sm  border-r-2 border-gray-100 relative">
                              {!assignment.isGroupedAssignment && assignment.minimumScore > 0 && (
                                  <>Minimum score required - {assignment.minimumScore}%</>
                              )}
                              {assignment.textNote && (
                                <div
                                  onClick={() =>
                                    setNotesFromTeacher(
                                      assignment.assignmentId +
                                        "inx" +
                                        index.toString()
                                    )
                                  }
                                  className={`${
                                    assignment &&
                                    assignment.textNote &&
                                    assignment.textNote.length > 0
                                      ? "bg-yellow-100"
                                      : "cursor-not-allowed "
                                  } px-2 py-2 rounded-md ${
                                    assignment.isLocked
                                      ? "pointer-events-none"
                                      : "cursor-pointer"
                                  }`}
                                >
                                  <p className="line-clamp-2 text-gray-700">
                                    {assignment.isLocked ? (
                                      "Locked"
                                    ) : <span
                                        dangerouslySetInnerHTML={{
                                          __html: sanitizeNoteContent(
                                            assignment.textNote
                                          ),
                                        }}
                                      ></span> ? (
                                      assignment.textNote.length > 50 ? (
                                        <span
                                          dangerouslySetInnerHTML={{
                                            __html:
                                              sanitizeNoteContent(
                                                assignment.textNote.substring(
                                                  0,
                                                  50
                                                )
                                              ) + "...",
                                          }}
                                        ></span>
                                      ) : (
                                        <span
                                          dangerouslySetInnerHTML={{
                                            __html: sanitizeNoteContent(
                                              assignment.textNote
                                            ),
                                          }}
                                        ></span>
                                      )
                                    ) : (
                                      ""
                                    )}
                                  </p>
                                </div>
                              )}

                              {assignment &&
                                assignment.assignmentId + "inx" + index ===
                                  notesFromTeacher &&
                                assignment?.isLocked === false &&
                                assignment.textNote &&
                                assignment.textNote.length > 0 && (
                                  <div
                                    aria-live="assertive"
                                    className={`absolute top-[3.2rem] inset-0 flex px-4 py-6 pointer-events-none items-start ${
                                      index === assignmentData.length
                                        ? " -top-12"
                                        : " top-12"
                                    }`}
                                  >
                                    <div className="w-full flex flex-col items-center space-y-4 sm:items-start z-10">
                                      <div className="max-w-sm w-full bg-yellow-200 shadow-lg rounded-md pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
                                        <div className="p-4">
                                          <div className="flex items-start">
                                            <div className="w-0 flex-1 pt-0.5">
                                              <p className="text-sm font-semibold text-gray-700">
                                                Notes from your teacher
                                              </p>
                                              <p className="mt-1 text-sm text-gray-700">
                                                <span
                                                  dangerouslySetInnerHTML={{
                                                    __html: sanitizeNoteContent(
                                                      assignment.textNote
                                                    ),
                                                  }}
                                                ></span>
                                              </p>
                                            </div>
                                            <div className="ml-4 flex-shrink-0 flex">
                                              <button className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-0 ">
                                                <span className="sr-only">
                                                  Close
                                                </span>
                                                <svg
                                                  className="h-5 w-5 fill-current text-gray-500 hover:text-gray-700 bg-yellow-200"
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  viewBox="0 0 20 20"
                                                  aria-hidden="true"
                                                  onClick={() =>
                                                    setNotesFromTeacher(null)
                                                  }
                                                >
                                                  <path
                                                    fillRule="evenodd"
                                                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                                    clipRule="evenodd"
                                                  ></path>
                                                </svg>
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                            </td>
                            <td className="px-3 py-4 border-r-2 border-gray-100">
                              <div
                                className={`flex justify-center ${
                                  assignment &&
                                  assignment.multipleAttempt ===
                                    assignment.totalAttempt &&
                                  assignment.multipleAttempt > 0 &&
                                  assignment.totalAttempt > 0 &&
                                  assignment.assignmentStatus?.toUpperCase() ===
                                    Constant.AssignmentStatus.COMPLETE
                                    ? "cursor-not-allowed pointer-events-none bg-opacity-50"
                                    : ""
                                }`}
                              >
                                {assignment.isLocked ? (
                                  <LockIcon />
                                ) : (
                                  <>
                                    {
                                      //Single Assignment Without Expand
                                      !assignment.isGroupedAssignment && (
                                        <span
                                          onClick={() => {
                                            assignment.sessionActivityType !==
                                            "ASSESSMENT"
                                              ? startAssignmentActivity(
                                                  assignment.subjectId,
                                                  assignment.assignmentActivityId,
                                                  getActivityName(
                                                    assignment,
                                                    props.profile
                                                      ?.plLanguageId ?? 1
                                                  ),
                                                  assignment.multipleAttempt,
                                                  assignment.assignmentId,
                                                  assignment.learnosityIdEnglish,
                                                  assignment.learnosityIdSpanish,
                                                  assignment.assignmentType
                                                )
                                              : startAssessmentActivity(
                                                  assignment.code,
                                                  assignment.subjectId
                                                );
                                          }}
                                          className={
                                            (!assignment.isGroupedAssignment &&
                                            assignment.multipleAttempt ===
                                              assignment.totalAttempt &&
                                            assignment.multipleAttempt > 0 &&
                                            assignment.totalAttempt > 0 &&
                                            assignment.assignmentStatus?.toUpperCase() ===
                                              Constant.AssignmentStatus.COMPLETE
                                              ? "bg-opacity-50 "
                                              : "") +
                                            `bg-blues ${
                                              (
                                                !assignment.isGroupedAssignment &&
                                                assignment.assignmentStatus
                                                  ?.toUpperCase()
                                                  .toString() ===
                                                  Constant.AssignmentStatus.SAVE
                                                  ? true
                                                  : false
                                              )
                                                ? "bg-site-orange"
                                                : ""
                                            } ${
                                              isPastDate(assignment.endDate)
                                                ? "bg-site-pink"
                                                : ""
                                            }                        
                                hover:bg-secondary-teal transition-all hover:text-gray-100 inline-block whitespace-nowrap rounded-lg text-sm flex items-center justify-center w-full h-10  px-4 text-center py-2 text-white cursor-pointer`
                                          }
                                        >
                                          {!assignment.isGroupedAssignment &&
                                          assignment.assignmentStatus?.toUpperCase() ===
                                            Constant.AssignmentStatus.SAVE
                                            ? t("Assignments.Continue")
                                            : assignment.assignmentStatus
                                                ?.toString()
                                                .toUpperCase() ===
                                              Constant.AssignmentStatus.NEW
                                            ? t("Assignments.Begin")
                                            : assignment.assignmentStatus
                                                ?.toString()
                                                .toLowerCase() === "in progress"
                                            ? t("Assignments.Begin")
                                            : assignment &&
                                              (assignment.assignmentStatus
                                                ?.toString()
                                                .toUpperCase() ===
                                                Constant.AssignmentStatus
                                                  .ATTEMPTED ||
                                                assignment.assignmentStatus
                                                  ?.toString()
                                                  .toUpperCase() ===
                                                  Constant.AssignmentStatus
                                                    .COMPLETE)
                                            ? t("Assignments.AttemptAgain")
                                            : assignment &&
                                              assignment.assignmentStatus
                                                ?.toString()
                                                .toUpperCase() ===
                                                Constant.AssignmentStatus
                                                  .COMPLETE
                                            ? t("Assignments.AttemptAgain")
                                            : ""}

                                          {assignment &&
                                            assignment.assignmentStatus
                                              ?.toString()
                                              .toUpperCase() ===
                                              Constant.AssignmentStatus.NEW && (
                                              <RightArrowIcon className="h-6 w-6 ml-1 inline-block" />
                                            )}
                                        </span>
                                      )
                                    }

                                    {
                                      //Group Assignments Without Expand
                                      assignment.isGroupedAssignment && (
                                        <span
                                          onClick={() => onGroupIconChange(index, assignment)}
                                          className={`bg-blues ${
                                            (
                                              assignment.isGroupedAssignment &&
                                              assignment.assignmentStatus
                                                ?.toUpperCase()
                                                .toString() ===
                                                Constant.AssignmentStatus
                                                  .INPROGRESS
                                                ? true
                                                : false
                                            )
                                              ? "bg-site-orange"
                                              : ""
                                          } ${
                                            isPastDate(assignment.endDate)
                                              ? "bg-site-pink"
                                              : ""
                                          } 
                                          hover:bg-secondary-teal transition-all hover:text-gray-100 inline-block whitespace-nowrap rounded-lg text-sm w-full px-2 h-10 flex items-center justify-center text-center py-2 text-white cursor-pointer`}
                                        >
                                          {expandedEvent &&
                                              expandedEvent.index.includes(index) ? (
                                                "- " + t("Assignments.CollapseView")
                                              )
                                              : ("+ " + t("Assignments.ExpandToView"))}
                                        </span>
                                      )
                                    }
                                  </>
                                )}
                              </div>
                            </td>
                          </tr>
                        }

                        {assignment.isGroupedAssignment &&
                          expandedEvent &&
                          expandedEvent.index.includes(index) &&
                          activities?.map((item, idx) => (
                            <tr
                              key={idx}
                              className={`${
                                assignment.assignmentId === item.assignmentId
                                  ? ""
                                  : "hidden"
                              } border-b-2`}
                            >
                              <td className="w-10 px-6 py-4 bg-gray-200"></td>
                              <td className="px-3 py-4 border-r-2 border-gray-100">
                                <div className="flex items-center">
                                  {item.activityType &&
                                    item.activityType ===
                                      Constant.AssignmentActivityType
                                        .ASSESSMENT && (
                                      <span title="Assessments">
                                        <AssignmentAssessmentIcon
                                          is_saved={
                                            item.assignmentStatus?.toUpperCase() ===
                                            Constant.AssignmentStatus.SAVE
                                              ? 1
                                              : 0
                                          }
                                          past_due={
                                            isPastDate(assignment.endDate)
                                              ? 1
                                              : 0
                                          }
                                        />
                                      </span>
                                    )}
                                  {item.activityType &&
                                    item.activityType ===
                                      Constant.AssignmentActivityType
                                        .PREBUILTTESTS && (
                                      <span title="Generated test">
                                        <PreBuildIcon
                                          is_saved={
                                            item.assignmentStatus?.toUpperCase() ===
                                            Constant.AssignmentStatus.SAVE
                                              ? 1
                                              : 0
                                          }
                                          past_due={
                                            isPastDate(assignment.endDate)
                                              ? 1
                                              : 0
                                          }
                                        />
                                      </span>
                                    )}
                                  {item.activityType ===
                                    Constant.AssignmentActivityType
                                      .PERFORMANCETASKS && (
                                    <span title="performance task">
                                      <AssignmentPerformanceTaskIcon
                                        is_saved={
                                          item.assignmentStatus?.toUpperCase() ===
                                          Constant.AssignmentStatus.SAVE
                                            ? 1
                                            : 0
                                        }
                                        past_due={
                                          isPastDate(assignment.endDate) ? 1 : 0
                                        }
                                      />
                                    </span>
                                  )}
                                  {item.activityType ===
                                    Constant.AssignmentActivityType
                                      .VIDEOLESSON && (
                                    <span title="Video">
                                      <AssignmentVideoIcon
                                        is_saved={
                                          item.assignmentStatus?.toUpperCase() ===
                                          Constant.AssignmentStatus.SAVE
                                            ? 1
                                            : 0
                                        }
                                        past_due={
                                          isPastDate(assignment.endDate) ? 1 : 0
                                        }
                                      />
                                    </span>
                                  )}
                                  {item.activityType ===
                                    Constant.AssignmentActivityType
                                      .PRACTICEQUESTIONS && (
                                    <span title="questions">
                                      <AssignmentQuestionsIcon
                                        is_saved={
                                          item.assignmentStatus?.toUpperCase() ===
                                          Constant.AssignmentStatus.SAVE
                                            ? 1
                                            : 0
                                        }
                                        past_due={
                                          isPastDate(assignment.endDate) ? 1 : 0
                                        }
                                      />
                                    </span>
                                  )}
                                  {item.activityType ===
                                    Constant.AssignmentActivityType
                                      .CONSTRUCTEDRESPONSE && (
                                    <span title="constructed response">
                                      <ConstructedResponseIcon
                                        is_saved={
                                          item.assignmentStatus?.toUpperCase() ===
                                          Constant.AssignmentStatus.SAVE
                                            ? 1
                                            : 0
                                        }
                                        past_due={
                                          isPastDate(assignment.endDate) ? 1 : 0
                                        }
                                      />
                                    </span>
                                  )}
                                </div>
                              </td>
                              <td className="px-3 py-4 border-r-2 border-gray-100">
                                <div className="text-sm text-gray-700 capitalize font-normal">
                                  {getActivityName(
                                    item,
                                    props.profile?.plLanguageId ?? 1
                                  )}
                                </div>
                              </td>
                              <td className="px-3 py-4 text-sm text-gray-500 border-r-2 border-gray-100">
                                <div className="text-sm text-gray-700 capitalize font-normal">
                                  {assignment.teacherName}
                                </div>
                                <div className="text-sm text-gray-800 capitalize font-medium">
                                  {assignment.className}
                                </div>
                              </td>
                              <td className="px-3 py-4 text-left text-sm border-r-2 border-gray-100">
                                {item.minimumScore > 0 && (
                                    <div>
                                      Minimum score required - {item.minimumScore}%
                                    </div>
                                )}
                              </td>
                              <td
                                className={`${
                                  item &&
                                  item.multipleAttempt > 0 &&
                                  item.multipleAttempt === item.totalAttempt &&
                                  item.assignmentStatus?.toUpperCase() ===
                                    Constant.AssignmentStatus.COMPLETE
                                    ? "cursor-text"
                                    : "px-3 py-4"
                                }`}
                              >
                                <div className="flex justify-center cursor-not-allowed">
                                  {assignment.isLocked || item.isLocked ? (
                                    <LockIcon />
                                  ) : (
                                    <span
                                      onClick={() => {
                                        item.sessionActivityType !==
                                        "ASSESSMENT"
                                          ? startAssignmentActivity(
                                              assignment.subjectId,
                                              item.assignmentActivityId,
                                              getActivityName(
                                                item,
                                                props.profile?.plLanguageId ?? 1
                                              ),
                                              item.multipleAttempt,
                                              assignment.assignmentId,
                                              item.learnosityIdEnglish,
                                              item.learnosityIdSpanish,
                                              item.activityType
                                            )
                                          : startAssessmentActivity(
                                              item.code,
                                              item.subjectId
                                            );
                                      }}
                                      className={`bg-blues ${
                                        item &&
                                        item.multipleAttempt > 0 &&
                                        item.multipleAttempt ===
                                          item.totalAttempt &&
                                        item.assignmentStatus?.toUpperCase() ===
                                          Constant.AssignmentStatus.COMPLETE
                                          ? "pointer-events-none  bg-opacity-50  bg-blues "
                                          : (item.assignmentStatus
                                              ?.toString()
                                              .toUpperCase() ===
                                              Constant.AssignmentStatus.NEW &&
                                              !item.isEnable) ||
                                            (item.completeInOrder &&
                                              idx > 0 &&
                                              activities[idx - 1].score <
                                                activities[idx - 1]
                                                  .minimumScore)
                                          ? " pointer-events-none text-opacity-70 text-white bg-opacity-50  bg-blues "
                                          : "hover:opacity-90 inline-block text-white bg-blues"
                                      }
                                       ${
                                         item.assignmentStatus
                                           ?.toString()
                                           .toUpperCase() ===
                                           Constant.AssignmentStatus.SAVE ||
                                         item.assignmentStatus
                                           ?.toString()
                                           .toLowerCase() === "in progress"
                                           ? "bg-site-orange"
                                           : ""
                                       } ${
                                        isPastDate(assignment.endDate)
                                          ? "bg-site-pink"
                                          : ""
                                      } 
                                    
                                    ${
                                      item &&
                                      item.multipleAttempt > 0 &&
                                      item.multipleAttempt === item.totalAttempt
                                        ? "text-white "
                                        : ""
                                    } rounded-lg whitespace-nowrap text-sm px-2 h-10 w-full hover:bg-secondary-teal transition-all hover:text-gray-100 text-center py-2 cursor-pointer `}
                                    >
                                      {item.assignmentStatus?.toUpperCase() ===
                                      Constant.AssignmentStatus.SAVE
                                        ? t("Assignments.Continue")
                                        : item.assignmentStatus
                                            ?.toString()
                                            .toUpperCase() ===
                                          Constant.AssignmentStatus.NEW
                                        ? t("Assignments.Begin")
                                        : item.assignmentStatus
                                            ?.toString()
                                            .toLowerCase() === "in progress"
                                        ? t("Assignments.Continue")
                                        : item &&
                                          item.multipleAttempt > 0 &&
                                          item.multipleAttempt ===
                                            item.totalAttempt &&
                                          (item.assignmentStatus
                                            ?.toString()
                                            .toUpperCase() ===
                                            Constant.AssignmentStatus
                                              .ATTEMPTED ||
                                            item.assignmentStatus
                                              ?.toString()
                                              .toUpperCase() ===
                                              Constant.AssignmentStatus
                                                .COMPLETE)
                                        ? "Attempt Again"
                                        : item &&
                                          item.assignmentStatus
                                            ?.toString()
                                            .toUpperCase() ===
                                            Constant.AssignmentStatus.COMPLETE
                                        ? "Attempt Again"
                                        : ""}

                                      {item &&
                                        item.assignmentStatus
                                          ?.toString()
                                          .toUpperCase() ===
                                          Constant.AssignmentStatus.NEW && (
                                          <RightArrowIcon className="h-6 w-6 ml-1 inline-block" />
                                        )}
                                    </span>
                                  )}
                                </div>
                              </td>
                            </tr>
                          ))}
                      </Fragment>
                    );
                  })}

                  {assignmentData?.length === 0 && (
                    <tr className="bg-white">
                      <td colSpan={6} className="px-6 xl:px-2 2xl:px-6 py-4">
                        {t("Assignments.NoRecordsFound")}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(AssignmentList);

import { Modal, ModalBody } from "@windmill/react-ui";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
  getStudentProfile,
  // UpdateNWEAResult,
} from "../../../../../api/student/studentProfile";
import {
  EnglishIcon,
  LoadingIcon,
  SocialStudiesIcon,
} from "../../../../../assets/icons";
import Profile from "../../../../../model/users/profile";
import StudentProfile from "../../../../../model/student/studentProfile";
import { fetchProfile } from "../../../../../redux/actions/userActions";
import * as userContextAction from "../../../../../redux/actions/userContextAction";
import { UserContextState } from "../../../../../redux/actions/userContextAction";
import { StudyType } from "../../../../../types/type";
import Constant from "../../../../../utils/constant/constant";
import LiftOffDiagnostics from "../../shared/liftoff/liftOffDiagnostics";
import LiftOffMyGalaxy from "../../shared/liftoff/liftOffMyGalaxy";
import SkillStandard from "../../skillPractice/elementary/skillStandard";
import AlienArenaElementary from "../arena/alienArenaElementary";
import Assignments from "../assignment/assignments";
import CollapsibleButton from "../home/collapsibleButton";
import GalaxyStarPopup from "../popup/galaxyStar";
import RocketPopup from "../popup/rocket";
import AlienAvatar from "../../shared/alienAvatar/alienAvatar";
import AlienRanking from "../../shared/alienRanking/alienRanking";
import GalaxyStars from "./popup/galaxyStar";
import Rockets from "../../shared/rockets/rockets";
import Blasters from "../../shared/blasters/blasters";
import Loader from "../../../../shared/loader";
import AssignmentsMiddleschool from "../../middleSchool/assignments/assignments";
// import * as uuid from "uuid";

import { getStudentRewards } from "../../../../../api/student/studentApi";
import { getNewAssignmentsCount } from "../../../../../api/student/studentAssignmentApi";
import { IStudentReward } from "../../../../../model/interface/studentReward";
import { getDefaultRewards } from "../../../../../utils/studentHelper";

interface HomeProps {
  userContext?: UserContextState;
  profile?: Profile;
  setUserContext?: (context: any) => void;
  setProfile?: (
    userId: number,
    roleId: number,
    schoolId: number,
    id: number
  ) => void;
}
interface StudyTypeDisplay {
  name: StudyType;
  background: string;
  border: string;
  collapsibleSubjects: boolean;
  localization: string;
  message: string;
}

const Home = (props: HomeProps) => {
  const [showLoader, setLoader] = useState(false);
  const [rewards, setRewards] = useState<IStudentReward>(getDefaultRewards());
  const isIntegratingNWEAResult = false;
  const [studentNewAssignmentsCount, setStudentNewAssignmentsCount] =
    useState<number>(0);
  const studyTypes = [
    {
      name: "Assignments",
      background: "bg-[#00b1fb]",
      border: "",
      collapsibleSubjects: true,
      localization: "LiftOffElementaryHome.Assignments",
    } as StudyTypeDisplay,
    {
      name: "Diagnostics",
      background: "bg-[#d2ff00]",
      border: "border-[#99bb07]",
      collapsibleSubjects: false,
      localization: "LiftOffElementaryHome.Diagnostics",
    } as StudyTypeDisplay,
    {
      name: "My Galaxies",
      background: "bg-[#fe3bac]",
      border: "border-[#e3178d]",
      collapsibleSubjects: true,
      localization: "LiftOffElementaryHome.MyGalaxy",
    } as StudyTypeDisplay,
    // {
    //   name: "Alien Arena",
    //   background: "bg-[#ff4655]",
    //   collapsibleSubjects: false,
    //   localization: "LiftOffElementaryHome.AlienArena",
    // } as StudyTypeDisplay,
  ];

  const { t } = useTranslation();
  const defaultLiftOffStudyType = studyTypes[0];
  const [showGalaxyStarPopup, setGalaxyStarPopup] = useState(false);
  const [showRocketPopup, setRocketPopup] = useState(false);
  const [selectedStudyType, setSelectedStudyType] = useState(
    Constant.StudyType.ASSIGNMENTS
  );
  const [studyTypebackground, setStudyTypeBackground] = useState(
    defaultLiftOffStudyType.background
  );
  const [studyTypeborder, setStudyTypeBorder] = useState(
    defaultLiftOffStudyType.border
  );
  const [selectedSubject, setSelectedSubject] = useState(0);
  const [subjectName, setSubjectName] = useState("");
  const [studentProfile, setStudentProfile] = useState<StudentProfile>();
  const refreshLiftOffDiagnostics = true;

  function setSubject(
    subjectId: number,
    studyType: string,
    subjectName: string
  ) {
    setSelectedSubject(subjectId);
    setSelectedStudyType(studyType);
    setSubjectName(subjectName);
    setBackgroundOfStudyType(studyType);
    setBorderOfStudyType(studyType);
  }
  function getNewAssignmentCount() {
    getNewAssignmentsCount(props.userContext!.userId).then((response) => {
      setStudentNewAssignmentsCount(response.data);
    });
  }

  function setBackgroundOfStudyType(studyType: string) {
    const st = studyTypes.find((x) => x.name === studyType);
    if (st !== undefined) setStudyTypeBackground(st.background);
  }

  function setBorderOfStudyType(studyType: string) {
    const st = studyTypes.find((x) => x.name === studyType);
    if (st !== undefined) setStudyTypeBorder(st.border);
  }

  function bindStudentProfile() {
    getStudentProfile(
      props.userContext!.userId,
      props.profile?.gradeId ? props.profile?.gradeId : 0
    ).then((response) => {
      setStudentProfile(response.data);
    });
  }
  async function handleStudyTypeSelection(studyType: StudyTypeDisplay) {
    setSelectedStudyType(studyType.name);
    setBackgroundOfStudyType(studyType.name);
    setBorderOfStudyType(studyType.name);
  }

  // const runNEWASync = async (e) => {
  //   e.stopPropagation();
  //   setRefreshLiftOffDiagnostics(false);
  //   if (props.profile?.nweaId !== uuid.NIL) {
  //     let isExist: boolean = false;
  //     if (!isExist) {
  //       setIsIntegratingNWEAResult(true);
  //       await UpdateNWEAResult(props.userContext!.userId);
  //       setIsIntegratingNWEAResult(false);
  //       setRefreshLiftOffDiagnostics(true);
  //     }
  //   }
  // };

  useEffect(() => {
    bindStudentProfile();
    getNewAssignmentCount();
  }, []);
  useEffect(() => {
    setLoader(true);
    getStudentRewards(props.userContext?.userId ?? 0).then((d) => {
      setLoader(false);
      setRewards(d.data);
    });
  }, []);
  return (
    <Fragment>
      {showLoader && <Loader></Loader>}
      <div className="flex h-full">
        <main className="bg-white h-full w-full mb-4 rounded-2xl p-5">
          <div className="grid   lg:grid-cols-4 xl:grid-cols-5 gap-4 xl:gap-4 xxxl:gap-60">
            <div className="flex flex-col md:flex-row gap-4 md:col-span-2">
              {studentProfile && (
                <AlienRanking
                  studentId={props.userContext!.userId}
                  gradeId={props.profile?.gradeId ? props.profile?.gradeId : 0}
                  firstName={props.profile?.firstName!}
                  lastName={props.profile?.lastName!}
                  type="LO"
                />
              )}
              {studentProfile && (
                <AlienAvatar
                  studentId={props.userContext!.userId}
                  gradeId={props.profile?.gradeId ? props.profile?.gradeId : 0}
                  alienData={studentProfile.loAvatar}
                  showOnQuestions={studentProfile.plShowAvatarOnQuestions}
                  studentGradeMode="LiftOff"
                  isLiftOff={true}
                  rewards={rewards}
                />
              )}
            </div>
            <div className="flex h-full items-end col-span-1 md:col-span-3 m-auto w-full ">
              <div className="grid grid-cols-1 xs:grid-cols-3 sm:grid-cols-3 md:grid-cols-7 gap-4 h-full w-full">
                <GalaxyStars rewards={rewards} type="LO" />
                <Rockets rewards={rewards} type="LO" />
                <Blasters
                  userContext={props.userContext}
                  rewards={rewards}
                  type="LO"
                />
              </div>
            </div>
          </div>
          <div className="mt-10">
            <div className="grid grid-cols-1 xl:grid-cols-12 gap-0 xl:gap-6">
              <div className="col-span-4 xl:block md:gap-4 xl:gap-0 space-y-4 xl:space-y-6 md:mb-4 xl:mb-0">
                {studyTypes.map((studyType, index) => {
                  return (
                    <Fragment key={index}>
                      {studyType.collapsibleSubjects && (
                        <CollapsibleButton
                          studyType={studyType.name}
                          onClick={setSubject}
                          newAssignmentsCount={studentNewAssignmentsCount}
                        ></CollapsibleButton>
                      )}
                      {!studyType.collapsibleSubjects && (
                        <div
                          className={`block ${studyType.background} p-4 rounded-2xl`}
                          onClick={() => {
                            handleStudyTypeSelection(studyType);
                          }}
                        >
                          {(studyType.name !== "Diagnostics" ||
                            !isIntegratingNWEAResult) && (
                            <div className="cursor-pointer">
                              <div className="flex justify-between">
                                <h2 className="text-lg py-1">
                                  {t(studyType.localization)}
                                </h2>
                                {/* {props.profile?.nweaId !== uuid.NIL &&
                                  !props.profile?.isNWEADisabled && (
                                    <button
                                      onClick={(e) => runNEWASync(e)}
                                      className="align-bottom items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-3 py-1 rounded-md text-sm text-white border border-transparent bg-yellow-300 text-gray-700 hover:bg-yellow-400"
                                      type="button"
                                    >
                                      {t("LiftOffDiagnosticStatus.NWEASync")}
                                    </button>
                                  )} */}
                              </div>
                            </div>
                          )}

                          {studyType.name === "Diagnostics" &&
                            isIntegratingNWEAResult && (
                              <div className="cursor-pointer flex">
                                <h2 className="text-lg py-1 pr-1">
                                  {t("LiftOffElementaryHome.NWEAMessage")}
                                </h2>
                                <LoadingIcon></LoadingIcon>
                              </div>
                            )}
                        </div>
                      )}
                    </Fragment>
                  );
                })}
              </div>
              <div className="col-span-8 h-full mt-8 md:mt-4 xl:mt-0">
                <div
                  className={`xl:h-full ${studyTypebackground} rounded-2xl relative`}
                >
                  {selectedStudyType.toLocaleUpperCase() !==
                    Constant.StudyType.ALIENARENA &&
                    selectedStudyType.toLocaleUpperCase() !==
                      Constant.StudyType.DIAGNOSTICS &&
                    selectedStudyType.toLocaleUpperCase() !==
                      Constant.StudyType.ASSIGNMENTS && (
                      <span className="mt-5 left-2 sm:left-6 lg:left-8 relative inline-flex items-center text-sm bg-white rounded-full px-4 py-2 bg-opacity-70">
                        {subjectName === "Math" && (
                          <img
                            className="w-6 mr-2"
                            src="https://assets.progresslearning.com/images/content-area/math.svg"
                          />
                        )}
                        {subjectName === "SS" && (
                          <SocialStudiesIcon className="h-5 mr-2 flex-shrink-0 m-auto filter invert" />
                        )}
                        {subjectName === "Reading" && (
                          <EnglishIcon className="w-6 mr-2 flex-shrink-0 m-auto filter invert" />
                        )}
                        {subjectName}
                      </span>
                    )}
                  <div className=" mx-auto space-y-3 px-2 py-4 sm:flex-col w-full sm:px-6 lg:px-8">
                    {selectedStudyType.toLocaleUpperCase() ===
                      Constant.StudyType.ALIENARENA && (
                      <AlienArenaElementary
                        userId={props.userContext!.userId}
                        roleId={props.userContext!.roleId}
                      ></AlienArenaElementary>
                    )}
                    {selectedStudyType.toLocaleUpperCase() ===
                      Constant.StudyType.ASSIGNMENTS &&
                      (props.userContext?.gradeId! > Constant.Grade.GRADE5 ? (
                        <AssignmentsMiddleschool />
                      ) : (
                        <Assignments />
                      ))}
                    {selectedStudyType.toLocaleUpperCase() ===
                      Constant.StudyType.SKILLPRACTICE && (
                      <SkillStandard skillSubjectId={selectedSubject} />
                    )}
                    {selectedStudyType.toLocaleUpperCase() ===
                      Constant.StudyType.DIAGNOSTICS && (
                      <LiftOffDiagnostics
                        userId={props.userContext?.userId}
                        border={studyTypeborder}
                        refresh={refreshLiftOffDiagnostics}
                      ></LiftOffDiagnostics>
                    )}
                    {selectedStudyType.toLocaleUpperCase() ===
                      "MY GALAXIES" && (
                      <LiftOffMyGalaxy
                        subjectId={selectedSubject}
                        userId={props.userContext?.userId}
                        border={studyTypeborder}
                        subjectName={subjectName}
                      ></LiftOffMyGalaxy>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>

      {/* <Modal
        isOpen={showAlienRankPopup}
        onClose={() => setShowAlienRankPopup(false)}
      >
        <ModalBody>
          <div onClick={() => setShowAlienRankPopup(false)}>
            <button
              className="inline-flex items-center justify-center w-6 h-6 text-gray-400 transition-colors duration-150 rounded dark:hover:text-gray-200 hover: hover:text-gray-700"
              aria-label="close"
            >
              <svg
                className="w-4 h-4"
                fill="currentColor"
                viewBox="0 0 20 20"
                role="img"
                aria-hidden="true"
              >
                <path
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                  fillRule="evenodd"
                ></path>
              </svg>
            </button>
            <AlienRankPopup
              studentId={props.userContext!.userId}
              gradeId={props.profile?.gradeId ? props.profile?.gradeId : 0}
            ></AlienRankPopup>
          </div>
        </ModalBody>
      </Modal> */}

      <Modal
        isOpen={showGalaxyStarPopup}
        onClose={() => setGalaxyStarPopup(false)}
      >
        <ModalBody>
          <div onClick={() => setGalaxyStarPopup(false)}>
            <GalaxyStarPopup></GalaxyStarPopup>
          </div>
        </ModalBody>
      </Modal>

      <Modal isOpen={showRocketPopup} onClose={() => setRocketPopup(false)}>
        <ModalBody>
          <div onClick={() => setRocketPopup(false)}>
            <button
              className="inline-flex items-center justify-center w-6 h-6 text-gray-400 transition-colors duration-150 rounded dark:hover:text-gray-200 hover: hover:text-gray-700"
              aria-label="close"
            >
              <svg
                className="w-4 h-4"
                fill="currentColor"
                viewBox="0 0 20 20"
                role="img"
                aria-hidden="true"
              >
                <path
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                  fillRule="evenodd"
                ></path>
              </svg>
            </button>
            <RocketPopup></RocketPopup>
          </div>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setUserContext: (payload: userContextAction.UserContextState) => {
      dispatch(userContextAction.setUserContext(payload));
    },
    setProfile: (
      userId: number,
      roleId: number,
      schoolId: number,
      districtId: number
    ) => {
      dispatch(fetchProfile(userId, roleId, schoolId, districtId));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);

import Constant from "../../utils/constant/constant";
import routeConstant from "../../utils/constant/routeConstant";

function AccountExpired(props: any) {
  const userRoleId = props.location?.state?.userRoleId ?? 0;
  function handleGoBack() {
    window.location.replace(routeConstant.ROUTE_LOGIN);
  }
  return (
    <div className="bg-gradient-to-b shadow-xl from-primary-violet to-dark-teal h-screen flex justify-center items-center">
      <div className=" bg-white w-full  md:w-3/4 xl:w-1/2 xxxl:w-1/3 flex flex-col items-center py-12 px-8 rounded-lg mx-8 lg:mx-0">
        <span>
          {userRoleId !== Constant.UserRoleId.Student && (
            <span>
              <h2 className="text-red-400 text-4xl font-semibold text-center">
                Your account is fully expired.
              </h2>
              <p className="text-gray-700 text-2xl text-center mt-3">
                To request a quote to renew your subscription, please contact us
                at{" "}
                <span className="text-primary-violet">
                  {Constant.PhoneNumber}
                </span>
                , or email{" "}
                <span className="text-primary-violet">{Constant.QuotesEmail}</span>
              </p>
            </span>
          )}
          {userRoleId == Constant.UserRoleId.Student && (            
            <span>
              <h2 className="text-red-400 text-4xl font-semibold text-center">
                Your account is fully expired.
              </h2>
              <p className="text-gray-700 text-2xl text-center mt-3">
              If you believe that this is an error, please reach out to your teacher or school administrator.
              </p>              
            </span>
          )}
        </span>
        <a
          className="bg-light-violet cursor-pointer hover:opacity-90 mt-8 px-8 h-12 xxxl:h-14 text-lg xxxl:text-xl flex items-center justify-center text-white hover:text-white font-Barlow font-semibold"
          onClick={handleGoBack}
        >
          Go to login screen
        </a>
      </div>
    </div>
  );
}

export default AccountExpired;

import { XIcon } from "@heroicons/react/outline";

import { Fragment } from "react";
import { connect } from "react-redux";

function NonEmailLoginUsers(props: any) {
  const handleShowPopup = (value: boolean) => {
    props.showPopup(value);
  };
  return (
    <Fragment>
      <div className="fixed inset-0 z-40 flex items-center bg-black bg-opacity-50 sm:items-center sm:justify-center">
        <div
          className="w-full flex flex-col justify-between overflow-hidden  h-auto max-h-80percent bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:max-w-5xl"
          role="dialog"
        >
          <header>
            {/* Header */}
            <div className="px-4 py-4 bg-gray-50">
              <div className="flex items-start justify-between space-x-3">
                <div className="space-y-1">
                  <div className="text-lg font-medium text-gray-900">
                    <h2 className="text-lg text-gray-700 mb-2">
                      {" "}
                      Login information could not be provided to the following
                      users due to missing email addresses:{" "}
                    </h2>
                  </div>
                </div>
                <div className="h-7 flex items-center">
                  <button
                    type="button"
                    className="text-gray-400 hover:text-gray-500"
                    onClick={() => handleShowPopup(false)}
                  >
                    <span className="sr-only">Close panel</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
          </header>

          <div className="border-t m-4 pt-2 overflow-auto">
            <table className="table-fixed w-full text-sm bg-gray-50 rounded-lg">
              <thead className=" rounded-2xl overflow-hidden">
                <tr className="bg-light-violet text-white py-3 px-3">
                  <th className="text-left  p-3 text-sm font-normal">
                    First Name
                  </th>
                  <th className="text-left  p-3 text-sm font-normal">
                    Last Name
                  </th>
                  <th className="text-left  p-3 text-sm font-normal">
                    Username
                  </th>
                  <th className="text-left  p-3 text-sm font-normal">Grade</th>
                </tr>
              </thead>
              <tbody>
                {props.users.map((user, index) => {
                  return (
                    <tr
                      className={
                        index % 2 === 0
                          ? " hover:bg-light-violet/20 transition ease-in-out duration-300 bg-white"
                          : "hover:bg-light-violet/20 transition ease-in-out duration-300 bg-light-violet/10"
                      }
                      key={index}
                    >
                      <td className="p-3 whitespace-nowrap text-sm text-gray-500">
                        {user.firstName}
                      </td>
                      <td className="p-3 whitespace-nowrap text-sm text-gray-500">
                        {user.lastName}
                      </td>
                      <td className="p-3 whitespace-nowrap text-sm text-gray-500">
                        {user.username}
                      </td>
                      <td className="p-3 whitespace-nowrap text-sm text-gray-500">
                        {user.grade}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          {/* <!-- Action Buttons -->  */}
          <footer className="flex flex-col items-center justify-end px-6 py-4 sm:flex-row bg-gray-50 dark:bg-gray-800">
            <div className="flex-shrink-0">
              <div className="space-x-3 flex justify-end">
                <button
                  className="align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 rounded-lg text-sm text-gray-600 border-gray-300 border dark:text-gray-400 focus:outline-none active:bg-transparent hover:border-gray-500 focus:border-gray-500 active:text-gray-500 focus:shadow-outline-gray sm:w-auto"
                  type="button"
                  onClick={() => {
                    handleShowPopup(false);
                  }}
                >
                  Ok
                </button>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </Fragment>
  );
}

const mapStateToProps = (state: any) => {
  return {
    masterData: state.masterData,
    userContext: state.userContext,
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(NonEmailLoginUsers);

import { Multiselect } from "multiselect-react-dropdown";
import React, { Fragment, useEffect } from "react";

export interface MultiselectProps {
  handleOnSelect: (selectedList: any, selectedItem: any) => void;
  handleOnRemove: (selectedList, selectedItem) => void;
  placeholder?: string;
  options: any[];
  selectedOptions: any[];
  displayValue: string;
  showCheckbox?: boolean;
  isValid: boolean;
}

const MultiselectComponent: React.FC<MultiselectProps> = (props) => {
  useEffect(() => {});

  const handleOnSelect = (selectedList, selectedItem) => {
    props.handleOnSelect(selectedList, selectedItem);
  };

  const handleOnRemove = (selectedList, removedItem) => {
    props.handleOnRemove(selectedList, removedItem);
  };

  const style = {
    chips: {
      background: "#E5E7EB",
      color: "#374151",
    },
    searchBox: {
      border: "1px solid rgb(209, 213, 219)",
      "border-radius": "5px",
      padding: "5px 15px",
      "max-height": "105px",
      height: "100%",
      overflow: "auto",
      "&:focus": {
        border: "1px solid #000",
      },
    },
    multiselectContainer: {
      color: "#374151",
    },
    highlight: {
      background: "#F3F4F6",
    },
  };

  style.searchBox.border = props.isValid
    ? "1px solid rgb(209, 213, 219)"
    : "1px solid rgba(239, 68, 68, var(--tw-border-opacity))";

  return (
    <Fragment>
      <Multiselect
        showCheckbox={props.showCheckbox}
        placeholder={props.placeholder}
        options={props.options}
        displayValue={props.displayValue}
        onSelect={(selectedList, selectedItem) => {
          handleOnSelect(selectedList, selectedItem);
        }}
        onRemove={(selectedList, removedItem) => {
          handleOnRemove(selectedList, removedItem);
        }}
        selectedValues={props.selectedOptions}
        style={style}
      ></Multiselect>
    </Fragment>
  );
};

export default MultiselectComponent;

import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { UserContextState } from "../../../../redux/actions/userContextAction";
import Constant from "../../../../utils/constant/constant";
import RouteConstant from "../../../../utils/constant/routeConstant";
import Permissions from "../../../../utils/permissions";
import AccessDenied from "../../../shared/accessDenied";
import AllowedTo from "../../../shared/rbac";
import MyAssignment from "../assignment/myAssignment";
import SchoolAssignment from "../assignment/schoolAssignment";
import RemediationAssignment from "./remediationAssignment";
import SharedAssignment from "./classAssignment";
import Breadcrumb from "../../teacher/shared/breadcrumb";
import { IBreadcrumb } from "../../../../model/common/breadcrumb";
import { useTranslation } from "react-i18next";
import { isPermissionExist } from "../../../../utils/helper";
import { getAssignmentBankUpgradePermission } from "../../../shared/permissionHelper";
import UpGradeMessagePopup from "../../../shared/upGradeMessagePopup";
import constant from "../../../../utils/constant/constant";
import { GetSchoolYears } from "../../../../api/teacher/school";
import { ISchoolYear } from "../../../../model/teacher/schoolYear";
import Moment from "moment";
import { getEndDate, getStartDate } from "../../../../utils/dateHelper";
interface passedProps {
  userContext: UserContextState;
}

function AssignmentBank(props: passedProps) {
  const { t } = useTranslation();
  const [assignmentType, setAssignmentType] = useState(
    Constant.AssignmentType.MYASSIGNMENT
  );
  const [upgrade, setUpgrade] = useState(false);
  const [showUpgradePopup, setShowUpgradePopup] = useState<boolean>(false);
  const [currentSchoolYearId, setCurrentSchoolYearId] = useState<number>();
  const [schoolYearData, setSchoolYearData] = useState<Array<ISchoolYear>>();
  const [schoolYearMinDate, setSchoolYearMinDate] = useState<Date>();
  const [schoolYearMaxDate, setSchoolYearMaxDate] = useState<Date>();

  useEffect(() => {
    checkUpgradeRequired().then(r => {
      getSchoolYears([1, 0, -1]);
    });
  }, []);

  function getSchoolYears(offsets: Array<number>) {
    GetSchoolYears(props.userContext.schoolId).then((response) => {
      if (response.data && response.data.length > 0) {
        let filteredData: Array<ISchoolYear> = response.data;
        let schoolYearId: number = 0;
        if (response.data && response.data.length) {
          if (offsets && offsets.length > 0) {
            filteredData = filteredData.filter((item) =>
              offsets?.some((offset) =>
                item.currentYearOffset.toString().includes(offset.toString())
              )
            );
          }

          setSchoolYearData(filteredData);
          const currentSchoolYear: ISchoolYear = filteredData.filter(
            (x) => x.currentYearOffset === 0
          )[0];
          if (currentSchoolYear) {
            schoolYearId = currentSchoolYear.schoolYearId;
          } else {
            schoolYearId = filteredData[0].schoolYearId;
          }
          setCurrentSchoolYearId(schoolYearId);
          handleDateFilter(filteredData, schoolYearId);
        }
      }
    });
  }

  function handleDateFilter(
    schoolYears: Array<ISchoolYear>,
    schoolYearId: number
  ) {
    let selectedSchoolYear = schoolYears?.filter(
      (s) => s.schoolYearId == schoolYearId
    )[0];
    let minDate = selectedSchoolYear
      ? Moment(
          selectedSchoolYear.startDate.toString().split("T")[0],
          "YYYY-MM-DD"
        ).toDate()
      : new Date();

    let maxDate = selectedSchoolYear
      ? Moment(
          selectedSchoolYear.startDate.toString().split("T")[0],
          "YYYY-MM-DD"
        )
          .add(1, "year")
          .subtract(1, "day")
          .toDate()
      : new Date();

    setSchoolYearMinDate(getStartDate(minDate));
    setSchoolYearMaxDate(getEndDate(maxDate));
  }

  const checkUpgradeRequired = async () => {
    if (getAssignmentBankUpgradePermission(props.userContext.roleId)) {
      setUpgrade(true);
    } else {
      setUpgrade(false);
    }
  };

  const breadcrumbItems = () => {
    const items: Array<IBreadcrumb> = [
      { name: t("Breadcrumb.Home"), url: RouteConstant.ROUTE_DASHBOARD },
      {
        name: "Assessment/Assignment Center",
        url: RouteConstant.TeacherRoutes.assignmentCenter,
      },
      { name: t("Breadcrumb.AssignmentBank"), url: "" },
    ];

    return items;
  };
  return (
    <AllowedTo
      perform={Permissions.assignments_view}
      no={() => <AccessDenied />}
    >
      {showUpgradePopup && (
        <UpGradeMessagePopup
          togglePopup={setShowUpgradePopup}
          roleId={props.userContext?.roleId ?? 0}
          message={constant.UPGRADE_MESSAGE_SCHOOL_ACCOUNT}
        />
      )}
      <div className="min-w-0 w-full">
        <div className="lg:flex lg:items-center px-6 py-3 lg:px-8 border-b border-gray-200">
          <Breadcrumb items={breadcrumbItems()} />
          <div className="lg:w-[55%] text-lg flex justify-between gap-2 h-auto sm:h-10 items-center">
            <h1 className="text-primary-violet font-medium flex justify-start">
              Assignment Bank
            </h1>
          </div>
        </div>
        <div className="flex bg-gray-100 px-2 sm:px-6 lg:px-8 pt-2 flex-col lg:flex-row lg:justify-between">
          <div>
            <nav aria-label="Progress">
              <ol className="flex space-x-2">
                <li
                  className="relative flex flex-col sm:flex-row items-start sm:items-center sm:justify-center"
                  onClick={() =>
                    setAssignmentType(Constant.AssignmentType.MYASSIGNMENT)
                  }
                >
                  <a
                    href="#!"
                    className={`relative flex items-center border-t-2 ${
                      assignmentType === Constant.AssignmentType.MYASSIGNMENT
                        ? "border-secondary-teal bg-white py-3 tab-box-shadow tab-box-shadow"
                        : "bg-primary-violet/10 py-[0.6rem] mt-auto transition-all hover:py-3 border-[#dee9f1]"
                    }  `}
                    x-description="Complete Step"
                  >
                    <span
                      className={`px-2 sm:px-4 min-w-0 flex flex-col ${
                        assignmentType === Constant.AssignmentType.MYASSIGNMENT
                          ? " text-primary-violet hover:text-dark-violet"
                          : "text-primary-violet text-opacity-60 hover:text-opacity-100 hover:text-primary-violet"
                      }`}
                    >
                      <span className="min-w-0 flex flex-col ">
                        <span className="text-sm flex flex-col tracking-wide">
                          <span className="uppercase font-medium">
                            My Assignments
                          </span>
                        </span>
                      </span>
                    </span>
                  </a>
                </li>

                {!upgrade && (
                  <>
                    <li
                      className="relative flex flex-col sm:flex-row items-start sm:items-center sm:justify-center "
                      onClick={() =>
                        setAssignmentType(
                          Constant.AssignmentType.CLASSASSIGNMENT
                        )
                      }
                    >
                      <a
                        href="#!"
                        className={`relative flex items-center border-t-2 ${
                          assignmentType ===
                          Constant.AssignmentType.CLASSASSIGNMENT
                            ? "border-secondary-teal bg-white py-3 tab-box-shadow tab-box-shadow"
                            : "bg-primary-violet/10 py-[0.6rem] mt-auto transition-all hover:py-3 border-[#dee9f1]"
                        }  `}
                        x-description="Complete Step"
                      >
                        <span
                          className={`px-2 sm:px-4 min-w-0 flex flex-col ${
                            assignmentType ===
                            Constant.AssignmentType.CLASSASSIGNMENT
                              ? " text-primary-violet hover:text-dark-violet"
                              : "text-primary-violet text-opacity-60 hover:text-opacity-100 hover:text-primary-violet"
                          }`}
                        >
                          <span className="min-w-0 flex flex-col">
                            <span className="text-sm flex flex-col tracking-wide">
                              <span className="uppercase font-medium">
                                Shared Class Assignments
                              </span>
                            </span>
                          </span>
                        </span>
                      </a>
                    </li>

                    <li
                      className="relative flex flex-col sm:flex-row items-start sm:items-center sm:justify-center "
                      onClick={() =>
                        setAssignmentType(
                          Constant.AssignmentType.SCHOOLASSIGNMENT
                        )
                      }
                    >
                      <a
                        href="#!"
                        className={`relative flex items-center border-t-2 ${
                          assignmentType ===
                          Constant.AssignmentType.SCHOOLASSIGNMENT
                            ? "border-secondary-teal bg-white py-3 tab-box-shadow tab-box-shadow"
                            : "bg-primary-violet/10 py-[0.6rem] mt-auto transition-all hover:py-3 border-[#dee9f1]"
                        }  `}
                        x-description="Complete Step"
                      >
                        <span
                          className={`px-2 sm:px-4 min-w-0 flex flex-col ${
                            assignmentType ===
                            Constant.AssignmentType.SCHOOLASSIGNMENT
                              ? " text-primary-violet hover:text-dark-violet"
                              : "text-primary-violet text-opacity-60 hover:text-opacity-100 hover:text-primary-violet"
                          }`}
                        >
                          <span className="min-w-0 flex flex-col">
                            <span className="text-sm flex flex-col tracking-wide">
                              <span className="uppercase font-medium">
                                School Assignment Bank
                              </span>
                            </span>
                          </span>
                        </span>
                      </a>
                    </li>

                    <li
                      className={`${
                        isPermissionExist(Permissions.remediations_view)
                          ? ""
                          : "hidden"
                      } relative flex flex-col sm:flex-row items-start sm:items-center sm:justify-center `}
                      onClick={() =>
                        setAssignmentType(
                          Constant.AssignmentType.REMEDIATIONASSIGNMENT
                        )
                      }
                    >
                      <a
                        className={`relative flex items-center border-t-2 ${
                          assignmentType ===
                          Constant.AssignmentType.REMEDIATIONASSIGNMENT
                            ? "border-secondary-teal bg-white py-3 tab-box-shadow tab-box-shadow"
                            : "bg-primary-violet/10 py-[0.6rem] mt-auto transition-all hover:py-3 border-[#dee9f1]"
                        }  `}
                        x-description="Complete Step"
                      >
                        <span
                          className={`px-2 sm:px-4 min-w-0 flex flex-col ${
                            assignmentType ===
                            Constant.AssignmentType.REMEDIATIONASSIGNMENT
                              ? " text-primary-violet hover:text-dark-violet"
                              : "text-primary-violet text-opacity-60 hover:text-opacity-100 hover:text-primary-violet"
                          }`}
                        >
                          <span className="min-w-0 flex flex-col ">
                            <span className="text-sm flex flex-col tracking-wide">
                              <span className="uppercase font-medium">
                                Assigned Remediation
                              </span>
                            </span>
                          </span>
                        </span>
                      </a>
                    </li>
                  </>
                )}

                {upgrade && (
                  <>
                    <li
                      className="relative flex flex-col sm:flex-row items-start sm:items-center sm:justify-center"
                      onClick={() => setShowUpgradePopup(true)}
                    >
                      <a
                        href="#!"
                        className={`relative flex items-center border-t-2 ${
                          assignmentType ===
                          Constant.AssignmentType.CLASSASSIGNMENT
                            ? "border-secondary-teal bg-white py-3 tab-box-shadow tab-box-shadow"
                            : "bg-primary-violet/10 py-[0.6rem] mt-auto transition-all hover:py-3 border-[#dee9f1]"
                        }  `}
                        x-description="Complete Step"
                      >
                        <span
                          className={`px-2 sm:px-4 min-w-0 flex flex-col ${
                            assignmentType ===
                            Constant.AssignmentType.CLASSASSIGNMENT
                              ? " text-primary-violet hover:text-dark-violet"
                              : "text-primary-violet text-opacity-60 hover:text-opacity-100 hover:text-primary-violet"
                          }`}
                        >
                          <span className="min-w-0 flex flex-col ">
                            <span className="text-sm flex flex-col tracking-wide">
                              <span className="uppercase font-medium">
                                Shared Class Assignments
                              </span>
                            </span>
                          </span>
                        </span>
                      </a>
                    </li>

                    <li
                      className="relative flex flex-col sm:flex-row items-start sm:items-center sm:justify-center"
                      onClick={() => setShowUpgradePopup(true)}
                    >
                      <a
                        href="#!"
                        className={`relative flex items-center border-t-2 ${
                          assignmentType ===
                          Constant.AssignmentType.SCHOOLASSIGNMENT
                            ? "border-secondary-teal bg-white py-3 tab-box-shadow tab-box-shadow"
                            : "bg-primary-violet/10 py-[0.6rem] mt-auto transition-all hover:py-3 border-[#dee9f1]"
                        }  `}
                        x-description="Complete Step"
                      >
                        <span
                          className={`px-2 sm:px-4 min-w-0 flex flex-col ${
                            assignmentType ===
                            Constant.AssignmentType.SCHOOLASSIGNMENT
                              ? " text-primary-violet hover:text-dark-violet"
                              : "text-primary-violet text-opacity-60 hover:text-opacity-100 hover:text-primary-violet"
                          }`}
                        >
                          <span className="min-w-0 flex flex-col ">
                            <span className="text-sm flex flex-col tracking-wide">
                              <span className="uppercase font-medium">
                                School Assignment Bank
                              </span>
                            </span>
                          </span>
                        </span>
                      </a>
                    </li>

                    <li
                      className={`${
                        isPermissionExist(Permissions.remediations_view)
                          ? ""
                          : "hidden"
                      } relative flex flex-col sm:flex-row items-start sm:items-center sm:justify-center`}
                      onClick={() => setShowUpgradePopup(true)}
                    >
                      <a
                        className={`relative flex items-center border-t-2 ${
                          assignmentType ===
                          Constant.AssignmentType.REMEDIATIONASSIGNMENT
                            ? "border-secondary-teal bg-white py-3 tab-box-shadow tab-box-shadow"
                            : "bg-primary-violet/10 py-[0.6rem] mt-auto transition-all hover:py-3 border-[#dee9f1]"
                        } `}
                        x-description="Complete Step"
                      >
                        <span
                          className={`px-2 sm:px-4 min-w-0 flex flex-col ${
                            assignmentType ===
                            Constant.AssignmentType.REMEDIATIONASSIGNMENT
                              ? " text-primary-violet hover:text-dark-violet"
                              : "text-primary-violet text-opacity-60 hover:text-opacity-100 hover:text-primary-violet"
                          } `}
                        >
                          <span className="min-w-0 flex flex-col ">
                            <span className="text-sm flex flex-col tracking-wide">
                              <span className="uppercase font-medium">
                                Assigned Remediation
                              </span>
                            </span>
                          </span>
                        </span>
                      </a>
                    </li>
                  </>
                )}
              </ol>
            </nav>
          </div>
        </div>
        <div className="px-2 sm:px-6 lg:px-8 py-6">
          <div className="mx-auto lg:gap-4 ">
            <div className="mb-4 pb-4 border-b-2">
              <AllowedTo perform={Permissions.assignments_create}>
                <div className="flex justify-end mb-4">
                  <Link to={RouteConstant.NEWASSIGNMENT}>
                    <button
                      type="submit"
                      className="bg-primary-violet border border-transparent shadow-sm py-2 px-4 justify-end text-sm font-medium text-white hover:bg-dark-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 whitespace-nowrap"
                    >
                      Create New Assignment
                    </button>
                  </Link>
                </div>
              </AllowedTo>
              {assignmentType === Constant.AssignmentType.MYASSIGNMENT &&
                currentSchoolYearId && (
                  <MyAssignment
                    userId={props.userContext.userId}
                    roleId={props.userContext.roleId}
                    schoolYearData={schoolYearData}
                  ></MyAssignment>
                )}

              {assignmentType === Constant.AssignmentType.CLASSASSIGNMENT &&
                currentSchoolYearId && (
                  <SharedAssignment
                    userId={props.userContext.userId}
                    roleId={props.userContext.roleId}
                    schoolYearId={currentSchoolYearId}
                    minDate={schoolYearMinDate}
                    maxDate={schoolYearMaxDate}
                  ></SharedAssignment>
                )}

              {assignmentType === Constant.AssignmentType.SCHOOLASSIGNMENT &&
                currentSchoolYearId && (
                  <SchoolAssignment
                    userId={props.userContext.userId}
                    roleId={props.userContext.roleId}
                    schoolYearData={schoolYearData}
                  ></SchoolAssignment>
                )}

              {assignmentType ===
                Constant.AssignmentType.REMEDIATIONASSIGNMENT &&
                currentSchoolYearId && (
                  <RemediationAssignment
                    userId={props.userContext.userId}
                    roleId={props.userContext.roleId}
                    schoolYearId={currentSchoolYearId}
                    minDate={schoolYearMinDate}
                    maxDate={schoolYearMaxDate}
                  ></RemediationAssignment>
                )}
            </div>
          </div>
        </div>
      </div>
    </AllowedTo>
  );
}

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(AssignmentBank);

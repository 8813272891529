import { AxiosHelper } from "../../environment/axiosConfig";
import APIConstant from "../../utils/constant/apiConstant";
import qs from "qs";
import { getTimezoneOffset } from "../../utils/helper";
import { AxiosResponse } from "axios";
import { IReportData } from "../../model/report/reportData";
import { IStudentCard } from "../../model/interface/report/studentReportCard";
import { ITeacherUsageReport } from "../../model/interface/report/teacherUsageReport";
import { IResult } from "../../model/interface/IResult";
import { AssessmentStudentAttemptsReport } from "../../model/report/resultsReport/StudentAttemptsReport";

export const getAssessmentResultReportHeader = (
  assessmentId: number,
  schoolIds: string,
  classIds: Array<number> | null,
  schoolYearId: number
) => {
  return AxiosHelper.axiosInstance().get(
    APIConstant.RESULTSREPORT_API.GetAssessmentResultReportHeader,
    {
      params: {
        assessmentId: assessmentId,
        schoolIds: schoolIds,
        classIds: classIds ? classIds.join(',') : null,
        schoolYearId: schoolYearId,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      }
    }
  );
};

export const getAssessmentResultReportByStudent = (
  assessmentId: number,
  schoolIds: Array<number> | null,
  classIds: Array<number> | null,
  schoolYearId: number
) => {
  return AxiosHelper.axiosInstance().get(
    APIConstant.RESULTSREPORT_API.GetAssessmentResultReportByStudent,
    {
      params: {
        assessmentId: assessmentId,
        schoolIds: schoolIds,
        classIds: classIds ? classIds.join(',') : null,
        schoolYearId: schoolYearId,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      }
    }
  );
};

export const getAssessmentResultReportByStudentExcel = (
  assessmentId: number,
  schoolIds: string,
  classIds: string,
  schoolYearId: number
) => {
  return AxiosHelper.axiosInstance().get(
    APIConstant.RESULTSREPORT_API.GetAssessmentResultReportByStudentExcel,
    {
      responseType: "blob",
      params: {
        assessmentId: assessmentId,
        schoolIds: schoolIds,
        classIds: classIds,
        timezoneOffset: getTimezoneOffset(),
        schoolYearId: schoolYearId,
      },
    }
  );
};
export const getAssessmentResultReportItemAnalysis = (
  assessmentId: number,
  schoolIds: string | null,
  classIds: string | null,
  schoolYearId: number
) => {
  return AxiosHelper.axiosInstance().get(
    APIConstant.RESULTSREPORT_API.GetAssessmentResultReportItemAnalysis,
    {
      params: {
        assessmentId: assessmentId,
        schoolIds: schoolIds,
        classIds: classIds,
        schoolYearId: schoolYearId,
      },
    }
  );
};

export const getAssignmentResultReportHeader = (
  assignmentActivityId: number,
  schoolIds: string,
  classIds: Array<number> | null,
  schoolYearId: number,
) => {
  return AxiosHelper.axiosInstance().get(
    APIConstant.RESULTSREPORT_API.GetAssignmentResultReportHeader,
    {
      params: {
        assignmentActivityId: assignmentActivityId,
        schoolIds: schoolIds,
        classIds: classIds ? classIds.join(',') : null,
        schoolYearId: schoolYearId,
      }
    }
  );
};

export const getAssignmentResultReportByStudent = (
  assignmentActivityId: number | undefined,
  schoolIds: number[],
  classIds: number[] | null,
  schoolYearId: number | undefined
) => {
  return AxiosHelper.axiosInstance().get(
    APIConstant.RESULTSREPORT_API.GetAssignmentResultReportByStudent,
    {
      params: {
        assignmentActivityId: assignmentActivityId,
        schoolIds: schoolIds ?? [],
        classIds: classIds ?? [],
        schoolYearId: schoolYearId,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      }
    }
  );
};
export const getAssignmentResultReportByStudentExcel = (
  assignmentActivityId: number,
  schoolIds: number[] | null,
  classIds: number[] | null,
  schoolYearId: number,
) => {
  return AxiosHelper.axiosInstance().get(
    APIConstant.RESULTSREPORT_API.GetAssignmentResultReportByStudentExcel,
    {
      responseType: "blob",
      params: {
        assignmentActivityId: assignmentActivityId,
        schoolIds: schoolIds ?? [],
        classIds: classIds ?? [],
        timezoneOffset: getTimezoneOffset(),
        schoolYearId: schoolYearId,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    }
  );
};
export const getAssignmentResultReportItemAnalysis = (
  assignmentActivityId: number,
  schoolIds: string,
  classIds: string | null,
  schoolYearId: number,
) => {
  return AxiosHelper.axiosInstance().get(
    APIConstant.RESULTSREPORT_API.GetAssignmentResultReportItemAnalysis,
    {
      params: {
        assignmentActivityId: assignmentActivityId,
        schoolIds: schoolIds,
        classIds: classIds,
        schoolYearId: schoolYearId,
      },
    }
  );
};

export const getAssessmentResultReportBySchool = (
  id: number,
  schoolIds: Array<number> | null,
  classIds: Array<number> | null,
  schoolYearId: number
) => {
  return AxiosHelper.axiosInstance().get(
    APIConstant.RESULTSREPORT_API.GetAssessmentResultsReportBySchool,
    {
      params: {
        assessmentId: id,
        schoolIds: schoolIds ? schoolIds : [],
        classIds: classIds ? classIds : [],
        schoolYearId: schoolYearId,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    }
  );
};

export const getAssignmentResultReportBySchool = (
  id: number,
  schoolIds: Array<number> | null,
  classIds: Array<number> | null,
  schoolYearId: number,
) => {
  return AxiosHelper.axiosInstance().get(
    APIConstant.RESULTSREPORT_API.GetAssignmentResultsReportBySchool,
    {
      params: {
        AssignmentActivityId: id,
        schoolIds: schoolIds ? schoolIds : [],
        classIds: classIds ? classIds : [],
        schoolYearId: schoolYearId,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    }
  );
};

export const getResultsAssessmentStudentDetailsBySchool = (
  schoolYearId: number,
  schoolIds: Array<number> | null,
  assessmentId?: number,
  pageNumber?: number,
  pageSize?: number
) => {
  return AxiosHelper.axiosInstance().get(
    APIConstant.RESULTSREPORT_API.GetResultsAssessmentStudentDetailsBySchool,
    {
      params: {
        assessmentId: assessmentId,
        schoolIds: schoolIds ? schoolIds : [],
        pageNumber: pageNumber,
        pageSize: pageSize,
        schoolYearId: schoolYearId,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    }
  );
};

export const getResultsAssessmentStudentDetailsByClass = (
  schoolYearId: number,
  classIds: Array<number> | null,
  assessmentId?: number,
  pageNumber?: number,
  pageSize?: number
) => {
  return AxiosHelper.axiosInstance().get(
    APIConstant.RESULTSREPORT_API.GetResultsAssessmentStudentDetailsByClass,
    {
      params: {
        assessmentId: assessmentId,
        classIds: classIds ? classIds : [],
        pageNumber: pageNumber,
        pageSize: pageSize,
        schoolYearId: schoolYearId,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    }
  );
};

export const getResultsAssignmentStudentDetailsByClass = (
  schoolYearId: number,
  classIds: Array<number> | null,
  assignmentActivityId?: number,
  pageNumber?: number,
  pageSize?: number,
) => {
  return AxiosHelper.axiosInstance().get(
    APIConstant.RESULTSREPORT_API.GetResultsAssignmentStudentDetailsByClass,
    {
      params: {
        assignmentActivityId: assignmentActivityId,
        classIds: classIds ? classIds : [],
        pageNumber: pageNumber,
        pageSize: pageSize,
        schoolYearId: schoolYearId,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    }
  );
};

export const exportResultsAssessmentStudentDetailsBySchoolExcel = (
  schoolYearId: number,
  schoolIds: Array<number> | null,
  assessmentId?: number
) => {
  return AxiosHelper.axiosInstance().get(
    APIConstant.RESULTSREPORT_API
      .GetResultsAssessmentStudentDetailsBySchoolExcel,
    {
      responseType: "blob",
      params: {
        assessmentId: assessmentId,
        schoolIds: schoolIds ? schoolIds : [],
        timezoneOffset: getTimezoneOffset(),
        schoolYearId: schoolYearId,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    }
  );
};

export const exportResultsAssessmentStudentDetailsByClassExcel = (
  schoolYearId: number,
  classIds: Array<number> | null,
  assessmentId?: number
) => {
  return AxiosHelper.axiosInstance().get(
    APIConstant.RESULTSREPORT_API
      .GetResultsAssessmentStudentDetailsByClassExcel,
    {
      responseType: "blob",
      params: {
        assessmentId: assessmentId,
        classIds: classIds ? classIds : [],
        timezoneOffset: getTimezoneOffset(),
        schoolYearId: schoolYearId,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    }
  );
};

export const exportResultsAssignmentStudentDetailsByClassExcel = (
  schoolYearId: number,
  classIds: Array<number> | null,
  assignmentActivityId?: number,
  activityName?: string,
  activityType?: string,
) => {
  return AxiosHelper.axiosInstance().get(
    APIConstant.RESULTSREPORT_API
      .GetResultsAssignmentStudentDetailsByClassExcel,
    {
      responseType: "blob",
      params: {
        assignmentActivityId: assignmentActivityId,
        classIds: classIds ? classIds : [],
        timezoneOffset: getTimezoneOffset(),
        activityName: activityName,
        activityType: activityType,
        schoolYearId: schoolYearId,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    }
  );
};

export const exportDotMatrixResultsBySchoolExcel = (
  type: string,
  assessmentId: number,
  assignmentActivityId: number,
  schoolIds: Array<number> | null,
  classIds: Array<number> | null,
  premiumAssessmentId: number | null,
  schoolYearId: number
) => {
  return AxiosHelper.axiosInstance().get(
    APIConstant.RESULTSREPORT_API.GetDotMatrixResultsBySchoolExcel,
    {
      responseType: "blob",
      params: {
        type: type,
        assessmentId: assessmentId,
        assignmentActivityId: assignmentActivityId,
        schoolIds: schoolIds ? schoolIds : [],
        classIds: classIds ? classIds : [],
        timezoneOffset: getTimezoneOffset(),
        premiumAssessmentId: premiumAssessmentId,
        schoolYearId: schoolYearId
      },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    }
  );
};

export const getResultsAssessmentMasteryByClass = (
  schoolYearId: number,
  classIds: Array<number> | null,
  assessmentId?: number,
  pageNumber?: number,
  pageSize?: number
) => {
  return AxiosHelper.axiosInstance().get(
    APIConstant.RESULTSREPORT_API.GetResultsAssessmentMasteryByClass,
    {
      params: {
        assessmentId: assessmentId,
        classIds: classIds ? classIds : [],
        pageNumber: pageNumber,
        pageSize: pageSize,
        schoolYearId: schoolYearId,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      }
    }
  );
};

export const getResultsAssessmentMasteryBySchool = (
  schoolYearId: number,
  schoolIds: Array<number> | null,
  assessmentId?: number,
  pageNumber?: number,
  pageSize?: number
) => {
  return AxiosHelper.axiosInstance().get(
    APIConstant.RESULTSREPORT_API.GetResultsAssessmentMasteryBySchool,
    {
      params: {
        assessmentId: assessmentId,
        schoolIds: schoolIds ? schoolIds : [],
        pageNumber: pageNumber,
        pageSize: pageSize,
        schoolYearId: schoolYearId,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    }
  );
};

export const getResultsAssignmentMasteryByClass = (
  schoolYearId: number,
  classIds: Array<number> | null,
  assignmentActivityId?: number,
  pageNumber?: number,
  pageSize?: number,
) => {
  return AxiosHelper.axiosInstance().get(
    APIConstant.RESULTSREPORT_API.GetResultsAssignmentMasteryByClass,
    {
      params: {
        assignmentActivityId: assignmentActivityId,
        classIds: classIds ? classIds : [],
        pageNumber: pageNumber,
        pageSize: pageSize,
        schoolYearId: schoolYearId,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      }
    }
  );
};

export const getPremiumAssessmentResultReportHeader = (
  premiumAssessmentId: number,
  schoolIds: Array<number>,
  classIds: Array<number> | null,
  schoolYearId: number
) => {
  return AxiosHelper.axiosInstance().get(
    APIConstant.RESULTSREPORT_API.GetPremiumAssessmentResultReportHeader,
    {
      params: {
        premiumAssessmentId: premiumAssessmentId,
        schoolIds: schoolIds ? schoolIds : [],
        classIds: classIds ? classIds : [],
        schoolYearId: schoolYearId,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    }
  );
};

export const getPremiumAssessmentResultReportBySchool = (
  id: number,
  schoolIds: Array<number> | null,
  classIds: Array<number> | null,
  schoolYearId: number
) => {
  return AxiosHelper.axiosInstance().get(
    APIConstant.RESULTSREPORT_API.GetPremiumAssessmentResultsReportBySchool,
    {
      params: {
        premiumAssessmentId: id,
        schoolIds: schoolIds ? schoolIds : [],
        classIds: classIds ? classIds : [],
        schoolYearId,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    }
  );
};

export const getResultsPremiumAssessmentStudentDetailsByClass = (
  schoolYearId: number,
  classIds: Array<number> | null,
  premiumAssessmentId?: number,
  pageNumber?: number,
  pageSize?: number
) => {
  return AxiosHelper.axiosInstance().get(
    APIConstant.RESULTSREPORT_API.GetResultsPremiumAssessmentStudentDetailsByClass,
    {
      params: {
        premiumAssessmentId: premiumAssessmentId,
        classIds: classIds ? classIds : [],
        pageNumber: pageNumber,
        pageSize: pageSize,
        schoolYearId: schoolYearId,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    }
  );
};

export const exportResultsPremiumAssessmentStudentDetailsByClassExcel = (
  schoolYearId: number,
  classIds: Array<number> | null,
  premiumAssessmentId?: number
) => {
  return AxiosHelper.axiosInstance().get(
    APIConstant.RESULTSREPORT_API
      .GetResultsPremiumAssessmentStudentDetailsByClassExcel,
    {
      responseType: "blob",
      params: {
        premiumAssessmentId: premiumAssessmentId,
        classIds: classIds ? classIds : [],
        timezoneOffset: getTimezoneOffset(),
        schoolYearId: schoolYearId,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    }
  );
};

export const getPremiumAssessmentResultReportByStudent = (
  premiumAssessmentId: number,
  schoolIds: Array<number> | null,
  classIds: Array<number> | null,
  schoolYearId: number
) => {
  return AxiosHelper.axiosInstance().get(
    APIConstant.RESULTSREPORT_API.GetPremiumAssessmentResultReportByStudent,
    {
      params: {
        premiumAssessmentId: premiumAssessmentId,
        schoolIds: schoolIds ? schoolIds : [],
        classIds: classIds ? classIds : [],
        schoolYearId: schoolYearId,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    }
  );
};

export const getPremiumAssessmentResultReportByStudentExcel = (
  schoolYearId: number,
  premiumAssessmentId: number,
  schoolIds: Array<number> | null,
  classIds: Array<number> | null
) => {
  return AxiosHelper.axiosInstance().get(
    APIConstant.RESULTSREPORT_API.GetPremiumAssessmentResultReportByStudentExcel,
    {
      responseType: "blob",
      params: {
        premiumAssessmentId: premiumAssessmentId,
        schoolIds: schoolIds ? schoolIds : [],
        classIds: classIds ? classIds : [],
        timezoneOffset: getTimezoneOffset(),
        schoolYearId: schoolYearId,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    }
  );
};

export const getPremiumAssessmentResultReportItemAnalysis = (
  premiumAssessmentId: number,
  schoolIds: Array<number> | null,
  classIds: Array<number> | null,
  schoolYearId: number
) => {
  return AxiosHelper.axiosInstance().get(
    APIConstant.RESULTSREPORT_API.GetPremiumAssessmentResultReportItemAnalysis,
    {
      params: {
        premiumAssessmentId: premiumAssessmentId,
        schoolIds: schoolIds ? schoolIds : [],
        classIds: classIds ? classIds : [],
        schoolYearId: schoolYearId,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    }
  );
};
export const getStudentReportCard = (
    schoolId: number,
    teacherUserId: number,
    studentUserId: number,
    subjectIds: Array<number>
): Promise<AxiosResponse<IReportData<IStudentCard>>> => {
    return AxiosHelper.axiosInstance().get<IReportData<IStudentCard>>(
        APIConstant.REPORT_API.GetStudentReportCard,
        {
            params: {
                schoolId: schoolId,
                teacherUserId: teacherUserId,
                studentUserId: studentUserId,
                subjectIds: subjectIds
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        }
    );
};

export const getResultsPremiumAssessmentMasteryByClass = (
  schoolYearId: number,
  classIds: Array<number> | null,
  premiumAssessmentId?: number,
  pageNumber?: number,
  pageSize?: number
) => {
  return AxiosHelper.axiosInstance().get(
    APIConstant.RESULTSREPORT_API.GetResultsPremiumAssessmentMasteryByClass,
    {
      params: {
        premiumAssessmentId: premiumAssessmentId,
        classIds: classIds ? classIds : [],
        pageNumber: pageNumber,
        pageSize: pageSize,
        schoolYearId: schoolYearId,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      }
    }
  );
};

export const getResultsPremiumAssessmentMasteryBySchool = (
  schoolYearId: number,
  schoolIds: Array<number> | null,
  premiumAssessmentId?: number,
  pageNumber?: number,
  pageSize?: number
) => {
  return AxiosHelper.axiosInstance().get(
    APIConstant.RESULTSREPORT_API.GetResultsPremiumAssessmentMasteryBySchool,
    {
      params: {
        premiumAssessmentId: premiumAssessmentId,
        schoolIds: schoolIds ? schoolIds : [],
        pageNumber: pageNumber,
        pageSize: pageSize,
        schoolYearId: schoolYearId,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    }
  );
};

export const getResultsPremiumAssessmentStudentDetailsBySchool = (
  schoolYearId: number,
  schoolIds: Array<number> | null,
  premiumAssessmentId?: number,
  pageNumber?: number,
  pageSize?: number
) => {
  return AxiosHelper.axiosInstance().get(
    APIConstant.RESULTSREPORT_API.GetResultsPremiumAssessmentStudentDetailsBySchool,
    {
      params: {
        premiumAssessmentId: premiumAssessmentId,
        schoolIds: schoolIds ? schoolIds : [],
        pageNumber: pageNumber,
        pageSize: pageSize,
        schoolYearId: schoolYearId,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    }
  );
};

export const exportResultsPremiumAssessmentStudentDetailsBySchoolExcel = (
  schoolYearId: number,
  schoolIds: Array<number> | null,
  premiumAssessmentId?: number
) => {
  return AxiosHelper.axiosInstance().get(
    APIConstant.RESULTSREPORT_API
      .GetResultsPremiumAssessmentStudentDetailsBySchoolExcel,
    {
      responseType: "blob",
      params: {
        premiumAssessmentId: premiumAssessmentId,
        schoolIds: schoolIds ? schoolIds : [],
        timezoneOffset: getTimezoneOffset(),
        schoolYearId: schoolYearId,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    }
  );
};

export const getReportTeacherUsage = (
  districtId: number,
  schoolIds: Array<number>,
  teacherUserId: number | undefined,
  subjectIds: Array<number>,
  startDate: Date | null,
  endDate: Date | null
): Promise<AxiosResponse<IResult<IReportData<ITeacherUsageReport>>>> => {
  return AxiosHelper.axiosInstance().get<IResult<IReportData<ITeacherUsageReport>>>(
      APIConstant.RESULTSREPORT_API.GetTeacherUsageReport,
      {
        params: {
          districtId,
          schoolIds,
          teacherUserId,
          subjectIds,
          startDate,
          endDate
        },
        paramsSerializer: (params) => qs.stringify(params),
      }
  );
};

export const getReportteacherUsageExcel = (
  districtId: number,
  schoolIds: Array<number>,
  teacherUserId: number | undefined,
  subjectIds: Array<number>,
  startDate: Date | null,
  endDate: Date | null
): Promise<AxiosResponse<Blob>> => {
  return AxiosHelper.axiosInstance().get<Blob>(
    APIConstant.RESULTSREPORT_API.getTeacherUsageReportExcel,
    {
      responseType: "blob",
      params: {
        districtId,
        schoolIds,
        teacherUserId,
        subjectIds,
        startDate,
        endDate
      },
      paramsSerializer: (params) => qs.stringify(params),
    }
  )
}

export const getAssessmentStudentAttemptsReport = (
  assessmentId: number | undefined,
  premiumAssessmentId: number | undefined,
  schoolIds: number[],
  classIds: number[],
  schoolYearId: number,
  userId: number,
  orderByColumn: string,
  sortByDesc: boolean,
  pageNumber: number,
  pageSize: number
): Promise<AxiosResponse<AssessmentStudentAttemptsReport>> => {
  return AxiosHelper.axiosInstance().get<AssessmentStudentAttemptsReport>(
    APIConstant.RESULTSREPORT_API.GetAssessmentStudentAttemptsReport,
    {
      params: {
        assessmentId,
        premiumAssessmentId,
        schoolIds,  
        classIds,
        schoolYearId,
        orderByColumn,
        sortByDesc,
        viewingUserId: userId,
        pageNumber,
        pageSize,
      },
      paramsSerializer: (params) => qs.stringify(params),
    }
  );
};

export const getAssessmentStudentAttemptsReportExcel = (
  assessmentId: number | undefined,
  premiumAssessmentId: number | undefined,
  schoolIds: number[],
  classIds: number[],
  schoolYearId: number,
  userId: number  
): Promise<AxiosResponse<Blob>> => {
  return AxiosHelper.axiosInstance().get<Blob>(
    APIConstant.RESULTSREPORT_API.GetAssessmentStudentAttemptsReportExcel,
    {
      responseType: "blob",
      params: {
        timezoneOffset: getTimezoneOffset(),
        assessmentId,
          premiumAssessmentId,
        schoolIds,  
        classIds,
        schoolYearId,
        viewingUserId: userId
      },
      paramsSerializer: (params) => qs.stringify(params),
    }
  );
};

export const getAssignmentStudentAttemptsReport = (
  assignmentId: number,
  schoolId: number,
  classIds: number[],
  schoolYearId: number,
  orderByColumn: string,
  sortByDesc: boolean,
  pageNumber: number,
  pageSize: number
): Promise<AxiosResponse<AssessmentStudentAttemptsReport>> => {
  return AxiosHelper.axiosInstance().get<AssessmentStudentAttemptsReport>(
    APIConstant.RESULTSREPORT_API.GetAssignmentStudentAttemptsReport,
    {
      params: {
        assignmentId,
        schoolIds: [schoolId],  
        classIds,
        schoolYearId,
        orderByColumn,
        sortByDesc,
        pageNumber,
        pageSize
      },
      paramsSerializer: (params) => qs.stringify(params),
    }
  );
};

export const getAssignmentStudentAttemptsReportExcel = (
  assignmentId: number,
  schoolId: number,
  classIds: number[],
  schoolYearId: number
): Promise<AxiosResponse<Blob>> => {
  return AxiosHelper.axiosInstance().get<Blob>(
    APIConstant.RESULTSREPORT_API.GetAssignmentStudentAttemptsReportExcel,
    {
      responseType: "blob",
      params: {
        timezoneOffset: getTimezoneOffset(),
        assignmentId,
        schoolId,  
        classIds,
        schoolYearId
      },
      paramsSerializer: (params) => qs.stringify(params),
    }
  );
};
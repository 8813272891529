import RouteConstant from "../../../../utils/constant/routeConstant";
import Permissions from "../../../../utils/permissions";
import AccessDenied from "../../../shared/accessDenied";
import AllowedTo from "../../../shared/rbac";
import AssessmentCard from "./assignmentCenterCard";
import Breadcrumb from "../../teacher/shared/breadcrumb";
import { IBreadcrumb } from "../../../../model/common/breadcrumb";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import Constant from "../../../../utils/constant/constant";
import { Configuration } from "../../../../environment/setup";
import { useEffect, useState } from "react";
import constant from "../../../../utils/constant/constant";
import {
  checkIfUpgradeRequired,
  getAsessementBuilderUpgradePermission,
  getAsessementCountUpgradePermission,
  getRequestedPermission,
} from "../../../shared/permissionHelper";
import permissions from "../../../../utils/permissions";

function AssignmentCenter(props: any) {
  const { t } = useTranslation();
  const [isDistrictUser] = useState<boolean>(
    props.userContext.roleId === constant.UserRoleId.District ? true : false
  );
  const [upgrade, setUpgrade] = useState(false);
  const [upgradeWithLimitAssessment, setUpgradeWithLimitAssessment] =
    useState(true);
  const isPermissionBasedUpgradeRequired: boolean = checkIfUpgradeRequired(
    Permissions.authored_question_upgrade
  );

  useEffect(() => {
    checkUpgradeRequired();
    checkUpgradeLimitAssessment();
  }, []);

  const checkUpgradeRequired = async () => {
    const isUpgrade: boolean = getAsessementBuilderUpgradePermission(
      props.userContext.roleId
    );
    setUpgrade(isUpgrade);
  };

  async function checkUpgradeLimitAssessment() {
    let isRestricted: boolean = await getAsessementCountUpgradePermission(
      props.userContext.roleId
    );
    setUpgradeWithLimitAssessment(isRestricted);
    return isRestricted;
  }

  const breadcrumbItems = () => {
    const items: Array<IBreadcrumb> = [
      { name: t("Breadcrumb.Home"), url: RouteConstant.ROUTE_DASHBOARD },
      {
        name:
          props.userContext.roleId === Constant.UserRoleId.District
            ? t("Breadcrumb.AssessmentCenter")
            : t("Breadcrumb.AssessmentAssignmentCenter"),
        url: "",
      },
    ];

    return items;
  };

  const getAssignmentbuilderItems = () => {
    let items: any = [
      {
        name: "Assignment Builder",
        url: RouteConstant.NEWASSIGNMENT,
      },
      {
        name: "Assignment Bank",
        url: RouteConstant.ASSIGNMENTBANK,
      },
    ];
    if (getRequestedPermission(permissions.reports_livemonitoring_view)) {
      items = [
        ...items,
        {
          name: "Assignment Live Monitoring",
          url: RouteConstant.ASSESSMENT_LIVE_MONITORING,
          upgrade: checkIfUpgradeRequired(
            permissions.reports_livemonitoring_upgrade
          ),
          showUpgradePopup: checkIfUpgradeRequired(
            permissions.reports_livemonitoring_upgrade
          ),
        },
      ];
    }

    return items;
  };

  return (
    <AllowedTo
      perform={Permissions.assessment_center_view}
      no={() => <AccessDenied />}
    >
      <div className="min-w-0 w-full">
        <div className="lg:flex lg:items-center px-4 py-3 lg:px-8 border-b border-gray-200">
          <Breadcrumb items={breadcrumbItems()} />
          <div className="lg:w-[55%] text-lg flex justify-between gap-2 h-auto sm:h-10 items-center">
            <h1 className="text-primary-violet">
              {props.userContext.roleId === Constant.UserRoleId.District
                ? t("Breadcrumb.AssessmentCenter")
                : t("Breadcrumb.AssessmentAssignmentCenter")}{" "}
            </h1>
          </div>
        </div>
        <div className="px-2 px-2 sm:px-6 lg:px-8 py-6">
          <div className="mx-auto lg:grid lg:grid-cols-12 lg:gap-16 mt-4">
            <div className="min-w-0 block lg:col-span-12 xl:col-span-12 w-full">
              <div className="bg-gray-100  w-full mb-5 rounded-lg p-6 lg:p-8 xl:p-12">
                <div className="mt-2 space-y-6 lg:space-y-0 sm:grid lg:grid-cols-3 lg:gap-x-4 xl:gap-x-16">
                  <AllowedTo perform={Permissions.assessments_view}>
                    <div className="flex flex-col justify-between gap-y-5">
                      <AssessmentCard
                        cardColor="bg-secondary-teal"
                        thumbnailUrl={`${Configuration.S3bucketImagePath}/images/teacher/assessment/assessment.png`}
                        title="Assessments"
                        videoUrl={"kk4z7tzjl5"}
                        tooltip={`${
                          props.userContext.roleId ===
                          Constant.UserRoleId.SchoolAdmin
                            ? "Admin users now have the ability to create an assessment similar to a district assessment that can be used within your school. These assessments can be assigned to any class within the school. Class level assessments should be used when creating assessments that would be assigned to your classes only or shared with other teachers to assign to their’s."
                            : ""
                        }`}
                        items={[
                          {
                            name: "Assessment Builder",
                            url: RouteConstant.NEWASSESSMENT,
                            roles: [
                              Constant.UserRoleId.District,
                              Constant.UserRoleId.SchoolTeacher,
                            ],
                            upgrade: false,
                          },
                          {
                            name: "Assessment Builder - School Assessment",
                            url: RouteConstant.NEWASSESSMENT,
                            roles: [
                              Constant.UserRoleId.SchoolAdmin,
                              Constant.UserRoleId.FreeTeacher,
                            ],
                            isSchoolAssignment: true,
                            upgrade: upgrade,
                            showUpgradePopup: upgrade,
                            upgradeMessage: upgrade
                              ? Constant.UPGRADE_MESSAGE_SCHOOL_ACCOUNT
                              : "",
                          },
                          {
                            name: "Assessment Builder - Class Assessment",
                            url: RouteConstant.NEWASSESSMENT,
                            roles: [
                              Constant.UserRoleId.SchoolAdmin,
                              Constant.UserRoleId.FreeTeacher,
                            ],
                            upgrade: upgradeWithLimitAssessment,
                            showUpgradePopup: upgradeWithLimitAssessment,
                            upgradeMessage: upgradeWithLimitAssessment
                              ? Constant.UPGRADE_MESSAGE_ASSESSMENT_LIMIT_NEW
                              : "",
                          },
                          {
                            name: "Assessment Bank",
                            url: RouteConstant.ASSESSMENTBANK,
                            roles: [
                              Constant.UserRoleId.District,
                              Constant.UserRoleId.SchoolAdmin,
                              Constant.UserRoleId.SchoolTeacher,
                              Constant.UserRoleId.FreeTeacher,
                            ],
                            upgrade: false,
                          },
                        ].filter((r) =>
                          r.roles.some((r) => r === props.userContext.roleId)
                        )}
                        headerLabel="Build"
                        headerColor="text-secondary-teal"
                        copy="Build custom assessments by selecting questions from the Item Bank."
                      ></AssessmentCard>
                      <div className="border-2 border-secondary-teal bg-white p-2 rounded-lg overflow-hidden">
                        <img
                          src={`${Configuration.S3bucketImagePath}/images/teacher/assessment/Build.png`}
                          alt={props.title}
                          title={props.title}
                          style={{ width: "100%", maxHeight: "400px" }}
                        />
                      </div>
                    </div>
                  </AllowedTo>

                  {!isDistrictUser && (
                    <AllowedTo perform={Permissions.assignments_view}>
                      <div className="flex flex-col justify-between gap-y-5">
                        <AssessmentCard
                          cardColor="bg-primary-violet"
                          thumbnailUrl={`${Configuration.S3bucketImagePath}/images/teacher/assessment/assignments.png`}
                          title="Assignments"
                          videoUrl={"tuxlkfd1u1"}
                          items={getAssignmentbuilderItems()}
                          headerLabel="Assign"
                          headerColor="text-primary-violet"
                          copy="Assign your assessments or choose from other activities like practice questions, video tasks, constructed response items and summative tests."
                        ></AssessmentCard>
                        {!isDistrictUser && (
                          <div className="border-2 border-primary-violet bg-white p-2 rounded-lg overflow-hidden">
                            <img
                              src={`${Configuration.S3bucketImagePath}/images/teacher/assessment/Assign.png`}
                              alt={props.title}
                              title={props.title}
                              style={{ width: "100%", maxHeight: "400px" }}
                            />
                          </div>
                        )}
                      </div>
                    </AllowedTo>
                  )}
                  <AllowedTo
                    perform={Permissions.authoured_questions_view}
                    upgradePermission={Permissions.authored_question_upgrade}
                  >
                    <div className="flex flex-col justify-between gap-y-5">
                      <AssessmentCard
                        cardColor="bg-tertiary-green"
                        thumbnailUrl={`${Configuration.S3bucketImagePath}/images/teacher/assessment/authoredquestion.png`}
                        title="My Authored Questions"
                        videoUrl={"pg0dmxlci2"}
                        items={[
                          {
                            name: "Question Builder",
                            url: RouteConstant.QUESTIONBUILDERCARD,
                            upgrade: isPermissionBasedUpgradeRequired,
                            showUpgradePopup: isPermissionBasedUpgradeRequired,
                          },
                          {
                            name: "My Questions Bank",
                            url: RouteConstant.AUTHOREDQUESTIONS,
                            upgrade: isPermissionBasedUpgradeRequired,
                            showUpgradePopup: isPermissionBasedUpgradeRequired,
                          },
                        ]}
                        headerLabel="Create"
                        headerColor="text-tertiary-green"
                        copy="Create your own questions with over 18 different question types to choose from."
                      ></AssessmentCard>
                      <div className="border-2 border-tertiary-green bg-white p-2 rounded-lg overflow-hidden">
                        <img
                          src={`${Configuration.S3bucketImagePath}/images/teacher/assessment/Create.png`}
                          alt={props.title}
                          title={props.title}
                          style={{ width: "100%", maxHeight: "400px" }}
                        />
                      </div>
                    </div>
                  </AllowedTo>
                </div>
                <div className="mt-6 space-y-12 lg:space-y-0 sm:grid lg:grid-cols-3 lg:gap-x-4 xl:gap-x-16"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AllowedTo>
  );
}

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(AssignmentCenter);

import { useEffect } from "react";

interface SubMenuProps {
  icon: any;
  parentMenu: any;
  menu: any;
  isActive: boolean;
  expandMenu: boolean;
  toggleSidebarView: boolean;
  subMenuType: any;
  onClick: (data: any) => void;
  setActiveMenu: (menu: string) => void;
}

const SubMenu = (props: SubMenuProps) => {
  const {
    isActive,
    expandMenu,
    parentMenu,
    menu,
    icon,
    subMenuType,
    onClick,
    setActiveMenu,
  } = props;

  const inActiveClassName = `${
    subMenuType === "close" ? " text-white " : " text-gray-600 "
  } group  flex items-center  `;
  const activeClassName = `${
    subMenuType === "close" ? " text-white " : " text-secondary-teal "
  } group flex items-center  `;

  useEffect(() => {
    if (isActive) {
      setActiveMenu(parentMenu.menu);
    }
  }, [isActive]);

  return (
    <>
      {subMenuType === "open" && (
        <div
          id="sub-menu-1"
          className={`ml-4 cursor-pointer transition-all duration-300 group ${
            expandMenu
              ? " h-8 opacity-100 "
              : " h-0 opacity-0 pointer-events-none "
          }
        ${isActive ? activeClassName : inActiveClassName}
        `}
          onClick={() => onClick(menu)}
        >
          <span
            className={`ml-4  group-hover:text-black 
         ${isActive ? "text-black" : "text-gray-600"}
        `}
          >
            {icon}
          </span>
          <a className="px-2 hover:text-secondary-teal">
            <div> {menu.name}</div>
          </a>
        </div>
      )}

      {subMenuType === "close" && (
        <div
          id="sub-menu-1"
          className={`  cursor-pointer   transition-all duration-300 pr-4 group   ${
            expandMenu ? " h-8 opacity-100 " : " h-0 opacity-0 hidden"
          }
        ${isActive ? activeClassName : inActiveClassName}
        `}
          onClick={() => onClick(menu)}
        >
          <span
            className={`ml-2   
         ${isActive ? " " : " "}
        `}
          >
            {icon}
          </span>
          <a className="px-2 hover:text-white group-hover:opacity-80 ">
            <div> {menu.name}</div>
          </a>
        </div>
      )}
    </>
  );
};

export default SubMenu;

import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { MessageApi } from "../../../api/message/messageApi";
import { LogoPLIcon } from "../../../assets/icons/index";
import ProfileModel from "../../../model/users/profile";
import { fetchProfile } from "../../../redux/actions/userActions";
import * as userContextAction from "../../../redux/actions/userContextAction";
import RouteConstant from "../../../utils/constant/routeConstant";
import Profile from "../../shared/header/profile";
import { useCollectionData } from "react-firebase-hooks/firestore";
import ProductFeedback from "../../screen/contactUs/productFeedback";
import constant from "../../../utils/constant/constant";
import UpGradeRequestPopup from "../../shared/upGradeRequestPopup";

export type HeaderProps = {
  userContext: userContextAction.UserContextState;
  profile?: ProfileModel;
  showParentConnectPopUp?: any;
  setUserContext: Function;
  setProfile: Function;
};

export class UrlParams {
  userId!: string;
  roleId!: string;
  schoolId!: string;
  userName!: string;
  password!: string;
  encodedUserName!: string;
  encodedPassword!: string;
  displayName!: string;
  avatar!: string;
}

function Header(props: HeaderProps) {
  const [isactiveClass, setIsactiveClass] = useState("");
  const [showMenu, setMenu] = useState(true);
  const history = useHistory();
  const chatQuery: any = MessageApi.getRecentChatRef().where(
    "userId",
    "==",
    props.userContext.userId
  );
  const [recentChat]: any = useCollectionData(chatQuery);
  const [productFeedbackPopup, setProductFeedbackPopup] =
    useState<boolean>(false);
  const [showUpgradeRequestPopup, setShowUpgradeRequestPopup] =
    useState<boolean>(false);

  function toggleMenu() {
    setMenu(!showMenu);
    if (!showMenu) {
      setIsactiveClass("is-active");
    } else {
      setIsactiveClass("");
    }
  }
  function handleProductFeedbackClick() {
    setProductFeedbackPopup(true);
  }
  function handleRedirectToHome() {
    history.push(RouteConstant.ROUTE_DASHBOARD);
  }

  useEffect(() => {
    MessageApi.handleOnActive(
      props.userContext.userId,
      props.userContext.schoolId
    );
  }, []);

  return (
    <>
      <header>
        {showUpgradeRequestPopup && (
          <UpGradeRequestPopup togglePopup={setShowUpgradeRequestPopup} />
        )}
        <div className="flex bg-white justify-between items-center">
          <div className="p-2 flex items-center bg-white">
            <LogoPLIcon
              title="Progress Learning"
              className="w-20 h-auto ml-1 mr-1 sm:mr-0 sm:ml-3 cursor-pointer"
              onClick={() => handleRedirectToHome()}
            />
          </div>
          <button
            onClick={() => toggleMenu()}
            className={`ml-auto mr-2 cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block hidden outline-none focus:outline-non navbar-burger focus:outline-none focus:bg-primary-green active:bg-primary-green ${isactiveClass}`}
            data-target="#navigation"
          >
            <span className="block relative w-6 h-px rounded-sm bg-black mt-1.5"></span>

            <span className="block relative w-6 h-px rounded-sm bg-black mt-1.5"></span>

            <span className="block relative w-6 h-px rounded-sm bg-black mt-1.5"></span>
          </button>
          {showMenu && (
            <nav
              className="top-navbar inline-flex md-flex-grow md:ml-auto"
              id="navigation"
            >
              {props.userContext.roleId == constant.UserRoleId.FreeTeacher && (
                <button
                  type="submit"
                  className="bg-transparent border border-primary-violet shadow-sm rounded-md py-1 px-2 mr-2 lg:px-4 sm:mr-4 flex justify-center items-center text-xs sm:text-sm font-medium text-primary-violet hover:text-white hover:bg-light-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition ease-in-out duration-500"
                  onClick={() => setShowUpgradeRequestPopup(true)}
                >
                  Upgrade Request
                </button>
              )}
              <div className="md:inline-flex md:flex-row flex flex-col lg:h-10 justify-center  order-last relative nav-hover">
                <div
                  id="marker"
                  className="absolute left-0 h-1 rounded-2xl w-0 bg-primary-green -bottom-0  duration-700"
                ></div>

                <div>
                  <div className="flex h-full">
                    <Profile
                      userContext={props.userContext}
                      profile={props.profile}
                      recentChat={recentChat}
                      name={
                        props.profile
                          ? props.profile.firstName +
                            " " +
                            props.profile.lastName
                          : ""
                      }
                      src={props.profile ? props.profile.profileImage : ""}
                      showContactUs={true}
                      onContactUsClicked={handleProductFeedbackClick}
                    ></Profile>
                  </div>
                </div>
              </div>
            </nav>
          )}
        </div>
        <div className="w-full flex">
          <div className="w-1/2 bg-teal-light h-2"></div>
          <div className="w-1/2 bg-light-violet h-2"></div>
        </div>
        {productFeedbackPopup && (
          <div>
            <ProductFeedback
              showIdCardModa={true}
              {...props}
              showPopUp={(e) => {
                setProductFeedbackPopup(false);
              }}
            ></ProductFeedback>
          </div>
        )}
      </header>
    </>
  );
}

Header.displayName = "Header";
const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setUserContext: (payload: userContextAction.UserContextState) => {
      dispatch(userContextAction.setUserContext(payload));
    },
    setProfile: (
      userId: number,
      roleId: number,
      schoolId: number,
      districtId: number
    ) => {
      dispatch(fetchProfile(userId, roleId, schoolId, districtId));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);

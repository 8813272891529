import React from "react";
import { CompletionInfo } from "./interface";

const PerformanceTask: React.FunctionComponent<CompletionInfo> = ({
  is_saved,
  past_due,
}: CompletionInfo) => {
  return (
    <div
      className={`flex-shrink-0 cursor-pointer h-12 w-12 bg-blues ${
        is_saved === 1 ? "bg-site-orange" : ""
      } ${
        past_due === 1 ? "bg-site-pink" : ""
      }  hover:opacity-90 rounded-full flex items-center justify-center`}
    >
      <svg
        className="w-6 h-6 fill-current text-white"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 23.762 16.714"
      >
        <g
          id="Group_15"
          data-name="Group 15"
          transform="translate(-1294.203 -742)"
        >
          <g
            id="Rectangle_5"
            data-name="Rectangle 5"
            transform="translate(1299 742)"
            fill="none"
            stroke="#fff"
            strokeWidth="1"
          >
            <rect width="16" height="13" stroke="none"></rect>
            <rect x="0.5" y="0.5" width="15" height="12" fill="none"></rect>
          </g>
          <path
            id="Path_42"
            data-name="Path 42"
            d="M1299.494,750.044l4.161-2.978,4.214,2.978,6.339,4.48"
            fill="none"
            stroke="#fff"
            strokeWidth="1"
          ></path>
          <path
            id="Path_43"
            data-name="Path 43"
            d="M1308.419,750.8l6.239-4.032"
            fill="#fff"
            stroke="#fff"
            strokeWidth="1"
          ></path>
          <g
            id="Ellipse_13"
            data-name="Ellipse 13"
            transform="translate(1306 744)"
            fill="#fff"
            stroke="#fff"
            strokeWidth="1"
          >
            <circle cx="2" cy="2" r="2" stroke="none"></circle>
            <circle cx="2" cy="2" r="1.5" fill="none"></circle>
          </g>
          <g
            id="Group_14"
            data-name="Group 14"
            transform="matrix(0.588, -0.809, 0.809, 0.588, -74.871, 1385.679)"
          >
            <g
              id="Path_45"
              data-name="Path 45"
              transform="translate(1325 748)"
              fill="#fff"
            >
              <path
                d="M 7.042020797729492 4.365721702575684 L 1.067518353462219 4.365721702575684 L 4.054769515991211 0.7810234427452087 L 7.042020797729492 4.365721702575684 Z"
                stroke="none"
              ></path>
              <path
                d="M 4.054769515991211 1.562045097351074 L 2.135037422180176 3.865721702575684 L 5.974501609802246 3.865721702575684 L 4.054769515991211 1.562045097351074 M 4.054769515991211 1.9073486328125e-06 L 8.109539985656738 4.865721702575684 L -9.5367431640625e-07 4.865721702575684 L 4.054769515991211 1.9073486328125e-06 Z"
                stroke="none"
                fill="#fff"
              ></path>
            </g>
            <line
              id="Line_15"
              data-name="Line 15"
              y2="3.244"
              transform="translate(1329.055 752.055)"
              fill="none"
              stroke="#fff"
              strokeWidth="2"
            ></line>
          </g>
          <path
            id="Path_44"
            data-name="Path 44"
            d="M1297.357,746.763H1292.7v11.451h12.034v-3.361"
            transform="translate(2)"
            fill="none"
            stroke="#fff"
            strokeWidth="1"
            strokeDasharray="1"
          ></path>
        </g>
      </svg>
    </div>
  );
};

export default PerformanceTask;

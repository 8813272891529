import ErrorMessagePopup from "./errorMessagePopup"
import { ErrorMessagePopupProps } from "./interfaces"

function networkErrorMessagePopup(props: ErrorMessagePopupProps) {
  const { 
    theme = "KINDERGARDEN", 
    title = "Network Issues",
    message = `We have lost connection to you!<br>Try checking your internet settings and reloading the page.`,
    okText = "Reload Page",
    okAction = () => {window.location.reload();},
    cancelText,
    cancelAction
  } = props;

  return (
    <div>
      <ErrorMessagePopup
        theme={theme}
        title={title}
        message={message}
        okText={okText}
        okAction={okAction}
        cancelText={cancelText}
        cancelAction={cancelAction}
      />      
    </div>
  )
}

export default networkErrorMessagePopup

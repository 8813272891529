import APIConstant from "../../utils/constant/apiConstant";
import {
  IAssessment,
  IPremiumAssessmentDTO,
} from "../../model/interface/assessment";
import { IAssessmentSearch } from "../../model/interface/assessmentSearch";
import { IQuestionSearch } from "../../model/interface/questionSearch";
import { IPassageSearch } from "../../model/interface/passageSearch";
import { AxiosHelper } from "../../environment/axiosConfig";
import qs from "qs";
import { AxiosResponse } from "axios";
import { IQuestionBreakdown } from "../../model/interface/questionBreakdown";
import PagingResponse from "../../model/common/pagingResponse";
import { ICustomKeyValue } from "../../model/interface/selectElement";
import { IResult } from "../../model/interface/IResult";

export const addAssessment = (
  teacherId: number,
  roleId: number,
  assessment: IAssessment
) => {
  const params = {
    userId: teacherId,
    roleId: roleId,
    name: assessment.name,
    subjectId: assessment.subjectId,
    tagIds: assessment.tagIds,
    isSharedAssessment: assessment.isSharedAssessment,
    sharedTypeId: assessment.sharedTypeId,
    assessmentResultDisplayTypeId: assessment.displayResultInDetailId,
    isAllowCalculator: assessment.isAllowCalculator,
    calculatorTypeId: assessment.calculatorTypeId,
    isDisplayRandomQuestion: assessment.isDisplayRandomQuestion,
    questions: assessment.questions,
  };

  return AxiosHelper.axiosInstance().post(
    APIConstant.ASSESSMENT_API.Assessment,
    params,
    {}
  );
};

export const addTag = (
  tagId: number,
  assessments: Array<number>,
  cannedTests: Array<number>
) => {
  const params = {
    tagId: tagId,
    assessments: assessments,
    cannedTests: cannedTests,
  };

  return AxiosHelper.axiosInstance().post(
    APIConstant.ASSESSMENT_API.AddTag,
    params,
    {}
  );
};

export const removeTag = (
  tagId: number,
  assessments: Array<number>,
  cannedTests: Array<number>
) => {
  const params = {
    tagId: tagId,
    assessments: assessments,
    cannedTests: cannedTests,
  };

  return AxiosHelper.axiosInstance().post(
    APIConstant.ASSESSMENT_API.RemoveTag,
    params,
    {}
  );
};

export const updateAssessmentData = (
  teacherId: number,
  roleId: number,
  assessment: IAssessment
) => {
  const params = {
    userId: teacherId,
    roleId: roleId,
    assessmentId: assessment.assessmentId,
    name: assessment.name,
    subjectId: assessment.subjectId,
    tagIds: assessment.tagIds,
    isSharedAssessment: assessment.isSharedAssessment,
    sharedTypeId:
      assessment.sharedTypeId && assessment.sharedTypeId > 0
        ? assessment.sharedTypeId
        : undefined,
    assessmentResultDisplayTypeId: assessment.displayResultInDetailId,
    isAllowCalculator: assessment.isAllowCalculator,
    calculatorTypeId:
      assessment.calculatorTypeId && assessment.calculatorTypeId > 0
        ? assessment.calculatorTypeId
        : undefined,
    isDisplayRandomQuestion: assessment.isDisplayRandomQuestion,
    isSystemGenerated: assessment.isSystemGenerated,
    isPassageQuestion: assessment.isPassageQuestion,
    isAuthoredQuestion: assessment.isAuthoredQuestion,
    testSizeTypeId:
      assessment.testSizeTypeId && assessment.testSizeTypeId > 0
        ? assessment.testSizeTypeId
        : undefined,
    questions: assessment.questions,
    isQuestionsUpdated: assessment.updateQuestions,
    learnosityIdEnglish: assessment.learnosityIdEnglish,
    learnosityIdSpanish: assessment.learnosityIdSpanish,
    canAssign: assessment.canAssign,
    canView: assessment.canView,
    canDuplicate: assessment.canDuplicate,
  };

  return AxiosHelper.axiosInstance().put<IAssessment>(
    APIConstant.ASSESSMENT_API.Assessment,
    params,
    {}
  );
};

export const getCalculatorTypes = () => {
  return AxiosHelper.axiosInstance().get(APIConstant.CMS_API.CalculatorTypes, {
    params: {},
  });
};

export const getAllQuestionTypes = () => {
  return AxiosHelper.axiosInstance().get(APIConstant.CMS_API.QuestionTypes, {
    params: {},
  });
};

export const getSubjectDetail = (subjectId: number) => {
  return AxiosHelper.axiosInstance().get(
    `${APIConstant.CMS_API.SubjectDetail}/${subjectId}`,
    {
      params: {},
    }
  );
};

export const getAssessments = (assessmentSearch: IAssessmentSearch) => {
  return AxiosHelper.axiosInstance().get(APIConstant.ASSESSMENT_API.GetAll, {
    params: {
      teacherId: assessmentSearch.teacherId,
      roleId: assessmentSearch.roleId,
      subjectId: assessmentSearch.subjectId,
      tagId: assessmentSearch.tagId,
      isArchived: assessmentSearch.isArchived,
      isOrphaned: assessmentSearch.isOrphaned,
      dateType: assessmentSearch.dateType,
      fromDate: assessmentSearch.fromDate,
      toDate: assessmentSearch.toDate,
      name: assessmentSearch.name,
      code: assessmentSearch.code,
      viewMyAssessmentOnly: assessmentSearch.viewMyAssessmentOnly,
      pageNumber: assessmentSearch.pageNumber,
      pageSize: assessmentSearch.pageSize,
      orderByColumn: assessmentSearch.orderByColumn,
      sortByDesc: assessmentSearch.sortByDesc,
      canViewValue: assessmentSearch.canViewValue,
      canDuplicateValue: assessmentSearch.canDuplicateValue,
      canAssignValue: assessmentSearch.canAssignValue,
      authorIds: assessmentSearch.authorIds,
    },
    paramsSerializer: (params) => {
      return qs.stringify(params);
    },
  });
};

export const getQuestions = (questionSearch: IQuestionSearch) => {
  return AxiosHelper.axiosInstance().get(
    APIConstant.ASSESSMENT_API.GetQuestions,
    {
      params: {
        userId: questionSearch.userId,
        standardIds: questionSearch.standardIds,
        questionTypeIds:
          questionSearch.questionTypeIds !== ""
            ? questionSearch.questionTypeIds === "0"
              ? undefined
              : questionSearch.questionTypeIds
            : undefined,
        passageCategoryId:
          questionSearch.passageCategoryId &&
          questionSearch.passageCategoryId > 0
            ? questionSearch.passageCategoryId
            : undefined,
        excludedQuestions: questionSearch.excludedQuestions
          ? questionSearch.excludedQuestions
          : [],
        includeConstructedResponseQuestionTypes: questionSearch.includeConstructedResponseQuestionTypes
          ? questionSearch.includeConstructedResponseQuestionTypes
          : false
      },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    }
  );
};

export const getQuestionSearch = (questionSearch: IQuestionSearch) => {
  const requestBody = {
    userId: questionSearch.userId,
    subjectId: questionSearch.subjectId,
    domainIds:
      questionSearch.domainIds === ""
        ? undefined
        : questionSearch.domainIds?.split(','),
    standardIds: 
      questionSearch.standardIds === ""
        ? undefined
        : questionSearch.standardIds?.split(','),
    questionTypeIds:
      questionSearch.questionTypeIds !== ""
        ? questionSearch.questionTypeIds === "0"
          ? undefined
          : questionSearch.questionTypeIds?.split(',')
        : undefined,
    passageCategoryId:
      questionSearch.passageCategoryId &&
      questionSearch.passageCategoryId > 0
        ? questionSearch.passageCategoryId
        : undefined,
    excludedQuestions: questionSearch.excludedQuestions
      ? questionSearch.excludedQuestions
      : [],
    includeConstructedResponseQuestionTypes: questionSearch.includeConstructedResponseQuestionTypes
      ? questionSearch.includeConstructedResponseQuestionTypes
      : false,
    keywords: questionSearch.keywordTags,
    matchStrategy: questionSearch.matchAnyTag ? "OR" : "AND",
    pageSize: questionSearch.pageSize,
    pageNumber: questionSearch.pageNumber,
  };

  return AxiosHelper.axiosInstance().post(
    APIConstant.ASSESSMENT_API.GetQuestionSearch,
    requestBody,
    {}
  )
}

export const getAssessmentById = (assessmentId: number) => {
  return AxiosHelper.axiosInstance().get(APIConstant.ASSESSMENT_API.GetById, {
    params: {
      assessmentId: assessmentId
    },
  });
};

export const getAssessmentStats = (assessmentId: number) => {
  return AxiosHelper.axiosInstance().get(APIConstant.ASSESSMENT_API.GetStats, {
    params: {
      assessmentId: assessmentId,
    },
  });
};

export const updateAssessments = (
  teacherId: number,
  assessmentIds: string,
  action: string,
  isArchived: boolean = false
) => {
  const params = {
    teacherId: teacherId,
    assessmentIds: assessmentIds,
    action: action,
    isArchived: isArchived,
  };

  return AxiosHelper.axiosInstance().put(
    APIConstant.ASSESSMENT_API.UpdateAssessments,
    params,
    {}
  );
};

export const deleteAssessments = (
  assessmentIds: string
): Promise<AxiosResponse<boolean>> => {
  const params = {
    assessmentIds: assessmentIds,
  };

  return AxiosHelper.axiosInstance().put(
    APIConstant.ASSESSMENT_API.DeleteAssessments,
    params,
    {}
  );
};

export const getPassagesCategory = () => {
  return AxiosHelper.axiosInstance().get(
    APIConstant.ASSESSMENT_API.GetPassgeCategory,
    {}
  );
};

export const getPassageCategoriesByStandard = (
  standardIds: string,
  subjectId: number
) => {
  return AxiosHelper.axiosInstance().get(
    APIConstant.ASSESSMENT_API.GetPassagesCategoriesByStandard,
    {
      params: {
        standardIds: standardIds,
        subjectId: subjectId,
      },
    }
  );
};

export const getPassages = (passageSearch: IPassageSearch) => {
  return AxiosHelper.axiosInstance().get(
    APIConstant.ASSESSMENT_API.GetPassages,
    {
      params: {
        standardIds: passageSearch.standardIds,
        questionTypeIds:
          passageSearch.questionTypeIds !== ""
            ? passageSearch.questionTypeIds === "0"
              ? undefined
              : passageSearch.questionTypeIds
            : undefined,
        passageCategoryIds:
          passageSearch.passageCategoryIds !== ""
            ? passageSearch.passageCategoryIds === "0"
              ? undefined
              : passageSearch.passageCategoryIds
            : undefined,
        subjectId: passageSearch.subjectId,
        includeConstructedResponseQuestionTypes: passageSearch.includeConstructedResponseQuestionTypes
          ? passageSearch.includeConstructedResponseQuestionTypes
          : false
      },
    }
  );
};

export const getPassageSearch = (passageSearch: IPassageSearch) => {
  return AxiosHelper.axiosInstance().get(
    APIConstant.ASSESSMENT_API.GetPassageSearch,
    {
      params: {
        userId: passageSearch.userId,
        subjectId: passageSearch.subjectId,
        domainIds:
          passageSearch.domainIds === ""
            ? undefined
            : passageSearch.domainIds?.split(','),
        standardIds:
          passageSearch.standardIds === ""
            ? undefined
            : passageSearch.standardIds?.split(','),
        questionTypeIds:
          passageSearch.questionTypeIds !== ""
            ? passageSearch.questionTypeIds === "0"
              ? undefined
              : passageSearch.questionTypeIds?.split(',')
            : undefined,
        passageCategoryIds:
          passageSearch.passageCategoryIds !== ""
            ? passageSearch.passageCategoryIds === "0"
              ? undefined
              : passageSearch.passageCategoryIds
            : undefined,
        includeConstructedResponseQuestionTypes: passageSearch.includeConstructedResponseQuestionTypes
            ? passageSearch.includeConstructedResponseQuestionTypes
            : false,
        keywords: passageSearch.keywordTags,
        matchStrategy: passageSearch.matchAnyTag ? "OR" : "AND",
        pageSize: passageSearch.pageSize,
        pageNumber: passageSearch.pageNumber,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      }
    }
  )
}

export const getPassageQuestions = (questionSearch: IQuestionSearch) => {
  return AxiosHelper.axiosInstance().get(
    APIConstant.ASSESSMENT_API.GetPassageQuestions,
    {
      params: {
        userId: questionSearch.userId,
        passageId: questionSearch.passageId,
        viewAll: questionSearch.viewAll,
        standardIds: questionSearch.standardIds,
        questionTypeIds:
          questionSearch.questionTypeIds !== ""
            ? questionSearch.questionTypeIds === "0"
              ? undefined
              : questionSearch.questionTypeIds
            : undefined,
        passageCategoryId:
          questionSearch.passageCategoryId &&
          questionSearch.passageCategoryId > 0
            ? questionSearch.passageCategoryId
            : undefined,
        subjectId: questionSearch.subjectId,
        excludedQuestions: questionSearch.excludedQuestions
          ? questionSearch.excludedQuestions
          : [],
        includeConstructedResponseQuestionTypes: questionSearch.includeConstructedResponseQuestionTypes
          ? questionSearch.includeConstructedResponseQuestionTypes
          : false
      },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    }
  );
};

export const getSystemGeneratedQuestions = (
  questionSearch: IQuestionSearch
) => {
  return AxiosHelper.axiosInstance().get(
    APIConstant.ASSESSMENT_API.GetSystemGeneratedQuestions,
    {
      params: {
        subjectId: questionSearch.subjectId,
        testSize: questionSearch.testSize,
        excludedQuestions: questionSearch.excludedQuestions,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    }
  );
};

export const getFavoriteQuestions = (userId: number) => {
  return AxiosHelper.axiosInstance().get(
    APIConstant.ASSESSMENT_API.GetFavoriteQuestions,
    {
      params: {
        userId: userId,
      },
    }
  );
};

export const updateFavoriteQuestion = (userId: number, questionId: number) => {
  const params = {
    userId: userId,
    questionId: questionId,
  };

  return AxiosHelper.axiosInstance().post(
    APIConstant.ASSESSMENT_API.UpdateFavoritequestion,
    params,
    {}
  );
};

export const duplicateAssessment = (userId: number, assessmentId: number) => {
  const params = {
    userId: userId,
    assessmentId: assessmentId,
  };

  return AxiosHelper.axiosInstance().post(
    APIConstant.ASSESSMENT_API.DuplicateAssessment,
    params,
    {}
  );
};

export const getQuestionReferences = (
  assessmentId: number,
  assignmentActivityId?: number
) => {
  return AxiosHelper.axiosInstance().get(
    APIConstant.ASSESSMENT_API.GetQuestionReferences,
    {
      params: {
        assessmentId: assessmentId,
        assignmentActivityId: assignmentActivityId,
      },
    }
  );
};

export const convertToDistrictAssessment = (userId: number, code: string) => {
  const params = {
    userId: userId,
    code: code,
  };

  return AxiosHelper.axiosInstance().put(
    APIConstant.ASSESSMENT_API.ConvertToDistrictAssessment,
    params,
    {}
  );
};

export const getAllAssessmentTags = (userId: number) => {
  return AxiosHelper.axiosInstance().get(APIConstant.ASSESSMENT_API.GetTag, {
    params: { userId: userId },
  });
};

export const getAssessmentsWithResults = (
  assessmentSearch: IAssessmentSearch
) => {
  return AxiosHelper.axiosInstance().get<IAssessment[]>(
    APIConstant.ASSESSMENT_API.GetWithResults,
    {
      params: {
        teacherId: assessmentSearch.teacherId,
        subjectId: assessmentSearch.subjectId,
        canViewValue: assessmentSearch.canViewValue,
        schoolYearId: assessmentSearch.schoolYearId,
        includePremium: assessmentSearch.includePremium ?? true,
        fromDate: assessmentSearch.fromDate,
        includeUngraded: assessmentSearch.includeUngraded,
        toDate: assessmentSearch.toDate,
      },
    }
  );
};

export const getQuestionBreakdown = (
  assessmentId: number
): Promise<AxiosResponse<IQuestionBreakdown>> => {
  return AxiosHelper.axiosInstance().get(
    APIConstant.ASSESSMENT_API.QuestionBreakdown,
    {
      params: {
        assessmentId: assessmentId,
      },
    }
  );
};

export const getReplacementQuestions = (
  standardId,
  assessmentId,
  questionTypeId,
  assessmentQuestionTypeId,
  limit = 5,
  passageId
) => {
  return AxiosHelper.axiosInstance().get(
    APIConstant.ASSESSMENT_API.GetReplacementQuestions,
    {
      params: {
        assessmentId: assessmentId,
        standardId: standardId,
        questionTypeId: questionTypeId,
        assessmentQuestionTypeId: assessmentQuestionTypeId,
        limit: limit,
        passageId: passageId,
      },
    }
  );
};

export const getAssessmentByAssignmentActivityId = (
  assignmentActivityId: number
) => {
  return AxiosHelper.axiosInstance().get(
    APIConstant.ASSESSMENT_API.GetAssessmentByAssignmentActivityId,
    {
      params: {
        assignmentActivityId: assignmentActivityId,
      },
    }
  );
};

export const getAssessmentFilterForTestComparisonReport = (
  assessmentSearch: any
) => {
  return AxiosHelper.axiosInstance().get(
    APIConstant.TESTCOMPARISONREPORT_API.GetAssessmentFilter,
    {
      params: {
        subjectId: assessmentSearch.subjectId,
        test1Id: assessmentSearch.test1Id,
        cannedTestTypeIds: assessmentSearch.cannedTestTypeIds,
        schoolId: assessmentSearch.schoolId,
        schoolYearId: assessmentSearch.schoolYearId
      },
      paramsSerializer: params => qs.stringify(params),
    }
  );
};

export const getMyAssessmentCount = (): Promise<AxiosResponse<number>> => {
  return AxiosHelper.axiosInstance().get(
    APIConstant.ASSESSMENT_API.GetMyAssessmentCount
  );
};

export const getPremiumAssessments = (
  name: string | null,
  assessmentType: string | null,
  subjectId: number | null,
  userTagId: number | null,
  startDate: Date | null,
  endDate: Date | null,
  pageNumber?: number,
  pageSize?: number,
  orderByColumn?: string,
  sortByDesc?: boolean,
  schoolYearId?: number | undefined
): Promise<AxiosResponse<PagingResponse<IPremiumAssessmentDTO>>> => {
  return AxiosHelper.axiosInstance().get(
    APIConstant.ASSESSMENT_API.GetPremiumAssessments,
    {
      params: {
        schoolYearId: schoolYearId,
        name: name,
        assessmentType: assessmentType,
        userTagId: userTagId,
        subjectId: subjectId,
        startDate: startDate,
        endDate: endDate,
        pageNumber: pageNumber,
        pageSize: pageSize,
        orderByColumn: orderByColumn,
        sortByDesc: sortByDesc,
      },
    }
  );
};

export const lockPremiumAssessments = (assessmentIds: Array<number>) => {
  return AxiosHelper.axiosInstance().post(
    APIConstant.ASSESSMENT_API.LockPremiumAssessments,
    {
      assessmentIds: assessmentIds,
    },
    {}
  );
};

export const getPremiumAssessmentQuestionReferences = (
  cannedTestId: number
): Promise<AxiosResponse<IResult<Array<string>>>> => {
  return AxiosHelper.axiosInstance().get(
    APIConstant.ASSESSMENT_API.GetPremiumAssessmentQuestionReferences,
    {
      params: {
        cannedTestId: cannedTestId,
      },
    }
  );
};

export const getPremiumAssessmentById = (
  cannedTestId: number
): Promise<AxiosResponse<IResult<IPremiumAssessmentDTO>>> => {
  return AxiosHelper.axiosInstance().get(
    APIConstant.ASSESSMENT_API.GetPremiumAssessmentById,
    {
      params: {
        cannedTestId: cannedTestId,
      },
    }
  );
};

export const getAssessmentAuthors = (
  teacherId: number,
  roleId: number
): Promise<AxiosResponse<PagingResponse<ICustomKeyValue>>> => {
  return AxiosHelper.axiosInstance().get(
    APIConstant.ASSESSMENT_API.GetAuthors,
    {
      params: {
        teacherId: teacherId,
        roleId: roleId,
      },
    }
  );
};

// @ts-nocheck
import {UserContextState} from "../redux/actions/userContextAction";
import Constant from "./constant/constant";
import Profile from "../model/users/profile";


export default function pendoScript(userInfo: UserContextState, profile: Profile) {

    if (userInfo == null || profile == null) return;
    (function(apiKey) {
        (function (p, e, n, d, o) {
            var v, w, x, y, z;
            o = p[d] = p[d] || {};

            o!._q = o!._q || [];
            v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track'];
            for (w = 0, x = v.length; w < x; ++w) (function (m) {
                o[m] = o[m] || function () {
                    o._q[m === v[0] ? 'unshift' : 'push']([m].concat([].slice.call(arguments, 0)));
                };
            })(v[w]);
            y = e.createElement(n);
            y.async = !0;
            y.src = 'https://content.pendo.progresslearning.com/agent/static/' + apiKey + '/pendo.js';
            z = e.getElementsByTagName(n)[0];
            z.parentNode.insertBefore(y, z);
        })(window, document, 'script', 'pendo');
        // This function creates anonymous visitor IDs in Pendo unless you change the visitor id field to use your app's values
        // This function uses the placeholder 'ACCOUNT-UNIQUE-ID' value for account ID unless you change the account id field to use your app's values
        // Call this function after users are authenticated in your app and your visitor and account id values are available
        // Please use Strings, Numbers, or Bools for value types.
        pendo.initialize({
            visitor: {
                id: userInfo.userId,
                full_name: profile.firstName + " " + profile.lastName,
                email: profile.email,
                user_type: Constant.RoleIdToUserType[userInfo.roleId],
                district_id: userInfo.districtId,
                is_administrator: [
                    Constant.UserRoleId.SuperAdmin,
                    Constant.UserRoleId.SchoolAdmin,
                    Constant.UserRoleId.District
                ].includes(userInfo.roleId),
                has_liftoff: profile.isLOActive
            },
            account: {
                id: userInfo.accountId,
                schoolId:  userInfo.schoolId,
                schoolAccountId: userInfo.schoolAccountId,
                district_id: userInfo.districtId,
                district_name: profile.districtName,
                state: Constant.stateIdToAbbreviation[userInfo.stateId],
                name: profile.schoolName,
                tags: [profile.districtName],
            }
        });
    })('76f40cf2-0410-4a11-7f41-29684e1f04b5');
    // since the salesforce chat widget is dependent on pendo, load it after.
    const allowedEmbeddedSFChatRoles = [Constant.UserRoleId.SchoolAdmin, Constant.UserRoleId.SchoolTeacher, Constant.UserRoleId.District];
    if (!window.sf_widget_loading && allowedEmbeddedSFChatRoles.includes(userInfo.roleId) &&
        (window.location.hostname === 'app-dev.progresslearning.com')
    ) {
        window.sf_widget_loading = true;
        var s = document.createElement('script');
        s.setAttribute('src', 'https://usatestprep--full.sandbox.my.site.com/ESWProgressLearningSupp1694808903971/assets/js/bootstrap.min.js');
        s.onload = function () {
            initEmbeddedMessaging();
        };
        document.body.appendChild(s);
    }
};
import { AxiosHelper } from "../../environment/axiosConfig";
import APIConstant from "../../utils/constant/apiConstant";

export const getSampleStudent = (
  gradeId: number
) => {
  return AxiosHelper.axiosInstance().get(
    APIConstant.SampleStudentView_API.GetSampleStudent,
    {
      params: {
        gradeId: gradeId,
      },
    }
  );
};

export function createSampleStudent(
  gradeId: number,
  gradeLevel: string
) {
  return AxiosHelper.axiosInstance().post(APIConstant.SampleStudentView_API.CreateSampleStudent, {
    gradeId: gradeId,
    gradeLevel: gradeLevel
  });
  
}

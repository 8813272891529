import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ExclamationIcon, XIcon } from "@heroicons/react/outline";

interface CopySessionConfirmationDialogProp {
  confirmationTitle: string;
  confirmationMessage: string;
  open: boolean;
  onOkClick: () => void;
  onCancelClick: () => void;
  className?: string;
  setSessionNameToCopy: (sessionNameToCopy: string) => void;
}

export default function CopySessionConfirmationDialog(
  props: CopySessionConfirmationDialogProp
) {
  const [sessionName, setSessionName] = useState("");
  function handleSessionNameChange(e: any) {
    setSessionName(e.target.value);
    props.setSessionNameToCopy(e.target.value);
  }
  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog
        as="div"
        static
        className={
          props.className !== undefined
            ? props.className
            : "fixed z-10 inset-0 overflow-y-auto"
        }
        open={props.open}
        onClose={props.onCancelClick}
      >
        <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                <button
                  type="button"
                  className="inline-flex items-center justify-center w-6 h-6 text-gray-400 transition-colors duration-150 rounded dark:hover:text-gray-200 hover: hover:text-gray-700"
                  onClick={() => props.onCancelClick()}
                >
                  <span className="sr-only">Close</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="sm:flex sm:items-start p-4 mb-6">
                <div className="mx-auto mt-4 flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-yellow-100 sm:mx-0 sm:h-10 sm:w-10">
                  <ExclamationIcon
                    className="h-6 w-6 text-yellow-500"
                    aria-hidden="true"
                  />
                </div>
                <div className="mt-3 text-center sm:mt-4 sm:ml-4 sm:text-left">
                  <Dialog.Title
                    as="h3"
                    className="text-lg leading-6 font-medium text-gray-900"
                  >
                    {props.confirmationTitle}
                  </Dialog.Title>
                  <div className="mt-2">
                    <p
                      className="text-sm text-gray-500"
                      dangerouslySetInnerHTML={{
                        __html: props.confirmationMessage,
                      }}
                    ></p>
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-start px-6 py-4 sm:flex-row">
                <div className="flex items-center">
                  <label
                    htmlFor="first-name"
                    className="block text-sm font-medium text-gray-500"
                  >
                    Session Name
                  </label>
                  <div className="mt-1">
                    <input
                      autoComplete="off"
                      type="text"
                      name="first-name"
                      id="first-name"
                      value={sessionName}
                      onChange={(e) => handleSessionNameChange(e)}
                      className=" ml-2 shadow-sm focus:ring-primary-violet focus:border-primary-violet h-10 block w-full text-lg border border-gray-300 text-gray-500 px-3 rounded-sm"
                    />
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-end px-6 py-4 sm:flex-row bg-gray-50"></div>
              <div className="flex-shrink-0 mb-4 mr-4">
                <div className="space-x-3 flex justify-end">
                  <button
                    type="button"
                    className="align-bottom inline-flex items-center whitespace-nowrap justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 rounded-lg text-sm text-white border border-transparent bg-primary-green focus:shadow-outline-purple w-auto"
                    onClick={() => props.onOkClick()}
                  >
                    Ok
                  </button>
                  <button
                    type="button"
                    className="w-auto inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                    onClick={() => props.onCancelClick()}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

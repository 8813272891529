import Moment from "moment";
import { Fragment, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { toast } from "react-toastify";
import {
  duplicateAssignment,
  getAssignmentById,
  getAssignmentClasses,
  getAssignments,
  getAssignmentStudents,
  getAssignmentSubjects,
} from "../../../../api/teacher/assignment";
import {
  GroupAssignmentIcon,
  LockIcon,
  UnLockIcon,
  CaretUpSolidIcon,
  CaretDownSolidIcon,
  DownArrowIcon,
  UpArrow,
  GradingIcon,
} from "../../../../assets/icons";
import PagingResponse from "../../../../model/common/pagingResponse";
import { IAssignmentActivity } from "../../../../model/interface/assignmentActivity";
import { IAssignmentSearch } from "../../../../model/interface/assignmentSearch";
import { ISelectElement } from "../../../../model/interface/selectElement";
import MyAssignmentDetails from "../../../../model/teacher/assignment";
import {
  getActivityName,
  getContextMenuActivityType,
  getIsParentGroupedActivityType,
  hasLockedPremiumAssessments,
  isGroupedAssignment,
} from "../../../../utils/assignmentHelper";
import Constant from "../../../../utils/constant/constant";
import { OrderByArray } from "../../../../utils/helper";
import {
  DEFAULT_PAGE_NUMBER,
  DEFAULT_PAGE_SIZE,
} from "../../../../utils/pagingConstant";
import ConfirmationDialog from "../../../shared/confirmationDialog";
import Loader from "../../../shared/loader";
import MessagePopup from "../../../shared/messagePopup";
import PageSize from "../../../shared/pagination/pageSize";
import Pagination from "../../../shared/pagination/pagination";
import AssignmentSetting from "../shared/assignmentSetting";
import QuestionPreview from "../shared/questionPreview/questionPreview";
import RouteConstant from "../../../../utils/constant/routeConstant";
import { isAllowViewAssignmentAssessment } from "../../../../utils/assessmentHelper";
import SharedByIcon from "../../../shared/icons/sharedIcon";
import { getEndDate, getStartDate } from "../../../../utils/dateHelper";
import { ArrowDownIcon, ArrowUpIcon } from "@heroicons/react/outline";
import ContextMenu from "../shared/contextMenu";
import PrintDialog from "../shared/print/learnosityPrintPopUp";
import constant from "../../../../utils/constant/constant";
import {useHistory} from "react-router-dom";

const dates = [
  { value: "1", label: "Due Date" },
  { value: "2", label: "Create Date" },
  { value: "3", label: "Assigned Date" },
];

const options = [
  {
    value: "1",
    label: "Duplicate",
    showForGroupActivity: true,
    roles: [
      Constant.UserRoleId.District,
      Constant.UserRoleId.SchoolAdmin,
      Constant.UserRoleId.SchoolTeacher,
      Constant.UserRoleId.FreeTeacher,
    ],
    isActive: true,
    isUpgrade: false,
  },
  {
    value: "2",
    label: "Preview",
    showForGroupActivity: false,
    roles: [
      Constant.UserRoleId.District,
      Constant.UserRoleId.SchoolAdmin,
      Constant.UserRoleId.SchoolTeacher,
      Constant.UserRoleId.FreeTeacher,
    ],
    isActive: true,
    isUpgrade: false,
  },
  {
    value: "3",
    label: "Print",
    showForGroupActivity: false,
    roles: [
      Constant.UserRoleId.District,
      Constant.UserRoleId.SchoolAdmin,
      Constant.UserRoleId.SchoolTeacher,
      Constant.UserRoleId.FreeTeacher,
    ],
    isActive: true,
    isUpgrade: false,
  },
];
interface passedProps {
  userId: number;
  roleId: number;
  schoolYearId?: number;
  minDate?: Date;
  maxDate?: Date;
}

function ClassAssignment(props: passedProps) {
  const [pageNumber, setPageNumber] = useState(DEFAULT_PAGE_NUMBER);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [resetPageNumber, setResetPageNumber] = useState<boolean>(false);
  const defaultValue = { label: "Select", value: "0" };
  const datesDefaultValue = { label: "Due Date", value: "1" };
  const { userId, roleId } = props;
  const [selectedDateType, setSelectedDateType] =
    useState<ISelectElement>(datesDefaultValue);
  const [students, setStudents] = useState<Array<ISelectElement>>([]);
  const [selectedStudent, setSelectedStudent] = useState<ISelectElement>();
  const [classes, setClasses] = useState<Array<ISelectElement>>([]);
  const [selectedClass, setSelectedClass] = useState<ISelectElement>();
  const [subjects, setSubjects] = useState<Array<ISelectElement>>([]);
  const [selectedSubject, setSelectedSubject] = useState<ISelectElement>();
  const [selectedAssigned, setSelectedAssigned] = useState<ISelectElement>();
  const [selectedFromDate, setFromDate] = useState<Date>();
  const [selectedToDate, setToDate] = useState<Date>();
  const [assignments, setAssignments] =
    useState<PagingResponse<MyAssignmentDetails>>();

  const [name, setName] = useState<string>("");
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [selectedAssignment, setSelectedAssignment] =
    useState<MyAssignmentDetails>();
  const [showSettingPopup, setShowSettingPopup] = useState<boolean>(false);
  const [selectedActivity, setSelectedActivity] =
    useState<IAssignmentActivity>();
  const [assignmentActivities, setAssignmentActivities] =
    useState<Array<IAssignmentActivity>>();
  const [selectedAssignmentId, setSelectedAssignmentId] = useState<number>(0);
  const [showConfirmation, setshowConfirmation] = useState<boolean>(false);
  const [confirmationMessage, setConfirmationMessage] = useState<string>("");
  const [duplicateAssignmentId, setDuplicateAssignmentId] = useState<number>(0);
  const [orderByColumn, setOrderByColumn] = useState<string>("DueDate");
  const [sortByDesc, setSortByDesc] = useState<boolean>(true);
  const [activityPreviewDetail, setActivityPreviewDetail] = useState<{
    previewId: string;
    activityIdPreview: number;
    activityName: string;
    activityType: string;
    standardId: number;
  }>();
  const [open, setOpen] = useState<boolean>(false);
  const [errroMessage, setErrorMessage] = useState<string>("");
  const [errorMessagePopup, setErrorMessagePopup] = useState<boolean>(false);
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [selectedLearnosityId, setSelectedLearnosityId] = useState<string>("");
  const [showPrintPopup, setShowPrintPopup] = useState<boolean>(false);
  const [activityName, setActivityName] = useState<string>("");
  const [selectedActivityTypeId, setSelectedActivityTypeId] =
    useState<number>();

  const history = useHistory();

  function handleStudentChange(selectdItem: any) {
    setSelectedStudent(selectdItem);
  }

  function handleClassChange(selectdItem: any) {
    setSelectedClass(selectdItem);
  }

  function handleSubjectChange(selectdItem: any) {
    setSelectedSubject(selectdItem);
  }

  function handleDateChange(selectdItem: any) {
    setSelectedDateType(selectdItem);
  }

  function handleFromDateChange(selectedItem: any) {
    if (selectedToDate && selectedItem > selectedToDate) {
      return false;
    }
    setFromDate(selectedItem);
  }

  function handleToDateChange(selectedItem: any) {
    if (selectedFromDate && selectedItem < selectedFromDate) {
      return false;
    }
    setToDate(selectedItem);
  }

  function resetFilters() {
    setSelectedStudent(defaultValue);
    setSelectedClass(defaultValue);
    setSelectedSubject(defaultValue);
    setSelectedAssigned(defaultValue);
    setSelectedDateType(datesDefaultValue);
    setName("");
    setFromDate(undefined);
    setToDate(undefined);
    setResetPageNumber(true);

    const assignmentSearch: IAssignmentSearch = {
      teacherId: userId,
      assignmentType: "CLASSASSIGNMENT",
      orderByColumn: "DueDate",
      pageNumber: DEFAULT_PAGE_NUMBER,
      pageSize: DEFAULT_PAGE_SIZE,
      sortByDesc: true,
      schoolYearId: props.schoolYearId,
    };

    setShowLoading(true);
    getAssignments(assignmentSearch).then((r) => {
      setAssignments(r.data);
      setShowLoading(false);
    });
  }

  const handleSortBy = (column: string) => {
    setSortByDesc(!sortByDesc);
    setOrderByColumn(column);
  };

  function searchAssignments(resetPageNumber: boolean = false) {
    let utcFromDate: Date | undefined;
    let utcToDate: Date | undefined;

    if(selectedFromDate != null)
      utcFromDate = getStartDate(selectedFromDate);
    if(selectedToDate != null)
      utcToDate = getEndDate(selectedToDate);

    if (resetPageNumber) {
      setPageNumber(DEFAULT_PAGE_NUMBER);
    }
    setShowLoading(true);
    const assignmentSearch: IAssignmentSearch = {
      teacherId: userId,
      studentId:
        selectedStudent && selectedStudent.value !== "0"
          ? parseInt(selectedStudent.value)
          : undefined,
      classId:
        selectedClass && selectedClass.value !== "0"
          ? parseInt(selectedClass.value)
          : undefined,
      subjectId:
        selectedSubject && selectedSubject.value !== "0"
          ? parseInt(selectedSubject.value)
          : undefined,
      isAssigned: selectedAssigned
        ? selectedAssigned.value === "0"
          ? undefined
          : selectedAssigned.value === "1"
          ? true
          : false
        : undefined,
      dateType:
        selectedDateType &&
        selectedFromDate &&
        selectedToDate &&
        selectedDateType.value !== "0"
          ? selectedDateType.label
          : undefined,
      fromDate: utcFromDate,
      toDate: utcToDate,
      name: name,
      assignmentType: "CLASSASSIGNMENT",
      pageNumber: resetPageNumber ? DEFAULT_PAGE_NUMBER : pageNumber,
      pageSize: pageSize,
      orderByColumn: orderByColumn,
      sortByDesc: sortByDesc,
      schoolYearId: props.schoolYearId,
    };
    getAssignments(assignmentSearch).then((r) => {
      setAssignments(r.data);
      setShowLoading(false);
    });
  }

  function bindStudents() {
    getAssignmentStudents(userId, roleId, true).then((response) => {
      const studentsData = response.data.data;
      var students: Array<ISelectElement> = new Array<ISelectElement>();

      studentsData.forEach(function (keyValuePair: any) {
        students.push({ label: keyValuePair.name, value: keyValuePair.id });
      });

      setStudents(students);
    });
  }

  const openModal = (
    learnosityReferenceId,
    activityId,
    activityName,
    activityType,
    standardId
  ) => {
    if (!learnosityReferenceId) {
      return false;
    }

    setOpen(true);
    setActivityPreviewDetail({
      previewId: learnosityReferenceId,
      activityIdPreview: activityId,
      activityName: activityName,
      activityType: activityType,
      standardId: standardId,
    });
  };

  const popUpClose = (value) => {
    setOpen(value);
    setActivityPreviewDetail(undefined);
  };

  function bindClasses() {
    getAssignmentClasses(userId, roleId, true).then((response) => {
      const classData = response.data.data;
      var classes: Array<ISelectElement> = new Array<ISelectElement>();

      classData.forEach(function (keyValuePair: any) {
        classes.push({ label: keyValuePair.name, value: keyValuePair.id });
      });

      setClasses(classes);
    });
  }

  function bindSubjects() {
    getAssignmentSubjects(userId, roleId, true).then((response) => {
      const subjectsData = response.data.data;
      var subjects: Array<ISelectElement> = new Array<ISelectElement>();

      subjectsData.forEach(function (keyValuePair: any) {
        subjects.push({
          label: keyValuePair.name,
          value: keyValuePair.subjectId,
        });
      });

      setSubjects(subjects);
    });
  }

  function handleSettingPopup(
    assignment: MyAssignmentDetails,
    activity?: IAssignmentActivity
  ) {
    toggleSettingPopup(true);
    setSelectedAssignment(assignment);
    setSelectedActivity(activity);
  }

  function toggleSettingPopup(popupAction: boolean) {
    setShowSettingPopup(popupAction);
  }

  function handleActivityDetails(assignmentId: number) {
    if (selectedAssignmentId === assignmentId) {
      setSelectedAssignmentId(0);
      setAssignmentActivities([]);
      return;
    }
    setShowLoading(true);
    setSelectedAssignmentId(assignmentId);
    getAssignmentById(assignmentId).then((response) => {
      setShowLoading(false);
      const activityDetails: Array<IAssignmentActivity> = response.data.activities;
      let activities: Array<IAssignmentActivity> = [];
      const hasCombineActivity = activityDetails.some(
        (r) => r.isCombinedActivity
      );
      if (hasCombineActivity) {
        const combineActivity = activityDetails.filter(
          (r) => r.isCombinedActivity
        )[0];
        const otherActivities = activityDetails.filter(
          (r) => !r.isCombinedActivity
        );
        activities = [...otherActivities, combineActivity];
        setAssignmentActivities(OrderByArray(activities, "sequenceNumber"));
      } else {
        setAssignmentActivities(OrderByArray(activityDetails, "sequenceNumber"));
      }
    });
  }

  const onPageChange = (pageNo) => {
    setPageNumber(pageNo);
  };

  const onPageSizeChange = (pageSize) => {
    setPageNumber(DEFAULT_PAGE_NUMBER);
    setPageSize(pageSize);
    setResetPageNumber(true);
  };

  const toggleErrorMessagePopup = () => {
    setErrorMessagePopup(false);
  };

  function duplicateAssignmentConfirmation(assignmentId: number) {
    var message: string =
      "Are you sure you want to duplicate selected assignment.?";
    setDuplicateAssignmentId(assignmentId);
    setshowConfirmation(true);
    setConfirmationMessage(message);
  }

  function handleDuplicateAssignments(assignmentId: number) {
    setShowLoading(true);
    duplicateAssignment(userId, assignmentId)
        .then((response) => {
          setShowLoading(false);
          if (response.data.isSuccess) {
            history.push(`/assignments/${response.data.data}`);
            return;
          }

          toast.error("Assignment duplication not successful");
          switch (response.data.code) {
            case 1: //Assignment contains activity with a Guid
              setErrorMessagePopup(true);
              setErrorMessage(Constant.DialogMessages.AssignmentDuplicationFailCode1);
              break;
            default:
              setErrorMessagePopup(true);
              setErrorMessage(response.data.message); //Message in this case coming from the server should not reveal sensitive data
              break;
          }
        })
        .catch(reason => {
          setShowLoading(false);
          toast.error("An error has occurred while duplicating the assignment");
        });
  }

  function handleOkConfirmation() {
    setshowConfirmation(false);
    handleDuplicateAssignments(duplicateAssignmentId);
  }

  function handleCancelConfirmation() {
    setshowConfirmation(false);
    setConfirmationMessage("");
  }

  function getActivityRef(activityType: string, reference: string) {
    let referenceId: string | null = null;
    switch (activityType) {
      case Constant.AssignmentActivityType.ASSESSMENT:
      case Constant.AssignmentActivityType.PREBUILTTESTS:
      case Constant.AssignmentActivityType.PRACTICEQUESTIONS:
        referenceId = reference;
        break;
    }

    return referenceId;
  }

  function getItemRef(activityType: string, reference: string) {
    let referenceId: string | null = null;
    switch (activityType) {
      case Constant.AssignmentActivityType.VIDEOLESSON:
      case Constant.AssignmentActivityType.CONSTRUCTEDRESPONSE:
        referenceId = reference;
        break;
    }

    return referenceId;
  }

  useEffect(() => {
    searchAssignments();
  }, [pageSize, pageNumber, sortByDesc]);

  useEffect(() => {
    bindStudents();
    bindClasses();
    bindSubjects();
  }, []);

  const handleResultsClick = (
    assignmentId: number,
    assignmentName: string,
    activityId: number,
    activityType: string,
    hasCRActivity: boolean = false,
    assessmentId: string | undefined,
    canViewAssessment?: boolean,
    assessmentSharedTypeId?: number,
    assessmentCreatedByUserId?: number,
    subjectId?: number
  ) => {
    if (
      !isAllowViewAssignmentAssessment(
        props.roleId,
        canViewAssessment,
        activityType,
        assessmentCreatedByUserId,
        props.userId,
        assessmentSharedTypeId
      )
    )
    {
      return;
    }     

    const pathname = `${RouteConstant.GROUPED_RESULT_REPORTS}?assignmentId=${assignmentId}&activityId=${activityId}&redirectedFrom=MYASSIGNMENTS` +
      `&assignmentType=${constant.AssignmentType.CLASSASSIGNMENT}&subjectId=${subjectId}&schoolYearId=${props.schoolYearId}`;
    window.open(pathname, "_blank");
  };

  function getOptions(
    isGroupActivity: boolean,
    canDuplicate: boolean,
    authorId: number,
    userId: number,
    canPrint: Boolean,
    hideDuplicate?: Boolean
  ) {
    let newOptions = JSON.parse(JSON.stringify(options));
    if (isGroupActivity) {
      newOptions = newOptions.filter(
        (o) => o.showForGroupActivity == isGroupActivity
      );
    } else if (hideDuplicate) {
      newOptions = newOptions.filter((o) => o.label != "Duplicate");
    }
    newOptions.forEach((option) => {
      if (option.label == "Print") {
        option.isActive = canPrint;
      } else if (option.label == "Duplicate") {
        option.isActive = canDuplicate;
      }
    });
    return newOptions;
  }

  const isQuestionReference = (activityTypeId: number) => {
    return activityTypeId ==
      Constant.AssignmentActivityTypeEnum.CONSTRUCTEDRESPONSE
      ? true
      : false;
  };

  const showPrintOption = (activityTypeId: number) => {
    return activityTypeId == 0 ||
      activityTypeId == Constant.AssignmentActivityTypeEnum.VIDEOLESSON
      ? false
      : true;
  };

  function handleOptionChange(
    selectdItem: any,
    entityId: number,
    learnosityId: string,
    _activityName: string,
    activityType: string,
    value?: any
  ) {
    const assignmentActivity =
      (assignmentActivities?.length ?? 0) > 0
        ? assignmentActivities?.find((s) => s.assignmentActivityId == entityId)
        : assignments?.data.find(
            (s) => s.assignmentActivity?.assignmentActivityId == entityId
          )?.assignmentActivity;

    const assignmentId = assignmentActivity
      ? assignmentActivity.assignmentId
      : null;
    const assignment = assignments?.data.find(
      (a) => a.assignmentId == assignmentId?.toString()
    );
    setActivityName(assignment?.name ?? "");
    switch (selectdItem.label) {
      case "Duplicate":
        if (hasLockedPremiumAssessments(assignments!.data, assignmentId!)) {
          setErrorMessagePopup(true);
          setErrorMessage(
            "This assignment cannot be duplicated as it contains locked premium assessment."
          );
          return;
        }
        let assignmentIdToDuplicate = assignmentId ?? 0;
        if (getIsParentGroupedActivityType(activityType)) {
          const parentGroupedActivity = assignments?.data?.find(
            (s) => s.assignmentId?.toString() === entityId.toString()
          );
          assignmentIdToDuplicate = parentGroupedActivity
            ? parseInt(parentGroupedActivity.assignmentId)
            : 0;
        }
        duplicateAssignmentConfirmation(assignmentIdToDuplicate);
        break;
      case "Print":
        if (isActivityValid(learnosityId)) {
          handlePrintPopup(learnosityId);
        }
        setSelectedActivityTypeId(assignmentActivity?.activityTypeId);
        break;
      case "Preview":
        if (validatePreview(assignment)) {
          openModal(
            assignmentActivity?.learnosityIdEnglish,
            assignmentId,
            assignmentActivity ? getActivityName(assignmentActivity) : "",
            assignmentActivity?.activityType,
            assignmentActivity?.standardId
          );
        }
        break;
    }
  }
  const validatePreview = (assignment: MyAssignmentDetails | undefined) => {
    if (assignment) {
      return assignment.hasActicity && assignment.activityCount === 1
        ? isAllowViewAssignmentAssessment(
            props.roleId,
            assignment.assignmentActivity?.canViewAssessment,
            assignment.assignmentActivity?.activityType,
            assignment.assignmentActivity?.assessmentCreatedByUserId,
            props.userId,
            assignment.assignmentActivity?.assessmentSharedTypeId
          )
        : isAllowViewAssignmentAssessment(
            props.roleId,
            assignment.assignmentActivity?.canViewAssessment,
            assignment.assignmentActivity?.activityType,
            assignment.assignmentActivity?.assessmentCreatedByUserId,
            props.userId,
            assignment.assignmentActivity?.assessmentSharedTypeId
          );
    }
    return false;
  };

  function handlePrintPopup(learnosityId: string) {
    setSelectedLearnosityId(learnosityId);
    setShowPrintPopup(true);
  }

  function isActivityValid(learnosityId: string) {
    if (learnosityId === "") {
      setErrorMessage(
        "You cannot perform this action because there are no questions associated with this assessment."
      );
      setErrorMessagePopup(true);
      return false;
    }
    return true;
  }

  const handleCancelPrint = () => {
    setShowPrintPopup(false);
    setSelectedLearnosityId("");
  };
  return (
    <Fragment>
      {showPrintPopup && (
        <PrintDialog
          learnosityId={selectedLearnosityId}
          onCancelClick={handleCancelPrint}
          open={showPrintPopup}
          isAnswerKey={false}
          activityName={activityName}
          code={""}
          isAssignment={true}
          isQuestionReference={isQuestionReference(selectedActivityTypeId ?? 0)}
        ></PrintDialog>
      )}
      {activityPreviewDetail && (
        <QuestionPreview
          modal={openModal}
          show={open}
          changeValue={popUpClose}
          activityRef={getActivityRef(
            activityPreviewDetail.activityType,
            activityPreviewDetail.previewId
          )}
          itemRef={getItemRef(
            activityPreviewDetail.activityType,
            activityPreviewDetail.previewId
          )}
          activityIdPreview={activityPreviewDetail.activityIdPreview}
          activityName={activityPreviewDetail.activityName}
          standardId={activityPreviewDetail.standardId}
        ></QuestionPreview>
      )}
      <ConfirmationDialog
        open={showConfirmation}
        confirmationTitle={"Please confirm"}
        confirmationMessage={confirmationMessage}
        onCancelClick={handleCancelConfirmation}
        onOkClick={handleOkConfirmation}
      ></ConfirmationDialog>
      {showLoading && <Loader></Loader>}
      {showSettingPopup && selectedAssignment && (
        <AssignmentSetting
          assignment={selectedAssignment}
          activity={selectedActivity}
          toggleSettingPopup={toggleSettingPopup}
        />
      )}
      {errorMessagePopup && (
        <MessagePopup
          message={errroMessage}
          togglePopup={toggleErrorMessagePopup}
        />
      )}
      <div className="min-w-0 block lg:col-span-9 xl:col-span-10 w-full">
        <div className="bg-gray-100  w-full mb-5 rounded-lg p-5 h-full">
          <div className="flex flex-col mb-5">
            <div
              className="flex relative items-center cursor-pointer"
              onClick={() => {
                if (!showFilter) {
                  setShowFilter(true);
                } else {
                  setShowFilter(false);
                }
              }}
            >
              <span className="bg-black w-10 h-10 rounded-full inline-block flex items-center justify-center cursor-pointer">
                {!showFilter && (
                  <ArrowDownIcon className="w-6 text-white opacity-90" />
                )}
                {showFilter && (
                  <ArrowUpIcon className="w-6 text-white opacity-90" />
                )}
              </span>
              <span className="mx-2 text-gray-700">
                {!showFilter
                  ? "Expand Filter Options"
                  : "Collapse Filter Options"}
              </span>
              <span className="text-sm ml-auto">
                This tab displays current school year activities only.
              </span>
            </div>
            <div
              className={`${
                showFilter
                  ? "max-h-[1000px] opacity-100 mt-5 pb-2"
                  : "max-h-0 opacity-0 -mt-5 pb-0 pointer-events-none"
              } w-full bg-white transform transition-all ease-in-out duration-300 relative z-0 p-5 shadow `}
            >
              <div className="grid grid-cols-2 sm:grid-cols-8 lg:grid-cols-8 xl:grid-cols-8 gap-6 pb-4">
                <div className="col-span-8 sm:col-span-4 xl:col-span-2">
                  <label className="block text-sm text-gray-500">Student</label>
                  <Select
                    className="mt-1"
                    value={selectedStudent}
                    options={students}
                    onChange={handleStudentChange}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: "#008fbb",
                      },
                    })}
                  />
                </div>
                <div className="col-span-8 sm:col-span-4 xl:col-span-2">
                  <label className="block text-sm text-gray-500">Class</label>
                  <Select
                    className="mt-1"
                    value={selectedClass}
                    options={classes}
                    onChange={handleClassChange}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: "#008fbb",
                      },
                    })}
                  />
                </div>
                <div className="col-span-8 sm:col-span-4 xl:col-span-2">
                  <label className="block text-sm text-gray-500">Subject</label>
                  <Select
                    className="mt-1"
                    value={selectedSubject}
                    options={subjects}
                    onChange={handleSubjectChange}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: "#008fbb",
                      },
                    })}
                  />
                </div>
                <div className="col-span-8 sm:col-span-4 xl:col-span-2">
                  <label className="block text-sm text-gray-500"> Name</label>
                  <input
                    type="text"
                    name="Assignment-name"
                    id="Assignment-name"
                    autoComplete="off"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="mt-1 shadow-sm focus:ring-primary-violet focus:border-primary-violet h-10 block w-full text-lg border border-gray-200 text-gray-500 px-3 rounded-sm"
                  />
                </div>
                <div className="col-span-8 sm:col-span-4 xl:col-span-2">
                  <label className="block text-sm text-gray-500">Dates</label>
                  <Select
                    className="mt-1"
                    value={selectedDateType}
                    options={dates}
                    onChange={handleDateChange}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: "#008fbb",
                      },
                    })}
                  />
                </div>
                <div className="col-span-8 sm:col-span-4 xl:col-span-4 xxxl:col-span-2 grid grid-cols-2 gap-6">
                  <div>
                    <label className="block text-sm text-gray-500" htmlFor="">
                      From{" "}
                    </label>
                    <DatePicker
                      wrapperClassName="w-full"
                      className="mt-1 text-gray-500 react-datepicker__input-container py-1.5 px-3 block w-full shadow-sm focus:ring-primary-violet focus:border-primary-violet border border-gray-300 rounded-md "
                      selected={selectedFromDate}
                      onChange={(date) => handleFromDateChange(date)}
                      minDate={props.minDate}
                      maxDate={props.maxDate}
                    />
                  </div>
                  <div>
                    <label className="block text-sm text-gray-500" htmlFor="">
                      To{" "}
                    </label>
                    <DatePicker
                      wrapperClassName="w-full"
                      className="mt-1 text-gray-500 react-datepicker__input-container py-1.5 px-3 block w-full shadow-sm focus:ring-primary-violet focus:border-primary-violet border border-gray-300 rounded-md  "
                      selected={selectedToDate}
                      onChange={(date) => handleToDateChange(date)}
                      minDate={props.minDate}
                      maxDate={props.maxDate}
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-col md:flex-row gap-4 items-end justify-end pb-5">
                <div className="grid grid-cols-2 gap-4">
                  <button
                    onClick={() => resetFilters()}
                    className="bg-transparent border my-auto border-primary-violet shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-primary-violet hover:text-white hover:bg-light-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3 transition ease-in-out duration-500"
                  >
                    {" "}
                    Reset
                  </button>
                  <button
                    onClick={() => searchAssignments(true)}
                    className="bg-primary-violet border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-dark-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3 "
                  >
                    Filter
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="bg-white py-5 px-5 shadow">
            <div className="flex flex-col gap-x-4 gap-y-3 sm:flex-row flex-wrap 2xl:gap-6 md:items-center xl:justify-end mb-5 mt-2">
              <div className="flex items-center">
                <GradingIcon className="w-4 text-red-400" />
                <span className="text-xs ml-2 text-gray-500">
                  Grading Required
                </span>
              </div>
              <div className="flex items-center">
                <SharedByIcon byTeacher={true} />
                <span className="text-xs ml-2 text-gray-500">
                  Teacher shared
                </span>
              </div>
            </div>
            <div className="mb-4 pb-4 border-b-2 overflow-auto">
              <table className="table w-full ">
                <thead className="mb-5">
                  <tr className="bg-light-violet  text-white py-3 px-3 overflow-hidden">
                    <th className="text-left p-3 text-sm font-normal w-4"></th>
                    <th></th>
                    <th className="text-left p-3 text-sm font-normal w-4"></th>
                    {selectedDateType &&
                      selectedDateType.label === "Create Date" && (
                        <th className="text-left  p-3 text-sm font-normal">
                          <span className="flex">
                            Create Date
                            <span className="cursor-pointer">
                              {sortByDesc && orderByColumn === "CreateDate" ? (
                                <CaretUpSolidIcon
                                  onClick={() => handleSortBy("CreateDate")}
                                  className="w-5 h-5"
                                ></CaretUpSolidIcon>
                              ) : (
                                <CaretDownSolidIcon
                                  onClick={() => handleSortBy("CreateDate")}
                                  className="w-5 h-5"
                                ></CaretDownSolidIcon>
                              )}
                            </span>
                          </span>
                        </th>
                      )}

                    {selectedDateType &&
                      selectedDateType.label === "Due Date" && (
                        <th className="text-left flex justify-center  p-3 text-sm font-normal">
                          <span className="flex whitespace-nowrap">
                            Due Date
                            <span className="cursor-pointer">
                              {sortByDesc && orderByColumn === "DueDate" ? (
                                <CaretUpSolidIcon
                                  onClick={() => handleSortBy("DueDate")}
                                  className="w-5 h-5"
                                ></CaretUpSolidIcon>
                              ) : (
                                <CaretDownSolidIcon
                                  onClick={() => handleSortBy("DueDate")}
                                  className="w-5 h-5"
                                ></CaretDownSolidIcon>
                              )}
                            </span>
                          </span>
                        </th>
                      )}
                    {selectedDateType &&
                      selectedDateType.label === "Assigned Date" && (
                        <th className="text-left  p-3 text-sm font-normal">
                          <span className="flex">
                            Assigned Date
                            <span className="cursor-pointer">
                              {sortByDesc &&
                              orderByColumn === "AssignedDate" ? (
                                <CaretUpSolidIcon
                                  onClick={() => handleSortBy("AssignedDate")}
                                  className="w-5 h-5"
                                ></CaretUpSolidIcon>
                              ) : (
                                <CaretDownSolidIcon
                                  onClick={() => handleSortBy("AssignedDate")}
                                  className="w-5 h-5"
                                ></CaretDownSolidIcon>
                              )}
                            </span>
                          </span>
                        </th>
                      )}
                    <th className="text-center  p-3 text-sm font-normal"></th>
                    <th className="text-left  p-3 text-sm font-normal">
                      <span className="flex">
                        Name
                        <span className="cursor-pointer">
                          {sortByDesc && orderByColumn === "name" ? (
                            <CaretUpSolidIcon
                              onClick={() => handleSortBy("name")}
                              className="w-5 h-5"
                            ></CaretUpSolidIcon>
                          ) : (
                            <CaretDownSolidIcon
                              onClick={() => handleSortBy("name")}
                              className="w-5 h-5"
                            ></CaretDownSolidIcon>
                          )}
                        </span>
                      </span>
                    </th>
                    <th className="text-center  p-3 text-sm font-normal">
                      Result
                    </th>
                    <th className="text-center p-3 text-sm font-normal">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {assignments &&
                    assignments.data?.length > 0 &&
                    assignments.data?.map((assignment, index) => {
                      const hasMultipleActivities = isGroupedAssignment(
                        assignment.hasActicity,
                        assignment.activityCount
                      );
                      return (
                        <Fragment key={index}>
                          <tr
                            className={`hover:bg-light-violet/20 transition ease-in-out duration-300 ${
                              index % 2 === 0
                                ? "bg-white"
                                : "bg-light-violet/10"
                            }`}
                          >
                            <td className=" mt-5 p-3 text-center">
                              {hasMultipleActivities &&
                                selectedAssignmentId !==
                                  parseInt(assignment.assignmentId) && (
                                  <span title="Expand row">
                                    <DownArrowIcon
                                      className="w-4 h-4 stroke-current text-gray-500 hover:text-gray-700"
                                      onClick={() =>
                                        handleActivityDetails(
                                          parseInt(assignment.assignmentId)
                                        )
                                      }
                                    />
                                  </span>
                                )}
                              {hasMultipleActivities &&
                                selectedAssignmentId ===
                                  parseInt(assignment.assignmentId) && (
                                  <span title="Collapse row">
                                    <UpArrow
                                      className="w-4 h-4 stroke-current text-gray-500 hover:text-gray-700"
                                      onClick={() =>
                                        handleActivityDetails(
                                          parseInt(assignment.assignmentId)
                                        )
                                      }
                                    />
                                  </span>
                                )}
                            </td>
                            <td className="text-center">
                              {assignment.isSharedWithSchool && (
                                <SharedByIcon byTeacher={true} />
                              )}
                            </td>
                            <td className=" mt-5 p-3 text-center">
                              {assignment.isLocked ||
                              assignment.hasLockedPremiumAssessments ? (
                                <LockIcon
                                  title="Locked assignment"
                                  className="fill-current text-gray-500 w-4"
                                />
                              ) : (
                                <UnLockIcon
                                  title="Open assignment"
                                  className="w-4 text-gray-500"
                                />
                              )}
                            </td>
                            {selectedDateType &&
                              selectedDateType.label === "Create Date" && (
                                <td className="text-center text-sm mt-5  p-3">
                                  {Moment(assignment.createdDate).format(
                                    "MM-DD-yyyy"
                                  )}
                                </td>
                              )}

                            {selectedDateType &&
                              selectedDateType.label === "Due Date" && (
                                <td className="text-sm mt-5  p-3">
                                  {assignment.endDate &&
                                    Moment(assignment.endDate).format(
                                      "MM-DD-yyyy"
                                    )}
                                </td>
                              )}

                            {selectedDateType &&
                              selectedDateType.label === "Assigned Date" && (
                                <td className="text-sm mt-5  p-3">
                                  {assignment.assignedDate &&
                                    Moment(assignment.assignedDate).format(
                                      "MM-DD-yyyy"
                                    )}
                                </td>
                              )}
                            <td className="text-sm mt-5  p-3 text-center">
                              {hasMultipleActivities && (
                                <>
                                  <GroupAssignmentIcon
                                    title="Group assignment"
                                    onClick={() =>
                                        handleActivityDetails(
                                            parseInt(assignment.assignmentId)
                                        )
                                      }
                                  />
                                  {(assignment.unGraded ?? 0) > 0 && (
                                      <GradingIcon
                                          title="Constructed Response"
                                          className={
                                            "inline-flex ml-2 w-5 h-5 text-red-500 hover:text-red-700"
                                          }
                                      />
                                  )}
                                </>
                              )}
                              {!hasMultipleActivities && (assignment.unGraded ?? 0) > 0 && (
                                  <GradingIcon
                                    title="Constructed Response"
                                    className="inline-flex ml-2 w-5 h-5 text-red-500 hover:text-red-700 cursor-pointer"
                                    onClick={() =>
                                      handleResultsClick(
                                            parseInt(assignment.assignmentId),
                                            assignment.name,
                                            assignment.assignmentActivity
                                              ? assignment.assignmentActivity
                                                  .assignmentActivityId
                                              : 0,
                                            assignment.assignmentActivity
                                              ?.activityType ?? "",
                                            assignment.assignmentActivity
                                              ?.activityType ===
                                              Constant.AssignmentActivityType
                                                .CONSTRUCTEDRESPONSE ||
                                              assignment.assignmentActivity
                                                ?.isAssessmentWithCR!,
                                            assignment.assignmentActivity
                                              ?.assessmentId,
                                            assignment.assignmentActivity
                                              ?.canViewAssessment,
                                            assignment.assignmentActivity
                                              ?.assessmentSharedTypeId,
                                            assignment.assignmentActivity
                                              ?.assessmentCreatedByUserId,
                                            assignment.subjectId
                                          )}
                                  />
                                )}
                            </td>
                            <td className="text-sm mt-5  p-3">
                              {!assignment.hasActicity && (
                                <Fragment>
                                  <a
                                    href="#!"
                                    className="text-blue-500 underline hover:no-underline"
                                    onClick={() =>
                                      handleSettingPopup(assignment)
                                    }
                                  >
                                    {assignment.name}
                                  </a>
                                </Fragment>
                              )}
                              {hasMultipleActivities && (
                                <>
                                  {assignment.hasLockedPremiumAssessments &&
                                    props.roleId ==
                                      constant.UserRoleId.SchoolTeacher && (
                                      <LockIcon
                                        className="inline-flex mr-2 w-5 h-5 text-gray-500"
                                        title="Assessment locked by admin"
                                      />
                                    )}

                                  {assignment.name}
                                </>
                              )}
                              {assignment.hasActicity &&
                                assignment.assignmentActivity &&
                                assignment.activityCount === 1 && (
                                  <div>
                                    <div>{assignment.name} </div>
                                    <div className="flex items-center">
                                      {assignment.hasLockedPremiumAssessments &&
                                        props.roleId ==
                                          constant.UserRoleId.SchoolTeacher && (
                                          <LockIcon
                                            className="inline-flex mr-2 w-5 h-5 text-gray-500"
                                            title="Assessment locked by admin"
                                          />
                                        )}
                                      <a
                                        href="#!"
                                        className="text-blue-500 underline hover:no-underline"
                                        onClick={() =>
                                          handleSettingPopup(
                                            assignment,
                                            assignment.assignmentActivity
                                          )
                                        }
                                      >
                                        {getActivityName(
                                          assignment.assignmentActivity
                                        )}
                                      </a>
                                    </div>
                                  </div>
                                )}
                            </td>
                            <td
                              className={`text-sm mt-5 text-center p-3  ${
                                assignment.hasActicity &&
                                assignment.activityCount === 1 &&
                                assignment.completed > 0 &&
                                isAllowViewAssignmentAssessment(
                                  props.roleId,
                                  assignment.assignmentActivity
                                    ?.canViewAssessment,
                                  assignment.assignmentActivity?.activityType,
                                  assignment.assignmentActivity
                                    ?.assessmentCreatedByUserId,
                                  props.userId,
                                  assignment.assignmentActivity
                                    ?.assessmentSharedTypeId
                                )
                                  ? "cursor-pointer text-blue-500 underline hover:no-underline"
                                  : ""
                              }`}
                              onClick={() =>
                                assignment.activityCount === 1 &&
                                assignment.completed > 0
                                  ? handleResultsClick(
                                      parseInt(assignment.assignmentId),
                                      assignment.name,
                                      assignment.assignmentActivity
                                        ? assignment.assignmentActivity
                                            .assignmentActivityId
                                        : 0,
                                      assignment.assignmentActivity
                                        ?.activityType ?? "",
                                      assignment.assignmentActivity
                                        ?.activityType ===
                                        Constant.AssignmentActivityType
                                          .CONSTRUCTEDRESPONSE ||
                                        assignment.assignmentActivity
                                          ?.isAssessmentWithCR!,
                                      assignment.assignmentActivity
                                        ?.assessmentId,
                                      assignment.assignmentActivity
                                        ?.canViewAssessment,
                                      assignment.assignmentActivity
                                        ?.assessmentSharedTypeId,
                                      assignment.assignmentActivity
                                        ?.assessmentCreatedByUserId,
                                      assignment.subjectId
                                    )
                                  : undefined
                              }
                            >
                              {assignment.hasActicity &&
                              assignment.activityCount === 1
                                ? `${assignment.completed}/${assignment.assigned}`
                                : ""}
                              {hasMultipleActivities &&
                                selectedAssignmentId !==
                                  parseInt(assignment.assignmentId) && (
                                  <a
                                    className="cursor-pointer text-blue-500 underline hover:no-underline"
                                    onClick={() =>
                                      handleActivityDetails(
                                        parseInt(assignment.assignmentId)
                                      )
                                    }
                                  >
                                    Expand to view
                                  </a>
                                )}
                              {hasMultipleActivities &&
                                selectedAssignmentId ===
                                  parseInt(assignment.assignmentId) && (
                                  <a
                                    className="cursor-pointer text-blue-500 underline hover:no-underline"
                                    onClick={() =>
                                      handleActivityDetails(
                                        parseInt(assignment.assignmentId)
                                      )
                                    }
                                  >
                                    Collapse view
                                  </a>
                                )}
                            </td>
                            <td className="text-sm mt-5 p-3">
                              <ContextMenu
                                items={getOptions(
                                  hasMultipleActivities,
                                  assignment.hasLockedPremiumAssessments &&
                                    roleId == constant.UserRoleId.SchoolTeacher
                                    ? false
                                    : true,
                                  Number(assignment.author),
                                  props.userId,
                                  assignment.hasLockedPremiumAssessments &&
                                    roleId == constant.UserRoleId.SchoolTeacher
                                    ? false
                                    : showPrintOption(
                                        assignment.assignmentActivity
                                          ?.activityTypeId ?? 0
                                      )
                                ).filter((r) =>
                                  r.roles.some((r) => r === roleId)
                                )}
                                entityId={
                                  hasMultipleActivities
                                    ? assignment.assignmentId
                                      ? parseInt(assignment.assignmentId)
                                      : 0
                                    : assignment.assignmentActivity
                                        ?.assignmentActivityId
                                }
                                onChange={handleOptionChange}
                                learnosityId={
                                  assignment.assignmentActivity
                                    ?.learnosityIdEnglish
                                }
                                activityName={assignment.name}
                                activityType={getContextMenuActivityType(
                                  hasMultipleActivities
                                )}
                                value={""}
                                onUpgrade={() => {}}
                              />
                            </td>
                          </tr>
                          {assignmentActivities &&
                            assignmentActivities.length > 0 &&
                            assignmentActivities[0].assignmentId ===
                              parseInt(assignment.assignmentId) &&
                            assignmentActivities.map(
                              (assignmentActivity, index) => {
                                return (
                                  <tr
                                    key={index}
                                    className={`hover:bg-light-violet/20 transition ease-in-out duration-300 ${
                                      index % 2 === 0
                                        ? "bg-white"
                                        : "bg-light-violet/10"
                                    }`}
                                  >
                                    <td className=" mt-5 p-3"></td>
                                    <td className="mt-5"></td>
                                    <td className=" mt-5 p-3"></td>
                                    {selectedDateType &&
                                      selectedDateType.label !== "Select" && (
                                        <td className=" mt-5 p-3"></td>
                                      )}
                                    <td className="text-sm mt-5  p-3 "></td>
                                    <td className="text-sm mt-5  p-3">
                                      <div>
                                        <div className="flex items-center">
                                          {(assignmentActivity.unGraded ?? 0) > 0 && (
                                              <GradingIcon
                                                title="Constructed Response"
                                                className="mr-2 w-5 h-5 text-red-500 hover:text-red-700 cursor-pointer"
                                                onClick={(e) =>
                                                  assignmentActivity.completed &&
                                                  assignmentActivity.completed >
                                                    0
                                                    ? handleResultsClick(
                                                        parseInt(
                                                          assignment.assignmentId
                                                        ),
                                                        assignment.name,
                                                        assignmentActivity.assignmentActivityId,
                                                        assignmentActivity.activityType,
                                                        true,
                                                        assignmentActivity.assessmentId,
                                                        assignmentActivity?.canViewAssessment,
                                                        assignmentActivity?.assessmentSharedTypeId,
                                                        assignmentActivity?.assessmentCreatedByUserId,
                                                        assignment.subjectId
                                                      )
                                                    : undefined
                                                }
                                              />
                                            )}
                                          {assignmentActivity.hasLockedPremiumAssessment &&
                                            props.roleId ==
                                              constant.UserRoleId
                                                .SchoolTeacher && (
                                              <LockIcon
                                                className="inline-flex mr-2 w-5 h-5 text-gray-500"
                                                title="Assessment locked by admin"
                                              />
                                            )}
                                          <a
                                            href="#!"
                                            className="text-blue-500 underline hover:no-underline"
                                            onClick={() =>
                                              handleSettingPopup(
                                                assignment,
                                                assignmentActivity
                                              )
                                            }
                                          >
                                            {getActivityName(
                                              assignmentActivity
                                            )}
                                          </a>
                                        </div>
                                      </div>
                                    </td>
                                    <td
                                      className={`text-sm mt-5 text-center p-3  ${
                                        assignmentActivity.completed &&
                                        assignmentActivity.completed > 0 &&
                                        isAllowViewAssignmentAssessment(
                                          props.roleId,
                                          assignmentActivity?.canViewAssessment,
                                          assignmentActivity?.activityType,
                                          assignmentActivity?.assessmentCreatedByUserId,
                                          props.userId,
                                          assignmentActivity?.assessmentSharedTypeId
                                        )
                                          ? "cursor-pointer text-blue-500 underline hover:no-underline"
                                          : ""
                                      }`}
                                      onClick={(e) =>
                                        assignmentActivity.completed &&
                                        assignmentActivity.completed > 0
                                          ? handleResultsClick(
                                              parseInt(assignment.assignmentId),
                                              assignment.name,
                                              assignmentActivity.assignmentActivityId,
                                              assignmentActivity.activityType,
                                              assignmentActivity.activityType ===
                                                Constant.AssignmentActivityType
                                                  .CONSTRUCTEDRESPONSE ||
                                                assignmentActivity.isAssessmentWithCR,
                                              assignmentActivity.assessmentId,
                                              assignmentActivity?.canViewAssessment,
                                              assignmentActivity?.assessmentSharedTypeId,
                                              assignmentActivity?.assessmentCreatedByUserId,
                                              assignment.subjectId
                                            )
                                          : undefined
                                      }
                                    >
                                      {`${
                                        assignmentActivity.completed
                                          ? assignmentActivity.completed
                                          : 0
                                      }/${assignment.assigned}`}
                                    </td>
                                    <td className="text-sm mt-5 p-3">
                                      <ContextMenu
                                        items={getOptions(
                                          false,
                                          false,
                                          Number(assignment.author),
                                          props.userId,
                                          assignmentActivity.hasLockedPremiumAssessment
                                            ? false
                                            : showPrintOption(
                                                assignmentActivity.activityTypeId
                                              ),
                                          true
                                        ).filter((r) =>
                                          r.roles.some((r) => r === roleId)
                                        )}
                                        entityId={
                                          assignmentActivity.assignmentActivityId
                                        }
                                        onChange={handleOptionChange}
                                        learnosityId={
                                          assignmentActivity?.learnosityIdEnglish
                                        }
                                        activityName={getActivityName(
                                          assignmentActivity
                                        )}
                                        activityType={
                                          constant.ContextMenuActivityType
                                            .GROUPEDACTIVITYASSIGNMENT
                                        }
                                        value={""}
                                        onUpgrade={() => {}}
                                      />
                                    </td>
                                  </tr>
                                );
                              }
                            )}
                        </Fragment>
                      );
                    })}
                  {assignments?.data.length === 0 && (
                    <tr>
                      <td colSpan={8}>
                        <div className="text-gray-700 w-full mt-2">
                          No records found
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
              {assignments?.totalRecords !== undefined &&
                assignments?.totalRecords !== 0 && (
                  <Pagination
                    totalRecords={assignments?.totalRecords}
                    pageSize={pageSize}
                    onPageChange={onPageChange}
                    reset={resetPageNumber}
                    pageNumber={pageNumber}
                  />
                )}
              {assignments?.totalRecords !== 0 && (
                <PageSize
                  pageSize={pageSize}
                  onPageSizeChange={onPageSizeChange}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default ClassAssignment;

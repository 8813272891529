import { connect } from "react-redux";
import LiftOffDiagnostics from "../../shared/liftoff/liftOffDiagnostics";

function DiagnosticsMS(props: any) {
  return (
    <>
      <LiftOffDiagnostics
        userId={props.userContext?.userId}
        refresh={true}
      ></LiftOffDiagnostics>
    </>
  );
}

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
  };
};
export default connect<{}, {}, {}>(mapStateToProps, {})(DiagnosticsMS);

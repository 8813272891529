import { LogoPLIcon } from "../../../../../assets/icons"

interface IPrintHeaderProps {
  ReportName: string
}

function PrintHeader(props: IPrintHeaderProps) {
  return (
    <div className="flex items-center justify-between mb-4 pb-4 border-b-2 overflow-x-auto xl:overflow-x-visible">
      <LogoPLIcon
        title="Progress Learning logo"
        className="w-20 h-auto ml-1 mr-1 sm:mr-0 sm:ml-3 cursor-pointer"
      ></LogoPLIcon>
      <h1 className="font-semibold text-primary-violet text-base">
        {props.ReportName}
      </h1>
      <div className={`text-center mt-2 mb-4`}>
        <h3 className="text-gray-400 text-xs">
          {" "}
          {new Date().toLocaleDateString()}
        </h3>
      </div>
    </div>
  )
}

export default PrintHeader

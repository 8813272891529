import { XIcon } from "@heroicons/react/outline";
import { connect } from "react-redux";
import NoData from "../../shared/noData";
import Pagination from "../../shared/pagination/pagination";
import PageSize from "../../shared/pagination/pageSize";
import { useEffect, useState } from "react";
import {
  DEFAULT_PAGE_NUMBER,
  DEFAULT_PAGE_SIZE,
} from "../../../utils/pagingConstant";
import { getNWEAStudentResult } from "../../../api/teacher/nwea";
import moment from "moment";

interface INWEAResultModal {
  userId?: number;
  nweaId?: string;
  firstName?: string;
  lastName?: string;
  closeModal: () => void;
}

interface INWEAStudentResultModel {
  subject: string;
  testName: string;
  testDate: Date;
  topic: string;
  score: string;
  grade: string;
  isTestValid: boolean;
}

const NWEAResultModal = (props: INWEAResultModal) => {
  const [pageNumber, setPageNumber] = useState(DEFAULT_PAGE_NUMBER);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [resetPageNumber, setResetPageNumber] = useState<boolean>(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [nweaResult, setNWEAResult] = useState<Array<any>>([]);

  const getNWEAResult = (
    userId?: number,
    nweaId?: string,
    pageNumber?: number,
    pageSize?: number
  ) => {
    getNWEAStudentResult(userId, nweaId, pageNumber, pageSize).then(
      (response) => {
        if (response.data.data.length > 0) {
          setTotalRecords(response.data.totalRecords);
          const studentResult: Array<INWEAStudentResultModel> =
            response.data.data;
          setNWEAResult(studentResult);
        } else {
          setNWEAResult([]);
          setTotalRecords(0);
        }
      }
    );
  };

  useEffect(() => {
    getNWEAResult(props.userId, props.nweaId, pageNumber, pageSize);
  }, [pageSize, pageNumber, resetPageNumber]);

  const onPageChange = (pageNo) => {
    setPageNumber(pageNo);
  };

  const onPageSizeChange = (pageSize) => {
    setPageNumber(DEFAULT_PAGE_NUMBER);
    setPageSize(pageSize);
    setResetPageNumber(true);
  };

  return (
    <div className="fixed inset-0 z-40 flex items-center bg-black bg-opacity-50 sm:items-center sm:justify-center">
      <div
        className="w-full flex flex-col justify-between overflow-hidden h-auto max-h-80percent bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:max-w-5xl"
        role="dialog"
      >
        <header>
          <div className="px-4 py-4 bg-gray-50">
            <div className="flex items-start justify-between space-x-3 border-b border-gray-300">
              <div className="space-y-1">
                <div className="text-lg font-medium text-gray-900">
                  <h2 className="text-lg text-gray-700 mb-2">
                    {" "}
                    NWEA Details -
                    <span className="bg-gray-100 border text-gray-700 text-sm p-1 mr-2">
                      {" "}
                      {`${props.lastName}${props.firstName ? ", " : ""}${
                        props.firstName
                      }   `}
                    </span>
                  </h2>
                </div>
              </div>

              <div className="h-7 flex items-center">
                <button
                  type="button"
                  className="text-gray-400 hover:text-gray-500"
                  onClick={() => props.closeModal()}
                >
                  <span className="sr-only">Close panel</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </header>

        <div className="relative overflow-auto px-4 pb-6">
          <div className="shadow overflow-auto">
            <table className="table w-full ">
              <thead className="mb-5">
                <tr className="bg-light-violet  text-white py-3 px-3">
                  <th
                    scope="col"
                    className="text-left  p-3 text-sm font-normal"
                  >
                    <div>Subject</div>
                  </th>
                  <th
                    scope="col"
                    className="text-left  p-3 text-sm font-normal"
                  >
                    <div>Test Name</div>
                  </th>

                  <th
                    scope="col"
                    className="text-left  p-3 text-sm font-normal"
                  >
                    <div>Test Date</div>
                  </th>
                  <th
                    scope="col"
                    className="text-left  p-3 text-sm font-normal"
                  >
                    <div>Topic</div>
                  </th>
                  <th
                    scope="col"
                    className="text-left  p-3 text-sm font-normal"
                  >
                    <div>NWEA Score</div>
                  </th>
                  <th
                    scope="col"
                    className="text-left  p-3 text-sm font-normal"
                  >
                    <div>NWEA Grade</div>
                  </th>
                  <th
                    scope="col"
                    className="text-left  p-3 text-sm font-normal"
                  >
                    <div>Test Valid</div>
                  </th>
                </tr>
              </thead>

              <tbody>
                {nweaResult?.map((result, i) => (
                  <tr
                    className={
                      i % 2 === 0
                        ? " hover:bg-light-violet/20 transition ease-in-out duration-300 bg-white"
                        : "hover:bg-light-violet/20 transition ease-in-out duration-300 bg-light-violet/10"
                    }
                  >
                    <td className="p-3 text-sm text-gray-500">
                      {result.subject}
                    </td>
                    <td className="p-3 text-sm text-gray-500">
                      {result.testName}
                    </td>
                    <td className="p-3 text-sm text-gray-500">
                      {moment(result.testDate).format("MM-DD-YYYY")}
                    </td>
                    <td className="p-3 text-sm text-gray-500">
                      {result.topic}
                    </td>
                    <td className="p-3 text-sm text-gray-500">
                      {result.score}
                    </td>
                    <td className="p-3 text-sm text-gray-500">
                      {result.grade}
                    </td>
                    <td className="p-3 text-sm text-gray-500">
                      {result.isTestValid ? "True" : "False"}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="flex px-4 pb-4">
          {nweaResult?.length === 0 && <NoData />}
          {nweaResult?.length !== 0 && (
            <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between border-t-2 pt-4">
              <Pagination
                totalRecords={totalRecords}
                pageNumber={pageNumber}
                pageSize={pageSize}
                onPageChange={onPageChange}
                reset={resetPageNumber}
                setPageNumber={setPageNumber}
              />
              <PageSize
                pageSize={pageSize}
                onPageSizeChange={onPageSizeChange}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default connect((state: any) => {
  return {
    masterData: state.masterData,
    userContext: state.userContext,
  };
}, {})(NWEAResultModal);

import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const PageSize: React.FC<any> = (props: {
  onPageSizeChange: any;
  bgColor?: string;
  pageSize?: number;
}) => {
  const [isDDLOpened, setIsDDLOpened] = useState<boolean>(false);
  const [pageSize, setPageSize] = useState<number>(props.pageSize ?? 10);
  const { t } = useTranslation();

  const ddlBtnClick = () => {
    setIsDDLOpened(!isDDLOpened);
  };

  const onPageSizeChange = (n) => {
    setPageSize(n);
    props.onPageSizeChange(n);
  };

  return (
    <div className={`inline-flex items-center mt-2`}>
      <p className="whitespace-nowrap text-sm text-gray-500 inline-block lg:inline-block mr-2 hidden lg:inline-block lg:whitespace-normal">
        {t("Pagination.NoOfRowsPerPage")}
      </p>

      <div
        className={`border rounded flex justify-center h-8 cursor-pointer border-${
          props.bgColor ? props.bgColor : "light-violet"
        }  px-4`}
        onClick={() => ddlBtnClick()}
      >
        <button
          type="button"
          className="rounded flex items-center text-sm focus:outline-none"
          aria-expanded="false"
          aria-haspopup="true"
        >
          <ul>
            <li className="flex text-left text-sm text-gray-700 items-center">
              {pageSize}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="inline h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </li>
          </ul>
        </button>
        {isDDLOpened && (
          <div
            className="transition-all origin-top-right absolute mt-2 z-50 rounded-md shadow-lg py-1 bg-gray-200 ring-1 ring-black ring-opacity-5 focus:outline-none"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="user-menu-button"
            tabIndex={-1}
            style={{
              height: "max-content",
            }}
          >
            {[10, 20, 50, 100].map((ds) => {
              return (
                <div
                  className={`whitespace-nowrap block px-4 py-2 text-sm text-gray-700 font-medium hover:bg-${
                    props.bgColor ? props.bgColor : "light-violet"
                  }  hover:text-white`}
                  key={ds}
                  onClick={() => onPageSizeChange(ds)}
                >
                  {ds}
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};
export default PageSize;

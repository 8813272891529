import { Configuration } from "../../../../environment/setup";
import VideoMessageFilters from "../../../../model/videoMessage/videoMessageFilters";
import PublishMessageParams from "../../../../model/videoMessage/publishMessageParams";
import { AxiosHelper } from "../../../../environment/axiosConfig";


export function getVideoMessage(videoMessageFilters: VideoMessageFilters) {
  const url = `${Configuration.ApiBaseUrl}VideoMessage/GetVideoMessage`;
  return AxiosHelper.axiosInstance().post(url, videoMessageFilters);
}

export function deleteVideoMessage(userId: number, messageId: number) {
  const url = `${Configuration.ApiBaseUrl}VideoMessage/DeleteVideoMessage`;
  return AxiosHelper.axiosInstance().delete(url, {
    params: {
      userId: userId,
      messageId: messageId,
    },
  });
}

export function uploadFile(formData: FormData) {
  const headers = { "Content-Type": "multipart/form-data" };

  const url = `${Configuration.ApiBaseUrl}FileUpload/Upload`;
  return AxiosHelper.axiosInstance().post(url, formData, { headers: headers });
}

export function publishData(publishMessageParams: PublishMessageParams) {
  const url = `${Configuration.ApiBaseUrl}VideoMessage/PublishVideoMessage`;
  return AxiosHelper.axiosInstance().post(url, publishMessageParams);
}

import { AxiosHelper } from "../../environment/axiosConfig";
import APIConstant from "../../utils/constant/apiConstant";

export const getTestComparisonStudents = (
  subjectId: number,
  preAssessmentId: number | null,
  preDiagnosticTestId: number | null,
  postAssessmentId: number | null,
  postDiagnosticTestId: number | null,
  userId: number,
  classIds: number | null,
  schoolIds: string | null,
  schoolYearId: number | null,
  pageNumber: number,
  pageSize: number
): Promise<any> => {
  return AxiosHelper.axiosInstance().get(
    APIConstant.TESTCOMPARISONREPORT_API.GetTestComparisonStudents,
    {
      params: {
        subjectId: subjectId,
        preAssessmentId: preAssessmentId,
        postAssessmentId: postAssessmentId,
        preDiagnosticTestId: preDiagnosticTestId,
        postDiagnosticTestId: postDiagnosticTestId,
        userId: userId,
        classIds: classIds,
        schoolIds: schoolIds,
        pageNumber: pageNumber,
        pageSize: pageSize,
        schoolYearId: schoolYearId
      },
    }
  );
};

export const getTestComparisonStudentsExport = (
  subjectId: number,
  preAssessmentId: number | null,
  preDiagnosticTestId: number | null,
  postAssessmentId: number | null,
  postDiagnosticTestId: number | null,
  userId: number,
  classIds: number | null,
  schoolIds: string | null,
  schoolYearId: number | null
): Promise<any> => {
  return AxiosHelper.axiosInstance().get(
    APIConstant.TESTCOMPARISONREPORT_API.GetTestComparisonStudentsExport,
    {
      responseType: "blob",
      params: {
        subjectId: subjectId,
        preAssessmentId: preAssessmentId,
        postAssessmentId: postAssessmentId,
        preDiagnosticTestId: preDiagnosticTestId,
        postDiagnosticTestId: postDiagnosticTestId,
        userId: userId,
        classIds: classIds,
        schoolIds: schoolIds,
        schoolYearId: schoolYearId
      },
    }
  );
};

export const getTestComparisonReportHeader = (headerSearch) => {
  return AxiosHelper.axiosInstance().get(
    APIConstant.TESTCOMPARISONREPORT_API.GetTestComparisonHeader,
    {
      params: {
        subjectId: headerSearch.subjectId,
        preAssessmentId: headerSearch.preAssessmentId,
        postAssessmentId: headerSearch.postAssessmentId,
        preDiagnosticTestId: headerSearch.preDiagnosticTestId,
        postDiagnosticTestId: headerSearch.postDiagnosticTestId,
        classIds: headerSearch.classIds,
        schoolId: headerSearch.schoolId,
        schoolYearId: headerSearch.schoolYearId
      },
    }
  );
};

export const getTestComparisonStudentDetails = (
  userId: number,
  subjectId: number,
  preActivitySessionId: number | null,
  postActivitySessionId: number | null,
  schoolYearId: number | null
) => {
  return AxiosHelper.axiosInstance().get(
    APIConstant.TESTCOMPARISONREPORT_API.GetTestComparisonStudentsDetails,
    {
      params: {
        subjectId: subjectId,
        preActivitySessionId: preActivitySessionId,
        postActivitySessionId: postActivitySessionId,
        studentId: userId,
        schoolYearId: schoolYearId
      },
    }
  );
};

export const getTestComparisonStudentDetailsExport = (
  subjectId: number,
  preAssessmentId: number | null,
  preDiagnosticTestId: number | null,
  postAssessmentId: number | null,
  postDiagnosticTestId: number | null,
  userId: number,
  classIds: string,
  schoolIds: string,
  schoolYearId: number
): Promise<any> => {
  return AxiosHelper.axiosInstance().get(
    APIConstant.TESTCOMPARISONREPORT_API.GetTestComparisonStudentDetailsExport,
    {
      responseType: "blob",
      params: {
        subjectId: subjectId,
        preAssessmentId: preAssessmentId,
        postAssessmentId: postAssessmentId,
        preDiagnosticTestId: preDiagnosticTestId,
        postDiagnosticTestId: postDiagnosticTestId,
        userId: userId,
        classIds: classIds,
        schoolIds: schoolIds,
        schoolYearId: schoolYearId
      },
    }
  );
};

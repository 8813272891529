import Environments from "./constant/environment";

export const getEnvironment = (): string =>  {
  const PROGRESSLEARNINGDOMAIN: string = "progresslearning.com"
  const currentHostname = window && window.location && window.location.hostname;
  let curEnvironment: string = Environments.Environment.LOCAL;

  switch (currentHostname) {
    case "localhost":
      curEnvironment = Environments.Environment.LOCAL;
      break;
    case `app-dev.${PROGRESSLEARNINGDOMAIN}`:
    case `app-dev-blue.${PROGRESSLEARNINGDOMAIN}`:
    case `app-dev-green.${PROGRESSLEARNINGDOMAIN}`:
      curEnvironment = Environments.Environment.DEV;
      break;
    case `app-preqa.${PROGRESSLEARNINGDOMAIN}`:
    case `app-qa-blue.${PROGRESSLEARNINGDOMAIN}`:
    case `app-qa-green.${PROGRESSLEARNINGDOMAIN}`:
    case `app-qa.${PROGRESSLEARNINGDOMAIN}`:
      curEnvironment = Environments.Environment.QA;
      break;
    case `app-sandbox-blue.${PROGRESSLEARNINGDOMAIN}`:
    case `app-sandbox-green.${PROGRESSLEARNINGDOMAIN}`:
    case `app-sandbox.${PROGRESSLEARNINGDOMAIN}`:
      curEnvironment = Environments.Environment.SANDBOX;
      break;
    case `app-localprod.${PROGRESSLEARNINGDOMAIN}`:
    case `app-preprod.${PROGRESSLEARNINGDOMAIN}`:
    case `app-blue.${PROGRESSLEARNINGDOMAIN}`:
    case `app-green.${PROGRESSLEARNINGDOMAIN}`:
    case `app.${PROGRESSLEARNINGDOMAIN}`:
      curEnvironment = Environments.Environment.PRODUCTION;
      break;
    default:
      curEnvironment = Environments.Environment.LOCAL;
  }
  return curEnvironment;
}

export const isLocalEnv = ():boolean => 
  getEnvironment() === Environments.Environment.LOCAL;

export const isDevEnv = ():boolean => 
  getEnvironment() === Environments.Environment.DEV;

export const isQAEnv = ():boolean => 
  getEnvironment() === Environments.Environment.QA;

export const isSandEnv = ():boolean =>
  getEnvironment() === Environments.Environment.SANDBOX;

export const isProdEnv = ():boolean =>
  getEnvironment() === Environments.Environment.PRODUCTION;

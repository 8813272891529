import { useTranslation } from "react-i18next";
import { ResultCoinIcon } from "../../../assets/icons";
import Profile from "../../../model/users/profile";

interface GameSummaryProps {
  profile: Profile;
  finalScore: number;
  tokensSpent: number;
  gameName: string;
  dateStarted: string;
  dateCompleted: string;
  elapsedTime: string;
  setGameFinished: (gameFinished: boolean) => void;
}
const GameSummary = (props: GameSummaryProps) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="md:px-8">
        <div className="bg-white w-full xl:w-3/4 xxxl:w-1/2 rounded-lg overflow-hidden pt-12 mb-12">
          <div className="flex justify-center border-b border-gray-400 w-4/5 mx-auto pb-2">
            <h2 className="text-lg font-medium text-gray-500">{props.profile.firstName}  {props.profile.lastName}</h2>
          </div>
          <div className="flex flex-col items-center mt-4">
            <div className="flex gap-x-4">
              <span className="text-5xl text-gray-700 font-light block text-center">
                Final Score
              </span>
              <span className="text-5xl font-bold block text-center text-primary-violet">
                {props.finalScore}
              </span>
            </div>
            <div className="bg-[#fb923c] px-10 py-1 rounded-lg mt-1">
              <span className="text-white text-xl">
                Tokens Spent{" "}
                <span className="text-gray-700 font-bold ml-2">{props.tokensSpent}</span>
              </span>
            </div>

            <span className="flex relative mt-2 mb-10">
            {Array.from({ length: props.tokensSpent }, (_, i) =>  <ResultCoinIcon className=" w-8 " />)}          
            </span>
          </div>
          <div className="w-4/5 text-lg mx-auto">
            <div>
              <div className="grid grid-cols-2">
                <div className="p-4 text-right text-gray-700 bg-primary-violet/10">
                  Game
                </div>
                <div className="p-4 font-medium bg-primary-violet/10">
                  {props.gameName}
                </div>
                <div className="p-4 text-right text-gray-700">Date Started</div>
                <div className="p-4 font-medium flex flex-wrap">
                  {props.dateStarted}
                  {/* <span className="text-gray-500 sm:border-l sm:border-gray-400 sm:pl-3 sm:ml-3">
                   
                  </span> */}
                </div>
                <div className="p-4 text-right text-gray-700 bg-primary-violet/10">
                  Date Completed
                </div>
                <div className="p-4 font-medium bg-primary-violet/10 flex flex-wrap">
                  {props.dateCompleted}
                  {/* <span className="text-gray-500 sm:border-l sm:border-gray-400 sm:pl-3 sm:ml-3">
                   
                  </span> */}
                </div>
                <div className="p-4 text-right text-gray-700">Elapsed Time</div>
                <div className="p-4 font-medium">{props.elapsedTime}</div>
              </div>
            </div>
          </div>
          <div className="flex justify-center gap-x-4 mt-10 bg-gray-50 py-8">
            <button
              onClick={() => props.setGameFinished(false)}
              className="bg-blues hover:opacity-90 inline-block whitespace-nowrap rounded-md text-base text-center py-2 px-4 text-white flex items-center justify-center"
            >
              {t("Shared.StudentSideBar.Arcade")}
            </button>           
          </div>
        </div>
      </div>
    </>
  );
};
export default GameSummary;

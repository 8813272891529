export const initRequest = (itemRef: string, isStudentScreen?: boolean) => {
  return {
    mode: "item_edit",
    reference: itemRef,
    config: {
      item_edit: {
        item: {
          back: false,
          columns: false,
          answers: isStudentScreen ? false : true,
          scoring: false,
          reference: {
            edit: false,
            show: false,
          },
          duplicate: {
            show: false,
          },
          mode: {
            default: "preview",
            show: false,
          },
          save: false,
          status: false,
          dynamic_content: false,
          shared_passage: true,
        },
        widget: {
          delete: false,
          edit: false,
        },
      },
    },
    user: {
      id: "demoTest",
      firstname: "",
      lastname: "",
      email: "",
    },
  };
};

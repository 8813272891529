import { useEffect, useState } from "react";
import Loader from "../../../shared/loader";
import classNames from "classnames";
import PreviewPage from "./questionPreview/learnosityPreviewPage";
import Classification from "./questionPreview/classification";
import { getReplacementQuestions } from "../../../../api/teacher/assessment";
import { XIcon } from "@heroicons/react/outline";
import { InformationIcon } from "../../../../assets/icons";
import { IAssessmentQuestion } from "../../../../model/interface/assessmentQuestion";
import {Configuration} from "../../../../environment/setup";

interface QuestionOptionPopupProp {
  standardId: number;
  assessmentId: number;
  questionTypeId: number;
  assessmentQuestionTypeId: number;
  selectQuestion: (selectQuestion: IAssessmentQuestion) => void;
  handleOptionNavigation: (isNavigated: boolean) => void;
  setOpen: (open: boolean) => void;
  limit?: number;
  passageId?: number;
  districtId: number;
  hasDistrictAssessmentQuestionIcon: boolean;
}

const QuestionOptionPopup: React.FC<QuestionOptionPopupProp> = (props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedQuestion, setSelectedQuestion] = useState<string>("");
  const [replacementQuestions, setReplacementQuestions] =
    useState<Array<any>>();
  const [minimumQuestionAlert, setMinimumQuestionAlert] =
    useState<boolean>(false);

  useEffect(() => {
    props.handleOptionNavigation(true);
    getRandomQuestions();
  }, []);

  useEffect(() => {
    if (replacementQuestions?.length ?? 0 > 0) {
      renderQuestion();
    }
  }, [selectedQuestion, replacementQuestions]);

  const renderQuestion = () => {
    getRefId();
    //return <PreviewPage itemRef={getRefId()} />;
  };

  const getRefId = () => {
    let id = "";
    const data = replacementQuestions?.filter(
      (ds) => ds.learnosityReferenceId === selectedQuestion
    );
    if (data?.length) {
      id = data[0].learnosityReferenceId;
      setSelectedQuestion(id);
    }
    return id || "";
  };
  const getRandomQuestions = async () => {
    setLoading(true);
    const resp = await getReplacementQuestions(
      props.standardId,
      props.assessmentId,
      props.questionTypeId,
      props.assessmentQuestionTypeId,
      props.limit,
      props.passageId
    );
    setLoading(false);
    if (resp && resp.data && resp.data.length) {
      if (resp.data.length < (props.limit ?? 5)) {
        setMinimumQuestionAlert(true);
      }
      setReplacementQuestions(resp.data);
      setSelectedQuestion(resp.data[0].learnosityReferenceId);
    } else {
      setReplacementQuestions([]);
      setSelectedQuestion("");
    }
  };

  const handleQuestionSave = () => {
    var selectedQuestionDetail = replacementQuestions?.filter(
      (q) => q.learnosityReferenceId == selectedQuestion
    )[0];
    if (selectedQuestionDetail) {
      props.handleOptionNavigation(false);
      props.selectQuestion(selectedQuestionDetail);
    }
  };

  return (
    <>
      {loading && <Loader />}
      <div className="fixed inset-0 z-10 flex items-center bg-black bg-opacity-50 sm:items-center sm:justify-center">
        <div
          className={`w-full flex flex-col justify-between overflow-hidden h-auto  bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 max-h-80percent sm:max-w-7xl`}
          role="dialog"
        >
          <header>
            {/* Header */}
            <div className="px-4 py-4 bg-gray-50">
              <div className="flex items-center">
                <div className="h-7 flex items-center">
                  <button
                    type="button"
                    className="text-gray-400 hover:text-gray-500"
                    onClick={() => {
                      props.handleOptionNavigation(false);
                      props.setOpen(false);
                    }}
                  >
                    <span className="sr-only">Close</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
          </header>

          {replacementQuestions && replacementQuestions.length > 0 && (
            <div className="w-full relative overflow-auto px-4 py-1">
              <div className="text-sm text-gray-700 font-light bg-blue-50 p-4 mt-4 flex w-full rounded-md mb-6 rounded-t-lg sm:rounded-lg overflow-hidden">
                <div className="flex-shrink-0 current-fill text-blue-400">
                  <InformationIcon />
                </div>
                <span className="pl-2">
                  Choose the question you would like to use to replace the
                  selected question from one of the options presented below.
                  Options are randomly generated based on the associated
                  standard and a new list of questions will be displayed each
                  time ‘Replace Question’ or ‘Refresh List’ is selected.
                </span>
              </div>
              {minimumQuestionAlert && (
                <>
                  <div className="text-sm text-gray-700 font-light bg-blue-50 p-4 mt-4 flex w-full rounded-md mb-6 rounded-t-lg sm:rounded-lg overflow-hidden">
                    <div className="flex-shrink-0 current-fill text-blue-400">
                      <InformationIcon />
                    </div>
                    <span className="pl-2">
                      Your assessment already contains the majority of questions
                      within this standard. The options displayed are the
                      remaining questions from our item bank.
                    </span>
                  </div>
                </>
              )}

              <div className="py-5 border-b">
                <div className="sm:px-6 lg:px-8 py-1 pt-2 bg-gray-100 mt-2">
                  <div className="flex mb-2 sm:mb-0 px-4 sm:px-0 flex-col sm:flex-row items-end sm:justify-between sm:items-center">
                    <nav className="flex space-x-2" aria-label="Tabs">
                      {replacementQuestions &&
                        replacementQuestions.map((que, i) => (
                          <a
                            href={`#${que.learnosityReferenceId}`}
                            key={i}
                            onClick={() => {
                              props.handleOptionNavigation(true);
                              setSelectedQuestion(que.learnosityReferenceId);
                            }}
                            className={classNames(
                              que.learnosityReferenceId === selectedQuestion
                                ? "border-secondary-teal bg-primary-green text-white hover:bg-opacity-90 hover:text-white"
                                : "text-gray-500 border-gray-300 hover:text-gray-700 hover:bg-gray-50 hover:border-gray-400/70",
                              "py-3 px-2 sm:px-4 text-center border-b-4 font-medium text-sm cursor-pointer"
                            )}
                          >
                            {`Option ${i + 1}`}
                          </a>
                        ))}
                    </nav>
                    <button
                      className={`bg-primary-violet border border-transparent shadow-sm mt-4 sm:mt-0 py-2 px-4 justify-center text-sm font-medium text-white hover:bg-dark-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
                      type="button"
                      onClick={(e) => getRandomQuestions()}
                    >
                      Refresh List
                    </button>
                  </div>
                </div>
              </div>
              <div className="pb-6">
                {replacementQuestions &&
                  replacementQuestions.map(
                    (que, i) =>
                      que.learnosityReferenceId === selectedQuestion &&
                      selectedQuestion.length > 0 &&
                      (replacementQuestions?.length ?? 0 > 0) && (
                        <div className="grid" key={i}>
                          <div className="flex justify-between bg-gray-50 mb-2 py-3 px-4 items-center">
                            <div className="flex-col px-3 py-2 font-semibold text-gray-700 text-base">
                              <div className="flex">
                                <div className="flex-col">{que.questionId}</div>
                                <div className="flex-col ml-2">
                                  {props.hasDistrictAssessmentQuestionIcon && que.isDistrictAssessmentQuestion ? (
                                      <img alt="" className=""
                                           src={`${Configuration.S3bucketImagePath}/districts/${props.districtId.toString()}/district-assessment-question-icon.png`}/>) : undefined}
                                </div>
                              </div>
                            </div>
                            <Classification
                              questionId={parseInt(
                                selectedQuestion?.split("-")[0] ?? "0"
                              )}
                              standardId={props.standardId.toString()}
                              questionTypeId={
                                selectedQuestion?.indexOf("-teacher") !== -1
                                  ? 2
                                  : 1
                              }
                            ></Classification>
                          </div>
                          <div className="">
                            <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5 ml-8">
                              <PreviewPage
                                itemRef={selectedQuestion}
                                questionId={selectedQuestion}
                              />
                            </div>
                          </div>
                        </div>
                      )
                  )}

                {replacementQuestions && replacementQuestions.length === 0 && (
                  <div className="grid place-items-center h-60">
                    No Records Available
                  </div>
                )}
              </div>
            </div>
          )}
          {replacementQuestions && replacementQuestions?.length === 0 && (
            <div className="w-full relative overflow-auto px-4 py-1">
              <div className="text-sm text-gray-700 font-light bg-blue-50 p-4 mt-4 flex w-full rounded-md mb-6 rounded-t-lg sm:rounded-lg overflow-hidden">
                <div className="flex-shrink-0 current-fill text-blue-400">
                  <InformationIcon />
                </div>
                <span className="pl-2">
                  No questions available for the associated standard.
                </span>
              </div>
            </div>
          )}
          <footer className="flex items-center justify-end px-4 py-4 sm:flex-row bg-gray-50 dark:bg-gray-800 mt-4">
            <div className="space-x-3 flex justify-end">
              {selectedQuestion && (
                <button
                  onClick={handleQuestionSave}
                  className="bg-primary-violet border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-dark-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3"
                >
                  Select Question and Save
                </button>
              )}
            </div>
          </footer>
        </div>
      </div>
    </>
  );
};

export default QuestionOptionPopup;

import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getCannedTestReport } from "../../../../../api/student/cannedTestApi";
import { IBreadcrumb } from "../../../../../model/common/breadcrumb";
import StandardEffitiencyReport from "../../../../../model/student/shared/cannedTest/standardEffitiencyReport";
import RouteConstant from "../../../../../utils/constant/routeConstant";
import Breadcrumb from "../../middleSchool/breadcrumb";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Configuration } from "../../../../../environment/setup";
import { getAPIParameters, validParameters } from "../../../../../utils/standardEfficiencyHelper";

function StandardEfficiencyReport(props: any) {
  const [report, setReport] = useState<StandardEffitiencyReport>();
  const { t } = useTranslation();
  const history = useHistory();

  function bindResult() {
    if (validParameters(props)) {
      const {
        studentId,
        learnosityReferenceId,
        learnositySessionId
      } = getAPIParameters(props);

      getCannedTestReport(
        studentId,
        learnosityReferenceId,
        learnositySessionId
      ).then((response) => {
        setReport(response.data);
      });
    }
  }

  const breadcrumbItems = () => {
    const items: Array<IBreadcrumb> = [
      {
        name: t("Breadcrumb.Home"),
        url: RouteConstant.MiddleSchool.assignments,
        textColor: "",
      },
      {
        name: t("Breadcrumb.DiagnosticsSubjects"),
        textColor: "",
        url: RouteConstant.SUBJECTS_MS,
      },
      {
        name: t("Diagnostics.StandardsEfficiencyReport"),
        textColor: "",
        url: "",
      },
    ];

    return items;
  };

  const redirectToResult = () => {
    history.goBack();
  };

  useEffect(() => {
    bindResult();
  }, []);

  return (
    <>
      <div className="md:flex md:items-center px-4 py-3 lg:px-8 border-b">
        {
          !props.isTeacherView &&
          <Breadcrumb items={breadcrumbItems()} />
        }
        <div className="md:w-[55%] text-lg flex justify-between gap-2 h-auto sm:h-10 items-center">
          <h1 className="text-gray-50 font-medium flex justify-start">
            {t("Diagnostics.StandardsEfficiencyReport")} 
          </h1>
        </div>
      </div>
      <div className="px-4 py-4 lg:px-8">
        <div className="grid grid-cols-12 gap-6 mb-6">
          <div className="col-span-12 md:col-span-4 shadow-lg rounded-lg overflow-hidden bg-white">
            <div className="bg-[#61D140] relative h-[12rem] md:h-[12rem] lg:h-[12rem] xl:h-[14rem] 2xl:h-[16rem] xxxl:h-[17rem]">
              <div className="w-full absolute top-[8rem] md:top-[10rem] lg:top-[8rem] left-0 z-0">
                <svg viewBox="0 0 300 100">
                  <path
                    d="M-34.667,62.998c0,0,56-45.667,120.316-27.839C167.484,57.842,197,41.332,232.286,30.428
  c53.07-16.399,104.047,36.903,104.047,36.903l1.333,36.667l-372-2.954L-34.667,62.998z"
                    fill="#FFFFFF"
                  ></path>
                </svg>
              </div>
              <div className="flex flex-col items-center text-white text-lg">
                <img
                  alt="areas of strength"
                  title="Areas of strength"
                  className="w-20 mt-10"
                  src={`${Configuration.S3bucketImagePath}/images/student/shared/Areas-of-Strength.png`}
                />
                <div className="uppercase pt-3 text-center">
                  {t("Diagnostics.AreasOfStrength")}
                </div>
              </div>
            </div>
            <ul className="mt-0 md:mt-5 lg:-mt-0 relative z-10 space-y-2 font-light pb-7">
              {report &&
                report.strongTopics?.length > 0 &&
                report.strongTopics?.map((standard, index) => {
                  return (
                    <li
                      key={index}
                      className="text-center text-base text-gray-700"
                    >
                      {standard}
                    </li>
                  );
                })}
            </ul>
          </div>
          <div className="col-span-12 md:col-span-4 bg-white shadow-lg rounded-lg overflow-hidden">
            <div className="bg-[#F4D220] relative h-[12rem] md:h-[12rem] lg:h-[12rem] xl:h-[14rem] 2xl:h-[16rem] xxxl:h-[17rem]">
              <div className="w-full absolute top-[8rem] md:top-[10rem] lg:top-[8rem] left-0 z-0">
                <svg viewBox="0 0 300 100">
                  <path
                    d="M-34.667,62.998c0,0,56-45.667,120.316-27.839C167.484,57.842,197,41.332,232.286,30.428
  c53.07-16.399,104.047,36.903,104.047,36.903l1.333,36.667l-372-2.954L-34.667,62.998z"
                    fill="#FFFFFF"
                  ></path>
                </svg>
              </div>
              <div className="flex flex-col items-center text-white text-lg">
                <img
                  alt="some study required"
                  title="Some study required"
                  className="w-20 mt-10"
                  src={`${Configuration.S3bucketImagePath}/images/student/shared/study-required.png`}
                />
                <div className="uppercase pt-3 text-center">
                  {t("Diagnostics.SomeStudy")}
                </div>
              </div>
            </div>
            <ul className="mt-0 md:mt-5 lg:-mt-0 relative z-10 space-y-2 font-light pb-7">
              {report &&
                report.averageTopics?.length > 0 &&
                report.averageTopics?.map((standard, index) => {
                  return (
                    <li
                      key={index}
                      className="text-center text-base text-gray-700"
                    >
                      {standard}
                    </li>
                  );
                })}
            </ul>
          </div>
          <div className="col-span-12 md:col-span-4 bg-white shadow-lg rounded-lg overflow-hidden">
            <div className="bg-[#BB0070] relative h-[12rem] md:h-[12rem] lg:h-[12rem] xl:h-[14rem] 2xl:h-[16rem] xxxl:h-[17rem]">
              <div className="w-full absolute top-[8rem] md:top-[10rem] lg:top-[8rem] left-0 z-0">
                <svg viewBox="0 0 300 100">
                  <path
                    d="M-34.667,62.998c0,0,56-45.667,120.316-27.839C167.484,57.842,197,41.332,232.286,30.428
  c53.07-16.399,104.047,36.903,104.047,36.903l1.333,36.667l-372-2.954L-34.667,62.998z"
                    fill="#FFFFFF"
                  ></path>
                </svg>
              </div>
              <div className="flex flex-col items-center text-white text-lg">
                <img
                  alt="critical need for study"
                  title="Critical need for study"
                  className="w-20 mt-10"
                  src={`${Configuration.S3bucketImagePath}/images/student/shared/Critical-Need-Study.png`}
                />
                <div className="uppercase pt-3 text-center">
                  {t("Diagnostics.Critical")}
                </div>
              </div>
            </div>
            <ul className="mt-0 md:mt-5 lg:-mt-0 relative space-y-2 z-10 font-light pb-7">
              {report &&
                report.criticalTopics?.length > 0 &&
                report.criticalTopics?.map((standard, index) => {
                  return (
                    <li
                      key={index}
                      className="text-center text-base text-gray-700"
                    >
                      {standard}
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>
        <div className="flex justify-end">
          <a
            href="#!"
            onClick={redirectToResult}
            className="cursor-pointer order-first bg-white hover:bg-opacity-70 text-gray-700 hover:text-gray-700 px-3 py-2.5 rounded-lg mb-3 mt-2"
          >
            View Results Screen
          </a>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
};
export default connect<{}, {}, {}>(
  mapStateToProps,
  {}
)(StandardEfficiencyReport);

import { Fragment, useState } from "react";
import Footer from "./footer";
// import Header from "../../../../shared/header/header";
import HeaderLeft from "./headerLeft";
import HeaderTop from "./headerTop";
import { backToOriginalUser } from "../../../../../utils/impersonateHelper";
import * as userContextAction from "../../../../../redux/actions/userContextAction";
import { fetchProfile } from "../../../../../redux/actions/userActions";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import StudentSchoolCode from "../../../header/studentSchoolCode";
import routeConstant from "../../../../../utils/constant/routeConstant";

const routesWithVisibleMenu = [
  routeConstant.MiddleSchool.home,
  routeConstant.MiddleSchool.gradedWork,
  routeConstant.MiddleSchool.subjects,
  routeConstant.MiddleSchool.standards,
  routeConstant.MiddleSchool.Arcade,
  routeConstant.MiddleSchool.ProgressReport,
  routeConstant.LEADERBOARDMS,
];

const Layout = ({ children, ...props }) => {
  const history = useHistory();
  const location = useLocation();
  const [toggleHeaderLeft, setToggleHeaderLeft] = useState(false);
  function backToTeacher(): void {
    backToOriginalUser(
      props.userContext,
      props.setUserContext,
      props.setProfile,
      history
    );
  }

  const isMenuVisible = routesWithVisibleMenu.find(r => location.pathname.toLowerCase().startsWith(r.toLowerCase().split(':')[0]));

  return (
    <Fragment>
      {/* <Header /> */}

      <div className="font-Roboto flex flex-col justify-between min-h-screen w-full border-4 border-blues relative lg:static">
        {props.userContext?.impersonatedUser !== null && (
          <div className="w-full fixed pointer-events-none min-h-full z-50 flex justify-center  ">
            <span className="bg-blues h-8  px-3  flex items-center  text-white ">
              You are logged in as {props.profile?.lastName},{" "}
              {props.profile?.firstName}
              <span
                className="pl-2 text-white hover:underline cursor-pointer pointer-events-auto font-semibold"
                onClick={backToTeacher}
              >
                Return to your account
              </span>
            </span>
          </div>
        )}
        <HeaderTop
          toggleHeaderLeft={toggleHeaderLeft}
          setToggleHeaderLeft={setToggleHeaderLeft}
        />
        <div className="flex flex-1">
          {isMenuVisible && (<div
            className={`w-60 flex-none pt-5 flex justify-center h-[calc(100%-79px)] absolute lg:static left-0 z-20 bg-white lg:h-auto transform shadow-xl  transition-all duration-300 lg:transform-none lg:translate-x-0
          ${toggleHeaderLeft ? "translate-x-0" : "-translate-x-64"}
          `}
          >
            <HeaderLeft />
          </div>)}
          <div className="min-w-0 w-full bg-primary-violet">
            <div>
              <StudentSchoolCode theme="MIDDLESCHOOL"></StudentSchoolCode>
            </div>
            {children}
          </div>
        </div>
        <Footer />
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setUserContext: (payload: userContextAction.UserContextState) => {
      dispatch(userContextAction.setUserContext(payload));
    },
    setProfile: (
      userId: number,
      roleId: number,
      schoolId: number,
      districtId: number
    ) => {
      dispatch(fetchProfile(userId, roleId, schoolId, districtId));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);

import React, { useState, useEffect, Fragment } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { InfoBox } from "./interfaces";
import { CloseIcon, RightArrowIcon } from "../../../../../assets/icons";
import { useHistory } from "react-router-dom";
import AssignmentAssessmentIcon from "../../../../../assets/icons/student/middleschool/assignments/AssignmentAssessmentIcon";
import AssignmentGroupedAssignmentExpandedIcon from "../../../../../assets/icons/student/middleschool/assignments/AssignmentGroupedAssignmentExpandedIcon";
import AssignmentPerformanceTaskIcon from "../../../../../assets/icons/student/middleschool/assignments/AssignmentPerformanceTaskIcon";
import AssignmentQuestionsIcon from "../../../../../assets/icons/student/middleschool/assignments/AssignmentQuestionsIcon";
import AssignmentsGroupedAssignmentCollapsedIcon from "../../../../../assets/icons/student/middleschool/assignments/AssignmentsGroupedAssignmentCollapsedIcon";
import StethoscopeIconI from "../../../../../assets/icons/StethoscopeIcon";
import ConstructedResponseIconI from "../../../../../assets/icons/student/middleschool/assignments/ConstructedResponseIcon";
import PreBuildIcon from "../../../../../assets/icons/student/middleschool/assignments/PreBuildIcon";
import AssignmentVideoIcon from "../../../../../assets/icons/student/middleschool/assignments/AssignmentVideoIcon";
import routeConstant from "../../../../../utils/constant/routeConstant";
import {
  getGradedWork,
  getGroupedActivities,
} from "../../../../../api/student/gradedWork";
import Loader from "../../../../shared/loader";
import Select from "react-select";
import { connect } from "react-redux";
import Constant from "../../../../../utils/constant/constant";
import {
  DEFAULT_PAGE_NUMBER,
  DEFAULT_PAGE_SIZE,
} from "../../../../../utils/pagingConstant";
import Pagination from "../../../../shared/pagination/student/pagination";
import PageSize from "../../../../shared/pagination/student/pageSize";
import { getStudentSubject } from "../../../../../api/student/studentSubjectApi";
import DatePicker from "react-datepicker";
import StudentClasses from "../../../../shared/dropdowns/studentClasses";
import {
  getScoreLabel,
  getScoreRounded,
} from "../../../../../utils/scoreHelper";
import { SearchIcon } from "@heroicons/react/outline";

function GradedWork(props: any): JSX.Element {
  const [gradedWorkData, setGradedWorkData] = useState<any>();
  const [activities, setActivities] = useState<any>([]);
  const [infoText, setInfoText] = useState<InfoBox>();
  const [selectedClass, setSelectedClass] = useState<any>();
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [pageNumber, setPageNumber] = useState(DEFAULT_PAGE_NUMBER);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [resetPageNumber, setResetPageNumber] = useState<boolean>(false);
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const history = useHistory();
  const { t } = useTranslation();

  const [expandedEvent, setExpandedEvent] = useState<any>({
    index: [],
  });

  const [assignmentType, setAssignmentType] = useState<number>(0);
  const [subjects, setSubjects] = useState<any>([]);
  const [selectedSubject, setSelectedSubject] = useState<any>();

  const onPageChange = (pageNo) => {
    setPageNumber(pageNo);
  };

  const handleViewClick = (data) => {
    if (data.activityType === "STUDYPLAN") {
      history.push(
        routeConstant.KindergartenStudentRoutes.StudyPlanResult.replace(
          ":sessionId",
          data.sessionId
        )
      );
    } else if (data.activityType === "DIAGNOSTIC") {
      history.push(
        routeConstant.KindergartenStudentRoutes.DiagnosticsTestResult.replace(
          ":sessionId",
          data.sessionId
        )
      );
    } else if (data.activityType === "VIDEOLEARN") {
      history.push(
        routeConstant.KindergartenStudentRoutes.VideoResult.replace(
          ":sessionId",
          data.sessionId
        )
      );
    } else {
      history.push(
        routeConstant.KindergartenStudentRoutes.AssignmentResult.replace(
          ":assignmentId",
          data.assignmentId
        )
          .replace(":assignmentActivityId", data.activityId)
          .replace(":runningAssignment", "No")
          .replace(":sessionId", data.sessionId)
      );
    }
  };

  const getSubjects = () => {
    getStudentSubject(props.userContext?.userId ?? 0).then((res) => {
      const subjects = res.data?.map((values: any) => {
        return {
          label: values?.name,
          value: values?.subjectId,
        };
      });
      setSubjects(subjects);
    });
  };

  function handleStartDate(date: any) {
    if (endDate && date > endDate) {
      return false;
    }
    setStartDate(date);
  }

  function handleDueDate(date: any) {
    if (startDate && date < startDate) {
      return false;
    }
    setEndDate(date);
  }

  const fetchGradedWork = (viewType: number, isClear?: boolean) => {
    setAssignmentType(viewType);
    getGradedWork(
      props.userContext.userId,
      viewType ?? assignmentType,
      isClear ? 0 : selectedClass?.value ? selectedClass?.value : 0,
      isClear ? 0 : selectedSubject?.value ? selectedSubject?.value : 0,
      startDate ? moment(startDate).format("MM-DD-yyyy") : undefined,
      endDate ? moment(endDate).format("MM-DD-yyyy") : undefined,
      pageSize,
      pageNumber
    ).then((res) => {
      setGradedWorkData(res.data);
    });
  };
  useEffect(() => {
    getSubjects();
    fetchGradedWork(assignmentType);
    const innerUl = document.getElementById("ul1");
    if (innerUl) innerUl.classList.add("anim-0");
  }, [pageSize, pageNumber]);

  const renderInfo: any = (idx: any) => {
    return (
      <div
        aria-live="assertive"
        className="absolute inset-0 flex px-4 py-6 pointer-events-none items-start"
      >
        <div
          className={`md:top-2/4 top-2/4 ${
            idx === 2 && "md:left-1/4"
          } absolute w-full flex flex-col items-center space-y-4 sm:items-start z-10`}
        >
          <div className="max-w-sm w-full bg-yellow-200 shadow-lg rounded-md pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
            <div className="p-4">
              <div className="flex items-start">
                <div className="w-0 flex-1 pt-0.5">
                  <p className="text-sm font-semibold text-blue">
                    {infoText && infoText.header}
                  </p>
                  <p className="mt-1 text-sm text-gray-800">
                    {infoText && infoText.body}{" "}
                  </p>
                </div>
                <div className="ml-4 flex-shrink-0 flex">
                  <button className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-0 ">
                    <span className="sr-only">Close</span>
                    <Fragment>
                      <span
                        onClick={() =>
                          setInfoText({ header: "", body: "", index: 0 })
                        }
                      >
                        <CloseIcon />
                      </span>
                    </Fragment>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const onPageSizeChange = (pageSize) => {
    setPageNumber(DEFAULT_PAGE_NUMBER);
    setPageSize(pageSize);
    setResetPageNumber(true);
  };

  const teacherClassOnChange: any = (
    event: React.FormEvent<EventTarget>
  ): void => {
    setSelectedClass(event);
  };

  const onGroupIconChange: any = (index, AssignmentId) => {
    handleGroupedActivity(AssignmentId);
    if (!expandedEvent.index.includes(index)) {
      const indexArr = [...expandedEvent.index, index];
      setExpandedEvent({
        index: indexArr,
      });
    } else {
      const indexArr = expandedEvent.index.filter(function (item) {
        return item !== index;
      });
      setExpandedEvent({ index: indexArr });
    }
  };
  const handleViewFilter = (viewType: number) => {
    setPageNumber(DEFAULT_PAGE_NUMBER);
    setAssignmentType(viewType);
    fetchGradedWork(viewType);
  };

  const handleGroupedActivity = (assignmentId) => {
    setShowLoading(true);
    getGroupedActivities(assignmentId, props.userContext.userId).then((res) => {
      setShowLoading(false);
      setActivities(res.data);
    });
  };
  const handleSubjectChange = (e) => {
    setSelectedSubject(e);
  };

  const handleSearch = () => {
    fetchGradedWork(0);
  };

  const handleClear = () => {
    setSelectedClass({ label: "", value: 0 });
    setSelectedSubject({ label: "", value: 0 });
    setStartDate(undefined);
    setEndDate(undefined);
    fetchGradedWork(0, true);
  };

  const getActivityIcon = (
    assignmentType,
    activityType,
    assignmentStatus,
    endDate,
    dueDate
  ) => {
    activityType = assignmentType ? assignmentType : activityType;
    if (activityType === Constant.AssignmentActivityType.ASSESSMENT) {
      return (
        <span title="Assessment">
          <AssignmentAssessmentIcon
            is_saved={assignmentStatus?.toLowerCase() === "saved" ? 1 : 0}
            past_due={isPastDate(endDate, dueDate) ? 1 : 0}
          />
        </span>
      );
    } else if (
      activityType === Constant.AssignmentActivityType.PERFORMANCETASKS
    ) {
      return (
        <span title="Performance task">
          <AssignmentPerformanceTaskIcon
            is_saved={assignmentStatus?.toLowerCase() === "saved" ? 1 : 0}
            past_due={isPastDate(endDate, dueDate) ? 1 : 0}
          />
        </span>
      );
    } else if (activityType === Constant.AssignmentActivityType.PREBUILTTESTS) {
      return (
        <span title="performance task">
          <PreBuildIcon
            is_saved={assignmentStatus?.toLowerCase() === "saved" ? 1 : 0}
            past_due={isPastDate(endDate, dueDate) ? 1 : 0}
          />
        </span>
      );
    } else if (
      activityType === Constant.AssignmentActivityType.VIDEOLESSON ||
      activityType === "VIDEOLEARN"
    ) {
      return (
        <span title="Performance task">
          <AssignmentVideoIcon
            is_saved={assignmentStatus?.toLowerCase() === "saved" ? 1 : 0}
            past_due={isPastDate(endDate, dueDate) ? 1 : 0}
          />
        </span>
      );
    } else if (
      activityType === Constant.AssignmentActivityType.PRACTICEQUESTIONS ||
      activityType === "STUDYPLAN"
    ) {
      return (
        <span title="Performance task">
          <AssignmentQuestionsIcon
            is_saved={assignmentStatus?.toLowerCase() === "saved" ? 1 : 0}
            past_due={isPastDate(endDate, dueDate) ? 1 : 0}
          />
        </span>
      );
    } else if (
      activityType === Constant.AssignmentActivityType.CONSTRUCTEDRESPONSE
    ) {
      return (
        <span title="Performance task">
          <ConstructedResponseIconI
            is_saved={assignmentStatus?.toLowerCase() === "saved" ? 1 : 0}
            past_due={isPastDate(endDate, dueDate) ? 1 : 0}
          />
        </span>
      );
    } else if (activityType === "DIAGNOSTIC") {
      return (
        <span title="Performance Task">
          <StethoscopeIconI
            is_saved={assignmentStatus?.toLowerCase() === "saved" ? 1 : 0}
            past_due={isPastDate(endDate, dueDate) ? 1 : 0}
          />
        </span>
      );
    } else {
      return (
        <span title="Assessment">
          <AssignmentAssessmentIcon
            is_saved={assignmentStatus?.toLowerCase() === "saved" ? 1 : 0}
            past_due={isPastDate(endDate, dueDate) ? 1 : 0}
          />
        </span>
      );
    }
  };

  function isPastDate(completedDate: Date, dueDate: Date | undefined) {
    if (dueDate === undefined) {
      return false;
    }

    let isPastDate: boolean = false;
    isPastDate = moment(completedDate, ["YYYY-MM-DD"]).isAfter(
      moment(dueDate, ["YYYY-MM-DD"]),
      "day"
    );

    return isPastDate;
  }

  function isPendingScore(assignmentData) {
    return (
      (assignmentData.activityType === "CONSTRUCTED RESPONSE" ||
        assignmentData.activityType === "ASSESSMENT") &&
      assignmentData.unmarked
    );
  }

  return (
    <Fragment>
      {showLoading && <Loader></Loader>}

      <div className="min-w-0 w-full h-full bg-[#26234c]">
        <div className="px-4 py-4 lg:px-8">
          <div className="bg-[#403d6a] rounded-t-lg">
            <h3 className="py-4 px-7 bg-[#4e4b75] rounded-t-lg tracking-wider font-Didact relative text-lg  lg:text-xl xl:text-2xl text-[#f5d500] text-white hover:shadow-2xl h-full flex items-center justify-center">
              {t("StudyPlan.GradedWork")}
            </h3>
            <div className="px-4 py-4 lg:px-8">
              <div className="flex flex-col mb-6">
                <div>
                  <span className="bg-black w-10 h-10 rounded-full inline-block flex items-center justify-center mb-2 cursor-pointer">
                    {!showFilter && (
                      <SearchIcon
                        className="w-6 text-white opacity-90"
                        onClick={() => {
                          setShowFilter(true);
                        }}
                      />
                    )}
                    {showFilter && (
                      <CloseIcon
                        className="w-6 text-white opacity-90"
                        onClick={() => {
                          setShowFilter(false);
                        }}
                      />
                    )}
                  </span>
                </div>

                <div
                  className={`${
                    showFilter
                      ? "max-h-[200px] opacity-100 mt-1"
                      : "transform translate-y-20 max-h-0 opacity-0 -mt-5"
                  } w-full bg-white rounded-md shadow transform transition-all ease-in-out duration-300 relative z-10 `}
                >
                  <div
                    className={`${
                      showFilter ? "scale-y-100 opacity-100" : ""
                    } grid grid-cols-12 space-y-6 xs:space-y-0 gap-y-3 sm:gap-6 mb-5 px-6 py-4 transform transition-all duration-300 `}
                  >
                    <div className={`col-span-12 md:col-span-4 xl:col-span-3`}>
                      <div className="font-semibold text-gray-700 whitespace-nowrap">
                        {t("Assignments.Teacher/Class")}
                      </div>
                      <StudentClasses
                        className="mt-1"
                        userId={props.userContext.userId}
                        isMulti={false}
                        includeFormerClass={true}
                        selectedItems={selectedClass}
                        onChange={(e) => teacherClassOnChange(e)}
                      />
                    </div>

                    <div className={`col-span-12 md:col-span-4 xl:col-span-3`}>
                      <div className="font-semibold text-gray-700">
                        {" "}
                        {t("Assignments.Subject")}
                      </div>
                      <Select
                        className="mt-1"
                        value={selectedSubject}
                        options={subjects}
                        onChange={(e) => handleSubjectChange(e)}
                      />
                    </div>
                    <div className={`col-span-12 md:col-span-4 xl:col-span-2`}>
                      <div>
                        <div className="font-semibold">
                          {t("Assignments.StartDate")}
                        </div>
                        <DatePicker
                          className="mt-1 react-datepicker__input-container py-1.5 px-3 block w-full shadow-sm focus:ring-primary-violet focus:border-primary-violet border border-gray-300 rounded-md "
                          onChange={(date) => handleStartDate(date)}
                          selected={startDate}
                        />
                      </div>
                    </div>
                    <div className={`col-span-12 md:col-span-4 xl:col-span-2`}>
                      <div className="relative">
                        <div className="font-semibold">
                          {t("Assignments.EndDate")}
                        </div>
                        <DatePicker
                          className="mt-1 react-datepicker__input-container py-1.5 px-3 block w-full shadow-sm focus:ring-primary-violet focus:border-primary-violet border border-gray-300 rounded-md "
                          onChange={(date) => handleDueDate(date)}
                          selected={endDate}
                        />
                      </div>
                    </div>
                    <div
                      className={`col-span-12 md:col-span-4 xl:col-span-2 flex items-end`}
                    >
                      <button
                        onClick={() => handleSearch()}
                        className="bg-blues hover:opacity-90 inline-block whitespace-nowrap rounded-md text-sm w-36 text-center py-2 text-white mr-4 "
                      >
                        {t("GradedWork.Search")}
                      </button>
                      <button
                        onClick={() => handleClear()}
                        className=" inline-block whitespace-nowrap text-sm text-center pt-2 mb-2 underline-offset-2 border-b border-gray-700"
                      >
                        {t("GradedWork.Clear")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex justify-between items-start mb-3 flex-col 2xl:flex-row 2xl:items-end relative">
                <div className="flex flex-col">
                  <div className="flex flex-col sm:flex-row items-start sm:items-center gap-y-4 sm:rounded-md sm:overflow-hidden divide-x divide-gray-300 capitalize">
                    <div
                      className={
                        assignmentType === 0
                          ? "bg-primary-violet text-white inner-btn-shadow whitespace-nowrap py-2 px-4 cursor-pointer"
                          : "bg-white bg-opacity-90 hover:bg-opacity-100 text-opacity-90 text-gray-700 whitespace-nowrap py-2 px-4 hover:text-opacity-100 cursor-pointer"
                      }
                      onClick={() => {
                        handleViewFilter(0);
                        setAssignmentType(0);
                      }}
                    >
                      {t("GradedWork.ViewAllResults")}
                    </div>

                    <div
                      className={
                        assignmentType === 1
                          ? "bg-primary-violet text-white inner-btn-shadow whitespace-nowrap py-2 px-4 cursor-pointer"
                          : "bg-white bg-opacity-90 hover:bg-opacity-100 text-opacity-90 text-gray-700 whitespace-nowrap py-2 px-4 hover:text-opacity-100 cursor-pointer"
                      }
                      onClick={() => {
                        setAssignmentType(1);
                        handleViewFilter(1);
                      }}
                    >
                      {t("GradedWork.ViewAssignmentResults")}
                    </div>

                    {infoText &&
                      Object.keys(infoText).length > 0 &&
                      infoText.index === 1 &&
                      renderInfo(1)}

                    <div
                      className={
                        assignmentType === 2
                          ? "bg-primary-violet text-white inner-btn-shadow whitespace-nowrap py-2 px-4 cursor-pointer"
                          : "bg-white bg-opacity-90 hover:bg-opacity-100 text-opacity-90 text-gray-700 whitespace-nowrap py-2 px-4 hover:text-opacity-100 cursor-pointer"
                      }
                      onClick={() => {
                        setAssignmentType(2);
                        handleViewFilter(2);
                      }}
                    >
                      {t("GradedWork.ViewIndependentPractice")}
                    </div>

                    {infoText &&
                      Object.keys(infoText).length > 0 &&
                      infoText.index === 2 &&
                      renderInfo(2)}
                  </div>
                </div>
              </div>

              {/* Deskop table */}
              <div className="relative">
                <div>
                  <div className="relative overflow-auto rounded-md">
                    <table
                      id="gradedwork-table"
                      className="min-w-full divide-y divide-gray-200"
                    >
                      <thead className="bg-gray-200 uppercase" id="top">
                        <tr>
                          <th
                            scope="col"
                            className="top-0 bg-gray-200 px-2 2xl:px-6 py-3 text-left text-base font-semibold text-dark-teal uppercase tracking-wider"
                          >
                            {t("Assignments.Subject")}
                          </th>
                          <th
                            scope="col"
                            className="hidden top-0 bg-gray-200 px-2 2xl:px-6 py-3 text-left text-base font-semibold text-dark-teal uppercase tracking-wider"
                          >
                            {t("Assignments.Type")}
                          </th>
                          <th
                            scope="col"
                            className="top-0 bg-gray-100 px-2 2xl:px-6 py-3 text-left text-base font-semibold text-dark-teal uppercase tracking-wider"
                          >
                            {t("Assignments.Name")}
                          </th>
                          <th
                            scope="col"
                            className="top-0 bg-gray-200 px-2 2xl:px-6 py-3 text-center text-base font-semibold text-dark-teal tracking-wider"
                          >
                            {t("Assignments.Completed")}
                          </th>
                          {assignmentType !== 2 && (
                            <th
                              scope="col"
                              className="top-0 bg-gray-100 px-2 2xl:px-6 py-3 text-left text-base font-semibold text-dark-teal uppercase tracking-wider"
                            >
                              {t("Assignments.Teacher/Class")}
                            </th>
                          )}
                          <th
                            scope="col"
                            className="top-0 bg-gray-200 px-2 2xl:px-6 py-3 text-center text-base font-semibold text-dark-teal tracking-wider"
                          >
                            {t("AssignmentResult.Score")}
                          </th>
                          <th
                            scope="col"
                            className="top-0 bg-gray-100 px-2 2xl:px-6"
                          >
                            <span className="sr-only">
                              {t("Assignments.ActionButton")}
                            </span>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="bg-white">
                        {gradedWorkData &&
                          gradedWorkData?.data?.map((data, index) => {
                            return (
                              <>
                                <tr key={index} className={`border-b-2 `}>
                                  <td className="px-6 py-4 border-r-2 border-gray-100">
                                    <div className="text-sm font-medium text-site-black w-72 xxl:w-80 font-semibold">
                                      {data.subjectName}
                                    </div>
                                  </td>
                                  <td className="hidden w-10 px-6 py-4 bg-gray-100">
                                    <div
                                      className="flex items-center"
                                      onClick={() =>
                                        onGroupIconChange(
                                          index,
                                          data.assignmentId
                                        )
                                      }
                                    >
                                      {!data.isGroupedAssignment ? (
                                        <div>
                                          {getActivityIcon(
                                            data.assignmentType,
                                            data.activityType,
                                            data.assignmentStatus,
                                            data.endDate,
                                            data.dueDate
                                          )}
                                        </div>
                                      ) : expandedEvent &&
                                        expandedEvent.index.includes(index) ? (
                                        <AssignmentsGroupedAssignmentCollapsedIcon
                                          is_saved={
                                            data.assignmentStatus?.toLowerCase() ===
                                            "saved"
                                              ? 1
                                              : 0
                                          }
                                          past_due={
                                            isPastDate(
                                              data.endDate,
                                              data.dueDate
                                            )
                                              ? 1
                                              : 0
                                          }
                                        />
                                      ) : (
                                        <AssignmentGroupedAssignmentExpandedIcon
                                          is_saved={
                                            data.assignmentStatus?.toLowerCase() ===
                                            "saved"
                                              ? 1
                                              : 0
                                          }
                                          past_due={
                                            isPastDate(
                                              data.endDate,
                                              data.dueDate
                                            )
                                              ? 1
                                              : 0
                                          }
                                        />
                                      )}
                                    </div>
                                  </td>

                                  <td className="px-6 py-4 border-r-2 border-gray-100">
                                    <div className="text-sm font-medium text-site-black w-72 xxl:w-80 font-semibold">
                                      {data.name}
                                    </div>
                                  </td>

                                  <td className="px-6 py-4 border-r-2 border-gray-100">
                                    <div className="text-sm font-medium text-center text-site-black">
                                      {data.endDate
                                        ? moment(data.endDate).format(
                                            "MM/DD/YYYY"
                                          )
                                        : ""}
                                    </div>
                                    <div className="text-xs text-indigo-700 font-semibold capitalize">
                                      {data &&
                                        data.result &&
                                        moment()
                                          .startOf("day")
                                          .add(
                                            data.result.elapsed_time,
                                            "seconds"
                                          )
                                          .format("HH:mm:ss")}
                                    </div>
                                  </td>

                                  {assignmentType !== 2 && (
                                    <td className="px-6 py-4 text-sm text-gray-500 border-r-2 border-gray-100">
                                      {data.activityType !== "STUDYPLAN" &&
                                        data.activityType !== "DOMAINTEST" &&
                                        data.activityType !== "SUMMATIVETEST" &&
                                        data.activityType !== "CANNEDTEST" &&
                                        data.activityType !== "VIDEOLEARN" &&
                                        data.activityType !== "DIAGNOSTIC" && (
                                          <>
                                            {data && (
                                              <>
                                                <div className="text-sm text-black-333 capitalize font-semibold w-36 2xl:w-52 xxl:w-96">
                                                  {data.teacherName}{" "}
                                                </div>

                                                {data && (
                                                  <div className="text-sm text-gray-800 capitalize font-medium w-36 2xl:w-52 xxl:w-96">
                                                    {data.className}
                                                  </div>
                                                )}
                                              </>
                                            )}
                                          </>
                                        )}
                                    </td>
                                  )}
                                  <td className="px-6 py-4 text-left text-sm font-medium border-r-2 border-gray-100 relative">
                                    <div className="grid text-sm font-medium text-center text-site-black capitalize">
                                      {!data.isGroupedAssignment &&
                                      data.score !== null ? (
                                        <>
                                          <div>
                                            {isPendingScore(data) && (
                                                <>{t("Assignments.Pending")}</>)}
                                          </div>
                                          {!isPendingScore(data) && (
                                            <>
                                              {data.score !== null && (
                                                <div>
                                                  {getScoreRounded(data.score)}%
                                                </div>
                                              )}
                                              <div>
                                                ({getScoreLabel(data.correct)}/
                                                {data.questionCount})
                                              </div>

                                              {data.retryScore && (
                                                <div className="italic text-gray-400">
                                                  <span className="text-xs">
                                                    Retry{" "}
                                                  </span>
                                                  {getScoreRounded(
                                                    data.retryScore
                                                  )}
                                                  %
                                                  <div>
                                                    (
                                                    {getScoreLabel(
                                                      data.retryCorrect
                                                    )}
                                                    /{data.questionCount})
                                                  </div>
                                                </div>
                                              )}
                                            </>
                                          )}
                                        </>
                                      ) : (
                                        <span className="text-sm text-gray-500"></span>
                                      )}
                                      {data.minimumScore > 0 && data.score < data.minimumScore && (
                                          <div className="text-[#d90000]">
                                            Minimum Score Required = {data.minimumScore}%
                                          </div>
                                      )}
                                    </div>
                                  </td>

                                  <td className={`px-3`}>
                                    {!data.isGroupedAssignment && (
                                      <div className="flex justify-center">
                                        <button
                                          onClick={() => handleViewClick(data)}
                                          className={`${
                                            (
                                              data.activityType !==
                                                "STUDYPLAN" &&
                                              isPastDate(
                                                data.endDate,
                                                data.dueDate
                                              )
                                                ? 1
                                                : 0
                                            )
                                              ? "bg-site-pink"
                                              : "bg-blues"
                                          } hover:bg-secondary-teal transition-all hover:text-gray-100 inline-block whitespace-nowrap rounded-md text-sm w-40 text-center py-2 text-white `}
                                        >
                                          {t("Assignments.View")}
                                          <RightArrowIcon className="h-6 w-6 ml-1 inline-block" />
                                        </button>
                                      </div>
                                    )}
                                  </td>
                                </tr>

                                {expandedEvent &&
                                  expandedEvent.index.includes(index) &&
                                  activities &&
                                  activities.map((data: any, idx: number) => {
                                    return (
                                      <tr className={`border-b-2`} key={idx}>
                                        <td
                                          className={`w-10 px-6 py-4 bg-gray-200`}
                                        ></td>

                                        <td className="px-6 py-4 border-r-2 border-gray-100">
                                          <div className="flex items-center">
                                            <div className="flex-shrink-0 cursor-pointer h-12 w-12 bg-blue hover:opacity-90 rounded-full flex items-center justify-center">
                                              <Fragment>
                                                <div>
                                                  {data.assignmentType ===
                                                    "STUDYPLAN" ||
                                                    (Constant
                                                      .AssignmentActivityType
                                                      .ASSESSMENT && (
                                                      <AssignmentAssessmentIcon
                                                        is_saved={
                                                          data.assignmentStatus?.toLowerCase() ===
                                                          "saved"
                                                            ? 1
                                                            : 0
                                                        }
                                                        past_due={
                                                          isPastDate(
                                                            data.endDate,
                                                            data.dueDate
                                                          )
                                                            ? 1
                                                            : 0
                                                        }
                                                      />
                                                    ))}
                                                  {data.assignmentType ===
                                                    Constant
                                                      .AssignmentActivityType
                                                      .PERFORMANCETASKS && (
                                                    <AssignmentPerformanceTaskIcon
                                                      is_saved={
                                                        data.assignmentStatus?.toLowerCase() ===
                                                        "saved"
                                                          ? 1
                                                          : 0
                                                      }
                                                      past_due={
                                                        isPastDate(
                                                          data.endDate,
                                                          data.dueDate
                                                        )
                                                          ? 1
                                                          : 0
                                                      }
                                                    />
                                                  )}
                                                  {data.assignmentType ===
                                                    Constant
                                                      .AssignmentActivityType
                                                      .PREBUILTTESTS && (
                                                    <PreBuildIcon
                                                      is_saved={
                                                        data.assignmentStatus?.toLowerCase() ===
                                                        "saved"
                                                          ? 1
                                                          : 0
                                                      }
                                                      past_due={
                                                        isPastDate(
                                                          data.endDate,
                                                          data.dueDate
                                                        )
                                                          ? 1
                                                          : 0
                                                      }
                                                    />
                                                  )}
                                                  {data.assignmentType ===
                                                    Constant
                                                      .AssignmentActivityType
                                                      .VIDEOLESSON && (
                                                    <AssignmentVideoIcon
                                                      is_saved={
                                                        data.assignmentStatus?.toLowerCase() ===
                                                        "saved"
                                                          ? 1
                                                          : 0
                                                      }
                                                      past_due={
                                                        isPastDate(
                                                          data.endDate,
                                                          data.dueDate
                                                        )
                                                          ? 1
                                                          : 0
                                                      }
                                                    />
                                                  )}
                                                  {data.assignmentType ===
                                                    Constant
                                                      .AssignmentActivityType
                                                      .PRACTICEQUESTIONS && (
                                                    <AssignmentQuestionsIcon
                                                      is_saved={
                                                        data.assignmentStatus?.toLowerCase() ===
                                                        "saved"
                                                          ? 1
                                                          : 0
                                                      }
                                                      past_due={
                                                        isPastDate(
                                                          data.endDate,
                                                          data.dueDate
                                                        )
                                                          ? 1
                                                          : 0
                                                      }
                                                    />
                                                  )}
                                                  {data.assignmentType ===
                                                    Constant
                                                      .AssignmentActivityType
                                                      .CONSTRUCTEDRESPONSE && (
                                                    <ConstructedResponseIconI
                                                      is_saved={
                                                        data.assignmentStatus?.toLowerCase() ===
                                                        "saved"
                                                          ? 1
                                                          : 0
                                                      }
                                                      past_due={
                                                        isPastDate(
                                                          data.endDate,
                                                          data.dueDate
                                                        )
                                                          ? 1
                                                          : 0
                                                      }
                                                    />
                                                  )}
                                                </div>
                                              </Fragment>
                                            </div>

                                            <div>
                                              <div className="text-sm text-gray-800 capitalize font-semibold mx-2">
                                                {data.name}
                                              </div>
                                            </div>
                                          </div>
                                        </td>

                                        <td className="px-6 py-4 border-r-2 border-gray-100">
                                          <div className="text-sm font-medium text-center text-site-black">
                                            {data.endDate
                                              ? moment(data.endDate).format(
                                                  "MM/DD/YYYY"
                                                )
                                              : ""}
                                          </div>
                                          <div className="text-xs text-indigo-700 font-semibold capitalize">
                                            {data &&
                                              data.result &&
                                              moment()
                                                .startOf("day")
                                                .add(
                                                  data.elapsed_time,
                                                  "seconds"
                                                )
                                                .format("HH:mm:ss")}
                                          </div>
                                        </td>

                                        <td className="px-6 py-4 text-sm text-gray-500 border-r-2 border-gray-100">
                                          {data && (
                                            <>
                                              <div className="text-sm text-black-333 capitalize font-semibold w-36 2xl:w-52 xxl:w-96">
                                                {data.teacherName}{" "}
                                              </div>

                                              {data && (
                                                <div className="text-sm text-gray-800 capitalize font-medium w-36 2xl:w-52 xxl:w-96">
                                                  {data.className}
                                                </div>
                                              )}
                                            </>
                                          )}
                                        </td>
                                        <td className="px-6 py-4 text-center text-sm font-medium border-r-2 border-gray-100 relative">
                                          <div className="grid text-sm text-center font-medium text-site-black capitalize">
                                            {!data.isGroupedAssignment &&
                                            data.score !== null ? (
                                              <>
                                                <div>
                                                  {data.activityType ===
                                                    "CONSTRUCTED RESPONSE" &&
                                                  data.unmarked ? (
                                                    <>
                                                      {t("Assignments.Pending")}
                                                    </>
                                                  ) : (
                                                    <>
                                                      {getScoreRounded(
                                                        data.score
                                                      )}
                                                      %
                                                    </>
                                                  )}
                                                </div>
                                                {data.activityType !==
                                                  "CONSTRUCTED RESPONSE" && (
                                                  <>
                                                    <div>
                                                      (
                                                      {getScoreLabel(
                                                        data.correct
                                                      )}{" "}
                                                      /{data.questionCount})
                                                    </div>
                                                  </>
                                                )}
                                              </>
                                            ) : (
                                              <span className="text-sm text-gray-500"></span>
                                            )}
                                            {data.minimumScore > 0 && data.score < data.minimumScore && (
                                                <div>
                                                  Minimum Score Required = {data.minimumScore}%
                                                </div>
                                            )}
                                          </div>
                                        </td>

                                        <td className={`px-3`}>
                                          {!data.isGroupedAssignment && (
                                            <div className="flex justify-center">
                                              <button
                                                onClick={() =>
                                                  handleViewClick(data)
                                                }
                                                className={`${
                                                  (
                                                    data.activityType !==
                                                      "STUDYPLAN" &&
                                                    isPastDate(
                                                      data.endDate,
                                                      data.dueDate
                                                    )
                                                      ? 1
                                                      : 0
                                                  )
                                                    ? "bg-site-pink"
                                                    : "bg-blues"
                                                } hover:bg-secondary-teal transition-all hover:text-gray-100 inline-block whitespace-nowrap rounded-md text-sm w-40 text-center py-2 text-white `}
                                              >
                                                {t("Assignments.View")}
                                                <RightArrowIcon className="h-6 w-6 ml-1 inline-block" />
                                              </button>
                                            </div>
                                          )}
                                        </td>
                                      </tr>
                                    );
                                  })}
                              </>
                            );
                          })}
                        {gradedWorkData &&
                          gradedWorkData?.totalRecords === 0 && (
                            <tr className="bg-white">
                              <td
                                colSpan={7}
                                className="text-sm px-6 py-4 text-center"
                              >
                                {t("Assignments.NoRecordsFound")}
                              </td>
                            </tr>
                          )}
                      </tbody>
                    </table>
                  </div>
                  <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between text-white">
                    {gradedWorkData?.totalRecords !== undefined &&
                      gradedWorkData?.totalRecords !== 0 && (
                        <Pagination
                          totalRecords={gradedWorkData?.totalRecords}
                          pageSize={pageSize}
                          onPageChange={onPageChange}
                          reset={resetPageNumber}
                        />
                      )}
                    {gradedWorkData?.totalRecords !== 0 && (
                      <PageSize onPageSizeChange={onPageSizeChange} />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(GradedWork);

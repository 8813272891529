import { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { getVideoAssignment } from "../../../../../../api/student/studentVideoAssignmentApi";
import { IStudentVideoAssignment } from "../../../../../../model/interface/studentVideoAssignment";
import { UserContextState } from "../../../../../../redux/actions/userContextAction";
import routeConstant from "../../../../../../utils/constant/routeConstant";
import { IBreadcrumb } from "../../../../../../model/common/breadcrumb";
import Breadcrumb from "../../../middleSchool/breadcrumb";
import { useTranslation } from "react-i18next";
import constant from "../../../../../../utils/constant/constant";
import Profile from "../../../../../../model/users/profile";
import { paramDecode } from "../../../../../../utils/urlHelper";
import UpgradeButtonControl from "../../../../../shared/customButtonControl";
interface IVideoQuestion {
  match: any;
  userContext: UserContextState;
  profile?: Profile;
}

function VideoQuestionList(props: IVideoQuestion) {
  const standardId = parseInt(atob(props.match.params.standardId));
  const subjectId = parseInt(atob(props.match.params.subjectId));
  const standardName = paramDecode(props.match.params.standardName);
  const history = useHistory();
  const [standardVideos, setStandardVideos] = useState<
    Array<IStudentVideoAssignment>
  >([]);
  const { t } = useTranslation();

  useEffect(() => {
    getVideoAssignment(props.userContext.userId, subjectId, standardId).then(
      (d) => {
        setStandardVideos(d.data);
      }
    );
  }, []);
  function getStatus(a: IStudentVideoAssignment) {
    switch (a.assignmentStatusId) {
      case 0:
        return t("StudyPlan.NotStarted");
      case 2:
        return t("Assignments.Continue");
      case 3:
        return a.score >= constant.VIDEO_PASSING_SCORE
          ? t("Assignments.Complete") + " - " + a.score.toString() + "%"
          : t("Assignments.TryAgain");
      default:
        return "";
    }
  }

  function startVideoPractice(video: IStudentVideoAssignment) {
    history.push({
      pathname: routeConstant.StudentRoute.VideoQuestionPractice.replace(
        ":standardName",
        encodeURI(btoa(standardName))
      )
        .replace(":subjectId", encodeURI(btoa(subjectId.toString())))
        .replace(":standardId", encodeURI(btoa(standardId.toString())))
        .replace(":videoId", encodeURI(btoa(video.videoId.toString()))),
      state: {
        videoTitle:
          props.profile?.plLanguageId === constant.Languages.Spanish
            ? video.spanishVideoTitle ?? video.videoTitle
            : video.videoTitle,
      },
    });
  }

  const breadcrumbItems = () => {
    const items: Array<IBreadcrumb> = [
      {
        name: t("Breadcrumb.Home"),
        url: routeConstant.StudentRoute.Home,
        textColor: "text-gray-500",
      },
      {
        name: t("Breadcrumb.VideoLearn"),
        textColor: "text-gray-500",
        url: "",
      },
    ];

    return items;
  };
  return (
    <Fragment>
      <div
        data-id={standardId}
        className="bg-white h-full min-h-[90vh] mb-2 rounded-2xl p-5 sm:mx-6 lg:mx-8"
      >
        <div>
          <nav className="flex px-2 pb-3 pt-2 border-b" aria-label="Breadcrumb">
            <Breadcrumb items={breadcrumbItems()} />
          </nav>
        </div>

        <section>
          <h2 className="text-lg mt-2 px-2 pb-3 pt-2">{standardName}</h2>
        </section>
        <div className="flex flex-wrap gap-5">
          {standardVideos.map((d) => {
            return (
              <Fragment key={d.videoId}>
                <UpgradeButtonControl
                  controlType={"div"}
                  userContext={props?.userContext}
                  isUpgradeRequired={props?.profile?.isUpgradeRequired}
                  isRoleCheck={true}
                  className={
                    "flex flex-col shadow-lg overflow-hidden w-56 bg-white border-4 rounded-xl group " +
                    (props?.profile?.isUpgradeRequired
                      ? " cursor-not-allowed bg-opacity-20 "
                      : " cursor-pointer hover:bg-gray-100 hover:border-4 hover:border-primary-violet/50 transition-all")
                  }
                  onClick={() => {
                    startVideoPractice(d);
                  }}
                >
                  <span className="inline-block p-4">
                    <img
                      className="rounded-xl w-full h-36 mx-auto cursor-pointer"
                      srcSet={d.thumbnailUrl}
                      alt="Video Questions"
                    />
                  </span>
                  <div className="justify-between flex-grow p-4 bg-gray-100 transition-all">
                    <div className="flex w-full justify-between items-center">
                      <span className="block font-semibold text-sm text-gray-700 group-hover:text-gray-900 mb-1">
                        {getStatus(d)}
                      </span>
                    </div>
                    <div className="flex w-full">
                      <span className="block text-xs font-semibold text-secondary-teal uppercase">
                        {props.profile?.plLanguageId ===
                        constant.Languages.Spanish
                          ? d.spanishVideoTitle ?? d.videoTitle
                          : d.videoTitle}
                      </span>
                    </div>
                  </div>
                </UpgradeButtonControl>
              </Fragment>
            );
          })}
        </div>
      </div>
    </Fragment>
  );
}

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(VideoQuestionList);

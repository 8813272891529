import { Fragment, useEffect, useState } from "react";
import "../../../../../../css/style.css";
import { getStudentAssignmentResult } from "../../../../../../api/student/studentAssignmentApi";
import { connect } from "react-redux";
import { UserContextState } from "../../../../../../redux/actions/userContextAction";
import Profile from "../../../../../../model/users/profile";
import OverviewResult from "./overviewResult";
import LearnosityReport from "../../../../results/learnosityReport";
import { useTranslation } from "react-i18next";
import ProgressResult from "./progressResult";
import TotalQuestionsByDomains from "./totalQuestionsByDomains";
import TotalQuestionsByStandards from "./totalQuestionsByStandards";
import { GoogleApi } from "../../../../../../api/google/googleApi";
import { CanvasApi } from "../../../../../../api/canvas/canvasApi";
import constant from "../../../../../../utils/constant/constant";
import { getStudentsTeacherFeedbackForActivity } from "../../../../../../api/student/studentActivityApi";
import { useHistory } from "react-router-dom";
import {
  canStudentViewItemDetails,
  canTeacherViewItemDetails,
  canViewAssessmentExplanation,
  redirectToActivity,
  redirectStandardsEfficiencyReport,
  getSessionFromActivities,
} from "../../../../../../utils/assignmentHelper";
import routeConstant from "../../../../../../utils/constant/routeConstant";
import Toggle from "../../../../../shared/toggle";
import {
  validateIsGoogleUser,
  validateIsCanvasUser,
} from "../../../../../../utils/helper";
import { IStandardEfficiencyParams } from "../../../../../../utils/standardEfficiencyHelper";
interface IAssignmentResult {
  userContext: UserContextState;
  profile: Profile;
  match: any;
  studentId?: number;
  studentGradeId?: number;
  isTeacherView?: boolean;
  assignmentId?: number;
  assignmentActivityId?: number;
  sessionId?: string;
  schoolYearId: number;
}
const AssignmentResultEL = (props: IAssignmentResult) => {
  const history = useHistory();
  type DisplayResultType = "NoDetail" | "QuestionsOnly" | "QuestionsAndAnswer";
  const { t } = useTranslation();
  const [assignmentResult, setAssignmentResult] = useState<any>(null);
  const [teacherFeedback, setTeacherFeedback] = useState<string>();
  const [resultscore, setresultscore] = useState<Number>();
  const [toggleRetry, setToggleRetry] = useState(false);
  const assignmentId = props.isTeacherView
    ? props.assignmentId ?? 0
    : parseInt(props.match.params.assignmentId);
  const assignmentActivityId = props.isTeacherView
    ? props.assignmentActivityId ?? 0
    : parseInt(props.match.params.assignmentActivityId);
  const sessionId = props.isTeacherView
    ? props.sessionId
    : props.match.params.sessionId ?? "";
  const userId =
    props.isTeacherView === true
      ? props.studentId ?? 0
      : props.userContext.userId;
  const [learnosityActivityUserId, setLearnosityActivityUserId] =
    useState<number>(userId);

  useEffect(() => {
    getResult(
      props.isTeacherView === true
        ? props.studentId ?? 0
        : props.userContext.userId,
      assignmentId,
      assignmentActivityId
    );
  }, []);

  function getResult(
    userId: number,
    assignmentId: number,
    assignmentActivityId: number
  ) {
    getStudentAssignmentResult(
      props.schoolYearId,
      userId,
      assignmentId,
      assignmentActivityId,
      true,
      sessionId
    ).then((res) => {
      const score = Math.round(
        (Number(res.data.totalScore) / Number(res.data.totalAttempted)) * 100
      );
      setresultscore(score);
      if (
        validateIsGoogleUser(props?.profile!) &&
        props?.userContext.impersonatedUser === null
      ) {
        GoogleApi.submitGoogleAssignmentScore(
          assignmentId,
          assignmentActivityId,
          props.userContext.googleId,
          props.userContext.userId,
          props.profile?.googleUserId,
          score,
          props.profile?.googleUser?.refreshToken!
        );
      }
      if (
        validateIsCanvasUser(props?.profile!) &&
        props?.userContext.impersonatedUser === null
      ) {
        CanvasApi.submitCanvasAssignmentScore(
          assignmentId,
          assignmentActivityId,
          props.userContext.userId,
          score,
          props.profile.canvasAccountSettings
        );
      }
      if (
        res.data?.studentAssignmentActivityId &&
        res.data?.assignmentActivityTypeId ===
          constant.AssignmentActivityTypeEnum.CONSTRUCTEDRESPONSE
      ) {
        GetTeacherFeedback(res.data.studentAssignmentActivityId);
      }

      setLearnosityActivityUserId(res.data.learnosityActivityUserId ?? userId);
      setAssignmentResult(
        res.data && res.data.activities?.length > 0 ? res.data : null
      );
    });
  }
  function showAnswer(value: number) {
    let result: DisplayResultType = "NoDetail";
    switch (value) {
      case 1:
        result = "NoDetail";
        break;
      case 2:
        result = "QuestionsOnly";
        break;
      case 3:
        result = "QuestionsAndAnswer";
        break;
    }
    return result === "QuestionsAndAnswer";
  }
  function GetTeacherFeedback(studentAssignmentActivityId: number) {
    getStudentsTeacherFeedbackForActivity(studentAssignmentActivityId).then(
      (r) => {
        if (r.data) {
          setTeacherFeedback(r.data);
        } else {
          setTeacherFeedback("");
        }
      }
    );
  }

  function redirectToNextActivity() {
    redirectToActivity(
      history,
      assignmentResult.nextActivityDetail,
      routeConstant.KindergartenStudentRoutes.StudentAssignmentActivity,
      props.profile?.plLanguageId
    );
  }

  function redirectToCurrentActivity() {
    redirectToActivity(
      history,
      assignmentResult.currentActivityDetail,
      routeConstant.KindergartenStudentRoutes.StudentAssignmentActivity,
      props.profile?.plLanguageId
    );
  }

  return (
    <Fragment>
      {assignmentResult && (
        <div className="relative h-full bg-[#26234c]">
          <div className="p-5 flex flex-col md:flex-row">
            <OverviewResult
              data={assignmentResult}
              redirectToReport={() => {
                redirectStandardsEfficiencyReport({
                  isTeacherView: props.isTeacherView ?? false,
                  studentGradeId: props.studentGradeId ?? 0,
                  studentId: props.studentId ?? 0,
                  studentUrl:
                    routeConstant.KindergartenStudentRoutes
                      .StandardEfficiencyReport,
                  sessionId: getSessionFromActivities(
                    assignmentResult.activities
                  ),
                  history: history,
                } as IStandardEfficiencyParams);
              }}
            />
            <div className="flex-grow h-auto md:w-1/2">
              {/* flex-grow h-auto md:w-1/2 */}
              <div className="flex w-full mt-6 md:mt-0 pb-6 justify-between">
                <div>
                  <h1 className="text-2xl font-bold text-white capitalize">
                    {assignmentResult.assignmentName}
                  </h1>
                </div>
                <div className="flex gap-x-3">
                  {!props.isTeacherView && (
                    <button
                      className={`cursor-pointer bg-orange-light border-[3px] hover:bg-tertiary-color border-orange-dark font-bold rounded-md shadow-sm py-2 px-4 flex justify-center text-base text-gray-700 hover:text-gray-700 hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
                      onClick={() =>
                        history.push(
                          routeConstant.KindergartenStudentRoutes.Assignment,
                          []
                        )
                      }
                    >
                      Return to Assignments
                    </button>
                  )}
                  {assignmentResult.nextActivityDetail !== null &&
                    assignmentResult.nextActivityDetail.totalAttempt <
                      assignmentResult.nextActivityDetail.multipleAttempt &&
                    (resultscore || resultscore === 0) &&
                    resultscore >= assignmentResult.minimumScore &&
                    !props.isTeacherView && (
                      <button
                        className={`cursor-pointer bg-orange-light border-[3px] hover:bg-tertiary-color border-orange-dark font-bold rounded-md shadow-sm py-2 px-4 flex justify-center text-base text-gray-700 hover:text-gray-700 hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
                        onClick={() => redirectToNextActivity()}
                      >
                        {" "}
                        Next Activity
                      </button>
                    )}
                  {assignmentResult.currentActivityDetail !== null &&
                    assignmentResult.currentActivityDetail.totalAttempt <
                      assignmentResult.currentActivityDetail.multipleAttempt &&
                    assignmentResult.attemptAgain &&
                    !props.isTeacherView && (
                      <button
                        className={`cursor-pointer bg-orange-light border-[3px] hover:bg-tertiary-color border-orange-dark font-bold rounded-md shadow-sm py-2 px-4 flex justify-center text-base text-gray-700 hover:text-gray-700 hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
                        onClick={() => redirectToCurrentActivity()}
                      >
                        {t("Assignments.AttemptAgain")}
                      </button>
                    )}
                </div>
              </div>
              <div className="flex-none xxl:flex">
                {assignmentResult.unmarked === false && (
                  <div className="flex-none w-full xl:w-4/5 xxl:w-2/5 bg-gray-100 rounded-md xl:mr-12 mb-12 xxl:mb-0 overflow-hidden">                  
                  <ProgressResult data={assignmentResult} />
                  </div>              
                )}                
                <div className="flex-grow w-full xl:w-4/5 xxl:w-3/5">
                  {teacherFeedback && (
                    <div className="mb-8">
                      <h2 className="text-md font-semibold text-white mb-2">
                        Teacher Feedback
                      </h2>
                      <div className="rounded-md bg-blue-100 p-4">
                        <div className="flex">
                          <div className="flex-shrink-0">
                            <svg
                              className="h-7 w-7 text-blue-400"
                              x-description="Heroicon name: solid/information-circle"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                              aria-hidden="true"
                            >
                              <path
                                fillRule="evenodd"
                                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                                clip-rule="evenodd"
                              ></path>
                            </svg>
                          </div>
                          <div className="ml-3 flex-1 md:flex md:justify-between">
                            <p className="text-lg text-blue-700">
                              {teacherFeedback}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <TotalQuestionsByDomains data={assignmentResult} />
                  <TotalQuestionsByStandards data={assignmentResult} />
                </div>
              </div>

              <>
                {(((props.isTeacherView ?? false) === true &&
                  canTeacherViewItemDetails(
                    props.userContext.roleId,
                    assignmentResult
                  )) ||
                  ((props.isTeacherView ?? false) === false &&
                    canStudentViewItemDetails(assignmentResult))) && (
                  <>
                    <div className="mt-6 rounded-md">
                      <div>
                        <div className="p-5 bg-gray-50 border rounded-md text-sm font-semibold">
                          <div className="flex w-full justify-between gap-x-4">
                            <div>
                              <h2>
                                {" "}
                                {t("AssignmentResult.AnswerStatusByQuestionNo")}
                              </h2>
                              <ul className="flex flex-wrap gap-2 w-full mt-3">
                                {assignmentResult.questions
                                  .filter(
                                    (q) =>
                                      (!toggleRetry ||
                                        (toggleRetry &&
                                          q.retryScore !== null)) &&
                                      q.order > 0
                                  )
                                  .map((question, index) => {
                                    return (
                                      <>
                                        {question.unmarked ? 
                                        (
                                          <li className="w-6 h-6 bg-[#ababab] border border-[#ababab] text-white text-xs font-semibold flex items-center justify-center rounded-sm shadow-sm justify-center hover:bg-transparent hover:text-[#ababab]">
                                            {index + 1}
                                        </li>
                                        ) : 
                                        (
                                          ((!toggleRetry && question.score === question.pointsPossible) ||
                                            (toggleRetry &&
                                              question.retryScore ===
                                                question.pointsPossible)) ? (
                                            <li className="w-6 h-6 bg-[#B2D136] border border-[#B2D136] text-white text-xs font-semibold flex items-center rounded-sm shadow-sm justify-center hover:bg-transparent hover:text-[#B2D136]">
                                              {index + 1}
                                            </li>
                                          ) : 
                                          ((!toggleRetry &&
                                            question.score <
                                              question.pointsPossible) ||
                                            (toggleRetry &&
                                              question.retryScore <
                                                question.pointsPossible)) && (
                                            <li className="w-6 h-6 bg-[#e53f71] border border-[#e53f71] text-white text-xs font-semibold flex items-center rounded-sm shadow-sm justify-center hover:bg-transparent hover:text-[#e53f71]">
                                              {index + 1}
                                            </li>
                                          )
                                        )}
                                      </>
                                    );
                                  })}
                              </ul>
                            </div>
                            {assignmentResult.retryScore !== null && (
                              <div className="flex gap-x-2">
                                <span> Original </span>
                                <Toggle
                                  checked={toggleRetry}
                                  onChange={(e) => {
                                    setToggleRetry(!toggleRetry);
                                  }}
                                />
                                <span> Retry </span>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="mt-5 p-5 bg-white rounded-md">
                        {assignmentResult &&
                          assignmentResult.activities?.length > 0 && (
                            <>
                              {!toggleRetry && (
                                <LearnosityReport
                                  sessionId={
                                    assignmentResult.activities[0].sessionId
                                  }
                                  user_id={learnosityActivityUserId.toString()}
                                  show_answers={
                                    assignmentResult.assessmentResultDisplayTypeId >
                                    0
                                      ? showAnswer(
                                          props.isTeacherView === true
                                            ? 3
                                            : assignmentResult.assessmentResultDisplayTypeId
                                        )
                                      : true
                                  }
                                  wrapperClass="K1-Wrapper"
                                  standardIds={assignmentResult.standards
                                    ?.map((standard) => {
                                      return standard.standardId;
                                    })
                                    ?.join(",")}
                                  hideExplanation={
                                    assignmentResult.assignmentActivityTypeId ===
                                    constant.AssignmentActivityTypeEnum
                                      .ASSESSMENT
                                      ? !canViewAssessmentExplanation(
                                          assignmentResult.assignmentActivityTypeId,
                                          assignmentResult.assessmentResultDisplayTypeId,
                                          props.isTeacherView
                                        )
                                      : false
                                  }
                                  showIndividualQuestionFeedback={true}
                                ></LearnosityReport>
                              )}

                              {toggleRetry && (
                                <LearnosityReport
                                  sessionId={
                                    assignmentResult.retryLernositySessionId
                                  }
                                  user_id={learnosityActivityUserId.toString()}
                                  show_answers={
                                    assignmentResult.assessmentResultDisplayTypeId >
                                    0
                                      ? showAnswer(
                                          props.isTeacherView === true
                                            ? 3
                                            : assignmentResult.assessmentResultDisplayTypeId
                                        )
                                      : true
                                  }
                                  wrapperClass="K1-Wrapper"
                                  standardIds={assignmentResult.standards
                                    ?.map((standard) => {
                                      return standard.standardId;
                                    })
                                    ?.join(",")}
                                  hideExplanation={
                                    assignmentResult.assignmentActivityTypeId ===
                                    constant.AssignmentActivityTypeEnum
                                      .ASSESSMENT
                                      ? !canViewAssessmentExplanation(
                                          assignmentResult.assignmentActivityTypeId,
                                          assignmentResult.assessmentResultDisplayTypeId,
                                          props.isTeacherView
                                        )
                                      : false
                                  }
                                  showIndividualQuestionFeedback={true}
                                ></LearnosityReport>
                              )}
                            </>
                          )}
                      </div>
                    </div>
                  </>
                )}
              </>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
};
export default connect<{}, {}, {}>(mapStateToProps, {})(AssignmentResultEL);

import routeConstant from "../../../../utils/constant/routeConstant";

export function assignmentResultRedirect(
  assignmentId: number,
  assignmentActivityId: number,
  studentUserId: number,
  gradeId: number,
  sessionId: string,
  source: string,
  schoolYearId: number | undefined
) {
  const pathname =
    routeConstant.TeacherRoutes.StudentAssignmentActivityResult.replace(
      ":source",
      source
    )
      .replace(":assignmentId", assignmentId.toString())
      .replace(":assignmentActivityId", assignmentActivityId.toString())
      .replace(":userId", studentUserId.toString())
      .replace(":gradeId", gradeId.toString() ?? 0)
      .replace(":sessionId?", sessionId);
  window.open(pathname + (schoolYearId && schoolYearId > 0 ? "?schoolYearId=" + schoolYearId : ""), "_blank");
}

export function studyPlanPracticeResultRedirect(
  activityType: string,
  studentUserId: number,
  gradeId: number,
  sessionId: string,
  source: string,
  schoolYearId?: number) {
  let pathname =
    routeConstant.TeacherRoutes.StudentPracticeActivityResult.replace(
      ":source",
      source
    )
      .replace(":sessionId", sessionId)
      .replace(":userId", studentUserId.toString())
      .replace(":activityType", activityType)
      .replace(":gradeId", gradeId.toString());

  window.open(pathname + (schoolYearId && schoolYearId > 0 ? "?schoolYearId=" + schoolYearId : ""), "_blank");
}

export function liftoffDiagnosticResultRedirect(
  sessionId: string,
  source: string,
  schoolYearId?: number) {
  var pathname =
    routeConstant.TeacherRoutes.StudentLiftOffDiagnosticResult.replace(
      ":source",
      source
    )
      .replace(":liftOffHeaderId", encodeURI(btoa("0")))
      .replace(":sessionId", sessionId);
  window.open(pathname + (schoolYearId && schoolYearId > 0 ? "?schoolYearId=" + schoolYearId : ""), "_blank");
}

export function myGalaxyStandardResultRedirect(
  sessionId: string,
  source: string,
  schoolYearId?: number
) {
  var pathname =
    routeConstant.TeacherRoutes.StudentMyGalaxyStandardResult.replace(
      ":studenttype",
      "middleschool"
    )
      .replace(":source", source)
      .replace(":standardId", "0")
      .replace(":isStandardGrouped", "false")
      .replace(":sessionId", sessionId);
  window.open(pathname + (schoolYearId && schoolYearId > 0 ? "?schoolYearId=" + schoolYearId : ""), "_self");
}
export function myGalaxyStandardcResultRedirectByStudent(
  sessionId: string,
  studentType: string,
  source: string
) {
  var pathname =
    routeConstant.StudentRoute.StudentMyGalaxyStandardResultByStudent.replace(
      ":studentType",
      studentType
    )
      .replace(":source", source)
      .replace(":standardId", "0")
      .replace(":isStandardGrouped", "false")
      .replace(":sessionId", sessionId);

  window.open(pathname, "_self");
}
export function liftoffDiagnosticResultRedirectByStudent(
  sessionId: string,
  studentType: string,
  source: string
) {
  var pathname =
    routeConstant.StudentRoute.StudentLiftOffDiagnosticResultByStudent.replace(
      ":source",
      source
    )
      .replace(":studentType", studentType)
      .replace(":liftOffHeaderId", encodeURI(btoa("0")))
      .replace(":sessionId", sessionId);
  window.open(pathname, "_self");
}

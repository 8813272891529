import { PageAccessMode } from "../../../../../model/common/pageAccessMode";
import { ISelectElement } from "../../../../../model/interface/selectElement";
import ClassModel from "../../../../../model/teacher/classManagement/classModel";
import ColorPicker from "../../../../shared/colorPicker";
import Select from "react-select";
import classNames from "classnames";
import { toast } from "react-toastify";

interface ClassBuilderClassDetailsProps {
    classDetail: ClassModel;
    mode: PageAccessMode;
    selectedColor: string;
    setSelectedColor: (color: string) => void;
    schoolYears: Array<ISelectElement> | undefined;
    selectedSchoolYearId: number;
    handleSchoolYearChange: (e: any) => void;
    register: any;
    errors: any;
    getValues: any;
    handleSubmit: any;
    handleSaveAndContinue: () => void;
    handleSkipClassSetup: () => void;
    isDetailsValid: boolean;
    invalidDates: boolean;
    classId: string | undefined;
    onSubmit: (data: ClassModel) => void;
}

  const ClassBuilderClassDetails = ({
    classDetail,
    mode,
    selectedColor,
    setSelectedColor,
    schoolYears,
    selectedSchoolYearId,
    handleSchoolYearChange,
    register,
    errors,
    getValues,
    handleSubmit,
    handleSaveAndContinue,
    handleSkipClassSetup,
    isDetailsValid,
    invalidDates,
    classId,
    onSubmit,
  }: ClassBuilderClassDetailsProps) => {

    const onSaveAndContinue = () => {
        if(isDetailsValid) {
          handleSaveAndContinue();
          return;
        }

        if(invalidDates) {
          toast.warning("End Time cannot be before Start Time.");
        }
    }

    return (
        <div className="flex">
            <div
              className="w-2.5 inline-block m-2 rounded-md shadow-md border"
              style={{
                backgroundColor:
                  selectedColor === "#fff" ? "gray" : selectedColor,
              }}
            >
              {" "}
            </div>
            <div className="px-8 py-5 relative flex-grow">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="grid grid-cols-10">
                <div className="col-span-10 lg:col-span-8 border-gray-200 pr-3 mt-4 lg:mt-0">
                  <div>
                    <div className="grid grid-cols-1 sm:grid-cols-6 xl:grid-cols-12 gap-6 ">                      
                      <div className="col-span-4 sm:col-span-2 xl:col-span-3">
                        <label className="block text-sm text-gray-500">
                          Class Name <span className="text-red-400">*</span>
                        </label>
                        <input
                          type="text"
                          id="name"
                          defaultValue={classDetail.name}
                          maxLength={40}
                          disabled={mode === PageAccessMode.Readonly}
                          className="mt-1 py-1.5 px-3 block w-full shadow-sm focus:ring-primary-violet focus:border-primary-violet border border-gray-300 rounded-md"
                          {...register("name", { required: true })}
                        />
                        <small className="text-red-400">
                          {errors.name && <span>Class name is required</span>}
                        </small>
                      </div>
                      <div className="col-span-4 sm:col-span-2 xl:col-span-3  whitespace-nowrap lg:whitespace-normal">
                        <label className="block text-sm text-gray-500">
                          Class Display Name
                        </label>
                        <input
                          type="text"
                          id="display-name"
                          disabled={mode === PageAccessMode.Readonly}
                          defaultValue={classDetail.displayName}
                          className="mt-1 py-1.5 px-3 block w-full shadow-sm focus:ring-primary-violet focus:border-primary-violet border border-gray-300 rounded-md"
                          {...register("displayName", { required: true })}
                        />
                        <small className="text-red-400">
                          {errors.displayName && (
                            <span>Class display name is required</span>
                          )}
                        </small>
                      </div>
                      <div className="col-span-4 sm:col-span-1 xl:col-span-3 ">
                        <label className="mb-1 block text-sm text-gray-500 whitespace-nowrap">
                          Class Color
                        </label>

                        <ColorPicker
                          hideInputField={true}
                          defaultValue={classDetail.colorCode}
                          disabled={mode === PageAccessMode.Readonly}
                          title="Class color code can be used to easily distinguish between classes on your assignments calendar on your homepage."
                          setSelectedColor={(c) => {
                            setSelectedColor(c);
                          }}
                          hideRefreshIcon
                        />
                      </div>
                    </div>
                  </div>
                <div>
                  <div className="grid grid-cols-1 sm:grid-cols-12 gap-6 mt-5">
                    <div className="col-span-4 xl:col-span-3">
                      <label className="block text-sm text-gray-500">
                        Class Start Time <span className="text-red-400">*</span>
                      </label>
                      <input
                        id="startTime"
                        type="time"
                        className="mt-1 py-1.5 px-3 block w-full shadow-sm focus:ring-primary-violet focus:border-primary-violet border border-gray-300 rounded-md cursor-pointer"
                        defaultValue={classDetail.startTime}
                        disabled={mode === PageAccessMode.Readonly}
                        {...register("startTime", {
                          validate: {
                            requiredField: (value) => {
                              return value !== "" && value !== undefined;
                            },
                            timeCompare: (value) => {
                              if (
                                getValues("endTime") !== "" &&
                                getValues("endTime") !== undefined &&
                                value !== "" &&
                                value !== undefined
                              ) {
                                return getValues("endTime") > value;
                              } else return true;
                            },
                          },
                        })}
                      />
                      <small className="text-red-400">
                        {errors.startTime &&
                          errors.startTime.type === "requiredField" && (
                            <span>Start time is required.</span>
                          )}
                      </small>
                      <small className="text-red-400">
                        {errors.startTime &&
                          errors.startTime.type === "timeCompare" && (
                            <span>
                              Start time must be less than end time.
                            </span>
                          )}
                      </small>                                                
                    </div>
                    <div className="col-span-4 xl:col-span-3">
                      <label className="block text-sm text-gray-500">
                        Class End Time <span className="text-red-400">*</span>
                      </label>
                      <input
                        type="time"
                        defaultValue={classDetail.endTime}
                        disabled={mode === PageAccessMode.Readonly}
                        className="mt-1 py-1.5 px-3 block w-full shadow-sm focus:ring-primary-violet focus:border-primary-violet border border-gray-300 rounded-md cursor-pointer"
                        {...register("endTime", {
                          validate: {
                            requiredField: (value) => {
                              return value !== "" && value !== undefined;
                            },
                          },
                        })}
                      />
                      <small className="text-red-400">
                        {errors.endTime &&
                          errors.endTime.type === "requiredField" && (
                            <span>End time is required.</span>
                          )}
                      </small>
                    </div>                      
                    <div className="col-span-4 xl:col-span-3">
                      <label className="block text-sm text-gray-500">
                        School Year
                      </label>
                      {classId !== undefined && classId === "new" && (
                        <Select
                          key="schoolyear"
                          id="schoolyear"
                          name="schoolyear"
                          value={schoolYears?.filter(
                            (x) =>
                              x.value === selectedSchoolYearId?.toString()
                          )}
                          onChange={handleSchoolYearChange}
                          options={schoolYears}
                        />
                      )}
                      {classId !== undefined && classId !== "new" && (
                        <label className="mt-1 cursor-not-allowed text-gray-400 py-1.5 px-3 block w-full shadow-sm focus:ring-primary-violet focus:border-primary-violet border border-gray-300 rounded-md ">
                          {
                            schoolYears?.filter(
                              (x) =>
                                x.value === selectedSchoolYearId?.toString()
                            )[0]?.label
                          }
                        </label>
                      )}
                    </div>
                    <div className="col-span-4 xl:col-span-3">
                      <label className="block text-sm text-gray-500">
                        Class Status
                      </label>
                      <span
                        className={classNames(
                          "flex-shrink-0 inline-block px-2 py-0.5 mt-1.5 text-xs font-medium  rounded-full",
                          classDetail.isArchived
                            ? "bg-yellow-100 text-yellow-800"
                            : "",
                          classDetail.isHidden &&
                            classDetail.isArchived === false
                            ? "bg-gray-200 text-gray-800"
                            : "",
                          classDetail.isActive &&
                            classDetail.isArchived === false &&
                            classDetail.isHidden === false
                            ? "bg-green-100 text-green-800"
                            : ""
                        )}
                      >
                        {classDetail.isArchived && <>Archived</>}
                        {classDetail.isHidden &&
                          classDetail.isArchived === false && <>Hidden</>}
                        {classDetail.isActive &&
                          classDetail.isArchived === false &&
                          classDetail.isHidden === false && <>Active</>}
                      </span>
                    </div>
                  </div>
                  <div className="col-span-4 sm:col-span-12 text-red-400 text-sm">
                    Be sure to set accurate class times as these times impact specific settings.
                  </div>
                </div>
                </div>
                <div className="order-first lg:order-last col-span-10 lg:col-span-2 flex flex-col gap-4 flex-grow">
                <button 
                  className="bg-primary-violet border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-dark-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 w-full"
                  onClick={onSaveAndContinue}
                  type="button"
                >
                  Save and Continue
                </button>
                <button
                  type="button"
                  onClick={handleSkipClassSetup}
                  className="bg-primary-violet border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-dark-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 w-full"
                >
                  Skip Class Setup
                </button>
                </div>         
              </div>
            </form>            
            </div>
          </div>
    )
}

export default ClassBuilderClassDetails;
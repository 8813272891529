import { AxiosResponse } from "axios";
import { AxiosHelper, axiosInstance } from "../../environment/axiosConfig";
import { ICanvasAccountSettingDTO } from "../../model/canvasClassroom/canvasAccountSettingDTO";
import {
  ICanvasClassDTO,
  ICanvasSectionDTO,
} from "../../model/canvasClassroom/canvasClassDTO";
import { ICanvasClassSectionDTO } from "../../model/canvasClassroom/canvasClassSectionDTO";
import { ICanvasClassSectionStudentDTO } from "../../model/canvasClassroom/canvasClassSectionStudentDTO";
import auth from "../../utils/auth";
import APIConstant from "../../utils/constant/apiConstant";
import { ICanvasRosterStudentDTO } from "../../model/interface/rosterUser";
import { IAssignmentStatus } from "../../model/externalRoster/externalRosterType";

export class CanvasApi {
  public static addCanvasUser(
    accountLoginName: string,
    username: string,
    password: string,
    canvasId: string,
    canvasLoginId: string
  ) {
    return axiosInstance.post(APIConstant.CANVAS_API.CanvasUser_Add, {
      AccountId: accountLoginName,
      Username: username,
      Password: password,
      canvasId: canvasId,
      canvasLoginId: canvasLoginId,
    });
  }

  public static unlinkCanvasUser(userId: number) {
    return AxiosHelper.axiosInstance().put(
      APIConstant.CANVAS_API.CanvasUser_UnlinkCanvasUser,
      {
        UserId: userId,
      }
    );
  }

  public static getCanvasAccountSettingsByAccountLoginName(
    accountLoginName: string
  ): Promise<AxiosResponse<ICanvasAccountSettingDTO>> {
    return axiosInstance.get<ICanvasAccountSettingDTO>(
      APIConstant.CANVAS_API
        .CanvasUser_GetCanvasAccountSettingsByAccountLoginName,
      {
        params: {
          accountLoginName: accountLoginName,
        },
      }
    );
  }

  public static getCanvasClassesToImport(
    userId: number,
    canvasId: string,
    name: string,
    orderByColumn: string,
    sortByDesc: boolean,
    pageSize: number,
    pageNumber: number,
    settings: ICanvasAccountSettingDTO | null
  ): Promise<AxiosResponse<Array<ICanvasClassDTO>>> {
    return AxiosHelper.axiosInstance().get<Array<ICanvasClassDTO>>(
      APIConstant.CANVAS_API.GetCanvasClassesToImport,
      {
        params: {
          userId: userId,
          canvasId: canvasId,
          name: name,
          orderByColumn: orderByColumn,
          sortByDesc: sortByDesc,
          pageSize: pageSize,
          pageNumber: pageNumber,
          adminAccountId: settings?.adminAccountId.toString(),
          baseEndPointUrl: settings?.endPointURL,
          defaultAccessToken: settings?.defaultToken,
          canvasAccessToken: auth.getCanvasAccessToken(),
        },
      }
    );
  }

  public static importCanvasClasses(
    canvasClasses: ICanvasClassDTO[],
    sections: ICanvasSectionDTO[],
    schoolAccountId: number,
    userId: number
  ) {
    return AxiosHelper.axiosInstance().post(
      APIConstant.CANVAS_API.ImportCanvasClasses,
      {
        canvasClasses: canvasClasses,
        sections: sections,
        schoolAccountId: schoolAccountId,
        userId: userId,
      }
    );
  }

  public static submitCanvasAssignmentScore(
    assignmentId: number,
    assignmentActivityId: number,
    studentUserId: number,
    score: number,
    settings: ICanvasAccountSettingDTO | null
  ) {
    return AxiosHelper.axiosInstance().post(
      APIConstant.CANVAS_API.SubmitCanvasAssignmentScore,
      {
        assignmentId: assignmentId,
        assignmentActivityId: assignmentActivityId,
        studentUserId: studentUserId,
        score: score,
        adminAccountId: settings?.adminAccountId.toString(),
        baseEndPointUrl: settings?.endPointURL,
        defaultAccessToken: settings?.defaultToken,
        canvasAccessToken: auth.getCanvasAccessToken(),
      }
    );
  }

  public static gradeCanvasAssignment(
    userId: number,
    settings: ICanvasAccountSettingDTO | null
  ) {
    return AxiosHelper.axiosInstance().post(
      APIConstant.CANVAS_API.GradeCanvasAssignment,
      {
        userId: userId,
        adminAccountId: settings?.adminAccountId.toString(),
        baseEndPointUrl: settings?.endPointURL,
        defaultAccessToken: settings?.defaultToken,
        canvasAccessToken: auth.getCanvasAccessToken(),
      }
    );
  }

  public static getCanvasUnSyncedStudents(
    classId: number,
    courseId: string,
    settings: ICanvasAccountSettingDTO | null
  ): Promise<AxiosResponse<ICanvasRosterStudentDTO[]>> {
    return AxiosHelper.axiosInstance().get<ICanvasRosterStudentDTO[]>(
      APIConstant.CANVAS_API.GetCanvasUnSyncedStudents,
      {
        params: {
          classId: classId,
          courseId: courseId,
          adminAccountId: settings?.adminAccountId.toString(),
          baseEndPointUrl: settings?.endPointURL,
          defaultAccessToken: settings?.defaultToken,
          canvasAccessToken: auth.getCanvasAccessToken(),
        },
      }
    );
  }

  public static getCanvasUnSyncedSectionStudents(
    classId: number,
    courseId: string,
    sectionId: string,
    settings: ICanvasAccountSettingDTO | null
  ): Promise<AxiosResponse<ICanvasRosterStudentDTO[]>> {
    return AxiosHelper.axiosInstance().get<ICanvasRosterStudentDTO[]>(
      APIConstant.CANVAS_API.GetCanvasUnSyncedSectionStudents,
      {
        params: {
          classId: classId,
          courseId: courseId,
          sectionId: sectionId,
          adminAccountId: settings?.adminAccountId.toString(),
          baseEndPointUrl: settings?.endPointURL,
          defaultAccessToken: settings?.defaultToken,
          canvasAccessToken: auth.getCanvasAccessToken(),
        },
      }
    );
  }

  public static syncCanvasStudents(
    canvasId: string,
    userId: number,
    classId: number,
    courseId: string,
    students: Array<ICanvasRosterStudentDTO>,
    settings: ICanvasAccountSettingDTO | null
  ) {
    return AxiosHelper.axiosInstance().post(
      APIConstant.CANVAS_API.SyncCanvasStudents,
      {
        canvasId: canvasId,
        userId: userId,
        classId: classId,
        courseId: courseId,
        students: students,
        adminAccountId: settings?.adminAccountId.toString(),
        baseEndPointUrl: settings?.endPointURL,
        defaultAccessToken: settings?.defaultToken,
        canvasAccessToken: auth.getCanvasAccessToken(),
      }
    );
  }

  public static mapCanvasStudents(canvasCourseStudents: Array<any>) {
    return AxiosHelper.axiosInstance().post(
      APIConstant.CANVAS_API.MapCanvasStudents,
      {
        canvasCourseStudents: canvasCourseStudents,
      }
    );
  }

  public static addCanvasCourse(
    classId: number,
    userId: number,
    name: string,
    schoolCanvasAccountId: string,
    settings: ICanvasAccountSettingDTO | null
  ) {
    return AxiosHelper.axiosInstance().post(
      APIConstant.CANVAS_API.AddCanvasCourse,
      {
        classId: classId,
        name: name,
        userId: userId,
        schoolCanvasAccountId: schoolCanvasAccountId,
        adminAccountId: settings?.adminAccountId.toString(),
        baseEndPointUrl: settings?.endPointURL,
        defaultAccessToken: settings?.defaultToken,
        canvasAccessToken: auth.getCanvasAccessToken(),
      }
    );
  }

  public static syncAssignemntsToCanvas(
    assignmentId: number,
    userId: number,
    dueDate: Date,
    accountLoginName: string,
    settings: ICanvasAccountSettingDTO | null
  ): Promise<AxiosResponse<IAssignmentStatus[]>> {
    return AxiosHelper.axiosInstance().post(
      APIConstant.CANVAS_API.AddCanvasAssignments,
      {
        assignmentId: assignmentId,
        userId: userId,
        dueDate: dueDate,
        AccountLoginName: accountLoginName,
        adminAccountId: settings?.adminAccountId.toString(),
        baseEndPointUrl: settings?.endPointURL,
        defaultAccessToken: settings?.defaultToken,
        canvasAccessToken: auth.getCanvasAccessToken(),
      }
    );
  }

  public static getClassSections(
    classId: number
  ): Promise<AxiosResponse<ICanvasClassSectionDTO[]>> {
    return AxiosHelper.axiosInstance().get<ICanvasClassSectionDTO[]>(
      APIConstant.CANVAS_API.GetClassSections,
      {
        params: {
          classId: classId,
        },
      }
    );
  }

  public static getClassSectionStudents(
    canvasClassSectionId: number
  ): Promise<AxiosResponse<ICanvasClassSectionStudentDTO[]>> {
    return AxiosHelper.axiosInstance().get<ICanvasClassSectionStudentDTO[]>(
      APIConstant.CANVAS_API.GetClassSectionStudents,
      {
        params: {
          canvasClassSectionId: canvasClassSectionId,
        },
      }
    );
  }

  public static syncClassSections(
    classId: number,
    courseId: string,
    userId: number,
    settings: ICanvasAccountSettingDTO | null
  ) {
    return AxiosHelper.axiosInstance().post(
      APIConstant.CANVAS_API.SyncClassSections,
      {
        classId: classId,
        courseId: courseId,
        userId: userId,
        adminAccountId: settings?.adminAccountId.toString(),
        baseEndPointUrl: settings?.endPointURL,
        defaultAccessToken: settings?.defaultToken,
        canvasAccessToken: auth.getCanvasAccessToken(),
      }
    );
  }

  public static removeCanvasAssignment(
    assignmentId: number,
    settings: ICanvasAccountSettingDTO | null
  ) {
    return AxiosHelper.axiosInstance().delete(
      APIConstant.CANVAS_API.RemoveCanvasAssignment,
      {
        data: {
          assignmentId: assignmentId,
          adminAccountId: settings?.adminAccountId.toString(),
          baseEndPointUrl: settings?.endPointURL,
          defaultAccessToken: settings?.defaultToken,
          canvasAccessToken: auth.getCanvasAccessToken(),
        },
      }
    );
  }

  public static connectToCanvas(
    classId: number,
    userId: number,
    overrideDisplayName: boolean,
    courseId: string,
    name: string
  ): Promise<AxiosResponse<number>> {
    return AxiosHelper.axiosInstance().post<number>(
      APIConstant.CANVAS_API.ConnectToCanvas,
      {
        classId: classId,
        userId: userId,
        overrideDisplayName: overrideDisplayName,
        courseId: courseId,
        name: name,
      }
    );
  }

  public static connectToSection(
    classId: number,
    courseId: string,
    overrideDisplayName: boolean,
    sectionId: string,
    name: string
  ): Promise<AxiosResponse<number>> {
    return AxiosHelper.axiosInstance().post<number>(
      APIConstant.CANVAS_API.ConnectToSection,
      {
        classId: classId,
        courseId: courseId,
        overrideDisplayName: overrideDisplayName,
        sectionId: sectionId,
        name: name,
      }
    );
  }

  public static getCanvasSections(
    courseId: string,
    settings: ICanvasAccountSettingDTO | null
  ): Promise<AxiosResponse<ICanvasSectionDTO[]>> {
    return AxiosHelper.axiosInstance().get<ICanvasSectionDTO[]>(
      APIConstant.CANVAS_API.GetCanvasSections,
      {
        params: {
          courseId: courseId,
          adminAccountId: settings?.adminAccountId.toString(),
          baseEndPointUrl: settings?.endPointURL,
          defaultAccessToken: settings?.defaultToken,
          canvasAccessToken: auth.getCanvasAccessToken(),
        },
      }
    );
  }

  public static disconnectClass(classId: number, courseId: string) {
    return AxiosHelper.axiosInstance().delete(
      APIConstant.CANVAS_API.DisconnectClass,
      {
        data: {
          classId: classId,
          courseId: courseId,
        },
      }
    );
  }

  public static disconnectSection(classId: number, sectionId: string) {
    return AxiosHelper.axiosInstance().delete(
      APIConstant.CANVAS_API.DisconnectSection,
      {
        data: {
          classId: classId,
          sectionId: sectionId,
        },
      }
    );
  }
}

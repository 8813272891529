import { Fragment } from "react";

type studentGradeModeType =
  | "Kindergarten"
  | "Elementary"
  | "MiddleSchool"
  | "LiftOff";
interface AlienAvatarProps {
  studentId: number;
  gradeId: number;
  alienData: any;
  showOnQuestions: boolean;
  studentGradeMode: studentGradeModeType;
  applykinderGartenStyle?: boolean;
  isLiftOff?: boolean;
}

function StudentAlienArenaPopUp(props: AlienAvatarProps) {
  return (
    <Fragment>
      <div
        className={`${
          props.studentGradeMode === "Elementary" ? "" : ""
        } rounded-2xl bg-white cursor-pointer h-full w-full py-4 px-4 text-center rounded-xl hover:shadow-2xl transition ease-in-out duration-500`}
      >
        <div className="flex flex-col items-center">
          <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xl sm:w-full">
            <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
              <button
                type="button"
                className="inline-flex items-center justify-center w-6 h-6 text-gray-400 transition-colors duration-150 rounded dark:hover:text-gray-200 hover: hover:text-gray-700"
              >
                <span className="sr-only">Close</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  className="h-6 w-6"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"
                  ></path>
                </svg>
              </button>
            </div>
            <div>
              <div className="flex items-center justify-center bg-yellow-400 px-4 py-6">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="w-8 h-8 text-yellow-800 mr-2 mt-1"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
                  />
                </svg>

                <h1 className="text-2xl text-yellow-800">
                  Question Not Available
                </h1>
              </div>
              <div className="px-4 py-8">
                <p className="text-gray-600 text-sm text-center">
                  Question is not available in selected language.
                </p>
              </div>
              <div className="flex items-center justify-end px-6 py-4 sm:flex-row bg-gray-50">
                <button
                  type="button"
                  className="align-bottom inline-flex items-center whitespace-nowrap justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 rounded-lg text-sm text-white border border-transparent bg-primary-green focus:shadow-outline-purple w-auto"
                >
                  Ok
                </button>
              </div>
            </div>
          </div>

          <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xl sm:w-full">
            <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
              <button
                type="button"
                className="inline-flex items-center justify-center w-6 h-6 text-gray-400 transition-colors duration-150 rounded dark:hover:text-gray-200 hover: hover:text-gray-700"
              >
                <span className="sr-only">Close</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  className="h-6 w-6"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"
                  ></path>
                </svg>
              </button>
            </div>
            <div>
              <div className="flex items-center justify-center bg-green-400 px-4 py-6">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="w-8 h-8 text-green-800 mr-2 mt-1"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>

                <h1 className="text-2xl text-green-800">Thank You</h1>
              </div>
              <div className="px-4 py-8">
                <p className="text-gray-600 text-base text-center font-semibold mb-2">
                  Your answer has been submitted.
                </p>
                <p className="text-gray-600 text-sm text-center">
                  Please wait while the rest of your classmates answer this
                  question.
                </p>
                <p className="text-gray-600 text-sm text-center">
                  Refresh the page if you are stuck on same question.
                </p>
              </div>
              <div className="flex items-center justify-end px-6 py-4 sm:flex-row bg-gray-50">
                <button
                  type="button"
                  className="align-bottom inline-flex items-center whitespace-nowrap justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 rounded-lg text-sm text-white border border-transparent bg-primary-green focus:shadow-outline-purple w-auto"
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default StudentAlienArenaPopUp;

import "../../../css/style.css";
import results from "../../../mockData/results.json";
import OverviewResult from "../../shared/widgets/overviewWidget";
// import ProgressWidget from "../../shared/widgets/progressWidget";
import LearnosityReport from "./learnosityReport";
import TotalQuestionsByDomains from "./totalQuestionsByDomains";
import TotalQuestionsByStandards from "./totalQuestionsByStandards";
import Breadcrumb from "../student/middleSchool/breadcrumb";
import { IBreadcrumb } from "../../../model/common/breadcrumb";
import routeConstant from "../../../utils/constant/routeConstant";
import { useTranslation } from "react-i18next";

function Results(props) {
  const resultsJson: any = results;
  const { t } = useTranslation();
  const breadcrumbItems = () => {
    const items: Array<IBreadcrumb> = [
      {
        name: "Home",
        url: routeConstant.MiddleSchool.assignments,
        textColor: "text-white",
      },
      {
        name: "Graded Work",
        url: routeConstant.MiddleSchool.gradedWork,
        textColor: "text-white",
      },
      {
        name: t("Breadcrumb.Result"),
        url: "",
        textColor: "text-white",
      },
    ];

    return items;
  };

  return (
    <div className="relative bg-teal-light">
      <Breadcrumb items={breadcrumbItems()} />
      <div className="p-5 flex flex-col md:flex-row">
        {resultsJson && Object.keys(resultsJson).length > 0 && (
          <OverviewResult inputData={resultsJson} />
        )}
        <div className="flex-grow h-auto md:w-1/2">
          {/* flex-grow h-auto md:w-1/2 */}
          <div className="flex w-full mt-6 md:mt-0 pb-6">
            <div>
              <h1 className="text-2xl font-bold text-white capitalize">
                Practice Result
              </h1>

              <h2 className="text-base font-medium text-white capitalize">
                Matter and Its Interactions / Periodic table
              </h2>
            </div>
          </div>
          {resultsJson?.buttons?.next_batch_assignment?.link && (
            <a
              href={resultsJson?.buttons?.next_batch_assignment?.link}
              className="bg-blues   
          hover:opacity-90 inline-block whitespace-nowrap rounded-full text-sm xl:w-40 xxl:w-40 text-center py-2 text-white mt-2 mb-2"
            >
              {resultsJson?.buttons?.next_batch_assignment?.title}
            </a>
          )}

          <div className="flex-none xxl:flex">
            <div className="flex-none w-full xl:w-4/5 xxl:w-2/5 bg-gray-50 rounded-md xl:mr-12 mb-12 xxl:mb-0 overflow-hidden">
              {/* {resultsJson && Object.keys(resultsJson).length > 0 && (
                <ProgressWidget data={resultsJson} />
              )} */}
            </div>
            <div className="flex-grow w-full xl:w-4/5 xxl:w-3/5">
              <div className="mb-8">
                <h2 className="text-md font-semibold text-gray-800 mb-2">
                  Teacher Feedback
                </h2>
                <div className="rounded-md bg-blue-100 p-4">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <svg
                        className="h-7 w-7 text-blue-400"
                        x-description="Heroicon name: solid/information-circle"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fillRule="evenodd"
                          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    </div>
                    <div className="ml-3 flex-1 md:flex md:justify-between">
                      <p className="text-lg text-blue-700">
                        You did a great job!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {resultsJson &&
                Object.keys(resultsJson).length > 0 &&
                resultsJson?.results_header?.assignment?.result?.not_graded ===
                  0 && (
                  <>
                    <TotalQuestionsByDomains data={resultsJson} />
                    <TotalQuestionsByStandards data={resultsJson} />
                  </>
                )}
            </div>
          </div>
        </div>
      </div>
      <div className="p-5">
        <div className="p-5 bg-gray-50 border rounded-md text-sm font-semibold">
          <h2>Answer status by Question No.</h2>
          <ul className="flex flex-wrap gap-2 w-full xl:w-1/2 mt-3">
            <li className="w-6 h-6 border border-green-400 text-gray-900 text-xs flex items-center rounded-sm shadow-sm  justify-center bg-orange-light cursor-pointer">
              1
            </li>
            <li className="w-6 h-6 border border-red-400 text-gray-500 text-xs flex items-center rounded-sm shadow-sm justify-center hover:bg-orange-light hover:text-gray-900 cursor-pointer">
              2
            </li>
            <li className="w-6 h-6 border border-green-400 text-gray-500 text-xs flex items-center rounded-sm shadow-sm justify-center hover:bg-orange-light hover:text-gray-900 cursor-pointer">
              3
            </li>
            <li className="w-6 h-6 border border-green-400 text-gray-500 text-xs flex items-center rounded-sm shadow-sm justify-center hover:bg-orange-light hover:text-gray-900 cursor-pointer">
              4
            </li>
            <li className="w-6 h-6 border border-red-400 text-gray-500 text-xs flex items-center rounded-sm shadow-sm justify-center hover:bg-orange-light hover:text-gray-900 cursor-pointer">
              5
            </li>
            <li className="w-6 h-6 border border-green-400 text-gray-500 text-xs flex items-center rounded-sm shadow-sm justify-center hover:bg-orange-light hover:text-gray-900 cursor-pointer">
              6
            </li>
            <li className="w-6 h-6 border border-green-400 text-gray-500 text-xs flex items-center rounded-sm shadow-sm justify-center hover:bg-orange-light hover:text-gray-900 cursor-pointer">
              7
            </li>
            <li className="w-6 h-6 border border-green-400 text-gray-500 text-xs flex items-center rounded-sm shadow-sm justify-center hover:bg-orange-light hover:text-gray-900 cursor-pointer">
              8
            </li>
            <li className="w-6 h-6 border border-green-400 text-gray-500 text-xs flex items-center rounded-sm shadow-sm justify-center hover:bg-orange-light hover:text-gray-900 cursor-pointer">
              9
            </li>
            <li className="w-6 h-6 border border-green-400 text-gray-500 text-xs flex items-center rounded-sm shadow-sm justify-center hover:bg-orange-light hover:text-gray-900 cursor-pointer">
              10
            </li>
            <li className="w-6 h-6 border border-green-400 text-gray-500 text-xs flex items-center rounded-sm shadow-sm justify-center hover:bg-orange-light hover:text-gray-900 cursor-pointer">
              11
            </li>
            <li className="w-6 h-6 border border-red-400 text-gray-500 text-xs flex items-center rounded-sm shadow-sm justify-center hover:bg-orange-light hover:text-gray-900 cursor-pointer">
              12
            </li>
            <li className="w-6 h-6 border border-red-400 text-gray-500 text-xs flex items-center rounded-sm shadow-sm justify-center hover:bg-orange-light hover:text-gray-900 cursor-pointer">
              13
            </li>
            <li className="w-6 h-6 border border-green-400 text-gray-500 text-xs flex items-center rounded-sm shadow-sm justify-center hover:bg-orange-light hover:text-gray-900 cursor-pointer">
              14
            </li>
            <li className="w-6 h-6 border border-green-400 text-gray-500 text-xs flex items-center rounded-sm shadow-sm justify-center hover:bg-orange-light hover:text-gray-900 cursor-pointer">
              15
            </li>
            <li className="w-6 h-6 border border-green-400 text-gray-500 text-xs flex items-center rounded-sm shadow-sm justify-center hover:bg-orange-light hover:text-gray-900 cursor-pointer">
              16
            </li>
            <li className="w-6 h-6 border border-green-400 text-gray-500 text-xs flex items-center rounded-sm shadow-sm justify-center hover:bg-orange-light hover:text-gray-900 cursor-pointer">
              17
            </li>
            <li className="w-6 h-6 border border-green-400 text-gray-500 text-xs flex items-center rounded-sm shadow-sm justify-center hover:bg-orange-light hover:text-gray-900 cursor-pointer">
              18
            </li>
            <li className="w-6 h-6 border border-red-400 text-gray-500 text-xs flex items-center rounded-sm shadow-sm justify-center hover:bg-orange-light hover:text-gray-900 cursor-pointer">
              19
            </li>
            <li className="w-6 h-6 border border-green-400 text-gray-500 text-xs flex items-center rounded-sm shadow-sm justify-center hover:bg-orange-light hover:text-gray-900 cursor-pointer">
              20
            </li>
            <li className="w-6 h-6 border border-green-400 text-gray-500 text-xs flex items-center rounded-sm shadow-sm justify-center hover:bg-orange-light hover:text-gray-900 cursor-pointer">
              21
            </li>
            <li className="w-6 h-6 border border-green-400 text-gray-500 text-xs flex items-center rounded-sm shadow-sm justify-center hover:bg-orange-light hover:text-gray-900 cursor-pointer">
              22
            </li>
            <li className="w-6 h-6 border border-green-400 text-gray-500 text-xs flex items-center rounded-sm shadow-sm justify-center hover:bg-orange-light hover:text-gray-900 cursor-pointer">
              23
            </li>
            <li className="w-6 h-6 border border-green-400 text-gray-500 text-xs flex items-center rounded-sm shadow-sm justify-center hover:bg-orange-light hover:text-gray-900 cursor-pointer">
              24
            </li>
            <li className="w-6 h-6 border border-green-400 text-gray-500 text-xs flex items-center rounded-sm shadow-sm justify-center hover:bg-orange-light hover:text-gray-900 cursor-pointer">
              25
            </li>
            <li className="w-6 h-6 border border-green-400 text-gray-500 text-xs flex items-center rounded-sm shadow-sm justify-center hover:bg-orange-light hover:text-gray-900 cursor-pointer">
              26
            </li>
            <li className="w-6 h-6 border border-green-400 text-gray-500 text-xs flex items-center rounded-sm shadow-sm justify-center hover:bg-orange-light hover:text-gray-900 cursor-pointer">
              27
            </li>
            <li className="w-6 h-6 border border-green-400 text-gray-500 text-xs flex items-center rounded-sm shadow-sm justify-center hover:bg-orange-light hover:text-gray-900 cursor-pointer">
              28
            </li>
            <li className="w-6 h-6 border border-green-400 text-gray-500 text-xs flex items-center rounded-sm shadow-sm justify-center hover:bg-orange-light hover:text-gray-900 cursor-pointer">
              29
            </li>
            <li className="w-6 h-6 border border-green-400 text-gray-500 text-xs flex items-center rounded-sm shadow-sm justify-center hover:bg-orange-light hover:text-gray-900 cursor-pointer">
              30
            </li>
            <li className="w-6 h-6 border border-green-400 text-gray-500 text-xs flex items-center rounded-sm shadow-sm justify-center hover:bg-orange-light hover:text-gray-900 cursor-pointer">
              31
            </li>
          </ul>
        </div>
      </div>
      <div className="p-5">
        <LearnosityReport
          sessionId="174dbdbe-c476-42b8-823d-3576c7653c46"
          user_id="6"
          show_answers={true}
          wrapperClass=""
        ></LearnosityReport>
      </div>
    </div>
  );
}

export default Results;

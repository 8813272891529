import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { ClassApi } from "../../../../api/teacher/classManagementApi";
import {
  ArchieveIcon,
  CanvasIcon,
  CanvasNewIcon,
  ClassLinkIcon,
  CleverIcon,
  ExclamationIcon,
  Eye,
  EyeOff,
  GoogleClassroomIcon,
  GradeBookIcon,
  GroupIcon,
  ProgressIcon,
} from "../../../../assets/icons/index";
import PagingResponse from "../../../../model/common/pagingResponse";
import classListResponse from "../../../../model/teacher/classManagement/classListResponse";
import ClassSearchDTO from "../../../../model/teacher/classManagement/classSearchModel";
import Constant from "../../../../utils/constant/constant";
import RouteConstant from "../../../../utils/constant/routeConstant";
import { DEFAULT_PAGE_NUMBER } from "../../../../utils/pagingConstant";
import Permissions from "../../../../utils/permissions";
import AccessDenied from "../../../shared/accessDenied";
import ConfirmationDialog from "../../../shared/confirmationDialog";
import Loader from "../../../shared/loader";
import PageSize from "../../../shared/pagination/pageSize";
import Pagination from "../../../shared/pagination/pagination";
import AllowedTo from "../../../shared/rbac";
import Breadcrumb from "../../../screen/teacher/shared/breadcrumb";
import { IBreadcrumb } from "../../../../model/common/breadcrumb";
import { useTranslation } from "react-i18next";
import ImportClassPopup from "./importClassPopup";
import GoogleSignInModal from "../../popup/googleSignInModal";
import constant from "../../../../utils/constant/constant";
import GoogleMappingModal from "../../popup/googleMappingModal";
import CanvasSignInModal from "../../popup/canvasSignInModal";
import { CanvasConfiguration } from "../../../../environment/setup";
import {
  validateIsCanvasUser,
  validateIsGoogleUser,
} from "../../../../utils/helper";
import { getClassManagementUpgradePermission } from "../../../shared/permissionHelper";
import UpGradeMessagePopup from "../../../shared/upGradeMessagePopup";
import { useConfirmation } from "../../../shared/confirmation/confirmationService";

const ClassManagement = (props: any) => {
  const { t } = useTranslation();
  const [pageNumber, setPageNumber] = useState(DEFAULT_PAGE_NUMBER);
  const [pageSize, setPageSize] = useState(20);
  const [showArchived, setShowArchived] = useState(false);
  const [showCurrent, setShowCurrent] = useState(true);
  const [showHidden, setShowHidden] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [confirmationTitle, setConfirmationTitle] = useState("Confirm Action");
  const [confirmationMessage, setConfirmationMessage] =
    useState("Please confirm");
  const [hideShowClass, setHideShowClass] = useState<any>();
  const [resetPageNumber, setResetPageNumber] = useState<boolean>(false);
  const [showImportGoogleClassPopup, setShowImportGoogleClassPopup] =
    useState(false);
  const [loading, showLoading] = useState<boolean>(false);
  const [classes, setClasses] = useState<PagingResponse<classListResponse>>();
  const [showGooglePopup, setShowGooglePopup] = useState(false);
  const [showGoogleMappingPopup, setShowGoogleMappingPopup] = useState(false);
  const [showCanvasPopup, setShowCanvasPopup] = useState({
    isopen: false,
    mode: "SIGNIN",
  });
  const [upgrade, setUpgrade] = useState(false);
  const [showUpgradePopup, setShowUpgradePopup] = useState<boolean>(false);

  const confirm = useConfirmation();
  const history = useHistory();

  useEffect(() => {
    checkUpgradeRequired();
  }, []);

  const checkUpgradeRequired = async () => {
    if (getClassManagementUpgradePermission(props.userContext.roleId)) {
      setUpgrade(true);
    } else {
      setUpgrade(false);
    }
  };

  useEffect(() => {
    getClasses();
  }, [pageSize, pageNumber]);

  const getClasses = () => {
    const classSearch: ClassSearchDTO = {
      pageNumber: pageNumber,
      pageSize: pageSize,
      showArchive: showArchived,
      showCurrent: showCurrent,
      showHidden: showHidden,
      userId: props.userContext.userId,
      schoolId: props.userContext.schoolId,
      schoolAccountId: props.userContext.schoolAccountId,
    };
    getClassList(classSearch);
  };

  const breadcrumbItems = () => {
    const items: Array<IBreadcrumb> = [
      { name: t("Breadcrumb.Home"), url: RouteConstant.ROUTE_DASHBOARD },
      { name: t("Breadcrumb.ClassManagement"), url: "" },
    ];

    return items;
  };

  function getClassList(classSearch: ClassSearchDTO) {
    showLoading(true);
    const response = ClassApi.getClassList(classSearch);
    response
      ?.then((d) => {
        setClasses(d.data);
        showLoading(false);
      })
      .catch(() => {
        showLoading(false);
      });
  }

  const handleClassClick = (id: number) => {
    history.push({
      pathname: id
        ? RouteConstant.TeacherRoutes.ClassesDetails.replace(
            ":classId",
            id.toString()
          )
        : RouteConstant.TeacherRoutes.Classes,
      state: {
        isArchieved: showArchived,
        isHidden: showHidden,
      },
    });
  };
  const handleCreateClick = () => {
    history.push({
      pathname: RouteConstant.TeacherRoutes.ClassesDetails.replace(
        ":classId",
        "new"
      ),
    });
  };

  const validateShowGooglePopup = () => {
    if (!validateIsGoogleUser(props.profile!)) {
      setShowGooglePopup(true);
      return false;
    } else if (!props.profile?.gmailId) {
      setShowGoogleMappingPopup(true);
      return false;
    }
    return true;
  };

  const validateShowCanvasPopup = () => {
    if (!validateIsCanvasUser(props.profile!)) {
      setShowCanvasPopup({ isopen: true, mode: "MAPPING" });
      return false;
    }
    return true;
  };

  const handleImportGoogleClassClick = () => {
    if (validateShowGooglePopup()) {
      setShowImportGoogleClassPopup(true);
    }
  };

  const handleImportCanvasClassClick = () => {
    if (validateShowCanvasPopup()) {
      history.push(RouteConstant.TeacherRoutes.ImportCanvasClasses);
    }
  };

  const onImportCompleted = () => {
    setShowImportGoogleClassPopup(false);
    getClasses();
  };

  const handleClassTypeChange = (type: string) => {
    switch (type) {
      case Constant.ClassType.CURRENT:
        setShowCurrent(true);
        setShowArchived(false);
        setShowHidden(false);
        break;
      case Constant.ClassType.ARCHIVED:
        setShowCurrent(false);
        setShowArchived(true);
        setShowHidden(false);
        break;
      case Constant.ClassType.HIDDEN:
        setShowCurrent(false);
        setShowArchived(false);
        setShowHidden(true);
        break;
    }
    const classSearch: ClassSearchDTO = {
      pageNumber: pageNumber,
      pageSize: pageSize,
      showArchive: type === Constant.ClassType.ARCHIVED,
      showCurrent: type === Constant.ClassType.CURRENT,
      showHidden: type === Constant.ClassType.HIDDEN,
      userId: props.userContext.userId,
      schoolId: props.userContext.schoolId,
      schoolAccountId: props.userContext.schoolAccountId,
    };
    getClassList(classSearch);
  };

  function handleGradeBookClick(classId: number): void {
    history.push({
      pathname: RouteConstant.TeacherRoutes.GradeBook,
      state: { classId: classId.toString() },
    });
  }

  function handleProgressReportClick(classId: number): void {
    history.push(
      RouteConstant.TeacherRoutes.ProgressReport.replace(
        ":classId",
        classId.toString()
      )
    );
  }

  function handleHideClass(classId: number, isHidden: boolean) {
    setHideShowClass({ classId: classId, isHidden: isHidden });
    showConfirmation(
      "Please confirm",
      "Are you sure you want to " +
        (isHidden ? "unhide" : "hide") +
        " this class?"
    );
  }

  function handleUnarchieveClass(classId: number) {
    setHideShowClass({ classId: classId });
    showConfirmation(
      "Please confirm",
      "Are you sure you want to unarchive this class?"
    );
  }

  function showConfirmation(title: string, message: string) {
    setOpenConfirmation(true);
    setConfirmationTitle(title);
    setConfirmationMessage(message);
  }

  function confirmationOkClose() {
    if (showArchived) {
      unarchiveClassConfirm(hideShowClass.classId);
    } else {
      hideShowClassConfirm(hideShowClass.classId, !hideShowClass.isHidden);
    }
  }

  function confirmationCancelClose() {
    setOpenConfirmation(false);
  }

  function hideShowClassConfirm(classId: number, isHidden: boolean): void {
    showLoading(true);
    ClassApi.setIsHidden(classId, props.userContext.userId, isHidden).then(
      () => {
        showLoading(false);
        const classSearch: ClassSearchDTO = {
          pageNumber: pageNumber,
          pageSize: pageSize,
          showArchive: showArchived,
          showCurrent: showCurrent,
          showHidden: showHidden,
          userId: props.userContext.userId,
          schoolId: props.userContext.schoolId,
          schoolAccountId: props.userContext.schoolAccountId,
        };
        getClassList(classSearch);
        confirmationCancelClose();
        toast.info(
          "Class " + (isHidden ? "hidden" : "unhidden") + " successfully"
        );
      }
    );
  }

  function unarchiveClassConfirm(classId: number): void {
    showLoading(true);
    ClassApi.setIsArchive(classId, props.userContext?.userId, false).then(
      () => {
        showLoading(false);
        const classSearch: ClassSearchDTO = {
          pageNumber: pageNumber,
          pageSize: pageSize,
          showArchive: showArchived,
          showCurrent: showCurrent,
          showHidden: showHidden,
          userId: props.userContext.userId,
          schoolId: props.userContext.schoolId,
          schoolAccountId: props.userContext.schoolAccountId,
        };
        getClassList(classSearch);
        confirmationCancelClose();
        toast.info("Class unarchived successfully");
      }
    );
  }

  const onPageChange = (pageNo) => {
    setPageNumber(pageNo);
  };

  const onPageSizeChange = (pageSize) => {
    setPageNumber(DEFAULT_PAGE_NUMBER);
    setPageSize(pageSize);
    setResetPageNumber(true);
  };

  const getExternalRosterIcon = (extId) => {
    if (extId === constant.ExternalRosterProviders.Clever) {
      return <CleverIcon className="w-5 h-5" />;
    } else if (extId === constant.ExternalRosterProviders.ClassLink) {
      return <ClassLinkIcon className="w-5 h-5" />;
    } else if (extId === constant.ExternalRosterProviders.Google) {
      return <GoogleClassroomIcon className="w-5 h-5" />;
    } else if (extId === constant.ExternalRosterProviders.Canvas) {
      return <CanvasNewIcon className="w-5 h-5" />;
    }
  };

  return (
    <AllowedTo
      perform={Permissions.class_management_view}
      no={() => <AccessDenied />}
    >
      {loading && <Loader />}
      {showUpgradePopup && (
        <UpGradeMessagePopup
          togglePopup={setShowUpgradePopup}
          roleId={props.userContext?.roleId ?? 0}
          message={constant.UPGRADE_MESSAGE_SCHOOL_ACCOUNT}
        />
      )}
      <section className="min-w-0 w-full">
        <div className="lg:flex lg:items-center px-4 py-3 lg:px-8 border-b border-gray-200">
          <Breadcrumb items={breadcrumbItems()} />
          <div className="lg:w-[55%] text-lg flex justify-between gap-2 h-auto sm:h-10 items-center">
            <h1 className="text-primary-violet font-medium flex justify-start">
              Class Management
            </h1>
          </div>
        </div>

        {/* Grid */}
        <div className="px-2 sm:px-6  lg:px-8 py-1 relative my-4">
          <div className=" mx-auto lg:grid lg:grid-cols-12 lg:gap-8">
            <div className="min-w-0 block lg:col-span-12 xl:col-span-12 w-full">
              <div className="bg-gray-100 w-full rounded-lg p-5">
                <div className="flex flex-col sm:flex-row sm:items-center sm:justify-end gap-4">
                  <div className="relative flex items-center">
                    <div className="flex items-center text-xs">
                      <CanvasNewIcon title="Canvas" className="w-5 ml-1" />
                      <label
                        htmlFor="chk-canvas"
                        className="ml-0.5 text-gray-700"
                      >
                        Canvas
                      </label>
                    </div>
                  </div>
                  <div className="relative flex items-center">
                    <div className="flex items-center text-xs">
                      <GoogleClassroomIcon
                        title="Google Classroom"
                        className="w-5 ml-1"
                      />
                      <label
                        htmlFor="chk-googleclassroom"
                        className="ml-0.5 text-gray-700"
                      >
                        Google Classroom
                      </label>
                    </div>
                  </div>
                  <div className="relative flex items-center">
                    <div className="flex items-center text-xs">
                      <CleverIcon title="Clever" className="w-5 ml-1" />
                      <label
                        htmlFor="chk-clever"
                        className="ml-0.5 text-gray-700"
                      >
                        Clever
                      </label>
                    </div>
                  </div>
                  <div className="relative flex items-center">
                    <div className="flex items-center text-xs">
                      <ClassLinkIcon title="Classlink" className="w-5 ml-1" />
                      <label
                        htmlFor="chk-classlink"
                        className=" ml-0.5 text-gray-700"
                      >
                        Classlink
                      </label>
                    </div>
                  </div>
                </div>
                <div className="mb-0">
                  <div className=" mt-2">
                    <div className="bg-white py-3 px-5">
                      <div className="flex sm:items-center sm:justify-between py-2 flex-wrap">
                        <ol className="flex sm:justify-end mb-2 lg:mb-0">
                          <li className="relative flex flex-col sm:flex-row items-start sm:items-center sm:justify-center  sm:mr-4">
                            <label
                              x-radio-group-option=""
                              className=" relative  px-4 py-2 flex cursor-pointer focus:outline-none border-gray-400 z-10"
                            >
                              <input
                                type="radio"
                                x-model="value"
                                name="privacy-setting"
                                value="Public access"
                                className="h-4 w-4 mt-0.5 cursor-pointer text-primary-violet border-gray-300 focus:ring-primary-violet"
                                aria-labelledby="privacy-setting-0-label"
                                aria-describedby="privacy-setting-0-description"
                                defaultChecked={true}
                                onChange={() =>
                                  handleClassTypeChange(
                                    Constant.ClassType.CURRENT
                                  )
                                }
                              />
                              <div className="ml-3 flex flex-col">
                                <span
                                  id="privacy-setting-0-label"
                                  className="text-sm font-medium text-primary-violet focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3"
                                >
                                  View Current Classes
                                </span>
                              </div>
                            </label>

                            <label
                              x-radio-group-option=""
                              className=" relative  px-4 py-2 flex cursor-pointer focus:outline-none border-gray-400 z-10"
                            >
                              <input
                                type="radio"
                                x-model="value"
                                name="privacy-setting"
                                value="Public access"
                                className="h-4 w-4 mt-0.5 cursor-pointer text-primary-violet border-gray-300 focus:ring-primary-violet"
                                aria-labelledby="privacy-setting-0-label"
                                aria-describedby="privacy-setting-0-description"
                                onClick={() =>
                                  handleClassTypeChange(
                                    Constant.ClassType.ARCHIVED
                                  )
                                }
                              />
                              <div className="ml-3 flex flex-col">
                                <span
                                  id="privacy-setting-0-label"
                                  className="text-sm font-medium text-primary-violet focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3"
                                >
                                  View Archived Classes
                                </span>
                              </div>
                            </label>

                            <label
                              x-radio-group-option=""
                              className=" relative  px-4 py-2 flex cursor-pointer focus:outline-none border-gray-400 z-10"
                            >
                              <input
                                type="radio"
                                x-model="value"
                                name="privacy-setting"
                                value="Public access"
                                className="h-4 w-4 mt-0.5 cursor-pointer text-primary-violet border-gray-300 focus:ring-primary-violet"
                                aria-labelledby="privacy-setting-0-label"
                                aria-describedby="privacy-setting-0-description"
                                onClick={() =>
                                  handleClassTypeChange(
                                    Constant.ClassType.HIDDEN
                                  )
                                }
                              />
                              <div className="ml-3 flex flex-col">
                                <span
                                  id="privacy-setting-0-label"
                                  className="text-sm font-medium text-primary-violet focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3"
                                >
                                  View Hidden Classes
                                </span>
                              </div>
                            </label>
                          </li>
                        </ol>

                        <AllowedTo
                          perform={Permissions.class_management_create}
                        >
                          <div className="flex space-x-3">
                            <>
                              <button
                                type="button"
                                className={
                                  (upgrade ||
                                  props.userContext.impersonatedUser !== null
                                    ? "opacity-50 "
                                    : "") +
                                  "disabled:opacity-50 disabled:cursor-not-allowed bg-transparent border border-primary-violet shadow-sm py-2 px-4 inline-flex items-center justify-center text-sm font-medium text-primary-violet hover:text-white hover:bg-light-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition ease-in-out duration-500"
                                }
                                onClick={() => {
                                  if (upgrade) {
                                    setShowUpgradePopup(true);
                                  } else if (
                                    props.userContext.impersonatedUser !== null
                                  ) {
                                    confirm({
                                      variant: "info",
                                      title: "Impersonation Detected",
                                      description:
                                        "This feature can only be accessed directly from the users login. Please reach out to the impersonated user to access this functionality.",
                                    });
                                  } else {
                                    handleImportGoogleClassClick();
                                  }
                                }}
                              >
                                <GoogleClassroomIcon className="w-5" />
                                <span className="ml-2 text-sm">
                                  Import Google
                                </span>
                                <span className="inline-block align-middle"></span>
                              </button>
                              {CanvasConfiguration.EnableCanvas && (
                                <button
                                  type="button"
                                  className={
                                    (upgrade ||
                                    props.userContext.impersonatedUser !== null
                                      ? "opacity-50 "
                                      : "") +
                                    "bg-transparent border border-primary-violet shadow-sm py-2 px-4 inline-flex items-center justify-center text-sm font-medium text-primary-violet hover:text-white hover:bg-light-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition ease-in-out duration-500"
                                  }
                                  onClick={() => {
                                    if (upgrade) {
                                      setShowUpgradePopup(true);
                                    } else if (
                                      props.userContext.impersonatedUser !==
                                      null
                                    ) {
                                      confirm({
                                        variant: "info",
                                        title: "Impersonation Detected",
                                        description:
                                          "This feature can only be accessed directly from the users login. Please reach out to the impersonated user to access this functionality.",
                                      });
                                    } else {
                                      handleImportCanvasClassClick();
                                    }
                                  }}
                                >
                                  <CanvasIcon className="w-5" />
                                  <span className="ml-2 text-sm">
                                    Import Canvas
                                  </span>
                                  <span className="inline-block align-middle"></span>
                                </button>
                              )}
                            </>
                            <button
                              onClick={handleCreateClick}
                              type="button"
                              className="bg-primary-violet border border-transparent shadow-sm py-2 px-4 justify-end text-sm font-medium text-white hover:bg-dark-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 whitespace-nowrap "
                            >
                              <span className="whitespace-normal sm:whitespace-nowrap">
                                Class Builder
                              </span>
                            </button>
                          </div>
                        </AllowedTo>
                      </div>
                      <div className="mb-4 pb-4 border-b-2 overflow-x-auto">
                        <table className="table w-full">
                          <thead className="mb-5">
                            <tr className="bg-light-violet text-white py-3 px-3 overflow-hidden">
                              <th className="text-left p-3 font-normal"></th>
                              <th className="text-left p-3 font-normal min-w-[14rem]">
                                Class Name
                              </th>
                              <th className="text-center p-3 text-sm font-normal w-48">
                                Class Details
                              </th>
                              <th className="text-center p-3 text-sm font-normal w-44">
                                Gradebook
                              </th>
                              <th className="text-center p-3 text-sm font-normal w-44">
                                Progress Report
                              </th>
                              <th className="text-center p-3 text-sm font-normal w-44">
                                {showCurrent && "Hide Class"}
                                {showArchived && "Unarchive Class"}
                                {showHidden && "Unhide Class"}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {classes?.data.map((classDetails, index) => {
                              return (
                                <tr
                                  key={classDetails.classId}
                                  className={`hover:bg-light-violet/20 transition ease-in-out duration-300 ${
                                    index % 2 === 0
                                      ? "bg-white"
                                      : "bg-light-violet/10"
                                  }`}
                                >
                                  <td
                                    className="w-4 h-4 pl-3"
                                    data-tip="Class color"
                                  >
                                    <div
                                      className="rounded-full w-5 h-5 border border-gray-200"
                                      style={{
                                        backgroundColor: classDetails.colorCode,
                                      }}
                                    >
                                      {" "}
                                    </div>
                                  </td>
                                  <td className="text-sm mt-5  p-3">
                                    <div className="flex gap-2 items-center">
                                      <span
                                        onClick={() =>
                                          handleClassClick(classDetails.classId)
                                        }
                                        className="cursor-pointer flex items-center text-blue-500 underline hover:no-underline"
                                        x-description="Upcoming Step"
                                      >
                                        {classDetails.name}
                                      </span>

                                      <div className="flex gap-2 justify-center items-center">
                                        {(classDetails.sectionId &&
                                          getExternalRosterIcon(
                                            constant.ExternalRosterProviders
                                              .Canvas
                                          )) ||
                                          (classDetails.externalRosterProviderIds &&
                                            classDetails.externalRosterProviderIds
                                              .split(",")
                                              .map((ext) =>
                                                getExternalRosterIcon(
                                                  parseInt(ext)
                                                )
                                              ))}
                                        {classDetails.isGoogleEnabled &&
                                          getExternalRosterIcon(
                                            constant.ExternalRosterProviders
                                              .Google
                                          )}
                                      </div>

                                      {(classDetails.studentCount === 0 ||
                                        classDetails.teacherCount === 0 ||
                                        classDetails.subjectCount === 0) && (
                                        <>
                                          <span
                                            className="ml-3 flex items-center inline-block my-0 px-2 py-1.5 bg-red-200 text-gray-700 text-xs whitespace-nowrap 

                                              leading-tight rounded-full"
                                          >
                                            <ExclamationIcon
                                              className="w-1 mr-2 h-3"
                                              title="Incomple section"
                                            />{" "}
                                            Click Class Name to Complete Setup
                                          </span>
                                        </>
                                      )}
                                    </div>
                                  </td>
                                  <td className=" mt-5 p-3 text-center">
                                    <span
                                      onClick={() =>
                                        handleClassClick(classDetails.classId)
                                      }
                                      className="cursor-pointer inline-block"
                                      x-description="Upcoming Step"
                                    >
                                      <GroupIcon
                                        title="Class Details"
                                        onClick={() =>
                                          handleClassClick(classDetails.classId)
                                        }
                                      />
                                    </span>
                                  </td>
                                  <td className=" mt-5 p-3 text-center cursor-pointer">
                                    <span className="inline-block">
                                      <GradeBookIcon
                                        title="Gradebook"
                                        onClick={() => {
                                          handleGradeBookClick(
                                            classDetails.classId
                                          );
                                        }}
                                      />
                                    </span>
                                  </td>
                                  <td className=" mt-5 p-3 text-center cursor-pointer">
                                    <span className="inline-block">
                                      <ProgressIcon
                                        title="Progress report"
                                        onClick={() => {
                                          handleProgressReportClick(
                                            classDetails.classId
                                          );
                                        }}
                                      />
                                    </span>
                                  </td>
                                  <td className=" mt-5 p-3 text-center cursor-pointer">
                                    <span className="inline-block">
                                      {classDetails.isHidden === false &&
                                        showArchived && (
                                          <ArchieveIcon
                                            title="Unarchive Class"
                                            onClick={() => {
                                              handleUnarchieveClass(
                                                classDetails.classId
                                              );
                                            }}
                                          />
                                        )}
                                      {classDetails.isHidden === false &&
                                        showCurrent && (
                                          <EyeOff
                                            title="Hide class"
                                            onClick={() => {
                                              handleHideClass(
                                                classDetails.classId,
                                                classDetails.isHidden
                                              );
                                            }}
                                          />
                                        )}
                                      {classDetails.isHidden === true && (
                                        <Eye
                                          className="text-gray-500 w-4"
                                          title="Unhide class"
                                          onClick={() => {
                                            handleHideClass(
                                              classDetails.classId,
                                              classDetails.isHidden
                                            );
                                          }}
                                        />
                                      )}
                                    </span>
                                  </td>
                                </tr>
                              );
                            })}
                            {classes?.totalRecords === 0 && (
                              <tr>
                                <td colSpan={5} className=" mt-5 p-3 text-left">
                                  {showCurrent
                                    ? "Create your first class by selecting the ‘Class Builder’ button on the right side of your screen or by navigating to Class Center → Class Management on the left side of your screen."
                                    : " No record found"}
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>

                      <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between mt-2">
                        {classes?.totalRecords !== undefined &&
                          classes?.totalRecords !== 0 && (
                            <Pagination
                              totalRecords={classes?.totalRecords}
                              pageSize={pageSize}
                              onPageChange={onPageChange}
                              reset={resetPageNumber}
                              pageNumber={pageNumber}
                            />
                          )}
                        {classes?.totalRecords !== 0 && (
                          <PageSize
                            onPageSizeChange={onPageSizeChange}
                            pageSize={pageSize}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ConfirmationDialog
        open={openConfirmation}
        confirmationTitle={confirmationTitle}
        confirmationMessage={confirmationMessage}
        onCancelClick={confirmationCancelClose}
        onOkClick={confirmationOkClose}
      ></ConfirmationDialog>
      {showImportGoogleClassPopup && (
        <ImportClassPopup
          showPopup={setShowImportGoogleClassPopup}
          onImportCompleted={onImportCompleted}
        />
      )}

      {showGooglePopup && (
        <GoogleSignInModal
          roleId={props.userContext.userId}
          returnUrl={RouteConstant.TeacherRoutes.Classes}
          showPopUp={setShowGooglePopup}
          mode={Constant.GoogleSignInMode.SSO}
        />
      )}
      {showGoogleMappingPopup && (
        <GoogleMappingModal
          roleId={props.userContext.userId}
          returnUrl={RouteConstant.TeacherRoutes.Classes}
          showPopUp={setShowGoogleMappingPopup}
        />
      )}
      {showCanvasPopup.isopen && (
        <CanvasSignInModal
          mappingMode={showCanvasPopup.mode as any}
          returnUrl={RouteConstant.TeacherRoutes.Classes}
          showPopUp={(show) => {
            setShowCanvasPopup({ isopen: show, mode: showCanvasPopup.mode });
          }}
          accountLoginName={props.profile.accountLoginName}
        />
      )}
    </AllowedTo>
  );
};

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(ClassManagement);

import { AxiosHelper } from "../../environment/axiosConfig";
import APIConstant from "../../utils/constant/apiConstant";
import qs from "qs";

export const getUsageReportBySubject = (
  schoolIds: Array<number> | null,
  subjectIds: Array<number> | null,
  districtId: number,
  schoolYearId: number
): any => {
  return AxiosHelper.axiosInstance().get(
    APIConstant.USAGEREPORT_API.GetUsageReportBySubject,
    {
      params: {
        schoolIds: schoolIds,
        subjectIds: subjectIds,
        districtId: districtId,
        schoolYearId: schoolYearId,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    }
  );
};

export const exportUsageReportBySubject = (
  schoolIds: Array<number> | null,
  subjectIds: Array<number> | null,
  districtId: number,
  schoolYearId: number,
  view: string,
  filters: string
): any => {
  return AxiosHelper.axiosInstance().get(
    APIConstant.USAGEREPORT_API.ExportUsageReportBySubject,
    {
      responseType: "blob",
      params: {
        schoolIds: schoolIds,
        subjectIds: subjectIds,
        districtId: districtId,
        schoolYearId: schoolYearId,
        view: view,
        filters: filters,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    }
  );
};


export const exportUsageReportBySchool = (
  schoolIds: Array<number> | null,
  subjectIds: Array<number> | null,
  districtId: number,
  schoolYearId: number,
  view: string,
  filters: string
): any => {
  return AxiosHelper.axiosInstance().get(
    APIConstant.USAGEREPORT_API.ExportUsageReportBySchool,
    {
      responseType: "blob",
      params: {
        schoolIds: schoolIds,
        subjectIds: subjectIds,
        districtId: districtId,
        schoolYearId: schoolYearId,
        view: view,
        filters: filters,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    }
  );
};

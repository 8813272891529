import i18n from "../i18Next";

export function initPreviewRequest(
    studentId: string,
    itemIds: Array<string>,
    studentType: string,
    sessionId: string,
    onExitRedirectUrl: string
) {
  return {
    user_id: studentId,
    rendering_type: "assess",
    name: "Items API",
    state: "preview",
    items: itemIds,
    session_id: sessionId,
    type: "local_practice",
    config: {
      annotations: false,
      ui_style: "horizontal",
      configuration: {
        onsave_redirect_url: onExitRedirectUrl,
        onsubmit_redirect_url: onExitRedirectUrl,
        ondiscard_redirec_url: false,
        decouple_submit_from_review: true,
        fontsize: studentType && studentType === "K1" 
            ? "xxlarge"
            : "normal",
      },
      navigation: {
        show_outro: false,
        show_intro: false,
        skip_submit_confirmation: true,
        warning_on_change: false
      },
      labelBundle: {
        "submitButtonLabel": i18n.t("StudentResponse.Close"),
      },
      regions: {
        "top-left": [
              {
                type: "title_element",
              },
        ],
        "top-right": [
          {
            type: "pause_button",
            position: "right",
          },
          {
            type: "timer_element",
          },
          {
            type: "itemcount_element",
          },
        ],
        right: [
          {
            type: "separator_element",
          },
          {
            type: "accessibility_button",
          },
          {
            type: "masking_button",
          },
        ],
        items: [
          {
            type: "slider_element",
            scrollable_option: false,
          },
          {
            type: "progress_element",
          },
        ],
        "bottom-right": [
          {
            type: "next_button",
          },
        ],
      },
    }
  };
}
export const initRequest = (
  itemRef: string,
  widgetType: string,
  questionTypeId: number
) => {
  var hiddenSection =
    widgetType === "features"
      ? ["more_options"]
      : ["details", "extras", "details.heading", "details.content"];

  return {
    mode: "item_edit",
    reference: itemRef,
    config: {
      dependencies: {
        question_editor_api: {
          init_options: {
            base_question_type: {
              hidden: [
                "shuffle_options",
                "validation.penalty",
                "validation.min_score_if_attempted",
                "feedback_attempts",
                "instant_feedback",
                "ui_style.validation_stem_numeration",
                "validation.unscored",
                "validation.automarkable",
                "is_math",
                "metadata.distractor_rationale_response_level",
                "character_map",
                "metadata.acknowledgements",
                "metadata.rubric_reference",
                "stimulus_review",
                "instructor_stimulus",
                "metadata.sample_answer"
              ],
              hidden_sections: hiddenSection,
            },
            question_types: {
              mcq: {
                shown:
                  questionTypeId === 49
                    ? ["ui_style.validation_stem_numeration"]
                    : [],
              },
              choicematrix: {
                shown: ["ui_style.validation_stem_numeration"],
              },
              longtextV2: {
                hidden: ["validation.min_score_if_attempted", "character_map"],
                shown_sections: ["details", "details.heading", "details.content"]
              },
              shorttext: {
                shown_sections: ["details", "details.heading", "details.content"]
              },
              drawing: {
                shown_sections: ["details", "details.heading", "details.content"]
              },
              simpleshading: {
                shown_sections: ["details", "details.heading", "details.content"]
              },
              chemistryessayV2: {
                shown_sections: ["details", "details.heading", "details.content"]
              },
              formulaessayV2: {
                shown_sections: ["details", "details.heading", "details.content"]
              },
              hotspot: {
                hidden: ["validation.scoring_type"],
                hidden_sections: [
                  "scoring.heading",
                  "details.header",
                  "more_options.divider",
                ],
              },
              simplechart: {
                hidden: ["ui_style.chart_type"],
                hidden_sections: ["scoring.heading", "more_options.divider"],
              },
              graphplotting: {
                hidden_sections: ["scoring.heading", "more_options.divider"],
              },
            },
            widget_type: "response",
            ui: {
              layout: {
                global_template: "edit",
              },
              change_button: false,
              hide_asset_input_field: true,
              source_button: false,
            },
            label_bundle: {
              formatting_options:
                "Select any of the below text formatting options. You can also drag the options in any order of your choice.",
            },
          },
          preload: true,
        },
      },
      widget_templates: {
        back: false,
        save: false,
        widget_types: {
          default: widgetType,
          show: true,
        },
      },
      container: {
        height: "auto",
        fixed_footer_height: 0,
      },
      item_edit: {
        tags_on_create: [
          {
            type: widgetType === "questions" ? "Question" : "Passage",
            name: widgetType === "questions" ? "Question" : "Passage",
          },
        ],
        item: {
          back: false,
          save: false,
          tag: {
            edit: true,
            show: true,
          },
        },
      },
    },
    user: {
      id: "demoTest",
      firstname: "",
      lastname: "",
      email: "",
    },
  };
};

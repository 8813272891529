import moment from "moment";
import { Fragment } from "react";
import ColumnHeader from "../../model/gridView/columnHeader";
import DataRow from "../../model/gridView/dataRow";
import { useTranslation } from "react-i18next";
interface GridViewProps {
  columnHeaders: Array<ColumnHeader>;
  rows: Array<DataRow>;
  source?: string;
  onRowClick?: (parameters: object) => void;
}

function GridView(props: GridViewProps) {
  const { t } = useTranslation();
  const { columnHeaders, rows } = props;
  return (
    <Fragment>
      <div>
        <div className="text-lg font-semibold mb-1 inline-block text-black opacity-70">
          {t("SkillPractice.SkillsPracticeTest")}
        </div>
        <div className="shadow overflow-x-auto rounded-lg border-4 border-[#e3178d]">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                {props.source && props.source === "SKILLPRACTICE"
                  ? columnHeaders.map((header, index) => {
                      return (
                        <th
                          scope="col"
                          className={
                            (index === 1 ? "w-32 text-center" : "") +
                            " px-3 py-3 text-left text-sm font-medium text-gray-500 tracking-wider"
                          }
                        >
                          {header.title}
                        </th>
                      );
                    })
                  : columnHeaders.map((header) => {
                      return (
                        <th
                          scope="col"
                          className="px-3 py-3 text-left text-xs font-medium text-gray-500 tracking-wider"
                        >
                          {header.title}
                        </th>
                      );
                    })}
              </tr>
            </thead>
            <tbody>
              {rows.map((row, index) => (
                <tr
                  key={index}
                  data-id={row?.id}
                  className={
                    index % 2 === 0 ? "bg-white" : "bg-white bg-opacity-90"
                  }
                >
                  {row.items.map((item, index) => {
                    return (
                      <td
                        key={index}
                        className={`px-3 py-2  text-sm text-gray-500 ${item.cssClass}`}
                      >
                        {item.type === Number &&
                          (item.isHyperLink ? (
                            <a
                              href="#!"
                              className="text-indigo-600 hover:text-indigo-900"
                            >
                              {item.value}
                            </a>
                          ) : (
                            item.value
                          ))}

                        {item.type === String &&
                          (item.isHyperLink ? (
                            <a
                              href="#!"
                              className="text-indigo-600 hover:text-indigo-900"
                            >
                              {item.value}
                            </a>
                          ) : (
                            item.value
                          ))}

                        {item.type === Date &&
                          item.value.toString() !== "" &&
                          moment(item.value.toString()).format("MM/DD/YYYY")}

                        {item.type === Date &&
                          item.value.toString() === "" &&
                          "-"}

                        {item.type === Image && <span>item.value</span>}

                        {item.type === Boolean && (
                          <div
                            className="floatleft textcenter hand startstudybtn studyplanstart cursor-pointer"
                            data-id={item.value}
                            onClick={props.onRowClick}
                          >
                            <button
                              className={
                                (props.source &&
                                props.source === "SKILLPRACTICE"
                                  ? "m-auto"
                                  : "") +
                                " flex items-center mx-auto justify-center  hover:bg-opacity-80  px-2 w-full max-w-[10rem]  text-xs py-1 text-white rounded-lg p-1 space-x-0.5 bg-blues"
                              }
                            >
                              <span>{t("SkillPractice.Start")}</span>
                              <span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                  className="w-4 cursor-pointer"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z"
                                  ></path>
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                  ></path>
                                </svg>
                              </span>
                            </button>
                          </div>
                        )}
                      </td>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="mt-3 text-right">
          {rows.length > 0 &&
            `Showing 1 to ${rows.length} of ${rows.length} entries`}
        </div>
      </div>
    </Fragment>
  );
}

export default GridView;

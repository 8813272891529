import React, { Fragment, useEffect, useState } from "react";
import Header from "../../../../screen/student/kindergarten/header";
import Footer from "../../../../screen/student/kindergarten/footer";
import { useLocation } from "react-router-dom";
import RouteConstant from "../../../../../utils/constant/routeConstant";

const Layout = ({ children }) => {
  const location = useLocation();
  const [isHomePage, setIsHomePage] = useState(true);

  useEffect(() => {
    const pageName = location.pathname.toUpperCase();
    const homePage = RouteConstant.KindergartenStudentRoutes.Home.toUpperCase();

    setIsHomePage(pageName === homePage);
  });

  return (
    <Fragment>
      <div className="font-Didact flex min-h-screen bg-gradient-to-l from-[#09132d]  via-[#240e63] to-[#0b1948] animate-animate_gradient">
        <div
          className={`flex w-full flex-col min-h-full ${
            !isHomePage ? "bg-[#26234c]" : ""
          }`}
        >
          <Header
            isSubjectHomePage={!isHomePage}
            showParentConnectPopUp={(show) => {}}
          ></Header>
          {children}
          <Footer></Footer>
        </div>
      </div>
    </Fragment>
  );
};

export default Layout;

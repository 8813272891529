import { useEffect, useState } from "react";
import DotRank from "../../../../shared/widgets/dotRank";
import SortTableHeader from "../../../../shared/sortTableHeader";
import { SortingOrder } from "../../../../../types/type";
import { sortReport } from "../../../../../utils/sortProgressReportHelper";

export default function ProgressReportBySchoolByCount(props: any) {
  const [progressReportBySchool, setProgressReportBySchool] = useState<any>();

  useEffect(() => {
    if (!props?.data?.modified) {
      setPercentageForSchools();
    }
    props.data.modified = true;
    setProgressReportBySchool(props.data);
    sortProgressReportBySchool(props.data, props.activeSortColumn, props.orderType);
  }, []);

  const setPercentageForSchools = () => {
    const summaryIndexes = props.getSummaryIndexes(props.data);
    props.data?.schools?.forEach((school, schoolIndex) => {
      school["isCollapse"] = true;
      if (schoolIndex !== 0) {
        school.pctCorrect.forEach((pct, pctIndex) => {
          let value = Math.round(pct * 100);
          school["lessThan65"] = school["lessThan65"]
            ? school["lessThan65"]
            : 0;

          school["lessThan80"] = school["lessThan80"]
            ? school["lessThan80"]
            : 0;

          school["greaterThan80"] = school["greaterThan80"]
            ? school["greaterThan80"]
            : 0;
          school["default"] = school["default"] ? school["default"] : 0;

          switch (true) {
            case summaryIndexes.includes(pctIndex):
              break;
            case pct == null:
              school["default"] = Number(school["default"]) + 1;
              break;
            case value < 65:
              school["lessThan65"] = Number(school["lessThan65"]) + 1;
              break;
            case value >= 65 && value < 80:
              school["lessThan80"] = Number(school["lessThan80"]) + 1;
              break;
            case value >= 80:
              school["greaterThan80"] = Number(school["greaterThan80"]) + 1;
              break;
            default:
              school["default"] = Number(school["default"]) + 1;
              break;
          }
        });

        //Summary
        if (props.data?.schools) {
          props.data.schools[0]["lessThan65"] =
            (props.data.schools[0]["lessThan65"]
              ? props.data.schools[0]["lessThan65"]
              : 0) + school["lessThan65"];

          props.data.schools[0]["lessThan80"] =
            (props.data.schools[0]["lessThan80"]
              ? props.data.schools[0]["lessThan80"]
              : 0) + school["lessThan80"];

          props.data.schools[0]["greaterThan80"] =
            (props.data.schools[0]["greaterThan80"]
              ? props.data.schools[0]["greaterThan80"]
              : 0) + school["greaterThan80"];

          props.data.schools[0]["default"] =
            (props.data.schools[0]["default"]
              ? props.data.schools[0]["default"]
              : 0) + school["default"];
        }
      }
    });
  };

  const sortProgressReportBySchool = (data: any, column: string, orderType: SortingOrder) => {
    sortReport({ data: data?.schools, column, orderType, defaultName: "All Schools"});
    props.setOrderOptions(column, orderType);
  }

  return (
    <>
      {progressReportBySchool && (
        <div className="pt-10 overflow-x-auto">
          <div className="overflow-x-auto">
            <table className="divide-y border-b w-full">
              <tr className="bg-light-violet text-white py-3 px-3">
                <th className="text-left p-3 text-sm font-normal">
                  <SortTableHeader
                    data={progressReportBySchool}
                    orderType={props.orderType}
                    headerComponent="School"
                    columnName="name"
                    activeSortColumn={props.activeSortColumn}
                    sortRecords={sortProgressReportBySchool}
                  />
                </th>
                <th className="text-center p-3 text-sm font-normal">
                  <span className="flex justify-center">
                    <SortTableHeader
                      data={progressReportBySchool}
                      orderType={props.orderType}
                      headerComponent={
                        <DotRank
                          value={86}
                          displayColorName={true}
                        />
                      }
                      columnName="greaterThan80"
                      activeSortColumn={props.activeSortColumn}
                      sortRecords={sortProgressReportBySchool}
                    />
                  </span>
                </th>
                <th className="text-center p-3 text-sm font-normal">
                  <span className="flex justify-center">
                    <SortTableHeader
                      data={progressReportBySchool}
                      orderType={props.orderType}
                      headerComponent={
                        <DotRank
                          value={65}
                          displayColorName={true}
                        />
                      }
                      columnName="lessThan80"
                      activeSortColumn={props.activeSortColumn}
                      sortRecords={sortProgressReportBySchool}
                    />
                  </span>
                </th>
                <th className="text-center p-3 text-sm font-normal">
                  <span className="flex justify-center">
                    <SortTableHeader
                      data={progressReportBySchool}
                      orderType={props.orderType}
                      headerComponent={
                        <DotRank
                          value={64}
                          displayColorName={true}
                        />
                      }
                      columnName="lessThan65"
                      activeSortColumn={props.activeSortColumn}
                      sortRecords={sortProgressReportBySchool}
                    />
                  </span>
                </th>
                <th className="text-center p-3 text-sm font-normal">
                  <span className="flex justify-center">
                    <SortTableHeader
                      data={progressReportBySchool}
                      orderType={props.orderType}
                      headerComponent={
                        <DotRank
                          value={undefined}
                          displayColorName={true}
                        />
                      }
                      columnName="default"
                      activeSortColumn={props.activeSortColumn}
                      sortRecords={sortProgressReportBySchool}
                    />
                  </span>
                </th>
              </tr>

              {progressReportBySchool.schools.map((school, index) => (
                <>
                  <tr
                    className={
                      "border-l border-r " +
                      (index % 2 !== 0 ? "bg-primary-violet/10" : "")
                    }
                  >
                    <td className="text-left text-sm font-normal flex">
                      <span
                        className={
                          (index === 0 ? "font-semibold " : "text-sm ") +
                          "text-center p-3  "
                        }
                      >
                        {school.name}
                      </span>
                    </td>
                    <td
                      className={
                        (index === 0 ? "font-semibold " : "text-sm ") +
                        "text-center p-3  "
                      }
                    >
                      {school.greaterThan80}
                    </td>
                    <td
                      className={
                        (index === 0 ? "font-semibold " : "text-sm ") +
                        "text-center p-3  "
                      }
                    >
                      {school.lessThan80}
                    </td>
                    <td
                      className={
                        (index === 0 ? "font-semibold " : "text-sm ") +
                        "text-center p-3  "
                      }
                    >
                      {school.lessThan65}
                    </td>
                    <td
                      className={
                        (index === 0 ? "font-semibold " : "text-sm ") +
                        "text-center p-3  "
                      }
                    >
                      {school.default}
                    </td>
                  </tr>
                </>
              ))}
            </table>
          </div>
        </div>
      )}
    </>
  );
}

import { AxiosResponse } from "axios";
import { AxiosHelper } from "../../environment/axiosConfig";
import {
  IActivitySessionRetryResponse,
  IActivitySessionRetryEligibilityDTO,
} from "../../model/interface/activitySession/retryAssignment";
import { IActivitySessionQuestion } from "../../model/interface/activitySession/activitySessionQuestion";
import APIConstant from "../../utils/constant/apiConstant";
import qs from "qs";
import { IActivitySessionQuestionFeedback } from "../../model/interface/activitySession/activitySessionQuestionFeedback";

export function getStudentActivities(studentId: number, assignmentId: number) {
  return AxiosHelper.axiosInstance().get(
    APIConstant.STUDENTASSIGNMENT_API.StudentActivities,
    {
      params: {
        StudentId: studentId,
        AssignmentId: assignmentId,
      },
    }
  );
}

export function initializeStudentAssignmentActivity(
  userId: number,
  assignmentId: number,
  assignmentActivityId: number,
  assignmentActivityName: string,
  languageId: number,
  lernosityActivityId: string,
  isBegin: boolean,
  gradeId: number,
  teacherId: number | undefined,
  classId: number | undefined,
  gameId: number,
  rocketId: number,
  blasterId: number,
  blasterType: string
): Promise<AxiosResponse<any>> {
  return AxiosHelper.axiosInstance().post<any>(
    APIConstant.STUDENTASSIGNMENTACTIVITY_API.Initialize,
    {
      userId: userId,
      assignmentId: assignmentId,
      assignmentActivityId: assignmentActivityId,
      assignmentActivityName: assignmentActivityName,
      languageId: languageId,
      lernosityActivityId: lernosityActivityId,
      isBegin: isBegin,
      gradeId: gradeId,
      teacherUserId: teacherId,
      classId: classId,
      gameId: gameId,
      rocketId: rocketId,
      blasterId: blasterId,
      blasterType: blasterType,
    }
  );
}

export function completeStudentAssignmentActivity(
  userId?: number,
  learnositySessionId?: string,
  TimeLimit?: number,
  QuestionsAttemptedTillTimeLimit?: number,
  activitySessionId?: number
) {
  return AxiosHelper.axiosInstance().put(
    APIConstant.STUDENTASSIGNMENTACTIVITY_API.Complete,
    {
      userId: userId,
      sessionId: learnositySessionId,
      timeLimit: TimeLimit,
      questionsAttemptedTillTimeLimit: QuestionsAttemptedTillTimeLimit,
      activitySessionId: activitySessionId,
    }
  );
}

export function updateStudentAssignmentActivity(
  userId?: number,
  learnositySessionId?: string,
  classId?: number,
  teacherId?: number
) {
  return AxiosHelper.axiosInstance().put(
    APIConstant.STUDENTASSIGNMENTACTIVITY_API.Update,
    {
      userId: userId,
      sessionId: learnositySessionId,
      classId: classId,
      teacherId: teacherId
    }
  );
}

export function getStudentsReportForActivity(
  assessmentId: number | null,
  assignmentActivityId: number,
  assignmentId: number,
  classIds: Array<number>,
  schoolIds: Array<number>,
  isConstructedResponse: boolean,
  schoolYearId?: number
) {
  return AxiosHelper.axiosInstance().get(
    APIConstant.STUDENTASSIGNMENTACTIVITY_API.StudentsReport,
    {
      params: {
        assessmentId: assessmentId,
        assignmentActivityId: assignmentActivityId,
        assignmentId: assignmentId,
        classIds: classIds,
        schoolIds: schoolIds,
        IsConstructedResponse: isConstructedResponse,
        schoolYearId: schoolYearId,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    }
  );
}

export function getStudentsTeacherFeedbackForActivity(
  studentAssignmentActivtityId: number
) {
  return AxiosHelper.axiosInstance().get(
    APIConstant.STUDENTASSIGNMENTACTIVITY_API.StudentsTeacherFeedback,
    {
      params: {
        studentAssignmentActivityId: studentAssignmentActivtityId,
      },
    }
  );
}

export function getStudentActivitiesStatus(studentId: number) {
  return AxiosHelper.axiosInstance().get(
    APIConstant.STUDENTASSIGNMENT_API.StudentActivitiesStatus,
    {
      params: {
        StudentId: studentId,
      },
    }
  );
}

export function getAssignmentsQuestionCount(sessionActivityId: string) {
  return AxiosHelper.axiosInstance().get(
    APIConstant.STUDENTASSIGNMENT_API.StudentAssignmentsQuestionCount,
    {
      params: {
        sessionActivityId: sessionActivityId,
      },
    }
  );
}

export function getStudentAssignmentLastSession(
  userId: number,
  assignmentId: number,
  assignmentActivityId: number,
  languageId: number
) {
  return AxiosHelper.axiosInstance().get(
    APIConstant.STUDENTASSIGNMENTACTIVITY_API.LastSession,
    {
      params: {
        userId: userId,
        assignmentId: assignmentId,
        assignmentActivityId: assignmentActivityId,
        languageId: languageId,
      },
    }
  );
}

export function validateAssignmentMaxAttempt(
  userId: number,
  assignmentActivityId: number
) {
  return AxiosHelper.axiosInstance().get(
    APIConstant.STUDENTASSIGNMENTACTIVITY_API.ValidateMaxAttempt,
    {
      params: {
        userId: userId,
        assignmentActivityId: assignmentActivityId,
      },
    }
  );
}

export function getAssignmentGameDetails(
  userId: number,
  sessionId: string
): Promise<any> {
  return AxiosHelper.axiosInstance().get<any>(
    APIConstant.STUDENTASSIGNMENTACTIVITY_API.GetGameDetails,
    {
      params: { userId: userId, sessionId: sessionId },
    }
  );
}

export function updateAssignmentGameScore(
  userId: number,
  sessionId: string,
  gameScore: number,
  gameLevel: number,
  gameSettings?: string
): Promise<AxiosResponse<number>> {
  return AxiosHelper.axiosInstance().put<number>(
    APIConstant.STUDENTASSIGNMENTACTIVITY_API.UpdateGameScore,
    {
      userId: userId,
      sessionId: sessionId,
      gameScore: gameScore,
      gameLevel: gameLevel,
      gameSettings: gameSettings,
    }
  );
}

export function getRetryEligibility(
  sessionId: string
): Promise<AxiosResponse<IActivitySessionRetryEligibilityDTO>> {
  return AxiosHelper.axiosInstance().get<IActivitySessionRetryEligibilityDTO>(
    APIConstant.ACTIVITYSESSIONS_API.GetRetryEligibility,
    {
      params: { sessionId: sessionId },
    }
  );
}

export function initializeRetry(
  sessionId: string,
  studentUserId: number,
  languageId: number
): Promise<AxiosResponse<IActivitySessionRetryResponse>> {
  return AxiosHelper.axiosInstance().post<IActivitySessionRetryResponse>(
    APIConstant.ACTIVITYSESSIONS_API.InitializeRetry,
    {
      sessionId: sessionId,
      studentUserId: studentUserId,
      languageId: languageId,
    }
  );
}

export function updateRetryRequested(
  sessionId: string,
  retryRequested: boolean
): Promise<AxiosResponse<number>> {
  return AxiosHelper.axiosInstance().put<number>(
    APIConstant.ACTIVITYSESSIONS_API.RetryRequested,
    {
      sessionId: sessionId,
      retryRequested: retryRequested,
    }
  );
}

export function retryCompleted(
  sessionId: string,
  activitySessionId: number
): Promise<AxiosResponse<number>> {
  return AxiosHelper.axiosInstance().put<number>(
    APIConstant.ACTIVITYSESSIONS_API.RetryCompleted,
    {
      sessionId: sessionId,
      activitySessionId: activitySessionId,
    }
  );
}

export function validateAssignment(userId: number, assignmentId: number) {
  return AxiosHelper.axiosInstance().get(
    APIConstant.STUDENTASSIGNMENTACTIVITY_API.ValidateAssignment,
    {
      params: {
        userId: userId,
        assignmentId: assignmentId,
      },
    }
  );
}
export function abandonAssignmentActivity(
  userId: number,
  sessionId: string
): Promise<AxiosResponse<number>> {
  return AxiosHelper.axiosInstance().put<number>(
    APIConstant.STUDENTASSIGNMENTACTIVITY_API.AbandonAssignmentActivity,
    {
      userId: userId,
      sessionId: sessionId,
    }
  );
}

export function updateDuration(
  activitySessionId: number,
  durationInSeconds: number
): Promise<AxiosResponse<number>> {
  return AxiosHelper.axiosInstance().put<number>(
    APIConstant.ACTIVITYSESSIONS_API.UpdateDuration,
    {
      activitySessionId: activitySessionId,
      durationInSeconds: durationInSeconds,
    }
  );
}

export function getQuestionsBySessionId(
  sessionId: string
): Promise<AxiosResponse<IActivitySessionQuestion[]>> {
  return AxiosHelper.axiosInstance().get<IActivitySessionQuestion[]>(
    APIConstant.ACTIVITYSESSIONS_API.GetQuestionsBySessionId,
    {
      params: { sessionId: sessionId },
    }
  );
}

export function getTeacherFeedback(
  activitySessionId: number,
  questionId: number
): Promise<AxiosResponse<IActivitySessionQuestionFeedback>> { 
  return AxiosHelper.axiosInstance().get<IActivitySessionQuestionFeedback>(
    APIConstant.ACTIVITYSESSIONS_API.TeacherFeedback,
    {
      params: { activitySessionId, questionId },
    }
  );
}

export function getTeacherFeedbacksBySessionId(
  sessionId: string
): Promise<AxiosResponse<IActivitySessionQuestionFeedback[]>> { 
  return AxiosHelper.axiosInstance().get<IActivitySessionQuestionFeedback[]>(
    APIConstant.ACTIVITYSESSIONS_API.TeacherFeedbacksBySessionId,
    {
      params: { sessionId },
    }
  );
}
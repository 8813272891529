import { AxiosHelper } from "../../environment/axiosConfig";
import BubbleSheetModel from "../../model/teacher/bubbleSheet/bubbleSheetModel";
import APIConstant from "../../utils/constant/apiConstant";
export const getClassesBubbleSheet = (
    userId?: number,
  ) => {
    return AxiosHelper.axiosInstance().get(APIConstant.CLASSMANAGEMENT_API.ClassList, {
      params: {
        userId: userId,
      },
    });
};

export const getStudentsBubbleSheet = (
    classId: number,
    pageNumber: number,
    pageSize: number
  ) => {
    return AxiosHelper.axiosInstance().get(APIConstant.CLASSMANAGEMENT_API.GetClassStudent, {
        params: {
            classId: classId,
            pageNumber: pageNumber,
            pageSize: pageSize,
          },
    });
};

export const downloadStudentsBubbleSheet = (
  students:Array<BubbleSheetModel>
) => {
  return AxiosHelper.axiosInstance().post(APIConstant.BubbleSheet_API.Download,
    students,
    {
    responseType: 'blob',
    params:{
      students:students
    },
    
  });
};

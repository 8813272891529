import { Fragment } from "react";
import { connect } from "react-redux";
import { ExportIcon, IdCardIcon, PrintIcon } from "../../../assets/icons/index";
import { UserContextState } from "../../../redux/actions/userContextAction";
import Constant from "../../../utils/constant/constant";

interface IAction {
  userContext?: UserContextState;
  onHandleSearch: Function;
  handlePasswordCardModal: Function;
  handlePrint: Function;
  handleExport: Function;
  handleReset: Function;
  mode: string;
}
const Action = (props: IAction) => {
  const handleSearch = (e) => {
    e.preventDefault();
    props.onHandleSearch();
  };

  return (
    <Fragment>
      <div className="flex mt-5 pt-5 text-left flex-col items-end lg:flex-row md:items-start justify-between border-t">
        <div className="flex flex-wrap flex-col sm:flex-row">
          <div className="flex flex-wrap gap-3 xl:mr-2 flex-col sm:flex-row ">
            {props.mode === Constant.ManageUserMode.Student &&
              props.userContext?.impersonatedUser === null && (
                <button
                  className="bg-transparent border border-primary-violet shadow-sm py-2 px-4 inline-flex justify-center items-center text-sm font-medium text-primary-violet hover:text-white hover:bg-light-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3 transition ease-in-out duration-500"
                  type="button"
                  onClick={() => {
                    props.handlePasswordCardModal(true);
                  }}
                >
                  <IdCardIcon className="w-4 opacity-70"></IdCardIcon>
                  <span className="ml-2 text-sm whitespace-nowrap">
                    Password Card
                  </span>
                </button>
              )}
            <button
              className="bg-transparent border border-primary-violet shadow-sm py-2 px-4 inline-flex justify-center items-center text-sm font-medium text-primary-violet hover:text-white hover:bg-light-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3 transition ease-in-out duration-500"
              type="button"
              onClick={() => {
                props.handlePrint();
              }}
            >
              <PrintIcon className="w-4 opacity-70"></PrintIcon>
              <span className="ml-2 text-sm">Print</span>
            </button>
            <button
              className="bg-transparent border border-primary-violet shadow-sm py-2 px-4 inline-flex justify-center items-center text-sm font-medium text-primary-violet hover:text-white hover:bg-light-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3 transition ease-in-out duration-500"
              type="button"
              onClick={() => {
                props.handleExport();
              }}
            >
              <ExportIcon className="w-4 opacity-70"></ExportIcon>
              <span className="ml-2 text-sm">Export</span>
            </button>
          </div>
        </div>
        <div className=" flex space-x-3 mt-3 lg:mt-0">
          <button
            className="bg-transparent border border-primary-violet shadow-sm py-2 px-4 inline-flex justify-center items-center text-sm font-medium text-primary-violet hover:text-white hover:bg-light-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3 transition ease-in-out duration-500"
            type="button"
            onClick={() => {
              props.handleReset();
            }}
          >
            <span className="text-sm">Reset</span>
          </button>
          <button
            className="bg-primary-violet border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-dark-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            type="button"
            onClick={(e) => handleSearch(e)}
          >
            Filter
          </button>
        </div>
      </div>
    </Fragment>
  );
};
const mapStateToProps = (state: any) => {
  return {
    masterData: state.masterData,
    userContext: state.userContext,
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(Action);

import XIcon from "@heroicons/react/outline/XIcon";
import React, { Fragment, useEffect, useState } from "react";
import { ClassApi } from "../../../api/teacher/classManagementApi";
import { connect } from "react-redux";
import Loader from "../../shared/loader";
import StudentClassListResponse from "../../../model/teacher/classManagement/studentClassListResponse";

interface StudentClassesProps {
  showPopUp: (show: boolean) => void;
  userId: number;
  name: string;
  userContext?: any;
}

const StudentClasses = (props: StudentClassesProps) => {
  const [loading, showLoading] = useState<boolean>(false);
  const [classes, setClasses] = useState<StudentClassListResponse[]>();

  useEffect(() => {
    getStudentClassList();
  }, []);

  function getStudentClassList() {
    showLoading(true);
    const response = ClassApi.getStudentClassList(props.userId);
    response
      ?.then((d) => {
        setClasses(d.data);
        showLoading(false);
      })
      .catch(() => {
        showLoading(false);
      });
  }

  return (
    <div className="fixed inset-0 z-40 flex items-center bg-black bg-opacity-50 sm:items-center sm:justify-center">
      <div
        className="w-full flex flex-col justify-between overflow-hidden h-auto max-h-80percent bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:max-w-5xl"
        role="dialog"
      >
        <header>
          {/* Header */}
          <div className="px-4 py-4 bg-gray-50 sm:px-4">
            <div className="flex items-start justify-between space-x-3">
              <div className="space-y-1">
                <div className="text-lg font-medium text-gray-900">
                  Classes - {props.name}
                </div>
              </div>
              <div className="h-7 flex items-center">
                <button
                  type="button"
                  className="text-gray-400 hover:text-gray-500"
                  onClick={() => props.showPopUp(false)}
                >
                  <span className="sr-only">Close panel</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </header>
        <div className="w-full relative overflow-auto px-4 py-3">
          <div className="flex-1">
            {/* <!-- Divider container -->  */}
            <div className="text-sm text-gray-700 px-4">
              <div>
                <div className="mb-4 pb-4 border-b-2 overflow-x-auto ">
                  <table className="table w-full relative">
                    <thead className="mb-5">
                      <tr className="bg-light-violet text-white py-3 px-3 overflow-hidden">
                        <th className="text-left p-3 text-sm font-normal w-48">
                          Class Name
                        </th>
                        <th className="text-left p-3 text-sm font-normal w-48">
                          Teacher
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-50 bg-white">
                      {loading && <Loader />}
                      {classes !== undefined &&
                        classes?.length > 0 &&
                        classes?.map((studentClass, index) => {
                          return (
                            <Fragment>
                              <tr
                                key={studentClass.classId}
                                className={`hover:bg-light-violet/20 transition ease-in-out duration-300 ${
                                  index % 2 === 0
                                    ? "bg-white"
                                    : "bg-light-violet/10"
                                }`}
                              >
                                <td className="px-3 py-2  pl-6 whitespace-nowrap text-sm text-left font-normal text-gray-500">
                                  {studentClass.className}
                                </td>
                                <td className="px-3 py-2  pl-6 whitespace-nowrap text-sm text-left font-normal text-gray-500">
                                  {studentClass.lastName},{" "}
                                  {studentClass.firstName}
                                </td>
                              </tr>
                            </Fragment>
                          );
                        })}
                      {classes !== undefined && classes?.length === 0 && (
                        <tr>
                          <td
                            colSpan={4}
                            className="px-3 py-2  pl-6 whitespace-nowrap text-sm text-left font-normal text-gray-500"
                          >
                            No record found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Action Buttons -->  */}
        <footer className="flex items-center mt-4 justify-end px-6 py-4 sm:flex-row bg-gray-50 dark:bg-gray-800">
          <div className="flex-shrink-0">
            <div className="space-x-3 flex justify-end">
              <button
                className="align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 rounded-lg text-sm text-gray-600 border-gray-300 border dark:text-gray-400 focus:outline-none active:bg-transparent hover:border-gray-500 focus:border-gray-500 active:text-gray-500 focus:shadow-outline-gray w-full sm:w-auto"
                type="button"
                onClick={() => {
                  props.showPopUp(false);
                }}
              >
                Close
              </button>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(StudentClasses);

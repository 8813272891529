import { XIcon } from "@heroicons/react/outline";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { ClassApi } from "../../../../../../api/teacher/classManagementApi";
import {
  EnglishIcon,
  MathIcon,
  ScienceIcon,
  SocialStudiesIcon,
} from "../../../../../../assets/icons";
import {
  GradeLevelSubject,
  IAvailavelClassSubject,
} from "../../../../../../model/teacher/classManagement/IAvailavelClassSubject";
import { UserContextState } from "../../../../../../redux/actions/userContextAction";
import ConfirmationDialog from "../../../../../shared/confirmationDialog";
import InfoMessage from "../../../../../shared/infoMessage";

interface SubjectCardProps {
  gradeLevelSubject: GradeLevelSubject;
  onSubjectSelect: Function;
}

interface SubjectPopupProps {
  showPopUp: (show: boolean) => void;
  classId: number;
  userContext?: UserContextState;
  selectedSubjectIds?: number[];
}

const SubjectPopup = (props: SubjectPopupProps) => {
  const [availableSubjects, setAvailableSubjects] = useState<
    IAvailavelClassSubject[]
  >([]);
  const [selectedSubject, setSelectedSubject] = useState<Array<number>>(
    props.selectedSubjectIds ?? []
  );
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [confirmationTitle, setConfirmationTitle] = useState("Confirm Action");
  const [confirmationMessage, setConfirmationMessage] =
    useState("Please confirm");
  const [isDisabled, setIsDisabled] = useState<boolean>(true);

  useEffect(() => {
    getAvailabelSubjects();
  }, []);
  function getAvailabelSubjects() {
    ClassApi.getAvailableClassSubject(
      props.classId,
      props.userContext?.schoolAccountId
    ).then((d) => {
      var filterdSubject: Array<IAvailavelClassSubject> = [];
      var hasAvailabelSubject: boolean = false;
      d.data.forEach((element) => {
        element.gradeLevelSubject.forEach((glSubject) => {
          if (glSubject.subjects.length > 0) {
            glSubject.subjects.forEach((subject) => {
              subject.isSelected =
                (props.selectedSubjectIds ?? []).indexOf(subject.subjectId) > -1
                  ? true
                  : false;
            });

            hasAvailabelSubject = true;
          }
        });

        if (hasAvailabelSubject) {
          filterdSubject.push(element);
        }
        hasAvailabelSubject = false;
      });
      setAvailableSubjects(filterdSubject);
    });
  }

  function selectSubject(subjectId: number) {
    const subjectIndex: number = selectedSubject.indexOf(subjectId);
    if (subjectIndex !== -1) {
      const filterdArray = selectedSubject.filter((r) => {
        return r !== subjectId;
      });
      setSelectedSubject(filterdArray);
      setIsDisabled(filterdArray.length === 0);
    } else {
      selectedSubject.push(subjectId);
      setSelectedSubject(selectedSubject);
      setIsDisabled(false);
    }
  }

  function showConfirmation(title: string, message: string) {
    setOpenConfirmation(true);
    setConfirmationTitle(title);
    setConfirmationMessage(message);
  }

  function confirmationOkClose() {
    addClassSubjectConfirm();
    deleteClassSubjectConfirm();
  }
  function confirmationCancelClose() {
    setOpenConfirmation(false);
  }

  function addClassSubject(): void {
    showConfirmation(
      "Please confirm",
      "Are you sure you want to add the selected subject(s) to the class?"
    );
  }
  function addClassSubjectConfirm(): void {
    ClassApi.addClassSubject(
      props.classId,
      selectedSubject,
      props.userContext?.userId === undefined ? 0 : props.userContext?.userId
    ).then(() => {
      confirmationCancelClose();
      toast.success("Subjects added successfully");
      props.showPopUp(false);
    });
  }

  function deleteClassSubjectConfirm(): void {
    const deleteSubjectIds = (props.selectedSubjectIds ?? []).filter((obj1) => {
      return !selectedSubject.some((obj2) => {
        return obj1 === obj2;
      });
    });

    if (deleteSubjectIds?.length > 0) {
      ClassApi.deleteClassSubject(
        props.classId,
        deleteSubjectIds.join(","),
        props.userContext?.userId === undefined ? 0 : props.userContext?.userId
      ).then(() => {
        confirmationCancelClose();
        toast.success("Subjects deleted successfully");
        props.showPopUp(false);
      });
    }
  }

  return (
    <div className="fixed inset-0 z-40 flex items-center bg-black bg-opacity-50 sm:items-center sm:justify-center ">
      <div
        className="w-full flex flex-col justify-between overflow-hidden h-auto max-h-80percent bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:w-9/12 md:w-full lg:w-9/12"
        role="dialog"
      >
        <header className="sticky">
          {/* Header */}
          <div className="px-4 py-4 bg-gray-50 sm:px-4">
            <div className="flex items-start justify-between space-x-3">
              <div className="space-y-1">
                <div className="text-lg font-medium text-gray-900">
                  Class Subject
                </div>
              </div>
              <div className="h-7 flex items-center">
                <button
                  type="button"
                  className="text-gray-400 hover:text-gray-500"
                  onClick={() => props.showPopUp(false)}
                >
                  <span className="sr-only">Close panel</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </header>
        <div className="w-full relative overflow-auto px-4 py-6">
          <div className="w-full">
            <InfoMessage message=" Select the subject(s) you would like associated with your class." />
            <div className="w-full">
              {availableSubjects.map((availableSubject) => (
                <div
                  className={classNames(
                    "flex flex-col text-white text-lg py-3 px-3",
                    availableSubject.gradeLevel === "Elementary School"
                      ? "bg-galaxy-green"
                      : availableSubject.gradeLevel === "Middle School"
                      ? "bg-light-blue"
                      : "bg-light-green"
                  )}
                >
                  <div>{availableSubject.gradeLevel}</div>
                  <div className="flex flex-col gap-y-4 md:flex-row flex-wrap space-x-0 md:gap-4">
                    {availableSubject.gradeLevelSubject.map((gl) => (
                      <div className="flex">
                        {" "}
                        <SubjectCard
                          gradeLevelSubject={gl}
                          onSubjectSelect={selectSubject}
                        />{" "}
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <footer className="flex flex-col items-center justify-end px-6 py-4 sm:flex-row bg-gray-50 dark:bg-gray-800">
          {/* <!-- Action Buttons -->  */}
          <div className="flex-shrink-0">
            <div className="space-x-3 flex justify-end">
              <button
                className="disabled:opacity-50 align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 rounded-lg text-sm text-white border border-transparent bg-primary-green focus:shadow-outline-purple w-full sm:w-auto"
                disabled={isDisabled}
                type="button"
                onClick={addClassSubject}
              >
                {" "}
                Add
              </button>
              <button
                className="align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 rounded-lg text-sm text-gray-600 border-gray-300 border dark:text-gray-400 focus:outline-none active:bg-transparent hover:border-gray-500 focus:border-gray-500 active:text-gray-500 focus:shadow-outline-gray w-full sm:w-auto"
                type="button"
                onClick={() => {
                  props.showPopUp(false);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </footer>
      </div>

      <ConfirmationDialog
        open={openConfirmation}
        confirmationTitle={confirmationTitle}
        confirmationMessage={confirmationMessage}
        onCancelClick={confirmationCancelClose}
        onOkClick={confirmationOkClose}
        className="fixed z-50 inset-0 overflow-y-auto"
      ></ConfirmationDialog>
    </div>
  );
};

const SubjectCard = (props: SubjectCardProps) => {
  return (
    <div className="bg-white w-full sm:w-80 p-2 mt-1 bg-white rounded-lg hover:shadow-lg transition ease-in-out duration-300">
      <dt className="flex items-center bg-gray-700 rounded-lg">
        <div className="flex items-center justify-center m-1.5 h-9 w-12 rounded-md bg-light-violet text-white">
          {props.gradeLevelSubject.contentAreaName === "Math" && (
            <MathIcon title="Math" />
          )}
          {props.gradeLevelSubject.contentAreaName === "English" && (
            <EnglishIcon title="English language arts" />
          )}
          {props.gradeLevelSubject.contentAreaName === "ELA" && (
            <EnglishIcon title="English language arts" />
          )}
          {props.gradeLevelSubject.contentAreaName === "Science" && (
            <ScienceIcon title="Science" />
          )}
          {props.gradeLevelSubject.contentAreaName === "Social Studies" && (
            <SocialStudiesIcon title="Social studies" />
          )}
        </div>
        <p className="text-lg w-full text-left leading-6 font-normal text-white">
          {props.gradeLevelSubject.contentAreaName}
        </p>
      </dt>
      <div className="divide-y divide-gray-200">
        {props.gradeLevelSubject.subjects.map((sub, i) => (
          <label
            key={i}
            onChange={() => {
              props.onSubjectSelect(sub.subjectId);
            }}
            className="flex justify-start min-h-[3rem] py-1 leading-none items-center text-gray-900 mr-auto text-sm font-medium bg-white px-2 hover:bg-gray-200 shadow cursor-pointer"
          >
            <label className="flex">
              <input
                type="checkbox"
                data-subjectId={sub.subjectId}
                className="h-4 w-4 mt-0.5 cursor-pointer text-primary-violet border-gray-300 focus:ring-primary-violet"
                defaultChecked={sub.isSelected}
              />
              <span className="pl-2 text-gray-500 hover:text-primary-violet transition ease-in-out duration-300">
                {sub.name}
              </span>
            </label>
          </label>
        ))}
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(SubjectPopup);

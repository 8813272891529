import { CSSProperties, useEffect, useState } from "react";
import { connect } from "react-redux";
import Select, { MultiValue, SingleValue } from "react-select";
import { getAssociatedSubjects } from "../../../api/teacher/assignment";
import { ISelectElement } from "../../../model/interface/selectElement";
import Loader from "../loader";
import arraySort from "array-sort";
import constant from "../../../utils/constant/constant";
import { getSchoolSubjectsBySchoolAccountId } from "../../../api/teacher/school";
import { Info } from "../../../assets/icons";
import Asterisk from "../asterisk";
import { ClassApi } from "../../../api/teacher/classManagementApi";
import { getContentArea } from "../../../api/contentArea/contentArea";

interface SubjectProps {
  infoTitle?: string;
  selectedItems: Array<ISelectElement>;
  setSelectedSubject: (selectedItems: Array<ISelectElement>) => void;
  selectedSchools?: Array<number>;
  selectedClass?: Array<ISelectElement>;
  isMulti?: boolean;
  isRequired?: boolean;
  subjectTypeId?: number;
  themeColor?: string;
  subjects?: Array<any>;
  hideAdditionalSubjects?: boolean;
  isDisabled?: boolean;
  schoolId?: number;
  placeholder?: string;
  subjectId?: number;
}
function Subject(props: SubjectProps & any) {
  const {
    infoTitle,
    selectedItems,
    selectedSchools,
    setSelectedSubject,
    selectedClass,
    placeholder,
  } = props;
  const [subjects, setSubjects] = useState<Array<any>>([]);
  const [showLoading, setShowLoading] = useState<boolean>(false);

  const groupStyles = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    fontSize: 14,
  };
  const groupBadgeStyles: CSSProperties = {
    backgroundColor: "#EBECF0",
    borderRadius: "2em",
    color: "#172B4D",
    display: "inline-block",
    fontSize: 14,
    fontWeight: "normal",
    lineHeight: "1",
    minWidth: 1,
    padding: "0.16666666666667em 0.5em",
    textAlign: "center",
  };
  const colourStyles = {
    option: (provided, state) => ({
      ...provided,
      color:
        state.label === "My Subjects" || state.label === "Additional Subjects"
          ? props.themeColor ?? "#992883"
          : "default",
    }),
  };

  const getSubjects = () => {
    setShowLoading(true);
    getAssociatedSubjects(props.userContext.userId, props.subjectTypeId).then(
      (response) => {
        setShowLoading(false);
        categorizeOptions(response);
      }
    );
  };

  const getSubjectByClass = () => {
    setShowLoading(true);
    const selectedClassIds = selectedClass
      .filter((c) => c.value != "0")
      .map((c) => c.value);

    ClassApi.getClassSubjectsByClassIds(selectedClassIds?.join(",")).then(
      async (response) => {
        setShowLoading(false);
        if (props.userContext.roleId === constant.UserRoleId.SchoolAdmin) {
          var subjectList = response;
          subjectList.data.map((subject: any, index) => {
            if (!subject.hasOwnProperty("isAdditionalSubject")) {
              subject.isAdditionalSubject = false;
            }
          });
          categorizeOptions(subjectList);
        } else {
          const options = await categorizeByContentArea(response.data);
          setSubjects(options);
        }
      }
    );
  };

  const fetchDistrictAdminSubjectsBySchool = () => {
    setShowLoading(true);
    getSchoolSubjectsBySchoolAccountId(selectedSchools).then(
      async (response) => {
        setShowLoading(false);

        let data =
          props.subjectTypeId && props.subjectTypeId > 0
            ? response.data?.filter(
                (x) => x.subjectTypeId === props.subjectTypeId
              )
            : response.data;
        const options = await categorizeByContentArea(data);
        setSubjects(options);
      }
    );
  };

  const fetchDistrictAdminSubjects = () => {
    setShowLoading(true);
    getAssociatedSubjects(
      props.userContext.userId,
      props.subjectTypeId,
      props.schoolId
    ).then(async (response) => {
      setShowLoading(false);
      const options = await categorizeByContentArea(response.data);
      setSubjects(options);
    });
  };

  const categorizeOptions = async (response: any) => {
    const results = response.data;
    const additionalSubjectRoles: Array<number> = [
      constant.UserRoleId.SchoolAdmin,
      constant.UserRoleId.SchoolTeacher,
    ];

    const showAdditionalSubject: boolean = additionalSubjectRoles.some(
      (r) => r === props.userContext.roleId
    );
    const mySubjects: Array<any> = [];
    const additionalSubjects: Array<any> = [];
    const additionalSubjectCount = results?.filter(
      (r) => r.isAdditionalSubject === true
    ).length;

    var contentAreas = await getContentArea();
    mySubjects.push({
      label: "My Subjects",
      disabled: true,
    });

    additionalSubjects.push({
      label: "Additional Subjects",
      disabled: true,
    });

    contentAreas.data.data?.map((contentArea) => {
      var subjectOptions = getContentAreaSubjects(
        results,
        false,
        contentArea.contentAreaId
      );
      mySubjects.push({
        label: contentArea.name,
        options: subjectOptions,
      });

      if (additionalSubjectCount > 0) {
        var additionalSubjectOptions = getContentAreaSubjects(
          results,
          true,
          contentArea.contentAreaId
        );
        additionalSubjects.push({
          label: contentArea.name,
          options: additionalSubjectOptions,
        });
      }
    });

    let subjectOptions = mySubjects;
    if (showAdditionalSubject && additionalSubjectCount > 0) {
      subjectOptions.push(...additionalSubjects);
    }

    setSubjects(subjectOptions);
  };

  const getContentAreaSubjects = (
    results: any,
    isAdditionalSubject: boolean,
    contentAreaId: number
  ) => {
    var subjects: Array<ISelectElement> = results
      ?.filter(
        (r) =>
          r.isAdditionalSubject === isAdditionalSubject &&
          r.contentAreaId === contentAreaId
      )
      .map((values: any) => {
        return {
          label: values?.name,
          value: values?.subjectId,
        };
      });

    return arraySort(subjects, "label");
  };

  const categorizeByContentArea = async (subjects) => {
    const options: Array<any> = [];
    var contentAreas = await getContentArea();
    contentAreas.data.data?.map((contentArea) => {
      var subjectOptions = subjects
        .filter((item) => item.contentAreaId === contentArea.contentAreaId)
        ?.map((values: any) => {
          return {
            label: values?.name,
            value: values?.id ?? values.subjectId,
          };
        });
      options.push({
        label: contentArea.name,
        options: arraySort(subjectOptions, "label"),
      });
    });

    return options;
  };

  function onChange(options: MultiValue<ISelectElement> | SingleValue<ISelectElement> | null) {
    if(!options) return;

    let selectedValues: Array<ISelectElement>;

    if (Array.isArray(options)) {
      // It's a multi-value (an array), so we can use map
      selectedValues = options.map(option => option);
    } else {
      // It's a single value (not an array), so we treat it as a single ISelectElement      
      selectedValues = [options as ISelectElement];
    }
    
    if (selectedValues && selectedValues.length > 0) {
      const lastSelectedValue = selectedValues[selectedValues.length - 1];
      if (lastSelectedValue.value === "") {
        selectedValues = [lastSelectedValue];
      } else if (
        selectedValues.some((r) => r.value === "") &&
        selectedValues.some((r) => r.value !== "")
      ) {
        selectedValues = selectedValues.filter((r) => r.value !== "");
      }
    } else {
      if (
        window.location.href.includes("progressreport") ||
        window.location.href.includes("livemonitoringreport")
      ) {
        selectedValues = [{ label: "Select Subject", value: "0" }];
      } else {
        selectedValues = [{ label: "All Subjects", value: "" }];
      }
    }
    setSelectedSubject(selectedValues);
  }

  const formatGroupLabel = (data: any) => {
    return (
      <div className="bg-gray-200 -mx-3" style={groupStyles}>
        <span className="text-secondary-teal pl-2">{data.label}</span>
        <span style={groupBadgeStyles}>{data.options.length}</span>
      </div>
    );
  };

  useEffect(() => {
    if (
      selectedClass &&
      selectedClass?.length > 0 &&
      selectedClass[0].value != "0"
    ) {
      getSubjectByClass();
    } else if (props.subjects) {
      categorizeOptions(props.subjects);
    } else {
      if (props.userContext.roleId === constant.UserRoleId.District) {
        selectedSchools && selectedSchools.length > 0
          ? fetchDistrictAdminSubjectsBySchool()
          : fetchDistrictAdminSubjects();
      } else {
        getSubjects();
      }
    }
  }, [selectedSchools, selectedClass, props.subjects, props.schoolId]);

  useEffect(() => {
    if (props.subjectId && subjects && subjects.length > 0) {
      const selectedSubject = subjects.find((subject) => {
        return subject.options && subject.options.some((s) => s.value === props.subjectId);
      });
  
      if (selectedSubject) {
        const selectedOption = selectedSubject.options.find((s) => s.value === props.subjectId);
        setSelectedSubject(selectedOption ? [selectedOption] : []);
      }
    }
  }, [props.subjectId, subjects]);
  

  return (
    <>
      {showLoading && <Loader></Loader>}
      <div className="nested-optgroup">
        <div>
          <label className="block text-sm text-gray-500">
            Subject {props.isRequired && <Asterisk />}
          </label>
          {infoTitle && <Info title={infoTitle} />}
        </div>
        <div className="mt-1">
          <Select
            value={selectedItems}
            options={subjects}
            isClearable
            isSearchable
            formatGroupLabel={formatGroupLabel}
            formatOptionLabel={(value) => {
              return value.label;
            }}
            getOptionValue={(value) => {
              return value.label;
            }}
            onChange={onChange}
            isMulti={props.isMulti ?? true}
            isOptionDisabled={(option) => option.disabled}
            closeMenuOnSelect={!(props.isMulti ?? true)}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary: props.themeColor ?? "#041E42",
              },
            })}
            styles={colourStyles}
            isDisabled={props.isDisabled ?? false}
            placeholder={placeholder}
          />
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(Subject);

import { useEffect, useState } from "react";
import GameSetup from "../../../../../assets/images/thinkorswim/GameSetup.jpg";
import TOSGameSetupCreateTeams from "./tosGameSetupCreateTeams";
import TOSGameSetupPickContent from "./tosGameSetupPickContent";
import { ISelectedStandard } from "../../../../../model/interface/classroomGame/thinkOrSwimSetup";
import TOSGameSetupInstructions from "./tosGameSetupInstructions";
import TextBubble from "./textBubble";
import { GetGameSession, GetGameSessionStandards, GetGameSessionTeamNames, GetTeamNames, TOSScreens, setupAction } from "../../../../../api/classroomGame/thinkOrSwim";
import { toast } from "react-toastify";
import Loader from "../../../../shared/loader";

interface ITOSGameSetupProps {
  setupScreenAction: setupAction,
  selectedClassroomGameSessionId: number,
  setSelectedScreen: (screen: TOSScreens) => void,
}

const ThinkOrSwimGameId = 1;

type setupTabs = "CreateTeams" | "PickContent" | "Instructions";

function TOSGameSetup(props: ITOSGameSetupProps) {
  const {
    setupScreenAction,
    selectedClassroomGameSessionId,
    setSelectedScreen,
  } = props;

  const [selectedTab, setSelectedTab] = useState<setupTabs>("CreateTeams");
  const [sessionName, setSessionName] = useState<string>("");
  const [teamNames, setTeamNames] = useState<string[]>([]);
  const [selectedSubject, setSelectedSubject] = useState<Array<{label: string, value: number }>>([]);
  const [selectedStandards, setSelectedStandards] = useState<ISelectedStandard[]>([]);
  const [includeReefRecess, setIncludeReefRecess] = useState<boolean>(true);
  const [showLoading, setShowLoading] = useState<boolean>(false);

  const createNewSession = () => {
    setShowLoading(true);
    GetTeamNames(ThinkOrSwimGameId, 5)
      .then(response => {
        setShowLoading(false);
        const teamNames: Array<string> = response.data.teamNames;
        setTeamNames(teamNames);
      })
      .catch(error => {
        setShowLoading(false);
        toast.error(`${error.message} Cannot get Team Names`)
      });
    setSelectedTab("CreateTeams");
  } 

  const loadSessionTeamNames = (classroomGameSessionId: number) => {
    GetGameSessionTeamNames(classroomGameSessionId)
    .then(response => {
      setShowLoading(false);
      const teamNames = response.data.teamNames.map(t => t.name);
      setTeamNames(teamNames);
    })
    .catch(error => {
      setShowLoading(false);
      toast.error(`${error.message} Cannot duplicate Session TeamNames`);
    })
  }

  const loadSessionStandards = (classroomGameId: number, classroomGameSessionId: number) => {
    GetGameSessionStandards(classroomGameId, classroomGameSessionId)
    .then(standardsResponse => {
      const standards = standardsResponse.data.standards;
      setSelectedStandards(standards);
      loadSessionTeamNames(classroomGameSessionId);
    })
    .catch(error => {
      setShowLoading(false);
      toast.error(`${error.message} Cannot duplicate Session Standards`);
    })
  }

  const loadSession = () => {
    setShowLoading(true);
    GetGameSession(selectedClassroomGameSessionId, ThinkOrSwimGameId)
    .then(sessionResponse => {
      const session = sessionResponse.data;
      setSessionName(
        session.name + 
        (setupScreenAction  === "Duplicate" ? " - Duplicate" : "")
      );
      setSelectedSubject([{label: session.subjectName, value: session.subjectId}]);
      setIncludeReefRecess(session.includeMiniBreaks);
      loadSessionStandards(session.classroomGameId, session.classroomGameSessionId);
    })
    .catch(error => {
      setShowLoading(false);
      toast.error(`${error.message} Cannot duplicate Session`);
    })
  }

  useEffect(() => {
    switch (setupScreenAction) {
      case "Create":
        createNewSession();
        break;

      case "Duplicate":
      case "Edit":
        loadSession();
        break;
    }
    
  }, [setupScreenAction])

  const activeTabStyle = "border-secondary-teal bg-white text-primary-violet hover:text-dark-violet py-3 tab-box-shadow tab-box-shadow font-medium  px-2 sm:px-4 text-center border-t-2 text-sm cursor-pointer";
  const inactiveTabStyle = "text-primary-violet text-opacity-60 bg-primary-violet/10 py-[0.6rem] mt-auto transition-all hover:py-3 hover:text-opacity-100 hover:text-primary-violet border-[#dee9f1] font-medium px-2 sm:px-4 text-center border-t-2 text-sm cursor-pointer";

  return (
    <div>
      {showLoading && (<Loader />)}
      <div className="relative overflow-hidden">
        <div className="absolute w-full grid grid-cols-1">
          <div className="relative top-[26%] left-[31%] w-[45%]">
            <TextBubble
              text="Welcome to Think or Swim! This is a team-based, quiz-show style game that is best played on a screen in the classroom. Teachers are the hosts and will conduct this game for each team."
              textSizeCSSClass="text-[1.2vw]"
              isBubblePointingToLeft={false}
            />
          </div>
        </div>
        <img
          className="w-full"
          src={GameSetup}
          alt='Game Setup'
        />
      </div>
      <div className="p-4 pt-0" style={{backgroundImage: "linear-gradient(rgba(76,150,235,1), rgba(43,69,205,1))"}}>
        <div className="bg-white rounded-md w-full p-4">
          <nav className="grid grid-cols-2 md:grid-cols-none md:grid-rows-none md:flex md:space-x-2 cursor-not-allowed">
            <button 
              type="button"
              className={selectedTab === "CreateTeams" ? activeTabStyle : inactiveTabStyle}
              onClick={() => setSelectedTab("CreateTeams")}
            >
              1. Create Teams
            </button>
            <button 
              type="button"
              className={selectedTab === "PickContent" ? activeTabStyle : inactiveTabStyle}
              onClick={() => setSelectedTab("PickContent")}
              disabled={selectedTab === "CreateTeams"}
            >
              2. Pick Content
            </button>
            <button 
              type="button"
              className={selectedTab === "Instructions" ? activeTabStyle : inactiveTabStyle}
              onClick={() => setSelectedTab("Instructions")}
              disabled={selectedTab === "PickContent" || selectedTab === "CreateTeams"}
            >
              3. Instructions
            </button>
          </nav>
          {selectedTab === "CreateTeams" && (
            <TOSGameSetupCreateTeams
              sessionName={sessionName}
              setSessionName={setSessionName}
              teamNames={teamNames}
              setTeamNames={setTeamNames}
              onContinue={() => setSelectedTab("PickContent")} 
            />
          )}
          {selectedTab === "PickContent" && (
            <TOSGameSetupPickContent 
              selectedSubject={selectedSubject}
              setSelectedSubject={setSelectedSubject}
              selectedStandards={selectedStandards}
              setSelectedStandards={setSelectedStandards} 
              onContinue={() => setSelectedTab("Instructions")} 
            />
          )}
          {selectedTab === "Instructions" && (
            <TOSGameSetupInstructions 
              sessionName={sessionName}
              teamNames={teamNames}
              selectedSubject={selectedSubject}
              selectedStandards={selectedStandards}
              setupScreenAction={setupScreenAction}
              includeReefRecess={includeReefRecess}
              setIncludeReefRecess={setIncludeReefRecess}
              selectedClassroomGameSessionId={selectedClassroomGameSessionId}
              onSave={() => setSelectedScreen("Sessions")}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default TOSGameSetup

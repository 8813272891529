import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import ReactTooltip from "react-tooltip";
import { ClassApi } from "../../../../../api/teacher/classManagementApi";
import { PageAccessMode } from "../../../../../model/common/pageAccessMode";
import ClassModel from "../../../../../model/teacher/classManagement/classModel";
import { UserContextState } from "../../../../../redux/actions/userContextAction";
import Constant from "../../../../../utils/constant/constant";
import RouteConstant from "../../../../../utils/constant/routeConstant";
import Permissions from "../../../../../utils/permissions";
import AccessDenied from "../../../../shared/accessDenied";
import Loader from "../../../../shared/loader";
import AllowedTo from "../../../../shared/rbac";
import Breadcrumb from "../../shared/breadcrumb";
import { IBreadcrumb } from "../../../../../model/common/breadcrumb";
import { useTranslation } from "react-i18next";
import auth from "../../../../../utils/auth";
import Profile from "../../../../../model/users/profile";
import { ISelectElement } from "../../../../../model/interface/selectElement";
import { GetSchoolYears } from "../../../../../api/teacher/school";
import { ISchoolYear } from "../../../../../model/teacher/schoolYear";
import SkipClassSetupDialog  from "../../../../shared/skipClassSetupDialog";
import { IAvailavelClassSubject } from "../../../../../model/teacher/classManagement/IAvailavelClassSubject";
import { DEFAULT_PAGE_NUMBER, DEFAULT_PAGE_SIZE } from "../../../../../utils/pagingConstant";
import PagingResponse from "../../../../../model/common/pagingResponse";
import ClassStudentModel from "../../../../../model/teacher/classManagement/classStudentModel";
import { getAllGrades } from "../../../../../api/gradeApi";
import { getNameLabel } from "../../../../../utils/helper";
import ClassTeacherModel from "../../../../../model/teacher/classManagement/classTeacherModel";
import ClassBuilderClassDetails from "./classBuilderClassDetails";
import ClassBuilderSubjects from "./classBuilderSubjects";
import ClassBuilderStudents from "./classBuilderStudents";
import ClassBuilderTeachers from "./classBuilderTeachers";
import moment from "moment";

interface ClassBuilderProps {
  userContext: UserContextState;
  profile: Profile;
  match?: any;
  location: any;
}

const ClassBuilder = (props: ClassBuilderProps & any) => {  

  let classId: any = props.match.params.classId;  
  const [defaultSchoolYearId, setDefaultSchoolYearId] = useState<number>(1);
  const classModel: ClassModel = {
    userId:
      props.userContext?.userId === undefined ? 0 : props.userContext?.userId,
    classId: classId,
    schoolId:
      props.userContext?.schoolId === undefined
        ? 0
        : props.userContext?.schoolId,
    colorCode: "",
    avatarLockDuration: 0,
    displayName: "",
    endTime: "",
    gameLockDuration: 0,
    isActive: true,
    isArchived: false,
    isAvatarLocked: false,
    isGameLocked: false,
    name: "",
    passwordHash: "",
    startTime: "",
    avatarLockStartTime: "",
    avatarLockEndTime: "",
    studentCount: 0,
    teacherCount: 0,
    subjectCount: 0,
    isLiftOffEnabled: false,
    isHidden: false,
    classExternalRoster: [],
    schoolAccountId: 0,
    higherGradeStudentCount: 0,
    googleId: "",
    canvasId: "",
    isGoogleEnabled: false,
    googleClassId: 0,
    courseId: "",
    googleAccessToken: "",
    schoolCanvasAccountId: "",
    canvasCourseId: "",
    isCanvasEnabled: false,
    baseEndPointUrl: "",
    canvasAccessToken: "",
    schoolYearId: defaultSchoolYearId,
  };
  const history = useHistory();
  const { t } = useTranslation();
  const [classDetail, setClassDetail] = useState<ClassModel>(classModel);  
  const [showLoader, setShowLoader] = useState(false);
  const [mode, setMode] = useState<PageAccessMode>(PageAccessMode.None);  
  const [selectedColor, setSelectedColor] = useState<string>("");
  const [schoolYears, setSchoolYears] = useState<Array<ISelectElement>>();
  const [schoolYearData, setSchoolYearData] = useState<Array<ISchoolYear>>();
  const [selectedSchoolYearId, setSelectedSchoolYearId] = useState<number>(0);
  
  const [activeTab, setActiveTab] = useState("");
  const [isDetailsValid, setIsDetailsValid] = useState(false);
  const [availableSubjects, setAvailableSubjects] = useState<
    IAvailavelClassSubject[]
  >([]);

  const [selectedSubject, setSelectedSubject] = useState<Array<number>>([]);
  const [studentStartWith, setStudentStartWith] = useState<string>("All");
  const [teacherStartWith, setTeacherStartWith] = useState<string>("View All");
  const [studentName, setStudentName] = useState<string>("");
  const [studentPageNumber, setStudentPageNumber] = useState(DEFAULT_PAGE_NUMBER);
  const [studentPageSize, setStudentPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [teacherPageNumber, setTeacherPageNumber] = useState(DEFAULT_PAGE_NUMBER);
  const [teacherPageSize, setTeacherPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [selectedGrade, setSelectedGrade] = useState<number>(0);
  const [selectStudentToAdd, setSelectStudentToAdd] = useState<Array<number>>(
    []
  );

  const [students, setStudents] = useState<PagingResponse<ClassStudentModel>>();
  const [gradeList, setGradeList] = useState<any>([]);
  const defaultGrade = [{ gradeId: 0, name: "All" }];
  const [selectAll, setSelectAll] = useState(false);
  const [resetStudentPageNumber, setStudentResetPageNumber] = useState<boolean>(false);
  const [resetTeacherPageNumber, setTeacherResetPageNumber] = useState<boolean>(false);
  const [invalidDates, setInvalidDates] = useState<boolean>(false);

  const filterCharList = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
    "All",
  ];

  const [schoolTeachers, setSchoolTeachers] =
    useState<PagingResponse<ClassTeacherModel>>();

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm<ClassModel>();

  const watchFields = watch(['name', 'startTime', 'endTime']);

  const tabs = ['DETAILS', 'SUBJECTS', 'STUDENTS', 'TEACHERS'];

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  

  useEffect(() => {
    GetSchoolYears(props?.userContext?.schoolId!).then((response) => {
      if (response.data) {
        var currentfutureschoolyears =
          classId !== undefined && classId === "new"
            ? response.data.filter((y) => y.currentYearOffset >= 0)
            : response.data;
        setSchoolYearData(currentfutureschoolyears);
        var schoolyears: Array<ISelectElement> = currentfutureschoolyears.map(
          (d: ISchoolYear) => {
            return {
              label: d.name,
              value: d.schoolYearId.toString(),
            };
          }
        );
        setSchoolYears(schoolyears);
      }
    });
  }, []);

  useEffect(() => {
    if (schoolYearData && schoolYearData?.length > 0) {
      var currentSchoolYear: ISchoolYear = schoolYearData.filter(
        (x) => x.currentYearOffset === 0
      )[0];
      setDefaultSchoolYearId(currentSchoolYear.schoolYearId);
      if (classId !== undefined && classId === "new") {
        setSelectedSchoolYearId(currentSchoolYear.schoolYearId);
      }
    }
  }, [schoolYearData]);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === "name") {
        if (
          value.name !== "" &&
          value.name !== undefined &&
          value.name !== null
        ) {
          setValue("displayName", getValues("name"));
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [watch("name")]);
  
  function handleSchoolYearChange(e) {
    setSelectedSchoolYearId(e.value);
  }

  const onSubmit = async (data: ClassModel): Promise<number> => {
    classDetail.userId = props.userContext.userId;
    classDetail.name = data.name;
    classDetail.displayName = data.displayName;
    classDetail.startTime =
      data.startTime === undefined ? classDetail.startTime : data.startTime;
    classDetail.endTime =
      data.endTime === undefined ? classDetail.endTime : data.endTime;
    classDetail.colorCode =
      selectedColor === undefined ? classDetail.colorCode : selectedColor;
    classDetail.schoolAccountId = props.userContext?.schoolAccountId;
    classDetail.isGoogleEnabled = false;
    classDetail.googleId = props.userContext?.googleId;
    classDetail.canvasId = props.profile?.canvasId;
    classDetail.googleAccessToken = auth.getGoogleAccessToken();
    classDetail.isCanvasEnabled = false;
    classDetail.schoolCanvasAccountId = props.profile?.schoolCanvasAccountId;
    classDetail.baseEndPointUrl = auth.getCanvasAccountSettings()?.endPointURL;
    classDetail.canvasAccessToken = auth.getCanvasAccessToken();
    classDetail.schoolYearId = selectedSchoolYearId ?? defaultSchoolYearId;

    setClassDetail(classDetail);
    const classId = await saveAll(classDetail);
    return classId;
  };

  const addClassSubjectConfirm = async(classId: number): Promise<void> => {
    await ClassApi.addClassSubject(
      classId,
      selectedSubject,
      props.userContext?.userId === undefined ? 0 : props.userContext?.userId
    )
  }

  const addClassStudentsConfirm = async(classId: number): Promise<void> => {    
    await ClassApi.addClassStudent(
      props.userContext?.userId,
      classId,
      selectStudentToAdd,
      0,
      false,
      false,
    )
  }

  const addClassTeachersConfirm = async(classId: number): Promise<void> => {
    const selectedTeachers = getSelectedTeachers();
    await ClassApi.addClassTeacher(
      props.userContext?.userId,
      classId,
      selectedTeachers
    )
  }

  const saveAll = async (classDetail: ClassModel): Promise<number> => {
    try {

      setShowLoader(true);
      // Step 1: Save Class Details
      const classId = await addNewClass(classDetail);

      // Set the classId to the classDetail object
      setClassDetail({ ...classDetail, classId });
  
      // Step 2: Add Subjects if any are selected
      if (selectedSubject.length > 0) {
        await addClassSubjectConfirm(classId);
      }
  
      // Step 3: Add Students if any are selected
      if (selectStudentToAdd.length > 0) {
        await addClassStudentsConfirm(classId);
      }
  
      // Step 4: Add Teachers if any are selected
      const selectedTeachers = getSelectedTeachers();
      if (selectedTeachers.length > 0) {
        await addClassTeachersConfirm(classId);
      } 

      return classId;
      
    } catch (error) {
      setShowLoader(false);
      toast.error("Failed to save class details.");
    }

    return 0;
  };  

  useEffect(() => {
    setMode(PageAccessMode.Add);
    setActiveTab(Constant.ActiveTabClassBuilder.DETAILS);    
  }, [classId, props.match.params.classId]);

  useEffect(() => {
    validateDetailsTab();
  }, [watchFields]);  

  useEffect(() => {
    getAvailabelSubjects();
    getGrades();
    getSchoolStudent();
  }, []);

  useEffect(() => {    
    getSchoolStudent();
  }, [studentPageNumber, studentPageSize, selectedGrade]);

  useEffect(() => {
    getSchoolTeacher();    
  }, [teacherPageNumber, teacherPageSize]);

  const addNewClass = async (classDetail: ClassModel): Promise<number> => {    
    try {
      const response = await ClassApi.addClass(classDetail);      
      const classId = response.data;
      setClassDetail({ ...classDetail, classId });
      return classId;
    } catch (error) {      
      throw error;
    }
  };  

  function redirectToClassList(): void {
    history.push({
      pathname: RouteConstant.TeacherRoutes.Classes,
    });

    window.location.reload(); // Force a reaload to refresh the class list
  }

  function redirectToClassDetails(classId: number): void {
    history.push({
      pathname: RouteConstant.TeacherRoutes.ClassesDetails.replace(
        ":classId",
        classId.toString()
      ),
    });
  }

  const breadcrumbItems = () => {
    const items: Array<IBreadcrumb> = [
      { name: t("Breadcrumb.Home"), url: RouteConstant.ROUTE_DASHBOARD },
      {
        name: t("Breadcrumb.ClassManagement"),
        url: RouteConstant.TeacherRoutes.Classes,
      },
      { name: mode === PageAccessMode.Add ? t("Breadcrumb.ClassBuilder") : t("Breadcrumb.ClassDetails") , url: "" },
    ];

    return items;
  };

  const handleSwitchTab = (tab) => {
    if (tab !== 'DETAILS' && !isDetailsValid) {
      return;
    }
    setActiveTab(tab);
  };

  const validateDetailsTab = () => {
    const { name, startTime, endTime } = getValues();
    const isValid = name?.trim() !== '' && startTime?.trim() !== '' && endTime?.trim() !== '';
    const start = moment(startTime, 'HH:mm');
    const end = moment(endTime, 'HH:mm');
    const validDates = start.isBefore(end);
    setInvalidDates(isValid && !validDates);
    setIsDetailsValid(isValid && validDates);
  };
  

  const handleSaveAndContinue = () => {    
    const currentIndex = tabs.indexOf(activeTab);
    if (currentIndex < tabs.length - 1) {
      setActiveTab(tabs[currentIndex + 1]);
    }
  };

  const handleSkipClassSetup = () => {
    setIsDialogOpen(true);
  };

  const handleSaveAndExit = () => {
    // Check if dates are valid
    const { startTime, endTime } = getValues();
    const start = moment(startTime, 'HH:mm');
    const end = moment(endTime, 'HH:mm');
    const validDates = start.isBefore(end);
    if(!validDates) {
      setInvalidDates(!validDates);
      toast.warning("End Time cannot be before Start Time.");
      return;
    }    

    // Save the form data
    handleSubmit(onSubmit)();
    redirectToClassList();
  };

  const finalSubmit = async () => {
    const formData = getValues();
    const classId = await onSubmit(formData);
    redirectToClassDetails(classId);
  };

  const handleDiscardChanges = () => {
    redirectToClassList();
  };

  function getAvailabelSubjects() {
    ClassApi.getAvailableClassSubject(
      props.classId,
      props.userContext?.schoolAccountId
    ).then((d) => {
      var filterdSubject: Array<IAvailavelClassSubject> = [];
      var hasAvailabelSubject: boolean = false;
      d.data.forEach((element) => {
        element.gradeLevelSubject.forEach((glSubject) => {
          if (glSubject.subjects.length > 0) {
            glSubject.subjects.forEach((subject) => {
              subject.isSelected =
                (props.selectedSubjectIds ?? []).indexOf(subject.subjectId) > -1
                  ? true
                  : false;
            });

            hasAvailabelSubject = true;
          }
        });

        if (hasAvailabelSubject) {
          filterdSubject.push(element);
        }
        hasAvailabelSubject = false;
      });
      setAvailableSubjects(filterdSubject);
    });
  }

  function selectSubject(subjectId: number) {
    const subjectIndex: number = selectedSubject.indexOf(subjectId);
    if (subjectIndex !== -1) {
      const filterdArray = selectedSubject.filter((r) => {
        return r !== subjectId;
      });
      setSelectedSubject(filterdArray);      
    } else {
      selectedSubject.push(subjectId);
      setSelectedSubject(selectedSubject);      
    }
  }

  const getTabName = (tab: string) => {
    switch (tab) {
      case 'DETAILS':
        return 'CLASS DETAILS';
      case 'SUBJECTS':
        return 'SUBJECTS';
      case 'STUDENTS':
        return 'STUDENTS';
      case 'TEACHERS':
        return 'TEACHERS';
    }
  }

  const getPreviousTab = (tab: string) => {
    switch (tab) {
      case 'DETAILS':
        return 'DETAILS';
      case 'SUBJECTS':
        return 'DETAILS';
      case 'STUDENTS':
        return 'SUBJECTS';
      case 'TEACHERS':
        return 'STUDENTS';
    }    
  }

  function handleStudentCharFilter(char: string) {
    if (char === "All") {
      setStudentName("");
    }
    setStudentStartWith(char);
    getSchoolStudent(char);
  }

  function handleTeacherCharFilter(char: string) {
    setTeacherStartWith(char);
    getSchoolTeacher(char);
  }

  function getSchoolStudent(charToSearch?: string): void {
    ClassApi.getClassPendingStudent(
      props.classId,
      studentPageNumber,
      studentPageSize,
      props.userContext?.schoolAccountId,
      charToSearch ? charToSearch : studentStartWith,
      selectedGrade,
      charToSearch === "All" ? "" : studentName,
      props.userContext?.userId
    ).then((d) => {
      let students = d.data.data?.map((student, index) => {
        if (selectStudentToAdd.indexOf(student.userId) > -1) {
          student.isSelected = true;
        }
        return student;
      });
      d.data.data = students;
      setStudents(d.data);
    });
  }

  function getGrades(): void {
    getAllGrades().then((d: any) => {
      setGradeList(defaultGrade.concat(d.data));
    });
  }

  function selectStudent(userId: any) {
    let currentStudents: any = {};
    Object.assign(currentStudents, students);
    students?.data.map((student) => {
      if (student.userId === userId) {
        student.isSelected = !student.isSelected;
      }
      return null;
    });

    setStudents(currentStudents);
    setSelectAll(false);

    if (selectStudentToAdd.includes(userId)) {
      setSelectStudentToAdd(selectStudentToAdd.filter((ds) => ds !== userId));
    } else {
      const arr = selectStudentToAdd;
      arr.push(userId);
    }
  }

  function searchStudents(): void {
    setStudentPageNumber(DEFAULT_PAGE_NUMBER);
    setStudentPageSize(DEFAULT_PAGE_SIZE);
    getSchoolStudent();
  }

  function selectAllStudent(e, students: Array<any>): void {
    setSelectAll(!selectAll);
    if (e.target.checked) {
      let arr = students.map((s) => s.userId) || [];
      setSelectStudentToAdd(arr);
    }
  }

  const onStudentPageSizeChange = (pageSize) => {
    setStudentPageNumber(DEFAULT_PAGE_NUMBER);
    setStudentPageSize(pageSize);
    setStudentResetPageNumber(true);
  };

  const onTeacherPageSizeChange = (pageSize) => {
    setTeacherPageNumber(DEFAULT_PAGE_NUMBER);
    setTeacherPageSize(pageSize);
    setTeacherResetPageNumber(true);
  };

  function selectAllteacher(): void {
    if (schoolTeachers?.data) {
      const updatedTeachers = schoolTeachers.data.map((teacher) => ({
        ...teacher,
        isSelected: !selectAll,
      }));
      setSchoolTeachers({
        ...schoolTeachers,
        data: updatedTeachers,
      });
    }
    setSelectAll(!selectAll);
  }

  function selectTeacher(userId: number) {
    if (schoolTeachers?.data) {
      const updatedTeachers = schoolTeachers.data.map((teacher) =>
        teacher.userId === userId
          ? { ...teacher, isSelected: !teacher.isSelected }
          : teacher
      );
      setSchoolTeachers({
        ...schoolTeachers,
        data: updatedTeachers,
      });
    }
  }
  

  function getSchoolTeacher(charToSearch?: string): void {
    ClassApi.getClassPendingTeacher(
      props.classId,
      teacherPageNumber,
      teacherPageSize,
      props.userContext?.schoolAccountId,
      charToSearch ? charToSearch : teacherStartWith,
      props.userContext?.userId
    ).then((d) => {
      setSchoolTeachers(d.data);
    });
  }

  function getSelectedTeachers() {
    if (schoolTeachers?.data) {
      const SelectedTeachers = schoolTeachers?.data?.filter((e) => {
        return e.isSelected === true;
      });
      return SelectedTeachers.map((e) => e.userId);
    }
    return [];
  }

  return (
    <AllowedTo
      perform={[
        Permissions.class_management_create,
        Permissions.class_management_modify,
      ]}
      no={() => <AccessDenied />}
    >
      {showLoader && <Loader></Loader>}

      <section className="min-w-0 w-full">       
          <div className="lg:flex lg:items-center px-4 py-3 lg:px-8 border-b">
            <Breadcrumb items={breadcrumbItems()} />
            <div className="lg:w-[55%] text-lg font-medium flex gap-2 h-auto sm:h-10 items-center">
              <h1 className="text-primary-violet font-medium flex justify-start mb-0 pb-0">
                Class Builder
              </h1>
            </div>
          </div>

          <div className="lg:border-b lg:border-gray-200 ">
          <nav className="px-4 sm:pl-6 lg:pl-8 lg:pr-0" aria-label="Progress">
            <ol className="rounded-md overflow-hidden lg:flex lg:border-l lg:border-gray-200 lg:rounded-none">
              {tabs.map((tab, index) => (
                <li
                  key={tab}
                  className={`relative overflow-hidden lg:flex-1 ${activeTab === tab ? "bg-primary-violet" : ""}`}
                  onClick={() => handleSwitchTab(tab)}
                  style={{ cursor: tab === Constant.ActiveTabClassBuilder.DETAILS || isDetailsValid ? 'pointer' : 'not-allowed' }}
                >
                  <div className={`${activeTab === tab ? "" : "border border-white"} border border-b-0 border-gray-200 overflow-hidden lg:border-0`}>
                    <a href="#!" aria-current="step">
                      <span className={`absolute top-0 left-0 w-1 h-full ${activeTab === tab ? "bg-primary-violet" : "bg-transparent group-hover:bg-gray-200"} lg:w-full lg:h-0.5 lg:bottom-0 lg:top-auto`} aria-hidden="true"></span>
                      <span className="px-6 py-3 flex items-center text-sm font-medium lg:pl-9">
                        <span className="flex-shrink-0">
                          <span className={`w-10 h-10 flex items-center justify-center border-2 ${activeTab === tab ? "border-white" : "border-gray-300"} rounded-full`}>
                            <span className={activeTab === tab ? "text-white" : "text-gray-500"}>
                              {`0${index + 1}`}
                            </span>
                          </span>
                        </span>
                        <span className="mt-0.5 ml-4 min-w-0 flex flex-col">
                          <span className={`text-sm font-semibold ${activeTab === tab ? "text-white" : "text-gray-500"} tracking-wide uppercase`}>
                            {getTabName(tab)}
                          </span>
                        </span>
                      </span>
                    </a>
                    {tab !== Constant.ActiveTabClassBuilder.DETAILS && (
                      <div
                      className={`hidden absolute top-0 -left-${
                        activeTab === getPreviousTab(tab) ? "0.5" : "0"
                      } w-3 inset-0 lg:block`}
                      aria-hidden="true"
                    >
                      {activeTab !== tab && (
                        <svg
                          className={`h-full w-full ${
                            activeTab === getPreviousTab(tab)
                              ? " text-primary-violet fill-current"
                              : " text-gray-300"
                          }`}
                          viewBox="0 0 12 82"
                          fill="none"
                          preserveAspectRatio="none"
                        >
                          <path
                            d="M0.5 0V31L10.5 41L0.5 51V82"
                            stroke="currentcolor"
                            vectorEffect="non-scaling-stroke"
                          ></path>
                        </svg>
                      )}
                  </div>
                    )}
                  </div>
                </li>
              ))}
            </ol>
          </nav>
        </div>
        {activeTab === Constant.ActiveTabClassBuilder.DETAILS && (
          <ClassBuilderClassDetails
            classDetail={classDetail}
            mode={mode}
            selectedColor={selectedColor}
            setSelectedColor={setSelectedColor}
            schoolYears={schoolYears}
            selectedSchoolYearId={selectedSchoolYearId}
            handleSchoolYearChange={handleSchoolYearChange}
            register={register}
            errors={errors}
            getValues={getValues}
            handleSubmit={handleSubmit}
            handleSaveAndContinue={handleSaveAndContinue}
            handleSkipClassSetup={handleSkipClassSetup}
            isDetailsValid={isDetailsValid}
            invalidDates={invalidDates}
            classId={classId}
            onSubmit={onSubmit}
          />
        )}

        {activeTab === Constant.ActiveTabClassBuilder.SUBJECTS && (
          <ClassBuilderSubjects
            availableSubjects={availableSubjects}
            selectedSubjects={selectedSubject}
            selectSubject={selectSubject}
            handleSaveAndContinue={handleSaveAndContinue}
            handleSkipClassSetup={handleSkipClassSetup}
            isDetailsValid={isDetailsValid}
          />
        )}

        {activeTab === Constant.ActiveTabClassBuilder.STUDENTS && (
            <ClassBuilderStudents
              availableSubjects={availableSubjects}
              filterCharList={filterCharList}
              startWith={studentStartWith}
              gradeList={gradeList}
              studentName={studentName}
              students={students}
              selectAll={selectAll}
              selectStudentToAdd={selectStudentToAdd}
              studentPageSize={studentPageSize}
              studentPageNumber={studentPageNumber}
              resetStudentPageNumber={resetStudentPageNumber}
              isDetailsValid={isDetailsValid}
              handleSaveAndContinue={handleSaveAndContinue}
              handleSkipClassSetup={handleSkipClassSetup}
              handleCharFilter={handleStudentCharFilter}
              setSelectedGrade={setSelectedGrade}
              setStudentName={setStudentName}
              searchStudents={searchStudents}
              selectAllStudent={selectAllStudent}
              selectStudent={selectStudent}
              getNameLabel={getNameLabel}
              onStudentPageSizeChange={onStudentPageSizeChange}
              setStudentPageNumber={setStudentPageNumber}
          />
        )}

        {activeTab === Constant.ActiveTabClassBuilder.TEACHERS && (
            <ClassBuilderTeachers
              filterCharList={filterCharList}
              startWith={teacherStartWith}
              schoolTeachers={schoolTeachers}
              selectAll={selectAll}
              teacherPageSize={teacherPageSize}
              teacherPageNumber={teacherPageNumber}
              resetTeacherPageNumber={resetTeacherPageNumber}
              isDetailsValid={isDetailsValid}              
              handleFinalSubmit={finalSubmit}
              handleSkipClassSetup={handleSkipClassSetup}
              handleCharFilter={handleTeacherCharFilter}
              selectAllteacher={selectAllteacher}
              selectTeacher={selectTeacher}
              setTeacherPageNumber={setTeacherPageNumber}
              onTeacherPageSizeChange={onTeacherPageSizeChange}
          />
        )}
        
      </section>

      <section>
        <SkipClassSetupDialog
          open={isDialogOpen}
          onSaveAndExit={handleSaveAndExit}
          onDiscardChanges={handleDiscardChanges}
          onCancel={() => setIsDialogOpen(false)}
        />
      </section>
      <ReactTooltip />
    </AllowedTo>
  );
};

export default ClassBuilder;

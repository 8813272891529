import React from "react";
import { CompletionInfo } from "./interface";

const AssignmentsGroupedAssignmentCollapsedIcon: React.FunctionComponent<
  CompletionInfo
> = ({ is_saved, past_due }: CompletionInfo) => {
  return (
    <div
      className={`flex-shrink-0 cursor-pointer h-12 w-12 bg-blues ${
        is_saved === 1 ? "bg-site-orange" : ""
      } ${
        past_due === 1 ? "bg-site-pink" : ""
      }  hover:opacity-90 rounded-full flex items-center justify-center`}
    >
      <svg
        className="flex-shrink-0 w-4 h-4 stroke-current text-white"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 38 26"
      >
        <g id="Group_1" data-name="Group 1" transform="translate(-249 -189)">
          <g
            id="Rectangle_2"
            data-name="Rectangle 2"
            transform="translate(249 189)"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
          >
            <rect width="38" height="26" rx="5" stroke="none" />
            <rect x="1" y="1" width="36" height="24" rx="4" fill="none" />
          </g>
          <line
            id="Line_1"
            data-name="Line 1"
            x2="23"
            transform="translate(257 195.5)"
            fill="none"
            stroke="currentColor"
            strokeWidth="1"
          />
          <line
            id="Line_1-2"
            data-name="Line 1"
            x2="23"
            transform="translate(257 199.5)"
            fill="none"
            stroke="currentColor"
            strokeWidth="1"
          />
          <line
            id="Line_1-3"
            data-name="Line 1"
            x2="23"
            transform="translate(257 203.5)"
            fill="none"
            stroke="currentColor"
            strokeWidth="1"
          />
          <line
            id="Line_1-4"
            data-name="Line 1"
            x2="23"
            transform="translate(257 207.5)"
            fill="none"
            stroke="currentColor"
            strokeWidth="1"
          />
        </g>
      </svg>
    </div>
  );
};

export default AssignmentsGroupedAssignmentCollapsedIcon;

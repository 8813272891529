import APIConstant from "../utils/constant/apiConstant";
import { AxiosHelper } from "../environment/axiosConfig";
import { IUsersQuery, IUsersResponse } from "../model/teacher/cms";
import { AxiosResponse } from "axios";

export const getStandardsByDomainId = (domainId: number) => {
  return AxiosHelper.axiosInstance().get(APIConstant.CMS_API.Standard, {
    params: {
      domainId: domainId,
      isActive: true,
      isApproved: true,
    },
  });
};

export const getDomainsBySubjectId = (subjectId: number) => {
  return AxiosHelper.axiosInstance().get(`${APIConstant.CMS_API.Domain}`, {
    params: {
      SubjectId: subjectId,
      IsActive: true,
      isApproved: true,
      orderByColumn: "name",
      sortDir: "asc",
    },
  });
};

export const getDOK = () => {
  const dokDataSet = [
    { value: 1, label: "Recall" },
    { value: 2, label: "Skill/Concept" },
    { value: 3, label: "Strategic Thinking" },
  ];
  return dokDataSet;
};

export const getStandardsBySubjectAndDomain = (domainId: number) => {
  return AxiosHelper.axiosInstance().get(APIConstant.CMS_API.Standard, {
    params: {
      DomainId: domainId,
    },
  });
};

export const getStandardGroupStandards = (
  standardGroupId: number  
) => {
  return AxiosHelper.axiosInstance().get(
    `${APIConstant.CMS_API.StandardGroupStandards}`,
    {
      params: {
        standardGroupId: standardGroupId,  
        standardId: 0,     
        isActive: true,
        pageNumber: 1,
        pageSize: 10,       
      },
    }
  );
};

export const getQuestionbyQuestionId = (questionId: number) => 
  AxiosHelper.axiosInstance().get(
    APIConstant.CMS_API.Question.replace("{id}", "" + questionId)
  );

export const getUsers = (
  query: IUsersQuery
): Promise<AxiosResponse<IUsersResponse>> =>
  AxiosHelper.axiosInstance().get<IUsersResponse>(
    APIConstant.CMS_API.Users,
    {
      params: {...query}
    },
  );

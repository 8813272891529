import { Fragment } from "react";
import { Configuration } from "../../../../../environment/setup";

type studentGradeModeType =
  | "Kindergarten"
  | "Elementary"
  | "MiddleSchool"
  | "LiftOff";
interface AlienAvatarProps {
  studentId: number;
  gradeId: number;
  alienData: any;
  showOnQuestions: boolean;
  studentGradeMode: studentGradeModeType;
  applykinderGartenStyle?: boolean;
  isLiftOff?: boolean;
}

function StudentAlienArenaFinalResult(props: AlienAvatarProps) {
  return (
    <Fragment>
      <div
        className={`${
          props.studentGradeMode === "Elementary" ? "" : ""
        } rounded-2xl bg-white cursor-pointer h-full w-full py-4 px-4 text-center rounded-xl hover:shadow-2xl transition ease-in-out duration-500`}
      >
        <div className="w-full bg-gray-100 text-center px-6 py-8 rounded-2xl">
          <div className="bg-gradient-to-b from-[#0062ff] to-[#61efff] md:w-3/4 lg:w-1/2 xl:w-1/3 xxl:w-1/3 xxxl:w-1/3 rounded-md py-10 mx-auto">
            <h1 className="text-white text-4xl font-light mb-4">
              Congratulations !
            </h1>
            <img
              src={`${Configuration.S3bucketImagePath}/images/sport_badges-05-512.png`}
              className="w-28 mx-auto"
              alt="Congratulations"
            />
            <div>
              <h2 className="text-white text-2xl mt-4">Winner</h2>
              <div className="flex justify-between shadow border bg-white mx-6 px-4 py-2 rounded-md mt-3">
                <div>
                  <div className="text-gray-700 text-sm">Rocketeers</div>
                </div>
                <div>
                  <div className="text-green-500 text-sm">4/5</div>
                </div>
              </div>
              <div className="flex justify-between shadow border bg-white mx-6 px-4 py-2 rounded-md mt-2">
                <div>
                  <div className="text-gray-700 text-sm">Shooting Stars</div>
                </div>
                <div>
                  <div className="text-green-500 text-sm">5/5</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default StudentAlienArenaFinalResult;

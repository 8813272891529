import { UserGroupIcon, XIcon } from "@heroicons/react/outline";
import { useEffect, useState } from "react";
import Moment from "react-moment";
import { connect } from "react-redux";
import { EditIcon, MergeIcon, ReactivateUserIcon } from "../../../assets/icons";
import { UserContextState } from "../../../redux/actions/userContextAction";
import Constant from "../../../utils/constant/constant";
import {
  DEFAULT_PAGE_NUMBER,
  DEFAULT_PAGE_SIZE_50,
} from "../../../utils/pagingConstant";
import Permissions from "../../../utils/permissions";
import { useConfirmation } from "../../shared/confirmation/confirmationService";
import Loader from "../../shared/loader";
import NoData from "../../shared/noData";
import PageSize from "../../shared/pagination/pageSize";
import Pagination from "../../shared/pagination/pagination";
import AllowedTo from "../../shared/rbac";
import TableSort from "../../shared/tableSort";
import Toggle from "../../shared/toggle";
import UserPasswordPopup from "../teacher/classManagement/classDetail/popups/userPasswordPopup";
import ApplyAllSettingContextMenu from "../teacher/shared/applyAllSettingContextMenu";
import { getNameLabel } from "../../../utils/helper";
import UserSearchQuery from "../../../model/users/userSearchQuery";
import LiftoffTile from "../../shared/LiftoffTile";
import userApi from "../../../api/userApi";

interface GridProp {
  selectedTab: string;
  showLoader: boolean;
  showNoData: boolean;
  pageSize: number;
  pageNumber: number;
  handlePageSize: (e: number) => void;
  handlePageNumber: (e: number) => void;
  totalRecords: number;
  studentsData: Array<any>;
  handleSortClick: (sortColumn: string, sortBy: number) => void;
  getUser: (
    pageNumber: number,
    searchFiler,
    exportType: any,
    currentPageSize: number
  ) => void;
  handleEdit: (user: any) => void;
  handleDeleteUser: (userId: number) => void;
  handleImpersonateUser: (user: any) => void;
  handleLockStudent: (userId: Array<number>, isLocked: boolean) => void;
  handleMergeStudent: (userId: number) => void;
  handleViewTeacherClasses: (userId: number, name: string) => void;
  handleViewStudentClasses: (userId: number, name: string) => void;
  handleReactivateUser: (userId: number) => void;
  handleUserRoleChange: (userId: number, roleId: number) => void;
  userContext?: UserContextState;
  handleSelectAll: (isChecked: boolean) => void;
  handleSelectSingle: (isChecked: boolean, userId: number) => void;
  selectAll: boolean;
  isLocked: boolean;
}

const Grid = (props: GridProp) => {
  const [pageNumber, setPageNumber] = useState(DEFAULT_PAGE_NUMBER);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE_50);
  const [resetPageNumber, setResetPageNumber] = useState<boolean>(false);
  const [selectedStudentToshowPassword, setSelectedStudentToshowPassword] =
    useState<any>();
  const [showUserPassword, setShowUserPassword] = useState<boolean>(false);
  const [hoverElement, setHoverElement] = useState<string>("");
  const confirm = useConfirmation();

  useEffect(() => {
    setPageNumber(DEFAULT_PAGE_NUMBER);
    setPageSize(DEFAULT_PAGE_SIZE_50);
    setResetPageNumber(true);
    props.handleSelectAll(false);
    props.handlePageSize(DEFAULT_PAGE_SIZE_50);
    if (props.selectedTab !== Constant.ManageUserMode.District) {
      const searchQuery: UserSearchQuery = {
        classId: 0,
        gradeId: 0,
        firstName: "",
        lastName: "",
        studentId: "",
        username: "",
        schoolAccountId: props.userContext?.schoolAccountId ?? 0,
      };
      props.getUser(
        DEFAULT_PAGE_NUMBER,
        searchQuery,
        "NONE",
        DEFAULT_PAGE_SIZE_50
      );
    }
  }, [props.selectedTab]);

  const onPageSizeChange = (pageSize: number) => {
    setPageNumber(DEFAULT_PAGE_NUMBER);
    setPageSize(pageSize);
    setResetPageNumber(true);
    props.handlePageSize(pageSize);
    props.getUser(DEFAULT_PAGE_NUMBER, null, "NONE", pageSize);
    props.handleSelectAll(false);
  };

  const onPageChange = (pageNumber: number) => {
    setPageNumber(pageNumber);
    props.handlePageNumber(pageNumber);
    props.getUser(pageNumber, null, "NONE", pageSize);
    props.handleSelectAll(false);
  };

  function applyAll(setting: string, userId: number, value: any): void {
    var users = props.studentsData.map((e) => e.userId);
    if (setting === "isDisabled") lockChange(users, value);
  }

  function applyAllAbove(setting: string, userId: number, value: any): void {
    var userIndex = props.studentsData?.findIndex((e) => e.userId === userId);
    var filteredStudents = props.studentsData?.filter((v, index) => {
      return index < (userIndex ?? 999);
    });
    if (filteredStudents !== undefined) {
      var users = filteredStudents.map((e) => e.userId);
      if (setting === "isDisabled") lockChange(users, value);
    }
  }

  function applyAllBelow(setting: string, userId: number, value: any): void {
    var userIndex = props.studentsData.findIndex((e) => e.userId === userId);
    var filteredStudents = props.studentsData.filter((v, index) => {
      return index > (userIndex ?? 0);
    });
    if (filteredStudents !== undefined) {
      var users = filteredStudents.map((e) => e.userId);
      if (setting === "isDisabled") lockChange(users, value);
    }
  }

  function lockChange(userIds: Array<number>, isLocked: boolean) {
    confirm({
      variant: "danger",
      title: "Please Confirm",
      description:
        "Are you sure you want to " +
        (isLocked ? "lock" : "unlock") +
        " this user(s)?",
    }).then((d) => {
      props.handleLockStudent(userIds, isLocked);
    });
  }

  function handleReactivateUser(userId: number) {
    var message: string = "Are you sure you want to re-activate this ";
    if (props.selectedTab === Constant.ManageUserMode.Student) {
      message = message + "student?";
    } else if (props.selectedTab === Constant.ManageUserMode.Teacher) {
      message = message + "teacher?";
    } else if (props.selectedTab === Constant.ManageUserMode.District) {
      message = message + "district user?";
    }
    confirm({
      variant: "danger",
      title: "Please Confirm",
      description: message,
    }).then((d) => {
      props.handleReactivateUser(userId);
    });
  }

  function handleChangeUserRole(userId: number, roleId: number): void {
    var message: string = "";
    if (roleId === Constant.UserRoleId.SchoolTeacher) {
      message =
        "Are you sure you want to change user role from School Admin to Teacher?";
    } else if (roleId === Constant.UserRoleId.SchoolAdmin) {
      message =
        "Are you sure you want to change user role from Teacher to School Admin?";
    }
    confirm({
      variant: "danger",
      title: "Please Confirm",
      description: message,
    }).then((d) => {
      props.handleUserRoleChange(userId, roleId);
    });
  }
  function getLoginDetails(userId: number) {
    userApi.GetUserDetail(userId).then((res) => {
      if (res.data) {
        setSelectedStudentToshowPassword(res.data);
        setShowUserPassword(true);
      }
    });
  }

  return (
    <>
      <div className="bg-gray-100 rounded-lg p-5 sm:mx-6 lg:mx-8">
        <div className="shadow hover:shadow-lg bg-white py-5 px-5 xl:px-2 1xl:px-5">
          {props.selectedTab === Constant.ManageUserMode.Student && (
            <div className="flex items-center">
              <LiftoffTile />
              <span className="text-xs ml-2 text-gray-500">
                Liftoff Activated
              </span>
            </div>
          )}
          <div className="flex flex-col relative">
            {props.showLoader && <Loader></Loader>}
            <div>
              <div className="py-2 ">
                <div className="shadow overflow-x-auto">
                  <table className="table w-full ">
                    <thead className="mb-5">
                      <tr className="bg-light-violet  text-white py-3 px-3">
                        <th
                          scope="col"
                          className="text-left  px-3 xl:px-2 xl:py-3 1xl:p-3 text-sm font-normal "
                        >
                          <label
                            x-radio-group-option=""
                            className="relative cursor-pointer"
                          >
                            <input
                              type="checkbox"
                              x-model="value"
                              name="privacy_setting"
                              value="Private to Project Members"
                              className="h-4 w-4 cursor-pointer text-primary-violet border-gray-300 focus:ring-primary-violet"
                              aria-labelledby="privacy-setting-1-label"
                              aria-describedby="privacy-setting-1-description"
                              onChange={(e) => {
                                props.handleSelectAll(e.target.checked);
                              }}
                              checked={props.selectAll}
                            />
                          </label>
                        </th>
                        <th
                          scope="col"
                          className="text-left  px-3 xl:px-2 xl:py-3 1xl:p-3 text-sm font-normal"
                        >
                          <div className="flex">
                            First Name
                            <TableSort
                              sortColumn={"FirstName"}
                              onClick={props.handleSortClick}
                            />
                          </div>
                        </th>
                        <th
                          scope="col"
                          className="text-left  px-3 xl:px-2 xl:py-3 1xl:p-3 text-sm font-normal"
                        >
                          <div className="flex">
                            Last Name
                            <TableSort
                              sortColumn={"LastName"}
                              onClick={props.handleSortClick}
                            />
                          </div>
                        </th>
                        <th></th>
                        <th
                          scope="col"
                          className="text-left  px-3 xl:px-2 xl:py-3 1xl:p-3 text-sm font-normal"
                        >
                          <div className="flex">
                            Username
                            <TableSort
                              sortColumn={"Username"}
                              onClick={props.handleSortClick}
                            />
                          </div>
                        </th>
                        {props.selectedTab ===
                          Constant.ManageUserMode.District && (
                          <th
                            scope="col"
                            className="text-left  px-3 xl:px-2 xl:py-3 1xl:p-3 text-sm font-normal"
                          >
                            <div className="flex">Email</div>
                          </th>
                        )}
                        {props.selectedTab ===
                          Constant.ManageUserMode.Student && (
                          <th
                            scope="col"
                            className="text-center  px-3 xl:px-2 xl:py-3 1xl:p-3 text-sm font-normal"
                          >
                            <div className="flex justify-center">
                              Grade
                              <TableSort
                                sortColumn={"Grade"}
                                onClick={props.handleSortClick}
                              />
                            </div>
                          </th>
                        )}
                        {props.userContext?.impersonatedUser === null &&
                          props.selectedTab !==
                            Constant.ManageUserMode.District && (
                            <th
                              scope="col"
                              className="text-left  px-3 xl:px-2 xl:py-3 1xl:p-3 text-sm font-normal"
                            >
                              <div className="flex">User Login</div>
                            </th>
                          )}
                        <th
                          scope="col"
                          className="text-center  px-3 xl:px-2 xl:py-3 1xl:p-3 text-sm font-normal"
                        >
                          Logins
                        </th>
                        <th
                          scope="col"
                          className="text-center  px-3 xl:px-2 xl:py-3 1xl:p-3 text-sm font-normal"
                        >
                          Last Login
                        </th>
                        {(props.selectedTab ===
                          Constant.ManageUserMode.Teacher ||
                          props.selectedTab ===
                            Constant.ManageUserMode.Student) && (
                          <th
                            scope="col"
                            className="text-center  px-3 xl:px-2 xl:py-3 1xl:p-3 text-sm font-normal"
                          >
                            Class List
                          </th>
                        )}
                        {props.selectedTab ===
                          Constant.ManageUserMode.Teacher &&
                          props.studentsData?.filter((s) => !s.isActive)
                            .length === 0 && (
                            <th
                              scope="col"
                              className="text-center  px-3 xl:px-2 xl:py-3 1xl:p-3 text-sm font-normal"
                            >
                              Admin
                            </th>
                          )}
                        <th
                          scope="col"
                          className="text-center  px-3 xl:px-2 xl:py-3 1xl:p-3 text-sm font-normal"
                        >
                          Lock
                        </th>
                        {props.userContext?.impersonatedUser === null && (
                          <th
                            scope="col"
                            className="w-14 text-center px-3 xl:px-2 xl:py-3 1xl:p-3 text-sm font-normal"
                          >
                            Action
                          </th>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {props.studentsData?.map((user, personIdx) => (
                        <tr
                          key={user.userId}
                          className={
                            personIdx % 2 === 0
                              ? " hover:bg-light-violet/20 transition ease-in-out duration-300 bg-white"
                              : "hover:bg-light-violet/20 transition ease-in-out duration-300 bg-light-violet/10"
                          }
                          onMouseOver={() => {
                            setHoverElement("row-" + user.userId);
                          }}
                        >
                          <td className="px-3 xl:px-2 xl:py-3 1xl:p-3 whitespace-nowrap text-sm text-gray-500">
                            <input
                              type="checkbox"
                              className="h-4 w-4 cursor-pointer text-primary-violet border-gray-300 focus:ring-primary-violet"
                              checked={user.isSelected}
                              onChange={(e) => {
                                props.handleSelectSingle(
                                  e.target.checked,
                                  user.userId
                                );
                              }}
                            />
                          </td>
                          <td className="px-3 xl:px-2 xl:py-3 1xl:p-3 text-sm text-gray-500">
                            {user.firstName}
                          </td>
                          <td className="px-3 xl:px-2 xl:py-3 1xl:p-3 text-sm text-gray-500">
                            {user.lastName}
                          </td>
                          <td className="w-4">
                            <span>
                              {user.isLOActive &&
                                user.gradeId !== "1" &&
                                user.gradeId !== "2" && (
                                  <>
                                    <LiftoffTile />
                                  </>
                                )}
                            </span>
                          </td>
                          <td className="px-3 xl:px-2 xl:py-3 1xl:p-3 text-sm text-gray-500 text-left">
                            {user.username}
                          </td>
                          {props.selectedTab ===
                            Constant.ManageUserMode.District && (
                            <td className="px-3 xl:px-2 xl:py-3 1xl:p-3 text-sm text-gray-500 text-left">
                              {user.email}
                            </td>
                          )}
                          {props.selectedTab ===
                            Constant.ManageUserMode.Student && (
                            <td className="px-3 xl:px-2 xl:py-3 1xl:p-3 text-sm text-gray-500 text-center">
                              {user.grade}
                            </td>
                          )}

                          {props.userContext?.impersonatedUser === null &&
                            props.selectedTab !==
                              Constant.ManageUserMode.District && (
                              <td className="px-3 xl:px-2 xl:py-3 1xl:p-3 text-sm text-gray-500 text-left">
                                <span className="flex gap-1">
                                  {user.isDisabled === false &&
                                    user.isActive === true && (
                                      <>
                                        <label
                                          className="w-10  text-blue-500 underline hover:no-underline cursor-pointer"
                                          onClick={() => {
                                            props.handleImpersonateUser(user);
                                          }}
                                        >
                                          Login
                                        </label>
                                      </>
                                    )}

                                  {props.selectedTab ===
                                    Constant.ManageUserMode.Student && (
                                    <>
                                      <span className="text-gray-700">|</span>
                                      <label
                                        className="w-18 text-blue-500 underline hover:no-underline cursor-pointer"
                                        onClick={() => {
                                          getLoginDetails(user.userId);
                                        }}
                                      >
                                        Show Login
                                      </label>
                                    </>
                                  )}
                                </span>
                              </td>
                            )}

                          <td className="px-3 xl:px-2 xl:py-3 1xl:p-3 text-sm text-gray-500 text-center">
                            {user.loginCount}
                          </td>
                          <td className="px-3 xl:px-2 xl:py-3 1xl:p-3 text-sm text-gray-500 text-center">
                            {user?.lastLogin !== null && (
                              <> {<Moment>{user?.lastLogin}</Moment>}</>
                            )}
                          </td>
                          {(props.selectedTab ===
                            Constant.ManageUserMode.Teacher ||
                            props.selectedTab ===
                              Constant.ManageUserMode.Student) && (
                            <td className="px-3 xl:px-2 xl:py-3 1xl:p-3 text-sm text-gray-500 text-left">
                              <span
                                className="cursor-pointer underscore flex justify-center"
                                onClick={() => {
                                  if (
                                    props.selectedTab ===
                                    Constant.ManageUserMode.Teacher
                                  ) {
                                    props.handleViewTeacherClasses(
                                      user.userId,
                                      user.firstName + " " + user.lastName
                                    );
                                  } else {
                                    props.handleViewStudentClasses(
                                      user.userId,
                                      user.lastName +
                                        (user.firstName
                                          ? ", " + user.firstName
                                          : "")
                                    );
                                  }
                                }}
                              >
                                <UserGroupIcon className="h-7 w-7 text-gray-500 opacity-80 cursor-pointer hover:text-gray-600"></UserGroupIcon>
                              </span>
                            </td>
                          )}
                          {props.selectedTab ===
                            Constant.ManageUserMode.Teacher &&
                            props.studentsData?.filter((s) => !s.isActive)
                              .length === 0 && (
                              <td className="px-3 xl:px-2 xl:py-3 1xl:p-3 text-sm text-gray-500 text-center">
                                <Toggle
                                  disabled={
                                    (props.userContext?.roleId !==
                                      Constant.UserRoleId.District &&
                                      props.userContext?.roleId !==
                                        Constant.UserRoleId.SchoolAdmin) ||
                                    !user.isActive
                                  }
                                  checked={
                                    user.roleId ===
                                    Constant.UserRoleId.SchoolAdmin
                                  }
                                  onChange={() => {
                                    handleChangeUserRole(
                                      user.userId,
                                      user.roleId ===
                                        Constant.UserRoleId.SchoolAdmin
                                        ? Constant.UserRoleId.SchoolTeacher
                                        : Constant.UserRoleId.SchoolAdmin
                                    );
                                  }}
                                  title={
                                    !user.isActive
                                      ? "The role can be changed only for active users."
                                      : "Only district/school admin can change the user role."
                                  }
                                />
                              </td>
                            )}
                          <td className="px-3 xl:px-2 xl:py-3 1xl:p-3 text-sm text-gray-500 text-center">
                            <div className="flex justify-center">
                              <Toggle
                                disabled={false}
                                checked={user.isDisabled}
                                onChange={() => {
                                  lockChange([user.userId], !user.isDisabled);
                                }}
                              />
                              <div className="w-6">
                                {hoverElement === "row-" + user.userId && (
                                  <ApplyAllSettingContextMenu
                                    isFirst={(personIdx === 0).toString()}
                                    isLast={(
                                      personIdx === pageSize - 1 ||
                                      personIdx === props.totalRecords - 1
                                    ).toString()}
                                    applyAll={applyAll}
                                    applyAllAbove={applyAllAbove}
                                    applyAllBelow={applyAllBelow}
                                    setting="isDisabled"
                                    userId={user.userId}
                                    value={user.isDisabled}
                                  />
                                )}
                              </div>
                            </div>
                          </td>
                          {props.userContext?.impersonatedUser === null && (
                            <td className="px-3 xl:px-2 xl:py-3 1xl:p-3 text-center text-sm text-gray-500">
                              {user.isActive === true && (
                                <span className="flex gap-1">
                                  <AllowedTo
                                    perform={Permissions.user_management_modify}
                                  >
                                    <span
                                      className="cursor-pointer"
                                      onClick={() => {
                                        props.handleEdit(user);
                                      }}
                                    >
                                      <EditIcon
                                        title={
                                          props.selectedTab ===
                                          Constant.ManageUserMode.District
                                            ? "Edit District User"
                                            : props.selectedTab ===
                                              Constant.ManageUserMode.Teacher
                                            ? "Edit Teacher"
                                            : "Edit Student"
                                        }
                                        className="w-4 text-gray-500 hover:text-gray-700 cursor-pointer m-auto"
                                      ></EditIcon>
                                    </span>

                                    {props.selectedTab !==
                                      Constant.ManageUserMode.District && (
                                      <span
                                        className="cursor-pointer hidden"
                                        onClick={() => {
                                          props.handleMergeStudent(user.userId);
                                        }}
                                        title="Merge"
                                      >
                                        <MergeIcon className="pl-2 w-8 h-4 text-gray-500 hover:text-gray-700 cursor-pointer" />
                                      </span>
                                    )}
                                  </AllowedTo>
                                  <AllowedTo
                                    perform={Permissions.user_management_delete}
                                  >
                                    <span
                                      title={
                                        props.selectedTab ===
                                        Constant.ManageUserMode.District
                                          ? "Delete District User"
                                          : props.selectedTab ===
                                            Constant.ManageUserMode.Teacher
                                          ? "Delete Teacher"
                                          : "Delete Student"
                                      }
                                      className="cursor-pointer"
                                      onClick={() => {
                                        props.handleDeleteUser(user.userId);
                                      }}
                                    >
                                      <XIcon className="pl-2 h-4_5 text-gray-500 hover:text-gray-700 cursor-pointer" />
                                    </span>
                                  </AllowedTo>
                                </span>
                              )}

                              {user.isActive === false && (
                                <span className="flex gap-1 justify-end">
                                  <span
                                    className="cursor-pointer"
                                    onClick={() => {
                                      handleReactivateUser(user.userId);
                                    }}
                                    title={
                                      props.selectedTab ===
                                      Constant.ManageUserMode.District
                                        ? "Re-activate District User"
                                        : props.selectedTab ===
                                          Constant.ManageUserMode.Teacher
                                        ? "Re-activate Teacher"
                                        : "Re-activate Student"
                                    }
                                  >
                                    <ReactivateUserIcon className="w-4 text-gray-500 hover:text-gray-700 cursor-pointer m-auto" />
                                  </span>
                                </span>
                              )}
                            </td>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            {props.showNoData && <NoData></NoData>}
          </div>
          <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between border-t-2 pt-4 mt-3">
            {props.totalRecords !== undefined && props.totalRecords !== 0 && (
              <Pagination
                totalRecords={props.totalRecords}
                pageSize={props.pageSize}
                pageNumber={pageNumber}
                onPageChange={(e) => {
                  onPageChange(e);
                }}
                reset={resetPageNumber}
              />
            )}
            {props.totalRecords !== 0 && (
              <PageSize
                pageSize={pageSize}
                onPageSizeChange={onPageSizeChange}
              />
            )}
          </div>
        </div>
      </div>
      {showUserPassword && (
        <UserPasswordPopup
          userName={selectedStudentToshowPassword?.username}
          password={atob(selectedStudentToshowPassword?.passwordHash)}
          name={getNameLabel(
            selectedStudentToshowPassword?.lastName,
            selectedStudentToshowPassword?.firstName
          )}
          showPopUp={(show) => {
            setShowUserPassword(show);
          }}
          title={
            props.selectedTab === Constant.ManageUserMode.Teacher
              ? "Teacher"
              : props.selectedTab === Constant.ManageUserMode.District
              ? "District"
              : "Student"
          }
          isTeacher={props.selectedTab === Constant.ManageUserMode.Teacher}
        />
      )}
    </>
  );
};
const mapStateToProps = (state: any) => {
  return {
    masterData: state.masterData,
    userContext: state.userContext,
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(Grid);

import { Fragment } from "react";
import { StarProgressIcon } from "../../assets/icons";

interface passedProps {
  percentage: string;
}

function ProgressBar(props: passedProps) {
  const completedPercentage = parseInt(props.percentage);
  return (
    <Fragment>
      <div className="flex mx-auto items-center text-xs">
        {completedPercentage !== 100 && (
          <span className="text-gray-400 w-10">{`${props.percentage}%`}</span>
        )}
        {completedPercentage < 50 && (
          <span className="relative w-full ml-1.5 rounded-lg text-xs my-3 flex items-center text-yellow-600 bg-gray-200">
            <span
              className="bg-red-500 h-1.5 rounded inline-block w-1/2"
              style={{ width: `${props.percentage}%` }}
            ></span>
          </span>
        )}

        {completedPercentage >= 50 && completedPercentage <= 80 && (
          <span className="relative w-full ml-1.5 rounded-lg text-xs my-3 flex items-center text-yellow-600 bg-gray-200">
            <span
              className="bg-yellow-400 h-1.5 rounded inline-block w-1/2"
              style={{ width: `${props.percentage}%` }}
            ></span>
          </span>
        )}

        {completedPercentage > 80 && completedPercentage < 100 && (
          <span className="relative w-full ml-1.5 rounded-lg text-xs my-3 flex items-center text-yellow-600 bg-gray-200">
            <span
              className="bg-green-500 h-1.5 rounded inline-block w-1/2"
              style={{ width: `${props.percentage}%` }}
            ></span>
          </span>
        )}

        {completedPercentage === 100 && <StarProgressIcon className="w-12 mx-auto" />}
      </div>
    </Fragment>
  );
}

export default ProgressBar;

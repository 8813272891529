import { Fragment, useEffect, useState } from "react";
import ColumnHeader from "../../../../../model/gridView/columnHeader";
import { AxiosHelper } from "../../../../../environment/axiosConfig";
import APIConstant from "../../../../../utils/constant/apiConstant";
import { PlayIcon, ChartBarIcon } from "@heroicons/react/outline";
import RouteConstant from "../../../../../utils/constant/routeConstant";
import { useHistory } from "react-router-dom";
import Constant from "../../../../../utils/constant/constant";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import Profile from "../../../../../model/users/profile";

interface diagnosticsProps {
  subjectId?: number;
  border?: string;
  userId?: number;
  profile?: Profile;
  refresh?: boolean;
}

function LiftOffDiagnostics(props: diagnosticsProps) {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const [assignments, setAssignments] = useState<any>([]);
  const [disableProgress, setDisableProgress] = useState(false);
  function getStudentLiftOffDiagnosticAssignments(userId?: number) {
    return AxiosHelper.axiosInstance().get(
      APIConstant.StudentLiftOffAssignment_API.GetByUserId,
      {
        params: {
          userId: userId,
        },
      }
    );
  }

  const columnHeaders: Array<ColumnHeader> = [
    new ColumnHeader("Header"),
    new ColumnHeader("Subject"),
    new ColumnHeader("TotalQuestions"),
    new ColumnHeader("TotalPointsEarned"),
    new ColumnHeader("TotalPointsPossible"),
    new ColumnHeader("Score"),
    new ColumnHeader("Action"),
  ];

  function getAssignments() {
    getStudentLiftOffDiagnosticAssignments(props.userId).then((response) => {
      const data = response.data;
      setAssignments(data);
      setDisableProgress(false);
    });
  }
  function handleAssignmentClick(assignment) {
    startAssignment(assignment);
  }

  function handleAssignmentCompletedClick(assignment) {
    const route =
      location.pathname.toLowerCase().indexOf("kindergarten") > -1
        ? RouteConstant.LiftOff.Kindergarten.DiagnosticResult
        : location.pathname.toLowerCase().indexOf("elementary") > -1
        ? RouteConstant.LiftOff.Elementary.DiagnosticResult
        : location.pathname.toLowerCase().indexOf("middleschool") > -1
        ? RouteConstant.LiftOff.MiddleSchool.DiagnosticResult
        : "";
    history.push(
      route.replace(
        ":liftOffHeaderId",
        encodeURI(btoa(assignment.liftOffHeaderId.toString()))
      )
    );
  }

  function startAssignment(assignment) {
    const route =
      location.pathname.toLowerCase().indexOf("kindergarten") > -1
        ? RouteConstant.LiftOff.Kindergarten.DiagnosticActivity
        : location.pathname.toLowerCase().indexOf("elementary") > -1
        ? RouteConstant.LiftOff.Elementary.DiagnosticActivity
        : location.pathname.toLowerCase().indexOf("middleschool") > -1
        ? RouteConstant.LiftOff.MiddleSchool.DiagnosticActivity
        : "";

    history.push(
      route
        .replace(
          ":activityId",
          encodeURI(btoa(assignment.englishExternalReferenceId.toString()))
        )
        .replace(
          ":activityStatus",
          encodeURI(btoa(assignment.diagnosticAssignmentStatus))
        )
        .replace(":activityHeader", encodeURI(btoa(assignment.header)))
        .replace(
          ":activityHeaderId",
          encodeURI(btoa(assignment.liftOffHeaderId))
        )
        .replace(
          ":activityStatusId",
          encodeURI(btoa(assignment.studentLiftOffDiagnosticAssignmentId))
        )
        .replace(":subjectId", encodeURI(btoa(assignment.subjectId.toString())))
        .replace(":subjectName", encodeURI(btoa(assignment.subjectName)))
    );
  }

  useEffect(() => {
    if (props.refresh) {
      getAssignments();
    } else if (!props.refresh) {
      setDisableProgress(true);
    }
  }, [props.refresh]);

  function getAssignmentStatus(assignment) {
    if (
      assignment.liftOffDiagnosticTestStatusId ===
      Constant.LiftOffDiagnosticAssignmentStatusType.InProgress
    )
      return Constant.LiftOffDiagnosticAssignmentStatusType.InProgress;
    else if (
      assignment.liftOffDiagnosticTestStatusId ===
      Constant.LiftOffDiagnosticAssignmentStatusType.Completed
    )
      return Constant.LiftOffDiagnosticAssignmentStatusType.Completed;
    else if (assignment.englishExternalReferenceId)
      return Constant.LiftOffDiagnosticAssignmentStatusType.Start;
    return Constant.LiftOffDiagnosticAssignmentStatusType.NotExist;
  }

  return (
    <Fragment>
      <div className="relative">
        <span className="text-lg font-semibold mb-1 inline-block">
          {t("LiftoffDiagnostics.LiftoffDiagnostics")}
        </span>
        <div>
          <div
            className={`shadow overflow-x-auto rounded-lg border-4 ${props.border}`}
          >
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  {columnHeaders.map((header, index) => {
                    return (
                      <th
                        key={index}
                        scope="col"
                        className="px-3 py-3 text-left text-xs font-medium text-gray-500 capitalize tracking-wider bg-gray-100"
                      >
                        {t("LiftoffDiagnostics." + header.title)}
                        {}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {assignments.map((assignment, index) => {
                  return (
                    <tr
                      key={index}
                      className={
                        index % 2 === 0 ? "bg-white" : "bg-white bg-opacity-80"
                      }
                    >
                      <td className="px-3 py-4  text-sm text-gray-500">
                        {assignment.header}
                      </td>
                      <td className="px-3 py-4  text-sm text-gray-500">
                        {assignment.subjectName}
                      </td>
                      <td className="px-3 py-4  text-sm text-gray-500 text-center">
                        {assignment.nweaAttempted
                          ? ""
                          : assignment.questionsAttempted}
                      </td>
                      <td className="px-3 py-4  text-sm text-gray-500 text-center">
                        {assignment.nweaAttempted
                          ? ""
                          : assignment.pointsEarned
                              .toFixed(2)
                              .replace(/\.?0+$/, "")}
                      </td>
                      <td className="px-3 py-4  text-sm text-gray-500 text-center">
                        {assignment.nweaAttempted
                          ? ""
                          : Math.round(assignment.pointsPossible)}
                      </td>
                      <td className="px-3 py-4  text-sm text-gray-500 text-center">
                        {assignment.nweaAttempted
                          ? ""
                          : Math.round(assignment.overallScore) + "%"}
                      </td>
                      <td className="px-3 py-4  text-sm text-gray-500">
                        {assignment.nweaAttempted && (
                          <span>
                            {" "}
                            {t("LiftOffDiagnosticStatus.NWEAEnabled")}
                          </span>
                        )}

                        {!assignment.nweaAttempted &&
                          getAssignmentStatus(assignment) ===
                            Constant.LiftOffDiagnosticAssignmentStatusType
                              .Start && (
                            <div className="cursor-not-allowed">
                              <button
                                className={
                                  (disableProgress
                                    ? "pointer-events-none text-gray-400 bg-gray "
                                    : "bg-blues ") +
                                  "flex items-center mx-auto w-full justify-center  hover:bg-opacity-80  px-2  text-xs py-1 text-white rounded-lg p-1 space-x-0.5"
                                }
                                onClick={() => {
                                  handleAssignmentClick(assignment);
                                }}
                              >
                                {t("LiftOffDiagnosticStatus.Start")}
                                <PlayIcon className="w-5 cursor-pointer ml-1"></PlayIcon>
                              </button>
                            </div>
                          )}
                        {!assignment.nweaAttempted &&
                          getAssignmentStatus(assignment) ===
                            Constant.LiftOffDiagnosticAssignmentStatusType
                              .InProgress && (
                            <div className="cursor-not-allowed">
                              <button
                                className={
                                  (disableProgress
                                    ? "pointer-events-none text-gray-400 bg-gray "
                                    : "bg-blue-500 ") +
                                  "flex items-center mx-auto w-full justify-center  hover:bg-opacity-80  px-2  text-xs py-1 text-white rounded-lg p-1 space-x-0.5"
                                }
                                onClick={() => {
                                  handleAssignmentClick(assignment);
                                }}
                              >
                                {t("LiftOffDiagnosticStatus.Resume")}
                                <PlayIcon className="w-5 cursor-pointer ml-1"></PlayIcon>
                              </button>
                            </div>
                          )}
                        {!assignment.nweaAttempted &&
                          getAssignmentStatus(assignment) ===
                            Constant.LiftOffDiagnosticAssignmentStatusType
                              .Completed && (
                            <div className="cursor-not-allowed">
                              <button
                                className={
                                  (disableProgress
                                    ? "pointer-events-none text-gray-400 bg-gray "
                                    : "bg-blues ") +
                                  "flex items-center mx-auto w-full justify-center  hover:bg-opacity-80  px-2  text-xs py-1 text-white rounded-lg p-1 space-x-0.5"
                                }
                                onClick={() => {
                                  handleAssignmentCompletedClick(assignment);
                                }}
                              >
                                {t("LiftOffDiagnosticStatus.Completed")}
                                <ChartBarIcon className="w-5 cursor-pointer ml-1"></ChartBarIcon>
                              </button>
                            </div>
                          )}
                      </td>
                    </tr>
                  );
                })}
                <tr></tr>
              </tbody>
            </table>
          </div>
          <div className="mt-3 text-right">
            {assignments.length > 0 &&
              `Showing 1 to ${assignments.length} of ${assignments.length} entries`}
          </div>
        </div>
      </div>
    </Fragment>
  );
}

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(LiftOffDiagnostics);

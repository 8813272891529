import { connect } from "react-redux";
import { ICannedTest, ISummativeTest } from "../../../../model/interface/summativeTest/summativeTest";
import UpgradeButtonControl from "../../../shared/customButtonControl";
import { UserContextState } from "../../../../redux/actions/userContextAction";
import { ArrowCircleRightIcon } from "@heroicons/react/solid";
import Moment from "react-moment";
import { useTranslation } from "react-i18next";
import { getTestSizeBySubjectGrades } from "../../../../utils/helper";
import constant from "../../../../utils/constant/constant";
import { IGrade } from "../../../../model/interface/grade";

const theme = {
  ELEMENTARY: {
    borderColor: "border-millitary-green/30",
    iconColor: "text-[#34D9A5]"
  },
  MIDDLESCHOOL: {
    borderColor: "border-primary-violet/50",
    iconColor: "text-primary-violet"
  },
}

interface StudyPlanSubjectTestsProps {
  smallSummativeTest: ISummativeTest|undefined;
  mediumSummativeTest: ISummativeTest|undefined;
  largeSummativeTest: ISummativeTest|undefined;
  cannedTests: ICannedTest[];
  cannedTestsInProgress: ICannedTest[];
  userContext?: UserContextState;
  profile?: any;
  startSummativeTest(testSize: number): void;
  startCannedTest(cannedTestId: number, learnosityReferenceId: string): void;
  themeName: "ELEMENTARY" | "MIDDLESCHOOL";
  subjectGrades: Array<IGrade>;
}

const StudyPlanSubjectTests = (props: StudyPlanSubjectTestsProps) => {
  const { t } = useTranslation();
  const {
    themeName,
    smallSummativeTest,
    mediumSummativeTest,
    largeSummativeTest,
    cannedTests,
    cannedTestsInProgress,
    startSummativeTest,
    startCannedTest,
  } = props;

  function getInProgressCannedTest(cannedTestId: number): ICannedTest|undefined{
    return cannedTestsInProgress?.find(
      (cannedTest) => cannedTest.cannedTestId === cannedTestId
    );
  }

  function isInProgressCannedTest(cannedTest: ICannedTest): boolean {
    if (!cannedTest) return false;
    return cannedTest.statusId > 1
      && cannedTest.statusId !== constant.AssignmentStatusId.COMPLETE
  }

  return (
    <div>
      {cannedTests && cannedTests.length === 0 &&
        <div className="bg-white">
          <ul className=" w-full">
            <li className={
                `flex group justify-start py-2 mb-4 items-center rounded-lg border ${theme[themeName].borderColor} text-gray-900 mr-auto text-sm font-medium bg-white px-2 hover:bg-gray-100 transition-all duration-300 cursor-pointer`
              }
            >
              <UpgradeButtonControl
                controlType={"div"}              
                userContext={props?.userContext}
                isUpgradeRequired={props?.profile?.isUpgradeRequired}                   
                isRoleCheck={true}               
                className={"w-full flex justify-between items-center " + (props?.profile?.isUpgradeRequired ? " cursor-not-allowed " : "")}
                onClick={() => {
                  startSummativeTest(1);
                }}
              >
                <div>
                  <div className="flex items-center p-1">
                    <label className="checkbox-label text-sm text-gray-500">
                      {t("SummativeTests.Small")} {getTestSizeBySubjectGrades(1, props.subjectGrades)} {t("StudyPlan.Questions")}
                    </label>
                  </div>
                  {smallSummativeTest && (
                    <span className="mt-0 block font-normal text-xs text-primary-violet mt-1 pl-1">
                      {" "}
                      Last saved on{" "}
                      <>
                        {" "}
                        {
                          <Moment>
                            {smallSummativeTest.updatedDate}
                          </Moment>
                        }
                      </>
                    </span>
                  )}
                </div>
                <ArrowCircleRightIcon
                  className={
                    `h-5 w-5 opacity-0 ${theme[themeName].iconColor} group-hover:opacity-100`
                  }
                />                        
              </UpgradeButtonControl>
            </li>
            <li className={
                `flex group justify-start py-2 mb-4 items-center rounded-lg border ${theme[themeName].borderColor} text-primary-violet text-gray-900 mr-auto text-sm font-medium bg-white px-2 hover:bg-gray-100 transition-all duration-300 cursor-pointer`
              }
            >
              <UpgradeButtonControl
                controlType={"div"}              
                userContext={props?.userContext}
                isUpgradeRequired={props?.profile?.isUpgradeRequired}                   
                isRoleCheck={true}               
                className={"w-full flex justify-between items-center " + (props?.profile?.isUpgradeRequired ? " cursor-not-allowed " : "")}
                onClick={() => {
                  startSummativeTest(2);
                }}
              >
                <div>
                  <div className="flex items-center p-1">
                    <label className="checkbox-label text-sm text-gray-500">
                    {t("SummativeTests.Medium")} {getTestSizeBySubjectGrades(2, props.subjectGrades)} {t("StudyPlan.Questions")}
                    </label>
                  </div>
                  {mediumSummativeTest && (
                    <span className="mt-2 pl-1 block text-sm text-gray-900">
                      {" "}
                      Last saved on{" "}
                      <>
                        {
                          <Moment>
                            {mediumSummativeTest.updatedDate}
                          </Moment>
                        }
                      </>
                    </span>
                  )}
                </div>
                <ArrowCircleRightIcon
                  className={
                    `h-5 w-5 opacity-0 ${theme[themeName].iconColor} group-hover:opacity-100`
                  }
                />                     
              </UpgradeButtonControl>
            </li>
            <li className={
                `flex group justify-start py-2 mb-4 items-center rounded-lg border ${theme[themeName].borderColor} text-gray-900 mr-auto text-sm font-medium bg-white px-2 hover:bg-gray-100 transition-all duration-300 cursor-pointer`
              }
            >
              <UpgradeButtonControl
                controlType={"div"}              
                userContext={props?.userContext}
                isUpgradeRequired={props?.profile?.isUpgradeRequired}                   
                isRoleCheck={true}               
                className={"w-full flex justify-between items-center " + (props?.profile?.isUpgradeRequired ? " cursor-not-allowed " : "")}
                onClick={() => {
                  startSummativeTest(3);
                }}
              >
                <div>
                  <div className="flex items-center p-1">
                    <label className="checkbox-label text-sm text-gray-500">
                      {t("SummativeTests.Large")} {getTestSizeBySubjectGrades(3, props.subjectGrades)} {t("StudyPlan.Questions")}
                    </label>
                  </div>
                  {largeSummativeTest && (
                    <span className="mt-0 pl-1 block font-normal text-xs text-primary-violet mt-1">
                      {" "}
                      Last saved on{" "}
                      <>
                        {
                          <Moment>
                            {largeSummativeTest.updatedDate}
                          </Moment>
                        }
                      </>
                    </span>
                  )}
                </div>
                <ArrowCircleRightIcon
                  className={
                    `h-5 w-5 opacity-0 ${theme[themeName].iconColor} group-hover:opacity-100`
                  }
                />                        
              </UpgradeButtonControl>
            </li>
          </ul>
        </div>
      }
      {cannedTests && cannedTests.length > 0 && (
        <div className="relative bg-white rounded-md overflow-hidden border border-gray-200 mt-2">
          <ul className="w-full divide-y divide-gray-200">
            {cannedTests.map((d) => (
              <li
                key={d.cannedTestId}
                className={
                  "flex group justify-start min-h-14 w-full py-2 items-center text-gray-900 mr-auto text-sm font-medium bg-white px-2 hover:bg-gray-100 transition-all duration-300 shadow cursor-pointer"
                }
              >
                <UpgradeButtonControl
                  controlType={"div"}              
                  userContext={props?.userContext}
                  isUpgradeRequired={props?.profile?.isUpgradeRequired}                   
                  isRoleCheck={true}               
                  className={"w-full flex justify-between items-center" + (props?.profile?.isUpgradeRequired ? " cursor-not-allowed " : "")}
                  onClick={() => {
                    startCannedTest(
                      d.cannedTestId,
                      d.learnosityReferenceId
                    );
                  }}
                  >
                  <div className="w-full flex justify-between items-center">
                    <div>
                      <span className="block text-sm">
                        {d.cannedTestName}
                      </span>
                      {cannedTests &&
                        isInProgressCannedTest(d) && (
                          <span className="mt-0 block font-normal text-xs text-primary-violet mt-1">
                            Last saved on{" "}
                            <>
                              {
                                <Moment>
                                  {
                                    getInProgressCannedTest(d.cannedTestId)?.updatedDate
                                  }
                                </Moment>
                              }
                            </>
                          </span>
                        )}
                    </div>
                    {
                      true &&
                      <ArrowCircleRightIcon
                        className={
                          `h-5 w-5 opacity-0 ${theme[themeName].iconColor} group-hover:opacity-100`
                        }
                      />
                    }
                  </div>
                </UpgradeButtonControl>
              </li>
            ))}
          </ul>
        </div>    
      )}
    </div>
  );
}

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(StudyPlanSubjectTests);
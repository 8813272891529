import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import RouteConstant from "../../../../../utils/constant/routeConstant";
import Breadcrumb from "../../shared/breadcrumb";
import { IBreadcrumb } from "../../../../../model/common/breadcrumb";
import DynamicComponent from "../../../../dynamicComponent/dynamicComponent";
import constant from "../../../../../utils/constant/constant";

const StudentStandardEfficiencyResult = (props: any) => {
  const { t } = useTranslation();
  const sessionId = props.match.params.sessionId;
  const gradeId = parseInt(props.match.params.gradeId);
  type dynamicComponent = { component: any; parameter: any };
  const [resultComponent, setResultComponent] = useState<dynamicComponent>();
  useEffect(() => {
    loadStudentView();
  }, []);

  function loadStudentView() {
    const params = {
      isTeacherView: true,
      sessionId: sessionId,
      studentId: parseInt(props.match.params.userId)
    };
    let componentName = "StandardEfficiencyReport";
    if (gradeId <= constant.Grade.GRADE1) {
      setResultComponent({ component: `${componentName}KG`, parameter: params });
    } else if (
      gradeId > constant.Grade.GRADE1 &&
      gradeId < constant.Grade.GRADE6
    ) {
      setResultComponent({ component: `${componentName}EL`, parameter: params });
    } else if (gradeId >= constant.Grade.GRADE6) {
      setResultComponent({ component: `${componentName}MS`, parameter: params });
    }
  }

  const breadcrumbItems = () => {
    let items: Array<IBreadcrumb> = [
        { name: t("Breadcrumb.Home"), url: RouteConstant.ROUTE_DASHBOARD },
        {
          name: t("Breadcrumb.GradeBook"),
          url: RouteConstant.TeacherRoutes.GradeBook,
        },
        { name: t("Diagnostics.StandardsEfficiencyReport"), url: ""}
      ];
    return items;
  };

  return (
    <Fragment>
      <div className="px-4 py-5 lg:px-8 border-b">
        <Breadcrumb items={breadcrumbItems()} />
      </div>
      <div className="relative bg-primary-violet w-full">
        {resultComponent && DynamicComponent(resultComponent)}
      </div>
    </Fragment>
  );
};

export default StudentStandardEfficiencyResult;

import { XIcon } from "@heroicons/react/outline";
import { connect } from "react-redux";
import {
  createSampleStudent,
  getSampleStudent,
} from "../../../../api/student/studentViewApi";
import ImpersonateUserModel from "../../../../model/users/impersonateUser";
import { fetchProfile } from "../../../../redux/actions/userActions";
import { UserContextState } from "../../../../redux/actions/userContextAction";
import constant from "../../../../utils/constant/constant";
import routeConstant from "../../../../utils/constant/routeConstant";
import { impersonateUser } from "../../../../utils/impersonateHelper";
import * as userContextAction from "../../../../redux/actions/userContextAction";
import { useHistory } from "react-router-dom";
import Loader from "../../../shared/loader";
import { useState } from "react";
interface StudentViewProps {
  userContext: UserContextState;
  setUserContext: Function;
  setProfile: Function;
  showPopUp: (show: boolean) => void;
}

function StudentView(props: StudentViewProps) {
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const viewAsStudent = (gradeId: number) => {
    let sampleStudent;
    setLoading(true);
    getSampleStudent(gradeId)
      .then((res) => {
        if (res.data) {
          sampleStudent = res.data;
          handleSampleStudentLogin(sampleStudent);
        } else {
          createSampleStudent(
            gradeId,
            constant.StudentViewGradeLevel.find((g) => g.gradeId == gradeId)
              ?.gradeLevel ?? ""
          ).then((res) => {
            if (res.data) {
              sampleStudent = res.data;
              handleSampleStudentLogin(sampleStudent);
            }
          });
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleSampleStudentLogin = (user) => {
    setLoading(false);
    var impersonateUserDetails = new ImpersonateUserModel(
      user?.username,
      atob(user?.passwordHash),
      user?.accountId,
      props.setUserContext,
      props.setProfile,
      props.userContext?.userId,
      localStorage.getItem("AuthToken")?.toString(),
      history,
      routeConstant.ROUTE_DASHBOARD,
      props.userContext?.activeExternalRoster ?? ""
    );
    impersonateUser(impersonateUserDetails);
  };

  return (
    <>
      {loading && <Loader />}
      <div className="fixed inset-0 z-40 flex items-center bg-black bg-opacity-50 sm:items-center sm:justify-center">
        <div
          className="w-full flex flex-col justify-between overflow-hidden h-auto max-h-80percent bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:max-w-5xl"
          role="dialog"
        >
          <header>
            {/* Header */}
            <div className="px-4 py-4 bg-gray-50">
              <div className="flex items-start justify-between space-x-3">
                <div className="space-y-1">
                  <div className="text-lg font-medium text-gray-900">
                    View As Student
                  </div>
                </div>
                <div className="h-7 flex items-center">
                  <button
                    type="button"
                    className="text-gray-400 hover:text-gray-500"
                    onClick={() => props.showPopUp(false)}
                  >
                    <span className="sr-only">Close panel</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
          </header>
          <div className="w-full relative overflow-auto px-8 py-6">
            <h2 className="text-gray-700 text-sm mb-5">
              Select a grade level to access a sample student view:
            </h2>

            <div className="grid grid-cols-4 gap-5 mb-4">
              {constant.StudentViewGradeLevel?.map((gradeLevel, i) => (
                <label
                  x-radio-group-option=""
                  className={`relative cursor-pointer flex focus:outline-none border-gray-400 z-10`}
                  key={i}
                >
                  <div className="flex flex-col">
                    <span
                      onClick={() => viewAsStudent(gradeLevel.gradeId)}
                      id="privacy-setting-0-label"
                      className="bg-gray-100 px-2 py-1 rounded-lg border border-gray-100 hover:border hover:border-blue-500 flex items-center text-sm text-blue-500 cursor-pointer"
                    >
                      {gradeLevel.name}
                    </span>
                  </div>
                </label>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    setUserContext: (payload: userContextAction.UserContextState) => {
      dispatch(userContextAction.setUserContext(payload));
    },
    setProfile: (
      userId: number,
      roleId: number,
      schoolId: number,
      districtId: number
    ) => {
      dispatch(fetchProfile(userId, roleId, schoolId, districtId));
    },
  };
};
const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StudentView);

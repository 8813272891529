import React from "react";
import { PieChart, Pie, Tooltip, Legend, Cell } from "recharts";

export default function PIEChart({ data, innerRadius }: any): JSX.Element {
  const COLORS: string[] = [
    "#FF6484",
    "#36A3EB",
    "#FEA042",
    "#8C93AC",
    "#4AC1BF",
    "#36A3EB",
    "#807257",
    "#CCCBD0",
    "#CD924D",
    "#79C454",
    "#53BF9D",
    "#F94C66",
    "#FFC54D",
    "#BD4291",
    "#B4FF9F",
    "#FFA1A1",
    "#FFD59E",
    "#990000",
    "#D4D925",
    "#809A6F"
  ];

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active) {
      return (
        <div className="custom-tooltip text-lg font-medium">
          <p className="label">{`${payload?.[0].name} : ${payload?.[0].value} %`}</p>
        </div>
      );
    }
    return null;
  };

  return (
    <div>
      <PieChart width={250} height={240}>
        <Pie
          data={data}
          cx={100}
          cy={100}
          labelLine={false}
          innerRadius={innerRadius || 0}
          outerRadius={100}
          fill="#8884d8"
          dataKey="value"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Tooltip wrapperStyle={{backgroundColor:'#ffffff', color:'#fff', fontSize:'16px', borderRadius:'5px' }} content={<CustomTooltip />} />
        <Legend layout="vertical" verticalAlign="middle" align="center" />
      </PieChart>
    </div>
  );
}

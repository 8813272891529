import APIConstant from "../../utils/constant/apiConstant";
import { AxiosHelper } from "../../environment/axiosConfig";

export const getRandomQuestionsByStandardId = (standardId, questionTypeId,limit = 5) => {
  return AxiosHelper.axiosInstance().get(
    `${APIConstant.BellRinger_API.APP_GET_RANDOM_QUES}?StandardId=${standardId}&questionTypeId=${questionTypeId}&limit=${limit}`,
    {}
  );
};

export const getBellRinger = (bellRingerId: number, teacherId: number) => {
  return AxiosHelper.axiosInstance().get(
    `${APIConstant.BellRinger_API.APP_GET_BELL_RINGER}?BellRingerId=${bellRingerId}&TeacherId=${teacherId}`,
    {}
  );
};

export const getBellRingerAll = (
  teacherId: number,
  pageNumber: number,
  pageSize: number,
  OrderByColumn: string,
  sortByDesc: boolean,
  subjectId: number
) => {
  return AxiosHelper.axiosInstance().get(
    APIConstant.BellRinger_API.APP_GET_BELL_RINGER_ALL,
    {
      params: {
        teacherId: teacherId,
        subjectId: subjectId,
        pageNumber: pageNumber,
        pageSize: pageSize,
        OrderByColumn: OrderByColumn,
        sortByDesc: sortByDesc,
      },
    }
  );
};

export const postBellRinger = (params) => {
  return AxiosHelper.axiosInstance().post(
    `${APIConstant.BellRinger_API.APP_POST_BELL_RINGER}`,
    params,
    {}
  );
};

export const putBellRinger = (params) => {
  return AxiosHelper.axiosInstance().put(
    `${APIConstant.BellRinger_API.APP_PUT_BELL_RINGER}`,
    params,
    {}
  );
};

export const deleteBellRinger = (params) => {
  return AxiosHelper.axiosInstance().put(
    `${APIConstant.BellRinger_API.APP_DELETE_BELL_RINGER}`,
    params,
    {}
  );
};

export const favQuestion = (params) => {
  return AxiosHelper.axiosInstance().post(
    `${APIConstant.BellRinger_API.APP_POST_FAV_QUESTION}`,
    params,
    {}
  );
};

import { Fragment, useEffect, useState } from "react";
// import { RocketImage } from "../../../../assets/images";
import { useHistory } from "react-router-dom";
import RouteConstant from "../../../../utils/constant/routeConstant";
import classNames from "classnames";
import {
  createStudentAssignment,
  getOpponent,
  getSkillReport,
} from "../../../../api/student/skillPracticeApi";
import { getStudentRewards } from "../../../../api/student/studentApi";
import { UserContextState } from "../../../../redux/actions/userContextAction";
import { connect } from "react-redux";
import Profile from "../../../../model/users/profile";
import { ISkillOpponent } from "../../../../model/skillPractice/skillOpponent";
import { ISkillReport } from "../../../../model/skillPractice/skillReport";
import { ISkillStandard } from "../../../../model/skillPractice/skillStandard";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../../../css/style.css";
import Ranking from "../shared/skillPractice/skillPracticeRanking";
import { getSkillStandardDetail } from "../../../../api/skillStandard/skillStandard";
import SkillStudentStats from "./skillStudentStats";
import { Configuration } from "../../../../environment/setup";
import { useTranslation } from "react-i18next";
import useTextToSpeech from "../shared/textToSpeech/useTextToSpeech";
import constant from "../../../../utils/constant/constant";
import { getStudentSchoolLevelByGrade } from "../../../../utils/helper";
import { StudentSchoolLevel } from "../../../../types/type";

interface GameChooseProps {
  userContext?: UserContextState;
  profile?: Profile;
  standardId: number;
  gradeId?: number;
  match?: any;
}

const theme = {
  ELEMENTARYHOME: {
    bgcolor: "bg-white ",
    breadcrumbcolor: "text-gray-500 ",
    breadcrumbborder: "border-gray-400 ",
    pgtitle: "",
  },
  KINDERGARTEN: {
    bgcolor: "bg-white mx-8",
    breadcrumbcolor: "text-gray-500 ",
    breadcrumbborder: "border-gray-400 hover:text-gray-700 ",
    pgtitle: "",
  },
  MIDDLESCHOOLHOME: {
    bgcolor: "",
    breadcrumbcolor: "text-white ",
    breadcrumbborder: "border-white ",
    pgtitle: "text-white ",
  },
};

function GameChoose(props: GameChooseProps) {
  const [selectedRocket, setSelectedRocket] = useState<number>(1);
  const [rockets, setRockets] = useState<Array<any>>([]);
  const [opponentUser, setOpponentUser] = useState<ISkillOpponent>();
  const [userSkillReport, setUserSkillReport] = useState<ISkillReport>();
  const [opponentSkillReport, setOpponentSkillReport] =
    useState<ISkillReport>();
  const [showNationalRanking, setShowNationalRanking] =
    useState<boolean>(false);
  const [showOpponentNationalRanking, setShowOpponentNationalRanking] =
    useState<boolean>(false);
  const [skillStandard, setSkillStandard] = useState<ISkillStandard>();
  const { params } = props.match;
  const history = useHistory();
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const { startSpeaking, stopSpeaking } = useTextToSpeech();
  const settings = {
    dots: false,
    infinite: true,
    autoplaySpeed: 2000,
    autoplay: false,
    slidesToShow: 5,
    slidesToScroll: 4,
    lazyLoad: true,
  };
  const { t } = useTranslation();

  const path: string = window.location.pathname;
  const themeName =
    path.indexOf("kindergarten") > -1
      ? "KINDERGARTEN"
      : path.indexOf("middleSchool") > -1
      ? "MIDDLESCHOOLHOME"
      : "ELEMENTARYHOME";

  useEffect(() => {
    getStudentRewards(props.userContext?.userId ?? 0).then((d) => {
      setRocketValues(d.data.plRockets);
    });
    function setRocketValues(rockets: Array<any>) {
      const rocketImageDefaultPath: string = `${Configuration.S3bucketImagePath}/images/student/kindergarten/rockets/`;
      const tempRocket: Array<any> = [];
      rockets?.map((rocket, index) => {
        tempRocket.push({
          rocketId: rocket.rocketId,
          path: rocketImageDefaultPath + "rocket" + index.toString() + ".png",
          isEnabled: !rocket.isLocked,
        });
        return null;
      });
      setRockets(tempRocket);
    }

    getOpponent(
      props.userContext?.userId ?? 0,
      props.userContext?.gradeId ?? 0,
      params.standardId
    ).then((d) => {
      setOpponentUser(d.data);
      getOpponentSkillReport(
        d.data.studentId ?? 0,
        d.data.schoolAccountId ?? 0
      );
    });

    // current user
    getSkillReport(
      params.standardId,
      props.userContext?.userId ?? 0,
      props.userContext?.schoolAccountId ?? 0
    ).then((d) => {
      setUserSkillReport(d.data);
      setIsLoaded(true);
    });
    if (params.standardId > 0) {
      getSkillStandardDetail(params.standardId).then((d) => {
        setSkillStandard(d.data);
      });
    }
  }, []);

  function getOpponentSkillReport(studentId: number, schoolAccountId: number) {
    if (studentId === -1) {
      //dummy opponent
      var dummySkillReport: ISkillReport = {
        looseCount: randomNumber(1, 20),
        winCount: randomNumber(1, 20),
        tieCount: randomNumber(1, 10),
        schoolRank: randomNumber(1, 50),
        totalAnswers: randomNumber(70, 100),
        totalCorrectAnswers: randomNumber(30, 60),
        winPercentage: 0,
      };
      dummySkillReport.winPercentage = Math.floor(
        (dummySkillReport.winCount /
          (dummySkillReport.winCount +
            dummySkillReport.looseCount +
            dummySkillReport.tieCount)) *
          100
      );
      setOpponentSkillReport(dummySkillReport);
    } else {
      getSkillReport(params.standardId, studentId, schoolAccountId).then(
        (d) => {
          setOpponentSkillReport(d.data);
        }
      );
    }
  }
  function randomNumber(min, max): number {
    return Math.floor(Math.random() * (max - min) + min);
  }
  function createSkillPracticeTest() {
    let dummyOpponentDetails = "";
    if (opponentUser?.skillStudentAssignmentId === 0) {
      let dummyOpponentUser = opponentUser;
      delete dummyOpponentUser["studentId"];
      delete dummyOpponentUser["skillStudentAssignmentId"];
      delete dummyOpponentUser["rocketId"];
      delete dummyOpponentUser["schoolId"];
      dummyOpponentUser.attendedQuestionCount = 15;
      dummyOpponentUser.attendedAnswers = "101010101010101";
      dummyOpponentUser.attendedQuestionCorrectCount = 8;
      dummyOpponentUser.rocketId = 1;
      dummyOpponentDetails = JSON.stringify(opponentUser);
    }
    createStudentAssignment(
      params.standardId,
      props.userContext?.userId ?? 0,
      opponentUser?.studentId ?? 0,
      opponentUser?.skillStudentAssignmentId ?? 0,
      dummyOpponentDetails,
      selectedRocket,
      opponentUser?.rocketId ?? 0
    ).then((d) => {
      // setStudentAssignmentId(d.data);
      if (d.data > 0) {
        const query: string = window.location.pathname;
        let url = "";
        let KinderUrl =
          RouteConstant.KindergartenStudentRoutes.SkillGameChoose.replace(
            ":standardId",
            params.standardId.toString()
          );
        url =
          query.indexOf(KinderUrl) > -1
            ? RouteConstant.KindergartenStudentRoutes.SkillPracticeTest
            : query.indexOf("middleSchool") > -1
            ? RouteConstant.MiddleSchool.SkillPracticeTest
            : RouteConstant.StudentRoute.SkillPracticeTest;
        const skillPracticeTestUrl = url
          .replace(":skillStandardId", params.standardId.toString())
          .replace(":skillStudentAssignmentId", d.data.toString());
        window.location.href = skillPracticeTestUrl;
      }
    });
  }

  function redirectToHome() {
    const gradeId = props.userContext?.gradeId ?? 0;
    const schoolLevel = getStudentSchoolLevelByGrade(gradeId);

    let homeUrl = RouteConstant.MiddleSchool.Home;

    switch (schoolLevel) {
      case StudentSchoolLevel.Kindergarten:
        homeUrl = RouteConstant.KindergartenStudentRoutes.Home;
        break;
      case StudentSchoolLevel.Elementary:
        homeUrl = RouteConstant.StudentRoute.Home;
        break;
    }

    history.push({
      pathname: homeUrl,
    });
  }

  return (
    <Fragment>
      {/* <div className="bg-white h-full min-w-0 w-full mb-4 rounded-2xl p-5"> */}
      <div
        className={` ${theme[themeName]?.bgcolor} h-full min-w-0 mb-4 rounded-2xl p-5`}
      >
        <div className="md:flex md:items-center border-b">
          <ol
            className={`${theme[themeName]?.breadcrumbcolor} md:w-[45%] flex items-center space-x-2 mb-1`}
          >
            <li>
              <div className="flex items-center">
                <span
                  onClick={redirectToHome}
                  className={`${theme[themeName]?.breadcrumbborder} cursor-pointer text-sm border-dashed border-b hover:border-opacity-0`}
                >
                  {t("Breadcrumb.Home")}
                </span>
              </div>
            </li>

            <li>
              <div className="flex items-center">
                <svg
                  className="flex-shrink-0 h-5 w-5"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
                <span className="ml-1 text-sm ">
                  {t("Breadcrumb.SkillsPractice")}
                </span>
              </div>
            </li>
          </ol>
          <div className="md:w-[55%] text-lg font-medium flex gap-2 h-auto sm:h-10 items-center">
            <h1
              className={` ${theme[themeName]?.pgtitle} font-medium flex justify-start mb-0 pb-0`}
            >
              {t("StudyType.SkillPractice")}
            </h1>
          </div>
        </div>

        <div className="sm:px-6  lg:px-8 py-8 relative">
          <section className="px-2 text-center mb-6">
            <div>
              <h1
                className={`${theme[themeName]?.pgtitle} text-lg font-semibold`}
              >
                {skillStandard?.skillSubjectName}
              </h1>
              <h2 className={`${theme[themeName]?.pgtitle}`}>
                {(props.profile?.plLanguageId ?? 1) ===
                constant.Languages.Spanish
                  ? skillStandard?.spanishName ?? skillStandard?.name
                  : skillStandard?.name}
              </h2>
            </div>
          </section>

          <div className="w-full xl:w-3/4 mx-auto shadow border bg-gray-100 rounded-md flex flex-col px-6 relative pt-12 pb-6">
            <div className="sm:flex justify-between items-center left-0 w-full pb-6">
              <div
                className="bg-secondary-teal text-white rounded-md py-4 px-4 border sm:w-1/2 text-center sm:text-right"
                onMouseEnter={() => {
                  if (props.profile?.schoolName) {
                    startSpeaking(
                      props.profile?.schoolName,
                      (props.profile?.plLanguageId ?? 1) === 2 ? 2 : 1
                    );
                  }
                }}
                onMouseLeave={() => {
                  stopSpeaking();
                }}
              >
                <div className="text-lg font-semibold">
                  {props.profile?.firstName.charAt(0) +
                    "." +
                    props.profile?.lastName.charAt(0)}
                </div>
                <div className="text-xs">{props.profile?.schoolName}</div>
              </div>

              <div className="flex flex-col items-center relative mx-4 my-4">
                <span className="inline-block w-full flex justify-center">
                  {t("SkillPractice.Ranking")}
                </span>
                <span className="w-full inline-block flex  justify-center space-x-4 text-2xl font-semibold">
                  <div
                    className={`${
                      userSkillReport?.schoolRank === 0
                        ? "text-gray-400"
                        : (opponentSkillReport?.schoolRank ?? 0) === 0 ||
                          (userSkillReport?.schoolRank ?? 0) <
                            (opponentSkillReport?.schoolRank ?? 0)
                        ? "text-green-400"
                        : "text-red-400"
                    } font-semibold`}
                  >
                    {userSkillReport?.schoolRank}
                  </div>
                  <div
                    className={`${
                      opponentSkillReport?.schoolRank === 0
                        ? "text-gray-400"
                        : (userSkillReport?.schoolRank ?? 0) === 0 ||
                          (opponentSkillReport?.schoolRank ?? 0) <
                            (userSkillReport?.schoolRank ?? 0)
                        ? "text-green-400"
                        : "text-red-400"
                    } font-semibold`}
                  >
                    {opponentSkillReport?.schoolRank}
                  </div>
                </span>
              </div>

              <div
                className="bg-primary-violet text-white rounded-md py-4 px-4 border sm:w-1/2 text-center sm:text-right"
                onMouseEnter={() => {
                  if (opponentUser?.schoolName) {
                    startSpeaking(
                      opponentUser?.schoolName,
                      (props.profile?.plLanguageId ?? 1) === 2 ? 2 : 1
                    );
                  }
                }}
                onMouseLeave={() => {
                  stopSpeaking();
                }}
              >
                <div className="text-lg font-semibold">
                  {opponentUser?.firstName.charAt(0) +
                    "." +
                    opponentUser?.lastName.charAt(0)}
                </div>
                <div className="text-xs">{opponentUser?.schoolName}</div>
              </div>
            </div>
            {isLoaded && (
              <>
                <SkillStudentStats
                  statName={t("SkillPractice.Wins")}
                  studentData={userSkillReport?.winCount ?? 0}
                  opponentData={opponentSkillReport?.winCount ?? 0}
                  studentTotal={
                    (userSkillReport?.winCount ?? 0) +
                    (userSkillReport?.looseCount ?? 0) +
                    (userSkillReport?.tieCount ?? 0)
                  }
                  opponentTotal={
                    (opponentSkillReport?.winCount ?? 0) +
                    (opponentSkillReport?.looseCount ?? 0) +
                    (opponentSkillReport?.tieCount ?? 0)
                  }
                />
                <SkillStudentStats
                  statName={t("SkillPractice.Losses")}
                  studentData={userSkillReport?.looseCount ?? 0}
                  opponentData={opponentSkillReport?.looseCount ?? 0}
                  studentTotal={
                    (userSkillReport?.winCount ?? 0) +
                    (userSkillReport?.looseCount ?? 0) +
                    (userSkillReport?.tieCount ?? 0)
                  }
                  opponentTotal={
                    (opponentSkillReport?.winCount ?? 0) +
                    (opponentSkillReport?.looseCount ?? 0) +
                    (opponentSkillReport?.tieCount ?? 0)
                  }
                />
                <SkillStudentStats
                  statName={t("SkillPractice.Ties")}
                  studentData={userSkillReport?.tieCount ?? 0}
                  opponentData={opponentSkillReport?.tieCount ?? 0}
                  studentTotal={
                    (userSkillReport?.winCount ?? 0) +
                    (userSkillReport?.looseCount ?? 0) +
                    (userSkillReport?.tieCount ?? 0)
                  }
                  opponentTotal={
                    (opponentSkillReport?.winCount ?? 0) +
                    (opponentSkillReport?.looseCount ?? 0) +
                    (opponentSkillReport?.tieCount ?? 0)
                  }
                />
              </>
            )}
            <div className="px-4 gap-8 grid grid-cols-2">
              <div className="">
                <Slider {...settings}>
                  {rockets.map((item) => {
                    return (
                      <div key={item.rocketId}>
                        <button
                          disabled={item.isEnabled ? false : true}
                          className={classNames(
                            "disabled:opacity-50 flex border-2 border-gray-200 p-1 items-center rounded w-16 h-20  bg-gray-50  inline text-center ml-2 group transition-all",
                            selectedRocket === item.rocketId && item.isEnabled
                              ? "border-primary-violet bg-white transition-all"
                              : "",
                            item.isEnabled
                              ? "cursor-pointer hover:border-primary-violet hover:bg-white"
                              : "cursor-not-allowed"
                          )}
                          onClick={() => {
                            if (item.isEnabled)
                              setSelectedRocket(item.rocketId);
                          }}
                        >
                          <img
                            srcSet={item.path}
                            className="h-16 mx-auto group-hover:h-14 transition-all"
                            alt="rocket"
                          ></img>
                        </button>
                      </div>
                    );
                  })}
                </Slider>
              </div>
              <div className="">
                <div
                  className={
                    "flex border-2  p-1 items-center rounded w-24 h-20 cursor-pointer border-primary-violet hover:bg-white inline text-center ml-2 group  bg-white transition-all"
                  }
                >
                  <img
                    srcSet={
                      Configuration.S3bucketImagePath +
                      "/images/student/kindergarten/rockets/rocket1.png"
                    }
                    className="h-16 mx-auto group-hover:h-14 transition-all"
                    alt="rockets"
                  ></img>
                </div>
              </div>
            </div>
            <div className="w-full flex flex-col mt-5">
              <div className="flex justify-center mb-2 text-xl font-semibold mb-4">
                {t("SkillPractice.Rankings")}
              </div>
              <div className="w-full md:flex justify-between space-y-4 md:space-y-0 md:space-x-4">
                <div className="bg-white border w-full rounded-md p-4 flex flex-col border-4">
                  <div className="flex w-full mt-1 border-b-4">
                    <div
                      onClick={() => {
                        setShowNationalRanking(false);
                      }}
                      onMouseEnter={() => {
                        startSpeaking(
                          t("SkillPractice.School"),
                          (props.profile?.plLanguageId ?? 1) === 2 ? 2 : 1
                        );
                      }}
                      onMouseLeave={() => {
                        stopSpeaking();
                      }}
                      className={`${
                        showNationalRanking
                          ? "bg-secondary-teal/20 border-secondary-teal border-opacity-20 hover:border-secondary-teal/50"
                          : "bg-secondary-teal text-white border-secondary-teal"
                      } border-b-4 rounded-tl w-20 text-sm font-medium cursor-pointer  relative top-1 px-3 py-1 text-center `}
                    >
                      <span
                        className={`${
                          showOpponentNationalRanking ? "" : ""
                        } inline-block`}
                      >
                        {t("SkillPractice.School")}
                      </span>
                    </div>
                    <div
                      onClick={() => {
                        setShowNationalRanking(true);
                      }}
                      onMouseEnter={() => {
                        startSpeaking(
                          t("SkillPractice.National"),
                          (props.profile?.plLanguageId ?? 1) === 2 ? 2 : 1
                        );
                      }}
                      onMouseLeave={() => {
                        stopSpeaking();
                      }}
                      className={`${
                        showNationalRanking
                          ? "bg-secondary-teal text-white border-secondary-teal"
                          : "bg-secondary-teal/20 border-secondary-teal border-opacity-20 hover:border-secondary-teal/50 "
                      } border-b-4 rounded-tr w-20 text-sm font-medium cursor-pointer  pl-2  relative top-1 px-3 py-1  text-center`}
                    >
                      {t("SkillPractice.National")}
                    </div>
                  </div>

                  {!showNationalRanking && (
                    <Ranking
                      skillStandardId={params.standardId}
                      schoolAccountId={props.userContext?.schoolAccountId ?? -1}
                      studentId={props.userContext?.userId ?? 0}
                      isSchoolRanking={true}
                    ></Ranking>
                  )}
                  {showNationalRanking && (
                    <Ranking
                      skillStandardId={params.standardId}
                      schoolAccountId={0}
                      studentId={props.userContext?.userId ?? 0}
                      isSchoolRanking={false}
                    ></Ranking>
                  )}
                </div>
                <div className="bg-white border w-full rounded-md p-4 flex flex-col  border-4">
                  <div className="flex w-full mt-1 border-b-4">
                    <div
                      onClick={() => {
                        setShowOpponentNationalRanking(false);
                      }}
                      onMouseEnter={() => {
                        startSpeaking(
                          t("SkillPractice.School"),
                          (props.profile?.plLanguageId ?? 1) === 2 ? 2 : 1
                        );
                      }}
                      onMouseLeave={() => {
                        stopSpeaking();
                      }}
                      className={`${
                        showOpponentNationalRanking
                          ? "bg-primary-violet bg-opacity-20 border-primary-violet border-opacity-20 hover:border-primary-violet/50"
                          : "bg-primary-violet text-white border-primary-violet"
                      } border-b-4 rounded-tl w-20 text-sm font-medium cursor-pointer  pl-2  relative top-1 px-3 py-1 text-center`}
                    >
                      <span className="inline-block">
                        {t("SkillPractice.School")}
                      </span>
                    </div>
                    <div
                      onClick={() => {
                        setShowOpponentNationalRanking(true);
                      }}
                      onMouseEnter={() => {
                        startSpeaking(
                          t("SkillPractice.National"),
                          (props.profile?.plLanguageId ?? 1) === 2 ? 2 : 1
                        );
                      }}
                      onMouseLeave={() => {
                        stopSpeaking();
                      }}
                      className={`${
                        showOpponentNationalRanking
                          ? "bg-primary-violet text-white border-primary-violet"
                          : "bg-primary-violet bg-opacity-20 border-primary-violet border-opacity-20 hover:border-primary-violet/50"
                      } border-b-4 rounded-tr w-20 text-sm font-medium cursor-pointer  pl-2  relative top-1 px-3 py-1  text-center`}
                    >
                      <span className="inline-block">
                        {t("SkillPractice.National")}
                      </span>
                    </div>
                  </div>
                  {!showOpponentNationalRanking && (
                    <Ranking
                      skillStandardId={params.standardId}
                      schoolAccountId={
                        opponentUser?.schoolId === -1
                          ? -1
                          : opponentUser?.schoolAccountId ?? -1
                      }
                      studentId={opponentUser?.studentId ?? 0}
                      isSchoolRanking={true}
                    ></Ranking>
                  )}
                  {showOpponentNationalRanking && (
                    <Ranking
                      skillStandardId={params.standardId}
                      schoolAccountId={0}
                      studentId={opponentUser?.studentId ?? 0}
                      isSchoolRanking={false}
                    ></Ranking>
                  )}
                </div>
              </div>

              <div className="text-center mt-8">
                <button
                  onClick={() => {
                    createSkillPracticeTest();
                  }}
                  className={
                    "rounded bg-primary-violet border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-dark-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 " +
                    (params.standardId && parseInt(params.standardId) > 0
                      ? ""
                      : "bg-opacity-20 text-primary-violet text-opacity-40 pointer-events-none")
                  }
                  type="button"
                  onMouseEnter={() => {
                    startSpeaking(
                      t("SkillPractice.StartAlienRace"),
                      (props.profile?.plLanguageId ?? 1) === 2 ? 2 : 1
                    );
                  }}
                  onMouseLeave={() => {
                    stopSpeaking();
                  }}
                  disabled={
                    !(params.standardId && parseInt(params.standardId) > 0)
                  }
                >
                  {t("SkillPractice.StartAlienRace")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(GameChoose);

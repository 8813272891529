import constant from "../../../../utils/constant/constant";
import { toast } from "react-toastify";
export const isAnswerSet = (element: any) => {
  let isValid = true;
  if (
    element?.type === "mcq" &&
    element?.validation.valid_response.value.length === 0
  ) {
    isValid = false;
    toast.error(constant.QuestionBuilderErrors.NoCorrectAnswers);
  } else if (
    (element?.type === "clozedropdown" ||
      element?.type === "imageclozeassociationV2" ||
      element?.type === "choicematrix" ||
      element?.type === "classification" ||
      element?.type === "association") &&
    (element?.validation?.valid_response?.value.length === 0 ||
      (Array.isArray(element?.validation?.valid_response?.value) &&
        element?.validation?.valid_response?.value.length > 0 &&
        element?.validation?.valid_response?.value.some(
          (x) => x === null || (Array.isArray(x) && x.length === 0)
        )))
  ) {
    isValid = false;
    toast.error(constant.QuestionBuilderErrors.NoCorrectAnswers);
  } else if (
    element?.type === "shorttext" &&
    !Array.isArray(element?.validation?.valid_response?.value) &&
    element?.validation?.valid_response?.value === ""
  ) {
    isValid = false;
    toast.error(constant.QuestionBuilderErrors.NoCorrectAnswers);
  } else if (
    element?.type === "clozeformula" &&
    !isValidadClozeFormulaElements(element)
  ) {
    isValid = false;
    toast.error(constant.QuestionBuilderErrors.NoCorrectAnswers);
  } else if (
    element?.type === "graphplotting" &&
    element?.validation.valid_response.value.length === 0
  ) {
    isValid = true;
    toast.warning(constant.QuestionBuilderErrors.NoCorrectAnswers);
  } else if (element?.type === "simplechart") {
    if (element?.validation.valid_response.value.length === 0) {
      isValid = false;
      toast.error(constant.QuestionBuilderErrors.NoCorrectAnswers);
    } else if (
      element?.validation.valid_response.value.length > 0 &&
      !element?.validation?.valid_response?.value.some((x) => x["y"] > 0)
    ) {
      isValid = true;
      toast.warning(constant.QuestionBuilderErrors.NoCorrectAnswers);
    }
  }
  return isValid;
};

function isValidadClozeFormulaElements(element) {
  if (element.value !== undefined) {
    if (element.value !== null || element.value !== "") {
      return false;
    }
  } else {
    if (Array.isArray(element?.validation.valid_response.value)) {
      return IsValidArrayElement(element);
    }
  }
  return true;
}

function IsValidArrayElement(arrayElement) {
  let isValid = true;
  if (arrayElement?.validation.valid_response.value.length > 0) {
    arrayElement?.validation?.valid_response?.value.some((element) => {
      if (Array.isArray(element)) {
        if (element.length === 0) {
          isValid = false;
        }
        element.some((subElement) => {
          if (subElement.value === null || subElement.value === "") {
            isValid = false;
          }
        });
      }
    });
  } else {
    isValid = false;
  }
  return isValid;
}

import { Fragment, useEffect, useState } from "react";
import {
  exportResultsAssessmentStudentDetailsByClassExcel,
  exportResultsAssignmentStudentDetailsByClassExcel,
  exportResultsPremiumAssessmentStudentDetailsByClassExcel,
  getResultsAssessmentStudentDetailsByClass,
  getResultsAssignmentStudentDetailsByClass,
  getResultsPremiumAssessmentStudentDetailsByClass,
} from "../../../../../../api/report/resultsReport";
import { getActivityName } from "../../../../../../utils/assignmentHelper";
import {
  DEFAULT_PAGE_NUMBER,
  DEFAULT_PAGE_SIZE,
} from "../../../../../../utils/pagingConstant";
import Loader from "../../../../../shared/loader";
import NoData from "../../../../../shared/noData";
import PageSize from "../../../../../shared/pagination/pageSize";
import Pagination from "../../../../../shared/pagination/pagination";
import permissions from "../../../../../../utils/permissions";
import ExportControl from "../../../../../shared/customButtonControl";
import { getCssForDisabled } from "../../../../../shared/permissionHelper";
import { UserContextState } from "../../../../../../redux/actions/userContextAction";
import constant from "../../../../../../utils/constant/constant";

interface IResultReportAssignmentStudentDetailsByClassInput {
  schoolYearId: number;
  assignmentActivityId?: number;
  type: string;
  classIds: number[];
  assignment: any;
  userContext: UserContextState;
  redirectedFrom: string;
}

export const ResultReportAssignmentStudentDetailsByClass = (
  props: IResultReportAssignmentStudentDetailsByClassInput
) => {
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [pageNumber, setPageNumber] = useState(DEFAULT_PAGE_NUMBER);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [resetPageNumber, setResetPageNumber] = useState<boolean>(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [resultDetails, setResultDetails] = useState<Array<any>>([]);
  const [isClassChanged, setIsClassChanged] = useState<boolean>(true);

  const onPageChange = (pageNo) => {
    setIsClassChanged(false);
    setPageNumber(pageNo);
  };

  const onPageSizeChange = (pageSize) => {
    setIsClassChanged(false);
    setPageNumber(DEFAULT_PAGE_NUMBER);
    setPageSize(pageSize);
    setResetPageNumber(true);
  };

  const getAssignmentsStudentDetailsByClass = (
    schoolYearId: number,
    classIds: number[],
    assignmentActivityId?: number,
    pageNumber?: number,
    pageSize?: number,
  ) => {
    getReportByType(schoolYearId, classIds, assignmentActivityId, pageNumber, pageSize);
  };

  const getReportByType = (
    schoolYearId: number,
    classIds: number[],
    assignmentActivityId?: number,
    pageNumber?: number,
    pageSize?: number,
  ) => {
    setShowLoader(true);
    if (props.type.toUpperCase() === "ASSIGNMENT") {
      getResultsAssignmentStudentDetailsByClass(
        schoolYearId,
        classIds.includes(0) ? null : classIds,
        assignmentActivityId,
        pageNumber,
        pageSize
      ).then((response) => {
        setShowLoader(false);
        if (!response.data.data) {
          setErrorMessage("No record found");
          setResultDetails([]);
          setTotalRecords(0);
        } else {
          setResultDetails(response.data.data.data);
          setTotalRecords(response.data.data.totalRecords);
        }
      });
    } else if (
      props.type.toUpperCase() === constant.AssessmentTypes.ASSESSMENT
    ) {
      getResultsAssessmentStudentDetailsByClass(
        props.schoolYearId,
        classIds.includes(0) ? null : classIds,
        assignmentActivityId,
        pageNumber,
        pageSize).then((response) => {
        setShowLoader(false);
        if (!response.data.data) {
          setErrorMessage("No record found");
          setResultDetails([]);
          setTotalRecords(0);
        } else {
          setResultDetails(response.data.data.data);
          setTotalRecords(response.data.data.totalRecords);
        }
      });
    } else if (
      props.type.toUpperCase() === constant.AssessmentTypes.PREMIUMASSESSMENT
    ) {
      getResultsPremiumAssessmentStudentDetailsByClass(
        props.schoolYearId,
        classIds.includes(0) ? null : classIds,
        assignmentActivityId,
        pageNumber,
        pageSize
      ).then((response) => {
        setShowLoader(false);
        if (!response.data.data) {
          setErrorMessage("No record found");
          setResultDetails([]);
          setTotalRecords(0);
        } else {
          setResultDetails(response.data.data.data);
          setTotalRecords(response.data.data.totalRecords);
        }
      });
    }
  };

  const exportToExcelByType = () => {
    setShowLoader(true);
    if (props.type.toUpperCase() === "ASSIGNMENT") {
      exportResultsAssignmentStudentDetailsByClassExcel(
        props.schoolYearId,
        props.classIds.includes(0) ? null : props.classIds,
        props.assignmentActivityId,
        getActivityName(props.assignment.activities[0]),
        props.assignment.activities[0].activityType.toUpperCase()
      ).then((response) => {
        setShowLoader(false);
        exportToExcel(response);
      });
    } else if (
      props.type.toUpperCase() === constant.AssessmentTypes.ASSESSMENT
    ) {
      exportResultsAssessmentStudentDetailsByClassExcel(
        props.schoolYearId,
        props.classIds.includes(0) ? null : props.classIds,
        props.assignmentActivityId
      ).then((response) => {
        setShowLoader(false);
        exportToExcel(response);
      });
    } else if (
      props.type.toUpperCase() === constant.AssessmentTypes.PREMIUMASSESSMENT
    ) {
      exportResultsPremiumAssessmentStudentDetailsByClassExcel(
        props.schoolYearId,
        props.classIds.includes(0) ? null : props.classIds,
        props.assignmentActivityId
      ).then((response) => {
        setShowLoader(false);
        exportToExcel(response);
      });
    }
  };
  const exportToExcel = (response) => {
    const file = response.data;
    const fileURL = URL.createObjectURL(file);
    const link = document.createElement("a");
    link.href = fileURL;
    link.download = "studentdetails.xlsx";
    link.setAttribute("target", "_blank");
    document.body.appendChild(link);
    link.click();
  };

  useEffect(() => {
    if (!isClassChanged) {
      getAssignmentsStudentDetailsByClass(
        props.schoolYearId,
        props.classIds,
        props.assignmentActivityId,
        pageNumber,
        pageSize);
    }
  }, [pageSize, pageNumber, resetPageNumber]);

  useEffect(() => {
    setResultDetails([]);
    setIsClassChanged(true);
    setPageNumber(DEFAULT_PAGE_NUMBER);
    setPageSize(DEFAULT_PAGE_SIZE);
    setResetPageNumber(false);
    getAssignmentsStudentDetailsByClass(
      props.schoolYearId,
      props.classIds,
      props.assignmentActivityId,
      DEFAULT_PAGE_NUMBER,
      DEFAULT_PAGE_SIZE);
  }, [JSON.stringify(props.classIds)]);

  return (
    <>
      {showLoader && <Loader></Loader>}
      {errorMessage !== "" && <div className="p-3">{errorMessage}</div>}
      {resultDetails && (
        <div className="mt-5">
          <div className="flex w-full justify-end items-center mb-4">
            <div className="px-5">
              Use the Export button to show all Student data for those that have
              been Assigned, Completed, Not Completed and Taken By Code.
            </div>

            <ExportControl
              controlType={"button"}
              controlText={"Export"}
              userContext={props.userContext}
              permissionName={permissions.reports_export_upgrade}
              className={
                (resultDetails && resultDetails.length > 0
                  ? "bg-primary-violet border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-dark-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3 "
                  : "pointer-events-none text-gray-400 bg-gray ") +
                getCssForDisabled(
                  permissions.reports_export_upgrade,
                  " bg-primary-violet hover:bg-primary-violet hover:bg-opacity-20 "
                )
              }
              schoolYearId={props.schoolYearId}
              onClick={exportToExcelByType}
            ></ExportControl>
          </div>

          <div>
            <div className="overflow-x-auto xl:overflow-x-visible">
              <table className="table w-full ">
                <thead>
                  <tr className="bg-primary-violet  text-white ">
                    <th className="text-left p-3 text-sm font-normal">Class</th>
                    <th className="text-center p-3 text-sm font-normal">
                      Total Assigned
                    </th>
                    <th className="text-center p-3 text-sm font-normal">
                      Completed
                    </th>
                    <th className="text-center p-3 text-sm font-normal">
                      Pending
                    </th>
                    {(props.type.toUpperCase() === "ASSESSMENT" ||
                      (props.assignment &&
                        props.assignment.activities[0].activityType.toUpperCase() ===
                          "ASSESSMENT")) && (
                      <th className="text-center p-3 text-sm font-normal">
                        Taken By Code
                      </th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {resultDetails &&
                    resultDetails?.map((student, index) => {
                      return (
                        <Fragment>
                          <tr
                            className={`hover:bg-primary-violet/20 transition ease-in-out duration-300 ${
                              index % 2 === 0
                                ? "bg-white"
                                : "bg-primary-violet/10"
                            }`}
                          >
                            <td className="text-sm p-3 ">
                              {student.displayName}
                            </td>
                            <td className="text-sm p-3 text-center ">
                              {student.assignedCount}
                            </td>
                            <td className="text-sm p-3 text-center">
                              {student.completedCount}
                            </td>
                            <td className="text-sm p-3 text-center">
                              {student.pendingCount}
                            </td>
                            {(props.type.toUpperCase() === "ASSESSMENT" ||
                              (props.assignment &&
                                props.assignment.activities[0].activityType.toUpperCase() ===
                                  "ASSESSMENT")) && (
                              <td className="text-sm p-3 text-center">
                                {student.takenByCodeCount}
                              </td>
                            )}
                          </tr>
                        </Fragment>
                      );
                    })}
                </tbody>
              </table>
            </div>

            {resultDetails?.length === 0 && <NoData />}
            {resultDetails?.length !== 0 && (
              <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between border-t-2 pt-4 mt-3">
                <Pagination
                  totalRecords={totalRecords}
                  pageNumber={pageNumber}
                  pageSize={pageSize}
                  onPageChange={onPageChange}
                  reset={resetPageNumber}
                  setPageNumber={setPageNumber}
                />
                <PageSize
                  pageSize={pageSize}
                  onPageSizeChange={onPageSizeChange}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import Slider from "react-slick";
import { toast } from "react-toastify";
import ReactTooltip from "react-tooltip";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { login } from "../../api/login.service";
import { syncTeacherClasses } from "../../api/thirdPartyIntegration/thirdPartyIntegration";
import {
  CancelIcon,
  CometIcon,
  CrossIcon,
  DNAIcon,
  EdGalaxyLogo,
  ExclamationIcon,
  GoogleLoginIcon,
  LoginSearchIcon,
  LogoIcon,
  RightArrow,
  UsaTestPrepLogo,
  CanvasIcon,
} from "../../assets/icons/index";
import "../../css/style.css";
import { CanvasConfiguration, Configuration } from "../../environment/setup";
import LoginErrorResponse from "../../model/loginErrorResponse";
import { fetchProfile, setProfile } from "../../redux/actions/userActions";
import * as userContextAction from "../../redux/actions/userContextAction";
import Constant from "../../utils/constant/constant";
import RouteConstant from "../../utils/constant/routeConstant";
import { getThemeByGrade } from "../../utils/helper";
import auth from "../../utils/auth";
import { CanvasApi } from "../../api/canvas/canvasApi";
import { handleCanvasAuthentication } from "../../utils/canvasHelper";
import { jwtToken } from "../shared/tokenHelper";
import Loader from "../shared/loader";
import { setSessionValues } from "../../redux/actions/sessionValuesAction";
import { sessionValuesInitialState } from "../../redux/reducers/sessionValuesReducer";

interface LoginContainerProps {
  fetchLoginData: any;
  token: string;
  history: string;
}

const Login: React.FC<LoginContainerProps> = (props: any) => {
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [accountLoginName, setAccountLoginName] = useState("");
  const history = useHistory();
  const [input, setInputFlag] = useState(false);
  const [isValidFlag, setIsValidFlag] = useState(true);
  const [crossIcon, setShowCrossIcon] = useState(false);
  const [loading, setLoading] = useState(false);
  const [load, setLoad] = useState(false);
  const [loginErrorMessage, setLoginErrorMessage] = useState<string>("");
  const location = useLocation();
  const parameters = location.state as any;
  const settings = {
    dots: true,
    infinite: true,
    autoplaySpeed: 6000,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const dispatch = useDispatch();

  useEffect(() => {
    if (parameters?.logoutClicked) {
      auth.clearSession();
      dispatch(setProfile({}));
      dispatch(setSessionValues(sessionValuesInitialState));
      localStorage.removeItem('teacherWelcomeSurveyAlreadyShown');
    }
    var accountLoginName = localStorage.getItem("accountLoginName");
    if (accountLoginName) {
      setAccountLoginName(accountLoginName);
    }

    const queryParameters = props.history.location.state;
    if (queryParameters && queryParameters.loginFailedType) {
      const loginFailedType = queryParameters.loginFailedType;
      const errorMessage = `Oops! It looks like your school is rostered by ${loginFailedType}. Please contact an administrator for assistance getting your account shared via ${loginFailedType}`;
      setLoginErrorMessage(errorMessage);
    }

    const loginLinkId = new URLSearchParams(props.location.search).get("loginLinkId");
    if(loginLinkId)
    {
      setLoad(true);
      login(
        loginLinkId,
        loginLinkId,
        loginLinkId,
        0,
        "",
        "",
        Constant.LoginType.LOGINASUSER,
        loginLinkId,
        false
      )
        .then((response) => {          
          completeLogin(response);
        })
        .catch((err) => {
          const loginErrorResponse: LoginErrorResponse = err.response.data;
          if (loginErrorResponse && loginErrorResponse.isExpired) {
            handleShowAccountExpiryPage(loginErrorResponse);
          } else {
            showLoginFailedMessage();
          }
          setLoad(false);
        });
    }
  }, []);

  const showLoginFailedMessage = () => {
    setInputFlag(true);
    toast.error("Please enter valid credentials");
  };

  const checkRole = (access_token) => {
    const user: any = jwtToken(access_token);

    const permittedRoles = [
      Constant.UserRoleId.Student,
      Constant.UserRoleId.SchoolAdmin,
      Constant.UserRoleId.SchoolTeacher,
      Constant.UserRoleId.FreeTeacher,
      Constant.UserRoleId.PayTeacher,
      Constant.UserRoleId.District,
    ];

    if (permittedRoles.includes(parseInt(user.roleId))) return true;
    return false;
  };

  const onSubmit = () => {
    setLoading(true);
    if (validate()) {
      auth.setAccountLoginName(accountLoginName);
      login(
        userName,
        password,
        accountLoginName,
        0,
        "",
        "",
        Constant.LoginType.FORM,
        "",
        false
      )
        .then((response) => {          
          completeLogin(response);
        })
        .catch((err) => {
          const loginErrorResponse: LoginErrorResponse = err.response.data;
          if (loginErrorResponse && loginErrorResponse.isExpired) {
            handleShowAccountExpiryPage(loginErrorResponse);
          } else {
            showLoginFailedMessage();
          }
          setLoading(false);
        });
    }
  };

  const completeLogin = (response) => {
    if (!response) {
      showLoginFailedMessage();
    } else {
      if (!checkRole(response?.data?.access_token)) {
        setLoading(false);
        return toast.warning("Unauthorized Access!");
      }
      const authToken = response.data["access_token"];
      localStorage.setItem("AuthToken", authToken);
      const user: any = jwtToken(authToken);
      const userId = isNaN(user.userId) ? 0 : parseInt(user.userId);
      const roleId = isNaN(user.roleId) ? 0 : parseInt(user.roleId);
      const gradeId = isNaN(user.gradeId) ? 0 : parseInt(user.gradeId);
      const linkedUserId = user.linkedUserId;
      const accountId = isNaN(user.accountId)
        ? 0
        : parseInt(user.accountId);
      const districtId = isNaN(user.districtId)
        ? 0
        : parseInt(user.districtId);

      const schoolId =
        isNaN(user.schoolId) || user.schoolId === ""
          ? 0
          : parseInt(user.schoolId);
      const schoolAccountId =
        isNaN(user.schoolAccountId) || user.schoolAccountId === ""
          ? 0
          : parseInt(user.schoolAccountId);
      const userName = !user.userName ? "" : user.userName;
      const stateId = isNaN(user.stateId) ? 0 : parseInt(user.stateId);
      if (
        roleId === Constant.UserRoleId.SchoolAdmin ||
        roleId === Constant.UserRoleId.SchoolTeacher
      ) {
        syncTeacherClasses(userId, schoolId, [
          Constant.ThirdPartyIntegrationType.ClassLink,
          Constant.ThirdPartyIntegrationType.CleverLink,
        ]);
      }

      const context: userContextAction.UserContextState = {
        userId: userId,
        roleId: roleId,
        gradeId: gradeId,
        schoolId: schoolId,
        districtId: districtId,
        accountId: accountId,
        stateId: stateId,
        schoolAccountId: schoolAccountId,
        impersonatedUser: null,
        googleId: "",
        activeExternalRoster: "",
        theme: getThemeByGrade(gradeId),
        userName: userName,
        linkedUserId: linkedUserId,
      };

      props.setUserContext(context);
      setTimeout(() => {
        props.setProfile(userId, roleId, schoolId, 0);

        if (parameters?.redirectUrl) {
          history.push(parameters?.redirectUrl);
        } else {
          handleRedirection(roleId, gradeId, linkedUserId ?? "");
        }
      }, 600);
    }
    setLoading(false);
  }

  const handleRedirection = (
    roleId: number,
    gradeId: number = 0,
    linkedUserId: string = ""
  ) => {
    var schoolSelectionUrl: string =
      RouteConstant.ROUTE_SCHOOL_SELECTION.replace(
        ":linkedUserId",
        linkedUserId
      ).replace(":loginType", Constant.ExterRosterType.FORM);
    localStorage.setItem("programMode", Constant.ProgramMode.EG);
    if (
      roleId === Constant.UserRoleId.SchoolAdmin ||
      roleId === Constant.UserRoleId.PayTeacher ||
      roleId === Constant.UserRoleId.FreeTeacher ||
      roleId === Constant.UserRoleId.SchoolTeacher ||
      roleId === Constant.UserRoleId.District ||
      roleId === Constant.UserRoleId.SuperAdmin ||
      roleId === 0
    ) {
      history.push(
        linkedUserId === "" ? RouteConstant.ROUTE_DASHBOARD : schoolSelectionUrl
      );
    } else if (roleId === Constant.UserRoleId.Student) {
      if (gradeId <= Constant.Grade.GRADE1) {
        history.push(
          linkedUserId === ""
            ? RouteConstant.KindergartenStudentRoutes.Home
            : schoolSelectionUrl
        );
      } else if (
        gradeId > Constant.Grade.GRADE1 &&
        gradeId < Constant.Grade.GRADE6
      ) {
        history.push(
          linkedUserId === ""
            ? RouteConstant.StudentRoute.Home
            : schoolSelectionUrl
        );
      } else if (gradeId >= Constant.Grade.GRADE6) {
        history.push(
          linkedUserId === ""
            ? RouteConstant.MiddleSchool.assignments
            : schoolSelectionUrl
        );
      } else {
        history.push(
          linkedUserId === ""
            ? RouteConstant.StudentRoute.Home
            : schoolSelectionUrl
        );
      }
    } else {
      history.push(RouteConstant.TeacherRoutes.assignmentCenter);
    }
  };

  const validate = () => {
    let isValid = true;
    setIsValidFlag(true);
    if (userName === "") {
      isValid = false;
      toast.error("Please enter userName");
    } else if (password === "") {
      isValid = false;
      toast.error("Please enter password");
    } else if (accountLoginName === "") {
      isValid = false;
      toast.error("Please enter your account login name");
    }
    if (!isValid) {
      setIsValidFlag(false);
    }
    return isValid;
  };

  function redirectToClever() {
    var url =
      "https://clever.com/oauth/authorize?response_type=code&client_id=" +
      Configuration.CleverClientId +
      "&redirect_uri=" +
      window.location.origin +
      "/clever";
    window.open(url);
  }

  function redirectToClaslink() {
    window.open(Configuration.ClassLinkLoginUrl);
  }

  const onKeyPress = (e) => {
    if (e.charCode === 13) onSubmit();
  };

  const handleShowAccountExpiryPage = (errorResponse: LoginErrorResponse) => {
    history.push({
      pathname: RouteConstant.EXPIRED,
      state: { userRoleId: errorResponse.userRoleId },
    });
  };

  const handleCanvasLogin = (accountLoginName) => {
    setLoading(true);
    CanvasApi.getCanvasAccountSettingsByAccountLoginName(accountLoginName)
      .then((r) => {
        setLoading(false);
        if (r.data && r.data.endPointURL) {
          handleCanvasAuthentication(r.data, accountLoginName, "");
        } else {
            var message = "";
            switch (r.data.reasonCode) {
              case Constant.SubscriptionTypes.TeacherFree:
                message = Constant.UPGRADE_THIRDPARTY;
                break;
              default:
                message =
                  "Your school has not configured canvas account settings yet please contact administrator.";
                break;
            }           
            toast.error(message);              
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };
  return (
    <>
      {load && <Loader></Loader>}
      <ReactTooltip
        id={"ssoInfo"}
        className="bg-primary-violet z-50"
      ></ReactTooltip>
      <div className="flex flex-col min-h-screen relative">
        <nav className="md:hidden absolute top-4 left-4 menu-button cursor-pointer">
          {!crossIcon && (
            <CrossIcon
              onClick={() => {
                setShowCrossIcon(true);
              }}
            />
          )}
          {crossIcon && (
            <CancelIcon
              onClick={() => {
                setShowCrossIcon(false);
              }}
            />
          )}
        </nav>
        <div>
          <div className="flex flex-col md:items-center md:justify-between md:flex-row w-11/12 md:w-10/12 xl:w-containerMain mx-auto py-2">
            <span className="flex justify-center md:flex-none mb-4 md:mb-0">
              <a href="https://progresslearning.com/">
                <LogoIcon title="Progress Learning" />
              </a>
            </span>
            <span className="md:mt-0 flex items-end inline-block justify-center">
              <span className="flex items-center">
                <a href="https://progresslearning.com/resources/">
                  <span className="text-primary-purple font-Barlow text-base font-semibold mr-4 md:mr-6 cursor-pointer hover:underline">
                    Resources
                  </span>
                </a>
                <a
                  className="bg-secondary-teal text-base font-medium font-Barlow text-white hover:text-white px-4 md:px-8 py-2.5 mr-3 cursor-pointer hover:bg-dark-teal transition-all"
                  href="https://progresslearning.com/our-products/pricing/"
                >
                  <span className="">Get a Quote</span>
                </a>
                <span className="text-base font-medium font-Barlow border border-primary-purple text-primary-purple px-4 md:px-8 py-2.5 hover:bg-secondary-teal hover:text-white cursor-pointer mr-4 md:mr-4 transition-all">
                  Login
                </span>
                <a href="https://progresslearning.com/search/">
                  <span>
                    <LoginSearchIcon />
                  </span>
                </a>
              </span>
            </span>
          </div>
        </div>
        <div
          className={`li-menu bg-primary-violet border-b border-gray-50 xl:border-0 md:block ${
            crossIcon ? "" : "hidden"
          }`}
        >
          <div className="flex py-3 md:py-0 flex-col md:flex-row justify-between w-10/12 md:w-full md:pl-4 lg:pl-0 lg:w-10/12 xl:w-containerMain mx-auto items-start md:items-center relative">
            <ul className="flex h-auto md:h-14 space-y-3 md:space-y-0 flex-col md:flex-row  font-Montserrat md:text-sm lg:text-base font-semibold text-white capitalize cursor-pointer">
              <li className="flex group inline-block relative hover:bg-dark-violet pl-3 md:pl-4 pr-3">
                <span className="flex items-center inline-block">
                  <span className="whitespace-nowrap">our products</span>
                  <RightArrow className="w-4 stroke-current text-white ml-1 group-hover:transform group-hover:rotate-90 transition-all" />
                </span>
                <ul className="absolute hidden group-hover:block z-10 top-7 left-0 md:top-14 md:left-0 bg-white shadow-lg rounded-sm w-56 min-w-full">
                  <li className="bg-dark-violet hover:bg-darkest-teal transition-all">
                    <a
                      href="https://progresslearning.com/our-products/elementary-school/"
                      className="font-normal font-Barlow border-b border-white border-opacity-50 block mx-4 py-3 hover:border-opacity-0"
                    >
                      Education Galaxy for Elementary (K-5){" "}
                    </a>
                  </li>
                  <li className="bg-dark-violet hover:bg-darkest-teal transition-all">
                    <a
                      href="https://progresslearning.com/our-products/liftoff-adaptive-intervention-2-8/"
                      className="font-normal font-Barlow border-b border-white border-opacity-50 block mx-4 py-3 hover:border-opacity-0"
                    >
                      Liftoff Adaptive Intervention (2-8){" "}
                    </a>
                  </li>
                  <li className="bg-dark-violet hover:bg-darkest-teal transition-all">
                    <a
                      href="https://progresslearning.com/our-products/middle-school/"
                      className="font-normal font-Barlow border-b border-white border-opacity-50 block mx-4 py-3 hover:border-opacity-0"
                    >
                      Progress Learning for Middle School (6-8){" "}
                    </a>
                  </li>
                  <li className="bg-dark-violet hover:bg-darkest-teal transition-all">
                    <a
                      href="https://progresslearning.com/our-products/high-school/"
                      className="font-normal font-Barlow border-b border-white border-opacity-50 block mx-4 py-3 hover:border-opacity-0"
                    >
                      Progress Learning for High School (9-12){" "}
                    </a>
                  </li>
                  <li className="bg-dark-violet hover:bg-darkest-teal transition-all">
                    <a
                      href="https://progresslearning.com/our-products/integrations/"
                      className="font-normal font-Barlow border-b border-white border-opacity-50 block mx-4 py-3 hover:border-opacity-0"
                    >
                      Integrations{" "}
                    </a>
                  </li>
                  <li className="bg-dark-violet hover:bg-darkest-teal transition-all">
                    <a
                      href="https://progresslearning.com/our-products/pricing/"
                      className="font-normal font-Barlow block mx-4 py-3 "
                    >
                      Pricing{" "}
                    </a>
                  </li>
                </ul>
              </li>
              <li className="flex inline-block items-center relative hover:bg-dark-violet pl-3 pr-3">
                <a href="https://progresslearning.com/schools-districts/">
                  schools & districts{" "}
                </a>
              </li>
              <li className="flex inline-block items-center relative hover:bg-dark-violet pl-3 pr-3">
                <a href="https://progresslearning.com/teachers/">teachers </a>
              </li>
              <li className="flex inline-block items-center relative hover:bg-dark-violet pl-3 pr-3">
                <a href="https://progresslearning.com/news-blog/">
                  news & blog{" "}
                </a>
              </li>
              <li className="flex group inline-block relative hover:bg-dark-violet pl-3 md:pl-4 pr-3">
                <span className="flex items-center inline-block">
                  <span className="whitespace-nowrap">about us</span>
                  <RightArrow className="w-4 stroke-current text-white ml-1 group-hover:transform group-hover:rotate-90 transition-all" />
                </span>
                <ul className="absolute hidden group-hover:block z-10 top-7 left-0 md:top-14 md:left-0 bg-white shadow-lg rounded-sm w-56 min-w-full">
                  <li className="bg-dark-violet hover:bg-darkest-teal transition-all">
                    <a
                      href="https://progresslearning.com/about-us/#careers"
                      className="font-normal font-Barlow border-b border-white border-opacity-50 block mx-4 py-3 hover:border-opacity-0"
                    >
                      Careers{" "}
                    </a>
                  </li>
                  <li className="bg-dark-violet hover:bg-darkest-teal transition-all">
                    <a
                      href="https://progresslearning.com/about-us/certifications/"
                      className="font-normal font-Barlow border-b border-white border-opacity-50 block mx-4 py-3 hover:border-opacity-0"
                    >
                      Certifications{" "}
                    </a>
                  </li>
                  <li className="bg-dark-violet hover:bg-darkest-teal transition-all">
                    <a
                      href="https://progresslearning.com/about-us/testimonials/"
                      className="font-normal font-Barlow border-b border-white border-opacity-50 block mx-4 py-3 hover:border-opacity-0"
                    >
                      Testimonials{" "}
                    </a>
                  </li>
                  <li className="bg-dark-violet hover:bg-darkest-teal transition-all">
                    <a
                      href="https://progresslearning.com/about-us/training/"
                      className="font-normal font-Barlow block mx-4 py-3"
                    >
                      Training{" "}
                    </a>
                  </li>
                  <li className="bg-dark-violet hover:bg-darkest-teal transition-all">
                    <a
                      href="https://progresslearning.com/meet-our-teachers/"
                      className="font-normal font-Barlow block mx-4 py-3"
                    >
                      Meet our teachers{" "}
                    </a>
                  </li>
                </ul>
              </li>
              <li className="flex inline-block items-center relative hover:bg-dark-violet pl-3 pr-3">
                <a href="https://progresslearning.com/contact-us/">
                  contact us{" "}
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="w-full xl:flex xl:flex-1 relative">
          <div className="relative flex flex-col lg:justify-between lg:flex-row xl:flex-col xl:absolute w-full xl:w-[27rem] 1xl:w-[27rem] xxl:w-[27rem] xxxl:w-100 xxl:left-44 xxxl:left-52 xl:left-20 xl:top-16 mt-0 xl:mt-2">
            <div className="flex flex-col w-full lg:w-6/12 xl:w-full items-center px-8 bg-primary-violet py-8">
              <h1 className="text-lg xxxl:text-2xl font-Montserrat font-bold text-white">
                Welcome To Progress Learning 
              </h1>
              <p className="mt-4 font-Barlow text-center text-white text-sm xxxl:text-lg">
                Previously known as USATestprep and Education Galaxy,
                <br /> Progress Learning is a full K12 supplemental learning
                <br /> platform designed to increase comprehension and <br />
                 engagement with students.
              </p>
            </div>

            <div className="flex flex-col w-full lg:w-6/12 xl:w-full items-center px-8 bg-olive-green py-8 pb-11">
              <h2 className="text-lg xxxl:text-2xl font-Montserrat font-bold text-white mb-2">
                Announcements
              </h2>
              <div className="w-inherit md:w-3/4 lg:w-full bg-white bg-opacity-20 ">
                <Slider {...settings}>
                  <div className="px-3 pb-2">
                    <p className="w-full text-left mt-2 font-Barlow text-white text-sm xxxl:text-lg">
                      Sign up for our “Getting Started” training webinar to
                      learn how to use and get the most out of Progress
                      Learning.
                    </p>

                    <p className="w-full text-left mt-6 font-Barlow text-white text-sm xxxl:text-lg">
                      Check out other special topic sessions for continuing to
                      help your students master state standards.
                    </p>

                    <div className="uppercase w-full h-12 xxxl:h-14 text-white text-lg xxxl:text-xl font-semibold flex items-center justify-center bg-secondary-teal mt-12 cursor-pointer hover:bg-dark-teal">
                      <a href="https://progresslearning.com/about-us/training/">
                        register now
                      </a>
                    </div>
                  </div>
                </Slider>
              </div>
            </div>

            <span className="flex justify-end">
              <DNAIcon />
            </span>
          </div>
          <div className="w-full xl:w-3/12 bg-bgDots bg-cover"></div>
          <div className="w-full xl:w-9/12 bg-light-gray">
            <div className="w-12/12 md:w-10/12 md:mx-auto lg:w-8/12 xl:mx-0 xl:w-11/12 xxl:w-10/12 xxxl:w-10/12 flex flex-col md:flex-row justify-end mt-0 pt-6 xl:pt-0 xl:mt-36 px-10 xl:px-0 xl:pr-0 xxl:pr-0 pb-12 xl:pb-20">
              <CometIcon />

              <div className="w-full md:w-7/12 xl:w-[40%] 1xl:w-[35%] xxxl:w-4/12 md:border-r border-gray-300 md:pr-6 xl:pr-5">
                {loginErrorMessage !== "" && (
                  <span className="inline-block p-3 bg-red-50 shadow rounded-md mb-4 text-red-600 font-normal">
                    {loginErrorMessage}
                  </span>
                )}
                <div>
                  <label className="text-gray-light block text-sm xxxl:text-base font-semibold font-Montserrat ">
                    Account ID
                  </label>
                  <div className="mt-1.5 relative group">
                    <input
                      tabIndex={1}
                      type="text"
                      value={accountLoginName}
                      onChange={(event) =>
                        setAccountLoginName(event.target.value)
                      }
                      onKeyPress={onKeyPress}
                      className={`border border-gray-200 shadow-sm focus:ring-primary-purple focus:border-primary-purple h-10 xxxl:h-12 block w-full text-lg border border-gray-200 text-gray-500 px-3 rounded-sm
                    `}
                    />
                    {input ||
                      (!isValidFlag && accountLoginName === "" && (
                        <span>
                          <span className="opacity-0 group-hover:opacity-100 bg-white text-red-500 border-red-400 border transition duration-500 rounded-sm max-w-70% p-0.5 pl-2 pr-3 text-xs inline-block absolute top-1/2 transform -translate-y-1/2 right-2 ">
                            Account Id is required
                          </span>
                          <ExclamationIcon className="w-1 absolute opacity-100 top-1/2 transform -translate-y-1/2 right-3 cursor-pointer"></ExclamationIcon>
                        </span>
                      ))}
                  </div>
                </div>
                <div className="mt-5">
                  <label className="text-gray-light block text-sm xxxl:text-base font-semibold font-Montserrat">
                    Username
                  </label>
                  <div className="mt-1.5 relative group">
                    <input
                      tabIndex={2}
                      type="text"
                      autoComplete="off"
                      onChange={(event) => setUserName(event.target.value)}
                      onKeyPress={onKeyPress}
                      className={`border border-gray-200 shadow-sm text-gray-500 px-3 focus:ring-primary-purple focus:border-primary-purple h-10 xxxl:h-12 block w-full text-lg rounded-sm 
                          `}
                    />
                    {input ||
                      (!isValidFlag && userName === "" && (
                        <span>
                          <span className="opacity-0 group-hover:opacity-100 bg-white text-red-500 border-red-400 border transition duration-500 rounded-sm max-w-70% p-0.5 pl-2 pr-3 text-xs inline-block absolute top-1/2 transform -translate-y-1/2 right-2 ">
                            Username is required
                          </span>
                          <ExclamationIcon className="w-1 absolute opacity-100 top-1/2 transform -translate-y-1/2 right-3 cursor-pointer"></ExclamationIcon>
                        </span>
                      ))}
                  </div>
                </div>
                <div className="mt-5">
                  <label className="text-gray-light block text-sm xxxl:text-base font-semibold font-Montserrat">
                    Password
                  </label>
                  <div className="mt-1.5 relative group">
                    <input
                      tabIndex={3}
                      autoComplete="off"
                      type="password"
                      onChange={(event) => setPassword(event.target.value)}
                      onKeyPress={onKeyPress}
                      className={`border border-gray-200 shadow-sm focus:ring-primary-purple focus:border-primary-purple h-10 xxxl:h-12 block w-full text-lg border border-gray-200 text-gray-500 px-3 rounded-sm
                  `}
                    />
                    {input ||
                      (!isValidFlag && password === "" && (
                        <span>
                          <span className="opacity-0 group-hover:opacity-100 bg-white text-red-500 border-red-400 border transition duration-500 rounded-sm max-w-70% p-0.5 pl-2 pr-3 text-xs inline-block absolute top-1/2 transform -translate-y-1/2 right-2 ">
                            password is required
                          </span>
                          <ExclamationIcon className="w-1 absolute opacity-100 top-1/2 transform -translate-y-1/2 right-3 cursor-pointer"></ExclamationIcon>
                        </span>
                      ))}
                  </div>
                </div>
                <button
                  type="submit"
                  tabIndex={4}
                  onClick={() => onSubmit()}
                  className="bg-light-violet cursor-pointer hover:opacity-90 mt-6 w-full h-12 xxxl:h-14 text-lg xxxl:text-xl flex items-center justify-center text-white font-Barlow font-semibold"
                >
                  <span>Log in</span>

                  <div className="p-4">
                    {loading && (
                      <div
                        style={{ borderTopColor: "transparent" }}
                        className="w-6 h-6 border-4 border-white-400 border-solid rounded-full animate-spin"
                      ></div>
                    )}
                  </div>
                </button>
                <div className="flex flex-col md:flex-row items-center justify-between mt-6 mb-6 md:mb-0">
                  <Link to={RouteConstant.FORGOTPASSWORD}>
                    <span className="pb-4 md:pb-0 text-xs xxxl:text-sm text-primary-purple hover:underline cursor-pointer font-Barlow">
                      Forgot Password?
                    </span>
                  </Link>
                </div>
                <div className="border-t border-gray-300 mt-8 hidden md:block">
                  <div className="text-sm xxxl:text-md text-primary-purple mt-8 px-10 xxxl:px-14 font-Montserrat text-center font-bold">
                    Education Galaxy and USATestprep are now Progress Learning
                  </div>
                  <div className="flex justify-center space-x-5 mt-5">
                    <div>
                      <EdGalaxyLogo title="Education Galaxy" />
                    </div>
                    <div>
                      <UsaTestPrepLogo title="USATestprep" />
                    </div>
                    <div></div>
                  </div>
                  <div className="flex justify-center gap-x-4 text-gray-500 mt-4">
                    <a
                      className="text-gray-500 hover:text-gray-500 hover:underline"
                      href="https://progresslearning.com/product-terms-of-use/"
                    >
                      Terms
                    </a>{" "}
                    |{" "}
                    <a
                      className="text-gray-500 hover:text-gray-500 hover:underline"
                      href="https://progresslearning.com/product-privacy-policy/"
                    >
                      Privacy
                    </a>
                  </div>
                </div>
              </div>
              <div className="md:w-5/12 xl:w-64 xxl:w-3/12 md:pl-6 xl:pl-4 xxl:pr-0 xxxl:pt-6 text-center md:text-left">
                <div
                  className="google-btn flex border border-[#4285F4] cursor-pointer mb-8 group"
                  onClick={() => {
                    history.push(RouteConstant.GOOGLEUSERLOGIN);
                  }}
                >
                  <span className="bg-white flex p-[16px] md:p-[8px] 2xl:p-[14px] xxxl:p-[16px] justify-center">
                    <GoogleLoginIcon
                      className="flex-shrink-0"
                      title="sign in with Google"
                    />
                  </span>
                  <span className="w-full pl-[32px] md:pl-[16px] 2xl:pl-[28px] xxxl:pl-[32px] text-sm xxxl:text-base text-white font-Roboto bg-[#4285F4] flex items-center ">
                    Sign in with Google
                  </span>
                </div>
                <div
                  onClick={redirectToClever}
                  className="mb-8 rounded-t-md flex border hover:opacity-90 border-clever-blue border-b-4 border-b-clever-blue-dark bg-clever-blue shadow-md cursor-pointer"
                >
                  <span className="flex w-16 justify-center items-center py-2.5 xxxl:py-4">
                    <img
                      className="h-5 w-5"
                      src={`${Configuration.S3bucketImagePath}/images/Clever-meta-clever.png`}
                      alt="clever icon"
                      title="Sign in with Clever"
                    />
                  </span>
                  <span className="border-l border-gray-50 border-opacity-30 w-full pl-[32px] md:pl-[16px] 2xl:pl-[28px] xxxl:pl-[32px] text-sm xxxl:text-base text-white font-Barlow inline-block bg-clever-blue flex items-center">
                    Sign in with Clever
                  </span>
                </div>

                <div
                  onClick={redirectToClaslink}
                  className="mb-8 cursor-pointer flex rounded border border-class-teal hover:opacity-90 bg-class-teal shadow-md cursor-pointer"
                >
                  <span className="flex rounded bg-white w-16 justify-center items-center py-2.5 xxxl:py-4">
                    <img
                      className="h-5 w-8"
                      src={`${Configuration.S3bucketImagePath}/images/ClassLinkLogin.png`}
                      alt="class link icon"
                      title="Sign in with ClassLink"
                    />
                  </span>
                  <span className="w-full pl-[32px] md:pl-[16px] 2xl:pl-[28px] xxxl:pl-[32px] text-sm xxxl:text-base text-white font-Barlow inline-block bg-class-blue flex items-center">
                    Sign in with ClassLink
                  </span>
                </div>

                {CanvasConfiguration.EnableCanvas && (
                  <div
                    onClick={() => {
                      if (accountLoginName) {
                        handleCanvasLogin(accountLoginName);
                      } else {
                        history.push(RouteConstant.CANVASUSERLOGIN);
                      }
                    }}
                    className="cursor-pointer flex rounded border border-[#E6252A] hover:opacity-90 bg-[#E6252A] shadow-md  mt-8"
                  >
                    <span className="flex rounded bg-white w-16 justify-center items-center py-2.5 xxxl:py-4">
                      <CanvasIcon className="w-5"></CanvasIcon>
                    </span>

                    <span className="w-full pl-[32px] md:pl-[16px] 2xl:pl-[28px] xxxl:pl-[32px] text-sm xxxl:text-base text-white font-Barlow inline-block bg-class-blue flex items-center">
                      Sign in with Canvas
                    </span>
                  </div>
                )}
              </div>

              <div className="border-t border-gray-300 mt-8 block md:hidden">
                <div className="text-sm md:text-lg text-primary-purple mt-8 px-14 font-Montserrat text-center font-bold">
                  Education Galaxy and USATestprep are now Progress Learning
                </div>
                <div className="flex justify-center space-x-5 mt-5">
                  <div>
                    <EdGalaxyLogo />
                  </div>
                  <div>
                    <UsaTestPrepLogo />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    profile: state.profile,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setUserContext: (payload: userContextAction.UserContextState) => {
      dispatch(userContextAction.setUserContext(payload));
    },
    setProfile: (
      userId: number,
      roleId: number,
      schoolId: number,
      districtId: number
    ) => {
      dispatch(fetchProfile(userId, roleId, schoolId, districtId));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);

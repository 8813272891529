import DefaultUserIcon from "../../assets/images/userIcon.png";
import constant from "../../utils/constant/constant";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
interface userTileProps {
  userId: number;
  onlineUsers: any[];
  className?: string;
  profile?: any;
  isLoggedInUser: boolean;
}

function UserTile(props: userTileProps) {
  const [defaultAvatar, setDefaultImage] = useState<string>(DefaultUserIcon);
  useEffect(() => {
    setDefaultImage(
      props.profile === null ||
        props.profile.profileImage === null ||
        props.isLoggedInUser === false
        ? DefaultUserIcon
        : props.profile.profileImage
    );
  }, [props.profile?.profileImage]);

  return (
    <>
      <div className="relative">
        {props.onlineUsers[props.userId] === constant.USERACTIVITY.Active && (
          <span className="text-green-500 absolute z-10 -right-1.5 -bottom-1 border-2 border-white-300 rounded-full">
            <svg width="11" height="11">
              <circle cx="5" cy="5" r="5" fill="currentColor"></circle>
            </svg>
          </span>
        )}
        {props.onlineUsers[props.userId] === constant.USERACTIVITY.Ideal && (
          <span className="text-yellow-500 absolute z-10 -right-1.5 -bottom-1 border-2 border-white-300 rounded-full">
            <svg width="11" height="11">
              <circle cx="5" cy="5" r="5" fill="currentColor"></circle>
            </svg>
          </span>
        )}

        {!props.onlineUsers[props.userId] && (
          <span className="text-gray-500 absolute z-10 -right-1.5 -bottom-1 border-2 border-white-300 rounded-full">
            <svg width="10" height="10">
              <circle cx="5" cy="5" r="5" fill="currentColor"></circle>
            </svg>
          </span>
        )}

        <div
          className={`relative ${
            props.className
              ? props.className
              : "bg-splitColor rounded-md border border-gray-300 w-10 sm:h-10 overflow-hidden"
          }`}
        >
          <img
            title="Avatar"
            src={defaultAvatar}
            alt="Avatar"
            className="w-10 sm:h-10"
          />
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state: any) => {
  return {
    profile: state.profile,
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(UserTile);

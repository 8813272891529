import { useEffect, useState } from "react";
import { Select } from "@windmill/react-ui";
import { connect } from "react-redux";
import schoolTime from "../../../mockData/schoolHours.json";
import { StarDarkIcon, StarIcon } from "../../../assets/icons/index";
import Toggle from "../../shared/toggle";
import { TagColor } from "../../shared/tagColor";
import { ClassDetailsDTO } from "../../../model/teacher/chatSettings/teacherChatSettings";
import {
  GetChatSettings,
  UpdateChatSettings,
  UpdateAvailableTeachingTime,
} from "../../../api/teacher/teacher";
import Loader from "../../shared/loader";

function Settings(props: any) {
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [classes, setClasses] = useState<Array<ClassDetailsDTO>>([]);
  const [loading, showLoading] = useState<boolean>(false);

  useEffect(() => {
    getChatSettings();
  }, []);

  function handleStartTimeChange(event) {
    setStartTime(event.target.value);
  }
  function handleEndTimeChange(event) {
    setEndTime(event.target.value);
  }

  const getChatSettings = () => {
    showLoading(true);
    const teacherId = props.profile.userId;
    GetChatSettings(teacherId)
      .then((r) => {
        if (r.data) {
          setClasses(r.data.classes);
          setStartTime(r.data.startTime);
          setEndTime(r.data.endTime);
        }
        showLoading(false);
      })
      .catch(() => {
        showLoading(false);
      });
  };

  const handleToggle = (chatSetting: ClassDetailsDTO) => {
    const teacherId = props.profile.userId;
    const param = {
      classId: chatSetting.classId,
      teacherId: teacherId,
      allowStudentChat: !chatSetting.allowStudentChat,
    };
    showLoading(true);
    UpdateChatSettings(param)
      .then((r) => {
        showLoading(false);
        getChatSettings();
      })
      .catch(() => {
        showLoading(false);
      });
  };

  function handleSaveHours() {
    if (startTime && endTime) {
      const teacherId = props.profile.userId;
      const param = {
        teacherId: teacherId,
        startTime: startTime,
        endTime: endTime,
      };
      showLoading(true);
      UpdateAvailableTeachingTime(param)
        .then(() => {
          showLoading(false);
          getChatSettings();
        })
        .catch(() => {
          showLoading(false);
        });
    }
  }

  return (
    <form action="#" method="POST">
      <dl className="grid grid-cols-1 gap-x-6 gap-y-4 xl:grid-cols-2">
        {loading && <Loader />}
        <div className="sm:col-span-1 border rounded-lg w-full flex flex-col justify-between h-auto max-h-96">
          <div className="flex flex-col items-center justify-start px-4 py-2 sm:flex-row bg-gray-50 text-sm font-semibold text-gray-700">
            Chat Settings
          </div>
          <div className="w-full relative overflow-auto px-4 py-3  space-y-3">
            {classes.map((item, index) => (
              <div
                className="relative rounded-lg border border-gray-300 bg-white px-6 py-4 shadow-sm flex items-center space-x-5 hover:border-gray-400 "
                key={index}
              >
                <div
                  className={`flex-shrink-0 h-10 w-10 rounded-full border
                  ${
                    TagColor.isDark(item.colorCode)
                      ? "border-white"
                      : "border-gray-500"
                  }
                  `}
                  style={{
                    backgroundColor: item.colorCode,
                  }}
                ></div>
                <div className="flex-1 w-full min-w-0">
                  <span className="flex justify-between items-center focus:outline-none">
                    <div>
                      <p className="text-sm font-medium text-gray-900">
                        {item.name}
                      </p>
                      <div className="flex items-center space-x-2 mt-1">
                        <p className="text-sm text-gray-500 truncate">
                          <span className="bg-blue-100 border flex items-center justify-center border-blue-200 text-gray-700 w-6 inline-block rounded-md">
                            {item.studentCount}
                          </span>
                        </p>
                        <span>
                          {item.isPrimary ? <StarDarkIcon /> : <StarIcon />}
                        </span>
                      </div>
                    </div>
                    <span>
                      <Toggle
                        checked={item.allowStudentChat}
                        onChange={() => {
                          handleToggle(item);
                        }}
                        disabled={false}
                      />
                    </span>
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="sm:col-span-1 text-sm font-semibold text-gray-500 order-first xl:order-last">
          <div>
            <div className="mb-4 text-sm text-gray-700">
              Set your availability
            </div>
            <dl className="grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-2">
              <div>
                <label className="text-sm font-medium text-gray-500">
                  Start Time
                </label>
                <Select
                  value={startTime!}
                  onChange={(e) => handleStartTimeChange(e)}
                  className="block w-full text-sm md:text-sm dark:text-gray-300 focus:outline-none focus:border-purple-400 dark:border-gray-600 dark:bg-gray-700 focus:shadow-outline-purple dark:focus:shadow-outline-gray dark:focus:border-gray-600 form-select leading-5 block  text-lg text-gray-700 py-3 px-2 bg-transparent focus:outline-none focus:border-gray-500 pb-5 border sm:pb-2 mt-1 rounded border-gray-300 focus:border-primary-green"
                >
                  {schoolTime.SchoolTime.map((obj) => {
                    return (
                      <option key={obj} value={obj}>
                        {obj}
                      </option>
                    );
                  })}
                </Select>
              </div>
              <div>
                <label className="text-sm font-medium text-gray-500">
                  End Time
                </label>
                <Select
                  value={endTime!}
                  onChange={(e) => handleEndTimeChange(e)}
                  className="block w-full text-sm md:text-sm dark:text-gray-300 focus:outline-none focus:border-purple-400 dark:border-gray-600 dark:bg-gray-700 focus:shadow-outline-purple dark:focus:shadow-outline-gray dark:focus:border-gray-600 form-select leading-5 block  text-lg text-gray-700 py-3 px-2 bg-transparent focus:outline-none focus:border-gray-500 pb-5 border sm:pb-2 mt-1 rounded border-gray-300 focus:border-primary-green"
                >
                  {schoolTime.SchoolTime.slice(0)
                    .reverse()
                    .map((obj) => {
                      return (
                        <option key={obj} value={obj}>
                          {obj}
                        </option>
                      );
                    })}
                </Select>
              </div>
            </dl>
          </div>
        </div>
      </dl>

      <div className="px-4 py-3 bg-gray-50 my-6 text-right ">
        <button
          type="button"
          onClick={(e) => handleSaveHours()}
          className="bg-primary-violet border border-transparent shadow-sm py-2 px-4 justify-end text-sm font-medium text-white hover:bg-dark-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 whitespace-nowrap ml-auto"
        >
          Save Hours
        </button>
      </div>
    </form>
  );
}
export default connect((state: any) => {
  return {
    profile: state.profile,
    userContext: state.userContext,
  };
}, {})(Settings);

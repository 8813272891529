import { Fragment, useEffect, useState } from "react";
import { IAlienArenaSession } from "../../../../model/interface/alienArenaSessions";
import Pagination from "../../../shared/pagination/pagination";
import PageSize from "../../../shared/pagination/pageSize";
import Loader from "../../../shared/loader";
import {
  DEFAULT_PAGE_NUMBER,
  DEFAULT_PAGE_SIZE,
} from "../../../../utils/pagingConstant";
import { getAllAlienArenaSessions } from "../../../../api/teacher/alienArena";
import { UserContextState } from "../../../../redux/actions/userContextAction";
import { connect } from "react-redux";
import PagingResponse from "../../../../model/common/pagingResponse";
import AlienArenaSessionsContextMenu from "./AlienArenaSessionsContextMenu";
import { Link } from "react-router-dom";
import RouteConstant from "../../../../utils/constant/routeConstant";
import Moment from "react-moment";
import Permissions from "../../../../utils/permissions";
import AllowedTo from "../../../shared/rbac";

interface AlienArenaSessionProps {
  userContext?: UserContextState;
  userId: number;
  roleId: number;
}

function AlienArenaSessionList(props: AlienArenaSessionProps) {
  const [loading, showLoading] = useState<boolean>(false);
  const [hoverElement, setHoverElement] = useState<string>("");
  const [pageNumber, setPageNumber] = useState(DEFAULT_PAGE_NUMBER);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [resetPageNumber, setResetPageNumber] = useState<boolean>(false);
  const [alienArenaSessions, setAlienArenaSessions] =
    useState<PagingResponse<IAlienArenaSession>>();
  const onPageChange = (pageNo) => {
    setPageNumber(pageNo);
  };

  const onPageSizeChange = (pageSize) => {
    setPageNumber(DEFAULT_PAGE_NUMBER);
    setPageSize(pageSize);
    setResetPageNumber(true);
  };
  const getAlienArenaSessionsList = () => {
    showLoading(true);
    getAllAlienArenaSessions(
      props.userContext?.userId ? props.userContext?.userId : 0,
      props.userContext?.schoolId ? props.userContext?.schoolId : 0,
      pageSize,
      pageNumber
    )
      .then((d) => {
        setAlienArenaSessions(d.data);
        showLoading(false);
      })
      .catch(() => {});
  };
  useEffect(() => {
    getAlienArenaSessionsList();
  }, [pageNumber, pageSize]);

  return (
    <Fragment>
      {loading && <Loader />}

      <div className="min-w-0 block lg:col-span-9 xl:col-span-10 w-full">
        <div className="bg-gray-100  w-full mb-5 rounded-lg p-5 h-full">
          <div className="flex flex-col md:flex-row gap-4 items-end md:items-center md:justify-between justify-start my-4">
            <div className="p-1"></div>
            <AllowedTo perform={Permissions.alien_arena_sessions_create}>
              <div className="grid grid-cols-1 gap-4">
                <Link to={RouteConstant.TeacherRoutes.AlienArena}>
                  <button
                    type="submit"
                    className="bg-primary-violet border border-transparent shadow-sm py-2 px-4 justify-end text-sm font-medium text-white hover:bg-dark-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 whitespace-nowrap"
                  >
                    Create New Alien Arena
                  </button>
                </Link>
              </div>
            </AllowedTo>
          </div>
          <div className="bg-white py-5 px-5">
            <div className="mb-4 pb-4 border-b-2 overflow-x-auto xl:overflow-x-visible">
              <table className="table w-full ">
                <thead>
                  <tr className="bg-primary-violet  text-white ">
                    <th className="text-left p-3 text-sm font-normal">Name</th>
                    <th className="text-left p-3 text-sm font-normal">Class</th>
                    <th className="text-left p-3 text-sm font-normal">
                      Subject
                    </th>
                    <th className="text-left p-3 text-sm font-normal">
                      Status
                    </th>
                    <th className="text-left p-3 text-sm font-normal">Date</th>
                    <th className="w-5"></th>
                  </tr>
                </thead>
                <tbody>
                  {alienArenaSessions &&
                    alienArenaSessions!.data.length > 0 &&
                    alienArenaSessions.data?.map((session, index) => {
                      return (
                        <Fragment>
                          <tr
                            key={index}
                            className={`hover:bg-primary-violet/20 transition ease-in-out duration-300 ${
                              index % 2 === 0
                                ? "bg-white"
                                : "bg-primary-violet/10"
                            }`}
                            onMouseOver={() => {
                              setHoverElement("row-" + session.sessionId);
                            }}
                          >
                            <td className="text-sm p-3 ">
                              {session.sessionName}
                            </td>
                            <td className="text-sm p-3 ">
                              {session.className}
                            </td>
                            <td className="text-sm p-3 ">
                              {session.subjectName}
                            </td>
                            <td className="text-sm p-3 ">
                              <span
                                className={`${
                                  session.status ? "bg-green-500" : ""
                                } py-0.5 px-1 text-xs rounded-lg text-white`}
                              >
                                {session.status}
                              </span>
                            </td>
                            <td className="text-sm p-3 ">
                              {<Moment>{session.createdDate}</Moment>}
                            </td>
                            <td>
                              <div className="w-6">
                                {hoverElement ===
                                  "row-" + session.sessionId && (
                                  <AlienArenaSessionsContextMenu
                                    isFirst={(index === 0).toString()}
                                    isLast={
                                      index === pageSize - 1 ? "true" : "false"
                                    }
                                    sessionId={session.sessionId}
                                    sessionName={
                                      session.sessionName
                                        ? session.sessionName
                                        : ""
                                    }
                                    alienArenaId={session.alienArenaId}
                                    refreshSessions={getAlienArenaSessionsList}
                                    status={
                                      session.status ? session.status : ""
                                    }
                                    session={session}
                                  />
                                )}
                              </div>
                            </td>
                          </tr>
                        </Fragment>
                      );
                    })}
                </tbody>
              </table>
            </div>
            <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
              {alienArenaSessions?.data.length !== 0 && (
                <Pagination
                  totalRecords={
                    alienArenaSessions?.totalRecords
                      ? alienArenaSessions?.totalRecords
                      : 0
                  }
                  pageSize={pageSize}
                  onPageChange={onPageChange}
                  reset={resetPageNumber}
                  pageNumber={pageNumber}
                  bgColor="primary-violet"
                />
              )}
              {alienArenaSessions?.data.length !== 0 && (
                <PageSize
                  pageSize={pageSize}
                  onPageSizeChange={onPageSizeChange}
                  bgColor="primary-violet"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(AlienArenaSessionList);

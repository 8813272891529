import { Fragment } from "react";
import DashboardComposer from "../dynamicComponent/dashboardComposer";
import Layout from "../../model/template/teacherLayout";
// import * as mockLayoutData from "../../mockData/layoutMasters/teacherLayout.json";
// import Profile from "../../model/profile"
import { connect } from "react-redux";

// function getLayout(userId: number) {
//     const data: Layout = { ...mockLayoutData.layout }
//     return data
// }

function LayoutContainer(props: any) {
  const data: Layout | null = props.profile ? props.profile.layout : null;

  return <Fragment>{DashboardComposer(data && data.name, data)}</Fragment>;
}

const mapStateToProps = (state: any) => {
  return {
    profile: state.profile,
  };
};

export default connect<{}, {}, {}>(mapStateToProps)(LayoutContainer);

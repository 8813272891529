import { containsAll } from "../../utils/helper";
import { PermissionType, UserPermission } from "../../types/type";
import { getRequestedPermission } from "./permissionHelper";
import { jwtToken } from "./tokenHelper";

interface iAllowedTo {
  perform: Array<string> | string;
  children?: any;
  no?: any;
  asFunction?: boolean;
  upgradePermission?: string;
}

const isEdit = () => {
  const param: any = window.location.pathname?.split("/")?.pop();
  if (!isNaN(parseInt(param))) return true;
  return false;
};

const isNew = () => {
  const param: any = window.location.pathname?.split("/")?.pop();
  if (param === "new") return true;
  return false;
};

const getModifyPerform = (perform) => {
  const arr: Array<string> = [];
  perform.map((ds) => {
    if (ds.includes("modify")) arr.push(ds);
    return null;
  });
  return arr;
};

const getCreatePerform = (perform) => {
  const arr: Array<string> = [];
  perform.map((ds) => {
    if (ds.includes("create")) arr.push(ds);
    return null;
  });
  return arr;
};

function convertToFlagsEnum(value: number): PermissionType[] {
  const flags: PermissionType[] = [];
  for (const flag in PermissionType) {
    if (isNaN(Number(flag))) {
      const flagValue = PermissionType[flag];
      if (typeof flagValue === "number" && (value & flagValue) === flagValue) {
        flags.push(flagValue);
      }
    }
  }
  return flags;
}

function hasFlag(
  flagEnums: PermissionType[],
  flagToCheck: PermissionType
): boolean {
  return flagEnums.includes(flagToCheck);
}

export const permissions = () => {
  let perm: any = [];
  const authToken = localStorage.getItem("AuthToken");
  if (authToken && authToken !== "") {
    const user: any = jwtToken(authToken);
    if (user && user.permissions && user.permissions !== "") {
      if (typeof user.permissions === "string") {
        return permissionsArray();
      }
      const permissions = user.permissions as {
        [key: string]: UserPermission;
      };
      const access = {};
      for (const [key, value] of Object.entries(permissions)) {
        const flagEnums = convertToFlagsEnum(value.flags);
        const prefix = key.toLowerCase();
        for (const permission in PermissionType) {
          if (isNaN(Number(permission))) {
            const flagValue = PermissionType[permission];
            if (hasFlag(flagEnums, Number(flagValue))) {
              access[`${prefix}_${permission.toLowerCase()}`] = true;
            }
          }
        }
      }
      perm = Object.keys(access);
    }
  }
  return perm;
};
const permissionsArray = () => {
  let perm: any = [];
  const authToken = localStorage.getItem("AuthToken");
  if (authToken && authToken !== "") {
    const user: any = jwtToken(authToken);
    if (user && user.permissions && user.permissions !== "") {
      const permissions = JSON.parse(user.permissions);
      const permissionTypes = ["View", "Create", "Modify", "Delete"];
      const access = {};
      for (let i = 0; i < permissions.length; i++) {
        const ds = permissions[i];
        permissionTypes.map((type) => {
          if (ds[type] === true) {
            access[`${ds.Name.toLowerCase()}_${type.toLowerCase()}`] = ds[type];
          }
          return null;
        });
      }

      perm = Object.keys(access);
    }
  }
  return perm;
};

const isUpgradeRequired = (permissionName: string) => {
  const requestedPerm = getRequestedPermission(permissionName);
  return permissionName.includes("roleupgrade")// check if role based upgrade 
        || requestedPerm?.includes("upgrade");//check if permission based upgrade
};

const AllowedTo = ({
  perform = [],
  children,
  no: No = () => null,
  asFunction,
  upgradePermission,
}: iAllowedTo) => {  
  //for tabs/menu show as disabled for Upgrade
  if (upgradePermission && isUpgradeRequired(upgradePermission)) {
    return children;
  }
  
  let isPermitted = false;
  const authToken = localStorage.getItem("AuthToken");
  const perm = permissions();
  if (perform instanceof Array) {
    if (isEdit() && containsAll(perm, getModifyPerform(perform))) {
      isPermitted = true;
    } else if (isNew() && containsAll(perm, getCreatePerform(perform))) {
      isPermitted = true;
    }
  } else if (typeof perform === "string" && perm.includes(perform)) {    
    isPermitted = true;
  }
  if (authToken === null || authToken === "") {
    isPermitted = true; // avoid access denied while logout
  }
  if (asFunction) {
    return isPermitted ? true : false;
  } else {
    return isPermitted ? children : <No />;
  }
};
export default AllowedTo;
export { permissions as userPermissions };
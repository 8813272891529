import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import RouteConstant from "../../utils/constant/routeConstant";

function Blank() {
    const history = useHistory();

    history.push(RouteConstant.ROUTE_DEFAULT);

    useEffect(() => {
        return () => {
            window.sessionStorage.setItem("showPopup", "true")
        }
    }, []);

    return (
        <div>

        </div>
    )
}

export default Blank
import { ChevronRightIcon } from "@heroicons/react/solid";
import { useState } from "react";
import { connect } from "react-redux";
import Constant from "../../../../utils/constant/constant";
import RouteConstant from "../../../../utils/constant/routeConstant";
import {
  IAlienArena,
  IAlienStudentData,
} from "../../../../model/interface/alienArena";
import ArenaSetup from "./arenaSetup";
import ArenaTeams from "./arenaTeams";
import { IAlienArenaSetup } from "../../../../model/interface/alienArenaSetup";
import { IAlienArenaTeams } from "../../../../model/interface/alienArenaTeams";
import { Link } from "react-router-dom";
import Permissions from "../../../../utils/permissions";
import AllowedTo from "../../../shared/rbac";
import AccessDenied from "../../../shared/accessDenied";

function AlienArena(props: any) {
  const [alienArenaTeams, setAlienArenaTeams] = useState<IAlienArenaTeams>({});
  const [activeTab, setActiveTab] = useState(Constant.ArenaActiveTab.SETUP);

  const [students, setStudents] = useState<Array<IAlienStudentData>>([]);
  const defaultAlienArenaSetup: IAlienArenaSetup = {
    alienArenaId: 0,
    blasterLevel: "",
    blasterName: "",
    classId: 0,
    className: "",
    gameId: 0,
    gamePlaySide: "",
    gradeName: "",
    isActive: true,
    questionCount: 0,
    rocketId: 0,
    schoolId: props.userContext?.schoolId ? props.userContext?.schoolId : 0,
    sessionName: "",
    standardIds: "",
    subjectId: 0,
    subjectName: "",
    userId: props.userContext?.userId ? props.userContext?.userId : 0,
    status: 1,
  };

  const [alienArenaSetup, setAlienArenaSetup] = useState<IAlienArenaSetup>(
    defaultAlienArenaSetup
  );
  const [alienArena, setAlienArena] = useState<IAlienArena>(
    defaultAlienArenaSetup
  );

  const isTabToDisable = (tabName: string) => {
    if (tabName === Constant.ArenaActiveTab.TEAMS) {
      if (
        alienArenaSetup.sessionName &&
        alienArenaSetup.sessionName?.length > 0 &&
        alienArenaSetup.questionCount &&
        alienArenaSetup.standardIds &&
        alienArenaSetup.subjectName &&
        alienArenaSetup.teamSize &&
        alienArenaSetup.className &&
        alienArenaSetup.gamePlaySide
      ) {
        setActiveTab(tabName);
      } else {
        setActiveTab(Constant.ArenaActiveTab.SETUP);
      }
    } else {
      setActiveTab(tabName);
    }
  };

  return (
    <AllowedTo
      perform={[
        Permissions.alien_arena_setup_create,
        Permissions.alien_arena_setup_modify,
      ]}
      no={() => <AccessDenied />}
    >
      <div className="min-w-0 w-full">
        <div className="px-4 py-5 lg:px-8 border-b">
          <ol className="flex items-center space-x-2 mb-1">
            <li>
              <Link to={RouteConstant.TeacherRoutes.AlienArenaSessions}>
                <div className="flex items-center">
                  <div className="cursor-pointer text-sm text-gray-500 hover:text-gray-700 border-dashed border-b border-gray-400 hover:border-opacity-0">
                    Alien Arena
                  </div>
                </div>
              </Link>
            </li>
            <li>
              <div className="flex items-center">
                <ChevronRightIcon className="flex-shrink-0 h-5 w-5 text-gray-400" />
                <span className="ml-1 text-sm text-gray-500 capitalize">
                  {`${activeTab === "SETUP" ? "Arena Setup" : "Select Teams"}`}
                </span>
              </div>
            </li>
          </ol>
          <div className=" text-lg font-medium flex gap-2 h-auto sm:h-10 items-center">
            <h1 className="text-primary-violet flex items-center justify-between w-full pr-4">
              {activeTab === Constant.ArenaActiveTab.SETUP
                ? "Alien arena setup"
                : "Select the teams"}
            </h1>
          </div>
        </div>
        <div className="lg:border-b lg:border-gray-200 ">
          <nav className="px-4 sm:pl-6 lg:pl-8 lg:pr-0" aria-label="Progress">
            <ol className="rounded-md overflow-hidden lg:flex lg:border-l lg:border-r lg:border-gray-200 lg:rounded-none">
              <li
                className={`relative overflow-hidden lg:flex-1  ${
                  activeTab === Constant.ArenaActiveTab.SETUP
                    ? "bg-primary-violet"
                    : ""
                } `}
                onClick={() => isTabToDisable(Constant.ArenaActiveTab.SETUP)}
              >
                <div
                  className={`${
                    activeTab === Constant.ArenaActiveTab.SETUP
                      ? ""
                      : "border border-white"
                  }   border border-b-0 border-gray-200 overflow-hidden lg:border-0 `}
                >
                  <a href="#!" aria-current="step" x-description="Current Step">
                    <span
                      className={`absolute top-0 left-0 w-1 h-full  ${
                        activeTab === Constant.ArenaActiveTab.SETUP
                          ? "bg-primary-violet"
                          : "bg-transparent group-hover:bg-gray-200 "
                      } lg:w-full lg:h-0.5 lg:bottom-0 lg:top-auto`}
                      aria-hidden="true"
                    ></span>
                    <span className="px-6 py-3 flex items-center text-sm font-medium lg:pl-9">
                      <span className="flex-shrink-0">
                        <span
                          className={`w-10 h-10 flex items-center justify-center border-2  ${
                            activeTab === Constant.ArenaActiveTab.SETUP
                              ? "border-white"
                              : "border-gray-300"
                          } rounded-full`}
                        >
                          <span
                            className={
                              activeTab === Constant.ArenaActiveTab.SETUP
                                ? "text-white"
                                : "text-gray-500"
                            }
                          >
                            01
                          </span>
                        </span>
                      </span>
                      <span className="mt-0.5 ml-4 min-w-0 flex flex-col">
                        <span
                          className={`text-sm font-semibold  ${
                            activeTab === Constant.ArenaActiveTab.SETUP
                              ? "text-white"
                              : "text-gray-500"
                          } tracking-wide uppercase`}
                        >
                          Arena Setup
                        </span>
                      </span>
                    </span>
                  </a>
                </div>
              </li>
              <li
                className={`relative overflow-hidden lg:flex-1 ${
                  activeTab === Constant.ArenaActiveTab.TEAMS
                    ? "bg-primary-violet"
                    : ""
                } `}
                onClick={() => isTabToDisable(Constant.ArenaActiveTab.TEAMS)}
              >
                <div
                  className={`${
                    activeTab === Constant.ArenaActiveTab.TEAMS
                      ? ""
                      : "border border-white"
                  }   border border-b-0 border-gray-200 overflow-hidden lg:border-0`}
                >
                  <a href="#!" aria-current="step" x-description="Current Step">
                    <span
                      className={`absolute top-0 left-0 w-1 h-full  ${
                        activeTab === Constant.ArenaActiveTab.TEAMS
                          ? "bg-primary-violet"
                          : "bg-transparent group-hover:bg-gray-200 "
                      }   lg:w-full lg:h-0.5 lg:bottom-0 lg:top-auto`}
                      aria-hidden="true"
                    ></span>
                    <span className="px-6 py-3 flex items-center text-sm font-medium lg:pl-9">
                      <span className="flex-shrink-0">
                        <span
                          className={`w-10 h-10 flex items-center justify-center border-2  ${
                            activeTab === Constant.ArenaActiveTab.TEAMS
                              ? "border-white"
                              : "border-gray-300"
                          } rounded-full`}
                        >
                          <span
                            className={
                              activeTab === Constant.ArenaActiveTab.TEAMS
                                ? "text-white"
                                : "text-gray-500"
                            }
                          >
                            02
                          </span>
                        </span>
                      </span>
                      <span className="mt-0.5 ml-4 min-w-0 flex flex-col">
                        <span
                          className={`text-sm font-semibold  ${
                            activeTab === Constant.ArenaActiveTab.TEAMS
                              ? "text-white"
                              : "text-gray-500"
                          } tracking-wide uppercase`}
                        >
                          Select Teams
                        </span>
                      </span>
                    </span>
                  </a>
                  <div
                    className={`hidden absolute top-0 -left-${
                      activeTab === Constant.ArenaActiveTab.SETUP ? "0.5" : "0"
                    } w-3 inset-0 lg:block`}
                    aria-hidden="true"
                  >
                    {activeTab === Constant.ArenaActiveTab.SETUP && (
                      <svg
                        className={`h-full w-full ${
                          activeTab === Constant.ArenaActiveTab.SETUP
                            ? " text-primary-violet fill-current"
                            : " text-gray-300"
                        }`}
                        viewBox="0 0 12 82"
                        fill="none"
                        preserveAspectRatio="none"
                      >
                        <path
                          d="M0.5 0V31L10.5 41L0.5 51V82"
                          stroke="currentcolor"
                          vectorEffect="non-scaling-stroke"
                        ></path>
                      </svg>
                    )}
                  </div>
                </div>
              </li>
            </ol>
          </nav>
        </div>
        {activeTab === Constant.ArenaActiveTab.SETUP && (
          <ArenaSetup
            userId={props.userContext.userId}
            setActiveTab={setActiveTab}
            setStudents={setStudents}
            alienArenaSetup={alienArenaSetup}
            setAlienArenaSetup={setAlienArenaSetup}
            alienArenaId={0}
            setAlienArenaTeams={setAlienArenaTeams}
          />
        )}
        {activeTab === Constant.ArenaActiveTab.TEAMS && (
          <ArenaTeams
            userId={props.userContext.userId}
            setActiveTab={setActiveTab}
            students={students}
            alienArenaSetup={alienArenaSetup}
            setAlienArena={setAlienArena}
            alienArena={alienArena}
            alienAreanaTeams={alienArenaTeams}
          />
        )}

        <div className="px-4 py-3 bg-gray-50 text-right sm:px-6  block lg:hidden">
          <button
            type="submit"
            className="bg-primary-violet border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Save & Continue
          </button>
        </div>
      </div>
    </AllowedTo>
  );
}

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(AlienArena);

import { Fragment } from "react/jsx-runtime";
import anglerfish from "../../../../../assets/images/thinkorswim/anglerfish.png";
import BubbleButton1 from "../../../../../assets/images/thinkorswim/BubbleButton1.png";
import BubbleButton2 from "../../../../../assets/images/thinkorswim/BubbleButton2.png";

interface IAnglerfishButtonProps {
  text: string,
  textSecondOption?: string
  canTextShink?: boolean,
  textSize?: number,
  onClick: () => void,
  onClickSecondOption?: () => void,
}

function AnglerfishButton(props: IAnglerfishButtonProps) {
  const textSize = props.textSize ? "" + props.textSize : "1.2";
  const sizeUnit = props.canTextShink ? "vw" : "em";
  const showSecondOption = props.textSecondOption && props.onClickSecondOption;

  const buttonClass = `cursor-pointer relative flex text-[${textSize}${sizeUnit}] group `;

  return (
    <Fragment>
      <div 
        className={`relative flex justify-center w-full`}
      >
        <div
          className={buttonClass + (showSecondOption ? 'w-3/5' : 'w-full')}
          onClick={props.onClick}
        >
          <div className="absolute left-[68%] top-[50%] w-1/2 -translate-x-1/2 -translate-y-1/2 group-hover:scale-110">
            <p 
              className="text-black text-center"
            >
              {props.text}
            </p>
          </div>
          <div 
            className="w-1/3 grid place-items-center"
          >
            <img
              className="w-full"
              src={anglerfish}
              alt="button"
            />
          </div>
          <div 
            className="w-2/3 grid place-items-center"
          >
            <img
              className="w-full h-auto"
              src={BubbleButton1}
              alt="option1"
            />
          </div>
        </div>
        {showSecondOption && (<div
          className={buttonClass + 'w-2/5'}
          onClick={props.onClickSecondOption}
        >
          <div className="absolute left-[53%] top-[50%] w-full -translate-x-1/2 -translate-y-1/2 group-hover:scale-110">
            <p 
              className="text-black text-center"
            >
              {props.textSecondOption}
            </p>
          </div>
          <div className="w-full grid place-items-center">
            <img 
              className="w-full h-auto" 
              src={BubbleButton2} 
              alt="option2" 
            />
          </div>
        </div>)}
      </div>
    </Fragment>
  )
}

export default AnglerfishButton

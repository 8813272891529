import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import Moment from "moment";
import { Fragment, useEffect } from "react";
import { IAssignmentActivity } from "../../../../model/interface/assignmentActivity";
import Assignment from "../../../../model/teacher/assignment";
import { getActivityName } from "../../../../utils/assignmentHelper";
import Constant from "../../../../utils/constant/constant";
interface AssignmentSettingProps {
  assignment: Assignment;
  activity: IAssignmentActivity | undefined;
  toggleSettingPopup: (popupAction: boolean) => void;
  isRemediation?: boolean;
}

function AssignmentSetting(props: AssignmentSettingProps) {
  const { assignment, activity, toggleSettingPopup, isRemediation } = props;
  useEffect(() => {}, []);

  return (
    <Fragment>
      <Transition.Root show={true} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          onClose={() => toggleSettingPopup(false)}
        >
          <div className="flex items-center justify-center min-h-screen text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-middle bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all w-full sm:max-w-xl">
                <div className="px-4 py-4 bg-gray-50 sm:px-4">
                  <div className="flex items-start justify-between space-x-3">
                    <div className="space-y-1">
                      <div className="text-lg font-medium text-gray-900">
                        <Dialog.Title
                          as="h3"
                          className="text-lg font-medium text-gray-900 capitalize"
                        >
                          {assignment.name}
                        </Dialog.Title>
                      </div>
                    </div>
                    <div className="h-7 flex items-center">
                      <button
                        type="button"
                        className="text-gray-400 hover:text-gray-500"
                        onClick={() => toggleSettingPopup(false)}
                      >
                        <span className="sr-only">Close</span>
                        <XIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                </div>

                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:text-left">
                    <div className="">
                      <div className="inline-block align-middle bg-white rounded-lg text-left overflow-hidden transform transition-all p-4">
                        <div className="sm:flex-row space-y-1 ml-4">
                          <div className="flex border">
                            <div className="w-60 sm:w-96 bg-gray-100 text-right px-4 py-1">
                              <span className="block text-sm font-medium text-gray-700 flex items-center justify-end">
                                Assignment
                              </span>
                            </div>
                            <div className="w-full px-4 py-1">
                              <span
                                className="block text-sm text-gray-500 flex items-center"
                                dangerouslySetInnerHTML={{
                                  __html: activity
                                    ? getActivityName(activity)
                                    : assignment.name,
                                }}
                              ></span>
                            </div>
                          </div>
                          <div className="flex border">
                            <div className="w-60 sm:w-96 bg-gray-100 text-right px-4 py-1">
                              <span className="block text-sm font-medium text-gray-700 flex items-center justify-end">
                                Creator
                              </span>
                            </div>
                            <div className="w-full px-4 py-1">
                              <span className="block text-sm text-gray-500 flex items-center">
                                {assignment.author}
                              </span>
                            </div>
                          </div>
                          <div className="flex border">
                            <div className="w-60 sm:w-96 bg-gray-100 text-right px-4 py-1">
                              <span className="block text-sm font-medium text-gray-700 flex items-center justify-end">
                                Type
                              </span>
                            </div>
                            <div className="w-full px-4 py-1">
                              <span className="block text-sm text-gray-500 flex items-center">
                                {activity
                                  ? activity.activityType
                                  : isRemediation
                                  ? Constant.AssignmentActivityType
                                      .PRACTICEQUESTIONS
                                  : "Assignment"}
                              </span>
                            </div>
                          </div>
                          <div className="flex border">
                            <div className="w-60 sm:w-96 bg-gray-100 text-right px-4 py-1">
                              <span className="block text-sm font-medium text-gray-700 flex items-center justify-end">
                                Subject
                              </span>
                            </div>
                            <div className="w-full px-4 py-1">
                              <span className="block text-sm text-gray-500 flex items-center">
                                {assignment.subject}
                              </span>
                            </div>
                          </div>
                          <div className="flex border">
                            <div className="w-60 sm:w-96 bg-gray-100 text-right px-4 py-1">
                              <span className="block text-sm font-medium text-gray-700 flex items-center justify-end">
                                Assigned
                              </span>
                            </div>
                            <div className="w-full px-4 py-1">
                              <span className="block text-sm text-gray-500 flex items-center">
                                {assignment.assigned}
                              </span>
                            </div>
                          </div>
                          <div className="flex border">
                            <div className="w-60 sm:w-96 bg-gray-100 text-right px-4 py-1">
                              <span className="block text-sm font-medium text-gray-700 flex items-center justify-end">
                                Completed
                              </span>
                            </div>
                            <div className="w-full px-4 py-1">
                              <span className="block text-sm text-gray-500 flex items-center">
                                {activity
                                  ? activity.completed
                                    ? activity.completed
                                    : 0
                                  : assignment.completed}
                              </span>
                            </div>
                          </div>
                          <div className="flex border">
                            <div className="w-60 sm:w-96 bg-gray-100 text-right px-4 py-1">
                              <span className="block text-sm font-medium text-gray-700 flex items-center justify-end">
                                Start Date
                              </span>
                            </div>
                            <div className="w-full px-4 py-1">
                              <span className="block text-sm text-gray-500 flex items-center">
                                {assignment.startDate &&
                                  Moment(assignment.startDate).format(
                                    "MM-DD-yyyy"
                                  )}
                              </span>
                            </div>
                          </div>
                          <div className="flex border">
                            <div className="w-60 sm:w-96 bg-gray-100 text-right px-4 py-1">
                              <span className="block text-sm font-medium text-gray-700 flex items-center justify-end">
                                Due Date
                              </span>
                            </div>
                            <div className="w-full px-4 py-1">
                              <span className="block text-sm text-gray-500 flex items-center">
                                {assignment.endDate &&
                                  Moment(assignment.endDate).format(
                                    "MM-DD-yyyy"
                                  )}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className={`${isRemediation ? "hidden" : ""}`}>
                          <div className="flex mt-4 mb-4 font-normal border-b border-gray-200 pb-2">
                            Settings
                          </div>
                          <div>
                            <div className="ml-4 space-y-1">
                              {activity && (
                                <Fragment>
                                  <div className="flex border">
                                    <div className="w-60 sm:w-96 bg-gray-100 text-right px-4 py-1">
                                      <span className="block text-sm font-medium text-gray-700 flex items-center justify-end">
                                        Number of attempts
                                      </span>
                                    </div>
                                    <div className="w-full px-4 py-1">
                                      <span className="block text-sm text-gray-500 flex items-center">
                                        {`${
                                          activity?.multipleAttempt === 999
                                            ? "Unlimited"
                                            : activity?.multipleAttempt
                                        } Attempt`}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="flex border">
                                    <div className="w-60 sm:w-96 bg-gray-100 text-right px-4 py-1">
                                      <span className="block text-sm font-medium text-gray-700 leading-tight">
                                        Minimum Score Requirement
                                      </span>
                                    </div>
                                    <div className="w-full px-4 py-1 flex items-center">
                                      <span className="block text-sm text-gray-500 ">
                                        {activity?.minimumScore}
                                      </span>
                                    </div>
                                  </div>
                                </Fragment>
                              )}
                              {!activity && (
                                <Fragment>
                                  <div className="flex">
                                    <div className="w-60 sm:w-96">
                                      <span className="block text-sm font-medium text-gray-700 mb-1">
                                        Add to school bank
                                      </span>
                                    </div>
                                    <div className="w-full">
                                      <span className="block text-sm text-gray-500 mb-1">
                                        {assignment.isSharedWithSchool
                                          ? "Yes"
                                          : "No"}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="flex">
                                    <div className="w-60 sm:w-96">
                                      <span className="block text-sm font-medium text-gray-700 mb-1">
                                        Display answer explanations
                                      </span>
                                    </div>
                                    <div className="w-full">
                                      <span className="block text-sm text-gray-500 mb-1">
                                        {assignment.displayAnswerExplanation
                                          ? "Yes"
                                          : "No"}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="flex">
                                    <div className="w-60 sm:w-96">
                                      <span className="block text-sm font-medium text-gray-700 mb-1">
                                        Complete Activities in Order / Any Order
                                      </span>
                                    </div>
                                    <div className="w-full">
                                      <span className="block text-sm text-gray-500 mb-1">
                                        {assignment.completeInOrder
                                          ? "Yes"
                                          : "No"}
                                      </span>
                                    </div>
                                  </div>
                                </Fragment>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col items-center justify-end px-6 py-4 sm:flex-row bg-gray-50 dark:bg-gray-800">
                  <div className="flex-shrink-0">
                    <div className="space-x-3 flex justify-end">
                      <button
                        onClick={() => toggleSettingPopup(false)}
                        className="align-middle inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 rounded-lg text-sm text-white border border-transparent bg-primary-green focus:shadow-outline-purple w-full sm:w-auto"
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </Fragment>
  );
}

export default AssignmentSetting;

import { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { getVideoAssignment } from "../../../../../../api/student/studentVideoAssignmentApi";
import { IBreadcrumb } from "../../../../../../model/common/breadcrumb";
import { IStudentVideoAssignment } from "../../../../../../model/interface/studentVideoAssignment";
import { UserContextState } from "../../../../../../redux/actions/userContextAction";
import routeConstant from "../../../../../../utils/constant/routeConstant";
import Breadcrumb from "../../../middleSchool/breadcrumb";
import { useTranslation } from "react-i18next";
import Profile from "../../../../../../model/users/profile";
import constant from "../../../../../../utils/constant/constant";
import {paramDecode, paramEncode} from "../../../../../../utils/urlHelper";
import UpgradeButtonControl from "../../../../../shared/customButtonControl";

interface IVideoQuestion {
  match: any;
  userContext: UserContextState;
  profile?: Profile;
}

function VideoQuestionList(props: IVideoQuestion) {
  const standardId = parseInt(atob(props.match.params.standardId));
  const subjectId = parseInt(atob(props.match.params.subjectId));
  const standardName = paramDecode(props.match.params.standardName);
  const history = useHistory();
  const { t } = useTranslation();
  const [standardVideos, setStandardVideos] = useState<
    Array<IStudentVideoAssignment>
  >([]);

  useEffect(() => {
    getVideoAssignment(props.userContext.userId, subjectId, standardId).then(
      (d) => {
        setStandardVideos(d.data);
      }
    );
  }, []);
  function getStatus(a: IStudentVideoAssignment) {
    switch (a.assignmentStatusId) {
      case 0:
        return t("Assignments.NotStarted");
      case 2:
        return t("Assignments.Continue");
      case 3:
        return a.score >= constant.VIDEO_PASSING_SCORE
          ? t("Assignments.Complete") + " - " + a.score.toString() + "%"
          : t("Assignments.TryAgain");
      default:
        return "";
    }
  }

  function startVideoPractice(video: IStudentVideoAssignment) {
    history.push({
      pathname: routeConstant.MiddleSchool.VideoQuestionPractice
          .replace(":standardName", paramEncode(standardName))
          .replace(":subjectId", encodeURI(btoa(subjectId.toString())))
          .replace(":standardId", encodeURI(btoa(standardId.toString())))
          .replace(":videoId", encodeURI(btoa(video.videoId.toString()))),
      state: {
        videoTitle:
          props.profile?.plLanguageId === constant.Languages.Spanish
            ? video.spanishVideoTitle ?? video.videoTitle
            : video.videoTitle,
      },
    });
  }
  const breadcrumbItems = () => {
    const items: Array<IBreadcrumb> = [
      {
        name: t("Breadcrumb.Home"),
        url: routeConstant.MiddleSchool.assignments,
        textColor: "",
      },
      {
        name: t("Breadcrumb.StudyPlanSubjects"),
        textColor: "",
        url: routeConstant.SUBJECTS_MS,
      },
      {
        name: t("Breadcrumb.StudyPlanStandards"),
        textColor: "",
        url: routeConstant.MiddleSchool.StudyPlanStandards.replace(
          ":id",
          subjectId.toString()
        ),
      },
      {
        name: t("Breadcrumb.VideoLearn"),
        textColor: "",
        url: "",
      },
    ];

    return items;
  };
  return (
    <Fragment>
      <div data-id={standardId}>
        <div className="md:flex md:items-center px-4 py-3 lg:px-8 border-b">
          <Breadcrumb items={breadcrumbItems()} />
          <div className="md:w-[55%] text-lg flex justify-between gap-2 h-auto sm:h-10 items-center">
            <h1 className="text-gray-50 font-medium flex justify-start">
              {t("StudyPlan.VideoQuestionLearn")}
            </h1>
          </div>
        </div>

        <div className="px-4 py-4 lg:px-8">
          <section>
            <h2 className="text-lg x-2 pb-3 text-white ">{standardName}</h2>
          </section>
          <div className="flex flex-wrap gap-5">
            {standardVideos.map((d) => {
              return (
                <Fragment key={d.videoId}>
                  <UpgradeButtonControl
                    controlType={"div"}
                    userContext={props?.userContext}
                    isUpgradeRequired={props?.profile?.isUpgradeRequired}
                    isRoleCheck={true}
                    className={
                      "flex flex-col shadow-lg overflow-hidden w-56 border-4 rounded-xl cursor-pointer group " +
                      (props?.profile?.isUpgradeRequired
                        ? " cursor-not-allowed bg-gray-200 "
                        : " bg-white hover:bg-gray-100 hover:border-4 hover:border-primary-violet/50 transition-all ")
                    }
                    onClick={() => {
                      startVideoPractice(d);
                    }}
                  >
                    <span className="inline-block p-4">
                      <img
                        className="rounded-xl w-full h-36 mx-auto cursor-pointer"
                        srcSet={d.thumbnailUrl}
                        alt="Video Questions"
                      />
                    </span>
                    <div className="justify-between flex-grow p-4 bg-gray-100 transition-all">
                      <div className="flex w-full justify-between items-center">
                        <span className="block font-semibold text-sm text-gray-700 group-hover:text-gray-900 mb-1">
                          {getStatus(d)}
                        </span>
                      </div>
                      <div className="flex w-full">
                        <span className="block text-xs font-semibold text-secondary-teal uppercase">
                          {props.profile?.plLanguageId ===
                          constant.Languages.Spanish
                            ? d.spanishVideoTitle ?? d.videoTitle
                            : d.videoTitle}
                        </span>
                      </div>
                    </div>
                  </UpgradeButtonControl>
                </Fragment>
              );
            })}

            {standardVideos && standardVideos.length === 0 && (
              <>
                <span> Videos not available to learn </span>
              </>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
}

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(VideoQuestionList);

import { connect } from "react-redux";
import { useEffect, useState } from "react";
import ClassDropDown from "../shared/classDropDown";
import ITeacherClass from "../../../../model/interface/teacherclass";
import { getAllClassesBySubject } from "../../../../api/teacher/teacher";
import { getStudentSettings } from "../../../../api/teacher/studentSetting";
import Asterisk from "../../../shared/asterisk";
// import AllowedTo from "../../../shared/rbac";
import Profile from "../../../../model/users/profile";
import { UserContextState } from "../../../../redux/actions/userContextAction";
import { getContentArea } from "../../../../api/contentArea/contentArea";
import TextToSpeechSetting from "./textToSpeechSetting";
import LanguageSetting from "./languageSetting";
import IStudentSetting from "../../../../model/teacher/studentSetting";
import PagingResponse from "../../../../model/common/pagingResponse";
import PageSize from "../../../shared/pagination/pageSize";
import Pagination from "../../../shared/pagination/pagination";
import {
  DEFAULT_PAGE_NUMBER,
  DEFAULT_PAGE_SIZE_50,
} from "../../../../utils/pagingConstant";
import { ISelectElement } from "../../../../model/interface/selectElement";
import Breadcrumb from "../../teacher/shared/breadcrumb";
import { IBreadcrumb } from "../../../../model/common/breadcrumb";
import RouteConstant from "../../../../utils/constant/routeConstant";
import { useTranslation } from "react-i18next";
import Loader from "../../../shared/loader";

interface StudentSettingProps {
  userContext: UserContextState;
  profile: Profile;
}
function StudentSetting(props: StudentSettingProps) {
  const { t } = useTranslation();
  const [classes, setClasses] = useState<Array<ITeacherClass>>([]);
  const [selectedClass, setSelectedClass] = useState<ISelectElement>({
    label: "Select Class",
    value: "0",
  });
  const [isLoadingClasses, setIsLoadingClasses] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState<string>("TTS");
  const [contentAreas, setContentAreas] = useState<any>();
  const [students, setStudents] = useState<
    PagingResponse<IStudentSetting> | undefined
  >();
  const [pageNumber, setPageNumber] = useState(DEFAULT_PAGE_NUMBER);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE_50);
  const [loading, showLoading] = useState(false);
  const [resetPageNumber, setResetPageNumber] = useState<boolean>(false);
  const getClasses = async () => {
    setIsLoadingClasses(true);
    const response = getAllClassesBySubject(
      props.userContext.userId,
      props.userContext.roleId,
      null,
      props.profile.currentSchoolYear.schoolYearId,
      props.userContext.schoolId,
        "gte"
    );
    response
      ?.then((d) => {
        setClasses(d.data.data);
      })
      .catch(() => {})
      .finally(() => {
        setIsLoadingClasses(false);
      });
  };
  const getContentAreas = async () => {
    var contentAreas = await getContentArea();
    setContentAreas(contentAreas.data.data);
  };

  const breadcrumbItems = () => {
    const items: Array<IBreadcrumb> = [
      { name: t("Breadcrumb.Home"), url: RouteConstant.ROUTE_DASHBOARD },
      {
        name: t("Breadcrumb.StudentSettings"),
        url: "",
      },
    ];

    return items;
  };

  const getSettings = async () => {
    showLoading(true);
    getStudentSettings(parseInt(selectedClass.value), pageSize, pageNumber)
      .then((response) => {
        if (response?.data) {
          if (response.data.isSuccess && response.data.data) {
            setStudents(response.data.data);
          }
        }
        showLoading(false);
      })
      .catch(() => {
        showLoading(false);
      });
  };
  useEffect(() => {
    getClasses();
    getContentAreas();
  }, []);

  useEffect(() => {
    if (parseInt(selectedClass?.value) > 0) {
      getSettings();
    } else {
      setStudents(undefined);
    }
  }, [selectedClass]);
  const onPageSizeChange = (pageSize) => {
    setPageNumber(DEFAULT_PAGE_NUMBER);
    setPageSize(pageSize);
    setResetPageNumber(true);
  };
  useEffect(() => {
    if (parseInt(selectedClass?.value) > 0) {
      getSettings();
    } else {
      setStudents(undefined);
    }
  }, [pageNumber, pageSize]);
  return (
    <>
      <div className="min-w-0 w-full">
        {loading && <Loader />}
        <div className="lg:flex lg:items-center px-4 py-3 lg:px-8 border-b">
          <Breadcrumb items={breadcrumbItems()} />
          <div className="lg:w-[55%] text-lg flex justify-between gap-2 h-auto sm:h-10 items-center">
            <h1 className="text-primary-violet font-medium flex justify-start">
              Student Settings
            </h1>
          </div>
        </div>
        <div className="px-2 sm:px-6 lg:px-8 py-3">
          <div className="max-w-sm">
            <label className="block mb-1 text-sm text-gray-500">
              Class <Asterisk />
            </label>
            <ClassDropDown
              schoolYearId={props.profile.currentSchoolYear.schoolYearId}
              defaultLabel="Select Class"
              isMultiSelect={false}
              onChange={(item) => setSelectedClass(item[0])}
              value={selectedClass}
              data={classes}
              isDisabled={isLoadingClasses}
              showArchived={true}
              showHidden={true}
              colorStyle="white"
            ></ClassDropDown>
          </div>
        </div>
        <div className="sm:px-6 lg:px-8 pt-2 bg-gray-100 mt-2">
          <div className="flex mb-2 sm:mb-0 px-4 sm:px-0 flex-col sm:flex-row items-end sm:justify-between sm:items-center">
            <nav className="flex space-x-2" aria-label="Tabs">
              <a
                key={"TTS"}
                href={"#!"}
                onClick={() => {
                  setSelectedTab("TTS");
                }}
                className={`${
                  selectedTab === "TTS"
                    ? "border-secondary-teal bg-white text-primary-violet hover:text-dark-violet py-3 tab-box-shadow tab-box-shadow"
                    : "text-primary-violet text-opacity-60 bg-primary-violet/10 py-[0.6rem] mt-auto transition-all hover:py-3 hover:text-opacity-100 hover:text-primary-violet border-[#dee9f1]"
                }
                "px-2 sm:px-4 text-center border-t-2 text-sm cursor-pointer font-medium"
              `}
              >
                Text To Speech
              </a>
              <a
                key={"LanguageSetting"}
                href={"#!"}
                onClick={() => {
                  setSelectedTab("LanguageSetting");
                }}
                className={`${
                  selectedTab === "LanguageSetting"
                    ? "border-secondary-teal bg-white text-primary-violet hover:text-dark-violet py-3 tab-box-shadow tab-box-shadow"
                    : "text-primary-violet text-opacity-60 bg-primary-violet/10 py-[0.6rem] mt-auto transition-all hover:py-3 hover:text-opacity-100 hover:text-primary-violet border-[#dee9f1]"
                }
                "px-2 sm:px-4 text-center border-t-2 text-sm cursor-pointer font-medium"
                `}
              >
                Language Setting
              </a>
            </nav>
            {selectedTab === "LanguageSetting" && (
              <span className="mr-auto ml-4 text-gray-500 text-xs mt-auto pb-2.5">
                Language settings are a global setting but can be overridden in
                an assignment
              </span>
            )}
            {selectedTab === "TTS" && (
              <span className="mr-auto ml-4 text-gray-500 text-xs mt-auto pb-2.5">
                Text to speech is a global setting but can be overridden in an
                assignment
              </span>
            )}
          </div>
        </div>
        <div className="px-2 sm:px-6 lg:px-8 py-6">
          {selectedTab === "TTS" && (
            <TextToSpeechSetting
              ContentAreas={contentAreas}
              Students={students?.data}
              GetSettings={getSettings}
              ClassId={parseInt(selectedClass.value)}
              UserContext={undefined}
            ></TextToSpeechSetting>
          )}
          {selectedTab === "LanguageSetting" && (
            <LanguageSetting
              ContentAreas={contentAreas}
              Students={students?.data}
              GetSettings={getSettings}
              ClassId={parseInt(selectedClass.value)}
              UserContext={undefined}
            ></LanguageSetting>
          )}
        </div>
        {students && (
          <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between border-t-2 py-4 mx-2 sm:mx-6 lg:mx-8">
            {students?.totalRecords !== undefined &&
              students?.totalRecords !== 0 && (
                <Pagination
                  totalRecords={students?.totalRecords}
                  pageSize={pageSize}
                  onPageChange={(e) => {
                    setPageNumber(e);
                  }}
                  reset={resetPageNumber}
                  pageNumber={pageNumber}
                />
              )}
            {students?.totalRecords !== 0 && (
              <PageSize
                pageSize={pageSize}
                onPageSizeChange={onPageSizeChange}
              />
            )}
            {students?.totalRecords === 0 && (
              <span className="text-color-black">No record found</span>
            )}
          </div>
        )}
      </div>
    </>
  );
}
const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
};

export default connect(mapStateToProps)(StudentSetting);

import { Select } from "@windmill/react-ui";
import { Fragment, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { getAllGrades } from "../../../api/gradeApi";
import {
  ArrowUpSmall,
  DownloadIcon,
  DuplicateIcon,
  ExportIcon,
  MessageIcon,
  OutlineFileUpload,
  UserAddIcon,
  UserRemove,
} from "../../../assets/icons";
import { IGrade } from "../../../model/interface/grade";
import Constant from "../../../utils/constant/constant";
import InfoMessage from "../../shared/infoMessage";
import Modal from "../../shared/modal/modal";
import ModalBody from "../../shared/modal/modalBody";
import ModalFooter from "../../shared/modal/modalFooter";
import userApi from "../../../api/userApi";
import Permissions from "../../../utils/permissions";
import AllowedTo from "../../shared/rbac";
import ReactTooltip from "react-tooltip";
import ImportedUserResult from "../../../model/manageUser/importedUserResult";
import moment from "moment";
import Download from "../../../utils/downloadHelper";

const GroupSection = (props) => {
  let hiddenFileInput = useRef<HTMLInputElement>(null);
  const getTab = () => {
    let tempTab: any = [];
    tempTab.push({ name: "Education Galaxy", href: "#", code: "eg" });
    tempTab.push({ name: "Liftoff", href: "#", code: "lo" });
    return tempTab;
  };
  const [showPromoteStudentModal, setShowPromoteStudentModal] = useState(false);
  const [showAddStudentsModal, setShowAddStudentsModal] = useState(false);
  const [tabs, setTabs] = useState<any[]>(getTab());
  const [grades, setGrades] = useState<IGrade[]>();
  const [selectedGrade, setSelectedGrade] = useState(0);
  const [failedUsers, setFailedUsers] = useState<ImportedUserResult[]>([]);

  const [instructionMessage, setInstructionMessage] = useState<string>("");
  const [selectedfile, setFile] = useState(new File([], ""));
  const [userImportId, setUserImportId] = useState<string>("");
  const [userImportStatus, setUserImportStatus] = useState<any[]>([]);
  const [isUploadDisabled, setIsUploadDisabled] = useState<boolean>(false);
  const importInstruction =
    "Please download the provided template and fill it to import users";
  const [timeTaken, setTimeTaken] = useState<number>(0);
  const handleAdd = () => {
    hiddenFileInput.current?.click();
  };

  const handleChange = (event) => {
    setFailedUsers([]);
    setUserImportId("");
    setUserImportStatus([]);
    var files = event.target.files,
      file = files[0];
    setFile(file);
  };

  const handleDelete = () => {
    props.deleteStudents();
  };

  const handleOnClose = () => {
    setShowPromoteStudentModal(false);
    setSelectedGrade(0);
  };

  const handleAddStudentsModalClose = () => {
    setFailedUsers([]);
    setUserImportStatus([]);
    setUserImportId("");
    setFile(new File([], ""));
    setShowAddStudentsModal(false);
    setIsUploadDisabled(false);
  };

  const handleGradePromote = () => {
    if (selectedGrade > 0) {
      props.handleGradePromote(selectedGrade);
      handleOnClose();
    } else {
      toast.error("Please select grade");
    }
  };
  const handlUploadStudent = () => {
    if (selectedfile) {
      const userType =
        props.mode === Constant.ManageUserMode.Student
          ? "S"
          : props.mode === Constant.ManageUserMode.Teacher
          ? "T"
          : "D";
      setIsUploadDisabled(true);
      var formData = new FormData();
      formData.append("file", selectedfile);
      formData.append("UserType", userType);
      userImportStatus.push({
        statusId: 0,
        statusDescription: "Importing file " + selectedfile.name,
        reason: "",
        time: moment().format("DD-MM-YYYY hh:mm:ss"),
        timeTaken: 0,
        isFailed: false,
      });
      setUserImportStatus(userImportStatus);
      userApi
        .importUsers(formData)
        .then((d) => {
          setUserImportId(d.data);
          setFile(new File([], ""));
          userImportStatus.push({
            statusId: 1,
            statusDescription: "Queued for processing",
            reason: "",
            time: moment().format("DD-MM-YYYY hh:mm:ss"),
            timeTaken: 0,
            isFailed: false,
          });
          setUserImportStatus(userImportStatus);
        })
        .catch((error) => {
          setIsUploadDisabled(false);
          toast.error("Error while uploading file. Please try again");
        })
        .finally(() => {});
    } else {
      toast.error("Please select file");
    }
  };

  const handleBulkEmailLogin = () => {
    props.bulkEmailLogin();
  };

  const handleExport = () => {
    Download(failedUsers[0].base64StringData, "Failed users", "csv");
  };

  useEffect(() => {
    setTabs(tabs);
    var gradeResponse = getAllGrades();
    gradeResponse?.then((d) => {
      setGrades(d.data);
    });
    setSelectedGrade(0);
  }, [false]);

  useEffect(() => {
    var message: string =
      "A group action will allow you to customize settings for a group of {mode}s at one time. Filter to view your class and then select the {mode}(s) by checking the box next to their name(s) or check the box at the top to select all {mode}s. Next, choose the desired setting; you can only update one setting at a time.";
    if (props.mode === Constant.ManageUserMode.Student) {
      message = message.replaceAll("{mode}", "student");
    } else if (props.mode === Constant.ManageUserMode.Teacher) {
      message = message.replaceAll("{mode}", "teacher");
    } else if (props.mode === Constant.ManageUserMode.District) {
      message = message.replaceAll("{mode}", "district user");
    }
    setInstructionMessage(message);
  }, [props.mode]);

  useEffect(() => {
    if ((userImportId?.length ?? 0) > 0) {
      const interval = setInterval(function () {
        userApi.userImportStatus(userImportId).then((response) => {
          if (response.data) {
            let importStatus = userImportStatus;
            if (
              importStatus.filter(
                (s) => s.statusDescription === response.data.statusDescription
              ).length === 0
            ) {
              importStatus.push({
                statusId: response.data.statusId,
                statusDescription:
                  response.data.resultCode === 12
                    ? "Import Failed"
                    : response.data.statusDescription,
                reason: response.data.resultDescription ?? "",
                time: moment().format("DD-MM-YYYY hh:mm:ss"),
                isFailed:
                  response.data.failureCount > 0 ||
                  response.data.successCount === 0
                    ? true
                    : false,
              });
            }
            setUserImportStatus(importStatus);
            if (response.data.statusId === 3) {
              clearInterval(interval);
              setIsUploadDisabled(false);
              const startTime = moment(
                userImportStatus[0].time,
                "DD-MM-YYYY hh:mm:ss"
              );
              const endTime = moment(
                userImportStatus[userImportStatus.length - 1].time,
                "DD-MM-YYYY hh:mm:ss"
              );
              setTimeTaken(endTime.diff(startTime, "seconds"));
              if (response.data.failureCount > 0) {
                userApi.importFailedUsers(userImportId).then((failedUsers) => {
                  setFailedUsers(failedUsers.data);
                });
              }
            }
          }
        });
      }, 2000);
    }
  }, [userImportId]);

  return (
    <Fragment>
      <ReactTooltip
        multiline={true}
        className="bg-secondary-teal"
        backgroundColor="#008fbb"
      />
      <div className="md:col-span-2 lg:col-span-2 bg-white  sm:pl-6 md:border-l">
        <div>
          <div className="flex flex-col">
            <span className="text-gray-500">Group Action </span>
            <div>
              <InfoMessage message={instructionMessage}></InfoMessage>
            </div>
          </div>
          <hr className="border-t border-gray-200" aria-hidden="true"></hr>
          <div className="gap-y-3">
            <AllowedTo perform={Permissions.user_management_create}>
              {props.mode !== Constant.ManageUserMode.District && (
                <div className="flex items-center mt-0.5">
                  <button
                    type="button"
                    onClick={() => {
                      setShowAddStudentsModal(true);
                    }}
                    className="items-center flex inline-block px-4 py-2.5 bg-transparent text-blue-600 font-medium text-xs leading-tight rounded hover:bg-gray-100 focus:text-blue-700 focus:bg-gray-100 focus:outline-none focus:ring-0 active:bg-gray-200 active:text-blue-800 transition duration-300 ease-in-out"
                  >
                    <UserAddIcon title="Import" className="w-4 h-4 mr-1" />

                    {props.mode === Constant.ManageUserMode.Student &&
                      "Import Students"}
                    {props.mode === Constant.ManageUserMode.Teacher &&
                      "Import Teachers"}
                    {props.mode === Constant.ManageUserMode.District &&
                      "Import District Users"}
                  </button>
                </div>
              )}
            </AllowedTo>

            <AllowedTo perform={Permissions.user_management_delete}>
              <div className="flex items-center mt-1">
                <button
                  type="button"
                  className="items-center flex inline-block px-4 py-2.5 bg-transparent text-blue-600 font-medium text-xs leading-tight rounded hover:bg-gray-100 focus:text-blue-700 focus:bg-gray-100 focus:outline-none focus:ring-0 active:bg-gray-200 active:text-blue-800 transition duration-300 ease-in-out"
                  onClick={handleDelete}
                >
                  <UserRemove title="Delete" className="w-4 h-4 mr-1" />
                  {props.mode === Constant.ManageUserMode.Student &&
                    "Delete Students"}
                  {props.mode === Constant.ManageUserMode.Teacher &&
                    "Delete Teachers"}
                  {props.mode === Constant.ManageUserMode.District &&
                    "Delete District Users"}
                </button>
              </div>
            </AllowedTo>

            <AllowedTo perform={Permissions.user_management_modify}>
              {props.mode === Constant.ManageUserMode.Student && (
                <div className="flex items-center mt-0.5">
                  <button
                    type="button"
                    className="items-center flex text-left inline-block px-4 py-2.5 bg-transparent text-blue-600 font-medium text-xs leading-tight rounded hover:bg-gray-100 focus:text-blue-700 focus:bg-gray-100 focus:outline-none focus:ring-0 active:bg-gray-200 active:text-blue-800 transition duration-300 ease-in-out"
                    onClick={() => {
                      if (
                        props.selectedUserIds === undefined ||
                        props.selectedUserIds === null ||
                        props.selectedUserIds.length === 0
                      ) {
                        toast.error("Please select users for group actions.");
                      } else {
                        setShowPromoteStudentModal(true);
                      }
                    }}
                  >
                    <ArrowUpSmall
                      title="Update student grade level"
                      className="w-4 h-4 mr-1"
                    />
                    Update Student Grade Level
                  </button>
                </div>
              )}
            </AllowedTo>

            {props.mode === Constant.ManageUserMode.Student && (
              <div className="flex items-center mt-0.5 hidden">
                <button
                  type="button"
                  className="items-center flex text-left inline-block px-4 py-2.5 bg-transparent text-blue-600 font-medium text-xs leading-tight rounded hover:bg-gray-100 focus:text-blue-700 focus:bg-gray-100 focus:outline-none focus:ring-0 active:bg-gray-200 active:text-blue-800 transition duration-300 ease-in-out"
                  onClick={props.handleViewPotentailDuplicateStudents}
                >
                  <DuplicateIcon className="w-4 h-4 mr-1" />
                  View potential duplicate students
                </button>
              </div>
            )}

            <AllowedTo perform={Permissions.user_management_modify}>
              {props.mode !== Constant.ManageUserMode.Student && (
                  <div className="flex items-center mt-0.5">
                    <button
                        type="button"
                        className="items-center flex text-left inline-block px-4 py-2.5 bg-transparent text-blue-600 font-medium text-xs leading-tight rounded hover:bg-gray-100 focus:text-blue-700 focus:bg-gray-100 focus:outline-none focus:ring-0 active:bg-gray-200 active:text-blue-800 transition duration-300 ease-in-out"
                        onClick={handleBulkEmailLogin}
                    >
                      <MessageIcon className="w-3.5 h-3.5 mr-1"/>
                      Email Login Information
                    </button>
                  </div>)}
            </AllowedTo>
          </div>
        </div>
      </div>
      <Modal
        isOpen={showPromoteStudentModal}
        onClose={() => handleOnClose()}
        title="Change Grade"
        className="w-full overflow-y-auto h-auto max-h-80percent bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:max-w-3xl"
      >
        <ModalBody>
          <div className="md:col-span-2 lg:col-span-3 px-6 py-4">
            <div className="">
              <div>
                <label
                  htmlFor="user_name"
                  className="block text-sm text-gray-500"
                >
                  Change Grade to:
                </label>
                <div className="mt-1"></div>
              </div>
              <div>
                <Select
                  onChange={(e) => {
                    setSelectedGrade(parseInt(e.target.value));
                  }}
                  className="py-2.5 px-4 block text-sm focus:outline-none border border-gray-400
                                sm:focus:border-gray-700 border-opacity-50 focus:shadow-outline-green form-select
                                leading-5 p-1 rounded-lg max-w-xs"
                >
                  <option key={0} value={0}>
                    Select Grade
                  </option>
                  {grades?.map((item: IGrade) => {
                    return (
                      <Fragment key={item.gradeId}>
                        <option key={item.gradeId} value={item.gradeId}>
                          {item?.name}
                        </option>
                      </Fragment>
                    );
                  })}
                </Select>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            className="align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 rounded-lg text-sm text-gray-600 border-gray-300 border dark:text-gray-400 focus:outline-none active:bg-transparent hover:border-gray-500 focus:border-gray-500 active:text-gray-500 focus:shadow-outline-gray sm:w-auto"
            type="button"
            onClick={() => {
              setShowPromoteStudentModal(false);
            }}
          >
            Cancel
          </button>
          <button
            className="align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 rounded-lg text-sm text-white border border-transparent bg-primary-green focus:shadow-outline-purple sm:w-auto"
            type="button"
            onClick={handleGradePromote}
          >
            Promote
          </button>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={showAddStudentsModal}
        onClose={handleAddStudentsModalClose}
        title="Import Users"
        className="w-full overflow-y-auto h-auto max-h-80percent bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:max-w-4xl"
      >
        <ModalBody>
          <div className="md:col-span-2 lg:col-span-3 px-6">
            <InfoMessage message={importInstruction}></InfoMessage>
          </div>
          <div className="md:col-span-4 lg:col-span-3 px-6 py-4">
            <div className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 xl:grid-cols-2 sm:gap-x-8">
              <div className="flex">
                <button
                  type="submit"
                  className="flex items-center bg-primary-violet border border-transparent mb-auto shadow-sm  py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-dark-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={handleAdd}
                  disabled={isUploadDisabled}
                >
                  <OutlineFileUpload className="w-4 h-4 mr-2 text-white fill-current" />
                  Upload file
                </button>
                <div className="ml-2 justify">
                  {(props.userContext.roleId ===
                    Constant.UserRoleId.SchoolAdmin ||
                    props.userContext.roleId ===
                      Constant.UserRoleId.SchoolTeacher ||
                    props.userContext.roleId
                      === Constant.UserRoleId.FreeTeacher) && (
                    <>
                      {props.mode === Constant.ManageUserMode.Student && (
                        <a
                          href="/templates/Import_Students.csv"
                          download
                          className="group bg-transparent border border-primary-violet shadow-sm py-2 px-4 inline-flex justify-center items-center text-sm font-medium text-primary-violet hover:text-white hover:bg-light-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition ease-in-out duration-500"
                        >
                          <DownloadIcon className="mr-2 w-4 h-4 group-hover:text-white fill-current" />
                          Download Template
                        </a>
                      )}
                      {props.mode === Constant.ManageUserMode.Teacher && (
                        <a
                          href="/templates/Import_Teachers.csv"
                          download
                          className="group bg-transparent border border-primary-violet shadow-sm py-2 px-4 inline-flex justify-center items-center text-sm font-medium text-primary-violet hover:text-white hover:bg-light-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition ease-in-out duration-500"
                        >
                          <DownloadIcon className="mr-2 w-4 h-4 group-hover:text-white fill-current" />
                          Download Template
                        </a>
                      )}
                    </>
                  )}

                  {props.userContext.roleId ===
                    Constant.UserRoleId.District && (
                    <>
                      {props.mode === Constant.ManageUserMode.Student && (
                        <a
                          href="/templates/districtTemplates/Import_Students.csv"
                          download
                          className="group bg-transparent border border-primary-violet shadow-sm py-2 px-4 inline-flex justify-center items-center text-sm font-medium text-primary-violet hover:text-white hover:bg-light-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition ease-in-out duration-500"
                        >
                          <DownloadIcon className="mr-2 w-4 h-4 group-hover:text-white fill-current" />
                          Download Template
                        </a>
                      )}
                      {props.mode === Constant.ManageUserMode.District && (
                        <a
                          href="/templates/districtTemplates/Import_District_Users.csv"
                          download
                          className="group bg-transparent border border-primary-violet shadow-sm py-2 px-4 inline-flex justify-center items-center text-sm font-medium text-primary-violet hover:text-white hover:bg-light-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition ease-in-out duration-500"
                        >
                          <DownloadIcon className="mr-2 w-4 h-4 group-hover:text-white fill-current" />
                          Download Template
                        </a>
                      )}
                      {props.mode === Constant.ManageUserMode.Teacher && (
                        <a
                          href="/templates/districtTemplates/Import_Teachers.csv"
                          download
                          className="group bg-transparent border border-primary-violet shadow-sm py-2 px-4 inline-flex justify-center items-center text-sm font-medium text-primary-violet hover:text-white hover:bg-light-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition ease-in-out duration-500"
                        >
                          <DownloadIcon className="mr-2 w-4 h-4 group-hover:text-white fill-current" />
                          Download Template
                        </a>
                      )}
                    </>
                  )}
                </div>
              </div>
              <div className="hidden">
                <input
                  type="file"
                  ref={hiddenFileInput}
                  onChange={handleChange}
                  onClick={(event) => {
                    event.currentTarget.value = "";
                  }}
                  style={{ display: "none" }}
                  accept=".csv"
                />
              </div>
              {userImportStatus && userImportStatus.length > 0 && (
                <div className="flex bg-gray-700 p-2 rounded-lg">
                  <table className="table w-full">
                    <thead className="mb-5">
                      <tr className="">
                        <th
                          scope="col"
                          colSpan={3}
                          className="text-left p-1 text-sm font-normal"
                        >
                          <div className="flex border-b pb-1 border-gray-50">
                            <span className="text-white text-base font-semibold">
                              Import Status
                            </span>
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {userImportStatus?.map((importStatus, statusIdx) => (
                        <tr
                          key={statusIdx}
                          className={
                            " hover:bg-gray-300/20 transition ease-in-out duration-300"
                          }
                        >
                          <td>
                            {(statusIdx < userImportStatus.length - 1 ||
                              importStatus.statusId === 3) &&
                              !importStatus.isFailed && (
                                <svg
                                  className="w-5 h-5 text-green-500 fill-current"
                                  viewBox="0 0 16 16"
                                  version="1.1"
                                  aria-hidden="true"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    d="M8 16A8 8 0 108 0a8 8 0 000 16zm3.78-9.72a.75.75 0 00-1.06-1.06L6.75 9.19 5.28 7.72a.75.75 0 00-1.06 1.06l2 2a.75.75 0 001.06 0l4.5-4.5z"
                                  ></path>
                                </svg>
                              )}
                            {(importStatus.isFailed ?? false) && (
                              <svg
                                className="w-5 h-5 text-red-500 fill-current"
                                viewBox="0 0 16 16"
                                version="1.1"
                                aria-hidden="true"
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M2.343 13.657A8 8 0 1113.657 2.343 8 8 0 012.343 13.657zM6.03 4.97a.75.75 0 00-1.06 1.06L6.94 8 4.97 9.97a.75.75 0 101.06 1.06L8 9.06l1.97 1.97a.75.75 0 101.06-1.06L9.06 8l1.97-1.97a.75.75 0 10-1.06-1.06L8 6.94 6.03 4.97z"
                                ></path>
                              </svg>
                            )}
                            {statusIdx === userImportStatus.length - 1 &&
                              importStatus.statusId !== 3 &&
                              !importStatus.isFailed && (
                                <svg
                                  fill="none"
                                  viewBox="0 0 16 16"
                                  className="animate-spin w-5 h-5"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    opacity=".5"
                                    d="M8 15A7 7 0 108 1a7 7 0 000 14v0z"
                                    stroke="#dbab0a"
                                    stroke-width="2"
                                  ></path>
                                  <path
                                    d="M15 8a7 7 0 01-7 7"
                                    stroke="#dbab0a"
                                    stroke-width="2"
                                  ></path>
                                  <path
                                    d="M8 12a4 4 0 100-8 4 4 0 000 8z"
                                    fill="#dbab0a"
                                  ></path>
                                </svg>
                              )}
                          </td>

                          <td className="p-1 text-sm text-white">
                            {importStatus.statusDescription}{" "}
                            {importStatus.reason ?? ""}
                          </td>
                          <td className="p-1 text-sm text-white">
                            <span className="inline-block">
                              {importStatus.statusId === 3
                                ? "Elapsed time " + timeTaken.toString() + "s"
                                : ""}
                            </span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
          {failedUsers && failedUsers.length > 0 && (
            <div className="md:col-span-4 lg:col-span-3 px-6">
              <div className="py-2 align-middle inline-block min-w-full overflow-x-auto">
                <div className="flex justify-between items-end mb-4">
                  <p className="text-sm font-semibold">
                    Import Failed for the following users:
                  </p>
                  <button
                    className="bg-transparent border border-primary-violet shadow-sm py-2 px-4 inline-flex justify-center items-center text-sm font-medium text-primary-violet hover:text-white hover:bg-light-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3 transition ease-in-out duration-500"
                    type="button"
                    onClick={() => {
                      handleExport();
                    }}
                  >
                    <ExportIcon className="w-4 opacity-70"></ExportIcon>
                    <span className="ml-2 text-sm">Export</span>
                  </button>
                </div>
                <div className="h-40 overflow-auto">
                  <table className="table w-full">
                    <thead className="mb-5">
                      <tr className="bg-light-violet  text-white py-3 px-3">
                        <th
                          scope="col"
                          className="text-left  p-3 text-sm font-normal"
                        >
                          <div className="flex">First Name</div>
                        </th>
                        <th
                          scope="col"
                          className="text-left  p-3 text-sm font-normal"
                        >
                          <div className="flex">Middle Name</div>
                        </th>
                        <th
                          scope="col"
                          className="text-left  p-3 text-sm font-normal"
                        >
                          <div className="flex">Last Name</div>
                        </th>
                        <th
                          scope="col"
                          className="text-left  p-3 text-sm font-normal"
                        >
                          <div className="flex">Email</div>
                        </th>
                        <th
                          scope="col"
                          className="text-left  p-3 text-sm font-normal"
                        >
                          <div className="flex">Username</div>
                        </th>
                        <th
                          scope="col"
                          className="text-left  p-3 text-sm font-normal"
                        >
                          <div className="flex">Password</div>
                        </th>
                        {props.mode === Constant.ManageUserMode.Student && (
                          <>
                            <th
                              scope="col"
                              className="text-left  p-3 text-sm font-normal"
                            >
                              <div className="flex">Grade</div>
                            </th>
                            <th
                              scope="col"
                              className="text-left  p-3 text-sm font-normal"
                            >
                              <div className="flex">StudentId</div>
                            </th>
                          </>
                        )}
                        <th
                          scope="col"
                          className="text-left  p-3 text-sm font-normal"
                        >
                          <div className="flex">Reason</div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {failedUsers?.map(
                        (user: ImportedUserResult, personIdx) => (
                          <tr
                            key={personIdx}
                            className={
                              personIdx % 2 === 0
                                ? " hover:bg-light-violet/20 transition ease-in-out duration-300 bg-white"
                                : "hover:bg-light-violet/20 transition ease-in-out duration-300 bg-light-violet/10"
                            }
                          >
                            <td className="p-3 text-sm text-gray-500">
                              {user.firstName}
                            </td>
                            <td className="p-3 text-sm text-gray-500">
                              {user.middleName}
                            </td>
                            <td className="p-3 text-sm text-gray-500">
                              {user.lastName}
                            </td>
                            <td className="p-3 text-sm text-gray-500">
                              {user.email}
                            </td>
                            <td className="p-3 text-sm text-gray-500">
                              {user.username}
                            </td>
                            <td className="p-3 text-sm text-gray-500">
                              {user.password}
                            </td>
                            {props.mode === Constant.ManageUserMode.Student && (
                              <>
                                <td className="p-3 text-sm text-gray-500">
                                  {user.studentId}
                                </td>
                                <td className="p-3 text-sm text-gray-500">
                                  {user.grade}
                                </td>
                              </>
                            )}
                            <td>{user.validationErrors}</td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          <button
            className="align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 rounded-lg text-sm text-gray-600 border-gray-300 border dark:text-gray-400 focus:outline-none active:bg-transparent hover:border-gray-500 focus:border-gray-500 active:text-gray-500 focus:shadow-outline-gray sm:w-auto"
            type="button"
            onClick={handleAddStudentsModalClose}
          >
            Cancel
          </button>
          <button
            className="align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 rounded-lg text-sm text-white border border-transparent bg-primary-green focus:shadow-outline-purple sm:w-auto"
            type="button"
            onClick={handlUploadStudent}
            disabled={isUploadDisabled}
          >
            {props.mode === Constant.ManageUserMode.Student && (
              <>Upload Students</>
            )}
            {props.mode === Constant.ManageUserMode.Teacher && (
              <>Upload Teachers</>
            )}
            {props.mode === Constant.ManageUserMode.District && (
              <>Upload District Users</>
            )}
          </button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};
const mapStateToProps = (state: any) => {
  return {
    masterData: state.masterData,
    userContext: state.userContext,
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(GroupSection);

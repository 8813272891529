import { useEffect, useState } from "react";

interface ITimerprops {
  isActive: boolean;
  isPaused: boolean;
  time: number;
  setSessionTimeTaken: (sessionTimeTaken: number) => void;
}

export default function Timer(props: ITimerprops) {
  const [time, setTime] = useState(props.time);
  useEffect(() => {
    props.setSessionTimeTaken(time);
  }, [time]);

  useEffect(() => {
    let interval: any = null;

    if (props.isActive && props.isPaused === false) {
      interval = setInterval(() => {
        setTime((time) => time + 1);
      }, 1000);
    } else {
      clearInterval(interval);
    }
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className="timer  text-xl mt-4 flex justify-end space-x-1 text-gray-600">
      <span className="digits flex items-center py-0.5 rounded-md inline-block">
        {("0" + Math.floor((time / 60) % 60)).slice(-2)}
      </span>
      <span>:</span>
      <span className="digits flex items-center  py-0.5 rounded-md inline-block">
        {("0" + Math.floor(time % 60)).slice(-2)}
      </span>
    </div>
  );
}

class Environments {
    public readonly Environment = {
        LOCAL: "LOCAL",
        DEV: "DEV",
        PRODUCTION: "PRODUCTION",
        STAGING: "STAGING",
        SANDBOX: "SANDBOX",
        QA: "QA",
    };
}

export default new Environments();
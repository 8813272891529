import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import AlienArenaSessionList from "./alienArenaSessionList";
import Permissions from "../../../../utils/permissions";
import AllowedTo from "../../../shared/rbac";
import AccessDenied from "../../../shared/accessDenied";
import Breadcrumb from "../../teacher/shared/breadcrumb";
import { IBreadcrumb } from "../../../../model/common/breadcrumb";
import RouteConstant from "../../../../utils/constant/routeConstant";
import { useTranslation } from "react-i18next";

function AlienArenaSessions(props: any) {
  const location = useLocation();
  const parameters = location.state as any;
  const { t } = useTranslation();

  const breadcrumbItems = () => {
    const items: Array<IBreadcrumb> = [
      { name: t("Breadcrumb.Home"), url: RouteConstant.ROUTE_DASHBOARD },
      {
        name: t("Breadcrumb.InstructionalResources"),
        url: "",
      },
      {
        name: t("Breadcrumb.Subjects"),
        url: RouteConstant.TeacherRoutes.InstructionalResourceSubjects,
      },
      {
        name: t("Breadcrumb.Resources"),
        url: RouteConstant.TeacherRoutes.InstructionalResources,
        stateObject: parameters?.selectedSubject,
      },
      { name: t("Breadcrumb.AlienArenaSessions"), url: `` },
    ];

    return items;
  };
  return (
    <AllowedTo
      perform={Permissions.alien_arena_sessions_view}
      no={() => <AccessDenied />}
    >
      <div className="min-w-0 w-full">
        <div className="px-6 py-5 lg:px-8 border-b border-gray-200">
          <Breadcrumb items={breadcrumbItems()} />
          <div className="text-lg flex justify-between gap-2 h-auto sm:h-10 items-center">
            <h1 className="text-primary-violet font-medium flex justify-start">
              Alien Arena Sessions
            </h1>
          </div>
        </div>
        <div className="px-2 sm:px-6 lg:px-8 py-6">
          <div>
            <div className="w-full block  sm:mx-0 lg:col-span-9 xl:col-span-12">
              <AlienArenaSessionList
                userId={props.userContext.userId}
                roleId={props.userContext.roleId}
              ></AlienArenaSessionList>
            </div>
          </div>
        </div>
      </div>
    </AllowedTo>
  );
}

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(AlienArenaSessions);

import {SetStateAction, useState} from "react";
import { connect } from "react-redux";
import { IBreadcrumb } from "../../../../../model/common/breadcrumb";
import routeConstant from "../../../../../utils/constant/routeConstant";
import Breadcrumb from "../../shared/breadcrumb";
import TeacherUsageReportFilters from "./teacherUsageReportFilters";
import { ISelectElement, ISelectSchoolElement } from "../../../../../model/interface/selectElement";
import constant from "../../../../../utils/constant/constant";
import Loader from "../../../../shared/loader";
import { getReportTeacherUsage, getReportteacherUsageExcel } from "../../../../../api/report/resultsReport";
import { toast } from "react-toastify";
import InfoMessage from "../../../../shared/infoMessage";
import { ITeacherUsageReport } from "../../../../../model/interface/report/teacherUsageReport";
import TeacherUsageReportTable from "./teacherUsageReportTable";
import { AxiosResponse } from "axios";
import {ISchoolYear} from "../../../../../model/teacher/schoolYear";
import Constant from "../../../../../utils/constant/constant";

interface TeacherUsageReportProps {
  profile: { 
    currentSchoolYear: ISchoolYear
  },
  userContext: { 
    schoolId: SetStateAction<number>,
    userId: number,
    roleId: number, 
    districtId: number, 
  },
  location: any
}

const allTeachers: ISelectElement = { label: "All Teachers", value: "0"};

function TeacherUsageReport(props: Readonly<TeacherUsageReportProps>) {
  const [selectedSchools, setSelectedSchools] = useState<ISelectSchoolElement[]>([]);
  const [selectedSubject, setSelectedSubject] = useState<Array<{label: string, value: number}>>([]);
  const [selectedTeacher, setSelectedTeacher] = useState<ISelectElement>(allTeachers);
  const [selectedStartDate, setSelectedStartDate] = useState<Date | null>(null);
  const [selectedEndDate, setSelectedEndDate] = useState<Date | null>(null);
  const [maxEndDate] = useState(new Date());
  const [reportData, setReportData] = useState<Array<ITeacherUsageReport>>();
  const [showReport, setShowReport] = useState<boolean>(false);
  const [showLoading, setShowLoading] = useState<boolean>(false);

  const isDistrictUser: boolean = props.userContext.roleId === constant.UserRoleId.District;

  const breadcrumbItems = () => {
    const items: Array<IBreadcrumb> = [
      { name: "Home", url: routeConstant.ROUTE_DASHBOARD },
      { name: "Reports", url: routeConstant.REPORTS },
      { name: "Teacher Usage Report", url: ""},
    ];
    return items;
  }

  const runReport = () => {
    setShowLoading(true);
    const selectedSubjectIds: number[] = selectedSubject.map(s => +s.value);
    const schoolIds = selectedSchools.map(school => school.value.schoolId);
    getReportTeacherUsage(
      props.userContext.districtId,
      schoolIds || [],
      +(selectedTeacher.value || 0) || undefined,
      selectedSubjectIds || [],
      selectedStartDate,
      selectedEndDate,
    )
    .then(response => {
      setShowLoading(false);
      console.log(response.data.data.data);
      setReportData(response.data.data.data);
      setShowReport(true);
    })
    .catch(error => {
      setShowLoading(false);
      toast.error(`${error.message || ''} Cannot load Report`);
    })
    setShowReport(true);
  }

  const exportReport = () => { 
    setShowLoading(true);
    const selectedSubjectIds: number[] = selectedSubject.map(s => +s.value);
    const schoolIds = selectedSchools.map(school => school.value.schoolId);
    getReportteacherUsageExcel(
      props.userContext.districtId,
      schoolIds || [],
      +(selectedTeacher.value || 0) || undefined,
      selectedSubjectIds || [],
      selectedStartDate,
      selectedEndDate,
    )
    .then(response => {
      setShowLoading(false);
      startFileDownload(response, "TeacherUsageReport.xlsx");
    })
    .catch(error => {
      setShowLoading(false);
      toast.error(
        "Export request failed. If the request takes a long time, consider adding filters to decrease the report size.",
        {autoClose: 0}
      );
    })
  }

  const startFileDownload = (
    response: AxiosResponse<any>,
    fileName: string,
  ) => {
    const file = response.data;
    const fileURL = URL.createObjectURL(file);
    const link = document.createElement("a");
    link.href = fileURL;
    link.download = fileName;
    link.setAttribute("target", "_blank");
    document.body.appendChild(link);
    link.click();
  }

  return (
    <div className="min-w-0 w-full">
      {showLoading && <Loader />}
      <div className="lg:flex lg:items-center px-4 py-3 lg:px-8 border-b">
        <Breadcrumb items={breadcrumbItems()} />
        <div className="lg:w-[55%] text-lg flex justify-between gap-2 items-end">
          <h1 className="text-primary-violet font-medium flex justify-start mb-0 pb-0">
            Teacher Usage Report
          </h1>
        </div>
      </div>
      <div className="px-2 sm:px-6 lg:px-8 py-1 relative my-4">
        <InfoMessage message={"The Teacher Usage Report pulls data based on the subjects associated with each class and any assessments or assignments created."} />
        <div className="bg-gray-100 w-full rounded-lg p-5">
          <TeacherUsageReportFilters 
            isDistrictUser={isDistrictUser} 
            selectedSchools={selectedSchools} 
            setSelectedSchools={setSelectedSchools}
            selectedSubject={selectedSubject}
            setSelectedSubject={setSelectedSubject}
            selectedTeacher={selectedTeacher}
            setSelectedTeacher={setSelectedTeacher}
            selectedStartDate={selectedStartDate}
            setSelectedStartDate={setSelectedStartDate}
            selectedEndDate={selectedEndDate}
            setSelectedEndDate={setSelectedEndDate}
            minStartDate={Constant.PLMinDate}
            maxEndDate={maxEndDate}
            runReport={runReport}
            exportReport={exportReport}
            showReport={showReport}
            setShowReport={setShowReport}
          />
          <div className="border-b border-gray-400 py-2 mb-2"></div>
          {showReport && (
            <TeacherUsageReportTable 
              reportData={reportData}
              isDistrictUser={isDistrictUser}
            />)
          }
        </div>
      </div>
    </div>
  )
}

export default connect((state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
})(TeacherUsageReport);

import classNames from "classnames";
import { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { getSkillStandard } from "../../../../api/student/skillPracticeApi";
import { ArrowRight, Info } from "../../../../assets/icons";
import Profile from "../../../../model/users/profile";
import { ISkillStandard } from "../../../../model/skillPractice/skillStandard";
import Loader from "../../../shared/loader";
import SkillReport from "./skillReport";

interface SkillStandardProps {
  skillSubjectId: number;
  profile?: Profile;
  onStandardSelected: (skillStandardId: number) => void;
}

const SkillStandard = (props: SkillStandardProps) => {
  const [skillStandards, setSkillStandards] = useState<Array<ISkillStandard>>(
    []
  );
  const [detailSkillStandardId, setDetailSkillStandardId] = useState<number>(0);
  const [showSkillReport, setShowSkillReport] = useState<boolean>(false);
  const [loading, showLoading] = useState<boolean>(false);
  const [selectedStandard, setSelectedStandard] = useState(0);

  useEffect(() => {
    if (props.skillSubjectId !== 0) {
      showLoading(true);
      getSkillStandard(props.skillSubjectId, 0, props.profile?.gradeId ?? 0)
        .then((d) => {
          setSkillStandards(d.data.data);
          showLoading(false);
        })
        .catch(() => {
          showLoading(false);
        });
    }
  }, [props.skillSubjectId]);

  useEffect(() => {
    props.onStandardSelected(detailSkillStandardId);
  }, [detailSkillStandardId]);

  return (
    <Fragment>
      <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 ml-5">
        {loading && <Loader />}

        {skillStandards.length === 0 && (
          <h2 className="text-gray-900 text-sm font-medium truncate">
            No record found.
          </h2>
        )}

        {skillStandards.length !== 0 &&
          skillStandards.map((standard) => (
            <li
              key={standard.skillStandardId}
              onClick={() => {
                setDetailSkillStandardId(standard.skillStandardId);
              }}
              className={classNames(
                "col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200 border",
                selectedStandard === standard.skillStandardId
                  ? "border-primary-violet"
                  : ""
              )}
            >
              <div className="w-full flex items-center justify-between p-6 space-x-6">
                <div className="flex-1 truncate">
                  <div className="flex items-center space-x-3">
                    <h3 className="text-gray-900 text-sm font-medium truncate">
                      {standard.name}
                    </h3>
                  </div>
                </div>
              </div>
              <div>
                <div className="-mt-px flex divide-x divide-gray-200">
                  <div className="w-0 flex-1 flex">
                    <span
                      onClick={() => {
                        setDetailSkillStandardId(standard.skillStandardId);
                        setShowSkillReport(true);
                      }}
                      className="cursor-pointer relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500"
                    >
                      <Info
                        className="w-5 h-5 text-gray-400"
                        aria-hidden="true"
                      />
                      <span className="ml-3">Detail</span>
                    </span>
                  </div>
                  <div className="-ml-px w-0 flex-1 flex">
                    <span
                      onClick={() => {
                        setSelectedStandard(standard.skillStandardId);
                      }}
                      className="cursor-pointer relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500"
                    >
                      <ArrowRight
                        className="w-5 h-5 text-gray-400"
                        aria-hidden="true"
                      />
                      <span className="ml-3">Start Studying</span>
                    </span>
                  </div>
                </div>
              </div>
            </li>
          ))}
      </ul>
      {showSkillReport && (
        <SkillReport
          skillStandardId={detailSkillStandardId}
          showPopUp={() => {
            setShowSkillReport(false);
          }}
        />
      )}
    </Fragment>
  );
};

const mapStateToProps = (state: any) => {
  return {
    profile: state.profile,
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(SkillStandard);

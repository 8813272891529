import { useEffect, useCallback, useRef } from "react";
import { connect } from "react-redux";
import { ILearnosityLiveMonitoringUser } from "../../../../model/report/LearnosityLiveMonitoringUser";
import { UserContextState } from "../../../../redux/actions/userContextAction";
import Profile from "../../../../model/users/profile";
import { getRequest } from "../../../../api/userActivity";

interface LearnosityLiveMonitoringProps {
  activityId: string;
  activityName: string;
  users: ILearnosityLiveMonitoringUser[];
  userContext: UserContextState;
  profile: Profile;
}
function LearnosityLiveMonitoring(props: LearnosityLiveMonitoringProps) {
  var reportsApp;
  const isInitialized = useRef(false);

  const initialization = useCallback(() => {
    if (isInitialized.current) return;
      
    isInitialized.current = true;
    var callbacks = {
      readyListener: function () {
        reportsApp
          ?.getReport("report-liveactivitystatus")
          .on("start", function (events) {
            document.onclick = function () {
              document
                ?.querySelectorAll("div.live-activitystatus-by-user")
                .forEach((item) => {
                  var y = item
                    ?.getElementsByClassName("modal-dialog")[0]
                    ?.getElementsByClassName("modal-content")[0]
                    ?.getElementsByClassName("modal-body")[0].children[1];
                  var x2 = y?.textContent?.replaceAll(
                    new RegExp("(.*?, .*?),", "g"),
                    "$1<br>"
                  );

                  if (x2) {
                    y.innerHTML = x2;
                  }
                });
            };
          });
      },
      errorListener: function (e) {
        // Adds a listener to all error codes.
        console.log("Error Code ", e.code);
        console.log("Error Message ", e.msg);
        console.log("Error Detail ", e.detail);
      },
    };
    const request = {
      reports: [
        {
          id: "report-liveactivitystatus",
          type: "live-activitystatus-by-user",
          activity: {
            id: props.activityId,
            name: props.activityName,
          },
          show_user_selectors: true,
          show_action_controls: true,
          // display_activity: true,
          users: props.users,
        },
      ],
    };

    getRequest(
      "reports",
      JSON.stringify(request),
      props.userContext.userId
    ).then(async (response) => {
      if (response.data) {
        reportsApp = window["LearnosityReports"]?.init(
          response.data,
          callbacks
        );
      }
    });
  }, []);

  useEffect(() => {
    initialization();
  }, [initialization]);
  return (
    <>
      <div id="report-liveactivitystatus"></div>
    </>
  );
}
const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
};

export default connect<{}, {}, {}>(
  mapStateToProps,
  {}
)(LearnosityLiveMonitoring);

import Select from "react-select";
import {
  DownArrowIcon,
  UpArrow,
} from "../../../../assets/icons/index";
import Constant from "../../../../utils/constant/constant";
import { IKeyValuePair, ISelectElement, ISelectElementCollapsable } from "../../../../model/interface/selectElement";
import { IAssessmentQuestion } from "../../../../model/interface/assessmentQuestion";
import NonPassageQuestionsDomainStandardsQuestions from "./nonPassageQuestionsDomainStandardsQuestions";


interface NonPassageQuestionsDomainStandardsProps {
  districtId: number,
  hasDistrictAssessmentQuestionIcon: boolean,
  questions: Array<IAssessmentQuestion>,
  displayDomains: Array<ISelectElement>,
  displayStandards: Array<ISelectElementCollapsable>,
  displayRows: Array<IKeyValuePair>,
  setDisplayRows: (displayRows: Array<IKeyValuePair>) => void,
  handleStandardCollapse: (standard: ISelectElementCollapsable) => void,
  getStandardQuestions: (standardId: string) => IAssessmentQuestion[],
  selectedQuestions: Array<IAssessmentQuestion>,
  updateQuestion: (question: IAssessmentQuestion) => void,
  openQuestionPreview: (
    learnosityReferenceId: any,
    questionId: any,
    questionText: any,
    standardId: any,
    spanishLearnosityReferenceId: any
  ) => void,
  favoriteQuestionsIds: Array<number>,
  selectedSortOption: Array<{ domainId: number; selectedValue: ISelectElement }>,
  handleQuestionsSortBy: (e: ISelectElement, domainIdToSort: string) => void,
  handleFavoriteQuestion: (questionId: number) => void,
  handleFeedbackPopup: (id: number, standardId: number) => void,
}

const questionSortOptions = [
  { value: "2", label: "Newest" },
  { value: "3", label: "Low DOK" },
  { value: "4", label: "High DOK" },
  { value: "6", label: "Favorites" },
];

function NonPassageQuestionsDomainStandards(props: NonPassageQuestionsDomainStandardsProps) {
  const {
    districtId,
    hasDistrictAssessmentQuestionIcon,
    questions,
    displayDomains,
    displayStandards,
    displayRows,
    setDisplayRows,
    handleStandardCollapse,
    getStandardQuestions,
    selectedQuestions,
    updateQuestion,
    openQuestionPreview,
    favoriteQuestionsIds,
    selectedSortOption,
    handleQuestionsSortBy,
    handleFavoriteQuestion,
    handleFeedbackPopup,
  } = props;

  const getRowsCount = (standardId: string) => {
    const rowsCount: number = displayRows?.length > 0
      ? parseInt(displayRows.find(r => r.key === standardId.toString())?.value ?? "10") 
      : 10
    return rowsCount;
  }

  const handleSelectedQuestion = (question: IAssessmentQuestion) => {
    question.assessmentQuestionTypeId = Constant.AssessmentQuestionType.NonPassageQuestions;
    updateQuestion(question);
  }

  const canLoadMoreQuestions = (standardId: string) => {
    const standardQuestionCount: number = questions.filter(question => question.standardId === standardId).length
    const canLoadMoreQuestions: boolean = standardQuestionCount < 10
      ? false
      : !(parseInt(displayRows.find(rows => rows.key.toString() === standardId.toString())?.value ?? "0") >= standardQuestionCount)

    return canLoadMoreQuestions;
  }

  const handleLoadMoreQuestions = (standardId: string) => {
    if (displayRows?.length > 0) {
      const updatedDisplayRows: Array<IKeyValuePair> = displayRows.map(rowSetting => ({
        key: rowSetting.key.toString(),
        value: rowSetting.key.toString() === standardId.toString() // for some reason these values (rowSetting.key and standardId) do not respect their defined types that's why both of them have .toString()
          ? (+rowSetting.value + 10).toString()
          : rowSetting.value
      }));
      setDisplayRows(updatedDisplayRows);
    } else {
      setDisplayRows([]);
    }
  }

  return (
    <div>
      {displayDomains.map((domain, index) => (
        <div className="mb-2" key={index}>
          <div className="flex flex-col sm:flex-row justify-between bg-gray-100 mb-3 py-2 px-3">
            <div className="flex items-center space-x-2">
              <div className="mb-2 sm:mb-0 text-gray-500 font-bold">
                {domain.label}
              </div>
            </div>
            <div className="justify-end w-full sm:w-1/2 md:w-1/4 lg:w-1/4 xl:w-1/4">
              <Select
                value={selectedSortOption.find(r => r.domainId.toString() === domain.value)?.selectedValue}
                placeholder={"Sort By"}
                options={questionSortOptions}
                onChange={(e) => handleQuestionsSortBy(e as ISelectElement, domain.value)}
              />
            </div>
          </div>
          {displayStandards
            .filter(standard => questions.some(question => question.standardId === standard.value && question.domainId == domain.value))
            .map((standard, index) => (
              <div>
                <div  key={index} className={`${standard.isCollapsed ? "border-2 border-secondary-teal" : ""} p-2 mb-2`}>
                  <div className="text-secondary-teal font-bold text-lg flex items-center">
                    <span className="flex-grow">
                      {standard.label}
                    </span>
                    {standard.isCollapsed && (
                      <span title="Expand to view Questions">
                        <DownArrowIcon
                          className="w-5 h-5 stroke-current text-gray-500 hover:text-gray-700 cursor-pointer"
                          onClick={() => handleStandardCollapse(standard)}
                        />
                      </span>
                    )}
                    {!standard.isCollapsed && (
                      <span title="Collapse Questions">
                        <UpArrow
                          className="w-5 h-5 stroke-current text-gray-500 hover:text-gray-700 cursor-pointer"
                          onClick={() => handleStandardCollapse(standard)}
                        />
                      </span>
                    )}
                  </div>
                  {standard.isCollapsed && (
                    <a
                      className="cursor-pointer text-blue-500 text-sm font-medium block underline  hover:no-underline -mt-1 relative"
                      onClick={() => handleStandardCollapse(standard)}
                    >
                      Expand to view Questions
                    </a>
                  )}
                </div>
                {!standard.isCollapsed && (
                  <NonPassageQuestionsDomainStandardsQuestions 
                    districtId={districtId}
                    hasDistrictAssessmentQuestionIcon={hasDistrictAssessmentQuestionIcon}
                    standard={standard}
                    selectedQuestions={selectedQuestions}
                    getStandardQuestions={getStandardQuestions}
                    getRowsCount={getRowsCount}
                    handleSelectedQuestion={handleSelectedQuestion}
                    favoriteQuestionsIds={favoriteQuestionsIds}
                    handleStandardCollapse={handleStandardCollapse}
                    handleFavoriteQuestion={handleFavoriteQuestion}
                    handleFeedbackPopup={handleFeedbackPopup}
                    canLoadMoreQuestions={canLoadMoreQuestions}
                    handleLoadMoreQuestions={handleLoadMoreQuestions}
                    openQuestionPreview={openQuestionPreview}
                  />
                )}
              </div>
            ))
          }
        </div>
      ))}
    </div>
  )
}

export default NonPassageQuestionsDomainStandards

import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { fetchData } from "../../api/adminListApi";
import AdminListModel from "../../model/teacher/adminList";
import Constant from "../../utils/constant/constant";
import Header from "../shared/header/header";
import Loader from "../shared/loader";
import Pagination from "../shared/pagination";

function AdminList(props: any) {
  const defaultReportData: Array<AdminListModel> = [];
  const pageSize = 10;
  const [showLoader, setLoader] = useState(false);
  const [currentPageNumber, setPageNumber] = useState(1);
  const [reportData, setReportData] = useState(defaultReportData);

  useEffect(() => {
    showReport();
  }, [currentPageNumber]);

  function bindData(data: any) {
    setLoader(false);
    setReportData(data);
  }
  function handlePageChange(e: any) {
    setPageNumber(e);
  }

  function showReport() {
    setLoader(true);
    const schoolId = props.userContext.schoolId;
    const userId = props.userContext.userId;

    fetchData(
      Constant.Report.ADMINLIST,
      userId,
      schoolId,
      currentPageNumber,
      pageSize
    )
      ?.then((r) => bindData(r.data))
      .catch((err) => {
        setLoader(false);
      });
  }
  const totalResults =
    reportData && reportData.length > 0 ? reportData[0].totalCount : 0;
  return (
    <Fragment>
      <Header></Header>
      <div className="px-4 lg:px-8 mt-10">
        <h2 className="text-2xl text-primary-green font-semibold">
          Admin List
        </h2>
      </div>
      <div className="px-4 lg:px-8 mt-3">
        <div className="flex flex-col relative">
          {showLoader && <Loader></Loader>}
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className="shadow overflow-hidden sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-primary-green bg-opacity-75">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-1 text-left text-xs font-medium text-gray-700 uppercase tracking-wider"
                      >
                        School
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-1 text-left text-xs font-medium text-gray-700 uppercase tracking-wider"
                      >
                        First Name
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-1 text-center text-xs font-medium text-gray-700 uppercase tracking-wider"
                      >
                        Last Name
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-1 text-center text-xs font-medium text-gray-700 uppercase tracking-wider"
                      >
                        Username
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-1 text-center text-xs font-medium text-gray-700 uppercase tracking-wider"
                      >
                        Email
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-1 text-center text-xs font-medium text-gray-700 uppercase tracking-wider"
                      >
                        Role
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-1 text-center text-xs font-medium text-gray-700 uppercase tracking-wider"
                      >
                        Start Date
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-1 text-center text-xs font-medium text-gray-700 uppercase tracking-wider"
                      >
                        Last Login
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-1 text-center text-xs font-medium text-gray-700 uppercase tracking-wider"
                      >
                        Expiry Date
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {reportData.map((report, personIdx) => (
                      <tr
                        className={
                          personIdx % 2 === 0
                            ? "bg-white"
                            : "bg-primary-green bg-opacity-10"
                        }
                        key={personIdx}
                      >
                        <td className="px-6 py-1 whitespace-nowrap text-xs text-gray-500">
                          {report.school}
                        </td>
                        <td className="px-6 py-1 whitespace-nowrap text-xs text-gray-500">
                          {report.firstName}
                        </td>
                        <td className="px-6 py-1 whitespace-nowrap text-xs text-gray-500 text-center">
                          {report.lastName}
                        </td>
                        <td className="px-6 py-1 whitespace-nowrap text-xs text-gray-500 text-center">
                          {report.username}
                        </td>
                        <td className="px-6 py-1 whitespace-nowrap text-xs text-gray-500 text-center">
                          {report.email}
                        </td>
                        <td className="px-6 py-1 whitespace-nowrap text-xs text-gray-500 text-center">
                          {report.role}
                        </td>
                        <td className="px-6 py-1 whitespace-nowrap text-xs text-gray-500 text-center">
                          {report.startDate === null
                            ? ""
                            : moment(report.startDate).format("MM/DD/YYYY")}
                        </td>
                        <td className="px-6 py-1 whitespace-nowrap text-xs text-gray-500 text-center">
                          {report.lastLogin === null
                            ? ""
                            : moment(report.lastLogin).format("MM/DD/YYYY")}
                        </td>
                        <td className="px-6 py-1 whitespace-nowrap text-xs text-gray-500 text-center">
                          {report.expiryDate === null
                            ? ""
                            : moment(report.expiryDate).format("MM/DD/YYYY")}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
          <Pagination
            totalResults={totalResults}
            resultsPerPage={pageSize}
            onChange={(e) => {
              handlePageChange(e);
            }}
            label=""
          />
        </div>
      </div>
    </Fragment>
  );
}

const mapStateToProps = (state: any) => {
  return {
    masterData: state.masterData,
    userContext: state.userContext,
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(AdminList);

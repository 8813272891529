import React from "react";
import { XIcon } from "@heroicons/react/solid";
import { useTranslation } from "react-i18next";
import { Configuration } from "../../../../../environment/setup";

export interface GalaxyStarPopupProps {
  rewards?: any;
  showPopup: (show: boolean) => void;
}

const GalaxyStarPopup: React.FC<GalaxyStarPopupProps> = (props) => {
  const { t } = useTranslation();
  return (
    <>
      <section>
        <div className="fixed inset-0 z-50 flex items-center bg-black bg-opacity-50 sm:items-center sm:justify-center enter-done">
          <div className="w-full flex flex-col justify-between  overflow-hidden  h-auto max-h-80percent bg-white rounded-lg dark:bg-gray-800 sm:m-4 sm:max-w-xl">
            <header className="flex justify-end p-4">
              <XIcon
                className="cursor-pointer h-4 w-4"
                onClick={() => props.showPopup(false)}
              ></XIcon>
            </header>
            <div className="w-full relative overflow-auto px-4">
              <p className="text-lg font-semibold text-gray-700 dark:text-gray-300 rounded-2xl overflow-auto">
                <img
                  className="w-full"
                  src={`${Configuration.S3bucketImagePath}/images/stars-count.svg`}
                  alt=""
                />
              </p>
              <div className="text-sm text-gray-700 dark:text-gray-400 text-center -top-8 z-10 relative">
                <h2 className="text-yellow-500 xl:text-6xl 2xl:text-8xl font-semibold shadow-xl border-2 border-yellow-500 p-3 rounded-2xl mx-2 bg-yellow-50 inline-block">
                  {props?.rewards?.plStarReward && (
                    <p>{`${props?.rewards?.plStarReward?.earnedStar}`} </p>
                  )}
                </h2>
                <p className="font-Didact xl:text-3xl mt-8">
                  {t("GalaxyStars.OutOf")}
                  <span className="text-yellow-600 shadow-md p-2 rounded mx-2 bg-yellow-100">
                    {props?.rewards?.plStarReward &&
                      props?.rewards?.plStarReward?.totalStar}
                  </span>
                  {t("GalaxyStars.GalaxyStars")}
                </p>
              </div>
            </div>
            <footer className="flex bg-gray-50 flex-col items-center justify-end px-6 py-4 sm:flex-row">
              <button
                className="align-bottom inline-flex font-bold items-center justify-center cursor-pointer leading-5 transition-colors duration-150  focus:outline-none px-5 py-3 rounded-lg text-lg text-white bg-primary-violet hover:shadow-lg hover:bg-dark-violet"
                type="button"
                onClick={() => {
                  props.showPopup(false);
                }}
              >
                OK
              </button>
            </footer>
          </div>
        </div>
      </section>
    </>
  );
};

export default GalaxyStarPopup;

interface SharedByIconProps {
  byTeacher?: boolean,
}

function SharedByIcon(props: SharedByIconProps) {
    return (
        <div
          className={"pointer-events-auto inline-flex items-baseline px-2 py-0 rounded-full border-solid border-2 border-paua text-xs font-medium lg:mt-0" +
                (props.byTeacher ? " text-white bg-paua": " text-paua bg-transparent")}
        >
          S
        </div>
    )
}

export default SharedByIcon
import Select from "react-select";
import QuestionTypeByStandardSelect from "../shared/questionTypeByStandardSelect"
import { ISelectElement, ISelectElementCollapsable } from "../../../../model/interface/selectElement";
import { useEffect, useState } from "react";
import {
  getAllDomainsBySubject, 
  getAllStandardByDomains,
} from "../../../../api/teacher/assignment";
import { IAssessmentQuestion } from "../../../../model/interface/assessmentQuestion";
import DomainStandard from "../../../../model/teacher/domainStandard";
import TagsInput from "../shared/tagsInput";

interface INonPassageQuestionsFiltersProps {
  subjectId: number,
  selectedQuestions: Array<IAssessmentQuestion>,
  selectedStandards: Array<ISelectElementCollapsable>,
  setSelectedStandards: (selectedStandards: Array<ISelectElementCollapsable>) => void,
  setDisplayStandards: (displayStandards: Array<ISelectElementCollapsable>) => void,
  searchQuestions: (
    selectedStandards: Array<ISelectElement>,
    questionCategoryIds?: Array<number>
  ) => void,
  setQuestions: (questions: Array<IAssessmentQuestion>) => void,
  selectedDomains: Array<ISelectElement>,
  setSelectedDomains: (selectedDomains: Array<ISelectElement>) => void,
  setDomainStandards: (domainStandards: Array<DomainStandard>) => void,
  updateStandadardsAndQuestions: (groupedStandards: any[]) => void,
  bindFavoriteQuestions: () => void,
  selectedQuestionTypes: Array<ISelectElement>,
  setSelectedQuestionCategoryIds: (selectedQuestionCategoryIds: Array<number>) => void,
  setSelectedQuestionTypes: (selectedQuestionTypes: Array<ISelectElement>) => void,
  keywordTags: Array<string>,
  setKeywordTags: (tags: Array<string>) => void,
  matchAnyTag: boolean,
  setMatchAnyTag: (any: boolean) => void,
  setShowLoading: (value: boolean) => void,
}

function NonPassageQuestionsFilters(props: INonPassageQuestionsFiltersProps) {
  const {
    subjectId,
    selectedQuestions,
    selectedStandards,
    setSelectedStandards,
    setDisplayStandards,
    searchQuestions,
    setQuestions,
    selectedDomains,
    setSelectedDomains,
    setDomainStandards,
    updateStandadardsAndQuestions,
    bindFavoriteQuestions,
    setSelectedQuestionCategoryIds,
    selectedQuestionTypes,
    setSelectedQuestionTypes,
    keywordTags,
    setKeywordTags,
    matchAnyTag,
    setMatchAnyTag,
    setShowLoading,
  } = props;

  const [domains, setDomains] = useState<Array<ISelectElement>>([]);
  const [standardsGroups, setStandardsGroups] = useState<Array<any>>([]);

  const agroupStandards = (domainStandards: any[]) => {
    const groupedStandards: Array<{label: string, options: Array<ISelectElementCollapsable>}> = domainStandards.map((domainStandard: any) => ({
      label: domainStandard.domainName,
      options: domainStandard.standards.map((standard: any) => ({
        label: `${standard.code} ${standard.name}`,
        value: standard.id,
        isCollapsed: true,
        isFirst: false,
      })),
    }));

    return groupedStandards;
  }

  const updateSelectedStandards = (groupedStandards: any[]) => {
    const currentStandards: Array<number> = groupedStandards
      .map((standard: any) => (standard.options.map((option: any) => +option.value)))
      .flat();

    const filteredStandard: Array<ISelectElementCollapsable> = selectedStandards
      .filter(standard => currentStandards.includes(+standard.value));
    setSelectedStandards(filteredStandard);
    setDisplayStandards(filteredStandard);
    searchQuestions(filteredStandard);
  }

  const bindStandards = (
    selectedDomains: string,
    setExistingStandards: boolean = false
  ) => {
    setShowLoading(true);
    getAllStandardByDomains(selectedDomains).then(response => {
      const domainStandards = response.data.data;
      setDomainStandards(domainStandards);

      const groupedStandards = agroupStandards(domainStandards);
      setStandardsGroups(groupedStandards);

      if (!setExistingStandards && selectedStandards.length > 0)
        updateSelectedStandards(groupedStandards);

      if (setExistingStandards && selectedQuestions?.length > 0)
        updateStandadardsAndQuestions(groupedStandards);
      
      setShowLoading(false);
    });
  }

  const getDomains = () => {
    setShowLoading(true);
    getAllDomainsBySubject(subjectId).then(res => {
      const domainsData: Array<ISelectElement> = res.data.data.map((value: any) => ({ 
          label: value.name, 
          value: value.domainId 
      }));
      setDomains(domainsData);

      if (selectedQuestions?.length > 0) {
        const domainIdSet: Set<number> = new Set(selectedQuestions.map(question => parseInt(question.domainId ?? "0")));
        const domainIds: Array<number> = Array.from(domainIdSet);
        const existingDomains: Array<ISelectElement> = domainsData.filter(domain => domainIds.includes(+domain.value));
        setSelectedDomains(existingDomains);
        bindStandards(domainIds.join(","), true);
      }
      setShowLoading(false);
    });
  }

  const handleDomainChange = (selectdItems: any) => {
    setSelectedDomains(selectdItems);
    const selectedDomainsIds = selectdItems.map((item: any) => item.value);
    bindStandards(selectedDomainsIds.join(","));
  }

  const handleStandardChange = (selectdItems: any) => {
    setSelectedStandards(selectdItems);
  }

  const handleQuestionTypeChange = (e: any) => {
    const questionTypeIds = e.map((q: any) => q.value);
    setSelectedQuestionCategoryIds(questionTypeIds);
    setSelectedQuestionTypes(e);
  }

  const handleSearch = () => {
    if (selectedStandards.length === 0 && keywordTags.length === 0) {
      setDisplayStandards([]);
      setQuestions([]);
      return;
    }
    searchQuestions(selectedStandards);
  }

  useEffect(() => {
    if (subjectId > 0) {
      getDomains();
    }
    bindFavoriteQuestions();
  }, [subjectId]);

  useEffect(() => {
    handleSearch();
  }, [selectedDomains, selectedStandards, selectedQuestionTypes])

  return (
    <div>
      <div className="flex flex-col sm:flex-row">
        <div className="grid grid-cols-2 sm:grid-cols-8 lg:grid-cols-8 xl:grid-cols-12 gap-6 pb-4 w-full">
          <div className="col-span-8 sm:col-span-4 xl:col-span-3 3xl:col-span-2">
            <label className="block text-sm text-gray-500">
              Select Domain(s)
            </label>
            <div className="mt-1">
              <Select
                value={selectedDomains}
                options={domains}
                isMulti={true}
                onChange={handleDomainChange}
                closeMenuOnSelect={false}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: "#008fbb",
                  },
                })}
              />
            </div>
          </div>
          <div className="col-span-8 sm:col-span-4 xl:col-span-3 3xl:col-span-2">
            <label className="block text-sm text-gray-500">
              Select Standard(s)
            </label>
            <div className="mt-1">
              <Select
                value={selectedStandards}
                options={standardsGroups}
                isMulti={true}
                onChange={handleStandardChange}
                closeMenuOnSelect={false}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: "#008fbb",
                  },
                })}
              />
            </div>
          </div>

          <div className="col-span-8 sm:col-span-4 xl:col-span-3 3xl:col-span-2">
            <label className="block text-sm text-gray-500">
              Question Type
            </label>
            <div className="mt-1">
              <QuestionTypeByStandardSelect
                onChange={handleQuestionTypeChange}
                value={selectedQuestionTypes}
                standardIds={selectedStandards
                  .map((s) => s.value)
                  .join(",")}
                includeConstructedReponseTypes={true}
              ></QuestionTypeByStandardSelect>
            </div>
          </div>

          <div className="col-span-8 sm:col-span-4 xl:col-span-3 3xl:col-span-2">
            <label className="block text-sm text-gray-500">
              Keyword Search
            </label>
            <div className="mt-1">
              <TagsInput 
                tags={keywordTags}
                setTags={setKeywordTags}
                matchAny={matchAnyTag}
                setMatchAny={setMatchAnyTag}
                instructions="To search multiple, press Enter after each keyword or phrase followed by Search"
                placeholder="Enter keyword or phrase"
                tagLengthLimit={40}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-end">
        <button
          onClick={handleSearch}
          disabled={!keywordTags.length && !selectedStandards.length}
          className={"my-auto bg-secondary-teal border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white whitespace-nowrap "
            + (keywordTags.length || selectedStandards.length ? "focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 hover:bg-dark-teal hover:shadow-lg " : "")
            + (!keywordTags.length && !selectedStandards.length ? "bg-opacity-20 text-opacity-40 cursor-not-allowed" : "")}
        >
          Search
        </button>
      </div>
    </div>
  )
}

export default NonPassageQuestionsFilters

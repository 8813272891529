import { useEffect, useState } from "react";
import { connect } from "react-redux";
import UsatpArcadeContainer from "./usatpArcadeContainer";
import moment from "moment/moment";
import ConfirmationDialog from "../../shared/confirmationDialog";
import { Configuration } from "../../../environment/setup";
import { IArcadeGames } from "../../../model/interface/arcadeGame";
import {
  addTokenTransaction,
  getArcadeGames,
  getTotalTokens,
  updateArcadeGameSession,
} from "../../../api/student/arcadeGames";
import GameSummary from "./gameSummary";
import constant from "../../../utils/constant/constant";
import { toast } from "react-toastify";
import { ResultCoinIcon } from "../../../assets/icons";
import { IBreadcrumb } from "../../../model/common/breadcrumb";
import { useTranslation } from "react-i18next";
import routeConstant from "../../../utils/constant/routeConstant";
import Breadcrumb from "../student/middleSchool/breadcrumb";
import Profile from "../../../model/users/profile";
import { useHistory, useLocation } from "react-router-dom";
import { isGameLocked } from "../../../utils/studentHelper";
interface GameSelectionProps {
  profile: Profile;
}
function GameSelection(props: GameSelectionProps) {
  // TODO: handle arcade lock
  const defaultGame: IArcadeGames = {
    arcadeGameId: 0,
    costInTokens: 0,
  };
  const defaultResult = {
    gameName: "",
    score: 0,
    tokensSpent: 0,
  };
  const [selectedGame, setSelectedGame] = useState<IArcadeGames>(defaultGame);
  const [gameResults, setGameResults] = useState(defaultResult);
  const [gameFinished, setGameFinished] = useState<boolean>(false);
  const [gameStartedTime, setGameStartedTime] = useState<string>("");
  const [gameEndedTime, setGameEndedTime] = useState<string>("");
  const [elapsedTime, setElapsedTime] = useState<string>("");
  const [openConfirmation, setOpenConfirmation] = useState<boolean>(false);
  //const [tokenCost, setTokenCost] = useState<number>(0);
  const [arcadeGames, setArcadeGames] = useState<Array<IArcadeGames>>();
  const [totalTokens, setTotalTokens] = useState<number>();
  const [studentTokenTransactionId, setStudentTokenTransactionId] =
    useState<number>();
  const [showSummary, setShowSummary] = useState<boolean>(false);
  const { t } = useTranslation();
  const history = useHistory();
  const { state } = useLocation();

  useEffect(() => {
    checkGameLock();
    if (!gameFinished) {
      window.addEventListener("message", receiveFinishMessage);
    }
    if (arcadeGames === undefined) {
      getArcadeGames().then((res) => {
        if (res) {
          setArcadeGames(res.data);
        }
      });
    }
    if (!totalTokens) {
      refreshBalance();
    }
  }, []);
  useEffect(() => {
    if (gameFinished) {
      const now = moment(Date.now()).format("MM/DD/YYYY hh:mm:ss A");
      setGameEndedTime(now);
      setElapsedTime(
        moment
          .utc(
            moment
              .duration(moment(now).diff(gameStartedTime))
              .as("milliseconds")
          )
          .format("HH:mm:ss")
      );
      updateGameScore();
      setShowSummary(true);
    } else {
      if (totalTokens) {
        setTotalTokens(totalTokens - selectedGame.costInTokens);
      }
      setShowSummary(false);
      setSelectedGame(defaultGame);
    }
  }, [gameFinished]);

  const checkGameLock = () => {
    const isLocked = isGameLocked(
      props.profile.isGameLocked,
      props.profile.gameLockStartTime,
      props.profile.gameLockEndTime
    );
    if (isLocked) {
      window.location.replace(routeConstant.FORBIDDEN);
    }
  };

  const refreshBalance = () => {
    getTotalTokens().then((res) => {
      setTotalTokens(res.data);
    });
  };

  const receiveFinishMessage = (e) => {
    if (e.data.score !== undefined) {
      setGameResults({ ...gameResults, score: e.data.score });
      setGameFinished(true);
    } else {
      setGameFinished(false);
    }
  };
  const confirmationCancelClose = () => {
    setSelectedGame(defaultGame);
    setOpenConfirmation(false);
  };

  const confirmationOkClose = () => {
    // token api call
    addTokenTransactionDetails();
    setOpenConfirmation(false);
    history.push({
      state: { gameStarted : true }
    })
  };

  const addTokenTransactionDetails = () => {
    const tokenCost = selectedGame?.costInTokens ?? 0;
    addTokenTransaction(
      constant.TokenTransactionTypes.StudentArcadeGame,
      tokenCost,
      selectedGame?.arcadeGameId
    ).then((res) => {
      if (res?.data) {
        if (res.data.isSuccess) {
          setGameStartedTime(
            moment(Date.now()).format("MM/DD/YYYY hh:mm:ss A")
          );
          setStudentTokenTransactionId(res.data.data);
        } else {
          switch (res.data.code) {
            case 1:
              toast.error("You do not have enough tokens");
              refreshBalance();
              break;
            default:
              toast.error("Unable to start the game");
              break;
          }
          setSelectedGame(defaultGame);
        }
      }
    });
  };

  const updateGameScore = () => {
    if (studentTokenTransactionId && gameResults.score) {
      updateArcadeGameSession(
        studentTokenTransactionId,
        selectedGame?.arcadeGameId,
        gameResults.score
      ).then((res) => {});
    }
  };

  const breadcrumbItems = () => {
    const items: Array<IBreadcrumb> = [
      {
        name: t("Breadcrumb.Home"),
        url: routeConstant.MiddleSchool.assignments,
        textColor: "text-white",
      },
      {
        name: t("Breadcrumb.GameArcade"),
        textColor: "text-white",
        url: "",
      },
    ];

    return items;
  };

  useEffect(() => {
    if (state === undefined) {
      setSelectedGame(defaultGame);
      setGameFinished(false);
      setOpenConfirmation(false);
      setShowSummary(false);
    }
  }, [state]);

  return (
    <>
      <div className="md:flex md:items-center px-4 py-3 lg:px-8 border-b">
        <Breadcrumb items={breadcrumbItems()} />
        <div className="md:w-[55%] text-lg flex justify-between gap-2 h-auto sm:h-10 items-center">
          <h1 className="text-gray-50 font-medium flex justify-start">
            {t("Breadcrumb.GameArcade")}
          </h1>
        </div>
      </div>
      <div className="px-4 py-4 lg:px-8">
        <div className="flex items-center gap-x-8 w-full">
          <div className="bg-[#fb923c] pl-6 pr-2 py-1.5 rounded-lg inline-block relative ml-4">
            <span className="bg-white bg-opacity-70 absolute -left-5 top-[-4px]  inline-block h-12 w-12 rounded-full flex items-center justify-center box-shadow-xl">
              <ResultCoinIcon className=" w-10 " />
            </span>
            <span className="text-white bg-black bg-opacity-40 px-2 py-0.5 ml-3 rounded-xl text-lg font-medium">
              <span className="text-white font-bold pr-2">{totalTokens}</span>
              {t("Arcade.Tokens")}
            </span>
          </div>
          <div>
            <button
              onClick={() => {
                history.push(routeConstant.LEADERBOARDMS);
              }}
              className="bg-blues hover:opacity-90 inline-block whitespace-nowrap rounded-md text-base text-center py-2 px-4 text-white flex items-center justify-center"
            >
              {t("Shared.StudentSideBar.Leaderboard")}
            </button>
          </div>
        </div>
        {selectedGame.arcadeGameId === 0 &&
          !openConfirmation &&
          !gameFinished && (
            <div className="flex flex-col md:flex-row flex-wrap gap-4 mt-8">
              {arcadeGames &&
                arcadeGames?.map((game) => {
                  var isAllowed =
                    (totalTokens ?? 0) > 0 &&
                    game.costInTokens <= (totalTokens ?? 0);
                  return (
                    <div
                      className="card w-40 bg-white rounded-lg cursor-pointer"
                      key={game.arcadeGameId}
                    >
                      <ul
                        className={
                          "list-group" +
                          (isAllowed ? "" : " cursor-not-allowed")
                        }
                        onClick={() => {
                          if (isAllowed) {
                            setSelectedGame(game);
                            setGameResults({
                              gameName: game?.name ?? "",
                              score: 0,
                              tokensSpent: game?.costInTokens ?? 0,
                            });
                            setOpenConfirmation(true);
                          } else {
                            toast.info(
                              "You don’t have enough tokens to play this game"
                            );
                          }
                        }}
                      >
                        <li className="flex list-group-item p-2 justify-center">
                          {game.name}
                        </li>
                        <li
                          className={"flex list-group-item p-2 justify-center"}
                        >
                          <img
                            key={game.name}
                            className="w-28 h-28 border-black rounded-lg"
                            src={`${Configuration.S3bucketImagePath}${game.imageurl}`}
                          />
                        </li>
                        <li className="flex list-group-item p-2 rounded-lg bg-gray-700 m-4 text-white justify-center">
                          <span
                            className="text-lg font-bold"
                            style={{ padding: "3px 0 0 0" }}
                          >
                            {game.costInTokens}
                          </span>
                          <img
                            className="pl-2"
                            src={`${Configuration.S3bucketImagePath}/usatp_arcade/image/token_angled.png`}
                          />
                        </li>
                      </ul>
                    </div>
                  );
                })}
            </div>
          )}
      </div>
      {selectedGame.arcadeGameId !== 0 &&
        !openConfirmation &&
        !gameFinished && (
          <div className="ml-8" style={{ width: "70vw", height: "85vh" }}>
            {/*this should be updated to be a full screen modal with the background grayed out*/}
            <UsatpArcadeContainer
              indexUrl={
                Configuration.S3bucketImagePath + "" + selectedGame?.gameurl
              }
            />
          </div>
        )}

      {showSummary && (
        <GameSummary
          key={selectedGame.arcadeGameId}
          profile={props.profile}
          finalScore={gameResults.score}
          tokensSpent={selectedGame.costInTokens}
          gameName={selectedGame.name ?? ""}
          dateStarted={gameStartedTime}
          dateCompleted={gameEndedTime}
          elapsedTime={elapsedTime}
          setGameFinished={setGameFinished}
        ></GameSummary>
      )}

      <ConfirmationDialog
        open={openConfirmation}
        confirmationTitle="Please confirm"
        confirmationMessage={`Are you sure you want to use ${selectedGame?.costInTokens} tokens to play ${selectedGame?.name}?`}
        onCancelClick={confirmationCancelClose}
        onOkClick={confirmationOkClose}
        className="fixed z-50 inset-0 overflow-y-auto"
      ></ConfirmationDialog>
    </>
  );
}
const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
};
export default connect<{}, {}, {}>(mapStateToProps, {})(GameSelection);

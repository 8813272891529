import { connect } from "react-redux";
import constant from "../../../../../utils/constant/constant";
import moment from "moment";
import ProgressReportHeaderPrint from "./progressReportHeaderPrint";

function ProgressReportFilterPrint(props: any) {
  return (
    <div id="progress-report-filter-print" className="w-full p-5 ">
      <ProgressReportHeaderPrint />
      <div className="border rounded-lg p-5">
        <div className="flex flex-col 1xl:flex-row">
          <div className="w-full 1xl:w-3/4">
            <div className="grid grid-cols-3 gap-4 mb-4">
              {props.userContext.roleId !== constant.UserRoleId.Student && (
                <div className="flex flex-col">
                  <label className="block mb-1 text-sm text-gray-700">
                    Subject
                  </label>
                  <label className="block mb-1 text-sm text-gray-500">
                    {props.filter?.subjectName}
                  </label>
                </div>
              )}
              {props.userContext.roleId === constant.UserRoleId.District &&
                props.filter?.schoolName !== undefined && (
                  <div className="flex flex-col">
                    <div className="flex gap-2">
                      <label className="block mb-1 text-sm text-gray-700">
                        School
                      </label>
                      <label className="block mb-1 text-sm text-gray-500">
                        {props.filter?.schoolName}
                      </label>
                    </div>
                  </div>
                )}
              {props.userContext.roleId !== constant.UserRoleId.Student &&
                props.filter?.classes.startsWith("All") === false && (
                  <div className="flex flex-col">
                    <label className="block mb-1 text-sm text-gray-700">
                      Class
                    </label>
                    <label className="block mb-1 text-sm text-gray-500">
                      {props.filter?.classes}
                    </label>
                  </div>
                )}
              {props.userContext.roleId !== constant.UserRoleId.Student &&
                props.filter?.assessmentTag.startsWith("All") === false && (
                  <div className="flex flex-col">
                    <label className="block mb-1 text-sm text-gray-700">
                      Assessment Tag
                    </label>
                    <label className="block mb-1 text-sm text-gray-500">
                      {props.filter?.assessmentTag}
                    </label>
                  </div>
                )}{" "}
              <div className="flex flex-col">
                <label className="block mb-1 text-sm text-gray-700">
                  Start Date
                </label>
                <label className="block mb-1 text-sm text-gray-500">
                  {moment(props.filter?.startDate).format("MM/DD/YYYY")}
                </label>
              </div>
              <div className="flex flex-col">
                <label className="block mb-1 text-sm text-gray-700">
                  End Date
                </label>
                <label className="block mb-1 text-sm text-gray-500">
                  {moment(props.filter?.endDate).format("MM/DD/YYYY")}
                </label>
              </div>
            </div>

            <div className="grid grid-cols-1 gap-4 mb-4">
              <div className="flex flex-col col-span-1 md:col-span-3 gap-1">
                <span className="text-gray-700">Sources</span>
                <div className="flex gap-4">
                  <div className="flex gap-3">
                    <input
                      type="checkbox"
                      name="time-line"
                      className={
                        "h-4 w-4 mt-0.5 cursor-pointer text-primary-violet border-gray-300 focus:ring-primary-violet "
                      }
                      checked={props.filter?.includeAssignments}
                    />
                    <span className="text-sm text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3">
                      Assessments/Assignments
                    </span>
                  </div>
                  <div className="flex gap-3">
                    <input
                      type="checkbox"
                      name="time-line"
                      className={
                        "h-4 w-4 mt-0.5 cursor-pointer text-primary-violet border-gray-300 focus:ring-primary-violet "
                      }
                      checked={props.filter?.includeStudyPlan}
                    />
                    <span className="text-sm text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3">
                      Study Plan Practice
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full 1xl:w-1/4">
            <div className="flex gap-1 flex-col bg-gray-50 p-3 text-gray-500 mb-3 rounded-md">
              <p>
                The Dot Rank contains cumulative data based on all questions
                answered within the activity.
              </p>

              <div className="flex gap-1 flex-col bg-white/50 p-1">
                <p className="flex items-center">
                  <span className="w-3.5 h-3.5 rounded-full bg-[#9e9e9e] border border-black inline-block mr-1"></span>
                  {"Gray dot: No questions answered"}
                </p>
                <p className="flex items-center">
                  {" "}
                  <span className="w-3.5 h-3.5 rounded-full bg-[#e53f71] border border-black inline-block mr-1"></span>
                  {"Pink dot: < 65%"}
                </p>
                <p className="flex items-center">
                  <span className="w-3.5 h-3.5 rounded-full bg-[#f4d221] border border-black inline-block mr-1"></span>
                  {"Yellow dot: 65-79%"}
                </p>
                <p className="flex items-center">
                  <span className="w-3.5 h-3.5 rounded-full bg-[#a3b938] border border-black inline-block mr-1"></span>
                  {"Green dot:  >= 80%"}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default connect((state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
})(ProgressReportFilterPrint);

import { useEffect, useCallback, useState, useRef } from "react";
import { initRequest } from "./learnosityPreviewInit";
import { initActivityRequest } from "../../../../../model/learnosity/activityPreviewIntit";
import {
  getActivityStandards,
  renderClassificationDetailsHtml,
} from "../../../../../utils/learnosityHelper";
import Loader from "../../../../shared/loader";
import QuestionClassification from "../../../../../model/teacher/questionClassification";
import QuestionTotalPoint from "../../../../../model/teacher/questionTotalPoint";
import {
  getQuestionClassification,
  questionTotalPoints,
} from "../../../../../api/teacher/question";
import { getRequest } from "../../../../../api/userActivity";
import { waitForElementsToRender } from "../../../../../utils/waitFor";

export default function PreviewPage(props: any) {
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const isInitialized = useRef(false);
  let activityQuestionClassification: Array<QuestionClassification> = [];
  let authorsApp;
  let activityquestionTotalPoints: Array<QuestionTotalPoint> = [];

  const initialization = useCallback((itemReference: string | undefined) => {
    
    if (isInitialized.current) return;
      
    isInitialized.current = true;
    setShowLoader(true);
    const callbacks = {
      readyListener: function () {
        authorsApp.on("open:activity", function () {
          setTimeout(() => {
            var learnosityItems = document.querySelectorAll(
              ".learnosity-item"
            ) as NodeListOf<Element>;
            loadClassification(learnosityItems);
            setShowLoader(false);
          }, 5000);

          if (props.isProjectorMode) {
            setTimeout(function () {
              var el = document.getElementsByClassName(
                "lrn-author-tab"
              )[1] as HTMLElement;
              el?.remove();
              setShowLoader(false);
            }, 1000);
          } else {
            setTimeout(function () {
              var el = document.getElementsByClassName(
                "lrn-author-tab-link"
              )[1] as HTMLAnchorElement;
              el?.click();
              setShowLoader(false);
            }, 1000);
          }
        });
        authorsApp.on("render:item", function (data) {
          var item = authorsApp.getItem();
          if (props.returnCurrentItem) {
            props.returnCurrentItem(item);
          }
          if (props.onShowAnswer) {
            waitForElementsToRender("", ".lrn-author-validate-questions-toggle", 0, 3000).then(()=> {
              const showAnswerButton = document.querySelector(".lrn-author-validate-questions-toggle") as HTMLElement;
              showAnswerButton?.addEventListener("click", () => {
                props.onShowAnswer();
                if (props.isShowAnswerButtonHiddenAfterClick && showAnswerButton) {
                  showAnswerButton.style.display = 'none';
                }
              });
            });
          }
          if (props.isCheckAnswerButtonHidden) {
            waitForElementsToRender("", ".lrn_validate", 0, 3000).then(() => {
              const checkAnswerButton = document.querySelector(".lrn_validate") as HTMLElement;
              checkAnswerButton.style.display = 'none';
            });
          }
          if (!props.isStudentScreen && !props.isTotalPointsHidden) {
            DisplayTotalPointValue(item);
          }
          var el = document.getElementsByClassName(
            "lrn-author-message-warning"
          )[0] as HTMLElement;
          el?.remove();
          setShowLoader(false);
        });
      },
      errorListener: function (e) {
        console.log("Error Code ", e.code);
        console.log("Error Message ", e.msg);
        console.log("Error Detail ", e.detail);
        setShowLoader(false);
      },
    };
    const request = itemReference
      ? initRequest(itemReference, props.isStudentScreen)
      : initActivityRequest(props.activityRef?.toString());
    getRequest("author", JSON.stringify(request)).then((response) => {
      if (response.data) {
        authorsApp = window["LearnosityAuthor"]?.init(response.data, callbacks);
      }
    });
  }, []);

  useEffect(() => {
    initialization(props.itemRef);
    return () => {
      if (props.isProjectorMode && !props.isStudentScreen) {
        window["LearnosityApp"]?.reset();
      }
    };
  }, [props.itemRef]);

  const parseQuestionId = (element: HTMLElement): number => {
    return parseInt(
      element.getAttribute("data-reference")?.split("-")[0] ?? "0"
    );
  };

  const parseQuestionTypeId = (element: HTMLElement): number => {
    return element.getAttribute("data-reference")?.indexOf("-teacher") !== -1
      ? 2
      : 1;
  };
  const getClassification = async (
    questionId,
    questionTypeId,
    standardIds,
    subjectId,
    domainId,
    assessmentId?
  ): Promise<QuestionClassification | undefined> => {
    var res = await getQuestionClassification(
      questionId,
      questionTypeId ?? 1,
      standardIds,
      subjectId,
      domainId,
      assessmentId
    );
    if (res.data) {
      const classification: QuestionClassification = res.data;
      if (classification) {
        classification.questionId = questionId;
        activityQuestionClassification?.push(classification);
      }
      return classification;
    } else {
      return undefined;
    }
  };
  const loadClassification = async (items?) => {
    if (props.activityRef) {
      var learnosityItems = items;
      if (learnosityItems?.length === 0) {
        learnosityItems = document.querySelectorAll(
          ".learnosity-item"
        ) as NodeListOf<Element>;
      }

      let subjectId = props.subjectId ?? 0;
      let domainId = 0;
      if (props.activityRef?.indexOf("DOMAINTEST") !== -1) {
        domainId = props.activityRef.split("-")[2] ?? 0;
      }
      if (
        subjectId === 0 &&
        props.activityRef?.indexOf("SUMMATIVETEST") !== -1
      ) {
        subjectId = parseInt(props.activityRef.split("-")[2] ?? "0");
      }

      const isCannedTest = /cannedtest/i.test(props.activityRef);
      if  (isCannedTest) {
        await renderAllClassifications(items, subjectId, null, null, null);
        return;
      }

      const isAssessment = /assessment/i.test(props.activityRef);
      if (isAssessment && props.activityIdPreview) {
        await renderAllClassifications(items, null, null, null, props.activityIdPreview);
        return;
      }

      const standardIds = await getActivityStandards(props.activityRef, props.standardId);
      await renderAllClassifications(items, subjectId, domainId, standardIds, null);
    }
  };

  const renderAllClassifications = async (items: any[], subjectId: number | null, domainId: number | null, standardIds: string | null, activityIdPreview: number | null) => {
    items?.forEach(async (element, key) => {
      const questionId = parseQuestionId(element);
      const questionTypeId = parseQuestionTypeId(element);
      let classification = activityQuestionClassification?.filter(
          (c) => c?.questionId === questionId
      )[0];
      if (!classification) {
        const tclassification = await getClassification(
            questionId,
            questionTypeId,
            standardIds,
            subjectId,
            domainId,
            activityIdPreview
        );
        if (tclassification) classification = tclassification;
      }

      renderClassificationDetailsHtml(
          element,
          questionId,
          false,
          classification
      );
    });
    DisplayPointValueForActivity();
  };

  function handleClickEvent(e) {
    const tabs = document.querySelectorAll(
      ".lrn-author-tab-link"
    ) as NodeListOf<Element>;
    if (tabs) {
      const ev: any = e.target;
      if (ev) {
        tabs.forEach((tab) => {
          if (tab.contains(ev)) {
            setTimeout(() => {
              loadClassification();
            }, 5000);
          }
        });
      }
    }
  }
  function DisplayTotalPointValue(item) {
    var questions = item?.questions;
    var score: number = 0;

    questions.forEach(function (question: any) {
      if(question?.data?.validation) {
        if (question?.type === "longtextV2") {
          score += question?.data?.validation?.max_score ?? 0;
        } else {
          score += question?.data?.validation?.valid_response?.score ?? 0;
        }
      }
    });
    
    if (score > 0) {
      let liEl = document.createElement("li");
      let pointValueEl = document.createElement("div");
      pointValueEl.innerHTML = "Point Value: " + score;
      pointValueEl.className = "point-value";
      liEl.appendChild(pointValueEl);
      var ulEl = document.querySelector(
        "ul.lrn-author-item-toolbar-controls.lrn-author-item-toolbar-controls-alt"
      );
      ulEl?.appendChild(liEl);
    }
  }
  function DisplayPointValueForActivity(items?) {
    items = items ?? authorsApp.getActivityItems();
    const itemReferences: string[] = Array.from(
      new Set(items?.map((item) => item.id))
    );
    var itemsPointValues: Array<QuestionTotalPoint> = [];

    if (
      itemReferences.length &&
      activityquestionTotalPoints?.length !== itemReferences.length
    ) {
      questionTotalPoints(itemReferences).then((response) => {
        if (response?.data) {
          itemsPointValues = response?.data;
          activityquestionTotalPoints = response?.data;
          itemsPointValues.forEach(async (o: QuestionTotalPoint) => {
            if (o?.totalPoints > 0) {
              var el = document.querySelector(
                "div[data-reference='" + o.itemReference + "']"
              ) as HTMLElement;
              if (el?.querySelector(".point-value") === null) {
                let pointValueEl = document.createElement("div");
                pointValueEl.innerHTML = "Point Value: " + o?.totalPoints;
                pointValueEl.className = "point-value float-right mt-1.5";
                el?.insertAdjacentElement("afterbegin", pointValueEl);
              }
            }
          });
        }
      });
    } else if (activityquestionTotalPoints.length) {
      itemsPointValues = activityquestionTotalPoints;
      itemsPointValues.forEach(async (o: QuestionTotalPoint) => {
        if (o?.totalPoints > 0) {
          var el = document.querySelector(
            "div[data-reference='" + o.itemReference + "']"
          ) as HTMLElement;
          if (el?.querySelector(".point-value") === null) {
            let pointValueEl = document.createElement("div");
            pointValueEl.innerHTML = "Point Value: " + o?.totalPoints;
            pointValueEl.className = "point-value float-right mt-1.5";
            el?.insertAdjacentElement("afterbegin", pointValueEl);
          }
        }
      });
    }
  }

  useEffect(() => {
    window.addEventListener("click", handleClickEvent);
    return () => {
      window.removeEventListener("click", handleClickEvent);
    };
  }, []);

  return (
    <>
      {showLoader && <Loader></Loader>}
      <div className={props.isHide ? "hidden" : ""}>
        <div className={props.isStudentScreen ? "StudentWrapper" : ""}>
          {props.questionId === undefined && (
            <div className={"main-content"}>
              <div id="learnosity-author"></div>
            </div>
          )}
          {props.questionId !== undefined && (
            <div
              key={props.questionId}
              className={"main-content-" + props.questionId}
            >
              <div id="learnosity-author"></div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

import { Fragment, useEffect, useState } from "react";
import Moment from "react-moment";
import { connect } from "react-redux";
import { getAllAlienArenaSessionsByStudent } from "../../../../../api/teacher/alienArena";
import PagingResponse from "../../../../../model/common/pagingResponse";
import AlienArenaDetails from "../../../../../model/student/alienArenaDetails";
import { UserContextState } from "../../../../../redux/actions/userContextAction";
import {
  DEFAULT_PAGE_NUMBER,
  DEFAULT_PAGE_SIZE,
} from "../../../../../utils/pagingConstant";
import Loader from "../../../../shared/loader";

interface AlienArenaProps {
  userContext: UserContextState;
  userId: number;
  roleId: number;
}

function AlienArenaElementary(props: AlienArenaProps) {
  const [loading, showLoading] = useState<boolean>(false);
  const pageNumber = DEFAULT_PAGE_NUMBER;
  const pageSize = DEFAULT_PAGE_SIZE;
  const [alienArenaSessions, setAlienArenaSessions] =
    useState<PagingResponse<AlienArenaDetails>>();

  const getAlienArenaSessionsList = () => {
    showLoading(true);
    getAllAlienArenaSessionsByStudent(props.userContext.userId)
      .then((d) => {
        setAlienArenaSessions(d.data);
        showLoading(false);
      })
      .catch(() => {});
  };

  useEffect(() => {
    getAlienArenaSessionsList();
  }, [pageNumber, pageSize]);

  return (
    <Fragment>
      {loading && <Loader />}

      <div className="min-w-0 block lg:col-span-9 xl:col-span-10 w-full h-full">
        <div className="bg-purple-700  w-full mb-5 rounded-lg p-5">
          <div className="bg-white py-5 px-5">
            <div className="mb-4 pb-4 border-b-2 overflow-x-auto xl:overflow-x-visible">
              <table className="table w-full ">
                <thead>
                  <tr className="bg-purple-300 text-gray-700 font-semibold  ">
                    <th className="text-left p-3 text-lg font-normal">
                      Session Name
                    </th>
                    <th className="text-left p-3 text-lg font-normal">
                      Subject
                    </th>
                    <th className="text-left p-3 text-lg font-normal">
                      Team Name
                    </th>
                    <th className="text-left p-3 text-lg font-normal">
                      Start Date
                    </th>
                    <th className="text-left p-3 text-lg font-normal">Score</th>
                    <th className="text-left p-3 text-lg font-normal"></th>
                  </tr>
                </thead>
                <tbody>
                  {alienArenaSessions &&
                    alienArenaSessions!.data.length > 0 &&
                    alienArenaSessions.data?.map((session, index) => {
                      return (
                        <Fragment>
                          <tr
                            key={index}
                            className={`bg-purple-400 text-white`}
                            // onMouseOver={() => {
                            //   setHoverElement("row-" + session.id);
                            // }}
                          >
                            <td className="text-sm p-3 ">
                              {session.sessionName}
                            </td>

                            <td className="text-sm p-3 ">
                              {session.sessionName}
                            </td>
                            <td className="text-sm p-3 ">{session.subject}</td>
                            <td className="text-sm p-3 ">
                              {<Moment>{session.startDate}</Moment>}
                            </td>
                            <td className="text-sm p-3 "><>{session.score}</></td>
                            <td className="text-sm p-3 ">
                              <span className="py-0.5 px-1 text-xs rounded-lg text-white">
                                {session.statusName}
                              </span>
                            </td>
                          </tr>
                        </Fragment>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(AlienArenaElementary);

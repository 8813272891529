import { compile } from "./regexHelper";

export type IRedirectProps = {
  urlTemplate: string;
  code?: string;
  subjectId?: number;
  multipleAttemptAllowed?: boolean;
  history?: any;
};

export function redirect(props: IRedirectProps) {
  const mappedUrl = compile(props.urlTemplate);
  const url = mappedUrl({
    multipleAttemptAllowed: encodeURI(
      btoa(props.multipleAttemptAllowed?.toString() ?? "false")
    ),
    code: encodeURI(btoa(props.code ?? "")),
    subjectId: encodeURI(btoa(props.subjectId?.toString() ?? "0")),
  });
  props.history?.push(url);
}

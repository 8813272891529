import { XIcon } from "@heroicons/react/outline";
import { useRef } from "react";

interface QuestionPrintDialogProp {
  children: (string | JSX.Element | JSX.Element[]);
  onCancelClick: () => void;
  className?: string;
}

export default function QuestionPrintDialog(props: QuestionPrintDialogProp) {
  const ref = useRef<HTMLDivElement>(null);

  const handleClick = (event) => {
    if(event.target && event.target === ref.current) {
      props.onCancelClick();
    }
  }
  return (
    <div
      id="questionPrintModal"
      className="fixed inset-0 z-40 flex items-center bg-black bg-opacity-50 sm:items-center sm:justify-center"
      ref={ref}
      onClick={(e) => handleClick(e)}
    >
      <div
        className="w-full flex flex-col justify-between overflow-hidden h-auto max-h-80percent bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:max-w-2xl"
        role="dialog"
      >
        <div className="relative w-full px-6 py-4 h-80-percent overflow-y-auto bg-white rounded-t-lg dark:bg-gray-800">
          <div className="h-7 mb-2 flex items-center justify-end">
            <button
              type="button"
              className="text-gray-400 hover:text-gray-500"
              onClick={() => props.onCancelClick()}
            >
              <span className="sr-only">Close modal</span>
              <XIcon className="h-4 w-4" aria-hidden="true" />
            </button>
          </div>
          <div>
            {props.children}
          </div>
        </div>
      </div>
    </div>
  );
}

import moment from "moment";
import getColors from "../component/shared/tagColor";
import Constant from "./constant/constant";
import { Configuration } from "../environment/setup";
import RouteConstant from "./constant/routeConstant";
import { UserContextState } from "../redux/actions/userContextAction";
import { StudentSchoolLevel } from "../types/type";
import {
  getUserDiagnosticSubjects,
  getUserHasLiftoffAccess,
} from "../api/subjectAPI";
import Profile from "../model/users/profile";
import { permissions } from "../component/shared/rbac";
import { jwtToken } from "../component/shared/tokenHelper";
import { GetSchoolYears } from "../api/teacher/school";
import { getSubjectDetail } from "../api/teacher/assessment";
import { IAssignmentStatus } from "../model/teacher/assignment/assignmentStatus";
import { IContentAreaSubject, ISubject } from "../model/interface/subject";
import constant from "./constant/constant";

export function getLastRefreshedDate() {
  return moment(new Date()).format("MM-DD-YYYY");
}

export function getActivityTypeId(activityType: string) {
  let activityTypeId = 0;
  switch (activityType) {
    case Constant.AssignmentActivityType.ASSESSMENT:
      activityTypeId = 1;
      break;
    case Constant.AssignmentActivityType.PREBUILTTESTS:
      activityTypeId = 2;
      break;
    case Constant.AssignmentActivityType.PRACTICEQUESTIONS:
      activityTypeId = 3;
      break;
    case Constant.AssignmentActivityType.VIDEOLESSON:
      activityTypeId = 4;
      break;
    case Constant.AssignmentActivityType.CONSTRUCTEDRESPONSE:
      activityTypeId = 5;
      break;
  }

  return activityTypeId;
}

export function OrderByArray(values: any[], orderType: any) {
  return values.sort((a, b) => {
    if (a[orderType] < b[orderType]) {
      return -1;
    }

    if (a[orderType] > b[orderType]) {
      return 1;
    }

    return 0;
  });
}

export function getRandomColor() {
  const existingColors = getColors;
  const randomColor =
    existingColors[Math.floor(Math.random() * existingColors.length)];
  return randomColor.code;
}

/** @params arr = original array refArr = Array to validate */
export const containsAll = (arr: Array<any>, refArr: Array<any>) => {
  return refArr.length && refArr.every((val) => arr.includes(val));
};

export const getThemeByGrade = (gradeId) => {
  if (gradeId <= Constant.Grade.GRADE1) {
    return "KINDERGARTEN";
  } else if (
    gradeId > Constant.Grade.GRADE1 &&
    gradeId < Constant.Grade.GRADE6
  ) {
    return "ELEMENTARYHOME";
  } else if (
    gradeId > Constant.Grade.GRADE6 &&
    gradeId <= Constant.Grade.GRADE9
  ) {
    return "MIDDLESCHOOLHOME";
  } else {
    return "ELEMENTARYHOME";
  }
};

export const getStudentSchoolLevelByGrade = (gradeId: number) => {
  switch (gradeId) {
    case Constant.Grade.GRADEK:
      return StudentSchoolLevel.Kindergarten;
    case Constant.Grade.GRADE1:
    case Constant.Grade.GRADE2:
    case Constant.Grade.GRADE3:
    case Constant.Grade.GRADE4:
    case Constant.Grade.GRADE5:
      return StudentSchoolLevel.Elementary;
    case Constant.Grade.GRADE6:
    case Constant.Grade.GRADE7:
    case Constant.Grade.GRADE8:
      return StudentSchoolLevel.Middle;
    default:
      return StudentSchoolLevel.High;
  }
};

export const getUrlParams: any = () => {
  return window.location.search
    .slice(1)
    .split("&")
    .reduce(
      (res, val) => ({ ...res, [val.split("=")[0]]: val.split("=")[1] }),
      {}
    );
};

export const getDayFromDate = (date: Date) => {
  var days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  var dayName = days[date.getDay()];
  return dayName;
};
export const getMonthFromDate = (date: Date) => {
  var months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  var monthName = months[date.getMonth()];
  return monthName;
};
export const getNameLabel = (lastName: string, firstName: string) => {
  return `${lastName}, ${firstName}`;
};

export const getTestSizeBySubjectGrades = (
  testSizeId: number,
  subjectGrades: Array<any>
) => {
  let result: number = 0;

  const grades: Array<number> = subjectGrades.map((grade) => {
    return grade.id;
  });

  const isElementarySubject = grades.some(
    (grade) => grade < Constant.Grade.GRADE6
  );

  switch (testSizeId) {
    case 1:
      result = isElementarySubject ? 10 : 20;
      break;
    case 2:
      result = isElementarySubject ? 20 : 40;
      break;
    case 3:
      result = isElementarySubject ? 30 : 60;
      break;
    default:
      result = 0;
      break;
  }
  return result;
};

export const getTestSizeBySubjectId = async (
  testSizeId: number,
  subjectId: number
) => {
  const subjectDetail = await getSubjectDetail(subjectId);
  if (!subjectDetail || !subjectDetail?.data?.grades?.length) return 0;
  return getTestSizeBySubjectGrades(testSizeId, subjectDetail.data.grades);
};

export const verifySubjectNeedsCannedTest = (subjectName: string): boolean => {
  return (
    subjectName.startsWith("ACT") ||
    subjectName.startsWith("SAT") ||
    subjectName.startsWith("PSAT") ||
    subjectName.startsWith("AP")
  );
};

export const getTestSizeType = (resultTypeId: number) => {
  let result: string | undefined;
  switch (resultTypeId) {
    case 1:
      result = "Small";
      break;
    case 2:
      result = "Medium";
      break;
    case 3:
      result = "Large";
      break;
  }

  return result;
};
export const GetIsQuestionRetryAllowed = (
  gradeId: number,
  stateId: number,
  activityType: string,
  allowedMultipleAttempts?: boolean
) => {
  let isAllowed = false;
  switch (activityType) {
    case Constant.AssignmentActivityType.PRACTICEQUESTIONS:
      if (
        gradeId <= 6 ||
        (gradeId >= 7 && gradeId <= 9 && stateId === Configuration.TexasStateId)
      ) {
        isAllowed = true;
      }
      break;
    case Constant.ActivitySubTypes.STUDYPLANPRACTICEQUESTIONS:
      if (
        gradeId <= 6 ||
        (gradeId >= 7 && gradeId <= 9 && stateId === Configuration.TexasStateId)
      ) {
        isAllowed = true;
      }
      break;
    case Constant.AssignmentActivityType.ASSESSMENT:
      if(allowedMultipleAttempts){
        isAllowed = true;
      }
      break;
    case Constant.ActivitySubTypes.LIFTOFFMYGALAXIES:
      isAllowed = true;
      break;
    case Constant.ActivitySubTypes.LIFTOFFDIAGNOSTICS:
      isAllowed = true;
      break;
  }
  return isAllowed;
};
export const GetShowExplanation = (
  gradeId: number,
  stateId: number,
  activityType: string,
  activityName?: string,
  allowedMultipleAttempts?: boolean,
  subjectName?: any
) => {
  let showExplanation = false;
  switch (activityType) {
    case Constant.ActivitySubTypes.LIFTOFFMYGALAXIES:
      showExplanation = true;
      break;
    case Constant.ActivitySubTypes.STUDYPLANPRACTICEQUESTIONS:
      showExplanation = true;
      break;
    case Constant.AssignmentActivityType.PRACTICEQUESTIONS:
      showExplanation = true;
      break;
  }
  return showExplanation;
};

export const GetShowOnlyTextExpalanation = (
  gradeId: number,
  stateId: number,
  activityType: string
) => {
  let showTextExplanation = false;
  switch (activityType) {
    case Constant.ActivitySubTypes.STUDYPLANPRACTICEQUESTIONS:
      if (
        (stateId !== Configuration.TexasStateId && gradeId >= 7) ||
        (stateId === Configuration.TexasStateId && gradeId >= 10)
      )
        showTextExplanation = true;
      break;
    case Constant.AssignmentActivityType.PRACTICEQUESTIONS:
      if (
        (stateId !== Configuration.TexasStateId && gradeId >= 7) ||
        (stateId === Configuration.TexasStateId && gradeId >= 10)
      )
        showTextExplanation = true;
      break;
  }
  return showTextExplanation;
};
export const getBeginActivityType = (
  assignmentType: string,
  prebuildTestType: string
) => {
  let activityType = "Questions";
  switch (assignmentType) {
    case Constant.AssignmentActivityType.VIDEOLESSON:
      activityType = "Video";
      break;
    case Constant.AssignmentActivityType.PRACTICEQUESTIONS:
      activityType = "Questions";
      break;
    case Constant.AssignmentActivityType.CONSTRUCTEDRESPONSE:
      activityType = "Constructed Response";
      break;
    case Constant.AssignmentActivityType.ASSESSMENT:
      activityType = "Assessment";
      break;
    case Constant.AssignmentActivityType.PREBUILTTESTS:
      activityType = prebuildTestType ?? "Practice Questions";
      break;
  }
  return activityType;
};

export const removeSpecialCharacters = (text: string) => {
  return text ?? "" ? text.replace(/[’]/g, "") : text;
};

export const getFormatedMathText = (text: string) => {
  text = !text.includes("<math>")
    ? !text.includes("<table")
      ? text.substring(0, Constant.MAX_LEGTH_PREVIEW_QUESTION_TEXT)
      : getInnerText(text).substring(
          0,
          Constant.MAX_LEGTH_PREVIEW_QUESTION_TEXT
        )
    : text;

  type objectType = {
    type: string;
    object: any;
  };

  let mainArray: objectType[] = [];
  if (text.includes("<math>")) {
    const obj: objectType = {
      type: "math",
      object:
        "<MathJaxContext><MathJax>" + text + "</MathJax></MathJaxContext>",
    };
    mainArray.push(obj);
  } else {
    var obj: objectType = {
      type: "text",
      object: text,
    };
    mainArray.push(obj);
  }

  return mainArray;
};

const getInnerText = (text: string) => {
  const divElement = document.createElement("div");
  divElement.innerHTML = text;
  const innerText = divElement.innerText.trim();
  return innerText.length > 0 ? innerText : text;
};

export const isPermissionExist = (permission: string) => {
  let permissionExist: boolean = false;
  let perm: any = permissions();
  permissionExist = perm.includes(permission);
  return permissionExist;
};

export const handleRedirection = (
  roleId: number,
  gradeId: number = 0,
  linkedUserId: string = "",
  history: any,
  loginType: string = Constant.ExterRosterType.FORM
) => {
  var schoolSelectionUrl: string = RouteConstant.ROUTE_SCHOOL_SELECTION.replace(
    ":linkedUserId",
    linkedUserId
  ).replace(":loginType", loginType);
  if (
    roleId === Constant.UserRoleId.SchoolAdmin ||
    roleId === Constant.UserRoleId.PayTeacher ||
    roleId === Constant.UserRoleId.FreeTeacher ||
    roleId === Constant.UserRoleId.SchoolTeacher ||
    roleId === Constant.UserRoleId.District ||
    roleId === Constant.UserRoleId.SuperAdmin ||
    roleId === 0
  ) {
    history.push(
      linkedUserId === "" ? RouteConstant.ROUTE_DASHBOARD : schoolSelectionUrl
    );
  } else if (roleId === Constant.UserRoleId.Student) {
    if (gradeId <= Constant.Grade.GRADE1) {
      history.push(
        linkedUserId === ""
          ? RouteConstant.KindergartenStudentRoutes.Home
          : schoolSelectionUrl
      );
    } else if (
      gradeId > Constant.Grade.GRADE1 &&
      gradeId < Constant.Grade.GRADE6
    ) {
      history.push(
        linkedUserId === ""
          ? RouteConstant.StudentRoute.Home
          : schoolSelectionUrl
      );
    } else if (gradeId >= Constant.Grade.GRADE6) {
      history.push(
        linkedUserId === ""
          ? RouteConstant.MiddleSchool.assignments
          : schoolSelectionUrl
      );
    } else {
      history.push(
        linkedUserId === ""
          ? RouteConstant.StudentRoute.Home
          : schoolSelectionUrl
      );
    }
  } else {
    history.push(RouteConstant.TeacherRoutes.assignmentCenter);
  }
};

export const GetImpersonatedUser = (
  userContext: UserContextState | undefined
) => {
  const impersonatedUser: any = userContext?.impersonatedUser?.token
    ? jwtToken(userContext?.impersonatedUser?.token)
    : null;
  return impersonatedUser;
};

export const getTimezoneOffset = () => {
  return new Date().getTimezoneOffset();
};

export const hasLiftOffAccess = async (_userContext: UserContextState) => {
  var hasAccess = await getUserHasLiftoffAccess();
  return hasAccess.data.data;
};

export const hasDiagnosticAccess = async (userContext: UserContextState) => {
  if (userContext.roleId === Constant.UserRoleId.District) {
    return true;
  }
  var diagnosticSubjects = await getUserDiagnosticSubjects();
  if (diagnosticSubjects && diagnosticSubjects.data.length > 0) {
    return true;
  } else {
    return false;
  }
};

export const validateIsGoogleSignedIn = (
  userContext: UserContextState,
  profile: Profile
) => {
  if (userContext?.activeExternalRoster !== "GOOGLE" && profile?.gmailId) {
    return false;
  } else if (!profile?.gmailId) {
    return false;
  }
  return true;
};

export const validateIsCanvasSignedIn = (
  userContext: UserContextState,
  profile: Profile
) => {
  if (
    userContext?.activeExternalRoster !== Constant.ExterRosterType.CANVAS &&
    profile?.canvasId
  ) {
    return false;
  } else if (!profile?.canvasId) {
    return false;
  }
  return true;
};

export const validateIsCanvasUser = (profile: Profile) => {
  if (!profile?.canvasId) {
    return false;
  }
  return true;
};

export const validateIsGoogleUser = (profile: Profile) => {
  if (
    profile?.googleUser?.googleId &&
    profile?.googleUser?.refreshToken !== null &&
    profile?.googleUser?.refreshToken !== ""
  ) {
    return true;
  }
  return false;
};

export const getCopyrightText = () => {
  return `© Progress Learning ${getCopyrightYear()}, All Rights Reserved.`;
};

export const getCopyrightYear = () => {
  return new Date().getFullYear();
};

export const isActiveCanvasExternalRoster = (userContext: any) => {
  return userContext?.activeExternalRoster === Constant.ExterRosterType.CANVAS;
};

export const isActiveGoogleExternalRoster = (userContext: UserContextState) => {
  return userContext?.activeExternalRoster === "GOOGLE";
};

export const validateStudentAssignment = (status: IAssignmentStatus) => {
  type CustomResponse = {
    isSuccess: boolean;
    message: string;
  };
  let response: CustomResponse = { isSuccess: false, message: "" };
  var scheduleDate = new Date(status.startDate);
  if (scheduleDate > new Date()) {
    response.isSuccess = false;
    response.message =
      "Cannot start the assignment, this assignment is scheduled for " +
      scheduleDate.toLocaleDateString();
  } else if (status.isActive && !status.isAssigned) {
    response.isSuccess = false;
    response.message =
      "This assignment is not assigned to you, please speak with your teacher for more information";
  } else if (status.isActive && status.isLocked) {
    response.isSuccess = false;
    response.message =
      "Cannot start the assignment, this assignment is locked, please speak with your teacher for more information";
  } else if (status.isActive && status.isArchived) {
    response.isSuccess = false;
    response.message =
      "Cannot start the assignment, this assignment is archived, please speak with your teacher for more information";
  } else if (!status.isActive) {
    response.isSuccess = false;
    response.message =
      "This assignment does not exist, please speak with your teacher for more information";
  } else if (status.isActive && status.isAssigned) {
    response.isSuccess = true;
    response.message = "Valid student assignment";
  }
  return response;
};

export const capitalizeWords = (str) => {
  return str
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export const IsLiftOff = () => {
  if (window.location.pathname.toLowerCase().indexOf("liftoff") > -1) {
    return true;
  } else {
    return false;
  }
};

export const hasSectionsGetByCannedtestTypeId = (cannedTestTypeId: number) => {
  return (
    cannedTestTypeId === Constant.CannedTestTypeId.CannedTest ||
    cannedTestTypeId === Constant.CannedTestTypeId.PracticeTest
  );
};

export const getActivitySubTypes = (activityType: string) => {
  let subtypes: Array<string> = [];
  switch (activityType) {
    case Constant.AssignmentActivityType.ASSESSMENT:
      subtypes = Object.values(Constant.AssignmentActivitySubType.ASSESSMENT);
      break;
    case Constant.AssignmentActivityType.PREBUILTTESTS:
      subtypes = Object.values(
        Constant.AssignmentActivitySubType.PREBUILTTESTS
      );
      break;
    case Constant.AssignmentActivityType.PRACTICEQUESTIONS:
      subtypes = Object.values(
        Constant.AssignmentActivitySubType.PRACTICEQUESTIONS
      );
      break;
    case Constant.AssignmentActivityType.VIDEOLESSON:
      subtypes = Object.values(Constant.AssignmentActivitySubType.VIDEOLESSON);
      break;
    case Constant.AssignmentActivityType.CONSTRUCTEDRESPONSE:
      subtypes = Object.values(
        Constant.AssignmentActivitySubType.CONSTRUCTEDRESPONSE
      );
      break;
  }
  return subtypes;
};

export async function GetCurrentSchoolYearId(schoolId) {
  var currentSchoolYearId = await GetSchoolYears(schoolId).then((r) => {
    var currentSchoolYearId = r.data.filter((y) => y.currentYearOffset === 0)[0]
      ?.schoolYearId;
    return currentSchoolYearId;
  });
  return currentSchoolYearId;
}
export function moveItemsToEnd<T>(
  items: T[],
  predicate: (item: T) => boolean
): T[] {
  // Filter items based on the predicate
  const itemsToMove = items.filter((item) => predicate(item));
  const itemsToKeep = items.filter((item) => !predicate(item));
  // Concatenate the two arrays, placing itemsToMove at the end
  return itemsToKeep.concat(itemsToMove);
}

export function formatDate(stringDate?: string) {
  if (!stringDate) return stringDate;
  return stringDate.replace("Z", ".000");
}

export const scrollElementIntoView = (element: HTMLElement, behavior: 'auto' | 'smooth') => {
  let scrollTop = window.pageYOffset || element.scrollTop;

  const finalOffset = element.getBoundingClientRect().top + scrollTop;

  window.parent.scrollTo({
    top: finalOffset,
    behavior: behavior || 'auto'
  });
};

export const scrollToElementById = (elementId: string) => {
  const element = document.getElementById(elementId);
  if (element) {
    scrollElementIntoView(element, 'smooth');
  }
};

export function getSubjectName(subject: ISubject, profile?: Profile) {
  return profile?.plLanguageId === constant.Languages.Spanish
      ? subject.spanishName ?? subject.name
      : subject.name;
}

export function getContentAreaName(subject: IContentAreaSubject, profile?: Profile) {
  return profile?.plLanguageId === constant.Languages.Spanish
      ? subject.spanishName ?? subject.name
      : subject.name;
}

export function getContentAreaNameFromSubject(subject: ISubject, profile?: Profile) {
  return profile?.plLanguageId === constant.Languages.Spanish
      ? subject.spanishContentAreaName ?? subject.contentAreaName
      : subject.contentAreaName;
}
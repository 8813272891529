import { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import Constant from "../../../../utils/constant/constant";
import RouteConstant from "../../../../utils/constant/routeConstant";
import Content from "./content";
import Preview from "./preview";
import { IAssessment } from "../../../../model/interface/assessment";
import { getAssessmentById } from "../../../../api/teacher/assessment";
import Loader from "../../../shared/loader";
import Permissions from "../../../../utils/permissions";
import AllowedTo from "../../../shared/rbac";
import AccessDenied from "../../../shared/accessDenied";
import Breadcrumb from "../../teacher/shared/breadcrumb";
import { IBreadcrumb } from "../../../../model/common/breadcrumb";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { GetSchoolYears } from "../../../../api/teacher/school";
import { GetDistrict } from "../../../../api/districtsApi"
import arraySort from "array-sort";

function NewAssessment(props: any) {
  const { t } = useTranslation();
  const defaultAssessment: IAssessment = {
    assessmentId: 0,
    name: "",
    subjectId: 0,
    questions: [],
  };
  const [activeTab, setActiveTab] = useState("");
  const [assessment, setAssessment] = useState<IAssessment>(defaultAssessment);
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [subjectDetail, setSubjectDetail] = useState<any>();
  const [editSettingOnly, setEditSettingOnly] = useState<boolean>(false);
  const [isPreviewActive, setIsPreviewActive] = useState<boolean>(false);
  const componentRef = useRef();
  const [isCalculatorSubject, setIsCalculatorSubject] = useState<boolean>(false);
  const [upgrade, setUpgrade] = useState(false);
  const location = useLocation();
  const parameters = location.state as any;
  const isSchoolAssessment: boolean =
    props.userContext.roleId === Constant.UserRoleId.District
      ? true
      : parameters && parameters.isSchoolAssessment
      ? parameters.isSchoolAssessment
      : false;
 const [defaultSchoolYearId, setDefaultSchoolYearId] = useState<number>(2);
 const [hasDistrictAssessmentQuestionIcon, setHasDistrictAssessmentIcon] = useState<boolean>(false);

  function SaveAndPreview() {
    let cref: any = componentRef.current;
    if (cref) {
      cref.handleSaveAndPreviewFromParent();
    }
  }

  function togglePreviewTab(selectedQuestionsLenght)
  {
    var isActive = selectedQuestionsLenght > 0;
    setIsPreviewActive(isActive);
  }  
  
  function updateAssessment(
    updateAssessment: IAssessment,
    moveToNextTab: boolean = false
  ) {
    setAssessment(updateAssessment);
    if (subjectDetail) {
      setSubjectDetail(subjectDetail);
    }
    if (moveToNextTab) {
      switch (activeTab) {
        case Constant.ActiveTab.SETTING:
          setActiveTab(Constant.ActiveTab.CONTENT);
          break;
        case Constant.ActiveTab.CONTENT:
          setActiveTab(Constant.ActiveTab.PREVIEW);
          break;
      }
    }
  }

  function updateSubjectDetail(subjectDetail: any) {
    setSubjectDetail(subjectDetail);
  }

  useEffect(() => {
    GetDistrict().then((response) => {
      if(response.data) {
        setHasDistrictAssessmentIcon(response.data.hasDistrictAssessmentQuestionIcon);
      }
    });
    (async () => {
      const assessmentId = props.match.params.id;
      if (assessmentId && assessmentId > 0) {
        setShowLoading(true);
        try {
          let response = await getAssessmentById(assessmentId);
          let assessment: IAssessment = response.data;
          const tags = response.data.tagIds;
          if (tags && tags !== "") {
            assessment.tagIds = tags.split(",").map((r) => parseInt(r));
          }

          setEditSettingOnly(assessment.isContentLocked ?? false);
          let sortedAssessmentQuestions = response.data.questions;
          arraySort(sortedAssessmentQuestions, 'sequenceNumber');
          let assessmentToPass = response.data;
          assessmentToPass.questions = sortedAssessmentQuestions;
          setAssessment(assessmentToPass);
        } finally {
          setShowLoading(false);
        }
      }

      setActiveTab(Constant.ActiveTab.CONTENT);
    })();
  }, []);

  useEffect(() => {
    GetSchoolYears(props.userContext?.schoolId!).then((response) => {
      if (response.data) {
        var currentfutureschoolyears = response.data.filter(
          (y) => y.currentYearOffset == 0
        );
        const schoolYearId = currentfutureschoolyears[0].schoolYearId;   
        setDefaultSchoolYearId(schoolYearId);     
      }
    });
  }, []);
  
  const breadcrumbItems = () => {
    const items: Array<IBreadcrumb> = [
      { name: t("Breadcrumb.Home"), url: RouteConstant.ROUTE_DASHBOARD },
      {
        name:
          props.userContext.roleId === Constant.UserRoleId.District
            ? "Assessment Center"
            : "Assessment/Assignment Center",
        url: RouteConstant.TeacherRoutes.assignmentCenter,
      },
      {
        name: t("Breadcrumb.Assessment"),
        url: RouteConstant.ASSESSMENTBANK,
      },

      {
        name: `${
          assessment.assessmentId > 0 ? "Edit Assessment" : "New Assessment"
        }`,
        url: "",
      },
    ];

    return items;
  };
  return (
    <AllowedTo
      perform={[Permissions.assessments_create, Permissions.assessments_modify]}
      no={() => <AccessDenied />}
    >
      {showLoading && <Loader></Loader>}
      <div className="min-w-0 w-full">
        <div className="lg:flex lg:items-center px-4 py-3 lg:px-8 border">
          <Breadcrumb items={breadcrumbItems()} />
          <div className="lg:w-[55%] text-lg font-medium flex gap-2 h-auto sm:h-10 items-center">
            <h1 className="text-secondary-teal flex items-center justify-between w-full pr-4">
              {`${
                assessment.assessmentId > 0 ? "Edit Assessment" : "New Assessment"
              }`}
            </h1>
          </div>
        </div>
        <div className="lg:border-t lg:border-b lg:border-gray-200 ">
          <nav className="px-4 sm:pl-6 lg:pl-8 lg:pr-0" aria-label="Progress">
            <ol className="rounded-md overflow-hidden lg:flex lg:border-l lg:border-r lg:border-gray-200 lg:rounded-none">
              <li
                className={`relative overflow-hidden lg:flex-1 ${
                  activeTab === Constant.ActiveTab.CONTENT
                    ? "bg-secondary-teal"
                    : ""
                } `}
                onClick={() =>
                  assessment.assessmentId > 0
                    ? setActiveTab(Constant.ActiveTab.CONTENT)
                    : undefined
                }
              >
                <div
                  className={`${
                    activeTab === Constant.ActiveTab.CONTENT
                      ? ""
                      : "border border-white"
                  }   border border-b-0 border-gray-200 overflow-hidden lg:border-0`}
                >
                  <a
                    href={void 0}
                    aria-current="step"
                    x-description="Current Step"
                  >
                    <span
                      className={`absolute top-0 left-0 w-1 h-full  ${
                        activeTab === Constant.ActiveTab.CONTENT
                          ? "bg-secondary-teal"
                          : "bg-transparent group-hover:bg-gray-200 "
                      } lg:w-full lg:h-0.5 lg:bottom-0 lg:top-auto`}
                      aria-hidden="true"
                    ></span>
                    <span className="px-6 py-3 flex items-center text-sm font-medium lg:pl-9">
                      <span className="flex-shrink-0">
                        <span
                          className={`w-10 h-10 flex items-center justify-center border-2  ${
                            activeTab === Constant.ActiveTab.CONTENT
                              ? "border-white"
                              : "border-gray-300"
                          } rounded-full`}
                        >
                          <span
                            className={
                              activeTab === Constant.ActiveTab.CONTENT
                                ? "text-white"
                                : "text-gray-500"
                            }
                          >
                            01
                          </span>
                        </span>
                      </span>
                      <span className="mt-0.5 ml-4 min-w-0 flex flex-col">
                        <span
                          className={`text-sm font-semibold  ${
                            activeTab === Constant.ActiveTab.CONTENT
                              ? "text-white"
                              : "text-gray-500"
                          } tracking-wide uppercase`}
                        >
                          Content
                        </span>
                      </span>
                    </span>
                  </a>
                </div>
              </li>
              <li
                className={`relative overflow-hidden lg:flex-1 ${
                  activeTab === Constant.ActiveTab.PREVIEW
                    ? "bg-secondary-teal"
                    : ""
                } `}
                onClick={() => SaveAndPreview()}
              >
                <div
                  className={`${
                    activeTab === Constant.ActiveTab.PREVIEW
                      ? ""
                      : "border border-b-0 border-gray-200 "
                  }  border border-gray-200 border border-b-0 border-gray-200 overflow-hidden lg:border-0`}
                >
                  <a
                    href={void 0}
                    aria-current="step"
                    x-description="Current Step"
                  >
                    <span
                      className={`absolute top-0 left-0 w-1 h-full  ${
                        activeTab === Constant.ActiveTab.PREVIEW
                          ? "bg-secondary-teal"
                          : "bg-transparent group-hover:bg-gray-200 "
                      } lg:w-full lg:h-0.5 lg:bottom-0 lg:top-auto`}
                      aria-hidden="true"
                    ></span>
                    <span className={`px-6 py-3 flex items-center text-sm font-medium lg:pl-9 ${ (isPreviewActive ? "" : "cursor-not-allowed") }`}>
                      <span className="flex-shrink-0">
                        <span
                          className={`w-10 h-10 flex items-center justify-center border-2  ${
                            activeTab === Constant.ActiveTab.PREVIEW
                              ? "text-white"
                              : "border-gray-300"
                          } rounded-full`}
                        >
                          <span
                            className={
                              activeTab === Constant.ActiveTab.PREVIEW
                                ? "text-white"
                                : (isPreviewActive ? "" : "text-gray-500 text-opacity-40")
                            }
                          >
                            02
                          </span>
                        </span>
                      </span>
                      <span className="mt-0.5 ml-4 min-w-0 flex flex-col">
                        <span
                          className={`text-sm font-semibold  ${
                            activeTab === Constant.ActiveTab.PREVIEW
                              ? "text-white"
                              : (isPreviewActive ? "" : "text-gray-500 text-opacity-40")
                          } tracking-wide uppercase`}
                        >
                          Preview
                        </span>
                      </span>
                    </span>
                  </a>
                  <div
                    className={`hidden absolute top-0 -left-${
                      activeTab === Constant.ActiveTab.CONTENT ? "0.5" : "0"
                    } w-3 inset-0 lg:block`}
                    aria-hidden="true"
                  >
                    {activeTab !== Constant.ActiveTab.PREVIEW && (
                      <svg
                        className={`h-full w-full ${
                          activeTab === Constant.ActiveTab.CONTENT
                            ? " text-secondary-teal fill-current"
                            : " text-gray-300"
                        }`}
                        viewBox="0 0 12 82"
                        fill="none"
                        preserveAspectRatio="none"
                      >
                        <path
                          d="M0.5 0V31L10.5 41L0.5 51V82"
                          stroke="currentcolor"
                          vectorEffect="non-scaling-stroke"
                        ></path>
                      </svg>
                    )}
                  </div>
                </div>
              </li>
            </ol>
          </nav>
        </div>
        {activeTab === Constant.ActiveTab.CONTENT && (
          <Content
            ref={componentRef}
            userId={props.userContext.userId}
            roleId={props.userContext.roleId}
            districtId={props.userContext.districtId}
            hasDistrictAssessmentQuestionIcon={hasDistrictAssessmentQuestionIcon}
            assessment={assessment}
            subjectDetail={subjectDetail}
            updateAssessment={updateAssessment}
            allowEdit={!editSettingOnly}
            togglePreviewTab={togglePreviewTab}
            updateSubjectDetail={updateSubjectDetail}
            setIsCalculatorSubject={setIsCalculatorSubject}
            upgrade={upgrade}
            isSchoolAssessment={isSchoolAssessment}           
          />
        )}
        {activeTab === Constant.ActiveTab.PREVIEW && (
          <Preview
            userId={props.userContext.userId}
            roleId={props.userContext.roleId}
            districtId={props.userContext.districtId}
              hasDistrictAssessmentQuestionIcon={hasDistrictAssessmentQuestionIcon}
            assessment={assessment}
            updateAssessment={updateAssessment}
            allowEdit={!editSettingOnly}
            isCalculatorSubject={isCalculatorSubject}
            setUpgrade={setUpgrade}
            upgrade={upgrade}
            isSchoolAssessment={isSchoolAssessment}
            defaultSchoolYearId={defaultSchoolYearId}
          />
        )}
      </div>
    </AllowedTo>
  );
}

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(NewAssessment);

import { Fragment } from "react";
import Moment from "moment";
import { LogoPLIcon } from "../../../../../assets/icons";
import { Configuration } from "../../../../../environment/setup";
interface LiftoffIndividualDignosticsPrintDialogProp {
  lastName: string;
  firstName: string;
  filters: any;
  backgroundColours: any;
  headers: any;
  liftOffHeaders: any;
  standardsEntries: any;
  getStandardVisibilty: (
    starCount: number,
    isStandardDisable: boolean
  ) => string;
}

export default function LiftoffIndividualDignosticsPrintDialog(
  props: LiftoffIndividualDignosticsPrintDialogProp
) {
  return (
    <div className="bg-white px-3 pt-2 pb-3">
      <div className="overflow-x-auto rounded-lg">
        <div className="flex items-center justify-between border-b-2 pb-4">
          <LogoPLIcon
            title="Progress Learning logo"
            className="w-20 h-auto cursor-pointer mr-5"
          />
          <div>
            <span className="text-base font-semibold text-primary-violet">
              Liftoff My Galaxies Report
            </span>
          </div>
          <span className="text-gray-400 text-xs">
            {Moment(new Date()).format("MM/DD/yyyy")}
          </span>
        </div>
        <div className="flex">
          {props.filters && (
            <>
              <span className="font-semibold text-sm text-gray-600 m-5 text-xs px-2 mt-2 mb-2">
                Filters -{" "}
              </span>
              <span className=" text-xs rounded-lg px-2 flex mt-2 mb-2">
                {props.filters}
              </span>
            </>
          )}
        </div>

        <table className="text-xs text-left rounded-lg w-full">
          <thead>
            <tr className="text-xs font-normal bg-gray-700 text-center text-white">
              <th className="p-5"></th>
              {props.liftOffHeaders &&
                props.liftOffHeaders.length > 0 &&
                props.liftOffHeaders.map((lf, index) => {
                  return (
                    <th key={index} colSpan={2} className="p-5">
                      <div className="flex flex-col">
                        {lf.header}
                        {!lf.isDiagnosticTestCompleted && (
                          <div className="flex items-center bg-yellow-50 border-yellow-500 inline-block text-yellow-500 mx-auto rounded-sm px-2 py-1 mt-2 ">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              className="w-5 h-5 mr-1"
                            >
                                
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M12 10.5v3.75m-9.303 3.376C1.83 19.126 2.914 21 4.645 21h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 4.88c-.866-1.501-3.032-1.501-3.898 0L2.697 17.626zM12 17.25h.007v.008H12v-.008z"
                              />
                            </svg>
                            No Diagnostic data
                          </div>
                        )}
                      </div>
                    </th>
                  );
                })}
            </tr>
          </thead>
          <tbody>
            {props.standardsEntries.map((entry, stindex) => {
              return (
                <tr key={stindex}>
                  {entry.span > 0 && (
                    <td
                      className={`${
                        entry.galaxyId < props.backgroundColours.length
                          ? props.backgroundColours[entry.galaxyId].light
                          : props.backgroundColours[1].light
                      }  hidden w-10 ${entry.galaxy ? " h-48" : ""}        `}
                      rowSpan={entry.span}
                    >
                      <div className="text-center text-2xl uppercase whitespace-nowrap  w-40 flex justify-center">
                        {entry.galaxy}
                      </div>
                    </td>
                  )}
                  {props.headers &&
                    props.headers.length > 0 &&
                    props.headers.map((header, index) => {
                      return (
                        <Fragment key={index}>
                          {index == 0 ? (
                            <td
                              className={`${
                                entry.galaxyId < props.backgroundColours.length
                                  ? props.backgroundColours[entry.galaxyId]
                                      .light
                                  : props.backgroundColours[1].light
                              }   w-40 text-center text-2xl`}
                            >
                              {entry.showGalaxyLabel ? (
                                <span>{entry.galaxy}</span>
                              ) : (
                                <></>
                              )}
                            </td>
                          ) : (
                            <td className="w-1 bg-gray-700"></td>
                          )}

                          <td
                            className={`relative ${
                              entry.galaxyId < props.backgroundColours.length
                                ? props.backgroundColours[entry.galaxyId]?.dark
                                : props.backgroundColours[1]?.dark
                            }`}
                          >
                            {entry[header]?.isDisplayRocket &&
                              entry[header].isDisplayRocket && (
                                <div className="pt-8">
                                  <img
                                    src={`${Configuration.S3bucketImagePath}/images/teacher/arena/rockets/rocket190001.png`}
                                    className="hidden absolute w-8 top-1 -left-4"
                                    alt="Liftoff rocket"
                                  ></img>
                                </div>
                              )}
                            {entry[header]?.standardName && (
                              <>
                                {entry[header]?.isRocket && (
                                  <img
                                    src={`${Configuration.S3bucketImagePath}/images/teacher/arena/rockets/rocket190001.png`}
                                    className="hidden absolute w-8 top-1 -left-4 space-x-1"
                                    alt="Liftoff rocket"
                                  ></img>
                                )}
                                {entry[header] &&
                                  entry[header].isStandardDisable && (
                                    <div
                                      className={props.getStandardVisibilty(
                                        entry[header]?.earnedStar,
                                        true
                                      )}
                                    >
                                      {entry[header]?.earnedStar > 0 && (
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          className="w-6 h-6 text-yellow-300 flex-none w-4"
                                          fill="yellow"
                                          viewBox="0 0 24 24"
                                          stroke="currentColor"
                                        >
                                          <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="2"
                                            d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"
                                          />
                                        </svg>
                                      )}
                                      {entry[header]?.earnedStar === 0 && (
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          className="w-6 h-6 text-yellow-300 flex-none w-4 invisible"
                                          fill="yellow"
                                          viewBox="0 0 24 24"
                                          stroke="currentColor"
                                        >
                                          <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="2"
                                            d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"
                                          />
                                        </svg>
                                      )}
                                      <span className="opacity-90">
                                        {entry[header]?.standardName}
                                      </span>
                                    </div>
                                  )}
                                {entry[header] &&
                                  !entry[header].isStandardDisable && (
                                    <div
                                      className={props.getStandardVisibilty(
                                        entry[header]?.earnedStar,
                                        false
                                      )}
                                    >
                                      {entry[header]?.earnedStar > 0 && (
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          className="w-6 h-6 text-yellow-300 flex-none w-4"
                                          fill="yellow"
                                          viewBox="0 0 24 24"
                                          stroke="currentColor"
                                        >
                                          <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="2"
                                            d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"
                                          />
                                        </svg>
                                      )}
                                      {entry[header]?.earnedStar === 0 && (
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          className="w-6 h-6 text-yellow-300 flex-none w-4 invisible"
                                          fill="yellow"
                                          viewBox="0 0 24 24"
                                          stroke="currentColor"
                                        >
                                          <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="2"
                                            d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"
                                          />
                                        </svg>
                                      )}
                                      <span
                                        className={
                                          entry[header]?.earnedStar > 0
                                            ? "opacity-90"
                                            : ""
                                        }
                                      >
                                        {entry[header]?.standardName}
                                      </span>
                                    </div>
                                  )}
                              </>
                            )}
                          </td>
                        </Fragment>
                      );
                    })}
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className="flex justify-between gap-x-6 mt-4">
          <span className="px-2 flex text-xs items-center">
            {"Created By Progress Learning, LLC "}
            {Moment(new Date()).format("yyyy")} {" Printed by "}
            {props.lastName + (props.firstName ? " " + props.firstName : "")}
            {"."}
          </span>
        </div>
      </div>
    </div>
  );
}

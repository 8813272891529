//import { Configuration } from "../environment/setup";
import BaseApi from "./baseApi";

/*export interface  UrlParams  {     
    userId: string,
    roleId: string,
    schoolId: string,
    userName: string,
    password: string,
    displayName: string,
    avatar: string
}*/
class UrlAuthApi extends BaseApi {
  public constructor() {
    super();
  }

  async GetURLParams(userId: number) {
    //  let result =  await this.api.get(`${Configuration.ApiBaseUrl}UrlAuth?userId=${userId}`);
    //  return await result.data;
    return new Promise<Array<any>>(() => []);
  }
}

export default new UrlAuthApi();

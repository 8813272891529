import React, { Fragment } from "react";
import { connect } from "react-redux";
import helptData from "../../mockData/help.json";
import Profile from "../../model/users/profile";
import { UserContextState } from "../../redux/actions/userContextAction";
import Header from "../shared/header/header";
import HelpCard from "./helpCard";

interface passedProps {
  userContext: UserContextState;
  profile: Profile;
}

function Help(props: passedProps) {
  const helpOptions = helptData.Help;

  function isShowContactUs(heading) {
    let isShow = true;
    if (heading === "Contact Us" && props.profile.isUSATESTEnabled) {
      if (!props.profile.isEGEnabled && props.profile.isLFEnabled) {
        isShow = false;
      }
    }
    return isShow;
  }

  return (
    <Fragment>
      <Header></Header>
      <div className="px-4 lg:px-8 mt-3 pt-6 xl:w-4/5 2xl:w-4/5 m-auto">
        <h2 className="text-2xl text-primary-green font-semibold">Help</h2>
        <div className="flex flex-wrap mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-2">
          {helpOptions &&
            helpOptions.map((option) => {
              return (
                <Fragment>
                  {isShowContactUs(option.heading) && (
                    <HelpCard
                      title={option.heading}
                      description={option.description}
                      url={option.url}
                      css={option.css}
                    ></HelpCard>
                  )}
                </Fragment>
              );
            })}
        </div>
      </div>
    </Fragment>
  );
}

const mapStateToProps = (state: any) => {
  return {
    profile: state.profile,
    userContext: state.userContext,
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(Help);

import XIcon from "@heroicons/react/outline/XIcon";
import React, { Fragment, useEffect, useState } from "react";
import InfoMessage from "../../shared/infoMessage";
import { ClassApi } from "../../../api/teacher/classManagementApi";
import PagingResponse from "../../../model/common/pagingResponse";
import classListResponse from "../../../model/teacher/classManagement/classListResponse";
import { connect } from "react-redux";
import PageSize from "../../shared/pagination/pageSize";
import Pagination from "../../shared/pagination/pagination";
import {
  DEFAULT_PAGE_NUMBER,
  DEFAULT_PAGE_SIZE,
} from "../../../utils/pagingConstant";
import ClassSearchDTO from "../../../model/teacher/classManagement/classSearchModel";
import Loader from "../../shared/loader";
import { MinusCircleIcon, PlusCircleIcon } from "@heroicons/react/solid";
import ClassStudentModel from "../../../model/teacher/classManagement/classStudentModel";

interface TeacherClassesProps {
  showPopUp: (show: boolean) => void;
  userId: number;
  name: string;
  userContext?: any;
}

const TeacherClasses = (props: TeacherClassesProps) => {
  const [loading, showLoading] = useState<boolean>(false);
  const [classes, setClasses] = useState<PagingResponse<classListResponse>>();
  const [pageNumber, setPageNumber] = useState(DEFAULT_PAGE_NUMBER);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [resetPageNumber, setResetPageNumber] = useState<boolean>(false);
  const [pageNumberStudent, setPageNumberStudent] =
    useState(DEFAULT_PAGE_NUMBER);
  const [pageSizeStudent, setPageSizeStudent] = useState(DEFAULT_PAGE_SIZE);
  const [resetPageNumberStudent, setResetPageNumberStudent] =
    useState<boolean>(false);
  const [showClassDetailsClassId, setShowClassDetailsClassId] =
    useState<number>(0);
  const [students, setStudents] = useState<PagingResponse<ClassStudentModel>>();

  useEffect(() => {
    const classSearch: ClassSearchDTO = {
      pageNumber: pageNumber,
      pageSize: pageSize,
      showArchive: false,
      showCurrent: true,
      showHidden: false,
      userId: props.userId,
      schoolId: props.userContext.schoolId,
      schoolAccountId: props.userContext.schoolAccountId,
      teacherUserId: props.userId
    };
    getClassList(classSearch);
  }, [pageSize, pageNumber]);

  function getClassList(classSearch: ClassSearchDTO) {
    showLoading(true);
    const response = ClassApi.getClassList(classSearch);
    response
      ?.then((d) => {
        setClasses(d.data);
        showLoading(false);
      })
      .catch(() => {
        showLoading(false);
      });
  }
  const handleShowClassStudents = (classId: number) => {
    ClassApi.getClassStudent(classId, pageNumberStudent, pageSizeStudent).then(
      (d) => {
        setStudents(d.data);
        setShowClassDetailsClassId(classId);
      }
    );
  };
  const handleHideClassStudents = (classId: number) => {
    setShowClassDetailsClassId(0);
    setPageNumberStudent(DEFAULT_PAGE_NUMBER);
  };
  const onPageChange = (pageNo) => {
    setPageNumber(pageNo);
  };

  const onPageSizeChange = (pageSize) => {
    setPageNumber(DEFAULT_PAGE_NUMBER);
    setPageSize(pageSize);
    setResetPageNumber(true);
  };

  const onPageChangeStudent = (pageNo) => {
    setPageNumberStudent(pageNo);
  };

  const onPageSizeChangeStudent = (pageSize) => {
    setPageNumberStudent(DEFAULT_PAGE_NUMBER);
    setPageSizeStudent(pageSize);
    setResetPageNumberStudent(true);
  };

  const handleToggleClassStudents = (classId: number) => {
    setPageNumberStudent(DEFAULT_PAGE_NUMBER);
    handleShowClassStudents(classId);
  };

  useEffect(() => {
    handleShowClassStudents(showClassDetailsClassId);
  }, [pageSizeStudent, pageNumberStudent]);

  return (
    <div className="fixed inset-0 z-40 flex items-center bg-black bg-opacity-50 sm:items-center sm:justify-center">
      <div
        className="w-full flex flex-col justify-between overflow-hidden h-auto max-h-80percent bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:max-w-5xl"
        role="dialog"
      >
        <header>
          {/* Header */}
          <div className="px-4 py-4 bg-gray-50 sm:px-4">
            <div className="flex items-start justify-between space-x-3">
              <div className="space-y-1">
                <div className="text-lg font-medium text-gray-900">
                  Teacher Classes
                </div>
              </div>
              <div className="h-7 flex items-center">
                <button
                  type="button"
                  className="text-gray-400 hover:text-gray-500"
                  onClick={() => props.showPopUp(false)}
                >
                  <span className="sr-only">Close panel</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </header>
        <div className="w-full relative overflow-auto px-4 py-3">
          <div className="flex-1">
            <div className="p-1">
              <InfoMessage
                message={
                  "List of all the classes assoicated to teacher " + props.name
                }
              />
            </div>

            {/* <!-- Divider container -->  */}
            <div className="text-sm text-gray-700 px-4">
              <div>
                <div className="mb-4 pb-4 border-b-2 overflow-x-auto ">
                  <table className="table w-full relative">
                    <thead className="mb-5">
                      <tr className="bg-light-violet text-white py-3 px-3 overflow-hidden">
                        <th className="text-left p-3 font-normal w-4"></th>
                        <th className="text-left p-3 font-normal w-8"></th>
                        <th className="text-center p-3 text-sm font-normal w-48">
                          Class Name
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {loading && <Loader />}
                      {classes?.data.map((classDetails, index) => {
                        return (
                          <Fragment>
                            <tr
                              key={classDetails.classId}
                              className={`hover:bg-light-violet/20 transition ease-in-out duration-300 ${
                                classDetails.classId === showClassDetailsClassId
                                  ? "bg-blue-50"
                                  : index % 2 === 0
                                  ? "bg-white"
                                  : "bg-light-violet/10"
                              }`}
                            >
                              <td className="pl-2">
                                {classDetails.classId !==
                                  showClassDetailsClassId && (
                                  <PlusCircleIcon
                                    className="w-4 text-gray-500 cursor-pointer"
                                    onClick={() => {
                                      handleToggleClassStudents(
                                        classDetails.classId
                                      );
                                    }}
                                  />
                                )}
                                {classDetails.classId ===
                                  showClassDetailsClassId && (
                                  <MinusCircleIcon
                                    className="w-4 text-gray-500 cursor-pointer"
                                    onClick={() => {
                                      handleHideClassStudents(
                                        classDetails.classId
                                      );
                                    }}
                                  />
                                )}
                              </td>
                              <td
                                className="w-4 h-4 pl-3"
                                data-tip="Class color"
                              >
                                <div
                                  className="rounded-full w-5 h-5 border border-gray-200"
                                  style={{
                                    backgroundColor: classDetails.colorCode,
                                  }}
                                >
                                  {" "}
                                </div>
                              </td>
                              <td className="text-sm mt-5  p-3 text-center">
                                {classDetails.name}
                              </td>
                            </tr>
                            {classDetails.classId ===
                              showClassDetailsClassId && (
                              <tr className="bg-blue-50">
                                <td></td>
                                <td colSpan={2}>
                                  <div className="shadow border rounded-md mr-4">
                                    <table className="min-w-full">
                                      <thead>
                                        <tr className="bg-gray-50 border">
                                          <th className="px-3 py-2 pl-6 font-semibold text-xs text-left  tracking-wider">
                                            Name
                                          </th>
                                          <th className="px-3 py-2 pl-6 font-semibold text-xs text-left  tracking-wider">
                                            Username
                                          </th>
                                          <th className="px-3 py-2 pl-6 font-semibold text-xs text-left  tracking-wider">
                                            Student Id
                                          </th>
                                          <th className="px-3 py-2 pl-6 font-semibold text-xs text-left  tracking-wider">
                                            Grade
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody className="divide-y divide-gray-50 bg-white">
                                        {students !== undefined &&
                                          students?.data.length > 0 &&
                                          students?.data.map(
                                            (student, index) => {
                                              return (
                                                <Fragment>
                                                  <tr key={student.userId}>
                                                    <td className="px-3 py-2  pl-6 whitespace-nowrap text-sm text-left font-normal text-gray-500">
                                                      {student.lastName},{" "}
                                                      {student.firstName}
                                                    </td>
                                                    <td className="px-3 py-2  pl-6 whitespace-nowrap text-sm text-left font-normal text-gray-500">
                                                      {student.username}
                                                    </td>
                                                    <td className="px-3 py-2  pl-6 whitespace-nowrap text-sm text-left font-normal text-gray-500">
                                                      {student.studentId}
                                                    </td>
                                                    <td className="px-3 py-2  pl-6 whitespace-nowrap text-sm text-left font-normal text-gray-500">
                                                      {student.grade}
                                                    </td>
                                                  </tr>
                                                </Fragment>
                                              );
                                            }
                                          )}
                                        {students !== undefined &&
                                          students.totalRecords === 0 && (
                                            <tr>
                                              <td
                                                colSpan={4}
                                                className="px-3 py-2  pl-6 whitespace-nowrap text-sm text-left font-normal text-gray-500"
                                              >
                                                No record found
                                              </td>
                                            </tr>
                                          )}
                                      </tbody>
                                    </table>
                                  </div>
                                  <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between mt-2 mr-4 mb-4">
                                    {students?.data !== undefined &&
                                      students?.totalRecords !== 0 && (
                                        <Pagination
                                          totalRecords={students?.totalRecords}
                                          pageSize={pageSizeStudent}
                                          onPageChange={onPageChangeStudent}
                                          reset={resetPageNumberStudent}
                                          pageNumber={pageNumberStudent}
                                        />
                                      )}
                                    {students?.totalRecords !== 0 && (
                                      <PageSize
                                        pageSize={pageSizeStudent}
                                        onPageSizeChange={
                                          onPageSizeChangeStudent
                                        }
                                      />
                                    )}
                                  </div>
                                </td>
                              </tr>
                            )}
                          </Fragment>
                        );
                      })}
                      {classes?.totalRecords === 0 && (
                        <tr>
                          <td colSpan={2} className=" mt-5 p-3 text-left">
                            No record found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>

                <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between mt-2">
                  {classes?.totalRecords !== undefined &&
                    classes?.totalRecords !== 0 && (
                      <Pagination
                        totalRecords={classes?.totalRecords}
                        pageSize={pageSize}
                        onPageChange={onPageChange}
                        reset={resetPageNumber}
                        pageNumber={pageNumber}
                      />
                    )}
                  {classes?.totalRecords !== 0 && (
                    <PageSize
                      pageSize={pageSize}
                      onPageSizeChange={onPageSizeChange}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Action Buttons -->  */}
        <footer className="flex items-center mt-4 justify-end px-6 py-4 sm:flex-row bg-gray-50 dark:bg-gray-800">
          <div className="flex-shrink-0">
            <div className="space-x-3 flex justify-end">
              <button
                className="align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 rounded-lg text-sm text-gray-600 border-gray-300 border dark:text-gray-400 focus:outline-none active:bg-transparent hover:border-gray-500 focus:border-gray-500 active:text-gray-500 focus:shadow-outline-gray w-full sm:w-auto"
                type="button"
                onClick={() => {
                  props.showPopUp(false);
                }}
              >
                Ok
              </button>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(TeacherClasses);

// unused
import { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  DotDateIcon,
  GoldCoinIcon,
  GraphIcon,
  LeftArrowIcon,
} from "../../../../../assets/icons/index";
import RouteConstant from "../../../../../utils/constant/routeConstant";
import { useTranslation } from "react-i18next";
import GoogleSignInModal from "../../../popup/googleSignInModal";
import Constant from "../../../../../utils/constant/constant";

const Home = (props: any) => {
  const { t } = useTranslation();
  const [showGooglePopup, setShowGooglePopup] = useState(false);

  useEffect(() => {
    if (
      props.userContext?.userId &&
      props.profile?.userId &&
      props.userContext?.userId! > 0 &&
      props.profile?.userId > 0
    ) {
      const showPopup =
        window.localStorage.getItem("showGoogleSignInPopup")! === "true";
      if (
        props.profile?.googleUser?.refreshToken === null &&
        showPopup &&
        props.profile?.gmailId &&
        props.userContext?.impersonatedUser === null
      ) {
        setShowGooglePopup(true);
      }
    }
  }, [props.userContext?.userId]);

  return (
    <Fragment>
      <div className="min-w-0 w-full bg-gradient-to-b from-secondary-teal to-teal-light">
        <div className="sm:px-6  lg:px-13 py-8 relative">
          <div className="w-3/5 absolute -left-80 top-0 opacity-10 h-16 transform -rotate-45 bg-gradient-to-r from-white to-transparent z-20 "></div>
          <div className="w-1/5 absolute left-60 top-0 opacity-10 h-10 transform -rotate-45 bg-gradient-to-r from-white to-transparent z-20 "></div>
          <div className="flex flex-col xl:flex-row xl:space-x-7 2xl:space-x-12">
            <div className="w-full xl:w-2/6">
              <div className="w-full rounded-lg bg-white shadow-lg overflow-hidden mb-12">
                <div className="flex bg-gradient-to-r from-gray-700 to-gray-500 rounded-t-lg rounded-b-none px-6 py-5 capitalize text-white text-lg  font-medium">
                  <span className="bg-white w-8 h-8 rounded-lg flex items-center justify-center mr-4 text-sm">
                    <GraphIcon className="w-6 stroke-current text-yellow-600"></GraphIcon>
                  </span>
                  Sample's Progress
                </div>
                <table className="table-auto w-full bg-gradient-to-b from-white via-white to-gray-200">
                  <thead>
                    <tr className="bg-gray-200">
                      <th className="text-sm font-semibold text-dark-violet text-left pl-5 pr-3 py-3">
                        Subject
                      </th>
                      <th className="text-sm font-semibold text-green-800 bg-green-200 text-center px-3 py-3">
                        Green Dots
                      </th>
                      <th className="text-sm font-semibold text-dark-violet text-center pl-3 pr-5 py-3">
                        Progress Report
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="group hover:bg-lightest-violet cursor-pointer hover:shadow text-gray-700">
                      <td className="text-left text-sm font-semibold pl-5 pr-3 py-4 group-hover:text-dark-violet">
                        11th Grade ELA SBAC
                      </td>
                      <td className="text-left text-sm font-semibold px-3 py-4 bg-green-200 group-hover:bg-lightest-violet">
                        <div className="flex items-center justify-center text-left">
                          <span className="w-4 h-4 mr-1 rounded-3xl bg-green-400 inline-block border border-gray-700"></span>
                          0 / 26
                        </div>
                      </td>
                      <td className="text-center text-sm font-semibold px-3 py-2 cursor-pointer">
                        <a
                          href="#null"
                          className="text-sm text-blue-500 underline hover:no-underline"
                        >
                          View
                        </a>
                      </td>
                    </tr>
                    <tr className="group hover:bg-lightest-violet cursor-pointer hover:shadow">
                      <td className="text-left text-sm font-semibold pl-5 pr-3 py-4 group-hover:text-dark-violet">
                        11th Grade ELA SBAC
                      </td>
                      <td className="text-left text-sm font-semibold pl-5 pr-3 py-4 bg-green-200 group-hover:bg-lightest-violet">
                        <div className="flex items-center justify-center text-left">
                          <span className="w-4 h-4 mr-1 rounded-3xl bg-green-400 inline-block border border-gray-700"></span>
                          0 / 26
                        </div>
                      </td>
                      <td className="text-center text-sm font-semibold px-3 py-2 cursor-pointer">
                        <a
                          href="#null"
                          className="text-sm text-blue-500 underline hover:no-underline"
                        >
                          View
                        </a>
                      </td>
                    </tr>
                    <tr className="group hover:bg-lightest-violet cursor-pointer hover:shadow">
                      <td className="text-left text-sm font-semibold pl-5 pr-3 py-4 group-hover:text-dark-violet">
                        11th Grade ELA SBAC
                      </td>
                      <td className="text-left text-sm font-semibold pl-5 pr-3 py-4 bg-green-200 group-hover:bg-lightest-violet">
                        <div className="flex items-center justify-center text-left">
                          <span className="w-4 h-4 mr-1 rounded-3xl bg-green-400 inline-block border border-gray-700"></span>
                          0 / 26
                        </div>
                      </td>
                      <td className="text-center text-sm font-semibold px-3 py-2 cursor-pointer">
                        <a
                          href="#null"
                          className="text-sm text-blue-500 underline hover:no-underline"
                        >
                          View
                        </a>
                      </td>
                    </tr>
                    <tr className="group hover:bg-lightest-violet cursor-pointer hover:shadow">
                      <td className="text-left text-sm font-semibold pl-5 pr-3 py-4 group-hover:text-dark-violet">
                        11th Grade ELA SBAC
                      </td>
                      <td className="text-left text-sm font-semibold pl-5 pr-3 py-4 bg-green-200 group-hover:bg-lightest-violet">
                        <div className="flex items-center justify-center text-left">
                          <span className="w-4 h-4 mr-1 rounded-3xl bg-green-400 inline-block border border-gray-700"></span>
                          0 / 26
                        </div>
                      </td>
                      <td className="text-center text-sm font-semibold px-3 py-2 cursor-pointer">
                        <a
                          href="#null"
                          className="text-sm text-blue-500 underline hover:no-underline"
                        >
                          View
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="flex flex-row gap-10">
                <div className="w-full rounded-lg overflow-hidden shadow-lg mb-12 xl:mb-0">
                  <Link to={RouteConstant.MiddleSchool.assignments}>
                    <div className="bg-gradient-to-r from-blue-900 to-blue-700 px-6 py-5">
                      <div className="capitalize text-white text-lg font-medium text-center">
                        {t("MiddleSchool.AssignmentActivites")}
                      </div>
                    </div>
                  </Link>

                  <div className="bg-gradient-to-b from-white via-white to-blue-100 px-6 py-5">
                    <Link to={RouteConstant.MiddleSchool.assignments}>
                      <div className="flex justify-between items-center cursor-pointer hover:bg-primary-violet hover:shadow-xl group rounded-lg transition-all mb-5">
                        <span className="inline-block text-lg text-white bg-primary-violet w-16 py-2.5 rounded-lg mr-5 text-center">
                          26
                        </span>
                        <span className="font-semibold text-sm text-gray-700 mr-auto group-hover:text-white">
                          View Not Started
                        </span>
                        <LeftArrowIcon className="cursor-pointer h-7 w-7 stroke-current text-gray-700 mr-4 group-hover:text-white"></LeftArrowIcon>
                      </div>
                    </Link>
                    <Link to={RouteConstant.MiddleSchool.assignments}>
                      <div className="flex justify-between items-center cursor-pointer hover:bg-green-500 hover:shadow-xl group rounded-lg transition-all mb-5">
                        <span className="inline-block text-lg text-white bg-green-500 w-16 py-2.5 rounded-lg mr-5 text-center">
                          13
                        </span>
                        <span className="font-semibold text-sm text-gray-700 mr-auto group-hover:text-white">
                          View Saved
                        </span>
                        <LeftArrowIcon className="cursor-pointer h-7 w-7 stroke-current text-gray-700 mr-4 group-hover:text-white"></LeftArrowIcon>
                      </div>
                    </Link>
                    <Link to={RouteConstant.MiddleSchool.assignments}>
                      <div className="flex justify-between items-center cursor-pointer hover:bg-blue-900 hover:shadow-xl group rounded-lg transition-all mb-5">
                        <span className="inline-block text-lg text-white bg-blue-900 w-16 py-2.5 rounded-lg mr-5 text-center">
                          164
                        </span>
                        <span className="font-semibold text-sm text-gray-700 mr-auto group-hover:text-white">
                          View Completed
                        </span>
                        <LeftArrowIcon className="cursor-pointer h-7 w-7 stroke-current text-gray-700 mr-4 group-hover:text-white"></LeftArrowIcon>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full xl:w-3/6">
              <div className="w-full rounded-lg overflow-hidden bg-white shadow-lg mb-12 xl:mb-0">
                <div className="flex flex-col md:flex-row md:justify-between bg-gradient-to-r from-gray-700 to-gray-500 rounded-t-lg rounded-b-none px-6 py-5">
                  <div className="flex items-center mb-2 md:mb-0">
                    <DotDateIcon className="w-9 h-9 fill-current text-white mr-3"></DotDateIcon>
                    <span className="capitalize text-white text-lg font-medium">
                      due this week
                    </span>
                  </div>
                  <div className="flex items-center mb-2 md:mb-0">
                    <div className="flex items-center w-6 h-6 rounded-full bg-yellow-600 text-white text-sm justify-center mr-3">
                      25
                    </div>
                    <span className="capitalize text-white text-sm font-medium">
                      overdue assignments
                    </span>
                  </div>
                  <div className="flex items-center mb-2 md:mb-0">
                    <span className="capitalize text-white text-lg font-medium mr-3">
                      December
                    </span>
                    <span className="flex">
                      <LeftArrowIcon className="text-white w-6 h-6 transform rotate-180 cursor-pointer hover:opacity-70"></LeftArrowIcon>
                      <LeftArrowIcon className="text-white w-6 h-6 cursor-pointer hover:opacity-70"></LeftArrowIcon>
                    </span>
                  </div>
                </div>
                <div className="flex w-full pr-2 min-h-8rem bg-yellow-50">
                  <div className="flex flex-col items-center justify-center text-center border-gray-200 border-r-2 px-6">
                    <div className="bg-yellow-600 text-white w-11 inline-block h-8 text-lg font-medium text-center py-1 mb-1">
                      24
                    </div>
                    <div className="w-full text-lg inline-block text-gray-700 font-medium">
                      Fri
                    </div>
                  </div>
                  <div className="flex w-full justify-start flex-wrap items-start xl:overflow-y-auto xl:max-h-40 pt-3 px-3">
                    <div className="text-sm font-semibold text-gray-700 px-3 py-3 leading-extra">
                      <span className="line-clamp-1">No assignments.</span>
                    </div>
                  </div>
                </div>
                <div className="flex w-full pr-2 mh-8 bg-white">
                  <div className="flex flex-col items-center justify-center text-center ">
                    <div className=" flex py-2 flex-col items-center justify-center text-center border-r-2 border-gray-200 px-6">
                      <div className="bg-gray-200 text-gray-700 w-11 inline-block h-8 text-lg font-medium text-center py-1 mb-1">
                        25
                      </div>
                      <div className="w-full text-lg inline-block text-gray-700 font-medium">
                        Sat
                      </div>
                    </div>
                    <div className=" flex py-2 flex-col items-center justify-center text-center border-r-2 border-gray-200 px-6">
                      <div className="bg-gray-200 text-gray-700 w-11 inline-block h-8 text-lg font-medium text-center py-1 mb-1">
                        26
                      </div>
                      <div className="w-full text-lg inline-block text-gray-700 font-medium">
                        Sun
                      </div>
                    </div>
                  </div>
                  <div className="flex w-full justify-start flex-wrap items-start xl:overflow-y-auto xl:max-h-40 pt-3 px-3">
                    <div
                      className="cursor-pointer hover:bg-opacity-70 inline-block bg-blue-200 text-sm font-semibold text-gray-700 px-3 py-3 rounded mr-2 mb-2 w-36 xxs:w-48 xs:w-56 shadow-lg"
                      title="Algebra I Practice Video"
                    >
                      <span className="line-clamp-1">
                        Algebra I Practice Video
                      </span>
                    </div>
                  </div>
                </div>
                <div className="flex w-full pr-2 min-h-8rem bg-gray-50">
                  <div className="flex flex-col items-center justify-center text-center border-gray-200 border-r-2 px-6">
                    <div className="bg-gray-200 text-gray-700 w-11 inline-block h-8 text-lg font-medium text-center py-1 mb-1">
                      27
                    </div>
                    <div className="w-full text-lg inline-block text-gray-700 font-medium">
                      Mon
                    </div>
                  </div>
                  <div className="flex w-full justify-start flex-wrap items-start xl:overflow-y-auto xl:max-h-40 pt-3 px-3">
                    <div className="text-sm font-semibold text-gray-700 px-3 py-3 leading-extra">
                      <span className="line-clamp-1">No assignments.</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full xl:w-80 flex flex-col">
              <div className="flex justify-between bg-gradient-to-r from-gray-700 to-gray-500 rounded-lg overflow-hidden px-6 py-5 shadow-lg lg:mr-1">
                <div className="flex w-24 items-center flex-col">
                  <span className="text-2xl font-bold text-white mb-1">
                    1039
                  </span>
                  <span className="text-sm text-white">Tokens</span>
                  <div className="relative w-20 h-10 inline-block mt-2">
                    <span className="inline-block absolute left-0">
                      <GoldCoinIcon className="w-7 h-7"></GoldCoinIcon>
                    </span>
                    <span className="inline-block absolute left-4">
                      <GoldCoinIcon className="w-7 h-7"></GoldCoinIcon>
                    </span>
                    <span className="inline-block absolute left-8">
                      <GoldCoinIcon className="w-7 h-7"></GoldCoinIcon>
                    </span>
                    <span className="inline-block absolute left-12">
                      <GoldCoinIcon className="w-7 h-7"></GoldCoinIcon>
                    </span>
                  </div>
                </div>
                <div className="flex flex-col">
                  <h2 className="text-lg font-medium text-white mb-2">
                    Time on site
                  </h2>
                  <div className="flex text-white text-sm mb-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-6 h-6 mr-1 fill-current text-white"
                      viewBox="0 0 32 32"
                    >
                      <path d="M 16 4 C 9.382813 4 4 9.382813 4 16 C 4 22.617188 9.382813 28 16 28 C 22.617188 28 28 22.617188 28 16 C 28 9.382813 22.617188 4 16 4 Z M 16 6 C 21.535156 6 26 10.464844 26 16 C 26 21.535156 21.535156 26 16 26 C 10.464844 26 6 21.535156 6 16 C 6 10.464844 10.464844 6 16 6 Z M 15 8 L 15 17 L 22 17 L 22 15 L 17 15 L 17 8 Z"></path>
                    </svg>{" "}
                    <span className="font-bold mr-1">0</span>hours
                  </div>
                  <div className="flex text-white text-sm">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-6 h-6 mr-1 fill-current text-white"
                      viewBox="0 0 32 32"
                    >
                      <path d="M 16 4 C 10.421875 4 5.742188 7.832031 4.40625 13 L 6.46875 13 C 7.746094 8.945313 11.53125 6 16 6 C 21.515625 6 26 10.484375 26 16 C 26 21.515625 21.515625 26 16 26 C 11.53125 26 7.746094 23.054688 6.46875 19 L 4.40625 19 C 5.742188 24.167969 10.421875 28 16 28 C 22.617188 28 28 22.617188 28 16 C 28 9.382813 22.617188 4 16 4 Z M 15.34375 11.28125 L 13.90625 12.71875 L 16.1875 15 L 4 15 L 4 17 L 16.1875 17 L 13.90625 19.28125 L 15.34375 20.71875 L 19.34375 16.71875 L 20.03125 16 L 19.34375 15.28125 Z"></path>
                    </svg>
                    <span className="font-bold mr-1">1128</span>logins
                  </div>
                </div>
              </div>
              <div className=" mt-10 rounded-lg bg-gradient-to-r from-blue-900 to-blue-700 px-6 py-5 hover:bg-opacity-70">
                <Link to={RouteConstant.MiddleSchool.subjects}>
                  <div className="capitalize text-white text-lg font-medium text-center">
                    {t("MiddleSchool.MyStudyPlan")}
                  </div>
                </Link>
              </div>

              <div className="hidden mt-10 rounded-lg overflow-hidden shadow-lg mb-12 xl:mb-0">
                <div className="bg-gradient-to-r from-blue-900 to-blue-700 px-6 py-5">
                  <a href={RouteConstant.ARENA_MIDDLE_SCHOOL}>
                    <div className="capitalize text-white text-lg font-medium text-center">
                      Alien Arena
                    </div>
                  </a>
                </div>
              </div>

              <div className="mt-10 rounded-lg overflow-hidden shadow-lg mb-12 xl:mb-0">
                <div className="bg-gradient-to-r from-blue-900 to-blue-700 px-6 py-5">
                  <Link to={RouteConstant.MiddleSchool.TrohpyRoom}>
                    <div className="capitalize text-white text-lg font-medium text-center">
                      Trophy Room
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showGooglePopup && (
        <GoogleSignInModal
          roleId={Constant.UserRoleId.Student}
          returnUrl={""}
          showPopUp={setShowGooglePopup}
          mode={Constant.GoogleSignInMode.SSO}
        />
      )}
    </Fragment>
  );
};

export default Home;

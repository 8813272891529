import { AxiosResponse } from "axios";
import { AxiosHelper } from "../../environment/axiosConfig";
import { ISchool } from "../../model/interface/school";
import APIConstant from "../../utils/constant/apiConstant";
import qs from "qs";
import { ISchoolYear } from "../../model/teacher/schoolYear";

export function getSchoolByDistrictId(
  districtId: number,
  subjectId: number = 0
): Promise<AxiosResponse<Array<ISchool>>> {
  return AxiosHelper.axiosInstance().get<Array<ISchool>>(
    APIConstant.APP_SCHOOLS_BYDISTRICT,
    { params: { districtId: districtId, subjectId: subjectId } }
  );
}
export function getSchoolStudentsBySchoolAccountId(
  schoolAccountId: number
): Promise<AxiosResponse<Array<any>>> {
  return AxiosHelper.axiosInstance().get<Array<any>>(
    APIConstant.APP_SCHOOLS_STUDENTS,
    { params: { schoolAccountId: schoolAccountId } }
  );
}
export function getSchoolSubjectsBySchoolAccountId(
  schoolAccountIds: Array<number>
): Promise<AxiosResponse<Array<any>>> {
  return AxiosHelper.axiosInstance().get<Array<any>>(
    APIConstant.APP_SCHOOLS_SUBJECTS,
    {
      params: {
        schoolAccountIds: schoolAccountIds,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    }
  );
}

export function getSchoolSubjectsBySchoolSchoolId(
  schoolId: number
): Promise<AxiosResponse<any>> {
  return AxiosHelper.axiosInstance().get<any>(APIConstant.APP_SCHOOLS_GETBYID, {
    params: { schoolId: schoolId },
  });
}

export function getSchoolSubscriptionSubjects(
  districtId: number,
  subjectId: number,
  startDate: string,
  endDate: string
): Promise<AxiosResponse<Array<any>>> {
  return AxiosHelper.axiosInstance().get(
    APIConstant.APP_SCHOOLSUBSCRIPTION_GETBYSUBJECTS,
    {
      params: {
        districtId: districtId,
        subjectId: subjectId,
        startDate: startDate,
        endDate: endDate,
      },
    }
  );
}
export function GetSchoolYears(
  schoolId: number
): Promise<AxiosResponse<Array<ISchoolYear>>> {
  return AxiosHelper.axiosInstance().get<Array<ISchoolYear>>(
    APIConstant.APP_SCHOOL_YEARS,
    {
      params: { schoolId: schoolId },
    }
  );
}

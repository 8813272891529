import { Fragment, useEffect, useState } from "react";

import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { getVideoAssignment } from "../../../../../../api/student/studentVideoAssignmentApi";

import {
  IDomainVideoStandardGroup,
  IStudentVideoAssignment,
} from "../../../../../../model/interface/studentVideoAssignment";
import Profile from "../../../../../../model/users/profile";
import { UserContextState } from "../../../../../../redux/actions/userContextAction";
import constant from "../../../../../../utils/constant/constant";
import routeConstant from "../../../../../../utils/constant/routeConstant";
import useTextToSpeech from "../../../shared/textToSpeech/useTextToSpeech";
//import Breadcrumb from "../../../middleSchool/breadcrumb";

interface IVideoQuestion {
  match?: any;
  userContext?: UserContextState;
  profile?: Profile;
  subjectId: number;
  subjectName: string;
  contentAreaId: number;
}

function VideoQuestionList(props: IVideoQuestion) {
  const passingScore = constant.VIDEO_PASSING_SCORE;
  const history = useHistory();

  const [standardGroups, setStandardGroups] = useState<
    Array<IDomainVideoStandardGroup>
  >([]);
  const { startSpeaking, stopSpeaking } = useTextToSpeech();

  useEffect(() => {
    getVideoAssignment(props.userContext?.userId ?? 0, props.subjectId, 0).then(
      (d) => {
        convertToStandardGroup(d.data);
      }
    );
  }, []);

  function convertToStandardGroup(videos: Array<IStudentVideoAssignment>) {
    const domains = Array.from(new Set(videos.map((item) => item.domainId)));
    var tempStandardGroup: Array<IDomainVideoStandardGroup> = [];
    var index: number = 1;
    domains.forEach((d) => {
      var domain = videos.find((t) => t.domainId === d);
      var standardGroup: IDomainVideoStandardGroup = {
        domainName:
          props.profile?.plLanguageId === constant.Languages.Spanish
            ? domain?.spanishDomainName ?? domain?.domainName ?? ""
            : domain?.domainName ?? "",
        videos: videos.filter((s) => s.domainId === d),
      };
      standardGroup.videos.forEach((std) => {
        std.index = index;
        index++;
      });
      tempStandardGroup.push(standardGroup);
    });
    setStandardGroups(tempStandardGroup);
  }

  function getStatus(a: IStudentVideoAssignment) {
    switch (a.assignmentStatusId) {
      case 0:
        return "Not Started";
      case 2:
        return "Continue";
      case 3:
        return a.score >= passingScore
          ? "Complete - " + a.score.toString() + "%"
          : "Try Again";
      default:
        return "";
    }
  }

  function startVideoPractice(
    video: IStudentVideoAssignment,
    standardId: number,
    standardName: string
  ) {
    history.push({
      pathname:
        routeConstant.KindergartenStudentRoutes.VideoQuestionPractice.replace(
          ":standardName",
          encodeURI(btoa(standardName))
        )
          .replace(":subjectId", encodeURI(btoa(props.subjectId.toString())))
          .replace(":standardId", encodeURI(btoa(standardId.toString())))
          .replace(":videoId", encodeURI(btoa(video.videoId.toString()))),
      state: {
        subjectName: props.subjectName,
        contentAreaId: props.contentAreaId,
        subjectId: props.subjectId,
        videoTitle:
          props.profile?.plLanguageId === constant.Languages.Spanish
            ? video.spanishVideoTitle ?? video.videoTitle
            : video.videoTitle,
      },
    });
  }

  return (
    <Fragment>
      <div className="bg-[#403d6a] rounded-lg mt-6 md:mt-0">
        <h3 className="py-4 px-7 bg-[#4e4b75] rounded-t-lg tracking-wider font-Didact relative text-lg  lg:text-xl xl:text-2xl text-[#f5d500] text-white hover:shadow-2xl h-full flex items-center justify-center">
          Videos
        </h3>
        <div className="p-4">
          {standardGroups.map((group) => {
            return (
              <Fragment key={group.domainName}>
                <h3 className="py-3 font-Didact relative text-lg tracking-wide lg:text-xl xl:text-xl text-white hover:shadow-2xl text-left pl-3 h-full flex items-center">
                  {group.domainName}
                </h3>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 gap-12 text-center flex flex-col mb-6">
                  {group.videos.map((video) => {
                    return (
                      <Fragment key={video.videoId}>
                        <div
                          className={`${
                            video.score >= passingScore
                              ? "border-green-500 hover:bg-green-300"
                              : "border-[#C038D3] hover:bg-[#f19afc]"
                          } bg-white group rounded-lg hover:shadow-2xl cursor-pointer flex flex-col justify-between overflow-hidden border-8 hover:shadow-2xl`}
                          onClick={() => {
                            startVideoPractice(
                              video,
                              video.standardId,
                              (props.profile?.plLanguageId ?? 1) ===
                                constant.Languages.Spanish
                                ? video.spanishVideoTitle ?? video.videoTitle
                                : video.videoTitle
                            );
                          }}
                          onMouseEnter={() => {
                            startSpeaking(
                              video.videoTitle,
                              (props.profile?.plLanguageId ?? 1) === 2 ? 2 : 1
                            );
                          }}
                          onMouseLeave={() => {
                            stopSpeaking();
                          }}
                        >
                          <label
                            className={`${
                              video.score >= passingScore
                                ? "bg-green-500"
                                : "bg-[#C038D3]"
                            }  flex-shrink-0 text-white font-semibold text-2xl cursor-pointer h-14 w-14 hover:opacity-90 m-4 mb-0 rounded-full flex inline-block items-center justify-center`}
                          >
                            {video.index}
                          </label>
                          <div className="flex flex-col h-full">
                            <span className="inline-block p-4">
                              <img
                                className="group-hover:scale-110 transform transition-all duration-300 rounded-xl h-36 mx-auto cursor-pointer"
                                srcSet={video.thumbnailUrl}
                                alt="video questions"
                                title="Video questions"
                              />
                            </span>
                            <div className="justify-between flex-grow p-4 bg-gray-100 transition-all">
                              <div className="flex w-full justify-between items-center">
                                <span className="block font-semibold text-lg text-gray-700 group-hover:text-gray-900 mb-1">
                                  {getStatus(video)}
                                </span>
                              </div>
                              <div className="flex w-full">
                                <span className="block text-xl text-left font-semibold text-secondary-teal uppercase">
                                  {props.profile?.plLanguageId ===
                                  constant.Languages.Spanish
                                    ? video.spanishVideoTitle ??
                                      video.videoTitle
                                    : video.videoTitle}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Fragment>
                    );
                  })}
                </div>
              </Fragment>
            );
          })}
        </div>
      </div>
    </Fragment>
  );
}

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(VideoQuestionList);

import { call, put, takeLatest } from "redux-saga/effects";
import * as actions from "../actions/actionTypes";
import { setMasterData } from "../actions/userActions";
import { Configuration } from "../../environment/setup";
import { AxiosHelper } from "../../environment/axiosConfig";

const getMaterData = (
  userId: number,
  roleId: number,
  schoolId: number,
  stateId: number,
  districtId: number
) => {  

  const url = `${Configuration.ApiBaseUrl}MasterData`;
  const response = AxiosHelper.axiosInstance().get(url, {
    params: {
      userId,
      schoolId,
      roleId,
      stateId,
      districtId,
    },
  });
  return response;
};

function* fetchMasterData(payload: any) {
  const { userId, roleId, schoolId, stateId, districtId } = payload;
  const res = yield call(
    getMaterData,
    userId,
    roleId,
    schoolId,
    stateId,
    districtId
  );
  yield put(setMasterData(res.data));
}

const masterDataSaga = [takeLatest(actions.GET_MASTERDATA, fetchMasterData)];
export default masterDataSaga;

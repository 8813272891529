import { Fragment } from "react";

function NoData() {
  return (
    <Fragment>
      <div className="flex items-center pl-2">
        No data found for the selected filter(s).
      </div>
    </Fragment>
  );
}

export default NoData;

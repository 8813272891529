import { Fragment } from "react";
import { NavigationIcon } from "../../../../assets/icons/index";
import { IBreadcrumb } from "../../../../model/common/breadcrumb";
import { useHistory } from "react-router-dom";

interface passedProps {
  items: Array<IBreadcrumb>;
}

function Breadcrumb(props: passedProps) {
  const history = useHistory();
  const { items } = props;
  const handleClick = (item: IBreadcrumb) => {
    if (item.stateObject) {
      history.push({
        pathname: item.url,
        state: { selectedSubject: item.stateObject },
      });
    } else {
      if (item.url) history.push(item.url);
    }
  };
  return (
    <Fragment>
      <nav
        className="md:flex md:items-center md:w-[45%] "
        aria-label="Breadcrumb"
      >
        <ol className="flex items-center gap-x-2 gap-y-1 mb-2 sm:mb-0 sm:gap-x-0 sm:gap-y-0 flex-wrap">
          {items.map((item, i) => {
            return (
              <li key={i}>
                <div className="flex items-center">
                  {i > 0 && (
                    <NavigationIcon
                      className={`w-4 ${
                        item.textColor ? item.textColor : "text-white"
                      }  `}
                    />
                  )}
                  {item.url !== "" ? (
                    <button
                      onClick={() => {
                        handleClick(item);
                      }}
                      className={`underscore cursor-pointer text-sm  ${
                        item.textColor ? item.textColor : "text-white"
                      }  border-dashed border-b ${
                        item.textColor
                          ? item.textColor.replace("text", "border")
                          : "border-white"
                      }  hover:border-opacity-0`}
                    >
                      {item.objectId ? item.objectId : item.name}
                    </button>
                  ) : (
                    <span
                      className={`ml-1 text-sm ${
                        item.textColor ? item.textColor : "text-white"
                      } `}
                    >
                      {item.objectId ? item.objectId : item.name}
                    </span>
                  )}
                </div>
              </li>
            );
          })}
        </ol>
      </nav>
    </Fragment>
  );
}

export default Breadcrumb;

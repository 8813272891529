import { connect } from "react-redux";
import { IBreadcrumb } from "../../../../../model/common/breadcrumb";
import RouteConstant from "../../../../../utils/constant/routeConstant";
import { default as Constant } from "../../../../../utils/constant/constant";
import Breadcrumb from "../../../teacher/shared/breadcrumb";
import { useTranslation } from "react-i18next";
import Asterisk from "../../../../shared/asterisk";
import SelectGroup from "react-select";
import { Fragment, useEffect, useRef, useState } from "react";
import { Select } from "@windmill/react-ui";
import { getSchoolByDistrictId } from "../../../../../api/teacher/school";
import { ISelectElement } from "../../../../../model/interface/selectElement";
import { getAllClassesBySubject } from "../../../../../api/teacher/teacher";
import { getByClassId } from "../../../../../api/class/classStudent";
import {
  getMyGalaxySubject,
  getStudentLiftOffMyGalaxyStandards,
} from "../../../../../api/student/liftOff/myGalaxyApi";
import {
  IStudentLiftOffMyGalaxy,
  MyGalaxyTableBulider,
} from "../../../student/shared/liftoff/myGalaxyTableBuilder";
import { Configuration } from "../../../../../environment/setup";
import ReactTooltip from "react-tooltip";
import Loader from "../../../../shared/loader";
import { hasLiftOffAccess } from "../../../../../utils/helper";
import LiftoffIndividualDignosticsPrintDialog from "./liftoffIndividualDignosticsPrintDialog";
import { useReactToPrint } from "react-to-print";
import ITeacherClass from "../../../../../model/interface/teacherclass";
import ClassDropDown from "../../shared/classDropDown";
import { ISchool } from "../../../../../model/interface/school";

function LiftoffIndividualDiagnostict(props) {
  const { t } = useTranslation();
  const [isDistrictUser] = useState<boolean>(
    props.userContext.roleId === Constant.UserRoleId.District ? true : false
  );
  const [schools, setSchools] = useState<Array<ISelectElement>>([]);
  const [classes, setClasses] = useState<Array<ITeacherClass>>([]);
  const [students, setStudents] = useState<Array<ISelectElement>>([]);
  const [subjects, setSubjects] = useState<Array<ISelectElement>>([]);
  const [selectedSchool, setSelectedSchool] = useState<string>(
    props.userContext.roleId === Constant.UserRoleId.District
      ? "0"
      : props.userContext.schoolId
  );
  const [selectedClass, setSelectedClass] = useState<string>("0");
  const [selectedStudent, setSelectedStudent] = useState<string>("0");
  const [selectedSubject, setSelectedSubject] = useState<string>("0");
  const [selectedViewBy, setSelectedViewBy] = useState<string>("0");

  const [isClassDisabled, setIsClassDisabled] = useState<boolean>(
    props.userContext.roleId !== Constant.UserRoleId.District ? false : true
  );
  const componentRefReport = useRef<HTMLDivElement>(null);
  const [isStudentDisabled, setIsStudentDisabled] = useState<boolean>(true);
  const [isSubjectsDisabled, setIsSubjectDisabled] = useState<boolean>(true);
  const [standardsEntries, setStandardsEntries] = useState<any>([]);
  const [headers, setheaders] = useState<any>([]);
  const [liftOffHeaders, setLiftOffHeaders] = useState<any>([]);
  const [showReport, setShowReport] = useState<boolean>(false);
  const [noRecordFoundMsg, setNoRecordFoundMsg] = useState<string>("");
  const [showLoader, setLoader] = useState<boolean>(false);
  const [selectedSchoolName, setSelectedSchoolName] = useState<string>("");
  const [selectedSubjectName, setsSelectedSubjectName] = useState<string>("");
  const [selectedclassName, setSelectedClassName] = useState<string>("");
  const [selectedStudentName, setSelectedStudentName] = useState<string>("");
  const [selectedViewByName, setSelectedViewByName] =
    useState<string>("All Topics");
  const [selectedClassItem, setSelectedClassItem] = useState<ISelectElement>({
    label: "Select Class",
    value: "0",
  });
  const viewType: any = [
    { label: "All Topics", value: 0 },
    { label: "Hide Mastered Topics", value: 1 },
  ];
  const [allSchools, setAllSchools] = useState<ISchool[]>([]);
  const [selectedSchoolAccountId, setSelectedSchoolAccountId] =
    useState<number>(0);
  const backgroundColours = [
    {},
    { light: "bg-[#B5F9EB]", dark: "bg-[#84F5DE]" },
    { light: "bg-[#F591A9]", dark: "bg-[#EF476F]" },
    { light: "bg-[#FABAA6]", dark: "bg-[#F78C6B]" },
    { light: "bg-[#FFE3A3]", dark: "bg-[#FFD166]" },
    { light: "bg-[#B5E5B5]", dark: "bg-[#83D483]" },
    { light: "bg-[#B5F9EB]", dark: "bg-[#84F5DE]" },
    { light: "bg-[#F591A9]", dark: "bg-[#EF476F]" },
    { light: "bg-[#FABAA6]", dark: "bg-[#F78C6B]" },
    { light: "bg-[#FFE3A3]", dark: "bg-[#FFD166]" },
    { light: "bg-[#B5E5B5]", dark: "bg-[#83D483]" },
  ];
  const breadcrumbItems = () => {
    const items: Array<IBreadcrumb> = [
      { name: t("Breadcrumb.Home"), url: RouteConstant.ROUTE_DASHBOARD },
      { name: t("Breadcrumb.Reports"), url: RouteConstant.REPORTS },
      { name: t("Breadcrumb.LiftoffMyGalaxiesReport"), url: "" },
    ];

    return items;
  };

  const getSchools = async () => {
    const resp = await getSchoolByDistrictId(
      props.userContext?.districtId ?? 0
    );
    if (resp?.data?.length) {
      let schoolList: Array<ISelectElement> = [];
      resp?.data.forEach((school) => {
        if (!schoolList.find((sl) => sl.value === school.schoolId.toString())) {
          schoolList.push({
            label: school.name,
            value: school.schoolId.toString(),
          });
        }
      });
      setSchools(schoolList);
      setAllSchools(resp?.data);
    }
  };

  const getClasses = async (schoolId: number) => {
    const resp = await getAllClassesBySubject(
      props.userContext.userId,
      props.userContext.roleId,
      null,
      props.profile.currentSchoolYear.schoolYearId,
      schoolId
    );
    setClasses(resp.data.data);
  };

  const getStudents = async (classId: number) => {
    const resp = await getByClassId(classId);
    if (resp?.data?.length) {
      resp?.data.sort((a, b) =>
        a.lastName === b.lastName
          ? a.firstName.localeCompare(b.firstName)
          : a.lastName.localeCompare(b.lastName)
      );
      let studentList: Array<ISelectElement> = [];
      resp?.data.forEach((student) => {
        if (!studentList.find((sl) => sl.value === student.userId.toString())) {
          studentList.push({
            label:
              student.lastName +
              (student.firstName ? ", " + student.firstName : ""),
            value: student.userId.toString(),
          });
        }
      });
      setStudents(studentList);
    }
  };

  const getSubjects = async (studentId: number) => {
    const resp = await getMyGalaxySubject(studentId);
    if (resp?.data?.length) {
      let subjectList: Array<ISelectElement> = [];
      resp?.data.forEach((subject) => {
        if (
          !subjectList.find((sl) => sl.value === subject.subjectId.toString())
        ) {
          subjectList.push({
            label: subject.name,
            value: subject.subjectId.toString(),
          });
        }
      });
      setSubjects(subjectList);
    }
  };

  const findSchooltByValue: any = () => {
    if (!selectedSchool || selectedSchool === "0") return null;
    return schools.find((school) => school.value === selectedSchool);
  };

  const findStudentByValue: any = () => {
    if (!selectedStudent || selectedStudent === "0") return null;
    return students.find((student) => student.value === selectedStudent);
  };

  const findSubjectByValue: any = () => {
    if (!selectedSubject || selectedSubject === "0") return null;
    return subjects.find((subject) => subject.value === selectedSubject);
  };

  const handleSchoolOnChange = (school: ISelectElement) => {
    if (school.value !== selectedSchool) {
      setClasses([]);
      setSelectedClass("0");
      setStudents([]);
      setSelectedStudent("0");
      setSubjects([]);
      setSelectedSubject("0");

      setIsClassDisabled(false);
      setIsStudentDisabled(true);
      setIsSubjectDisabled(true);

      setSelectedSchool(school.value);
      setSelectedSchoolName(school.label);
      getClasses(Number(school.value));
      setSelectedSchoolAccountId(
        allSchools.filter((s) => s.schoolId === Number(school.value))[0]
          ?.schoolAccountId ?? 0
      );
    }
  };

  const handleClassOnChange = (cl: ISelectElement) => {
    setStudents([]);
    setSelectedStudent("0");
    setSubjects([]);
    setSelectedSubject("0");
    setIsStudentDisabled(false);
    setIsSubjectDisabled(true);

    setSelectedClass(cl.value);
    setSelectedClassName(cl.label);
    setSelectedClassItem(cl);
    getStudents(Number(cl.value));
  };

  const handleStudentOnChange = (student: ISelectElement) => {
    if (student.value !== selectedStudent) {
      setSubjects([]);
      setSelectedSubject("0");
      setIsSubjectDisabled(false);
      setSelectedStudent(student.value);
      setSelectedStudentName(student.label);
      getSubjects(Number(student.value));
    }
  };

  const handleSubjectOnChange = (subject: ISelectElement) => {
    setSelectedSubject(subject.value);
    setsSelectedSubjectName(subject.label);
  };

  function onPrint() {
    if (handleReportPrint) handleReportPrint();
  }
  const handleReportPrint = useReactToPrint({
    content: () => componentRefReport.current,
  });

  const onReset = () => {
    if (props.userContext.roleId === Constant.UserRoleId.District) {
      setSelectedSchool("0");
      setClasses([]);
      setIsClassDisabled(true);
    }
    setNoRecordFoundMsg("");
    setheaders([]);
    setLiftOffHeaders([]);
    setStandardsEntries([]);
    setSelectedClass("0");
    setSelectedClassName("");
    setStudents([]);
    setSelectedStudent("0");
    setSelectedStudentName("");
    setSubjects([]);
    setSelectedSubject("0");
    setsSelectedSubjectName("");
    setSelectedViewBy("0");
    setSelectedViewByName("All Topics");
    setIsStudentDisabled(true);
    setIsSubjectDisabled(true);
    setShowReport(false);
  };

  const getFilters = () => {
    let filter: string = "";
    if (selectedSchoolName) {
      filter = filter + "School: " + selectedSchoolName + ", ";
    }
    if (selectedSubjectName) {
      filter = filter + "Subject: " + selectedSubjectName + ", ";
    }
    if (selectedclassName) {
      filter = filter + "Class: " + selectedclassName + ",";
    }
    if (selectedStudentName) {
      filter = filter + "Student: " + selectedStudentName + ", ";
    }
    if (selectedViewByName) {
      filter = filter + "View By: " + selectedViewByName;
    }
    return filter;
  };

  const onRunReport = () => {
    getMyGalaxyTable();
  };

  const updateCenterGalaxy = (standardsEntries: any) => {
    let counter: number = 0;
    let totStandards: number = 0;
    standardsEntries.map((entry, stindex) => {
      if (entry.span > 0) {
        counter = 0;
        totStandards = entry.span;
      }
      if (totStandards == 1) {
        entry.showGalaxyLabel = true;
      } else if (Math.round(totStandards / 2) === counter) {
        entry.showGalaxyLabel = true;
      } else {
        entry.showGalaxyLabel = false;
      }
      counter++;
    });
  };

  const getMyGalaxyTable = async () => {
    setLoader(true);
    setNoRecordFoundMsg("");
    setShowReport(false);
    setheaders([]);
    setLiftOffHeaders([]);
    setStandardsEntries([]);
    getStudentLiftOffMyGalaxyStandards(
      Number(selectedStudent),
      Number(selectedSubject)
    )
      .then(async (response) => {
        const data = response.data as IStudentLiftOffMyGalaxy;
        if (data.standards.length > 0) {
          setheaders(data.headers);
          setLiftOffHeaders(data.liftOffHeaders);
          let table = await new MyGalaxyTableBulider(data).build();
          updateCenterGalaxy(table);
          setStandardsEntries(table);
          setShowReport(true);
        } else {
          setNoRecordFoundMsg("No record found.");
        }
        setLoader(false);
      })
      .catch(() => {
        setLoader(false);
      });
  };

  const getStandardVisibilty = (
    starCount: number,
    isStandardDisable: boolean
  ) => {
    if (isStandardDisable) {
      if (selectedViewBy === "1" && starCount > 0) {
        return "flex items-start space-x-1 py-2 pr-2 pl-1 rounded my-1 mx-2.5 bg-white bg-opacity-20 hidden";
      } else {
        return "flex items-start space-x-1 py-2 pr-2 pl-1 rounded my-1 mx-2.5 bg-white bg-opacity-20";
      }
    } else {
      if (selectedViewBy === "1") {
        return starCount > 0
          ? "flex items-start space-x-1 py-2 pr-2 pl-1 rounded my-1 mx-2.5 cursor-not-allowed  bg-white bg-opacity-70 pointer-events-none hidden"
          : "flex items-start space-x-1 py-2 pr-2 pl-1 rounded my-1 mx-1.5  bg-white bg-opacity-90";
      } else {
        return starCount > 0
          ? "flex items-start space-x-1 py-2 pr-2 pl-1 rounded my-1 mx-2.5 cursor-not-allowed  bg-white bg-opacity-70 pointer-events-none"
          : "flex items-start space-x-1 py-2 pr-2 pl-1 rounded my-1 mx-1.5  bg-white bg-opacity-90";
      }
    }
  };

  useEffect(() => {
    hasLiftOffAccess(props.userContext).then((res) => {
      if (!res) {
        window.location.replace(RouteConstant.FORBIDDEN);
      }
    });
    getSchools();
    if (props.userContext.roleId !== Constant.UserRoleId.District)
      getClasses(props.userContext.schoolId);
  }, []);

  return (
    <div className="min-w-0 w-full">
      <div className="lg:flex lg:items-center px-4 py-3 lg:px-8 border-b">
        {props.userContext.roleId !== Constant.UserRoleId.Student && (
          <Breadcrumb items={breadcrumbItems()} />
        )}

        <div className="lg:w-[55%] text-lg font-medium flex gap-2 h-auto sm:h-10 items-center">
          <h1 className="text-primary-violet font-medium flex justify-start mb-0 pb-0">
            Liftoff My Galaxies Report
          </h1>
        </div>
      </div>
      <div className="px-2 sm:px-6  lg:px-8 py-1 relative my-4">
        <div className="bg-gray-100 w-full rounded-lg p-5">
          <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-4 mb-4">
            {isDistrictUser && (
              <div className="flex flex-col">
                <label className="block mb-1 text-sm text-gray-500">
                  School <Asterisk />
                </label>
                <SelectGroup
                  placeholder={"Select School"}
                  value={findSchooltByValue()}
                  options={schools}
                  isSearchable={true}
                  onChange={(item) => {
                    handleSchoolOnChange(item);
                  }}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: "#008fbb",
                    },
                  })}
                />
              </div>
            )}

            <div className="flex flex-col">
              <label className="block mb-1 text-sm text-gray-500">
                Class <Asterisk />
              </label>
              <ClassDropDown
                schoolYearId={props.profile.currentSchoolYear.schoolYearId}
                defaultLabel={"Select Class"}
                onChange={(item) => handleClassOnChange(item[0])}
                value={selectedClassItem}
                data={classes}
                isDisabled={isClassDisabled}
                showArchived={true}
                showHidden={true}
                selectedSchoolAccountId={selectedSchoolAccountId}
              ></ClassDropDown>
            </div>

            <div className="flex flex-col">
              <label className="block mb-1 text-sm text-gray-500">
                Student <Asterisk />
              </label>
              <SelectGroup
                placeholder={"Select Student"}
                value={findStudentByValue()}
                options={students}
                isSearchable={true}
                isDisabled={isStudentDisabled}
                onChange={(item) => {
                  handleStudentOnChange(item);
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: "#008fbb",
                  },
                })}
              />
            </div>

            <div className="flex flex-col">
              <label className="block mb-1 text-sm text-gray-500">
                Subject <Asterisk />
              </label>
              <SelectGroup
                placeholder={"Select Subject"}
                value={findSubjectByValue()}
                options={subjects}
                isSearchable={true}
                isDisabled={isSubjectsDisabled}
                onChange={(item) => {
                  handleSubjectOnChange(item);
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: "#008fbb",
                  },
                })}
              />
            </div>

            <div className="flex flex-col">
              <label className="block mb-1 text-sm text-gray-500">
                View By
              </label>

              <Select
                value={selectedViewBy}
                onChange={(e) => {
                  setSelectedViewBy(e.target.value);
                  const label: string = viewType.find(
                    (v) => v.value === Number(e.target.value)
                  ).label;
                  setSelectedViewByName(label);
                }}
                className={
                  "py-2 px-4 block text-sm border border-gray-300 focus:ring-primary-violet focus:border-primary-violet form-select leading-5 p-1 rounded "
                }
              >
                <option value={0}>All Topics</option>
                <option value={1}>Hide Mastered Topics</option>
              </Select>
            </div>
          </div>

          <div className="flex gap-4 justify-end">
            <button
              onClick={onReset}
              className="bg-transparent border my-auto border-primary-violet shadow-sm py-2 px-4 flex justify-center text-sm font-medium text-primary-violet hover:text-white hover:bg-light-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3 transition ease-in-out duration-500"
            >
              Reset
            </button>

            <p
              className="cursor-pointer"
              data-tip
              data-for="incompleteSelection"
            >
              {(selectedSchool === "0" ||
                selectedClass === "0" ||
                selectedStudent === "0" ||
                selectedSubject === "0") && (
                <ReactTooltip
                  id="incompleteSelection"
                  class="bg-secondary-teal/10 z-50 "
                  effect="solid"
                >
                  <span>Please complete all required fields</span>
                </ReactTooltip>
              )}
              <div className="cursor-not-allowed">
                <button
                  onClick={onRunReport}
                  className={
                    "bg-primary-violet border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 " +
                    (selectedSchool === "0" ||
                    selectedClass === "0" ||
                    selectedStudent === "0" ||
                    selectedSubject === "0"
                      ? "bg-opacity-20 text-primary-violet text-opacity-40 pointer-events-none"
                      : "hover:bg-dark-violet hover:shadow-lg")
                  }
                >
                  Run Report
                </button>
              </div>
            </p>

            <p
              className="cursor-pointer"
              data-tip
              data-for="incompleteSelectionPrint"
            >
              {selectedSchool === "0" ||
                selectedClass === "0" ||
                selectedStudent === "0" ||
                selectedSubject === "0" ||
                !standardsEntries ||
                (standardsEntries && standardsEntries.length == 0 && (
                  <ReactTooltip
                    id="incompleteSelectionPrint"
                    class="bg-secondary-teal/10 z-50 "
                    effect="solid"
                  >
                    <span>
                      {"Please complete all required fields and run the report"}
                    </span>
                  </ReactTooltip>
                ))}
              <div className="cursor-not-allowed">
                <button
                  onClick={onPrint}
                  className={
                    "bg-primary-violet border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 " +
                    (selectedSchool === "0" ||
                    selectedClass === "0" ||
                    selectedStudent === "0" ||
                    selectedSubject === "0" ||
                    !standardsEntries ||
                    (standardsEntries && standardsEntries.length == 0)
                      ? "bg-opacity-20 text-primary-violet text-opacity-40 pointer-events-none"
                      : "hover:bg-dark-violet hover:shadow-lg")
                  }
                >
                  Print Report
                </button>
              </div>
            </p>
          </div>

          <div className="border-b border-gray-400 py-2 mb-2"></div>
          {showLoader && <Loader></Loader>}
          {noRecordFoundMsg}
          {showReport && (
            <>
              <div className="bg-white px-3 pt-2 pb-3">
                <div className="overflow-x-auto rounded-lg">
                  <table className="text-xs text-left rounded-lg w-full">
                    <thead>
                      <tr className="text-xs font-normal bg-gray-700 text-center text-white">
                        <th className="p-5"></th>
                        {liftOffHeaders &&
                          liftOffHeaders.length > 0 &&
                          liftOffHeaders.map((lf, index) => {
                            return (
                              <th key={index} colSpan={2} className="p-5">
                                <div className="flex flex-col">
                                  {lf.header}
                                  {!lf.isDiagnosticTestCompleted && (
                                    <div className="flex items-center bg-yellow-50 border-yellow-500 inline-block text-yellow-500 mx-auto rounded-sm px-2 py-1 mt-2 ">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke-width="1.5"
                                        stroke="currentColor"
                                        className="w-5 h-5 mr-1"
                                      >
                                        <path
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                          d="M12 10.5v3.75m-9.303 3.376C1.83 19.126 2.914 21 4.645 21h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 4.88c-.866-1.501-3.032-1.501-3.898 0L2.697 17.626zM12 17.25h.007v.008H12v-.008z"
                                        />
                                      </svg>
                                      No Diagnostic data
                                    </div>
                                  )}
                                </div>
                              </th>
                            );
                          })}
                      </tr>
                    </thead>
                    <tbody>
                      {standardsEntries.map((entry, index) => {
                        return (
                          <tr key={index}>
                            {entry.span > 0 && (
                              <td
                                className={`${
                                  entry.galaxyId < backgroundColours.length
                                    ? backgroundColours[entry.galaxyId].light
                                    : backgroundColours[1].light
                                } w-10 ${entry.galaxy ? " h-48" : ""}        `}
                                rowSpan={entry.span}
                              >
                                <div className="transform rotate-90 text-center text-2xl uppercase whitespace-nowrap  w-10 flex justify-center">
                                  {entry.galaxy}
                                </div>
                              </td>
                            )}
                            {headers &&
                              headers.length > 0 &&
                              headers.map((header, index) => {
                                return (
                                  <Fragment key={index}>
                                    <td className="w-1 bg-gray-700"></td>
                                    <td
                                      className={`relative ${
                                        entry.galaxyId <
                                        backgroundColours.length
                                          ? backgroundColours[entry.galaxyId]
                                              ?.dark
                                          : backgroundColours[1]?.dark
                                      }`}
                                    >
                                      {entry[header]?.isDisplayRocket &&
                                        entry[header].isDisplayRocket && (
                                          <div className="pt-8">
                                            <img
                                              src={`${Configuration.S3bucketImagePath}/images/teacher/arena/rockets/rocket190001.png`}
                                              className="hidden absolute w-8 top-1 -left-4"
                                              alt="Liftoff rocket"
                                            ></img>
                                          </div>
                                        )}
                                      {entry[header]?.standardName && (
                                        <>
                                          {entry[header]?.isRocket && (
                                            <img
                                              src={`${Configuration.S3bucketImagePath}/images/teacher/arena/rockets/rocket190001.png`}
                                              className="hidden absolute w-8 top-1 -left-4 space-x-1"
                                              alt="Liftoff rocket"
                                            ></img>
                                          )}
                                          {entry[header] &&
                                            entry[header].isStandardDisable && (
                                              <div
                                                className={getStandardVisibilty(
                                                  entry[header]?.earnedStar,
                                                  true
                                                )}
                                              >
                                                {entry[header]?.earnedStar >
                                                  0 && (
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="w-6 h-6 text-yellow-300 flex-none w-4"
                                                    fill="yellow"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                  >
                                                    <path
                                                      stroke-linecap="round"
                                                      stroke-linejoin="round"
                                                      stroke-width="2"
                                                      d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"
                                                    />
                                                  </svg>
                                                )}
                                                {entry[header]?.earnedStar ===
                                                  0 && (
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="w-6 h-6 text-yellow-300 flex-none w-4 invisible"
                                                    fill="yellow"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                  >
                                                    <path
                                                      stroke-linecap="round"
                                                      stroke-linejoin="round"
                                                      stroke-width="2"
                                                      d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"
                                                    />
                                                  </svg>
                                                )}
                                                <span className="opacity-90">
                                                  {entry[header]?.standardName}
                                                </span>
                                              </div>
                                            )}
                                          {entry[header] &&
                                            !entry[header]
                                              .isStandardDisable && (
                                              <div
                                                className={getStandardVisibilty(
                                                  entry[header]?.earnedStar,
                                                  false
                                                )}
                                              >
                                                {entry[header]?.earnedStar >
                                                  0 && (
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="w-6 h-6 text-yellow-300 flex-none w-4"
                                                    fill="yellow"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                  >
                                                    <path
                                                      stroke-linecap="round"
                                                      stroke-linejoin="round"
                                                      stroke-width="2"
                                                      d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"
                                                    />
                                                  </svg>
                                                )}
                                                {entry[header]?.earnedStar ===
                                                  0 && (
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="w-6 h-6 text-yellow-300 flex-none w-4 invisible"
                                                    fill="yellow"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                  >
                                                    <path
                                                      stroke-linecap="round"
                                                      stroke-linejoin="round"
                                                      stroke-width="2"
                                                      d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"
                                                    />
                                                  </svg>
                                                )}
                                                <span
                                                  className={
                                                    entry[header]?.earnedStar >
                                                    0
                                                      ? "opacity-90"
                                                      : ""
                                                  }
                                                >
                                                  {entry[header]?.standardName}
                                                </span>
                                              </div>
                                            )}
                                        </>
                                      )}
                                    </td>
                                  </Fragment>
                                );
                              })}
                          </tr>
                        );
                      })}

                      <tr
                        className={`text-xs font-normal text-center text-white ${backgroundColours[1]?.dark}`}
                      >
                        <th
                          className={`p-5 ${backgroundColours[1]?.light}`}
                        ></th>
                        {headers &&
                          headers.length > 0 &&
                          headers.map((header, index) => {
                            return (
                              <td
                                key={index}
                                colSpan={2}
                                className="p-5 relative"
                              >
                                <img
                                  src={`${Configuration.S3bucketImagePath}/images/student/shared/rocket-launcher.png`}
                                  className="hidden absolute w-8 top-0 -top-1.5 -left-1"
                                  alt="Liftoff rocket launcher"
                                ></img>
                              </td>
                            );
                          })}
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="hidden">
                <div
                  className="bg-white py-5 px-5 pt-2 mt-4"
                  ref={componentRefReport}
                >
                  <LiftoffIndividualDignosticsPrintDialog
                    lastName={props.profile.lastName}
                    firstName={props.profile.firstName}
                    filters={getFilters()}
                    backgroundColours={backgroundColours}
                    headers={headers}
                    liftOffHeaders={liftOffHeaders}
                    standardsEntries={standardsEntries}
                    getStandardVisibilty={getStandardVisibilty}
                  ></LiftoffIndividualDignosticsPrintDialog>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default connect((state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
})(LiftoffIndividualDiagnostict);

import * as actionType from "./actionTypes"

export const setProfile = (payload: any) => ({
    type: actionType.SET_PROFILE,
    payload,
})

export const fetchProfile = (userId: number, roleId: number, schoolId: number, districtId: number) => ({
    type: actionType.GET_PROFILE,
    userId,
    roleId,
    schoolId,
    districtId
})

export const setMasterData = (payload: any) => ({
    type: actionType.SET_MASTERDATA,
    payload,
})

export const fetchMasterData = (userId: number, roleId: number, schoolId: number, stateId: number,districtId : number) => ({
    type: actionType.GET_MASTERDATA,
    userId,
    roleId,
    schoolId,
    stateId,
    districtId
})

import { CSSProperties } from "react";
import { Configuration } from "../../environment/setup";
import { exterRosterType } from "../../model/externalRoster/externalRosterType";
import Environments from "./environment";

class Constant {
  public readonly PPT_EXTENSION = "PPT";
  public readonly PDF_EXTENSION = "PDF";
  public readonly DOC_EXTENSION = "DOC";
  public readonly XLS_EXTENSION = "XLS";
  public readonly PPTX_EXTENSION = "PPTX";
  public readonly DOCX_EXTENSION = "DOCX";
  public readonly DOT_EXTENSION = "DOT";
  public readonly XLSX_EXTENSION = "XLSX";
  public readonly DEFAULTPASSWORD = "123";
  public readonly ASSIGNMENTDETAIL = "Assignment Detail";
  public readonly SESSIONSUMMARY = "Session Summary";
  public readonly TOPICDETAIL = "Topic Detail";
  public readonly GALAXYSTARDETAIL = "Galaxy Star Detail";
  public readonly PERFORMANCEDETAIL = "Performance Detail";
  public readonly USTPRedirectLink = "https://www.usatestprep.com/member-login";
  public readonly LASTREFRESHMESSAGE =
      "The date filter will only work for the current school year.This report data was last refreshed on";
  public readonly ERRORMESSAGE =
      "Something went wrong. Please contact support.";
  public readonly MANAGESTUDENTUri = "Manage Students";
  public readonly IMPERSONATORKEY = "ImpersonatorId";
  public readonly SWITCHEGCONTEXT = "switchContext";
  public readonly DuplicateRestrictedCode = 403;
  public readonly UnlimitedAttempt = 999;
  public readonly PhoneNumber = "877-377-9537";
  public readonly QuotesEmail = "quotes@progresslearning.com.";
  public readonly MINIMUMSUBJECTVIDEOS = 6;
  public readonly PERCENTAGEVIDEOSREQUIRED = 0.75;

  private readonly FORM: exterRosterType = "FORM";
  private readonly GOOGLE: exterRosterType = "GOOGLE";
  private readonly CANVAS: exterRosterType = "CANVAS";
  private readonly CLEVER: exterRosterType = "CLEVER";
  private readonly CLASSLINK: exterRosterType = "CLASSLINK";
  private readonly SSO: exterRosterType = "SSO";

  public readonly AlphaNumericExpression = new RegExp(/^[a-zA-Z0-9 ]*$/);

  public readonly ExterRosterType = {
    FORM: this.FORM,
    GOOGLE: this.GOOGLE,
    CLEVER: this.CLEVER,
    CLASSLINK: this.CLASSLINK,
    SIMPLESIGNON: this.SSO,
    CANVAS: this.CANVAS,
  };

  public readonly GoogleSignInMode = {
    SSO: "SSO",
    MAPPING: "MAPPING",
    AUTHORIZE: "AUTHORIZE",
  };

  public readonly Filters = {
    CLASS: "CLASS",
    GRADE: "GRADE",
    LOGRADE: "LOGRADE",
    SUBJECT: "SUBJECT",
    LOSUBJECT: "LOSUBJECT",
    RANGE: "RANGE",
    SCHOOL: "SCHOOL",
    LOSCHOOL: "LOSCHOOL",
    INPUT1: "INPUT1",
    INPUT2: "INPUT2",
  };

  public readonly WidgetPanels = {
    LEFTTOP: "LeftTop",
    LEFTMIDDLE: "LeftMiddle",
    LEFTBOTTOM: "LeftBottom",
    CENTERTOP: "CenterTop",
    CENTERMIDDLE: "CenterMiddle",
    CENTERBOTTOM: "CenterBottom",
    RIGHTTOP: "RightTop",
    RIGHTMIDDLE: "RightMiddle",
    RIGHTBOTTOM: "RightBottom",
  };

  public readonly Widget = {
    OVERALLSTATS: "OVERALLSTATS",
    COURSE: "COURSE",
    TRAININGDOCUMENT: "TRAININGDOCUMENT",
    QUICKLINK: "QUICKLINK",
    GALAXYSTAR: "GALAXYSTAR",
    RECENTACTIVITY: "RECENTACTIVITY",
    PERFORMANCE: "PERFORMANCE",
    ASSIGNMENT: "ASSIGNMENT",
    GALAXYMESSAGE: "GALAXYMESSAGE",
    SCHOOLUSAGE: "SCHOOLUSAGE",
    OVERALLSTATSGRAPH: "OVERALLSTATSGRAPH",
    DISTRICTPERFORMANCE: "DISTRICTPERFORMANCE",
    IMPERSONATEUSER: "IMPERSONATEUSER",
    DISTRICTCOURSE: "DISTRICTCOURSE",
  };

  public readonly ProgramMode = {
    EG: "EG",
    LO: "LO",
    PL: "PL",
  };

  public readonly SpeakerMode = {
    Hold: 0,
    Speak: 1,
  };

  public readonly LiftOffDiagnosticAssignmentStatusType = {
    NotExist: -1,
    Start: 0,
    InProgress: 1,
    Completed: 2,
  };

  public readonly StudentAssignmentActivityStatusType = {
    NotExist: -1,
    Start: 0,
    InProgress: 1,
    Completed: 3,
  };

  public readonly ProgramType = {
    EducationGalaxy: "Education Galaxy",
    LiftOff: "Liftoff",
  };

  public readonly Environment = Environments.Environment;

  public readonly AssignmentType = {
    ASSIGNMENTFROMTESTBANK: "AssignmentFromTestBank",
    ASSIGNMENTFROMTOPIC: "AssignmentFromTopic",
    VIDEOASSIGNMENT: "VideoAssignment",
    MYASSIGNMENT: "MyAssignment",
    CLASSASSIGNMENT: "ClassAssignment",
    SCHOOLASSIGNMENT: "SchoolAssignment",
    REMEDIATIONASSIGNMENT: "RemediationAssignment",
  };

  public readonly ManualGradedQuestionTypes = [
    "plaintext",
    "longtextV2",
    "drawing",
    "audio",
    "formulaessayV2",
    "chemistryessayV2",
    "fileupload",
    "rating",
  ];
  public readonly RangeOptions = [
    {
      key: "GREATERTHAN",
      value: "Greater Than",
    },
    {
      key: "LESSTHAN",
      value: "Less Than",
    },
    {
      key: "BETWEEN",
      value: "Between",
    },
  ];
  public readonly UserRoleId = {
    SuperAdmin: 1 as number,
    Employee: 2 as number,
    Contractor: 3 as number,
    District: 4 as number,
    SchoolAdmin: 5 as number,
    SchoolTeacher: 6 as number,
    FreeTeacher: 7 as number,
    PayTeacher: 8 as number,
    Student: 9 as number,
  };

  public readonly RoleIdToUserType = {
    1: "SuperAdmin",
    2: "Employee",
    3: "Contractor",
    4: "DistrictAdmin",
    5: "SchoolAdmin",
    6: "SchoolTeacher",
    7: "FreeTeacher",
    8: "PayTeacher",
    9: "Student",
  };

  public readonly ClassExternalRoster = {
    Clever: 1 as number,
    ClassLink: 2 as number,
    Google: 3 as number,
    Canvas: 4 as number,
  };

  public readonly Grade = {
    GRADEK: 1 as number,
    GRADE1: 2 as number,
    GRADE2: 3 as number,
    GRADE3: 4 as number,
    GRADE4: 5 as number,
    GRADE5: 6 as number,
    GRADE6: 7 as number,
    GRADE7: 8 as number,
    GRADE8: 9 as number,
    GRADE9: 10 as number,
    GRADE10: 11 as number,
  };

  public readonly MultiSelectOperations = {
    SELECT: "SELECT",
    REMOVE: "REMOVE",
  };

  public readonly FilterType = {
    SUBJECT: "Subject",
    LOSUBJECT: "loSubject",
    CLASS: "Class",
    GRADE: "Grade",
  };

  public readonly Subject = {
    MATH: "Math",
    READING: "Reading",
  };

  public readonly Report = {
    EGSCHOOLPERFORMANCE: "EGSchoolPerformance",
    STUDYPLANPERFORMANCE: "StudyPlanPerformance",
    EGPERFORMANCECOMPARISON: "EGPerformanceComparison",
    EGSCHOOLUSAGE: "EGSchoolUsage",
    EGDISTRICTUSAGE: "EGDistrictUsage",
    EGDISTRICTDIAGNOSTIC: "EGDistrictDiagnostic",
    EGSCHOOLDIAGNOSTIC: "EGSchoolDiagnostic",
    EGSCHOOLPREPOSTDIAGNOSTICCOMPARISON: "EGSchoolPrePostDiagnosticComparison",
    EGDIAGNOSTICTESTTYPES: ["PreTest", "PostTest"],
    EGDIAGNOSTICCOMPARISON: "EGDistrictComparison",
    LFSCHOOLUSAGE: "LFSchoolUsage",
    LFDistrictUsage: "LFDistrictUsage",
    EGSCHOOLUSAGECOMPARISON: "EGSchoolUsageComparison",
    EGDISTRICTPERFORMANCE: "EGDistrictPerformance",
    LFSCHOOLUSAGECOMPARISON: "LOSchoolUsageComparison",
    LFSCHOOLPERFORMANCECOMPARISON: "LFSchoolPerformanceComparison",
    LFDISTRICTDIAGNOSTIC: "LFDistrictDiagnostic",
    LFSCHOOLPERFORMANCE: "LFSchoolPerformance",
    LFPERFORMANCE: "LFPerformance",
    LFDISTRICTPERFORMANCE: "LFDistrictPerformance",
    LFSCHOOLDIAGNOSTIC: "LFSchoolDiagnostic",
    LFDIAGNOSTICCOMPARISON: "LFDiagnosticComparison",
    ADMINLIST: "AdminList",
    CAMPUSTRAINING: "CampusTraining",
    TEACHERTRAINING: "TeacherTraining",
    EGARCHIVEDUSAGE: "EGArchivedUsage",
    LOARCHIVEDUSAGE: "LFArchivedUsage",
  };
  public readonly Profile = {
    PROFILE: "Profile",
    UNLINKGOOGLECLASSROOM: "UnlinkGoogleClassroom",
    SCHOOLPRICIPAL: "SchoolPricipal",
    SCHOOLHOURS: "SchoolHours",
    REMOVESCHOOLCLASS: "RemoveSchoolClass",
    CANCELSUBSCRIPTION: "CancelSubscription",
    UPLOADIMAGE: "UploadImage",
  };

  public readonly SortBy = {
    NONE: "NONE",
    ASC: "ASC",
    DESC: "DESC",
  };

  public readonly LoginType = {
    FORM: "Form",
    GOOGLE: "Google",
    CLEVER: "Clever",
    CLASSLINK: "Classlink",
    GOOGLEMAP: "GoogleMap",
    CANVAS: "Canvas",
    LOGINASUSER: "LoginAsUser",
    GWINNETT: "gwinnett"
  };

  public readonly AuthErrorCode = {
    GENERAL: "GENERAL",
    GOOGLEMAPPINGREQUIRED: "GOOGLEMAPPINGREQUIRED",
  };

  public readonly StudyType = {
    ASSIGNMENTS: "ASSIGNMENTS",
    GRADED_WORK: "GRADED WORK",
    DIAGNOSTICS: "DIAGNOSTICS",
    MYSTUDYPLAN: "MY STUDY PLAN",
    SKILLPRACTICE: "SKILL PRACTICE",
    ALIENARENA: "ALIEN ARENA",
    FOCUSAREAS: "FOCUS AREAS",
    VIDEO: "VIDEO",
  };

  public readonly FocusAreaStatusType = {
    APPROACHINGMASTERY: "ApproachingMastery",
    CRITICAL: "Critical",
  };

  public readonly AssignmentPart = {
    ASSESSMENT: "ASSESSMENT",
    PREBUILTTESTS: "PREBUILT TESTS",
    PRACTICEQUESTIONS: "PRACTICE QUESTIONS",
    PERFORMANCETASKS: "PERFORMANCE TASKS",
    VIDEOLESSON: "VIDEO LESSON",
    CONSTRUCTEDRESPONSE: "CONSTRUCTED RESPONSE",
  };

  public readonly ActiveTab = {
    CONTENT: "CONTENT",
    CLASS: "CLASS",
    NOTES: "NOTES",
    SETTING: "SETTING",
    PREVIEW: "PREVIEW",
  };

  public readonly ActiveTabClassBuilder = {
    DETAILS: "DETAILS",
    SUBJECTS: "SUBJECTS",
    STUDENTS: "STUDENTS",
    TEACHERS: "TEACHERS",
  };

  public readonly QuestionBuilderTab = {
    PASSAGE: "PASSAGE",

    QUESTION: "QUESTION",

    REVIEW: "REVIEW",
  };

  public readonly ArenaActiveTab = {
    SETUP: "SETUP",
    TEAMS: "TEAMS",
  };

  public readonly ClassSettings = {
    STUDENT: "Students",
    TEACHER: "Teachers",
    SUBJECTS: "Subjects",
    SETTINGS: "Settings",
    LIFTOFFSETTINGS: "Liftoff Settings",
    STUDENTMAPPING: "Student Mapping",
    SECTIONS: "Sections",
  };

  public readonly LayoutType = {
    NONE: "NONE",
    TEACHER: "TEACHER",
    KINDERGARTEN: "KINDERGARTEN",
    ELEMENTARY: "ELEMENTARY",
    MIDDLESCHOOL: "MIDDLESCHOOL",
    MIDDLESCHOOLLiftOff: "MIDDLESCHOOLLiftOff",
  };

  public readonly ClassType = {
    CURRENT: "CURRENT",
    ARCHIVED: "ARCHIVED",
    HIDDEN: "HIDDEN",
  };

  public readonly FeedbackReferenceType = {
    QUESTION: "QUESTION",
    STANDARD: "STANDARD",
    Worksheet: "WORKSHEET",
    VIDEO: "VIDEO",
  };

  public readonly AssignmentActivityType = {
    ASSESSMENT: "ASSESSMENT",
    PREBUILTTESTS: "PREBUILT TESTS",
    PRACTICEQUESTIONS: "PRACTICE QUESTIONS",
    PERFORMANCETASKS: "PERFORMANCE TASKS",
    VIDEOLESSON: "VIDEO LESSON",
    CONSTRUCTEDRESPONSE: "CONSTRUCTED RESPONSE",
  };

  public readonly AssignmentActivitySubType = {
    ASSESSMENT: {
      ASSESSMENT: "ASSESSMENT",
      DIAGNOSTIC_PRETEST: "DIAGNOSTIC-PRETEST",
      DIAGNOSTIC_POSTTEST: "DIAGNOSTIC-POSTTEST",
      STATE_PRACTICE_ASSESSMENT: "STATE-PRACTICE-ASSESSMENT",
      CANNEDTEST: "CANNEDTEST",
    },
    PREBUILTTESTS: {
      SUMMATIVE: "SUMMATIVE",
      DOMAIN: "DOMAIN",
      CANNEDTEST: "CANNEDTEST",
    },
    PRACTICEQUESTIONS: {
      PRACTICEQUESTIONS: "PRACTICEQUESTIONS",
    },
    VIDEOLESSON: {
      VIDEOLESSON: "VIDEOLESSON",
    },
    CONSTRUCTEDRESPONSE: {
      CONSTRUCTEDRESPONSE: "CONSTRUCTEDRESPONSE",
    },
  };

  public readonly AssignmentActivityType_Spanish = {
    ASSESSMENT: "EVALUACIÓN",
    PREBUILTTESTS: "PRUEBAS PRECONSTRUIDAS",
    PRACTICEQUESTIONS: "PREGUNTAS DE PRÁCTICA",
    PERFORMANCETASKS: "TAREAS DE DESEMPEÑO",
    VIDEOLESSON: "LECCIÓN EN VÍDEO",
    CONSTRUCTEDRESPONSE: "RESPUESTA CONSTRUIDA",
  };

  public readonly AssignmentActivityTypeEnum = {
    ASSESSMENT: 1,
    PREBUILDTEST: 2,
    PRACTICEQUESTIONS: 3,
    VIDEOLESSON: 4,
    CONSTRUCTEDRESPONSE: 5,
  };

  public readonly ActivityType = {
    ASSIGNMENT: "ASSIGNMENT",
    DIAGNOSTIC: "DIAGNOSTIC",
    STUDYPLAN: "STUDYPLAN",
    MYGALAXY: "MYGALAXY",
    SKILLPRACTICE: "SKILLPRACTICE",
    ALIENARENA: "ALIENARENA",
    VIDEOPRACTICE: "VIDEOPRACTICE",
    LIFTOFFDIAGNOSTIC: "LIFTOFFDIAGNOSTIC",
  };

  public readonly ActivitySubTypes = {
    ASSESSMENT: "ASSESSMENT",
    PRACTICETEST: "PRACTICETEST",
    GENERATEDTEST: "GENERATEDTEST",
    DIAGNOSTICS: "DIAGNOSTICS",
    PRACTICEQUESTIONS: "PRACTICE QUESTIONS",
    VIDEO: "VIDEO",
    CONSTRUCTEDRESPONSE: "CONSTRUCTEDRESPONSE",
    LIFTOFFDIAGNOSTICS: "LIFTOFFDIAGNOSTICS",
    LIFTOFFMYGALAXIES: "LIFTOFFMYGALAXIES",
    SKILLPRACTICE: "SKILLPRACTICE",
    STUDYPLANPRACTICEQUESTIONS: "STUDYPLANPRACTICEQUESTIONS",
  };

  public readonly RetryActivityType = {
    ASSIGNMENT: "ASSIGNMENT",
    STUDYPLAN_SUMMATIVE: "SUMMATIVE",
    STUDYPLAN_DOMAIN: "DOMAIN",
    STUDYPLAN_CANNEDTEST: "CANNEDTEST",
  };

  public readonly StudentType = {
    EL: "EL",
    K1: "K1",
    MS: "MS",
  };

  public readonly PageAcceessMode = {
    Add: "add",
    Edit: "edit",
    Readonly: "readonly",
  };

  public readonly Pagination = {
    DefaultPageSize: 99999,
    DefaultPageNumber: 1,
  };

  public readonly ManageUserMode = {
    Teacher: "MT",
    Student: "MS",
    District: "MD",
  };

  public readonly UserErrorMessage = {
    FirstName: "Please enter first name",
    LastName: "Please enter last name",
    UserName: "Please enter username",
    Password: "Please enter password",
    Email: "Please enter email",
    Grade: "Please select grade",
    EmailFormat: "Please enter email in correct format",
    UsernameLength: "Username should be between 6 to 60 characters",
    PasswordLength: "Password should be between 6 to 60 characters",
    AssignmentCreateError: `There was an error processing your assignment.
    You can try again or reach out to our support team if you continue to experience problems at 1-877-377-9537.`,
    SchoolAccountId: `Please select a school`,
  };

  public readonly DialogMessages = {
    AssignmentDuplicationFailCode1: "Improvements have been made to the items in the original assignment. This assignment cannot be duplicated. Please create a new assignment."
  }

  public readonly AssignmentUpdateAction = {
    LOCK: "LOCK",
    DELETE: "DELETE",
    ARCHIVE: "ARCHIVE",
  };

  public readonly Menu = {
    HOME: "HOME",
    CLASSMANAGEMENT: "CLASSMANAGEMENT",
    USERLOGINMAPPING: "USERLOGINMAPPING",
    ASSIGNMENT: "ASSIGNMENT",
    USERMANAGEMENT: "USERMANAGEMENT",
    ACCOUNT_INFORMATION: "ACCOUNT_INFORMATION",
    STUDENT_SETTINGS: "STUDENT_SETTINGS",
    LIFTOFF_MANAGEMENT: "LIFTOFF_MANAGEMENT",
    ALIENARENA: "ALIENARENA",
    ALIENARENA_SETUP: "ALIENARENA_SETUP",
    ALIENARENA_SESSION: "ALIENARENA_SESSION",

    SUPPORT: "SUPPORT",
    FAMILYCONNECT: "FAMILYCONNECT",
    CONTACTUS: "CONTACTUS",
    TAGS: "TAGS",
    TRAINING: "TRAINING",
    SUPERSHEET: "SUPERSHEET",
    STUDENTRESPONSESCREEN: "STUDENTRESPONSESCREEN",
    QUESTIONBUILDER: "QUESTIONBUILDER",
    CLASSCENTER: "CLASSCENTER",
    SCHOOLCENTER: "SCHOOLCENTER",
    INSTRUCTIONALRESOURCES: "INSTRUCTIONALRESOURCES",
    TRAININGCENTER: "TRAININGCENTER",
    VIDEOMESSAGE: "VIDEOMESSAGE",
    REPORT: "REPORT",
    NWEAMAPPING: "NWEAMAPPING",
    STUDENTVIEW: "STUDENTVIEW",
    CLASSROOM_GAME: "CLASSROOM_GAME"
  };

  public readonly ExternalRosterProviders = {
    Clever: 1,
    ClassLink: 2,
    Google: 3,
    Canvas: 4,
  };

  public readonly AssignmentStatus = {
    NEW: "NEW",
    INPROGRESS: "INPROGRESS",
    COMPLETE: "COMPLETED",
    DRAFT: "DRAFT",
    SAVE: "SAVED",
    ATTEMPTED: "ATTEMPTED",
  };

  public readonly AssignmentStatusId = {
    NotStarted: 0 as Number,
    NEW: 1 as number,
    INPROGRESS: 2 as number,
    COMPLETE: 3 as number,
    DRAFT: 4 as number,
  };

  public readonly LocalStorageKeys = {
    ToggleSideMenu: "ToggleSideMenu",
    i18nextLng: "i18nextLng",
  };

  public readonly AssessmentSharedType = {
    ClassShared: 1 as number,
    SchoolShared: 2 as number,
    DistrictShared: 3 as number,
  };

  public readonly AssessmentResultDisplayTypeId = {
    NoDetail: 1 as number,
    QuestionsOnly: 2 as number,
    QuestionsAndAnswer: 3 as number,
  };

  public readonly AssessmentResultDisplayType = {
    ScoresOnly: 1 as number,
    QuestionsOnly: 2 as number,
    QuestionsAndCorrectAnswers: 3 as number,
  };

  public readonly AssessmentQuestionType = {
    NonPassageQuestions: 1 as number,
    PassageQuestions: 2 as number,
    AuthoredQuestions: 3 as number,
    SystemGenerated: 4 as number,
  };

  public readonly AssessmentWarningMessage = {
    AssessmentQuestionMultiplePassages: `You have selected a question that is associated with more than 1 passage.
    The question shall be pre-selected for all of the associated passages.`,
  };

  public readonly QuestionBuilderErrors = {
    InvalidPointValue: "Total question points must be a whole number.",
    InvalidAlternatePointValue: "Alternate points must be a whole number.",
    NoCorrectAnswers: "Please provide answers to the question",
  };

  public readonly MAX_LEGTH_PREVIEW_QUESTION_TEXT = 120;

  public readonly Languages = {
    English: 1 as number,
    Spanish: 2 as number,
    Toggle: 3 as number,
  };
  public readonly LanguageSetting = {
    EnglishDefault: 1 as number,
    SpanishDefault: 2 as number,
    EnglishOnly: 3 as number,
  };

  public readonly LanguageCode = {
    English: "en" as string,
    Spanish: "es" as string,
  };

  public readonly Rockets = [
    {
      id: 1,
      name: "rocket10001",
      imageUrl: `${Configuration.S3bucketImagePath}/images/teacher/arena/rockets/rocket10001.png`,
    },
    {
      id: 3,
      name: "rocket20001",
      imageUrl: `${Configuration.S3bucketImagePath}/images/teacher/arena/rockets/rocket20001.png`,
    },
    {
      id: 5,
      name: "rocket30001",
      imageUrl: `${Configuration.S3bucketImagePath}/images/teacher/arena/rockets/rocket30001.png`,
    },
    {
      id: 7,
      name: "rocket40001",
      imageUrl: `${Configuration.S3bucketImagePath}/images/teacher/arena/rockets/rocket40001.png`,
    },
    {
      id: 9,
      name: "rocket50001",
      imageUrl: `${Configuration.S3bucketImagePath}/images/teacher/arena/rockets/rocket50001.png`,
    },
    {
      id: 11,
      name: "rocket60001",
      imageUrl: `${Configuration.S3bucketImagePath}/images/teacher/arena/rockets/rocket60001.png`,
    },
    {
      id: 13,
      name: "rocket70001",
      imageUrl: `${Configuration.S3bucketImagePath}/images/teacher/arena/rockets/rocket70001.png`,
    },
    {
      id: 15,
      name: "rocket80001",
      imageUrl: `${Configuration.S3bucketImagePath}/images/teacher/arena/rockets/rocket80001.png`,
    },
    {
      id: 17,
      name: "rocket90001",
      imageUrl: `${Configuration.S3bucketImagePath}/images/teacher/arena/rockets/rocket90001.png`,
    },
    {
      id: 19,
      name: "rocket100001",
      imageUrl: `${Configuration.S3bucketImagePath}/images/teacher/arena/rockets/rocket100001.png`,
    },
    {
      id: 21,
      name: "rocket110001",
      imageUrl: `${Configuration.S3bucketImagePath}/images/teacher/arena/rockets/rocket110001.png`,
    },
    {
      id: 23,
      name: "rocket120001",
      imageUrl: `${Configuration.S3bucketImagePath}/images/teacher/arena/rockets/rocket120001.png`,
    },
    {
      id: 24,
      name: "rocket130001",
      imageUrl: `${Configuration.S3bucketImagePath}/images/teacher/arena/rockets/rocket130001.png`,
    },
    {
      id: 25,
      name: "rocket140001",
      imageUrl: `${Configuration.S3bucketImagePath}/images/teacher/arena/rockets/rocket140001.png`,
    },
    {
      id: 26,
      name: "rocket150001",
      imageUrl: `${Configuration.S3bucketImagePath}/images/teacher/arena/rockets/rocket150001.png`,
    },
    {
      id: 27,
      name: "rocket160001",
      imageUrl: `${Configuration.S3bucketImagePath}/images/teacher/arena/rockets/rocket160001.png`,
    },
    {
      id: 28,
      name: "rocket170001",
      imageUrl: `${Configuration.S3bucketImagePath}/images/teacher/arena/rockets/rocket170001.png`,
    },
    {
      id: 29,
      name: "rocket180001",
      imageUrl: `${Configuration.S3bucketImagePath}/images/teacher/arena/rockets/rocket180001.png`,
    },
    {
      id: 30,
      name: "rocket190001",
      imageUrl: `${Configuration.S3bucketImagePath}/images/teacher/arena/rockets/rocket190001.png`,
    },
    {
      id: 31,
      name: "rocket200001",
      imageUrl: `${Configuration.S3bucketImagePath}/images/teacher/arena/rockets/rocket200001.png`,
    },
    {
      id: 32,
      name: "rocket210001",
      imageUrl: `${Configuration.S3bucketImagePath}/images/teacher/arena/rockets/rocket210001.png`,
    },
    {
      id: 33,
      name: "rocket220001",
      imageUrl: `${Configuration.S3bucketImagePath}/images/teacher/arena/rockets/rocket220001.png`,
    },
    {
      id: 34,
      name: "rocket230001",
      imageUrl: `${Configuration.S3bucketImagePath}/images/teacher/arena/rockets/rocket230001.png`,
    },
    {
      id: 35,
      name: "rocket240001",
      imageUrl: `${Configuration.S3bucketImagePath}/images/teacher/arena/rockets/rocket240001.png`,
    },
    {
      id: 36,
      name: "rocket250001",
      imageUrl: `${Configuration.S3bucketImagePath}/images/teacher/arena/rockets/rocket250001.png`,
    },
    {
      id: 37,
      name: "rocket260001",
      imageUrl: `${Configuration.S3bucketImagePath}/images/teacher/arena/rockets/rocket260001.png`,
    },
    {
      id: 38,
      name: "rocket270001",
      imageUrl: `${Configuration.S3bucketImagePath}/images/teacher/arena/rockets/rocket270001.png`,
    },
    {
      id: 39,
      name: "rocket280001",
      imageUrl: `${Configuration.S3bucketImagePath}/images/teacher/arena/rockets/rocket280001.png`,
    },
    {
      id: 40,
      name: "rocket290001",
      imageUrl: `${Configuration.S3bucketImagePath}/images/teacher/arena/rockets/rocket290001.png`,
    },
  ];

  public readonly Blasters = {
    cannons: [
      {
        id: 1,
        name: "cannon1",
        imageUrl: `${Configuration.S3bucketImagePath}/images/teacher/arena/blasters/cannon1.png`,
      },
      {
        id: 2,
        name: "cannon2",
        imageUrl: `${Configuration.S3bucketImagePath}/images/teacher/arena/blasters/cannon2.png`,
      },
      {
        id: 3,
        name: "cannon3",
        imageUrl: `${Configuration.S3bucketImagePath}/images/teacher/arena/blasters/cannon3.png`,
      },
      {
        id: 4,
        name: "cannon4",
        imageUrl: `${Configuration.S3bucketImagePath}/images/teacher/arena/blasters/cannon4.png`,
      },
    ],
    energies: [
      {
        id: 5,
        name: "energy1",
        imageUrl: `${Configuration.S3bucketImagePath}/images/teacher/arena/blasters/energy1.png`,
      },
      {
        id: 6,
        name: "energy2",
        imageUrl: `${Configuration.S3bucketImagePath}/images/teacher/arena/blasters/energy2.png`,
      },
      {
        id: 7,
        name: "energy3",
        imageUrl: `${Configuration.S3bucketImagePath}/images/teacher/arena/blasters/energy3.png`,
      },
      {
        id: 8,
        name: "energy4",
        imageUrl: `${Configuration.S3bucketImagePath}/images/teacher/arena/blasters/energy4.png`,
      },
    ],
    fires: [
      {
        id: 9,
        name: "fire1",
        imageUrl: `${Configuration.S3bucketImagePath}/images/teacher/arena/blasters/fire1.png`,
      },
      {
        id: 10,
        name: "fire2",
        imageUrl: `${Configuration.S3bucketImagePath}/images/teacher/arena/blasters/fire2.png`,
      },
      {
        id: 11,
        name: "fire3",
        imageUrl: `${Configuration.S3bucketImagePath}/images/teacher/arena/blasters/fire3.png`,
      },
      {
        id: 12,
        name: "fire4",
        imageUrl: `${Configuration.S3bucketImagePath}/images/teacher/arena/blasters/fire4.png`,
      },
    ],
    ices: [
      {
        id: 13,
        name: "ice1",
        imageUrl: `${Configuration.S3bucketImagePath}/images/teacher/arena/blasters/ice1.png`,
      },
      {
        id: 14,
        name: "ice2",
        imageUrl: `${Configuration.S3bucketImagePath}/images/teacher/arena/blasters/ice2.png`,
      },
      {
        id: 15,
        name: "ice3",
        imageUrl: `${Configuration.S3bucketImagePath}/images/teacher/arena/blasters/ice3.png`,
      },
      {
        id: 16,
        name: "ice4",
        imageUrl: `${Configuration.S3bucketImagePath}/images/teacher/arena/blasters/ice4.png`,
      },
    ],
    lazers: [
      {
        id: 17,
        name: "lazer1",
        imageUrl: `${Configuration.S3bucketImagePath}/images/teacher/arena/blasters/lazer1.png`,
      },
      {
        id: 18,
        name: "lazer2",
        imageUrl: `${Configuration.S3bucketImagePath}/images/teacher/arena/blasters/lazer2.png`,
      },
      {
        id: 19,
        name: "lazer3",
        imageUrl: `${Configuration.S3bucketImagePath}/images/teacher/arena/blasters/lazer3.png`,
      },
      {
        id: 20,
        name: "lazer4",
        imageUrl: `${Configuration.S3bucketImagePath}/images/teacher/arena/blasters/lazer4.png`,
      },
    ],
    missiles: [
      {
        id: 21,
        name: "missile1",
        imageUrl: `${Configuration.S3bucketImagePath}/images/teacher/arena/blasters/missile1.png`,
      },
      {
        id: 22,
        name: "missile2",
        imageUrl: `${Configuration.S3bucketImagePath}/images/teacher/arena/blasters/missile2.png`,
      },
      {
        id: 23,
        name: "missile3",
        imageUrl: `${Configuration.S3bucketImagePath}/images/teacher/arena/blasters/missile3.png`,
      },
      {
        id: 24,
        name: "missile4",
        imageUrl: `${Configuration.S3bucketImagePath}/images/teacher/arena/blasters/missile4.png`,
      },
    ],
  };
  public readonly defaultTeams = [
    {
      TeamId: "1",
      TeamName: "Space Aces",
    },
    {
      TeamId: "2",
      TeamName: "Galaxies",
    },
    {
      TeamId: "3",
      TeamName: "Comets",
    },
    {
      TeamId: "4",
      TeamName: "Shooting Stars",
    },
    {
      TeamId: "5",
      TeamName: "Area 51",
    },
    {
      TeamId: "6",
      TeamName: "Rocketeers",
    },
    {
      TeamId: "7",
      TeamName: "Supernovas",
    },
    {
      TeamId: "8",
      TeamName: "Aliens",
    },
    {
      TeamId: "9",
      TeamName: "Extra Terrestrials",
    },
    {
      TeamId: "10",
      TeamName: "Starburst",
    },
    {
      TeamId: "11",
      TeamName: "Asteroids",
    },
    {
      TeamId: "12",
      TeamName: "Astronauts",
    },
    {
      TeamId: "13",
      TeamName: "Space Pirates",
    },
    {
      TeamId: "14",
      TeamName: "Time Travelers",
    },
  ];

  public readonly GameChooseMode = {
    ElementaryStudyPlan: "ElementaryStudyPlan",
    KinderGartenStudyPlan: "KinderGartenStudyPlan",
    ElementaryLiftOffMyGalaxyStandard: "ElementaryLiftOffMyGalaxyStandard",
    KinderGartenLiftOffMyGalaxyStandard: "KinderGartenLiftOffMyGalaxyStandard",
    MiddleSchoolLiftOffMyGalaxyStandard: "MiddleSchoolLiftOffMyGalaxyStandard",
    MiddleSchoolStudyPlan: "MiddleSchoolStudyPlan",
    MiddleSchoolSummativeTest: "MiddleSchoolSummativeTest",
    MiddleSchoolDomainTest: "MiddleSchoolDomainTest",
    MiddleSchoolCannedTest: "MiddleSchoolCannedTest",
    ElementaryPracticeTest: "ElementaryPracticeTest",
    MiddleSchoolPracticeTest: "MiddleSchoolPracticeTest",
    KinderGartenPracticeTest: "KinderGartenPracticeTest",
  };

  public readonly Game = {
    BlastOff: 1,
    TapRocket: 2,
    RocketJump: 3,
    GalaxyDefender: 4,
    ZigZag: 5,
    WormHole: 6,
    SpaceKraken: 7,
    AlienInvasion: 8,
    BossBattles: 9,
    GalaxyLander: 10,
    SpaceRaid: 11,
    StarDust: 12,
    SpaceMatch: 13,
    GalacticEscape: 14,
    AlienRacer: 15,
  };

  public readonly CANNEDTEST_LOCKED = "Locked";

  public readonly USERACTIVITY = {
    Active: 1,
    InActive: 2,
    Ideal: 3,
  };
  public readonly Activity_Type_Enum = {
    Assignment: 1,
    StudyPlan: 2,
    Diagnostics: 3,
    SkillsPractice: 4,
    LiftOffDiagnostics: 5,
    LiftOffMyGalaxies: 6,
    Assessment: 7,
  };
  public readonly ThirdPartyIntegrationType = {
    CleverLink: "CleverLink",
    ClassLink: "ClassLink",
  };
  public readonly ReportType = {
    AllReports: "0",
    ProgressMonitoring: "1",
    ActivityResults: "2",
    LiftoffReports: "3",
    SchoolReports: "4",
  };
  public readonly CHATWIDOW = {
    ONE: 1,
    TWO: 2,
    THREE: 3,
    FOUR: 4,
  };

  public readonly Report_StudentActivityType = {
    ASSIGNMENT: "ASSIGNMENT",
    ASSESSMENT: "ASSESSMENT",
    STUDY_PLAN_QUESTIONS: "STUDY_PLAN_QUESTIONS",
    STUDY_PLAN_SUMMATIVE_TEST: "STUDY_PLAN_SUMMATIVE_TEST",
    STUDY_PLAN_DOMAIN_TEST: "STUDY_PLAN_DOMAIN_TEST",
    STUDY_PLAN_VIDEO: "STUDY_PLAN_VIDEO",
    DIAGNOSTIC_TEST: "DIAGNOSTIC_TEST",
    CANNED_TEST: "CANNED_TEST",
    LO_MY_GALAXY_STANDARD: "LO_MY_GALAXY_STANDARD",
    LO_DIAGNOSTIC_ASSIGNMENTS: "LO_DIAGNOSTIC_ASSIGNMENTS",
  };

  public readonly RemediationSource = {
    PROGRESSREPORT: 1 as number,
    ASSIGNMENT: 2 as number,
  };

  public readonly SUBJECTTYPE = {
    PL: 1,
    LIFTOFF: 2,
  };

  public readonly QuestionCategory = {
    Charts: 1,
    ClassifyMatchAndOrder: 3,
    FillInTheBlanks: 4,
    Graphing: 5,
    MultipleChoice: 8,
    Passages: 11,
    WrittenAndRecorded: 10,
    Math: 7,
    Highlight: 6,
  };

  public readonly stateIdToAbbreviation = {
    1: "AL",
    2: "AK",
    3: "AZ",
    4: "AR",
    5: "CA",
    6: "CO",
    7: "CT",
    8: "DE",
    9: "DM",
    10: "DC",
    11: "FL",
    12: "GA",
    13: "HI",
    14: "ID",
    15: "IL",
    16: "IN",
    17: "IA",
    18: "KS",
    19: "KY",
    20: "LA",
    21: "ME",
    22: "MD",
    23: "MA",
    24: "MI",
    25: "MN",
    26: "MS",
    27: "MO",
    28: "MT",
    29: "NT",
    30: "NE",
    31: "NV",
    32: "NH",
    33: "NJ",
    34: "NM",
    35: "NY",
    36: "NC",
    37: "ND",
    38: "OH",
    39: "OK",
    40: "OR",
    41: "PA",
    42: "PR",
    43: "RI",
    44: "SC",
    45: "SD",
    46: "TN",
    47: "TX",
    48: "UT",
    49: "VT",
    50: "VI",
    51: "VA",
    52: "WA",
    53: "WV",
    54: "WI",
    55: "WY",
  };
  public readonly ErrorType = {
    CREATEACTIVITYFAILED: 1,
  };
  public readonly ContentArea = {
    English: 1,
    Math: 2,
    Science: 3,
    SocialStudies: 4,
  };
  public readonly TTS = {
    MIN_LENGTH_STORAGE_TTS: 3000,
    MAX_LENGTH_STORAGE_TTS: 10000,
    SPEAKER_MODE_KEY: "speakerMode",
  };
  public readonly Diagnostic = "Diagnostic";
  public readonly StudentViewGradeLevel = [
    {
      gradeId: 1,
      name: "Kindergarten",
      gradeLevel: "Kindergarten",
    },
    {
      gradeId: 2,
      name: "1st Grade",
      gradeLevel: "First",
    },
    {
      gradeId: 3,
      name: "2nd Grade",
      gradeLevel: "Second",
    },
    {
      gradeId: 4,
      name: "3rd Grade",
      gradeLevel: "Third",
    },
    {
      gradeId: 5,
      name: "4th Grade",
      gradeLevel: "Fourth",
    },
    {
      gradeId: 6,
      name: "5th Grade",
      gradeLevel: "Fifth",
    },
    {
      gradeId: 9, //8th Grade student
      name: "6th - 12th Grade",
      gradeLevel: "Eighth",
    },
  ];

  public readonly TokenTransactionTypes = {
    StudentActivity: 1,
    StudentArcadeGame: 2,
  };

  public readonly CannedTestTypeId = {
    PreTest: 1 as number,
    PostTest: 2 as number,
    CannedTest: 3 as number,
    PracticeTest: 4 as number,
  };

  public readonly PermissionTypes = [
    "View",
    "Create",
    "Modify",
    "Delete",
    "Upgrade",
  ];
  public readonly RolesRequireUpgrade = [this.UserRoleId.FreeTeacher];
  public readonly TEACHER_FREE_ASSESSMENT_LIMIT = 5;
  public readonly TEACHER_FREE_AUTHOR_QUESTION_LIMIT = 5;
  public readonly UPGRADE_MESSAGE_SCHOOL_ACCOUNT = "#upgradePopup";
  public readonly UPGRADE_MESSAGE_SCHOOL_ACCOUNT_Popup =
      "Upgrade to a School Account to access this feature. You can request a quote by clicking";
  public readonly UPGRADE_MESSAGE_ASSESSMENT_LIMIT =
      "You have maxed out your assessments. Upgrade to a School Account to create more";
  public readonly UPGRADE_MESSAGE_ASSESSMENT_LIMIT_NEW =
      "You have reached the maximum number of assessments that your account allows. You can upgrade to a School Account to access unlimited assessments. You can request a quote by clicking";
  public readonly UPGRADE_MESSAGE_STUDENT_ACCOUNT =
      "This feature is not available for your account.";
  public readonly UPGRADE_AUTHOR_QUESTION_LIMIT =
      "You have maxed out your questions limit. Upgrade to a School Account to create more";
  public readonly UPGRADE_MESSAGE =
      "The feature is not available for your account, please upgrade.";
  public readonly SubscriptionTypes = {
    School: 1,
    TeacherPay: 2,
    TeacherFree: 3,
    Trial: 4,
    District: 5,
  };
  public readonly UPGRADE_THIRDPARTY =
      "This integration is not supported for your account. Please upgrade to a School account in order to access this service.";
  public readonly SECONDS_PER_DAY = 86400;

  public readonly VIDEO_PASSING_SCORE = 66;
  public readonly AssessmentTypes = {
    ASSESSMENT: "ASSESSMENT",
    PREMIUMASSESSMENT: "PREMIUMASSESSMENT",
    ASSIGNMENT: "ASSIGNMENT",
  };
  public readonly RedirectedFrom = {
    MYASSESSMENTS: "MYASSESSMENTS",
    PREMIUMASSESSMENTS: "PREMIUMASSESSMENTS",
    MYASSIGNMENTS: "MYASSIGNMENTS",
    FULLASSESSMENT: "FULLASSESSMENT",
    GRADEBOOK: "GRADEBOOK",
  };

  public readonly QuestionMapStatus = {
    CURRENT: "CURRENT",
    ANSWERED: "ANSWERED",
    NOTANSWERED: "NOTANSWERED",
  };

  public readonly ContextMenuActivityType = {
    ...this.AssessmentTypes,
    GROUPEDASSIGNMENT: "GROUPEDASSIGNMENT",
    GROUPEDACTIVITYASSIGNMENT: "GROUPEDACTIVITYASSIGNMENT",
    REMEDIATIONASSIGNMENT: "REMEDIATIONASSGINEMTN",
  };

  public readonly Theme = {
    KINDERGARTEN: "KINDERGARTEN",
    ELEMENTARY: "ELEMENTARYHOME",
    MIDDLESCHOOL: "MIDDLESCHOOLHOME",
  };

  public readonly SubjectDDLCSS = {
    groupStyles: {
      display: "flex",

      alignItems: "center",

      justifyContent: "space-between",

      fontSize: 14,
    },

    groupBadgeStyles: () => {
      let style: CSSProperties = {
        backgroundColor: "#EBECF0",

        borderRadius: "2em",

        color: "#172B4D",

        display: "inline-block",

        fontSize: 14,

        fontWeight: "normal",

        lineHeight: "1",

        minWidth: 1,

        padding: "0.16666666666667em 0.5em",

        textAlign: "center",
      };

      return style;
    },

    colourStyles: {
      option: (provided, state) => ({
        ...provided,

        color:
            state.label === "My Subjects" || state.label === "Additional Subjects"
                ? "#992883"
                : "default",
      }),
    },
  };


  public readonly LearnosityPlayerStorage = "LearnosityPlayerStorage";


  public readonly cannedTestTypeIds: Array<number> = [1, 2, 3, 4];

  public readonly MimeTypes = {
    MP4: "video/mp4",
    WEBM: "video/webm",
  }

  public readonly PLMinDate: Date = new Date(2022, 0, 1);
}

export default new Constant();

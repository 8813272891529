import { Fragment, useEffect, useState } from "react";
import Select from "react-select";
import DatePicker from "react-datepicker";
import MyAssignmentDetails from "../../../../model/teacher/assignment";
import {
  getAssignmentStudents,
  getAssignments,
  getAssignmentClasses,
  getAssignmentSubjects,
  updateAssignments,
} from "../../../../api/teacher/assignment";
import Moment from "moment";
import {
  LockIcon,
  UnLockIcon,
  DeleteIcon,
  QuestionIcon,
  CaretUpSolidIcon,
  CaretDownSolidIcon,
  OutlineSettingIcon,
} from "../../../../assets/icons";
import Constant from "../../../../utils/constant/constant";
import RouteConstant from "../../../../utils/constant/routeConstant";
import { ISelectElement } from "../../../../model/interface/selectElement";
import { IAssignmentSearch } from "../../../../model/interface/assignmentSearch";
import Loader from "../../../shared/loader";
import ConfirmationDialog from "../../../shared/confirmationDialog";
import { toast } from "react-toastify";
import { getActivityName } from "../../../../utils/assignmentHelper";
import Pagination from "../../../shared/pagination/pagination";
import PageSize from "../../../shared/pagination/pageSize";
import {
  DEFAULT_PAGE_NUMBER,
  DEFAULT_PAGE_SIZE,
} from "../../../../utils/pagingConstant";
import PagingResponse from "../../../../model/common/pagingResponse";
import Permissions from "../../../../utils/permissions";
import AllowedTo from "../../../shared/rbac";
import AccessDenied from "../../../shared/accessDenied";
import QuestionPreview from "../shared/questionPreview/questionPreview";
import { IAssessmentSearch } from "../../../../model/interface/assessmentSearch";
import { getAssessments } from "../../../../api/teacher/assessment";
import Assessment from "../../../../model/teacher/assessment/assessment";
import AssignmentSetting from "../shared/assignmentSetting";
import RemediationSetting from "./remediation/popup/remediationSetting";
import { isAllowViewAssignmentAssessment } from "../../../../utils/assessmentHelper";
import Subject from "../../../shared/dropdowns/subject";
import { getEndDate, getStartDate } from "../../../../utils/dateHelper";
import ArrowUpIcon from "@heroicons/react/outline/ArrowUpIcon";
import ArrowDownIcon from "@heroicons/react/outline/ArrowDownIcon";
import ContextMenu from "../shared/contextMenu";
import PrintDialog from "../shared/print/learnosityPrintPopUp";
import constant from "../../../../utils/constant/constant";

const dates = [
  { value: "1", label: "Due Date" },
  { value: "2", label: "Create Date" },
];
const options = [
  {
    value: "1",
    label: "Preview",
    roles: [
      Constant.UserRoleId.District,
      Constant.UserRoleId.SchoolAdmin,
      Constant.UserRoleId.SchoolTeacher,
      Constant.UserRoleId.FreeTeacher,
    ],
    isActive: true,
    isUpgrade: false,
  },
  {
    value: "2",
    label: "Print",
    roles: [
      Constant.UserRoleId.District,
      Constant.UserRoleId.SchoolAdmin,
      Constant.UserRoleId.SchoolTeacher,
      Constant.UserRoleId.FreeTeacher,
    ],
    isActive: true,
    isUpgrade: false,
  },
];
interface passedProps {
  userId: number;
  roleId: number;
  schoolYearId?: number;
  minDate?: Date;
  maxDate?: Date;
}

function RemediationAssignment(props: passedProps) {
  const defaultOrderByColumn: string = "DueDate";
  type ActionButton = "Lock" | "Archive" | "Delete" | "Duplicate" | "Unarchive";
  const defaultValue = { label: "Select", value: "0" };
  const assignedDefaultValue = { label: "All", value: "0" };
  const datesDefaultValue = { label: "Due Date", value: "1" };
  const { userId, roleId } = props;
  const [pageNumber, setPageNumber] = useState(DEFAULT_PAGE_NUMBER);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [resetPageNumber, setResetPageNumber] = useState<boolean>(false);
  const [selectedDateType, setSelectedDateType] =
    useState<ISelectElement>(datesDefaultValue);
  const [students, setStudents] = useState<Array<ISelectElement>>([]);
  const [selectedStudent, setSelectedStudent] = useState<ISelectElement>();
  const [selectedAssessment, setSelectedAssessment] =
    useState<ISelectElement>();
  const [classes, setClasses] = useState<Array<ISelectElement>>([]);
  const [selectedClass, setSelectedClass] = useState<ISelectElement>();
  const [subjects, setSubjects] = useState<any>([]);
  const [selectedSubject, setSelectedSubject] = useState<ISelectElement>();
  const [selectedAssigned, setSelectedAssigned] =
    useState<ISelectElement>(assignedDefaultValue);
  const [selectedFromDate, setFromDate] = useState<Date>();
  const [selectedToDate, setToDate] = useState<Date>();
  const [assignments, setAssignments] =
    useState<PagingResponse<MyAssignmentDetails>>();
  const [name, setName] = useState<string>("");
  const [selectedAssignments, setSelectedAssignments] = useState<Array<number>>(
    []
  );
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [showConfirmation, setshowConfirmation] = useState<boolean>(false);
  const [confirmationMessage, setConfirmationMessage] = useState<string>("");
  const [buttonAction, setButtonAction] = useState<ActionButton>();
  const [toggleSelectAll, setToggleSelectAll] = useState<boolean>(false);
  const [orderByColumn, setOrderByColumn] = useState<string>("DueDate");
  const [sortByDesc, setSortByDesc] = useState<boolean>(true);
  const [activityPreviewDetail, setActivityPreviewDetail] = useState<{
    previewId: string;
    activityIdPreview: number;
    activityName: string;
    activityType: string;
    standardId: number;
  }>();
  const [open, setOpen] = useState<boolean>(false);
  const [assessments, setAssessments] = useState<Array<ISelectElement>>();
  const [showDetailPopup, setShowDetailPopup] = useState<boolean>(false);
  const [selectedAssignment, setSelectedAssignment] =
    useState<MyAssignmentDetails>();
  const [showEditSettingPopup, setShowEditSettingPopup] =
    useState<boolean>(false);
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [selectedLearnosityId, setSelectedLearnosityId] = useState<string>("");
  const [showPrintPopup, setShowPrintPopup] = useState<boolean>(false);
  const [activityName, setActivityName] = useState<string>("");

  const openModal = (
    learnosityReferenceId,
    activityId,
    activityName,
    standardId
  ) => {
    if (!learnosityReferenceId) {
      return false;
    }

    setOpen(true);
    setActivityPreviewDetail({
      previewId: learnosityReferenceId,
      activityIdPreview: activityId,
      activityName: Constant.AssignmentActivityType.PRACTICEQUESTIONS,
      activityType: Constant.AssignmentActivityType.PRACTICEQUESTIONS,
      standardId: standardId,
    });
  };

  const popUpClose = (value) => {
    setOpen(value);
    setActivityPreviewDetail(undefined);
  };

  const handleAssignmentDetailPopup = (assignment: MyAssignmentDetails) => {
    setShowDetailPopup(true);
    setSelectedAssignment(assignment);
  };

  const handleEditAssignmentPopup = (assignment: MyAssignmentDetails) => {
    setSelectedAssignment(assignment);
    setShowEditSettingPopup(true);
  };

  function handleStudentChange(selectdItem: any) {
    setSelectedStudent(selectdItem);
  }

  function handleClassChange(selectdItem: any) {
    setSelectedClass(selectdItem);
  }

  function handleSubjectChange(selectdItem: any) {
    setSelectedSubject(selectdItem);
  }

  function handleAssessmentChange(selectdItem: any) {
    setSelectedAssessment(selectdItem);
  }

  function bindAssessments() {
    let assessments: Array<ISelectElement> = [];

    const assessmentSearch: IAssessmentSearch = {
      teacherId: userId,
      viewMyAssessmentOnly: true,
    };

    getAssessments(assessmentSearch).then((r) => {
      const data = r.data.data;
      if (data && data.length > 0) {
        data.map((assessment: Assessment) => {
          assessments.push({
            label: `${assessment.name}-${assessment.code}`,
            value: assessment.assessmentId,
          });
          return null;
        });
      }
    });

    setAssessments(assessments);
  }

  function handleDateChange(selectdItem: any) {
    setSelectedDateType(selectdItem);
  }

  function handleFromDateChange(selectedItem: any) {
    if (selectedToDate && selectedItem > selectedToDate) {
      return false;
    }
    setFromDate(selectedItem);
  }

  function handleToDateChange(selectedItem: any) {
    if (selectedFromDate && selectedItem < selectedFromDate) {
      return false;
    }
    setToDate(selectedItem);
  }

  function handleUpdateAssignments(
    action: string,
    isArchived: boolean = false
  ) {
    const assignmentIds = selectedAssignments.join(",");
    setShowLoading(true);
    updateAssignments(userId, assignmentIds, action, isArchived).then(
      (response) => {
        setShowLoading(false);
        var message = "";
        switch (action) {
          case Constant.AssignmentUpdateAction.LOCK:
            message = "Selected assignments locked/unlocked successfully.";
            break;
          case Constant.AssignmentUpdateAction.DELETE:
            message = "Selected assignments deleted successfully.";
            break;
          case Constant.AssignmentUpdateAction.ARCHIVE:
            message = `Selected assignments ${
              isArchived ? "archived" : "unarchived"
            } successfully.`;
            break;
        }
        toast.success(message);
        searchAssignments();
      }
    );
  }

  function isDefaultLoading() {
    let isDefaultLoading: boolean = false;
    if (
      (!selectedStudent || selectedStudent?.value === defaultValue.value) &&
      (!selectedClass || selectedClass?.value === defaultValue.value) &&
      (!selectedSubject || selectedSubject?.value === defaultValue.value) &&
      (!selectedAssigned ||
        selectedAssigned.value === assignedDefaultValue.value) &&
      (!selectedDateType ||
        selectedDateType.value === datesDefaultValue.value) &&
      name === "" &&
      selectedFromDate === undefined &&
      selectedToDate === undefined &&
      orderByColumn === "DueDate" &&
      sortByDesc === false
    ) {
      isDefaultLoading = true;
    }

    return isDefaultLoading;
  }

  function resetFilters() {
    setSelectedStudent(defaultValue);
    setSelectedClass(defaultValue);
    setSelectedSubject(defaultValue);
    setSelectedAssessment(defaultValue);
    setSelectedAssigned(assignedDefaultValue);
    setSelectedDateType(datesDefaultValue);
    setName("");
    setFromDate(undefined);
    setToDate(undefined);

    const assignmentSearch: IAssignmentSearch = {
      teacherId: userId,
      assignmentType: "REMEDIATIONASSIGNMENT",
      orderByColumn: defaultOrderByColumn,
      pageNumber: DEFAULT_PAGE_NUMBER,
      pageSize: DEFAULT_PAGE_SIZE,
      sortByDesc: true,
      schoolYearId: props.schoolYearId,
    };

    setShowLoading(true);
    getAssignments(assignmentSearch).then((r) => {
      setAssignments(r.data);
      setSelectedAssignments([]);
      setShowLoading(false);
      setPageNumber(DEFAULT_PAGE_NUMBER);
      setResetPageNumber(!resetPageNumber);
    });
  }

  const handleSortBy = (column: string) => {
    setSortByDesc(!sortByDesc);
    setOrderByColumn(column);
  };

  function searchAssignments(resetPageNumber: boolean = false) {
    let utcFromDate: Date | undefined;
    let utcToDate: Date | undefined;

    if(selectedFromDate != null)
      utcFromDate = getStartDate(selectedFromDate);
    if(selectedToDate != null)
      utcToDate = getEndDate(selectedToDate);

    if (resetPageNumber) {
      setPageNumber(DEFAULT_PAGE_NUMBER);
      setResetPageNumber(!resetPageNumber);
    }
    setShowLoading(true);
    let orderByColumnValue: string = orderByColumn;
    if (isDefaultLoading()) {
      orderByColumnValue = defaultOrderByColumn;
    }
    const assignmentSearch: IAssignmentSearch = {
      teacherId: userId,
      studentId:
        selectedStudent && selectedStudent.value !== "0"
          ? parseInt(selectedStudent.value)
          : undefined,
      classId:
        selectedClass && selectedClass.value !== "0"
          ? parseInt(selectedClass.value)
          : undefined,
      subjectId:
        selectedSubject && selectedSubject.value !== "0"
          ? parseInt(selectedSubject.value)
          : undefined,
      assessmentId:
        selectedAssessment && selectedAssessment.value !== "0"
          ? parseInt(selectedAssessment.value)
          : undefined,
      isAssigned: selectedAssigned
        ? selectedAssigned.value === "0"
          ? undefined
          : selectedAssigned.value === "1"
          ? true
          : false
        : undefined,
      dateType:
        selectedDateType &&
        selectedFromDate &&
        selectedToDate &&
        selectedDateType.value !== "0"
          ? selectedDateType.label
          : undefined,
      fromDate: utcFromDate,
      toDate: utcToDate,
      name: name,
      assignmentType: "REMEDIATIONASSIGNMENT",
      pageNumber: resetPageNumber ? DEFAULT_PAGE_NUMBER : pageNumber,
      pageSize: pageSize,
      orderByColumn: orderByColumnValue,
      sortByDesc: sortByDesc,
      schoolYearId: props.schoolYearId,
    };

    getAssignments(assignmentSearch).then((r) => {
      setAssignments(r.data);
      setSelectedAssignments([]);
      setShowLoading(false);
      setToggleSelectAll(false);
    });
  }

  function bindStudents() {
    setShowLoading(true);
    getAssignmentStudents(userId, roleId, false, true).then((response) => {
      setShowLoading(false);
      const studentsData = response.data.data;
      var students: Array<ISelectElement> = new Array<ISelectElement>();

      studentsData.forEach(function (keyValuePair: any) {
        students.push({ label: keyValuePair.name, value: keyValuePair.id });
      });
      setStudents(students);
    });
  }

  function bindClasses() {
    setShowLoading(true);
    getAssignmentClasses(userId, roleId, false, true).then((response) => {
      setShowLoading(false);
      const classData = response.data.data;
      var classes: Array<ISelectElement> = new Array<ISelectElement>();

      classData.forEach(function (keyValuePair: any) {
        classes.push({ label: keyValuePair.name, value: keyValuePair.id });
      });

      setClasses(classes);
    });
  }

  function bindSubjects() {
    setShowLoading(true);
    getAssignmentSubjects(userId, roleId, false, true).then((response) => {
      setShowLoading(false);
      const subjectsData = response.data;
      setSubjects(subjectsData);
    });
  }

  function toggleEditSettingPopup(
    popupAction: boolean,
    refreshData: boolean = false
  ) {
    setShowEditSettingPopup(popupAction);
    if (refreshData) {
      searchAssignments();
    }
  }

  function handleSelectedAssignments(assignmentId: number) {
    var selectedAssignmentList: Array<number> = new Array<number>();

    const assignmentExist = selectedAssignments.some(
      (r) => (r as number) === assignmentId
    );

    if (assignmentExist) {
      selectedAssignmentList = selectedAssignments.filter(
        (r) => (r as number) !== assignmentId
      );
    } else {
      selectedAssignmentList = [...selectedAssignments, assignmentId];
    }

    setSelectedAssignments(selectedAssignmentList);
  }

  function handleSelectAll() {
    const isSelected = !toggleSelectAll;
    if (isSelected && assignments && assignments.data) {
      setSelectedAssignments(
        assignments.data.map((r) => {
          return parseInt(r.assignmentId);
        })
      );
    } else {
      setSelectedAssignments([]);
    }
    setToggleSelectAll(!toggleSelectAll);
  }

  function handleConfirmation(action: ActionButton, assignmentId: number = 0) {
    setButtonAction(action);
    setshowConfirmation(true);
    var message: string = "";
    switch (action) {
      case "Lock":
        message =
          "Are you sure you want to lock/unlock the selected assignment(s)?";
        break;
      case "Archive":
        message =
          "Are you sure you want to archive the selected assignment(s)?";
        break;
      case "Delete":
        message =
          "Are you sure you want to delete the selected assignment(s)? <br /><br /> This will remove the assignment(s) from your class gradebook. <br /><br /> This CANNOT be undone!";
        break;
      case "Unarchive":
        message =
          "Are you sure you want to unarchive the selected assignment(s)?";
        break;
    }

    setConfirmationMessage(message);
  }

  function handleOkConfirmation() {
    setshowConfirmation(false);
    setToggleSelectAll(!toggleSelectAll);
    switch (buttonAction) {
      case "Lock":
        handleUpdateAssignments(Constant.AssignmentUpdateAction.LOCK);
        break;
      case "Archive":
        handleUpdateAssignments(Constant.AssignmentUpdateAction.ARCHIVE, true);
        break;
      case "Unarchive":
        handleUpdateAssignments(Constant.AssignmentUpdateAction.ARCHIVE, false);
        break;
      case "Delete":
        handleUpdateAssignments(Constant.AssignmentUpdateAction.DELETE);
        break;
    }
  }

  function handleCancelConfirmation() {
    setshowConfirmation(false);
    setButtonAction(undefined);
    setConfirmationMessage("");
  }

  const onPageChange = (pageNo) => {
    setPageNumber(pageNo);
  };

  const onPageSizeChange = (pageSize) => {
    setPageNumber(DEFAULT_PAGE_NUMBER);
    setPageSize(pageSize);
    setResetPageNumber(true);
  };

  function getActivityRef(activityType: string, reference: string) {
    let referenceId: string | null = null;
    switch (activityType) {
      case Constant.AssignmentActivityType.ASSESSMENT:
      case Constant.AssignmentActivityType.PREBUILTTESTS:
      case Constant.AssignmentActivityType.PRACTICEQUESTIONS:
        referenceId = reference;
        break;
    }

    return referenceId;
  }

  function getItemRef(activityType: string, reference: string) {
    let referenceId: string | null = null;
    switch (activityType) {
      case Constant.AssignmentActivityType.VIDEOLESSON:
      case Constant.AssignmentActivityType.CONSTRUCTEDRESPONSE:
        referenceId = reference;
        break;
    }

    return referenceId;
  }

  useEffect(() => {
    bindAssessments();
    searchAssignments();
  }, [pageSize, pageNumber, sortByDesc]);

  useEffect(() => {
    bindStudents();
    bindClasses();
    bindSubjects();
  }, []);

  const handleResultsClick = (
    assignmentId: number,
    activityId: number,
    activityType: string,
    hasCRActivity: boolean = false,
    canViewAssessment?: boolean,
    assessmentSharedTypeId?: number,
    assessmentCreatedByUserId?: number,
    assessmentId?: string | undefined,
    schoolYearId?: number,
    subjectId?: number
  ) => {
    if (
      !isAllowViewAssignmentAssessment(
        props.roleId,
        canViewAssessment,
        activityType,
        assessmentCreatedByUserId,
        props.userId,
        assessmentSharedTypeId
      )
    )
    {
      return;
    }
      
    const pathname = `${RouteConstant.GROUPED_RESULT_REPORTS}?assignmentId=${assignmentId}&redirectedFrom=MYASSIGNMENTS`+
    `&assignmentType=${constant.AssignmentType.REMEDIATIONASSIGNMENT}&subjectId=${subjectId}&schoolYearId=${props.schoolYearId}`;
    window.open(pathname, "_blank");
  };

  function handleOptionChange(
    selectdItem: any,
    assignmentActivityId: number,
    learnosityId: string
  ) {
    const assignment = assignments?.data.filter(
      (a) => a.assignmentActivity?.assignmentActivityId == assignmentActivityId
    )[0];
    setActivityName(assignment?.name ?? "");
    switch (selectdItem.label) {
      case "Print":
        if (isActivityValid(learnosityId)) {
          handlePrintPopup(learnosityId);
        }
        break;
      case "Preview":
        if (validatePreview(assignment)) {
          openModal(
            assignment?.assignmentActivity?.learnosityIdEnglish,
            assignment?.assignmentActivity?.assignmentActivityId,
            assignment?.assignmentActivity
              ? getActivityName(assignment.assignmentActivity)
              : "",
            assignment?.assignmentActivity?.standardId
          );
        }
        break;
    }
  }
  const validatePreview = (assignment: MyAssignmentDetails | undefined) => {
    return isAllowViewAssignmentAssessment(
      props.roleId,
      assignment?.assignmentActivity?.canViewAssessment,
      assignment?.assignmentActivity?.activityType,
      assignment?.assignmentActivity?.assessmentCreatedByUserId,
      props.userId,
      assignment?.assignmentActivity?.assessmentSharedTypeId
    );
  };

  function handlePrintPopup(learnosityId: string) {
    setSelectedLearnosityId(learnosityId);
    setShowPrintPopup(true);
  }

  function isActivityValid(learnosityId: string) {
    if (learnosityId === "") {
      return false;
    }
    return true;
  }

  const handleCancelPrint = () => {
    setShowPrintPopup(false);
    setSelectedLearnosityId("");
  };
  return (
    <AllowedTo
      perform={Permissions.remediations_view}
      no={() => <AccessDenied />}
    >
      {showLoading && <Loader></Loader>}
      {showPrintPopup && (
        <PrintDialog
          learnosityId={selectedLearnosityId}
          onCancelClick={handleCancelPrint}
          open={showPrintPopup}
          isAnswerKey={false}
          activityName={activityName}
          code={""}
          isAssignment={true}
        ></PrintDialog>
      )}
      {showDetailPopup && selectedAssignment && (
        <AssignmentSetting
          assignment={selectedAssignment}
          activity={undefined}
          toggleSettingPopup={setShowDetailPopup}
          isRemediation={true}
        />
      )}

      {showEditSettingPopup && selectedAssignment && (
        <RemediationSetting
          userId={userId}
          assignmentId={selectedAssignment.assignmentId}
          assignmentName={selectedAssignment.name}
          subjectId={selectedAssignment.subjectId}
          startDate={selectedAssignment.startDate}
          endDate={selectedAssignment.endDate}
          schoolYearId={selectedAssignment.schoolYearId}
          toggleSettingPopup={toggleEditSettingPopup}
        />
      )}

      {activityPreviewDetail && (
        <QuestionPreview
          modal={openModal}
          show={open}
          changeValue={popUpClose}
          activityRef={getActivityRef(
            activityPreviewDetail.activityType,
            activityPreviewDetail.previewId
          )}
          itemRef={getItemRef(
            activityPreviewDetail.activityType,
            activityPreviewDetail.previewId
          )}
          activityIdPreview={activityPreviewDetail.activityIdPreview}
          activityName={activityPreviewDetail.activityName}
          standardId={activityPreviewDetail.standardId}
        ></QuestionPreview>
      )}
      <ConfirmationDialog
        open={showConfirmation}
        confirmationTitle={"Please confirm"}
        confirmationMessage={confirmationMessage}
        onCancelClick={handleCancelConfirmation}
        onOkClick={handleOkConfirmation}
        okButtonText={buttonAction === "Delete" ? "Delete" : undefined}
        cancelButtonText={
          buttonAction === "Delete" ? "Don't Delete" : undefined
        }
      ></ConfirmationDialog>
      <div className="min-w-0 block lg:col-span-9 xl:col-span-10 w-full">
        <div className="bg-gray-100  w-full mb-5 rounded-lg p-5 h-full">
          <div className="flex flex-col mb-5">
            <div
              className="flex relative items-center cursor-pointer"
              onClick={() => {
                if (!showFilter) {
                  setShowFilter(true);
                } else {
                  setShowFilter(false);
                }
              }}
            >
              <span className="bg-black w-10 h-10 rounded-full inline-block flex items-center justify-center cursor-pointer">
                {!showFilter && (
                  <ArrowDownIcon className="w-6 text-white opacity-90" />
                )}
                {showFilter && (
                  <ArrowUpIcon className="w-6 text-white opacity-90" />
                )}
              </span>
              <span className="mx-2 text-gray-700">
                {!showFilter
                  ? "Expand Filter Options"
                  : "Collapse Filter Options"}
              </span>
              <span className="text-sm ml-auto">
                This tab displays current school year activities only.
              </span>
            </div>
            <div
              className={`${
                showFilter
                  ? "max-h-[1000px] opacity-100 mt-5 pb-2"
                  : "max-h-0 opacity-0 -mt-5 pb-0 pointer-events-none"
              } w-full bg-white transform transition-all ease-in-out duration-300 relative z-0 p-5 shadow `}
            >
              <div className="grid grid-cols-2 sm:grid-cols-8 lg:grid-cols-8 xl:grid-cols-8 gap-6 pb-4">
                <div className="col-span-8 sm:col-span-4 xl:col-span-2">
                  <label className="block text-sm text-gray-500">Student</label>
                  <Select
                    className="mt-1"
                    value={selectedStudent}
                    options={students}
                    onChange={handleStudentChange}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: "#008fbb",
                      },
                    })}
                  />
                </div>
                <div className="col-span-8 sm:col-span-4 xl:col-span-2">
                  <label className="block text-sm text-gray-500">Class</label>
                  <Select
                    className="mt-1"
                    value={selectedClass}
                    options={classes}
                    onChange={handleClassChange}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: "#008fbb",
                      },
                    })}
                  />
                </div>
                <div className="col-span-8 sm:col-span-4 xl:col-span-2">
                  <Subject
                    setSelectedSubject={(items) =>
                      handleSubjectChange(items[0])
                    }
                    selectedItems={selectedSubject}
                    isMulti={false}
                    themeColor={"#008fbb"}
                    subjects={subjects}
                  ></Subject>
                </div>
                <div className="col-span-8 sm:col-span-4 xl:col-span-2">
                  <label className="block text-sm text-gray-500">
                    Assessment
                  </label>
                  <Select
                    className="mt-1"
                    value={selectedAssessment}
                    options={assessments}
                    onChange={handleAssessmentChange}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: "#008fbb",
                      },
                    })}
                  />
                </div>
                <div className="col-span-8 sm:col-span-4 xl:col-span-2">
                  <label className="block text-sm text-gray-500">Dates</label>
                  <Select
                    className="mt-1"
                    value={selectedDateType}
                    options={dates}
                    onChange={handleDateChange}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: "#008fbb",
                      },
                    })}
                  />
                </div>
                <div className="col-span-8 sm:col-span-8 lg:col-span-4 xl:col-span-4 xxxl:col-span-2 grid grid-cols-2 gap-6">
                  <div>
                    <label className="block text-sm text-gray-500" htmlFor="">
                      From{" "}
                    </label>
                    <DatePicker
                      wrapperClassName="w-full mt-1"
                      className="react-datepicker__input-container py-1.5 px-3 block w-full shadow-sm focus:ring-primary-violet focus:border-primary-violet border border-gray-300 rounded-md text-gray-500"
                      selected={selectedFromDate}
                      onChange={(date) => handleFromDateChange(date)}
                      minDate={props.minDate}
                      maxDate={props.maxDate}
                    />
                  </div>
                  <div>
                    <label className="block text-sm text-gray-500" htmlFor="">
                      To{" "}
                    </label>
                    <DatePicker
                      wrapperClassName="w-full"
                      className="react-datepicker__input-container mt-1 py-1.5 px-3 block w-full shadow-sm focus:ring-primary-violet focus:border-primary-violet border text-gray-500 border-gray-300 rounded-md "
                      selected={selectedToDate}
                      onChange={(date) => handleToDateChange(date)}
                      minDate={props.minDate}
                      maxDate={props.maxDate}
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-col md:flex-row gap-4 items-end md:items-center md:justify-end justify-start pb-5">
                <div className="grid grid-cols-2 gap-4">
                  <button
                    onClick={() => resetFilters()}
                    className="bg-transparent border my-auto border-primary-violet shadow-sm py-2 px-4 flex justify-center text-sm font-medium text-primary-violet hover:text-white hover:bg-light-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3 transition ease-in-out duration-500"
                  >
                    {" "}
                    Reset
                  </button>
                  <button
                    onClick={() => searchAssignments(true)}
                    className="bg-primary-violet border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-dark-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3 "
                  >
                    Filter
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-white py-5 px-5 shadow">
            <div className="flex flex-col md:flex-row justify-start gap-x-4 gap-y-3 mb-5 mt-2">
              <AllowedTo perform={Permissions.assignments_modify}>
                <button
                  disabled={selectedAssignments.length > 0 ? false : true}
                  onClick={() => handleConfirmation("Lock")}
                  className={`${
                    selectedAssignments.length > 0
                      ? "hover:text-white hover:bg-light-violet hover:shadow-lg"
                      : "opacity-50 cursor-not-allowed"
                  } bg-transparent border border-primary-violet shadow-sm py-2 px-4 inline-flex items-center justify-center text-sm font-medium text-primary-violet  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3 transition ease-in-out duration-500`}
                >
                  <LockIcon className="w-4" />
                  <span className="ml-2">Lock/Unlock</span>
                </button>
              </AllowedTo>
              <AllowedTo perform={Permissions.assignments_delete}>
                <button
                  disabled={selectedAssignments.length > 0 ? false : true}
                  onClick={() => handleConfirmation("Delete")}
                  className={`${
                    selectedAssignments.length > 0
                      ? "hover:text-white hover:bg-red-500 hover:shadow-lg"
                      : "opacity-50 cursor-not-allowed"
                  } className="bg-transparent group border border-red-500 shadow-sm py-2 px-4 inline-flex items-center justify-center text-sm font-medium text-red-500 right-6 top-3 transition ease-in-out duration-500 "`}
                >
                  <DeleteIcon className="w-4" />
                  <span className="ml-2">Delete</span>
                </button>
              </AllowedTo>
            </div>
            <div className="mb-4 pb-4 border-b-2 overflow-auto">
              <table className="table w-full ">
                <thead className="mb-5">
                  <tr className="bg-light-violet  text-white py-3 px-3 overflow-hidden">
                    <th className="text-left p-3 text-sm font-normal w-4">
                      <input
                        id="styled-checkbox-2"
                        type="checkbox"
                        className="checkbox"
                        checked={toggleSelectAll}
                        onChange={() => handleSelectAll()}
                      />
                    </th>
                    <th className="text-left p-3 text-sm font-normal w-4"></th>
                    {selectedDateType &&
                      selectedDateType.label === "Create Date" && (
                        <th className="text-left  p-3 text-sm font-normal">
                          <span className="flex">
                            Create Date
                            <span className="cursor-pointer">
                              {sortByDesc && orderByColumn === "CreateDate" ? (
                                <CaretUpSolidIcon
                                  onClick={() => handleSortBy("CreateDate")}
                                  className="w-5 h-5"
                                ></CaretUpSolidIcon>
                              ) : (
                                <CaretDownSolidIcon
                                  onClick={() => handleSortBy("CreateDate")}
                                  className="w-5 h-5"
                                ></CaretDownSolidIcon>
                              )}
                            </span>
                          </span>
                        </th>
                      )}

                    {selectedDateType &&
                      selectedDateType.label === "Due Date" && (
                        <th className="text-left p-3 text-sm font-normal">
                          <span className="flex whitespace-nowrap">
                            Due Date
                            <span className="cursor-pointer">
                              {sortByDesc && orderByColumn === "DueDate" ? (
                                <CaretUpSolidIcon
                                  onClick={() => handleSortBy("DueDate")}
                                  className="w-5 h-5"
                                ></CaretUpSolidIcon>
                              ) : (
                                <CaretDownSolidIcon
                                  onClick={() => handleSortBy("DueDate")}
                                  className="w-5 h-5"
                                ></CaretDownSolidIcon>
                              )}
                            </span>
                          </span>
                        </th>
                      )}
                    <th className="text-center  p-3 text-sm font-normal">
                      Type
                    </th>
                    <th className="text-left  p-3 text-sm font-normal">
                      <span className="flex">
                        Name
                        <span className="cursor-pointer">
                          {sortByDesc && orderByColumn === "name" ? (
                            <CaretUpSolidIcon
                              onClick={() => handleSortBy("name")}
                              className="w-5 h-5"
                            ></CaretUpSolidIcon>
                          ) : (
                            <CaretDownSolidIcon
                              onClick={() => handleSortBy("name")}
                              className="w-5 h-5"
                            ></CaretDownSolidIcon>
                          )}
                        </span>
                      </span>
                    </th>
                    <th className="text-center  p-3 text-sm font-normal">
                      Results
                    </th>
                    <th className="text-center  p-3 text-sm font-normal">
                      Assessment Code
                    </th>
                    <th className="text-center p-3 text-sm font-normal">
                      Action
                    </th>
                    <th className="text-center  p-3 text-sm font-normal">
                      Settings
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {assignments &&
                    assignments.data?.length > 0 &&
                    assignments.data?.map((assignment, index) => {
                      return (
                        <Fragment key={index}>
                          <tr
                            className={`hover:bg-light-violet/20 transition ease-in-out duration-300 ${
                              index % 2 === 0
                                ? "bg-white"
                                : "bg-light-violet/10"
                            }`}
                          >
                            <td className=" mt-5 p-3 text-center">
                              <input
                                id="styled-checkbox-2"
                                type="checkbox"
                                className="checkbox"
                                checked={selectedAssignments.some(
                                  (r) =>
                                    (r as number) ===
                                    parseInt(assignment.assignmentId)
                                )}
                                onChange={() =>
                                  handleSelectedAssignments(
                                    parseInt(assignment.assignmentId)
                                  )
                                }
                              />
                              <label className="checkbox-label"></label>
                            </td>
                            <td className=" mt-5 p-3 text-center text-gray-500">
                              {assignment.isLocked ? (
                                <LockIcon
                                  title="Locked assignment"
                                  className="fill-current w-4"
                                />
                              ) : (
                                <UnLockIcon
                                  title="Open assignment"
                                  className="w-4"
                                />
                              )}
                            </td>
                            {selectedDateType &&
                              selectedDateType.label === "Create Date" && (
                                <td className="text-sm mt-5  p-3">
                                  {Moment(assignment.createdDate).format(
                                    "MM-DD-yyyy"
                                  )}
                                </td>
                              )}

                            {selectedDateType &&
                              selectedDateType.label === "Due Date" && (
                                <td className="text-sm mt-5  p-3">
                                  {assignment.endDate &&
                                    Moment(assignment.endDate).format(
                                      "MM-DD-yyyy"
                                    )}
                                </td>
                              )}

                            <td className="text-sm mt-5 p-3 text-center">
                              <div className="w-5 h-5 mx-auto flex justify-center">
                                <QuestionIcon
                                  title="Questions"
                                  className="w-4 opacity-40 text-gray-500 hover:text-gray-700"
                                />
                              </div>
                            </td>
                            <td className="text-sm mt-5  p-3">
                              <a
                                href="#!"
                                className="text-blue-500 underline hover:no-underline"
                                onClick={() =>
                                  handleAssignmentDetailPopup(assignment)
                                }
                              >
                                {assignment.name}
                              </a>
                            </td>
                            <td
                              className={`text-sm mt-5 text-center p-3  ${
                                assignment.completed > 0 &&
                                isAllowViewAssignmentAssessment(
                                  props.roleId,
                                  assignment.assignmentActivity
                                    ?.canViewAssessment,
                                  assignment.assignmentActivity?.activityType,
                                  assignment.assignmentActivity
                                    ?.assessmentCreatedByUserId,
                                  props.userId,
                                  assignment.assignmentActivity
                                    ?.assessmentSharedTypeId
                                )
                                  ? "cursor-pointer text-blue-500 underline hover:no-underline"
                                  : ""
                              }`}
                              onClick={() =>
                                assignment.completed > 0
                                  ? handleResultsClick(
                                      parseInt(assignment.assignmentId),
                                      assignment.assignmentActivity
                                        ? assignment.assignmentActivity
                                            .assignmentActivityId
                                        : 0,
                                      assignment.assignmentActivity
                                        ?.activityType ?? "",
                                      assignment.assignmentActivity
                                        ?.activityType ===
                                        Constant.AssignmentActivityType
                                          .CONSTRUCTEDRESPONSE ||
                                        assignment.assignmentActivity
                                          ?.isAssessmentWithCR!,
                                      assignment.assignmentActivity
                                        ?.canViewAssessment,
                                      assignment.assignmentActivity
                                        ?.assessmentSharedTypeId,
                                      assignment.assignmentActivity
                                        ?.assessmentCreatedByUserId,
                                      assignment.assignmentActivity
                                        ?.assessmentId,
                                      assignment.schoolYearId,
                                      assignment.subjectId
                                    )
                                  : undefined
                              }
                            >
                              {`${assignment.completed}/${assignment.assigned}`}
                            </td>
                            <td className="text-sm text-center mt-5  p-3">
                              {assignment?.assessmentCode}
                            </td>
                            <td className="text-sm text-center mt-5">
                              <ContextMenu
                                items={options.filter((r) =>
                                  r.roles.some((r) => r === roleId)
                                )}
                                entityId={
                                  assignment.assignmentActivity
                                    ?.assignmentActivityId
                                }
                                onChange={(
                                  selectdItem,
                                  assessmentId,
                                  learnosityId,
                                  _activityName,
                                  _activityType,
                                  _value
                                ) => {
                                  handleOptionChange(
                                    selectdItem,
                                    assessmentId,
                                    learnosityId
                                  );
                                }}
                                learnosityId={
                                  assignment.assignmentActivity
                                    ?.learnosityIdEnglish
                                }
                                activityName={assignment.name}
                                value={""}
                                onUpgrade={() => {}}
                                activityType={
                                  constant.ContextMenuActivityType
                                    .REMEDIATIONASSIGNMENT
                                }
                              />
                            </td>
                            <td className="text-sm mt-5  p-3 cursor-pointer">
                              <OutlineSettingIcon
                                title="Edit assignment settings"
                                className="w-4 m-auto text-gray-500 hover:text-gray-700 cursor-pointer"
                                onClick={() =>
                                  handleEditAssignmentPopup(assignment)
                                }
                              />
                            </td>
                          </tr>
                        </Fragment>
                      );
                    })}
                  {assignments?.data.length === 0 && (
                    <tr>
                      <td colSpan={9}>
                        <div className="text-gray-700 w-full mt-2">
                          No records found
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="sm:flex-1 sm:flex sm:items-center sm:justify-between">
              {assignments?.totalRecords !== undefined &&
                assignments?.totalRecords !== 0 && (
                  <Pagination
                    totalRecords={assignments?.totalRecords}
                    pageSize={pageSize}
                    onPageChange={onPageChange}
                    reset={resetPageNumber}
                    pageNumber={pageNumber}
                  />
                )}
              {assignments?.totalRecords !== 0 && (
                <PageSize
                  pageSize={pageSize}
                  onPageSizeChange={onPageSizeChange}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </AllowedTo>
  );
}

export default RemediationAssignment;

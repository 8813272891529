import Select from "react-select";
import { AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  getTestComparisonReportHeader,
  getTestComparisonStudentsExport,
  getTestComparisonStudentDetailsExport,
} from "../../../../../api/report/testComparisonReport";
import { getAssessmentFilterForTestComparisonReport } from "../../../../../api/teacher/assessment";
import {
  ISelectElement,
  ISelectElementAssessmentComparisonReport,
} from "../../../../../model/interface/selectElement";
import TestHeader from "../../../../../model/report/testComparison/testHeader";
import constant from "../../../../../utils/constant/constant";
import Assessment from "../../../../shared/dropdowns/assessment";
import Subject from "../../../../shared/dropdowns/subject";
import Loader from "../../../../shared/loader";
import Header from "./header";
import IndividualStudents from "./individualStudents";
import { getSchoolByDistrictId } from "../../../../../api/teacher/school";
import { IBreadcrumb } from "../../../../../model/common/breadcrumb";
import routeConstant from "../../../../../utils/constant/routeConstant";
import Breadcrumb from "../../shared/breadcrumb";
import classNames from "classnames";
import InfoMessage from "../../../../shared/infoMessage";
import Asterisk from "../../../../shared/asterisk";
import { ISchool } from "../../../../../model/interface/school";
import { SchoolYearDropDown } from "../../../schoolYearDropdown";

function AssessmentComparisonReport(props) {
  const defaultValue = {
    label: "Select Subject",
    value: "0",
  };
  const districtUserTabOptions: any = {
    DistrictAssessment: "District",
    SchoolAssessment: "School",
  };
  const [selectedSubject, setSelectedSubject] =
    useState<ISelectElementAssessmentComparisonReport>(defaultValue);
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [assessments1, setAssessments1] = useState<Array<any>>();
  const [assessments2, setAssessments2] = useState<Array<any>>();
  const [selectedAssessment1, setSelectedAssessment1] =
    useState<ISelectElementAssessmentComparisonReport>(defaultValue);
  const [selectedAssessment2, setSelectedAssessment2] =
    useState<ISelectElementAssessmentComparisonReport>(defaultValue);
  const [test1HeaderData, setTest1HeaderData] = useState<TestHeader | null>();
  const [test2HeaderData, setTest2HeaderData] = useState<TestHeader | null>();
  const [selectedClassId, setSelectedClass] = useState<Array<number>>([]);
  const [selectedSchoolsInsideHeader, setSelectedSchoolsInsideHeader] =
    useState<Array<number>>([]);
  const [schools, setSchools] = useState<Array<any>>();
  const [selectedTab, setSelectedTab] = useState<string>(
    districtUserTabOptions.DistrictAssessment
  );
  const [selectedSchool, setSelectedSchool] = useState<ISelectElement>({
    ...defaultValue,
    label: "Select School",
  });
  const [isDistrictUser] = useState<boolean>(
    props.userContext.roleId === constant.UserRoleId.District ? true : false
  );
  const [showReport, setShowReport] = useState<boolean>(false);
  const [enableExport, setEnableExport] = useState<boolean>(false);
  const [schoolIds, setSchoolIds] = useState<Array<ISchool>>([]);
  const [selectedSchoolAccountId, setSelectedSchoolAccountId] =
    useState<number>(0);
  const [studentsRefreshCounter, setStudentsRefreshCounter] =
    useState<number>(0);
  const [selectedSchoolYearId, setSelectedSchoolYearId] = useState<number>();
  const [resetSchoolYear, setResetSchoolYear] = useState<boolean>(false);

  const getSchools = async () => {
    const resp = await getSchoolByDistrictId(
      props.userContext?.districtId ?? 0
    );
    if (resp?.data?.length) {
      const schools: Array<ISelectElement> = resp?.data.map((values: any) => {
        return {
          label: values?.name,
          value: values?.schoolId,
        };
      });
      setSchools(schools);
      const schoolIds: Array<any> = resp?.data.map((values: any) => {
        return {
          schoolAccountId: values?.schoolAccountId,
          schoolId: values?.schoolId,
        };
      });
      setSchoolIds(schoolIds);
    }
  };

  function handleSchoolYearLoaded(currentSchoolYearId?: number) {
    handleSchoolYearChange(currentSchoolYearId);
  }

  function handleSchoolYearChange(schoolYearId?: number) {
    setSelectedSchoolYearId(schoolYearId);
    setSelectedSubject(defaultValue);
    setSelectedAssessment1({
      label: "Select Assessment 1",
      value: "0",
    });
    setSelectedAssessment2({
      label: "Select Assessment 2",
      value: "0",
    });

    setShowReport(false);
  }

  function handleSchoolChange(selectedItem: any) {
    setSelectedAssessment1({
      label: "Select Assessment 1",
      value: "0",
    });
    setSelectedAssessment2({
      label: "Select Assessment 2",
      value: "0",
    });
    setSelectedSubject(defaultValue);
    setSelectedSchool(selectedItem);
    setSelectedSchoolAccountId(
      schoolIds?.filter((s) => s.schoolId == parseInt(selectedItem.value))[0]
        ?.schoolAccountId ?? 0
    );

    setShowReport(false);
  }

  function handleSubjectChange(selectedItem: any) {
    setSelectedAssessment1({
      label: "Select Assessment 1",
      value: "0",
    });
    setSelectedAssessment2({
      label: "Select Assessment 2",
      value: "0",
    });
    setSelectedSubject(selectedItem);
    fetchAssessments(selectedItem?.value, null);
    setShowReport(false);
  }

  function handleAssessment1Change(
    selectedItem: ISelectElementAssessmentComparisonReport
  ) {
    setSelectedAssessment1(selectedItem);
    defaultValue.label = "Select Assessment 2";
    setSelectedAssessment2(defaultValue);
    fetchAssessments(selectedSubject ? parseInt(selectedSubject.value) : 0, parseInt(selectedItem.value));
    setShowReport(false);
  }

  function handleAssessment2Change(
    selectedItem: ISelectElementAssessmentComparisonReport
  ) {
    setSelectedAssessment2(selectedItem);
    setShowReport(false);
  }

  function fetchAssessments(subjectId: number, test1Id?: number | null) {
    setShowLoading(true);
    const assessmentSearch = {
      subjectId: subjectId,
      test1Id: test1Id,
      cannedTestTypeIds: constant.cannedTestTypeIds,
      schoolId:
        selectedSchool.value && selectedSchool.value !== defaultValue.value
          ? parseInt(selectedSchool.value)
          : null,
      schoolYearId: selectedSchoolYearId
    };

    getAssessmentFilterForTestComparisonReport(assessmentSearch)
      .then((r) => {
        setShowLoading(false);
        if (test1Id === null) {
          setAssessments1(r.data);
        } else {
          setAssessments2(r.data);
        }
        setShowLoading(false);
      })
      .finally(() => {
        setShowLoading(false);
      });
  }
  const resetReport = () => {
    setResetSchoolYear(true);
    setAssessments1([]);
    setAssessments2([]);
    setSelectedSubject(defaultValue);
    setSelectedSchool({ ...defaultValue, label: "Select School" });
    setSelectedAssessment1({
      label: "Select Assessment 1",
      value: "0",
    });
    setSelectedAssessment2({
      label: "Select Assessment 2",
      value: "0",
    });
    setTest1HeaderData(null);
    setTest2HeaderData(null);
    setShowReport(false);
    setEnableExport(false);
  };

  const downloadStudentIndividualSummary = () => {
    setShowLoading(true);
    getTestComparisonStudentsExport(
      parseInt(selectedSubject.value ?? "0"),
      selectedAssessment1.cannedTestTypeId
        ? null
        : parseInt(selectedAssessment1.value),
      selectedAssessment1.cannedTestTypeId
        ? parseInt(selectedAssessment1.value)
        : null,
      selectedAssessment2.cannedTestTypeId
        ? null
        : parseInt(selectedAssessment2.value),
      selectedAssessment2.cannedTestTypeId
        ? parseInt(selectedAssessment2.value)
        : null,
      props.userContext?.userId ?? 0,
      arrayToCommaString(selectedClassId),
      (selectedSchool.value &&
        selectedSchool.value !== defaultValue.value)
        ? selectedSchool.value
        : arrayToCommaString(
          selectedSchoolsInsideHeader
        ),
      selectedSchoolYearId ?? 0
    )
      .then((response) => {
        startFileDownload(response, "CompareAssessmentStudents.xlsx");
        setShowLoading(false);
      })
      .finally(() => {
        setShowLoading(false);
      });
  };

  const downloadStudentIndividualDetails = () => {
    setShowLoading(true);

    getTestComparisonStudentDetailsExport(
      parseInt(selectedSubject.value ?? "0"),
      selectedAssessment1.cannedTestTypeId
        ? null
        : parseInt(selectedAssessment1.value ?? "0"),
      selectedAssessment1.cannedTestTypeId
        ? parseInt(selectedAssessment1.value ?? "0")
        : null,
      selectedAssessment2.cannedTestTypeId
        ? null
        : parseInt(selectedAssessment2.value ?? "0"),
      selectedAssessment2.cannedTestTypeId
        ? parseInt(selectedAssessment2.value ?? "0")
        : null,
      props.userContext?.userId ?? 0,
      selectedClassId.join(","),
      (selectedSchool.value &&
        selectedSchool.value !== defaultValue.value)
        ? selectedSchool.value
        : arrayToCommaString(
          selectedSchoolsInsideHeader
        ),
      selectedSchoolYearId ?? 0
    )
      .then((response) => {
        startFileDownload(response, "CompareTestStudentDetails.xlsx");
        setShowLoading(false);
      })
      .finally(() => {
        setShowLoading(false);
      });
  };

  const startFileDownload = (
    response: AxiosResponse<any>,
    fileName: string
  ) => {
    const file = response.data;
    const fileURL = URL.createObjectURL(file);
    let link = document.createElement("a");
    link.href = fileURL;
    link.download = fileName;
    link.setAttribute("target", "_blank");
    document.body.appendChild(link);
    link.click();
  };

  const runReport = () => {
    fetchTestHeaders();
    setStudentsRefreshCounter(studentsRefreshCounter + 1);
    setShowReport(true);
  };

  const fetchTestHeaders = () => {
    setShowLoading(true);
    const headerSearch = {
      subjectId: selectedSubject.value,
      preAssessmentId: selectedAssessment1.cannedTestTypeId
        ? null
        : selectedAssessment1.value,
      postAssessmentId: selectedAssessment2.cannedTestTypeId
        ? null
        : selectedAssessment2.value,
      preDiagnosticTestId: selectedAssessment1.cannedTestTypeId
        ? selectedAssessment1.value
        : null,
      postDiagnosticTestId: selectedAssessment2.cannedTestTypeId
        ? selectedAssessment2.value
        : null,
      classIds: arrayToCommaString(selectedClassId),
      schoolId: selectedSchool.value !== defaultValue.value
        ? parseInt(selectedSchool.value)
        : null,
      schoolYearId: selectedSchoolYearId
    };

    getTestComparisonReportHeader(headerSearch)
      .then((r) => {
        setShowLoading(false);
        setTest1HeaderData(r.data.data?.test1Data);
        setTest2HeaderData(r.data.data?.test2Data);
      })
      .finally(() => {
        setShowLoading(false);
      });
  };

  const handleClassChange: any = (e: any) => {
    const classIds: Array<number> = e.map((ele) => parseInt(ele.value));
    setSelectedClass(classIds);
  };
  const handleSchoolInsideHeaderChange: any = (e: any) => {
    const schoolIds: Array<number> = e.map((ele) => parseInt(ele.value));
    setSelectedSchoolsInsideHeader(schoolIds);
  };

  function arrayToCommaString(numbers) {
    return numbers.join(",");
  }

  useEffect(() => {
    if (selectedSchoolYearId) {
      setResetSchoolYear(false);
      setSelectedAssessment1({
        label: "Select Assessment 1",
        value: "0",
      });
      setSelectedAssessment2({
        label: "Select Assessment 2",
        value: "0",
      });
    }
  }, [selectedSchoolYearId]);

  useEffect(() => {
    if (selectedClassId && selectedAssessment1 && selectedAssessment2 && selectedSchoolYearId) {
      fetchTestHeaders();
    }
  }, [selectedClassId]);

  useEffect(() => {
    if (isDistrictUser) {
      getSchools();
    }
  }, [isDistrictUser]);

  useEffect(() => {
    resetReport();
  }, [selectedTab]);

  const breadcrumbItems = () => {
    const items: Array<IBreadcrumb> = [
      { name: "Home", url: routeConstant.ROUTE_DASHBOARD },
      { name: "Reports", url: routeConstant.REPORTS },
      { name: "Assessment Comparison Report", url: "" },
    ];

    return items;
  };

  const isExportEnabled = () => {
    return (
      selectedSubject.value !== "0" &&
      selectedAssessment1.value !== "0" &&
      selectedAssessment2.value !== "0"
    );
  };

  return (
    <div className="min-w-0 w-full">
      {showLoading && <Loader></Loader>}
      <div className="lg:flex lg:items-center px-4 py-3 lg:px-8 border-b">
        {<Breadcrumb items={breadcrumbItems()} />}
        <div className="lg:w-[55%] text-lg flex justify-between gap-2 items-end">
          <h1 className="text-primary-violet font-medium flex justify-start mb-0 pb-0">
            Assessment Comparison Report
          </h1>
        </div>
      </div>
      <div className="px-2 sm:px-6  lg:px-8 py-1 relative my-4">
        {props.userContext?.roleId === constant.UserRoleId.District && (
          <div className="flex lg:mr-2 xl:mr-0 bg-gray-100 pt-2 pl-2">
            <nav
              className="grid grid-cols-2 md:grid-cols-none md:grid-rows-none md:flex md:space-x-2"
              aria-label="Tabs"
            >
              <button
                className={classNames(
                  "px-2 sm:px-4 text-center border-t-2 text-sm cursor-pointer font-medium",
                  selectedTab === districtUserTabOptions.DistrictAssessment
                    ? "border-secondary-teal bg-white text-primary-violet hover:text-dark-violet py-3 tab-box-shadow tab-box-shadow"
                    : "text-primary-violet text-opacity-60 bg-primary-violet/10 py-[0.6rem] mt-auto transition-all hover:py-3 hover:text-opacity-100 hover:text-primary-violet border-[#dee9f1]"
                )}
                onClick={() => {
                  setSelectedTab(districtUserTabOptions.DistrictAssessment);
                }}
              >
                District Assessment
              </button>
              <button
                className={classNames(
                  "px-2 sm:px-4 text-center border-t-2 text-sm cursor-pointer font-medium",
                  selectedTab === districtUserTabOptions.SchoolAssessment
                    ? "border-secondary-teal bg-white text-primary-violet hover:text-dark-violet py-3 tab-box-shadow tab-box-shadow"
                    : "text-primary-violet text-opacity-60 bg-primary-violet/10 py-[0.6rem] mt-auto transition-all hover:py-3 hover:text-opacity-100 hover:text-primary-violet border-[#dee9f1]"
                )}
                onClick={() => {
                  setSelectedTab(districtUserTabOptions.SchoolAssessment);
                }}
              >
                School Assessment
              </button>
            </nav>
          </div>
        )}
        <div className="bg-gray-100 w-full rounded-lg p-5">
          {isDistrictUser &&
            selectedTab === districtUserTabOptions.SchoolAssessment && (
              <InfoMessage message="Select a school to view the assessments that are available at the rooftop level." />
            )}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-4 xxxl:grid-cols-6 gap-4 mb-4 mt-4">
            <div className="flex flex-col">
              <label className="block mb-1 text-sm text-gray-500">
                School Year <Asterisk/>
              </label>
              <SchoolYearDropDown
                reset={resetSchoolYear}
                offsets={[0, -1]}
                handleSchoolYearChange={handleSchoolYearChange}
                handleSchoolYearLoaded={handleSchoolYearLoaded}
                handleSchoolYearData={(e) => {}}
              ></SchoolYearDropDown>
            </div>
            {isDistrictUser &&
              selectedTab === districtUserTabOptions.SchoolAssessment && (
                <div className="flex flex-col">
                  <label className="block mb-1 text-sm text-gray-500">
                    School <Asterisk/>
                  </label>
                  <Select
                    options={schools}
                    value={selectedSchool}
                    onChange={(e) => handleSchoolChange(e)}
                  />
                </div>
              )}
            <div className="flex flex-col">
              <Subject
                setSelectedSubject={(items) => handleSubjectChange(items[0])}
                selectedItems={selectedSubject}
                isMulti={false}
                isRequired={true}
                themeColor={"#008fbb"}
                schoolId={
                  selectedSchool.value &&
                  selectedSchool.value !== defaultValue.value
                    ? parseInt(selectedSchool.value)
                    : null
                }
                isDisabled={
                  isDistrictUser &&
                  selectedTab === districtUserTabOptions.SchoolAssessment
                    ? selectedSchool.value === "0"
                    : false
                }
              ></Subject>
            </div>
            <div className="flex flex-col">
              <Assessment
                label={"Assessment 1 (Pre-Test)"}
                defaultText={"Select Assessment 1"}
                setSelectedAssessment={(items) =>
                  handleAssessment1Change(items[0])
                }
                selectedItems={selectedAssessment1}
                assessments={assessments1}
                isMulti={false}
                isRequired={true}
                themeColor={"#008fbb"}
                showDiagTest={true}
                isDisabled={selectedSubject.value == "0" ? true : false}
              ></Assessment>
            </div>
            <div className="flex flex-col">
              <Assessment
                label={"Assessment 2 (Post-Test)"}
                setSelectedAssessment={(items: any) =>
                  handleAssessment2Change(items[0])
                }
                selectedItems={selectedAssessment2}
                assessments={assessments2}
                isMulti={false}
                isRequired={true}
                themeColor={"#008fbb"}
                isDisabled={selectedAssessment1.value == "0" ? true : false}
              ></Assessment>
            </div>
          </div>
          <div className="flex gap-4 justify-end">
            <button
              className="bg-transparent border my-auto border-primary-violet shadow-sm py-2 px-4 flex justify-center text-sm font-medium text-primary-violet hover:text-white hover:bg-light-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 right-6 top-3 transition ease-in-out duration-500"
              onClick={resetReport}
            >
              Reset
            </button>
            <div className="cursor-not-allowed">
              <button
                className={
                  "bg-primary-violet border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 " +
                  (selectedAssessment1.value !== "0" &&
                  selectedAssessment2.value !== "0"
                    ? "hover:bg-dark-violet hover:shadow-lg"
                    : "bg-opacity-20 text-primary-violet text-opacity-40 pointer-events-none")
                }
                onClick={runReport}
              >
                Run Report
              </button>
            </div>
          </div>
          <div className="border-b border-gray-400 py-2 mb-2"></div>
          <div className="bg-white px-3 py-5">
            <div>
              {test1HeaderData && test2HeaderData && showReport && (
                <Header
                  selectedTab={selectedTab}
                  test1HeaderData={test1HeaderData}
                  test2HeaderData={test2HeaderData}
                  subjectName={selectedSubject.label}
                  handleClassChange={handleClassChange}
                  handleSchoolInsideHeaderChange={
                    handleSchoolInsideHeaderChange
                  }
                  userContext={props.userContext}
                  profile={props.profile}
                  subjectId={parseInt(selectedSubject.value ?? "0")}
                  schoolId={parseInt(selectedSchool.value ?? "0")}
                  selectedSchoolAccountId={selectedSchoolAccountId}
                ></Header>
              )}
              <div className="flex items-start bg-gray-100 pt-2 py-1 px-3 flex-col lg:flex-row lg:justify-between mt-6">
                <div className="flex lg:mr-2 xl:mr-0">
                  <nav
                    className="grid grid-cols-2 md:grid-cols-none md:grid-rows-none md:flex md:space-x-2"
                    aria-label="Tabs"
                  >
                    <button className="border-secondary-teal bg-white text-primary-violet hover:text-dark-violet font-medium py-3 tab-box-shadow tab-box-shadow px-2 sm:px-4 text-center border-t-2 text-sm cursor-pointer">
                      Individual Students
                    </button>
                  </nav>
                </div>
                <div className="flex gap-4 justify-end">
                  <button
                    className={classNames(
                      isExportEnabled() && showReport && enableExport
                        ? "bg-transparent border border-primary-violet text-primary-violet hover:text-white hover:bg-light-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition ease-in-out duration-500 font-medium"
                        : "bg-transparent border border-primary-violet text-primary-violet font-medium cursor-not-allowed opacity-50",
                      "my-auto shadow-sm py-2 px-4 flex justify-center text-sm"
                    )}
                    onClick={() => {
                      if (showReport) {
                        downloadStudentIndividualSummary();
                      }
                    }}
                    disabled={
                      !(isExportEnabled() && showReport && enableExport)
                    }
                  >
                    Export Summary
                  </button>
                  {(!isDistrictUser ||
                    (isDistrictUser &&
                      selectedTab ===
                        districtUserTabOptions.SchoolAssessment)) && (
                    <button
                      className={classNames(
                        isExportEnabled() && showReport && enableExport
                          ? "bg-transparent border border-primary-violet text-primary-violet hover:text-white hover:bg-light-violet hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition ease-in-out duration-500 font-medium"
                          : "bg-transparent border border-primary-violet text-primary-violet font-medium cursor-not-allowed opacity-50",
                        "my-auto shadow-sm py-2 px-4 flex justify-center text-sm"
                      )}
                      onClick={() => {
                        if (showReport) {
                          downloadStudentIndividualDetails();
                        }
                      }}
                      disabled={
                        !(isExportEnabled() && showReport && enableExport)
                      }
                    >
                      Export Details
                    </button>
                  )}
                </div>
              </div>
              <div>
                {selectedAssessment1.value !== "0" &&
                  selectedAssessment2.value !== "0" &&
                  showReport && (
                    <IndividualStudents
                      selectedTab={selectedTab}
                      subjectId={parseInt(selectedSubject.value ?? "0")}
                      preAssessmentId={
                        selectedAssessment1.cannedTestTypeId
                          ? null
                          : parseInt(selectedAssessment1.value)
                      }
                      preDiagnosticTestId={
                        selectedAssessment1.cannedTestTypeId
                          ? parseInt(selectedAssessment1.value)
                          : null
                      }
                      postAssessmentId={
                        selectedAssessment2.cannedTestTypeId
                          ? null
                          : parseInt(selectedAssessment2.value)
                      }
                      postDiagnosticTestId={
                        selectedAssessment2.cannedTestTypeId
                          ? parseInt(selectedAssessment2.value)
                          : null
                      }
                      preAssessmentName={
                        test1HeaderData?.testName || selectedAssessment1.label
                      }
                      postAssessmentName={
                        test2HeaderData?.testName || selectedAssessment2.label
                      }
                      classIds={arrayToCommaString(selectedClassId)}
                      schoolIds={(selectedSchool.value &&
                        selectedSchool.value !== defaultValue.value)
                          ? selectedSchool.value
                          : arrayToCommaString(
                            selectedSchoolsInsideHeader
                          )}
                      setEnableExport={setEnableExport}
                      schoolYearId={selectedSchoolYearId ?? 0}
                      refreshCounter={studentsRefreshCounter}
                    />
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default connect((state: any) => {
  return {
    userContext: state.userContext,
    profile: state.profile,
  };
})(AssessmentComparisonReport);

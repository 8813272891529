import qs from "qs";
import { axiosInstance } from "../environment/axiosConfig";
import { Configuration } from "../environment/setup";

export const login = (
  userName: string,
  password: string,
  accountLoginName: string,
  userId: number,
  gmailId: string,
  canvasLoginId: string,
  loginMethod: string = "",
  accessCode: string = "",
  isImpersonate: boolean = false,
  impersonatedByUserId: number = 0
) => {
  if (!isImpersonate) {
    window.localStorage.setItem("showGoogleSignInPopup", "true");
  }
  const data = qs.stringify({
    username: userName,
    password: password,
    accountId: accountLoginName,
    userId: userId,
    loginMethod: loginMethod,
    accessCode: accessCode,
    grant_type: "password",
    scope: "plWebApi openid",
    gmailId: gmailId,
    canvasLoginId: canvasLoginId,
    isImpersonate: isImpersonate,
    impersonatedByUserId: impersonatedByUserId    
  });

  return axiosInstance.post(`${Configuration.AuthBaseUrl}connect/token`, data, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization:
        "Basic ZWdDbGllbnQ6OTZlYjMxMzYtYmE5MS00Yjc0LTgzMmEtMGRiMTVlNjU0ZTM4",
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
};

import { ChevronRightIcon } from "@heroicons/react/solid";
import { Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import RouteConstant from "../../../../utils/constant/routeConstant";
import Loader from "../../../shared/loader";

function AssessmentResult(props: any) {
  const showLoading = false;

  return (
    <Fragment>
      {showLoading && <Loader></Loader>}
      <div className="min-w-0 w-full">
        <div className="px-4 py-5 lg:px-8 border">
          <ol className="flex items-center space-x-2 mb-1">
            <li>
              <div className="flex items-center">
                <Link
                  to={RouteConstant.ASSESSMENTBANK}
                  className="cursor-pointer text-sm text-gray-500 hover:text-gray-700 border-dashed border-b border-gray-400 hover:border-opacity-0"
                >
                  Assessment
                </Link>
              </div>
            </li>
            <li>
              <div className="flex items-center">
                <ChevronRightIcon className="flex-shrink-0 h-5 w-5 text-gray-400" />
                <span className="ml-1 text-sm text-gray-500 capitalize">
                  {`${" Assessment Result"}`}
                </span>
              </div>
            </li>
          </ol>
          <div className=" text-lg font-medium flex gap-2 h-auto sm:h-10 items-center">
            <h1 className="text-secondary-teal flex items-center justify-between w-full pr-4">
              {`${" Assessment Result"}`}
            </h1>
          </div>
        </div>
        <div className="px-2 sm:px-6  lg:px-8 py-1 relative my-4">
          <div className="bg-gray-100 w-full rounded-lg py-5 px-8 font-semibold text-2xl text-gray-700 mb-8">
            TEST AVG <span className="text-secondary-teal "> 64%</span>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-12 text-gray-700 md:space-x-4">
            <div className="px-2 md:px-0 md:col-span-6 2xl:col-span-4 xxxl:col-span-3 space-y-3">
              <div className="flex w-full">
                <div className="w-40 font-semibold flex-none">Name</div>
                <div>Beginning of the Year Diagnostic</div>
              </div>
              <div className="flex w-full">
                <div className="w-40 font-semibold flex-none">Teacher</div>
                <div>Felicia Example</div>
              </div>
              <div className="flex w-full">
                <div className="w-40 font-semibold flex-none">Date Created</div>
                <div className="flex flex-wrap">
                  <span className="bg-gray-100 rounded-lg inline-block px-2 mr-2">
                    02/18/2022
                  </span>
                  <span className="bg-gray-100 rounded-lg inline-block px-2">
                    02/18/2022
                  </span>
                </div>
              </div>
              <div className="flex w-full">
                <div className="w-40 font-semibold flex-none">
                  Assessment Code
                </div>
                <div className="flex flex-wrap">
                  <span className="bg-gray-100 rounded-lg inline-block px-2 mr-2">
                    DOHOFEZEHO<sup className="text-red-500">1</sup>
                  </span>
                  <span className="bg-gray-100 rounded-lg inline-block px-2">
                    DOHOFEZEHO<sup className="text-red-500">2</sup>
                  </span>
                </div>
              </div>
              <div className="flex w-full">
                <div className="w-40 font-semibold flex-none">Test</div>
                <div>Chemistry (CA NGSS)</div>
              </div>
              <div className="flex w-full">
                <div className="w-40 font-semibold flex-none">Length</div>
                <div>
                  <div className="font-semibold">Questions</div>
                  <div>13 (25% of Actual Test Length)</div>
                  <div>25 (50% of Actual Test Length)</div>
                </div>
              </div>
              <div className="flex w-full">
                <div className="w-40 font-semibold flex-none">Standards</div>
                <div>
                  <div>Waves</div>
                  <div>Electromagnetic Radiation</div>
                  <div>Electrical Forces Between Particles</div>
                </div>
              </div>
            </div>
            <div className="mt-4 md:mt-0 md:col-span-6 2xl:col-span-5 xxxl:col-span-4 bg-gray-50 shadow-lg py-4 px-6 space-y-3">
              <div className="inline-flex inline-block border text-sm border-gray-300 rounded-md overflow-hidden">
                <span className="bg-secondary-teal text-white w-36 inline-block py-0.5 text-center cursor-pointer hover:opacity-80">
                  Scores by Domain
                </span>
                <span className="bg-white text-gray-400 w-36 py-0.5 inline-block text-center  cursor-pointer hover:text-gray-500 ">
                  View By Standard
                </span>
              </div>
              <div>
                <div className="underline text-blue-400 mb-1">
                  Matter and Its Interactions
                </div>
                <div className="flex items-center w-full">
                  <div className="text-xl font-semibold text-gray-500">
                    +35%
                  </div>
                  <div className="bg-gray-200 w-full ml-2 h-5 flex rounded-lg overflow-hidden">
                    <div className="w-[50%] bg-secondary-teal"></div>
                    <div className="w-[30%] bg-[#82c95e]"></div>
                  </div>
                </div>
              </div>
              <div>
                <div className="underline text-blue-400 mb-1">Energy</div>
                <div className="flex items-center w-full">
                  <div className="text-xl font-semibold text-gray-500">
                    +24%
                  </div>
                  <div className="bg-gray-200 w-full ml-2 h-5 flex rounded-lg overflow-hidden">
                    <div className="w-[50%] bg-secondary-teal"></div>
                    <div className="w-[30%] bg-[#82c95e]"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

const mapStateToProps = (state: any) => {
  return {
    userContext: state.userContext,
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(AssessmentResult);

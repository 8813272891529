import { Fragment, useEffect, useState } from "react";
import { CloseIcon } from "../../../../../assets/icons/index";
import {
  getStudyPlanGameDetails,
  updateStudyPlanGameScore,
} from "../../../../../api/student/studyPlanApi";
import {
  getMyGalaxyGameDetails,
  updateMyGalaxyGameScore,
} from "../../../../../api/student/liftOff/myGalaxyApi";
import Constant from "../../../../../utils/constant/constant";
import Game from "../../../game/game";
import {
  getAssignmentGameDetails,
  updateAssignmentGameScore,
} from "../../../../../api/student/studentActivityApi";

interface GamePopupProps {
  studentId: number;
  sessionId: string;
  activityType: string;
  showPopup(show: boolean): void;
}

export default function GamePopup(props: GamePopupProps) {
  const { studentId, sessionId, activityType, showPopup } = props;
  const [gameOver, setGameOver] = useState<boolean>(false);
  const [gameScore, setGameScore] = useState<number>(0);

  const timer = 20;
  const [game, setGame] = useState<any>({
    gameId: 0,
    rocketId: 0,
    blasterId: 0,
    startScore: 0,
    startLevel: 1,
    blasterType: "cannon",
    gameSettings: "", 
    bossLevel: 1,
    bossHealth: 100 
  });
  useEffect(() => {
    if (activityType === Constant.ActivityType.STUDYPLAN) {
      getStudyPlanGameData(studentId, sessionId);
    } else if (activityType === Constant.ActivityType.MYGALAXY) {
      getMyGalaxyGameData(studentId, sessionId);
    } else if (activityType === Constant.ActivityType.ASSIGNMENT) {
      getAssignmentGameData(studentId, sessionId);
    }
  }, []);

  const setGameData = (data: any) => {    
    setGame({     
      gameId: data.gameId,
      rocketId: data.rocketId,
      blasterId: data.blasterId,
      startScore: data.gameScore ? data.gameScore : 0,
      startLevel: data.gameLevel ? data.gameLevel : 1,
      blasterType: data.blasterType ? data.blasterType : "cannon",
      gameSettings: data.gameSettings ? data.gameSettings : "",  
      bossLevel: data.gameLevel ? data.gameLevel : 1, 
    });
  };

  const getAssignmentGameData = (studentId: number, sessionId: string) => {
    getAssignmentGameDetails(studentId, sessionId).then((r) => {
      if (r.data && r.data.gameId) {
        setGameData(r.data);
      }
    });
  };

  const getStudyPlanGameData = (studentId: number, sessionId: string) => {
    getStudyPlanGameDetails(studentId, sessionId).then((r) => {
      if (r.data && r.data.gameId) {
        setGameData(r.data);
      }
    });
  };

  const getMyGalaxyGameData = (studentId: number, sessionId: string) => {
    getMyGalaxyGameDetails(studentId, sessionId).then((r) => {
      if (r.data && r.data.gameId) {
        setGameData(r.data);
      }
    });
  };

  const saveStudyPlanGameData = (
    studentId: number,
    sessionId: string,
    gameScore: number,
    gameLevel: number,
    gameSettings?: string
  ) => {
    updateStudyPlanGameScore(
      studentId,
      sessionId,
      gameScore,
      gameLevel,
      gameSettings
    ).then((r) => {
      // showPopup(false);
    });
  };

  const saveMyGalaxyGameData = (
    studentId: number,
    sessionId: string,
    gameScore: number,
    gameLevel: number,
    gameSettings?: string
  ) => {
    updateMyGalaxyGameScore(
      studentId,
      sessionId,
      gameScore,
      gameLevel,
      gameSettings
    ).then((r) => {
      showPopup(false);
    });
  };

  const saveAssignmentGameData = (
    studentId: number,
    sessionId: string,
    gameScore: number,
    gameLevel: number,
    gameSettings?: string
  ) => {
    updateAssignmentGameScore(
      studentId,
      sessionId,
      gameScore,
      gameLevel,
      gameSettings
    ).then((r) => {
      // showPopup(false);
    });
  };

  const onGameComplete = (data: any) => {
    console.log(data)
    if (data.currentScore !== 0) {
      //Health for Boss battle game
      //itemsleftarray for SpaceMatch
      const gameSettings = data.itemsleftarray ?? (data.Health ? (data.Health == 0 ? 100 : data.Health).toString() : "");
      if (activityType === Constant.ActivityType.STUDYPLAN) {
        saveStudyPlanGameData(
          studentId,
          sessionId,
          data.currentScore,
          data.newLevel,
          gameSettings 
        );
      } else if (activityType === Constant.ActivityType.MYGALAXY) {
        saveMyGalaxyGameData(
          studentId,
          sessionId,
          data.currentScore,
          data.newLevel,
          gameSettings
        );
      } else if (activityType === Constant.ActivityType.ASSIGNMENT) {
        saveAssignmentGameData(
          studentId,
          sessionId,
          data.currentScore,
          data.newLevel,
          gameSettings
        );
      }
    }
    setGameScore(data.currentScore === 0 ? game.startScore : data.currentScore);
    setGameOver(true);
  };

  return (
    <Fragment>
      <div
        className="fixed inset-0 flex items-center bg-black bg-opacity-50 justify-center"
        style={{ zIndex: 2147483638 }}
      >
        <div className=" flex flex-col justify-between overflow-hidden bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg sm:m-0  ">
          <header>
            {/* Header */}
            <div className="px-4 py-2 bg-gray-50">
              <div className="flex items-start justify-end space-x-3">
                <button
                  type="button"
                  className="inline-flex items-center justify-center w-6 h-6 text-gray-400 transition-colors duration-150 rounded dark:hover:text-gray-200 hover: hover:text-gray-700"
                  onClick={() => showPopup(false)}
                >
                  <CloseIcon />
                </button>
              </div>
            </div>
          </header>

          <div className="flex items-center justify-center px-6 pb-6 sm:flex-row bg-gray-50 gameContainer">
            {game.gameId > 0 && !gameOver && (
              <Game
                gameId={game.gameId}
                startLevel={game.startLevel}
                startScore={game.startScore}
                rocketId={
                  game.rocketId === 0 ? game.rocketId : game.rocketId - 1
                }
                blasterId={game.blasterId}
                blasterType={game.blasterType}
                timer={timer}
                onGameComplete={onGameComplete}
                bossLevel={game.gameId == Constant.Game.BossBattles ? game.bossLevel : null}
                gameSettings={game.gameSettings}
              />
            )}
          </div>
          {gameOver && (
            <div className="fixed inset-0 z-40 flex items-center bg-black bg-opacity-50 sm:items-center sm:justify-center">
              <div className="w-full relative radial-gradient mx-auto lg:max-w-3xl sm:max-w-3xl h-[40rem] rounded-lg flex flex-col items-center justify-center">
                <button
                  type="button"
                  className="absolute z-20 top-2 right-2 inline-flex items-center justify-center w-8 h-8 text-gray-400 transition-colors duration-150 rounded dark:hover:text-gray-200 hover: hover:text-gray-700"
                  onClick={() => showPopup(false)}
                >
                  <CloseIcon className="w-6 h-6" />
                </button>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 1600 900"
                  className="absolute z-0"
                >
                  <rect fill="none" width="1600" height="900" />
                  <defs>
                    <linearGradient id="a" x1="0" x2="0" y1="1" y2="0">
                      <stop offset="0" stop-color="#0FF" />
                      <stop offset="1" stop-color="#CF6" />
                    </linearGradient>
                    <linearGradient id="b" x1="0" x2="0" y1="0" y2="1">
                      <stop offset="0" stop-color="#F00" />
                      <stop offset="1" stop-color="#FC0" />
                    </linearGradient>
                  </defs>
                  <g fill="#FFF" fill-opacity="0" stroke-miterlimit="10">
                    <g stroke="url(#a)" stroke-width="2">
                      <path
                        transform="translate(0 0)"
                        d="M1409 581 1450.35 511 1490 581z"
                      />
                      <circle
                        stroke-width="4"
                        transform="rotate(0 800 450)"
                        cx="500"
                        cy="100"
                        r="40"
                      />
                      <path
                        transform="translate(0 0)"
                        d="M400.86 735.5h-83.73c0-23.12 18.74-41.87 41.87-41.87S400.86 712.38 400.86 735.5z"
                      />
                    </g>
                    <g stroke="url(#b)" stroke-width="4">
                      <path
                        transform="translate(0 0)"
                        d="M149.8 345.2 118.4 389.8 149.8 434.4 181.2 389.8z"
                      />
                      <rect
                        stroke-width="8"
                        transform="rotate(0 1089 759)"
                        x="1039"
                        y="709"
                        width="100"
                        height="100"
                      />
                      <path
                        transform="rotate(0 1400 132)"
                        d="M1426.8 132.4 1405.7 168.8 1363.7 168.8 1342.7 132.4 1363.7 96 1405.7 96z"
                      />
                    </g>
                  </g>
                </svg>
                <h1 className="uppercase text-7xl text-white font-bold font-Barlow drop-shadow-2xl">
                  Game
                </h1>
                <h1 className="uppercase text-7xl text-white font-bold font-Barlow drop-shadow-2xl">
                  Over
                </h1>

                <h1 className="uppercase text-2xl mt-2 text-white font-bold font-Barlow drop-shadow-2xl">
                  Score : {gameScore}
                </h1>

                <button
                  className="py-2 absolute bottom-10 z-20 px-3 bg-primary-violet border-1 border-gray-500 shadow-lg mt-6 text-sm text-white hover:text-white transform hover:scale-110 transition-all duration-300 rounded-md cursor-pointer"
                  onClick={() => showPopup(false)}
                >
                  Close and Continue
                </button>
              </div>
            </div>
          )}
        </div>

        {/* <!-- Action Buttons -->  */}
      </div>
    </Fragment>
  );
}

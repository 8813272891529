import { Button } from "@windmill/react-ui";
import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import Download from "../../../src/utils/downloadHelper";
import { fetchData } from "../../api/campusTrainingApi";
import CampusTrainingModel from "../../model/campusTraining";
import Constant from "../../utils/constant/constant";
import Header from "../shared/header/header";
import Loader from "../shared/loader";
import Pagination from "../shared/pagination";
import ProgressBar from "../shared/progressBar";

function CampusTraining(props: any) {
  type ExportType = "NONE" | "CSV";
  let defaultReportData: Array<CampusTrainingModel> = [];
  const pageSize = 10;
  const [showLoader, setLoader] = useState(false);
  const [currentPageNumber, setPageNumber] = useState(1);
  const [reportData, setReportData] = useState(defaultReportData);

  useEffect(() => {
    showReport("NONE");
  }, [currentPageNumber]);

  function bindData(data: any, exportType: ExportType) {
    setLoader(false);
    setReportData(data);
    if (data && data.length > 0 && exportType !== "NONE") {
      if (data[0].base64StringData) {
        const fileName = "CAMPUSTRAINING";
        const hasMultipleFiles = data[0].hasMultipleFiles;
        const extension = hasMultipleFiles ? "zip" : "csv";
        Download(data[0].base64StringData, fileName, extension);
      }
    }
  }
  function handlePageChange(e: any) {
    setPageNumber(e);
  }

  function showReport(exportType: ExportType) {
    setLoader(true);
    const stateId = props.userContext.stateId;
    const userId = props.userContext.userId;
    const districtId = props.userContext.districtId;
    const schoolId = props.userContext.schoolId;
    const roleId = props.userContext.roleId;
    fetchData(
      Constant.Report.CAMPUSTRAINING,
      userId,
      schoolId,
      stateId,
      districtId,
      currentPageNumber,
      pageSize,
      exportType,
      roleId
    )
      ?.then((r) => bindData(r.data, exportType))
      .catch((err) => {
        setLoader(false);
      });
  }
  const totalResults =
    reportData && reportData.length > 0 ? reportData[0].totalCount : 0;
  return (
    <Fragment>
      <Header></Header>
      <div className="px-4 lg:px-8 mt-10">
        <h2 className="text-2xl text-primary-green font-semibold">
          Campus Training Report
        </h2>
      </div>
      <div className="px-4 lg:px-8 mt-10">
        <Button
          className="text-primary-green border border-primary-green py-1.5 px-2 bg-primary-green bg-opacity-0 hover:bg-primary-green hover:text-gray-700"
          layout="primary"
          onClick={() => showReport("CSV")}
        >
          Export CSV
        </Button>
      </div>
      <div className="px-4 lg:px-8 mt-3">
        <div className="flex flex-col relative">
          {showLoader && <Loader></Loader>}
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className="shadow overflow-hidden sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-primary-green bg-opacity-75">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-1 text-left text-xs font-medium text-gray-700 uppercase tracking-wider"
                      >
                        State
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-1 text-left text-xs font-medium text-gray-700 uppercase tracking-wider"
                      >
                        Region
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-1 text-center text-xs font-medium text-gray-700 uppercase tracking-wider"
                      >
                        District
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-1 text-center text-xs font-medium text-gray-700 uppercase tracking-wider"
                      >
                        School
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-1 text-center text-xs font-medium text-gray-700 uppercase tracking-wider"
                      >
                        Teachers Enrolled
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-1 text-center text-xs font-medium text-gray-700 uppercase tracking-wider"
                      >
                        Training Started
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-1 text-center text-xs font-medium text-gray-700 uppercase tracking-wider"
                      >
                        Training Completed
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-1 text-center text-xs font-medium text-gray-700 uppercase tracking-wider"
                      >
                        Training Completed %
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {reportData.map((report, personIdx) => (
                      <tr
                        key={personIdx}
                        className={
                          personIdx % 2 === 0
                            ? "bg-white"
                            : "bg-primary-green bg-opacity-10"
                        }
                      >
                        <td className="px-6 py-1 whitespace-nowrap text-xs text-gray-500">
                          {report.state}
                        </td>
                        <td className="px-6 py-1 whitespace-nowrap text-xs text-gray-500">
                          {report.region}
                        </td>
                        <td className="px-6 py-1 whitespace-nowrap text-xs text-gray-500 text-center">
                          {report.district}
                        </td>
                        <td className="px-6 py-1 whitespace-nowrap text-xs text-gray-500 text-center">
                          {report.school}
                        </td>
                        <td className="px-6 py-1 whitespace-nowrap text-xs text-gray-500 text-center">
                          {report.teachersEnrolled}
                        </td>
                        <td className="px-6 py-1 whitespace-nowrap text-xs text-gray-500 text-center">
                          {report.trainingStarted}
                        </td>
                        <td className="px-6 py-1 whitespace-nowrap text-xs text-gray-500 text-center">
                          {report.teachersCompleted}
                        </td>
                        <td>
                          <ProgressBar
                            percentage={report.completionPercentage.toLocaleString(
                              undefined,
                              { maximumFractionDigits: 2 }
                            )}
                          ></ProgressBar>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
          <Pagination
            totalResults={totalResults}
            resultsPerPage={pageSize}
            onChange={(e) => {
              handlePageChange(e);
            }}
            label=""
          />
        </div>
      </div>
    </Fragment>
  );
}

const mapStateToProps = (state: any) => {
  return {
    masterData: state.masterData,
    userContext: state.userContext,
  };
};

export default connect<{}, {}, {}>(mapStateToProps, {})(CampusTraining);

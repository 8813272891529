import { IUpdateClassStudentSetting } from "./../model/teacher/classManagement/IUpdateClassStudentSetting";
import { IDuplicateUser } from "../model/teacher/userManagement/duplicateUser";
import BaseApi from "./baseApi";
import { AxiosHelper } from "../environment/axiosConfig";
import APIConstant from "../utils/constant/apiConstant";
import { AxiosResponse } from "axios";
import { IMergeUser } from "../model/teacher/userManagement/mergeUser";

class UserApi extends BaseApi {
  public constructor() {
    super();
  }

  async GetAllUsers(query: any) {
    return AxiosHelper.axiosInstance().get(APIConstant.MANAGEUSER_API.GetAll, {
      params: query,
    });
  }
  async GetUserDetail(id: number) {
    return AxiosHelper.axiosInstance().get(
      `${APIConstant.MANAGEUSER_API.GetUserDetail}?id=${id}`
    );
  }
  async AddUser(user: any) {
    return AxiosHelper.axiosInstance().post(
      APIConstant.MANAGEUSER_API.Add,
      user
    );
  }
  async UpdateUser(user: any) {
    return AxiosHelper.axiosInstance().put(
      APIConstant.MANAGEUSER_API.Update,
      user
    );
  }
  async Delete(ids: number[], userId: number) {
    return AxiosHelper.axiosInstance().delete(
      APIConstant.MANAGEUSER_API.Delete,
      {
        data: { ids: ids, userId: userId },
      }
    );
  }
  async AddUsers(users: any[]) {
    return AxiosHelper.axiosInstance().post(
      APIConstant.MANAGEUSER_API.AddUsers,
      users
    );
  }
  async ReActivateUser(ids: number[], userId: number) {
    return AxiosHelper.axiosInstance().put(
      APIConstant.MANAGEUSER_API.ReActivateUser,
      { userIds: ids, userId: userId, isActive: true }
    );
  }
  async GetDuplicateUsers(
    schoolAccountId: number,
    mergeType: string,
    mergeUserId: number
  ): Promise<AxiosResponse<Array<IDuplicateUser>>> {
    return AxiosHelper.axiosInstance().get<Array<IDuplicateUser>>(
      APIConstant.MANAGEUSER_API.DuplicateUser,
      {
        params: {
          mergeType: mergeType,
          schoolAccountId: schoolAccountId,
          mergeUserId: mergeUserId,
        },
      }
    );
  }
  async MergeUsers(postData: Array<IMergeUser>, userId: number) {
    return AxiosHelper.axiosInstance().put(
      APIConstant.MANAGEUSER_API.MergeUsers,
      { userId: userId, mergeUsers: postData }
    );
  }
  async UpdateUserRole(
    userId: number,
    roleId: number,
    updatedByUserId: number
  ) {
    return AxiosHelper.axiosInstance().put(
      APIConstant.MANAGEUSER_API.UpdateUserRole,
      { userId: userId, roleId: roleId, updatedByUserId: updatedByUserId }
    );
  }
  async MergeUserSearchStudent(
    schoolAccountId: number,
    firstName: string,
    lastName: string,
    skipUserId: number,
    pageNumber: number,
    pageSize: number
  ) {
    return AxiosHelper.axiosInstance().get(
      APIConstant.MANAGEUSER_API.MergeUserSearchStudent,
      {
        params: {
          schoolAccountId: schoolAccountId,
          firstName: firstName,
          lastName: lastName,
          skipUserId: skipUserId,
          pageNumber: pageNumber,
          pageSize: pageSize,
        },
      }
    );
  }

  async updateStudentLiftoffSetting(updateSetting: IUpdateClassStudentSetting) {
    return AxiosHelper.axiosInstance().put(
      APIConstant.MANAGEUSER_API.UpdateLiftoffSetting,
      {
        classId: updateSetting.classId,
        setting: updateSetting.setting,
        updatedByUserId: updateSetting.updatedByUserId,
        userIds: updateSetting.userIds.join(","),
        value: updateSetting.value.toString(),
      }
    );
  }

  async importUsers(formData: FormData) {
    const headers = { "Content-Type": "multipart/form-data" };
    return AxiosHelper.axiosInstance().post(
      APIConstant.MANAGEUSER_API.ImportUser,
      formData,
      {
        headers: headers,
      }
    );
  }

  async userImportStatus(userImportId: any) {
    return AxiosHelper.axiosInstance().get(
      APIConstant.MANAGEUSER_API.ImportUser + `/` + userImportId,
      {}
    );
  }

  async importFailedUsers(userImportId: any) {
    return AxiosHelper.axiosInstance().get(
      APIConstant.MANAGEUSER_API.ImportUser +
        `/` +
        userImportId +
        `/failed-users`,
      {}
    );
  }

  async GetLiftoffStudents(
    schoolYearId: number,
    schoolAccountId?: number,
    classId?: number
  ) {
    return AxiosHelper.axiosInstance().get(
      APIConstant.MANAGEUSER_API.GetLiftoffStudents,
      {
        params: {
          schoolAccountId: schoolAccountId,
          classId: classId,
          schoolYearId: schoolYearId,
        },
      }
    );
  }
}

export default new UserApi();
